// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const generalSection = {
  title: 'titles:general',
  fields: {
    [GC.FLEET_GENERAL_SHOW_DASHBOARDS]: {
      type: 'switcher',
      hideForNotSuperAdmin: true,
      name: 'titles:show-dashboards',
      nameForAttribute: GC.FLEET_GENERAL_SHOW_DASHBOARDS,
    },
    [GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED]: {
      type: 'switcher',
      name: 'titles:auto-issue-id',
      nameForAttribute: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED,
    },
    [GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE]: {
      type: 'select',
      options: 'sequences',
      name: 'titles:issue-id-sequence',
      nameForAttribute: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE,
    },
    [GC.FLEET_GENERAL_INSPECTION_ID_AUTOGENERATED]: {
      type: 'switcher',
      name: 'titles:auto-inspection-id',
      nameForAttribute: GC.FLEET_GENERAL_INSPECTION_ID_AUTOGENERATED,
    },
    [GC.FLEET_GENERAL_INSPECTION_ID_SEQUENCE]: {
      type: 'select',
      options: 'sequences',
      name: 'titles:inspection-id-sequence',
      nameForAttribute: GC.FLEET_GENERAL_INSPECTION_ID_SEQUENCE,
    },
    [GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED]: {
      type: 'switcher',
      name: 'titles:auto-work-order-name',
      nameForAttribute: GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED,
    },
    [GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE]: {
      type: 'select',
      options: 'sequences',
      name: 'titles:work-order-name-sequence',
      nameForAttribute: GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE,
    },
    [GC.FLEET_GENERAL_SERVICE_ISSUE_AUTO_ASSIGN_SERVICE_VENDOR]: {
      type: 'switcher',
      name: 'titles:issue-auto-assign-service-vendor',
      nameForAttribute: GC.FLEET_GENERAL_SERVICE_ISSUE_AUTO_ASSIGN_SERVICE_VENDOR,
    },
  },
};

const componentTypeSection = {
  fields: [],
  configType: 'dropdown',
  editPopupTitle: 'titles:edit',
  title: 'titles:component-type',
  configName: GC.FLEET_COMPONENT_TYPE,
};

const componentManufacturerSection = {
  fields: [],
  configType: 'dropdown',
  editPopupTitle: 'titles:edit',
  title: 'titles:component-manufacturer',
  configName: GC.FLEET_COMPONENT_MANUFACTURER,
};

const equipmentServiceTypeSection = {
  fields: [],
  configType: 'dropdown',
  title: 'titles:service-type',
  editPopupTitle: 'titles:edit',
  configName: GC.FLEET_EQUIPMENT_SERVICE_TYPE,
};

const serviceIssueTypeSection = {
  fields: [],
  configType: 'dropdown',
  title: 'titles:issue-type',
  editPopupTitle: 'titles:edit',
  configName: GC.FLEET_GENERAL_SERVICE_ISSUE_TYPE,
};

const ConfigFleetGeneralGroup = {
  generalSection,
  componentTypeSection,
  serviceIssueTypeSection,
  equipmentServiceTypeSection,
  componentManufacturerSection,
};

export {
  ConfigFleetGeneralGroup,
};
