import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
import { ConfirmComponent } from '../../../components/confirm';
// forms
import { Fieldset2 } from '../../../forms';
// hocs
import { withAsyncGetBranchListByType } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const changeAssignedDivisionCondition = ({ truck, trailers, isPrimaryDriver }: Object) => R.and(
  G.isTrue(isPrimaryDriver),
  R.or(G.isNotNilAndNotEmpty(truck), G.isNotNilAndNotEmpty(trailers)),
);

const assignedToDivisionField = {
  type: 'select',
  isRequired: true,
  options: 'divisionOptions',
  label: ['titles:division', 'Division'],
  fieldName: GC.FIELD_ASSIGNED_TO_DIVISION_GUID,
  inputWrapperStyles: { mt: 15, mb: 25, width: 270 },
};

const changeAssignedDivisionField = {
  type: 'toggle',
  inputWrapperStyles: { mb: 25, width: 270 },
  fieldName: GC.FIELD_CHANGE_ASSIGNED_UNIT_DIVISION,
  label: ['titles:change-assigned-unit-division', 'Change Assigned Unit Division'],
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_ASSIGNED_TO_DIVISION_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const getMapPropsToValues = (props: Object) => {
  const { guid, assignedToDivision } = props;

  const assignedToDivisionGuid = R.pathOr(null, [GC.FIELD_GUID], assignedToDivision);

  if (changeAssignedDivisionCondition(props)) {
    return {
      guid,
      assignedToDivisionGuid,
      [GC.FIELD_CHANGE_ASSIGNED_UNIT_DIVISION]: false,
    };
  }

  return { guid, assignedToDivisionGuid };
};

const getFields = (props: Object) => G.ifElse(
  changeAssignedDivisionCondition(props),
  [assignedToDivisionField, changeAssignedDivisionField],
  R.of(Array, assignedToDivisionField),
);

const formEnhance = compose(
  withAsyncGetBranchListByType(),
  withFormik({
    validationSchema,
    mapPropsToValues: getMapPropsToValues,
    handleSubmit: (values: Object, { props }: Object) => {
      props.submitAction(values);
    },
  }),
  pure,
);

const WorkingDivisionForm = formEnhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={getFields(props)}
        divisionOptions={G.addEmptyOptionToDropDown(R.pathOr([], ['branchListByType'], props))}
      />
      <FormFooter2 />
    </form>
  );
});

const WorkingDivision = ({
  hasAction,
  assignedToDivision,
  handleOpenWorkingDivisionForm,
  handleUnassignWorkingDivision,
}: Object) => {
  const divisionGuid = G.getGuidFromObject(assignedToDivision);
  const divisionName = G.getPropFromObject(GC.BRANCH_NAME, assignedToDivision);

  return (
    <Flex py='5px'>
      {G.getWindowLocale('titles:working-division', 'Working Division')}:
      {
        G.isNotNilAndNotEmpty(divisionName) &&
        <TextComponent
          ml='8px'
          maxWidth={180}
          cursor='pointer'
          withEllipsis={true}
          title={divisionName}
          color={G.ifElse(hasAction, G.getTheme('colors.light.blue'))}
          onClick={() => {
            if (R.and(divisionGuid, hasAction)) G.goToRoute(routesMap.getEditBranchRoute(divisionGuid));
          }}
        >
          {divisionName}
        </TextComponent>
      }
      <Box ml='8px' cursor='pointer' onClick={handleOpenWorkingDivisionForm}>
        {G.ifElse(
          G.isNotNilAndNotEmpty(divisionGuid),
          I.pencil,
          I.plusRound,
        )()}
      </Box>
      {
        G.isNotNilAndNotEmpty(divisionGuid) &&
        <Box ml='8px' cursor='pointer' onClick={handleUnassignWorkingDivision}>
          {I.trash()}
        </Box>
      }
    </Flex>
  );
};

const enhance = compose(
  withHandlers({
    handleOpenWorkingDivisionForm: (props: Object) => () => {
      const { openModal, changeAssignedToDivisionRequest } = props;

      const component = <WorkingDivisionForm {...props} submitAction={changeAssignedToDivisionRequest} />;

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocaleArr(['titles:assign', 'titles:working-division']),
      );

      openModal(modal);
    },
    handleUnassignWorkingDivision: (props: Object) => {
      const {
        guid,
        openModal,
        assignedToDivision,
        changeAssignedToDivisionRequest,
      } = props;

      const name = G.getPropFromObject(GC.BRANCH_NAME, assignedToDivision);

      const textLocale = G.getWindowLocale(
        'messages:driver-unassign:confirmation:text',
        'Are you sure, you want unassign',
      );

      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          title: G.getWindowLocaleArr(['titles:unassign', 'titles:working-division']),
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:save', 'Save'),
              action: () => changeAssignedToDivisionRequest({ guid, [GC.FIELD_ASSIGNED_TO_DIVISION_GUID]: null }),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default enhance(WorkingDivision);
