import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import ExpenseForm from '../forms/expense-form';
import ExpenseDocument from '../components/expense-document';
import ApproveDeclineExpense from '../components/approve-decline-expense';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_STATUS]: {
    width: 150,
    customComponent: ({ data }: Object) => <ApproveDeclineExpense {...data} />,
  },
  [GC.FIELD_TYPE]: {
    width: 150,
    name: 'titles:expense-type',
    customComponent: ({ data }: Object) => R.path([GC.FIELD_TYPE, GC.FIELD_NAME], data),
  },
  [GC.FIELD_TOTAL]: {
    width: 100,
    name: 'titles:total',
    customComponent: ({ data }: Object) => {
      const { total, currency } = data;

      if (R.isNil(total)) return null;

      return `${G.getCurrencySymbol(currency)} ${total}`;
    },
  },
  [GC.FIELD_FILE_NAME]: {
    width: 200,
    name: 'titles:file',
    customComponent: ({ data }: Object) => <ExpenseDocument {...data} />,
  },
  [GC.FIELD_TRUCK]: {
    width: 150,
    name: 'titles:truck',
    customComponent: ({ data }: Object) => {
      const { truck } = data;

      if (R.isNil(truck)) return null;

      const text = `${R.prop(GC.FIELD_UNIT_ID, truck)} (${
        R.path([GC.FIELD_TRUCK_TYPE, GC.FIELD_DISPLAYED_VALUE], truck)})`;

      return (
        <TextComponent
          title={text}
          maxWidth='90%'
          display='block'
          withEllipsis={true}
        >
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_TRAILER]: {
    width: 150,
    name: 'titles:trailer',
    customComponent: ({ data }: Object) => {
      const { trailer } = data;

      if (R.isNil(trailer)) return null;

      const text = `${R.prop(GC.FIELD_UNIT_ID, trailer)} (${
        R.path([GC.FIELD_TRAILER_TYPE, GC.FIELD_DISPLAYED_VALUE], trailer)})`;

      return (
        <TextComponent
          title={text}
          maxWidth='90%'
          display='block'
          withEllipsis={true}
        >
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_EXPENSE_REQUIRABLE_TYPE]: {
    width: 150,
    name: 'titles:requirable-type',
    customComponent: ({ data }: Object) => {
      const { requirableType } = data;

      const localeMap = {
        DRIVER: G.getWindowLocale('titles:driver-payroll', 'Driver Payroll'),
        VENDOR: G.getWindowLocale('titles:vendor-payroll', 'Vendor Payroll'),
        NON_REQUIRABLE: G.getWindowLocale('titles:non-payroll', 'Non-Payroll'),
      };

      return R.pathOr(requirableType, [requirableType], localeMap);
    },
  },
  [GC.FIELD_DATE]: {
    width: 120,
    type: 'date',
    name: 'titles:date',
  },
  [GC.FIELD_ADDRESS]: {
    width: 200,
    name: 'titles:address',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 250,
    name: 'titles:description',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 120,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 120,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(columnSettings),
};

const getRemoveOrEditPermissions = ({ payrollGuid, vendorPayrollGuid }: Object) => {
  if (R.or(G.isNotNil(payrollGuid), G.isNotNil(vendorPayrollGuid))) return '';
};

export const expenseSettings = {
  report,
  columnSettings,
  groupName: 'driverExpenseList',
  getItemListRequestMethod: 'post',
  actionsPicker: ['edit', 'remove'],
  itemTitleArr: ['titles:expense', 'Expense'],
  getEditPermissions: getRemoveOrEditPermissions,
  getRemovePermissions: getRemoveOrEditPermissions,
  formGroupTitleArr: ['titles:expense', 'Expense'],
  endpoints: {
    list: 'driverExpenseList',
    remove: 'driverExpenseByGuid',
    createOrUpdate: 'driverExpense',
  },
  CustomForm: (props: Object) => <ExpenseForm {...props} />,
  makeRequestPayload: ({ driverBranchGuid, primaryObjectGuid }: Object) => ({
    params: {
      [GC.FIELD_DRIVER_GUID]: primaryObjectGuid,
    },
    data: {
      offset: 0,
      limit: 300,
      pageable: false,
      [GC.CURRENT_BRANCH]: driverBranchGuid,
    },
  }),
  makeInitialValues: (initialValues: Object, props: Object) => ({
    ...initialValues,
    [GC.FIELD_BRANCH_GUID]: G.getPropFromObject('driverBranchGuid', props),
    [GC.FIELD_EXPENSE_TYPE_GUID]: R.pathOr(null, [GC.FIELD_TYPE, GC.FIELD_GUID], initialValues),
  }),
};
