import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet/equipment-service
import EquipmentServiceReport from '../../fleet/equipment-service/components/report';
//////////////////////////////////////////////////

const Component = (props: Object) => {
  const {
    isOpened,
    openModal,
    openLoader,
    fleetType,
    closeModal,
    closeLoader,
    configGroup,
    primaryObjectGuid,
    primaryObjectBranchGuid,
    handleToggleFormGroupTable,
  } = props;

  const fleetProfileTypeTruck = R.equals(fleetType, 'truck');
  const entityGuidType = G.ifElse(fleetProfileTypeTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID);
  const documentTypeDropdownName = G.ifElse(fleetProfileTypeTruck, GC.TRUCK_DOCUMENT_TYPE, GC.TRAILER_DOCUMENT_TYPE);

  return (
    <EquipmentServiceReport
      isOpened={isOpened}
      openModal={openModal}
      entityType={fleetType}
      openLoader={openLoader}
      closeModal={closeModal}
      closeLoader={closeLoader}
      entityGuid={primaryObjectGuid}
      entityGuidType={entityGuidType}
      branchGuid={primaryObjectBranchGuid}
      handleToggleFormGroup={() => handleToggleFormGroupTable('equipmentServices')}
      documentTypeOptions={R.pathOr([], ['dropdownOptions', documentTypeDropdownName], configGroup)}
    />
  );
};

export const equipmentServiceSettings = {
  Component,
  groupName: 'equipmentServices',
};
