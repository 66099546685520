import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import ErrorsPopover from '../../../components/errors-popover';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature carrier-edi
import { downloadEdiFileRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      downloadEdiFileRequest,
    },
  ),
  pure,
);

const ActionsCell = (props: Object) => {
  const { entity } = props;

  const errors = R.path(['data', GC.FIELD_ERRORS], entity);
  const telGuid = R.path(['data', GC.SYSTEM_OBJECT_TEL_INFO, GC.FIELD_TEL_GUID], entity);

  const fileUri = R.path(['data', GC.FIELD_EDI_FILE_URI], entity);
  const branchGuid = R.path(['data', GC.FIELD_BRANCH_GUID], entity);
  const originalFileName = R.path(['data', GC.FIELD_EDI_ORIGINAL_FILE_NAME], entity);

  return (
    <Flex
      alignItems='center'
      height='max-content'
      color={G.getTheme('colors.dark.blue')}
    >
      <Box
        mr={10}
        cursor='pointer'
        title={G.getWindowLocale('actions:download', 'Download')}
        onClick={() => props.downloadEdiFileRequest({
          fileUri,
          branchGuid,
          fileName: originalFileName,
        })}
      >
        {I.downloadDocument(G.getTheme('colors.dark.blue'))}
      </Box>
      {
        G.isNotNilAndNotEmpty(telGuid) &&
        <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
          <Box
            cursor='pointer'
            onClick={() => G.goToRoute(routesMap.dispatchDetailsLoad(telGuid))}
            title={G.getWindowLocale('actions:go-to-trip-details', 'Go To Trip Details')}
          >
            {I.routesLoads(G.getTheme('colors.dark.blue'))}
          </Box>
        </AuthWrapper>
      }
      <ErrorsPopover errors={errors} wrapperPadding='3px' />
    </Flex>
  );
};

export default enhance(ActionsCell);
