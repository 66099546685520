import React from 'react';
import * as R from 'ramda';
// components
import { FormGroupTitlePanel } from '../../../components/form-group-table';
// forms
import { FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { MainActionButton } from '../../../ui';
// feature fleet-list
import FleetList from '../../fleet-list';
//////////////////////////////////////////////////

const tableSettings = {
  minHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 50,
  allowEditBtn: true,
  tableRowHeight: 35,
  checkBoxCellWidth: 54,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 90px)',
  checkBoxCellJustifyContent: 'flex-start',
};

const CreateButton = ({ handleCreateFleetServiceIssue }: Object) => (
  <MainActionButton
    ml={20}
    mb='3px'
    height={30}
    width={120}
    onClick={handleCreateFleetServiceIssue}
  >
    {G.getAddTitle(['titles:issue', 'Issue'])}
  </MainActionButton>
);

export const ServiceIssueReport = (props: Object) => {
  const {
    shared,
    isOpened,
    entityType,
    configGroup,
    primaryObjectGuid,
    primaryObjectUnitId,
    handleToggleFormGroup,
    primaryObjectBranchGuid,
  } = props;

  const deactivateOnServiceIssue = G.getConfigValueFromStore(
    G.ifElse(
      R.equals(entityType, GC.FIELD_TRUCK),
      GC.TRUCK_DEACTIVATE_ON_SERVICE_ISSUE,
      GC.TRAILER_DEACTIVATE_ON_SERVICE_ISSUE,
    ),
    configGroup,
    false,
  );

  return (
    <FormGroupWrapper isOpened={isOpened}>
      <FormGroupTitlePanel
        isOpened={isOpened}
        isHiddenAddIcon={true}
        handleToggleFormGroup={handleToggleFormGroup}
        panelTitle={G.getWindowLocale('titles:issues', 'Issues')}
      />
      <FleetList
        pt='0px'
        pb='6px'
        shared={shared}
        withoutPageActions={true}
        useSavedReportState={false}
        tableSettings={tableSettings}
        openedFromFleetProfile={true}
        primaryObjectBranchGuid={primaryObjectBranchGuid}
        deactivateOnServiceIssue={deactivateOnServiceIssue}
        callbackData={R.assoc('withoutExpandedContainer', true, props)}
        customTitleComponent={({ handleCreateFleetServiceIssue }: Object) => (
          <CreateButton
            handleCreateFleetServiceIssue={() => handleCreateFleetServiceIssue(
              entityType, primaryObjectGuid, primaryObjectUnitId,
            )}
          />
        )}
      />
    </FormGroupWrapper>
  );
};
