import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet-profile
import * as A from '../actions';
//////////////////////////////////////////////////

// monthly payroll deduction
const getVendorPayrollChargesIsPaidOnPayrollSuccess = (state: Object, data: Object) => (
  P.$set(
    'vendor.monthlyPayrollDeductionList',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      R.path(['vendor', 'monthlyPayrollDeductionList'], state),
    ),
    state,
  )
);

// payroll accessorial
const getVendorPayrollAccessorialsIsPaidOnPayrollSuccess = (state: Object, data: Object) => (
  P.$set(
    'vendor.payrollAccessorialList',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      R.path(['vendor', 'payrollAccessorialList'], state),
    ),
    state,
  )
);

export const vendorReducer = {
  // monthly payroll deduction
  [A.getVendorPayrollChargesIsPaidOnPayrollSuccess]: getVendorPayrollChargesIsPaidOnPayrollSuccess,
  // payroll accessorial
  [A.getVendorPayrollAccessorialsIsPaidOnPayrollSuccess]: getVendorPayrollAccessorialsIsPaidOnPayrollSuccess,
};
