import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// forms
import { DefaultAccessorialForm } from '../../../../forms/forms/accessorial-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { getCloGlobalSearchOptions } from '../../../../helpers/options';
// feature configs
import ConfigComponent from '../../components/config-component';
import {
  itemPageConfigEnhancer,
  defaultPageConfigEnhancer,
  withTermsAndConditionsDocument,
} from '../../hocs';
import {
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  removeDefaultOrderAccessorialRequest,
  createTermsAndConditionsDocumentRequest,
  updateTermsAndConditionsDocumentRequest,
  removeTermsAndConditionsDocumentRequest,
  createOrUpdateDefaultOrderAccessorialRequest,
} from '../../actions';
import {
  makeSelectFormValues,
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectConfigInitialValues,
  makeSelectConfigGuidsToItemsMap,
  makeSelectConfigInheritedValues,
  makeSelectDefaultOrderAccessorialList,
  makeSelectOrderQuoteTermsAndConditionsDocument,
} from '../../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(GC.CLO_CONFIG_GROUP),
  itemPageConfigEnhancer,
  withTermsAndConditionsDocument(GC.CLO_CONFIG_GROUP),
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const {
        openModal,
        closeModal,
        configOptions,
        handleClickEndLabel,
        handleAddTermsAndConditionsDocument,
        createOrUpdateDefaultOrderAccessorialRequest,
      } = props;

      if (R.equals(configName, GC.CUSTOM_ORDER_QUOTE_TERMS_AND_CONDITIONS_DOCUMENT)) {
        return handleAddTermsAndConditionsDocument();
      }

      if (R.equals(configName, GC.CUSTOM_DEFAULT_ORDER_ACCESSORIAL_CONFIG)) {
        const { orderAccessorials, accessorialConfigList } = configOptions;

        const component = (
          <DefaultAccessorialForm
            closeModal={closeModal}
            accessorialConfigOptions={orderAccessorials}
            accessorialConfigList={accessorialConfigList}
            submitAction={createOrUpdateDefaultOrderAccessorialRequest}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            height: 'auto',
            title: G.getAddTitle(['titles:additional-charges', 'Additional Charge']),
          },
        };

        return openModal(modal);
      }

      return handleClickEndLabel(configName, popupTitle);
    },
    handleEditDefaultOrderAccessorial: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        configOptions,
        createOrUpdateDefaultOrderAccessorialRequest,
      } = props;

      const { orderAccessorials, accessorialConfigList } = configOptions;

      const component = (
        <DefaultAccessorialForm
          closeModal={closeModal}
          initialValues={initialValues}
          accessorialConfigOptions={orderAccessorials}
          accessorialConfigList={accessorialConfigList}
          submitAction={createOrUpdateDefaultOrderAccessorialRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getEditTitle(['titles:additional-charges', 'Additional Charge']),
        },
      };

      return openModal(modal);
    },
    handleRemoveDefaultOrderAccessorial: ({ removeDefaultOrderAccessorialRequest }: Object) => (guid: string) =>
      removeDefaultOrderAccessorialRequest(guid),
  }),
);

const getConfigOptions = ({ formValues, configOptions }: Object) => {
  const usedGlobalSearchTypes = R.pathOr([], [GC.CLO_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES], formValues);
  const defaultCloGlobalSearchOptions = R.filter(({ value }: Object) => G.isAnyTrue(
    G.isNilOrEmpty(usedGlobalSearchTypes),
    R.includes(value, usedGlobalSearchTypes),
    R.equals(value, GC.GLOBAL_SEARCH_TYPE_ALL),
    R.includes(GC.GLOBAL_SEARCH_TYPE_ALL, usedGlobalSearchTypes),
  ), getCloGlobalSearchOptions());

  return R.assoc('defaultCloGlobalSearchOptions', defaultCloGlobalSearchOptions, configOptions);
};

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:clo'
    groupSettings={GC.CLO_CONFIG_GROUP}
    configOptions={getConfigOptions(props)}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  formValues: makeSelectFormValues()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.CLO_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.CLO_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.CLO_CONFIG_GROUP),
  defaultOrderAccessorialList: makeSelectDefaultOrderAccessorialList()(state),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.CLO_CONFIG_GROUP),
  guidsToItemsMap: makeSelectConfigGuidsToItemsMap(state, GC.CLO_CONFIG_GROUP),
  termsAndConditionsDocument: makeSelectOrderQuoteTermsAndConditionsDocument()(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  removeDefaultOrderAccessorialRequest,
  createTermsAndConditionsDocumentRequest,
  updateTermsAndConditionsDocumentRequest,
  removeTermsAndConditionsDocumentRequest,
  createOrUpdateDefaultOrderAccessorialRequest,
})(enhance(ConfigPageComponent));
