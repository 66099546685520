import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 200,
};

const fieldsWrapperStyles = {
  width: 430,
};

export const fields = {
  [GC.FIELD_CARRIER_ASSOC_COMPANY_NAME]: {
    isRequired: true,
    name: 'titles:name',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTERNAL_ID]: {
    name: 'titles:internal-id',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_SCAC]: {
    name: 'titles:scac',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_USDOT_NUMBER]: {
    isRequired: true,
    name: 'titles:usdot-number',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_DOT]: {
    name: 'titles:intrastate-dot',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_STATE]: {
    name: 'titles:intrastate-state',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_DUNS_NUMBER]: {
    name: 'titles:duns-number',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_URL]: {
    name: 'titles:url',
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_ASSOC_COMPANY_URL]: G.yupStringNotRequired.url(G.getShouldBeUrlLocaleTxt()),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_SCAC]: G.yupStringNotRequired.max(6, G.getShouldBeFromToCharLocaleTxt(0, 6)),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_NAME]: G.yupStringRequired.max(250, G.getShouldBeFromToCharLocaleTxt(1, 250)),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_USDOT_NUMBER]: G.yupStringRequired.max(12, G.getShouldBeFromToCharLocaleTxt(1, 12)),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTERNAL_ID]: G.yupStringNotRequired.max(30, G.getShouldBeFromToCharLocaleTxt(0, 30)),
});

export const associatedCompanySettings = {
  fields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'associatedCompany',
  itemTitleArr: ['titles:associated-company', 'Associated Company'],
  formGroupTitleArr: ['titles:associated-companies', 'Associated Companies'],
  endpoints: {
    list: 'carrierAssocCompanyList',
    createOrUpdate: 'carrierAssocCompany',
    remove: 'getCarrierAssocCompanyEndpoint',
  },
};
