import React from 'react';
import * as R from 'ramda';
import {
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ComposedChart,
} from 'recharts';
// feature dashboards
import { CHART_COLORS } from '../constants';
import { getDataForAreaAndLineChartFromDashboard } from '../helpers';
import {
  xAxisCommonProps,
  yAxisCommonProps,
  legendCommonProps,
  cartesianGridCommonProps,
  largeChartWrapperCommonProps,
} from '../settings';
//////////////////////////////////////////////////

const getWrapperProps = (cardType: any) => {
  if (R.equals(cardType, 'middle')) {
    return {
      width: 500,
      height: 340,
      margin: {
        top: 20,
        right: 15,
        bottom: 20,
        left: 10,
      },
    };
  }

  return largeChartWrapperCommonProps;
};

const lineCommonProps = {
  dot: { r: 3 },
  strokeWidth: 3,
  type: 'monotone',
  activeDot: { r: 6 },
};

const ticks = [0, 500, 1000, 1500, 2000];

const domain = [0, 2000];

const Composed2LineChart = (props: Object) => {
  const {
    data,
    ticksY,
    domainY,
    cardType,
    lineName,
    line2Name,
    lineDataKey,
    line2DataKey,
    tickFormatter,
  } = props;

  const dataToUse = getDataForAreaAndLineChartFromDashboard(data);

  const wrapperProps = getWrapperProps(cardType);

  return (
    <ComposedChart
      data={dataToUse}
      {...wrapperProps}
    >
      <CartesianGrid {...cartesianGridCommonProps} />
      <XAxis
        dataKey='name'
        {...xAxisCommonProps}
      />
      <YAxis
        ticks={ticksY || ticks}
        allowDataOverflow={true}
        domain={domainY || domain}
        tickFormatter={tickFormatter}
        {...yAxisCommonProps}
      />
      <Tooltip />
      <Legend {...legendCommonProps} />
      <Line
        name={lineName}
        dataKey={lineDataKey}
        fill={CHART_COLORS.blue1}
        stroke={CHART_COLORS.blue1}
        {...lineCommonProps}
      />
      <Line
        name={line2Name}
        dataKey={line2DataKey}
        fill={CHART_COLORS.green1}
        stroke={CHART_COLORS.green1}
        {...lineCommonProps}
      />
    </ComposedChart>
  );
};

export default Composed2LineChart;
