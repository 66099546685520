import { createSelector } from 'reselect';
// feature customer-portal
import { initialState } from './reducer';
//////////////////////////////////////////////////

const store = (state: Object) => state.customerPortal;

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => itemList,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectLocations = () => createSelector(
  store,
  ({ locations }: Object) => locations,
);

const makeSelectMapOpened = () => createSelector(
  store,
  ({ mapOpened }: Object) => mapOpened,
);

const makeSelectShowBooked = () => createSelector(
  store,
  ({ showBooked }: Object) => showBooked,
);

const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectTimerStarted = () => createSelector(
  store,
  ({ timerStarted }: Object) => timerStarted,
);

const makeSelectTableHeight = () => createSelector(
  store,
  ({ tableHeight }: Object) => tableHeight,
);

const makeSelectIsInitialState = () => createSelector(
  store,
  (state: Object) => Object.is(state, initialState),
);

export {
  makeSelectItemList,
  makeSelectLocations,
  makeSelectMapOpened,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectShowBooked,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectTableHeight,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTimerStarted,
  makeSelectIsInitialState,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
};
