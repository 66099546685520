import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withHandlers,
} from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// forms
import { LocationFormWithSearch } from '../../../forms/forms/location-form-with-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature branch
import { makeSelectEditBranchBillToLocation } from '../selectors';
import {
  createBranchBillToLocationRequest,
  deleteBranchBillToLocationRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleSubmit: (props: Object) => (location: Object) => {
      const {
        branchGuid,
        initialValues,
        createBranchBillToLocationRequest } = props;
      const data = R.assoc(GC.BRANCH_GUID, branchGuid, location);
      if (G.isNilOrEmpty(initialValues)) {
        createBranchBillToLocationRequest({
          data,
          method: 'post',
        });
      } else {
        createBranchBillToLocationRequest({
          data,
          method: 'put',
        });
      }
    },
  }),
  withHandlers({
    handleOpenLocationForm: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        branchGuid,
        handleSubmit,
        initialValues,
        locationTypeOptions,
      } = props;

      const mode = G.ifElse(
        G.isNilOrEmpty(initialValues),
        G.getAddTitle,
        G.getEditTitle,
      )(['titles:bill-to', 'Bill To']);

      const initialValuesWithLocationTypeGuid = G.ifElse(
        G.isNotNilAndNotEmpty(initialValues),
        R.assoc(
          GC.FIELD_LOCATION_TYPE,
          R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues),
          initialValues,
        ),
        initialValues,
      );

      const optionsForSelect = { [GC.FIELD_LOCATION_TYPE]: locationTypeOptions };

      const cancelStyles = { mr: 'auto' };

      const modalContent = (
        <LocationFormWithSearch
          mode={mode}
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          returnFullLocation={true}
          cancelStyles={cancelStyles}
          submitHandler={handleSubmit}
          submitAndSaveLocation={true}
          optionsForSelect={optionsForSelect}
          formType={GC.LOCATION_FORM_TYPE_BILL_TO}
          initialValues={initialValuesWithLocationTypeGuid}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '87vh',
        },
      };

      openModal(modal);
    },
    handleDelete: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        initialValues,
        deleteBranchBillToLocationRequest } = props;
      const modalContent = getConfirmModal({
        cancelAction: props.closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => {
          deleteBranchBillToLocationRequest(R.path(['guid'], initialValues));
          closeModal();
        },
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:bill-to-location', 'Bill To Location')}?`,
      });
      openModal(modalContent);
    },
  }),
  pure,
);

export const BillToLocation = (props: Object) => {
  const {
    handleDelete,
    initialValues,
    handleOpenLocationForm } = props;
  const addIcon = G.ifElse(
    G.isNotNilAndNotEmpty(initialValues),
    'renderEditIcon',
    'plusRound',
  );
  const darkBlue = G.getTheme('colors.dark.blue');

  return (
    <Flex zIndex={11} height={40}>
      <Box pl={15} width={320}>{G.getWindowLocale('titles:bill-to-location', 'Bill To Location')}</Box>
      <Box pl={10} maxWidth='calc(100% - 400px)'>
        {G.isNotNilAndNotEmpty(initialValues) && G.renderFullAddressString(R.or(initialValues, {}))}
        {G.isNilOrEmpty(initialValues) && G.getWindowLocale('titles:add-location', 'Add Location...')}
      </Box>
      <Box pl={15} cursor='pointer' onClick={handleOpenLocationForm}>
        {I[addIcon](darkBlue)}
      </Box>
      {
        G.isNotNilAndNotEmpty(props.initialValues) &&
        <Box pl={15} cursor='pointer' onClick={handleDelete}>
          {I.trash(darkBlue)}
        </Box>
      }
    </Flex>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  initialValues: makeSelectEditBranchBillToLocation(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  createBranchBillToLocationRequest,
  deleteBranchBillToLocationRequest,
})(enhance(BillToLocation));
