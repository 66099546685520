import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
export const createGeoFencingZoneRequest = createAction('createGeoFencingZoneRequest');
export const toggleGeoFencingZoneDetails = createAction('toggleGeoFencingZoneDetails');
export const deleteGeoFencingZoneRequest = createAction('deleteGeoFencingZoneRequest');
export const deleteGeoFencingZoneSuccess = createAction('deleteGeoFencingZoneSuccess');
export const receivedGeoFencingZoneSuccess = createAction('receivedGeoFencingZoneSuccess');
export const createGeoFencingZoneExportRequest = createAction('createGeoFencingZoneExportRequest');
export const receivedGeoFencingZoneListSuccess = createAction('receivedGeoFencingZoneListSuccess');
