import { useDispatch } from 'react-redux';
import React, { memo, Fragment } from 'react';
// components
import { openModal } from '../../../../components/modal/actions';
// features
import { CreateTruck, CreateDriver, CreateTrailer } from '../../../fleet-list/components/create-form';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { ActionButton } from '../../../../ui';
// feature fleet-profile
import { createFleetItemRequest } from '../../actions';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');
const lightBlueColor = G.getTheme('colors.light.blue');

const buttonStyles = {
  mr: 10,
  p: '5px',
  height: 30,
  width: 120,
  bgColor: 'none',
  fontWeight: 'bold',
  border: '1px solid',
  borderRadius: '5px',
  background: whiteColor,
  textColor: lightBlueColor,
  borderColor: darkBlueColor,
  textTransform: 'uppercase',
};

const CreateFleet = memo(() => {
  const dispatch = useDispatch();

  const handleCreateFleetEntityByType = (type: string) => {
    const submitAction = ({ values }: Object) => dispatch(createFleetItemRequest({ type, values }));

    const componentsMap = {
      truck: <CreateTruck createFleetItemRequest={submitAction} />,
      driver: <CreateDriver createFleetItemRequest={submitAction} />,
      trailer: <CreateTrailer createFleetItemRequest={submitAction} />,
    };

    const component = G.getPropFromObject(type, componentsMap);

    const titleMap = {
      truck: G.getWindowLocale('actions:add-truck', 'Add Truck'),
      driver: G.getWindowLocale('actions:add-driver', 'Add Driver'),
      trailer: G.getWindowLocale('actions:add-trailer', 'Add Trailer'),
    };

    const title = G.getPropFromObject(type, titleMap);

    const modal = {
      p: 15,
      component,
      options: { title },
    };

    dispatch(openModal(modal));
  };

  return (
    <Fragment>
      <ActionButton {...buttonStyles} onClick={() => handleCreateFleetEntityByType('driver')}>
        {G.getWindowLocale('actions:add-driver', 'Add Driver')}
      </ActionButton>
      <ActionButton {...buttonStyles} onClick={() => handleCreateFleetEntityByType('truck')}>
        {G.getWindowLocale('actions:add-truck', 'Add Truck')}
      </ActionButton>
      <ActionButton {...buttonStyles} onClick={() => handleCreateFleetEntityByType('trailer')}>
        {G.getWindowLocale('actions:add-trailer', 'Add Trailer')}
      </ActionButton>
    </Fragment>
  );
});

export default CreateFleet;
