import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const initialValues = {
  [GC.FIELD_LOCATION]: null,
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: null,
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: null,
};

export const validationSchema = {
  [GC.FIELD_LOCATION]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

const commonProps = {
  width: 250,
  labelPl: '0px',
  errorTop: '103%',
  errorLeft: '0px',
  isRequired: true,
  labelWidth: '100%',
  jc: 'space-between',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
  errorPosition: 'absolute',
};

export const getFields = (locationOptions: Array) => [
  {
    ...commonProps,
    width: 600,
    afterTop: 13,
    afterRight: 13,
    type: 'select',
    options: locationOptions,
    fieldName: GC.FIELD_LOCATION,
    loc: 'titles:select-terminal-drop-location',
  },
  {
    ...commonProps,
    zIndex: 12,
    iconColor: '',
    type: 'calendar',
    isClearable: true,
    timeSelection: true,
    loc: 'titles:early-date',
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
  },
  {
    ...commonProps,
    zIndex: 12,
    iconColor: '',
    type: 'calendar',
    isClearable: true,
    timeSelection: true,
    loc: 'titles:late-date',
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
  },
];
