import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
import { closeModal, openModal } from '../../../components/modal/actions';
// features
import AuthHoc from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
// feature branch
import { makeSelectBranchesTree } from '../selectors';
import {
  getBranchRequest,
  sendDeleteRequest,
  sendRestoreRequest,
  sendSuspendRequest,
  switchBranchRequest,
  sendActivateRequest,
  sendUnsuspendRequest,
  sendDeactivateRequest,
  openAddBranchModalRequest,
  receivedBranchesTreeSuccess } from '../actions';
//////////////////////////////////////////////////

export const renderConfirmationModal = (props: Object) => {
  const {
    ent,
    action,
    sendDeleteRequest,
    sendRestoreRequest,
    sendSuspendRequest,
    sendActivateRequest,
    sendUnsuspendRequest,
    sendDeactivateRequest } = props;
  const { guid } = ent;
  const requestEndpoints = {
    delete: () => sendDeleteRequest(guid),
    restore: () => sendRestoreRequest(guid),
    suspend: () => sendSuspendRequest(guid),
    activate: () => sendActivateRequest(guid),
    unsuspend: () => sendUnsuspendRequest(guid),
    deactivate: () => sendDeactivateRequest(guid),
  };
  const actionName = {
    delete: G.getWindowLocale('actions:delete', 'Delete'),
    restore: G.getWindowLocale('actions:restore', 'Restore'),
    suspend: G.getWindowLocale('actions:suspend', 'Suspend'),
    activate: G.getWindowLocale('actions:activate', 'Activate'),
    unsuspend: G.getWindowLocale('actions:unsuspend', 'Unsuspend'),
    deactivate: G.getWindowLocale('actions:deactivate', 'Deactivate'),
  };
  const confirmationText = {
    activate: G.getWindowLocale('messages:activate-confirmation-text', 'Are you sure you want activate'),
    restore: G.getWindowLocale('messages:restore-confirmation-text', 'Are you sure you want to restore'),
    delete: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
    deactivate: G.getWindowLocale('messages:deactivate-confirmation-text', 'Are you sure you want deactivate'),
    suspend: G.getWindowLocale('messages:enterprise:suspend-confirmation-text', 'Are you sure you want suspend'),
    unsuspend: G.getWindowLocale(
      'messages:enterprise:unsuspend-confirmation-text',
      'Are you sure you want unsuspend',
    ),
  };
  const confirmationContent = (
    <ConfirmComponent
      name={R.prop(GC.FIELD_BRANCH_NAME, ent)}
      textLocale={R.prop(action, confirmationText)} />
  );
  const modal = {
    component: confirmationContent,
    options: {
      width: '600px',
      height: '160px',
      controlButtons: [
        {
          type: 'button',
          name: R.prop(action, actionName),
          action: () => {
            closeModal();
            requestEndpoints[action]();
          },
        },
      ],
    },
  };
  return modal;
};

export const enhance = compose(
  AuthHoc([PC.BRANCH_WRITE]),
  withHandlers({
    handleAddChild: ({ parent, closeModal, closeFixedPopup, openAddBranchModalRequest }: Object) => () => {
      closeFixedPopup();
      closeModal();
      openAddBranchModalRequest({
        parentGuid: R.prop(GC.FIELD_GUID, parent),
        type: GC.BRANCH_TYPE_CUSTOMER_STORED_VALUE,
      });
    },
    handleEdit: ({ parent, closeModal }: Object) => () => {
      closeModal();
      G.goToRoute(routesMap.getEditBranchRoute(parent.guid));
    },
    handleSwitch: ({ parent, closeModal, closeFixedPopup, switchBranchRequest }: Object) => () => {
      closeFixedPopup();
      closeModal();
      switchBranchRequest(parent.guid);
    },
    handleEntState: (props: Object) => (ent: Object, type: string) => {
      props.closeFixedPopup();
      const actions = {
        delete: G.ifElse(ent.deleted, 'restore', 'delete'),
        activation: G.ifElse(ent.active, 'deactivate', 'activate'),
        suspension: G.ifElse(ent.suspended, 'unsuspend', 'suspend'),
      };
      const modal = renderConfirmationModal({...props, ent, action: actions[type]});
      props.closeModal();
      props.openModal(modal);
    },
  }),
);

export const RowActionsComponent = (props: Object) => {
  const {
    parent,
    prefix,
    handleEdit,
    handleSwitch,
    handleAddChild,
    handleEntState,
    handleShowChild,
    closeFixedPopup,
    handleShareWithDivisions,
  } = props;

  const iconColor = G.getTheme('colors.dark.blue');

  const options = (branch: Object) => ([
    {
      action: handleEdit,
      frontIcon: I.pencil(iconColor),
      permissions: [PC.BRANCH_WRITE],
      text: G.getWindowLocale('actions:edit', 'Edit'),
    },
    {
      action: handleSwitch,
      frontIcon: I.switchCompany(iconColor),
      permissions: [PC.BRANCH_READ, PC.BRANCH_WRITE],
      text: G.getWindowLocale('actions:switch-company', 'Switch Company'),
    },
    {
      action: handleAddChild,
      permissions: [PC.BRANCH_WRITE],
      frontIcon: I.addChild(iconColor),
      text: G.getWindowLocale('actions:customer', 'Add Customer'),
    },
    {
      action: () => handleEntState(branch, 'activation'),
      permissions: [PC.ACTIVATE_BRANCH_EXECUTE],
      text: G.ifElse(
        branch.active,
        G.getWindowLocale('actions:deactivate', 'Deactivate'),
        G.getWindowLocale('actions:activate', 'Activate'),
      ),
      frontIcon: G.ifElse(
        branch.active,
        I.crossInRound(iconColor),
        I.checkMarkInRound(iconColor),
      ),
    },
    {
      action: () => handleEntState(branch, 'suspension'),
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      text: G.ifElse(
        branch.suspended,
        G.getWindowLocale('actions:unsuspend', 'Unsuspend'),
        G.getWindowLocale('actions:suspend', 'Suspend'),
      ),
      frontIcon: G.ifElse(
        branch.suspended,
        I.playInRound(iconColor),
        I.pauseInRound(iconColor),
      ),
    },
    {
      action: () => handleEntState(branch, 'delete'),
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      text: G.ifElse(
        branch.deleted,
        G.getWindowLocale('actions:restore', 'Restore'),
        G.getWindowLocale('actions:delete', 'Delete'),
      ),
      frontIcon: G.ifElse(
        branch.deleted,
        I.reloadCircle(iconColor),
        I.trash(iconColor),
      ),
    },
  ]);
  const childTreeOption = {
    action: handleShowChild,
    frontIcon: I.grantTo(iconColor),
    permissions: [PC.BRANCH_READ, PC.BRANCH_WRITE],
    text: G.getWindowLocale('actions:show-child-tree-action', 'Show Child Tree'),
  };
  const shareWithDivisionsOption = {
    permissions: [PC.BRANCH_WRITE],
    frontIcon: I.grantTo(iconColor),
    text: G.getWindowLocale('actions:share-with-divisions', 'Share With Divisions'),
    action: () => {
      closeFixedPopup();
      handleShareWithDivisions(G.getGuidFromObject(parent));
    },
  };
  let elementActions = options(parent);
  if (R.propEq(GC.BRANCH_TYPE_ENUM_CUSTOMER, GC.BRANCH_TYPE, parent)) {
    elementActions = R.prepend(shareWithDivisionsOption, elementActions);
  }
  if (R.equals(prefix, 'list')) {
    elementActions = R.prepend(childTreeOption, elementActions);
  }
  return <ActionsElement options={elementActions} />;
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  treeBranches: makeSelectBranchesTree(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  getBranchRequest,
  sendDeleteRequest,
  sendRestoreRequest,
  sendSuspendRequest,
  sendActivateRequest,
  switchBranchRequest,
  sendUnsuspendRequest,
  sendDeactivateRequest,
  openAddBranchModalRequest,
  receivedBranchesTreeSuccess,
})(enhance(RowActionsComponent));
