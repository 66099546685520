import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { makeSelectDocumentTemplates } from '../../../../common/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import * as H from '../../helpers';
import ConfigComponent from '../../components/config-component';
import { getDefaultDropdownConfigFormComponent } from '../../components/dropdown-config-modal';
import {
  itemPageConfigEnhancer,
  defaultPageConfigEnhancer,
  withTermsAndConditionsDocument,
} from '../../hocs';
import {
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectConfigInitialValues,
  makeSelectDeactivationRuleList,
  makeSelectConfigInheritedValues,
  makeSelectOnboardingPackageList,
  makeSelectCarrierInvoiceStatuses,
  makeSelectCarrierScoreCardConfigList,
  makeSelectTermsAndConditionsDocument,
  makeSelectOnboardingPackageDocumentList,
} from '../../selectors';
import {
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  removeCarrierScorecardConfigRequest,
  deleteCarrierDeactivationRuleRequest,
  createCarrierDeactivationRuleRequest,
  updateCarrierDeactivationRuleRequest,
  deleteCarrierOnboardingPackageRequest,
  removeTermsAndConditionsDocumentRequest,
  updateTermsAndConditionsDocumentRequest,
  createTermsAndConditionsDocumentRequest,
  createOrUpdateCarrierScorecardConfigRequest,
  createOrUpdateCarrierOnboardingPackageRequest,
  deleteCarrierOnboardingPackageDocumentRequest,
  createOrUpdateCarrierOnboardingPackageDocumentRequest,
} from '../../actions';
// carrier
import DeactivationRuleForm from './components/deactivation-rule-form';
import OnboardingPackageForm from './components/onboarding-package-form';
import CarrierScorecardConfigForm from './components/carrier-scorecard-config-form';
import OnboardingPackageDocumentForm from './components/onboarding-package-document-form';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(GC.CARRIER_CONFIG_GROUP),
  itemPageConfigEnhancer,
  withTermsAndConditionsDocument(GC.CARRIER_CONFIG_GROUP),
  withHandlers({
    handleRemoveOnboardingPackage: ({ deleteCarrierOnboardingPackageRequest }: Object) => (guid: string) =>
      deleteCarrierOnboardingPackageRequest(guid),
    handleRemoveOnboardingPackageDocument: (props: Object) => (guid: Object) =>
      props.deleteCarrierOnboardingPackageDocumentRequest(guid),
    handleRemoveCarrierScorecardConfig: ({ removeCarrierScorecardConfigRequest }: Object) => (guid: string) =>
      removeCarrierScorecardConfigRequest(guid),
    handleEditOnboardingPackage: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        createOrUpdateCarrierOnboardingPackageRequest,
      } = props;

      const component = (
        <OnboardingPackageForm
          method='put'
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateCarrierOnboardingPackageRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:edit-onboarding-package', 'Edit Onboarding Package'),
        },
      };

      openModal(modal);
    },
    handleEditOnboardingPackageDocument: (props: Object) => (entity: Object) => {
      const {
        openModal,
        closeModal,
        createOrUpdateCarrierOnboardingPackageDocumentRequest,
      } = props;

      const initialValues = R.assoc(
        GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE,
        R.prop(GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_FILE_NAME, entity),
        entity,
      );

      const component = (
        <OnboardingPackageDocumentForm
          closeModal={closeModal}
          initialValues={initialValues}
          endpoint='updateCarrierOnboardingPackageDocument'
          submitAction={createOrUpdateCarrierOnboardingPackageDocumentRequest}
          primaryObjectGuid={R.path(['onboardingPackageList', 0, GC.FIELD_GUID], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getEditTitle(['titles:onboarding-package-document', 'Onboarding Package Document']),
        },
      };

      openModal(modal);
    },
    handleRemoveEntity: ({ deleteCarrierDeactivationRuleRequest }: Object) => (guid: string) => (
      deleteCarrierDeactivationRuleRequest(guid)
    ),
    handleEditEntity: (props: Object) => (entity: Object, popupTitle: string) => {
      const { openModal, updateCarrierDeactivationRuleRequest } = props;

      const title = G.getEditTitle([popupTitle]);

      const component = (
        <DeactivationRuleForm
          isEdit={true}
          initialValues={entity}
          submitAction={updateCarrierDeactivationRuleRequest}
          insuranceTypeOptions={G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
            props,
            GC.CARRIER_INSURANCE_TYPE,
          )}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 300,
          height: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
    handleEditCarrierScorecardConfig: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        configOptions,
        carrierInvoiceStatuses,
        createOrUpdateCarrierScorecardConfigRequest,
      } = props;

      const optionsForSelect = {
        carrierInvoiceStatuses: R.or(carrierInvoiceStatuses, []),
        transportationModes: R.pathOr([], ['transportationModes'], configOptions),
        availableDocumentTypes: R.compose(
          G.addEmptyOptionToDropDown,
          R.map(R.pick([GC.FIELD_VALUE, GC.FIELD_LABEL])),
          R.pathOr([], ['configOptions', 'availableDocumentTypes']),
        )(props),
      };

      const component = (
        <CarrierScorecardConfigForm
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          submitAction={createOrUpdateCarrierScorecardConfigRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getEditTitle(['titles:score-card', 'Score Card']),
        },
      };

      openModal(modal);
    },
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const {
        openModal,
        closeModal,
        handleAddTermsAndConditionsDocument,
        createCarrierDeactivationRuleRequest,
        createOrUpdateCarrierScorecardConfigRequest,
        createOrUpdateCarrierOnboardingPackageRequest,
        createOrUpdateCarrierOnboardingPackageDocumentRequest,
      } = props;

      const title = R.or(popupTitle, 'titles:add-item');
      const modalContent = getDefaultDropdownConfigFormComponent(configName, props);

      if (R.equals(configName, GC.CARRIER_TERMS_AND_CONDITIONS_DOCUMENT)) {
        return handleAddTermsAndConditionsDocument();
      }

      if (R.equals(configName, GC.CARRIER_DEACTIVATION_RULES)) {
        const component = (
          <DeactivationRuleForm
            submitAction={createCarrierDeactivationRuleRequest}
            insuranceTypeOptions={G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
              props,
              GC.CARRIER_INSURANCE_TYPE,
            )}
          />
        );

        const title = G.getAddTitle([popupTitle]);

        const modal = {
          p: 15,
          component,
          options: {
            title,
            width: 300,
            height: 'auto',
            maxHeight: '90vh',
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CARRIER_ONBOARDING_PACKAGE)) {
        const component = (
          <OnboardingPackageForm
            method='post'
            closeModal={closeModal}
            submitAction={createOrUpdateCarrierOnboardingPackageRequest}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 400,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getAddTitle(['titles:onboarding-package', 'Onboarding Package']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CARRIER_ONBOARDING_PACKAGE_DOCUMENT)) {
        const component = (
          <OnboardingPackageDocumentForm
            closeModal={closeModal}
            endpoint='carrierOnboardingPackageDocument'
            submitAction={createOrUpdateCarrierOnboardingPackageDocumentRequest}
            primaryObjectGuid={R.path(['onboardingPackageList', 0, GC.FIELD_GUID], props)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 400,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getAddTitle(['titles:onboarding-package-document', 'Onboarding Package Document']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CARRIER_SCORECARD_CONFIG)) {
        const optionsForSelect = {
          carrierInvoiceStatuses: R.pathOr([], ['carrierInvoiceStatuses'], props),
          transportationModes: R.pathOr([], ['configOptions', 'transportationModes'], props),
          availableDocumentTypes: R.compose(
            G.addEmptyOptionToDropDown,
            R.map(R.pick([GC.FIELD_VALUE, GC.FIELD_LABEL])),
            R.pathOr([], ['configOptions', 'availableDocumentTypes']),
          )(props),
        };

        const component = (
          <CarrierScorecardConfigForm
            optionsForSelect={optionsForSelect}
            submitAction={createOrUpdateCarrierScorecardConfigRequest}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getAddTitle(['titles:score-card', 'Score Card']),
          },
        };

        return openModal(modal);
      }

      const modal = H.getModalOptions(modalContent, title);

      openModal(modal);
    },
  }),
  pure,
);

const getConfigOptions = ({ configOptions, documentTemplates }: Object) => ({
  ...configOptions,
  carrierDispatchDocumentTemplates: G.mapNameGuidToLabelValue(
    R.propOr([], GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS, documentTemplates),
  ),
});

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:carrier'
    groupSettings={GC.CARRIER_CONFIG_GROUP}
    configOptions={getConfigOptions(props)}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  documentTemplates: makeSelectDocumentTemplates()(state),
  deactivationRuleList: makeSelectDeactivationRuleList()(state),
  onboardingPackageList: makeSelectOnboardingPackageList()(state),
  carrierInvoiceStatuses: makeSelectCarrierInvoiceStatuses()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.CARRIER_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.CARRIER_CONFIG_GROUP),
  termsAndConditionsDocument: makeSelectTermsAndConditionsDocument()(state),
  carrierScorecardConfigList: makeSelectCarrierScoreCardConfigList()(state),
  initialValues: makeSelectConfigInitialValues(state, GC.CARRIER_CONFIG_GROUP),
  onboardingPackageDocumentList: makeSelectOnboardingPackageDocumentList()(state),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.CARRIER_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  removeCarrierScorecardConfigRequest,
  deleteCarrierDeactivationRuleRequest,
  updateCarrierDeactivationRuleRequest,
  createCarrierDeactivationRuleRequest,
  deleteCarrierOnboardingPackageRequest,
  createTermsAndConditionsDocumentRequest,
  removeTermsAndConditionsDocumentRequest,
  updateTermsAndConditionsDocumentRequest,
  createOrUpdateCarrierScorecardConfigRequest,
  deleteCarrierOnboardingPackageDocumentRequest,
  createOrUpdateCarrierOnboardingPackageRequest,
  createOrUpdateCarrierOnboardingPackageDocumentRequest,
})(enhance(ConfigPageComponent));
