import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { Form, FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// branch-document-form
import { getFields, defaultValues, getValidationSchemaObject } from '../settings/branch-document-form-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'BRANCH_DOCUMENT_FORM',
    mapPropsToValues: ({ initialValues, searchedValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
      searchedValues,
    ),
    validationSchema: () => Yup.lazy((values: Object) => Yup.object().shape(getValidationSchemaObject(values))),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSaveDocument, documentTypeOptions } = props;

      const documentType = G.transformGuidToRequest(values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE], documentTypeOptions);

      let data = R.assoc(GC.FIELD_DOCUMENT_DOCUMENT_TYPE, documentType, values);

      if (G.isNilOrEmpty(values[GC.FIELD_DOCUMENT_URL])) {
        data = R.omit([GC.FIELD_DOCUMENT_URL], data);
      }

      handleSaveDocument(data);
    },
  }),
  pure,
);

const getAvailableDocTypeOptions = (options: Array) => (
  G.getOptionsFromConfigValueByParentGuidOrGuid(options)
);

const BranchDocumentForm = enhance((props: Object) => {
  const { closeModal, handleSubmit, initialValues, documentTypeOptions} = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        justifyContent='center'
        fields={getFields(initialValues)}
        fileName={G.getWindowLocale('titles:select-file', 'Select File')}
        optionsForSelect={{
          [GC.AVAILABLE_DOCUMENT_TYPES]: getAvailableDocTypeOptions(documentTypeOptions),
        }}
      />
      <FormFooter
        closeModal={closeModal}
        boxStyles={{ p: 10, justifyContent: 'space-around' }}
      />
    </Form>
  );
});

export default BranchDocumentForm;
