import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, AbsoluteWrapper } from '../../../ui';
//////////////////////////////////////////////////

const InfoLengthContainer = ({ length }: Object) => (
  <AbsoluteWrapper top='0px' left='30px'>
    <Flex
      width={20}
      height={20}
      zIndex={201}
      borderRadius='50%'
      justifyContent='center'
      color={G.getTheme('colors.white')}
      bg={G.getTheme('colors.dark.blue')}
      border={`2px solid ${G.getTheme('colors.boxShadowLightGrey')}`}
    >
      {length}
    </Flex>
  </AbsoluteWrapper>
);

export default InfoLengthContainer;
