import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// forms
import SelectPrintTemplateForm from '../../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleGetIFTAXml: ({ guid, closeFixedPopup, getIFTAXmlRequest }: Object) => () => {
      G.callFunction(closeFixedPopup);
      G.callFunctionWithArgs(getIFTAXmlRequest, guid);
    },
    handleRemoveIFTA: (props: Object) => () => {
      const { guid, openModal, closeFixedPopup, removeIFTARequest } = props;

      G.callFunction(closeFixedPopup);
      const textLocale = G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this');
      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeIFTARequest(R.of(Array, guid)),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePrintIFTA: (props: Object) => () => {
      const {
        guid,
        openModal,
        closeFixedPopup,
        printIFTARequest,
      } = props;

      G.callFunction(closeFixedPopup);

      const submitAction = ({ templateGuid }: Object, format: string) => {
        const requestData = { guid, templateGuid };

        printIFTARequest({ format, requestData });
      };

      const component = (
        <SelectPrintTemplateForm
          submitAction={submitAction}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_IFTA}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'auto',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const iconColor = G.getTheme('colors.light.blue');

const getOptions = (
  handlePrintIFTA: Funtction,
  handleRemoveIFTA: Function,
  handleGetIFTAXml: Function,
) => [
  {
    action: handlePrintIFTA,
    frontIcon: I.printer(iconColor),
    text: G.getWindowLocale('actions:print', 'Print'),
  },
  {
    action: handleGetIFTAXml,
    text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    frontIcon: I.downloadDocument(iconColor, 15, 17),
  },
  {
    action: handleRemoveIFTA,
    frontIcon: I.trash(iconColor),
    text: G.getWindowLocale('titles:delete', 'Delete'),
  },
];

const RowActions = ({ handlePrintIFTA, handleGetIFTAXml, handleRemoveIFTA }: Object) =>
  <ActionsElement options={getOptions(handlePrintIFTA, handleRemoveIFTA, handleGetIFTAXml)} />;

export default enhance(RowActions);
