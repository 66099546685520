// helpers/constants
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const CONFIG_PAGE_FORM = 'CONFIG_PAGE_FORM';
export const CONFIG_DROPDOWN_POPUP_FORM = 'CONFIG_DROPDOWN_POPUP_FORM';
// external transportation modes
const RMIS_EXTERNAL_TRANSPORTATION_MODES_MAP = {
  AIR: 'Air',
  AIR_DOMESTIC: 'Air Domestic',
  AIR_FREIGHT: 'Air Freight',
  AIR_INTERNATIONAL: 'Air International',
  BARGE: 'Barge',
  BOX_TRUCKS: 'Box Trucks',
  BROKER: 'Broker',
  BULK: 'Bulk',
  BULK_GAS: 'Bulk Gas',
  BULK_NGLS: 'Bulk Ngls',
  CARTAGE: 'Cartage',
  CONTAINER_DRAYAGE_STEAMSHIP_LINE_EQUIPMENT: 'Container Drayage Steamship Line Equipment',
  DEEP_FROZEN: 'Deep Frozen',
  DEFFERRED: 'Defferred',
  DOMESTIC: 'Domestic',
  DOMESTIC_FREIGHT_SERVICES: 'Domestic Freight Services',
  DRAYAGE: 'Drayage',
  EXPEDITE: 'Expedite',
  FLATBED: 'Flatbed',
  FREIGHT_FORWARDER: 'Freight Forwarder',
  FROZEN: 'Frozen',
  HOTSHOT: 'Hotshot',
  IMDL: 'Imdl',
  INTERMODAL: 'Intermodal',
  INTERMODAL_DRY: 'Intermodal Dry',
  INTERMODAL_REEFER: 'Intermodal Reefer',
  KOSHER_BULK_DRY_HOPPER: 'Kosher Bulk Dry Hopper',
  KOSHER_BULK_LIQUID_TANKER: 'Kosher Bulk Liquid Tanker',
  LAST_MILE: 'Last Mile',
  LESS_THAN_TRUCKLOAD: 'Less Than Truckload',
  LOCAL_DELIVERY_LTL: 'Local Delivery LTL',
  LOCAL_DELIVERY_TL: 'Local Delivery Tl',
  LTL: 'LTL',
  LTL_DRY: 'LTL Dry',
  LTL_REEFER: 'LTL Reefer',
  LTL_VAN: 'LTL Van',
  MIXED: 'Mixed',
  MULTI_MODAL: 'Multi Modal',
  NON_KOSHER_BULK_DRY_HOPPER: 'Non Kosher Bulk Dry Hopper',
  NON_KOSHER_BULK_LIQUID_TANKER: 'Non Kosher Bulk Liquid Tanker',
  OCEAN: 'Ocean',
  OCEAN_FCL: 'Ocean Fcl',
  OCEAN_LCL: 'Ocean Lcl',
  OTHER: 'Other',
  OUT_OF_GAUGE: 'Out Of Gauge',
  PARCEL: 'Parcel',
  PARCEL_TEMP: 'Parcel Temp',
  PARTIAL: 'Partial',
  PARTIAL_FLATBED: 'Partial Flatbed',
  PARTIAL_TRUCKLOAD: 'Partial Truckload',
  POWER_ONLY: 'Power Only',
  RAIL: 'Rail',
  REEFER: 'Reefer',
  RENTAL: 'Rental',
  ROAD: 'Road',
  SHUTTLE: 'Shuttle',
  SMALL_PACKAGE: 'Small Package',
  SPECIALIZED_FLATBED: 'Specialized Flatbed',
  SPECIALIZED_HEAVY_HAUL: 'Specialized Heavy Haul',
  SPECIALIZED_HEAVY_HULL: 'Specialized Heavy Hull',
  SPECIAL_EQUIPMENT: 'Special Equipment',
  STORAGE: 'Storage',
  STRAIGHT_TRUCK: 'Straight Truck',
  TANKER: 'Tanker',
  TEAM: 'Team',
  THREE_PL: 'Three PL',
  TL: 'Tl',
  TL_DRY: 'Tl Dry',
  TL_FLATBED: 'Tl Flatbed',
  TL_HAZMAT_DRY: 'Tl Hazmat Dry',
  TL_HAZMAT_REEFER: 'Tl Hazmat Reefer',
  TL_REEFER: 'Tl Reefer',
  TL_VAN: 'Tl Van',
  TRAILER_ON_FLAT_CAR: 'Trailer On Flat Car',
  TRUCKLOAD: 'Truckload',
  VOLUME: 'Volume',
  VOLUME_LTL: 'Volume Ltl',
  WAREHOUSE: 'Warehouse',
  YARD_MANAGEMENT: 'Yard Management',
};

const MANITOULIN_EXTERNAL_TRANSPORTATION_MODES_MAP = {
  ROAD: 'Road',
  RAIL: 'Rail',
  HOTSHOT: 'Hotshot',
};

const GLS_EXTERNAL_TRANSPORTATION_MODES_MAP = {
  PARCEL: 'Parcel',
  FREIGHT: 'Freight',
  LOGISTICS: 'Logistics',
  DISTRIBUTION: 'Distribution',
};

export const externalTransportationModeOptionsMap = {
  [GC.CARRIER_RATE_INTEGRATION_TYPE_GLS]: GLS_EXTERNAL_TRANSPORTATION_MODES_MAP,
  [GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS]: RMIS_EXTERNAL_TRANSPORTATION_MODES_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN]: MANITOULIN_EXTERNAL_TRANSPORTATION_MODES_MAP,
};

export const qbAccountTypeEnumsMap = {
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_TOLL]: 'titles:toll-charge-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT]: 'titles:discount-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_FUEL_CARD]: 'titles:fuel-card-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL]: 'titles:line-haul-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS]: 'titles:accessorial-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE]: 'titles:invoice-charge-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ADVANCED_PAYMENT]: 'titles:advance-payment-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_PAYABLE]: 'titles:driver-payable-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_PAYABLE]: 'titles:vendor-payable-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_PAYABLE]: 'titles:carrier-payable-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_RECEIVABLE]: 'titles:customer-receivable-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_PAYABLE]: 'titles:contractor-payable-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_PAYABLE]: 'titles:owner-operator-payable-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_SERVICE_VENDOR_PAYABLE]: 'titles:service-vendor-payable-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_EXPENSE_DISTRIBUTION_LINE]: 'titles:vendor-expense-distribution-line-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_EXPENSE_DISTRIBUTION_LINE]: 'titles:driver-expense-distribution-line-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_INCOME_DISTRIBUTION_LINE]:
    'titles:customer-income-distribution-line-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_EXPENSE_DISTRIBUTION_LINE]:
    'titles:carrier-expense-distribution-line-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_EXPENSE_DISTRIBUTION_LINE]:
    'titles:contractor-expense-distribution-line-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE]:
    'titles:owner-operator-expense-distribution-line-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_SERVICE_VENDOR_EXPENSE_DISTRIBUTION_LINE]:
    'titles:service-vendor-expense-distribution-line-api-iif',
};

export const qbCustomerAccountTypeEnumsMap = {
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT]: 'titles:discount-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL]: 'titles:line-haul-api-iif',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS]: 'titles:accessorial-api-iif',
};

export const qbCustomerAccountTypeEnumsMap2 = {
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT]: 'titles:discount',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL]: 'titles:line-haul',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS]: 'titles:accessorial',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE]: 'titles:invoice-charge',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_INCOME_DISTRIBUTION_LINE]: 'titles:customer-income-distribution-line',
};

export const hiddenMenuItemOptions = [
  {
    key: 'menu:load-planner-beta',
    value: '842d0f11-2ef4-4f8d-9c42-a1c2dac13aa1',
    label: ['menu:load-planner-beta', 'Load Planner (BETA)'],
  },
  {
    key: 'menu:route-builder',
    value: '8fd67567-2add-47d7-8609-d886d12a3e69',
    label: ['menu:route-builder', 'Route Builder'],
  },
  {
    key: 'menu:dispatch',
    value: 'ce243ddb-4f32-4cb6-875b-32ab6f3fc8a1',
    label: ['menu:dispatch-submenu', 'Dispatch Submenu'],
  },
];

// mail template placeholders
export const MAIL_TEMPLATE_PLACEHOLDERS_MAP = {
  BODY: 'body',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  FAREWELL: 'farewell',
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
  SHOW_ALL_EVENTS: 'showAllEvents',
  USE_COMPANY_LOGO: 'useCompanyLogo',
};
