import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withState, lifecycle, withProps, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { RatePriceImport } from '../../new-import';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncGeoFencingZones,
  withAsyncInitialDataOnDidMount,
  withConnectModalAndLoaderActions,
} from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature carrier
import * as LC from '../constants';
import { setInitialValue } from '../helpers';
import { changeBranchRequest } from '../actions';
import CarrierRateForm from '../components/carrier-rate-form';
import AwardedLineForm from '../components/carrier-awarded-line-form';
//////////////////////////////////////////////////

export const carrierRateEnhance = compose(
  withAsyncGeoFencingZones,
  withHandlers({
    handleRemoveCarrierContractRates: (props: Object) => (selectedEntities: Array) => {
      if (G.isNilOrEmpty(selectedEntities)) return;

      const { openModal, removeCarrierContractRatesRequest } = props;

      const message = G.getWindowLocale('messages:delete-rates', 'Do you want to delete these Rates?');
      const component = <ConfirmComponent textLocale={message} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeCarrierContractRatesRequest(selectedEntities),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleSetQuickFilter: (props: Object) => () => {
      const {
        carrierContract,
        getCarrierContractRatesRequest,
        resetCarrierContractListAndPagination,
      } = props;

      const contractGuid = G.getGuidFromObject(carrierContract);

      resetCarrierContractListAndPagination();
      getCarrierContractRatesRequest({ contractGuid });
    },
    handleLoadMoreEntities: ({ carrierContract, getCarrierContractRatesRequest }: Object) => () =>
      getCarrierContractRatesRequest({ contractGuid: G.getGuidFromObject(carrierContract) }),
    handleAddCarrierRate: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        openLoader,
        equipments,
        branchGuid,
        closeLoader,
        carrierContract,
        geoFencingZoneList,
        createCarrierContractRateRequest,
      } = props;

      const modalContext = (
        <CarrierRateForm
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          equipments={equipments}
          branchGuid={branchGuid}
          closeLoader={closeLoader}
          geoFencingZoneList={geoFencingZoneList}
          submitAction={createCarrierContractRateRequest}
          contractGuid={G.getGuidFromObject(carrierContract)}
          carrierGuid={R.prop('carrierGuid', carrierContract)}
          configsNamesArray={[GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]}
        />
      );

      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 1000,
          height: 'auto',
          title: G.getWindowLocale('titles:add-rate', 'Add Rate'),
        },
      };

      openModal(modal);
    },
    handleOpenRatePriceImport: (props: Object) => () => {
      const {
        openModal,
        branchGuid,
        equipments,
        carrierContract,
        geoFencingZoneList,
        getCarrierContractRatesRequest,
        resetCarrierContractListAndPagination,
      } = props;

      const component = (
        <RatePriceImport
          equipments={equipments}
          branchGuid={branchGuid}
          geoFencingZoneList={geoFencingZoneList}
          contractGuid={G.getGuidFromObject(carrierContract)}
          configsNamesArray={[GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]}
          getCarrierContractRatesRequest={getCarrierContractRatesRequest}
          resetCarrierContractListAndPagination={resetCarrierContractListAndPagination}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: '90vw',
          height: '90vh',
          movable: false,
        },
      };

      openModal(modal);
    },
    handleEditCarrierRate: (props: Object) => async ({ guid }: Object) => {
      const {
        openModal,
        closeModal,
        openLoader,
        equipments,
        branchGuid,
        closeLoader,
        collapsedGroup,
        carrierContract,
        geoFencingZoneList,
        handleToggleFormGroup,
        accessorialConfigParams,
        handleCreateRateAssessorial,
        updateCarrierContractRateRequest,
        addOrRemoveSharedAccessorialsRequest,
      } = props;

      const rate = await sendRequest('get', endpointsMap.getCarrierRatePriceEndpoint(guid));

      const options = { params: { ratePriceGuid: guid }};

      const { data } = await sendRequest('get', endpointsMap.carrierContractRateAssessorialList, options);

      const modalContext = (
        <CarrierRateForm
          isEditMode={true}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          equipments={equipments}
          branchGuid={branchGuid}
          closeLoader={closeLoader}
          assessorials={R.or(data, [])}
          collapsedGroup={collapsedGroup}
          geoFencingZoneList={geoFencingZoneList}
          handleToggleFormGroup={handleToggleFormGroup}
          submitAction={updateCarrierContractRateRequest}
          accessorialConfigParams={accessorialConfigParams}
          contractGuid={G.getGuidFromObject(carrierContract)}
          carrierGuid={R.prop('carrierGuid', carrierContract)}
          createAssessorialAction={handleCreateRateAssessorial}
          configsNamesArray={[GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]}
          initialValues={setInitialValue(rate.data, LC.CARRIER_RATE_MAIN_FIELDS)}
          addOrRemoveSharedAccessorialsRequest={addOrRemoveSharedAccessorialsRequest}
        />
      );

      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 1000,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-rate', 'Edit Rate'),
        },
      };

      openModal(modal);
    },
    handleDeleteCarrierRate: (props: Object) => ({ name, guid }: Object) => {
      const { openModal, closeModal, deleteCarrierContractRateRequest } = props;

      const confirmationContent = (
        <ConfirmComponent
          name={name}
          textLocale={
            G.getWindowLocale('messages:delete-confirmation-rate', 'Do you want to delete this Rate?')
          }
        />
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteCarrierContractRateRequest(guid);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.cleanQuickFilter();
    },
  }),
  pure,
);

export const enhanceAwardedLine = ({ contractType }: Object) => compose(
  withHandlers({
    handleAddAwardedLine: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        createCarrierContractAwardedLineRequest,
        createCustomerContractAwardedLineRequest,
      } = props;

      const contractProp = G.ifElse(
        G.isContractTypeCarrier(contractType),
        'carrierContract',
        'customerContract',
      );

      const contractGuid = R.path([contractProp, GC.FIELD_GUID], props);

      const submitAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        createCarrierContractAwardedLineRequest,
        createCustomerContractAwardedLineRequest,
      );

      const modalContext = (
        <AwardedLineForm
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          submitAction={submitAction}
          contractGuid={contractGuid}
        />
      );

      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 1000,
          height: 'auto',
          title: G.getWindowLocale('titles:add-awarded-lane', 'Add Awarded Lane'),
        },
      };

      openModal(modal);
    },
    handleEditAwardedLine: (props: Object) => async ({ guid }: Object) => {
      const {
        openModal,
        closeModal,
        openLoader,
        branchGuid,
        closeLoader,
        accessorialConfigParams,
        addOrRemoveSharedAccessorialsRequest,
        updateCarrierContractAwardedLineRequest,
        updateCustomerContractAwardedLineRequest,
      } = props;

      const res = await sendRequest('get', endpointsMap.getCarrierAwardedLineEndpoint(guid));

      const options = { params: { awardedLineGuid: guid }};

      const { data } = await sendRequest('get', endpointsMap.carrierContractAwardedLineAssessorialList, options);

      const contractProp = G.ifElse(
        G.isContractTypeCarrier(contractType),
        'carrierContract',
        'customerContract',
      );

      const contractGuid = R.path([contractProp, GC.FIELD_GUID], props);

      const submitAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        updateCarrierContractAwardedLineRequest,
        updateCustomerContractAwardedLineRequest,
      );

      let initialValues = setInitialValue(res.data, LC.AWARDED_LINE_MAIN_FIELDS);

      if (R.isNil(R.path([LC.FIELD_CARRIER_CONTRACT_DISCOUNT], initialValues))) {
        initialValues = R.assoc(
          LC.FIELD_CARRIER_CONTRACT_DISCOUNT,
          {},
          initialValues,
        );
      }

      const modalContext = (
        <AwardedLineForm
          isEditMode={true}
          openModal={openModal}
          branchGuid={branchGuid}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          contractGuid={contractGuid}
          submitAction={submitAction}
          assessorials={R.or(data, [])}
          initialValues={initialValues}
          accessorialConfigParams={accessorialConfigParams}
          configsNamesArray={[GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]}
          addOrRemoveSharedAccessorialsRequest={addOrRemoveSharedAccessorialsRequest}
        />
      );

      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 1000,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-awarded-lane', 'Edit Awarded Lane'),
        },
      };

      openModal(modal);
    },
    handleDeleteAwardedLine: (props: Object) => ({ guid, name }: Object) => {
      const {
        openModal,
        closeModal,
        deleteCarrierContractAwardedLineRequest,
        deleteCustomerContractAwardedLineRequest,
      } = props;

      const deleteAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        deleteCarrierContractAwardedLineRequest,
        deleteCustomerContractAwardedLineRequest,
      );

      const confirmationContent = (
        <ConfirmComponent
          name={name}
          textLocale={
            G.getWindowLocale('messages:carrier:contract:delete-awarded-line', 'Are you sure you want awarded line')
          }
        />
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteAction(guid);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withAsyncGetParentBranches = compose(
  connect(null, { changeBranchRequest }),
  withState('options', 'setOptions', []),
  withHandlers({
    submitAction: ({ guid, changeBranchRequest }: Object) => (newBranchGuid: string) =>
      changeBranchRequest({ guid, [GC.NEW_BRANCH_GUID]: newBranchGuid }),
    handleGetParentBranches: ({ setOptions, carrierBranchGuid }: Object) => async () => {
      const endpoint = endpointsMap.getParentAndCustomerBranchesWithoutRootByBranchGuid(carrierBranchGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setOptions(G.mapNameGuidToLabelValue(data));
      } else {
        G.handleException('error', 'withAsyncGetParentBranches');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { parentBranches, handleGetParentBranches } = this.props;

      if (G.isNilOrEmpty(parentBranches)) handleGetParentBranches();
    },
  }),
  pure,
);

export const withCarrierListForContract = compose(
  connect(null, { openModal, closeModal, openLoader, closeLoader }),
  withProps(() => {
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const asyncOptions = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    return {
      asyncOptions,
      asyncEndpoint: endpointsMap.carrierListForContract,
    };
  }),
  withAsyncInitialDataOnDidMount,
  withProps(({ asyncInitialData }: Object) => {
    const data = R.pathOr([], ['data'], asyncInitialData);

    return {
      carrierOptions: G.mapNameGuidObjectPropsToLabelValueObject(data),
    };
  }),
  pure,
);

export const withInternalDispatchCRUD = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    handleCreate: (props: Object) => async (values: Object) => {
      const { closeModal, openLoader, closeLoader, createOrUpdateCarrierInternalDispatchSettingsSuccess } = props;

      try {
        openLoader();

        const res = await sendRequest('post', endpointsMap.carrierInternalDispatchSettings, { data: values });

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          G.showToastrMessageSimpleSuccessDefault();

          createOrUpdateCarrierInternalDispatchSettingsSuccess(data);
        } else {
          G.handleFailResponseSimple(res);
        }

        closeModal();
        closeLoader();
      } catch (error) {
        closeModal();
        closeLoader();

        G.handleException(error, 'withInternalDispatchCRUD -> handleCreate');

        G.showToastrMessageSimpleCatchDefault();
      }
    },
    handleUpdate: (props: Object) => async (values: Object) => {
      const { closeModal, openLoader, closeLoader, createOrUpdateCarrierInternalDispatchSettingsSuccess } = props;

      try {
        openLoader();

        const res = await sendRequest('put', endpointsMap.carrierInternalDispatchSettings, { data: values });

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          G.showToastrMessageSimpleSuccessDefault();

          createOrUpdateCarrierInternalDispatchSettingsSuccess(data);
        } else {
          G.handleFailResponseSimple(res);
        }

        closeModal();
        closeLoader();
      } catch (error) {
        closeModal();
        closeLoader();

        G.handleException(error, 'withInternalDispatchCRUD -> handleUpdate');

        G.showToastrMessageSimpleCatchDefault();
      }
    },
    handleDelete: (props: Object) => async (guid: string) => {
      const { openLoader, closeModal, closeLoader, removeCarrierInternalDispatchSettingsSuccess } = props;

      try {
        openLoader();

        const res = await sendRequest('delete', endpointsMap.carrierInternalDispatchSettingsByGuid(guid));

        const { status } = res;

        if (G.isResponseSuccess(status)) {
          G.showToastrMessageSimpleSuccessDefault();

          removeCarrierInternalDispatchSettingsSuccess(guid);
        } else {
          G.handleFailResponseSimple(res);
        }

        closeModal();
        closeLoader();
      } catch (error) {
        closeModal();
        closeLoader();

        G.handleException(error, 'withInternalDispatchCRUD -> handleDelete');

        G.showToastrMessageSimpleCatchDefault();
      }
    },
  }),
);
