import { call } from 'redux-saga/effects';
// helpers/constants
import * as G from '../../helpers';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

export function* getFleetConfigByGroupSaga(params: Object) {
  try {
    return yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      { params },
    );
  } catch (error) {
    yield call(G.handleException, error, 'getFleetConfigByGroupSaga');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}
