import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const registrationValidation = (value: any, form: Object, _: Object, fieldName: string) => {
  const fields = ['licensePlate', 'registrationState', 'registrationExpiredDate',
    'titleState', 'titleNumber'];
  const validate = fields.map((field: string) => (
    G.isNotNilAndNotEmpty(R.prop(field, form))
  ));
  if (R.and(validate.includes(true), fields.includes(fieldName))) {
    return G.ifElse(
      R.prop(fieldName, form),
      undefined,
      G.getRequiredLocaleTxt(),
    );
  }
};

export const customRequired = (value: string) => G.ifElse(value, undefined, ' ');

export const customIsNumeric = (value: string) => G.ifElse(G.isEmptyOrNumeric(value), ' ', undefined);

export const documentValidation = (value: any, form: Object, _: Object, fieldName: string) => {
  if (R.and(G.isNotEmpty(value), G.isNotNil(value))) {
    return undefined;
  }

  switch (fieldName) {
    case 'url':
      return G.ifElse(
        R.and(R.not(value), form.documentFilename),
        undefined,
        ' ',
      );
    case 'documentFilename':
      return G.ifElse(
        R.and(R.not(value), form.url),
        undefined,
        ' ',
      );
    default:
      return '';
  }
};

const uomByFieldName = {
  cubeType: ['cube'],
  widthUom: ['width'],
  lengthUom: ['length'],
  heightUom: ['height'],
  weightTypeGVWT: ['gvwt'],
  gvwtWeightType: ['gvwt'],
  intervalUOM: ['intervalCount'],
  weightTareType: ['tareWeight'],
  tareWeightType: ['tareWeight'],
  deckHeightType: ['deckHeight'],
  performedType: ['performedValue'],
  bulkCompVolumeType: ['bulkCompVolume'],
  fifthWheelWidthType: ['fifthWheelWidth'],
  fifthWheelHeightType: ['fifthWheelHeight'],
  'doorDimension.widthUOM': ['doorDimension', 'width'],
  'wellDimension.heightUOM': ['wellDimension', 'height'],
  'wellDimension.lengthUOM': ['wellDimension', 'length'],
  'doorDimension.heightUOM': ['doorDimension', 'height'],
  'doorDimension.doorWidthUom': ['doorDimension', 'doorWidth'],
  'internalDimension.widthUOM': ['internalDimension', 'width'],
  'externalDimension.widthUOM': ['externalDimension', 'width'],
  'doorDimension.doorHeightUom': ['doorDimension', 'doorHeight'],
  'internalDimension.lengthUOM': ['internalDimension', 'length'],
  'internalDimension.heightUOM': ['internalDimension', 'height'],
  'externalDimension.lengthUOM': ['externalDimension', 'length'],
  'externalDimension.heightUOM': ['externalDimension', 'height'],
};

export const uomValidation = (value: any, form: Object, { touch }: Object, fieldName: string) => {
  const inputValue = R.pathOr(null, uomByFieldName[fieldName], form);

  const uomValidation = R.and(G.isNotNilAndNotEmpty(inputValue), G.isNilOrEmpty(value));
  const inputValueValidation = R.and(G.isNilOrEmpty(inputValue), G.isNotNilAndNotEmpty(value));

  if (uomValidation) {
    touch(fieldName);
    return G.getWindowLocale('validation:select-uom', 'Please select UOM');
  } else if (inputValueValidation) {
    return G.getWindowLocale('validation:type-value', 'Please type value');
  }
};

export const customUomValidation = (
  value: any,
  form: Object,
  props: Object,
  fieldName: string,
) => (
  G.ifElse(uomValidation(value, form, props, fieldName), ' ', undefined)
);