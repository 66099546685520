import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature geo-fencing-location
import * as A from './actions';
import * as H from './helpers';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers();

const initialLocationState = {
  ...initialState,
  currentLocation: null,
  milestoneLogList: null,
  trailersByLocation: null,
  reportType: GC.GEO_FENCING_LOCATION_REPORT,
};

const setCurrentLocation = (state: Object, location: Object) => {
  if (G.isNilOrEmpty(location)) return P.$set('currentLocation', null, state);

  const currentLocation = H.convertLocationWithRadius(location);

  return P.$set(
    'currentLocation', currentLocation, state,
  );
};

const setTrailersByLocation = (state: Object, list: Object) => P.$set(
  'trailersByLocation', list, state,
);

const setMilestoneLogList = (state: Object, list: Object) => P.$set(
  'milestoneLogList', list, state,
);

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // location
  [A.getGeoFencingLocationSuccess]: setCurrentLocation,
  // fleet/trailers
  [A.getFleetTrailerListSuccess]: setTrailersByLocation,
  [A.getFleetTrailerRouteHistorySuccess]: setMilestoneLogList,
}, initialLocationState);
