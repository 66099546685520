import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../../components/form-buttons';
import { FormGroupTable } from '../../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../../components/form-group-title';
// forms
import { Form } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
// feature fleet/truck
import * as C from '../../constants';
import { TruckGroupWrapper } from '../ui';
import { makeSelectTruckLocations } from '../selectors';
import { locationSettings } from '../settings/column-settings';
import { withAddOrEditLocation } from '../../hocs/with-add-or-edit-location';
import { renderField, renderFormGroupWithMultipleInputs } from './render-field';
import {
  updateTruckRequest,
  deleteTruckLocationRequest,
  updateTruckLocationRequest,
  createTruckLocationRequest,
} from '../actions';
import {
  ownershipFields,
  costDetailsFields,
  termDetailsFields,
  equipmentReturnFields,
  mainSpecificationFields,
  tractorSpecificationFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const locationEnhance = withAddOrEditLocation({ fleetType: C.FLEET_TRUCK });

const RenderLocationGroup = locationEnhance((props: Object) => {
  const {
    locations,
    collapsedGroup,
    handleDeleteLocation,
    handleToggleFormGroup,
    handleClickAddOrEditLocation,
  } = props;

  return (
    <FormGroupTable
      entities={locations}
      fields={collapsedGroup}
      entitiesFields={locationSettings}
      groupName={C.GROUP_NAME_LOCATIONS}
      handleDeleteRow={handleDeleteLocation}
      handleEditRow={handleClickAddOrEditLocation}
      handleAddClick={handleClickAddOrEditLocation}
      panelTitle={G.getWindowLocale('titles:locations', 'Locations')}
      isOpened={G.getPropFromObject([C.GROUP_NAME_LOCATIONS], collapsedGroup)}
      handleToggleFormGroup={() => handleToggleFormGroup(C.GROUP_NAME_LOCATIONS)}
    />
  );
});

const renderGroup = (props: Object, group: Object) => (
  group.fields.map((field: Object, index: number) => {
    if (G.showFormField(field.showField, props.formValues)) {
      return G.ifElse(
        G.isNotNil(field.inputs),
        renderFormGroupWithMultipleInputs,
        renderField,
      )(props, field, index);
    }

    return null;
  },
));

const enhance = compose(
  withHandlers({
    handleFormSubmit: ({ updateTruckRequest }: Object) => (values: Object) => updateTruckRequest(values),
  }),
  pure,
);

const setFormGroup = ({ formValues }: Object) => (
  G.ifElse(
    R.equals(formValues.ownershipType, C.FLEET_OWNERSHIP_TYPE_OWN),
    [
      mainSpecificationFields,
      tractorSpecificationFields,
      ownershipFields,
      costDetailsFields,
    ],
    [
      mainSpecificationFields,
      tractorSpecificationFields,
      ownershipFields,
      termDetailsFields,
      costDetailsFields,
      equipmentReturnFields,
    ],
  )
);

const TruckSpecificationComponent = (props: Object) => (
  <Fragment>
    <Form minHeight='max-content' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
      {
        setFormGroup(props).map((group: Object, index: number) => (
          <TruckGroupWrapper
            key={index}
            isOpened={R.path(['collapsedGroup', group.name], props)}
          >
            <FormGroupTitleComponent
              withArrowDown={true}
              text={G.getWindowLocale(group.title)}
              isOpened={R.path(['collapsedGroup', group.name], props)}
              onToggleFormGroup={() => props.handleToggleFormGroup(group.name)}
            />
            {renderGroup(props, group)}
          </TruckGroupWrapper>
        ))
      }
      <FormButtons
        {...props}
        width='auto'
        btnText={G.getWindowLocale('actions:update', 'Update')}
      />
    </Form>
    <RenderLocationGroup {...props} />
  </Fragment>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  locations: makeSelectTruckLocations(state),
}));

export default connect(mapStateToProps, {
  updateTruckRequest,
  deleteTruckLocationRequest,
  updateTruckLocationRequest,
  createTruckLocationRequest,
})(enhance(TruckSpecificationComponent));
