import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSelectors } from '../../report-common';
// feature carrier
import {
  CARRIER_DOCUMENT_FORM,
  EDIT_GENERAL_DETAILS_FORM,
  ADD_CARRIER_INSURANCE_FORM,
} from './constants';
//////////////////////////////////////////////////

const {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  store: selectCarrierStore,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('carrier');

const selectFormStore = (state: Object) => state.form;
const selectRoleStore = (state: Object) => state.roles;

const makeSelectItemList = () => createSelector(
  selectCarrierStore,
  (state: Object) => R.sortBy(
    R.prop('index'),
    R.values(state.itemList),
  ),
);

const makeSelectRoleOptions = () => createSelector(
  selectRoleStore,
  (role: Object) => R.compose(
    R.map(({ name, guid }: Object) => ({ label: name, value: guid })),
    R.values,
  )(role.roleList),
);

const makeSelectCarrierActiveTab = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.activeTab,
);

const makeSelectCarrierConfigs = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => ({
    emailGroup: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      carrier.carrierConfigs,
      GC.CARRIER_EMAIL_GROUP,
    ),
    defaultBranchCurrency: G.getConfigValueFromStore(
      GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
      carrier.generalConfigs,
    ),
    insuranceType: G.mapDropdownConfigWithParentGuid(
      carrier.carrierConfigs, GC.CARRIER_INSURANCE_TYPE,
    ),
    documentType: G.mapDropdownConfigWithParentGuid(
      carrier.carrierConfigs, GC.CARRIER_DOCUMENT_TYPE,
    ),
    deactivateType: G.mapDropdownConfigWithParentGuid(
      carrier.carrierConfigs, GC.CARRIER_DEACTIVATED_TYPE,
    ),
    transportationMode: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      carrier.generalConfigs, GC.GENERAL_MODE_TRANSPORTATION,
    ),
    serviceType: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      carrier.generalConfigs, GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
    ),
    equipmentType: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      carrier.generalConfigs, GC.GENERAL_EQUIPMENTS,
    ),
    locationType: G.mapDropdownConfigWithParentGuid(
      carrier.templateConfigs, GC.TEMPLATES_LOCATION_TYPE,
    ),
    carrierSequences: G.addEmptyOptionToDropDown(R.map((item: Object) => ({
      value: item.guid,
      label: item.displayName,
    }), carrier.carrierSequences)),
    carrierReferenceTypes: G.addEmptyOptionToDropDown(R.map((item: Object) => ({
      label: item.name,
      value: item.guid,
    }), carrier.carrierReferenceTypes)),
  }),
);

const makeSelectCollapsedGroup = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.collapsedGroup,
);

const makeSelectCarrierBranchGuid = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.carrierDetails[GC.FIELD_BRANCH_GUID],
);

const makeSelectCarrierDetails = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => ({
    ...carrier.carrierDetails,
    terminal: carrier.terminalDetails,
    carrierFinance: carrier.carrierFinance,
    terminalFinance: carrier.terminalFinance,
  }),
);

const makeSelectCarrierGuid = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.carrierDetails.guid,
);

const makeSelectDispatchConfigs = () => createSelector(
  selectCarrierStore,
  ({ dispatchConfigs }: Object) => dispatchConfigs,
);

const makeSelectCarrierRefList = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier[GC.UI_FIELD_REFERENCES],
);

const makeSelectCarrierContracts = () => createSelector(
  selectCarrierStore,
  ({ carrierContracts }: Object) => carrierContracts,
);

const makeSelectCarrierContractAwardedLines = () => createSelector(
  selectCarrierStore,
  ({ carrierContractAwardedLines }: Object) => carrierContractAwardedLines,
);

const makeSelectCustomerContractAwardedLines = () => createSelector(
  selectCarrierStore,
  ({ customerContractAwardedLines }: Object) => customerContractAwardedLines,
);

const makeSelectCarrierContract = () => createSelector(
  selectCarrierStore,
  ({ carrierContract }: Object) => carrierContract,
);

const makeSelectCustomersContract = () => createSelector(
  selectCarrierStore,
  ({ customersContract }: Object) => customersContract,
);

const makeSelectCustomerContract = () => createSelector(
  selectCarrierStore,
  ({ customerContract }: Object) => customerContract,
);

const makeSelectCarrierInsurances = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.insurances,
);

const makeSelectCarrierAssocCompanies = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.assocCompanies,
);

const makeSelectCarrierFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => R.pathOr({}, [EDIT_GENERAL_DETAILS_FORM, 'values'], form),
);

const makeSelectCarrierDocFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => R.pathOr({}, [CARRIER_DOCUMENT_FORM, 'values'], form),
);

const makeSelectCarrierInsuranceFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => R.pathOr({}, [ADD_CARRIER_INSURANCE_FORM, 'values'], form),
);

const makeSelectCarrierDocs = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.documents,
);

const makeSelectCarrierTerminals = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.terminals,
);

const makeSelectTerminalContacts = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.terminalContacts,
);

const makeSelectCarrierCertificates = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.certificates,
);

const makeSelectTerminalFinance = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.terminalFinance,
);

const makeSelectTerminalPayLocations = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.terminalPayLocations,
);

const makeSelectCarrierFinance = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.carrierFinance,
);

const makeSelectCarrierPayLocations = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.carrierPayLocations,
);

const makeSelectCarrierAssessorials = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.carrierAssessorials,
);

const makeSelectContractVariableValues = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.variableValues,
);

const makeSelectCarrierContractAssessorials = () => createSelector(
  selectCarrierStore,
  (carrier: Object) => carrier.carrierContractAssessorials,
);

const makeSelectCarrierPortalLoginUsers = () => createSelector(
  selectCarrierStore,
  ({ carrierPortalLoginUsers }: Object) => carrierPortalLoginUsers,
);


const makeSelectMailList = () => createSelector(
  selectCarrierStore,
  ({ mailList }: Object) => mailList,
);

const makeSelectOnboardingPackage = () => createSelector(
  selectCarrierStore,
  ({ sendPackage }: Object) => sendPackage,
);

const makeSelectCustomerContractAssessorials = () => createSelector(
  selectCarrierStore,
  ({ customerContractAssessorials }: Object) => customerContractAssessorials,
);

const makeSelectSafety = () => createSelector(
  selectCarrierStore,
  ({ safety }: Object) => safety,
);

const makeSelectCarrierDetailsUSDotNumber = () => createSelector(
  selectCarrierStore,
  ({ carrierDetails }: Object) => R.prop(GC.FIELD_CARRIER_USDOT_NUMBER, carrierDetails),
);

const makeSelectCarrierContractRate = () => createSelector(
  selectCarrierStore,
  ({ carrierContractRate }: Object) => carrierContractRate,
);

const makeSelectActivePage = () => createSelector(
  selectCarrierStore,
  ({ activePage }: Object) => activePage,
);

const makeSelectAdditionalContactList = () => createSelector(
  selectCarrierStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['additionalContactList']),
  ),
);

const makeSelectIntegrationInfoList = () => createSelector(
  selectCarrierStore,
  ({ integrationInfoList }: Object) => integrationInfoList,
);

const makeSelectIntegrationAuditList = () => createSelector(
  selectCarrierStore,
  ({ integrationAuditList }: Object) => integrationAuditList,
);

const makeSelectCarrierIntegrationOptions = () => createSelector(
  selectCarrierStore,
  ({ carrierIntegrationOptions }: Object) => carrierIntegrationOptions,
);

const makeSelectAccessorialConfigParams = () => createSelector(
  selectCarrierStore,
  R.pick(['accessorialConfigList', 'accessorialConfigOptions']),
);

const makeSelectContractBillTo = () => createSelector(
  selectCarrierStore,
  ({ contractBillTo }: Object) => contractBillTo,
);

const makeSelectLoadBoardIntegrationList = () => createSelector(
  selectCarrierStore,
  ({ loadBoardIntegrationList }: Object) => loadBoardIntegrationList,
);

const makeSelectLoadBoardSynchronizationParams = () => createSelector(
  selectCarrierStore,
  ({ parentBranchGuids, loadBoardIntegrationList }: Object) => {
    if (G.isNilOrEmpty(loadBoardIntegrationList)) {
      return {
        [GC.FIELD_CARRIER_ADDED_TO_NETWORK]: false,
        [GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW]: false,
      };
    }

    const indexed = R.indexBy(R.prop(GC.BRANCH_GUID), loadBoardIntegrationList);

    const mapped = R.compose(
      R.map((item: string) => G.getPropFromObject(item, indexed)),
      R.filter((item: string) => R.has(item, indexed)),
    )(parentBranchGuids);

    const addedToNetwork = R.pathOr(false, [0, GC.FIELD_CARRIER_ADDED_TO_NETWORK], mapped);
    const allowedBookItNow = R.pathOr(false, [0, GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW], mapped);

    return { addedToNetwork, allowedBookItNow };
  },
);

const makeSelectAvailableForCarrierLoadBoards = () => createSelector(
  selectCarrierStore,
  ({ availableForCarrierLoadBoards }: Object) => availableForCarrierLoadBoards,
);

const makeSelectCarrierPaymentTerms = () => createSelector(
  selectCarrierStore,
  ({ carrierPaymentTerms }: Object) => carrierPaymentTerms,
);

const makeSelectCarrierInternalDispatchSettings = () => createSelector(
  selectCarrierStore,
  ({ carrierInternalDispatchSettings }: Object) => carrierInternalDispatchSettings,
);

export {
  makeSelectSafety,
  makeSelectItemList,
  selectCarrierStore,
  makeSelectMailList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectActivePage,
  makeSelectListLoading,
  makeSelectRoleOptions,
  makeSelectCarrierGuid,
  makeSelectCarrierDocs,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectCarrierFinance,
  makeSelectContractBillTo,
  makeSelectCarrierRefList,
  makeSelectCarrierConfigs,
  makeSelectCollapsedGroup,
  makeSelectCarrierDetails,
  makeSelectTitleSortValues,
  makeSelectDispatchConfigs,
  makeSelectTerminalFinance,
  makeSelectCarrierContract,
  makeSelectCustomerContract,
  makeSelectCarrierTerminals,
  makeSelectTerminalContacts,
  makeSelectCarrierActiveTab,
  makeSelectAvailableReports,
  makeSelectCarrierContracts,
  makeSelectTableTitleFilters,
  makeSelectCarrierBranchGuid,
  makeSelectCarrierInsurances,
  makeSelectCustomersContract,
  makeSelectCarrierFormValues,
  makeSelectOnboardingPackage,
  makeSelectCarrierContractRate,
  makeSelectIntegrationInfoList,
  makeSelectCarrierPayLocations,
  makeSelectCarrierCertificates,
  makeSelectCarrierAssessorials,
  makeSelectCarrierPaymentTerms,
  makeSelectTerminalPayLocations,
  makeSelectCarrierDocFormValues,
  makeSelectIntegrationAuditList,
  makeSelectAdditionalContactList,
  makeSelectCarrierAssocCompanies,
  makeSelectContractVariableValues,
  makeSelectCarrierPortalLoginUsers,
  makeSelectAccessorialConfigParams,
  makeSelectLoadBoardIntegrationList,
  makeSelectCarrierDetailsUSDotNumber,
  makeSelectCarrierIntegrationOptions,
  makeSelectCarrierInsuranceFormValues,
  makeSelectCarrierContractAssessorials,
  makeSelectCarrierContractAwardedLines,
  makeSelectCustomerContractAwardedLines,
  makeSelectCustomerContractAssessorials,
  makeSelectAvailableForCarrierLoadBoards,
  makeSelectLoadBoardSynchronizationParams,
  makeSelectCarrierInternalDispatchSettings,
};
