import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../../constants';
// feature fleet-profile/driver
import SafetyViolation from '../components/safety-violation';
//////////////////////////////////////////////////

export const safetyViolationSettings = {
  groupName: 'safetyViolation',
  Component: ({
    isOpened,
    itemList,
    configGroup,
    primaryObjectGuid,
    handleToggleFormGroupTable,
  }: Object) => (
    <SafetyViolation
      isOpened={isOpened}
      driverGuid={primaryObjectGuid}
      safetyViolationList={itemList}
      handleToggleFormGroup={handleToggleFormGroupTable}
      optionsForSelect={{
        documentType: R.path(['dropdownOptions', GC.DRIVER_DOCUMENT_TYPE], configGroup),
        safetyViolationType: R.path(['dropdownOptions', GC.DRIVER_SAFETY_VIOLATION_TYPE], configGroup),
        safetyViolationSeverity: R.path(['dropdownOptions', GC.DRIVER_SAFETY_VIOLATION_SEVERITY], configGroup),
        safetyViolationDisciplinaryAction: R.path(
          ['dropdownOptions', GC.DRIVER_SAFETY_VIOLATION_DISCIPLINARY_ACTION],
          configGroup,
        ),
      }}
    />
  ),
};
