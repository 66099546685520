import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// component
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Form, Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature dispatch-details-new
import {
  docFields,
  defaultDocumentFields,
  getDocumentFormValidationSchemaObject,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultDocumentFields,
      props.initialValues,
      props.searchedValues,
    ),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getDocumentFormValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const {
        telGuid,
        availableDocumentTypes,
        handleActionLoadDocument } = props;

      const callback = () => setSubmitting(false);

      let documentType = G.ifElse(
        R.and(
          R.is(String, values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE]),
          R.isEmpty(values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE]),
        ),
        null,
        values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE],
      );

      if (G.isString(documentType)) {
        documentType = G.transformGuidToRequest(values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE], availableDocumentTypes);
      }

      let data = R.assoc(GC.FIELD_DOCUMENT_DOCUMENT_TYPE, documentType, values);

      if (G.isNilOrEmpty(values[GC.FIELD_DOCUMENT_URL])) data = R.omit([GC.FIELD_DOCUMENT_URL], data);

      if (G.isNotNil(telGuid)) data = R.assoc(GC.FIELD_TEL_GUID, telGuid, data);

      handleActionLoadDocument({ data, callback });
    },
  }),
  pure,
);

const getAvailableDocTypeOptions = (props: Object) => (
  R.compose(
    R.map((item: Object) => ({
      label: item.displayedValue,
      value: G.getParentGuidOrGuidFromObject(item),
    })),
    R.pathOr([], [GC.AVAILABLE_DOCUMENT_TYPES]),
  )(props)
);

const getEventGuidOptions = (props: Object) => {
  const loadEventIndex = G.ifElse(
    R.equals(props.entityName, GC.FIELD_TEL),
    GC.FIELD_TEL_EVENT_INDEX,
    GC.FIELD_CLO_EVENT_INDEX,
  );
  let events = R.sortBy((item: Object) => R.prop(loadEventIndex, item), R.path(['load', 'events'], props));

  if (R.propEq(GC.DOCUMENT_PROOF_TYPE_POP, GC.FIELD_DOCUMENT_PROOF_TYPE, R.path(['values'], props))) {
    events = events.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_PICKUP));
  }

  if (R.propEq(GC.DOCUMENT_PROOF_TYPE_POD, GC.FIELD_DOCUMENT_PROOF_TYPE, R.path(['values'], props))) {
    events = events.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_DROP));
  }

  let entityName = props.entityName;

  if (R.pathEq(GC.FIELD_CLO, ['load', 'loadType'], props)) {
    events = events.filter((event: Object) => G.isTrue(event.cloEvent));

    entityName = GC.FIELD_CLO;
  }

  return G.createStopOptions(events, R.equals(entityName, GC.FIELD_TEL));
};

const DocumentForm = enhance((props: Object) => {
  const { values, handleSubmit, initialValues } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={docFields(values, initialValues)}
        eventsOptions={getEventGuidOptions(props)}
        fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
        availableDocumentTypes={getAvailableDocTypeOptions(props)}
      />
      <FormFooter2 />
    </Form>
  );
});

export default DocumentForm;
