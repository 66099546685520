import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, lifecycle } from 'react-recompose';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet-list
import { driversOnboardingColumnSettings } from '../settings';
import { getDriverListByDriverOnboardingGuidRequest } from '../actions';
//////////////////////////////////////////////////

const tableSettings = {
  maxHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 40,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  allowSelectItems: false,
  minHeight: 'max-content',
  emptyListPadding: '10px 0',
};

const DriverOnboardingList = (props: Object) => (
  <Table
    itemList={props.itemList}
    tableSettings={tableSettings}
    columnSettings={driversOnboardingColumnSettings}
    report={{
      fields: G.mapIndexed(
        (name: tring, sequence: number) => ({ name, sequence, freezed: G.isZero(sequence) }),
        R.keys(driversOnboardingColumnSettings),
      ),
    }}
  />
);

const enhance = compose(
  connect(null, { getDriverListByDriverOnboardingGuidRequest }),
  lifecycle({
    componentDidMount() {
      const { itemList, driverOnboardingGuid, getDriverListByDriverOnboardingGuidRequest } = this.props;

      if (G.isNilOrEmpty(itemList)) {
        getDriverListByDriverOnboardingGuidRequest(driverOnboardingGuid);
      }
    },
  }),
  pure,
);

export default enhance(DriverOnboardingList);
