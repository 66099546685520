import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as C from '../../constants';
// ////////////////////////////////////////

export const CUSTOM_TAB = 'CUSTOM_TAB';
export const DEFAULT_TAB = 'DEFAULT_TAB';

export const ADD_NEW_LANGUAGE_ITEM = 'ADD_NEW_LANGUAGE_ITEM';
export const ADD_NEW_CUSTOM_LANGUAGE_ITEM = 'ADD_NEW_CUSTOM_LANGUAGE_ITEM';

export const tabStyles = {
  p: '8px',
  mr: '8px',
  height: 50,
  fontSize: 30,
  maxWidth: '250px',
  width: 'max-comtent',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  bg: G.getTheme('colors.white'),
  color: G.getTheme('colors.dark.grey'),
  boxShadow: `2px -2px 5px -2px ${G.getTheme('colors.boxShadowGrey')}`,
};

export const activeTabStyles = R.mergeRight(tabStyles, {
  color: G.getTheme('colors.light.blue'),
});
