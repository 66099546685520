import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const useForm = ({
  fields,
  submitAction,
  initialValues,
  omitFormFields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  optionsForSelect = {},
  configOptionNames = [],
  handleGetCarrierProfileConfigsRequest,
}: Object) => {
  const [configOptions, setConfigOptions] = useState(R.pickAll(configOptionNames, optionsForSelect));

  useEffect(() => {
    if (R.and(G.isNotNilAndNotEmpty(configOptionNames), G.isAnyNilOrEmpty(R.values(configOptions)))) {
      handleGetCarrierProfileConfigsRequest({ names: configOptionNames, callback: setConfigOptions });
    }
  }, []);

  const onSubmit = (values: Object, { setSubmitting }: Object) => {
    const failCallback = () => setSubmitting(false);
    const successCallback = () => setSubmitting(false);

    submitAction(G.mapObjectEmptyStringFieldsToNull(values), { failCallback, successCallback });
  };

  const formFields = R.omit(R.or(omitFormFields, []), fields);

  const defaultValues = R.map(R.propOr('', 'defaultValue'), formFields);

  const fieldSettings = R.compose(
    R.values,
    R.mapObjIndexed((field: Object, fieldName: string) => {
      const { name, additionalInputWrapperStyles } = field;

      return {
        ...field,
        fieldName,
        label: G.getWindowLocale(R.of(Array, name)),
        inputWrapperStyles: {
          ...inputWrapperStyles,
          ...additionalInputWrapperStyles,
        },
      };
    }),
  )(formFields);

  const wrapperStyles = {
    pt: 15,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...fieldsWrapperStyles,
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: R.mergeRight(defaultValues, initialValues),
  });

  return {
    formik,
    wrapperStyles,
    fieldSettings,
    optionsForSelect: R.mergeRight(optionsForSelect, configOptions),
  };
};

const Form = (props: Object) => {
  const { formik, wrapperStyles, fieldSettings, optionsForSelect } = useForm(props);

  const { isSubmitting, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(formik)}
        {...optionsForSelect}
        fields={fieldSettings}
        fieldsWrapperStyles={wrapperStyles}
      />
      <FormFooter2 submitDisabled={isSubmitting} />
    </form>
  );
};

export default Form;
