import styled from 'styled-components';
// ui
import { PageWrapper } from '../../ui';
// utilities
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const TitleAction = styled.div`
  display: flex;
  align-items: center;
  & > div {
    color: ${({ suspention }: Object) => G.getTheme(
      G.ifElse(
        suspention,
        'colors.light.mainRed',
        'colors.light.green',
      ),
    )};
  }

  & section {
    color: ${({ active }: Object) => G.getTheme(
      G.ifElse(
        active,
        'colors.light.mainRed',
        'colors.light.green',
      ),
    )};
  }
`;

export const CustomPageWrapper = styled(PageWrapper)`
  background-color: ${() => G.getTheme('forms.bgColor')};
  & > div {
    justify-content: space-between;
  }
`;

export const ZIndexWrapper = styled.div`
  z-index: unset;
  position: relative;
  & > * {
    z-index: unset;
    & > * { z-index: unset }
  }
`;

export const Form = styled.form`
  background-color: ${() => G.getTheme('colors.bgGrey')};
`;
