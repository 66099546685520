import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box, Flex } from '../../../../ui';
// feature dispatch-details-new
import * as C from '../../constants';
import { withLoadCardsProps } from '../../load/hocs/with-load-cards-props';
import { withOrderCardsProps } from '../../order/hocs/with-order-cards-props';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const wrapperProps = {
  height: 436,
  width: '100%',
  borderRadius: '4px',
  background: whiteColor,
  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
};

const CardWrapper = (props: Object) => {
  const { cardType, headerBgColor } = props;

  if (R.equals(cardType, C.CARD_TYPE_MESSAGE_CENTER)) {
    return (
      <Box {...wrapperProps}>
        {R.pathOr(null, ['card', 'leftHeaderComponent'], props)}
        {R.pathOr(null, ['card', 'component'], props)}
      </Box>
    );
  }

  return (
    <Box {...wrapperProps}>
      <Flex
        px='8px'
        height={36}
        borderTopLeftRadius='4px'
        borderTopRightRadius='4px'
        justifyContent='space-between'
        background={R.or(headerBgColor, G.getTheme('colors.lightGrey'))}
      >
        {R.pathOr(null, ['card', 'leftHeaderComponent'], props)}
        {R.pathOr(null, ['card', 'rightHeaderComponent'], props)}
      </Flex>
      {R.pathOr(null, ['card', 'component'], props)}
    </Box>
  );
};

export const LoadCardWrapper = withLoadCardsProps(CardWrapper);
export const OrderCardWrapper = withOrderCardsProps(CardWrapper);

export default CardWrapper;
