import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// helpers/constants
import * as GC from '../../../../constants';
// sagas
import { visitPageSaga } from '../../../../sagas';
// feature configs
import { getConfigByGroupSaga, getSequencesByTypeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

function* handleVisitConfigTemplatesPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_TEMPLATES_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getConfigByGroupSaga, GC.TEMPLATES_CONFIG_GROUP);
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_TEMPLATES });
    yield put(closeLoader());
    break;
  }
}

function* templatesConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_TEMPLATES_PAGE, handleVisitConfigTemplatesPageSaga);
}

export default templatesConfigWatcherSaga;
