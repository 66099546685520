import * as R from 'ramda';
import React, { Fragment } from 'react';
import { withFormik, FieldArray } from 'formik';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withProps,
  withHandlers,
} from 'react-recompose';
// components
import { TextComponent } from '../../../../components/text';
import { Tabs2, withTabs2 } from '../../../../components/tabs-mui';
import { ChargeFormFooter } from '../../../../components/form-footer';
// forms
import { Fieldset } from '../../../new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetServiceVendorListAvailabe,
} from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, StickedBox, ActionButton, ModalCancelButton } from '../../../../ui';
// feature fleet
import EquipmentServiceDocuments from './documents';
import {
  withAddServiceVendor,
  withAsyncGetEquipmentServiceByEntityTypeAndServiceGuid,
  withAsyncGetAvailableForServiceEquipmentComponentsByEntityType,
} from '../hocs';
import {
  validationSchema,
  invoiceDefaultFields,
  serviceFieldSettings,
  invoiceFieldSettings,
  chargesFieldSettings,
  serviceDefaultFields,
  chargeDefaultSettings,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const whiteGreyColor = G.getTheme('colors.whiteGrey');
const darkBlueColor = G.getTheme('colors.dark.blue');

const correctInvoiceFieldSettings = R.map((item: Object) => R.assoc(
  'fieldName',
  `${GC.SYSTEM_OBJECT_INVOICE}.${item.fieldName}`,
  item,
), invoiceFieldSettings);

const firstServiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_SERVICE_ID,
    GC.FIELD_SERVICE_DATE,
    GC.FIELD_SERVICE_TYPE,
  ],
  serviceFieldSettings,
));

const secondServiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_APPROVED_BY,
    GC.FIELD_COMPONENT_GUIDS,
  ],
  serviceFieldSettings,
));

const thirdServiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_INTERVAL,
    GC.FIELD_INTERVAL_UOM,
    GC.FIELD_COMMENTS,
  ],
  serviceFieldSettings,
));

const fourthServiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_PERFORMED_DATE,
    GC.FIELD_PERFORMED_VALUE,
    GC.FIELD_PERFORMED_TYPE,
  ],
  serviceFieldSettings,
));

const firstInvoiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_INVOICE_NUMBER,
    GC.FIELD_INVOICE_STATUS,
    GC.FIELD_GL_CODE,
    GC.FIELD_PO_NUMBER,
  ],
  correctInvoiceFieldSettings,
));

const secondInvoiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_INVOICE_DATE,
    GC.FIELD_INVOICE_NET_DAYS,
    GC.FIELD_INVOICE_PAYMENT_DUE_DATE,
  ],
  correctInvoiceFieldSettings,
));

const thirdInvoiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_INVOICE_CHECK_NUMBER,
    GC.FIELD_INVOICE_CHECK_DATE,
    GC.FIELD_CHECK_AMOUNT,
    GC.FIELD_INVOICE_DEPOSIT_DATE,
    GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER,
  ],
  correctInvoiceFieldSettings,
));

const fourthInvoiceSectionFields = R.values(R.pick(
  [
    GC.FIELD_CURRENCY,
    GC.FIELD_COMMENTS,
  ],
  correctInvoiceFieldSettings,
));

const serviceVendorTypes = R.join(
  ',',
  [GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE, GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE],
);

const setInitialValues = (equipmentService: Object) => {
  if (G.isNilOrEmpty(equipmentService)) return null;

  const { invoice } = equipmentService;

  const serviceType = R.pathOr(null, [GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], equipmentService);

  if (G.isNilOrEmpty(invoice)) {
    return R.assoc(GC.FIELD_SERVICE_TYPE, serviceType, equipmentService);
  }

  const status = R.pathOr(null, [GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], equipmentService);
  const glCode = R.pathOr(
    null,
    [GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_GL_CODE, GC.FIELD_DROPDOWN_OPTION_GUID],
    equipmentService,
  );

  return {
    ...equipmentService,
    serviceType,
    serviceVendorType: R.pathOr(null, [GC.SYSTEM_OBJECT_SERVICE_VENDOR, GC.FIELD_TYPE], equipmentService),
    [GC.SYSTEM_OBJECT_INVOICE]: {
      ...invoice,
      status,
      glCode,
    },
  };
};

const enhance = compose(
  withProps(() => ({
    type: 'fleetService',
    configsNamesArray: [
      GC.INVOICE_GL_CODE,
      GC.FLEET_EQUIPMENT_SERVICE_TYPE,
      GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
      GC.SERVICE_VENDOR_VENDOR_SERVICE_TYPE,
    ],
  })),
  withAsyncConfigs,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetServiceVendorListAvailabe(serviceVendorTypes),
  withAsyncGetAvailableForServiceEquipmentComponentsByEntityType,
  withState('invoiceExpanded', 'setInvoiceExpanded', null),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues, equipmentService }: Object) => G.setInitialFormikValues(
      R.assoc(GC.SYSTEM_OBJECT_INVOICE, null, serviceDefaultFields),
      initialValues,
      setInitialValues(equipmentService),
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  withHandlers({
    handleToggleInvoiceSection: (props: Object) => () => {
      const { values, touched, asyncConfigs, setTouched, setFieldValue, invoiceExpanded, setInvoiceExpanded } = props;

      if (R.and(R.not(invoiceExpanded), G.isNilOrEmpty(R.prop(GC.SYSTEM_OBJECT_INVOICE, values)))) {
        if (R.propEq(true, GC.SYSTEM_OBJECT_INVOICE, touched)) {
          const invoiceFieldsTouched = R.mapObjIndexed((value: any, key: string) => {
            if (R.equals(key, GC.FIELD_CHARGES)) {
              return R.map(() => ({
                [GC.FIELD_TOTAL]: true,
                [GC.FIELD_COMMENTS]: true,
                [GC.FIELD_DISPLAYED_NAME]: true,
              }), value);
            }

            return true;
          }, invoiceDefaultFields);

          setTouched(R.assoc(GC.SYSTEM_OBJECT_INVOICE, invoiceFieldsTouched, touched));
        }

        const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, asyncConfigs);

        setFieldValue(GC.SYSTEM_OBJECT_INVOICE, R.assoc(GC.FIELD_CURRENCY, branchCurrency, invoiceDefaultFields));
      }

      if (invoiceExpanded) {
        const isAnyChargeNotNilAndNotEmpty = R.compose(
          R.any(R.compose(
            R.any(G.isNotNilAndNotEmpty),
            R.values,
            R.pick([GC.FIELD_TOTAL, GC.FIELD_COMMENTS, GC.FIELD_DISPLAYED_VALUE]),
          )),
          R.pathOr([], [GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_CHARGES]),
        )(values);

        const isAnyInvoiceFieldNotNilAndNotEmpty = R.compose(
          R.any(G.isNotNilAndNotEmpty),
          R.values,
          R.omit([
            ...GC.GROUPED_FIELDS.API_DEFAULT_FIELDS,
            GC.FIELD_CHARGES,
          ]),
          R.propOr({}, GC.SYSTEM_OBJECT_INVOICE),
        )(values);

        if (R.and(G.isFalse(isAnyChargeNotNilAndNotEmpty), G.isFalse(isAnyInvoiceFieldNotNilAndNotEmpty))) {
          setFieldValue(GC.SYSTEM_OBJECT_INVOICE, null);
        }
      }

      setInvoiceExpanded(R.not);
    },
  }),
  withAddServiceVendor,
  withProps((props: Object) => {
    const { asyncConfigs } = props;

    const invoiceStatusOptions = G.mapCustomConfigOptionsFromProps('FleetServiceIStatusConfig', props, true);

    const glCodeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.INVOICE_GL_CODE,
      true,
    );

    const equipmentServiceTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.FLEET_EQUIPMENT_SERVICE_TYPE,
      true,
    );

    return { glCodeOptions, invoiceStatusOptions, equipmentServiceTypeOptions };
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const props = this.props;

      const {
        setFieldValue,
        invoiceExpanded,
        equipmentService,
        setInvoiceExpanded,
        handleToggleInvoiceSection,
        serviceVendorListAvailabeOptions,
      } = props;

      const getPerformedDate = R.path(['values', GC.FIELD_PERFORMED_DATE]);

      if (G.notEquals(getPerformedDate(props), getPerformedDate(prevProps))) {
        if (R.and(R.isNil(invoiceExpanded), G.isNotNilAndNotEmpty(getPerformedDate(this.props)))) {
          return handleToggleInvoiceSection();
        }
      }

      if (G.notEquals(equipmentService, prevProps.equipmentService)) {
        if (G.isNotNilAndNotEmpty(R.prop(GC.SYSTEM_OBJECT_INVOICE, equipmentService))) {
          return setInvoiceExpanded(true);
        }
      }

      const getServiceVendorGuid = R.path(['values', GC.FIELD_SERVICE_VENDOR_GUID]);
      const serviceVendorGuid = getServiceVendorGuid(props);

      if (G.notEquals(getServiceVendorGuid(props), getServiceVendorGuid(prevProps))) {
        if (G.isNotNilAndNotEmpty(serviceVendorListAvailabeOptions)) {
          const serviceVendorType = R.compose(
            R.pathOr(null, [GC.FIELD_TYPE]),
            R.find(R.propEq(serviceVendorGuid, GC.FIELD_VALUE)),
          )(serviceVendorListAvailabeOptions);

          setFieldValue('serviceVendorType', serviceVendorType);
        }
      }
    },
  }),
  pure,
);

const LocationInfo = ({ ml, type, label, entity }: Object) => {
  const location = G.concatLocationFields(entity);
  const address = G.renderAddressInfoWithNameAndCode(R.dissoc(GC.FIELD_TEMPLATE_ID, entity));
  const contact = G.concatContactFields(entity, [GC.FIELD_CONTACT_NAME, GC.FIELD_PHONE, GC.FIELD_EMAILS]);

  return (
    <Box
      p='5px'
      ml={ml}
      maxWidth={300}
      borderRadius='2px'
      bg={whiteGreyColor}
    >
      <Box fontWeight={600}>
        {label}
      </Box>
      <TextComponent title={address} display='block' maxWidth='100%' withEllipsis={true}>
        {address}
      </TextComponent>
      <TextComponent title={location} display='block' maxWidth='100%' withEllipsis={true}>
        {location}
      </TextComponent>
      {
        R.equals(type, GC.SYSTEM_OBJECT_PRIMARY_LOCATION) &&
        <TextComponent title={contact} display='block' maxWidth='100%' withEllipsis={true}>
          {contact}
        </TextComponent>
      }
    </Box>
  );
};

const ServiceVendorInfo = ({ text, label }: Object) => (
  <Box
    p='5px'
    ml={20}
    maxWidth={150}
    borderRadius='2px'
    bg={whiteGreyColor}
  >
    <TextComponent
      minWidth='100%'
      maxWidth='100%'
      withEllipsis={true}
      whiteSpace='pre-line'
      wordBreak='break-all'
    >
      <Flex justifyContent='space-between'>
        <Box fontWeight={600} textTransform='uppercase'>
          {label}
        </Box>
      </Flex>
      {text}
    </TextComponent>
  </Box>
);

const SelectServiceVendor = (props: Object) => (
  <Fieldset
    {...G.getFormikPropsToFieldset(props)}
    fields={R.of(Array, R.prop(GC.FIELD_SERVICE_VENDOR_GUID, serviceFieldSettings))}
    serviceVendorListAvailableOptions={props.serviceVendorListAvailableOptions}
    fieldsWrapperStyles={{ px: 0, mr: props.mr, justifyContent: 'space-between' }}
  />
);

const ServiceVendorSection = (props: Object) => {
  const serviceVendorGuid = R.path(['values', GC.FIELD_SERVICE_VENDOR_GUID], props);

  if (G.isNilOrEmpty(serviceVendorGuid)) {
    return (
      <Flex px={15} mt={25} mb={15}>
        <SelectServiceVendor {...props} />
        <ActionButton
          ml={20}
          px='8px'
          height={30}
          type='button'
          borderRadius='5px'
          onClick={props.handleAddServiceVendor}
        >
          {G.getWindowLocale('titles:add-service-vendor', 'Add Service Vendor')}
        </ActionButton>
      </Flex>
    );
  }

  const { serviceVendorListAvailableOptions } = props;

  const serviceVendor = R.find(R.propEq(serviceVendorGuid, GC.FIELD_VALUE), serviceVendorListAvailableOptions);
  const dba = G.getPropFromObject(GC.FIELD_DBA, serviceVendor);
  const dunsNumber = G.getPropFromObject(GC.FIELD_DUNS_NUMBER, serviceVendor);
  const payToLocation = G.getPropFromObject(GC.SYSTEM_OBJECT_PAY_TO_LOCATION, serviceVendor);
  const primaryLocation = G.getPropFromObject(GC.SYSTEM_OBJECT_PRIMARY_LOCATION, serviceVendor);
  const hasNotServiceVendorInfoFields = G.isAllNilOrEmpty([dba, dunsNumber]);

  return (
    <Flex mt={25} mb={15} px={15} flexWrap='wrap'>
      <SelectServiceVendor {...props} mr={G.ifElse(hasNotServiceVendorInfoFields, 20, '0px')} />
      {
        G.isNotNilAndNotEmpty(dba) &&
        <ServiceVendorInfo text={dba} label={G.getWindowLocale('titles:dba', 'DBA')} />
      }
      {
        G.isNotNilAndNotEmpty(dunsNumber) &&
        <ServiceVendorInfo text={dunsNumber} label={G.getWindowLocale('titles:duns-number', 'D-U-N-S Number')} />
      }
      {
        R.or(G.isNotNilAndNotEmpty(payToLocation), G.isNotNilAndNotEmpty(primaryLocation)) &&
        <Flex
          mt={G.ifElse(hasNotServiceVendorInfoFields, '0px', 15)}
          mx={G.ifElse(hasNotServiceVendorInfoFields, null, 'auto')}
        >
          {
            G.isNotNilAndNotEmpty(payToLocation) &&
            <LocationInfo entity={payToLocation} label={`${G.getWindowLocale('titles:pay-to', 'Pay To')}:`} />
          }
          {
            G.isNotNilAndNotEmpty(primaryLocation) &&
            <LocationInfo
              ml={20}
              entity={primaryLocation}
              type={GC.SYSTEM_OBJECT_PRIMARY_LOCATION}
              label={`${G.getWindowLocale('titles:primary-location', 'Primary Location')}:`}
            />
          }
        </Flex>
      }
    </Flex>
  );
};

const SectionHeader = (props: Object) => {
  const {
    title,
    expanded,
    children,
    setExpanded,
    handleAddItem,
  } = props;

  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Fragment>
      <Flex
        p='5px'
        my={10}
        fontWeight='bold'
        color={darkBlueColor}
        justifyContent='center'
        alignItems='flex-start'
        textTransform='uppercase'
        bg={G.getTheme('colors.whiteGrey')}
      >
        <Box mr={15} ml='auto'>{title}</Box>
        {
          G.isFunction(handleAddItem) &&
          <Box
            cursor='pointer'
            onClick={() => {
              handleAddItem();

              if (R.not(expanded)) setExpanded(true);
            }}
          >
            {I.plusRound(darkBlueColor)}
          </Box>
        }
        <Box
          px='6px'
          ml='auto'
          cursor='pointer'
          onClick={() => setExpanded(R.not)}
        >
          {I[toggleIconName](darkBlueColor)}
        </Box>
      </Flex>
      {G.isTrue(expanded) && children}
    </Fragment>
  );
};

const expandedEnhance = withState('expanded', 'setExpanded', R.prop('expanded'));

const EnhancedSectionHeader = expandedEnhance(SectionHeader);

const Divider = () => <Box height='1px' width='100%' borderTop='1px solid' borderColor={darkBlueColor} />;

const ChargesSection = ({ push, form, remove }: Object) => {
  const charges = R.pathOr([], ['values', GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_CHARGES], form);
  const chargesLength = R.length(charges);

  return (
    <EnhancedSectionHeader
      expanded={G.isNotNilAndNotEmpty(charges)}
      handleAddItem={() => push(chargeDefaultSettings())}
      title={G.getWindowLocale('titles:charges', 'Charges')}
    >
      {
        charges.map((_: any, index: number) => (
          <Flex px={15} my={25} key={index}>
            {
              G.notEquals(chargesLength, 1) &&
              <Box
                mx={10}
                display='flex'
                cursor='pointer'
                alignItems='center'
                onClick={() => remove(index)}
                title={G.getWindowLocale('titles:remove', 'Remove')}
              >
                {I.trash(darkBlueColor)}
              </Box>
            }
            <Fieldset
              {...form}
              itemIndex={index}
              fieldsetType='array'
              arrayLength={chargesLength}
              fields={chargesFieldSettings}
              arrayName={`${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_CHARGES}`}
              fieldsWrapperStyles={{ width: 'calc(100% - 23px)', justifyContent: 'space-between' }}
            />
          </Flex>
        ))
      }
    </EnhancedSectionHeader>
  );
};

const getInvoiceTotal = (values: Object, asyncConfigs: Object) => {
  const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, asyncConfigs);
  const currencySymbol = G.getCurrencySymbol(
    R.pathOr(branchCurrency, [GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_CURRENCY], values),
  );
  const chargesTotal = R.compose(
    G.NaNToZero,
    G.toFixed,
    R.sum,
    R.map(R.pathOr(0, [GC.FIELD_TOTAL])),
    R.pathOr([], [GC.SYSTEM_OBJECT_INVOICE, GC.FIELD_CHARGES]),
  )(values);

  return `${chargesTotal} ${currencySymbol}`;
};

const EquipmentServiceForm = (props: Object) => {
  const {
    values,
    closeModal,
    asyncConfigs,
    handleSubmit,
    glCodeOptions,
    invoiceStatusOptions,
    handleAddServiceVendor,
    serviceVendorListAvailabeOptions,
    availableForServiceEquipmentComponents,
  } = props;

  return (
    <Box
      width={840}
      maxHeight='87vh'
      overflowY={G.ifElse(R.propEq(true, 'invoiceExpanded', props), 'auto', 'unset')}
    >
      <form onSubmit={handleSubmit}>
        <ServiceVendorSection
          {...G.getFormikPropsToFieldset(props)}
          handleAddServiceVendor={handleAddServiceVendor}
          serviceVendorListAvailableOptions={serviceVendorListAvailabeOptions}
        />
        <EnhancedSectionHeader
          expanded={true}
          title={G.getWindowLocale('titles:service-details', 'Service Details')}
        >
          <Box px={15}>
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              fields={firstServiceSectionFields}
              equipmentServiceTypeOptions={props.equipmentServiceTypeOptions}
              fieldsWrapperStyles={{ px: 0, my: 25, justifyContent: 'space-between' }}
            />
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              fields={secondServiceSectionFields}
              availableForServiceEquipmentComponents={availableForServiceEquipmentComponents}
              fieldsWrapperStyles={{ px: 0, width: 530, my: 25, justifyContent: 'space-between' }}
            />
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              fields={thirdServiceSectionFields}
              fieldsWrapperStyles={{ px: 0, my: 25, justifyContent: 'space-between' }}
            />
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              fields={fourthServiceSectionFields}
              fieldsWrapperStyles={{ px: 0, my: 25, justifyContent: 'space-between' }}
            />
          </Box>
        </EnhancedSectionHeader>
        <SectionHeader
          expanded={props.invoiceExpanded}
          setExpanded={props.handleToggleInvoiceSection}
          title={G.getWindowLocale('titles:invoice-details', 'Invoice Details')}
        >
          <Flex px={15} my={25} mx='auto' alignItems='flex-start' justifyContent='space-between'>
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              glCodeOptions={glCodeOptions}
              fields={firstInvoiceSectionFields}
              invoiceStatusOptions={invoiceStatusOptions}
              fieldsWrapperStyles={{ px: 0, flexDirection: 'column' }}
            />
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              fields={secondInvoiceSectionFields}
              fieldsWrapperStyles={{ px: 0, flexDirection: 'column' }}
            />
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              fields={thirdInvoiceSectionFields}
              fieldsWrapperStyles={{ px: 0, flexDirection: 'column' }}
            />
          </Flex>
          <Divider />
          <Fieldset
            {...G.getFormikPropsToFieldset(props)}
            fields={fourthInvoiceSectionFields}
            fieldsWrapperStyles={{ px: 15, my: 25, justifyContent: 'space-between' }}
          />
          <FieldArray
            name={`${GC.SYSTEM_OBJECT_INVOICE}.${GC.FIELD_CHARGES}`}
            render={(arrayHelpers: Object) => <ChargesSection {...arrayHelpers} />}
          />
        </SectionHeader>
        <StickedBox bottom='0px' zIndex={G.ifElse(R.propEq(true, 'invoiceExpanded', props), 1001, 12)}>
          <Flex
            p={10}
            mt={10}
            bg={whiteGreyColor}
            borderTop='1px solid'
            justifyContent='center'
            borderBottom='1px solid'
            borderColor={darkBlueColor}
          >
            <Box
              p={10}
              borderRadius='5px'
              bg={darkBlueColor}
              color={whiteColor}
            >
              {G.getWindowLocale('titles:total', 'Total')}: {getInvoiceTotal(values, asyncConfigs)}
            </Box>
          </Flex>
          <ChargeFormFooter closeModal={closeModal} />
        </StickedBox>
      </form>
    </Box>
  );
};

export default enhance(EquipmentServiceForm);

const tabs = [
  { text: G.getWindowLocale('titles:equipment-service', 'Equipment Service') },
  { text: G.getWindowLocale('titles:documents', 'Documents') },
];

const enhanceEditForm = compose(
  withTabs2,
  withState('localItemList', 'setLocalItemList', R.prop('documentList')),
  withAsyncGetEquipmentServiceByEntityTypeAndServiceGuid,
  enhance,
);

export const EditEquipmentService = enhanceEditForm((props: Object) => {
  const {
    activeTab,
    openModal,
    entityType,
    closeModal,
    openLoader,
    closeLoader,
    localItemList,
    setLocalItemList,
    handleSetActiveTab,
    documentTypeOptions,
  } = props;

  return (
    <Fragment>
      <Tabs2
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        tabStyles={GC.COMMON_MUI_TAB_STYLES}
        tabsStyles={{ ...GC.COMMON_MUI_TABS_STYLES, m: '15px' }}
      />
      {
        R.equals(activeTab, 0) && <EquipmentServiceForm {...props} />
      }
      {
        R.equals(activeTab, 1) &&
        <Fragment>
          <EquipmentServiceDocuments
            p='0 15px 15px 15px'
            openModal={openModal}
            entityType={entityType}
            closeModal={closeModal}
            openLoader={openLoader}
            useComponentState={true}
            closeLoader={closeLoader}
            showAddDocumentButton={true}
            localItemList={localItemList}
            setLocalItemList={setLocalItemList}
            documentTypeOptions={documentTypeOptions}
            documentList={R.pathOr([], ['documentList'], props)}
            primaryObjectGuid={R.pathOr(null, ['equipmentService', GC.FIELD_GUID], props)}
          />
          <Flex
            bottom='0px'
            p='14px 20px'
            boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
            background={G.getTheme('colors.white')}
          >
            <ModalCancelButton width={120} fontSize={14} onClick={closeModal}>
              {G.getWindowLocale('actions:close', 'Close')}
            </ModalCancelButton>
          </Flex>
        </Fragment>
      }
    </Fragment>
  );
});
