import { createLogic } from 'redux-logic';
// features
import { sendLogOutRequest } from '../auth/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import { globalCleanReports } from '../../common/actions';
// feature dispatch-report
import { setInitialState as setLoadInitialState } from './load/actions';
import { setInitialState as setOrderInitialState } from './order/actions';
//////////////////////////////////////////////////

const handleCleanStateActionsLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    receivedSwitchBranchSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(setLoadInitialState());
    dispatch(setOrderInitialState());
    done();
  },
});

export default [
  handleCleanStateActionsLogic,
];
