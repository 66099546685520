import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const LatestKnownLocationHeaderTitle = ({ latestKnownLocations }: Object) => (
  <TextComponent
    mr='10px'
    p='5px 8px'
    fontSize='14px'
    maxWidth='100%'
    borderRadius='3px'
    withEllipsis={true}
    display='inline-block'
    bg={G.getTheme('pages.listTitle.infoBoxBgColor')}
    color={G.getTheme('pages.listTitle.infoBoxColor')}
    title={G.concatLocationFields(R.head(latestKnownLocations))}
  >
    {G.concatLocationFields(R.head(latestKnownLocations))}
  </TextComponent>
);

export default LatestKnownLocationHeaderTitle;
