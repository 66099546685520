import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import moment from 'moment';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// feature fleet/driver
import {
  commentsField,
  defaultMonthlyPayrollDeductionFields,
  getMonthlyPayrollDeductionFirstFieldset,
  getMonthlyPayrollDeductionSecondFieldset,
  getMonthlyPayrollDeductiinValidationSchemaObject,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultMonthlyPayrollDeductionFields,
      props.initialValues,
    ),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getMonthlyPayrollDeductiinValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props }: Object) => {
      const requestData = G.ifElse(
        G.isFalse(R.prop(GC.FIELD_LIMITED, values)),
        R.mergeRight(values, {
          [GC.FIELD_DATE_TO]: null,
          [GC.FIELD_TOTAL_AMOUNT]: null,
          [GC.FIELD_HISTORICAL_PAID_AMOUNT]: null,
        }),
        values,
      );
      const data = {
        requestData,
        requestMethod: props.requestMethod,
      };
      props.submitAction(data);
    },
  }),
  withHandlers({
    handleCustomChange: (props: Object) => (event: Object) => {
      const { values, setValues } = props;
      const { id, value } = event.target;

      let valuesToSet = R.assoc(id, value, values);
      const amount = R.prop(GC.FIELD_AMOUNT, valuesToSet);
      const totalAmount = R.prop(GC.FIELD_TOTAL_AMOUNT, valuesToSet);
      const dateFrom = R.prop(GC.FIELD_DATE_FROM, valuesToSet);
      const dateTo = R.prop(GC.FIELD_DATE_TO, valuesToSet);

      const cond1 = G.isFalse(R.prop(GC.FIELD_LIMITED, values));
      const cond2 = G.isAllTrue(
        R.includes(id, [GC.FIELD_AMOUNT, GC.FIELD_TOTAL_AMOUNT, GC.FIELD_DATE_FROM]),
        G.notEquals(id, GC.FIELD_DATE_TO),
        G.isAllNotNilOrNotEmpty([amount, totalAmount, dateFrom]),
      );
      const cond3 = G.isAllTrue(
        R.includes(id, [GC.FIELD_TOTAL_AMOUNT, GC.FIELD_DATE_FROM, GC.FIELD_DATE_TO]),
        G.notEquals(id, GC.FIELD_AMOUNT),
        G.isAllNotNilOrNotEmpty([totalAmount, dateFrom, dateTo]),
      );

      if (cond1) {
        valuesToSet = R.mergeRight(valuesToSet, {
          [GC.FIELD_DATE_TO]: null,
          [GC.FIELD_TOTAL_AMOUNT]: null,
          [GC.FIELD_HISTORICAL_PAID_AMOUNT]: null,
        });
      } else if (cond2) {
        const count = Math.ceil(R.divide(totalAmount, amount));
        const dateTo = moment(dateFrom).add(count, 'months').format(GC.DEFAULT_DATE_FORMAT);
        valuesToSet = R.assoc(GC.FIELD_DATE_TO, dateTo, valuesToSet);
      } else if (cond3) {
        const count = Math.ceil(Math.abs(
          moment(dateTo).endOf('month').diff(moment(dateFrom).startOf('month'), 'months', true),
        ));
        const amount = R.divide(totalAmount, count);
        valuesToSet = R.assoc(GC.FIELD_AMOUNT, G.mathRoundNumber(amount), valuesToSet);
      }

      setValues(valuesToSet);
    },
  }),
  pure,
);

const MonthlyPayrollDeductionForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Flex alignItems='flex-start'>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fieldsetWidth={260}
        justifyContent='space-between'
        handleCustomChange={props.handleCustomChange}
        optionsForSelect={{
          accessorialOptions: props.accessorialOptions,
        }}
        fields={getMonthlyPayrollDeductionFirstFieldset(
          props.values,
          props.editMode,
          R.pathOr(false, ['initialValues', 'deductionDisabled'], props),
        )}
      />
      <Box>
        <FieldsetComponent
          {...G.getFormikProps(props)}
          fieldsetWidth={260}
          justifyContent='space-between'
          handleCustomChange={props.handleCustomChange}
          fields={getMonthlyPayrollDeductionSecondFieldset(props.values, props.editMode)}
        />
        {
          props.editMode &&
          <Flex mt={10} ml={12}>
            <Box mr={20}>{G.getWindowLocale('titles:fully-paid', 'Fully Paid')}</Box>
            {
              I[G.ifElse(
                G.isTrue(R.path(['initialValues', GC.FIELD_FULLY_PAID], props)),
                'uiTrue',
                'uiFalse',
              )]()
            }
          </Flex>
        }
      </Box>
    </Flex>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={R.of(Array, commentsField)}
    />
    <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={props.closeModal} />
  </form>
));

export default MonthlyPayrollDeductionForm;
