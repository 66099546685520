import React from 'react';
import * as R from 'ramda';
import { pure, compose, withProps } from 'react-recompose';
// components
import { TabsMuiEnhanced } from '../../../../components';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, RelativeBox, ActionButton } from '../../../../ui';
// feature dispatch-details-new
import * as C from '../../constants';
import { withOrderAddDocument } from './with-document-actions';
import DispatchCardComponent from '../../components/cards/dispatch';
import { OrderPaymentsCardComponent } from '../../components/cards/payments';
import { OrderDocumentsCardComponent } from '../../components/cards/documents';
import { withLoadCustomerRateTableGroupRowActions } from './with-customer-rate';
import { withOrderMessageCenterHeaderActions } from './with-message-center-actions';
import { OrderMessageCenterCardComponent } from '../../components/cards/message-center';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');
const greyColor = G.getTheme('colors.greyMatterhorn');

const headerActionButtonStyles = {
  px: '8px',
  ml: '4px',
  height: 20,
  fontSize: 12,
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
};

const ActionButtonComponent = ({ text, handleClick, permissions }: Object) => (
  <AuthWrapper has={permissions}>
    <ActionButton {...headerActionButtonStyles} type='button' onClick={handleClick}>
      {text}
    </ActionButton>
  </AuthWrapper>
);

const DocumentRightHeader = withOrderAddDocument((props: Object) => {
  const {
    load,
    handlePrintLoad,
    handleAddDocument,
    handlePrintLoadDocumentsInvoice,
  } = props;

  return (
    <Flex>
      <AuthWrapper has={[PC.CLO_DOCUMENT_WRITE]}>
        <RelativeBox
          pl='5px'
          cursor='pointer'
          alignSelf='flex-start'
          onClick={handlePrintLoadDocumentsInvoice}
        >
          {I.renderPrintDocumentsIcon(blueColor)}
        </RelativeBox>
      </AuthWrapper>
      <AuthWrapper has={[PC.CLO_DOCUMENT_WRITE]}>
        <RelativeBox
          ml='5px'
          cursor='pointer'
          onClick={() => handlePrintLoad(G.getGuidFromObject(load))}
        >
          {I.renderPrintIcon(blueColor)}
        </RelativeBox>
      </AuthWrapper>
      <ActionButtonComponent
        handleClick={handleAddDocument}
        permissions={[PC.CLO_DOCUMENT_WRITE]}
        text={G.getWindowLocale('titles:add-document', 'Add Document')}
      />
    </Flex>
  );
});

export const LeftHeaderComponent = ({ text }: Object) => (
  <Flex
    fontSize={12}
    fontWeight='bold'
    color={greyColor}
    textTransform='uppercase'
  >
    {text}
  </Flex>
);

const getChatTabs = () => [
  {
    value: 'chatMessageList',
    text: G.getWindowLocale('titles:chat', 'Chat'),
    permissions: [PC.TEL_CHAT_MESSAGE_READ, PC.TEL_CHAT_MESSAGE_WRITE],
  },
  {
    value: 'noteList',
    notHasPermissions: [PC.ROLE_TYPE_CARRIER],
    text: G.getWindowLocale('titles:notes', 'Notes'),
  },
];

const ChatLeftHeaderComponent = withOrderMessageCenterHeaderActions((props: Object) => {
  const { messageCenter, changeMessageCenterActiveTab } = props;

  const { activeTab } = messageCenter;

  const handleClick = (tab: string) => {
    if (R.equals(tab, activeTab)) return;

    return changeMessageCenterActiveTab(tab);
  };

  return (
    <Box border={`1px solid ${G.getTheme('colors.bgGrey')}`}>
      <TabsMuiEnhanced
        tabs={getChatTabs()}
        defaultActiveMuiTab={activeTab}
        handleClickMuiTab={handleClick}
        tabStyles={{ minHeight: 36, padding: 0 }}
        tabsStyles={{ maxHeight: 36, minHeight: 36 }}
      />
    </Box>
  );
});

export const withOrderCardsProps = compose(
  withLoadCustomerRateTableGroupRowActions,
  withProps((props: Object) => {
    const {
      load,
      cardType,
      invoices,
      openModal,
      closeModal,
      autodialApp,
      loadConfigs,
      loadDocuments,
      invoiceTotals,
      selectedCustomerRate,
      handleEditCustomerRate,
    } = props;

    const popDocuments = R.filter(R.propEq(GC.DOCUMENT_PROOF_TYPE_POP, GC.FIELD_PROOF_TYPE), R.or(loadDocuments, []));
    const podDocuments = R.filter(R.propEq(GC.DOCUMENT_PROOF_TYPE_POD, GC.FIELD_PROOF_TYPE), R.or(loadDocuments, []));
    const communicationConfigs = R.pathOr({}, ['configGroups', 'communication'], loadConfigs);

    const documentsProps = {
      load,
      openModal,
      closeModal,
      documents: loadDocuments,
      mailPermissions: [PC.SEND_EMAIL_EXECUTE],
      documentPermissions: [PC.CLO_DOCUMENT_WRITE],
      useBillToContactEmail: G.getConfigValueFromStore(
        GC.COMMUNICATION_EMAIL_USE_BILL_TO_CONTACT_EMAIL,
        communicationConfigs,
      ),
    };

    const cardTypeMap = {
      [C.CARD_TYPE_DISPATCH]: {
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:dispatch', 'Dispatch')} />
        ),
        component: (
          <DispatchCardComponent
            autodialApp={autodialApp}
            showAcceptDeclineSection={false}
            loadGuid={G.getGuidFromObject(load)}
            selectedCarrierDriverRate={R.path([GC.SYSTEM_LIST_TELS, 0, GC.SYSTEM_OBJECT_RATE], load)}
          />
        ),
      },
      [C.CARD_TYPE_DOCUMENTS]: {
        component: <OrderDocumentsCardComponent {...documentsProps} />,
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:documents', 'Documents')} />
        ),
        rightHeaderComponent: (
          <DocumentRightHeader
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            documents={loadDocuments}
            configs={communicationConfigs}
          />
        ),
      },
      [C.CARD_TYPE_DOCUMENTS_POP]: {
        component: <OrderDocumentsCardComponent {...documentsProps} documents={popDocuments} />,
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:pop-documents', 'POP Documents')} />
        ),
        rightHeaderComponent: (
          <DocumentRightHeader
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            documents={loadDocuments}
            configs={communicationConfigs}
            proofType={GC.DOCUMENT_PROOF_TYPE_POP}
          />
        ),
      },
      [C.CARD_TYPE_DOCUMENTS_POD]: {
        component: <OrderDocumentsCardComponent {...documentsProps} documents={podDocuments} />,
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:pod-documents', 'POD Documents')} />
        ),
        rightHeaderComponent: (
          <DocumentRightHeader
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            documents={loadDocuments}
            configs={communicationConfigs}
            proofType={GC.DOCUMENT_PROOF_TYPE_POD}
          />
        ),
      },
      [C.CARD_TYPE_MESSAGE_CENTER]: {
        leftHeaderComponent: <ChatLeftHeaderComponent />,
        component: (
          <OrderMessageCenterCardComponent
            load={load}
            openModal={openModal}
            closeModal={closeModal}
          />
        ),
      },
      [C.CARD_TYPE_PAYMENTS]: {
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:payments', 'Payments')} />
        ),
        rightHeaderComponent: (
          <ActionButtonComponent
            permissions={[PC.CLO_RATE_WRITE]}
            handleClick={() => handleEditCustomerRate(true)}
            text={G.getWindowLocale('titles:edit-customer-rate', 'Edit Customer Rate')}
          />
        ),
        component: (
          <OrderPaymentsCardComponent
            load={load}
            invoices={invoices}
            openModal={openModal}
            closeModal={closeModal}
            autodialApp={autodialApp}
            invoiceTotals={invoiceTotals}
            selectedRate={selectedCustomerRate}
            configCurrency={G.getConfigValueFromStore(
              GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
              R.path(['configGroups', GC.GENERAL_CONFIG_GROUP], loadConfigs),
              GC.DEFAULT_UI_CURRENCY,
            )}
          />
        ),
      },
    };

    return { card: cardTypeMap[cardType], headerBgColor: 'rgba(206, 244, 195, 0.5)' };
  }),
  pure,
);
