import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Flex,
  StickedBox,
  AbsoluteBox,
  IconWrapper,
  RelativeBox,
  ActionButton,
} from '../../../ui';
// features carrier-portal
import { MobileForm } from '../ui';
import {
  renderFields,
  setReasonCodeField,
  dropReasonCodeField,
  formatStatusReasonCodeOptions,
} from '../helpers';
import { getValidationSchema, setInitialFormikValues } from '../settings/fields-settings';
//////////////////////////////////////////////////

const setCurrentFields = (props: Object) => props.setDisplayFields(
  renderFields(
    props.checkInModal,
    props.geoLocationProcess,
    props.fields,
  ));

const enhance = compose(
  withState('file', 'setFile', null),
  withState('statusCode', 'setStatusCode', []),
  withState('displayFields', 'setDisplayFields', []),
  withState('statusReasonCode', 'setStatusReasonCode', []),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => setInitialFormikValues(
      props.defaultValues,
      props.initialValues,
    ),
    validationSchema: (props: Object) => Yup.object().shape(getValidationSchema(props)),
    displayName: 'CheckInLocationForm',
    handleSubmit: (values: Object, { props }: Object) => {
      const { loadGuid, closeModal, carrierMobView, addCarrierStatusMessage } = props;

      const locationPropName = 'telStatusMessageLocation';
      let data = {
        loadGuid,
        [locationPropName]: R.omit(GC.GROUPED_FIELDS.STATUS_MESSAGE_OMIT_ARR, values),
        ...R.pick(GC.GROUPED_FIELDS.STATUS_MESSAGE_PICK_ARR, values),
      };

      if (G.isNilOrEmpty(R.path([GC.FIELD_STATUS_MESSAGE_DATE], data))) {
        data = R.set(R.lensProp([GC.FIELD_STATUS_MESSAGE_DATE]), G.getCurrentDateWithFormat('MM/DD/YYYY LT'), data);
      }

      carrierMobView(true);
      addCarrierStatusMessage(data);
      closeModal();
    },
  }),
  withHandlers({
    handleSetReasonOptions: () => () => false,
    setValues: (props: Object) => (values: Object) => props.setValues(R.mergeRight(
      props.values,
      {
        [GC.FIELD_ZIP]: values.zip,
        [GC.FIELD_CITY]: values.city,
        [GC.FIELD_STATE]: values.state,
        [GC.FIELD_ADDRESS]: values.address,
        [GC.FIELD_COUNTRY]: values.country,
        [GC.FIELD_LOCATION]: values.location,
        [GC.FIELD_LATITUDE]: values.latitude,
        [GC.FIELD_LONGITUDE]: values.longitude,
        [GC.FIELD_STATUS_MESSAGE_DATE]: values.statusDate,
      },
    )),
    resetForm: (props: Object) => () => {
      props.setValues(R.mergeRight(props.values, props.initialValues));
      props.closeModal();
    },
    geocodeByPlace: (props: Object) => (
      value: string,
      componentName: string,
      callback: Function,
    ) => {
      const { carrierToken, enterpriseGuid } = props;

      const result = G.geocodeByPlaceAddressWithTokenAndEnterpriseGuid(
        value,
        componentName,
        callback,
        { enterpriseGuid, carrierToken },
      );

      return result;
    },
  }),
  withPropsOnChange(
    ['statusCodeConfigs', 'values'],
    (props: Object) => {
      const { values, displayFields, setStatusCode, setDisplayFields, setStatusReasonCode } = props;

      const options = R.path(['statusCodeConfigs'], props);
      let statusReasonCodeOptions;

      if (G.isNotNilAndNotEmpty(R.path(['statusReasonCodes'], props))) {
        statusReasonCodeOptions = G.createIndexedOptions(R.path(['statusReasonCodes'], props));
      }

      const selectedOptions = options.map((item: Object) => ({
        name: item.displayedValue,
        value: R.or(item.parentGuid, item.guid),
      }));
      setStatusCode(R.prepend(
        {
          value: '',
          name: 'Select Status Code…',
        },
        selectedOptions,
      ));
      const hasNotStatusCodeValue = R.isNil(
        R.path(['values', GC.FIELD_STATUS_MESSAGE_CODE], props),
      );

      if (hasNotStatusCodeValue) return;

      const selectedStatusMessage = R.path(['statusCodeConfigs'], props).find(
        (statusCode: Object) => (
          R.or(
            R.equals(values.statusCode, statusCode.parentGuid),
            R.equals(values.statusCode, statusCode.guid),
          )
        ),
      );

      if (G.isNilOrEmpty(selectedStatusMessage)) return;

      const isSelected = R.has(
        GC.FIELD_STATUS_MESSAGE_REASON_CODE,
        R.indexBy(R.prop('fieldName'), displayFields),
      );
      const statusReasonCodeGuids = JSON.parse(selectedStatusMessage.reasonCodes);

      if (G.isNilOrEmpty(statusReasonCodeGuids)) {
        setStatusReasonCode(null);

        if (isSelected) setDisplayFields(dropReasonCodeField(displayFields));

        return;
      }

      const currentStatusReasonCodeOptions = statusReasonCodeGuids.map(
        (guid: string) => statusReasonCodeOptions[guid],
      );
      setStatusReasonCode(formatStatusReasonCodeOptions(currentStatusReasonCodeOptions));

      if (R.not(isSelected)) {
        setDisplayFields(setReasonCodeField(props.fields, props.displayFields));
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      setCurrentFields(this.props);
    },
  }),
  pure,
);

const TrackingFormComponent = enhance((props: Object) => (
  <AbsoluteBox
    left='0'
    top='0px'
    zIndex='100'
    width='100%'
    height='100%'
    minWidth='320px'
    flexDirection='column'
    bg={G.getTheme('colors.light.black')}
  >
    <MobileForm onSubmit={props.handleSubmit}>
      <Flex
        p='5px 15px'
        width='100%'
        height='60px'
        minWidth='320px'
        fontWeight='bolder'
        alignItems='center'
        justifyContent='flex-start'
        bg={G.getTheme('colors.cobalt')}
        color={G.getTheme('colors.white')}
        boxShadow='5px 8px 9px -2px rgb(21,21,21)'
      >
        <IconWrapper
          ml='15px'
          pr='10px'
          mr='30px'
          cursor='pointer'
          onClick={() => props.closeModal()}
        >
          {I.arrowIconMob()}
        </IconWrapper>
        {props.title}
      </Flex>
      <RelativeBox
        width='100%'
        minWidth='320px'
        overflowY='scroll'
        height='calc(90vh - 140px)'
      >
        <AbsoluteBox
          top='0'
          left='0'
          width='100%'
          p='30px 15px'
          height='max-content'
          flexDirection='column'
        >
          <Flex
            width='100%'
            alignItems='center'
            flexDirection='column'
            justifyContent='space-between'
          >
            <IconWrapper
              mb='15px'
            >
              {I.locationMarker(G.getTheme('colors.white'), '45px', '45px')}
            </IconWrapper>
            <Flex
              mb='15px'
              width='60%'
              fontSize='16px'
              fontWeight='bold'
              textAlign='center'
              justifyContent='center'
              color={G.getTheme('colors.white')}
            >
              {props.formattedAddress}
            </Flex>
          </Flex>
          <FieldsetComponent
            {...props}
            fieldsGroupWidth='100%'
            setValues={props.setValues}
            fields={props.displayFields}
            handlers={{
              geocodeByPlace: props.geocodeByPlace,
              handleSetReasonOptions: props.handleSetReasonOptions,
            }}
            optionsForSelect={{
              statusCode: props.statusCode,
              statusReasonCode: props.statusReasonCode,
            }}
          />
        </AbsoluteBox>
      </RelativeBox>
      <StickedBox
        bottom='0'
        width='100%'
        height='80px'
        minWidth='300px'
        minHeight='80px'
        alignItems='center'
        justifyContent='center'
      >
        <Flex
          width='100%'
          height='100%'
          alignItems='center'
          justifyContent='center'
        >
          <ActionButton
            mt='5px'
            width='280px'
            height='35px'
            type='submit'
            fontSize='16px'
            minWidth='250px'
            cursor='pointer'
            borderRadius='20px'
            fontWeight='bolder'
            alignItems='center'
            justifyContent='center'
            color={G.getTheme('colors.white')}
            bgColor={G.getTheme('colors.cobalt')}
            boxShadow='5px 8px 9px -2px rgb(21,21,21)'
          >
            {G.getWindowLocale('actions:send', 'SEND', { caseAction: 'upperCase' })}
          </ActionButton>
        </Flex>
      </StickedBox>
    </MobileForm>
  </AbsoluteBox>
));

export default TrackingFormComponent;
