import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { Tabs2 } from '../../components/tabs-mui';
import EditReport from '../../components/edit-report';
import { TitlePanel } from '../../components/title-panel';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import PC from '../permission/role-permission';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { ListWrapper, ZOrderWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature charge-report
import {
  settings,
  tableSettings,
  cloChargeFilterParams,
  telChargeFilterParams,
} from './settings';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
} from './actions';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectReportType,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  reportEnhancer,
  withProps(({ reportType }: Object) => ({
    filterProps: G.ifElse(
      R.equals(reportType, GC.CLO_RATE_CHARGE_REPORT),
      cloChargeFilterParams,
      telChargeFilterParams,
    ),
  })),
  withPromptModal(),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);


export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    reportType,
    reportList,
    filterProps,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const tableSettingsToUse = G.getTableSettingsWithMaxHeightByConditions({
    reportList,
    filterParams,
    tableSettings,
    selectedReport,
  });

  const data = {
    loading,
    totalCount,
    pagination,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: false,
    handleTableTitleFilter,
    report: selectedReport,
    useSearchableColumns: true,
    withResizableColumns: true,
    itemList: R.values(itemList),
    useNewTitleFilterInputs: true,
    tableSettings: tableSettingsToUse,
    handleLoadMoreEntities: G.ifElse(loading, () => {}, getItemListRequest),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(filterProps),
    ),
    columnSettings: settings(G.ifElse(
      R.equals(reportType, GC.CLO_RATE_CHARGE_REPORT),
      GC.FIELD_CLO,
      GC.FIELD_TEL,
    )),
  };

  return <Table {...data} />;
};

const getTabs = () => [
  {
    route: routesMap.cloRateChargeReport,
    permissions: [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
    text: G.getWindowLocale('titles:order-rate-charges', 'Order Rate Charges'),
    withCount: R.equals(window.location.pathname, routesMap.cloRateChargeReport),
  },
  {
    route: routesMap.telRateChargeReport,
    text: G.getWindowLocale('titles:trip-rate-charges', 'Trip Rate Charges'),
    withCount: R.equals(window.location.pathname, routesMap.telRateChargeReport),
    permissions: [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
  },
];

const handleClickTab = (index: number) => G.goToRoute(R.path([index, 'route'], getTabs()));

const ChargeReport = (props: Object) => {
  const { reportType, totalCount, getItemListRequest } = props;

  const activeTab = R.findIndex(R.propEq(window.location.pathname, 'route'), getTabs());

  const customTitleComponent = (
    <Tabs2
      tabs={getTabs()}
      count={totalCount}
      activeTab={activeTab}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          version={2}
          withCount={true}
          type={reportType}
          popperWithCount={true}
          hiddenRightFilterInfo={false}
          getItemListRequest={getItemListRequest}
          customTitleComponent={customTitleComponent}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  reportType: makeSelectReportType(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
})(enhance(ChargeReport));
