import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useMemo, useEffect, useCallback } from 'react';
// components
import { Table } from '../../../../components/table';
import { ConfirmComponent } from '../../../../components/confirm';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature claim-management
import { makeSelectAttorneyList, makeSelectInvolvedPartyList } from '../../selectors';
import {
  removeAttorneyRequest,
  getAttorneyListRequest,
  removeInvolvedPartyRequest,
  getInvolvedPartyListRequest,
} from '../../actions';
//////////////////////////////////////////////////

const TableComponent = (props: Object) => {
  const { dispatch, openModal, itemList, isAttorney, handleCreateOrUpdateItem } = props;

  const handleRemoveItem = useCallback((guid: string) => {
    const textLocale = G.getWindowLocale(
      'messages:confirm-delete-entity',
      'Are you sure you want to delete this entity?',
    );

    const component = <ConfirmComponent textLocale={textLocale} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:delete', 'Delete'),
            action: () =>
              dispatch(G.ifElse(isAttorney, removeAttorneyRequest, removeInvolvedPartyRequest)(guid)),
          },
        ],
      },
    };

    openModal(modal);
  }, []);

  useEffect(() => {
    if (R.isNil(itemList)) {
      dispatch(G.ifElse(isAttorney, getAttorneyListRequest, getInvolvedPartyListRequest)());
    }
  }, []);

  const tableProps = useMemo(() => {
    const columnSettings = {
      [GC.FIELD_FIRST_NAME]: {
        width: 150,
        name: 'titles:first-name',
      },
      [GC.FIELD_LAST_NAME]: {
        width: 150,
        name: 'titles:last-name',
      },
      [`${GC.FIELD_CONTACT_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
        width: 150,
        getDataByPath: true,
        name: 'titles:contact-type',
      },
      [GC.FIELD_EMAIL]: {
        width: 180,
        name: 'titles:last-name',
      },
      [GC.FIELD_PHONE]: {
        width: 120,
        name: 'titles:phone',
      },
      [GC.FIELD_COMMENTS]: {
        width: 300,
        name: 'titles:comments',
      },
      [GC.FIELD_CREATED_BY]: {
        width: 150,
        name: 'titles:created-by',
      },
      [GC.FIELD_CREATED_DATE]: {
        width: 150,
        type: 'date',
        name: 'titles:created-date',
      },
      [GC.FIELD_LAST_MODIFIED_BY]: {
        width: 150,
        name: 'titles:updated-by',
      },
      [GC.FIELD_LAST_MODIFIED_DATE]: {
        width: 150,
        type: 'date',
        name: 'titles:updated-date',
      },
    };

    const reportFields = R.compose(
      G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
      R.keys,
      R.omit(G.ifElse(isAttorney, [`${GC.FIELD_CONTACT_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`], [])),
    )(columnSettings);

    const actionButtons = [
      {
        iconName: 'pencil',
        permissions: [PC.CLAIM_WRITE],
        action: (_: string, entity: Object) => handleCreateOrUpdateItem(entity),
      },
      {
        iconName: 'trash',
        action: handleRemoveItem,
        permissions: [PC.CLAIM_WRITE],
      },
    ];

    const tableSettings = {
      rowHeight: 40,
      cellFontSize: 11,
      titleFontSize: 11,
      maxHeight: '400px',
      titleRowHeight: 32,
      tableRowHeight: 40,
      allowEditBtn: true,
      useMainColors: true,
      checkBoxCellWidth: 55,
      expandableItems: false,
      allowSelectItems: false,
      checkBoxCellJustifyContent: 'unset',
    };

    return {
      tableSettings,
      actionButtons,
      columnSettings,
      loading: false,
      allChecked: false,
      withoutWaypoint: true,
      report: { fields: reportFields },
    };
  }, []);

  return <Table {...tableProps} itemList={itemList} />;
};

const Attorney = (props: Object) => {
  const itemList = useSelector(makeSelectAttorneyList());

  return <TableComponent {...props} isAttorney={true} itemList={itemList} />;
};

const InvolvedParty = (props: Object) => {
  const itemList = useSelector(makeSelectInvolvedPartyList());

  return <TableComponent {...props} itemList={itemList} />;
};

export {
  Attorney,
  InvolvedParty,
};
