import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_ID]: {
    width: 200,
    name: 'titles:id',
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    name: 'titles:type',
    customComponent: R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_TYPE]),
    ),
  },
  [GC.FIELD_BRANCH_GUID]: {
    width: 200,
    type: 'enterpriseGuid',
    name: 'titles:branch-name',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
};

export const report = {
  fields: [
    { sequence: 0, name: GC.FIELD_TYPE },
    { sequence: 1, name: GC.FIELD_ID },
    { sequence: 2, name: GC.FIELD_NAME },
    { sequence: 3, name: GC.FIELD_BRANCH_GUID },
    { sequence: 4, name: GC.FIELD_CREATED_BY },
    { sequence: 5, name: GC.FIELD_LAST_MODIFIED_BY },
  ],
};
