import * as R from 'ramda';
import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// components
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import { getCreateOrUpdateItemModal } from '../helpers';
import {selectGeneralDetails, selectFormGroupFilters } from '../selectors';
import { getItemListSuccess, removeEntityRequest, createOrUpdateEntityRequest } from '../actions';
//////////////////////////////////////////////////

export const useFormGroupOperations = (props: Object) => {
  const {
    isReport,
    itemList,
    groupName,
    openModal,
    openLoader,
    closeLoader,
    configGroup,
    defaultValues,
    endpoints = {},
    makeInitialValues,
    primaryObjectGuid,
    useGetListRequest,
    makeRequestPayload,
    customRemoveHandler,
    primaryObjectGuidKey,
    customCreateOrUpdateHandler,
    getItemListRequestMethod = 'get',
  } = props;

  const generalDetails = useSelector(selectGeneralDetails);
  const formGroupFilters = useSelector(selectFormGroupFilters);

  const dispatch = useDispatch();

  const getItemListRequest = useCallback(async () => {
    const { list, getListByGuid } = endpoints;

    if (G.isAllNilOrEmpty([list, getListByGuid])) return;

    let options = {
      params: { [primaryObjectGuidKey]: primaryObjectGuid },
    };

    if (G.isFunction(makeRequestPayload)) options = makeRequestPayload(props);

    let endpoint;

    if (G.isFunction(getListByGuid)) {
      endpoint = getListByGuid(props);
    } else {
      endpoint = R.prop(list, endpointsMap);
    }

    openLoader();

    const res = await sendRequest(getItemListRequestMethod, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { successCallback } = props;

      if (G.isFunction(successCallback)) {
        successCallback({ data, groupName });
      } else {
        dispatch(getItemListSuccess({ data, groupName }));
      }
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, [endpoints, formGroupFilters]);

  let createOrUpdateDeps = [itemList, groupName, configGroup, customCreateOrUpdateHandler];

  if (R.equals(groupName, 'advancePaymentList')) createOrUpdateDeps = R.append(generalDetails, createOrUpdateDeps);

  const handleCreateOrUpdateItem = useCallback(async (entity: Object) => {
    const isEditMode = G.isNotNil(G.getGuidFromObject(entity));
    const endpointName = G.getPropFromObject('createOrUpdate', endpoints);

    let initialValues = G.ifElse(
      isEditMode,
      entity,
      R.assoc(primaryObjectGuidKey, primaryObjectGuid, defaultValues),
    );

    if (G.isFunction(makeInitialValues)) initialValues = makeInitialValues(initialValues, props);

    let method = G.ifElse(isEditMode, 'put', 'post');

    if (R.includes(groupName, ['driverHours'])) method = 'put';

    let submitAction = (values: Object, additionalOptions: Object) => {
      const reqData = {
        method,
        values,
        isReport,
        groupName,
        additionalOptions,
        endpoint: G.getPropFromObject(endpointName, endpointsMap),
        getItemListRequest: useGetListRequest ? () => getItemListRequest(isReport) : null,
      };

      dispatch(createOrUpdateEntityRequest(reqData));
    };

    if (G.isFunction(customCreateOrUpdateHandler)) {
      submitAction = (values: Object, options: Object) =>
        customCreateOrUpdateHandler(R.assoc('values', values, props), options);
    }

    const modal = getCreateOrUpdateItemModal({
      ...props,
      isEditMode,
      submitAction,
      initialValues,
    });

    openModal(modal);
  }, createOrUpdateDeps);

  const handleRemoveItem = useCallback((guid: string) => {
    const { itemTitleArr } = props;

    const component = (
      <ConfirmComponent
        name={G.getWindowLocale(...itemTitleArr)}
        textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
      />
    );

    const endpointName = G.getPropFromObject('remove', endpoints);

    let endpoint = G.getPropFromObject(endpointName, endpointsMap);

    if (G.isFunction(endpoint)) endpoint = endpoint(guid);

    let removeAction = () => dispatch(removeEntityRequest({ guid, isReport, endpoint, groupName }));

    if (G.isFunction(customRemoveHandler)) {
      removeAction = () => customRemoveHandler(props, guid);
    }

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: removeAction,
            name: G.getWindowLocale('actions:remove', 'Remove'),
          },
        ],
      },
    };

    openModal(modal);
  }, [itemList, groupName, customRemoveHandler]);

  useEffect(() => {
    if (R.isNil(itemList)) getItemListRequest();
  }, []);

  return {
    handleRemoveItem,
    getItemListRequest,
    handleCreateOrUpdateItem,
  };
};
