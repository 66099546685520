import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { COUNTRY_OPTIONS } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// forms
import { Fieldset2 } from '../../../forms';
// ui
import { Box, Flex, scrollableContainerCss4px } from '../../../ui';
// feature geo-fencing-zone
import {
  geoFencingZoneFieldName,
  zoneAddressPointsFields,
  defaultGeoFencingZoneFields,
  defaultZoneAddressPointsFields,
  validationSchemaGeoFencingZoneObject,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: Yup.object().shape(validationSchemaGeoFencingZoneObject),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultGeoFencingZoneFields,
      initialValues,
    ),
  }),
  withHandlers({
    handleAddSection: ({ values, setFieldValue }: Object) => () => {
      const zoneAddressPoints = R.append(
        defaultZoneAddressPointsFields(),
        R.prop(GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS, values),
      );

      setFieldValue(GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS, zoneAddressPoints);
    },
  }),
  pure,
);

const zoneEnhance = compose(
  withHandlers({
    handleSetAddressPointValues: (props: Object) => (entity: Object) => {
      const filteredEntity = R.filter(G.isNotNilAndNotEmpty, entity);

      if (G.isNilOrEmpty(filteredEntity)) return;

      const { form } = props;
      const { values, setValues } = form;
      const { zip, label, city, state, country } = entity;

      const pathArray = R.split('.', label);
      let sectionValues = null;
      const path = R.take(2, pathArray);
      const section = R.path(path, values);
      const searchedFieldName = R.last(pathArray);

      if (R.equals(searchedFieldName, 'searchCity')) {
        const cities = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(city),
        )(section.cities);

        const states = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(state),
        )(section.states);

        const countries = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(country),
        )(section.countries);

        sectionValues = R.mergeRight(R.path(path, values), { cities, states, countries, searchCity: null });
      }

      if (R.equals(searchedFieldName, 'searchZip')) {
        const zipCodes = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(zip),
        )(section.zipCodes);

        sectionValues = R.mergeRight(R.path(path, values), { zipCodes, searchZip: null });
      }

      const zoneAddressPoints = R.compose(
        R.values,
        R.prop('zoneAddressPoints'),
        R.assocPath(path, sectionValues),
      )(values);

      const newValues = R.assoc('zoneAddressPoints', zoneAddressPoints, values);
      setValues(newValues);
    },
  }),
);

const ZoneAddressPointsSection = zoneEnhance((props: Object) => {
  const { type, form, remove, handleSetAddressPointValues } = props;

  const darkBlueColor = G.getTheme('colors.dark.blue');
  const borderColor = G.getTheme('colors.light.darkGrey');
  const zoneAddressPoints = R.values(R.path(['values', GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS], form));

  const handleRemoveSection = (index: number) => {
    if (R.gt(R.length(zoneAddressPoints), 1)) remove(index);
  };

  return (
    <Box
      mt={15}
      pr='3px'
      overflowY='auto'
      maxHeight='calc(87vh - 195px)'
      css={scrollableContainerCss4px}
      id='zone_address_points_wrapper'
    >
      {
        zoneAddressPoints.map((_: any, index: number) => (
          <Box
            pt={15}
            px={20}
            key={index}
            borderRadius={8}
            border='1px solid'
            borderColor={borderColor}
            mt={G.ifElse(G.isZero(index), 0, 15)}
          >
            <Flex
              m='5px 10px'
              cursor='pointer'
              width='max-content'
              onClick={() => handleRemoveSection(index)}
            >
              <Box color={darkBlueColor} fontWeight='bold'>
                {G.getWindowLocale('titles:address-point', 'Address Point')}
              </Box>
              <Box ml={15}>{I.trash()}</Box>
            </Flex>
            <Fieldset2
              {...form}
              {...G.getFormikProps(props)}
              key={index}
              fieldsWrapperStyles={{ mt: 25 }}
              countryOptions={COUNTRY_OPTIONS}
              fields={zoneAddressPointsFields(index, type)}
              customSelectLocationFunction={handleSetAddressPointValues}
            />
          </Box>
        ))
      }
    </Box>
  );
});

const GeoFencingZoneForm = (props: Object) => {
  const { type, values, handleSubmit, handleAddSection } = props;

  const allowAdd = R.or(
    G.notEquals(type, 'report'),
    R.lt(R.pathOr(0, [GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS, 'length'], values), 5),
  );

  return (
    <form onSubmit={handleSubmit}>
      <Flex>
        <Fieldset2
          {...G.getFormikProps(props)}
          fieldsWrapperStyles={{ mt: 10 }}
          fields={R.of(Array, geoFencingZoneFieldName)}
        />
        {
          allowAdd &&
          <Flex
            m='5px'
            ml={20}
            cursor='pointer'
            width='max-content'
            onClick={handleAddSection}
          >
            <Box fontWeight='bold' color={G.getTheme('colors.light.blue')}>
              {G.getWindowLocale('titles:add-address-point', 'Add Address Point')}
            </Box>
            <Box ml={10}>
              {I.plusRound()}
            </Box>
          </Flex>
        }
      </Flex>
      <FieldArray
        name={GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}
        render={(arrayHelpers: Object) => <ZoneAddressPointsSection {...arrayHelpers} type={type} />}
      />
      <FormFooter2 boxStyles={{ mt: 25 }} />
    </form>
  );
};

export default enhance(GeoFencingZoneForm);
