import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const getCrossDockLocationsSuccess = createAction('getCrossDockLocationsSuccess');
export const getGeneralConfigsForTelConfigSuccess = createAction('getGeneralConfigsForTelConfigSuccess');
export const getAccessorialConfigListForTelConfigSuccess = createAction('getAccessorialConfigListForTelConfigSuccess');
// default carrier accessorial
export const removeDefaultCarrierAccessorialRequest = createAction('removeDefaultCarrierAccessorialRequest');
export const removeDefaultCarrierAccessorialSuccess = createAction('removeDefaultCarrierAccessorialSuccess');
export const getDefaultCarrierAccessorialListRequest = createAction('getDefaultCarrierAccessorialListRequest');
export const getDefaultCarrierAccessorialListSuccess = createAction('getDefaultCarrierAccessorialListSuccess');
export const createOrUpdateDefaultCarrierAccessorialRequest =
  createAction('createOrUpdateDefaultCarrierAccessorialRequest');
export const createOrUpdateDefaultCarrierAccessorialSuccess =
  createAction('createOrUpdateDefaultCarrierAccessorialSuccess');
