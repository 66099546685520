import React from 'react';
// components
import { QuickFilterOutsideControl3 } from '../../../components/quick-filter';
// helpers
import * as G from '../../../helpers';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const QuickFilterOutsideControl3Component = () => {
  const description = '<QuickFilterOutsideControl3 /> component manages an input field used for filtering content.';

  const usage = (
    <ul>
      <li>Parameter `<i>type</i>` a specifies the type of input.</li>
      <li>Parameter `<i>inputStyles</i>` are the styling properties for the input element.</li>
      <li>Parameter `<i>label</i>` is the text displayed as the label for the input field.</li>
      <li>Parameter `<i>placeholder</i>` is a placeholder text displayed within the input field.</li>
      <li>Parameter `<i>handleSetFilter</i>` is the function to set the filter value in an external.</li>
    </ul>
  );

  const handleSetFilter = (value: string) => {
    window.alert(`Filter value is ${value}`);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      description={description}
      name='QuickFilterOutsideControl3'
      path='src/components/quick-filter'
    >
      <QuickFilterOutsideControl3
        label='Filter by Name'
        handleSetFilter={handleSetFilter}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
      />
    </ComponentStoryWrapper>
  );
};

export default QuickFilterOutsideControl3Component;
