import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const onZoomSwitched = createAction('onZoomSwitched');
export const setZoomToStore = createAction('setZoomToStore');
export const setValueToStore = createAction('setValueToStore');
export const setInitialState = createAction('setInitialState');
export const setFilterToStore = createAction('setFilterToStore');
export const setFiltersToStore = createAction('setFiltersToStore');
export const onGroupBySwitched = createAction('onGroupBySwitched');
export const setGroupByToStore = createAction('setGroupByToStore');
export const setDayFilterToStore = createAction('setDayFilterToStore');
export const setDateRangeFilters = createAction('setDateRangeFilters');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const setSearchRadiusValue = createAction('setSearchRadiusValue');
export const setSearchDateToStore = createAction('setSearchDateToStore');
export const setLoadRefFilterValue = createAction('setLoadRefFilterValue');
export const toggleUnassignedLoads = createAction('toggleUnassignedLoads');
export const getDriversCardRequest = createAction('getDriversCardRequest');
export const getDriversCardSuccess = createAction('getDriversCardSuccess');
export const setMinEmptyHoursValue = createAction('setMinEmptyHoursValue');
export const setWarningPeriodConfig = createAction('setWarningPeriodConfig');
export const getDriverCardsInfoRequest = createAction('getDriverCardsInfoRequest');
export const getDriverCardsInfoSuccess = createAction('getDriverCardsInfoSuccess');
export const filterByPlaceAutocomplete = createAction('filterByPlaceAutocomplete');
export const getUnassignedLoadsSuccess = createAction('getUnassignedLoadsSuccess');
export const getUnassignedLoadsRequest = createAction('getUnassignedLoadsRequest');
export const getTelNotificationsSuccess = createAction('getTelNotificationsSuccess');
export const getTelNotificationsRequest = createAction('getTelNotificationsRequest');
export const hideTelNotificationsRequest = createAction('hideTelNotificationsRequest');
export const hideTelNotificationsSuccess = createAction('hideTelNotificationsSuccess');
export const refreshAssignedLoadsRequest = createAction('refreshAssignedLoadsRequest');
export const getGroupedAssignedLoadsRequest = createAction('getGroupedAssignedLoadsRequest');
export const getMoreNotGroupedAssignedLoads = createAction('getMoreNotGroupedAssignedLoads');
export const setSearchPlaceAutocompleteValue = createAction('setSearchPlaceAutocompleteValue');
export const createDriverOnDriversCardRequest = createAction('createDriverOnDriversCardRequest');
export const createDriverOnDriversCardSuccess = createAction('createDriverOnDriversCardSuccess');
export const getNotGroupedAssignedLoadsRequest = createAction('getNotGroupedAssignedLoadsRequest');
export const getNotGroupedAssignedLoadsSuccess = createAction('getNotGroupedAssignedLoadsSuccess');
export const getDriverLoadsByDispatcherRequest = createAction('getDriverLoadsByDispatcherRequest');
export const getDriverLoadsByDispatcherSuccess = createAction('getDriverLoadsByDispatcherSuccess');
export const getDriverLoadsByDispatchGroupRequest = createAction('getDriverLoadsByDispatchGroupRequest');
export const getDriverLoadsByDispatchGroupSuccess = createAction('getDriverLoadsByDispatchGroupSuccess');
// pending activity
export const createPendingActivitySuccess = createAction('createPendingActivitySuccess');
export const updatePendingActivitySuccess = createAction('updatePendingActivitySuccess');
export const removePendingActivitySuccess = createAction('removePendingActivitySuccess');
// unavailable period
export const createUnavailablePeriodSuccess = createAction('createUnavailablePeriodSuccess');
export const updateUnavailablePeriodSuccess = createAction('updateUnavailablePeriodSuccess');
export const removeUnavailablePeriodSuccess = createAction('removeUnavailablePeriodSuccess');
// driver card color
export const updateTripDriverCardColorSuccess = createAction('updateTripDriverCardColorSuccess');
