import * as R from 'ramda';
import React from 'react';
// components
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner-events
import { Tel } from './related-clo-tels';
import Stop from './stop-for-related-trip';
//////////////////////////////////////////////////

const commonOptions = [
  { key: 'eventType' },
  {
    key: GC.FIELD_EARLY_DATE,
    name: 'titles:early-date',
  },
  {
    name: 'titles:late-date',
    key: GC.FIELD_LOAD_EVENT_LATE_DATE,
  },
];

const eventOptions = R.prepend({
  name: 'titles:stop',
  key: GC.FIELD_CLO_EVENT_INDEX,
}, commonOptions);

const telEventOptions = R.prepend({
  name: 'titles:stop',
  key: GC.FIELD_TEL_EVENT_INDEX,
}, commonOptions);

// TODO: remove renderEvents after testing
const renderEvents = (props: Object, isForTel: boolean=false) => {
  const { data, btnText, clickHandler } = props;
  if (G.isNilOrEmpty(data)) return null;
  const options = G.ifElse(isForTel, telEventOptions, eventOptions);
  return (
    <Box fontSize={12}>
      {
        data.map((event: Object, i: number) => (
          <Flex p='6px' key={`route-order-event-${i}-${event.guid}`}>
            {
              options.map((option: Object, optionIndex: number) => (
                <Flex mr='6px' key={`route-order-event-option-${optionIndex}-${event.guid}`}>
                  {
                    G.isNotNil(option.name) &&
                    <Box mr='6px' fontWeight={700}>{G.getWindowLocale(option.name, '')}:</Box>
                  }
                  <Box>{R.propOr('-', option.key, event)},</Box>
                </Flex>
              ))
            }
            <Box mr='6px' fontWeight={700}>{G.getWindowLocale('titles:address', 'Address')}:</Box>
            <Box mr='6px'>{G.concatLocationFirstAndSecondAddresses(R.prop(GC.FIELD_LOCATION, event))}</Box>
            <Box mr='6px'>{G.concatLocationFields(R.prop(GC.FIELD_LOCATION, event))}</Box>
            {
              G.isFunction(clickHandler) &&
              <Box
                ml='auto'
                p='4px 8px'
                cursor='pointer'
                borderRadius='4px'
                color={G.getTheme('colors.white')}
                bg={G.getTheme('colors.dark.blue')}
                onClick={() => clickHandler(event)}
              >{btnText}</Box>
            }
          </Flex>
        ))
      }
    </Box>
  );
};

const renderCloEvents = (props: Object) => {
  const { data, btnText, clickHandler } = props;

  if (G.isNilOrEmpty(data)) return null;

  const blueColor = G.getTheme('colors.dark.blue');
  const manageTrailersFrom = G.getAmousConfigByNameFromWindow(GC.TRAILER_MANAGE_TRAILERS_FROM);
  const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

  return (
    <Flex p={10} fontSize={12} width='fit-content' alignItems='flex-start'>
      {
        data.map((stop: Object, i: number) => (
          <Box key={`unassigned-event-${i}`}>
            <Box p='4px 10px' cursor='pointer' color={blueColor} onClick={() => clickHandler(stop)}>
              {btnText}
            </Box>
            <Stop
              {...props}
              key={i}
              stop={stop}
              isCloStop={true}
              hideActions={true}
              useStopTrailers={useStopTrailers}
              lastStopInTel={R.pathEq(R.inc(i), ['length'], data)}
            />
          </Box>
        ))
      }
    </Flex>
  );
};

const EventDetails = (props: Object) => (
  <Box
    key={props.key}
    overflow='auto'
    width='fit-content'
    maxWidth='calc(100vw - 65px)'
    boxShadow='inset -1px -1px 0px 1px rgba(221, 221, 221, 0.5)'
  >
    {renderCloEvents(props)}
  </Box>
);

export const TelDetails = (props: Object) => {
  const { key, data } = props;

  const manageTrailersFrom = G.getAmousConfigByNameFromWindow(GC.TRAILER_MANAGE_TRAILERS_FROM);
  const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

  return (
    <Box
      key={key}
      width='calc(100vw - 65px)'
      boxShadow='inset -1px -1px 0px 1px rgba(221, 221, 221, 0.5)'
    >
      <Tel tel={data} showStopsOnly={true} useStopTrailers={useStopTrailers} />
    </Box>
  );
};

export const UnassignedEvents = (props: Object) => {
  if (G.isNilOrEmpty(props.data)) return null;
  return (
    <PopperComponent
      zi={21}
      type='hover'
      position='right-start'
      borderColor={G.getTheme('colors.boxShadowBlue')}
      content={
        <EventDetails
          data={props.data}
          clickHandler={props.addEventToPlanner}
          btnText={G.getWindowLocale('actions:add-to-planner', 'Add To Planner')} />
      }
    >
      <Flex width={154} cursor='pointer' justifyContent='space-between'>
        <Box>
          {G.getWindowLocale('titles:unassigned-events', 'Unassigned Events')}
        </Box>
        <Flex
          width={20}
          height={20}
          fontSize={14}
          borderRadius={10}
          justifyContent='center'
          bg={G.getTheme('colors.dark.blue')}
          color={G.getTheme('colors.light.mainLight')}
        >
          {R.path(['data', 'length'], props)}
        </Flex>
      </Flex>
    </PopperComponent>
  );
};

export default EventDetails;
