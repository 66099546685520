import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// features
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature configs
import * as A from './actions';
import {
  sequencesCLOConfigsNamesArray,
  sequencesTELConfigsNamesArray,
  sequencesGeneralConfigsNamesArray,
  sequencesInvoiceConfigsNamesArray,
  referenceTypesCLOConfigsNamesArray,
  referenceTypesTELConfigsNamesArray,
  referenceTypesMasterConfigsNamesArray } from './settings/groups-config';
//////////////////////////////////////////////////

const handleSwitchBranchLogic = createLogic({
  type: receivedSwitchBranchSuccess,
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(A.cleanConfigState());
    done();
  },
});

const getGuids = (configs: Object, names: Array) => (
  R.compose(
    R.uniq(),
    R.reduce((acc: Array, item: Object) => {
      if (R.is(Array, item.inheritedValue)) {
        return R.concat(acc, item.inheritedValue);
      }
      return R.concat(acc, R.of(Array, item.inheritedValue));
    }, []),
    R.filter((item: Object) => (
      R.and(
        R.includes(item.name, names),
        G.isNotNil(item.inheritedValue),
      )
    )),
  )(configs)
);

const handleGetConfigByGroupSuccess = createLogic({
  type: A.getConfigByGroupSuccess,
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    if (R.equals(action.payload.groupName, GC.CLO_CONFIG_GROUP)) {
      const refTypesGuids = getGuids(
        action.payload.res.configs,
        referenceTypesCLOConfigsNamesArray,
      );
      const sequencesGuids = getGuids(
        action.payload.res.configs,
        sequencesCLOConfigsNamesArray,
      );
      dispatch(A.getReferenceTypesGuidsNamesMapRequest(refTypesGuids));
      dispatch(A.getSequencesGuidNameMapRequest(sequencesGuids));
    } else if (R.equals(action.payload.groupName, GC.TEL_CONFIG_GROUP)) {
      const refTypesGuids = getGuids(
        action.payload.res.configs,
        referenceTypesTELConfigsNamesArray,
      );
      const sequencesGuids = getGuids(
        action.payload.res.configs,
        sequencesTELConfigsNamesArray,
      );
      dispatch(A.getReferenceTypesGuidsNamesMapRequest(refTypesGuids));
      dispatch(A.getSequencesGuidNameMapRequest(sequencesGuids));
    } else if (R.equals(action.payload.groupName, GC.OPTIONAL_CONFIG_GROUP)) {
      const refTypesGuids = getGuids(
        action.payload.res.configs,
        referenceTypesMasterConfigsNamesArray,
      );
      dispatch(A.getReferenceTypesGuidsNamesMapRequest(refTypesGuids));
    } else if (R.equals(action.payload.groupName, GC.GENERAL_CONFIG_GROUP)) {
      const sequencesGuids = getGuids(
        action.payload.res.configs,
        sequencesGeneralConfigsNamesArray,
      );
      dispatch(A.getSequencesGuidNameMapRequest(sequencesGuids));
    } else if (R.equals(action.payload.groupName, GC.INVOICE_CONFIG_GROUP)) {
      const sequencesGuids = getGuids(
        action.payload.res.configs,
        sequencesInvoiceConfigsNamesArray,
      );
      dispatch(A.getSequencesGuidNameMapRequest(sequencesGuids));
    } else if (R.equals(action.payload.groupName, GC.TEMPLATES_CONFIG_GROUP)) {
      const locationTypes = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
        { dropdowns: R.indexBy(R.prop('name'), action.payload.res.dropdowns) },
        GC.TEMPLATES_LOCATION_TYPE,
      );
      dispatch(A.setAvailableLocationTypes(locationTypes));
    }
    done();
  },
});

export default [
  handleSwitchBranchLogic,
  handleGetConfigByGroupSuccess,
];
