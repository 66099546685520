import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// common
import { globalCleanReports } from '../../common/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import * as GC from '../../constants';
// feature invoice/carrier
import { setInitialState } from './actions';
//////////////////////////////////////////////////

const handleSwitchBranchOrChangeLocationLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    GC.LOCATION_CHANGE_ACTION,
    receivedSwitchBranchSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    const { type } = action;

    const pathname = R.pathOr('', ['payload', 'pathname'], action);

    if (R.and(
      R.equals(type, GC.LOCATION_CHANGE_ACTION),
      R.any(
        (item: string) => R.includes(item, pathname),
        ['carrier_profile', 'carrier_contract', 'customer_contract'],
      ),
    )) return done();

    dispatch(setInitialState());
    done();
  },
});

export default [handleSwitchBranchOrChangeLocationLogic];
