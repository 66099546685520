import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps } from 'react-recompose';
// components
import { Table } from '../../../../components/table';
import { FormGroupTable } from '../../../../components/form-group-table';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// features
import PC from '../../../permission/role-permission';
import { setQuickFilterParams, getQuickFilteredListRequest } from '../../../report-format/actions';
// hocs
import { withAsyncAccessorials } from '../../../../hocs/with-async-accessorials';
// feature fleet
import { enhanceMonthlyPayrollDeduction } from '../../driver/components/edit-compensation-tab';
import {
  AccessorialsFormGroupTable,
  PayrollAccessorialsFormGroupTable } from '../../components/accessorials-form-group-table';
//
import {
  monthlyPayrollDeductionReport,
  monthlyPayrollDeductionTableSettings,
  getMonthlyPayrollDeductionColumnSettings } from '../../driver/settings/column-settings';
// feature fleet/driver
import CompensationFormGroupTable from '../../driver/components/compensation-form-group-table';
// feature fleet/vendor
import {
  makeSelectBranchConfigs,
  makeSelectVendorAccessorials,
  makeSelectVendorCompensations,
  makeSelectPayrollAssessorialList,
  makeSelectMonthlyPayrollDeduction,
} from '../selectors';
import {
  createVendorAccessorialRequest,
  removeVendorAccessorialRequest,
  updateVendorAccessorialRequest,
  removePayrollAssessorialRequest,
  createVendorCompensationRequest,
  removeVendorCompensationRequest,
  updateVendorCompensationRequest,
  togglePayrollAssessorialDetails,
  deleteMonthlyPayrollDeductionRequest,
  toggleMonthlyPayrollDeductionDetails,
  createOrUpdatePayrollAssessorialRequest,
  createOrUpdateMonthlyPayrollDeductionRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncAccessorials,
  withProps(({ branchConfigs }: Object) => {
    const defaultCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);

    return { defaultCurrency };
  }),
  enhanceMonthlyPayrollDeduction,
  pure,
);

const addAssessorialInfoToEntity = (entities: Array = [], assessorialConfigs: Array) => {
  if (G.isNilOrEmpty(assessorialConfigs)) return entities;

  const indexedConfigs = R.indexBy(
    (config: Object) => G.getParentGuidOrGuidFromObject(config),
    assessorialConfigs,
  );

  return entities.map((item: Object) => {
    const assessorial = R.path([item.payrollAssessorialGuid], indexedConfigs);

    return R.mergeRight(assessorial, item);
  });
};

const CompensationTabComponent = (props: Object) => {
  const {
    initialValues,
    defaultCurrency,
    accessorialsConfigs,
    monthlyPayrollDeduction,
    removeVendorAccessorialRequest,
    createVendorAccessorialRequest,
    updateVendorAccessorialRequest,
    removePayrollAssessorialRequest,
    createVendorCompensationRequest,
    updateVendorCompensationRequest,
    removeVendorCompensationRequest,
    togglePayrollAssessorialDetails,
    handleDeleteMonthlyPayrollDeduction,
    toggleMonthlyPayrollDeductionDetails,
    handleAddOrEditMonthlyPayrollDeduction,
    createOrUpdatePayrollAssessorialRequest,
  } = props;

  return (
    <div>
      <FormGroupTable
        entities={null}
        groupName='monthlyPayrollDeduction'
        handleAddClick={() => handleAddOrEditMonthlyPayrollDeduction(false)}
        panelTitle={G.getWindowLocale('titles:monthly-payroll-deduction', 'Monthly Payroll Deduction')}
      />
      {
        G.isNotNilAndNotEmpty(monthlyPayrollDeduction) &&
        <Table
          report={monthlyPayrollDeductionReport}
          toggle={toggleMonthlyPayrollDeductionDetails}
          itemList={addAssessorialInfoToEntity(monthlyPayrollDeduction, accessorialsConfigs)}
          tableSettings={monthlyPayrollDeductionTableSettings}
          columnSettings={getMonthlyPayrollDeductionColumnSettings({
            handleDelete: handleDeleteMonthlyPayrollDeduction,
            handleEdit: handleAddOrEditMonthlyPayrollDeduction,
            deletePermission: PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE,
          })}
        />
      }
      <PayrollAccessorialsFormGroupTable
        {...props}
        defaultCurrency={defaultCurrency}
        objKey={GC.FIELD_FLEET_VENDOR_GUID}
        objGuid={G.getGuidFromObject(initialValues)}
        removeAction={removePayrollAssessorialRequest}
        handleToggleDetails={togglePayrollAssessorialDetails}
        createOrUpdateAction={createOrUpdatePayrollAssessorialRequest}
      />
      <AccessorialsFormGroupTable
        {...props}
        defaultCurrency={defaultCurrency}
        objKey={GC.FIELD_FLEET_VENDOR_GUID}
        objGuid={G.getGuidFromObject(initialValues)}
        updateAction={updateVendorAccessorialRequest}
        createAction={createVendorAccessorialRequest}
        removeAction={removeVendorAccessorialRequest}
      />
      <CompensationFormGroupTable
        {...props}
        compensationAccessorials={true}
        defaultCurrency={defaultCurrency}
        reportType={GC.VENDOR_COMPENSATION_REPORT}
        createCompensationAction={createVendorCompensationRequest}
        updateCompensationAction={updateVendorCompensationRequest}
        removeCompensationAction={removeVendorCompensationRequest}
      />
    </div>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  branchConfigs: makeSelectBranchConfigs(state),
  accessorials: makeSelectVendorAccessorials(state),
  compensations: makeSelectVendorCompensations(state),
  payrollAssessorialList: makeSelectPayrollAssessorialList(state),
  monthlyPayrollDeduction: makeSelectMonthlyPayrollDeduction(state),
}));

export default connect(mapStateToProps, {
  setQuickFilterParams,
  getQuickFilteredListRequest,
  createVendorAccessorialRequest,
  removeVendorAccessorialRequest,
  updateVendorAccessorialRequest,
  removePayrollAssessorialRequest,
  createVendorCompensationRequest,
  removeVendorCompensationRequest,
  updateVendorCompensationRequest,
  togglePayrollAssessorialDetails,
  deleteMonthlyPayrollDeductionRequest,
  toggleMonthlyPayrollDeductionDetails,
  createOrUpdatePayrollAssessorialRequest,
  createOrUpdateMonthlyPayrollDeductionRequest,
})(enhance(CompensationTabComponent));
