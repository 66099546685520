import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import Events from '../../components/events';
import { LocalLoader } from '../../components/local-loader';
import { ConfirmComponent } from '../../components/confirm';
// features
import DriverRateForm from '../rate/driver';
import NotificationItem from '../notification/components/notification-item';
import { makeSelectAvailableReferenceTypesByScope } from '../reference/selectors';
// forms
import withAppointmentsForm from '../../forms/hocs/with-appointments-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// hocs
import {
  withAsyncTelEvents,
  withSetExpandedContainerOptions,
  withAsyncCloReferenceByEventGuids,
  withComponentDidUpdatePropCallback,
} from '../../hocs';
// ui
import { Box, Flex, StickedBox } from '../../ui';
// feature drivers-card
import Chat from './components/chat';
import { GROUP_TYPE_NONE } from './constants';
import { subtractOneFromNumber } from './helpers';
import {
  EnhancedDocumentForm,
  EnhancedStatusMessageForm,
  EnhancedSelectDropdownForm,
} from './forms';
//////////////////////////////////////////////////

const getRequestAction = (props: Object) => {
  const { groupBy, getGroupedAssignedLoadsRequest, getNotGroupedAssignedLoadsRequest } = props;

  const action = G.ifElse(
    R.equals(groupBy, GROUP_TYPE_NONE),
    getNotGroupedAssignedLoadsRequest,
    getGroupedAssignedLoadsRequest,
  );

  return { action };
};

export const enhanceCardsDateRange = compose(
  withHandlers({
    handleSetFilterDates: (props: Object) => (dates: Object) => {
      const { groupBy, filtersStore, setDateRangeFilters } = props;

      const { filter } = filtersStore;
      const { action } = getRequestAction(props);

      const data = {
        filter,
        groupBy,
        dateTo: G.toEndOfDayFromDate(dates.dateTo),
        dateFrom: G.toStartOfDayFromDate(dates.dateFrom),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      };

      action({ data });
      setDateRangeFilters(dates);
    },
    handleSetFilterQuickDays: (props: Object) => (value: number) => {
      const { groupBy, filtersStore, setFiltersToStore } = props;

      const { filter } = filtersStore;

      const { action } = getRequestAction(props);

      const currentDate = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);

      const dayToUse = R.divide(subtractOneFromNumber(value), 2);

      const dateToToUse = G.addMomentTimeWithFormat(
        currentDate,
        dayToUse,
        'days',
        GC.DEFAULT_DATE_FORMAT,
      );

      const filters = {
        ...filtersStore,
        dateTo: dateToToUse,
        initialFilterDays: value,
        dateFrom: G.subtractMomentTimeWithFormat(
          currentDate,
          dayToUse,
          'days',
          GC.DEFAULT_DATE_FORMAT,
        ),
      };

      const data = {
        filter,
        groupBy,
        dateTo: G.toEndOfDayFromDate(filters.dateTo),
        dateFrom: G.toStartOfDayFromDate(filters.dateFrom),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      };

      action({ data });

      setFiltersToStore(filters);
    },
  }),
  pure,
);

const enhanceDispatchActions = compose(
  withHandlers({
    declineTelRate: (props: Object) => async (data: Object) => {
      try {
        const { rateGuid } = data;
        const { refreshAssignedLoadsRequest } = props;
        const options = {
          params: {
            [GC.FIELD_DECLINE_REASON_CODE_GUID]: R.pathOr(null, [GC.FIELD_DECLINE_REASON_CODE_GUID], data),
          },
        };
        const endpoint = endpointsMap.getTelRateDeclineEndpoint(rateGuid);
        const res = await sendRequest('put', endpoint, options);
        const { status } = res;
        if (G.isResponseSuccess(status)) {
          refreshAssignedLoadsRequest();
        } else {
          G.handleFailResponseSimple(res);
        }
      } catch (error) {
        G.catchSendRequestSimple(error, 'declineTelRate');
      }
    },
    сallStatusCheck: () => async (reqData: Object) => {
      try {
        const options = { data: reqData };
        const res = await sendRequest('post', endpointsMap.callStatusCheck, options);
        const { data, status } = res;
        const resCondition = R.not(
          G.isResponseSuccess(status, data, G.getWindowLocale('messages:success:200-201', 'The request has succeeded')),
        );
        if (resCondition) {
          G.handleFailResponseSimple(res);
        }
      } catch (error) {
        G.catchSendRequestSimple(error, 'сallStatusCheck');
      }
    },
    createDocument: () => async (payload: Object) => {
      try {
        const reqData = G.makeDataForDocument(payload.data);
        const options = { data: reqData };
        const res = await sendRequest('post', endpointsMap.telDocument, options);
        const { data, status } = res;
        const resCondition = R.not(
          G.isResponseSuccess(status, data, G.getWindowLocale('messages:success:200-201', 'The request has succeeded')),
        );
        if (resCondition) {
          G.handleFailResponseSimple(res);
          G.callFunction(payload.callback);
        }
      } catch (error) {
        G.callFunction(payload.callback);
        G.catchSendRequestSimple(error, 'createDocument');
      }
    },
    addStatusMessage: ({ refreshAssignedLoadsRequest }: Object) => async (payload: Object) => {
      try {
        const options = { data: payload };
        const res = await sendRequest('post', endpointsMap.telStatusMessage, options);
        const { status } = res;
        if (G.isResponseSuccess(status)) {
          refreshAssignedLoadsRequest();
        } else {
          G.handleFailResponseSimple(res);
        }
      } catch (error) {
        G.catchSendRequestSimple(error, 'addStatusMessage');
      }
    },
  }),
  withHandlers({
    handleDispatchTelRateAction: (props: Object) => async (data: Object) => {
      try {
        const { closeFixedPopup, refreshAssignedLoadsRequest } = props;
        G.callFunction(closeFixedPopup);
        const { action, telGuid } = data;
        const endpoint = endpointsMap.getTelActionEndpoint(telGuid, action);
        const res = await sendRequest('put', endpoint);
        const { status } = res;
        if (G.isResponseSuccess(status)) {
          refreshAssignedLoadsRequest();
        } else {
          G.handleFailResponseSimple(res);
        }
      } catch (error) {
        G.catchSendRequestSimple(error, 'handleDispatchTelRateAction');
      }
    },
    handleAcceptTelRate: (props: Object) => async (rateGuid: string) => {
      try {
        const { closeFixedPopup, refreshAssignedLoadsRequest } = props;
        G.callFunction(closeFixedPopup);
        const endpoint = endpointsMap.getTelRateAcceptEndpoint(rateGuid);
        const res = await sendRequest('put', endpoint);
        const { status } = res;
        if (G.isResponseSuccess(status)) {
          refreshAssignedLoadsRequest();
        } else {
          G.handleFailResponseSimple(res);
        }
      } catch (error) {
        G.catchSendRequestSimple(error, 'handleAcceptTelRate');
      }
    },
    handleDeclineTelRate: (props: Object) => async ({ tel, rateGuid }: Object) => {
      try {
        const { closeModal, closeFixedPopup, declineTelRate } = props;
        const branchGuid = R.path([GC.FIELD_BRANCH_GUID], tel);
        G.callFunction(closeFixedPopup);
        const component = (
          <EnhancedSelectDropdownForm
            optionRequired={false}
            branchGuid={branchGuid}
            cancelAction={closeModal}
            fieldLabel={G.getWindowLocale('titles:select-reason', 'Select Reason')}
            submitAction={(value: string) => {
              declineTelRate({
                rateGuid,
                [GC.FIELD_DECLINE_REASON_CODE_GUID]: value,
              });
              closeModal();
            }}
          />
        );
        const modal = { p: 15, component, options: {} };
        props.openModal(modal);
      } catch (error) {
        G.catchSendRequestSimple(error, 'handleDeclineTelRate');
      }
    },
    handleCallStatusCheck: (props: Object) => async (telGuid: string) => {
      try {
        const { openModal, closeModal, сallStatusCheck, closeFixedPopup } = props;
        G.callFunction(closeFixedPopup);
        const component = (
          <ConfirmComponent
            textLocale={G.getWindowLocale('message:common-confirmation', 'Are you sure?')}
          />
        );
        const actions = () => {
          сallStatusCheck(R.of(Array, telGuid));
          closeModal();
        };
        const modal = {
          component,
          options: {
            width: '440px',
            height: 'auto',
            movable: false,
            title: G.getWindowLocale('titles:check-status', 'Check Status'),
            controlButtons: [
              {
                type: 'button',
                action: actions,
                margin: '0 12px 0 0',
                name: G.getWindowLocale('actions:send', 'Send'),
              },
            ],
          },
        };
        openModal(modal);
      } catch (error) {
        G.catchSendRequestSimple(error, 'handleCallStatusCheck');
      }
    },
    handleCreateDocument: (props: Object) => async (tel: Object, defaultProof: string) => {
      const { openModal, closeModal, createDocument, closeFixedPopup } = props;

      G.callFunction(closeFixedPopup);
      const telGuid = G.getGuidFromObject(tel);
      const branchGuid = R.path([GC.FIELD_BRANCH_GUID], tel);

      const handleCreateLoadDocument = ({ data, callback }: Object) => {
        const reqData = G.mapObjectEmptyStringFieldsToNull(R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, telGuid, data));

        createDocument({ callback, data: reqData });
        closeModal();
      };

      const component = (
        <EnhancedDocumentForm
          {...props}
          load={tel}
          telGuid={telGuid}
          branchGuid={branchGuid}
          availableDocumentTypes={props}
          handleActionLoadDocument={handleCreateLoadDocument}
          initialValues={{ [GC.FIELD_DOCUMENT_PROOF_TYPE]: R.or(defaultProof, 'NONE') }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-doc', 'Add Doc'),
        },
      };
      openModal(modal);
    },
    handleAddStatusMessage: (props: Object) => async (tel: Object) => {
      const { openModal, closeFixedPopup, addStatusMessage } = props;
      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(tel);
      const branchGuid = R.path([GC.FIELD_BRANCH_GUID], tel);
      const handleCreateLoadStatusMessage = (values: Object) => {
        const data = {
          ...R.pick(GC.GROUPED_FIELDS.STATUS_MESSAGE_PICK_ARR, values),
          loadGuid,
          telStatusMessageLocation: R.omit(GC.GROUPED_FIELDS.STATUS_MESSAGE_OMIT_ARR, values),
        };
        addStatusMessage(data);
      };
      const component = (
        <EnhancedStatusMessageForm
          {...props}
          load={tel}
          telGuid={loadGuid}
          branchGuid={branchGuid}
          entityName={GC.FIELD_TEL}
          handleActionLoadStatusMessage={handleCreateLoadStatusMessage}
          initialValues={{ [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE' }}
        />
      );
      const modal = {
        p: '0px',
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-status-msg', 'Add Status Msg'),
        },
      };
      openModal(modal);
    },
  }),
);

export const enhanceDetails = compose(
  withSetExpandedContainerOptions,
  withHandlers({
    handleOpenTelDetails: (props: Object) => (item: Object) => {
      const { entity, closeFixedPopup, handleSetExpandedContainerOptions } = props;

      G.callFunction(closeFixedPopup);
      const visitPageGuid = G.getGuidFromObject(G.ifElse(
        G.isNilOrEmpty(entity),
        item,
        entity,
      ));

      handleSetExpandedContainerOptions({
        visitPageGuid,
        openContainerAsNewWindow: true,
        componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
      });
    },
  }),
  pure,
);

export const enhanceActions = compose(
  enhanceDetails,
  enhanceDispatchActions,
  withHandlers({
    handleAddTelDriverRate: (props: Object) => (item: Object) => {
      const { entity, openModal, closeFixedPopup, handleCreateTelRate } = props;

      G.callFunction(closeFixedPopup);
      const load = G.ifElse(
        G.isNilOrEmpty(entity),
        item,
        entity,
      );
      const telGuid = R.prop(GC.FIELD_GUID, load);
      const branchGuid = R.path([GC.FIELD_BRANCH_GUID], load);

      const component = (
        <DriverRateForm
          tel={load}
          telGuid={telGuid}
          branchGuid={branchGuid}
          configsNamesArray={GC.ADD_RATE_CONFIGS_ARRAY}
          stopCount={R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], load))}
          handleSendTelRate={(values: Object) =>
            handleCreateTelRate(R.assoc(GC.FIELD_TEL_GUID, telGuid, values))
          }
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
  }),
  pure,
);

export const enhanceNotifications = compose(
  withHandlers({
    handleShowNotifications: (props: Object) => (e: Object, notifications: Object, telGuid: string) => (
      props.openFixedPopup({
        version: 2,
        position: 'right',
        wrapperPadding: '0',
        el: e.currentTarget,
        content: (
          <Flex
            bg='white'
            width='100%'
            maxHeight={500}
            overflowY='auto'
            height='max-content'
            flexDirection='column'
            alignItems='flex-start'
            borderBottom='1px solid'
            borderColor={G.getTheme('colors.light.middleGrey')}
          >
            <StickedBox
              p={16}
              top='0px'
              zIndex={12}
              width='100%'
              bg={G.getTheme('colors.light.mainLight')}
            >
              <Flex justifyContent='space-between'>
                <Box>{G.getWindowLocale('titles:notifications', 'Notifications')}</Box>
                <Box
                  cursor='pointer'
                  color={G.getTheme('colors.light.blue')}
                  onClick={() => props.hideTelNotificationsRequest(telGuid)}
                >
                  {
                    `${
                      G.getWindowLocale('actions:hide', 'Hide')
                      } ${G.getWindowLocale('titles:notifications', 'Notifications')}`
                  }
                </Box>
              </Flex>
            </StickedBox>
            {R.values(notifications).map((notification: Object, index: number) => (
              <NotificationItem
                key={index}
                {...notification}
                withCheckbox={false}
              />
            ))}
          </Flex>
        ),
      })
    ),
  }),
  pure,
);

const enhanceAsyncEvents = compose(
  withAsyncCloReferenceByEventGuids,
  withHandlers({
    handleGetAsyncTelEvents: ({ getAsyncCloReferenceByEventGuids }: Object) => (propName: string, props: Object) => {
      const eventGuids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(G.isLoadTypeClo),
        R.path(['asyncEvents']),
      )(props);

      getAsyncCloReferenceByEventGuids(eventGuids);
    },
  }),
  withAsyncTelEvents,
  withComponentDidUpdatePropCallback({
    propName: 'asyncEvents',
    callbackName: 'handleGetAsyncTelEvents',
  }),
  pure,
);

const EnhancedEvents = enhanceAsyncEvents((props: Object) => {
  const {
    loadGuid,
    loadType,
    branchGuid,
    asyncEvents,
    handleChangeStop,
    availableRefTypes,
    handleChangeAppointment,
    asyncCloReferenceByEventGuids,
  } = props;

  let eventsToUse = [];

  if (G.isNotNil(asyncEvents)) {
    eventsToUse = asyncEvents;
    eventsToUse = R.map(
      (event: Object) => R.assoc(
        GC.FIELD_LOAD_REFERENCES,
        R.filter(
          R.propEq(G.getGuidFromObject(event), GC.FIELD_EVENT_GUID),
          R.or(asyncCloReferenceByEventGuids, []),
        ),
        event,
      ),
      asyncEvents,
    );
  }

  const referenceTypes = R.map(
    (item: Object) => ({ label: R.prop(GC.FIELD_NAME, item), value: R.prop(GC.FIELD_GUID, item) }),
    R.or(availableRefTypes, []),
  );

  return (
    <LocalLoader width={800} localLoaderOpen={R.isNil(asyncEvents)}>
      <Box width={800} overflow='auto'>
        <Events
          loadGuid={loadGuid}
          loadType={loadType}
          events={eventsToUse}
          fromPage='driversCard'
          branchGuid={branchGuid}
          referenceTypes={referenceTypes}
          handleChangeStop={handleChangeStop}
          handleChangeAppointment={handleChangeAppointment}
        />
      </Box>
    </LocalLoader>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  availableRefTypes: makeSelectAvailableReferenceTypesByScope(state),
});

export const enhanceEvents = compose(
  connect(mapStateToProps),
  withAppointmentsForm,
  withHandlers({
    handleShowEvents: (props: Object) => (loadGuid: string, branchGuid: string) => {
      const { openModal, handleChangeStop, availableRefTypes, handleChangeAppointment } = props;

      const component = (
        <EnhancedEvents
          telGuid={loadGuid}
          loadGuid={loadGuid}
          branchGuid={branchGuid}
          loadType={GC.LOAD_TYPE_TEL}
          handleChangeStop={handleChangeStop}
          availableRefTypes={availableRefTypes}
          handleChangeAppointment={handleChangeAppointment}
        />
      );
      const modal = {
        p: '10px',
        component,
        options: {
          outsideCloseButton: true,
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const enhanceChatMessages = compose(
  withHandlers({
    handleOpenChatMessages: ({ openModal }: Object) => (itemProps: Object) => {
      const component = (
        <Chat {...itemProps} />
      );
      const modal = {
        p: '0',
        component,
        options: {
          outsideCloseButton: true,
        },
      };
      openModal(modal);
    },
  }),
  pure,
);
