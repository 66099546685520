import * as R from 'ramda';
import React, { Fragment, useCallback } from 'react';
// features
import { RatePriceImport } from '../../new-import';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_TEMPERATURE_OPTIONS,
  DEFAULT_WEIGHT_UOM_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
} from '../../../helpers/options';
// ui
import { MainActionButton } from '../../../ui';
// feature carrier-profile
import { TableCell } from '../components/table-cells';
import ContractRateForm from '../forms/contract-rate';
import { massDeleteCarrierContractRatePricesRequest } from '../actions';
//////////////////////////////////////////////////

const AdditionalReportTitleComponent = (props: Object) => {
  const {
    dispatch,
    openModal,
    branchGuid,
    equipments,
    selectedList,
    primaryObjectGuid,
    geoFencingZoneList,
    getItemListRequest,
    handleResetListAndPagination,
  } = props;

  const selectListLength = R.length(selectedList);

  const handleOpenRatePriceImport = useCallback(() => {
    const component = (
      <RatePriceImport
        equipments={equipments}
        branchGuid={branchGuid}
        // contractGuid={}
        contractGuid={primaryObjectGuid}
        geoFencingZoneList={geoFencingZoneList}
        getCarrierContractRatesRequest={getItemListRequest}
        configsNamesArray={[GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]}
        resetCarrierContractListAndPagination={handleResetListAndPagination}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        width: '90vw',
        height: '90vh',
        movable: false,
      },
    };

    openModal(modal);
  }, []);

  return (
    <Fragment>
      <MainActionButton
        ml={20}
        mb='3px'
        height={30}
        disabled={G.isZero(selectListLength)}
        onClick={() => dispatch(massDeleteCarrierContractRatePricesRequest(selectedList))}
      >
        {G.getWindowLocale('titles:delete-contract-rates', 'Delete Contract Rates')} ({selectListLength})
      </MainActionButton>
      <MainActionButton
        ml={20}
        mb='3px'
        height={30}
        onClick={handleOpenRatePriceImport}
      >
        {G.getWindowLocale('titles:import', 'Import')}
      </MainActionButton>
    </Fragment>
  );
};

const getAddressPointFieldsByType = (prefix: string) => {
  const localePrefix = G.ifElse(
    R.equals(prefix, GC.FIELD_ORIGIN),
    'titles:origin',
    'titles:destination',
  );

  const getLocaleArr = (key: string) => [localePrefix, key];

  return {
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:cities'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:states'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:countries'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:zip-codes'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`]: {
      name: getLocaleArr('titles:zip-from'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`]: {
      name: getLocaleArr('titles:zip-to'),
    },
  };
};

const fields = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_EFFECTIVE_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:effective-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:expiration-date',
  },
  [GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID]: {
    width: 200,
    searchable: false,
    name: 'titles:origin-zone-name',
    customComponent: ({ data, callbackData }: Object) => (
      <TableCell
        options={R.prop('geoFencingZoneList', callbackData)}
        text={R.prop(GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID, data)}
      />
    ),
  },
  [GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID]: {
    width: 200,
    searchable: false,
    name: 'titles:destination-zone-name',
    customComponent: ({ data, callbackData }: Object) => (
      <TableCell
        options={R.prop('geoFencingZoneList', callbackData)}
        text={R.prop(GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID, data)}
      />
    ),
  },
  ...getAddressPointFieldsByType(GC.FIELD_ORIGIN),
  ...getAddressPointFieldsByType(GC.FIELD_DESTINATION),
  [GC.FIELD_RATE]: {
    width: 150,
    type: 'number',
    name: 'titles:rate',
  },
  [`${GC.FIELD_EQUIPMENTS}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    collection: true,
    name: 'titles:equipments',
  },
  [`${GC.FIELD_SERVICE_TYPES}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    collection: true,
    name: 'titles:service-type',
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 150,
    name: 'titles:rate-type',
    filterType: 'selectMultiple',
    options: DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
    customComponent: ({ data }: Object) => (
      <TableCell
        text={R.prop(GC.FIELD_CHARGE_RATE_TYPE, data)}
        options={DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS}
      />
    ),
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 150,
    name: 'titles:rate-unit',
    filterType: 'selectMultiple',
    options: R.filter(R.prop(GC.FIELD_VALUE), DEFAULT_CONTRACT_RATE_UNIT_OPTIONS),
    customComponent: ({ data }: Object) => (
      <TableCell
        options={DEFAULT_CONTRACT_RATE_UNIT_OPTIONS}
        text={R.prop(GC.FIELD_CHARGE_RATE_UNIT, data)}
      />
    ),
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 150,
    type: 'number',
    name: 'titles:min-rate',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 150,
    type: 'number',
    name: 'titles:max-rate',
  },
  [GC.FIELD_TRANSIT_DAYS]: {
    width: 120,
    type: 'number',
    name: 'titles:transit-days',
  },
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM]: {
    width: 120,
    type: 'number',
    name: 'titles:distance-range-from',
  },
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_TO]: {
    width: 120,
    type: 'number',
    name: 'titles:distance-range-to',
  },
  [GC.FIELD_CARRIER_RATE_DISTANCE_OUM]: {
    width: 150,
    filterType: 'selectMultiple',
    name: 'titles:distance-range-uom',
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
    customComponent: ({ data }: Object) => (
      <TableCell
        options={DEFAULT_DISTANCE_UOM_OPTIONS}
        text={R.prop(GC.FIELD_CARRIER_RATE_DISTANCE_OUM, data)}
      />
    ),
  },
  [GC.FIELD_MIN_WEIGHT]: {
    width: 120,
    type: 'number',
    name: 'titles:min-weight',
  },
  [GC.FIELD_MAX_WEIGHT]: {
    width: 120,
    type: 'number',
    name: 'titles:max-weight',
  },
  [GC.FIELD_WEIGHT_UOM]: {
    width: 150,
    name: 'titles:weight-uom',
    filterType: 'selectMultiple',
    options: DEFAULT_WEIGHT_UOM_OPTIONS,
    customComponent: ({ data }: Object) => (
      <TableCell
        options={DEFAULT_WEIGHT_UOM_OPTIONS}
        text={R.prop(GC.FIELD_WEIGHT_UOM, data)}
      />
    ),
  },
  [GC.FIELD_MIN_TEMPERATURE]: {
    width: 120,
    type: 'number',
    name: 'titles:min-temperature',
  },
  [GC.FIELD_MAX_TEMPERATURE]: {
    width: 120,
    type: 'number',
    name: 'titles:max-temperature',
  },
  [GC.FIELD_TEMPERATURE_UOM]: {
    width: 150,
    filterType: 'selectMultiple',
    name: 'titles:temperature-uom',
    options: ITEM_TEMPERATURE_OPTIONS,
    customComponent: ({ data }: Object) => (
      <TableCell
        options={ITEM_TEMPERATURE_OPTIONS}
        text={R.prop(GC.FIELD_TEMPERATURE_UOM, data)}
      />
    ),
  },
  [GC.FIELD_MIN_QUANTITY]: {
    width: 120,
    type: 'number',
    name: 'titles:min-quantity',
  },
  [GC.FIELD_MAX_QUANTITY]: {
    width: 120,
    type: 'number',
    name: 'titles:max-quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 150,
    name: 'titles:package-type',
    filterType: 'selectMultiple',
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    customComponent: ({ data }: Object) => (
      <TableCell
        text={R.prop(GC.FIELD_PACKAGE_TYPE, data)}
        options={DEFAULT_QUANTITY_RATE_UNIT_OPTIONS}
      />
    ),
  },
};

const makeRequestPayload = ({ primaryObjectGuid, primaryObjectBranchGuid }: Object) => ({
  [GC.CURRENT_BRANCH]: primaryObjectBranchGuid,
  params: { [GC.FIELD_CONTRACT_GUID]: primaryObjectGuid },
});

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = R.mapObjIndexed((
  { name, options, collection, filterType, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.ifElse(
    G.isArray(name),
    `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
    G.getWindowLocale(name),
  ),
}), fields);

const carrierContractRatePriceSettings = {
  fields,
  filterProps,
  isReport: true,
  useGetListRequest: true,
  useSearchableColumns: true,
  withResizableColumns: true,
  useNewTitleFilterInputs: true,
  getItemListRequestMethod: 'post',
  groupName: 'carrierContract.ratePrice',
  primaryObjectGuidKey: GC.FIELD_CONTRACT_GUID,
  tableCallbackDataProps: ['geoFencingZoneList'],
  reportType: GC.CARRIER_CONTRACT_RATE_PRICE_REPORT,
  omitFormFields: GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR,
  itemTitleArr: ['titles:contract-rate', 'Contract Rate'],
  formGroupTitleArr: ['titles:contract-rates', 'Contract Rates'],
  endpoints: {
    list: 'carrierRatePriceList',
    createOrUpdate: 'carrierRatePrice',
    remove: 'getCarrierRatePriceEndpoint',
  },
  additionalTableSettings: {
    allowSelectAll: true,
    checkBoxCellWidth: 90,
    searchableTitles: true,
    allowSelectItems: true,
  },
  // components
  CustomForm: ContractRateForm,
  AdditionalReportTitleComponent,
  // helpers
  makeRequestPayload,
  makeInitialValues: (initialValues: any, { carrierGuid, primaryObjectGuid }: Object) => ({
    ...initialValues,
    carrierGuid,
    [GC.FIELD_CONTRACT_GUID]: primaryObjectGuid,
  }),
  makeOptionsForSelect: ({ configGroup, carrierEquipments, geoFencingZoneList }: Object) => {
    const equipmentOptions = R.compose(
      R.filter(({ value }: Object) => R.includes(value, carrierEquipments)),
      R.pathOr([], ['dropdownOptions', GC.GENERAL_EQUIPMENTS]),
    )(configGroup);

    return {
      geoFencingZoneList,
      [GC.GENERAL_EQUIPMENTS]: equipmentOptions,
      [GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]: R.path(
        ['dropdownOptions', GC.GENERAL_TRANSPORTATION_SERVICE_TYPE],
        configGroup,
      ),
    };
  },
};

export {
  filterProps,
  carrierContractRatePriceSettings,
};
