import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////


const {
  setListLoading,
  setInitialState,
  setReportPending,
  setTableTitleSort,
  getItemListSuccess,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
} = getReportActions();

const createFuelPricesRequest = createAction('createFuelPricesRequest');

export {
  // report
  setListLoading,
  setInitialState,
  setReportPending,
  setTableTitleSort,
  getItemListSuccess,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
  // report
  createFuelPricesRequest,
};
