import React from 'react';
import * as R from 'ramda';
import { withHandlers } from 'react-recompose';
// features
import ReferenceFormComponent from '../../../reference/components/reference-form';
// forms
import { SelectDropdownForm } from '../../../../forms/forms/select-dropdown-form';
import MassCreateCarrierRateForm from '../../../../forms/forms/mass-carrier-rate-form';
import SelectPrintTemplateForm from '../../../../forms/forms/select-print-template-form';
import { PostTelToLoadboardsWithAsyncTELData } from '../../../../forms/forms/post-tel-to-loadboard-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch-report/load
import RowActions from '../components/row-actions';
//////////////////////////////////////////////////

export const withPageActionsHandlers = withHandlers({
  handleClickEditIcon: (props: Object) => (e: Object, data: Object) => {
    const {
      openModal,
      closeModal,
      openFixedPopup,
      closeFixedPopup,
      getItemListRequest,
      resetListAndPagination,
      sendTelToMacroPointRequest,
    } = props;

    openFixedPopup({
      position: 'right',
      el: e.currentTarget,
      content: (
        <RowActions
          entity={data}
          openModal={openModal}
          closeModal={closeModal}
          closeFixedPopup={closeFixedPopup}
          getItemListRequest={getItemListRequest}
          resetListAndPagination={resetListAndPagination}
          sendTelToMacroPointRequest={sendTelToMacroPointRequest}
        />
      ),
    });
  },
  handlePrint: ({ openModal, printRequest }: Object) => () => {
    const component = (
      <SelectPrintTemplateForm
        submitAction={(values: Object) => printRequest(values)}
        printableSection={GC.DOCUMENT_PRINTABLE_SECTION_TEL_REPORT}
      />
    );

    const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-options', 'Print Options'));

    openModal(modal);
  },
  handleAddReferenceList: (props: Object) => () => {
    const { itemList, openModal, createReferenceRequest } = props;

    const isSelected = ({ selected }: Object) => selected;

    const selectedList = R.map(
      ({ guid }: Object) => guid,
      R.filter(isSelected, itemList),
    );

    if (R.isEmpty(selectedList)) {
      return G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
      );
    }

    const multiAddingEntRef = (values: Object) => (
      createReferenceRequest(R.assoc('primaryObjectGuids', selectedList, values))
    );

    const component = (
      <ReferenceFormComponent
        scope={GC.REF_SCOPE_NAME_TEL}
        submitAction={(params: Object) => multiAddingEntRef(params)}
      />
    );

    const modal = G.createAddReferenceModalOptions(component);

    openModal(modal);
  },
  handleDispatchTelList: (props: Object) => () => {
    const { itemList, openModal, closeModal, documentTemplates, dispatchTelListRequest } = props;

    const selectedList = R.compose(
      R.map(G.getGuidFromObject),
      R.filter(R.prop('selected')),
    )(itemList);

    if (G.isNilOrEmpty(selectedList)) {
      const message = `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')} ${
        G.getWindowLocale('titles:tel', 'TEL')}!`;

      return G.showToastrMessageSimple('info', message);
    }

    const options = G.getPropFromObject(GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS, documentTemplates);

    const submitAction = (documentTemplateGuid: string) => {
      let data = { [GC.FIELD_TEL_GUIDS]: selectedList };

      if (G.isNotNilAndNotEmpty(documentTemplateGuid)) {
        data = R.assoc(GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID, documentTemplateGuid, data);
      }

      dispatchTelListRequest(data);
    };

    const component = (
      <SelectDropdownForm
        options={options}
        optionRequired={false}
        cancelAction={closeModal}
        submitAction={submitAction}
        fieldLabel={G.getWindowLocale('titles:dispatch-document', 'Dispatch Document')}
      />
    );

    const modal = { p: 15, component, options: {} };

    openModal(modal);
  },
  handlePostTelToLoadBoard: (props: Object) => () => {
    const { itemList, openModal, closeModal } = props;

    const isSelected = ({ selected }: Object) => selected;

    const selectedList = R.map(
      ({ guid }: Object) => guid,
      R.filter(isSelected, itemList),
    );

    if (R.isEmpty(selectedList)) {
      return G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
      );
    }

    const modal = {
      p: 15,
      component: (
        <PostTelToLoadboardsWithAsyncTELData
          width={500}
          guids={selectedList}
          cancelAction={closeModal}
        />
      ),
      options: {
        height: 'auto',
        minWidth: 'fit-content',
        title: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
      },
    };

    openModal(modal);
  },
  handleSendTelToMacroPoint: ({ itemList, sendTelToMacroPointRequest }: Object) => () => {
    const selectedList = R.compose(
      R.map(R.prop(GC.FIELD_GUID)),
      R.filter(R.prop('selected')),
    )(itemList);

    if (R.isEmpty(selectedList)) {
      return G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
      );
    }

    sendTelToMacroPointRequest({ guids: selectedList });
  },
  handleAddCarrierRate: (props: Object) => () => {
    const { itemList, openModal, closeModal, addCarrierRatesRequest } = props;

    const telGuids = R.compose(
      R.map(G.getGuidFromObject),
      R.filter(R.prop('selected')),
    )(itemList);

    if (G.isNilOrEmpty(telGuids)) {
      const message = `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')} ${
        G.getWindowLocale('titles:tel', 'TEL')}!`;

      return G.showToastrMessageSimple('info', message);
    }

    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const submitAction = (values: Object) => addCarrierRatesRequest({
      ...values,
      telGuids,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    });

    const component = (
      <MassCreateCarrierRateForm
        branchGuid={branchGuid}
        closeModal={closeModal}
        submitAction={submitAction}
      />
    );

    const modal = {
      p: '0',
      component,
      options: {
        width: 300,
        title: G.getWindowLocale('titles:mass-add-carrier-rate', 'Mass Add Carrier Rate'),
      },
    };

    openModal(modal);
  },
  handleAddCarrierRateByReport: (props: Object) => () => {
    const { openModal, closeModal, addCarrierRatesByReportRequest } = props;

    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const submitAction = (values: Object) => (
      addCarrierRatesByReportRequest(R.assoc(GC.FIELD_CURRENT_BRANCH, branchGuid, values))
    );

    const component = (
      <MassCreateCarrierRateForm
        branchGuid={branchGuid}
        closeModal={closeModal}
        submitAction={submitAction}
      />
    );

    const modal = {
      p: '0',
      component,
      options: {
        width: 300,
        title: G.getWindowLocale('titles:mass-add-carrier-rate-by-report', 'Mass Add Carrier Rate By Report'),
      },
    };

    openModal(modal);
  },
});
