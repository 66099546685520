import * as R from 'ramda';
import { useFormik } from 'formik';
import { css } from 'styled-components';
import React, { useMemo, useCallback } from 'react';
// components
import { FormSectionHeader } from '../../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature carrier-profile
import {
  terminalDetails,
  emailAutomationDetails,
} from './settings';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

const useForm = ({
  activeTab,
  submitAction,
  initialValues,
  validationSchema,
  fieldsWrapperStyles,
}: Object) => {
  const onSubmit = useCallback((values: Object, { setSubmitting }: Object) => {
    const failCallback = () => setSubmitting(false);
    const successCallback = () => setSubmitting(false);

    const { dispatchProcess, statusCheckProcess } = values;

    const { type: dispatchProcessType } = dispatchProcess;

    let data = values;

    if (R.includes(dispatchProcessType, [GC.TERMINAL_PROCESS_TYPE_EDI, GC.TERMINAL_PROCESS_TYPE_INTERNAL])) {
      data = {
        ...R.omit(
          [
            GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS,
            GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS,
            GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS,
          ],
          values,
        ),
        [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: {
          [GC.FIELD_TYPE]: dispatchProcessType,
        },
      };
    } else {
      data = {
        ...values,
        [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: R.ifElse(
          R.propEq(GC.TERMINAL_PROCESS_TYPE_API, GC.FIELD_TYPE),
          R.pick([
            GC.FIELD_TERMINAL_PROCESS_TYPE,
            GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID,
            GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
          ]),
          R.dissoc(GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID),
        )(dispatchProcess),
        [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]: R.ifElse(
          R.propEq(GC.TERMINAL_PROCESS_TYPE_API, GC.FIELD_TYPE),
          R.pick([
            GC.AUTO_STATUS_CHECK_ENABLED,
            GC.FIELD_TERMINAL_PROCESS_TYPE,
            GC.FIELD_TERMINAL_PROCESS_ENABLED,
            GC.FIELD_TERMINAL_STATUS_CHECK_FREQUENCY,
            GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID,
          ]),
          R.dissoc(GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID),
        )(statusCheckProcess),
      };
    }

    const requestValues = R.filter((item: Object) => {
      if (G.isNilOrEmpty(item)) return false;

      if (G.isObject(item)) return G.isOneNotNilOrNotEmpty(R.values(item));

      return true;
    }, data);

    submitAction(requestValues, { failCallback, successCallback });
  });

  const makeFieldSettings = (fields: Object, prefix: string) => R.compose(
    R.values,
    R.mapObjIndexed((field: Object, fieldName: string) => {
      const { label, additionalInputWrapperStyles } = field;

      return {
        ...field,
        label: G.getWindowLocale(R.of(Array, label)),
        fieldName: G.ifElse(R.isNotNil(prefix), `${prefix}.${fieldName}`, fieldName),
        inputWrapperStyles: {
          ...inputWrapperStyles,
          ...additionalInputWrapperStyles,
        },
      };
    }),
  )(fields);

  const wrapperStyles = {
    mt: 25,
    px: 15,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...fieldsWrapperStyles,
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      ...initialValues,
      ...R.mapObjIndexed(({ fields }: Object, entityName: string) => {
        const entity = R.prop(entityName, initialValues);

        if (G.isNilOrEmpty(entity)) return R.map(() => null, fields);

        return entity;
      }, emailAutomationDetails),
    },
  });

  const dispatchProcessType = R.path(['values', GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE], formik);

  const statusCheckProcessType = R.path(
    ['values', GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS, GC.FIELD_TYPE],
    formik,
  );

  const formSections = useMemo(() => {
    if (G.isZero(activeTab)) {
      const { title, fields } = terminalDetails;

      return R.of(Array, { title, fieldSettings: makeFieldSettings(fields) });
    }

    if (R.equals(activeTab, 2)) return [];

    if (R.includes(dispatchProcessType, [GC.TERMINAL_PROCESS_TYPE_EDI, GC.TERMINAL_PROCESS_TYPE_INTERNAL])) {
      const { title, fields } = R.prop(GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, emailAutomationDetails);

      return R.of(
        Array,
        {
          title,
          fieldSettings: makeFieldSettings(R.pick([GC.FIELD_TYPE], fields), GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS),
        },
      );
    }

    return R.map((prefix: string) => {
      const title = R.path([prefix, 'title'], emailAutomationDetails);

      let fields = R.path([prefix, 'fields'], emailAutomationDetails);

      if (R.equals(prefix, GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS)) {
        const omittedFields = G.ifElse(
          R.equals(dispatchProcessType, 'API'),
          [
            GC.FIELD_MESSAGE,
            GC.FIELD_EMAIL_TO,
            GC.FIELD_MESSAGE_SUBJECT,
            GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_REF_TYPE_GUID,
          ],
          [GC.FIELD_INTEGRATION_CONFIG_GUID],
        );

        fields = R.omit(omittedFields, fields);
      }

      if (R.equals(prefix, GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS)) {
        const omittedFields = G.ifElse(
          R.equals(statusCheckProcessType, 'API'),
          [GC.FIELD_MESSAGE, GC.FIELD_EMAIL_TO, GC.FIELD_MESSAGE_SUBJECT, GC.FIELD_TERMINAL_SMS_NOTIFICATION_ENABLED],
          [GC.FIELD_INTEGRATION_CONFIG_GUID],
        );

        fields = R.omit(omittedFields, fields);
      }

      return {
        title,
        fieldSettings: makeFieldSettings(fields, prefix),
      };
    }, R.keys(emailAutomationDetails));
  }, [activeTab, dispatchProcessType, statusCheckProcessType]);

  return {
    formik,
    formSections,
    wrapperStyles,
  };
};

const TerminalForm = (props: Object) => {
  const { optionsForSelect } = props;

  const { formik, formSections, wrapperStyles } = useForm(props);

  const { handleSubmit } = formik;

  return (
    <form
      id='terminal_form'
      onSubmit={handleSubmit}
      css={css`
        overflow-y: auto;
        max-height: calc(95vh - 180px);

        &::-webkit-scrollbar {
          width: 6px;
        }
      `}
    >
      {
        formSections.map(({ title, fieldSettings }: Object, index: number) => (
          <FormSectionHeader key={index} title={title} expanded={true}>
            <Fieldset2
              {...G.getFormikPropsToFieldset(formik)}
              {...optionsForSelect}
              fields={fieldSettings}
              fieldsWrapperStyles={wrapperStyles}
            />
          </FormSectionHeader>
        ))
      }
    </form>
  );
};

export default TerminalForm;
