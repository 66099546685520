import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderComponent } from 'react-recompose';
// common
import { makeSelectInitialSplashScreen } from '../../common/selectors';
// components
import LoaderComponent from '../../components/loader';
// helpers/constants
import * as G from '../../helpers';
// auth settings
import {
  newPasswordMobilePageSettings,
  newPasswordMobileFieldSettings,
  defaultNewPasswordMobileInitFields,
  newPasswordMobileValidationSchemaObject,
} from './settings';
// feature auth
import AuthForm from './auth-form';
import { FormWrapper } from './ui';
import { makeSelectAuthData } from './selectors';
import stylingPanelHoc from './styling-panel-hoc';
import { sendCreateNewPasswordMobileRequest } from './actions';
//////////////////////////////////////////////////

const NewPasswordMobile = (props: Object) => {
  const {
    auth,
    handleSubmit,
    handleChange,
    isSubmitting,
    splashScreenSetting,
  } = props;

  if (R.path(['loggedIn'], auth)) return <Redirect to='/' />;

  const { title, action } = newPasswordMobilePageSettings;

  return (
    <FormWrapper bs='100%'>
      <AuthForm
        title={title}
        props={props}
        widthLogo={350}
        heightLogo={350}
        widthForm='100%'
        maxWidthForm='90%'
        fontSizeTitle={38}
        textAlign='center'
        displayLinkTo='none'
        widthSubmitBtn='100%'
        borderRadiusBtn='8px'
        heightSubmitBtn={120}
        displaySubtitle='none'
        fontSizeSubmitBtn={40}
        actionSubmitBtn={action}
        displayImageSlider='none'
        displayInputsBlock='block'
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isSubmitting={isSubmitting}
        puddingSubmitBtn='16px 16px'
        fields={newPasswordMobileFieldSettings}
        splashScreenSetting={splashScreenSetting}
      />
    </FormWrapper>
  );
};

const enhance = compose(
  stylingPanelHoc,
  withFormik({
    enableReinitialize: true,
    validationSchema: newPasswordMobileValidationSchemaObject,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultNewPasswordMobileInitFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { sendCreateNewPasswordMobileRequest } = props;

      const params = G.parseQueryString();

      setSubmitting(true);
      sendCreateNewPasswordMobileRequest({ ...values, ...params, callback: () => setSubmitting(false) });
    },
  }),
  branch(
    ({ splashScreenSetting }: Object) => R.not(R.path(['isLoaded'], splashScreenSetting)),
    renderComponent(LoaderComponent),
  ),
  pure,
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  auth: makeSelectAuthData(state),
  splashScreenSetting: makeSelectInitialSplashScreen(state),
}));

export default connect(mapStateToProps, {
  sendCreateNewPasswordMobileRequest,
})(enhance(NewPasswordMobile));
