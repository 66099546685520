import * as R from 'ramda';
import React from 'react';
import { Field } from 'redux-form';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { styles, renderFormGroup } from '../../../../forms';
// feature fleet
import { setOptions } from '../../helpers';
//////////////////////////////////////////////////

const renderAfterAction = (attribute: string, afterAction: Function) =>
  R.and(R.equals(attribute, GC.FIELD_USER_LOGIN_ID), afterAction);

const setUsersGeneralToOptions = (field: Object, props: Object, options: Object) => {
  if (G.notEquals(field.nameForAttribute, 'assignedDispatcherGuid')) return options;

  const users = R.compose(
    R.prepend(GC.EMPTY_OPTION_OBJECT),
    R.map((item: Object) => ({
      value: G.getGuidFromObject(item),
      label: R.pathOr('', ['fullText'], G.getUserInfo(item)),
    })),
    R.pathOr([], ['usersGeneral']),
  )(props);

  return users;
};

const setDispatchingGroupToOptions = (field: Object, props: Object, options: Object) => {
  if (G.notEquals(field.nameForAttribute, 'dispatchingGroupGuid')) return options;

  return R.compose(
    R.prepend(GC.EMPTY_OPTION_OBJECT),
    R.pathOr([], ['dispatchingGroupOptions']),
  )(props);
};

const addDeletedSelectedOption = (options: Array, selectedOption: any) => {
  if (G.isArray(selectedOption)) return options;

  const value = R.propOr(selectedOption, GC.FIELD_DROPDOWN_OPTION_GUID, selectedOption);

  if (G.isNilOrEmpty(value)) return options;

  const option = R.find(R.propEq(value, GC.FIELD_VALUE), options);

  if (G.isNotNilAndNotEmpty(option)) return options;

  return R.concat(
    options,
    R.of(Array, {
      label: R.prop(GC.FIELD_DISPLAYED_VALUE, selectedOption),
      value,
    }),
  );
};

const getOptions = (props: Object, field: Object) => {
  if (G.isNilOrEmpty(field.options)) return null;

  const options = setUsersGeneralToOptions(field, props, setOptions(props.driverConfigs, field));
  const options2 = setDispatchingGroupToOptions(field, props, options);
  const selectedValue = R.pathOr(null, ['initialValues', field.nameForAttribute], props);

  return addDeletedSelectedOption(options2, selectedValue);
};

const getDisabled = ({ disabled }: Object) => {
  if (G.isFunction(disabled)) return disabled();

  return disabled;
};

export const renderField = (props: Object, field: Object, index: number) => (
  <Field
    key={index}
    width='232px'
    direction='row'
    margin='5px 10px'
    type={field.type}
    labelFontSize={16}
    labelMargin='5px 0'
    errorFontSize={14}
    labelPosition='left'
    inputs={field.inputs}
    zIndex={field.zIndex}
    errorMargin='0 0 5px 0'
    onChange={props.change}
    disabled={getDisabled(field)}
    handler={props[field.handler]}
    isClearable={field.isClearable}
    additionClass={styles.inputGroup}
    options={getOptions(props, field)}
    validate={R.or(field.validate, [])}
    afterActionHasPerm={[PC.USER_WRITE]}
    minDate={G.setMinDate(props, field)}
    maxDate={G.setMaxDate(props, field)}
    label={G.getWindowLocale(field.name)}
    component={renderFormGroup(field.type)}
    onChangeAddress={props.handleChangeAddress}
    onSelectAddress={props.handleSelectAddress}
    selectAction={R.path([field.selectAction], props)}
    info={R.and(field.info, G.getWindowLocale(field.info))}
    name={R.or(field.nameForAttribute, R.prop(GC.FIELD_NAME, field))}
    afterActionText={G.getWindowLocale('actions:change-password', 'Change Password')}
    afterAction={renderAfterAction(field.nameForAttribute, props.handleChangePassword)}
  />
);
