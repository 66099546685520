import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet
import DocumentForm from '../components/document-form';
//////////////////////////////////////////////////

export const withAddOrEditDocument = ({ fleetType }: Object) => compose(
  withHandlers({
    handleAddDoc: (props: Object) => (event: Event) => {
      G.stopPropagation(event);

      const { openModal, closeModal } = props;

      const modalContentMap = {
        truck: {
          configs: R.path(['truckConfigs'], props),
          submitAction: R.path(['createTruckDocRequest'], props),
        },
        driver: {
          configs: R.path(['driverConfigs'], props),
          submitAction: R.path(['createDriverDocRequest'], props),
        },
        trailer: {
          configs: R.path(['trailerConfigs'], props),
          submitAction: R.path(['createTrailerDocumentRequest'], props),
        },
        vendor: {
          configs: R.path(['vendorConfigs'], props),
          submitAction: R.path(['createVendorDocumentRequest'], props),
        },
      };

      const component = (
        <DocumentForm
          edit={false}
          fleetType={fleetType}
          closeModal={closeModal}
          configs={R.path([fleetType, 'configs'], modalContentMap)}
          primaryObjectGuid={R.path(['initialValues', 'guid'], props)}
          submitAction={R.path([fleetType, 'submitAction'], modalContentMap)}
        />
      );

      const modal = {
        p: '15px',
        component,
        options: {
          width: 440,
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:add-doc', 'Add Document'),
        },
      };

      openModal(modal);
    },
    handleEditDoc: (props: Object) => (doc: Object) => {
      const { openModal, closeModal } = props;
      const initialValues = {
        ...doc,
        file: doc.documentFilename,
        documentType: R.path(['documentType', 'dropdownOptionGuid'], doc),
      };
      const modalContentMap = {
        truck: {
          configs: R.path(['truckConfigs'], props),
          submitAction: R.path(['updateTruckDocRequest'], props),
        },
        driver: {
          configs: R.path(['driverConfigs'], props),
          submitAction: R.path(['updateDriverDocRequest'], props),
        },
        trailer: {
          configs: R.path(['trailerConfigs'], props),
          submitAction: R.path(['updateTrailerDocumentRequest'], props),
        },
        vendor: {
          configs: R.path(['vendorConfigs'], props),
          submitAction: R.path(['updateVendorDocumentRequest'], props),
        },
      };
      const component = (
        <DocumentForm
          edit={true}
          fleetType={fleetType}
          closeModal={closeModal}
          initialValues={initialValues}
          configs={R.path([fleetType, 'configs'], modalContentMap)}
          primaryObjectGuid={R.path(['initialValues', 'guid'], props)}
          submitAction={R.path([fleetType, 'submitAction'], modalContentMap)} />
      );
      const modal = {
        p: '15px',
        component,
        options: {
          width: 440,
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:edit-document', 'Edit Document'),
        },
      };
      openModal(modal);
    },
    handleDeleteDoc: (props: Object) => ({ guid }: Object) => {
      const {
        openModal,
        closeModal,
        deleteTruckDocRequest,
        deleteDriverDocRequest,
        removeVendorDocumentRequest,
        deleteTrailerDocumentRequest } = props;
      const modalContentMap = {
        truck: {
          action: () => {
            closeModal();
            deleteTruckDocRequest(guid);
          },
        },
        driver: {
          action: () => {
            closeModal();
            deleteDriverDocRequest(guid);
          },
        },
        trailer: {
          action: () => {
            closeModal();
            deleteTrailerDocumentRequest(guid);
          },
        },
        vendor: {
          action: () => removeVendorDocumentRequest(guid),
        },
      };
      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:documents:remove-confirmation-text',
            'Are you sure you want delete this document?',
          )} />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'delete'),
              action: R.path([fleetType, 'action'], modalContentMap),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);
