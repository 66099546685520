import React from 'react';
// components
import { PasswordInput } from '../../../components/password-input';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const PasswordInputComponent = () => {
  const description = '<PasswordInput /> component is a password input field with a toggle button to show/hide the entered password.';

  const usage = (
    <ul>
      <li>Parameter `<i>isMobile</i>` indicates if the component is being used in a mobile view.</li>
      <li>Set additional props that are not explicitly handled within the PasswordInput component but are passed down to the underlying Input component.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='PasswordInput'
      description={description}
      path='src/components/password-input'
    >
      <PasswordInput placeholder='Enter password' />
    </ComponentStoryWrapper>
  );
};

export default PasswordInputComponent;
