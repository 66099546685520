import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 280,
};

const calendarInputWrapperStyles = { width: '100%' };

const availableSharedComponentDefaultFields = {
  [GC.FIELD_COMPONENT_ID]: '',
  [GC.FIELD_SPECIFICATION]: '',
  [GC.FIELD_SERIAL_NUMBER]: '',
  [GC.FIELD_COMPONENT_TYPE]: '',
  [GC.FIELD_WARRANTY_EXPIRATION]: null,
  [GC.FIELD_COMPONENT_MANUFACTURER]: '',
};

const fieldSettings = [
  {
    isRequired: false,
    type: 'reactSelect',
    shouldCustomChange: true,
    label: ['titles:shared-component'],
    options: 'availableSharedComponents',
    fieldName: GC.FIELD_SHARED_COMPONENT_GUID,
    disabled: (_: any, values: Object) => R.pathOr(false, ['createdFromSharedComponent'], values),
    inputWrapperStyles: {
      ...inputWrapperStyles,
      mr: 280,
    },
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:component-id'],
    fieldName: GC.FIELD_COMPONENT_ID,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:serial-number'],
    fieldName: GC.FIELD_SERIAL_NUMBER,
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:type'],
    options: GC.FIELD_COMPONENT_TYPE,
    fieldName: GC.FIELD_COMPONENT_TYPE,
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:manufacturer'],
    options: GC.FIELD_COMPONENT_MANUFACTURER,
    fieldName: GC.FIELD_COMPONENT_MANUFACTURER,
  },
  {
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    fieldName: GC.FIELD_WARRANTY_EXPIRATION,
    label: ['titles:warranty-expiration-date'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      mr: 280,
    },
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    label: ['titles:installed-date'],
    fieldName: GC.FIELD_INSTALL_DATE,
    inputWrapperStyles: {
      mb: 25,
      width: 220,
    },
  },
  {
    isClearable: true,
    type: 'datePicker',
    shouldCustomChange: true,
    calendarInputWrapperStyles,
    label: ['titles:remove-date'],
    fieldName: GC.FIELD_REPLACE_OR_REMOVE_DATE,
    inputWrapperStyles: {
      mb: 25,
      width: 220,
    },
    customChangeHandler: (event: Object, _: any, { setFieldValue }: Object) =>
      setFieldValue(GC.FIELD_REPLACE_OR_REMOVE_DATE, G.getEventTargetValue(event)),
    customChangeHandler2: ({ value, props }: Object) => {
      const { values, setValues } = props;

      const replaceOrRemoveDate = G.convertInstanceToDefaultDateFormat(value);

      const installed = G.isNilOrEmpty(replaceOrRemoveDate);

      return setValues(R.mergeRight(values, { installed, replaceOrRemoveDate }));
    },
  },
  {
    type: 'toggle',
    defaultValue: true,
    label: ['titles:installed'],
    fieldName: GC.FIELD_INSTALLED,
    inputWrapperStyles: {
      mb: 25,
      width: 'max-content',
    },
  },
  {
    type: 'textarea',
    label: ['titles:specification'],
    fieldName: GC.FIELD_SPECIFICATION,
    inputWrapperStyles: {
      mb: 25,
      width: 600,
    },
  },
];

const getDisabled = ({ sharedComponentGuid, createdFromSharedComponent }: Object = {}) =>
  R.or(G.isNotNilAndNotEmpty(sharedComponentGuid), G.isTrue(createdFromSharedComponent));

const getFieldSettings = (fromFleetSharedComponentList: boolean, values: Object) => {
  if (G.isTrue(fromFleetSharedComponentList)) {
    return R.compose(
      R.map((item: Object) => R.assoc('width', 370, item)),
      R.reject(({ fieldName }: Object) => R.includes(
        fieldName,
        [
          GC.FIELD_INSTALLED,
          GC.FIELD_INSTALL_DATE,
          GC.FIELD_SHARED_COMPONENT_GUID,
          GC.FIELD_REPLACE_OR_REMOVE_DATE,
        ],
      )),
    )(fieldSettings);
  }

  const disabled = getDisabled(values);

  return R.map((item: Object) => {
    const { type, fieldName } = item;

    if (R.equals(fieldName, GC.FIELD_REPLACE_OR_REMOVE_DATE)) {
      const isRequired = R.propEq(false, GC.FIELD_INSTALLED, values);

      return R.assoc('isRequired', isRequired, item);
    }

    if (R.includes(
      fieldName,
      [
        GC.FIELD_INSTALLED,
        GC.FIELD_INSTALL_DATE,
        GC.FIELD_SHARED_COMPONENT_GUID,
      ],
    )) return item;

    if (R.equals(type, 'calendar')) {
      return {
        ...item,
        disabled,
        type: G.ifElse(G.isTrue(disabled), 'text', 'calendar'),
      };
    }

    return R.assoc('disabled', disabled, item);
  }, fieldSettings);
};

const defaultValues = R.compose(
  R.map(({ defaultValue = null }: Object) => defaultValue),
  R.indexBy(R.prop('fieldName')),
)(fieldSettings);

const validationSchema = ({ fromFleetSharedComponentList }: Object) => Yup.lazy((values: Object) =>
  Yup.object().shape(R.compose(
    R.mergeRight({
      [GC.FIELD_SPECIFICATION]: Yup.string()
        .nullable(true)
        .min(0, G.getShouldBeFromToLocaleTxt(0, 300))
        .max(300, G.getShouldBeFromToLocaleTxt(0, 300)),
      [GC.FIELD_COMPONENT_ID]: Yup.string()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .min(0, G.getShouldBeFromToLocaleTxt(0, 85))
        .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
    }),
    R.map(({ isRequired }: Object) => {
      if (G.isTrue(isRequired)) return Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

      return Yup.string().nullable(true).notRequired();
    }),
    R.indexBy(R.prop('fieldName')),
  )(getFieldSettings(fromFleetSharedComponentList, values))),
);

const enhance = compose(
  withState('availableSharedComponents', 'setAvailableSharedComponents', {}),
    withFormik({
      validationSchema,
      enableReinitialize: true,
      mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
        defaultValues,
        initialValues,
      ),
      handleSubmit: (values: Object, { props }: Object) => {
        const { submitAction, requestGuid, requestGuidKey } = props;

        const reqBody = G.ifElse(
          G.isNotNilAndNotEmpty(requestGuidKey),
          R.assoc(requestGuidKey, requestGuid, values),
          values,
        );

        submitAction(reqBody);
      },
    }),
  withHandlers({
    handleGetAvailableSharedComponents: (props: Object) => async () => {
      const {
        branchGuid,
        openLoader,
        closeLoader,
        setAvailableSharedComponents,
      } = props;

      G.callFunction(openLoader);
      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
        },
      };

      const res = await sendRequest('get', endpointsMap.availableFleetSharedComponents, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const availableSharedComponents = R.compose(
          R.indexBy(R.prop(GC.FIELD_GUID)),
          R.map((item: Object) => {
            const { guid, componentId, truckUnitId, trailerUnitId } = item;

            const componentType = R.path([GC.FIELD_COMPONENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], item);
            const componentManufacturer = R.path(
              [GC.FIELD_COMPONENT_MANUFACTURER, GC.FIELD_DROPDOWN_OPTION_GUID],
              item,
            );
            let label = componentId;

            if (G.isNotNilAndNotEmpty(truckUnitId)) {
              label = `${componentId} (${G.getWindowLocale('titles:truck', 'Truck')}: ${truckUnitId})`;
            }

            if (G.isNotNilAndNotEmpty(trailerUnitId)) {
              label = `${componentId} (${G.getWindowLocale('titles:trailer', 'Trailer')}: ${trailerUnitId})`;
            }

            return {
              ...item,
              label,
              componentType,
              componentManufacturer,
              [GC.FIELD_VALUE]: guid,
            };
          }),
        )(R.or(data, []));

        setAvailableSharedComponents(availableSharedComponents);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
    handleSelectSharedComponent: (props: Object) => (value: string) => {
      const { values, setValues, availableSharedComponents } = props;

      if (G.isNilOrEmpty(value)) {
        return setValues({
          ...values,
          ...availableSharedComponentDefaultFields,
          [GC.FIELD_SHARED_COMPONENT_GUID]: null,
        });
      }

      setValues({
        ...values,
        ...R.pick(R.keys(availableSharedComponentDefaultFields), G.getPropFromObject(value, availableSharedComponents)),
        [GC.FIELD_SHARED_COMPONENT_GUID]: value,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        availableSharedComponents,
        fromFleetSharedComponentList,
        handleGetAvailableSharedComponents,
      } = this.props;

      if (R.or(G.isNotNilAndNotEmpty(availableSharedComponents), G.isTrue(fromFleetSharedComponentList))) return;

      handleGetAvailableSharedComponents();
    },
  }),
  pure,
);

const EquipmentComponentForm = (props: Object) => {
  const {
    values,
    handleSubmit,
    availableSharedComponents,
    handleSelectSharedComponent,
    fromFleetSharedComponentList,
  } = props;

  const optionsForSelect = R.pathOr(
    {
      ...R.pick(
        [GC.FIELD_COMPONENT_TYPE, GC.FIELD_COMPONENT_MANUFACTURER],
        R.pathOr({}, ['configs'], props),
      ),
    },
    ['optionsForSelect'],
    props,
  );

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        handleCustomChange={handleSelectSharedComponent}
        availableSharedComponents={R.values(availableSharedComponents)}
        fields={getFieldSettings(fromFleetSharedComponentList, values)}
        fieldsWrapperStyles={{
          pt: 15,
          width: 600,
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(EquipmentComponentForm);
