import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setDocumentsFilter = createAction('setDocumentsFilter');
export const createDocumentRequest = createAction('createDocumentRequest');
export const createDocumentSuccess = createAction('createDocumentSuccess');
export const updateDocumentRequest = createAction('updateDocumentRequest');
export const updateDocumentSuccess = createAction('updateDocumentSuccess');
export const getDataSourceListSuccess = createAction('getDataSourceListSuccess');
export const deleteNotificationRequest = createAction('deleteNotificationRequest');
export const deleteNotificationSuccess = createAction('deleteNotificationSuccess');
export const createNotificationRequest = createAction('createNotificationRequest');
export const createNotificationSuccess = createAction('createNotificationSuccess');
export const createStatusMessageRequest = createAction('createStatusMessageRequest');
export const createStatusMessageSuccess = createAction('createStatusMessageSuccess');
export const updateStatusMessageRequest = createAction('updateStatusMessageRequest');
export const updateStatusMessageSuccess = createAction('updateStatusMessageSuccess');
export const deleteStatusMessageRequest = createAction('deleteStatusMessageRequest');
export const deleteStatusMessageSuccess = createAction('deleteStatusMessageSuccess');
export const deleteDocumentConfigRequest = createAction('deleteDocumentConfigRequest');
export const deleteDocumentConfigSuccess = createAction('deleteDocumentConfigSuccess');
export const getDocumentsForConfigRequest = createAction('getDocumentsForConfigRequest');
export const getDocumentsForConfigSuccess = createAction('getDocumentsForConfigSuccess');
export const setNotificationTriggersFilter = createAction('setNotificationTriggersFilter');
export const returnInheritedDocumentSuccess = createAction('returnInheritedDocumentSuccess');
export const getNotificationTriggersRequest = createAction('getNotificationTriggersRequest');
export const getNotificationTriggersSuccess = createAction('getNotificationTriggersSuccess');
export const downloadDocumentTemplateRequest = createAction('downloadDocumentTemplateRequest');
export const setNotificationObjectTypeFilter = createAction('setNotificationObjectTypeFilter');
export const updateNotificationConfigsRequest = createAction('updateNotificationConfigsRequest');
export const updateNotificationConfigsSuccess = createAction('updateNotificationConfigsSuccess');
export const getStatusMessagesForConfigRequest = createAction('getStatusMessagesForConfigRequest');
export const getStatusMessagesForConfigSuccess = createAction('getStatusMessagesForConfigSuccess');
export const getNotificationListTriggersRequest = createAction('getNotificationListTriggersRequest');
export const getNotificationListTriggersSuccess = createAction('getNotificationListTriggersSuccess');
export const returnInheritedStatusMessagesSuccess = createAction('returnInheritedStatusMessagesSuccess');
// integration mail config
export const getIntegrationMailConfigRequest = createAction('getIntegrationMailConfigRequest');
export const getIntegrationMailConfigSuccess = createAction('getIntegrationMailConfigSuccess');
export const removeIntegrationMailConfigRequest = createAction('removeIntegrationMailConfigRequest');
export const createOrUpdateIntegrationMailConfigRequest = createAction('createOrUpdateIntegrationMailConfigRequest');
export const createOrUpdateIntegrationMailConfigSuccess = createAction('createOrUpdateIntegrationMailConfigSuccess');
// mail template config
export const getMailTemplateTypesRequest = createAction('getMailTemplateTypesRequest');
export const getMailTemplateTypesSuccess = createAction('getMailTemplateTypesSuccess');
export const getMailTemplateByKeyRequest = createAction('getMailTemplateByKeyRequest');
export const getMailTemplateByKeySuccess = createAction('getMailTemplateByKeySuccess');
export const getMailTemplateConfigRequest = createAction('getMailTemplateConfigRequest');
export const getMailTemplateConfigSuccess = createAction('getMailTemplateConfigSuccess');
export const removeMailTemplateConfigRequest = createAction('removeMailTemplateConfigRequest');
export const getMailTemplateNamesByTypeRequest = createAction('getMailTemplateNamesByTypeRequest');
export const getMailTemplateNamesByTypeSuccess = createAction('getMailTemplateNamesByTypeSuccess');
export const createOrUpdateMailTemplateConfigRequest = createAction('createOrUpdateMailTemplateConfigRequest');
// document sending config
export const removeDocumentSendingConfigRequest = createAction('removeDocumentSendingConfigRequest');
export const removeDocumentSendingConfigSuccess = createAction('removeDocumentSendingConfigSuccess');
export const getDocumentSendingConfigListRequest = createAction('getDocumentSendingConfigListRequest');
export const getDocumentSendingConfigListSuccess = createAction('getDocumentSendingConfigListSuccess');
export const createOrUpdateDocumentSendingConfigRequest = createAction('createOrUpdateDocumentSendingConfigRequest');
export const createOrUpdateDocumentSendingConfigSuccess = createAction('createOrUpdateDocumentSendingConfigSuccess');
