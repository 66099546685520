import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  pure,
  branch,
  compose,
  withProps,
  withState,
  withHandlers,
  renderNothing } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import Loader from '../../../components/loader';
import { PageTitle } from '../../../components/page-title';
import { FormButtons } from '../../../components/form-buttons';
// features
import { makeSelectRoleList } from '../../role/selectors';
import { setFilterInitialValues } from '../../report-format/helpers';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { renderFormGroup } from '../../../forms';
// ui
import { PageWrapper, SimpleWrapper, PageHeaderWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature charts
import { Chart } from './am-charts';
import AdditionFiltersForm from './addition-filter-form';
import { mainFilters } from '../settings/fields-settings';
import { TOTAL, NO_GROUP, CHART_CONFIGS_FORM } from '../constants';
import {
  getCondition,
  setAdditionBtns,
  getSelectOptions,
  setDisabledStatus } from '../helpers';
import {
  getChartSuccess,
  updateChartRequest,
  createChartRequest,
  getChartPreviewRequest,
  getChartPreviewSuccess } from '../actions';
import {
  fieldClass,
  PreviewButton,
  PreviewFooter,
  PreviewMessage,
  PreviewWrapper,
  FormSectionWrapper } from '../ui';
import {
  makeSelectForm,
  makeSelectPreviewData,
  makeSelectCurrentChart,
  makeSelectChartConfigs,
  makeSelectPreviewStatus,
  makeSelectRequestStatus } from '../selectors';
//////////////////////////////////////////////////

const renderDetails = (props: Object) => (
  <FormSectionWrapper>
    <span>{G.getWindowLocale('titles:main-filters', 'Main Filters')}</span>
    <form id={CHART_CONFIGS_FORM} onSubmit={props.handleSubmit(props.handleSubmitForm)}>
      {
        mainFilters(props).fields.map((field: Object, index: number, arr: any) => {
          if (field.isHide) return null;
          return (
            <Field
              key={index}
              width='232px'
              direction='row'
              errorFontSize={14}
              errorMargin='0 5px'
              labelFontSize={16}
              labelPosition='left'
              fieldClass={fieldClass}
              isMulti={field.isMulti}
              display={field.display}
              validate={field.validate}
              errorClass={field.errorClass}
              labelWidth={field.labelWidth}
              name={field.nameForAttribute}
              placeholder={field.placeholder}
              type={G.setFieldType(field.type)}
              selectAction={props[field.handler]}
              selectedRoles={props.selectedRoles}
              timeSelection={field.timeSelection}
              margin={R.or(field.margin, '5px 10px')}
              label={G.getWindowLocale(field.name, '')}
              labelMargin={R.or(field.labelMargin, '5px 0')}
              onChange={props[R.or(field.handler, 'change')]}
              zI={R.subtract(R.add(field.zI, arr.length), index)}
              component={field.renderComponent || renderFormGroup(field.type)}
              options={G.ifElse(G.isString(field.options), props.chartConfigs[field.options], field.options)} />
          );
        })
      }
    </form>
  </FormSectionWrapper>
);

const renderChart = (props: Object) => {
  if (G.isTrue(props.previewPending)) {
    return <Loader position='absolute' />;
  }
  const condition = R.or(
    R.isEmpty(props.previewData),
    G.isNilOrEmpty(R.pathOr(null, ['values', GC.FIELD_CHART_CHART_TYPE], props.formValue)),
  );
  if (condition) {
    return (
      <PreviewMessage>
        {`${G.getWindowLocale(
          'titles:before-watch-preview-please-select-values',
          'Before watch preview Please select values',
        )}: `}
        <li>{G.getWindowLocale('titles:data-type', 'Data Type')};</li>
        <li>{G.getWindowLocale('titles:chart-type', 'Chart Type')};</li>
        <li>{G.getWindowLocale('titles:group-by', 'Group By')};</li>
      </PreviewMessage>
    );
  }
  const title = R.pathOr(
    G.getWindowLocale('actions:preview', 'Preview'),
    ['formValue', 'values', GC.FIELD_NAME],
    props,
  );
  return (
    <Chart
      width='100%'
      title={title}
      height='calc(100% - 50px)'
      chartData={props.previewData}
      type={R.path(['formValue', 'values', GC.FIELD_CHART_CHART_TYPE], props)}
      guid={R.pathOr('draft', ['formValue', 'values', GC.FIELD_GUID], props)} />
  );
};

const renderPreview = (props: Object) => (
  <FormSectionWrapper>
    <span>{G.getWindowLocale('actions:preview', 'Preview')}</span>
    <PreviewWrapper>
      {renderChart(props)}
    </PreviewWrapper>
    <PreviewFooter onClick={props.handlePreview}>
      <PreviewButton>{G.getWindowLocale('actions:preview', 'Preview')}</PreviewButton>
    </PreviewFooter>
  </FormSectionWrapper>
);

const enhance = compose(
  withProps((props: Object) => {
    const { initialValues, chartConfigs } = props;
    const filterFields = R.or(initialValues.filters, []);
    const availableFields = getSelectOptions(props, GC.FIELD_FILTERS)();
    const formFilters = setFilterInitialValues(filterFields, GC.FIELD_FILTER, availableFields);
    const collection = R.pathOr(
      '',
      [GC.FIELD_VALUE],
      R.find(
        R.propEq(R.pathOr('', [GC.FIELD_COLLECTION, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues), GC.FIELD_VALUE),
        chartConfigs.collectionType,
      ),
    );
    return {
      filterFields,
      initialValues: {
        ...initialValues,
        collection,
        filters: formFilters,
      },
    };
  }),
  reduxForm({
    form: CHART_CONFIGS_FORM,
    enableReinitialize: true,
  }),
  branch(
    (props: Object) => (
      R.or(
        R.not(props.initialDataLoaded),
        R.equals(props.requestPending, true),
      )
    ),
    renderNothing,
  ),
  withState(GC.FIELD_FILTERS, 'setFilters', ({ filterFields }: Object) => filterFields),
  withState('selectedRoles', 'setSelectedRoles', ({ initialValues }: Object) => R.pathOr(
    [],
    [GC.FIELD_ROLE_GUIDS],
    initialValues,
  )),
  withHandlers({
    handleSubmitForm: (props: Object) => (values: Object) => {
      const reqBody = {
        ...values,
        [GC.FIELD_ROLE_GUIDS]: props.selectedRoles,
        [GC.FIELD_FILTERS]: R.prop(GC.FIELD_FILTERS, props),
      };
      if (R.equals(props.match.path, routesMap.chartNew)) {
        props.createChartRequest(reqBody);
      } else {
        props.updateChartRequest(reqBody);
      }
    },
    handleClickSaveAsNew: (props: Object) => () => {
      const collection = R.path(['formValue', 'values', GC.FIELD_COLLECTION], props);
      const reqBody = {
        ...props.formValue.values,
        touch: props.touch,
        [GC.FIELD_GUID]: null,
        [GC.FIELD_FILTERS]: props.filters,
        [GC.FIELD_COLLECTION]: R.pathOr(
          collection,
          [GC.FIELD_DROPDOWN_OPTION_GUID],
          collection,
        ),

      };
      props.createChartRequest(reqBody);
    },
    handleSelectRoles: (props: Object) => (newValues: Object) => {
      const values = newValues.map((entity: Object) => entity.value);
      props.setSelectedRoles(values);
    },
    handleSelectDataType: (props: Object) => (
      event: Object,
      value: any,
      _: any,
      fieldName: string,
    ) => {
      const dataType = R.pathOr('', ['values', GC.FIELD_CHART_DATA_TYPE], props.formValue);
      props.change(fieldName, value);
      if (G.notEquals(dataType, value)) {
        props.setFilters([]);
        const fields = [
          { value: {}, fieldName: GC.FIELD_FILTERS },
          { value: '', fieldName: GC.FIELD_CHART_CHART_TYPE },
          { value: '', fieldName: GC.FIELD_CHART_GROUP_BY_TYPE },
        ];
        fields.forEach((item: Object) => props.change(item.fieldName, item.value));
      }
      props.getChartPreviewSuccess({});
    },
    handleSelectChartType: (props: Object) => (
      event: Object,
      value: any,
      _: any,
      fieldName: string,
    ) => {
      props.change(fieldName, value);
      if (R.equals(TOTAL, value)) {
        props.change(GC.FIELD_CHART_GROUP_BY_TYPE, '');
      }
      if (R.and(
        G.notEquals(TOTAL, value),
        R.equals(NO_GROUP, R.pathOr('', ['formValue', 'values', GC.FIELD_CHART_GROUP_BY_TYPE], props)),
      )) {
        props.change(GC.FIELD_CHART_GROUP_BY_TYPE, '');
      }
      props.getChartPreviewSuccess({});
    },
    handlePreview: (props: Object) => () => {
      const values = R.pathOr({}, ['formValue', 'values'], props);
      const reqBody = R.assoc(GC.FIELD_FILTERS, R.prop(GC.FIELD_FILTERS, props), values);
      props.getChartPreviewRequest(reqBody);
    },
    handleClickCancel: (props: Object) => () => {
      props.history.goBack();
      props.getChartSuccess({});
    },
  }),
  pure,
);

const ChartFormComponent = (props: Object) => (
  <PageWrapper>
    <PageHeaderWrapper>
      <PageTitle
        title={G.getWindowLocale('titles:chart-configurations', 'Chart Configurations')} />
    </PageHeaderWrapper>
    <SimpleWrapper width='1350px' jc='space-between'>
      {renderDetails(props)}
      {renderPreview(props)}
    </SimpleWrapper>
    {getCondition(props) && <AdditionFiltersForm {...props} />}
    <FormButtons
      {...props}
      width='auto'
      formId={CHART_CONFIGS_FORM}
      submitting={setDisabledStatus(props)}
      showSaveAsNew={setAdditionBtns(props)}
      zIndex={R.add(props.filters.length, 10)} />
  </PageWrapper>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  formValue: makeSelectForm(state),
  roles: makeSelectRoleList(state),
  previewData: makeSelectPreviewData(state),
  chartConfigs: makeSelectChartConfigs(state),
  initialValues: makeSelectCurrentChart(state),
  previewPending: makeSelectPreviewStatus(state),
  requestPending: makeSelectRequestStatus(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
}));

export default connect(mapStateToProps, {
  getChartSuccess,
  updateChartRequest,
  createChartRequest,
  getChartPreviewRequest,
  getChartPreviewSuccess,
})(enhance(ChartFormComponent));
