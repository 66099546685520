import React from 'react';
import * as R from 'ramda';
import titleCase from 'voca/title_case';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_IFTA_DATE_FROM]: {
    width: 220,
    name: 'titles:date-from',
  },
  [GC.FIELD_IFTA_DATE_TO]: {
    width: 220,
    name: 'titles:date-to',
  },
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_STATUS]: {
    width: 150,
    name: 'titles:status',
    customComponent: ({ data }: Object) => titleCase(R.pathOr('', [GC.FIELD_STATUS], data)),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 220,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
};

export const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_IFTA_DATE_FROM },
    { sequence: 2, name: GC.FIELD_IFTA_DATE_TO },
    { sequence: 3, name: GC.FIELD_NAME },
    { sequence: 4, name: GC.FIELD_STATUS },
    { sequence: 5, name: GC.FIELD_CREATED_DATE },
    { sequence: 6, name: GC.FIELD_CREATED_BY },
  ],
};

export const stateDetailsReport = {
  fields: [
    { sequence: 1, name: GC.FIELD_IFTA_STATE },
    { sequence: 2, name: GC.FIELD_IFTA_TOTAL_DISTANCE },
    { sequence: 3, name: GC.FIELD_IFTA_TOTAL_DISTANCE_UOM },
    { sequence: 4, name: GC.FIELD_IFTA_TOTAL_FUEL_QUANTITY },
    { sequence: 5, name: GC.FIELD_IFTA_FUEL_QUANTITY_UOM },
  ],
};

export const stateDetailsColumnSettings = {
  [GC.FIELD_IFTA_STATE]: {
    width: 150,
    name: 'titles:state',
  },
  [GC.FIELD_IFTA_TOTAL_DISTANCE]: {
    width: 150,
    name: 'titles:total-distance',
  },
  [GC.FIELD_IFTA_TOTAL_DISTANCE_UOM]: {
    width: 150,
    name: 'titles:total-distance-uom',
  },
  [GC.FIELD_IFTA_TOTAL_FUEL_QUANTITY]: {
    width: 150,
    name: 'titles:total-fuel-quantity',
  },
  [GC.FIELD_IFTA_FUEL_QUANTITY_UOM]: {
    width: 150,
    name: 'titles:total-quantity-uom',
  },
};

export const errorColumnSettings = {
  [GC.FIELD_ID]: {
    width: 200,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [GC.FIELD_ERRORS]: {
    width: 500,
    name: 'titles:truck-errors',
    customComponent: ({ data }: Object) => {
      const { errors, message } = data;

      if (R.and(G.isNilOrEmpty(errors), G.isNilOrEmpty(message))) return null;

      return (
        <Box>
          <Box>{message}</Box>
          {
            errors.map(({ message }: Object, index: number) => <Box key={index}>{message}</Box>)
          }
        </Box>
      );
    },
  },
};

export const errorsReport = {
  fields: [
    { sequence: 1, name: GC.FIELD_ID },
    { sequence: 2, name: GC.FIELD_ERRORS },
  ],
};
