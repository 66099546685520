import { Form } from 'formik';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { space, width, fontSize } from 'styled-system';
// helpers
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const AmousBg = 'https://amous-front-dev.s3.amazonaws.com/white-background-new.jpg';

export const FormContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: ${({ width }: Object) => width || '37%'};
  min-width: ${({ minWidth }: Object) => minWidth};
  background-color: ${({ bgColor }: Object) => bgColor};

  & .remember label {
    margin-left: 5px;
    font-weight: 500;
    color: ${() => G.getTheme('colors.light.mainLight')};
  }
`;

export const StyledLinkTo = styled(Link)` 
  ${space}
  ${width}
  ${fontSize}
  
  font-size: 12px;
  margin-top: 15px;
  text-decoration: underline;

  color: ${({ theme }: Object) => theme.colors.black};

  &:focus {
    outline: none;
  }
`;

StyledLinkTo.defaultProps = {
  fontSize: 14,
};

export const FormWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  margin: 0 auto;
  max-width: 2800px;
  background-size: ${({ bs }: Object) => bs || '100% 100%'};
  background-image: url(${({ bgImage }: Object) => bgImage});
`;

export const StyledForm = styled(Form)`
  width: 100%;
  padding: 0 48px;
  position: relative;
  max-width: ${({ maxWidth }: Object) => maxWidth || '500px'};
`;

export const FormLogo = styled.div`
  ${space}

  text-align: left;
  height: ${({ height }: Object) => height};

  & img { max-width: 100% }
`;

export const StyledFormTitle = styled.div`
  font-weight: 900;
  margin-bottom: 15px;
  font-family: Urbanist;
  text-align: ${({ textAlign }: Object) => textAlign};
  font-size: ${({ fontSizeTitle }: Object) => fontSizeTitle || '36px'};
  color: ${({ color }: Object) => color || G.getTheme('colors.black')};
`;

export const StyledFormSubtitle = styled.div`
  font-size: 14px;
  margin-bottom: 21px;
  text-align: ${({ textAlign }: Object) => textAlign};
  color: ${() => G.getTheme('colors.greyMatterhorn')};
  display: ${({ displaySubtitle }: Object) => displaySubtitle};
`;

export const LogoWrapper = styled.span`
  margin-right: 20px;
`;

export const HighlightedText = styled.div`
  font-family: Urbanist;
  color: ${({ color }: Object) => color || G.getTheme('colors.lime')};
`;
