import React from 'react';
import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// components
import { Table } from '../../../components/table';
// icons
import * as I from '../../../svgs';
// ui
import { IconWrapper, HideIfExpandedContainerOpenedBox } from '../../../ui';
// feature dispatch-details-new
import { report, tableSettings } from '../settings/related-data-table-settings';
/////////////////////////////////////////////////

const getReport = () => {
  const hasNotEarningsPermissions = G.hasNotAmousCurrentUserPermissions(R.of(Array, PC.SHOW_EARNINGS_EXECUTE));

  if (hasNotEarningsPermissions) {
    const fields = R.dropLast(1, report.fields);

    return { fields };
  }

  return report;
};

const RelatedDataTable = (props: Object) => {
  const {
    loading,
    itemList,
    loadType,
    totalCount,
    columnSettings,
    handleDeleteTel,
  } = props;

  const tableSettingsToUse = G.ifElse(
    R.and(R.equals(totalCount, 1), G.isLoadTypeClo(loadType)),
    R.assoc('allowEditBtn', true, tableSettings),
    tableSettings,
  );

  const renderRightStickedComponent = (data: Object) => {
    const { guid, isLinked } = data;

    if (isLinked) return null;

    return (
      <HideIfExpandedContainerOpenedBox>
        <IconWrapper
          px={12}
          cursor='pointer'
          onClick={() => handleDeleteTel(guid)}
        >
          {I.trash(G.getTheme('colors.dark.blue'))}
        </IconWrapper>
      </HideIfExpandedContainerOpenedBox>
    );
  };

  const tableData = {
    loading,
    itemList,
    totalCount,
    columnSettings,
    allChecked: false,
    withoutWaypoint: true,
    report: getReport(report),
    renderRightStickedComponent,
    tableSettings: tableSettingsToUse,
    rowStyles: {
      my: '5px',
      borderTop: true,
      borderColor: 'colors.bgGrey',
      activeBorderColor: 'colors.bgGrey',
    },
  };

  return <Table {...tableData} />;
};

export default RelatedDataTable;
