import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, withState } from 'react-recompose';
// components
import ImageCropper from '../../../components/image-cropper';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withState('imageSourse', 'setImageSourse', null),
  withHandlers({
    handleOpenImageCropper: (props: Object) => (event: Object) => {
      const { openModal, closeModal, setImageSourse } = props;

      const handleSubmit = (file: Object) => {
        const data = window.URL.createObjectURL(file);

        setImageSourse(data);

        closeModal();
      };

      const component = (
        <ImageCropper submitAction={handleSubmit} file={event.target.files[0]} />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:change-photo', 'Change Photo'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const ImageCropperComponent = ({ imageSourse, setImageSourse, handleOpenImageCropper }: object) => {
  const description = '<ImageCropper /> component allows image cropping functionality within a defined area.';

  const usage = (
    <ul>
      <li>Parameter `<i>file</i>` is the image file to be cropped.</li>
      <li>Parameter `<i>submitAction</i>` is a callback function that handles the submission of the cropped image.</li>
    </ul>
  );

  const handleClearImageSourse = (event: Object) => {
    event.target.value = null;

    setImageSourse(null);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ImageCropper'
      description={description}
      path='src/components/image-cropper'
    >
      <input
        type='file'
        accept='.png, .jpg, .jpeg'
        onClick={handleClearImageSourse}
        onChange={handleOpenImageCropper}
      />
      <Box mt={G.ifElse(imageSourse, 10, 0)}>
        {
          imageSourse && <img src={imageSourse} alt='crop-img' />
        }
      </Box>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(ImageCropperComponent));
