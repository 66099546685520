import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import LocalLoader from '../../../components/local-loader';
import { ConfirmComponent } from '../../../components/confirm';
import MessageCenter from '../../../components/message-center';
import { ActionsElement } from '../../../components/actions-element';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withFixedPopover, withAsyncInitialDataOnDidMount } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// feature available-driver
import { NoteForm } from './note-form';
import { deleteDriverNoteRequest, createOrUpdateDriverNoteRequest } from '../actions';
//////////////////////////////////////////////////

const getRowActionsOptions = (props: Object) => {
  const { entity, handleUpdateNote, handleDeleteNote } = props;

  const iconColor = G.getTheme('colors.light.blue');

  return [
    {
      permissions: null,
      frontIcon: I.pencil(iconColor),
      action: () => handleUpdateNote(entity),
      text: G.getWindowLocale('actions:edit', 'Edit'),
    },
    {
      permissions: null,
      frontIcon: I.remove(iconColor),
      action: () => handleDeleteNote(entity),
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];
};

const enhance = compose(
  withFixedPopover,
  withAsyncInitialDataOnDidMount,
  connect(null, {
    deleteDriverNoteRequest,
    createOrUpdateDriverNoteRequest,
  }),
  withHandlers({
    handleCreateNote: (props: Object) => ({ message }: Object) => {
      const { driverGuid, closeContent, createOrUpdateDriverNoteRequest } = props;

      const data = {
        driverGuid,
        text: message,
        isCreate: true,
      };

      createOrUpdateDriverNoteRequest(data);

      closeContent();
    },
    handleUpdateNote: (props: Object) => (entity: Object) => {
      const { openModal, closeContent, createOrUpdateDriverNoteRequest } = props;

      const component = (
        <NoteForm
          isRequired={true}
          initialValues={entity}
          submitAction={createOrUpdateDriverNoteRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: G.getWindowLocale('actions:edit-note', 'Edit Note'),
        },
      };

      closeContent();
      openModal(modal);
    },
    handleDeleteNote: (props: Object) => ({ guid }: Object) => {
      const { openModal, closeContent, deleteDriverNoteRequest } = props;

      const textLocale = G.getWindowLocale(
        'messages:remove-ref-confirmation-text',
        'Are you sure you want delete this note?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => deleteDriverNoteRequest(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      closeContent();
      openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => ({ currentTarget }: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: currentTarget,
        content: (
          <ActionsElement version={2} options={getRowActionsOptions({ ...props, entity })} />
        ),
      })
    ),
  }),
  pure,
);

export const Notes = enhance((props: Object) => {
  const { width, height, handleCreateNote, asyncInitialData, handleClickEditIcon } = props;

  const { data, loading } = asyncInitialData;

  return (
    <LocalLoader width={width} height={height} localLoaderOpen={loading}>
      <MessageCenter
        {...props}
        entities={data}
        submitAction={handleCreateNote}
        handleClickEditIcon={handleClickEditIcon}
      />
    </LocalLoader>
  );
});
