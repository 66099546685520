import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
import { compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedBox, StickedFlex } from '../../../ui';
// feature fuel-cards
import { getLineInitFields, fuelCardLineFieldset } from '../settings/edit-form-settings';
//////////////////////////////////////////////////

const LinesSectionHeader = ({ push }: Object) => (
  <StickedBox
    mb={25}
    top='0px'
    p='10px 0'
    zIndex={11}
    display='block'
    bg={G.getTheme('modal.bgColor')}
  >
    <Flex>
      <TextComponent
        p='2px 10px'
        fontSize={14}
        fontWeight={700}
        borderRadius='3px'
        display='inline-block'
        bg={G.getTheme('colors.dark.lightGrey')}
        color={G.getTheme('colors.light.black')}
      >
        {G.getWindowLocale('titles:charges', 'Charges')}
      </TextComponent>
      <Box
        mx='5px'
        cursor='pointer'
        onClick={() => push(getLineInitFields())}
        title={G.getWindowLocale('titles:add-new-charge', 'Add New Charge')}
      >
        {I.plusRound(G.getTheme('colors.dark.blue'))}
      </Box>
    </Flex>
  </StickedBox>
);

const LinesSectionFooter = ({ currency, grandTotal }: Object) => (
  <StickedFlex
    p='10px 0'
    zIndex={11}
    bottom='0px'
    justifyContent='flex-end'
    bg={G.getTheme('modal.bgColor')}
  >
    <TextComponent
      mr={10}
      ml='auto'
      p='2px 10px'
      fontSize={14}
      fontWeight={700}
      borderRadius='3px'
      display='inline-block'
      color={G.getTheme('colors.white')}
      bg={G.getTheme('colors.light.blue')}
    >
      {`${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${currency} ${G.toFixed(grandTotal)}`}
    </TextComponent>
  </StickedFlex>
);

const isPriceOrQuantityChargeField = (fieldName: string) => R.or(
  R.equals(GC.FIELD_FUEL_CARDS_PRICE, fieldName),
  R.equals(GC.FIELD_FUEL_CARDS_QUANTITY, fieldName),
);

const recalculateLinesOnChangeQuantity = ({
  value,
  values,
  lineIndex,
  lineFieldName,
}: Object) => {
  const lines = R.pathOr([], [GC.FIELD_FUEL_CARDS_LINES], values);
  const currentLineWithValue = R.assoc(lineFieldName, value, R.nth(lineIndex, lines));

  const currentChargeWithTotal = R.assoc(
    GC.FIELD_FUEL_CARDS_TOTAL,
    G.mathRoundNumber(R.multiply(
      R.propOr(1, GC.FIELD_FUEL_CARDS_PRICE, currentLineWithValue),
      R.propOr(1, GC.FIELD_FUEL_CARDS_QUANTITY, currentLineWithValue),
    )),
    currentLineWithValue,
  );

  return R.update(lineIndex, currentChargeWithTotal, lines);
};

const enhanceLineFields = compose(
  withHandlers({
    handleRemoveLine: ({ form, remove, lineIndex }: Object) => () => {
      const linesLength = R.length(R.pathOr([], ['values', GC.FIELD_FUEL_CARDS_LINES], form));

      if (R.gt(linesLength, 1)) remove(lineIndex);
    },
    handleChangeInput: (props: Object) => (event: Object) => {
      const { target: { name, value } } = event;
      const { lineIndex, form: { values, handleChange, setFieldValue } } = props;

      const lineFieldName = R.compose(
        R.last,
        R.split('.'),
      )(name);

      if (isPriceOrQuantityChargeField(lineFieldName)) {
        return setFieldValue(
          GC.FIELD_FUEL_CARDS_LINES,
          recalculateLinesOnChangeQuantity({ value, values, lineIndex, lineFieldName }),
        );
      }

      if (R.and(
        R.equals(lineFieldName, GC.FIELD_FUEL_CARDS_PRODUCT_TYPE),
        R.and(
          R.includes(value, [GC.FUEL_CARDS_PRODUCT_TYPE_FUEL, GC.FUEL_CARDS_PRODUCT_TYPE_REEFER]),
          G.isNilOrEmpty(R.path([GC.FIELD_FUEL_CARDS_LINES, lineIndex, GC.FIELD_FUEL_CARDS_PRODUCT_NAME], values)),
        ),
      )) {
        const line = R.mergeRight(
          R.path([GC.FIELD_FUEL_CARDS_LINES, lineIndex], values),
          { [GC.FIELD_FUEL_CARDS_PRODUCT_TYPE]: value, [GC.FIELD_FUEL_CARDS_PRODUCT_NAME]: 'Diesel' },
        );

        return setFieldValue(`${GC.FIELD_FUEL_CARDS_LINES}.${lineIndex}`, line);
      }

      handleChange(event);
    },
  }),
);

const LineFields = enhanceLineFields((props: Object) => {
  const {
    form,
    currency,
    lineIndex,
    handleRemoveLine,
    handleChangeInput,
  } = props;

  const total = `${currency} ${
    R.path(['values', GC.FIELD_FUEL_CARDS_LINES, lineIndex, GC.FIELD_FUEL_CARDS_TOTAL], form)
  }`;

  return (
    <Flex mb={25} justifyContent='space-between'>
      <Flex
        mx='5px'
        display='flex'
        cursor='pointer'
        alignItems='center'
        onClick={handleRemoveLine}
      >
        {I.trash()}
      </Flex>
      <Fieldset2
        {...G.getFormikProps(form)}
        handleChange={handleChangeInput}
        fields={fuelCardLineFieldset(lineIndex)}
        fieldsWrapperStyles={{ mx: 10, flexGrow: 1 }}
      />
      <TextComponent
        width={150}
        p='2px 10px'
        title={total}
        fontSize={14}
        textAlign='center'
        borderRadius='3px'
        withEllipsis={true}
        display='inline-block'
        bg={G.getTheme('colors.dark.lightGrey')}
        color={G.getTheme('colors.light.black')}
      >
        {total}
      </TextComponent>
    </Flex>
  );
});

const LinesSection = (props: Object) => {
  const pathToLines = ['values', GC.FIELD_FUEL_CARDS_LINES];

  const currency = G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    ['values', GC.FIELD_CURRENCY],
    props,
  ));

  const total = R.compose(
    R.sum,
    R.map(({ total, productType }: Object) => G.ifElse(
      R.equals(productType, GC.FUEL_CARDS_PRODUCT_TYPE_DISCOUNT),
      R.negate(total),
      total,
    )),
    R.pathOr([], ['values', GC.FIELD_FUEL_CARDS_LINES]),
  )(props);

  return (
    <Box
      p={15}
      overflow='auto'
      borderTop='1px solid'
      borderColor={G.getTheme('colors.light.grey')}
    >
      <FieldArray
        name={GC.FIELD_FUEL_CARDS_LINES}
        render={(formikArrayProps: Object) => (
          <Box>
            <LinesSectionHeader {...props} push={formikArrayProps.push} />
            <Box>
              {
                R.gt(R.length(R.pathOr([], pathToLines, props)), 0) &&
                R.path(pathToLines, props).map((charge: string, i: string) => (
                  <LineFields {...formikArrayProps} key={i} lineIndex={i} currency={currency} />
                ))
              }
            </Box>
            <LinesSectionFooter grandTotal={total} currency={currency} />
          </Box>
        )}
      />
    </Box>
  );
};

export default LinesSection;
