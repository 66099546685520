import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleFormSubmit: ({ eventGuid, closeModal, handleActionLoadItem }: Object) => (items: Object) => {
      handleActionLoadItem({ items, eventGuid });
      closeModal();
    },
  }),
  pure,
);

const DropItemsForm = enhance((props: Object) => (
  <SelectDropdownForm
    isMulti={true}
    fieldWidth={420}
    options={props.options}
    submitAction={props.handleFormSubmit}
  />
));

export default DropItemsForm;
