import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
// component
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { makeSelectModal } from '../../components/modal/selectors';
// helpers/constants
import { makeSelectInitialSplashScreen } from '../../common/selectors';
// feature carrier-portal
import { CarrierStatusMessageFormWrapper } from './ui';
import CarrierDetailPage from './components/carrier-detail-page';
import {
  addCarrierDoc,
  carrierMobView,
  addCarrierStatusMessage,
} from './actions';
import {
  makeSelectBranchGuid,
  makeSelectCarrierName,
  makeSelectCarrierToken,
  makeSelectCarrierPortalInfo,
  makeSelectStatusReasonCodes,
  makeSelectTelMappedEventsForMap,
  makeSelectCarrierDetailPageForm,
} from './selectors';
//////////////////////////////////////////////////

const CarrierStatusMessageForm = (props: Object) => (
  <CarrierStatusMessageFormWrapper
    overflow='hidden'
    bgImage={R.path(['splashScreenSetting', 'firstBackgroundImageUrl'], props)}
  >
    <Helmet>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
    </Helmet>
    <CarrierDetailPage {...props} />
  </CarrierStatusMessageFormWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  modal: makeSelectModal(state),
  branchGuid: makeSelectBranchGuid(state),
  carrierName: makeSelectCarrierName(state),
  carrierToken: makeSelectCarrierToken(state),
  detailPageInfo: makeSelectCarrierPortalInfo(state),
  statusReasonCodes: makeSelectStatusReasonCodes(state),
  statusMessageForm: makeSelectCarrierDetailPageForm(state),
  splashScreenSetting: makeSelectInitialSplashScreen(state),
  mappedEventsForMap: makeSelectTelMappedEventsForMap(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  addCarrierDoc,
  carrierMobView,
  addCarrierStatusMessage,
})(CarrierStatusMessageForm);
