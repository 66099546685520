import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
import { LocalLoader } from '../../../../components/local-loader';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../../hocs';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 280,
};

const fleetAssignmentFieldSettings = [
  {
    type: 'reactSelect',
    shouldCustomChange: true,
    options: 'primaryDriverOptions',
    label: ['titles:primary-driver'],
    fieldName: 'newPrimaryDriverGuid',
    inputWrapperStyles: {
      ...inputWrapperStyles,
      zIndex: 13,
    },
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: 'primaryDriverPayable',
    label: ['titles:create-primary-driver-invoice'],
  },
  {
    type: 'reactSelect',
    shouldCustomChange: true,
    label: ['titles:secondary-driver'],
    options: 'secondaryDriverOptions',
    fieldName: 'newSecondaryDriverGuid',
    inputWrapperStyles: {
      ...inputWrapperStyles,
      zIndex: 12,
      display: (props: Object) => G.ifElse(
        R.path(['values', 'showSecondaryDriver'], props),
        'flex',
        'none',
      ),
    },
  },
  {
    type: 'toggle',
    fieldName: 'secondaryDriverPayable',
    label: ['titles:create-secondary-driver-invoice'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: (props: Object) => G.ifElse(
        R.path(['values', 'showSecondaryDriver'], props),
        'flex',
        'none',
      ),
    },
  },
  {
    type: 'reactSelect',
    label: ['titles:truck'],
    options: 'truckOptions',
    fieldName: 'newTruckGuid',
    inputWrapperStyles: {
      ...inputWrapperStyles,
      zIndex: 11,
    },
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: 'trailerOptions',
    label: ['titles:trailers'],
    fieldName: 'newTrailerGuids',
    inputWrapperStyles: {
      ...inputWrapperStyles,
      zIndex: 11,
    },
  },
];

export const defaultFleetAssignmentFields = {
  newTruckGuid: null,
  newTrailerGuids: null,
  newPrimaryDriverGuid: null,
  primaryDriverPayable: false,
  newSecondaryDriverGuid: null,
};

const enhance = compose(
  withState('fleetAssignable', 'setFleetAssignable', {}),
  withState('optionsForSelect', 'setOptionsForSelect', {}),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFleetAssignmentFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const { showSecondaryDriver } = values;

      let data = R.dissoc('showSecondaryDriver', values);

      if (G.isFalse(showSecondaryDriver)) {
        data = R.omit(['newSecondaryDriverGuid', 'oldSecondaryDriverGuid', 'secondaryDriverPayable'], data);
      }

      submitAction(data);
    },
  }),
  withHandlers({
    handleGetOptionsForSelect: (props: Object) => async () => {
      const { entity, vendorGuid, branchGuid, setFleetAssignable } = props;

      const trailerOptions = {
        params: { vendorGuid },
      };

      const options = {
        params: { vendorGuid, [GC.BRANCH_GUID]: branchGuid },
      };

      const [truckRes, driverRes, trailerRes] = await Promise.all([
        sendRequest('get', endpointsMap.getAvailableTrucks, options),
        sendRequest('get', endpointsMap.getAvailableDrivers, options),
        sendRequest('get', endpointsMap.getAvailableTrailers, trailerOptions),
      ]);

      let fleetAssignable = {
        truck: truckRes.data,
        driver: driverRes.data,
        trailer: trailerRes.data,
      };

      if (G.isNotNilAndNotEmpty(entity)) {
        const {
          truck,
          trailers,
          primaryDriver,
          secondaryDriver,
        } = entity;

        if (G.isNotNilAndNotEmpty(truck)) {
          const truckAssignable = R.prepend(truck, fleetAssignable.truck);

          fleetAssignable = R.assoc('truck', truckAssignable, fleetAssignable);
        }

        if (G.isNotNilAndNotEmpty(primaryDriver)) {
          const primaryDriverAssignable = R.prepend(primaryDriver, fleetAssignable.driver);

          fleetAssignable = R.assoc('driver', primaryDriverAssignable, fleetAssignable);
        }

        if (G.isNotNilAndNotEmpty(secondaryDriver)) {
          const secondaryDriverAssignable = R.prepend(secondaryDriver, fleetAssignable.driver);

          fleetAssignable = R.assoc('driver', secondaryDriverAssignable, fleetAssignable);
        }

        if (G.isNotNilAndNotEmpty(trailers)) {
          const trailerAssignable = R.concat(trailers, fleetAssignable.trailer);

          fleetAssignable = R.assoc('trailer', trailerAssignable, fleetAssignable);
        }
      }

      setFleetAssignable(fleetAssignable);
    },
    handleCustomChange: (props: Object) => (fieldValue: any, fieldName: string) => {
      const {
        values,
        setValues,
        setFieldValue,
        fleetAssignable,
        optionsForSelect,
        setOptionsForSelect,
      } = props;

      const driverAssignable = G.getPropFromObject('driver', fleetAssignable);

      if (R.equals(fieldName, 'newPrimaryDriverGuid')) {
        const newPrimaryDriverGuid = fieldValue;

        const showSecondaryDriver = R.compose(
          R.pathOr(false, [GC.FIELD_TEAM_DRIVER]),
          R.find(R.propEq(newPrimaryDriverGuid, GC.FIELD_GUID)),
        )(driverAssignable);

        const newValues = R.mergeRight(values, { showSecondaryDriver, newPrimaryDriverGuid });

        const secondaryDriverOptions = R.compose(
          R.map((item: Object) => ({
            [GC.FIELD_VALUE]: G.getGuidFromObject(item),
            [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
          })),
          R.filter(({ guid, teamDriver }: Object) =>
            R.and(G.notEquals(guid, newPrimaryDriverGuid), G.isTrue(teamDriver)),
          ),
        )(driverAssignable);

        const newOptionsForSelect = R.assoc('secondaryDriverOptions', secondaryDriverOptions, optionsForSelect);

        setValues(newValues);
        setOptionsForSelect(newOptionsForSelect);
      }

      if (R.equals(fieldName, 'newSecondaryDriverGuid')) {
        const primaryDriverOptions = R.compose(
          R.map((item: Object) => ({
            [GC.FIELD_VALUE]: G.getGuidFromObject(item),
            [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
          })),
          R.filter(({ guid }: Object) => G.notEquals(guid, fieldValue)),
        )(driverAssignable);

        const newOptionsForSelect = R.assoc('primaryDriverOptions', primaryDriverOptions, optionsForSelect);

        setFieldValue(fieldName, fieldValue);
        setOptionsForSelect(newOptionsForSelect);
      }
    },
    handleSetInitialFormData: (props: Object) => () => {
      const { entity, setValues, fleetAssignable, setOptionsForSelect } = props;

      const editMode = G.isNotNilAndNotEmpty(entity);

      const drivers = R.compose(
        R.uniqBy(R.prop(GC.FIELD_GUID)),
        R.pathOr([], ['driver']),
      )(fleetAssignable);

      const getPrimaryDriverOptions = R.compose(
        R.map((item: Object) => ({
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
          [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
        })),
        R.filter(({ fleetVendorGuid }: Object) => G.isNilOrEmpty(fleetVendorGuid)),
      )(drivers);

      const getSecondaryDriverOptions = R.compose(
        R.map((item: Object) => ({
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
          [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
        })),
        R.filter(R.prop(GC.FIELD_TEAM_DRIVER)),
      )(drivers);

      const getFleetOptions = (fleetType: string) => G.mapUnitIdGuidObjectPropsToLabelValueObject(
        R.uniqBy(R.prop(GC.FIELD_GUID), R.pathOr([], [fleetType], fleetAssignable)),
      );

      const optionsForSelect = {
        truckOptions: getFleetOptions(GC.FIELD_TRUCK),
        primaryDriverOptions: getPrimaryDriverOptions,
        trailerOptions: getFleetOptions(GC.FIELD_TRAILER),
        secondaryDriverOptions: getSecondaryDriverOptions,
      };

      if (G.isTrue(editMode)) {
        const {
          oldTruckGuid,
          primaryDriver,
          oldTrailerGuids,
          secondaryDriver,
          oldPrimaryDriverGuid,
          oldSecondaryDriverGuid,
        } = entity;

        const primaryDriverPayable = R.pathOr(false, [GC.FIELD_PAYABLE], primaryDriver);
        const secondaryDriverPayable = R.pathOr(false, [GC.FIELD_PAYABLE], secondaryDriver);

        const initialValues = {
          oldTruckGuid,
          oldTrailerGuids,
          oldPrimaryDriverGuid,
          primaryDriverPayable,
          secondaryDriverPayable,
          oldSecondaryDriverGuid,
          newTruckGuid: oldTruckGuid,
          newTrailerGuids: oldTrailerGuids,
          newPrimaryDriverGuid: oldPrimaryDriverGuid,
          newSecondaryDriverGuid: oldSecondaryDriverGuid,
          showSecondaryDriver: G.isNotNilAndNotEmpty(secondaryDriver),
        };

        setValues(initialValues);
      }

      setOptionsForSelect(optionsForSelect);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'fleetAssignable',
    callbackName: 'handleSetInitialFormData',
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetOptionsForSelect();
    },
  }),
  pure,
);

const fieldsWrapperStyles = {
  pt: 15,
  width: 600,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const FleetAssignmentForm = (props: Object) => {
  const { handleSubmit, fleetAssignable, optionsForSelect, handleCustomChange } = props;

  return (
    <LocalLoader
      minWidth={600}
      localLoaderOpen={G.isNilOrEmpty(fleetAssignable)}
    >
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...optionsForSelect}
          {...G.getFormikProps(props)}
          fields={fleetAssignmentFieldSettings}
          handleCustomChange={handleCustomChange}
          fieldsWrapperStyles={fieldsWrapperStyles}
        />
        <FormFooter2 />
      </form>
    </LocalLoader>
  );
};

export default enhance(FleetAssignmentForm);
