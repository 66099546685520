import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../../components/form-buttons';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable } from '../../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../../components/form-group-title';
// features
import { withDocumentActions } from '../../../fleet-profile/hocs/with-document-actions';
// forms
import { Form, TabFormWrapper, FormGroupWrapper } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import * as C from '../../constants';
import { withAddOrEditDocument } from '../../hocs';
import { getAdditionaDocumentColumnActions } from '../../helpers';
import { DocumentsTitlePanel } from '../../components/documents-title-panel';
// feature fleet/driver
import { renderField } from './render-field';
import CertificationForm from './certification-form';
import { licenseFields, citizenshipFields } from '../settings/fields-settings';
import { docsSettings, certificationSettings } from '../settings/column-settings';
import { DRIVER_CERTIFICATION_FORM, DRIVER_GROUP_NAME_CERTIFICATION, FIELD_DRIVER_ENDORSEMENTS } from '../constants';
import {
  makeSelectDriverDocuments,
  makeSelectDriverCertification,
  makeSelectDriveDocumentsFilter,
} from '../selectors';
import {
  setDocumentsFilter,
  updateDriverRequest,
  getDriverDocRequest,
  createDriverDocRequest,
  updateDriverDocRequest,
  deleteDriverDocRequest,
  getDriverCertificationRequest,
  createDriverCertificateRequest,
  updateDriverCertificateRequest,
  deleteDriverCertificateRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleFormSubmit: ({ updateDriverRequest }: Object) => (values: Object) => updateDriverRequest(values),
    handleAddCertificate: (props: Object) => () => {
      const component = (
        <CertificationForm
          closeModal={props.closeModal}
          driverConfigs={props.driverConfigs}
          submitAction={props.createDriverCertificateRequest}
          driverGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        />
      );
      const modal = {
        component,
        options: {
          width: 440,
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:add-driver-certification', 'Add Driver Certification'),
          controlButtons: [
            {
              type: 'button',
              id: DRIVER_CERTIFICATION_FORM,
              name: G.getWindowLocale('actions:create', 'Create'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleDeleteCertificate: (props: Object) => (element: Object) => {
      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:driver-certification:confirmation',
            'Are you sure you want delete Driver Certificate',
          )} />
      );
      const modal = {
        component,
        options: {
          width: 440,
          height: '130px',
          btnWidth: '190px',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.closeModal();
                props.deleteDriverCertificateRequest(element.guid);
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleEditCertificate: (props: Object) => (elem: Object) => {
      const component = (
        <CertificationForm
          initialValues={elem}
          closeModal={props.closeModal}
          driverConfigs={props.driverConfigs}
          submitAction={props.updateDriverCertificateRequest}
          driverGuid={R.path(['initialValues', GC.FIELD_GUID], props)} />
      );
      const modal = {
        component,
        options: {
          width: 440,
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:edit-driver-certification', 'Edit Driver Certification'),
          controlButtons: [
            {
              type: 'button',
              id: DRIVER_CERTIFICATION_FORM,
              name: G.getWindowLocale('actions:update', 'Update'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleSelectAddress: (props: Object) => async (value: string) => {
      const { change, openLoader, closeLoader } = props;

      openLoader({ showDimmer: true });

      const { state, country } = await G.geocodeByPlaceAddress(value, 'fleet driver -> EditDriverDocsComponent');

      const locationArr = [
        {
          value: state,
          fieldName: GC.FIELD_LICENSE_STATE,
        },
        {
          value: country,
          fieldName: GC.FILED_LICENSE_COUNTRY,
        },
      ];

      R.forEach(({ value, fieldName }: Object) => change(fieldName, value), locationArr);

      closeLoader();
    },
    handleChangeAddress: ({ change }: Object) => (address: string) => change(GC.FIELD_LICENSE_STATE, address),
    handleChangeEndorsements: ({ change }: Object) => (options: Array) => {
      const endorsements = options.map((option: Object) => option.value);
      change(FIELD_DRIVER_ENDORSEMENTS, endorsements);
    },
  }),
  pure,
);

const documentsMapStateToProps = (state: Object) => createStructuredSelector({
  documents: makeSelectDriverDocuments(state),
  documentsFilter: makeSelectDriveDocumentsFilter(state),
});

const documentsEnhance = compose(
  connect(
    documentsMapStateToProps,
    {
      setDocumentsFilter,
      createDriverDocRequest,
      updateDriverDocRequest,
      deleteDriverDocRequest,
    },
  ),
  withDocumentActions,
  withAddOrEditDocument({ fleetType: C.FLEET_DRIVER }),
);

const Documents = documentsEnhance((props: Object) => {
  const {
    documents,
    driverConfigs,
    handleEditDoc,
    collapsedGroup,
    handleDeleteDoc,
    handlePreviewDocument,
    handleDownloadDocument,
  } = props;

  const documentTypes = R.pathOr([], [GC.FIELD_DOCUMENT_DOCUMENT_TYPE], driverConfigs);

  return (
    <FormGroupTable
      entities={documents}
      tableColumnWidth={100}
      fields={collapsedGroup}
      entitiesFields={docsSettings}
      handleEditRow={handleEditDoc}
      handleDeleteRow={handleDeleteDoc}
      groupName={C.GROUP_NAME_DOCUMENTS}
      isOpened={G.getPropFromObject(C.GROUP_NAME_DOCUMENTS, collapsedGroup)}
      customPanel={() => <DocumentsTitlePanel {...props} filterOptions={documentTypes} />}
      additionalColumnActions={getAdditionaDocumentColumnActions(
        { handlePreviewDocument, handleDownloadDocument, endpointName: 'driverDownloadDoc' },
      )}
    />
  );
});

const renderCertifications = (props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={props.certifications}
    entitiesFields={certificationSettings}
    groupName={DRIVER_GROUP_NAME_CERTIFICATION}
    handleEditRow={props.handleEditCertificate}
    handleAddClick={props.handleAddCertificate}
    handleDeleteRow={props.handleDeleteCertificate}
    isOpened={R.path(['collapsedGroup', DRIVER_GROUP_NAME_CERTIFICATION], props)}
    panelTitle={G.getWindowLocale('titles:driver-certifications', 'Driver Certifications')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(DRIVER_GROUP_NAME_CERTIFICATION)}
  />
);

const EditDriverDocsComponent = (props: Object) => (
  <TabFormWrapper>
    <Form minHeight='max-content' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
      {
        [licenseFields, citizenshipFields].map((group: Object, index: number) => (
          <FormGroupWrapper
            key={index}
            isOpened={R.path(['collapsedGroup', group.name], props)}
          >
            <FormGroupTitleComponent
              withArrowDown={true}
              text={G.getWindowLocale(group.title)}
              isOpened={R.path(['collapsedGroup', group.name], props)}
              onToggleFormGroup={() => props.handleToggleFormGroup(group.name)} />
            {group.fields.map((field: Object, index: number) => renderField(props, field, index))}
          </FormGroupWrapper>
        ))
      }
      <FormButtons
        {...props}
        zIndex={20}
        width='auto'
      />
    </Form>
    <Documents
      openModal={props.openModal}
      openLoader={props.openLoader}
      closeModal={props.closeModal}
      closeLoader={props.closeLoader}
      driverConfigs={props.driverConfigs}
      initialValues={props.initialValues}
      handleEditDoc={props.handleEditDoc}
      collapsedGroup={props.collapsedGroup}
      handleDeleteDoc={props.handleDeleteDoc}
      handleToggleFormGroup={props.handleToggleFormGroup}
      primaryObjectGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
    />
    {renderCertifications(props)}
  </TabFormWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  certifications: makeSelectDriverCertification(state),
});

export default connect(mapStateToProps, {
  getDriverDocRequest,
  updateDriverRequest,
  getDriverCertificationRequest,
  createDriverCertificateRequest,
  updateDriverCertificateRequest,
  deleteDriverCertificateRequest,
})(enhance(EditDriverDocsComponent));
