import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { openModal, closeModal } from '../../../../components/modal/actions';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// ui
import { Box, Grid } from '../../../../ui';
// feature dispatch-details-new
import * as C from '../../constants';
import PageHeader from '../../components/page-header';
import { OrderCardWrapper } from '../../components/cards';
import { withOrderPageActions } from '../hocs/with-page-actions';
import DocumentsTableGroupWrapper from './documents-table-group-wrapper';
import { getOrderMailListRequest, toggleExpandedListsSuccess } from '../actions';
import {
  makeSelectEvents,
  makeSelectMailList,
  makeSelectLoadConfigs,
  makeSelectOrderDetails,
  makeSelectMessageCenter,
  makeSelectLoadDocuments,
  makeSelectExpandedLists } from '../selectors';
//////////////////////////////////////////////////

const cards = [
  C.CARD_TYPE_DOCUMENTS,
  C.CARD_TYPE_DOCUMENTS_POP,
  C.CARD_TYPE_DOCUMENTS_POD,
];

const getPrimaryReferenceValue = R.compose(
  R.path([GC.FIELD_VALUE]),
  R.find(R.propEq(true, GC.FIELD_PRIMARY)),
  R.pathOr([], [GC.FIELD_REFERENCES]),
);

const EnhancedPageHeader = withFixedPopover(withOrderPageActions(PageHeader), true);

const DocumentsTab = (props: Object) => {
  const {
    events,
    mailList,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    loadConfigs,
    loadDetails,
    setActiveTab,
    messageCenter,
    expandedLists,
    loadDocuments,
    getOrderMailListRequest,
    toggleExpandedListsSuccess } = props;

  const autodialApp = G.getConfigValueFromStore(
    GC.UI_LOADBOARD_AUTODIALAPPNAME,
    R.path(['configGroups', GC.UI_CONFIG_GROUP, 'configs'], loadConfigs),
  );
  const commonComponentProps = {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    loadConfigs,
    load: loadDetails,
  };
  const cardProps = {
    ...commonComponentProps,
    autodialApp,
    loadDocuments,
  };
  const tableGroupProps = {
    ...commonComponentProps,
    events,
    mailList,
    messageCenter,
    getOrderMailListRequest,
    documents: loadDocuments,
    toggleExpandedListsSuccess,
  };
  const pageHeaderProps = {
    openModal,
    setActiveTab,
    load: loadDetails,
    [GC.FIELD_PRIMARY_REFERENCE_VALUE]: getPrimaryReferenceValue(loadDetails),
    showFast: G.getConfigValueFromStore(GC.UI_SHOW_FAST_LOAD_INDICATOR, loadConfigs.configsByNames),
  };

  return (
    <Box mx='auto' maxWidth={1550}>
      <EnhancedPageHeader {...pageHeaderProps} activeTab='documents' />
      <Grid
        gridGap={20}
        gridTemplateColumns='repeat(auto-fill, minmax(380px, 1fr))'
      >
        {
          cards.map((cardType: Object, index: number) => (
            <AuthWrapper key={index} width='100%' has={[PC.TEL_DOCUMENT_READ, PC.TEL_DOCUMENT_WRITE]}>
              <OrderCardWrapper {...cardProps} cardType={cardType} />
            </AuthWrapper>
          ))
        }
      </Grid>
      <DocumentsTableGroupWrapper {...tableGroupProps} expandedList={R.last(expandedLists)} />
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  events: makeSelectEvents(state),
  mailList: makeSelectMailList(state),
  loadConfigs: makeSelectLoadConfigs(state),
  loadDetails: makeSelectOrderDetails(state),
  messageCenter: makeSelectMessageCenter(state),
  expandedLists: makeSelectExpandedLists(state),
  loadDocuments: makeSelectLoadDocuments(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  getOrderMailListRequest,
  toggleExpandedListsSuccess })(DocumentsTab);
