
import * as R from 'ramda';
import React from 'react';
// components
import { Label } from '../../../components/label';
import { FormGroupTable } from '../../../components/form-group-table';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { FormGroupTitle, FormGroupTitleWrapper } from '../../../forms';
// icons
import * as I from '../../../svgs';
import { addAssessorialInfoToEntity } from '../helpers';
import { accessorialsSettings } from '../settings/column-settings';
import { carrierAccessorialsEnhance } from '../hocs/carrier-assessorial-enhance';
//////////////////////////////////////////////////

const AccessorialTitlePanel = (props: Object) => {
  const whiteColor = G.getTheme('colors.white');
  const { handleClickAddAssessorial, handleAddSharedAccessorials } = props;

  return (
    <FormGroupTitleWrapper>
      <FormGroupTitle>
        <Label
          endIcon={I.plusRound(whiteColor)}
          endAction={handleClickAddAssessorial}
        >
          {G.getWindowLocale('titles:carrier-accessorials', 'Carrier Accessorials')}
        </Label>
        <Label
          ml={15}
          endIcon={I.plusRound(whiteColor)}
          endAction={handleAddSharedAccessorials}
        >
          {G.getWindowLocale('titles:shared-accessorials', 'Shared Accessorials')}
        </Label>
      </FormGroupTitle>
    </FormGroupTitleWrapper>
  );
};
export const CarrierAssessorials = carrierAccessorialsEnhance((props: Object) => (
  <FormGroupTable
    groupName={props.groupName}
    fields={props.collapsedGroup}
    panelTitle={props.panelTitle}
    entitiesFields={accessorialsSettings}
    handleEditRow={props.handleEditAssessorial}
    handleAddClick={props.handleClickAddAssessorial}
    isOpened={R.path([props.groupName], props.collapsedGroup)}
    successSharedAccessorialsAction={props.successSharedAccessorialsAction}
    handleToggleFormGroup={() => props.handleToggleFormGroup(props.groupName)}
    handlePreview={(entity: Object) => props.handleEditAssessorial(
      entity,
      'titles:view-shared-accessorial',
    )}
    entities={addAssessorialInfoToEntity(
      R.or(props.entities, []),
      R.path(['accessorialConfigParams', 'accessorialConfigList'], props),
    )}
    handleDeleteRow={(entity: Object) => G.ifElse(
      entity.shared,
      props.handleRemoveSharedAccessorial,
      props.handleDeleteAssessorial,
    )(entity)}
    customPanel={() =>
      <AccessorialTitlePanel
        handleClickAddAssessorial={props.handleClickAddAssessorial}
        handleAddSharedAccessorials={props.handleAddSharedAccessorials}
      />
    }
  />
));
