import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Flex, ActionButton } from '../../../../ui';
// feature fleet
import { CreateTruck, CreateDriver, CreateTrailer } from '../../components/create-form';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleCreateFleetEntityByType: ({ openModal }: Object) => (type: string) => {
      const componentsMap = {
        truck: <CreateTruck fromVendorPage={true} />,
        driver: <CreateDriver fromVendorPage={true} />,
        trailer: <CreateTrailer fromVendorPage={true} />,
      };
      const component = G.getPropFromObject(type, componentsMap);

      const titleMap = {
        truck: G.getWindowLocale('titles:create-truck', 'Create Truck'),
        driver: G.getWindowLocale('titles:create-driver', 'Create Driver'),
        trailer: G.getWindowLocale('titles:create-trailer', 'Create Trailer'),
      };
      const title = G.getPropFromObject(type, titleMap);

      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: '430px',
          height: 'auto',
        },
      };
      openModal(modal);
    },
  }),
);

const buttonStyles = {
  ml: 20,
  mt: '6px',
  height: 30,
  minWidth: 110,
  type: 'button',
  borderRadius: '3px',
};

const CreateFleet = ({ handleCreateFleetEntityByType }: Object) => (
  <Flex>
    <ActionButton {...buttonStyles} onClick={() => handleCreateFleetEntityByType('driver')}>
      {G.getWindowLocale('actions:create-driver', 'Create Driver')}
    </ActionButton>
    <ActionButton {...buttonStyles} onClick={() => handleCreateFleetEntityByType('truck')}>
      {G.getWindowLocale('actions:create-truck', 'Create Truck')}
    </ActionButton>
    <ActionButton {...buttonStyles} onClick={() => handleCreateFleetEntityByType('trailer')}>
      {G.getWindowLocale('actions:create-trailer', 'Create Trailer')}
    </ActionButton>
  </Flex>
);

export default enhance(CreateFleet);
