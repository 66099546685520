import * as R from 'ramda';
import React from 'react';
import { Field, FormSection } from 'redux-form';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../components/form-buttons';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncDocumentTemplates } from '../../../hocs';
// forms
import {
  styles,
  renderFormGroup,
  FormGroupWrapper,
} from '../../../forms';
// feature carrier
import { setOptions } from '../helpers';
import { processGroups, dispatchProcessTypeEDIInternalGroup } from '../settings/fields-settings';
//////////////////////////////////////////////////

const isDispatchProcessTypeEDI = R.pathEq(
  GC.TERMINAL_PROCESS_TYPE_EDI,
  [GC.SYSTEM_OBJECT_TERMINAL, GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE],
);

const isDispatchProcessTypeInternal = R.pathEq(
  GC.TERMINAL_PROCESS_TYPE_INTERNAL,
  [GC.SYSTEM_OBJECT_TERMINAL, GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE],
);

const getOptions = (props: Object, field: Object) => {
  if (R.equals(field.nameForAttribute, GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID)) {
    const { dispatchConfigs } = props;
    const { filterForOptions } = field;

    return R.compose(
      G.addEmptyOptionToDropDown,
      R.filter((item: Object) => G.isTrue(G.getPropFromObject(filterForOptions, item))),
    )(dispatchConfigs);
  }

  if (R.propEq(GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID, 'nameForAttribute', field)) {
    const { asyncDocumentTemplates } = props;

    return G.addEmptyOptionToDropDown(G.mapNameGuidToLabelValue(
      R.propOr([], GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS, asyncDocumentTemplates),
    ));
  }

  return setOptions(props, field);
};

const setDisplay = (field: Object, formValues: Object, sectionName: string) => {
  const { display, nameForAttribute } = field;

  const terminalSectionName = R.compose(
    R.last,
    R.split('.'),
  )(sectionName);

  const type = R.path([GC.SYSTEM_OBJECT_TERMINAL, terminalSectionName, GC.FIELD_TYPE], formValues);

  const condition = R.or(
    G.notEquals(type, GC.TERMINAL_PROCESS_TYPE_API),
    G.notContain(
      terminalSectionName,
      [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS],
    ),
  );

  if (condition) return display;

  const fieldsToShow = [
    GC.AUTO_STATUS_CHECK_ENABLED,
    GC.FIELD_TERMINAL_PROCESS_TYPE,
    GC.FIELD_TERMINAL_PROCESS_ENABLED,
    GC.FIELD_TERMINAL_STATUS_CHECK_FREQUENCY,
    GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID,
    GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
  ];

  const fieldsCondition = R.and(
    R.equals(type, GC.TERMINAL_PROCESS_TYPE_API),
    G.notContain(nameForAttribute, fieldsToShow),
  );

  if (fieldsCondition) return 'none';

  return 'flex';
};

const getProcessGroupsByDispatchProcessType = (formValues: Object) => G.ifElse(
  R.or(isDispatchProcessTypeEDI(formValues), isDispatchProcessTypeInternal(formValues)),
  dispatchProcessTypeEDIInternalGroup,
  processGroups,
);

const setFieldInfo = ({ info }: Object, { formValues }: Object) => {
  if (isDispatchProcessTypeEDI(formValues)) {
    return G.getWindowLocale(
      'messages:add-exporter-config',
      'Please add Carrier Edi Exporter for the Dispatch Process Type EDI',
    );
  }

  return info;
};

const TerminalProcessTabComponent = (props: Object) => (
  <div>
    {
      getProcessGroupsByDispatchProcessType(props.formValues).map((group: Object, index: number) => (
        <FormGroupWrapper key={index} isOpened={props.collapsedGroup[group.formGroup]}>
          <FormGroupTitleComponent
            withArrowDown={true}
            text={G.getWindowLocale(group.title)}
            isOpened={props.collapsedGroup[group.formGroup]}
            onToggleFormGroup={() => (
              props.handleToggleFormGroup(group.formGroup)
            )}
          />
          <FormSection name={group.sectionName}>
            {
              group.fields.map((field: Object, index: number) => (
                <Field
                  key={index}
                  direction='row'
                  margin='5px 10px'
                  zI={field.zIndex}
                  errorFontSize={14}
                  labelFontSize={16}
                  labelMargin='5px 0'
                  labelPosition='left'
                  change={props.change}
                  inputs={field.inputs}
                  errorMargin='0 0 5px 0'
                  disabled={field.disabled}
                  type={R.or(field.type, '')}
                  onChange={props.handleChange}
                  info={setFieldInfo(field, props)}
                  additionClass={styles.inputGroup}
                  options={getOptions(props, field)}
                  width={R.or(field.width, '232px')}
                  validate={R.or(field.validate, [])}
                  selectedValues={props[field.values]}
                  label={G.getWindowLocale(field.name)}
                  selectAction={props[field.selectAction]}
                  name={R.or(field.nameForAttribute, field.name)}
                  component={field.component || renderFormGroup(field.type)}
                  display={setDisplay(field, props.formValues, group.sectionName)}
                />
              ))
            }
          </FormSection>
        </FormGroupWrapper>
      ))
    }
    <FormButtons
      {...props}
      width='auto'
      handleClickCancel={props.handleTerminalClickCancel}
    />
  </div>
);

const enhance = compose(
  withAsyncDocumentTemplates,
  withHandlers({
    handleSelectEmailGroup: ({ change }: Object) => (values: Array) => {
      const emailGroups = R.map(R.prop(GC.FIELD_VALUE), R.or(values, []));

      change(
        `${GC.SYSTEM_OBJECT_TERMINAL}.${GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS}.${GC.FIELD_CARRIER_EMAIL_GROUP_GUIDS}`,
        emailGroups,
      );
    },
    handleChange: ({ change }: Object) => (event: Object) => {
      const name = R.path(['currentTarget', GC.FIELD_NAME], event);
      const value = R.path(['currentTarget', GC.FIELD_VALUE], event);

      const fieldName = R.compose(
        R.last,
        R.split('.'),
      )(name);

      if (R.includes(fieldName, [GC.FIELD_TYPE, GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID])) {
        const statusCheckProcessTypeFieldName =
          `${GC.SYSTEM_OBJECT_TERMINAL}.${GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.${fieldName}`;

        change(statusCheckProcessTypeFieldName, value);
      }

      if (R.and(R.equals(fieldName, GC.FIELD_TYPE), R.equals(value, GC.TERMINAL_PROCESS_TYPE_API))) {
        const statusCheckEnabledFieldName = `${GC.SYSTEM_OBJECT_TERMINAL}.${
          GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.${GC.AUTO_STATUS_CHECK_ENABLED}`;

        change(statusCheckEnabledFieldName, true);
      }

      change(name, value);
    },
  }),
  pure,
);

export default enhance(TerminalProcessTabComponent);
