import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet
import { LOCATION_FORM } from '../constants';
// feature fleet/truck
import {
  EDIT_TRAILER_FORM,
  TRAILER_DOCUMENT_FORM } from './constants';
//////////////////////////////////////////////////

const selectFormStore = (state: Object) => (state.form);
const selectTrailerStore = (state: Object) => state.fleetTrailer;

const makeSelectTrailerList = () => createSelector(
  selectTrailerStore,
  ({ trailers }: Object) => trailers,
);

const makeSelectGlobalFilterValue = () => createSelector(
  selectTrailerStore,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectCollapsedGroup = () => createSelector(
  selectTrailerStore,
  ({ collapsedGroup }: Object) => collapsedGroup,
);

const makeSelectTrailerRefList = () => createSelector(
  selectTrailerStore,
  (fleetTrailer: Object) => fleetTrailer[GC.UI_FIELD_REFERENCES],
);

const makeSelectTrailerDocuments = () => createSelector(
  selectTrailerStore,
  ({ documents, documentsFilter }: Object) => {
    if (G.isAnyNilOrEmpty([documents, documentsFilter])) return documents;

    return R.filter(
      ({ documentType }: Object) => R.propEq(documentsFilter, GC.FIELD_DROPDOWN_OPTION_GUID, documentType),
      documents,
    );
  },
);

const makeSelectTrailerDocumentsFilter = () => createSelector(
  selectTrailerStore,
  ({ documentsFilter }: Object) => documentsFilter,
);

const makeSelectTrailerInsurances = () => createSelector(
  selectTrailerStore,
  ({ insurances }: Object) => insurances,
);

const makeSelectExternalIntegrationList = () => createSelector(
  selectTrailerStore,
  ({ externalIntegration }: Object) => externalIntegration,
);

const makeSelectTrailerConfigs = () => createSelector(
  selectTrailerStore,
  ({ trailerConfigs }: Object) => ({
    // TODO: remove or uncomment after testing
    doorType: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_DOOR_TYPE),
    trailerType: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_TRAILER_TYPE),
    documentType: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_DOCUMENT_TYPE),
    bodyMaterial: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_BODY_MATERIAL),
    componentType: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.FLEET_COMPONENT_TYPE),
    insuranceType: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_INSURANCE_TYPE),
    outOfServiceReason: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_OUT_SERVICE_REASON),
    specificationClass: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_SPECIFICATION_CLASS),
    equipmentServiceType: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.FLEET_EQUIPMENT_SERVICE_TYPE),
    unavailabilityReason: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_UNAVAILABILITY_REASON),
    componentManufacturer: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.FLEET_COMPONENT_MANUFACTURER),
    [GC.TEMPLATES_LOCATION_TYPE]: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TEMPLATES_LOCATION_TYPE),
    refrigerationManufacturer: G.mapDropdownConfigWithParentGuid(trailerConfigs, GC.TRAILER_REFTIGERATION_MANUFACTURER),
  }),
);

const makeSelectTrailerDetails = () => createSelector(
  selectTrailerStore,
  ({ trailerDetails, trailerOwnership, trailerSpecification }: Object) => {
    if (G.isNilOrEmpty(trailerDetails)) return null;

    return {
      ...trailerOwnership,
      ownershipVersion: trailerOwnership.version,
      ownershipGuid: trailerOwnership.guid,
      ...trailerSpecification,
      specVersion: trailerSpecification.version,
      specGuid: trailerSpecification.guid,
      ...trailerDetails,
    };
  },
);

const makeSelectTrailerFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => (
    G.ifElse(
      G.isNotNil(form, EDIT_TRAILER_FORM),
      G.getOrElse(form[EDIT_TRAILER_FORM], 'values', {}),
      {},
    )),
);

const makeSelectTrailerActiveTab = () => createSelector(
  selectTrailerStore,
  ({ activeTab }: Object) => activeTab,
);

const makeSelectTrailerLocationsValues = () => createSelector(
  selectFormStore,
  (forms: Object) => forms[LOCATION_FORM],
);

const makeSelectTrailerLocations = () => createSelector(
  selectTrailerStore,
  ({ locations }: Object) => locations,
);

const makeSelectTrailerEquipComponent = () => createSelector(
  selectTrailerStore,
  R.compose(
    R.map((item: Object) => R.assoc(
      'backgroundColor',
      G.getThemeByCond(R.propEq(false, GC.FIELD_INSTALLED, item), 'colors.lightGrey', 'colors.white'),
      item,
    )),
    R.pathOr([], ['equipComponent']),
  ),
);

const makeSelectTrailerDocFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => (
    G.ifElse(
      G.isNotNil(form, TRAILER_DOCUMENT_FORM),
      G.getOrElse(form[TRAILER_DOCUMENT_FORM], 'values', {}),
      {},
    )),
);

const makeSelectTrailerDropDowns = () => (createSelector(
  selectTrailerStore,
  ({ trailerConfigs }: Object) => trailerConfigs.dropdowns,
));

const makeSelectLatestKnownLocationList = () => createSelector(
  selectTrailerStore,
  ({ latestKnownLocation }: Object) => latestKnownLocation,
);

const makeSelectUsedReport = () => createSelector(
  selectTrailerStore,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterProps = () => createSelector(
  selectTrailerStore,
  ({ filterProps }: Object) => filterProps,
);

const makeSelectFilterParams = () => createSelector(
  selectTrailerStore,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectListLoading = () => createSelector(
  selectTrailerStore,
  ({ loading }: Object) => loading,
);

const makeSelectReportStatus = () => createSelector(
  selectTrailerStore,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  selectTrailerStore,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectPagination = () => createSelector(
  selectTrailerStore,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  selectTrailerStore,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectSelectedList = () => createSelector(
  selectTrailerStore,
  (state: Object) => R.compose(
    R.keys,
    R.filter(({ selected }: Object) => selected),
    R.pathOr([], ['trailers']),
  )(state),
);

const makeSelectTableTitleFilters = () => createSelector(
  selectTrailerStore,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

const makeSelectTitleSortValues = () => createSelector(
  selectTrailerStore,
  ({ titleSortValues }: Object) => titleSortValues,
);

export {
  selectTrailerStore,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectTrailerList,
  makeSelectReportStatus,
  makeSelectSelectedList,
  makeSelectFilterParams,
  makeSelectTrailerConfigs,
  makeSelectTrailerDetails,
  makeSelectTrailerRefList,
  makeSelectCollapsedGroup,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTrailerDropDowns,
  makeSelectTrailerActiveTab,
  makeSelectTrailerLocations,
  makeSelectTrailerDocuments,
  makeSelectTrailerInsurances,
  makeSelectGlobalFilterValue,
  makeSelectTrailerFormValues,
  makeSelectTableTitleFilters,
  makeSelectTrailerDocFormValues,
  makeSelectTrailerEquipComponent,
  makeSelectTrailerDocumentsFilter,
  makeSelectTrailerLocationsValues,
  makeSelectExternalIntegrationList,
  makeSelectLatestKnownLocationList,
};
