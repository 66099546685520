import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import OnboardingIntegrationConfigForm from './components/oboarding-integration-config-form';
import GpsIntegrationConfigForm from '../integration/components/gps-integration-config-form';
import {
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest } from '../../actions';
import {
  removeGpsIntegrationConfigRequest,
  removeOnboardingIntegrationConfigRequest,
  createOrUpdateGpsIntegrationConfigRequest,
  createOrUpdateOnboardingIntegrationConfigRequest,
} from './actions';
import {
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectGpsIntegrationList,
  makeSelectConfigInitialValues,
  makeSelectConfigInheritedValues,
  makeSelectOnboardingIntegrationList,
} from '../../selectors';
//////////////////////////////////////////////////

const getModalWithComponent = (component: any, title: string) => ({
  p: '15px',
  component,
  options: {
    title,
    width: '320px',
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
  },
});

const enhance = compose(
  defaultPageConfigEnhancer(GC.DRIVER_CONFIG_GROUP),
  itemPageConfigEnhancer,
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const {
        openModal,
        closeModal,
        handleClickEndLabel,
        createOrUpdateGpsIntegrationConfigRequest,
        createOrUpdateOnboardingIntegrationConfigRequest,
      } = props;

      if (R.equals(configName, GC.CUSTOM_GPS_INTEGRATION_CONFIG)) {
        const component = (
          <GpsIntegrationConfigForm
            closeModal={closeModal}
            submitAction={createOrUpdateGpsIntegrationConfigRequest} />
        );

        const title = G.getAddTitle(['titles:gps-integration-config', 'GPS Integration Config']);
        const modal = getModalWithComponent(component, title);

        return openModal(modal);
      }


      if (R.equals(configName, GC.CUSTOM_DRIVER_ONBOARDING_INTEGRATION_CONFIG)) {
        const component = (
          <OnboardingIntegrationConfigForm
            isEditMode={false}
            closeModal={closeModal}
            submitAction={createOrUpdateOnboardingIntegrationConfigRequest}
          />
        );
        const modal = {
          p: 15,
          component,
          options: {
            width: 300,
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getAddTitle(['titles:onboarding-integration', 'Onboarding Integration']),
          },
        };

        return openModal(modal);
      }

      handleClickEndLabel(configName, popupTitle);
    },
    handleEditGpsIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateGpsIntegrationConfigRequest } = props;

      const component = (
        <GpsIntegrationConfigForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateGpsIntegrationConfigRequest} />
      );

      const title = G.getEditTitle(['titles:gps-integration-config', 'GPS Integration Config']);
      const modal = getModalWithComponent(component, title);

      openModal(modal);
    },
    handleEditOnboardingIntegrationConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateOnboardingIntegrationConfigRequest } = props;

      const component = (
        <OnboardingIntegrationConfigForm
          isEditMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateOnboardingIntegrationConfigRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getEditTitle(['titles:onboarding-integration', 'Onboarding Integration']),
        },
      };

      openModal(modal);
    },
    handleRemoveGpsIntegrationConfig: ({ removeGpsIntegrationConfigRequest }: Object) => (guid: string) =>
      removeGpsIntegrationConfigRequest(guid),
    handleRemoveOnboardingIntegrationConfig: ({ removeOnboardingIntegrationConfigRequest }: Object) => (guid: string) =>
      removeOnboardingIntegrationConfigRequest(guid),
  }),
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:driver'
    groupSettings={GC.DRIVER_CONFIG_GROUP}
    configOptions={R.assoc(
      'documentTypes',
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(props, GC.DRIVER_DOCUMENT_TYPE),
      R.pathOr({}, ['configOptions'], props),
    )}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  gpsIntegrationList: makeSelectGpsIntegrationList()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.DRIVER_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.DRIVER_CONFIG_GROUP),
  onboardingIntegrationList: makeSelectOnboardingIntegrationList()(state),
  initialValues: makeSelectConfigInitialValues(state, GC.DRIVER_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.DRIVER_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  removeGpsIntegrationConfigRequest,
  restoreInheritedByConfigTypeRequest,
  removeOnboardingIntegrationConfigRequest,
  createOrUpdateGpsIntegrationConfigRequest,
  createOrUpdateOnboardingIntegrationConfigRequest,
})(enhance(ConfigPageComponent));
