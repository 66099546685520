import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

class ServiceTypeTransportationMode extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncData: null };
    this.getServiceTypeTransportationModeRequest = this.getServiceTypeTransportationModeRequest.bind(this);
  }

  componentDidMount() {
    this.getServiceTypeTransportationModeRequest(this.props);
  }

  async getServiceTypeTransportationModeRequest(props: Object) {
    const { cloGuids } = props;
    const params = { cloGuids: R.join(',', cloGuids) };
    const res = await sendRequest('get', endpointsMap.serviceTypeTransportationMode, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      let newState;
      if (G.isNilOrEmpty(data)) {
        newState = P.$set('asyncData', {}, this.state);
      } else {
        const asyncData = R.compose(
          R.head,
          R.map(({ serviceType, transportationMode }: Object) => ({
            [GC.FIELD_MODE]: R.pathOr(null, [GC.FIELD_DROPDOWN_OPTION_GUID], transportationMode),
            [GC.FIELD_SERVICE_TYPE]: R.pathOr(null, [GC.FIELD_DROPDOWN_OPTION_GUID], serviceType),
          })),
        )(data);
        newState = P.$set('asyncData', asyncData, this.state);
      }
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncModeAndServiceType -> getServiceTypeTransportationModeRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getDocumentsRequest)}</div>;
  }
}

export const withAsyncModeAndServiceType = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <ServiceTypeTransportationMode
          cloGuids={this.props.cloGuids}
          render={(parentState: Object) =>
            <Component
              {...this.props}
              asyncData={parentState.asyncData} />
          } />
      );
    }
  };
};
