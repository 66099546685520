import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

// service codes
export const PRIORITY_ONE_ACCESSORIAL_SERVICE_CODE_MAP = {
  '*': 'Misc Freight Charge',
  AIRDEL: 'Airport Delivery',
  AIRPU: 'Airport Pickup',
  APPT: 'Delivery Appointment',
  BLIND: 'Blind Shipment',
  CAMPDEL: 'Camp Delivery',
  CAMPPU: 'Camp Pickup',
  CFSDEL: 'Container Freight Station Delivery',
  CFSPU: 'Container Freight Station Pickup',
  CHRCDEL: 'Church Delivery',
  CHRCPU: 'Church Pickup',
  CLUBDEL: 'Country Club Delivery',
  CLUBPU: 'Country Club Pickup',
  CNVDEL: 'Trade Show Delivery',
  CNVPU: 'Trade Show Pickup',
  CONDEL: 'Construction Site Delivery',
  CONPU: 'Construction Site Pickup',
  DOCKDEL: 'Dock Delivery',
  DOCKPU: 'Dock Pickup',
  EDUDEL: 'School Delivery',
  EDUPU: 'School Pickup',
  ELS_10: 'Excessive Length, 10ft',
  ELS_11: 'Excessive Length, 11ft',
  ELS_12: 'Excessive Length, 12ft',
  ELS_13: 'Excessive Length, 13ft',
  ELS_14: 'Excessive Length, 14ft',
  ELS_15: 'Excessive Length, 15ft',
  ELS_16: 'Excessive Length, 16ft',
  ELS_17: 'Excessive Length, 17ft',
  ELS_18: 'Excessive Length, 18ft',
  ELS_19: 'Excessive Length, 19ft',
  ELS_20: 'Excessive Length, 20ft',
  ELS_21: 'Excessive Length, 21ft',
  ELS_22: 'Excessive Length, 22ft',
  ELS_23: 'Excessive Length, 23ft',
  ELS_24: 'Excessive Length, 24ft',
  ELS_25: 'Excessive Length, 25ft',
  ELS_26: 'Excessive Length, 26ft',
  ELS_27: 'Excessive Length, 27ft',
  ELS_28: 'Excessive Length, 28ft',
  ELS_29: 'Excessive Length, 29ft',
  ELS_30: 'Excessive Length, 30ft and over',
  ELS_8: 'Excessive Length, 8ft',
  ELS_9: 'Excessive Length, 9ft',
  FARMDEL: 'Farm Delivery',
  FARMPU: 'Farm Pickup',
  GOVDEL: 'Government Site Delivery',
  GOVPU: 'Government Site Pickup',
  GS10: 'Guaranteed by 10AM',
  GS11: 'Guaranteed by 11AM',
  GS14: 'Guaranteed by 2PM',
  GS15: 'Guaranteed by 3PM',
  GS1530: 'Guaranteed by 3:30PM',
  GS8: 'Guaranteed by 8AM',
  GS9: 'Guaranteed by 9AM',
  GSAM: 'Guaranteed by 12PM',
  GSMUL: 'Guaranteed Multi-Hour Window',
  GUR: 'Guaranteed by 5PM',
  GURWIN: 'Guaranteed by Hour',
  HAZM: 'Hazardous Material',
  HOSDEL: 'Hospital Delivery',
  HOSPU: 'Hospital Pickup',
  HOTLDEL: 'Hotel Delivery',
  HOTLPU: 'Hotel Pickup',
  INDEL: 'Inside Delivery',
  INPU: 'Inside Pickup',
  LGDEL: 'Lift Gate Delivery',
  LGPU: 'Lift Gate Pickup',
  LTDDEL: 'Limited Access Delivery',
  LTDPU: 'Limited Access Pickup',
  NOTIFY: 'Notify Consignee',
  PFZ: 'Protection From Freezing',
  PRISDEL: 'Prison Delivery',
  PRISPU: 'Prison Pickup',
  RESDEL: 'Residential Delivery',
  RESPU: 'Residential Pickup',
  SATPU: 'Saturday Pickup',
  SORTDEL: 'Sort/Segregate Delivery',
  SORTPU: 'Sort/Segregate Pickup',
};

export const XPO_LOGISTICS_ACCESSORIAL_SERVICE_CODE_MAP = {
  IBC: 'IBC IN BOND TIR CARNET',
  OLG: 'OLG ORIG LIFTGATE SERVICE',
  DPW: 'DPW DEST PIER/WHARF LOAD/UNLOAD',
  ELS: 'ELS EXCESSIVE LENGTH SHIPMENT',
  RRS: 'RAPID REMOTE SERVICE',
  TDC: 'TDC TIME DATE CRITICAL/APPOINTMENT',
  CSD: 'CSD CONSTR/UTILITY SITE DELIVERY',
  SRT: 'SRT DEST PALLETIZE, SORT, SEGREGATE',
  WHI: 'INSIDE PKUP/DLVRY TRUCK LEVEL',
  DSH: 'DSH DEST SAT, SUN, HOLIDAY DELIVERY',
  CIB: 'CIB CUSTOMS INBOND U.S.',
  WHN: 'INSIDE PKUP/DLVRY NON-TRUCK LEVEL',
  DID: 'DID DEST INSIDE DELIVERY',
  ZHM: 'ZHM HAZMAT',
  RSD: 'RSD DEST RESIDENTIAL DELIVERY',
  SSC: 'SSC SINGLE SHIPMENT',
  GUR: 'GUR XPO LOGISTICS GUARANTEED!',
  OSH: 'OSH ORIG SAT, SUN, HOLIDAY PICKUP',
  DCF: 'DCF DEST CONTAINER FREIGHT STATION',
  G12: 'G12 XPO LOGISTICS GUARANTEED BEFORE NOON!',
  DMS: 'DMS GOVT AIRPORT MINE GAS OIL DLVRY',
  DAH: 'DAH AFTER BUSINESS HOURS DELIVERY',
  RSO: 'RSO ORIG RESIDENTIAL PICKUP',
  OSS: 'OSS ORIG PALLETIZE, SORT, SEGREGATE',
  PED: 'PED DEST EXHIBITION SITES DELIVERY',
  OCF: 'OCF ORIG CONTAINER FREIGHT STATION',
  OIP: 'OIP ORIG INSIDE PICKUP',
  DNC: 'DNC DEST NOTIFICATION',
  PEO: 'PEO ORIG EXHIBITION SITES PICKUP',
  DLG: 'DLG DEST LIFTGATE SERVICE',
  OCS: 'OCS CONSTR/UTILITY SITE PICKUP',
};

export const DAYTON_FREIGHT_ACCESSORIAL_SERVICE_CODE_MAP = {
  //  Pickup Services
  AIRPU: 'Airport (Pickup)',
  LIMITP: 'Apartment, School, University, Library (Pickup)',
  NOT: 'Appointment Needed or Call Before',
  LIMITP_2: 'Camp, Amusement Park, Marina  (Pickup)',
  LIMITP_3: 'Carnival, Fair, Flea Market, Strip Mall  (Pickup)',
  LIMITP_4: 'Church, Nursing Home, Country Club, Social Club  (Pickup)',
  LIMITP_5: 'Farm, Ranch, Vineyard, Orchard, Winery (Pickup)',
  LIMITP_6: 'Hotel, Motel, Inn, Restaurant (Pickup)',
  LIMITP_7: 'Self Storage Facility (Pickup)',
  LIMITP_8: 'Limited Access (Pickup)',
  CCVP: 'Chicago Trade Show, Exhbition Hall (Pickup)',
  CONSTP: 'Construction Site Pickup',
  LIFTPU: 'Liftgate Trailer for Pick-up',
  MILP: 'Military, Correctional, Plant (Nuclear, Power, Water, Utility) (Pickup)',
  MINESP: 'Mine/Mill Site (Pickup)',
  IPC: 'Pick-up Inside of Facility',
  RESIP: 'Residential Pickup',
  CVP: 'Trade Show (Pickup)',
  AIRDEL: 'Airport (Delivery)',
  LIMIT: 'Apartment, School, University, Library (Delivery)',
  LIMIT_2: 'Camp, Amusement Park, Marina (Delivery)',
  LIMIT_3: 'Carnival, Fair, Flea Market, Strip Mall (Delivery)',
  LIMIT_4: 'Church, Nursing Home, Country Club, Social Club (Delivery)',
  LIMIT_5: 'Farm, Ranch, Vineyard, Orchard, Winery (Delivery)',
  LIMIT_6: 'Hotel, Motel, Inn, Restaurant (Delivery)',
  LIMIT_7: 'Self Storage Facility (Delivery)',
  LIMIT_8: 'Limited Access (Delivery)',
  CCVD: 'Chicago Trade Show, Exhibition Hall (Delivery)',
  CONSTR: 'Construction Site Delivery',
  IDC: 'Delivery Inside of Facility',
  LIFT: 'Liftgate Trailer for Delivery',
  MILD: 'Military, Correctional, Plant (Nuclear, Power, Water, Utility) (Delivery)',
  MINES: 'Mine/Mill Site (Delivery)',
  RESID: 'Residential Delivery',
  CVD: 'Trade Show (Delivery)',
  FRC: 'FRC',
  HMF: 'HMF',
  EXLG08: 'Overlength Freight 8 feet',
  EXLG09: 'Overlength Freight 9 feet',
  EXLG10: 'Overlength Freight 10 feet',
  EXLG11: 'Overlength Freight 11 feet',
  EXLG12: 'Overlength Freight 12 feet',
  EXLG13: 'Overlength Freight 13 feet',
  EXLG14: 'Overlength Freight 14 feet',
  EXLG15: 'Overlength Freight 15 feet',
  EXLG16: 'Overlength Freight 16 feet',
  EXLG17: 'Overlength Freight 17 feet',
  EXLG18: 'Overlength Freight 18 feet',
  EXLG19: 'Overlength Freight 19 feet',
  EXLG20: 'Overlength Freight 20 feet',
};

const ODFL_ACCESSORIAL_SERVICE_CODE_MAP = {
  AID: 'Delivery-airports',
  AIP: 'Pickup-airports',
  ARN: 'Notification prior to delivery',
  CA: 'Scheduled appointment delivery',
  CDC: 'Delivery: schools;colleges & church',
  COD: 'Collect on delivery - cod fee',
  CPC: 'Pickup: schools, colleges & church',
  CSD: 'Delivery: construction site',
  CSP: 'Pickup: construction site',
  DSM: 'Dely: mines/oil fields/quarries',
  EXD: 'Exp center - delivery',
  EXO: 'Exp center -  pickup',
  H10: 'Hhs 10 straps requested',
  H15: 'Hhs 15 straps requested',
  H20: 'Hhs 20 straps requested',
  H25: 'Hhs 25 straps requested',
  HAZ: 'Hazardous materials',
  HDP: 'Hhs same day delivery & pickup @ dest',
  HGO: 'Hhs gtd 2hr spot window-origin',
  HH5: 'Hhs 5 straps requested',
  HHL: 'Hhs live load requested',
  HHT: 'Household services - hand truck',
  HHU: 'Hhs live unload requested',
  HSO: 'Household services scale-origin',
  HSP: 'Hhs same day spot & pickup @ origin',
  HYD: 'Hydraulic liftgate service-delivery',
  HYO: 'Hydraulic liftgate service-pick up',
  IDC: 'Inside delivery',
  IND: 'Addl cargo liab-ltl $',
  INH: 'Liability coverage for:',
  IPC: 'Inside pickup',
  LDC: 'Delivery: secured or limited access',
  LPC: 'Pickup: secured or limited access',
  OV1: 'Overlength article 8 but l/t 12',
  OV3: 'Overlength article 12 but l/t 20 (formerly ovl)',
  OV5: 'Overlength article 20 or more',
  PFF: 'Protect from freezing',
  RDC: 'Delivery: residential/farm',
  RPC: 'Pickup: residential/farm',
  SDS: 'Security divider service',
  STR: 'Storage',
  SWD: 'Delivery to self storage',
};

const A_DUIE_PYLE_ACCESSORIAL_SERVICE_CODE_MAP = {
  NCALL: 'Appointment / Call Before',
  NBLIND: 'Blind / Hidden',
  NCONS: 'Construction Site',
  NGOVT: 'Government',
  NID: 'Inside Delivery',
  NID2: 'Inside Delivery other than Ground',
  NLIFT: 'Delivery Liftgate',
  NPLIFT: 'Pickup Liftgate',
  NUCLR: 'Nuclear',
  NCORIN: 'Prison',
  NRESID: 'Residential',
  SSHIP: 'Single Shipment',
  NLMACC: 'Limited Access',
};

export const FEDEX_ACCESSORIAL_SERVICE_CODE_MAP = {
  BLIND_SHIPMENT: 'Blind Shipment',
  BROKER_SELECT_OPTION: 'Broker Select Option',
  CALL_BEFORE_DELIVERY: 'Call Before Delivery',
  COD: 'COD',
  COD_REMITTANCE: 'COD Remittance',
  CUT_FLOWERS: 'CUT Flowers',
  DANGEROUS_GOODS: 'Dangerous Goods',
  DELIVERY_ON_INVOICE_ACCEPTANCE: 'Delivery On Invoice Acceptance',
  DETENTION: 'Detention',
  DO_NOT_BREAK_DOWN_PALLETS: 'Do Not Break Down Pallets',
  DO_NOT_STACK_PALLETS: 'Do Not Stack Pallets',
  DRY_ICE: 'Dry Ice',
  EAST_COAST_SPECIAL: 'East Coast Special',
  ELECTRONIC_TRADE_DOCUMENTS: 'Electronic Trade Documents',
  EVENT_NOTIFICATION: 'Event Notification',
  EXCLUDE_FROM_CONSOLIDATION: 'Exclude From Consolidation',
  EXCLUSIVE_USE: 'Exclusive Use',
  EXHIBITION_DELIVERY: 'Exhibition Delivery',
  EXHIBITION_PICKUP: 'Exhibition Pickup',
  EXPEDITED_ALTERNATE_DELIVERY_ROUTE: 'Expedited Alternate Delivery Route',
  EXPEDITED_ONE_DAY_EARLIER: 'Expedited One Day Earlier',
  EXPEDITED_SERVICE_MONITORING_AND_DELIVERY: 'Expedited Service Monitoring And Delivery',
  EXPEDITED_STANDARD_DAY_EARLY_DELIVERY: 'Expedited Standard Day Early Delivery',
  EXTRA_LABOR: 'Extra Labor',
  EXTREME_LENGTH: 'Extreme Length',
  FEDEX_ONE_RATE: 'Fedex One Rate',
  FLATBED_TRAILER: 'Flatbed Trailer',
  FOOD: 'Food',
  FREIGHT_GUARANTEE: 'Freight Guarantee',
  FREIGHT_TO_COLLECT: 'Freight To Collect',
  HOLD_AT_LOCATION: 'Hold At Location',
  HOLIDAY_DELIVERY: 'Holiday Delivery',
  HOLIDAY_GUARANTEE: 'Holiday Guarantee',
  HOME_DELIVERY_PREMIUM: 'Home Delivery Premium',
  INSIDE_DELIVERY: 'Inside Delivery',
  INSIDE_PICKUP: 'Inside Pickup',
  INTERNATIONAL_CONTROLLED_EXPORT_SERVICE: 'International Controlled Export Service',
  INTERNATIONAL_MAIL_SERVICE: 'International Mail Service',
  INTERNATIONAL_TRAFFIC_IN_ARMS_REGULATIONS: 'International Traffic In Arms Regulations',
  LIFTGATE_DELIVERY: 'Liftgate Delivery',
  LIFTGATE_PICKUP: 'Liftgate Pickup',
  LIMITED_ACCESS_DELIVERY: 'Limited Access Delivery',
  LIMITED_ACCESS_PICKUP: 'Limited Access Pickup',
  MARKING_OR_TAGGING: 'Marking Or Tagging',
  NON_BUSINESS_TIME: 'Non Business Time',
  PALLETS_PROVIDED: 'Pallets Provided',
  PALLET_SHRINKWRAP: 'Pallet Shrinkwrap',
  PALLET_WEIGHT_ALLOWANCE: 'Pallet Weight Allowance',
  PENDING_COMPLETE: 'Pending Complete',
  PENDING_SHIPMENT: 'Pending Shipment',
  PERMIT: 'Permit',
  PHARMACY_DELIVERY: 'Pharmacy Delivery',
  POISON: 'Poison',
  PORT_DELIVERY: 'Port Delivery',
  PORT_PICKUP: 'Port Pickup',
  PRE_DELIVERY_NOTIFICATION: 'Pre Delivery Notification',
  PRE_EIG_PROCESSING: 'Pre Eig Processing',
  PRE_MULTIPLIER_PROCESSING: 'Pre Multiplier Processing',
  PROTECTION_FROM_FREEZING: 'Protection From Freezing',
};

const SAIA_ACCESSORIAL_SERVICE_CODE_MAP = {
  SINGLE_SHIPMENT: 'Single Shipment',
  ARRIVAL_NOTICE_APPOINTMENT: 'Arrival Notice/Appointment',
  MARKING_TAGGING: 'Marking/Tagging',
  LIMITED_ACCESS_LOCATION_PU: 'Limited Access Location PU',
  LIMITED_ACCESS_LOCATION: 'Limited Access Location',
  LIFT_GATE_SERVICE_PU: 'Liftgate Service PU',
  LIFT_GATE_SERVICE: 'Liftgate Service',
  IN_BOND: 'In Bond',
  INSIDE_PICKUP: 'Inside Pickup',
  INSIDE_DELIVERY: 'Inside Delivery',
  EXCESSIVE_LENGTH: 'Excessive Length',
  HAZARDOUS: 'Hazardous',
  PROTECTION_FROM_FREEZING: 'Protection From Freezing',
  RESIDENTIAL_PICKUP: 'Residential Pickup',
  RESIDENTIAL_DELIVERY: 'Residential Delivery',
  ROOM_OF_CHOICE: 'Room Of Choice',
  WHITE_GLOVE: 'White Glove',
  SORTING_SEGREGATING: 'Sorting/Segregating',
  SORTING_SEGREGATING_PICKUP: 'Sorting/Segregating Pickup',
  BORDER_CROSSING: 'Border Crossing',
  TRADE_SHOW_PICKUP: 'Trade Show Pickup',
  TRADE_SHOW_DELIVERY: 'Trade Show Delivery',
  XTREME_ASSURANCE: 'Xtreme Assurance',
};

export const WARD_ACCESSORIAL_SERVICE_CODE_MAP = {
  APU: 'Airport Pickup Charge',
  APD: 'Airport Delivery Charge',
  CONSTR: 'Construction Site Pickup or Delivery',
  FEEC: 'C.O.D. Fee (Collect)',
  FEEP: 'C.O.D. Fee (Prepaid)',
  GA: 'Guaranteed A.M. Delivery (12:00 Noon)',
  GP: 'Guaranteed P.M. Delivery (3:00 P.M.)',
  HAZCHG: 'HAZMAT Handling Fee',
  IDC: 'Inside Delivery Charge',
  IDCAB: 'Inside Delivery Additional Floor',
  IDCMF: 'Inside Delivery Multiple Floors',
  IPC: 'Inside Pickup Charge',
  IPCAB: 'Inside Pickup Additional Floor',
  IPCMF: 'Inside Pickup Multiple Floors',
  LIFTG: 'Liftgate Charge (Delivery)',
  LIFTGP: 'Liftgate Charge (Pickup)',
  LTDD: 'Limited Access (Delivery)',
  LTDP: 'Limited Access (Pickup)',
  NOT: 'Notify/Appointment Charge',
  NTADJD: 'Not Adjacent to Vehicle — Delivery',
  NTADJP: 'Not Adjacent to Vehicle — Pickup',
  OV649: 'Over Dim 1 piece greater than 6′ X 49″ or more in W',
  OVERL: '*8OVERL* Over Dim 1 piece at least 8′ but less than 12′',
  OVER: '*12OVER* Over Dim 1 piece at least 12′ to 15′',
  OVER15: 'Over Dim 1 piece greater than 15′ L and under 49″ W',
  OV1549: 'Over Dim 1 piece greater than 15′ L and 49″ or more in W',
  OVER18: 'Over Dim 1 piece greater than 18′ L and under 49″ W',
  OV1849: 'Over Dim 1 piece greater than 18′ L and 49″ or more in W',
  LIN24: 'Linear foot 24′',
  LIN30: 'Linear foot 30′',
  PFF: 'Protection from Freezing',
  TRNFR: 'Transfer of Lading',
  RDC: 'Residential Delivery Charge',
  RPC: 'Residential Pickup Charge',
};

export const HOLLAND_ACCESSORIAL_SERVICE_CODE_MAP = {
  HAZARDOUS_MATERIALS: 'HazardousMaterials',
  LIFT_GATE_DELIVERY: 'LiftgateDelivery',
  PROTECT_FROM_FREEZING: 'ProtectFromFreezing',
  IN_BOND_SHIPMENT: 'InBondShipment',
  RESIDENTIAL_DELIVERY: 'ResidentialDelivery',
  NON_COMMERCIAL_DELIVERY: 'NonCommercialDelivery',
  INSIDE_DELIVERY_GROUND_FLOOR: 'InsideDeliveryGroundFloor',
  INSIDE_DELIVERY_NOT_GROUND_FLOOR: 'InsideDeliveryNotGroundFloor',
  APPOINTMENT_DELIVERY: 'AppointmentDelivery',
};

export const REDDAWAY_ACCESSORIAL_SERVICE_CODE_MAP = {
  HAZARDOUS_MATERIALS: 'HazardousMaterials',
  LIFT_GATE_SERVICE: 'LiftgateService',
  INSIDE_DELIVERY: 'InsideDelivery',
  CONSTRUCTION_SITE_DELIVERY: 'ConstructionSiteDelivery',
  IN_BOND_SHIPMENT: 'InBondShipment',
  LIFT_GATE_DELIVERY: 'LiftgateDelivery',
  RESIDENTIAL_DELIVERY: 'ResidentialDelivery',
  NON_COMMERCIAL_DELIVERY: 'NonCommercialDelivery',
  APPOINTMENT_DELIVERY: 'AppointmentDelivery',
  PROTECT_FROM_FREEZING: 'ProtectFromFreezing',
};

export const SEFL_ACCESSORIAL_SERVICE_CODE_MAP = {
  AIRPORT_DELIVERY: 'Airport Delivery',
  AIRPORT_PICKUP: 'Airport Pickup',
  ARRIVAL_NOTICE: 'Arrival Notice',
  CONVENTION_CENTER_DELIVERY: 'Convention Center Delivery',
  CONVENTION_CENTER_PICKUP: 'Convention Center Pickup',
  DAY_DEFINITE_GUARANTEED_SERVICE: 'Day Definite Guaranteed Service',
  DELIVERY_LIFT_GATE: 'Delivery Lift Gate',
  EXPORT_SHIPMENT: 'Export Shipment',
  HAZARDOUS_MATERIALS_SHIPMENTS: 'Hazardous Materials Shipments',
  IMPORT_SHIPMENT: 'Import Shipment',
  INSIDE_DELIVERY: 'Inside Delivery',
  INSIDE_PICKUP: 'Inside Pickup',
  IN_BOND_FEE: 'In Bond Fee',
  LIMITED_ACCESS_DELIVERY: 'Limited Access Delivery',
  LIMITED_ACCESS_PICKUP: 'Limited Access Pickup',
  NOON_GUARANTEED_SERVICE: 'Noon Guaranteed Service',
  PICKUP_LIFT_GATE: 'Pickup Lift Gate',
  PRIVATE_RESIDENCE: 'Private Residence',
  REDELIVER_DOCK_PICKUP: 'Redeliver Dock Pickup',
  REDELIVER_TO_CONSIGNEE: 'Redeliver To Consignee',
  SHIPPER_WILL_BRING_SHIPMENT_TO_CARRIERS_DOCK: 'Shipper Will Bring Shipment To Carriers Dock',
  TRUCK_LOAD_SHIPMENTS: 'Truck Load Shipments',
};

export const AAA_COOPER_ACCESSORIAL_SERVICE_CODE_MAP = {
  CAD: 'Camp Delivery',
  CAP: 'Camp Pickup',
  CCD: 'Country Club Delivery',
  CHD: 'Church Delivery',
  CHP: 'Church Pickup',
  COD: 'Collect On Delivery',
  CSD: 'Construction Site Delivery',
  CSP: 'Construction Site Pickup',
  EXL: 'Excess Length >= 96in (8FT)',
  HMF: 'Hazmat Fees',
  ISD: 'Inside Delivery',
  ISP: 'Inside Pickup',
  LGD: 'Liftgate Delivery',
  LGP: 'Liftgate Pickup',
  MID: 'Military Installation Delivery',
  MIP: 'Military Installation Pickup',
  MND: 'Mine Delivery',
  MNP: 'Mine Pickup',
  NCM: 'Notify Charge',
  PFF: 'Pickup From Freezing',
  PRD: 'Pier Delivery',
  PRP: 'Pier Pickup',
  PSD: 'Prison Site Delivery',
  PSP: 'Prison Site Pickup',
  RAD: 'Restricted Access Delivery',
  RAP: 'Restricted Access Pickup',
  RSD: 'Residential Delivery',
  RSP: 'Residential Pickup',
  RTD: 'Resort Delivery',
  RTP: 'Resort Pickup',
  SCD: 'School Delivery',
  SCP: 'School Pickup',
  SSD: 'Self-Storage Delivery',
  SSP: 'Self-Storage Pickup',
  TOL: 'Transfer Of Lading',
  USD: 'Utility Site Delivery',
  USP: 'Utility Site Pickup',
};

export const SMTL_ACCESSORIAL_SERVICE_CODE_MAP = {
  CONST: 'Construction or Utility Site',
  HAZ: 'Hazardous Materials',
  IB: 'In Bond',
  INSD: 'Inside Delivery Required',
  INSP: 'Inside Pickup',
  LIFTD: 'Lift Gate Required Delivery',
  LIFTP: 'Lift Gate Required Pickup',
  LIMITD: 'Limited Access Delivery',
  LIMITP: 'Limited Access Pickup',
  NOT: 'Notification Required',
  OVER8: 'Over Dim Frt 8',
  OVER12: 'Over Dim Frt 12 to 27',
  OVER28: 'Over Dim Frt 28',
  RESID: 'Residential Delivery',
  RESIP: 'Residential Pickup',
  STOP: 'Stop Off Charges',
};

const NEW_PENN_ACCESSORIAL_SERVICE_CODE_MAP = {
  INSIDE_PICKUP: 'Inside Pickup',
  SINGLE_SHIPMENT: 'Shipment Singel',
  INSIDE_DELIVERY: 'Inside Delivery',
  LIFTGATE_PICKUP: 'Liftgate Pickup',
  NYC_GARMENT_AREA: 'NYC Garment Area',
  LIFTGATE_DELIVERY: 'Liftgate Delivery',
  RESIDENTIAL_PICKUP: 'Residential Pickup',
  RESIDENTIAL_DELIVERY: 'Residential Delivery',
  DELIVERY_APPOINTMENT: 'Delivery Appointment',
  LIMITED_ACCESS_PICKUP: 'Limited Access Pickup',
  LIMITED_ACCESS_DELIVERY: 'Limited Access Delivery',
};

const YRC_ACCESSORIAL_SERVICE_CODE_MAP = {
  FREZ: 'Protect from Freezing',
  HAZM: 'Hazardous Materials',
  ID: 'Inside Delivery',
  IP: 'Inside Pickup',
  LFTD: 'Liftgate Service at Delivery',
  LFTO: 'Liftgate Service at Pickup',
  LTDD: 'Limited Access Delivery',
  LTDO: 'Limited Access Pickup',
  MRK: 'Marking or Tagging',
  NTFY: 'Notify before Delivery',
  HOMD: 'Residential Delivery',
  HOMP: 'Residantial Pickup',
  SS: 'Single Shipment',
  APPT: 'Delivery Appointment',
};

const RIST_ACCESSORIAL_SERVICE_CODE_MAP = {
  PICKUP_INSIDE: 'Pickup Inside',
  PICKUP_LIFTGATE: 'Pickup Liftgate',
  PICKUP_LIMITED_ACCESS: 'Pickup Limited Access',
  PICKUP_NOTIFY: 'Pickup Notify',
  PICKUP_RESIDENTIAL: 'Pickup Residential',
  PICKUP_STRAIGHT_TRUCK: 'Pickup Straight Truck',
  PICKUP_SORT: 'Pickup Sort',
  DELIVERY_INSIDE: 'Delivery Inside',
  DELIVERY_LIFTGATE: 'Delivery Liftgate',
  DELIVERY_LIMITED_ACCESS: 'Delivery Limited Access',
  DELIVERY_NOTIFY: 'Delivery Notify',
  DELIVERY_RESIDENTIAL: 'Delivery Residential',
  DELIVERY_STRAIGHT_TRUCK: 'Delivery Straight Truck',
  DELIVERY_SORT: 'Delivery Sort',
};

const ROADRUNNER_ACCESSORIAL_SERVICE_CODE_MAP = {
  AHD: 'Apartment Delivery',
  AHP: 'Apartment Pickup',
  AID: 'Airport Site Delivery',
  AIP: 'Airport Site Pickup',
  AMD: 'Amusement Park Delivery',
  AMP: 'Amusement Park Pickup',
  APT: 'Appointment Charge',
  BSC: 'Blind Shipment Charge',
  CCD: 'Country Club/Golf Delivery',
  CCP: 'Country Club/Golf Pickup',
  CFS: 'Container Freight Station -Pickup',
  CFD: 'Container Freight Station -Delivery',
  CHD: 'Place of Worship –Delivery',
  CHP: 'Place of Worship –Pickup',
  CPD: 'Camp/Park/Resort Delivery',
  CPP: 'Camp/Park/Resort Pickup',
  CSD: 'Construction Delivery',
  CSP: 'Construction Pickup',
  DCC: 'Distribution Center Delivery',
  ESD: 'Exhibition Site Delivery',
  EXH: 'Exhibition Site Pickup',
  EXL: 'Shipment contains article(s) 12 ft but less than 20 ft',
  EXM: 'Shipment contains article(s) 8 ft but less than 12',
  EXX: 'Shipment contains article(s) 20 ft or greater',
  FAD: 'Farm/Ranch Delivery',
  FAP: 'Farm/Ranch Pickup',
  FAB: 'Floor Above/Below Delivery',
  FSD: 'Forest Delivery',
  GSD: 'Government Site Delivery',
  WCL: 'Hawaiian Will Call',
  HAZ: 'Hazardous Materials',
  HHD: 'Hotel Delivery',
  HHP: 'Hotel Pickup',
  IRD: 'Indian Reservation Delivery',
  ID: 'Inside Delivery',
  IP: 'Inside Pickup',
  LGD: 'Liftgate Delivery',
  LGP: 'Liftgate Pickup',
  PSM: 'Maintain Temperature',
  MSD: 'Mine Site Delivery',
  NBD: 'Non Business Hours Delivery',
  NC: 'Notification Charge',
  NHD: 'Nursing Home Delivery',
  NHP: 'Nursing Home Pickup',
  PWD: 'Pier Port or Warf Delivery',
  PWP: 'Pier Port or Warf Pickup',
  PSD: 'Prison Delivery',
  PSC: 'Protect From Cold',
  PSH: 'Protect From Heat',
  RSD: 'Residential Delivery',
  RSP: 'Residential Pickup',
  SHD: 'School Site Delivery',
  SHP: 'School Site Pickup',
  SS: 'Single Shipment',
  SRT: 'Sort or Segregate',
  STD: 'Storage Unit Delivery',
  STP: 'Storage Unit Pickup',
  USD: 'Utility Site Delivery –Use for Limited Access',
  USP: 'Utility Site Pickup –Use for Limited Access',
};

const MANITOULIN_ACCESSORIAL_SERVICE_CODE_MAP = {
  RESIDENTIAL_PICKUP: 'Residential Pickup',
  TAILGATE_PICKUP: 'Tailgate Pickup',
  FLAT_DECK_PICKUP: 'Flat Deck Pickup',
  INSIDE_PICKUP: 'Inside Pickup',
  DROP_OFF_AT_TERMINAL: 'Drop off at Terminal',
  PICK_UP_AT_TERMINAL: 'Pickup at Terminal',
  WAREHOUSE_PICKUP: 'Warehouse Pickup',
  RESIDENTIAL_DELIVERY: 'Residential Delivery',
  TAILGATE_DELIVERY: 'Tailgate Delivery',
  FLAT_DECK_DELIVERY: 'Flat Deck Delivery',
  INSIDE_DELIVERY: 'Inside Delivery',
  DOCK_PICKUP: 'Dock Pickup',
  PROTECTIVE_SERVICES_HEAT: 'Protective Services Heat',
  PROTECTIVE_SERVICES_FRESH: 'Pritective Services Fresh',
  PROTECTIVE_SERVICES_FROZEN: 'Pritective Services Frozen',
  PROTECTIVE_SERVICES_REEFER: 'Protecctiove Services Reefer',
  CALL_PRIOR_TO_DELIVERY: 'Call Prior To Delivery',
  OFF_HOUR_PICKUP: 'Off Hour Pickup',
  OFF_HOUR_DELIVERY: 'Off Hour Delivery',
  WEEKEND_PICKUP: 'Weekend Pickup',
  WEEKEND_DELIVERY: 'Weekend Delivery',
  DELIVERY_BY_APPOINTMENT: 'Delivery by Appointment',
  RURAL_ROUTE: 'Rural Route',
};

const POLARIS_SERVICE_CODE_MAP = {
  INSIDE_PICKUP: 'Inside Pickup',
  RESIDENTIAL_PICKUP: 'Residential Pickup',
  LIFTGATE_PICKUP: 'Liftgate Pickup',
  INSIDE_DELIVERY: 'Inside Delivery',
  RESIDENTIAL_DELIVERY: 'Residential Delivery',
  LIFTGATE_DELIVERY: 'Liftgate Delivery',
  APPOINTMENT_DELIVERY: 'Appointment Delivery',
};

const GLS_ACCESSORIAL_SERVICE_CODE_MAP = {
  DCV: 'Declared Value',
  DGG: 'Dangerous Goods',
  PHD: 'Residential Delivery',
  TRD: 'Tradeshow Delivery',
  SNR: 'Signature Not Required',
  HFP: 'Hold For Pickup',
  NCV: 'Non Conveyable',
  PHDS: 'Residential Delivery Signature',
  WKD: 'Weekend Delivery',
  CNSTD: 'Construction Site Delivery',
  COD: 'Collect On Delivery',
  HEAT: 'Heating',
  IDEL: 'Inside Delivery',
  PHPU: 'Residential Pickup',
  TGT: 'Tailgate Delivery',
  TGTPU: 'Tailgate Pickup',
};

const UPS_ACCESSORIAL_SERVICE_CODE_MAP = {
  SATURDAY_PICKUP: 'Saturday Pickup',
  HOLD_FOR_PICKUP: 'Hold for Pickup',
  LIFT_GATE_AT_PICKUP: 'Lift Gate at Pickup',
  SATURDAY_DELIVERY: 'Saturday Delivery',
  DROP_OFF_AT_UPS_FACILITY: 'Drop off at UPS Facility',
  LIFT_GATE_AT_DELIVERY: 'Lift Gate at Delivery',
};

const DAY_AND_ROSS_ACCESSORIAL_SERVICE_CODE_MAP = {
  APTFGT: 'Appointment Delivery',
  DANGEROUS: 'Dangerous Goods',
  INSDLY: 'Inside Delivery',
  INSFEE: 'Insurance/Declared Value',
  PRESDL: 'Private Residence/Limited Access Delivery',
  PRESPU: 'Private Residence/Limited Access Pickup',
  PROTEC: 'Protected Service',
  TLGDEL: 'Tailgate Delivery',
  TLGPU: 'Tailgate Pickup',
};

const ARC_BEST_ACCESSORAILS_SERVICE_CODE_MAP = {
  ARRIVAL_NOTIFICATION: 'Arrival Notification',
  CAPACITY_LOAD: 'Capacity Load',
  CHURCH_DELIVERY: 'Church Delivery',
  CHURCH_PICKUP: 'Church Pickup',
  CONSIGNEE_UNLOAD: 'Consignee Unload',
  CONSTRUCTION_SITE_DELIVERY: 'Construction Site Delivery',
  CUSTOMS_OR_IN_BOND_FREIGHT: 'Customs Or In Bond Freight',
  DESTINATION_SERVING_TERMINAL_PICKUP: 'Destination Serving Terminal Pickup',
  DO_NOT_STACK: 'Do Not Stack',
  FLATBED_DELIVERY: 'Flatbed Delivery',
  FREEZE_PROTECTION: 'Freeze Protection',
  HAZARDOUS_SHIPMENT: 'Hazardous Shipment',
  INSIDE_DELIVERY: 'Inside Delivery',
  INSIDE_PICKUP: 'Inside Pickup',
  LIFTGATE_GROUND_DELIVERY: 'Liftgate Ground Delivery',
  LIFTGATE_GROUND_PICKUP: 'Liftgate Ground Pickup',
  MILITARY_SITE_DELIVERY: 'Military Site Delivery',
  MILITARY_SITE_PICKUP: 'Military Site Pickup',
  MINI_STORAGE_DELIVERY: 'Mini Storage Delivery',
  MINI_STORAGE_PICKUP: 'Mini Storage Pickup',
  ORIGIN_SERVING_TERMINAL_DELIVERY: 'Origin Serving Terminal Delivery',
  OTHER_TYPE_OF_LIMITED_ACCESS_DELIVERY: 'Other Type Of Limited Access Delivery',
  OTHER_TYPE_OF_LIMITED_ACCESS_PICKUP: 'Other Type Of Limited Access Pickup',
  OVER_DIMENSION: 'Over Dimension',
  PALLETIZED_SHIPMENT: 'Palletized Shipment',
  RESIDENTIAL_DELIVERY_OPTION: 'Residential Delivery Option',
  RESIDENTIAL_PICKUP: 'Residential Pickup',
  SCHOOL_DELIVERY: 'School Delivery',
  SCHOOL_PICKUP: 'School Pickup',
  SHIPPER_LOAD: 'Shipper Load',
  SINGLE_SHIPMENT: 'Single Shipment',
  TRADESHOW_PICKUP: 'Tradeshow Pickup',
};

const UNISHIPPERS_ACCESSORIAL_SERVICE_CODE_MAP = {
  IS_SIGNATURE_REQUIRED: 'Is Signature Required',
  APPOINTMENT_DELIVERY_FLAG: 'Appointment Delivery Flag',
  DELIVERY_CONFIRMATION_FLAG: 'Delivery Confirmation Flag',
  DIRECT_DELIVERY_ONLY_FLAG: 'Direct Delivery Only Flag',
  HOLD_AT_TERMINAL_FLAG: 'Hold at Terminal Flag',
  INSIDE_DELIVERY_FLAG: 'Inside Delivery Flag',
  INSIDE_PICKUP_FLAG: 'Inside Pickup Flag',
  CARRIER_TERMINAL_PICKUP_FLAG: 'Carrier Terminal Pickup Flag',
  LIFTGATE_DELIVERY_FLAG: 'Liftgate Delivery Flag',
  LIFTGATE_PICKUP_FLAG: 'Liftgate Pickup Flag',
  NOTIFY_BEFORE_DELIVERY_FLAG: 'Notify Before Delivery Flag',
  PROTECTION_FROM_COLD_FLAG: 'Protection from Cold Flag',
  PROTECTION_FROM_HEAT_FLAG: 'Protection from Head Flag',
  RESIDENTIAL_DELIVERY_FLAG: 'Residential Delivery Flag',
  RESIDENTIAL_PICKUP_FLAG: 'Residential Pickup Flag',
  SHIPPER_RELEASE_FLAG: 'Shipper Release Flag',
  SORT_AND_SEGREGATE_FLAG: 'Sort and Segregate Flag',
};

const T_FORCE_WORLD_WIDE_ACCESSORIAL_SERVICE_CODE_MAP = {
  ADRP: 'Airport Drop',
  APPT: 'Appointment',
  AREC: 'Airport Recovery',
  COD: 'COD',
  CONV1: 'Convention-Exhibition Site Delivery',
  CONV2: 'Convention-Exhibition Site Pickup',
  DRCT: 'Driver Count',
  GLTL: 'Guaranteed LTL Service Standard',
  HAZM: 'Hazardous Material',
  IDL: 'Inside Delivery',
  INAR: 'Ins-All Risk',
  IPU: 'Inside Pickup',
  LG1: 'Liftgate Delivery',
  LG2: 'Liftgate Pickup',
  LADAIRP: 'Limited Access Delivery - Airports',
  LADCAMP: 'Limited Access Delivery - Camps',
  LADCEME: 'Limited Access Delivery - Cemetary',
  LADCHUR: 'Limited Access Delivery - Churches',
  LADCONS: 'Limited Access Delivery - Constructions, Job Sites',
  LADDOCK: 'Limited Access Delivery - Dock Obstruction',
  LADFAIR: 'Limited Access Delivery - Fairs, Carnivals, Amusement Parks',
  LADFARM: 'Limited Access Delivery - Farms',
  LADFLEA: 'Limited Access Delivery – Flea Markets',
  LADFUNR: 'Limited Access Delivery – Funeral Homes',
  LADGOLF: 'Limited Access Delivery - Country Club, Golf Course',
  LADGOVE: 'Limited Access Delivery - Government Facilities',
  LADGROC: 'Limited Access Delivery - Grocery Warehouse',
  LADHOSP: 'Limited Access Delivery - Hospitals',
  LADINSP: 'Limited Access Delivery - Inspection Site',
  LADLIBR: 'Limited Access Delivery - Libraries',
  LADMALL: 'Limited Access Delivery - Malls',
  LADMARI: 'Limited Access Delivery - Marina',
  LADMILI: 'Limited Access Delivery - Military Bases, Installations',
  LADMINE: 'Limited Access Delivery - Mine Sites',
  LADMINI: 'Limited Access Delivery - Mini Storage Facilities',
  LADNURS: 'Limited Access Delivery - Nursing Homes',
  LADPARK: 'Limited Access Delivery - Parks, Forest Preserves',
  LADPRIS: 'Limited Access Delivery - Prisons',
  LADRANC: 'Limited Access Delivery - Ranch',
  LADRECR: 'Limited Access Delivery - Recreational, Athletic Facilities',
  LADRESE: 'Limited Access Delivery - Reservations',
  LADRESO: 'Limited Access Delivery - Resorts, Hotels, Motels, Inns, or Lodging',
  LADREST: 'Limited Access Delivery - Restaurants',
  LADSCHO: 'Limited Access Delivery - Schools',
  LADSERV: 'Limited Access Delivery – Service Stations',
  LADSOCL: 'Limited Access Delivery – Social Clubs',
  LADSTEE: 'Limited Access Delivery - Steel Mills',
  LADUTLI: 'Limited Access Delivery - Utility Sites',
  LADWINE: 'Limited Access Delivery - Winery, Brewery, or Distilleries',
  LAPAIRP: 'Limited Access Pickup - Airports',
  LAPCAMP: 'Limited Access Pickup - Camps',
  LAPCEME: 'Limited Access Pickup - Cemetary',
  LAPCHUR: 'Limited Access Pickup - Churches',
  LAPCONS: 'Limited Access Pickup - Constructions, Job Sites',
  LAPDOCK: 'Limited Access Pickup - Dock Obstruction',
  LAPFAIR: 'Limited Access Pickup - Fairs, Carnivals, Amusement Parks',
  LAPFARM: 'Limited Access Pickup - Farms',
  LAPFLEA: 'Limited Access Pickup - Flea Markets',
  LAPFUNR: 'Limited Access Pickup – Funeral Homes',
  LAPGOLF: 'Limited Access Pickup - Country Club, Golf Course',
  LAPGOVE: 'Limited Access Pickup - Government Facilities',
  LAPGROC: 'Limited Access Pickup - Grocery Warehouse',
  LAPHOSP: 'Limited Access Pickup - Hospitals',
  LAPINSP: 'Limited Access Pickup - Inspection Site',
  LAPLIBR: 'Limited Access Pickup - Libraries',
  LAPMALL: 'Limited Access Pickup - Malls',
  LAPMARI: 'Limited Access Pickup - Marina',
  LAPMILI: 'Limited Access Pickup - Military Bases, Installations',
  LAPMINE: 'Limited Access Pickup - Mine Sites',
  LAPMINI: 'Limited Access Pickup - Mini Storage Facilities',
  LAPNURS: 'Limited Access Pickup - Nursing Homes',
  LAPPARK: 'Limited Access Pickup - Parks, Forest Preserves',
  LAPPRIS: 'Limited Access Pickup - Prisons',
  LAPRANC: 'Limited Access Pickup - Ranch',
  LAPRECR: 'Limited Access Pickup - Recreational, Athletic Facilities',
  LAPRESE: 'Limited Access Pickup - Reservations',
  LAPRESO: 'Limited Access Pickup - Resorts, Hotels, Motels, Inns, or Lodging',
  LAPREST: 'Limited Access Pickup - Restaurants',
  LAPSCHO: 'Limited Access Pickup - Schools',
  LAPSERV: 'Limited Access Pickup – Service Stations',
  LAPSOCL: 'Limited Access Pickup - Social Clubs',
  LAPSTEE: 'Limited Access Pickup – Steel Mills',
  LAPUTLI: 'Limited Access Pickup - Utility Sites',
  LAPWINE: 'Limited Access Pickup - Winery, Brewery, or Distilleries',
  NTFY: 'Notification',
  OVL07: 'Overlength 7 feet',
  OVL08: 'Overlength 8 feet',
  OVL09: 'Overlength 9 feet',
  OVL1: 'Excessive Overlength (LKVL Only)',
  OVL10: 'Overlength 10 feet',
  OVL11: 'Overlength 11 feet',
  OVL12: 'Overlength 12 feet',
  OVL13: 'Overlength 13 feet',
  OVL14: 'Overlength 14 feet',
  OVL15: 'Overlength 15 feet',
  OVL16: 'Overlength 16 feet',
  OVL17: 'Overlength 17 feet',
  OVL18: 'Overlength 18 feet',
  OVL19: 'Overlength 19 feet',
  OVL20: 'Overlength 20 feet',
  OVL21: 'Overlength 21 feet',
  OVL22: 'Overlength 22 feet',
  OVL23: 'Overlength 23 feet',
  OVL24: 'Overlength 24 feet',
  OVL25: 'Overlength 25 feet',
  OVL27: 'Overlength 27 feet',
  OVL28: 'Overlength 28 feet',
  OVL29: 'Overlength 29 feet',
  OVL30: 'Overlength 30 feet',
  PROT: 'Protect From Freeze',
  RES1: 'Residential Delivery',
  RES2: 'Residential Pickup',
  SPDLV: 'Special Delivery',
  STSG: 'Sort And Segregate',
  TARP: 'Tarping',
  WGAMPM: 'WG-AMPM Required',
  WGDS: 'WG-Day Specific',
  WGSD: 'WG-Same Day Service',
  WGTS: 'WG-Time Specific Stop',
  WTGS: 'White Glove',
};

export const integrationTypeToServiceCodeMap = {
  [GC.CARRIER_RATE_INTEGRATION_TYPE_POLARIS]: POLARIS_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_GLS]: GLS_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_UPS]: UPS_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_YRC]: YRC_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_RIST]: RIST_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_WARD]: WARD_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_SEFL]: SEFL_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ODFL]: ODFL_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_SAIA]: SAIA_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_SMTL]: SMTL_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_FEDEX]: FEDEX_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_HOLLAND]: HOLLAND_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_NEW_PENN]: NEW_PENN_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_REDDAWAY]: REDDAWAY_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ARC_BEST]: ARC_BEST_ACCESSORAILS_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN]: MANITOULIN_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_AAA_COOPER]: AAA_COOPER_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ROADRUNNER]: ROADRUNNER_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_A_DUIE_PYLE]: A_DUIE_PYLE_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS]: UNISHIPPERS_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAY_AND_ROSS]: DAY_AND_ROSS_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE]: PRIORITY_ONE_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_XPO_LOGISTICS]: XPO_LOGISTICS_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAYTON_FREIGHT]: DAYTON_FREIGHT_ACCESSORIAL_SERVICE_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_T_FORCE_WORLD_WIDE]: T_FORCE_WORLD_WIDE_ACCESSORIAL_SERVICE_CODE_MAP,
};

// status codes
const DAYTON_FREIGHT_STATUS_CODE_MAP = {
  ADD: 'Freight bill has been added (ADD)',
  OFD: 'Shipment is out for delivery (OFD)',
  DLV: 'Shipment delivery has been delivered (DLV)',
  ATD: 'There was an attempt to deliver the shipment (ATD)',
  TPC: 'Shipment has been transferred to a partner (TPC)',
  LOA: 'Shipment has been loaded on an outbound truck (LOA)',
  OK: 'Shipment has been delivered to the destination (OK)',
  PWD: 'Shipment has been delayed due to poor weather (PWD)',
  OTR: 'Shipment is in transit between service centers (OTR)',
  UNL: 'Shipment has been unloaded at a service center (UNL)',
  DSC: 'Shipment has arrived at the destination center (DSC)',
  RP: 'This shipment has been added onto another shipment. The other bill contains the operational tracking information (RP)',
  SBR: 'Day Freight has received the basic shipping information but the shipment is not ready for transit (SBR)',
  SAD: 'Trailer has been dropped of at the destination and is awaiting further action (SAD)',
};

const ODFL_STATUS_CODE_MAP = {
  DELAY: 'delay',
  DELIVERED: 'Delivered',
  IN_TRANSIT: 'In Transit',
  PICKUP_REQUESTED: 'Pickup Requested',
  PICKUP_COMPLETED: 'Pickup Completed',
  PICKUP_CONFIRMED: 'Pickup Confirmed',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  RECEIVED_AT_DOCK: 'Received At Dock',
  DELIVERY_CONFIRMED: 'Delivery Confirmed',
  APPOINTMENT_CONFIRMED: 'Appointment Confirmed',
  APPOINTMENT_CANCELLED: 'Appointment Cancelled',
  APPOINTMENT_ATTEMPTED: 'Appointment Attempted',
  DEPARTED_SERVICE_CENTER: 'Departed Service Center',
  ARRIVED_AT_SERVICE_CENTER: 'Arrived at Service Center',
  APPOINTMENT_SET_OR_CONFIRMED: 'Appointment Set/Confirmed',
  DELIVERED_AND_DELIVERY_CONFIRMED: 'Delivered and Delivery Confirmed',
};

const XPO_LOGISTICS_STATUS_CODE_MAP = {
  CODE_1: 'Picked up',
  CODE_2: 'En route to interim',
  CODE_3: 'Arrived at interim',
  CODE_4: 'Delayed en route to interim',
  CODE_5: 'En route to destination',
  CODE_6: 'Arrived at destination',
  CODE_7: 'Delayed en route to destination',
  CODE_8: 'Out for delivery',
  CODE_10: 'Consolidating shipments per consignee',
  CODE_11: 'Holding on dock for Customs clearance at destination',
  CODE_12: 'Undeliverable',
  CODE_13: 'Appointment required at destination',
  CODE_14: 'Holding on dock for cartage carrier at destination',
  CODE_15: 'All short',
  CODE_17: 'Returned to dock, no attempt to deliver',
  CODE_18: 'Awaiting unloading at consignee',
  CODE_19: 'Attempted delivery',
  CODE_20: 'Delivered part short',
  CODE_21: 'Refused delivery',
  CODE_22: 'Refused for damage',
  CODE_23: 'Delivered by',
  CODE_24: 'Non-Movement PRO (parent)',
  CODE_25: 'At origin',
  CODE_26: 'Shipment has been canceled',
  CODE_27: 'Overage',
  CODE_28: 'Final delivery pending review',
  CODE_29: 'Transfer',
  CODE_30: 'Delivery Receipt Image available',
  CODE_31: 'BOL Image available',
  CODE_32: 'Recorded in system',
  CODE_33: 'Possible Delay Notification',
  CODE_34: 'Follow-up on time',
  CODE_35: 'Follow-up delayed',
  CODE_36: 'Late but no possible delay notification sent',
  CODE_37: 'Late',
  CODE_38: 'Enroute to Alaska',
  CODE_39: 'En route to Hawaii',
  CODE_40: 'En route to Puerto Rico',
  CODE_41: 'International shipment to Mexico has been tendered to the broker',
  CODE_42: 'International shipment from Mexico has been tendered to the broker',
  CODE_43: 'At interim',
  CODE_44: 'At destination',
};

const MACRO_POINT_STATUS_CODE_MAP = {
  X3: 'Arrived - Pickup (X3)',
  AF: 'Departed - Pickup (AF)',
  X1: 'Arrived - DropOff (X1)',
  D1: 'Departed - DropOff (D1)',
};

const FEDEX_STATUS_CODE_MAP = {
  //Movement
  AT_AIRPORT: 'At Airport',
  AT_CANADA_POST_FACILITY: 'At Canada Post facility',
  AT_DELIVERY: 'At Delivery',
  AT_FEDEX_FACILITY: 'At FedEx Facility',
  AT_PICKUP: 'At Pickup',
  ARRIVED_AT: 'Arrived at',
  SHIPMENT_CANCELLED: 'Shipment Cancelled',
  LOCATION_CHANGED: 'Location Changed',
  DELIVERY_DELAY: 'Delivery Delay',
  DELIVERY_EXCEPTION: 'Delivery Exception',
  DELIVERED: 'Delivered',
  DEPARTED: 'Departed',
  VEHICLE_FURNISHED_BUT_NOT_USED: 'Vehicle furnished but not used',
  VEHICLE_DISPATCHED: 'Vehicle Dispatched',
  DELAY: 'Delay',
  ENROUTE_TO_AIRPORT: 'Enroute to Airport',
  ENROUTE_TO_DELIVERY: 'Enroute to Delivery',
  ENROUTE_TO_ORIGIN_AIRPORT: 'Enroute to Origin Airport',
  ENROUTE_TO_PICKUP: 'Enroute to Pickup',
  AT_FEDEX_DESTINATION: 'At FedEx Destination',
  HOLD_AT_LOCATION: 'Hold at Location',
  IN_TRANSIT: 'In Transit',
  IN_TRANSIT_DETAILS: 'In transit (see Details)',
  LEFT_ORIGIN: 'Left Origin',
  ORDER_CREATED: 'Order Created',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  AT_FEDEX_ORIGIN_FACILITY: 'At FedEx origin facility',
  SHIPMENT_INFORMATION_SENT_TO_USPS: 'Shipment information sent to USPS',
  PICKUP_DELAY: 'Pickup Delay',
  PLANE_IN_FLIGHT: 'Plane in Flight',
  PLANE_LANDED: 'Plane Landed',
  IN_PROGRESS: 'In Progress',
  PICKED_UP: 'Picked Up',
  PICKED_UP_DETAILS: 'Picked up (see Details)',
  CDO_REQUESTED: 'CDO requested',
  CDO_MODIFIED: 'CDO Modified',
  AT_USPS_FACILITY: 'At USPS facility',
  CDO_CANCELLED: 'CDO Cancelled',
  RETURN_TO_SHIPPER: 'Return to Shipper',
  RETURN_LABEL_LINK_EMAILED_TO_RETURN_SENDER: 'Return label link emailed to return sender',
  RETURN_LABEL_LINK_CANCELLED_BY_SHIPMENT_ORIGINATOR: 'Return label link cancelled by shipment originator',
  RETURN_LABEL_LINK_EXPIRING_SOON: 'Return label link expiring soon',
  RETURN_LABEL_LINK_EXPIRED: 'Return label link expired',
  SHIPMENT_EXCEPTION: 'Shipment Exception',
  AT_SORT_FACILITY: 'At Sort Facility',
  SPLIT_STATUS: 'Split Status',
  TRANSFER: 'Transfer',
  //Regulatory
  CLEARED_CUSTOMS: 'Cleared Customs',
  CLEARANCE_DELAY: 'Clearance Delay',
  CLEARANCE_IN_PROGRESS: 'Clearance in Progress',
  EXPORT_APPROVED: 'Export Approved',
  //Posession
  CARRIER: 'Carrier',
  RECIPIENT: 'Recipient',
  SHIPPER: 'Shipper',
  CUSTOMS: 'Customs',
  TRANSFER_PARTNER: 'Transfer Partner',
  BROKER: 'Broker',
};

const SAIA_STATUS_CODES = [
  'P/U manifest',
  'Arrived at origin terminal',
  'Loaded on trailer',
  'Linehaul',
  'Break',
  'Dock manifest',
  'Arrived at destination terminal',
  'Arrived at exchange terminal',
  'Arrived at Breakbulk Terminal',
  'Scheduled for delivery',
  'Tendered to partner',
  'En route via partner',
  'Trailer at customer location',
  'Customer loading trailer',
  'Departed from Terminal',
  'Out for delivery',
  'Delivered',
  'Delivered to partner',
  'Cleared',
  'Rev. only',
  'Void',
  'Delete',
  'TestMode',
];

const SEFL_STATUS_CODE_MAP = {
  CDY: 'Closed for city delivery (CDY)',
  CFC: 'Closed for city (CFC)',
  CFD: 'Closed for dispatch (CFD)',
  DFU: 'Dropped for unloading (DFU)',
  DIS: 'Dispatched (DIS)',
  DLV: 'Delivered (DLV)',
  DOC: 'On dock (DOC)',
  DPT: 'Delivery print trailer (DPT)',
  HAP: 'Hold for appointment (HAP)',
  LOD: 'Loaded on trailer (LOD)',
  OSD: 'Over, short, or damaged (OSD)',
  VOD: 'Void (VOD)',
};

const DUIE_PYLE_STATUS_CODE_MAP = {
  PICKUP_WINDOW: 'Pickup Window',
  PICKED_UP: 'Picked Up',
  ARRIVED_AT_TERMINAL: 'Arrived at Terminal',
  DEPARTED_TERMINAL: 'Departed Terminal',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  DELIVERY_APPOINTMENT_WINDOW: 'Delivery Appointment Window',
  ETA_WINDOW: 'ETA Window',
  DELIVERED: 'Delivered',
};

const HOLLAND_AND_REDDAWAY_STATUS_CODE_MAP = {
  ARRIVED_AT_STATUS: 'Arrived at',
  AT_STATUS: 'At',
  DELIVERY_STATUS: 'Delivery',
  DELIVERED_STATUS: 'Delivered',
  EN_ROUTE_TO_STATUS: 'En-route to',
  LOADED_AT_STATUS: 'Loaded at',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  CLOSED_AT: 'Closed at',
  LOADING: 'Loading',
};

const WARD_STATUS_CODE_MAP = {
  UNLOADED_AT_PICKUP: 'Unloaded at pickup facility',
  ARRIVED_AT: 'Arrived at local facility',
  EN_ROUTE: 'En route to carrier',
  OUT_FOR_DELIVERY: 'Out for delivery',
  OUT_FOR_DELIVERY_BY_INTERLINE: 'Out for delivery by interline',
  TRAILER_DEPARTED: 'Trailer departed',
  DELIVERY_TO: 'Delivery to',
  DELIVERED: 'Delivered',
  DELIVERY_ATTEMPTED: 'Delivery attempted, please call',
  BAD_PRO_NUMBER: 'Bad Pro Number',
  NOT_EXIST: 'No status exists',
};

const AAA_COOPER_STATUS_CODE_MAP = {
  APPOINTMENT_DELIVERY: 'Appointment delivery',
  AT_DESTINATION: 'At Destination Terminal',
  AT_ORIGIN: 'At Origin Terminal',
  CONSIGNEE_CLOSED: 'Consignee closed',
  DEDICATED_CUSTOMER: 'Dedicated customer only',
  DELIVERED: 'Delivered',
  DELIVERED_BY_OTHER_CARRIER: 'Delivered by another carrier',
  DESTINED_OUTSIDE_USA: 'Destined outside of USA',
  TO_INTERLINE_PARTNER: 'Giving to interline partner',
  HELD_AT_AACT: 'Held at AACT facility',
  HELD_FOR_CONSOLIDATION: 'Held for consolidation',
  HELD_FOR_STORAGE: 'Held for storage',
  HELD_FOR_DOCK: 'Held on dock for customer PKUP',
  HELD_WITH_STORAGE_CHARGES: 'Held with storage charges',
  IN_TRANSIT: 'In Transit',
  OUT_OF_DELIVERY: 'Out For Delivery',
  DAMAGED: 'Over/Short/Damaged',
  PARTNER_CARRIER_DELIVERING: 'Partner carrier delivering',
  PICKED_UP: 'Picked Up',
  RECONSIGNED: 'Reconsigned',
  REFUSED_BY_CONSIGNEE: 'Refused by consignee',
  RETURNED_NOT_DELIVERED: 'Returned - not delivered',
  RETURNED_TO_TERMINAL: 'Returned to terminal',
  SALVAGE: 'Salvage',
  SHIPMENT_RECEIVED: 'Shipment Received',
  TENDERED_TO_INTERLINE_CARRIER: 'Tendered to Interline Carrier',
  TERMINAL_USE: 'Terminal use',
  FREIGHT_AT_CONSIGNEE: 'Trailer/Freight at consignee',
  UNCLAIMED: 'Unclaimed by Shpr/Cons',
  UNDETERMINED: 'UnDetermined',
};

export const SMTL_STATUS_CODE_MAP = {
  ORIGIN_DOCK: 'Origin Dock at',
  CURRENTLY_MANIFESTED: 'Currently manifested at',
  FREIGHT_IS_MOVING_ON_LINEHAU: 'Freight is moving on linehaul',
  RELAY_DOCK_AT: 'Relay Dock at',
  DESTINATION_DOCK: 'Destination Dock at',
  FREIGHT_TO_BE_UNLOADED: 'Freight to be unloaded on the',
  CITY_MANIFEST_OUT_FOR_DELIVERY: 'City Manifest - Out for Delivery',
  DOCK_MANIFEST: 'Dock manifest',
  CITY_DISPATCH_OUT_FOR_DELIVERY: 'City dispatch - out for delivery',
  CLEARED: 'Cleared',
  DELIVERED_TO_CUSTOMER: 'Delivered to customer',
  PARTNER_OUT_FOR_DELIVERY: 'Partner Out for Delivery',
  OUT_FOR_CITY_DELIVERY: 'Out for City Delivery',
  DELIVERED_TO_PARTNER_CARRIER: 'Delivered to partner carrier',
  RETURNED_SHIPMENT: 'Returned shipment',
  RETURNED_TO_PARTNER_CARRIER: 'Returned to Partner carrier',
  REVENUE_ONLY: 'Revenue only',
  SPOTTED_AT_CUSTOMER_LOCATION: 'Spotted at Customer location',
  GIVEN_TO_AGENT_CARRIER: 'Given to agent carrier',
  PICKUP_MANIFEST: 'Pickup manifest',
  DELETED_BILL: 'Deleted bill',
};

const NEW_PENN_STATUS_CODE_MAP = {
  AT_DESTINATION_TERMINAL: 'At Destination Terminal',
  AT_ORIGIN_TERMINAL: 'At Origin Terminal',
  AT_RELAY_TERMINAL: 'At Relay Terminal',
  DELIVERED: 'Delivered',
  DELIVERY_APPOINTMENT_NOT_SECURED: 'Delivery Appointment Not Secured',
  DELIVERY_APPOINTMENT_SCHEDULED: 'Delivery Appointment Scheduled',
  ENROUTE_TO_PARTNER_CARRIER: 'Enroute To Partner Carrier',
  IN_TRANSIT_FROM_PARTNER_CARRIER: 'In Transit From Partner Carrier',
  IN_TRANSIT_FROM_SHIPPER: 'In Transit From Shipper',
  IN_TRANSIT_TO_DESTINATION_TERMINAL: 'In-transit To Destination Terminal',
  IN_TRANSIT_TO_RELAY_TERMINAL: 'In-transit To Relay Terminal',
  LOADING_AT_PARTNER: 'Loading At Partner',
  LOADING_AT_SHIPPER: 'Loading At Shipper',
  NOW_BEING_DELIVERED: 'Now Being Delivered',
  NOW_BEING_PICKED_UP: 'Now Being Picked Up',
  NOW_ENROUTE_TO_BE_DELIVERED: 'Now Enroute To Be Delivered',
  NOW_ENROUTE_TO_BE_PICKED_UP: 'Now Enroute To Be Picked Up',
  OUT_FOR_DELIVERY_TODAY: 'Out For Delivery Today',
  PICKUP_COMPLETED: 'Pickup Completed',
  PLANNED_DELIVERY_DATE: 'Planned Delivery Date',
  PLANNED_FOR_FUTURE_PICK_UP_DATE: 'Planned For Future Pick Up Date',
  SCHEDULED_FOR_PICKUP_TODAY: 'Scheduled For Pickup Today',
  TRANSFERRED_TO_PARTNER_CARRIER: 'Transferred To Partner Carrier',
  WAITING_TO_CLEAR_CUSTOMS: 'Waiting To Clear Customs',
};

const YRC_STATUS_CODE_MAP = {
  DELIVERED: 'Delivered',
  SPOT: 'Spotted at Consignee (SPOT)',
  OFD: 'Out Of Delivery (OFD)',
  LDD: 'Loaded For Delivery (LDD)',
  ENR: 'Trailer or Container is enroute to city, state (ENR)',
  LDG: 'Trailer or Container is Loading at city, state (LDG)',
  CLS: 'Trailer or Container was Closed at city, state (CLS)',
  ONH: 'Now on Trailer or Container and on-hand at city, state (ONH)',
  OHD: 'Shipment is on-hand at the final destination terminal (OHD)',
  UNL: 'Trailer or Container unloading at city, state (UNL)',
  OFL: 'Trailer or Container to carrier at city, state (OFL)',
  TENDERED_ICMX: 'Tendered ICMX',
  TENDERED_MXEX: 'Tendered MXEX',
  FBO: 'Now at city state country (FBO)',
  PICKED_UP: 'Picked up',
  UNLOADED: 'Unloaded',
  LOADED: 'Loaded',
};

const ROADRUNNER_STATUS_CODE_MAP = {
  PU: 'Freight picked up from shipper location (PU)',
  CLO: 'Trailer closed - ready for dispatch (CLO)',
  DSP: 'Trailer dispatched to destination terminal (DSP)',
  ENR: 'Trailer enroute: <location> (ENR)',
  ARV: 'Trailer arrived at terminal in <location> (ARV)',
  UNL: 'Trailer unloaded at destination terminal (UNL)',
  OFD: 'Shipment out for delivery (OFD)',
  APT: 'Appointment set for delivery to consignee on <date> (APT)',
  RFD: 'Shipment refused by consignee (RFD)',
  ARQ: 'Appointment pending (ARQ)',
  DEL: 'Shipment delivered to consignee (DEL)',
  BRN: 'Customs (BRN)',
  TRF: 'Released to Delivery (TRF)',
  DUD: 'Projected Delivery Date of <date> (DUD)',
  SLC: 'Sailing Date (SLC)',
  WTR: 'Weather Delay (WTR)',
  TDC: 'Trailer Spotted at customer (TDC)',
};

const TRUCKER_TOOLS_STATUS_CODE_MAP = {
  // Stop Related Events
  DE: 'Entered Delivery Location – Radius Geofence (DE)',
  DEC: 'Entered Delivery Location – Custom Geofence (DEC)',
  DX: 'Left Delivery Location – Radius Geofence (DX)',
  DXC: 'Left Delivery Location – Custom Geofence (DXC)',
  PE: 'Entered Pickup Location – Radius Geofence (PE)',
  PEC: 'Entered Pickup Location – Custom Geofence (PEC)',
  PX: 'Left Pickup Location – Radius Geofence (PX)',
  PXC: 'Left Pickup Location – Custom Geofence (PXC)',
  SE: 'Entered Stop at X – Radius Geofence (SE)',
  SEC: 'Entered Stop at X – Custom Geofence (SEC)',
  SX: 'Left Stop at X – Radius Geofence (SX)',
  SXC: 'Left Stop at X – Custom Geofence (SXC)',
  AC: 'Stop actions (AC)',
  // ELD Events
  EAPP: 'Switched From ELD To App Tracking (EAPP)',
  ESET: 'Carrier Has An ELD Provider (ESET)',
  ESUC: 'Successful Connection To ELD Provider (ESUC)',
  ESETIA: 'ELD loc invalid asset (ESETIA)',
  ESETOOD: 'ELD loc out of date (ESETOOD)',
  ASTF: 'Cannot autostart this device - Driver has to open the app to start load track (ASTF)',
  BLCES: 'Email sent to the broker (BLCES)',
  BVLFEL: 'Broker has viewed the Load Track (BVLFEL)',
  CA: 'Cancelled (CA)',
  CL: 'Created (CL)',
  CLCES: 'Email sent to the dispatcher (CLCES)',
  CVLFEL: 'Carrier has viewed the Load Track (CVLFEL)',
  DDA: 'Driver downloaded the app (DDA)',
  DDAGP: 'Driver did not permit Trucker Tools App to find the phone location. Please have the driver turn Location services to Always for Trucker Tools App. (DDAGP)',
  DHA: 'Driver has the app (DHA)',
  DHTDA: 'Driver has to Download the app (DHTDA)',
  DIA: 'Driver installed Trucker Tools App (DIA)',
  DNTS: 'Driver needs to start (DNTS)',
  DOC: 'Driver is charging his phone (DOC)',
  DOFUD: 'Driver has opened the sms from an unsupported device (DOFUD)',
  DOGB: 'Driver has charged his phone (DOGB)',
  DOLB: 'Driver phone is on low battery (DOLB)',
  DTAL: 'Tracking another load (DTAL)',
  DUIA: 'Driver might have uninstalled Trucker Tools App (DUIA)',
  DUNPN: 'Sent verification code to driver (DUNPN)',
  DVL: 'Driver viewed the Load Track (DVL)',
  IGLWIU: 'Driver has set location services to WHILE USING THE APP. Please have the driver turn location services to ALWAYS for Trucker Tools App. (IGLWIU)',
  LDUF: 'Load Track details update failed (LDUF)',
  LGSA: 'Location GPS Set Always (LGSA)',
  LRBD: 'Reset by the dispatcher (LRBD)',
  LSLC: 'Driver has clicked the text message link for the Load Track (LSLC)',
  LSLCF: 'Failed to create a link to send SMS to the driver. Please contact support (LSLCF)',
  LSLS: 'Sent text message to the driver (LSLS)',
  LSLSF: 'Failed to send a text message, Reason (LSLSF)',
  LVBD: 'Driver has viewed the Load Track (LVBD)',
  NAGT: 'GPS temporarily unavailable on users device (NAGT)',
  NALT: 'Driver turned off Location Services on his Android. Please have the driver to turn Location Services back on (NALT)',
  NAM: 'Not a mobile number (NAM)',
  NILT: 'Driver turned off Location Services on his iPhone. Please have the driver to turn Location Services back on (NILT)',
  NINF: 'Driver did not permit Trucker Tools App to find the phone location. Please have the driver turn Location services to Always for Trucker Tools App. (NINF)',
  NS: 'Not Started (NS)',
  PS: 'Pre-started (PS)',
  RA: 'Reassigned (RA)',
  RS: 'Restarted (RS)',
  SASA: 'Stopped at stop address (SASA)',
  SDTB: 'Dwell time started (SDTB)',
  SDTE: 'Dwell time ended (SDTE)',
  SCD: 'Driver Scanned DOCUMENT (SCD)',
  SD: 'Ended (SD)',
  SI: 'Paused (SI)',
  SS: 'Stopped by the system (SS)',
  ST: 'Started (ST)',
  SVLFEL: 'Shipper has viewed the Load Track (SVLFEL)',
  TILTA: 'Tracking interrupted - Send another text message (TILTA)',
  TILTI: 'Tracking interrupted (TILTI)',
  TILTO: 'Tracking interrupted - Device may be switched off (TILTO)',
  TILTTS: 'Tracking interrupted - Text message sent (TILTTS)',
  TILTNS: 'Tracking interrupted load track notification sent (TILTNS)',
  TLT: 'Tracking (TLT)',
  TWSA: 'Tracking will start at TIME on DATE (TWSA)',
  TWSS: 'Tracking will start soon (TWSS)',
  UL: 'Updated (UL)',
  UPBDD: 'Updated broker dispatcher details (UPBDD)',
  UPCDE: 'Updated carrier dispatch email (UPCDE)',
  UPDP: 'Updated driver cell phone (UPDP)',
  UPLN: 'Updated load number (UPLN)',
  UPSE: 'Updated shipper email (UPSE)',
  UPSEF: 'Updated shipper email frequency (UPSEF)',
  UPSLN: 'Updated shipper load number (UPSLN)',
};

const POLARIS_STATUS_CODE_MAP = {
  IN_TRANSIT: 'In Transit',
};

const MANITOULIN_STATUS_CODE_MAP = {
  ROAD_CLOSURE: 'ROAD CLOSURE',
  BILLER_ERROR: 'Biller Error',
  BREAKDOWN: 'Breakdown',
  RECEIVING_BLOCKED: 'Receiving Blocked',
  CONSIGNEE_CLOSED_OR_NOT_AVAILABLE: 'Consignee Closed or not available',
  DELAY_AT_CUSTOMS_OR_NO_BROKER_PAPER_WORK: 'Delay at Customs or no broker paper work',
  CONSIGNEE_REQUEST: 'Consignee Request',
  DELIVERED_BY_APPOINTMENT: 'Delivered By Appointment',
  DELIVERED: 'Delivered',
  DELIVERED_DAMAGED: 'Delivered Damaged',
  LATE: 'Late',
  DELIVERED_EARLY: 'Delivered Early',
  IN_TRANSIT_LATE: 'In Transit - Late',
  DELIVERED_OVER: 'Delivered Over',
  DELIVERED_SHORT: 'Delivered Short',
  SPECIAL_DELIVERY_EQUIPMENT_REQUIRED: 'Special delivery equipment required',
  CONSIGNEE_RECEIVES_FREIGHT_ON_CERTAIN_DAYS: 'Consignee receives freight on certain days',
  HELD_FOR_CHEQUE_PICKUP: 'Held for cheque pickup',
  HELD_FOR_CONSOLIDATION: 'Held for consolidation',
  HELD_FOR_SHIPPER: 'Held for shipper',
  CONSIGNEE_HOLIDAY: 'Consignee Holiday',
  HELD_FOR_PICKUP_BY_CUSTOMER: 'Held for pickup by customer',
  HELD_FOR_RETURN_AUTHORIZATION_NUMBER: 'Held for Return Authorization Number',
  LATE_IN_BOND: 'Late - In Bond',
  LATE_ORIGIN: 'Late - Origin',
  IN_TRANSIT: 'In Transit',
  LATE_DELIVERY: 'Late Delivery',
  LATE_DAMAGED: 'Late Damaged',
  LATE_OVERAGE: 'Late Overage',
  LATE_PART_REFUSED: 'Late Part Refused',
  DELIVERED_LATE_AND_WAS_REFUSED: 'Delivered Late and was refused',
  LATE_SHORTAGE: 'Late Shortage',
  MULTIPLE_STOP_OFF_DELAY_SHIPPER: 'Multiple stop off delay (Shipper)',
  SERVICED_ON_MON_WED_FRI: 'Serviced on Mon, Wed, and Fri',
  NO_FREIGHT_BILL: 'No Freight Bill',
  NO_FREIGHT: 'No Freight',
  NO_RECEIVING_PERSONNEL_OR_NO_ONE_HOME: 'No receiving personnel or no one home',
  DELIVERED_CLEAR: 'Delivered Clear',
  STOCK_NOT_AVAILABLE: 'Plant Delay/Stock not available',
  PARTIALLY_REFUSED: 'Partially Refused',
  POOR_WEATHER_CONDITIONS: 'Poor Weather Conditions',
  REFUSED: 'Refused',
  REFUSED_AND_DELIVERED: 'Refused and Delivered',
  FREIGHT_REROUTED_CONSIGNEE: 'Freight rerouted (Consignee)',
  FREIGHT_REROUTED_SHIPPER: 'Freight rerouted (Shipper)',
  SHIPPER_CAUSED_ERROR: 'Shipper Caused Error',
  ON_STRIKE: 'On Strike',
  SERVICED_TUESDAY_OR_THURSDAY: 'Serviced Tuesday and/or Thursday',
  TRANSFER_TERMINAL_ERROR: 'Transfer Terminal Error',
  UNREALISTIC_ARRIVAL_DATE: 'Unrealistic Arrival Date',
  UNABLE_TO_LOCATE: 'Unable to Locate',
  WRONG_ADDRESS: 'Wrong Address',
  UNKNOWN: 'Delivery information is not available at this time. Please contact customer service for more information',
};

const GLS_STATUS_CODE_MAP = {
  SHIPMENT_CREATED: 'Shipment Created',
  PICKUP: 'Pickup',
  DELIVERED: 'Delivered',
  NOT_PICKED_UP: 'Not Picked Up',
  NOT_DELIVERED: 'Not Delivered',
  DELIVERY_DRIVER_LOADED: 'Delivery Driver Loaded',
  DELIVERY_DRIVER_OFFLOADED: 'Delivery Driver Offloaded',
  CHECK_IN: 'Check-In',
  ARRIVED_FACILITY: 'Arrived Facility',
  REWEIGH: 'Reweigh',
  RECUBE: 'Recube',
  SORTED: 'Sorted',
  OUTBOUND_LOADED: 'Outbound Loaded',
  TENDER_TO_AGENT_PARTNER: 'Tender to Agent/Partner',
  UNDEL_IN: 'Undel In',
  SERVICE_ALERT: 'Service Alert',
  EXCEPTION: 'Exception',
  UNDEL_PROCESSING: 'Undel Processing',
  UNDEL_OUT: 'Undel Out',
  LINEHAUL_DEPART: 'Linehaul Depart',
  LINEHAUL_ARRIVE: 'Linehaul Arrive',
  TRANSPORT_EXCEPTION: 'Transport Exception',
  CLEARANCE: 'Clearance',
  CLEARANCE_EXCEPTION: 'Clearance Exception',
  CREATE: 'Create',
};

const UPS_STATUS_CODE_MAP = {
  DELIVERED: 'Delivered',
  IN_TRANSIT: 'In Transit',
  BILLING_INFORMATION_RECEIVED: 'Billing Information Received',
  BILLING_INFORMATION_VOIDED: 'Billing Information Voided',
  PICKUP: 'Pickup',
  EXCEPTION: 'Exception',
  RETURNED_TO_SHIPPER: 'Returned to Shipper',
  DELIVERED_ORIGIN_CFS: 'Delivered Origin CFS (Freight Only)',
  DELIVERED_DESTINATION_CFS: 'Delivered Destination CFS (Freight Only)',
  WAREHOUSING: 'Warehousing (Freight Only)',
  NOT_AVAILABLE: 'Not Available',
  OUT_FOR_DELIVERY: 'Out for Delivery',
};

const DAY_AND_ROSS_STATUS_CODE_MAP = {
  A: 'Linehaul arrived (A)',
  APMIS: 'Delivery terminal missed appointment (APMIS)',
  APMTX: 'Service matrix error (APMTX)',
  APPRO: 'Appointment proactve Re-Book (APPRO)',
  APREQ: 'Delivery attempt - Appointment was required (date and time) (APREQ)',
  APSAP: 'Delivery attempt - Permanent appointment is required (APSAP)',
  APTRL: 'Trailer delivery manfiest appointment (APTRL)',
  ASACC: 'Attempted to contact consignee (ASACC)',
  ASADD: 'Delivery attempt - Consignee address could not be located (ASADD)',
  ASBOC: 'Beyond our control (ASBOC)',
  ASCLS: 'Delivery attempt - Consignee closed (ASCLS)',
  ASCNR: 'Consignee closed (ASCNR)',
  ASCRD: 'Credit hold (ASCRD)',
  ASDEL: 'Delivery attempt - No dock space or driver delay (ASDEL)',
  ASDIS: 'Emergency - Delay in transit (ASDIS)',
  ASEQP: 'Require special equipment (ASEQP)',
  ASHDY: 'Consignee closed holiday (ASHDY)',
  ASHPU: 'Held at carrier location for recipient pickup (ASHPU)',
  ASINV: 'Consignee closed for inventory (ASINV)',
  ASIPW: 'Goods delayed due to missing or incomplete paperwork (ASIPW)',
  ASLPU: 'Shipment tendered late - Adds one day to service (ASLPU)',
  ASONH: 'On hand. Awaiting further instructions (ASONH)',
  ASRCL: 'Delivery attempt - Consignee closed today (before 5pm local) (ASRCL)',
  ASRDL: 'Refused - Consignee cannot receive on delivery date (ASRDL)',
  ASREC: 'Reconsigneed to new shipper/consignee (ASREC)',
  ASRLD: 'Delivery attempt - Rear load (ASRLD)',
  ASSPH: 'Limited access locations (ASSPH)',
  ASSPT: 'Trailer at consignee*s location unloading (ASSPT)',
  ASSTL: 'Refused - Shipped too late (ASSTL)',
  ASSTS: 'Refused - Shipped too soon (ASSTS)',
  ASWEA: 'Weather - Delay in transit (ASWEA)',
  ASWSP: 'Delay due to work stoppage beyond carrier*s control (ASWSP)',
  B: 'Shipment Released from Customs (B)',
  CDDNA: 'Late rail arrival (CDDNA)',
  DLDNT: 'Delivery - Driver no time (DLDNT)',
  DLFOD: 'Freight left on dock (DLFOD)',
  DLMDR: 'Delivery - Freight misdirected (DLMDR)',
  E: 'Out for delivery (E)',
  F: 'Arrived at Customer Locationm (F)',
  G: 'Picked up (G)',
  H: 'Shipment in Bond/Awaiting Clearance (H)',
  HODEE: 'Head office - Data entry error (HODEE)',
  LHDNA: 'Linehaul - Did not arrive on schedule day (LHDNA)',
  LHFER: 'Delay to ferry schedule - not on time (LHFER)',
  LHLSD: 'Linehaul - Arrived late on scheduled day (LHLSD)',
  M: 'Manifested (M)',
  MSASH: 'All short in transit (MSASH)',
  MSBRK: 'Mechanical - Vehicle breakdown (MSBRK)',
  MSDMG: 'Damaged (MSDMG)',
  MSHAZ: 'Hazardous material or incompatible item (MSHAZ)',
  MSOVR: 'Over (MSOVR)',
  MSPRT: 'Product requires protective service (MSPRT)',
  MSSHT: 'Shortage (MSSHT)',
  OTDNM: 'Origin terminal did not move shipment (OTDNM)',
  OTDRL: 'Origin terminal dock ready late (OTDRL)',
  OTMDR: 'Origin terminal misdirected shipment (OTMDR)',
  P: 'Delivered (P)',
  Q: 'Dropped trailer (Q)',
  R: 'Not Delivered - Please contact Customer Service at 866-329-7677 (R)',
  REBAD: 'Refused - Incorrect address provided (REBAD)',
  REBPO: 'Refused - Invalid or missing purchase order number (REBPO)',
  RECAN: 'Refused - Consignee cancelled order (RECAN)',
  RECNO: 'Refused - Consignee did not order freight (RECNO)',
  REDMG: 'Refused - Damaged on delivery (REDMG)',
  REDUP: 'Refused - Duplicate shipment (REDUP)',
  RENPL: 'Refused - Missing packing list (RENPL)',
  RENRA: 'Refused - No return authorization (RENRA)',
  REPRD: 'Refused - Wrong product shipped (REPRD)',
  RESHT: 'Refused - Short on delivery (RESHT)',
  RETRM: 'Refused - Consignee disputes freight charges or terms (RETRM)',
  T: 'Transfer to Partner (T)',
  TBBRO: 'Timely customs accept. Not received (TBBRO)',
  TBCUS: 'Shipments delayed due to customs inspection (TBCUS)',
  TBINB: 'Shipment is in bond/Waiting customs clearance (TBINB)',
  TBIPW: 'Goods delayed due to missing or incomplete paperwork (TBIPW)',
  TBNBC: 'No correct broker information provided (TBNBC)',
  TFDNM: 'Transfer - Did not move shipment (TFDNM)',
  TFMDR: 'Transfer - Misdirected shipment (TFMDR)',
  U: 'Not Delivered - Please contact Customer Service at 866-329-7677 (U)',
  W: 'Departed From (W)',
  Y: 'Appointment required for delivery (Y)',
};

const FOUR_KITES_STATUS_CODE_MAP = {
  AT_PICKUP: 'At Pickup',
  DEPARTED_PICKUP: 'Departed Pickup',
  AUTO_PICKUP: 'Auto Pickup',
  PICKED_UP: 'Picked Up',
  RE_ENTRY: 'Re-entry',
  EARLY: 'Early',
  ON_TIME: 'On Time',
  DELAYED: 'Delayed',
  DELAYED_LOAD: 'Delayed Load',
  DELAYED_UNLOAD: 'Delayed Unload',
  AT_CONSIGNEE: 'At Consignee',
  DELIVERED: 'Delivered',
  DEPARTED_CONSIGNEE: 'Departed Consignee',
  AUTO_DELIVERY: 'Auto Delivery',
  MARKED_AS_INCOMPLETE: 'Marked As Incomplete',
};

const RIST_STATUS_CODES = {
  AWAITING: 'Awaiting',
  CANCELLED: 'Cancelled',
  CARRIER_DEL: 'Carrier DEL',
  DELIVERED: 'Delivered',
  DELIVERY_EXCEPTION: 'Delivery Exception',
  DOCKED: 'Docked',
  ENROUTE_TRM: 'Enroute TRM',
  HANDLING: 'Handling',
  OFD: 'OFD',
  PICKED_UP: 'Picked Up',
  PICKUP_EXCEPTION: 'Pickup Exception',
  ENROUTE_DEL: 'Enroute DEL',
};

const ARC_BEST_STATUS_CODES = {
  A: 'Awaiting (A)',
  C: 'Cancelled (C)',
  D: 'Deleted (D)',
  P: 'Picked Up (P)',
  R: 'Rejected (R)',
  S: 'Spotted (S)',
  AP_O: 'Agent Pass Over (AP_0)',
  APKUP: 'Agent Pickup (APKUP)',
  ARR: 'Arrived (ARR)',
  ARRV: 'Arrived (ARRV)',
  ASHRT: 'All Short (ASHRT)',
  ATCUS: 'At Customs (ATCUS)',
  ATRMP: 'At ramp (ATRMP)',
  AWDEL: 'Awaiting Delivery (AWDEL)',
  BOOK: 'Booked (BOOK)',
  CLDNG: 'City Loading (CLDNG)',
  CSCHD: 'City Scheduled (CSCHD)',
  DEL: 'Delivered (DEL)',
  DELVR: 'Delivered (DELVR)',
  DEPT: 'Departed (DEPT)',
  DISP: 'Dispatched (DISP)',
  DKDEL: 'Dock Delivered (DKDEL)',
  DOCK: 'Dock (DOCK)',
  INB: 'Inbound (INB)',
  INPT: 'In Port (INPT)',
  LDD: 'Loaded (LDD)',
  LDNG: 'Loading (LDNG)',
  MDEL: 'Mobile Delivered (MDEL)',
  NONMV: 'Non-Movement (NONMV)',
  OCEAN: 'Ocean (OCEAN)',
  OFDEL: 'Out for delivery (OFDEL)',
  OFTRN: 'Out for transfer (OFTRN)',
  ORG: 'Origin (ORG)',
  P_O: 'Pass Over (P_O)',
  PCKUP: 'Picked up (PCKUP)',
  PDEL: 'Presumed Delivered (PDEL)',
  PKUP: 'Picked up (PKUP)',
  PROER: 'Pro Error (PROER)',
  RECOV: 'Recovered (RECOV)',
  REPL: 'Replaced (REPL)',
  RLCUS: 'Release from customs (RLCUS)',
  SCHED: 'Scheduled (SCHED)',
  SP_IB: 'Spotted Inbound (SP_IB)',
  SPOT: 'Spotted (SPOT)',
  TRNST: 'In Transit (TRNST)',
  UNLD: 'Unloaded (UNLD)',
  VOID: 'Void (VOID)',
};

const PROJECT_44_STATUS_CODES = {
  ARRIVED: 'Arrived',
  DEPARTED: 'Departed',
  LOADING: 'Loading',
  POSITION: 'Position',
  SCANNED_AT_STOP: 'Scanned at Stop',
  UNLOADING: 'Unloading',
};

const CH_ROBINSON_STATUS_CODES = {
  A3: 'Shipment Returned to Shipper (A3)',
  A7: 'Refused by Consignee (A7)',
  A9: 'Shipment Damaged (A9)',
  AA: 'Pick-up Appointment Date and/or Time (AA)',
  AB: 'Delivery Appointment Date and/or Time (AB)',
  AC: 'Estimated Delivery Appointment Date and/or Time (AC)',
  AF: 'Carrier Departed Pick-up Location with Shipment (AF)',
  AG: 'Estimated Delivery (AG)',
  AH: 'Attempted Delivery (AH)',
  AI: 'Shipment has been Reconsigned (AI)',
  AJ: 'Tendered for Delivery (AJ)',
  AM: 'Loaded on Truck (AM)',
  AN: 'Diverted to Air Carrier (AN)',
  AP: 'Delivery Not Completed (AP)',
  AR: 'Rail Arrival at Destination Intermodal Ramp (AR)',
  AV: 'Available for Delivery (AV)',
  B6: 'Estimated to Arrive at Carrier Terminal (B6)',
  BA: 'Connecting Line or Cartage Pick-up (BA)',
  BC: 'Storage in Transit (BC)',
  C1: 'Estimated to Depart Terminal Location (C1)',
  CA: 'Shipment Cancelled (CA)',
  CD: 'Carrier Departed Delivery Location (CD)',
  CL: 'Trailer Closed Out (CL)',
  CP: 'Completed Loading at Pick-up Location (CP)',
  D1: 'Completed Unloading at Delivery Location (D1)',
  ED: 'Deliver No Earlier than Date and/or Time (ED)',
  EP: 'Pick-up No Earlier than Date and/or Time (EP)',
  I1: 'In-Gate (I1)',
  J1: 'Delivered to Connecting Line (J1)',
  K1: 'Arrived at Customs (K1)',
  L1: 'Loading (L1)',
  LD: 'Deliver No Later than Date and/or Time (LD)',
  LP: 'Pick-up No Later Than Date and/or Time (LP)',
  OA: 'Out-Gate (OA)',
  OO: 'Paperwork Received - Did not Receive Shipment or Equipment (OO)',
  P1: 'Departed Terminal Location (P1)',
  PR: 'U.S. Customs Hold at In-Bond Location (PR)',
  R1: 'Received from Prior Carrier (R1)',
  RL: 'Rail Departure from Origin Intermodal Ramp (RL)',
  S1: 'Trailer Spotted at Consignee*s Location (S1)',
  SD: 'Shipment Delayed (SD)',
  XA: 'Pick-up Appointment secured on this Date and/or Tim (XA)',
  X1: 'Arrived at Delivery Location (X1)',
  X2: 'Estimated Date and/or Time of Arrival at Consignee*s Location (X2)',
  X3: 'Arrived at Pick-up Location (X3)',
  X4: 'Arrived at Terminal Location (X4)',
  X5: 'Arrived at Delivery Location Loading Dock (X5)',
  X6: 'En Route to Delivery Location (X6)',
  X9: 'Delivery Appointment Secured on this Date and/or Time (X9)',
  X8: 'Arrived at Pick-up Location Loading Dock (X8)',
  XB: 'Shipment Acknowledged (XB)',
};

const UNISHIPPERS_STATUS_CODE_MAP = {
  CREATED: 'Created',
  PENDING_PICKUP: 'Pending Pickup',
  IN_TRANSIT: 'In Transit',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  DELIVERED: 'Delivered',
  EXCEPTION: 'Exception',
  VOID: 'Void',
};

const T_FORCE_WORLD_WIDE = {
  CARRIER_DEPARTED_PICKUP_LOCATION_WITH_SHIPMENT: 'Carrier Departed Pick-up Location with Shipment',
  SHIPMENT_RETURNED_TO_SHIPPER: 'Shipment Returned to Shipper',
  UNABLE_TO_LOCATE: 'Unable to Locate',
  REFUSED_BY_CONSIGNEE: 'Refused by Consignee',
  SHIPMENT_DAMAGED: 'Shipment Damaged',
  PICKUP_APPOINTMENT_DATE_TIME: 'Pickup Appointment Date/Time',
  DELIVERY_APPOINTMENT_DATE_TIME: 'Delivery Appointment Date/Time',
  DELIVERY_APPOINTMENT_SCHEDULED: 'Delivery Appointment Scheduled',
  ACTUAL_PICKUP: 'Actual Pickup',
  ESTIMATED_DELIVERY: 'Estimated Delivery',
  ATTEMPTED_DELIVERY: 'Attempted Delivery',
  SHIPMENT_RECONSIGNED: 'Shipment has been Reconsigned',
  TENDERED_FOR_DELIVERY: 'Tendered for Delivery',
  LOADED_ON_TRUCK: 'Loaded on Truck',
  DIVERTED_TO_AIR_CARRIER: 'Diverted to Air Carrier',
  DELIVERY_NOT_COMPLETED: 'Delivery Not Completed',
  RAIL_ARRIVAL_AT_DESTINATION_INTERMODAL_RAMP: 'Rail Arrival at Destination Intermodal Ramp',
  AVAILABLE_FOR_DELIVERY: 'Available for Delivery',
  PAST_CUT_OFF_TIME: 'Past Cut-off Time',
  DAMAGED: 'Damaged',
  TRAP_FOR_CUSTOMER: 'Trap for Customer',
  ESTIMATED_TO_ARRIVE_AT_CARRIER_TERMINAL: 'Estimated to Arrive at Carrier Terminal',
  CONNECTING_LINE_OR_CARTAGE_PICKUP: 'Connecting Line or Cartage Pick-up',
  STORAGE_IN_TRANSIT: 'Storage in Transit',
  GENERAL_INFORMATION_UPDATE: 'General Information Update',
  INSUFFICIENT_TIME_TO_COMPLETE_DELIVERY: 'Insufficient Time to Complete Delivery',
  CARTAGE_AGENT: 'Cartage Agent',
  REFUSED_BY_CUSTOMER: 'Refused by Customer',
  ESTIMATED_TO_DEPART_TERMINAL_LOCATION: 'Estimated to Depart Terminal Location',
  SHIPMENT_CANCELLED: 'Shipment Cancelled',
  CARRIER_DEPARTED_DELIVERY_LOCATION: 'Carrier Departed Delivery Location',
  TRAILER_CLOSED_OUT: 'Trailer Closed Out',
  COMPLETED_LOADING_AT_PICKUP_LOCATION: 'Completed Loading at Pick-up Location',
  COMPLETED_UNLOADING_AT_DELIVERY_LOCATION: 'Completed Unloading at Delivery Location',
  COMPLETED_UNLOADING: 'Completed Unloading at Delivery Location',
  DISPATCHED: 'Dispatched',
  ESTIMATED_TO_ARRIVE_EN_ROUTE: 'Estimated to Arrive (En Route)',
  HELD_REFUSED: 'Held - Refused',
  HELD_CONSIGNEE_CLOSED: 'Held - Consignee Closed',
  HELD_REFUSED_SHIPMENT: 'Held - Refused Shipment',
  IN_GATE: 'In-Gate',
  DELIVERED_TO_CONNECTING_LINE: 'Delivered to Connecting Line',
  ARRIVED_AT_CUSTOMS: 'Arrived at Customs',
  CUSTOMS: 'Customs',
  LOADING: 'Loading',
  NORMAL_STATUS: 'Normal Status',
  OUT_GATE: 'Out-Gate',
  PAPERWORK_RECEIVED: 'Paperwork Received',
  DEPARTURE_FROM_LOCATION: 'Departure From Location',
  DEPARTED_TERMINAL_LOCATION: 'Departed Terminal Location',
  US_CUSTOMS_HOLD_AT_INBOUND_LOCATION: 'U.S. Customs Hold at In-Bound Location',
  RECEIVED_FROM_PRIOR_CARRIER: 'Received from Prior Carrier',
  DELIVERY_APPOINTMENT_REQUESTED: 'Delivery Appointment Requested',
  RAIL_DEPARTURE_FROM_ORIGIN_INTERMODAL_RAMP: 'Rail Departure from Origin Intermodal Ramp',
  TRAILER_SPOTTED_AT_CONSIGNEE: 'Trailer Spotted at Consignee',
  SHIPMENT_DELAYED: 'Shipment Delayed',
  AT_TERMINAL_INTRA_TERMINAL_MOVEMENT: 'At Terminal; Intra-Terminal Movement',
  TRAILER_REFERENCE: 'Trailer Reference',
  WEATHER_PREVENTED_MOVEMENT: 'Weather Prevented Movement',
  ARRIVED_AT_DELIVERY_LOCATION: 'Arrived at Delivery Location',
  ESTIMATED_DATE_AND_OR_TIME_OF_ARRIVAL_AT_CONSIGNEES_LOCATION: 'Estimated Date and/or Time of Arrival at Consignees Location',
  ARRIVED_AT_PICKUP_LOCATION: 'Arrived at Pick-up Location',
  ARRIVED_AT_TERMINAL_LOCATION: 'Arrived at Terminal Location',
  EN_ROUTE_TO_DELIVERY_LOCATION: 'En Route to Delivery Location',
  ARRIVED_AT_PICKUP_LOCATION_LOADING_DOCK: 'Arrived at Pick-up Location Loading Dock',
  SHIPMENT_ACKNOWLEDGED: 'Shipment Acknowledged',
  INTRANSIT_DELAYED: 'Intransit Delayed',
  SHIPMENT_BOOKED: 'Shipment Booked',
  VESSEL_ARRIVED_DESTINATION_PORT: 'Vessel Arrived Destination Port',
  VESSEL_DEPARTED_ORIGIN_PORT: 'Vessel Departed Origin Port',
  RECOVERED_AT_DESTINATION: 'Recovered at Destination',
  INTRANSIT_ON_SCHEDULE: 'Intransit On Schedule',
  DELIVERED_COMPLETE: 'Delivered Complete',
  CONFIRMED_ON_BOARD: 'Confirmed on Board',
  ON_HAND_DESTINATION_DOCK: 'On Hand Destination Dock',
  ON_HAND_ORIGIN_DOCK: 'On Hand Origin Dock',
  OUT_FOR_DELIVERY: 'Out for delivery',
  PICKUP_APPOINTMENT_SCHEDULED: 'Pickup Appointment Scheduled',
  TFWW_TO_PAYCORE: 'TFWW to Paycore',
};

export const statusCodeOptionsMap = {
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_RIST]: RIST_STATUS_CODES,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_SAIA]: SAIA_STATUS_CODES,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_UPS]: UPS_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_GLS]: GLS_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_YRC]: YRC_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_SMTL]: SMTL_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_SEFL]: SEFL_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_WARD]: WARD_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_ODFL]: ODFL_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_FEDEX]: FEDEX_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_ARC_BEST]: ARC_BEST_STATUS_CODES,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_POLARIS]: POLARIS_STATUS_CODE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS]: UNISHIPPERS_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_NEW_PENN]: NEW_PENN_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_PROJECT_44]: PROJECT_44_STATUS_CODES,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_CH_ROBINSON]: CH_ROBINSON_STATUS_CODES,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_MANITOULIN]: MANITOULIN_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_AAA_COOPER]: AAA_COOPER_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_A_DUIE_PYLE]: DUIE_PYLE_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_ROADRUNNER]: ROADRUNNER_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_FOUR_KITES]: FOUR_KITES_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_T_FORCE_WORLD_WIDE]: T_FORCE_WORLD_WIDE,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_MACRO_POINT]: MACRO_POINT_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_DAY_AND_ROSS]: DAY_AND_ROSS_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_XPO_LOGISTICS]: XPO_LOGISTICS_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_TRUCKER_TOOLS]: TRUCKER_TOOLS_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_DAYTON_FREIGHT]: DAYTON_FREIGHT_STATUS_CODE_MAP,
  [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_HOLLAND_AND_REDDAWAY]: HOLLAND_AND_REDDAWAY_STATUS_CODE_MAP,
};

const FEDEX_SERVICE_TYPE_MAP = {
  EUROPE_FIRST_INTERNATIONAL_PRIORITY: 'Europe First International Priority',
  FEDEX_1_DAY_FREIGHT: 'Fedex 1 Day Freight',
  FEDEX_2_DAY: 'Fedex 2 Day',
  FEDEX_2_DAY_AM: 'Fedex 2 Day AM',
  FEDEX_2_DAY_FREIGHT: 'Fedex 2 Day Freight',
  FEDEX_3_DAY_FREIGHT: 'Fedex 3 Day Freight',
  FEDEX_CARGO_AIRPORT_TO_AIRPORT: 'Fedex Cargo Airport To Airport',
  FEDEX_CARGO_FREIGHT_FORWARDING: 'Fedex Cargo Freight Forwarding',
  FEDEX_CARGO_INTERNATIONAL_EXPRESS_FREIGHT: 'Fedex Cargo International Express Freight',
  FEDEX_CARGO_INTERNATIONAL_PREMIUM: 'Fedex Cargo International Premium',
  FEDEX_CARGO_MAIL: 'Fedex Cargo Mail',
  FEDEX_CARGO_REGISTERED_MAIL: 'Fedex Cargo Registered Mail',
  FEDEX_CARGO_SURFACE_MAIL: 'Fedex Cargo Surface Mail',
  FEDEX_CUSTOM_CRITICAL_AIR_EXPEDITE: 'Fedex Custom Critical Air Expedite',
  FEDEX_CUSTOM_CRITICAL_AIR_EXPEDITE_EXCLUSIVE_USE: 'Fedex Custom Critical Air Expedite Exclusive Use',
  FEDEX_CUSTOM_CRITICAL_AIR_EXPEDITE_NETWORK: 'Fedex Custom Critical Air Expedite Network',
  FEDEX_CUSTOM_CRITICAL_CHARTER_AIR: 'Fedex Custom Critical Charter Air',
  FEDEX_CUSTOM_CRITICAL_POINT_TO_POINT: 'Fedex Custom Critical Point To Point',
  FEDEX_CUSTOM_CRITICAL_SURFACE_EXPEDITE: 'Fedex Custom Critical Surface Expedite',
  FEDEX_CUSTOM_CRITICAL_SURFACE_EXPEDITE_EXCLUSIVE_USE: 'Fedex Custom Critical Surface Expedite Exclusive Use',
  FEDEX_CUSTOM_CRITICAL_TEMP_ASSURE_AIR: 'Fedex Custom Critical Temp Assure Air',
  FEDEX_CUSTOM_CRITICAL_TEMP_ASSURE_VALIDATED_AIR: 'Fedex Custom Critical Temp Assure Validated Air',
  FEDEX_CUSTOM_CRITICAL_WHITE_GLOVE_SERVICES: 'Fedex Custom Critical White Glove Services',
  FEDEX_DISTANCE_DEFERRED: 'Fedex Distance Deferred',
  FEDEX_EXPRESS_SAVER: 'Fedex Express Saver',
  FEDEX_FIRST_FREIGHT: 'Fedex First Freight',
  FEDEX_FREIGHT_ECONOMY: 'Fedex Freight Economy',
  FEDEX_FREIGHT_PRIORITY: 'Fedex Freight Priority',
  FEDEX_GROUND: 'Fedex Ground',
  FEDEX_INTERNATIONAL_CONNECT_PLUS: 'Fedex International Connect Plus',
  FEDEX_INTERNATIONAL_PRIORITY: 'Fedex International Priority',
  FEDEX_INTERNATIONAL_PRIORITY_EXPRESS: 'Fedex International Priority Express',
  FEDEX_INTERNATIONAL_PRIORITY_PLUS: 'Fedex International Priority Plus',
  FEDEX_NEXT_DAY_AFTERNOON: 'Fedex Next Day Afternoon',
  FEDEX_NEXT_DAY_EARLY_MORNING: 'Fedex Next Day Early Morning',
  FEDEX_NEXT_DAY_END_OF_DAY: 'Fedex Next Day End Of Day',
  FEDEX_NEXT_DAY_FREIGHT: 'Fedex Next Day Freight',
  FEDEX_NEXT_DAY_MID_MORNING: 'Fedex Next Day Mid Morning',
  FEDEX_REGIONAL_ECONOMY: 'Fedex Regional Economy',
  FEDEX_REGIONAL_ECONOMY_FREIGHT: 'Fedex Regional Economy Freight',
  FIRST_OVERNIGHT: 'First Overnight',
  GROUND_HOME_DELIVERY: 'Ground Home Delivery',
  INTERNATIONAL_DISTRIBUTION_FREIGHT: 'International Distribution Freight',
  INTERNATIONAL_ECONOMY: 'International Economy',
  INTERNATIONAL_ECONOMY_DISTRIBUTION: 'International Economy Distribution',
  INTERNATIONAL_ECONOMY_FREIGHT: 'International Economy Freight',
  INTERNATIONAL_FIRST: 'International First',
  INTERNATIONAL_GROUND: 'International Ground',
  INTERNATIONAL_PRIORITY: 'International Priority',
  INTERNATIONAL_PRIORITY_DISTRIBUTION: 'International Priority Distribution',
  INTERNATIONAL_PRIORITY_FREIGHT: 'International Priority Freight',
  PRIORITY_OVERNIGHT: 'Priority Overnight',
  SAME_DAY: 'Same Day',
  SAME_DAY_CITY: 'Same Day City',
  SAME_DAY_METRO_AFTERNOON: 'Same Day Metro Afternoon',
  SAME_DAY_METRO_MORNING: 'Same Day Metro Morning',
  SAME_DAY_METRO_RUSH: 'Same Day Metro Rush',
  SMART_POST: 'Smart Post',
  STANDARD_OVERNIGHT: 'Standard Overnight',
  TRANSBORDER_DISTRIBUTION_CONSOLIDATION: 'Transborder Distribution Consolidation',
};

const DAYTON_FREIGHT_SERVICE_TYPE_MAP = {
  NONE: 'NONE',
  GUARANTEED_AM: 'Guaranteed AM',
  GUARANTEED_PM: 'Guaranteed PM',
};

const HOLLAND_AND_REDDAWAY_SERVICE_TYPE_MAP = {
  GUARANTEED_9_AM: 'Guaranteed 9 AM',
  GUARANTEED_AM: 'Guaranteed AM',
  GUARANTEED_DAY: 'Guaranteed Day',
  GUARANTEED_FRIDAY_MONDAY: 'Guaranteed Friday Monday',
  GUARANTEED_PM: 'Guaranteed PM',
  INTERLINE: 'Interline',
  INTER_REGIONAL: 'Inter Regional',
  MULTI_HOUR_WINDOW: 'Multi Hour Window',
  REGIONAL: 'Regional',
  SINGLE_HOUR_WINDOW: 'Single Hour Window',
};

const XPO_LOGISTICS_SERVICE_TYPE_MAP = {
  NONE: 'None',
  GUARANTEED_AM: 'Guaranteed AM',
};

const WARD_SERVICE_TYPE_MAP = {
  GUARANTEED_AM: 'Guaranteed AM',
  GUARANTEED_PM: 'Guaranteed PM',
};

const YRC_SERVICE_TYPE_MAP = {
  ACCELERATED: 'Accelerated',
  FAST_AS_FLIGHT: 'Fast as Flight',
  GUARANTEED: 'Guaranteed',
  GUARANTEED_AM: 'Guaranteed AM',
  GUARANTEED_PM: 'Guaranteed PM',
  MULTI_DAY_WINDOW: 'Multi Day Window',
  MULTI_HOUR_WINDOW: 'Multi Hour Window',
  QUOTE_ID: 'Quote Id',
  SPOT_VOLUME: 'Spot Volume',
  STANDARD: 'Standard',
  TIME_CRITICAL: 'Time Critical',
  TIME_CRITICAL_FASTEST_GROUND: 'Time Critical Fastest Ground',
};

const AAA_COOPER_SERVICE_TYPE_MAP = {
  GUARANTEED_DAY: 'Guaranteed Day',
};

const SAIA_SERVICE_TYPE_MAP = {
  GUARANTEED_AM: 'Guaranteed AM',
  GUARANTEED_PM: 'Guaranteed PM',
};

const MANITOULIN_SERVICE_TYPE_MAP = {
  NONE: 'None',
  GUARANTEED_AM: 'Guaranteed AM',
  GUARANTEED_PM: 'Guaranteed PM',
};

const GLS_SERVICE_TYPE_MAP = {
  NEG: 'Neg',
  GEN: 'Gen',
  VOL: 'Vol',
  NEGGEN: 'Neggen',
};

const UPS_SERVICE_TYPE_MAP = {
  NEXT_DAY_AIR: 'Next Day Air',
  SECOND_DAY_AIR: '2nd Day Air',
  GROUND: 'Ground',
  THREE_DAY_SELECT: 'Day Select',
  NEXT_DAY_AIR_SAVER: 'Next Day Air Saver',
  UPS_NEXT_DAY_AIR_EARLY: 'UPS Next Day Air Early',
  SECOND_DAY_AIR_A_M: '2ndDay Air A.M.',
  WORLDWIDE_EXPRESS: 'Worldwide Express',
  WORLDWIDE_EXPEDITED: 'Worldwide Expedited',
  STANDARD: 'Standard',
  WORLDWIDE_EXPRESS_PLUS: 'Worldwide Express Plus',
  SAVER: 'Saver',
  UPS_WORLDWIDE_EXPRESS_FREIGHT_MIDDAY: 'UPS Worldwide Express Freight Midday',
  UPS_WORLDWIDE_EXPRESS_FREIGHT: 'UPS Worldwide Express Freight',
};

const UNISHIPPERS_SERVICE_TYPE_MAP = {
  STANDARD: 'Standard',
  GUARANTEED: 'Guaranteed',
};

export const serviceTypeOptionsMap = {
  [GC.CARRIER_RATE_INTEGRATION_TYPE_YRC]: YRC_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_UPS]: UPS_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_GLS]: GLS_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_SAIA]: SAIA_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_WARD]: WARD_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_FEDEX]: FEDEX_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_AAA_COOPER]: AAA_COOPER_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN]: MANITOULIN_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS]: UNISHIPPERS_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_XPO_LOGISTICS]: XPO_LOGISTICS_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_HOLLAND]: HOLLAND_AND_REDDAWAY_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_REDDAWAY]: HOLLAND_AND_REDDAWAY_SERVICE_TYPE_MAP,
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAYTON_FREIGHT]: DAYTON_FREIGHT_SERVICE_TYPE_MAP,
};

export const labelTypeKeysMap = {
  NONE: 'None',
  AVERY_5164: 'Avery 5164',
  AVERY_4161: 'Avery 4161',
  AVERY_5168: 'Avery 5168',
  AVERY_6573: 'Avery 6573',
  STOCK_4_X_8: 'Stock 4x8',
  STOCK_4_X_9: 'Stock 4x9',
  PAPER_4_X_8: 'Papper 4x8',
  PAPER_4_X_9: 'Papper 4x9',
  STOCK_4_X_6_75: 'Stock 4x6.75',
  PAPER_4_X_6_75: 'Papper 4x6.75',
  PAPER_7_X_4_75: 'Papper 7x4.75',
  PAPER_8_5_X_11: 'Papper 8.5x11',
  STANDARD_LAYOUT_1X2: 'Standard Layout 1x2',
  STANDARD_LAYOUT_4X5: 'Standard Layout 4x5',
  STANDARD_LAYOUT_4X6: 'Standard Layout 4x6',
  STANDARD_LAYOUT_8X11: 'Standard Layout 8x11',
  STANDARD_LAYOUT_LETTER: 'Standard Layout Letter',
  STANDARD_LAYOUT_4X5_PEEL: 'Standard Layout 4x5 Pell',
  STANDARD_LAYOUT_AVERY_5264: 'Standard Layout Avery 5264',
  STOCK_4_X_9_LEADING_DOC_TAB: 'Stock 4x9 Leading Doc Tab',
  STOCK_4_X_9_TRAILING_DOC_TAB: 'Stock 4x9 Trailing Doc Tab',
  PAPER_8_5_X_11_TOP_HALF_LABEL: 'Papper 8.5x11 Top Half Label',
  STOCK_4_X_6_75_LEADING_DOC_TAB: 'Stock 4x6.75 Leading Doc Tab',
  STOCK_4_X_6_75_TRAILING_DOC_TAB: 'Stock 4x6.75 Trailing Doc Tab',
  PAPER_8_5_X_11_BOTTOM_HALF_LABEL: 'Papper 8.5x11 Botttom Half Label',
};

export const groupedByIntegrationTypeLabelTypeMap = {
  [GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN]: R.pick(['NONE', 'AVERY_6573'], labelTypeKeysMap),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_NEW_PENN]: R.pick(['NONE', 'STANDARD_LAYOUT_4X6'], labelTypeKeysMap),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_XPO_LOGISTICS]: R.pick(['NONE', 'STANDARD_LAYOUT_4X6'], labelTypeKeysMap),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAY_AND_ROSS]: R.pick(['NONE', 'STANDARD_LAYOUT_LETTER'], labelTypeKeysMap),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ARC_BEST]: R.pick(
    ['NONE', 'STANDARD_LAYOUT_4X6', 'STANDARD_LAYOUT_LETTER', 'STANDARD_LAYOUT_1X2'],
    labelTypeKeysMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ODFL]: R.pick(
    ['NONE', 'STANDARD_LAYOUT_4X6', 'STANDARD_LAYOUT_8X11', 'AVERY_5168', 'AVERY_5164'],
    labelTypeKeysMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_GLS]: R.pick(
    ['NONE', 'STANDARD_LAYOUT_4X5', 'STANDARD_LAYOUT_4X5_PEEL', 'PAPER_8_5_X_11'],
    labelTypeKeysMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ROADRUNNER]: R.pick(
    ['NONE', 'STANDARD_LAYOUT_4X6', 'STANDARD_LAYOUT_LETTER'],
    labelTypeKeysMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_YRC]: R.pick(
    ['NONE', 'STANDARD_LAYOUT_4X6', 'AVERY_4161', 'AVERY_5164', 'AVERY_5168'],
    labelTypeKeysMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAYTON_FREIGHT]: R.pick(
    [
      'NONE',
      'STANDARD_LAYOUT_1X2',
      'STANDARD_LAYOUT_4X6',
      'STANDARD_LAYOUT_LETTER',
      'STANDARD_LAYOUT_AVERY_5264',
    ],
    labelTypeKeysMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_FEDEX]: R.pick(
    [
      'NONE',
      'PAPER_4_X_8',
      'PAPER_4_X_9',
      'STOCK_4_X_8',
      'STOCK_4_X_9',
      'PAPER_4_X_6_75',
      'STOCK_4_X_6_75',
      'PAPER_7_X_4_75',
      'STANDARD_LAYOUT_4X6',
      'STANDARD_LAYOUT_LETTER',
      'STOCK_4_X_9_LEADING_DOC_TAB',
      'STOCK_4_X_9_TRAILING_DOC_TAB',
      'PAPER_8_5_X_11_TOP_HALF_LABEL',
      'STOCK_4_X_6_75_LEADING_DOC_TAB',
      'STOCK_4_X_6_75_TRAILING_DOC_TAB',
      'PAPER_8_5_X_11_BOTTOM_HALF_LABEL',
    ],
    labelTypeKeysMap,
  ),
};

const HUB_TRAN_DOCUMENT_TYPES = {
  BILL_OF_LADING: 'Bill of Lading / Packing Slip',
  HIDDEN_BILL_OF_LADING: 'Hidden Bill of Lading',
  PROOF_OF_DELIVERY: 'Delivery Receipt',
  INVOICE: 'Carrier Invoice / Billing Statement',
  CARRIER_INVOICE: 'Carrier Invoice for Broker Clients',
  CARRIER_RATE_CONFIRMATION: 'Carrier Rate Confirmation for Broker Clients',
  CUSTOMER_INVOICE: 'Customer Invoice',
  CUSTOMER_TENDER: 'Customer Rate Confirmation',
  DELIVERY_ORDER: 'Delivery Order',
  FACTOR_INVOICE: 'Factor Invoice / Billing Statement',
  FUNDING_FORM: 'Funding Request Form',
  LETTER_OF_ASSIGNMENT: 'Letter Of Assignment',
  LABOR_RECEIPT: 'Lumper / Other Accessorial Receipt',
  NMFC_CERTIFICATE: 'NMFC / W & I Certificate',
  NOTICE_OF_ASSIGNMENT: 'Notice Of Assignment',
  NOTICE_OF_RELEASE: 'Notice Of Release',
  CUSTOMER_OTHER: 'Customer Documents - Other',
  OTHER: 'Internal Documents - Other',
  POSTAL: 'Postal',
  PACKING_SLIP: 'Packing Slip',
  RATE_CONFIRMATION: 'Rate Confirmation',
  LOAD_TENDER: 'Rate Confirmation',
  TIR: 'Trailer Interchange Report',
  VOIDED_CHECK: 'Voided Check',
  WEIGHT_TICKET: 'Weight Ticket / Gate Pass',
  ADVANCE_REQUEST: 'Advance Request',
  DRIVER_FORM: 'Driver Form',
  ESCORT_RECEIPT: 'Escort Receipt',
  FERRY_RECEIPT: 'Ferry Receipt',
  RUN_TICKET: 'Run Ticket',
  STEAMING_RECEIPT: 'Steaming Receipt',
  TOLL_RECEIPT: 'Toll Receipt',
  EXIT_PASS: 'Exit Pass',
  WASH_RECEIPT: 'Wash Receipt',
  DETENTION_APPROVAL: 'Detention Approval',
  EMAIL_APPROVAL: 'Email Approval',
  LAYOVER_APPROVAL: 'Layover Approval',
  LUMPER_APPROVAL: 'Lumper Approval',
  TONU_APPROVAL: 'TONU Approval',
  MX_BOL: 'MX BOL',
  VENDOR_INVOICE: 'Vendor Invoice',
  FINE: 'Fine',
  W_9: 'W9',
  AUTHORITY: 'Authority',
  BALANCE_DUE: 'Balance Due',
  CLAIM: 'Claim',
  COMCHECK: 'Comcheck',
  CUSTOMS_DOCUMENT: 'Customs Document',
  ACH_SETUP_FORM: 'ACH Setup Form',
  CONTRACT: 'Contract',
  INSURANCE_CERTIFICATE: 'Insurance Certificate',
  QUOTE_DOC: 'Quote Doc',
  EXPORT_DOC: 'Export Doc',
  MASTER_BILL_OF_LADING: 'Master Bill of Lading',
  HOUSE_BILL_OF_LADING: 'House Bill of Lading',
  WAYBILL: 'Waybill',
  COMMERCIAL_INVOICE: 'Commercial Invoice',
  PACKING_LIST: 'Packing List',
  CERTIFICATE_OF_ORIGIN: 'Certificate of Origin',
  INSPECTION_CERTIFICATE: 'Inspection Certificate',
  WEIGHT_TICKET_UNLOADED: 'Weight Ticket (Unloaded)',
  PALLET_RECEIPT: 'Pallet Receipt',
  FORM_1113: 'Form 1113',
  GOVERNMENT_BILL_OF_LADING: 'Government Bill Of Lading',
  INTERNATIONAL_BILL_OF_LADING: 'International Bill Of Lading',
  INTERNAL_PROOF_OF_DELIVERY: 'Internal Proof Of Delivery',
  PURCHASE_ORDER: 'Purchase Order',
  LETTER_OF_CREDIT: 'Letter of Credit',
  PRE_ALERT: 'Pre-Alert',
  SHIPPERS_LETTER_OF_INSTRUCTION: 'Shipper*s Letter of Instruction',
  MANIFEST: 'Manifest',
  HOUSE_AIRWAY_BILL: 'House Airway Bill',
  MASTER_AIRWAY_BILL: 'Master Airway Bill',
  HOUSE_OCEAN_BILL_OF_LADING: 'House Ocean Bill of Lading',
  MASTER_OCEAN_BILL_OF_LADING: 'Master Ocean Bill of Lading',
  TRUCK_BILL_OF_LADING: 'Truck Bill of Lading',
  BOOKING_REQUEST: 'Booking Request',
  ISF_FILING_INSTRUCTION: 'ISF Filing Instruction',
  PROFORMA_INVOICE: 'Proforma Invoice',
  CUSTOMS_CLEARANCE_REQUEST: 'Customs Clearance Request',
  CUSTOMS_ENTRY_SUMMARY: 'Customs Entry Summary',
  INTERMODAL_IN_TRANSIT_NOTICE: 'Intermodal In-Transit Notice',
  FREETIME_NOTIFICATION: 'Freetime Notification',
  ARRIVAL_NOTICE: 'Arrival Notice',
  EXPORT_DECLARATION: 'Export Declaration',
  AGENT_INVOICE: 'Agent Invoice',
  CUSTOMER_RELEASE: 'Customer Release',
  CUSTOMS_POWER_OF_ATTORNEY: 'Customs Power of Attorney',
  TRACKING_STATUS: 'Tracking Status',
  DECLARATION_CERTIFICATE: 'Declaration/Certificate',
  DANGEROUS_GOODS_DECLARATION: 'Dangerous Goods Declaration',
  FREIGHT_LABEL: 'Freight Label',
  BOOKING_CONFIRMATION: 'Booking Confirmation',
  CLAIM_NOTICE: 'Claim Notice',
  CORRECTION_NOTICE: 'Correction Notice',
  CUSTOMS_AUTHORITY: 'Customs Authority',
  EXPORT_AUTHORITY: 'Export Authority',
  AUTHORIZATION_RELEASE: 'Authorization Release',
  VETERINARY_CERTIFICATE: 'Veterinary Certificate',
  SANITARY_CERTIFICATE: 'Sanitary Certificate',
  MOTOR_VEHICLE_CERTIFICATE: 'Motor Vehicle Certificate',
  FUMIGATION_CERTIFICATE: 'Fumigation Certificate',
  SHIPPERS_SECURITY_DECLARATION: 'Shipper*s Security Declaration',
  NAFTA_CERTIFICATE: 'NAFTA Certificate',
  CARTAGE_ADVICE_WITH_RECEIPT: 'Cartage Advice with Receipt',
  US_CUSTOMS_DOCUMENT: 'US Customs Document',
  WEIGHT_MEASUREMENT_REPORT: 'Weight/Measurement Report',
  GATE_PASS: 'Gate Pass',
  PERMIT_TO_TRANSFER: 'Permit To Transfer',
  PICKUP_NUMBER: 'Pickup Number',
  TELEX_RELEASE: 'Telex Release',
  ASBESTOS_DECLARATION: 'Asbestos Declaration',
  AUS_FTA: 'AUSFTA',
  MSDS: 'MSDS',
  SOLAS_FORM: 'SOLAS Form',
  PACKING_DECLARATION: 'Packing Declaration',
  CONSIGNMENT_SECURITY_DECLARATION: 'Consignment Security Declaration',
  B3_CANADIAN_CUSTOMS_ENTRY: 'B3 - Canadian Customs Entry',
  VGM_CONTAINER_INFORMATION: 'VGM/Container Information',
  PARTIAL_OCEAN_BILL_OF_LADING: 'Ocean Bill of Lading (House or Master)',
  PARTIAL_AIRWAY_BILL_OF_LADING: 'Airway Bill of Lading (House or Master)',
  PARTIAL_BILL_OF_LADING: 'Bill of Lading (House or Master)',
  UNKNOWN: 'Unknown',
};

const SAIA_DOCUMENT_TYPE_MAPPING_OPTIONS = {
  BOL: 'Bill of Landing',
  POD: 'Proof of Delivery',
  WGT: 'Other (WGT)',
};

export const EXTERNAL_DOCUMENT_TYPE_MAP = {
  [GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN]: HUB_TRAN_DOCUMENT_TYPES,
  [GC.DOCUMENT_TYPE_MAPPING_TYPE_SAIA]: SAIA_DOCUMENT_TYPE_MAPPING_OPTIONS,
};

export const paymentTermsMap = {
  [GC.PAYMENT_TERM_OPTION_PREPAID]: G.getWindowLocale('titles:prepaid', 'Prepaid'),
  [GC.PAYMENT_TERM_OPTION_COLLECT]: G.getWindowLocale('titles:collect', 'Collect'),
  [GC.PAYMENT_TERM_OPTION_THIRD_PARTY]: G.getWindowLocale('titles:third-party', 'Third Party'),
  [GC.PAYMENT_TERM_OPTION_OUTBOUND_COLLECT]: G.getWindowLocale('titles:outbound-collect', 'Outbound Collect'),
  [GC.PAYMENT_TERM_OPTION_THIRD_PARTY_PREPAID]: G.getWindowLocale('titles:third-party-prepaid', 'Third Party Prepaid'),
  [GC.PAYMENT_TERM_OPTION_THIRD_PARTY_COLLECT]: G.getWindowLocale('titles:third-party-collect', 'Third Party Collect'),
};

export const groupedByIntegrationTypePaymentTermsMap = {
  default: R.pick([GC.PAYMENT_TERM_OPTION_PREPAID, GC.PAYMENT_TERM_OPTION_COLLECT], paymentTermsMap),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_GLS]: R.pick(
    [
      GC.PAYMENT_TERM_OPTION_PREPAID,
      GC.PAYMENT_TERM_OPTION_COLLECT,
      GC.PAYMENT_TERM_OPTION_THIRD_PARTY,
    ],
    paymentTermsMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAY_AND_ROSS]: R.pick(
    [
      GC.PAYMENT_TERM_OPTION_PREPAID,
      GC.PAYMENT_TERM_OPTION_COLLECT,
      GC.PAYMENT_TERM_OPTION_THIRD_PARTY,
    ],
    paymentTermsMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAYTON_FREIGHT]: R.pick(
    [
      GC.PAYMENT_TERM_OPTION_PREPAID,
      GC.PAYMENT_TERM_OPTION_COLLECT,
      GC.PAYMENT_TERM_OPTION_THIRD_PARTY_PREPAID,
      GC.PAYMENT_TERM_OPTION_THIRD_PARTY_COLLECT,
    ],
    paymentTermsMap,
  ),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_NEW_PENN]: R.pick(
    [
      GC.PAYMENT_TERM_OPTION_PREPAID,
      GC.PAYMENT_TERM_OPTION_COLLECT,
      GC.PAYMENT_TERM_OPTION_THIRD_PARTY,
      GC.PAYMENT_TERM_OPTION_OUTBOUND_COLLECT,
    ],
    paymentTermsMap,
  ),
};

export const groupedByIntegrationTypeBolPaymentTermsMap = {
  default: R.pick([GC.PAYMENT_TERM_OPTION_PREPAID, GC.PAYMENT_TERM_OPTION_COLLECT], paymentTermsMap),
  [GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN]: R.pick(
    [GC.PAYMENT_TERM_OPTION_PREPAID, GC.PAYMENT_TERM_OPTION_COLLECT, GC.PAYMENT_TERM_OPTION_THIRD_PARTY],
    paymentTermsMap,
  ),
};

export const bolPaymentTermOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: GC.PAYMENT_TERM_OPTION_PREPAID,
    label: G.getWindowLocale('titles:prepaid', 'Prepaid'),
  },
  {
    value: GC.PAYMENT_TERM_OPTION_COLLECT,
    label: G.getWindowLocale('titles:collect', 'Collect'),
  },
];

const RMIS_EQUIPMENTS = {
  CH: 'Chassis',
  CN: 'Container',
  CV: 'Cargo Van',
  DD: 'Double-Drop Trailer',
  FT: 'Flat Bed Trailer',
  G1: 'VAN',
  G2: 'SMALL STRAIGHT',
  G3: 'LARGE STRAIGHT',
  G4: 'TRACTOR',
  HV: 'High Cube Van',
  LU: 'Load/unload Device on Equipment',
  OV: 'Open Top Van',
  RD: 'Fixed-Rack, Double Drop Trailer',
  RA: 'Fixed-Rack, Flat-bed Trailer',
  RS: 'Fixed-Rack, Single Drop Trailer',
  RT: 'Controlled Temperature Trailer (Reefer)',
  SD: 'Single-Drop Trailer',
  TB: 'Trailer, Bulk',
  ST: 'Removeable Side Trailer',
  TA: 'Trailer, Heater/Insulated/Vented',
  TF: 'Trailer, Dry Freight',
  TG: 'Trailer, Tank (Gas)',
  TI: 'Trailer, Insulated',
  TJ: 'Trailer, Tank (Chemicals)',
  TK: 'Trailer, Tank (Food Grade-Liquid)',
  TL: 'Trailer (not otherwise specified)',
  TM: 'Trailer, Insulated/Ventilated',
  TP: 'Trailer, Pneumatic',
  TQ: 'Trailer, Electric Heat',
  TV: 'Truck, Van',
  TW: 'Trailer, Refrigerated',
};

export const equipmentOptionsMap = {
  [GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS]: RMIS_EQUIPMENTS,
};

// customer edi pickup/drop stop types map
export const CUSTOMER_EDI_PICKUP_DROP_STOP_TYPES_MAP = {
  LD: 'Load',
  UL: 'Unload',
  CL: 'Complete',
  PL: 'Part Load',
  PU: 'Part Unload',
  CU: 'Complete Unload',
  PA: 'Pick-up Pre-loaded Equipment',
  DR: 'Deramp and Ramp for Subsequent Loading',
};

export const TIME_ZONE_TYPES_MAP = {
  LT: 'Local Time (LT)',
  AD: 'Alaska Daylight Time (AD)',
  AS: 'Alaska Standard Time (AS)',
  AT: 'Alaska Time (AT)',
  CD: 'Central Daylight Time (CD)',
  CS: 'Central Standard Time (CS)',
  CT: 'Central Time (CT)',
  ED: 'Eastern Daylight Time (ED)',
  ES: 'Eastern Standard Time (ES)',
  ET: 'Eastern Time (ET)',
  GM: 'Greenwich Mean Time (GM)',
  HD: 'Hawaii-Aleutian Daylight Time (HD)',
  HS: 'Hawaii-Aleutian Standard Time (HS)',
  HT: 'Hawaii-Aleutian Time (HT)',
  MD: 'Mountain Daylight Time (MD)',
  MS: 'Mountain Standard Time (MS)',
  MT: 'Mountain Time (MT)',
  ND: 'Newfoundland Daylight Time (ND)',
  NS: 'Newfoundland Standard Time (NS)',
  NT: 'Newfoundland Time (NT)',
  PD: 'Pacific Daylight Time (PD)',
  PS: 'Pacific Standard Time (PS)',
  PT: 'Pacific TimeTD, //Atlantic Daylight Time (PT)',
  TS: 'Atlantic Standard Time (TS)',
  TT: 'Atlantic Time (TT)',
  UT: 'Universal Time Coordinate (TT)',
};

const BORDER_CONNECT_TRUCK_TYPE_MAP = {
  ARMORED_TRUCK: 'Armored Truck',
  AUTOMOBILE: 'Automobile',
  BOX_TRUCK: 'Box Truck',
  BUS: 'Bus',
  BEVERAGE_TRUCK: 'Beverage Truck',
  BICYCLE: 'Bicycle',
  CONSTRUCTION_VEHICLE: 'Construction Vehicle',
  EMERGENCY_VEHICLE: 'Emergency Vehicle',
  FERRY: 'Ferry',
  FARM_TRACTOR: 'Farm Tractor',
  GARBAGE_TRUCK: 'Garbage Truck',
  MOTORCYCLE: 'Motorcycle',
  OTHER: 'Other',
  PICK_UP_TRUCK_WITH_CAMPER: 'Pick Up Truck With Camper',
  PANEL_TRUCK: 'Panel Truck',
  PICK_UP_TRUCK: 'Pick Up Truck',
  PASSENGER_VAN: 'Passenger Van',
  RECREATION_VEHICLE: 'Recreation Vehicle',
  SEMI_TRACTOR: 'Semi Tractor',
  VAN: 'Van',
};

export const truckTypeOptionsMap = {
  [GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT]: BORDER_CONNECT_TRUCK_TYPE_MAP,
};

const BORDER_CONNECT_TRAILER_TYPE_MAP = {
  CONTAINER_CLOSED_TOP_SEA_CONTAINER_20_FT: '2B, 20 ft. Container (Closed top) sea container',
  CONTAINER_OPEN_TOP_SEA_CONTAINER_20_FT: '20, 20 ft. Container (Open top) sea container',
  CONTAINER_CLOSED_TOP_SEA_CONTAINER_40_FT: '4B, 40 ft. Container (Closed top) sea container',
  CONTAINER_OPEN_TOP_SEA_CONTAINER_40_FT: '40, 40 ft. Container (Open top) sea container',
  AUTO_CARRIER_TRAILER: 'TC, Auto carrier/trailer',
  BEVERAGE_RACK_TRAILER: 'BI, Beverage rack trailer',
  CHASSIS: 'CH, Chassis',
  CONTROLLED_TEMPERATURE: 'RT, Controlled temperature',
  CONTROLLED_TEMPERATURE_TRAILER: 'TW, Controlled temperature trailer',
  DOUBLE_DROP_TRAILER: 'DD, Double drop trailer',
  DROP_BACK_TRAILER: 'DT, Drop back trailer',
  FIXED_RACK_DOUBLE_DROP_TRAILER: 'RD, Fixed rack, Double drop trailer',
  FIXED_RACK_SINGLE_DROP_TRAILER: 'RS, Fixed rack, Single drop trailer',
  FLATBED_PERFORM_TRAILER: 'FT, Flatbed/Perform trailer',
  FLATBED_TRAILER: 'FR, Flatbed trailer',
  FLATBED_TRAILER_WITH_HEADBOARDS: 'FH, Flatbed trailer with headboards',
  FLATBED_TRAILER_WITH_NO_HEADBOARDS: 'FN, Flatbed trailer with no headboards',
  GONDOLA_CLOSED: 'RG, Gondola (Closed)',
  GONDOLA_OPEN: 'RO, Gondola (Open)',
  GOOSENECK_TRAILER: 'CB, Gooseneck trailer',
  HOPPER_CAR_COVERED: 'HC, Hopper car (Covered)',
  HOPPER_CAR_COVERED_PNEUMATIC_DISCHARGE: 'HP, Hopper car  (Covered; Pneumatic discharge)',
  HOPPER_CAR_OPEN: 'HO, Hopper car (Open)',
  HORSE_TRAILER: 'HE, Horse trailer',
  LIVESTOCK_TRAILER: 'LT, Livestock trailer',
  NO_EQUIPMENT: 'NC, No equipment',
  OTHER: 'OE, Other',
  OTHER_LENGTH_SEA_CONTAINER_CLOSED_TOP: 'CL, Other length sea container (Closed top)',
  OTHER_LENGTH_SEA_CONTAINER_OPEN_TOP: 'CU, Other length sea container (Open top)',
  REFRIGERATED_CONTAINER: 'CZ, Refrigerated container',
  SEMI_TRUCK_TRAILER: 'TL, Semi truck trailer',
  SINGLE_DROP_TRAILER: 'SD, Single drop trailer',
  TANK_TRAILER_CHEMICALS_HEATED_INSULATED: 'T8, Tank trailer (Chemicals) - Heated/Insulated',
  TANK_TRAILER_CHEMICALS_HEATED_NOT_INSULATED: 'T6, Tank trailer (Chemicals) - Heated/Not insulated',
  TANK_TRAILER_CHEMICALS_NOT_HEATED_INSULATED: 'T7, Tank trailer (Chemicals) - Not heated/Insulated',
  TANK_TRAILER_CHEMICALS_NOT_HEATED_NOT_INSULATED: 'T5, Tank trailer (Chemicals) - Not heated/Not insulated',
  TANK_TRAILER_FOOD_GRADE_LIQUIDS: 'TK, Tank trailer (Food grade liquids)',
  TANK_TRAILER_GAS_HEATED_INSULATED: 'T4, Tank trailer (Gas) - Heated/Insulated',
  TANK_TRAILER_GAS_HEATED_NOT_INSULATED: 'T2, Tank trailer (Gas) - Heated/Not insulated',
  TANK_TRAILER_GAS_NOT_HEATED_INSULATED: 'T3, Tank trailer (Gas) - Not heated/Insulated',
  TANK_TRAILER_GAS_NOT_HEATED_NOT_INSULATED: 'T1, Tank trailer (Gas) - Not heated/Not insulated',
  TANK_TRAILER_LIQUIDS_HEATED_INSULATED: 'L4, Tank trailer (Liquids) - Heated/Insulated',
  TANK_TRAILER_LIQUIDS_HEATED_NOT_INSULATED: 'L2, Tank trailer (Liquids) - Heated/Not insulated',
  TANK_TRAILER_LIQUIDS_NOT_HEATED_INSULATED: 'L3, Tank trailer (Liquids) - Not heated/Insulated',
  TANK_TRAILER_LIQUIDS_NOT_HEATED_NOT_INSULATED: 'L1, Tank trailer (Liquids) - Not heated/Not insulated',
  TRAILER_DRY_FREIGHT: 'TF, Trailer (Dry freight)',
};

export const trailerTypeOptionsMap = {
  [GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT]: BORDER_CONNECT_TRAILER_TYPE_MAP,
};
