import React from 'react';
// forms
import { Divider, Description } from './ui';
// ui
import { Box, Flex, FlexHovered, Text } from '../../ui';
// utilities
import * as G from '../../helpers';
//////////////////////////////////////////////////

const ComponentDescription = ({ path, description, usage = null }: Object) => (
  <Box mb={15}>
    <Flex mb={10}>
      Path to component:
      <Box
        ml={5}
        color={G.getTheme('colors.light.mainRed')}
      >
        {path}
      </Box>
    </Flex>
    <Description>
      <span>{description}</span>
      {usage}
    </Description>
  </Box>
);

const ComponentStoryWrapper = ({ name, path, description, usage, children }: object) => (
  <FlexHovered
    p={20}
    cursor='default'
    borderRadius={5}
    flexDirection='column'
    alignItems='flex-start'
    bgColor={G.getTheme('colors.bgBlue')}
    boxShadow='0 0 5px 0 rgba(0, 0, 0, 0.2)'
    backgroundColor={G.getTheme('colors.whiteGrey')}
  >
    <Text
      mb='15px'
      fontSize='16px'
      fontWeight='bold'
      color={G.getTheme('colors.darkGrey')}
    >
      {`<${name}/>`}
    </Text>
    <ComponentDescription
      path={path}
      usage={usage}
      description={description}
    />
    <Divider />
    <Box>
      {children}
    </Box>
  </FlexHovered>
);

export default ComponentStoryWrapper;
