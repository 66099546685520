import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withProps, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getTransportationModeTypeOptions } from '../../../helpers/options';
// feature configs
import { externalTransportationModeOptionsMap } from '../constants';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '270px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

export const fieldSettings = {
  [GC.FIELD_INTEGRATION_TYPE]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    defaultValue: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
    options: [
      {
        label: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
      },
      {
        label: 'Manitoulin',
        value: GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN,
      },
      {
        label: GC.CARRIER_RATE_INTEGRATION_TYPE_GLS,
        value: GC.CARRIER_RATE_INTEGRATION_TYPE_GLS,
      },
      {
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS,
        label: G.getEnumLocale(GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS),
      },
      {
        label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
        value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
      },
    ],
  },
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: {
    ...defaultStyles,
    loc: 'titles:mode',
    type: 'searchselect',
    options: 'transportationModeOptions',
    fieldName: GC.FIELD_TRANSPORTATION_MODE_GUID,
  },
  [GC.FIELD_ICON_TYPE]: {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:type-of-icon',
    fieldName: GC.FIELD_ICON_TYPE,
    options: getTransportationModeTypeOptions(),
  },
  [GC.EXTERNAL_TRANSPORTATION_MODE]: {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:external-mode',
    options: 'externalTransportationModes',
    fieldName: GC.EXTERNAL_TRANSPORTATION_MODE,
  },
};

export const defaultTransportationModeConfigMappingFields = {
  [GC.FIELD_ICON_TYPE]: null,
  [GC.EXTERNAL_TRANSPORTATION_MODE]: null,
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
};

export const validationSchemaObject = {
  [GC.FIELD_ICON_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.EXTERNAL_TRANSPORTATION_MODE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

const enhance = compose(
  withProps(({ initialValues }: Object) => {
    const fieldsToPick = G.ifElse(
      R.propEq(GC.INTEGRATION_CONFIG_GROUP, 'configGroup', initialValues),
      [GC.FIELD_INTEGRATION_TYPE, GC.FIELD_TRANSPORTATION_MODE_GUID, GC.EXTERNAL_TRANSPORTATION_MODE],
      [GC.FIELD_TRANSPORTATION_MODE_GUID, GC.FIELD_ICON_TYPE],
    );

    const values = R.compose(
      R.mergeDeepLeft(initialValues),
      R.map(R.pathOr(null, ['defaultValue'])),
      R.pick(fieldsToPick),
    )(fieldSettings);
    const validationSchema = R.pick(fieldsToPick, validationSchemaObject);

    return {
      values,
      validationSchema,
      fields: R.values(R.pick(fieldsToPick, fieldSettings)),
    };
  }),
  withFormik({
    mapPropsToValues: ({ values }: Object) => values,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: ({ validationSchema }: Object) => Yup.object().shape(validationSchema),
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    ({ values, transportationModeOptions, ...props }: Object) => {
      const integrationType = R.pathOr(GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS, [GC.FIELD_INTEGRATION_TYPE], values);
      const externalTransportationModes = R.compose(
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ label, value })),
        R.pathOr([], [integrationType]),
      )(externalTransportationModeOptionsMap);

      let fields = R.prop('fields', props);

      if (R.equals(integrationType, GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI)) {
        fields = R.assocPath([2, 'type'], 'text', fields);
      }

      return {
        fields,
        optionsForSelect: { transportationModeOptions, externalTransportationModes },
      };
    },
  ),
  pure,
);

const TransportationModeConfigMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={props.fields}
      flexDirection='column'
      optionsForSelect={props.optionsForSelect}
    />
    <FormFooter boxStyles={{ mt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(TransportationModeConfigMappingForm);
