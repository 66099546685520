import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import VinLookup from '../../components/vin-lookup';
//////////////////////////////////////////////////

const fieldDisabled = (_: any, __: any, { shared }: Object) => shared;

const settings = {
  // first fields
  [GC.FIELD_FLEET_IN_SERVICE]: {
    type: 'toggle',
    disabled: fieldDisabled,
    name: 'titles:in-service',
    additionalInputWrapperSettings: { mr: 20, mb: 10, height: 36, minWidth: 'unset', width: 'max-content' },
  },
  [GC.FIELD_OUT_OF_SERVICE_REASON]: {
    type: 'select',
    isRequired: true,
    disabled: fieldDisabled,
    options: GC.TRAILER_OUT_SERVICE_REASON,
    name: 'titles:out-of-service-status-reason',
    additionalInputWrapperSettings: {
      mr: 20,
      mb: 10,
      width: 200,
      display: (props: Object) => G.ifElse(
        R.path(['values', GC.FIELD_FLEET_IN_SERVICE], props),
        'none',
      ),
    },
  },
  [GC.FIELD_AVAILABLE]: {
    type: 'toggle',
    disabled: fieldDisabled,
    name: 'titles:available',
    additionalInputWrapperSettings: { mr: 20, mb: 10, height: 36, minWidth: 'unset', width: 'max-content' },
  },
  [GC.FIELD_UNAVAILABILITY_REASON]: {
    type: 'select',
    isRequired: true,
    disabled: fieldDisabled,
    name: 'titles:unavailable-status-reason',
    options: GC.TRAILER_UNAVAILABILITY_REASON,
    additionalInputWrapperSettings: {
      mr: 20,
      mb: 10,
      width: 200,
      display: (props: Object) => G.ifElse(
        R.path(['values', GC.FIELD_AVAILABLE], props),
        'none',
      ),
    },
  },
  // general
  [GC.FIELD_TRAILER_TYPE]: {
    type: 'select',
    isRequired: true,
    name: 'titles:type',
    options: GC.TRAILER_TRAILER_TYPE,
  },
  [GC.FIELD_UNIT_ID]: {
    type: 'text',
    isRequired: true,
    name: 'titles:unit-id',
  },
  [GC.FIELD_LEGACY_UNIT_ID]: {
    type: 'text',
    minmax: [0, 40],
    isRequired: false,
    name: 'titles:legacy-unit-id',
  },
  [GC.FIELD_SERIAL_NUMBER]: {
    type: 'text',
    minmax: [0, 40],
    additionalLabelComponent: <VinLookup />,
    additionalLabelStyles: {
      right: 0,
      left: '5px',
      width: 'calc(100% - 10px)',
      justifyContent: 'space-between',
    },
  },
  [GC.FIELD_MANUFACTURER]: {
    type: 'text',
    minmax: [0, 100],
    name: 'titles:manufacturer',
  },
  [GC.FIELD_OUTPUT_YEAR]: {
    type: 'number',
    name: 'titles:year',
    minmax: [1900, 2100],
    validationType: 'number',
  },
  [GC.FIELD_TRAILER_MODEL]: {
    type: 'text',
    minmax: [0, 40],
    name: 'titles:model',
  },
  [GC.FIELD_MODEL_SERIES]: {
    type: 'text',
    minmax: [0, 40],
    name: 'titles:series',
  },
  [GC.FIELD_FLEET_IN_SERVICE_DATE]: {
    type: 'datePicker',
    isClearable: true,
    name: 'titles:in-service-date',
  },
  outServiceDate: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:out-service-date',
  },
  // registration
  [GC.FIELD_LICENSE_PLATE]: {
    type: 'text',
    minmax: [0, 15],
    name: 'titles:license-plate',
    additionalInputWrapperSettings: { mb: 0 },
  },
  [GC.FIELD_REGISTRATION_STATE]: {
    type: 'text',
    minmax: [0, 100],
    name: 'titles:registration-state',
    additionalInputWrapperSettings: { mb: 0 },
  },
  [GC.FIELD_REGISTRATION_EXPIRED_DATE]: {
    isClearable: true,
    type: 'datePicker',
    additionalInputWrapperSettings: { mb: 0 },
    name: 'titles:registration-expiration-date',
  },
  [GC.FIELD_TITLE_STATE]: {
    type: 'text',
    minmax: [0, 100],
    name: 'titles:title-state',
    additionalInputWrapperSettings: { mb: 0 },
  },
  [GC.FIELD_TITLE_NUMBER]: {
    type: 'text',
    minmax: [0, 40],
    name: 'titles:title-number',
    additionalInputWrapperSettings: { mb: 0 },
  },
};

const fieldsWrapperStyles = {
  mx: 20,
  mt: 25,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

const groups = [
  {
    fields: [
      GC.FIELD_FLEET_IN_SERVICE,
      GC.FIELD_OUT_OF_SERVICE_REASON,
      GC.FIELD_AVAILABLE,
      GC.FIELD_UNAVAILABILITY_REASON,
    ],
  },
  {
    fieldsWrapperStyles,
    formSectionTitle: G.getWindowLocale('titles:primary-details', 'Primary Details'),
    fields: [
      GC.FIELD_UNIT_ID,
      GC.FIELD_LEGACY_UNIT_ID,
      GC.FIELD_TRAILER_TYPE,
      GC.FIELD_FLEET_IN_SERVICE_DATE,
      'outServiceDate',
      GC.FIELD_SERIAL_NUMBER,
      GC.FIELD_MANUFACTURER,
      GC.FIELD_OUTPUT_YEAR,
      GC.FIELD_TRAILER_MODEL,
      GC.FIELD_MODEL_SERIES,
    ],
  },
  {
    fieldsWrapperStyles,
    formSectionTitle: G.getWindowLocale('titles:registration', 'Registration'),
    fields: [
      GC.FIELD_LICENSE_PLATE,
      GC.FIELD_REGISTRATION_STATE,
      GC.FIELD_REGISTRATION_EXPIRED_DATE,
      GC.FIELD_TITLE_STATE,
      GC.FIELD_TITLE_NUMBER,
    ],
  },
];

const defaultInputWrapperStyles = {
  mb: 25,
  width: '19%',
  maxWidth: 280,
  minWidth: 125,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

const mappedGroups = R.map((group: Object) => {
  const { fields } = group;

  const fieldSettings = R.map((fieldName: Object) => {
    const field = R.pathOr({}, [fieldName], settings);

    const {
      name,
      inputStyles,
      additionalLabelStyles,
      additionalLabelComponent,
      additionalInputWrapperSettings,
    } = field;

    return {
      ...field,
      fieldName,
      inputStyles,
      errorWidth: '100%',
      additionalLabelStyles,
      additionalLabelComponent,
      calendarInputWrapperStyles,
      label: G.ifElse(G.isNotNil(name), R.of(Array, name)),
      inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperSettings },
    };
  }, fields);

  return R.assoc('fieldSettings', fieldSettings, group);
}, groups);

const validationSchema = Yup.lazy(({ available, inService }: Object) => Yup.object().shape({
  ...R.map(({ minmax, isRequired, validationType = 'string' }: Object) => {
    const fieldsMap = {
      string: G.ifElse(isRequired, G.yupStringRequired, G.yupStringNotRequired),
      number: Yup.number().nullable(true).transform((value: number) => G.ifElse(G.isNotNaN(value), value)),
    };

    let field = R.prop(validationType, fieldsMap);

    if (G.isNotNil(minmax)) {
      const locale = G.ifElse(
        R.equals(validationType, 'string'),
        G.getShouldBeFromToCharLocaleTxt,
        G.getShouldBeFromToLocaleTxt,
      )(...minmax);

      field = field.min(R.head(minmax), locale).max(R.last(minmax), locale);
    }

    return field;
  }, settings),
  [GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT]: G.yupArrayNotRequired,
  [GC.FIELD_OUT_OF_SERVICE_REASON]: G.ifElse(
    G.isFalse(inService),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.FIELD_UNAVAILABILITY_REASON]: G.ifElse(
    G.isFalse(available),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

export const generalDetailsSettings = {
  validationSchema,
  firstFields: R.head(mappedGroups),
  fieldsGroups: R.tail(mappedGroups),
};
