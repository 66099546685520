import * as R from 'ramda';
import React from 'react';
import { getIn, Field, FieldArray } from 'formik';
// components
import { Input } from '../../../../components/charge/ui';
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import Calendar from '../../../../forms/formik/calendar';
import DatePickerMuiFormik from '../../../../forms/formik/datepicker-mui';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, StickedBox } from '../../../../ui';
// feature fleet/driver
import { DRIVER_DAILY_HOURS } from '../constants';
import { getDriverHoursInitFields, driverHoursFieldSettings } from '../settings/fields-settings';
//////////////////////////////////////////////////

const SectionHeader = ({ push, zIndex, isEditMode }: Object) => {
  const text = G.getWindowLocale('actions:add', 'Add');
  const textColor = G.getTheme('colors.light.black');

  return (
    <StickedBox
      top='0'
      p='10px 0'
      zIndex={zIndex}
      display='block'
      bg={G.getTheme('modal.bgColor')}
    >
      {
        R.not(isEditMode) &&
        <Flex
          title={text}
          cursor='pointer'
          width='fit-content'
          onClick={() => push(getDriverHoursInitFields())}
        >
          <TextComponent
            p='2px 10px'
            fontSize={14}
            fontWeight={700}
            color={textColor}
            borderRadius='3px'
            display='inline-block'
          >
            {text}
          </TextComponent>
          {I.plusRound(G.getTheme('colors.dark.blue'))}
        </Flex>
      }
      <Flex p='6px 15px 0' justifyContent='space-around'>
        {driverHoursFieldSettings.map(({ loc, width, fieldWidth }: Object, i: number) => (
          <Box key={i} fontSize={13} color={textColor} width={R.or(width, fieldWidth)}>
            {G.getWindowLocale(loc, loc)}
          </Box>
        ))}
      </Flex>
    </StickedBox>
  );
};

const checkHasFieldError = (name: string, errors: Object, touched: Object) => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  return G.ifElse(
    R.and(G.isNotNilAndNotEmpty(error), G.isTrue(touch)),
    true,
    false,
  );
};

const renderField = (fieldSettings: Object, props: Object) => {
  const { form, rowIndex } = props;

  if (R.equals(fieldSettings.type, 'datePicker')) {
    const { values, zIndex, excludeDates, setFieldValue, setFieldTouched } = props;

    const name = `${DRIVER_DAILY_HOURS}.${rowIndex}.${GC.FIELD_LOG_DATE}`;
    const value = R.path([DRIVER_DAILY_HOURS, rowIndex, GC.FIELD_LOG_DATE], values);

    const configUseMuiCalendar = G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR);
    const CalendarToUse = G.ifElse(G.isTrue(configUseMuiCalendar), DatePickerMuiFormik, Calendar);

    return (
      <Box zIndex={zIndex}>
        <CalendarToUse
          {...fieldSettings}
          value={value}
          touched={true}
          excludeDates={excludeDates}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={checkHasFieldError(name, form.errors, form.touched)}
          id={`${DRIVER_DAILY_HOURS}.${rowIndex}.${GC.FIELD_LOG_DATE}`}
        />
      </Box>
    );
  }

  return (
    <Field name={`${DRIVER_DAILY_HOURS}.${rowIndex}.${fieldSettings.fieldName}`}>
      {({ field }: Object) => (
        <Input
          {...field}
          px={10}
          width={R.or(fieldSettings.fieldWidth, 'auto')}
          placeholder={G.getWindowLocale(fieldSettings.placeholder)}
          hasError={checkHasFieldError(field.name, form.errors, form.touched)}
        />
      )}
    </Field>
  );
};

const DayFields = (props: Object) => {
  const { remove, rowIndex, isEditMode } = props;

  return (
    <Flex flexWrap='wrap' justifyContent='space-between'>
      {
        driverHoursFieldSettings.map((fieldSettings: Object, i: number) => (
          <Box
            key={i}
            my='5px'
            mr='5px'
            display='flex'
            alignItems='center'
            width={R.or(fieldSettings.wrapperBoxWidth, 'auto')}
          >
            {renderField(fieldSettings, props)}
            {
              R.equals(i, 4) && R.not(isEditMode) &&
              <Flex
                mx='5px'
                display='flex'
                cursor='pointer'
                alignItems='center'
                title='Add new charge'
                onClick={() => remove(rowIndex)}
              >
                {I.trash()}
              </Flex>
            }
          </Box>
        ))
      }
    </Flex>
  );
};

const DriverDailyHoursSection = (props: Object) => {
  const list = R.pathOr([], ['values', DRIVER_DAILY_HOURS], props);
  const zIndexStart = R.add(R.length(list), 11);
  const existingDates = R.pathOr([], ['existingDates'], props);
  const excludeDates = R.compose(
    R.concat(existingDates),
    R.map(R.prop(GC.FIELD_LOG_DATE)),
  )(list);

  return (
    <Box overflow='auto' maxHeight={400}>
      <FieldArray
        name={DRIVER_DAILY_HOURS}
        render={(formikArrayProps: Object) => (
          <Box>
            <SectionHeader {...props} zIndex={R.inc(zIndexStart)} push={formikArrayProps.push} />
            <Box p={15}>
              {
                list.map((driverDay: string, i: number) => (
                  <DayFields
                    {...props}
                    {...formikArrayProps}
                    key={i}
                    rowIndex={i}
                    excludeDates={excludeDates}
                    zIndex={R.subtract(zIndexStart, i)}
                  />
                ))
              }
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default DriverDailyHoursSection;
