import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StyledLink } from '../../../ui';
// feature carrier-profile
import { downloadCarrierMailRequest } from '../actions';
//////////////////////////////////////////////////

const EmailsComponent = ({ emails }: Object) => (
  <Flex flexWrap='wrap'>
    {
      emails.map((email: string, index: number) => (
        <StyledLink
          pl={0}
          p='5px'
          key={index}
          target='_blank'
          color='light.blue'
          wordBreak='break-all'
          href={`mailto:${email}`}
        >
          {email}{R.and(R.lt(R.inc(index), R.length(emails)), ',')}
        </StyledLink>
      ))
    }
  </Flex>
);

const DownloadMail = ({ guid }: Object) => {
  const dispatch = useDispatch();

  return (
    <Box height={20} cursor='pointer' onClick={() => dispatch(downloadCarrierMailRequest(guid))}>
      {I.downloadMail(G.getTheme('colors.dark.blue'))}
    </Box>
  );
};

const fields = {
  actions: {
    width: 50,
    name: 'actions',
    customComponent: (row: Object) => <DownloadMail guid={R.path(['data', GC.FIELD_GUID], row)} />,
  },
  [GC.FIELD_TO]: {
    width: 400,
    name: 'titles:mail-to',
    customComponent: (row: Object) => {
      const emails = R.path(['data', GC.FIELD_TO], row);

      if (G.isNilOrEmpty(emails)) return null;

      return <EmailsComponent emails={emails} />;
    },
  },
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT]: {
    name: 'titles:subject',
  },
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO]: {
    name: 'titles:reply-to',
  },
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO]: {
    width: 400,
    name: 'titles:copy-to',
    customComponent: (row: Object) => {
      const emails = R.path(['data', GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO], row);

      if (G.isNilOrEmpty(emails)) return null;

      return <EmailsComponent emails={emails} />;
    },
  },
};

export const mailSettings = {
  fields,
  singleItem: '',
  groupName: 'mail',
  actionsPicker: [],
  formGroupTitleArr: ['titles:mails', 'Mails'],
  additionalTableSettings: { checkBoxCellWidth: '0px' },
  endpoints: {
    list: 'carrierMailList',
  },
};
