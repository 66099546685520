import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { Form, FieldsetComponent } from '../../../forms';
// ui
import { Flex } from '../../../ui';
// feature dispatch-planner-events
import { getFields, initialValues, validationSchema } from '../settings/add-tel-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'ADD_TEL_TO_PLANNER',
    mapPropsToValues: () => initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    handleSubmit: (values: Object, { props }: Object) => props.createLoadRequest(values),
  }),
  pure,
);

const AddTelToPlannerForm = enhance((props: Object) => (
  <Form
    maxHeight='auto'
    onSubmit={props.handleSubmit}
  >
    <Flex flexDirection='column'>
      <FieldsetComponent
        {...props}
        fieldsetWidth='100%'
        justifyContent='space-between'
        fields={getFields(props.locationOptions)} />
      <FormFooter
        closeModal={props.closeModal}
        boxStyles={{ p: 10, justifyContent: 'space-around' }} />
    </Flex>
  </Form>
));

export default AddTelToPlannerForm;
