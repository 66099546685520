import * as R from 'ramda';
import * as Yup from 'yup';
import { css } from 'styled-components';
import React, { Fragment } from 'react';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../../helpers/options';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');

const inputWrapperStyles = { mb: 25, width: 150 };

const fieldSettings1 = [
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    label: ['titles:date'],
    maxDate: G.getCurrentDate(),
    fieldName: GC.FIELD_LOG_DATE,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:on-duty-hours'],
    fieldName: GC.FIELD_ON_DUTY_HOURS,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:on-duty-mins'],
    fieldName: GC.FIELD_ON_DUTY_MINUTES,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:driving-hours'],
    fieldName: GC.FIELD_DRIVING_HOURS,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:driving-mins'],
    fieldName: GC.FIELD_DRIVING_MINUTES,
  },
];

const inputWrapperStyles2 = { mr: 20 };

const fieldSettings2 = [
  {
    type: 'text',
    fieldName: GC.FIELD_TOTAL_DISTANCE,
    inputWrapperStyles: inputWrapperStyles2,
    label: G.getWindowLocale('titles:total-distance', 'Total Distance'),
  },
  {
    type: 'select',
    fieldName: GC.FIELD_TOTAL_DISTANCE_UOM,
    inputWrapperStyles: inputWrapperStyles2,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
    label: G.getWindowLocale('titles:distance-uom', 'Distance UOM'),
  },
];

const emptyRow = () => ({
  id: G.genShortId(),
  [GC.FIELD_LOG_DATE]: null,
  [GC.FIELD_ON_DUTY_HOURS]: null,
  [GC.FIELD_DRIVING_HOURS]: null,
  [GC.FIELD_TOTAL_DISTANCE]: null,
  [GC.FIELD_ON_DUTY_MINUTES]: null,
  [GC.FIELD_DRIVING_MINUTES]: null,
  [GC.FIELD_TOTAL_DISTANCE_UOM]: null,
});

const validationSchema = Yup.object().shape({
  dailyHours: Yup.array().of(Yup.object().shape({
    [GC.FIELD_LOG_DATE]: G.yupStringRequired,
    [GC.FIELD_ON_DUTY_HOURS]: Yup.number()
      .nullable(true)
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(24, G.getShouldBeLessOrEqualLocaleTxt(24)),
    [GC.FIELD_ON_DUTY_MINUTES]: Yup.number()
      .nullable(true)
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(59, G.getShouldBeLessOrEqualLocaleTxt(59)),
    [GC.FIELD_DRIVING_HOURS]: Yup.number()
      .nullable(true)
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(24, G.getShouldBeLessOrEqualLocaleTxt(24)),
    [GC.FIELD_DRIVING_MINUTES]: Yup.number()
      .nullable(true)
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(59, G.getShouldBeLessOrEqualLocaleTxt(59)),
    [GC.FIELD_TOTAL_DISTANCE]: Yup.string()
      .nullable(true)
      .min(0, G.getShouldBePositiveLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt()),
    [GC.FIELD_TOTAL_DISTANCE_UOM]: Yup.string().when(
      GC.FIELD_TOTAL_DISTANCE,
      {
        is: (value: any) => G.isNilOrEmpty(value),
        then: (schema: Object) => schema.notRequired(),
        otherwise: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      },
    ),
  })),
});

const HoursSection = (props: Object) => {
  const { form, push, remove, isEditMode } = props;

  const variableValues = R.pathOr([], ['values', 'dailyHours'], form);

  const handleRemove = (index: number) => {
    if (R.equals(R.length(variableValues), 1)) return;

    remove(index);
  };

  const title = G.ifElse(
    isEditMode,
    G.getEditTitle,
    G.getAddTitle,
  )(['titles:driver-daily-log', 'Driver Daily Log']);

  return (
    <Fragment>
      <Flex
        pl={20}
        mb={25}
        height={30}
        width='100%'
        fontWeight='bold'
        color={whiteColor}
        bg={G.getTheme('colors.mainBlue')}
      >
        {title}
        {
        G.isFalse(isEditMode) &&
        <Box ml={10} cursor='pointer' onClick={() => push(emptyRow())}>
          {I.plusRound(whiteColor)}
        </Box>
        }
      </Flex>
      <Box
        mx='9px'
        px='6px'
        maxHeight={400}
        overflowY={G.ifElse(R.gt(R.length(variableValues), 2), 'auto')}
        css={css`
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
        `}
      >
        {
          variableValues.map((_: any, index: number) => (
            <Flex key={index} mb={30} flexDirection='column'>
              <Flex>
                {
                  G.isFalse(isEditMode) &&
                  <Box
                    mr={10}
                    mb={25}
                    cursor='pointer'
                    onClick={() => handleRemove(index)}
                  >
                    {I.trash(darkBlueColor)}
                  </Box>
                }
                <Fieldset2
                  {...form}
                  itemIndex={index}
                  fieldsetType='array'
                  arrayName='dailyHours'
                  fields={fieldSettings1}
                  fieldsWrapperStyles={{ width: 810, justifyContent: 'space-between' }}
                />
              </Flex>
              <Fieldset2
                {...form}
                itemIndex={index}
                fieldsetType='array'
                arrayName='dailyHours'
                fields={fieldSettings2}
              />
            </Flex>
          ))
        }
      </Box>
    </Fragment>
  );
};

const DailyHoursForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldArray
      name='dailyHours'
      render={(arrayHelpers: Object) => <HoursSection {...arrayHelpers} isEditMode={props.isEditMode} />}
    />
    <FormFooter2 boxStyles={{ px: 15, mt: 10 }} />
  </form>
);

const mapper = (values: Array, driverGuid: string) => R.map((item: Object) => {
  const { onDutyHours, drivingHours, onDutyMinutes, drivingMinutes } = item;

  const fullOnDutyMinutes = R.add(
    R.or(onDutyMinutes, 0),
    R.multiply(R.or(onDutyHours, 0), 60),
  );

  const fullDrivingMinutes = R.add(
    R.or(drivingMinutes, 0),
    R.multiply(R.or(drivingHours, 0), 60),
  );

  return {
    ...item,
    driverGuid,
    [GC.FIELD_ON_DUTY_MINUTES]: fullOnDutyMinutes,
    [GC.FIELD_DRIVING_MINUTES]: fullDrivingMinutes,
  };
}, values);

const enhance = compose(
  withProps(({ initialValues }: Object) => ({
    isEditMode: G.isNotNil(G.getPropFromObject(GC.FIELD_VERSION, initialValues)),
    [GC.FIELD_DRIVER_GUID]: G.getPropFromObject(GC.FIELD_DRIVER_GUID, initialValues),
  })),
  withFormik({
    validationSchema: Yup.lazy(() => validationSchema),
    mapPropsToValues: ({ isEditMode, initialValues }: Object) => {
      if (G.isFalse(isEditMode)) {
        return { dailyHours: R.of(Array, emptyRow()) };
      }

      const {
        hours: onDutyHours,
        minutes: onDutyMinutes,
      } = G.convertMinutesToHoursAndMinutes(initialValues.onDutyMinutes);
      const {
        hours: drivingHours,
        minutes: drivingMinutes,
      } = G.convertMinutesToHoursAndMinutes(initialValues.drivingMinutes);

      const newValues = {
        ...initialValues,
        [GC.FIELD_ON_DUTY_HOURS]: onDutyHours,
        [GC.FIELD_DRIVING_HOURS]: drivingHours,
        [GC.FIELD_ON_DUTY_MINUTES]: onDutyMinutes,
        [GC.FIELD_DRIVING_MINUTES]: drivingMinutes,
      };

      return { dailyHours: R.of(Array, newValues) };
    },
    handleSubmit: ({ dailyHours }: Object, { props }: Object) => {
      const { isEditMode, driverGuid, submitAction } = props;

      const mappedValues = mapper(dailyHours, driverGuid);

      const data = G.ifElse(
        isEditMode,
        R.head(mappedValues),
        mappedValues,
      );

      submitAction(data);
    },
  }),
  pure,
);

export default enhance(DailyHoursForm);
