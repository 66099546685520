import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import { enhanceRateCharges } from '../../../rate/hocs';
import { withDispatchCarrierRate } from '../../../mail-sending/hocs';
import DriverRateForm, { EditDriverRateForm } from '../../../rate/driver';
import CarrierRateForm, { EditCarrierRateForm } from '../../../rate/carrier';
import EditCarrierRateForUserTypeCarrier from '../../../rate/carrier/edit-carrier-rate-for-user-type-carrier';
// forms
import { ApproveRateForm } from '../../../../forms/forms/approve-rate-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature dispatch details new
import AddDriverCarrierRate from '../components/add-driver-carrier-rate';
import {
  approveLoadRateRequest,
  dispatchLoadRateRequest,
  createLoadDriverRateRequest,
  updateLoadDriverRateRequest,
  removeLoadDriverRateRequest,
  createLoadCarrierRateRequest,
  updateLoadCarrierRateRequest,
  removeLoadCarrierRateRequest,
  dispatchLoadCarrierRateRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddDriverOrCarrierRate = compose(
  connect(
    null,
    {
      createLoadDriverRateRequest,
      createLoadCarrierRateRequest,
    },
  ),
  enhanceRateCharges('rateLoadDetailsNew'),
  withHandlers({
    handleAddDriverRate: (props: Object) => (shouldSelect: boolean) => {
      const { load, openModal, handleCreateTelRate } = props;

      const telGuid = R.path([GC.FIELD_GUID], load);
      const branchGuid = R.pathOr('', [GC.FIELD_BRANCH, GC.FIELD_GUID], load);

      const select = G.ifElse(G.isBoolean(shouldSelect), shouldSelect, false);

      const component = (
        <DriverRateForm
          tel={load}
          telGuid={telGuid}
          shouldSelect={select}
          branchGuid={branchGuid}
          handleSendTelRate={handleCreateTelRate}
          configsNamesArray={GC.ADD_RATE_CONFIGS_ARRAY}
          stopCount={R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], load))}
          transportationMode={G.getTelSingleCloTransportationMode(load.clos)}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
    handleAddCarrierRate: (props: Object) => (shouldSelect: boolean) => {
      const { load, openModal, handleCreateCarrierTelRate } = props;

      const telGuid = R.path([GC.FIELD_GUID], load);
      const stops = R.pathOr([], [GC.FIELD_LOAD_STOPS], load);
      const branchGuid = R.path([GC.FIELD_BRANCH, GC.FIELD_GUID], load);

      const select = G.ifElse(G.isBoolean(shouldSelect), shouldSelect, false);

      const component = (
        <CarrierRateForm
          stops={stops}
          telGuid={telGuid}
          shouldSelect={select}
          branchGuid={branchGuid}
          stopCount={R.length(stops)}
          handleSendTelRate={handleCreateCarrierTelRate}
          configsNamesArray={GC.ADD_CARRIER_RATE_CONFIGS_ARRAY}
          equipmentGuid={R.path([GC.FIELD_LOAD_EQUIPMENT, GC.FIELD_DROPDOWN_OPTION_GUID], load)}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
  }),
  withHandlers({
    handleAddDriverCarrierRate: (props: Object) => (event: Object) => {
      props.openFixedPopup({
        position: 'left',
        el: event.currentTarget,
        content: (
          <AddDriverCarrierRate
            load={props.load}
            events={props.events}
            itemList={props.itemList}
            openModal={props.openModal}
            closeModal={props.closeModal}
            loadConfigs={props.loadConfigs}
            activeListName={props.activeListName}
            closeFixedPopup={props.closeFixedPopup}
            handleAddDriverRate={props.handleAddDriverRate}
            handleAddCarrierRate={props.handleAddCarrierRate}
          />
        ),
      });
    },
  }),
  pure,
);

export const withLoadDriverCarrierRateTableGroupRowActions = compose(
  connect(
    null,
    {
      approveLoadRateRequest,
      dispatchLoadRateRequest,
      updateLoadDriverRateRequest,
      removeLoadDriverRateRequest,
      updateLoadCarrierRateRequest,
      removeLoadCarrierRateRequest,
      dispatchLoadCarrierRateRequest,
    },
  ),
  enhanceRateCharges('rateLoadDetailsNew'),
  withDispatchCarrierRate,
  withHandlers({
    handleEditDriverRate: (props: Object) => (useSelectedCarrierDriverRate: boolean) => {
      const {
        load,
        entity,
        openModal,
        handleUpdateTelRate,
        selectedCarrierDriverRate,
      } = props;

      const telGuid = R.path([GC.FIELD_GUID], load);
      const telStatus = R.path([GC.FIELD_STATUS], load);
      const branchGuid = R.pathOr('', [GC.FIELD_BRANCH, GC.FIELD_GUID], load);

      const initialValues = G.ifElse(
        useSelectedCarrierDriverRate,
        selectedCarrierDriverRate,
        entity,
      );

      const component = (
        <EditDriverRateForm
          telGuid={telGuid}
          telStatus={telStatus}
          branchGuid={branchGuid}
          initialValues={initialValues}
          handleSendTelRate={handleUpdateTelRate}
          configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
    handleEditCarrierRate: (props: Object) => (useSelectedCarrierDriverRate: boolean) => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        selectedCarrierDriverRate,
        handleUpdateCarrierTelRate,
      } = props;

      const telGuid = R.path([GC.FIELD_GUID], load);
      const branchGuid = R.pathOr('', [GC.FIELD_BRANCH, GC.FIELD_GUID], load);

      const initialValues = G.ifElse(
        useSelectedCarrierDriverRate,
        selectedCarrierDriverRate,
        entity,
      );

      let component = (
        <EditCarrierRateForm
          telGuid={telGuid}
          branchGuid={branchGuid}
          initialValues={initialValues}
          handleSendTelRate={handleUpdateCarrierTelRate}
          configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
        />
      );

      if (G.isCurrentUserTypeCarrier()) {
        component = (
          <EditCarrierRateForUserTypeCarrier
            rate={initialValues}
            openModal={openModal}
            branchGuid={branchGuid}
            closeModal={closeModal}
            handleSendTelRate={handleUpdateCarrierTelRate}
            title={G.getWindowLocale('titles:edit-carrier-rate', 'Edit Carrier Rate')}
          />
        );
      }

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
    handleRemoveDriverCarrierRate: (props: Object) => () => {
      const { entity, openModal, removeLoadDriverRateRequest, removeLoadCarrierRateRequest } = props;

      const { guid, type } = entity;

      const action = () => G.ifElse(
        R.equals(type, GC.RATE_TYPE_FLEET_RATE),
        removeLoadDriverRateRequest,
        removeLoadCarrierRateRequest,
      )(guid);

      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-rate',
        'Do you want to delete this Rate?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              action,
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleApproveRate: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        approveLoadRateRequest,
        selectedCarrierDriverRate,
      } = props;

      const { marginViolationNote, marginViolationReason } = selectedCarrierDriverRate;

      const initialValues = {
        marginViolationNote,
        marginViolationReason: G.getPropFromObject(GC.FIELD_DROPDOWN_OPTION_GUID, marginViolationReason),
      };

      const submitAction = (values: Object) => approveLoadRateRequest({
        ...values,
        [GC.FIELD_TEL_GUID]: G.getGuidFromObject(load),
      });

      const component = (
        <ApproveRateForm
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={initialValues}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 380,
        },
      };

      openModal(modal);
    },
    handleDispatchCarrierRate: (props: Object) => (data: Object) => {
      const {
        loadDocuments,
        dispatchLoadRateRequest,
        documentTemplateOptions,
        selectedCarrierDriverRate,
        handleDispatchCarrierRate,
        dispatchLoadCarrierRateRequest,
      } = props;

      const telGuid = G.getGuidFromObject(data);

      if (R.prop(GC.FIELD_EMAILS_SUSPENDED, selectedCarrierDriverRate)) {
        return dispatchLoadRateRequest({
          telGuid,
          action: GC.DISPATCH_ACTION,
        });
      }

      const customHandleSubmit = (values: Object) => {
        const data = R.compose(
          R.mergeRight({ telGuid, mailTo: G.getPropFromObject(GC.FIELD_TO, values) }),
          R.dissoc(GC.FIELD_TO),
        )(values);
        dispatchLoadCarrierRateRequest(data);
      };

      const branch = G.getAmousCurrentBranchFromWindow();

      const asyncOptions = {
        params: {
          [GC.FIELD_PRIMARY_OBJECT_GUID]: telGuid,
          [GC.CURRENT_BRANCH]: G.getGuidFromObject(branch),
        },
      };

      const carrierDispatchDocumentTemplateOptions = R.compose(
        R.map(({ guid, name }: Object) => ({
          [GC.FIELD_VALUE]: guid,
          [GC.FIELD_LABEL]: name,
        })),
        R.pathOr([], [GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS]),
      )(documentTemplateOptions);

      const options = {
        tel: data,
        asyncOptions,
        isMulti: true,
        customHandleSubmit,
        asyncMethod: 'get',
        withDispatchDocs: true,
        selectedRate: selectedCarrierDriverRate,
        asyncInitialData: { data: loadDocuments },
        asyncEndpoint: endpointsMap.telDocumentList,
        branchGuid: G.getPropFromObject(GC.BRANCH_GUID, data),
        templateOptions: carrierDispatchDocumentTemplateOptions,
        handleDispatchCarrierRateWithTerminalData: () => dispatchLoadRateRequest({
          telGuid,
          action: GC.DISPATCH_ACTION,
        }),
      };

      handleDispatchCarrierRate(options);
    },
  }),
  withHandlers({
    handleEditCarrierDriverRate: (props: Object) => (useSelectedCarrierDriverRate: boolean = false) => {
      const {
        entity,
        handleEditDriverRate,
        handleEditCarrierRate,
        selectedCarrierDriverRate,
      } = props;

      const getEntity = G.ifElse(
        useSelectedCarrierDriverRate,
        selectedCarrierDriverRate,
        entity,
      );

      if (R.pathEq(GC.RATE_TYPE_FLEET_RATE, [GC.FIELD_TYPE], getEntity)) {
        handleEditDriverRate(useSelectedCarrierDriverRate);
      } else {
        handleEditCarrierRate(useSelectedCarrierDriverRate);
      }
    },
    handleDispatchLoadRate: (props: Object) => (data: Object) => {
      const { dispatchLoadRateRequest, handleDispatchCarrierRate } = props;

      const isCarrierRate = R.or(
        G.isNotNil(R.prop(GC.FIELD_CARRIER_ASSIGNMENT_GUID, data)),
        G.isNotNil(R.prop(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, data)),
      );

      if (isCarrierRate) {
        handleDispatchCarrierRate(data);
      } else {
        dispatchLoadRateRequest({
          action: GC.DISPATCH_ACTION,
          telGuid: G.getGuidFromObject(data),
        });
      }
    },
    handleCancelDispatch: ({ dispatchLoadRateRequest }: Object) => (data: Object) => (
      dispatchLoadRateRequest({
        action: GC.CANCEL_DISPATCH_ACTION,
        telGuid: G.getGuidFromObject(data),
      })
    ),
  }),
);
