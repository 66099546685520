import styled from 'styled-components';
import { space, fontSize } from 'styled-system';
// forms
import { FormGroupWrapper } from '../../../forms';
//////////////////////////////////////////////////

// REFACTOR, z-index
export const TrailerWrapper = styled.div`
  ${space}
  
  & input,
  & textarea {
    z-index: auto;
  }
`;

export const Label = styled.div`
  ${fontSize}

  width: 320px;
  padding-left: 15px;
`;

export const Title = styled.div`
  ${space}
  ${fontSize}

  margin: 5px;
  font-weight: bold;
`;

export const FormWrapper = styled.div`
  & + div {
    padding: 5px;
    position: static;
  }
`;

export const EditTrailerWrapper = styled(TrailerWrapper)`
  height: ${({ height }: Object) => height};
  overflow-y: ${({ overflowY }: Object) => overflowY};
  background: ${({ background }: Object) => background};

  & div.react-datepicker-popper[data-placement^="top"],
  & div.react-datepicker-popper[data-placement^="bottom"] {
    margin-left: 12px;
    & div.react-datepicker__month-container {
      width: 226px;
    }
  }
`;

export const TrailerGroupWrapper = styled(FormGroupWrapper)`
   & * {
     z-index: auto;
   }
`;
