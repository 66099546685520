import * as R from 'ramda';
import { getTELFilterParams, getCLOFilterParams } from '../../dispatch-board-new/settings/filter-params';
// components
import {
  getChartTypeOptions,
  getRouteGroupByOptions,
  getChartDataTypeOptions,
} from '../../../components/filter/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature charts
import options from './options';
import {
  TOTAL,
  TEL_COUNT,
  CLO_COUNT,
  TEL_SPEND,
  TEL_MARGIN,
  CLO_INCOME,
  CLO_MARGIN,
  EVENT_ON_TIME_LATE } from '../constants';
//////////////////////////////////////////////////

export const RC = {
  COLLECTION_DISPLAYED_VALUE: `${GC.FIELD_COLLECTION}.${GC.FIELD_DISPLAYED_VALUE}`,
};

const getTELFilterParamsWithActualRouteName = () => getTELFilterParams({});

const getCLOFilterParamsWithActualRouteName = () => getCLOFilterParams({});

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_NAME,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_DESCRIPTION,
    name: G.getWindowLocale('titles:description', 'Description'),
  },
  {
    type: 'string',
    value: RC.COLLECTION_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:collection', 'Collection'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_CHART_DATA_TYPE,
    options: getChartDataTypeOptions(),
    name: G.getWindowLocale('titles:data-type', 'Data Type'),
    placeholder: {
      text: 'Choose a data type',
      key: 'titles:choose-a-data-type',
    },
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_CHART_GROUP_BY_TYPE,
    options: getRouteGroupByOptions(),
    name: G.getWindowLocale('titles:group-by', 'Group By'),
    placeholder: {
      text: 'Choose a group',
      key: 'titles:choose-a-group',
    },
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_CHART_CHART_TYPE,
    options: getChartTypeOptions(),
    name: G.getWindowLocale('titles:chart-type', 'Chart Type'),
    placeholder: {
      text: 'Choose a chart type',
      key: 'titles:choose-a-chart-type',
    },
  },
];

const telCount = () => getTELFilterParamsWithActualRouteName();

const cloCount = () => getCLOFilterParamsWithActualRouteName();

const telMargin = () => getTELFilterParamsWithActualRouteName();

const cloMargin = () => getTELFilterParamsWithActualRouteName();

const telTotalSpend = () => getTELFilterParamsWithActualRouteName();

const cloTotalSpend = () => getTELFilterParamsWithActualRouteName();

const eventOnTimeLate = () => getTELFilterParamsWithActualRouteName();

export const selectOptionsByDataType = {
  defaultParams: {
    filters: () => [],
    groupBy: () => [],
    chartTypes: () => [],
  },
  [TEL_COUNT]: {
    filters: telCount,
    chartTypes: () => [
      options.emptyObject,
      options.totalChart,
      options.barChart,
      options.barChart3D,
      options.pieChart,
      options.pieChart3D,
      options.lineChart,
    ],
    groupBy: ({ formValue }: Object) => {
      const chartType = R.pathOr('defaultParams', ['values', 'chartType'], formValue);
      if (R.equals(chartType, TOTAL)) {
        return [
          options.emptyObject,
          options.noGroupGrouping,
        ];
      }
      return [
        options.emptyObject,
        options.driverGrouping,
        options.statusGrouping,
        options.branchGrouping,
        options.dateDayGrouping,
      ];
    },
  },
  [CLO_COUNT]: {
    filters: cloCount,
    chartTypes: () => [
      options.pieChart,
      options.emptyObject,
      options.totalChart,
      options.barChart,
      options.barChart3D,
      options.pieChart3D,
      options.lineChart,
    ],
    groupBy: ({ formValue }: Object) => {
      const chartType = R.pathOr('defaultParams', ['values', 'chartType'], formValue);
      if (R.equals(chartType, TOTAL)) {
        return [
          options.emptyObject,
          options.noGroupGrouping,
        ];
      }
      return [
        options.emptyObject,
        options.statusGrouping,
        options.branchGrouping,
        options.dateDayGrouping,
      ];
    },
  },
  [TEL_MARGIN]: {
    filters: telMargin,
    chartTypes: () => [
      options.emptyObject,
      options.totalChart,
      options.barChart,
      options.barChart3D,
      options.lineChart,
    ],
    groupBy: ({ formValue }: Object) => {
      const chartType = R.pathOr('defaultParams', ['values', 'chartType'], formValue);
      if (R.equals(chartType, TOTAL)) {
        return [
          options.emptyObject,
          options.noGroupGrouping,
        ];
      }
      return [
        options.emptyObject,
        options.driverGrouping,
        options.branchGrouping,
        options.dateDayGrouping,
      ];
    },
  },
  [CLO_MARGIN]: {
    filters: cloMargin,
    chartTypes: () => [
      options.emptyObject,
      options.totalChart,
      options.barChart,
      options.barChart3D,
      options.lineChart,
    ],
    groupBy: ({ formValue }: Object) => {
      const chartType = R.pathOr('defaultParams', ['values', 'chartType'], formValue);
      if (R.equals(chartType, TOTAL)) {
        return [
          options.emptyObject,
          options.noGroupGrouping,
        ];
      }
      return [
        options.emptyObject,
        options.branchGrouping,
        options.dateDayGrouping,
      ];
    },
  },
  [TEL_SPEND]: {
    filters: telTotalSpend,
    chartTypes: () => [
      options.emptyObject,
      options.totalChart,
      options.barChart,
      options.barChart3D,
      options.pieChart,
      options.pieChart3D,
      options.lineChart,
    ],
    groupBy: ({ formValue }: Object) => {
      const chartType = R.pathOr('defaultParams', ['values', 'chartType'], formValue);
      if (R.equals(chartType, TOTAL)) {
        return [
          options.emptyObject,
          options.noGroupGrouping,
        ];
      }
      return [
        options.emptyObject,
        options.driverGrouping,
        options.branchGrouping,
        options.dateDayGrouping,
      ];
    },
  },
  [CLO_INCOME]: {
    filters: cloTotalSpend,
    chartTypes: () => [
      options.emptyObject,
      options.totalChart,
      options.barChart,
      options.barChart3D,
      options.pieChart,
      options.pieChart3D,
      options.lineChart,
    ],
    groupBy: ({ formValue }: Object) => {
      const chartType = R.pathOr('defaultParams', ['values', 'chartType'], formValue);
      if (R.equals(chartType, TOTAL)) {
        return [
          options.emptyObject,
          options.noGroupGrouping,
        ];
      }
      return [
        options.emptyObject,
        options.branchGrouping,
        options.dateDayGrouping,
      ];
    },
  },
  [EVENT_ON_TIME_LATE]: {
    filters: eventOnTimeLate,
    chartTypes: () => [
      options.emptyObject,
      options.barChart,
      options.barChart3D,
      options.lineChart,
    ],
    groupBy: () => [
      options.emptyObject,
      options.driverGrouping,
      options.branchGrouping,
      options.dateDayGrouping,
    ],
  },
};
