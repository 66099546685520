import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature geo-fencing-zone
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  itemList: {},
  availableList: [],
};

const setInitialState = () => initialState;

const deleteGeoFencingZoneSuccess = (state: Object, guid: Object) => P.$drop(`itemList.${guid}`, state);

const toggleGeoFencingZoneDetails = (state: Object, { guid }: Object) => (
  P.$toggle(`itemList.${guid}.expanded`, state)
);

const receivedGeoFencingZoneListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set('itemList', {}, state);

  const indexedList = R.compose(
    G.indexByGuid,
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        index,
        expanded: false,
        disabled: G.isFalse(item.editable),
      },
    )),
  )(data);

  return P.$set('itemList', indexedList, state);
};

const receivedGeoFencingZoneSuccess = (state: Object, data: Object) => {
  const { guid } = data;
  const { itemList } = state;

  const expanded = R.pathOr(false, [guid, 'expanded'], itemList);
  const index = R.pathOr(R.length(itemList), [guid, 'index'], itemList);
  const dataWithTableProps = R.mergeRight(data, { index, expanded });
  const newList = R.assoc(guid, dataWithTableProps, itemList);

  return P.$set('itemList', newList, state);
};

const getAvailableReportGeoFencingZoneListSuccess = (state: Object, data: Array) => (
  P.$set('availableList', data, state)
);


export default createReducer({
  [A.setInitialState]: setInitialState,
  [A.deleteGeoFencingZoneSuccess]: deleteGeoFencingZoneSuccess,
  [A.toggleGeoFencingZoneDetails]: toggleGeoFencingZoneDetails,
  [A.receivedGeoFencingZoneSuccess]: receivedGeoFencingZoneSuccess,
  [A.receivedGeoFencingZoneListSuccess]: receivedGeoFencingZoneListSuccess,
  [A.getAvailableReportGeoFencingZoneListSuccess]: getAvailableReportGeoFencingZoneListSuccess,
}, initialState);

