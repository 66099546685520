import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectDashboardsPayrollStore = (state: Object) => state.dashboardsPayroll;

const makeSelectPayroll = () => createSelector(
  selectDashboardsPayrollStore,
  ({ payroll }: Object) => payroll,
);

export {
  makeSelectPayroll,
};
