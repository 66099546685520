import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useMemo, useCallback } from 'react';
// components
import { PageHeaderActions } from '../../../components/page-header-actions';
// features
import PC from '../../permission/role-permission';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-profile
import {
  makeSelectSendPackage,
  makeSelectAvailableForCarrierLoadBoards,
  makeSelectLoadBoardSynchronizationParams,
} from '../selectors';
import {
  createWatchRequest,
  syncWithIntegrationRequest,
  updateGeneralDetailsRequest,
  sendOnboardingPackageRequest,
  addOrRemoveToNetworkCarrierRequest,
  allowOrForbidBookItNowCarrierRequest,
} from '../actions';
//////////////////////////////////////////////////

const carrierLoadBoardSynchronizations = [
  GC.EXTERNAL_LOAD_BOARD_PARADE,
  GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
  GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
];

const hasCarrierLoadBoardSynchronizations = (loadBoard: string) =>
  R.includes(loadBoard, carrierLoadBoardSynchronizations);

export const showCarrierLoadBoardSynchronization = R.any((item: string) => hasCarrierLoadBoardSynchronizations(item));

export const carrierLoadBoardSynchronizationSources = R.filter(
  ({ value }: string) => hasCarrierLoadBoardSynchronizations(value),
  GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS,
);

export const getAuthorizedCarrierLoadBoardSynchronization = R.compose(
  R.head,
  R.filter((item: string) => hasCarrierLoadBoardSynchronizations(item)),
);

export const isMultipleLoadBoardSynchronizations = R.compose(
  R.lt(1),
  R.length,
  R.filter((item: string) => hasCarrierLoadBoardSynchronizations(item)),
);

export const getSynchronizationType = () => {
  const synchronizationMap = {
    [GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS]: 'RMIS',
    [GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS]: 'My Carrier Packets',
  };

  return R.pathOr('SaferWatch', [G.getCarrierSynchronizationType()], synchronizationMap);
};

const SAFER_WATCH_PROFILE_LINK_ADDRESS = (uSDotNumber: string) =>
  `https://www.saferwatch.com/swCarrierDetailsLink.php?&number=${uSDotNumber}`;

const PageActions = (props: Object) => {
  const {
    values,
    dispatch,
    openModal,
    closeModal,
    initialValues,
  } = props;

  const { email: prevEmail } = initialValues;

  const { guid, email, usDotNumber, addedToWatchList } = values;

  const sendPackage = useSelector(makeSelectSendPackage());
  const availableForCarrierLoadBoards = useSelector(makeSelectAvailableForCarrierLoadBoards());
  const loadBoardSynchronizationParams = useSelector(makeSelectLoadBoardSynchronizationParams());

  const handleCreateWatchRequest = useCallback(
    () => dispatch(createWatchRequest({ guid, usDotNumber, watch: R.not(addedToWatchList) })),
    [usDotNumber, addedToWatchList],
  );

  const handleSyncWithIntegrationRequest = useCallback(() => dispatch(syncWithIntegrationRequest(values)), [values]);

  const handleSendOnboardingPackage = useCallback(() => {
    if (G.isFalse(sendPackage)) {
      const message = G.getWindowLocale(
        'messages:carrier-onboarding-package-is-not-configured',
        'Carrier onboarding package is not configured',
      );

      return G.showToastrMessageSimple('info', message);
    }

    if (G.isNilOrEmpty(email)) {
      const message = G.getWindowLocale('messages:enter-email', 'Please, enter email');

      return G.showToastrMessageSimple('info', message);
    }

    if (R.equals(email, prevEmail)) return dispatch(sendOnboardingPackageRequest(guid));

    dispatch(updateGeneralDetailsRequest({ values, sendPackage: true }));
  }, [email, prevEmail]);

  const handleSynchronizeWithLoadBoard = useCallback((actionName: string, propName: string) => {
    const actionsMap = {
      addOrRemoveToNetworkCarrierRequest,
      allowOrForbidBookItNowCarrierRequest,
    };

    const action = R.prop(actionName, actionsMap);

    const args = {
      [GC.FIELD_CARRIER_GUID]: G.getGuidFromObject(values),
      [propName]: R.prop(propName, loadBoardSynchronizationParams),
    };

    const synchronizationAction = (source: string) => dispatch(
      action(R.assoc(GC.FIELD_SOURCE, source, args)),
    );

    if (isMultipleLoadBoardSynchronizations(availableForCarrierLoadBoards)) {
      let options = carrierLoadBoardSynchronizationSources;

      if (R.equals(actionName, 'allowOrForbidBookItNowCarrierRequest')) {
        options = R.reject(R.propEq(GC.EXTERNAL_LOAD_BOARD_NEWTRUL, GC.FIELD_VALUE), options);
      }

      const componentProps = {
        options,
        fieldWidth: 300,
        optionRequired: true,
        cancelAction: closeModal,
        fieldLabel: G.getWindowLocale('titles:source', 'Source'),
        submitAction: (source: string) => {
          closeModal();
          synchronizationAction(source);
        },
      };

      const component = <SelectDropdownForm {...componentProps} />;

      const modal = {
        p: 15,
        component,
        options: {},
      };

      return openModal(modal);
    }

    return synchronizationAction(getAuthorizedCarrierLoadBoardSynchronization(availableForCarrierLoadBoards));
  }, [loadBoardSynchronizationParams]);

  const { addedToNetwork, allowedBookItNow } = loadBoardSynchronizationParams;

  const permissions = [PC.CARRIER_WRITE];

  const actions = useMemo(
    () => {
      let pageHeaderActions = [
        {
          title: G.getWindowLocale('titles:onboarding-package', 'Onboarding Package'),
          actions: [
            {
              permissions,
              action: handleSendOnboardingPackage,
              text: G.getWindowLocale('titles:send-onboarding-package', 'Send Onboarding Package'),
            },
          ],
        },
      ];

      if (R.or(G.showSaferWatch(), G.showCarrierSynchronization())) {
        const synchronizationActions = {
          title: getSynchronizationType(),
          actions: [
            {
              permissions,
              action: handleCreateWatchRequest,
              text: G.getWindowLocale(...G.ifElse(
                addedToWatchList,
                ['actions:remove-from-watch-list', 'Remove From Watch List'],
                ['actions:add-to-watch-list', 'Add To Watch List'],
              )),
            },
            {
              permissions,
              action: handleSyncWithIntegrationRequest,
              text: `${G.getWindowLocale('titles:sync-with', 'Sync with')} ${getSynchronizationType()}`,
            },
          ],
        };

        if (G.isFalse(G.showCarrierSynchronization())) {
          const saferWatchLink = {
            permissions,
            type: 'link',
            iconName: 'truckStop',
            link: SAFER_WATCH_PROFILE_LINK_ADDRESS(usDotNumber),
            text: G.getWindowLocale('titles:go-to-safer-watch-profile', 'Go to SaferWatch profile'),
          };

          synchronizationActions.actions = R.append(saferWatchLink, synchronizationActions.actions);
        }

        pageHeaderActions = R.append(synchronizationActions, pageHeaderActions);
      }

      if (showCarrierLoadBoardSynchronization(availableForCarrierLoadBoards)) {
        const truckerToolsActions = {
          title: G.getWindowLocale('titles:load-board-synchronization', 'Load Board Synchronization'),
          actions: [
            {
              permissions,
              action: () => handleSynchronizeWithLoadBoard(
                'addOrRemoveToNetworkCarrierRequest',
                GC.FIELD_CARRIER_ADDED_TO_NETWORK,
              ),
              text: G.getWindowLocale(...G.ifElse(
                addedToNetwork,
                ['titles:remove-from-network', 'Remove From Network'],
                ['titles:add-to-network', 'Add To Network'],
              )),
            },
            {
              permissions,
              action: () => handleSynchronizeWithLoadBoard(
                'allowOrForbidBookItNowCarrierRequest',
                GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW,
              ),
              text: G.getWindowLocale(...G.ifElse(
                allowedBookItNow,
                ['titles:forbid-book-it-now', 'Forbid Book it Now'],
                ['titles:allow-book-it-now', 'Allow Book it Now'],
              )),
            },
          ],
        };

        pageHeaderActions = R.append(truckerToolsActions, pageHeaderActions);
      }

      return pageHeaderActions;
    },
    [
      handleCreateWatchRequest,
      handleCreateWatchRequest,
      loadBoardSynchronizationParams,
      handleSynchronizeWithLoadBoard,
      handleSyncWithIntegrationRequest,
    ],
);

  return <PageHeaderActions count={0} pageHeaderActions={actions} />;
};

export default PageActions;
