import * as R from 'ramda';
import React from 'react';
import { put, fork, call, select, takeLatest } from 'redux-saga/effects';
// common
import { sendLogOutRequest, initSwitchBranchLogic } from '../../common/actions';
// components
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import PC from '../permission/role-permission';
import { makeSelectAuthorities } from '../permission/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
import {
  transformSearchCriteriaBeforeReportPost,
  transformSearchCriteriaBeforeFilterPost,
} from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { catchSaga, visitPageSaga } from '../../sagas';
// utilities
import routesMap from '../../utilities/routes';
import endpointsMap from '../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../utilities/http';
// feature branch
import * as A from './actions';
import * as S from './selectors';
import AddBranch from './components/add-branch';
import { ROOT_BRANCH_GUID, BRANCH_SETTING_FORM_SECTION } from './constants';
import {
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectCurrentBranchGuid,
  makeSelectTableTitleFilters,
  makeSelectBranchGuidByBranchId,
} from './selectors';
//////////////////////////////////////////////////

export function* initialBranchTreeSaga() {
  try {
    const endpoint = endpointsMap.branchesTree;

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.getAmousBranchesObjectAndSetToGlobalWindow(data);

      const tree = G.setBoolPropToEveryTreeItem(data, false, 'isOpened');

      yield put(A.receivedBranchesTreeSuccess(tree));

      return true;
    }
    yield call(G.handleFailResponse, res, 'initialBranchTreeSaga fail');

    return false;
  } catch (error) {
    yield call(G.handleException, error, 'initialBranchTreeSaga exception');

    return false;
  }
}

export function* initialBranchListSaga() {
  try {
    const currentBranchGuid = yield call(G.getCurrentBranchGuid);

    if (G.isNilOrEmpty(currentBranchGuid)) {
      yield put(closeLoader());

      return yield put(sendLogOutRequest());
    }

    const endpoint = endpointsMap.getCurrentBranchEndpoint(currentBranchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedSwitchBranchSuccess(data));

      yield put(A.getBranchMasterSettingsRequest(currentBranchGuid));

      return true;
    }

    yield put(closeLoader());

    yield call(G.handleFailResponse, res, 'initialBranchListSaga fail');

    return false;
  } catch (error) {
    yield call(G.handleException, error, 'initialBranchListSaga exception');

    return false;
  }
}

function* handleCreateBranchRequestSaga({ payload }: Object) {
  try {
    const endpoint = endpointsMap.branch;
    const callbackAction = R.path(['callbackAction'], payload);

    const data = R.assoc(
      GC.FIELD_CURRENCY,
      G.getOrElse(payload.data, GC.FIELD_CURRENCY, GC.DEFAULT_UI_CURRENCY),
      payload.data,
    );

    const options = { data };

    const res = yield call(sendRequest, 'post', endpoint, options);

    if (G.isResponseSuccess(res.status)) {
      yield put(closeModal());

      yield fork(initialBranchTreeSaga);

      yield put(A.receivedNewBranchSuccess(res.data));

      if (G.isFunction(callbackAction)) return yield put(callbackAction(data));

      yield call(G.goToRoute, routesMap.getEditBranchRoute(R.path(['data', GC.FIELD_GUID], res)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateBranchRequestSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateBranchRequestSaga exception');
  }
}

function* handleUpdateBranchRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { formSection, BranchSetting, CompanyDetails } = payload;

    const endpoint = G.getPropFromObject('branch', endpointsMap);

    const res = yield call(sendRequest, 'put', endpoint, { data: CompanyDetails });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const authorities = yield select(makeSelectAuthorities());

      yield fork(initialBranchTreeSaga);

      const rolePerm = G.checkStringsContains(
        R.of(Array, PC.ROLE_LIUBANCHYK_SUPER_ADMIN),
        authorities,
      );

      const master = R.or(
        R.propEq(ROOT_BRANCH_GUID, GC.FIELD_GUID, data),
        R.propEq(ROOT_BRANCH_GUID, GC.FIELD_PARENT_GUID, data),
      );

      const saveAndClose = G.isNilOrEmpty(formSection);
      const isSuperAdminAndMaster = R.and(master, rolePerm);

      if (isSuperAdminAndMaster) {
        if (saveAndClose) {
          yield put(A.updateBranchSettingsRequest(payload));
        } else {
          yield put(A.receivedBranchSettingSuccess(BranchSetting));
        }
      }

      yield put(A.receivedBranchSuccess(data));
      yield put(A.receivedUpdateBranchSuccess(data));

      if (R.and(saveAndClose, R.not(isSuperAdminAndMaster))) {
        yield call(G.goToRoute, GC.ROUTE_PATH_BRANCH_LIST);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateBranchRequestSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateBranchRequestSaga exception');
  }
}

function* handleSwitchBranchRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    if (G.isNilOrEmpty(payload)) {
      yield put(closeLoader());

      return yield put(sendLogOutRequest());
    }

    const endpoint = endpointsMap.getCurrentBranchEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(initSwitchBranchLogic(data));

      const branchGuid = G.getGuidFromObject(data);

      yield call(G.setCurrentBranchGuid, branchGuid);

      yield put(A.receivedSwitchBranchSuccess(data));

      yield put(A.getBranchMasterSettingsRequest(branchGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleSwitchBranchRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSwitchBranchRequestSaga exception');
  }
}

function* handleActivateBranchSaga({ payload }: string) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getActivateBranchEndpoint(payload);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(initialBranchTreeSaga);

      if (G.notEquals(G.getCurrentPathname(), GC.ROUTE_PATH_BRANCH_LIST)) {
        yield call(G.goToRoute, GC.ROUTE_PATH_BRANCH_LIST);
      } else {
        yield put(A.resetListAndPagination());
        yield put(A.getItemListRequest());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleActivateBranchSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleActivateBranchSaga exception');
  }
}

function* handleDeactivateBranchSaga({ payload }: string) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getDeactivateBranchEndpoint(payload);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(initialBranchTreeSaga);

      if (G.notEquals(G.getCurrentPathname(), GC.ROUTE_PATH_BRANCH_LIST)) {
        yield call(G.goToRoute, GC.ROUTE_PATH_BRANCH_LIST);
      } else {
        yield put(A.resetListAndPagination());
        yield put(A.getItemListRequest());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDeactivateBranchSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeactivateBranchSaga exception');
  }
}

function* handleSuspendBranchSaga({ payload }: string) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getSuspendBranchEndpoint(payload);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());

      yield call(initialBranchTreeSaga);

      if (R.not(R.equals(G.getCurrentPathname(), GC.ROUTE_PATH_BRANCH_LIST))) {
        yield call(G.goToRoute, routesMap.homePage);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleSuspendBranchSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(catchSaga, { error, name: 'handleSuspendBranchSaga' });
  }
}

function* handleUnsuspendBranchSaga({ payload }: string) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getUnsuspendBranchEndpoint(payload);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(initialBranchTreeSaga);

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());

      if (R.not(R.equals(G.getCurrentPathname(), GC.ROUTE_PATH_BRANCH_LIST))) {
        yield call(G.goToRoute, routesMap.homePage);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUnsuspendBranchSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(catchSaga, { error, name: 'handleUnsuspendBranchSaga' });
  }
}

function* handleDeleteBranchSaga({ payload }: string) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getDeleteBranchEndpoint(payload);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());

      yield call(initialBranchTreeSaga);

      if (R.not(R.equals(G.getCurrentPathname(), GC.ROUTE_PATH_BRANCH_LIST))) {
        yield call(G.goToRoute, routesMap.homePage);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteBranchSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteBranchSaga exception');
  }
}

function* handleRestoreBranchSaga({ payload }: string) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getRestoreBranchEndpoint(payload);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());

      yield call(initialBranchTreeSaga);
      if (R.not(R.equals(G.getCurrentPathname(), GC.ROUTE_PATH_BRANCH_LIST))) {
        yield call(G.goToRoute, routesMap.homePage);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleRestoreBranchSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRestoreBranchSaga exception');
  }
}

export function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    if (G.isNotEmpty(reqBody.fields)) {
      const requiredFields = [
        GC.FIELD_ACTIVE,
        GC.FIELD_DELETED,
        GC.FIELD_BRANCH_NAME,
        GC.FIELD_BRANCH_SUSPENDED,
        GC.FIELD_BRANCH_BRANCH_TYPE,
      ];

      reqBody.fields = G.addRequiredFields(reqBody.fields, requiredFields);
    }

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.branchList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, error, 'handleGetItemListSaga exception');
  }
}

function* handleBranchBillToRequestSaga(payload: string) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: payload },
    };

    const endpoint = endpointsMap.branchBillTo;

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchBillToLocationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleBranchBillToRequestSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleBranchBillToRequestSaga exception');
  }
}

function* handleGetAdditionalLocationListRequest(branchGuid: string) {
  try {
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.locationListByBranchGuid, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchAdditionalLocationListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAdditionalLocationListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetAdditionalLocationListRequest exception');
  }
}

function* handleCreateBranchBillToSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { data, method } = payload;

    const endpoint = endpointsMap.branchBillTo;

    const branchGuidByBranchId = yield select(makeSelectBranchGuidByBranchId());

    const res = yield call(sendRequest, method, endpoint, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedBranchBillToLocationSuccess(res.data));

      if (G.isTrue(data.saveAsTemplate)) {
        yield call(handleGetAdditionalLocationListRequest, branchGuidByBranchId);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateBranchBillToSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateBranchBillToSaga exception');
  }
}

function* handleDeleteBranchBillToSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getBranchBillToEndpoint(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchBillToLocationSuccess(null));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteBranchBillToSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteBranchBillToSaga exception');
  }
}

function* handleBranchPrimaryLocationRequestSaga(payload: string) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: payload },
    };

    const endpoint = endpointsMap.branchPrimaryLocation;

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchPrimaryLocationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleBranchPrimaryLocationRequestSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleBranchPrimaryLocationRequestSaga exception');
  }
}

function* handleCreateBranchPrimaryLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { data, method } = payload;

    const endpoint = endpointsMap.branchPrimaryLocation;

    const branchGuidByBranchId = yield select(makeSelectBranchGuidByBranchId());

    const res = yield call(sendRequest, method, endpoint, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedBranchPrimaryLocationSuccess(res.data));

      if (G.isTrue(data.saveAsTemplate)) {
        yield call(handleGetAdditionalLocationListRequest, branchGuidByBranchId);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateBranchPrimaryLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateBranchPrimaryLocationSaga exception');
  }
}

function* handleDeleteBranchPrimaryLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getBranchPrimaryLocationEndpoint(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchPrimaryLocationSuccess(null));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteBranchPrimaryLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteBranchPrimaryLocationSaga exception');
  }
}

function* handleBranchRequestSaga({ guid, payload }: string) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = R.or(payload, guid);

    if (G.isNilOrEmpty(branchGuid)) {
      yield put(closeLoader());

      return yield put(sendLogOutRequest());
    }

    const endpoint = endpointsMap.getCurrentBranchEndpoint(branchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const guid = R.path(['data', 'guid'], res);

      yield put(A.getBranchRefRequest(guid));
      yield put(A.receivedBranchSuccess(data));
      yield put(A.getBranchSettingRequest(guid));
      yield put(A.getBranchMasterSettingsRequest(guid));

      yield call(handleBranchBillToRequestSaga, guid);
      yield call(handleBranchPrimaryLocationRequestSaga, guid);
      yield call(handleGetAdditionalLocationListRequest, guid);
    } else {
      yield call(G.handleFailResponse, res, 'handleBranchRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleBranchRequestSaga exception');
  }
}

function* handleGetBranchSettingsRequestSaga({ payload }: string) {
  try {
    const endpoint = endpointsMap.branchSetting;

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (R.and(G.isResponseSuccess(status), G.isObject(data))) {
      yield put(A.receivedBranchSettingSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBranchSettingsRequestSaga fail', false);
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchSettingsRequestSaga exception');
  }
}

function* handleBranchRefRequestSaga({ payload }: Object) {
  try {
    const endpoint = endpointsMap.branchReferenceList;

    const params = { [GC.FIELD_BRANCH_GUID]: payload };

    const res = yield call(sendRequest, 'get', endpoint, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchRefSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleBranchRefRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleBranchRefRequestSaga exception');
  }
}

function* handleCreateBranchRefRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.branchReference,
      { data: payload },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
      yield put(A.createBranchRefSuccess(R.head(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateBranchRefRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateBranchRefRequestSaga exception');
  }
}

function* handleUpdateBranchRefRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.branchReference;

    const res = yield call(sendRequest, 'put', endpoint, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.updateBranchRefSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateBranchRefRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateBranchRefRequestSaga exception');
  }
}

function* handleUpdateBranchSettingsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { formSection, BranchSetting, CompanyDetails } = payload;

    const endpoint = G.getPropFromObject('branchSetting', endpointsMap);

    const res = yield call(sendRequest, 'put', endpoint, { data: BranchSetting });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchSettingSuccess(data));

      if (R.equals(formSection, BRANCH_SETTING_FORM_SECTION)) {
        yield put(A.receivedBranchSuccess(CompanyDetails));
      } else {
        yield call(G.goToRoute, GC.ROUTE_PATH_BRANCH_LIST);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateBranchSettingsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateBranchSettingsRequest exception');
  }
}

function* handleDeleteBranchRefRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getRemoveBranchRefEndpoint(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteBranchRefSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteBranchRefRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteBranchRefRequestSaga exception');
  }
}

function* handleGetDocumentListSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: {
        [GC.FIELD_PRIMARY_OBJECT_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchDocumentList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDocumentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDocumentListSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetDocumentListSaga exception');
  }
}

function* handleSaveBranchDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { update } = payload;

    const endpoint = G.ifElse(update, endpointsMap.branchDocumentUpdate, endpointsMap.branchDocument);

    const branchGuid = R.path(['data', GC.FIELD_PRIMARY_OBJECT_GUID], payload);

    const options = {
      data: G.makeDataForDocument(payload.data),
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDocumentListRequest(branchGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveBranchDocumentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSaveBranchDocumentSaga exception');
  }
}

function* handleDeleteBranchDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { branchGuid, documentGuid } = payload;

    const res = yield call(sendRequest, 'delete', endpointsMap.getBranchDocumentEndpoint(documentGuid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDocumentListRequest(branchGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteBranchDocumentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteBranchDocumentSaga exception');
  }
}

function* handleDownloadDocumentSaga({ payload }: Object) {
  try {
    const options = {
      params: payload,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequestWithQSParamsSerializer, 'get', endpointsMap.branchDocumentDownload, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, payload[GC.FIELD_FILE_NAME]);
    } else {
      yield call(G.handleFailResponse, G.convertArrayBufferFailResponse(res), 'handleDownloadDocumentsZipSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDownloadDocumentSaga exception');
  }
}

function* handleOpenAddBranchModal({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const { type, parentGuid } = payload;

    const callbackAction = R.path(['callbackAction'], payload);

    const modal = {
      p: '0px',
      component: (
        <AddBranch type={type} branchGuid={branchGuid} parentGuid={parentGuid} callbackAction={callbackAction} />
      ),
      options: {
        width: 550,
        height: 'auto',
        maxHeight: '95vh',
        title: G.getWindowLocale('titles:create-customer', 'Create Customer'),
      },
    };

    yield put(openModal(modal));
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleOpenAddBranchModal exception');
  }
}

function* getConfigsByNamesSaga({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;

    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield put(A.getConfigsByNamesSuccess(mappedConfigs));
    } else {
      yield call(G.handleFailResponse, res, 'getConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getConfigsByNamesSaga exception');
  }
}

function* getBranchConfigsByNamesSaga({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;

    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const configs = G.mapConfigValuesByName(data);

      yield put(A.getBranchConfigsByNamesSuccess({ configs, branchGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getBranchConfigsByNamesSaga exception');
  }
}

const changeBranchEndpointsMap = {
  route: endpointsMap.changeRouteBranch,
  cloDivision: endpointsMap.changeCloDivision,
  [GC.LOAD_TYPE_CLO]: endpointsMap.changeCloBranch,
  [GC.LOAD_TYPE_TEL]: endpointsMap.changeTelBranch,
};

function* handleChangeEntityBranch({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { data, type, callback } = payload;

    const options = { data };

    const res = yield call(sendRequest, 'put', changeBranchEndpointsMap[type], options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (G.isFunction(callback)) {
        const branchGuid = R.path(['data', 'newEnterpriseGuid'], payload);

        callback(branchGuid);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeEntityBranch fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeEntityBranch exception');
  }
}

function* handleShareBranchSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.shareBranch, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleShareBranchSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleShareBranchSaga exception');
  }
}

function* handleShareBranchesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.shareBranches, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleShareBranchesSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleShareBranchesSaga exception');
  }
}

function* handleUnshareBranchesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.unshareBranches, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUnshareBranchesSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUnshareBranchesSaga exception');
  }
}

function* handleGetImportMappersSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.branchImportMappers);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchImportMappersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetImportMappersSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetImportMappersSaga exception');
  }
}

function* handleCreateBranchAdditionalLocationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const method = G.ifElse(
      G.isNilOrEmpty(payload.version),
      'post',
      'put',
    );

    const res = yield call(sendRequest, method, endpointsMap.location, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedBranchAdditionalLocationSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateBranchAdditionalLocationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleCreateBranchAdditionalLocationRequest exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const filterParams = yield select(makeSelectFilterParams());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { fields, fileType, orderFields, searchCriteria } = payload;

    const reqBody = {
      fields,
      orderFields,
      currentEnterprise,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const params = { format: fileType };

    const sendData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const options = {
      params,
      data: sendData,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.branchExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* handleDeleteBranchAdditionalLocationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      data: R.of(Array, payload),
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.location, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (G.isNotNilAndNotEmpty(R.prop(payload, data))) {
        const message = R.join(', ', R.prop(payload, data));

        yield call(G.showToastrMessage, 'info', message);
      } else {
        yield put(A.deleteBranchAdditionalLocationSuccess(payload));

        yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteBranchAdditionalLocationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleDeleteBranchAdditionalLocationRequest exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.BRANCH_REPORT,
  A,
  handleGetItemListSaga,
  { S, createUpdateReportSuccessCallback },
);

function* getAvailableCommissionAssignmentListRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.commissionAssigneeAvailableList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableCommissionAssignmentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getUserOptionsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getUserOptionsRequest exception');
  }
}

function* generateExcelWithCreditLimitRequest() {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectBranchGuidByBranchId());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloListExportAddedToCredit, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'generateExcelWithCreditLimitRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'generateExcelWithCreditLimitRequest exception');
  }
}

function* getBranchMasterSettingsRequest({ payload }: string) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: payload },
    };

    const endpoint = endpointsMap.branchMasterSetting;

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedBranchMasterSettingsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchMasterSettingsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchMasterSettingsRequest exception');
  }
}

// visit
function* handleVisitBranchListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_BRANCH_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    yield put(A.setIgnorePromptStatus(false));
    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_BRANCH));

    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });

    if (G.isTrue(pageVisited)) {
      yield put(A.resetListAndPagination());
    }

    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* handleVisitBranchNewSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_BRANCH_CREATE_PAGE);

    break;
  }
}

const CONFIGS_ARR = [
  GC.GENERAL_BRANCH_SUBTYPE,
  GC.TEMPLATES_LOCATION_TYPE,
  GC.COMMUNICATION_BRANCH_DOCUMENT_TYPE,
];

function* handleVisitBranchEditSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_ROLE_EDIT_PAGE);

    yield put(A.getDocumentListRequest(R.prop(GC.FIELD_GUID, payload)));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(A.getConfigsByNamesRequest({
      branchGuid,
      names: R.join(',', CONFIGS_ARR),
    }));

    yield put(A.getAvailableCommissionAssignmentListRequest(G.getGuidFromObject(payload)));

    yield call(handleGetImportMappersSaga);

    yield put(A.resetBranchSettingAndCompanyDetails());
    yield put(A.receivedBranchBillToLocationSuccess(null));
    yield put(A.receivedBranchPrimaryLocationSuccess(null));

    yield call(handleBranchRequestSaga, payload);

    break;
  }
}

function* handleVisitBranchDetailSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_BRANCH_DETAILS_PAGE);

    break;
  }
}

function* branchesWatcherSaga() {
  yield takeLatest(GC.VISIT_BRANCH_EDIT_PAGE, handleVisitBranchEditSaga);
  yield takeLatest(GC.VISIT_BRANCH_CREATE_PAGE, handleVisitBranchNewSaga);
  yield takeLatest(GC.VISIT_BRANCH_LIST_PAGE, handleVisitBranchListPageSaga);
  yield takeLatest(GC.VISIT_BRANCH_DETAILS_PAGE, handleVisitBranchDetailSaga);
  //
  yield takeLatest(A.switchBranchRequest, handleSwitchBranchRequestSaga);
  yield takeLatest(A.getBranchSettingRequest, handleGetBranchSettingsRequestSaga);
  yield takeLatest(A.getBranchMasterSettingsRequest, getBranchMasterSettingsRequest);
  //
  yield takeLatest(A.shareBranch, handleShareBranchSaga);
  yield takeLatest(A.shareBranches, handleShareBranchesSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.getBranchRequest, handleBranchRequestSaga);
  yield takeLatest(A.sendDeleteRequest, handleDeleteBranchSaga);
  yield takeLatest(A.unshareBranches, handleUnshareBranchesSaga);
  yield takeLatest(A.sendRestoreRequest, handleRestoreBranchSaga);
  yield takeLatest(A.sendSuspendRequest, handleSuspendBranchSaga);
  yield takeLatest(A.downloadDocument, handleDownloadDocumentSaga);
  yield takeLatest(A.changeEntityBranch, handleChangeEntityBranch);
  yield takeLatest(A.sendActivateRequest, handleActivateBranchSaga);
  yield takeLatest(A.deleteDocument, handleDeleteBranchDocumentSaga);
  yield takeLatest(A.sendUnsuspendRequest, handleUnsuspendBranchSaga);
  yield takeLatest(A.getBranchRefRequest, handleBranchRefRequestSaga);
  yield takeLatest(A.getConfigsByNamesRequest, getConfigsByNamesSaga);
  yield takeLatest(A.sendDeactivateRequest, handleDeactivateBranchSaga);
  yield takeLatest(A.getDocumentListRequest, handleGetDocumentListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.updateBranchRequest, handleUpdateBranchRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.openAddBranchModalRequest, handleOpenAddBranchModal);
  yield takeLatest(A.createNewBranchRequest, handleCreateBranchRequestSaga);
  yield takeLatest(A.saveBranchDocumentRequest, handleSaveBranchDocumentSaga);
  yield takeLatest(A.updateBranchRefRequest, handleUpdateBranchRefRequestSaga);
  yield takeLatest(A.deleteBranchRefRequest, handleDeleteBranchRefRequestSaga);
  yield takeLatest(A.createBranchRefRequest, handleCreateBranchRefRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.getBranchConfigsByNamesRequest, getBranchConfigsByNamesSaga);
  yield takeLatest(A.updateBranchSettingsRequest, handleUpdateBranchSettingsRequest);
  yield takeLatest(A.createBranchBillToLocationRequest, handleCreateBranchBillToSaga);
  yield takeLatest(A.deleteBranchBillToLocationRequest, handleDeleteBranchBillToSaga);
  yield takeLatest(A.generateExcelWithCreditLimitRequest, generateExcelWithCreditLimitRequest);
  yield takeLatest(A.deleteBranchPrimaryLocationRequest, handleDeleteBranchPrimaryLocationSaga);
  yield takeLatest(A.createBranchPrimaryLocationRequest, handleCreateBranchPrimaryLocationSaga);
  yield takeLatest(A.deleteBranchAdditionalLocationRequest, handleDeleteBranchAdditionalLocationRequest);
  yield takeLatest(A.createBranchAdditionalLocationRequest, handleCreateBranchAdditionalLocationRequest);
  yield takeLatest(A.getAvailableCommissionAssignmentListRequest, getAvailableCommissionAssignmentListRequest);
}

export default branchesWatcherSaga;
