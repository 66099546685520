import React from 'react';
import * as R from 'ramda';
// features
import ProfilePhoto from '../../profile/components/profile-photo';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const FleetProfilePhoto = (props: Object) => {
  const { type, guid, shared, userGuid } = props;

  let options = {
    userGuid,
    withActions: true,
    defaultPhotoUrl: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/022288f7-2895-9c91-53e9-8976df3d7c9a.png',
  };

  if (G.notEquals(type, 'driver')) {
    const controllersMap = {
      truck: 'fleet/truck',
      vendor: 'fleetVendor',
      trailer: 'fleet/trailer',
    };

    const controllerType = controllersMap[type];

    const endpoints = {
      profilePhotoUrl: endpointsMap.getFleetProfilePhoto(controllerType),
      addProfilePhoto: endpointsMap.addFleetProfilePhoto(controllerType),
      removeProfilePhoto: endpointsMap.removeFleetProfilePhoto(controllerType),
    };

    options = {
      ...props,
      endpoints,
      withoutUpload: shared,
      withActions: R.not(shared),
      [GC.FIELD_TRUCK_GUID]: G.ifElse(R.equals(type, 'truck'), guid),
      [GC.FIELD_TRAILER_GUID]: G.ifElse(R.equals(type, 'trailer'), guid),
    };
  }

  return <ProfilePhoto {...options} />;
};

export default FleetProfilePhoto;
