import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { Form, FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const getFields = (locationOptions: Array, singleSelect: boolean=false) => [
  {
    width: 600,
    afterTop: 13,
    afterRight: 13,
    labelPl: '0px',
    labelWidth: '0px',
    jc: 'space-between',
    flexDirection: 'row',
    inputWrapMargin: '4px 0',
    options: locationOptions,
    fieldName: 'locationGuids',
    type: G.ifElse(singleSelect, 'select', 'multiselect'),
  },
];

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SELECT_CROSS_DOCK_LOCATIONS',
    mapPropsToValues: (props: Object) => ({ locationGuids: props.initialValues }),
    handleSubmit: (values: Object, { props }: Object) => props.setLocations(values.locationGuids),
  }),
  pure,
);

const CrossDockLocationForm = enhance((props: Object) => (
  <Form
    maxHeight='auto'
    onSubmit={props.handleSubmit}
  >
    <Flex flexDirection='column'>
      <FieldsetComponent
        {...props}
        justifyContent='space-between'
        fields={getFields(props.locationOptions, props.singleSelect)} />
      <FormFooter
        closeModal={props.closeModal}
        boxStyles={{ p: 10, justifyContent: 'space-around' }} />
    </Flex>
  </Form>
));

export default CrossDockLocationForm;
