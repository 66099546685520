import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
// import * as GC from '../../../constants';
// ui
import { Box, Flex, ActionButton, scrollableContainerCss4px } from '../../../ui';
// feature dispatch-planner
import { LocationInfo } from './stop';
import CrossDockLocationForm from './cross-dock-location-form';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpenSelectLocationForm: (props: Object) => () => {
      const {
        locations,
        openModal,
        closeModal,
        setLocations,
        locationOptions } = props;
      const component = (
        <CrossDockLocationForm
          closeModal={closeModal}
          initialValues={locations}
          setLocations={setLocations}
          locationOptions={locationOptions} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-location', 'Select Locations'),
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const CrossDockLocationSelect = enhance((props: Object) => (
  <Flex>
    <ActionButton
      mx={16}
      p='2px 6px'
      height={24}
      fontSize={10}
      bgColor='none'
      background='none'
      border='1px solid'
      borderRadius='5px'
      textTransform='uppercase'
      textColor={G.getTheme('colors.dark.blue')}
      borderColor={G.getTheme('colors.dark.blue')}
      onClick={props.handleOpenSelectLocationForm}
    >
      {G.getWindowLocale('titles:select-location', 'Select Locations')}
    </ActionButton>
    {
      G.isNotNilAndNotEmpty(props.locations) &&
      <Flex
        overflow='auto'
        maxWidth='calc(100vw - 1000px)'
        css={scrollableContainerCss4px}
      >
        {props.locations.map((templateId: string, i: number) => (
          <PopperComponent
            key={i}
            type='hover'
            position='right'
            activeStyles={{ zIndex: 21 }}
            borderColor={G.getTheme('listActions.borderColor')}
            content={<LocationInfo width='100%' location={R.path(['crossDockLocations', templateId], props)} />}
          >
            <Box
              mr='6px'
              p='2px 6px'
              fontSize={12}
              borderRadius='3px'
              bg={G.getTheme('colors.light.lightGrey')}
            >
              {templateId}
            </Box>
          </PopperComponent>
        ))}
      </Flex>
    }
  </Flex>
));

export default CrossDockLocationSelect;
