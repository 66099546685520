// feature fleet-profile
export { referenceSettings } from './reference';
export { getInspectionsSettings } from './inspection';
export { fleetServiceIssueSettings } from './service-issue';
export { equipmentServiceSettings } from './equipment-service';
export { contactSettings, vendorContactSettings } from './contacts';
export { truckLocationSettings, trailerLocationSettings } from './location';
export { truckOwnershipSettings, trailerOwnershipSettings } from './ownership';
export { truckInsuranceSettings, trailerInsuranceSettings } from './insurance';
export { compensationSettings, vendorCompensationSettings } from './compensation';
export { payToLocationSettings, vendorPayToLocationsSettings } from './pay-to-location';
export { truckSpecificationSettings, trailerSpecificationSettings } from './specification';
export { payrollAccessorialSettings, vendorPayrollAccessorialSettings } from './payroll-accessorial';
export { integrationSettings, truckIntegrationOptions, trailerIntegrationOptions } from './integration';
export { truckEquipmentComponentSettings, trailerEquipmentComponentSettings } from './equipment-component';
export { monthlyPayrollDeductionSettings, vendorMonthlyPayrollDeductionSettings } from './monthly-payroll-deduction';
export { accessorialSettings, vendorAccessorialSettings, vendorCompensationAccessorialSettings } from './accessorial';
export { documentSettings, truckDocumentSettings, vendorDocumentSettings, trailerDocumentSettings } from './documents';
export {
  lastKnownLocationSettings,
  truckLastKnownLocationSettings,
  trailerLastKnownLocationSettings,
} from './last-known-location';
//////////////////////////////////////////////////
