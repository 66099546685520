import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import Insurances from '../../../../components/insurance';
import { FormButtons } from '../../../../components/form-buttons';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTitleComponent } from '../../../../components/form-group-title';
// features
import { AuthWrapper } from '../../../permission';
import References from '../../../reference/components/reference';
import { IntegrationInfo, IntegrationAudit } from '../../../carrier/components/general-tab';
// forms
import { Form, TabFormWrapper, FormGroupWrapper } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import ContactsFormGroupTable from '../../components/contacts-form-group-table';
import PayToLocationFormGroupTable from '../../components/pay-to-location-form-group-table';
// feature fleet/vendor
import { renderField } from './render-field';
import FleetAssignmentFormGroupTable from './fleet-assignment';
import { accountingFields, editVendorFieldSettings } from '../settings/fields-settings';
import {
  makeSelectInsuranceList,
  makeSelectVendorRefList,
  makeSelectVendorContactList,
  makeSelectVendorPayToLocation,
  makeSelectIntegrationInfoList,
  makeSelectIntegrationAuditList,
} from '../selectors';
import {
  updateVendorRequest,
  updateVendorContactRequest,
  removeVendorContactRequest,
  createVendorContactRequest,
  deleteVendorReferenceRequest,
  createVendorReferenceRequest,
  updateVendorReferenceRequest,
  createVendorInsuranceRequest,
  updateVendorInsuranceRequest,
  removeVendorInsuranceRequest,
  removeVendorPayToLocationRequest,
  createOrUpdateVendorPayToLocationRequest,
} from '../actions';
//////////////////////////////////////////////////

const renderReferences = (props: Object) => (
  <References
    openModal={props.openModal}
    references={props.references}
    closeModal={props.closeModal}
    scope={GC.REF_SCOPE_NAME_FLEET_VENDOR}
    handleCreateReference={props.handleCreateReference}
    handleUpdateReference={props.handleUpdateReference}
    handleRemoveReference={props.handleDeleteReference}
  />
);

const enhance = compose(
  withHandlers({
    handleFormSubmit: ({ updateVendorRequest }: Object) => (values: Object) => {
      updateVendorRequest({ values, updateAndClose: true });
    },
    handleUpdate: (props: Object) => () => {
      const { touch, errors, formValues, updateVendorRequest } = props;

      if (R.isEmpty(props.errors)) {
        updateVendorRequest({ values: formValues, updateAndClose: false });
      } else {
        R.forEachObjIndexed((_: string, key: string) => touch(key), errors);
      }
    },
    handleCreateReference: (props: Object) => (values: Object) => {
      const reqBody = {
        values,
        selectedList: R.of(Array, R.path(['initialValues', GC.FIELD_GUID], props)),
      };
      props.createVendorReferenceRequest(reqBody);
    },
    handleUpdateReference: ({ updateVendorReferenceRequest }: Object) => (reqBody: Object) => (
      updateVendorReferenceRequest(reqBody)
    ),
    handleDeleteReference: (props: Object) => (guid: Object) => {
      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_REFERENCES, guid, GC.FIELD_NAME], props)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.closeModal();
                props.deleteVendorReferenceRequest(guid);
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

const renderButtons = ({ handleUpdate }: Object) => ([
  {
    width: 'auto',
    type: 'button',
    action: handleUpdate,
    btnText: G.getWindowLocale('actions:update', 'Update'),
  },
]);

const EditDriverDetailsComponent = (props: Object) => (
  <TabFormWrapper>
    <Form minHeight='max-content' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
      {
        [editVendorFieldSettings, accountingFields].map(({ name, fields, title, permissions }: Object) => (
          <AuthWrapper display='block' key={name} has={permissions}>
            <FormGroupWrapper isOpened={R.path(['collapsedGroup', name], props)}>
              <FormGroupTitleComponent
                withArrowDown={true}
                text={G.getWindowLocale(title)}
                isOpened={R.path(['collapsedGroup', name], props)}
                onToggleFormGroup={() => props.handleToggleFormGroup(name)}
              />
              {
                fields.map((field: Object, index: number) => {
                  if (G.showFormField(field.showField, props.formValues)) return renderField(props, field, index);

                  return null;
                })
              }
            </FormGroupWrapper>
          </AuthWrapper>
        ))
      }
      <FormButtons
        {...props}
        width='auto'
        buttons={renderButtons(props)}
      />
    </Form>
    <FleetAssignmentFormGroupTable
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
      vendorGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
      branchGuid={R.path(['initialValues', GC.BRANCH_GUID], props)}
    />
    <PayToLocationFormGroupTable
      openModal={props.openModal}
      closeModal={props.closeModal}
      payToLocation={props.payToLocation}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
      removePayToLocation={props.removeVendorPayToLocationRequest}
      addOrEditPayToLocation={(values: Object) => {
        const method = G.ifElse(
          G.isNotNilAndNotEmpty(props.payToLocation),
          'put',
          'post',
        );
        props.createOrUpdateVendorPayToLocationRequest({ method, values });
      }}
    />
    <ContactsFormGroupTable
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      createAction={props.createVendorContactRequest}
      updateAction={props.updateVendorContactRequest}
      removeAction={props.removeVendorContactRequest}
      entities={R.pathOr([], [GC.FIELD_CONTACTS], props)}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
    <Insurances
      {...props}
      objKey={GC.FIELD_FLEET_VENDOR_GUID}
      updateAction={props.updateVendorInsuranceRequest}
      createAction={props.createVendorInsuranceRequest}
      removeAction={props.removeVendorInsuranceRequest}
      objGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
    />
    {renderReferences(props)}
    <IntegrationInfo
      entities={props.integrationInfoList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
    <IntegrationAudit
      entities={props.integrationAuditList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
  </TabFormWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  references: makeSelectVendorRefList(state),
  insurances: makeSelectInsuranceList(state),
  contacts: makeSelectVendorContactList(state),
  payToLocation: makeSelectVendorPayToLocation(state),
  integrationInfoList: makeSelectIntegrationInfoList(state),
  integrationAuditList: makeSelectIntegrationAuditList(state),
});

export default connect(mapStateToProps, {
  updateVendorRequest,
  updateVendorContactRequest,
  removeVendorContactRequest,
  createVendorContactRequest,
  deleteVendorReferenceRequest,
  createVendorReferenceRequest,
  updateVendorReferenceRequest,
  createVendorInsuranceRequest,
  updateVendorInsuranceRequest,
  removeVendorInsuranceRequest,
  removeVendorPayToLocationRequest,
  createOrUpdateVendorPayToLocationRequest,
})(enhance(EditDriverDetailsComponent));
