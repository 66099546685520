import styled from 'styled-components';
// helpers
import * as G from '../../helpers';
// hocs
import { StyledForm, FormWrapper } from '../auth/ui';
// ui
import { Flex, AbsoluteBox, RelativeBox, RelativeFlex } from '../../ui';
//////////////////////////////////////////////////

export const AmousBg = 'https://amous-front-dev.s3.amazonaws.com/blue-background-new.jpg';

export const FormDecline = styled(StyledForm)`
  border-radius: 6px;
  background-image: linear-gradient(119deg, #350828, #2c594d);
`;

export const ChatWrapper = styled(AbsoluteBox)`
  top: 0px;
  left: 0px;
  @media (min-width: 768px) {
    right: 134px;
    bottom: 332px;
    border: 1px solid;
    border-radius: ${({ borderRadiusMedia }: Object) => borderRadiusMedia || '20px'};
  }
`;

export const CarrierStatusMessageFormWrapper = styled(RelativeBox)`
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url(${({ bgImage }: Object) =>
    bgImage || AmousBg}
  );
`;

export const CarrierDetailFormWrapper = styled(FormWrapper)`
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  background-image: none;
  position: ${({ position }: Object) => position};
  flex-wrap: ${({ flexWrap }: Object) => flexWrap};
  display: ${({ display }: Object) => display || 'flex'};
  background-color: ${() => G.getTheme('colors.light.black')};
  flex-direction: ${({ flexDirection }: Object) => flexDirection};
  align-items: ${({ alignItems }: Object) => alignItems || 'center'};
  justify-content: ${({ justifyContent }: Object) => justifyContent || 'normal'};
`;

export const ScrollableWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  overflow-y: auto;
  position: absolute;
  padding: 30px 15px;
  height: max-content;
  flex-direction: column;
`;

export const MobileForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const ChatMessageWrapper = styled(RelativeFlex)`
  border-top: solid 1px ${({ borderColorTop }: Object) => borderColorTop};
  & textarea {
    width: 100%;
    border: none;
    height: 100%;
    padding: 2px;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
    &:focus {
      box-shadow: none;
    }
  }
`;

export const ResponseBox = styled(Flex)`
  padding: 30px 48px;
  position: relative;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  color: ${({ color }: Object) => color};
  &::after {
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
    width: 100%;
    opacity: 0.8;
    height: 100%;
    position: absolute;
    border: 3.5px solid;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-color: ${({ color }: Object) => color};
    background-image: ${() =>
      `linear-gradient(119deg, ${G.getTheme('colors.nearDark')}, ${G.getTheme('colors.nearGray')})`
    };
  }
`;

export const Button = styled.button`
  outline: 0;
  width: 300px;
  height: 30px;
  border: none;
  display: flex;
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
  min-width: 250px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  border-radius: 20px;
  font-weight: bolder;
  align-items: center;
  text-decoration: none;
  vertical-align: middle;
  justify-content: center;
  box-shadow: 5px 8px 9px -2px rgb(21,21,21);
  color: ${() => G.getTheme('colors.white')};
  background: ${() => G.getTheme('colors.cobalt')};
`;

export const ArrowUp = styled.div`
  position: absolute;
  width: 16px;
  height: 8px;
  left: 388px;
  top: 207px;

  background: #015295;
  border-radius: 1px;
  transform: rotate(180deg);
`;

export const DeclineDispatchForm = styled.form`
  width: 100%;
`;
