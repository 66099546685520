import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ITEM_DIMENSIONS_OPTIONS } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// feature fleet-profile
import { TableCell } from '../components/table-cells';
import SpecificationForm from '../forms/specification';
//////////////////////////////////////////////////

const getUomLabel = (uom: string) => R.compose(
  R.propOr('', GC.FIELD_LABEL),
  R.find(R.propEq(uom, GC.FIELD_VALUE)),
)(ITEM_DIMENSIONS_OPTIONS);

const makeDimensionsDisplayedValue = R.compose(
  R.join(' x '),
  R.map(({ uom, value }: Object) => `${value} ${uom}`),
  R.filter(({ value }: Object) => G.isNotNilAndNotEmpty(value)),
);

const truckColumnSettings = {
  [GC.FIELD_TARE_WEIGHT]: {
    width: 150,
    name: 'titles:tare-weight',
    customComponent: (row: Object) => {
      const { tareWeight, tareWeightType } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(tareWeight)) return null;

      return <TableCell text={`${tareWeight} ${tareWeightType}`} />;
    },
  },
  [GC.FIELD_GVWT]: {
    width: 150,
    name: 'titles:gvwr',
    customComponent: (row: Object) => {
      const { gvwt, gvwtWeightType } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(gvwt)) return null;

      return <TableCell text={`${gvwt} ${gvwtWeightType}`} />;
    },
  },
  [GC.FIELD_SPECIFICATION_CLASS]: {
    name: 'titles:class',
  },
  [GC.FIELD_AXLE_COUNT]: {
    width: 100,
    name: 'titles:axle-count',
  },
  [GC.FIELD_AXLE_CONFIG]: {
    width: 100,
    name: 'titles:axle-config',
    customComponent: (row: Object) => {
      const axleConfig = R.path(['data', GC.FIELD_AXLE_CONFIG], row);

      if (G.isNilOrEmpty(axleConfig)) return null;

      const iconsMap = {
        '1-2': 'truckTwo',
        '1-1': 'truckOne',
      };

      return I[R.prop(axleConfig, iconsMap)]();
    },
  },
  truckDimensions: {
    width: 250,
    name: 'titles:truck-dimensions',
    customComponent: (row: Object) => {
      const { width, length, height, widthUom, lengthUom, heightUom } = R.propOr({}, 'data', row);

      if (G.isAllNilOrEmpty([width, length, height])) return null;

      const dimensions = [
        { value: length, uom: getUomLabel(lengthUom) },
        { value: width, uom: getUomLabel(widthUom) },
        { value: height, uom: getUomLabel(heightUom) },
      ];

      return <TableCell text={makeDimensionsDisplayedValue(dimensions)} />;
    },
  },
  doorDimensions: {
    width: 200,
    name: 'titles:door-dimensions',
    customComponent: (row: Object) => {
      const {
        doorWidth,
        doorHeight,
        doorWidthUom,
        doorHeightUom,
      } = R.pathOr({}, ['data', GC.FIELD_DOOR_DIMENSION], row);

      if (G.isAllNilOrEmpty([doorWidth, doorHeight])) return null;

      const doorDimensions = [
        { value: doorWidth, uom: getUomLabel(doorWidthUom) },
        { value: doorHeight, uom: getUomLabel(doorHeightUom) },
      ];

      return <TableCell text={makeDimensionsDisplayedValue(doorDimensions)} />;
    },
  },
  [GC.FIELD_SLEEPER]: {
    width: 100,
    type: 'boolean',
    name: 'titles:sleeper',
  },
  [GC.FIELD_MPG_HIGH]: {
    width: 100,
    name: 'titles:mpg-high',
  },
  [GC.FIELD_MPG_LOW]: {
    width: 100,
    name: 'titles:mpg-low',
  },
  [GC.FIELD_FUEL_TYPE]: {
    width: 100,
    name: 'titles:fuel-type',
  },
  fifthWheelHeight: {
    width: 150,
    name: 'titles:5th-wheel-height',
    customComponent: (row: Object) => {
      const {
        fifthWheelWidth,
        fifthWheelHeight,
        fifthWheelWidthType,
        fifthWheelHeightType,
      } = R.propOr({}, 'data', row);

      if (G.isAllNilOrEmpty([fifthWheelWidth, fifthWheelHeight])) return null;

      const fifthWheelDimensions = [
        { value: fifthWheelWidth, type: fifthWheelWidthType },
        { value: fifthWheelHeight, type: fifthWheelHeightType },
      ];

      return <TableCell text={makeDimensionsDisplayedValue(fifthWheelDimensions)} />;
    },
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const trailerColumnSettings = {
  [GC.FIELD_TARE_WEIGHT]: {
    width: 150,
    name: 'titles:tare-weight',
    customComponent: (row: Object) => {
      const { tareWeight, weightTareType } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(tareWeight)) return null;

      return <TableCell text={`${tareWeight} ${weightTareType}`} />;
    },
  },
  [GC.FIELD_GVWT]: {
    width: 150,
    name: 'titles:gvwr',
    customComponent: (row: Object) => {
      const { gvwt, weightTypeGVWT } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(gvwt)) return null;

      return <TableCell text={`${gvwt} ${weightTypeGVWT}`} />;
    },
  },
  [GC.FIELD_SPECIFICATION_CLASS]: {
    name: 'titles:class',
    customComponent: (row: Object) => {
      const specificationClass = R.path(['data', GC.FIELD_SPECIFICATION_CLASS, GC.FIELD_DISPLAYED_VALUE], row);

      if (G.isNilOrEmpty(specificationClass)) return null;

      return <TableCell text={specificationClass} />;
    },
  },
  [GC.FIELD_AXLE_COUNT]: {
    width: 100,
    name: 'titles:axle-count',
  },
  [GC.FIELD_AXLE_CONFIG]: {
    width: 100,
    name: 'titles:axle-config',
    customComponent: (row: Object) => {
      const axleConfig = R.path(['data', GC.FIELD_AXLE_CONFIG], row);

      if (G.isNilOrEmpty(axleConfig)) return null;

      const iconsMap = {
        '0-2': 'trailerTwo',
        '0-3': 'trailerThree',
        '0-4': 'trailerFour',
        '3-2': 'trailerThreeTwo',
      };

      return I[R.prop(axleConfig, iconsMap)]();
    },
  },
  intDimensions: {
    width: 250,
    name: 'titles:int-dimensions',
    customComponent: (row: Object) => {
      const {
        width,
        length,
        height,
        widthUOM,
        lengthUOM,
        heightUOM,
      } = R.pathOr({}, ['data', GC.FIELD_INTERNAL_DIMENSION], row);

      if (G.isAllNilOrEmpty([width, length, height])) return null;

      const dimensions = [
        { value: length, uom: lengthUOM },
        { value: width, uom: widthUOM },
        { value: height, uom: heightUOM },
      ];

      return <TableCell text={makeDimensionsDisplayedValue(dimensions)} />;
    },
  },
  extDimensions: {
    width: 250,
    name: 'titles:ext-dimensions',
    customComponent: (row: Object) => {
      const {
        width,
        length,
        height,
        widthUOM,
        lengthUOM,
        heightUOM,
      } = R.pathOr({}, ['data', GC.FIELD_EXTERNAL_DIMENSION], row);

      if (G.isAllNilOrEmpty([width, length, height])) return null;

      const dimensions = [
        { value: length, uom: lengthUOM },
        { value: width, uom: widthUOM },
        { value: height, uom: heightUOM },
      ];

      return <TableCell text={makeDimensionsDisplayedValue(dimensions)} />;
    },
  },
  [GC.FIELD_DOOR_TYPE]: {
    name: 'titles:door-type',
    customComponent: (row: Object) => {
      const doorType = R.path(['data', GC.FIELD_DOOR_TYPE, GC.FIELD_DISPLAYED_VALUE], row);

      if (G.isNilOrEmpty(doorType)) return null;

      return <TableCell text={doorType} />;
    },
  },
  doorDimensions: {
    width: 200,
    name: 'titles:door-dimensions',
    customComponent: (row: Object) => {
      const {
        doorWidth,
        doorHeight,
        doorWidthUOM,
        doorHeightUOM,
      } = R.pathOr({}, ['data', GC.FIELD_DOOR_DIMENSION], row);

      if (G.isAllNilOrEmpty([doorWidth, doorHeight])) return null;

      const doorDimensions = [
        { value: doorWidth, uom: doorWidthUOM },
        { value: doorHeight, uom: doorHeightUOM },
      ];

      return <TableCell text={makeDimensionsDisplayedValue(doorDimensions)} />;
    },
  },
  [GC.FIELD_BODY_MATERIAL]: {
    name: 'titles:body-material',
    customComponent: (row: Object) => {
      const bodyMaterial = R.path(['data', GC.FIELD_BODY_MATERIAL, GC.FIELD_DISPLAYED_VALUE], row);

      if (G.isNilOrEmpty(bodyMaterial)) return null;

      return <TableCell text={bodyMaterial} />;
    },
  },
  [GC.FIELD_DECK_HEIGHT]: {
    name: 'titles:deck-height',
    customComponent: (row: Object) => {
      const { deckHeight, deckHeightType } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(deckHeight)) return null;

      return `${deckHeight} ${deckHeightType}`;
    },
  },
  [GC.FIELD_CUBE]: {
    name: 'titles:cube',
    customComponent: (row: Object) => {
      const { cube, cubeType } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(cube)) return null;

      return `${cube} ${cubeType}`;
    },
  },
  [GC.FIELD_BULK_COMP_COUNT]: {
    name: 'titles:bulk-compartment-count',
  },
  [GC.FIELD_BULK_COMP_VOLUME]: {
    name: 'titles:bulk-compartment-volume',
    customComponent: (row: Object) => {
      const { bulkCompVolume, bulkCompVolumeType } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(bulkCompVolume)) return null;

      return `${bulkCompVolume} ${bulkCompVolumeType}`;
    },
  },
  [GC.FIELD_TEMPERATURE_CONTROL]: {
    width: 150,
    type: 'boolean',
    name: 'titles:temperature-control',
  },
  // refrigeration
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_REFRIGERATION_MANUFACTURER}`]: {
    name: ['titles:refrigeration', 'titles:manufacturer'],
    customComponent: (row: Object) => {
      const refrigerationManufacturer = R.path(
        ['data', GC.FIELD_REFRIGERATION, GC.FIELD_REFRIGERATION_MANUFACTURER, GC.FIELD_DISPLAYED_VALUE],
        row,
      );

      if (G.isNilOrEmpty(refrigerationManufacturer)) return null;

      return <TableCell text={refrigerationManufacturer} />;
    },
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_OUTPUT_YEAR}`]: {
    name: ['titles:refrigeration', 'titles:year'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_OUTPUT_YEAR], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_MODEL}`]: {
    name: ['titles:refrigeration', 'titles:model'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_MODEL], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_SERIES}`]: {
    name: ['titles:refrigeration', 'titles:series'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_SERIES], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_SERIAL_NUMBER}`]: {
    name: ['titles:refrigeration', 'titles:number'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_SERIAL_NUMBER], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_COOLANT_TYPE}`]: {
    name: ['titles:refrigeration', 'titles:coolant-type'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_COOLANT_TYPE], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_CARB_ID}`]: {
    name: ['titles:refrigeration', 'titles:carb-id'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_CARB_ID], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_TRUATCM_ID}`]: {
    name: ['titles:refrigeration', 'titles:carb-id'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_TRUATCM_ID], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_TEMP_RANGE_FROM}`]: {
    name: ['titles:refrigeration', 'titles:temperature-range'],
    customComponent: (row: Object) => {
      const {
        tempRangeTo,
        tempRangeFrom,
        tempRangeType,
      } = R.pathOr({}, ['data', GC.FIELD_REFRIGERATION], row);

      if (G.isNilOrEmpty(tempRangeFrom)) return null;

      return <TableCell text={`${tempRangeFrom} - ${tempRangeTo} ${tempRangeType}`} />;
    },
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_HOURS}`]: {
    name: ['titles:refrigeration', 'titles:hours'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_HOURS], row)} />,
  },
  [`${GC.FIELD_REFRIGERATION}.${GC.FIELD_HOURS_DATE}`]: {
    name: ['titles:refrigeration', 'titles:hours-date'],
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_REFRIGERATION, GC.FIELD_HOURS_DATE], row)} />,
  },
};

const makeReport = (fields: Object) => ({
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(fields),
});

export const truckSpecificationSettings = {
  singleItem: true,
  hideEmptyFields: true,
  actionsPicker: ['edit'],
  groupName: 'specification',
  columnSettings: truckColumnSettings,
  report: makeReport(truckColumnSettings),
  modalAdditionalOptions: { p: '0 0 15px 0' },
  itemTitleArr: ['titles:specifications', 'Specifications'],
  formGroupTitleArr: ['titles:specifications', 'Specifications'],
  additionalTableSettings: { checkBoxCellJustifyContent: 'center' },
  endpoints: {
    list: 'truckSpecification',
    createOrUpdate: 'truckSpecification',
  },
  // components
  CustomForm: SpecificationForm,
};

export const trailerSpecificationSettings = {
  ...truckSpecificationSettings,
  columnSettings: trailerColumnSettings,
  report: makeReport(trailerColumnSettings),
  endpoints: {
    list: 'trailerSpecification',
    createOrUpdate: 'trailerSpecification',
  },
  modalAdditionalOptions: {
    p: '0 0 15px 0',
    options: {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 120px)',
      title: G.getEditTitle(['titles:specification', 'Specification']),
    },
  },
  // components
  CustomForm: (props: Object) => <SpecificationForm {...props} formWidth={730} type='trailer' />,
  // helpers
  makeOptionsForSelect: R.path(['configGroup', 'dropdownOptions']),
  makeInitialValues: (entity: Object) => {
    const { doorType, bodyMaterial, refrigeration, specificationClass } = entity;

    const initialValues = {
      ...entity,
      [GC.FIELD_DOOR_TYPE]: G.getDropdownOptionGuidFromObject(doorType),
      [GC.FIELD_BODY_MATERIAL]: G.getDropdownOptionGuidFromObject(bodyMaterial),
      [GC.FIELD_SPECIFICATION_CLASS]: G.getDropdownOptionGuidFromObject(specificationClass),
    };

    if (G.isNilOrEmpty(refrigeration)) return initialValues;

    const refrigerationManufacturer = R.path(
      [GC.FIELD_REFRIGERATION_MANUFACTURER, GC.FIELD_DROPDOWN_OPTION_GUID],
      refrigeration,
    );

    return {
      ...initialValues,
      [GC.FIELD_DOOR_TYPE]: G.getDropdownOptionGuidFromObject(doorType),
      [GC.FIELD_BODY_MATERIAL]: G.getDropdownOptionGuidFromObject(bodyMaterial),
      [GC.FIELD_SPECIFICATION_CLASS]: G.getDropdownOptionGuidFromObject(specificationClass),
      [GC.FIELD_REFRIGERATION]: {
        ...refrigeration,
        [GC.FIELD_REFRIGERATION_MANUFACTURER]: refrigerationManufacturer,
      },
    };
  },
};
