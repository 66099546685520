import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// common
export const getVendorGeneralDetailsRequest = createAction('getVendorGeneralDetailsRequest');
export const updateVendorGeneralDetailsRequest = createAction('updateVendorGeneralDetailsRequest');
// configs
export const getVendorBranchConfigsRequest = createAction('getVendorBranchConfigsRequest');
// monthly payroll deduction
export const getVendorPayrollChargesIsPaidOnPayrollRequest =
  createAction('getVendorPayrollChargesIsPaidOnPayrollRequest');
export const getVendorPayrollChargesIsPaidOnPayrollSuccess =
  createAction('getVendorPayrollChargesIsPaidOnPayrollSuccess');
// payroll accessorials
export const getVendorPayrollAccessorialsIsPaidOnPayrollRequest =
  createAction('getVendorPayrollAccessorialsIsPaidOnPayrollRequest');
export const getVendorPayrollAccessorialsIsPaidOnPayrollSuccess =
  createAction('getVendorPayrollAccessorialsIsPaidOnPayrollSuccess');
// fleet assignment
export const createFleetItemRequest = createAction('createFleetItemRequest');
export const changeFleetAssignmentRequest = createAction('changeFleetAssignmentRequest');
export const getFleetAssignmentListRequest = createAction('getFleetAssignmentListRequest');
