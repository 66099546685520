import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const TableCell = ({ text }: Object) => {
  if (G.isNilOrEmpty(text)) return null;

  return (
    <TextComponent
      title={text}
      maxWidth='90%'
      display='block'
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};
