import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { sendTelToExternalSystemRequest } from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
// feature dispatch details new
import { removeTelFromExternalSystemRequest } from '../actions';
//////////////////////////////////////////////////

export const withLoadExternalTableGroupRowActions = compose(
  connect(
    null,
    {
      sendTelToExternalSystemRequest,
      removeTelFromExternalSystemRequest,
    },
  ),
  withHandlers({
    handleSendToExternalSystem: (props: Object) => () => {
      const { entity, fromPage, closeFixedPopup, sendTelToExternalSystemRequest } = props;
      const { telGuid, integrationType } = entity;

      G.callFunction(closeFixedPopup);
      sendTelToExternalSystemRequest({ telGuid, fromPage, integrationType });
    },
    handleRemoveTelFromExternalSystem: (props: Object) => () => {
      const { entity, openModal, closeFixedPopup, removeTelFromExternalSystemRequest } = props;

      G.callFunction(closeFixedPopup);
      const textLocale = `${G.getWindowLocale('messages:remove-trip-from-external-system', 'Do you want to delete this trip from external system')}?`
      const component = <ConfirmComponent textLocale={textLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeTelFromExternalSystemRequest(entity),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
