import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const selectItem = createAction('selectItem');
export const setGroupBy = createAction('setGroupBy');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setFilterProps = createAction('setFilterProps');
export const setInitialState = createAction('setInitialState');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const deleteItemRequest = createAction('deleteItemRequest');
export const deleteItemSuccess = createAction('deleteItemSuccess');
export const getSummaryRequest = createAction('getSummaryRequest');
export const getSummarySuccess = createAction('getSummarySuccess');
export const setUsedReportOnly = createAction('setUsedReportOnly');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const refreshPageRequest = createAction('refreshPageRequest');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const setGlobalFilterType = createAction('setGlobalFilterType');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const acceptTelRateRequest = createAction('acceptTelRateRequest');
export const setShipUnitsExpanded = createAction('setShipUnitsExpanded');
export const declineTelRateRequest = createAction('declineTelRateRequest');
export const setIgnorePromptStatus = createAction('setIgnorePromptStatus');
export const getLoadDetailsRequest = createAction('getLoadDetailsRequest');
export const getLoadDetailsSuccess = createAction('getLoadDetailsSuccess');
export const resetListAndPagination = createAction('resetListAndPagination');
export const createReferenceRequest = createAction('createReferenceRequest');
export const approveLoadRateRequest = createAction('approveLoadRateRequest');
export const dispatchTelRateRequest = createAction('dispatchTelRateRequest');
export const getLoadInvoicesRequest = createAction('getLoadInvoicesRequest');
export const getLoadInvoicesSuccess = createAction('getLoadInvoicesSuccess');
export const dispatchTelListRequest = createAction('dispatchTelListRequest');
export const addCarrierRatesRequest = createAction('addCarrierRatesRequest');
export const updateContainerCallback = createAction('updateContainerCallback');
export const getLoadsShipUnitRequest = createAction('getLoadsShipUnitRequest');
export const getLoadsShipUnitSuccess = createAction('getLoadsShipUnitSuccess');
export const toggleLoadDetailsRequest = createAction('toggleLoadDetailsRequest');
export const toggleLoadDetailsSuccess = createAction('toggleLoadDetailsSuccess');
export const updateAppointmentRequest = createAction('updateAppointmentRequest');
export const updateLoadDetailsRequest = createAction('updateLoadDetailsRequest');
export const updateLoadDetailsSuccess = createAction('updateLoadDetailsSuccess');
export const updateLoadDocumentRequest = createAction('updateLoadDocumentRequest');
export const updateLoadDocumentSuccess = createAction('updateLoadDocumentSuccess');
export const removeLoadDocumentRequest = createAction('removeLoadDocumentRequest');
export const removeLoadDocumentSuccess = createAction('removeLoadDocumentSuccess');
export const createFleetInvoiceRequest = createAction('createFleetInvoiceRequest');
export const sendUpdateEdiOrApiRequest = createAction('sendUpdateEdiOrApiRequest');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const getTelNotificationsRequest = createAction('getTelNotificationsRequest');
export const getTelNotificationsSuccess = createAction('getTelNotificationsSuccess');
export const getLoadSelectedRateRequest = createAction('getLoadSelectedRateRequest');
export const getLoadSelectedRateSuccess = createAction('getLoadSelectedRateSuccess');
export const getLoadDocumentListRequest = createAction('getLoadDocumentListRequest');
export const getLoadDocumentListSuccess = createAction('getLoadDocumentListSuccess');
export const createCarrierInvoiceRequest = createAction('createCarrierInvoiceRequest');
export const hideTelNotificationsRequest = createAction('hideTelNotificationsRequest');
export const getLoadInvoiceTotalsSuccess = createAction('getLoadInvoiceTotalsSuccess');
export const getLoadInvoiceTotalsRequest = createAction('getLoadInvoiceTotalsRequest');
export const hideTelNotificationsSuccess = createAction('hideTelNotificationsSuccess');
export const downloadLoadDocumentRequest = createAction('downloadLoadDocumentRequest');
export const setDataToListItemByPropName = createAction('setDataToListItemByPropName');
export const setDataToItemListItemDetails = createAction('setDataToItemListItemDetails');
export const dispatchCarrierTelRateRequest = createAction('dispatchCarrierTelRateRequest');
export const createLoadStatusMessageRequest = createAction('createLoadStatusMessageRequest');
export const addCarrierRatesByReportRequest = createAction('addCarrierRatesByReportRequest');
export const sendTelsToExternalSystemRequest = createAction('sendTelsToExternalSystemRequest');
export const markAsReadTelNotificationRequest = createAction('markAsReadTelNotificationRequest');
export const markAsReadTelNotificationSuccess = createAction('markAsReadTelNotificationSuccess');
export const sendCrossBorderIntegrationRequest = createAction('sendCrossBorderIntegrationRequest');
export const getLoadDispatchBoardDetailsRequest = createAction('getLoadDispatchBoardDetailsRequest');
export const getLoadDispatchBoardDetailsSuccess = createAction('getLoadDispatchBoardDetailsSuccess');
export const createFleetInvoiceByTelGuidRequest = createAction('createFleetInvoiceByTelGuidRequest');
export const getCloReferencesByEventGuidsRequest = createAction('getCloReferencesByEventGuidsRequest');
export const getCloReferencesByEventGuidsSuccess = createAction('getCloReferencesByEventGuidsSuccess');
export const createTelRateOnDispatchBoardRequest = createAction('createTelRateOnDispatchBoardRequest');
export const updateTelRateOnDispatchBoardRequest = createAction('updateTelRateOnDispatchBoardRequest');
export const createCrossBorderIntegrationRequest = createAction('createCrossBorderIntegrationRequest');
export const createCarrierInvoiceByTelGuidRequest = createAction('createCarrierInvoiceByTelGuidRequest');
export const suspendResumeCarrierRateEmailsRequest = createAction('suspendResumeCarrierRateEmailsRequest');
export const createLoadMultipleStatusMessageRequest = createAction('createLoadMultipleStatusMessageRequest');
export const createTelCarrierRateOnDispatchBoardRequest = createAction('createTelCarrierRateOnDispatchBoardRequest');
export const updateTelCarrierRateOnDispatchBoardRequest = createAction('updateTelCarrierRateOnDispatchBoardRequest');
