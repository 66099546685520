import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectComponentsStoryStore = (state: Object) => state.componentsStory;

const makeSelectComponentsSearch = () => createSelector(
  selectComponentsStoryStore,
  ({ searchFilter }: Object) => searchFilter,
);

const makeSelectActiveTab = () => createSelector(
  selectComponentsStoryStore,
  ({ activeTab }: Object) => activeTab,
);

const makeSelectDatepickerMuiValue = () => createSelector(
  selectComponentsStoryStore,
  ({ datepickerMuiValue }: Object) => datepickerMuiValue,
);

const makeSelectShowHideText = () => createSelector(
  selectComponentsStoryStore,
  ({ showHideText }: Object) => showHideText,
);

const makeSelectColorPickerValue = () => createSelector(
  selectComponentsStoryStore,
  ({ colorPickerValue }: Object) => colorPickerValue,
);

const makeSelectChatMessages = () => createSelector(
  selectComponentsStoryStore,
  ({ chatMessages }: Object) => chatMessages,
);

const makeSelectAdditionalFilters = () => createSelector(
  selectComponentsStoryStore,
  ({ additionalFilters }: Object) => additionalFilters,
);

const makeSelectDateRangeValues = () => createSelector(
  selectComponentsStoryStore,
  ({ dateRangeValues }: Object) => dateRangeValues,
);

const makeSelectDateRangeMuiValues = () => createSelector(
  selectComponentsStoryStore,
  ({ dateRangeMuiValues }: Object) => dateRangeMuiValues,
);

const makeSelectDateSelectorValues = () => createSelector(
  selectComponentsStoryStore,
  ({ dateSelectorValues }: Object) => dateSelectorValues,
);

const makeSelectShowLoader = () => createSelector(
  selectComponentsStoryStore,
  ({ showLoader }: Object) => showLoader,
);

const makeSelectShowLocalLoader = () => createSelector(
  selectComponentsStoryStore,
  ({ showLocalLoader }: Object) => showLocalLoader,
);

const makeSelectMessageCenterValues = () => createSelector(
  selectComponentsStoryStore,
  ({ messageCenterValues }: Object) => messageCenterValues,
);

const makeSelectProgressValue = () => createSelector(
  selectComponentsStoryStore,
  ({ progressValue }: Object) => progressValue,
);

const makeSelectSelectedCarriers = () => createSelector(
  selectComponentsStoryStore,
  ({ selectedCarriers }: Object) => selectedCarriers,
);

const makeSelectSwitchValue = () => createSelector(
  selectComponentsStoryStore,
  ({ switchValue }: Object) => switchValue,
);

const makeSelectToggleBtnValue = () => createSelector(
  selectComponentsStoryStore,
  ({ toggleBtnValue }: Object) => toggleBtnValue,
);

const makeSelectReport = () => createSelector(
  selectComponentsStoryStore,
  ({ report }: Object) => report,
);

const makeSelectFilterProps = () => createSelector(
  selectComponentsStoryStore,
  ({ filterProps }: Object) => filterProps,
);

const makeSelectFilterParam = () => createSelector(
  selectComponentsStoryStore,
  ({ filterParam }: Object) => filterParam,
);

const makeSelectInputValue = () => createSelector(
  selectComponentsStoryStore,
  ({ inputValue }: Object) => inputValue,
);

const makeSelectUserList = () => createSelector(
  selectComponentsStoryStore,
  ({ userList }: Object) => userList,
);

const makeSelectUserColumnSettings = () => createSelector(
  selectComponentsStoryStore,
  ({ userColumnSettings }: Object) => userColumnSettings,
);

const makeSelectCollapsedGroup = () => createSelector(
  selectComponentsStoryStore,
  ({ collapsedGroup }: Object) => collapsedGroup,
);

export {
  makeSelectReport,
  makeSelectUserList,
  makeSelectActiveTab,
  makeSelectInputValue,
  makeSelectShowLoader,
  makeSelectFilterParam,
  makeSelectFilterProps,
  makeSelectSwitchValue,
  makeSelectShowHideText,
  makeSelectChatMessages,
  makeSelectProgressValue,
  makeSelectToggleBtnValue,
  makeSelectCollapsedGroup,
  makeSelectDateRangeValues,
  makeSelectShowLocalLoader,
  makeSelectComponentsSearch,
  makeSelectColorPickerValue,
  makeSelectSelectedCarriers,
  makeSelectAdditionalFilters,
  makeSelectUserColumnSettings,
  makeSelectDateRangeMuiValues,
  makeSelectDatepickerMuiValue,
  makeSelectDateSelectorValues,
  makeSelectMessageCenterValues,
};
