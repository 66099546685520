import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import AuthHoc from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const enhance = compose(
  AuthHoc([
    PC.CHART_WRITE,
    PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
  ]),
  withHandlers({
    handleEditChart: ({ chart, closeFixedPopup }: Object) => () => {
      closeFixedPopup();
      G.goToRoute(routesMap.chartEdit(R.prop(GC.FIELD_GUID, chart)));
    },
    handleDelete: (props: Object) => () => {
      props.closeFixedPopup();
      const modalContent = getConfirmModal({
        cancelAction: props.closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:delete', 'Delete'),
        text: G.getWindowLocale(
          'messages:delete-confirmation-text',
          'Are you sure you want delete chart?',
        ),
        submitAction: () => {
          props.closeModal();
          props.handleDeleteChart(R.of(Array, R.path(['chart', GC.FIELD_GUID], props)));
        },
      });
      return props.openModal(modalContent);
    },
  }),
  pure,
);

const getOptions = ({ handleDelete, handleEditChart }: Object) => ([
  {
    action: handleEditChart,
    permissions: [PC.CHART_WRITE],
    text: G.getWindowLocale('actions:edit'),
    frontIcon: I.pencil(G.getTheme('icons.iconColor')),
  },
  {
    action: handleDelete,
    permissions: [PC.CHART_WRITE],
    text: G.getWindowLocale('actions:delete'),
    frontIcon: I.trash(G.getTheme('icons.iconColor')),
  },
]);

const ElementActionsComponent = (props: Object) => (
  <ActionsElement options={getOptions(props)} />
);

export default enhance(ElementActionsComponent);
