import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, lifecycle } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { ActionBox } from '../../../components/action-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-list
import { getPayrollAccessorialChargesByPayrollAccessorialGuidRequest } from '../actions';
//////////////////////////////////////////////////

const tableSettings = {
  maxHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 40,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  allowSelectItems: false,
  minHeight: 'max-content',
  emptyListPadding: '10px 0',
  tableWrapperProps: { width: 'max-content', m: '20px auto 20px 20px' },
};

const columnSettings = {
  [GC.FIELD_PAYROLL_NUMBER]: {
    name: 'titles:payroll-number',
    customComponent: ({ data }: Object) =>
      <ActionBox text={data.payrollNumber} action={() => G.goToRoute(routesMap.driverPayrollList)} />,
  },
  [GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL]: {
    name: 'titles:total',
    customComponent: ({ data = {} }: Object) => {
      const { total, currency, priceSheetCurrencyTotal } = data;

      const parentCurrency = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY);

      const displayText = G.ifElse(
        R.equals(total, priceSheetCurrencyTotal),
        `${priceSheetCurrencyTotal} ${parentCurrency}`,
        `${total} ${currency} (${R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data)} ${
          R.prop(GC.FIELD_CURRENCY, data)})`,
      );

      return displayText;
    },
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(columnSettings)),
};

const PayrollAccessorialDetails = (props: Object) => (
  <Table
    report={report}
    itemList={props.itemList}
    tableSettings={tableSettings}
    columnSettings={columnSettings}
  />
);

const enhance = compose(
  connect(null, { getPayrollAccessorialChargesByPayrollAccessorialGuidRequest }),
  lifecycle({
    componentDidMount() {
      const {
        itemList,
        isVendor,
        payrollAssessorialGuid,
        getPayrollAccessorialChargesByPayrollAccessorialGuidRequest,
      } = this.props;

      if (R.isNil(itemList)) {
        getPayrollAccessorialChargesByPayrollAccessorialGuidRequest({ isVendor, payrollAssessorialGuid });
      }
    },
  }),
  pure,
);

export default enhance(PayrollAccessorialDetails);
