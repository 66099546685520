import React from 'react';
// components
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
//////////////////////////////////////////////////


const getOptions = ({ handleAddDriverRate, handleAddCarrierRate }: Object) => [
  {
    frontIcon: null,
    action: handleAddDriverRate,
    permissions: [PC.FLEET_RATE_WRITE],
    text: G.getWindowLocale('titles:add-driver-rate', 'Add Driver Rate'),
  },
  {
    frontIcon: null,
    action: handleAddCarrierRate,
    permissions: [PC.CARRIER_RATE_WRITE],
    text: G.getWindowLocale('titles:add-carrier-rate', 'Add Carrier Rate'),
  },
];

export const AddDriverCarrierRate = (props: Object) => <ActionsElement version={2} options={getOptions(props)} />;

export default AddDriverCarrierRate;
