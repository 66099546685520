import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const ReturnInheritedComponent = (props: Object) => (
  <Box cursor='pointer' m='0 10px'>
    <Box
      mx='5px'
      cursor='pointer'
      title={props.tooltipText}
      onClick={props.handleClickReturnInherited}
    >
      {I.arrowUndo(G.getTheme('forms.group.title.textColor'))}
    </Box>
  </Box>
);

export default ReturnInheritedComponent;
