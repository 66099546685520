import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../../helpers';
// forms
import { Fieldset2 } from '../../../../../forms';
// feature configs
import { getStatusFields, defaultStatusFields, statusValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: statusValidationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultStatusFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, configName, shouldUpdate, createUpdateInvoiceStatusRequest } = props;

      closeModal();

      if (G.isTrue(shouldUpdate)) {
        return createUpdateInvoiceStatusRequest({ values, configName, action: 'update' });
      }

      createUpdateInvoiceStatusRequest({ values, configName, action: 'create' });
    },
  }),
  pure,
);

const InvoiceStatusForm = (props: Object) => {
  const { configName, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 15 }}
        fields={getStatusFields(configName)}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(InvoiceStatusForm);
