import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  printRequest,
  setReportType,
  getXMLRequest,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const resetListAndPaginationAndGetItemsRequest = createAction('resetListAndPaginationAndGetItemsRequest');

export {
  // report
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setUsedReport,
  setReportType,
  setFilterProps,
  setListLoading,
  setInitialState,
  cleanQuickFilter,
  setReportPending,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  resetListAndPaginationAndGetItemsRequest,
};
