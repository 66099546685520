import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../components/form-buttons';
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// features
import { contractListEnhance } from '../../contracts';
// forms
import { styles, renderFormGroup, FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier
import * as LC from '../constants';
import { setOptions } from '../helpers';
import PayLocationForm from './pay-location-form';
import { financeFields } from '../settings/fields-settings';
import CarrierPaymentTermForm from './carrier-payment-term';
import { CarrierAssessorials } from './carrier-assessorial';
import { makeSelectCarrierPaymentTerms } from '../selectors';
import { removeCarrierPaymentTermRequest, createOrUpdateCarrierPaymentTermRequest } from '../actions';
import {
  financeLocationSettings,
  carrierContractSettings,
  customerContractSettings,
  carrierPaymentTermsSettings,
} from '../settings/column-settings';
//////////////////////////////////////////////////

const makeRequestBody = (props: Object) => {
  const { values, groupName } = props;

  const objectGuidName = G.ifElse(
    R.equals(groupName, 'carrierFinance'),
    'carrierFinancialGuid',
    'terminalFinancialGuid',
  );
  const objectGuid = R.path([groupName, GC.FIELD_GUID], props);

  return R.assoc(objectGuidName, objectGuid, values);
};

const locationEnhance = compose(
  withHandlers({
    handleAddContact: (props: Object) => () => {
      const { groupName, openModal, closeModal, createPayLocationAction } = props;

      const submitAction = (values: Object) => createPayLocationAction(makeRequestBody({ ...props, values }));
      const component = (
        <PayLocationForm
          closeModal={closeModal}
          submitAction={submitAction}
          isTerminal={R.equals(groupName, 'terminalFinance')}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '900px',
          height: 'auto',
          maxHeight: '88vh',
          title: G.getWindowLocale('titles:add-pay-location', 'Add Pay Location'),
        },
      };

      openModal(modal);
    },
    handleEditContact: (props: Object) => (payLocation: Object) => {
      const { groupName, openModal, closeModal, updatePayLocationAction } = props;

      const submitAction = (values: Object) => updatePayLocationAction(makeRequestBody({ ...props, values }));
      const component = (
        <PayLocationForm
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={payLocation}
          isTerminal={R.equals(groupName, 'terminalFinance')}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '900px',
          height: 'auto',
          maxHeight: '88vh',
          title: G.getWindowLocale('titles:edit-pay-location', 'Edit Pay Location'),
        },
      };

      openModal(modal);
    },
    handleDeleteContact: (props: Object) => ({ locationName, guid }: Object) => {
      const confirmationContent = (
        <ConfirmComponent
          display='block'
          name={locationName}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deletePayLocationAction(guid);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

const getFinanceTabEntitiesFieldsByGroupName = (groupName: string) => G.ifElse(
  R.equals(groupName, 'carrierFinance'),
  financeLocationSettings,
  R.omit(['contactEmails', GC.FIELD_COMMENTS], financeLocationSettings),
);

const FinanceLocation = locationEnhance((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={props.listPayLocations}
    handleAddClick={props.handleAddContact}
    handleEditRow={props.handleEditContact}
    handleDeleteRow={props.handleDeleteContact}
    groupName={LC.CARRIER_GROUP_NAME_FINANCE_LOCATION}
    entitiesFields={getFinanceTabEntitiesFieldsByGroupName(props.groupName)}
    panelTitle={G.getWindowLocale('titles:pay-to-locations', 'Pay To Locations')}
    isOpened={R.path([LC.CARRIER_GROUP_NAME_FINANCE_LOCATION], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_FINANCE_LOCATION)}
  />
));

const carrierMapStateToProps = (state: Object) => createStructuredSelector({
  carrierPaymentTerms: makeSelectCarrierPaymentTerms(state),
});

const carrierPaymentTermEnhance = compose(
  connect(carrierMapStateToProps, { removeCarrierPaymentTermRequest, createOrUpdateCarrierPaymentTermRequest }),
  withHandlers({
    handleAddCarrierPaymentTerm: (props: Object) => () => {
      const { openModal, closeModal, carrierGuid, createOrUpdateCarrierPaymentTermRequest } = props;

      const component = (
        <CarrierPaymentTermForm
          closeModal={closeModal}
          initialValues={{ carrierGuid }}
          submitAction={createOrUpdateCarrierPaymentTermRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getAddTitle(['titles:carrier-payment-term', 'Carrier Payment Term']),
        },
      };

      openModal(modal);
    },
    handleEditCarrierPaymentTerm: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateCarrierPaymentTermRequest } = props;

      const component = (
        <CarrierPaymentTermForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateCarrierPaymentTermRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getEditTitle(['titles:carrier-payment-term', 'Carrier Payment Term']),
        },
      };

      openModal(modal);
    },
    handleRemoveCarrierPaymentTerm: ({ openModal, removeCarrierPaymentTermRequest }: Object) => ({ guid }: Object) => {
      const textLocale = G.getWindowLocale(
        'messages:remove-carrier-payment-term',
        'Are you sure you want to remove carrier payment term',
      );
      const confirmationContent = <ConfirmComponent display='block' textLocale={textLocale} />;
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              action: () => removeCarrierPaymentTermRequest(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const CarrierPaymentTerm = carrierPaymentTermEnhance((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={props.carrierPaymentTerms}
    entitiesFields={carrierPaymentTermsSettings}
    handleAddClick={props.handleAddCarrierPaymentTerm}
    handleEditRow={props.handleEditCarrierPaymentTerm}
    handleDeleteRow={props.handleRemoveCarrierPaymentTerm}
    groupName={LC.CARRIER_GROUP_NAME_CARRIER_PAYMENT_TERM}
    isHiddenAddIcon={G.isNotNilAndNotEmpty(props.carrierPaymentTerms)}
    panelTitle={G.getWindowLocale('titles:carrier-payment-term', 'Carrier Payment Term')}
    isOpened={R.path(['collapsedGroup', LC.CARRIER_GROUP_NAME_CARRIER_PAYMENT_TERM], props)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_CARRIER_PAYMENT_TERM)}
  />
));

const CarrierContractRates = contractListEnhance({
  contractType: 'carrier',
  pageType: 'carrierDetails',
})((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    roleOptions={props.roleOptions}
    entities={props.carrierContracts}
    entitiesFields={carrierContractSettings}
    handleEditRow={props.handleEditContract}
    handleDeleteRow={props.handleDeleteContract}
    handleAddClick={props.handleClickAddContract}
    groupName={LC.CARRIER_GROUP_NAME_CARRIER_CONTRACT}
    panelTitle={G.getWindowLocale('titles:carrier-contract', 'Carrier Contracts')}
    isOpened={R.path([LC.CARRIER_GROUP_NAME_CARRIER_CONTRACT], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_CARRIER_CONTRACT)} />
));

const CustomersContract = contractListEnhance({
  contractType: 'customer',
  pageType: 'carrierDetails',
})((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    roleOptions={props.roleOptions}
    entities={props.customersContract}
    handleEditRow={props.handleEditContract}
    entitiesFields={customerContractSettings}
    handleDeleteRow={props.handleDeleteContract}
    handleAddClick={props.handleClickAddContract}
    groupName={LC.CARRIER_GROUP_NAME_CUSTOMERS_CONTRACT}
    panelTitle={G.getWindowLocale('titles:customer-contracts', 'Customer Contracts')}
    isOpened={R.path([LC.CARRIER_GROUP_NAME_CUSTOMERS_CONTRACT], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_CUSTOMERS_CONTRACT)} />
));

const FinanceTab = (props: Object) => (
  <FormSection name={props.groupName}>
    <FormGroupWrapper isOpened={props.collapsedGroup[props.groupName]}>
      <FormGroupTitleComponent
        withArrowDown={true}
        text={G.getWindowLocale(financeFields.title)}
        isOpened={props.collapsedGroup[props.groupName]}
        onToggleFormGroup={() => (
          props.handleToggleFormGroup(props.groupName)
        )} />
      {
        financeFields.fields.map((field: Object, index: number) => (
          <Field
            width={242}
            key={index}
            direction='row'
            margin='5px 10px'
            errorFontSize={14}
            labelFontSize={16}
            labelMargin='5px 0'
            labelPosition='left'
            inputs={field.inputs}
            errorMargin='0 0 5px 0'
            display={field.display}
            disabled={field.disabled}
            type={R.or(field.type, '')}
            additionClass={styles.inputGroup}
            options={setOptions(props, field)}
            validate={R.or(field.validate, [])}
            selectedValues={props[field.values]}
            label={G.getWindowLocale(field.name)}
            component={renderFormGroup(field.type)}
            selectAction={props[field.selectAction]}
            name={R.or(field.nameForAttribute, field.name)} />
        ))
      }
    </FormGroupWrapper>
    <CarrierPaymentTerm
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
      carrierGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
    />
    <FinanceLocation {...props} />
    {
      R.equals(props.groupName, 'carrierFinance') &&
      <CarrierAssessorials
        {...props}
        entities={props.assessorials}
        relatedEntityField='carrierGuid'
        relatedGuid={props.initialValues.guid}
        groupName={LC.CARRIER_GROUP_NAME_ASSESSORIALS}
        deleteAction={props.deleteCarrierAssessorialRequest}
        updateAction={props.updateCarrierAssessorialRequest}
        createAction={props.createCarrierAssessorialRequest}
        addSharedAccessorialsEndpoint='addCarrierSharedAccessorials'
        removeSharedAccessorialsEndpoint='removeCarrierSharedAccessorials'
        successSharedAccessorialsAction={props.getCarrierAssessorialsRequest}
        panelTitle={G.getWindowLocale('titles:carrier-accessorials', 'Carrier Accessorials')} />
    }
    {
      R.equals(props.groupName, 'carrierFinance')
      && <CarrierContractRates {...props} />
    }
    {
      R.equals(props.groupName, 'carrierFinance')
      && <CustomersContract {...props} />
    }
    <FormButtons
      {...props}
      width='auto'
      handleClickCancel={props.cancelAction} />
  </FormSection>
);

export default FinanceTab;
