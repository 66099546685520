import * as R from 'ramda';
import React, { memo, useState } from 'react';
// components
import { FormSectionHeader } from '../../../components/form-section-header';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hooks
import { useModalAndLoaderActions } from '../../../hooks';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import FormGroupTable from './form-group-table';
import { vendorCompensationAccessorialSettings } from '../settings';
import { useFormGroupOperations } from '../hooks/use-form-group-operations';
//////////////////////////////////////////////////

const VendorCompensationAccessorials = memo((props: Object) => {
  const [itemList, setItemList] = useState(null);

  const modalAndLoaderActions = useModalAndLoaderActions();

  const { closeModal, openLoader, closeLoader } = modalAndLoaderActions;

  const settings = R.assoc(
    GC.FIELD_PRIMARY_OBJECT_GUID,
    G.getPropFromObject('compensationGuid', props),
    vendorCompensationAccessorialSettings,
  );

  const successCallback = ({ data }: Object) => setItemList(data);

  const customCreateOrUpdateHandler = async ({ values, endpoints }: Object) => {
    openLoader();

    const isEditMode = G.isNotNil(G.getGuidFromObject(values));

    const method = G.ifElse(isEditMode, 'put', 'post');
    const endpoint = G.getPropFromObject(G.getPropFromObject('createOrUpdate', endpoints), endpointsMap);

    const res = await sendRequest(method, endpoint, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(method, 'post')) {
        setItemList(R.append(data));
      } else {
        const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), itemList);
        const newItemList = R.assocPath([index], data, itemList);

        setItemList(newItemList);
      }

      closeModal();
    } else {
      G.handleFailResponseSimple(res, 'vendorCompensationAccessorials fail');
    }

    closeLoader();
  };

  const customRemoveHandler = async ({ endpoints }: Object, guid: string) => {
    openLoader();

    const endpointName = G.getPropFromObject('remove', endpoints);
    const endpoint = G.getPropFromObject(endpointName, endpointsMap)(guid);

    const res = await sendRequest('delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      setItemList(R.reject(R.propEq(guid, GC.FIELD_GUID)));

      closeModal();
    } else {
      G.handleFailResponse(res, 'vendorCompensationAccessorials fail');
    }

    closeLoader();
  };

  const { handleRemoveItem, getItemListRequest, handleCreateOrUpdateItem } = useFormGroupOperations({
    ...props,
    ...settings,
    ...modalAndLoaderActions,
    successCallback,
    customRemoveHandler,
    customCreateOrUpdateHandler,
  });

  if (R.isNil(itemList)) return null;

  return (
    <FormSectionHeader
      expanded={G.isNotEmpty(itemList)}
      action={handleCreateOrUpdateItem}
      title={G.getWindowLocale('titles:accessorials', 'Accessorials')}
    >
      <FormGroupTable
        {...props}
        {...settings}
        itemList={itemList}
        tableInnerWidth={992}
        hasFormGroupTitle={false}
        handleRemoveItem={handleRemoveItem}
        getItemListRequest={getItemListRequest}
        handleCreateOrUpdateItem={handleCreateOrUpdateItem}
      />
    </FormSectionHeader>
  );
});

export default VendorCompensationAccessorials;
