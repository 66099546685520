import * as R from 'ramda';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withState, withProps, withHandlers } from 'react-recompose';
// components
import { Tabs, withTabs } from '../../../components/tabs';
import { FormFooter2 } from '../../../components/form-footer';
// features
import { Fieldset2 } from '../../../forms';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs, withAsyncRolesByUserType } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature fleet
import {
  getItemListRequest,
  resetListAndPagination,
  massCreateFleetRequest,
} from '../actions';
import {
  createFleetFieldSettings,
  createFleetDefaultFields,
  createFleetValidationSchema,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');

const getTabs = (fleet: Array, errors: Array, touched: Array) => G.mapIndexed(
  (_: any, index: number) => ({
    text: `${G.getWindowLocale('titles:fleet', 'Fleet')} ${R.inc(index)}`,
    hasError: R.and(G.isNotNil(R.prop(index, errors)), G.isNotNil(R.prop(index, touched))),
  }),
  fleet,
);

const enhance = compose(
  withProps(() => ({
    branchGuid: G.getAmousCurrentBranchGuidFromWindow(),
    currentBranchGuid: G.getAmousCurrentBranchGuidFromWindow(),
    configsNamesArray: [GC.TRUCK_TRUCK_TYPE, GC.TRAILER_TRAILER_TYPE],
  })),
  withTabs,
  withAsyncConfigs,
  withState('resetListOnCloseModal', 'setResetListOnCloseModal', false),
  withAsyncRolesByUserType({ [GC.FIELD_ROLE_ROLE_TYPE]: GC.USER_ROLE_TYPE_DRIVER }),
  withHandlers({
    handleCloseModal: (props: Object) => () => {
      const {
        closeModal,
        getItemListRequest,
        resetListOnCloseModal,
        resetListAndPagination,
      } = props;

      closeModal();

      if (resetListOnCloseModal) {
        resetListAndPagination();
        getItemListRequest();
      }
    },
  }),
  withFormik({
    validationSchema: createFleetValidationSchema,
    mapPropsToValues: () => ({ fleetDtos: R.of(Array, createFleetDefaultFields(1)) }),
    handleSubmit: (values: Object, { props, setValues }: Object) => {
      const { handleSetActiveTab, massCreateFleetRequest, setResetListOnCloseModal } = props;

      const fleetDtos = R.map(R.filter((item: Object) => {
        if (G.isObject(item)) {
          return G.isAllNotNilOrNotEmpty(R.values(R.dissoc(GC.FIELD_ID, item)));
        }

        return true;
      }), R.pathOr([], ['fleetDtos'], values));

      massCreateFleetRequest({ setValues, fleetDtos, handleSetActiveTab, setResetListOnCloseModal });
    },
  }),
  pure,
);

const sections = [
  {
    fieldSettings: createFleetFieldSettings.driver,
    title: G.getWindowLocale('titles:driver', 'Driver'),
  },
  {
    justifyContent: 'space-between',
    permissions: PC.FLEET_TRUCK_WRITE,
    fieldSettings: createFleetFieldSettings.truck,
    title: G.getWindowLocale('titles:truck', 'Truck'),
  },
  {
    justifyContent: 'space-between',
    permissions: PC.FLEET_TRAILER_WRITE,
    fieldSettings: createFleetFieldSettings.trailer,
    title: G.getWindowLocale('titles:trailer', 'Trailer'),
  },
];

const Section = ({ form, title, itemIndex, fieldSettings, optionsForSelect }: Object) => (
  <Fragment>
    <Flex
      p='5px'
      mb={10}
      fontWeight='bold'
      color={darkBlueColor}
      justifyContent='center'
      textTransform='uppercase'
      bg={G.getTheme('colors.whiteGrey')}
    >
      {title}
    </Flex>
    <Fieldset2
      {...form}
      {...optionsForSelect}
      fieldsetType='array'
      arrayName='fleetDtos'
      itemIndex={itemIndex}
      fields={fieldSettings}
      fieldsWrapperStyles={{ py: 15, px: 15, width: 520, justifyContent: 'space-between' }}
    />
  </Fragment>
);

const FleetSection = (props: Object) => {
  const { form, push, remove, activeTab, truckType, rolesGuids, trailerType, handleSetActiveTab } = props;

  const fleetLength = R.length(R.pathOr([], ['values', 'fleetDtos'], form));
  const filteredSections = R.filter(({ permissions }: Object) => R.or(
    G.isNilOrEmpty(permissions),
    G.hasAmousCurrentUserPermissions(permissions)),
    sections,
  );

  const handleRemoveFleet = () => {
    const tabIndex = G.ifElse(G.isZero(activeTab), activeTab, R.dec(activeTab));

    remove(activeTab);
    handleSetActiveTab(tabIndex);
  };

  return (
    <Box>
      <Flex
        mb={15}
        pl={15}
        height={30}
        width='100%'
        fontWeight='bold'
        color={whiteColor}
        bg={G.getTheme('colors.mainBlue')}
      >
        {G.getWindowLocale('actions:add-fleet', 'Add Fleet')}
        {
          R.lt(fleetLength, 5) &&
          <Box ml='5px' cursor='pointer' onClick={() => push(createFleetDefaultFields(R.inc(fleetLength)))}>
            {I.plusRound(whiteColor)}
          </Box>
        }
      </Flex>
      <Flex px={15} justifyContent='space-between'>
        <Tabs
          activeTab={activeTab}
          handleClickTab={handleSetActiveTab}
          tabs={getTabs(
            R.path(['values', 'fleetDtos'], form),
            R.pathOr([], ['errors', 'fleetDtos'], form),
            R.pathOr([], ['touched', 'fleetDtos'], form),
          )}
        />
        {
          R.gt(fleetLength, 1) &&
          <Box
            cursor='pointer'
            fontWeight='bold'
            color={darkBlueColor}
            onClick={handleRemoveFleet}
          >
            {G.getWindowLocale('actions:remove-fleet', 'Remove Fleet')}
          </Box>
        }
      </Flex>
      <Box>
        {
          filteredSections.map((item: Object, index: number) => (
            <Section
              {...item}
              form={form}
              key={index}
              itemIndex={activeTab}
              optionsForSelect={{ truckType, rolesGuids, trailerType }}
            />
          ))
        }
      </Box>
    </Box>
  );
};

const CreateFleet = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldArray
      name='fleetDtos'
      render={(arrayHelpers: Object) => (
        <FleetSection
          {...arrayHelpers}
          activeTab={props.activeTab}
          rolesGuids={props.rolesGuids}
          handleSetActiveTab={props.handleSetActiveTab}
          truckType={G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
            props.asyncConfigs,
            GC.TRUCK_TRUCK_TYPE,
            true,
          )}
          trailerType={G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
            props.asyncConfigs,
            GC.TRAILER_TRAILER_TYPE,
            true,
          )}
        />
      )}
    />
    <FormFooter2
      closeModal={props.handleCloseModal}
      boxStyles={{ p: '5px 15px 15px 15px', bg: G.getTheme('colors.white')}}
    />
  </form>
);

export default connect(null, {
  getItemListRequest,
  resetListAndPagination,
  massCreateFleetRequest,
})(enhance(CreateFleet));
