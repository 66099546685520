import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// features
import DriverInvoiceForm from '../../invoice/components/driver-form';
import CarrierInvoiceForm from '../../invoice/components/carrier-form';
// forms
import { TelInvoiceByCurrencyForm } from '../../../forms/forms/tel-invoice-by-currency-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const hasInvoiceTotals = (data: Object) => G.isNotNil(R.path([GC.FIELD_TEL, 'details', 'invoiceTotals'], data));

const withCarrierFleetInvoices = compose(
  withHandlers({
    handleAddFleetInvoice: (props: Object) => (data: Object) => {
      const {
        fromPage,
        openModal,
        closeModal,
        totalWeight,
        loadConfigs,
        totalDistance,
        configsByGroup,
        defaultUomFields,
        totalCustomersRate,
        createFleetInvoiceRequest,
        createFleetInvoiceByTelGuidRequest,
      } = props;

      if (G.isNilOrEmpty(data.selectedRate)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale(
            'messages:not-create-invoice',
            'Sorry, you can not create an invoice without the selected rate',
          ),
        );
      }

      const telGuid = R.path([GC.FIELD_TEL, GC.FIELD_GUID], data);
      const defaultInvoiceName = R.pathOr('invoice', [GC.FIELD_TEL, GC.FIELD_PRIMARY_REFERENCE_VALUE], data);
      const branchGuid = R.path([GC.FIELD_TEL, GC.FIELD_BRANCH, GC.FIELD_GUID], data);

      const configs = R.pathOr(
        R.path(['configGroups', GC.INVOICE_CONFIG_GROUP], loadConfigs),
        [GC.INVOICE_CONFIG_GROUP],
        configsByGroup,
      );

      const defaultNetDays = G.getConfigValueFromStore(
        GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_NET_DAYS,
        configs,
      );

      const defaultStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const defaultSecondInvoiceStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SECOND_DRIVER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const separateByCurrency = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SEPARATE_PAYMENT_BY_CURRENCY,
        configs,
      );

      let modal;

      const refreshInvoiceTotals = hasInvoiceTotals(data);

      if (separateByCurrency) {
        const modalContent = (
          <TelInvoiceByCurrencyForm
            telGuid={telGuid}
            closeModal={closeModal}
            submitAction={(data: Object) =>
              createFleetInvoiceByTelGuidRequest({ data, fromPage, refreshInvoiceTotals })
            }
          />
        );

        modal = G.getDefaultModalOptions(
          modalContent,
          G.getWindowLocale('titles:create-trip-invoices', 'Create Trip Invoices'),
        );
      } else {
        const modalContent = (
          <DriverInvoiceForm
            telGuid={telGuid}
            branchGuid={branchGuid}
            closeModal={closeModal}
            totalWeight={totalWeight}
            defaultStatus={defaultStatus}
            totalDistance={totalDistance}
            defaultNetDays={defaultNetDays}
            defaultUomFields={defaultUomFields}
            defaultInvoiceName={defaultInvoiceName}
            totalCustomersRate={totalCustomersRate}
            defaultSecondInvoiceStatus={defaultSecondInvoiceStatus}
            sequenceConfigName={GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_SEQUENCE}
            autogenerateConfigName={GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_AUTOGENERATED}
            selectedRate={G.makeTelRateWithCorrectChargesForFleetVendorInvoice(data.selectedRate)}
            handleSendFleetInvoice={(values: Object) => (
              createFleetInvoiceRequest(R.mergeRight(values, { telGuid, refreshInvoiceTotals }))
            )}
          />
        );

        modal = {
          p: '0',
          component: modalContent,
          options: {
            width: 900,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
          },
        };
      }

      openModal(modal);
    },
    handleAddCarrierInvoice: (props: Object) => (data: Object) => {
      if (G.isNilOrEmpty(data.selectedRate)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale(
            'messages:not-create-invoice',
            'Sorry, you can not create an invoice without the selected rate',
          ),
        );
      }

      const {
        fromPage,
        openModal,
        closeModal,
        totalWeight,
        loadConfigs,
        totalDistance,
        configsByGroup,
        defaultUomFields,
        totalCustomersRate,
        createCarrierInvoiceRequest,
        createCarrierInvoiceByTelGuidRequest,
      } = props;

      const telGuid = R.path([GC.FIELD_TEL, GC.FIELD_GUID], data);
      const defaultInvoiceName = R.pathOr('invoice', [GC.FIELD_TEL, GC.FIELD_PRIMARY_REFERENCE_VALUE], data);

      const configs = R.pathOr(
        R.path(['configGroups', GC.INVOICE_CONFIG_GROUP], loadConfigs),
        [GC.INVOICE_CONFIG_GROUP],
        configsByGroup,
      );

      const defaultNetDays = G.getConfigValueFromStore(
        GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_NET_DAYS,
        configs,
      );

      const defaultStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const defaultSecondInvoiceStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const separateByCurrency = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SEPARATE_PAYMENT_BY_CURRENCY,
        configs,
      );

      const refreshInvoiceTotals = hasInvoiceTotals(data);

      if (separateByCurrency) {
        return createCarrierInvoiceByTelGuidRequest({
          telGuid,
          fromPage,
          refreshInvoiceTotals,
        });
      }

      const modalContent = (
        <CarrierInvoiceForm
          telGuid={telGuid}
          closeModal={closeModal}
          totalWeight={totalWeight}
          totalDistance={totalDistance}
          defaultStatus={defaultStatus}
          defaultNetDays={defaultNetDays}
          selectedRate={data.selectedRate}
          defaultUomFields={defaultUomFields}
          totalCustomersRate={totalCustomersRate}
          defaultInvoiceName={defaultInvoiceName}
          defaultSecondInvoiceStatus={defaultSecondInvoiceStatus}
          branchGuid={R.path([GC.FIELD_TEL, GC.FIELD_BRANCH, GC.FIELD_GUID], data)}
          autogenerateConfigName={GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_AUTOGENERATED}
          handleSendCarrierInvoice={(values: Object) => (
            createCarrierInvoiceRequest(R.mergeRight(values, { telGuid, refreshInvoiceTotals }))
          )}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };

      openModal(modal);
    },
  }),
);

export default withCarrierFleetInvoices;
