import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const SimpleForm = (props: Object) => {
  const {
    isSubmitting,
    handleSubmit,
    fieldSettings,
    optionsForSelect = {},
    fieldsWrapperStyles = {},
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        {...optionsForSelect}
        fields={fieldSettings}
        fieldsWrapperStyles={{ px: '0px', pt: 15, flexDirection: 'column', ...fieldsWrapperStyles }}
      />
      <FormFooter2 submitDisabled={isSubmitting} />
    </form>
  );
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: R.path(['validationSchema']),
    mapPropsToValues: R.pathOr({}, ['initialValues']),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { submitAction } = props;

      const callback = () => setSubmitting(false);

      const data = G.mapObjectEmptyStringFieldsToNull(values);

      submitAction(data, { failCallback: callback, successCallback: callback });
    },
  }),
  pure,
);

export default enhance(SimpleForm);
