import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FooterBtns } from '../../../components/footer-btns';
import { openModal, closeModal } from '../../../components/modal/actions';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpen: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <form onSubmit={closeModal}>
          <h2>Modal content</h2>
          <FooterBtns closeModal={closeModal} />
        </form>
      );

      const modal = {
        p: 15,
        component,
        options: {
          movable: false,
          title: 'Modal title',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const FooterBtnsComponent = (props: Object) => {
  const description = '<FooterBtns /> component renders various buttons for a footer section.';

  const usage = (
    <ul>
      <li>Parameter `<i>handleReset</i>` function to handle resetting form values.</li>
      <li>Parameter `<i>closeModal</i>` function to close the modal or perform cancel action.</li>
      <li>Parameter `<i>submitAction</i>` function triggered when the `Save` button is clicked.</li>
      <li>Parameter `<i>saveAndAddNew</i>` determines whether to display the `Save and Add New` button.</li>
      <li>Parameter `<i>isSubmitting</i>` boolean indicating if the form or action is in a submitting state.</li>
      <li>Parameter `<i>handleSaveAndAddNew</i>` function triggered when the `Save and Add New` button is clicked.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='FooterBtns'
      description={description}
      path='src/components/footer-btns'
    >
      <CustomButton onClick={props.handleOpen}>Open modal with component `FooterBtns`</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(FooterBtnsComponent));
