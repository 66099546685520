import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import MultipleStatusMessageForm from '../../../components/multiple-status-message';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const mockMultipleStatusMessageFormData = {
  'loadType': 'TEL',
  'useArrivedDepartedForm': false,
  'events': [
    {
      'items': [],
      'version': 2,
      'fcfs': false,
      'itemIds': [],
      'stopId': null,
      'itemGuids': [],
      'comments': null,
      'cloEvent': true,
      'stopType': 'CLO',
      'loadType': 'CLO',
      'cloEventIndex': 1,
      'telEventIndex': 1,
      'stopNumber': null,
      'arriveDate': null,
      'completed': false,
      'status': 'PENDING',
      'title': 'Pickup 1',
      'eventType': 'PICKUP',
      'droppedTrailers': [],
      'relatedDropGuids': [],
      'pickedUpTrailers': [],
      'createdBy': 'Julianna',
      'appointmentDate': null,
      'droppedContainers': [],
      'eventCheckinDate': null,
      'pickedUpContainers': [],
      'appointmentNumber': null,
      'eventCompleteDate': null,
      'closItemInternalIds': [],
      'droppedTrailerGuids': [],
      'droppedContainerIds': [],
      'pickedUpTrailerGuids': [],
      'pickedUpContainerIds': [],
      'cloDistanceToNextStop': {},
      'telDistanceToNextStop': {},
      'appointmentLateTime': null,
      'appointmentRequired': false,
      'lastTerminalDropGuid': null,
      'appointmentEarlyTime': null,
      'completedStatusSource': null,
      'lateDate': '01/04/2024 10:00 AM',
      'earlyDate': '01/04/2024 10:00 AM',
      'eventLateDate': '01/04/2024 10:00 AM',
      'eventEarlyDate': '01/04/2024 10:00 AM',
      'lastModifiedBy': 'eventNotificationJob',
      'createdDate': '01/04/2024 2:54:37 PM +02',
      'guid': 'c4d24fae-5516-4d3a-8456-e8ee41a88e03',
      'lastModifiedDate': '01/04/2024 3:00:00 PM +02',
      'cloGuid': '9a6b29ff-8dcf-4fa9-9037-a2daef19e184',
      'telGuid': 'a8b09595-56c0-476b-8174-fdb55360c6cf',
      'latLng': { 'lat': 40.511846, 'lng': -88.992231 },
      'loadGuid': '9a6b29ff-8dcf-4fa9-9037-a2daef19e184',
      'locationGuid': 'd611db08-31d5-4771-9c31-ab74f8b3a90c',
      'location': {
        'version': 0,
        'zip': '61761',
        'contacts': [],
        'city': 'Normal',
        'templateId': 'T4',
        'externalId': null,
        'state': 'Illinois',
        'operationHour': [],
        'address2': 'Normal',
        'address3': 'Normal',
        'address4': 'Normal',
        'residential': false,
        'latitude': 40.511846,
        'integrationId': null,
        'createdBy': 'Julianna',
        'longitude': -88.992231,
        'integrationType': null,
        'locationName': 'Normal',
        'hoursOfOperation': null,
        'country': 'United States',
        'lastModifiedBy': 'Julianna',
        'coordinatesPopulated': true,
        'comments': 'cdfdgfghbgfhfgnfgh',
        'address1': '100 North University Street',
        'createdDate': '01/04/2024 2:54:37 PM +02',
        'guid': 'd611db08-31d5-4771-9c31-ab74f8b3a90c',
        'lastModifiedDate': '01/04/2024 2:54:37 PM +02',
        'locationType': {
          'storedValue': 'Destination',
          'displayedValue': 'Destination',
          'guid': 'e42cab06-247a-451c-a3d9-986383a8e214',
          'dropdownOptionGuid': 'eead31b7-fca3-462a-9009-f989beb4b940',
        },
      },
    },
    {
      'items': [],
      'version': 1,
      'fcfs': false,
      'itemIds': [],
      'stopId': null,
      'itemGuids': [],
      'comments': null,
      'cloEvent': true,
      'stopType': 'CLO',
      'loadType': 'CLO',
      'title': 'Drop 2',
      'cloEventIndex': 2,
      'telEventIndex': 2,
      'stopNumber': null,
      'arriveDate': null,
      'completed': false,
      'eventType': 'DROP',
      'status': 'PENDING',
      'droppedTrailers': [],
      'relatedDropGuids': [],
      'pickedUpTrailers': [],
      'createdBy': 'Julianna',
      'appointmentDate': null,
      'droppedContainers': [],
      'eventCheckinDate': null,
      'pickedUpContainers': [],
      'appointmentNumber': null,
      'eventCompleteDate': null,
      'closItemInternalIds': [],
      'droppedTrailerGuids': [],
      'droppedContainerIds': [],
      'pickedUpTrailerGuids': [],
      'pickedUpContainerIds': [],
      'appointmentLateTime': null,
      'lastModifiedBy': 'Julianna',
      'appointmentRequired': false,
      'lastTerminalDropGuid': null,
      'appointmentEarlyTime': null,
      'completedStatusSource': null,
      'cloDistanceToNextStop': null,
      'telDistanceToNextStop': null,
      'lateDate': '01/06/2024 10:00 PM',
      'earlyDate': '01/06/2024 10:00 PM',
      'eventLateDate': '01/06/2024 10:00 PM',
      'eventEarlyDate': '01/06/2024 10:00 PM',
      'createdDate': '01/04/2024 2:54:37 PM +02',
      'guid': '8ee3111a-b5ed-4deb-8abf-785e295389b3',
      'lastModifiedDate': '01/04/2024 2:54:37 PM +02',
      'cloGuid': '9a6b29ff-8dcf-4fa9-9037-a2daef19e184',
      'telGuid': 'a8b09595-56c0-476b-8174-fdb55360c6cf',
      'loadGuid': '9a6b29ff-8dcf-4fa9-9037-a2daef19e184',
      'latLng': { 'lat': 41.8313967, 'lng': -87.6272292 },
      'locationGuid': 'f5967d37-f61e-4a8e-849a-b30218abcc16',
      'location': {
        'version': 0,
        'zip': '60616',
        'contacts': [],
        'address2': null,
        'address3': null,
        'address4': null,
        'comments': null,
        'city': 'Chicago',
        'templateId': 'T5',
        'externalId': null,
        'state': 'Illinois',
        'operationHour': [],
        'residential': false,
        'integrationId': null,
        'latitude': 41.8313967,
        'createdBy': 'Julianna',
        'integrationType': null,
        'hoursOfOperation': null,
        'longitude': -87.6272292,
        'locationName': 'Chicago',
        'country': 'United States',
        'lastModifiedBy': 'Julianna',
        'coordinatesPopulated': true,
        'address1': '10 West 35th Street',
        'createdDate': '01/04/2024 2:54:37 PM +02',
        'guid': 'f5967d37-f61e-4a8e-849a-b30218abcc16',
        'lastModifiedDate': '01/04/2024 2:54:37 PM +02',
        'locationType': {
          'storedValue': 'Airport',
          'displayedValue': 'Airport',
          'guid': '4793284c-c195-448c-bf1f-e7e473eb8128',
          'dropdownOptionGuid': '846e17b1-2925-4041-9ac1-0cbb044537ec',
        },
      },
    },
  ],
  'statusMessagesConfigs': [
    {
      'statusType': 'PICKUP_COMPLETED',
      'displayedValue': 'Actual Pickup Complete ',
      'originalConfigGuid': '1a0baeb7-94ff-490f-9b00-0471e34a40e1',
    },
    {
      'statusType': 'DROP_COMPLETED',
      'displayedValue': 'Completed Unloading at Delivery Location',
      'originalConfigGuid': '0cb38904-a9e1-40d7-b128-df6c62611d5c',
    },
  ],
};

const enhance = compose(
  withHandlers({
    handleOpen: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <MultipleStatusMessageForm
          {...mockMultipleStatusMessageFormData}
          createMultipleStatusMessage={closeModal}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {},
      };

      openModal(modal);
    },
  }),
  pure,
);

const MultipleStatusMessageFormComponent = (props: Object) => {
  const description = '<MultipleStatusMessageForm /> component provides a comprehensive interface for managing and interacting with multiple status messages associated with various events';

  const usage = (
    <ul>
      <li>Parameter `<i>events</i>` represents a collection of events.</li>
      <li>Parameter `<i>loadType</i>` indicates the type of load associated with the events.</li>
      <li>Parameter `<i>statusMessagesConfigs</i>` is the configuration settings related to status messages.</li>
      <li>Parameter `<i>useArrivedDepartedForm </i>` determines whether to use the ArrivedDepartedStatusMessageForm or the CompletedStatusMessageForm.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      description={description}
      name='MultipleStatusMessageForm'
      path='src/components/multiple-status-message'
    >
      <CustomButton onClick={props.handleOpen}>Open Multiple Status Message Form</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(MultipleStatusMessageFormComponent));
