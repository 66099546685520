import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature dispatch details new
import * as C from '../constants';
import { columnSettings as claimColumnSettings } from '../../claim-management/report/settings';
import {
  chatSettings,
  noteSettings,
  mailSettings,
  itemsSettings,
  invoiceSettings,
  documentSettings,
  loadBoardsSettings,
  notificationSettings,
  carrierInvoiceSettings,
  containerColumnSettings,
  referenceColumnSettings,
  macroPointColumnSettings,
  vehicleItemColumnSettings,
  customerRateColumnSettings,
  driverInvoiceColumnSettings,
  statusMessageColumnSettings,
  externalSystemColumnSettings,
  advancePaymentColumnSettings,
  driverCarrierRateColumnSettings,
  serviceVendorInvoiceColumnSettings,
  crossBorderIntegrationColumnSettings,
} from './column-settings';
//////////////////////////////////////////////////

export const tableSettings = {
  rowHeight: 40,
  cellFontSize: 11,
  titleFontSize: 11,
  maxHeight: '400px',
  titleRowHeight: 32,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 50,
  expandableItems: false,
  allowSelectItems: false,
};

export const damagesTableSettings = {
  useMainColors: true,
  checkBoxCellWidth: 0,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'fit-content',
};

const getLoadRatesLabel = () => G.ifElse(
  G.isCurrentUserTypeCarrier(),
  ['titles:carrier-rates', 'Carrier Rates'],
  ['titles:carrier-fleet-rates', 'Carrier/Fleet Rates'],
);

export const getLoadTabs = (showLinkedCloInvoices: boolean = false) => {
  const tabs = [
    {
      withAddition: true,
      value: C.ACTIVE_LIST_REFERENCES,
      addPermissions: [PC.TEL_REFERENCE_WRITE],
      label: ['titles:references', 'References'],
      permissions: [PC.TEL_REFERENCE_READ, PC.TEL_REFERENCE_WRITE],
    },
    {
      withAddition: true,
      value: C.ACTIVE_LIST_STATUS_MESSAGES,
      addPermissions: [PC.TEL_STATUS_MESSAGE_WRITE],
      label: ['titles:status-messages', 'Status Messages'],
      permissions: [PC.TEL_STATUS_MESSAGE_READ, PC.TEL_STATUS_MESSAGE_WRITE],
    },
    {
      // TODO: check permissions
      permissions: null,
      withAddition: false,
      value: C.ACTIVE_LIST_MACRO_POINT,
      label: ['titles:macro-point', 'MacroPoint'],
    },
    {
      permissions: null,
      withAddition: false,
      value: C.ACTIVE_LIST_ITEMS,
      label: ['titles:item-details', 'Item Details'],
    },
    {
      permissions: null,
      withAddition: false,
      value: C.ACTIVE_LIST_VEHICLE_ITEMS,
      label: ['titles:vehicle-item-details', 'Vehicle Item Details'],
    },
    {
      permissions: null,
      withAddition: false,
      value: C.ACTIVE_LIST_CONTAINERS,
      label: ['titles:container-details', 'Container Details'],
    },
    {
      withAddition: true,
      label: getLoadRatesLabel(),
      value: C.ACTIVE_LIST_CARRIER_FLEET_RATES,
      addPermissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
      permissions: G.ifElse(
        G.isCurrentUserTypeCarrier(),
        [PC.CARRIER_RATE_READ],
        [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
      ),
    },
    {
      withAddition: false,
      value: C.ACTIVE_LIST_CUSTOMER_RATES,
      addPermissions: [PC.CLO_RATE_WRITE],
      label: ['titles:customer-rates', 'Customer Rates'],
      permissions: [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
    },
    {
      withAddition: false,
      value: C.ACTIVE_LIST_DRIVER_INVOICES,
      label: ['titles:driver-vendor-invoices', 'Driver/Vendor Invoice(s)'],
      addPermissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      permissions: [PC.TEL_FLEET_INVOICE_READ, PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      withAddition: false,
      value: C.ACTIVE_LIST_CARRIER_INVOICES,
      addPermissions: [PC.TEL_CARRIER_INVOICE_WRITE],
      label: ['titles:carrier-invoices', 'Carrier Invoice(s)'],
      permissions: [PC.TEL_CARRIER_INVOICE_READ, PC.TEL_CARRIER_INVOICE_WRITE],
    },
    {
      withAddition: true,
      addPermissions: [PC.SERVICE_VENDOR_WRITE],
      value: C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES,
      permissions: [PC.SERVICE_VENDOR_READ, PC.SERVICE_VENDOR_WRITE],
      label: ['titles:service-vendor-invoices', 'Service Vendor Invoice(s)'],
    },
    {
      withAddition: false,
      value: C.ACTIVE_LIST_CUSTOMER_INVOICES,
      label: ['titles:customer-invoices', 'Customer Invoice(s)'],
      permissions: [PC.CLO_INVOICE_READ, PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      value: C.ACTIVE_LIST_LOAD_BOARDS,
      label: ['titles:load-boards', 'Load Boards'],
      permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
      addPermissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
    },
    {
      withAddition: false,
      value: C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST,
      permissions: [PC.TEL_ADMINISTRATION_EXECUTE],
      label: ['titles:external-system', 'External System'],
    },
    {
      permissions: null,
      withAddition: true,
      label: ['titles:cross-border', 'Cross Border'],
      value: C.ACTIVE_LIST_CROSS_BORDER_INTEGRATION_LIST,
    },
  ];

  if (showLinkedCloInvoices) {
    return R.compose(
      R.insert(1, {
        withAddition: false,
        value: C.ACTIVE_LIST_LINKED_ORDER_REF_LIST,
        permissions: [PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE],
        label: ['titles:crossdock-order-references', 'Cross Dock Order References'],
      }),
      R.insert(12, {
        withAddition: false,
        value: C.ACTIVE_LIST_LINKED_CUSTOMER_INVOICES,
        label: ['titles:crossdock-customer-invoices', 'Cross Dock Customer Invoice(s)'],
        permissions: [PC.CLO_INVOICE_READ, PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
      }),
    )(tabs);
  }

  return tabs;
};

export const orderTabs = [
  {
    withAddition: true,
    value: C.ACTIVE_LIST_REFERENCES,
    addPermissions: [PC.CLO_REFERENCE_WRITE],
    label: ['titles:references', 'References'],
    permissions: [PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE],
  },
  {
    withAddition: true,
    value: C.ACTIVE_LIST_STATUS_MESSAGES,
    addPermissions: [PC.CLO_STATUS_MESSAGE_WRITE],
    label: ['titles:status-messages', 'Status Messages'],
    permissions: [PC.CLO_STATUS_MESSAGE_READ, PC.CLO_STATUS_MESSAGE_WRITE],
  },
  {
    permissions: null,
    withAddition: false,
    value: C.ACTIVE_LIST_ITEMS,
    label: ['titles:item-details', 'Item Details'],
  },
  {
    permissions: null,
    withAddition: false,
    value: C.ACTIVE_LIST_VEHICLE_ITEMS,
    label: ['titles:vehicle-item-details', 'Vehicle Item Details'],
  },
  {
    withAddition: true,
    value: C.ACTIVE_LIST_CLAIMS,
    addPermissions: [PC.CLAIM_WRITE],
    label: ['titles:claims', 'Claims'],
    permissions: [PC.CLAIM_READ, PC.CLAIM_WRITE],
  },
  {
    permissions: null,
    withAddition: false,
    value: C.ACTIVE_LIST_CONTAINERS,
    label: ['titles:container-details', 'Container Details'],
  },
  {
    withAddition: true,
    value: C.ACTIVE_LIST_CUSTOMER_RATES,
    addPermissions: [PC.CLO_RATE_WRITE],
    label: ['titles:customer-rates', 'Customer Rates'],
    permissions: [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
  },
  {
    withAddition: true,
    value: C.ACTIVE_LIST_CUSTOMER_INVOICES,
    label: ['titles:customer-invoices', 'Customer Invoice(s)'],
    addPermissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    permissions: [PC.CLO_INVOICE_READ, PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    withAddition: false,
    value: C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST,
    permissions: [PC.CLO_ADMINISTRATION_EXECUTE],
    label: ['titles:external-system', 'External System'],
  },
];

export const loadDocumentTabs = [
  {
    withAddition: true,
    value: C.ACTIVE_LIST_DOCUMENTS,
    addPermissions: [PC.TEL_DOCUMENT_WRITE],
    label: ['titles:documents', 'Documents'],
    permissions: [PC.TEL_DOCUMENT_READ, PC.TEL_DOCUMENT_WRITE],
  },
  {
    withAddition: true,
    label: ['titles:chats', 'Chats'],
    value: C.ACTIVE_LIST_CHAT_MESSAGES,
    addPermissions: [PC.TEL_CHAT_MESSAGE_WRITE],
    permissions: [PC.TEL_CHAT_MESSAGE_READ, PC.TEL_CHAT_MESSAGE_WRITE],
  },
  {
    permissions: null,
    withAddition: true,
    value: C.ACTIVE_LIST_NOTES,
    label: ['titles:notes', 'Notes'],
    notHasPermissions: [PC.ROLE_TYPE_CARRIER],
  },
  {
    withAddition: true,
    value: C.ACTIVE_LIST_MAILS,
    label: ['titles:mails', 'Mails'],
    notHasPermissions: [PC.ROLE_TYPE_CARRIER],
  },
];

export const orderDocumentTabs = [
  {
    withAddition: true,
    value: C.ACTIVE_LIST_DOCUMENTS,
    addPermissions: [PC.CLO_DOCUMENT_WRITE],
    label: ['titles:documents', 'Documents'],
    permissions: [PC.CLO_DOCUMENT_READ, PC.CLO_DOCUMENT_WRITE],
  },
  {
    withAddition: true,
    label: ['titles:chats', 'Chats'],
    value: C.ACTIVE_LIST_CHAT_MESSAGES,
    addPermissions: [PC.CLO_CHAT_MESSAGE_WRITE],
    permissions: [PC.CLO_CHAT_MESSAGE_READ, PC.CLO_CHAT_MESSAGE_WRITE],
  },
  {
    permissions: null,
    withAddition: true,
    value: C.ACTIVE_LIST_NOTES,
    label: ['titles:notes', 'Notes'],
  },
  {
    permissions: null,
    withAddition: true,
    value: C.ACTIVE_LIST_MAILS,
    label: ['titles:mails', 'Mails'],
  },
];

export const loadListMap = {
  [C.ACTIVE_LIST_STATUS_MESSAGES]: {
    columnSettings: statusMessageColumnSettings,
    rowActionsPermissions: [PC.TEL_STATUS_MESSAGE_WRITE],
  },
  [C.ACTIVE_LIST_CARRIER_FLEET_RATES]: {
    rowActionsPermissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
    columnSettings: () => G.ifElse(
      G.isFalse(G.showTransportingCarrier()),
      R.dissoc(GC.FIELD_TRANSPORTING_CARRIER_NAME, driverCarrierRateColumnSettings),
      driverCarrierRateColumnSettings,
    ),
  },
  [C.ACTIVE_LIST_CUSTOMER_RATES]: {
    rowActionsPermissions: [PC.CLO_RATE_WRITE],
    columnSettings: () => G.ifElse(
      G.isFalse(G.showTransportingCarrier()),
      R.dissoc(GC.FIELD_TRANSPORTING_CARRIER_NAME, customerRateColumnSettings),
      customerRateColumnSettings,
    ),
  },
  [C.ACTIVE_LIST_REFERENCES]: {
    columnSettings: referenceColumnSettings,
    rowActionsPermissions: [PC.TEL_REFERENCE_WRITE],
  },
  [C.ACTIVE_LIST_LINKED_ORDER_REF_LIST]: {
    rowActionsPermissions: null,
    columnSettings: referenceColumnSettings,
  },
  [C.ACTIVE_LIST_ITEMS]: {
    rowActionsPermissions: null,
    columnSettings: (fields: Array) => {
      if (G.isNilOrEmpty(fields)) return itemsSettings;

      return R.omit(fields, itemsSettings);
    },
  },
  [C.ACTIVE_LIST_DRIVER_INVOICES]: {
    columnSettings: driverInvoiceColumnSettings,
    rowActionsPermissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  [C.ACTIVE_LIST_CARRIER_INVOICES]: {
    columnSettings: carrierInvoiceSettings,
    rowActionsPermissions: [PC.TEL_CARRIER_INVOICE_WRITE],
  },
  [C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES]: {
    rowActionsPermissions: [PC.SERVICE_VENDOR_WRITE],
    columnSettings: serviceVendorInvoiceColumnSettings,
  },
  [C.ACTIVE_LIST_CUSTOMER_INVOICES]: {
    columnSettings: invoiceSettings,
    rowActionsPermissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  [C.ACTIVE_LIST_LINKED_CUSTOMER_INVOICES]: {
    columnSettings: invoiceSettings,
    rowActionsPermissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  [C.ACTIVE_LIST_ADVANCE_PAYMENT]: {
    rowActionsPermissions: null,
    columnSettings: advancePaymentColumnSettings,
  },
  [C.ACTIVE_LIST_LOAD_BOARDS]: {
    rowActionsPermissions: null,
    columnSettings: loadBoardsSettings,
  },
  [C.ACTIVE_LIST_NOTIFICATIONS]: {
    rowActionsPermissions: null,
    columnSettings: notificationSettings,
  },
  [C.ACTIVE_LIST_MACRO_POINT]: {
    rowActionsPermissions: null,
    columnSettings: macroPointColumnSettings,
    notHasRowActionsPermissions: [PC.ROLE_TYPE_CARRIER],
  },
  [C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST]: {
    rowActionsPermissions: null,
    columnSettings: externalSystemColumnSettings,
  },
  [C.ACTIVE_LIST_VEHICLE_ITEMS]: {
    rowActionsPermissions: null,
    columnSettings: (fields: Array) => {
      const columnSettings = R.mergeRight(vehicleItemColumnSettings, itemsSettings);

      if (G.isNilOrEmpty(fields)) return columnSettings;

      return R.omit(fields, columnSettings);
    },
  },
  [C.ACTIVE_LIST_CONTAINERS]: {
    rowActionsPermissions: null,
    columnSettings: containerColumnSettings,
  },
  [C.ACTIVE_LIST_CROSS_BORDER_INTEGRATION_LIST]: {
    rowActionsPermissions: null,
    columnSettings: crossBorderIntegrationColumnSettings,
  },
};

export const orderListMap = {
  [C.ACTIVE_LIST_STATUS_MESSAGES]: {
    rowActionsPermissions: [PC.CLO_STATUS_MESSAGE_WRITE],
    columnSettings: R.omit(
      [GC.FIELD_SOURCE, GC.FIELD_INFO_FROM_INTEGRATION, GC.FIELD_STATUS_FROM_INTEGRATION],
      statusMessageColumnSettings,
    ),
  },
  [C.ACTIVE_LIST_CUSTOMER_RATES]: {
    rowActionsPermissions: [PC.CLO_RATE_WRITE],
    columnSettings: () => G.ifElse(
      G.isFalse(G.showTransportingCarrier()),
      R.dissoc(GC.FIELD_TRANSPORTING_CARRIER_NAME, customerRateColumnSettings),
      customerRateColumnSettings,
    ),
  },
  [C.ACTIVE_LIST_REFERENCES]: {
    columnSettings: referenceColumnSettings,
    rowActionsPermissions: [PC.CLO_REFERENCE_WRITE],
  },
  [C.ACTIVE_LIST_ITEMS]: {
    rowActionsPermissions: null,
    columnSettings: (fields: Array) => {
      if (G.isNilOrEmpty(fields)) return itemsSettings;

      return R.omit(fields, itemsSettings);
    },
  },
  [C.ACTIVE_LIST_CLAIMS]: {
    rowActionsPermissions: [PC.CLAIM_WRITE],
    columnSettings: R.compose(
      R.map((item: Object) => R.assoc('getDataByPath', true, item)),
      R.omit([
        GC.FIELD_BRANCH_DOT_BRANCH_NAME,
        `${GC.FIELD_CLO}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`,
        `${GC.FIELD_TEL}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`,
      ]),
    )(claimColumnSettings),
  },
  [C.ACTIVE_LIST_CUSTOMER_INVOICES]: {
    rowActionsPermissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    columnSettings: () => G.ifElse(
      G.isCurrentUserTypeCustomer(),
      R.omit(
        [
          GC.FIELD_GL_CODE,
          GC.FIELD_EXTERNAL_INVOICE_NUMBER,
          GC.GRC.ACCOUNTING_INTEGRATION_TYPE,
          GC.GRC.ACCOUNTING_INTEGRATION_STATUS,
        ],
        invoiceSettings,
      ),
      invoiceSettings,
    ),
  },
  [C.ACTIVE_LIST_NOTIFICATIONS]: {
    rowActionsPermissions: null,
    columnSettings: notificationSettings,
  },
  [C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST]: {
    rowActionsPermissions: null,
    columnSettings: R.dissoc(GC.FIELD_STOP, externalSystemColumnSettings),
  },
  [C.ACTIVE_LIST_VEHICLE_ITEMS]: {
    rowActionsPermissions: null,
    columnSettings: (fields: Array) => {
      const columnSettings = R.mergeRight(vehicleItemColumnSettings, itemsSettings);

      if (G.isNilOrEmpty(fields)) return columnSettings;

      return R.omit(fields, columnSettings);
    },
  },
  [C.ACTIVE_LIST_CONTAINERS]: {
    rowActionsPermissions: null,
    columnSettings: containerColumnSettings,
  },
};

export const documentsListMap = {
  [C.ACTIVE_LIST_DOCUMENTS]: {
    rowActionsPermissions: null,
    columnSettings: documentSettings,
  },
  [C.ACTIVE_LIST_MAILS]: {
    rowActionsPermissions: null,
    columnSettings: mailSettings,
  },
  [C.ACTIVE_LIST_NOTES]: {
    rowActionsPermissions: null,
    columnSettings: noteSettings,
  },
  [C.ACTIVE_LIST_CHAT_MESSAGES]: {
    rowActionsPermissions: null,
    columnSettings: chatSettings,
  },
};

export const orderDocumentsListMap = {
  [C.ACTIVE_LIST_DOCUMENTS]: {
    rowActionsPermissions: [PC.CLO_DOCUMENT_WRITE],
    columnSettings: R.dissoc(GC.FIELD_SOURCE, documentSettings),
  },
  [C.ACTIVE_LIST_MAILS]: {
    rowActionsPermissions: null,
    columnSettings: mailSettings,
  },
  [C.ACTIVE_LIST_NOTES]: {
    rowActionsPermissions: null,
    columnSettings: noteSettings,
  },
  [C.ACTIVE_LIST_CHAT_MESSAGES]: {
    columnSettings: chatSettings,
    rowActionsPermissions: [PC.CLO_ADMINISTRATION_EXECUTE],
  },
};

export const loadDocumentsListMap = {
  [C.ACTIVE_LIST_DOCUMENTS]: {
    columnSettings: documentSettings,
    rowActionsPermissions: [PC.TEL_DOCUMENT_WRITE],
  },
  [C.ACTIVE_LIST_MAILS]: {
    rowActionsPermissions: null,
    columnSettings: mailSettings,
  },
  [C.ACTIVE_LIST_NOTES]: {
    rowActionsPermissions: null,
    columnSettings: noteSettings,
  },
  [C.ACTIVE_LIST_CHAT_MESSAGES]: {
    columnSettings: chatSettings,
    rowActionsPermissions: [PC.TEL_ADMINISTRATION_EXECUTE],
  },
};
