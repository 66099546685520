import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { DUTY_STATUS_CODE_OPTIONS } from '../../../../helpers/options';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_TIME_IN_CURRENT_STATUS]: {
    width: 150,
    minmax: [0, 200],
    name: 'titles:time-in-current-status',
  },
  [GC.FIELD_TIME_UNTIL_BREAK]: {
    width: 150,
    minmax: [0, 100],
    name: 'titles:time-until-break',
  },
  [GC.FIELD_SHIFT_DRIVER_REMAINING]: {
    width: 150,
    minmax: [0, 100],
    name: 'titles:shift-drive-remaining',
  },
  [GC.FIELD_SHIFT_REMAINING]: {
    width: 150,
    minmax: [0, 100],
    name: 'titles:shift-remaining',
  },
  [GC.FIELD_CYCLE_REMAINING]: {
    width: 150,
    minmax: [0, 100],
    name: 'titles:cycle-remaining',
  },
  [GC.FIELD_CYCLE_TOMORROW]: {
    width: 150,
    minmax: [0, 100],
    name: 'titles:cycle-tomorrow',
  },
  [GC.FIELD_DUTY_STATUS_CODE]: {
    width: 150,
    type: 'select',
    name: 'titles:duty-status-code',
    options: G.addEmptyOptionToDropDown(DUTY_STATUS_CODE_OPTIONS),
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_DUTY_STATUS_CODE, data), GC.FIELD_VALUE)),
    )(DUTY_STATUS_CODE_OPTIONS),
  },
  [GC.FIELD_OUT_OF_DUTY_REASON]: {
    width: 200,
    type: 'select',
    name: 'titles:out-of-duty-reason',
    options: 'outOfDutyReasonOptions',
    customComponent: R.path(['data', GC.FIELD_OUT_OF_DUTY_REASON, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const validationSchema = Yup.object().shape(R.map(({ minmax }: Object) => {
  if (G.isArray(minmax)) {
    return (
      Yup.number()
        .nullable(true)
        .typeError(G.getShouldBeNumericLocaleTxt())
        .min(R.head(minmax), G.getShouldBeFromToLocaleTxt(R.head(minmax), R.last(minmax)))
        .max(R.last(minmax), G.getShouldBeFromToLocaleTxt(R.head(minmax), R.last(minmax)))
    );
  }

  return Yup.string().nullable(true);
}, settings));

const inputWrapperStyles = {
  mb: 25,
  width: 280,
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({ name, options, type = 'text' }: Object, fieldName: string) => ({
    type,
    options,
    fieldName,
    inputWrapperStyles,
    label: R.of(Array, name),
  })),
  R.omit([GC.FIELD_CREATED_BY, GC.FIELD_CREATED_DATE, GC.FIELD_LAST_MODIFIED_BY, GC.FIELD_LAST_MODIFIED_DATE]),
)(settings);

const columnSettings = R.map(R.pick(['name', 'type', 'width', 'customComponent']), settings);

export const driverHoursSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  singleItem: true,
  actionsPicker: ['edit'],
  useGetListRequest: true,
  groupName: 'driverHours',
  itemTitleArr: ['titles:driver-hours', 'Driver Hours'],
  formGroupTitleArr: ['titles:driver-hours', 'Driver Hours'],
  additionalTableSettings: {
    checkBoxCellJustifyContent: 'center',
  },
  endpoints: {
    list: 'driverHours',
    createOrUpdate: 'driverHours',
  },
  fieldsWrapperStyles: {
    width: 600,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // helpers
  makeOptionsForSelect: (props: Object) => ({
    outOfDutyReasonOptions: R.path(['configGroup', 'dropdownOptions', GC.DRIVER_OUT_DUTY_REASON], props),
  }),
  makeInitialValues: (initialValues: Object) => {
    const { outOfDutyReason } = initialValues;

    return R.assoc(GC.FIELD_OUT_OF_DUTY_REASON, G.getDropdownOptionGuidFromObject(outOfDutyReason), initialValues);
  },
};
