import * as R from 'ramda';
import React from 'react';
// components
import { PageHeaderActions } from '../../../components/page-header-actions';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier
import { SAFER_WATCH_PROFILE_LINK_ADDRESS } from '../constants';
import { getSynchronizationType, showCarrierLoadBoardSynchronization } from '../helpers';
//////////////////////////////////////////////////

const GeneralDetailPageActions = (props: Object) => {
  const {
    formValues,
    createWatchRequest,
    handleSendOnboardingPackage,
    availableForCarrierLoadBoards,
    loadBoardSynchronizationParams,
    addOrRemoveCarrierWatchRequest,
    updateCarrierGeneralDetailsRequest,
    handleGetCarrierSynchronizationAction,
  } = props;

  const { guid, usDotNumber, addedToWatchList } = formValues;
  const { addedToNetwork, allowedBookItNow } = loadBoardSynchronizationParams;

  const permissions = [PC.CARRIER_WRITE];
  let pageHeaderActions = [
    {
      title: G.getWindowLocale('titles:onboarding-package', 'Onboarding Package'),
      actions: [
        {
          permissions,
          action: handleSendOnboardingPackage,
          text: G.getWindowLocale('titles:send-onboarding-package', 'Send Onboarding Package'),
        },
      ],
    },
  ];
  if (R.or(G.showSaferWatch(), G.showCarrierSynchronization())) {
    const watchAction = G.ifElse(
      G.showCarrierSynchronization(),
      addOrRemoveCarrierWatchRequest,
      createWatchRequest,
    );
    const handleCreateWatchRequest = () => watchAction({ guid, usDotNumber, watch: R.not(addedToWatchList) });
    const synchronizationActions = {
      title: getSynchronizationType(),
      actions: [
        {
          permissions,
          action: handleCreateWatchRequest,
          text: G.ifElse(
            addedToWatchList,
            G.getWindowLocale('actions:remove-from-watch-list', 'Remove From Watch List'),
            G.getWindowLocale('actions:add-to-watch-list', 'Add To Watch List'),
          ),
        },
        {
          permissions,
          action: updateCarrierGeneralDetailsRequest,
          text: `${G.getWindowLocale('titles:sync-with', 'Sync with')} ${getSynchronizationType()}`,
        },
      ],
    };
    if (G.isFalse(G.showCarrierSynchronization())) {
      const saferWatchLink = {
        permissions,
        type: 'link',
        iconName: 'truckStop',
        link: SAFER_WATCH_PROFILE_LINK_ADDRESS(usDotNumber),
        text: G.getWindowLocale('titles:go-to-safer-watch-profile', 'Go to SaferWatch profile'),
      };
      synchronizationActions.actions = R.append(saferWatchLink, synchronizationActions.actions);
    }
    pageHeaderActions = R.append(synchronizationActions, pageHeaderActions);
  }

  if (showCarrierLoadBoardSynchronization(availableForCarrierLoadBoards)) {
    const truckerToolsActions = {
      title: G.getWindowLocale('titles:load-board-synchronization', 'Load Board Synchronization'),
      actions: [
        {
          permissions,
          action: () => handleGetCarrierSynchronizationAction(
            'addOrRemoveToNetworkCarrierRequest',
            GC.FIELD_CARRIER_ADDED_TO_NETWORK,
          ),
          text: G.ifElse(
            addedToNetwork,
            G.getWindowLocale('titles:remove-from-network', 'Remove From Network'),
            G.getWindowLocale('titles:add-to-network', 'Add To Network'),
          ),
        },
        {
          permissions,
          action: () => handleGetCarrierSynchronizationAction(
            'allowOrForbidBookItNowCarrierRequest',
            GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW,
          ),
          text: G.ifElse(
            allowedBookItNow,
            G.getWindowLocale('titles:forbid-book-it-now', 'Forbid Book it Now'),
            G.getWindowLocale('titles:allow-book-it-now', 'Allow Book it Now'),
          ),
        },
      ],
    };

    pageHeaderActions = R.append(truckerToolsActions, pageHeaderActions);
  }

  return <PageHeaderActions count={0} pageHeaderActions={pageHeaderActions} />;
};

export default GeneralDetailPageActions;
