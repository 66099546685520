import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-profile
import { TableCell } from '../components/table-cells';
import FormGroupTable from '../components/form-group-table';
import { makeSelectRoleOptions, makeSelectTransportationModeOptionsForContracts } from '../selectors';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fieldsWrapperStyles = { flexDirection: 'column' };

const fields = {
  [GC.FIELD_NAME]: {
    width: 150,
    isRequired: true,
    name: 'titles:name',
  },
  // TODO: uncomment when be will be done
  // [GC.FIELD_VENDOR]: {
  //   width: 200,
  //   name: 'titles:vendor',
  //   customLogic: (value: string) => G.getEnumLocale(value),
  // },
  [GC.FIELD_EFFECTIVE_DATE]: {
    width: 120,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:effective-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 120,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:expiration-date',
  },
  [GC.FIELD_ACTIVE]: {
    width: 120,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:active',
  },
  [GC.FIELD_TRANSPORTATION_MODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:mode',
  },
  [GC.FIELD_MODE]: {
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:mode',
    options: 'transportationModeOptions',
  },
  [GC.FIELD_CURRENCY]: {
    width: 120,
    type: 'select',
    isRequired: true,
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 120,
    name: 'titles:min-rate',
  },
  [GC.FIELD_ROLE_GUIDS]: {
    width: 300,
    isMulti: true,
    type: 'reactSelect',
    name: 'titles:roles',
    options: 'roleOptions',
    customComponent: ({ data, callbackData }: Object) =>
      <TableCell text={R.prop(GC.FIELD_ROLE_GUIDS, data)} options={R.prop('roleOptions', callbackData)} />,
  },
};

const validationSchema = Yup.object().shape(({
  ...R.map(({ isRequired }: Object) => G.ifElse(isRequired, G.yupStringRequired), fields),
  [GC.FIELD_EXPIRATION_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_EFFECTIVE_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
}));

const group = {
  fields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'carrierContracts',
  getItemListRequestMethod: 'post',
  tableCallbackDataProps: ['roleOptions'],
  primaryObjectGuidKey: GC.FIELD_CARRIER_GUID,
  actionsPicker: ['goToCarrierContractDetails', 'remove'],
  itemTitleArr: ['titles:carrier-contract', 'Carrier Contract'],
  formGroupTitleArr: ['titles:carrier-contracts', 'Carrier Contracts'],
  omitFormFields: [GC.FIELD_CHARGE_MIN_RATE, GC.FIELD_TRANSPORTATION_MODE_DISPLAYED_VALUE],
  endpoints: {
    remove: 'getCarrierContract',
    createOrUpdate: 'carrierContract',
    list: 'carrierContractsByCarrierGuid',
  },
  omitColumns: [...GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR_2, GC.FIELD_MODE],
  // helpers
  makeOptionsForSelect: R.pick(['roleOptions', 'transportationModeOptions']),
  makeInitialValues: (initialValues: Object = {}, { configGroup }: Object) => {
    const { currency } = initialValues;

    const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, configGroup);

    return {
      ...initialValues,
      [GC.FIELD_CURRENCY]: R.or(currency, branchCurrency),
    };
  },
};

const customerGroup = {
  ...group,
  groupName: 'customerContracts',
  actionsPicker: ['goToCustomerContractDetails', 'remove'],
  itemTitleArr: ['titles:customer-contract', 'Customer Contract'],
  formGroupTitleArr: ['titles:customer-contracts', 'Customer Contracts'],
  endpoints: {
    remove: 'getCustomerContract',
    createOrUpdate: 'customerContract',
    list: 'customersContractByCarrierGuid',
  },
};

const ContractFormGroupTable = ({ group, ...props }: Object) => {
  const roleOptions = useSelector(makeSelectRoleOptions());
  const transportationModeOptions = useSelector(makeSelectTransportationModeOptionsForContracts());

  if (R.isNil(transportationModeOptions)) return null;

  return (
    <FormGroupTable
      {...props}
      roleOptions={roleOptions}
      group={{ ...group, transportationModeOptions }}
      transportationModeOptions={transportationModeOptions}
    />
  );
};

const carrierContractSettings = {
  groupName: 'carrierContracts',
  Component: (props: Object) => <ContractFormGroupTable {...props} group={group} />,
};

const customerContractSettings = {
  groupName: 'customerContracts',
  Component: (props: Object) => <ContractFormGroupTable {...props} group={customerGroup} />,
};

export {
  carrierContractSettings,
  customerContractSettings,
};
