import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { DateRangeMui } from '../../../components/date-range';
// helpers
import * as G from '../../../helpers';
// feature components-story
import { setDateRangeMuiValues } from '../actions';
import { makeSelectDateRangeMuiValues } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const DateRangeMuiComponent = ({ dateRangeMuiValues, setDateRangeMuiValues }: Object) => {
  const description = '<DateRangeMui /> component is used for selecting date ranges.';

  const usage = (
    <ul>
      <li>Parameter `<i>minDate</i>` is the minimum selectable date.</li>
      <li>Parameter `<i>maxDate</i>` is the maximum selectable date.</li>
      <li>Parameter `<i>dateTo</i>` represents the selected end date.</li>
      <li>Parameter `<i>labelTo</i>` is a label for the `To` date input.</li>
      <li>Parameter `<i>dateFrom</i>` represents the selected start date.</li>
      <li>Parameter `<i>width</i>` sets the width of the date input field.</li>
      <li>Parameter `<i>labelFrom</i>` is a label for the `From` date input.</li>
      <li>Parameter `<i>popperPlacement</i>` specifies the placement of the date picker popup.</li>
      <li>Parameter `<i>useNewMuiInputField</i>` determines whether to use the new Material-UI input field.</li>
      <li>Parameter `<i>withIcon</i>` determines whether to display a calendar icon next to the input field.</li>
      <li>Parameter `<i>onSelectDateRange</i>` is a higher-order function that is used as a callback to handle the selection of a date range.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='DateRangeMui'
      description={description}
      path='src/components/date-range'
    >
      <DateRangeMui
        {...dateRangeMuiValues}
        width={120}
        withIcon={true}
        useNewMuiInputField={true}
        popperPlacement='bottom-end'
        onSelectDateRange={setDateRangeMuiValues}
        maxDate={G.momentAddYearsFromCurrent(100)}
        labelTo={G.getWindowLocale('titles:to', 'To')}
        minDate={G.momentSubtractYearsFromCurrent(100)}
        labelFrom={G.getWindowLocale('titles:from', 'From')}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  dateRangeMuiValues: makeSelectDateRangeMuiValues(state),
});

export default connect(mapStateToProps, {
  setDateRangeMuiValues,
})(DateRangeMuiComponent);
