import React from 'react';
// components
import { PageTitle } from '../../../components/page-title';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const PageTitleComponent = () => {
  const description = '<PageTitle /> component renders a title with optional features like a count display, label, status indicator, and additional content.';

  const usage = (
    <ul>
      <li>Parameter `<i>mr</i>` is a margin-right value.</li>
      <li>Parameter `<i>title</i>` is the main title text.</li>
      <li>Parameter `<i>withCount</i>` displays the count.</li>
      <li>Parameter `<i>status</i>` is a status information.</li>
      <li>Parameter `<i>label</i>` is an additional label content.</li>
      <li>Parameter `<i>showStatus</i>` shows the status indicator.</li>
      <li>Parameter `<i>display</i>` is a CSS display property value.</li>
      <li>Parameter `<i>zIndex</i>` is a Z-index value for positioning.</li>
      <li>Parameter `<i>content</i>` is an additional content to display.</li>
      <li>Parameter `<i>textTransform</i>` is a CSS text-transform property value.</li>
      <li>Parameter `<i>count</i>` is the count value displayed at the top-right corner.</li>
      <li>Parameter `<i>pageTitleMarginTop</i>` is a margin-top value for the page title.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='PageTitle'
      description={description}
      path='src/components/page-title'
    >
      <PageTitle
        mr='32px'
        status={true}
        title='Driver'
        display='block'
        showStatus={true}
      />
    </ComponentStoryWrapper>
  );
};

export default PageTitleComponent;
