import * as R from 'ramda';
import React from 'react';
// component
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 300,
    name: 'titles:name',
  },
  [GC.FIELD_DRIVERS]: {
    width: 700,
    name: 'titles:drivers',
    customComponent: ({ data }: Object) => {
      const drivers = R.map((item: Object) => {
        const { fullText } = G.getUserInfo(item);

        return fullText;
      }, data.drivers);
      const text = R.join(', ', drivers);

      return (
        <TextComponent
          width='100%'
          title={text}
          whiteSpace='pre'
          withEllipsis={true}
        >
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DISPATCHERS]: {
    width: 700,
    name: 'titles:dispatchers',
    customComponent: ({ data }: Object) => {
      const dispatchers = R.map((item: Object) => {
        const { fullText } = G.getUserInfo(item);

        return fullText;
      }, data.dispatchers);
      const text = R.join(', ', dispatchers);

      return (
        <TextComponent
          width='100%'
          title={text}
          whiteSpace='pre'
          withEllipsis={true}
        >
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 300,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
};

export const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_NAME },
    { sequence: 2, name: GC.FIELD_DRIVERS },
    { sequence: 3, name: GC.FIELD_DISPATCHERS },
    { sequence: 4, name: GC.FIELD_CREATED_BY },
    { sequence: 5, name: GC.FIELD_CREATED_DATE },
  ],
};
