import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// common
import { getShowDriverOnboardingTabRequest } from '../../../common/actions';
import { makeSelectShowDriverOnboardingTab } from '../../../common/selectors';
// components
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// saga
import { visitPageSaga } from '../../../sagas/common';
// feature fleet-list
import * as A from '../actions';
import { getAvailableReportsRequest } from './';
import { getFilterParamsByReportType } from '../settings';
//////////////////////////////////////////////////

const reportNameToRefScopeNameMap = {
  [GC.FLEET_TRUCK_REPORT]: GC.REF_SCOPE_NAME_FLEET_TRUCK,
  [GC.FLEET_DRIVER_REPORT]: GC.REF_SCOPE_NAME_FLEET_DRIVER,
  [GC.FLEET_VENDOR_REPORT]: GC.REF_SCOPE_NAME_FLEET_VENDOR,
  [GC.FLEET_TRAILER_REPORT]: GC.REF_SCOPE_NAME_FLEET_TRAILER,
};

function* visitFleetListPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { reportType, checkVisitPageType } = payload;

    yield put(A.setInitialState(reportType));

    yield call(visitPageSaga, payload, checkVisitPageType);

    if (R.includes(
      reportType,
      [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT, GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT],
    )) {
      yield put(A.getAccessorialConfigListRequest());

      yield put(A.getConfigsByNamesRequest());
    }

    yield put(openLoader());
    yield put(A.setReportPending());
    yield put(A.setFilterProps(getFilterParamsByReportType(reportType)));
    yield put(getAllAvailableRefTypesByScopeRequest(R.prop(reportType, reportNameToRefScopeNameMap)));

    yield call(getAvailableReportsRequest, { payload: { setUsedReport: true } });

    yield put(A.getItemListRequest());

    if (R.equals(reportType, GC.FLEET_DRIVER_REPORT)) {
      const showDriverOnboardingTab = yield select(makeSelectShowDriverOnboardingTab());

      if (R.isNil(showDriverOnboardingTab)) yield put(getShowDriverOnboardingTabRequest());
    }

    if (R.includes(reportType, [GC.FLEET_EQUIPMENT_SERVICE_REPORT])) {
      yield put(A.getConfigsByNamesRequest());
    }

    yield put(closeLoader());
    break;
  }
}

function* visitFleetListOnFleetProfilePage({ payload }: Object) {
  while (true) { // eslint-disable-line
    const {
      reportType,
      isPageVisited,
      fleetProfileType,
      fleetProfileGuid,
      fleetProfileGuidType,
      additionalSettings = {},
    } = payload;

    if (G.isTrue(isPageVisited)) return yield put(A.setReportType(reportType));

    yield put(openLoader());
    yield put(A.setInitialState(reportType));

    yield put(A.setFleetProfileSettings({
      ...additionalSettings,
      fleetProfileType,
      fleetProfileGuid,
      fleetProfileGuidType,
    }));

    yield put(A.setReportPending());
    yield put(A.setFilterProps(getFilterParamsByReportType(reportType)));
    yield call(getAvailableReportsRequest, { payload: { setUsedReport: true } });
    yield put(A.getItemListRequest());

    yield put(closeLoader());
    break;
  }
}

function* fleetVisitPagesWatcherSaga() {
  yield takeLatest(GC.VISIT_FLEET_TRUCKS_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_VENDOR_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_DRIVER_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_TRAILERS_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE, visitFleetListPage);
  yield takeLatest(GC.VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE, visitFleetListPage);
  // profile
  yield takeLatest(A.visitFleetListOnFleetProfilePage, visitFleetListOnFleetProfilePage);
}

export default fleetVisitPagesWatcherSaga;
