import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const createFuelCardRequest = createAction('createFuelCardRequest');
export const updateFuelCardRequest = createAction('updateFuelCardRequest');
export const removeFuelCardRequest = createAction('removeFuelCardRequest');
export const getFuelCardListSuccess = createAction('getFuelCardListSuccess');
export const setServiceMappingFilter = createAction('setServiceMappingFilter');
export const receivedFuelCardSuccess = createAction('receivedFuelCardSuccess');
export const createEdiConfigsRequest = createAction('createEdiConfigsRequest');
export const updateEdiConfigsRequest = createAction('updateEdiConfigsRequest');
export const removeEdiConfigsRequest = createAction('removeEdiConfigsRequest');
export const getEdiConfigsListSuccess = createAction('getEdiConfigsListSuccess');
export const receivedEdiConfigsSuccess = createAction('receivedEdiConfigsSuccess');
export const removeAdvancePaymentSuccess = createAction('removeAdvancePaymentSuccess');
export const removeAdvancePaymentRequest = createAction('removeAdvancePaymentRequest');
export const removeServiceMappingRequest = createAction('removeServiceMappingRequest');
export const removeServiceMappingSuccess = createAction('removeServiceMappingSuccess');
export const getCustomerBranchListSuccess = createAction('getCustomerBranchListSuccess');
export const getAdvancePaymentListRequest = createAction('getAdvancePaymentListRequest');
export const getServiceMappingListRequest = createAction('getServiceMappingListRequest');
export const getServiceMappingListSuccess = createAction('getServiceMappingListSuccess');
export const getAdvancePaymentListSuccess = createAction('getAdvancePaymentListSuccess');
export const setIntegrationActiveConfigTab = createAction('setIntegrationActiveConfigTab');
export const removeIntegrationConfigRequest = createAction('removeIntegrationConfigRequest');
export const updateIntegrationConfigRequest = createAction('updateIntegrationConfigRequest');
export const createIntegrationConfigRequest = createAction('createIntegrationConfigRequest');
export const getSharedAccessorialListSuccess = createAction('getSharedAccessorialListSuccess');
export const createServiceMappingListRequest = createAction('createServiceMappingListRequest');
export const createServiceMappingListSuccess = createAction('createServiceMappingListSuccess');
export const getIntegrationConfigListSuccess = createAction('getIntegrationConfigListSuccess');
export const getIntegrationConfigListRequest = createAction('getIntegrationConfigListRequest');
export const receivedIntegrationConfigSuccess = createAction('receivedIntegrationConfigSuccess');
export const receivedBranchConfigsByNameSuccess = createAction('receivedBranchConfigsByNameSuccess');
export const createOrUpdateAdvancePaymentSuccess = createAction('createOrUpdateAdvancePaymentSuccess');
export const createOrUpdateAdvancePaymentRequest = createAction('createOrUpdateAdvancePaymentRequest');
export const removeCarrierRateIntegrationRequest = createAction('removeCarrierRateIntegrationRequest');
export const removeCarrierRateIntegrationSuccess = createAction('removeCarrierRateIntegrationSuccess');
export const createOrUpdateServiceMappingRequest = createAction('createOrUpdateServiceMappingRequest');
export const createOrUpdateServiceMappingSuccess = createAction('createOrUpdateServiceMappingRequest');
export const getCarrierRateIntegrationListRequest = createAction('getCarrierRateIntegrationListRequest');
export const receivedAccessorialServiceCodesSuccess = createAction('receivedAccessorialServiceCodesSuccess');
export const receivedCarrierRateIntegrationListSuccess = createAction('getCarrierRateIntegrationListSuccess');
export const removeLoadBoardIntegrationConfigRequest = createAction('removeLoadBoardIntegrationConfigRequest');
export const removeLoadBoardIntegrationConfigSuccess = createAction('removeLoadBoardIntegrationConfigSuccess');
export const getLoadBoardIntegrationConfigListRequest = createAction('getLoadBoardIntegrationConfigListRequest');
export const getLoadBoardIntegrationConfigListSuccess = createAction('getLoadBoardIntegrationConfigListSuccess');
export const createOrUpdateCarrierRateIntegrationRequest = createAction('createOrUpdateCarrierRateIntegrationRequest');
export const createOrUpdateCarrierRateIntegrationSuccess = createAction('createOrUpdateCarrierRateIntegrationSuccess');
export const createOrUpdateLoadBoardIntegrationConfigRequest =
  createAction('createOrUpdateLoadBoardIntegrationConfigRequest');
export const createOrUpdateLoadBoardIntegrationConfigSuccess =
  createAction('createOrUpdateLoadBoardIntegrationConfigSuccess');
// toll charges
export const removeTollChargesConfigSuccess = createAction('removeTollChargesConfigSuccess');
export const removeTollChargesConfigRequest = createAction('removeTollChargesConfigRequest');
export const getTollChargesConfigListSuccess = createAction('getTollChargesConfigListSuccess');
export const getTollChargesConfigListRequest = createAction('getTollChargesConfigListRequest');
export const createOrUpdateTollChargesConfigRequest = createAction('createOrUpdateTollChargesConfigRequest');
export const createOrUpdateTollChargesConfigSuccess = createAction('createOrUpdateTollChargesConfigSuccess');
// dispatch integration
export const removeDispatchIntegrationConfigRequest = createAction('removeDispatchIntegrationConfigRequest');
export const removeDispatchIntegrationConfigSuccess = createAction('removeDispatchIntegrationConfigSuccess');
export const getDispatchIntegrationConfigListSuccess = createAction('getDispatchIntegrationConfigListSuccess');
export const getDispatchIntegrationConfigListRequest = createAction('getDispatchIntegrationConfigListRequest');
export const createOrUpdateDispatchIntegrationConfigRequest =
  createAction('createOrUpdateDispatchIntegrationConfigRequest');
export const createOrUpdateDispatchIntegrationConfigSuccess =
  createAction('createOrUpdateDispatchIntegrationConfigSuccess');
// terminal integration
export const removeTerminalIntegrationConfigRequest = createAction('removeTerminalIntegrationConfigRequest');
export const removeTerminalIntegrationConfigSuccess = createAction('removeTerminalIntegrationConfigSuccess');
export const getTerminalIntegrationConfigListSuccess = createAction('getTerminalIntegrationConfigListSuccess');
export const getTerminalIntegrationConfigListRequest = createAction('getTerminalIntegrationConfigListRequest');
export const createOrUpdateTerminalIntegrationConfigRequest =
  createAction('createOrUpdateTerminalIntegrationConfigRequest');
export const createOrUpdateTerminalIntegrationConfigSuccess =
  createAction('createOrUpdateTerminalIntegrationConfigSuccess');
// edi extractor config
export const createEdiExtractorConfigRequest = createAction('createEdiExtractorConfigRequest');
export const updateEdiExtractorConfigRequest = createAction('updateEdiExtractorConfigRequest');
export const removeEdiExtractorConfigRequest = createAction('removeEdiExtractorConfigRequest');
export const getEdiExtractorConfigListSuccess = createAction('getEdiExtractorConfigListSuccess');
export const receivedEdiExtractorConfigSuccess = createAction('receivedEdiExtractorConfigSuccess');
// edi exporter config
export const createEdiExporterConfigRequest = createAction('createEdiExporterConfigRequest');
export const updateEdiExporterConfigRequest = createAction('updateEdiExporterConfigRequest');
export const removeEdiExporterConfigRequest = createAction('removeEdiExporterConfigRequest');
export const getEdiExporterConfigListSuccess = createAction('getEdiExtractorConfigListSuccess');
export const receivedEdiExporterConfigSuccess = createAction('receivedEdiExtractorConfigSuccess');
// status code mapping
export const removeStatusCodeMappingRequest = createAction('removeStatusCodeMappingRequest');
export const removeStatusCodeMappingSuccess = createAction('removeStatusCodeMappingSuccess');
export const getStatusCodeMappingListRequest = createAction('getStatusCodeMappingListRequest');
export const getStatusCodeMappingListSuccess = createAction('getStatusCodeMappingListSuccess');
export const createOrUpdateStatusCodeMappingRequest = createAction('createOrUpdateStatusCodeMappingRequest');
export const createOrUpdateStatusCodeMappingSuccess = createAction('createOrUpdateStatusCodeMappingSuccess');
// status reason code mapping
export const removeStatusReasonCodeMappingRequest = createAction('removeStatusReasonCodeMappingRequest');
export const removeStatusReasonCodeMappingSuccess = createAction('removeStatusReasonCodeMappingSuccess');
export const getStatusReasonCodeMappingListRequest = createAction('getStatusReasonCodeMappingListRequest');
export const getStatusReasonCodeMappingListSuccess = createAction('getStatusReasonCodeMappingListSuccess');
export const createOrUpdateStatusReasonCodeMappingRequest =
  createAction('createOrUpdateStatusReasonCodeMappingRequest');
export const createOrUpdateStatusReasonCodeMappingSuccess =
  createAction('createOrUpdateStatusReasonCodeMappingSuccess');
// public clo
export const getPublicCLOConfigRequest = createAction('getPublicCLOConfigRequest');
export const getPublicCLOConfigSuccess = createAction('getPublicCLOConfigSuccess');
export const removePublicCLOConfigSuccess = createAction('removePublicCLOConfigSuccess');
export const removePublicCLOConfigRequest = createAction('removePublicCLOConfigRequest');
export const createOrUpdatePublicCLOConfigRequest = createAction('createOrUpdatePublicCLOConfigRequest');
// service type mapping
export const removeServiceTypeMappingRequest = createAction('removeServiceTypeMappingRequest');
export const removeServiceTypeMappingSuccess = createAction('removeServiceTypeMappingSuccess');
export const getServiceTypeMappingListRequest = createAction('getServiceTypeMappingListRequest');
export const getServiceTypeMappingListSuccess = createAction('getServiceTypeMappingListSuccess');
export const createOrUpdateServiceTypeMappingRequest = createAction('createOrUpdateServiceTypeMappingRequest');
export const createOrUpdateServiceTypeMappingSuccess = createAction('createOrUpdateServiceTypeMappingSuccess');
// carrier synchronization
export const getCarrierSynchronizationConfigSuccess = createAction('getCarrierSynchronizationConfigSuccess');
export const getCarrierSynchronizationConfigRequest = createAction('getCarrierSynchronizationConfigRequest');
export const removeCarrierSynchronizationConfigSuccess = createAction('removeCarrierSynchronizationConfigSuccess');
export const removeCarrierSynchronizationConfigRequest = createAction('removeCarrierSynchronizationConfigRequest');
export const createOrUpdateCarrierSynchronizationConfigRequest =
  createAction('createOrUpdateCarrierSynchronizationConfigRequest');
// user monitoring
export const removeUserMonitoringIntegrationRequest = createAction('removeUserMonitoringIntegrationRequest');
export const removeUserMonitoringIntegrationSuccess = createAction('removeUserMonitoringIntegrationSuccess');
export const getUserMonitoringIntegrationListRequest = createAction('getUserMonitoringIntegrationListRequest');
export const receivedUserMonitoringIntegrationListSuccess = createAction('receivedUserMonitoringIntegrationListSuccess');
export const createOrUpdateUserMonitoringIntegrationSuccess = createAction('createOrUpdateUserMonitoringIntegrationSuccess');
export const createOrUpdateUserMonitoringIntegrationRequest = createAction('createOrUpdateUserMonitoringIntegrationRequest');
// carrier integration override
export const removeCarrierIntegrationOverrideRequest = createAction('removeCarrierIntegrationOverrideRequest');
export const removeCarrierIntegrationOverrideSuccess = createAction('removeCarrierIntegrationOverrideSuccess');
export const getCarrierIntegrationOverrideListRequest = createAction('getCarrierIntegrationOverrideListRequest');
export const getCarrierIntegrationOverrideListSuccess = createAction('getCarrierIntegrationOverrideListSuccess');
export const createOrUpdateCarrierIntegrationOverrideRequest =
  createAction('createOrUpdateCarrierIntegrationOverrideRequest');
export const createOrUpdateCarrierIntegrationOverrideSuccess =
  createAction('createOrUpdateCarrierIntegrationOverrideSuccess');
// document type mapping
export const removeDocumentTypeMappingRequest = createAction('removeDocumentTypeMappingRequest');
export const removeDocumentTypeMappingSuccess = createAction('removeDocumentTypeMappingSuccess');
export const getDocumentTypeMappingListSuccess = createAction('getDocumentTypeMappingListSuccess');
export const getDocumentTypeMappingListRequest = createAction('getDocumentTypeMappingListRequest');
export const createOrUpdateDocumentTypeMappingSuccess = createAction('createOrUpdateDocumentTypeMappingSuccess');
export const createOrUpdateDocumentTypeMappingRequest = createAction('createOrUpdateDocumentTypeMappingRequest');
// document hub config
export const removeDocumentHubConfigRequest = createAction('removeDocumentHubConfigRequest');
export const removeDocumentHubConfigSuccess = createAction('removeDocumentHubConfigSuccess');
export const getDocumentHubConfigListRequest = createAction('getDocumentHubConfigListRequest');
export const getDocumentHubConfigListSuccess = createAction('getDocumentHubConfigListSuccess');
export const createOrUpdateDocumentHubConfigRequest = createAction('createOrUpdateDocumentHubConfigRequest');
export const createOrUpdateDocumentHubConfigSuccess = createAction('createOrUpdateDocumentHubConfigSuccess');
// accounting
export const removeAccountingConfigSuccess = createAction('removeAccountingConfigSuccess');
export const removeAccountingConfigRequest = createAction('removeAccountingConfigRequest');
export const getAccountingConfigListSuccess = createAction('getAccountingConfigListSuccess');
export const createOrUpdateAccountingConfigSuccess = createAction('createOrUpdateAccountingConfigSuccess');
export const createOrUpdateAccountingConfigRequest = createAction('createOrUpdateAccountingConfigRequest');
// carrier pickup requester
export const removeCarrierPickupRequesterSuccess = createAction('removeCarrierPickupRequesterSuccess');
export const removeCarrierPickupRequesterRequest = createAction('removeCarrierPickupRequesterRequest');
export const getCarrierPickupRequesterListRequest = createAction('getCarrierPickupRequesterListRequest');
export const getCarrierPickupRequesterListSuccess = createAction('getCarrierPickupRequesterListSuccess');
export const createOrUpdateCarrierPickupRequesterRequest = createAction('createOrUpdateCarrierPickupRequesterRequest');
export const createOrUpdateCarrierPickupRequesterSuccess = createAction('createOrUpdateCarrierPickupRequesterSuccess');
export const getAvailableCarrierPickupRequesterListSuccess =
  createAction('getAvailableCarrierPickupRequesterListSuccess');
// carrier edi loader config
export const getCarrierOptionsSuccess = createAction('getCarrierOptionsSuccess');
export const removeCarrierEdiLoaderConfigRequest = createAction('removeCarrierEdiLoaderConfigRequest');
export const removeCarrierEdiLoaderConfigSuccess = createAction('removeCarrierEdiLoaderConfigSuccess');
export const getCarrierEdiLoaderConfigListSuccess = createAction('getCarrierEdiLoaderConfigListSuccess');
export const createOrUpdateCarrierEdiLoaderConfigRequest = createAction('createOrUpdateCarrierEdiLoaderConfigRequest');
export const createOrUpdateCarrierEdiLoaderConfigSuccess = createAction('createOrUpdateCarrierEdiLoaderConfigSuccess');
// carrier edi loader config
export const removeCarrierEdiExporterConfigRequest = createAction('removeCarrierEdiExporterConfigRequest');
export const removeCarrierEdiExporterConfigSuccess = createAction('removeCarrierEdiExporterConfigSuccess');
export const getCarrierEdiExporterConfigListSuccess = createAction('getCarrierEdiExporterConfigListSuccess');
export const createOrUpdateCarrierEdiExporterConfigRequest =
  createAction('createOrUpdateCarrierEdiExporterConfigRequest');
export const createOrUpdateCarrierEdiExporterConfigSuccess =
  createAction('createOrUpdateCarrierEdiExporterConfigSuccess');
// vin lookup config
export const removeVinLookupConfigRequest = createAction('removeVinLookupConfigRequest');
export const removeVinLookupConfigSuccess = createAction('removeVinLookupConfigSuccess');
export const getVinLookupConfigListSuccess = createAction('getVinLookupConfigListSuccess');
export const createOrUpdateVinLookupConfigRequest = createAction('createOrUpdateVinLookupConfigRequest');
export const createOrUpdateVinLookupConfigSuccess = createAction('createOrUpdateVinLookupConfigSuccess');
// factoring integration config
export const removeFactoringIntegrationConfigRequest = createAction('removeFactoringIntegrationConfigRequest');
export const removeFactoringIntegrationConfigSuccess = createAction('removeFactoringIntegrationConfigSuccess');
export const getFactoringIntegrationConfigListRequest = createAction('getFactoringIntegrationConfigListRequest');
export const getFactoringIntegrationConfigListSuccess = createAction('getFactoringIntegrationConfigListSuccess');
export const createOrUpdateFactoringIntegrationConfigRequest =
  createAction('createOrUpdateFactoringIntegrationConfigRequest');
export const createOrUpdateFactoringIntegrationConfigSuccess =
  createAction('createOrUpdateFactoringIntegrationConfigSuccess');
// equipment mapping config
export const removeEquipmentMappingConfigRequest = createAction('removeEquipmentMappingConfigRequest');
export const removeEquipmentMappingConfigSuccess = createAction('removeEquipmentMappingConfigSuccess');
export const getEquipmentMappingConfigListRequest = createAction('getEquipmentMappingConfigListRequest');
export const getEquipmentMappingConfigListSuccess = createAction('getEquipmentMappingConfigListSuccess');
export const createOrUpdateEquipmentMappingConfigRequest =
  createAction('createOrUpdateEquipmentMappingConfigRequest');
export const createOrUpdateEquipmentMappingConfigSuccess =
  createAction('createOrUpdateEquipmentMappingConfigSuccess');
// available edi export mappers
export const getAvailableEdiExportMappersSuccess = createAction('getAvailableEdiExportMappersSuccess');
// trailer tracking integration config
export const removeTrailerTrackingIntegrationConfigRequest =
  createAction('removeTrailerTrackingIntegrationConfigRequest');
export const removeTrailerTrackingIntegrationConfigSuccess =
  createAction('removeTrailerTrackingIntegrationConfigSuccess');
export const getTrailerTrackingIntegrationConfigListSuccess =
  createAction('getEquipmentTrailerTrackingIntegrationListSuccess');
export const createOrUpdateTrailerTrackingIntegrationConfigRequest =
  createAction('createOrUpdateTrailerTrackingIntegrationConfigRequest');
export const createOrUpdateTrailerTrackingIntegrationConfigSuccess =
  createAction('createOrUpdateTrailerTrackingIntegrationConfigSuccess');
// los subscription config
export const unlinkLosAccountRequest = createAction('unlinkLosAccountRequest');
export const unlinkLosAccountSuccess = createAction('unlinkLosAccountSuccess');
export const getLosSubscriptionConfigRequest = createAction('getLosSubscriptionConfigRequest');
export const getLosSubscriptionConfigSuccess = createAction('getLosSubscriptionConfigSuccess');
export const updateLosSubscriptionConfigRequest = createAction('updateLosSubscriptionConfigRequest');
export const updateLosSubscriptionConfigSuccess = createAction('updateLosSubscriptionConfigSuccess');
// los pricing plan config
export const removeLosPricingPlanConfigRequest = createAction('removeLosPricingPlanConfigRequest');
export const removeLosPricingPlanConfigSuccess = createAction('removeLosPricingPlanConfigSuccess');
export const getLosPricingPlanConfigListRequest = createAction('getLosPricingPlanConfigListRequest');
export const getLosPricingPlanConfigListSuccess = createAction('getLosPricingPlanConfigListSuccess');
export const createOrUpdateLosPricingPlanConfigRequest = createAction('createOrUpdateLosPricingPlanConfigRequest');
export const createOrUpdateLosPricingPlanConfigSuccess = createAction('createOrUpdateLosPricingPlanConfigSuccess');
export const removeLosPricingPlanConfigWithSubstitutionRequest =
  createAction('removeLosPricingPlanConfigWithSubstitutionRequest');
// cross border integration config
export const getCrossBorderIntegrationConfigSuccess = createAction('getCrossBorderIntegrationConfigSuccess');
export const removeCrossBorderIntegrationConfigRequest = createAction('removeCrossBorderIntegrationConfigRequest');
export const removeCrossBorderIntegrationConfigSuccess = createAction('removeCrossBorderIntegrationConfigSuccess');
export const createOrUpdateCrossBorderIntegrationConfigRequest =
  createAction('createOrUpdateCrossBorderIntegrationConfigRequest');
export const createOrUpdateCrossBorderIntegrationConfigSuccess =
  createAction('createOrUpdateCrossBorderIntegrationConfigSuccess');
// truck type mapping
export const removeTruckTypeMappingRequest = createAction('removeTruckTypeMappingRequest');
export const removeTruckTypeMappingSuccess = createAction('removeTruckTypeMappingSuccess');
export const getTruckTypeMappingListRequest = createAction('getTruckTypeMappingListRequest');
export const getTruckTypeMappingListSuccess = createAction('getTruckTypeMappingListSuccess');
export const createOrUpdateTruckTypeMappingRequest = createAction('createOrUpdateTruckTypeMappingRequest');
export const createOrUpdateTruckTypeMappingSuccess = createAction('createOrUpdateTruckTypeMappingSuccess');
// trailer type mapping
export const removeTrailerTypeMappingRequest = createAction('removeTrailerTypeMappingRequest');
export const removeTrailerTypeMappingSuccess = createAction('removeTrailerTypeMappingSuccess');
export const getTrailerTypeMappingListRequest = createAction('getTrailerTypeMappingListRequest');
export const getTrailerTypeMappingListSuccess = createAction('getTrailerTypeMappingListSuccess');
export const createOrUpdateTrailerTypeMappingSuccess = createAction('createOrUpdateTrailerTypeMappingSuccess');
export const createOrUpdateTrailerTypeMappingRequest = createAction('createOrUpdateTrailerTypeMappingRequest');
