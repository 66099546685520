import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
export const removeCommissionAssignmentRequest = createAction('removeCommissionAssignmentRequest');
export const removeCommissionAssignmentSuccess = createAction('removeCommissionAssignmentSuccess');
export const getCommissionAssignmentListRequest = createAction('getCommissionAssignmentListRequest');
export const getCommissionAssignmentListSuccess = createAction('getCommissionAssignmentListSuccess');
export const createOrUpdateCommissionAssignmentRequest = createAction('createOrUpdateCommissionAssignmentRequest');
export const createOrUpdateCommissionAssignmentSuccess = createAction('createOrUpdateCommissionAssignmentSuccess');
