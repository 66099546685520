import * as R from 'ramda';
import React from 'react';
// components
import { Map } from '../../../components/map';
import { Directions } from '../../../components/map/components/directions';
import { MarkerWithInfo } from '../../../components/map/components/marker-with-info';
import { StopInfo, StopMarker } from '../../../components/map/components/stop-components';
// items
import { calendar, locationMarker } from '../../../svgs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, SimpleWrapper } from '../../../ui';
// feature route
//////////////////////////////////////////////////

const StatusMessageInfoContent = (props: Object) => {
  const dateString = G.convertDateTimeToConfigFormat(
    R.path([GC.FIELD_LOCATION, GC.FIELD_STATUS_MESSAGE_DATE], props),
  );

  return (
    <SimpleWrapper
      jc='center'
      height='100%'
      maxWidth='250px'
      flexDirection='column'
      alignItems='flex-start'
    >
      {
        G.isNotNilAndNotEmpty(
          R.path(['location', GC.FIELD_STATUS_MESSAGE_CODE, GC.FIELD_DISPLAYED_VALUE], props))
          && (
            <Flex my='2px' alignItems='flex-start'>
              <Box whiteSpace='nowrap'>{`${G.getWindowLocale('titles:status-code', 'Status Code')}:`}</Box>
              <Box ml='5px'>
                {R.path(['location', GC.FIELD_STATUS_MESSAGE_CODE, GC.FIELD_DISPLAYED_VALUE], props)}
              </Box>
            </Flex>
          )
      }
      {
        G.isNotNilAndNotEmpty(
          R.path(['location', GC.FIELD_STATUS_MESSAGE_REASON_CODE, GC.FIELD_DISPLAYED_VALUE], props))
          && (
            <Flex my='2px' alignItems='flex-start'>
              <Box whiteSpace='nowrap'>{`${G.getWindowLocale('titles:status-reason', 'Status Reason')}:`}</Box>
              <Box ml='5px'>
                {R.path(['location', GC.FIELD_STATUS_MESSAGE_REASON_CODE, GC.FIELD_DISPLAYED_VALUE], props)}
              </Box>
            </Flex>
          )
      }
      <Box my='2px'>{calendar()} {`${G.getWindowLocale('titles:date', 'Date')}:`} {dateString}</Box>
      <Box my='2px'>{locationMarker()} {G.concatLocationFields(props.location)}</Box>
    </SimpleWrapper>
  );
};

const StatusMessageMarkerContent = (props: Object) => (
  <SimpleWrapper
    jc='center'
    height='100%'
    alignItems='center'
  >
    {props.location.title}
  </SimpleWrapper>
);

export const renderLocationsWithContent = (location: Object) => (
  {
    ...location,
    infoContent: <StatusMessageInfoContent location={location} />,
    markerContent: <StatusMessageMarkerContent location={location} />,
  }
);

const locationsWithContent = (locations: Array) => (
  locations.map((item: Object) => ({
    ...item,
    infoContent: <StopInfo {...item} />,
    markerContent: <StopMarker {...item} />,
  }))
);

export const StatusMessageMarker = (props: Object) => {
  const locationToTransform = R.concat(
    locationsWithContent(props.mappedEventsForMap),
    props.locations,
  );
  return (
    <MarkerWithInfo
      color={G.getTheme('map.redMarkerColor')}
      infoBorderColor={G.getTheme('map.infoBoxBorderColorRed')}
      locations={G.makeLocationsWithTransform(locationToTransform)} />
  );
};

const makeRouteFromStatusMessages = (locations: Array) => {
  let usedLocations = [];
  const newLocations = R.compose(
    R.filter((item: Object | null) => (
      G.isAllTrue(
        G.isNotNilAndNotEmpty(R.path(['lat'], item)),
        G.isNotNilAndNotEmpty(R.path(['lng'], item)),
        G.notEquals(item, { lat: 0, lng: 0 }),
      )
    )),
    R.map((location: Object) => {
      if (R.includes(location.latLng, usedLocations)) {
        return null;
      }
      usedLocations = R.append(location.latLng, usedLocations);
      return location.latLng;
    }),
  )(R.or(locations, []));
  return newLocations;
};

const arrayReductionWithFirstAndLastElement = (arr: Array, count: number) => {
  if (R.lte(arr.length, count)) return arr;

  const newArr = [];
  const maxVal = R.subtract(count, 2);
  const delta = Math.ceil(R.divide(arr.length, maxVal));

  newArr.push(R.prop(0, arr));

  for (let i = 0; i < arr.length; i += delta) {
    newArr.push(R.prop(i, arr));
  }

  newArr.push(R.prop(R.dec(arr.length), arr));

  return newArr;
};

export const MapWithStatusMessages = (props: Object) => {
  let lastStop = R.head(props.mappedEventsForMap);
  let locations = [];
  let statusLocations = [];
  if (props.statusMessages) {
    const statusMessagesToUse = arrayReductionWithFirstAndLastElement(props.statusMessages, 25);
    lastStop = R.head(statusMessagesToUse);
    locations = R.compose(
      R.values,
      G.mapIndexed((statusMessage: Object, index: number) => renderLocationsWithContent(
        {
          ...statusMessage,
          title: R.inc(index),
          color: G.getTheme('map.redMarkerColor'),
          infoBorderColor: G.getTheme('map.infoBoxBorderColorRed'),
          latLng: { lat: statusMessage.latitude, lng: statusMessage.longitude },
        },
      )),
      R.filter(({ latitude, longitude }: Object = {}) => G.isAllNotNilOrNotEmpty([latitude, longitude])),
      R.reverse,
    )(statusMessagesToUse);
    statusLocations = makeRouteFromStatusMessages(locations);
  }
  return (
    <Box
      width='100%'
      height='100%'
      boxShadow='0 0 10px 0 rgba(205, 205, 205, 0.5)'
    >
      <Map
        height='100%'
        center={{
          lat: R.pathOr(38.755157, ['latitude'], lastStop),
          lng: R.pathOr(-98.269035, ['longitude'], lastStop),
        }}
      >
        {
          props.mappedEventsForMap &&
          <Directions
            locations={props.mappedEventsForMap.map((location: Object) => location.latLng)} />
        }
        {
          R.gte(R.pathOr(0, ['length'], statusLocations), 2) &&
          <Directions locations={statusLocations} options={{ color: '#3bbd21' }} />
        }
        <StatusMessageMarker {...props} locations={locations} />
      </Map>
    </Box>
  );
};

const StatusMessageMapComponent = (props: Object) => (
  <SimpleWrapper flexDirection='column' height='500px' width='900px'>
    <SimpleWrapper jc='space-between' height='max-content' mb='15px' width='100%'>
      <Box fontSize={16} fontWeight='bold'>
        {G.getWindowLocale('titles:map', 'Map')}
      </Box>
      <Box
        p='5px 15px'
        cursor='pointer'
        border='1px solid'
        width='max-content'
        onClick={props.closeModal}
        bg={G.getTheme('detailPage.stops.redBgColor')}
        color={G.getTheme('detailPage.stops.whiteTextColor')}
      >
        {G.getWindowLocale('actions:close-map', 'Close Map')}
      </Box>
    </SimpleWrapper>
    <SimpleWrapper height='100%' width='100%'>
      <MapWithStatusMessages
        statusMessages={props.statusMessages}
        mappedEventsForMap={props.mappedEventsForMap} />
    </SimpleWrapper>
  </SimpleWrapper>
);

export default StatusMessageMapComponent;
