import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { getConfigByGroupSaga } from '../../configurations/sagas';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { getAllAvailableRefTypesByScopeRequestSaga } from '../../reference/sagas';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-report/route
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader());
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('routeDefault', reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const reqBody = {
      guids,
      orderFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.routeList, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectFilterParams());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);

    const reqBody = {
      searchCriteria,
      fields: payload.fields,
      orderFields: payload.orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };
    const options = {
      resType: 'arraybuffer',
      params: { format: payload.fileType },
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.routeListExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.ROUTE_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

function* handleVisitRouteListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_ROUTE_REPORT_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_TEL });
    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_CLO });

    yield call(getConfigByGroupSaga, GC.GENERAL_CONFIG_GROUP);

    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* routeReportListWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(GC.VISIT_ROUTE_REPORT_LIST_PAGE, handleVisitRouteListPageSaga);
}

export default routeReportListWatcherSaga;
