import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet/driver
import {
  payLocationFieldSettings,
  payLocationFieldSettings2,
  payToLocationDefaultFields,
  payToLocationValidationSchema,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(payToLocationValidationSchema),
    handleSubmit: (values: Object, { props }: Object) => {
      const data = R.compose(
        R.assoc(GC.FIELD_ADDRESS, G.getPropFromObject(GC.FIELD_ADDRESS_1, values)),
        R.pick(R.keys(payToLocationDefaultFields)),
      )(values);
      props.submitAction(data);
    },
    mapPropsToValues: ({ initialValues }: Object) =>
      G.setInitialFormikValues(payToLocationDefaultFields, initialValues),
  }),
  pure,
);

const getFields = ({ isServiceVendor }: Object) => G.ifElse(
  G.isTrue(isServiceVendor),
  payLocationFieldSettings2,
  payLocationFieldSettings,
);

const PayToLocationForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent {...G.getFormikProps(props)} fields={getFields(props)} />
    <FormFooter boxStyles={{ p: 15 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(PayToLocationForm);
