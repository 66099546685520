import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
// feature branch
import BranchTree from './branch-tree';
import { changeEntityBranch } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleSelectBranch: (props: Object) => (branchGuid: string) => {
      const { guid, type, callback } = props;
      props.changeEntityBranch({
        type,
        callback,
        data: { guid, newEnterpriseGuid: branchGuid },
      });
    },
  }),
  pure,
);

const ChangeEntityBranch = (props: Object) => {
  const { closeModal, branchTypeFilter, handleSelectBranch } = props;

  return (
    <BranchTree
      width={700}
      cursor='pointer'
      closeAction={closeModal}
      branchActiveFilter={true}
      onClickBranch={handleSelectBranch}
      branchTypeFilter={branchTypeFilter}
      handleMultipleSelect={handleSelectBranch} />
  );
};

export default connect(null, {
  openModal,
  closeModal,
  changeEntityBranch,
})(enhance(ChangeEntityBranch));
