import React from 'react';
import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature drivers-card
import CardNotes from '../components/card-notes';
/////////////////////////////////////////////////

const withCardNotes = compose(
  withFixedPopover,
  withHandlers({
    handleNoteClick: (props: Object) => (e: Object) => {
      const { guid } = props;

      const loadType = GC.LOAD_TYPE_TEL;

      const {
        openModal,
        closeModal,
        openFixedPopup,
        closeFixedPopup,
      } = props;

      const contentProps = {
        loadType,
        openModal,
        closeModal,
        width: 350,
        height: 400,
        padding: '8px',
        loadGuid: guid,
        addPermissions: null,
        editPermissions: null,
        closeContent: closeFixedPopup,
        addMessageText: G.getWindowLocale('actions:post', 'Post'),
      };

      const content = <CardNotes {...contentProps} />;

      openFixedPopup({
        content,
        version: 2,
        position: 'center',
        el: e.currentTarget,
        boxStyles: { zIndex: 2101 },
        shouldNotCloseOnScroll: true,
      });
    },
    patchUpdateNote: (props: Object) => async (values: Object, successCallback: Function) => {
      const { guid, openLoader, closeLoader } = props;

      G.callFunction(openLoader);

      const method = G.ifElse(G.isNilOrEmpty(G.getGuidFromObject(values)), 'post', 'put');

      const reqData = R.assoc(GC.FIELD_LOAD_GUID, guid, values);

      try {
        const res = await sendRequest(method, endpointsMap.telNote, { data: reqData });

        const { data, status } = res;

        if (G.isResponseSuccess(status, data)) {
          G.callFunction(successCallback);
        } else {
          G.handleFailResponseSimple(
            res,
            true,
            'withNoteCRUD -> patchUpdateNote',
          );
        }
        G.callFunction(closeLoader);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'withNoteCRUD -> patchUpdateNote');
      }
    },
  }),
);

export {
  withCardNotes,
};
