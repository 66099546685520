import React from 'react';
import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components';
// features
import LocationForm from '../../location/components/location-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet
import * as LC from '../constants';
//////////////////////////////////////////////////

export const withAddOrEditLocation = ({ fleetType }: Object) => compose(
  withHandlers({
    handleClickAddOrEditLocation: (props: Object) => (location: Object = null) => {
      const {
        truckGuid,
        openModal,
        closeModal,
        trailerGuid,
        updateTruckLocationRequest,
        createTruckLocationRequest,
        updateTrailerLocationRequest,
        createTrailerLocationRequest,
      } = props;

      const isEditMode = G.isNotNilAndNotEmpty(location);

      const submitActions = {
        [LC.FLEET_TRAILER]: (data: Object) => {
          const reqData = R.assoc(LC.FLEET_TRAILER_GUID, trailerGuid, data);

          if (isEditMode) return updateTrailerLocationRequest(reqData);

          createTrailerLocationRequest(reqData);
        },
        [LC.FLEET_TRUCK]: (data: Object) => {
          const reqData = R.assoc(LC.FLEET_TRUCK_GUID, truckGuid, data);

          if (isEditMode) return updateTruckLocationRequest(reqData);

          createTruckLocationRequest(reqData);
        },
      };

      const modalContext = (
        <LocationForm
          entity={location}
          openModal={openModal}
          closeModal={closeModal}
          guid={R.pathOr(null, [GC.FIELD_GUID], location)}
          submitAction={G.getPropFromObject(fleetType, submitActions)}
        />
      );

      const getTitle = G.ifElse(isEditMode, G.getEditTitle, G.getAddTitle);

      const modal = {
        p: '0',
        component: modalContext,
        options: {
          width: 'auto',
          height: 'auto',
          title: getTitle(['titles:location', 'Location']),
        },
      };

      openModal(modal);
    },
    handleDeleteLocation: (props: Object) => ({ guid }: Object) => {
      const { openModal, closeModal, deleteTruckLocationRequest, deleteTrailerLocationRequest } = props;

      const component = (
        <ConfirmComponent
          textLocale={
            G.getWindowLocale('messages:location-delete-confirmation', 'Are you sure you want delete location?')
          }
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                closeModal();

                if (R.equals(fleetType, LC.FLEET_TRAILER)) {
                  return deleteTrailerLocationRequest(guid);
                }

                return deleteTruckLocationRequest(guid);
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
);
