import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncSequence } from '../../../hocs';
// feature branch
import { ADD_BRANCH_FORM } from '../constants';
import {
  getValidationSchema,
  defaultBranchValues,
  setInitialBranchFormikValues,
} from '../settings/formik-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('origSequence', 'setOrigSequence', null),
  withState('subTypeOptions', 'setSubTypeOptions', []),
  withFormik({
    enableReinitialize: true,
    displayName: ADD_BRANCH_FORM,
    mapPropsToValues: ({ initialValues }: Object) => setInitialBranchFormikValues(
      defaultBranchValues,
      initialValues,
    ),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getValidationSchema(values))
    )),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { origSequence, handleSubmitCreateBranch } = props;

      setSubmitting(true);

      if (R.equals(R.prop(GC.FIELD_BRANCH_ACCOUNT_NAME, values), origSequence)) {
        handleSubmitCreateBranch(R.assoc(GC.FIELD_BRANCH_ACCOUNT_NAME, null, values));
      } else {
        handleSubmitCreateBranch(values);
      }

      setTimeout(() => setSubmitting(false), 3000);
    },
  }),
  withAsyncSequence,
  withPropsOnChange(
    ['asyncSequence'],
    (props: Object) => {
      const { asyncSequence, setFieldValue, setOrigSequence } = props;

      if (G.isNotNil(asyncSequence)) {
        setOrigSequence(asyncSequence);
        setFieldValue(GC.FIELD_BRANCH_ACCOUNT_NAME, asyncSequence);
      }
    },
  ),
  withPropsOnChange(
    ['asyncConfigs'],
    (props: Object) => {
      const { asyncConfigs, setSubTypeOptions, handleSetLocationTypeOption } = props;

      if (G.isNotNilAndNotEmpty(R.path(['dropdowns', GC.GENERAL_BRANCH_SUBTYPE, 'options'], asyncConfigs))) {
        setSubTypeOptions(
          G.createOptionsFromDropdownConfig(asyncConfigs, GC.GENERAL_BRANCH_SUBTYPE, GC.FIELD_GUID, true),
        );
      }

      if (G.isNotNilAndNotEmpty(R.path(['dropdowns', GC.TEMPLATES_LOCATION_TYPE, 'options'], asyncConfigs))) {
        handleSetLocationTypeOption(
          G.createOptionsFromDropdownConfig(asyncConfigs, GC.TEMPLATES_LOCATION_TYPE, GC.FIELD_GUID, true),
        );
      }
    },
  ),
  pure,
);

const AddBranchForm = enhance((props: Object) => {
  const { fields, handleSubmit, isSubmitting, subTypeOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        subTypeOptions={subTypeOptions}
        fieldsWrapperStyles={{ mt: 25, justifyContent: 'space-between' }}
      />
      <FormFooter2 submitDisabled={isSubmitting} />
    </form>
  );
});

export default AddBranchForm;
