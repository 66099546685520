import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderNothing } from 'react-recompose';
// common
import { createLoadDocumentsRequest, sendTelToExternalSystemRequest } from '../../../../common/actions';
// components
import { LoadStatusActions } from '../../../../components/load-status-actions';
// features
import { makeSelectAvailableDocumentTypes } from '../../../configurations/selectors';
import { withDispatchDetailsLoadActions } from '../../../dispatch-board-new/hocs/with-load-actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex } from '../../../../ui';
// feature dispatch-details-new
import { withAddTableEntity } from '../hocs';
import {
  makeSelectStatusMessageConfigs,
  makeSelectDeclineReasonOptions,
  makeSelectIsAnyEnabledCrossBorder,
} from '../selectors';
import {
  approveLoadRateRequest,
  dispatchLoadRateRequest,
  updateAppointmentRequest,
  sendUpdateEdiOrApiRequest,
  createDriverInvoiceRequest,
  createLoadDriverRateRequest,
  updateLoadDriverRateRequest,
  createLoadCarrierRateRequest,
  updateLoadCarrierRateRequest,
  createLoadStatusMessageRequest,
  createLoadCarrierInvoiceRequest,
  acceptLoadDriverCarrierRateRequest,
  declineLoadDriverCarrierRateRequest,
} from '../actions';
//////////////////////////////////////////////////

export const defaultTelActionPanel = [
  {
    secondRow: [],
    status: GC.LOAD_STATUS_PLANNED,
    firstRow: [
      GC.ADD_DRIVER_RATE_ACTION,
      GC.ADD_CARRIER_RATE_ACTION,
      GC.SEND_QUOTE_REQUEST_ACTION,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
  },
  {
    status: GC.LOAD_STATUS_RATED,
    secondRow: [GC.DISPATCH_LOAD_ACTION, GC.OPEN_IN_ROUTE_BUILDER_ACTION, GC.APPLY_ORDER_RATE_UPLIFT_ACTION],
    firstRow: [
      GC.ADD_DRIVER_RATE_ACTION,
      GC.ADD_CARRIER_RATE_ACTION,
      GC.SEND_QUOTE_REQUEST_ACTION,
      GC.EDIT_DRIVER_CARRIER_RATE_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
  },
  {
    secondRow: [],
    status: GC.LOAD_STATUS_DISPATCHED,
    firstRow: [
      GC.ACCEPT_LOAD_ACTION,
      GC.DECLINE_LOAD_ACTION,
      GC.CANCEL_DISPATCHED_LOAD_ACTION,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.APPLY_ORDER_RATE_UPLIFT_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
  },
  {
    status: GC.LOAD_STATUS_DISPATCH_EXPIRED,
    secondRow: [GC.EDIT_DRIVER_CARRIER_RATE_ACTION, GC.RE_DISPATCH_LOAD_ACTION],
    firstRow: [
      GC.ADD_DRIVER_RATE_ACTION,
      GC.ADD_CARRIER_RATE_ACTION,
      GC.SEND_QUOTE_REQUEST_ACTION,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
  },
  {
    status: GC.LOAD_STATUS_DISPATCH_REJECTED,
    secondRow: [GC.EDIT_DRIVER_CARRIER_RATE_ACTION, GC.RE_DISPATCH_LOAD_ACTION],
    firstRow: [
      GC.ADD_DRIVER_RATE_ACTION,
      GC.ADD_CARRIER_RATE_ACTION,
      GC.SEND_QUOTE_REQUEST_ACTION,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
  },
  {
    status: GC.LOAD_STATUS_BOOKED_STATUS,
    firstRow: [
      GC.CALL_STATUS_CHECK_ACTION,
      GC.CHECK_IN_ACTION,
      GC.COMPLETE_ACTION,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
    secondRow: [
      GC.ADD_DOCUMENT_ACTION,
      GC.ADD_DOCUMENT_POP_ACTION,
      GC.GET_INTEGRATION_DOCUMENTS_ACTION,
      GC.ADD_APPOINTMENT_ACTION,
      GC.CANCEL_DISPATCHED_LOAD_ACTION,
      GC.SEND_TO_EXTERNAL_SYSTEM_ACTION,
      GC.SEND_UPDATE_EDI_OR_API_ACTION,
      GC.APPLY_ORDER_RATE_UPLIFT_ACTION,
    ],
  },
  {
    status: GC.LOAD_STATUS_IN_TRANSIT,
    secondRow: [
      GC.ADD_DOCUMENT_ACTION,
      GC.ADD_DOCUMENT_POP_ACTION,
      GC.GET_INTEGRATION_DOCUMENTS_ACTION,
      GC.ADD_APPOINTMENT_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
    firstRow: [
      GC.CALL_STATUS_CHECK_ACTION,
      GC.CHECK_IN_ACTION,
      GC.COMPLETE_ACTION,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.SEND_TO_EXTERNAL_SYSTEM_ACTION,
      GC.SEND_UPDATE_EDI_OR_API_ACTION,
      GC.APPLY_ORDER_RATE_UPLIFT_ACTION,

    ],
  },
  {
    secondRow: [GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION],
    status: GC.LOAD_STATUS_DELIVERED,
    firstRow: [
      GC.ADD_DRIVER_CARRIER_INVOICE,
      GC.ADD_DOCUMENT_POD_ACTION,
      GC.GET_INTEGRATION_DOCUMENTS_ACTION,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.SEND_TO_EXTERNAL_SYSTEM_ACTION,
      GC.APPLY_ORDER_RATE_UPLIFT_ACTION,
    ],
  },
  {
    secondRow: [],
    status: GC.LOAD_STATUS_CANCELED,
    firstRow: [
      GC.ADD_DRIVER_CARRIER_INVOICE,
      GC.OPEN_IN_ROUTE_BUILDER_ACTION,
      GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION,
    ],
  },
];

const enhance = compose(
  withAddTableEntity,
  withDispatchDetailsLoadActions,
  branch(
    (props: Object) => G.isNilOrEmpty(props.load),
    renderNothing,
  ),
  pure,
);

const ActionsCenter = (props: Object) => {
  const {
    load,
    handleActionClick,
    isAnyEnabledCrossBorder,
    selectedCarrierDriverRate,
  } = props;

  const status = G.getPropFromObject(GC.FIELD_STATUS, load);

  let data = R.compose(
    R.pathOr(null, [status]),
    R.indexBy(R.prop(GC.FIELD_STATUS)),
  )(defaultTelActionPanel);

  const dataFirstRow = R.pathOr([], [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW], data);
  const dataSecondRow = R.pathOr([], [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW], data);

  if (R.and(R.equals(status, GC.LOAD_STATUS_CANCELED), G.isNilOrEmpty(selectedCarrierDriverRate))) {
    const firstRow = R.without([GC.ADD_DRIVER_CARRIER_INVOICE], dataFirstRow);
    const secondRow = R.without([GC.ADD_DRIVER_CARRIER_INVOICE], dataSecondRow);

    data = R.mergeRight(data, { firstRow, secondRow });
  }

  if (R.not(R.path([GC.FIELD_SEND_TO_EDI], selectedCarrierDriverRate))) {
    const firstRow = R.without([GC.SEND_UPDATE_EDI_OR_API_ACTION], dataFirstRow);
    const secondRow = R.without([GC.SEND_UPDATE_EDI_OR_API_ACTION], dataSecondRow);

    data = R.mergeRight(data, { firstRow, secondRow });
  }

  const apiCarrier = R.path(
    [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.SYSTEM_OBJECT_TERMINAL_SNAPSHOT, GC.FIELD_API_CARRIER],
    selectedCarrierDriverRate,
  );

  if (G.notContain(apiCarrier, [GC.DOCUMENT_TYPE_MAPPING_TYPE_SAIA])) {
    const firstRow = R.without([GC.GET_INTEGRATION_DOCUMENTS_ACTION], dataFirstRow);
    const secondRow = R.without([GC.GET_INTEGRATION_DOCUMENTS_ACTION], dataSecondRow);

    data = R.mergeRight(data, { firstRow, secondRow });
  }

  if (G.isNotNilAndNotEmpty(selectedCarrierDriverRate)) {
    const isCarrierRate = G.isRateTypeCarrierRate(G.getPropFromObject(GC.FIELD_TYPE, selectedCarrierDriverRate));

    if (G.getHasNotEditRatePermissionsByRateType(isCarrierRate)) {
      const firstRow = R.without([GC.EDIT_DRIVER_CARRIER_RATE_ACTION], dataFirstRow);
      const secondRow = R.without([GC.EDIT_DRIVER_CARRIER_RATE_ACTION], dataSecondRow);

      data = R.mergeRight(data, { firstRow, secondRow });
    }
  }

  if (G.isTrue(R.not(isAnyEnabledCrossBorder))) {
    const firstRow = R.without([GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION], dataFirstRow);
    const secondRow = R.without([GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION], dataSecondRow);

    data = R.mergeRight(data, { firstRow, secondRow });
  }

  return (
    <Flex p='8px 25px' overflowX='auto'>
      <Box
        mr={16}
        py='8px'
        fontWeight='bold'
        textTransform='uppercase'
        color={G.getTheme('colors.greyMatterhorn')}
      >
        {G.getWindowLocale('titles:action-center', 'Action Center')}
      </Box>
      <LoadStatusActions
        data={data}
        fromDetails={true}
        loadType={GC.LOAD_TYPE_TEL}
        handleActionClick={(actionName: string) => handleActionClick(actionName, load)}
        load={R.mergeRight(load, {
          maxPayViolated: G.getPropFromObject(GC.FIELD_MAX_PAY_VIOLATED, selectedCarrierDriverRate),
          minMarginViolated: G.getPropFromObject(GC.FIELD_MIN_MARGIN_VIOLATED, selectedCarrierDriverRate),
          criticalMarginViolated: G.getPropFromObject(GC.FIELD_CRITICAL_MARGIN_VIOLATED, selectedCarrierDriverRate),
          marginViolationApproved: G.getPropFromObject(GC.FIELD_MARGIN_VIOLATION_APPROVED, selectedCarrierDriverRate),
        })}
      />
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  declineLoadReasons: makeSelectDeclineReasonOptions(state),
  statusMessagesConfigs: makeSelectStatusMessageConfigs(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes(state),
  isAnyEnabledCrossBorder: makeSelectIsAnyEnabledCrossBorder(state),
});

export default connect(mapStateToProps, {
  approveLoadRateRequest,
  updateAppointmentRequest,
  sendUpdateEdiOrApiRequest,
  createLoadDocumentsRequest,
  createLoadDriverRateRequest,
  updateLoadDriverRateRequest,
  createLoadCarrierRateRequest,
  updateLoadCarrierRateRequest,
  createLoadStatusMessageRequest,
  sendTelToExternalSystemRequest,
  dispatchTelRateRequest: dispatchLoadRateRequest,
  createFleetInvoiceRequest: createDriverInvoiceRequest,
  acceptTelRateRequest: acceptLoadDriverCarrierRateRequest,
  declineTelRateRequest: declineLoadDriverCarrierRateRequest,
  createCarrierInvoiceRequest: createLoadCarrierInvoiceRequest,
})(enhance(ActionsCenter));
