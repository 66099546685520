import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { EmptyList } from '../../../components/list';
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedBox, RelativeBox, FormCancelButton } from '../../../ui';
// feature dispatch-planner-events
import * as H from '../helpers';
import Stop from './stop-for-related-trip';
import { renderLoadTotal } from './load-header';
import { selectTelOnAllLists, setTelsToPlannerRequest } from '../actions';
import {
  makeSelectCurrentRoute,
  makeSelectTelsByCloGuidList,
  makeSelectCloRelatedTelListLoading,
} from '../selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  currentRoute: makeSelectCurrentRoute(state),
  loading: makeSelectCloRelatedTelListLoading(state),
  telsByCloGuidList: makeSelectTelsByCloGuidList(state),
});

const enhance = compose(
  connect(mapStateToProps, { selectTelOnAllLists, setTelsToPlannerRequest }),
  withHandlers({
    handleAddTelsToPlanner: (props: Object) => (tels: Array) => {
      const {
        cloGuid,
        handleClose,
        currentRoute,
        telsByCloGuidList,
        selectTelOnAllLists,
        setTelsToPlannerRequest,
      } = props;

      const telGuids = R.map(G.getGuidFromObject, tels);

      selectTelOnAllLists(telGuids);
      setTelsToPlannerRequest(tels);

      const notAddedTels = R.compose(
        R.values,
        R.omit(R.concat(
          telGuids,
          R.keys(R.prop(GC.SYSTEM_LIST_TELS, currentRoute)),
        )),
        G.indexByGuid,
        R.pathOr([], [cloGuid]),
      )(telsByCloGuidList);

      if (R.isEmpty(notAddedTels)) handleClose();
    },
  }),
  pure,
);

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

export const Tel = (props: Object) => {
  const {
    tel,
    showStopsOnly,
    useStopTrailers,
    handleAddTelsToPlanner,
  } = props;

  return (
    <Box>
      {
        R.not(showStopsOnly) &&
        <Flex
          p='5px 10px'
          width='100%'
          borderTop='1px solid'
          borderBottom='1px solid'
          justifyContent='space-between'
          bg={G.getTheme('colors.light.lightGrey')}
          boxShadow='0 0 10px 0 rgba(204, 204, 204, 0.5)'
          borderColor={G.getTheme('tables.rows.borderColor')}
        >
          <Box width='100%'>
            <Flex width='100%'>
              <Box>
                {I.truck(blueColor)}
              </Box>
              <Box m='0 30px' color={blueColor}>
                {`${G.getWindowLocale('titles:tel', 'TEL')}: ${H.getLoadName(tel, 'Unknown')}`}
              </Box>
              <Box fontSize={11} color={G.getTheme('colors.light.black')}>
                {props.loadTotal && renderLoadTotal(H.getRelatedTelTotalInfo(tel))}
              </Box>
            </Flex>
          </Box>
        </Flex>
      }
      <Flex p={12} >
        {
          R.not(showStopsOnly) &&
          <Box
            width={100}
            cursor='pointer'
            color={blueColor}
            onClick={() => handleAddTelsToPlanner(R.of(Array, tel))}
          >
            {G.getWindowLocale('actions:add-to-planner', 'Add To Planner')}
          </Box>
        }
        <Flex flex={1} overflow='auto'>
          <Flex width='fit-content' alignItems='flex-start'>
            {
              R.pathOr([], [GC.FIELD_LOAD_STOPS], tel).map((stop: Object, j: number) => {
                let cloName = null;

                const isCloStop = G.isLoadTypeClo(stop);

                if (isCloStop) {
                  cloName = R.path(['cloPrimaryReferenceValues', stop[GC.FIELD_CLO_GUID]], tel);
                }

                return (
                  <Stop
                    {...props}
                    key={j}
                    tel={tel}
                    stop={stop}
                    cloName={cloName}
                    hideActions={true}
                    isCloStop={isCloStop}
                    useStopTrailers={useStopTrailers}
                    lastStopInTel={R.pathEq(R.inc(j), [GC.FIELD_LOAD_STOPS, 'length'], tel)}
                  />
                );
              })
            }
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const RelatedCloTels = enhance((props: Object) => {
  const {
    cloGuid,
    loading,
    handleClose,
    currentRoute,
    telsByCloGuidList,
    handleAddTelsToPlanner,
  } = props;

  const manageTrailersFrom = G.getAmousConfigByNameFromWindow(GC.TRAILER_MANAGE_TRAILERS_FROM);
  const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

  const tels = R.compose(
    R.values,
    R.omit(R.keys(R.prop(GC.SYSTEM_LIST_TELS, currentRoute))),
    G.indexByGuid,
    R.pathOr([], [cloGuid]),
  )(telsByCloGuidList);

  if (loading) return <LocalLoader height='100%' bg={whiteColor} localLoaderOpen={true} />;

  return (
    <RelativeBox
      zIndex='0'
      height='100%'
      overflow='auto'
      bg={whiteColor}
      borderLeft='1px solid'
      borderColor={G.getTheme('colors.lightGrey')}
    >
      {
        R.isEmpty(tels) &&
        <EmptyList>
          {G.getWindowLocale('titles:empty-list', 'Empty List')}
        </EmptyList>
      }
      {
        G.isNotEmpty(tels) &&
        <StickedBox
          py={10}
          top='0px'
          zIndex={100}
          bg={whiteColor}
          cursor='pointer'
          color={blueColor}
          textAlign='center'
          onClick={() => handleAddTelsToPlanner(tels)}
        >
          {G.getWindowLocale('actions:add-all-to-planner', 'Add All To Planner')}
        </StickedBox>
      }
      {
        tels.map((tel: Object, i: number) => (
          <Tel
            key={i}
            tel={tel}
            useStopTrailers={useStopTrailers}
            handleAddTelsToPlanner={handleAddTelsToPlanner}
          />
        ))
      }
      <StickedBox p='10px 20px' zIndex={100} bg={whiteColor}>
        <FormCancelButton
          p='8px 16px'
          width='100px'
          type='button'
          fontSize={14}
          onClick={handleClose}
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </FormCancelButton>
      </StickedBox>
    </RelativeBox>
  );
});

export default RelatedCloTels;
