import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withWindowSize, withIsOpenedModalStatus } from '../../../hocs';
// ui
import { Flex, RelativeBox } from '../../../ui';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// report-common
import { generateDefaultReport } from '../../../report-common';
// features
import { loadColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
import { loadFilterProps, loadFilterPropsForTitleFilter } from '../../dispatch-report/settings/filter-params';
// feature dispatch-planner
import CustomTitle from './custom-title';
import { pageSizes } from '../settings/page-settings';
import { telTableSettings } from '../settings/table-settings';
import CrossDockLocationSelect from './cross-dock-location-select';
import {
  setTelReports,
  addTelToPlanner,
  toggleTelDetails,
  setInboundTerminals,
  removeTelFromPlanner,
  getInboundTelNextPage,
  createTelReportRequest,
  updateTelReportRequest,
  setGlobalFilterValueIT,
  setInboundTableTitleSort,
  resetTelListAndPagination,
  setInboundTableTitleFilter,
  setCurrentInboundTelReport,
  changeDefaultTelReportRequest,
  resetInboundTelListAndPagination,
} from '../actions';
import {
  makeSelectInboundTelList,
  makeSelectInboundTelTotal,
  makeSelectTelFilterParams,
  makeSelectInboundTelReport,
  makeSelectInboundTerminals,
  makeSelectAvailableTelReports,
  makeSelectGlobalFilterValueIT,
  makeSelectInboundTelPagination,
  makeSelectCrossDockLocationList,
  makeSelectInboundTelListLoading,
  makeSelectInboundTableTitleFilters,
  makeSelectInboundTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withWindowSize,
  withIsOpenedModalStatus,
  withHandlers({
    handleSetGlobalFilter: (props: Object) => (value: string) => {
      if (R.equals(props.globalFilterValue, value)) return;
      props.resetInboundTelListAndPagination();
      props.setGlobalFilterValueIT(value);
      props.getInboundTelNextPage(true);
    },
    handleListRequest: ({ getInboundTelNextPage }: Object) => (isInitial: boolean = false) =>
      getInboundTelNextPage(isInitial),
    handleClickItem: (props: Object) => (guid: string, tel: Object) => {
      if (G.isTrue(tel.selected)) return props.removeTelFromPlanner(guid);
      props.addTelToPlanner(guid);
    },
    handleToggleDetails: (props: Object) => (tel: Object) => (
      props.toggleTelDetails(R.assoc('isInbound', true, tel))
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        getInboundTelNextPage,
        createTelReportRequest,
        updateTelReportRequest,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.TEL_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          hideSaveAsNew={true}
          setReport={setReport}
          requestPending={requestPending}
          createReportRequest={createTelReportRequest}
          updateReportRequest={updateTelReportRequest}
          onReportSet={() => getInboundTelNextPage(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), props.reportList);
      props.setReport(selectedReport);
      props.getInboundTelNextPage(true);
    },
    handleSetLocations: (props: Object) => (locations: Array) => {
      props.setInboundTerminals(locations);
      props.resetInboundTelListAndPagination();
      props.getInboundTelNextPage(true);
      props.closeModal();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    selectedReport,
    titleSortValues,
    handleClickItem,
    tableTitleFilters,
    handleToggleDetails,
    getInboundTelNextPage,
    handleTableTitleFilter } = props;
  const allChecked = G.isAllChecked(itemList);
  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    report: selectedReport,
    handleTableTitleFilter,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleClickItem,
    tableSettings: R.mergeRight(telTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(loadFilterPropsForTitleFilter)),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getInboundTelNextPage,
    ),
  };

  return <Table {...data} />;
};

const InboundLoads = enhance((props: Object) => {
  const {
    locations,
    openModal,
    closeModal,
    totalCount,
    setTelReports,
    selectedReport,
    globalFilterValue,
    handleSetLocations,
    crossDockLocations,
    handleSetGlobalFilter,
    updateTelReportRequest,
  } = props;

  return (
    <RelativeBox minWidth={700}>
      <RelativeBox pr={15} zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          version={2}
          loading={false} // HACK: some UI error block title panel - remove after fix
          type='inbound-tel'
          noExportable={true}
          hideFilterInfo={true}
          useExactFilters={true}
          name='inbound-tel-report'
          withoutQuickFilter={true}
          filterPopupMinWidth={710}
          setReports={setTelReports}
          usedReport={selectedReport}
          filterProps={loadFilterProps}
          hiddenRightFilterInfo={false}
          height={pageSizes.tableHeaderHeight}
          updateReportRequest={updateTelReportRequest}
          customTitleComponent={
            <Flex>
              <CustomTitle
                count={totalCount}
                globalFilterValue={globalFilterValue}
                handleSetGlobalFilter={handleSetGlobalFilter}
                text={G.getWindowLocale('titles:inbound-tels', 'Inbound TELs')}
              />
              <CrossDockLocationSelect
                openModal={openModal}
                locations={locations}
                closeModal={closeModal}
                setLocations={handleSetLocations}
                crossDockLocations={crossDockLocations}
                locationOptions={G.getLocationOptions(R.values(crossDockLocations))}
              />
            </Flex>
          }
        />
      </RelativeBox>
      <RelativeBox zIndex={1}>
        {renderTable(props)}
      </RelativeBox>
    </RelativeBox>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectInboundTelList(state),
  totalCount: makeSelectInboundTelTotal(state),
  locations: makeSelectInboundTerminals(state),
  filterParams: makeSelectTelFilterParams(state),
  loading: makeSelectInboundTelListLoading(state),
  reportList: makeSelectAvailableTelReports(state),
  pagination: makeSelectInboundTelPagination(state),
  selectedReport: makeSelectInboundTelReport(state),
  globalFilterValue: makeSelectGlobalFilterValueIT(state),
  crossDockLocations: makeSelectCrossDockLocationList(state),
  tableTitleFilters: makeSelectInboundTableTitleFilters(state),
  titleSortValues: makeSelectInboundTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setTelReports,
  addTelToPlanner,
  toggleTelDetails,
  setInboundTerminals,
  removeTelFromPlanner,
  getInboundTelNextPage,
  createTelReportRequest,
  updateTelReportRequest,
  setGlobalFilterValueIT,
  resetTelListAndPagination,
  resetInboundTelListAndPagination,
  setReport: setCurrentInboundTelReport,
  getItemListRequest: getInboundTelNextPage,
  setUsedReport: setCurrentInboundTelReport,
  setTableTitleSort: setInboundTableTitleSort,
  setTableTitleFilter: setInboundTableTitleFilter,
  resetListAndPagination: resetInboundTelListAndPagination,
  changeDefaultReportRequest: changeDefaultTelReportRequest,
})(InboundLoads);
