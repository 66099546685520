import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// features
import ScheduleRoute from '../../template/route/components/schedule-route';
import { setDispatchPlannerOpened } from '../../dispatch-details-new/load/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-planner
import { setOpenedFromPage } from '../actions';
import RouteInfo from '../components/route-info';
import { CREATE_ROUTE_INFO_FORM } from '../constants';
import { getRateGuidToCommitmentCountMap } from '../helpers';
import { RouteTemplateForm } from '../components/route-template-form';
//////////////////////////////////////////////////

const saveAsTemplate = async (props: Object) => {
  const { openLoader, closeLoader, requestData } = props;

  try {
    openLoader();

    const res = await sendRequest(
      'post',
      endpointsMap.routeTemplate,
      { data: requestData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      closeLoader();

      return data;
    }

    closeLoader();

    return null;
  } catch (error) {
    closeLoader();

    G.handleException('error', 'saveAsTemplate exception');
  }
};

const withScheduleRoute = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    // TODO: remove after customer testing
    // handleScheduleRouteAfterTemplateSave: (props: Object) => (template: Object) => {
    //   const {
    //     openModal,
    //     routeChanged,
    //   } = props;

    //   if (routeChanged) return;

    //   const templateGuid = G.getGuidFromObject(template);
    //   const templateName = G.getNameFromObject(template);

    //   const component = (
    //     <ScheduleRoute
    //       templateName={templateName}
    //       templateGuid={templateGuid}
    //       hideEditTemplateRoute={true}
    //       branchGuid={G.getBranchGuidFromObject(template)}
    //       asyncEndpoint={endpointsMap.getRouteTemplateByGuid(templateGuid)}
    //     />
    //   );

    //   const modal = G.getFullWindowModalWithContent(component);

    //   openModal(modal);
    // },
    handleScheduleTemporaryRoute: (props: Object) => async () => {
      const {
        openModal,
        openLoader,
        closeLoader,
        routeChanged,
        currentRoute,
      } = props;

      if (routeChanged) return;

      const { tels, templateGuid, templateName, masterTemplateGuid } = currentRoute;

      const requestData = {
        temporaryForScheduler: true,
        [GC.FIELD_NAME]: templateName,
        [GC.FIELD_ROUTE_GUID]: G.getGuidFromObject(currentRoute),
        [GC.FIELD_MASTER_TEMPLATE_GUID]: R.or(masterTemplateGuid, templateGuid),
        [GC.FIELD_TEL_GUID_DISPATCH_TYPE]: R.map(R.prop(GC.FIELD_DISPATCH_TYPE), tels),
        [GC.FIELD_RATE_GUID_TO_COMMITMENT_COUNT]: getRateGuidToCommitmentCountMap(R.values(tels)),
      };

      const temporaryTemplate = await saveAsTemplate({ openLoader, closeLoader, requestData });

      if (G.isNilOrEmpty(temporaryTemplate)) return;

      const temporaryTemplateGuid = G.getGuidFromObject(temporaryTemplate);
      const temporaryTemplateName = G.getNameFromObject(temporaryTemplate);
      const temporaryTemplateBranchGuid = G.getBranchGuidFromObject(temporaryTemplate);

      const leftSpaceWidth = 100;

      const component = (
        <ScheduleRoute
          temporaryTemplate={true}
          hideEditTemplateRoute={true}
          leftSpaceWidth={leftSpaceWidth}
          templateName={temporaryTemplateName}
          templateGuid={temporaryTemplateGuid}
          branchGuid={temporaryTemplateBranchGuid}
          asyncEndpoint={endpointsMap.getRouteTemplateByGuid(temporaryTemplateGuid)}
        />
      );

      const modal = G.getFullWindowModalWithContentWithLeftSpace(component, leftSpaceWidth);

      openModal(modal);
    },
  }),
);

const withSaveCancelRoute = compose(
  connect(null, { setOpenedFromPage, setDispatchPlannerOpened }),
  withScheduleRoute,
  withHandlers({
    handleClickSaveRoute: (props: Object) => (
      withCloseModal: boolean = false,
      withRefresh: boolean = false,
      withCloseOnSave: boolean = false) => {
      const {
        openModal,
        closeModal,
        currentRoute,
        routeConfigs,
        onSaveCallback,
        currentRouteItems,
        validateRouteRequest,
        saveRouteWithoutRates,
        currentRouteContainers,
      } = props;

      if (R.or(
        G.isTrue(routeConfigs.autoRouteName),
        G.isNotNilAndNotEmpty(R.path(['name'], currentRoute)),
      )) {
        return validateRouteRequest({
          withRefresh,
          currentRoute,
          onSaveCallback,
          withCloseModal,
          withCloseOnSave,
          currentRouteItems,
          saveRouteWithoutRates,
          currentRouteContainers,
        });
      }

      const component = (
        <RouteInfo
          closeModal={closeModal}
          label={G.getWindowLocale('titles:route-name', 'Route Name')}
          createRequest={
            (data: Object) => validateRouteRequest({
              withRefresh,
              withCloseOnSave,
              currentRouteItems,
              currentRouteContainers,
              currentRoute: R.assoc('name', data.routeName, currentRoute),
            })
          }
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '440px',
          height: 'auto',
          title: 'Route Info',
        },
      };
      openModal(modal);
    },
    handleClickCancelRoute: (props: Object) => () => {
      const { openedFromPage, setOpenedFromPage, setDispatchPlannerOpened, setCurrentRouteInitialState } = props;

      setCurrentRouteInitialState();

      if (R.equals(openedFromPage, 'DISPATCH_DETAILS_NEW')) {
        setOpenedFromPage(null);
        setDispatchPlannerOpened(false);
      }
    },
    handleClickCancelRouteClo: (props: Object) => () => {
      const modal = {
        component: (
          <ConfirmComponent
            textLocale={G.getWindowLocale(
              'messages:confirmation-leave-page',
              'All changes will be lost. Are you sure you want leave page?',
            )}
          />
        ),
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:leave', 'Leave'),
              action: () => {
                props.cleanBuilder();
                props.closeAllModals();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleSaveAsTemplate: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        cloRefTypes,
        telRefTypes,
        currentRoute,
        routeChanged,
        editTemplateRoute,
        saveAsTemplateRequest,
        // handleScheduleRouteAfterTemplateSave,
      } = props;

      if (routeChanged) return;

      const { tels, templateName, templateGuid, masterTemplateGuid } = currentRoute;

      const submitAction = (values: Object) => (
        saveAsTemplateRequest({
          ...values,
          // TODO: remove after customer testing
          // callback: handleScheduleRouteAfterTemplateSave,
          [GC.FIELD_ROUTE_GUID]: G.getGuidFromObject(currentRoute),
          [GC.FIELD_TEL_GUID_DISPATCH_TYPE]: R.map(R.prop(GC.FIELD_DISPATCH_TYPE), tels),
          [GC.FIELD_RATE_GUID_TO_COMMITMENT_COUNT]: getRateGuidToCommitmentCountMap(R.values(tels)),
        })
      );

      const cloReferenceTypeOptions = G.getOptionsFromDataArrayByPath([GC.FIELD_NAME], [GC.FIELD_GUID], cloRefTypes);
      const telReferenceTypeOptions = G.getOptionsFromDataArrayByPath([GC.FIELD_NAME], [GC.FIELD_GUID], telRefTypes);

      const initialValues = {
        [GC.FIELD_NAME]: templateName,
        [GC.FIELD_DELETE_ORIGINAL_ROUTE]: false,
      };

      const component = (
        <RouteTemplateForm
          closeModal={closeModal}
          submitAction={submitAction}
          templateGuid={templateGuid}
          templateName={templateName}
          initialValues={initialValues}
          masterTemplateGuid={masterTemplateGuid}
          hideDeleteOriginRoute={editTemplateRoute}
          optionsForSelect={{ cloReferenceTypeOptions, telReferenceTypeOptions }}
        />
      );

      const modal = { p: 15, component, options: { width: 410 } };

      openModal(modal);
    },
  }),
  pure,
);

export default withSaveCancelRoute;
