import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// helpers/constants
import * as GC from '../../../../constants';
// sagas
import { visitPageSaga } from '../../../../sagas';
// feature configs
import {
  getConfigByGroupSaga,
  getReferenceTypesByScopeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

function* handleVisitConfigPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_OPTIONAL_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getConfigByGroupSaga, GC.OPTIONAL_CONFIG_GROUP);
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CUSTOMER_INVOICE });
    yield put(closeLoader());
    break;
  }
}

function* masterOptionalWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_OPTIONAL_PAGE, handleVisitConfigPageSaga);
}

export default masterOptionalWatcherSaga;
