import EventDetails, { TelDetails } from '../components/event-details';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const titleRowHeight = 55;
export const tableMaxHeight = 200;

export const cloEventTableSettings = {
  titleRowHeight,
  expandableItems: true,
  checkBoxCellWidth: 70,
  searchableTitles: true,
  coloredActiveRow: true,
  maxHeight: tableMaxHeight,
  tableRowHeight: rowHeight,
  expandedDetailsComponent: EventDetails,
  activeRowColor: 'colors.light.teaGreen',
};

export const telTableSettings = {
  titleRowHeight,
  useMainColors: false,
  expandableItems: true,
  checkBoxCellWidth: 70,
  searchableTitles: true,
  coloredActiveRow: true,
  maxHeight: tableMaxHeight,
  tableRowHeight: rowHeight,
  expandedDetailsComponent: TelDetails,
};

export const telClosTableSettings = {
  coloredHeader: true,
  useMainColors: false,
  allowSelectItems: false,
  checkBoxCellWidth: '0px',
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

export const cloTelsTableSettings = {
  coloredHeader: true,
  useMainColors: true,
  allowSelectItems: false,
  checkBoxCellWidth: '0px',
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};
