import React from 'react';
// components
import { HighlightedText } from '../../../components/highlighted-text';
// helpers
import * as G from '../../../helpers';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const HighlightedTextComponent = () => {
  const description = '<HighlightedText /> component is designed to display text with specific portions highlighted.';

  const usage = (
    <ul>
      <li>Set `<i>highlightBgColor</i>` for the background color of the highlighted portion.</li>
      <li>Parameter `<i>highlight</i>` is substring within the parameter `text` that should be highlighted.</li>
      <li>Parameter `<i>text</i>` is the complete text to be displayed, with portions potentially highlighted.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='HighlightedText'
      description={description}
      path='src/components/highlighted-text'
    >
      <HighlightedText
        highlight='dummy text'
        highlightBgColor={G.getTheme('colors.light.yellow')}
        text='Lorem Ipsum is simply dummy text of the printing and typesetting industry'
      />
    </ComponentStoryWrapper>
  );
};

export default HighlightedTextComponent;
