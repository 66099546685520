import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
import { Ranges } from '../../dispatch-planner/components/stop';
import StopTrailers from '../../dispatch-planner/components/stop-trailers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner-events
import * as H from '../helpers';
import { MainInfo } from './stop';
import StopItems from './related-trip-stop-items';
//////////////////////////////////////////////////

const DistanceToStop = ({ stop, loadType }: Object) => {
  const distanceObject = R.prop(G.getDistancePropByLoadType(loadType), stop);
  const manualDistance = R.pathOr(null, [GC.FIELD_DISTANCE_MANUAL], distanceObject);

  return (
    <Box>
      <Flex>
        <TextComponent
          fontSize={11}
          whiteSpace='normal'
          wordBreak='break-word'
        >
          {G.getWindowLocale('titles:distance-to-next-stop', 'Distance to next stop')}:
        </TextComponent>
        <Box ml='4px'>
          <TextComponent
            fontSize={11}
            fontWeight='bold'
          >
            {R.isNil(distanceObject) && G.getWindowLocale('titles:not-calculated', 'Not Calculated')}
            {G.isNotNil(distanceObject) && H.getStopDistanceInfo(manualDistance, distanceObject)}
          </TextComponent>
          {
            G.isNotNil(manualDistance) &&
            <TextComponent
              ml='3px'
              p='0 3px'
              bg={G.getTheme('colors.dark.blue')}
              color={G.getTheme('colors.light.mainLight')}
            >
              M
            </TextComponent>
          }
        </Box>
      </Flex>
    </Box>
  );
};

const Header = ({
  stop,
  cloName,
  lastStopInTel,
}: Object) => {
  const isClo = G.isLoadTypeClo(stop);
  const isDrop = G.isStopDrop(stop);

  return (
    <Box height={80} bg={G.getTheme('colors.light.lightGrey')}>
      <Flex mx='8px' height={45} borderBottom='1px solid' borderColor={G.getTheme('colors.dark.grey')}>
        <Flex
          width={30}
          height={30}
          borderRadius='50%'
          justifyContent='center'
          bg={R.prop('headerBg', G.renderStopColor(stop))}
          color={G.getTheme(G.ifElse(isDrop, 'colors.white', 'colors.light.black'))}
        >
          {
            G.ifElse(isClo, '', G.ifElse(G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop)), 'T', 'I'), '')
          }{
            R.pathOr('', ['eventType', 0], stop)
          }{R.prop(GC.FIELD_TEL_EVENT_INDEX, stop)}
        </Flex>
        <Ranges stop={stop} />
      </Flex>
      <Flex mx='8px' height={34} fontSize={11} justifyContent='space-between'>
        {
          R.and(isClo, G.isNotNilAndNotEmpty(cloName)) &&
          <Flex>
            <Box>{G.getWindowLocale('titles:clo', 'CLO')}:</Box>
            <Box ml='4px' fontWeight='bold'>{cloName}</Box>
          </Flex>
        }
        {
          R.not(lastStopInTel) && <DistanceToStop stop={stop} loadType={GC.FIELD_TEL} />
        }
        {
          lastStopInTel &&
          <TextComponent
            fontSize={11}
            fontWeight='bold'
            whiteSpace='normal'
            wordBreak='break-word'
          >
            {G.getWindowLocale('titles:end-of-trip', 'End of Trip')}
          </TextComponent>
        }
      </Flex>
    </Box>
  );
};

const Stop = (props: Object) => {
  const {
    stop,
    cloName,
    lastStopInTel,
    expandedEvents,
    useStopTrailers,
  } = props;

  return (
    <Box
      m='0 10px'
      width={320}
      borderRadius='4px'
      order={props.order}
      boxShadow={`0 2px 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
    >
      <Header
        stop={stop}
        cloName={cloName}
        lastStopInTel={lastStopInTel}
      />
      <MainInfo
        stop={stop}
        cloName={cloName}
        loadType={GC.FIELD_TEL}
        expanded={expandedEvents}
      />
      { useStopTrailers && <StopTrailers {...props} /> }
      <StopItems {...props} />
    </Box>
  );
};

export default Stop;
