import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectDashboardStore = ({ analytics }: Object) => analytics;

const makeSelectGroupList = () => createSelector(
  selectDashboardStore,
  ({ groupList }: Object) => groupList,
);

const makeSelectDashboardList = () => createSelector(
  selectDashboardStore,
  ({ dashboardList }: Object) => dashboardList,
);

const makeSelectCurrentDashboard = () => createSelector(
  selectDashboardStore,
  ({ currentDashboard }: Object) => currentDashboard,
);

const makeSelectDashboardCharts = () => createSelector(
  selectDashboardStore,
  ({ dashboardCharts }: Object) => dashboardCharts,
);

export {
  makeSelectGroupList,
  makeSelectDashboardList,
  makeSelectDashboardCharts,
  makeSelectCurrentDashboard,
};
