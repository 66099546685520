import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
export const openDriverProfile = createAction('openDriverProfile');
export const closeDriverProfile = createAction('closeDriverProfile');
export const toggleDriverFuelCards = createAction('toggleDriverFuelCards');
export const toggleDriverCertificates = createAction('toggleDriverCertificates');
export const getDriverFuelCardsRequest = createAction('getDriverFuelCardsRequest');
export const getDriverFuelCardsSuccess = createAction('getDriverFuelCardsSuccess');
export const toggleDriverFuelCardRequest = createAction('toggleDriverFuelCardRequest');
export const toggleDriverFuelCardSuccess = createAction('toggleDriverFuelCardSuccess');
export const getDriverProfileInfoSuccess = createAction('getDriverProfileInfoSuccess');
export const getDriverProfileInfoRequest = createAction('getDriverProfileInfoRequest');
export const getDriverCertificatesRequest = createAction('getDriverCertificatesRequest');
export const getDriverCertificatesSuccess = createAction('getDriverCertificatesSuccess');
export const getDriverAvailabilityStatusRequest = createAction('getDriverProfileInfoRequest');
export const getDriverAvailabilityStatusSuccess = createAction('getDriverAvailabilityStatusSuccess');
