import React from 'react';
import * as R from 'ramda';
// components
import { Tabs } from '../../../components/tabs';
import TitlePanel from '../../../components/title-panel';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const TitlePanelComponent = () => {
  const description = '<TitlePanel /> component combines various features in the header, including the title,  additional components, additional filters, page header actions, and reports.';

  const usage = (
    <ul>
      <li>Parameter `<i>type</i>` is a type of the report.</li>
      <li>Parameter `<i>filterProps</i>` related to filters.</li>
      <li>Parameter `<i>pt</i>` is a padding-top for the header.</li>
      <li>Parameter `<i>pr</i>` is a padding-right for the header.</li>
      <li>Parameter `<i>pb</i>` is a padding-bottom for the header.</li>
      <li>Parameter `<i>reportList</i>` is a list of available reports.</li>
      <li>Parameter `<i>useExactFilters</i>` if true, uses exact filters.</li>
      <li>Parameter `<i>filterParams</i>` are the parameters for filtering.</li>
      <li>Parameter `<i>selectedReport</i>` is a currently selected report.</li>
      <li>Parameter `<i>noExportable</i>` if true, prevents exporting reports.</li>
      <li>Parameter `<i>setQuickFilterParams</i>` sets quick filter parameters.</li>
      <li>Parameter `<i>noEditReport</i>` if true, prevents editing of reports.</li>
      <li>Parameter `<i>globalFilterValue</i>` is a value of the global filter.</li>
      <li>Parameter `<i>withoutQuickFilter</i>` if true, excludes quick filters.</li>
      <li>Parameter `<i>withoutReport</i>` if true, excludes the report section.</li>
      <li>Parameter `<i>handleSetGlobalFilter</i>` handles setting global filters.</li>
      <li>Parameter `<i>hiddenRightFilterInfo</i>` if true, hides right filter info.</li>
      <li>Parameter `<i>handleEditReport</i>` is a function to handle report editing.</li>
      <li>Parameter `<i>withCount</i>` represents the count of actions to be displayed.</li>
      <li>Parameter `<i>withAdditionalFilters</i>` if true, includes additional filters.</li>
      <li>Parameter `<i>hiddenAddReportFilter</i>` if true, hides the add report filter.</li>
      <li>Parameter `<i>handleSelectReport</i>` is a function to handle report selection.</li>
      <li>Parameter `<i>selectedListLength</i>` is the length of the selected report list.</li>
      <li>Parameter `<i>rightTitlePanelZIndex</i>` is a Z-index for the right title panel.</li>
      <li>Parameter `<i>withGlobalSearchFilter</i>` if true, includes global search filter.</li>
      <li>Parameter `<i>additionalComponent</i>` is an additional component to be displayed.</li>
      <li>Parameter `<i>additionalFilterOptions</i>` are the options for additional filters.</li>
      <li>Parameter `<i>noSelectDropdown</i>` if true, excludes the report selection dropdown.</li>
      <li>Parameter `<i>pageHeaderActions</i>` are the actions to be displayed in the page header.</li>
      <li>Parameter `<i>getQuickFilteredListRequest</i>` is a function to request a filtered list.</li>
      <li>Parameter `<i>exportReportDataRequest</i>` is a function to request exporting report data.</li>
      <li>Parameter `<i>additionalFiltersApplyHandler</i>` is a handler for applying additional filters.</li>
      <li>Parameter `<i>allAvailableRefTypesByScopeName</i>` is a reference type available by scope name.</li>
      <li>Parameter `<i>changeDefaultReportRequest</i>` is a function to request changing the default report.</li>
      <li>Parameter `<i>disableRefFilter</i>` specifies whether filters for reference list should be disabled.</li>
      <li>Parameter `<i>allowSetAdditionalFiltersImmediately</i>` if `true`, the handleSetFilter function is called immediately with the current filter value.</li>
    </ul>
  );

  const reportList = [
    { 'name': 'report', 'pinnedReport': false, 'defaultReport': false },
    { 'pinnedReport': true, 'defaultReport': true, 'name': 'report-full' },
  ];

  const getTabs = (activeTab: number) => [{ text: 'Tabs title', withCount: R.equals(activeTab, 0) }];

  const customTitleComponent = (<Tabs count={16} activeTab={0} tabs={getTabs(0)} handleClickTab={() => {}} />);

  const pageHeaderActions = [{
    title: 'page header actions',
    actions: [{ text: 'action', permissions: null, action: () => {} }],
  }];

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='TitlePanel'
      description={description}
      path='src/components/title-panel'
    >
      <TitlePanel
        type={null}
        withCount={true}
        popperWithCount={true}
        reportList={reportList}
        withoutQuickFilter={false}
        handleEditReport={() => {}}
        handleSelectReport={() => {}}
        hiddenRightFilterInfo={false}
        exportReportDataRequest={() => {}}
        count={R.length(pageHeaderActions)}
        changeDefaultReportRequest={() => {}}
        pageHeaderActions={pageHeaderActions}
        customTitleComponent={customTitleComponent}
        selectedListLength={R.length(pageHeaderActions)}
      />
    </ComponentStoryWrapper>
  );
};

export default TitlePanelComponent;
