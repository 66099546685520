import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { FieldsetComponent } from '../../../../forms';
// ui
import { Box } from '../../../../ui';
// feature dispatch-report
import * as LC from '../../constants';
//////////////////////////////////////////////////

export const validationSchema = Yup.object().shape({
  [LC.FIELD_CLO_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [LC.FIELD_TEMPLATE_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [LC.FIELD_TEL_CREATION_MODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [LC.FIELD_RETAINED_REFERENCES]: Yup.array()
    .notRequired()
    .nullable(true),
});

const defaultFormikValues = {
  [LC.FIELD_CLO_GUID]: null,
  [LC.FIELD_TEMPLATE_NAME]: null,
  [LC.FIELD_TEL_CREATION_MODE]: null,
  [LC.FIELD_RETAINED_REFERENCES]: null,
};

const commonProps = {
  width: 300,
  fontSize: 12,
  labelPl: '0px',
  errorTop: '103%',
  isRequired: true,
  labelWidth: '100%',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
  errorPosition: 'absolute',
};

const fields = [
  {
    ...commonProps,
    type: 'text',
    loc: 'titles:template-name',
    fieldName: LC.FIELD_TEMPLATE_NAME,
  },
  {
    ...commonProps,
    afterTop: 13,
    type: 'select',
    afterRight: 13,
    loc: 'titles:tel-creation-mode',
    fieldName: LC.FIELD_TEL_CREATION_MODE,
    options: [
      {
        value: GC.TEL_CREATION_MODE_NO_TEL,
        label: G.getWindowLocale('titles:no-tel', 'No TEL'),
      },
      {
        value: GC.TEL_CREATION_MODE_SINGLE_TEL,
        label: G.getWindowLocale('titles:single-tel', 'Single TEL'),
      },
    ],
  },
  {
    ...commonProps,
    afterTop: 13,
    afterRight: 13,
    isRequired: false,
    type: 'multiselect',
    options: 'referenceTypeOptions',
    loc: 'titles:retained-references',
    fieldName: LC.FIELD_RETAINED_REFERENCES,
  },
];

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    displayName: 'CREATE_LOAD_TEMPLATE_FORM',
    handleSubmit: (values: Object, { props }: Object) => props.handleSubmitForm(values),
    mapPropsToValues: (props: Object) => R.mergeRight(defaultFormikValues, props.initialValues),
  }),
  pure,
);

export const OrderTemplateForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Box py='8px'>
      <FieldsetComponent
        {...props}
        fields={fields}
        labelFontSize={12}
        onChange={props.handleChange}
        justifyContent='space-between' />
    </Box>
    <FormFooter
      boxStyles={{ mt: '10px' }}
      closeModal={props.closeModal} />
  </form>
));
