import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const generalSection = {
  title: 'titles:general',
  fields: {
    [GC.TASK_MANAGEMENT_TASK_NUMBER_SEQUENCE]: {
      type: 'select',
      options: 'sequences',
      name: 'titles:task-number-sequence',
      nameForAttribute: GC.TASK_MANAGEMENT_TASK_NUMBER_SEQUENCE,
    },
  },
};

const taskTypeSection = {
  configType: 'custom',
  title: 'titles:task-type',
  configName: GC.CUSTOM_TASK_TYPE_CONFIG,
  customConfigName: GC.CUSTOM_TASK_TYPE_CONFIG,
  fields: [
    {
      name: 'titles:name',
      nameForAttribute: GC.FIELD_NAME,
    },
    {
      name: 'titles:task-title',
      nameForAttribute: GC.FIELD_TASK_TITLE,
    },
    {
      name: 'titles:task-description',
      nameForAttribute: GC.FIELD_TASK_DESCRIPTION,
    },
  ],
};

const taskStatusSection = {
  configType: 'custom',
  title: 'titles:task-status',
  configName: GC.CUSTOM_TASK_STATUS_CONFIG,
  customConfigName: GC.CUSTOM_TASK_STATUS_CONFIG,
  fields: [
    {
      name: 'titles:name',
      nameForAttribute: GC.FIELD_NAME,
    },
    {
      name: 'titles:completed',
      nameForAttribute: GC.FIELD_COMPLETED,
    },
    {
      name: 'titles:task-types',
      nameForAttribute: GC.FIELD_TASK_TYPE_GUIDS,
      customLogic: (_: any, entity: Array, props: Object) => (
        R.compose(
          R.join(', '),
          R.map((item: string) => (
            R.compose(
              R.propOr('', GC.FIELD_NAME),
              R.find(R.propEq(item, GC.FIELD_GUID)),
              R.propOr([], 'taskTypeList'),
            )(props)
          )),
          R.propOr('', GC.FIELD_TASK_TYPE_GUIDS),
        )(entity)
      ),
    },
    {
      name: 'titles:days-before-remove',
      nameForAttribute: 'daysBeforeRemoval',
    },
  ],
};

const ConfigTaskManagementGroup = {
  generalSection,
  taskTypeSection,
  taskStatusSection,
};

export {
  ConfigTaskManagementGroup,
};
