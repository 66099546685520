
import React, { useEffect } from 'react';
// features
import CarrierRateRequestReport from '../../carrier-rate-request-report';
import { getCarrierRateRequestReportDataRequest } from '../../carrier-rate-request-report/actions';
//////////////////////////////////////////////////

const Component = ({ dispatch, carrierName, primaryObjectGuid }: Object) => {
  useEffect(() => {
    dispatch(getCarrierRateRequestReportDataRequest(primaryObjectGuid));
  }, []);

  return (
    <CarrierRateRequestReport padding={15} carrierName={carrierName} maxHeight='calc(100% - 860px)' />
  );
};

export const bidHistorySettings = {
  Component,
  groupName: 'bidHistory',
};
