import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { ActionButton } from '../../../ui';
// feature fleet trailer
import { syncTrailerByVINRequest, updateTrailerDetailsRequest } from '../trailer/actions';
import { makeSelectTrailerDetails, makeSelectTrailerFormValues } from '../trailer/selectors';
// feature fleet truck
import { syncTruckByVINRequest, updateTruckDetailsRequest } from '../truck/actions';
import { makeSelectTruckDetails, makeSelectTruckFormValues } from '../truck/selectors';
//////////////////////////////////////////////////


const VINLookup = ({ handleSyncByVIN }: Object) => (
  <ActionButton
    ml={15}
    width={90}
    height={20}
    fontSize={12}
    type='button'
    minWidth='unset'
    borderRadius='3px'
    onClick={handleSyncByVIN}
  >
    {G.getWindowLocale('actions:vin-lookup', 'VIN Lookup')}
  </ActionButton>
);

const enhance = compose(
  withHandlers({
    handleSyncByVIN: (props: Object) => () => {
      const { formValues, initialValues, syncRequest, updateRequest } = props;

      const guid = G.getGuidFromObject(formValues);
      const serialNumber = G.getPropFromObject(GC.FIELD_SERIAL_NUMBER, formValues);
      const initialSerialNumber = G.getPropFromObject(GC.FIELD_SERIAL_NUMBER, initialValues);

      if (G.isNilOrEmpty(serialNumber)) {
        const message = G.getWindowLocale('messages:serial-number-is-required', 'Serial Number is required');

        return G.showToastrMessageSimple('error', message);
      }

      if (R.equals(serialNumber, initialSerialNumber)) return syncRequest(guid);

      updateRequest(R.assoc('sync', true, formValues));
    },
  }),
  pure,
);

const truckMapStateToProps = (state: Object) => createStructuredSelector({
  formValues: makeSelectTruckFormValues(state),
  initialValues: makeSelectTruckDetails(state),
});

const trailerMapStateToProps = (state: Object) => createStructuredSelector({
  formValues: makeSelectTrailerFormValues(state),
  initialValues: makeSelectTrailerDetails(state),
});

const truckEnhance = compose(
  connect(
    truckMapStateToProps,
    {
      syncRequest: syncTruckByVINRequest,
      updateRequest: updateTruckDetailsRequest,
    },
  ),
  enhance,
);

const trailerEnhance = compose(
  connect(
    trailerMapStateToProps,
    {
      syncRequest: syncTrailerByVINRequest,
      updateRequest: updateTrailerDetailsRequest,
    },
  ),
  enhance,
);

export const VINLookupTruck = truckEnhance(VINLookup);
export const VINLookupTrailer = trailerEnhance(VINLookup);
