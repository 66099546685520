import React from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch-report
import { TableField } from '../../components/table-fields';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: ['titles:route', 'titles:name'],
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    name: ['titles:route', 'titles:branch'],
  },
  [GC.FIELD_MARGIN]: {
    width: 200,
    pivotType: 'number',
    name: ['titles:route', 'titles:margin'],
  },
  [GC.FIELD_CLO_COUNT]: {
    width: 200,
    pivotType: 'number',
    name: ['titles:route', 'titles:clo-count'],
  },
  [GC.FIELD_TEL_COUNT]: {
    width: 200,
    pivotType: 'number',
    name: ['titles:route', 'titles:tel-count'],
  },
  [GC.TOTAL_INCOME]: {
    width: 200,
    pivotType: 'number',
    name: ['titles:route', 'titles:total-income'],
  },
  [GC.FIELD_TOTAL_SPEND]: {
    width: 200,
    pivotType: 'number',
    name: ['titles:route', 'titles:total-spend'],
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: ['titles:route', 'titles:created-by'],
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:route', 'titles:created-date'],
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: ['titles:route', 'titles:last-modified-by'],
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:route', 'titles:last-modified-date'],
  },
  [GC.GRC.LOAD_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    searchable: false,
    name: ['titles:load', 'titles:primary-ref-value'],
  },
  [GC.GRC.LOAD_TYPE]: {
    width: 200,
    type: 'enum',
    searchable: false,
    name: ['titles:load', 'titles:type'],
  },
  [GC.GRC.LOAD_STATUS]: {
    width: 200,
    type: 'enum',
    searchable: false,
    name: ['titles:load', 'titles:status'],
  },
  [GC.GRC.LOAD_BRANCH_NAME]: {
    width: 200,
    searchable: false,
    name: ['titles:load', 'titles:branch'],
  },
  [GC.GRC.LOAD_BRANCH_ACCOUNT_NAME]: {
    width: 200,
    searchable: false,
    name: ['titles:load', 'titles:account-number'],
  },
  [GC.GRC.LOAD_EVENT_COUNT]: {
    width: 200,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:event-count'],
  },
  [GC.GRC.LOAD_INVOICE_COUNT]: {
    width: 200,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:invoice-count'],
  },
  [GC.GRC.LOAD_STORED_TOTAL_WEIGHT]: {
    width: 200,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:total-weight'],
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_WEIGHT} />,
  },
  [GC.GRC.LOAD_STORED_TOTAL_VOLUME]: {
    width: 200,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:total-volume'],
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_ITEM_VOLUME} />,
  },
  [GC.GRC.LOAD_STORED_TOTAL_DISTANCE]: {
    width: 200,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:total-distance'],
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_DISTANCE} />,
  },
  [GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:container-number'],
  },
  [GC.GRC.CLO_CONTAINERS_CONTAINER_INITIAL]: {
    width: 150,
    searchable: false,
    name: ['titles:clo', 'titles:container-initial'],
  },
  [GC.GRC.CLO_ITEMS_FREIGHT_CLASS]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:freight-class'],
  },
  [GC.GRC.CLO_ITEMS_TEMPERATURE_TYPE]: {
    width: 300,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:temperature-type'],
    customComponent: ({ data }: Object) => {
      const text = G.getReportColumnPropTextFromData(GC.GRC.CLO_ITEMS_TEMPERATURE_TYPE, data);

      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.GRC.CLO_ITEMS_MANUFACTURER]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:manufacturer'],
  },
  [GC.GRC.CLO_ITEMS_YEAR]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:year'],
  },
  [GC.GRC.CLO_ITEMS_MAKE]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:make'],
  },
  [GC.GRC.CLO_ITEMS_MODEL]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:model'],
  },
  [GC.GRC.CLO_ITEMS_SUB_MODEL]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:sub-model'],
  },
  [GC.GRC.CLO_ITEMS_WHEELBASE]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:wheelbase'],
  },
  [GC.GRC.CLO_ITEMS_WHEELBASE_UOM]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:wheelbase-uom'],
  },
  [GC.GRC.CLO_ITEMS_ODOMETER]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:odometer'],
  },
  [GC.GRC.CLO_ITEMS_ODOMETER_UOM]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:odometer-uom'],
  },
  [GC.GRC.CLO_ITEMS_COLOR]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:color'],
  },
  [GC.GRC.CLO_ITEMS_LICENSE_PLATE]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:license-plate'],
  },
  [GC.GRC.CLO_ITEMS_VIN]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:vin'],
  },
  [GC.GRC.CLO_ITEMS_BAY]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:bay'],
  },
  [GC.GRC.CLO_ITEMS_ITEM_TYPE]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:item-type'],
  },
  [GC.GRC.CLO_ITEMS_ITEM_ID]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:item-id'],
  },
  [GC.GRC.CLO_ITEMS_ORIGIN_STATE]: {
    width: 250,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:origin-state'],
  },
  [GC.GRC.CLO_ITEMS_ORIGIN_COUNTRY]: {
    width: 250,
    searchable: false,
    name: ['titles:clo', 'titles:items', 'titles:origin-country'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID]: {
    width: 220,
    searchable: false,
    name: ['titles:primary-driver', 'titles:login-id'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL]: {
    width: 220,
    searchable: false,
    name: ['titles:primary-driver', 'titles:email'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME]: {
    width: 220,
    searchable: false,
    name: ['titles:primary-driver', 'titles:first-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME]: {
    width: 220,
    searchable: false,
    name: ['titles:primary-driver', 'titles:last-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID]: {
    width: 220,
    searchable: false,
    name: ['titles:secondary-driver', 'titles:login-id'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL]: {
    width: 220,
    searchable: false,
    name: ['titles:secondary-driver', 'titles:email'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME]: {
    width: 220,
    searchable: false,
    name: ['titles:secondary-driver', 'titles:first-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME]: {
    width: 220,
    searchable: false,
    name: ['titles:secondary-driver', 'titles:last-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_TRUCK_UNIT_ID]: {
    width: 220,
    searchable: false,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME]: {
    width: 200,
    searchable: false,
    name: ['titles:truck', 'titles:vendor'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID]: {
    width: 220,
    searchable: false,
    name: ['titles:trailers', 'titles:unit-id'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FULL_NAME]: {
    width: 220,
    searchable: false,
    name: ['titles:primary-driver', 'titles:full-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FULL_NAME]: {
    width: 220,
    searchable: false,
    name: ['titles:secondary-driver', 'titles:full-name'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_NAME]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:name'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:scac'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:mc-number'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_TRUCK]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:truck'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_TRAILER]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:trailer'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:primary-driver'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:primary-driver', 'titles:phone'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:secondary-driver'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE]: {
    width: 200,
    searchable: false,
    name: ['titles:carrier', 'titles:secondary-driver', 'titles:phone'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:first-event', 'titles:location-name'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:first-event', 'titles:template-id'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:first-event', 'titles:address1'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ADDRESS2]: {
    width: 250,
    name: ['titles:first-event', 'titles:address2'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:first-event', 'titles:city'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:state'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:first-event', 'titles:country'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:first-event', 'titles:zip'],
  },
  [GC.GRC.FIRST_EVENT_COMPLETE_DATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:complete-date'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:last-event', 'titles:template-id'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:last-event', 'titles:location-name'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:last-event', 'titles:address1'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ADDRESS2]: {
    width: 250,
    name: ['titles:last-event', 'titles:address2'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:last-event', 'titles:city'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:last-event', 'titles:state'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:last-event', 'titles:country'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:last-event', 'titles:zip'],
  },
  [GC.GRC.LAST_EVENT_COMPLETE_DATE]: {
    width: 250,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:complete-date'],
  },
  [GC.GRC.FIRST_EVENT_EARLY_DATE]: {
    width: 250,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:early-date'],
  },
  [GC.GRC.FIRST_EVENT_LATE_DATE]: {
    width: 250,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:late-date'],
  },
  [GC.GRC.FIRST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.LAST_EVENT_EARLY_DATE]: {
    width: 250,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:early-date'],
  },
  [GC.GRC.LAST_EVENT_LATE_DATE]: {
    width: 250,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:late-date'],
  },
  [GC.GRC.LAST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_TYPE]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:location-type'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_NAME]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:location-name'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:template-id'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:address1'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_ADDRESS2]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:address2'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_CITY]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:city'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_STATE]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:state'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:country'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LOCATION_ZIP]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:first-event', 'titles:zip'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_COMPLETE_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:first-event', 'titles:complete-date'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_TYPE]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:location-type'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:template-id'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_NAME]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:location-name'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:address1'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_ADDRESS2]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:address2'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_CITY]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:city'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_STATE]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:state'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:country'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LOCATION_ZIP]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:last-event', 'titles:zip'],
  },
  [GC.GRC.LOAD_LAST_EVENT_COMPLETE_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:last-event', 'titles:complete-date'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_EARLY_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:first-event', 'titles:early-date'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_LATE_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:first-event', 'titles:late-date'],
  },
  [GC.GRC.LOAD_FIRST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:first-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.LOAD_LAST_EVENT_EARLY_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:last-event', 'titles:early-date'],
  },
  [GC.GRC.LOAD_LAST_EVENT_LATE_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:last-event', 'titles:load', 'titles:last-event'],
  },
  [GC.GRC.LOAD_LAST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    searchable: false,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:load', 'titles:last-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.LOAD_BILL_TO_LOCATION_NAME]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:name'],
  },
  [GC.GRC.LOAD_BILL_TO_COUNTRY]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:country'],
  },
  [GC.GRC.LOAD_BILL_TO_STATE]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:state'],
  },
  [GC.GRC.LOAD_BILL_TO_CITY]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:city'],
  },
  [GC.GRC.LOAD_BILL_TO_ADDRESS1]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:address1'],
  },
  [GC.GRC.LOAD_BILL_TO_ADDRESS2]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:address2'],
  },
  [GC.GRC.LOAD_BILL_TO_ZIP]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:zip'],
  },
  [GC.GRC.LOAD_BILL_TO_COMMENTS]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:comments'],
  },
  [GC.GRC.LOAD_BILL_TO_PAYMENT_TERM]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:payment-terms'],
  },
  [GC.GRC.LOAD_BILL_TO_LOCATION_TYPE]: {
    width: 250,
    searchable: false,
    name: ['titles:load', 'titles:bill-to', 'titles:location-type'],
  },
  [GC.GRC.LOAD_RATE_MAIN_CHARGES_TOTAL]: {
    width: 220,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:main-charges-total'],
  },
  [GC.GRC.LOAD_RATE_ADDITIONAL_CHARGES_TOTAL]: {
    width: 220,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:additional-charges-total'],
  },
  [GC.GRC.LOAD_RATE_FUEL_CHARGES_TOTAL]: {
    width: 220,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:fuel-charges-total'],
  },
  [GC.GRC.LOAD_RATE_TOTAL]: {
    width: 220,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:total-rate'],
  },
  [GC.GRC.LOAD_RATE_NORMALIZED_TOTAL]: {
    width: 220,
    searchable: false,
    pivotType: 'number',
    name: ['titles:load', 'titles:normalized-total-rate'],
  },
  [GC.GRC.LOAD_RATE_MODE]: {
    width: 200,
    searchable: false,
    name: ['titles:load', 'titles:mode'],
  },
  [GC.FIELD_PRIMARY_DIVISION_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:primary-division-name',
  },
};

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_NAME,
    name: `${G.getWindowLocale('titles:route', 'route')}: ${G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:branch', 'Branch')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_PRIMARY_DIVISION_NAME,
    name: G.getWindowLocale('titles:primary-division-name', 'Primary Division Name'),
  },
  {
    type: 'number',
    value: GC.FIELD_MARGIN,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:margin', 'Margin')}`,
  },
  {
    type: 'number',
    value: GC.FIELD_CLO_COUNT,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:clo-count', 'Clo Count')}`,
  },
  {
    type: 'number',
    value: GC.FIELD_TEL_COUNT,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:tel-count', 'Tel Count')}`,
  },
  {
    type: 'number',
    value: GC.TOTAL_INCOME,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:total-income', 'Total Income')}`,
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_SPEND,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:total-spend', 'Total Spend')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:created-by', 'Created By')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:created-date', 'Created Date')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_LAST_MODIFIED_BY,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${
      G.getWindowLocale('titles:last-modified-by', 'Last Modified By')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${
      G.getWindowLocale('titles:last-modified-date', 'Last Modified Date')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_TYPE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_STATUS,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:status', 'Status')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:branch', 'Branch')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BRANCH_ACCOUNT_NAME,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:account-number', 'Account Number')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_EVENT_COUNT,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:event-count', 'Event Count')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_INVOICE_COUNT,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:invoice-count', 'Invoice Count')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_STORED_TOTAL_WEIGHT,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:total-weight', 'Total Weight')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_STORED_TOTAL_VOLUME,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:total-volume', 'Total Volume')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_STORED_TOTAL_DISTANCE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:total-distance', 'Total Distance')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${
      G.getWindowLocale('titles:container-number', 'Container Number')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_CONTAINERS_CONTAINER_INITIAL,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${
      G.getWindowLocale('titles:container-initial', 'Container Initial')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_FREIGHT_CLASS,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:freight-class', 'Freight Class')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_TEMPERATURE_TYPE,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:temperature-type', 'Temperature Type')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_MANUFACTURER,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:manufacturer', 'Manufacturer')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_YEAR,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:year', 'Year')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_MAKE,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:make', 'Make')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_MODEL,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:model', 'Model')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_SUB_MODEL,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:sub-model', 'Sub Model')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_WHEELBASE,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase', 'Wheelbase')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_WHEELBASE_UOM,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase UOM')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_ODOMETER,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer', 'Odometer')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_ODOMETER_UOM,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer-uom', 'Odometer UOM')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_COLOR,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:color', 'Color')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_LICENSE_PLATE,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:license-plate', 'License Plate')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_VIN,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:vin', 'Vin')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_BAY,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:bay', 'Bay')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_ITEM_TYPE,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-type', 'Item Type')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_ITEM_ID,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-id', 'Item ID')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_ORIGIN_STATE,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-state', 'Origin State')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.CLO_ITEMS_ORIGIN_COUNTRY,
    name: `${G.getWindowLocale('titles:clo', 'Clo')}: ${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-country', 'Origin Country')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:vendor', 'Vendor')}`,
  },
  {
    type: 'string',
    collection: true,
    disableFilter: true,
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
    name: `${G.getWindowLocale('titles:trailers', 'Trailers')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_NAME,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:scac', 'SCAC')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:mc-number', 'MC Number')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_TRUCK,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:truck', 'Truck')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_TRAILER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:trailer', 'Trailer')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Tempate ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_FIRST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.LOAD_LAST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_ADDRESS2,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_BILL_TO_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_RATE_MAIN_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:main-charges-total', 'Main Charges Total')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_RATE_ADDITIONAL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:additional-charges-total', 'Additional Charges Total')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_RATE_FUEL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:fuel-charges-total', 'Fuel Charges Total')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_RATE_TOTAL,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:total-rate', 'Total Rate')}`,
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.GRC.LOAD_RATE_NORMALIZED_TOTAL,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${
      G.getWindowLocale('titles:normalized-total', 'Normalized Total')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.LOAD_RATE_MODE,
    name: `${G.getWindowLocale('titles:load', 'Load')}: ${G.getWindowLocale('titles:mode', 'Mode')}`,
  },
];
