import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// features
import PC from '../../../../permission/role-permission';
import AuthHoc, { AuthWrapper } from '../../../../permission';
import { setExpandedContainerOptions } from '../../../../expanded-container/actions';
import { makeSelectExpandedContainerOptions } from '../../../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex, HideIfUserTypeCarrierBox } from '../../../../../ui';
// utilities
import routesMap from '../../../../../utilities/routes';
// feature dispatch-detail-new
import { InfoPair, ContactInfoPair, InfoPairWithLabelAction } from '../../info-pair';
import { withLoadPaymentsActions } from '../../../load/hocs/with-load-payments-actions';
import { withOrderPaymentsActions } from '../../../order/hocs/with-order-payments-actions';
//////////////////////////////////////////////////

// TODO: check get totals from API rate without calculation as it used exchange rate

const getPrimaryReferenceValue = R.compose(
  R.path([GC.FIELD_VALUE]),
  R.find(R.propEq(true, GC.FIELD_PRIMARY)),
  R.pathOr([], [GC.FIELD_REFERENCES]),
);

const getMainRateName = (rate: Object, orValue: string = '') => (
  R.or(G.getChargeRateNameFromRate(rate, GC.CHARGE_TYPE_MAIN), orValue)
);

const getTelMainTotalInfo = (rate: Object = {}) => (
  `${G.getCurrencySymbolFromRate(rate)} ${G.getCalcTelRateTotalByType(rate, GC.CHARGE_TYPE_MAIN).toFixed(2)}`
);

const getTelFuelTotalInfo = (rate: Object = {}) => (
  `${G.getCurrencySymbolFromRate(rate)} ${G.getCalcTelRateTotalByType(rate, GC.CHARGE_TYPE_FUEL).toFixed(2)}`
);

const getTelAdditionalChargesTotalInfo = (rate: Object = {}) => (
  `${G.getCurrencySymbolFromRate(rate)} ${G.getCalcTelRateTotalByType(
    rate, GC.CHARGE_TYPE_ADDITIONAL, { omitAdvancePayment: true }).toFixed(2)}`
);

const getCloOnTelRateTotalInfo = (
  entity: Object,
  costAllocations: Array,
  configCurrency: string = GC.DEFAULT_UI_CURRENCY,
) => {
  const currency = R.pathOr(configCurrency, [0, GC.FIELD_CURRENCY], costAllocations);

  if (G.isNilOrEmpty(costAllocations)) return `${G.getCurrencySymbol(currency)} 0.00`;

  const costAllocation = R.find(
    (ca: Object) => R.equals(R.path([GC.FIELD_FINANCE_RELATION, GC.FIELD_CLO_GUID], ca), entity.guid),
    costAllocations,
  );
  const cost = R.or(costAllocation.cloCost, 0);

  return `${G.getCurrencySymbol(currency)} ${cost.toFixed(2)}`;
};

const getSelectedRate = (rates: Array = []) => R.find(
  (rate: Object) => rate.selected,
  rates,
);

const getTelOnCloRateTotalInfo = (
  entity: Object,
  costAllocations: Array,
  configCurrency: string = GC.DEFAULT_UI_CURRENCY,
) => {
  const currency = R.pathOr(configCurrency, [0, GC.FIELD_CURRENCY], costAllocations);

  if (G.isNilOrEmpty(costAllocations)) return `${G.getCurrencySymbol(currency)} 0.00`;

  const costAllocation = R.find(
    (ca: Object) => R.equals(R.path([GC.FIELD_FINANCE_RELATION, GC.FIELD_TEL_GUID], ca), entity.guid),
    costAllocations,
  );
  const cost = R.or(costAllocation.telCost, 0);

  return `${G.getCurrencySymbol(currency)} ${cost.toFixed(2)}`;
};

const getClosOnTelTotalInfo = (tel: Object, configCurrency: string = GC.DEFAULT_UI_CURRENCY) => {
  const costAllocations = R.pathOr([], [GC.FIELD_FINANCE_COST_ALLOCATIONS], tel);
  const cost = R.sum(R.map(
    (costAllocation: Object) => costAllocation.cloCost,
    R.pathOr([], [GC.FIELD_FINANCE_COST_ALLOCATIONS], tel),
  ));
  const currency = R.pathOr(configCurrency, [0, GC.FIELD_CURRENCY], costAllocations);

  return `${G.getCurrencySymbol(currency)} ${cost.toFixed(2)}`;
};

const getTelsOnCloTotalInfo = (clo: Object, configCurrency: string = GC.DEFAULT_UI_CURRENCY) => {
  const cost = R.sum(R.map(
    (costAllocation: Object) => costAllocation.telCost,
    R.pathOr([], [GC.FIELD_FINANCE_COST_ALLOCATIONS], clo),
  ));
  const selectedRate = getSelectedRate(clo.relatedTelRates);
  const currency = R.pathOr(configCurrency, [GC.FIELD_CURRENCY], selectedRate);

  return `${G.getCurrencySymbol(currency)} ${cost.toFixed(2)}`;
};

const whiteColor = G.getTheme('colors.white');
const borderColor = G.getTheme('colors.bgGrey');
const blueColor = G.getTheme('colors.light.blue');
const darkBlueColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const InvoicesSection = ({ invoices }: Object) => (
  <Box p='8px'>
    <Box fontSize={12} fontWeight='bold' color={textColor} textAlign='center'>
      {G.getWindowLocale('titles:invoices', 'Invoices')}
    </Box>
    {
      invoices.map((invoice: Object, i: number) => (
        <InfoPair
          key={i}
          label={invoice.invoiceNumber}
          text={`${invoice.total} ${G.getCurrencySymbol(invoice.currency)}`}
        />
      ))
    }
    <Box borderBottom='1px solid' borderColor={borderColor} />
  </Box>
);

const BillToSection = AuthHoc(
  [PC.TEL_BILL_TO_READ, PC.TEL_BILL_TO_WRITE],
)((props: Object) => {
  const {
    billTo,
    maxHeight,
    autodialApp,
    addDisabled,
    editDisabled,
    handleEditBillTo,
    handleCreateBillTo,
  } = props;

  if (G.isNilOrEmpty(billTo)) {
    if (G.isTrue(addDisabled)) return null;

    return (
      <HideIfUserTypeCarrierBox
        mt={12}
        ml='8px'
        p='4px 10px'
        fontSize={11}
        cursor='pointer'
        bg={darkBlueColor}
        color={whiteColor}
        borderRadius='4px'
        width='max-content'
        onClick={handleCreateBillTo}
      >
        {G.getWindowLocale('titles:add-bill-to', 'Add Bill To')}
      </HideIfUserTypeCarrierBox>
    );
  }

  const { phone, email, contactName, paymentTerm, phoneExtension } = billTo;

  const phoneText = G.ifElse(
    G.isAllNotNilOrNotEmpty([phone, phoneExtension]),
    `${phone}(${phoneExtension})`,
    phone,
  );
  const phoneNumber = G.ifElse(
    G.isAllNotNilOrNotEmpty([phone, phoneExtension]),
    `${phone}${phoneExtension}`,
    phone,
  );

  return (
    <Box
      p='8px'
      borderBottom='1px solid'
      borderColor={borderColor}
    >
      <Flex
        py='4px'
        width='100%'
        fontSize={12}
        lineHeight={1.6}
        color={textColor}
        alignItems='flex-start'
      >
        <Box>
          {G.getWindowLocale('titles:bill-to', 'Bill To')}:
        </Box>
        <Box mx='5px' fontWeight='bold' width='calc(100% - 60px)'>
          <Box wordBreak='break-all'>
            {G.renderAddressNameAndCode(billTo)}
          </Box>
          <Box wordBreak='break-all'>
            {G.renderAddressInfoWithNameAndCode(billTo, false, false)}
          </Box>
          <Box wordBreak='break-all'>
            {G.concatLocationFields(billTo)}
          </Box>
        </Box>
        {
          R.not(editDisabled) &&
          <HideIfUserTypeCarrierBox cursor='pointer' onClick={() => handleEditBillTo(billTo)}>
            {I.pencil(blueColor)}
          </HideIfUserTypeCarrierBox>
        }
      </Flex>
      <InfoPair
        text={G.getEnumLocale(paymentTerm)}
        label={G.getWindowLocale('titles:payment-terms', 'Payment Term')}
      />
      <InfoPair
        text={contactName}
        label={G.getWindowLocale('titles:contact-name', 'Contact Name')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        text={phoneText}
        phoneNumber={phoneNumber}
        autodialApp={autodialApp}
        label={G.getWindowLocale('titles:phone-number', 'Phone Number')}
      />
      <ContactInfoPair
        text={email}
        label={G.getWindowLocale('titles:email', 'Email')}
      />
    </Box>
  );
});

const LoadTotalsSection = AuthHoc(
  [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
)((props: Object) => {
  const { selectedRate } = props;

  const totalTxtLocale = G.getWindowLocale('titles:total', 'Total');
  const rateTxtLocale = G.getWindowLocale('titles:rate', 'Rate');
  const telsTotalTxtLocale = `${G.getWindowLocale('titles:trip', 'Trip')} ${rateTxtLocale} ${totalTxtLocale}`;

  return (
    <Box px='8px' ml='auto' width='max-content'>
      <InfoPair
        label={telsTotalTxtLocale}
        text={G.getTotalTextFromRate(selectedRate)}
      />
    </Box>
  );
});

const OrderTotalsSection = AuthHoc(
  [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
)((props: Object) => {
  const { selectedRate } = props;

  const totalTxtLocale = G.getWindowLocale('titles:total', 'Total');
  const rateTxtLocale = G.getWindowLocale('titles:rate', 'Rate');
  const closTotalTxtLocale = `${G.getWindowLocale('titles:order', 'Order')} ${rateTxtLocale} ${totalTxtLocale}`;

  return (
    <Box px='8px' ml='auto' width='max-content'>
      <InfoPair
        label={closTotalTxtLocale}
        text={G.getTotalTextFromRate(selectedRate)}
      />
    </Box>
  );
});

const ClosOnTelTotalsSection = AuthHoc(
  [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
)((props: Object) => {
  const { tel, configCurrency } = props;

  const totalTxtLocale = G.getWindowLocale('titles:total', 'Total');
  const closTotalTxtLocale = `${G.getWindowLocale('titles:clos', 'CLOs')} ${totalTxtLocale}`;

  return (
    <Box px='8px' ml='auto' width='max-content'>
      <InfoPair
        label={closTotalTxtLocale}
        text={getClosOnTelTotalInfo(tel, configCurrency)}
      />
    </Box>
  );
});

const TelsOnCloTotalsSection = AuthHoc(
  [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
)((props: Object) => {
  const { clo, configCurrency } = props;

  const totalTxtLocale = G.getWindowLocale('titles:total', 'Total');
  const telsTotalTxtLocale = `${G.getWindowLocale('titles:tels', 'TELs')} ${totalTxtLocale}`;

  return (
    <Box px='8px' ml='auto' width='max-content'>
      <InfoPair
        label={telsTotalTxtLocale}
        text={getTelsOnCloTotalInfo(clo, configCurrency)}
      />
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

const enhanceLoadPaymentsSection = compose(
  AuthHoc([PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]),
  connect(mapStateToProps, { setExpandedContainerOptions }),
  withHandlers({
    handleClickOnPrimaryReference: (props: Object) => (guid: string) => {
      const { expandedContainerOptions, setExpandedContainerOptions } = props;

      const { opened, componentType } = expandedContainerOptions;

      if (R.and(G.isTrue(opened), R.equals(componentType, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD))) {
        setExpandedContainerOptions({ opened, visitPageGuid: guid, componentType: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER });
      } else {
        G.goToRoute(routesMap.dispatchDetailsOrder(guid));
      }
    },
  }),
  pure,
);

const invoiceTotalsMap = {
  [GC.INVOICE_TYPE_FLEET_INVOICE]: {
    locale: ['titles:driver-invoice-total', 'Driver Invoice Total'],
    permissions: [PC.TEL_FLEET_INVOICE_READ, PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  [GC.INVOICE_TYPE_CARRIER_INVOICE]: {
    locale: ['titles:carrier-invoice-total', 'Carrier Invoice Total'],
    permissions: [PC.TEL_CARRIER_INVOICE_READ, PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  [GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE]: {
    locale: ['titles:vendor-invoice-total', 'Vendor Invoice Total'],
    permissions: [PC.TEL_FLEET_INVOICE_READ, PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  [GC.INVOICE_TYPE_CUSTOMER_INVOICE]: {
    locale: ['titles:customer-invoice-total', 'Customer Invoice Total'],
    permissions: [PC.CLO_INVOICE_READ, PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  [GC.INVOICE_TYPE_SERVICE_VENDOR_INVOICE]: {
    permissions: [PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    locale: ['titles:service-vendor-invoice-total', 'Service Vendor Invoice Total'],
  },
};

const InvoiceTotalsSection = ({ invoiceTotals }: Object) => (
  <Fragment>
    {
      invoiceTotals.map(({ total, currency, invoiceType }: Object, index: number) => (
        <AuthWrapper key={index} display='block' has={R.path([invoiceType, 'permissions'], invoiceTotalsMap)}>
          <InfoPair
            text={`${G.getCurrencySymbol(currency)} ${total}`}
            label={G.getWindowLocale(...R.pathOr([], [invoiceType, 'locale'], invoiceTotalsMap))}
          />
          <Box borderBottom='1px solid' borderColor={borderColor} />
        </AuthWrapper>
      ))
    }
  </Fragment>
);

const LoadPaymentsSection = enhanceLoadPaymentsSection((props: Object) => {
  const {
    clos,
    load,
    selectedRate,
    invoiceTotals,
    configCurrency,
    costAllocations,
    normalizedCurrency,
    primaryReferenceValue,
    handleClickOnPrimaryReference,
  } = props;

  const { normalizedTripMaxPay } = load;

  const cloTxtLocale = G.getWindowLocale('titles:clo', 'CLO');

  return (
    <Box p='8px'>
      <InfoPair
        text={primaryReferenceValue}
        label={G.getWindowLocale('titles:tel', 'TEL')}
      />
      <InfoPair
        text={getTelMainTotalInfo(selectedRate)}
        label={G.getWindowLocale('titles:main', 'Main')}
      />
      <InfoPair
        text={getTelFuelTotalInfo(selectedRate)}
        label={G.getWindowLocale('titles:fuel', 'Fuel')}
      />
      <InfoPair
        label={G.getWindowLocale('titles:other', 'Other')}
        text={getTelAdditionalChargesTotalInfo(selectedRate)}
      />
      <Box borderBottom='1px solid' borderColor={borderColor} />
      {G.isNotNilAndNotEmpty(invoiceTotals) && <InvoiceTotalsSection invoiceTotals={invoiceTotals} />}
      <LoadTotalsSection selectedRate={selectedRate} />
      <Box borderBottom='1px solid' borderColor={borderColor} />
      <AuthWrapper width='100%' display='block' has={[PC.SHOW_EARNINGS_EXECUTE]}>
        {
          R.or(clos, []).map((clo: Object) => {
            const { guid, primaryReference } = clo;

            const action = () => handleClickOnPrimaryReference(guid);
            const text = getCloOnTelRateTotalInfo(clo, costAllocations, configCurrency);

            return (
              <InfoPairWithLabelAction
                key={guid}
                text={text}
                action={action}
                label={cloTxtLocale}
                labelActionText={R.pathOr('', [GC.FIELD_VALUE], primaryReference)}
              />
            );
          })
        }
        <Box borderBottom='1px solid' borderColor={borderColor} />
        <ClosOnTelTotalsSection tel={load} configCurrency={configCurrency} />
      </AuthWrapper>
      {
        G.isNotNil(normalizedTripMaxPay) &&
        <AuthWrapper
          width='100%'
          display='block'
          has={[PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]}
        >
          <Box borderBottom='1px solid' borderColor={borderColor} />
          <InfoPair
            label={G.getWindowLocale('titles:trip-max-pay', 'Trip Max Pay')}
            text={`${G.getCurrencySymbol(normalizedCurrency)} ${normalizedTripMaxPay.toFixed(2)}`}
          />
        </AuthWrapper>
      }
    </Box>
  );
});

const enhanceOrderPaymentsSection = compose(
  AuthHoc([PC.CLO_RATE_READ, PC.CLO_RATE_WRITE]),
  connect(mapStateToProps, { setExpandedContainerOptions }),
  withHandlers({
    handleClickOnPrimaryReference: (props: Object) => (guid: string) => {
      const { expandedContainerOptions, setExpandedContainerOptions } = props;

      const { opened, componentType } = expandedContainerOptions;

      if (R.and(G.isTrue(opened), R.equals(componentType, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER))) {
        setExpandedContainerOptions({ opened, componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD, visitPageGuid: guid });
      } else {
        G.goToRoute(routesMap.dispatchDetailsLoad(guid));
      }
    },
  }),
  pure,
);

const OrderPaymentsSection = enhanceOrderPaymentsSection((props: Object) => {
  const {
    tels,
    load,
    selectedRate,
    invoiceTotals,
    configCurrency,
    costAllocations,
    primaryReferenceValue,
    handleClickOnPrimaryReference,
  } = props;

  const { normalizedTripMaxPay } = load;

  const normalizedCurrency = R.path([GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_CURRENCY], selectedRate);

  const telTxtLocale = G.getWindowLocale('titles:tel', 'TEL');

  return (
    <Box p='8px'>
      <InfoPair
        text={primaryReferenceValue}
        label={G.getWindowLocale('titles:clo', 'CLO')}
      />
      <InfoPair
        text={G.getCloMainTotalInfo(selectedRate)}
        label={getMainRateName(selectedRate, G.getWindowLocale('titles:main', 'Main'))}
      />
      <InfoPair
        text={G.getCloDiscountTotalInfo(selectedRate)}
        label={G.getWindowLocale('titles:discount', 'Discount')}
      />
      <InfoPair
        text={G.getCloFuelTotalInfo(selectedRate)}
        label={G.getWindowLocale('titles:fuel', 'Fuel')}
      />
      <InfoPair
        label={G.getWindowLocale('titles:other', 'Other')}
        text={G.getCloAdditionalChargesTotalInfo(selectedRate)}
      />
      <Box borderBottom='1px solid' borderColor={borderColor} />
      {G.isNotNilAndNotEmpty(invoiceTotals) && <InvoiceTotalsSection invoiceTotals={invoiceTotals} />}
      <OrderTotalsSection selectedRate={selectedRate} />
      <Box borderBottom='1px solid' borderColor={borderColor} />
      <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
        <Flex flexDirection='column'>
          {
            tels.map((tel: Object) => {
              const { guid, primaryReference } = tel;
              const action = () => handleClickOnPrimaryReference(guid);
              const text = getTelOnCloRateTotalInfo(tel, costAllocations, configCurrency);

              return (
                <InfoPairWithLabelAction
                  key={guid}
                  text={text}
                  action={action}
                  label={telTxtLocale}
                  labelActionText={R.prop(GC.FIELD_VALUE, primaryReference)}
                />
              );
            })
          }
        </Flex>
      </AuthWrapper>
      <Box borderBottom='1px solid' borderColor={borderColor} />
      <TelsOnCloTotalsSection clo={load} configCurrency={configCurrency} />
      {
        G.isNotNil(normalizedTripMaxPay) &&
        <Box borderBottom='1px solid' borderColor={borderColor} />
      }
      {
        G.isNotNil(normalizedTripMaxPay) &&
        <InfoPair
          label={G.getWindowLocale('titles:trip-max-pay', 'Trip Max Pay')}
          text={`${G.getCurrencySymbol(normalizedCurrency)} ${normalizedTripMaxPay.toFixed(2)}`}
        />
      }
    </Box>
  );
});

export const LoadPaymentsCardComponent = withLoadPaymentsActions((props: Object) => {
  const {
    load,
    invoices,
    addDisabled,
    autodialApp,
    selectedRate,
    editDisabled,
    invoiceTotals,
    configCurrency,
    handleEditBillTo,
    handleCreateBillTo,
    normalizedCurrency,
  } = props;

  const { clos, billTo, costAllocations } = load;

  return (
    <Box height={400} overflowY='auto'>
      <BillToSection
        billTo={billTo}
        addDisabled={addDisabled}
        autodialApp={autodialApp}
        editDisabled={editDisabled}
        handleEditBillTo={handleEditBillTo}
        handleCreateBillTo={handleCreateBillTo}
      />
      {G.isNotNilAndNotEmpty(invoices) && <InvoicesSection invoices={invoices} />}
      <LoadPaymentsSection
        clos={clos}
        load={load}
        selectedRate={selectedRate}
        invoiceTotals={invoiceTotals}
        configCurrency={configCurrency}
        costAllocations={costAllocations}
        normalizedCurrency={normalizedCurrency}
        primaryReferenceValue={getPrimaryReferenceValue(load)}
      />
    </Box>
  );
});

export const OrderPaymentsCardComponent = withOrderPaymentsActions((props: Object) => {
  const {
    load,
    invoices,
    autodialApp,
    addDisabled,
    selectedRate,
    editDisabled,
    invoiceTotals,
    configCurrency,
    handleEditBillTo,
  } = props;

  const { tels, billTo, costAllocations } = load;

  return (
    <Box height={400} overflowY='auto'>
      <BillToSection
        billTo={billTo}
        addDisabled={addDisabled}
        autodialApp={autodialApp}
        editDisabled={editDisabled}
        handleEditBillTo={handleEditBillTo}
      />
      {G.isNotNilAndNotEmpty(invoices) && <InvoicesSection invoices={invoices} />}
      <OrderPaymentsSection
        tels={tels}
        load={load}
        selectedRate={selectedRate}
        invoiceTotals={invoiceTotals}
        configCurrency={configCurrency}
        costAllocations={costAllocations}
        primaryReferenceValue={getPrimaryReferenceValue(load)}
      />
    </Box>
  );
});
