import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Form, Fieldset2 } from '../../../forms';
import { SelectLocation } from '../../../forms/forms/select-location';
import { LocationTemplateSearch } from '../../../forms/forms/template-search/components/location-template-search';
// icons
import * as I from '../../../svgs';
// feature fleet
import { Text, SimpleWrapper } from '../ui';
import {
  latestKnownLocationFields,
  defaultLatestKnownLocationFields,
  latestKnownLocationValidationSchema,
} from '../settings/fields-settings';
////////////////////////////////////////////////

const enhance = compose(
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withHandlers({
    handleSearchLocation: (props: Object) => () => {
      const { openModal, branchGuid, closeModal, setInitialValues } = props;

      const searchTemplateRequest = (location: Object) => {
        const neededValues = R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR, location);
        setInitialValues((prev: Object) => R.mergeRight(prev, neededValues));
        closeModal();
      };

      const modalContent = (
        <LocationTemplateSearch
          closeModal={closeModal}
          branchGuid={branchGuid}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
    handleSelectLocation: (props: Object) => (e: Event) => {
      const { openModal, closeModal, initialValues, setInitialValues } = props;

      G.stopPropagation(e);

      const searchTemplateRequest = (location: Object) => {
        const needValues = R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR, location);

        setInitialValues((prev: Object) => R.mergeRight(prev, needValues));
        closeModal();
      };

      const modalContent = (
        <SelectLocation
          closeModal={closeModal}
          initialValues={initialValues}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
  }),
  pure,
);

const getInitialValues = (props: Object) => {
  const { initialValues, searchedValues } = props;

  if (G.isNotNilAndNotEmpty(searchedValues)) {
    const pickedInitialValues = R.pick(
      GC.GROUPED_FIELDS.LATEST_KNOWN_LOCATION_ARR,
      R.pathOr({}, ['initialValues'], props),
    );

    return R.mergeRight(pickedInitialValues, searchedValues);
  }

  return initialValues;
};

const enhanceForm = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: latestKnownLocationValidationSchema,
    mapPropsToValues: R.pathOr(defaultLatestKnownLocationFields, ['initialValues']),
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, submitAction } = props;

      submitAction(values);
      closeModal();
    },
  }),
  withHandlers({
    handleChangeAddress: ({ change }: Object) => (address: string) => {
      change(GC.FIELD_CITY, address);
    },
    handleSelectAddress: (props: Object) => async (value: string) => {
      const { change, openLoader, closeLoader } = props;

      openLoader({ showDimmer: true });

      const {
        zip,
        city,
        state,
        country,
        latitude,
        longitude,
      } = await G.geocodeByPlaceAddress(value, 'fleet -> LatestKnownLocationForm');

      const locationArr = [
        {
          value: city,
          fieldName: GC.FIELD_CITY,
        },
        {
          value: state,
          fieldName: GC.FIELD_STATE,
        },
        {
          value: zip,
          fieldName: GC.FIELD_ZIP,
        },
        {
          value: country,
          fieldName: GC.FIELD_COUNTRY,
        },
        {
          value: latitude,
          fieldName: GC.FIELD_LATITUDE,
        },
        {
          value: longitude,
          fieldName: GC.FIELD_LONGITUDE,
        },
      ];

      R.forEach(({ value, fieldName }: Object) => change(fieldName, value), locationArr);

      closeLoader();
    },
  }),
  pure,
);

const mapStateToProps = (state: Object, ownProps: Object) => ({
  initialValues: getInitialValues(ownProps),
});

const LatestKnownLocationForm = (props: Object) => (
  <Form p={15} onSubmit={props.handleSubmit} >
    <Fieldset2
      {...G.getFormikPropsToFieldset(props)}
      fields={latestKnownLocationFields}
      fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
    />
    <FormFooter2 />
  </Form>
);

const ItemFormWithConnect = connect(mapStateToProps)(enhanceForm(LatestKnownLocationForm));

const LatestKnownLocationFormComponent = (props: Object) => (
  <SimpleWrapper display='block' height='100%' flexDirection='column'>
    <SimpleWrapper
      pl={20}
      width='100%'
      height='30px'
      jc='flex-start'
      bgColor={G.getTheme('colors.mainBlue')}
    >
      <Text fontWeight='bold' color={G.getTheme('forms.group.title.textColor')}>
        {G.getWindowLocale('titles:add-last-known-location', 'Add Last Known Location')}
      </Text>
      <SimpleWrapper ml={10} cursor='pointer' onClick={props.handleSearchLocation}>
        {I.search(G.getTheme('forms.group.title.textColor'))}
      </SimpleWrapper>
      <SimpleWrapper ml={10} cursor='pointer' onClick={props.handleSelectLocation}>
        {I.location(G.getTheme('forms.group.title.textColor'))}
      </SimpleWrapper>
    </SimpleWrapper>
    <ItemFormWithConnect {...props} />
  </SimpleWrapper>
);

export default enhance(LatestKnownLocationFormComponent);
