import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '400px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
};

// margin violation rule
export const marginViolationRuleFieldSettings = [
  {
    ...defaultStyles,
    type: 'multiselect',
    options: 'transportationModes',
    loc: 'titles:transportation-mode',
    fieldName: GC.FIELD_TRANSPORTATION_MODE_GUIDS,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:min-margin',
    fieldName: GC.FIELD_MIN_MARGIN,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:critical-margin',
    fieldName: GC.FIELD_CRITICAL_MARGIN,
  },
];

export const defaultMarginViolationRuleFields = {
  [GC.FIELD_MIN_MARGIN]: null,
  [GC.FIELD_CRITICAL_MARGIN]: null,
  [GC.FIELD_TRANSPORTATION_MODE_GUIDS]: [],
};

export const getMarginViolationRuleValidationSchema = (values: Object) => {
  const { minMargin, criticalMargin } = values;

  if (R.and(G.isNotNilAndNotEmpty(minMargin), G.isNotNilAndNotEmpty(criticalMargin))) {
    return {
      [GC.FIELD_TRANSPORTATION_MODE_GUIDS]: G.yupArrayRequired,
      [GC.FIELD_MIN_MARGIN]: Yup.number()
        .nullable(true)
        .notRequired()
        .positive(G.getShouldBePositiveLocaleTxt())
        .max(100, G.getShouldBeLessOrEqualLocaleTxt(100))
        .min(
          R.inc(criticalMargin),
          G.getShouldBeBiggerLocaleTxt(G.getWindowLocale('titles:critical-margin', 'Critical Margin')),
        ),
      [GC.FIELD_CRITICAL_MARGIN]: Yup.number()
        .nullable(true)
        .notRequired()
        .positive(G.getShouldBePositiveLocaleTxt())
        .max(R.dec(minMargin), G.getShouldBeLessLocaleTxt(G.getWindowLocale('titles:min-margin', 'Min Margin')),
      ),
    };
  } else if (R.and(G.isNilOrEmpty(minMargin), G.isNilOrEmpty(criticalMargin))) {
    const minOrCriticalRequired = G.getWindowLocale(
      'validation:add-min-or-critical-margin',
      'Please, add Min or Critical Margin',
    );

    return {
      [GC.FIELD_TRANSPORTATION_MODE_GUIDS]: G.yupArrayRequired,
      [GC.FIELD_MIN_MARGIN]: Yup.number()
        .nullable(true)
        .required(minOrCriticalRequired)
        .positive(G.getShouldBePositiveLocaleTxt()),
      [GC.FIELD_CRITICAL_MARGIN]: Yup.number()
        .nullable(true)
        .required(minOrCriticalRequired)
        .positive(G.getShouldBePositiveLocaleTxt()),
    };
  }

  return {
    [GC.FIELD_TRANSPORTATION_MODE_GUIDS]: G.yupArrayRequired,
    [GC.FIELD_MIN_MARGIN]: Yup.number()
      .nullable(true)
      .notRequired()
      .positive(G.getShouldBePositiveLocaleTxt())
      .max(100, G.getShouldBeLessOrEqualLocaleTxt(100)),
    [GC.FIELD_CRITICAL_MARGIN]: Yup.number()
      .nullable(true)
      .notRequired()
      .positive(G.getShouldBePositiveLocaleTxt())
      .max(100, G.getShouldBeLessOrEqualLocaleTxt(100)),
  };
};
