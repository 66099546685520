import React from 'react';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const getOptions = ({ handleAddStatusMessage, handleAddMultipleStatusMessage }: Object) => [
  {
    frontIcon: null,
    permissions: null,
    action: handleAddStatusMessage,
    text: G.getWindowLocale('titles:add-status-message', 'Add Status Message'),
  },
  {
    frontIcon: null,
    action: handleAddMultipleStatusMessage,
    permissions: [PC.TEL_STATUS_MESSAGE_WRITE],
    text: G.getWindowLocale('titles:add-multiple-status-message', 'Add Multiple Status Message'),
  },
];

export const AddStatusMessage = (props: Object) => <ActionsElement version={2} options={getOptions(props)} />;

export default AddStatusMessage;
