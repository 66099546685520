import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Label } from '../../../components/label';
import { openModal } from '../../../components/modal/actions';
import { ConfirmComponent } from '../../../components/confirm';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, ActionButton } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature configs
import { unlinkLosAccountRequest } from '../actions';
import { ConfigGroupRightSectionWrapper } from '../ui';
import { makeSelectConfigInitialValues } from '../selectors';
//////////////////////////////////////////////////

const LosLinkingCodeComponent = ({ code, expirationTimeInMinutes }: Objetc) => (
  <Box textAlign='center' color={G.getThemeColor('colors.textDark')}>
    {G.getWindowLocale('titles:code-for-los', 'Your code for LOS account linking')}:
    <Box my={15} fontSize={20} fontWeight={600} color={G.getThemeColor('colors.mainBlue')}>
      {code}
    </Box>
    <Box>
      {G.getWindowLocale('titles:expiration-time-mins', 'Expiration Time')}: {expirationTimeInMinutes}
    </Box>
  </Box>
);

const enhance = compose(
  withHandlers({
    handleUnlinkLosAccount: (props: Object) => () => {
      const { openModal, unlinkLosAccountRequest } = props;

      const confirmationContent = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'titles:unlink-los-account', 'Are you sure you want to unlink your LOS account?',
          )}
        />
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: unlinkLosAccountRequest,
              name: G.getWindowLocale('actions:unlink', 'Unlink'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleGenerateLosLinkingCode: (props: Object) => async () => {
      const { openModal, openLoader, closeLoader } = props;

      openLoader();

      const res = await sendRequest('post', endpointsMap.losSubscriptionGenerateLosLinkingCode);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const component = <LosLinkingCodeComponent {...data} />;

        const modal = {
          p: 15,
          component,
          options: {
            width: 300,
            height: 'auto',
            movable: false,
            outsideCloseButton: true,
            title: G.getWindowLocale('titles:los-linking-code', 'LOS Linking Code'),
          },
        };

        openModal(modal);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  pure,
);

const renderLinkLabel = (linked: boolean) => {
  const icon = G.ifElse(G.isTrue(linked), I.uiTrue, I.uiFalse)();

  const text = G.getWindowLocale(...G.ifElse(
    G.isTrue(linked),
    ['titles:linked', 'Linked'],
    ['titles:not-linked', 'Not Linked'],
  ));

  return (
    <Label frontIcon={icon}>{text}</Label>
  );
};

const LinkToLosComponent = ({ configInitialValues, handleUnlinkLosAccount, handleGenerateLosLinkingCode }: Object) => {
  const linked = R.pathOr(false, [GC.INTEGRATION_LOS_ACCOUNT_LINKED], configInitialValues);

  const title = G.getWindowLocale(...G.ifElse(
    G.isTrue(linked),
    ['actions:unlink', 'Unlink'],
    ['actions:generate-code', 'Generate Code'],
  ));

  const action = G.ifElse(G.isTrue(linked), handleUnlinkLosAccount, handleGenerateLosLinkingCode);

  return (
    <ConfigGroupRightSectionWrapper mr={10}>
      <ActionButton
        mr={20}
        width={120}
        height={30}
        type='button'
        fontSize={14}
        onClick={action}
      >
        {title}
      </ActionButton>
      {renderLinkLabel(linked)}
    </ConfigGroupRightSectionWrapper>
  );
};

const mapStateToProps = (state: Object) => ({
  configInitialValues: makeSelectConfigInitialValues(state, GC.INTEGRATION_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  openLoader,
  closeLoader,
  unlinkLosAccountRequest,
})(enhance(LinkToLosComponent));

