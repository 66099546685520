import * as R from 'ramda';
import React, { useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { ReactSelect } from '../../../ui';
// feature new-do
import { InputWrapper } from '../../new-do/forms/formik/input-wrapper';
//////////////////////////////////////////////////

const BranchSearch = (props: Object) => {
  const { value, options, selectHandler } = props;

  const onChangeHandler = useCallback((option: Object) => {
    selectHandler(R.prop('value', option));
  }, [selectHandler]);

  return (
    <InputWrapper
      inputWrapperStyles={{ zIndex: 16 }}
      label={G.getWindowLocale('titles:select-customer', 'Select Customer')}
    >
      <ReactSelect
        height={32}
        width={175}
        name='branch'
        value={value}
        options={options}
        isClearable={true}
        onChange={onChangeHandler}
      />
    </InputWrapper>
  );
};

export default BranchSearch;
