import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderComponent } from 'react-recompose';
// common
import { makeSelectInitialSplashScreen } from '../../common/selectors';
// components
import LoaderComponent from '../../components/loader';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
//ui
import { Box, Flex } from '../../ui';
// feature auth
import { makeSelectAuthData } from './selectors';
import stylingPanelHoc from './styling-panel-hoc';
import { FormLogo, FormWrapper, FormContainer, StyledFormTitle } from './ui';
//////////////////////////////////////////////////

const NewPasswordMobileSuccessPage = (props: Object) => {
  const { auth, splashScreenSetting } = props;

  if (R.path(['loggedIn'], auth)) return <Redirect to='/' />;

  return (
    <FormWrapper bs='100%'>
      <FormContainer width='100%'>
        <FormLogo mb={60} height={350}>
          <img
            alt='Logo'
            width={350}
            height={350}
            src={R.or(R.path(['logoUrl'], splashScreenSetting), G.getNewLogoLink())}
          />
        </FormLogo>
        <StyledFormTitle>New Password Created Successfully</StyledFormTitle>
        <Flex mt={25} fontSize={36} color={G.getTheme('colors.greyMatterhorn')}>
          Go Back To Your Amous Driver Application To Login
          <Box mr={10} ml='5px' transform='rotate(270deg)'>{I.arrowDown()}</Box>
        </Flex>
      </FormContainer>
    </FormWrapper>
  );
};

const enhance = compose(
  stylingPanelHoc,
  branch(
    ({ splashScreenSetting }: Object) => R.not(R.path(['isLoaded'], splashScreenSetting)),
    renderComponent(LoaderComponent),
  ),
  pure,
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  auth: makeSelectAuthData(state),
  splashScreenSetting: makeSelectInitialSplashScreen(state),
}));

export default connect(mapStateToProps)(enhance(NewPasswordMobileSuccessPage));
