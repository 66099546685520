import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// features
import { receivedLogOutSuccess } from '../auth/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature branch
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  salePersons: [],
  brokerAgents: [],
  treeBranches: {},
  currentBranch: {},
  importMappers: [],
  masterSettings: {},
  branchesConfigs: {},
  currentBranchConfigs: null,
  additionalLocationList: [],
  editBranchDocumentList: null,
  editBranchBillToLocation: null,
  editBranchPrimaryLocation: null,
  branch: {
    CompanyDetails: {},
    BranchSetting: {},
  },
  [GC.UI_FIELD_REFERENCES]: [],
  collapsedGroup: {
    editDocuments: true,
    editBranchSetting: true,
    editBillToLocation: true,
    editCompanyDetails: true,
    createBranchSetting: true,
    additionalLocations: true,
    createCompanyDetails: true,
  },
};

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const receivedBranchSuccess = (state: Object, ent: Object) => (
  P.$set('branch.CompanyDetails', ent, state)
);

const receivedBranchSettingSuccess = (state: Object, data: Object) => (
  P.$set('branch.BranchSetting', data, state)
);

const resetBranchSettingAndCompanyDetails = (state: Object) => (
  P.$all(
    P.$set('branch.BranchSetting', {}),
    P.$set('branch.CompanyDetails', {}),
    state,
  )
);

const receivedBranchMasterSettingsSuccess = (state: Object, data: Object) => (
  P.$set('masterSettings', data, state)
);

const receivedBranchesTreeSuccess = (state: Object, branches: Array) => (
  P.$set('treeBranches', branches, state)
);

const receivedNewBranchSuccess = (state: Object, branch: Object) => {
  return (
    P.$set('itemList', { [G.getGuidFromObject(branch)]: branch }, state)
  );
};

const receivedUpdateBranchSuccess = (state: Object, updatedBranch: Object) => (
  P.$set(`itemList.${G.getGuidFromObject(updatedBranch)}`, updatedBranch, state)
);

const receivedSwitchBranchSuccess = (state: Object, data: Object) => {
  G.setItemToWindow('amousCurrentBranch', data);
  return P.$set('currentBranch', data, state);
};

const collapseBranchFormGroup = (state: Object, fieldName: string) => (
  P.$toggle(`collapsedGroup.${fieldName}`, state)
);

const receivedActivateSuccess = (state: Object, guid: string) => (
  P.$set(`itemList.${guid}.active`, true, state)
);

const receivedDeactivateSuccess = (state: Object, guid: string) => (
  P.$set(`itemList.${guid}.active`, false, state)
);

const receivedSuspendSuccess = (state: Object, guid: string) => (
  P.$set(`itemList.${guid}.suspended`, true, state)
);

const receivedUnsuspendSuccess = (state: Object, guid: string) => (
  P.$set(`itemList.${guid}.suspended`, false, state)
);

const receivedBranchRefSuccess = (state: Object, refs: Object) => (
  P.$set(GC.UI_FIELD_REFERENCES, { ...refs }, state)
);

const createBranchRefSuccess = (state: Object, ref: Object) => (
  P.$add(GC.UI_FIELD_REFERENCES, { [G.getGuidFromObject(ref)]: ref }, state)
);

const updateBranchRefSuccess = (state: Object, ref: Object) => (
  P.$set(`${GC.UI_FIELD_REFERENCES}.${G.getGuidFromObject(ref)}`, ref, state)
);

const deleteBranchRefSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.UI_FIELD_REFERENCES}.${guid}`, state)
);

const handleReceivedLogOutSuccess = () => initialState;

const receivedBranchBillToLocationSuccess = (state: Object, data: Object) => (
  P.$set('editBranchBillToLocation', data, state)
);

const receivedBranchPrimaryLocationSuccess = (state: Object, data: Object) => (
  P.$set('editBranchPrimaryLocation', data, state)
);

const getConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('currentBranchConfigs', data, state)
);

const getDocumentListSuccess = (state: Object, data: Array) => (
  P.$set('editBranchDocumentList', data, state)
);

const getBranchConfigsByNamesSuccess = (state: Object, { configs, branchGuid }: Object) => {
  const currentConfigs = R.pathOr({}, ['branchesConfigs', branchGuid], state);
  return (
    P.$set(`branchesConfigs.${branchGuid}`, R.mergeRight(currentConfigs, configs), state)
  );
};

const receivedBranchImportMappersSuccess = (state: Object, data: Object) => (
  P.$set(
    'importMappers',
    R.map(({ mapper }: Object) => ({
      [GC.FIELD_VALUE]: mapper,
      [GC.FIELD_LABEL]: G.getEnumLocale(mapper),
    }), data),
    state,
  )
);

const receivedBranchAdditionalLocationListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set('additionalLocationList', [], state);

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: Object) => {
      const { contacts } = item;

      if (G.isNilOrEmpty(contacts)) return R.assoc('index', index, item);

      const fax = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_FAX);
      const phone = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_PHONE);
      const email = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_EMAIL);
      const contactName = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_CONTACT_NAME);
      const phoneExtension = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_PHONE_EXTENSION);

      return R.mergeRight(item, { fax, index, phone, email, contactName, phoneExtension });
    }),
  )(data);

  return P.$set('additionalLocationList', list, state);
};

const receivedBranchAdditionalLocationSuccess = (state: Object, data: Object) => {
  const { additionalLocationList } = state;
  const { guid, contacts } = data;

  const index = R.pathOr(R.length(additionalLocationList), [guid, 'index'], additionalLocationList);
  let additionalLocation = R.assoc('index', index, data);

  if (G.isNotNilAndNotEmpty(contacts)) {
    const fax = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_FAX);
    const phone = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_PHONE);
    const email = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_EMAIL);
    const contactName = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_CONTACT_NAME);
    const phoneExtension = G.transformArrayOfObjectsToStringByPropName(contacts, ', ', GC.FIELD_PHONE_EXTENSION);

    additionalLocation = R.mergeRight(additionalLocation, { fax, phone, email, contactName, phoneExtension });
  }

  const newList = R.assoc(guid, additionalLocation, additionalLocationList);

  return P.$set('additionalLocationList', newList, state);
};

const deleteBranchAdditionalLocationSuccess = (state: Object, guid: string) => (
  P.$drop(`additionalLocationList.${guid}`, state)
);

const getAvailableCommissionAssignmentListSuccess = (state: Object, data: Array) => {
  const commissionAssigneeByType = R.compose(
    R.groupBy(R.prop(GC.FIELD_TYPE)),
    R.map(({ name, guid, type }: Object) => ({
      type,
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: name,
    })),
  )(R.or(data, []));

  return P.$set('commissionAssigneeByType', commissionAssigneeByType, state);
};

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
    // report common
  [A.receivedBranchSuccess]: receivedBranchSuccess,
  [A.createBranchRefSuccess]: createBranchRefSuccess,
  [A.updateBranchRefSuccess]: updateBranchRefSuccess,
  [A.deleteBranchRefSuccess]: deleteBranchRefSuccess,
  [A.receivedSuspendSuccess]: receivedSuspendSuccess,
  [A.getDocumentListSuccess]: getDocumentListSuccess,
  [A.receivedActivateSuccess]: receivedActivateSuccess,
  [A.collapseBranchFormGroup]: collapseBranchFormGroup,
  [receivedLogOutSuccess]: handleReceivedLogOutSuccess,
  [A.receivedBranchRefSuccess]: receivedBranchRefSuccess,
  [A.receivedNewBranchSuccess]: receivedNewBranchSuccess,
  [A.receivedUnsuspendSuccess]: receivedUnsuspendSuccess,
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [A.receivedDeactivateSuccess]: receivedDeactivateSuccess,
  [A.receivedBranchesTreeSuccess]: receivedBranchesTreeSuccess,
  [A.receivedUpdateBranchSuccess]: receivedUpdateBranchSuccess,
  [A.receivedSwitchBranchSuccess]: receivedSwitchBranchSuccess,
  [A.receivedBranchSettingSuccess]: receivedBranchSettingSuccess,
  [A.getBranchConfigsByNamesSuccess]: getBranchConfigsByNamesSuccess,
  [A.receivedBranchImportMappersSuccess]: receivedBranchImportMappersSuccess,
  [A.receivedBranchMasterSettingsSuccess]: receivedBranchMasterSettingsSuccess,
  [A.resetBranchSettingAndCompanyDetails]: resetBranchSettingAndCompanyDetails,
  [A.receivedBranchBillToLocationSuccess]: receivedBranchBillToLocationSuccess,
  [A.receivedBranchPrimaryLocationSuccess]: receivedBranchPrimaryLocationSuccess,
  [A.deleteBranchAdditionalLocationSuccess]: deleteBranchAdditionalLocationSuccess,
  [A.receivedBranchAdditionalLocationSuccess]: receivedBranchAdditionalLocationSuccess,
  [A.getAvailableCommissionAssignmentListSuccess]: getAvailableCommissionAssignmentListSuccess,
  [A.receivedBranchAdditionalLocationListSuccess]: receivedBranchAdditionalLocationListSuccess,
}, initialState);
