import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DragDropContext } from 'react-beautiful-dnd';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withIsOpenedModalStatus, withAsyncGetTrailersInService } from '../../../hocs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-planner
import Dimmer from './dimmer';
import RightPanel from './right-panel';
import RouteSummary from './route-summary';
import { PLANNER_TEL_MODES } from '../constants';
import Load, { LoadWithAsyncData } from './load';
import {
  setPlannerMode,
  recalculateTelDistancesRequest,
  recalculateTelDistancesWithMoveStopRequest,
} from '../actions';
import {
  makeSelectRouteErrors,
  makeSelectPlannerMode,
  makeSelectCurrentRoute,
  makeSelectOpenedFromPage,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncGetTrailersInService(),
  withIsOpenedModalStatus,
  withState('expanded', 'toggle', true),
  withHandlers({
    handleDragEnd: ({
      currentRoute,
      recalculateTelDistancesWithMoveStopRequest,
    }: Object) => (result: Object) => {
      const { source, destination, draggableId } = result;

      if (R.not(destination)) return;

      const eventGuid = R.compose(
        R.last,
        R.split('_'),
      )(draggableId);

      const guids = R.compose(
        R.map((item: Object) => R.path([GC.FIELD_LOAD_STOPS, eventGuid, 'guid'], item)),
        R.filter((item: Object) => R.pathEq(
          GC.STOP_TYPE_INDEPENDENT,
          [GC.FIELD_LOAD_STOPS, eventGuid, 'stopType'],
          item,
        )),
        R.values,
      )(currentRoute.tels);

      if (R.and(
        R.includes(eventGuid, guids),
        G.notEquals(source.droppableId, destination.droppableId),
      )) return;

      recalculateTelDistancesWithMoveStopRequest(result);
    },
    handleToggleTels: ({ mode, setPlannerMode }: Object) => () => {
      const newMode = G.ifElse(
        G.notEquals(mode, PLANNER_TEL_MODES.ALL_EXPANDED),
        PLANNER_TEL_MODES.ALL_EXPANDED,
        PLANNER_TEL_MODES.ALL_COLLAPSED,
      );
      setPlannerMode(newMode);
    },
  }),
  pure,
);

const LoadComponent = (props: Object) => {
  const { tel, currentBranch } = props;

  const guid = G.getGuidFromObject(tel);

  if (R.or(R.isNil(guid), R.path(['isNew'], tel))) {
    return (
      <Load {...props} />
    );
  }

  return (
    <LoadWithAsyncData
      {...props}
      asyncEndpoint={endpointsMap.telDocumentList}
      asyncOptions={{
        params: {
          [GC.BRANCH_GUID]: currentBranch,
          [GC.FIELD_PRIMARY_OBJECT_GUID]: guid,
        },
      }}
    />
  );
};

const PlannerWithDnD = (props: Object) => {
  const {
    mode,
    errors,
    settings,
    maxHeight,
    currentRoute,
    handleDragEnd,
    itemsExpanded,
    trailersInService,
    trailersInServiceOptions,
  } = props;

  const tels = R.compose(
    R.sortBy(R.prop(GC.FIELD_ORDER)),
    R.values,
    R.path([GC.SYSTEM_LIST_TELS]),
  )(currentRoute);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box
        flexGrow={1}
        overflow='auto'
        maxHeight={maxHeight}
      >
        {
          G.isNotNilAndNotEmpty(tels) &&
          tels.map((tel: Object, index: number) => (
            <LoadComponent
              tel={tel}
              key={index}
              mode={mode}
              itemsExpanded={itemsExpanded}
              trailersInService={trailersInService}
              trailersInServiceOptions={trailersInServiceOptions}
              eventsCount={R.length(R.values(tel[GC.FIELD_LOAD_STOPS]))}
              withError={R.pathOr(false, [G.getGuidFromObject(tel)], errors)}
              withoutHeaderActions={R.path(['withoutHeaderActions'], settings)}
            />
          ))
        }
      </Box>
    </DragDropContext>
  );
};

const Content = (props: Object) => {
  if (R.and(G.isNilOrEmpty(props.currentRoute), R.path(['settings', 'onlyClo'], props))) {
    return (
      <Box
        mt={10}
        overflow='auto'
        maxHeight={props.maxHeight}
        width={R.or(R.path(['settings', 'builderMaxWidth'], props), props.loadsContentMaxWidth)}
      >
        <Dimmer count={6} withHeader={true} />
      </Box>
    );
  }

  if (G.isNilOrEmpty(props.currentRoute)) {
    return (
      <Flex height={50} fontSize={18} justifyContent='center'>
        {G.getWindowLocale('titles:select-route-to-build', 'Please, select a Route to build!')}
      </Flex>
    );
  }

  return (
    <Flex
      width='100%'
      height='100%'
      border='1px solid'
      alignItems='stretch'
      justifyContent='flex-start'
      borderColor={G.getTheme('tables.rows.borderColor')}
    >
      <Flex maxWidth='100%' width='max-content'>
        {
          R.and(G.isNilOrEmpty(props.currentRoute.tels), props.settings.withoutSummary)
          && <Dimmer count={6} withHeader={true} />
        }
        {
          R.not(props.settings.withoutSummary) && <RouteSummary openedFromPage={props.openedFromPage} />
        }
      </Flex>
      <PlannerWithDnD {...props} />
      {
        R.not(props.settings.withoutRightBar) &&
        <RightPanel
          mode={props.mode}
          handleToggleTels={props.handleToggleTels}
        />
      }
    </Flex>
  );
};

const Planner = enhance((props: Object) => (
  <Box width='100%' height='calc(100% - 20px)' overflow={G.ifElse(props.collapsed, 'hidden')}>
    <Content {...props} />
  </Box>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  mode: makeSelectPlannerMode(state),
  errors: makeSelectRouteErrors(state),
  currentRoute: makeSelectCurrentRoute(state),
  openedFromPage: makeSelectOpenedFromPage(state),
  currentBranch: makeSelectCurrentBranchGuid(state),
});

export default connect(mapStateToProps, {
  setPlannerMode,
  recalculateTelDistancesRequest,
  recalculateTelDistancesWithMoveStopRequest,
})(Planner);
