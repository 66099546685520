import React from 'react';
// features
import { withAddFuelTransaction } from '../../fuel-cards';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { MainActionButton } from '../../../ui';
//////////////////////////////////////////////////

const AddFuelTransaction = ({ handleAddFuelCard }: Object) => (
  <MainActionButton ml={20} height={30} width={160} onClick={() => handleAddFuelCard()}>
    {G.getWindowLocale('titles:add-fuel-transaction', 'Add Fuel Transaction')}
  </MainActionButton>
);

export default withAddFuelTransaction(AddFuelTransaction);
