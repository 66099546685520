import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
// components
import { FormFooter2 } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withReferenceTypes } from '../../../hocs';
// forms
import { Fieldset2 } from '../../../forms';
//////////////////////////////////////////////////

const validationSchema = Yup.object().shape({
  [GC.FIELD_VALUE]: G.yupStringRequired,
  [GC.FIELD_REFERENCE_TYPE_GUID]: G.yupStringRequired,
});

const inputWrapperStyles = { mb: 25, width: 400 };

const fieldSettings = [
  {
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_VALUE,
    label: ['titles:tel-primary-reference-value'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'refTypeOptions',
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    label: ['titles:tel-primary-reference-type'],
  },
];

const LoadInfo = (props: Object) => {
  const {
    scopeName,
    closeModal,
    branchGuid,
    parentState,
    createLoadRequest,
    getReferenceTypesByScopeName,
  } = props;

  useEffect(() => {
    getReferenceTypesByScopeName(scopeName, branchGuid);
  }, []);

  const refTypeOptions = G.mapNameGuidToLabelValue(R.propOr([], 'referenceTypes', parentState));

  const onSubmit = (values: Object) => {
    closeModal();
    createLoadRequest({ primaryReference: values });
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { [GC.FIELD_VALUE]: null, [GC.FIELD_REFERENCE_TYPE_GUID]: null },
  });

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        fields={fieldSettings}
        refTypeOptions={refTypeOptions}
        fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
      />
      <FormFooter2 submitBtnText={G.getWindowLocale('actions:select', 'Select')} />
    </form>
  );
};

export default withReferenceTypes(LoadInfo);
