import React from 'react';
// components
import { TextBtn1 } from '../../../components/text-btns';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const TextBtn1Component = () => {
  const description = '<TextBtn1 /> component generates a styled button using the TextComponent as its base for text rendering.';

  const usage = (
    <ul>
      <li>Parameter `<i>textColor</i>` sets the text color for the button.</li>
      <li>Parameter `<i>bgColor</i>` sets the background color for the button.</li>
      <li>Parameter `<i>borderColor</i>` sets the border color for the button.</li>
      <li>Parameter `<i>text</i>` is the text content displayed within the button.</li>
      <li>Parameter `<i>styles</i>` is an additional styles to be applied to the button.</li>
      <li>Parameter `<i>onClickHandler</i>` set a function handler for the button`s click event.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='TextBtn1'
      description={description}
      path='src/components/text-btns'
    >
      <TextBtn1
        text='show alert window'
        onClickHandler={() => { window.alert('Lorem lorem'); }}
      />
    </ComponentStoryWrapper>
  );
};

export default TextBtn1Component;
