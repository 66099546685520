import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withActiveStatus } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, AbsoluteBox, FlexHovered } from '../../../ui';
// features intl
import { DEFAULT_TAB } from '../constants';
import { changeStyleOnHover } from '../helpers';
import {
  makeSelectIntl,
  makeSelectTabInfo,
  makeSelectActiveTab } from '../selectors';
//////////////////////////////////////////////////

const ArrowIcon = (props: Object) => (
  <Box mr='10px'>
    {
      G.ifElse(
        G.isTrue(props.isActiveStatus),
        I.arrowDownSimple(G.getTheme('colors.light.black')),
        I.arrowUpSimple(G.getTheme('colors.light.black')),
      )
    }
  </Box>
);

const SubMenu = withActiveStatus((props: Object) => (
  <Box width='100%'>
    <FlexHovered
      pl='16px'
      width='100%'
      height='40px'
      key={props.key}
      cursor='pointer'
      minHeight='40px'
      alignItems='center'
      fontWeightHover='bold'
      justifyContent='space-between'
      bgColor={G.getTheme('colors.dark.lightGrey')}
      onClick={G.notEquals(props.tab.pattern, props.activeTab) && props.handleToggleActiveState}
      display={G.ifElse(
        R.and(
          R.equals(props.pageTab.type, DEFAULT_TAB),
          props.tab.isDefaultView),
        'none',
        'flex',
      )}
      border={changeStyleOnHover(
        props.tab.pattern,
        props.activeTab,
        G.getTheme('colors.light.darkGrey'),
        '#f3f3f3',
      )}
      bg={changeStyleOnHover(
        props.tab.pattern,
        props.activeTab,
        G.getTheme('colors.dark.lightGrey'),
        '#f3f3f3',
      )}
      fontWeight={changeStyleOnHover(
        props.tab.pattern,
        props.activeTab,
        'bold',
        'normal',
      )}
    >
      {G.getWindowLocale(R.prop('titleLocaleKey', props.tab), props.tab.pattern)}
      <ArrowIcon {...props} />
    </FlexHovered>
    {
      props.tab.subMenu.map(
        (subTab: Object, index: number) => (
          <FlexHovered
            pl='25px'
            key={index}
            width='100%'
            bg='#d8d8d8'
            height='40px'
            cursor='pointer'
            minHeight='40px'
            alignItems='center'
            fontWeightHover='bold'
            justifyContent='space-between'
            border={G.getTheme('colors.light.darkGrey')}
            bgColor={G.getTheme('colors.dark.lightGrey')}
            onClick={() => props.handleClickTitle(subTab.pattern)}
            display={changeStyleOnHover(
              props.isActiveStatus,
              true,
              'none',
              'flex',
            )}
            fontWeight={changeStyleOnHover(
              subTab.pattern,
              props.activeTab,
              'bold',
              'normal',
            )}
          >
            {G.getWindowLocale(R.prop('titleLocaleKey', subTab), subTab.pattern)}
          </FlexHovered>
        ),
      )
    }
  </Box>
));

const DropdownComponent = (props: Object) => {
  const { tab, activeTab} = props;
  if (R.has('subMenu', tab)) {
    return (
      <SubMenu {...props} tab={tab} />
    );
  }
  return (
    <FlexHovered
      pl='16px'
      width='100%'
      height='40px'
      cursor='pointer'
      minHeight='40px'
      alignItems='center'
      fontWeightHover='bold'
      justifyContent='space-between'
      bgColor={G.getTheme('colors.dark.lightGrey')}
      onClick={() => props.handleClickTitle(tab.pattern)}
      border={changeStyleOnHover(
        tab.pattern,
        props.activeTab,
        G.getTheme('colors.light.darkGrey'),
        '#f3f3f3',
      )}
      bg={changeStyleOnHover(
        tab.pattern,
        activeTab,
        G.getTheme('colors.dark.lightGrey'),
        '#f3f3f3',
      )}
      fontWeight={changeStyleOnHover(
        tab.pattern,
        props.activeTab,
        'bold',
        'normal',
      )}
    >
      {G.getWindowLocale(R.prop('titleLocaleKey', tab), tab.pattern)}
    </FlexHovered>
  );
};

const TabBar = (props: Object) => (
  <Box display='flex'>
    <AbsoluteBox
      bg='#f3f3f3'
      width='320px'
      overflowY='scroll'
      flexDirection='column'
      transition='left .4 linear'
      height='calc(100vh - 105px)'
    >
      {
        props.tabs.map((tab: Object, index: number) => (
          <DropdownComponent {...props} tab={tab} key={index} />
        ))
      }
    </AbsoluteBox>
  </Box>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  tabs: makeSelectTabInfo(state),
  intlSettings: makeSelectIntl(state),
  activeTab: makeSelectActiveTab(state),
});

export default connect(mapStateToProps, {})(TabBar);
