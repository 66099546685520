export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  allowSelectAll: true,
  expandableItems: false,
  searchableTitles: true,
  maxHeight: 'calc(100vh - 145px)',
};

export const cloTelTableSettings = {
  ...tableSettings,
  checkBoxCellWidth: 70,
  checkBoxCellJustifyContent: 'unset',
};

export const telEventsTableSettings = {
  ...tableSettings,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
};
