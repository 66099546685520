import * as R from 'ramda';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// feature dispatch-board-new
import { getItemListGuids } from '../helpers';
/////////////////////////////////////////////////

export const withSelectLoads = compose(
  withState('selectedList', 'setSelectedList', []),
  withHandlers({
    handleSelectLoad: (props: Object) => (guid: string) => {
      const { selectedList, setSelectedList } = props;

      const newList = G.ifElse(
        R.includes(guid, selectedList),
        R.without(R.of(Array, guid), selectedList),
        R.append(guid, selectedList),
      );

      setSelectedList(newList);
    },
    handleSelectAll: (props: Object) => (allChecked: boolean) => {
      const { itemList, setSelectedList } = props;

      if (allChecked) return setSelectedList([]);

      const selectedList = getItemListGuids(itemList);

      setSelectedList(selectedList);
    },
  }),
);
