import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// features
import ItemForm from '../../../item/components/item-form';
// helpers/constants
import * as G from '../../../../helpers';
// feature dispatch details new
import { updateOrderItemRequest } from '../actions';
//////////////////////////////////////////////////

export const withItemsTableGroupRowActions = compose(
  connect(
    null, { updateOrderItemRequest },
  ),
  withHandlers({
    handleUpdateItem: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        updateOrderItemRequest,
      } = props;

      const componentProps = {
        openModal,
        closeModal,
        initialValues: entity,
        submitAction: updateOrderItemRequest,
      };

      const component = <ItemForm {...componentProps} />;

      const modal = {
        p: '0',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getEditTitle(['titles:item', 'Item']),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
