import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { FormGroupTable } from '../../../components/form-group-table';
// ui
import { Box } from '../../../ui';
// feature components-story
import { setToggleFormGroup } from '../actions';
import ComponentStoryWrapper from '../component-story-wrapper';
import { makeSelectUserList, makeSelectCollapsedGroup, makeSelectUserColumnSettings } from '../selectors';
//////////////////////////////////////////////////

const USERS = 'users';

const FormGroupTableComponent = (props: Object) => {
  const {
    userList,
    collapsedGroup,
    setToggleFormGroup,
    userColumnSettings,
  } = props;

  const description = '<FormGroupTable /> component renders a structured form group presentation within a table layout.';

  const usage = (
    <ul>
      <li>Parameter `<i>panelTitle</i>` is a title for the form group table.</li>
      <li>Parameter `<i>groupName</i>` indicates the group name associated with table.</li>
      <li>Parameter `<i>tableColumnWidth</i>` specifies the width of the table columns.</li>
      <li>Parameter `<i>notEditable</i>` specifies whether the table entities are not editable.</li>
      <li>Parameter `<i>notDeletable</i>` indicates that entities in the table are not deletable.</li>
      <li>Parameter `<i>entities</i>` contains the list of entities or items to be displayed in the table.</li>
      <li>Parameter `<i>handleAddClick</i>` is a function that is triggered when the add button is clicked.</li>
      <li>Parameter `<i>handlePreviewDocument</i>` is a function that is triggered when previewing a document.</li>
      <li>Parameter `<i>isOpened</i>` determines whether this specific group of the table is open or collapsed.</li>
      <li>Parameter `<i>entitiesFields</i>` contains settings or configurations for the columns/fields of the table.</li>
      <li>Parameter `<i>fields</i>` refers to some settings or configurations for the fields displayed in the table.</li>
      <li>Parameter `<i>handleEditRow</i>`  is a function that is triggered when editing a row/document within the table.</li>
      <li>Parameter `<i>handleDeleteRow</i>`  is a function that is triggered when deleting a row/document within the table.</li>
      <li>Parameter `<i>additionalColumnActions</i>` contains additional actions/buttons/icons to be displayed as columns in the table.</li>
      <li>Parameter `<i>handleToggleFormGroup</i>` is a function that is triggered when toggling the form group panel, specifically used to set the toggle state for the group.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='FormGroupTable'
      description={description}
      path='src/components/form-group-table'
    >
      <Box width={500}>
        <FormGroupTable
          groupName={USERS}
          notEditable={true}
          panelTitle={USERS}
          entities={userList}
          notDeletable={true}
          fields={collapsedGroup}
          entitiesFields={userColumnSettings}
          isOpened={R.path([USERS], collapsedGroup)}
          handleToggleFormGroup={() => setToggleFormGroup(USERS)}
          handleAddClick={() => window.alert('function handleAddClick starts')}
        />
      </Box>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  userList: makeSelectUserList(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  userColumnSettings: makeSelectUserColumnSettings(state),
});

export default connect(mapStateToProps, {
  setToggleFormGroup,
})(FormGroupTableComponent);
