import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderNothing } from 'react-recompose';
// common
import { createLoadDocumentsRequest } from '../../../../common/actions';
// components
import { LoadStatusActions } from '../../../../components/load-status-actions';
// features
import { setInitialRouteGuid } from '../../../dispatch-planner/actions';
import { setInitialPlannerData } from '../../../dispatch-planner-events/actions';
import { makeSelectAvailableDocumentTypes } from '../../../configurations/selectors';
import { withOrderActionsWithoutConnect } from '../../../dispatch-board-new/hocs/with-order-actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex } from '../../../../ui';
// feature dispatch-details-new
import { makeSelectRouteTelConfigs } from '../selectors';
import { withAddCustomerInvoice, withLoadCustomerRateTableGroupRowActions } from '../hocs';
import {
  cancelCLORequest,
  unquoteCLORequest,
  createTelFromCloRequest,
  changeCancelStatusRequest,
  getOrderDocumentListRequest,
  createOrderCustomerInvoiceRequest,
} from '../actions';
//////////////////////////////////////////////////

const defaultCloActionPanel = [
  {
    status: GC.LOAD_STATUS_QUOTE,
    secondRow: [GC.CANCEL_CLO_ACTION, GC.UNQUOTE_CLO_ACTION],
    firstRow: [GC.EDIT_CLO_RATE_ACTION, GC.DUPLICATE_ORDER_ACTION],
  },
  {
    status: GC.LOAD_STATUS_UNSCHEDULED,
    secondRow: [GC.CANCEL_CLO_ACTION, GC.OPEN_IN_ROUTE_BUILDER_ACTION],
    firstRow: [GC.CREATE_TEL_ACTION, GC.EDIT_CLO_RATE_ACTION, GC.DUPLICATE_ORDER_ACTION],
  },
  {
    status: GC.LOAD_STATUS_PLANNED,
    firstRow: [GC.CANCEL_CLO_ACTION, GC.DUPLICATE_ORDER_ACTION],
    secondRow: [GC.EDIT_CLO_RATE_ACTION, GC.OPEN_IN_ROUTE_BUILDER_ACTION],
  },
  {
    status: GC.LOAD_STATUS_BOOKED_STATUS,
    secondRow: [GC.SEND_CLO_TO_EXTERNAL_SYSTEM_ACTION],
    firstRow: [GC.CANCEL_CLO_ACTION, GC.DUPLICATE_ORDER_ACTION, GC.OPEN_IN_ROUTE_BUILDER_ACTION],
  },
  {
    status: GC.LOAD_STATUS_IN_TRANSIT,
    firstRow: [GC.ADD_DOCUMENT_ACTION, GC.CREATE_CUSTOMER_INVOICE_ACTION],
    secondRow: [GC.DUPLICATE_ORDER_ACTION, GC.OPEN_IN_ROUTE_BUILDER_ACTION, GC.SEND_CLO_TO_EXTERNAL_SYSTEM_ACTION],
  },
  {
    status: GC.LOAD_STATUS_DELIVERED,
    firstRow: [GC.ADD_DOCUMENT_ACTION, GC.CREATE_CUSTOMER_INVOICE_ACTION],
    secondRow: [GC.DUPLICATE_ORDER_ACTION, GC.OPEN_IN_ROUTE_BUILDER_ACTION, GC.SEND_CLO_TO_EXTERNAL_SYSTEM_ACTION],
  },
  {
    status: GC.LOAD_STATUS_CANCELED,
    firstRow: [GC.RESTORE_CLO_ACTION, GC.DUPLICATE_ORDER_ACTION],
  },
];

const enhance = compose(
  withAddCustomerInvoice,
  withLoadCustomerRateTableGroupRowActions,
  withOrderActionsWithoutConnect,
  branch(
    (props: Object) => G.isNilOrEmpty(props.load),
    renderNothing,
  ),
  pure,
);

const ActionsCenter = (props: Object) => {
  const { load, handleActionClick } = props;

  const data = R.compose(
    R.pathOr(null, [R.path([GC.FIELD_STATUS], load)]),
    R.indexBy(R.prop(GC.FIELD_STATUS)),
  )(defaultCloActionPanel);

  return (
    <Flex p='8px 25px' overflowX='auto'>
      <Box
        mr={16}
        py='8px'
        fontWeight='bold'
        textTransform='uppercase'
        color={G.getTheme('colors.greyMatterhorn')}
      >
        {G.getWindowLocale('titles:action-center', 'Action Center')}
      </Box>
      <LoadStatusActions
        data={data}
        load={load}
        fromDetails={true}
        loadType={GC.LOAD_TYPE_CLO}
        handleActionClick={(actionName: string) => handleActionClick(actionName, load)}
      />
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  routeTelConfigs: makeSelectRouteTelConfigs(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes(state),
});

export default connect(mapStateToProps, {
  cancelCLORequest,
  unquoteCLORequest,
  setInitialRouteGuid,
  setInitialPlannerData,
  createTelFromCloRequest,
  changeCancelStatusRequest,
  createLoadDocumentsRequest,
  getOrderDocumentListRequest,
  createOrderCustomerInvoiceRequest,
})(enhance(ActionsCenter));
