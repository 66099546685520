import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withProps,
  withHandlers } from 'react-recompose';
// components
import { LocalLoader } from '../../../components/local-loader';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as GC from '../../../constants';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../hocs';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature branch
import BranchTree from './branch-tree';
import { shareBranch } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withProps(({ branchGuid }: Object) => ({
    asyncEndpoint: endpointsMap.getBranchSharedDivisions(branchGuid),
  })),
  withAsyncInitialDataOnDidMount,
  withHandlers({
    handleShareBranch: ({ branchGuid, shareBranch }: Object) => (divisionGuids: Array) => (
      shareBranch({ divisionGuids, customerGuid: branchGuid })
    ),
  }),
  pure,
);

const ShareWithDivisions = (props: Object) => {
  const { asyncInitialData, handleShareBranch } = props;
  return (
    <LocalLoader localLoaderOpen={R.isNil(asyncInitialData.data)}>
      <BranchTree
        width={600}
        allowSelect={true}
        rootDisabled={true}
        initiallyExpanded={true}
        closeAction={props.closeModal}
        handleMultipleSelect={handleShareBranch}
        initialSelectedList={asyncInitialData.data}
        branchTypeFilter={GC.BRANCH_TYPE_ENUM_DIVISION} />
    </LocalLoader>
  );
};

export default connect(null, {
  openModal,
  closeModal,
  shareBranch,
})(enhance(ShareWithDivisions));
