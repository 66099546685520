import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  renderNothing,
} from 'react-recompose';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
// ui
import { PageWrapper } from '../../ui';
// features dashboards
import Header from './components/header';
import GeneralCharts from './components/general-charts';
import TrailersCharts from './components/trailers-charts';
import AccountingCharts from './components/accounting-charts';
import {
  DASHBOARD_TYPE_FLEET_GENERAL,
  DASHBOARD_TYPE_FLEET_TRAILER,
  DASHBOARD_TYPE_FLEET_ACCOUNTING,
} from './constants';
import {
  makeSelectFilters,
  makeSelectDashboard,
  makeSelectDashboardType,
  makeSelectCurrencySymbol,
} from './selectors';
//////////////////////////////////////////////////

const chartsByDashboardType = {
  [DASHBOARD_TYPE_FLEET_GENERAL]: GeneralCharts,
  [DASHBOARD_TYPE_FLEET_TRAILER]: TrailersCharts,
  [DASHBOARD_TYPE_FLEET_ACCOUNTING]: AccountingCharts,
};

const Dashboard = (props: Object) => {
  const { noWrapper, dashboardType, expandedContainer } = props;

  const ChartComponent = R.pathOr(GeneralCharts, [dashboardType], chartsByDashboardType);

  if (noWrapper) {
    return (
      <React.Fragment>
        <Header />
        <ChartComponent {...props} />
      </React.Fragment>
    );
  }


  return (
    <PageWrapper
      bgColor={G.getTheme('colors.bgGrey')}
      pl={G.ifElse(G.isTrue(expandedContainer), '0px', 60)}
    >
      <Header />
      <ChartComponent {...props} />
    </PageWrapper>
  );
};

const enhance = compose(
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  filters: makeSelectFilters(state),
  dashboard: makeSelectDashboard(state),
  dashboardType: makeSelectDashboardType(state),
  currencySymbol: makeSelectCurrencySymbol(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, null)(enhance(Dashboard));
