import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// features
import LocationForm from '../../location/components/location-form';
// feature fleet-profile
import { TableCell } from '../components/table-cells';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_LOCATION_TYPE]: {
    width: 150,
    name: 'titles:type',
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_LOCATION_TYPE, GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  [GC.FIELD_TEMPLATE_ID]: {
    width: 150,
    name: 'titles:template-id',
  },
  [GC.FIELD_LOCATION_NAME]: {
    width: 150,
    name: 'titles:name',
  },
  [GC.FIELD_ADDRESS_1]: {
    width: 150,
    name: 'titles:address1',
  },
  [GC.FIELD_ADDRESS_2]: {
    width: 150,
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 150,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 150,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    name: 'titles:country',
  },
  [GC.FIELD_LATITUDE]: {
    width: 150,
    name: 'titles:latitude',
  },
  [GC.FIELD_LONGITUDE]: {
    width: 150,
    name: 'titles:longitude',
  },
  [GC.FIELD_RESIDENTIAL]: {
    width: 100,
    type: 'boolean',
    name: 'titles:residential',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
};

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(settings),
};

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

export const truckLocationSettings = {
  report,
  columnSettings,
  groupName: 'locations',
  modalAdditionalOptions: { p: '0px' },
  itemTitleArr: ['titles:location', 'Location'],
  formGroupTitleArr: ['titles:locations', 'Locations'],
  endpoints: {
    list: 'truckLocations',
    createOrUpdate: 'truckLocations',
    remove: 'getCurrentTruckLocationEndpoint',
  },
  // helpers
  CustomForm: (props: Object) => {
    const { submitAction, initialValues, primaryObjectGuid } = props;

    return (
      <LocationForm
        {...props}
        entity={initialValues}
        guid={R.propOr(null, GC.FIELD_GUID, initialValues)}
        submitAction={(values: Object) => submitAction(R.assoc(GC.FIELD_TRUCK_GUID, primaryObjectGuid, values))}
      />
    );
  },
};


export const trailerLocationSettings = {
  ...truckLocationSettings,
  endpoints: {
    list: 'trailerLocations',
    createOrUpdate: 'trailerLocations',
    remove: 'getCurrentTrailerLocationEndpoint',
  },
  // components
  CustomForm: (props: Object) => {
    const { submitAction, initialValues, primaryObjectGuid } = props;

    return (
      <LocationForm
        {...props}
        entity={initialValues}
        guid={R.propOr(null, GC.FIELD_GUID, initialValues)}
        submitAction={(values: Object) => submitAction(R.assoc(GC.FIELD_TRAILER_GUID, primaryObjectGuid, values))}
      />
    );
  },
};
