import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature config
import { statusCodeOptionsMap } from '../constants';
import {
  statusCodeMappingFieldSettings,
  defaultStatusCodeMappingFields,
  statusCodeMappingValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultStatusCodeMappingFields,
      initialValues,
    ),
    validationSchema: statusCodeMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { mappingType, submitAction } = props;

      submitAction({ mappingType, data: values });
    },
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    (props: Object) => {
      const { values, mappingType, allStatusCodeList } = props;

      const integrationType = G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, values);

      let map = statusCodeOptionsMap;

      if (R.equals(mappingType, 'outboundMapping')) {
        map = {
          ...statusCodeOptionsMap,
          [GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_MACRO_POINT]: {
            ARRIVED: 'Arrived',
            DEPARTED: 'Departed',
          },
        };
      }

      const optionsObjectMap = R.pathOr([], [integrationType], map);

      const useLabelAsValue = G.isArray(optionsObjectMap);
      const statusCodeOptions = R.compose(
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ label, value: G.ifElse(useLabelAsValue, label, value) })),
      )(optionsObjectMap);

      return {
        optionsForSelect: { statusCodeOptions, allStatusCodeList },
      };
    },
  ),
  pure,
);

const StatusCodeMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      justifyContent='space-between'
      optionsForSelect={props.optionsForSelect}
      handlers={{ disableIntegrationType: () => props.isEditMode }}
      fields={statusCodeMappingFieldSettings(R.path(['values', GC.FIELD_INTEGRATION_TYPE], props), props.mappingType)}
    />
    <FormFooter boxStyles={{ py: '10px' }} closeModal={props.closeModal} />
  </form>
);

export default enhance(StatusCodeMappingForm);
