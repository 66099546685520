import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { css } from 'styled-components';
import { pure, compose, withState } from 'react-recompose';
// components
import { Tabs } from '../../../../../components/tabs';
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withAsyncGetBranchListByType } from '../../../../../hocs';
// ui
import { scrollableContainerCss4px } from '../../../../../ui';
// feature config
import {
  ediExporterConfigFields,
  defaultEdiExporterConfigFields,
  ediExporterConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: `${G.getWindowLocale('titles:invoice', 'Invoice')} (210)` },
  { text: `${G.getWindowLocale('titles:status-message', 'Status Message')} (214)` },
  { text: `${G.getWindowLocale('titles:tender-response', 'Tender Response')} (990)` },
  { text: `${G.getWindowLocale('titles:functional-acknowledgment', 'Functional Acknowledgment')} (997)` },
];

const filedKeysMap = [
  [
    GC.FIELD_CUSTOMER_GUID,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME,
    GC.FIELD_TRANSACTION_TYPES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD,
    GC.FIELD_AUTH_KEY,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID,
    GC.FIELD_EDI_SENDER_QUALIFIER,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PRIMARY_REFERENCE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID,
    GC.FIELD_RECEIVER_QUALIFIER,
    GC.FIELD_CONTENT_FORMAT,
    GC.FIELD_SCAC_OVERRIDE,
  ],
  [
    GC.FIELD_ENCODING_TYPE,
    GC.FIELD_INVOICE_RECEIVER_ID,
    GC.FIELD_INVOICE_RECEIVER_QUALIFIER,
    GC.FIELD_SEND_CARRIER_LOCATION,
    GC.FIELD_BILL_TO_LOCATION_IDENTIFIER,
    GC.FIELD_ORIGIN_LOCATION_IDENTIFIER,
    GC.FIELD_LOCATION_IDENTIFICATION_QUALIFIER,
    GC.FIELD_INVOICE_REFERENCES,
    GC.FIELD_INVOICE_ASSOCIATED_DOCUMENT_TYPES,
  ],
  [
    GC.FIELD_STATUS_MESSAGE_EVENT_REFERENCES,
    GC.FIELD_STATUS_MESSAGE_ORDER_REFERENCES,
    GC.FIELD_SENT_STATUS_CODES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_EVENT_WITH_STATUS,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_SHIPMENT_WITH_STATUS,
    GC.FIELD_CUSTOM_MAPPER,
    GC.FIELD_STATUS_MESSAGE_DEFAULT_TIME_ZONE,
    GC.FIELD_STATUS_MESSAGE_RECEIVER_ID,
    GC.FIELD_STATUS_MESSAGE_RECEIVER_QUALIFIER,
    GC.FIELD_STATUS_MESSAGE_SEND_FIRST_LAST_EVENT,
    GC.FIELD_SEND_APPOINTMENT_DATES,
    GC.FIELD_STATUS_MESSAGE_SEND_NEXT_EVENT_INDEX,
    GC.FIELD_STATUS_MESSAGE_STOP_NUMBER_POSITION,
  ],
  [
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_TENDER_RESPONSE_REFERENCE,
    GC.FIELD_ACCEPT_DECLINE_RECEIVER_ID,
    GC.FIELD_ACCEPT_DECLINE_RECEIVER_QUALIFIER,
    GC.FIELD_REFERENCES_TO_COMMENT,
  ],
  [
    GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_ID,
    GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_QUALIFIER,
  ],
];

const getFieldSettingsByActiveTab = (activeTab: number) => R.compose(
  R.values,
  R.pick(R.propOr([], activeTab, filedKeysMap)),
  R.indexBy(R.prop('fieldName')),
)(ediExporterConfigFields);

const enhance = compose(
  withState('activeTab', 'setActiveTab', 0),
  withAsyncGetBranchListByType(GC.BRANCH_TYPE_ENUM_CUSTOMER),
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultEdiExporterConfigFields,
      props.initialValues,
    ),
    validationSchema: Yup.object().shape(ediExporterConfigValidationSchema),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const EdiExporterConfigForm = (props: Object) => (
  <form
    id='edi_exporter_form'
    onSubmit={props.handleSubmit}
    css={css`
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 160px);

      ${scrollableContainerCss4px}
    `}
  >
    <Tabs
      tabs={tabs}
      wrapperStyles={{ mb: 15 }}
      activeTab={props.activeTab}
      handleClickTab={props.setActiveTab}
    />
    <FieldsetComponent
      {...G.getFormikProps(props)}
      justifyContent='space-between'
      fields={getFieldSettingsByActiveTab(props.activeTab)}
      optionsForSelect={R.assoc('branchListByType', props.branchListByType, props.optionsForSelect)}
    />
    <FormFooter boxStyles={{ py: '10px' }} />
  </form>
);

export default enhance(EdiExporterConfigForm);
