import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useState, useEffect, Fragment } from 'react';
// components
import { Tabs2 } from '../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature carrier-profile
import FormGroupTable from '../components/form-group-table';
import { makeSelectActiveRatingEngineTab } from '../selectors';
import {
  generalSettings,
  auditTabSettings,
  documentTabSettings,
  statisticTabSettings,
  scorecardsTabSettings,
  bidHistoryTabSettings,
  certificateTabSettings,
  ratingEngineTabSettings,
  additionalContactTabSettings,
} from '../settings';
/////////////////////////////////////////////

const makeGroups = (options: Object) => {
  const { props, groupsSettings } = options;

  const groups = R.map((item: Object) => {
    const {
      shouldNotRenderTable,
      primaryObjectGuidKey = GC.FIELD_CARRIER_GUID,
    } = item;

    return {
      ...item,
      primaryObjectGuidKey,
      shouldNotRenderTable: G.isFunction(shouldNotRenderTable) ? shouldNotRenderTable(props) : false,
    };
  }, groupsSettings);

  return groups;
};

const Tab = (props: Object) => (
  <Fragment>
    {
      R.pathOr([], ['groups'], props).map((group: Object, index: number) => {
        const { Component } = group;

        if (G.isNotNil(Component)) return <Component {...props} group={group} key={index} />;

        return (
          <FormGroupTable
            {...R.omit(['groups', 'collapsedGroup'], props)}
            key={index}
            group={group}
          />
        );
      })
    }
  </Fragment>
);

const tabs = [
  {
    value: 0,
    groupsSettings: generalSettings,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    value: 1,
    groupsSettings: documentTabSettings,
    text: G.getWindowLocale('titles:documents', 'Documents'),
  },
  {
    value: 2,
    groupsSettings: certificateTabSettings,
    text: G.getWindowLocale('titles:certificates', 'Certificates'),
  },
  {
    value: 3,
    groupsSettings: additionalContactTabSettings,
    text: G.getWindowLocale('titles:additional-contacts', 'Additional Contacts'),
  },
  {
    value: 4,
    groupsSettings: ratingEngineTabSettings,
    text: G.getWindowLocale('titles:rating-engine', 'Rating Engine'),
  },
  {
    value: 5,
    groupsSettings: bidHistoryTabSettings,
    text: G.getWindowLocale('titles:bid-history', 'Bid History'),
  },
  {
    value: 6,
    groupsSettings: statisticTabSettings,
    text: G.getWindowLocale('titles:statistic', 'Statistic'),
  },
  {
    value: 7,
    groupsSettings: scorecardsTabSettings,
    text: G.getWindowLocale('titles:scorecards', 'Scorecards'),
  },
  {
    value: 8,
    groupsSettings: auditTabSettings,
    text: G.getWindowLocale('titles:audit', 'Audit'),
  },
];

const TabsGroups = (props: Object) => {
  const [activeTab, setActiveTab] = useState(0);

  const activeRatingEngineTab = useSelector(makeSelectActiveRatingEngineTab());

  const groups = makeGroups({
    props,
    groupsSettings: R.pathOr([], [activeTab, 'groupsSettings'], tabs),
  });

  useEffect(() => {
    if (activeRatingEngineTab) setActiveTab(4);
  }, [activeRatingEngineTab]);

  return (
    <Fragment>
      <Tabs2
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsProps={{ variant: 'scrollable' }}
        tabsStyles={{ pb: '3px', background: G.getTheme('colors.white') }}
      />
      <Box
        bg='white'
        borderRadius='4px'
        boxShadow={`0 2px 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
      >
        <Tab {...props} groups={groups} />
      </Box>
    </Fragment>
  );
};

export default TabsGroups;
