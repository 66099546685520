import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-portal
import * as H from '../helpers';
//////////////////////////////////////////////////

const fieldNotRequired = Yup.string().nullable(true).notRequired();
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

export const carrierDetailPageSettings = [
  {
    type: 'select',
    name: 'titles:type',
    nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_TYPE,
    options: GC.FIELD_AVAILABLE_CARRIER_DOCUMENT_TYPE,
  },
  {
    type: 'file',
    name: 'actions:upload',
    nameForAttribute: 'file',
  },
];

export const defaultTrackingSettings = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_ADDRESS]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_LOCATION]: null,
  [GC.FIELD_LATITUDE]: null,
  [GC.FIELD_LONGITUDE]: null,
  [GC.FIELD_STATUS_MESSAGE_DATE]: null,
  [GC.FIELD_STATUS_MESSAGE_CODE]: null,
  [GC.FIELD_STATUS_MESSAGE_REASON_CODE]: null,
  [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE',
  [GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID]: null,
};

const commonProps = {
  width: 300,
  label: true,
  afterTop: 13,
  afterRight: 13,
  errorTop: '103%',
  jc: 'flex-start',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
  errorPosition: 'absolute',
};

export const documentFieldsCarrier = {
  fields: [
    {
      type: 'select',
      name: 'titles:type',
      validate: G.required,
      options: 'availableDocumentTypes',
      fieldName: GC.FIELD_CARRIER_DOCUMENT_TYPE,
      nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_TYPE,
    },
    {
      type: 'multiswitch',
      name: 'titles:description',
      nameForAttribute: 'proofType',
      fieldName: GC.FIELD_STATUS_MESSAGE_DATE,
      options: [
        {
          width: 100,
          name: GC.DOCUMENT_PROOF_TYPE_GENERAL_NAME,
          value: GC.DOCUMENT_PROOF_TYPE_GENERAL_VALUE,
        },
        {
          width: 80,
          name: GC.DOCUMENT_PROOF_TYPE_POP,
          value: GC.DOCUMENT_PROOF_TYPE_POP,
        },
        {
          width: 80,
          name: GC.DOCUMENT_PROOF_TYPE_POD,
          value: GC.DOCUMENT_PROOF_TYPE_POD,
        },
      ],
    },
    {
      type: 'select',
      name: 'titles:type',
      options: 'stopOptions',
      fieldName: GC.FIELD_EVENT_GUID,
      nameForAttribute: GC.FIELD_EVENT_GUID,
    },
    {
      type: 'file',
      name: 'actions:upload',
      nameForAttribute: 'file',
      validate: G.isEmptyFileAndURL,
      fieldName: GC.FIELD_STATUS_MESSAGE_DATE,
      placeholder: G.getWindowLocale('actions:upload', 'Upload'),
    },
    {
      type: 'text',
      name: 'titles:document-name',
      validate: G.isEmptyOrMinMax(0, 40),
      nameForAttribute: GC.FIELD_DOCUMENT_DOCUMENT_NAME,
      label: G.getWindowLocale('titles:document-name', 'Document Name'),
    },
    {
      type: 'textarea',
      name: 'titles:comments',
      validate: G.isEmptyOrMinMax(0, 2000),
      fieldName: GC.FIELD_DOCUMENT_COMMENTS,
      nameForAttribute: GC.FIELD_DOCUMENT_COMMENTS,
      label: G.getWindowLocale('titles:comments', 'Comments'),
    },
  ],
};

export const defaultAcceptValues = {
  token: null,
  [GC.FIELD_APPROVED_BY]: '',
  [GC.FIELD_DISPATCH_NAME]: '',
  [GC.FIELD_DISPATCH_PHONE]: '',
  [GC.FIELD_DISPATCH_EMAILS]: '',
  [GC.FIELD_CARRIER_ACCEPT_DRIVER_NAME]: '',
  [GC.FIELD_CARRIER_ACCEPT_TRUCK_NUMBER]: '',
  [GC.FIELD_CARRIER_ACCEPT_TRAILER_NUMBER]: '',
  [GC.FIELD_CARRIER_ACCEPT_TRACKING_NUMBER]: '',
  [GC.FIELD_CARRIER_ACCEPT_DRIVER_PHONE_NUMBER]: '',
};

export const initialGoogleStatusMessageFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_LATITUDE]: null,
  [GC.FIELD_ADDRESS_1]: null,
  [GC.FIELD_LONGITUDE]: null,
};

const declineFormFieldStyles = {
  width: 300,
  inputWrapperStyles: { m: '30px 20px 0 0' },
};

export const declineDispatchFieldSettings = [
  {
    ...declineFormFieldStyles,
    type: 'select',
    isRequired: true,
    options: 'declineReasonOptions',
    fieldName: GC.FIELD_DECLINE_REASON_CODE_GUID,
    label: G.getWindowLocale('titles:decline-reason', 'Decline Reason'),
  },
  {
    ...declineFormFieldStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_DECLINE_BY,
    label: G.getWindowLocale('titles:decline-by', 'Decline By'),
    inputWrapperStyles: {
      m: '30px 20px 0 0',
      display: (props: Object) => G.ifElse(
        R.pathEq('customer', ['values', 'portalType'], props),
        'none',
        undefined,
      ),
    },
  },
];

export const declineOrderQuoteFieldSettings = [
  {
    ...declineFormFieldStyles,
    type: 'select',
    options: 'declineReasonOptions',
    fieldName: GC.FIELD_STATUS_REASON_CODE_GUID,
    label: G.getWindowLocale('titles:decline-reason', 'Decline Reason'),
  },
  {
    p: 15,
    width: 300,
    type: 'textarea',
    isRequired: false,
    fieldName: GC.FIELD_COMMENT,
    label: G.getWindowLocale('titles:comments', 'Comments'),
    inputWrapperStyles: {
      width: 300,
      m: '30px 20px 0 0',
    },
  },
];

export const defaultDeclineDispatchFields = {
  [GC.FIELD_DECLINE_BY]: null,
  [GC.FIELD_DECLINE_REASON_CODE_GUID]: null,
};

export const declineDispatchValidationSchema = ({ portalType }: Object) => Yup.lazy(() => Yup.object().shape({
  [GC.FIELD_DECLINE_REASON_CODE_GUID]: G.ifElse(
    H.isPortalTypeCarrier(portalType),
    fieldRequired,
    fieldNotRequired,
  ),
  [GC.FIELD_DECLINE_BY]: G.ifElse(
    H.isPortalTypeCarrier(portalType),
    fieldRequired,
    fieldNotRequired,
  ),
}));

const acceptFormFieldStyles = {
  width: 300,
  inputWrapperStyles: { m: '30px 20px 0 0' },
};

export const acceptDispatchFieldSections = (requiredFields: Array = [], disabledFields: Object) => [
  {
    title: G.getWindowLocale('titles:electronic-signature', 'Electronic Signature'),
    fields: [
      {
        ...acceptFormFieldStyles,
        fieldName: GC.FIELD_APPROVED_BY,
        label: G.getWindowLocale('titles:approved-by', 'Approved By'),
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_APPROVED_BY, requiredFields),
      },
    ],
  },
  {
    title: G.getWindowLocale('titles:driver-truck-information', 'Driver/Truck Information'),
    fields: [
      {
        ...acceptFormFieldStyles,
        fieldName: GC.FIELD_CARRIER_ACCEPT_DRIVER_NAME,
        label: G.getWindowLocale('titles:driver-name', 'Driver Name'),
        disabled: disabledFields[GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME],
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME, requiredFields),
      },
      {
        ...acceptFormFieldStyles,
        type: 'phoneNumber',
        phoneInputPaddingLeft: 15,
        phoneInputBorderRadius: 4,
        phoneInputBorder: '1px solid',
        fieldName: GC.FIELD_CARRIER_ACCEPT_DRIVER_PHONE_NUMBER,
        label: G.getWindowLocale('titles:driver-phone-number', 'Driver Phone Number'),
        disabled: disabledFields[GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER],
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER, requiredFields),
      },
      {
        ...acceptFormFieldStyles,
        fieldName: GC.FIELD_CARRIER_ACCEPT_TRUCK_NUMBER,
        label: G.getWindowLocale('titles:truck-number', 'Truck Number'),
        disabled: disabledFields[GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER],
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER, requiredFields),
      },
      {
        ...acceptFormFieldStyles,
        fieldName: GC.FIELD_CARRIER_ACCEPT_TRAILER_NUMBER,
        label: G.getWindowLocale('titles:trailer-number', 'Trailer Number'),
        disabled: disabledFields[GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER],
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER, requiredFields),
      },
    ],
  },
  {
    title: G.getWindowLocale('titles:dispatcher-information', 'Dispatcher Information'),
    fields: [
      {
        ...acceptFormFieldStyles,
        fieldName: GC.FIELD_DISPATCH_NAME,
        label: G.getWindowLocale('titles:dispatcher-name', 'Dispatcher Name'),
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_FULL_NAME, requiredFields),
      },
      {
        ...acceptFormFieldStyles,
        type: 'multiEmail',
        shouldOverrideEmails: true,
        fieldName: GC.FIELD_DISPATCH_EMAILS,
        label: G.getWindowLocale('titles:dispatcher-emails', 'Dispatcher Emails'),
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_EMAIL, requiredFields),
      },
      {
        ...acceptFormFieldStyles,
        type: 'phoneNumber',
        phoneInputPaddingLeft: 15,
        phoneInputBorderRadius: 4,
        phoneInputBorder: '1px solid',
        fieldName: GC.FIELD_DISPATCH_PHONE,
        label: G.getWindowLocale('titles:dispatcher-phone', 'Dispatcher Phone'),
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_PHONE_NUMBER, requiredFields),
      },
      {
        ...acceptFormFieldStyles,
        fieldName: GC.FIELD_CARRIER_ACCEPT_TRACKING_NUMBER,
        label: G.getWindowLocale('titles:internal-tracking-number', 'Internal Tracking Number'),
        isRequired: R.includes(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_INTERNAL_LOAD_NUMBER, requiredFields),
      },
    ],
  },
];

const acceptFieldsMap = {
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_APPROVED_BY]: GC.FIELD_APPROVED_BY,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_EMAIL]: GC.FIELD_DISPATCH_EMAILS,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_FULL_NAME]: GC.FIELD_DISPATCH_NAME,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_PHONE_NUMBER]: GC.FIELD_DISPATCH_PHONE,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER]: GC.FIELD_CARRIER_ACCEPT_TRUCK_NUMBER,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME]: GC.FIELD_CARRIER_ACCEPT_DRIVER_NAME,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER]: GC.FIELD_CARRIER_ACCEPT_TRAILER_NUMBER,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_INTERNAL_LOAD_NUMBER]: GC.FIELD_CARRIER_ACCEPT_TRACKING_NUMBER,
  [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER]: GC.FIELD_CARRIER_ACCEPT_DRIVER_PHONE_NUMBER,
};

const validationSchemaAcceptCarrierRate = (props: Object) => Yup.lazy(() => Yup.object().shape(
  R.compose(
    R.reduce((acc: Object, key: string) => {
      if (R.equals(key, GC.FIELD_DISPATCH_EMAILS)) return R.assoc(key, G.yupArrayRequired, acc);

      return R.assoc(key, fieldRequired, acc);
    }, {}),
    R.values,
    R.pick(R.pathOr([], ['portal', 'requiredFields'], props)),
  )(acceptFieldsMap),
));

const acceptOrderQuoteValidationSchema = Yup.object().shape({
  [GC.FIELD_ACCEPTED_BY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.ACCEPTED_BY_PHONE_NUMBER]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_ACCEPTED_BY_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_LOAD_STOPS]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_ADDRESS_1]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    [GC.FIELD_LOAD_EVENT_EARLY_DATE]: Yup.date().nullable(true).required(G.getRequiredLocaleTxt()),
  })),
  [GC.FIELD_REFERENCES]: Yup.array()
    .nullable(true)
    .notRequired()
    .of(Yup.object().shape({
      [GC.FIELD_VALUE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      [GC.FIELD_REFERENCE_TYPE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    })),
});

const acceptCarrierQuoteValidationSchema = Yup.object().shape({
  [GC.FIELD_CURRENCY]: fieldRequired,
  [GC.CHARGE_TYPE_MAIN]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt()),
  [GC.FIELD_CHARGES]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_TOTAL]: Yup.number()
      .nullable(true)
      .positive(G.getShouldBePositiveLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt()),
  })),
});

export const getAcceptFormValidationSchema = (props: Object) => {
  if (H.isPortalTypeCarrier(props.portalType)) return validationSchemaAcceptCarrierRate(props);

  if (H.isPortalTypeBidQuote(props.portalType)) return acceptCarrierQuoteValidationSchema;

  return acceptOrderQuoteValidationSchema;
};

export const eventFieldSettings = (index: number, isEventTypePickup: boolean) => [
  {
    ...acceptFormFieldStyles,
    isRequired: true,
    timeIntervals: 1,
    type: 'datePicker',
    timeSelection: true,
    fieldName: `events.${index}.${GC.FIELD_LOAD_EVENT_EARLY_DATE}`,
    label: `${G.ifElse(isEventTypePickup, 'Pickup', 'Drop')} Date & Time`,
  },
  {
    ...acceptFormFieldStyles,
    isRequired: false,
    label: 'Location Name',
    fieldName: `events.${index}.${GC.FIELD_LOCATION_NAME}`,
  },
  {
    ...acceptFormFieldStyles,
    isRequired: true,
    label: 'Address 1',
    fieldName: `events.${index}.${GC.FIELD_ADDRESS_1}`,
  },
  {
    ...acceptFormFieldStyles,
    isRequired: false,
    label: 'Address 2',
    fieldName: `events.${index}.${GC.FIELD_ADDRESS_2}`,
  },
];

export const additionalIfoFieldSettings = [
  {
    ...acceptFormFieldStyles,
    width: 200,
    isRequired: true,
    label: 'Accepted By',
    fieldName: GC.FIELD_ACCEPTED_BY,
  },
  {
    ...acceptFormFieldStyles,
    width: 200,
    isRequired: true,
    label: 'Accepted By Email',
    fieldName: GC.FIELD_ACCEPTED_BY_EMAIL,
  },
  {
    width: 200,
    isRequired: true,
    type: 'phoneNumber',
    phoneInputPaddingLeft: 15,
    phoneInputBorderRadius: 4,
    phoneInputBorder: '1px solid',
    label: 'Accepted By Phone Number',
    fieldName: GC.ACCEPTED_BY_PHONE_NUMBER,
    inputWrapperStyles: { m: '30px 0 0 0' },
  },
  {
    p: 15,
    width: 640,
    type: 'textarea',
    isRequired: false,
    label: 'Special Instructions',
    inputWrapperStyles: { m: '30px 0 0 0' },
    fieldName: GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS,
  },
];

export const referenceFieldSettings = (index: number, zIndex: number) => [
  {
    width: 298,
    isRequired: true,
    type: 'reactSelect',
    options: 'refTypeOptions',
    label: G.getWindowLocale('titles:name', 'Name'),
    inputWrapperStyles: { zIndex, m: '30px 20px 0 0' },
    fieldName: `${GC.FIELD_REFERENCES}.${index}.${GC.FIELD_REFERENCE_TYPE_GUID}`,
  },
  {
    width: 298,
    isRequired: true,
    options: 'allowedValues',
    inputWrapperStyles: { zIndex, m: '30px 0 0 0' },
    label: G.getWindowLocale('titles:value', 'Value'),
    fieldName: `${GC.FIELD_REFERENCES}.${index}.${GC.FIELD_VALUE}`,
  },
];

export const currencyFieldSettings = [
  {
    ...acceptFormFieldStyles,
    width: 320,
    type: 'select',
    isRequired: true,
    label: 'Currency',
    fieldName: GC.FIELD_CURRENCY,
    options: R.drop(1, GC.CURRENCY_OPTIONS),
  },
  {
    ...acceptFormFieldStyles,
    width: 320,
    isRequired: true,
    label: 'Linehaul',
    fieldName: GC.CHARGE_TYPE_MAIN,
  },
];

export const commentsFieldSettings = [
  {
    ...acceptFormFieldStyles,
    p: 15,
    width: 320,
    type: 'textarea',
    label: 'Comments',
    fieldName: GC.FIELD_COMMENTS,
  },
];

export const chargesFieldSettings = [
  {
    ...acceptFormFieldStyles,
    width: 320,
    label: 'Discount',
    fieldName: `${GC.FIELD_CHARGES}.0.${GC.FIELD_TOTAL}`,
  },
  {
    ...acceptFormFieldStyles,
    width: 320,
    label: 'Fuel',
    fieldName: `${GC.FIELD_CHARGES}.1.${GC.FIELD_TOTAL}`,
  },
];

export const additionalChargesFieldSettings = (index: number) => [
  {
    width: 290,
    type: 'select',
    label: 'Charge',
    options: 'accessorialOptions',
    inputWrapperStyles: { m: '30px 20px 0 0' },
    fieldName: `${GC.FIELD_CHARGES}.${R.add(index, 2)}.${GC.FIELD_ACCESSORIAL_CONFIG_GUID}`,
  },
  {
    ...acceptFormFieldStyles,
    width: 298,
    label: 'Rate',
    inputWrapperStyles: { m: '30px 0 0 0' },
    fieldName: `${GC.FIELD_CHARGES}.${R.add(index, 2)}.${GC.FIELD_TOTAL}`,
  },
];

export const defaultBidQuoteFields = {
  [GC.FIELD_COMMENTS]: null,
  [GC.CHARGE_TYPE_MAIN]: null,
  [GC.FIELD_CURRENCY]: GC.CURRENCY_TYPE_USD,
  [GC.FIELD_CHARGES]: [
    {
      [GC.FIELD_TOTAL]: null,
      [GC.FIELD_DISPLAYED_VALUE]: null,
      [GC.FIELD_TYPE]: GC.CHARGE_TYPE_DISCOUNT,
      [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
    },
    {
      [GC.FIELD_TOTAL]: null,
      [GC.FIELD_DISPLAYED_VALUE]: null,
      [GC.FIELD_TYPE]: GC.CHARGE_TYPE_FUEL,
      [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
    },
  ],
};

export const checkInSettings = [
  {
    ...commonProps,
    zIndex: 100,
    type: 'calendar',
    isClearable: true,
    name: 'titles:date',
    timeSelection: true,
    alignItems: 'center',
    placeholder: 'Select a datetime',
    fieldName: GC.FIELD_STATUS_MESSAGE_DATE,
    nameForAttribute: GC.FIELD_STATUS_MESSAGE_DATE,
    pathToIconColor: 'carrierPortal.configGroupItem.iconColor',
  },
  {
    ...commonProps,
    alignItems: 'center',
    loc: 'titles:address',
    placeholder: 'Address',
    labelFontWeight: 'bold',
    customPlaceGeocoder: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
  },
  {
    ...commonProps,
    placeholder: 'City',
    loc: 'titles: city',
    alignItems: 'center',
    labelFontWeight: 'bold',
    fieldName: GC.FIELD_CITY,
    customPlaceGeocoder: true,
    type: 'addressAutocomplete',
  },
  {
    ...commonProps,
    loc: 'titles:state',
    alignItems: 'center',
    placeholder: 'State',
    labelFontWeight: 'bold',
    customPlaceGeocoder: true,
    fieldName: GC.FIELD_STATE,
    type: 'addressAutocomplete',
  },
  {
    ...commonProps,
    labelWidth: 100,
    alignItems: 'center',
    placeholder: 'Country',
    labelFontWeight: 'bold',
    customPlaceGeocoder: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_COUNTRY,
  },
  {
    ...commonProps,
    type: 'select',
    alignItems: 'center',
    options: 'statusCode',
    name: 'titles:status-code',
    fieldName: GC.FIELD_STATUS_MESSAGE_CODE,
    nameForAttribute: GC.FIELD_STATUS_MESSAGE_CODE,
  },
  {
    ...commonProps,
    type: 'select',
    alignItems: 'center',
    options: 'statusReasonCode',
    name: 'titles:status-reason-code',
    fieldName: GC.FIELD_STATUS_MESSAGE_REASON_CODE,
    customDisabledFunction: 'handleSetReasonOptions',
    nameForAttribute: GC.FIELD_STATUS_MESSAGE_REASON_CODE,
  },
];

export const setInitialFormikValues = (
  defaultValues: Object,
  initialValues: Object = {},
) => R.mergeRight(defaultValues, initialValues);

export const getInitialValidationSchema = {
  [GC.FIELD_ADDRESS]: Yup.string()
    .nullable(true),
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(2, G.getShouldBeFromToLocaleTxt(2, 70))
    .max(70, G.getShouldBeFromToLocaleTxt(2, 70)),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(2, G.getShouldBeFromToLocaleTxt(2, 70))
    .max(70, G.getShouldBeFromToLocaleTxt(2, 70)),
  [GC.FIELD_STATUS_MESSAGE_CODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const getInitialStatusMessageValidationSchema = {
  [GC.FIELD_STATUS_MESSAGE_CODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const getValidationSchema = (props: Object) => {
  const { geoLocationProcess, statusReasonCode } = props;
  let schema = getInitialValidationSchema;
  if (G.isTrue(geoLocationProcess)) {
    schema = getInitialStatusMessageValidationSchema;
  }
  if (G.isNotNilAndNotEmpty(statusReasonCode)) {
    schema[GC.FIELD_STATUS_MESSAGE_REASON_CODE] = Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt());
  }
  return schema;
};
