import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '370px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

const termsAndConditionsDocumentFieldSettings = [
  {
    ...defaultStyles,
    type: 'file',
    loc: 'titles:upload-file',
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
  },
];

const defaultTermsAndConditionsDocumentFields = {
  [GC.FIELD_DOCUMENT_UPLOAD]: null,
};

const termsAndConditionsDocumentValidationSchema = Yup.object().shape({
  [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(G.mapObjectEmptyStringFieldsToNull(values)),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultTermsAndConditionsDocumentFields,
      initialValues,
    ),
    validationSchema: termsAndConditionsDocumentValidationSchema,
  }),
  pure,
);

const TermsAndConditionsDocumentForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={termsAndConditionsDocumentFieldSettings}
        fileName={G.getWindowLocale('titles:select-file', 'Select File')}
      />
      <FormFooter boxStyles={{ mt: 10 }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(TermsAndConditionsDocumentForm);
