import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { memo, useEffect } from 'react';
// components
import { ActionBox } from '../../../../components/action-box';
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Flex } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature fleet-profile
import FleetAssignmentForm from '../forms/fleet-assignment';
import FormGroupTable from '../../components/form-group-table';
import { changeFleetAssignmentRequest, getFleetAssignmentListRequest } from '../../actions';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_PRIMARY_DRIVER]: {
    width: 250,
    name: 'titles:primary-driver',
    customComponent: (row: Object) => {
      const primaryDriver = R.path(['data', GC.FIELD_PRIMARY_DRIVER], row);

      if (G.isNilOrEmpty(primaryDriver)) return null;

      const { fullText } = G.getUserInfo(primaryDriver);

      const guid = G.getGuidFromObject(primaryDriver);

      return (
        <ActionBox
          text={fullText}
          boxStyles={{ maxWidth: 250 }}
          action={() => G.goToRoute(routesMap.fleetDriverProfile(guid))}
        />
      );
    },
  },
  [GC.FIELD_SECONDARY_DRIVER]: {
    width: 250,
    name: 'titles:secondary-driver',
    customComponent: (row: Object) => {
      const secondaryDriver = R.path(['data', GC.FIELD_SECONDARY_DRIVER], row);

      if (G.isNilOrEmpty(secondaryDriver)) return null;

      const { fullText } = G.getUserInfo(secondaryDriver);

      const guid = G.getGuidFromObject(secondaryDriver);

      return (
        <ActionBox
          text={fullText}
          boxStyles={{ maxWidth: 250 }}
          action={() => G.goToRoute(routesMap.fleetDriverProfile(guid))}
        />
      );
    },
  },
  [GC.FIELD_TRUCK]: {
    width: 150,
    name: 'titles:truck',
    customComponent: (row: Object) => {
      const truck = R.path(['data', GC.FIELD_TRUCK], row);

      if (G.isNilOrEmpty(truck)) return null;

      const { guid, unitId } = truck;

      return (
        <ActionBox
          text={unitId}
          boxStyles={{ maxWidth: 150 }}
          action={() => G.goToRoute(routesMap.fleetTruckProfile(guid))}
        />
      );
    },
  },
  [GC.FIELD_TRAILERS]: {
    width: 400,
    name: 'titles:trailers',
    customComponent: (row: Object) => {
      const trailers = R.pathOr([], ['data', GC.FIELD_TRAILERS], row);

      return (
        <Flex flexWrap='wrap'>
          {
            trailers.map(({ guid, unitId }: Object) => (
              <ActionBox
                key={guid}
                text={unitId}
                boxStyles={{ mr: 10, maxWidth: 130 }}
                action={() => G.goToRoute(routesMap.fleetTrailerProfile(guid))}
              />
            ))
          }
        </Flex>
      );
    },
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

export const FleetAssignment = memo((props: Object) => {
  const { isOpened, openModal, fleetAssignment, handleToggleFormGroupTable } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (R.isNil(fleetAssignment)) dispatch(getFleetAssignmentListRequest());
  }, []);

  const handleRemoveAssignment = (guid: string, entity: Object) => {
    const data = R.pick(
      ['oldTruckGuid', 'oldTrailerGuids', 'oldPrimaryDriverGuid', 'oldSecondaryDriverGuid'],
      entity,
    );

    const component = (
      <ConfirmComponent textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
    );

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:remove', 'Remove'),
            action: () => dispatch(changeFleetAssignmentRequest(data)),
          },
        ],
      },
    };

    openModal(modal);
  };

  const handleOpenFleetAssignmentForm = (entity: Object) => {
    const { branchGuid, vendorGuid } = props;

    const editMode = G.isNotNilAndNotEmpty(entity);

    const title = G.ifElse(
      editMode,
      G.getWindowLocale('titles:edit-fleet-assignment', 'Edit Fleet Assignment'),
      G.getWindowLocale('titles:add-fleet-assignment', 'Add Fleet Assignment'),
    );

    const component = (
      <FleetAssignmentForm
        entity={entity}
        vendorGuid={vendorGuid}
        branchGuid={branchGuid}
        submitAction={(data: Object) => dispatch(changeFleetAssignmentRequest(data))}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        title,
        height: 'auto',
      },
    };

    openModal(modal);
  };

  return (
    <FormGroupTable
      report={report}
      isOpened={isOpened}
      columnSettings={settings}
      itemList={fleetAssignment}
      handleRemoveItem={handleRemoveAssignment}
      handleCreateOrUpdateItem={handleOpenFleetAssignmentForm}
      formGroupTitleArr={['titles:fleet-assignment', 'Fleet Assignment']}
      handleToggleFormGroupTable={() => handleToggleFormGroupTable('fleetAssignment')}
    />
  );
});
