import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, call, fork, select, takeEvery, takeLatest } from 'redux-saga/effects';
// common
import { sendLogOutRequest, initialDataLoadRequest } from '../../common/actions';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { messageType } from '../sockets-v2/constants';
import { setAuthoritiesRequest } from '../permission/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { socketPostMessage, socketConnectRequest } from '../sockets-v2/actions';
import {
  getCarrierPortalInfoSuccess,
  getChatMessageCarrierRequest,
  getCarrierResponseTokenSuccess,
} from '../carrier-portal/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import routesMap from '../../utilities/routes';
import endpointsMap from '../../utilities/endpoints';
// feature auth
import * as A from './actions';
import { makeSelectAuthData } from './selectors';
//////////////////////////////////////////////////

const mobileWebUrl = 'https://ongoamous.com';

const auth = {
  username: 'frontend',
  password: 'secret',
};

function* handleDispatchAnyAction({ type }: Object) {
  try {
    const { loggedIn, isDispatchedActions } = yield select(makeSelectAuthData());

    const condition = G.isAllTrue(
      loggedIn,
      R.not(G.stringContains(type, 'refreshTokenRequest')),
      R.not(G.stringContains(type, 'checkTokenRequest')),
    );

    const isDispatched = R.and(R.not(G.stringContains(type, 'setDispatchAction')), R.not(isDispatchedActions));

    if (R.and(condition, isDispatched)) {
      yield put(A.setDispatchAction(true));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleDispatchAnyAction exception');
  }
}

function* logoutWithReloadSaga() {
  try {
    yield put(A.receivedLogOutSuccess());

    yield call(G.removeCurrentBranchGuid);
    yield call(G.removeRefreshTokenFromSession);
    yield call(G.removeAuthTokenFromSession);

    yield call(G.goToRoute, routesMap.loginPage);
    yield call(G.windowLocationReload);
  } catch (error) {
    yield call(G.windowLocationReload);
  }
}

function* handleCheckTokenSaga({ payload }: Object) {
  try {
    const data = { token: payload };

    if (G.isNilOrEmpty(payload)) {
      yield call(logoutWithReloadSaga);

      return;
    }

    const { status } = yield call(sendRequest, 'post', endpointsMap.checkToken, { data, auth }, true);

    if (R.gte(status, 400)) {
      yield call(logoutWithReloadSaga);
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleCheckTokenSaga exception');
  }
}

function* handleRefreshTokenSaga() {
  try {
    const refreshToken = G.getRefreshTokenFromSession();

    const reqData = { grant_type: 'refresh_token', refresh_token: refreshToken };

    const res = yield call(sendRequest, 'post', endpointsMap.login, { data: reqData, auth }, true);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.setAuthTokenToSession, data.access_token);
      yield call(G.setRefreshTokenToSession, data.refresh_token);
      yield call(G.setAuthTokenExpirationToSession, data.expires_in);

      yield put(A.setDispatchAction(false));
      yield put(A.refreshTokenSuccess());
      // TODO: chech why we don't update tokens in sessionData makeSelectCurrentUserData if we need it
      // Check auth flow
    } else {
      yield call(logoutWithReloadSaga);
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleRefreshTokenSaga, exception');

    yield put(A.refreshTokenCatch());
  }
}

function* checkTokenAndRefreshTokenSaga() {
  try {
    const { loggedIn, isDispatchedActions } = yield select(makeSelectAuthData());

    if (loggedIn) {
      yield put(A.checkTokenRequest(G.getAuthTokenFromSession()));

      if (isDispatchedActions) {
        yield put(A.refreshTokenRequest());
      }
    }
  } catch (error) {
    yield call(G.handleException, error, 'checkTokenAndRefreshTokenSaga exception');
  }
}

function* checkTokenAndRefreshTokenSetDelaySaga() {
  while (true) { // eslint-disable-line
    yield delay(300000);

    yield call(checkTokenAndRefreshTokenSaga);
  }
}

function* handleLoginRequest({ payload }: Object) {
  try {
    yield put(initialDataLoadRequest());

    const { password, username, callback } = payload;

    const reqData = { username, password, grant_type: 'password' };

    const res = yield call(sendRequest, 'post', endpointsMap.login, { data: reqData, auth }, true);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.isEmpty(data.authorities)) {
        yield call(G.showToastrMessage, 'info', 'messages:permissions:missing');

        yield call(G.goToRoute, routesMap.loginPage);
      } else {
        if (R.equals(data.user_type, GC.USER_TYPE_DRIVER)) {
          yield call(G.showToastrMessage, 'info', 'messages:user:driver:wrong-site');

          yield delay(2000);

          window.location.href = mobileWebUrl;

          return;
        }

        const branchGuid = data[GC.FIELD_BRANCH_UNDERSCORE_GUID];

        yield call(G.setCurrentBranchGuid, branchGuid);

        yield call(G.setAuthTokenToSession, data.access_token);
        yield call(G.setRefreshTokenToSession, data.refresh_token);
        yield call(G.setAuthTokenExpirationToSession, data.expires_in);

        yield put(A.receivedLogInSuccess(data));
        yield put(setAuthoritiesRequest(data.authorities));
      }

      G.setItemToWindow('amousCurrentUser', data);
    } else {
      yield call(G.handleFailResponse, res, 'handleLoginRequest fail', true);
    }

    G.callFunction(callback);
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:401');
    yield call(G.handleException, error, 'handleLoginRequest exception');

    yield call(logoutWithReloadSaga);
  }
}

const omitSessionPages = [
  GC.ROUTE_PATH_NAL_LOGIN,
  GC.ROUTE_PATH_NEW_PASSWORD,
  GC.ROUTE_PATH_OAUTH2_POPUP,
  GC.ROUTE_PATH_PRIVACY_POLICY,
  GC.ROUTE_PATH_RESET_PASSWORD,
  GC.ROUTE_PATH_BOSH_LOS_REDIRECT,
  GC.ROUTE_PATH_BOSH_LOS_CALLBACK,
  GC.ROUTE_PATH_NEW_PASSWORD_MOBILE,
  GC.ROUTE_PATH_TERMS_AND_CONDITIONS,
  GC.ROUTE_PATH_NEW_PASSWORD_MOBILE_SUCCESS_PAGE,
];

function* failSessionSaga() {
  yield call(G.removeCurrentBranchGuid);
  yield call(G.removeRefreshTokenFromSession);
  yield call(G.removeAuthTokenFromSession);

  yield put(A.receivedSessionValidationFail());

  yield call(G.goToRoute, routesMap.loginPage);

  yield put({ type: GC.CLEAR_STORE });
}

export function* sessionSaga(token: string) {
  try {
    if (R.includes(G.getWindowLocationPathname(), omitSessionPages)) return false;

    // TODO: move carrier portal logic to own saga
    const forCarrierData = G.parseQueryString();
    const tokenCarrier = forCarrierData.token;

    const portals = [
      GC.ROUTE_PATH_CARRIERS_ACCEPT,
      GC.ROUTE_PATH_CARRIERS_DECLINE,
      GC.ROUTE_PATH_ORDER_QUOTE_ACCEPT,
      GC.ROUTE_PATH_ORDER_QUOTE_DECLINE,
      GC.ROUTE_PATH_CARRIER_QUOTE_ACCEPT,
      GC.ROUTE_PATH_CARRIER_QUOTE_DECLINE,
    ];

    const isPortalScreen = R.any((item: string) => R.includes(item, G.getWindowLocationPathname()), portals);

    if (G.isTrue(isPortalScreen)) {
      const portalTypesMap = {
        [GC.ROUTE_PATH_CARRIERS_ACCEPT]: GC.PORTAL_TYPE_CARRIER,
        [GC.ROUTE_PATH_CARRIERS_DECLINE]: GC.PORTAL_TYPE_CARRIER,
        [GC.ROUTE_PATH_ORDER_QUOTE_ACCEPT]: GC.PORTAL_TYPE_CUSTOMER,
        [GC.ROUTE_PATH_ORDER_QUOTE_DECLINE]: GC.PORTAL_TYPE_CUSTOMER,
        [GC.ROUTE_PATH_CARRIER_QUOTE_ACCEPT]: GC.PORTAL_TYPE_CARRIER_QUOTE,
        [GC.ROUTE_PATH_CARRIER_QUOTE_DECLINE]: GC.PORTAL_TYPE_CARRIER_QUOTE,
      };

      const routePath = R.head(R.split('?', R.pathOr('', ['location', 'pathname'], window)));
      const portalType = G.getPropFromObject(routePath, portalTypesMap);

      const actionType = G.ifElse(
        R.includes('decline', routePath),
        GC.VISIT_PORTAL_DECLINE_PAGE,
        GC.VISIT_PORTAL_ACCEPT_PAGE,
      );

      return yield put({ type: actionType, payload: { portalType, token: tokenCarrier } });
    }

    const carrierDetailPage = yield call(
      G.stringContains,
      G.getWindowLocationPathname(),
      GC.ROUTE_PATH_CARRIERS_ADD_MESSAGE_PAGE,
    );

    if (G.isTrue(carrierDetailPage)) {
      if (R.is(String, tokenCarrier)) {
        yield put(getCarrierResponseTokenSuccess(tokenCarrier));

        const headers = { carrierToken: tokenCarrier };
        const options = { headers };

        const detailPageRes = yield call(
          sendRequest,
          'get',
          endpointsMap.getCarrierDetailPageEndpoint(tokenCarrier),
          options,
        );

        if (G.isResponseSuccess(detailPageRes.status)) {
          yield put(socketConnectRequest());
          yield put(getCarrierPortalInfoSuccess(detailPageRes.data));
          yield put(getChatMessageCarrierRequest(forCarrierData));

          yield delay(2000);

          yield put(socketPostMessage({
            action: messageType.subscribe,
            destination: `/tel/forCarrier/${tokenCarrier}`,
          }));
        }
      }

      return false;
    }

    const carrierResponseRoute = yield call(
      G.stringContains,
      G.getWindowLocationPathname(),
      GC.ROUTE_PATH_CARRIERS_RESPONSE,
    );

    if (G.isTrue(carrierResponseRoute)) return false;
    // finish carrier portal logic

    const res = yield call(sendRequest, 'post', endpointsMap.checkToken, { data: { token }, auth }, true);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.isEmpty(res.data.authorities)) {
        yield call(G.showToastrMessage, 'info', 'messages:permissions:missing');

        yield call(G.goToRoute, routesMap.loginPage);
      } else {
        const userWithAccessToken = R.assoc('access_token', token, data);

        yield put(A.receivedSessionValidationSuccess(userWithAccessToken));
        yield put(setAuthoritiesRequest(data.authorities));
      }

      G.setItemToWindow('amousCurrentUser', data);
    } else {
      yield call(failSessionSaga);
    }
  } catch (error) {
    yield call(failSessionSaga);
  }
}


// Reset password
function* handleResetPasswordRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { email, loginId, callback } = payload;

    const reqData = { email, loginId };

    const res = yield call(sendRequest, 'post', endpointsMap.forgot, { data: reqData, auth }, true);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:forgot:psw');
      yield call(G.goToRoute, routesMap.loginPage);
    } else {
      yield call(G.handleFailResponse, res, 'handleResetPasswordRequest fail');
    }

    G.callFunction(callback);

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleResetPasswordRequest exception');
  }
}

function* handleCreateNewPasswordRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { newPassword, token, callback } = payload;

    const reqData = { newPassword };
    const headers = { Authorization: `Bearer ${token}` };

    const res = yield call(sendRequest, 'post', endpointsMap.createNewPassword, { data: reqData, headers });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:reset:psw');
      yield call(G.goToRoute, routesMap.loginPage);

      yield put(A.receivedCreateNewPasswordSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateNewPasswordRequest fail');
    }

    G.callFunction(callback);

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateNewPasswordRequest exception');
  }
}

export function* handleCreateNewPasswordMobileRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { token, newPassword, callback } = payload;

    const reqData = { newPassword };
    const headers = { Authorization: `Bearer ${token}` };

    const res = yield call(sendRequest, 'post', endpointsMap.createNewPassword, { data: reqData, headers });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.goToRoute, routesMap.passwordSuccessPage);

      yield put(A.receivedCreateNewPasswordMobileSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateNewPasswordMobileRequest fail');
    }

    G.callFunction(callback);

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateNewPasswordMobileRequest exception');
  }
}

// NAL
function* getUserSsoByNalIdSaga() {
  try {
    yield put(openLoader());

    const { nalId } = G.parseQueryString();

    if (G.isNilOrEmpty(nalId)) return yield call(G.goToRoute, routesMap.loginPage);

    const res = yield call(sendRequest, 'post', endpointsMap.userSsoByNalId, G.setParamToRequestQuery('nalId', nalId));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { authorities, access_token, refresh_token } = data;

      if (R.isEmpty(authorities)) {
        yield call(G.showToastrMessage, 'info', 'messages:permissions:missing');

        yield call(G.goToRoute, routesMap.loginPage);
      } else {
        const branchGuid = data[GC.FIELD_BRANCH_UNDERSCORE_GUID];

        yield call(G.setCurrentBranchGuid, branchGuid);
        yield call(G.setAuthTokenToSession, access_token);
        yield call(G.setRefreshTokenToSession, refresh_token);

        yield put(A.receivedNalLogInSuccess(data));
        yield put(setAuthoritiesRequest(authorities));
      }

      G.setItemToWindow('amousCurrentUser', data);
    } else {
      yield call(G.goToRoute, routesMap.loginPage);
      yield call(G.handleFailResponse, res, 'getUserSsoByNalIdSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.goToRoute, routesMap.loginPage);
    yield call(G.handleException, error, 'getUserSsoByNalIdSaga exception');
  }
}

function* handleVisitNALLoginPage() {
  while (true) { // eslint-disable-line
    yield call(getUserSsoByNalIdSaga);

    break;
  }
}

// BOSCH
function* handleVisitBoshRedirectPage() {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'get', endpointsMap.losSsoRedirectUrl);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(window.open, data, '_self');
    } else {
      yield call(G.goToRoute, routesMap.loginPage);
      yield call(G.handleFailResponse, res, 'handleVisitBoshRedirectPage fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.goToRoute, routesMap.loginPage);
    yield call(G.handleException, error, 'handleVisitBoshRedirectPage exception');
  }
}

function* handleVisitBoshCallbackPage() {
  try {
    yield put(openLoader());

    const { code, state } = G.parseQueryString();

    const res = yield call(sendRequest, 'get', endpointsMap.losSsoToken, { params: { code, state } });

    const { data, status } = res;

    console.log('//////////////////////////////////////////////////////////////handleVisitBoshCallbackPage-res', res);

    if (G.isResponseSuccess(status)) {
      const { authorities, access_token, refresh_token } = data;

      if (R.isEmpty(authorities)) {
        yield call(G.showToastrMessage, 'info', 'messages:permissions:missing');

        yield call(G.goToRoute, routesMap.loginPage);
      } else {
        const branchGuid = data[GC.FIELD_BRANCH_UNDERSCORE_GUID];

        yield call(G.setCurrentBranchGuid, branchGuid);
        yield call(G.setAuthTokenToSession, access_token);
        yield call(G.setRefreshTokenToSession, refresh_token);

        yield put(A.receivedBoshLogInSuccess(data));
        yield put(setAuthoritiesRequest(authorities));
      }

      G.setItemToWindow('amousCurrentUser', data);
    } else {
      yield call(G.goToRoute, routesMap.loginPage);
      yield call(G.handleFailResponse, res, 'handleVisitBoshCallbackPage fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.goToRoute, routesMap.loginPage);
    yield call(G.handleException, error, 'handleVisitBoshCallbackPage exception');
  }
}

function* getTokenByCurrentBranchGuidRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.CURRENT_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.token, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.setAuthTokenToSession, data.access_token);
      yield call(G.setRefreshTokenToSession, data.refresh_token);
      yield call(G.setAuthTokenExpirationToSession, data.expires_in);
    } else {
      yield call(G.handleException, 'error', 'getTokenByCurrentBranchGuidRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getTokenByCurrentBranchGuidRequest exception');
  }
}

function* loginWatcherSaga() {
  yield takeEvery('*', handleDispatchAnyAction);
  yield fork(checkTokenAndRefreshTokenSetDelaySaga);
  yield takeLatest(A.sendLogInRequest, handleLoginRequest);
  yield takeLatest(sendLogOutRequest, logoutWithReloadSaga);
  yield takeLatest(A.sendLogOutRequest, logoutWithReloadSaga);
  yield takeLatest(A.checkTokenRequest, handleCheckTokenSaga);
  yield takeLatest(A.refreshTokenRequest, handleRefreshTokenSaga);
  yield takeLatest(A.sendResetPasswordRequest, handleResetPasswordRequest);
  yield takeLatest(A.sendCreateNewPasswordRequest, handleCreateNewPasswordRequest);
  yield takeLatest(receivedSwitchBranchSuccess, getTokenByCurrentBranchGuidRequest);
  yield takeLatest(A.getTokenByCurrentBranchGuidRequest, getTokenByCurrentBranchGuidRequest);
  yield takeLatest(A.sendCreateNewPasswordMobileRequest, handleCreateNewPasswordMobileRequest);
  // NAL
  yield takeLatest(GC.VISIT_NAL_LOGIN_PAGE, handleVisitNALLoginPage);
  // BOSCH
  yield takeLatest(GC.VISIT_BOSH_REDIRECT_PAGE, handleVisitBoshRedirectPage);
  yield takeLatest(GC.VISIT_BOSH_CALLBACK_PAGE, handleVisitBoshCallbackPage);
}

export default loginWatcherSaga;
