import React from 'react';
import * as R from 'ramda';
// features
import { createNewUserRequest } from '../../user/actions';
// forms
import CreateUserForm from '../../../forms/forms/create-user-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-profile
import { TableCell } from '../components/table-cells';
//////////////////////////////////////////////////

const fields = {
  [GC.FIELD_USER_LOGIN_ID]: {
    width: 200,
    name: 'titles:user-id',
  },
  [GC.FIELD_ROLE_ROLES]: {
    width: 300,
    name: 'titles:roles',
    customComponent: (row: Object) => {
      const text = R.compose(
        R.join(', '),
        R.map(R.prop(GC.FIELD_NAME)),
        R.pathOr([], ['data', GC.FIELD_ROLE_ROLES]),
      )(row);

      return <TableCell text={text} />;
    },
  },
};

export const carrierPortalLoginSettings = {
  fields,
  groupName: 'carrierPortalLogin',
  itemTitleArr: ['titles:user', 'User'],
  actionsPicker: ['goToUserProfile', 'remove'],
  formGroupTitleArr: ['titles:carrier-portal-login', 'Carrier Portal Login'],
  endpoints: {
    list: 'userListByCarrier',
    remove: 'getRemoveUserEndpoint',
  },
  modalAdditionalOptions: {
    options: {
      width: 400,
      title: `${G.getWindowLocale('titles:create-user', 'Create User')} ${
        G.getWindowLocale('titles:carrier', 'Carrier')}`,
    },
  },
  // components
  CustomForm: (props: Object) => (
    <CreateUserForm
      {...props}
      roleType={GC.USER_ROLE_TYPE_CARRIER}
      currentBranchGuid={G.getAmousCurrentBranchGuidFromWindow()}
    />
  ),
  // helpers
  customCreateOrUpdateHandler: ({ values, dispatch }: Object) => dispatch(createNewUserRequest(values)),
  makeInitialValues: (initialValues: Object = {}) => ({
    ...initialValues,
    [GC.FIELD_TYPE]: GC.USER_ROLE_TYPE_CARRIER,
  }),
};
