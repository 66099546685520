import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Form, Fieldset2 } from '../../../forms';
// feature configs
import * as H from '../helpers';
import { CONFIG_DROPDOWN_POPUP_FORM } from '../constants';
//////////////////////////////////////////////////

const defaultDropdownConfigFields = {
  [GC.FIELD_STORED_VALUE]: '',
  [GC.FIELD_DISPLAYED_VALUE]: '',
};

const validation = G.yupStringRequired.max(250, G.getShouldBeFromToCharLocaleTxt(1, 250));

const validationSchema = Yup.object().shape({
  [GC.FIELD_STORED_VALUE]: validation,
  [GC.FIELD_DISPLAYED_VALUE]: validation,
});

const dropdownConfigFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STORED_VALUE,
    inputWrapperStyles: { mb: 25, width: 300 },
    label: ['titles:stored-value', 'Stored Value'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: { width: 300 },
    fieldName: GC.FIELD_DISPLAYED_VALUE,
    label: ['titles:user-displayed-value', 'User Displayed Value'],
  },
];

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDropdownConfigFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { dropdowns, closeModal, configName, shouldUpdateItem, updateConfigsRequest } = props;

      const prevConfigValue = H.getConfigValueFromGroupStore(dropdowns, configName);

      if (G.isNotNil(shouldUpdateItem)) {
        updateConfigsRequest(H.updateDropdownValueInConfigs(values, prevConfigValue));
      } else {
        updateConfigsRequest(H.addDropdownValueToConfigs(values, prevConfigValue));
      }

      closeModal();
    },
  }),
  pure,
);

const DropdownConfigFormComponent = enhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit} id={CONFIG_DROPDOWN_POPUP_FORM}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 15 }}
        fields={dropdownConfigFieldSettings}
      />
    </Form>
  );
});

export const getDefaultDropdownConfigFormComponent = (
  configName: string,
  { dropdowns, closeModal, updateConfigsRequest }: Object,
) => (
  <DropdownConfigFormComponent
    dropdowns={dropdowns}
    configName={configName}
    closeModal={closeModal}
    updateConfigsRequest={updateConfigsRequest}
  />
);

export default DropdownConfigFormComponent;
