import * as R from 'ramda';
import MaskedInput from 'react-text-mask';
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { space, width, height } from 'styled-system';
// components
import { Switcher } from '../../components/switcher';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Toggle } from '../../forms/restyled';
import { Label, ToggleWrapper } from '../../forms/ui';
// ui
import { Box, Flex, StyledLink, ReactSelect } from '../../ui';
//////////////////////////////////////////////////

export const ConfigPageWrapper = styled.div`
  z-index: auto;
  & * {
    z-index: auto;
  }
  & form > div:last-child {
    z-index: 30
  }
`;

const flexStandartStyles = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

const inputStandartStyles = css`
  height: 30px;
  outline: none;
  line-height: 30px;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 25px;
  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

const texstareaStandartStyles = css`
  padding: 15px;
  outline: none;
  border-radius: 4px;
  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

export const ConfigGroupLeftSectionWrapper = styled.div`
  width: 320px;
  flex-grow: 0;
  display: flex;
  flex-shrink: 0;
  font-size: 15px;
  padding: 0 15px;
  font-weight: 600;
  align-items: center;
  color: ${() => G.getTheme('colors.light.black')};
  background-color: ${() => G.getTheme('colors.lightGrey')};
`;

export const ConfigGroupRightSectionWrapper = styled.div`
  ${space}
  ${flexStandartStyles};

  background-color: ${() => G.getTheme('colors.white')};
`;

export const ConfigGroupRightSectionFieldGroupWrapper = styled.div`
  ${flexStandartStyles};
  padding: 10px 0;
  @media (max-width: 1600px) {
    & > div {
      margin-bottom: 5px;
    }
  }
`;

export const ConfigGroupFieldWrapper = styled.div`
  ${flexStandartStyles};
  margin-right: 15px;
`;

export const ConfigGroupSelectFieldWrapper = styled.div`
  ${width}
  ${space}

  align-items: center;
  margin: 5px 15px 0px 0px;
  justify-content: space-between;
  display: ${({ display }: Object) => display};
  z-index: ${({ zIndex }: Object) => zIndex || 11};
`;

export const ConfigGroupModalFieldsWrapper = styled.div`
  & > div {
    margin-right: 0;
  }
  & input {
    height: 34px;
    margin: 5px 0;
    line-height: 34px;
  }
  & span {
    margin: 5px 0;
  }
`;

const renderBorderColor = (props: Object) => (
  G.ifElse(
    R.and(G.isNotNil(props.error), props.touched),
    G.getTheme('forms.inputs.borderColorErr'),
    G.getTheme('colors.dark.grey'),
  )
);

const Input = styled.input`
  ${space}
  ${width}
  ${inputStandartStyles};

  z-index: 2;
  cursor: text;
  transition: border-color 0.4s;

  background-color: ${() => G.getTheme('forms.inputs.bgColor')};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};

  &:hover {
    border-color: ${G.getTheme('colors.light.darksGrey')};
  }
`;

const Textarea = styled.textarea`
  ${space}
  ${width}
  ${height}
  ${texstareaStandartStyles};

  cursor: text;

  background-color: ${() => G.getTheme('forms.inputs.bgColor')};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};

  &:hover {
    border-color: ${G.getTheme('colors.light.darksGrey')};
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  &:after {
    content: '';
    top: 13px;
    width: 6px;
    height: 6px;
    right: 15px;
    border: solid;
    position: absolute;
    pointer-events: none;
    border-width: 0px 2px 2px 0;
    transition: border-color 0.4s;
    transform: rotate(45deg) translate(0, -60%);
    border-color: ${G.getTheme('colors.dark.grey')};
  }

  &:hover:after {
    border-color: ${G.getTheme('colors.light.darksGrey')};
  }
`;

const SelectComponent = styled.select`
  ${space}
  ${width}
  ${inputStandartStyles};

  z-index: 0;
  outline: none;
  appearance: none;
  position: relative;
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${() => G.getTheme('forms.inputs.bgColor')};
`;

const Error = styled.span`
  ${space}

  color: ${() => G.getTheme('forms.inputs.errorTextColor')};
`;

export const CustomSelectWrapper = styled.div`
  ${space}

  z-index: 15;
  & .Select {
    height: 30px;
    width: ${({ width }: Object) => width};
    &.is-focused {
      box-shadow: ${() => `0 0 5px 0 ${G.getTheme('rgbaColors.transparentPink')}`};
    }
    & .Select-control {
      height: 30px;
      display: flex;
      border-radius: 2px;
      align-items: center;
      justify-content: space-between;
      border: 1px solid ${() => G.getTheme('forms.inputs.borderColor')};
      & .Select-input {
        display: none !important;
      }
      & .Select-placeholder {
        padding: 0 15px;
        line-height: 30px;
      }
    }
  }
`;

export const errorClass = css`
  bottom: 0;
  right: 10px;
  position: absolute;
`;

export const formGroupPosition = css`
  position: relative;
`;

export const Button = styled.button`
  cursor: pointer;
  background: none;
  padding: 8px 5px;
  border-radius: 4px;
  margin: 0 5px 10px 0;
  color: ${() => G.getTheme('colors.dark.blue')};
  border: 1px solid ${() => G.getTheme('colors.light.middleGrey')};
`;

export const PreviewWrapper = styled(Box)`
  & code {
    font-family: monospace;

    & * {
      font-family: inherit;
    }
  }
`;

const renderInputField = ({
  input,
  width,
  disabled,
  placeholder,
  meta: { touched, error },
}: Object) => (
  <ConfigGroupFieldWrapper>
    <Input
      {...input}
      type='text'
      error={error}
      touched={touched}
      width={width || 232}
      disabled={disabled || false}
      placeholder={placeholder || ''}
    />
    {R.and(touched, error) && <Error ml={15}>{error}</Error>}
  </ConfigGroupFieldWrapper>
);

const renderTextareaField = ({
  input,
  width,
  height,
  disabled,
  placeholder,
  meta: { error, touched },
}: Object) => (
  <ConfigGroupFieldWrapper>
    <Textarea
      {...input}
      type='text'
      error={error}
      touched={touched}
      width={width || '500'}
      height={height || '300'}
      disabled={disabled || false}
      placeholder={placeholder || ''}
    />
    {R.and(touched, error) && <Error ml={15}>{error}</Error>}
  </ConfigGroupFieldWrapper>
);

const renderSelectGroup = ({
  input,
  width,
  options,
  disabled,
  meta: { touched, error },
}: Object) => (
  <ConfigGroupFieldWrapper>
    <SelectWrapper>
      <SelectComponent
        {...input}
        width={width || 232}
        disabled={disabled || false}
      >
        <option />
        {
          options.map((option: string, index: number) => (
            <option
              key={index}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))
        }
      </SelectComponent>
    </SelectWrapper>
    {R.and(touched, error) && <Error ml={15}>{error}</Error>}
  </ConfigGroupFieldWrapper>
);

const renderToggleField = ({
  input,
  margin,
  checked,
  disabled,
}: Object) => (
  <ConfigGroupFieldWrapper>
    <ToggleWrapper m={margin}>
      <Toggle
        {...input}
        icons={false}
        onChange={input.onChange}
        disabled={disabled || false}
        checked={G.ifElse(G.notEquals(checked, undefined), checked, input.value)} />
    </ToggleWrapper>
  </ConfigGroupFieldWrapper>
);

const getSwitcherOptions = () => [
  {
    width: 40,
    value: true,
    name: G.getWindowLocale('titles:yes', 'Yes'),
  },
  {
    width: 40,
    value: false,
    name: G.getWindowLocale('titles:no', 'No'),
  },
  {
    width: 60,
    value: 'inherited',
    name: G.getWindowLocale('titles:inherited', 'Inherited'),
  },
];

const getSwitcherIndex = (value: any) => {
  if (G.isTrue(value)) return 0;

  if (G.isFalse(value)) return 1;

  return 2;
};

const renderSwitcher = (props: Object) => (
  <ConfigGroupFieldWrapper>
    <ToggleWrapper m={props.margin}>
      <Switcher
        version={3}
        itemZIndex='1 !important'
        options={getSwitcherOptions()}
        switchElementZIndex='unset !important'
        selectedOptionIndex={getSwitcherIndex(props.input.value)}
        onSwitch={(newValue: Object) => props.input.onChange(newValue)}
      />
    </ToggleWrapper>
  </ConfigGroupFieldWrapper>
);

export class MultiselectFieldComponent extends PureComponent {
  render() {
    const { input, label, width, zIndex, display, options, fieldClass, labelMargin,
      labelPosition, labelFontSize, closeMenuOnSelect } = this.props;

    const { input: { value, onChange, onBlur } } = this.props;
    const { meta: { touched, error } } = this.props;

    return (
      <ConfigGroupSelectFieldWrapper
        mx={10}
        zIndex={zIndex}
        width={width || 232}
        display={display || 'block'}
      >
        {
          R.equals(labelPosition, 'left') &&
          <Label css={fieldClass} fontSize={labelFontSize} m={labelMargin}>{label}</Label>
        }
        <ReactSelect
          {...input}
          height={36}
          value={value}
          isMulti={true}
          options={options}
          onBlur={() => onBlur([...value])}
          closeMenuOnSelect={closeMenuOnSelect}
          onChange={(newValue: Object) => onChange(newValue.map((item: Object) => item.value))}
        />
        {
          R.and(touched, error) && <Error>{error}</Error>
        }
      </ConfigGroupSelectFieldWrapper>
    );
  }
}

export const EmailsComponent = ({ p, emails }: Object) => (
  <Flex flexWrap='wrap'>
    {
      emails.map((email: string, index: number) => (
        <StyledLink
          key={index}
          target='_blank'
          p={R.or(p, '5px')}
          wordBreak='break-all'
          href={`mailto:${email}`}
          color={G.getTheme('colors.light.blue')}
        >
          {email}{R.and(R.lt(R.inc(index), R.length(emails)), ',')}
        </StyledLink>
      ))
    }
  </Flex>
);

export const ReactSelectComponent = ({ value, options, placeholder, handleChange }: Object) => (
  <Box p='5px 10px' width={250}>
    <ReactSelect
      height={32}
      value={value}
      isClearable={true}
      placeholder={placeholder}
      options={R.or(options, [])}
      onChange={(option: Object) => handleChange(R.pathOr('', ['value'], option))}
    />
  </Box>
);

const renderMaskedTimeInput = ({ input, width }: Object) => {
  const value = G.setCorrectTimeInitialValue(G.getValueFromObject(input));

  const { mask, pipe, placeholder } = R.path([GC.DATE_TIME_FORMAT_US, 'time'], GC.dateTimeFormatMap);

  return (
    <MaskedInput
      {...input}
      pipe={pipe}
      mask={mask}
      value={value}
      render={(ref: Object, props: Object) => (
        <Input {...props} ref={ref} width={R.or(width, 232)} placeholder={placeholder} />
      )}
    />
  );
};

export const renderFormFieldComponent = (type: string) => {
  switch (type) {
    case ('input'):
      return renderInputField;
    case ('textarea'):
      return renderTextareaField;
    case ('toggle'):
      return renderToggleField;
    case ('switcher'):
      return renderSwitcher;
    case ('select'):
      return renderSelectGroup;
    case ('time'):
      return renderMaskedTimeInput;
    default:
      return renderInputField;
  }
};
