import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, lifecycle, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { Tabs2 } from '../../components/tabs-mui';
import EditReport from '../../components/edit-report';
import { TitlePanel } from '../../components/title-panel';
import { PageActions } from '../../components/page-actions';
import CreateCarrier from '../../components/create-carrier';
import { ConfirmComponent } from '../../components/confirm';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import PC from '../permission/role-permission';
import { AuthWrapper } from '../permission/index';
import { makeSelectCurrentBranch } from '../branch/selectors';
import { makeSelectAuthorizedLoadBoards } from '../load-board/selectors';
import ReferenceFormComponent from '../reference/components/reference-form';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../reference/selectors';
import { getCarrierRateRequestReportDataRequest } from '../carrier-rate-request-report/actions';
import { getReportDataRequest as getCarrierStatisticReportDataRequest } from '../carrier-statistic-report/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { accountingIntegrationOptions } from '../../helpers/options';
// hocs
import { withShowListIssues, withSetExpandedContainerOptions } from '../../hocs';
// icons
import * as I from '../../svgs';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { IconWrapper, ListWrapper, ZOrderWrapper } from '../../ui';
// feature carrier
import { FILTER_PARAMS } from './settings/filter-params';
import CarrierActions from './components/element-actions';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import {
  getTabs,
  getSynchronizationType,
  getSelectDropDownFormModal,
  isMultipleLoadBoardSynchronizations,
  showCarrierLoadBoardSynchronization,
  carrierLoadBoardSynchronizationSources,
  getAuthorizedCarrierLoadBoardSynchronization,
} from './helpers';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectOnboardingPackage,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  createCarrierRequest,
  setQuickFilterParams,
  startCarrierInsurances,
  syncCarrierListRequest,
  resetListAndPagination,
  createCarrierRefRequest,
  exportReportDataRequest,
  changeDefaultReportRequest,
  syncCarrierByReportRequest,
  addToNetworkCarriersRequest,
  addCarriersToWatchListRequest,
  allowBookItNowCarriersRequest,
  forbidBookItNowCarriersRequest,
  removeFromNetworkCarriersRequest,
  addSyncCarriersToWatchListRequest,
  addToNetworkCarriersByReportRequest,
  sendCarrierListToIntegrationRequest,
  addCarriersToWatchListByReportRequest,
  allowBookItNowCarriersByReportRequest,
  createCarrierSaferWatchMassUpdateRequest,
  addSyncCarriersToWatchListByReportRequest,
  createCarrierSaferWatchMassUpdateByReportRequest,
} from './actions';
//////////////////////////////////////////////////

const getSelectedList = R.compose(
  R.values,
  R.map(({ guid }: string) => guid),
  R.filter(({ selected }: boolean) => selected),
);

const iconColor = G.getTheme('colors.light.mainLight');

const enhance = compose(
  withSetExpandedContainerOptions,
  withShowListIssues({ listName: 'carrier', getListRequestActionName: 'getItemListRequest' }),
  reportEnhancer,
  withHandlers({
    handleOpenLoadHistoryByCarrierGuid: (props: Object) => (carrier: string) => {
      const { guid, name } = carrier;
      const { closeFixedPopup, handleSetExpandedContainerOptions, getCarrierRateRequestReportDataRequest } = props;

      closeFixedPopup();
      getCarrierRateRequestReportDataRequest(guid);

      handleSetExpandedContainerOptions({
        carrierName: name,
        componentType: 'loadHistory',
      });
    },
    handleOpenLoadStatisticByCarrierGuid: (props: Object) => (carrier: string) => {
      const { guid, name } = carrier;
      const { closeFixedPopup, handleSetExpandedContainerOptions, getCarrierStatisticReportDataRequest } = props;

      closeFixedPopup();
      getCarrierStatisticReportDataRequest(guid);

      handleSetExpandedContainerOptions({
        carrierName: name,
        componentType: 'loadStatistic',
      });
    },
    handleOpenCarrierScorecardsByCarrierGuid: (props: Object) => ({ guid, name }: Object) => {
      const { closeFixedPopup, setExpandedContainerOptions } = props;

      closeFixedPopup();

      setExpandedContainerOptions({
        opened: true,
        options: { guid, name },
        componentType: 'carrierScorecards',
      });
    },
    handleSendCarrierListToIntegration: (props: Object) => (guid: string) => {
      const {
        itemList,
        openModal,
        closeModal,
        closeFixedPopup,
        sendCarrierListToIntegrationRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      let carrierGuids = getSelectedList(itemList);

      if (G.isString(guid)) carrierGuids = R.of(Array, guid);

      if (G.isNilOrEmpty(carrierGuids)) {
        const message = G.getWindowLocale('messages:select-carrier', 'Please, select a Carrier!');

        return G.showToastrMessageSimple('info', message);
      }

      const submitAction = (integrationType: string) =>
        sendCarrierListToIntegrationRequest({ carrierGuids, integrationType });

      const componentProps = {
        submitAction,
        fieldWidth: 300,
        optionRequired: true,
        cancelAction: closeModal,
        options: accountingIntegrationOptions(),
        fieldLabel: G.getWindowLocale('titles:integration-type', 'Integration Type'),
      };

      const modal = getSelectDropDownFormModal({ width: 330 }, componentProps);

      openModal(modal);
    },
  }),
  withHandlers({
    handleSyncCarrierByReport: (props: Object) => () => {
      const {
        itemList,
        selectedReport,
        syncCarrierByReportRequest,
        createCarrierSaferWatchMassUpdateByReportRequest,
      } = props;

      if (G.isNilOrEmpty(itemList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-carrier', 'Please, select a Carrier!'),
        );
      }

      const { fields, orderFields, searchCriteria } = selectedReport;

      const action = G.ifElse(
        G.showCarrierSynchronization(),
        syncCarrierByReportRequest,
        createCarrierSaferWatchMassUpdateByReportRequest,
      );

      action({ fields, orderFields, searchCriteria });
    },
    handleSyncCarrierList: (props: Object) => () => {
      const { itemList, syncCarrierListRequest, createCarrierSaferWatchMassUpdateRequest } = props;

      const selectedList = R.compose(
        R.values,
        R.map(({ guid }: string) => guid),
        R.filter(({ selected }: boolean) => selected),
      )(itemList);

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-carrier', 'Please, select a Carrier!'),
        );
      }

      const action = G.ifElse(
        G.showCarrierSynchronization(),
        syncCarrierListRequest,
        createCarrierSaferWatchMassUpdateRequest,
      );

      return action(selectedList);
    },
    handleAddCarriersToWatchList: (props: Object) => () => {
      const { itemList, addCarriersToWatchListRequest, addSyncCarriersToWatchListRequest } = props;

      const selectedList = R.compose(
        R.values,
        R.map(({ guid }: string) => guid),
        R.filter(({ selected }: boolean) => selected),
      )(itemList);

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-carrier', 'Please, select a Carrier!'),
        );
      }

      const action = G.ifElse(
        G.showCarrierSynchronization(),
        addSyncCarriersToWatchListRequest,
        addCarriersToWatchListRequest,
      );

      action(selectedList);
    },
    handleAddCarriersToWatchListByReport: (props: Object) => () => {
      const {
        itemList,
        selectedReport,
        addCarriersToWatchListByReportRequest,
        addSyncCarriersToWatchListByReportRequest } = props;

      if (G.isNilOrEmpty(itemList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-carrier', 'Please, select a Carrier!'),
        );
      }

      const { fields, orderFields, searchCriteria } = selectedReport;

      const action = G.ifElse(
        G.showCarrierSynchronization(),
        addSyncCarriersToWatchListByReportRequest,
        addCarriersToWatchListByReportRequest,
      );

      action({ fields, orderFields, searchCriteria });
    },
    handleAddReferenceList: (props: Object) => () => {
      const { itemList, openModal, createCarrierRefRequest } = props;

      const isSelected = (item: Object) => item.selected;

      const selectedList = R.map(
        (item: Object) => item.guid,
        R.filter(isSelected, itemList),
      );

      if (R.isEmpty(selectedList)) {
        return toastr.info(G.getWindowLocale(
          'messages:select-carrier',
          'Please, select a Carrier!',
        ));
      }

      const multiAddingEntRef = (values: Object) => {
        const params = R.assoc('primaryObjectGuids', selectedList, values);
        createCarrierRefRequest(params);
      };

      const modalContent = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_CARRIER}
          submitAction={(params: Object) => multiAddingEntRef(params)}
        />
      );

      const modal = G.createAddReferenceModalOptions(modalContent);

      openModal(modal);
    },
    handleCreateCarrier: (props: Object) => () => {
      const { openModal } = props;

      const component = <CreateCarrier />;

      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          version: 2,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:add-new-carrier', 'Create Carrier'),
        },
      };

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => ({ currentTarget }: Object, carrier: Object) => {
      const {
        openModal,
        closeModal,
        openFixedPopup,
        closeFixedPopup,
        deleteItemRequest,
        handleOpenLoadHistoryByCarrierGuid,
        handleSendCarrierListToIntegration,
        handleOpenLoadStatisticByCarrierGuid,
        handleOpenCarrierScorecardsByCarrierGuid,
      } = props;

      return (
        openFixedPopup({
          position: 'right',
          el: currentTarget,
          content: (
            <CarrierActions
              carrier={carrier}
              openModal={openModal}
              closeModal={closeModal}
              closeFixedPopup={closeFixedPopup}
              deleteCarrierRequest={(guid: string) => deleteItemRequest(guid)}
              handleSendCarrierListToIntegration={handleSendCarrierListToIntegration}
              handleOpenLoadHistoryByCarrierGuid={() => handleOpenLoadHistoryByCarrierGuid(carrier)}
              handleOpenLoadStatisticByCarrierGuid={() => handleOpenLoadStatisticByCarrierGuid(carrier)}
              handleOpenCarrierScorecardsByCarrierGuid={() => handleOpenCarrierScorecardsByCarrierGuid(carrier)}
            />
          ),
        })
      );
    },
    handleGetCarrierSynchronizationAction: (props: Object) => (actionName: string, byReport: boolean) => {
      const {
        itemList,
        openModal,
        closeModal,
        selectedReport,
        authorizedLoadBoards,
      } = props;

      const selectedList = getSelectedList(itemList);
      const listToCheck = G.ifElse(G.isTrue(byReport), itemList, selectedList);

      if (G.isNilOrEmpty(listToCheck)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-carrier', 'Please, select a Carrier!'),
        );
      }

      const args = G.ifElse(
        G.isTrue(byReport),
        R.pick(['fields', 'orderFields', 'searchCriteria'], selectedReport),
        { [GC.FIELD_CARRIER_GUIDS]: selectedList },
      );

      const synchronizationAction = (source: string) => G.callFunctionWithArgs(
        G.getPropFromObject(actionName, props),
        R.assoc(GC.FIELD_SOURCE, source, args),
      );

      if (isMultipleLoadBoardSynchronizations(authorizedLoadBoards)) {
        let options = carrierLoadBoardSynchronizationSources;

        if (R.includes(
          actionName,
          [
            'allowBookItNowCarriersRequest',
            'forbidBookItNowCarriersRequest',
            'allowBookItNowCarriersByReportRequest',
          ],
        )) {
          options = R.reject(R.propEq(GC.EXTERNAL_LOAD_BOARD_NEWTRUL, GC.FIELD_VALUE), options);
        }

        const componentProps = {
          options,
          fieldWidth: 300,
          optionRequired: true,
          cancelAction: closeModal,
          fieldLabel: G.getWindowLocale('titles:source', 'Source'),
          submitAction: (source: string) => {
            closeModal();
            synchronizationAction(source);
          },
        };

        const modal = getSelectDropDownFormModal({ width: 330 }, componentProps);

        return openModal(modal);
      }

      return synchronizationAction(getAuthorizedCarrierLoadBoardSynchronization(authorizedLoadBoards));
    },
    handleAllowBookItNowCarriers: (props: Object) => () => {
      const { itemList, allowBookItNowCarriersRequest } = props;

      const selectedList = getSelectedList(itemList);

      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-carrier', 'Please, select a Carrier!'),
        );
      }

      return allowBookItNowCarriersRequest(selectedList);
    },
    handleEdit: () => (_: any, carrier: Object) => {
      const guid = G.getGuidFromObject(carrier);

      G.goToCarrierProfileByConfig(guid);
    },
    handleDelete: (props: Object) => ({ currentTarget }: Object, carrier: Object) => {
      const guid = G.getGuidFromObject(carrier);

      const { openModal, deleteItemRequest } = props;

      const confirmationContent = (
        <ConfirmComponent
          textLocale={
            G.getWindowLocale('messages:delete-confirmation-carrier', 'Are you sure you want delete carrier?')
          }
        />
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteItemRequest(guid);
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          hasIssues={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(modalContent);

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const { getItemListRequest, expandedContainerOptions: { opened } = {} } = this.props;

      const { expandedContainerOptions: { opened: prevOpened, componentType, visitPageGuid } = {} } = prevProps;

      if (G.isAllTrue(
        prevOpened,
        G.isFalse(opened),
        G.isNotNilAndNotEmpty(visitPageGuid),
        R.equals(componentType, 'carrierProfile'),
      )) getItemListRequest({ guids: R.of(Array, visitPageGuid) });
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    handleDelete,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleShowListIssues,
    handleTableTitleFilter,
    setExpandedContainerOptions,
  } = props;

  const isAmousUserSuperAdminFromWindow = G.getIsAmousUserSuperAdminFromWindow();

  if (R.not(selectedReport)) return null;

  const elementActionsComponent = (carrier: Object) => {
    const cond = R.not(R.or(
      isAmousUserSuperAdminFromWindow,
      G.isTrue(R.prop('editable', carrier)),
    ));

    if (cond) return null;

    return (
      <AuthWrapper has={[PC.CARRIER_READ, PC.CARRIER_WRITE]}>
        <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, carrier)}>
          {I.threeDots()}
        </IconWrapper>
      </AuthWrapper>
    );
  };

  const allChecked = G.isAllChecked(itemList);
  const showIssues = G.isTrue(selectedReport.showIssues);

  const additionalTableProps = G.ifElse(
    R.or(showIssues, isAmousUserSuperAdminFromWindow),
    {
      checkBoxCellJustifyContent: 'flex-start',
      checkBoxCellWidth: G.ifElse(R.and(showIssues, isAmousUserSuperAdminFromWindow), 120, 90),
    },
    {},
  );

  const tableSettingsToUse = {
    ...G.getTableSettingsWithMaxHeightByConditions({ reportList, filterParams, selectedReport, tableSettings }),
    ...additionalTableProps,
  };

  const actionButtons = [
    {
      iconName: 'pencil',
      permissions: [PC.CARRIER_WRITE],
      action: (guid: string) => setExpandedContainerOptions({
        opened: true,
        visitPageGuid: guid,
        componentType: GC.PAGE_CARRIER_PROFILE,
      }),
    },
    {
      iconName: 'trash',
      action: handleDelete,
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
    },
  ];

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleShowListIssues,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: tableSettingsToUse,
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS)),
  };

  return <Table {...data} />;
};

const CarrierListComponent = (props: Object) => {
  const {
    itemList,
    activeTab,
    totalCount,
    selectedReport,
    handleSetActiveTab,
    handleCreateCarrier,
    authorizedLoadBoards,
    handleSyncCarrierList,
    startCarrierInsurances,
    handleAddReferenceList,
    handleSyncCarrierByReport,
    handleAddCarriersToWatchList,
    handleSendCarrierListToIntegration,
    handleAddCarriersToWatchListByReport,
    handleGetCarrierSynchronizationAction,
  } = props;

  const listActionsOpt = [
    {
      type: 'massAction',
      action: handleAddReferenceList,
      permissions: [PC.CARRIER_WRITE],
      icon: I.plusRound(iconColor, 25, 25),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference Type'),
    },
    {
      type: 'massAction',
      permissions: [PC.CARRIER_WRITE],
      icon: I.gear(iconColor, 25, 25),
      action: handleSendCarrierListToIntegration,
      text: G.getWindowLocale('actions:send-to-integration', 'Send to Integration'),
    },
  ];

  let pageHeaderActions = [];

  if (R.or(G.showSaferWatch(), G.showCarrierSynchronization())) {
    const synchronizationActions = {
      title: getSynchronizationType(),
      actions: [
        {
          action: handleSyncCarrierList,
          permissions: [PC.CARRIER_WRITE],
          text: `${G.getWindowLocale('titles:sync-with', 'Sync with')} ${getSynchronizationType()}`,
        },
        {
          permissions: [PC.CARRIER_WRITE],
          action: handleSyncCarrierByReport,
          text: `${G.getWindowLocale('titles:sync-with', 'Sync with')} ${getSynchronizationType()} ${G.getWindowLocale('titles:by-report', 'By Report')}`,
        },
        {
          permissions: [PC.CARRIER_WRITE],
          action: handleAddCarriersToWatchList,
          text: G.getWindowLocale('actions:add-to-watch-list', 'Add to Watch List'),
        },
        {
          permissions: [PC.CARRIER_WRITE],
          action: handleAddCarriersToWatchListByReport,
          text: G.getWindowLocale('actions:add-to-watch-list-by-report', 'Add To Watch List By Report'),
        },
      ],
    };

    pageHeaderActions = R.of(Array, synchronizationActions);
  }

  if (showCarrierLoadBoardSynchronization(authorizedLoadBoards)) {
    const loadBoardSynchronizationActions = {
      title: G.getWindowLocale('titles:load-board-synchronization', 'Load Board Synchronization'),
      actions: [
        {
          permissions: [PC.CARRIER_WRITE],
          text: G.getWindowLocale('titles:add-to-network', 'Add to Network'),
          action: () => handleGetCarrierSynchronizationAction('addToNetworkCarriersRequest'),
        },
        {
          permissions: [PC.CARRIER_WRITE],
          text: G.getWindowLocale('titles:add-to-network-by-report', 'Add to Network by Report'),
          action: () => handleGetCarrierSynchronizationAction('addToNetworkCarriersByReportRequest', true),
        },
        {
          permissions: [PC.CARRIER_WRITE],
          text: G.getWindowLocale('titles:allow-book-it-now', 'Allow Book it Now'),
          action: () => handleGetCarrierSynchronizationAction('allowBookItNowCarriersRequest'),
        },
        {
          permissions: [PC.CARRIER_WRITE],
          text: G.getWindowLocale('titles:allow-book-it-now-by-report', 'Allow Book it Now By Report'),
          action: () => handleGetCarrierSynchronizationAction('allowBookItNowCarriersByReportRequest', true),
        },
        {
          permissions: [PC.CARRIER_WRITE],
          text: G.getWindowLocale('titles:remove-from-network', 'Remove From Network'),
          action: () => handleGetCarrierSynchronizationAction('removeFromNetworkCarriersRequest'),
        },
        {
          permissions: [PC.CARRIER_WRITE],
          text: G.getWindowLocale('titles:forbid-book-it-now', 'Forbid Book it Now'),
          action: () => handleGetCarrierSynchronizationAction('forbidBookItNowCarriersRequest'),
        },
      ],
    };

    pageHeaderActions = R.append(loadBoardSynchronizationActions, pageHeaderActions);
  }

  const selectedListLength = R.length(R.filter(({ selected }: Object) => selected, R.or(itemList, [])));

  const handleClickTab = (tabIndex: number) => {
    if (R.equals(tabIndex, 1)) startCarrierInsurances();

    handleSetActiveTab(tabIndex);
  };

  const customTitleComponent = (
    <Tabs2
      count={totalCount}
      activeTab={activeTab}
      tabs={getTabs(activeTab)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          type={PC.CARRIER_REPORT}
          withoutQuickFilter={true}
          usedReport={selectedReport}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          pageHeaderActions={pageHeaderActions}
          selectedListLength={selectedListLength}
          customTitleComponent={customTitleComponent}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.CARRIER_WRITE]}>
        <PageActions
          count={selectedListLength}
          listActions={listActionsOpt}
          hideMainActionIfBranchTypeCustomer={true}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            action: handleCreateCarrier,
            hideIfBranchTypeCustomer: true,
            permissions: [PC.CREATE_CARRIER_EXECUTE],
            text: G.getWindowLocale('actions:create-carrier', 'Create Carrier'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  currentBranch: makeSelectCurrentBranch(state),
  sendPackage: makeSelectOnboardingPackage(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  authorizedLoadBoards: makeSelectAuthorizedLoadBoards(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReports,
  closeModal,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  createCarrierRequest,
  setQuickFilterParams,
  startCarrierInsurances,
  syncCarrierListRequest,
  resetListAndPagination,
  createCarrierRefRequest,
  exportReportDataRequest,
  changeDefaultReportRequest,
  syncCarrierByReportRequest,
  addToNetworkCarriersRequest,
  addCarriersToWatchListRequest,
  allowBookItNowCarriersRequest,
  forbidBookItNowCarriersRequest,
  removeFromNetworkCarriersRequest,
  addSyncCarriersToWatchListRequest,
  addToNetworkCarriersByReportRequest,
  sendCarrierListToIntegrationRequest,
  getCarrierStatisticReportDataRequest,
  addCarriersToWatchListByReportRequest,
  allowBookItNowCarriersByReportRequest,
  getCarrierRateRequestReportDataRequest,
  createCarrierSaferWatchMassUpdateRequest,
  addSyncCarriersToWatchListByReportRequest,
  createCarrierSaferWatchMassUpdateByReportRequest,
})(enhance(CarrierListComponent));
