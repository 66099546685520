import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import React, { memo, useState, useCallback } from 'react';
// components
import DimmerComponent from '../../../components/loader/dimmer';
// helpers/constants
import * as G from '../../../helpers';
// hooks
import { useModalAndLoaderActions } from '../../../hooks';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import GeneralDetails from '../forms/general-details';
import VendorTabsGroups from './components/tabs-group';
import { generalDetailsSettings } from './settings/general-details';
import FleetProfileWrapper from '../components/fleet-profile-wrapper';
import { useFleetProfileTabsView } from '../hooks/use-fleet-profile-tabs-view';
import { setInitialState, updateVendorGeneralDetailsRequest } from '../actions';
import { useFleetProfileWrapperActions } from '../hooks/use-fleet-profile-wrapper-actions';
import { makeSelectBranchConfigs, makeSelectCollapsedGroup, makeSelectVendorGeneralDetails } from '../selectors';
//////////////////////////////////////////////////

const VendorGeneralDetails = memo((props: Object) => {
  const { initialValues: { name } } = props;

  const title = `${G.getWindowLocale('titles:fleet-vendor', 'Fleet: Vendor')} ${name}`;

  const vendorProps = {
    ...generalDetailsSettings,
    title,
    type: 'vendor',
    hasPhoto: true,
    showStatus: false,
    hasAssignment: false,
  };

  return <GeneralDetails {...props} {...vendorProps} />;
});

const FleetProfileVendor = ({ handleGoBack, expandedContainer, closeExpandedContainer }: Object) => {
  const [saveAndClose, setSaveAndClose] = useState(false);

  const branchConfigs = useSelector(makeSelectBranchConfigs());
  const collapsedGroup = useSelector(makeSelectCollapsedGroup());
  const generalDetails = useSelector(makeSelectVendorGeneralDetails());

  const dispatch = useDispatch();

  const commonActions = useModalAndLoaderActions();

  const { closeModal } = commonActions;

  const handleClose = useCallback(() => {
    dispatch(setInitialState());

    if (R.and(G.isFunction(handleGoBack), G.isArray(saveAndClose))) return handleGoBack();

    if (G.isFunction(closeExpandedContainer)) return closeExpandedContainer();

    G.goToRoute(routesMap.fleetVendorList);
  }, [saveAndClose]);

  const { handleToggleFormGroupTable, handleUpdateGeneralDetails } = useFleetProfileWrapperActions({
    dispatch,
    closeModal,
    handleClose,
    saveAndClose,
    generalDetails,
    setSaveAndClose,
    updateGeneralDetailsRequest: updateVendorGeneralDetailsRequest,
  });

  const { setTabsView, tabsCollapsedView } = useFleetProfileTabsView();

  const defaultProps = {
    ...commonActions,
    tabsCollapsedView,
    fleetType: 'vendor',
  };

  const guid = G.getGuidFromObject(generalDetails);

  if (G.isNilOrEmpty(guid)) return <DimmerComponent tableCount={3} rectangleCount={4} />;

  return (
    <FleetProfileWrapper
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      setSaveAndClose={setSaveAndClose}
      expandedContainer={expandedContainer}
    >
      <VendorGeneralDetails
        {...defaultProps}
        saveAndClose={saveAndClose}
        initialValues={generalDetails}
        setSaveAndClose={setSaveAndClose}
        submitAction={handleUpdateGeneralDetails}
      />
      <VendorTabsGroups
        {...defaultProps}
        primaryObjectGuid={guid}
        setTabsView={setTabsView}
        branchConfigs={branchConfigs}
        collapsedGroup={collapsedGroup}
        handleToggleFormGroupTable={handleToggleFormGroupTable}
        handleUpdateGeneralDetails={handleUpdateGeneralDetails}
        vendorBranchGuid={G.getBranchGuidFromObject(generalDetails)}
      />
    </FleetProfileWrapper>
  );
};

export default FleetProfileVendor;
