import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 360,
};

const fieldsWrapperStyles = {
  flexDirection: 'column',
};

export const fields = {
  [GC.FIELD_PRIMARY]: {
    width: 100,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:primary',
  },
  [GC.FIELD_CONTACT_NAME]: {
    isRequired: true,
    name: 'titles:contact-name',
  },
  [GC.FIELD_EMAIL]: {
    name: 'titles:email',
    isRequired: ({ values }: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_PHONE, values)),
  },
  [GC.FIELD_PHONE]: {
    width: 150,
    type: 'phoneNumber',
    name: 'titles:primary-phone',
    isRequired: ({ values }: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_EMAIL, values)),
  },
  [GC.FIELD_PHONE_EXTENSION]: {
    width: 100,
    type: 'number',
    name: 'titles:phone-extension',
  },
  [GC.FIELD_CARRIER_TERMINAL_CONTACT_SECONDARY_PHONE]: {
    width: 150,
    type: 'phoneNumber',
    name: 'titles:secondary-phone',
  },
  [GC.FIELD_FAX]: {
    width: 150,
    name: 'titles:fax',
  },
};

const validationSchema = Yup.lazy(({ email, phone }: Object) => {
  let schema = {
    [GC.FIELD_EMAIL]: Yup.string()
      .nullable(true)
      .required(G.getEmailOrPrimaryPhoneMustBeFilled())
      .email(G.getShouldBeEmailLocaleTxt()),
    [GC.FIELD_FAX]: Yup.string()
      .nullable(true)
      .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
    [GC.FIELD_PHONE]: Yup.string()
      .nullable(true)
      .required(G.getEmailOrPrimaryPhoneMustBeFilled())
      .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
    [GC.FIELD_PHONE_EXTENSION]: Yup.string()
      .nullable(true)
      .max(5, G.getShouldBeFromToCharLocaleTxt(0, 5)),
    [GC.FIELD_CARRIER_TERMINAL_CONTACT_SECONDARY_PHONE]: Yup.string()
      .nullable(true)
      .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
    [GC.FIELD_CONTACT_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .min(1, G.getShouldBeFromToLocaleTxt(0, 50))
      .max(50, G.getShouldBeFromToLocaleTxt(1, 50)),
  };

  if (G.isNotNilAndNotEmpty(email)) {
    schema = R.assoc(
      [GC.FIELD_PHONE],
      Yup.string().nullable(true).matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
      schema,
    );
  }

  if (G.isNotNilAndNotEmpty(phone)) {
    schema = R.assoc(
      [GC.FIELD_EMAIL],
      Yup.string().nullable(true).email(G.getShouldBeEmailLocaleTxt()),
      schema,
    );
  }

  return Yup.object().shape(schema);
});

export const terminalContactSettings = {
  fields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'terminalContact',
  primaryObjectGuidKey: 'carrierTerminalGuid',
  itemTitleArr: ['titles:contact', 'Contact'],
  formGroupTitleArr: ['titles:contact-details', 'Contact Details'],
  endpoints: {
    list: 'carrierTerminalContactList',
    remove: 'getTerminalContactEndpoint',
    createOrUpdate: 'carrierTerminalContact',
  },
  // helpers
  makeRequestPayload: ({ primaryObjectGuid }: Object) => ({
    params: { [GC.FIELD_CARRIER_TERMINAL_GUID]: primaryObjectGuid },
  }),
};
