import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// feature carrier
import ContractDiscountForm from '../components/contract-discount-form';
//////////////////////////////////////////////////

export const enhanceContractDiscount = compose(
  withHandlers({
    handleAddDiscount: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        contractVariableValues,
        addContractVariableValues,
      } = props;

      const modalContext = (
        <ContractDiscountForm
          closeModal={closeModal}
          discounts={contractVariableValues}
          submitAction={addContractVariableValues}
        />
      );

      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 400,
          height: 'auto',
          title: G.getWindowLocale('titles:add-discount', 'Add Discount'),
        },
      };

      openModal(modal);
    },
    handleEditDiscount: (props: Object) => (entity: Object) => {
      const {
        openModal,
        closeModal,
        contractVariableValues,
        editContractVariableValues,
      } = props;

      const modalContext = (
        <ContractDiscountForm
          editMode={true}
          initialValues={entity}
          closeModal={closeModal}
          discounts={contractVariableValues}
          submitAction={editContractVariableValues}
        />
      );

      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 400,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-discount', 'Edit Discount'),
        },
      };

      openModal(modal);
    },
    handleDeleteDiscount: ({ contractVariableValues, deleteContractVariableValues }: Object) => (entity: Object) => {
      const newValues = R.filter(
        ({ guid }: Object) => G.notEquals(guid, G.getGuidFromObject(entity)),
        contractVariableValues,
      );

      deleteContractVariableValues(newValues);
    },
  }),
  pure,
);
