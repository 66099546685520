import React from 'react';
// components
import { SearchMatch } from '../../../components/search-match';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const SearchMatchComponent = () => {
  const description = '<SearchMatch /> component is designed to highlight matches in a text string based on a specified search text.';

  const usage = (
    <ul>
      <li>Parameter `<i>value</i>` is the text value to be displayed.</li>
      <li>Parameter `<i>color</i>` is a text color for the matched portion.</li>
      <li>Parameter `<i>maxWidth</i>` is the maximum width for the component.</li>
      <li>Parameter `<i>bgColor</i>` is a background color for the matched portion.</li>
      <li>Parameter `<i>searchText</i>` is the search text used to identify and highlight matches.</li>
    </ul>
  );

  const mockupText = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry';

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='SearchMatch'
      description={description}
      path='src/components/search-match'
    >
      <SearchMatch
        value={mockupText}
        searchText='dummy text'
      />
    </ComponentStoryWrapper>
  );
};

export default SearchMatchComponent;
