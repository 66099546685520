import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature audit2
import * as A from './actions';
import { makeSelectPagination, makeSelectObjGuidAndAuditType } from './selectors';
//////////////////////////////////////////////////

function* handleGetAuditListSaga({ payload }: Object = {}) {
  try {
    let extraOptions = R.pick([GC.FIELD_TYPE, GC.FIELD_OBJECT_GUID], payload);

    if (R.isEmpty(extraOptions)) {
      extraOptions = yield select(makeSelectObjGuidAndAuditType());
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setAuditLoading(true));

    const pagination = yield select(makeSelectPagination());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        ...pagination,
        ...extraOptions,
        fields: [],
        orderFields: [],
        searchCriteria: [],
        [GC.CURRENT_BRANCH]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.auditList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedAuditListSuccess(R.mergeRight(data, extraOptions)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAuditListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetAuditListSaga exception');
  } finally {
    yield put(A.setAuditLoading(false));
  }
}

function* audit2WatcherSaga() {
  yield takeLatest(A.getAuditListRequest, handleGetAuditListSaga);
}

export default audit2WatcherSaga;

export { handleGetAuditListSaga };
