import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { width, space, fontSize } from 'styled-system';
// forms
import { FormGroupTitle } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { StickedBox } from '../../../ui';
//////////////////////////////////////////////////

export const FormWrapper = styled.div`
  & + div {
    padding: 5px;
    position: static;
  }
  & .Select-control {
    position: ${({ fieldPosition }: Object) => fieldPosition};
  }
`;

export const LocationFieldsWrapper = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: ${() => G.getTheme('colors.light.lightGrey')};
  & + div {
    margin-left: 16px;
  }
`;

export const LocationSectionWrapper = styled.div`
  display: flex;
  padding: 10px;
  border-top: solid 1px ${() => G.getTheme('colors.light.darkGrey')};
`;

export const DriverCompensationForm = styled.div`
overflow: auto;
max-height: 90vh;
  & form {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const DriverWrapper = styled.div`
  ${space}

  & input,
  & textarea {
    z-index: auto;
  }
`;

export const EditDriverWrapper = styled(DriverWrapper)`
  height: ${({ height }: Object) => height};
  overflow-y: ${({ overflowY }: Object) => overflowY};
  background: ${({ background }: Object) => background};

  & form > section {
    &:nth-child(2) {
      z-index: unset;
    }

  }
  & div.react-datepicker-popper[data-placement^="top"],
  & div.react-datepicker-popper[data-placement^="bottom"] {
    margin-left: 12px;
    & div.react-datepicker__month-container {
      width: 226px;
    }
  }
`;

export const CustomFormGroupTitle = styled(FormGroupTitle)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  & #dropdown-branch-filter {
    height: 20px;
  }
  & #dropdown-branch-filter + div {
    color: ${() => G.getTheme('downFilter.textColor')};
  }
  & div.react-datepicker-popper {
    z-index: 12;
    left: auto !important;
    right: -15px !important;
    &[data-placement^="bottom"] {
      width: 300px !important;
    }
  }
`;


export const Title = styled.div`
  ${space}

  font-size: 14px;
  font-weight: bold;

  color: ${() => G.getTheme('colors.dark.blue')};
`;

export const SimpleWrapper = styled.div`
  ${width}
  ${space}

  transition: width 0.2s linear;
  height: ${({ height }: Object) => height};
  z-index: ${({ zIndex }: Object) => zIndex};
  justify-content: ${({ jc }: Object) => jc};
  min-width: ${({ minWidth }: Object) => minWidth};
  min-height: ${({ minHeight }: Object) => minHeight};
  display: ${({ display }: Object) => R.or(display, 'flex')};
  flex-direction: ${({ flexDirection }: Object) => flexDirection};
  align-items: ${({ alignItems }: Object) => R.or(alignItems, 'center')};
`;

export const Text = styled.div`
  ${width}
  ${space}
  ${fontSize}

  color: ${({ color }: Object) => color};
  font-weight: ${({ fontWeight }: Object) => fontWeight};
`;

export const NormalLineHeight = styled.div`
  line-height: normal;
`;

export const formGroupPosition = css`
  position: relative;
`;

export const errorClass = css`
  bottom: 0;
  right: 10px;
  position: absolute;
`;

export const LabelBox = styled(StickedBox)`
  left: 5px;
  top: -13px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &.required::after {
    content: '*';
    color: ${() => G.getTheme('colors.light.mainRed')};
  }
`;
