import React from 'react';
import * as R from 'ramda';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, RelativeBox } from '../../../ui';
// feature import-report
import {
  expandedDetailsReport,
  expandedDetailsTableSettings,
  expandedDetailsColumnSettings,
} from '../settings/table-settings';
//////////////////////////////////////////////////

const getItemList = R.compose(
  R.filter(({ importStatus }: Object) => R.includes(importStatus, [GC.STATUS_ERROR, GC.STATUS_FAILED])),
  R.pathOr([], ['results']),
);

const ErrorsComponent = ({ globalErrors }: Object) => (
  <Box
    p={10}
    ml={50}
    width={300}
    maxHeight={400}
    overflowY='auto'
    borderRadius='4px'
    border='1px solid'
    justifyContent='center'
    borderColor={G.getTheme('colors.lightGrey')}
  >
    <Box mb={10} fontWeight='bold'>{G.getWindowLocale('titles:global-errors', 'Global Errors')}:</Box>
    {
      globalErrors.map((error: string, index: number) => (
        <Box py='5px' key={index} forBreak='break-all'>{R.inc(index)}. {error}</Box>
      ))
    }
  </Box>
);

const ExpandedDetails = ({ parentProps }: Object) => (
  <Flex py={20}>
    {
      G.isNotNilAndNotEmpty(parentProps.globalErrors) &&
      <ErrorsComponent globalErrors={parentProps.globalErrors} />
    }
    {
      G.isNotNilAndNotEmpty(getItemList(parentProps)) &&
      <RelativeBox ml={50} zIndex='0'>
        <Table
          loading={false}
          report={expandedDetailsReport}
          itemList={getItemList(parentProps)}
          tableSettings={expandedDetailsTableSettings}
          columnSettings={expandedDetailsColumnSettings}
        />
      </RelativeBox>
    }
  </Flex>
);

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  useMainColors: true,
  checkBoxCellWidth: 70,
  expandableItems: true,
  allowSelectItems: true,
  searchableTitles: true,
  maxHeight: 'calc(100vh - 145px)',
  expandedDetailsComponent: ExpandedDetails,
};

export default ExpandedDetails;
