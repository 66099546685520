import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { PageActions } from '../../components/page-actions';
import { ConfirmComponent } from '../../components/confirm';
import { ActionsElement } from '../../components/actions-element';
import { openModal, closeModal } from '../../components/modal/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hooks
import useFixedPopover from '../../hooks/use-mui-fixed-popover';
// icons
import * as I from '../../svgs';
// ui
import { Box, PageWrapper, ZOrderWrapper } from '../../ui';
// feature commission-assignment
import { tableSettings } from './settings/table-settings';
import { makeSelectCommissionAssignmentList } from './selectors';
import { report, columnSettings } from './settings/column-settings';
import CommissionAssignmentForm from './components/commission-assignment-form';
import { removeCommissionAssignmentRequest, createOrUpdateCommissionAssignmentRequest} from './actions';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');
const iconColorWhite = G.getTheme('colors.white');

const RowActions = ({ entity, handleRemoveCommissionAssignment, handleAddOrEditCommissionAssignment }: Object) => {
  const editable = R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, entity);

  const editAction = {
    frontIcon: I.renderEditIcon(iconColor),
    text: G.getWindowLocale('titles:edit', 'Edit'),
    action: () => handleAddOrEditCommissionAssignment(entity),
  };

  const deleteAction = {
    frontIcon: I.trash(iconColor),
    text: G.getWindowLocale('titles:delete', 'Delete'),
    action: () => handleRemoveCommissionAssignment(entity),
  };

  let options = R.of(Array, deleteAction);

  if (G.isTrue(editable)) options = R.prepend(editAction, options);

  return <ActionsElement version={2} options={options} />;
};

const enhance = compose(
  withHandlers({
    handleAddOrEditCommissionAssignment: (props: Object) => ({ initialValues, closeFixedPopup }: Object) => {
      const {
        openModal,
        closeModal,
        createOrUpdateCommissionAssignmentRequest,
    } = props;

      G.callFunction(closeFixedPopup);

      const component = (
        <CommissionAssignmentForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateCommissionAssignmentRequest}
        />
      );

      const titleArr = G.ifElse(
        G.isNotNilAndNotEmpty(initialValues),
        ['titles:edit-commission-assignment', 'Edit Commission Assignment'],
        ['titles:add-commission-assignment', 'Add Commission Assignment'],
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'max-content',
          title: G.getWindowLocale(...titleArr),
        },
      };

      openModal(modal);
    },
    handleRemoveCommissionAssignment: (props: Object) => ({ entity, closeFixedPopup }: Object) => {
      const { name, guid } = entity;
      const { openModal, removeCommissionAssignmentRequest } = props;

      G.callFunction(closeFixedPopup);
      const textLocale = G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove');
      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              version: 2,
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeCommissionAssignmentRequest(guid),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const renderRowActions = (data: Object, handleClickEditIcon: Function) => (
  <Box cursor='pointer' onClick={(event: Object) => handleClickEditIcon(event, data)}>
    {I.threeDots(G.getTheme(iconColor))}
  </Box>
);

const CommissionAssignmentList = (props: Object) => {
  const {
    commissionAssignmentList,
    handleRemoveCommissionAssignment,
    handleAddOrEditCommissionAssignment,
  } = props;

  const { PopoverComponent, openFixedPopup, closeFixedPopup } = useFixedPopover();

  const handleClickEditIcon = useCallback((event: Object, entity: Object) => {
    openFixedPopup({
      position: 'right',
      el: event.currentTarget,
      content: (
        <RowActions
          entity={entity}
          openModal={props.openModal}
          closeModal={props.closeModal}
          handleRemoveCommissionAssignment={
            (entity: Object) => handleRemoveCommissionAssignment({ entity, closeFixedPopup })
          }
          handleAddOrEditCommissionAssignment={
            (initialValues: Object) => handleAddOrEditCommissionAssignment({ initialValues, closeFixedPopup })
          }
        />
      ),
    });
  }, [props, openFixedPopup, closeFixedPopup, handleRemoveCommissionAssignment, handleAddOrEditCommissionAssignment]);

  return (
    <PageWrapper pt={15} pr={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          withCount={true}
          noExportable={true}
          hideFilterInfo={true}
          popperWithCount={true}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={true}
          totalCount={R.length(commissionAssignmentList)}
          title={G.getWindowLocale('titles:commission-assignee', 'Commission Assignee')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <Table
          report={report}
          loading={false}
          tableSettings={tableSettings}
          columnSettings={columnSettings}
          itemList={commissionAssignmentList}
          totalCount={R.length(commissionAssignmentList)}
          renderRightStickedComponent={(data: Object) => renderRowActions(data, handleClickEditIcon)}
        />
        {PopoverComponent}
      </ZOrderWrapper>
      <PageActions
        shadowColor={G.getTheme('createButton.shadowColor')}
        mainAction={{
          hideIfBranchTypeCustomer: true,
          icon: I.createCloIcon(iconColorWhite, 40, 40, 'transparent'),
          action: () => handleAddOrEditCommissionAssignment({ closeFixedPopup }),
          text: G.getWindowLocale('actions:add-commission-assignee', 'Add Commission Assignee'),
        }}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  commissionAssignmentList: makeSelectCommissionAssignmentList(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  removeCommissionAssignmentRequest,
  createOrUpdateCommissionAssignmentRequest,
})(enhance(CommissionAssignmentList));
