import { createSelector } from 'reselect';
// feature fleet-map
import { getFilteredFleetsEntity } from './helpers';
//////////////////////////////////////////////////

const selectFleetMapStore = (state: Object) => state.fleetMap;

const makeSelectFleets = () => createSelector(
  selectFleetMapStore,
  ({ fleets, enterpriseFilter }: Object) => {
    const getFilteredEntities = getFilteredFleetsEntity(enterpriseFilter, fleets);

    return {
      ...fleets,
      trucks: getFilteredEntities('trucks'),
      drivers: getFilteredEntities('drivers'),
      trailers: getFilteredEntities('trailers'),
    };
  }
);

export {
  makeSelectFleets,
};
