import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../../helpers';
// feature fleet
import { VINLookupTrailer } from '../../components/vin-lookup';
import {
  uomValidation,
  customRequired,
  customIsNumeric,
  documentValidation,
} from '../../validation';
//////////////////////////////////////////////////

export const trailerDetailsFields = {
  name: 'trailerDetails',
  title: 'titles:general-details',
  fields: [
    {
      type: 'select',
      name: 'titles:type',
      validate: G.required,
      options: 'trailerType',
      nameForAttribute: 'trailerType',
    },
    {
      type: 'text',
      validate: G.required,
      name: 'titles:unit-id',
      nameForAttribute: 'unitId',
    },
    {
      type: 'text',
      name: 'titles:legacy-unit-id',
      nameForAttribute: 'legacyUnitId',
      validate: G.curriedIsEmptyOrMinMax(0, 40),
    },
    {
      type: 'text',
      name: 'titles:serial-number-vin',
      nameForAttribute: 'serialNumber',
      validate: G.curriedIsEmptyOrMinMax(0, 40),
      additionalLabelComponent: <VINLookupTrailer />,
    },
    {
      type: 'text',
      name: 'titles:manufacturer',
      nameForAttribute: 'manufacturer',
      validate: G.curriedIsEmptyOrMinMax(0, 40),
    },
    {
      type: 'text',
      name: 'titles:year',
      nameForAttribute: 'outputYear',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1900, 2100)],
    },
    {
      type: 'text',
      name: 'titles:model',
      nameForAttribute: 'trailerModel',
      validate: G.curriedIsEmptyOrMinMax(0, 40),
    },
    {
      type: 'text',
      name: 'titles:series',
      nameForAttribute: 'modelSeries',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'toggle',
      name: 'titles:in-service',
      nameForAttribute: 'inService',
    },
    {
      type: 'select',
      validate: G.required,
      options: 'outOfServiceReason',
      nameForAttribute: 'outOfServiceReason',
      name: 'titles:out-of-service-status-reason',
      showField: (params: Object) => R.not(params.inService),
    },
    {
      type: 'toggle',
      name: 'titles:available',
      nameForAttribute: 'available',
    },
    {
      type: 'select',
      validate: G.required,
      options: 'unavailabilityReason',
      name: 'titles:unavailable-status-reason',
      nameForAttribute: 'unavailabilityReason',
      showField: ({ available }: Object) => R.not(available),
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:in-service-date',
      nameForAttribute: 'inServiceDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:out-service-date',
      nameForAttribute: 'outServiceDate',
    },
  ],
};

export const trailerRegistrationFields = {
  name: 'trailerRegistration',
  title: 'titles:registration',
  fields: [
    {
      type: 'text',
      name: 'titles:license-plate',
      nameForAttribute: 'licensePlate',
      validate: G.curriedIsEmptyOrMinMax(0, 15),
    },
    {
      type: 'text',
      name: 'titles:title-state',
      nameForAttribute: 'titleState',
      validate: G.curriedIsEmptyOrMinMax(0, 100),
    },
    {
      type: 'text',
      name: 'titles:title-number',
      nameForAttribute: 'titleNumber',
      validate: G.curriedIsEmptyOrMinMax(0, 40),
    },
    {
      type: 'text',
      name: 'titles:registration-state',
      nameForAttribute: 'registrationState',
      validate: G.curriedIsEmptyOrMinMax(0, 100),
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:registration-expired-date',
      nameForAttribute: 'registrationExpiredDate',
    },
  ],
};

export const insuranceFields = {
  fields: [
    {
      type: 'select',
      options: 'insuranceType',
      validate: customRequired,
      name: 'titles:insurance-type',
      nameForAttribute: 'insuranceType',
    },
    {
      width: '159',
      type: 'calendar',
      isClearable: true,
      validate: customRequired,
      name: 'titles:effective-date',
      nameForAttribute: 'effectiveDate',
    },
    {
      width: '159',
      type: 'calendar',
      isClearable: true,
      validate: customRequired,
      name: 'titles:expiration-date',
      nameForAttribute: 'expirationDate',
    },
    {
      type: 'text',
      width: '185',
      name: 'titles:payment',
      nameForAttribute: 'paymentAmount',
      validate: [customRequired, customIsNumeric],
    },
    {
      width: '185',
      type: 'select',
      validate: customRequired,
      name: 'titles:payment-interval',
      nameForAttribute: 'paymentInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
    },
    {
      type: 'textarea',
      name: 'titles:description',
      nameForAttribute: 'description',
    },
  ],
};

export const documentFields = {
  fields: [
    {
      type: 'select',
      name: 'titles:type',
      options: 'documentType',
      validate: customRequired,
      nameForAttribute: 'documentType',
    },
    {
      type: 'select',
      name: 'titles:status',
      validate: customRequired,
      nameForAttribute: 'status',
      options: ['', 'CURRENT', 'HISTORICAL'],
    },
    {
      type: 'textarea',
      direction: 'column',
      name: 'titles:description',
      nameForAttribute: 'description',
      validate: G.curriedIsEmptyOrMinMax(0, 2000),
    },
    {
      width: 159,
      type: 'calendar',
      isClearable: true,
      name: 'titles:signed-date',
      nameForAttribute: 'signedDate',
    },
    {
      width: 159,
      type: 'calendar',
      isClearable: true,
      name: 'titles:expired-date',
      nameForAttribute: 'expirationDate',
    },
    {
      type: 'text',
      name: 'titles:url',
      nameForAttribute: 'url',
      validate: documentValidation,
      disabled: ({ documentFilename }: Object) => G.isNotNilAndNotEmpty(documentFilename),
    },
    {
      type: 'file',
      name: 'actions:upload',
      validate: documentValidation,
      nameForAttribute: 'documentFilename',
      disabled: (params: Object) => G.isNotNilAndNotEmpty(params.url),
    },
  ],
};

export const ownershipFields = {
  name: 'ownership',
  title: 'titles:ownership-details',
  fields: [
    {
      type: 'select',
      name: 'titles:ownership-type',
      nameForAttribute: 'ownershipType',
      options: ['Own', 'Lease', 'Rental'],
    },
    {
      type: 'text',
      name: 'titles:contact-name',
      nameForAttribute: 'contractName',
      showField: (params: Object) => (
        R.and(
          G.isNotNil(params.ownershipType),
          G.notEquals(params.ownershipType, 'Own'),
        )
      ),
    },
    {
      type: 'text',
      name: 'titles:contract-number',
      nameForAttribute: 'contractNumber',
      showField: (params: Object) => (
        R.and(
          G.isNotNil(params.ownershipType),
          G.notEquals(params.ownershipType, 'Own'),
        )
      ),
    },
  ],
};

export const termDetailsFields = {
  name: 'termDetails',
  title: 'titles:term-details',
  fields: [
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:start-date',
      nameForAttribute: 'startDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:end-date',
      nameForAttribute: 'endDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:date-returned',
      nameForAttribute: 'returnedDate',
    },
  ],
};

export const costDetailsFields = {
  name: 'costDetails',
  title: 'titles:cost-details',
  fields: [
    {
      type: 'text',
      validate: G.isEmptyOrNumeric,
      name: 'titles:payment-amount',
      nameForAttribute: 'paymentAmount',
    },
    {
      type: 'select',
      name: 'titles:payment-interval',
      nameForAttribute: 'paymentInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
    },
    {
      type: 'select',
      name: 'titles:payment-method',
      options: ['', 'ACH', 'Invoice'],
      nameForAttribute: 'paymentMethod',
    },
    {
      type: 'select',
      name: 'titles:maintenance-cost-interval',
      nameForAttribute: 'maintenanceCostInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
      showField: (params: Object) => (
        R.and(
          G.isNotNil(params.ownershipType),
          G.notEquals(params.ownershipType, 'Own'),
        )
      ),
    },
    {
      type: 'select',
      name: 'titles:maintenance-cost-method',
      nameForAttribute: 'maintenanceCostMethod',
      options: ['', 'ACH', 'Invoice'],
      showField: ({ ownershipType }: Object) => (
        R.and(
          G.isNotNil(ownershipType),
          G.notEquals(ownershipType, 'Own'),
        )
      ),
    },
    {
      type: 'text',
      validate: G.isEmptyOrNumeric,
      name: 'titles:registration-cost',
      nameForAttribute: 'registrationCost',
    },
    {
      type: 'select',
      name: 'titles:registration-interval',
      nameForAttribute: 'registrationPaymentInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
    },
  ],
};

export const equipmentReturnFields = {
  name: 'equipmentReturn',
  title: 'titles:equipment-return',
  fields: [
    {
      type: 'text',
      name: 'titles:cost',
      nameForAttribute: 'equipmentReturnCost',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      name: 'titles:cost-ratio',
      nameForAttribute: 'equipmentReturnCostRatio',
    },
    {
      type: 'text',
      name: 'titles:cost-accrued',
      nameForAttribute: 'equipmentReturnCostAccrued',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      name: 'titles:cost-paid',
      nameForAttribute: 'equipmentReturnCostPaid',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      name: 'titles:cost-not-provisioned',
      nameForAttribute: 'equipmentReturnCostNotProvisioned',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      validate: G.isEmptyOrNumeric,
      name: 'titles:balance-required',
      nameForAttribute: 'equipmentReturnBalanceRequired',
    },
  ],
};

export const mainSpecificationFields = {
  name: 'mainSpecification',
  title: 'titles:specifications',
  fields: [
    {
      name: 'titles:tare-weight',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'tareWeight',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'kg', 'lb'],
          nameForAttribute: 'weightTareType',
        },
      ],
    },
    {
      name: 'titles:gvwr',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'gvwt',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'kg', 'lb'],
          nameForAttribute: 'weightTypeGVWT',
        },
      ],
    },
    {
      type: 'select',
      name: 'titles:class',
      options: 'specificationClass',
      nameForAttribute: 'specificationClass',
    },
    {
      type: 'select',
      name: 'titles:axle-count',
      nameForAttribute: 'axleCount',
      options: ['', '1', '2', '3', '4', '5'],
    },
    {
      type: 'customSelect',
      name: 'titles:axle-config',
      nameForAttribute: 'axleConfig',
      options: [
        { label: 'trailerTwo', value: '0-2' },
        { label: 'trailerThree', value: '0-3' },
        { label: 'trailerFour', value: '0-4' },
        { label: 'trailerThreeTwo', value: '3-2' },
      ],
    },
    {
      separator: 'x',
      name: 'titles:int-dimensions',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'internalDimension.length',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          separate: true,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'internalDimension.lengthUOM',
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'internalDimension.width',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          separate: true,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'internalDimension.widthUOM',
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'internalDimension.height',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'internalDimension.heightUOM',
        },
      ],
    },
    {
      separator: 'x',
      name: 'titles:ext-dimensions',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'externalDimension.length',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          separate: true,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'externalDimension.lengthUOM',
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'externalDimension.width',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          separate: true,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'externalDimension.widthUOM',
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'externalDimension.height',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'externalDimension.heightUOM',
        },
      ],
    },
    {
      separator: 'x',
      name: 'titles:well-height',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'wellDimension.height',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'wellDimension.heightUOM',
        },
      ],
    },
    {
      separator: 'x',
      name: 'titles:well-length',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'wellDimension.length',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'wellDimension.lengthUOM',
        },
      ],
    },
    {
      type: 'select',
      options: 'doorType',
      name: 'titles:door-type',
      nameForAttribute: 'doorType',
    },
    {
      name: 'titles:door-dimensions',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'doorDimension.width',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'doorDimension.widthUOM',
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'doorDimension.height',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'doorDimension.heightUOM',
        },
      ],
    },
    {
      type: 'select',
      options: 'bodyMaterial',
      name: 'titles:body-material',
      nameForAttribute: 'bodyMaterial',
    },
    {
      name: 'titles:deck-height',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'deckHeight',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          nameForAttribute: 'deckHeightType',
          options: ['', 'cm', 'm', 'inch', 'ft'],
        },
      ],
    },
    {
      name: 'titles:cube',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'cube',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          nameForAttribute: 'cubeType',
          options: ['', 'cubic m', 'cubic ft'],
        },
      ],
    },
    {
      type: 'text',
      nameForAttribute: 'bulkCompCount',
      name: 'titles:bulk-compartment-count',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      name: 'titles:bulk-compartment-volume',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'bulkCompVolume',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          nameForAttribute: 'bulkCompVolumeType',
          options: ['', 'cubic m', 'cubic ft', 'gallons', 'liter'],
        },
      ],
    },
    {
      type: 'toggle',
      name: 'titles:temperature-control',
      nameForAttribute: 'temperatureControl',
    },
  ],
};

export const refrigerationFields = {
  name: 'refrigeration',
  title: 'titles:refrigeration',
  fields: [
    {
      type: 'select',
      validate: G.required,
      name: 'titles:manufacturer',
      options: 'refrigerationManufacturer',
      nameForAttribute: 'refrigeration.refrigerationManufacturer',
    },
    {
      type: 'text',
      name: 'titles:year',
      nameForAttribute: 'refrigeration.outputYear',
      validate: [G.required, G.curriedIsEmptyOrMinMax(4, 4), G.isEmptyOrNumeric],
    },
    {
      type: 'text',
      name: 'titles:model',
      validate: G.required,
      nameForAttribute: 'refrigeration.model',
    },
    {
      type: 'text',
      validate: G.required,
      name: 'titles:series',
      nameForAttribute: 'refrigeration.series',
    },
    {
      type: 'text',
      validate: G.required,
      name: 'titles:serial-number',
      nameForAttribute: 'refrigeration.serialNumber',
    },
    {
      type: 'select',
      validate: G.required,
      name: 'titles:coolant-type',
      nameForAttribute: 'refrigeration.coolantType',
      options: ['', 'Ethylene glycol', 'Propylene glycol', 'Water'],
    },
    {
      type: 'text',
      name: 'titles:carb-id',
      nameForAttribute: 'refrigeration.carbId',
    },
    {
      type: 'text',
      name: 'titles:tru-atcm-id',
      nameForAttribute: 'refrigeration.truatcmId',
    },
    {
      separator: '-',
      name: 'titles:temp-range',
      inputs: [
        {
          width: 50,
          type: 'text',
          separate: true,
          validate: [G.required, G.isEmptyOrNumeric],
          nameForAttribute: 'refrigeration.tempRangeFrom',
        },
        {
          width: 50,
          type: 'text',
          validate: [G.required, G.isEmptyOrNumeric],
          nameForAttribute: 'refrigeration.tempRangeTo',
        },
        {
          width: 100,
          type: 'select',
          validate: G.required,
          options: ['', 'Celsius', 'Fahrenheit'],
          nameForAttribute: 'refrigeration.tempRangeType',
        },
      ],
    },
    {
      name: 'titles:hours',
      type: 'text',
      nameForAttribute: 'refrigeration.hours',
      validate: [G.required, G.isEmptyOrNumeric],
    },
    {
      isClearable: true,
      type: 'datePicker',
      validate: G.required,
      name: 'titles:hours-date',
      nameForAttribute: 'refrigeration.hoursDate',
    },
  ],
};

export const componentFields = {
  fields: [
    {
      type: 'text',
      align: 'start',
      labelPosition: true,
      name: 'titles:component-id',
      label: 'titles:component-id',
      nameForAttribute: 'componentId',
      validate: [G.required, G.curriedIsEmptyOrMinMax(0, 85)],
    },
    {
      type: 'text',
      align: 'start',
      labelPosition: true,
      validate: G.required,
      name: 'titles:serial-number',
      label: 'titles:serial-number',
      nameForAttribute: 'serialNumber',
    },
    {
      type: 'select',
      align: 'start',
      name: 'titles:type',
      labelPosition: true,
      validate: G.required,
      label: 'titles:type',
      options: 'componentType',
      nameForAttribute: 'componentType',
    },
    {
      type: 'select',
      align: 'start',
      labelPosition: true,
      validate: G.required,
      name: 'titles:manufacturer',
      label: 'titles:manufacturer',
      options: 'componentManufacturer',
      nameForAttribute: 'componentManufacturer',
    },
    {
      width: '159',
      type: 'calendar',
      direction: 'row',
      align: 'baseline',
      isClearable: true,
      validate: customRequired,
      name: 'titles:installed-date',
      label: 'titles:installed-date',
      nameForAttribute: 'installedDate',
    },
    {
      width: '159',
      type: 'calendar',
      direction: 'row',
      isClearable: true,
      align: 'baseline',
      name: 'titles:removed-date',
      label: 'titles:removed-date',
      nameForAttribute: 'replaceOrRemoveDate',
    },
    {
      width: '170',
      type: 'calendar',
      direction: 'row',
      isClearable: true,
      align: 'baseline',
      nameForAttribute: 'warrantyExpiration',
      name: 'titles:warranty-expiration-date',
      label: 'titles:warranty-expiration-date',
    },
    {
      align: 'start',
      type: 'textarea',
      labelPosition: true,
      name: 'titles:specification',
      label: 'titles:specification',
      nameForAttribute: 'specification',
      validate: G.curriedIsEmptyOrMinMax(0, 300),
    },
  ],
};
