import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import styled from 'styled-components';
import { TwitterPicker } from 'react-color';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const StyledColorPicker = styled(TwitterPicker)`
  &.twitter-picker {
    box-shadow: none !important;
    border: 1px solid ${R.path(['theme', 'colors', 'dark', 'grey'])} !important;
  }
`;

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const taskTypeInputWrapperStyles = {
  mb: 25,
  width: 500,
};

const taskTypeFieldSettings = [
  {
    isRequired: true,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
    inputWrapperStyles: taskTypeInputWrapperStyles,
  },
  {
    fieldName: GC.FIELD_TASK_TITLE,
    label: ['titles:task-title', 'Task Title'],
    inputWrapperStyles: taskTypeInputWrapperStyles,
  },
  {
    type: 'editor',
    shouldCustomChange: true,
    fieldName: GC.FIELD_TASK_DESCRIPTION,
    inputWrapperStyles: taskTypeInputWrapperStyles,
    label: ['titles:task-description', 'Task Description'],
    customChangeHandler: ({ value }: Object, _: any, { setFieldValue }: Object) => setFieldValue('editorState', value),
  },
];

const taskTypeDefaultValues = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_TASK_TITLE]: null,
  [GC.FIELD_TASK_DESCRIPTION]: null,
};

const taskTypeValidationSchema = Yup.object().shape({
  [GC.FIELD_NAME]: G.yupStringRequired,
  [GC.FIELD_TASK_TITLE]: G.yupStringNotRequired,
  [GC.FIELD_TASK_DESCRIPTION]: G.yupStringNotRequired,
});

export const taskTypeFormSettings = {
  fieldSettings: taskTypeFieldSettings,
  defaultValues: taskTypeDefaultValues,
  validationSchema: taskTypeValidationSchema,
};

const taskStatusFieldSettings = [
  {
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_COMPLETED,
    label: ['titles:completed', 'Completed'],
  },
  {
    fieldName: 'daysBeforeRemoval',
    label: ['titles:days-before-remove', 'Days Before Remove'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: (props: Object) => G.ifElse(
        R.pathEq(false, ['values', GC.FIELD_COMPLETED], props),
        'none',
      ),
    },
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.FIELD_TASK_TYPE_GUIDS,
    fieldName: GC.FIELD_TASK_TYPE_GUIDS,
    label: ['titles:task-types', 'Task Types'],
  },
  {
    inputWrapperStyles,
    type: 'customComponent',
    fieldName: GC.FIELD_COLOR,
    label: ['titles:color', 'Color'],
    Component: ({ value, setFieldValue }: Object) => (
      <StyledColorPicker
        width='300px'
        triangle='hide'
        color={R.or(value, 'rgb(255,105,0)')}
        onChangeComplete={(color: Object = {}) => {
          const { r, g, b } = R.propOr({}, 'rgb', color);

          setFieldValue(GC.FIELD_COLOR, `rgb(${r},${g},${b})`);
        }}
      />
    ),
  },
];

const taskStatusDefaultValues = {
  daysBeforeRemoval: 3,
  [GC.FIELD_NAME]: null,
  [GC.FIELD_COMPLETED]: false,
  [GC.FIELD_TASK_TYPE_GUIDS]: [],
  [GC.FIELD_COLOR]: 'rgb(255,105,0)',
};

const taskStatusValidationSchema = Yup.lazy(({ completed }: Object) => Yup.object().shape({
  [GC.FIELD_NAME]: G.yupStringRequired,
  [GC.FIELD_TASK_TYPE_GUIDS]: G.yupArrayRequired,
  daysBeforeRemoval: G.ifElse(
    G.isTrue(completed),
    G.yupNumberRequired
      .min(0, G.getShouldBePositiveLocaleTxt())
      .integer(G.getShouldBeIntegerLocaleTxt())
      .max(10, G.getShouldBeLessOrEqualLocaleTxt(10)),
  ),
}));

export const taskStatusFormSettings = {
  fieldSettings: taskStatusFieldSettings,
  defaultValues: taskStatusDefaultValues,
  validationSchema: taskStatusValidationSchema,
};
