import React from 'react';
import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleDelete: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        closeFixedPopup,
        handleDeleteEntity,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => handleDeleteEntity(G.getGuidFromObject(entity)),
        text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
      });

      closeFixedPopup();

      return openModal(modalContent);
    },
  }),
);

// TODO: check update clo from edi
const getOptions = (props: Object) => {
  const { entity, handleDelete, closeFixedPopup } = props;

  const transactionAction = R.path(['entity', GC.GRC.EDI_CLO_TRANSACTION_ACTION], props);
  const createdCloGuid = R.path(['entity', GC.GRC.EDI_CREATED_CLO_GUID], props);
  const iconColor = G.getTheme('colors.light.blue');

  const options = [
    {
      action: handleDelete,
      frontIcon: I.trash(iconColor),
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];

  const transactionActionOptionsMap = {
    [GC.EDI_TRANSACTION_ACTION_CREATE]: {
      frontIcon: I.routesLoads(iconColor),
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
      action: () => {
        const options = {
          [GC.FIELD_GUID]: G.getGuidFromObject(entity),
          [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_EDI,
        };
        G.goToRoute(routesMap.newDOBySourceType(options));
      },
    },
    [GC.EDI_TRANSACTION_ACTION_REPLACE]: {
      frontIcon: I.routesLoads(iconColor),
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
      action: () => {
        const options = {
          [GC.FIELD_GUID]: G.getGuidFromObject(entity),
          [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_EDI,
        };
        G.goToRoute(routesMap.newDOBySourceType(options));
      },
    },
    [GC.EDI_TRANSACTION_ACTION_UPDATE]: {
      frontIcon: I.routesLoads(iconColor),
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      text: G.getWindowLocale('actions:update-clo', 'Update CLO'),
      action: () => {
        const options = {
          [GC.FIELD_GUID]: G.getGuidFromObject(entity),
          [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_EDI,
        };
        G.goToRoute(routesMap.newDOBySourceType(options));
      },
    },
  };
  if (G.isNotNilAndNotEmpty(createdCloGuid)) {
    const option = {
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      frontIcon: I.routesLoads(iconColor, 13, 13),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
      action: () => {
        closeFixedPopup();
        G.goToRoute(routesMap.dispatchDetailsOrder(createdCloGuid));
      },
    };

    return R.prepend(option, options);
  }

  if (G.isNilOrEmpty(R.prop(transactionAction, transactionActionOptionsMap))) return options;

  return R.prepend(R.prop(transactionAction, transactionActionOptionsMap), options);
};

export const RowActions = enhance((props: Object) => (
  <ActionsElement
    entity={props.entity}
    options={getOptions(props)}
  />
));
