import * as R from 'ramda';
import { useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet-profile
import { toggleFormGroupTable } from '../actions';
//////////////////////////////////////////////////

export const useFleetProfileWrapperActions = (props: Object) => {
  const {
    dispatch,
    closeModal,
    handleClose,
    saveAndClose,
    generalDetails,
    setSaveAndClose,
    updateGeneralDetailsRequest,
    handleUpdateGeneralDetailsRequest,
  } = props;

  const handleToggleFormGroupTable = useCallback((groupName: string) => dispatch(toggleFormGroupTable(groupName)), []);

  const handleUpdateGeneralDetails = useCallback((values: Object, options: Object = {}) => {
    const { setSubmitting, shouldCloseModal } = options;

    const closeAction = () => handleClose(saveAndClose);

    if (R.equals(R.filter(G.isNotNilAndNotEmpty, values), R.filter(G.isNotNilAndNotEmpty, generalDetails))) {
      G.callFunctionWithArgs(setSubmitting, false);

      if (shouldCloseModal) closeModal();

      if (saveAndClose) closeAction();

      return;
    }

    const newValues = { ...generalDetails, ...values };

    setSaveAndClose(false);

    const reqData = { options, saveAndClose, handleClose: closeAction, values: newValues };

    return G.isFunction(handleUpdateGeneralDetailsRequest)
      ? handleUpdateGeneralDetailsRequest(reqData)
      : dispatch(updateGeneralDetailsRequest(reqData));
  }, [saveAndClose, generalDetails]);

  return {
    handleToggleFormGroupTable,
    handleUpdateGeneralDetails,
  };
};
