import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_ADDRESS]: {
    isRequired: true,
    name: 'titles:address',
    type: 'addressAutocomplete',
    additionalInputWrapperStyles: { zIndex: 12 },
    locationMapper: (location: Object) => ({
      ...location,
      [GC.FIELD_ADDRESS]: G.getPropFromObject(GC.FIELD_ADDRESS_1, location),
      [GC.FIELD_CONTACT_POSTAL_CODE]: G.getPropFromObject(GC.FIELD_ZIP, location),
    }),
  },
  [GC.FIELD_CITY]: {
    isRequired: true,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 150,
    isRequired: true,
    name: 'titles:state',
  },
  [GC.FIELD_CONTACT_POSTAL_CODE]: {
    width: 150,
    isRequired: true,
    name: 'titles:postal-code',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    isRequired: true,
    type: 'countrySelect',
    name: 'titles:country',
  },
  [GC.FIELD_EMAIL]: {
    name: 'titles:email',
  },
  [GC.FIELD_FLEET_MOBILE_PHONE]: {
    width: 150,
    name: 'titles:mobile-phone',
  },
  [GC.FIELD_HOME_PHONE]: {
    width: 150,
    name: 'titles:home-phone',
  },
  [GC.FIELD_EMERGENCY_CONTACT_NAME]: {
    width: 150,
    isRequired: true,
    name: 'titles:emergency-contact-name',
  },
  [GC.FIELD_EMERGENCY_PHONE]: {
    width: 150,
    isRequired: true,
    name: 'titles:emergency-phone',
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CITY]: G.yupStringRequired,
  [GC.FIELD_STATE]: G.yupStringRequired,
  [GC.FIELD_ADDRESS]: G.yupStringRequired,
  [GC.FIELD_COUNTRY]: G.yupStringRequired,
  [GC.FIELD_CONTACT_POSTAL_CODE]: G.yupStringRequired,
  [GC.FIELD_EMERGENCY_CONTACT_NAME]: G.yupStringRequired,
  [GC.FIELD_EMAIL]: Yup.string()
    .notRequired()
    .nullable(true)
    .email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_HOME_PHONE]: Yup.string()
    .notRequired()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
  [GC.FIELD_FLEET_MOBILE_PHONE]: Yup.string()
    .notRequired()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
  [GC.FIELD_EMERGENCY_PHONE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
});

const defaultInputWrapperStyles = {
  mb: 25,
  width: 280,
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    type = 'text',
    locationMapper,
    isRequired = false,
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    type,
    options,
    fieldName,
    isRequired,
    locationMapper,
    label: R.of(Array, name),
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...G.spreadUiStyles(additionalInputWrapperStyles),
    },
  })),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width']), settings);

export const contactSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  groupName: 'contacts',
  itemTitleArr: ['titles:contact', 'Contact'],
  formGroupTitleArr: ['titles:contacts', 'Contacts'],
  endpoints: {
    list: 'driverContactList',
    createOrUpdate: 'driverContact',
    remove: 'getCurrentContactEndpoint',
  },
  fieldsWrapperStyles: {
    width: 600,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export const vendorContactSettings = {
  ...contactSettings,
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  endpoints: {
    list: 'vendorContactList',
    remove: 'removeVendorContact',
    createOrUpdate: 'vendorContact',
  },
  // helpers
  makeRequestPayload: ({ primaryObjectGuid }: Object) => ({
    params: {
      [GC.FIELD_VENDOR_GUID]: primaryObjectGuid,
    },
  }),
};
