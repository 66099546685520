import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
// component
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { withDispatchActions } from '../../rate/hocs';
import ItemForm from '../../item/components/item-form';
import StopForm from '../../new-do/outside-use/stop-form';
import DropItemsForm from '../../dispatch-details-new/forms/drop-items-form';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// forms
import SelectTerminal from '../../../forms/forms/select-terminal';
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner
import * as H from '../helpers';
import LoadMap from './load-map';
import LoadHeader from './load-header';
import LinkedOrders from './linked-orders';
import ContainerForm from './container-form';
import SelectClosItems from './select-clos-items';
import { withAddTrailer } from '../hocs/with-add-trailer';
import { withPlannerRates } from '../hocs/with-planner-rates';
import LoadInnerWithRenderer, { LoadInner } from './load-inner';
import {
  makeSelectItemsForDrop,
  makeSelectCurrentRoute,
  makeSelectRouteChanged,
  makeSelectItemsForPickup,
  makeSelectDefaultUomFields,
  makeSelectOptionsForSelect,
  makeSelectCurrentRouteItems,
  makeSelectExistedTerminalOptions,
  makeSelectCurrentRouteContainers,
  makeSelectCurrentRouteInitialState,
} from '../selectors';
import {
  editItem,
  cleanTelRate,
  sortTelEvents,
  addNewItemOnUI,
  addItemsForDrop,
  updateContainer,
  addTrailersToStop,
  addItemsForPickup,
  removeItemFromStop,
  addItemsClosForDrop,
  uncheckCrossDockClos,
  removeTrailerFromStop,
  removeCloItemFromStop,
  addItemsClosForPickup,
  addNewContainerToStop,
  dispatchTelRateRequest,
  addExistedTerminalToTel,
  removeContainerFromStop,
  addDropContainersToStop,
  addPickupContainersToStop,
  removeTelFromRouteBuilder,
  addNewStopToRouteBuilderTel,
  dispatchCarrierTelRateRequest,
  removeStopFromRouteBuilderTel,
  recalculateTelDistancesRequest,
  addNewCloStopToRouteBuilderTel,
  updateStopOnRouteBuilderTelRequest,
} from '../actions';
//////////////////////////////////////////////////

const ItemsClosForDrop = (props: Object) => {
  const itemOptions = R.compose(
    R.map(({ itemId, itemInternalId }: Object) => ({
      label: itemId,
      value: itemInternalId,
    })),
    H.getTelItemsForDrop,
  )(props);
  const cloOptions = R.compose(
    R.map((cloId: Array) => ({
      value: cloId,
      label: H.getLoadName(
        R.path(['currentRoute', 'clos', cloId], props),
        R.pathOr('Unknown CLO', ['currentRoute', 'clos', cloId, 'loadName'], props),
      ),
    })),
    R.keys,
  )(props.clos);

  return (
    <SelectClosItems
      {...props}
      closObj={props.clos}
      options={itemOptions}
      cloOptions={cloOptions}
      itemOptions={itemOptions}
      eventGuid={R.path(['stop', 'guid', props])}
      handleActionLoadItem={(data: Object) => props.handleAddItemsClosForDrop(data, props.stop)}
    />
  );
};

const ItemsForPickup = (props: Object) => {
  const options = R.compose(
    R.map(({ itemId, itemInternalId }: Object) => ({
      label: itemId,
      value: itemInternalId,
    })),
  )(props.items);

  return (
    <Box>
      <Flex mb={10}>
        <Box mr='8px'>
          {G.getWindowLocale('titles:add-item-msg', 'Add new item to this stop')}
        </Box>
        <Box
          cursor='pointer'
          onClick={() => {
            props.closeModal();
            props.handleCreateNewItem(props.stop);
          }}
        >
          {I.plusRound(G.getTheme('colors.light.darkRed'))}
        </Box>
      </Flex>
      <DropItemsForm
        {...props}
        options={options}
        defaultItems={[]}
        eventGuid={R.path(['stop', 'guid'], props)}
        handleActionLoadItem={(data: Object) => props.handleAddItemsForPickup(data, props.stop)}
      />
    </Box>
  );
};

const ItemsClosForPickup = (props: Object) => {
  const itemOptions = R.compose(
    R.map((item: Object) => ({
      label: item.itemId,
      value: item.itemInternalId,
    })),
  )(props.items);
  const cloOptions = R.compose(
    R.map((cloId: Array) => ({
      value: cloId,
      label: H.getLoadName(
        R.path(['currentRoute', 'clos', cloId], props),
        R.pathOr('Unknown CLO', ['currentRoute', 'clos', cloId, 'loadName'], props),
      ),
    })),
    R.keys,
  )(props.clos);

  return (
    <Box>
      <Flex mb={10}>
        <Box mr='8px'>
          {G.getWindowLocale('titles:add-item-msg', 'Add new item to this stop')}
        </Box>
        <Box
          cursor='pointer'
          onClick={() => {
            props.closeModal();
            props.handleCreateNewItem(props.stop);
          }}
        >
          {I.plusRound(G.getTheme('colors.light.darkRed'))}
        </Box>
      </Flex>
      <SelectClosItems
        {...props}
        closObj={props.clos}
        options={itemOptions}
        cloOptions={cloOptions}
        itemOptions={itemOptions}
        eventGuid={R.path(['stop', 'guid'], props)}
        handleActionLoadItem={(data: Object) => props.handleAddItemsClosForPickup(data, props.stop)}
      />
    </Box>
  );
};

const ItemsForDrop = (props: Object) => {
  const options = R.compose(
    R.map((item: Object) => ({
      label: item.itemId,
      value: item.itemInternalId,
    })),
    H.getTelItemsForDrop,
  )(props);

  return (
    <DropItemsForm
      {...props}
      defaultItems={[]}
      options={options}
      eventGuid={R.path(['stop', GC.FIELD_GUID], props)}
      handleActionLoadItem={(data: Object) => props.handleAddItemsForDrop(data, props.stop)}
    />
  );
};

const CloItemsForDrop = (props: Object) => {
  const options = R.compose(
    R.map((item: Object) => ({
      label: R.prop(GC.FIELD_ITEM_ID, item),
      value: R.prop(GC.FIELD_ITEM_INTERNAL_ID, item),
    })),
    H.getCloItemsForDrop,
  )(props);

  return (
    <DropItemsForm
      {...props}
      defaultItems={[]}
      options={options}
      eventGuid={R.path(['stop', GC.FIELD_GUID], props)}
      handleActionLoadItem={(data: Object) => props.handleAddItemsForDrop(data, props.stop)}
    />
  );
};

const getContainerOptions = (containers: Array) => R.compose(
  G.addEmptyOptionToDropDown,
  R.map((container: Object) => ({
    [GC.FIELD_LABEL]: H.getContainerInfo(container),
    [GC.FIELD_VALUE]: G.getPropFromObject(GC.FIELD_CONTAINER_INTERNAL_ID, container),
  })),
)(containers);

const getContainerGuid = (data: Object, containers: Array) => {
  let containerGuid = null;

  if (G.isNotNil(G.getPropFromObject(GC.FIELD_CONTAINER_INTERNAL_ID, data))) {
    containerGuid = R.compose(
      R.pathOr(null, [GC.FIELD_GUID]),
      R.find((container: Object) => R.eqProps(GC.FIELD_CONTAINER_INTERNAL_ID, container, data)),
    )(containers);
  }

  return containerGuid;
};

const enhance = compose(
  withDispatchActions,
  withPlannerRates({ page: GC.PAGE_DISPATCH_PLANNER }),
  withState('telVisibility', 'setTelVisibility', null),
  withHandlers({
    handleAddItemsForPickup: (props: Object) => ({ items }: Object, stop: Object) => (
      props.addItemsForPickup({
        items,
        eventGuid: stop.guid,
        telGuid: stop.telGuid,
      })
    ),
    handleAddItemsClosForPickup: (props: Object) => ({ items, loadItems }: Object, stop: Object) => (
      props.addItemsClosForPickup({
        stop,
        items,
        loadItems: R.concat(stop.items, loadItems),
      })
    ),
  }),
  withHandlers({
    handleAddItemsForDrop: (props: Object) => ({ items }: Object, stop: Object) => (
      props.addItemsForDrop({
        items,
        eventGuid: stop.guid,
        telGuid: stop.telGuid,
      })
    ),
    handleAddItemsClosForDrop: (props: Object) => ({ items, loadItems }: Object, stop: Object) => (
      props.addItemsClosForDrop({
        stop,
        items,
        loadItems: R.concat(stop.items, loadItems),
      })
    ),
    handleCreateNewItem: (props: Object) => (stop: Object) => {
      const { tel, openModal, closeModal, currentRoute, addNewItemOnUI } = props;

      const { eventType, droppedContainers, pickedUpContainers } = stop;

      const eventGuid = G.getGuidFromObject(stop);
      const availableContainers = G.ifElse(G.isEventTypePickup(eventType), pickedUpContainers, droppedContainers);

      const submitAction = (values: Object) => {
        const { guid, loadType } = tel;

        const data = R.mergeRight(
          values,
          { loadType, eventGuid, loadGuid: guid },
        );

        const containerGuid = getContainerGuid(data, availableContainers);

        editItem(R.assoc(GC.FIELD_CONTAINER_GUID, containerGuid, data));

        addNewItemOnUI({
          stop,
          eventGuid,
          telGuid: G.getPropFromObject(GC.FIELD_TEL_GUID, stop),
          data: R.assoc(GC.FIELD_CONTAINER_GUID, containerGuid, data),
        });

        closeModal();
      };

      const componentProps = {
        openModal,
        closeModal,
        submitAction,
        withContainer: true,
        withItemTemplateSearch: true,
        branchGuid: G.getBranchGuidFromObject(currentRoute),
        containerOptions: getContainerOptions(availableContainers),
      };

      const component = <ItemForm {...componentProps} />;

      const modal = {
        p: '0px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getAddTitle(['titles:item', 'Item']),
        },
      };

      openModal(modal);
    },
    handleAddNewCloStop: (props: Object) => (options: Object) => {
      const { tel, openModal, closeModal } = props;
      const { branchGuid, eventType, loadGuid } = options;

      const order = R.compose(
        R.inc,
        R.length,
        R.values,
        R.path([GC.FIELD_LOAD_STOPS]),
      )(tel);
      const initialValues = {
        eventType,
        [GC.FIELD_LOAD_FCFS]: true,
        [GC.FIELD_TEL_EVENT_INDEX]: order,
      };

      const handleSubmit = (location: Object) => {
        closeModal();
        props.addNewCloStopToRouteBuilderTel({
          location,
          loadGuid,
          eventType,
          branchGuid,
          loadType: GC.LOAD_TYPE_CLO,
          telGuid: G.getGuidFromObject(tel),
        });
      };
      const modalContent = (
        <StopForm
          {...props}
          branchGuid={branchGuid}
          withoutReferences={true}
          submitHandler={handleSubmit}
          initialValues={initialValues}
          fromPage={GC.PAGE_DISPATCH_PLANNER}
          configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]}
        />
      );
      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          width: 700,
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handleToggleFullScreenView: ({ toggleFullScreenView }: Object) => () => (
      toggleFullScreenView()
    ),
    handleClickRemoveLoad: (props: Object) => (tel: Object) => (
      props.removeTelFromRouteBuilder(tel)
    ),
    handleClickLoadMap: (props: Object) => (tel: Object) => {
      const { closeModal } = props;
      const component = <LoadMap {...tel} close={closeModal} />;
      const modal = {
        p: '0px',
        component,
        options: {
          height: 'auto',
          width: '100vh',
          maxWidth: '100%',
          overflow: 'auto',
        },
      };
      props.openModal(modal);
    },
    handleRecalculateLoadDistances: ({ tel, recalculateTelDistancesRequest }: Object) => () => (
      recalculateTelDistancesRequest(G.getGuidFromObject(tel))
    ),
    handleClickAddNewStop: (props: Object) => (options: Object) => {
      const { tel, openModal, closeModal } = props;
      const { branchGuid, eventType, loadGuid, loadType } = options;

      const order = R.compose(
        R.inc,
        R.length,
        R.values,
        R.path([GC.FIELD_LOAD_STOPS]),
      )(tel);
      const initialValues = {
        eventType,
        [GC.FIELD_LOAD_FCFS]: true,
        [GC.FIELD_TEL_EVENT_INDEX]: order,
      };

      const handleSubmit = (location: Object) => {
        closeModal();
        props.addNewStopToRouteBuilderTel({
          location,
          loadGuid,
          loadType,
          eventType,
        });
      };
      const modalContent = (
        <StopForm
          {...props}
          branchGuid={branchGuid}
          withoutReferences={true}
          submitHandler={handleSubmit}
          initialValues={initialValues}
          fromPage={GC.PAGE_DISPATCH_PLANNER}
          configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]}
        />
      );
      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          width: 700,
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };
      openModal(modal);
    },
    handleSelectCloToAddNewCloStop: (props: Object) => (options: Object) => {
      const cloOptions = R.compose(
        R.map((clo: Object) => ({
          value: R.prop(GC.FIELD_GUID, clo),
          label: R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], clo),
        })),
        R.values,
        R.pathOr({}, ['currentRoute', GC.SYSTEM_LIST_CLOS]),
      )(props);
      const component = (
        <SelectDropdownForm
          options={cloOptions}
          cancelAction={props.closeModal}
          fieldLabel={G.getWindowLocale('titles:select-clo', 'Select CLO')}
          submitAction={(value: string) => {
            props.closeModal();
            props.handleAddNewCloStop(R.assoc(GC.FIELD_LOAD_GUID, value, options));
          }}
        />
      );
      const modal = { p: 15, component, options: {} };
      props.openModal(modal);
    },
    handleClickEditStop: (props: Object) => (event: Object) => {
      const { tel, openModal, closeModal, updateStopOnRouteBuilderTelRequest } = props;

      const initialValues = R.mergeRight(
        event,
        G.resetLocationTypeFromDropdownOption(G.getPropFromObject(GC.FIELD_LOCATION, event)),
      );

      const telGuid = G.getGuidFromObject(tel);

      const handleSubmit = (location: Object) => {
        closeModal();

        updateStopOnRouteBuilderTelRequest({
          telGuid,
          stop: {
            location,
            loadGuid: telGuid,
            loadType: GC.LOAD_TYPE_TEL,
            eventGuid: G.getGuidFromObject(event),
            [GC.FIELD_STOP_TYPE]: R.prop(GC.FIELD_STOP_TYPE, event),
          },
        });
      };

      const modalContent = (
        <StopForm
          {...props}
          withoutReferences={true}
          submitHandler={handleSubmit}
          initialValues={initialValues}
          fromPage={GC.PAGE_DISPATCH_PLANNER}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
          configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]}
        />
      );

      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          width: 700,
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };

      openModal(modal);
    },
    handleClickRemoveStop: (props: Object) => (stop: Object) => {
      props.removeStopFromRouteBuilderTel(stop);
      props.sortTelEvents(props.tel.guid);
      props.recalculateTelDistancesRequest(props.tel.guid);
    },
    handleClickAddTerminal: (props: Object) => (tel: Object) => {
      const options = H.getAvailableExistedTerminalOptions(props.existedTerminalOptions, tel[GC.FIELD_LOAD_STOPS]);
      const component = (
        <SelectTerminal
          options={options}
          closeModal={props.closeModal}
          handleSubmitAction={(data: Object) => props.addExistedTerminalToTel(R.assoc(
            'telGuid', R.prop('guid', tel), data,
          ))}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:choose-terminal-and-type', 'Please, choose Terminal and Stop Type'),
        },
      };
      props.openModal(modal);
    },
    handleClickAddItems: (props: Object) => (stop: Object) => {
      const { tel, openModal, currentRoute, itemsForPickup, handleCreateNewItem } = props;

      const { stopId, stopType, eventType } = stop;

      let component;
      let options;

      const isCloStop = G.isLoadTypeClo(stop);
      const isPickup = G.isEventTypePickup(eventType);
      const isTerminal = G.isStopTypeTerminal(stopType);

      if (R.and(R.not(isPickup), isCloStop)) {
        component = <CloItemsForDrop stop={stop} {...props} />;

        options = {
          title: G.getWindowLocale('titles:select-items-for-drop', 'Select Items For Drop'),
        };
      } else if (isPickup) {
        let availableClosForPickup = null;
        const availableItems = H.getAvailableItemsForPickup(stop, itemsForPickup);

        if (isTerminal) {
          availableClosForPickup = H.getAvailableTerminalPickupClos(
            tel,
            R.values(R.path([GC.SYSTEM_LIST_TELS], currentRoute)),
            stopId,
          );
        }

        if (R.and(G.isNilOrEmpty(availableItems), G.isNilOrEmpty(availableClosForPickup))) {
          return handleCreateNewItem(stop);
        }

        if (G.isNilOrEmpty(availableClosForPickup)) {
          component = <ItemsForPickup {...props} stop={stop} items={availableItems} />;
        } else {
          component = (
            <ItemsClosForPickup
              {...props}
              stop={stop}
              items={availableItems}
              clos={availableClosForPickup}
            />
          );
        }

        options = {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
          title: G.getWindowLocale('titles:select-items-for-pickup', 'Select Items For Pickup'),
        };
      } else {
        let availableClosForDrop = null;

        if (isTerminal) {
          availableClosForDrop = H.getAvailableDropClosWithItems(tel);
        }

        if (G.isNilOrEmpty(availableClosForDrop)) {
          component = <ItemsForDrop stop={stop} {...props} />;
        } else {
          component = (
            <ItemsClosForDrop
              {...props}
              stop={stop}
              clos={availableClosForDrop}
            />
          );
        }

        options = {
          title: G.getWindowLocale('titles:select-items-for-drop', 'Select Items For Drop'),
        };
      }

      const modal = {
        p: 15,
        options,
        component,
      };

      openModal(modal);
    },
    handleRemoveItem: ({ removeItemFromStop }: Object) => (stop: Object, id: Object) => (
      removeItemFromStop({ stop, id })
    ),
    handleRemoveCloItem: ({ tel, removeCloItemFromStop }: Object) => (stopGuid: Object, cloGuid: string) => (
      removeCloItemFromStop({ stopGuid, cloGuid, telGuid: tel.guid })
    ),
    handleEditItem: (props: Object) => (stop: Object, id: Object) => {
      const { items, editItem, openModal, closeModal } = props;

      const { eventType, droppedContainers, pickedUpContainers } = stop;

      const availableContainers = G.ifElse(G.isEventTypePickup(eventType), pickedUpContainers, droppedContainers);

      const submitAction = (data: Object) => {
        const containerGuid = getContainerGuid(data, availableContainers);

        editItem(R.assoc(GC.FIELD_CONTAINER_GUID, containerGuid, data));

        closeModal();
      };

      const componentProps = {
        openModal,
        closeModal,
        submitAction,
        withContainer: true,
        initialValues: G.getPropFromObject(id, items),
        containerOptions: getContainerOptions(availableContainers),
      };

      const component = <ItemForm {...componentProps} />;

      const modal = {
        p: '0px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getEditTitle(['titles:item', 'Item']),
        },
      };

      openModal(modal);
    },
    handleEditContainer: (props: Object) => (stop: Object, id: string) => {
      const { tel, openModal, closeModal, containers, containerTypes, updateContainer } = props;

      const initialContainer = R.pathOr({}, [id], containers);
      const component = (
        <ContainerForm
          openModal={openModal}
          closeModal={closeModal}
          containerTypes={containerTypes}
          initialContainer={initialContainer}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
          submitAction={({ pickedUpContainers }: Object) => {
            const data = R.compose(
              R.assoc(GC.FIELD_CONTAINER_INTERNAL_ID, id),
              R.mergeRight(initialContainer),
              R.head,
            )(pickedUpContainers);
            updateContainer(data);
            closeModal();
          }}
        />
      );
      const modal = {
        p: '10px',
        component,
        options: {
          width: 615,
          title: G.getWindowLocale('titles:edit-container', 'Edit Container'),
        },
      };

      openModal(modal);
    },
    handleClickAddDropContainer: (props: Object) => (stop: Object) => {
      const { tel, openModal, closeModal, currentRoute, addDropContainersToStop } = props;

      const isCloStop = G.isLoadTypeClo(stop);
      let containerOptions;
      let availableContainers;

      if (isCloStop) {
        availableContainers = H.getAvailableContainersForCloDrop(
          currentRoute.tels,
          R.prop(GC.FIELD_CLO_GUID, stop),
        );
        containerOptions = R.compose(
          R.map((container: Object) => ({
            value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
            label: `${H.getContainerInfo(container)} (${
              R.path([
                GC.SYSTEM_LIST_CLOS,
                R.prop(GC.FIELD_LOAD_GUID, container),
                GC.FIELD_PRIMARY_REFERENCE,
                GC.FIELD_VALUE,
              ], currentRoute)})`,
          })),
          R.filter(R.propEq(R.prop(GC.FIELD_CLO_GUID, stop), GC.FIELD_LOAD_GUID)),
        )(availableContainers);
      } else {
        availableContainers = H.getAvailableContainersForDrop(tel);
        containerOptions = R.map((container: Object) => ({
          value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
          label: `${H.getContainerInfo(container)} (${
            R.path([
              GC.SYSTEM_LIST_CLOS,
              R.prop(GC.FIELD_LOAD_GUID, container),
              GC.FIELD_PRIMARY_REFERENCE,
              GC.FIELD_VALUE,
            ], currentRoute)})`,
        }), availableContainers);
      }

      const component = (
        <SelectDropdownForm
          isMulti={true}
          cancelAction={closeModal}
          options={containerOptions}
          fieldLabel={G.getWindowLocale('titles:select-containers', 'Select Containers')}
          submitAction={(value: Array) => {
            const droppedContainers = R.compose(
              R.values,
              R.pick(value),
              R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
            )(availableContainers);
            addDropContainersToStop({
              droppedContainers,
              telGuid: G.getGuidFromObject(tel),
              eventGuid: G.getGuidFromObject(stop),
              droppedContainerIds: R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), droppedContainers),
            });
            closeModal();
          }}
        />
      );
      const modal = { p: 15, component, options: {} };
      openModal(modal);
    },
    handleClickAddPickupContainer: (props: Object) => (stop: Object) => {
      const {
        tel,
        openModal,
        closeModal,
        currentRoute,
        containerTypes,
        addNewContainerToStop,
        addPickupContainersToStop,
      } = props;

      const isCloStop = G.isLoadTypeClo(stop);
      let component;
      let options = {};

      if (isCloStop) {
        component = (
          <ContainerForm
            openModal={openModal}
            closeModal={closeModal}
            containerTypes={containerTypes}
            branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
            submitAction={(values: Object) => {
              addNewContainerToStop({
                telGuid: G.getGuidFromObject(tel),
                eventGuid: G.getGuidFromObject(stop),
                container: H.createContainerFromFormValuesAndStop(
                  R.head(R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, values)),
                  stop,
                ),
              });
              closeModal();
            }}
          />
        );
        options = {
          width: 615,
          title: G.getWindowLocale('titles:add-container', 'Add Container'),
        };
      } else {
        const availableContainers = H.getAvailableContainersForPickup(stop, currentRoute);
        const containerOptions = R.map((container: Object) => ({
          value: R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
          label: `${H.getContainerInfo(container)} (${
            R.path([
              GC.SYSTEM_LIST_CLOS,
              R.prop(GC.FIELD_LOAD_GUID, container),
              GC.FIELD_PRIMARY_REFERENCE,
              GC.FIELD_VALUE,
            ], currentRoute)})`,
        }), availableContainers);
        component = (
          <SelectDropdownForm
            isMulti={true}
            cancelAction={closeModal}
            options={containerOptions}
            fieldLabel={G.getWindowLocale('titles:select-containers', 'Select Containers')}
            submitAction={(value: Array) => {
              const pickedUpContainers = R.compose(
                R.values,
                R.pick(value),
                R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
              )(availableContainers);
              addPickupContainersToStop({
                pickedUpContainers,
                telGuid: G.getGuidFromObject(tel),
                eventGuid: G.getGuidFromObject(stop),
                pickedUpContainerIds: R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), pickedUpContainers),
              });
              closeModal();
            }}
          />
        );
      }

      const modal = { p: 15, options, component };
      openModal(modal);
    },
    handleShowLinkedOrders: (props: Object) => () => {
      const { tel, openModal, closeModal, currentRoute, uncheckCrossDockClos } = props;

      const component = (
        <LinkedOrders
          openModal={openModal}
          closeModal={closeModal}
          uncheckCrossDockClos={uncheckCrossDockClos}
          clos={R.pick(tel.linkedCloGuids, currentRoute.linkedClos)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 1100,
          title: G.getWindowLocale('titles:crossdock-clos', 'Cross Dock Orders'),
        },
      };
      openModal(modal);
    },
    handleOpenCloDetails: ({ setExpandedContainerOptions }: Object) => (guid: string) => setExpandedContainerOptions({
      opened: true,
      options: { guid },
      visitPageGuid: guid,
      componentType: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    }),
  }),
  withAddTrailer,
  withPropsOnChange(
    ['eventsCount'],
    ({ tel, currentRoute, currentRouteInitialState, recalculateTelDistancesRequest }: Object) => {
      const guid = G.getGuidFromObject(tel);
      if (G.notEquals(
        R.path([GC.SYSTEM_LIST_TELS, guid], currentRoute),
        R.path([GC.SYSTEM_LIST_TELS, guid], currentRouteInitialState),
      )) recalculateTelDistancesRequest(guid);
    },
  ),
  withPropsOnChange(
    ['mode'],
    ({ setTelVisibility }: Object) => setTelVisibility(null),
  ),
  pure,
);

const Load = enhance((props: Object) => {
  const {
    tel,
    mode,
    items,
    withError,
    containers,
    currentRoute,
    cleanTelRate,
    telVisibility,
    sortTelEvents,
    itemsExpanded,
    handleEditItem,
    handleRemoveItem,
    setTelVisibility,
    handleClickLoadMap,
    handleRemoveCloItem,
    handleClickAddItems,
    handleClickEditStop,
    handleEditContainer,
    handleOpenCloDetails,
    withoutHeaderActions,
    handleClickAddTrailer,
    removeTrailerFromStop,
    handleClickRemoveStop,
    handleClickRemoveLoad,
    handleDispatchTelRate,
    handleClickAddNewStop,
    handleClickAddTerminal,
    handleAddTelDriverRate,
    handleShowLinkedOrders,
    handleAddTelCarrierRate,
    handleMultiCarrierRates,
    removeContainerFromStop,
    currentRouteInitialState,
    handleUpdateTelDriverRate,
    handleUpdateTelCarrierRate,
    handleCancelDispatchTelRate,
    handleClickAddDropContainer,
    handleClickAddPickupContainer,
    handleSelectCloToAddNewCloStop,
    handleRecalculateLoadDistances,
  } = props;

  const isExpanded = H.checkTelExpandedOnPlanner(
    R.values(R.path([GC.FIELD_TEL, GC.FIELD_LOAD_STOPS], props)),
    mode,
    telVisibility,
  );
  const guid = G.getGuidFromObject(tel);
  const telChanged = G.notEquals(
    R.path([GC.SYSTEM_LIST_TELS, guid], currentRoute),
    R.path([GC.SYSTEM_LIST_TELS, guid], currentRouteInitialState),
  );

  return (
    <Box
      border={G.ifElse(G.isFalse(withError), 'unset', '1px solid')}
      borderColor={G.ifElse(G.isFalse(withError), 'unset', G.getTheme('colors.light.mainRed'))}
    >
      <LoadHeader
        tel={tel}
        loadGuid={guid}
        error={withError}
        isExpanded={isExpanded}
        loadName={tel.loadName}
        telChanged={telChanged}
        cleanTelRate={cleanTelRate}
        sortTelEvents={sortTelEvents}
        setTelVisibility={setTelVisibility}
        branchGuid={tel[GC.FIELD_BRANCH_GUID]}
        handleDispatch={handleDispatchTelRate}
        handleClickLoadMap={handleClickLoadMap}
        loadTotal={H.getTelTotalInfo(tel, items)}
        withoutHeaderActions={withoutHeaderActions}
        handleClickRemoveLoad={handleClickRemoveLoad}
        handleClickAddNewStop={handleClickAddNewStop}
        handleClickAddTerminal={handleClickAddTerminal}
        handleAddTelDriverRate={handleAddTelDriverRate}
        handleShowLinkedOrders={handleShowLinkedOrders}
        handleAddTelCarrierRate={handleAddTelCarrierRate}
        handleMultiCarrierRates={handleMultiCarrierRates}
        handleCancelDispatch={handleCancelDispatchTelRate}
        handleAddNewCloStop={handleSelectCloToAddNewCloStop}
        handleUpdateTelDriverRate={handleUpdateTelDriverRate}
        handleUpdateTelCarrierRate={handleUpdateTelCarrierRate}
        handleRecalculateLoadDistances={handleRecalculateLoadDistances}
      />
      {
        isExpanded &&
        <Droppable droppableId={guid} direction='horizontal'>
          {(provided: Object, snapshot: Object) => (
            <Flex ref={provided.innerRef}>
              {
                props.withoutHeaderActions &&
                <LoadInnerWithRenderer
                  tel={tel}
                  items={items}
                  snapshot={snapshot}
                  clos={currentRoute.clos}
                  itemsExpanded={itemsExpanded}
                  handleEditItem={handleEditItem}
                  handleRemoveItem={handleRemoveItem}
                  handleRemoveCloItem={handleRemoveCloItem}
                  handleClickAddItems={handleClickAddItems}
                  handleClickEditStop={handleClickEditStop}
                  handleEditContainer={handleEditContainer}
                  handleClickRemoveStop={handleClickRemoveStop}
                />
              }
              {
                R.not(props.withoutHeaderActions) &&
                <LoadInner
                  tel={tel}
                  items={items}
                  noDimmer={true}
                  error={withError}
                  snapshot={snapshot}
                  showComponent={true}
                  containers={containers}
                  clos={currentRoute.clos}
                  itemsExpanded={itemsExpanded}
                  handleEditItem={handleEditItem}
                  handleRemoveItem={handleRemoveItem}
                  handleClickEditStop={handleClickEditStop}
                  handleClickAddItems={handleClickAddItems}
                  handleRemoveCloItem={handleRemoveCloItem}
                  handleEditContainer={handleEditContainer}
                  handleOpenCloDetails={handleOpenCloDetails}
                  removeTrailerFromStop={removeTrailerFromStop}
                  handleClickAddTrailer={handleClickAddTrailer}
                  handleClickRemoveStop={handleClickRemoveStop}
                  removeContainerFromStop={removeContainerFromStop}
                  handleClickAddDropContainer={handleClickAddDropContainer}
                  handleClickAddPickupContainer={handleClickAddPickupContainer}
                />
              }
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      }
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  items: makeSelectCurrentRouteItems(state),
  currentRoute: makeSelectCurrentRoute(state),
  itemsForDrop: makeSelectItemsForDrop(state),
  routeChanged: makeSelectRouteChanged(state),
  itemsForPickup: makeSelectItemsForPickup(state),
  optionsForSelect: makeSelectOptionsForSelect(state),
  defaultUomFields: makeSelectDefaultUomFields(state),
  containers: makeSelectCurrentRouteContainers(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  existedTerminalOptions: makeSelectExistedTerminalOptions(state),
  currentRouteInitialState: makeSelectCurrentRouteInitialState(state),
});

const actions = {
  editItem,
  openModal,
  openLoader,
  closeModal,
  closeLoader,
  cleanTelRate,
  sortTelEvents,
  addNewItemOnUI,
  addItemsForDrop,
  updateContainer,
  addItemsForPickup,
  addTrailersToStop,
  removeItemFromStop,
  addItemsClosForDrop,
  uncheckCrossDockClos,
  addItemsClosForPickup,
  removeCloItemFromStop,
  addNewContainerToStop,
  removeTrailerFromStop,
  dispatchTelRateRequest,
  addExistedTerminalToTel,
  removeContainerFromStop,
  addDropContainersToStop,
  addPickupContainersToStop,
  removeTelFromRouteBuilder,
  setExpandedContainerOptions,
  addNewStopToRouteBuilderTel,
  dispatchCarrierTelRateRequest,
  removeStopFromRouteBuilderTel,
  recalculateTelDistancesRequest,
  addNewCloStopToRouteBuilderTel,
  updateStopOnRouteBuilderTelRequest,
};

export const LoadWithAsyncData = connect(mapStateToProps, actions)(withAsyncInitialDataOnDidMount(Load));

export default connect(mapStateToProps, actions)(Load);
