import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
// components
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature carrier-profile
import { useProfile } from './hooks/use-profile';
import TabsGroups from './components/tabs-groups';
import GeneralDetails from './forms/general-details';
import CarrierProfileWrapper from './components/wrapper';
import {
  setInitialState,
  updateGeneralDetailsRequest,
  getAvailableDocumentTypesRequest,
} from './actions';
import {
  makeSelectConfigGroup,
  makeSelectGeneralDetails,
  makeSelectAvailableDocumentTypes,
} from './selectors';
//////////////////////////////////////////////////

const CarrierProfile = (props: Object) => {
  const { handleGoBack, expandedContainer, closeExpandedContainer } = props;

  const [internalDivision, setInternalDivision] = useState(false);

  const configGroup = useSelector(makeSelectConfigGroup());
  const generalDetails = useSelector(makeSelectGeneralDetails());
  const availableDocumentTypes = useSelector(makeSelectAvailableDocumentTypes());

  const { guid, name, [GC.FIELD_BRANCH_GUID]: branchGuid } = generalDetails;

  const {
    dispatch,
    handleClose,
    commonActions,
    setSaveAndClose,
    handleUpdateGeneralDetails,
  } = useProfile({ isCarrier: true, generalDetails, handleGoBack, closeExpandedContainer });

  useEffect(() => {
    setInternalDivision(R.prop(GC.FIELD_INTERNAL_DIVISION, generalDetails));
  }, [R.prop(GC.FIELD_INTERNAL_DIVISION, generalDetails)]);

  useEffect(() => {
    if (R.and(internalDivision, R.isNil(availableDocumentTypes))) {
      dispatch(getAvailableDocumentTypesRequest());
    }
  }, [internalDivision, availableDocumentTypes]);

  if (G.isNilOrEmpty(guid)) return <Box width='100%' height='100vh' bg='bgGrey' />;

  return (
    <CarrierProfileWrapper
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      setSaveAndClose={setSaveAndClose}
      expandedContainer={expandedContainer}
    >
      <GeneralDetails
        {...commonActions}
        dispatch={dispatch}
        initialValues={generalDetails}
        setInternalDivision={setInternalDivision}
        submitAction={handleUpdateGeneralDetails}
      />
      <TabsGroups
        {...commonActions}
        carrierName={name}
        dispatch={dispatch}
        primaryObjectGuid={guid}
        configGroup={configGroup}
        internalDivision={internalDivision}
        primaryObjectBranchGuid={branchGuid}
        expandedContainer={expandedContainer}
        closeExpandedContainer={closeExpandedContainer}
        availableDocumentTypes={availableDocumentTypes}
      />
    </CarrierProfileWrapper>
  );
};

export default CarrierProfile;

