import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderNothing, renderComponent } from 'react-recompose';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => ({
  loggedIn: state.auth.loggedIn,
});

const userAuthHoc = (WrappedComponent: React.ComponentType<any>) => {
  const enhance = compose(
    branch(
      (props: Object) => props.loggedIn,
      renderComponent(WrappedComponent),
      renderNothing,
  ));
  return connect(mapStateToProps)(enhance(WrappedComponent));
};

export default userAuthHoc;
