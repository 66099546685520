import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ChargeFormFooter } from '../../../components/form-footer';
import { openModal, closeModal } from '../../../components/modal/actions';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpen: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <form onSubmit={closeModal}>
          <h2>Form fields...</h2>
          <ChargeFormFooter />
        </form>
      );

      const modal = {
        p: '0px',
        component,
        options: {
          width: 320,
          movable: false,
          title: 'Modal title',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const ChargeFormFooterComponent = (props: Object) => {
  const description = '<ChargeFormFooter /> component renders a standardized footer for charging purposes or any transactional submission within a form.';

  const usage = (
    <ul>
      <li>Parameter `<i>cancelStyles</i>` are styles for the cancel button.</li>
      <li>Parameter `<i>boxStyles</i>` are styles for the container box of the footer.</li>
      <li>Parameter `<i>submitStyles</i>` are styles for the submission (charge) button.</li>
      <li>Parameter `<i>submitDisabled</i>` is a boolean determining if the submission action is disabled.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ChargeFormFooter'
      description={description}
      path='src/components/charge-form-footer'
    >
      <CustomButton onClick={props.handleOpen}>Open modal with component `ChargeFormFooter`</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(ChargeFormFooterComponent));
