import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
// import * as GC from '../../../constants';
// ui
import { Box, Flex, ActionButton, scrollableContainerCss4px } from '../../../ui';
// feature dispatch-planner
import { LocationInfo } from './stop';
import CrossDockLocationForm from './cross-dock-location-form';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpenSelectLocationForm: (props: Object) => () => {
      const {
        locations,
        openModal,
        closeModal,
        setLocations,
        locationOptions } = props;
      const component = (
        <CrossDockLocationForm
          closeModal={closeModal}
          initialValues={locations}
          setLocations={setLocations}
          locationOptions={locationOptions}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-location', 'Select Locations!'),
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const CrossDockLocationSelect = enhance((props: Object) => {
  const { locations, handleOpenSelectLocationForm } = props;

  return (
    <Flex>
      <ActionButton
        mx={16}
        p='2px 6px'
        height={24}
        fontSize={10}
        bgColor='none'
        background='none'
        border='1px solid'
        borderRadius='5px'
        textTransform='uppercase'
        onClick={handleOpenSelectLocationForm}
        textColor={G.getTheme('colors.dark.blue')}
        borderColor={G.getTheme('colors.dark.blue')}
      >
        {G.getWindowLocale('titles:select-location', 'Select Locations!')}
      </ActionButton>
      {
        G.isNotNilAndNotEmpty(locations) &&
        <Flex
          overflowX='auto'
          width='calc(100vw - 705px)'
          css={scrollableContainerCss4px}
        >
          {
            locations.map((templateId: string, i: number) => (
              <PopperComponent
                key={i}
                type='hover'
                position='right'
                borderColor={G.getTheme('listActions.borderColor')}
                content={<LocationInfo width='100%' location={R.path(['crossDockLocations', templateId], props)} />}
              >
                <Box
                  mr='6px'
                  p='2px 6px'
                  fontSize={12}
                  borderRadius='3px'
                  bg={G.getTheme('colors.light.lightGrey')}
                >
                  {templateId}
                </Box>
              </PopperComponent>
            ))
          }
        </Flex>
      }
    </Flex>
  );
});

export default CrossDockLocationSelect;
