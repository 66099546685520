import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
// components
import { FormFooter2 } from '../../../../components/form-footer';
import { FormSectionHeader } from '../../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet/profile
import {
  truckFieldsGroups,
  truckDefaultFields,
  trailerFieldsGroups,
  trailerDefaultFields,
  truckValidationSchema,
  trailerValidationSchema,
} from './settings';
//////////////////////////////////////////////////

const useFormSettings = (props: Object) => {
  const { type, submitAction, initialValues } = props;

  const isTrailer = R.equals(type, 'trailer');

  const defaultFields = G.ifElse(isTrailer, trailerDefaultFields, truckDefaultFields);

  const onSubmit = (values: Object, { setSubmitting }: Object) => {
    const failCallback = () => setSubmitting(false);
    const successCallback = () => setSubmitting(false);

    submitAction(G.mapObjectEmptyStringFieldsToNull(values), { failCallback, successCallback });
  };

  const validationSchema = G.ifElse(isTrailer, trailerValidationSchema, truckValidationSchema);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: R.mergeDeepRight(defaultFields, R.filter(R.isNotNil, initialValues)),
  });

  const temperatureControl = R.path(['values', GC.FIELD_TEMPERATURE_CONTROL], formik);

  const fieldsGroups = useMemo(() => {
    if (isTrailer) {
      return G.ifElse(temperatureControl, trailerFieldsGroups, R.take(1, trailerFieldsGroups));
    }

    return truckFieldsGroups;
  }, [isTrailer, temperatureControl]);

  return { ...formik, fieldsGroups };
};

const SpecificationForm = (props: Object) => {
  const { formWidth = 1030, optionsForSelect } = props;

  const formSettings = useFormSettings(props);

  const { fieldsGroups, isSubmitting, handleSubmit } = formSettings;

  return (
    <form onSubmit={handleSubmit}>
      {
        fieldsGroups.map(({ fieldSettings, formSectionTitle }: Object, index: number) => (
          <FormSectionHeader
            key={index}
            expanded={true}
            title={formSectionTitle}
          >
            <Fieldset2
              {...optionsForSelect}
              {...G.getFormikPropsToFieldset(formSettings)}
              key={index}
              fields={fieldSettings}
              fieldsWrapperStyles={{
                mt: 25,
                px: 15,
                flexWrap: 'wrap',
                width: formWidth,
              }}
            />
          </FormSectionHeader>
        ))
      }
      <FormFooter2 boxStyles={{ px: 15, width: formWidth }} submitDisabled={isSubmitting} />
    </form>
  );
};

export default SpecificationForm;
