import * as R from 'ramda';
import React from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// common
import { createLoadDocumentsRequest } from '../../../../common/actions';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import DispatchCardComponent from '../../../dispatch-details-new/components/cards/dispatch';
import DocumentsCardComponent from '../../../dispatch-details-new/components/cards/documents';
import { LoadPaymentsCardComponent } from '../../../dispatch-details-new/components/cards/payments';
import { LeftHeaderComponent } from '../../../dispatch-details-new/order/hocs/with-order-cards-props';
import { DispatchHeaderActionButtons } from '../../../dispatch-details-new/load/hocs/with-load-cards-props';
import {
  withLoadAddDocumentWithoutConnect,
  withLoadDocumentActionsWithoutConnect } from '../../../dispatch-details-new/load/hocs/with-document-actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, Grid, ActionButton } from '../../../../ui';
// feature dispatch-board-new/order
import { makeSelectUseBillToContactEmail } from '../../selectors';
import withDispatchBoardLoadActions from '../../hocs/with-load-actions';
import {
  updateLoadDocumentRequest,
  removeLoadDocumentRequest,
  getLoadDocumentListRequest,
  downloadLoadDocumentRequest } from '../actions';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const Card = ({ children }: Object) => (
  <Box
    width='100%'
    height={436}
    borderRadius='4px'
    background={whiteColor}
    boxShadow='0 0 5px 0 rgba(0, 0, 0, 0.2)'
  >
    {children}
  </Box>
);

const CardHeader = ({ children }: Object) => (
  <Flex
    px='8px'
    height={36}
    borderTopLeftRadius='4px'
    borderTopRightRadius='4px'
    justifyContent='space-between'
    background={G.getTheme('colors.transparentRed')}
  >
    {children}
  </Flex>
);

const documentsMapStateToProps = (state: Object) => createStructuredSelector({
  useBillToContactEmail: makeSelectUseBillToContactEmail(state),
});

const enhanceDocumentsCard = compose(
  connect(
    documentsMapStateToProps,
    {
      updateLoadDocumentRequest,
      removeLoadDocumentRequest,
      createLoadDocumentsRequest,
      getLoadDocumentListRequest,
      downloadLoadDocumentRequest,
    },
  ),
  withLoadDocumentActionsWithoutConnect,
);

const headerActionButtonStyles = {
  px: '8px',
  height: 20,
  fontSize: 12,
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
};

const DocumentsHeaderActionButton = withLoadAddDocumentWithoutConnect(({ handleAddDocument }: Object) => (
  <AuthWrapper has={[PC.TEL_DOCUMENT_WRITE]}>
    <ActionButton {...headerActionButtonStyles} type='button' onClick={handleAddDocument}>
      {G.getWindowLocale('titles:add-document', 'Add Document')}
    </ActionButton>
  </AuthWrapper>
));

const DocumentsCard = enhanceDocumentsCard((props: Object) => (
  <Card>
    <CardHeader>
      <LeftHeaderComponent
        iconName='file'
        text={G.getWindowLocale('titles:documents', 'Documents')}
      />
      <DocumentsHeaderActionButton {...props} />
    </CardHeader>
    <DocumentsCardComponent {...props} showOrderName={true} />
  </Card>
));

const DispatchCard = withDispatchBoardLoadActions((props: Object) => {
  const {
    load,
    autodialApp,
    declineLoadReasons,
    acceptTelRateRequest,
    declineTelRateRequest,
    suspendResumeCarrierRateEmailsRequest,
  } = props;

  const showAcceptDeclineSection = R.and(
    R.pathEq(GC.LOAD_STATUS_DISPATCHED, [GC.FIELD_STATUS], load),
    R.pathEq(GC.RATE_STATUS_PENDING, [GC.FIELD_STATUS], load.selectedRate),
  );

  const guid = G.getGuidFromObject(load);

  return (
    <Card>
      <CardHeader>
        <LeftHeaderComponent
          iconName='dispatch'
          text={G.getWindowLocale('titles:dispatch', 'Dispatch')}
        />
        <DispatchHeaderActionButtons
          printHidden={true}
          selectedRate={load.selectedRate}
          loadStatus={R.prop(GC.FIELD_STATUS, load)}
          rateType={R.path(['selectedRate', GC.FIELD_TYPE], load)}
          handleApprove={() => R.path([GC.DISPATCH_LOAD_ACTION], props)(load)}
          handleDispatch={() => R.path([GC.DISPATCH_LOAD_ACTION], props)(load)}
          handleAddDriverRate={() => R.path([GC.ADD_DRIVER_RATE_ACTION], props)(load)}
          suspendResumeCarrierRateEmailsRequest={suspendResumeCarrierRateEmailsRequest}
          handleAddCarrierRate={() => R.path([GC.ADD_CARRIER_RATE_ACTION], props)(load)}
          handleEditRate={() => R.path([GC.EDIT_DRIVER_CARRIER_RATE_ACTION], props)(load)}
          handleCancelDispatch={() => R.path([GC.CANCEL_DISPATCHED_LOAD_ACTION], props)(load)}
        />
      </CardHeader>
      <DispatchCardComponent
        loadGuid={guid}
        fromDispatchBoard={true}
        autodialApp={autodialApp}
        declineReasonOptions={declineLoadReasons}
        selectedCarrierDriverRate={load.selectedRate}
        showAcceptDeclineSection={showAcceptDeclineSection}
        acceptLoadDriverCarrierRateRequest={(data: Object) => (
          acceptTelRateRequest(R.assoc(GC.FIELD_LOAD_GUID, guid, data))
        )}
        declineLoadDriverCarrierRateRequest={(data: Object) => (
          declineTelRateRequest(R.assoc(GC.FIELD_TEL_GUID, guid, data))
        )}
      />
    </Card>
  );
});

const Cards = (props: Object) => {
  const {
    load,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    loadConfigs,
    normalizedCurrency,
  } = props;

  const { invoices, documents, selectedRate, invoiceTotals } = load;

  const configCurrency = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY);

  const autodialApp = G.getConfigValueFromStore(
    GC.UI_LOADBOARD_AUTODIALAPPNAME,
    R.path(['configGroups', GC.UI_CONFIG_GROUP, 'configs'], loadConfigs),
  );

  const commonComponentProps = {
    load,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
  };

  const documentsProps = {
    ...commonComponentProps,
    documents: R.or(documents, []),
    telGuid: G.getGuidFromObject(load),
    mailPermissions: [PC.SEND_EMAIL_EXECUTE],
    documentPermissions: [PC.TEL_DOCUMENT_WRITE],
  };

  const paymentPermissions = [
    PC.FLEET_RATE_READ,
    PC.FLEET_RATE_WRITE,
    PC.TEL_BILL_TO_READ,
    PC.TEL_BILL_TO_WRITE,
    PC.CARRIER_RATE_READ,
    PC.CARRIER_RATE_WRITE,
  ];

  return (
    <Grid
      gridGap={20}
      gridTemplateColumns='repeat(auto-fill, minmax(360px, 1fr))'
    >
      <AuthWrapper width='100%' has={paymentPermissions}>
        <Card>
          <CardHeader>
            <LeftHeaderComponent
              iconName='dollar'
              text={G.getWindowLocale('titles:payments', 'Payments')}
            />
          </CardHeader>
          <LoadPaymentsCardComponent
            load={load}
            addDisabled={true}
            editDisabled={true}
            invoices={invoices}
            autodialApp={autodialApp}
            selectedRate={selectedRate}
            invoiceTotals={invoiceTotals}
            configCurrency={configCurrency}
            normalizedCurrency={normalizedCurrency}
          />
        </Card>
      </AuthWrapper>
      <AuthWrapper
        width='100%'
        has={[PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]}
      >
        <DispatchCard load={load} autodialApp={autodialApp} />
      </AuthWrapper>
      <AuthWrapper width='100%' has={[PC.TEL_DOCUMENT_READ, PC.TEL_DOCUMENT_WRITE]}>
        <DocumentsCard {...documentsProps} />
      </AuthWrapper>
    </Grid>
  );
};

export default Cards;
