import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet/vendor
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  configs: {},
  contacts: [],
  documents: [],
  insurances: [],
  accessorials: [],
  branchConfigs: {},
  payToLocation: {},
  currentVendor: {},
  compensations: [],
  fleetAssignment: [],
  reportPending: false,
  availableReports: null,
  integrationInfoList: [],
  integrationAuditList: [],
  payrollAssessorialList: [],
  monthlyPayrollDeduction: [],
  [GC.UI_FIELD_REFERENCES]: [],
  assignment: {
    truck: {},
    trailer: {},
  },
  collapsedGroup: {
    contacts: true,
    documents: true,
    accounting: true,
    insurances: true,
    vendorDetails: true,
    payToLocation: true,
    fleetAssignment: true,
    integrationInfo: true,
    integrationAudit: true,
    payrollAssessorials: true,
    monthlyPayrollDeduction: true,
  },
};

const cleanFormStore = (state: Object) => (
  P.$set('currentVendor', {}, state)
);

// configs
const getVendorConfigsSuccess = (state: Object, data: Object) => (
  P.$set('configs', data, state)
);

// vendor
const updateVendorSuccess = (state: Object, data: Object) => (
  P.$set('currentVendor', data, state)
);

const toggleVendorFormGroup = (state: Object, groupName: string) => (
  P.$toggle(`collapsedGroup.${groupName}`, state)
);

const getFleetVendorSuccess = (state: Object, data: Object) => (
  P.$set('currentVendor', data, state)
);

// reference
const createVendorReferenceSuccess = (state: Object, data: Object) => (
  P.$add(GC.UI_FIELD_REFERENCES, R.indexBy(R.prop(GC.FIELD_GUID), data), state)
);

const updateVendorReferenceSuccess = (state: Object, ref: Object) => (
  P.$set(`${GC.UI_FIELD_REFERENCES}.${ref.guid}`, ref, state)
);

const deleteVendorReferenceSuccess = (state: Object, refGuid: string) => (
  P.$drop(`${GC.UI_FIELD_REFERENCES}.${refGuid}`, state)
);

const getVendorReferenceListSuccess = (state: Object, data: Object) => (
  P.$set(GC.UI_FIELD_REFERENCES, R.indexBy(R.prop(GC.FIELD_GUID), data), state)
);

// contact
const createVendorContactSuccess = (state: Object, contact: Object) => (
  P.$add('contacts', contact, state)
);

const updateVendorContactSuccess = (state: Object, data: Object) => {
  const { contacts } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), contacts);

  return P.$set(`contacts.${index}`, data, state);
};

const removeVendorContactSuccess = (state: Object, guid: string) => {
  const { contacts } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), contacts);

  return P.$drop(`contacts.${index}`, state);
};

const getVendorContactListSuccess = (state: Object, contacts: Array) => (
  P.$set('contacts', contacts, state)
);

// pay to location
const getVendorPayToLocationSuccess = (state: Object, data: Object) => (
  P.$set('payToLocation', data, state)
);
// insurance
const getVendorInsuranceListSuccess = (state: Object, data: Object) => (
  P.$set('insurances', data, state)
);

const createVendorInsuranceSuccess = (state: Object, contact: Object) => (
  P.$add('insurances', contact, state)
);

const updateVendorInsuranceSuccess = (state: Object, data: Object) => {
  const { insurances } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), insurances);

  return P.$set(`insurances.${index}`, data, state);
};

const removeVendorInsuranceSuccess = (state: Object, guid: string) => {
  const { insurances } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), insurances);

  return P.$drop(`insurances.${index}`, state);
};

// payroll accessorials
const getPayrollAssessorialListSuccess = (state: Object, data: Object) => (
  P.$set(
    'payrollAssessorialList',
    R.map(R.assoc('expanded', false), data),
    state,
  )
);

// accessorials
const getVendorAccessorialListSuccess = (state: Object, data: Array) => (
  P.$set('accessorials', data, state)
);

const createVendorAccessorialSuccess = (state: Object, data: Object) => (
  P.$add('accessorials', data, state)
);

const updateVendorAccessorialSuccess = (state: Object, data: Object) => {
  const { accessorials } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), accessorials);

  return P.$set(`accessorials.${index}`, data, state);
};

const removeVendorAccessorialSuccess = (state: Object, guid: string) => {
  const { accessorials } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), accessorials);

  return P.$drop(`accessorials.${index}`, state);
};

// compensation
const getVendorCompensationListSuccess = (state: Object, data: Array) => (
  P.$set('compensations', data, state)
);

const createVendorCompensationSuccess = (state: Object, data: Object) => (
  P.$add('compensations', data, state)
);

const updateVendorCompensationSuccess = (state: Object, data: Object) => {
  const { compensations } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), compensations);

  return P.$set(`compensations.${index}`, data, state);
};

const removeVendorCompensationSuccess = (state: Object, guid: Object) => {
  const { compensations } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), compensations);

  return P.$drop(`compensations.${index}`, state);
};

// documents
const getVendorDocumentListSuccess = (state: Object, data: Object) => (
  P.$set('documents', data, state)
);

const createVendorDocumentSuccess = (state: Object, data: Object) => (
  P.$add('documents', data, state)
);

const updateVendorDocumentSuccess = (state: Object, data: Object) => {
  const { documents } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), documents);

  return P.$set(`documents.${index}`, data, state);
};

const removeVendorDocumentSuccess = (state: Object, guid: Object) => {
  const { documents } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), documents);

  return P.$drop(`documents.${index}`, state);
};

// assignment
const assignVendorSuccess = (state: Object, { type, name, guid }: Object) => (
  P.$set(`assignment.${type}`, { name, guid }, state)
);

const unAssignVendorSuccess = (state: Object, { type }: Object) => (
  P.$set(`assignment.${type}`, null, state)
);

// fleet assignment
const getFleetAssignmentListSuccess = (state: Object, data: Object) => {
  const list = R.map((item: Object) => {
    const oldTruckGuid = R.pathOr(null, [GC.SYSTEM_OBJECT_TRUCK, GC.FIELD_GUID], item);
    const oldPrimaryDriverGuid = R.pathOr(null, [GC.SYSTEM_OBJECT_PRIMARY_DRIVER, GC.FIELD_GUID], item);
    const oldSecondaryDriverGuid = R.pathOr(null, [GC.SYSTEM_OBJECT_SECONDARY_DRIVER, GC.FIELD_GUID], item);
    const oldTrailerGuids = R.map(
      R.prop(GC.FIELD_GUID),
      R.pathOr([], [GC.SYSTEM_LIST_TRAILERS], item),
    );

    return R.mergeRight(item, { oldTruckGuid, oldTrailerGuids, oldPrimaryDriverGuid, oldSecondaryDriverGuid });
  }, R.or(data, []));

  return P.$set('fleetAssignment', list, state);
};

// branch configs
const getBranchConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('branchConfigs', data, state)
);

// monthly payroll deduction
const getMonthlyPayrollDeductionSuccess = (state: Object, data: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(R.assoc('expanded', false), data),
    state,
  )
);

const toggleMonthlyPayrollDeductionDetails = (state: Object, { guid }: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('expanded', R.not(entity.expanded), entity);
        }

        return entity;
      },
      state.monthlyPayrollDeduction,
    ),
    state,
  )
);

const getVendorPayrollChargesByMonthlyPaymentSuccess = (state: Object, { data, guid }: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('details', data, entity);
        }

        return entity;
      },
      state.monthlyPayrollDeduction,
    ),
    state,
  )
);

const getVendorPayrollChargesIsPaidOnPayrollSuccess = (state: Object, data: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      state.monthlyPayrollDeduction,
    ),
    state,
  )
);

const getPayrollAccessorialIsPaidOnPayrollSuccess = (state: Object, data: Oject) => (
  P.$set(
    'payrollAssessorialList',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      state.payrollAssessorialList,
    ),
    state,
  )
);

const getPayrollChargesByPayrollAccessorialSuccess = (state: Object, { data, guid }: Object) => (
  P.$set(
    'payrollAssessorialList',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('details', data, entity);
        }

        return entity;
      },
      state.payrollAssessorialList,
    ),
    state,
  )
);

const togglePayrollAssessorialDetails = (state: Object, { guid }: Object) => (
  P.$set(
    'payrollAssessorialList',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('expanded', R.not(entity.expanded), entity);
        }

        return entity;
      },
      state.payrollAssessorialList,
    ),
    state,
  )
);

const sendListToIntegrationSuccess = (state: Object, data: Object) => P.$set(
  'vendorList',
  R.map(
    (entity: Object) => {
      const guid = G.getGuidFromObject(entity);
      const branches = R.pathOr(null, [guid], data);

      if (R.isNil(branches)) return entity;

      return R.assoc(GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME, R.prop(guid, data), entity);
    },
    state.vendorList,
  ),
  state,
);

const getVendorIntegrationListSuccess = (state: Object, data: Object) => (
  P.$set('integrationInfoList', data, state)
);

const getVendorIntegrationAuditListSuccess = (state: Object, data: Object) => (
  P.$set('integrationAuditList', data, state)
);

export default createReducer({
  [A.cleanFormStore]: cleanFormStore,
  // configs
  [A.getVendorConfigsSuccess]: getVendorConfigsSuccess,
  // vendor
  [A.updateVendorSuccess]: updateVendorSuccess,
  [A.getFleetVendorSuccess]: getFleetVendorSuccess,
  [A.toggleVendorFormGroup]: toggleVendorFormGroup,
  [A.sendListToIntegrationSuccess]: sendListToIntegrationSuccess,
  [A.getVendorIntegrationListSuccess]: getVendorIntegrationListSuccess,
  [A.getVendorIntegrationAuditListSuccess]: getVendorIntegrationAuditListSuccess,
  // reference
  [A.deleteVendorReferenceSuccess]: deleteVendorReferenceSuccess,
  [A.createVendorReferenceSuccess]: createVendorReferenceSuccess,
  [A.updateVendorReferenceSuccess]: updateVendorReferenceSuccess,
  [A.getVendorReferenceListSuccess]: getVendorReferenceListSuccess,
  // contact
  [A.createVendorContactSuccess]: createVendorContactSuccess,
  [A.updateVendorContactSuccess]: updateVendorContactSuccess,
  [A.removeVendorContactSuccess]: removeVendorContactSuccess,
  [A.getVendorContactListSuccess]: getVendorContactListSuccess,
  // pay to location
  [A.getVendorPayToLocationSuccess]: getVendorPayToLocationSuccess,
  // insurance
  [A.removeVendorInsuranceSuccess]: removeVendorInsuranceSuccess,
  [A.updateVendorInsuranceSuccess]: updateVendorInsuranceSuccess,
  [A.createVendorInsuranceSuccess]: createVendorInsuranceSuccess,
  [A.getVendorInsuranceListSuccess]: getVendorInsuranceListSuccess,
  // accessorials
  [A.updateVendorAccessorialSuccess]: updateVendorAccessorialSuccess,
  [A.createVendorAccessorialSuccess]: createVendorAccessorialSuccess,
  [A.removeVendorAccessorialSuccess]: removeVendorAccessorialSuccess,
  [A.getVendorAccessorialListSuccess]: getVendorAccessorialListSuccess,
  // payroll accessorials
  [A.togglePayrollAssessorialDetails]: togglePayrollAssessorialDetails,
  [A.getPayrollAssessorialListSuccess]: getPayrollAssessorialListSuccess,
  [A.getPayrollAccessorialIsPaidOnPayrollSuccess]: getPayrollAccessorialIsPaidOnPayrollSuccess,
  [A.getPayrollChargesByPayrollAccessorialSuccess]: getPayrollChargesByPayrollAccessorialSuccess,
  // compensation
  [A.removeVendorCompensationSuccess]: removeVendorCompensationSuccess,
  [A.createVendorCompensationSuccess]: createVendorCompensationSuccess,
  [A.updateVendorCompensationSuccess]: updateVendorCompensationSuccess,
  [A.getVendorCompensationListSuccess]: getVendorCompensationListSuccess,
  // documents
  [A.removeVendorDocumentSuccess]: removeVendorDocumentSuccess,
  [A.updateVendorDocumentSuccess]: updateVendorDocumentSuccess,
  [A.createVendorDocumentSuccess]: createVendorDocumentSuccess,
  [A.getVendorDocumentListSuccess]: getVendorDocumentListSuccess,
  // assignment
  [A.assignVendorSuccess]: assignVendorSuccess,
  [A.unAssignVendorSuccess]: unAssignVendorSuccess,
  // fleet assignment
  [A.getFleetAssignmentListSuccess]: getFleetAssignmentListSuccess,
  // branch configs
  [A.getBranchConfigsByNamesSuccess]: getBranchConfigsByNamesSuccess,
  // monthly payroll deduction
  [A.getMonthlyPayrollDeductionSuccess]: getMonthlyPayrollDeductionSuccess,
  [A.toggleMonthlyPayrollDeductionDetails]: toggleMonthlyPayrollDeductionDetails,
  [A.getVendorPayrollChargesIsPaidOnPayrollSuccess]: getVendorPayrollChargesIsPaidOnPayrollSuccess,
  [A.getVendorPayrollChargesByMonthlyPaymentSuccess]: getVendorPayrollChargesByMonthlyPaymentSuccess,
}, initialState);
