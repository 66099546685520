import React from 'react';
import * as R from 'ramda';
// components
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// feature dispatch details new
import * as C from '../../constants';
import { MessageCenterRowActions } from '../hocs/with-message-center-actions';
import {
  withDownloadMail,
  withLoadDocumentActions,
  withLoadBoardTableGroupRowActions,
  withReferenceTableGroupRowActions,
  withLoadMacroPointTableGroupActions,
  withLoadExternalTableGroupRowActions,
  withStatusMessageTableGroupRowActions,
  withLoadCustomerRateTableGroupRowActions,
  withLoadDriverInvoiceTableGroupRowActions,
  withLoadAdvancePaymentTableGroupRowActions,
  withLoadCarrierInvoiceTableGroupRowActions,
  withLoadCustomerInvoiceTableGroupRowActions,
  withServiceVendorInvoiceTableGroupRowActions,
  withLoadDriverCarrierRateTableGroupRowActions,
  withCrossBorderIntegrationTableGroupRowActions,
} from '../hocs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');

const gearIcon = I.gear(iconColor);
const editIcon = I.pencil(iconColor);
const removeIcon = I.remove(iconColor);
const quickBooksIcon = I.quickbook(iconColor);
const printIcon = I.renderPrintIcon(iconColor);
const printWithDocumentsIcon = I.renderPrintDocumentsIcon(iconColor);
const editTxtLocale = () => G.getWindowLocale('actions:edit', 'Edit');
const deleteTxtLocale = () => G.getWindowLocale('actions:delete', 'Delete');
const sageTxtLocale = () => G.getWindowLocale('actions:send-to-sage-intacct', 'Send to Sage Intacct');
const qbiifTxtLocale = () => G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export');
const quickbooksTxtLocale = () => G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks');

const getReferenceOptions = (props: Object) => {
  const { entity, handleEditReference, handleRemoveReference } = props;

  let actions = [
    {
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: handleRemoveReference,
      permissions: [PC.TEL_REFERENCE_WRITE],
    },
  ];

  if (R.and(
    G.isNilOrEmpty(G.getPropFromObject('invoiceGuid', entity)),
    G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_PARENT_REFERENCE_GUID, entity)),
  )) {
    actions = R.prepend({
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: handleEditReference,
      permissions: [PC.TEL_REFERENCE_WRITE],
    }, actions);
  }

  return actions;
};


const getStatusMessageOptions = ({ handleUpdateStatusMessage, handleRemoveStatusMessage }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: handleUpdateStatusMessage,
    permissions: [PC.TEL_STATUS_MESSAGE_WRITE],
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveStatusMessage,
    permissions: [PC.TEL_STATUS_MESSAGE_WRITE],
  },
];

const getExternalSystemOptions = ({
  entity,
  handleSendToExternalSystem,
  handleRemoveTelFromExternalSystem,
}: Object) => {
  const integrationType = G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, entity);

  const removeAction = {
    permissions: null,
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveTelFromExternalSystem,
  };
  const updateAction = {
    permissions: null,
    action: handleSendToExternalSystem,
    frontIcon: I.reloadCircle(iconColor),
    text: G.getWindowLocale('actions:update', 'Update'),
  };

  if (R.includes(
    integrationType,
    [
      GC.GPS_INTEGRATION_TYPE_SAMSARA,
      GC.DISPATCH_INTEGRATION_TYPE_FOUR_KITES,
      GC.DISPATCH_INTEGRATION_TYPE_TRUCKER_TOOLS,
    ],
  )) return [updateAction, removeAction];

  return R.of(Array, updateAction);
};

const getDriverCarrierRateOptions = (props: Object) => {
  const { entity, handleEditCarrierDriverRate, handleRemoveDriverCarrierRate } = props;

  const permissions = G.ifElse(
    G.isRateTypeFleetRate(G.getPropFromObject(GC.FIELD_TYPE, entity)),
    [PC.FLEET_RATE_WRITE],
    [PC.CARRIER_RATE_WRITE],
  );

  return [
    {
      permissions,
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: handleEditCarrierDriverRate,
    },
    {
      permissions,
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: handleRemoveDriverCarrierRate,
    },
  ];
};

const getCustomerRateOptions = ({ handleEditCustomerRate, handleRemoveCustomerRate }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: handleEditCustomerRate,
    permissions: [PC.CLO_RATE_WRITE],
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveCustomerRate,
    permissions: [PC.CLO_RATE_WRITE],
  },
];

const getCustomerInvoiceOptions = ({
  entity,
  handleEditCloInvoice,
  handleSendCIToFinancial,
  handleExportInvoiceToEDI,
  handlePrintCustomerInvoice,
  handleRemoveCustomerInvoice,
  handleSendCustomerInvoiceToBC,
  handlePrintCustomerInvoiceWithImages,
  handleCreateQBIIFImportCustomerInvoice,
  handleSendCustomerInvoiceListToSageIntacct,
}: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: () => handleEditCloInvoice(entity),
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveCustomerInvoice,
    permissions: [PC.CLO_INVOICE_DELETE_EXECUTE],
  },
  // TODO: add locale for print, print with documents
  {
    frontIcon: quickBooksIcon,
    text: quickbooksTxtLocale(),
    action: handleSendCIToFinancial,
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    text: qbiifTxtLocale(),
    frontIcon: quickBooksIcon,
    action: handleCreateQBIIFImportCustomerInvoice,
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    frontIcon: gearIcon,
    action: handleSendCustomerInvoiceToBC,
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
  },
  {
    frontIcon: gearIcon,
    text: sageTxtLocale(),
    action: handleSendCustomerInvoiceListToSageIntacct,
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    frontIcon: gearIcon,
    action: handleExportInvoiceToEDI,
    text: G.getWindowLocale('actions:export-to-edi', 'Export to EDI'),
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    text: 'Print',
    permissions: null,
    frontIcon: printIcon,
    action: handlePrintCustomerInvoice,
  },
  {
    permissions: null,
    text: 'Print with images',
    frontIcon: printWithDocumentsIcon,
    action: handlePrintCustomerInvoiceWithImages,
  },
];

const getAdvancePaymentOptions = ({ handleUpdateAdvancePaymentStatus }: Object) => [
  {
    permissions: null,
    frontIcon: editIcon,
    action: handleUpdateAdvancePaymentStatus,
    text: G.getWindowLocale('titles:update-advance-payment-status', 'Update Advance Payment Status'),
  },
];

const getDriverInvoiceOptions = (props: Object) => {
  const {
    entity,
    loadConfigs,
    handleEditDriverInvoice,
    handlePrintDriverInvoice,
    handleRemoveDriverInvoice,
    handleSendVendorInvoiceToBC,
    handlePrintDriverInvoiceWithImages,
    handleSendDriverInvoiceToFinancial,
    handleCreateQBIIFImportDriverInvoice,
    handleSendDriverOrVendorInvoiceToSage,
  } = props;

  let actions = [
    {
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: () => handleEditDriverInvoice(entity),
      permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: handleRemoveDriverInvoice,
      permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
    },
    {
      text: qbiifTxtLocale(),
      frontIcon: quickBooksIcon,
      action: handleCreateQBIIFImportDriverInvoice,
      permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      text: 'Print',
      frontIcon: printIcon,
      action: handlePrintDriverInvoice,
      permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      text: 'Print with images',
      frontIcon: printWithDocumentsIcon,
      action: handlePrintDriverInvoiceWithImages,
      permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    },
  ];
  const allowExport = G.getConfigValueFromStore(
    GC.INVOICE_TEL_DRIVER_INVOICE_EXPORT,
    R.path(['configGroups', GC.INVOICE_CONFIG_GROUP], loadConfigs),
  );

  if (allowExport) {
    const quickBookAction = {
      frontIcon: quickBooksIcon,
      text: quickbooksTxtLocale(),
      action: handleSendDriverInvoiceToFinancial,
      permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    };
    actions = R.insert(2, quickBookAction, actions);

    if (G.isInvoiceTypeFleetVendorInvoice(G.getPropFromObject(GC.FIELD_TYPE, entity))) {
      const sageAction = {
        frontIcon: gearIcon,
        text: sageTxtLocale(),
        action: handleSendDriverOrVendorInvoiceToSage,
        permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      };

      const bcAction = {
        frontIcon: gearIcon,
        action: handleSendVendorInvoiceToBC,
        permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
        text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
      };
      actions = R.compose(
        R.insert(4, bcAction),
        R.insert(3, sageAction),
      )(actions);
    }
  }

  return actions;
};

const getCarrierInvoiceOptions = ({
  entity,
  handleEditCarrierInvoice,
  handlePrintCarrierInvoice,
  handleRemoveCarrierInvoice,
  handleSendCarrierInvoiceToBC,
  handleSendCarrierInvoiceToSage,
  handleImportCarrierInvoiceQBIIF,
  handleSendCarrierInvoiceToFinancial,
  handlePrintCarrierInvoiceWithImages,
}: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: () => handleEditCarrierInvoice(entity),
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveCarrierInvoice,
    permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
  },
  {
    frontIcon: quickBooksIcon,
    text: quickbooksTxtLocale(),
    action: handleSendCarrierInvoiceToFinancial,
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    text: qbiifTxtLocale(),
    frontIcon: quickBooksIcon,
    action: handleImportCarrierInvoiceQBIIF,
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    frontIcon: gearIcon,
    action: handleSendCarrierInvoiceToBC,
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
  },
  {
    frontIcon: gearIcon,
    text: sageTxtLocale(),
    action: handleSendCarrierInvoiceToSage,
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    text: 'Print',
    frontIcon: printIcon,
    action: handlePrintCarrierInvoice,
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    text: 'Print with images',
    frontIcon: printWithDocumentsIcon,
    action: handlePrintCarrierInvoiceWithImages,
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
];

const getServiceVendorInvoiceOptions = ({
  entity,
  handleEditServiceVendorInvoice,
  handlePrintServiceVendorInvoice,
  handleRemoveServiceVendorInvoice,
  handleSendServiceVendorInvoiceToBC,
  handleSendServiceVendorInvoiceToSage,
  handlePrintServiceVendorInvoiceWithImages,
  handleSendServiceVendorInvoiceToFinancial,
  handleCreateQBIIFImportServiceVendorInvoice,
}: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    permissions: [PC.SERVICE_VENDOR_WRITE],
    action: () => handleEditServiceVendorInvoice(entity),
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveServiceVendorInvoice,
    permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
  },
  {
    frontIcon: quickBooksIcon,
    text: quickbooksTxtLocale(),
    permissions: [PC.SERVICE_VENDOR_WRITE],
    action: handleSendServiceVendorInvoiceToFinancial,
  },
  {
    frontIcon: gearIcon,
    text: sageTxtLocale(),
    permissions: [PC.SERVICE_VENDOR_WRITE],
    action: handleSendServiceVendorInvoiceToSage,
  },
  {
    text: qbiifTxtLocale(),
    frontIcon: quickBooksIcon,
    permissions: [PC.SERVICE_VENDOR_WRITE],
    action: handleCreateQBIIFImportServiceVendorInvoice,
  },
  {
    frontIcon: gearIcon,
    permissions: [PC.SERVICE_VENDOR_WRITE],
    action: handleSendServiceVendorInvoiceToBC,
    text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
  },
  {
    text: 'Print',
    frontIcon: printIcon,
    action: handlePrintServiceVendorInvoice,
    permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    text: 'Print with images',
    frontIcon: printWithDocumentsIcon,
    action: handlePrintServiceVendorInvoiceWithImages,
    permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
];

const getLoadBoardOptions = (props: Object) => {
  const { entity, handleRePostTelToLoadBoard, handleRemoveTELFromLoadBoard } = props;
  const exportToLoadBoardAction = {
    permissions: null,
    action: handleRePostTelToLoadBoard,
    frontIcon: I.reloadCircle(iconColor),
    text: G.getWindowLocale('titles:post-to-load-boards', 'Post to Load Boards'),
  };
  if (R.pathEq(GC.LOAD_BOARD_SHIPMENT_STATUS_REMOVED, [GC.FIELD_STATUS], entity)) return R.of(Array, exportToLoadBoardAction);

  return [
    exportToLoadBoardAction,
    {
      permissions: null,
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: handleRemoveTELFromLoadBoard,
    },
  ];
};

const getDocumentOptions = ({ entity, handleEditDocument, handleRemoveDocument }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    permissions: [PC.TEL_DOCUMENT_WRITE],
    action: () => handleEditDocument(entity),
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    permissions: [PC.TEL_DOCUMENT_WRITE],
    action: () => handleRemoveDocument(entity),
  },
];

const getMailOptions = ({ handleDownloadMail }: Object) => [
  {
    permissions: null,
    action: handleDownloadMail,
    frontIcon: I.downloadMail(iconColor),
    text: G.getWindowLocale('titles:download-mail', 'Download Mail'),
  },
];

const getMacroPointOptions = ({ handleActivateMacroPoint, handleDeactivateMacroPoint }: Object) => [
  {
    permissions: null,
    action: handleActivateMacroPoint,
    frontIcon: I.reloadCircle(iconColor),
    text: G.getWindowLocale('titles:update-macro-point', 'Update MacroPoint'),
  },
  {
    permissions: null,
    action: handleDeactivateMacroPoint,
    frontIcon: I.crossInRound(iconColor),
    text: G.getWindowLocale('titles:deactivate-macro-point', 'Deactivate MacroPoint'),
  },
];

const getCrossBorderIntegrationOptions = (props: Object) => {
  const { handleUpdateCrossBorderIntegration, handleRemoveCrossBorderIntegration } = props;

  return [
    {
      permissions: null,
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: handleUpdateCrossBorderIntegration,
    },
    {
      permissions: null,
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: handleRemoveCrossBorderIntegration,
    },
  ];
};

const ReferenceRowActions = withReferenceTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getReferenceOptions(props)} />,
);

const StatusMessageRowActions = withStatusMessageTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getStatusMessageOptions(props)} />,
);

const DriverCarrierRateRowActions = withLoadDriverCarrierRateTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getDriverCarrierRateOptions(props)} />,
);

const CustomerRateRowActions = withLoadCustomerRateTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getCustomerRateOptions(props)} />,
);

const CustomerInvoiceRowActions = withLoadCustomerInvoiceTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getCustomerInvoiceOptions(props)} />,
);

const AdvancePaymentRowActions = withLoadAdvancePaymentTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getAdvancePaymentOptions(props)} />,
);

const DriverInvoiceRowActions = withLoadDriverInvoiceTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getDriverInvoiceOptions(props)} />,
);

const CarrierInvoiceRowActions = withLoadCarrierInvoiceTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getCarrierInvoiceOptions(props)} />,
);

const ServiceVendorInvoiceRowActions = withServiceVendorInvoiceTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getServiceVendorInvoiceOptions(props)} />,
);

const LoadBoardRowActions = withLoadBoardTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getLoadBoardOptions(props)} />,
);

const DocumentRowActions = withLoadDocumentActions((props: Object) =>
  <ActionsElement version={2} options={getDocumentOptions(props)} />,
);

const MailRowActions = withDownloadMail((props: Object) =>
  <ActionsElement version={2} options={getMailOptions(props)} />,
);

const MacroPointRowActions = withLoadMacroPointTableGroupActions((props: Object) =>
  <ActionsElement version={2} options={getMacroPointOptions(props)} />,
);

const ExternalSystemRowActions = withLoadExternalTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getExternalSystemOptions(props)} />,
);

const CrossBorderIntegrationRowAction = withCrossBorderIntegrationTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getCrossBorderIntegrationOptions(props)} />,
);

const RowActions = (props: Object) => {
  const { activeListName } = props;

  const rowActionsMap = {
    [C.ACTIVE_LIST_MAILS]: <MailRowActions {...props} />,
    [C.ACTIVE_LIST_DOCUMENTS]: <DocumentRowActions {...props} />,
    [C.ACTIVE_LIST_NOTES]: <MessageCenterRowActions {...props} />,
    [C.ACTIVE_LIST_REFERENCES]: <ReferenceRowActions {...props} />,
    [C.ACTIVE_LIST_LOAD_BOARDS]: <LoadBoardRowActions {...props} />,
    [C.ACTIVE_LIST_MACRO_POINT]: <MacroPointRowActions {...props} />,
    [C.ACTIVE_LIST_CUSTOMER_RATES]: <CustomerRateRowActions {...props} />,
    [C.ACTIVE_LIST_CHAT_MESSAGES]: <MessageCenterRowActions {...props} />,
    [C.ACTIVE_LIST_DRIVER_INVOICES]: <DriverInvoiceRowActions {...props} />,
    [C.ACTIVE_LIST_STATUS_MESSAGES]: <StatusMessageRowActions {...props} />,
    [C.ACTIVE_LIST_ADVANCE_PAYMENT]: <AdvancePaymentRowActions {...props} />,
    [C.ACTIVE_LIST_CARRIER_INVOICES]: <CarrierInvoiceRowActions {...props} />,
    [C.ACTIVE_LIST_CUSTOMER_INVOICES]: <CustomerInvoiceRowActions {...props} />,
    [C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST]: <ExternalSystemRowActions {...props} />,
    [C.ACTIVE_LIST_CARRIER_FLEET_RATES]: <DriverCarrierRateRowActions {...props} />,
    [C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES]: <ServiceVendorInvoiceRowActions {...props} />,
    [C.ACTIVE_LIST_CROSS_BORDER_INTEGRATION_LIST]: <CrossBorderIntegrationRowAction {...props} />,
  };

  return rowActionsMap[activeListName];
};

export default RowActions;
