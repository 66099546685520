import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature carrier
import * as LC from '../constants';
import { FooterBtns } from './footer-btns';
import { LocationFieldsWrapper } from '../ui';
import { ContractDiscounts } from './contract-discounts';
import ContractDiscountForm from './contract-discount-form';
import { CarrierAssessorials } from './carrier-assessorial';
import {
  setInitialOptions,
  setOptionsAndValue,
  makeValuesForRequest,
} from './carrier-rate-form';
import {
  awardedLine,
  awardedLineParams,
  defaultAwardedLine,
  awardedDiscountSettings,
  carrierRateLocationFields,
  setInitialLocationFormikValues,
  getCarrierAwardedLineValidationSchemaObject,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const awardedLineAssessorialCRUD = withHandlers({
  handleAddSharedAccessorials: ({ setAssessorials }: Object) => async (guid: Object) => {
    const options = { params: { awardedLineGuid: guid } };
    const { data } = await sendRequest('get', endpointsMap.carrierContractAwardedLineAssessorialList, options);

    setAssessorials(data);
  },
  handleCreateAwardedLineAssessorial: (props: Object) => async (data: Object) => {
    try {
      props.openLoader();
      const res = await sendRequest('post', endpointsMap.carrierContractAwardedLineAssessorial, { data });

      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple(
          'success',
          G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
        );
        const newValues = R.concat(props.awardedLineAssessorials, R.of(Array, res.data));

        props.setAssessorials(newValues);
        props.closeModal();
      } else {
        G.handleFailResponseSimple(res);
      }

      props.closeLoader();
    } catch (error) {
      props.closeLoader();
      G.handleException(error, 'handleCreateAwardedLineAssessorial');
      G.showToastrMessageSimple(
        'error',
        G.getWindowLocale(
          'messages:error:unknown',
          'Oops! The system is experiencing a problem. The issue has been reported.',
        ),
      );
    }
  },
  handleUpdateAwardedLineAssessorial: (props: Object) => async (accessorial: Object) => {
    try {
      props.openLoader();

      const res = await sendRequest('put', endpointsMap.carrierContractAwardedLineAssessorial, { data: accessorial });

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        G.showToastrMessageSimple(
          'success',
          G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
        );
        const newValues = R.map((item: Object) => {
          if (G.notEquals(G.getGuidFromObject(data), G.getGuidFromObject(item))) return item;

          return data;
        }, props.awardedLineAssessorials);

        props.closeModal();
        props.setAssessorials(newValues);
      } else {
        G.handleFailResponseSimple(res);
      }

      props.closeLoader();
    } catch (error) {
      props.closeLoader();
      G.handleException(error, 'handleUpdateAwardedLineAssessorial');
      G.showToastrMessageSimple(
        'error',
        G.getWindowLocale(
          'messages:error:unknown',
          'Oops! The system is experiencing a problem. The issue has been reported.',
        ),
      );
    }
  },
  handleDeleteAwardedLineAssessorial: (props: Object) => async (entityGuid: Object) => {
    try {
      props.openLoader();
      const res = await sendRequest('delete', endpointsMap.getCarrierAwardedLineAssessorialEndpoint(entityGuid));

      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple('success', G.getWindowLocale('messages:success:204', 'Successfully deleted'));
        const newValues = R.filter(
          ({ guid }: Object) => G.notEquals(guid, entityGuid),
          props.awardedLineAssessorials,
        );

        props.setAssessorials(newValues);
      } else {
        G.handleFailResponseSimple(res);
      }

      props.closeLoader();
    } catch (error) {
      props.closeLoader();
      G.handleException(error, 'handleDeleteAwardedLineAssessorial');
      G.showToastrMessageSimple(
        'error',
        G.getWindowLocale(
          'messages:error:unknown',
          'Oops! The system is experiencing a problem. The issue has been reported.',
        ),
      );
    }
  },
});

const enhance = compose(
  withState(
    LC.ORIGIN_ZIP_CODES_OPTIONS,
    'setOriginZipCodesOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES),
  ),
  withState(
    LC.DESTINATION_ZIP_CODES_OPTIONS,
    'setDestinationZipCodesOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES),
  ),
  withState(
    LC.ORIGIN_CITY_OPTIONS,
    'setOriginCityOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_ORIGIN_CITIES),
  ),
  withState(
    LC.DESTINATION_CITY_OPTIONS,
    'setDestinationCityOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_DESTINATION_CITIES),
  ),
  withState(LC.FIELD_VARIABLE_VALUES, 'setVariableValues', []),
  withPropsOnChange(['initialValues'], ({ initialValues, ...props }: Object) => {
    const variableValues = R.pathOr(
      [],
      [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_VARIABLE_VALUES],
      initialValues,
    );
    props.setVariableValues(variableValues);
  }),
  withState('awardedLineAssessorials', 'setAssessorials', ({ assessorials }: Object) => assessorials),
  withState('collapsedGroup', 'setCollapsedGroup', {
    discount: true,
    assessorial: true,
  }),
  withFormik({
    enableReinitialize: true,
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getCarrierAwardedLineValidationSchemaObject(values))
    )),
    mapPropsToValues: (props: Object) => setInitialLocationFormikValues(
      defaultAwardedLine,
      { ...props.initialValues },
      props.searchedValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const valuesForRequest = makeValuesForRequest(
        values,
        LC.AWARDED_LINE_MAIN_FIELDS,
        LC.AWARDED_LINE_MAIN_FIELDS_OMIT,
      );
      let reqBody = R.assoc('contractGuid', props.contractGuid, valuesForRequest);
      const discountType = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

      if (R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE)) {
        reqBody = R.assocPath(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_VARIABLE_VALUES],
          props[LC.FIELD_VARIABLE_VALUES],
          reqBody,
        );
      }

      if (R.equals(discountType, '')) {
        reqBody = R.assocPath(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT],
          null,
          reqBody,
        );
      }

      props.submitAction(reqBody);
    },
    displayName: LC.AWARDED_LINE_FORM,
  }),
  withHandlers({
    handleUpdateSubmit: (props: Object) => (entity: Object) => {
      const newValues = R.map(
        (discount: Object) => {
          if (R.equals(discount.guid, entity.guid)) {
            return entity;
          }

          return discount;
        },

        props.variableValues,
      );

      props.setVariableValues(newValues);
    },
    handleToggleFormGroup: (props: Object) => (group: string) => {
      props.setCollapsedGroup(R.assoc(group, R.not(props.collapsedGroup[group]), props.collapsedGroup));
    },
  }),
  withHandlers({
    setValues: (props: Object) => (values: Object) => {
      const labelStrings = R.split(':', values.label);
      const property = R.last(labelStrings);
      setOptionsAndValue(values.label, values[property], values, props);
    },
    handleChangeSelect: (props: Object) => (id: any, data: Array) => {
      const functionsForSetOptions = {
        [LC.FIELD_CARRIER_RATE_ORIGIN_CITIES]: props.setOriginCityOptions,
        [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES]: props.setOriginZipCodesOptions,
        [LC.FIELD_CARRIER_RATE_DESTINATION_CITIES]: props.setDestinationCityOptions,
        [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES]: props.setDestinationZipCodesOptions,
      };
      R.propOr(() => {}, id, functionsForSetOptions)(
        (prev: Array) => prev.filter((option: Object) => (
          R.includes(option.value, data)
        ),
        ));
    },
    handleEnter: (props: Object) => (value: string, id: string) => {
      setOptionsAndValue(id, value, props.values, props);
    },
    handleAddDiscount: (props: Object) => () => {
      const modalContext = (
        <ContractDiscountForm
          closeModal={props.closeModal}
          discounts={props.variableValues}
          submitAction={props.setVariableValues}
        />
      );
      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 400,
          height: 'auto',
          title: G.getWindowLocale('titles:add-discount', 'Add Discount'),
        },
      };

      props.openModal(modal);
    },
    handleEditDiscount: (props: Object) => (entity: Object) => {
      const modalContext = (
        <ContractDiscountForm
          editMode={true}
          initialValues={entity}
          closeModal={props.closeModal}
          discounts={props.variableValues}
          submitAction={props.handleUpdateSubmit}
        />
      );
      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 400,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-discount', 'Edit Discount'),
        },
      };

      props.openModal(modal);
    },
    handleDeleteDiscount: (props: Object) => (entity: Object) => {
      const newValues = R.filter(
        ({ guid }: Object) => G.notEquals(guid, entity.guid),
        props.variableValues,
      );

      props.setVariableValues(newValues);
    },
  }),
  awardedLineAssessorialCRUD,
  pure,
);

const AwardedLineForm = (props: Object) => (
  <Box maxHeight='90vh' overflow='auto'>
    <form onSubmit={props.handleSubmit}>
      <Box p='10px 15px' zIndex='13'>
        <FieldsetComponent
          {...props}
          fields={awardedLine.fields}
        />
      </Box>
      <Flex
        p='10px'
        zIndex='12'
        borderTop='solid 1px'
        alignItems='flex-start'
        borderColor={G.getTheme('colors.light.darkGrey')}
      >
        {
          carrierRateLocationFields.map((item: Object, index: number) => (
            <LocationFieldsWrapper key={index}>
              <Box fontSize='14px' fontWeight='bold' m='10px 0 5px 10px' color={G.getTheme('forms.titleColor')}>
                {G.getWindowLocale(item.title)}
              </Box>
              <FieldsetComponent
                {...props}
                fields={item.fields}
                fieldsGroupWidth='50%'
                fieldsetPadding='0 0 0 5px'
                optionsForSelect={R.pick(LC.CARRIER_RATE_OPTIONS_PICK, props)}
                handlers={{
                  handleEnter: props.handleEnter,
                  handleChangeSelect: props.handleChangeSelect,
                }}
              />
            </LocationFieldsWrapper>
          ))
        }
      </Flex>
      {
        awardedLineParams.map((item: Object, index: number) => (
          <Box
            key={index}
            zIndex='11'
            p='0 15px 10px'
            display='inline-block'
          >
            <Box fontSize='14px' fontWeight='bold' m='10px 0 5px 10px' color={G.getTheme('forms.titleColor')}>
              {G.getWindowLocale(item.title)}
            </Box>
            <FieldsetComponent
              {...props}
              fields={item.fields}
            />
          </Box>
        ))
      }
      <Flex
        p='0 15px 10px'
        flexDirection='column'
        alignItems='flex-start'
      >
        <Box fontSize='14px' fontWeight='bold' m='10px 0 5px 10px' color={G.getTheme('forms.titleColor')}>
          {G.getWindowLocale(awardedDiscountSettings.title)}
        </Box>
        <FieldsetComponent
          {...props}
          fields={awardedDiscountSettings.fields}
        />
      </Flex>
      {
        R.equals(
          R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], props.values),
          GC.DISCOUNT_TYPE_VARIABLE,
        )
        &&
        <ContractDiscounts
          {...props}
          addContractVariableValues={props.setVariableValues}
          editContractVariableValues={props.setVariableValues}
          deleteContractVariableValues={props.setVariableValues}
          contractVariableValues={R.prop(LC.FIELD_VARIABLE_VALUES, props)}
        />
      }
      {
        props.isEditMode &&
        <CarrierAssessorials
          {...props}
          groupName='assessorial'
          relatedEntityField='awardedLineGuid'
          entities={props.awardedLineAssessorials}
          deleteAction={props.handleDeleteAwardedLineAssessorial}
          updateAction={props.handleUpdateAwardedLineAssessorial}
          createAction={props.handleCreateAwardedLineAssessorial}
          relatedGuid={R.pathOr('', ['initialValues', 'guid'], props)}
          addSharedAccessorialsEndpoint='addAwardedLineSharedAccessorials'
          successSharedAccessorialsAction={props.handleAddSharedAccessorials}
          removeSharedAccessorialsEndpoint='removeAwardedLineSharedAccessorials'
          panelTitle={G.getWindowLocale('titles:awarded-line-accessorials', 'Awarded Line Accessorials')}
        />
      }
      <FooterBtns closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(AwardedLineForm);
