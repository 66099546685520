import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import { withEditBillTo } from '../../../../forms/forms/bill-remit-to';
// helpers/constants
import * as GC from '../../../../constants';
// feature dispatch-details new
import { updateLoadBillToRequest, createLoadBillToRequest } from '../actions';
//////////////////////////////////////////////////

export const withLoadPaymentsActions = compose(
  connect(null, { updateLoadBillToRequest, createLoadBillToRequest }),
  withEditBillTo,
  withHandlers({
    handleEditBillTo: (props: Object) => (initialValues: Object) => {
      const {
        load,
        handleClickEditBillTo,
        updateLoadBillToRequest } = props;
      const dataForBillTo = {
        initialValues,
        branchGuid: load[GC.BRANCH_GUID],
        updateBillToRequest: updateLoadBillToRequest,
      };
      handleClickEditBillTo(dataForBillTo);
    },
    handleCreateBillTo: (props: Object) => () => {
      const {
        load,
        handleClickEditBillTo,
        createLoadBillToRequest } = props;

      const dataForBillTo = {
        branchGuid: load[GC.BRANCH_GUID],
        updateBillToRequest: createLoadBillToRequest,
      };

      handleClickEditBillTo(dataForBillTo);
    },
  }),
  pure,
);
