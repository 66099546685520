import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
import { PopperComponent } from '../../../components/popper';
// features
import { DispatchButton } from '../../rate/ui';
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, CustomButton } from '../../../ui';
// feature dispatch-planner-events
import * as H from '../helpers';
import HeaderActions from './header-actions';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const getPrimaryDriverNameFromLoad = (tel: Object) => {
  if (G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_RATE, 'primaryDriverName'], tel))) {
    return R.pathOr('', [GC.SYSTEM_OBJECT_RATE, 'primaryDriverName'], tel);
  }

  return G.getFirstDriverInfo(R.prop(GC.SYSTEM_OBJECT_RATE, tel));
};

const getSecondaryDriverNameFromLoad = (tel: Object) => {
  if (G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_RATE, 'secondaryDriverName'], tel))) {
    return R.pathOr('', [GC.SYSTEM_OBJECT_RATE, 'secondaryDriverName'], tel);
  }

  return G.getTeamDriverInfo(R.prop(GC.SYSTEM_OBJECT_RATE, tel));
};

const getChargesTotalFromRate = (rate: Object, keyToCharges: string) => {
  const { currency } = rate;

  const charges = R.pathOr([], [keyToCharges], rate);

  const currencySymbol = G.getCurrencySymbolFromRate(rate);
  const total = G.getCalcTelRateChargesTotal(charges, currency);

  return `${currencySymbol} ${G.toFixed(total)}`;
};

const isSecondaryDriverPresent = R.ifElse(
  R.propEq(true, 'isNew'),
  R.prop(GC.FIELD_SECONDARY_DRIVER_GUID),
  R.path([GC.FIELD_FLEET_ASSIGNMENT, GC.FIELD_SECONDARY_DRIVER_GUID]),
);

const getDriverInfoFromTel = (tel: Object) => `
  ${getPrimaryDriverNameFromLoad(tel)}, ${
    getChargesTotalFromRate(R.prop(GC.SYSTEM_OBJECT_RATE, tel), GC.FIELD_PRIMARY_DRIVER_CHARGES)}
`;

const getSecondaryDriverInfoFromTel = (tel: Object) => `
  ${getSecondaryDriverNameFromLoad(tel)}, ${
    getChargesTotalFromRate(R.prop(GC.SYSTEM_OBJECT_RATE, tel), GC.FIELD_SECONDARY_DRIVER_CHARGES)}
`;

const getTruckInfoFromLoad = (tel: Object) => {
  if (G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_RATE, 'truckName'], tel))) {
    return R.path([GC.SYSTEM_OBJECT_RATE, 'truckName'], tel);
  }

  return G.getTruckInfo(R.prop(GC.SYSTEM_OBJECT_RATE, tel));
};

const getTrailersInfoFromLoad = (tel: Object) => {
  if (G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_RATE, 'trailerNames'], tel))) {
    const trailers = R.path([GC.SYSTEM_OBJECT_RATE, 'trailerNames'], tel);

    if (G.isNilOrEmpty(trailers)) return null;

    return R.join(', ', trailers);
  }

  return G.getTrailersInfo(R.prop(GC.SYSTEM_OBJECT_RATE, tel));
};

const getTruckTrailersInfo = (tel: Object) => {
  const truckInfo = getTruckInfoFromLoad(tel);
  const trailersInfo = getTrailersInfoFromLoad(tel);

  if (G.isNilOrEmpty(trailersInfo)) return `${G.getWindowLocale('titles:truck', 'Truck')}: ${truckInfo}`;

  return `${G.getWindowLocale('titles:truck', 'Truck')}: ${truckInfo}; ${
    G.getWindowLocale('titles:trailers', 'Trailers')}: ${trailersInfo}`;
};

const DriversInfo = ({ tel }: Object) => (
  <Box mr='5px' fontSize={11}>
    <TextComponent
      mb='2px'
      maxWidth={250}
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
      title={getDriverInfoFromTel(tel)}
    >
      {getDriverInfoFromTel(tel)}
    </TextComponent>
    {
      isSecondaryDriverPresent(R.prop(GC.SYSTEM_OBJECT_RATE, tel)) &&
      <TextComponent
        mb='2px'
        maxWidth={250}
        fontWeight='bold'
        withEllipsis={true}
        display='inline-block'
        title={getSecondaryDriverInfoFromTel(tel)}
      >
        {getSecondaryDriverInfoFromTel(tel)}
      </TextComponent>
    }
    <TextComponent
      mb='2px'
      maxWidth={250}
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
      title={getTruckTrailersInfo(tel)}
    >
      {getTruckTrailersInfo(tel)}
    </TextComponent>
  </Box>
);

const getCarrierInfo = ({ rate }: Object) => {
  const carrierTitle = G.getWindowLocale('titles:carrier', 'Carrier');
  const carrierName = R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_NAME], rate);
  const chargesTotal = getChargesTotalFromRate(rate, 'carrierRateCharges');

  return `${carrierTitle}: ${carrierName}, ${chargesTotal}`;
};

const CarrierInfo = (props: Object) => (
  <Box mr='5px' fontSize={11}>
    <TextComponent
      mb='2px'
      maxWidth={250}
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
      title={getCarrierInfo(props)}
    >
      {getCarrierInfo(props)}
    </TextComponent>
  </Box>
);

const ActionsButton = (props: Object) => (
  <Box zIndex={11}>
    <PopperComponent
      zi={21}
      type='hover'
      rotate={true}
      position='left'
      content={<HeaderActions {...props} />}
      borderColor={G.getTheme('colors.boxShadowBlue')}
    >
      {I.threePointer(G.getTheme('colors.dark.blue'))}
    </PopperComponent>
  </Box>
);

const UpdateRate = ({ tel, isCarrierRate, handleUpdateTelDriverRate, handleUpdateTelCarrierRate }: Object) => (
  <Flex>
    <CustomButton
      p='4px'
      mr={10}
      height={20}
      type='button'
      fontSize={12}
      minWidth={84}
      bgColor={G.getTheme('buttons.assignBtn.bgColor')}
      onClick={() => {
        if (isCarrierRate) return handleUpdateTelCarrierRate(tel);

        return handleUpdateTelDriverRate(tel);
      }}
    >
      {G.getWindowLocale('actions:update-rate', 'Update Rate')}
    </CustomButton>
  </Flex>
);

const DispatchBlock = (props: Object) => {
  const {
    tel,
    currentRoute,
    handleDispatch,
    handleCancelDispatch,
    currentRouteInitialState,
  } = props;

  const { guid, status } = tel;

  const notChanged = R.equals(
    R.path([GC.SYSTEM_LIST_TELS, guid], currentRoute),
    R.path([GC.SYSTEM_LIST_TELS, guid], currentRouteInitialState),
  );

  const saveChangesText = G.getWindowLocale('titles:save-changes', 'You need to save your changes first');

  const dispatchHandler = () => {
    if (notChanged) handleDispatch();
  };

  if (R.includes(status, GC.TEL_STATUSES_TO_DISPATCH)) {
    const btnText = G.getWindowLocale('titles:dispatch', 'Dispatch');

    const title = G.ifElse(
      notChanged,
      btnText,
      saveChangesText,
    );

    return (
      <AuthWrapper has={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}>
        <DispatchButton
          mr={10}
          title={title}
          onClick={dispatchHandler}
        >
          {btnText}
        </DispatchButton>
      </AuthWrapper>
    );
  } else if (R.includes(status, GC.TEL_STATUSES_TO_CANCEL)) {
    const btnText = G.getWindowLocale('titles:cancel-dispatched', 'Cancel Dispatched');

    const title = G.ifElse(
      notChanged,
      btnText,
      saveChangesText,
    );

    return (
      <AuthWrapper has={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}>
        <DispatchButton
          mr={10}
          title={title}
          onClick={() => {
            if (notChanged) handleCancelDispatch();
          }}
        >
          {btnText}
        </DispatchButton>
      </AuthWrapper>
    );
  } else if (R.includes(status, GC.TEL_NEGATIVE_STATUSES)) {
    const btnText = G.getWindowLocale('titles:redispatch', 'Redispatch');

    const title = G.ifElse(
      notChanged,
      btnText,
      saveChangesText,
    );

    return (
      <AuthWrapper has={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}>
        <DispatchButton
          mr={10}
          title={title}
          onClick={dispatchHandler}
        >
          {btnText}
        </DispatchButton>
      </AuthWrapper>
    );
  }

  return null;
};

const renderRateInfo = (props: Object, isCarrierRate: boolean) => {
  const { tel: { rate } } = props;

  if (isCarrierRate) return <CarrierInfo rate={rate} />;

  return <DriversInfo {...props} />;
};

const RightActions = (props: Object) => {
  const {
    tel,
    loadGuid,
    branchGuid,
    isExpanded,
    cleanTelRate,
    sortTelEvents,
    setTelVisibility,
    handleAddCloStop,
    handleAddDocument,
    handleClickLoadMap,
    handleClickAddNewStop,
    handleClickDeleteLoad,
    handleClickAddTerminal,
    handleAddTelDriverRate,
    handleClickUnassignTel,
    handleAddTelCarrierRate,
    handleApplyRouteTemplate,
    handleAddExchangeTerminal,
    handlePrintCreateLoadDocument,
    handleRecalculateLoadDistances,
  } = props;

  const { guid, rate, isNew } = tel;

  const notNewTel = R.not(isNew);
  const withRate = G.isNotNilAndNotEmpty(rate);
  const isCarrierRate = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, rate));

  return (
    <Flex>
      {
        withRate &&
        <Flex>
          <DispatchBlock {...props} />
          <AuthWrapper
            has={G.ifElse(
              isCarrierRate,
              [PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
              [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE],
            )}
          >
            {renderRateInfo(props, isCarrierRate)}
          </AuthWrapper>
          {
            G.isTrue(G.getPropFromObject('isNew', rate)) &&
            <Box
              mr='5px'
              cursor='pointer'
              onClick={() => cleanTelRate(guid)}
              title={G.getWindowLocale('actions:clean-tel-rate', 'Clean TEL Rate')}
            >
              {I.crossInRound()}
            </Box>
          }
        </Flex>
      }
      {
        withRate &&
        <AuthWrapper has={G.ifElse(isCarrierRate, [PC.CARRIER_RATE_WRITE], [PC.FLEET_RATE_WRITE])}>
          <UpdateRate {...props} isCarrierRate={isCarrierRate} />
        </AuthWrapper>
      }
      {
        notNewTel &&
        <AuthWrapper has={[PC.TEL_DOCUMENT_WRITE]}>
          <Flex
            mr='8px'
            cursor='pointer'
            onClick={handlePrintCreateLoadDocument}
            title={G.getWindowLocale('titles:print-create-document', 'Print/Create Document')}
          >
            {I.renderPrintDocumentsIcon(blueColor)}
          </Flex>
        </AuthWrapper>
      }
      {
        notNewTel &&
        <AuthWrapper has={[PC.TEL_DOCUMENT_WRITE]}>
          <Flex
            mr='8px'
            cursor='pointer'
            onClick={handleAddDocument}
            title={G.getWindowLocale('titles:add-document', 'Add Document')}
          >
            {I.pdf()}
          </Flex>
        </AuthWrapper>
      }
      <Flex
        p='3px'
        mr='8px'
        cursor='pointer'
        flexDirection={G.ifElse(isExpanded, 'column-reverse', 'column')}
        onClick={() => setTelVisibility(G.ifElse(isExpanded, 'hidden', 'visible'))}
        title={G.ifElse(
          isExpanded,
          G.getWindowLocale('actions:hide', 'Hide'),
          G.getWindowLocale('actions:show', 'Show'),
        )}
      >
        {I.arrowUpSimple(blueColor, 12, 8)}
        {I.arrowDownSimple(blueColor, 12, 8)}
      </Flex>
      <ActionsButton
        tel={tel}
        addCloStop={handleAddCloStop}
        showOnMap={handleClickLoadMap}
        deleteTel={handleClickDeleteLoad}
        addTerminal={handleClickAddTerminal}
        unassignTel={handleClickUnassignTel}
        applyRouteTemplate={handleApplyRouteTemplate}
        addExchangeTerminal={handleAddExchangeTerminal}
        assignDriver={() => handleAddTelDriverRate(tel)}
        assignCarrier={() => handleAddTelCarrierRate(tel)}
        recalculateDistances={handleRecalculateLoadDistances}
        addDrop={() => handleClickAddNewStop({
          loadGuid,
          branchGuid,
          loadType: GC.LOAD_TYPE_TEL,
          eventType: GC.EVENT_TYPE_DROP,
        })}
        addPickup={() => handleClickAddNewStop({
          loadGuid,
          branchGuid,
          loadType: GC.LOAD_TYPE_TEL,
          eventType: GC.EVENT_TYPE_PICKUP,
        })}
        sortByDate={() => {
          sortTelEvents(guid);
          handleRecalculateLoadDistances();
        }}
      />
    </Flex>
  );
};

export const renderLoadTotal = (total: Object) => {
  const totalItemsCount = `${G.getWindowLocale('titles:total', 'Total', { caseAction: 'upperCase' })}:
   ${total.itemsTotal}`;

  const itemsLocale = G.getWindowLocale('titles:items', 'Items', { caseAction: 'lowerCase' });
  const quantity = `${total.totalQuantity[GC.FIELD_ITEM_QUANTITY]} ${total.totalQuantity[GC.FIELD_PACKAGE_TYPE]}`;
  const weight = `${total.totalWeight[GC.FIELD_ITEM_WEIGHT]} ${total.totalWeight[GC.FIELD_ITEM_WEIGHT_TYPE]}`;
  const distance = `${G.getWindowLocale('titles:distance', 'Distance', { caseAction: 'upperCase' })}:
    ${G.ifElse(G.isNilOrEmpty(total.totalDistance), 0, R.path(['totalDistance', GC.FIELD_TOTAL_TRIP_DISTANCE], total))}
    ${
      G.ifElse(
        G.isNilOrEmpty(total.totalDistance),
        '',
        R.path(['totalDistance', GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], total),
      )
    }
  `;

  return `${totalItemsCount} ${itemsLocale}, ${quantity}, ${weight}, ${distance}`;
};

const renderErrorContent = (error: Object) => {
  const color = G.getTheme('colors.light.mainRed');

  return (
    <Box p='10px' color={color}>
      {
        G.isNotNil(error.event) &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:wrong-date-sequence-for-stops', 'Wrong date sequence for some stops')}
        </Box>
      }
      {
        error.distanceError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:recalculate-stop-distances', 'Some stops need to recalculate distances')}
        </Box>
      }
      {
        error.cloItemsError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:invalid-clo-items', 'There are invalid clo items')}
        </Box>
      }
      {
        error.eventsCountError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:tel-should-contain-min-two-stops', 'TEL should contain at least two stops')}
        </Box>
      }
      {
        error.terminalCloItemsError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:terminal-should-contain-clo-items', 'Terminal should contain CLO items')}
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(error.eventsErrors) &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:check-events-errors', 'Double check the events for errors')}
        </Box>
      }
    </Box>
  );
};

const renderErrorsInfo = (error: Object, tel: Object) => {
  if (G.isFalse(error)) return null;

  return (
    <PopperComponent
      zi={21}
      type='hover'
      position='bottom'
      content={renderErrorContent(error, tel)}
      borderColor={G.getTheme('listActions.borderColor')}
    >
      <Box cursor='pointer'>
        {I.warningIcon()}
      </Box>
    </PopperComponent>
  );
};

const LoadHeader = (props: Object) => {
  const {
    tel,
    error,
    loadTotal,
    handleOpenDetails,
  } = props;

  const blueColor = G.getTheme('colors.dark.blue');

  return (
    <Flex
      p='5px 10px'
      width='100%'
      borderTop='1px solid'
      borderBottom='1px solid'
      justifyContent='space-between'
      bg={G.getTheme('colors.light.lightGrey')}
      boxShadow='0 0 10px 0 rgba(204, 204, 204, 0.5)'
      borderColor={G.getTheme('tables.rows.borderColor')}
    >
      <Box width='100%'>
        <Flex width='100%'>
          <Box>
            {I.truck(blueColor)}
          </Box>
          <Box
            m='0 30px'
            color={blueColor}
            onClick={handleOpenDetails}
            cursor={G.ifElse(R.path(['isNew'], tel), 'auto', 'pointer')}
          >
            {`${G.getWindowLocale('titles:tel', 'TEL')}: ${H.getLoadName(tel, 'Unknown')}`}
          </Box>
          <Box fontSize={11} color={G.getTheme('colors.light.black')}>
            {loadTotal && renderLoadTotal(loadTotal)}
          </Box>
          <Box mr={10} ml='auto'>
            {renderErrorsInfo(error, tel)}
          </Box>
        </Flex>
      </Box>
      <RightActions {...props} />
    </Flex>
  );
};

export default LoadHeader;
