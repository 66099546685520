import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
import { MessageItem } from '../../../components/message-center';
import { getLoadStatusOptions } from '../../../components/filter/settings';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-details-new
import LoadCustomStatus from './load-custom-status';
import CommissionAssignee from './commission-assignee';
import CloApproval from '../order/components/approve-clo';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const getStatusTextLocale = (status: string) => R.compose(
  R.pathOr('', [status, GC.FIELD_LABEL]),
  R.indexBy(R.prop(GC.FIELD_VALUE)),
)(getLoadStatusOptions());

const getStatusColor = (status: string) => G.getTheme(`status.${status}`, textColor);

const StatusWrapper = ({ status, loadCancelReason }: Object) => (
  <Flex mr={16} fontSize={12}>
    <Box
      fontWeight='bold'
      color={textColor}
      textTransform='uppercase'
    >
      {G.getWindowLocale('titles:load-status', 'Load Status')}
    </Box>
    <Flex flexDirection='column'>
      <Flex
        ml={10}
        p='5px'
        minWidth='70px'
        fontWeight='bold'
        border='1px solid'
        borderRadius='16px'
        justifyContent='center'
        color={getStatusColor(status)}
        borderColor={getStatusColor(status)}
      >
        {getStatusTextLocale(status)}
      </Flex>
      {
        R.and(
          R.equals(status, GC.LOAD_STATUS_CANCELED),
          G.isNotNil(loadCancelReason),
        ) &&
        <Box
          fontSize={11}
          title={loadCancelReason}
          color={getStatusColor(status)}
        >
          {loadCancelReason}
        </Box>
      }
    </Flex>
  </Flex>
);

const getDistance = (distanceObj: Object = {}) => {
  const distance = R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, distanceObj);
  const uom = R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, distanceObj);

  return G.ifElse(
    R.and(G.isNotNilAndNotEmpty(distance), G.isNotNilAndNotEmpty(uom)),
    `${distance} ${uom}`,
    G.getWindowLocale('messages:check-distance', 'Please, check this distance'),
  );
};

const TotalTripDistanceSection = ({ totalDistance, selectedRate }: Object) => (
  <Flex mr='5px' color={textColor}>
    <Box width={110} fontWeight='bold'>
      {G.getWindowLocale('titles:total-trip-distance', 'Total Trip Distance')}:
    </Box>
    <Box ml={10}>
      <Flex lineHeight={1.6}>
        {G.getWindowLocale('titles:selected-rate', 'Selected Rate')} -
        <Box ml='5px' fontWeight='bold'>
          {getDistance(selectedRate)}
        </Box>
      </Flex>
      <Flex lineHeight={1.6}>
        {G.getWindowLocale('titles:stops', 'Stops')} -
        <Box ml='5px' fontWeight='bold'>
          {getDistance(totalDistance)}
        </Box>
      </Flex>
    </Box>
  </Flex>
);

const RouteMarginSection = ({ loadType, selectedRate, configCurrency, costAllocations }: Object) => {
  const currency = R.pathOr(configCurrency, [0, GC.FIELD_CURRENCY], costAllocations);
  const margin = R.compose(
    R.sum,
    R.map(R.prop(GC.FIELD_MARGIN)),
  )(R.or(costAllocations, []));
  const routeMarginString = `${G.getCurrencySymbol(currency)} ${margin.toFixed(2)}`;
  let routeMarginColor;

  if (R.lt(margin, 0)) {
    routeMarginColor = G.getTheme('colors.light.mainRed');
  } else if (R.gt(margin, 0)) {
    const marginViolated = R.or(
      G.isTrue(G.getPropFromObject(GC.FIELD_MIN_MARGIN_VIOLATED, selectedRate)),
      G.isTrue(G.getPropFromObject(GC.FIELD_CRITICAL_MARGIN_VIOLATED, selectedRate)),
    );

    if (R.and(marginViolated, G.isLoadTypeTel(loadType))) {
      routeMarginColor = G.getTheme('colors.light.mainRed');
    } else {
      routeMarginColor = G.getTheme('colors.light.green');
    }
  } else {
    routeMarginColor = G.getTheme('colors.light.blue');
  }

  return (
    <Flex
      mt='8px'
      color={textColor}
      fontWeight='bold'
      alignItems='flex-end'
    >
      <Box width={110}>{G.getWindowLocale('titles:route-margin', 'Route Margin')}:</Box>
      <Box ml={10} fontWeight='bold' color={routeMarginColor}>
        {routeMarginString}
      </Box>
    </Flex>
  );
};

const AdditionalInfo = (props: Object) => {
  const {
    load,
    fromPage,
    services,
    equipment,
    orderType,
    openModal,
    closeModal,
    tableIndex,
    handleSetServices,
    handleSetOrderType,
    handleSetEquipments,
    specialInstructions,
    internalInstructions,
    handleSetSpecialInstructions,
    handleSetInternalInstructions,
    changeCommissionAssignmentRequest,
  } = props;

  const orderTypeTxtLocale = G.getWindowLocale('titles:order-type', 'Order Type');
  const servicesTxtLocale = G.getWindowLocale('titles:ordered-services', 'Ordered Services');
  const equipmentTxtLocale = G.getWindowLocale('titles:ordered-equipment', 'Ordered Equipment');
  const specialInstructionsTxtLocale = G.getWindowLocale('titles:special-instructions', 'Special Instructions');
  const internalInstructionsTxtLocale = G.getWindowLocale('titles:internal-instructions', 'Internal Instructions');
  const equipmentString = `${equipmentTxtLocale}: ${R.pathOr('', [GC.FIELD_DISPLAYED_VALUE], equipment)}`;
  const orderTypeString = `${orderTypeTxtLocale}: ${R.pathOr('', [GC.FIELD_DISPLAYED_VALUE], orderType)}`;
  const specialInstructionsString = `${specialInstructionsTxtLocale}: ${R.or(specialInstructions, '')}`;
  const internalInstructionsString = `${internalInstructionsTxtLocale}: ${R.or(internalInstructions, '')}`;

  const servicesString = R.compose(
    R.concat(`${servicesTxtLocale}: `),
    R.join(', '),
    R.map(R.path([GC.FIELD_DISPLAYED_VALUE])),
  )(R.or(services, []));

  return (
    <Flex mt='8px' flexWrap='wrap'>
      <Flex mr={10}>
        <TextComponent
          maxWidth={190}
          withEllipsis={true}
          title={servicesString}
        >
          {servicesString}
        </TextComponent>
        <AuthWrapper has={[PC.TEL_WRITE]}>
          <Box ml='5px' cursor='pointer' onClick={handleSetServices}>
            {I.pencil(iconColor)}
          </Box>
        </AuthWrapper>
      </Flex>
      <Flex mr={10}>
        <TextComponent
          maxWidth={190}
          withEllipsis={true}
          title={equipmentString}
        >
          {equipmentString}
        </TextComponent>
        <AuthWrapper has={[PC.TEL_WRITE]}>
          <Box ml='5px' cursor='pointer' onClick={handleSetEquipments}>
            {I.pencil(iconColor)}
          </Box>
        </AuthWrapper>
      </Flex>
      {
        G.isLoadTypeClo(load) && (
          <Flex mr={10}>
            <TextComponent
              maxWidth={190}
              withEllipsis={true}
              title={orderTypeString}
            >
              {orderTypeString}
            </TextComponent>
            <AuthWrapper has={[PC.CLO_WRITE]}>
              <Box ml='5px' cursor='pointer' onClick={handleSetOrderType}>
                {I.pencil(iconColor)}
              </Box>
            </AuthWrapper>
          </Flex>
        )
      }
      <Flex mr={10}>
        <TextComponent
          maxWidth={190}
          withEllipsis={true}
          title={specialInstructionsString}
        >
          {specialInstructionsString}
        </TextComponent>
        <AuthWrapper has={[PC.TEL_WRITE]}>
          <Box ml='5px' cursor='pointer' onClick={handleSetSpecialInstructions}>
            {I.pencil(iconColor)}
          </Box>
        </AuthWrapper>
      </Flex>
      <Flex mr={10}>
        <TextComponent
          maxWidth={190}
          withEllipsis={true}
          title={internalInstructionsString}
        >
          {internalInstructionsString}
        </TextComponent>
        <AuthWrapper has={[PC.TEL_WRITE]}>
          <Box ml='5px' cursor='pointer' onClick={handleSetInternalInstructions}>
            {I.pencil(iconColor)}
          </Box>
        </AuthWrapper>
      </Flex>
      {
        G.getUseCommissionAssignmentConfigFromWindow() &&
        <CommissionAssignee
          load={load}
          fromPage={fromPage}
          openModal={openModal}
          closeModal={closeModal}
          tableIndex={tableIndex}
          changeCommissionAssignmentRequest={changeCommissionAssignmentRequest}
        />
      }
    </Flex>
  );
};

const LoadHeader = (props: Object) => {
  const {
    load,
    fromPage,
    openModal,
    closeModal,
    openLoader,
    tableIndex,
    pinnedNote,
    closeLoader,
    selectedRate,
    totalDistance,
    configCurrency,
    approvalRequired,
    handlePinMessage,
    handleSetServices,
    loadCustomStatuses,
    handleSetOrderType,
    handleSetEquipments,
    updateLoadDetailsRequest,
    updateOrderDetailsRequest,
    handleSetSpecialInstructions,
    handleSetInternalInstructions,
    changeCommissionAssignmentRequest,
    getAvailableLoadCustomStatusListSuccess,
  } = props;

  const {
    guid,
    status,
    services,
    approved,
    loadType,
    equipment,
    approvedBy,
    orderType,
    customStatus,
    costAllocations,
    loadCancelReason,
    specialInstructions,
    internalInstructions,
  } = load;

  const getUpdateDetailsAction = (values: Object) => {
    const data = R.mergeRight(load, values);

    if (G.isLoadTypeClo(loadType)) return updateOrderDetailsRequest(data);

    return updateLoadDetailsRequest(data);
  };

  return (
    <Flex
      p='8px 25px'
      fontSize={11}
      borderBottom='1px solid'
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Flex alignItems='flex-start' flexDirection='column'>
        {
          approvalRequired &&
          <CloApproval
            guid={guid}
            fromPage={fromPage}
            approved={approved}
            tableIndex={tableIndex}
            approvedBy={approvedBy}
          />
        }
        <Flex>
          <StatusWrapper
            status={status}
            loadCancelReason={G.getDisplayedValueFromObject(loadCancelReason)}
          />
          <Flex alignItems='flex-start' flexDirection='column'>
            <TotalTripDistanceSection totalDistance={totalDistance} selectedRate={selectedRate} />
            <AuthWrapper has={[PC.SHOW_EARNINGS_EXECUTE]}>
              <RouteMarginSection
                loadType={loadType}
                selectedRate={selectedRate}
                configCurrency={configCurrency}
                costAllocations={costAllocations}
              />
            </AuthWrapper>
          </Flex>
        </Flex>
        <LoadCustomStatus
          loadType={loadType}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          customStatus={customStatus}
          loadCustomStatuses={loadCustomStatuses}
          setCustomStatus={getUpdateDetailsAction}
          branchGuid={G.getBranchGuidFromObject(load)}
          getAvailableLoadCustomStatusListSuccess={getAvailableLoadCustomStatusListSuccess}
        />
        <AdditionalInfo
          load={load}
          fromPage={fromPage}
          services={services}
          openModal={openModal}
          equipment={equipment}
          orderType={orderType}
          tableIndex={tableIndex}
          closeModal={closeModal}
          handleSetServices={handleSetServices}
          handleSetOrderType={handleSetOrderType}
          handleSetEquipments={handleSetEquipments}
          specialInstructions={specialInstructions}
          internalInstructions={internalInstructions}
          handleSetSpecialInstructions={handleSetSpecialInstructions}
          handleSetInternalInstructions={handleSetInternalInstructions}
          changeCommissionAssignmentRequest={changeCommissionAssignmentRequest}
        />
      </Flex>
      {
        R.and(G.isNotNil(pinnedNote), R.not(G.isCurrentUserTypeCustomer())) &&
        <Box ml='auto' minWidth={300} maxWidth={400}>
          <MessageItem message={pinnedNote} isActiveTabNotes={true} handlePinMessage={handlePinMessage} />
        </Box>
      }
    </Flex>
  );
};

export default LoadHeader;
