import styled from 'styled-components';
//////////////////////////////////////////////////

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  text-align: justify;
  color: ${({ theme }: Object) => theme.colors.textDark};

  @media (min-width: 576px) {
    padding: 60px 40px;
  }

  @media (min-width: 768px) {
    padding: 80px 60px;
  }

  @media (min-width: 992px) {
    padding: 100px 160px;
  }
`;

export const PageTitle = styled.h1`
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;

  @media (min-width: 576px) {
    font-size: 24px;
  }

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 992px) {
    font-size: 64px;
  }
`;

export const TextBold = styled.p`
  font-size: 12px;
  font-weight:  700;
  margin: ${({ margin }: Object) => margin || '10px 0 0'};
  text-align: ${({ textAlign }: Object) => textAlign || 'start'};

  @media (min-width: 640px) {
    font-size: 14px;
  }

  @media (min-width: 832px) {
    font-size: 16px;
  }
`;

export const TextRegular = styled.p`
  font-size: 10px;
  line-height: 1.5;
  font-weight: 400;
  margin: ${({ margin }: Object) => margin || '10px 0 0'};

  @media (min-width: 640px) {
    font-size: 12px;
  }

  @media (min-width: 832px) {
    font-size: 14px;
  }
`;

export const TextItalic = styled(TextRegular)`
  font-style: italic;
`;

export const Ol = styled.ol`
  padding-left: 0;
`;

export const Ul = styled.ul`
  width:  100%;
  font-weight: 400;
  padding-left: 20px;

  @media (min-width: 640px) {
    padding-left: 40px;
  }
`;

export const Li = styled.li`
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;

  @media (min-width: 640px) {
    font-size: 12px;
  }

  @media (min-width: 832px) {
    font-size: 14px;
  }
`;

export const LiUl = styled.li`
  font-size: 10px;
  margin-top: 10px;
  line-height: 1.5;
  list-style-type: disc;

  @media (min-width: 640px) {
    font-size: 12px;
  }

  @media (min-width: 832px) {
    font-size: 14px;
  }
`;

export const LiOl = styled.li`
  font-size: 16px;
  margin-top: 30px;
  font-weight:  600;
  list-style-position: inside;

  & > ol {
    padding-left: 30px;
  }

  @media (min-width: 640px) {
    font-size: 18px;
  }

  @media (min-width: 832px) {
    font-size: 20px;
  }
`;

export const Table = styled.table`
  margin-top: 40px;
  margin-bottom; 40px;
  border: 1px solid darkGrey;
`;

export const Td = styled.td`
  padding: 10px;
  font-size: 10px;
  vertical-align: middle;
  border: 1px solid darkGrey;
  text-align: ${({ textAlign }: Object) => textAlign || 'start'};
  font-weight:  ${({ fontWeight }: Object) => fontWeight || '400'};

  @media (min-width: 640px) {
    font-size: 12px;
  }

  @media (min-width: 832px) {
    font-size: 14px;
  }
`;
