import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature toll charges
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  itemList: {},
  totalCount: 0,
  loading: false,
  usedReport: {},
  truckGuid: null,
  filterParams: {},
  titleSortValues: {},
  reportPending: false,
  tableTitleFilters: {},
  additionalFilters: {},
  pagination: {
    limit: 20,
    offset: 0,
  },
};

const setInitialState = () => initialState;

const setTruckGuid = (state: Object, truckGuid: string) => P.$set('truckGuid', truckGuid, state);

const toggleIftaReportDetails = (state: Object, data: Object) => (
  P.$toggle(`itemList.${data.guid}.expanded`, state)
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const getIftaListSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;
  const { results, totalCount } = data;

  const mappedList = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        index,
        expanded: false,
        [GC.SYSTEM_LIST_TELS]: [],
        activeTab: GC.FIELD_IFTA_TRUCKS,
        [GC.SYSTEM_LIST_FUEL_TRANSACTIONS]: [],
      },
    )),
  )(results);
  const newList = R.mergeRight(itemList, mappedList);
  const newOffset = R.sum(R.values(pagination));
  const offset = G.ifElse(R.gt(data.totalCount, pagination.offset), newOffset, totalCount);

  return P.$all(
    P.$set('itemList', newList),
    P.$set('totalCount', totalCount),
    P.$set('pagination.offset', offset),
    state,
  );
};

const setAdditionalFilters = (state: Object, filters: Object) => (
  P.$set('additionalFilters', filters, state)
);

const setQuickFilterParams = (state: Object, data: Object) => (
  P.$set('filterParams', data, state)
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setReportPending = (state: Object) => (
  P.$set('reportPending', true, state)
);

const setReports = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('filterParams', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const removeIFTASuccess = (state: Object, guid: string) => (
  P.$drop(`itemList.${guid}`, state)
);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('itemList', {}),
    P.$set('totalCount', 0),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const changeActiveTabSuccess = (state: Object, { guid, list, tabName }: Object) => (
  P.$set(
    `itemList.${guid}`,
    R.mergeRight(
      R.path(['itemList', guid], state),
      { [tabName]: list, activeTab: tabName },
    ),
    state,
  )
);

export default createReducer({
  [A.setReports]: setReports,
  [A.setTruckGuid]: setTruckGuid,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setReportPending]: setReportPending,
  [A.removeIFTASuccess]: removeIFTASuccess,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.getIftaListSuccess]: getIftaListSuccess,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.setAdditionalFilters]: setAdditionalFilters,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.changeActiveTabSuccess]: changeActiveTabSuccess,
  [A.toggleIftaReportDetails]: toggleIftaReportDetails,
}, initialState);
