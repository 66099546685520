import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// utilities
import { sendRequest } from '../../../../../utilities/http';
import endpointsMap from '../../../../../utilities/endpoints';
//////////////////////////////////////////////////

export class ConditionValues extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      conditionValues: null,
    };
    this.postConditionValues = this.postConditionValues.bind(this);
    this.postCondtionsValuesRequest = this.postCondtionsValuesRequest.bind(this);
  }

  postConditionValues(props: Object, valaue: string) {
    this.postCondtionsValuesRequest(valaue, props);
  }

  async postCondtionsValuesRequest(arg: string, props: Object) {
    if (G.isNilOrEmpty(arg)) return;
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: props.branchGuid, conditionType: arg },
    };
    const res = await sendRequest('get', endpointsMap.conditionTypeEndpoint, options);
    const { status, data } = res;
    if (R.and(G.isResponseSuccess(status), G.isNotEmpty(data))) {
      const newState = P.$set('conditionValues', data, this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncConditionValues -> postConditionValues',
      );
    }
  }

  render() {
    return (
      <div>
        {
          this.props.render(
            this.state,
            this.postConditionValues,
          )
        }
      </div>
    );
  }
}

export const withAsyncConditionValues = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <ConditionValues
          branchGuid={this.props.branchGuid}
          render={
            (parentState: Object, postConditionValues: Function) => (
              <Component
                {...this.props}
                postConditionValues={postConditionValues}
                conditionValues={parentState.conditionValues} />
          )} />
      );
    }
  };
};
