import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS } from '../../../../../helpers/options';
// forms
import { Fieldset2 } from '../../../../../forms';
// feature configs
import { makeSelectServicesForAccessorials } from '../../../selectors';
import { accessorialFieldSettings, accessorialValidationSchema, defaultAccessorialSectionFields } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: accessorialValidationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultAccessorialSectionFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { shouldUpdate, serviceOptions, updateAccessorialRequest, createAccessorialRequest } = props;

      const data = G.mapObjectEmptyStringFieldsToNull(values);

      if (G.isTrue(shouldUpdate)) {
        const servicesGuids = R.pathOr([], ['optionsGuids'], serviceOptions);
        const service = G.getPropFromObject(GC.FIELD_ACCESSORIAL_SERVICE, data);

        const serviceGuid = G.ifElse(G.isNilOrEmpty(service), null, service);

        if (R.and(G.isNotNilAndNotEmpty(serviceGuid), R.includes(serviceGuid, servicesGuids))) {
          return updateAccessorialRequest(R.assoc(GC.FIELD_ACCESSORIAL_SERVICE, serviceGuid, data));
        }

        return updateAccessorialRequest(data);
      }

      createAccessorialRequest(data);
    },
  }),
  pure,
);

const AccessorialsForm = (props: Object) => {
  const { values, handleSubmit, serviceOptions } = props;

  const rateType = G.getPropFromObject(GC.FIELD_CHARGE_RATE_TYPE, values);

  return (
    <form onSubmit={handleSubmit}>
      {
        accessorialFieldSettings.map(({ fields }: Object, index: number) => (
          <Fieldset2
            {...G.getFormikProps(props)}
            key={index}
            fields={fields}
            serviceOptions={R.pathOr([], [GC.FIELD_OPTIONS], serviceOptions)}
            fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between' }}
            rateUnitOptions={R.pathOr([], [rateType], DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS)}
          />
        ))
      }
      <FormFooter2 />
    </form>
  );
};

const mapStateToProps = (state: Object) => ({
  serviceOptions: makeSelectServicesForAccessorials()(state),
});

export default connect(mapStateToProps)(enhance(AccessorialsForm));
