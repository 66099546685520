import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import {
  defaultGpsIntegrationConfigFields,
  gpsIntegrationConfigFieldSettings,
  getGpsIntegrationConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultGpsIntegrationConfigFields,
      props.initialValues,
    ),
    validationSchema: Yup.lazy((values: Object) => Yup.object().shape(getGpsIntegrationConfigValidationSchema(values))),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      submitAction(values);
    },
  }),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    ({ values }: Object) => ({
      fieldSettings: gpsIntegrationConfigFieldSettings(values),
    }),
  ),
  pure,
);

const GpsIntegrationConfigForm = (props: Object) => {
  const { handleSubmit, fieldSettings } = props;

  const formikProps = G.getFormikProps(props);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formikProps}
        fields={fieldSettings}
        fieldsWrapperStyles={{
          mt: 10,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      />
      <FormFooter />
    </form>
  );
};

export default enhance(GpsIntegrationConfigForm);
