import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { Table } from '../../components/table';
import EditReport from '../../components/edit-report';
import { TitlePanel } from '../../components/title-panel';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { FixedFlex, RelativeBox, CancelButton, ZOrderWrapper } from '../../ui';
// feature carrier-rate-request-report
import { tableSettings, columnSettings, FILTER_PARAMS } from './settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  setUsedReport,
  setInitialState,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  reportEnhancer,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        createReportRequest,
        updateReportRequest,
        getItemListRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.setInitialState();
    },
  }),
  pure,
);


export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const data = {
    loading,
    totalCount,
    pagination,
    tableSettings,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: false,
    handleTableTitleFilter,
    report: selectedReport,
    useSearchableColumns: true,
    withResizableColumns: true,
    itemList: R.values(itemList),
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: G.ifElse(loading, () => {}, getItemListRequest),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS)),
  };

  return <Table {...data} />;
};

const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const CarrierRateRequestReport = (props: Object) => (
  <RelativeBox
    pr={15}
    p={props.padding}
    height={props.height}
    maxHeight={props.maxHeight}
    bg={G.getTheme('pages.layOutBgColor')}
  >
    <ZOrderWrapper zIndex={2}>
      <TitlePanel
        {...props}
        version={2}
        withCount={true}
        noExportable={true}
        popperWithCount={true}
        filterProps={FILTER_PARAMS}
        shouldSubmitFilterForm={true}
        hiddenRightFilterInfo={false}
        type={GC.CARRIER_RATE_REQUEST_REPORT}
        title={`${G.getWindowLocale('titles:bid-history', 'Bid History')}: ${R.pathOr('', ['carrierName'], props)}`}
      />
    </ZOrderWrapper>
    <ZOrderWrapper zIndex='1' height={props.height}>
      {renderTable(props)}
    </ZOrderWrapper>
    {
      G.isTrue(props.expandedContainer) &&
      <FixedFlex
        bottom='0'
        width='100%'
        p='14px 20px'
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
        background={G.getTheme('colors.white')}
      >
        <CancelButton
          height={32}
          width={150}
          p='4px 8px'
          fontSize={14}
          bgColor='none'
          background='none'
          border='1px solid'
          borderRadius='5px'
          textTransform='uppercase'
          textColor={greyMatterhornColor}
          borderColor={greyMatterhornColor}
          onClick={() => props.closeExpandedContainer(true)}
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </CancelButton>
      </FixedFlex>
    }
  </RelativeBox>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  setUsedReport,
  setInitialState,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
})(enhance(CarrierRateRequestReport));
