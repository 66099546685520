import * as Yup from 'yup';
import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
// components
import { FormFooter2 } from '../../../../components/form-footer';
import { FormSectionHeader } from '../../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
// feature claim-management
import { useCreateDropdownOptionsFromAsyncConfigs } from '../../hooks';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const thinInputWrapperStyles = {
  mb: 25,
  width: 165,
};

const formSections = [
  {
    additionalStyles: { mt: '0px' },
    title: ['titles:general', 'General'],
    fields: [
      {
        isRequired: true,
        inputWrapperStyles,
        type: 'datePicker',
        fieldName: GC.FIELD_ACCIDENT_DATE,
        label: ['titles:accident-date', 'Accident Date'],
      },
      {
        isRequired: true,
        inputWrapperStyles,
        type: 'reactSelect',
        options: GC.CLAIM_ACCIDENT_TYPE,
        fieldName: GC.FIELD_ACCIDENT_TYPE,
        label: ['titles:accident-type', 'Accident Type'],
      },
      {
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: GC.FIELD_INSURANCE_CLAIM_NUMBER,
        label: ['titles:insurance-claim-number', 'Insurance Claim Number'],
      },
      {
        fieldName: GC.FIELD_DESCRIPTION,
        label: ['titles:description', 'Description'],
        inputWrapperStyles: {
          mb: 25,
          width: 361,
        },
      },
    ],
  },
  {
    title: ['titles:accident-location', 'Accident Location'],
    fields: [
      {
        isRequired: true,
        inputWrapperStyles,
        type: 'addressAutocomplete',
        label: ['titles:address', 'Address'],
        fieldName: `accidentLocation.${GC.FIELD_ADDRESS}`,
        locationMapper: ({ address1, ...result }: Object) => ({
          accidentLocation: { ...result, [GC.FIELD_ADDRESS]: address1 },
        }),
      },
      {
        isRequired: true,
        inputWrapperStyles,
        label: ['titles:city', 'City'],
        fieldName: `accidentLocation.${GC.FIELD_CITY}`,
      },
      {
        isRequired: true,
        label: ['titles:state', 'State'],
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: `accidentLocation.${GC.FIELD_STATE}`,
      },
      {
        isRequired: true,
        label: ['titles:zip', 'Zip'],
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: `accidentLocation.${GC.FIELD_ZIP}`,
      },
      {
        isRequired: true,
        type: 'countrySelect',
        label: ['titles:country', 'Country'],
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: `accidentLocation.${GC.FIELD_COUNTRY}`,
      },
    ],
  },
  {
    title: ['titles:accident-details', 'Accident Details'],
    fields: [
      {
        fieldName: 'accidentDetails.dotNumber',
        label: ['titles:dot-number', 'DOT Number'],
        inputWrapperStyles: { ...thinInputWrapperStyles, ml: 395 },
      },
      {
        type: 'radio',
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.commercialVehicle',
        label: ['titles:was-this-in-commercial-vehicle', 'Was this in commercial vehicle?'],
      },
      {
        type: 'radio',
        labelStyles: { textAlign: 'center' },
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.dotRecordableAccident',
        label: ['titles:was this-a-dot-recordable-accident', 'Was this a DOT recordable accident?'],
      },
      {
        type: 'radio',
        inputWrapperStyles: { mb: 25, width: 180 },
        fieldName: 'accidentDetails.vehicleTowedAway',
        label: ['titles:was-the-vehicle-towed-away', 'Was the vehicle towed away?'],
      },
      {
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.numberOfFatalities',
        label: ['titles:number-of-fatalities', 'Number of Fatalities'],
      },
      {
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.numberOfVehicles',
        label: ['titles:number-of-vehicles', 'Number of Vehicles'],
      },
      {
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.numberOfInjuries',
        label: ['titles:number-of-injures', 'Number of Injures'],
      },
      {
        type: 'radio',
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.driverInjured',
        label: ['titles:driver-injured', 'Driver Injured?'],
      },
      {
        type: 'radio',
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.hazmatAccident',
        label: ['titles:hazmat-accident', 'Hazmat Accident/Incident?'],
      },
      {
        type: 'radio',
        inputWrapperStyles: { mb: 25, width: 180 },
        fieldName: 'accidentDetails.driverAtFault',
        label: ['titles:was-a-driver-at-fault', 'Was a driver at fault?'],
      },
      {
        type: 'radio',
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.driverTicketed',
        label: ['titles:was-driver-ticked', 'Was driver ticked?'],
      },
      {
        fieldName: 'accidentDetails.citedBy',
        label: ['titles:cited-by', 'Cited By'],
        inputWrapperStyles: thinInputWrapperStyles,
      },
      {
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.citationNumber',
        label: ['titles:citation-number', 'Citation Number'],
      },
      {
        fieldName: 'accidentDetails.citationNotes',
        inputWrapperStyles: { mb: 25, width: 575 },
        label: ['titles:citation-notes', 'Citation Notes'],
      },
      {
        type: 'radio',
        fieldName: 'accidentDetails.policeReport',
        inputWrapperStyles: thinInputWrapperStyles,
        label: ['titles:was-there-police-report', 'Was there police report?'],
      },
      {
        fieldName: 'accidentDetails.officerName',
        inputWrapperStyles: thinInputWrapperStyles,
        label: ['titles:officer-name', 'Officer Name'],
      },
      {
        inputWrapperStyles: thinInputWrapperStyles,
        fieldName: 'accidentDetails.policeReportNumber',
        label: ['titles:police-report-number', 'Police Report Number'],
      },
      {
        inputWrapperStyles: { mb: 25, width: 575 },
        fieldName: 'accidentDetails.policeReportNotes',
        label: ['titles:police-notes', 'Police Notes'],
      },
    ],
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_ACCIDENT_DATE]: G.yupStringRequired,
  [GC.FIELD_ACCIDENT_TYPE]: G.yupStringRequired,
  accidentLocation: Yup.object().shape({
    [GC.FIELD_ZIP]: G.yupStringRequired,
    [GC.FIELD_CITY]: G.yupStringRequired,
    [GC.FIELD_STATE]: G.yupStringRequired,
    [GC.FIELD_COUNTRY]: G.yupStringRequired,
    [GC.FIELD_ADDRESS]: G.yupStringRequired,
  }),
});

const defaultValues = {
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_ACCIDENT_DATE]: '',
  [GC.FIELD_INSURANCE_CLAIM_NUMBER]: '',
  accidentLocation: {
    [GC.FIELD_ZIP]: '',
    [GC.FIELD_CITY]: '',
    [GC.FIELD_STATE]: '',
    [GC.FIELD_COUNTRY]: '',
    [GC.FIELD_ADDRESS]: '',
  },
  accidentDetails: {
    citedBy: '',
    dotNumber: '',
    officerName: '',
    citationNotes: '',
    citationNumber: '',
    policeReport: false,
    numberOfVehicles: '',
    driverAtFault: false,
    numberOfInjuries: '',
    driverInjured: false,
    policeReportNotes: '',
    hazmatAccident: false,
    driverTicketed: false,
    policeReportNumber: '',
    numberOfFatalities: '',
    vehicleTowedAway: false,
    commercialVehicle: false,
    dotRecordableAccident: false,
  },
};

const AccidentForm = (props: Object) => {
  const { claimGuid, submitAction, removeAction, claimBranchGuid, initialValues = {} } = props;

  const onSubmit = (values: Object) => submitAction(G.mapObjectEmptyStringFieldsToNull(values));

  const { guid, accidentType } = initialValues;

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      ...defaultValues,
      ...initialValues,
      claimGuid,
      [GC.FIELD_ACCIDENT_TYPE]: R.propOr(null, GC.FIELD_DROPDOWN_OPTION_GUID, accidentType),
    },
  });

  const configOptions = useCreateDropdownOptionsFromAsyncConfigs({
    branchGuid: claimBranchGuid,
    names: GC.CLAIM_ACCIDENT_TYPE,
  });

  const whiteColor = G.getTheme('colors.white');
  const accidentLoc = G.getWindowLocale('titles:accident', 'Accident');

  const fieldsWrapperStyles = {
    pt: 15,
    px: 15,
    width: 1198,
    alignItems: 'start',
    justifyContent: 'space-between',
  };

  const formFooterProps = useMemo(() => ({
    cancelAction: () => removeAction(guid),
    submitBtnText: `${G.getWindowLocale('actions:update', 'Update')} ${accidentLoc}`,
    cancelBtnText: `${G.getWindowLocale('actions:remove', 'Remove')} ${accidentLoc}`,
    boxStyles: { p: 15, bg: '#F6F6F6', borderTop: '1px solid', borderColor: '#E4E4E4' },
    submitBtnStyles: { border: 'none', width: 'unset', textColor: whiteColor, bgColor: G.getTheme('colors.dark.blue') },
    cancelBtnStyles: {
      border: 'none',
      width: 'unset',
      textColor: whiteColor,
      bgColor: G.getTheme('colors.light.mainRed'),
      cursor: G.ifElse(R.isNil(guid), 'not-allowed', 'pointer'),
    },
  }), [guid]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box overflowX='auto'>
        {
          formSections.map(({ title, fields, additionalStyles }: Object, index: number) => (
            <FormSectionHeader
              key={index}
              expanded={true}
              title={G.getWindowLocale(...title)}
              additionalStyles={{ ...additionalStyles, width: 1198 }}
            >
              <Fieldset2
                {...formik}
                {...configOptions}
                key={index}
                fields={fields}
                fieldsWrapperStyles={fieldsWrapperStyles}
              />
            </FormSectionHeader>
          ))
        }
      </Box>
      <FormFooter2 {...formFooterProps} />
    </form>
  );
};

export default AccidentForm;
