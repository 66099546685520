import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import { equipmentOptionsMap } from '../constants';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '270px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

const fieldSettings = (integrationType: string) => [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: [
      {
        label: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
      },
      {
        label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
        value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
      },
    ],
  },
  {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:equipment',
    options: 'equipmentOptions',
    fieldName: GC.FIELD_EQUIPMENT_GUID,
  },
  {
    ...defaultStyles,
    loc: 'titles:external-equipment',
    options: 'externalEquipmentOptions',
    fieldName: GC.FIELD_EXTERNAL_EQUIPMENT,
    type: G.ifElse(R.equals(integrationType, GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI), 'text', 'searchselect'),
  },
];

const defaultFields = {
  [GC.FIELD_EQUIPMENT_GUID]: null,
  [GC.FIELD_EXTERNAL_EQUIPMENT]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_EQUIPMENT_GUID]: G.yupStringRequired,
  [GC.FIELD_EXTERNAL_EQUIPMENT]: G.yupStringRequired,
});

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  withState('prevProps', 'setPrevProps', {}),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    ({ values, equipmentOptions }: Object) => {
      const integrationType = R.pathOr(GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS, [GC.FIELD_INTEGRATION_TYPE], values);
      const externalEquipmentOptions = R.compose(
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ label, value })),
        R.pathOr([], [integrationType]),
      )(equipmentOptionsMap);

      return {
        optionsForSelect: { externalEquipmentOptions, equipmentOptions: R.drop(1, equipmentOptions) },
      };
    },
  ),
  pure,
);

const EquipmentMappingConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      optionsForSelect={props.optionsForSelect}
      fields={fieldSettings(R.path(['values', GC.FIELD_INTEGRATION_TYPE], props))}
      handlers={{
        handleDisableIntegrationType: () => props.editMode,
      }}
    />
    <FormFooter boxStyles={{ mt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(EquipmentMappingConfigForm);
