import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Form, renderFormGroup } from '../../../forms';
// feature carrier
import { CARRIER_DOCUMENT_FORM } from '../constants';
import { setOptions, setDisabled } from '../helpers';
import { errorClass, formGroupPosition } from '../ui';
import { documentFields } from '../settings/fields-settings';
import { makeSelectCarrierDocFormValues } from '../selectors';
///////////////////////////////////////////////////////////////////////////////////////////////////

const enhance = compose(
  reduxForm({ form: CARRIER_DOCUMENT_FORM }),
  withState('documentFilename', 'setFile', null),
  withHandlers({
    handleFormSubmit: (props: Object) => (values: Object) => {
      const data = {
        ...values,
        primaryObjectGuid: props.carrierGuid,
        file: props.documentFilename,
      };
      props.submitAction(data);
      props.closeModal();
    },
    handleSelectFile: (props: Object) => (event: Object) => {
      props.change('documentFilename', event.target.value);
      props.change('url', null);
      props.setFile(event.target.files[0]);
    },
    handleCleanUpload: (props: Object) => () => {
      props.change('documentFilename', null);
      props.change('url', null);
      props.setFile(null);
    },
  }),
  pure,
);

const getValidation = (field: Object, values: Object) => {
  if (R.and(R.equals(field.nameForAttribute, 'url'), G.isNotNilAndNotEmpty(values.documentFilename))) {
    return null;
  }
  return field.validate;
};

export const TruckDocComponent = (props: Object) => (
  <div>
    <Form
      id={CARRIER_DOCUMENT_FORM}
      onSubmit={props.handleSubmit(props.handleFormSubmit)}
    >
      {
        documentFields.fields.map((field: Object, index: number) => (
          <Field
            key={index}
            margin='5px 0'
            direction='row'
            errorFontSize={14}
            jc='space-between'
            labelFontSize={16}
            labelMargin='5px 0'
            align={field.align}
            labelPosition='left'
            errorClass={errorClass}
            withoutCalendarLabel={true}
            name={field.nameForAttribute}
            isClearable={field.isClearable}
            groupPadding='0 10px 10px 10px'
            additionClass={formGroupPosition}
            type={G.setFieldType(field.type)}
            width={R.or(field.width, '230px')}
            options={setOptions(props, field)}
            minDate={G.setMinDate(props, field)}
            maxDate={G.setMaxDate(props, field)}
            label={G.getWindowLocale(field.name)}
            cleanUpload={props.handleCleanUpload}
            component={renderFormGroup(field.type)}
            selectFileAction={props.handleSelectFile}
            validate={getValidation(field, props.formValues)}
            disabled={setDisabled(field.disabled, props.formValues)}
            documentFilename={G.getWindowLocale('actions:upload', 'Upload')} />
        ))
      }
    </Form>
  </div>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  formValues: makeSelectCarrierDocFormValues(state),
}));

export default connect(mapStateToProps)(enhance(TruckDocComponent));
