import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature config
import {
  defaultCrossBorderIntegrationFields,
  getCrossBorderIntegrationFieldSettings,
  getCrossBorderIntegrationValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: ({ editMode }: Object) => Yup.lazy((values: Object) =>
      Yup.object().shape(getCrossBorderIntegrationValidationSchema(values, editMode))),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultCrossBorderIntegrationFields,
      initialValues,
    ),
  }),
  pure,
);

const CrossBorderIntegrationConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ mt: 15 }}
      fields={getCrossBorderIntegrationFieldSettings(props.editMode)}
    />
    <FormFooter2 />
  </form>
);

export default enhance(CrossBorderIntegrationConfigForm);
