import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { generateDefaultReport } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature dispatch-report/load
import * as A from './actions';
import {
  makeSelectReportType,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const endpoints = {
  [GC.CLO_RATE_CHARGE_REPORT]: {
    list: endpointsMap.cloRateChargeList,
    export: endpointsMap.cloRateChargeListExport,
  },
  [GC.TEL_RATE_CHARGE_REPORT]: {
    list: endpointsMap.telRateChargeList,
    export: endpointsMap.telRateChargeListExport,
  },
};

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportType = yield select(makeSelectReportType());
    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('telDefault', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const fields = R.append(
      {
        sequence: 100,
        name: G.ifElse(
          R.equals(reportType, GC.CLO_RATE_CHARGE_REPORT),
          `${GC.FIELD_CLO_RATE}.${GC.FIELD_CLO}.${GC.FIELD_GUID}`,
          `${GC.FIELD_TEL_RATE}.${GC.FIELD_TEL}.${GC.FIELD_GUID}`,
        ),
      },
      R.pathOr([], ['fields'], reportParams),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      fields,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const endpoint = R.path([reportType, 'list'], endpoints);

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpoint, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({data, guids}));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleAvailableReportsRequest({ notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return false;

    const reportType = yield select(makeSelectReportType());

    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);

      yield put(A.setReports(checkedReports));

      if (R.not(R.prop('length', reports))) {
        return yield put(A.setUsedReport(generateDefaultReport(reportType)));
      }

      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(reportType));

        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));

    const options = {
      data: R.mergeRight(payload, { searchCriteria, [GC.FIELD_BRANCH_GUID]: currentBranchGuid }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(R.head(checkReportFunction(R.of(Array, data)))));

      yield call(handleAvailableReportsRequest, { notSetUsedReport: true });
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));

    const options = {
      data: R.assoc('searchCriteria', searchCriteria, payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(R.head(checkReportFunction(R.of(Array, data)))));

      yield call(handleAvailableReportsRequest, { notSetUsedReport: true });
      yield call(handleGetItemListSaga, { payload: { openLoader: true } });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleAvailableReportsRequest, {});
      yield call(handleGetItemListSaga, { payload: { openLoader: true } });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const reportType = yield select(makeSelectReportType());
    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { fields, fileType, orderFields, searchCriteria } = payload;

    const reqBody = {
      fields,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const params = { format: fileType };

    const sendData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const options = {
      params,
      data: sendData,
      resType: 'arraybuffer',
    };

    const endpoint = R.path([reportType, 'export'], endpoints);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* visitChargeReportPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { reportType, checkVisitPage } = payload;

    yield call(visitPageSaga, payload, checkVisitPage);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield put(A.setReportPending());
    yield put(A.setReportType(reportType));
    yield put(A.setIgnorePromptStatus(false));

    yield call(handleAvailableReportsRequest, {});
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* chargeReportWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(GC.VISIT_CLO_RATE_CHARGE_REPORT_PAGE, visitChargeReportPageSaga);
  yield takeLatest(GC.VISIT_TEL_RATE_CHARGE_REPORT_PAGE, visitChargeReportPageSaga);
}

export default chargeReportWatcherSaga;
