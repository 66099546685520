import styled from 'styled-components';
// helpers
import * as G from '../../helpers';
//////////////////////////////////////////////////

const AppVersionWrap = styled.div`
  height: max-content;
`;

const CloseModal = styled.div`
  cursor: pointer;
  width: max-content;
  height: max-content;
`;

const EditBtn = styled.div`
  margin: 0 10px;
  cursor: pointer;
`;

const FakeLink = styled.p`
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px 0;
  color: ${() => G.getTheme('common.linkColor')};
`;

const HeaderContent = styled.div`
  display: flex;
  font-weight: bold;
  width: max-content;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeftBlock = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
  height: max-content;
  justify-content: space-between;
`;

const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InputEdit = styled.input`
  width: 50px;
  margin: 0 7px;
`;

const NoteText = styled.p`
  margin: 5px 0;
  font-size: 16px;
  line-height: 25px;
  padding-left: 25px;
  position: relative;
  &:before {
    left: 5px;
    top: 10px;
    width: 5px;
    content: '';
    height: 5px;
    position: absolute;
    border-radius: 100%;
    background-color: ${() => G.getTheme('colors.light.black')};
  }
`;

const TextArea = styled.textarea`
  width: 95%;
  padding: 5px;
  resize: none;
  height: max-content;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  line-height: 22px;
  align-items: center;
  justify-content: space-between;
`;

const TextHeader = styled.div`
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
`;

const VersionNumber = styled.div`
  margin-left: 5px;
  width: max-content;
`;

const WrapperBtn = styled.div`
  display: flex;
  height: max-content;
  justify-content: space-between;
`;

const WrapperBtnsAdd = styled.div`
  width: 100%;
  margin: 0 0 -5px;
  height: max-content;
`;

export {
  EditBtn,
  NoteText,
  FakeLink,
  TextArea,
  InputEdit,
  CloseModal,
  HeaderWrap,
  TextHeader,
  WrapperBtn,
  VersionNumber,
  HeaderContent,
  AppVersionWrap,
  WrapperBtnsAdd,
  HeaderLeftBlock,
  TextAreaWrapper,
};
