import React from 'react';
// components
import { Tabs } from '../../../components/tabs';
import { QuickFilterOutsideControl } from '../../../components/quick-filter';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-board-new
import Summary from './summary';
import * as H from '../helpers';
import * as C from '../constants';
import SelectGroupBy from './select-group-by';
import GlobalSearchType from './global-search-type';
import DispatchBoardTileTabs from './title-tabs';
/////////////////////////////////////////////////

const LeftTitleSection = (props: Object) => {
  const {
    summary,
    groupBy,
    loadType,
    activeTab,
    totalCount,
    selectedReport,
    handleEditReport,
    globalFilterType,
    getSummaryRequest,
    globalFilterValue,
    setGlobalFilterType,
    handleSelectGroupBy,
    handleSummaryFilter,
    handleSetGlobalFilter,
    handleSetSummaryGroup,
    configsForGlobalSearch,
  } = props;

  const searchInputColor = G.getTheme('colors.dark.grey');

  return (
    <Flex>
      <DispatchBoardTileTabs marginLeft='0px' totalCount={totalCount} hasBorderBottom={true} />
      {/* <Box mt='auto'>
        <Tabs
          count={totalCount}
          activeTab={activeTab}
          tabStyles={C.tabStyles}
          tabs={H.getTabs(loadType)}
          countStyles={C.countStyles}
          activeTabStyles={C.activeTabStyles}
        />
      </Box> */}
      <Summary
        summary={summary}
        routeTab={loadType}
        selectedReport={selectedReport}
        handleEditReport={handleEditReport}
        getSummaryRequest={getSummaryRequest}
        handleSummaryFilter={handleSummaryFilter}
        handleSetSummaryGroup={handleSetSummaryGroup}
        handleCleanSummaryFilter={props.handleCleanSummaryFilter}
      />
      <GlobalSearchType
        {...configsForGlobalSearch}
        loadType={loadType}
        globalFilterType={globalFilterType}
        globalFilterValue={globalFilterValue}
        setGlobalFilterType={setGlobalFilterType}
        handleSetGlobalFilter={handleSetGlobalFilter}
      />
      <QuickFilterOutsideControl
        withoutLabel={true}
        outsideValue={globalFilterValue}
        shouldClearTimeoutOnKeyPress={true}
        handleSetFilter={handleSetGlobalFilter}
        onKeyPressHandler={handleSetGlobalFilter}
        endLabel={<Box mr='8px'>{I.search(searchInputColor)}</Box>}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        inputProps={{ p: '0 0 0 8px', height: '28px', width: '106px', withoutBorder: true }}
        boxStyles={{
          mt: 12,
          border: '1px solid',
          borderRadius: '2px',
          borderColor: searchInputColor,
          bg: G.getTheme('colors.light.mainLight'),
        }}
      />
      <SelectGroupBy
        groupBy={groupBy}
        loadType={loadType}
        handleSelectGroupBy={handleSelectGroupBy}
      />
    </Flex>
  );
};

export default LeftTitleSection;
