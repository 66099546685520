import * as R from 'ramda';
import { put, call, takeLatest, select } from 'redux-saga/effects';
// components
import { closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from './actions';
import { getConfigByGroupSaga, getSequencesByTypeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

// task type config
function* getTaskTypeListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getTaskTypeListSuccess,
      parentSagaName: 'getTaskTypeListRequest',
      endpoint: endpointsMap.taskTypeConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTaskTypeListRequest exception');
  }
}

function* createOrUpdateTaskTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.taskTypeConfig, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateTaskTypeSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateTaskTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateTaskTypeRequest exception');
  }
}

function* removeTaskTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.taskTypeConfig, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeTaskTypeSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeTaskTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeTaskTypeRequest exception');
  }
}

// task status config
function* getTaskStatusListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getTaskStatusListSuccess,
      parentSagaName: 'getTaskStatusListRequest',
      endpoint: endpointsMap.taskStatusConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTaskStatusListRequest exception');
  }
}

function* createOrUpdateTaskStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { version, completed, daysBeforeRemoval } = payload;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const requestData = R.mergeRight(
      payload,
      {
        daysBeforeRemoval: G.ifElse(completed, daysBeforeRemoval, null),
        [GC.FIELD_BRANCH_GUID]: R.propOr(branchGuid, GC.FIELD_BRANCH_GUID, payload),
      },
    );

    const options = { data: requestData };

    const res = yield call(sendRequest, method, endpointsMap.taskStatusConfig, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateTaskStatusSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateTaskStatusRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateTaskStatusRequest exception');
  }
}

function* removeTaskStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.taskStatusConfig, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeTaskStatusSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeTaskStatusRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeTaskStatusRequest exception');
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_TASK_MANAGEMENT_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield call(getConfigByGroupSaga, GC.TASK_MANAGEMENT_CONFIG_GROUP);
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_TASK });
    yield call(getTaskTypeListRequest);
    yield call(getTaskStatusListRequest);

    yield put(closeLoader());
    break;
  }
}

function* taskManagementConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_TASK_MANAGEMENT_PAGE, handleVisitPageSaga);
  // task type config
  yield takeLatest(A.removeTaskTypeRequest, removeTaskTypeRequest);
  yield takeLatest(A.getTaskTypeListRequest, getTaskTypeListRequest);
  yield takeLatest(A.createOrUpdateTaskTypeRequest, createOrUpdateTaskTypeRequest);
  // task status config
  yield takeLatest(A.removeTaskStatusRequest, removeTaskStatusRequest);
  yield takeLatest(A.getTaskStatusListRequest, getTaskStatusListRequest);
  yield takeLatest(A.createOrUpdateTaskStatusRequest, createOrUpdateTaskStatusRequest);
}

export default taskManagementConfigWatcherSaga;
