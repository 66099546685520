import React from 'react';
// components
import { PopperComponent as Popper } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Text } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const PopperComponent = () => {
  const description = '<PopperComponent /> component is a wrapper for a dropdown behavior based on specified settings.';

  const usage = (
    <ul>
      <li>Parameter `<i>minWidth</i>` sets the minimum width for the popper component.</li>
      <li>Parameter `<i>type</i>` determines the type of interaction triggering the popper.</li>
      <li>Parameter `<i>content</i>` is the content to render within the popper when it`s active.</li>
      <li>Parameter `<i>popperBoxShadow</i>` defines the box shadow styling for the popper element.</li>
      <li>Parameter `<i>rotate</i>` indicates whether the content should rotate when the popper is active.</li>
      <li>Parameter `<i>position</i>` specifies the position of the popper relative to the trigger element.</li>
    </ul>
  );

  const dummyComponent = (
    <Box p={15}>
      <Text>Popper content ...</Text>
    </Box>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='PopperComponent'
      description={description}
      path='src/components/popper'
    >
      <Popper
        type='click'
        rotate={true}
        minWidth={150}
        position='right'
        content={dummyComponent}
        popperBoxShadow={`0 1px 8px 0 ${G.getTheme('colors.boxShadowGrey')}`}
      >
        {I.arrowUpBold(G.getTheme('colors.navyBlue'))}
      </Popper>
    </ComponentStoryWrapper>
  );
};

export default PopperComponent;
