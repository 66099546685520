import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
import { openModal, closeModal } from '../../../components/modal/actions';
// forms
import { LocationFormWithSearch } from '../../../forms/forms/location-form-with-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature branch
import { additionalLocationColumnSettings } from '../settings/fields-settings';
import { makeSelectAdditionalLocationList, makeSelectEditBranchPrimaryLocation } from '../selectors';
import { createBranchAdditionalLocationRequest, deleteBranchAdditionalLocationRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleSubmit: (props: Object) => (location: Object) => {
      const { branchGuid, createBranchAdditionalLocationRequest } = props;

      const operationHours = G.convertOperationHoursToDefaultFormat(
        R.pathOr([], [GC.FIELD_OPERATION_HOUR], location),
      );

      const data = R.mergeRight(
        location,
        {
          [GC.BRANCH_GUID]: branchGuid,
          [GC.FIELD_OPERATION_HOUR]: operationHours,
        },
      );

      createBranchAdditionalLocationRequest(data);
    },
  }),
  withHandlers({
    handleOpenLocationForm: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, branchGuid, handleSubmit, locationTypeOptions } = props;

      const initialValuesWithLocationTypeGuid = G.ifElse(
        G.isNotNilAndNotEmpty(initialValues),
        R.assoc(
          GC.FIELD_LOCATION_TYPE,
          R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues),
          initialValues,
        ),
        initialValues,
      );

      const optionsForSelect = { [GC.FIELD_LOCATION_TYPE]: locationTypeOptions };

      const mode = G.ifElse(
        G.isNilOrEmpty(initialValues),
        G.getAddTitle,
        G.getEditTitle,
      )(['titles:additional-location-template', 'Additional Location Template']);

      const modalContent = (
        <LocationFormWithSearch
          mode={mode}
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          returnFullLocation={true}
          submitHandler={handleSubmit}
          submitAndSaveLocation={false}
          optionsForSelect={optionsForSelect}
          formType={GC.LOCATION_FORM_TYPE_BRANCH_LOCATION}
          initialValues={initialValuesWithLocationTypeGuid}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '87vh',
        },
      };

      openModal(modal);
    },
    handleDelete: (props: Object) => (entity: Object) => {
      const { openModal, closeModal, deleteBranchAdditionalLocationRequest } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () =>
          deleteBranchAdditionalLocationRequest(G.getGuidFromObject(entity)),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:additional-location', 'Additional Location')}?`,
      });

      openModal(modalContent);
    },
  }),
  pure,
);

const AdditionalLocations = (props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    groupName='additionalLocations'
    handleDeleteRow={props.handleDelete}
    entities={props.additionalLocationList}
    handleEditRow={props.handleOpenLocationForm}
    handleAddClick={props.handleOpenLocationForm}
    entitiesFields={additionalLocationColumnSettings}
    isOpened={R.path(['collapsedGroup', 'additionalLocations'], props)}
    handleToggleFormGroup={() => props.collapseBranchFormGroup('additionalLocations')}
    panelTitle={G.getWindowLocale('titles:additional-location-templates', 'Additional Location Templates')}
  />
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  initialValues: makeSelectEditBranchPrimaryLocation(state),
  additionalLocationList: makeSelectAdditionalLocationList(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  createBranchAdditionalLocationRequest,
  deleteBranchAdditionalLocationRequest,
})(enhance(AdditionalLocations));
