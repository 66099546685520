import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import {
  exportInvoicesToEDIRequest,
  sendInvoiceToBCByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import CustomerInvoiceForm from '../../../invoice/components/customer-form';
// forms
import { SelectGLPostedDateForm } from '../../../../forms/forms/select-gl-posted-date-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncEditCloInvoice } from '../../../../hocs/with-async-edit-clo-invoice';
// feature dispatch details new
import * as H from '../../helpers';
import PrintForm, { PrintFormWithCLODocuments } from '../../forms/print-form';
import {
  sendCIToFinancialRequest,
  printOrderCustomerInvoiceRequest,
  updateOrderCustomerInvoiceRequest,
  removeOrderCustomerInvoiceRequest,
  getOrderCustomerInvoiceListRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddCustomerInvoice = compose(
  withHandlers({
    handleAddCloCustomerInvoice: (props: Object) => () => {
      const {
        load,
        openModal,
        itemsVolume,
        loadConfigs,
        configsByGroup,
        orderTotalWeight,
        orderTotalDistance,
        selectedCustomerRate,
        loadConfigs: { configsByNames },
        createOrderCustomerInvoiceRequest,
      } = props;

      if (G.isNilOrEmpty(selectedCustomerRate)) {
        const message = G.getWindowLocale(
          'messages:not-create-customer-invoice',
          'Sorry, you can not create a customer invoice without the selected rate',
        );

        return G.showToastrMessageSimple('info', message);
      }

      const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, load);
      const defaultInvoiceName = R.path([GC.FIELD_PRIMARY_REFERENCE_VALUE], load);

      const configs = R.pathOr(
        R.path(['configGroups', GC.INVOICE_CONFIG_GROUP], loadConfigs),
        [GC.INVOICE_CONFIG_GROUP],
        configsByGroup,
      );

      const defaultNetDays = G.getConfigValueFromStore(
        GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_NET_DAYS,
        configs,
      );

      const defaultStatus = G.getConfigValueFromStore(
        GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const defaultSecondInvoiceStatus = G.getConfigValueFromStore(
        GC.INVOICE_CLO_SECOND_CUSTOMER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const component = (
        <CustomerInvoiceForm
          clo={load}
          branchGuid={branchGuid}
          itemsVolume={itemsVolume}
          defaultStatus={defaultStatus}
          totalWeight={orderTotalWeight}
          defaultNetDays={defaultNetDays}
          totalDistance={orderTotalDistance}
          cloGuid={G.getGuidFromObject(load)}
          selectedRate={selectedCustomerRate}
          defaultInvoiceName={defaultInvoiceName}
          defaultSecondInvoiceStatus={defaultSecondInvoiceStatus}
          handleSendCloInvoice={createOrderCustomerInvoiceRequest}
          sequenceConfigName={GC.INVOICE_CLO_INVOICE_NUMBER_SEQUENCE}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          autogenerateConfigName={GC.INVOICE_CLO_INVOICE_NUMBER_AUTOGENERATED}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 900,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadCustomerInvoiceTableGroupRowActions = compose(
  connect(
    null,
    {
      sendCIToFinancialRequest,
      exportInvoicesToEDIRequest,
      printOrderCustomerInvoiceRequest,
      updateOrderCustomerInvoiceRequest,
      removeOrderCustomerInvoiceRequest,
      getOrderCustomerInvoiceListRequest,
      sendInvoiceToBCByInvoiceTypeRequest,
      createQBIIFImportByInvoiceTypeRequest,
      sendInvoiceToSageByInvoiceTypeRequest,
    },
  ),
  withAsyncEditCloInvoice({ updateFrom: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER }),
  withHandlers({
    handleSendCIToSageIntacctAction: (props: Object) => (glPostedDate: any) => {
      const {
        load,
        entity,
        closeFixedPopup,
        getOrderCustomerInvoiceListRequest,
        sendInvoiceToSageByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(load);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getOrderCustomerInvoiceListRequest({ loadGuid });

      sendInvoiceToSageByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'customerInvoice',
        [GC.FIELD_GL_POSTED_DATE]: glPostedDate,
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
  }),
  withHandlers({
    handleExportInvoiceToEDI: ({ entity, closeFixedPopup, exportInvoicesToEDIRequest }: Object) => () => {
      const guids = R.of(Array, G.getGuidFromObject(entity));

      G.callFunction(closeFixedPopup);
      exportInvoicesToEDIRequest({ guids });
    },
    handleCreateQBIIFImportCustomerInvoice: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getOrderCustomerInvoiceListRequest,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      const callback = () =>
        getOrderCustomerInvoiceListRequest({ [GC.FIELD_LOAD_GUID]: G.getGuidFromObject(load) });

      const data = {
        callback,
        type: 'customer',
        guids: R.of(Array, G.getGuidFromObject(entity)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      };

      createQBIIFImportByInvoiceTypeRequest(data);
    },
    handleSendCIToFinancial: ({ entity, sendCIToFinancialRequest }: Object) => () =>
      sendCIToFinancialRequest(R.prop(GC.FIELD_GUID, entity)),
    handleSendCustomerInvoiceToBC: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getOrderCustomerInvoiceListRequest,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(load);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getOrderCustomerInvoiceListRequest({ loadGuid });

      sendInvoiceToBCByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'customerInvoice',
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleSendCustomerInvoiceListToSageIntacct: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        handleSendCIToSageIntacctAction,
      } = props;

      const component = (
        <SelectGLPostedDateForm
          closeModal={closeModal}
          submitAction={(date: any) => handleSendCIToSageIntacctAction(date)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'auto',
          footerZIndex: 'unset',
          title: G.getWindowLocale('titles:gl-posted-date', 'GL Posted Date'),
        },
      };

      openModal(modal);
    },
    handleRemoveCustomerInvoice: ({ entity, openModal, removeOrderCustomerInvoiceRequest }: Object) => () => {
      const { guid } = entity;

      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-customer-invoice',
        'Do you want to delete this Customer Invoice?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeOrderCustomerInvoiceRequest(guid),
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePrintCustomerInvoice: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        printOrderCustomerInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);

      const component = (
        <PrintForm
          selectedEntity={guid}
          closeModal={closeModal}
          branchGuid={branchGuid}
          useVendorDocuments={true}
          cloGuid={G.getGuidFromObject(load)}
          submitAction={printOrderCustomerInvoiceRequest}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
    handlePrintCustomerInvoiceWithImages: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        printOrderCustomerInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);
      const cloGuid = G.getPropFromObject('cloGuid', entity);

      const printInvoiceProps = {
        cloGuid,
        closeModal,
        branchGuid,
        selectedEntity: guid,
        useVendorDocuments: true,
        [GC.BRANCH_GUID]: branchGuid,
        [GC.FIELD_PRIMARY_OBJECT_GUID]: cloGuid,
        submitAction: printOrderCustomerInvoiceRequest,
        printableSection: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE,
      };

      const component = <PrintFormWithCLODocuments {...printInvoiceProps} />;

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
  }),
);
