import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fieldSettings = [
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: 'transportationModes',
    fieldName: GC.FIELD_TRANSPORTATION_MODE_GUIDS,
    inputWrapperStyles: R.assoc('zIndex', 12, inputWrapperStyles),
    label: ['titles:transportation-modes', 'Transportation Modes'],
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: 'carrierInvoiceStatuses',
    fieldName: GC.FIELD_UPLOAD_TIME_INVOICE_STATUS_GUIDS,
    inputWrapperStyles: R.assoc('zIndex', 11, inputWrapperStyles),
    label: ['titles:upload-time-invoice-statuses', 'Upload Time Invoice Statuses'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: 'availableDocumentTypes',
    fieldName: GC.FIELD_UPLOAD_TIME_DOCUMENT_TYPE_GUID,
    label: ['titles:upload-time-document-type', 'Upload Time Document Type'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LATE_AFTER_MINUTES,
    label: ['titles:late-after-minutes', 'Late After Minutes'],
  },
];

const defaultValues = {
  [GC.FIELD_LATE_AFTER_MINUTES]: null,
  [GC.FIELD_TRANSPORTATION_MODE_GUIDS]: null,
  [GC.FIELD_UPLOAD_TIME_DOCUMENT_TYPE_GUID]: null,
  [GC.FIELD_UPLOAD_TIME_INVOICE_STATUS_GUIDS]: null,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_TRANSPORTATION_MODE_GUIDS]: G.yupArrayRequired,
  [GC.FIELD_UPLOAD_TIME_DOCUMENT_TYPE_GUID]: G.yupStringRequired,
  [GC.FIELD_UPLOAD_TIME_INVOICE_STATUS_GUIDS]: G.yupArrayNotRequired,
  [GC.FIELD_LATE_AFTER_MINUTES]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt()),
});

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  pure,
);

const CarrierScorecardConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      {...R.pathOr({}, ['optionsForSelect'], props)}
      fields={fieldSettings}
      fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
    />
    <FormFooter2 />
  </form>
);

export default enhance(CarrierScorecardConfigForm);
