import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  setInitialState,
  updateGeneralDetailsRequest,
  updateContractGeneralDetailsRequest,
} from '../actions';
//////////////////////////////////////////////////

export const useProfile = (props: Object) => {
  const { type, generalDetails, handleGoBack, expandedContainer, closeExpandedContainer } = props;

  const { guid, carrierGuid } = generalDetails;

  const [saveAndClose, setSaveAndClose] = useState(false);

  const dispatch = useDispatch();

  const commonActions = useMemo(() => ({
    closeModal: () => dispatch(closeModal()),
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
    openModal: (options: Object) => dispatch(openModal(options)),
  }), []);

  const handleGoToCarrier = useCallback(() => {
    dispatch(setInitialState());

    if (expandedContainer) {
      return dispatch(setExpandedContainerOptions({
        opened: true,
        visitPageGuid: R.or(carrierGuid, guid),
        componentType: GC.PAGE_CARRIER_PROFILE,
      }));
    }

    return G.goToCarrierProfileByConfig(R.or(carrierGuid, guid));
  }, [guid, carrierGuid]);

  const handleClose = useCallback(() => {
    if (R.and(G.isFunction(handleGoBack), G.isArray(saveAndClose))) return handleGoBack();

    if (G.isFunction(closeExpandedContainer)) {
      dispatch(setInitialState());

      return closeExpandedContainer();
    }

    G.historyGoBack();
  }, [saveAndClose, handleGoBack]);

  const handleUpdateGeneralDetails = useCallback((values: Object, options: Object) => {
    const { setSubmitting } = options;

    const successCallback = () => (
      saveAndClose ? handleClose() : setSubmitting(false)
    );

    if (R.equals(values, generalDetails)) return successCallback();

    const action = G.ifElse(
      R.isNil(type),
      updateGeneralDetailsRequest,
      updateContractGeneralDetailsRequest,
    );

    dispatch(action({
      type,
      values,
      successCallback,
      failCallback: () => setSubmitting(false),
    }));
  }, [saveAndClose, R.prop(GC.FIELD_VERSION, generalDetails)]);

  useEffect(() => {
    return () => {
      if (R.isNotNil(type)) dispatch(setInitialState({}));
    };
  }, []);

  return {
    dispatch,
    handleClose,
    saveAndClose,
    commonActions,
    setSaveAndClose,
    handleGoToCarrier,
    handleUpdateGeneralDetails,
  };
};
