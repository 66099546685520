import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormGroupTable } from '../../../components/form-group-table';
import { AsyncDocumentPreview } from '../../../components/document-preview';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature branch
import { documentsColumnSettings } from '../settings/branch-document-form-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handlePreviewDocument: ({ openModal, onClickCallback }: Object) => (document: Object) => {
      const { documentFilename, primaryObjectGuid } = document;

      const fileType = R.last(R.split('.', documentFilename));

      let modal;
      let component;

      if (G.notContain(fileType, GC.FILE_PREVIEW_SUPPORTED_EXTENSIONS)) {
        component = (
          <Box fontSize={14}>
            {
              G.getWindowLocale(
                'messages:file-not-supported-for-preview',
                'Sorry, file is not supported for preview',
              )
            }
          </Box>
        );

        modal = {
          p: 15,
          component,
          options: {
            width: 400,
            outsideCloseButton: true,
          },
        };
      } else {
        const data = {
          primaryObjectGuid,
          [GC.FIELD_FILE_NAME]: documentFilename,
        };

        component = (
          <AsyncDocumentPreview
            fileType={fileType}
            asyncEndpoint={endpointsMap.branchDocumentDownload}
            asyncOptions={{ params: data, resType: 'arraybuffer' }}
          />
        );

        modal = {
          p: '0',
          component,
          options: {
            width: '95vw',
            minWidth: 800,
            height: '95vh',
            overflow: 'auto',
            title: documentFilename,
            outsideCloseButton: true,
          },
        };
      }

      if (G.isFunction(onClickCallback)) onClickCallback();

      openModal(modal);
    },
  }),
  pure,
);

const getAdditionalColumnActions = (downloadDocument: Function) => [
  {
    title: G.getWindowLocale('actions:download', 'Download'),
    icon: I.downloadDocument(G.getTheme('colors.darkRed'), 15, 15),
    getVisibility: ({ documentFilename }: Object) => G.ifElse(
      G.isNilOrEmpty(documentFilename),
      'hidden',
      'visible',
    ),
    handleClick: ({ documentFilename, primaryObjectGuid }: Object) => {
      if (G.isNotNilAndNotEmpty(documentFilename)) {
        downloadDocument({
          primaryObjectGuid,
          [GC.FIELD_FILE_NAME]: documentFilename,
        });
      }
    },
  },
];

const DocumentList = enhance((props: Object) => {
  const {
    documents,
    collapsedGroup,
    downloadDocument,
    handleAddDocument,
    handleEditDocument,
    handleDeleteDocument,
    handlePreviewDocument,
    collapseBranchFormGroup,
  } = props;

  return (
    <FormGroupTable
      entities={documents}
      tableColumnWidth={90}
      fields={collapsedGroup}
      groupName='editDocuments'
      handleAddClick={handleAddDocument}
      handleEditRow={handleEditDocument}
      handleDeleteRow={handleDeleteDocument}
      entitiesFields={documentsColumnSettings}
      handlePreviewDocument={handlePreviewDocument}
      isOpened={G.getPropFromObject('editDocuments', collapsedGroup)}
      panelTitle={G.getWindowLocale('titles:documents', 'Documents')}
      handleToggleFormGroup={() => collapseBranchFormGroup('editDocuments')}
      additionalColumnActions={getAdditionalColumnActions(downloadDocument)}
    />
  );
});

export default DocumentList;
