import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature carrier
import * as A from '../actions';
import { transformToReportList } from '../helpers';
import { contractAwardedLineSettings } from '../settings/column-settings';
import {
  makeSelectCarrierGuid,
  makeSelectCustomersContract,
  makeSelectCustomerContractAssessorials,
  makeSelectCustomerContractAwardedLines } from '../selectors';
//////////////////////////////////////////////////

function* handleGetCustomersContractSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const options = {
      params: { carrierGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.customersContractByCarrierGuid, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCustomersContractSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCustomerContractsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCustomerContractsSaga exeption');
  }
}

function* handleGetCustomerContractSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getCustomerContract(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCustomerContractSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCustomerContractSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCustomerContractSaga exeption');
  }
}

function* handleSendCreateCustomerContractSuccessSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const contractGuid = R.prop(GC.FIELD_GUID, payload);
    yield put(A.createCustomerContractSuccess(payload));
    yield put(A.getCustomerContractSuccess(payload));
    yield put(A.getCustomerContractAssessorialsRequest(contractGuid));
    yield put(A.getCustomerContractAwardedLinesRequest(contractGuid));
    yield put(A.setActiveTab({ tabName: 'customerContract' }));
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleSendCreateCustomerContractSuccessSaga exeption');
  }
}

function* handleUpdateCustomerContractSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'put', endpointsMap.customerContract, { data: payload });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const contracts = yield select(makeSelectCustomersContract());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), contracts);
      yield call(G.showToastrMessage, 'success', 'toastr:success:200');
      yield put(A.updateCustomerContractSuccess({ data, index }));
      yield put(A.setActiveTab({
        tabName: 'carrierFinance',
        tabAction: () => {},
      }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCustomerContractSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCustomerContractSaga exeption');
  }
}

function* handleDeleteCustomerContractSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: R.of(Array, payload) };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.customerContract,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const contracts = yield select(makeSelectCustomersContract());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), contracts);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
      yield put(A.deleteCustomerContractSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCustomerContractSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteCustomerContractSaga exeption');
  }
}

function* handleGetCustomerContractAssessorialsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: { contractGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.customerContractAssessorialList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCustomerContractAssessorialsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCustomerContractAssessorialsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCustomerContractAssessorialsSaga exeption');
  }
}

function* handleCreateCustomerContractAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.customerContractAssessorial, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
      yield put(A.createCustomerContractAssessorialSuccess(data));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCustomerContractAssessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleCreateCustomerContractAssessorialSaga exeption');
  }
}

function* handleUpdateCustomerContractAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.customerContractAssessorial,
      { data: payload },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const accessorials = yield select(makeSelectCustomerContractAssessorials());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), accessorials);
      yield call(G.showToastrMessage, 'success', 'toastr:success:200');
      yield put(A.updateCustomerContractAssessorialSuccess({ data: res.data, index }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCustomerContractAssessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCustomerContractAssessorialSaga exeption');
  }
}

function* handleDeleteCustomerContractAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: R.of(Array, payload) };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.customerContractAssessorial,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const accessorials = yield select(makeSelectCustomerContractAssessorials());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), accessorials);
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      yield put(A.deleteCustomerContractAssessorialSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCustomerContractAssessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCustomerContractAssessorialSaga exeption');
  }
}

function* handleGetCustomerContractAwardedLinesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { contractGuid: payload },
      data: {
        currentEnterprise: currentBranchGuid,
        fields: R.keys(contractAwardedLineSettings).map(
          (name: string, sequence: number) => ({
            name,
            sequence,
            reference: false,
            collection: R.pathOr(false, [name, 'collection'], contractAwardedLineSettings),
          }),
        ),
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.customerContractAwardedLineList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCustomerContractAwardedLinesSuccess(data.results));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCustomerContractAwardedLinesSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCustomerContractAwardedLinesSaga exeption');
  }
}

function* handleCreateCustomerContractAwardedLineSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const reqData = R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, payload);
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.customerContractAwardedLine,
      { data: reqData },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
      const listFormat = transformToReportList(data, contractAwardedLineSettings);
      yield put(A.createCustomerContractAwardedLineSuccess(listFormat));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCustomerContractAwardedLineSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCustomerContractAwardedLineSaga exeption');
  }
}

function* handleUpdateCustomerContractAwardedLineSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const reqData = R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, payload);
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.customerContractAwardedLine,
      { data: reqData },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const awardedLines = yield select(makeSelectCustomerContractAwardedLines());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), awardedLines);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      const listFormat = transformToReportList(data, contractAwardedLineSettings);
      yield put(A.updateCustomerContractAwardedLineSuccess({ data: listFormat, index }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCustomerContractAwardedLineSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCustomerContractAwardedLineSaga exeption');
  }
}

function* handleDeleteCustomerContractAwardedLineSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.of(Array, payload),
    };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.customerContractAwardedLine,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const awardedLine = yield select(makeSelectCustomerContractAwardedLines());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), awardedLine);
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      yield put(A.deleteCustomerContractAwardedLineSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCustomerContractAwardedLineSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCustomerContractAwardedLineSaga exeption');
  }
}

export function* CustomerContractSaga() {
  yield takeLatest(A.getCustomerContractRequest, handleGetCustomerContractSaga);
  yield takeLatest(A.getCustomersContractRequest, handleGetCustomersContractSaga);
  yield takeLatest(A.updateCustomerContractRequest, handleUpdateCustomerContractSaga);
  yield takeLatest(A.deleteCustomerContractRequest, handleDeleteCustomerContractSaga);
  yield takeLatest(A.getCustomerContractAwardedLinesRequest, handleGetCustomerContractAwardedLinesSaga);
  yield takeLatest(A.getCustomerContractAssessorialsRequest, handleGetCustomerContractAssessorialsSaga);
  yield takeLatest(A.handleSendCreateCustomerContractSuccess, handleSendCreateCustomerContractSuccessSaga);
  yield takeLatest(A.createCustomerContractAwardedLineRequest, handleCreateCustomerContractAwardedLineSaga);
  yield takeLatest(A.updateCustomerContractAwardedLineRequest, handleUpdateCustomerContractAwardedLineSaga);
  yield takeLatest(A.deleteCustomerContractAwardedLineRequest, handleDeleteCustomerContractAwardedLineSaga);
  yield takeLatest(A.createCustomerContractAssessorialRequest, handleCreateCustomerContractAssessorialSaga);
  yield takeLatest(A.updateCustomerContractAssessorialRequest, handleUpdateCustomerContractAssessorialSaga);
  yield takeLatest(A.deleteCustomerContractAssessorialRequest, handleDeleteCustomerContractAssessorialSaga);
}

export default CustomerContractSaga;
