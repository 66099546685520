import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// forms
import { Toggle } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withBooleanStatus } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Span, Flex, Grid, StyledLink, BoxHovered } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const bgGreyColor = G.getTheme('colors.bgGrey');
const actionColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');
const lightGreyColor = G.getTheme('colors.light.grey');
const textStyles = {
  display: 'block',
  color: textColor,
  withEllipsis: true,
  whiteSpace: 'nowrap',
};

export const InfoPair = ({ mt, text, label, width, fontSize }: Object) => (
  <TextComponent
    {...textStyles}
    mt={mt}
    title={text}
    fontWeight='bold'
    width={R.or(width, '45%')}
    fontSize={R.or(fontSize, 12)}
    maxWidth={R.or(width, '45%')}
  >
    {label}: {text}
  </TextComponent>
);

export const InfoPairWithHandler = ({ mt, text, label, width, fontSize, handleClick }: Object) => (
  <Flex
    mt={mt}
    title={text}
    flexWrap='wrap'
    color={textColor}
    fontWeight='bold'
    width={R.or(width, '45%')}
    fontSize={R.or(fontSize, 12)}
    maxWidth={R.or(width, '45%')}
  >
    <Box mr='5px'>
      {label}:
    </Box>
    <BoxHovered
      cursor='pointer'
      wordBreak='break-all'
      hoverColor={actionColor}
      textDecoration='underline'
      onClick={() => G.callFunction(handleClick)}
    >
      {text}
    </BoxHovered>
  </Flex>
);

export const ContactInfoPair = (props: Object) => {
  if (G.isNilOrEmpty(R.prop(GC.FIELD_TEXT, props))) return null;

  const { mt, text, mail, phone } = props;

  let href = `mailto:${props.text}`;

  if (G.isTrue(phone)) href = G.getCallUrl(G.getAutodialAppConfigFromWindow(), text);

  const color = G.ifElse(phone, textColor, actionColor);
  const textDecoration = G.ifElse(phone, 'unset', 'underline');

  return (
    <Flex
      mt={mt}
      fontSize={12}
      lineHeight={1.6}
    >
      {
        phone &&
        <Box mr='5px'>
          {I.phone(actionColor, 10, 10)}
        </Box>
      }
      {
        mail &&
        <Box mr='5px'>
          {I.renderMailIcon(actionColor, 12, 10)}
        </Box>
      }
      <StyledLink
        href={href}
        color={color}
        fontWeight='bold'
        wordBreak='break-all'
        textDecoration={textDecoration}
      >
        {text}
      </StyledLink>
    </Flex>
  );
};

export const Avatar = ({ driver }: Object) => {
  const { fullText, avatarText } = G.getUserInfo(driver);

  return (
    <Flex
      width={60}
      height={60}
      fontSize={30}
      cursor='pointer'
      title={fullText}
      border='1px solid'
      borderRadius='50%'
      alignItems='center'
      justifyContent='center'
      borderColor={lightGreyColor}
      background='rgba(255, 255, 255, 0.5)'
      onClick={() => G.goToRoute(routesMap[G.getFleetProfileRoutePathNameByFleetType()](G.getGuidFromObject(driver)))}
    >
      {avatarText}
    </Flex>
  );
};

export const LatestKnownLocation = ({ latestLocation }: Object) => (
  <Box
    p='20px 15px'
    color={textColor}
    borderBottom='1px solid'
    borderColor={bgGreyColor}
  >
    <Box
      fontSize={12}
      fontWeight='bold'
      textTransform='uppercase'
    >
      {G.getWindowLocale('titles:last-known-location', 'Last Known Location')}:
    </Box>
    <Box mt='5px' fontSize={11}>
      {G.concatLocationFields(latestLocation)}
    </Box>
  </Box>
);

export const TruckAdditionalEquipment = ({ truckAdditionalEquipment }: Object) => {
  const additionalEquipments = R.join(', ', R.map(R.prop(GC.FIELD_DISPLAYED_VALUE), truckAdditionalEquipment));

  return (
    <Box
      p='20px 15px'
      color={textColor}
      borderBottom='1px solid'
      borderColor={bgGreyColor}
    >
      <Box
        fontSize={12}
        fontWeight='bold'
        textTransform='uppercase'
      >
        {G.getWindowLocale('titles:truck-additional-equipment', 'Truck Additional Equipment')}:
      </Box>
      <Box mt='5px' fontSize={11}>{additionalEquipments}</Box>
    </Box>
  );
};

export const DriverHours = ({ driverHours }: Object) => {
  if (G.isNilOrEmpty(driverHours)) return null;

  const fieldsArray = [
    GC.FIELD_CYCLE_TOMORROW,
    GC.FIELD_CYCLE_REMAINING,
    GC.FIELD_SHIFT_REMAINING,
    GC.FIELD_DUTY_STATUS_CODE,
    GC.FIELD_TIME_UNTIL_BREAK,
    GC.FIELD_OUT_OF_DUTY_REASON,
    GC.FIELD_SHIFT_DRIVER_REMAINING,
    GC.FIELD_TIME_IN_CURRENT_STATUS,
  ];
  const fields = G.pickNotNilAndNotEmptyPropsFromObject(fieldsArray, driverHours);
  const fieldsToUse = R.mapObjIndexed((value: any, key: string) => {
    if (R.equals(key, GC.FIELD_DUTY_STATUS_CODE)) {
      return G.getEnumLocale(value);
    }

    if (R.equals(key, GC.FIELD_OUT_OF_DUTY_REASON)) {
      return G.getDisplayedValueFromObject(value);
    }

    return value;
  }, fields);

  if (G.isNilOrEmpty(fieldsToUse)) return null;

  return (
    <Box
      p='20px 15px'
      color={textColor}
      borderBottom='1px solid'
      borderColor={bgGreyColor}
    >
      <Box
        mb='5px'
        fontSize={12}
        fontWeight='bold'
        textTransform='uppercase'
      >
        {G.getWindowLocale('titles:driver-hours', 'Driver Hours')}:
      </Box>
      <Grid gridGap={10} gridTemplateColumns='1fr 1fr'>
        {
          R.keys(fieldsToUse).map((item: Object, index: number) => (
            <Box
              key={index}
              fontSize={11}
              fontWeight='bold'
            >
              {`${G.getSystemFieldLocale(item)}: ${G.getPropFromObject(item, fieldsToUse)}`}
            </Box>
          ))
        }
      </Grid>
    </Box>
  );
};

export const Certificates = ({ certificates, openedCertificates, handleToggleDriverCertificates }: Object) => (
  <Box
    p='20px 15px'
    color={textColor}
    borderBottom='1px solid'
    borderColor={bgGreyColor}
  >
    <Box
      fontSize={12}
      cursor='pointer'
      fontWeight='bold'
      textTransform='uppercase'
      onClick={handleToggleDriverCertificates}
    >
      {G.getWindowLocale('titles:certificates', 'Certificates')}
      <Span ml='5px'>
        {
          G.ifElse(
            openedCertificates,
            I.arrowUpSimple,
            I.arrowDownSimple,
          )(actionColor)
        }
      </Span>
    </Box>
    {
      openedCertificates &&
      <Box mt={10} border={`1px solid ${bgGreyColor}`}>
        <Flex
          p='10px 5px'
          fontSize={12}
          bg={bgGreyColor}
          fontWeight='bold'
          color={textColor}
        >
          <Box width={80} mr={10}>
            Type
          </Box>
          <Box width={80} mr={10}>
            Name
          </Box>
          <Box width={120} mr={10}>
            Certification Date
          </Box>
          <Box width={120}>
            Expiration Date
          </Box>
        </Flex>
        <Box fontSize={11}>
          {
            certificates.map((item: Object, index: number) => (
              <Flex key={index} p='10px 5px'>
                <TextComponent
                  {...textStyles}
                  mr={10}
                  width={80}
                  maxWidth={80}
                  title={R.path(['certificationType', GC.FIELD_DISPLAYED_VALUE], item)}
                >
                  {R.path(['certificationType', GC.FIELD_DISPLAYED_VALUE], item)}
                </TextComponent>
                <TextComponent
                  {...textStyles}
                  mr={10}
                  width={80}
                  maxWidth={80}
                  title={R.path(['certificationName', GC.FIELD_DISPLAYED_VALUE], item)}
                >
                  {R.path(['certificationName', GC.FIELD_DISPLAYED_VALUE], item)}
                </TextComponent>
                <TextComponent
                  {...textStyles}
                  mr={10}
                  width={120}
                  maxWidth={120}
                  title={G.getPropFromObject(GC.FIELD_CERTIFICATION_DATE, item)}
                >
                  {G.getPropFromObject(GC.FIELD_CERTIFICATION_DATE, item)}
                </TextComponent>
                <TextComponent
                  {...textStyles}
                  width={120}
                  maxWidth={120}
                  title={G.getPropFromObject(GC.FIELD_EXPIRATION_DATE, item)}
                >
                  {G.getPropFromObject(GC.FIELD_EXPIRATION_DATE, item)}
                </TextComponent>
              </Flex>
            ))
          }
        </Box>
      </Box>
    }
  </Box>
);

export const FuelCards = ({
  fuelCards,
  openedFuelCards,
  toggleDriverFuelCardRequest,
  handleToggleDriverFuelCards,
}: Object) => (
  <Box
    p='20px 15px'
    color={textColor}
    borderBottom='1px solid'
    borderColor={bgGreyColor}
  >
    <Box
      fontSize={12}
      cursor='pointer'
      fontWeight='bold'
      textTransform='uppercase'
      onClick={handleToggleDriverFuelCards}
    >
      {G.getWindowLocale('titles:fuel-cards', 'Fuel Cards')}
      <Span ml='5px'>
        {
          G.ifElse(
            openedFuelCards,
            I.arrowUpSimple,
            I.arrowDownSimple,
          )(actionColor)
        }
      </Span>
    </Box>
    {
      openedFuelCards &&
      <Box mt={10} border={`1px solid ${bgGreyColor}`}>
        <Flex
          p='10px 5px'
          fontSize={12}
          bg={bgGreyColor}
          fontWeight='bold'
          color={textColor}
        >
          <Box width={80} mr={10}>
            {G.getWindowLocale('titles:type', 'Type')}
          </Box>
          <Box mr={10} width={120}>
            {G.getWindowLocale('titles:fuel-card-id', 'Fuel Card ID')}
          </Box>
          <Box width={120}>
            {G.getWindowLocale('titles:enabled', 'Enabled')}
          </Box>
        </Flex>
        <Box fontSize={11}>
          {
            fuelCards.map(({ guid, type, enabled, driverGuid, fuelCardId }: Object, index: number) => (
              <Flex key={index} p='10px 5px'>
                <TextComponent
                  {...textStyles}
                  mr={10}
                  width={80}
                  title={type}
                  maxWidth={80}
                >
                  {type}
                </TextComponent>
                <TextComponent
                  {...textStyles}
                  mr={10}
                  width={120}
                  maxWidth={120}
                  title={fuelCardId}
                >
                  {fuelCardId}
                </TextComponent>
                <Flex alignItems='baseline'>
                  <Box>{G.ifElse(G.isTrue(enabled), I.uiTrue, I.uiFalse)()}</Box>
                  <AuthWrapper ml={10} has={[PC.ENABLE_FUEL_CARD_EXECUTE]}>
                    <Toggle
                      icons={false}
                      checked={enabled}
                      onChange={() =>
                        toggleDriverFuelCardRequest({ guid, driverGuid, [GC.FIELD_VALUE]: R.not(enabled) })
                      }
                    />
                  </AuthWrapper>
                </Flex>
              </Flex>
            ))
          }
        </Box>
      </Box>
    }
  </Box>
);

const UnavailablePeriod = (props: Object) => {
  const { period, handleRemoveUnavailablePeriod, handleUpdateUnavailablePeriod } = props;

  const { note, reason, endDate, location, startDate } = period;

  return (
    <Flex p='10px 5px'>
      <TextComponent
        {...textStyles}
        mr={10}
        width={60}
        maxWidth={60}
        title={G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(false))}
      >
        {G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(false))}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={60}
        maxWidth={60}
        title={G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(false))}
      >
        {G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(false))}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={70}
        maxWidth={70}
        title={G.getDisplayedValueFromObject(reason)}
      >
        {G.getDisplayedValueFromObject(reason)}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={100}
        title={note}
        maxWidth={100}
      >
        {note}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={100}
        maxWidth={100}
        title={G.concatLocationFields(location)}
      >
        {G.concatLocationFields(location)}
      </TextComponent>
      <Flex justifyContent='space-between'>
        <Box
          cursor='pointer'
          onClick={() => handleRemoveUnavailablePeriod(period)}
        >
          {I.trash(actionColor)}
        </Box>
        <Box
          ml='5px'
          cursor='pointer'
          onClick={() => handleUpdateUnavailablePeriod(
            R.assoc('reason', G.getDropdownOptionGuidFromObject(reason), period),
          )}
        >
          {I.pencil(actionColor)}
        </Box>
      </Flex>
    </Flex>
  );
};

export const UnavailablePeriods = withBooleanStatus(false)(({
  booleanStatus,
  unavailablePeriods,
  handleToggleBooleanStatus,
  handleUpdateUnavailablePeriod,
  handleRemoveUnavailablePeriod,
}: Object) => (
  <Box
    p='20px 15px'
    color={textColor}
    borderBottom='1px solid'
    borderColor={bgGreyColor}
  >
    <Box
      fontSize={12}
      cursor='pointer'
      fontWeight='bold'
      textTransform='uppercase'
      onClick={handleToggleBooleanStatus}
    >
      {G.getWindowLocale('titles:unavailable-periods', 'Unavailable Periods')}
      <Span ml='5px'>
        {
          G.ifElse(
            booleanStatus,
            I.arrowUpSimple,
            I.arrowDownSimple,
          )(actionColor)
        }
      </Span>
    </Box>
    {
      booleanStatus &&
      <Box mt={10} border={`1px solid ${bgGreyColor}`}>
        <Flex
          p='10px 5px'
          fontSize={12}
          bg={bgGreyColor}
          fontWeight='bold'
          color={textColor}
        >
          <Box mr={10} width={70}>
            {G.getWindowLocale('titles:start-date', 'Start Date')}
          </Box>
          <Box mr={10} width={70}>
            {G.getWindowLocale('titles:end-date', 'End Date')}
          </Box>
          <Box mr={10} width={70}>
            {G.getWindowLocale('titles:reason', 'Reason')}
          </Box>
          <Box mr={10} width={100}>
            {G.getWindowLocale('titles:note', 'Note')}
          </Box>
          <Box mr={10} width={100}>
            {G.getWindowLocale('titles:location', 'Location')}
          </Box>
        </Flex>
        <Box fontSize={11}>
          {
            unavailablePeriods.map((period: Object, index: number) => (
              <UnavailablePeriod
                key={index}
                period={period}
                handleUpdateUnavailablePeriod={handleUpdateUnavailablePeriod}
                handleRemoveUnavailablePeriod={handleRemoveUnavailablePeriod}
              />
            ))
          }
        </Box>
      </Box>
    }
  </Box>
));

const PendingActivity = (props: Object) => {
  const { activity, handleRemovePendingActivity, handleUpdatePendingActivity } = props;

  const { note, status, endDate, location, startDate } = activity;

  return (
    <Flex p='10px 5px'>
      <TextComponent
        {...textStyles}
        mr={10}
        width={60}
        maxWidth={60}
        title={G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(false))}
      >
        {G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(false))}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={60}
        maxWidth={60}
        title={G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(false))}
      >
        {G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(false))}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={70}
        maxWidth={70}
        title={G.getDisplayedValueFromObject(status)}
      >
        {G.getDisplayedValueFromObject(status)}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={100}
        title={note}
        maxWidth={100}
      >
        {note}
      </TextComponent>
      <TextComponent
        {...textStyles}
        mr={10}
        width={100}
        maxWidth={100}
        title={G.concatLocationFields(location)}
      >
        {G.concatLocationFields(location)}
      </TextComponent>
      <Flex justifyContent='space-between'>
        <Box
          cursor='pointer'
          onClick={() => handleRemovePendingActivity(activity)}
        >
          {I.trash(actionColor)}
        </Box>
        <Box
          ml='5px'
          cursor='pointer'
          onClick={() => handleUpdatePendingActivity(
            R.assoc('status', G.getDropdownOptionGuidFromObject(status), activity),
          )}
        >
          {I.pencil(actionColor)}
        </Box>
      </Flex>
    </Flex>
  );
};

export const PendingActivities = withBooleanStatus(false)(({
  booleanStatus,
  pendingActivities,
  handleToggleBooleanStatus,
  handleUpdatePendingActivity,
  handleRemovePendingActivity,
}: Object) => (
  <Box
    p='20px 15px'
    color={textColor}
    borderBottom='1px solid'
    borderColor={bgGreyColor}
  >
    <Box
      fontSize={12}
      cursor='pointer'
      fontWeight='bold'
      textTransform='uppercase'
      onClick={handleToggleBooleanStatus}
    >
      {G.getWindowLocale('titles:pending-activities', 'Pending Activities')}
      <Span ml='5px'>
        {
          G.ifElse(
            booleanStatus,
            I.arrowUpSimple,
            I.arrowDownSimple,
          )(actionColor)
        }
      </Span>
    </Box>
    {
      booleanStatus &&
      <Box mt={10} border={`1px solid ${bgGreyColor}`}>
        <Flex
          p='10px 5px'
          fontSize={12}
          bg={bgGreyColor}
          fontWeight='bold'
          color={textColor}
        >
          <Box mr={10} width={70}>
            {G.getWindowLocale('titles:start-date', 'Start Date')}
          </Box>
          <Box mr={10} width={70}>
            {G.getWindowLocale('titles:end-date', 'End Date')}
          </Box>
          <Box mr={10} width={70}>
            {G.getWindowLocale('titles:status', 'Status')}
          </Box>
          <Box mr={10} width={100}>
            {G.getWindowLocale('titles:note', 'Note')}
          </Box>
          <Box mr={10} width={100}>
            {G.getWindowLocale('titles:location', 'Location')}
          </Box>
        </Flex>
        <Box fontSize={11}>
          {
            pendingActivities.map((activity: Object, index: number) => (
              <PendingActivity
                key={index}
                activity={activity}
                handleUpdatePendingActivity={handleUpdatePendingActivity}
                handleRemovePendingActivity={handleRemovePendingActivity}
              />
            ))
          }
        </Box>
      </Box>
    }
  </Box>
));
