import React from 'react';
import * as R from 'ramda';
import { Pie, Cell, Legend, Tooltip, PieChart } from 'recharts';
// helpers/constants
import * as G from '../../../helpers';
// features dashboards
import { CHART_COLORS } from '../constants';
import { getLabelFormatter } from '../helpers';
//////////////////////////////////////////////////

const { PIE_BAR_COLORS } = CHART_COLORS;

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  percent,
  midAngle,
  innerRadius,
  outerRadius,
}: any) => {
  const radius = R.add(innerRadius, R.multiply(R.subtract(outerRadius, innerRadius), 0.5));
  const x = R.add(cx, R.multiply(radius, Math.cos(R.multiply(-midAngle, RADIAN))));
  const y = R.add(cy, R.multiply(radius, Math.sin(R.multiply(-midAngle, RADIAN))));

  return (
    <text
      x={x}
      y={y}
      fill={CHART_COLORS.white}
      dominantBaseline='central'
      textAnchor={R.gt(x, cx) ? 'start' : 'end'}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const pieChartProps = {
  width: 500,
  height: 340,
  margin: {
    top: 15,
    right: 0,
    left: 0,
    bottom: 15,
  },
};

const PieChartComponent = (props: Object) => {
  const { data, showPercent, pieProps = {}, labelFormatter, tooltipFormatter } = props;

  const { endAngle, startAngle, innerRadius, paddingAngle } = pieProps;

  if (G.isTrue(showPercent)) {
    return (
      <PieChart {...pieChartProps}>
        <Pie
          cx={250}
          cy={120}
          data={data}
          dataKey='value'
          outerRadius={100}
          labelLine={false}
          endAngle={endAngle}
          startAngle={startAngle}
          innerRadius={innerRadius}
          fill={CHART_COLORS.blue3}
          paddingAngle={paddingAngle}
          label={renderCustomizedLabel}
        >
          {data.map((entry: Object, index: number) => (
            <Cell key={`cell-${index}`} fill={R.reverse(PIE_BAR_COLORS)[index % PIE_BAR_COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={tooltipFormatter} />
        <Legend />
      </PieChart>
    );
  }

  return (
    <PieChart {...pieChartProps}>
      <Pie
        cx={250}
        cy={120}
        data={data}
        dataKey='value'
        outerRadius={100}
        endAngle={endAngle}
        startAngle={startAngle}
        fill={CHART_COLORS.blue3}
        innerRadius={innerRadius}
        paddingAngle={paddingAngle}
        label={getLabelFormatter(labelFormatter)}
      >
        {data.map((entry: Object, index: number) => (
          <Cell key={`cell-${index}`} fill={PIE_BAR_COLORS[index % PIE_BAR_COLORS.length]} />
        ))}
      </Pie>
      <Tooltip formatter={tooltipFormatter} />
      <Legend />
    </PieChart>
  );
};

export default PieChartComponent;
