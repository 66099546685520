import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { Fragment, useEffect } from 'react';
// components
import { PageTitle } from '../../../components/page-title';
import { FormSectionHeader } from '../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, MainActionButton } from '../../../ui';
// feature fleet-profile
import Assignment from '../components/assignment';
import FleetProfilePhoto from '../components/profile-photo';
import CreateFleet from '../vendor/components/create-fleet';
//////////////////////////////////////////////////

export const PageHeader = (props: Object) => {
  const {
    type,
    title,
    active,
    showStatus,
    initialValues,
    hasAssignment = true,
  } = props;

  return (
    <Flex>
      <PageTitle
        mr={10}
        title={title}
        status={active}
        display='inline-flex'
        showStatus={showStatus}
      />
      {
        hasAssignment &&
        <Assignment
          {...R.pick(
            [
              'type',
              'shared',
              'openModal',
              'openLoader',
              'closeModal',
              'teamDriver',
              'closeLoader',
              'isPrimaryDriver',
              'driverAssignmentGuid',
            ],
            props,
          )}
          branchGuid={G.getBranchGuidFromObject(initialValues)}
          primaryObjectGuid={G.getGuidFromObject(initialValues)}
          payable={R.pathOr(false, [GC.FIELD_PAYABLE], initialValues)}
          fleetVendorGuid={G.getPropFromObject(GC.FIELD_FLEET_VENDOR_GUID, initialValues)}
          assignedToDivisionGuid={G.getPropFromObject(GC.FIELD_ASSIGNED_TO_DIVISION_GUID, initialValues)}
        />
      }
      {R.equals(type, 'vendor') && <CreateFleet />}
    </Flex>
  );
};

const GeneralDetails = (props: Object) => {
  const {
    type,
    mb = 20,
    hasPhoto,
    closeModal,
    firstFields,
    fieldsGroups,
    submitAction,
    saveAndClose,
    openedSection,
    initialValues,
    setSaveAndClose,
    showCloseButton,
    shouldCloseModal,
    validationSchema,
    tabsCollapsedView,
    withoutPageHeader,
    hasFormSectionTitles,
    optionsForSelect = {},
    shouldNotDisableSubmitBtn,
  } = props;

  const onSubmit = (values: Object, { setSubmitting }: Object) =>
    submitAction(G.mapObjectEmptyStringFieldsToNull(values), { setSubmitting, shouldCloseModal });

  const formik = useFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues: R.or(initialValues, {}),
    validationSchema: R.or(validationSchema, {}),
  });

  const { errors, values, submitForm, isSubmitting, handleSubmit, setSubmitting } = formik;

  const handleSaveAndClose = () => {
    if (saveAndClose) submitForm();

    if (R.and(saveAndClose, G.isNotNilAndNotEmpty(errors))) setSaveAndClose(false);
  };

  const handleSetSubmittingAfterSubmitForm = () => {
    if (isSubmitting) setSubmitting(false);
  };

  useEffect(handleSaveAndClose, [saveAndClose]);
  useEffect(handleSetSubmittingAfterSubmitForm, [initialValues]);

  return (
    <Fragment>
      {R.not(withoutPageHeader) && <PageHeader {...props} />}
      {
        tabsCollapsedView &&
        <Box mb={mb} bg='white' p={G.ifElse(hasFormSectionTitles, '20px 0', 20)}>
          <form onSubmit={handleSubmit}>
            {
              hasPhoto &&
              <Flex mb={30} mx={G.ifElse(hasFormSectionTitles, 20)}>
                <FleetProfilePhoto
                  type={type}
                  guid={G.getGuidFromObject(initialValues)}
                  userGuid={G.getPropFromObject('userGuid', initialValues)}
                />
                <Fieldset2
                  {...G.getFormikPropsToFieldset(formik)}
                  {...optionsForSelect}
                  fields={R.pathOr([], ['fieldSettings'], firstFields)}
                  fieldsWrapperStyles={{ ml: 25, px: '0px', ...R.pathOr({}, ['fieldsWrapperStyles'], firstFields) }}
                />
              </Flex>
            }
            {
              fieldsGroups.map(({
                fieldSettings,
                formSectionTitle,
                fieldsWrapperStyles = {},
              }: Object, index: number) => {
                const fields = (
                  <Fieldset2
                    {...G.getFormikPropsToFieldset(formik)}
                    {...optionsForSelect}
                    key={index}
                    fields={fieldSettings}
                    fieldsWrapperStyles={{ px: '0px', ...fieldsWrapperStyles }}
                  />
                );

                if (R.isNil(formSectionTitle)) return fields;

                return (
                  <FormSectionHeader
                    key={index}
                    title={formSectionTitle}
                    expanded={G.ifElse(R.isNotNil(openedSection), R.equals(index, openedSection), true)}
                  >
                    {fields}
                  </FormSectionHeader>
                );
              })
            }
            <Flex mt={20} justifyContent='flex-end' mr={G.ifElse(hasFormSectionTitles, 20)}>
              {
                showCloseButton &&
                <MainActionButton
                  mr={20}
                  width={120}
                  type='button'
                  color='greyMatterhorn'
                  borderColor='greyMatterhorn'
                  onClick={() => closeModal()}
                >
                  {G.getWindowLocale('actions:close', 'Close')}
                </MainActionButton>
              }
              <MainActionButton
                width={120}
                disabled={G.ifElse(
                  shouldNotDisableSubmitBtn,
                  isSubmitting,
                  R.or(isSubmitting, R.equals(values, initialValues)),
                )}
              >
                {G.getWindowLocale('actions:update', 'Update')}
              </MainActionButton>
            </Flex>
          </form>
        </Box>
      }
    </Fragment>
  );
};

export default GeneralDetails;
