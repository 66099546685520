import { $all, $set } from 'plow-js';
import { createReducer } from 'redux-act';
// feature auth
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  loginData: null,
  loggedIn: false,
  sessionData: null,
  isDispatchedActions: false,
};

const receivedSessionValidationFail = (state: Object) => (
  $all(
    $set('loggedIn', false),
    $set('loginData', null),
    $set('sessionData', null),
    state,
  )
);

const receivedSessionValidationSuccess = (state: Object, data: Object) => (
  $all(
    $set('loggedIn', true),
    $set('sessionData', data),
    state,
  )
);

const receivedLogInSuccess = (state: Object, data: Object) => (
  $all(
    $set('loggedIn', true),
    $set('loginData', data),
    state,
  )
);

const receivedNalLogInSuccess = (state: Object, data: Object) => (
  $all(
    $set('loggedIn', true),
    $set('loginData', data),
    state,
  )
);

const receivedBoshLogInSuccess = (state: Object, data: Object) => (
  $all(
    $set('loggedIn', true),
    $set('loginData', data),
    state,
  )
);

const receivedLogOutSuccess = (state: Object) => (
  $all(
    $set('loggedIn', false),
    $set('loginData', null),
    $set('sessionData', null),
    $set('isDispatchedActions', false),
    state,
  )
);

const setDispatchAction = (state: Object, value: boolean = false) => (
  $set('isDispatchedActions', value, state)
);

export default createReducer({
  [A.setDispatchAction]: setDispatchAction,
  [A.receivedLogInSuccess]: receivedLogInSuccess,
  [A.receivedLogOutSuccess]: receivedLogOutSuccess,
  [A.receivedNalLogInSuccess]: receivedNalLogInSuccess,
  [A.receivedBoshLogInSuccess]: receivedBoshLogInSuccess,
  [A.receivedSessionValidationFail]: receivedSessionValidationFail,
  [A.receivedSessionValidationSuccess]: receivedSessionValidationSuccess,
}, initialState);
