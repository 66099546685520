import { connect } from 'react-redux';
import React, { Fragment } from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
// helpers/constants
import * as G from '../../../helpers';
// ui
import { ActionButton } from '../../../ui';
// feature fleet-profile
import { setVinLookup } from '../actions';
//////////////////////////////////////////////////

const VinLookup = ({ setVinLookup }: Object) => (
  <Fragment>
    <TextComponent
      fontSize={11}
      display='block'
      withEllipsis={true}
      color='greyMatterhorn'
      maxWidth='calc(100% - 70px)'
    >
      {G.getWindowLocale('titles:serial-number-vin', 'Serial Number(VIN)')}
    </TextComponent>
    <ActionButton
      height={16}
      fontSize={11}
      type='submit'
      minWidth='unset'
      borderRadius='3px'
      onClick={() => setVinLookup(true)}
    >
      {G.getWindowLocale('actions:vin-lookup', 'VIN Lookup')}
    </ActionButton>
  </Fragment>
);

export default connect(null, { setVinLookup })(VinLookup);
