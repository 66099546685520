import * as R from 'ramda';
import {
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../hocs';
//////////////////////////////////////////////////

export const withComplexInitialValues = compose(
  withState('stateActiveTab', 'setStateActiveTab', 'details'),
  withState('oldInitialValues', 'setOldInitialValues', ({ initialValues }: Object) => initialValues),
  withState('complexInitialValues', 'setComplexInitialValues', ({ initialValues }: Object) => initialValues),
  withHandlers({
    handleSetActiveTab: (props: Object) => (tabName: string) => {
      const { setActiveTab, setStateActiveTab, setComplexInitialValues } = props;

      setComplexInitialValues(props.formValues);

      setStateActiveTab(tabName);

      if (G.isFunction(setActiveTab)) {
        setActiveTab(tabName);
      }
    },
    activeTabPropCallback: (props: Object) => () => {
      const { activeTab, stateActiveTab, setStateActiveTab } = props;

      if (R.and(
        G.notEquals(activeTab, stateActiveTab),
        G.isNotNilAndNotEmpty(activeTab),
      )) {
        setStateActiveTab(activeTab);
      }
    },
    initialValuesPropCallback: (props: Object) => () => {
      const { initialValues, setOldInitialValues, complexInitialValues, setComplexInitialValues } = props;

      if (G.isNilOrEmpty(initialValues)) return;

      const initialValuesToUpdate = R.compose(
        R.filter(G.isNotEmpty),
        R.mapObjIndexed((value: any, key: string) => (
          G.ifElse(R.equals(value, R.path(['oldInitialValues', key], props)), '', value)
        )),
      )(initialValues);

      if (R.isEmpty(initialValuesToUpdate)) return;

      const currentValues = R.filter((value: any) => G.isNotNilAndNotEmpty(value), R.or(complexInitialValues, {}));

      setComplexInitialValues(R.mergeRight(currentValues, initialValuesToUpdate));
      setOldInitialValues(initialValues);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'initialValues',
    callbackName: 'initialValuesPropCallback',
  }),
  withComponentDidUpdatePropCallback({
    propName: 'activeTab',
    callbackName: 'activeTabPropCallback',
  }),
);
