import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// forms
import { Form, renderFormGroup } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import { setOptions } from '../../helpers';
// feature fleet/driver
import { errorClass, formGroupPosition } from '../ui';
import { DRIVER_CERTIFICATION_FORM } from '../constants';
import { addCertificationFields } from '../settings/fields-settings';
import { makeSelectDriverCertificationFormValues } from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  reduxForm({
    form: DRIVER_CERTIFICATION_FORM,
  }),
  withState('file', 'setFile', null),
  withHandlers({
    handleSelectFile: ({ change, setFile }: Object) => (event: Object) => {
      const file = R.pathOr(null, ['currentTarget', 'files', 0], event);
      const fileName = R.pathOr(null, [GC.FIELD_NAME], file);

      setFile(file);
      change(GC.FIELD_FILE_NAME, fileName);
    },
    handleFormSubmit: (props: Object) => (values: Object) => {
      const { file, closeModal, driverGuid, submitAction } = props;

      const reqBody = R.mergeRight(values, { file, driverGuid });

      submitAction(reqBody);
      closeModal();
    },
  }),
  pure,
);

const CertificationFormComponent = (props: Object) => (
  <div>
    <Form
      id={DRIVER_CERTIFICATION_FORM}
      onSubmit={props.handleSubmit(props.handleFormSubmit)}
    >
      {
        addCertificationFields.fields.map((field: Object, index: number) => (
          <Field
            key={index}
            margin='5px 0'
            direction='row'
            info={field.info}
            jc='space-between'
            labelFontSize={16}
            errorFontSize={14}
            align={field.align}
            labelMargin='5px 0'
            labelPosition='left'
            errorClass={errorClass}
            endLabel={field.endLabel}
            validate={field.validate}
            withoutCalendarLabel={true}
            name={field.nameForAttribute}
            groupPadding='0 10px 10px 10px'
            isClearable={field.isClearable}
            additionClass={formGroupPosition}
            type={G.setFieldType(field.type)}
            width={R.or(field.width, '230px')}
            minDate={G.setMinDate(props, field)}
            maxDate={G.setMaxDate(props, field)}
            label={G.getWindowLocale(field.name)}
            component={renderFormGroup(field.type)}
            selectFileAction={props.handleSelectFile}
            cleanUpload={() => props.handleSelectFile()}
            options={setOptions(props.driverConfigs, field)}
            placeholder={G.getWindowLocale(field.placeholder, ' ')} />
        ))
      }
    </Form>
  </div>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  formValues: makeSelectDriverCertificationFormValues(state),
}));

export default connect(mapStateToProps)(enhance(CertificationFormComponent));

