import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature carrier-portal
import { CARRIER_DETAIL_PAGE, CARRIER_DETAIL_PAGE_DOC_FORM } from './constants';
//////////////////////////////////////////////////

const store = (state: Object) => state.carrierPortal;
const selectFormStore = (state: Object) => state.form;

const makeSelectPortal = () => createSelector(
  store,
  ({ portal }: Object) => portal,
);

const makeSelectDeclineReason = () => createSelector(
  store,
  ({ declineReasons }: Object) => declineReasons,
);

const makeSelectCarrierName = () => createSelector(
  store,
  ({ carrierResponseDetailPage }: Object) => R.path(['carrierName'], carrierResponseDetailPage),
);

const makeSelectCarrierChat = () => createSelector(
  store,
  ({ carrierResponseDetailPage }: Object) => R.pathOr([], ['chatData'], carrierResponseDetailPage),
);

const makeSelectCarrierPortalInfo = () => createSelector(
  store,
  ({ carrierResponseDetailPage }: Object) => R.or(carrierResponseDetailPage, []),
);

const makeSelectMobView = () => createSelector(
  store,
  ({ carrierResponseDetailPage }: Object) => R.path(['mobView'], carrierResponseDetailPage),
);

const makeSelectCarrierDocsOutsideApp = () => createSelector(
  store,
  ({ carrierResponseDetailPage }: Object) => R.path(['documents'], carrierResponseDetailPage),
);

const makeSelectMessage = () => createSelector(
  store,
  ({ portal }: Object) => R.path(['messageStatus'], portal),
);

const makeSelectStatusReason = () => createSelector(
  store,
  ({ portal }: Object) => R.path(['status'], portal),
);

const makeSelectPreviousPathName = () => createSelector(
  store,
  ({ portal }: Object) => R.path(['previousPathName'], portal),
);

const makeSelectStatusReasonCodes = () => createSelector(
  store,
  ({ carrierResponseDetailPage }: Object) => R.path(['statusReasonCodes'], carrierResponseDetailPage),
);


const makeSelectCarrierDetailPageForm = () => createSelector(
  selectFormStore,
  (formState: Object) => formState[CARRIER_DETAIL_PAGE],
);

const makeSelectCarrierDetailPageFormDocForm = () => createSelector(
  selectFormStore,
  (formState: Object) => formState[CARRIER_DETAIL_PAGE_DOC_FORM],
);

const makeSelectTelMappedEventsForMap = () => createSelector(
  store,
  (carrierPortal: Object) => {
    const stops = R.map((stop: Object) => {
      const lat = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE], stop);
      const lng = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE], stop);
      if (R.or(R.isNil(lat), R.isNil(lng))) return null;
      return {
        ...stop,
        latLng: { lat, lng },
        title: `${G.toTitleCase(stop.eventType)} ${G.ifElse(
          G.isNotNilAndNotEmpty(stop.cloEventIndex),
          stop[GC.FIELD_CLO_EVENT_INDEX],
          stop[GC.FIELD_TEL_EVENT_INDEX],
        )}`,
      };
    }, R.pathOr([], ['carrierResponseDetailPage', GC.FIELD_LOAD_STOPS], carrierPortal));

    return R.compose(
      R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
      R.values(),
      R.filter((event: Object) => G.isNotNil(event)),
    )(stops);
  },
);

const makeSelectAcceptedDispatch = () => createSelector(
  store,
  ({ acceptedDispatch }: Object) => acceptedDispatch,
);

const makeSelectDeclinedDispatch = () => createSelector(
  store,
  ({ declinedDispatch }: Object) => declinedDispatch,
);

const makeSelectCarrierToken = () => createSelector(
  store,
  R.path(['portal', 'token']),
);

const makeSelectEvents = () => createSelector(
  store,
  R.path(['carrierResponseDetailPage', 'events']),
);

const makeSelectBranchGuid = () => createSelector(
  store,
  R.path(['carrierResponseDetailPage', GC.FIELD_BRANCH_GUID]),
);

const makeSelectPortalType = () => createSelector(
  store,
  ({ portalType }: Object) => portalType,
);

const makeSelectCloRefTypes = () => createSelector(
  store,
  ({ cloRefTypes }: Object) => cloRefTypes,
);

export {
  makeSelectPortal,
  makeSelectEvents,
  makeSelectMessage,
  makeSelectMobView,
  makeSelectBranchGuid,
  makeSelectPortalType,
  makeSelectCarrierChat,
  makeSelectCarrierName,
  makeSelectCloRefTypes,
  makeSelectCarrierToken,
  makeSelectStatusReason,
  makeSelectDeclineReason,
  makeSelectAcceptedDispatch,
  makeSelectDeclinedDispatch,
  makeSelectPreviousPathName,
  makeSelectStatusReasonCodes,
  makeSelectCarrierPortalInfo,
  makeSelectCarrierDocsOutsideApp,
  makeSelectTelMappedEventsForMap,
  makeSelectCarrierDetailPageForm,
  makeSelectCarrierDetailPageFormDocForm,
};
