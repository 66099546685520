import React from 'react';
import { compose } from 'react-recompose';
// hocs
import { withFixedPopover } from '../../../../hocs';
// ui
import { Box } from '../../../../ui';
// feature route
import TableGroup from '../../components/table-group';
import { withTableGroupProps } from '../hocs/with-documents-tab-table-group-props';
import { withAddTableEntity } from '../hocs/with-documents-tab-table-group-actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withTableGroupProps,
  withAddTableEntity,
);

const DocumentsTableGroupWrapper = (props: Object) => {
  const {
    tabs,
    tableData,
    loadConfigs,
    expandedList,
    activeListName,
    handleClickEditIcon,
    handleAddTableEntity,
    handleChangeActiveList,
    handleSelectTableEntity,
    handleToggleExpandedList } = props;

  return (
    <Box mt={25}>
      <TableGroup
        tabs={tabs}
        tableData={tableData}
        loadConfigs={loadConfigs}
        expandedList={expandedList}
        activeListName={activeListName}
        handleClickEditIcon={handleClickEditIcon}
        handleAddTableEntity={handleAddTableEntity}
        handleChangeActiveList={handleChangeActiveList}
        handleSelectTableEntity={handleSelectTableEntity}
        handleToggleExpandedList={handleToggleExpandedList} />
    </Box>
  );
};

export default enhance(DocumentsTableGroupWrapper);
