import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import * as H from '../../helpers';
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import { getDefaultDropdownConfigFormComponent } from '../../components/dropdown-config-modal';
import {
  makeSelectFinancial,
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectExpenseTypeList,
  makeSelectGLCodeMappingList,
  makeSelectConfigInitialValues,
  makeSelectGLCodeMappingFilter,
  makeSelectQBAccountMappingList,
  makeSelectConfigInheritedValues,
  makeSelectDriverPayrollStatuses,
  makeSelectInvoiceStatusRuleList,
  makeSelectConfigGuidsToItemsMap,
  makeSelectCustomerIdMappingList,
  makeSelectQBAccountMappingFilter,
  makeSelectCarrierInvoiceStatuses,
  makeSelectSageAccountMappingList,
  makeSelectInvoiceStatusRuleFilter,
  makeSelectCustomerInvoiceStatuses,
  makeSelectDepartmentIdMappingList,
  makeSelectSageAccountMappingFilter,
  makeSelectInvoiceStatusPriorityList,
  makeSelectFleetServiceInvoiceStatuses,
} from '../../selectors';
import {
  updateConfigsRequest,
  setGLCodeMappingFilter,
  removeExpenseTypeRequest,
  setQBAccountMappingFilter,
  deleteInvoiceStatusRequest,
  removeGLCodeMappingRequest,
  setInvoiceStatusRuleFilter,
  setSageAccountMappingFilter,
  removeQBAccountMappingRequest,
  returnInheritedStatusesRequest,
  removeInvoiceStatusRuleRequest,
  removeCustomerIdMappingRequest,
  removeSageAccountMappingRequest,
  createUpdateInvoiceStatusRequest,
  removeDepartmentIdMappingRequest,
  createOrUpdateExpenseTypeRequest,
  createOrUpdateCLCodeMappingRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateQBAccountMappingRequest,
  createOrUpdateInvoiceStatusRuleRequest,
  createOrUpdateCustomerIdMappingRequest,
  createOrUpdateSageAccountMappingRequest,
  createOrUpdateDepartmentIdMappingRequest,
} from '../../actions';
// invoice
import GLCodeMappingForm from './components/gl-code-mapping';
import ExpenseTypeForm from './components/expense-type-form';
import InvoiceStatusForm from './components/invoice-status-form';
import InvoiceStatusRuleForm from './components/invoice-status-rule';
import CustomerIdMappingForm from './components/customer-id-mapping-form';
import SageAccountMappingForm from './components/sage-account-mapping-form';
import DepartmentIdMappingForm from './components/department-id-mapping-form';
import QBDistributionLineAccountMappingForm from './components/qb-distribution-line-account-mapping';
//////////////////////////////////////////////////

const invoiceStatusConfigNames = [
  GC.INVOICE_DP_STATUS,
  GC.INVOICE_CI_STATUS,
  GC.INVOICE_CARRIER_STATUS,
  GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS,
];

const enhance = compose(
  defaultPageConfigEnhancer(GC.INVOICE_CONFIG_GROUP),
  itemPageConfigEnhancer,
  withHandlers({
    handleClickReturnInherited: (props: Object) => (configName: string) => {
      const { handleClickReturnInherited, returnInheritedStatusesRequest } = props;

      if (R.includes(configName, invoiceStatusConfigNames)) {
        return returnInheritedStatusesRequest(configName);
      }

      handleClickReturnInherited(configName);
    },
    handleRemoveEntity: ({ deleteInvoiceStatusRequest }: Object) => (entityGuid: string, configName: string) => (
      deleteInvoiceStatusRequest({ entityGuid, configName })
    ),
    handleEditEntity: (props: Object) => (entity: string, popupTitle: string, configName: string) => {
      const { openModal, closeModal, createUpdateInvoiceStatusRequest } = props;

      const title = R.or(popupTitle, 'titles:edit-item');

      const modalContent = (
        <InvoiceStatusForm
          shouldUpdate={true}
          initialValues={entity}
          configName={configName}
          closeModal={closeModal}
          createUpdateInvoiceStatusRequest={createUpdateInvoiceStatusRequest}
        />
      );

      const modal = G.getDefaultModalOptions(modalContent, G.getWindowLocale(title));

      openModal(modal);
    },
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const { openModal, closeModal } = props;

      if (R.equals(configName, GC.CUSTOM_QUICK_BOOKS_ACCOUNT_MAPPING_CONFIG)) {
        const { qbAccountMappingList, createOrUpdateQBAccountMappingRequest } = props;

        const component = (
          <QBDistributionLineAccountMappingForm
            closeModal={closeModal}
            qbAccountMappingList={qbAccountMappingList}
            submitAction={createOrUpdateQBAccountMappingRequest}
            accessorialList={R.pathOr([], ['configOptions', 'accessorialList'], props)}
            qbAccountList={R.pathOr([], ['dropdowns', GC.INVOICE_QB_QB_ACCOUNT, 'options'], props)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 440,
            height: 'auto',
            title: G.getAddTitle(['titles:quick-books-account-mapping', 'QuickBooks Account Mapping']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_SAGE_ACCOUNT_MAPPING_CONFIG)) {
        const { sageAccountMappingList, createOrUpdateSageAccountMappingRequest } = props;

        const component = (
          <SageAccountMappingForm
            closeModal={closeModal}
            accountMappingList={sageAccountMappingList}
            submitAction={createOrUpdateSageAccountMappingRequest}
            initialValues={{ [GC.FIELD_SCOPE]: GC.INVOICE_SCOPE_TYPE_CUSTOMER }}
            accessorialList={R.pathOr([], ['configOptions', 'accessorialList'], props)}
            accountList={R.pathOr([], ['dropdowns', GC.INVOICE_SAGE_ACCOUNT, 'options'], props)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 440,
            height: 'auto',
            title: G.getAddTitle(['titles:account-mapping', 'Account Mapping']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_CUSTOMER_ID_MAPPING_CONFIG)) {
        const { customerIdMappingList, createOrUpdateCustomerIdMappingRequest } = props;

        const component = (
          <CustomerIdMappingForm
            closeModal={closeModal}
            customerIdMappingList={customerIdMappingList}
            submitAction={createOrUpdateCustomerIdMappingRequest}
            customerBranchList={R.pathOr([], ['configOptions', 'customerBranchList'], props)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 440,
            height: 'auto',
            title: G.getAddTitle(['titles:customer-id-mapping', 'Customer ID Mapping']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_DEPARTMENT_ID_MAPPING_CONFIG)) {
        const { departmentIdMappingList, createOrUpdateDepartmentIdMappingRequest } = props;

        const component = (
          <DepartmentIdMappingForm
            closeModal={closeModal}
            departmentIdMappingList={departmentIdMappingList}
            submitAction={createOrUpdateDepartmentIdMappingRequest}
            transportationModes={R.pathOr([], ['configOptions', 'transportationModes'], props)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 440,
            height: 'auto',
            title: G.getAddTitle(['titles:department-id-mapping', 'Department ID Mapping']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_GL_CODE_MAPPING_CONFIG)) {
        const { glCodeMappingList, createOrUpdateCLCodeMappingRequest } = props;

        const component = (
          <GLCodeMappingForm
            closeModal={closeModal}
            glCodeMappingList={glCodeMappingList}
            submitAction={createOrUpdateCLCodeMappingRequest}
            orderTypeOptions={R.pathOr([], ['configOptions', 'orderType'], props)}
            accessorialList={R.pathOr([], ['configOptions', 'accessorialList'], props)}
            glCodeList={R.pathOr([], ['dropdowns', GC.INVOICE_GL_CODE, 'options'], props)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 440,
            height: 'auto',
            title: G.getAddTitle(['titles:gl-code-mapping', 'Add GL Code Mapping']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_INVOICE_STATUS_RULE_CONFIG)) {
        const {
          configOptions,
          carrierInvoiceStatuses,
          invoiceStatusRulePriorityList,
          createOrUpdateInvoiceStatusRuleRequest,
        } = props;

        const initialValues = {
          [GC.FIELD_PRIORITY]: G.getValueFromObject(R.last(invoiceStatusRulePriorityList)),
        };

        const component = (
          <InvoiceStatusRuleForm
            closeModal={closeModal}
            initialValues={initialValues}
            submitAction={createOrUpdateInvoiceStatusRuleRequest}
            invoiceStatusRulePriorityList={invoiceStatusRulePriorityList}
            configOptions={R.assoc('carrierInvoiceStatuses', carrierInvoiceStatuses, configOptions)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            height: 'auto',
            title: G.getAddTitle(['titles:default-invoice-status-rule', 'Invoice Status Rule']),
          },
        };

        return openModal(modal);
      }

      const title = R.or(popupTitle, 'titles:add-item');
      const modalContent = getDefaultDropdownConfigFormComponent(configName, props);

      if (R.includes(configName, invoiceStatusConfigNames)) {
        const { createUpdateInvoiceStatusRequest } = props;

        const component = (
          <InvoiceStatusForm
            configName={configName}
            closeModal={closeModal}
            createUpdateInvoiceStatusRequest={createUpdateInvoiceStatusRequest}
          />
        );

        const modal = G.getDefaultModalOptions(component, G.getWindowLocale(title));

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_EXPENSE_TYPES_CONFIG)) {
        const { createOrUpdateExpenseTypeRequest } = props;

        const component = (
          <ExpenseTypeForm submitAction={createOrUpdateExpenseTypeRequest} />
        );

        const modal = G.getDefaultModalOptions(component, G.getWindowLocale(title));

        return openModal(modal);
      }

      const modal = H.getModalOptions(modalContent, title);

      openModal(modal);
    },
    handleEditQBAccountMapping: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, qbAccountMappingList, createOrUpdateQBAccountMappingRequest } = props;

      const component = (
        <QBDistributionLineAccountMappingForm
          closeModal={closeModal}
          initialValues={initialValues}
          qbAccountMappingList={qbAccountMappingList}
          submitAction={createOrUpdateQBAccountMappingRequest}
          accessorialList={R.pathOr([], ['configOptions', 'accessorialList'], props)}
          qbAccountList={R.pathOr([], ['dropdowns', GC.INVOICE_QB_QB_ACCOUNT, 'options'], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 440,
          height: 'auto',
          title: G.getEditTitle(['titles:quick-books-account-mapping', 'QuickBooks Account Mapping']),
        },
      };

      return openModal(modal);
    },
    handleEditSageAccountMapping: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, sageAccountMappingList, createOrUpdateSageAccountMappingRequest } = props;

      const component = (
        <SageAccountMappingForm
          closeModal={closeModal}
          initialValues={initialValues}
          accountMappingList={sageAccountMappingList}
          submitAction={createOrUpdateSageAccountMappingRequest}
          accessorialList={R.pathOr([], ['configOptions', 'accessorialList'], props)}
          accountList={R.pathOr([], ['dropdowns', GC.INVOICE_SAGE_ACCOUNT, 'options'], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 440,
          height: 'auto',
          title: G.getEditTitle(['titles:quick-books-account-mapping', 'QuickBooks Account Mapping']),
        },
      };

      openModal(modal);
    },
    handleEditCustomerIdMapping: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, customerIdMappingList, createOrUpdateCustomerIdMappingRequest } = props;

      const component = (
        <CustomerIdMappingForm
          closeModal={closeModal}
          initialValues={initialValues}
          customerIdMappingList={customerIdMappingList}
          submitAction={createOrUpdateCustomerIdMappingRequest}
          customerBranchList={R.pathOr([], ['configOptions', 'customerBranchList'], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 440,
          height: 'auto',
          title: G.getAddTitle(['titles:customer-id-mapping', 'Customer ID Mapping']),
        },
      };

      openModal(modal);
    },
    handleEditDepartmentIdMapping: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, departmentIdMappingList, createOrUpdateDepartmentIdMappingRequest } = props;

      const component = (
        <DepartmentIdMappingForm
          closeModal={closeModal}
          initialValues={initialValues}
          departmentIdMappingList={departmentIdMappingList}
          submitAction={createOrUpdateDepartmentIdMappingRequest}
          transportationModes={R.pathOr([], ['configOptions', 'transportationModes'], props)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 440,
          height: 'auto',
          title: G.getAddTitle(['titles:department-id-mapping', 'Department ID Mapping']),
        },
      };

      openModal(modal);
    },
    handleEditGLCodeMapping: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, glCodeMappingList, createOrUpdateCLCodeMappingRequest } = props;

      const component = (
        <GLCodeMappingForm
          closeModal={closeModal}
          initialValues={initialValues}
          glCodeMappingList={glCodeMappingList}
          submitAction={createOrUpdateCLCodeMappingRequest}
          orderTypeOptions={R.pathOr([], ['configOptions', 'orderType'], props)}
          accessorialList={R.pathOr([], ['configOptions', 'accessorialList'], props)}
          glCodeList={R.pathOr([], ['dropdowns', GC.INVOICE_GL_CODE, 'options'], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 440,
          height: 'auto',
          title: G.getEditTitle(['titles:gl-code-mapping', 'GL Code Mapping']),
        },
      };

      openModal(modal);
    },
    handleEditInvoiceStatusRule: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        configOptions,
        carrierInvoiceStatuses,
        invoiceStatusRulePriorityList,
        createOrUpdateInvoiceStatusRuleRequest,
      } = props;

      const component = (
        <InvoiceStatusRuleForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateInvoiceStatusRuleRequest}
          invoiceStatusRulePriorityList={invoiceStatusRulePriorityList}
          configOptions={R.assoc('carrierInvoiceStatuses', carrierInvoiceStatuses, configOptions)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getEditTitle(['titles:invoice-status-rule', 'Invoice Status Rule']),
        },
      };

      openModal(modal);
    },
    handleEditExpenseType: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        createOrUpdateExpenseTypeRequest,
      } = props;

      const component = (
        <ExpenseTypeForm
          isEdit={true}
          initialValues={initialValues}
          submitAction={createOrUpdateExpenseTypeRequest}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getEditTitle(['titles:expense-type', 'Expense Type']));

      openModal(modal);
    },
    handleRemoveExpenseType: ({ removeExpenseTypeRequest }: Object) => (entity: Object) =>
      removeExpenseTypeRequest(entity),
    handleRemoveAccountMapping: ({ removeQBAccountMappingRequest }: Object) => (entity: Object) =>
      removeQBAccountMappingRequest(entity),
    handleRemoveGLCodeMapping: ({ removeGLCodeMappingRequest }: Object) => (guid: string) =>
      removeGLCodeMappingRequest(guid),
    handleRemoveInvoiceStatusRule: ({ removeInvoiceStatusRuleRequest }: Object) => (guid: string) =>
      removeInvoiceStatusRuleRequest(guid),
    handleRemoveSageAccountMapping: ({ removeSageAccountMappingRequest }: Object) => (guid: string) =>
      removeSageAccountMappingRequest(guid),
    handleRemoveCustomerIdMapping: ({ removeCustomerIdMappingRequest }: Object) => (guid: string) =>
      removeCustomerIdMappingRequest(guid),
    handleRemoveDepartmentIdMapping: ({ removeDepartmentIdMappingRequest }: Object) => (guid: string) =>
      removeDepartmentIdMappingRequest(guid),
  }),
  pure,
);

const getConfigOptions = (props: Object) => {
  const { configOptions, carrierInvoiceStatuses } = props;

  const filter = R.filter(R.prop('completedStatus'));

  return R.mergeRight(
    configOptions,
    {
      carrierInvoiceStatuses,
      completedCarrierInvoiceStatuses: filter(R.or(carrierInvoiceStatuses, [])),
      completedDriverPayrollStatuses: filter(R.pathOr([], ['driverPayrollStatuses'], configOptions)),
      completedCustomerInvoiceStatuses: filter(R.pathOr([], ['customerInvoiceStatuses'], configOptions)),
    },
  );
};

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:invoice'
    groupSettings={GC.INVOICE_CONFIG_GROUP}
    configOptions={getConfigOptions(props)}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  financial: makeSelectFinancial()(state),
  expenseTypeList: makeSelectExpenseTypeList()(state),
  glCodeMappingList: makeSelectGLCodeMappingList()(state),
  glCodeMappingFilter: makeSelectGLCodeMappingFilter()(state),
  qbAccountMappingList: makeSelectQBAccountMappingList()(state),
  driverPayrollStatuses: makeSelectDriverPayrollStatuses()(state),
  invoiceStatusRuleList: makeSelectInvoiceStatusRuleList()(state),
  customerIdMappingList: makeSelectCustomerIdMappingList()(state),
  carrierInvoiceStatuses: makeSelectCarrierInvoiceStatuses()(state),
  qbAccountMappingFilter: makeSelectQBAccountMappingFilter()(state),
  sageAccountMappingList: makeSelectSageAccountMappingList()(state),
  customerInvoiceStatuses: makeSelectCustomerInvoiceStatuses()(state),
  invoiceStatusRuleFilter: makeSelectInvoiceStatusRuleFilter()(state),
  departmentIdMappingList: makeSelectDepartmentIdMappingList()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.INVOICE_CONFIG_GROUP),
  sageAccountMappingFilter: makeSelectSageAccountMappingFilter()(state),
  configOptions: makeSelectConfigOptions(state, GC.INVOICE_CONFIG_GROUP),
  invoiceStatusRulePriorityList: makeSelectInvoiceStatusPriorityList()(state),
  fleetServiceInvoiceStatuses: makeSelectFleetServiceInvoiceStatuses()(state),
  initialValues: makeSelectConfigInitialValues(state, GC.INVOICE_CONFIG_GROUP),
  guidsToItemsMap: makeSelectConfigGuidsToItemsMap(state, GC.INVOICE_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.INVOICE_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  setGLCodeMappingFilter,
  removeExpenseTypeRequest,
  setQBAccountMappingFilter,
  deleteInvoiceStatusRequest,
  removeGLCodeMappingRequest,
  setInvoiceStatusRuleFilter,
  setSageAccountMappingFilter,
  removeQBAccountMappingRequest,
  removeInvoiceStatusRuleRequest,
  returnInheritedStatusesRequest,
  removeCustomerIdMappingRequest,
  removeSageAccountMappingRequest,
  createUpdateInvoiceStatusRequest,
  removeDepartmentIdMappingRequest,
  createOrUpdateExpenseTypeRequest,
  createOrUpdateCLCodeMappingRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateQBAccountMappingRequest,
  createOrUpdateInvoiceStatusRuleRequest,
  createOrUpdateCustomerIdMappingRequest,
  createOrUpdateSageAccountMappingRequest,
  createOrUpdateDepartmentIdMappingRequest,
})(enhance(ConfigPageComponent));
