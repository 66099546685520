// helpers/constants
import * as GC from '../../../constants';
import { notificationLevelOptions } from '../../../helpers/options';
// feature configs
import { MultiselectFieldComponent } from '../ui';
//////////////////////////////////////////////////

const generalSection = {
  title: 'titles:general',
  fields: {
    [GC.TRUCK_REQUIRED_DOCUMENT_TYPES]: {
      type: 'list',
      options: 'documentTypes',
      component: MultiselectFieldComponent,
      name: 'titles:required-document-types',
      shouldGetInheritedValueFromConfigOptions: true,
      nameForAttribute: GC.TRUCK_REQUIRED_DOCUMENT_TYPES,
    },
    [GC.TRUCK_REQUIRED_DOCUMENT_WARNING_LEVEL]: {
      type: 'select',
      options: notificationLevelOptions(),
      name: 'titles:required-documents-warning-level',
      nameForAttribute: GC.TRUCK_REQUIRED_DOCUMENT_WARNING_LEVEL,
    },
    [GC.TRUCK_DEACTIVATE_ON_SERVICE_ISSUE]: {
      type: 'switcher',
      name: 'titles:deactivate-on-service-issue',
      nameForAttribute: GC.TRUCK_DEACTIVATE_ON_SERVICE_ISSUE,
    },
    [GC.TRUCK_DEFAULT_OUT_OF_SERVICE_REASON]: {
      type: 'select',
      options: 'outOfServiceReasons',
      name: 'titles:default-out-of-service-reason',
      nameForAttribute: GC.TRUCK_DEFAULT_OUT_OF_SERVICE_REASON,
    },
  },
};

const IFTASection = {
  title: 'titles:ifta',
  fields: {
    [GC.TRUCK_IFTA_EXCLUDE_FUEL_STOPS]: {
      type: 'switcher',
      name: 'titles:exclude-fuel-stops',
      nameForAttribute: GC.TRUCK_IFTA_EXCLUDE_FUEL_STOPS,
    },
  },
};

const truckTypeSection = {
  fields: [],
  configType: 'dropdown',
  title: 'titles:truck-type',
  editPopupTitle: 'titles:edit',
  configName: GC.TRUCK_TRUCK_TYPE,
};

const insuranceTypeSection = {
  fields: [],
  configType: 'dropdown',
  editPopupTitle: 'titles:edit',
  title: 'titles:insurance-type',
  configName: GC.TRUCK_INSURANCE_TYPE,
};

const unavailabilityReasonSection = {
  fields: [],
  configType: 'dropdown',
  editPopupTitle: 'titles:edit',
  title: 'titles:unavailability-reason',
  configName: GC.TRUCK_UNAVAILABILITY_REASON,
};

const documentTypeSection = {
  fields: [],
  configType: 'dropdown',
  editPopupTitle: 'titles:edit',
  title: 'titles:document-type',
  configName: GC.TRUCK_DOCUMENT_TYPE,
};

const outOfServiceReasonSection = {
  fields: [],
  configType: 'dropdown',
  editPopupTitle: 'titles:edit',
  title: 'titles:out-of-service-reason',
  configName: GC.TRUCK_OUT_SERVICE_REASON,
};

const additionalEquipmentSection = {
  fields: [],
  configType: 'dropdown',
  editPopupTitle: 'titles:edit',
  title: 'titles:additional-equipment-type',
  configName: GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE,
};

const ConfigTruckGroup = {
  generalSection,
  IFTASection,
  truckTypeSection,
  insuranceTypeSection,
  unavailabilityReasonSection,
  documentTypeSection,
  outOfServiceReasonSection,
  additionalEquipmentSection,
};

export {
  ConfigTruckGroup,
};
