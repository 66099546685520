import * as R from 'ramda';
import React from 'react';
import moment from 'moment';
import { Field, FormSection } from 'redux-form';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { renderField, renderSelect } from '../../../components/edit-report/report-common';
import { reportConditions, reportBooleanValues } from '../../../components/filter/settings';
// features
import { setFilterInitialValues } from '../../report-format/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Span, Flex, RelativeBox } from '../../../ui';
// feature charts
import { getSelectOptions } from '../helpers';
import {
  groupStyles,
  AdditionFiltersWrapper } from '../ui';
//////////////////////////////////////////////////

const options = ({ filters }: Object, key: number) => ({
  dateZI: 'unset',
  margin: '0 10px',
  zI: R.subtract(R.add(10, R.length(filters)), key),
});

const emptyFilter = {
  [GC.FIELD_TO]: '',
  [GC.FIELD_LAST]: '',
  [GC.FIELD_FROM]: '',
  [GC.FIELD_NEXT]: '',
  [GC.FIELD_OPERATION]: '',
  [GC.FIELD_STRING_VALUE]: '',
  [GC.FIELD_NUMBER_VALUE]: '',
  [GC.FIELD_PROPERTY_NAME]: '',
  [GC.FIELD_BOOLEAN_VALUE]: '',
  [GC.FIELD_CHART_DATA_TYPE]: '',
  [GC.FIELD_CHART_TIME_UNIT]: '',
  [GC.FIELD_REFERENCE_VALUE]: '',
  [GC.FIELD_CHART_DATE_RELATIVE]: '',
};

const renderFilters = (props: Object) => {
  if (G.isNilOrEmpty(R.path([GC.FIELD_FILTERS], props))) {
    return (
      <Flex
        height='100px'
        fontSize='24px'
        justifyContent='center'
        color={G.getTheme('colors.light.middleGrey')}
      >
        {G.getWindowLocale('titles:no-additional-filters', 'No Additional Filters')}
      </Flex>
    );
  }
  const availableFields = getSelectOptions(props, GC.FIELD_FILTERS)();
  return (
    <FormSection name={GC.FIELD_FILTERS}>
      {
        props.filters.map((field: Object, key: number) => (
          <FormSection key={key} css={groupStyles} name={`filter-${R.inc(key)}`}>
            <RelativeBox cursor='pointer' onClick={() => props.handleCountFilter(key)}>
              {I.minusInRound()}
            </RelativeBox>
            <Field
              component={renderSelect}
              name={`field-${R.inc(key)}`}
              validate={G.requiredWithoutMessage}
              selectAction={props.handleFilterFieldSelect}
              zI={R.subtract(10 + props.filters.length, key)}
              options={getSelectOptions(props, GC.FIELD_FILTERS)()} />
            <Field
              component={renderSelect}
              name={`operation-${R.inc(key)}`}
              validate={G.requiredWithoutMessage}
              zI={R.subtract(10 + props.filters.length, key)}
              selectAction={props.handleFilterOperationSelect}
              options={reportConditions()[R.or(field.dataType, 'default')]} />
            {
              renderField(
                R.assoc('availableFields', availableFields, props),
                field,
                `filters.filter-${R.inc(key)}.`,
                key,
                options(props, key),
              )
            }
          </FormSection>
        ))
      }
    </FormSection>
  );
};

const enhance = compose(
  withHandlers({
    handleCountFilter: (props: Object) => (seq: number) => {
      const filters = R.clone(props.filters);
      const availableFields = getSelectOptions(props, GC.FIELD_FILTERS)();
      if (R.isNil(seq)) {
        filters.splice(R.length(filters), 0, emptyFilter);
      } else {
        filters.splice(seq, 1);
      }
      const columnSequence = filters.map(
        (column: Object, index: number) => R.assoc('sequence', `${R.inc(index)}`, column),
      );
      props.setFilters(filters);
      props.change(
        GC.FIELD_FILTERS,
        setFilterInitialValues(columnSequence, GC.FIELD_FILTER, availableFields),
      );
    },
    handleFilterFieldSelect: (props: Object) => (fieldName: string, option: any) => {
      const sequence = G.toNumber(R.last(fieldName));
      const availableFields = getSelectOptions(props, GC.FIELD_FILTERS)();
      const newFields = props.filters.map((field: Object, index: number) => {
        if (G.notEquals(R.inc(index), sequence)) return field;
        const nameValue = R.find(R.propEq(R.prop(GC.FIELD_NAME, option), GC.FIELD_NAME), availableFields);
        let fields = {
          ...emptyFilter,
          [GC.FIELD_CHART_DATA_TYPE]: nameValue.type,
          [GC.FIELD_CHART_DATE_RELATIVE]: field.dateRelative,
          [GC.FIELD_PROPERTY_NAME]: R.prop(GC.FIELD_VALUE, nameValue),
        };
        if (R.equals(nameValue.type, GC.FIELD_REFERENCE)) {
          fields = R.mergeRight(
            fields,
            {
              [GC.FIELD_PROPERTY_NAME]: '',
              [GC.FIELD_REFERENCE_NAME]: GC.FIELD_REFERENCES,
            },
          );
        }
        return fields;
      });
      props.setFilters(newFields);
      props.change(fieldName, option.value);
      if (G.notEquals(option.type, R.pathOr('', [GC.FIELD_CHART_DATA_TYPE], props.filters[R.subtract(sequence, 1)]))) {
        const filterDotFilter = `${GC.FIELD_FILTER}.${GC.FIELD_FILTER}`;
        const fields = [
          `${filterDotFilter}-${sequence}.${GC.FIELD_VALUE}-${sequence}`,
          `${filterDotFilter}-${sequence}.${GC.FIELD_OPERATION}-${sequence}`,
          `${filterDotFilter}-${sequence}.${GC.FIELD_VALUE}-${GC.FIELD_TO}-${sequence}`,
          `${filterDotFilter}-${sequence}.${GC.FIELD_VALUE}-${GC.FIELD_FROM}-${sequence}`,
          `${filterDotFilter}-${sequence}.${GC.FIELD_VALUE}-${GC.FIELD_NEXT}-${sequence}`,
          `${filterDotFilter}-${sequence}.${GC.FIELD_VALUE}-${GC.FIELD_LAST}-${sequence}`,
        ];
        fields.forEach((item: Object) => props.change(item, ''));
      }
    },
    handleFilterOperationSelect: (props: Object) => (columnName: string, option: string) => {
      const sequence = G.toNumber(R.last(columnName));
      const newFields = props.filters.map((field: Object, index: number) => {
        if (G.notEquals(R.inc(index), sequence)) return field;
        const operation = R.find(
          R.propEq(R.prop(GC.FIELD_NAME, option), GC.FIELD_NAME),
          reportConditions()[R.or(field.dataType, GC.FIELD_DEFAULT)],
        );
        const dateType = G.ifElse(
          R.and(
            R.equals(R.prop(GC.FIELD_CHART_DATA_TYPE, field), GC.FIELD_DATE),
            G.notEquals(R.prop(GC.FIELD_VALUE, operation), GC.FIELD_DATE_RANGE),
          ),
          {
            [GC.FIELD_TO]: '',
            [GC.FIELD_FROM]: '',
            [GC.FIELD_OPERATION]: '',
            [GC.FIELD_CHART_DATE_RELATIVE]: true,
            [GC.FIELD_CHART_TIME_UNIT]: G.getOrElse(operation, GC.FIELD_VALUE, ''),
          },
          {
            [GC.FIELD_LAST]: '',
            [GC.FIELD_NEXT]: '',
            [GC.FIELD_CHART_TIME_UNIT]: null,
            [GC.FIELD_CHART_DATE_RELATIVE]: false,
            [GC.FIELD_OPERATION]: G.getOrElse(operation, 'value', ''),
          },
        );
        return { ...field, ...dateType };
      });
      props.setFilters(newFields);
      props.change(columnName, R.prop(GC.FIELD_NAME, option));
    },
    handleFilterValue: (props: Object) => (columnName: string, option: any, sequence: any) => {
      const newFields = props.filters.map((field: Object, index: number) => {
        if (G.notEquals(index, sequence)) return field;
        const booleanConditions = R.pathOr(
          '',
          [GC.FIELD_VALUE],
          R.find(
            R.propEq(R.prop(GC.FIELD_VALUE, option), GC.FIELD_VALUE),
            reportBooleanValues(),
          ),
        );
        return {
          ...field,
          [`${field.dataType}Value`]: G.ifElse(
            R.equals(field.dataType, 'boolean'),
            booleanConditions,
            option,
          ),
        };
      });
      props.setFilters(newFields);
      props.change(columnName, R.pathOr(option, [GC.FIELD_NAME], option));
    },
    handleFilterDateValue: (props: Object) => (
      columnName: string,
      value: string,
      sequence: any,
      prefix: string,
    ) => {
      const dateValue = G.ifElse(
        R.includes(prefix, [GC.FIELD_FROM, GC.FIELD_TO]),
        moment(value).format(GC.DEFAULT_DATE_TIME_FORMAT),
        value,
      );
      const newFields = props.filters.map((field: Object, index: number) => {
        if (G.notEquals(index, sequence)) return field;
        return {
          ...field,
          [prefix]: dateValue,
          [GC.FIELD_CHART_DATA_TYPE]: GC.FIELD_DATE,
        };
      });
      props.setFilters(newFields);
      props.change(columnName, moment(value).format(GC.DEFAULT_DATE_TIME_FORMAT));
    },
  }),
  pure,
);

const AdditionFiltersComponent = (props: Object) => (
  <AdditionFiltersWrapper>
    <Box>
      <Span>{G.getWindowLocale('titles:additional-filters', 'Additional Filters')}</Span>
      <Span onClick={() => props.handleCountFilter()}>{I.plusRound()}</Span>
    </Box>
    {renderFilters(props)}
  </AdditionFiltersWrapper>
);

export default enhance(AdditionFiltersComponent);
