import { createAction } from 'redux-act';
//////////////////////////////////////////////////

const sendLogInRequest = createAction('sendLogInRequest');
const receivedLogInFail = createAction('receivedLogInFail');
const setDispatchAction = createAction('setDispatchAction');
const sendLogOutRequest = createAction('sendLogOutRequest');
const checkTokenRequest = createAction('checkTokenRequest');
const refreshTokenCatch = createAction('refreshTokenCatch');
const receivedLogOutFail = createAction('receivedLogOutFail');
const refreshTokenRequest = createAction('refreshTokenRequest');
const refreshTokenSuccess = createAction('refreshTokenSuccess');
const receivedLogInSuccess = createAction('receivedLogInSuccess');
const receivedLogOutSuccess = createAction('receivedLogOutSuccess');
const receivedNalLogInSuccess = createAction('receivedNalLogInSuccess');
const receivedBoshLogInSuccess = createAction('receivedBoshLogInSuccess');
const sendResetPasswordRequest = createAction('sendResetPasswordRequest');
const receivedResetPasswordSuccess = createAction('receivedResetPasswordSuccess');
const sendCreateNewPasswordRequest = createAction('sendCreateNewPasswordRequest');
const receivedSessionValidationFail = createAction('receivedSessionValidationFail');
const receivedSessionValidationSuccess = createAction('receivedSessionValidationSuccess');
const receivedCreateNewPasswordSuccess = createAction('receivedCreateNewPasswordSuccess');
const getTokenByCurrentBranchGuidRequest = createAction('getTokenByCurrentBranchGuidRequest');
const sendCreateNewPasswordMobileRequest = createAction('sendCreateNewPasswordMobileRequest');
const receivedCreateNewPasswordMobileSuccess = createAction('receivedCreateNewPasswordMobileSuccess');

export {
  sendLogInRequest,
  receivedLogInFail,
  setDispatchAction,
  sendLogOutRequest,
  checkTokenRequest,
  refreshTokenCatch,
  receivedLogOutFail,
  refreshTokenRequest,
  refreshTokenSuccess,
  receivedLogInSuccess,
  receivedLogOutSuccess,
  receivedNalLogInSuccess,
  receivedBoshLogInSuccess,
  sendResetPasswordRequest,
  receivedResetPasswordSuccess,
  sendCreateNewPasswordRequest,
  receivedSessionValidationFail,
  receivedSessionValidationSuccess,
  receivedCreateNewPasswordSuccess,
  getTokenByCurrentBranchGuidRequest,
  sendCreateNewPasswordMobileRequest,
  receivedCreateNewPasswordMobileSuccess,
};
