import React from 'react';
import * as R from 'ramda';
// helpers
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Text, IconWrapper } from '../../../ui';
// feature dashboards
import ChartFilters from './chart-filters';
import BarChartComponent from './bar-chart';
import GroupableTableComponent from './groupable-table';
import SimpleBarChartComponent from './simple-bar-chart';
import Composed2LineChart from './composed-2-line-chart';
import ComposedAreaAndLineChart from './composed-area-and-line-chart';
import ComposedLineWithAreaChart from './composed-line-with-area-chart';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const Chart = (props: Object) => {
  const { chartType } = props;

  if (R.equals(chartType, 'BAR')) {
    return <BarChartComponent {...props} />;
  }

  if (R.equals(chartType, 'GROUPABLE_TABLE')) {
    return <GroupableTableComponent {...props} />;
  }

  if (R.equals(chartType, 'SIMPLE_BAR')) {
    return <SimpleBarChartComponent {...props} />;
  }

  if (R.equals(chartType, '2_LINE')) {
    return <Composed2LineChart {...props} />;
  }

  if (R.equals(chartType, 'AREA_AND_LINE')) {
    return <ComposedAreaAndLineChart {...props} />;
  }

  if (R.equals(chartType, 'LINE_WITH_AREA')) {
    return <ComposedLineWithAreaChart {...props} />;
  }

  return null;
};

const LargeCard = (props: Object) => {
  const {
    mb,
    title,
    height,
    withExport,
    exportChartType,
    filterChartType,
    handleGenerateExcel,
  } = props;

  const exportTitle = G.getWindowLocale('actions:generate-excel', 'Generate Excel');

  return (
    <Flex
      p={15}
      bg='white'
      width={1060}
      mb={mb || 20}
      borderRadius={10}
      alignItems='start'
      height={height || 460}
      flexDirection='column'
      justifyContent='space-between'
    >
      <Flex width='100%' justifyContent='space-between'>
        <Flex>
          <Box
            fontSize={14}
            fontWeight='bold'
            color='dark.mainDark'
          >
            {title}
          </Box>
          { filterChartType && <ChartFilters chartType={filterChartType} /> }
        </Flex>
        { withExport && (
          <Flex
            gap={10}
            cursor='pointer'
            title={exportTitle}
            onClick={() => handleGenerateExcel(exportChartType)}
          >
            <IconWrapper>
              <Text fontSize={13}>{exportTitle}</Text>
            </IconWrapper>
            <IconWrapper>
              {I.downloadDocument(iconColor, 15, 17)}
            </IconWrapper>
          </Flex>
        )}
      </Flex>
      <Chart {...props} />
    </Flex>
  );
};

export default LargeCard;
