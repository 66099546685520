// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const getTableSettings = (isAllDriversList: boolean) => ({
  cellFontSize: 12,
  titleFontSize: 14,
  titleRowHeight: 40,
  tableRowHeight: 55,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 0,
  moreBtnCellWidth: 200,
  allowSelectItems: false,
  maxHeight: `calc(100vh - ${G.ifElse(isAllDriversList, '100px', '160px')})`,
});
