import React, { useState } from 'react';
// components
import ToggleBtn from '../../../components/toggle-btn';
import { Switcher } from '../../../components/switcher';
import { DateRangeMui } from '../../../components/date-range';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const switchIndexMap = {
  1: 0,
  3: 1,
  7: 2,
};

const switchOptions = [
  {
    value: 1,
    width: 50,
    name: G.getWindowLocale('titles:1-day', '1 day'),
  },
  {
    value: 3,
    width: 50,
    name: G.getWindowLocale('titles:3-days', '3 days'),
  },
  {
    value: 7,
    width: 50,
    name: G.getWindowLocale('titles:7-days', '7 days'),
  },
];

const LeftFilters = (props: Object) => {
  const {
    filtersStore,
    teamFilterValue,
    show24HoursView,
    setTeamFilterValue,
    handleSetFilterDates,
    toggleShow24HoursView,
    handleSetFilterQuickDays,
  } = props;

  const [showDateRange, setShowDateRange] = useState(false);

  const quickDaysValue = G.getPropFromObject('initialFilterDays', filtersStore);

  return (
    <Flex>
      <ToggleBtn
        mx={15}
        checked={teamFilterValue}
        onToggleHandler={setTeamFilterValue}
        title={G.getWindowLocale('titles:show-team', 'Show Team')}
      />
      <ToggleBtn
        mr={15}
        checked={show24HoursView}
        onToggleHandler={toggleShow24HoursView}
        title={G.getWindowLocale('titles:show-24-hours', 'Show 24 hours')}
      />
      <Switcher
        mr={15}
        version={3}
        fontSize={10}
        options={switchOptions}
        selectedOptionIndex={switchIndexMap[quickDaysValue]}
        onSwitch={(value: string) => handleSetFilterQuickDays(value)}
      />
      <ToggleBtn
        mr={15}
        checked={showDateRange}
        onToggleHandler={setShowDateRange}
        title={G.getWindowLocale('titles:date-range', 'Date Range')}
      />
      {
        showDateRange &&
        <DateRangeMui
          {...filtersStore}
          width={80}
          withIcon={true}
          useNewMuiInputField={true}
          popperPlacement='bottom-end'
          onSelectDateRange={handleSetFilterDates}
          maxDate={G.momentAddYearsFromCurrent(100)}
          labelTo={G.getWindowLocale('titles:to', 'To')}
          minDate={G.momentSubtractYearsFromCurrent(100)}
          labelFrom={G.getWindowLocale('titles:from', 'From')}
        />
      }
    </Flex>
  );
};

export default LeftFilters;
