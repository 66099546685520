import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const generalSection = {
  title: 'titles:rating',
  fields: {
    [GC.RATE_ENGINE_AUTO_ASSIGN_TYPE]: {
      nameForAttribute: GC.RATE_ENGINE_AUTO_ASSIGN_TYPE,
      name: 'titles:auto-assign',
      type: 'select',
      options: [
        { label: G.getWindowLocale('titles:rate', 'Rate'), value: 'RATE' },
        { label: G.getWindowLocale('titles:distance', 'Distance'), value: 'DISTANCE' },
      ],
    },
    [GC.RATE_ENGINE_MAX_DISTANCE]: {
      type: 'input',
      name: 'titles:max-distance',
      validate: G.isEmptyOrInteger,
      nameForAttribute: GC.RATE_ENGINE_MAX_DISTANCE,
    },
    [GC.RATE_ENGINE_MAX_DISTANCE_UOM]: {
      type: 'select',
      name: 'titles:max-distance-uom',
      options: DEFAULT_DISTANCE_UOM_OPTIONS,
      nameForAttribute: GC.RATE_ENGINE_MAX_DISTANCE_UOM,
    },
    [GC.RATE_ENGINE_CLO_RATE_ADJUSTMENT_FOR_TEL_VALUE]: {
      type: 'input',
      validate: G.isIntOrFloat,
      name: 'titles:rate-adjustment-value',
      nameForAttribute: GC.RATE_ENGINE_CLO_RATE_ADJUSTMENT_FOR_TEL_VALUE,
    },
    [GC.RATE_ENGINE_CLO_RATE_ADJUSTMENT_FOR_TEL_RATE_UNIT]: {
      type: 'select',
      name: 'titles:rate-adjustment-unit',
      options: R.tail(DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS),
      nameForAttribute: GC.RATE_ENGINE_CLO_RATE_ADJUSTMENT_FOR_TEL_RATE_UNIT,
    },
    [GC.RATE_ENGINE_MULTIPLE_CURRENCY_CHARGES]: {
      type: 'switcher',
      name: 'titles:multiple-currency-charges',
      nameForAttribute: GC.RATE_ENGINE_MULTIPLE_CURRENCY_CHARGES,
    },
    [GC.RATE_ENGINE_SEPARATE_LINE_HAUL_FOR_EVERY_CLO]: {
      type: 'switcher',
      name: 'titles:separate-line-haul-for-every-order',
      nameForAttribute: GC.RATE_ENGINE_SEPARATE_LINE_HAUL_FOR_EVERY_CLO,
    },
    [GC.RATE_ENGINE_USE_TRIP_MAX_PAY]: {
      type: 'switcher',
      name: 'titles:use-trip-max-pay',
      nameForAttribute: GC.RATE_ENGINE_USE_TRIP_MAX_PAY,
    },
  },
};

const marginViolationReason = {
  fields: [],
  configType: 'dropdown',
  addPopupTitle: 'titles:add',
  editPopupTitle: 'titles:edit',
  title: 'titles:violation-reason',
  configName: GC.RATE_ENGINE_MARGIN_VIOLATION_REASON,
};

const marginViolationRule = {
  configType: 'custom',
  title: 'titles:margin-violation-rule',
  configName: GC.CUSTOM_MARGIN_VIOLATION_RULE_CONFIG,
  customConfigName: GC.CUSTOM_MARGIN_VIOLATION_RULE_CONFIG,
  fields: [
    {
      name: 'titles:transportation-modes',
      nameForAttribute: GC.FIELD_TRANSPORTATION_MODE_GUIDS,
      customLogic: (_: any, { transportationModeGuids }: Object, { configOptions }: Object) => R.compose(
        R.join(', '),
        R.filter(G.isNotNilAndNotEmpty),
        R.map((mode: Object) => G.getPropFromObject(GC.FIELD_LABEL, mode)),
        R.values,
        R.pick(transportationModeGuids),
        R.indexBy(R.prop(GC.FIELD_VALUE)),
        R.pathOr([], ['transportationModes']),
      )(configOptions),
    },
    {
      tableColumnWith: '200px',
      name: 'titles:min-margin',
      nameForAttribute: GC.FIELD_MIN_MARGIN,
    },
    {
      tableColumnWith: '200px',
      name: 'titles:critical-margin',
      nameForAttribute: GC.FIELD_CRITICAL_MARGIN,
    },
  ],
};

const ConfigRatingGroup = {
  generalSection,
  marginViolationReason,
  marginViolationRule,
};

export {
  ConfigRatingGroup,
};
