import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: 400,
  afterTop: 14,
  errorTop: '110%',
  errorLeft: '5px',
  labelWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

export const getFields = (initialValues: Object) => [
  {
    ...defaultFieldStyles,
    zIndex: 12,
    type: 'searchselect',
    loc: 'titles:document-type',
    options: 'availableDocumentTypes',
    name: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
  },
  {
    ...defaultFieldStyles,
    type: 'textarea',
    loc: 'titles:description',
    name: GC.FIELD_DESCRIPTION,
    fieldName: GC.FIELD_DESCRIPTION,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:url',
    name: GC.FIELD_DOCUMENT_URL,
    fieldName: GC.FIELD_DOCUMENT_URL,
    fieldGroupDisplay: (_: any, values: Object) => (
      G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_UPLOAD, initialValues)
    ),
  },
  {
    ...defaultFieldStyles,
    type: 'file',
    loc: 'titles:upload-file',
    name: GC.FIELD_DOCUMENT_UPLOAD,
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    fieldGroupDisplay: (_: any, values: Object) => (
      G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_URL, initialValues)
    ),
  },
];

export const defaultValues = {
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_DOCUMENT_URL]: '',
  [GC.FIELD_DOCUMENT_UPLOAD]: '',
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: '',
};

const yupUrlValidation = Yup.string().nullable(true).url(G.getShouldBeUrlLocaleTxt());

const defaultValidationSchemaObject = {
  [GC.FIELD_DOCUMENT_URL]: yupUrlValidation,
  [GC.FIELD_DESCRIPTION]: Yup.string()
    .nullable(true),
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const getValidationSchemaObject = ({ url, file }: Object) => {
  if (G.isOneNotNilOrNotEmpty([url, file])) return defaultValidationSchemaObject;

  const requiredFieldsValidationSchemaObject = {
    [GC.FIELD_DOCUMENT_URL]: yupUrlValidation
      .required(G.getShouldBeFileOrUrlLocaleTxt()),
    [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string()
      .nullable(true)
      .required(G.getShouldBeFileOrUrlLocaleTxt()),
  };

  return R.mergeRight(defaultValidationSchemaObject, requiredFieldsValidationSchemaObject);
};

export const documentsColumnSettings = {
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 300,
    uiField: true,
    name: 'titles:file',
    customLogic: (documentFilename: string, { entity, handlePreviewDocument }: Object) => {
      if (G.isNotNilAndNotEmpty(documentFilename)) {
        return (
          <Box
            cursor='pointer'
            color={G.getTheme('colors.light.blue')}
            onClick={() => handlePreviewDocument(entity)}
            title={`${G.getWindowLocale('titles:click-for-preview', 'Click For Preview')} - ${documentFilename}`}
          >
            {documentFilename}
          </Box>
        );
      }

      return <Box>{R.pathOr('', [GC.FIELD_DOCUMENT_URL], entity)}</Box>;
    },
  },
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    width: 200,
    name: 'titles:type',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 300,
    name: 'titles:description',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
    customLogic: (value: string) => G.createLocalDateTimeFromInstanceOrISOString(
      value,
      G.getDateTimeFormatFromConfig('dateTime'),
    ),
  },
};
