import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature claim-management
import * as A from '../actions';
import { detailsReducer } from './details';
import { initialState, reportReducer } from './report';
import { setBranchConfigsToStoreByPath } from '../helpers';
//////////////////////////////////////////////////

// configs
const getBranchConfigsByNamesSuccess = (state: Object, data: Object) => setBranchConfigsToStoreByPath({
  data,
  state,
  path: 'configGroup',
});

export default createReducer({
  ...reportReducer,
  ...detailsReducer,
  [A.getBranchConfigsByNamesSuccess]: getBranchConfigsByNamesSuccess,
}, initialState);
