import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature dispatch-board-new
import * as C from '../constants';
/////////////////////////////////////////////////

export const getGroupByOptions = (type: string) => {
  const options = {
    [GC.LOAD_TYPE_CLO]: [
      {
        value: GC.ORGANIZE_BY_NO_GROUPING,
        name: G.getWindowLocale('titles:no-group', 'No Group'),
      },
      {
        value: GC.ORGANIZE_BY_ROUTE,
        name: G.getWindowLocale('titles:route', 'Route'),
      },
      {
        value: GC.ORGANIZE_BY_BRANCH,
        name: G.getWindowLocale('titles:branch', 'Branch'),
      },
      {
        value: GC.ORGANIZE_BY_BILL_TO,
        name: G.getWindowLocale('titles:bill-to', 'Bill To'),
      },
      {
        value: GC.ORGANIZE_BY_FIRST_EVENT_DATE,
        name: G.getWindowLocale('titles:first-stop-date', 'First Stop Date'),
      },
      {
        value: GC.ORGANIZE_BY_LAST_EVENT_DATE,
        name: G.getWindowLocale('titles:last-stop-date', 'Last Stop Date'),
      },
      {
        value: GC.ORGANIZE_BY_STATUS,
        name: G.getWindowLocale('titles:status', 'Status'),
      },
      {
        value: GC.ORGANIZE_BY_CLO_TRANSPORTATION_MODE,
        name: G.getWindowLocale('titles:mode', 'Mode'),
      },
    ],
    [GC.LOAD_TYPE_TEL]: [
      {
        value: GC.ORGANIZE_BY_NO_GROUPING,
        name: G.getWindowLocale('titles:no-group', 'No Group'),
      },
      {
        value: GC.ORGANIZE_BY_ROUTE,
        name: G.getWindowLocale('titles:route', 'Route'),
      },
      {
        value: GC.ORGANIZE_BY_BRANCH,
        name: G.getWindowLocale('titles:branch', 'Branch'),
      },
      {
        value: GC.ORGANIZE_BY_DRIVER,
        name: G.getWindowLocale('titles:driver-carrier', 'Driver/Carrier'),
      },
      {
        value: GC.ORGANIZE_BY_CARRIER,
        name: G.getWindowLocale('titles:carrier-driver', 'Carrier/Driver'),
      },
      {
        value: GC.ORGANIZE_BY_TRUCK,
        name: G.getWindowLocale('titles:truck', 'Truck'),
      },
      {
        value: GC.ORGANIZE_BY_TRAILER,
        name: G.getWindowLocale('titles:trailer', 'Trailer'),
      },
      {
        value: GC.ORGANIZE_BY_FIRST_EVENT_DATE,
        name: G.getWindowLocale('titles:first-stop-date', 'First Stop Date'),
      },
      {
        value: GC.ORGANIZE_BY_LAST_EVENT_DATE,
        name: G.getWindowLocale('titles:last-stop-date', 'Last Stop Date'),
      },
      {
        value: GC.ORGANIZE_BY_DISPATCHED_BY,
        name: G.getWindowLocale('titles:dispatched-by', 'Dispatched By'),
      },
      {
        value: GC.ORGANIZE_BY_STATUS,
        name: G.getWindowLocale('titles:status', 'Status'),
      },
      {
        value: GC.ORGANIZE_BY_TEL_TRANSPORTATION_MODE,
        name: G.getWindowLocale('titles:mode', 'Mode'),
      },
    ],
  };
  if (R.and(R.equals(type, GC.LOAD_TYPE_TEL), G.isCurrentUserTypeCarrier())) {
    return R.remove(2, 1, options[type]);
  }
  return options[type];
};

export const getSummaryOptions = (type: string) => {
  const options = {
    [GC.LOAD_TYPE_CLO]: [
      {
        value: C.SUMMARY_STATUS,
        name: G.getWindowLocale('titles:status', 'Status'),
      },
      {
        value: C.SUMMARY_BRANCH,
        name: G.getWindowLocale('titles:branch', 'Branch'),
      },
      {
        value: C.SUMMARY_BILL_TO,
        name: G.getWindowLocale('titles:bill-to', 'Bill To'),
      },
      {
        value: C.SUMMARY_FIRST_EVENT_DATE,
        name: G.getWindowLocale('titles:first-stop-date', 'First Stop Date'),
      },
      {
        value: C.SUMMARY_LAST_EVENT_DATE,
        name: G.getWindowLocale('titles:last-stop-date', 'Last Stop Date'),
      },
    ],
    [GC.LOAD_TYPE_TEL]: [
      {
        value: C.SUMMARY_STATUS,
        name: G.getWindowLocale('titles:status', 'Status'),
      },
      {
        value: C.SUMMARY_DRIVER,
        name: G.getWindowLocale('titles:driver-carrier', 'Driver/Carrier'),
      },
      {
        value: C.SUMMARY_CARRIER,
        name: G.getWindowLocale('titles:carrier-driver', 'Carrier/Driver'),
      },
      {
        value: C.SUMMARY_BRANCH,
        name: G.getWindowLocale('titles:branch', 'Branch'),
      },
      {
        value: GC.ORGANIZE_BY_TRUCK,
        name: G.getWindowLocale('titles:truck', 'Truck'),
      },
      {
        value: GC.ORGANIZE_BY_TRAILER,
        name: G.getWindowLocale('titles:trailer', 'Trailer'),
      },
      {
        value: C.SUMMARY_FIRST_EVENT_DATE,
        name: G.getWindowLocale('titles:first-stop-date', 'First Stop Date'),
      },
      {
        value: C.SUMMARY_LAST_EVENT_DATE,
        name: G.getWindowLocale('titles:last-stop-date', 'Last Stop Date'),
      },
      {
        value: GC.ORGANIZE_BY_DISPATCHED_BY,
        name: G.getWindowLocale('titles:dispatched-by', 'Dispatched By'),
      },
    ],
  };
  if (R.and(R.equals(type, GC.LOAD_TYPE_TEL), G.isCurrentUserTypeCarrier())) {
    return R.remove(3, 1, options[type]);
  }
  return options[type];
};
