import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner
import * as A from './actions';
import * as H from './helpers';
//////////////////////////////////////////////////

const hasLoadCloEvents = R.compose(
  R.not,
  R.isEmpty,
  R.filter((event: Object) => R.not(R.isNil(event.cloGuid))),
);

export const handleRemoveTelFromRouteBuilder = createLogic({
  type: A.removeTelFromRouteBuilder,
  validate({ action }: Object, allow: void, reject: void) {
    const tel = action.payload;
    if (hasLoadCloEvents(R.prop(GC.FIELD_LOAD_STOPS, tel))) {
      G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('titles:cant-remove-tel-with-clo', 'Sorry, you can not remove TEL with CLO events'),
      );
      return reject();
    }
    allow(action);
  },
});

const isInvalidDistances = (events: Object) => G.isNotNilAndNotEmpty(
  events.find((event: Object, index: number) => (
    R.and(
      R.lt(index, R.dec(events.length)),
      G.isNilOrEmpty(event.telDistanceToNextStop),
    )
  )),
);

const validate = ({ currentRoute, currentRouteItems }: Object) => {
  let isValid = true;
  const errors = {};
  const tels = R.values(currentRoute.tels);
  const noTels = R.isEmpty(tels);
  isValid = R.not(noTels);

  tels.forEach((tel: Object) => {
    const telEvents = R.sortBy(
      R.prop('telEventIndex'),
      R.values(tel[GC.FIELD_LOAD_STOPS]),
    );
    const eventsErrors = H.getEventsErrors(telEvents, currentRouteItems);
    const distanceError = isInvalidDistances(telEvents);
    const eventsCountError = R.lt(telEvents.length, 2);
    const containersError = H.getContainersError(tel);

    if (R.any(G.isTrue, [
      distanceError,
      containersError,
      eventsCountError,
      G.isNotEmpty(eventsErrors),
    ])) {
      errors[tel.guid] = {
        eventsErrors,
        distanceError,
        containersError,
        eventsCountError,
      };
    }
  });

  if (G.isNotEmpty(errors)) isValid = false;

  return { noTels, errors, isValid };
};

export const saveRoute = createLogic({
  type: A.validateRouteRequest,
  process({ action }: Object, dispatch: void, done: void) {
    const validationData = validate(action.payload);

    if (R.not(validationData.isValid)) {
      dispatch(A.setRouteErrors(validationData.errors));

      if (G.isTrue(validationData.noTels)) {
        G.showToastrMessageSimple(
          'error',
          G.getWindowLocale('validation:route-should-contain-tel', 'Route should contain at least one trip'),
        );
      }
    } else {
      dispatch(A.setRouteErrors(null));
      dispatch(A.saveRouteRequest(action.payload));
    }

    done();
  },
});

export default [
  saveRoute,
  handleRemoveTelFromRouteBuilder,
];
