import * as R from 'ramda';
import { withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequestWithQSParamsSerializer } from '../../../utilities/http';
//////////////////////////////////////////////////

export const withDocumentActions = withHandlers({
  handlePreviewDocument: (props: Object) => async (entity: Object, extraOptions: Object) => {
    const { openLoader, closeLoader, primaryObjectGuid } = props;

    const { fileUri, fileName, documentFilename } = entity;

    const { endpointName, primaryObjectGuidKey } = extraOptions;

    if (G.isNilOrEmpty(endpointName)) return;

    openLoader();

    const options = {
      resType: 'arraybuffer',
      params: {
        fileUri,
        [primaryObjectGuidKey]: primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.or(fileName, documentFilename),
      },
    };

    const endpoint = G.getPropFromObject(endpointName, endpointsMap);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.openFileInWindowFromArrayBufferResponse(res);
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  },
  handleDownloadDocument: (props: Object) => async (entity: Object, extraOptions: Object) => {
    const { openLoader, closeLoader, primaryObjectGuid } = props;

    const { fileUri, fileName, documentFilename } = entity;

    const { endpointName, primaryObjectGuidKey } = extraOptions;

    if (G.isNilOrEmpty(endpointName)) return;

    openLoader();

    const options = {
      resType: 'arraybuffer',
      params: {
        fileUri,
        [primaryObjectGuidKey]: primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.or(fileName, documentFilename),
      },
    };

    const endpoint = G.getPropFromObject(endpointName, endpointsMap);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      G.showToastrMessageSimple(
        'success',
        G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
      );
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  },
});
