import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
// helpers/constants
import * as G from '../../helpers';
// ui
import { PageWrapper, ZOrderWrapper } from '../../ui';
// feature average-fuel-price
import AddFuelPrices from './components/average-fuel-prices';
import { report, columnSettings, FILTER_PARAMS } from './settings/column-settings';
import {
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
} from './actions';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  useMainColors: true,
  checkBoxCellWidth: 0,
  expandableItems: false,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 135px)',
};

const enhance = compose(
  withHandlers({
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    pagination,
    totalCount,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleTableTitleFilter,
  } = props;

  const data = {
    report,
    loading,
    itemList,
    totalCount,
    pagination,
    tableSettings,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    handleTableTitleFilter,
    filterProps: FILTER_PARAMS,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities:
      G.ifElse(
        loading,
        () => {},
        getItemListRequest,
      ),
  };

  return <Table {...data} />;
};

const AverageFuelPriceComponent = (props: Object) => {
  const { totalCount } = props;

  return (
    <PageWrapper pt={15} pr={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          withCount={true}
          noExportable={true}
          hideFilterInfo={true}
          popperWithCount={true}
          totalCount={totalCount}
          withoutQuickFilter={true}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={true}
          title={G.getWindowLocale('titles:average-fuel-prices', 'Average Fuel Prices')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <AddFuelPrices />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  requestPending: makeSelectReportStatus(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
});

export default connect(mapStateToProps, {
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
})(enhance(AverageFuelPriceComponent));
