import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// common
export const getTruckGeneralDetailsRequest = createAction('getTruckGeneralDetailsRequest');
export const updateTruckGeneralDetailsRequest = createAction('updateTruckGeneralDetailsRequest');
// configs
export const getTruckBranchConfigsRequest = createAction('getTruckBranchConfigsRequest');
// daily log
export const getDailyLogListRequest = createAction('getDailyLogListRequest');
export const getDailyLogListSuccess = createAction('getDailyLogListSuccess');
