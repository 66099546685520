import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  manifestTypeOptions,
  crossBorderIntegrationTypeOptions,
} from '../../../helpers/options';
// ui
import { BoxHovered } from '../../../ui';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  mb: 25,
  width: 400,
};

export const docFields = (values: Object, initialValues: Object) => [
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'availableDocumentTypes',
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
    label: ['titles:document-type', 'Document Type'],
    inputWrapperStyles: { ...defaultFieldStyles, zIndex: 2 },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DOCUMENT_COMMENTS,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: { ...defaultFieldStyles, mb: 15 },
  },
  {
    version: 2,
    type: 'multiswitch',
    fieldName: GC.FIELD_DOCUMENT_PROOF_TYPE,
    inputWrapperStyles: {
      ...defaultFieldStyles,
      display: 'flex',
      justifyContent: 'center',
    },
    options: [
      {
        width: 100,
        name: 'General',
        value: GC.DOCUMENT_PROOF_TYPE_GENERAL_VALUE,
      },
      {
        width: 80,
        name: GC.DOCUMENT_PROOF_TYPE_POP,
        value: GC.DOCUMENT_PROOF_TYPE_POP,
      },
      {
        width: 80,
        name: GC.DOCUMENT_PROOF_TYPE_POD,
        value: GC.DOCUMENT_PROOF_TYPE_POD,
      },
    ],
  },
  {
    type: 'reactSelect',
    options: 'eventsOptions',
    label: ['titles:stops', 'Stops'],
    inputWrapperStyles: defaultFieldStyles,
    fieldName: GC.FIELD_DOCUMENT_EVENT_GUID,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:url', 'URL'],
    fieldName: GC.FIELD_DOCUMENT_URL,
    inputWrapperStyles: {
      ...defaultFieldStyles,
      display: G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_UPLOAD, initialValues),
    },
  },
  {
    type: 'file',
    isRequired: true,
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    label: ['titles:upload-file', 'Upload File'],
    inputWrapperStyles: {
      ...defaultFieldStyles,
      display: G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_URL, initialValues, { defaultDisplay: 'block' }),
    },
  },
];

export const defaultDocumentFields = {
  [GC.FIELD_DOCUMENT_URL]: '',
  [GC.FIELD_DOCUMENT_UPLOAD]: '',
  [GC.FIELD_DOCUMENT_COMMENTS]: '',
  [GC.FIELD_DOCUMENT_PROOF_TYPE]: '',
  [GC.FIELD_DOCUMENT_EVENT_GUID]: '',
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: '',
};

export const defaultValidationSchemaDocumentFormObject = {
  [GC.FIELD_DOCUMENT_EVENT_GUID]: G.yupStringNotRequired,
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_URL]: Yup.string()
    .nullable(true)
    .url(G.getShouldBeUrlLocaleTxt()),
  [GC.FIELD_DOCUMENT_COMMENTS]: Yup.string()
    .nullable(true)
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
};

export const getDocumentFormValidationSchemaObject = (values: Object) => {
  const requiredFields = [values[GC.FIELD_DOCUMENT_UPLOAD], values[GC.FIELD_DOCUMENT_URL]];

  if (R.any(G.isNotNilAndNotEmpty)(requiredFields)) return defaultValidationSchemaDocumentFormObject;

  const requiredFieldsValidationSchemaObject = {
    [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string()
      .nullable(true)
      .required(G.getShouldBeFileLocaleTxt()),
    [GC.FIELD_DOCUMENT_URL]: Yup.string()
      .nullable(true)
      .required(G.getShouldBeUrlLocaleTxt())
      .url(G.getShouldBeUrlLocaleTxt()),
  };

  return R.mergeRight(defaultValidationSchemaDocumentFormObject, requiredFieldsValidationSchemaObject);
};

const statusMessageFieldsStyles = {
  mb: 25,
  width: 450,
};

const locationsFieldsStyles = {
  mb: 25,
  width: 220,
};

const getOptionBackground = (status: Object) => G.ifElse(
  R.equals(status, GC.STOP_STATUS_TYPE_COMPLETED),
  G.getTheme('colors.lightGrey'),
  'none',
);

const getOptionHoverBackground = (status: Object) => G.ifElse(
  R.equals(status, GC.STOP_STATUS_TYPE_COMPLETED),
  'none',
  G.getTheme('colors.reactSelectOptionHoverBgColor'),
);

const renderOptionComponent = ({ data, selectOption }: Object) => {
  const { label, status } = data;

  return (
    <BoxHovered
      p='8px 10px'
      cursor='pointer'
      onClick={() => selectOption(data)}
      background={getOptionBackground(status)}
      hoverBackgroundColor={getOptionHoverBackground(status)}
    >
      {label}
    </BoxHovered>
  );
};

export const getStatusMessageFields = (props: Object) => {
  const { setFieldValue, handleCleanForm, statusMessagesConfigs } = props;

  const fields = [
    {
      isRequired: true,
      isClearable: true,
      type: 'datePicker',
      timeSelection: true,
      label: ['titles:date', 'Date'],
      fieldName: GC.FIELD_STATUS_MESSAGE_DATE,
      inputWrapperStyles: statusMessageFieldsStyles,
    },
    {
      isRequired: true,
      type: 'reactSelect',
      shouldCustomChange: true,
      options: GC.FIELD_STATUS_MESSAGE_CODE,
      fieldName: GC.FIELD_STATUS_MESSAGE_CODE,
      label: ['titles:status-code', 'Status Code'],
      inputWrapperStyles: { zIndex: 11, ...statusMessageFieldsStyles },
      customChangeHandler: (value: string) => {
        if (R.isNil(value)) {
          setFieldValue(GC.FIELD_STATUS_MESSAGE_REASON_CODE, null);
        }

        if (G.isNotNilAndNotEmpty(value)) {
          const selectedStatusMessage = statusMessagesConfigs.find(
            (item: Object) => (
              R.or(
                R.equals(value, item.parentGuid),
                R.equals(value, item.guid),
              )
            ),
          );

          if (G.isNilOrEmpty(R.prop('reasonCodes', selectedStatusMessage))) {
            setFieldValue(GC.FIELD_STATUS_MESSAGE_REASON_CODE, null);
          }
        }

        setFieldValue(GC.FIELD_STATUS_MESSAGE_CODE, value);
      },
    },
    {
      type: 'reactSelect',
      options: 'stopOptions',
      shouldCustomChange: true,
      optionComponent: renderOptionComponent,
      inputWrapperStyles: statusMessageFieldsStyles,
      label: ['titles:select-stops', 'Select Stops...'],
      fieldName: GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID,
      components: {
        Option: renderOptionComponent,
      },
    },
  ];

  const statusMessageReasonCode = {
    type: 'select',
    options: 'statusReasonCodeOptions',
    inputWrapperStyles: statusMessageFieldsStyles,
    fieldName: GC.FIELD_STATUS_MESSAGE_REASON_CODE,
    label: ['titles:status-reason-code', 'Status Reason Code'],
  };

  const statusMessageProofType = {
    version: 2,
    type: 'multiswitch',
    shouldCustomChange: true,
    inputWrapperStyles: {
      ...statusMessageFieldsStyles,
      display: 'flex',
      justifyContent: 'center',
    },
    customChangeHandler: (value: string) => handleCleanForm(value),
    fieldName: GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE,
    options: [
      {
        width: 100,
        value: 'NONE',
        name: 'General',
      },
      {
        width: 80,
        name: GC.DOCUMENT_PROOF_TYPE_POP,
        value: GC.DOCUMENT_PROOF_TYPE_POP,
      },
      {
        width: 80,
        name: GC.DOCUMENT_PROOF_TYPE_POD,
        value: GC.DOCUMENT_PROOF_TYPE_POD,
      },
    ],
  };

  let statusMessageFields = [...fields];

  if (G.notEquals(props.entityName, GC.FIELD_CLO)) {
    statusMessageFields = R.insert(1, statusMessageProofType, fields);
  }

  if (props.isShowStatusReasonCode) {
    statusMessageFields = R.insert(3, statusMessageReasonCode, statusMessageFields);
  }

  return statusMessageFields;
};

export const getLocationFields = ({ values }: Object) => {
  const isDisabledLocationSearch = G.isNotNilAndNotEmpty(values.loadEventGuid);

  return [
    {
      type: 'addressAutocomplete',
      fieldName: GC.FIELD_ADDRESS_1,
      disabled: isDisabledLocationSearch,
      label: ['titles:address', 'Address'],
      inputWrapperStyles: statusMessageFieldsStyles,
    },
    {
      isRequired: true,
      fieldName: GC.FIELD_CITY,
      type: 'addressAutocomplete',
      label: ['titles:city', 'City'],
      disabled: isDisabledLocationSearch,
      inputWrapperStyles: locationsFieldsStyles,
    },
    {
      type: 'text',
      isRequired: true,
      fieldName: GC.FIELD_STATE,
      label: ['titles:state', 'State'],
      disabled: isDisabledLocationSearch,
      inputWrapperStyles: locationsFieldsStyles,
    },
    {
      type: 'text',
      fieldName: GC.FIELD_ZIP,
      label: ['titles:zip', 'Zip'],
      disabled: isDisabledLocationSearch,
      inputWrapperStyles: locationsFieldsStyles,
    },
    {
      isRequired: true,
      type: 'countrySelect',
      fieldName: GC.FIELD_COUNTRY,
      disabled: isDisabledLocationSearch,
      label: ['titles:country', 'Country'],
      inputWrapperStyles: locationsFieldsStyles,
    },
    {
      type: 'text',
      disabled: true,
      isRequired: true,
      fieldName: GC.FIELD_LATITUDE,
      label: ['titles:latitude', 'Latitude'],
      inputWrapperStyles: locationsFieldsStyles,
    },
    {
      type: 'text',
      disabled: true,
      isRequired: true,
      fieldName: GC.FIELD_LONGITUDE,
      label: ['titles:longitude', 'Longitude'],
      inputWrapperStyles: locationsFieldsStyles,
    },
  ];
};

export const initialGoogleStatusMessageFields = {
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_ADDRESS_1]: '',
  [GC.FIELD_LONGITUDE]: '',
};

export const defaultStatusMessageFields = {
  ...initialGoogleStatusMessageFields,
  [GC.FIELD_STATUS_MESSAGE_CODE]: '',
  [GC.FIELD_STATUS_MESSAGE_DATE]: null,
  [GC.FIELD_STATUS_MESSAGE_REASON_CODE]: '',
  [GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID]: null,
};

export const statusMessageFieldsValidationSchema = Yup.object().shape({
  [GC.FIELD_STATUS_MESSAGE_DATE]: G.yupStringRequired,
  [GC.FIELD_STATUS_MESSAGE_CODE]: G.yupStringRequired,
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(2, G.getShouldBeFromToLocaleTxt(2, 70))
    .max(70, G.getShouldBeFromToLocaleTxt(2, 70)),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(2, G.getShouldBeFromToLocaleTxt(2, 70))
    .max(70, G.getShouldBeFromToLocaleTxt(2, 70)),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(2, G.getShouldBeFromToLocaleTxt(2, 70))
    .max(70, G.getShouldBeFromToLocaleTxt(2, 70)),
  [GC.FIELD_LATITUDE]: G.yupStringRequired,
  [GC.FIELD_LONGITUDE]: G.yupStringRequired,
});

const crossBorderIntegrationFieldStyles = { width: 270 };

// cross border integration
export const getCrossBorderIntegrationFields = (manifestType: string) => {
  const disabled = G.isNilOrEmpty(manifestType);

  const fields = [
    {
      isRequired: true,
      type: 'reactSelect',
      fieldName: GC.FIELD_INTEGRATION_TYPE,
      options: crossBorderIntegrationTypeOptions,
      label: ['titles:integration-type', 'Integration Type'],
      inputWrapperStyles: crossBorderIntegrationFieldStyles,
    },
    {
      isRequired: true,
      type: 'reactSelect',
      options: manifestTypeOptions,
      fieldName: GC.FIELD_MANIFEST_TYPE,
      label: ['titles:manifest-type', 'Manifest Type'],
      inputWrapperStyles: crossBorderIntegrationFieldStyles,
    },
    {
      fieldName: GC.FIELD_TEL_NUMBER,
      label: ['titles:tel-number', 'Trip Number'],
      inputWrapperStyles: crossBorderIntegrationFieldStyles,
    },
    {
      isClearable: true,
      type: 'datePicker',
      timeSelection: true,
      fieldName: GC.FIELD_ESTIMATED_ARRIVAL_DATE_TIME,
      inputWrapperStyles: crossBorderIntegrationFieldStyles,
      label: ['titles:estimated-arrival-date-time', 'Estimated Arrival Date/Time'],
    },
    {
      disabled,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      options: 'entryPortOptions',
      fieldName: GC.FIELD_ENTRY_PORT,
      label: ['titles:entry-port', 'Entry Port'],
      inputWrapperStyles: crossBorderIntegrationFieldStyles,
    },
    {
      disabled,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      options: 'releaseOfficeOptions',
      fieldName: GC.FIELD_RELEASE_OFFICE,
      label: ['titles:release-office', 'Release Office'],
      inputWrapperStyles: crossBorderIntegrationFieldStyles,
    },
  ];

  if (R.equals(manifestType, GC.ACE)) return R.dropLast(1, fields);

  return fields;
};

export const getDefaultCrossBorderIntegrationFields = (cloList: array) => {
  const country = R.compose(
    R.path(['firstEvent', 'location', 'country']),
    R.last,
  )(cloList);

  const manifestType = G.ifElse(R.equals(country, 'United States'), GC.ACE, GC.ACI);

  return {
    [GC.FIELD_TEL_NUMBER]: '',
    [GC.FIELD_ENTRY_PORT]: null,
    [GC.FIELD_RELEASE_OFFICE]: null,
    [GC.FIELD_MANIFEST_TYPE]: manifestType,
    [GC.FIELD_ESTIMATED_ARRIVAL_DATE_TIME]: '',
    [GC.FIELD_INTEGRATION_TYPE]: GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT,
  };
};

const getTelNumberValidation = (saveAndSend: boolean) => G.ifElse(
  G.isTrue(saveAndSend),
  G.yupStringRequired,
  G.yupStringNotRequired,
);

export const getCrossBorderIntegrationFieldsValidationSchema = (props: Object) => {
  const { saveAndSend, manifestType, integrationType } = props;

  const schema = {
    [GC.FIELD_ENTRY_PORT]: G.yupStringRequired,
    [GC.FIELD_MANIFEST_TYPE]: G.yupStringRequired,
    [GC.FIELD_INTEGRATION_TYPE]: G.yupStringRequired,
    [GC.FIELD_ESTIMATED_ARRIVAL_DATE_TIME]: G.yupStringRequired,
    [GC.FIELD_RELEASE_OFFICE]: R.and(G.isTrue(saveAndSend), R.equals(manifestType, GC.ACI))
      ? G.yupStringRequired
      : G.yupStringNotRequired,
    [GC.FIELD_TEL_NUMBER]: R.equals(integrationType, GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT)
      ? getTelNumberValidation(saveAndSend)
        .min(8, G.getShouldBeFromToLocaleTxt(8, 25))
        .max(25, G.getShouldBeFromToLocaleTxt(8, 25))
      : getTelNumberValidation(saveAndSend),
  };

  if (G.isFalse(saveAndSend)) {
    return R.pick([GC.FIELD_TEL_NUMBER, GC.FIELD_MANIFEST_TYPE, GC.FIELD_INTEGRATION_TYPE], schema);
  }

  return schema;
};
