import * as R from 'ramda';
import React from 'react';
import { Field } from 'redux-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Info } from '../../../forms';
// ui
import { Box, Flex } from '../../../ui';
// feature configs
import * as H from '../helpers';
import {
  renderFormFieldComponent,
  ConfigGroupLeftSectionWrapper,
  ConfigGroupRightSectionWrapper,
  ConfigGroupRightSectionFieldGroupWrapper,
} from '../ui';
import CustomConfigGroupComponent from './custom-config-group';
import DropdownConfigGroupComponent from './dropdown-config-group';
//////////////////////////////////////////////////

const getGoogleApiKeyInheritedValue = (inheritedValue: string) => {
  if (G.isNilOrEmpty(inheritedValue)) return null;

  if (G.getIsAmousUserSuperAdminFromWindow()) return inheritedValue;

  return `${G.getWindowLocale('titles:parent-value', 'Parent value')}: ...${R.takeLast(6, inheritedValue)}`;
};

const getInheritedValueLabelFromConfigOptions = (props: Object, options: Array) => {
  const { inheritedValues, nameForAttribute } = props;

  const inheritedValue = R.pathOr([], [nameForAttribute], inheritedValues);

  const value = R.compose(
    R.join(', '),
    R.reject((item: Object) => G.isNilOrEmpty(item)),
    R.map((item: string) => {
      const itemFromOptions = R.find(R.propEq(item, GC.FIELD_VALUE), options);

      return R.path([GC.FIELD_LABEL], itemFromOptions);
    }),
  )(R.or(inheritedValue, []));

  return value;
};

const renderInheritedValue = (props: Object, options: Array | undefined) => {
  const {
    type,
    inheritedValues,
    guidsToItemsMap,
    nameForAttribute,
    hideInheritedValue,
    shouldGetInheritedValueFromConfigOptions,
  } = props;

  if (G.isTrue(hideInheritedValue)) return null;

  let inheritedValue = R.prop(nameForAttribute, inheritedValues);
  const valueFromMap = R.pathOr(null, [inheritedValue], guidsToItemsMap);

  if (R.equals(nameForAttribute, GC.GENERAL_DC_GOOGLE_MAPS_API_KEY)) {
    return getGoogleApiKeyInheritedValue(inheritedValue);
  }

  if (shouldGetInheritedValueFromConfigOptions) {
    inheritedValue = getInheritedValueLabelFromConfigOptions(props, options);
  } else if (R.and(R.equals(type, 'list'), R.is(Array, inheritedValue))) {
    const mappedValue = R.map(
      (item: string) => R.pathOr(null, [item], guidsToItemsMap),
      inheritedValue,
    );
    inheritedValue = G.createStringFromArray(mappedValue, ', ');
  } else if (G.isNotNil(valueFromMap)) {
    inheritedValue = valueFromMap;
  }

  if (G.isNotNilAndNotEmpty(options)) {
    const indexedOptions = R.indexBy(({ guid, value }: Object) => R.or(value, guid), options);

    inheritedValue = R.pathOr(inheritedValue, [inheritedValue, 'label'], indexedOptions);

    if (R.and(
      R.or(
        R.equals(nameForAttribute, GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_STATUS),
        R.equals(nameForAttribute, GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES),
      ),
      G.isNotNilAndNotEmpty(R.prop(nameForAttribute, inheritedValues),
    ))) {
      const mappedValue = R.map(
        (item: Object) => R.path([item, GC.FIELD_DISPLAYED_VALUE], indexedOptions),
        R.prop(nameForAttribute, inheritedValues),
      );
      inheritedValue = G.createStringFromArray(mappedValue, ', ');
    }
  }

  if (G.isNotNilAndNotEmpty(inheritedValue)) {
    return (
      <Box
        p='3px'
        mr='25px'
        fontSize='12px'
        borderRadius='2px'
        bg={G.getTheme('colors.lightGrey')}
      >
        {`${G.getWindowLocale('titles:parent-value', 'Parent value')}: ${inheritedValue}`}
      </Box>
    );
  }

  return null;
};

const setFieldComponent = ({ type, component }: Object) => {
  if (R.or(R.equals(type, 'list'), R.equals(type, 'customComponent'))) {
    return component;
  }

  return renderFormFieldComponent(type);
};

const FieldItem = (props: Object) => {
  const { infoText, fieldLabel, nameForAttribute, additionalComponent } = props;

  const options = H.setFieldSelectOptions(props);

  return (
    <ConfigGroupRightSectionFieldGroupWrapper>
      {
        G.isNotNil(fieldLabel) &&
        <Box mr='15px' fontSize='15px'>
          {R.or(G.getWindowLocale(fieldLabel), fieldLabel)}
        </Box>
      }
      <Field
        {...props}
        options={options}
        name={nameForAttribute}
        component={setFieldComponent(props)}
      />
      {
        G.isNotNil(infoText) &&
        <Box mr={15}>
          <Info text={infoText} />
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(additionalComponent) && additionalComponent(props)
      }
      {renderInheritedValue(props, options)}
    </ConfigGroupRightSectionFieldGroupWrapper>
  );
};

const renderFieldItems = (fields: Object, props: Object) => (
  <ConfigGroupRightSectionWrapper p='0px 15px'>
    {
      R.map((item: Object) => (
        <FieldItem
          {...item}
          {...props}
          key={item.name}
        />
      ), R.values(fields))
    }
  </ConfigGroupRightSectionWrapper>
);

const ConfigGroupItemComponent = (props: Object) => {
  const { marginBottom, initialValues, inheritedValues, nameForAttribute, hideForNotSuperAdmin } = props;

  if (R.and(G.isTrue(hideForNotSuperAdmin), R.not(G.getIsAmousUserSuperAdminFromWindow()))) return null;

  const notExistedConfig = G.checkIsConfigNotExisted(nameForAttribute, initialValues, inheritedValues);

  if (notExistedConfig) return null;

  return (
    <Flex alignItems='stretch' mb={marginBottom}>
      <ConfigGroupLeftSectionWrapper>
        {G.getWindowLocale(props.name)}
      </ConfigGroupLeftSectionWrapper>
      <ConfigGroupRightSectionWrapper p='0px 15px'>
        <FieldItem {...props} />
      </ConfigGroupRightSectionWrapper>
    </Flex>
  );
};

const ConfigGroupMultiItemsComponent = (props: Object) => (
  <div>
    {
      R.map((item: Object) => (
        <Flex key={item.title} alignItems='stretch'>
          <ConfigGroupLeftSectionWrapper>
            {G.getWindowLocale(item.title)}
          </ConfigGroupLeftSectionWrapper>
          {renderFieldItems(item.fields, props)}
        </Flex>
      ), R.values(props.fields))
    }
  </div>
);

const ConfigGroupMultiFieldsComponent = (props: Object) => (
  <Flex alignItems='stretch'>
    <ConfigGroupLeftSectionWrapper>
      {G.getWindowLocale(props.title)}
    </ConfigGroupLeftSectionWrapper>
    {renderFieldItems(props.fields, props)}
  </Flex>
);

export const renderConfigGroupItemComponent = (props: Object) => {
  const { fields, component, dropdowns, configType, configName, multiItems, multiFields } = props;

  if (R.equals(configType, 'dropdown')) {
    if (G.isNotNil(dropdowns[configName])) {
      return (
        <DropdownConfigGroupComponent
          {...props}
          dropdownConfig={H.getConfigValueFromGroupStore(dropdowns, configName)}
        />
      );
    }

    return null;
  }

  if (R.equals(configType, 'customComponent')) {
    return component(props);
  }

  if (R.equals(configType, 'custom')) {
    return (
      <CustomConfigGroupComponent {...props} />
    );
  }

  if (R.equals(multiItems, true)) {
    return (
      <ConfigGroupMultiItemsComponent {...props} />
    );
  }

  if (R.equals(multiFields, true)) {
    return (
      <ConfigGroupMultiFieldsComponent {...props} />
    );
  }

  return (
    <div>
      {
        R.map((item: Object) => (
          <ConfigGroupItemComponent
            {...item}
            {...props}
            key={`${item.nameForAttribute}${item.name}`}
          />
        ), R.values(fields))
      }
    </div>
  );
};
