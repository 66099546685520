import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// utilities
import { sendRequest } from '../../utilities/http';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature import-report
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)))
    ) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      currentEnterprise,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.importReportList, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleDeleteItemsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      successAction: A.deleteItemsSuccess,
      endpoint: endpointsMap.importReport,
      parentSagaName: 'deleteItemsRequest',
    });

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemsRequest exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.IMPORT_REPORT_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });


function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_IMPORT_REPORT_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* importReportWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.deleteItemsRequest, handleDeleteItemsRequest);
  yield takeLatest(GC.VISIT_IMPORT_REPORT_PAGE, handleVisitPageSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default importReportWatcherSaga;
