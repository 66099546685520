import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// icons
import * as I from '../../../svgs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
// feature carrier
import { withAsyncGetParentBranches } from '../hocs';
//////////////////////////////////////////////////

const ChangeBranchForm = withAsyncGetParentBranches(SelectDropdownForm);

const enhance = compose(
  withHandlers({
    handleChangeBranch: (props: Object) => () => {
      const { carrier, openModal, closeModal, closeFixedPopup } = props;

      const component = (
        <ChangeBranchForm
          cancelAction={closeModal}
          guid={G.getGuidFromObject(carrier)}
          carrierBranchGuid={G.getPropFromObject(GC.BRANCH_GUID, carrier)}
          fieldLabel={G.getWindowLocale('titles:select-branch', 'Select Branch')}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:change-branch', 'Change Branch'),
        },
      };

      openModal(modal);
      closeFixedPopup();
    },
    handleDelete: (props: Object) => () => {
      const confirmationContent = (
        <ConfirmComponent
          textLocale={
            G.getWindowLocale('messages:delete-confirmation-carrier', 'Are you sure you want delete carrier?')
          }
        />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteCarrierRequest(props.carrier.guid);
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

const iconColor = G.getTheme('icons.iconColor');

const getOptions = (props: Object) => ([
  {
    frontIcon: I.pencil(iconColor),
    permissions: [PC.CARRIER_WRITE],
    text: G.getWindowLocale('actions:edit', 'Edit'),
    action: () => G.goToCarrierProfileByConfig(props.carrier.guid),
  },
  {
    action: props.handleDelete,
    frontIcon: I.trash(iconColor),
    permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
    text: G.getWindowLocale('actions:delete', 'Delete'),
  },
  {
    permissions: [PC.CARRIER_WRITE],
    frontIcon: I.company(iconColor),
    action: props.handleChangeBranch,
    text: G.getWindowLocale('titles:change-branch', 'Change Branch'),
  },
  {
    frontIcon: I.gear(iconColor),
    permissions: [PC.CARRIER_WRITE],
    text: G.getWindowLocale('action:send-to-integration', 'Send to Integration'),
    action: () => props.handleSendCarrierListToIntegration(
      R.path([GC.SYSTEM_OBJECT_CARRIER, GC.FIELD_GUID], props),
    ),
  },
  {
    frontIcon: I.gear(iconColor),
    permissions: [PC.CARRIER_WRITE],
    action: props.handleOpenLoadHistoryByCarrierGuid,
    text: G.getWindowLocale('titles:bid-history', 'Bid History'),
  },
  {
    permissions: [PC.CARRIER_WRITE],
    frontIcon: I.analytic(iconColor),
    action: props.handleOpenLoadStatisticByCarrierGuid,
    text: G.getWindowLocale('titles:statistic', 'Statistic'),
  },
  {
    frontIcon: I.scorecards(iconColor, 15, 15),
    permissions: [PC.CARRIER_READ, PC.CARRIER_WRITE],
    action: props.handleOpenCarrierScorecardsByCarrierGuid,
    text: G.getWindowLocale('titles:scorecards', 'Scorecards'),
  },
]);

const ElementActionComponent = (props: Object) => (
  <ActionsElement options={getOptions(props)} />
);

export default enhance(ElementActionComponent);
