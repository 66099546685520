import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet-profile
import SimpleForm from '../forms';
//////////////////////////////////////////////////

export const getCreateOrUpdateItemModal = (props: Object) => {
  const {
    fleetType,
    openModal,
    closeModal,
    CustomForm,
    openLoader,
    isEditMode,
    closeLoader,
    submitAction,
    itemTitleArr,
    fieldSettings,
    initialValues,
    validationSchema,
    primaryObjectGuid,
    fieldsWrapperStyles,
    makeOptionsForSelect,
    primaryObjectGuidKey,
    primaryObjectBranchGuid,
    modalAdditionalOptions = {},
  } = props;

  let optionsForSelect = {};

  if (G.isFunction(makeOptionsForSelect)) optionsForSelect = makeOptionsForSelect(props);

  let component;

  if (G.isNotNil(CustomForm)) {
    component = (
      <CustomForm
        fleetType={fleetType}
        openModal={openModal}
        openLoader={openLoader}
        closeModal={closeModal}
        isEditMode={isEditMode}
        closeLoader={closeLoader}
        submitAction={submitAction}
        initialValues={initialValues}
        optionsForSelect={optionsForSelect}
        primaryObjectGuid={primaryObjectGuid}
        primaryObjectGuidKey={primaryObjectGuidKey}
        primaryObjectBranchGuid={primaryObjectBranchGuid}
      />
    );
  } else {
    component = (
      <SimpleForm
        closeModal={closeModal}
        submitAction={submitAction}
        fieldSettings={fieldSettings}
        initialValues={initialValues}
        validationSchema={validationSchema}
        optionsForSelect={optionsForSelect}
        fieldsWrapperStyles={fieldsWrapperStyles}
      />
    );
  }

  let title;

  if (G.isNotNil(itemTitleArr)) title = G.ifElse(isEditMode, G.getEditTitle, G.getAddTitle)(itemTitleArr);

  return {
    p: 15,
    component,
    options: { title },
    ...modalAdditionalOptions,
  };
};
