import styled, { css } from 'styled-components';
import { space, width, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// forms
import { FormGroupTitle } from '../../forms';
import { renderBorderColor } from '../../forms/helpers';
// ui
import { Flex } from '../../ui';
//////////////////////////////////////////////////

// REFACTOR z-index
export const RightTitlePanel = styled.div`
  z-index: 11;
  display: flex;
  align-items: center;

  & #dropdown-user-export {
    & > div > img {
      height: 20px;
    }
  }
`;

export const GeneralGroup = styled.div`
  background: linear-gradient(
    to right,
    ${() => G.getTheme('pages.layOutBgColor')} 0%
    ${() => G.getTheme('forms.labelsWrapper.bgColor')} 320px,
   );

  z-index: ${({ zI }: Object) => zI};
`;

export const Title = styled.div`
  ${space}
  ${fontSize}

  margin: 5px;
  font-weight: bold;
`;

export const FormWrapper = styled.div`
  & + div {
    padding: 5px;
    position: static;
  }
`;

export const errorClass = css`
  bottom: 0;
  right: 10px;
  position: absolute;
`;

export const formGroupPosition = css`
  position: relative;
`;

export const formGroupPositionResponsePage = css`
  width: 100%;
  color: white;
  position: relative;
`;

export const PrimaryWrapper = styled.div`
  font-weight: bold;

  color: ${() => G.getTheme('colors.light.green')}
`;

export const ResponseBox = styled(Flex)`
  padding: 30px 48px;
  position: relative;

  font-size: 35px;
  font-weight: bold;
  text-align: center;

  color: ${({ color }: Object) => color};

  &::after {
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
    width: 100%;
    opacity: 0.8;
    height: 100%;
    position: absolute;
    border: 3.5px solid;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-color: ${({ color }: Object) => color};
    background-image: linear-gradient(119deg, #350828, #2c594d);
  }
`;

export const LocationFieldsWrapper = styled.div`
  width: 50%;
  border-radius: 8px;
  background-color: lightgrey;
  & + div {
    margin-left: 16px;
  }
`;

export const CarrierRateForm = styled.div`
  max-height: 90vh;
  overflow: auto;
  & form {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const CustomFormGroupTitle = styled(FormGroupTitle)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  & #dropdown-branch-filter {
    height: 20px;
  }
  & #dropdown-branch-filter + div {
    color: ${() => G.getTheme('downFilter.textColor')};
  }
  & div.react-datepicker-popper {
    z-index: 12;
    left: auto !important;
    right: -15px !important;
    &[data-placement^="bottom"] {
      width: 300px !important;
    }
  }
`;
