import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../../../components/text';
// features
import { makeSelectSortedByNamePinnedBranches } from '../../profile/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature branch
import { switchBranchRequest } from '../actions';
//////////////////////////////////////////////////

const PinnedBranches = (props: Object) => {
  const { pinnedList, switchBranchRequest } = props;

  if (G.isNilOrEmpty(pinnedList)) return null;

  const darkGreyColor = G.getTheme('colors.light.darkGrey');

  return (
    <Box p={15} width={230} height='100%'>
      <Box p='6px 0' fontWeight='bold'>
        {G.getWindowLocale('titles:pinned-branches', 'Pinned Branches')}
      </Box>
      <Box overflow='auto' height='calc(100% - 30px)'>
        {
          pinnedList.map((branch: Object) => {
            const { guid, enterpriseName, enterpriseType } = branch;

            return (
              <Flex
                p='4px 0'
                key={guid}
                cursor='pointer'
                onClick={() => switchBranchRequest(guid)}
              >
                <Box
                  mr='4px'
                  color={darkGreyColor}
                  title={enterpriseType}
                >
                  {R.pathOr(enterpriseType, [enterpriseType], GC.branchTypeDisplayMap)}
                </Box>
                <TextComponent
                  maxWidth={200}
                  display='block'
                  withEllipsis={true}
                  title={enterpriseName}
                >
                  {enterpriseName}
                </TextComponent>
              </Flex>
            );
          })
        }
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  pinnedList: makeSelectSortedByNamePinnedBranches(state),
});

export default connect(mapStateToProps, {
  switchBranchRequest,
})(PinnedBranches);
