import * as R from 'ramda';
import randomColor from 'randomcolor';
import { connect } from 'react-redux';
import { useScroll } from 'react-use';
import { createStructuredSelector } from 'reselect';
import React, { memo, useRef, useMemo, useState, useEffect } from 'react';
import { compose, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { pickerColorGrey } from '../../../components/color-picker';
import PopperColorPicker from '../../../components/color-picker/popper-color-picker';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withHoveredZIndex } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  RelativeBox,
  AbsoluteBox,
  IconWrapper,
  scrollableContainerCss3px,
} from '../../../ui';
// utilities
import T from '../../../theme';
// features drivers-card
import { makeSelectZoom } from '../selectors';
import { withCardNotes } from '../hocs/with-card-notes';
import { PICKER_COLOR_TO_TEXT_COLOR_MAP } from '../constants';
import { getClosInfo, getCardWidth, getCardLeftPosition } from './card';
import { getMainCardWidth, getCardActionColor, getCardDayMultiplier } from '../helpers';
import { withTelDriverCardColor, withConnectTripDriverCardColor } from '../hocs/with-tel-driver-card-color';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const mainLightColor = G.getTheme('colors.light.mainLight');
const lightDarkGreyColor = G.getTheme('colors.light.darkGrey');

const getEventLocationInfo = (firstEvent: Object, lastEvent: Object) => {
  const firstEventCity = R.path(['location', GC.FIELD_CITY], firstEvent);

  const firstEventState = G.getShortStateFromLocation(firstEvent.location);

  const firstEventLocationInfo = G.createStringFromArray([firstEventCity, firstEventState], ', ');

  const lastEventCity = R.path(['location', GC.FIELD_CITY], lastEvent);

  const lastEventState = G.getShortStateFromLocation(lastEvent.location);

  const lastEventLocationInfo = G.createStringFromArray([lastEventCity, lastEventState], ', ');

  return { firstEventLocationInfo, lastEventLocationInfo };
};

const getHeaderInfoMaxWidth = (widthToUse: number, primaryReferenceValue: string) => {
  const telTextWidth = G.getStringWidth(primaryReferenceValue, 12);

  const headerEmptyWidth = R.subtract(widthToUse, 100);

  const headerHalfEmptyWidth = R.divide(headerEmptyWidth, 2);

  const closInfoMaxWidth = R.lte(telTextWidth, headerHalfEmptyWidth) ?
    R.subtract(R.subtract(headerEmptyWidth, telTextWidth), 20) : R.subtract(headerHalfEmptyWidth, 20);

  const telInfoMaxWidth = R.add(R.subtract(headerEmptyWidth, closInfoMaxWidth), 10);

  return { telInfoMaxWidth, closInfoMaxWidth };
};

const getTimeFromFirstEvent = (firstEvent: Object) => {
  const {
    fcfs,
    lateDate,
    earlyDate,
    appointmentDate,
    appointmentLateDate,
    appointmentEarlyDate,
  } = firstEvent;

  const earlyTime = G.getLocalTimeFromDate(earlyDate);
  const lateTime = G.getLocalTimeFromDate(lateDate);

  if (G.isTrue(fcfs)) {
    return `${earlyTime} - ${lateTime}`;
  }

  if (G.isNotNilAndNotEmpty(appointmentDate)) {
    const earlyTime = G.getLocalTimeFromDate(appointmentEarlyDate);
    const lateTime = G.getLocalTimeFromDate(appointmentLateDate);

    return `${earlyTime} - ${lateTime}`;
  }

  return earlyTime;
};

const getTimeFromLastEvent = (lastEvent: Object) => {
  const {
    fcfs,
    lateDate,
    earlyDate,
    appointmentDate,
    appointmentLateDate,
    appointmentEarlyDate,
  } = lastEvent;

  const earlyTime = G.getLocalTimeFromDate(earlyDate);
  const lateTime = G.getLocalTimeFromDate(lateDate);

  if (G.isTrue(fcfs)) {
    return `${earlyTime} - ${lateTime}`;
  }

  if (G.isNotNilAndNotEmpty(appointmentDate)) {
    const earlyTime = G.getLocalTimeFromDate(appointmentEarlyDate);
    const lateTime = G.getLocalTimeFromDate(appointmentLateDate);

    return `${earlyTime} - ${lateTime}`;
  }

  return lateTime;
};

const getFirstLastEventTime = (firstEvent: Object, lastEvent: Object) => {
  const firstEventTime = getTimeFromFirstEvent(firstEvent);
  const lastEventTime = getTimeFromLastEvent(lastEvent);

  return { lastEventTime, firstEventTime };
};

const enhance = compose(
  withCardNotes,
  withTelDriverCardColor,
  withConnectTripDriverCardColor,
  withPropsOnChange(['guid'], () => ({ focusRandomColor: randomColor() })),
  withHandlers({
    onClickOpenTelDetails: (props: Object) => () => {
      const { editable, handleOpenTelDetails } = props;

      if (G.isFalse(editable)) return;

      handleOpenTelDetails(props);
    },
    onClickEditIcon: (props: Object) => (e: Object) => props.handleClickEditIcon(e, props, true),
    onClickShowEvents: (props: Object) => () => {
      const { guid, enterprise, handleShowEvents } = props;

      const branchGuid = R.pathOr('', [GC.FIELD_GUID], enterprise);

      handleShowEvents(guid, branchGuid);
    },
  }),
);

const HybridCard = enhance(memo((props: Object) => {
  const {
    clos,
    zoom,
    guid,
    notes,
    width,
    status,
    groupBy,
    editable,
    groupGuid,
    cardIndex,
    lastEvent,
    routeGuid,
    driverGuid,
    cardHeight,
    firstEvent,
    filtersStore,
    hoveredZIndex,
    handleNoteClick,
    driverCardColor,
    show24HoursView,
    onClickEditIcon,
    focusRandomColor,
    expandedContainer,
    onClickShowEvents,
    handleZIndexOnHover,
    onClickOpenTelDetails,
    handleZIndexOnUnHover,
    primaryReferenceValue,
    expandedContainerOptions,
    updateTelDriverCardColor,
    updateTripDriverCardColorSuccess,
  } = props;

  const [colorValue, setColorValue] = useState(driverCardColor);

  const colorValueToUse = R.or(colorValue, pickerColorGrey);

  const scrollRef = useRef(null);

  const { x } = useScroll(scrollRef);

  const headerWidth = `calc(100% + ${x}px)`;

  useEffect(() => {
    setColorValue(driverCardColor);
  }, [driverCardColor]);

  const changeColorCallback = (color: string) => {
    updateTelDriverCardColor(color, () => {
      setColorValue(color);
      updateTripDriverCardColorSuccess({ color, groupBy, groupGuid, driverGuid, tripGuid: guid });
    });
  };

  const zIndex = R.subtract(1000, cardIndex);

  const mainCardWidth = useMemo(() => getMainCardWidth(show24HoursView, zoom), [show24HoursView, zoom]);

  const cardDayMultiplier = useMemo(() => getCardDayMultiplier(show24HoursView, zoom), [show24HoursView, zoom]);

  const lastEventDate = R.prop('lateDate', lastEvent);
  const firstEventDate = R.prop('earlyDate', firstEvent);

  const { firstEventLocationInfo, lastEventLocationInfo } = useMemo(() => getEventLocationInfo(
    firstEvent,
    lastEvent,
  ), [firstEvent, lastEvent]);

  const { firstEventTime, lastEventTime } = useMemo(() => getFirstLastEventTime(
    firstEvent,
    lastEvent,
  ), [firstEvent, lastEvent]);

  const statusBorderColor = R.path(['status', status], T);

  const widthToUse = useMemo(() => getCardWidth(
    width,
    firstEventDate,
    lastEventDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  ), [
    width,
    firstEventDate,
    lastEventDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  ]);

  if (R.and(R.equals(width, 'calculate'), G.isNilOrEmpty(widthToUse))) return null;

  const left = useMemo(() => getCardLeftPosition(
    true,
    filtersStore,
    firstEventDate,
    mainCardWidth,
    cardDayMultiplier,
  ), [
    filtersStore,
    firstEventDate,
    mainCardWidth,
    cardDayMultiplier,
  ]);

  const fromPage = R.path(['options', 'fromPage'], expandedContainerOptions);

  const notEditableFromExpanded = R.and(
    expandedContainer,
    R.or(
      R.and(R.equals(fromPage, GC.PAGE_DISPATCH_PLANNER), R.isNil(routeGuid)),
      R.and(R.equals(fromPage, GC.PAGE_DISPATCH_PLANNER_EVENTS), G.isNotNil(routeGuid)),
    ),
  );

  const focusRight = R.add(5, R.multiply(cardIndex, 10));

  const headerTextColor = R.path([colorValueToUse], PICKER_COLOR_TO_TEXT_COLOR_MAP);

  const cardActionColor = useMemo(() => getCardActionColor(colorValueToUse), [colorValueToUse]);

  const { telInfoMaxWidth, closInfoMaxWidth } = useMemo(() => getHeaderInfoMaxWidth(
    widthToUse,
    primaryReferenceValue,
  ), [widthToUse, primaryReferenceValue]);

  const closInfo = useMemo(() => getClosInfo(clos), [clos]);

  return (
    <Flex
      left={left}
      alignItems='start'
      borderRadius='3px'
      border='1px solid'
      bg={mainLightColor}
      position='absolute'
      height={cardHeight}
      flexDirection='column'
      borderLeft='3px solid'
      justifyContent='space-between'
      borderColor={lightDarkGreyColor}
      width={R.or(widthToUse, 'auto')}
      onMouseEnter={handleZIndexOnHover}
      borderLeftColor={statusBorderColor}
      onMouseLeave={handleZIndexOnUnHover}
      zIndex={R.or(hoveredZIndex, zIndex)}
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
    >
      <Box width='100%' height='100%' overflow='auto' ref={scrollRef} css={scrollableContainerCss3px}>
        <Flex
          px='5px'
          height={24}
          fontSize={12}
          borderRadius='1px'
          width={headerWidth}
          bg={colorValueToUse}
          justifyContent='space-between'
        >
          <Flex>
            <Box
              mr='5px'
              cursor='pointer'
              color={headerTextColor}
              textDecoration='underline'
              onClick={onClickOpenTelDetails}
              title={G.getWindowLocale('actions:go-to-tel', 'Go to Trip')}
            >
              <TextComponent
                display='block'
                withEllipsis={true}
                maxWidth={telInfoMaxWidth}
                title={primaryReferenceValue}
              >
                {primaryReferenceValue}
              </TextComponent>
            </Box>
            {
              closInfo &&
                <TextComponent
                  mr='5px'
                  title={closInfo}
                  withEllipsis={true}
                  display='inline-block'
                  color={headerTextColor}
                  maxWidth={closInfoMaxWidth}
                >
                  {closInfo}
                </TextComponent>
            }
          </Flex>
          <Flex>
            <PopperColorPicker
              arrowColor={cardActionColor}
              activeBorder={`1px solid ${cardActionColor}`}
              pickerProps={{
                changeColorCallback,
                value: colorValueToUse,
                wrapperStyles: { width: 150, justifyContent: 'space-between' },
                itemStyles: { width: 20, height: 20, opacity: 0.9, borderRadius: '4px' },
              }}
            />
            <Flex
              cursor='pointer'
              onClick={(e: Object) => handleNoteClick(e)}
              title={G.getWindowLocale('titles:notes', 'Notes')}
            >
              {I.renderFileIcon(G.ifElse(G.isNotNilAndNotEmpty(notes), darkBlueColor, cardActionColor))}
            </Flex>
            {
              R.and(G.isTrue(editable), R.not(notEditableFromExpanded)) &&
              <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
                <IconWrapper
                  pt='3px'
                  ml='3px'
                  cursor='pointer'
                  onClick={onClickEditIcon}
                >
                  {I.threePointer(cardActionColor)}
                </IconWrapper>
              </AuthWrapper>
            }
          </Flex>
        </Flex>
        <Flex
          px='3px'
          width='100%'
          zIndex={zIndex}
          height={R.subtract(cardHeight, 32)}
        >
          <Flex
            width='100%'
            height='100%'
            fontSize={11}
            alignItems='center'
            justifyContent='space-between'
          >
            <Flex alignItems='start' flexDirection='column'>
              <Box>{firstEventLocationInfo}</Box>
              <Box>{firstEventTime}</Box>
            </Flex>
            <Flex mx='5px' flexDirection='column'>
              <Box color={statusBorderColor}>
                {G.getWindowLocale(GC.statusLocaleMap[status])}
              </Box>
              {
                G.isTrue(editable) &&
                <Flex minWidth={75} flexDirection='column'>
                  <Flex
                    cursor='pointer'
                    color={darkBlueColor}
                    onClick={onClickShowEvents}
                  >
                    <Box>{G.getWindowLocale('titles:show-stops', 'Show Stops')}</Box>
                  </Flex>
                </Flex>
              }
            </Flex>
            <Flex alignItems='start' flexDirection='column'>
              <Box>{lastEventLocationInfo}</Box>
              <Box>{lastEventTime}</Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Flex width='100%' justifyContent='flex-end'>
        <RelativeBox>
          <AbsoluteBox
            top='1px'
            width={20}
            height='5px'
            borderRadius='3px'
            right={focusRight}
            bg={focusRandomColor}
          />
        </RelativeBox>
      </Flex>
    </Flex>
  );
}));

const mapStateToProps = (state: Object) => createStructuredSelector({
  zoom: makeSelectZoom(state),
});

export default connect(mapStateToProps, {})(withHoveredZIndex({ zIndex: 1001 })(HybridCard));
