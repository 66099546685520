import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature dashboards-payroll
import * as A from './actions';
import fakeData from './settings/fake-data';
//////////////////////////////////////////////////

const initialState = {
  payroll: [],
};

const getFakeData = (state: Object) => (
  P.$set('payroll', fakeData, state)
);

export default createReducer({
  [A.getFakePayrollData]: getFakeData,
}, initialState);
