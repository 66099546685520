import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'react-recompose';
// features
import * as C from '../../load-board/constants';
import SearchFilterForm from '../../load-board/components/search-filter-form';
import SaveTemplateForm from '../../load-board/components/save-template-form';
import {
  makeSelectTemplates,
  makeSelectAvailableLoadBoards,
} from '../../load-board/selectors';
import {
  saveTemplateRequest,
  deleteTemplateRequest,
  createSearchFilterRequest,
} from '../../load-board/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
/////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  templates: makeSelectTemplates(state),
  availableLoadBoards: makeSelectAvailableLoadBoards(state),
});

export const withPostToLoadBoard = compose(
  connect(mapStateToProps, {
    saveTemplateRequest,
    deleteTemplateRequest,
    createSearchFilterRequest,
  }),
  withHandlers({
    handleSaveTemplate: ({ openModal, closeModal, saveTemplateRequest }: Object) => (data: Object) => {
      const omitFields = [
        GC.FIELD_LOAD_BOARD_DATE_LATEST,
        GC.FIELD_LOAD_BOARD_DATE_EARLIEST,
      ];
      const modal = {
        p: 15,
        component: (
          <SaveTemplateForm
            closeModal={closeModal}
            submit={(values: Object) => saveTemplateRequest(R.omit(omitFields, R.mergeRight(data, values)))}
          />
        ),
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handlePostEquipment: (props: Object) => (load: Object) => {
      const {
        templates,
        openModal,
        closeModal,
        handleSaveTemplate,
        availableLoadBoards,
        createSearchFilterRequest,
      } = props;

      const templateOptions = R.map(
        ({ name, guid }: Object) => ({ label: name, value: guid }),
        templates,
      );

      const loadBoardOptions = R.filter(
        ({ value }: Object) => G.isNotNil(R.find(
          R.propEq(value, GC.FIELD_TYPE),
          availableLoadBoards,
        )),
        GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS,
      );

      const [originCountry, originState] = G.getCountryAndStateByStringValues(R.path(
        [GC.SYSTEM_OBJECT_FIRST_EVENT, GC.SYSTEM_OBJECT_LOCATION],
        load,
      ));

      const [destinationCountry, destinationState] = G.getCountryAndStateByStringValues(R.path(
        [GC.SYSTEM_OBJECT_LAST_EVENT, GC.SYSTEM_OBJECT_LOCATION],
        load,
      ));

      const initialValues = {
        originState,
        originCountry,
        destinationCountry,
        [C.FIELD_ORIGIN_CITY]: R.pathOr(
          '',
          [GC.SYSTEM_OBJECT_FIRST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CITY],
          load,
        ),
        [C.FIELD_DESTINATION_CITY]: R.pathOr(
          '',
          [GC.SYSTEM_OBJECT_LAST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CITY],
          load,
        ),
        [C.FIELD_DESTINATION_STATES]: G.ifElse(G.isNotEmpty(destinationState), [destinationState], []),
        [GC.FIELD_LOAD_BOARD_CAPACITY]: 'FULL',
        [GC.FIELD_LOAD_BOARD_TYPES]: [],
        [C.FIELD_ORIGIN_LATITUDE]: R.pathOr(
          0,
          [GC.SYSTEM_OBJECT_FIRST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE],
          load,
        ),
        [C.FIELD_ORIGIN_LONGITUDE]: R.pathOr(
          0,
          [GC.SYSTEM_OBJECT_FIRST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE],
          load,
        ),
        [C.FIELD_DESTINATION_LATITUDE]: R.pathOr(
          0,
          [GC.SYSTEM_OBJECT_LAST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE],
          load,
        ),
        [C.FIELD_DESTINATION_LONGITUDE]: R.pathOr(
          0,
          [GC.SYSTEM_OBJECT_LAST_EVENT, GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE],
          load,
        ),
      };

      const modal = {
        fixedWidth: true,
        isExpandedContainer: true,
        wrapperStyles: { width: 490 },
        title: G.getWindowLocale('actions:post-equipment', 'Post Equipment'),
        component: (
          <SearchFilterForm
            cancelAction={closeModal}
            initialValues={initialValues}
            saveTemplate={handleSaveTemplate}
            templateOptions={templateOptions}
            loadBoardOptions={loadBoardOptions}
            templates={G.indexByGuid(templates)}
            handleSave={createSearchFilterRequest}
          />
        ),
        options: {
          minWidth: 490,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width: 490, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
  }),
);
