import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../../components/confirm';
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// utilities
import routesMap from '../../../../utilities/routes';
//////////////////////////////////////////////////

// TODO: check moving to global report hocs
const withHandleDeleteReportEntity = withHandlers({
  handleDelete: (props: Object) => () => {
    const {
      entity,
      openModal,
      closeModal,
      closeFixedPopup,
      handleDeleteEntity } = props;
    const modalContent = getConfirmModal({
      cancelAction: closeModal,
      submitAction: () => handleDeleteEntity(entity.guid),
      cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
      submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
      text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
    });
    closeFixedPopup();
    return openModal(modalContent);
  },
});

const enhance = compose(
  withHandleDeleteReportEntity,
  withHandlers({
    handleGoToDetails: ({ entity, closeFixedPopup }: Object) => () => {
      closeFixedPopup();

      const carrierGuid = R.prop(GC.GRC.CARRIER_GUID, entity);

      G.goToCarrierProfileByConfig(carrierGuid);
    },
  }),
  pure,
);

const getOptions = ({
  handleDelete,
  handleGoToDetails,
}: Object) => ([
  {
    action: handleGoToDetails,
    permissions: [PC.CARRIER_READ, PC.CARRIER_WRITE],
    frontIcon: I.routesLoads(G.getTheme('icons.iconColor')),
    text: G.getWindowLocale('actions:go-to-carrier', 'Go to Carrier'),
  },
  {
    action: handleDelete,
    permissions: [PC.CARRIER_WRITE],
    frontIcon: I.trash(G.getTheme('icons.iconColor')),
    text: G.getWindowLocale('actions:delete', 'Delete'),
  },
]);

export const RowActions = enhance((props: Object) => <ActionsElement options={getOptions(props)} />);

