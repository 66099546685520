import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 30,
  width: 250,
};

const fieldSettings = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: G.getWindowLocale('titles:name'),
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_MAX_NUMBER_OF_TRUCKS,
    label: G.getWindowLocale('titles:max-number-of-trucks'),
  },
];

const defaultFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_MAX_NUMBER_OF_TRUCKS]: null,
};

export const validationSchema = () => {
  const schema = {
    [GC.FIELD_NAME]: G.yupStringRequired,
    [GC.FIELD_MAX_NUMBER_OF_TRUCKS]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .positive(G.getShouldBePositiveLocaleTxt())
      .max(10000, G.getShouldBeLessOrEqualLocaleTxt(10000))
      .typeError('Should be a ${type}'), // eslint-disable-line
  };

  return Yup.object().shape(schema);
};

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const LosPricingPlanForm = (props: Object) => {
  const { handleSubmit } = props;

  const formikProps = G.getFormikProps(props);

  return (
    <Box mt={15} width={250}>
      <form onSubmit={handleSubmit}>
        <Fieldset2 {...formikProps} fields={fieldSettings} />
        <FormFooter2 />
      </form>
    </Box>
  );
};

export default enhance(LosPricingPlanForm);
