import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// features drivers-card
import {
  createUnavailablePeriodSuccess,
  updateUnavailablePeriodSuccess,
  removeUnavailablePeriodSuccess,
} from '../actions';
import UnavailablePeriodForm from '../forms/unavailable-period-form';
//////////////////////////////////////////////////

const withConnectDriverCardsUnavailablePeriodActions = connect(null, {
  createUnavailablePeriodSuccess,
  updateUnavailablePeriodSuccess,
  removeUnavailablePeriodSuccess,
});

const withUnavailablePeriod = compose(
  withConnectModalAndLoaderActions,
  withConnectDriverCardsUnavailablePeriodActions,
  withHandlers({
    handleAddUnavailablePeriod: (props: Object) => (driverGuid: string) => {
      const {
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        driverGroupGuid,
        isLocationRequired,
        createUnavailablePeriodSuccess,
      } = props;

      const submitHandler = async (values: Object) => {
        const { endDate, location, startDate } = values;

        G.callFunction(openLoader);

        const options = {
          data: {
            ...values,
            driverGuid,
            location: G.ifElse(G.isNotEmptyString(location), location, null),
            endDate: G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(true)),
            startDate: G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(true)),
          },
        };

        try {
          const res = await sendRequest('post', endpointsMap.routeFleetDriverUnavailablePeriod, options);

          const { data, status } = res;

          if (G.isResponseSuccess(status, data)) {
            G.callFunction(closeModal);

            G.callFunctionWithArgs(createUnavailablePeriodSuccess, R.assoc('groupGuid', driverGroupGuid, data));
          } else {
            G.handleFailResponseSimple(
              res,
              true,
              'withUnavailablePeriod -> handleAddUnavailablePeriod',
            );
          }

          G.callFunction(closeLoader);
        } catch (error) {
          G.callFunction(closeLoader);

          G.handleException(error, 'withUnavailablePeriod -> handleAddUnavailablePeriod');
        }
      };

      const modalContent = (
        <UnavailablePeriodForm
          submitHandler={submitHandler}
          isLocationRequired={isLocationRequired}
        />
      );

      const modal = G.getDefaultModalOptions2(
        modalContent,
        G.getAddTitle(['titles:unavailable-period', 'Unavailable Period']),
      );

      openModal(modal);
    },
    handleUpdateUnavailablePeriod: (props: Object) => (initialValues: Object) => {
      const {
        groupBy,
        groupGuid,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        isLocationRequired,
        updateUnavailablePeriodSuccess,
      } = props;

      const submitHandler = async (values: Object) => {
        const { endDate, location, startDate } = values;

        G.callFunction(openLoader);

        const options = {
          data: {
            ...values,
            location: G.ifElse(G.isNotEmptyString(location), location, null),
            endDate: G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(true)),
            startDate: G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(true)),
          },
        };

        try {
          const res = await sendRequest('put', endpointsMap.routeFleetDriverUnavailablePeriod, options);

          const { data, status } = res;

          if (G.isResponseSuccess(status, data)) {
            G.callFunction(closeModal);

            G.callFunctionWithArgs(updateUnavailablePeriodSuccess, {...data, groupBy, groupGuid });
          } else {
            G.handleFailResponseSimple(
              res,
              true,
              'withUnavailablePeriod -> handleUpdateUnavailablePeriod',
            );
          }

          G.callFunction(closeLoader);
        } catch (error) {
          G.callFunction(closeLoader);

          G.handleException(error, 'withUnavailablePeriod -> handleUpdateUnavailablePeriod');
        }
      };

      const modalContent = (
        <UnavailablePeriodForm
          submitHandler={submitHandler}
          initialValues={initialValues}
          isLocationRequired={isLocationRequired}
        />
      );

      const modal = G.getDefaultModalOptions2(
        modalContent,
        G.getEditTitle(['titles:unavailable-period', 'Unavailable Period']),
      );

      openModal(modal);
    },
    handleRemoveUnavailablePeriod: (props: Object) => (period: Object) => {
      const {
        groupBy,
        groupGuid,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        removeUnavailablePeriodSuccess,
      } = props;

      const submitHandler = async (values: Object) => {
        G.callFunction(openLoader);

        const guid = G.getGuidFromObject(values);

        try {
          const res = await sendRequest('delete', endpointsMap.getRemoveRouteFleetDriverUnavailablePeriod(guid));

          const { data, status } = res;

          if (G.isResponseSuccess(status, data)) {
            G.callFunction(closeModal);

            G.callFunctionWithArgs(removeUnavailablePeriodSuccess, { ...period, groupBy, groupGuid });
          } else {
            G.handleFailResponseSimple(
              res,
              true,
              'withUnavailablePeriod -> handleRemoveUnavailablePeriod',
            );
          }

          G.callFunction(closeLoader);
        } catch (error) {
          G.callFunction(closeLoader);

          G.handleException(error, 'withUnavailablePeriod -> handleRemoveUnavailablePeriod');
        }
      };

      const title = G.getWindowLocale(
        'messages:delete-confirmation-text-unavailable-period',
        'Are you sure you want delete this unavailable period?',
      );

      const component = <ConfirmComponent textLocale={title} />;

      const modal = {
        component,
        options: {
          width: '600px',
          height: '100px',
          controlButtons: [
            {
              type: 'button',
              action: () => submitHandler(period),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    patchUpdateUnavailablePeriod: (props: Object) => async (values: Object, successCallback: Function) => {
      const {
        groupBy,
        groupGuid,
        openLoader,
        closeLoader,
        updateUnavailablePeriodSuccess,
      } = props;

      G.callFunction(openLoader);

      try {
        const res = await sendRequest('put', endpointsMap.routeFleetDriverUnavailablePeriod, { data: values });

        const { data, status } = res;

        if (G.isResponseSuccess(status, data)) {
          G.callFunctionWithArgs(updateUnavailablePeriodSuccess, {...data, groupBy, groupGuid });
          G.callFunction(successCallback);
        } else {
          G.handleFailResponseSimple(
            res,
            true,
            'withUnavailablePeriod -> patchUpdateUnavailablePeriod',
          );
        }
        G.callFunction(closeLoader);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'withUnavailablePeriod -> patchUpdateUnavailablePeriod');
      }
    },
  }),
  pure,
);

export {
  withUnavailablePeriod,
};
