import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { trailerTrackingSettings } from '../fleet-profile/trailer/settings/trailer-tracking';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature geo-fencing-location
import * as A from './actions';
import * as H from './helpers';
import {
  makeSelectReportType,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import { fleetTrailerFields } from './settings/location-settings';
//////////////////////////////////////////////////

function* geoFencingLocationListRequestSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportType = yield select(makeSelectReportType());
    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals(`${reportType}Default`, reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const reqBody = {
      ...pagination,
      orderFields,
      currentEnterprise,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.geoFencingLocationList, { data: reqData });

    const { data, status } = res;

    yield put(A.setListLoading(false));

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data }));
    } else {
      yield call(G.handleFailResponse, res, 'geoFencingLocationListRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'geoFencingLocationListRequestSaga exception');
  }
}

function* getListItemByGuidRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const props = {
      offset: 0,
      limit: 1000,
      currentEnterprise,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: G.getSearchCriteriaByGuid(payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.geoFencingLocationList, { data: props });

    const { data, status } = res;

    yield put(A.setListLoading(false));

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids: [payload]}));
    } else {
      yield call(G.handleFailResponse, res, 'getListItemByGuidRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getListItemByGuidRequestSaga exception');
  }
}

function* submitGeoFencingLocationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, currentLocation } = payload;

    const guid = G.getGuidFromObject(currentLocation);

    const method = G.ifElse(guid, 'put', 'post');

    const enterpriseGuid = yield select(makeSelectCurrentBranchGuid());

    const preparedValues = H.prepareLocationForSubmitting(values);

    const requestData = R.mergeRight(currentLocation, R.assoc('enterpriseGuid', enterpriseGuid, preparedValues));

    const res = yield call(sendRequest, method, endpointsMap.geoFencingLocation, { data: requestData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield delay(100);
      yield put(A.getGeoFencingLocationSuccess(null));
      yield put(A.getListItemByGuidRequest(G.getGuidFromObject(data)));
    } else {
      yield call(G.handleFailResponse, res, 'submitGeoFencingLocationRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'submitGeoFencingLocationRequestSaga exception');
  }
}

function* getGeoFencingLocationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, mode } = payload;

    const res = yield call(sendRequest, 'get', endpointsMap.geoFencingLocationByGuid(guid));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(mode, H.VIEW_MODE)) yield put(A.getFleetTrailerListRequest(guid));

      yield put(A.getGeoFencingLocationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getGeoFencingLocationRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getGeoFencingLocationRequestSaga exception');
  }
}

function* deleteGeoFencingLocationsRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.geoFencingLocationByGuid(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteItemSuccess([payload]));
    } else {
      yield call(G.handleFailResponse, res, 'deleteGeoFencingLocationsRequestSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'deleteGeoFencingLocationsRequestSaga exception');
  }
}
// fleet/trailer
function* getFleetTrailerListRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));

    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const reqBody = {
      pageable: false,
      currentEnterprise,
      fields: fleetTrailerFields,
      searchCriteria: G.getSearchCriteriaByGuidAndPropertyName(
        payload,
        'trailerGeofencingLocation.geofencingLocation.guid',
      ),
    };

    const reqData = G.setSearchCriteria({ reqBody });

    const res = yield call(sendRequest, 'post', endpointsMap.listFleetTrailers, { data: reqData });

    const { data, status } = res;

    yield put(A.setListLoading(false));

    if (G.isResponseSuccess(status)) {
      yield put(A.getFleetTrailerListSuccess(R.propOr([], 'results', data)));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetTrailerListRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetTrailerListRequestSaga exception');
  }
}

function* getFleetTrailerHistoryLogSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));

    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const fields = R.path(['report', 'fields'], trailerTrackingSettings);

    const reqBody = {
      fields,
      pageable: false,
      currentEnterprise,
      searchCriteria: G.getSearchCriteriaByGuidAndPropertyName(
        payload,
        'trailerGuid',
      ),
    };

    const reqData = G.setSearchCriteria({ reqBody });

    const res = yield call(sendRequest, 'post', endpointsMap.trailerMilestoneLogList, { data: reqData });

    const { data, status } = res;

    yield put(A.setListLoading(false));

    if (G.isResponseSuccess(status)) {
      yield put(A.getFleetTrailerRouteHistorySuccess(R.propOr([], 'results', data)));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetTrailerStopHistorySaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetTrailerStopHistorySaga exception');
  }
}

// report
const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.GEO_FENCING_LOCATION_REPORT, A, geoFencingLocationListRequestSaga, { createUpdateReportSuccessCallback },
);

// visit page
function* visitGeoFencingLocationPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_GEO_FENCING_LOCATION_PAGE);

    yield put(openLoader());

    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload: { setUsedReport: true } });

    yield put(A.getItemListRequest({ payload: { openLoader: true } }));

    yield put(closeLoader());

    break;
  }
}

function* geoFencingLocationWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_GEO_FENCING_LOCATION_PAGE, visitGeoFencingLocationPage);
  // report
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // location
  yield takeLatest(A.getListItemByGuidRequest, getListItemByGuidRequestSaga);
  yield takeLatest(A.getItemListRequest, geoFencingLocationListRequestSaga);
  yield takeLatest(A.deleteItemRequest, deleteGeoFencingLocationsRequestSaga);
  yield takeLatest(A.getGeoFencingLocationRequest, getGeoFencingLocationRequestSaga);
  yield takeLatest(A.submitGeoFencingLocationRequest, submitGeoFencingLocationRequestSaga);
  // fleet/trailer
  yield takeLatest(A.getFleetTrailerListRequest, getFleetTrailerListRequestSaga);
  yield takeLatest(A.getFleetTrailerRouteHistoryRequest, getFleetTrailerHistoryLogSaga);
}

export default geoFencingLocationWatcherSaga;
