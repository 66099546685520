import * as R from 'ramda';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
/////////////////////////////////////////////////

export const withAsyncDataOnDidMount = compose(
  withState('asyncData', 'setAsyncData', null),
  withState('asyncLoading', 'setAsyncLoading', true),
  withHandlers({
    getAsyncData: (props: Object) => async () => {
      const {
        asyncMethod,
        asyncOptions,
        setAsyncData,
        asyncEndpoint,
        setAsyncLoading } = props;
      const res = await sendRequest(R.or(asyncMethod, 'get'), asyncEndpoint, asyncOptions);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        setAsyncData(data, () => setAsyncLoading(false));
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAsyncData();
    },
  }),
);
