// helpers/constants
import * as G from '../../../helpers';
// feature chart
import * as C from '../constants';
//////////////////////////////////////////////////

const emptyObject = {
  label: '',
  value: '',
};

const totalChart = {
  value: C.TOTAL,
  label: G.getWindowLocale('titles:total', 'Total'),
};

const pieChart = {
  value: C.PIE,
  label: G.getWindowLocale('titles:pie', 'Pie'),
};

const barChart = {
  value: C.BAR,
  label: G.getWindowLocale('titles:bar', 'Bar'),
};

const pieChart3D = {
  value: C.PIE3D,
  label: G.getWindowLocale('titles:pie-3d', 'Pie 3D'),
};

const barChart3D = {
  value: C.BAR3D,
  label: G.getWindowLocale('titles:bar-3d', 'Bar 3D'),
};

const lineChart = {
  value: C.LINE,
  label: G.getWindowLocale('titles:line', 'Line'),
};

const driverGrouping = {
  value: C.DRIVER,
  label: G.getWindowLocale('titles:driver', 'Driver'),
};

const statusGrouping = {
  value: C.STATUS,
  label: G.getWindowLocale('titles:status', 'Status'),
};

const branchGrouping = {
  value: C.BRANCH,
  label: G.getWindowLocale('titles:branch', 'Branch'),
};

const dateDayGrouping = {
  value: C.FIRST_DATE_DAY,
  label: G.getWindowLocale('titles:date-day', 'Date Day'),
};

const noGroupGrouping = {
  value: C.NO_GROUP,
  label: G.getWindowLocale('titles:no-group', 'No Group'),
};

export default {
  emptyObject,
  barChart,
  pieChart,
  lineChart,
  totalChart,
  barChart3D,
  pieChart3D,
  statusGrouping,
  driverGrouping,
  branchGrouping,
  dateDayGrouping,
  noGroupGrouping,
};
