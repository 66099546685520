import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
/////////////////////////////////////////////////

export const tabStyles = {
  mr: '4px',
  height: 36,
  p: '4px 6px',
  fontSize: 24,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  bg: G.getTheme('colors.white'),
  color: G.getTheme('colors.dark.grey'),
  boxShadow: `2px -2px 5px -2px ${G.getTheme('colors.boxShadowGrey')}`,
};

export const activeTabStyles = R.mergeRight(tabStyles, {
  color: G.getTheme('colors.black'),
  bg: G.getTheme('colors.lightLime'),
});

export const countStyles = {
  mt: '4px',
  ml: '4px',
  fontSize: 18,
};

// table cells
export const ACTIONS_CELL = 'ACTIONS_CELL';
export const LEFT_TABLE_CELL = 'LEFT_TABLE_CELL';
export const TOGGLE_EVENTS_CELL = 'TOGGLE_EVENTS_CELL';
export const MESSAGES_NOTES_CELL = 'MESSAGES_NOTES_CELL';
export const TOGGLE_SHIP_UNIT_CELL = 'TOGGLE_SHIP_UNIT_CELL';

export const CLO_NOTE_FORM = 'CLO_NOTE_FORM';
export const CLO_ITEM_FORM = 'CLO_ITEM_FORM';
export const DOCUMENTS_TAB = 'DOCUMENTS_TAB';
export const INVOICE_ACTIONS = 'INVOICE_ACTIONS';
export const DROP_ITEMS_FORM = 'DROP_ITEMS_FORM';
export const MAIN_DETAILS_TAB = 'MAIN_DETAILS_TAB';
export const CLO_SERVICE_FORM = 'CLO_SERVICE_FORM';
export const EVENT_DATES_FORM = 'EVENT_DATES_FORM';
export const DROP_TRAILERS_FORM = 'DROP_TRAILERS_FORM';
export const EVENT_CONTACT_FORM = 'EVENT_CONTACT_FORM';
export const CLO_EQUIPMENT_FORM = 'CLO_EQUIPMENT_FORM';
export const EVENT_DISTANCE_FORM = 'EVENT_DISTANCE_FORM';
export const CLO_CHAT_MESSAGE_FORM = 'CLO_CHAT_MESSAGE_FORM';
export const CLO_CHANGE_OWNER_FORM = 'CLO_CHANGE_OWNER_FORM';
export const SPECIAL_INSTRUCTIONS_FORM = 'SPECIAL_INSTRUCTIONS_FORM';

// SUMMARY
export const SUMMARY_TRUCK = 'TRUCK';
export const SUMMARY_STATUS = 'STATUS';
export const SUMMARY_DRIVER = 'DRIVER';
export const SUMMARY_CARRIER = 'CARRIER';
export const SUMMARY_BILL_TO = 'BILL_TO';
export const SUMMARY_TRAILER = 'TRAILER';
export const SUMMARY_BRANCH = GC.BRANCH_UPPERCASE;
export const SUMMARY_LAST_EVENT_DATE = 'LAST_EVENT_DATE';
export const SUMMARY_FIRST_EVENT_DATE = 'FIRST_EVENT_DATE';

export const handlersMap = {
  [GC.OPEN_IN_ROUTE_BUILDER_ACTION]: 'handleGoToRouteBuilder',
};
