import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import MultipleStatusMessageForm from '../../../../components/multiple-status-message';
// features
import StatusMessageFormComponent from '../../forms/status-message-form';
import StatusMessageMapComponent from '../../components/status-messages-map';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import AddStatusMessage from '../../components/add-status-message';
import { updateOrderStatusMessageRequest, removeOrderStatusMessageRequest } from '../actions';
//////////////////////////////////////////////////

const mappedEvents = R.compose(
  R.sortBy(R.prop(GC.FIELD_CLO_EVENT_INDEX)),
  R.filter(G.isNotNil),
  R.map((stop: Object) => {
    const lat = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE], stop);
    const lng = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE], stop);
    if (R.or(R.isNil(lat), R.isNil(lng))) return null;

    return {
      ...stop,
      latLng: { lat, lng },
      [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE',
      title: `${G.toTitleCase(stop.eventType)} ${stop[GC.FIELD_CLO_EVENT_INDEX]}`,
    };
  }),
);

export const withAddStatusMessage = compose(
  withHandlers({
    handleAddStatusMessage: (props: Object) => () => {
      const {
        load,
        events,
        openModal,
        closeModal,
        createOrderStatusMessageRequest,
        loadConfigs: { configGroups, statusMessageConfigs } } = props;
      const handleCreateLoadStatusMessage = (values: Object) => {
        const data = {
          ...R.pick(GC.GROUPED_FIELDS.STATUS_MESSAGE_PICK_ARR, values),
          cloStatusMessageLocation: R.omit(GC.GROUPED_FIELDS.STATUS_MESSAGE_OMIT_ARR, values),
        };
        createOrderStatusMessageRequest(data);
      };
      const initialValues = {
        [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE',
      };
      const component = (
        <StatusMessageFormComponent
          load={load}
          loadType={GC.FIELD_CLO}
          closeModal={closeModal}
          entityName={GC.FIELD_CLO}
          initialValues={initialValues}
          mapLocations={mappedEvents(events)}
          statusMessagesConfigs={statusMessageConfigs}
          communicationsConfigs={configGroups.communication}
          handleActionLoadStatusMessage={handleCreateLoadStatusMessage} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: null,
          height: 'auto',
          width: 'max-content',
        },
      };
      openModal(modal);
    },
    handleAddMultipleStatusMessage: (props: Object) => () => {
      const {
        load,
        events,
        openModal,
        closeModal,
        closeFixedPopup,
        createLoadStatusMessageRequest,
        createOrderMultipleStatusMessageRequest,
        loadConfigs: { configGroups, statusMessageConfigs } } = props;

      G.callFunction(closeFixedPopup);
      const component = (
        <MultipleStatusMessageForm
          load={load}
          closeModal={closeModal}
          loadType={GC.LOAD_TYPE_CLO}
          events={mappedEvents(events)}
          statusMessagesConfigs={statusMessageConfigs}
          communicationsConfigs={configGroups.communication}
          createLoadStatusMessageRequest={createLoadStatusMessageRequest}
          createMultipleStatusMessage={createOrderMultipleStatusMessageRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: null,
          height: 'auto',
          width: 'max-content',
        },
      };
      openModal(modal);
    },
    handleOpenStatusMessagesMap: (props: Object) => () => {
      const { load, itemList, events, openModal, closeModal } = props;

      const loadStatusMessageLocationName = G.ifElse(
        G.isLoadTypeClo(load),
        'cloStatusMessageLocation',
        'telStatusMessageLocation',
      );
      const statusMessages = R.compose(
        R.values,
        R.map((item: Object) => R.mergeRight(item, G.getPropFromObject(loadStatusMessageLocationName, item))),
        R.filter((item: Object) => G.isNotNilAndNotEmpty(G.getPropFromObject(loadStatusMessageLocationName, item))),
      )(itemList);
      if (G.isNilOrEmpty(statusMessages)) return;
      const component = (
        <StatusMessageMapComponent
          closeModal={closeModal}
          statusMessages={statusMessages}
          mappedEventsForMap={mappedEvents(events)} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'max-content',
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleOpenAddStatusMessage: (props: Object) => (event: Object) => {
      props.openFixedPopup({
        position: 'left',
        el: event.currentTarget,
        content: (
          <AddStatusMessage
            handleAddStatusMessage={props.handleAddStatusMessage}
            handleAddMultipleStatusMessage={props.handleAddMultipleStatusMessage} />
        ),
      });
    },
  }),
  pure,
);

export const withStatusMessageTableGroupRowActions = compose(
  connect(
    null,
    {
      updateOrderStatusMessageRequest,
      removeOrderStatusMessageRequest,
    },
  ),
  withHandlers({
    handleUpdateStatusMessage: (props: Object) => () => {
      const {
        load,
        entity,
        events,
        openModal,
        closeModal,
        updateOrderStatusMessageRequest,
        loadConfigs: { configGroups, statusMessageConfigs } } = props;
      const { cloStatusMessageLocation } = entity;
      const location = G.concatLocationFields(cloStatusMessageLocation);
      const locationFields = R.pick(
        [
          GC.FIELD_ZIP,
          GC.FIELD_CITY,
          GC.FIELD_STATE,
          GC.FIELD_COUNTRY,
          GC.FIELD_LATITUDE,
          GC.FIELD_LONGITUDE,
        ],
        R.or(cloStatusMessageLocation, {}),
      );
      const getStatusCodeFromInitialValues = R.path([GC.FIELD_STATUS_MESSAGE_CODE, 'statusCodeConfigGuid'], entity);
      const statusCode = R.compose(
        R.pathOr(null, [GC.FIELD_ORIGINAL_CONFIG_GUID]),
        R.find(R.propEq(getStatusCodeFromInitialValues, GC.FIELD_ORIGINAL_CONFIG_GUID)),
      )(statusMessageConfigs);
      const initialValues = {
        ...entity,
        ...locationFields,
        location,
        statusCode,
        [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE',
      };
      const mappedEvents = R.compose(
        R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
        R.filter(G.isNotNil),
        R.map((stop: Object) => {
          const lat = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LATITUDE], stop);
          const lng = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LONGITUDE], stop);
          if (R.or(R.isNil(lat), R.isNil(lng))) return null;

          return {
            ...stop,
            latLng: { lat, lng },
            title: `${G.toTitleCase(stop.eventType)} ${stop[GC.FIELD_CLO_EVENT_INDEX]}`,
          };
        }),
      )(events);
      const component = (
        <StatusMessageFormComponent
          load={load}
          loadType={GC.FIELD_CLO}
          closeModal={closeModal}
          entityName={GC.FIELD_CLO}
          mapLocations={mappedEvents}
          initialValues={initialValues}
          statusMessagesConfigs={statusMessageConfigs}
          communicationsConfigs={configGroups.communication}
          handleActionLoadStatusMessage={updateOrderStatusMessageRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: null,
          height: 'auto',
          width: 'max-content',
        },
      };
      openModal(modal);
    },
    handleRemoveStatusMessage: ({ entity, openModal, removeOrderStatusMessageRequest }: Object) => () => {
      const textLocale = G.getWindowLocale(
        'messages:remove-confirmation-status-text',
        'Are you sure you want delete this status message?',
      );
      const component = <ConfirmComponent textLocale={textLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeOrderStatusMessageRequest(G.getGuidFromObject(entity)),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
);
