import * as R from 'ramda';
import { put, call, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature claim-management
import * as A from '../actions';
import claimManagementClaimDetailsWatcherSaga from './details';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from '../selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader());
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.propEq('CLAIMDefault', GC.FIELD_GUID, reportParams),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const systemFields = [
      GC.GRC.CLO_GUID,
      GC.GRC.TEL_GUID,
    ];

    const reqData = G.addSystemFieldsToTableReport(
      G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      systemFields,
    );

    const res = yield call(sendRequest, 'post', endpointsMap.claimList, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids}));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.getClaimByGuid(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(closeModal());
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.CLAIM_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });


// configs
function* getBranchConfigsByNamesRequest({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        names: R.join(',', names),
        [GC.FIELD_BRANCH_GUID]: R.or(branchGuid, currentBranchGuid),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const configs = G.mapConfigValuesByName(data);

      yield put(A.getBranchConfigsByNamesSuccess(configs));
    } else {
      yield call(G.handleException, 'error', 'getBranchConfigsByNamesRequest exception');
    }
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getBranchConfigsByNamesRequest exception');
  }
}

// item
function* createOrUpdateClaimRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, version, fromDetails, [GC.BRANCH_GUID]: branchGuid } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.BRANCH_GUID, R.or(branchGuid, currentBranchGuid), payload),
    };

    const method = G.ifElse(R.isNotNil(version), 'put', 'post');

    const res = yield call(sendRequest, method, endpointsMap.claim, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (fromDetails) {
        yield put(A.getRelatedTelsRequest());
        yield put(A.getClaimGeneralDetailsRequest(guid));
      } else {
        yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateClaimRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getBranchConfigsByNamesRequest exception');
  }
}

function* handleVisitClaimManagementListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CLAIM_MANAGEMENT_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(A.getBranchConfigsByNamesRequest({
      names: [
        GC.CLAIM_TYPE,
        GC.CLAIM_STATUS,
        GC.CLAIM_REASON,
        GC.CLAIM_SUB_STATUS,
        GC.CLAIM_CLAIM_NUMBER_SEQUENCE,
        GC.CLAIM_CLAIM_NUMBER_AUTOGENERATED,
      ],
    }));

    yield put(closeLoader());

    break;
  }
}

function* claimManagementWatcherSaga() {
  // visit page
  yield takeLatest(GC.VISIT_CLAIM_MANAGEMENT_LIST_PAGE, handleVisitClaimManagementListPageSaga);
  // report
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // configs
  yield takeLatest(A.getBranchConfigsByNamesRequest, getBranchConfigsByNamesRequest);
  // item
  yield takeLatest(A.createOrUpdateClaimRequest, createOrUpdateClaimRequest);
  // details
  yield fork(claimManagementClaimDetailsWatcherSaga);
}

export default claimManagementWatcherSaga;
