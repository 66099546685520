import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature ifta-report
import { changeActiveTab } from '../actions';
import { makeSelectTruckGuid } from '../selectors';
//////////////////////////////////////////////////

const sourceMap = {
  [GC.SYSTEM_LIST_TELS]: {
    route: routesMap.dispatchDetailsLoad,
    key: GC.FIELD_PRIMARY_REFERENCE_VALUE,
  },
  [GC.FIELD_IFTA_TRUCKS]: {
    key: GC.FIELD_TRUCK_UNIT_ID,
    route: routesMap[G.getFleetProfileRoutePathNameByFleetType('truck')],
  },
  [GC.SYSTEM_LIST_FUEL_TRANSACTIONS]: {
    route: routesMap.fuelCards,
    key: GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER,
  },
};

const Card = (props: Object) => {
  const { activeTab, truckGuid, handleChangeActiveTab } = props;
  const list = R.pathOr([], [activeTab], props);
  const whiteColor = G.getTheme('colors.white');
  const blueColor = G.getTheme('colors.light.blue');
  const lightGreyColor = G.getTheme('colors.lightGrey');
  const greyColor = G.getTheme('colors.greyMatterhorn');
  const key = R.path([activeTab, 'key'], sourceMap);
  const handleGoToDetailPage = (guid: string) => {
    const route = R.path([activeTab, 'route'], sourceMap);
    if (G.isFunction(route)) return G.goToRoute(route(guid));
    G.goToRoute(route);
  };
  const getColor = (tab: string) => G.ifElse(
    R.equals(tab, activeTab),
    blueColor,
    lightGreyColor,
  );
  const tabStyles = {
    px: '8px',
    height: 22,
    fontSize: 12,
    bg: whiteColor,
    cursor: 'pointer',
    fontWeight: 'bold',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  };

  return (
    <Box
      width={300}
      height='100%'
      borderRadius='4px'
      background={whiteColor}
      boxShadow={`0 0 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
    >
      <Flex
        px='8px'
        height={40}
        color={greyColor}
        fontWeight='bold'
        borderTopLeftRadius='4px'
        borderTopRightRadius='4px'
        background={G.getTheme('colors.lightGrey')}
      >
        {
          G.isNilOrEmpty(truckGuid) &&
          <Flex
            {...tabStyles}
            mr={10}
            color={getColor(GC.FIELD_IFTA_TRUCKS)}
            onClick={() => handleChangeActiveTab(GC.FIELD_IFTA_TRUCKS)}
          >
            {G.getWindowLocale('titles:trucks', 'Trucks')}
          </Flex>
        }
        <Flex
          {...tabStyles}
          mr={10}
          color={getColor(GC.SYSTEM_LIST_TELS)}
          onClick={() => handleChangeActiveTab(GC.SYSTEM_LIST_TELS)}
        >
          {G.getWindowLocale('titles:tels', 'TELs')}
        </Flex>
        <Flex
          {...tabStyles}
          color={getColor(GC.SYSTEM_LIST_FUEL_TRANSACTIONS)}
          onClick={() => handleChangeActiveTab(GC.SYSTEM_LIST_FUEL_TRANSACTIONS)}
        >
          {G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions')}
        </Flex>
      </Flex>
      <Box
        px='8px'
        fontSize={12}
        maxHeight={360}
        overflowY='auto'
        fontWeight='bold'
        minHeight='max-content'
        height='calc(100% - 40px)'
      >
        {
          list.map((item: Object) => (
            <Box
              py='8px'
              cursor='pointer'
              color={blueColor}
              wordBreak='break-all'
              key={G.getGuidFromObject(item)}
              onClick={() => handleGoToDetailPage(G.getGuidFromObject(item))}
            >
              {G.getPropFromObject(key, item)}
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  truckGuid: makeSelectTruckGuid(state),
});

const enhance = compose(
  connect(mapStateToProps, { changeActiveTab }),
  withHandlers({
    handleChangeActiveTab: (props: Object) => (tabName: string) => {
      const { changeActiveTab } = props;

      const row = R.pick(
        [
          GC.FIELD_GUID,
          GC.SYSTEM_LIST_TELS,
          GC.FIELD_IFTA_TRUCKS,
          GC.FIELD_IFTA_TRUCKS,
          GC.FIELD_IFTA_TEL_GUIDS,
          GC.SYSTEM_LIST_FUEL_TRANSACTIONS,
          GC.FIELD_IFTA_FUEL_TRANSACTION_GUIDS,
        ],
        props,
      );

      changeActiveTab({ row, tabName });
    },
  }),
  pure,
);


export default enhance(Card);
