import React from 'react';
import * as R from 'ramda';
import { FieldArray } from 'formik';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Fieldset2 } from '../../../../forms';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, RelativeBox } from '../../../../ui';
// feature fleet/driver
import {
  getPayrollRateInitFields,
  payrollCompensationRateFieldSettings,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const whiteGreyColor = G.getTheme('colors.whiteGrey');

const VariableValues = (props: Object) => {
  const { form, push, remove } = props;

  const variableValues = R.pathOr([], ['values', GC.FIELD_PAYROLL_VARIABLE_VALUES], form);

  const handleRemove = (index: number) => {
    if (R.equals(R.length(variableValues), 1)) return;

    remove(index);
  };

  return (
    <RelativeBox overflowY='auto' maxHeight={400}>
      <Flex
        pl={20}
        my={25}
        height={30}
        width='100%'
        fontWeight='bold'
        bg={whiteGreyColor}
        color={darkBlueColor}
      >
        {G.getWindowLocale('titles:add-rate', 'Add Rate')}
        <Flex ml={10} cursor='pointer' onClick={() => push(getPayrollRateInitFields())}>
          {I.plusRound(darkBlueColor)}
        </Flex>
      </Flex>
      {
        variableValues.map((_: any, index: number) => (
          <Flex mx={15} key={index}>
            <Box
              mr={10}
              mb={25}
              cursor='pointer'
              onClick={() => handleRemove(index)}
            >
              {I.trash(darkBlueColor)}
            </Box>
            <Fieldset2
              {...form}
              itemIndex={index}
              fieldsetType='array'
              arrayName={GC.FIELD_PAYROLL_VARIABLE_VALUES}
              fields={payrollCompensationRateFieldSettings}
              fieldsWrapperStyles={{ width: 587, justifyContent: 'space-between' }}
            />
          </Flex>
        ))
      }
    </RelativeBox>
  );
};

const RatesSection = () => (
  <FieldArray
    name={GC.FIELD_PAYROLL_VARIABLE_VALUES}
    render={(arrayHelpers: Object) => <VariableValues {...arrayHelpers} />}
  />
);

export default RatesSection;
