import React from 'react';
// components
import { Label } from '../../../components/label';
// helpers
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Flex } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const LabelComponent = () => {
  const description = '<Label /> component serves as a customizable label container that can include icons, text, and callback functions for various actions.';

  const usage = (
    <ul>
      <li>Parameter `<i>label</i>` text label associated with the icon.</li>
      <li>Parameter `<i>iconWrapperWidth</i>` width of the wrapper for the icons.</li>
      <li>Parameters `<i>endLabel</i>` and `<i>frontLabel</i>` are alternative labels for the end and front icons.</li>
      <li>Parameters `<i>endIcon</i>` and `<i>frontIcon</i>` are icons displayed at the end and front positions respectively.</li>
      <li>Parameters `<i>endAction</i>` and `<i>frontAction</i>` are callback functions for actions associated with the end and front icons respectively.</li>
    </ul>
  );

  const icon = I.play(G.getTheme('colors.navyBlue'));

  const labelStyle = { mb: 10 };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='Label'
      description={description}
      path='src/components/label'
    >
      <Flex width={400} flexDirection='column'>
        <Label {...labelStyle} frontIcon={icon}>with front icon</Label>
        <Label {...labelStyle} endIcon={icon}>with end icon</Label>
        <Label {...labelStyle} frontLabel='active'>with front label</Label>
        <Label {...labelStyle} endLabel='active'>with end label</Label>
        <Label {...labelStyle} frontIcon={icon} label='Lorem'>with icon hover effect</Label>
        <Label
          {...labelStyle}
          endIcon={icon}
          frontIcon={icon}
          endAction={() => window.alert('action on end icon')}
          frontAction={() => window.alert('action on front icon')}
        >
          with action on icons
        </Label>
        <Label {...labelStyle} frontIcon={icon} iconWrapperWidth={40}>with icon wrapper width</Label>
      </Flex>
    </ComponentStoryWrapper>
  );
};

export default LabelComponent;
