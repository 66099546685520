import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { LoaderComponent } from '../../../components/loader';
import { FormFooter2 } from '../../../components/form-footer';
import { QuickFilter } from '../../../components/quick-filter';
// features
import { sendPinBranchRequest } from '../../profile/actions';
import { makeSelectPinnedBranches } from '../../profile/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  RelativeBox,
  AbsoluteBox,
} from '../../../ui';
// feature branch
import PinnedBranches from './pinned-branches';
import BranchTreeComponent from './branch-tree-component';
import {
  makeSelectBranchesTree,
  makeSelectCurrentBranch,
  makeSelectBranchesTreeFromCurrentAndDown,
} from '../selectors';
//////////////////////////////////////////////////

const mapTree = (
  tree: Object,
  expanded: boolean,
  searchText: string = '',
  initialSelectedList: Array = [],
) => {
  const copy = G.quickClone(tree);
  const exp = new RegExp(searchText, 'gi');
  const filterTree = (obj: Object) => {
    if (
      G.isNotNilAndNotEmpty(initialSelectedList) &&
      G.notEquals(initialSelectedList.indexOf(obj.guid), -1)
    ) obj.selected = true; // eslint-disable-line

    if (G.isNilOrEmpty(obj.children)) {
      return R.test(exp, obj[GC.FIELD_BRANCH_NAME]);
    }

    let anyMatch = false;
    const filtered = obj.children.filter((ent: Object) => {
      const match = filterTree(ent, searchText);
      if (match) {
        anyMatch = true;
      }

      return match;
    });
    obj.children = filtered; // eslint-disable-line
    obj.isOpened = G.ifElse(G.isNotNilAndNotEmpty(searchText), true, expanded); // eslint-disable-line

    return R.or(anyMatch, R.test(exp, obj[GC.FIELD_BRANCH_NAME]));
  };

  filterTree(copy);
  copy.isOpened = true;

  return copy;
};

const setCurrentBranchOpened = (tree: Object, currentBranchGuid: string) => {
  const copy = G.quickClone(tree);
  const filterTree = (obj: Object) => {
    if (G.isNilOrEmpty(obj.children)) {
      return R.propEq(currentBranchGuid, GC.FIELD_GUID, obj);
    }

    let anyMatch = false;
    const mapped = obj.children.map((ent: Object) => {
      const match = filterTree(ent);

      if (match) {
        anyMatch = true;
      }

      return ent;
    });
    obj.children = mapped; // eslint-disable-line
    obj.isOpened = R.or(anyMatch, obj.isOpened); // eslint-disable-line

    return R.or(anyMatch, R.propEq(currentBranchGuid, GC.FIELD_GUID, obj));
  };
  filterTree(copy);
  copy.isOpened = true;

  return copy;
};

const getTreeFilteredByType = (tree: Object, type: string) => {
  const copy = G.quickClone(tree);
  const filterTree = (children: Array) => children.filter((obj: Object) => {
    const branchType = R.prop(GC.BRANCH_TYPE, obj);
    let isOfNeededType = null;

    if (G.isArray(type)) {
      isOfNeededType = R.includes(branchType, type);
    } else {
      isOfNeededType = R.equals(branchType, type);
    }

    if (R.and(
      isOfNeededType,
      G.isNotNilAndNotEmpty(obj.children),
    )) {
      obj.children = filterTree(obj.children); // eslint-disable-line
    }

    return isOfNeededType;
  });
  copy.children = filterTree(copy.children);

  return copy;
};

const getTreeFilteredByActive = (tree: Object, active: boolean) => {
  const copy = G.quickClone(tree);
  const filterTree = (children: Array) => children.filter((obj: Object) => {
    const isOfNeededActive = R.propEq(active, GC.FIELD_ACTIVE, obj);

    if (R.and(
      isOfNeededActive,
      G.isNotNilAndNotEmpty(obj.children),
    )) {
      obj.children = filterTree(obj.children); // eslint-disable-line
    }

    return isOfNeededActive;
  });
  copy.children = filterTree(copy.children);

  return copy;
};

const selectAllChildren = (tree: Object, guid: string, selected: boolean = true) => {
  const clone = G.quickClone(tree);

  const select = (arr: Array) => {
    return arr.map((ent: Object) => {
      ent.selected = selected; // eslint-disable-line

      if (G.isNotNilAndNotEmpty(ent.children)) {
        ent.children = select(ent.children); // eslint-disable-line
      }

      return ent;
    });
  };

  const setSelected = (branch: Object) => {
    if (R.equals(branch.guid, guid)) {
      branch.children = select(branch.children); // eslint-disable-line
    } else {
      branch.children.some((ent: Object) => setSelected(ent));
    }
  };

  setSelected(clone);

  return clone;
};

const getSelectedList = (tree: Object) => {
  let branches = [];
  const collect = (branch: Object) => {
    if (branch.selected) {
      branches = R.append({ guid: branch.guid, [GC.FIELD_BRANCH_NAME]: branch[GC.FIELD_BRANCH_NAME] }, branches);
    }

    if (G.isNotNilAndNotEmpty(branch.children)) {
      R.forEach((child: Object) => collect(child), branch.children);
    }
  };
  collect(tree);

  return branches;
};

const enhance = compose(
  withState('expanded', 'setExpanded', ({ initiallyExpanded }: Object) => R.or(initiallyExpanded, false)),
  withState('searchText', 'setSearchText', ''),
  withState('searchedTree', 'setSearchedTree', null),
  withState('loading', 'setLoading', false),
  withState('selectedList', 'setSelectedList', ({ initialSelectedList }: Object) => {
    if (G.isNilOrEmpty(initialSelectedList)) return [];

    return R.map(
      (guid: string) => ({ guid }),
      initialSelectedList,
    );
  }),
  withState(
    'treeMapped',
    'setMappedTree',
    ({
      tree,
      currentBranch,
      branchTypeFilter,
      initiallyExpanded,
      branchActiveFilter,
      initialSelectedList,
      useFromCurrentAndDown,
      treeFromCurrentAndDown }: Object) => {
      let treeToUse = setCurrentBranchOpened(mapTree(
        G.setBoolPropToEveryTreeItem(
          G.ifElse(G.isTrue(useFromCurrentAndDown), treeFromCurrentAndDown, tree),
          false,
          'selected',
        ),
        R.or(initiallyExpanded, false),
        '',
        initialSelectedList,
      ), R.prop(GC.FIELD_GUID, currentBranch));

      if (G.isNotNil(branchTypeFilter)) {
        treeToUse = getTreeFilteredByType(treeToUse, branchTypeFilter);
      }

      if (G.isBoolean(branchActiveFilter)) {
        treeToUse = getTreeFilteredByActive(treeToUse, branchActiveFilter);
      }

      return treeToUse;
    },
  ),
  withHandlers({
    handleToggleTree: (props: Object) => () => {
      const expanded = R.not(props.expanded);

      props.setLoading(
        true,
        () => setTimeout(() => {
          props.setExpanded(expanded);
          props.setMappedTree(mapTree(props.treeMapped, expanded));
          props.setLoading(false);
        }, 0),
      );
    },
    handleBranchNameClick: ({ onClickBranch }: Object) => (guid: string) => {
      if (G.isFunction(onClickBranch)) onClickBranch(guid);
    },
    handleToggleBranch: ({ setLoading, treeMapped, setMappedTree }: Object) => (guid: string) => {
      setLoading(
        true,
        () => setTimeout(() => {
          const tree = G.quickClone(treeMapped);

          const setIsOpened = (branch: Object) => {
            if (R.equals(branch.guid, guid)) {
              branch.isOpened = R.not(branch.isOpened); // eslint-disable-line

              return true;
            }

            if (G.isNotNilAndNotEmpty(branch.children)) {
              branch.children.some((ent: Object) => setIsOpened(ent));
            }
          };

          setIsOpened(tree);
          setMappedTree(tree);
          setLoading(false);
        }, 0),
      );
    },
    handleSearch: (props: Object) => (value: string = '') => {
      if (G.isNilOrEmpty(value)) {
        return props.setLoading(
          true,
          () => setTimeout(() => {
            props.setSearchText(value);
            props.setLoading(false);
          }, 0),
        );
      }

      props.setLoading(
        true,
        () => setTimeout(() => props.setSearchText(
          value,
          props.setSearchedTree(
            mapTree(props.treeMapped, false, value),
            () => props.setLoading(false),
          ),
        ), 0),
      );
    },
    handleSelectBranch: ({
      searchText,
      treeMapped,
      setMappedTree,
      currentBranch,
      setSearchedTree,
      setSelectedList,
    }: Object) => (guid: string) => {
      const tree = G.quickClone(treeMapped);

      const setSelected = (branch: Object) => {
        if (R.equals(branch.guid, guid)) {
          branch.selected = R.not(branch.selected); // eslint-disable-line

          return true;
        }

        if (G.isNotNilAndNotEmpty(branch.children)) {
          branch.children.some((ent: Object) => setSelected(ent));
        }
      };

      setSelected(tree);
      setMappedTree(tree);
      setSelectedList(getSelectedList(tree));

      if (G.isNotNilAndNotEmpty(searchText)) {
        setSearchedTree(mapTree(tree, false, searchText, null, currentBranch));
      }
    },
    handleSelectAllChildren: ({
      searchText,
      treeMapped,
      setMappedTree,
      currentBranch,
      setSearchedTree,
      setSelectedList,
    }: Object) => (guid: string) => {
      const tree = selectAllChildren(treeMapped, guid);

      setMappedTree(tree);
      setSelectedList(getSelectedList(tree));

      if (G.isNotNilAndNotEmpty(searchText)) {
        setSearchedTree(mapTree(tree, false, searchText, null, currentBranch));
      }
    },
    handleUnselectAllChildren: ({
      searchText,
      treeMapped,
      setMappedTree,
      currentBranch,
      setSearchedTree,
      setSelectedList,
    }: Object) => (guid: string) => {
      const tree = selectAllChildren(treeMapped, guid, false);

      setMappedTree(tree);
      setSelectedList(getSelectedList(tree));

      if (G.isNotNilAndNotEmpty(searchText)) {
        setSearchedTree(mapTree(tree, false, searchText, null, currentBranch));
      }
    },
    handlePinBranch: ({ sendPinBranchRequest }: Object) => (guid: string, pinned: bollean) =>
      sendPinBranchRequest({ guid, pinned }),
    handleMultipleSelectAction: ({ selectedList, handleMultipleSelect }: Object) => () =>
      R.and(
        G.isFunction(handleMultipleSelect),
        handleMultipleSelect(R.map(({ guid }: Object) => guid, selectedList)),
      ),
  }),
);

export const BranchTree = enhance((props: Object) => {
  const {
    width,
    cursor,
    loading,
    allowPin,
    expanded,
    searchText,
    pinnedList,
    treeMapped,
    allowSelect,
    closeAction,
    handleSearch,
    rootDisabled,
    allowActions,
    singleSelect,
    searchedTree,
    currentBranch,
    withoutCurrent,
    handlePinBranch,
    handleToggleTree,
    handleToggleBranch,
    handleSelectBranch,
    handleBranchNameClick,
    handleSelectAllChildren,
    handleShareWithDivisions,
    handleUnselectAllChildren,
    handleMultipleSelectAction,
  } = props;

  const toggleText = G.ifElse(
    expanded,
    G.getWindowLocale('actions:collapse', 'Collapse'),
    G.getWindowLocale('actions:uncollapse', 'Uncollapse'),
  );

  const bgColor = G.getTheme(G.ifElse(
    expanded,
    'colors.light.green',
    'colors.light.blue',
  ));

  const mainLightColor = G.getTheme('colors.light.mainLight');

  return (
    <RelativeBox p={15} width={R.or(width, 700)}>
      {
        allowPin && G.isNotNilAndNotEmpty(pinnedList) &&
        <AbsoluteBox
          top='0px'
          width={230}
          left='-230px'
          height='100%'
          bg={mainLightColor}
          borderTopLeftRadius='2px'
          borderBottomLeftRadius='2px'
          boxShadow={`0 0 8px 1px ${G.getTheme('colors.mainBlue')}`}
        >
          <PinnedBranches />
        </AbsoluteBox>
      }
      <Flex mb={16} height={40}>
        <Flex
          p='4px'
          mr={16}
          width={130}
          bg={bgColor}
          cursor='pointer'
          borderRadius='4px'
          color={mainLightColor}
          justifyContent='center'
          textTransform='uppercase'
          onClick={handleToggleTree}
        >
          {G.ifElse(expanded, I.folderOpened, I.folderClosed)(mainLightColor)}
          <TextComponent
            ml='6px'
            maxWidth={110}
            title={toggleText}
            width='fit-content'
            withEllipsis={true}
            display='inline-block'
          >
            {toggleText}
          </TextComponent>
        </Flex>
        <QuickFilter
          delayTime={2000}
          withoutLabel={true}
          handleSetFilter={handleSearch}
          placeholder={G.getWindowLocale('titles:search', 'Search')}
        />
        {
          G.isTrue(allowSelect) &&
          <Box ml={16}>
            {
              `${G.getWindowLocale('titles:selected', 'Selected')}: ${R.pathOr(0, ['selectedList', 'length'], props)
              }`
            }
          </Box>
        }
        {
          R.not(allowSelect) &&
          <Box
            ml='auto'
            height='100%'
            display='flex'
            cursor='pointer'
            onClick={closeAction}
          >
            {I.closeIcon(G.getTheme('colors.dark.blue'), 14)}
          </Box>
        }
      </Flex>
      <BranchTreeComponent
        cursor={cursor}
        allowPin={allowPin}
        searchText={searchText}
        pinnedList={pinnedList}
        allowSelect={allowSelect}
        rootDisabled={rootDisabled}
        allowActions={allowActions}
        currentBranch={currentBranch}
        withoutCurrent={withoutCurrent}
        toggleBranch={handleToggleBranch}
        handlePinBranch={handlePinBranch}
        handleSelectBranch={handleSelectBranch}
        singleSelect={R.or(singleSelect, false)}
        handleBranchNameClick={handleBranchNameClick}
        handleSelectAllChildren={handleSelectAllChildren}
        handleShareWithDivisions={handleShareWithDivisions}
        handleUnselectAllChildren={handleUnselectAllChildren}
        tree={G.ifElse(
          R.or(G.isNilOrEmpty(searchText), R.isNil(searchedTree)),
          treeMapped,
          searchedTree,
        )}
      />
      {
        G.isTrue(allowSelect) &&
        <FormFooter2 boxStyles={{ mt: 15 }} submitAction={handleMultipleSelectAction} />
      }
      {
        loading &&
        <LoaderComponent
          position='absolute'
          wrapperStyles={{ background: 'rgba(53, 53, 53, 0.1)' }}
        />
      }
    </RelativeBox>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  tree: makeSelectBranchesTree(state),
  pinnedList: makeSelectPinnedBranches(state),
  currentBranch: makeSelectCurrentBranch(state),
  treeFromCurrentAndDown: makeSelectBranchesTreeFromCurrentAndDown(state),
});

export default connect(mapStateToProps, {
  sendPinBranchRequest,
})(BranchTree);
