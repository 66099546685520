import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const getDriverOptions = (props: Object) => {
  const { driverList, driverGuid, driverLastName, driverFirstName, showUnemployedDrivers } = props;

  if (G.isNotNilAndNotEmpty(driverGuid)) {
    return [{
      value: driverGuid,
      label: `${driverFirstName} ${driverLastName}`,
    }];
  }

  return R.compose(
    R.map(({ guid, lastName, firstName }: Object) => (
      {
        value: guid,
        label: `${firstName} ${lastName}`,
      }
    )),
    R.filter(({ employed }: Object) => G.ifElse(showUnemployedDrivers, true, employed)),
    R.values,
  )(driverList);
};

export const getTruckOptions = ({ truckList, truckGuid, truckUnitId, showIsNotInServiceTrucks }: Object) => {
  if (G.isNotNilAndNotEmpty(truckGuid)) {
    return [{
      value: truckGuid,
      label: truckUnitId,
    }];
  }

  return R.compose(
    R.map(({ guid, unitId }: Object) => (
      {
        value: guid,
        label: unitId,
      }
    )),
    R.filter(({ inService }: Object) => G.ifElse(showIsNotInServiceTrucks, true, inService)),
    R.values,
  )(truckList);
};
