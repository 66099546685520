import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('fuelCards');

const makeSelectDriverList = () => createSelector(
  store,
  ({ driverList }: Object) => driverList,
);

const makeSelectTruckList = () => createSelector(
  store,
  ({ truckList }: Object) => truckList,
);

const makeSelectDriverPayrollNumberList = () => createSelector(
  store,
  ({ driverPayrollNumberList }: Object) => driverPayrollNumberList,
);

const makeSelectVendorPayrollNumberList = () => createSelector(
  store,
  ({ vendorPayrollNumberList }: Object) => vendorPayrollNumberList,
);

const makeSelectTruckGuid = () => createSelector(
  store,
  ({ truckGuid }: Object) => truckGuid,
);

const makeSelectDriverGuid = () => createSelector(
  store,
  ({ driverGuid }: Object) => driverGuid,
);

const makeSelectShowUnemployedDrivers = () => createSelector(
  store,
  ({ showUnemployedDrivers }: Object) => showUnemployedDrivers,
);

const makeSelectShowIsNotInServiceTrucks = () => createSelector(
  store,
  ({ showIsNotInServiceTrucks }: Object) => showIsNotInServiceTrucks,
);

const makeSelectDriverAssignmentsTrucks = () => createSelector(
  store,
  ({ driverAssignmentsTrucks }: Object) => driverAssignmentsTrucks,
);

export {
  makeSelectItemList,
  makeSelectTruckGuid,
  makeSelectTruckList,
  makeSelectDriverGuid,
  makeSelectUsedReport,
  makeSelectDriverList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectShowUnemployedDrivers,
  makeSelectDriverAssignmentsTrucks,
  makeSelectDriverPayrollNumberList,
  makeSelectVendorPayrollNumberList,
  makeSelectShowIsNotInServiceTrucks,
};
