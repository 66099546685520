import * as R from 'ramda';
import FileSaver from 'file-saver';
import { initialize } from 'redux-form';
import FileDownload from 'js-file-download';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectExpandedContainerOpened } from '../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
import { openWindowSaga } from '../../../sagas/common';
// utilities
import routesMap from '../../../utilities/routes';
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// fleet
import { getFleetConfigByGroupSaga } from '../sagas';
// feature fleet/driver
import * as A from './actions';
import {
  makeSelectDriverFormValues,
  makeSelectDriverContactList,
  makeSelectCurrentDriverGuid,
  makeSelectDriverAccessorials,
  makeSelectDriverCertification,
  makeSelectDriverCompensations,
} from './selectors';
//////////////////////////////////////////////////

function* getDriverProfileRequest({ payload }: Object) {
  try {
    const endpoint = endpointsMap.getCurrentDriverEndpoint(payload);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverProfileRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getDriverProfileRequest exception');
  }
}

function* getFleetVendorListRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.BRANCH_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.fleetVendorList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getFleetVendorListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetVendorListRequest fail');
    }

  } catch (error) {
    yield call(G.handleException, error, 'getFleetVendorListRequest exception');
  }
}

function* handleDriverRequestSaga({ guid }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getCurrentDriverEndpoint(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { guid, primaryDriverGuid } = data;

      const branchGuid = G.getBranchGuidFromObject(data);

      yield all([
        put(A.getUsersRequest()),
        put(A.getDriverRefRequest(guid)),
        put(A.receivedDriverSuccess(data)),
        put(A.getDriverHoursRequest(guid)),
        put(A.getDriverContactRequest(guid)),
        put(A.getExternalDriverIdRequest(guid)),
        put(A.getDriverConfigsRequest(branchGuid)),
        put(A.getDriverLatestKnownLocationRequest(guid)),
        put(A.getBranchConfigsByNamesRequest(branchGuid)),
        put(A.getAssignInfo(R.or(primaryDriverGuid, guid))),
      ]);

      yield call(getFleetVendorListRequest, { payload: branchGuid });
    } else {
      yield call(G.handleFailResponse, res, 'handleDriverRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDriverRequestSaga exception');
  }
}

export function* handleGetUsersSaga() {
  try {
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.USER_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listUsersGeneral, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getUsersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetUsersSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetUsersSaga exception');
  }
}

export function* handleGetDriverHoursSaga({ payload }: Object) {
  try {
    const endpoint = endpointsMap.driverHours;
    const options = {
      params: {
        driverGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverHoursSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleDriverRequestSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverHoursSaga exception');
  }
}

export function* handleDriverContactRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.driverContactList;
    const options = {
      params: { driverGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverContactSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleDriverContactRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDriverContactRequestSaga exception');
  }
}

export function* handleGetDriverLatestKnownLocationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.driverLatestLocation;
    const options = {
      params: { driverGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const locationData = G.ifElse(G.isNilOrEmpty(data), [], R.of(Array, data));
      yield put(A.receivedDriverLatestKnownLocationSuccess(locationData));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverLatestKnownLocationRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetDriverLatestKnownLocationRequestSaga exception');
  }
}

export function* handleGetDriverCertificationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        driverGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.driverCertificationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverCertificationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverCertificationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverCertificationSaga exception');
  }
}

export function* handleGetDriverDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        primaryObjectGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.driverDocList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverDocSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverDocSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverDocSaga exception');
  }
}

export function* handleGetDriverCitizenSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        driverGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.driverCitizen, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverCitizenSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverCitizenSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverCitizenSaga exception');
  }
}

export function* handleGetDriverLicenseSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.driverLicenseEndpoint(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverLicenseSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverLicenseSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverLicenseSaga exception');
  }
}

export function* handleGetDriverAccessorialsSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const driverGuid = yield select(makeSelectCurrentDriverGuid());

    const options = {
      params: { driverGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.driverAccessorialsList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverAccessorialsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverAccessorialsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverAccessorialsSaga exception');
  }
}

export function* handleGetDriverCompensationsSaga(action: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const reqBody = R.path(['payload', 'reqBody'], action);
    const searchCriteria = G.ifElse(
      G.isNotNilAndNotEmpty(reqBody),
      R.of(Array, reqBody),
      [],
    );

    const driverGuid = yield select(makeSelectCurrentDriverGuid());

    const options = {
      data: {
        driverGuid,
        searchCriteria,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.driverCompensationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverCompensationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverCompensationsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverCompensationsSaga exception');
  }
}

function* handleGetDriverDailyHoursSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        driverGuid: payload,
      },
      data: {
        offset: 0,
        limit: 300,
        pageable: false,
        [GC.CURRENT_BRANCH]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.driverDailyLogList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverDailyHoursSuccess(data.results));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverDailyHoursSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverDailyHoursSaga exception');
  }
}

function* handleCreateDriverDailyHoursSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const driverGuid = yield select(makeSelectCurrentDriverGuid());
    const options = {
      data: R.map(R.compose(
        R.omit([GC.FIELD_ID, GC.FIELD_ON_DUTY_HOURS, GC.FIELD_DRIVING_HOURS]),
        R.assoc(GC.FIELD_DRIVER_GUID, driverGuid),
      ), payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.driverDailyLogMassCreate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createDriverDailyHoursSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverDailyHoursSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverDailyHoursSaga exception');
  }
}

function* handleUpdateDriverDailyHoursSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.compose(
        R.pick([
          ...GC.GROUPED_FIELDS.API_DEFAULT_FIELDS,
          GC.FIELD_LOG_DATE,
          GC.FIELD_DRIVER_GUID,
          GC.FIELD_DRIVING_MINUTES,
          GC.FIELD_ON_DUTY_MINUTES,
        ]),
        R.head,
      )(payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.driverDailyLog, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateDriverDailyHoursSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverDailyHoursSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverDailyHoursSaga exception');
  }
}

function* handleDeleteDriverDailyHoursSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'delete', endpointsMap.driverDailyLogByGuid(payload));
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.deleteDriverDailyHoursSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverDailyHoursSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverDailyHoursSaga exception');
  }
}

function* handleGetPayrollCompensationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        driverGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.payrollCompensation, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getPayrollCompensationRateSuccess(R.or(data, null)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetPayrollCompensationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetPayrollCompensationSaga exception');
  }
}

function* handleSavePayrollCompensationRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const driverGuid = yield select(makeSelectCurrentDriverGuid());
    const method = G.ifElse(R.isNil(G.getGuidFromObject(payload)), 'post', 'put');
    const { driverData } = payload;
    const reqData = R.dissoc('driverData', payload);
    const options = {
      data: R.assoc(GC.FIELD_DRIVER_GUID, driverGuid, reqData),
    };
    const res = yield call(sendRequest, method, endpointsMap.payrollCompensation, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotNil(driverData)) {
        yield put(A.updateDriverDetailsRequest(driverData));
      }
      yield put(closeModal());
      yield put(A.getPayrollCompensationRateSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleSavePayrollCompensationRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSavePayrollCompensationRateSaga exception');
  }
}

function* handleDeletePayrollCompensationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'delete', endpointsMap.getPayrollCompensationEndpoint(payload));
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getPayrollCompensationRateSuccess(null));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeletePayrollCompensationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeletePayrollCompensationSaga exception');
  }
}

export function* handleGetAssignInfoSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getAssignInfoEndpoint(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetAssignInfo(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAssignInfoSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetAssignInfoSaga exception');
  }
}

export function* handleGetAvailableDriversSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        currentPrimaryDriverGuid: payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.getAvailableDrivers, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetAvailableDriverSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableDriversSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetAvailableDriversSaga exception');
  }
}

export function* handleGetAvailableTrucksSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        currentDriverGuid: payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.getAvailableTrucks, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetAvailableTrucksSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableTrucksSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetAvailableTrucksSaga exception');
  }
}

export function* handleGetAvailableTrailersSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        currentDriverGuid: payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.getAvailableTrailers, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetAvailableTrailersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableTrailersSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetAvailableTrailersSaga exception');
  }
}

export function* handleGetDriverConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      group: GC.DRIVER_CONFIG_GROUP,
      [GC.FIELD_BRANCH_GUID]: payload,
    };
    const res = yield call(getFleetConfigByGroupSaga, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetDriverConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverConfigsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverConfigsSaga exception');
  }
}

export function* handleCreateDriverRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { values, fromVendorPage } = payload;
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.driver, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const url = routesMap[G.getFleetProfileRoutePathNameByFleetType()](G.getGuidFromObject(data));
      if (G.isTrue(fromVendorPage)) {
        yield call(openWindowSaga, { url, name: 'driverDetailPage', reloadLocation: false });
      } else {
        yield call(G.goToRoute, url);
      }
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverRequestSaga exception');
  }
}

export function* handleCreateDriverContactSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const driverGuid = yield select(makeSelectCurrentDriverGuid());
    const options = {
      data: R.assoc(GC.FIELD_DRIVER_GUID, driverGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.driverContact, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedCreateDriverContactSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverContactSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverContactSaga exception');
  }
}

export function* handleCreateDriversReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { values, selectedList } = payload;

    const options = {
      data: R.assoc('primaryObjectGuids', selectedList, values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.driverReference, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedCreatedDriverReferenceSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriversReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriversReferenceSaga exception');
  }
}

export function* handleCreateDriverCertificationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const formData = new window.FormData();
    formData.append('file', payload.file);
    formData.append('data', JSON.stringify(payload));
    const options = { data: formData };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.driverCertification,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedCreateDriverCertificateSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriversReferenceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverCertificationSaga exception');
  }
}

export function* handleCreateDriverDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.driverDoc,
      { data },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedCreateDriverDocSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverDocSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverDocSaga exception');
  }
}

export function* handleCreateDriverLatestKnownLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.driverLatestLocation,
      { data: payload },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedCreateDriverLatestKnownLocationSuccess(R.of(Array, payload)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverLatestKnownLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverLatestKnownLocationSaga exception');
  }
}

export function* handleCreateDriverCompensationSaga({ payload }: Object) {
  const { resetForm, saveAndAddNew, setSubmitting } = payload;

  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.omit(['resetForm', 'saveAndAddNew', 'setSubmitting'], payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.driverCompensation, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedCreateDriverCompensationSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (G.isTrue(saveAndAddNew)) {
        G.callFunction(resetForm);
      } else {
        yield put(closeModal());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverCompensationSaga fail');
    }
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
  } catch (error) {
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverCompensationSaga exception');
  }
}

export function* handleCreateDriverAccessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.driverAccessorial, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedCreateDriverAccessorialSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverAccessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverAccessorialSaga exception');
  }
}

export function* handleDeleteDriverContactSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'delete', endpointsMap.getCurrentContactEndpoint(payload));
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const contacts = yield select(makeSelectDriverContactList());
      const deletedContactIndex = R.findIndex(R.propEq(payload, 'guid'), contacts);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
      yield put(A.receivedDeleteDriverContactSuccess(deletedContactIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverContactSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverContactSaga exception');
  }
}

export function* handleGetDriverRefRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.driverReferenceList;
    const options = {
      params: { driverGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverRefSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleDriverRefRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDriverRefRequestSaga exception');
  }
}

export function* handleUpdateDriverDetailsSaga({ payload }: Object) {
  try {
    const res = yield call(sendRequest, 'put', endpointsMap.driver, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateDriverDetailsSuccess(data));
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateDriverDetailsSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverDetailsSaga exception');
    return false;
  }
}

const setDriverHoursBody = (payload: Object) => ({
  data: {
    guid: payload.hoursGuid,
    driverGuid: payload.guid,
    version: payload.driverVersion,
    cycleTomorrow: payload.cycleTomorrow,
    timeUntilBreak: payload.timeUntilBreak,
    shiftRemaining: payload.shiftRemaining,
    cycleRemaining: payload.cycleRemaining,
    dutyStatusCode: payload.dutyStatusCode,
    outOfDutyReason: payload.outOfDutyReason,
    shiftDriveRemaining: payload.shiftDriveRemaining,
    timeInCurrentStatus: payload.timeInCurrentStatus,
  },
});

export function* handleUpdateDriverHoursSaga(payload: Object) {
  try {
    const reqBody = setDriverHoursBody(payload);
    const res = yield call(sendRequest, 'put', endpointsMap.driverHours, reqBody);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateDriverHoursSuccess(data));
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateDriverHoursSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverHoursSaga exeption');
    return false;
  }
}

const setDriverCitizenBody = (data: Object) => ({
  data: {
    citizen: data.citizen,
    driverGuid: data.guid,
    guid: data.citizenGuid,
    visaNumber: data.visaNumber,
    version: data.citizenVersion,
    visaCountry: data.visaCountry,
    uscisNumber: data.uscisNumber,
    visaExpiration: data.visaExpiration,
    passportNumber: data.passportNumber,
    greenCardNumber: data.greenCardNumber,
    passportCountry: data.passportCountry,
    wanExpirationDate: data.wanExpirationDate,
    passportExpiration: data.passportExpiration,
    greenCardExpiration: data.greenCardExpiration,
    workAuthorizationNumber: data.workAuthorizationNumber,
  },
});

export function* handleUpdateDriverCitizenSaga(payload: Object) {
  try {
    const reqBody = setDriverCitizenBody(payload);
    const res = yield call(sendRequest, 'put', endpointsMap.driverCitizen, reqBody);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateDriverCitizenSuccess(data));
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateDriverCitizenSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverCitizenSaga exception');
    return false;
  }
}

const setDriverLicenseBody = (data: Object) => ({
  driverGuid: data.guid,
  guid: data.licenseGuid,
  issueDate: data.issueDate,
  version: data.licenseVersion,
  licenseClass: data.licenseClass,
  licenseState: data.licenseState,
  restrictions: data.restrictions,
  endorsements: data.endorsements,
  licenseNumber: data.licenseNumber,
  licenseCountry: data.licenseCountry,
  expirationDate: data.expirationDate,
});

export function* handleUpdateDriverLicenseSaga(payload: Object) {
  try {
    const data = setDriverLicenseBody(payload);
    const options = { data };
    const res = yield call(sendRequest, 'put', endpointsMap.driverLicense, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateDriverLicenseSuccess(res.data));
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateDriverLicenseSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverLicenseSaga exception');
    return false;
  }
}

export function* handleDriverUpdateSaga({ payload }: Object) {
  try {
    const { guid, licenseGuid, primaryDriverGuid } = payload;

    yield put(openLoader({ showDimmer: true }));

    const detailsResults = yield all([
      put(A.updateDriverDetailsRequest(payload)),
      call(handleUpdateDriverHoursSaga, payload),
    ]);

    let docResults = [];

    if (G.isNotNil(licenseGuid)) {
      docResults = yield all([
        call(handleUpdateDriverCitizenSaga, payload),
        call(handleUpdateDriverLicenseSaga, payload),
      ]);
    }

    if (G.notContain(false, R.concat(detailsResults, docResults))) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (R.isNil(payload.updated)) {
        const expandedContainerOpened = yield select(makeSelectExpandedContainerOpened());

        if (expandedContainerOpened) {
          yield put(setExpandedContainerOptions({
            window: null,
            options: null,
            opened: false,
            componentType: null,
            visitPageGuid: G.getGuidFromObject(payload),
          }));
        } else {
          G.historyGoBack();
        }
      } else {
        yield call(handleGetAssignInfoSaga, { payload: R.or(primaryDriverGuid, guid) });
      }
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDriverUpdateSaga exception');
  }
}

export function* handleUpdateDriverContactSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'put', endpointsMap.driverContact, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      const contacts = yield select(makeSelectDriverContactList());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), contacts);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedUpdateDriverContactSuccess({ index, contact: data}));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverContactSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverContactSaga exception');
  }
}

export function* handleUpdateDriverReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.driverReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedUpdateDriverReferenceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverReferenceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverReferenceSaga exception');
  }
}

export function* handleUpdateDriverCertificationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const formData = new window.FormData();
    formData.append('file', payload.file);
    formData.append('data', JSON.stringify(payload));
    const options = { data: formData };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.updateDriverCertification,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const certifications = yield select(makeSelectDriverCertification());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), certifications);
      yield put(A.receivedUpdateDriverCertificateSuccess({ data, index}));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverCertificationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverCertificationSaga exception');
  }
}

function* downloadDriverCertificationDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { fileUri, driverGuid, documentFilename } = payload;
    const options = {
      resType: 'arraybuffer',
      params: { fileUri, driverGuid },
    };
    const res = yield call(
      sendRequestWithQSParamsSerializer,
      'get',
      endpointsMap.downloadDriverCertificationFile,
      options,
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, documentFilename);
    } else {
      yield call(G.handleFailResponse, res, 'downloadDriverCertificationDocumentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'downloadDriverCertificationDocumentRequest exception');
  }
}

export function* handleUpdateDriverDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.driverDocUpdate,
      { data },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedUpdateDriverDocSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverDocSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverDocSaga exception');
  }
}

export function* handleUpdateDriverCompensationSaga({ payload }: Object) {
  const { setSubmitting } = payload;

  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.omit(['resetForm', 'saveAndAddNew', 'setSubmitting'], payload),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.driverCompensation, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const compensations = yield select(makeSelectDriverCompensations());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), compensations);
      yield put(closeModal());
      yield put(A.receivedUpdateDriverCompensationSuccess({ data, index}));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverCompensationSaga fail');
    }
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
  } catch (error) {
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverCompensationSaga exception');
  }
}

export function* handleUpdateDriverAccessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.driverAccessorial,
      { data: payload },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const accessorials = yield select(makeSelectDriverAccessorials());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), accessorials);
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'toastr:success:200');
      yield put(A.receivedUpdateDriverAccessorialSuccess({ data, index}));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverAccessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDriverAccessorialSaga exception');
  }
}

export function* handleDeleteDriverReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentReferenceEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDeleteDriverReferenceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverReferenceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverReferenceSaga exception');
  }
}

export function* handleDeleteDriverCertificateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentCertificateEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const certifications = yield select(makeSelectDriverCertification());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), certifications);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
      yield put(A.receivedDeleteDriverCertificateSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverCertificateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverCertificateSaga exception');
  }
}

export function* handleDeleteDriverCompensationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getDriverCompensationEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const compensations = yield select(makeSelectDriverCompensations());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), compensations);
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      yield put(A.receivedDeleteDriverCompensationSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverCompensationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverCompensationSaga exception');
  }
}

export function* handleDeleteDriverAccessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getDriverAccessorialEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      const accessorials = yield select(makeSelectDriverAccessorials());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), accessorials);
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      yield put(A.receivedDeleteDriverAccessorialSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverAccessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverAccessorialSaga exception');
  }
}

export function* handleDeleteDriverDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentDocumentsEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDeleteDriverDocSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverDocSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverDocSaga exception');
  }
}

export function* handleAssignTeamDriverSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { driverGuid, teamDriverGuid } = payload;
    const options = {
      params: { teamDriverGuid },
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.assignTeamDriverEndpoint(driverGuid),
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAssignInfo(driverGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleAssignTeamDriverSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAssignTeamDriverSaga exception');
  }
}

export function* handleAssignTruckSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { driverGuid, truckGuid } = payload;
    const options = {
      params: { truckGuid },
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.assignTruckEndpoint(driverGuid),
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAssignInfo(driverGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleAssignTruckSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAssignTruckSaga exception');
  }
}

export function* handleAssignTrailerSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { driverGuid } = payload;
    const options = {
      data: payload,
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.assignTrailerEndpoint,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getAssignInfo(driverGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleAssignTrailerSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAssignTrailerSaga exception');
  }
}

export function* handleUnassignTeamDriverSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.unassignTeamDriverEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAssignInfo(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUnassignTeamDriverSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUnassignTeamDriverSaga exception');
  }
}

export function* handleUnassignTruckSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.unassignTruckEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAssignInfo(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUnassignTruckSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUnassignTruckSaga exception');
  }
}

export function* handleUnassignTrailerSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.unassignTrailerEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAssignInfo(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUnassignTrailerSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUnassignTrailerSaga exception');
  }
}

export function* handleCreateExternalDriverIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = payload;
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.fleetDriverExternal,
      { data },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalDriverIdRequest(data.driverGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateExternalDriverIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateExternalDriverIdSaga exception');
  }
}

export function* handleGetExternalDriverIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.fleetDriverExternalByGuid(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getExternalDriverIdSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetExternalDriverIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetExternalDriverIdSaga exception');
  }
}

export function* handleDeleteExternalDriverIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, driverGuid } = payload;
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.fleetDriverExternalByGuid(guid),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalDriverIdRequest(driverGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteExternalDriverIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteExternalDriverIdSaga exception');
  }
}

export function* handleUpdateExternalDriverIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.fleetDriverExternal,
      { data: payload },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalDriverIdRequest(payload.driverGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateExternalDriverIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateExternalDriverIdSaga exception');
  }
}

export function* toggleDriverExternalEnableRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { value, entity } = payload;

    const entityGuid = G.getGuidFromObject(entity);
    const driverGuid = G.getPropFromObject('driverGuid', entity);
    const params = { [GC.FIELD_DRIVER_GUID]: driverGuid };
    const endpoint = G.ifElse(
      G.isTrue(value),
      endpointsMap.fleetDriverExternalEnableByGuid(entityGuid),
      endpointsMap.fleetDriverExternalDisableByGuid(entityGuid),
    );

    const res = yield call(sendRequest, 'put', endpoint, { params});

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalDriverIdRequest(driverGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'toggleDriverExternalEnableRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'toggleDriverExternalEnableRequest exception');
  }
}

function* handleGetDriverXML({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getDriverXMLEndpoint(payload);
    const res = yield call(sendRequest, 'get', endpoint, { resType: 'arraybuffer' });
    const { data, status, request } = res;
    if (G.isResponseSuccess(status)) {
      const filename = R.or(G.getFileNameFromResponseDispositionHeader(request), 'driver.xml');
      FileDownload(data, filename);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverXML fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetDriverXML exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handlePrintDriverSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: { ...payload },
      resType: 'arraybuffer',
    };
    const res = yield call(sendRequest, 'post', endpointsMap.driverPrint, options);
    const { data, status, request, headers } = res;
    if (G.isResponseSuccess(status)) {
      const file = new window.Blob(
        R.of(Array, data),
        { type: R.pathOr('application/pdf', ['content-type'], headers) },
      );
      const filename = R.or(G.getFileNameFromResponseDispositionHeader(request), 'driver.pdf');
      FileSaver.saveAs(file, filename);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintDriverSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handlePrintDriverSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetFuelCardListByDriverRequest({ payload }: Object) {
  try {
    const params = { [GC.FIELD_DRIVER_GUID]: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.fuelCard, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedFuelCardListByDriverSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetFuelCardListByDriverRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleGetFuelCardListByDriverRequest exception'));
  }
}

function* handleGetPayrollAssessorialListRequest({ payload }: Object) {
  try {
    const params = { [GC.FIELD_DRIVER_GUID]: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.payrollAssessorialList, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollAssessorialListSuccess(data));
      if (G.isNotNilAndNotEmpty(data)) {
        yield put(A.getPayrollAccessorialIsPaidOnPayrollRequest(R.map(G.getGuidFromObject, data)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetPayrollAssesorialListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'handleGetPayrollAssesorialListRequest exception'));
  }
}

function* getPayrollAccessorialIsPaidOnPayrollRequest({ payload }: Object) {
  try {
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.driverPayrollAssessorialsPaidOnPayroll, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollAccessorialIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getPayrollAccessorialIsPaidOnPayrollRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getPayrollAccessorialIsPaidOnPayrollRequest exception'));
  }
}

function* getDriverPayrollChargesByPayrollAccessorial({ payload }: Object) {
  try {
    const params = { payrollAssessorialGuid: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.driverPayrollChargesByPayrollAssessorial, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollChargesByPayrollAccessorialSuccess({ data, guid: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverPayrollChargesByPayrollAccessorial fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getDriverPayrollChargesByPayrollAccessorial exception'));
  }
}

function* togglePayrollAssessorialDetails({ payload }: Object) {
  try {
    if (R.and(R.not(payload.expanded), R.isNil(R.prop('details', payload)))) {
      yield put(A.getPayrollChargesByPayrollAccessorialRequest(payload.guid));
    }
  } catch (error) {
    yield call(G.handleException, error, 'togglePayrollAssessorialDetails exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateOrUpdatePayrollAssessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { requestData, requestMethod } = payload;
    const res = yield call(sendRequest, requestMethod, endpointsMap.payrollAssessorial, { data: requestData });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getPayrollAssessorialListRequest(R.prop(GC.FIELD_DRIVER_GUID, data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateOrUpdatePayrollAssessorialRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'handleCreateOrUpdatePayrollAssessorialRequest exception'));
  }
}

function* handleRemovePayrollAssessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, driverGuid } = payload;

    const endpoint = endpointsMap.getPayrollAssessorialEndpoint(guid);

    const params = { guid };

    const res = yield call(sendRequest, 'delete', endpoint, { params });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getPayrollAssessorialListRequest(driverGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleRemovePayrollAssessorialRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException(error, 'handleRemovePayrollAssessorialRequest exception'));
  }
}

function* getAdvancePaymentListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_DRIVER_GUID]: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.advancePaymentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAdvancePaymentListSuccess(data));
      const payrollGuidList = R.compose(
        R.uniq,
        R.map(({ payrollGuid }: Object) => payrollGuid),
        R.filter(({ payrollGuid }: Object) => G.isNotNilAndNotEmpty(payrollGuid)),
      )(R.or(data, []));
      if (G.isNotNilAndNotEmpty(payrollGuidList)) {
        yield put(A.getDriverPayrollNumberListByGuidsRequest(payrollGuidList));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getAdvancePaymentListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getAdvancePaymentListRequest exception');
  }
}

function* getDriverPayrollNumberListByGuidsRequest({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.getDriverPayrollListByGuids, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNilOrEmpty(data)) return;
      yield put(A.getDriverPayrollNumberListByGuidsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getDriverPayrollNumberListByGuidsRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getDriverPayrollNumberListByGuidsRequest exception');
  }
}

function* createAdvancePaymentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.advancePayment, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createAdvancePaymentSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createAdvancePaymentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createAdvancePaymentRequest exception');
  }
}

function* updateAdvancePaymentStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.advancePaymentChangeStatus, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateAdvancePaymentStatusSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateAdvancePaymentStatusRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'updateAdvancePaymentStatusRequest exception');
  }
}

function* getDeductionSettingsListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_DRIVER_GUID]: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.deductionSettings, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDeductionSettingsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDeductionSettingsListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getDeductionSettingsListRequest exception');
  }
}

function* updateDeductionSettingsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.deductionSettings, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getDeductionSettingsSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateDeductionSettingsRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'updateDeductionSettingsRequest exception');
  }
}

const configNames = [GC.GENERAL_BRANCH_DEFAULT_CURRENCY];

function* getBranchConfigsByNamesRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
        names: R.join(', ', configNames),
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsByNamesSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsByNamesRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getBranchConfigsByNamesRequest exception');
  }
}

// monthly payroll deduction
function* getMonthlyPayrollDeductionRequest({ payload }: Object) {
  try {
    const params = { [GC.FIELD_DRIVER_GUID]: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.monthlyPaymentList, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getMonthlyPayrollDeductionSuccess(data));
      if (G.isNotNilAndNotEmpty(data)) {
        yield put(A.getDriverPayrollChargesIsPaidOnPayrollRequest(R.map(G.getGuidFromObject, data)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getMonthlyPayrollDeductionRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getMonthlyPayrollDeductionRequest exception'));
  }
}

function* getDriverPayrollChargesIsPaidOnPayrollSaga({ payload }: Object) {
  try {
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.driverPayrollChargesIsPaidOnPayroll, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverPayrollChargesIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverPayrollChargesIsPaidOnPayrollSaga fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getDriverPayrollChargesIsPaidOnPayrollSaga exception'));
  }
}

function* getDriverPayrollChargesByMonthlyPaymentSaga({ payload }: Object) {
  try {
    const params = { monthlyPaymentGuid: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.driverPayrollChargesByMonthlyPaymentGuid, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverPayrollChargesByMonthlyPaymentSuccess({ data, guid: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverPayrollChargesByMonthlyPaymentSaga fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getDriverPayrollChargesByMonthlyPaymentSaga exception'));
  }
}

function* handleToggleMonthlyPayrollDeductionDetails({ payload }: Object) {
  try {
    if (R.and(R.not(payload.expanded), R.isNil(R.prop('details', payload)))) {
      yield put(A.getDriverPayrollChargesByMonthlyPaymentRequest(payload.guid));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleToggleMonthlyPayrollDeductionDetails exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* createOrUpdateMonthlyPayrollDeductionRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { requestData, requestMethod } = payload;
    const driverGuid = yield select(makeSelectCurrentDriverGuid());
    const options = {
      data: R.assoc('driverGuid', driverGuid, requestData),
    };
    const res = yield call(sendRequest, requestMethod, endpointsMap.monthlyPayment, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getMonthlyPayrollDeductionRequest(R.prop(GC.FIELD_DRIVER_GUID, data)));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateMonthlyPayrollDeductionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'createOrUpdateMonthlyPayrollDeductionRequest exception'));
  }
}

function* deleteMonthlyPayrollDeductionRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, driverGuid } = payload;
    const endpoint = endpointsMap.getMonthlyPaymentEndpoint(guid);
    const params = { guid };
    const res = yield call(sendRequest, 'delete', endpoint, { params });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getMonthlyPayrollDeductionRequest(driverGuid));
    } else {
      yield call(G.handleFailResponse, res, 'deleteMonthlyPayrollDeductionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'deleteMonthlyPayrollDeductionRequest exception'));
  }
}

// working division
function* changeAssignedToDivisionRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const formValues = yield select(makeSelectDriverFormValues());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDriverAssignedToDivision, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      const version = R.inc(G.getPropFromObject(GC.FIELD_VERSION, formValues));
      const newFormValues = R.mergeRight(formValues, { version, assignedToDivision: R.or(data, null) });
      yield put(A.getAssignInfo(G.getGuidFromObject(payload)));
      yield put(initialize('EDIT_DRIVER_DETAIL', newFormValues));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'changeAssignedToDivisionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeAssignedToDivisionRequest exception');
  }
}

// safety violation
function* createOrUpdateSafetyViolationEntityByEntityTypeRequest({ payload }: Object) {
  try {
    const { values, entityType } = payload;

    yield put(openLoader());
    const isUpdate = G.isNotNilAndNotEmpty(values.version);
    const method = G.ifElse(R.and(isUpdate, G.notEquals(entityType, 'document')), 'put', 'post');
    const safetyViolationEndpointsMap = {
      note: endpointsMap.safetyViolationNote,
      safetyViolation: endpointsMap.safetyViolation,
      document: G.ifElse(
        isUpdate,
        endpointsMap.safetyViolationDocumentUpdate,
        endpointsMap.safetyViolationDocument,
      ),
    };
    let requestData = values;

    if (R.equals(entityType, 'document')) requestData = G.makeDataForDocument(values);

    const options = { data: requestData };
    const endpoint = G.getPropFromObject(entityType, safetyViolationEndpointsMap);

    const res = yield call(sendRequest, method, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.createOrUpdateSafetyViolationEntityByEntityTypeSuccess({ data, entityType }));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateSafetyViolationEntityByEntityTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateSafetyViolationEntityByEntityTypeRequest exception');
  }
}

function* removeSafetyViolationEntityByEntityTypeRequest({ payload }: Object) {
  try {
    const { guid, entityType } = payload;

    yield put(openLoader());

    const safetyViolationEndpointsMap = {
      note: endpointsMap.removeSafetyViolationNoteByGuid,
      safetyViolation: endpointsMap.removeSafetyViolationByGuid,
      document: endpointsMap.removeSafetyViolationDocumentByGuid,
    };

    const endpoint = G.getPropFromObject(entityType, safetyViolationEndpointsMap)(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
      yield put(A.removeSafetyViolationEntityByEntityTypeSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'removeSafetyViolationEntityByEntityTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeSafetyViolationEntityByEntityTypeRequest exception');
  }
}

export function* handlerVisitEditDriverPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_DRIVER_EDIT_PAGE);
    yield put(A.cleanFormStore());
    yield put(A.cleanQuickFilter());
    yield put(A.cleanSafetyViolation());
    yield call(handleDriverRequestSaga, payload);
    break;
  }
}

export function* driversWatcherSaga() {
  yield takeLatest(A.getDriverXML, handleGetDriverXML);
  yield takeLatest(A.printDriver, handlePrintDriverSaga);
  yield takeLatest(A.getUsersRequest, handleGetUsersSaga);
  yield takeLatest(A.getAssignInfo, handleGetAssignInfoSaga);
  yield takeLatest(A.assignTruckRequest, handleAssignTruckSaga);
  yield takeLatest(A.updateDriverRequest, handleDriverUpdateSaga);
  yield takeLatest(A.getDriverDocRequest, handleGetDriverDocSaga);
  yield takeLatest(A.unassignTruckRequest, handleUnassignTruckSaga);
  yield takeLatest(A.assignTrailerRequest, handleAssignTrailerSaga);
  yield takeLatest(A.getDriverHoursRequest, handleGetDriverHoursSaga);
  yield takeLatest(A.getDriverProfileRequest, getDriverProfileRequest);
  yield takeLatest(A.unassignTrailerRequest, handleUnassignTrailerSaga);
  yield takeLatest(A.updateDriverDocRequest, handleUpdateDriverDocSaga);
  yield takeLatest(A.deleteDriverDocRequest, handleDeleteDriverDocSaga);
  yield takeLatest(A.createDriverDocRequest, handleCreateDriverDocSaga);
  yield takeLatest(A.createDriverRequest, handleCreateDriverRequestSaga);
  yield takeLatest(A.getDriverRefRequest, handleGetDriverRefRequestSaga);
  yield takeLatest(A.getDriverConfigsRequest, handleGetDriverConfigsSaga);
  yield takeLatest(A.getDriverCitizenRequest, handleGetDriverCitizenSaga);
  yield takeLatest(A.assignTeamDriverRequest, handleAssignTeamDriverSaga);
  yield takeLatest(A.getDriverLicenseRequest, handleGetDriverLicenseSaga);
  yield takeLatest(A.getAvailableTrucksRequest, handleGetAvailableTrucksSaga);
  yield takeLatest(A.unassignTeamDriverRequest, handleUnassignTeamDriverSaga);
  yield takeLatest(A.getDriverContactRequest, handleDriverContactRequestSaga);
  yield takeLatest(A.createAdvancePaymentRequest, createAdvancePaymentRequest);
  yield takeLatest(A.updateDriverContactRequest, handleUpdateDriverContactSaga);
  yield takeLatest(A.deleteDriverContactRequest, handleDeleteDriverContactSaga);
  yield takeLatest(A.getAvailableDriversRequest, handleGetAvailableDriversSaga);
  yield takeLatest(A.createDriverContactRequest, handleCreateDriverContactSaga);
  yield takeLatest(A.getExternalDriverIdRequest, handleGetExternalDriverIdSaga);
  yield takeLatest(A.getDriverDailyHoursRequest, handleGetDriverDailyHoursSaga);
  yield takeLatest(A.updateDriverDetailsRequest, handleUpdateDriverDetailsSaga);
  yield takeLatest(A.getAdvancePaymentListRequest, getAdvancePaymentListRequest);
  yield takeLatest(A.getAvailableTrailersRequest, handleGetAvailableTrailersSaga);
  yield takeLatest(A.updateDriverReferenceRequest, handleUpdateDriverReferenceSaga);
  yield takeLatest(A.deleteDriverReferenceRequest, handleDeleteDriverReferenceSaga);
  yield takeLatest(A.getDriverAccessorialsRequest, handleGetDriverAccessorialsSaga);
  yield takeLatest(GC.VISIT_FLEET_DRIVER_EDIT_PAGE, handlerVisitEditDriverPageSaga);
  yield takeLatest(A.createDriverReferenceRequest, handleCreateDriversReferenceSaga);
  yield takeLatest(A.getDriverCompensationsRequest, handleGetDriverCompensationsSaga);
  yield takeLatest(A.createExternalDriverIdRequest, handleCreateExternalDriverIdSaga);
  yield takeLatest(A.updateExternalDriverIdRequest, handleUpdateExternalDriverIdSaga);
  yield takeLatest(A.deleteExternalDriverIdRequest, handleDeleteExternalDriverIdSaga);
  yield takeLatest(A.getDriverCertificationRequest, handleGetDriverCertificationSaga);
  yield takeLatest(A.createDriverDailyHoursRequest, handleCreateDriverDailyHoursSaga);
  yield takeLatest(A.updateDriverDailyHoursRequest, handleUpdateDriverDailyHoursSaga);
  yield takeLatest(A.deleteDriverDailyHoursRequest, handleDeleteDriverDailyHoursSaga);
  yield takeLatest(A.createDriverAccessorialRequest, handleCreateDriverAccessorialSaga);
  yield takeLatest(A.deleteDriverCertificateRequest, handleDeleteDriverCertificateSaga);
  yield takeLatest(A.deleteDriverAccessorialRequest, handleDeleteDriverAccessorialSaga);
  yield takeLatest(A.updateDriverAccessorialRequest, handleUpdateDriverAccessorialSaga);
  yield takeLatest(A.createDriverCertificateRequest, handleCreateDriverCertificationSaga);
  yield takeLatest(A.updateDriverCompensationRequest, handleUpdateDriverCompensationSaga);
  yield takeLatest(A.createDriverCompensationRequest, handleCreateDriverCompensationSaga);
  yield takeLatest(A.deleteDriverCompensationRequest, handleDeleteDriverCompensationSaga);
  yield takeLatest(A.updateDriverCertificateRequest, handleUpdateDriverCertificationSaga);
  yield takeLatest(A.getPayrollCompensationRateRequest, handleGetPayrollCompensationSaga);
  yield takeLatest(A.toggleDriverExternalEnableRequest, toggleDriverExternalEnableRequest);
  yield takeLatest(A.updateAdvancePaymentStatusRequest, updateAdvancePaymentStatusRequest);
  yield takeLatest(A.getFuelCardListByDriverRequest, handleGetFuelCardListByDriverRequest);
  yield takeLatest(A.deletePayrollCompensationRequest, handleDeletePayrollCompensationSaga);
  yield takeLatest(A.removePayrollAssessorialRequest, handleRemovePayrollAssessorialRequest);
  yield takeLatest(A.getPayrollAssessorialListRequest, handleGetPayrollAssessorialListRequest);
  yield takeLatest(A.savePayrollCompensationRateRequest, handleSavePayrollCompensationRateSaga);
  yield takeLatest(A.createDriverLatestKnownLocationRequest, handleCreateDriverLatestKnownLocationSaga);
  yield takeLatest(A.getDriverPayrollNumberListByGuidsRequest, getDriverPayrollNumberListByGuidsRequest);
  yield takeLatest(A.getDriverLatestKnownLocationRequest, handleGetDriverLatestKnownLocationRequestSaga);
  yield takeLatest(A.createOrUpdatePayrollAssessorialRequest, handleCreateOrUpdatePayrollAssessorialRequest);
  yield takeLatest(A.downloadDriverCertificationDocumentRequest, downloadDriverCertificationDocumentRequest);
  // deduction settings
  yield takeLatest(A.updateDeductionSettingsRequest, updateDeductionSettingsRequest);
  yield takeLatest(A.getDeductionSettingsListRequest, getDeductionSettingsListRequest);
  // branch configs
  yield takeLatest(A.getBranchConfigsByNamesRequest, getBranchConfigsByNamesRequest);
  // working division
  yield takeLatest(A.changeAssignedToDivisionRequest, changeAssignedToDivisionRequest);
  // monthly payroll deduction
  yield takeLatest(A.getMonthlyPayrollDeductionRequest, getMonthlyPayrollDeductionRequest);
  yield takeLatest(A.deleteMonthlyPayrollDeductionRequest, deleteMonthlyPayrollDeductionRequest);
  yield takeLatest(A.toggleMonthlyPayrollDeductionDetails, handleToggleMonthlyPayrollDeductionDetails);
  yield takeLatest(A.getDriverPayrollChargesIsPaidOnPayrollRequest, getDriverPayrollChargesIsPaidOnPayrollSaga);
  yield takeLatest(A.createOrUpdateMonthlyPayrollDeductionRequest, createOrUpdateMonthlyPayrollDeductionRequest);
  yield takeLatest(A.getDriverPayrollChargesByMonthlyPaymentRequest, getDriverPayrollChargesByMonthlyPaymentSaga);
  // payroll accessorial
  yield takeLatest(A.togglePayrollAssessorialDetails, togglePayrollAssessorialDetails);
  yield takeLatest(A.getPayrollAccessorialIsPaidOnPayrollRequest, getPayrollAccessorialIsPaidOnPayrollRequest);
  yield takeLatest(A.getPayrollChargesByPayrollAccessorialRequest, getDriverPayrollChargesByPayrollAccessorial);
  // safety violation
  yield takeLatest(A.removeSafetyViolationEntityByEntityTypeRequest, removeSafetyViolationEntityByEntityTypeRequest);
  yield takeLatest(
    A.createOrUpdateSafetyViolationEntityByEntityTypeRequest,
    createOrUpdateSafetyViolationEntityByEntityTypeRequest,
  );
}

export default driversWatcherSaga;
