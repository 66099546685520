import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const getConfigsForCloConfigSuccess = createAction('getConfigsForCloConfigSuccess');
export const receivedStatusMessageListForCloSuccess = createAction('receivedStatusMessageListForCloSuccess');
export const getAccessorialConfigListForCloConfigSuccess = createAction('getAccessorialConfigListForCloConfigSuccess');
// default order accessorials
export const removeDefaultOrderAccessorialRequest = createAction('removeDefaultOrderAccessorialRequest');
export const removeDefaultOrderAccessorialSuccess = createAction('removeDefaultOrderAccessorialSuccess');
export const getDefaultOrderAccessorialListRequest = createAction('getDefaultOrderAccessorialListRequest');
export const getDefaultOrderAccessorialListSuccess = createAction('getDefaultOrderAccessorialListSuccess');
export const createOrUpdateDefaultOrderAccessorialRequest =
  createAction('createOrUpdateDefaultOrderAccessorialRequest');
export const createOrUpdateDefaultOrderAccessorialSuccess =
  createAction('createOrUpdateDefaultOrderAccessorialSuccess');
