import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset } from '../../../../new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature fleet
import { fieldSettingsMap } from './field-settings';
//////////////////////////////////////////////////

const fieldNotRequired = Yup.string().nullable(true);
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const FormComponent = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset
      {...G.getFormikPropsToFieldset(props)}
      {...R.pathOr({}, ['optionsForSelect'], props)}
      fields={props.fieldSettings}
      documentTypeOptions={props.documentTypeOptions}
      fieldsWrapperStyles={{ px: '0px', pt: 15, pb: 20, flexDirection: 'column' }}
    />
    <FormFooter2 closeModal={props.closeModal} />
  </form>
);

const makeInitialValues = (entity: Object, entityType: string) => {
  if (G.notContain(entityType, ['document', 'safetyViolation'])) return entity;

  if (R.equals(entityType, 'safetyViolation')) {
    return {
      ...entity,
      [GC.FIELD_TYPE]: R.pathOr(null, [GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], entity),
      [GC.FIELD_SEVERITY]: R.pathOr(null, [GC.FIELD_SEVERITY, GC.FIELD_DROPDOWN_OPTION_GUID], entity),
      disciplinaryAction: R.pathOr(null, ['disciplinaryAction', GC.FIELD_DROPDOWN_OPTION_GUID], entity),
    };
  }

  return {
    ...entity,
    [GC.FIELD_DOCUMENT_UPLOAD]: R.pathOr(null, [GC.FIELD_DOCUMENT_FILE_NAME], entity),
    [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: R.pathOr(
      null,
      [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
      entity,
    ),
  };
};

const enhance = compose(
  withProps(({ entity, entityType }: Object) => {
    const fieldsToPickMap = {
      note: [GC.FIELD_TEXT],
      document: [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DOCUMENT_URL, GC.FIELD_DOCUMENT_UPLOAD],
      safetyViolation: [
        GC.FIELD_TYPE,
        GC.FIELD_SEVERITY,
        'occurrenceDate',
        'disciplinaryAction',
        'disciplinaryDate',
        'eventDescription',
      ],
    };
    const fieldSettings = R.pick(R.prop(entityType, fieldsToPickMap), fieldSettingsMap);
    const defaultFields = R.map(() => null, fieldSettings);
    const initialValues = R.mergeRight(defaultFields, makeInitialValues(entity, entityType));

    const validationSchema = Yup.lazy((values: Object) => {
      let schema = R.map(({ isRequired }: Object) => G.ifElse(
        isRequired,
        fieldRequired,
        fieldNotRequired,
      ), fieldSettings);

      if (R.equals(entityType, 'document')) {
        const { url, file } = values;

        schema = {
          ...schema,
          [GC.FIELD_DOCUMENT_UPLOAD]: G.ifElse(
            G.isNilOrEmpty(url),
            fieldRequired,
            fieldNotRequired,
          ),
          [GC.FIELD_DOCUMENT_URL]: G.ifElse(
            G.isNilOrEmpty(file),
            fieldRequired,
            fieldNotRequired,
          ),
        };
      }

      return Yup.object().shape(schema);
    });

    return { initialValues, validationSchema, fieldSettings: R.values(fieldSettings) };
  }),
  withFormik({
    mapPropsToValues: R.pathOr({}, ['initialValues']),
    validationSchema: R.pathOr({}, ['validationSchema']),
    handleSubmit: (values: Object, { props }: Object) => {
      const { entityType, submitAction } = props;

      let data = values;

      if (R.equals(entityType, 'safetyViolation')) {
        data = G.getValuesWithConvertedDateTimeFields({
          values,
          dateTimeFields: ['occurrenceDate', 'disciplinaryDate'],
        });
      }

      submitAction(data);
    },
  }),
  pure,
);

export default enhance(FormComponent);
