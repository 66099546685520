import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import AddAdvancePaymentForm from '../../../components/advance-payment/add-advance-payment-form';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const selectedRateDrivers = {
  '42926586-9db5-4f47-bb45-4e6114794a3d': {
    'nickName': '',
    'payable': true,
    'employed': true,
    'driverType': null,
    'phoneNumber': null,
    'teamDriver': false,
    'paidByTrip': false,
    'fleetVendor': null,
    'firstName': 'Betty',
    'driverNumber': null,
    'middleInitial': null,
    'fleetVendorGuid': null,
    'autoAcceptRate': false,
    'loginId': '141-92-XXXX',
    'primaryDriverGuid': null,
    'lastName': 'D. Rainwater',
    'showVendorPayroll': false,
    'expeditedDivision': false,
    'secondaryPhoneNumber': null,
    'assignedDispatcherGuid': null,
    'assignedToDivisionGuid': null,
    'locationSharingEnabled': false,
    'fullName': 'Betty D. Rainwater',
    'email': 'BettyDRainwater@jourrapide.com',
    'guid': '42926586-9db5-4f47-bb45-4e6114794a3d',
    'userGuid': '6e30b944-86b5-43fb-b5f7-df876d70696d',
    'enterpriseGuid': 'adab4162-6863-467f-a4f9-cbc3a410cd4d',
    'dispatchingGroupGuid': 'c10ae098-19c1-4869-aec1-2e828aa63892',
  },
};

const optionsForSelect = {
  selectedRateDriverOptions: [
    {
      'label': '',
      'value': '',
    },
    {
      'label': 'Betty, D. Rainwater',
      'value': '42926586-9db5-4f47-bb45-4e6114794a3d',
    },
  ],
};

const enhance = compose(
  withHandlers({
    handleOpen: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <AddAdvancePaymentForm
          closeModal={closeModal}
          submitAction={closeModal}
          optionsForSelect={optionsForSelect}
          selectedRateDrivers={selectedRateDrivers}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 660,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:add-advance-payment', 'Add Advance Payment'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const AddAdvancePaymentFormComponent = (props: Object) => {
  const description = '<AddAdvancePaymentForm /> component encapsulates the logic and rendering of the form fields required for adding advance payment details.';

  const usage = (
    <ul>
      <li>Parameter `<i>handleSubmit</i>` is a function handling form submission.</li>
      <li>Parameter `<i>handleSelectDriver</i>` is a handler function for selecting a driver.</li>
      <li>Parameter `<i>optionsForSelect</i>` is an additional options for select fields in the form.</li>
      <li>Parameter `<i>disableSelectDriverField</i>` is a boolean flag that disable the driver selection field.</li>
      <li>Other props related to formik form management.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      description={description}
      name='AddAdvancePaymentForm'
      path='src/components/advance-payment'
    >
      <CustomButton onClick={props.handleOpen}>Open advance payment modal</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(AddAdvancePaymentFormComponent));
