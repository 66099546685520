import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import EditReport from '../../../components/edit-report';
import { TitlePanel } from '../../../components/title-panel';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { orderFilterProps } from '../../dispatch-report/settings/filter-params';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, FixedBox, ZOrderWrapper } from '../../../ui';
// feature customer-portal
import { tableSettings, columnSettings } from '../settings';
import {
  setReports,
  setUsedReport,
  setTableHeight,
  cleanQuickFilter,
  setTableTitleSort,
  updateReportRequest,
  createReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  getCustomerPortalListRequest as getItemListRequest,
} from '../actions';
import {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectShowBooked,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from '../selectors';
//////////////////////////////////////////////////

const resizeStyles = {
  ml: 10,
  width: 16,
  cursor: 'pointer',
  borderTop: '3px solid',
  borderLeft: '1px solid',
  borderRight: '1px solid',
  borderBottom: '1px solid',
  borderColor: G.getTheme('colors.light.black'),
};

const ChangeTableHeight = ({ tableHeight, setTableHeight }: Object) => (
  <Flex
    ml='auto'
    height={25}
    p='3px 10px'
    width='100%'
    alignItems='flex-end'
  >
    {
      G.notEquals(tableHeight, 0) &&
      <Box
        {...R.omit(['borderTop', 'borderLeft', 'borderRight'], resizeStyles)}
        height='16px'
        borderBottom='4px solid'
        onClick={() => setTableHeight(0)}
        title={G.getWindowLocale('actions:collapse', 'Collapse')}
      />
    }
    {
      G.notEquals(tableHeight, 50) &&
      <Box
        {...resizeStyles}
        height='10px'
        onClick={() => setTableHeight(50)}
        title={G.getWindowLocale('actions:split', 'Split')}
      />
    }
    {
      G.notEquals(tableHeight, 100) &&
      <Box
        {...resizeStyles}
        height='16px'
        onClick={() => setTableHeight(100)}
        title={G.getWindowLocale('actions:expand', 'Expand')}
      />
    }
  </Flex>
);

const enhance = compose(
  withState('originalReport', 'setOriginalReport', null),
  withHandlers({
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleListRequest: ({ getItemListRequest }: Object) => () => getItemListRequest(),
    getQuickFilteredListRequest: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      getItemListRequest();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { reportList, setUsedReport, getItemListRequest } = props;

      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);

      setUsedReport(selectedReport);
      getItemListRequest();
    },
    handleCleanFilter: ({ cleanQuickFilter, getItemListRequest }: Object) => () => {
      cleanQuickFilter();
      getItemListRequest();
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
  }),
  pure,
);

const CustomTitleComponent = (props: Object) => {
  const {
    itemList,
    totalCount,
    showBooked,
    tableHeight,
    setTableHeight,
    getItemListRequest,
    resetListAndPagination,
  } = props;

  const listLength = R.length(itemList);
  const loadMoreOrders = R.and(R.gt(totalCount, 500), R.gt(totalCount, listLength));
  const whiteColor = G.getTheme('colors.white');
  const blueColor = G.getTheme('colors.dark.blue');
  const getTotalCount = G.ifElse(loadMoreOrders, `${listLength} of ${totalCount}`, totalCount);
  const actionButtonStyles = {
    p: '5px',
    ml: '4px',
    fontSize: 12,
    bg: blueColor,
    cursor: 'pointer',
    color: whiteColor,
    borderRadius: '4px',
  };

  return (
    <Flex
      ml={10}
      p='8px'
      fontWeight='bold'
      width='max-content'
      borderTopLeftRadius='8px'
      borderTopRightRadius='8px'
      bg={G.getTheme('colors.light.lightGrey')}
      color={G.getTheme('colors.greyMatterhorn')}
    >
      <Flex>
        {G.getWindowLocale('titles:current-orders', 'Current Orders')} ({getTotalCount})
        <Box ml='5px'>
          <ChangeTableHeight tableHeight={tableHeight} setTableHeight={setTableHeight} />
        </Box>
      </Flex>
      {
        loadMoreOrders &&
        <Box {...actionButtonStyles} onClick={() => getItemListRequest()}>
          {G.getWindowLocale('titles:load-more-orders', 'Load More Orders')}
        </Box>
      }
      <Box
        {...actionButtonStyles}
        onClick={() => {
          resetListAndPagination();
          getItemListRequest({ showBooked: R.not(showBooked) });
        }}
      >
        {G.getWindowLocale(...G.ifElse(
          showBooked,
          ['titles:hide-booked-orders', 'Hide Booked Orders'],
          ['titles:show-booked-orders', 'Show Booked Orders'],
        ))}
      </Box>
      <AuthWrapper has={[PC.ORDER_QUOTE_WRITE]}>
        <Box {...actionButtonStyles} onClick={() => G.goToRoute(GC.ROUTE_PATH_DO_QUOTE)}>
          {G.getWindowLocale('titles:create-quote', 'Create Quote')}
        </Box>
      </AuthWrapper>
    </Flex>
  );
};

const CurrentOrdersReport = (props: Object) => {
  const {
    loading,
    itemList,
    flyToGuid,
    totalCount,
    pagination,
    reportList,
    tableHeight,
    useGoogleMap,
    setFlyToGuid,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleTableTitleFilter,
    handleSetActiveLocation,
  } = props;

  const freezedField = {
    sequence: 1,
    freezed: true,
    name: 'actions',
    reference: false,
  };

  const whiteColor = G.getTheme('colors.white');
  const reportToUse = G.prependFieldToTableReportFields(selectedReport, freezedField);
  const hasPinned = G.hasPinnedReports(R.or(reportList, []));

  const hasSearchCriteria = R.or(
    G.isNotNilAndNotEmpty(filterParams),
    G.isNotNilAndNotEmpty(R.path(['searchCriteria'], selectedReport)),
  );

  let heightSubtract = 40;

  if (hasPinned) heightSubtract = R.add(heightSubtract, 32);

  if (hasSearchCriteria) heightSubtract = R.add(heightSubtract, 34);

  const data = {
    loading,
    pagination,
    totalCount,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    report: reportToUse,
    hasSelectable: false,
    handleTableTitleFilter,
    callbackData: { flyToGuid, setFlyToGuid },
    handleLoadMoreEntities: getItemListRequest,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), orderFilterProps),
    tableSettings: R.assoc('maxHeight', `calc(${tableHeight}vh - ${heightSubtract}px)`, tableSettings),
    itemList: G.ifElse(G.isNotNilAndNotEmpty(G.getPropFromObject('fields', selectedReport)), itemList, []),
    setTableRowHover: G.ifElse(
      R.or(useGoogleMap, R.includes(tableHeight, [0, 100])),
      () => null,
      (item: Object) => handleSetActiveLocation(item),
    ),
    unsetTableRowHover: G.ifElse(
      R.or(useGoogleMap, R.includes(tableHeight, [0, 100])),
      () => null,
      (item: Object) => handleSetActiveLocation(item, 'mouseLeave'),
    ),
  };

  const isZeroTableHeight = G.isZero(tableHeight);

  return (
    <FixedBox
      bottom='0'
      zIndex={10}
      width='calc(100vw - 55px)'
      pt={G.ifElse(R.equals(tableHeight, 100), 15, '0px')}
      background={G.getThemeByCond(R.equals(tableHeight, 100), 'colors.white', '')}
      height={G.ifElse(G.notEquals(tableHeight, 0), `${tableHeight}vh`, 'max-content')}
    >
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          pr={10}
          pt='0px'
          height='auto'
          withCount={true}
          popperWithCount={true}
          reportTemplatesPx={10}
          filterProps={orderFilterProps}
          noExportable={isZeroTableHeight}
          type={GC.CUSTOMER_PORTAL_CLO_REPORT}
          hidePinnedReports={isZeroTableHeight}
          hideReportFilters={isZeroTableHeight}
          withoutQuickFilter={isZeroTableHeight}
          hiddenRightFilterInfo={isZeroTableHeight}
          hideFilterInfo={G.ifElse(isZeroTableHeight, true, null)}
          customTitleComponent={<CustomTitleComponent {...props} />}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        <Box
          bg={whiteColor}
          height={G.ifElse(isZeroTableHeight, 0, '100%')}
          visibility={G.ifElse(isZeroTableHeight, 'hidden')}
        >
          <Table {...data} />
        </Box>
      </ZOrderWrapper>
    </FixedBox>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  showBooked: makeSelectShowBooked(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  setUsedReport,
  setTableHeight,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  updateReportRequest,
  createReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
})(enhance(CurrentOrdersReport));
