import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { hideIf } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const EnhancedBox = hideIf(Box);

const SectionHeader = () => (
  <Flex justifyContent='left' bg={G.getTheme('colors.light.lightGrey')}>
    <Box width='320px' p='10px 20px' justifyContent='left'>
      {G.getWindowLocale('titles:stored-value', 'Stored Value')}
    </Box>
    <Box p='10px 20px'>
      {G.getWindowLocale('titles:user-stored-value', 'User Stored Value')}
    </Box>
  </Flex>
);

const renderItems = (props: Object) => (
  props.dropdownConfig.options.map((item: Object) => (
    <Flex key={item.guid} justifyContent='left'>
      <Flex width='320px' p='10px 20px' justifyContent='left'>
        <Box
          mr='10px'
          cursor='pointer'
          onClick={() => props.handleRemoveItem(item, props.configName)}
        >
          {I.trash(G.getTheme('icons.iconColor'))}
        </Box>
        <EnhancedBox
          mr='10px'
          hideComponent={G.isNotNil(item.parentGuid)}
          onClick={() => props.handleEditItem(item, props.configName, props.editPopupTitle)}
        >
          {I.pencil(G.getTheme('icons.iconColor'))}
        </EnhancedBox>
        <TextComponent title={item.storedValue} display='block' maxWidth='100%' withEllipsis={true}>
          {item.storedValue}
        </TextComponent>
      </Flex>
      <Box p='10px 20px'>
        {item.displayedValue}
      </Box>
    </Flex>
  ))
);

const DropdownConfigGroupComponent = (props: Objects) => (
  <Box bg={G.getTheme('colors.white')}>
    <SectionHeader />
    {renderItems(props)}
  </Box>
);

export default DropdownConfigGroupComponent;

