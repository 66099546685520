import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const selectFormStore = (state: Object) => state.form;
const selectFleetVendorStore = (state: Object) => state.fleetVendor;

const makeSelectCurrentVendor = () => createSelector(
  selectFleetVendorStore,
  ({ currentVendor }: Object) => currentVendor,
);

const makeSelectCurrentVendorGuid = () => createSelector(
  selectFleetVendorStore,
  ({ currentVendor }: Object) => G.getGuidFromObject(currentVendor),
);

const makeSelectCurrentVendorBranchGuid = () => createSelector(
  selectFleetVendorStore,
  R.path(['currentVendor', GC.BRANCH_GUID]),
);

const makeSelectCollapsedGroup = () => createSelector(
  selectFleetVendorStore,
  ({ collapsedGroup }: Object) => collapsedGroup,
);

const makeSelectVendorDocuments = () => (createSelector(
  selectFleetVendorStore,
  ({ documents }: Object) => documents,
));

const makeSelectVendorCompensations = () => (createSelector(
  selectFleetVendorStore,
  ({ compensations }: Object) => compensations,
));

const makeSelectFormErrors = () => createSelector(
  selectFormStore,
  (form: Object) => (
    G.ifElse(
      G.isNotNil(form.EDIT_VENDOR_DETAIL),
      G.getOrElse(form.EDIT_VENDOR_DETAIL, 'syncErrors', {}),
      {},
    )),
);

const makeSelectPayrollAssessorialList = () => createSelector(
  selectFleetVendorStore,
  ({ payrollAssessorialList }: Object) => payrollAssessorialList,
);

// vendor
const makeSelectVendorRefList = () => createSelector(
  selectFleetVendorStore,
  ({ references }: Object) => references,
);

const makeSelectVendorContactList = () => createSelector(
  selectFleetVendorStore,
  ({ contacts }: Object) => contacts,
);

const makeSelectVendorPayToLocation = () => createSelector(
  selectFleetVendorStore,
  ({ payToLocation }: Object) => payToLocation,
);

const makeSelectInsuranceList = () => createSelector(
  selectFleetVendorStore,
  ({ insurances }: Object) => insurances,
);

const makeSelectVendorAccessorials = () => createSelector(
  selectFleetVendorStore,
  ({ accessorials }: Object) => accessorials,
);

const makeSelectVendorFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => R.pathOr({}, ['EDIT_VENDOR_DETAIL', 'values'], form),
);

const makeSelectVendorConfigs = () => createSelector(
  selectFleetVendorStore,
  ({ configs }: Object) => ({
    [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.mapDropdownConfigWithParentGuid(configs, GC.CARRIER_DOCUMENT_TYPE),
  }),
);

const makeSelectVendorAssignment = () => createSelector(
  selectFleetVendorStore,
  ({ assignment }: Object) => assignment,
);

const makeSelectFleetAssignment = () => createSelector(
  selectFleetVendorStore,
  ({ fleetAssignment }: Object) => fleetAssignment,
);

const makeSelectBranchConfigs = () => createSelector(
  selectFleetVendorStore,
  ({ branchConfigs }: Object) => branchConfigs,
);

const makeSelectMonthlyPayrollDeduction = () => createSelector(
  selectFleetVendorStore,
  ({ monthlyPayrollDeduction }: Object) => monthlyPayrollDeduction,
);

const makeSelectIntegrationInfoList = () => createSelector(
  selectFleetVendorStore,
  ({ integrationInfoList }: Object) => integrationInfoList,
);

const makeSelectIntegrationAuditList = () => createSelector(
  selectFleetVendorStore,
  ({ integrationAuditList }: Object) => integrationAuditList,
);

export {
  makeSelectFormErrors,
  makeSelectBranchConfigs,
  makeSelectInsuranceList,
  makeSelectVendorRefList,
  makeSelectVendorConfigs,
  makeSelectCurrentVendor,
  makeSelectCollapsedGroup,
  makeSelectFleetAssignment,
  makeSelectVendorDocuments,
  makeSelectVendorAssignment,
  makeSelectVendorFormValues,
  makeSelectVendorContactList,
  makeSelectCurrentVendorGuid,
  makeSelectVendorAccessorials,
  makeSelectVendorPayToLocation,
  makeSelectVendorCompensations,
  makeSelectIntegrationInfoList,
  makeSelectIntegrationAuditList,
  makeSelectPayrollAssessorialList,
  makeSelectCurrentVendorBranchGuid,
  makeSelectMonthlyPayrollDeduction,
};
