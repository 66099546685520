import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { PageTitle } from '../../components/page-title';
import { QuickFilterOutsideControl } from '../../components/quick-filter';
// features
import { makeSelectComponentsSearch } from './selectors';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, PageWrapper, PageHeaderWrapper } from '../../ui';
// utilities
import * as G from '../../helpers';
// feature components story
import * as Story from './story';
import { setSearchFilter } from './actions';
//////////////////////////////////////////////////

const StoryList = ({ searchFilter }: Object) => {
  const storyList = Object.keys(Story).filter((componentName: string) =>
    G.notEquals(R.indexOf(searchFilter.toLowerCase(), componentName.toLowerCase()), -1));

  return storyList.map((componentName: Function, index: number) => {
    const Component = Story[componentName];
    return <Component key={index} />;
  });
};

export const ComponentsStory = ({
  searchFilter,
  setSearchFilter,
}: Object) => {
  const searchInputColor = G.getTheme('colors.dark.grey');

  return (
    <PageWrapper bgColor={G.getTheme('forms.bgColor')}>
      <PageHeaderWrapper aI='center'>
        <PageTitle title={G.getWindowLocale('titles:components-story', 'Components')} />
        <QuickFilterOutsideControl
          noBlur={true}
          delayTime={500}
          withoutLabel={true}
          outsideValue={searchFilter}
          handleSetFilter={setSearchFilter}
          placeholder={G.getWindowLocale('titles:search', 'Search')}
          endLabel={<Box mr='8px'>{I.search(searchInputColor)}</Box>}
          inputProps={{ p: '0 0 0 8px', height: '28px', withoutBorder: true }}
          boxStyles={{
            mr: 20,
            right: 0,
            border: '1px solid',
            borderRadius: '2px',
            position: 'absolute',
            borderColor: searchInputColor,
            bg: G.getTheme('colors.light.mainLight'),
          }}
        />
      </PageHeaderWrapper>
      <Flex
        p='5px'
        mt={20}
        gap={20}
        flexDirection='column'
        alignItems='flex-start'
      >
        <StoryList searchFilter={searchFilter} />
      </Flex>
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  searchFilter: makeSelectComponentsSearch(state),
}));

export default connect(mapStateToProps, {
  setSearchFilter,
})(ComponentsStory);
