import React from 'react';
// components
import { SearchEmail } from '../../../components/search-email';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const contactList = [
  {
    'lastName': 'admin',
    'firstName': 'admin',
    'email': 'admin@gmail.com',
  },
  {
    'lastName': 'user',
    'firstName': 'user',
    'email': 'user@gmail.com',
  },
];

const SearchEmailComponent = () => {
  const description = '<SearchEmail /> component designed to handle email input fields and manage email addresses for a form.';

  const usage = (
    <ul>
      <li>Parameter `<i>zIndex</i>` is Z-index for the component.</li>
      <li>Parameter `<i>width</i>` set the width of the component.</li>
      <li>Parameter `<i>labelColor</i>` is the color of the label.</li>
      <li>Parameter `<i>fieldName</i>` set the name of the email field.</li>
      <li>Parameter `<i>margin</i>` is a margin applied to the component.</li>
      <li>Parameter `<i>errors</i>` contains error messages related to emails.</li>
      <li>Parameter `<i>title</i>` is the title or label for the email input field.</li>
      <li>Parameter `<i>fieldError</i>` is an error message for the entire email field.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='SearchEmail'
      description={description}
      path='src/components/search-email'
    >
      <form>
        <SearchEmail
          width={300}
          borderRadius='4px'
          emails={contactList}
          setFieldValue={() => {}}
          setFieldError={() => {}}
          contactList={contactList}
          setFieldTouched={() => {}}
          setIsValidEmails={() => {}}
          fieldName={GC.FIELD_DISPATCH_EMAILS}
          labelColor={G.getTheme('colors.darkGrey')}
          borderColor={G.getTheme('colors.veryLightGrey')}
          title={G.getWindowLocale('titles:dispatcher-emails', 'Dispatcher Emails')}
        />
      </form>
    </ComponentStoryWrapper>
  );
};

export default SearchEmailComponent;
