import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Form, Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const getDefaultFields = (withDate: boolean) => {
  const defaultFields = {
    [GC.FIELD_LOCATION]: null,
  };

  if (G.isTrue(withDate)) return R.assoc(GC.FIELD_AVAILABLE_DATE, '', defaultFields);

  return defaultFields;
};

const getAvailabilityFields = (withDate: boolean) => {
  const inputWrapperStyles = {
    mb: 25,
    width: 270,
  };

  let fields = [
    {
      isRequired: true,
      inputWrapperStyles,
      type: 'addressAutocomplete',
      fieldName: GC.FIELD_LOCATION,
      label: ['titles:location', 'Location'],
    },
  ];

  if (G.isTrue(withDate)) {
    fields = R.append(
      {
        isRequired: true,
        type: 'datePicker',
        inputWrapperStyles,
        timeSelection: true,
        minDate: G.getCurrentDate(),
        fieldName: GC.FIELD_AVAILABLE_DATE,
        label: ['titles:available-at', 'Available At'],
      },
      fields,
    );
  }

  return fields;
};

const getValidationSchemaObject = (withDate: boolean) => {
  const validationSchema = {
    [GC.FIELD_LOCATION]: Yup.object()
      .default(null)
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .typeError(G.getSelectLocationLocaleTxt()),
  };

  if (G.isTrue(withDate)) return R.assoc(GC.FIELD_AVAILABLE_DATE, G.yupStringRequired, validationSchema);

  return validationSchema;
};

export const enhanceForm = compose(
  withFormik({
    validationSchema: ({ withDate }: Object) => Yup.object().shape(getValidationSchemaObject(withDate)),
    mapPropsToValues: ({ withDate, initialValues }: Object) => G.setInitialFormikValues(
      getDefaultFields(withDate),
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { withDate, driverGuid, submitAction } = props;

      let data = R.assoc(GC.FIELD_DRIVER_GUID, driverGuid, values);

      if (G.isTrue(withDate)) {
        const { availableDate } = values;

        data = R.assoc(GC.FIELD_AVAILABLE_DATE, G.convertInstanceToDefaultDateTimeFormat(availableDate), data);
      }

      submitAction(data);
    },
  }),
  withHandlers({
    handleSelectLocation: ({ setFieldValue }: Object) => (location: string) =>
      setFieldValue(GC.FIELD_LOCATION, location),
  }),
  pure,
);

export const AvailabilityForm = enhanceForm((props: Object) => {
  const { withDate, handleSubmit, handleSelectLocation } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 15 }}
        fields={getAvailabilityFields(withDate)}
        customSelectLocationFunction={handleSelectLocation}
      />
      <FormFooter2 />
    </Form>
  );
});
