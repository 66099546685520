import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  selectItem,
  setReports,
  setReportType,
  setUsedReport,
  setFilterProps,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  updateReportRequest,
  createReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  getAvailableReportsRequest,
  changeDefaultReportRequest,
} = getReportActions();

const toggleDetails = createAction('toggleDetails');
const deleteItemsRequest = createAction('deleteItemsRequest');
const deleteItemsSuccess = createAction('deleteItemsSuccess');

export {
  // report
  selectItem,
  setReports,
  setUsedReport,
  setReportType,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  updateReportRequest,
  setTableTitleFilter,
  createReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  getAvailableReportsRequest,
  changeDefaultReportRequest,
  // report
  toggleDetails,
  deleteItemsRequest,
  deleteItemsSuccess,
};
