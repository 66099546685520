import React from 'react';
import { pure, compose, withState } from 'react-recompose';
// components
import { SimpleDate } from '../../../components/simple-date';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withState('selectedDate', 'setSelectedDate', '01/12/2024 12:30 AM'),
  pure,
);

const SimpleDateComponent = ({ selectedDate, setSelectedDate }: object) => {
  const description = '<SimpleDate /> component is a date input component with an associated date picker.';

  const usage = (
    <ul>
      <li>Parameter `<i>minDate</i>` is a minimum selectable date.</li>
      <li>Parameter `<i>maxDate</i>` is a maximum selectable date.</li>
      <li>Parameter `<i>initialZIndex</i>` is an initial z-index value.</li>
      <li>Parameter `<i>value</i>` is the current value of the date input.</li>
      <li>Parameter `<i>onSelectDate</i>` is a function called when a date is selected.</li>
      <li>Parameter `<i>formatUsOrUs24</i>` is a format for displaying the date (US or US24).</li>
      <li>Parameter `<i>timeIntervals</i>` is a time interval between time options in the date picker.</li>
      <li>Parameter `<i>zIndexOnClick</i>` is a function called when the date picker is clicked to adjust the z-index.</li>
      <li>Parameter `<i>timeSelection</i>` is a boolean indicating whether to include time selection in the date picker.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='SimpleDate'
      description={description}
      path='src/components/simple-date'
    >
      <SimpleDate
        width={200}
        initialZIndex={1}
        timeIntervals={15}
        value={selectedDate}
        timeSelection={true}
        onSelectDate={setSelectedDate}
      />
    </ComponentStoryWrapper>
  );
};

export default enhance(SimpleDateComponent);
