import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { reportEnhancer } from '../../report-common';
// icons
import * as I from '../../svgs';
// ui
import { PageWrapper, ZOrderWrapper } from '../../ui';
// feature import-report
import { FILTER_PARAMS } from './settings/filter-params';
import { tableSettings } from './components/expanded-details';
import { report, columnSettings } from './settings/table-settings';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  setUsedReport,
  toggleDetails,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemsRequest,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  reportEnhancer,
  withHandlers({
    handleDeleteItems: (props: Object) => () => {
      const { itemList, openModal, closeModal, deleteItemsRequest } = props;

      const selectedList = G.getSelectedItemGuids(itemList);

      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteItemsRequest(selectedList),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: `${G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this')}?`,
      });

      openModal(modalContent);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          disableSetReportFields={true}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    toggleDetails,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleTableTitleFilter,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  const data = {
    report,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    toggle: toggleDetails,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    loading: R.and(loading, G.isNilOrEmpty(itemList)),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const ImportReport = enhance((props: Object) => {
  const { itemList, totalCount, getItemListRequest, handleDeleteItems, resetListAndPagination } = props;

  const listActions = [
    {
      action: handleDeleteItems,
      text: G.getWindowLocale('actions:delete', 'Delete'),
      icon: I.trash(G.getTheme('colors.light.mainLight'), 'transparent'),
    },
  ];

  const reloadAction = () => {
    resetListAndPagination();
    setTimeout(() => getItemListRequest(), 100);
  };

  return (
    <PageWrapper pt={15} pr={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          display='flex'
          withCount={true}
          withReload={true}
          noExportable={true}
          popperWithCount={true}
          totalCount={totalCount}
          filterProps={FILTER_PARAMS}
          reloadAction={reloadAction}
          hiddenRightFilterInfo={false}
          type={GC.IMPORT_REPORT_REPORT}
          title={G.getWindowLocale('titles:import-report', 'Import Report')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <PageActions
        version={2}
        listActions={listActions}
        shadowColor={G.getTheme('createButton.shadowColor')}
        count={R.filter(R.prop('selected'), R.or(itemList, [])).length}
      />
    </PageWrapper>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setReports,
  setUsedReport,
  toggleDetails,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemsRequest,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
})(ImportReport);
