import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../../components/form-buttons';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable } from '../../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../../components/form-group-title';
// features
import References from '../../../reference/components/reference';
import FleetProfilePhoto from '../../../fleet-profile/components/profile-photo';
import { withDocumentActions } from '../../../fleet-profile/hocs/with-document-actions';
// forms
import { Form, TabFormWrapper } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import * as LC from '../../constants';
import { getAdditionaDocumentColumnActions } from '../../helpers';
import IntegrationFormComponent from '../../components/integration-form';
import { withAddOrEditDocument, withAddOrEditInsurance } from '../../hocs';
import { latestKnownLocationSettings } from '../../settings/fields-settings';
import { withAddOrEditLocation } from '../../hocs/with-add-or-edit-location';
import { DocumentsTitlePanel } from '../../components/documents-title-panel';
import { withAddOrEditLastLocation } from '../../hocs/add-or-edit-last-location';
// ui
import { Box, Flex } from '../../../../ui';
// feature fleet/trailer
import { TrailerGroupWrapper } from '../ui';
import { renderField } from './render-field';
import {
  GROUP_NAME_TRAILER_SAMSARA,
  GROUP_NAME_TRAILER_LATES_KNOWN_LOCATION,
} from '../constants';
import {
  makeSelectTrailerRefList,
  makeSelectTrailerLocations,
  makeSelectTrailerDocuments,
  makeSelectTrailerInsurances,
  makeSelectTrailerDocumentsFilter,
} from '../selectors';
import {
  locationColumns,
  documentColumns,
  insuranceColumns,
  integrationSamsaraColumnSettings,
} from '../settings/column-settings';
import {
  ownershipFields,
  costDetailsFields,
  termDetailsFields,
  trailerDetailsFields,
  equipmentReturnFields,
  trailerRegistrationFields,
} from '../settings/fields-settings';
import {
  setDocumentsFilter,
  createTrailerLocationRequest,
  updateTrailerLocationRequest,
  updateTrailerDocumentRequest,
  createTrailerDocumentRequest,
  deleteTrailerDocumentRequest,
  deleteTrailerLocationRequest,
  createTrailerReferenceRequest,
  updateTrailerReferenceRequest,
  deleteTrailerReferenceRequest,
  createTrailerInsuranceRequest,
  updateTrailerInsuranceRequest,
  deleteTrailerInsuranceRequest,
  updateExternalTrailerIdRequest,
  deleteExternalTrailerIdRequest,
  createExternalTrailerIdRequest,
  createTrailerLatestKnownLocationRequest,
} from '../actions';
//////////////////////////////////////////////////

const refEnhance = compose(
  withHandlers({
    handleCreateReference: (props: Object) => (values: Object) => {
      const reqBody = {
        selectedList: R.of(Array, R.path(['initialValues', GC.FIELD_GUID], props)),
        values,
      };
      props.createTrailerReferenceRequest(reqBody);
    },
    handleUpdateReference: ({ updateTrailerReferenceRequest }: Object) => (reqBody: Object) =>
      updateTrailerReferenceRequest(reqBody),
    handleDeleteReference: (props: Object) => (guid: Object) => {
      const component = (
        <ConfirmComponent
          name={R.path([GC.UI_FIELD_REFERENCES, guid, GC.FIELD_NAME], props)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.closeModal();
                props.deleteTrailerReferenceRequest(guid);
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
);

const RenderReferences = refEnhance((props: Object) => (
  <References
    openModal={props.openModal}
    closeModal={props.closeModal}
    scope={GC.REF_SCOPE_NAME_FLEET_TRAILER}
    references={R.prop(GC.UI_FIELD_REFERENCES, props)}
    handleCreateReference={props.handleCreateReference}
    handleUpdateReference={props.handleUpdateReference}
    handleRemoveReference={props.handleDeleteReference}
  />
));

const insuranceEnhance = withAddOrEditInsurance({ fleetType: LC.FLEET_TRAILER });

const integrationEnhance = compose(
  withHandlers({
    handleClickAddExternalId: (props: Object) => () => {
      const createdIntegrations = R.compose(
        R.map(R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE)),
        R.pathOr([], ['externalIntegration']),
      )(props);

      const component = (
        <IntegrationFormComponent
          isEditMode={false}
          closeModal={props.closeModal}
          objectKey={GC.FIELD_TRAILER_GUID}
          createdIntegrations={createdIntegrations}
          submitAction={props.createExternalTrailerIdRequest}
          objectGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          title: G.getWindowLocale('titles:add-integration', 'Add Integration'),
        },
      };

      props.openModal(modal);
    },
    handleClickEditExternalId: (props: Object) => (initialValues: Object) => {
      const component = (
        <IntegrationFormComponent
          isEditMode={true}
          createdIntegrations={[]}
          closeModal={props.closeModal}
          initialValues={initialValues}
          objectKey={GC.FIELD_TRAILER_GUID}
          submitAction={props.updateExternalTrailerIdRequest}
          objectGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:edit-integration', 'Edit Integration'),
        },
      };
      props.openModal(modal);
    },
    handleDeleteSamsaraRow: (props: Object) => ({ guid, externalId }: Object) => {
      const component = (
        <ConfirmComponent
          name={externalId}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.closeModal();
                props.deleteExternalTrailerIdRequest(guid);
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
);

const ExternalIntegration = integrationEnhance((props: Object) => {
  const {
    collapsedGroup,
    assignInfo = {},
    externalIntegration,
    handleToggleFormGroup,
    handleDeleteSamsaraRow,
    handleClickAddExternalId,
    handleClickEditExternalId,
  } = props;

  const { truck, lastName, firstName, externalId, teamDriver } = assignInfo;

  const externalIntegrationAssignment = {
    truck,
    teamDriver,
    [GC.FIELD_PRIMARY_DRIVER]: { lastName, firstName, externalId },
  };
  const entities = R.map((item: Object) => {
    const { type } = item;

    if (G.notEquals(type, GC.FLEET_INTEGRATION_TYPE_SAMSARA)) return item;

    return R.mergeRight(item, externalIntegrationAssignment);
  }, externalIntegration);

  return (
    <FormGroupTable
      entities={entities}
      fields={collapsedGroup}
      groupName={GROUP_NAME_TRAILER_SAMSARA}
      handleDeleteRow={handleDeleteSamsaraRow}
      handleEditRow={handleClickEditExternalId}
      handleAddClick={handleClickAddExternalId}
      entitiesFields={integrationSamsaraColumnSettings}
      panelTitle={G.getWindowLocale('titles:integration', 'Integration')}
      isOpened={R.path(['collapsedGroup', GROUP_NAME_TRAILER_SAMSARA], props)}
      handleToggleFormGroup={() => handleToggleFormGroup(GROUP_NAME_TRAILER_SAMSARA)}
    />
  );
});

const RenderInsuranceGroup = insuranceEnhance((props: Object) => (
  <FormGroupTable
    entities={props.insurances}
    fields={props.collapsedGroup}
    entitiesFields={insuranceColumns}
    groupName={LC.GROUP_NAME_INSURANCE}
    handleEditRow={props.handleEditInsurance}
    handleAddClick={props.handleAddInsurance}
    handleDeleteRow={props.handleDeleteInsurance}
    panelTitle={G.getWindowLocale('titles:insurance', 'Insurance')}
    isOpened={R.path(['collapsedGroup', LC.GROUP_NAME_INSURANCE], props)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.GROUP_NAME_INSURANCE)}
  />
));

const docsEnhance = R.compose(
  withDocumentActions,
  withAddOrEditDocument({ fleetType: LC.FLEET_TRAILER }),
);

const RenderDocsGroup = docsEnhance((props: Object) => {
  const {
    documents,
    handleEditDoc,
    trailerConfigs,
    collapsedGroup,
    handleDeleteDoc,
    handlePreviewDocument,
    handleDownloadDocument,
  } = props;

  const documentTypes = R.pathOr([], [GC.FIELD_DOCUMENT_DOCUMENT_TYPE], trailerConfigs);

  return (
    <FormGroupTable
      entities={documents}
      tableColumnWidth={100}
      fields={collapsedGroup}
      handleEditRow={handleEditDoc}
      entitiesFields={documentColumns}
      handleDeleteRow={handleDeleteDoc}
      groupName={LC.GROUP_NAME_DOCUMENTS}
      isOpened={G.getPropFromObject(LC.GROUP_NAME_DOCUMENTS, collapsedGroup)}
      customPanel={() => <DocumentsTitlePanel {...props} filterOptions={documentTypes} />}
      additionalColumnActions={getAdditionaDocumentColumnActions(
        { handlePreviewDocument, handleDownloadDocument, endpointName: 'trailerDownloadDoc' },
      )}
    />
  );
});

const locationEnhance = withAddOrEditLocation({ fleetType: LC.FLEET_TRAILER });

const RenderLocationGroup = locationEnhance((props: Object) => {
  const {
    locations,
    collapsedGroup,
    handleDeleteLocation,
    handleToggleFormGroup,
    handleClickAddOrEditLocation,
  } = props;

  return (
    <FormGroupTable
      entities={locations}
      fields={collapsedGroup}
      entitiesFields={locationColumns}
      groupName={LC.GROUP_NAME_LOCATIONS}
      handleDeleteRow={handleDeleteLocation}
      handleEditRow={handleClickAddOrEditLocation}
      handleAddClick={handleClickAddOrEditLocation}
      panelTitle={G.getWindowLocale('titles:locations', 'Locations')}
      isOpened={G.getPropFromObject([LC.GROUP_NAME_LOCATIONS], collapsedGroup)}
      handleToggleFormGroup={() => handleToggleFormGroup(LC.GROUP_NAME_LOCATIONS)}
    />
  );
});

const renderLatestKnownLocation = (props: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    fields={props.collapsedGroup}
    entities={props.latestKnownLocations}
    entitiesFields={latestKnownLocationSettings}
    groupName={GROUP_NAME_TRAILER_LATES_KNOWN_LOCATION}
    handleEditRow={props.handleClickAddOrEditLatestKnownLocation}
    handleAddClick={props.handleClickAddOrEditLatestKnownLocation}
    panelTitle={G.getWindowLocale('titles:last-known-location', 'Last Known Location')}
    isOpened={R.path(['collapsedGroup', GROUP_NAME_TRAILER_LATES_KNOWN_LOCATION], props)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(GROUP_NAME_TRAILER_LATES_KNOWN_LOCATION)}
  />
);

const renderGroup = (props: Object, group: Object) => (
  group.fields.map((field: Object, index: number) => {
    if (G.showFormField(field.showField, props.formValues)) return renderField(props, field, index);
    return null;
  })
);

const setFormGroup = ({ formValues }: Object) => (
  G.ifElse(
    R.or(
      R.isNil(formValues.ownershipType),
      R.equals(formValues.ownershipType, 'Own'),
    ),
    [
      trailerDetailsFields,
      trailerRegistrationFields,
      ownershipFields,
      costDetailsFields,
    ],
    [
      trailerDetailsFields,
      trailerRegistrationFields,
      ownershipFields,
      termDetailsFields,
      costDetailsFields,
      equipmentReturnFields,
    ],
  )
);

const enhance = compose(
  withHandlers({
    handleFormSubmit: ({ updateTrailerRequest }: Object) => (values: Object) => updateTrailerRequest(values),
    handleActionForAddOrEditLastLocationHoc: (props: Object) => (values: Object) => (
      props.createTrailerLatestKnownLocationRequest(
        R.assoc('trailerGuid', R.path(['initialValues', 'guid'], props), values),
      )
    ),
  }),
  withAddOrEditLastLocation,
  pure,
);

const TrailerDetailComponent = (props: Object) => (
  <TabFormWrapper>
    <Form minHeight='max-content' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
      {
        setFormGroup(props).map((group: Object, index: number) => (
          <TrailerGroupWrapper
            key={index}
            isOpened={R.path(['collapsedGroup', group.name], props)}
          >
            <FormGroupTitleComponent
              withArrowDown={true}
              text={G.getWindowLocale(group.title)}
              isOpened={R.path(['collapsedGroup', group.name], props)}
              onToggleFormGroup={() => props.handleToggleFormGroup(group.name)} />
            <Flex alignItems='flex-start'>
              <div>{renderGroup(props, group)}</div>
              {
                R.and(G.isZero(index), G.isNotNil(props.trailerGuid)) &&
                <Box mt={10} ml={20}>
                  <FleetProfilePhoto type='trailer' trailerGuid={props.trailerGuid} />
                </Box>
              }
            </Flex>
          </TrailerGroupWrapper>
        ))
      }
      <FormButtons
        {...props}
        zIndex='13'
        width='auto'
        btnText={G.getWindowLocale('actions:update', 'Update')} />
    </Form>
    <ExternalIntegration {...props} />
    {renderLatestKnownLocation(props)}
    <RenderLocationGroup {...props} />
    <RenderInsuranceGroup {...props} />
    <RenderDocsGroup {...props} />
    <RenderReferences {...props} />
  </TabFormWrapper>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  locations: makeSelectTrailerLocations(state),
  documents: makeSelectTrailerDocuments(state),
  insurances: makeSelectTrailerInsurances(state),
  documentsFilter: makeSelectTrailerDocumentsFilter(state),
  [GC.UI_FIELD_REFERENCES]: makeSelectTrailerRefList(state),
}));

export default connect(mapStateToProps, {
  setDocumentsFilter,
  updateTrailerDocumentRequest,
  createTrailerDocumentRequest,
  deleteTrailerDocumentRequest,
  deleteTrailerLocationRequest,
  createTrailerLocationRequest,
  updateTrailerLocationRequest,
  createTrailerReferenceRequest,
  updateTrailerReferenceRequest,
  deleteTrailerReferenceRequest,
  createTrailerInsuranceRequest,
  updateTrailerInsuranceRequest,
  deleteTrailerInsuranceRequest,
  createExternalTrailerIdRequest,
  updateExternalTrailerIdRequest,
  deleteExternalTrailerIdRequest,
  createTrailerLatestKnownLocationRequest,
})(enhance(TrailerDetailComponent));
