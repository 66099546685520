import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
export const getDispatchGroupRequest = createAction('getDispatchGroupRequest');
export const createDispatchGroupRequest = createAction('createDispatchGroupRequest');
export const toggleDispatchGroupDetails = createAction('toggleDispatchGroupDetails');
export const deleteDispatchGroupRequest = createAction('deleteDispatchGroupRequest');
export const deleteDispatchGroupSuccess = createAction('deleteDispatchGroupSuccess');
export const receivedDispatchGroupListSuccess = createAction('receivedDispatchGroupListSuccess');
