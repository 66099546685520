import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectQuickFilteredParams } from '../../report-format/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature carrier
import * as A from '../actions';
import { transformToReportList } from '../helpers';
import { getCarrierRateFilterParams } from '../settings/filter-params';
import {
  contractAwardedLineSettings,
  carrierContractRateSettings } from '../settings/column-settings';
import {
  makeSelectCarrierGuid,
  makeSelectCarrierContract,
  makeSelectCarrierContracts,
  makeSelectCarrierContractRate,
  makeSelectCarrierContractAssessorials,
  makeSelectCarrierContractAwardedLines } from '../selectors';
//////////////////////////////////////////////////

function* handleGetCarrierContractsSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const options = {
      params: { carrierGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierContractsByCarrierGuid, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierContractsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierContractsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCarrierContractsSaga exeption');
  }
}

function* handleGetCarrierContractSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getCarrierContract(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierContractSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierContractSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCarrierContractSaga exeption');
  }
}

function* handleSendCreateCarrierContractSuccessSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const contractGuid = R.prop(GC.FIELD_GUID, payload);
    yield put(A.resetCarrierContractListAndPagination());
    yield put(A.createCarrierContractSuccess(payload));
    yield put(A.getCarrierContractSuccess(payload));
    yield put(A.getCarrierContractAssessorialsRequest(contractGuid));
    yield put(A.getCarrierContractAwardedLinesRequest(contractGuid));
    yield put(A.getCarrierContractRatesRequest({ contractGuid }));
    yield put(A.setActiveTab({ tabName: 'carrierContract' }));
    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleSendCreateCarrierContractSuccessSaga exeption');
  }
}

function* handleUpdateCarrierContractSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'put', endpointsMap.carrierContract, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const contracts = yield select(makeSelectCarrierContracts());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), contracts);
      yield call(G.showToastrMessage, 'success', 'toastr:success:200');
      yield put(A.updateCarrierContractSuccess({ data: res.data, index }));
      yield put(A.setActiveTab({
        tabName: 'carrierFinance',
        tabAction: () => {},
      }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierContractSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCarrierContractSaga exeption');
  }
}

function* handleGetCarrierContractAwardedLinesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { contractGuid: payload },
      data: {
        currentEnterprise: currentBranchGuid,
        fields: R.keys(contractAwardedLineSettings).map(
          (name: string, sequence: number) => ({
            name,
            sequence,
            reference: false,
            collection: R.pathOr(false, [name, 'collection'], contractAwardedLineSettings),
          }),
        ),
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierContractAwardedLineList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierContractAwardedLinesSuccess(data.results));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierContractAwardedLinesSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCarrierContractAwardedLinesSaga exeption');
  }
}

function* handleCreateCarrierContractAwardedLineSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const reqData = R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, payload);
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.carrierContractAwardedLine,
      { data: reqData },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
      const listFormat = transformToReportList(data, contractAwardedLineSettings);
      yield put(A.createCarrierContractAwardedLineSuccess(listFormat));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierContractAwardedLineSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateCarrierContractAwardedLineSaga exeption');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateCarrierContractAwardedLineSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const reqData = R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, payload);
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.carrierContractAwardedLine,
      { data: reqData },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const awardedLines = yield select(makeSelectCarrierContractAwardedLines());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), awardedLines);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      const listFormat = transformToReportList(data, contractAwardedLineSettings);
      yield put(A.updateCarrierContractAwardedLineSuccess({ data: listFormat, index }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierContractAwardedLineSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateCarrierContractAwardedLineSaga exeption');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteCarrierContractAwardedLineSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.of(Array, payload),
    };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.carrierContractAwardedLine,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const awardedLine = yield select(makeSelectCarrierContractAwardedLines());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), awardedLine);
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      yield put(A.deleteCarrierContractAwardedLineSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierContractAwardedLineSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteCarrierContractAwardedLineSaga exeption');
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
  }
}

export function* handleGetCarrierContractRatesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentContract = yield select(makeSelectCarrierContract());
    const filterParams = yield select(makeSelectQuickFilteredParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const contractGuid = G.ifElse(
      G.isNotNilAndNotEmpty(currentContract),
      R.propOr('', 'guid', currentContract),
      R.propOr('', 'contractGuid', payload),
    );
    const { pagination } = yield select(makeSelectCarrierContractRate());
    const fields = R.prepend(
      {
        sequence: 100,
        name: 'version',
        reference: false,
        collection: false,
      },
      getCarrierRateFilterParams.map(
        ({ value, collection }: string, i: number) => ({
          collection,
          sequence: i,
          name: value,
          reference: false,
        }),
      ),
    );
    const options = {
      params: { contractGuid },
      data: {
        fields,
        ...pagination,
        currentEnterprise: currentBranchGuid,
        searchCriteria: G.ifElse(R.isEmpty(filterParams), [], R.of(Array, filterParams)),
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierRatePriceList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierContractRatesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierRatesSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetCarrierRatesSaga exeption');
  }
}

function* handleCreateCarrierContractRateSaga({ payload }: Object) {
  const { resetForm, saveAndAddNew, setSubmitting } = payload;

  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.omit(['resetForm', 'saveAndAddNew', 'setSubmitting'], payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierRatePrice, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      const listFormat = transformToReportList(data, carrierContractRateSettings);
      yield put(A.createCarrierContractRateSuccess(listFormat));
      if (G.isTrue(saveAndAddNew)) {
        G.callFunction(resetForm);
      } else {
        yield put(closeModal());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierContractRateSaga fail');
    }
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
  } catch (error) {
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
    yield call(G.handleException, error, 'handleCreateCarrierContractRateSaga exeption');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateCarrierContractRateSaga({ payload }: Object) {
  const { setSubmitting } = payload;

  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.omit(['resetForm', 'saveAndAddNew', 'setSubmitting'], payload),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.carrierRatePrice, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const { list } = yield select(makeSelectCarrierContractRate());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), list);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      const listFormat = transformToReportList(data, carrierContractRateSettings);
      yield put(A.updateCarrierContractRateSuccess({ data: listFormat, index }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierContractRateSaga fail');
    }
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
  } catch (error) {
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
    yield call(G.handleException, error, 'handleUpdateCarrierContractRateSaga exeption');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteCarrierContractRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierRatePriceEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const { list } = yield select(makeSelectCarrierContractRate());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), list);
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      yield put(A.deleteCarrierContractRateSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierContractRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCarrierContractRateSaga exeption');
  }
}

function* handleGetCarrierContractAssessorialsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: { contractGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.carrierContractAssessorialList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierContractAssessorialsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierContractAssessorialsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCarrierContractAssessorialsSaga exeption');
  }
}

function* handleCreateCarrierContractAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.carrierContractAssessorial, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
      yield put(A.createCarrierContractAssessorialSuccess(data));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierContractAssessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleCreateCarrierContractAssessorialSaga exeption');
  }
}

function* handleUpdateCarrierContractAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.carrierContractAssessorial,
      { data: payload },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const accessorials = yield select(makeSelectCarrierContractAssessorials());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), accessorials);
      yield call(G.showToastrMessage, 'success', 'toastr:success:200');
      yield put(A.updateCarrierContractAssessorialSuccess({ data: res.data, index }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierContractAssessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCarrierContractAssessorialSaga exeption');
  }
}

function* handleDeleteCarrierContractAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: R.of(Array, payload) };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.carrierContractAssessorial,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const accessorials = yield select(makeSelectCarrierContractAssessorials());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), accessorials);
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
      yield put(A.deleteCarrierContractAssessorialSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierContractAssessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCarrierContractAssessorialSaga exeption');
  }
}

function* handleDeleteCarrierContractSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: R.of(Array, payload) };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.carrierContract,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const contracts = yield select(makeSelectCarrierContracts());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), contracts);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
      yield put(A.deleteCarrierContractSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierContractSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteCarrierContractSaga exeption');
  }
}

function* removeCarrierContractRatesRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: payload };
    const res = yield call(sendRequest, 'delete', endpointsMap.deleteCarrierRatePrices, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetCarrierContractListAndPagination());
      yield put(A.getCarrierContractRatesRequest());
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeCarrierContractRatesRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierContractRatesRequest exeption');
  }
}

export function* CarrierContractSaga() {
  yield takeLatest(A.getCarrierContractRequest, handleGetCarrierContractSaga);
  yield takeLatest(A.getCarrierContractsRequest, handleGetCarrierContractsSaga);
  yield takeLatest(A.updateCarrierContractRequest, handleUpdateCarrierContractSaga);
  yield takeLatest(A.deleteCarrierContractRequest, handleDeleteCarrierContractSaga);
  yield takeLatest(A.getCarrierContractRatesRequest, handleGetCarrierContractRatesSaga);
  yield takeLatest(A.removeCarrierContractRatesRequest, removeCarrierContractRatesRequest);
  yield takeLatest(A.createCarrierContractRateRequest, handleCreateCarrierContractRateSaga);
  yield takeLatest(A.updateCarrierContractRateRequest, handleUpdateCarrierContractRateSaga);
  yield takeLatest(A.deleteCarrierContractRateRequest, handleDeleteCarrierContractRateSaga);
  yield takeLatest(A.getCarrierContractAssessorialsRequest, handleGetCarrierContractAssessorialsSaga);
  yield takeLatest(A.getCarrierContractAwardedLinesRequest, handleGetCarrierContractAwardedLinesSaga);
  yield takeLatest(A.handleSendCreateCarrierContractSuccess, handleSendCreateCarrierContractSuccessSaga);
  yield takeLatest(A.createCarrierContractAwardedLineRequest, handleCreateCarrierContractAwardedLineSaga);
  yield takeLatest(A.updateCarrierContractAwardedLineRequest, handleUpdateCarrierContractAwardedLineSaga);
  yield takeLatest(A.deleteCarrierContractAwardedLineRequest, handleDeleteCarrierContractAwardedLineSaga);
  yield takeLatest(A.createCarrierContractAssessorialRequest, handleCreateCarrierContractAssessorialSaga);
  yield takeLatest(A.updateCarrierContractAssessorialRequest, handleUpdateCarrierContractAssessorialSaga);
  yield takeLatest(A.deleteCarrierContractAssessorialRequest, handleDeleteCarrierContractAssessorialSaga);
}

export default CarrierContractSaga;
