import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { Tabs2, withTabs2 } from '../../../components/tabs-mui';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { startPivotTable } from '../../pivot-table/actions';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectPivotTableTotalCount } from '../../pivot-table/selectors';
import ReferenceFormComponent from '../../reference/components/reference-form';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import PivotTableWrapper from '../../pivot-table/components/pivot-table-wrapper';
import {
  makeSelectAllAvailableCloReferenceTypes,
  makeSelectAvailableReferenceTypesByScope,
} from '../../reference/selectors';
// forms
import SelectPrintTemplateForm from '../../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
import { reportEnhancer } from '../../../report-common';
// icons
import * as I from '../../../svgs';
// ui
import {
  IconWrapper,
  ListWrapper,
  ZOrderWrapper,
} from '../../../ui';
// utilities
import { getOrderEntryRoute } from '../../../utilities/routes';
// feature dispatch-report
import { RowActions } from './components/row-actions';
import { getOrderTabs } from '../settings/tabs-options';
import { orderFilterProps } from '../settings/filter-params';
import { cloTelTableSettings } from '../settings/table-settings';
import { orderColumnSettings as columnSettings } from '../settings/column-settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setUsedReport,
  cleanQuickFilter,
  cancelCLORequest,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  createReferenceRequest,
  resetListAndPagination,
  exportReportDataRequest,
  changeCancelStatusRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withTabs2,
  withFixedPopover,
  reportEnhancer,
  withPromptModal(orderFilterProps),
  withHandlers({
    handleAddReferenceList: (props: Object) => () => {
      const {
        itemList,
        openModal,
        createReferenceRequest,
      } = props;

      const isSelected = (item: Object) => item.selected;

      const selectedList = R.map(
        (item: Object) => item.guid,
        R.filter(isSelected, itemList),
      );

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const multiAddingEntRef = (values: Object) => {
        const params = R.assoc('primaryObjectGuids', selectedList, values);
        createReferenceRequest(params);
      };

      const component = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_CLO}
          submitAction={(params: Object) => multiAddingEntRef(params)}
        />
      );

      const modal = G.createAddReferenceModalOptions(component);

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            entity={data}
            openModal={props.openModal}
            openLoader={props.openLoader}
            closeModal={props.closeModal}
            closeLoader={props.closeLoader}
            closeFixedPopup={props.closeFixedPopup}
            cancelCLORequest={props.cancelCLORequest}
            currentBranchGuid={props.currentBranchGuid}
            getItemListRequest={props.getItemListRequest}
            resetListAndPagination={props.resetListAndPagination}
            changeCancelStatusRequest={props.changeCancelStatusRequest}
            handleDeleteClo={(guid: string) => props.deleteItemRequest(guid)}
            referenceTypeOptions={G.getOptionsFromDataArrayByPath(
              [GC.FIELD_NAME],
              [GC.FIELD_GUID],
              props.cloRefTypes,
            )}
          />
        ),
      })
    ),
    handlePrint: ({ openModal, printRequest }: Object) => () => {
      const component = (
        <SelectPrintTemplateForm
          submitAction={(values: Object) => printRequest(values)}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CLO_REPORT}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-options', 'Print Options'));

      openModal(modal);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          hasIssues={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          updateReportRequest={updateReportRequest}
          createReportRequest={createReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleDelete: (props: Object) => (guid: string) => {
      const {
        openModal,
        closeModal,
        deleteItemRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteItemRequest(guid),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
      });

      openModal(modalContent);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    handleDelete,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const freezedField = {
    sequence: 1,
    freezed: true,
    reference: false,
    name: 'orderDetails',
  };

  const reportToUse = G.prependFieldToTableReportFields(selectedReport, freezedField);

  const elementActionsComponent = (data: Object) => (
    <AuthWrapper has={[PC.CLO_READ, PC.CLO_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  const rowBgFunction = (guid: string, item: Object) => G.ifElse(
    R.and(G.isTrue(R.prop(GC.FIELD_LOAD_APPOINTMENT_REQUIRED, item)), selectedReport.showIssues),
    G.getTheme('colors.light.warningBackground'),
    null,
  );

  const actionButtons = [
    {
      iconName: 'trash',
      action: handleDelete,
      permissions: [PC.CLO_DELETE_EXECUTE],
    },
  ];

  const tableSettings = G.getTableSettingsWithMaxHeightByConditions({
    reportList,
    filterParams,
    selectedReport,
    tableSettings: cloTelTableSettings,
  });

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    rowBgFunction,
    actionButtons,
    tableSettings,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    report: reportToUse,
    hasSelectable: true,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(orderFilterProps),
    ),
  };

  return <Table {...data} />;
};

const OrderListComponent = (props: Object) => {
  const {
    itemList,
    activeTab,
    totalCount,
    handlePrint,
    getXMLRequest,
    startPivotTable,
    handleSetActiveTab,
    pivotTableTotalCount,
    handleAddReferenceList,
  } = props;

  const tabsOptions = getOrderTabs(activeTab);
  const isClo = R.equals(activeTab, 0);

  const handleClickTab = (i: number) => {
    if (isClo) startPivotTable(GC.PIVOT_CLO_REPORT);

    handleSetActiveTab(i);
  };

  const count = G.ifElse(isClo, totalCount, pivotTableTotalCount);

  const customTitleComponent = (
    <Tabs2
      count={count}
      tabs={tabsOptions}
      activeTab={activeTab}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  if (R.not(isClo)) {
    return (
      <PivotTableWrapper
        {...props}
        tabs={customTitleComponent}
        relativeFlexStyles={{ paddingTop: 15 }}
      />
    );
  }

  const mainLightColor = G.getTheme('colors.light.mainLight');

  const listActionsOpt = [
    {
      action: getXMLRequest,
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      action: handlePrint,
      icon: I.printer(mainLightColor, 20, 20),
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      text: G.getWindowLocale('actions:print', 'Print'),
    },
    {
      type: 'massAction',
      action: handleAddReferenceList,
      permissions: [PC.CLO_REFERENCE_WRITE],
      icon: I.plusRound(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    },
  ];

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          height='auto'
          withCount={true}
          type={GC.CLO_REPORT}
          popperWithCount={true}
          hiddenRightFilterInfo={false}
          filterProps={orderFilterProps}
          customTitleComponent={customTitleComponent}
          title={G.getWindowLocale('titles:clo-list', 'CLO List')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.CLO_REFERENCE_WRITE]}>
        <PageActions
          listActions={listActionsOpt}
          shadowColor={G.getTheme('createButton.shadowColor')}
          count={R.filter((item: Object) => item.selected, R.or(itemList, [])).length}
          mainAction={{
            permissions: [PC.CLO_WRITE],
            action: () => G.goToRoute(getOrderEntryRoute()),
            text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  pivotTableTotalCount: makeSelectPivotTableTotalCount(state),
  cloRefTypes: makeSelectAllAvailableCloReferenceTypes(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  openLoader,
  selectItem,
  closeLoader,
  printRequest,
  setUsedReport,
  getXMLRequest,
  startPivotTable,
  cleanQuickFilter,
  cancelCLORequest,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  createReferenceRequest,
  resetListAndPagination,
  exportReportDataRequest,
  changeCancelStatusRequest,
  changeDefaultReportRequest,
})(enhance(OrderListComponent));
