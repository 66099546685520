import React from 'react';
import * as R from 'ramda';
// components
import InsuranceForm from '../../../components/insurance/insurance-form';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const fields = {
  [GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE]: {
    name: 'titles:effective-date',
  },
  [GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE]: {
    name: 'titles:expiration-date',
  },
  [GC.FIELD_CARRIER_INSURANCE_INSURANCE_NAME]: {
    name: 'titles:insurance-name',
  },
  [GC.FIELD_CARRIER_INSURANCE_TYPE]: {
    name: 'titles:insurance-type',
    customComponent: R.path(['data', GC.FIELD_CARRIER_INSURANCE_TYPE, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_CARRIER_INSURANCE_POLICY_NUMBER]: {
    name: 'titles:policy-number',
  },
  [GC.FIELD_CARRIER_INSURANCE_DESCRIPTION]: {
    width: 300,
    name: 'titles:description',
  },
  [GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT]: {
    name: 'titles:coverage-amount',
  },
};

export const insuranceSettings = {
  fields,
  groupName: 'insurance',
  itemTitleArr: ['titles:insurance', 'Insurance'],
  formGroupTitleArr: ['titles:insurances', 'Insurances'],
  endpoints: {
    list: 'carrierInsuranceList',
    createOrUpdate: 'carrierInsurance',
    remove: 'getCarrierInsuranceEndpoint',
  },
  // components
  CustomForm: (props: Object) => (
    <InsuranceForm
      {...props}
      submitAction={({ values, ...submitProps }: Object) => props.submitAction(values, submitProps)}
    />
  ),
  // helpers
  makeInitialValues: (initialValues: Object = {}) => ({
    ...initialValues,
    [GC.FIELD_CARRIER_INSURANCE_TYPE]: R.path(
      [GC.FIELD_CARRIER_INSURANCE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
      initialValues,
    ),
  }),
};
