import * as R from 'ramda';
import { Rnd } from 'react-rnd';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { closeModal } from '../../components/modal/actions';
import { HandResize } from '../../components/resizable/settings';
// common
import {
  visitNewDoPage,
  visitCloListPage,
  visitTelListPage,
  visitLoadBoardsPage,
  visitDriverCardsPage,
  visitCarrierProfilePage,
  visitNewDOQuoteEditPage,
  visitDispatchBoardCloPage,
  visitCarrierProfilePageV2,
  visitDispatchBoardTelPage,
  visitFleetProfileTruckPage,
  visitFleetTruckProfilePage,
  visitFleetDriverProfilePage,
  visitFleetVendorProfilePage,
  visitFleetProfileDriverPage,
  visitFleetProfileVendorPage,
  visitFleetProfileTrailerPage,
  visitDispatchDetailsLoadPage,
  visitFleetTrailerProfilePage,
  visitDispatchDetailsOrderPage,
  visitCarrierContractDetailsPage,
  visitCustomerContractDetailsPage,
  visitClaimManagementClaimDetailsPage,
} from '../../common/actions';
// features
import NewDOComponent from '../new-do';
import LoadBoards from '../load-board';
import DriversCard from '../drivers-card';
import NewQuoteComponent from '../new-do-quote';
import CarrierScorecards from '../carrier-scorecards';
import CarrierStatisticReport from '../carrier-statistic-report';
import CarrierEditComponent from '../carrier/components/edit-form';
import LoadDetailsNewComponent from '../dispatch-details-new/load';
import OrderDetailsNewComponent from '../dispatch-details-new/order';
import CarrierRateRequestReport from '../carrier-rate-request-report';
import ClaimManagementClaimDetails from '../claim-management/details';
import { getLoadDispatchBoardDetailsRequest } from '../dispatch-board-new/load/actions';
import { getOrderDispatchBoardDetailsRequest } from '../dispatch-board-new/order/actions';
import { getLoadDriverCarrierRateListRequest } from '../dispatch-details-new/load/actions';
import { getOrderDetailsRequest as getDispatchDetailsOrderRequest } from '../dispatch-details-new/order/actions';
import {
  resetCloEventListAndPagination,
  refreshCloEventListWithSelectedEvents,
} from '../dispatch-planner-events/actions';
import {
  getCustomerPortalListRequest,
  resetListAndPagination as resetCustomerPortalListAndPagination,
} from '../customer-portal/actions';
// fleet
import FleetTruckProfile from '../fleet/truck/components/edit-form';
import FleetDriverProfile from '../fleet/driver/components/edit-form';
import FleetVendorProfile from '../fleet/vendor/components/edit-form';
import FleetTrailerProfile from '../fleet/trailer/components/edit-form';
// fleet-v2
import FleetProfileTruck from '../fleet-profile/truck';
import FleetProfileDriver from '../fleet-profile/driver';
import FleetProfileVendor from '../fleet-profile/vendor';
import FleetProfileTrailer from '../fleet-profile/trailer';
// carrier
import CarrierProfile from '../carrier-profile';
import CarrierContractDetails from '../carrier-profile/contracts/carrier';
import CustomerContractDetails from '../carrier-profile/contracts/customer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, FixedBox, AbsoluteBox, RelativeFlex } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature expanded container
import { setExpandedContainerOptions } from './actions';
import { makeSelectExpandedContainerOptions } from './selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      closeModal,
      visitNewDoPage,
      visitCloListPage,
      visitTelListPage,
      visitLoadBoardsPage,
      visitDriverCardsPage,
      visitNewDOQuoteEditPage,
      visitCarrierProfilePage,
      visitCarrierProfilePageV2,
      visitDispatchBoardCloPage,
      visitDispatchBoardTelPage,
      // socketTelDisconnectRequest,
      visitFleetTruckProfilePage,
      visitFleetProfileTruckPage,
      visitFleetProfileDriverPage,
      visitFleetProfileVendorPage,
      visitFleetDriverProfilePage,
      visitFleetVendorProfilePage,
      setExpandedContainerOptions,
      visitFleetProfileTrailerPage,
      visitFleetTrailerProfilePage,
      visitDispatchDetailsLoadPage,
      getCustomerPortalListRequest,
      visitDispatchDetailsOrderPage,
      getDispatchDetailsOrderRequest,
      resetCloEventListAndPagination,
      visitCarrierContractDetailsPage,
      visitCustomerContractDetailsPage,
      getLoadDispatchBoardDetailsRequest,
      getOrderDispatchBoardDetailsRequest,
      getLoadDriverCarrierRateListRequest,
      resetCustomerPortalListAndPagination,
      visitClaimManagementClaimDetailsPage,
      refreshCloEventListWithSelectedEvents,
    },
  ),
  withHandlers({
    handleGoBack: (props: Object) => () => {
      const { expandedContainerOptions, setExpandedContainerOptions } = props;

      const componentsHistory = R.propOr([], 'componentsHistory', expandedContainerOptions);
      const prevComponent = R.prop(R.subtract(R.length(componentsHistory), 2), componentsHistory);

      if (G.isNilOrEmpty(prevComponent)) return;

      const { componentType, visitPageGuid } = prevComponent;

      setExpandedContainerOptions({
        ...expandedContainerOptions,
        componentType,
        visitPageGuid,
        componentsHistory: R.dropLast(2, componentsHistory),
      });
    },
    handleCloseExpandedContainer: (props: Object) => (notUseVisitPageAction: boolean = false) => {
      const {
        location,
        visitCloListPage,
        visitTelListPage,
        visitDriverCardsPage,
        visitDispatchBoardCloPage,
        visitDispatchBoardTelPage,
        // socketTelDisconnectRequest,
        setExpandedContainerOptions,
        getCustomerPortalListRequest,
        getDispatchDetailsOrderRequest,
        resetCloEventListAndPagination,
        getLoadDispatchBoardDetailsRequest,
        getOrderDispatchBoardDetailsRequest,
        getLoadDriverCarrierRateListRequest,
        visitClaimManagementClaimDetailsPage,
        resetCustomerPortalListAndPagination,
        refreshCloEventListWithSelectedEvents,
        expandedContainerOptions: { options, componentType },
      } = props;

      const guid = G.getGuidFromObject(options);
      const routePath = G.getPropFromObject('pathname', location);

      const dispatchDetailsOpened = R.includes(
        componentType,
        [GC.PAGE_DISPATCH_DETAILS_NEW_LOAD, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER],
      );

      const visitPageActionsMap = {
        // TODO: check how to handle close here
        // [GC.ROUTE_PATH_DRIVERS_CARD]: visitDriverCardsPage,
        [GC.ROUTE_PATH_CLO_REPORT_LIST]: () => visitCloListPage({ reportType: GC.CLO_REPORT }),
        [GC.ROUTE_PATH_TEL_REPORT_LIST]: () => visitTelListPage({ reportType: GC.TEL_REPORT }),
        [GC.ROUTE_PATH_DISPATCH_BOARD_TEL]: () => {
          const guid = G.getGuidFromObject(options);

          if (G.isNotNilAndNotEmpty(guid)) {
            getLoadDispatchBoardDetailsRequest(guid);
          } else {
            visitDispatchBoardTelPage();
          }
        },
        [GC.ROUTE_PATH_DISPATCH_BOARD_CLO]: () => {
          const guid = G.getGuidFromObject(options);

          if (G.isNotNilAndNotEmpty(guid)) {
            getOrderDispatchBoardDetailsRequest(guid);
          } else {
            visitDispatchBoardCloPage();
          }
        },
        [GC.ROUTE_PATH_CUSTOMER_PORTAL]: () => {
          resetCustomerPortalListAndPagination();
          getCustomerPortalListRequest();
        },
        [routesMap.dispatchDetailsLoad(guid)]: () => {
          if (R.equals(componentType, GC.PAGE_CARRIER_PROFILE)) {
            getLoadDriverCarrierRateListRequest();
          }

          if (R.equals(componentType, GC.PAGE_TYPE_CLAIM__DETAILS)) {
            G.callFunction(options.callback);
          }
        },
        [routesMap.dispatchDetailsOrder(guid)]: () => {
          if (R.equals(componentType, GC.PAGE_CARRIER_PROFILE)) {
            getDispatchDetailsOrderRequest(guid);
          }

          if (R.equals(componentType, GC.PAGE_TYPE_CLAIM__DETAILS)) {
            G.callFunction(options.callback);
          }
        },
        [routesMap.claimManagementClaimDetails(guid)]: () => {
          visitClaimManagementClaimDetailsPage({ guid });
        },
        [GC.ROUTE_PATH_LOAD_PLANNER]: () => {
          if (dispatchDetailsOpened) {
            resetCloEventListAndPagination();
            refreshCloEventListWithSelectedEvents();
          }
        },
      };

      // if (dispatchDetailsOpened) socketTelDisconnectRequest('deactivate');

      setExpandedContainerOptions({ opened: false, componentType: null, componentsHistory: [] });

      if (G.isTrue(notUseVisitPageAction)) return;

      G.callFunction(G.getPropFromObject(routePath, visitPageActionsMap));
    },
    handleChangeComponent: (props: Object) => () => {
      const {
        visitNewDoPage,
        visitLoadBoardsPage,
        visitDriverCardsPage,
        visitCarrierProfilePage,
        visitNewDOQuoteEditPage,
        expandedContainerOptions,
        visitCarrierProfilePageV2,
        visitFleetProfileTruckPage,
        visitFleetTruckProfilePage,
        visitFleetDriverProfilePage,
        visitFleetVendorProfilePage,
        visitFleetProfileDriverPage,
        visitFleetProfileVendorPage,
        setExpandedContainerOptions,
        visitFleetTrailerProfilePage,
        visitDispatchDetailsLoadPage,
        visitFleetProfileTrailerPage,
        visitDispatchDetailsOrderPage,
        visitCarrierContractDetailsPage,
        visitCustomerContractDetailsPage,
        visitClaimManagementClaimDetailsPage,
      } = props;

      const { componentsHistory, componentType, visitPageGuid } = expandedContainerOptions;

      const visitPageActionsMap = {
        [GC.PAGE_NEW_DO]: visitNewDoPage,
        [GC.PAGE_LOAD_BOARDS]: visitLoadBoardsPage,
        [GC.PAGE_DRIVERS_CARD]: visitDriverCardsPage,
        [GC.PAGE_NEW_DO_QUOTE_EDIT]: visitNewDOQuoteEditPage,
        [GC.PAGE_DISPATCH_DETAILS_NEW_LOAD]: visitDispatchDetailsLoadPage,
        [GC.PAGE_DISPATCH_DETAILS_NEW_ORDER]: visitDispatchDetailsOrderPage,
        [GC.PAGE_TYPE_CLAIM__DETAILS]: visitClaimManagementClaimDetailsPage,
        // fleet
        [GC.PAGE_FLEET_TRUCK_PROFILE]: visitFleetTruckProfilePage,
        [GC.PAGE_FLEET_DRIVER_PROFILE]: visitFleetDriverProfilePage,
        [GC.PAGE_FLEET_VENDOR_PROFILE]: visitFleetVendorProfilePage,
        [GC.PAGE_FLEET_TRAILER_PROFILE]: visitFleetTrailerProfilePage,
        // fleet-v2
        [GC.PAGE_FLEET_TRUCK_PROFILE_V2]: visitFleetProfileTruckPage,
        [GC.PAGE_FLEET_DRIVER_PROFILE_V2]: visitFleetProfileDriverPage,
        [GC.PAGE_FLEET_VENDOR_PROFILE_V2]: visitFleetProfileVendorPage,
        [GC.PAGE_FLEET_TRAILER_PROFILE_V2]: visitFleetProfileTrailerPage,
        // carrier
        [GC.PAGE_CARRIER_CONTRACT_DETAILS]: visitCarrierContractDetailsPage,
        [GC.PAGE_CUSTOMER_CONTRACT_DETAILS]: visitCustomerContractDetailsPage,
        [GC.PAGE_CARRIER_PROFILE]: G.ifElse(
          G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_CARRIER_PROFILE),
          visitCarrierProfilePageV2,
          visitCarrierProfilePage,
        ),
      };

      setExpandedContainerOptions({
        ...expandedContainerOptions,
        componentsHistory: R.append({ componentType, visitPageGuid }, R.or(componentsHistory, [])),
      });

      G.callFunctionWithArgs(
        G.getPropFromObject(componentType, visitPageActionsMap),
        { editPage: true, [GC.FIELD_GUID]: visitPageGuid },
      );
    },
  }),
  withHandlers({
    handleCloseExpandedContainerFromKey: ({ handleCloseExpandedContainer }: Object) => (e: Object) => {
      if (R.equals(e.keyCode, GC.EVENT_KEY_CODE_ESC)) handleCloseExpandedContainer();
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        setZIndex,
        modalOpened,
        handleChangeComponent,
        handleCloseExpandedContainerFromKey,
      } = this.props;

      document.addEventListener('keyup', handleCloseExpandedContainerFromKey);

      handleChangeComponent();

      if (R.not(modalOpened)) {
        setZIndex(11);
      } else {
        setZIndex(1301);
      }
    },
    componentWillUnmount() {
      document.removeEventListener('keyup', this.props.handleCloseExpandedContainerFromKey);
    },
    componentDidUpdate(prevProps: Object) {
      const {
        location,
        handleChangeComponent,
        expandedContainerOptions,
        handleCloseExpandedContainer,
      } = this.props;

      const pathname = G.getPropFromObject('pathname', location);
      const prevPathname = R.path(['location', 'pathname'], prevProps);

      if (G.notEquals(pathname, prevPathname)) return handleCloseExpandedContainer();

      const componentType = G.getPropFromObject('componentType', expandedContainerOptions);
      const prevComponentType = R.path(['expandedContainerOptions', 'componentType'], prevProps);

      if (G.notEquals(componentType, prevComponentType)) handleChangeComponent();
    },
  }),
  pure,
);

const CarrierProfileByConfig = (props: Object) => {
  if (G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_CARRIER_PROFILE)) {
    return <CarrierProfile {...props} />;
  }

  return <CarrierEditComponent {...props} />;
};

const Component = (({ handleGoBack, expandedContainerOptions, handleCloseExpandedContainer }: Object) => {
  const { componentType, componentsHistory } = expandedContainerOptions;

  const handleGoToPrevComponent = R.gt(R.length(componentsHistory), 1) ? () => handleGoBack() : null;

  const map = {
    [GC.PAGE_NEW_DO]: (
      <NewDOComponent pageWrapperPaddingLeft='0' closeExpandedContainer={handleCloseExpandedContainer} />
    ),
    [GC.PAGE_NEW_DO_QUOTE_EDIT]: (
      <NewQuoteComponent pageWrapperPaddingLeft='0' closeExpandedContainer={handleCloseExpandedContainer} />
    ),
    [GC.PAGE_DRIVERS_CARD]: (
      <DriversCard expandedContainer={true} closeExpandedContainer={handleCloseExpandedContainer} />
    ),
    [GC.PAGE_DISPATCH_DETAILS_NEW_LOAD]: (
      <LoadDetailsNewComponent
        expandedContainer={true}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    [GC.PAGE_DISPATCH_DETAILS_NEW_ORDER]: (
      <OrderDetailsNewComponent
        expandedContainer={true}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    [GC.PAGE_LOAD_BOARDS]: (
      <LoadBoards
        expandedContainer={true}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    carrierScorecards: (
      <CarrierScorecards
        overflowY='auto'
        expandedContainer={true}
        height='calc(100vh - 60px)'
        closeExpandedContainer={handleCloseExpandedContainer}
        carrierName={R.path(['options', GC.FIELD_NAME], expandedContainerOptions)}
        carrierGuid={R.path(['options', GC.FIELD_GUID], expandedContainerOptions)}
      />
    ),
    loadHistory: (
      <CarrierRateRequestReport
        height='100vh'
        expandedContainer={true}
        closeExpandedContainer={handleCloseExpandedContainer}
        carrierName={R.path(['options', 'carrierName'], expandedContainerOptions)}
      />
    ),
    loadStatistic: (
      <CarrierStatisticReport
        height='100vh'
        expandedContainer={true}
        closeExpandedContainer={handleCloseExpandedContainer}
        carrierName={R.path(['options', 'carrierName'], expandedContainerOptions)}
      />
    ),
    [GC.PAGE_TYPE_CLAIM__DETAILS]: (
      <ClaimManagementClaimDetails
        expandedContainer={true}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    // fleet
    [GC.PAGE_FLEET_TRUCK_PROFILE]: (
      <FleetTruckProfile closeExpandedContainer={handleCloseExpandedContainer} />
    ),
    [GC.PAGE_FLEET_DRIVER_PROFILE]: (
      <FleetDriverProfile closeExpandedContainer={handleCloseExpandedContainer} />
    ),
    [GC.PAGE_FLEET_VENDOR_PROFILE]: (
      <FleetVendorProfile closeExpandedContainer={handleCloseExpandedContainer} />
    ),
    [GC.PAGE_FLEET_TRAILER_PROFILE]: (
      <FleetTrailerProfile closeExpandedContainer={handleCloseExpandedContainer} />
    ),
    // fleet-v2
    [GC.PAGE_FLEET_DRIVER_PROFILE_V2]: (
      <FleetProfileDriver
        expandedContainer={true}
        handleGoBack={handleGoToPrevComponent}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    [GC.PAGE_FLEET_VENDOR_PROFILE_V2]: (
      <FleetProfileVendor
        expandedContainer={true}
        handleGoBack={handleGoToPrevComponent}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    [GC.PAGE_FLEET_TRUCK_PROFILE_V2]: (
      <FleetProfileTruck
        expandedContainer={true}
        handleGoBack={handleGoToPrevComponent}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    [GC.PAGE_FLEET_TRAILER_PROFILE_V2]: (
      <FleetProfileTrailer
        expandedContainer={true}
        handleGoBack={handleGoToPrevComponent}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    // carrier
    [GC.PAGE_CARRIER_PROFILE]: (
      <CarrierProfileByConfig
        expandedContainer={true}
        handleGoBack={handleGoToPrevComponent}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    [GC.PAGE_CARRIER_CONTRACT_DETAILS]: (
      <CarrierContractDetails
        expandedContainer={true}
        handleGoBack={handleGoToPrevComponent}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
    [GC.PAGE_CUSTOMER_CONTRACT_DETAILS]: (
      <CustomerContractDetails
        expandedContainer={true}
        handleGoBack={handleGoToPrevComponent}
        closeExpandedContainer={handleCloseExpandedContainer}
      />
    ),
  };

  return map[componentType];
});

const options = {
  dragAxis: 'x',
  minWidth: 800,
  maxWidth: '99vw',
  disableDragging: true,
  enableResizing: { left: true, right: false },
  default: { x: 0, y: 0, width: '70vw', height: '100vh' },
  resizeHandleComponent: {
    left: <HandResize />,
  },
  resizeHandleStyles: {
    left: { cursor: 'ew-resize' },
  },
};

const RndOrFragment = (props: Object) => (
  window.isMobile ?
    <Box width='100%' height='100%'>{props.children}</Box> :
    <Rnd {...props}>{props.children}</Rnd>
);

const ExpandedContainer = (props: Object) => (
  <FixedBox
    top='0'
    width='100%'
    height='100%'
    zIndex={props.zIndex}
    background={G.getTheme('reactModal.overlayBgColor')}
  >
    <AbsoluteBox right='0' height='100vh' width={window.isMobile ? 'calc(100vw - 60px)' : '70vw'}>
      <RndOrFragment {...options} >
        <Component {...props} />
      </RndOrFragment>
    </AbsoluteBox>
  </FixedBox>
);

export default enhance(ExpandedContainer);
