import React from 'react';
// import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
// ui
import { PageWrapper } from '../../ui';
// feature fleet-map
import { makeSelectFleets } from './selectors';
import FleetsMapComponent from './components/map';
//////////////////////////////////////////////////

const FleetMap = (props: Object) => (
  <PageWrapper pb='0' pl='0' bgColor={G.getTheme('pages.layOutBgColor')}>
    <FleetsMapComponent {...props.fleets} />
  </PageWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  fleets: makeSelectFleets(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps)(FleetMap);
