import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// component
import { Table } from '../../../components/table';
import { FormFooter2 } from '../../../components/form-footer';
// feature
import { LastDropCell, FirstPickupCell } from '../../dispatch-board-new/components/table-cells';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../hocs';
// ui
import { Box } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-details-new
import { renderInput, renderSelect } from '../ui';
import { itemsSettings } from '../settings/column-settings';
import { getOptions, isLengthBetween8And16, createFieldsForReport } from '../helpers';
import {
  getCrossBorderIntegrationFields,
  getDefaultCrossBorderIntegrationFields,
  getCrossBorderIntegrationFieldsValidationSchema,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const setValueToListItemByPropName = ({ guid, value, itemList, propName, errorField }: Object) =>
  R.map((item: Object) => {
    let isInValid = false;

    if (R.equals(propName, GC.FIELD_CONTROL_NUMBER)) isInValid = R.not(isLengthBetween8And16(value));

    if (R.equals(propName, GC.FIELD_SHIPMENT_TYPE)) isInValid = G.isNilOrEmpty(value);

    return G.ifElse(
      R.pathEq(guid, ['cloGuid'])(item),
      {
        ...item,
        [propName]: value,
        [errorField]: isInValid,
      },
      item,
    );
  }, itemList,
  );

const RC = {
  CLO_SHIPMENT_TYPE: `${GC.FIELD_CLOS}.${GC.FIELD_SHIPMENT_TYPE}`,
  CLO_CONTROL_NUMBER: `${GC.FIELD_CLOS}.${GC.FIELD_CONTROL_NUMBER}`,
};

const closReport = {
  fields: [
    { name: GC.FIELD_PRIMARY_REFERENCE_VALUE, sequence: 0 },
    { name: GC.FIELD_FIRST_PICKUP, sequence: 1 },
    { name: GC.FIELD_LAST_DROP, sequence: 2 },
    { name: RC.CLO_SHIPMENT_TYPE, sequence: 3 },
    { name: RC.CLO_CONTROL_NUMBER, sequence: 4 },
  ],
};

const itemsReport = {
  fields: createFieldsForReport(itemsSettings),
};

const commonTableSettings = {
  titleRowHeight: 30,
  tableRowHeight: 65,
  allowEditBtn: false,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  maxHeight: 'calc(100Vh - 350px)',
};

const commonTableData = {
  allChecked: false,
  rowStyles: {
    my: '5px',
    borderTop: true,
    borderColor: 'colors.bgGrey',
    activeBorderColor: 'colors.bgGrey',
  },
};

const closColumnSettings = {
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 120,
    name: 'titles:clo-number',
  },
  [GC.FIELD_FIRST_PICKUP]: {
    width: 160,
    name: 'titles:first-pickup',
    customComponent: ({ data }: Object) => <FirstPickupCell data={data} />,
  },
  [GC.FIELD_LAST_DROP]: {
    width: 160,
    name: 'titles:last-drop',
    customComponent: ({ data }: Object) => <LastDropCell data={data} />,
  },
  [RC.CLO_SHIPMENT_TYPE]: {
    width: 200,
    name: 'titles:shipment-type',
    customComponent: renderSelect,
  },
  [RC.CLO_CONTROL_NUMBER]: {
    width: 185,
    name: 'titles:control-number',
    customComponent: renderInput,
  },
};

const enhance = compose(
  withState('clos', 'setClos', []),
  withState('saveAndSend', 'setSaveAndSend', false),
  withFormik({
    mapPropsToValues: ({ cloList, initialValues }: Object) => G.setInitialFormikValues(
      getDefaultCrossBorderIntegrationFields(cloList),
      initialValues,
    ),
    validationSchema: ({ saveAndSend }: Object) => Yup.lazy(({ integrationType, manifestType }: Object) =>
      Yup.object().shape(getCrossBorderIntegrationFieldsValidationSchema({
        saveAndSend,
        manifestType,
        integrationType,
      })),
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { clos, saveAndSend, submitAction } = props;

      if (G.isTrue(saveAndSend)) {
        const condition = R.compose(
          R.any(R.equals(true)),
          R.flatten(),
          R.map((clo: Object) =>
            R.compose(
              R.values(),
              R.pick(['hasErrorControlNumber', 'hasErrorShipmentType']),
            )(clo),
          ))(clos);

        if (condition) return;
      }

      return submitAction({ clos, values, saveAndSend });
    },
  }),
  withPropsOnChange(['saveAndSend'], (props: Object) => {
    const { clos, setClos, saveAndSend } = props;

    const newClos = R.map((clo: Object) => ({
      ...clo,
      hasErrorShipmentType: R.and(saveAndSend, G.isNilOrEmpty(clo.shipmentType)),
      hasErrorControlNumber: R.and(saveAndSend, R.not(isLengthBetween8And16(clo.controlNumber))),
    }), clos);

    setClos(newClos);
  },
  ),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentManifestType = R.path(['values', GC.FIELD_MANIFEST_TYPE], props);
      const nextManifestType = R.path(['values', GC.FIELD_MANIFEST_TYPE], nextProps);

      return G.notEquals(currentManifestType, nextManifestType);
    },
    (props: Object) => {
      const { values } = props;

      const manifestType = G.getPropFromObject(GC.FIELD_MANIFEST_TYPE, values);

      if (R.equals(manifestType, GC.ACI)) {
        return {
          optionsForSelect: {
            entryPortOptions: getOptions(GC.ACIEntryPort),
            shipmentTypeOptions: getOptions(GC.ACIShipmentType),
            releaseOfficeOptions: getOptions(GC.ACIReleaseOffice),
          },
        };
      }

      if (R.equals(manifestType, GC.ACE)) {
        return {
          optionsForSelect: {
            entryPortOptions: getOptions(GC.ACEPortOfEntry),
            shipmentTypeOptions: getOptions(GC.ACEShipmentType),
          },
        };
      }

      return {
        optionsForSelect: {
          entryPortOptions: [],
          shipmentTypeOptions: [],
          releaseOfficeOptions: [],
        },
      };
    },
  ),
  withHandlers({
    handleGetItemList: ({ setClos }: Object) => async (cloGuid: string, callback: Function) => {
      const options = { params: { cloGuid } };

      try {
        const res = await sendRequest('get', endpointsMap.routeItemList, options);

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          setClos((prevClos: Array) => {
            const updatedClos = setValueToListItemByPropName({
              value: data,
              guid: cloGuid,
              itemList: prevClos,
              propName: 'itemList',
            });

            if (callback) callback(updatedClos);

            return updatedClos;
          });
        }
      } catch (error) {
        G.handleException('error', 'handleGetItemList exception');
      }
    },
  }),
  withHandlers({
    handleChangeClo: ({ setClos }: Object) => ({ guid, value, propName, errorField }: Object) => {
      setClos((prevClos: Array) => setValueToListItemByPropName({
        guid,
        value,
        propName,
        errorField,
        itemList: prevClos,
      }));
    },
    handleToggleItemsData: (props: Object) => (clo: Object) => {
      const { clos, setClos, handleGetItemList } = props;

      const { cloGuid, expanded, itemList } = clo;

      const toggleExpand = (updatedClos: Array) => {
        setClos(setValueToListItemByPropName({
          guid: cloGuid,
          value: !expanded,
          propName: 'expanded',
          itemList: R.or(updatedClos, clos),
        }));
      };

      if (G.isNilOrEmpty(itemList)) {
        handleGetItemList(cloGuid, toggleExpand);
      } else {
        toggleExpand();
      }
    },
    handleUpdateFormFields: (props: Object) => () => {
      const {
        clos,
        values,
        setClos,
        setValues,
        optionsForSelect,
      } = props;

      const closToUse = R.map((clo: Object) => ({
        ...clo,
        shipmentType: null,
        'shipmentTypeOptions': optionsForSelect.shipmentTypeOptions,
      }),
        clos,
      );

      const valuesToMerge = {
        [GC.FIELD_ENTRY_PORT]: null,
        [GC.FIELD_RELEASE_OFFICE]: null,
      };

      setValues(R.mergeRight(values, valuesToMerge));

      setClos(closToUse);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'optionsForSelect',
    callbackName: 'handleUpdateFormFields',
  }),
  lifecycle({
    componentDidMount() {
      const {
        cloList,
        setClos,
        handleChangeClo,
        optionsForSelect,
      } = this.props;

      const closToUse = R.map((clo: Object) => {
        const {
          guid,
          cloGuid,
          lastEvent,
          firstEvent,
          shipmentType,
          controlNumber,
        } = clo;

        return {
          lastEvent,
          firstEvent,
          itemList: [],
          handleChangeClo,
          expanded: false,
          hasErrorShipmentType: false,
          hasErrorControlNumber: false,
          cloGuid: R.or(cloGuid, guid),
          shipmentType: R.or(shipmentType, null),
          controlNumber: R.or(controlNumber, ''),
          shipmentTypeOptions: optionsForSelect.shipmentTypeOptions,
          primaryReferenceValue: R.path(['primaryReference', 'value'], clo),
        };
      },
        cloList,
      );

      setClos(closToUse);
    },
  }),
  pure,
);

const CrossBorderIntegrationForm = enhance((props: Object) => {
  const {
    clos,
    values,
    handleSubmit,
    setSaveAndSend,
    optionsForSelect,
    handleToggleItemsData,
  } = props;

  const manifestType = G.getPropFromObject(GC.FIELD_MANIFEST_TYPE, values);

  const closTableSettings = {
    ...commonTableSettings,
    tableRowHeight: 65,
    expandableItems: true,
    checkBoxCellWidth: 30,
    expandedDetailsComponent: ({ parentProps }: Object) => (
      <Box width={858}>
        <Table
          {...parentProps}
          report={itemsReport}
          columnSettings={itemsSettings}
          tableSettings={{ ...commonTableSettings, tableRowHeight: 35 }}
        />
      </Box>
    ),
  };

  const closTableData = {
    ...commonTableData,
    itemList: clos,
    report: closReport,
    toggle: handleToggleItemsData,
    tableSettings: closTableSettings,
    columnSettings: closColumnSettings,
  };

  const actionButtons = [{
    type: 'submit',
    action: () => setSaveAndSend(false),
    displayText: G.getWindowLocale('actions:save', 'Save'),
    buttonStyles: {
      mr: 16,
      ml: 'auto',
      bgColor: 'none',
      background: 'none',
      border: '1px solid',
      borderRadius: '5px',
      textColor: G.getTheme('colors.dark.blue'),
      borderColor: G.getTheme('colors.dark.blue'),
    },
  }];

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        fields={getCrossBorderIntegrationFields(manifestType)}
        fieldsWrapperStyles={{ mt: 15, gap: 25, justifyContent: 'flex-start' }}
      />
      <Box my={25}>
        <Table {...closTableData} />
      </Box>
      <FormFooter2
        boxStyles={{ mt: 25 }}
        actionButtons={actionButtons}
        submitAction={() => setSaveAndSend(true)}
        submitBtnText={G.getWindowLocale('actions:save-and-send', 'Save And Send')}
      />
    </form>
  );
});

export default CrossBorderIntegrationForm;
