import { connect } from 'react-redux';
import React, { useCallback } from 'react';
// components
import { openModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const PreviewModal = ({ filePublicLink, fileNotAvailable }: Object) => (
  <Box height='90vh'>
    {
      filePublicLink ?
        <img
          width='auto'
          height='100%'
          src={filePublicLink}
          alt={fileNotAvailable}
        /> :
        fileNotAvailable
    }
  </Box>
);

const ImagePreview = (props: Object) => {
  const {
    height,
    openModal,
    filePublicLink,
    documentFilename,
    thumbnailFilePublicLink,
  } = props;

  const fileNotAvailable = G.getWindowLocale('messages:file-not-available', 'The file is not available');

  const handleClick = useCallback(() => {
    const component = <PreviewModal filePublicLink={filePublicLink} fileNotAvailable={fileNotAvailable} />;

    const modal = {
      p: '0',
      component,
      options: {
        title: '',
        minWidth: 500,
        height: '90vh',
        outsideCloseButton: true,
      },
    };

    openModal(modal);
  }, []);

  const title = `${G.getWindowLocale('actions:click-to-view', 'Click To View')} ${documentFilename}`;

  return (
    <Box cursor='pointer' height={height || 30} boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'>
      <img
        width='auto'
        title={title}
        height={height || 30}
        onClick={handleClick}
        alt={fileNotAvailable}
        src={thumbnailFilePublicLink}
      />
    </Box>
  );
};

export default connect(null, { openModal })(ImagePreview);
