export const tableDetailsSettings = {
  cellFontSize: 13,
  titleFontSize: 13,
  maxHeight: '500px',
  useMainColors: true,
  checkBoxCellWidth: 27,
  expandableItems: false,
  allowSelectItems: false,
  tableRowHeight: 'max-content',
};

export const tableSettings = {
  cellFontSize: 14,
  titleFontSize: 14,
  tableRowHeight: 50,
  titleRowHeight: 50,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 140px)',
};
