import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import CustomerRateForm, { EditCustomerRateForm } from '../../../rate/customer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch-details-new
import * as H from '../../helpers';
import {
  removeLoadCustomerRateRequest,
  updateLoadCustomerRateRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddCustomerRate = compose(
  withHandlers({
    handleAddCustomerRate: (props: Object) => (clo: Object) => {
      const {
        load,
        openModal,
        createLoadCustomerRateRequest,
        loadConfigs: { configsByNames },
      } = props;

      const cloTotalData = H.makeCloTotalData(clo, load);

      const cloGuid = R.path([GC.FIELD_GUID], clo);
      const equipment = R.path([GC.FIELD_LOAD_EQUIPMENT], clo);
      const services = R.pathOr([], [GC.FIELD_LOAD_SERVICES], clo);
      const stopCount = R.pathOr(0, ['cloEvents', 'length'], cloTotalData);

      const equipments = G.ifElse(G.isNilOrEmpty(equipment), [], R.of(Array, equipment));

      const handleSendCloRate = (data: Object) =>
        createLoadCustomerRateRequest(R.assoc(GC.FIELD_CLO_GUID, cloGuid, data));

      const component = (
        <CustomerRateForm
          cloGuid={cloGuid}
          stopCount={stopCount}
          stopsQuantity={stopCount}
          handleSendCloRate={handleSendCloRate}
          totalWeight={cloTotalData.cloTotalWeight}
          totalDistance={cloTotalData.cloTotalDistance}
          branchGuid={R.pathOr('', [GC.FIELD_BRANCH_GUID], clo)}
          totalPickupQuantity={cloTotalData.cloTotalPickupQuantity}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          services={G.mapArrayOfObjectsToPropArray(GC.FIELD_DROPDOWN_OPTION_GUID, services)}
          equipments={G.mapArrayOfObjectsToPropArray(GC.FIELD_DROPDOWN_OPTION_GUID, equipments)}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadCustomerRateTableGroupRowActions = compose(
  connect(
    null,
    {
      removeLoadCustomerRateRequest,
      updateLoadCustomerRateRequest,
    },
  ),
  withHandlers({
    handleEditCustomerRate: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        updateLoadCustomerRateRequest,
        loadConfigs: { configsByNames },
        transportationModeGroupingList,
        getTransportationModeGroupingListSuccess,
      } = props;

      const { cloGuid } = entity;

      const clo = R.find(R.pathEq(cloGuid, [GC.FIELD_GUID]), R.prop(GC.FIELD_CLOS, load));

      const cloTotalData = H.makeCloTotalData(clo, load);

      const handleSendCloRate = (values: Object, loadData: Object) =>
        updateLoadCustomerRateRequest({ loadData, values: R.assoc(GC.FIELD_CLO_GUID, cloGuid, values) });

      const component = (
        <EditCustomerRateForm
          load={load}
          cloGuid={cloGuid}
          initialValues={entity}
          handleSendCloRate={handleSendCloRate}
          totalWeight={cloTotalData.cloTotalWeight}
          totalDistance={cloTotalData.cloTotalDistance}
          branchGuid={R.path([GC.FIELD_BRANCH_GUID], clo)}
          transportationModeGroupingList={transportationModeGroupingList}
          stopsQuantity={R.pathOr(0, ['cloEvents', 'length'], cloTotalData)}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          getTransportationModeGroupingListSuccess={getTransportationModeGroupingListSuccess}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
    handleRemoveCustomerRate: (props: Object) => () => {
      const {
        entity,
        openModal,
        removeLoadCustomerRateRequest,
      } = props;

      const textLocale = G.getWindowLocale('messages:delete-confirmation-rate', 'Do you want to delete this Rate?');

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeLoadCustomerRateRequest(entity[GC.FIELD_GUID]),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
);
