import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = (state: Object) => state.audit2;

const makeSelectAuditList = () => createSelector(
  store,
  ({ auditList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(auditList),
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectAuditLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectObjGuidAndAuditType = () => createSelector(
  store,
  ({ type, objectGuid }: Object) => ({ type, objectGuid }),
);

export {
  makeSelectAuditList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectAuditLoading,
  makeSelectObjGuidAndAuditType,
};
