import React from 'react';
// components
import { AddressBlock } from '../../../components/address-block';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const AddressBlockComponent = () => {
  const description = '<AddressBlock /> component is designed to display information about an address or location.';

  const usage = (
    <ul>
      <li>Set `<i>fontSize</i>` for the text size.</li>
      <li>Parameter `<i>color</i>` determines of the text color.</li>
      <li>Parameter `<i>width</i>` specifies the width of the address block.</li>
      <li>Parameter `<i>location</i>` is an object that contains information about the location.</li>
    </ul>
  );

  const location = {
    zip: '28046',
    city: 'Madrid',
    country: 'Spain',
    state: 'Comunidad de Madrid',
    address1: '36 Paseo de la Castellana',
    locationName: 'Paseo de la Castellana, 36, Madrid, Spain',
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='AddressBlock'
      description={description}
      path='src/components/address-block'
    >
      <AddressBlock
        width={250}
        fontSize={11}
        color='darkBlue'
        location={location}
      />
    </ComponentStoryWrapper>
  );
};

export default AddressBlockComponent;
