import React from 'react';
// components
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// forms
import { FormGroupWrapper, FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
// helpers/constants
import * as G from '../../../helpers';
// feature carrier
import * as LC from '../constants';
import { customIfElse } from '../helpers';
import { carrierEditContractUpliftSection } from '../settings/fields-settings';
//////////////////////////////////////////////////

const UpliftSection = (props: Object) => (
  <FormGroupWrapper isOpened={props.collapsedGroup[LC.CARRIER_GROUP_NAME_UPLIFT]}>
    <Box
      height={customIfElse(props, '32px', 'auto')}
      overflow={customIfElse(props, 'hidden', 'visible')}
      background='linear-gradient(to right, #f3f3f3 300px, #fff 0)'
    >
      <FormGroupTitleComponent
        withArrowDown={true}
        isOpened={props.collapsedGroup[LC.CARRIER_GROUP_NAME_UPLIFT]}
        text={G.getWindowLocale(carrierEditContractUpliftSection.title)}
        onToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_UPLIFT)} />
      <FieldsetComponent
        {...props}
        fieldsetWidth={530}
        flexDirection='column'
        fields={carrierEditContractUpliftSection.fields} />
    </Box>
  </FormGroupWrapper>
);

export default UpliftSection;
