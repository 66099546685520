import * as R from 'ramda';
import { put, all, call, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectCurrentUserSettingsFields } from '../../profile/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
import { createTelRateActionSuccess, updateTelRateActionSuccess } from '../../rate/actions';
import { getAvailableReportGeoFencingZoneListRequest } from '../../geo-fencing-zone/report/actions';
import { getAvailableDocumentTypes, getTelDispatchBoardActionListRequest } from '../../configurations/actions';
import {
  getLoadInvoiceTotalsRequest as getLoadDetailsInvoiceTotalsRequest,
  getLoadDriverInvoiceListRequest,
  getLoadCarrierInvoiceListRequest,
  refreshTelCrossBorderIntegrationSuccess,
} from '../../dispatch-details-new/load/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { generateDefaultReport } from '../../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-board-new
import {
  getConfigsByNamesRequest,
  getCrossDockLocationsRequest,
  getDispatchBoardConfigsRequest,
} from '../actions';
// feature dispatch-board-new/load
import * as A from './actions';
import * as H from '../helpers';
import {
  makeSelectGroupBy,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectTelNotifications,
  makeSelectGlobalFilterType,
  makeSelectGlobalFilterValue,
  makeSelectTableTitleFilters,
  makeSelectShipUnitsExpanded,
} from './selectors';
//////////////////////////////////////////////////

const reportPayload = {
  reportType: GC.ROUTE_TEL_REPORT,
  pathname: GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
};

function* refreshPageSaga() {
  yield put(A.resetListAndPagination());
  yield put(A.getItemListRequest(true));
}

function* handleGetTelNotificationsByTelGuidsSaga({ payload }: Object) {
  try {
    const options = {
      data: { elements: payload },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.notificationList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTelNotificationsSuccess(data.results));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelNotificationsByTelGuidsSaga');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetTelNotificationsByTelGuidsSaga');
  }
}

function* handleHideTelNotificationsSaga({ payload }: Object) {
  try {
    const notifications = yield select(makeSelectTelNotifications());
    const data = {
      elements: R.compose(
        R.map(({ userNotificationGuid }: Object) => userNotificationGuid),
        R.values,
        R.path([payload]),
      )(notifications),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.hideWarnings, { data });

    if (G.isResponseSuccess(res.status)) {
      yield put(A.hideTelNotificationsSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleHideTelNotificationsSaga');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleHideTelNotificationsSaga');
  }
}

function* handleTelReadNotificationsSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, objectGuid, userNotificationGuid } = payload;

    const options = {
      data: {
        read: true,
        guids: [userNotificationGuid],
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.notificationMark, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.markAsReadTelNotificationSuccess({ guid, objectGuid }));
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleTelReadNotificationsSaga exception');
  } finally {
    yield put(closeLoader());
  }
}

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));

    const groupBy = yield select(makeSelectGroupBy());
    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      H.getTitleOrderFields(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const primaryReferenceFilterValue = R.pathOr(
      '',
      [GC.FIELD_PRIMARY_REFERENCE_VALUE, GC.FIELD_STRING_VALUE],
      titleFilterParams,
    );

    const { limit, offset } = pagination;

    const reqBodyWithPrimaryReferenceFilter = {
      limit,
      offset,
      groupBy,
      fields: [],
      orderFields,
      primaryReferenceFilterValue,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    let reqData = reqBodyWithPrimaryReferenceFilter;

    if (G.isNilOrEmpty(primaryReferenceFilterValue)) {
      const filterParams = yield select(makeSelectFilterParams());
      const globalFilterType = yield select(makeSelectGlobalFilterType());
      const globalFilterValue = yield select(makeSelectGlobalFilterValue());

      const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

      const searchCriteria = G.ifElse(
        G.isNotEmpty(titleFilterParams),
        H.getTitleFilterParams(titleFilterParams),
        G.getOrElse(reportParams, 'searchCriteria', []),
      );

      const reqBody = R.mergeRight(reqBodyWithPrimaryReferenceFilter, {
        globalFilterType,
        globalFilterValue,
        showLastDays: R.pathOr(30, ['showLastDays'], reportParams),
        summaryFilters: R.pathOr([], ['summaryFilters'], reportParams),
        searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      });

      reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });
    }

    const res = yield call(sendRequest, 'post', endpointsMap.telDispatchBoard, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));

      const telGuids = H.getGuidsFromRouteListResults(res.data.results);

      if (G.isNotEmpty(telGuids)) {
        const shipUnitsExpanded = yield select(makeSelectShipUnitsExpanded());

        if (shipUnitsExpanded) {
          yield put(A.getLoadsShipUnitRequest(telGuids));
        }

        yield fork(handleGetTelNotificationsByTelGuidsSaga, { payload: telGuids });
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleAvailableReportsRequest({ notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return false;

    const params = {
      reportType: GC.ROUTE_TEL_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);
      yield put(A.setReports(checkedReports));

      if (R.not(R.prop('length', reports))) {
        return yield put(A.setUsedReport(generateDefaultReport(GC.ROUTE_TEL_REPORT)));
      }

      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(GC.ROUTE_TEL_REPORT));
        const currentUser = yield select(makeSelectCurrentUserSettingsFields());
        const configGroupBy = G.getAmousConfigByNameFromWindow(GC.UI_ROUTE_LIST_TEL_DEFAULT_ORGANIZE_BY);
        const groupBy = R.pathOr(
          R.pathOr(
            R.or(configGroupBy, GC.ORGANIZE_BY_NO_GROUPING),
            ['cloRouteDefaultGroupBy'],
            currentUser,
          ),
          [GC.FIELD_GROUP_BY],
          usedReport,
        );
        yield put(A.setGroupBy(groupBy));
        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.set(R.lensProp('searchCriteria'), searchCriteria, payload);
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newPayload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.report, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.set(
        R.lensProp('searchCriteria'),
        R.path(['searchCriteria'], payload),
        data,
      ))));
      yield call(handleAvailableReportsRequest, { notSetUsedReport: true, payload: reportPayload });
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = {
      data: R.set(R.lensProp('searchCriteria'), searchCriteria, payload),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.report, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.set(
        R.lensProp('searchCriteria'),
        R.path(['searchCriteria'], payload),
        data,
      ))));
      yield call(handleAvailableReportsRequest, { notSetUsedReport: true, payload: reportPayload });
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleAvailableReportsRequest, { payload: reportPayload });
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetSummarySaga({ payload }: Object) {
  try {
    const reportParams = yield select(makeSelectUsedReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const onEndAction = R.path(['onEndAction'], payload);

    if (G.isNilOrEmpty(reportParams.summaryGroup)) {
      yield put(A.getSummarySuccess({}));

      if (G.isFunction(onEndAction)) onEndAction();
      return;
    }

    const reqBody = {
      group: reportParams.summaryGroup,
      subgroup: reportParams.summarySubgroup,
      showLastDays: reportParams.showLastDays,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telDispatchBoardSummary, { data: reqBody });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSummarySuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSummarySaga');
    }

    if (G.isFunction(onEndAction)) onEndAction();
  } catch (error) {
    yield call(G.handleException, error, 'handleGetSummarySaga');
  }
}

function* handleCreateReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telMassReference, options);
    const { data, status } = res;
    const resCondition = R.not(
      G.isResponseSuccess(status, data, 'Thanks, your data was created successfully'),
    );

    if (resCondition) {
      yield call(G.handleFailResponse, res, 'handleCreateReferenceSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateReferenceSaga');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteTelSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'delete', endpointsMap.getTelEntity(payload[GC.FIELD_GUID]));

    if (G.isResponseSuccess(res.status)) {
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTelSaga');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteTelSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAcceptTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, loadGuid, isCarrierRate } = payload;
    const endpoint = G.ifElse(
      isCarrierRate,
      endpointsMap.getTelCarrierRateAcceptEndpoint,
      endpointsMap.getTelRateAcceptEndpoint,
    )(guid);
    const res = yield call(sendRequest, 'put', endpoint);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDetailsRequest(loadGuid));
      yield put(A.getLoadSelectedRateRequest({ rateGuid: guid, guid: loadGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleAcceptTelRateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAcceptTelRateSaga exception');
  }
}

function* handleDeclineTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        [GC.FIELD_DECLINE_REASON_CODE_GUID]: R.pathOr(null, [GC.FIELD_DECLINE_REASON_CODE_GUID], payload),
      },
    };
    const { guid, telGuid, isCarrierRate } = payload;
    const endpoint = G.ifElse(
      isCarrierRate,
      endpointsMap.getTelCarrierRateDeclineEndpoint,
      endpointsMap.getTelRateDeclineEndpoint,
    )(guid);
    const res = yield call(sendRequest, 'put', endpoint, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDetailsRequest(telGuid));
      yield put(A.getLoadSelectedRateRequest({ rateGuid: guid, guid: telGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeclineTelRateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeclineTelRateSaga exception');
  }
}

function* handleApproveTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { telGuid } = payload;
    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'put', endpointsMap.telRateApprove, options);

    const { status} = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDetailsRequest(telGuid));
      const itemList = yield select(makeSelectItemList());
      const rateGuid = H.getSelectedRateGuidFromDispatchBoardByTelGuid(itemList, telGuid);

      if (G.isNotNil(rateGuid)) {
        yield put(A.getLoadSelectedRateRequest({ rateGuid, guid: telGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleApproveTelRateSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleApproveTelRateSaga exception');
  }
}

function* handleDispatchTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { action, telGuid } = payload;
    const endpoint = endpointsMap.getTelActionEndpoint(telGuid, action);
    const res = yield call(sendRequest, 'put', endpoint);
    const { status} = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDetailsRequest(telGuid));
      const itemList = yield select(makeSelectItemList());
      const rateGuid = H.getSelectedRateGuidFromDispatchBoardByTelGuid(itemList, telGuid);
      if (G.isNotNil(rateGuid)) {
        yield put(A.getLoadSelectedRateRequest({ rateGuid, guid: telGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDispatchTelRateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDispatchTelRateSaga exception');
  }
}

function* handleDispatchCarrierTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.telCarrierRateDispatch;
    const res = yield call(sendRequest, 'put', endpoint, { data: payload });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDetailsRequest(payload.telGuid));
      const { telGuid } = payload;
      const itemList = yield select(makeSelectItemList());
      const rateGuid = H.getSelectedRateGuidFromDispatchBoardByTelGuid(itemList, telGuid);

      if (G.isNotNil(rateGuid)) {
        yield put(A.getLoadSelectedRateRequest({ rateGuid, guid: telGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDispatchCarrierTelRateSaga fail');
    }

    yield put(closeLoader());
    yield put(closeModal());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDispatchCarrierTelRateSaga  exception');
  }
}

function* handleCreateFleetInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = G.ifElse(
      G.isInvoiceTypeFleetVendorInvoice(payload.type),
      endpointsMap.telFleetVendorInvoice,
      endpointsMap.telFleetInvoice,
    );
    const res = yield call(sendRequest, 'post', endpoint, { data: payload });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(closeModal());

      if (R.pathEq(true, ['refreshInvoiceTotals'], payload)) {
        yield put(A.getLoadInvoiceTotalsRequest(G.getPropFromObject(GC.FIELD_TEL_GUID, payload)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateFleetInvoiceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateFleetInvoiceSaga exception');
  }
}

function* handleCreateFleetInvoiceByTelGuidSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { data, fromPage, refreshInvoiceTotals } = payload;

    const res = yield call(sendRequest, 'post', endpointsMap.telInvoiceByTelGuid, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const loadGuid = G.getPropFromObject(GC.FIELD_TEL_GUID, data);

      if (R.equals(fromPage, 'details')) {
        yield put(getLoadDetailsInvoiceTotalsRequest(loadGuid));
        yield put(getLoadDriverInvoiceListRequest({ loadGuid }));
      }

      if (R.and(G.isTrue(refreshInvoiceTotals), G.notEquals(fromPage, 'details'))) {
        yield put(A.getLoadInvoiceTotalsRequest(loadGuid));
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateFleetInvoiceByTelGuidSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateFleetInvoiceByTelGuidSaga exception');
  }
}

function* handleCreateCarrierInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'post', endpointsMap.telCarrierInvoice, { data: payload });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(closeModal());

      if (R.pathEq(true, ['refreshInvoiceTotals'], payload)) {
        yield put(A.getLoadInvoiceTotalsRequest(G.getPropFromObject(GC.FIELD_TEL_GUID, payload)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierInvoiceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCarrierInvoiceSaga exception');
  }
}

function* handleCreateCarrierInvoiceByTelGuidSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { telGuid, fromPage, refreshInvoiceTotals } = payload;
    const options = {
      params: { telGuid },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierInvoiceByTelGuid, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(A.getLoadInvoiceTotalsRequest(G.getPropFromObject(GC.FIELD_TEL_GUID, payload)));

      if (R.equals(fromPage, 'details')) {
        yield put(getLoadDetailsInvoiceTotalsRequest(telGuid));
        yield put(getLoadCarrierInvoiceListRequest({ loadGuid: telGuid }));
      }

      if (R.and(G.isTrue(refreshInvoiceTotals), G.notEquals(fromPage, 'details'))) {
        yield put(A.getLoadInvoiceTotalsRequest(telGuid));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierInvoiceByTelGuidSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCarrierInvoiceByTelGuidSaga exception');
  }
}

function* handleCreateLoadStatusMessage({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.telStatusMessage, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(A.getLoadDetailsRequest(payload.loadGuid));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateLoadStatusMessage fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateLoadStatusMessage exception');
  }
}

function* createLoadMultipleStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { loadGuid, callback, statusMessages } = payload;
    const completedStatusMessages = [];
    for (let i = 0; R.lt(i, R.length(statusMessages)); i++) {
      const statusMessage = G.getPropFromObject(i, statusMessages);
      const options = { data: R.assoc(GC.FIELD_LOAD_GUID, loadGuid, statusMessage) };
      const res = yield call(sendRequest, 'post', endpointsMap.telStatusMessage, options);
      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        completedStatusMessages.push(data);
      } else {
        G.callFunctionWithArgs(callback, completedStatusMessages);
        yield call(G.handleFailResponse, res, 'createLoadStatusMessageRequest fail');
        break;
      }
    }

    if (G.isNotEmpty(completedStatusMessages)) {
      yield put(A.getLoadDetailsRequest(loadGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      if (R.equals(R.length(statusMessages), R.length(completedStatusMessages))) yield put(closeModal());
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadStatusMessageRequest exception');
  }
}

const fieldsToPickUpdateAppts = [GC.FIELD_STOP_NUMBER, ...GC.GROUPED_FIELDS.APPOINTMENTS_PICK_ARR];

function* handleUpdateAppointmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const data = R.pick(fieldsToPickUpdateAppts, payload);
    const res = yield call(sendRequest, 'put', endpointsMap.telEventAppointment, { data });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(A.getLoadDetailsRequest(payload.loadGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateAppointmentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateAppointmentSaga exception');
  }
}

function* handleGetLoadDispatchBoardDetailsSaga({ payload }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const params = {
      [GC.FIELD_TEL_GUID]: payload,
      [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telDispatchBoardByTelGuid, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDispatchBoardDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLoadDispatchBoardDetailsSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLoadDispatchBoardDetailsSaga exception');
  }
}

function* handleGetLoadDetailsSaga({ payload }: Object) {
  try {
    const telGuid = G.ifElse(
      G.isString(payload),
      payload,
      G.getGuidFromObject(payload),
    );
    const endpoint = endpointsMap.getTelEndpoint(telGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const eventGuids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(G.isLoadTypeClo),
        R.pathOr([], [GC.FIELD_LOAD_STOPS]),
      )(data);

      if (R.and(G.isNotEmpty(eventGuids), R.pathEq(true, ['callCloEventReferences'], payload))) {
        yield put(A.getCloReferencesByEventGuidsRequest(R.assoc('eventGuids', eventGuids, payload)));
      }

      const items = R.reduce(
        (items: Array, stop: Object) => {
          const filteredItems = R.filter(
            (stopItem: Object) => R.not(R.any(R.eqProps(GC.FIELD_GUID, stopItem), items)),
            stop.items,
          );

          return R.concat(items, filteredItems);
        },
        [],
        data.events,
      );
      const containers = G.getContainersFromEventsUniqByGuid(data.events);
      const load = R.mergeRight(
        data,
        {
          items,
          containers,
          [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL,
        },
      );
      yield put(A.getLoadDetailsSuccess(load));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLoadDetailsSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLoadDetailsSaga exception');
  }
}

function* handleGetLoadSelectedRateSaga({ payload }: Object) {
  try {
    const { guid, rateGuid } = payload;

    if (R.isNil(rateGuid)) return;

    const res = yield call(sendRequest, 'get', endpointsMap.getTelRateItemEndpoint(rateGuid));
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadSelectedRateSuccess({ guid, rateData: data }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLoadSelectedRateSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLoadSelectedRateSaga exception');
  }
}

function* handleGetLoadsShipUnitSaga({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.getShipUnitByTelGuid, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadsShipUnitSuccess({ data, guids: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLoadsShipUnitSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLoadsShipUnitSaga exception');
  }
}

function* handleGetLoadDocumentListSaga({ payload }: Object) {
  try {
    const params = {
      [GC.FIELD_PRIMARY_OBJECT_GUID]: payload,
      [GC.FIELD_BRANCH_GUID]: yield select(makeSelectCurrentBranchGuid()),
    };
    const res = yield call(sendRequest, 'get', endpointsMap.telDocumentList, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDocumentListSuccess({
        guid: payload,
        documents: data,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLoadDocumentListSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLoadDocumentListSaga exception');
  }
}

function* getLoadInvoicesSaga({ payload }: Object) {
  try {
    const { guid, isCarrierRate } = payload;
    const authorities = yield select(makeSelectAuthorities());
    const permissions = G.ifElse(
      isCarrierRate,
      [
        PC.TEL_CARRIER_INVOICE_READ,
        PC.TEL_CARRIER_INVOICE_WRITE,
        PC.TEL_INVOICE_OVERWRITE_EXECUTE,
      ],
      [
        PC.TEL_FLEET_INVOICE_READ,
        PC.TEL_FLEET_INVOICE_WRITE,
        PC.TEL_INVOICE_OVERWRITE_EXECUTE,
      ],
    );
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const endpoint = G.ifElse(isCarrierRate, endpointsMap.carrierInvoice, endpointsMap.telInvoice);
    const options = {
      params: { telGuid: guid },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);

    if (G.isResponseSuccess(res.status)) {
      yield put(A.getLoadInvoicesSuccess({ guid, invoices: res.data }));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadInvoicesSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadInvoicesSaga exception');
  }
}

function* getLoadInvoiceTotalsRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.TEL_FLEET_INVOICE_READ,
      PC.TEL_FLEET_INVOICE_WRITE,
      PC.TEL_CARRIER_INVOICE_READ,
      PC.TEL_CARRIER_INVOICE_WRITE,
      PC.TEL_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const options = {
      params: { [GC.FIELD_TEL_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telInvoiceTotals, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadInvoiceTotalsSuccess({ invoiceTotals: data, [GC.FIELD_GUID]: payload }));
    } else {
      yield call(G.handleException, 'error', 'getLoadInvoiceTotalsRequest exception');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadInvoiceTotalsRequest exception');
  }
}

function* handleToggleLoadDetailsSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, data, noDetails, isCarrierRate } = payload;

    if (R.and(R.path(['expanded'], data), noDetails)) {
      if (R.pathEq('SHIP_UNIT', ['shownDetails'], data)) {
        yield call(handleGetLoadsShipUnitSaga, { payload: R.of(Array, guid) });
      } else {
        const loadDetailsPayload = {
          guid,
          expandDetails: true,
          callCloEventReferences: true,
        };

        yield all([
          call(handleGetLoadDetailsSaga, { payload: loadDetailsPayload }),
          call(handleGetLoadSelectedRateSaga, { payload }),
          call(getLoadInvoicesSaga, { payload: { guid, isCarrierRate } }),
          call(getLoadInvoiceTotalsRequest, { payload: guid }),
          call(handleGetLoadDocumentListSaga, { payload: guid }),
        ]);
      }
    }

    yield put(closeLoader());

    yield put(A.toggleLoadDetailsSuccess(payload));
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleToggleLoadDetailsSaga exception');
  }
}

function* handleUpdateLoadDetailsSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const updateData = R.pick(GC.GROUPED_FIELDS.LOAD_PICK_ARR, payload);
    const options = { data: updateData };
    const res = yield call(sendRequest, 'put', endpointsMap.tel, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateLoadDetailsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateLoadDetailsSaga exception');
  }
}

function* handleRefreshLoadOnRateCreateOrUpdateSaga({ payload }: Object) {
  try {
    const { telGuid, selected } = payload;

    if (G.isFalse(selected)) return;

    yield put(openLoader());
    yield put(A.getLoadDetailsRequest(telGuid));
    yield put(A.getLoadSelectedRateSuccess({ guid: telGuid, rateData: payload }));

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRefreshLoadOnRateCreateOrUpdateSaga exception');
  }
}

function* handleCreateSuspendResumeCarrierRateEmailsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, endpointName } = payload;
    const endpoint = R.prop(endpointName, endpointsMap)(guid);
    const res = yield call(sendRequest, 'put', endpoint);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadSelectedRateSuccess({ guid: data.telGuid, rateData: data }));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateSuspendResumeCarrierRateEmailsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateSuspendResumeCarrierRateEmailsSaga exception');
  }
}

function* updateLoadDocumentSaga({ payload }: Object) {
  const { callback, data: payloadData } = payload;

  try {
    yield put(openLoader());
    const { telGuid } = payloadData;

    const reqData = G.makeDataForDocument(payloadData);
    const options = { data: reqData };
    const endpoint = G.ifElse(
      G.isLoadTypeClo(payloadData),
      endpointsMap.cloDocumentUpdate,
      endpointsMap.telDocumentUpdate,
    );
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateLoadDocumentSuccess({ data, telGuid }));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      G.callFunction(callback);
      yield call(G.handleFailResponse, res, 'updateLoadDocumentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    G.callFunction(callback);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadDocumentSaga exception');
  }
}

function* removeLoadDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, telGuid, loadType } = payload;
    const endpoint = G.ifElse(
      G.isLoadTypeClo(loadType),
      endpointsMap.getCloDocumentEndpoint,
      endpointsMap.getTelDocumentEndpoint,
    )(guid);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeLoadDocumentSuccess({ guid, loadGuid: telGuid }));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadDocumentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadDocumentSaga exception');
  }
}

function* getDispatchBoardConfigsSaga(branchGuid: string) {
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.UI_CONFIG_GROUP,
  }));
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.GENERAL_CONFIG_GROUP,
  }));
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.CLO_CONFIG_GROUP,
  }));
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.COMMUNICATION_CONFIG_GROUP,
  }));
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.INVOICE_CONFIG_GROUP,
  }));
  const telDispatchBoardConfigNames = [
    GC.TEL_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES,
    GC.TEL_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE,
  ];
  yield put(getConfigsByNamesRequest({
    branchGuid,
    names: R.join(',', telDispatchBoardConfigNames),
  }));
}

function* dispatchTelListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.massDispatch, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.refreshPageRequest());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isNotNilAndNotEmpty(data)) {
        const message = R.compose(
          R.join('. '),
          R.map(({ error, primaryReferenceValue }: Object) => `${primaryReferenceValue} - ${error}`),
        )(data);
        yield call(G.showToastrMessage, 'error', message);
      }
    } else {
      yield call(G.handleFailResponse, res, 'dispatchTelListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'dispatchTelListRequest exception');
  }
}

function* massCreateCarrierRateByReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const report = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(report, 'searchCriteria', []),
    );
    const reqBody = {
      ...payload,
      showLastDays: R.pathOr(30, ['showLastDays'], report),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };
    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };
    yield call(crudSaga, {
      options,
      method: 'post',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'massCreateCarrierRateByReportSaga',
      endpoint: endpointsMap.telCarrierRateMassCreateByDispatchBoardReport,
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
  }
}

function* sendUpdateEdiOrApiRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.sendCarrierRateUpdate(payload);
    const res = yield call(sendRequest, 'post', endpoint);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'sendUpdateEdiOrApiRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendUpdateEdiOrApiRequest exception');
  }
}

function* handleVisitDispatchBoardTelPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DISPATCH_BOARD_TEL_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield put(A.setIgnorePromptStatus(false));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(getTelDispatchBoardActionListRequest(branchGuid));
    yield put(getAvailableDocumentTypes());

    yield call(getDispatchBoardConfigsSaga, branchGuid);

    yield put(getCrossDockLocationsRequest(branchGuid));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload });

    yield put(getAllAvailableRefTypesByScopeRequest(GC.LOAD_TYPE_TEL));
    yield put(getAvailableReportGeoFencingZoneListRequest());

    yield call(handleGetItemListSaga, { payload: true });

    yield put(closeLoader());

    break;
  }
}

function* getCloReferencesByEventGuidsRequest({ payload }: Object) {
  try {
    const { eventGuids, expandDetails } = payload;

    if (G.isTrue(expandDetails)) yield put(openLoader());

    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) {
      if (G.isTrue(expandDetails)) yield put(closeLoader());

      return;
    }

    const options = { data: eventGuids };

    const res = yield call(sendRequest, 'post', endpointsMap.cloReferenceByEventGuids, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCloReferencesByEventGuidsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCloReferencesByEventGuidsRequest fail');
    }

    if (G.isTrue(expandDetails)) yield put(closeLoader());
  } catch (error) {
    if (R.pathEq(true, ['expandDetails'], payload)) yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getCloReferencesByEventGuidsRequest exception');
  }
}

function* sendTelsToExternalSystemRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { params: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.sendMassToExternalSystem, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'sendTelsToExternalSystemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendTelsToExternalSystemRequest exception');
  }
}

function* handleCreateCrossBorderIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { clos, guid, values, saveAndSend } = payload;

    const options = {
      data: {
        ...values,
        clos,
        [GC.FIELD_TEL_GUID]: guid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telCrossBorderIntegration, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (G.isTrue(saveAndSend)) {
        yield put(A.sendCrossBorderIntegrationRequest(data));
      } else {
        yield put(refreshTelCrossBorderIntegrationSuccess(data));
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCrossBorderIntegrationRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCrossBorderIntegrationRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* handleSendCrossBorderIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid } = payload;

    const endpoint = endpointsMap.sendTelCrossBorderIntegration(guid);

    const res = yield call(sendRequest, 'post', endpoint, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(refreshTelCrossBorderIntegrationSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:send');
    } else {
      yield call(G.handleFailResponse, res, 'handleSendCrossBorderIntegrationRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSendCrossBorderIntegrationRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* loadListWatcherSaga() {
  yield takeLatest(A.refreshPageRequest, refreshPageSaga);
  yield takeLatest(A.deleteItemRequest, handleDeleteTelSaga);
  yield takeLatest(A.getSummaryRequest, handleGetSummarySaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.getLoadInvoicesRequest, getLoadInvoicesSaga);
  yield takeLatest(A.acceptTelRateRequest, handleAcceptTelRateSaga);
  yield takeLatest(A.dispatchTelListRequest, dispatchTelListRequest);
  yield takeLatest(A.declineTelRateRequest, handleDeclineTelRateSaga);
  yield takeLatest(A.getLoadDetailsRequest, handleGetLoadDetailsSaga);
  yield takeLatest(A.approveLoadRateRequest, handleApproveTelRateSaga);
  yield takeLatest(A.createReferenceRequest, handleCreateReferenceSaga);
  yield takeLatest(A.dispatchTelRateRequest, handleDispatchTelRateSaga);
  yield takeLatest(A.removeLoadDocumentRequest, removeLoadDocumentSaga);
  yield takeLatest(A.updateLoadDocumentRequest, updateLoadDocumentSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.getLoadsShipUnitRequest, handleGetLoadsShipUnitSaga);
  yield takeLatest(A.sendUpdateEdiOrApiRequest, sendUpdateEdiOrApiRequest);
  yield takeLatest(A.updateAppointmentRequest, handleUpdateAppointmentSaga);
  yield takeLatest(A.toggleLoadDetailsRequest, handleToggleLoadDetailsSaga);
  yield takeLatest(A.updateLoadDetailsRequest, handleUpdateLoadDetailsSaga);
  yield takeLatest(A.createFleetInvoiceRequest, handleCreateFleetInvoiceSaga);
  yield takeLatest(A.getLoadInvoiceTotalsRequest, getLoadInvoiceTotalsRequest);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.getLoadDocumentListRequest, handleGetLoadDocumentListSaga);
  yield takeLatest(A.getLoadSelectedRateRequest, handleGetLoadSelectedRateSaga);
  yield takeLatest(A.hideTelNotificationsRequest, handleHideTelNotificationsSaga);
  yield takeLatest(A.createCarrierInvoiceRequest, handleCreateCarrierInvoiceSaga);
  yield takeLatest(A.createLoadStatusMessageRequest, handleCreateLoadStatusMessage);
  yield takeLatest(A.dispatchCarrierTelRateRequest, handleDispatchCarrierTelRateSaga);
  yield takeLatest(A.sendTelsToExternalSystemRequest, sendTelsToExternalSystemRequest);
  yield takeLatest(A.markAsReadTelNotificationRequest, handleTelReadNotificationsSaga);
  yield takeLatest(A.addCarrierRatesByReportRequest, massCreateCarrierRateByReportSaga);
  yield takeLatest(A.getTelNotificationsRequest, handleGetTelNotificationsByTelGuidsSaga);
  yield takeLatest(GC.VISIT_DISPATCH_BOARD_TEL_PAGE, handleVisitDispatchBoardTelPageSaga);
  yield takeLatest(createTelRateActionSuccess, handleRefreshLoadOnRateCreateOrUpdateSaga);
  yield takeLatest(updateTelRateActionSuccess, handleRefreshLoadOnRateCreateOrUpdateSaga);
  yield takeLatest(A.getCloReferencesByEventGuidsRequest, getCloReferencesByEventGuidsRequest);
  yield takeLatest(A.getLoadDispatchBoardDetailsRequest, handleGetLoadDispatchBoardDetailsSaga);
  yield takeLatest(A.createFleetInvoiceByTelGuidRequest, handleCreateFleetInvoiceByTelGuidSaga);
  yield takeLatest(A.sendCrossBorderIntegrationRequest, handleSendCrossBorderIntegrationRequest);
  yield takeLatest(A.createCarrierInvoiceByTelGuidRequest, handleCreateCarrierInvoiceByTelGuidSaga);
  yield takeLatest(A.createLoadMultipleStatusMessageRequest, createLoadMultipleStatusMessageRequest);
  yield takeLatest(A.createCrossBorderIntegrationRequest, handleCreateCrossBorderIntegrationRequest);
  yield takeLatest(A.suspendResumeCarrierRateEmailsRequest, handleCreateSuspendResumeCarrierRateEmailsSaga);
}

export default loadListWatcherSaga;
