import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature dispatch-group
import * as A from './actions';
//////////////////////////////////////////////////

function* getDispatchGroupListSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      parentSagaName: 'getDispatchGroupListSaga',
      successAction: A.receivedDispatchGroupListSuccess,
      endpoint: endpointsMap.fleetDispatchingGroupByBranchGuid,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDispatchGroupListSaga exception');
  }
}

function* createDispatchGroupRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(payload.version),
      'post',
      'put',
    );
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successAction: A.getDispatchGroupRequest,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'createDispatchGroupRequest',
      endpoint: endpointsMap.fleetDispatchingGroup,
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createDispatchGroupRequest exception');
  }
}

function* deleteDispatchGroupRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.fleetDispatchingGroup; // TODO:
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      options: {
        data: R.of(Array, payload),
      },
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.deleteDispatchGroupSuccess,
      parentSagaName: 'deleteDispatchGroupRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'deleteDispatchGroupRequest exception');
  }
}

function* handleVisitDispatchGroupSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DISPATCH_GROUP_PAGE);
    yield put(openLoader());
    yield call(getDispatchGroupListSaga);
    yield put(closeLoader());
    break;
  }
}

function* dispatchGroupWatcherSaga() {
  yield takeLatest(GC.VISIT_DISPATCH_GROUP_PAGE, handleVisitDispatchGroupSaga);
  yield takeLatest(A.getDispatchGroupRequest, getDispatchGroupListSaga);
  yield takeLatest(A.deleteDispatchGroupRequest, deleteDispatchGroupRequest);
  yield takeLatest(A.createDispatchGroupRequest, createDispatchGroupRequest);
}

export default dispatchGroupWatcherSaga;
