import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const setTruckGuid = createAction('setTruckGuid');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const changeActiveTab = createAction('changeActiveTab');
export const setInitialState = createAction('setInitialState');
export const setReportPending = createAction('setReportPending');
export const printIFTARequest = createAction('printIFTARequest');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const removeIFTASuccess = createAction('removeIFTASuccess');
export const removeIFTARequest = createAction('removeIFTARequest');
export const getIFTAXmlRequest = createAction('getIFTAXmlRequest');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getIftaListSuccess = createAction('getIftaListSuccess');
export const getIftaListRequest = createAction('getIftaListRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const setAdditionalFilters = createAction('setAdditionalFilters');
export const resetListAndPagination = createAction('resetListAndPagination');
export const changeActiveTabSuccess = createAction('changeActiveTabSuccess');
export const toggleIftaReportDetails = createAction('toggleIftaReportDetails');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const visitIftaReportOnFleetPage = createAction('visitIftaReportOnFleetPage');
