import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const fieldNotRequired = Yup.string().nullable(true).notRequired();
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const vehicleValidationSchema = ({ isPhotos, isEditMode }: Object) => Yup.lazy(() => Yup.object().shape({
  [GC.FIELD_AREA]: fieldNotRequired,
  [GC.FIELD_NOTE]: fieldNotRequired,
  [GC.FIELD_SEVERITY]: fieldNotRequired,
  [GC.FIELD_TYPE]: G.ifElse(
    R.and(G.isFalse(isEditMode), G.isTrue(isPhotos)),
    fieldNotRequired,
    fieldRequired,
  ),
  [GC.FIELD_DOCUMENT_UPLOAD]: G.ifElse(
    R.and(G.isFalse(isEditMode), G.isTrue(isPhotos)),
    fieldRequired,
    fieldNotRequired,
  ),
}));

const defaultProps = {
  width: '300px',
  errorTop: '110%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

const fieldSettings = [
  {
    ...defaultProps,
    type: 'select',
    isRequired: true,
    loc: 'titles:type',
    fieldName: GC.FIELD_TYPE,
    options: 'damageTypeOptions',
  },
  {
    ...defaultProps,
    type: 'select',
    loc: 'titles:area',
    options: 'areaOptions',
    fieldName: GC.FIELD_AREA,
  },
  {
    ...defaultProps,
    type: 'select',
    loc: 'titles:severity',
    options: 'severityOptions',
    fieldName: GC.FIELD_SEVERITY,
  },
  {
    ...defaultProps,
    type: 'textarea',
    loc: 'titles:note',
    fieldName: GC.FIELD_NOTE,
  },
  {
    ...defaultProps,
    type: 'file',
    loc: 'titles:damage-photo',
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
  },
];

const getFieldSettings = ({ isPhotos, isEditMode }: Object) => G.ifElse(
  R.and(G.isFalse(isEditMode), G.isTrue(isPhotos)),
  [
    {
      ...defaultProps,
      type: 'file',
      isRequired: true,
      loc: 'titles:damage-photo',
      fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    },
  ],
  fieldSettings,
);

const defaultFields = R.map(() => null, R.indexBy(R.prop('fieldName'), fieldSettings));

const enhance = compose(
  withFormik({
    validationSchema: ({ isPhotos, isEditMode }: Object) => vehicleValidationSchema({ isPhotos, isEditMode }),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const DamageForm = (props: Object) => {
  const { isPhotos, isEditMode, closeModal, handleSubmit, optionsForSelect } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        flexDirection='column'
        fileName={props.fileName}
        optionsForSelect={optionsForSelect}
        fields={getFieldSettings({ isPhotos, isEditMode })}
      />
      <FormFooter closeModal={closeModal} boxStyles={{ pt: 15 }} />
    </form>
  );
};

export default enhance(DamageForm);
