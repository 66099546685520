import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Label } from '../../../components/label';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { ActionButton } from '../../../ui';
import { ConfigGroupRightSectionWrapper } from '../ui';
// feature configs
import { makeSelectConnectedToQB } from '../selectors';
import { connectToAccountingRequest, disconnectFromAccountingRequest } from '../actions';
//////////////////////////////////////////////////

const renderDisconnectButton = ({ ownAuth, disconnectFromAccountingRequest }: Object) => {
  if (R.isNil(ownAuth)) return null;

  return (
    <ActionButton
      mr={15}
      height={30}
      type='button'
      fontSize={14}
      bgColor={G.getTheme('buttons.cancelBtn.bgColor')}
      textColor={G.getTheme('buttons.cancelBtn.textColor')}
      onClick={() => disconnectFromAccountingRequest('quickbooks')}
    >
      {G.getWindowLocale('actions:disconnect', 'Disconnect')}
    </ActionButton>
  );
};

const renderConnectLabel = ({ ownAuth, parentAuth }: Object) => {
  const icon = G.ifElse(
    R.or(G.isNotNil(ownAuth), G.isNotNil(parentAuth)),
    I.uiTrue,
    I.uiFalse,
  )();
  let text = G.getWindowLocale('titles:not-connected', 'Not connected');
  if (G.isNotNil(ownAuth)) {
    text = G.getWindowLocale('titles:connected', 'Connected');
  } else if (G.isNotNil(parentAuth)) {
    text = G.getWindowLocale('titles:connected-via-parent', 'Connected Via Parent');
  }
  return (
    <Label frontIcon={icon}>{text}</Label>
  );
};

const ConnectToQBComponent = ({
  connectedToQB,
  connectToAccountingRequest,
  disconnectFromAccountingRequest,
}: Object) => (
  <ConfigGroupRightSectionWrapper mr={12}>
    <ActionButton
      mr={15}
      height={30}
      type='button'
      fontSize={14}
      onClick={() => connectToAccountingRequest('quickbooks')}
    >
      {G.getWindowLocale('actions:connect', 'Connect')}
    </ActionButton>
    {renderDisconnectButton({ ...connectedToQB, disconnectFromAccountingRequest })}
    {renderConnectLabel(connectedToQB)}
  </ConfigGroupRightSectionWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  connectedToQB: makeSelectConnectedToQB(state),
});

export default connect(mapStateToProps, {
  connectToAccountingRequest,
  disconnectFromAccountingRequest,
})(ConnectToQBComponent);
