import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSelectors } from '../../report-common';
// feature branch
import { EDIT_BRANCH_FORM } from './constants';
//////////////////////////////////////////////////

const {
  store,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('branch');

const makeSelectBranchesTree = () => createSelector(
  store,
  (branch: Object) => G.getPropFromObject('treeBranches', branch),
);

const searchTree = (tree: Object, currentBranch: Object) => {
  const children = G.getPropFromObject('children', tree);

  if (R.eqProps(GC.FIELD_GUID, tree, currentBranch)) {
    return tree;
  } else if (G.isNotNilAndNotEmpty(children)) {
    let i;
    let result = null;

    for (i = 0; result == null && i < R.length(children); i++) {
      result = searchTree(children[i], currentBranch);
    }

    if (G.isNotNilAndNotEmpty(result)) return result;
  }
};

const makeSelectBranchesTreeFromCurrentAndDown = () => createSelector(
  store,
  (state: Object) => {
    const { treeBranches, currentBranch } = state;

    return searchTree(treeBranches, currentBranch);
  },
);

const getListFromTree = (tree: Object) => {
  const array = [];

  const mapTree = (childTree: Object) => {
    array.push(R.omit('children', childTree));

    const children = G.getPropFromObject('children', childTree);

    if (G.isNotNilAndNotEmpty(children)) {
      let i;

      for (i = 0; i < R.length(children); i++) {
        mapTree(children[i]);
      }
    }
  };

  mapTree(tree);

  return R.indexBy(R.prop(GC.FIELD_GUID), array);
};

const makeSelectBranchListFromCurrentAndDown = () => createSelector(
  store,
  (state: Object) => {
    const { treeBranches, currentBranch } = state;

    const tree = searchTree(treeBranches, currentBranch);

    return getListFromTree(tree);
  },
);

const makeSelectBranchById = () => createSelector(
  store,
  ({ branch }: Object) => branch,
);

const makeSelectBranchCompanyDetails = () => createSelector(
  store,
  ({ branch }: Object) => G.getPropFromObject('CompanyDetails', branch),
);

const makeSelectBranchSetting = () => createSelector(
  store,
  ({ branch }: Object) => G.getPropFromObject('BranchSetting', branch),
);

const makeSelectCurrentBranch = () => createSelector(
  store,
  ({ currentBranch }: Object) => currentBranch,
);

const makeSelectCurrentBranchGuid = () => createSelector(
  store,
  ({ currentBranch }: Object) => G.getGuidFromObject(currentBranch),
);

const makeSelectEditForm = () => createSelector(
  ({ form }: Object) => form,
  (reduxForm: Object) => reduxForm[EDIT_BRANCH_FORM],
);

const makeSelectCollapsedGroup = () => createSelector(
  store,
  ({ collapsedGroup }: Object) => collapsedGroup,
);

const makeSelectBranchRefs = () => createSelector(
  store,
  (branch: Object) => G.getPropFromObject(GC.UI_FIELD_REFERENCES, branch),
);

const makeSelectEditBranchBillToLocation = () => createSelector(
  store,
  ({ editBranchBillToLocation }: Object) => editBranchBillToLocation,
);

const makeSelectEditBranchPrimaryLocation = () => createSelector(
  store,
  ({ editBranchPrimaryLocation }: Object) => editBranchPrimaryLocation,
);

const makeSelectCurrentBranchConfigs = () => createSelector(
  store,
  ({ currentBranchConfigs }: Object) => {
    const documentTypeOptions = G.getFullOptionsFromDropdownConfigStore(
      currentBranchConfigs,
      GC.COMMUNICATION_BRANCH_DOCUMENT_TYPE,
    );

    const locationTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      currentBranchConfigs,
      GC.TEMPLATES_LOCATION_TYPE,
      true,
    );

    const subTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      currentBranchConfigs,
      GC.GENERAL_BRANCH_SUBTYPE,
      true,
    );

    return { subTypeOptions, documentTypeOptions, locationTypeOptions };
  },
);

const makeSelectEditBranchDocumentList = () => createSelector(
  store,
  ({ editBranchDocumentList }: Object) => editBranchDocumentList,
);

const makeSelectImportMappers = () => createSelector(
  store,
  ({ importMappers }: Object) => importMappers,
);

const makeSelectAdditionalLocationList = () => createSelector(
  store,
  ({ additionalLocationList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(additionalLocationList),
);

const makeSelectBranchGuidByBranchId = () => createSelector(
  store,
  ({ branch }: Object) => R.path(['CompanyDetails', GC.FIELD_GUID], branch),
);

const makeSelectSelectedList = () => createSelector(
  store,
  (state: Object) => R.compose(
    R.keys,
    R.filter(({ selected }: Object) => selected),
    R.pathOr([], ['itemList']),
  )(state),
);

const makeSelectCurrentBranchType = () => createSelector(
  store,
  R.path(['currentBranch', GC.BRANCH_TYPE]),
);

const makeSelectCommissionAssigneeByType = () => createSelector(
  store,
  ({ commissionAssigneeByType }: Object) => commissionAssigneeByType,
);

const makeSelectMasterSettings = () => createSelector(
  store,
  ({ masterSettings }: Object) => masterSettings,
);

export {
  store,
  makeSelectItemList,
  makeSelectEditForm,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectBranchById,
  makeSelectTotalCount,
  makeSelectBranchRefs,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectSelectedList,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectBranchesTree,
  makeSelectCurrentBranch,
  makeSelectImportMappers,
  makeSelectBranchSetting,
  makeSelectMasterSettings,
  makeSelectCollapsedGroup,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectCurrentBranchType,
  makeSelectCurrentBranchGuid,
  makeSelectTableTitleFilters,
  makeSelectBranchCompanyDetails,
  makeSelectBranchGuidByBranchId,
  makeSelectCurrentBranchConfigs,
  makeSelectEditBranchDocumentList,
  makeSelectAdditionalLocationList,
  makeSelectCommissionAssigneeByType,
  makeSelectEditBranchBillToLocation,
  makeSelectEditBranchPrimaryLocation,
  makeSelectBranchListFromCurrentAndDown,
  makeSelectBranchesTreeFromCurrentAndDown,
};
