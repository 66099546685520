import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms/formik';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultValues = {
  [GC.FIELD_NOTE]: '',
  [GC.FIELD_STATUS]: '',
  [GC.FIELD_END_DATE]: null,
  [GC.FIELD_START_DATE]: null,
  [GC.FIELD_DRIVER_CARD_COLOR]: null,
};

const fieldSettings = [
  {
    isRequired: true,
    type: 'datePicker',
    timeSelection: true,
    fieldName: GC.FIELD_START_DATE,
    label: ['titles:start-date', 'Start Date'],
    inputWrapperStyles: { mr: 15, mb: 25, width: 200 },

  },
  {
    isRequired: true,
    type: 'datePicker',
    timeSelection: true,
    fieldName: GC.FIELD_END_DATE,
    label: ['titles:end-date', 'End Date'],
    inputWrapperStyles: { mb: 25, width: 200 },
  },
  {
    type: 'select',
    fieldName: GC.FIELD_STATUS,
    options: GC.DRIVER_PENDING_ACTIVITY_STATUS,
    inputWrapperStyles: { mr: 15, mb: 25, width: 200 },
    label: ['titles:pending-activity-status', 'Pending Activity Status'],
  },
  {
    type: 'colorPicker',
    label: ['titles:color', 'Color'],
    fieldName: GC.FIELD_DRIVER_CARD_COLOR,
    inputWrapperStyles: { mb: 25, width: 200 },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_NOTE,
    label: ['titles:note', 'Note'],
    inputWrapperStyles: { mb: 25, width: 415 },
  },
  {
    concatLocationFields: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_LOCATION,
    label: ['titles:location', 'Location'],
    inputWrapperStyles: { mb: 25, width: 415 },
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_START_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_END_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_NOTE]: Yup.string().nullable(true).notRequired(),
  [GC.FIELD_STATUS]: Yup.string().nullable(true).notRequired(),
});

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitHandler(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
  }),
  withProps({ configsNamesArray: [GC.DRIVER_PENDING_ACTIVITY_STATUS] }),
  withAsyncConfigs,
  withProps(({ asyncConfigs }: Object) => {
    const options = G.mapDropdownConfigWithParentGuid(asyncConfigs, GC.DRIVER_PENDING_ACTIVITY_STATUS);

    return {
      optionsForSelect: { [GC.DRIVER_PENDING_ACTIVITY_STATUS]: options },
    };
  }),
  withHandlers({
    handleSelectLocation: (props: Object) => (location: string) => {
      const { setFieldValue } = props;

      setFieldValue(GC.FIELD_LOCATION, location);
    },
  }),
  pure,
);

const PendingActivityForm = (props: Object) => {
  const { handleSubmit, optionsForSelect, handleSelectLocation } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fields={fieldSettings}
        fieldsWrapperStyles={{ mt: 10 }}
        customSelectLocationFunction={handleSelectLocation}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(PendingActivityForm);
