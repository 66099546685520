import React from 'react';
import styled from 'styled-components';
import { space, width, height, fontSize, fontWeight, borderRadius } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { RelativeBox, AbsoluteBox } from '../../ui';
import { ReactSelect } from '../../ui/select';
//////////////////////////////////////////////////

const getBorderColor = ({ hasError }: Object) => G.ifElse(
  hasError,
  G.getTheme('forms.inputs.borderColorErr'),
  G.getTheme('colors.dark.grey'),
);

const Error = styled.span`
  ${space}
  ${width}

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${() => G.getTheme('forms.inputs.errorTextColor')};
`;

const Input = styled.input`
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${fontWeight}
  ${borderRadius}

  cursor: text;
  outline: none;
  line-height: 36px;
  border: 1px solid ${(props: Object) => getBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    border-width: 1px;
    border-color: ${() => G.getTheme('colors.curiosBlue')};
  }

  &:focus:hover  {
    border-width: 2px;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;

Input.defaultProps = {
  m: 0,
  px: '8px',
  height: 36,
  borderRadius: 4,
};

const renderInput = ({ data }: Object) => {
  const {
    cloGuid,
    controlNumber,
    handleChangeClo,
    hasErrorControlNumber,
  } = data;

  const errorTextControlNumber = G.getShouldBeFromToCharLocaleTxt(8, 16);

  return (
    <RelativeBox>
      <Input
        type='text'
        width='100%'
        hasError={hasErrorControlNumber}
        value={controlNumber.toUpperCase()}
        placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
        onChange={(event: Object) =>
          handleChangeClo({
            guid: cloGuid,
            value: event.target.value,
            propName: GC.FIELD_CONTROL_NUMBER,
            errorField: 'hasErrorControlNumber',
          })}
      />
      <AbsoluteBox>
        {
          hasErrorControlNumber &&
          <Error
            pl='5px'
            width={170}
            title={errorTextControlNumber}
          >
            {errorTextControlNumber}
          </Error>}
      </AbsoluteBox>
    </RelativeBox>
  );
};

const renderSelect = ({ data }: Object) => {
  const {
    cloGuid,
    shipmentType,
    handleChangeClo,
    shipmentTypeOptions,
    hasErrorShipmentType,
  } = data;

  return (
    <RelativeBox>
      <ReactSelect
        value={shipmentType}
        useMenuPortalTarget={true}
        options={shipmentTypeOptions}
        hasError={hasErrorShipmentType}
        isDisabled={G.isNilOrEmpty(shipmentTypeOptions)}
        additionalStyles={{ container: () => ({ fontSize: 14 }) }}
        placeholder={G.getWindowLocale('actions:select', 'Select...')}
        onChange={(selectedOption: Object) => {
          handleChangeClo(
            {
              guid: cloGuid,
              propName: GC.FIELD_SHIPMENT_TYPE,
              errorField: 'hasErrorShipmentType',
              value: selectedOption ? selectedOption.value : null,
            },
          );
        }}
      />
      <AbsoluteBox>
        {
          data.hasErrorShipmentType &&
          <Error
            pl='3px'
            width={170}
          >
            {G.getRequiredLocaleTxt()}
          </Error>}
      </AbsoluteBox>
    </RelativeBox>
  );
};

export {
  renderInput,
  renderSelect,
};
