import React from 'react';
import { compose } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// hocs
import { withFixedPopover } from '../../../../hocs';
// ui
import { Box } from '../../../../ui';
// feature route
import TableGroup from '../../components/table-group';
import { withAddTableEntity } from '../hocs';
import { withTableGroupProps } from '../hocs/with-table-group-props';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withAddTableEntity,
  withTableGroupProps,
);

const TableGroupWrapper = (props: Object) => {
  const {
    tabs,
    tableData,
    loadConfigs,
    tableNumber,
    expandedList,
    activeListName,
    handleClickEditIcon,
    handleAddTableEntity,
    handleChangeActiveList,
    handleSelectTableEntity,
    handleToggleExpandedList,
    handleOpenStatusMessagesMap,
  } = props;

  return (
    <Box mt={25} key={G.genShortId()}>
      <TableGroup
        tabs={tabs}
        tableData={tableData}
        tableNumber={tableNumber}
        loadConfigs={loadConfigs}
        expandedList={expandedList}
        activeListName={activeListName}
        handleClickEditIcon={handleClickEditIcon}
        handleAddTableEntity={handleAddTableEntity}
        handleChangeActiveList={handleChangeActiveList}
        handleSelectTableEntity={handleSelectTableEntity}
        handleToggleExpandedList={handleToggleExpandedList}
        handleOpenStatusMessagesMap={handleOpenStatusMessagesMap}
      />
    </Box>
  );
};

export default enhance(TableGroupWrapper);
