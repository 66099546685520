import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { macroPointStopOrderRequest, sendTelToMacroPointRequest } from '../../../../common/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import { getMacroPointListRequest } from '../actions';
//////////////////////////////////////////////////

export const withActivateMacroPoint = withHandlers({
  handleActivateMacroPoint: (props: Object) => () => {
    const { load, closeFixedPopup, getMacroPointListRequest, sendTelToMacroPointRequest } = props;

    const loadGuid = G.getGuidFromObject(load);
    const callback = () => getMacroPointListRequest({ loadGuid });

    G.callFunction(closeFixedPopup);
    sendTelToMacroPointRequest({ callback, guids: R.of(Array, loadGuid) });
  },
});

export const withLoadMacroPointTableGroupActions = compose(
  connect(null, { getMacroPointListRequest, macroPointStopOrderRequest, sendTelToMacroPointRequest }),
  withActivateMacroPoint,
  withHandlers({
    handleDeactivateMacroPoint: (props: Object) => () => {
      const { load, closeFixedPopup, getMacroPointListRequest, macroPointStopOrderRequest } = props;

      const loadGuid = G.getGuidFromObject(load);
      const callback = () => getMacroPointListRequest({ loadGuid });

      closeFixedPopup();
      macroPointStopOrderRequest({ callback, [GC.FIELD_TEL_GUID]: loadGuid });
    },
  }),
  pure,
);
