export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  checkBoxCellWidth: 0,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 135px)',
};
