// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const SERVICE_VENDOR_GENERAL = [
  {
    title: 'titles:general',
    fields: {
      [GC.SERVICE_VENDOR_ACCOUNTING_EXTERNAL_ID_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:accounting-external-id-autogenerated',
        nameForAttribute: GC.SERVICE_VENDOR_ACCOUNTING_EXTERNAL_ID_AUTOGENERATED,
      },
      [GC.SERVICE_VENDOR_ACCOUNTING_EXTERNAL_ID_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:accounting-external-id-sequence',
        nameForAttribute: GC.SERVICE_VENDOR_ACCOUNTING_EXTERNAL_ID_SEQUENCE,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    title: 'titles:service-type',
    editPopupTitle: 'titles:edit',
    configName: GC.SERVICE_VENDOR_VENDOR_SERVICE_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    title: 'titles:document-type',
    editPopupTitle: 'titles:edit',
    configName: GC.SERVICE_VENDOR_DOCUMENT_TYPE,
  },
];

const ConfigServiceVendorGroup = {
  SERVICE_VENDOR_GENERAL,
};

export {
  ConfigServiceVendorGroup,
};
