import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms/formik';
// hocs
import { withConnectModalAndLoaderActions } from '../../../../hocs';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
//////////////////////////////////////////////////

const defaultValues = {
  [GC.FIELD_DATE_TO]: null,
  [GC.FIELD_DATE_FROM]: null,
  [GC.FIELD_TRUCK_GUIDS]: [],
};

const inputWrapperStyles = { mb: 25, width: 270 };

const fieldSettings = [
  {
    isMulti: true,
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'truckOptions',
    fieldName: GC.FIELD_TRUCK_GUIDS,
    label: ['titles:trucks', 'Trucks'],
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_DATE_FROM,
    label: ['titles:date-from', 'Date From'],
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_DATE_TO,
    label: ['titles:date-to', 'Date To'],
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_DATE_TO]: G.yupStringRequired,
  [GC.FIELD_DATE_FROM]: G.yupStringRequired,
  [GC.FIELD_TRUCK_GUIDS]: G.yupArrayRequired,
});

const enhance = compose(
  withConnectModalAndLoaderActions,
  withState('optionsForSelect', 'setOptionsForSelect', {}),
  withHandlers({
    getAvailableTrucks: (props: Object) => async () => {
      const {
        openLoader,
        closeLoader,
        currentDriverGuid,
        setOptionsForSelect,
      } = props;

      G.callFunction(openLoader);

      const options = {
        params: {
          currentDriverGuid,
          [GC.BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow(),
        },
      };

      try {
        const res = await sendRequest('get', endpointsMap.getAvailableTrucks, options);

        const { data, status } = res;

        if (G.isResponseSuccess(status, data)) {
          const truckOptions = R.map(
            ({ guid, unitId }: Object) => ({
              [GC.FIELD_VALUE]: guid,
              [GC.FIELD_LABEL]: unitId,
            }),
            R.or(data, []),
          );

          setOptionsForSelect({ truckOptions });
        } else {
          G.handleFailResponseSimple(
            res,
            true,
            'getAvailableTrucks',
          );
        }

        G.callFunction(closeLoader);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'getAvailableTrucks');
      }
    },
    getDailyLogListAvailable: (props: Object) => async (values: Object) => {
      const {
        openLoader,
        closeModal,
        closeLoader,
        driverBranchGuid,
        handleGetDailyLogListAvailable,
      } = props;

      G.callFunction(openLoader);

      const options = {
        data: {
          ...values,
          [GC.FIELD_BRANCH_GUID]: driverBranchGuid,
        },
      };

      try {
        const res = await sendRequest('post', endpointsMap.truckDailyLogListAvailable, options);

        const { data, status } = res;

        if (G.isResponseSuccess(status, data)) {
          G.callFunction(closeModal);

          G.callFunctionWithArgs(handleGetDailyLogListAvailable, data);
        } else {
          G.handleFailResponseSimple(
            res,
            true,
            'getDailyLogListAvailable',
          );
        }

        G.callFunction(closeLoader);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'getDailyLogListAvailable');
      }
    },
  }),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => {
      const { getDailyLogListAvailable } = props;

      getDailyLogListAvailable(values);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
  }),
  withHandlers({
    handleSelectLocation: (props: Object) => (location: string) => {
      const { setFieldValue } = props;

      setFieldValue(GC.FIELD_LOCATION, location);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAvailableTrucks();
    },
  }),
  pure,
);

const CreateFromTruckLogForm = (props: Object) => {
  const { handleSubmit, optionsForSelect } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fields={fieldSettings}
        fieldsWrapperStyles={{ mt: 10 }}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(CreateFromTruckLogForm);
