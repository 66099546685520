import React from 'react';
import * as R from 'ramda';
// components
import { FormFooter2 } from '../../../components';
import DimmerComponent from '../../../components/loader/dimmer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FormGroupWrapper } from '../../../forms';
// hocs
import { withFormGroupOpenedStatus } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// feature configs
import * as H from '../helpers';
import GroupsTab from '../settings/groups-tab';
import { CONFIG_PAGE_FORM } from '../constants';
import GroupSettings from '../settings/groups-config';
import ReturnInheritedComponent from './return-inherited';
import ConfigPageWrapperComponent from './config-page-wrapper';
import { renderConfigGroupItemComponent } from './config-group-item';
import FormGroupTitleMultipleComponent from './form-group-title-multiple';
//////////////////////////////////////////////////

const getHideTitleActions = (props: Object) => {
  const { isSuperAdmin, hideTitleActions, hideTitleActionsForNotSuperAdmin } = props;

  if (G.isFunction(hideTitleActions)) return hideTitleActions(props);

  if (G.isTrue(hideTitleActionsForNotSuperAdmin)) return G.isFalse(isSuperAdmin);
};

const renderConfigGroupTitle = (props: Object) => {
  const {
    title,
    configName,
    configType,
    showInherit,
    hideInherited,
    customConfigName,
    isFormGroupOpened,
    handleClickEndLabel,
    onboardingPackageList,
    handleClickReturnInherited,
    handleToggleFormGroupState } = props;

  if (R.and(
    G.isNilOrEmpty(onboardingPackageList),
    R.equals(customConfigName, GC.CARRIER_ONBOARDING_PACKAGE_DOCUMENT),
  )) return;

  if (R.and(
    G.isNotNilAndNotEmpty(onboardingPackageList),
    R.equals(customConfigName, GC.CARRIER_ONBOARDING_PACKAGE),
  )) {
    return (
      <FormGroupTitleMultipleComponent
        mb='0'
        showArrowToggle={true}
        isOpened={isFormGroupOpened}
        title={G.getWindowLocale(title)}
        onToggleFormGroup={handleToggleFormGroupState}
      />
    );
  }

  if (getHideTitleActions(props)) {
    return (
      <FormGroupTitleMultipleComponent
        mb='0'
        showArrowToggle={true}
        isOpened={isFormGroupOpened}
        title={G.getWindowLocale(title)}
        onToggleFormGroup={handleToggleFormGroupState}
      />
    );
  }

  if (hideInherited) {
    return (
      <FormGroupTitleMultipleComponent
        mb='0'
        showArrowToggle={true}
        isOpened={isFormGroupOpened}
        title={G.getWindowLocale(title)}
        onToggleFormGroup={handleToggleFormGroupState}
        endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
        onClickLabel={() => handleClickEndLabel(configName, title)}
      />
    );
  }

  if (G.isAnyTrue(
    G.isTrue(showInherit),
    R.equals(configType, 'custom'),
    R.equals(configType, 'dropdown'),
    R.equals(configType, 'customComponent'),
  )) {
    return (
      <FormGroupTitleMultipleComponent
        mb='0'
        showArrowToggle={true}
        isOpened={isFormGroupOpened}
        title={G.getWindowLocale(title)}
        onToggleFormGroup={handleToggleFormGroupState}
        endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
        onClickLabel={() => handleClickEndLabel(configName, title)}
      >
        <ReturnInheritedComponent
          handleClickReturnInherited={() => handleClickReturnInherited(configName)}
          tooltipText={G.getWindowLocale('titles:return-inherited', 'Return Inherited')}
        />
        {
          R.and(G.isTrue(isFormGroupOpened), G.isFunction(props.additionalTitleComponent)) &&
          props.additionalTitleComponent(props)
        }
      </FormGroupTitleMultipleComponent>
    );
  }

  return (
    <FormGroupTitleMultipleComponent
      mb='0'
      showArrowToggle={true}
      isOpened={isFormGroupOpened}
      title={G.getWindowLocale(title)}
      onToggleFormGroup={handleToggleFormGroupState}
    />
  );
};

const ConfigGroupComponent = withFormGroupOpenedStatus((props: Object) => {
  const { hideForNotSuperAdmin, showOnlyOnMasterBranch } = props;

  const isMasterBranch = R.pathEq(
    GC.BRANCH_TYPE_ENUM_MASTER,
    [GC.BRANCH_TYPE],
    G.getAmousCurrentBranchFromWindow(),
  );

  if (R.and(G.isFalse(isMasterBranch), G.isTrue(showOnlyOnMasterBranch))) return null;

  if (R.and(G.isTrue(hideForNotSuperAdmin), R.not(G.getIsAmousUserSuperAdminFromWindow()))) return null;

  return (
    <FormGroupWrapper isOpened={props.isFormGroupOpened}>
      {renderConfigGroupTitle(props)}
      {renderConfigGroupItemComponent(props)}
    </FormGroupWrapper>
  );
});

const getGroupItems = ({ useTabs, activeTab, groupSettings }: Object) => {
  const group = H.filterTabsWithoutPermissions(GroupSettings[groupSettings]);
  const tabName = R.path([activeTab], R.keys(group));

  if (useTabs) return R.pathOr(group[tabName], [tabName, 'settings'], group);

  return R.values(group);
};

const ConfigGroupsComponent = (props: Object) => (
  <div>
    {
      R.map((item: Object) => (
        <ConfigGroupComponent
          {...item}
          {...props}
          key={item.title}
        />
      ), getGroupItems(props))
    }
  </div>
);

const ConfigComponent = (props: Object) => {
  const {
    loader,
    useTabs,
    pageTitle,
    activeTab,
    handleSubmit,
    groupSettings,
    initialValues,
    configWarning,
    inheritedValues,
    handleFormSubmit,
    handleClickCancel,
    handleSetActiveTab,
    updateConfigsRequest,
    removeOptionalConfigRequest,
  } = props;

  const tabs = GroupsTab[groupSettings] ? H.filterTabsWithoutPermissions(GroupsTab[groupSettings]) : null;

  return (
    <ConfigPageWrapperComponent
      tabs={tabs}
      useTabs={useTabs}
      pageTitle={pageTitle}
      activeTab={activeTab}
      groupSettings={groupSettings}
      initialValues={initialValues}
      configWarning={configWarning}
      inheritedValues={inheritedValues}
      handleSetActiveTab={handleSetActiveTab}
      updateConfigsRequest={updateConfigsRequest}
      removeOptionalConfigRequest={removeOptionalConfigRequest}
    >
      {
        loader.showDimmer
          ? <DimmerComponent tableCount={3} rectangleCount={4} />
          : (
            <form id={CONFIG_PAGE_FORM} onSubmit={handleSubmit(handleFormSubmit)}>
              <ConfigGroupsComponent {...props} />
              <FormFooter2
                cancelAction={handleClickCancel}
                boxStyles={{
                  pr: 20,
                  pl: 70,
                  left: 0,
                  bottom: 0,
                  zIndex: 12,
                  height: 60,
                  width: '100%',
                  position: 'fixed',
                  borderTop: '1px solid',
                  bg: G.getTheme('colors.white'),
                  borderColor: G.getTheme('forms.actionsFooter.borderTopColor'),
                }}
              />
            </form>
          )
      }
    </ConfigPageWrapperComponent>
  );
};

export default ConfigComponent;
