import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { IconWrapper } from '../../../components/chat/ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Textarea } from '../../../forms';
import { Error } from '../../../forms/formik/fieldset2/ui';
// icons
import * as I from '../../../svgs';
// ui
import {
  Flex,
  RelativeBox,
  AbsoluteBox,
} from '../../../ui';
// features carrier-portal
import { sendCarrierMessageRequest } from '../actions';
import {
  ChatWrapper,
  ScrollableWrapper,
  ChatMessageWrapper,
} from '../ui';
import {
  makeSelectCarrierName,
  makeSelectCarrierChat,
  makeSelectCarrierPortalInfo,
  makeSelectCarrierDetailPageForm,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withState('error', 'setError', null),
  withState('message', 'setMessage', ''),
  withState('scrollDown', 'setScrollDown', true),
  withState('validation', 'setValidation', false),
  withHandlers(() => {
    const refs = {};

    return {
      handleScrollWrapper: () => () => {
        const scrollHeight = R.path(['scrollableWrapper', 'scrollHeight'], refs);

        if (G.isNotNilAndNotEmpty(scrollHeight)) {
          setTimeout(() => {
            if (G.isNotNil(refs.scrollableWrapper)) {
              refs.scrollableWrapper.scrollTop = scrollHeight;
            }
          }, 0);
        }
      },
      handleSetRefs: (props: Object) => (ref: Object, name: string) => {
        refs[name] = ref;

        if (R.and(G.isNotNil(refs.scrollableWrapper), G.isTrue(props.scrollDown))) {
          refs.scrollableWrapper.scrollTop = refs.scrollableWrapper.scrollHeight;
          const alreadyScrolled = R.or(
            R.equals(refs.scrollableWrapper.scrollHeight, 0),
            G.notEquals(refs.scrollableWrapper.scrollTop, 0),
          );

          if (alreadyScrolled) {
            props.setScrollDown(false);
          }
        }
      },
    };
  }),
  withHandlers({
    handleChangeMessage: (props: Object) => (event: Event) => {
      const message = R.path(['currentTarget', GC.FIELD_VALUE], event);

      const { setError, setMessage, setValidation } = props;

      if (R.lt(1000, R.length(message))) {
        setValidation(true);
        setError(G.getShouldBeLessOrEqualLocaleTxt(1000));
      } else {
        setValidation(false);
        setError(null);
      }

      setMessage(message);
    },
    handleSendMessage: (props: Object) => () => {
      const {
        message,
        setMessage,
        validation,
        setScrollDown,
        sendCarrierMessageRequest,
      } = props;

      if (G.isNotNilAndNotEmpty(R.trim(message))) {
        if (G.isTrue(validation)) return;

        const data = {
          text: message,
          telGuid: R.path(['detailPageInfo', 'guid'], props),
        };

        sendCarrierMessageRequest(data);
        setMessage('');
        setScrollDown(true);
      }
    },
  }),
  withPropsOnChange(['chatMessages'], (props: Object) => props.handleScrollWrapper()),
  pure,
);


const ChatModalWindow = enhance((props: Object) => (
  <ChatWrapper
    zIndex={100}
    width='100%'
    minWidth={320}
    flexDirection='column'
    justifyContent='space-between'
    borderRadiusMedia={props.borderRadiusMedia}
    height={R.or(props.chatWrapHeight, '100%')}
    bg={R.or(props.bg, G.getTheme('colors.light.black'))}
  >
    <Flex
      p='5px 15px'
      width='100%'
      height={60}
      minHeight={60}
      minWidth={320}
      alignItems='center'
      fontWeight='bolder'
      justifyContent='flex-start'
      bg={G.getTheme('colors.cobalt')}
      color={G.getTheme('colors.white')}
      boxShadow='5px 8px 9px -2px rgb(21,21,21)'
    >
      <IconWrapper
        ml={15}
        pr={10}
        mr={30}
        cursor='pointer'
        onClick={() => props.closeModal()}
      >
        {I.arrowIconMob()}
      </IconWrapper>
      {R.path(['title'], props)}
    </Flex>
    <RelativeBox
      p='5px 0px'
      width='100%'
      minWidth={320}
      overflowY='scroll'
      flexDirection='column'
      height={props.chatHeight}
      justifyContent='flex-start'
      color={G.getTheme('colors.white')}
      minHeight={R.or(props.chatHeight, 'calc(100% - 150px)')}
    >
      <ScrollableWrapper
        ref={(ref: Object) => props.handleSetRefs(ref, 'scrollableWrapper')}
      >
        {
          R.or(props.chatMessages, []).map((message: Object, index: number) => {
            const isOwner = R.equals(R.path(['createdBy'], message), R.path(['carrierName'], props));

            return (
              <Flex
                mb={20}
                key={index}
                p='5px 15px'
                fontSize={16}
                maxWidth={280}
                borderRadius={12}
                alignItems='stretch'
                wordBreak='break-word'
                flexDirection='column'
                alignSelf={G.ifElse(isOwner, 'flex-end', 'flex-start')}
                color={G.getTheme(G.ifElse(isOwner, 'colors.neroBlack', 'colors.white'))}
                bg={G.getTheme(G.ifElse(isOwner, 'colors.veryLightGrey', 'colors.demin'))}
              >
                {R.path(['text'], message)}
                <Flex
                  mt='4px'
                  fontSize={10}
                  alignSelf={G.ifElse(isOwner, 'flex-end', 'flex-start')}
                >
                  {G.createLocalDateTimeFromInstanceOrISOString(R.path(['createdDate'], message), 'LT')}
                </Flex>
              </Flex>
            );
          })
        }
      </ScrollableWrapper>
    </RelativeBox>
    <Flex
      width='100%'
      height={50}
      minHeight={50}
      minWidth={300}
      alignItems='center'
      flexDirection='column'
      borderBottomLeftRadius={20}
      borderBottomRightRadius={20}
      justifyContent='space-around'
    >
      <ChatMessageWrapper
        width='100%'
        height={50}
        p='8px 50px 8px 8px'
        bg={G.getTheme('colors.light.lightGrey')}
        colorTextArea={G.getTheme('colors.neroBlack')}
        borderBottomLeftRadius={props.borderBottomLeftRadius}
        borderColorTop={G.getTheme('colors.light.darksGrey')}
        borderBottomRightRadius={props.borderBottomRightRadius}
        border={R.and(props.validation, `2px solid ${G.getTheme('colors.light.mainRed')}`)}
      >
        <Textarea
          fontSize={15}
          value={props.message}
          onChange={props.handleChangeMessage}
          placeholder={G.getWindowLocale('titles:add-your-message-here', 'Add your message here…')}
        />
        {
          props.validation &&
          <Error top='-15px' left='10px'>{props.error}</Error>
        }
        <AbsoluteBox
          right={15}
          width={30}
          bottom='2px'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          onClick={props.handleSendMessage}
        >
          <IconWrapper mt='4px'>
            {I.sendMessadeArrow(G.getTheme('colors.darkBlue'), 30, 30)}
          </IconWrapper>
        </AbsoluteBox>
      </ChatMessageWrapper>
    </Flex>
  </ChatWrapper>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  carrierName: makeSelectCarrierName(state),
  chatMessages: makeSelectCarrierChat(state),
  detailPageInfo: makeSelectCarrierPortalInfo(state),
  statusMessageForm: makeSelectCarrierDetailPageForm(state),
});

export default connect(mapStateToProps, {
  sendCarrierMessageRequest,
})(ChatModalWindow);
