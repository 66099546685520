import * as R from 'ramda';
import React from 'react';
import { Field } from 'redux-form';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { styles, renderFormGroup } from '../../../../forms';
//////////////////////////////////////////////////

const getDisabled = ({ disabled }: Object) => {
  if (G.isFunction(disabled)) return disabled();

  return disabled;
};

export const renderField = (props: Object, field: Object, index: number) => (
  <Field
    width={232}
    key={index}
    direction='row'
    margin='5px 10px'
    type={field.type}
    labelFontSize={16}
    errorFontSize={14}
    labelMargin='5px 0'
    labelPosition='left'
    inputs={field.inputs}
    zIndex={field.zIndex}
    errorMargin='0 0 5px 0'
    onChange={props.change}
    disabled={getDisabled(field)}
    handler={props[field.handler]}
    isClearable={field.isClearable}
    additionClass={styles.inputGroup}
    validate={R.or(field.validate, [])}
    afterActionHasPerm={[PC.USER_WRITE]}
    minDate={G.setMinDate(props, field)}
    maxDate={G.setMaxDate(props, field)}
    label={G.getWindowLocale(field.name)}
    component={renderFormGroup(field.type)}
    onChangeAddress={props.handleChangeAddress}
    onSelectAddress={props.handleSelectAddress}
    selectAction={R.path([field.selectAction], props)}
    info={R.and(field.info, G.getWindowLocale(field.info))}
    name={R.or(field.nameForAttribute, R.prop(GC.FIELD_NAME, field))}
  />
);
