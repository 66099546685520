import React from 'react';
import * as Charts from '@amcharts/amcharts4/charts';
import * as ChartCore from '@amcharts/amcharts4/core';
import am4themesThemes from '@amcharts/amcharts4/themes/kelly';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
// helpers/constants
import * as G from '../../../../helpers';
// components charts
import { chartConfigsByType } from './settings';
//////////////////////////////////////////////////////

ChartCore.useTheme(am4themesThemes);
ChartCore.useTheme(am4themesAnimated);

export const withChartConfiguration = (Component: any) => {
  return class extends React.Component {
    constructor(props: Object) {
      super(props);
      this.chart = null;
    }

    componentDidMount() {
      const {
        type,
        guid,
        title,
        chartData,
      } = this.props;

      const chart = ChartCore.create(guid, Charts[chartConfigsByType[type].create]);

      const chartTitle = chart.titles.create();

      chartConfigsByType[type].configs(this.props, chart);

      chart.data = chartData.data;

      chartTitle.text = title;
      chartTitle.fontSize = 25;

      this.chart = chart;
    }

    componentWillUnmount() {
      if (G.isNotNilAndNotEmpty(this.chart)) {
        this.chart.dispose();
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};
