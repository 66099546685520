import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature customer-portal
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  itemList: [],
  totalCount: 0,
  locations: [],
  loading: false,
  tableHeight: 0,
  filterParams: {},
  usedReport: null,
  mapOpened: false,
  showBooked: false,
  timerStarted: false,
  titleSortValues: {},
  reportPending: false,
  tableTitleFilters: {},
  availableReports: null,
  transportationModeMapping: {},
  pagination: {
    offset: 0,
    limit: 500,
  },
};

const setInitialState = () => initialState;

const setTableHeight = (state: Object, data: number) => P.$set('tableHeight', data, state);

const setTimerStarted = (state: Object, data: boolean) => P.$set('timerStarted', data, state);

const cleanCustomerPortalListAndLocations = (state: Object) => P.$all(
  P.$set('itemList', []),
  P.$set('locations', []),
  state,
);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('itemList', []),
    P.$set('locations', []),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const getCustomerPortalListSuccess = (state: Object, { data, showBooked, refreshPage }: Object) => {
  const { clos, results, totalCount } = data;
  const { locations, itemList, pagination, transportationModeMapping } = state;

  const newOffset = G.ifElse(G.isTrue(refreshPage), pagination.offset, R.sum(R.values(pagination)));
  const useGoogleMap = G.isTrue(G.getAmousConfigByNameFromWindow(GC.UI_CUSTOMER_PORTAL_USE_GOOGLE_MAP));
  let newLocations = [];

  if (useGoogleMap) {
    newLocations = R.compose(
      R.uniqBy(R.prop(GC.FIELD_GUID)),
      R.concat(R.or(locations, [])),
      R.flatten,
      R.values,
      R.map((orders: Array) => R.map((order: Object) => {
        const { firstEvent, lastStatusMessage, transportationModeConfigGuid } = order;

        const transportationModeType = R.pathOr(
          GC.TRANSPORTATION_MODE_TYPE_TRUCK_LOAD,
          [transportationModeConfigGuid],
          transportationModeMapping,
        );
        let lat = R.or(
          G.getPropFromObject(GC.FIELD_LATITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LATITUDE, firstEvent),
        );
        let lng = R.or(
          G.getPropFromObject(GC.FIELD_LONGITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LONGITUDE, firstEvent),
        );

        if (R.gt(R.length(orders), 1)) {
          lat += (Math.random() - 0.5) / 1500;
          lng += (Math.random() - 0.5) / 1500;
        }

        const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };

        return R.mergeRight(order, { latLng, transportationModeType });
      }, orders)),
      R.groupBy(({ firstEvent, lastStatusMessage }: Object) => {
        let groupBy;
        const joiner = R.compose(
          R.join('-'),
          R.values,
          R.pick([GC.FIELD_LATITUDE, GC.FIELD_LONGITUDE]),
        );

        if (G.isNotNil(firstEvent)) groupBy = joiner(firstEvent);

        if (G.isNotNil(lastStatusMessage)) groupBy = joiner(lastStatusMessage);

        return groupBy;
      }),
      R.filter(({ firstEvent, lastStatusMessage }: Object) => {
        const lat = R.or(
          G.getPropFromObject(GC.FIELD_LATITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LATITUDE, firstEvent),
        );
        const lng = R.or(
          G.getPropFromObject(GC.FIELD_LONGITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LONGITUDE, firstEvent),
        );

        return R.and(G.isNotNilAndNotZero(lat), G.isNotNilAndNotZero(lng));
      }),
    )(R.or(clos, []));
  } else {
    newLocations = R.compose(
      R.uniqBy(R.prop(GC.FIELD_GUID)),
      R.concat(R.or(locations, [])),
      R.map((order: Object) => {
        const { firstEvent, lastStatusMessage, transportationModeConfigGuid } = order;

        const lat = R.or(
          G.getPropFromObject(GC.FIELD_LATITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LATITUDE, firstEvent),
        );
        const lng = R.or(
          G.getPropFromObject(GC.FIELD_LONGITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LONGITUDE, firstEvent),
        );
        const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
        const transportationModeType = R.pathOr(
          GC.TRANSPORTATION_MODE_TYPE_TRUCK_LOAD,
          [transportationModeConfigGuid],
          transportationModeMapping,
        );

        return R.mergeRight(order, { latLng, transportationModeType });
      }),
      R.filter(({ firstEvent, lastStatusMessage }: Object) => {
        const lat = R.or(
          G.getPropFromObject(GC.FIELD_LATITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LATITUDE, firstEvent),
        );
        const lng = R.or(
          G.getPropFromObject(GC.FIELD_LONGITUDE, lastStatusMessage),
          G.getPropFromObject(GC.FIELD_LONGITUDE, firstEvent),
        );

        return R.and(G.isNotNilAndNotZero(lat), G.isNotNilAndNotZero(lng));
      }),
    )(R.or(clos, []));
  }

  const list = R.compose(
    R.concat(R.or(itemList, [])),
    R.map((item: Object) => {
      const { guid } = item;

      const transportationModeType = R.compose(
        R.path(['transportationModeType']),
        R.find(R.propEq(guid, GC.FIELD_GUID)),
      )(newLocations);

      return R.assoc('transportationModeType', transportationModeType, item);
    }),
  )(R.or(results, []));
  const offset = G.ifElse(R.gt(totalCount, newOffset), newOffset, totalCount);

  return P.$all(
    P.$set('itemList', list),
    P.$set('mapOpened', true),
    P.$set('totalCount', totalCount),
    P.$set('showBooked', showBooked),
    P.$set('locations', newLocations),
    P.$set('pagination.offset', offset),
    state,
  );
};

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const setReportPending = (state: Object) => (
  P.$set('reportPending', true, state)
);

const setReports = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('titleSortValues', {}),
    P.$set('reportPending', false),
    P.$set('tableTitleFilters', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('filterParams', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setQuickFilterParams = (state: Object, data: Object) => (
  P.$set('filterParams', data, state)
);

const setMapOpened = (state: Object, mapOpened: boolean) => P.$set('mapOpened', mapOpened, state);

const getTransportationModeMappingSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const mapping = R.compose(
    R.map(R.prop(GC.FIELD_ICON_TYPE)),
    R.indexBy(R.prop(GC.FIELD_TRANSPORTATION_MODE_GUID)),
  )(data);

  return P.$set('transportationModeMapping', mapping, state);
};

export default createReducer({
  [A.setReports]: setReports,
  [A.setMapOpened]: setMapOpened,
  [A.setUsedReport]: setUsedReport,
  [A.setTableHeight]: setTableHeight,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setTimerStarted]: setTimerStarted,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setReportPending]: setReportPending,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.getCustomerPortalListSuccess]: getCustomerPortalListSuccess,
  [A.cleanCustomerPortalListAndLocations]: cleanCustomerPortalListAndLocations,
  [A.getTransportationModeMappingSuccess]: getTransportationModeMappingSuccess,
}, initialState);
