import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature audit
import * as A from './actions';
import { makeSelectPagination, makeSelectObjGuidAndAuditType } from './selectors';
//////////////////////////////////////////////////

function* getAuditListRequest({ payload }: Object = {}) {
  try {
    yield put(openLoader());
    let extraOptions = R.pick([GC.FIELD_TYPE, GC.FIELD_OBJECT_GUID], payload);

    if (R.isEmpty(extraOptions)) {
      extraOptions = yield select(makeSelectObjGuidAndAuditType());
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setAuditLoading(true));
    const pagination = yield select(makeSelectPagination());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        ...pagination,
        ...extraOptions,
        fields: [],
        orderFields: [],
        searchCriteria: [],
        [GC.CURRENT_BRANCH]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.auditList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedAuditListSuccess(R.mergeRight(data, extraOptions)));
    } else {
      yield call(G.handleFailResponse, res, 'getAuditListRequest fail');
    }

    yield put(closeLoader());
    yield put(A.setAuditLoading(false));
  } catch (error) {
    yield put(closeLoader());
    yield put(A.setAuditLoading(false));
    yield call(G.handleException, error, 'getAuditListRequest exception');
  }
}

function* auditWatcherSaga() {
  yield takeLatest(A.getAuditListRequest, getAuditListRequest);
}

export default auditWatcherSaga;
