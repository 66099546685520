import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, FixedFlex, PageWrapper, ActionButton, CancelButton } from '../../../ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const buttonStyles = {
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const FleetProfileWrapper = (props: Object) => {
  const { children, handleGoBack, handleClose, setSaveAndClose, expandedContainer } = props;

  const isMobileAndExpanded = R.and(expandedContainer, window.isMobile);

  return (
    <PageWrapper
      p={20}
      pb='0px'
      height='calc(100vh - 60px)'
      bgColor={G.getTheme('colors.bgGrey')}
      pl={G.ifElse(G.isTrue(expandedContainer), 30, 75)}
    >
      <Box mx='auto' maxWidth={1550}>
        {children}
      </Box>
      <FixedFlex
        right='0px'
        zIndex={12}
        bottom='0px'
        p='14px 20px'
        justifyContent='flex-end'
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
        left={isMobileAndExpanded ? 60 : '0px'}
        background={G.getTheme('colors.white')}
        width={isMobileAndExpanded ? 'auto' : '100%'}
      >
        {
          G.isFunction(handleGoBack) &&
          <CancelButton
            {...buttonStyles}
            mr={20}
            width={160}
            textColor={greyMatterhornColor}
            borderColor={greyMatterhornColor}
            onClick={() => setSaveAndClose([])}
          >
            {G.getWindowLocale('actions:save-and-go-back', 'Save and Go Back')}
          </CancelButton>
        }
        <CancelButton
          {...buttonStyles}
          onClick={handleClose}
          textColor={greyMatterhornColor}
          borderColor={greyMatterhornColor}
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </CancelButton>
        {
          G.isFunction(setSaveAndClose) &&
          <ActionButton
            {...buttonStyles}
            ml={20}
            textColor={darkBlueColor}
            borderColor={darkBlueColor}
            onClick={() => setSaveAndClose(true)}
          >
            {G.getWindowLocale('actions:save-and-close', 'Save and Close')}
          </ActionButton>
        }
      </FixedFlex>
    </PageWrapper>
  );
};

export default FleetProfileWrapper;
