import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const store = R.path(['fleetProfile', 'vendor']);

const makeSelectVendorGeneralDetails = () => createSelector(
  store,
  ({ generalDetails }: Object) => generalDetails,
);

const makeSelectVendorGeneralTabEntities = () => createSelector(
  store,
  R.pick([
    'contacts',
    'insurance',
    'references',
    'payToLocation',
    'integrationInfo',
    'fleetAssignment',
    'integrationAudit',
  ]),
);

const makeSelectDocumentsTabEntities = () => createSelector(
  store,
  (state: Object) => {
    const { documents } = state;

    const documentsFilter = R.path(['formGroupFilters', 'documentsFilter'], state);

    const filteredDocuments = R.compose(
      R.filter((item: Object) => R.or(
        G.isNilOrEmpty(documentsFilter),
        R.pathEq(documentsFilter, [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], item),
      )),
      R.pathOr([], ['documents']),
    )(state);

    return {
      documents: G.ifElse(G.isNotNil(documents), filteredDocuments, documents),
    };
  },
);

const makeSelectVendorRatingEngineTabEntities = () => createSelector(
  store,
  R.pick(['accessorialList', 'compensationList']),
);

const makeSelectVendorPayrollTabEntities = () => createSelector(
  store,
  R.pick(['payrollAccessorialList', 'monthlyPayrollDeductionList']),
);

export {
  // common
  makeSelectVendorGeneralDetails,
  // vendor
  makeSelectDocumentsTabEntities,
  makeSelectVendorGeneralTabEntities,
  makeSelectVendorPayrollTabEntities,
  makeSelectVendorRatingEngineTabEntities,
};
