import React from 'react';
import * as R from 'ramda';
// components
import { Table } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
// feature carrier-rating-report
import { telReport, telTableSettings, telColumnSettings } from '../settings/table-settings';
//////////////////////////////////////////////////

const TelList = ({ parentProps }: Object) => (
  <Box ml={50}>
    <Box p='6px 16px' fontSize={14} bg={G.getTheme('colors.light.lightGrey')}>
      {G.getWindowLocale('titles:failed-tels', 'Failed Trips')}
    </Box>
    <Table
      loading={false}
      report={telReport}
      tableSettings={telTableSettings}
      columnSettings={telColumnSettings}
      itemList={R.values(parentProps.tels)}
    />
  </Box>
);

export default TelList;
