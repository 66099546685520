import React from 'react';
import { connect } from 'react-redux';
// components
import { setAudioPlaying } from '../../../components/audio-player/actions';
// svg
import * as I from '../../../svgs';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const AudioPlayerComponent = ({ setAudioPlaying }: Object) => {
  const description = '<AudioPlayer /> component is designed to render an audio player.';

  const usage = (
    <ul>
      <li>This component has been integrated into main layout, allowing access through the `<i>setAudioPlaying</i>` dispatch action.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='AudioPlayer'
      description={description}
      path='src/components/audio-player'
    >
      <CustomButton onClick={() => setAudioPlaying(true)}>Play audio {I.bellIcon('white')}</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  setAudioPlaying,
})(AudioPlayerComponent);
