// helpers/constants
import * as GC from '../../../constants';
// feature configs
import { ConfigUIGroup } from './ui-group';
import { ConfigCloGroup } from './clo-group';
import { ConfigTelGroup } from './tel-group';
import { ConfigTruckGroup } from './truck-group';
import { ConfigRatingGroup } from './rating-group';
import { ConfigDriverGroup } from './driver-group';
import { ConfigTrailerGroup } from './trailer-group';
import { ConfigGeneralGroup } from './general-group';
import { ConfigCarrierGroup } from './carrier-group';
import { ConfigOptionalGroup } from './optional-group';
import { ConfigInvoicesGroup } from './invoices-group';
import { ConfigPasswordGroup } from './password-group';
import { ConfigTemplatesGroup } from './template-group';
import { ConfigMobileAppGroup } from './mobileapp-group';
import { ConfigFleetGeneralGroup } from './fleet-general';
import { ConfigServiceVendorGroup } from './service-vendor';
import { ConfigIntegrationGroup } from './integration-group';
import { ConfigClaimManagementGroup } from './claim-management';
import { ConfigCommunicationGroup } from './communication-group';
import { ConfigTaskManagementGroup } from './task-management-group';
//////////////////////////////////////////////////

export const referenceTypesCLOConfigsNamesArray = [
  GC.CLO_PRIMARY_REFERENCE_TYPE,
  GC.CLO_AUTO_REFERENCE_1_TYPE,
  GC.CLO_AUTO_REFERENCE_2_TYPE,
  GC.CLO_AUTO_REFERENCE_3_TYPE,
  GC.CLO_GENERAL_COPY_REFERENCES,
];

export const sequencesCLOConfigsNamesArray = [
  GC.CLO_QUOTE_REQUEST_NUMBER_SEQUENCE,
  GC.CLO_PRIMARY_REFERENCE_SEQUENCE,
  GC.CLO_AUTO_REFERENCE_1_SEQUENCE,
  GC.CLO_AUTO_REFERENCE_2_SEQUENCE,
  GC.CLO_AUTO_REFERENCE_3_SEQUENCE,
];

export const referenceTypesTELConfigsNamesArray = [
  GC.TEL_PRIMARY_REFERENCE_TYPE,
  GC.TEL_AUTO_REFERENCE_1_TYPE,
  GC.TEL_AUTO_REFERENCE_2_TYPE,
  GC.TEL_AUTO_REFERENCE_3_TYPE,
  GC.TEL_GENERAL_COPY_REFERENCES,
];

export const referenceTypesMasterConfigsNamesArray = [
  GC.OPTIONAL_QB_OVERRIDE_URL_REFERENCES,
];

export const sequencesTELConfigsNamesArray = [
  GC.TEL_PRIMARY_REFERENCE_SEQUENCE,
  GC.TEL_AUTO_REFERENCE_1_SEQUENCE,
  GC.TEL_AUTO_REFERENCE_2_SEQUENCE,
  GC.TEL_AUTO_REFERENCE_3_SEQUENCE,
];

export const sequencesGeneralConfigsNamesArray = [
  GC.GENERAL_ROUTE_NAME_SEQUENCE,
];

export const sequencesInvoiceConfigsNamesArray = [
  GC.INVOICE_TEL_PAYROLL_NUMBER_SEQUENCE,
];

export default {
  [GC.CLO_CONFIG_GROUP]: {
    ...ConfigCloGroup,
  },
  [GC.TEL_CONFIG_GROUP]: {
    ...ConfigTelGroup,
  },
  [GC.GENERAL_CONFIG_GROUP]: {
    ...ConfigGeneralGroup,
  },
  [GC.OPTIONAL_CONFIG_GROUP]: {
    ...ConfigOptionalGroup,
  },
  [GC.PASSWORD_CONFIG_GROUP]: {
    ...ConfigPasswordGroup,
  },
  [GC.TEMPLATES_CONFIG_GROUP]: {
    ...ConfigTemplatesGroup,
  },
  [GC.DRIVER_CONFIG_GROUP]: {
    ...ConfigDriverGroup,
  },
  [GC.TRUCK_CONFIG_GROUP]: {
    ...ConfigTruckGroup,
  },
  [GC.TRAILER_CONFIG_GROUP]: {
    ...ConfigTrailerGroup,
  },
  [GC.COMMUNICATION_CONFIG_GROUP]: {
    ...ConfigCommunicationGroup,
  },
  [GC.RATE_ENGINE_CONFIG_GROUP]: {
    ...ConfigRatingGroup,
  },
  [GC.INVOICE_CONFIG_GROUP]: {
    ...ConfigInvoicesGroup,
  },
  [GC.UI_CONFIG_GROUP]: ConfigUIGroup,
  [GC.CARRIER_CONFIG_GROUP]: {
    ...ConfigCarrierGroup,
  },
  [GC.MOBILEAPP_CONFIG_GROUP]: {
    ...ConfigMobileAppGroup,
  },
  [GC.CLAIM_CONFIG_GROUP]: ConfigClaimManagementGroup,
  [GC.INTEGRATION_CONFIG_GROUP]: ConfigIntegrationGroup,
  [GC.FLEET_GENERAL_CONFIG_GROUP]: ConfigFleetGeneralGroup,
  [GC.SERVICE_VENDOR_CONFIG_GROUP]: ConfigServiceVendorGroup,
  [GC.TASK_MANAGEMENT_CONFIG_GROUP]: ConfigTaskManagementGroup,
};
