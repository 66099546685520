import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { FormGroupTable } from '../../../../components/form-group-table';
// features
import { withDocumentActions } from '../../../fleet-profile/hocs/with-document-actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import * as LC from '../../constants';
import { withAddOrEditDocument } from '../../hocs';
import { getAdditionaDocumentColumnActions } from '../../helpers';
import { DocumentsTitlePanel } from '../../components/documents-title-panel';
// feature fleet/truck
import { docsSettings } from '../settings/column-settings';
import { makeSelectTruckDocuments, makeSelectTruckDocumentsFilter } from '../selectors';
import {
  setDocumentsFilter,
  createTruckDocRequest,
  updateTruckDocRequest,
  deleteTruckDocRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = R.compose(
  withDocumentActions,
  withAddOrEditDocument({ fleetType: 'truck' }),
);

const DocTabComponent = (props: Object) => {
  const {
    documents,
    truckConfigs,
    handleEditDoc,
    collapsedGroup,
    handleDeleteDoc,
    handlePreviewDocument,
    handleDownloadDocument,
  } = props;

  const documentTypes = R.pathOr([], [GC.FIELD_DOCUMENT_DOCUMENT_TYPE], truckConfigs);

  return (
    <FormGroupTable
      entities={documents}
      tableColumnWidth={100}
      fields={collapsedGroup}
      handleEditRow={handleEditDoc}
      entitiesFields={docsSettings}
      handleDeleteRow={handleDeleteDoc}
      groupName={LC.GROUP_NAME_DOCUMENTS}
      isOpened={G.getPropFromObject(LC.GROUP_NAME_DOCUMENTS, collapsedGroup)}
      customPanel={() => <DocumentsTitlePanel {...props} filterOptions={documentTypes} />}
      additionalColumnActions={getAdditionaDocumentColumnActions(
        { handlePreviewDocument, handleDownloadDocument, endpointName: 'truckDownloadDoc' },
      )}
    />
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  documents: makeSelectTruckDocuments(state),
  documentsFilter: makeSelectTruckDocumentsFilter(state),
}));

export default connect(mapStateToProps, {
  setDocumentsFilter,
  createTruckDocRequest,
  updateTruckDocRequest,
  deleteTruckDocRequest,
})(enhance(DocTabComponent));
