import { createAction } from 'redux-act';
// common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const setValueToStore = createAction('setValueToStore');
const getDashboardRequest = createAction('getDashboardRequest');
const getDashboardSuccess = createAction('getDashboardSuccess');
const setDateFiltersToStore = createAction('setDateFiltersToStore');
const setDashboardTypeToStore = createAction('setDashboardTypeToStore');
const getChartByFiltersRequest = createAction('getChartByFiltersRequest');
const getChartByFiltersSuccess = createAction('getChartByFiltersSuccess');
const setEnterpriseFilterToStore = createAction('setEnterpriseFilterToStore');
const getNormalizedCurrencyRequest = createAction('getNormalizedCurrencyRequest');
const getNormalizedCurrencySuccess = createAction('getNormalizedCurrencySuccess');

const { setTableTitleSort, setTableTitleFilter } = getReportActions();

const generateExcelWithChartData = createAction('generateExcelWithChartData');

export {
  setValueToStore,
  setTableTitleSort,
  getDashboardRequest,
  getDashboardSuccess,
  setTableTitleFilter,
  setDateFiltersToStore,
  setDashboardTypeToStore,
  getChartByFiltersRequest,
  getChartByFiltersSuccess,
  generateExcelWithChartData,
  setEnterpriseFilterToStore,
  getNormalizedCurrencyRequest,
  getNormalizedCurrencySuccess,
};

