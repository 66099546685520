import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature driver-profile-simple
import * as A from './actions';
//////////////////////////////////////////////////

function* getDriverProfileInfoRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.driverCardsInfo(payload);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverProfileInfoSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverProfileInfoRequest');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverProfileInfoRequest');
  }
}

function* getDriverCertificatesRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_DRIVER_GUID]: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.driverCertificationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverCertificatesSuccess({ certificates: data, [GC.FIELD_DRIVER_GUID]: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverCertificatesRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverCertificatesRequest exception');
  }
}

function* getDriverFuelCardsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_DRIVER_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.fleetDriverExternalFuelCards, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverFuelCardsSuccess({ fuelCards: data, [GC.FIELD_DRIVER_GUID]: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverFuelCardsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverFuelCardsRequest exception');
  }
}

function* toggleDriverFuelCardRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, value, driverGuid } = payload;

    const params = { [GC.FIELD_DRIVER_GUID]: driverGuid };
    const endpoint = G.ifElse(
      G.isTrue(value),
      endpointsMap.fleetDriverExternalEnableByGuid(guid),
      endpointsMap.fleetDriverExternalDisableByGuid(guid),
    );

    const res = yield call(sendRequest, 'put', endpoint, { params });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.toggleDriverFuelCardSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'toggleDriverFuelCardRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'toggleDriverFuelCardRequest exception');
  }
}

function* getDriverAvailabilityStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_DRIVER_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.driverAvailabilityStatus, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverAvailabilityStatusSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverAvailabilityStatusRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverAvailabilityStatusRequest exception');
  }
}

function* driverProfileSimpleWatcherSaga() {
  yield takeLatest(A.getDriverFuelCardsRequest, getDriverFuelCardsRequest);
  yield takeLatest(A.getDriverProfileInfoRequest, getDriverProfileInfoRequest);
  yield takeLatest(A.toggleDriverFuelCardRequest, toggleDriverFuelCardRequest);
  yield takeLatest(A.getDriverCertificatesRequest, getDriverCertificatesRequest);
  yield takeLatest(A.getDriverAvailabilityStatusRequest, getDriverAvailabilityStatusRequest);
}

export default driverProfileSimpleWatcherSaga;
