import React from 'react';
import * as R from 'ramda';
// components
import { Table } from '../../../../components/table';
import { Label } from '../../../../components/label';
import { DownloadWithLabel } from '../../../../components/download-with-label';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// forms
import { Toggle } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
} from '../../../../helpers/options';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature fleet/driver
import * as LC from '../constants';
import DriverHosLogList from '../components/driver-hos-log-list';
import { downloadDriverCertificationDocumentRequest } from '../actions';
import { driverIntegrationTypeOptions } from '../../settings/fields-settings';
//////////////////////////////////////////////////

export const latestKnownLocationSettings = {
  country: {
    width: 200,
    name: 'titles:country',
  },
  state: {
    width: 200,
    name: 'titles:state',
  },
  city: {
    width: 200,
    name: 'titles:city',
  },
  zip: {
    width: 200,
    name: 'titles:postal-code',
  },
  lastModifiedDate: {
    width: 200,
    name: 'titles:update-on',
  },
  lastModifiedBy: {
    width: 150,
    name: 'titles:updated-by',
  },
  createdDate: {
    width: 200,
    name: 'titles:created-on',
  },
  createdBy: {
    width: 150,
    name: 'titles:created-by',
  },
};

export const compensationSettings = {
  [GC.FIELD_DRIVER_COMPENSATION_NAME]: {
    width: 100,
    name: 'titles:name',
  },
  [GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE]: {
    width: 100,
    name: 'titles:effective-date',
  },
  [GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE]: {
    width: 100,
    name: 'titles:expiration-date',
  },
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITIES]: {
    width: 150,
    name: 'titles:origin-cities',
  },
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_STATES]: {
    width: 150,
    name: 'titles:origin-states',
  },
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_COUNTRIES]: {
    width: 150,
    name: 'titles:origin-countries',
  },
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_CODES]: {
    width: 150,
    name: 'titles:origin-zip-codes',
  },
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_FROM]: {
    width: 100,
    name: 'titles:origin-zip-from',
  },
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_TO]: {
    width: 100,
    name: 'titles:origin-zip-to',
  },
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITIES]: {
    width: 150,
    name: 'titles:destination-cities',
  },
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_STATES]: {
    width: 150,
    name: 'titles:destination-states',
  },
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_COUNTRIES]: {
    width: 150,
    name: 'titles:destination-countries',
  },
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_CODES]: {
    width: 150,
    name: 'titles:destination-zip-codes',
  },
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_FROM]: {
    width: 100,
    name: 'titles:destination-zip-form',
  },
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_TO]: {
    width: 100,
    name: 'titles:destination-zip-to',
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 100,
    name: 'titles:rate',
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 100,
    name: 'titles:rate-type',
    options: DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 100,
    name: 'titles:rate-unit',
    options: DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 110,
    name: 'titles:rate-minimum',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 110,
    name: 'titles:rate-maximum',
  },
  [GC.FIELD_TRANSIT_DAYS]: {
    width: 100,
    name: 'titles:transit-days',
  },
  [GC.FIELD_SERVICE_TYPE]: {
    width: 100,
    name: 'titles:service-type',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: {
    width: 100,
    name: 'titles:distance-range-from',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: {
    width: 100,
    name: 'titles:distance-range-to',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: {
    width: 100,
    name: 'titles:distance-range-uom',
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
  },
  [GC.FIELD_MIN_WEIGHT]: {
    width: 100,
    name: 'titles:min-weight',
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
  },
  [GC.FIELD_MAX_WEIGHT]: {
    width: 100,
    name: 'titles:max-weight',
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
  },
  [GC.FIELD_WEIGHT_UOM]: {
    width: 100,
    name: 'titles:weight-uom',
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
  },
  [GC.FIELD_MIN_TEMPERATURE]: {
    width: 100,
    name: 'titles:min-temperature',
  },
  [GC.FIELD_MAX_TEMPERATURE]: {
    width: 100,
    name: 'titles:max-temperature',
  },
  [GC.FIELD_TEMPERATURE_UOM]: {
    width: 100,
    name: 'titles:temperature-uom',
  },
  [GC.FIELD_MIN_QUANTITY]: {
    width: 100,
    name: 'titles:min-quantity',
  },
  [GC.FIELD_MAX_QUANTITY]: {
    width: 100,
    name: 'titles:max-quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 100,
    name: 'titles:package-type',
  },
  [GC.FIELD_GET_FUEL_FROM_ORDER]: {
    width: 100,
    name: 'titles:get-fuel-from-order',
  },
  [GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER]: {
    width: 150,
    name: 'titles:add-additional-charges-from-order',
  },
};

export const docsSettings = {
  documentType: {
    width: 150,
    name: 'titles:type',
  },
  status: {
    width: 150,
    name: 'titles:status',
  },
  description: {
    width: 300,
    name: 'titles:description',
  },
  signedDate: {
    width: 150,
    name: 'titles:signed-date',
  },
  expirationDate: {
    width: 150,
    name: 'titles:expired-date',
  },
  documentFilename: {
    width: 300,
    name: 'titles:file',
  },
  url: {
    width: 250,
    name: 'titles:url',
    label: ({ url }: Object) =>
      <a target='_blank' href={G.makeURLString(url)}>{url}</a>, // eslint-disable-line
  },
};

export const certificationSettings = {
  certificationType: {
    width: 150,
    name: 'titles:type',
  },
  certificationName: {
    width: 150,
    name: 'titles:name',
  },
  certificationDate: {
    width: 150,
    name: 'titles:certification-date',
  },
  expirationDate: {
    width: 150,
    name: 'titles:expiration-date',
  },
  notes: {
    width: 300,
    name: 'titles:notes',
  },
  oosItem: {
    width: 100,
    name: 'titles:oos-item',
    label: (ent: Object) =>
      <Label frontLabel={G.ifElse(ent.oosItem, 'active', 'deactive')} />,
  },
  [GC.FIELD_FILE_NAME]: {
    width: 600,
    name: 'titles:file',
    label: ({ fileName, fileUri, driverGuid }: Object) =>
      <DownloadWithLabel
        icon='pdf'
        downloadAction={downloadDriverCertificationDocumentRequest}
        entity={{ fileUri, driverGuid, documentFilename: fileName }} />,
  },
};

export const contactSettings = {
  address: {
    width: 200,
    name: 'titles:address',
  },
  country: {
    width: 200,
    name: 'titles:country',
  },
  state: {
    width: 200,
    name: 'titles:state',
  },
  city: {
    width: 200,
    name: 'titles:city',
  },
  postalCode: {
    width: 200,
    name: 'titles:postal-code',
  },
  email: {
    width: 200,
    name: 'titles:email',
  },
  mobilePhone: {
    width: 200,
    name: 'titles:mobile-phone',
  },
  homePhone: {
    width: 200,
    name: 'titles:home-phone',
  },
  emergencyContactName: {
    width: 200,
    name: 'titles:emergency-contact-name',
  },
  emergencyPhone: {
    width: 200,
    name: 'titles:emergency-phone',
  },
};

const fuelCardEnabledIntegrations = [GC.FLEET_INTEGRATION_TYPE_EFS];

export const externalIntegrationColumnSettings = {
  [GC.FIELD_FLEET_EXTERNAL_ID]: {
    width: 150,
    name: 'titles:external-id',
  },
  [GC.FIELD_FLEET_INTEGRATION_TYPE]: {
    width: 150,
    name: 'titles:integration-type',
    customLogic: (item: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(item, GC.FIELD_VALUE)),
    )(driverIntegrationTypeOptions),
  },
  [GC.FIELD_FLEET_FUEL_CARD_ID]: {
    width: 150,
    name: 'titles:fuel-card-id',
  },
  [GC.FIELD_FUEL_CARD_ENABLED]: {
    width: 100,
    uiField: true,
    name: 'titles:fuel-card-enabled',
    customLogic: (_: Object, props: Object) => {
      const { entity, handleToggleEnabled } = props;
      const { type, enabled } = entity;

      if (G.notContain(type, fuelCardEnabledIntegrations)) return null;

      const enabledIcon = G.ifElse(G.isTrue(enabled), I.uiTrue, I.uiFalse)();

      return (
        <Flex alignItems='baseline' justifyContent='space-around'>
          <Box>{enabledIcon}</Box>
          <AuthWrapper has={[PC.ENABLE_FUEL_CARD_EXECUTE]}>
            <Toggle
              icons={false}
              checked={enabled}
              onChange={(e: Object) => handleToggleEnabled({ value: e.target.checked, entity })}
            />
          </AuthWrapper>
        </Flex>
      );
    },
  },
  [GC.FIELD_FLEET_TRANSPONDER_ID]: {
    width: 150,
    name: 'titles:transponder-id',
  },
  [GC.FIELD_TEAM_DRIVER]: {
    width: 300,
    name: 'titles:team-driver-external-id',
    customLogic: (item: Object) => {
      if (G.isNilOrEmpty(R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item))) return;

      const fullName = R.join('', [R.prop(GC.FIELD_FIRST_NAME, item), R.prop(GC.FIELD_LAST_NAME, item)]);

      return R.join('', [fullName, `(${R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item)})`]);
    },
  },
  [GC.FIELD_TRUCK]: {
    width: 200,
    name: 'titles:truck-external-id',
    customLogic: (item: Object) => R.and(
      G.isNotNilAndNotEmpty(R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item)),
      R.join('', [R.prop(GC.FIELD_TRUCK_UNIT_ID, item), `(${R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item)})`]),
    ),
  },
  [GC.FIELD_TRAILERS]: {
    width: 500,
    name: 'titles:trailers-external-id',
    customLogic: (item: Object) => R.compose(
      R.join(', '),
      R.map((item: Object) => R.join(
        '',
        [R.prop(GC.FIELD_TRUCK_UNIT_ID, item), `(${R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item)})`],
      )),
      R.filter((item: Object) => G.isNotNilAndNotEmpty(R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item))),
    )(item),
  },
};

export const fuelDetailsTableSettings = {
  useMainColors: true,
  checkBoxCellWidth: 0,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'fit-content',
};

export const fuelCardsReport = {
  fields: [
    { sequence: 8, name: GC.FIELD_CITY },
    { sequence: 5, name: GC.FIELD_TOTAL },
    { sequence: 9, name: GC.FIELD_STATE },
    { sequence: 6, name: GC.FIELD_CURRENCY },
    { sequence: 10, name: GC.FIELD_TIME_ZONE },
    { sequence: 7, name: GC.FIELD_LOCATION_NAME },
    { sequence: 2, name: GC.FIELD_FUEL_CARDS_SOURCE },
    { sequence: 1, name: GC.FIELD_FUEL_CARDS_CARD_ID },
    { sequence: 4, name: GC.FIELD_FUEL_CARDS_TRANSACTION_DATE },
    { sequence: 3, name: GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER },
  ],
};

export const fuelDetailsReport = {
  fields: [
    { sequence: 6, name: GC.FIELD_UOM },
    { sequence: 7, name: GC.FIELD_PRICE },
    { sequence: 9, name: GC.FIELD_TOTAL },
    { sequence: 8, name: GC.FIELD_FUEL_CARDS_DISCOUNT },
    { sequence: 5, name: GC.FIELD_FUEL_CARDS_QUANTITY },
    { sequence: 3, name: GC.FIELD_FUEL_CARDS_GROUP_NAME },
    { sequence: 2, name: GC.FIELD_FUEL_CARDS_PRODUCT_NAME },
    { sequence: 1, name: GC.FIELD_FUEL_CARDS_PRODUCT_TYPE },
    { sequence: 4, name: GC.FIELD_FUEL_CARDS_PRODUCT_CODE },
  ],
};

export const fuelDetailsColumnSettings = {
  [GC.FIELD_FUEL_CARDS_PRODUCT_TYPE]: {
    width: 200,
    name: 'titles:product-type',
  },
  [GC.FIELD_FUEL_CARDS_PRODUCT_NAME]: {
    width: 200,
    name: 'titles:product-name',
  },
  [GC.FIELD_FUEL_CARDS_GROUP_NAME]: {
    width: 200,
    name: 'titles:group-name',
  },
  [GC.FIELD_FUEL_CARDS_PRODUCT_CODE]: {
    width: 200,
    name: 'titles:product-code',
  },
  [GC.FIELD_FUEL_CARDS_QUANTITY]: {
    width: 200,
    name: 'titles:quantity',
  },
  [GC.FIELD_UOM]: {
    width: 200,
    name: 'titles:uom',
  },
  [GC.FIELD_PRICE]: {
    width: 200,
    name: 'titles:price',
  },
  [GC.FIELD_FUEL_CARDS_DISCOUNT]: {
    width: 200,
    name: 'titles:discount',
  },
  [GC.FIELD_TOTAL]: {
    width: 200,
    name: 'titles:total',
  },
};

const FuelCardDetailsComponent = (props: Object) => (
  <Box ml={25}>
    <Table
      itemList={props.data}
      report={fuelDetailsReport}
      tableSettings={fuelDetailsTableSettings}
      columnSettings={fuelDetailsColumnSettings}
    />
  </Box>
);

export const fuelCardTableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: false,
  checkBoxCellWidth: 27,
  expandableItems: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 140px)',
  expandedDetailsComponent: FuelCardDetailsComponent,
};

export const fuelCardColumnSettings = {
  [GC.FIELD_FUEL_CARDS_CARD_ID]: {
    width: 150,
    name: 'titles:card-id',
  },
  [GC.FIELD_FUEL_CARDS_SOURCE]: {
    width: 150,
    name: 'titles:source',
  },
  [GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER]: {
    width: 150,
    name: 'titles:transaction-number',
  },
  [GC.FIELD_FUEL_CARDS_TRANSACTION_DATE]: {
    width: 200,
    name: 'titles:transaction-date',
  },
  [GC.FIELD_TOTAL]: {
    width: 150,
    name: 'titles:total',
  },
  [GC.FIELD_CURRENCY]: {
    width: 150,
    name: 'titles:currency',
  },
  [GC.FIELD_LOCATION_NAME]: {
    width: 200,
    name: 'titles:location-name',
  },
  [GC.FIELD_CITY]: {
    width: 200,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 150,
    name: 'titles:state',
  },
  [GC.FIELD_TIME_ZONE]: {
    width: 150,
    name: 'titles:time-zone',
  },
};

export const deductionSettings = {
  [GC.FIELD_INCLUDE_FUEL_CHARGE]: {
    width: 200,
    name: 'titles:include-fuel-charge',
  },
  [GC.FIELD_INCLUDE_TOLL_CHARGE]: {
    width: 200,
    name: 'titles:include-toll-charge',
  },
  [GC.FIELD_INCLUDE_ADVANCE_PAYMENT]: {
    width: 200,
    name: 'titles:include-advance-payment',
  },
};

export const driverDailyHoursTableSettings = {
  maxHeight: 800,
  cellFontSize: 14,
  titleFontSize: 14,
  tableRowHeight: 55,
  titleRowHeight: 55,
  expandableItems: true,
  checkBoxCellWidth: 30,
  allowSelectItems: false,
  expandedDetailsComponent: ({ parentProps }: Object) => <DriverHosLogList {...parentProps} />,
};

export const driverDailyHoursReport = {
  fields: [
    { sequence: 0, name: 'actions' },
    { sequence: 1, name: GC.FIELD_LOG_DATE },
    { sequence: 2, name: GC.FIELD_ON_DUTY_MINUTES },
    { sequence: 3, name: GC.FIELD_DRIVING_MINUTES },
    { sequence: 4, name: GC.FIELD_SOURCE },
    { sequence: 5, name: GC.FIELD_CREATED_BY },
    { sequence: 6, name: GC.FIELD_CREATED_DATE },
    { sequence: 7, name: GC.FIELD_LAST_MODIFIED_BY },
    { sequence: 8, name: GC.FIELD_LAST_MODIFIED_DATE },
  ],
};

export const getDriverDailyHoursColumnSettings = ({ handleEdit, handleDelete }: Object) => ({
  actions: {
    width: 80,
    customComponent: ({ data }: Object) => {
      if (G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_PAYROLL_GUID, data))) return null;

      return (
        <Flex>
          <Box p='6px 8px' ml='6px' cursor='pointer' onClick={() => handleDelete(data)}>
            {I.trash()}
          </Box>
          <Box p='6px' ml='4px' cursor='pointer' onClick={() => handleEdit(data)}>
            {I.pencil()}
          </Box>
        </Flex>
      );
    },
  },
  [GC.FIELD_LOG_DATE]: {
    width: 200,
    name: 'titles:date',
  },
  [GC.FIELD_ON_DUTY_MINUTES]: {
    width: 150,
    name: 'titles:on-duty-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_ON_DUTY_MINUTES, data));

      return <Box>{shortString}</Box>;
    },
  },
  [GC.FIELD_DRIVING_MINUTES]: {
    width: 150,
    name: 'titles:driving-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_DRIVING_MINUTES, data));

      return <Box>{shortString}</Box>;
    },
  },
  [GC.FIELD_SOURCE]: {
    width: 200,
    name: 'titles:source',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    name: 'titles:update-on',
  },
});

export const payrollCompensationFields = {
  [GC.FIELD_RATE_TYPE]: {
    width: 150,
    name: 'titles:rate-type',
  },
  [GC.FIELD_RATE_UNIT]: {
    width: 150,
    name: 'titles:rate-unit',
  },
  [GC.FIELD_WORKING_DAYS]: {
    width: 150,
    name: 'titles:working-days',
  },
  [GC.FIELD_CHARGE_NAME]: {
    width: 150,
    name: 'titles:charge-name',
  },
  [GC.FIELD_RATE]: {
    width: 150,
    name: 'titles:rate',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
};

export const payrollVariableValuesTableSettings = {
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  maxHeight: 'fit-content',
};

export const payrollVariableValuesReport = {
  fields: [
    { sequence: 0, name: GC.FIELD_CHARGE_NAME },
    { sequence: 1, name: GC.FIELD_RATE },
    { sequence: 2, name: GC.FIELD_VALUE_FROM },
    { sequence: 3, name: GC.FIELD_VALUE_TO },
  ],
};

export const payrollVariableValuesColumnSettings = {
  [GC.FIELD_CHARGE_NAME]: {
    width: 200,
    name: 'titles:charge-name',
  },
  [GC.FIELD_RATE]: {
    width: 100,
    name: 'titles:rate',
  },
  [GC.FIELD_VALUE_FROM]: {
    width: 100,
    name: 'titles:from',
  },
  [GC.FIELD_VALUE_TO]: {
    width: 100,
    name: 'titles:to',
  },
};

export const defaultMonthlyPayrollDeductionFields = {
  [GC.FIELD_RATE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_IFTA_DATE_TO]: null,
  [GC.FIELD_IFTA_DATE_FROM]: null,
  [GC.FIELD_PAYROLL_ASSESSORIAL_GUID]: null,
};

export const monthlyPayrollDeductionReport = {
  fields: [
    { sequence: 0, name: 'actions', freezed: true },
    { sequence: 1, name: GC.FIELD_DISPLAYED_VALUE },
    { sequence: 2, name: GC.FIELD_AMOUNT },
    { sequence: 3, name: GC.FIELD_TOTAL_AMOUNT },
    { sequence: 4, name: GC.FIELD_PAID_AMOUNT },
    { sequence: 5, name: GC.FIELD_HISTORICAL_PAID_AMOUNT },
    { sequence: 6, name: GC.FIELD_CURRENCY },
    { sequence: 7, name: GC.FIELD_ACTIVE },
    { sequence: 8, name: GC.FIELD_LIMITED },
    { sequence: 9, name: GC.FIELD_DEDUCTION },
    { sequence: 10, name: GC.FIELD_FULLY_PAID },
    { sequence: 11, name: GC.FIELD_DATE_FROM },
    { sequence: 12, name: GC.FIELD_DATE_TO },
    { sequence: 13, name: GC.FIELD_COMMENTS },
  ],
};

export const getMonthlyPayrollDeductionColumnSettings = ({ handleEdit, handleDelete, deletePermission }: Object) => ({
  actions: {
    width: 80,
    customComponent: ({ data }: Object) => (
      <Flex>
        <AuthWrapper has={[deletePermission]}>
          <Box p='6px 8px' ml='6px' cursor='pointer' onClick={() => handleDelete(data)}>
            {I.trash()}
          </Box>
        </AuthWrapper>
        <Box p='6px' ml='4px' cursor='pointer' onClick={() => handleEdit(true, data)}>
          {I.pencil()}
        </Box>
      </Flex>
    ),
  },
  [GC.FIELD_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:accessorial',
  },
  [GC.FIELD_AMOUNT]: {
    width: 200,
    name: 'titles:amount',
  },
  [GC.FIELD_TOTAL_AMOUNT]: {
    width: 200,
    name: 'titles:total-amount',
  },
  [GC.FIELD_PAID_AMOUNT]: {
    width: 200,
    name: 'titles:paid-amount',
  },
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: {
    width: 200,
    name: 'titles:historical-paid-amount',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_DEDUCTION]: {
    width: 150,
    type: 'boolean',
    name: 'titles:deduction',
  },
  [GC.FIELD_FULLY_PAID]: {
    width: 150,
    type: 'boolean',
    name: 'titles:fully-paid',
  },
  [GC.FIELD_DATE_FROM]: {
    width: 200,
    name: 'titles:date-from',
  },
  [GC.FIELD_DATE_TO]: {
    width: 200,
    name: 'titles:date-to',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.FIELD_LIMITED]: {
    width: 100,
    type: 'boolean',
    name: 'titles:limited',
  },
  [GC.FIELD_ACTIVE]: {
    width: 100,
    type: 'boolean',
    name: 'titles:active',
  },
});

export const monthlyPayrollDeductionDetailsTableSettings = {
  maxHeight: 400,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
};

export const monthlyPayrollDeductionDetailsReport = {
  fields: [
    { sequence: 0, name: GC.FIELD_PAYROLL_NUMBER },
    { sequence: 1, name: GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL },
    { sequence: 2, name: GC.FIELD_PAYMENT_MONTH },
    { sequence: 3, name: GC.FIELD_PAYMENT_YEAR },
  ],
};

export const getMonthlyPayrollDeductionDetailsColumnSettings = (currency: string) => ({
  [GC.FIELD_PAYROLL_NUMBER]: {
    width: 300,
    name: 'titles:payroll-number',
    customComponent: ({ data }: Object) => (
      <Box
        cursor='pointer'
        textDecoration='underline'
        color={G.getTheme('colors.dark.blue')}
        onClick={() => G.goToRoute(routesMap.driverPayrollList)}
      >
        {data.payrollNumber}
      </Box>
    ),
  },
  [GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL]: {
    width: 200,
    name: 'titles:total',
    customComponent: ({ data }: Object) => {
      const displayText = G.ifElse(
        R.equals(R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data), R.prop(GC.FIELD_TOTAL, data)),
        `${R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data)} ${currency}`,
        `${R.prop(GC.FIELD_TOTAL, data)} ${currency} (${R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data)} ${
          R.prop(GC.FIELD_CURRENCY, data)})`,
      );

      return (
        <Box>{displayText}</Box>
      );
    },
  },
  [GC.FIELD_PAYMENT_MONTH]: {
    width: 200,
    name: 'titles:payment-month',
  },
  [GC.FIELD_PAYMENT_YEAR]: {
    width: 200,
    name: 'titles:payment-year',
  },
});

const MonthlyPayrollDeductionDetails = ({ data, parentProps }: Object) => (
  <Box ml={50}>
    <Table
      loading={false}
      itemList={data}
      report={monthlyPayrollDeductionDetailsReport}
      tableSettings={monthlyPayrollDeductionDetailsTableSettings}
      columnSettings={getMonthlyPayrollDeductionDetailsColumnSettings(R.prop(GC.FIELD_CURRENCY, parentProps))}
    />
  </Box>
);

export const monthlyPayrollDeductionTableSettings = {
  maxHeight: 800,
  cellFontSize: 14,
  titleFontSize: 14,
  tableRowHeight: 55,
  titleRowHeight: 55,
  expandableItems: true,
  checkBoxCellWidth: 30,
  allowSelectItems: false,
  expandedDetailsComponent: MonthlyPayrollDeductionDetails,
};
