import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// forms
import { SingleInputForm } from '../../../forms/forms/single-input-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature carrier-edi
import StatusMessages from './status-messages';
import { deleteListItemRequest, assignEdiInvoiceToTripRequest } from '../actions';
//////////////////////////////////////////////////

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

const enhance = compose(
  connect(
    null,
    { deleteListItemRequest, assignEdiInvoiceToTripRequest },
  ),
  withHandlers({
    handleAssignToTrip: (props: Object) => () => {
      const { entity, openModal, closeModal, closeFixedPopup, assignEdiInvoiceToTripRequest } = props;

      G.callFunction(closeFixedPopup);
      const fieldLabel = G.getWindowLocale('titles:tel-number', 'Trip #');
      const submitAction = (telPrimaryReferenceValue: string) => assignEdiInvoiceToTripRequest({
        telPrimaryReferenceValue,
        [GC.FIELD_GUID]: G.getGuidFromObject(entity),
      });
      const component = (
        <SingleInputForm fieldLabel={fieldLabel} cancelAction={closeModal} submitAction={submitAction} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('actions:assign-to-trip', 'Assign To Trip'),
        },
      };

      openModal(modal);
    },
    handleShowStatusMessages: ({ entity, openModal, closeFixedPopup }: Object) => () => {
      G.callFunction(closeFixedPopup);
      const itemList = R.compose(
        R.map((item: Object) => {
          const { location } = item;

          const fieldsToPick = [GC.FIELD_ADDRESS, GC.FIELD_CITY, GC.FIELD_STATE, GC.FIELD_ZIP, GC.FIELD_COUNTRY];

          return R.assoc(GC.FIELD_LOCATION, G.concatLocationFields(location, fieldsToPick), item);
        }),
        R.propOr([], GC.SYSTEM_OBJECT_STATUS_MESSAGES),
      )(entity);
      const component = <StatusMessages itemList={itemList} />;
      const modal = {
        p: '0px',
        component,
        options: {
          maxWidth: '90vw',
          maxHeight: '90vh',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:status-messages', 'Status Messages'),
        },
      };

      openModal(modal);
    },
    handleDeleteItem: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        deleteListItemRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => {
          closeModal();
          deleteListItemRequest(R.of([], G.getGuidFromObject(entity)));
        },
      });

      return openModal(modalContent);
    },
  }),
  pure,
);

const getOptions = (props: Object) => {
  const {
    entity,
    closeFixedPopup,
    handleDeleteItem,
    handleAssignToTrip,
    handleShowStatusMessages,
  } = props;

  const iconColor = G.getTheme('colors.light.blue');

  const { status, transactionType } = entity;

  let options = [
    {
      frontIcon: I.trash(iconColor),
      text: G.getWindowLocale('actions:delete', 'Delete'),
      permissions: [PC.CARRIER_EDI_READ, PC.CARRIER_EDI_WRITE],
      action: () => {
        closeFixedPopup();
        handleDeleteItem();
      }
    },
  ];

  const condition = R.and(
    R.equals(status, GC.INVOICE_EDI_STATUS_NO_MATCH),
    R.includes(
      transactionType,
      [
        GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_INVOICE,
        GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_STATUS_MESSAGE,
      ],
    ),
  );

  if (condition) {
    const assignToTripOption = {
      action: handleAssignToTrip,
      frontIcon: I.plusRound(iconColor),
      permissions: [PC.CARRIER_EDI_READ, PC.CARRIER_EDI_WRITE],
      text: G.getWindowLocale('actions:assign-to-trip', 'Assign To Trip'),
    };

    options = R.prepend(assignToTripOption, options);
  }

  if (G.isNotNilAndNotEmpty(R.prop(GC.SYSTEM_OBJECT_STATUS_MESSAGES, entity))) {
    const showStatusMessagesAction = {
      frontIcon: I.eye(iconColor),
      action: handleShowStatusMessages,
      text: G.getWindowLocale('actions:show-status-messages', 'Show Status Messages'),
    };

    options = R.append(showStatusMessagesAction, options);
  }

  return options;
};

export const RowActions = enhance((props: Object) => (
  <ActionsElement entity={props.entity} options={getOptions(props)} />
));
