import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, branch, compose, renderComponent } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import Loader from '../../components/loader';
import { makeSelectLoader } from '../../components/loader/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { AbsoluteBox, RelativeFlex } from '../../ui';
// features carrier-portal
import * as H from './helpers';
import {
  carrierResponseAcceptRequest,
  downloadRateConfirmationDocumentRequest,
  previewCarrierTermsAndConditionsDocumentRequest,
} from './actions';
import {
  defaultAcceptValues,
  defaultBidQuoteFields,
  setInitialFormikValues,
  getAcceptFormValidationSchema,
} from './settings/fields-settings';
import {
  RateSection,
  CarrierSection,
  BidQuoteSection,
  OrderQuoteSection,
  AcceptedRateSection,
} from './components/sections';
import {
  makeSelectPortal,
  makeSelectPortalType,
  makeSelectCloRefTypes,
  makeSelectAcceptedDispatch,
} from './selectors';
//////////////////////////////////////////////////

const CarrierResponseAccept = (props: Object) => (
  <RelativeFlex width='100vw' alignItems='stretch'>
    {R.path(['loader', 'isOpened'], props) && <Loader />}
    <RateSection
      width='50%'
      portal={props.portal}
      fromAcceptPage={true}
      portalType={props.portalType}
    />
    {
      R.and(G.isFalse(props.acceptedDispatch), H.isPortalTypeCarrier(props.portalType)) &&
      <CarrierSection {...props} />
    }
    {
      R.and(G.isFalse(props.acceptedDispatch), H.isPortalTypeCustomer(props.portalType)) &&
      <OrderQuoteSection {...props} />
    }
    {
      R.and(G.isFalse(props.acceptedDispatch), H.isPortalTypeBidQuote(props.portalType)) &&
      <BidQuoteSection {...props} />
    }
    {
      G.isTrue(props.acceptedDispatch) &&
      <AcceptedRateSection
        portalType={props.portalType}
        emailDivisionName={R.pathOr('', ['portal', 'emailDivisionName'], props)}
        carrierCompanyName={R.pathOr('', ['portal', 'carrierCompanyName'], props)}
        existsRateConfirmation={R.path(['portal', 'existsRateConfirmation'], props)}
        downloadRateConfirmationDocumentRequest={props.downloadRateConfirmationDocumentRequest}
      />
    }
    <AbsoluteBox
      top='0'
      right='0'
      zIndex={9}
      width='50%'
      height='100%'
      minHeight='100vh'
      background={G.getTheme('colors.bgGrey')}
    />
  </RelativeFlex>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  loader: makeSelectLoader(state),
  portal: makeSelectPortal(state),
  portalType: makeSelectPortalType(state),
  cloRefTypes: makeSelectCloRefTypes(state),
  acceptedDispatch: makeSelectAcceptedDispatch(state),
});

const getFormikMapPropsToValues = ({ portal, portalType }: Object) => {
  if (H.isPortalTypeCarrier(portalType)) {
    return setInitialFormikValues(
      defaultAcceptValues,
      R.pick(R.keys(defaultAcceptValues), portal),
    );
  }

  if (H.isPortalTypeBidQuote(portalType)) {
    const firstAccessorial = R.path(['additionalChargeAssessorials', 0, 'originGuid'], portal);

    const charges = R.compose(
      R.map((item: Object) => {
        if (R.propEq(GC.CHARGE_TYPE_ADDITIONAL, GC.FIELD_TYPE, item)) {
          return R.assoc(GC.FIELD_ACCESSORIAL_CONFIG_GUID, firstAccessorial, item);
        }

        return item;
      }),
      R.pathOr([], [GC.FIELD_CHARGES]),
    )(defaultBidQuoteFields);

    return R.assoc(GC.FIELD_CHARGES, charges, defaultBidQuoteFields);
  }

  const references = R.filter(({ primary }: Object) =>
    G.isFalse(primary),
    R.pathOr([], [GC.FIELD_REFERENCES], portal),
  );
  const events = R.map(({ location, cloEventIndex, eventEarlyDate }: Object) => ({
    cloEventIndex,
    eventEarlyDate,
    [GC.FIELD_ADDRESS_1]: G.getPropFromObject(GC.FIELD_ADDRESS_1, location),
    [GC.FIELD_ADDRESS_2]: G.getPropFromObject(GC.FIELD_ADDRESS_2, location),
    [GC.FIELD_LOCATION_NAME]: G.getPropFromObject(GC.FIELD_LOCATION_NAME, location),
  }), R.pathOr([], [GC.FIELD_LOAD_STOPS], portal));

  return {
    events,
    references,
    [GC.FIELD_ACCEPTED_BY]: null,
    [GC.FIELD_ACCEPTED_BY_EMAIL]: null,
    [GC.ACCEPTED_BY_PHONE_NUMBER]: null,
    [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: G.getPropFromObject(GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS, portal),
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    {
      openModal,
      closeModal,
      carrierResponseAcceptRequest,
      downloadRateConfirmationDocumentRequest,
      previewCarrierTermsAndConditionsDocumentRequest,
    },
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: getFormikMapPropsToValues,
    validationSchema: getAcceptFormValidationSchema,
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { portalType, carrierResponseAcceptRequest } = props;

      let data = values;

      if (H.isPortalTypeBidQuote(portalType)) {
        const { main, comments, currency } = values;

        const charges = R.compose(
          R.prepend({
            [GC.FIELD_TOTAL]: main,
            [GC.FIELD_TYPE]: GC.CHARGE_TYPE_MAIN,
            [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
          }),
          R.filter(({ total }: Object) => G.isNotZero(total)),
          R.map((charge: Object) => R.assoc(
            GC.FIELD_TOTAL,
            G.NaNToZero(G.toNumber(G.getPropFromObject(GC.FIELD_TOTAL, charge))),
            charge,
          )),
          R.prop(GC.FIELD_CHARGES),
        )(values);

        data = { charges, comments, currency };
      }

      carrierResponseAcceptRequest({ data, setSubmitting });
    },
  }),
  branch(
    ({ portal }: Object) => G.isNilOrEmpty(portal),
    renderComponent(Loader),
  ),
  pure,
);

export default enhance(CarrierResponseAccept);
