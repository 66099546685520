import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const createCarrierDeactivationRuleRequest = createAction('createCarrierDeactivationRuleRequest');
export const createCarrierDeactivationRuleSuccess = createAction('createCarrierDeactivationRuleSuccess');
export const updateCarrierDeactivationRuleRequest = createAction('updateCarrierDeactivationRuleRequest');
export const updateCarrierDeactivationRuleSuccess = createAction('updateCarrierDeactivationRuleSuccess');
export const deleteCarrierDeactivationRuleRequest = createAction('deleteCarrierDeactivationRuleRequest');
export const deleteCarrierDeactivationRuleSuccess = createAction('deleteCarrierDeactivationRuleSuccess');
export const getParentBranchesByBranchGuidSuccess = createAction('getParentBranchesByBranchGuidSuccess');
export const receivedCarrierOnboardingListSuccess = createAction('receivedCarrierOnboardingListSuccess');
export const getCarrierDeactivationRuleListRequest = createAction('getCarrierDeactivationRuleListRequest');
export const getCarrierDeactivationRuleListSuccess = createAction('getCarrierDeactivationRuleListSuccess');
export const deleteCarrierOnboardingPackageRequest = createAction('deleteCarrierOnboardingPackageRequest');
export const deleteCarrierOnboardingPackageSuccess = createAction('deleteCarrierOnboardingPackageSuccess');
export const getCarrierOnboardingPackageListRequest = createAction('getCarrierOnboardingPackageListRequest');
export const createOnboardingPackageDocumentRequest = createAction('createOnboardingPackageDocumentRequest');
export const getCarrierOnboardingDocumentListRequest = createAction('getCarrierOnboardingDocumentListRequest');
export const downloadOnboardingPackageDocumentRequest = createAction('downloadOnboardingPackageDocumentRequest');
export const getBranchConfigsByNamesForCarrierGroupSuccess =
  createAction('getBranchConfigsByNamesForCarrierGroupSuccess');
export const deleteCarrierOnboardingPackageDocumentRequest =
  createAction('deleteCarrierOnboardingPackageDocumentRequest');
export const deleteCarrierOnboardingPackageDocumentSuccess =
  createAction('deleteCarrierOnboardingPackageDocumentSuccess');
export const receivedCarrierOnboardingDocumentListSuccess =
  createAction('receivedCarrierOnboardingDocumentListSuccess');
export const createOrUpdateCarrierOnboardingPackageRequest =
  createAction('createOrUpdateCarrierOnboardingPackageRequest');
export const createOrUpdateCarrierOnboardingPackageSuccess =
  createAction('createOrUpdateCarrierOnboardingPackageSuccess');
export const createOrUpdateCarrierOnboardingPackageDocumentRequest =
  createAction('createOrUpdateCarrierOnboardingPackageDocumentRequest');
export const createOrUpdateCarrierOnboardingPackageDocumentSuccess =
  createAction('createOrUpdateCarrierOnboardingPackageDocumentSuccess');
// carrier scorecards config
export const removeCarrierScorecardConfigRequest = createAction('removeCarrierScorecardConfigRequest');
export const removeCarrierScorecardConfigSuccess = createAction('removeCarrierScorecardConfigSuccess');
export const getCarrierScorecardConfigListRequest = createAction('getCarrierScorecardConfigListRequest');
export const getCarrierScorecardConfigListSuccess = createAction('getCarrierScorecardConfigListSuccess');
export const createOrUpdateCarrierScorecardConfigRequest = createAction('createOrUpdateCarrierScorecardConfigRequest');
export const createOrUpdateCarrierScoreCardconfigSuccess = createAction('createOrUpdateCarrierScoreCardconfigSuccess');
