import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import {
  createPendingActivitySuccess as driverCardsCreatePendingActivitySuccess,
  updatePendingActivitySuccess as driverCardsUpdatePendingActivitySuccess,
  removePendingActivitySuccess as driverCardsRemovePendingActivitySuccess,
  createUnavailablePeriodSuccess as driverCardsCreateUnavailablePeriodSuccess,
  updateUnavailablePeriodSuccess as driverCardsUpdateUnavailablePeriodSuccess,
  removeUnavailablePeriodSuccess as driverCardsRemoveUnavailablePeriodSuccess,
} from '../drivers-card/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature driver-profile-simple
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  driverProfiles: {},
  driverGroupGuid: null,
  driverProfileGuid: null,
  openedDriverProfile: false,
  driverPendingActivities: [],
  driverUnavailablePeriods: [],
  driverAvailabilityStatus: null,
};

const setInitialState = () => initialState;

const openDriverProfile = (
  state: Object,
  {
    driverGroupGuid,
    driverProfileGuid,
    pendingActivities = [],
    unavailablePeriods = [],
  }: Object) => (
  P.$all(
    P.$set('openedDriverProfile', true),
    P.$set('driverGroupGuid', driverGroupGuid),
    P.$set('driverProfileGuid', driverProfileGuid),
    P.$set('driverPendingActivities', pendingActivities),
    P.$set('driverUnavailablePeriods', unavailablePeriods),
    state,
  )
);

const closeDriverProfile = (state: Object) => (
  P.$all(
    P.$set('driverGroupGuid', null),
    P.$set('driverProfileGuid', null),
    P.$set('openedDriverProfile', false),
    P.$set('driverPendingActivities', []),
    P.$set('driverUnavailablePeriods', []),
    state,
  )
);

const getDriverProfileInfoSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  if (G.isNilOrEmpty(guid)) return state;

  const driverProfile = R.mergeRight(data, { openedFuelCards: false, openedCertificates: false });

  return P.$set(`driverProfiles.${guid}`, driverProfile, state);
};

const toggleDriverCertificates = (state: Object, driverGuid: string) => (
  P.$toggle(`driverProfiles.${driverGuid}.openedCertificates`, state)
);

const getDriverCertificatesSuccess = (state: Object, { driverGuid, certificates }: Object) => {
  if (G.isNilOrEmpty(driverGuid)) return state;
  const { driverProfiles } = state;

  const newProfile = R.mergeRight(
    G.getPropFromObject(driverGuid, driverProfiles),
    { certificates, openedCertificates: true },
  );

  return P.$set(`driverProfiles.${driverGuid}`, newProfile, state);
};

const toggleDriverFuelCards = (state: Object, driverGuid: string) => (
  P.$toggle(`driverProfiles.${driverGuid}.openedFuelCards`, state)
);

const getDriverFuelCardsSuccess = (state: Object, { fuelCards, driverGuid }: Object) => {
  if (G.isNilOrEmpty(driverGuid)) return state;

  const { driverProfiles } = state;

  const newProfile = R.mergeRight(
    G.getPropFromObject(driverGuid, driverProfiles),
    { fuelCards, openedFuelCards: true },
  );

  return P.$set(`driverProfiles.${driverGuid}`, newProfile, state);
};

const toggleDriverFuelCardSuccess = (state: Object, { guid, driverGuid }: Object) => {
  const index = R.findIndex(
    R.propEq(guid, GC.FIELD_GUID),
    R.pathOr([], ['driverProfiles', driverGuid, 'fuelCards'], state),
  );

  return P.$toggle(`driverProfiles.${driverGuid}.fuelCards.${index}.enabled`, state);
};

const getDriverAvailabilityStatusSuccess = (state: Object, { status }: Object) => (
  P.$set('driverAvailabilityStatus', status, state)
);

// pending activities
const createPendingActivitySuccess = (state: Object, data: Object) => {
  const { driverPendingActivities } = state;

  const newPeriods = R.append(data, driverPendingActivities);

  return P.$set('driverPendingActivities', newPeriods, state);
};

const updatePendingActivitySuccess = (state: Object, data: Object) => {
  const { driverPendingActivities } = state;
  const { guid } = data;

  const newPeriods = R.map(
    (item: Object) => {
      const itemGuid = G.getGuidFromObject(item);

      if (R.equals(guid, itemGuid)) return data;

      return item;
    },
    driverPendingActivities,
  );

  return P.$set('driverPendingActivities', newPeriods, state);
};

const removePendingActivitySuccess = (state: Object, data: Object) => {
  const { driverPendingActivities } = state;
  const { guid } = data;

  const newPeriods = R.reject(R.propEq(guid, GC.FIELD_GUID), driverPendingActivities);

  return P.$set('driverPendingActivities', newPeriods, state);
};

// unavailable periods
const createUnavailablePeriodSuccess = (state: Object, data: Object) => {
  const { driverUnavailablePeriods } = state;

  const newPeriods = R.append(data, driverUnavailablePeriods);

  return P.$set('driverUnavailablePeriods', newPeriods, state);
};

const updateUnavailablePeriodSuccess = (state: Object, data: Object) => {
  const { driverUnavailablePeriods } = state;
  const { guid } = data;

  const newPeriods = R.map(
    (item: Object) => {
      const itemGuid = G.getGuidFromObject(item);

      if (R.equals(guid, itemGuid)) return data;

      return item;
    },
    driverUnavailablePeriods,
  );

  return P.$set('driverUnavailablePeriods', newPeriods, state);
};

const removeUnavailablePeriodSuccess = (state: Object, data: Object) => {
  const { driverUnavailablePeriods } = state;
  const { guid } = data;

  const newPeriods = R.reject(R.propEq(guid, GC.FIELD_GUID), driverUnavailablePeriods);

  return P.$set('driverUnavailablePeriods', newPeriods, state);
};

export default createReducer({
  [A.setInitialState]: setInitialState,
  [A.openDriverProfile]: openDriverProfile,
  [A.closeDriverProfile]: closeDriverProfile,
  [A.toggleDriverFuelCards]: toggleDriverFuelCards,
  [A.toggleDriverCertificates]: toggleDriverCertificates,
  [A.getDriverFuelCardsSuccess]: getDriverFuelCardsSuccess,
  [A.getDriverProfileInfoSuccess]: getDriverProfileInfoSuccess,
  [A.toggleDriverFuelCardSuccess]: toggleDriverFuelCardSuccess,
  [A.getDriverCertificatesSuccess]: getDriverCertificatesSuccess,
  [driverCardsCreatePendingActivitySuccess]: createPendingActivitySuccess,
  [driverCardsUpdatePendingActivitySuccess]: updatePendingActivitySuccess,
  [driverCardsRemovePendingActivitySuccess]: removePendingActivitySuccess,
  [A.getDriverAvailabilityStatusSuccess]: getDriverAvailabilityStatusSuccess,
  [driverCardsCreateUnavailablePeriodSuccess]: createUnavailablePeriodSuccess,
  [driverCardsUpdateUnavailablePeriodSuccess]: updateUnavailablePeriodSuccess,
  [driverCardsRemoveUnavailablePeriodSuccess]: removeUnavailablePeriodSuccess,
}, initialState);
