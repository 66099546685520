import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState } from 'react-recompose';
// helpers/constants
import * as G from '../../../../../helpers';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex } from '../../../../../ui';
// feature configs
import { Button } from '../../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('expanded', 'setExpanded', true),
  pure,
);

const Header = ({ expanded, setExpanded }: Object) => {
  const darkBlueColor = G.getTheme('colors.dark.blue');
  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Flex
      mb={15}
      p='5px'
      fontWeight='bold'
      color={darkBlueColor}
      justifyContent='center'
      alignItems='flex-start'
      bg={G.getTheme('colors.whiteGrey')}
    >
      <Box mr={15} ml='auto'>{G.getWindowLocale('titles:interpolation-variables', 'Interpolation Variables')}</Box>
      <Box px='6px' ml='auto' cursor='pointer' onClick={() => setExpanded(R.not(expanded))}>
        {I[toggleIconName](darkBlueColor)}
      </Box>
    </Flex>
  );
};

export const copyToClipboard = async (variable: string) => {
  try {
    const clipboard = R.path(['navigator', 'clipboard'], window);

    await clipboard.writeText(variable);

    G.showToastrMessageSimple(
      'info',
      `${G.getWindowLocale('messages:copied', 'Copied:')} ${variable}`,
    );
  } catch (error) {
    G.showToastrMessageSimple(
      'error',
      `${G.getWindowLocale('messages:failed-to-copy', 'Failed to copy, please try writing manually:')} ${variable}`,
    );
  }
};

// TODO: check locale
export const InterpolationVariablesSection = enhance(({ expanded, setExpanded, interpolationVariables }: Object) => {
  const lightGreyColor = G.getTheme('colors.light.grey');

  return (
    <Box>
      <Header expanded={expanded} setExpanded={setExpanded} />
      {
        R.and(G.isTrue(expanded), G.isNotNilAndNotEmpty(interpolationVariables)) &&
        <Box color={lightGreyColor}>
          <Box p='0 15px'>
            Mail Subject, Body and Signature can contain one or more interpolation variables,
            which are replaced by the corresponding values in your email template.
          </Box>
          <Box width='100%' p='15px 15px 0'>
            There are available interpolation variables (click to copy for use):
            <Box mt={10}>
              {
                interpolationVariables.map((variable: string, i: number) => (
                  <Button key={i} type='button' onClick={() => copyToClipboard(variable)}>
                    {variable}
                  </Button>
                ))
              }
            </Box>
          </Box>
        </Box>
      }
    </Box>
  );
});
