import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers
import * as G from '../../helpers';
// feature dashboards
import * as A from './actions';
import { getFormattedDateRangeFilters } from './helpers';
import { DAY_14, DASHBOARD_TYPE_FLEET_GENERAL } from './constants';
//////////////////////////////////////////////////

const initialState = {
  dashboardType: DASHBOARD_TYPE_FLEET_GENERAL,
  filters: {
    days: DAY_14,
    enterprise: null,
    ...getFormattedDateRangeFilters(DAY_14),
  },
  titleSortValues: {},
  tableTitleFilters: {},
  dashboard: null,
  normalizedCurrency: {},
};

const setValueToStore = (state: Object, { path, value }: Object) => (
  P.$set(path, value, state)
);

const setDashboardTypeToStore = (state: Object, data: string) => (
  P.$set('dashboardType', data, state)
);

const setEnterpriseFilterToStore = (state: Object, data: string) => (
  P.$set('filters.enterprise', data, state)
);

const setDateFiltersToStore = (state: Object, { days, dateTo, dateFrom }: Object) => (
  P.$all(
    P.$set('filters.days', days),
    P.$set('filters.dateTo', dateTo),
    P.$set('filters.dateFrom', dateFrom),
    state,
  )
);

const setTableTitleSort = (state: Object, { sort, guid }: Object) => {
  const titleSortValues = P.$get('titleSortValues', state);

  const values = R.propOr({}, guid, titleSortValues);

  const sortValues = G.getTableTitleSortValues(values, sort);

  return P.$set(
    'titleSortValues',
    R.assoc(guid, sortValues, titleSortValues),
    state,
  );
};

const setTableTitleFilter = (state: Object, { filters, guid }: Array) => {
  const tableTitleFilters = P.$get('tableTitleFilters', state);

  const filtersByGuid = R.propOr({}, guid, tableTitleFilters);
  const filterValues = G.getTableTitleFilterValues(filtersByGuid, filters);

  return P.$set(
    'tableTitleFilters',
    R.assoc(guid, filterValues, tableTitleFilters),
    state,
  );
};

const getDashboardSuccess = (state: Object, value: string) => (
  P.$set('dashboard', value, state)
);

const getChartByFiltersSuccess = (state: Object, { groups, chartType }: Object) => (
  P.$set(`dashboard.${chartType}.groups`, groups, state)
);

const getNormalizedCurrencySuccess = (state: Object, { data, enterpriseGuid }: Object) => (
  P.$set(`normalizedCurrency.${enterpriseGuid}`, data, state)
);

export default createReducer({
  [A.setValueToStore]: setValueToStore,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getDashboardSuccess]: getDashboardSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setDateFiltersToStore]: setDateFiltersToStore,
  [A.setDashboardTypeToStore]: setDashboardTypeToStore,
  [A.getChartByFiltersSuccess]: getChartByFiltersSuccess,
  [A.setEnterpriseFilterToStore]: setEnterpriseFilterToStore,
  [A.getNormalizedCurrencySuccess]: getNormalizedCurrencySuccess,
}, initialState);
