import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature dispatch-planner-events
import { defaultValues, getFieldSettings, validationSchema } from '../settings/apply-route-template-settings';
//////////////////////////////////////////////////

export const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validationSchema),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const { tel, submitAction } = props;

      submitAction(R.assoc(GC.FIELD_TEL, R.or(tel, {}), values));
    },
  }),
  pure,
);

export const ApplyRouteTemplateForm = enhance((props: Object) => {
  const { handleSubmit, routeTemplates, openedFromTemplatePage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        routeTemplates={routeTemplates}
        fields={getFieldSettings(openedFromTemplatePage)}
        fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
});
