import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature config
import { fuelCardFields, defaultFuelCardFields, getFuelCardValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultFuelCardFields,
      props.initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { type } = values;

      let data = values;

      if (R.equals(type, GC.FUEL_CARDS_TYPE_EFS)) {
        data = R.dissoc(GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_START_CODE, data);
      } else if (R.equals(type, GC.FUEL_CARDS_TYPE_QUIKQ)) {
        data = R.dissoc(GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_CARRIER_ID, data);
      }

      props.submitAction(G.mapObjectEmptyStringFieldsToNull(data));
    },
    validationSchema: ({ isEditMode }: Object) =>
      Yup.lazy(({ type }: Object) => Yup.object().shape(getFuelCardValidationSchema(type, isEditMode))),
  }),
  pure,
);

const FuelCardFormComponent = (props: Object) => (
  <Box mx='auto' width='270px'>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={fuelCardFields}
        handlers={{ handleDisableFuelCardsType: () => props.disableFuelCardType }} />
      <FormFooter boxStyles={{ py: '10px' }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(FuelCardFormComponent);
