import * as R from 'ramda';
// components
import {
  rateTypeOptions,
  chargeTypeOptions,
  rateUnitOptionsGroup,
} from '../../../components/charge/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const telRateChargeTypeOptions = [
  {
    value: GC.RATE_CHARGE_TYPE_CARRIER,
    label: G.getWindowLocale('titles:carrier', 'Carrier'),
  },
  {
    value: GC.RATE_CHARGE_TYPE_FLEET_VENDOR,
    label: G.getWindowLocale('titles:vendor', 'Vendor'),
  },
  {
    value: GC.RATE_CHARGE_TYPE_PRIMARY_DRIVER,
    label: G.getWindowLocale('titles:primary-driver', 'Primary Driver'),
  },
  {
    value: GC.RATE_CHARGE_TYPE_SECONDARY_DRIVER,
    label: G.getWindowLocale('titles:team-driver', 'Team Driver'),
  },
];

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  checkBoxCellWidth: 0,
  expandableItems: false,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

export const settings = (type: string) => ({
  [GC.FIELD_CURRENCY]: {
    width: 200,
    searchable: true,
    name: 'titles:currency',
    filterType: 'selectMultiple',
    options: R.tail(GC.CURRENCY_OPTIONS),
    placeholder: { text: 'Choose', key: 'titles:choose'},
  },
  [GC.FIELD_ADVANCE_PAYMENT]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    filterType: 'boolean',
    name: 'titles:advance-payment',
  },
  chargeType: {
    width: 200,
    searchable: true,
    filterType: 'selectMultiple',
    name: 'titles:charge-rate-type',
    options: telRateChargeTypeOptions,
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject('chargeType', data), GC.FIELD_VALUE)),
    )(telRateChargeTypeOptions),
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    searchable: true,
    name: 'titles:comments',
  },
  [GC.FIELD_DISPLAYED_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:charge-description',
  },
  [GC.FIELD_CHARGE_HOLD_QUANTITY]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: 'titles:hold',
    filterType: 'boolean',
  },
  [GC.FIELD_NON_TAXABLE]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    filterType: 'boolean',
    name: 'titles:non-taxable',
  },
  [GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: 'titles:price-sheet-currency-total',
  },
  [GC.FIELD_QUANTITY]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: 'titles:quantity',
  },
  [GC.FIELD_RATE]: {
    width: 200,
    searchable: true,
    name: 'titles:rate',
    filterType: 'number',
  },
  [GC.FIELD_RATE_TYPE]: {
    width: 200,
    type: 'string',
    searchable: true,
    name: 'titles:rate-type',
    filterType: 'selectMultiple',
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
    options: R.compose(
      R.uniqBy(R.prop(GC.FIELD_VALUE)),
      R.flatten,
      R.values,
      R.map(R.prop(type)),
    )(rateTypeOptions),
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_RATE_TYPE, data), GC.FIELD_VALUE)),
      R.flatten,
      R.values,
      R.map(R.prop(type)),
    )(rateTypeOptions),
  },
  [GC.FIELD_RATE_UNIT]: {
    width: 200,
    type: 'string',
    searchable: true,
    name: 'titles:rate-unit',
    filterType: 'selectMultiple',
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
    options: R.compose(
      R.uniqBy(R.prop(GC.FIELD_VALUE)),
      R.filter(({ value }: Object) => G.isNotNilAndNotEmpty(value)),
      R.flatten,
      R.values,
    )(rateUnitOptionsGroup),
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_RATE_UNIT, data), GC.FIELD_VALUE)),
      R.flatten,
      R.values,
    )(rateUnitOptionsGroup),
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    type: 'string',
    searchable: true,
    name: 'titles:charge-type',
    options: chargeTypeOptions,
    filterType: 'selectMultiple',
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_TYPE, data), GC.FIELD_VALUE)),
    )(chargeTypeOptions),
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    filterType: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    filterType: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
  [`${GC.FIELD_TEL_RATE}.${GC.FIELD_TEL}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`]: {
    width: 200,
    searchable: true,
    useExpandedContainer: true,
    type: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
    name: ['titles:tel', 'titles:primary-ref-value'],
    guidPropName: `${GC.FIELD_TEL_RATE}.${GC.FIELD_TEL}.${GC.FIELD_GUID}`,
  },
  [`${GC.FIELD_CLO_RATE}.${GC.FIELD_CLO}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`]: {
    width: 200,
    searchable: true,
    useExpandedContainer: true,
    type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    name: ['titles:clo', 'titles:primary-ref-value'],
    guidPropName: `${GC.FIELD_CLO_RATE}.${GC.FIELD_CLO}.${GC.FIELD_GUID}`,
  },
  [`${GC.FIELD_TEL_RATE}.${GC.FIELD_TOTAL}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:tel', 'titles:total'],
  },
  [`${GC.FIELD_CLO_RATE}.${GC.FIELD_TOTAL}`]: {
    width: 200,
    searchable: true,
    filterType: 'number',
    name: ['titles:clo', 'titles:total'],
  },
});

const commonChargeFields = [
  GC.FIELD_CURRENCY,
  GC.FIELD_TYPE,
  GC.FIELD_DISPLAYED_NAME,
  GC.FIELD_RATE,
  GC.FIELD_RATE_TYPE,
  GC.FIELD_RATE_UNIT,
  GC.FIELD_QUANTITY,
  GC.FIELD_TOTAL,
  GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL,
  GC.FIELD_CHARGE_HOLD_QUANTITY,
  GC.FIELD_CHARGE_INTERNAL_EXPENSE,
];

const commonFields = [
  GC.FIELD_CREATED_DATE,
  GC.FIELD_CREATED_BY,
  GC.FIELD_LAST_MODIFIED_DATE,
  GC.FIELD_LAST_MODIFIED_BY,
];

const getFilterParams = (type: string, fieldsToPick: Array) => R.compose(
  R.values,
  R.mapObjIndexed(({ name, options, placeholder, filterType = 'string' }: Object, value: string) => ({
    value,
    options,
    placeholder,
    [GC.FIELD_TYPE]: filterType,
    [GC.FIELD_NAME]: G.ifElse(
      G.isArray(name),
      `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
      G.getWindowLocale(name),
    ),
  })),
  R.pick(fieldsToPick),
)(settings(type));

export const cloChargeFilterParams = getFilterParams(
  GC.FIELD_CLO,
  [
    `${GC.FIELD_CLO_RATE}.${GC.FIELD_TOTAL}`,
    `${GC.FIELD_CLO_RATE}.${GC.FIELD_CLO}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`,
    ...commonChargeFields,
    ...commonFields,
  ],
);

export const telChargeFilterParams = getFilterParams(
  GC.FIELD_TEL,
  [
    `${GC.FIELD_TEL_RATE}.${GC.FIELD_TOTAL}`,
    `${GC.FIELD_TEL_RATE}.${GC.FIELD_TEL}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`,
    ...commonChargeFields,
    'chargeType',
    GC.FIELD_ADVANCE_PAYMENT,
    GC.FIELD_CHARGE_INTERNAL_EXPENSE,
    ...commonFields,
  ],
);
