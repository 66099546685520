import * as R from 'ramda';
import React from 'react';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../../components/table';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncGetTransportationModeGrouping } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, IconWrapper, ActionButton } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature dispatch details new
import * as C from '../../constants';
import RowActions from '../components/row-actions';
import PostedShipments from '../components/posted-shipments';
import { vehicleTableSettings } from '../../components/damage-table';
import { getLoadTabs, loadListMap, tableSettings } from '../../settings/table-settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const ExpandedCustomerTable = (props: Object) => {
  const {
    data,
    report,
    onOptionClick,
    columnSettings,
    handleClickEditIcon,
    rowActionsPermissions,
  } = props;

  const allowSelectItems = R.all(({ selected }: Object) => G.isNotNil(selected), data);
  const tableSettingsWithSelectItemProp = R.assoc('allowSelectItems', allowSelectItems, tableSettings);
  const tableData = {
    report,
    onOptionClick,
    columnSettings,
    itemList: data,
    withoutBorder: true,
    withoutTitleRow: true,
    tableSettings: tableSettingsWithSelectItemProp,
    mainWidth: G.ifElse(G.isNotNilAndNotEmpty(data), 'max-content', '100%'),
  };
  const renderRowActions = (rowData: Object) => G.ifElse(
    R.not(rowData.withoutRowActions),
    <AuthWrapper has={rowActionsPermissions}>
      <IconWrapper
        px={12}
        cursor='pointer'
        onClick={(event: Object) => handleClickEditIcon(event, rowData)}
      >
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>,
    null,
  );

  return (
    <Table
      {...tableData}
      tableWrapperOverflow='unset'
      renderRightStickedComponent={renderRowActions}
    />
  );
};

const actionButtonStyles = {
  px: '8px',
  height: 18,
  fontSize: 11,
  type: 'button',
  minWidth: 'unset',
  borderRadius: '5px',
  textColor: whiteColor,
  bgColor: 'transparent',
  border: `1px solid ${whiteColor}`,
};

const AdditionalCustomerRow = ({ data, handleAddTableEntity, addTableEntityTxtLocale }: Object) => (
  <Flex
    pl={10}
    py='8px'
    width='100%'
    alignItems='center'
    bg={G.getTheme('colors.dark.blue')}
  >
    <Flex left='10px' position='sticky'>
      <AuthWrapper has={[PC.CLO_RATE_WRITE]}>
        <ActionButton
          {...actionButtonStyles}
          position='relative'
          onClick={() => handleAddTableEntity(data)}
        >
          {addTableEntityTxtLocale}
        </ActionButton>
      </AuthWrapper>
      <Flex fontSize={11} pl={10} color={whiteColor}>
        {R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_REFERENCE, GC.FIELD_NAME], data)}:
        <Box
          ml='5px'
          fontSize={12}
          cursor='pointer'
          fontWeight='bold'
          color={whiteColor}
          onClick={() => G.goToRoute(routesMap.dispatchDetailsOrder(R.path([GC.FIELD_GUID], data)))}
        >
          {R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_REFERENCE, GC.FIELD_VALUE], data)}
        </Box>
      </Flex>
    </Flex>
  </Flex>
);

const getCustomerTableData = R.curry((
  load: Object,
  report: Object,
  itemList: Object,
  columnSettings: Object,
  handleClickEditIcon: Function,
  handleAddTableEntity: Function,
  rowActionsPermissions: Object,
  handleSelectTableEntity: Function,
  addTableEntityTxtLocale: string,
) => {
  const expandedDetailsComponent = ({ data }: Object) => G.ifElse(
    G.isNotNilAndNotEmpty(data),
    <ExpandedCustomerTable
      data={data}
      report={report}
      columnSettings={columnSettings}
      onOptionClick={handleSelectTableEntity}
      handleClickEditIcon={handleClickEditIcon}
      rowActionsPermissions={rowActionsPermissions}
    />,
      null,
  );
  const customerTableSettings = {
    ...tableSettings,
    expandableItems: true,
    expandedDetailsComponent,
    withOnlyExpandedDetails: true,
  };
  const list = R.map(
    (item: Object) => ({
      ...item,
      expanded: true,
      withoutRowActions: true,
      details: R.filter(
        ({ cloGuid }: Object) => R.pathEq(cloGuid, [GC.FIELD_GUID], item),
        R.or(itemList, []),
      ),
    }),
    R.pathOr([], [GC.FIELD_CLOS], load),
  );
  const tableData = {
    report,
    columnSettings,
    itemList: list,
    withoutBorder: true,
    tableWrapperOverflow: 'none',
    tableSettings: customerTableSettings,
    AdditionalRow: (receivedProps: Object) => (
      <AdditionalCustomerRow
        {...receivedProps}
        handleAddTableEntity={handleAddTableEntity}
        addTableEntityTxtLocale={addTableEntityTxtLocale}
      />
    ),
  };

  return tableData;
});

const ExpandedLinkedOrderRefsTable = (props: Object) => {
  const {
    data,
    report,
    columnSettings,
  } = props;

  const tableData = {
    report,
    tableSettings,
    columnSettings,
    itemList: data,
    withoutBorder: true,
    withoutTitleRow: true,
  };

  return (
    <Table
      {...tableData}
      tableWrapperOverflow='unset'
    />
  );
};

const AdditionalLinkedOrderRefsRow = ({ data }: Object) => (
  <Flex
    pl={10}
    py='8px'
    width='100%'
    alignItems='center'
    bg={G.getTheme('colors.dark.blue')}
  >
    <Flex left='10px' position='sticky'>
      <Box
        ml='5px'
        pl={10}
        fontSize={12}
        cursor='pointer'
        fontWeight='bold'
        color={whiteColor}
        onClick={() => G.goToRoute(routesMap.dispatchDetailsOrder(R.path([GC.FIELD_GUID], data)))}
      >
        {R.pathOr('', [GC.FIELD_PRIMARY_REFERENCE_VALUE], data)}
      </Box>
    </Flex>
  </Flex>
);

const getLinkedOrderRefsTableData = R.curry((props: Object) => {
  const {
    report,
    itemList,
    columnSettings,
    linkedOrderList,
  } = props;

  const expandedDetailsComponent = ({ data }: Object) => G.ifElse(
    G.isNotNilAndNotEmpty(data),
    <ExpandedLinkedOrderRefsTable
      data={data}
      report={report}
      columnSettings={columnSettings}
    />,
    null,
  );
  const linkedOrderRefsTableSettings = {
    ...tableSettings,
    expandableItems: true,
    expandedDetailsComponent,
    withOnlyExpandedDetails: true,
  };
  const list = R.map(
    (item: Object) => ({
      ...item,
      expanded: true,
      withoutRowActions: true,
      details: R.filter(
        ({ loadGuid }: Object) => R.pathEq(loadGuid, [GC.FIELD_GUID], item),
        R.or(itemList, []),
      ),
    }),
    R.or(linkedOrderList, []),
  );
  const tableData = {
    report,
    columnSettings,
    itemList: list,
    withoutBorder: true,
    tableWrapperOverflow: 'none',
    tableSettings: linkedOrderRefsTableSettings,
    AdditionalRow: (receivedProps: Object) => (
      <AdditionalLinkedOrderRefsRow {...receivedProps} />
    ),
  };

  return tableData;
});

export const withTableGroupProps = compose(
  withProps(({ load, activeListName }: Object) => ({
    branchGuid: G.getBranchGuidFromObject(load),
    shouldNotGetTransportationModeList: G.notEquals(activeListName, C.ACTIVE_LIST_CUSTOMER_RATES),
  })),
  withAsyncGetTransportationModeGrouping,
  withHandlers({
    handleChangeActiveList: (props: Object) => (listName: string, activeItem: boolean) => {
      const { tableNumber, changeActiveList, toggleExpandedListsSuccess } = props;

      if (G.isFalse(activeItem)) {
        changeActiveList({ listName, tableNumber });
        toggleExpandedListsSuccess({ tableNumber, expanded: false });
      }
    },
    handleToggleExpandedList: ({ tableNumber, toggleExpandedListsSuccess }: Object) => () =>
      toggleExpandedListsSuccess({ tableNumber }),
    handleSelectTableEntity: (props: Object) => (guid: string, entity: Object) => {
      const {
        itemList,
        activeListName,
        selectLoadCustomerRateRequest,
        selectDriverCarrierRateRequest,
        handleCheckModeGroupingAndShowConfirmMessage,
      } = props;

      const isAlreadySelect = R.compose(
        R.prop('selected'),
        R.find(R.pathEq(guid, [GC.FIELD_GUID])),
      )(itemList);

      if (isAlreadySelect) return;

      if (R.equals(activeListName, C.ACTIVE_LIST_CARRIER_FLEET_RATES)) {
        const isCarrierRate = G.isRateTypeCarrierRate(G.getPropFromObject(GC.FIELD_TYPE, entity));

        if (G.getHasNotEditRatePermissionsByRateType(isCarrierRate)) return;

        return selectDriverCarrierRateRequest(entity);
      }

      if (R.equals(activeListName, C.ACTIVE_LIST_CUSTOMER_RATES)) {
        const callback = (loadData: Object) => selectLoadCustomerRateRequest({ entity, loadData });

        return handleCheckModeGroupingAndShowConfirmMessage({
          callback,
          [GC.FIELD_MODE]: R.path([GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], entity),
        });
      }
    },
    handleToggle: (props: Object) => (row: Object) => {
      const { tableNumber, activeListName, toggleLoadVehicleItemRequest } = props;

      if (R.equals(activeListName, C.ACTIVE_LIST_VEHICLE_ITEMS)) {
        toggleLoadVehicleItemRequest(R.assoc('tableNumber', tableNumber, row));
      }
    },
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <RowActions
            entity={entity}
            load={props.load}
            events={props.events}
            itemList={props.itemList}
            openModal={props.openModal}
            closeModal={props.closeModal}
            loadConfigs={props.loadConfigs}
            loadDocuments={props.loadDocuments}
            activeListName={props.activeListName}
            loadTotalWeight={props.loadTotalWeight}
            closeFixedPopup={props.closeFixedPopup}
            loadTotalDistance={props.loadTotalDistance}
            transportationModeGroupingList={props.transportationModeGroupingList}
            getServiceVendorInvoiceListRequest={props.getServiceVendorInvoiceListRequest}
            sendInvoiceToBCByInvoiceTypeRequest={props.sendInvoiceToBCByInvoiceTypeRequest}
            createQBIIFImportByInvoiceTypeRequest={props.createQBIIFImportByInvoiceTypeRequest}
            getTransportationModeGroupingListSuccess={props.getTransportationModeGroupingListSuccess}
          />
        ),
      })
    ),
  }),
  withProps((props: Object) => {
    const {
      load,
      itemList,
      hiddenTabs,
      pagination,
      totalCount,
      loadConfigs,
      handleToggle,
      activeListName,
      postedShipment,
      linkedOrderList,
      handleClickEditIcon,
      handleAddTableEntity,
      handleLoadMoreEntities,
      handleSelectTableEntity,
      selectedCarrierDriverRate,
    } = props;

    const generateReport = (settings: Array) => R.compose(
      G.mapIndexed((item: string, index: number) => ({
        sequence: index,
        [GC.FIELD_NAME]: item,
        freezed: R.pathOr(false, [item, 'freezed'], settings),
      })),
      R.keys,
    )(settings);
    let columnSettings = R.path([activeListName, 'columnSettings'], loadListMap);

    if (G.isFunction(columnSettings)) {
      if (R.includes(activeListName, [C.ACTIVE_LIST_ITEMS, C.ACTIVE_LIST_VEHICLE_ITEMS])) {
        const keysWithEmptyValues = G.getKeysWithEmptyValues(itemList);

        columnSettings = columnSettings(keysWithEmptyValues);
      } else {
        columnSettings = columnSettings();
      }
    }

    const rowActionsPermissions = R.path([activeListName, 'rowActionsPermissions'], loadListMap);
    const notHasRowActionsPermissions = R.pathOr(null, [activeListName, 'notHasRowActionsPermissions'], loadListMap);
    const report = { fields: generateReport(columnSettings) };
    const allowSelectItems = R.all(({ selected }: Object) => G.isNotNil(selected), itemList);

    let tableSettingsToUse = tableSettings;

    if (R.equals(activeListName, C.ACTIVE_LIST_VEHICLE_ITEMS)) {
      tableSettingsToUse = R.assoc(
        'expandableItems',
        G.hasAmousCurrentUserPermissions([PC.ITEM_DAMAGE_READ, PC.ITEM_DAMAGE_WRITE]),
        vehicleTableSettings,
      );
    } else if (R.equals(activeListName, C.ACTIVE_LIST_LINKED_CUSTOMER_INVOICES)) {
      tableSettingsToUse = R.assoc('allowEditBtn', false, tableSettings);
    }

    const tableSettingsWithSelectItemProp = R.assoc('allowSelectItems', allowSelectItems, tableSettingsToUse);

    let tableData = {
      report,
      pagination,
      totalCount,
      columnSettings,
      withoutBorder: true,
      toggle: handleToggle,
      rowActionsPermissions,
      handleLoadMoreEntities,
      notHasRowActionsPermissions,
      itemList: R.or(itemList, []),
      tableWrapperOverflow: 'none',
      onOptionClick: handleSelectTableEntity,
      tableSettings: tableSettingsWithSelectItemProp,
      callbackData: {
        loadConfigs,
        [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL,
      },
    };

    if (R.includes(
      activeListName,
      [C.ACTIVE_LIST_CUSTOMER_RATES, C.ACTIVE_LIST_CUSTOMER_INVOICES],
    )) {
      const addTableEntityTxtLocaleMap = {
        [C.ACTIVE_LIST_CUSTOMER_RATES]: G.getWindowLocale('titles:add-customer-rate', 'Add Customer Rate'),
        [C.ACTIVE_LIST_CUSTOMER_INVOICES]: G.getWindowLocale('titles:add-customer-invoice', 'Add Customer Invoice'),
      };
      tableData = getCustomerTableData(
        load,
        report,
        itemList,
        columnSettings,
        handleClickEditIcon,
        handleAddTableEntity,
        rowActionsPermissions,
        handleSelectTableEntity,
        addTableEntityTxtLocaleMap[activeListName],
      );
    }

    if (R.equals(activeListName, C.ACTIVE_LIST_LINKED_ORDER_REF_LIST)) {
      tableData = getLinkedOrderRefsTableData({
        report,
        itemList,
        columnSettings,
        linkedOrderList,
      });
    }

    let tabs = getLoadTabs(G.isNotNilAndNotEmpty(R.path(['linkedCloGuids'], load)));

    if (R.equals(activeListName, C.ACTIVE_LIST_LOAD_BOARDS)) {
      tableData = R.assoc(
        'HeadComponent',
        () => <PostedShipments />,
        tableData,
      );

      if (G.isNilOrEmpty(postedShipment)) {
        const index = R.findIndex(R.propEq(C.ACTIVE_LIST_LOAD_BOARDS, GC.FIELD_VALUE), tabs);

        tabs[index] = R.assoc('withAddition', true, tabs[index]);
      }
    }

    if (G.isTrue(G.showAdvancePayment())) {
      const advancePaymentTab = {
        value: C.ACTIVE_LIST_ADVANCE_PAYMENT,
        label: ['titles:advance-payment', 'Advance Payment'],
        withAddition: R.pathEq(GC.RATE_TYPE_FLEET_RATE, [GC.FIELD_TYPE], selectedCarrierDriverRate),
      };
      tabs = R.insert(5, advancePaymentTab, tabs);
    }

    if (R.or(
      R.pathEq(GC.RATE_STATUS_ACCEPTED, [GC.FIELD_STATUS], selectedCarrierDriverRate),
      R.and(
        G.isNotNilAndNotEmpty(selectedCarrierDriverRate),
        R.pathEq(GC.LOAD_STATUS_CANCELED, [GC.FIELD_STATUS], load),
      ),
    )) {
      const invoiceList = G.ifElse(
        R.pathEq(GC.RATE_TYPE_FLEET_RATE, [GC.FIELD_TYPE], selectedCarrierDriverRate),
        C.ACTIVE_LIST_DRIVER_INVOICES,
        C.ACTIVE_LIST_CARRIER_INVOICES,
      );
      const index = R.findIndex(R.propEq(invoiceList, GC.FIELD_VALUE), tabs);

      tabs[index] = R.assoc('withAddition', true, tabs[index]);
    }

    if (R.equals(activeListName, C.ACTIVE_LIST_MACRO_POINT)) {
      const index = R.findIndex(R.propEq(C.ACTIVE_LIST_MACRO_POINT, GC.FIELD_VALUE), tabs);
      tabs = R.assocPath([index, 'withAddition'], G.isNilOrEmpty(itemList), tabs);
    }

    tabs = R.filter(({ value }: Object) => G.isFalse(R.pathOr(false, [value], hiddenTabs)), tabs);

    return { tabs, tableData };
  }),
  pure,
);
