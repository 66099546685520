import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_INCLUDE_FUEL_CHARGE]: {
    type: 'boolean',
    name: 'titles:include-fuel-charge',
  },
  [GC.FIELD_INCLUDE_TOLL_CHARGE]: {
    type: 'boolean',
    name: 'titles:include-toll-charge',
  },
  [GC.FIELD_INCLUDE_ADVANCE_PAYMENT]: {
    type: 'boolean',
    name: 'titles:include-advance-payment',
  },
};

const inputWrapperStyles = {
  mb: 25,
  width: 'max-content',
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => false, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({ name, options, type = 'text' }: Object, fieldName: string) => ({
    options,
    fieldName,
    type: 'toggle',
    inputWrapperStyles,
    label: R.of(Array, name),
  })),
)(settings);

const columnSettings = R.map(R.pick(['name', 'type', 'width']), settings);

const validationSchema = Yup.object().shape(R.map(() => G.yupStringNotRequired, settings));

export const deductionSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  singleItem: true,
  validationSchema,
  actionsPicker: ['edit'],
  useGetListRequest: true,
  groupName: 'deductionSettings',
  itemTitleArr: ['titles:deduction-settings', 'Deduction Settings'],
  formGroupTitleArr: ['titles:deduction-settings', 'Deduction Settings'],
  additionalTableSettings: {
    checkBoxCellJustifyContent: 'center',
  },
  endpoints: {
    list: 'deductionSettings',
    createOrUpdate: 'deductionSettings',
  },
  fieldsWrapperStyles: {
    width: 600,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};
