import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  loadCustomStatusFieldSettings,
  defaultLoadCustomStatusFields,
  loadCustomStatusValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: loadCustomStatusValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultLoadCustomStatusFields,
      initialValues,
    ),
  }),
  pure,
);

const LoadCustomStatusForm = (props: Object) => (
  <Box mx='auto' width={270}>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={loadCustomStatusFieldSettings}
      />
      <FormFooter boxStyles={{ py: '10px' }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(LoadCustomStatusForm);
