import * as R from 'ramda';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// hocs
import { withRerenderComponent } from '../../../hocs';
// ui
import { Flex } from '../../../ui';
// feature dispatch-planner
import Stop from './stop';
import Dimmer from './dimmer';
import * as H from '../helpers';
//////////////////////////////////////////////////

const renderStops = (props: Object) => {
  const { tel, noDimmer, showComponent } = props;

  if (R.and(showComponent, G.isNotNilAndNotEmpty(R.path(['tel', GC.FIELD_LOAD_STOPS], props)))) {
    const telGuid = G.getGuidFromObject(tel);
    const events = H.sortTelEvents(props.tel[GC.FIELD_LOAD_STOPS]);
    const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);
    const manageTrailersFrom = G.getAmousConfigByNameFromWindow(GC.TRAILER_MANAGE_TRAILERS_FROM);
    const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

    return (
      events.map((stop: Object, index: number) => {
        let cloName = null;
        const isCloStop = G.isLoadTypeClo(stop);

        if (isCloStop) {
          cloName = H.getLoadName(R.path([GC.SYSTEM_LIST_CLOS, stop[GC.FIELD_CLO_GUID]], props));
        }

        return (
          <Draggable
            index={index}
            key={stop.guid}
            draggableId={`${telGuid}_${G.getGuidFromObject(stop)}`}
            isDragDisabled={R.equals(stop.stopType, GC.STOP_TYPE_TERMINAL)}
          >
            {(provided: Object) => (
              <div>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Stop
                    {...props}
                    stop={stop}
                    cloName={cloName}
                    useContainers={useContainers}
                    useStopTrailers={useStopTrailers}
                    lastStopInTel={R.equals(R.length(events), R.inc(index))}
                    error={R.pathOr(null, ['error', 'eventsErrors', R.prop(GC.FIELD_GUID, stop)], props)}
                  />
                </div>
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        );
      })
    );
  }

  return R.not(noDimmer) && <Dimmer count={6} />;
};

export const LoadInner = (props: Object) => (
  <Flex
    pt={20}
    pb='8px'
    width='100%'
    overflow='auto'
    minHeight={200}
    alignItems='normal'
  >
    {renderStops(props)}
  </Flex>
);

export default withRerenderComponent(['tel'])(LoadInner);
