import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import EditReport from '../../components/edit-report';
import { TitlePanel } from '../../components/title-panel';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import DispatchBoardTileTabs from '../dispatch-board-new/components/title-tabs';
import { makeSelectAvailableReferenceTypesByScope } from '../reference/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// report-common
import { reportEnhancer } from '../../report-common';
// icons
import * as I from '../../svgs';
// ui
import { ListWrapper, Box, ZOrderWrapper } from '../../ui';
// feature EDI Integration
import { RowActions } from './components/row-actions';
import { FILTER_PARAMS } from './settings/filter-params';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import {
  setReports,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  deleteListItemRequest,
  resetListAndPagination,
  changeDefaultReportRequest,
} from './actions';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

const renderElementActions = (data: Object, handleClickEditIcon: Function) => (
  <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
    {I.threeDots()}
  </Box>
);

const withActions = compose(
  withHandlers({
    handleDeleteListItem: (props: Object) => () => {
      const { itemList, openModal, closeModal, deleteListItemRequest } = props;

      const selectedList = G.getSelectedItemGuids(itemList);

      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => {
          closeModal();
          deleteListItemRequest(selectedList);
        },
      });

      return openModal(modalContent);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => {
      const { openModal, closeModal, openFixedPopup, closeFixedPopup } = props;

      return (
        openFixedPopup({
          position: 'right',
          el: e.currentTarget,
          content: (
            <RowActions
              entity={data}
              openModal={openModal}
              closeModal={closeModal}
              closeFixedPopup={closeFixedPopup}
            />
          ),
        })
      );
    },
  }),
  pure,
);

const enhance = compose(
  reportEnhancer,
  withFixedPopover,
  withActions,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);


export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    selectItem,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const freezedField = {
    sequence: 1,
    freezed: true,
    name: 'actions',
    reference: false,
  };

  const reportToUse = G.prependFieldToTableReportFields(selectedReport, freezedField);

  const allChecked = R.all(
    (item: Object) => item.selected,
    itemList,
  );

  const enhancedTableSettings = G.ifElse(
    R.gt(R.length(itemList), 0),
    tableSettings,
    R.mergeRight(tableSettings, { allowSelectAll: false }),
  );

  const omitRenderFields = [
    GC.FIELD_EDI_FILE_URI,
    GC.FIELD_EDI_SENDER_QUALIFIER,
  ];

  const data = {
    loading,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    omitRenderFields,
    tableTitleFilters,
    hasSelectable: true,
    report: reportToUse,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    withResizableColumns: true,
    useSearchableColumns: true,
    itemList: R.values(itemList),
    useNewTitleFilterInputs: true,
    tableSettings: enhancedTableSettings,
    handleLoadMoreEntities: G.ifElse(loading, () => {}, getItemListRequest),
    renderRightStickedComponent: (data: Object) => renderElementActions(data, handleClickEditIcon),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS)),
  };

  return <Table {...data} />;
};

const CarrierEdiList = (props: Object) => {
  const {
    itemList,
    totalCount,
    reportList,
    filterParams,
    selectedReport,
    handleDeleteListItem,
  } = props;

  const hasPinned = G.hasPinnedReports(reportList);
  const hasFilters = G.hasSearchCriteria(selectedReport, filterParams);
  const hasBorderBottom = R.or(hasPinned, hasFilters);

  const customTitleComponent = (
    // TODO: remove or uncomment after testing
    // <TitleTabs totalCount={totalCount} />
    <DispatchBoardTileTabs
      totalCount={totalCount}
      hasBorderBottom={hasBorderBottom}
      marginLeft={G.ifElse(hasBorderBottom, '0px', '1px')}
    />
  );

  const listActions = [
    {
      type: 'massAction',
      action: handleDeleteListItem,
      permissions: [PC.CLO_EDI_WRITE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
      icon: I.trash(G.getTheme('colors.light.mainLight'), 22, 22),
    },
  ];

  return (
    <ListWrapper p={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          version={2}
          noExportable={true}
          popperWithCount={true}
          type={GC.INVOICE_EDI_REPORT}
          hiddenRightFilterInfo={false}
          customTitleComponent={customTitleComponent}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.CARRIER_EDI_WRITE]}>
        <PageActions
          listActions={listActions}
          shadowColor={G.getTheme('createButton.shadowColor')}
          count={R.length(R.filter((item: Object) => item.selected, R.or(itemList, [])))}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  filterProps: makeSelectFilterProps(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReports,
  closeModal,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  deleteListItemRequest,
  resetListAndPagination,
  changeDefaultReportRequest,
})(enhance(CarrierEdiList));
