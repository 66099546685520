import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Map, TileLayer } from 'react-leaflet';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const MapWrapper = ({
  bounds,
  center,
  children,
  mapCss = [],
  width = '100%',
  height = 'auto',
}: Object) => (
  <Box width='100%'>
    <Map
      zoom={4}
      maxZoom={18}
      center={center}
      bounds={bounds}
      css={R.concat(css`
        z-index: 1;
        width: ${width};
        height: ${height};
      }`, mapCss)}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {children}
    </Map>
  </Box>
);

export default MapWrapper;
