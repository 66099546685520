import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, ReactSelect } from '../../../ui';
// feature route/list
import { getGroupByOptions } from '../settings';
import { ReportPanelOption } from '../../report-format/ui';
/////////////////////////////////////////////////

const renderOptionComponent = ({ data, selectOption }: Object) => (
  <ReportPanelOption
    isDefault={data.defaultReport}
    onClick={() => selectOption(data)}
  >
    {data.name}
  </ReportPanelOption>
);

const getValue = (input: Object, options: Array) => {
  const usingProp = R.find(
    ({ value }: Object) => R.equals(value, input),
    options,
  );
  return ({
    value: R.propOr(GC.ORGANIZE_BY_NO_GROUPING, GC.FIELD_VALUE, usingProp),
    label: R.propOr(G.getWindowLocale('titles:no-group', 'No Group'), GC.FIELD_NAME, usingProp),
  });
};

const SelectGroupBy = (props: Object) => {
  const options = getGroupByOptions(props.loadType);

  return (
    <Box px={10} mb='4px' width={150}>
      <TextComponent mb='5px' ml={15} fontSize={9} color={G.getTheme('colors.light.black')}>
        {G.getWindowLocale('titles:organise-by', 'Organise By')}
      </TextComponent>
      <ReactSelect
        height={30}
        options={options}
        isClearable={false}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        shouldNotGetValueFromOptions={true}
        onChange={props.handleSelectGroupBy}
        value={getValue(props.groupBy, options)}
        components={{ Option: renderOptionComponent, IndicatorSeparator: () => null }}
      />
    </Box>
  );
};

export default SelectGroupBy;
