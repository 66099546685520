import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, lifecycle, withProps, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncInitialDataOnDidMount,
  withAsyncGetDriverListFullName,
  withAsyncGetUnitIdListByEntityType,
} from '../../../hocs';
// ui
import { Box } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature fleet/driver
import {
  getPayrollAssessorialFields,
  defaultPayrollAssessorialFields,
  getVendorPayrollAccessorialFieldSettings,
  getPayrollAssessorialValidationSchemaObject,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultPayrollAssessorialFields,
      props.initialValues,
    ),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getPayrollAssessorialValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props }: Object) => {
      const data = {
        requestData: values,
        requestMethod: props.requestMethod,
      };

      props.submitAction(data);
    },
  }),
  withHandlers({
    handleCustomChange: (props: Object) => ({ field }: Object) => {
      const { values, setValues, accessorialConfigs } = props;
      const value = R.prop(GC.FIELD_DRIVER_ASSESSORIAL_GUID, field);
      const accessorial = R.compose(
        R.assoc(GC.FIELD_DRIVER_ASSESSORIAL_GUID, value),
        R.find(R.or(R.propEq(value, GC.FIELD_ACCESSORIAL_PARENT_GUID), R.propEq(GC.FIELD_GUID, value))),
      )(R.or(accessorialConfigs, []));
      const newValues = R.mergeRight(
        values,
        R.pick([GC.FIELD_FUEL_RELATED, GC.FIELD_NON_TAXABLE, GC.FIELD_DRIVER_ASSESSORIAL_GUID], accessorial),
      );
      setValues(newValues);
    },
  }),
  pure,
);

const enhanceVendor = compose(
  enhance,
  withAsyncGetDriverListFullName,
  withAsyncGetUnitIdListByEntityType,
  withProps(({ initialValues }: Object) => {
    const asyncEndpoint = endpointsMap.listFleetTrucks;
    const asyncOptions = {
      params: {
        [GC.FIELD_VENDOR_GUID]: R.prop(GC.FIELD_FLEET_VENDOR_GUID, initialValues),
      },
    };

    return { asyncOptions, asyncEndpoint };
  }),
  withAsyncInitialDataOnDidMount,
  withProps((props: Object) => {
    const { asyncInitialData, asyncUnitIdList, asyncDriverListFullName } = props;

    const driverOptions = R.map((item: Object) => {
      const { fullText } = G.getUserInfo(item);
      const value = G.getGuidFromObject(item);

      return { value, label: fullText };
    }, asyncDriverListFullName);

    let truckOptions = R.compose(
      R.map(({ guid, unitId }: Object) => ({ label: unitId, value: guid })),
      R.pathOr([], ['data']),
    )(asyncInitialData);

    const truckGuids = R.path(['values', GC.FIELD_TRUCK_GUIDS], props);

    if (R.and(G.isNotNilAndNotEmpty(asyncUnitIdList), G.isNotNilAndNotEmpty(truckGuids))) {
      const assignedToVendorTruckGuids = R.map(G.getValueFromObject, truckOptions);

      truckOptions = R.compose(
        R.concat(truckOptions),
        R.map((value: string) => ({
          value,
          [GC.FIELD_LABEL]: R.pathOr('_', [GC.FIELD_UNIT_ID], R.find(R.propEq(value, GC.FIELD_GUID), asyncUnitIdList)),
        })),
        R.filter((item: string) => G.notContain(item, assignedToVendorTruckGuids)),
      )(truckGuids);
    }

    return { truckOptions, driverOptions };
  }),
  lifecycle({
    componentDidMount() {
      const props = this.props;

      const { branchGuid, initialValues, getAsyncGetUnitIdList, getAsyncGetDriverListFullName } = props;

      G.callFunctionWithArgs(getAsyncGetDriverListFullName, { branchGuid });

      if (G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_TRUCK_GUIDS, initialValues))) {
        getAsyncGetUnitIdList({ branchGuid });
      }
    },
  }),
);

const getFields = (isVendor: boolean, values: Object, isEdit: boolean) => G.ifElse(
  G.isTrue(isVendor),
  getVendorPayrollAccessorialFieldSettings({ ...values, isEdit }),
  getPayrollAssessorialFields({ ...values, isEdit }),
);

const PayrollAssessorialFormComponent = (props: Object) => {
  const {
    isEdit,
    values,
    closeModal,
    handleSubmit,
    truckOptions,
    driverOptions,
    handleCustomChange,
    accessorialOptions,
    payrollAccessorialTypeVendor,
  } = props;

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <FieldsetComponent
          {...G.getFormikProps(props)}
          justifyContent='space-between'
          handlers={{ handleCustomChange }}
          fields={getFields(payrollAccessorialTypeVendor, values, isEdit)}
          optionsForSelect={{
            truckOptions,
            driverOptions,
            accessorialOptions,
          }}
        />
        <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={closeModal} />
      </form>
    </Box>
  );
};

const VendorPayrollAccessorialForm = enhanceVendor((props: Object) => (
  <PayrollAssessorialFormComponent {...props} />
));

const DriverPayrollAccessorialForm = enhance((props: object) => (
  <PayrollAssessorialFormComponent {...props} />
));

const PayrollAccessorialForm = (props: Object) => {
  const { payrollAccessorialTypeVendor } = props;

  if (payrollAccessorialTypeVendor) {
    return <VendorPayrollAccessorialForm {...props} entityType={GC.FIELD_TRUCK} />;
  }

  return <DriverPayrollAccessorialForm {...props} />;
};

export default PayrollAccessorialForm;
