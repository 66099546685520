import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { generateDefaultReport } from '../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature customer-portal
import * as A from './actions';
import {
  makeSelectShowBooked,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterParams,
  makeSelectIsInitialState,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* getCustomerPortalListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.setMapOpened(false));
    yield put(A.setListLoading(true));
    const showBooked = yield select(makeSelectShowBooked());
    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const { limit, offset } = pagination;

    const refreshPage = R.pathOr(false, ['refreshPage'], payload);
    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );
    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );
    const reqBody = {
      ...pagination,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: R.pathOr([], ['fields'], reportParams),
      offset: G.ifElse(G.isTrue(refreshPage), 0, offset),
      limit: G.ifElse(G.isTrue(refreshPage), offset, limit),
      showBooked: R.pathOr(showBooked, ['showBooked'], payload),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };
    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloListForCustomer, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCustomerPortalListSuccess({
        data,
        refreshPage: R.pathOr(false, ['refreshPage'], payload),
        showBooked: R.pathOr(showBooked, ['showBooked'], payload),
      }));
      const useTimer = G.isTrue(R.or(
        G.getPropFromObject('startTimer', payload),
        G.getPropFromObject('refreshPage', payload),
      ));

      if (useTimer) yield put(A.setTimerStarted(true));
    } else {
      yield call(G.handleFailResponse, res, 'getCustomerPortalListRequest fail');
    }
    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));
    yield put(A.setTimerStarted(false));
    yield call(G.handleException, err, 'getCustomerPortalListRequest exception');
  }
}

function* handleAvailableReportsRequest(action: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return false;

    const params = {
      reportType: GC.CUSTOMER_PORTAL_CLO_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      yield put(A.setReports(reports));

      if (R.not(R.prop('length', reports))) {
        const generatedReport = generateDefaultReport(GC.CUSTOMER_PORTAL_CLO_REPORT);

        return yield put(A.setUsedReport(generatedReport));
      }

      if (R.not(R.path(['payload', 'notSetUsedReport'], action))) {
        const defaultReport = G.findDefaultReport(reports);
        const usedReport = R.or(defaultReport, generateDefaultReport(GC.CUSTOMER_PORTAL_CLO_REPORT));

        yield put(A.setUsedReport(usedReport));
      }

    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = {
      data: R.mergeRight(payload, { searchCriteria, [GC.FIELD_BRANCH_GUID]: currentBranchGuid }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(data)));
      yield call(
        handleAvailableReportsRequest,
        { payload: { notSetUsedReport: true } },
      );
      yield put(A.getCustomerPortalListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = {
      data: R.assoc('searchCriteria', searchCriteria, payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(data)));
      yield call(
        handleAvailableReportsRequest,
        { payload: { notSetUsedReport: true } },
      );
      yield put(A.getCustomerPortalListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleAvailableReportsRequest);
      yield put(A.resetListAndPagination());
      yield put(A.getCustomerPortalListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const showBooked = yield select(makeSelectShowBooked());
    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);
    const reqBody = {
      showBooked,
      searchCriteria,
      fields: payload.fields,
      orderFields: payload.orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };
    const options = {
      resType: 'arraybuffer',
      params: { format: payload.fileType },
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.exportCloListForCustomer, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}


function* getTransportationModeMappingRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getTransportationModeMappingSuccess,
      parentSagaName: 'getTransportationModeMappingRequest',
      endpoint: endpointsMap.transportationModeIconMappingList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTransportationModeMappingRequest exception');
  }
}

function* visitCustomerPortalPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CUSTOMER_PORTAL_PAGE);
    const isInitialState = yield select(makeSelectIsInitialState());

    if (G.isTrue(isInitialState)) {
      yield put(A.refreshCustomerPortalPageRequest());
      yield put(openLoader({ showDimmer: true }));
      yield call(getTransportationModeMappingRequest);
      yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_CLO));
      yield call(handleAvailableReportsRequest);
      yield put(A.getCustomerPortalListRequest({ startTimer: true }));
      yield put(closeLoader());
    }

    break;
  }
}

function* customerPortalWatcherSaga() {
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(GC.VISIT_CUSTOMER_PORTAL_PAGE, visitCustomerPortalPage);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.getCustomerPortalListRequest, getCustomerPortalListRequest);
}

export default customerPortalWatcherSaga;
