import L from 'leaflet';
import * as R from 'ramda';
import { css } from 'styled-components';
import React, {useCallback } from 'react';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import { Popup, Circle, CircleMarker, FeatureGroup } from 'react-leaflet';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature geo-fencing-location
import * as H from '../helpers';
import MapWrapper from './map-wrapper';
//////////////////////////////////////////////////

const trailerMarkerColor = G.getTheme('colors.light.red');

const getMapCenterAndBounds = (position: Object = {}, currentRadius: number) => {
  if (G.isNilOrEmpty(position)) return { center: [38.755157, -98.269035] };

  const diff = H.differenceByRadius(currentRadius);
  const pos = R.values(position);
  const southWest = L.latLng(pos[0] + diff, pos[1] + diff);
  const northEast = L.latLng(pos[0] - diff, pos[1] - diff);
  const bounds = L.latLngBounds(southWest, northEast);

  return {
    bounds,
    center: pos,
  };
};

const TrailerMarkers = ({ trailerList }: Object) => (
  R.map((trailer: Object) => {
    const name = R.prop(GC.FIELD_UNIT_ID, trailer);
    const enterpriseName = R.prop('enterprise.enterpriseName', trailer);
    const latitude = R.prop('trailerLatestLocation.latitude', trailer);
    const longitude = R.prop('trailerLatestLocation.longitude', trailer);

    const center = [latitude, longitude];

    return (
      <CircleMarker
        radius={5}
        center={center}
        color={trailerMarkerColor}
        key={G.getGuidFromObject(trailer)}
      >
        <Popup>
          {name} <br /> ({enterpriseName})
        </Popup>
      </CircleMarker>
    );
  }, trailerList)
);

const LocationMap = (props: Object) => {
  const { mode, radius, position, handleSetRadius, trailerList } = props;

  const currentRadius = G.ifElse(G.isMetricUomSystem(), +radius, +H.feetToMeters(radius));

  const { bounds, center } = getMapCenterAndBounds(position, currentRadius);

  const handleEditComplete = useCallback((data: Object) => {
    const circleLayer = R.head(data.layers.getLayers());

    if (G.isNilOrEmpty(circleLayer)) return;

    let radius = G.toFixed(circleLayer.getRadius(), 0);

    if (R.not(G.isMetricUomSystem())) {
      radius = H.metersToFeet(radius);
    }

    handleSetRadius(radius);
  }, [handleSetRadius]);

  L.drawLocal.edit = {
    toolbar: {
      actions: {
        save: {
          text: G.getWindowLocale('actions:save', 'Save'),
          title: G.getWindowLocale('titles:geo-fencing-location-save-radius', 'Save radius'),
        },
        cancel: {
          text: G.getWindowLocale('actions:cancel', 'Cancel'),
          title: G.getWindowLocale('titles:geo-fencing-location-cancel-radius', 'Cancel radius editing'),
        },
      },
      buttons: {
        edit: G.getWindowLocale('titles:geo-fencing-location-edit-radius', 'Edit radius'),
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: G.getWindowLocale('titles:geo-fencing-location-edit-radius-text', 'Drag handles to edit radius.'),
          subtext: G.getWindowLocale('titles:geo-fencing-location-cancel-radius-text', 'Click cancel to undo changes.'),
        },
      },
    },
  };

  return (
    <MapWrapper
      height='610px'
      bounds={bounds}
      center={center}
      mapCss={css`
        .leaflet-pane.leaflet-marker-pane > div:first-child {
          opacity: 0;
          pointer-events: none;
        }
      }`}
    >
      <FeatureGroup>
        { R.equals(mode, H.EDIT_MODE) && (
          <EditControl
            position='topleft'
            onEdited={handleEditComplete}
            edit={{
              edit: true,
              remove: false,
            }}
            draw={{
              circle: false,
              marker: false,
              polygon: false,
              polyline: false,
              rectangle: false,
              circlemarker: false,
            }}
          />
        )}
        <Circle center={center} radius={currentRadius} />
      </FeatureGroup>
      { G.isNotNilAndNotEmpty(trailerList) && (
        <TrailerMarkers trailerList={trailerList} />
      )}
    </MapWrapper>
  );
};

export default LocationMap;
