import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
/////////////////////////////////////////////

// events
export const getStatusMessageListWithLoadEvent = (list: Array, events: Array) => R.map((item: Object) => {
  const event = R.path([R.prop(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID, item)], events);
  if (G.isNilOrEmpty(event)) return item;
  const loadEvent = G.getStopData(event, R.not(G.isLoadTypeClo(event)));

  return R.assoc(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT, loadEvent, item);
}, list);

export const mapLoadStopsWithNewStatus = (load: Object, stopGuid: string, status: string) => R.map(
  R.when(
    R.propEq(stopGuid, GC.FIELD_GUID),
    R.set(R.lensProp(GC.FIELD_LOAD_STATUS), status),
  ),
)(R.pathOr([], [GC.FIELD_LOAD_STOPS], load),
);

// reducer
export const getStateWithUnselectedRates = (state: Object) => {
  const rates = R.map(
    R.assoc('selected', false),
    R.path(['lists', 'driverCarrierRateList'], state),
  );

  return P.$set('lists.driverCarrierRateList', rates, state);
};

export const setActiveListDataToStore = R.curry((
  state: Object,
  list: Object,
  listName: string,
  tableNumber: number,
) => {
  if (G.isNilOrEmpty(tableNumber)) return P.$set(`lists.${listName}`, list, state);
  if (R.equals(tableNumber, 'all')) {
    return P.$all(
      P.$set(`lists.${listName}`, list),
      P.$set('activeLists', R.map(() => listName, state.activeLists)),
      state,
    );
  }

  return P.$all(
    P.$set(`lists.${listName}`, list),
    P.$set(`activeLists.${tableNumber}`, listName),
    state,
  );
});

export const setUpdatedListItemToStore = R.curry((data: Object, state: Object, listName: string) => {
  const { guid } = data;
  const list = R.path(['lists', listName], state);
  const index = R.findIndex(R.pathEq(guid, [GC.FIELD_GUID]), list);

  return P.$set(`lists.${listName}.${index}`, data, state);
});

export const removeListItemFromStore = R.curry((state: Object, guid: string, listName: string) => {
  const list = R.path(['lists', listName], state);
  const index = R.findIndex(R.pathEq(guid, [GC.FIELD_GUID]), list);

  return P.$drop(`lists.${listName}.${index}`, state);
});

// customer rates
export const getCloTotalWeightByCloGuid = (tel: Object, cloGuid: string) => {
  const items = R.filter(
    R.pathEq(cloGuid, [GC.FIELD_LOAD_GUID]),
    R.pathOr([], [GC.FIELD_LOAD_ITEMS], tel),
  );
  if (G.isNotNilAndNotEmpty(items)) {
    const total = G.calcItemsTotalWeightWithoutQty(items);

    return G.renameKeys({
      [GC.FIELD_ITEM_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
      [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    }, total);
  }

  return {};
};

export const makeCloTotalData = (clo: Object, tel: Object) => {
  const { guid } = clo;

  const cloTotalWeight = getCloTotalWeightByCloGuid(tel, guid);

  const cloEvents = R.filter(R.pathEq(guid, [GC.FIELD_CLO_GUID]), R.pathOr([], [GC.FIELD_LOAD_STOPS], tel));

  const mappedCloEvents = R.compose(
    R.filter((item: Object) => G.isNotNilAndNotEmpty(item)),
    R.map(R.prop('cloDistanceToNextStop')),
  )(cloEvents);

  const cloTotalDistance = G.calculateTotalDistance(mappedCloEvents);

  const items = R.filter(
    R.pathEq(guid, [GC.FIELD_LOAD_GUID]),
    R.pathOr([], [GC.FIELD_LOAD_ITEMS], tel),
  );

  const cloTotalPickupQuantity = G.makeTotalPickupQuantityObjectFromItems(items);

  return {
    cloEvents,
    cloTotalWeight,
    cloTotalDistance,
    cloTotalPickupQuantity,
  };
};

export const getTotalCustomerRatesFromClos = (clos: Array) => {
  if (G.isNotNilAndNotEmpty(clos)) {
    return R.reduce((acc: number, item: object) => {
      const total = R.pathOr(0, [GC.FIELD_TOTAL], item);

      return R.add(acc, total);
    }, 0, clos);
  }

  return 0;
};

// configs
export const getDefaultUomFieldsFromConfigs = (configsByNames: Object) => {
  const uomSystem = G.getConfigValueFromStore(GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM, configsByNames);

  return R.pathOr({}, [uomSystem], GC.defaultSystemUoms);
};

export const getSyncRateFromCustomerInvoiceValue = (loadConfigs: Object, useConfigGroups: boolean = false) => {
  const configPath = G.ifElse(useConfigGroups, ['configGroups', GC.CLO_CONFIG_GROUP], ['configsByNames']);

  return G.getConfigValueFromStore(
    GC.CLO_RATE_SYNC_RATE_FROM_CUSTOMER_INVOICE,
    R.pathOr({}, configPath, loadConfigs),
  );
};

// status messages
export const filterStatusCodeOptions = (props: Object, withoutComplited: boolean = false) => {
  let statusMessages = props.statusMessagesConfigs;

  const proofType = R.path(['values', GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE], props);

  if (R.isNil(proofType)) return statusMessages;

  if (R.equals(proofType, GC.DOCUMENT_PROOF_TYPE_POP)) {
    statusMessages = R.filter(
      (status: Object) => R.equals(status.statusType, GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED),
      statusMessages,
    );
  }

  if (R.equals(proofType, GC.DOCUMENT_PROOF_TYPE_POD)) {
    statusMessages = R.filter(
      (status: Object) => R.equals(status.statusType, GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED),
      statusMessages,
    );
  }

  if (R.equals(withoutComplited, true)) {
    statusMessages = R.filter(
      (status: Object) => (
        R.and(
          G.notEquals(status.statusType, GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED),
          G.notEquals(status.statusType, GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED),
        )
      ),
      statusMessages,
    );
  }

  return statusMessages;
};

export const getOptions = (setting: Object) => R.compose(
  R.values,
  R.mapObjIndexed((label: string, value: string) => ({ value, label })),
)(setting);

export const createFieldsForReport = (setting: Object) =>
  R.compose(
    R.addIndex(R.map)((key: string, index: number) =>
      ({ name: key, sequence: index })),
    R.keys,
  )(setting);

export const isLengthBetween8And16 = R.both(
  R.compose(R.gte(R.__, 8), R.length),
  R.compose(R.lte(R.__, 16), R.length),
);

