import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
// common
import { openModal } from '../../../components/modal/actions';
// components
import { TextComponent } from '../../../components/text';
// features
import SectionDivider from '../../new-do/components/section-divider';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StyledLink } from '../../../ui';
// feature geo-fencing-location
import RouteHistoryPopup from './route-history-popup';
import {
  getFleetTrailerRouteHistoryRequest,
  getFleetTrailerRouteHistorySuccess,
} from '../actions';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const Trailer = ({ trailer }: Object) => {
  const dispatch = useDispatch();

  const [showRouteHistory, setShowRouteHistory] = useState(false);

  const guid = G.getGuidFromObject(trailer);
  const name = R.prop(GC.FIELD_UNIT_ID, trailer);
  const enterpriseName = R.prop('enterprise.enterpriseName', trailer);
  const text = `${name} (${enterpriseName})`;

  const handleGetHistory = useCallback(() => {
    dispatch(getFleetTrailerRouteHistoryRequest(guid));

    setShowRouteHistory(true);
  }, [guid]);

  const closePopup = useCallback(() => {
    dispatch(getFleetTrailerRouteHistorySuccess(null));

    setShowRouteHistory(false);
  }, [showRouteHistory]);


  const handleShowRoutePopup = useCallback(() => {
    const component = (
      <RouteHistoryPopup
        closeModal={closePopup}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        width: 'auto',
        height: 'auto',
        movable: false,
        withCloseIcon: true,
        title: G.getWindowLocale('titles:trailer-tracking', 'Trailer Tracking'),
      },
    };

    dispatch(openModal(modal));
  }, [closePopup]);

  useEffect(() => {
    if (showRouteHistory) {
      handleShowRoutePopup();
    }
  }, [showRouteHistory]);

  return (
    <Box>
      <Flex gap={16} justifyContent='space-between'>
        <TextComponent
          title={text}
          fontSize={14}
          withEllipsis={true}
        >
          {text}
        </TextComponent>
        <StyledLink
          cursor='pointer'
          color={blueColor}
          textTransform='lowercase'
          onClick={handleGetHistory}
        >{G.getWindowLocale('actions:trailer-history', 'History')}</StyledLink>
      </Flex>
      <SectionDivider mt={8} mb={8} />
    </Box>
  );
};

const TrailerList = ({
  loading,
  trailerList,
}: Object) => (
  <Box width='100%' overflow='auto' maxHeight='64vh'>
    {
      G.isNotNilAndNotEmpty(trailerList) && R.map((trailer: Object) => (
        <Trailer key={G.getGuidFromObject(trailer)} trailer={trailer} />
      ), trailerList)
    }
    { R.and(
      R.not(loading),
      G.isNilOrEmpty(trailerList),
    ) && (
      <TextComponent>
        {G.getWindowLocale(
          'titles:geo-fencing-location-no-trailers',
          'There are no trailers at the current location.',
        )}
      </TextComponent>
    )}
  </Box>
);

export default TrailerList;
