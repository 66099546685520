import * as R from 'ramda';
import { createSelector } from 'reselect';
import { convertToOptions } from './helpers';
///////////////////////////////////////////////////////////////

const selectIntStore = (state: Object) => state.intl;

const makeSelectIntl = () => createSelector(
  selectIntStore,
  ({ intlSettings }: Object) => R.or(intlSettings, []),
);

const makeSelectChangedLocaleItems = () => createSelector(
  selectIntStore,
  ({ changedLocaleItems }: Object) => R.or(changedLocaleItems, []),
);

const makeSelectCustomKeys = () => createSelector(
  selectIntStore,
  ({ customKeys }: Object) => customKeys,
);

const makeSelectActiveTab = () => createSelector(
  selectIntStore,
  ({ activeTab }: Object) => activeTab,
);

const makeSelectActiveBranchName = () => createSelector(
  selectIntStore,
  ({ branchName }: Object) => branchName,
);

const makeSelectActivePageTab = () => createSelector(
  selectIntStore,
  ({ pageTab }: Object) => pageTab,
);

const makeSelectTabInfo = () => createSelector(
  selectIntStore,
  ({ tabs }: Object) => R.or(tabs, []),
);

const makeSelectLanguage = () => createSelector(
  selectIntStore,
  ({ languageTitle }: Object) => R.or(languageTitle, {}),
);

const makeSelectMastersBranchList = () => createSelector(
  selectIntStore,
  ({ masterBranchList }: Object) => convertToOptions(masterBranchList),
);

const makeSelectCurrentLanguage = () => createSelector(
  selectIntStore,
  ({ listLanguageFromBack }: Object) => R.or(listLanguageFromBack, []),
);

export {
  makeSelectIntl,
  selectIntStore,
  makeSelectTabInfo,
  makeSelectLanguage,
  makeSelectActiveTab,
  makeSelectCustomKeys,
  makeSelectActivePageTab,
  makeSelectCurrentLanguage,
  makeSelectActiveBranchName,
  makeSelectMastersBranchList,
  makeSelectChangedLocaleItems,
};
