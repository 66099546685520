import React, { memo, Fragment } from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, SectionsDivider, scrollableContainerCss4px } from '../../../../ui';
// feature fleet-list
import { SectionHeader } from './section-header';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkGreyColor = G.getTheme('colors.#7D828C');
const lightGreyColor = G.getTheme('colors.lightGrey');

const InfoPair = ({ title, titleLocaleArr }: Object) => {
  if (G.isNilOrEmpty(title)) return null;

  return (
    <Flex my='5px' color={darkGreyColor}>
      {
        G.isNotNil(titleLocaleArr) &&
        <Box mr='5px' width={150}>{G.getWindowLocale(...titleLocaleArr)}:</Box>
      }
      <TextComponent
        title={title}
        maxWidth={300}
        display='block'
        withEllipsis={true}
      >
        {title}
      </TextComponent>
    </Flex>
  );
};

const RepairZones = ({ repairZones }: Object) => (
  <Box px={15} borderRadius='6px' background={whiteColor}>
    <Flex mt={15} pb='8px' flexDirection='column' alignItems='flex-start'>
      <Box py={10} mr={22} fontWeight='bold' color={darkGreyColor}>
        {G.getWindowLocale('titles:repair-zones', 'Repair Zones')}:
      </Box>
      <Box width='100%'>
        {
          repairZones.map((zone: Object) => {
            const { guid, name, addressPoints } = zone;

            return (
              <SectionHeader key={guid} title={name}>
                <Box mb={10}>
                  {
                    addressPoints.map((addressPoint: Object) => {
                      const { guid, cities, states, zipCodes, countries, zipRangeTo, zipRangeFrom } = addressPoint;

                      return (
                        <Box px='5px' key={guid}>
                          <InfoPair
                            titleLocaleArr={['titles:cities', 'Cities']}
                            title={G.createStringFromArray(cities, ', ')}
                          />
                          <InfoPair
                            titleLocaleArr={['titles:states', 'States']}
                            title={G.createStringFromArray(states, ', ')}
                          />
                          <InfoPair
                            title={G.createStringFromArray(countries, ', ')}
                            titleLocaleArr={['titles:countries', 'Countries']}
                          />
                          <InfoPair
                            title={G.createStringFromArray(zipCodes, ', ')}
                            titleLocaleArr={['titles:zip-codes', 'Zip Codes']}
                          />
                          <InfoPair title={zipRangeFrom} titleLocaleArr={['titles:zip-from', 'Zip From']} />
                          <InfoPair title={zipRangeTo} titleLocaleArr={['titles:zip-to', 'Zip To']} />
                          <SectionsDivider borderColor={lightGreyColor} />
                        </Box>
                      );
                    })
                  }
                </Box>
              </SectionHeader>
            );
          })
        }
      </Box>
    </Flex>
  </Box>
);

export const ServiceVendorInfo = memo(({ repairZones, serviceVendor }: Object) => {
  const {
    dba,
    name,
    type,
    mcNumber,
    dunsNumber,
    usDotNumber,
    serviceTypes,
    licenseNumber,
  } = serviceVendor;

  return (
    <Fragment>
      <Box
        mb={10}
        width='100%'
        flexShrink={0}
        borderRadius='6px'
        background={whiteColor}
      >
        <Flex
          px={15}
          height={32}
          width='100%'
          color={darkGreyColor}
          justifyContent='center'
          borderTopLeftRadius='6px'
          borderTopRightRadius='6px'
          bg={G.getTheme('colors.borderGray')}
        >
          <TextComponent
            title={name}
            display='block'
            withEllipsis={true}
            maxWidth='calc(100% - 30px)'
          >
            {name}
          </TextComponent>
        </Flex>
        <Box p='0px 15px 5px'>
          <InfoPair title={G.getEnumLocale(type)} titleLocaleArr={['titles:type', 'Type']} />
          <InfoPair title={dba} titleLocaleArr={['titles:dba', 'DBA']} />
          <InfoPair title={usDotNumber} titleLocaleArr={['titles:usdot-number', 'USDOT Number']} />
          <InfoPair title={mcNumber} titleLocaleArr={['titles:mc-number', 'MC Number']} />
          <InfoPair title={dunsNumber} titleLocaleArr={['titles:duns-number', 'D-U-N-S Number']} />
          <InfoPair title={licenseNumber} titleLocaleArr={['titles:license-number', 'License Number']} />
        </Box>
        {
          G.isNotNilAndNotEmpty(serviceTypes) &&
          <Fragment>
            <SectionsDivider borderColor={lightGreyColor} />
            <Flex px={15} my='8px' pb='8px' color={darkGreyColor} alignItems='flex-start'>
              <Box mr='5px' width={150} flexShrink={0}>
                {G.getWindowLocale('titles:service-types', 'Service Types')}:
              </Box>
              <Box maxHeight={70} overflowY='auto' css={scrollableContainerCss4px}>
                {G.createStringFromArrayWithProp(serviceTypes, ', ', GC.FIELD_DISPLAYED_VALUE)}
              </Box>
            </Flex>
          </Fragment>
        }
      </Box>
      {G.isNotNilAndNotEmpty(repairZones) && <RepairZones repairZones={repairZones} />}
    </Fragment>
  );
});
