import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withWindowSize, withIsOpenedModalStatus } from '../../../hocs';
// ui
import { RelativeBox } from '../../../ui';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// report-common
import { generateDefaultReport } from '../../../report-common';
// features
import { loadFilterProps } from '../../dispatch-report/settings/filter-params';
import { loadColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
// feature dispatch-planner
import CustomTitle from './custom-title';
import { pageSizes } from '../settings/page-settings';
import { telTableSettings } from '../settings/table-settings';
import {
  cleanBuilder,
  getTelNextPage,
  addTelToPlanner,
  toggleTelDetails,
  cleanTelQuickFilter,
  removeTelFromPlanner,
  setTelTableTitleSort,
  setTelTableTitleFilter,
  setAvailableTelReports,
  createTelReportRequest,
  updateTelReportRequest,
  setTelQuickFilterParams,
  resetTelListAndPagination,
  setCurrentTelReportWithRoute,
  changeDefaultTelReportRequest,
  getCurrentRouteByTelGuidRequest,
} from '../actions';
import {
  makeSelectTelList,
  makeSelectTelTotal,
  makeSelectTelReport,
  makeSelectTelPagination,
  makeSelectTelListLoading,
  makeSelectTelFilterParams,
  makeSelectAvailableTelReports,
  makeSelectTelTableTitleFilters,
  makeSelectTelTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withWindowSize,
  withIsOpenedModalStatus,
  withHandlers({
    handleListRequest: ({ getTelNextPage }: Object) => () => (
      getTelNextPage()
    ),
    getQuickFilteredListRequest: (props: Object) => () => {
      props.resetTelListAndPagination();
      props.cleanBuilder();
      props.getTelNextPage();
    },
    handleSelectItem: (props: Object) => (guid: string, tel: Object) => {
      if (G.isTrue(tel.selected)) return props.removeTelFromPlanner(guid);

      props.addTelToPlanner(guid);
    },
    handleToggleDetails: (props: Object) => (tel: Object) => (
      props.toggleTelDetails(tel)
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        getTelNextPage,
        createTelReportRequest,
        updateTelReportRequest,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.TEL_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          setReport={setReport}
          requestPending={requestPending}
          onReportSet={() => getTelNextPage(true)}
          createReportRequest={createTelReportRequest}
          updateReportRequest={updateTelReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, 'guid'), props.reportList);
      props.setReport(selectedReport);
      props.getTelNextPage(true);
    },
    handleCleanFilter: (props: Object) => () => {
      props.cleanTelQuickFilter();
      props.cleanBuilder();
      props.getTelNextPage();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    getTelNextPage,
    selectedReport,
    titleSortValues,
    handleSelectItem,
    tableTitleFilters,
    handleToggleDetails,
    handleTableTitleFilter,
  } = props;

  const allChecked = G.isAllChecked(itemList);
  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleSelectItem,
    handleLoadMoreEntities: getTelNextPage,
    tableSettings: R.mergeRight(telTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(loadFilterProps)),
  };

  return <Table {...data} />;
};

const Loads = enhance((props: Object) => {
  const {
    setReport,
    totalCount,
    getTelNextPage,
    updateTelReportRequest,
    setAvailableTelReports,
  } = props;

  return (
    <div>
      <RelativeBox pr={15} zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          name='tel-report'
          type={GC.TEL_REPORT}
          hideFilterInfo={true}
          withoutQuickFilter={true}
          setUsedReport={setReport}
          filterProps={loadFilterProps}
          hiddenRightFilterInfo={false}
          getItemListRequest={getTelNextPage}
          setReports={setAvailableTelReports}
          height={pageSizes.tableHeaderHeight}
          updateReportRequest={updateTelReportRequest}
          customTitleComponent={
            <CustomTitle
              mr={10}
              count={totalCount}
              text={G.getWindowLocale('titles:existing-tels', 'Existing TELs')}
            />
          }
        />
      </RelativeBox>
      <RelativeBox zIndex={1}>
        {renderTable(props)}
      </RelativeBox>
    </div>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  refList: () => [],
  noExportable: () => true,
  itemList: makeSelectTelList(state),
  totalCount: makeSelectTelTotal(state),
  loading: makeSelectTelListLoading(state),
  pagination: makeSelectTelPagination(state),
  selectedReport: makeSelectTelReport(state),
  filterParams: makeSelectTelFilterParams(state),
  reportList: makeSelectAvailableTelReports(state),
  tableTitleFilters: makeSelectTelTableTitleFilters(state),
  titleSortValues: makeSelectTelTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  cleanBuilder,
  getTelNextPage,
  addTelToPlanner,
  toggleTelDetails,
  cleanTelQuickFilter,
  removeTelFromPlanner,
  setAvailableTelReports,
  createTelReportRequest,
  updateTelReportRequest,
  resetTelListAndPagination,
  getCurrentRouteByTelGuidRequest,
  getItemListRequest: getTelNextPage,
  setReport: setCurrentTelReportWithRoute,
  setTableTitleSort: setTelTableTitleSort,
  setTableTitleFilter: setTelTableTitleFilter,
  setQuickFilterParams: setTelQuickFilterParams,
  resetListAndPagination: resetTelListAndPagination,
  changeDefaultReportRequest: changeDefaultTelReportRequest,
})(Loads);
