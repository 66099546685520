import * as R from 'ramda';
import { all, put, take, fork, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { getTermsAndConditionsDocumentRequest } from '../configurations/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// utilities
import routesMap from '../../utilities/routes';
// feature carrier
import * as H from './helpers';
import * as A from './actions';
import {
  makeSelectPortal,
  makeSelectMobView,
  makeSelectPortalType,
  makeSelectCarrierToken,
  makeSelectCarrierPortalInfo,
  makeSelectCarrierDocsOutsideApp,
} from './selectors';
//////////////////////////////////////////////////

const endpointsMapByPortalType = {
  [GC.PORTAL_TYPE_CARRIER]: {
    getPortalInfo: endpointsMap.getTelAcceptRateInfo,
    accept: endpointsMap.getTelRateMailAcceptEndpoint,
    decline: endpointsMap.getTelRateMailDeclineEndpoint,
    getDeclineReasonCodes: endpointsMap.getTelRateDeclineReasonCodes,
  },
  [GC.PORTAL_TYPE_CUSTOMER]: {
    accept: endpointsMap.orderQuoteAcceptFromMail,
    decline: endpointsMap.orderQuoteDeclineFromMail,
    getPortalInfo: endpointsMap.getOrderQuoteByToken,
  },
  [GC.PORTAL_TYPE_CARRIER_QUOTE]: {
    accept: endpointsMap.emailRateQuoteAccept,
    decline: endpointsMap.emailRateQuoteDecline,
    getPortalInfo: endpointsMap.getEmailRateQuoteInfo,
    getDeclineReasonCodes: endpointsMap.getEmailRateQuoteDeclineReasonCodes,
  },
};

function* getPortalInfoByPortalTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { token, portalType } = payload;

    const endpoint = R.path([portalType, 'getPortalInfo'], endpointsMapByPortalType)(token);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getPortalInfoByPortalTypeSuccess(R.assoc('token', token, data)));
    } else {
      yield call(G.goToRoute, routesMap.loginPage);
      yield call(G.handleFailResponse, res, 'getPortalInfoByPortalTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.goToRoute, routesMap.loginPage);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getPortalInfoByPortalTypeRequest exception');
  }
}

function* getDeclineReasonCodesRequest({ payload }: Object) {
  try {
    const { token, branchGuid, portalType } = payload;

    let options;
    let endpoint;

    if (H.isPortalTypeCustomer(portalType)) {
      endpoint = endpointsMap.getDropDownsByNames;
      options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
          names: GC.CLO_QUOTE_DECLINE_REASON_CODE,
        },
      };
    } else {
      endpoint = R.path([portalType, 'getDeclineReasonCodes'], endpointsMapByPortalType)(token);
    }

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDeclineOptionsConfigsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDeclineReasonCodesRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDeclineReasonCodesRequest exception');
  }
}

function* handleCarrierAcceptRequest({ payload }: Object) {
  const { data, setSubmitting } = payload;

  try {
    yield put(openLoader());
    const token = yield select(makeSelectCarrierToken());
    const portalType = yield select(makeSelectPortalType());
    const options = { data };
    const endpoint = R.path([portalType, 'accept'], endpointsMapByPortalType)(token);

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.acceptDispatchSuccess());
      yield put(A.getCarrierResponseStatus(true));
    } else {
      setSubmitting(false);
      yield call(G.handleFailResponse, res, 'handleCarrierAcceptRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    setSubmitting(false);
    yield put(A.getCarrierResponseStatus(false));
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCarrierAcceptRequest exception');
  }
}

function* handleCarrierDeclineRequest({ payload }: Object) {
  const { values, setSubmitting } = payload;

  try {
    const { token, portalType } = values;

    const isCarrierPortal = H.isPortalTypeCarrier(portalType);
    yield put(openLoader());
    const endpoint = R.path([portalType, 'decline'], endpointsMapByPortalType)(token);
    const options = G.ifElse(
      isCarrierPortal,
      { params: R.pick([GC.FIELD_DECLINE_BY, GC.FIELD_DECLINE_REASON_CODE_GUID], values) },
      { data: R.pick([GC.FIELD_COMMENT, GC.FIELD_STATUS_REASON_CODE_GUID], values) },
    );

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.declineDispatchSuccess());
      yield put(A.getCarrierResponseStatus(true));
    } else {
      setSubmitting(false);
      yield call(G.handleFailResponse, res, 'handleCarrierDeclineRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    setSubmitting(false);
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCarrierDeclineRequest exception');
  }
}

function* handleCarrierAddDocRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const portal = yield select(makeSelectPortal());
    const headers = { carrierToken: portal.token };
    const formData = new window.FormData();
    formData.append('file', payload.file);
    formData.append('documentData', JSON.stringify(payload));
    const options = { headers, data: formData };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.carrierDetailPageCreateDocEndpoint(portal.token),
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'The request has succeeded');
    } else {
      yield call(G.handleFailResponse, res, 'handleCarrierAddDocRequest fail');
      yield put(A.getCarrierResponseStatus(false));
      yield put(A.setMessageStatus(R.path(['message'], data)));
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(
      G.showToastrMessage,
      'error',
      'Sorry, some error. Please, try again or reload your browser and try'
    );
    yield put(A.getCarrierResponseStatus(false));
    G.goToRoute(routesMap.portal);
    yield call(G.handleException, error, 'handleCarrierAddDocRequest exception');
  }
}

function* handleCarrierGetChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const telDetails = yield select(makeSelectCarrierPortalInfo());
    const headers = { carrierToken: payload.token };
    const options = {
      headers,
      params: {
        telGuid: telDetails.guid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.getTelChatMessagesByTelGuid(payload.token),
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierResponseStatus(true));
      yield put(A.getChatMessageCarrierSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCarrierGetChatMessageRequest fail');
      yield put(A.getCarrierResponseStatus(false));
      yield put(A.setMessageStatus(R.path(['message'], data)));
      G.goToRoute(routesMap.portal);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(A.getCarrierResponseStatus(false));
    G.goToRoute(routesMap.portal);
    yield call(G.handleException, error, 'handleCarrierGetChatMessageRequest exception');
  }
}

function* handleCarrierAddChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { token } = G.parseQueryString();
    const options = {
      data: payload,
      headers: { carrierToken: token },
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.addTelChatMessagesByTelGuid(token),
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierResponseStatus(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleCarrierAddChatMessageRequest fail');
      yield put(A.getCarrierResponseStatus(false));
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(A.getCarrierResponseStatus(false));
    yield call(G.handleException, error, 'handleCarrierAddChatMessageRequest exception');
  }
}

function* handleCarrierAddMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const portal = yield select(makeSelectPortal());
    const mobView = yield select(makeSelectMobView());
    const document = yield select(makeSelectCarrierDocsOutsideApp());
    const path = window.location.pathname;
    const headers = { carrierToken: portal.token };
    let options = {
      headers,
      data: payload,
    };

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.setStatusMessageEndpoint(portal.token),
      options,
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierResponseStatus(true));
      yield put(A.getPreviousPathName(path));

      if (G.isTrue(mobView)) {
        yield call(G.showToastrMessage, 'success', 'The request has succeeded');
        options = { headers };

        const detailPageRes = yield call(
          sendRequest,
          'get',
          endpointsMap.getCarrierDetailPageEndpoint(portal.token),
          options,
        );

        const { data, status } = detailPageRes;

        if (G.isResponseSuccess(status)) {
          yield put(A.getCarrierPortalInfoSuccess(data));
        }
      } else {
        G.goToRoute(routesMap.portal);
      }

      if (G.isNotNilAndNotEmpty(document)) {
        yield call(handleCarrierAddDocRequest, { payload: document });
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCarrierAddMessageRequest fail');
      yield put(A.getCarrierResponseStatus(false));
      yield put(A.setMessageStatus(R.path(['data', 'message'], res)));

      if (G.isFalse(mobView)) {
        G.goToRoute(routesMap.portal);
      }
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(
      G.showToastrMessage,
      'error',
      'Sorry, some error. Please, try again or reload your browser and try',
    );
    yield put(A.getCarrierResponseStatus(false));
    const mobView = yield select(makeSelectMobView());
    R.and(G.isFalse(mobView), G.goToRoute(routesMap.portal));
    yield call(G.handleException, error, 'handleCarrierAddMessageRequest exception');
  }
}

function* previewCarrierTermsAndConditionsDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = G.getPropFromObject('branchGuid', payload);
    const portalTypeCustomer = R.pathEq('customer', ['portalType'], payload);
    const options = {
      resType: 'arraybuffer',
      params: G.ifElse(
        portalTypeCustomer,
        { [GC.BRANCH_GUID]: branchGuid },
        undefined,
      ),
    };
    const endpoint = G.ifElse(
      portalTypeCustomer,
      endpointsMap.downloadCustomerTermsAndConditionsDocument,
      endpointsMap.downloadCarrierTermsAndConditions(payload.token),
    );
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.openFileInWindowFromArrayBufferResponse(res);
    } else {
      yield call(G.handleFailResponse, res, 'previewCarrierTermsAndConditionsDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(A.getCarrierResponseStatus(false));
    yield call(G.handleException, error, 'previewCarrierTermsAndConditionsDocumentRequest exception');
  }
}

function* downloadRateConfirmationDocumentRequest() {
  try {
    yield put(openLoader());
    const token = yield select(makeSelectCarrierToken());
    const options = {
      resType: 'arraybuffer',
    };
    const endpoint = endpointsMap.downloadCarrierRateConfirmation(token);
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);
      G.openFileInWindowFromArrayBufferResponse(res);
    } else {
      yield call(G.handleFailResponse, res, 'downloadRateConfirmationDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(A.getCarrierResponseStatus(false));
    yield call(G.handleException, error, 'downloadRateConfirmationDocumentRequest exception');
  }
}

function* getBranchLogoUrlRequest({ payload }: Object) {
  try {
    const endpoint = endpointsMap.getLogoByBranchGuid(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchLogoUrlSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchLogoUrlRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchLogoUrlRequest exception');
  }
}

function* getAllAvailableCloRefTypesRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
        // CLO REF TYPE SCOPE GUID
        scopeGuid: 'c77635d9-03b4-4464-b99e-9da58d6abc25',
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableReferenceTypesForScope,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAllAvailableCloRefTypesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAllAvailableCloRefTypes fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAllAvailableCloRefTypes exception');
  }
}

// visit pages
function* visitPortalAcceptPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { portalType } = payload;

    yield put(A.setPortalType(portalType));
    yield fork(getPortalInfoByPortalTypeRequest, { payload });

    const portalInfo = yield take(A.getPortalInfoByPortalTypeSuccess);
    const isCustomerPortal = H.isPortalTypeCustomer(portalType);

    if (isCustomerPortal) {
      const branchGuid = R.path(['payload', GC.BRANCH_GUID], portalInfo);

      yield all([
        put(A.getAllAvailableCloRefTypesRequest(branchGuid)),
        put(A.getBranchLogoUrlRequest(R.or(
          R.path(['payload', GC.FIELD_LOAD_DIVISION_GUID], portalInfo),
          branchGuid,
        ))),
        put(getTermsAndConditionsDocumentRequest({
          branchGuid,
          configGroup: GC.CLO_CONFIG_GROUP,
        })),
      ]);
    }

    break;
  }
}

function* visitPortalDeclinePage({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { portalType } = payload;

    yield put(A.setPortalType(portalType));
    yield fork(getPortalInfoByPortalTypeRequest, { payload });

    const portalInfo = yield take(A.getPortalInfoByPortalTypeSuccess);
    const branchGuid = R.path(['payload', GC.BRANCH_GUID], portalInfo);
    const isCustomerPortal = H.isPortalTypeCustomer(portalType);

    if (isCustomerPortal) {
      yield put(A.getBranchLogoUrlRequest(R.or(
        R.path(['payload', GC.FIELD_LOAD_DIVISION_GUID], portalInfo),
        branchGuid,
      )));
    }

    yield call(getDeclineReasonCodesRequest, { payload: R.assoc('branchGuid', branchGuid, payload) });

    break;
  }
}

function* CarrierPortalWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_PORTAL_ACCEPT_PAGE, visitPortalAcceptPage);
  yield takeLatest(GC.VISIT_PORTAL_DECLINE_PAGE, visitPortalDeclinePage);
  // visit pages
  yield takeLatest(A.getBranchLogoUrlRequest, getBranchLogoUrlRequest);
  yield takeLatest(A.setCarrierDocRequest, handleCarrierAddDocRequest);
  yield takeLatest(A.addCarrierStatusMessage, handleCarrierAddMessageRequest);
  yield takeLatest(A.carrierResponseAcceptRequest, handleCarrierAcceptRequest);
  yield takeLatest(A.carrierResponseDeclineRequest, handleCarrierDeclineRequest);
  yield takeLatest(A.sendCarrierMessageRequest, handleCarrierAddChatMessageRequest);
  yield takeLatest(A.getChatMessageCarrierRequest, handleCarrierGetChatMessageRequest);
  yield takeLatest(A.getAllAvailableCloRefTypesRequest, getAllAvailableCloRefTypesRequest);
  yield takeLatest(A.downloadRateConfirmationDocumentRequest, downloadRateConfirmationDocumentRequest);
  yield takeLatest(A.previewCarrierTermsAndConditionsDocumentRequest, previewCarrierTermsAndConditionsDocumentRequest);
}

export default CarrierPortalWatcherSaga;
