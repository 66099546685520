import * as R from 'ramda';
import { put, call, take, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga, openWindowSaga } from '../../../sagas';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import * as A from '../actions';
import { makeSelectEntityBranchGuid, makeSelectPrimaryObjectGuid } from '../selectors';
//////////////////////////////////////////////////

const configNames = [
  GC.CARRIER_DOCUMENT_TYPE,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.INVOICE_FLEET_VENDOR_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
];

function* getVendorBranchConfigsRequest({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectEntityBranchGuid());

    const options = {
      params: {
        names: R.join(', ', configNames),
        [GC.FIELD_BRANCH_GUID]: R.or(payload, branchGuid),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverBranchConfigsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverBranchConfigsRequest exception');
  }
}

function* getVendorGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getFleetVendorByGuid(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorGeneralDetailsRequest exception');
  }
}

function* updateVendorGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, handleClose, saveAndClose } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.fleetVendor, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.pathEq(true, ['options', 'shouldCloseModal'], payload)) {
        yield put(closeModal());
      }

      if (saveAndClose) {
        yield put(closeLoader());

        G.callFunction(handleClose);

        return false;
      }

      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateDriverGeneralDetailsRequest exception');
  }
}

// visit page
function* visitFleetVendorProfilePage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_PROFILE_VENDOR_PAGE);

    yield put(A.setInitialState('vendor'));

    yield fork(getVendorGeneralDetailsRequest, { payload: G.getGuidFromObject(payload) });

    const generalDetails = yield take(A.getGeneralDetailsSuccess);

    const branchGuid = R.path(['payload', GC.BRANCH_GUID], generalDetails);

    yield call(getVendorBranchConfigsRequest, { payload: branchGuid });

    break;
  }
}

// monthly payroll deduction
function* getVendorPayrollChargesIsPaidOnPayrollRequest({ payload }: Object) {
  try {
    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollChargesIsPaidOnPayroll, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorPayrollChargesIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorPayrollChargesIsPaidOnPayrollRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getVendorPayrollChargesIsPaidOnPayrollRequest exception'));
  }
}

// payroll accessorial
function* getVendorPayrollAccessorialsIsPaidOnPayrollRequest({ payload }: Object) {
  try {
    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollAssessorialsPaidOnPayroll, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorPayrollAccessorialsIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorPayrollAccessorialsIsPaidOnPayrollRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getVendorPayrollAccessorialsIsPaidOnPayrollRequest exception'));
  }
}

// fleet assignment
function* getFleetAssignmentListRequest() {
  try {
    yield put(openLoader());

    const vendorGuid = yield select(makeSelectPrimaryObjectGuid());

    const options = {
      params: { vendorGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.getVendorAssignments, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mapped = R.map((item: Object) => {
        const oldTruckGuid = R.pathOr(null, [GC.SYSTEM_OBJECT_TRUCK, GC.FIELD_GUID], item);
        const oldPrimaryDriverGuid = R.pathOr(null, [GC.SYSTEM_OBJECT_PRIMARY_DRIVER, GC.FIELD_GUID], item);
        const oldSecondaryDriverGuid = R.pathOr(null, [GC.SYSTEM_OBJECT_SECONDARY_DRIVER, GC.FIELD_GUID], item);

        const oldTrailerGuids = R.map(
          R.prop(GC.FIELD_GUID),
          R.pathOr([], [GC.SYSTEM_LIST_TRAILERS], item),
        );

        return R.mergeRight(item, { oldTruckGuid, oldTrailerGuids, oldPrimaryDriverGuid, oldSecondaryDriverGuid });
      }, R.or(data, []));

      yield put(A.getItemListSuccess({ data: mapped, groupName: 'fleetAssignment' }));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetAssignmentListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetAssignmentListRequest exception');
  }
}

function* changeFleetAssignmentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const vendorGuid = yield select(makeSelectPrimaryObjectGuid());

    const options = {
      data: R.assoc(GC.FIELD_VENDOR_GUID, vendorGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.changeVendorAssignment, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(getFleetAssignmentListRequest);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'changeFleetAssignmentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeFleetAssignmentRequest exception');
  }
}

function* createFleetItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { type, values } = payload;

    const branchGuid = yield select(makeSelectEntityBranchGuid());

    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, values),
    };

    const res = yield call(sendRequest, 'post', R.prop(type, endpointsMap), options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      const guid = G.getGuidFromObject(data);

      const routes = {
        truck: routesMap.fleetTruckProfile(guid),
        driver: routesMap.fleetDriverProfile(guid),
        trailer: routesMap.fleetTrailerProfile(guid),
      };

      const url = R.prop(type, routes);

      yield call(openWindowSaga, { url, name: `${type}Profile`, reloadLocation: false });
    } else {
      yield call(G.handleFailResponse, res, 'createFleetItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createFleetItemRequest exception');
  }
}

export function* fleetProfileVendorWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_FLEET_PROFILE_VENDOR_PAGE, visitFleetVendorProfilePage);
  // general
  yield takeLatest(A.getVendorGeneralDetailsRequest, getVendorGeneralDetailsRequest);
  yield takeLatest(A.updateVendorGeneralDetailsRequest, updateVendorGeneralDetailsRequest);
  // monthly payroll deduction
  yield takeLatest(A.getVendorPayrollChargesIsPaidOnPayrollRequest, getVendorPayrollChargesIsPaidOnPayrollRequest);
  // payroll accessorial
  yield takeLatest(
    A.getVendorPayrollAccessorialsIsPaidOnPayrollRequest,
    getVendorPayrollAccessorialsIsPaidOnPayrollRequest,
  );
  // fleet assignment
  yield takeLatest(A.createFleetItemRequest, createFleetItemRequest);
  yield takeLatest(A.changeFleetAssignmentRequest, changeFleetAssignmentRequest);
  yield takeLatest(A.getFleetAssignmentListRequest, getFleetAssignmentListRequest);
}

export default fleetProfileVendorWatcherSaga;
