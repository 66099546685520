import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle, withProps } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../../hocs/with-async-initial-data';
import {
  withAsyncGetDriverListFullName,
  withAsyncGetUnitIdListByEntityType,
} from '../../../../hocs/with-async-get-endpoint-hocs';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature fleet-profile
import SimpleForm from '../../forms';
import { makeSelectEntityBranchGuid } from '../../selectors';
////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  branchGuid: makeSelectEntityBranchGuid(state),
});

export const withVendorPayrollAccessorial = compose(
  withAsyncGetDriverListFullName,
  withAsyncGetUnitIdListByEntityType,
  withProps(({ initialValues }: Object) => {
    const asyncEndpoint = endpointsMap.listFleetTrucks;
    const vendorGuid = R.prop(GC.FIELD_FLEET_VENDOR_GUID, initialValues);

    const asyncOptions = {
      params: {
        vendorGuid,
        [GC.BRANCH_GUID]: G.ifElse(R.isNil(vendorGuid), G.getAmousCurrentUserBranchGuidFromWindow()),
      },
    };

    return { asyncOptions, asyncEndpoint };
  }),
  withAsyncInitialDataOnDidMount,
  withProps((props: Object) => {
    const { asyncInitialData, asyncUnitIdList, optionsForSelect, asyncDriverListFullName } = props;

    const driverOptions = R.map((item: Object) => {
      const { fullText } = G.getUserInfo(item);

      const value = G.getGuidFromObject(item);

      return { value, label: fullText };
    }, asyncDriverListFullName);

    let truckOptions = R.compose(
      R.map(({ guid, unitId }: Object) => ({ label: unitId, value: guid })),
      R.pathOr([], ['data']),
    )(asyncInitialData);

    const truckGuids = R.path(['values', GC.FIELD_TRUCK_GUIDS], props);

    if (R.and(G.isNotNilAndNotEmpty(asyncUnitIdList), G.isNotNilAndNotEmpty(truckGuids))) {
      const assignedToVendorTruckGuids = R.map(G.getValueFromObject, truckOptions);

      truckOptions = R.compose(
        R.concat(truckOptions),
        R.map((value: string) => ({
          value,
          [GC.FIELD_LABEL]: R.pathOr('_', [GC.FIELD_UNIT_ID], R.find(R.propEq(value, GC.FIELD_GUID), asyncUnitIdList)),
        })),
        R.filter((item: string) => G.notContain(item, assignedToVendorTruckGuids)),
      )(truckGuids);
    }

    return { optionsForSelect: { ...optionsForSelect, truckOptions, driverOptions } };
  }),
  lifecycle({
    componentDidMount() {
      const props = this.props;

      const { branchGuid, initialValues, getAsyncGetUnitIdList, getAsyncGetDriverListFullName } = props;

      G.callFunctionWithArgs(getAsyncGetDriverListFullName, { branchGuid });

      if (G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_TRUCK_GUIDS, initialValues))) {
        getAsyncGetUnitIdList({ branchGuid });
      }
    },
  }),
  pure,
);

const enhance = compose(
  connect(mapStateToProps),
  withVendorPayrollAccessorial,
);

const VendorPayrollCompensationForm = enhance(SimpleForm);

export default VendorPayrollCompensationForm;
