import * as R from 'ramda';
import React from 'react';
import { pure } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, ActionButton, CancelButton } from '../../../ui';
//////////////////////////////////////////////////

const ActionBtn = (props: Object) => (
  <ActionButton
    type='submit'
    zIndex={props.zIndex}
    height={props.height}
    m={R.or(props.m, '5px')}
    p={R.or(props.p, '8px 16px')}
    width={R.or(props.width, '45%')}
    borderRadius={props.borderRadius}
    fontSize={R.or(props.fontSize, 18)}
  >
    {R.or(props.actionText, G.getWindowLocale('actions:upload', 'Upload'))}
  </ActionButton>
);

const FooterBtns = pure((props: Object) => (
  <Flex mt='10px' zIndex={props.zIndex} width='100%' justifyContent={R.or(props.jc, 'space-between')}>
    <ActionBtn {...props} />
    <CancelButton
      type='button'
      zIndex={props.zIndex}
      height={props.height}
      m={R.or(props.m, '5px')}
      onClick={props.cancelAction}
      p={R.or(props.p, '8px 16px')}
      width={R.or(props.width, '45%')}
      borderRadius={props.borderRadius}
      fontSize={R.or(props.fontSize, 18)}
    >
      {R.or(props.cancelText, G.getWindowLocale('actions:cancel', 'Cancel'))}
    </CancelButton>
  </Flex>
));

export default FooterBtns;
