import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
// ui
import { Box, CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    openModalHandler: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <Box>
          <h1>Modal Content</h1>
        </Box>
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          version: 2,
          height: 200,
          movable: true,
          btnWidth: 100,
          boxShadow: 'none',
          withCloseIcon: true,
          title: 'Modal title',
          withBorderRadius: true,
          controlButtons: [
            {
              type: 'button',
              name: 'submit',
              action: closeModal,
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const ModalComponent = (props: Object) => {
  const description = '<Modal /> component provides a flexible and configurable modal window.';

  const usage = (
    <React.Fragment>
      <p>Configuration for the modal:</p>
      <ul>
        <li>Parameter `<i>p</i>` is a padding property for the modal content.</li>
        <li>
          Parameter `<i>options </i>` is additional options for the modal window:
          <ul>
            <li>Parameter `<i>title</i>` is the title of the modal window.</li>
            <li>Parameter `<i>footerZIndex</i>` is the Z-index for the modal footer.</li>
            <li>Parameter `<i>footerPadding</i>` is the padding for the modal footer.</li>
            <li>Set `<i>closeAction</i>` function that triggered on closing the modal.</li>
            <li>Parameter `<i>version</i>` is a version identifier for the modal window.</li>
            <li>Parameter `<i>footerJC</i>` allows justify-content for the modal footer.</li>
            <li>Parameter `<i>btnWidth</i>` is the width of buttons in the modal footer.</li>
            <li>Parameter `<i>footerPosition</i>` is the positioning for the modal footer.</li>
            <li>Parameter `<i>footerBottom</i>` is the bottom position for the modal footer.</li>
            <li>Parameter `<i>movable</i>` is used to enable or disable the modal`s draggable behavior.</li>
            <li>Parameter `<i>withBorderRadius</i>` determines whether the modal should have rounded corners or not.</li>
            <li>Parameter `<i>withCloseIcon</i>` specifies whether a close icon should be displayed in the modal header or not.</li>
            <li>Parameter `<i>outsideCloseButton</i>` specifies whether a close button should be displayed outside the modal container.</li>
            <li>
              Parameter `<i>controlButtons</i>` is the Array of buttons for modal control, each containing:
              <ul>
                <li>Parameter <i>type</i> is the type for the button.</li>
                <li>Parameter <i>name</i> the the name or label of the button.</li>
                <li>Set <i>action</i> function that triggered on button click.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </React.Fragment >
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='Modal'
      description={description}
      path='src/components/modal'
    >
      <CustomButton onClick={props.openModalHandler}>Open modal</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(ModalComponent));
