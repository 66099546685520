import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { FormGroupWrapper } from '../../../forms';
// ui
import { ReactSelect, RelativeBox } from '../../../ui';
// feature dispatch-planner
import Footer from './footer';
//////////////////////////////////////////////////

const getValue = (data: any) => data.map(({ value }: Object) => value);

const enhance = compose(
  withState('pickValue', 'setPickValue', []),
  withState('dropValue', 'setDropValue', []),
  withHandlers({
    handleSubmit: ({
      dropValue,
      pickValue,
      closeModal,
      handleSubmitAction,
    }: Object) => () => {
      closeModal();
      handleSubmitAction({
        dropTels: dropValue,
        pickupTels: pickValue,
      });
    },
    handleChangeDropSelect: ({ setDropValue }: Object) => (value: Array | string) => (
      setDropValue(getValue(value))
    ),
    handleChangePickSelect: ({ setPickValue }: Object) => (value: Array | string) => (
      setPickValue(getValue(value))
    ),
  }),
  pure,
);

const getOptions = (options: Array, filterValues: Array) => R.filter(
  ({ value }: Object) => G.isNilOrEmpty(R.find(
    (filterValue: string) => R.equals(value, filterValue),
    filterValues,
  )),
  options,
);

const SelectDrops = (props: Object) => (
  <RelativeBox mb={15} width={420}>
    <ReactSelect
      height={36}
      isMulti={true}
      name='dropValue'
      useMaxHeight={true}
      valueContainerMaxHeight={200}
      onChange={props.handleChangeDropSelect}
      options={getOptions(props.options, props.pickValue)}
      placeholder={G.getWindowLocale('titles:select-drops', 'Select Drops')}
    />
  </RelativeBox>
);

const SelectPickups = (props: Object) => (
  <RelativeBox width={420}>
    <ReactSelect
      height={36}
      isMulti={true}
      name='pickValue'
      useMaxHeight={true}
      valueContainerMaxHeight={200}
      onChange={props.handleChangePickSelect}
      options={getOptions(props.options, props.dropValue)}
      placeholder={G.getWindowLocale('titles:select-pickups', 'Select Pickups')}
    />
  </RelativeBox>
);

const SelectLoadsForTerminal = enhance((props: Object) => (
  <FormGroupWrapper>
    <SelectDrops {...props} />
    <SelectPickups {...props} />
    <Footer
      {...props}
      handleFormSubmit={(props.handleSubmit)}
      actionText={G.getWindowLocale('actions:use', 'Use')}
      cancelText={G.getWindowLocale('actions:cancel', 'Cancel')}
    />
  </FormGroupWrapper>
));

export default SelectLoadsForTerminal;
