import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import MessageCenter from '../../../components/message-center';
import { LocalLoader } from '../../../components/local-loader';
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
import ChatMessageForm from '../../dispatch-details-new/forms/chat-message-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// dispatch-board-new
import { withAsyncLoadChatMessages } from '../hocs/with-async-load-chat-messages';
//////////////////////////////////////////////////

const getRowActionsOptions = (props: Object) => {
  const { entity, handleEditMessage, handleRemoveMessage } = props;

  const iconColor = G.getTheme('colors.light.blue');
  const editIcon = I.pencil(iconColor);
  const removeIcon = I.remove(iconColor);
  const editTxtLocale = () => G.getWindowLocale('actions:edit', 'Edit');
  const deleteTxtLocale = () => G.getWindowLocale('actions:delete', 'Delete');
  const chatPermissions = [PC.TEL_ADMINISTRATION_EXECUTE];

  return [
    {
      frontIcon: editIcon,
      text: editTxtLocale(),
      permissions: chatPermissions,
      action: () => handleEditMessage(entity),
    },
    {
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      permissions: chatPermissions,
      action: () => handleRemoveMessage(entity),
    },
  ];
};

const enhance = compose(
  withFixedPopover,
  withAsyncLoadChatMessages,
  withHandlers({
    handleAddMessage: ({ loadGuid, loadType, getLoadChatMessagesRequest }: Object) => async ({ message }: Object) => {
      const authorUserGuid = G.getAmousCurrentUserGuidFromWindow();
      const isClo = G.isLoadTypeClo(loadType);
      const propName = G.ifElse(isClo, GC.FIELD_CLO_GUID, GC.FIELD_TEL_GUID);
      const endpoint = G.ifElse(isClo, endpointsMap.cloChatMessage, endpointsMap.telChatMessage);
      const data = {
        authorUserGuid,
        [propName]: loadGuid,
        [GC.FIELD_TEXT]: message,
      };
      await sendRequest('post', endpoint, { data });
      getLoadChatMessagesRequest({ loadGuid, loadType });
    },
    handleEditMessage: ({ loadType, openModal, closeModal, closeContent }: Object) => (entity: Object) => {
      const component = (
        <ChatMessageForm
          initialValues={entity}
          closeModal={closeModal}
          handleActionLoadChatMessage={async (data: Object) => {
            const endpoint = G.ifElse(
              G.isLoadTypeClo(loadType),
              endpointsMap.cloChatMessage,
              endpointsMap.telChatMessage,
            );
            sendRequest('put', endpoint, { data });
            closeModal();
          }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:edit-message', 'Edit Message'),
        },
      };
      closeContent();
      openModal(modal);
    },
    handleRemoveMessage: ({ loadType, openModal, closeModal, closeContent }: Object) => (entity: Object) => {
      const textLocale = G.getWindowLocale(
        'messages:chat-messages:remove-ref-confirmation-text',
        'Are you sure you want delete this chat message?',
      );
      const component = <ConfirmComponent textLocale={textLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: async () => {
                const endpoint = G.ifElse(
                  G.isLoadTypeClo(loadType),
                  endpointsMap.getCloChatMessageEndpoint,
                  endpointsMap.getTelChatMessageEndpoint,
                )(G.getGuidFromObject(entity));
                sendRequest('delete', endpoint);
                closeModal();
              },
            },
          ],
        },
      };
      closeContent();
      openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <ActionsElement version={2} options={getRowActionsOptions({ ...props, entity })} />
        ),
      })
    ),
  }),
  pure,
);

const AsyncChatMessages = enhance((props: Object) => {
  const { handleAddMessage, asyncChatMessages } = props;

  const sortedAsyncChatMessages = G.sortListByDate(
    R.or(asyncChatMessages, []), 'createdDate', false,
  );

  return (
    <LocalLoader
      width={350}
      height={400}
      localLoaderOpen={R.isNil(asyncChatMessages)}
    >
      <MessageCenter
        {...props}
        submitAction={handleAddMessage}
        entities={sortedAsyncChatMessages}
      />
    </LocalLoader>
  );
});

export default AsyncChatMessages;
