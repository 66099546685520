import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Table } from '../../../components/table';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
import { makeSelectReport, makeSelectUserColumnSettings, makeSelectUserList } from '../selectors';
//////////////////////////////////////////////////

const tableSettings = {
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  moreBtnCellWidth: 0,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  searchableTitles: false,
  allowSelectItems: false,
};

const TableComponent = ({ report, userList, columnSettings }: Object) => {
  const description = '<Table /> component is responsible for rendering tabular data.';

  const usage = (
    <ul>
      <li>Parameter `<i>loading</i>` indicates the loading state.</li>
      <li>Parameter `<i>totalCount</i>` is the total count of items.</li>
      <li>Parameter `<i>report</i>` is an object that contains the report details.</li>
      <li>Parameter `<i>itemList</i>` is an array of items to be displayed in the table.</li>
      <li>Parameter `<i>withResizableColumns</i>` enable resizable column functionality.</li>
      <li>Parameter `<i>pagination</i>` specifes pagination details like limit, offset, etc.</li>
      <li>Parameter `<i>tableTitleFilters</i>`  represents a collection or configuration of filters.</li>
      <li>Parameter `<i>handleTableTitleFilter</i>` is a function for managing and handling the filters.</li>
      <li>Parameter `<i>actionButtons </i>` represents buttons associated with a particular row component.</li>
      <li>Parameter `<i>hasSelectable</i>` determines whether the component has selectable behavior or not.</li>
      <li>Parameter `<i>useSearchableColumns </i>`  determines whether columns in the table are searchable.</li>
      <li>Parameter `<i>columnSettings</i>` represents settings related to column widths and configurations.</li>
      <li>Parameter `<i>filterProps</i>` is a set of properties intended to control the behavior of filters.</li>
      <li>Parameter `<i>allowSelectItems</i>` is a boolean flag that permits the selection of items in table.</li>
      <li>Parameter `<i>titleSortValues</i>` is a property that represents the values associated with sorting.</li>
      <li>Parameter `<i>tableSettings</i>` is the object that contains configurations for the table component.</li>
      <li>Parameter `<i>handleLoadMoreEntities</i>` is the function that involves pagination or fetching additional data.</li>
      <li>Parameter `<i>useNewTitleFilterInputs </i>` is a boolean flag that controls if use new filter title for inputs.</li>
      <li>Parameter `<i>allChecked </i>` represents if all items are selected within a group, collection, or specific set.</li>
    </ul>
  );

  const data = {
    report,
    tableSettings,
    columnSettings,
    loading: false,
    itemList: userList,
    withResizableColumns: true,
  };

  return (
    <ComponentStoryWrapper
      name='Table'
      usage={usage}
      description={description}
      path='src/components/table'
    >
      <Table {...data} />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  report: makeSelectReport(state),
  userList: makeSelectUserList(state),
  columnSettings: makeSelectUserColumnSettings(state),
});

export default connect(mapStateToProps, {})(TableComponent);
