import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const payTypeOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: GC.PAYMENT_TERM_OPTION_FACTORING_COMPANY,
    label: G.getWindowLocale('titles:factoring-company', 'Factoring Company'),
  },
  {
    value: GC.PAYMENT_TERM_OPTION_DIRECT_PAY,
    label: G.getWindowLocale('titles:direct-pay', 'Direct Pay'),
  },
];

const vendorPayTypeOptions = [
  ...payTypeOptions,
  {
    value: GC.PAYMENT_TERM_OPTION_INTERNAL_FACTORING,
    label: G.getWindowLocale('titles:internal-factoring', 'Internal Factoring'),
  },
];

const settings = {
  [GC.FIELD_LOCATION_NAME]: {
    name: 'titles:pay-to-name',
  },
  [GC.FIELD_PAY_TYPE]: {
    width: 150,
    type: 'select',
    name: 'titles:pay-type',
    options: 'payTypeOptions',
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_PAY_TYPE, data), GC.FIELD_VALUE)),
    )(vendorPayTypeOptions),
  },
  [GC.FIELD_ADDRESS]: {
    name: 'titles:address',
    type: 'addressAutocomplete',
    additionalInputWrapperStyles: { zIndex: 12 },
    locationMapper: (location: Object) =>
      R.assoc(GC.FIELD_ADDRESS, G.getPropFromObject(GC.FIELD_ADDRESS_1, location), location),
  },
  [GC.FIELD_ADDRESS_2]: {
    isRequired: false,
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 150,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 150,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    type: 'countrySelect',
    name: 'titles:country',
  },
  [GC.FIELD_START_DATE]: {
    width: 150,
    type: 'datePicker',
    name: 'titles:start-date',
    additionalInputWrapperStyles: { zIndex: 11 },
    calendarInputWrapperStyles: {
      width: '100%',
    },
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_ZIP]: G.yupStringRequired,
  [GC.FIELD_CITY]: G.yupStringRequired,
  [GC.FIELD_STATE]: G.yupStringRequired,
  [GC.FIELD_COUNTRY]: G.yupStringRequired,
  [GC.FIELD_ADDRESS]: G.yupStringRequired,
  [GC.FIELD_PAY_TYPE]: G.yupStringRequired,
  [GC.FIELD_START_DATE]: G.yupStringRequired,
  [GC.FIELD_LOCATION_NAME]: G.yupStringRequired,
});

const defaultInputWrapperStyles = {
  mb: 25,
  width: 280,
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    type = 'text',
    locationMapper,
    isRequired = true,
    calendarInputWrapperStyles,
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    type,
    options,
    fieldName,
    isRequired,
    locationMapper,
    label: R.of(Array, name),
    calendarInputWrapperStyles,
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...G.spreadUiStyles(additionalInputWrapperStyles),
    },
  })),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

export const payToLocationSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  singleItem: true,
  groupName: 'payToLocation',
  includeItemListToTablePropsMemoDeps: true,
  itemTitleArr: ['titles:pay-to-location', 'Pay To Location'],
  formGroupTitleArr: ['titles:pay-to-location', 'Pay To Location'],
  fieldsWrapperStyles: {
    width: 600,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // helpers
  makeOptionsForSelect: () => ({ payTypeOptions }),
  customRemoveHandler: ({ handleUpdateGeneralDetails }: Object) => handleUpdateGeneralDetails(
    { [GC.SYSTEM_OBJECT_PAY_TO_LOCATION]: null },
    { shouldCloseModal: true },
  ),
  customCreateOrUpdateHandler: ({ values, handleUpdateGeneralDetails }: Object) => handleUpdateGeneralDetails(
    { [GC.SYSTEM_OBJECT_PAY_TO_LOCATION]: values },
    { shouldCloseModal: true },
  ),
};

export const vendorPayToLocationsSettings = {
  ...R.omit(['customRemoveHandler', 'customCreateOrUpdateHandler'], payToLocationSettings),
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  endpoints: {
    list: 'vendorPayToLocation',
    remove: 'removeVendorPayToLocation',
    createOrUpdate: 'vendorPayToLocation',
  },
  // helpers
  makeOptionsForSelect: () => ({ payTypeOptions: vendorPayTypeOptions }),
};
