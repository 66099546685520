import * as R from 'ramda';
import React from 'react';
// components
import { ConnectToMailIntegration } from '../../../components/connect-to-mail-integration';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_REPLY_TO,
  smtpEncryptionTypeOptions,
  integrationMailConfigTypeOptions,
  getCommunicationDocumentFiltersOptions,
  getCommunicationNotificationObjectTypeOptions,
} from '../../../helpers/options';
// ui
import { Flex } from '../../../ui';
// feature configs
import { ReactSelectComponent } from '../ui';
import { getOptions } from './notification-group';
//////////////////////////////////////////////////

const MultiFilterlTitleComponent = ({
    notificationOptions,
    notificationTriggersFilter,
    notificationObjectTypeFilter,
    setNotificationTriggersFilter,
    setNotificationObjectTypeFilter,
  }: Object) => {
  const triggerNames = R.map(
    ({ trigger }: Object) => ({ label: trigger, value: trigger }),
    R.or(notificationOptions, []),
  );

  return (
    <Flex>
      <ReactSelectComponent
        value={notificationObjectTypeFilter}
        handleChange={setNotificationObjectTypeFilter}
        options={getCommunicationNotificationObjectTypeOptions()}
        placeholder={G.getWindowLocale('titles:filter-by-object-type', 'Filter By Object Type')}
      />
      <ReactSelectComponent
        options={getOptions(triggerNames)}
        value={notificationTriggersFilter}
        handleChange={setNotificationTriggersFilter}
        placeholder={G.getWindowLocale('titles:filter-by-triggers', 'Filter By Triggers')}
      />
    </Flex>
  );
};

const getValidNotificationValueFromTable = (entity: Object, fieldName: string) => G.ifElse(
  R.and(G.isNotNilAndNotEmpty(R.prop(fieldName, entity)), G.isArray(R.prop(fieldName, entity))),
  R.join(', ', R.map(G.getEnumLocale, R.prop(fieldName, entity))),
  G.getEnumLocale(R.prop(fieldName, entity)),
);

const generalTab = [
  {
    fields: [],
    showInherit: true,
    configType: 'dropdown',
    title: 'titles:decline-dispatch-reasons',
    addPopupTitle: 'titles:add-decline-reason',
    editPopupTitle: 'titles:edit-decline-reason',
    configName: GC.COMMUNICATION_DISPATCH_DECLINE_REASON_CODE,
  },
];

const notificationsTab = [
  {
    title: 'titles:notifications',
    fields: {
      [GC.COMMUNICATION_NOTIFY_ROUTE_EMAIL]: {
        type: 'input',
        name: 'titles:route-notification-email',
        nameForAttribute: GC.COMMUNICATION_NOTIFY_ROUTE_EMAIL,
      },
    },
  },
  {
    title: 'titles:sms-notification',
    fields: {
      [GC.COMMUNICATION_SMS_SENDER_TYPE]: {
        type: 'select',
        options: DEFAULT_REPLY_TO,
        name: 'titles:sender-type',
        nameForAttribute: GC.COMMUNICATION_SMS_SENDER_TYPE,
      },
      [GC.COMMUNICATION_SMS_BRANCH_SENDER_NAME]: {
        type: 'input',
        name: 'titles:sender-branch-phone',
        nameForAttribute: GC.COMMUNICATION_SMS_BRANCH_SENDER_NAME,
      },
    },
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:mail-templates',
    configName: GC.CUSTOM_MAIL_TEMPLATE_CONFIG,
    customConfigName: GC.CUSTOM_MAIL_TEMPLATE_CONFIG,
    fields: [
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_NAME,
      },
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_BRANCH_GUID, entity)),
        ),
      },
      {
        name: 'titles:trigger',
        nameForAttribute: GC.FIELD_TEMPLATE_TYPE,
        customLogic: (_: Object, { templateType }: Object) => (
          G.ifElse(
            G.isNotNilAndNotEmpty(templateType),
            G.getEnumLocale(G.getPropFromObject(GC.FIELD_NAME, templateType)),
            '',
          )
        ),
      },
      {
        tableColumnWith: 400,
        name: 'titles:mail-subject',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_SUBJECT,
      },
    ],
  },
  {
    showInherit: false,
    configType: 'custom',
    addPopupTitle: 'titles:add-notification',
    title: 'titles:notification-configuration',
    configName: GC.CUSTOM_ADD_NOTIFICATION_CONFIG,
    customConfigName: GC.CUSTOM_ADD_NOTIFICATION_CONFIG,
    additionalTitleComponent: MultiFilterlTitleComponent,
    fields: [
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_NAME,
      },
      {
        name: 'titles:roles',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ROLE,
        customLogic: (field: Object, entity: Object, props: Object) => {
          const listName = R.compose(
            R.filter(G.isNotNilAndNotEmpty),
            R.map((guid: string) => {
              const role = R.prop(guid, props.roleList);
              if (G.isNilOrEmpty(role)) return '';
              return role.name;
            }),
            R.prop(GC.FIELD_ROLE_GUIDS),
          )(entity);
          return R.join(', ', listName);
        },
      },
      {
        name: 'titles:triggers',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER,
        customLogic: (field: Object, { trigger }: Object) => (
          G.ifElse(
            G.isNotNilAndNotEmpty(trigger),
            G.getEnumLocale(trigger),
            '',
          )
        ),
      },
      {
        name: 'titles:object-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE,
        customLogic: (field: Object, { objectType }: Object) => (
          G.ifElse(
            G.isNotNilAndNotEmpty(objectType),
            G.getEnumLocale(objectType),
            '',
          )
        ),
      },
      {
        name: 'titles:notification-level',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL,
        customLogic: (field: Object, { warningLevel }: Object) => (
          G.ifElse(
            G.isNotNilAndNotEmpty(warningLevel),
            G.getEnumLocale(warningLevel),
            '',
          )
        ),
      },
      {
        name: 'titles:condition-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE,
        customLogic: (field: Object, { conditionType }: Object) => (
          G.ifElse(
            G.isNotNilAndNotEmpty(conditionType),
            G.getEnumLocale(conditionType),
            '',
          )
        ),
      },
      {
        name: 'titles:operation',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION,
        customLogic: (field: Object, { operation }: Object) => (
          G.ifElse(
            G.isNotNilAndNotEmpty(operation),
            G.getEnumLocale(operation),
            '',
          )
        ),
      },
      {
        name: 'titles:value',
        tableColumnWith: 400,
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE,
        customLogic: (field: Object, entity: Object, props: Object) => {
          const { value, trigger, conditionType } = entity;

          if (R.includes(
            R.or(
              GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_EARLY_DATE,
              GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_DETENTION,
            ),
            R.of(Array, trigger),
          )) return R.prop(GC.FIELD_VALUE, entity);

          if (R.equals(
            conditionType,
            GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_STATUS_CODE,
          )) {
            const valueSplit = R.split(',', value);
            const mapMessage = R.filter(
              G.isNotNilAndNotEmpty,
              R.map(
              ({ guid, parentGuid, displayedValue }: Object) => {
                if (R.includes(R.or(parentGuid, guid), valueSplit)) {
                  return displayedValue;
                }
              },
              props.statusMessages,
            ));

            return R.join(', ', mapMessage);
          }

          if (R.and(G.isString(value), R.includes(conditionType, [
            GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CLO_STATUS,
            GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TEL_STATUS,
            GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_MACRO_POINT_STATUS,
            GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_CARRIER_RATE_TRAILER_UPDATE_ACTION,
          ]))) {
            return R.compose(
              R.join(', '),
              R.map(G.getEnumLocale),
              R.split(','),
            )(value);
          }

          return G.getEnumLocale(entity.value);
        },
      },
      {
        name: 'titles:message-types',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES,
        customLogic: (_: Object, entity: Object) =>
          getValidNotificationValueFromTable(entity, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES),
      },
      {
        name: 'titles:email-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES,
        customLogic: (_: Object, entity: Object) =>
          getValidNotificationValueFromTable(entity, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES),
      },
      {
        name: 'titles:static-emails',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_STATIC_EMAILS,
        customLogic: (_: Object, entity: Object) =>
          getValidNotificationValueFromTable(entity, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_STATIC_EMAILS),
      },
      {
        name: 'titles:references',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES,
        customLogic: (_: Object, entity: Object, props: Object) => {
          const data = R.prop(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES, entity);

          if (G.isNilOrEmpty(data)) return '';

          const { objectType } = entity;
          const referenceTypesIndexed = R.compose(
            R.map(R.prop('label')),
            R.indexBy(R.prop(GC.FIELD_VALUE)),
            R.pathOr(
              [],
              [
                'configOptions',
                G.ifElse(
                  R.equals(objectType, GC.NOTIFICATION_OBJECT_TYPE_TEL),
                  'telReferenceTypes',
                  'cloReferenceTypes',
                ),
              ],
            ),
          )(props);

          return R.compose(
            R.join(', '),
            R.filter(G.isNotNil),
            R.map((guid: string) => R.pathOr(null, [guid], referenceTypesIndexed)),
          )(data);
        },
      },
      {
        name: 'titles:notification-sound',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ALARM_ENABLED,
      },
    ],
  },
];

const statusMessagesTab = [
  {
    fields: [],
    showInherit: true,
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:status-message-reason',
    configName: GC.COMMUNICATION_REASON_CODE,
  },
  {
    showInherit: true,
    configType: 'custom',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:status-message-codes',
    configName: GC.CUSTOM_STATUS_MESSAGES_CONFIG,
    customConfigName: GC.CUSTOM_STATUS_MESSAGES_CONFIG,
    fields: [
      {
        name: 'titles:stored-value',
        nameForAttribute: GC.FIELD_STORED_VALUE,
      },
      {
        name: 'titles:default-value',
        nameForAttribute: GC.FIELD_DISPLAYED_VALUE,
      },
      {
        name: 'titles:stop-status',
        nameForAttribute: GC.FIELD_STATUS_TYPE,
        customLogic: (field: Object, { statusType }: Object) => (
          G.ifElse(
            G.isNotNilAndNotEmpty(statusType),
            G.getEnumLocale(statusType),
            '',
          )
        ),
      },
      {
        name: 'titles:warning-level',
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_WARNING_LEVEL,
      },
      {
        name: 'titles:tracking',
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_TRACKING,
      },
      {
        name: 'titles:reason-required',
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_REASON_REQUIRED,
      },
      {
        options: '',
        name: 'titles:status-reason',
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_REASON_CODES,
      },
    ],
  },
];

const mailsTab = [
  {
    title: 'titles:mails',
    fields: {
      [GC.COMMUNICATION_BRANCH_EMAIL]: {
        type: 'input',
        name: 'titles:branch-email',
        nameForAttribute: GC.COMMUNICATION_BRANCH_EMAIL,
        additionalComponent: ({ initialValues, currentCommunicationMails }: Object) =>
          <ConnectToMailIntegration
            mr={15}
            fromConfig={true}
            configName={GC.COMMUNICATION_BRANCH_EMAIL}
            shouldNotGetConnectedToGmailOnDidMount={true}
            email={G.getPropFromObject(GC.COMMUNICATION_BRANCH_EMAIL, initialValues)}
            currentEmail={G.getPropFromObject(GC.COMMUNICATION_BRANCH_EMAIL, currentCommunicationMails)}
          />,
      },
      [GC.COMMUNICATION_BRANCH_SIGNATURE]: {
        type: 'input',
        name: 'titles:branch-signature',
        nameForAttribute: GC.COMMUNICATION_BRANCH_SIGNATURE,
      },
      [GC.COMMUNICATION_MAILING_DEFAULT_REPLY_TO]: {
        type: 'select',
        options: DEFAULT_REPLY_TO,
        name: 'titles:default-reply-to',
        nameForAttribute: GC.COMMUNICATION_MAILING_DEFAULT_REPLY_TO,
      },
      [GC.COMMUNICATION_MAILING_LOCK_DEFAULT_REPLY_TO]: {
        type: 'switcher',
        name: 'titles:lock-default-reply-to',
        nameForAttribute: GC.COMMUNICATION_MAILING_LOCK_DEFAULT_REPLY_TO,
      },
      [GC.COMMUNICATION_EMAIL_USE_BILL_TO_CONTACT_EMAIL]: {
        type: 'switcher',
        name: 'titles:bill-to-contact-email',
        nameForAttribute: GC.COMMUNICATION_EMAIL_USE_BILL_TO_CONTACT_EMAIL,
      },
      [GC.COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL]: {
        type: 'text',
        validate: G.isEmptyOrEmail,
        name: 'titles:accounting-department-email',
        nameForAttribute: GC.COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL,
        additionalComponent: ({ initialValues, currentCommunicationMails }: Object) =>
          <ConnectToMailIntegration
            mr={15}
            shouldNotGetConnectedToGmailOnDidMount={true}
            configName={GC.COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL}
            email={G.getPropFromObject(GC.COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL, initialValues)}
            currentEmail={G.getPropFromObject(GC.COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL, currentCommunicationMails)}
          />,
      },
    },
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:integration-mails',
    configName: GC.CUSTOM_INTEGRATION_MAIL_CONFIG,
    customConfigName: GC.CUSTOM_INTEGRATION_MAIL_CONFIG,
    fields: [
      {
        tableColumnWith: 100,
        name: 'titles:active',
        nameForAttribute: GC.FIELD_ENABLED,
      },
      {
        name: 'titles:used',
        tableColumnWith: 100,
        nameForAttribute: GC.FIELD_USED,
      },
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_BRANCH_GUID, entity)),
        ),
      },
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(integrationMailConfigTypeOptions()),
      },
      {
        name: 'titles:default-email',
        nameForAttribute: GC.FIELD_DEFAULT_EMAIL,
      },
      {
        name: 'titles:client-id',
        nameForAttribute: GC.FIELD_CLIENT_ID,
      },
      {
        name: 'titles:tenant-id',
        nameForAttribute: GC.FIELD_TENANT_ID,
      },
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_USERNAME,
      },
      {
        name: 'titles:host',
        nameForAttribute: GC.FIELD_HOST,
      },
      {
        name: 'titles:port',
        nameForAttribute: GC.FIELD_PORT,
      },
      {
        name: 'titles:smtp-encryption-type',
        nameForAttribute: GC.FIELD_SMTP_ENCRYPTION_TYPE,
        customLogic: (_: any, { smtpEncryptionType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(smtpEncryptionType, GC.FIELD_VALUE)),
        )(smtpEncryptionTypeOptions()),
      },
      {
        name: 'titles:disable-ssl-certificate-validation',
        nameForAttribute: GC.FIELD_DISABLE_SSL_CERTIFICATE_VALIDATION,
      },
    ],
  },
];

const documentsTab = [
  {
    fields: [],
    showInherit: true,
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:document-types',
    configName: GC.COMMUNICATION_DOCUMENT_TYPE,
  },
  {
    fields: [],
    showInherit: true,
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:branch-document-types',
    configName: GC.COMMUNICATION_BRANCH_DOCUMENT_TYPE,
  },
  {
    showInherit: true,
    configType: 'custom',
    title: 'titles:document-sending',
    configName: GC.CUSTOM_DOCUMENT_SENDING_CONFIG,
    customConfigName: GC.CUSTOM_DOCUMENT_SENDING_CONFIG,
    fields: [
      {
        name: 'titles:document-type',
        nameForAttribute: GC.FIELD_DOCUMENT_TYPE_NAME,
      },
      {
        name: 'titles:sending-type',
        nameForAttribute: GC.FIELD_SENDING_TYPE,
        customLogic: (_: any, { sendingType }: Object) => G.getEnumLocale(sendingType),
      },
      {
        type: 'number',
        name: 'titles:expiration-days',
        nameForAttribute: GC.FIELD_EXPIRATION_DAYS,
      },
    ],
  },
  {
    showInherit: false,
    configType: 'custom',
    title: 'titles:documents',
    addPopupTitle: 'titles:add-doc',
    configName: GC.CUSTOM_DOCUMENT_CONFIG,
    editPopupTitle: 'titles:edit-document',
    customConfigName: GC.CUSTOM_DOCUMENT_CONFIG,
    editEntPerm: [PC.CUSTOM_DOCUMENT_TEMPLATES],
    additionalTitleComponent: ({ documentsFilter, setDocumentsFilter }: Object) =>
      <ReactSelectComponent
        value={documentsFilter}
        handleChange={setDocumentsFilter}
        options={getCommunicationDocumentFiltersOptions()}
        placeholder={G.getWindowLocale('titles:filter-by-data-source', 'Filter By Data Source')}
      />,
    fields: [
      {
        type: 'file',
        width: '410px',
        name: 'titles:file',
        validators: [G.required],
        nameForAttribute: 'fileName',
        placeholder: 'actions:upload',
      },
      {
        isEnum: true,
        type: 'select',
        validators: [G.required],
        tableColumnWith: '200px',
        name: 'titles:data-source',
        nameForAttribute: 'dataSource',
        options: [
          '',
          GC.DOCUMENT_PRINTABLE_SECTION_ROUTE,
          GC.DOCUMENT_PRINTABLE_SECTION_CLO,
          GC.DOCUMENT_PRINTABLE_SECTION_TEL,
          GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL,
          GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE,
          GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DRIVER,
          GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK,
          GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER,
          GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE,
        ],
      },
      {
        type: 'select',
        name: 'titles:type',
        options: 'documentType',
        tableColumnWith: '250px',
        validators: [G.required],
        nameForAttribute: 'documentTypeGuid',
      },
      {
        type: 'text',
        name: 'titles:name',
        tableColumnWith: '250px',
        nameForAttribute: 'name',
        validators: [G.required, G.curriedIsMinMax(2, 40)],
      },
      {
        name: 'titles:customer',
        tableColumnWith: '250px',
        nameForAttribute: GC.FIELD_CUSTOMER_GUID,
        customLogic: (_: any, entity: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_CUSTOMER_GUID, entity)),
        ),
      },
      {
        type: 'select',
        validators: [G.required],
        tableColumnWith: '200px',
        nameForAttribute: 'format',
        name: 'titles:document-format',
        options: ['', GC.FILE_FORMAT_PDF, GC.FILE_FORMAT_EXCEL],
      },
      {
        type: 'text',
        name: 'titles:notes',
        tableColumnWith: '400px',
        nameForAttribute: 'notes',
        validators: G.curriedIsMinMax(2, 250),
      },
      {
        options: [],
        isEnum: true,
        type: 'select',
        validators: [G.required],
        tableColumnWith: '250px',
        name: 'titles:printable-section',
        nameForAttribute: 'printableSection',
      },
      {
        isEnum: true,
        tableColumnWith: '200px',
        name: 'titles:specific-document-features',
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_SPECIFIC_DOCUMENT_FEATURES,
      },
    ],
  },
];

const ConfigCommunicationGroup = {
  generalTab,
  notificationsTab,
  statusMessagesTab,
  mailsTab,
  documentsTab,
};

export {
  ConfigCommunicationGroup,
};
