import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  renderComponent,
  withPropsOnChange } from 'react-recompose';
// component
import { TextComponent } from '../../components/text';
import LoaderComponent from '../../components/loader';
// features
import { makeSelectAuthData } from '../auth/selectors';
import { FormLogo, FormWrapper, FormContainer } from '../auth/ui';
// ui
import { Flex, StyledLink } from '../../ui';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { makeSelectInitialSplashScreen } from '../../common/selectors';
// carrier
import { AmousBg, ResponseBox } from './ui';
import {
  makeSelectMessage,
  makeSelectStatusReason,
  makeSelectPreviousPathName } from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withState('infoPanelIsOpen', 'toggleInfoPanelIsOpen', true),
  withPropsOnChange(['splashScreenSetting'], (props: Object) => {
    props.toggleInfoPanelIsOpen(G.getOrElse(props.splashScreenSetting, 'panelExpanded', true));
  }),
  branch(
    (props: Object) => R.not(props.splashScreenSetting.isLoaded),
    renderComponent(LoaderComponent),
  ),
  pure,
);

const CarrierResponsePage = (props: Object) => {
  const { status, path, splashScreenSetting } = props;
  const viewMessage = (pathPage: string) => {
    switch (pathPage) {
      case GC.ROUTE_PATH_CARRIERS_ACCEPT:
        return G.getWindowLocale('messages:success:load-accepted', 'Load Accepted');
      case GC.ROUTE_PATH_CARRIERS_DECLINE:
        return G.getWindowLocale('messages:success:load-declined', 'Load Declined');
      case GC.ROUTE_PATH_CARRIERS_ADD_MESSAGE_PAGE:
        return G.getWindowLocale('messages:success:message-was-added', 'Status Message Added Successfully');
      default:
        return '';
    }
  };

  return (
    <FormWrapper
      currentTheme='light'
      bgImage={R.pathOr(AmousBg, ['firstBackgroundImageUrl'], splashScreenSetting)}
    >
      <FormContainer width='100%'>
        <ResponseBox
          alignItems='center'
          flexDirection='column'
          justifyContent='space-beetwen'
          color={G.getTheme(G.ifElse(
            G.isTrue(status),
            'colors.light.green',
            'colors.light.mainRed',
          ))}
        >
          <Flex
            width={1}
            height='max-content'
            flexDirection='column'
            justifyContent='space-around'
          >
            <FormLogo mb='30px'>
              <img
                alt='Logo'
                height='110px'
                src={R.or(splashScreenSetting.logoUrl, G.getNewLogoLink('light'))}
              />
            </FormLogo>
            <TextComponent
              width={1}
              mb='20px'
              fontSize={26}
              maxWidth='360px'
              whiteSpace='pre-wrap'
            >
              {G.ifElse(status, viewMessage(path), props.message)}
            </TextComponent>
          </Flex>
          <StyledLink
            width={1}
            p='0 15px'
            height='42px'
            color='white'
            target='_blank'
            fontSize='18px'
            cursor='pointer'
            lineHeight='42px'
            fontWeight='bold'
            textAlign='center'
            href='https://amousinternational.com/'
            bg={G.getTheme('colors.light.mainRed')}
          >
            {G.getWindowLocale('actions:power-of-tms', 'Powered by Amous TMS')}
          </StyledLink>
        </ResponseBox>
      </FormContainer>
    </FormWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  auth: makeSelectAuthData(state),
  message: makeSelectMessage(state),
  status: makeSelectStatusReason(state),
  path: makeSelectPreviousPathName(state),
  splashScreenSetting: makeSelectInitialSplashScreen(state),
}));

export default connect(mapStateToProps)(enhance(CarrierResponsePage));
