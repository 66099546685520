import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// charts
import { CHART_CONFIGS_FORM } from './constants';
//////////////////////////////////////////////////

const selectFormStore = (chart: Object) => chart.form;
const selectChartStore = (chart: Object) => chart.charts;

const makeSelectReportStatus = () => createSelector(
  selectChartStore,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  selectChartStore,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  selectChartStore,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  selectChartStore,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectItemList = () => createSelector(
  selectChartStore,
  ({ itemList }: Object) => R.values(itemList),
);

const makeSelectPagination = () => createSelector(
  selectChartStore,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  selectChartStore,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  selectChartStore,
  ({ loading }: Object) => loading,
);

const makeSelectRequestStatus = () => createSelector(
  selectChartStore,
  ({ requestPending }: Object) => requestPending,
);

const makeSelectCurrentChart = () => createSelector(
  selectChartStore,
  ({ currentChart }: Object) => currentChart,
);

const makeSelectPreviewStatus = () => createSelector(
  selectChartStore,
  ({ previewPending }: Object) => previewPending,
);

const makeSelectPreviewData = () => createSelector(
  selectChartStore,
  ({ previewData }: Object) => previewData,
);

const makeSelectChartConfigs = () => createSelector(
  selectChartStore,
  ({ chartConfigs }: Object) => ({
    collectionType: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      chartConfigs, GC.UI_CHART_COLLECTIONS_TYPE, true
    ),
  }),
);

const makeSelectForm = () => createSelector(
  selectFormStore,
  (form: Object) => form[CHART_CONFIGS_FORM],
);

export {
  makeSelectForm,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectPreviewData,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectChartConfigs,
  makeSelectCurrentChart,
  makeSelectPreviewStatus,
  makeSelectRequestStatus,
  makeSelectAvailableReports,
};
