import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// gps integration config
export const removeGpsIntegrationConfigSuccess = createAction('removeGpsIntegrationConfigSuccess');
export const removeGpsIntegrationConfigRequest = createAction('removeGpsIntegrationConfigRequest');
export const getGpsIntegrationConfigListSuccess = createAction('getGpsIntegrationConfigListSuccess');
export const getGpsIntegrationConfigListRequest = createAction('getGpsIntegrationConfigListRequest');
export const createOrUpdateGpsIntegrationConfigRequest = createAction('createOrUpdateGpsIntegrationConfigRequest');
export const createOrUpdateGpsIntegrationConfigSuccess = createAction('createOrUpdateGpsIntegrationConfigSuccess');
// onboarding integration config
export const removeOnboardingIntegrationConfigRequest = createAction('removeOnboardingIntegrationConfigRequest');
export const getOnboardingIntegrationConfigListSuccess = createAction('getOnboardingIntegrationConfigListSuccess');
export const createOrUpdateOnboardingIntegrationConfigRequest =
  createAction('createOrUpdateOnboardingIntegrationConfigRequest');
