import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
import { importTypesWithCarrierRatePrice } from '../../new-import/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_STATUS]: {
    width: 150,
    type: 'enum',
    name: 'titles:status',
  },
  [GC.FIELD_BRANCH_NAME]: {
    width: 150,
    name: 'titles:branch',
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    name: 'titles:import-type',
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_TYPE, data), GC.FIELD_VALUE)),
    )(importTypesWithCarrierRatePrice),
  },
  importerByLoginId: {
    width: 200,
    name: 'titles:importer',
  },
  totalRows: {
    width: 150,
    name: 'titles:total-rows',
  },
  created: {
    width: 150,
    name: 'titles:created',
  },
  updated: {
    width: 150,
    name: 'titles:updated',
  },
  failed: {
    width: 150,
    name: 'titles:failed',
  },
  errors: {
    width: 150,
    name: 'titles:errors',
  },
  startDate: {
    width: 250,
    type: 'date',
    name: 'titles:start-date',
  },
  endDate: {
    width: 250,
    type: 'date',
    name: 'titles:end-date',
  },
};

export const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_STATUS },
    { sequence: 2, name: GC.FIELD_BRANCH_NAME },
    { sequence: 3, name: GC.FIELD_TYPE },
    { sequence: 4, name: 'importerByLoginId' },
    { sequence: 5, name: 'startDate' },
    { sequence: 6, name: 'endDate' },
    { sequence: 7, name: 'totalRows' },
    { sequence: 8, name: 'created' },
    { sequence: 9, name: 'updated' },
    { sequence: 10, name: 'failed' },
    { sequence: 11, name: 'errors' },
  ],
};

export const expandedDetailsTableSettings = {
  rowHeight: 30,
  titleRowHeight: 30,
  useMainColors: true,
  allowSelectItems: false,
  checkBoxCellWidth: '0px',
  tableRowHeight: 'max-content',
};

export const expandedDetailsReport = {
  fields: [
    { sequence: 1, name: GC.FIELD_ID },
    { sequence: 2, name: 'index' },
    { sequence: 2, name: 'failedFields' },
    { sequence: 3, name: 'error' },
  ],
};

export const expandedDetailsColumnSettings = {
  [GC.FIELD_ID]: {
    width: 150,
    name: 'titles:id',
  },
  index: {
    width: 150,
    name: 'titles:row',
  },
  failedFields: {
    width: 400,
    name: 'titles:failed-fields',
    customComponent: ({ data }: Object) => {
      const failedFields = R.pathOr([], ['error', 'invalidFields'], data);

      if (G.isNilOrEmpty(failedFields)) return '';

      return (
        <div>
          {failedFields.map(({ message, fieldName }: Object, index: number) => (
            <TextComponent
              mt='5px'
              key={index}
              display='block'
              withEllipsis={true}
              title={`${fieldName}: ${message}`}
            >
              {fieldName}: {message}
            </TextComponent>
          ))}
        </div>
      );
    },
  },
  error: {
    width: 300,
    name: 'titles:errors',
    customComponent: ({ data }: Object) => {
      const errors = R.path(['error', GC.FIELD_ERRORS], data);
      const errorMessage = R.path(['error', GC.FIELD_MESSAGE], data);

      if (G.isNilOrEmpty(errors)) return errorMessage;
      const errorMessages = R.compose(
        R.join(', '),
        R.filter(G.isNotNilAndNotEmpty),
        R.prepend(errorMessage),
      )(errors);

      return errorMessages;
    },
  },
};
