// report-common
import { getReportActions } from '../../../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  setTableTitleFilter,
  updateReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

export {
  // report
  setReports,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  setTableTitleFilter,
  updateReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
};
