import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Label } from '../../../../components/label';
import { Filter } from '../../../../components/filter';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable } from '../../../../components/form-group-table';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { FormGroupTitleWrapper } from '../../../../forms';
// icons
import * as I from '../../../../svgs';
// ui
import { SimpleWrapper } from '../../../../ui';
// feature fleet/driver
import * as LC from '../constants';
import DriverCompensation from './compensation-form';
import { CustomFormGroupTitle, NormalLineHeight } from '../ui';
import { getCompFilterParams } from '../settings/filter-params';
import { compensationSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const renderCustomTitle = (title: Object, withFilter: Object = false) => (props: Object) => (
  <FormGroupTitleWrapper zIndex={15}>
    <CustomFormGroupTitle>
      <Label
        endAction={props.handleAddClick}
        endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
      >
        {title}
      </Label>
      {
        withFilter
        && (
          <NormalLineHeight>
            <SimpleWrapper mr='45px' color={G.getTheme('downFilter.textColor')}>
              <Filter
                type={props.reportType}
                setFilterParam={props.setQuickFilterParams}
                filterColor={G.getTheme('icons.iconColorWhite')}
                filterAction={props.getQuickFilteredListRequest}
                filterBy={G.setFilterByParams(props.refList, getCompFilterParams)}
              />
            </SimpleWrapper>
          </NormalLineHeight>
        )
      }
    </CustomFormGroupTitle>
  </FormGroupTitleWrapper>
);

const CompensationFormGroupTable = ({
  reportType,
  compensations,
  collapsedGroup,
  setQuickFilterParams,
  handleEditCompensation,
  handleDeleteCompensation,
  handleClickAddCompensation,
  getQuickFilteredListRequest,
}: Object) => (
  <FormGroupTable
    reportType={reportType}
    fields={collapsedGroup}
    entities={compensations}
    entitiesFields={compensationSettings}
    handleEditRow={handleEditCompensation}
    handleDeleteRow={handleDeleteCompensation}
    setQuickFilterParams={setQuickFilterParams}
    handleAddClick={handleClickAddCompensation}
    groupName={LC.DRIVER_GROUP_NAME_COMPENSATION}
    getQuickFilteredListRequest={getQuickFilteredListRequest}
    customPanel={renderCustomTitle(
      G.getWindowLocale('titles:compensation-details', 'Compensation Details'),
      true,
    )}
  />
);

const setInitialValue = (entity: Object) => {
  const valuesForRequest = R.pick(
    GC.GROUPED_FIELDS.DRIVER_COMPENSATION_MAIN_FIELDS,
    entity,
  );
  R.compose(
    R.forEachObjIndexed((value: string, key: string) => {
      valuesForRequest[`${LC.FIELD_DRIVER_COMPENSATION_DESTINATION}:${key}`] = value;
    }),
    R.pick(GC.GROUPED_FIELDS.DRIVER_COMPENSATION_LOCATIONS_FIELDS),
  )(entity.destination);
  R.compose(
    R.forEachObjIndexed((value: string, key: string) => {
      valuesForRequest[`${LC.FIELD_DRIVER_COMPENSATION_ORIGIN}:${key}`] = value;
    }),
    R.pick(GC.GROUPED_FIELDS.DRIVER_COMPENSATION_LOCATIONS_FIELDS),
  )(entity.origin);

  return valuesForRequest;
};

const enhance = compose(
  withHandlers({
    handleClickAddCompensation: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        defaultCurrency,
        createCompensationAction,
      } = props;

      const initialValues = { [GC.FIELD_CURRENCY]: defaultCurrency };
      const component = (
        <DriverCompensation
          editMode={false}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          initialValues={initialValues}
          compensationAccessorials={false}
          submitAction={createCompensationAction}
          driverGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
          branchGuid={R.path(['initialValues', GC.FIELD_BRANCH_GUID], props)}
          configsNamesArray={[GC.GENERAL_MODE_TRANSPORTATION, GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]}
        />
      );
      const modal = {
        p: '0px',
        component,
        options: {
          width: 1000,
          height: 'auto',
          title: G.getWindowLocale('titles:add-compensation-details', 'Add Compensation Details'),
        },
      };
      openModal(modal);
    },
    handleEditCompensation: (props: Object) => (entity: Object) => {
      const {
        form,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        accessorialsConfigs,
        compensationAccessorials,
        updateCompensationAction,
      } = props;

      const isVendor = R.equals(form, 'EDIT_VENDOR_DETAIL');

      const component = (
        <DriverCompensation
          editMode={true}
          isVendor={isVendor}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          initialValues={setInitialValue(entity)}
          submitAction={updateCompensationAction}
          accessorialsConfigs={accessorialsConfigs}
          compensationGuid={G.getGuidFromObject(entity)}
          compensationAccessorials={compensationAccessorials}
          driverGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
          branchGuid={R.path(['initialValues', GC.FIELD_BRANCH_GUID], props)}
          configsNamesArray={[GC.GENERAL_MODE_TRANSPORTATION, GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]}
        />
      );
      const modal = {
        p: '0px',
        component,
        options: {
          width: 1000,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-compensation-details', 'Edit Compensation Details'),
        },
      };
      openModal(modal);
    },
    handleDeleteCompensation: ({ openModal, removeCompensationAction }: Object) => ({ guid, name }: Object) => {
      const textLocale = G.getWindowLocale(
        'messages:fleet:driver:delete-compensation',
        'Are you sure you want delete Compensation',
      );
      const component = <ConfirmComponent name={name} textLocale={textLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeCompensationAction(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

export default enhance(CompensationFormGroupTable);
