import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import {
  updatePostedShipmentStateRequest,
  deletePostedShipmentStateRequest,
} from '../../../../common/actions';
// forms
import { PostTelToLoadboardsWithAsyncTELData } from '../../../../forms/forms/post-tel-to-loadboard-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////


export const withPostToLoadBoard = compose(
  withHandlers({
    handlePostTELToLoadBoard: (props: Object) => () => {
      const { load, openModal, closeModal } = props;

      const component = (
        <PostTelToLoadboardsWithAsyncTELData
          width={500}
          cancelAction={closeModal}
          guids={R.of(Array, G.getGuidFromObject(load))}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadBoardTableGroupRowActions = compose(
  connect(null, {
    updatePostedShipmentStateRequest,
    deletePostedShipmentStateRequest,
  }),
  withHandlers({
    handleRePostTelToLoadBoard: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        updatePostedShipmentStateRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      updatePostedShipmentStateRequest({
        [GC.FIELD_GUID]: G.getGuidFromObject(entity),
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(load),
      });
    },
    handleRemoveTELFromLoadBoard: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        deletePostedShipmentStateRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      return deletePostedShipmentStateRequest({
        [GC.FIELD_GUID]: G.getGuidFromObject(entity),
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(load),
      });
    },
  }),
  pure,
);
