import React from 'react';
import * as R from 'ramda';
// components
import { Label } from '../../components/label';
import { Filter } from '../../components/filter';
// forms
import { FormGroupTitleWrapper } from '../../forms';
import { SelectDropdownForm } from '../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, SimpleWrapper } from '../../ui';
// feature carrier
import * as LC from './constants';
import { CustomFormGroupTitle } from './ui';
import { getCarrierRateFilterParams } from './settings/filter-params';
//////////////////////////////////////////////////

export const getSelectDropDownFormModal = (options: Object, componentProps: Object) => ({
  p: 15,
  options,
  component: <SelectDropdownForm {...componentProps} />,
});

export const getAdditionBtns = (props: Object) => {
  const { handleGoBack, handleSubmitAndStay } = props;

  let buttons = R.of(
    Array,
    {
      type: 'button',
      handleClick: handleSubmitAndStay,
      btnText: G.getWindowLocale('actions:update', 'Update'),
    },
  );

  if (G.isFunction(handleGoBack)) {
    buttons = R.append(
      {
        type: 'button',
        handleClick: handleGoBack,
        bgColor: G.getTheme('colors.light.black'),
        btnText: G.getWindowLocale('actions:go-back', 'Go Back'),
      },
      buttons,
    );
  }

  return buttons;
};

export const setOptions = (props: Object, field: Object) => {
  const { carrierConfigs } = props;

  const { options, useOptionsFromProps } = field;

  if (R.and(G.isString(options), G.isTrue(useOptionsFromProps))) return R.pathOr([], [options], props);

  if (G.isString(options)) return R.pathOr([], [options], carrierConfigs);

  return options;
};

export const setDisabled = (handler: any, values: Object) => {
  if (R.isNil(handler)) return false;

  return handler(values);
};

export const isActiveTabCarrierContract = R.equals('carrierContract');

export const isActiveTabCustomerContract = R.equals('customerContract');

export const getFormTitle = (props: Object) => {
  const { activeTab, activePage, formValues, initialValues, carrierContract, customerContract } = props;

  if (R.and(G.isNotNil(carrierContract), isActiveTabCarrierContract(activeTab))) {
    return carrierContract.name;
  }

  if (R.and(G.isNotNil(customerContract), isActiveTabCustomerContract(activeTab))) {
    return customerContract.name;
  }

  if (R.equals(activePage, 'terminal')) {
    return `${G.getWindowLocale('titles:terminal', 'Terminal:')} ${R.pathOr('', ['terminal', 'name'], formValues)}`;
  }

  return R.propOr('', 'name', initialValues);
};

export const CustomTitle = (props: Object) => {
  const whiteColor = G.getTheme('colors.white');
  const {
    title,
    refList,
    entities,
    withFilter,
    contractGuid,
    handleAddClick,
    ratePriceImport,
    ratePriceExport,
    setQuickFilterParams,
    handleSetQuickFilter,
    shouldSubmitFilterForm,
    handleOpenRatePriceImport,
    handleRemoveCarrierContractRates,
    createCarrierRatePriceExportRequest,
  } = props;

  const selectedEntities = R.compose(
    R.map(R.prop(GC.FIELD_GUID)),
    R.filter(R.prop('selected')),
  )(entities);

  return (
    <FormGroupTitleWrapper zIndex='11'>
      <CustomFormGroupTitle>
        <Flex>
          <Label
            endAction={handleAddClick}
            endIcon={I.plusRound(whiteColor)}
          >
            {title}
          </Label>
          <Label
            ml={10}
            endIcon={I.trash(whiteColor)}
            endAction={() => handleRemoveCarrierContractRates(selectedEntities)}
          >
            {G.getWindowLocale('titles:delete-contract-rates', 'Delete Contract Rates')} ({R.length(selectedEntities)})
          </Label>
        </Flex>
        <Flex>
          {
            ratePriceExport &&
            <Flex
              mr={30}
              cursor='pointer'
              onClick={() => createCarrierRatePriceExportRequest(contractGuid)}
            >
              {I.xls(whiteColor, 20, 20)}
              <Box ml={15} color={whiteColor}>
                {G.getWindowLocale('actions:export-exl', 'Generate Excel')}
              </Box>
            </Flex>
          }
          {
            ratePriceImport &&
            <Flex
              mr={30}
              cursor='pointer'
              onClick={handleOpenRatePriceImport}
            >
              {I.downloadDocument(whiteColor, 18, 20)}
              <Box ml={15} color={whiteColor}>
                {G.getWindowLocale('titles:import', 'Import')}
              </Box>
            </Flex>
          }
          {
            withFilter
            && (
              // NOTE: CustomFormGroupTitle as enhanced FormGroupTitle has props which passing to each child
              <Box lineHeight='normal'>
                <SimpleWrapper mr='45px' color={G.getTheme('downFilter.textColor')}>
                  <Filter
                    position='top-end'
                    filterAction={handleSetQuickFilter}
                    setFilterParam={setQuickFilterParams}
                    shouldSubmitFilterForm={shouldSubmitFilterForm}
                    filterColor={G.getTheme('icons.iconColorWhite')}
                    filterBy={G.setFilterByParams(refList, getCarrierRateFilterParams)}
                  />
                </SimpleWrapper>
              </Box>
            )
          }
        </Flex>
      </CustomFormGroupTitle>
    </FormGroupTitleWrapper>
  );
};

export const setInitialValue = (entity: Object, mainFields: Array) => {
  const { origin, destination } = entity;

  const valuesForRequest = R.pick(mainFields, entity);

  if (G.isNotNilAndNotEmpty(destination)) {
    R.compose(
      R.forEachObjIndexed((value: string, key: string) => {
        valuesForRequest[`${LC.FIELD_CARRIER_RATE_DESTINATION}:${key}`] = value;
      }),
      R.pick(LC.CARRIER_RATE_LOCATIONS_FIELDS),
    )(destination);
  }

  if (G.isNotNilAndNotEmpty(origin)) {
    R.compose(
      R.forEachObjIndexed((value: string, key: string) => {
        valuesForRequest[`${LC.FIELD_CARRIER_RATE_ORIGIN}:${key}`] = value;
      }),
      R.pick(LC.CARRIER_RATE_LOCATIONS_FIELDS),
    )(origin);
  }

  return valuesForRequest;
};

export const addAssessorialInfoToEntity = (entities: Array, indexedConfigs: Array) => {
  const entitiesWithNotEditableProp = R.map((item: Object) =>
    R.mergeRight(item, { notEditable: R.or(item.shared, false), withPreviewAction: R.or(item.shared, false) }), entities);

  if (G.isNilOrEmpty(indexedConfigs)) return entitiesWithNotEditableProp;

  return entitiesWithNotEditableProp.map((item: Object) => {
    const assessorial = indexedConfigs[item.assessorialGuid];
    return R.mergeRight(assessorial, item);
  });
};

export const transformToReportList = (data: Object, fields: Array) => {
  const entity = { guid: data.guid };
  R.forEach(
    (key: string) => {
      const splitedPath = R.split('.', key);

      if (
        R.is(Array, R.prop(splitedPath[0], data)) &&
        R.any(R.is(Object), R.prop(splitedPath[0], data))
      ) {
        entity[key] = R.map(R.prop(splitedPath[1]), R.prop(splitedPath[0], data));

        return;
      }
      entity[key] = R.path(R.split('.', key), data);
    },
    R.keys(fields),
  );

  return entity;
};

export const customIfElse = (props: Object, positiveValue: any, negativeValue: any) => (
  G.ifElse(
    R.and(
      R.equals(props.collapsedGroup[props.groupName], false),
      G.isNotNil(props.collapsedGroup[props.groupName]),
    ),
    positiveValue,
    negativeValue,
  )
);

const carrierLoadBoardSynchronizations = [
  GC.EXTERNAL_LOAD_BOARD_PARADE,
  GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
  GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
];

const hasCarrierLoadBoardSynchronizations = (loadBoard: string) =>
  R.includes(loadBoard, carrierLoadBoardSynchronizations);

export const showCarrierLoadBoardSynchronization = R.any((item: string) => hasCarrierLoadBoardSynchronizations(item));

export const carrierLoadBoardSynchronizationSources = R.filter(
  ({ value }: string) => hasCarrierLoadBoardSynchronizations(value),
  GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS,
);

export const getAuthorizedCarrierLoadBoardSynchronization = R.compose(
  R.head,
  R.filter((item: string) => hasCarrierLoadBoardSynchronizations(item)),
);

export const isMultipleLoadBoardSynchronizations = R.compose(
  R.lt(1),
  R.length,
  R.filter((item: string) => hasCarrierLoadBoardSynchronizations(item)),
);

export const getSynchronizationType = () => {
  const synchronizationMap = {
    [GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS]: 'RMIS',
    [GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS]: 'My Carrier Packets',
  };

  return R.pathOr('SaferWatch', [G.getCarrierSynchronizationType()], synchronizationMap);
};

export const getTabs = (activeTab: number) => [
  {
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:carrier-list', 'Carriers'),
  },
  {
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:insurances', 'Insurances'),
  },
];
