import * as R from 'ramda';
import React from 'react';
// features
import { orderColumnSettings } from '../../dispatch-report/settings/column-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Span } from '../../../ui';
//////////////////////////////////////////////////

export const tableSettings = {
  cellFontSize: 12,
  maxHeight: '100%',
  minHeight: '100%',
  titleFontSize: 12,
  tableRowHeight: 30,
  titleRowHeight: 60,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  searchableTitles: true,
  expandableItems: false,
  allowSelectItems: false,
};

const iconsMap = {
  [GC.TRANSPORTATION_MODE_TYPE_LTL]: I.ltl(),
  [GC.TRANSPORTATION_MODE_TYPE_AIR]: I.plain(),
  [GC.TRANSPORTATION_MODE_TYPE_RAIL]: I.train(),
  [GC.TRANSPORTATION_MODE_TYPE_OCEAN]: I.boat(),
  [GC.TRANSPORTATION_MODE_TYPE_DRAYAGE]: I.crane(),
  [GC.TRANSPORTATION_MODE_TYPE_SMALL_TRUCK]: I.van(),
  [GC.TRANSPORTATION_MODE_TYPE_TRUCK_LOAD]: I.truckLoad(),
  [GC.TRANSPORTATION_MODE_TYPE_BOX_SHIPMENTS]: I.parcel(),
};

export const columnSettings = {
  ...orderColumnSettings,
  actions: {
    width: 40,
    customComponent: ({ data, callbackData }: Object) => {
      const { guid, transportationModeType } = data;

      const { flyToGuid, setFlyToGuid } = callbackData;

      const icon = G.getPropFromObject(transportationModeType, iconsMap);

      if (R.equals(guid, flyToGuid)) return icon;

      return (
        <Span cursor='pointer' onClick={() => setFlyToGuid(guid)}>
          {G.getPropFromObject(transportationModeType, iconsMap)}
        </Span>
      );
    },
  },
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    searchable: true,
    useExpandedContainer: true,
    guidPropName: GC.FIELD_GUID,
    name: 'titles:primary-ref-value',
    type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
  },
};
