import * as R from 'ramda';
import { change } from 'redux-form';
import FileDownload from 'js-file-download';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectExpandedContainerOpened } from '../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import routesMap from '../../../utilities/routes';
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// fleet
import { getFleetConfigByGroupSaga } from '../sagas';
import { getDriverProfileRequest } from '../driver/actions';
// feature fleet/vendor
import * as A from './actions';
import { makeSelectCurrentVendorGuid } from './selectors';
//////////////////////////////////////////////////

export function* getFleetVendorRequest({ guid }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getFleetVendorByGuid(guid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const vendorGuid = G.getGuidFromObject(data);
      const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, data);
      yield all([
        put(A.getFleetVendorSuccess(data)),
        put(A.getVendorConfigsRequest(branchGuid)),
        put(A.getVendorContactListRequest(vendorGuid)),
        put(A.getFleetAssignmentListRequest(vendorGuid)),
        put(A.getVendorInsuranceListRequest(vendorGuid)),
        put(A.getVendorPayToLocationRequest(vendorGuid)),
        put(A.getVendorReferenceListRequest(vendorGuid)),
        put(A.getBranchConfigsByNamesRequest(branchGuid)),
      ]);
    } else {
      yield call(G.handleFailResponse, res, 'handleDriverRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetVendorRequest exception');
  }
}

function* getDataForCompensationTabRequest({ payload }: Object) {
  try {
    yield all([
      put(A.getVendorAccessorialListRequest(payload)),
      put(A.getPayrollAssessorialListRequest(payload)),
      put(A.getVendorCompensationListRequest(payload)),
      put(A.getMonthlyPayrollDeductionRequest(payload)),
    ]);
  } catch (error) {
    yield call(G.handleException, error, 'getDataForCompensationTabRequest exception');
  }
}

// reference
export function* createVendorReferenceRequest({ payload }: Object) {
  try {
    const { values, selectedList } = payload;

    yield put(openLoader());
    const options = {
      data: R.assoc('primaryObjectGuids', selectedList, values),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorReference, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createVendorReferenceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriversReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriversReferenceSaga exception');
  }
}

export function* updateVendorReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.vendorReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.updateVendorReferenceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateVendorReferenceRequest exception');
  }
}

export function* deleteVendorReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.removeVendorReference(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteVendorReferenceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'deleteVendorReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'deleteVendorReferenceRequest exception');
  }
}

export function* getVendorReferenceListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.vendorReferenceList;
    const options = {
      params: { vendorGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorReferenceListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorReferenceListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorReferenceListRequest exception');
  }
}
// reference

// contact
function* getVendorContactListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.vendorContactList;
    const options = {
      params: { [GC.FIELD_VENDOR_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorContactListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorContactListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorContactListRequest exception');
  }
}

function* createVendorContactRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      data: R.assoc(GC.FIELD_FLEET_VENDOR_GUID, vendorGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.vendorContact, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createVendorContactSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createVendorContactRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createVendorContactRequest exception');
  }
}

function* removeVendorContactRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'delete', endpointsMap.removeVendorContact(payload));
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeVendorContactSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeVendorContactRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeVendorContactRequest exception');
  }
}

function* updateVendorContactRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.vendorContact, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateVendorContactSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorContactRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateVendorContactRequest exception');
  }
}
// contact

// pay to location
function* getVendorPayToLocationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.vendorPayToLocation;
    const options = {
      params: { [GC.FIELD_FLEET_VENDOR_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorPayToLocationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorPayToLocationRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorPayToLocationRequest exception');
  }
}

function* createOrUpdateVendorPayToLocationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { method, values } = payload;
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      data: R.assoc(GC.FIELD_FLEET_VENDOR_GUID, vendorGuid, values),
    };
    const res = yield call(sendRequest, method, endpointsMap.vendorPayToLocation, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getVendorPayToLocationSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateVendorPayToLocationRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateVendorPayToLocationRequest exception');
  }
}

function* removeVendorPayToLocationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeVendorPayToLocation(G.getGuidFromObject(payload));
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getVendorPayToLocationSuccess({}));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeVendorPayToLocationRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeVendorPayToLocationRequest exception');
  }
}
// pay to location

// insurance
function* getVendorInsuranceListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.fleetVendorInsuranceList;
    const options = {
      params: { [GC.FIELD_VENDOR_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorInsuranceListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorInsuranceListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorInsuranceListRequest exception');
  }
}

function* createVendorInsuranceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { values, resetForm } = payload;
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      data: R.assoc(GC.FIELD_FLEET_VENDOR_GUID, vendorGuid, values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.fleetVendorInsurance, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.createVendorInsuranceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (G.isFunction(resetForm)) {
        resetForm();
      } else {
        yield put(closeModal());
      }
    } else {
      yield call(G.handleFailResponse, res, 'createVendorInsuranceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createVendorInsuranceRequest exception');
  }
}

function* removeVendorInsuranceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'delete', endpointsMap.removeVendorInsurance(payload));
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeVendorInsuranceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeVendorInsuranceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeVendorInsuranceRequest exception');
  }
}

function* updateVendorInsuranceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload.values,
    };
    const res = yield call(sendRequest, 'put', endpointsMap.fleetVendorInsurance, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateVendorInsuranceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorInsuranceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateVendorInsuranceRequest exception');
  }
}
// insurance

// accessorials
function* getVendorAccessorialListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_FLEET_VENDOR_GUID]: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.fleetVendorAccessorialList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorAccessorialListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDrgetVendorAccessorialListRequestiverAccessorialsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'getVendorAccessorialListRequest exception');
  }
}

function* createVendorAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.fleetVendorAccessorial, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createVendorAccessorialSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createVendorAccessorialRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'createVendorAccessorialRequest exception');
  }
}

function* updateVendorAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.fleetVendorAccessorial,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateVendorAccessorialSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:200');
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorAccessorialRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'updateVendorAccessorialRequest exception');
  }
}

export function* removeVendorAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getVendorAccessorialEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeVendorAccessorialSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeVendorAccessorialRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'removeVendorAccessorialRequest exception');
  }
}
// accessorials

// payroll accessorials
function* getPayrollAssessorialListRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_FLEET_VENDOR_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.fleetVendorPayrollAccessorialList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollAssessorialListSuccess(data));
      if (G.isNotNilAndNotEmpty(data)) {
        yield put(A.getPayrollAccessorialIsPaidOnPayrollRequest(R.map(G.getGuidFromObject, data)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getPayrollAssessorialListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getPayrollAssessorialListRequest exception'));
  }
}

function* getPayrollAccessorialIsPaidOnPayrollRequest({ payload }: Object) {
  try {
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollAssessorialsPaidOnPayroll, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollAccessorialIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getPayrollAccessorialIsPaidOnPayrollRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getPayrollAccessorialIsPaidOnPayrollRequest exception'));
  }
}

function* getPayrollChargesByPayrollAccessorial({ payload }: Object) {
  try {
    const params = { payrollAssessorialGuid: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.vendorPayrollChargesByPayrollAssessorial, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollChargesByPayrollAccessorialSuccess({ data, guid: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'getPayrollChargesByPayrollAccessorial fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getPayrollChargesByPayrollAccessorial exception'));
  }
}

function* togglePayrollAssessorialDetails({ payload }: Object) {
  try {
    if (R.and(R.not(payload.expanded), R.isNil(R.prop('details', payload)))) {
      yield put(A.getPayrollChargesByPayrollAccessorialRequest(payload.guid));
    }
  } catch (error) {
    yield call(G.handleException, error, 'togglePayrollAssessorialDetails exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* createOrUpdatePayrollAssessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { requestData, requestMethod } = payload;
    const options = {
      data: requestData,
    };
    const res = yield call(
      sendRequest,
      requestMethod,
      endpointsMap.fleetVendorPayrollAccessorial,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getPayrollAssessorialListRequest(G.getPropFromObject(GC.FIELD_FLEET_VENDOR_GUID, data)));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdatePayrollAssessorialRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'createOrUpdatePayrollAssessorialRequest exception'));
  }
}

function* removePayrollAssessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, fleetVendorGuid } = payload;

    const endpoint = endpointsMap.getVendorPayrollAccessorialEndpoint(guid);

    const options = {
      params: { guid },
    };

    const res = yield call(sendRequest, 'delete', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getPayrollAssessorialListRequest(fleetVendorGuid));
    } else {
      yield call(G.handleFailResponse, res, 'removePayrollAssessorialRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException(error, 'removePayrollAssessorialRequest exception'));
  }
}
// payroll accessorials

// compensation
export function* getVendorCompensationListRequest({ payload }: Object) {
  try {
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const reqBody = G.getPropFromObject('reqBody', payload);
    const searchCriteria = G.ifElse(
      G.isNotNilAndNotEmpty(reqBody),
      R.of(Array, reqBody),
      [],
    );
    const options = {
      data: {
        searchCriteria,
        [GC.FIELD_FLEET_VENDOR_GUID]: vendorGuid,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.fleetVendorCompensationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorCompensationListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorCompensationListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorCompensationListRequest exception');
  }
}

function* createVendorCompensationRequest({ payload }: Object) {
  const { resetForm, saveAndAddNew, setSubmitting } = payload;

  try {
    yield put(openLoader());
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      data: R.assoc(
        GC.FIELD_FLEET_VENDOR_GUID,
        vendorGuid,
        R.omit(['resetForm', 'saveAndAddNew', 'setSubmitting'], payload),
      ),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.fleetVendorCompensation, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.createVendorCompensationSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (G.isTrue(saveAndAddNew)) {
        G.callFunction(resetForm);
      } else {
        yield put(closeModal());
      }
    } else {
      yield call(G.handleFailResponse, res, 'createVendorCompensationRequest fail');
    }
    G.callFunctionWithArgs(setSubmitting, false);
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createVendorCompensationRequest exception');
  }
}

function* updateVendorCompensationRequest({ payload }: Object) {
  const { setSubmitting } = payload;

  try {
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      data: R.assoc(
        GC.FIELD_FLEET_VENDOR_GUID,
        vendorGuid,
        R.omit(['resetForm', 'saveAndAddNew', 'setSubmitting'], payload),
      ),
    };
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.fleetVendorCompensation, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateVendorCompensationSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorCompensationRequest fail');
    }
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
  } catch (error) {
    yield put(closeLoader());
    G.callFunctionWithArgs(setSubmitting, false);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateVendorCompensationRequest exception');
  }
}

function* removeVendorCompensationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getVendorCompensationEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeVendorCompensationSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'toastr:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeVendorCompensationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
    yield call(G.handleException, error, 'removeVendorCompensationRequest exception');
  }
}
// compensation

// documents
function* getVendorDocumentListRequest() {
  try {
    yield put(openLoader());
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      params: {
        [GC.FIELD_PRIMARY_OBJECT_GUID]: vendorGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.fleetVendorDocumentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorDocumentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorDocumentListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorDocumentListRequest exception');
  }
}

function* downloadVendorDocumentRequest({ payload }: Object) {
  try {
    const { documentFilename, primaryObjectGuid } = payload;
    const options = {
      resType: 'arraybuffer',
      params: {
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: documentFilename,
      },
    };

    const res = yield call(
      sendRequestWithQSParamsSerializer,
      'get',
      endpointsMap.fleetVendorDocumentDownloadFile,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      FileDownload(data, documentFilename);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'downloadVendorDocumentRequest fail');
    }

  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'downloadVendorDocumentRequest exception');
  }
}

function* createVendorDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.fleetVendorDocument,
      { data },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createVendorDocumentSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createVendorDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createVendorDocumentRequest exception');
  }
}

function* updateVendorDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.fleetVendorDocumentUpdate,
      { data },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateVendorDocumentSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateVendorDocumentRequest exception');
  }
}

function* removeVendorDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.removeVendorDocument(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeVendorDocumentSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeVendorDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeVendorDocumentRequest exception');
  }
}
// documents

// vendor
function* createVendorRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload.values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.fleetVendor, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(
        G.goToRoute,
        routesMap[G.getFleetProfileRoutePathNameByFleetType('vendor')](G.getGuidFromObject(data)),
      );
    } else {
      yield call(G.handleFailResponse, res, 'createVendorRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createVendorRequest exception');
  }
}

export function* updateVendorRequest({ payload }: Object) {
  try {
    const { values, updateAndClose } = payload;

    const options = { data: values };

    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.fleetVendor, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (updateAndClose) {
        const expandedContainerOpened = yield select(makeSelectExpandedContainerOpened());

        if (expandedContainerOpened) {
          yield put(setExpandedContainerOptions({
            window: null,
            options: null,
            opened: false,
            componentType: null,
            visitPageGuid: G.getGuidFromObject(payload),
          }));
        } else {
          G.historyGoBack();
        }
      } else {
        yield put(A.updateVendorSuccess(data));
      }
    } else {
      yield call(G.handleFailResponse, res, 'updateVendorRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateVendorRequest exception');
  }
}

function* getVendorConfigsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      group: GC.CARRIER_CONFIG_GROUP,
      [GC.FIELD_BRANCH_GUID]: payload,
    };
    const res = yield call(getFleetConfigByGroupSaga, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorConfigsRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorConfigsRequest exception');
  }
}

function* getVendorIntegrationListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: { [GC.FIELD_FLEET_VENDOR_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.fleetVendorIntegrationInfoList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorIntegrationListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorIntegrationListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, 'error', 'getVendorIntegrationListRequest exception');
  }
}

function* getVendorIntegrationAuditListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: { [GC.FIELD_FLEET_VENDOR_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.fleetVendorIntegrationAuditList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorIntegrationAuditListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorIntegrationAuditListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, 'error', 'getVendorIntegrationAuditListRequest exception');
  }
}
// vendor

// assignment
function* getVendorAssignmentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { type, guid } = payload;
    const endpoint = endpointsMap.getVendorAssignment(type, guid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.assignVendorSuccess(R.assoc(GC.FIELD_TYPE, type, data)));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorAssignmentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getVendorAssignmentRequest exception');
  }
}

const formTypeMap = {
  truck: 'EDIT_TRUCK_FORM',
  trailer: 'EDIT_TRAILER_FORM',
  driver: 'EDIT_DRIVER_DETAIL',
};

function* assignVendorRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { type, guid, version, objGuid } = payload;
    const endpoint = endpointsMap.assignVendor(type, objGuid);
    const options = {
      params: { [GC.FIELD_VENDOR_GUID]: guid },
    };
    const res = yield call(sendRequest, 'put', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.assignVendorSuccess(payload));
      yield put(change(formTypeMap[type], GC.FIELD_VERSION, R.inc(version)));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'assignVendorRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'assignVendorRequest exception');
  }
}

function* assignVendorToDriverRequest({ payload }: Object) {
  try {
    const { guid, payable, objGuid } = payload;

    yield put(openLoader());

    const options = {
      data: {
        payable,
        [GC.FIELD_VENDOR_GUID]: guid,
        [GC.FIELD_DRIVER_GUID]: objGuid,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.assignVendorToDriver, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(getDriverProfileRequest(objGuid));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'assignVendorToDriverRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'assignVendorToDriverRequest exception');
  }
}

function* unAssignVendorRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { type, guid, version, objGuid } = payload;

    const endpoint = endpointsMap.unassignVendor(type, objGuid);

    const options = {
      params: { [GC.FIELD_VENDOR_GUID]: guid },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (R.equals(type, 'driver')) {
        yield put(getDriverProfileRequest(objGuid));
      } else {
        yield put(A.unAssignVendorSuccess(payload));
        yield put(change(formTypeMap[type], GC.FIELD_VERSION, R.inc(version)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'unAssignVendorRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'unAssignVendorRequest exception');
  }
}
// assignment

// fleet assignment
function* getFleetAssignmentListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: { [GC.FIELD_VENDOR_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.getVendorAssignments, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getFleetAssignmentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetAssignmentListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetAssignmentListRequest exception');
  }
}

function* changeFleetAssignmentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      data: R.assoc(GC.FIELD_VENDOR_GUID, vendorGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.changeVendorAssignment, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getFleetAssignmentListRequest(vendorGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'changeFleetAssignmentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeFleetAssignmentRequest exception');
  }
}
// fleet assignment

// branch configs
const configNames = [GC.GENERAL_BRANCH_DEFAULT_CURRENCY];

function* getBranchConfigsByNamesRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
        names: R.join(', ', configNames),
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsByNamesSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsByNamesRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getBranchConfigsByNamesRequest exception');
  }
}

// monthly payroll deduction
function* getMonthlyPayrollDeductionRequest({ payload }: Object) {
  try {
    const params = { [GC.FIELD_FLEET_VENDOR_GUID]: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.vendorMonthlyPaymentList, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getMonthlyPayrollDeductionSuccess(data));
      if (G.isNotNilAndNotEmpty(data)) {
        yield put(A.getVendorPayrollChargesIsPaidOnPayrollRequest(R.map(G.getGuidFromObject, data)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getMonthlyPayrollDeductionRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getMonthlyPayrollDeductionRequest exception'));
  }
}

function* getVendorPayrollChargesIsPaidOnPayrollSaga({ payload }: Object) {
  try {
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollChargesIsPaidOnPayroll, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorPayrollChargesIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorPayrollChargesIsPaidOnPayrollSaga fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getVendorPayrollChargesIsPaidOnPayrollSaga exception'));
  }
}

function* getVendorPayrollChargesByMonthlyPaymentSaga({ payload }: Object) {
  try {
    const params = { monthlyPaymentGuid: payload };
    const res = yield call(sendRequest, 'get', endpointsMap.vendorPayrollChargesByMonthlyPaymentGuid, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getVendorPayrollChargesByMonthlyPaymentSuccess({ data, guid: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'getVendorPayrollChargesByMonthlyPaymentSaga fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getVendorPayrollChargesByMonthlyPaymentSaga exception'));
  }
}

function* handleToggleMonthlyPayrollDeductionDetails({ payload }: Object) {
  try {
    if (R.and(R.not(payload.expanded), R.isNil(R.prop('details', payload)))) {
      yield put(A.getVendorPayrollChargesByMonthlyPaymentRequest(payload.guid));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleToggleMonthlyPayrollDeductionDetails exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* createOrUpdateMonthlyPayrollDeductionRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { requestData, requestMethod } = payload;
    const vendorGuid = yield select(makeSelectCurrentVendorGuid());
    const options = {
      data: R.assoc('fleetVendorGuid', vendorGuid, requestData),
    };
    const res = yield call(sendRequest, requestMethod, endpointsMap.vendorMonthlyPayment, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getMonthlyPayrollDeductionRequest(R.prop(GC.FIELD_FLEET_VENDOR_GUID, data)));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateMonthlyPayrollDeductionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'createOrUpdateMonthlyPayrollDeductionRequest exception'));
  }
}

function* deleteMonthlyPayrollDeductionRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, fleetVendorGuid } = payload;
    const endpoint = endpointsMap.getVendorMonthlyPaymentEndpoint(guid);
    const params = { guid };
    const res = yield call(sendRequest, 'delete', endpoint, { params });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getMonthlyPayrollDeductionRequest(fleetVendorGuid));
    } else {
      yield call(G.handleFailResponse, res, 'deleteMonthlyPayrollDeductionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'deleteMonthlyPayrollDeductionRequest exception'));
  }
}

function* sendListToIntegrationRequest({ payload }: Object) {
  try {
    const { guids, integrationType } = payload;

    yield put(openLoader());
    const options = {
      data: guids,
      params: { integrationType },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.sendFleetVendorListToIntegration, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { errors, sentToDivisionNames } = data;
      yield put(A.sendListToIntegrationSuccess(sentToDivisionNames));
      yield put(closeModal());

      if (G.isNotNilAndNotEmpty(errors)) {
        yield all(R.mapObjIndexed(
          (errs: Array, entity: string) => call(G.showToastrMessage, 'error', `${entity}: ${R.join(', ', errs)}`),
          errors,
        ));
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'sendListToIntegrationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, 'error', 'sendListToIntegrationRequest exception');
  }
}

// visit page
export function* visitEditFleetVendorPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_VENDOR_EDIT_PAGE);
    yield put(A.cleanFormStore());
    const guid = G.getGuidFromObject(payload);
    yield call(getFleetVendorRequest, payload);
    yield put(A.getVendorIntegrationListRequest(guid));
    yield put(A.getVendorIntegrationAuditListRequest(guid));
    break;
  }
}
// visit page

function* fleetVendorWatcherSaga() {
  // visit page
  yield takeLatest(GC.VISIT_FLEET_VENDOR_EDIT_PAGE, visitEditFleetVendorPage);
  // report
  // configs
  yield takeLatest(A.getVendorConfigsRequest, getVendorConfigsRequest);
  // vendor
  yield takeLatest(A.createVendorRequest, createVendorRequest);
  yield takeLatest(A.updateVendorRequest, updateVendorRequest);
  yield takeLatest(A.sendListToIntegrationRequest, sendListToIntegrationRequest);
  yield takeLatest(A.getVendorIntegrationListRequest, getVendorIntegrationListRequest);
  yield takeLatest(A.getDataForCompensationTabRequest, getDataForCompensationTabRequest);
  yield takeLatest(A.getVendorIntegrationAuditListRequest, getVendorIntegrationAuditListRequest);
  // reference
  yield takeLatest(A.createVendorReferenceRequest, createVendorReferenceRequest);
  yield takeLatest(A.deleteVendorReferenceRequest, deleteVendorReferenceRequest);
  yield takeLatest(A.updateVendorReferenceRequest, updateVendorReferenceRequest);
  yield takeLatest(A.getVendorReferenceListRequest, getVendorReferenceListRequest);
  // contact
  yield takeLatest(A.updateVendorContactRequest, updateVendorContactRequest);
  yield takeLatest(A.createVendorContactRequest, createVendorContactRequest);
  yield takeLatest(A.removeVendorContactRequest, removeVendorContactRequest);
  yield takeLatest(A.getVendorContactListRequest, getVendorContactListRequest);
  // pay to location
  yield takeLatest(A.getVendorPayToLocationRequest, getVendorPayToLocationRequest);
  yield takeLatest(A.removeVendorPayToLocationRequest, removeVendorPayToLocationRequest);
  yield takeLatest(A.createOrUpdateVendorPayToLocationRequest, createOrUpdateVendorPayToLocationRequest);
  // insurance
  yield takeLatest(A.createVendorInsuranceRequest, createVendorInsuranceRequest);
  yield takeLatest(A.updateVendorInsuranceRequest, updateVendorInsuranceRequest);
  yield takeLatest(A.removeVendorInsuranceRequest, removeVendorInsuranceRequest);
  yield takeLatest(A.getVendorInsuranceListRequest, getVendorInsuranceListRequest);
  // payroll accessorial
  yield takeLatest(A.removePayrollAssessorialRequest, removePayrollAssessorialRequest);
  yield takeLatest(A.togglePayrollAssessorialDetails, togglePayrollAssessorialDetails);
  yield takeLatest(A.getPayrollAssessorialListRequest, getPayrollAssessorialListRequest);
  yield takeLatest(A.createOrUpdatePayrollAssessorialRequest, createOrUpdatePayrollAssessorialRequest);
  yield takeLatest(A.getPayrollChargesByPayrollAccessorialRequest, getPayrollChargesByPayrollAccessorial);
  yield takeLatest(A.getPayrollAccessorialIsPaidOnPayrollRequest, getPayrollAccessorialIsPaidOnPayrollRequest);
  // accessorials
  yield takeLatest(A.removeVendorAccessorialRequest, removeVendorAccessorialRequest);
  yield takeLatest(A.createVendorAccessorialRequest, createVendorAccessorialRequest);
  yield takeLatest(A.updateVendorAccessorialRequest, updateVendorAccessorialRequest);
  yield takeLatest(A.getVendorAccessorialListRequest, getVendorAccessorialListRequest);
  // compensation
  yield takeLatest(A.createVendorCompensationRequest, createVendorCompensationRequest);
  yield takeLatest(A.updateVendorCompensationRequest, updateVendorCompensationRequest);
  yield takeLatest(A.removeVendorCompensationRequest, removeVendorCompensationRequest);
  yield takeLatest(A.getVendorCompensationListRequest, getVendorCompensationListRequest);
  // documents
  yield takeLatest(A.createVendorDocumentRequest, createVendorDocumentRequest);
  yield takeLatest(A.updateVendorDocumentRequest, updateVendorDocumentRequest);
  yield takeLatest(A.removeVendorDocumentRequest, removeVendorDocumentRequest);
  yield takeLatest(A.getVendorDocumentListRequest, getVendorDocumentListRequest);
  yield takeLatest(A.downloadVendorDocumentRequest, downloadVendorDocumentRequest);
  // assignment
  yield takeLatest(A.assignVendorRequest, assignVendorRequest);
  yield takeLatest(A.unAssignVendorRequest, unAssignVendorRequest);
  yield takeLatest(A.getVendorAssignmentRequest, getVendorAssignmentRequest);
  yield takeLatest(A.assignVendorToDriverRequest, assignVendorToDriverRequest);
  // fleet assignment
  yield takeLatest(A.changeFleetAssignmentRequest, changeFleetAssignmentRequest);
  yield takeLatest(A.getFleetAssignmentListRequest, getFleetAssignmentListRequest);
  // branch configs
  yield takeLatest(A.getBranchConfigsByNamesRequest, getBranchConfigsByNamesRequest);
  // monthly payroll deduction
  yield takeLatest(A.getMonthlyPayrollDeductionRequest, getMonthlyPayrollDeductionRequest);
  yield takeLatest(A.deleteMonthlyPayrollDeductionRequest, deleteMonthlyPayrollDeductionRequest);
  yield takeLatest(A.toggleMonthlyPayrollDeductionDetails, handleToggleMonthlyPayrollDeductionDetails);
  yield takeLatest(A.getVendorPayrollChargesIsPaidOnPayrollRequest, getVendorPayrollChargesIsPaidOnPayrollSaga);
  yield takeLatest(A.createOrUpdateMonthlyPayrollDeductionRequest, createOrUpdateMonthlyPayrollDeductionRequest);
  yield takeLatest(A.getVendorPayrollChargesByMonthlyPaymentRequest, getVendorPayrollChargesByMonthlyPaymentSaga);
}

export default fleetVendorWatcherSaga;
