import React from 'react';
import * as R from 'ramda';
// features
import Audit2 from '../../audit2';
import { getAdditionBtns } from '../helpers';
import CarrierScorecards from '../../carrier-scorecards';
import CarrierStatisticReport from '../../carrier-statistic-report';
import CarrierRateRequestReport from '../../carrier-rate-request-report';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier
import DocsTab from './docs-tab';
import FinanceTab from './finance-tab';
import GeneralTab from './general-tab';
import CertificateTab from './certificate-tab';
import TerminalDetailsTab from './terminal-detail-tab';
import TerminalProcessTab from './terminal-process-tab';
import AdditionalContactTab from './additional-contacts-tab';
//////////////////////////////////////////////////

export const renderTab = (props: Object) => {
  const {
    openModal,
    activeTab,
    closeModal,
    initialValues,
    handleClickCancel,
    carrierPayLocations,
    terminalPayLocations,
    handleTerminalClickCancel,
    createCarrierPayLocationRequest,
    updateCarrierPayLocationRequest,
    deleteCarrierPayLocationRequest,
    createTerminalPayLocationRequest,
    updateTerminalPayLocationRequest,
    deleteTerminalPayLocationRequest,
  } = props;

  const requestOptions = {
    [GC.FIELD_TYPE]: GC.AUDIT_TYPE_CARRIER,
    [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(initialValues),
  };

  const tabs = {
    docs: () => <DocsTab {...props} />,
    general: () => <GeneralTab {...props} />,
    certificate: () => <CertificateTab {...props} />,
    audit: () => <Audit2 useFullScreen={true} requestOptions={requestOptions} />,
    scorecards: () => (
      <CarrierScorecards
        pr={25}
        carrierGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        carrierName={R.path(['initialValues', GC.FIELD_NAME], props)}
      />
    ),
    carrierStatistic: () =>
      <CarrierStatisticReport carrierName={R.path(['initialValues', GC.FIELD_NAME], props)} />,
    carrierRateRequest: () =>
      <CarrierRateRequestReport carrierName={R.path(['initialValues', GC.FIELD_NAME], props)} />,
    carrierFinance: () => (
      <FinanceTab
        {...props}
        groupName='carrierFinance'
        cancelAction={handleClickCancel}
        additionBtns={getAdditionBtns(props)}
        listPayLocations={carrierPayLocations}
        branchGuid={initialValues[GC.FIELD_BRANCH_GUID]}
        createPayLocationAction={createCarrierPayLocationRequest}
        updatePayLocationAction={updateCarrierPayLocationRequest}
        deletePayLocationAction={deleteCarrierPayLocationRequest}
      />
    ),
    terminalGeneral: () => <TerminalDetailsTab {...props} />,
    terminalProcess: () => <TerminalProcessTab {...props} branchGuid={G.getBranchGuidFromObject(initialValues)} />,
    additionalContacts: () =>
      <AdditionalContactTab
        openModal={openModal}
        closeModal={closeModal}
        carrierGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
      />,
    terminalFinance: () => (
      <FinanceTab
        {...props}
        groupName='terminalFinance'
        listPayLocations={terminalPayLocations}
        cancelAction={handleTerminalClickCancel}
        createPayLocationAction={createTerminalPayLocationRequest}
        updatePayLocationAction={updateTerminalPayLocationRequest}
        deletePayLocationAction={deleteTerminalPayLocationRequest}
      />
    ),
  };

  return tabs[activeTab] && tabs[activeTab]();
};
