import React from 'react';
// ui
import { Flex, ActionButton, CancelButton } from '../../../ui';
//////////////////////////////////////////////////

const Footer = (props: Object) => (
  <Flex mt={15} width='100%'>
    <ActionButton
      mr={15}
      width='50%'
      p='8px 16px'
      type='submit'
      fontSize={18}
      onClick={props.handleFormSubmit}
    >
      {props.actionText}
    </ActionButton>
    <CancelButton
      width='50%'
      p='8px 16px'
      fontSize={18}
      onClick={props.closeModal}
    >
      {props.cancelText}
    </CancelButton>
  </Flex>
);

export default Footer;
