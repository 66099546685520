import React from 'react';
import * as R from 'ramda';
// components
import { PageHeaderActions } from '../../../components/page-header-actions';
// helpers
import * as G from '../../../helpers';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const pageHeaderActions = [
  {
    title: G.getWindowLocale('titles:onboarding-package', 'Onboarding Package'),
    actions: [
      {
        permissions: null,
        action: () => {
          window.alert('Send Onboarding Package');
        },
        text: G.getWindowLocale('titles:send-onboarding-package', 'Send Onboarding Package'),
      },
    ],
  },
];

const PageHeaderActionsComponent = () => {
  const description = '<PageHeaderActions /> component generates a popper containing various actions based on the provided data. ';

  const usage = (
    <ul>
      <li>Parameter `<i>count</i>` represents the count of actions to be displayed.</li>
      <li>Parameter `<i>pageHeaderActions</i>` is an array containing objects that define the structure of actions to be displayed.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='PageHeaderActions'
      description={description}
      path='src/components/page-header-actions'
    >
      <PageHeaderActions
        count={R.length(pageHeaderActions)}
        pageHeaderActions={pageHeaderActions}
      />
    </ComponentStoryWrapper>
  );
};

export default PageHeaderActionsComponent;
