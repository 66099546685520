import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// features drivers-card
import { updateTripDriverCardColorSuccess } from '../actions';
//////////////////////////////////////////////////

const withConnectTripDriverCardColor = connect(null, {
  updateTripDriverCardColorSuccess,
});

const withTelDriverCardColor = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    updateTelDriverCardColor: (props: Object) => async (color: string, successCallback: Function) => {
      const { guid, openLoader, closeLoader } = props;

      G.callFunction(openLoader);

      try {
        const res = await sendRequest(
          'put', endpointsMap.getTelDriverCardColor(guid), { data: color, plainText: true },
        );

        const { data, status } = res;

        if (G.isResponseSuccess(status, data)) {
          G.callFunction(successCallback);
        } else {
          G.handleFailResponseSimple(
            res,
            true,
            'withTelDriverCardColor -> updateTelDriverCardColor',
          );
        }
        G.callFunction(closeLoader);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'withTelDriverCardColor -> updateTelDriverCardColor');
      }
    },
  }),
  pure,
);

export {
  withTelDriverCardColor,
  withConnectTripDriverCardColor,
};
