import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import { TaskTypeForm, TaskStatusForm } from './forms';
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import {
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
} from '../../actions';
import {
  removeTaskTypeRequest,
  removeTaskStatusRequest,
  createOrUpdateTaskTypeRequest,
  createOrUpdateTaskStatusRequest,
} from './actions';
import {
  makeSelectTaskTypeList,
  makeSelectConfigOptions,
  makeSelectTaskStatusList,
  makeSelectConfigInitialValues,
  makeSelectConfigInheritedValues,
} from '../../selectors';
//////////////////////////////////////////////////

const getModalWithComponent = (component: any, options: Object) => ({
  p: 15,
  options,
  component,
});

const enhance = compose(
  defaultPageConfigEnhancer(),
  itemPageConfigEnhancer,
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string) => {
      const {
        openModal,
        taskTypeList,
        createOrUpdateTaskTypeRequest,
        createOrUpdateTaskStatusRequest,
      } = props;

      const map = {
        [GC.CUSTOM_TASK_TYPE_CONFIG]: {
          titleArr: ['titles:task-type', 'Task Type'],
          component: <TaskTypeForm submitAction={createOrUpdateTaskTypeRequest} />,
        },
        [GC.CUSTOM_TASK_STATUS_CONFIG]: {
          titleArr: ['titles:task-status', 'Task Status'],
          component: <TaskStatusForm taskTypeList={taskTypeList} submitAction={createOrUpdateTaskStatusRequest} />,
        },
      };

      const component = R.path([configName, 'component'], map);
      const title = G.getAddTitle(R.path([configName, 'titleArr'], map));

      const modal = getModalWithComponent(component, { title, maxHeight: '87vh', overflow: 'auto' });

      openModal(modal);
    },
    handleEditTaskManagementConfig: (props: Object) => ({ configName, initialValues }: Object) => {
      const {
        openModal,
        taskTypeList,
        createOrUpdateTaskTypeRequest,
        createOrUpdateTaskStatusRequest,
      } = props;

      const map = {
        [GC.CUSTOM_TASK_TYPE_CONFIG]: {
          titleArr: ['titles:task-type', 'Task Type'],
          component: <TaskTypeForm initialValues={initialValues} submitAction={createOrUpdateTaskTypeRequest} />,
        },
        [GC.CUSTOM_TASK_STATUS_CONFIG]: {
          titleArr: ['titles:task-status', 'Task Status'],
          component: (
            <TaskStatusForm
              taskTypeList={taskTypeList}
              initialValues={initialValues}
              submitAction={createOrUpdateTaskStatusRequest}
            />
          ),
        },
      };

      const component = R.path([configName, 'component'], map);
      const title = G.getEditTitle(R.path([configName, 'titleArr'], map));

      const modal = getModalWithComponent(component, { title, maxHeight: '87vh', overflow: 'auto' });

      openModal(modal);
    },
    handleRemoveTaskManagementConfig: (props: Object) => ({ guid, configName }: Object) => {
      const {
        removeTaskTypeRequest,
        removeTaskStatusRequest,
      } = props;

      const actionsMap = {
        [GC.CUSTOM_TASK_TYPE_CONFIG]: removeTaskTypeRequest,
        [GC.CUSTOM_TASK_STATUS_CONFIG]: removeTaskStatusRequest,
      };

      return G.callFunctionWithArgs(R.prop(configName, actionsMap), guid);
    },
  }),
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    pageTitle='titles:task-management'
    groupSettings={GC.TASK_MANAGEMENT_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  taskTypeList: makeSelectTaskTypeList()(state),
  taskStatusList: makeSelectTaskStatusList()(state),
  configOptions: makeSelectConfigOptions(state, GC.TASK_MANAGEMENT_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.TASK_MANAGEMENT_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.TASK_MANAGEMENT_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  removeTaskTypeRequest,
  removeTaskStatusRequest,
  createOrUpdateTaskTypeRequest,
  createOrUpdateTaskStatusRequest,
  restoreInheritedByConfigTypeRequest,
})(enhance(ConfigPageComponent));
