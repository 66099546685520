import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useMemo, useEffect, useCallback } from 'react';
// components
import { Table } from '../../../../components/table';
import { PageTitle } from '../../../../components/page-title';
import { ConfirmComponent } from '../../../../components/confirm';
// features
import PC from '../../../permission/role-permission';
import { itemsSettings } from '../../../dispatch-details-new/settings/column-settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import * as I from '../../../../svgs';
// ui
import { Box, Flex, MainActionButton } from '../../../../ui';
// feature claim-management
import DamagedItemForm from '../forms/damaged-item';
import { makeSelectCloItems, makeSelectDamagedItemsByCloItemGuid } from '../../selectors';
import {
  toggleCloItem,
  getCloItemsRequest,
  removeDamagedItemRequest,
  createOrUpdateDamagedItemRequest,
  getDamagedItemsByCloItemGuidRequest,
} from '../../actions';
//////////////////////////////////////////////////

const defaultTableSettings = {
  rowHeight: 40,
  cellFontSize: 11,
  titleFontSize: 11,
  maxHeight: '400px',
  titleRowHeight: 32,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 0,
  expandableItems: false,
  allowSelectItems: false,
  checkBoxCellJustifyContent: 'unset',
};

const DamageTable = (props: Object) => {
  const { dispatch, openModal, claimGuid, cloItemGuid, packageType } = props;

  const itemList = useSelector((state: Object) => makeSelectDamagedItemsByCloItemGuid(state, cloItemGuid));

  useEffect(() => {
    if (R.isNil(itemList)) dispatch(getDamagedItemsByCloItemGuidRequest({ claimGuid, cloItemGuid }));
  }, []);

  const handleCreateOrUpdateDamagedItem = useCallback((entity: Object) => {
    const initialValues = R.or(
      entity,
      {
        claimGuid,
        packageType,
        cloItemGuid,
        [GC.FIELD_CURRENCY]: G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
      },
    );
    const submitAction = (values: Object) => dispatch(createOrUpdateDamagedItemRequest(values));

    const component = <DamagedItemForm submitAction={submitAction} initialValues={initialValues} />;

    const modal = {
      p: 15,
      component,
      options: {
        overflow: 'auto',
        maxHeight: '90vh',
        title: G.ifElse(
          R.isNotNil(R.prop(GC.FIELD_VERSION, entity)),
          G.getEditTitle,
          G.getAddTitle,
        )(['titles:damaged-item', 'Damaged Item']),
      },
    };

    openModal(modal);
  }, []);

  const handleRemoveDamagedItem = useCallback((guid: string) => {
    const textLocale = G.getWindowLocale(
      'messages:confirm-delete-entity',
      'Are you sure you want to delete this entity?',
    );

    const component = <ConfirmComponent textLocale={textLocale} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:delete', 'Delete'),
            action: () => dispatch(removeDamagedItemRequest({ guid, cloItemGuid })),
          },
        ],
      },
    };

    openModal(modal);
  }, []);

  const tableProps = useMemo(() => {
    const columnSettings = {
      [GC.FIELD_ITEM_QUANTITY]: {
        width: 100,
        name: 'titles:quantity',
      },
      [GC.FIELD_ITEM_PACKAGE_TYPE]: {
        width: 100,
        type: 'enumLocale',
        name: 'titles:package-type',
      },
      [GC.FIELD_DAMAGED_VALUE]: {
        width: 120,
        type: 'number',
        name: 'titles:damaged-value',
      },
      [GC.FIELD_PRODUCT_VALUE]: {
        width: 120,
        type: 'number',
        name: 'titles:product-value',
      },
      [GC.FIELD_AWARDED_VALUE]: {
        width: 120,
        name: 'titles:awarded-value',
        customComponent: ({ data: { awardedValue } }: Object) => (
          G.isNilOrZero(awardedValue) ? 0 : G.toFixed(awardedValue)
        ),
      },
      [GC.FIELD_OUTSTANDING_TOTAL]: {
        width: 120,
        name: 'titles:outstanding-total',
        customComponent: ({ data: { outstandingTotal } }: Object) => (
          G.isNilOrZero(outstandingTotal) ? 0 : G.toFixed(outstandingTotal)
        ),
      },
      [GC.FIELD_CLOSED_TOTAL]: {
        width: 120,
        name: 'titles:closed-total',
        label: ['titles:closed-total'],
        customComponent: ({ data: { closedTotal } }: Object) => (
          G.isNilOrZero(closedTotal) ? 0 : G.toFixed(closedTotal)
        ),
      },
      [GC.FIELD_CURRENCY]: {
        width: 100,
        name: 'titles:currency',
      },
      [GC.FIELD_DESCRIPTION]: {
        width: 400,
        name: 'titles:description',
      },
    };

    const actionButtons = [
      {
        iconName: 'pencil',
        permissions: [PC.CLAIM_WRITE],
        action: (_: string, entity: Object) => handleCreateOrUpdateDamagedItem(entity),
      },
      {
        iconName: 'trash',
        permissions: [PC.CLAIM_WRITE],
        action: handleRemoveDamagedItem,
      },
    ];

    const reportFields = R.compose(
      G.mapIndexed((name: string, sequence: number) => ({ name, sequence: R.inc(sequence) })),
      R.keys,
    )(columnSettings);

    return {
      actionButtons,
      columnSettings,
      loading: false,
      allChecked: false,
      report: { fields: reportFields },
      tableSettings: {
        ...defaultTableSettings,
        checkBoxCellWidth: 55,
      },
    };
  }, []);

  return (
    <Box p={15} width='max-content'>
      <Flex>
        <PageTitle
          mr='32px'
          withCount={false}
          title={G.getWindowLocale('titles:damaged-items', 'Damaged Items')}
        />
        <MainActionButton onClick={() => handleCreateOrUpdateDamagedItem()}>
          {G.getWindowLocale('titles:add-damaged-item', 'Add Damaged Item')}
        </MainActionButton>
      </Flex>
      <Table {...tableProps} itemList={R.or(itemList, [])} />
    </Box>
  );
};

const CloItems = (props: Object) => {
  const { dispatch } = props;

  const itemList = useSelector(makeSelectCloItems());

  useEffect(() => {
    if (R.isNil(itemList)) dispatch(getCloItemsRequest());
  }, []);

  const tableProps = useMemo(() => {
    if (R.isNil(itemList)) return {};

    const columnSettings = {
      toggleItem: {
        width: 150,
        freezed: true,
        customComponent: ({ data: { guid, expanded } }: Object) => (
          <Box cursor='pointer' color='light.blue' onClick={() => dispatch(toggleCloItem(guid))}>
            {G.getWindowLocale(`actions:${G.ifElse(expanded, 'hide', 'show')}`)} {
              G.getWindowLocale('titles:damaged-items', 'Damaged Items')} {
                I[G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple')](G.getTheme('colors.light.blue'))}
          </Box>
        ),
      },
      ...R.pick(
        [
          GC.FIELD_ITEM_ID,
          GC.FIELD_QUANTITY,
          GC.FIELD_PACKAGE_TYPE,
          GC.FIELD_ITEM_WEIGHT,
          GC.FIELD_ITEM_WEIGHT_TYPE,
          GC.FIELD_ITEM_DIMENSIONS_LENGTH,
          GC.FIELD_ITEM_DIMENSIONS_WIDTH,
          GC.FIELD_ITEM_DIMENSIONS_HEIGHT,
          GC.FIELD_ITEM_DIMENSIONS_UOM,
          GC.FIELD_ITEM_TEMPERATURE_LOW,
          GC.FIELD_ITEM_TEMPERATURE_HIGH,
          GC.FIELD_ITEM_TEMPERATURE_UOM,
          GC.FIELD_ITEM_HAZARDOUS,
          GC.FIELD_DESCRIPTION,
        ],
        itemsSettings,
      ),
    };

    const reportFields = R.compose(
      G.mapIndexed((name: string, sequence: number) => ({
        name,
        sequence,
        freezed: R.path([name, 'freezed'], columnSettings),
      })),
      R.keys,
      R.omit(R.or(G.getKeysWithEmptyValues(R.or(itemList, [])), [])),
    )(columnSettings);

    const tableSettings = {
      ...defaultTableSettings,
      expandedDetailsComponent: (row: Object) => (
        <DamageTable
          {...props}
          cloItemGuid={R.path(['parentProps', GC.FIELD_GUID], row)}
          packageType={R.path(['parentProps', GC.FIELD_PACKAGE_TYPE], row)}
        />
      ),
    };

    return {
      itemList,
      tableSettings,
      columnSettings,
      loading: false,
      allChecked: false,
      withoutWaypoint: true,
      report: { fields: reportFields },
    };
  }, [itemList]);

  return <Table {...tableProps} />;
};

export default CloItems;
