import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { ActionBox } from '../../../components/action-box';
// ui
import { Flex, Text } from '../../../ui';
// feature components-story
import { setShowHideText } from '../actions';
import { makeSelectShowHideText } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ActionBoxComponent = ({ showHideText, setShowHideText }: Object) => {
  const description = '<ActionBox /> component is used to styled text for some action';

  const usage = (
    <ul>
      <li>Set field `<i>text</i>` as the content.</li>
      <li>Set `<i>action</i>` to execute a particular action or function when the user clicks on the component.</li>
      <li>Parameter `<i>boxStyles</i>` accepts an object of styles to be applied to the component.</li>
    </ul>
  );

  const boxStyles = {
    maxWidth: 250,
    padding: '7px',
    marginRight: 20,
    borderRadius: '5px',
    borderColor: 'demin',
    border: '2px dashed',
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ActionBox'
      description={description}
      path='src/components/action-box'
    >
      <Flex>
        <ActionBox
          boxStyles={boxStyles}
          action={setShowHideText}
          text='click to toogle hide text'
        />
        {showHideText && <Text>hidden text...</Text>}
      </Flex>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  showHideText: makeSelectShowHideText(state),
});

export default connect(mapStateToProps, {
  setShowHideText,
})(ActionBoxComponent);
