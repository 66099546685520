import React, { useState, useEffect } from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const CountUp = ({ startTime }: Object) => {
  const [{ days, hours }, setTimer] = useState({ days: 0, hours: 0 });

  useEffect(() => {
    const handleStart = () => {
      const now = new Date();
      const diff = now - startTime;

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);

      setTimer({ days, hours });
    };

    handleStart();

    const interval = setInterval(handleStart, 300000);

    return () => clearInterval(interval);
  }, []);

  const text = `${days} ${G.getWindowLocale('titles:days', 'Days')}, ${hours} ${
    G.getWindowLocale('titles:hours', 'Hours')}`;

  return (
    <TextComponent
      title={text}
      maxWidth='90%'
      display='block'
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};

export default CountUp;
