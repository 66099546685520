import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// features
import Dashboards from '../dashboards';
import { setDashboardTypeToStore } from '../dashboards/actions';
import { DASHBOARD_TYPE_FLEET_TRAILER } from '../dashboards/constants';
// helpers/constants
import * as G from '../../helpers';
// ui
import { ListWrapper } from '../../ui';
//////////////////////////////////////////////////

const TrailerDashboards = (props: Object) => {
  const { tabs } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDashboardTypeToStore(DASHBOARD_TYPE_FLEET_TRAILER));
  }, []);

  return (
    <ListWrapper
      pl='0px'
      p='30px 15px 60px 0px'
      bgColor={G.getTheme('colors.bgGrey')}
    >
      {tabs}
      <Dashboards {...props} noWrapper={true} />
    </ListWrapper>
  );
};

export {
  TrailerDashboards,
};
