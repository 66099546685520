// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.GRC.CARRIER_NAME]: {
    width: 200,
    name: 'titles:company-name',
  },
  [GC.GRC.CARRIER_USDOT_NUMBER]: {
    width: 200,
    name: 'titles:usdot-number',
  },
  [GC.GRC.CARRIER_MC_NUMBER]: {
    width: 250,
    name: 'titles:mc-number',
  },
  [GC.GRC.CARRIER_ACTIVE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:status',
  },
  [`${GC.FIELD_CARRIER_INSURANCE_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 250,
    customLogic: true,
    isRedirectLink: true,
    name: ['titles:insurance', 'titles:type'],
  },
  [GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT]: {
    width: 250,
    customLogic: true,
    name: ['titles:insurance', 'titles:coverage-amount'],
  },
  [GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE]: {
    width: 250,
    customLogic: true,
    name: ['titles:insurance', 'titles:effective-date'],
  },
  [GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE]: {
    width: 250,
    customLogic: true,
    name: ['titles:insurance', 'titles:expiration-date'],
  },
};

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.GRC.CARRIER_NAME,
    name: G.getWindowLocale('titles:company-name', 'Company Name'),
  },
  {
    type: 'string',
    value: GC.GRC.CARRIER_USDOT_NUMBER,
    name: G.getWindowLocale('titles:usdot-number', 'USDOT Number'),
  },
  {
    type: 'string',
    value: GC.GRC.CARRIER_MC_NUMBER,
    name: G.getWindowLocale('titles:mc-number', 'MC Numbers'),
  },
  {
    type: 'boolean',
    value: GC.GRC.CARRIER_ACTIVE,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'string',
    collection: true,
    value: `${GC.FIELD_CARRIER_INSURANCE_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
        ${G.getWindowLocale('titles:type', 'Type')}
    `,
  },
  {
    type: 'number',
    collection: true,
    value: GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
        ${G.getWindowLocale('titles:coverage-amount', 'Coverage Amount')}
    `,
  },
  {
    type: 'date',
    collection: true,
    value: GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
        ${G.getWindowLocale('titles:effective-date', 'Effective Date')}
    `,
  },
  {
    type: 'date',
    collection: true,
    value: GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
        ${G.getWindowLocale('titles:expiration-date', 'Expiration Date')}
    `,
  },
];
