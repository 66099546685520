import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import SingleTextareaForm from '../../../forms/forms/single-textarea-form';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleFormSubmit: ({ closeModal, initialValues, handleActionLoadChatMessage }: Object) => (text: any) => {
      const data = G.ifElse(
        G.isNotNil(initialValues),
        R.assoc('text', text, initialValues),
        { text },
      );

      handleActionLoadChatMessage(data);
      closeModal();
    },
  }),
  pure,
);

const ChatMessageForm = ({ initialValues, handleFormSubmit }: Object) => (
  <SingleTextareaForm
    submitHandler={handleFormSubmit}
    inputStyles={{ width: 350, height: 150 }}
    initialValues={{ value: R.prop('text', initialValues) }}
  />
);

export default enhance(ChatMessageForm);
