import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature fuel-cards
import AssignTruckForm from './assign-truck';
import AssignDriverComponent from './assign-driver';
import EditFuelCardForm from './edit-fuel-card-form';
import { getTruckOptions, getDriverOptions } from '../helpers';
import {
  updateFuelCardRequest,
  deleteFuelCardsRequest,
  updateTruckFuelCardRequest,
  updateDriverFuelCardRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  connect(null, {
    updateFuelCardRequest,
    deleteFuelCardsRequest,
    updateTruckFuelCardRequest,
    updateDriverFuelCardRequest,
  }),
  withHandlers({
    handleAssignDriver: (props: Object) => () => {
      const {
        guid,
        openModal,
        driverGuid,
        closeModal,
        driverList,
        driverLastName,
        driverFirstName,
        closeFixedPopup,
        currentBranchGuid,
        fuelCardDriverGuid,
        updateDriverFuelCardRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const initialValues = { [GC.FIELD_DRIVER_GUID]: fuelCardDriverGuid };
      const assignDriverProps = {
        guid,
        driverGuid,
        driverList,
        closeModal,
        initialValues,
        driverLastName,
        driverFirstName,
        currentBranchGuid,
        submitAction: updateDriverFuelCardRequest,
      };
      const component = <AssignDriverComponent {...assignDriverProps} />;
      const modal = {
        p: '15px',
        component,
        options: {
          title: G.getWindowLocale('titles:assign-driver', 'Assign Driver'),
        },
      };
      openModal(modal);
    },
    handleAssignTruck: (props: Object) => () => {
      const {
        guid,
        openModal,
        truckList,
        truckGuid,
        closeModal,
        truckUnitId,
        closeFixedPopup,
        fuelCardTruckGuid,
        updateTruckFuelCardRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const initialValues = { [GC.FIELD_TRUCK_GUID]: R.or(fuelCardTruckGuid, null) };
      const assignTruckProps = {
        guid,
        truckList,
        truckGuid,
        closeModal,
        truckUnitId,
        initialValues,
        submitAction: updateTruckFuelCardRequest,
      };
      const component = <AssignTruckForm {...assignTruckProps} />;
      const modal = {
        p: '15px',
        component,
        options: {
          title: G.getWindowLocale('titles:assign-truck', 'Assign Truck'),
        },
      };
      openModal(modal);
    },
    handleEditFuelCard: (props: Object) => () => {
      const {
        fuelCard,
        openModal,
        truckGuid,
        closeModal,
        driverList,
        driverGuid,
        openLoader,
        closeLoader,
        closeFixedPopup,
        updateFuelCardRequest,
      } = props;

      const fuelCardDriverGuid = R.prop(GC.FIELD_DRIVER_GUID, fuelCard);
      let title = G.getEditTitle(['titles:fuel-transaction', 'Fuel Transaction']);

      if (R.and(G.isNotNilAndNotEmpty(fuelCardDriverGuid), G.isNotNilAndNotEmpty(driverList))) {
        title = `${title} - ${
          R.pathOr('', [fuelCardDriverGuid, GC.FIELD_FIRST_NAME], driverList)
        } ${
          R.pathOr('', [fuelCardDriverGuid, GC.FIELD_LAST_NAME], driverList)
        } (${R.pathOr('', [fuelCardDriverGuid, GC.FIELD_USER_LOGIN_ID], driverList)})`;
      }

      const optionsForSelect = {
        truckOptions: getTruckOptions(props),
        driverOptions: getDriverOptions(props),
      };

      const component = (
        <EditFuelCardForm
          titleText={title}
          truckGuid={truckGuid}
          driverGuid={driverGuid}
          openLoader={openLoader}
          closeModal={closeModal}
          closeLoader={closeLoader}
          optionsForSelect={optionsForSelect}
          submitAction={updateFuelCardRequest}
          fuelCardGuid={G.getGuidFromObject(fuelCard)}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
        },
      };

      closeFixedPopup();
      openModal(modal);
    },
    handleDeleteFuelCard: (props: Object) => () => {
      const {
        fuelCard,
        openModal,
        driverList,
        closeFixedPopup,
        deleteFuelCardsRequest } = props;
      const name = `${G.getWindowLocale('titles:fuel-card', 'Fuel Card')}: ${
        R.pathOr('', [R.prop(GC.FIELD_DRIVER_GUID, fuelCard), GC.FIELD_FIRST_NAME], driverList)} ${
        R.pathOr('', [R.prop(GC.FIELD_DRIVER_GUID, fuelCard), GC.FIELD_LAST_NAME], driverList)} (${
          R.pathOr('', [R.prop(GC.FIELD_DRIVER_GUID, fuelCard), GC.FIELD_USER_LOGIN_ID], driverList)})`;
      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-text-double',
        'Are you sure you want to delete',
      );
      const component = (
        <ConfirmComponent
          name={name}
          textLocale={textLocale} />
      );
      const modal = {
        component,
        options: {
          width: 600,
          height: 160,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => deleteFuelCardsRequest(R.of(Array, G.getGuidFromObject(fuelCard))),
            },
          ],
        },
      };
      closeFixedPopup();
      openModal(modal);
    },
  }),
  pure,
);

const getOptions = (props: Object) => {
  const {
    truckGuid,
    driverGuid,
    handleAssignTruck,
    handleEditFuelCard,
    handleAssignDriver,
    handleDeleteFuelCard } = props;
  const iconColor = G.getTheme('colors.dark.blue');
  const assignDriverRowAction = {
    frontIcon: I.driver(iconColor),
    action: handleAssignDriver,
    permissions: [PC.FLEET_FUEL_CARD_WRITE],
    text: G.getWindowLocale('titles:assign-driver', 'Assign Driver'),
  };
  const assignTruckRowAction = {
    action: handleAssignTruck,
    frontIcon: I.truck(iconColor),
    permissions: [PC.FLEET_FUEL_CARD_WRITE],
    text: G.getWindowLocale('titles:assign-truck', 'Assign Truck'),
  };
  let actions;
  if (G.isNotNilAndNotEmpty(truckGuid)) {
    actions = R.of(Array, assignDriverRowAction);
  } else if (G.isNotNilAndNotEmpty(driverGuid)) {
    actions = R.of(Array, assignTruckRowAction);
  } else {
    actions = [assignDriverRowAction, assignTruckRowAction];
  }
  const editDeleteActions = [
    {
      action: handleEditFuelCard,
      frontIcon: I.pencil(iconColor),
      permissions: [PC.FLEET_FUEL_CARD_WRITE],
      text: G.getWindowLocale('actions:edit', 'Edit'),
    },
    {
      action: handleDeleteFuelCard,
      frontIcon: I.trash(iconColor),
      permissions: [PC.FLEET_FUEL_CARD_WRITE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];
  return R.concat(editDeleteActions, actions);
};

export const RowActions = enhance((props: Object) => <ActionsElement version={2} options={getOptions(props)} />);
