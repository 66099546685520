import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import CrossBorderIntegrationForm from '../../forms/cross-border-integration-form';
import {
  updateCrossBorderIntegrationRequest,
  removeCrossBorderIntegrationRequest,
} from '../actions';
//////////////////////////////////////////////////

const withCrossBorderIntegrationTableGroupRowActions = compose(
  connect(
    null,
    {
      updateCrossBorderIntegrationRequest,
      removeCrossBorderIntegrationRequest,
    },
  ),
  withHandlers({
    handleUpdateCrossBorderIntegration: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        updateCrossBorderIntegrationRequest,
      } = props;

      const initialClosObject = R.indexBy(R.path(['cloGuid']), entity.clos);
      const closObject = R.indexBy(R.path(['guid']), load.clos);
      const clos = R.mergeDeepRight(closObject, initialClosObject);

      const component = (
        <CrossBorderIntegrationForm
          initialValues={entity}
          cloList={R.values(clos)}
          submitAction={updateCrossBorderIntegrationRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 890,
          title: G.getWindowLocale('titles:send-to-cross-border', 'Send To Cross Border'),
        },
      };

      openModal(modal);
    },
    handleRemoveCrossBorderIntegration: (props: Object) => () => {
      const { entity, openModal, removeCrossBorderIntegrationRequest } = props;

      const textLocale = G.getWindowLocale(
        'messages:remove-confirmation-cross-border-integration',
        'Are you sure you want delete this cross border integration?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeCrossBorderIntegrationRequest(entity[GC.FIELD_GUID]),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const withAddCrossBorder = compose(
  withHandlers({
    handleAddCrossBorder: (props: Object) => () => {
      const { load, openModal, createCrossBorderIntegrationRequest } = props;

      const { clos, guid } = load;

      const handleCreateCrossBorderIntegration = (props: Object) => {
        const { clos, values, saveAndSend } = props;

        const requestData = {
          clos,
          guid,
          values,
          saveAndSend,
        };

        createCrossBorderIntegrationRequest(requestData);
      };

      const component = (
        <CrossBorderIntegrationForm
          cloList={clos}
          submitAction={handleCreateCrossBorderIntegration}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 890,
          title: G.getWindowLocale('titles:send-to-cross-border', 'Send To Cross Border'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export {
  withAddCrossBorder,
  withCrossBorderIntegrationTableGroupRowActions,
};
