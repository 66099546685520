import React from 'react';
// components
import { ItemInfo, ContainerInfo } from '../../../components/events';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const ShipUnitDetails = ({ items, containers }: Object) => (
  <Flex py='8px' overflow='auto' maxHeight={500}>
    {
      G.isNotNilAndNotEmpty(items) &&
      <Flex
        px={16}
        fontSize={12}
        flexWrap='wrap'
        flexDirection='column'
        alignItems='flex-start'
      >
        {
          items.map((item: Object, index: number) => (
            <ItemInfo {...item} key={index} singleItem={false} maxWidth='max-content' />
          ))
        }
      </Flex>
    }
    {
      G.isNotNilAndNotEmpty(containers) &&
      <Flex
        px={16}
        fontSize={12}
        flexWrap='wrap'
        flexDirection='column'
        alignItems='flex-start'
      >
        {
          containers.map((item: Object, index: number) => (
            <ContainerInfo {...item} labelStart='' key={index} />
          ))
        }
      </Flex>
    }
  </Flex>
);

export default ShipUnitDetails;
