// features
import { importStatusesTypes, importTypesWithCarrierRatePrice } from '../../new-import/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'selectMultiple',
    value: GC.FIELD_STATUS,
    options: importStatusesTypes,
    name: G.getWindowLocale('titles:status', 'Status'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_NAME,
    name: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: GC.FIELD_TYPE,
    type: 'selectMultiple',
    options: importTypesWithCarrierRatePrice,
    name: G.getWindowLocale('titles:import-type', 'Import Type'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: 'importerByLoginId',
    name: G.getWindowLocale('titles:importer', 'Importer'),
  },
  {
    type: 'date',
    value: 'startDate',
    name: G.getWindowLocale('titles:start-date', 'Start Date'),
  },
  {
    type: 'date',
    value: 'endDate',
    name: G.getWindowLocale('titles:end-date', 'End Date'),
  },
];
