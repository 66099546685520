import { mailSettings } from './mail';
import { auditSettings } from './audit';
import { safetySettings } from './safety';
import { terminalSettings } from './terminal';
import { documentSettings } from './document';
import { referenceSettings } from './reference';
import { insuranceSettings } from './insurance';
import { statisticSettings } from './statistic';
import { bidHistorySettings } from './bid-history';
import { scorecardsSettings } from './score-cards';
import { certificateSettings } from './certificate';
import { terminalContactSettings } from './terminal-contact';
import { internalDispatchSettings } from './internal-dispatch';
import { associatedCompanySettings } from './associated-company';
import { additionalContactSettings } from './additional-contacts';
import { carrierContractRatePriceSettings } from './contract-rate';
import { carrierPortalLoginSettings } from './carrier-portal-login';
import { financeSettings, terminalFinanceSettings } from './finance';
import { accessorialSettings, AccessorialFormGroupTable } from './accessorial';
import { carrierContractSettings, customerContractSettings } from './contracts';
import { payToLocationSettings, terminalPayToLocationSettings } from './pay-to-location';
import { carrierContractBillToSettings, customerContractBillToSettings } from './bill-to';
import {
  carrierContractAwardedLaneSettings,
  customerContractAwardedLaneSettings,
} from './awarded-lane';
import {
  variableValuesSettings,
  awardedLaneVariableDiscountSettings,
  customerContractVariableDiscountSettings,
} from './variable-discounts';
import {
  integrationInfoSettings,
  integrationAuditSettings,
  synchronizationInfoSettings,
  loadBoardIntegrationInfoSettings,
} from './integration';
//////////////////////////////////////////////////

const generalSettings = [
  internalDispatchSettings,
  carrierPortalLoginSettings,
  associatedCompanySettings,
  terminalSettings,
  safetySettings,
  insuranceSettings,
  referenceSettings,
  loadBoardIntegrationInfoSettings,
  synchronizationInfoSettings,
  integrationInfoSettings,
  integrationAuditSettings,
];

const documentTabSettings = [
  documentSettings,
  mailSettings,
];

const terminalGeneralTabSettings = [
  terminalContactSettings,
];

const terminalFinanceTabSettings = [
  terminalFinanceSettings,
  terminalPayToLocationSettings,
];

const certificateTabSettings = [
  certificateSettings,
];

const additionalContactTabSettings = [
  additionalContactSettings,
];

const ratingEngineTabSettings = [
  financeSettings,
  payToLocationSettings,
  accessorialSettings,
  carrierContractSettings,
  customerContractSettings,
];

const scorecardsTabSettings = [scorecardsSettings];

const statisticTabSettings = [statisticSettings];

const bidHistoryTabSettings = [bidHistorySettings];

const auditTabSettings = [auditSettings];

export {
  generalSettings,
  auditTabSettings,
  documentTabSettings,
  statisticTabSettings,
  scorecardsTabSettings,
  bidHistoryTabSettings,
  certificateTabSettings,
  variableValuesSettings,
  ratingEngineTabSettings,
  AccessorialFormGroupTable,
  terminalFinanceTabSettings,
  terminalGeneralTabSettings,
  additionalContactTabSettings,
  carrierContractBillToSettings,
  customerContractBillToSettings,
  carrierContractRatePriceSettings,
  carrierContractAwardedLaneSettings,
  customerContractAwardedLaneSettings,
  awardedLaneVariableDiscountSettings,
  customerContractVariableDiscountSettings,
};
