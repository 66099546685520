import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { Toggle, FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature fuel-cards
import { getTruckOptions } from '../helpers';
import { toggleShowIsNotInServiceTrucks } from '../actions';
import { makeSelectShowIsNotInServiceTrucks } from '../selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  showIsNotInServiceTrucks: makeSelectShowIsNotInServiceTrucks(state),
});

const assignTruckField = {
  width: '300px',
  errorTop: '110%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  type: 'searchselect',
  flexDirection: 'column',
  options: 'truckOptions',
  errorPosition: 'absolute',
  loc: 'titles:select-truck',
  fieldName: GC.FIELD_TRUCK_GUID,
};

const enhance = compose(
  connect(mapStateToProps, { toggleShowIsNotInServiceTrucks }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => initialValues,
    handleSubmit: ({ truckGuid }: Object, { props }: Object) => {
      const { guid, submitAction } = props;

      submitAction({ guid, truckGuid });
    },
  }),
  withHandlers({
    handleToggleShowIsNotInServiceTrucks: (props: Object) => () => {
      const {
        setFieldValue,
        showIsNotInServiceTrucks,
        toggleShowIsNotInServiceTrucks,
      } = props;

      toggleShowIsNotInServiceTrucks();

      if (showIsNotInServiceTrucks) setFieldValue(GC.FIELD_TRUCK_GUID, null);
    },
  }),
  pure,
);

export const AssignTruckForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Flex pl='5px' my={10}>
      <Toggle
        icons={false}
        checked={props.showIsNotInServiceTrucks}
        onChange={props.handleToggleShowIsNotInServiceTrucks}
      />
      <Box
        ml={10}
        cursor='pointer'
        onClick={props.handleToggleShowIsNotInServiceTrucks}
      >
        {G.getWindowLocale('titles:show-all-trucks', 'Show All Trucks')}
      </Box>
    </Flex>
    <FieldsetComponent
      fields={R.of(Array, assignTruckField)}
      {...G.getFormikPropsToFieldset(props)}
      optionsForSelect={{ truckOptions: getTruckOptions(props) }}
    />
    <FormFooter boxStyles={{ p: '5px' }} closeModal={props.closeModal} />
  </form>
);

export default enhance(AssignTruckForm);
