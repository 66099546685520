import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { memo, useMemo } from 'react';
// components
import { Table } from '../../../components/table';
// forms
import { FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet-profile
import { FormGroupTitle } from './form-group-title';
import { toggleFormGroupTableDetails } from '../actions';
import { tableSettings } from '../settings/table-settings';
import { useFormGroupOperations } from '../hooks/use-form-group-operations';
//////////////////////////////////////////////////

const makeReport = ({ report, itemList, hideEmptyFields }: Object) => {
  if (R.not(hideEmptyFields)) return report;

  const fields = R.filter(
    ({ name }: Object) => G.isNotNilAndNotEmpty(R.path([0, ...R.split('.', name)], itemList)),
    report.fields,
  );

  return { fields };
};

const useTableProps = (props: Object) => {
  const {
    report,
    itemList,
    groupName,
    endpoints,
    paidByTrip,
    configGroup,
    columnSettings,
    hideEmptyFields,
    downloadOptions,
    handleRemoveItem,
    handleShowDetails,
    primaryObjectGuid,
    getEditPermissions,
    primaryObjectGuidKey,
    getRemovePermissions,
    handlePreviewDocument,
    handleDownloadDocument,
    handleCreateOrUpdateItem,
    tableCallbackDataProps = [],
    additionalTableSettings = {},
    handleOpenLastKnownLocationAudit,
    handleUpdateAdvancePaymentStatus,
    actionsPicker = ['edit', 'remove'],
    includeItemListToTablePropsMemoDeps = false,
  } = props;

  const dispatch = useDispatch();

  const deps = G.ifElse(
    R.or(hideEmptyFields, includeItemListToTablePropsMemoDeps),
    [itemList, groupName, configGroup],
    [groupName],
  );

  const tableProps = useMemo(() => {
    const actionButtonsMap = {
      edit: {
        iconName: 'pencil',
        getPermissions: getEditPermissions,
        action: (guid: string, entity: Object) => handleCreateOrUpdateItem(entity),
      },
      updateAdvancePaymentStatus: {
        iconName: 'pencil',
        action: (guid: string, entity: Object) => handleUpdateAdvancePaymentStatus(entity),
      },
      remove: {
        iconName: 'trash',
        action: handleRemoveItem,
        getPermissions: getRemovePermissions,
        enableIfEditable: R.equals(groupName, 'inspections'),
      },
      removePayrollCompensation: {
        iconName: 'trash',
        action: handleRemoveItem,
        permissions: G.ifElse(R.not(paidByTrip), ''),
      },
      lastKnownLocationAudit: {
        iconSize: [18, 18],
        iconName: 'renderAuditHistoryIcon',
        action: handleOpenLastKnownLocationAudit,
      },
      downloadDocument: {
        iconName: 'downloadDocument',
        getPermissions: ({ fileUri }: Object) => G.ifElse(R.isNil(fileUri), ''),
        action: (guid: string, entity: Object) => handleDownloadDocument(
          entity,
          downloadOptions,
          { primaryObjectGuidKey, endpointName: G.getPropFromObject('downloadDocument', endpoints) },
        ),
      },
      previewDocument: {
        iconName: 'eye',
        getPermissions: ({ fileUri }: Object) => G.ifElse(R.isNil(fileUri), ''),
        action: (guid: string, entity: Object) => handlePreviewDocument(
          entity,
          downloadOptions,
          { primaryObjectGuidKey, endpointName: G.getPropFromObject('downloadDocument', endpoints) },
        ),
      },
      showDetails: {
        iconName: 'eye2',
        iconColor: G.getTheme('colors.mainBlue'),
        action: (guid: string) => handleShowDetails(guid, primaryObjectGuid),
      },
    };

    const actionButtons = R.values(R.pick(actionsPicker, actionButtonsMap));

    return {
      actionButtons,
      columnSettings,
      callbackData: R.pick(tableCallbackDataProps, props),
      report: makeReport({ report, itemList, hideEmptyFields }),
      tableSettings: R.mergeRight(tableSettings, additionalTableSettings),
      toggle: ({ guid }: Object) => dispatch(toggleFormGroupTableDetails({ guid, groupName })),
    };
  }, deps);

  return tableProps;
};

const FormGroupTable = memo((props: Object) => {
  const {
    margin,
    itemList,
    totalCount,
    pagination,
    rowBgFunction,
    isOpened = true,
    tableInnerWidth,
    handleLoadMoreEntities,
    hasFormGroupTitle = true,
  } = props;

  const tableProps = useTableProps(props);

  return (
    <FormGroupWrapper m={margin} isOpened={isOpened}>
      {hasFormGroupTitle && <FormGroupTitle {...props} />}
      {
        G.isTrue(isOpened) &&
        <Table
          {...tableProps}
          itemList={itemList}
          pagination={pagination}
          totalCount={totalCount}
          rowBgFunction={rowBgFunction}
          tableInnerWidth={tableInnerWidth}
          handleLoadMoreEntities={handleLoadMoreEntities}
        />
      }
    </FormGroupWrapper>
  );
});

export const FormGroupTableWithSimpleCrudOperations = memo((props: Object) => {
  const formGroupOperations = useFormGroupOperations(props);

  return <FormGroupTable {...props} {...formGroupOperations} />;
});

export default FormGroupTable;
