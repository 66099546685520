import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { FieldsetComponent } from '../../../forms';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature dashboard
import {
  dashboardListField,
  searchGroupListField,
  defaultRightSidebarFields } from '../settings/field-settings';
import {
  ArrowWrapper,
  ToggleButton,
  ChartItemWrapper,
  GroupItemWrapper,
  ListGroupWrapper,
  RightSidebarWrapper,
} from '../ui';
//////////////////////////////////////////////////

const GroupItem = (props: Object) => (
  <GroupItemWrapper isOpen={R.equals(props.openGroup, props.group.group)}>
    <div onClick={() => props.toggleAction(props.group.group)}>
      <span>{props.group.group}</span>
      <span>
        {
          G.ifElse(
            R.equals(props.openGroup, props.group.group),
            I.arrowUpSimple(null, null, '8'),
            I.arrowDownSimple(null, null, '8'),
          )
        }
      </span>
    </div>
    {
      R.equals(props.openGroup, props.group.group) &&
      <Droppable droppableId={props.group.group}>
        {(provided: Object) => (
          <div ref={provided.innerRef}>
            {
              props.group.items.map((chartData: Object, index: number) => {
                const existedChart = G.isNotNilAndNotEmpty(R.find(
                  ({ chart }: Object) => R.equals(chart.guid, chartData.guid),
                  props.existingCharts,
                ));

                if (existedChart) return null;

                return (
                  <Draggable
                    index={index}
                    key={chartData.guid}
                    draggableId={`right-sidebar-dnd-item-${index}`}
                  >
                    {(provided: Object) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ChartItemWrapper>
                          <div>{I[`chart${chartData.chartType}`]()}</div>
                          <div>{chartData.name}</div>
                        </ChartItemWrapper>
                      </div>
                    )}
                  </Draggable>
                );
              })
            }
          </div>
        )}
      </Droppable>
    }
  </GroupItemWrapper>
);

const setOptions = (list: Array) => (
  list.map((option: Object) => ({ label: option.name, value: option.guid }))
);

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultRightSidebarFields,
      props.initialValues,
      props.searchedValues,
    ),
  }),
  withState(
    'openGroup',
    'setOpenGroup',
    ({ groupList }: Object) => R.pathOr('', ['0', 'group'], groupList),
  ),
  withHandlers({
    handleCustomChange: (props: Object) => (event: Object) => {
      props.handleChange(event);
      props.handleFilterGroups(event.target.value);
    },
    handleOpenGroup: (props: Object) => (guid: string) => {
      if (R.equals(props.openGroup, guid)) {
        return props.setOpenGroup('');
      }
      props.setOpenGroup(guid);
    },
    handleChangeDashboard: (props: Object) => ({ field }: Object) => {
      const selectedDashboard = R.find(R.propEq(field.dashboardList, 'guid'), props.dashboardList);
      props.setCurrentDashboard(selectedDashboard);
      props.cleanDashboardCharts();
      R.forEach(
        (chart: Object) => props.getChartDataRequest(chart),
        R.pathOr([], ['chartsDetails'], selectedDashboard),
      );
    },
  }),
  pure,
);

const RightSidebar = enhance((props: Object) => (
  <RightSidebarWrapper isOpen={props.showSidebar}>
    <ToggleButton onClick={() => props.setStatusSidebar(R.not(props.showSidebar))}>
      <ArrowWrapper isOpen={props.showSidebar}>{I.arrowDownSimple()}</ArrowWrapper>
    </ToggleButton>
    <AuthWrapper display='block' has={[PC.DASHBOARD_WRITE]}>
      <Box pb='10px' borderBottom='1px solid' borderColor={G.getTheme('colors.light.darkRed')}>
        <FieldsetComponent
          {...props}
          fields={dashboardListField}
          handlers={{ handleCustomChange: props.handleChangeDashboard }}
          optionsForSelect={{ dashboardOptions: setOptions(props.dashboardList) }}
        />
      </Box>
      <FieldsetComponent
        {...props}
        fieldsetPadding='10px 0 0 0'
        fields={searchGroupListField}
      />
      <ListGroupWrapper>
        {
          G.isNotEmpty(props.currentDashboard) &&
          props.groupList.map((group: Object, index: number) => (
            <GroupItem
              key={index}
              group={group}
              openGroup={props.openGroup}
              toggleAction={props.handleOpenGroup}
              existingCharts={props.dashboardCharts}
            />
          ))
        }
      </ListGroupWrapper>
    </AuthWrapper>
  </RightSidebarWrapper>
));

export default RightSidebar;
