import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const printDriver = createAction('printDriver');
export const getDriverXML = createAction('getDriverXML');
export const getAssignInfo = createAction('getAssignInfo');
export const cleanFormStore = createAction('cleanFormStore');
export const getUsersSuccess = createAction('getUsersSuccess');
export const getUsersRequest = createAction('getUsersRequest');
export const getDriverRequest = createAction('getDriverRequest');
export const assignTruckRequest = createAction('assignTruckRequest');
export const setDocumentsFilter = createAction('setDocumentsFilter');
export const getDriverRefRequest = createAction('getDriverRefRequest');
export const getDriverDocRequest = createAction('getDriverDocRequest');
export const createDriverRequest = createAction('createDriverRequest');
export const updateDriverRequest = createAction('updateDriverRequest');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const unassignTruckRequest = createAction('unassignTruckRequest');
export const assignTrailerRequest = createAction('assignTrailerRequest');
export const toggleFuelCardDetails = createAction('toggleFuelCardDetails');
export const receivedDriverSuccess = createAction('receivedDriverSuccess');
export const getDriverHoursRequest = createAction('getDriverHoursRequest');
export const receivedGetAssignInfo = createAction('receivedGetAssignInfo');
export const toggleDriverFormGroup = createAction('toggleDriverFormGroup');
export const unassignTrailerRequest = createAction('unassignTrailerRequest');
export const updateDriverDocRequest = createAction('updateDriverDocRequest');
export const deleteDriverDocRequest = createAction('deleteDriverDocRequest');
export const createDriverDocRequest = createAction('createDriverDocRequest');
export const getDriverContactRequest = createAction('getDriverContactRequest');
export const getDriverProfileRequest = createAction('getDriverProfileRequest');
export const getListOfDriversRequest = createAction('getListOfDriversRequest');
export const getDriverCitizenRequest = createAction('getDriverCitizenRequest');
export const getDriverLicenseRequest = createAction('getDriverLicenseRequest');
export const assignTeamDriverRequest = createAction('assignTeamDriverRequest');
export const getDriverConfigsRequest = createAction('getDriverConfigsRequest');
export const receivedDriverDocSuccess = createAction('receivedDriverDocSuccess');
export const receivedDriverRefSuccess = createAction('receivedDriverRefSuccess');
export const unassignTeamDriverRequest = createAction('unassignTeamDriverRequest');
export const getAvailableTrucksRequest = createAction('getAvailableTrucksRequest');
export const getAvailableDriversRequest = createAction('getAvailableDriversRequest');
export const createDriverContactRequest = createAction('createDriverContactRequest');
export const receivedDriverHoursSuccess = createAction('receivedDriverHoursSuccess');
export const getExternalDriverIdRequest = createAction('getExternalDriverIdRequest');
export const getExternalDriverIdSuccess = createAction('getExternalDriverIdSuccess');
export const updateDriverContactRequest = createAction('updateDriverContactRequest');
export const deleteDriverContactRequest = createAction('deleteDriverContactRequest');
export const getDriverDailyHoursRequest = createAction('getDriverDailyHoursRequest');
export const getDriverDailyHoursSuccess = createAction('getDriverDailyHoursSuccess');
export const updateDriverDetailsRequest = createAction('updateDriverDetailsRequest');
export const getAvailableTrailersRequest = createAction('getAvailableTrailersRequest');
export const receivedListOfDriversSuccess = createAction('receivedListOfDriversSuccess');
export const receivedDriverContactSuccess = createAction('receivedDriverContactSuccess');
export const receivedDriverLicenseSuccess = createAction('receivedDriverLicenseSuccess');
export const receivedDriverCitizenSuccess = createAction('receivedDriverCitizenSuccess');
export const getDriverAccessorialsRequest = createAction('getDriverAccessorialsRequest');
export const createDriverReferenceRequest = createAction('createDriverReferenceRequest');
export const updateDriverReferenceRequest = createAction('updateDriverReferenceRequest');
export const deleteDriverReferenceRequest = createAction('deleteDriverReferenceRequest');
export const createDriverDailyHoursSuccess = createAction('createDriverDailyHoursSuccess');
export const getDriverCertificationRequest = createAction('getDriverCertificationRequest');
export const getDriverCompensationsRequest = createAction('getDriverCompensationsRequest');
export const createExternalDriverIdRequest = createAction('createExternalDriverIdRequest');
export const createExternalDriverIdSuccess = createAction('createExternalDriverIdSuccess');
export const updateExternalDriverIdRequest = createAction('updateExternalDriverIdRequest');
export const updateExternalDriverIdSuccess = createAction('updateExternalDriverIdSuccess');
export const deleteExternalDriverIdRequest = createAction('deleteExternalDriverIdRequest');
export const validateBeforeChangeDriverTab = createAction('validateBeforeChangeDriverTab');
export const createDriverDailyHoursRequest = createAction('createDriverDailyHoursRequest');
export const updateDriverDailyHoursRequest = createAction('updateDriverDailyHoursRequest');
export const updateDriverDailyHoursSuccess = createAction('updateDriverDailyHoursSuccess');
export const deleteDriverDailyHoursRequest = createAction('deleteDriverDailyHoursRequest');
export const deleteDriverDailyHoursSuccess = createAction('deleteDriverDailyHoursSuccess');
export const toggleDriverDailyHoursDetails = createAction('toggleDriverDailyHoursDetails');
export const getFuelCardListByDriverRequest = createAction('getFuelCardListByDriverRequest');
export const receivedCreateDriverDocSuccess = createAction('receivedCreateDriverDocSuccess');
export const createDriverCertificateRequest = createAction('createDriverCertificateRequest');
export const createDriverAccessorialRequest = createAction('createDriverAccessorialRequest');
export const updateDriverCertificateRequest = createAction('updateDriverCertificateRequest');
export const receivedUpdateDriverDocSuccess = createAction('receivedUpdateDriverDocSuccess');
export const updateDriverAccessorialRequest = createAction('updateDriverAccessorialRequest');
export const deleteDriverCertificateRequest = createAction('deleteDriverCertificateRequest');
export const deleteDriverAccessorialRequest = createAction('deleteDriverAccessorialRequest');
export const receivedDeleteDriverDocSuccess = createAction('receivedDeleteDriverDocSuccess');
export const receivedGetDriverConfigsSuccess = createAction('receivedGetDriverConfigsSuccess');
export const createDriverCompensationRequest = createAction('createDriverCompensationRequest');
export const updateDriverCompensationRequest = createAction('updateDriverCompensationRequest');
export const deleteDriverCompensationRequest = createAction('deleteDriverCompensationRequest');
export const receivedUpdateDriverHoursSuccess = createAction('receivedUpdateDriverHoursSuccess');
export const deletePayrollCompensationRequest = createAction('deletePayrollCompensationRequest');
export const getPayrollCompensationRateRequest = createAction('getPayrollCompensationRateRequest');
export const getPayrollCompensationRateSuccess = createAction('getPayrollCompensationRateSuccess');
export const receivedGetAvailableTrucksSuccess = createAction('receivedGetAvailableTrucksSuccess');
export const receivedDriverAccessorialsSuccess = createAction('receivedDriverAccessorialsSuccess');
export const receivedGetAvailableDriverSuccess = createAction('receivedGetAvailableDriverSuccess');
export const toggleDriverExternalEnableRequest = createAction('toggleDriverExternalEnableRequest');
export const savePayrollCompensationRateRequest = createAction('savePayrollCompensationRateRequest');
export const receivedDriverCertificationSuccess = createAction('receivedDriverCertificationSuccess');
export const receivedDriverCompensationsSuccess = createAction('receivedDriverCompensationsSuccess');
export const receivedCreateDriverContactSuccess = createAction('receivedCreateDriverContactSuccess');
export const receivedUpdateDriverDetailsSuccess = createAction('receivedUpdateDriverDetailsSuccess');
export const receivedUpdateDriverLicenseSuccess = createAction('receivedUpdateDriverLicenseSuccess');
export const receivedUpdateDriverCitizenSuccess = createAction('receivedUpdateDriverCitizenSuccess');
export const receivedUpdateDriverContactSuccess = createAction('receivedUpdateDriverContactSuccess');
export const receivedDeleteDriverContactSuccess = createAction('receivedDeleteDriverContactSuccess');
export const receivedFuelCardListByDriverSuccess = createAction('receivedFuelCardListByDriverSuccess');
export const getDriverLatestKnownLocationRequest = createAction('getDriverLatestKnownLocationRequest');
export const receivedGetAvailableTrailersSuccess = createAction('receivedGetAvailableTrailersSuccess');
export const receivedUpdateDriverReferenceSuccess = createAction('receivedUpdateDriverReferenceSuccess');
export const receivedDeleteDriverReferenceSuccess = createAction('receivedDeleteDriverReferenceSuccess');
export const receivedCreatedDriverReferenceSuccess = createAction('receivedCreatedDriverReferenceSuccess');
export const receivedCreateDriverAccessorialSuccess = createAction('receivedCreateDriverAccessorialSuccess');
export const receivedCreateDriverCertificateSuccess = createAction('receivedCreateDriverCertificateSuccess');
export const createDriverLatestKnownLocationRequest = createAction('createDriverLatestKnownLocationRequest');
export const receivedUpdateDriverAccessorialSuccess = createAction('receivedUpdateDriverAccessorialSuccess');
export const receivedUpdateDriverCertificateSuccess = createAction('receivedUpdateDriverCertificateSuccess');
export const receivedDeleteDriverCertificateSuccess = createAction('receivedDeleteDriverCertificateSuccess');
export const receivedDeleteDriverAccessorialSuccess = createAction('receivedDeleteDriverAccessorialSuccess');
export const receivedCreateDriverCompensationSuccess = createAction('receivedCreateDriverCompensationSuccess');
export const receivedUpdateDriverCompensationSuccess = createAction('receivedUpdateDriverCompensationSuccess');
export const receivedDeleteDriverCompensationSuccess = createAction('receivedDeleteDriverCompensationSuccess');
export const receivedDriverLatestKnownLocationSuccess = createAction('receivedDriverLatestKnownLocationSuccess');
export const downloadDriverCertificationDocumentRequest = createAction('downloadDriverCertificationDocumentRequest');
export const receivedCreateDriverLatestKnownLocationSuccess = createAction(
  'receivedCreateDriverLatestKnownLocationSuccess',
);
// advance payment
export const createAdvancePaymentSuccess = createAction('createAdvancePaymentSuccess');
export const createAdvancePaymentRequest = createAction('createAdvancePaymentRequest');
export const getAdvancePaymentListSuccess = createAction('getAdvancePaymentListSuccess');
export const getAdvancePaymentListRequest = createAction('getAdvancePaymentListRequest');
export const updateAdvancePaymentStatusRequest = createAction('updateAdvancePaymentStatusRequest');
export const updateAdvancePaymentStatusSuccess = createAction('updateAdvancePaymentStatusSuccess');
export const getDriverPayrollNumberListByGuidsSuccess = createAction('getDriverPayrollNumberListByGuidsSuccess');
export const getDriverPayrollNumberListByGuidsRequest = createAction('getDriverPayrollNumberListByGuidsRequest');
// report actions
export const setInitialState = createAction('setInitialState');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setQuickFilterParams = createAction('setQuickFilterParams');
// deduction settings
export const getDeductionSettingsSuccess = createAction('getDeductionSettingsSuccess');
export const updateDeductionSettingsRequest = createAction('updateDeductionSettingsRequest');
export const getDeductionSettingsListRequest = createAction('getDeductionSettingsListRequest');
// table title filtering
export const setTableTitleSort = createAction('setTableTitleSort');
export const setTableTitleFilter = createAction('setTableTitleFilter');
// branch configs
export const getBranchConfigsByNamesSuccess = createAction('getBranchConfigsByNamesSuccess');
export const getBranchConfigsByNamesRequest = createAction('getBranchConfigsByNamesRequest');
// working division
export const changeAssignedToDivisionRequest = createAction('changeAssignedToDivisionRequest');
// monthly payroll deduction
export const getMonthlyPayrollDeductionRequest = createAction('getMonthlyPayrollDeductionRequest');
export const getMonthlyPayrollDeductionSuccess = createAction('getMonthlyPayrollDeductionSuccess');
export const deleteMonthlyPayrollDeductionRequest = createAction('deleteMonthlyPayrollDeductionRequest');
export const toggleMonthlyPayrollDeductionDetails = createAction('toggleMonthlyPayrollDeductionDetails');
export const createOrUpdateMonthlyPayrollDeductionRequest =
  createAction('createOrUpdateMonthlyPayrollDeductionRequest');
export const getDriverPayrollChargesIsPaidOnPayrollRequest =
  createAction('getDriverPayrollChargesIsPaidOnPayrollRequest');
export const getDriverPayrollChargesIsPaidOnPayrollSuccess =
  createAction('getDriverPayrollChargesIsPaidOnPayrollSuccess');
export const getDriverPayrollChargesByMonthlyPaymentRequest =
  createAction('getDriverPayrollChargesByMonthlyPaymentRequest');
export const getDriverPayrollChargesByMonthlyPaymentSuccess =
  createAction('getDriverPayrollChargesByMonthlyPaymentSuccess');
// payroll accessorial
export const removePayrollAssessorialRequest = createAction('removePayrollAssessorialRequest');
export const togglePayrollAssessorialDetails = createAction('togglePayrollAssessorialDetails');
export const getPayrollAssessorialListRequest = createAction('getPayrollAssessorialListRequest');
export const getPayrollAssessorialListSuccess = createAction('getPayrollAssessorialListSuccess');
export const createOrUpdatePayrollAssessorialRequest = createAction('createOrUpdatePayrollAssessorialRequest');
export const getPayrollAccessorialIsPaidOnPayrollRequest =
  createAction('getPayrollAccessorialIsPaidOnPayrollRequest');
export const getPayrollAccessorialIsPaidOnPayrollSuccess =
  createAction('getPayrollAccessorialIsPaidOnPayrollSuccess');
export const getPayrollChargesByPayrollAccessorialRequest =
  createAction('getPayrollChargesByPayrollAccessorialRequest');
export const getPayrollChargesByPayrollAccessorialSuccess =
  createAction('getPayrollChargesByPayrollAccessorialSuccess');
// safety violation
export const cleanSafetyViolation = createAction('cleanSafetyViolation');
export const toggleSafetyViolationItem = createAction('toggleSafetyViolationItem');
export const getSafetyViolationListByEntityTypeSuccess = createAction('getSafetyViolationListByEntityTypeSuccess');
export const removeSafetyViolationEntityByEntityTypeRequest =
  createAction('removeSafetyViolationEntityByEntityTypeRequest');
export const removeSafetyViolationEntityByEntityTypeSuccess =
  createAction('removeSafetyViolationEntityByEntityTypeSuccess');
export const createOrUpdateSafetyViolationEntityByEntityTypeSuccess =
  createAction('createOrUpdateSafetyViolationEntityByEntityTypeSuccess');
export const createOrUpdateSafetyViolationEntityByEntityTypeRequest =
  createAction('createOrUpdateSafetyViolationEntityByEntityTypeRequest');
// fleet vendor
export const getFleetVendorListSuccess = createAction('getFleetVendorListSuccess');
