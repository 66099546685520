import React from 'react';
import * as R from 'ramda';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, BoxHovered } from '../../../ui';
//////////////////////////////////////////////////

const HeaderActions = (props: Object) => {
  const {
    tel,
    addDrop,
    deleteTel,
    addPickup,
    showOnMap,
    sortByDate,
    addCloStop,
    unassignTel,
    assignDriver,
    assignCarrier,
    applyRouteTemplate,
    addExchangeTerminal,
    recalculateDistances,
  } = props;

  const iconColor = G.getTheme('colors.dark.blue');

  return (
    <Flex
      p='5px 0'
      fontSize={14}
      alignItems='normal'
      flexDirection='column'
      color={G.getTheme('colors.light.black')}
    >
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={recalculateDistances}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.reloadCircle(iconColor, 16, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:recalculate-distances', 'Recalculate Distances')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        onClick={sortByDate}
        hoverColor={iconColor}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.sortByDate(iconColor, 20, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:sort-by-date', 'Sort by Date')}</Box>
        </Flex>
      </BoxHovered>
      <AuthWrapper has={[PC.FLEET_RATE_WRITE]}>
        <BoxHovered
          p='4px 8px'
          width='100%'
          hoverColor={iconColor}
          onClick={assignDriver}
        >
          <Flex cursor='pointer'>
            <Flex width={20}>{I.plusRound(iconColor, 16, 16)}</Flex>
            <Box ml='6px'>{G.getWindowLocale('actions:assign-driver-rate', 'Assign Driver Rate')}</Box>
          </Flex>
        </BoxHovered>
      </AuthWrapper>
      <AuthWrapper has={[PC.CARRIER_RATE_WRITE]}>
        <BoxHovered
          p='4px 8px'
          width='100%'
          hoverColor={iconColor}
          onClick={assignCarrier}
        >
          <Flex cursor='pointer'>
            <Flex width={20}>{I.plusRound(iconColor, 16, 16)}</Flex>
            <Box ml='6px'>{G.getWindowLocale('actions:assign-carrier-rate', 'Assign Carrier Rate')}</Box>
          </Flex>
        </BoxHovered>
      </AuthWrapper>
      <BoxHovered
        p='4px 8px'
        width='100%'
        onClick={addCloStop}
        hoverColor={iconColor}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.pencil(iconColor, 16, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('titles:edit-clo', 'Edit CLO')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        onClick={addPickup}
        hoverColor={iconColor}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.plusArrowUp(iconColor, 16, 16, null)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-a-pickup', 'Add a Pickup')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        onClick={addDrop}
        hoverColor={iconColor}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.plusArrowDown(iconColor, 16, 16, null)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-a-drop', 'Add a Drop')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={() => addExchangeTerminal(true)}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.terminal(null, 16, 16, iconColor)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-exchange-point', 'Add Exchange Point')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={applyRouteTemplate}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.terminal(null, 16, 16, iconColor)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:apply-route-template', 'Apply Route Template')}</Box>
        </Flex>
      </BoxHovered>
      {/* NOTE: temporary commented: wait for customers feedback on exchange point. Remove or check after feedback */}
      {/* <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={() => addExchangeTerminal(true)}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.terminal(null, 16, 16, iconColor)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:split-tel', 'Split the TEL')}</Box>
        </Flex>
      </BoxHovered> */}
      <BoxHovered
        p='4px 8px'
        width='100%'
        onClick={showOnMap}
        hoverColor={iconColor}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.onMap(iconColor, 16, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:show-on-a-map', 'Show on a Map')}</Box>
        </Flex>
      </BoxHovered>
      {
        R.not(R.prop('isNew', tel)) &&
        <BoxHovered
          p='4px 8px'
          width='100%'
          onClick={unassignTel}
          hoverColor={iconColor}
        >
          <Flex cursor='pointer'>
            <Flex width={20}>{I.crossInRound(iconColor, 16, 16)}</Flex>
            <Box ml='6px'>{G.getWindowLocale('actions:unassign-tel', 'Unassign the TEL')}</Box>
          </Flex>
        </BoxHovered>
      }
      <BoxHovered
        p='4px 8px'
        width='100%'
        onClick={deleteTel}
        hoverColor={iconColor}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.trash(iconColor, 16, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:delete-tel', 'Delete the TEL')}</Box>
        </Flex>
      </BoxHovered>
    </Flex>
  );
};

export default HeaderActions;
