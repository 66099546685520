import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const printTruck = createAction('printTruck');
export const setActiveTab = createAction('setActiveTab');
export const cleanFormStore = createAction('cleanFormStore');
export const setInitialState = createAction('setInitialState');
export const getTruckRefRequest = createAction('getTruckRefRequest');
export const updateTruckRequest = createAction('updateTruckRequest');
export const createTruckRequest = createAction('createTruckRequest');
export const setDocumentsFilter = createAction('setDocumentsFilter');
export const getTruckDocsRequest = createAction('getTruckDocsRequest');
export const toggleTruckFormGroup = createAction('toggleTruckFormGroup');
export const deleteTruckDocRequest = createAction('deleteTruckDocRequest');
export const updateTruckDocRequest = createAction('updateTruckDocRequest');
export const createTruckDocRequest = createAction('createTruckDocRequest');
export const getTruckConfigsRequest = createAction('getTruckConfigsRequest');
export const getTruckLocationRequest = createAction('getTruckLocationRequest');
export const receivedGetTruckSuccess = createAction('receivedGetTruckSuccess');
export const getTruckInsuranceRequest = createAction('getTruckInsuranceRequest');
export const getTruckOwnershipRequest = createAction('getTruckOwnershipRequest');
export const getTruckComponentRequest = createAction('getTruckComponentRequest');
export const updateTruckDetailsRequest = createAction('updateTruckDetailsRequest');
export const getExternalTruckIdRequest = createAction('getExternalTruckIdRequest');
export const getExternalTruckIdSuccess = createAction('getExternalTruckIdSuccess');
export const updateTruckLocationRequest = createAction('updateTruckLocationRequest');
export const receivedDeleteTruckSuccess = createAction('receivedDeleteTruckSuccess');
export const deleteTruckLocationRequest = createAction('deleteTruckLocationRequest');
export const createTruckLocationRequest = createAction('createTruckLocationRequest');
export const receivedGetTruckRefSuccess = createAction('receivedGetTruckRefSuccess');
export const updateTruckComponentRequest = createAction('updateTruckComponentRequest');
export const receivedGetTruckDocsSuccess = createAction('receivedGetTruckDocsSuccess');
export const createTruckInsuranceRequest = createAction('createTruckInsuranceRequest');
export const updateTruckReferenceRequest = createAction('updateTruckReferenceRequest');
export const deleteTruckReferenceRequest = createAction('deleteTruckReferenceRequest');
export const deleteTruckComponentRequest = createAction('deleteTruckComponentRequest');
export const deleteTruckInsuranceRequest = createAction('deleteTruckInsuranceRequest');
export const createTruckComponentRequest = createAction('createTruckComponentRequest');
export const updateTruckInsuranceRequest = createAction('updateTruckInsuranceRequest');
export const createTrucksReferenceRequest = createAction('createTrucksReferenceRequest');
export const validateBeforeChangeTruckTab = createAction('validateBeforeChangeTruckTab');
export const getTruckSpecificationRequest = createAction('getTruckSpecificationRequest');
export const updateExternalTruckIdRequest = createAction('updateExternalTruckIdRequest');
export const updateExternalTruckIdSuccess = createAction('updateExternalTruckIdSuccess');
export const deleteExternalTruckIdRequest = createAction('deleteExternalTruckIdRequest');
export const deleteExternalTruckIdSuccess = createAction('deleteExternalTruckIdSuccess');
export const createExternalTruckIdRequest = createAction('createExternalTruckIdRequest');
export const createExternalTruckIdSuccess = createAction('createExternalTruckIdSuccess');
export const getFleetGeneralConfigsRequest = createAction('getFleetGeneralConfigsRequest');
export const receivedDeleteTruckDocSuccess = createAction('receivedDeleteTruckDocSuccess');
export const receivedCreateTruckDocSuccess = createAction('receivedCreateTruckDocSuccess');
export const receivedUpdateTruckDocSuccess = createAction('receivedUpdateTruckDocSuccess');
export const getBranchConfigsByNamesRequest = createAction('getBranchConfigsByNamesRequest');
export const getBranchConfigsByNamesSuccess = createAction('getBranchConfigsByNamesSuccess');
export const receivedGetTruckConfigsSuccess = createAction('receivedGetTruckConfigsSuccess');
export const receivedGetTruckLocationSuccess = createAction('receivedGetTruckLocationSuccess');
export const receivedGetLocationTypesSuccess = createAction('receivedGetLocationTypesSuccess');
export const receivedGetTruckInsuranceSuccess = createAction('receivedGetTruckInsuranceSuccess');
export const receivedGetTruckOwnershipSuccess = createAction('receivedGetTruckOwnershipSuccess');
export const receivedGetTruckComponentSuccess = createAction('receivedGetTruckComponentSuccess');
export const receivedUpdateTruckDetailsSuccess = createAction('receivedUpdateTruckDetailsSuccess');
export const getTruckLatestKnownLocationRequest = createAction('getTruckLatestKnownLocationRequest');
export const receivedCreateTruckLocationSuccess = createAction('receivedCreateTruckLocationSuccess');
export const receivedUpdateTruckLocationSuccess = createAction('receivedUpdateTruckLocationSuccess');
export const receivedDeleteTruckLocationSuccess = createAction('receivedDeleteTruckLocationSuccess');
export const receivedDeleteTruckReferenceSuccess = createAction('receivedDeleteTruckReferenceSuccess');
export const receivedDeleteTruckInsuranceSuccess = createAction('receivedDeleteTruckInsuranceSuccess');
export const receivedDeleteTruckComponentSuccess = createAction('receivedDeleteTruckComponentSuccess');
export const receivedCreateTruckInsuranceSuccess = createAction('receivedCreateTruckInsuranceSuccess');
export const receivedUpdateTruckInsuranceSuccess = createAction('receivedUpdateTruckInsuranceSuccess');
export const receivedUpdateTruckOwnershipSuccess = createAction('receivedUpdateTruckOwnershipSuccess');
export const receivedUpdateTruckReferenceSuccess = createAction('receivedUpdateTruckReferenceSuccess');
export const receivedUpdateTruckComponentSuccess = createAction('receivedUpdateTruckComponentSuccess');
export const receivedGetTruckSpecificationSuccess = createAction('receivedGetTruckSpecificationSuccess');
export const receivedCreateTrucksReferenceSuccess = createAction('receivedCreateTrucksReferenceSuccess');
export const createTruckLatestKnownLocationRequest = createAction('createTruckLatestKnownLocationRequest');
export const receivedUpdateTruckSpecificationSuccess = createAction('receivedUpdateTruckSpecificationSuccess');
export const receivedGetTruckLatestKnownLocationSuccess = createAction('receivedGetTruckLatestKnownLocationSuccess');
export const receivedCreateTruckLatestKnownLocationSuccess = createAction(
  'receivedCreateTruckLatestKnownLocationSuccess',
);
// working division
export const changeAssignedToDivisionRequest = createAction('changeAssignedToDivisionRequest');
// sync truck
export const syncTruckByVINRequest = createAction('syncTruckByVINRequest');
export const syncTrucksByVINRequest = createAction('syncTrucksByVINRequest');
// daily log
export const getDailyLogListRequest = createAction('getDailyLogListRequest');
export const getDailyLogListSuccess = createAction('getDailyLogListSuccess');
