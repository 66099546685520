import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withBooleanStatus } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner-events
import * as H from '../helpers';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const ContainerInfo = (props: Object) => {
  const {
    event,
    CLO_NAME,
    useContainers,
    isPickupContainer,
    handleEditContainer,
    containerInternalId,
    removeContainerFromStop,
  } = props;

  const loadName = CLO_NAME || G.getWindowLocale('titles:unknown', 'Unknown');
  const info = H.getContainerInfo(props);

  return (
    <Flex py='4px' wordBreak='break-all'>
      <Box mx='4px' maxWidth='calc(50% - 20px)'>{info}</Box>
      <TextComponent
        fontSize={12}
        display='block'
        title={loadName}
        overflow='hidden'
        color={blueColor}
        withEllipsis={true}
        width='calc(50% - 30px)'
      >
        ({loadName})
      </TextComponent>
      {
        useContainers &&
        <Flex ml='auto'>
          <Box
            ml='6px'
            mr='8px'
            cursor='pointer'
            onClick={() => handleEditContainer(event, containerInternalId)}
          >
            {I.pencil(blueColor, 13, 13)}
          </Box>
          <Box
            cursor='pointer'
            onClick={() => removeContainerFromStop({
              stop: event,
              isPickupContainer,
              id: containerInternalId,
            })}
          >
            {I.minusInRound(blueColor)}
          </Box>
        </Flex>
      }
    </Flex>
  );
};

const Content = (props: Object) => {
  const {
    event,
    containers,
    useContainers,
    handleEditContainer,
    removeContainerFromStop,
    handleClickAddDropContainer,
    handleClickAddPickupContainer,
  } = props;

  const { stopType, eventType, droppedContainers, pickedUpContainers } = event;

  const isPickup = G.isEventTypePickup(eventType);
  const isTerminal = G.isStopTypeTerminal(stopType);

  return (
    <Box
      px='5px'
      fontSize={12}
      overflow='auto'
      maxHeight='200px'
      flexDirection='column'
    >
      {
        R.not(R.and(R.not(isPickup), isTerminal)) &&
        <Flex py='3px'>
          {
            useContainers &&
            <Box cursor='pointer' onClick={() => handleClickAddPickupContainer(event)}>
              {I.plusRound(blueColor, 13, 13)}
            </Box>
          }
          <Box mx='4px'>{G.getWindowLocale('titles:picked-up-containers', 'Picked Up Containers')}:</Box>
        </Flex>
      }
      {
        G.isNotNilAndNotEmpty(pickedUpContainers) &&
        <Box py='2px'>
          {
            pickedUpContainers.map(({ containerInternalId }: Object, i: number) => (
              <ContainerInfo
                {...R.prop(containerInternalId, containers)}
                key={i}
                event={event}
                isPickupContainer={true}
                useContainers={useContainers}
                handleEditContainer={handleEditContainer}
                removeContainerFromStop={removeContainerFromStop}
              />
            ))
          }
        </Box>
      }
      {
        R.not(R.and(isPickup, isTerminal)) &&
        <Flex py='3px'>
          {
            useContainers &&
            <Box cursor='pointer' onClick={() => handleClickAddDropContainer(event)}>
              {I.plusRound(blueColor, 13, 13)}
            </Box>
          }
          <Box mx='4px'>{G.getWindowLocale('titles:dropped-containers', 'Dropped Containers')}:</Box>
        </Flex>
      }
      {
        G.isNotNilAndNotEmpty(droppedContainers) &&
        <Box py='2px'>
          {
            droppedContainers.map(({ containerInternalId }: Object, i: number) => (
              <ContainerInfo
                {...R.prop(containerInternalId, containers)}
                key={i}
                event={event}
                isPickupContainer={false}
                useContainers={useContainers}
                handleEditContainer={handleEditContainer}
                removeContainerFromStop={removeContainerFromStop}
              />
            ))
          }
        </Box>
      }
    </Box>
  );
};

const ContainersHeader = (props: Object) => {
  const { stop } = props;

  const count = R.compose(
    R.length,
    R.concat(R.pathOr([], [GC.FIELD_STOP_DROPPED_CONTAINERS], stop)),
    R.pathOr([], [GC.FIELD_STOP_PICKED_UP_CONTAINERS]),
  )(stop);

  return (
    <Box mx='4px'>{count} {G.getWindowLocale('titles:containers', 'Containers')}:</Box>
  );
};

const StopContainers = withBooleanStatus(false)((props: StopInfoPropsType) => {
  const {
    tel,
    clos,
    stop,
    containers,
    booleanStatus,
    useContainers,
    handleEditContainer,
    removeContainerFromStop,
    handleToggleBooleanStatus,
    handleClickAddDropContainer,
    handleClickAddPickupContainer,
  } = props;

  return (
    <Box
      width='100%'
      borderRadius={5}
      overflow='hidden'
      borderBottom='1px solid'
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Flex
        px='8px'
        height={24}
        fontSize={12}
        justifyContent='space-between'
        bg={G.getTheme('colors.light.lightGrey')}
      >
        <ContainersHeader {...props} color={blueColor} />
        <Flex mr='6px' cursor='pointer' onClick={handleToggleBooleanStatus}>
          <Box color={blueColor}>
            {
              G.ifElse(
                booleanStatus,
                G.getWindowLocale('actions:hide', 'Hide'),
                G.getWindowLocale('actions:show', 'Show'),
              )
            }
          </Box>
          <Box ml='3px'>
            {G.ifElse(booleanStatus, I.arrowUpSimple, I.arrowDownSimple)(blueColor, 9, 6)}
          </Box>
        </Flex>
      </Flex>
      {
        booleanStatus &&
        <Content
          tel={tel}
          clos={clos}
          event={stop}
          containers={containers}
          useContainers={useContainers}
          handleEditContainer={handleEditContainer}
          removeContainerFromStop={removeContainerFromStop}
          handleClickAddDropContainer={handleClickAddDropContainer}
          handleClickAddPickupContainer={handleClickAddPickupContainer}
        />
      }
    </Box>
  );
});

export default StopContainers;
