import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
// ui
import { Box } from '../../../ui';
// feature fuel cards
import * as C from '../constants';
//////////////////////////////////////////////////

const fuelDetailsTableSettings = {
  useMainColors: true,
  checkBoxCellWidth: 0,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'fit-content',
};

const report = {
  fields: [
    { sequence: 1, name: GC.GRC.DRIVER_FIRST_NAME },
    { sequence: 2, name: GC.GRC.DRIVER_LAST_NAME },
    { sequence: 3, name: C.RC.TRUCK_UNIT_ID },
    { sequence: 4, name: GC.FIELD_FUEL_CARDS_CARD_ID },
    { sequence: 5, name: GC.FIELD_FUEL_CARDS_PAYROLL_GUID },
    { sequence: 6, name: GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID },
    { sequence: 7, name: GC.FIELD_FUEL_CARDS_SOURCE },
    { sequence: 8, name: GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER },
    { sequence: 9, name: GC.FIELD_FUEL_CARDS_TRANSACTION_DATE },
    { sequence: 10, name: GC.FIELD_TRIP_ID },
    { sequence: 11, name: GC.FIELD_FUEL_CARDS_EXCLUDED_TOTAL },
    { sequence: 12, name: GC.FIELD_FUEL_CARDS_PAYABLE_TOTAL },
    { sequence: 13, name: GC.FIELD_TOTAL },
    { sequence: 14, name: GC.FIELD_CURRENCY },
    { sequence: 15, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_LOCATION_NAME },
    { sequence: 16, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_ADDRESS },
    { sequence: 17, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_CITY },
    { sequence: 18, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_STATE },
    { sequence: 19, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_COUNTRY },
    { sequence: 20, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_LONGITUDE },
    { sequence: 21, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_LATITUDE },
    { sequence: 22, name: GC.FIELD_CREATED_BY },
    { sequence: 23, name: GC.FIELD_CREATED_DATE },
    { sequence: 24, name: GC.FIELD_LAST_MODIFIED_BY },
    { sequence: 25, name: GC.FIELD_LAST_MODIFIED_DATE },
  ],
};

const fuelDetailsReport = {
  fields: [
    { sequence: 1, name: GC.FIELD_FUEL_CARDS_PRODUCT_TYPE },
    { sequence: 2, name: GC.FIELD_FUEL_CARDS_PRODUCT_NAME },
    { sequence: 3, name: GC.FIELD_FUEL_CARDS_GROUP_NAME },
    { sequence: 4, name: GC.FIELD_FUEL_CARDS_PRODUCT_CODE },
    { sequence: 5, name: GC.FIELD_FUEL_CARDS_QUANTITY },
    { sequence: 6, name: GC.FIELD_UOM },
    { sequence: 7, name: GC.FIELD_PRICE },
    { sequence: 8, name: GC.FIELD_FUEL_CARDS_DISCOUNT },
    { sequence: 9, name: GC.FIELD_TOTAL },
  ],
};

const fuelDetailsColumnSettings = {
  [GC.FIELD_FUEL_CARDS_PRODUCT_TYPE]: {
    width: 200,
    name: 'titles:product-type',
  },
  [GC.FIELD_FUEL_CARDS_PRODUCT_NAME]: {
    width: 200,
    name: 'titles:product-name',
  },
  [GC.FIELD_FUEL_CARDS_GROUP_NAME]: {
    width: 200,
    name: 'titles:group-name',
  },
  [GC.FIELD_FUEL_CARDS_PRODUCT_CODE]: {
    width: 200,
    name: 'titles:product-code',
  },
  [GC.FIELD_FUEL_CARDS_QUANTITY]: {
    width: 200,
    name: 'titles:quantity',
  },
  [GC.FIELD_UOM]: {
    width: 200,
    name: 'titles:uom',
  },
  [GC.FIELD_PRICE]: {
    width: 200,
    name: 'titles:price',
  },
  [GC.FIELD_FUEL_CARDS_DISCOUNT]: {
    width: 200,
    name: 'titles:discount',
  },
  [GC.FIELD_TOTAL]: {
    width: 200,
    name: 'titles:total',
  },
};

const getPayrollNumberCustomComponent = (payrollNumberProp: string) => (props: Object) => {
  const { data, closeModal, setInitialExpandedContainer } = props;

  const payrollNumber = G.getPropFromObject(payrollNumberProp, data);

  if (G.isNilOrEmpty(payrollNumber)) return null;

  return (
    <Box
      cursor='pointer'
      textDecoration='underline'
      color={G.getTheme('colors.dark.blue')}
      onClick={() => {
        if (G.notEquals(G.getCurrentPathname(), GC.ROUTE_PATH_PAYROLLS)) {
          return G.goToRoute(routesMap.driverPayrollList);
        }

        G.callFunction(setInitialExpandedContainer);
        G.callFunction(closeModal);
      }}
    >
      {payrollNumber}
    </Box>
  );
};

const columnSettings = {
  [GC.GRC.DRIVER_FIRST_NAME]: {
    width: 150,
    name: ['titles:driver', 'titles:first-name'],
  },
  [GC.GRC.DRIVER_LAST_NAME]: {
    width: 150,
    name: ['titles:driver', 'titles:last-name'],
  },
  [GC.GRC.DRIVER_LOGIN_ID]: {
    width: 150,
    name: ['titles:driver', 'titles:login-id'],
  },
  [C.RC.TRUCK_UNIT_ID]: {
    width: 150,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [GC.FIELD_FUEL_CARDS_CARD_ID]: {
    width: 150,
    name: 'titles:card-id',
  },
  [GC.FIELD_FUEL_CARDS_SOURCE]: {
    width: 150,
    name: 'titles:source',
  },
  [GC.FIELD_FUEL_CARDS_PAYROLL_GUID]: {
    width: 200,
    name: 'titles:payroll-number',
    customComponent: getPayrollNumberCustomComponent(GC.FIELD_FUEL_CARDS_PAYROLL_GUID),
  },
  [GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID]: {
    width: 200,
    name: 'titles:vendor-payroll-number',
    customComponent: getPayrollNumberCustomComponent(GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID),
  },
  [GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER]: {
    width: 150,
    name: 'titles:transaction-number',
  },
  [GC.FIELD_FUEL_CARDS_TRANSACTION_DATE]: {
    type: 'date',
    name: 'titles:transaction-date',
  },
  [GC.FIELD_TRIP_ID]: {
    width: 200,
    name: 'titles:trip-id',
  },
  [GC.FIELD_FUEL_CARDS_EXCLUDED_TOTAL]: {
    width: 150,
    name: 'titles:excluded-total',
  },
  [GC.FIELD_FUEL_CARDS_PAYABLE_TOTAL]: {
    width: 150,
    name: 'titles:payable-total',
  },
  [GC.FIELD_TOTAL]: {
    width: 150,
    name: 'titles:total',
  },
  [GC.FIELD_CURRENCY]: {
    width: 150,
    name: 'titles:currency',
  },
  [C.RC.FUEL_CARDS_TRANSACTION_STOP_LOCATION_NAME]: {
    width: 200,
    name: 'titles:location-name',
  },
  [C.RC.FUEL_CARDS_TRANSACTION_STOP_ADDRESS]: {
    width: 200,
    name: 'titles:address',
  },
  [C.RC.FUEL_CARDS_TRANSACTION_STOP_CITY]: {
    width: 200,
    name: 'titles:city',
  },
  [C.RC.FUEL_CARDS_TRANSACTION_STOP_STATE]: {
    width: 150,
    name: 'titles:state',
  },
  [C.RC.FUEL_CARDS_TRANSACTION_STOP_COUNTRY]: {
    width: 150,
    name: 'titles:country',
  },
  [C.RC.FUEL_CARDS_TRANSACTION_STOP_LONGITUDE]: {
    width: 150,
    name: 'titles:longitude',
  },
  [C.RC.FUEL_CARDS_TRANSACTION_STOP_LATITUDE]: {
    width: 150,
    name: 'titles:latitude',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:last-modified-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:last-modified-date',
  },
  [GC.FIELD_TIME_ZONE]: {
    width: 150,
    name: 'titles:time-zone',
  },
  [C.RC.FUEL_CARDS_LINES_PRODUCT_NAME]: {
    width: 200,
    name: 'titles:product-name',
  },
  [C.RC.FUEL_CARDS_LINES_QUANTITY]: {
    width: 200,
    name: 'titles:quantity',
  },
  [C.RC.FUEL_CARDS_LINES_UOM]: {
    width: 200,
    name: 'titles:uom',
  },
};

export {
  report,
  columnSettings,
  fuelDetailsReport,
  fuelDetailsTableSettings,
  fuelDetailsColumnSettings,
};
