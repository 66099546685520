import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
///////////////////////////////////////////////////////////////

const groupByItemId = (prop: string, value: string, allIntObj: Array) => {
  const isSelected = (item: Object) => R.equals(R.path([prop], item), value);
  const selectedList = R.map(
    (item: Object) => item,
    R.filter(isSelected, allIntObj),
  );
  return R.assoc(value, selectedList, {});
};

export const makeSettingInfo = (intObj: Array) => R.reduce((prev: Array, locale: Array) => (
    R.concat(prev, R.values(locale).map(({ itemId }: Object) => itemId))
  ), [], intObj);

export const makeCurrentIntObj = (data: object) => {
  const itemIdArray = R.uniq(makeSettingInfo(data));
  const allIntObj = R.flatten(data);
  const newObj = R.mergeAll(itemIdArray.map((itemId: object) => groupByItemId('itemId', itemId, allIntObj)));
  const indexedObj = R.mapObjIndexed((item: Object) => R.indexBy(R.prop('language'), item), newObj);
  return indexedObj;
};

export const convertToOptions = (list: Array) => {
  if (G.isNilOrEmpty(list)) return [];
  return R.map((item: object) => ({
    label: R.path(['name'], item),
    value: R.path(['guid'], item),
  }), list);
};

export const changeStyleOnHover = (
  pattern: any,
  activeTab: any,
  hoverStyle: any,
  currentStyle: any,
) => (
  G.ifElse(
    R.equals(pattern, activeTab),
    hoverStyle,
    currentStyle,
  )
);
