import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex, scrollableContainerCss3px } from '../../ui';
// feature audit2
import * as H from './helpers';
import AuditGroup from './components/audit-group';
import { makeSelectAuditList } from './selectors';
import { setInitialState, getAuditListRequest } from './actions';
import { fieldSetting, renderHeader } from './settings/field-settings';
//////////////////////////////////////////////////

const Audit2 = (props: Object) => {
  const {
    requestOptions = {},
    useFullScreen = false,
    maxHeight = 'calc(100vh - 160px)',
  } = props;

  const dispatch = useDispatch();

  const auditList = useSelector(makeSelectAuditList());

  const width = G.ifElse(
    useFullScreen,
    '100%',
    1100,
  );

  const handleGetAuditList = (requestOptions: Object) =>
    dispatch(getAuditListRequest(requestOptions));

  useEffect(() => {
    handleGetAuditList(requestOptions);

    return () => dispatch(setInitialState());
  }, [dispatch, requestOptions]);

  return (
    <Box
      bg='white'
      maxWidth='95vw'
      overflowX='auto'
      data-testid='testid-Audit2'
      css={scrollableContainerCss3px}
    >
      <Box
        data-testid='testid-Audit2-innerWrapper'
        minWidth={useFullScreen ? 700 : 'unset'}
      >
        <Box
          px={15}
          width={width}
          background='#E0E0E0'
          borderBottom={`1px solid ${G.getTheme('colors.white')}`}
        >
          <Flex
            height={32}
            width='calc(100% - 30px)'
          >
            {renderHeader(fieldSetting)}
          </Flex>
        </Box>
        <Box
          width={width}
          overflowY='auto'
          maxHeight={maxHeight}
          css={scrollableContainerCss3px}
        >
          {
            G.isNotNil(auditList) &&
            <AuditGroup
              handleGetAuditList={handleGetAuditList}
              groupedList={H.getGroupedByDate(auditList)}
            />
          }
        </Box>
      </Box>
    </Box >
  );
};

export default Audit2;
