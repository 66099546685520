import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
// feature clo
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  totalCount: 0,
  loading: false,
  itemList: null,
  previewData: [],
  filterParams: {},
  currentChart: {},
  usedReport: null,
  reportPending: false,
  previewPending: false,
  availableReports: null,
  pagination: {
    limit: 10,
    offset: 0,
  },
};

const setInitialState = () => initialState;

const setReportPending = (state: Object) => P.$set('reportPending', true, state);

const getChartPreviewRequest = (state: Object) => P.$set('previewPending', true, state);

const setListLoading = (state: Object, loading: boolean) => P.$set('loading', loading, state);

const getChartSuccess = (state: Object, chart: Object) => P.$set('currentChart', chart, state);

const setReports = (state: Object, reports: Array) => P.$set('availableReports', reports, state);

const setRequestPending = (state: Object, status: boolean) => P.$set('requestPending', status, state);

const getChartConfigsSuccess = (state: Object, configs: Object) => P.$set('chartConfigs', configs, state);

const setQuickFilterParams = (state: Object, filterParams: Object) => P.$set('filterParams', filterParams, state);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const getListOfChartsSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;
  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );
  const newItems = data.results.map((item: Object, index: number) => R.mergeRight(
    item,
    {
      selected: false,
      index: R.add(index, indexAdditional),
    },
  ));
  const list = R.mergeRight(itemList, R.indexBy(R.prop('guid'), newItems));
  const newOffset = R.add(pagination.offset, pagination.limit);
  return P.$all(
    P.$set('itemList', list),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const selectItem = (state: Object, id: Object) => {
  const { itemList } = state;
  if (R.equals(id, 'all')) {
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));
    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc(
          'selected',
          value,
          item,
        ),
        itemList,
      ),
      state,
    );
  }
  return P.$toggle(`itemList.${id}.selected`, state);
};

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('filterParams', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const getChartPreviewSuccess = (state: Object, previewData: Object) => (
  P.$all(
    P.$set('previewPending', false),
    P.$set('previewData', previewData),
    state,
  )
);

const deleteChartSuccess = (state: Object, data: Object) => {
  const listAfterDelete = R.omit([...data], R.path(['itemList'], state));
  return (
    P.$all(
      P.$set('itemList', listAfterDelete),
      P.$set('totalCount', G.ifElse(R.equals(state.totalCount, 0), 0, R.subtract(state.totalCount, data.length))),
      state,
    )
  );
};

export default createReducer({
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.getChartSuccess]: getChartSuccess,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setRequestPending]: setRequestPending,
  [A.deleteChartSuccess]: deleteChartSuccess,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.getListOfChartsSuccess]: getListOfChartsSuccess,
  [A.getChartPreviewRequest]: getChartPreviewRequest,
  [A.getChartConfigsSuccess]: getChartConfigsSuccess,
  [A.getChartPreviewSuccess]: getChartPreviewSuccess,
  [A.resetListAndPagination]: resetListAndPagination,
}, initialState);
