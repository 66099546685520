import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-group
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  itemList: {},
};

const setInitialState = () => initialState;

const deleteDispatchGroupSuccess = (state: Object, guid: Object) => P.$drop(`itemList.${guid}`, state);

const toggleDispatchGroupDetails = (state: Object, { guid }: Object) => (
  P.$toggle(`itemList.${guid}.expanded`, state)
);

const receivedDispatchGroupListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set('itemList', {}, state);
  const indexedList = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, expanded: false },
    )),
  )(data);

  return P.$set('itemList', indexedList, state);
};

export default createReducer({
  [A.setInitialState]: setInitialState,
  [A.deleteDispatchGroupSuccess]: deleteDispatchGroupSuccess,
  [A.toggleDispatchGroupDetails]: toggleDispatchGroupDetails,
  [A.receivedDispatchGroupListSuccess]: receivedDispatchGroupListSuccess,
}, initialState);
