import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner-events
import { DELETED_TEL_GUIDS } from '../constants';
import { removeTelFromPlanner } from '../actions';
import { makeSelectTelList, makeSelectCurrentRoute } from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleRemoveFromList: (props: Object) => (guid: string, name: string) => {
      const { openModal, closeModal, removeTelFromPlanner } = props;

      const modalContent = getConfirmModal({
        name,
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale(
          'messages:unassign-deleted-tel-from-planner',
          'Are you sure you want to unassign trip from planner? All trip events will be removed from planner. Trip',
        ),
        submitAction: () => {
          removeTelFromPlanner(guid);
          closeModal();
        },
      });
      openModal(modalContent);
    },
  }),
  pure,
);

const TelsForDelete = enhance((props: Object) => {
  const { telList, currentRoute, handleRemoveFromList } = props;
  if (G.isNilOrEmpty(R.prop(DELETED_TEL_GUIDS, currentRoute))) return null;
  const iconColor = G.getTheme('colors.dark.blue');
  return (
    <Flex mt={20} width='100%' flexDirection='column'>
      <Box fontWeight='bold'>
        {G.getWindowLocale('titles:tels-for-delete', 'TELs selected for delete')}:
      </Box>
      {
        R.prop(DELETED_TEL_GUIDS, currentRoute).map((guid: string, i: number) => {
          const tel = R.compose(
            R.prop('details'),
            R.find(R.propEq(guid, GC.FIELD_GUID)),
          )(telList);
          const text = R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], tel);
          return (
            <Flex py='4px' key={i} width='100%' justifyContent='space-between'>
              <TextComponent
                title={text}
                withEllipsis={true}
                display='inline-block'
                maxWidth='calc(100% - 20px)'
              >
                {text}
              </TextComponent>
              <Box cursor='pointer' onClick={() => handleRemoveFromList(guid, text)}>{I.minusInRound(iconColor)}</Box>
            </Flex>
          );
        })
      }
    </Flex>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  telList: makeSelectTelList(state),
  currentRoute: makeSelectCurrentRoute(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  removeTelFromPlanner,
})(TelsForDelete);
