import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// features
import { makeSelectRoleList } from '../../../role/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import * as H from '../../helpers';
import { CONFIG_DROPDOWN_POPUP_FORM } from '../../constants';
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import { getDefaultDropdownConfigFormComponent } from '../../components/dropdown-config-modal';
import {
  makeSelectDocumentList,
  makeSelectDocumentTypes,
  makeSelectConfigOptions,
  makeSelectDocumentFilter,
  makeSelectConfigDropdowns,
  makeSelectMailTemplateConfig,
  makeSelectReasonCodesOptions,
  makeSelectStatusMessagesList,
  makeSelectDocumentSendingList,
  makeSelectConfigInitialValues,
  makeSelectFilteredDocumentList,
  makeSelectNotificationTriggers,
  makeSelectIntegrationMailConfig,
  makeSelectConfigInheritedValues,
  makeSelectAvailableDocumentTypes,
  makeSelectNotificationListTriggers,
  makeSelectFilteredNotificationList,
  makeSelectCurrentCommunicationMails,
  makeSelectNotificationTriggersFilter,
  makeSelectNotificationObjectTypeFilter,
} from '../../selectors';
import {
  setDocumentsFilter,
  updateConfigsRequest,
  createDocumentRequest,
  updateDocumentRequest,
  deleteNotificationRequest,
  createNotificationRequest,
  updateStatusMessageRequest,
  createStatusMessageRequest,
  deleteStatusMessageRequest,
  deleteDocumentConfigRequest,
  setNotificationTriggersFilter,
  getNotificationTriggersRequest,
  setNotificationObjectTypeFilter,
  removeMailTemplateConfigRequest,
  updateNotificationConfigsRequest,
  removeIntegrationMailConfigRequest,
  removeDocumentSendingConfigRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateMailTemplateConfigRequest,
  createOrUpdateIntegrationMailConfigRequest,
  createOrUpdateDocumentSendingConfigRequest,
} from '../../actions';
// communication
import DocumentForm from './components/document-form';
import NotificationForm from './components/notification-form';
import MailTemplateForm from './components/mail-template-form';
import StatusMessageForm from './components/statuses-message-form';
import DocumentSendingForm from './components/document-sending-form';
import IntegrationMailConfig from './components/integration-mail-config';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(GC.COMMUNICATION_CONFIG_GROUP),
  itemPageConfigEnhancer,
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const { openModal, closeModal } = props;

      const title = R.or(popupTitle, 'titles:add-item');
      let modalContent = getDefaultDropdownConfigFormComponent(configName, props);

      if (R.equals(configName, GC.CUSTOM_DOCUMENT_SENDING_CONFIG)) {
        const { createOrUpdateDocumentSendingConfigRequest } = props;

        const component = (
          <DocumentSendingForm
            submitAction={createOrUpdateDocumentSendingConfigRequest}
            documentTypeOptions={G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
              props,
              GC.COMMUNICATION_DOCUMENT_TYPE,
            )}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            title: G.getAddTitle(['titles:document-sending', 'Document Sending']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_STATUS_MESSAGES_CONFIG)) {
        const { createStatusMessageRequest } = props;

        const component = (
          <StatusMessageForm
            closeModal={closeModal}
            createStatusMessageRequest={createStatusMessageRequest}
          />
        );

        const modal = {
          p: '0px',
          component,
          options: {
            width: 330,
            height: 'auto',
            title: G.getWindowLocale(title),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_DOCUMENT_CONFIG)) {
        const { documentTypes, createDocumentRequest } = props;

        const customerBranchList = G.addEmptyOptionToDropDown(
          R.pathOr([], ['configOptions', 'customerBranchList'], props),
        );

        const component = (
          <DocumentForm
            closeModal={closeModal}
            documentTypes={documentTypes}
            form={CONFIG_DROPDOWN_POPUP_FORM}
            submitAction={createDocumentRequest}
            customerBranchList={customerBranchList}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 480,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getWindowLocale(title),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_ADD_NOTIFICATION_CONFIG)) {
        const { createNotificationRequest } = props;

        modalContent = (
          <NotificationForm
            name='add'
            closeModal={closeModal}
            submitActions={createNotificationRequest}
            cloReferenceTypes={R.pathOr([], ['configOptions', 'cloReferenceTypes'], props)}
            telReferenceTypes={R.pathOr([], ['configOptions', 'telReferenceTypes'], props)}
          />
        );

        const modal = {
          p: '0px',
          component: modalContent,
          firstChildZIndex: 'unset',
          options: {
            width: 680,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
            title: G.getWindowLocale(title),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_INTEGRATION_MAIL_CONFIG)) {
        const { createOrUpdateIntegrationMailConfigRequest } = props;

        const component = (
          <IntegrationMailConfig
            editMode={false}
            closeModal={closeModal}
            submitAction={createOrUpdateIntegrationMailConfigRequest}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            overflow: 'auto',
            maxHeight: '87vh',
            title: G.getAddTitle(['titles:integration-mail', 'Integration Mail']),
          },
        };

        return openModal(modal);
      }

      if (R.equals(configName, GC.CUSTOM_MAIL_TEMPLATE_CONFIG)) {
        const { createOrUpdateMailTemplateConfigRequest } = props;

        const component = (
          <MailTemplateForm
            editMode={false}
            openModal={openModal}
            closeModal={closeModal}
            submitAction={createOrUpdateMailTemplateConfigRequest}
            cloReferenceTypes={R.pathOr([], ['configOptions', 'cloReferenceTypes'], props)}
            telReferenceTypes={R.pathOr([], ['configOptions', 'telReferenceTypes'], props)}
          />
        );

        const modal = {
          p: '0px',
          component,
          options: {
            width: 740,
            movable: false,
            height: 'auto',
            title: G.getAddTitle(['titles:mail-template', 'Mail Template']),
          },
        };

        return openModal(modal);
      }

      const modal = H.getModalOptions(modalContent, title);

      openModal(modal);
    },
    handleEditEntity: (props: Object) => (entity: string, popupTitle: string) => {
      const { openModal, closeModal, updateStatusMessageRequest } = props;

      const component = (
        <StatusMessageForm
          shouldUpdate={true}
          initialValues={entity}
          closeModal={closeModal}
          updateStatusMessageRequest={updateStatusMessageRequest}
        />
      );

      const title = R.or(popupTitle, 'titles:edit-item');

      const modal = {
        p: '0px',
        component,
        options: {
          width: 330,
          height: 'auto',
          title: G.getWindowLocale(title),
        },
      };

      openModal(modal);
    },
    handleRemoveEntity: (props: Object) => (entityGuid: string, configName: string, entity: Object) => {
      const { openModal, closeModal, deleteStatusMessageRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_STORED_VALUE], entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const deleteStatusMessage = () => {
        closeModal();
        deleteStatusMessageRequest(entityGuid);
      };

      const modal = G.getConfirmDeleteModal(component, deleteStatusMessage);

      openModal(modal);
    },
    handleDeleteDocument: (props: Object) => (entityGuid: string, configName: string, entity: Object) => {
      const { openModal, closeModal, deleteDocumentConfigRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_NAME], entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const deleteDocumentConfig = () => {
        closeModal();
        deleteDocumentConfigRequest(entityGuid);
      };

      const modal = G.getConfirmDeleteModal(component, deleteDocumentConfig);

      openModal(modal);
    },
    handleDeleteNotification: (props: Object) => (entityGuid: string, configName: string, entity: Object) => {
      const { openModal, closeModal, deleteNotificationRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_NAME], entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const deleteNotification = () => {
        closeModal();
        deleteNotificationRequest(entityGuid);
      };

      const modal = G.getConfirmDeleteModal(component, deleteNotification);

      openModal(modal);
    },
    handleEditDocument: (props: Object) => (doc: Object, popupTitle: string) => {
      const initialValues = R.assoc(
        GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FILE,
        R.prop(GC.FIELD_FILE_NAME, doc),
        doc,
      );
      const customerBranchList = G.addEmptyOptionToDropDown(
        R.pathOr([], ['configOptions', 'customerBranchList'], props),
      );
      const component = (
        <DocumentForm
          initialValues={initialValues}
          closeModal={props.closeModal}
          form={CONFIG_DROPDOWN_POPUP_FORM}
          documentTypes={props.documentTypes}
          customerBranchList={customerBranchList}
          submitAction={props.updateDocumentRequest}
        />
      );
      const title = R.or(popupTitle, 'titles:edit-item');
      const modal = {
        p: '15px',
        component,
        options: {
          width: '480px',
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale(title),
        },
      };
      props.openModal(modal);
    },
    handleEditTrigger: (props: Object) => (doc: Object) => {
      const { openModal, closeModal, updateNotificationConfigsRequest } = props;

      const modalContent = (
        <NotificationForm
          name='edit'
          initialValues={doc}
          closeModal={closeModal}
          submitActions={updateNotificationConfigsRequest}
          cloReferenceTypes={R.pathOr([], ['configOptions', 'cloReferenceTypes'], props)}
          telReferenceTypes={R.pathOr([], ['configOptions', 'telReferenceTypes'], props)}
        />
      );

      const modal = {
        p: '0px',
        component: modalContent,
        firstChildZIndex: 'unset',
        options: {
          width: 680,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:edit-notification', 'Edit Notification'),
        },
      };

      openModal(modal);
    },
    handleEditIntegrationMailConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateIntegrationMailConfigRequest } = props;

      const component = (
        <IntegrationMailConfig
          editMode={true}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateIntegrationMailConfigRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          overflow: 'auto',
          maxHeight: '87vh',
          title: G.getEditTitle(['titles:integration-mail', 'Integration Mail']),
        },
      };

      openModal(modal);
    },
    handleEditMailTemplateConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateMailTemplateConfigRequest } = props;

      const component = (
        <MailTemplateForm
          editMode={true}
          openModal={openModal}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateMailTemplateConfigRequest}
          cloReferenceTypes={R.pathOr([], ['configOptions', 'cloReferenceTypes'], props)}
          telReferenceTypes={R.pathOr([], ['configOptions', 'telReferenceTypes'], props)}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          width: 740,
          movable: false,
          height: 'auto',
          title: G.getEditTitle(['titles:mail-template', 'Mail Template']),
        },
      };

      openModal(modal);
    },
    handleEditDocumentSendingConfig: (props: Object) => (initialValues: Object) => {
      const { openModal, createOrUpdateDocumentSendingConfigRequest } = props;

      const { documentTypeGuid, documentTypeName } = initialValues;

      let documentTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
        props,
        GC.COMMUNICATION_DOCUMENT_TYPE,
      );

      const isExist = R.find(R.propEq(documentTypeGuid, GC.FIELD_VALUE), documentTypeOptions);

      if (R.isNil(isExist)) {
        documentTypeOptions = R.prepend(
          {
            [GC.FIELD_LABEL]: documentTypeName,
            [GC.FIELD_VALUE]: documentTypeGuid,
          },
          documentTypeOptions,
        );
      }

      const component = (
        <DocumentSendingForm
          initialValues={initialValues}
          documentTypeOptions={documentTypeOptions}
          submitAction={createOrUpdateDocumentSendingConfigRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getEditTitle(['titles:document-sending', 'Document Sending']),
        },
      };

      openModal(modal);
    },
    handleRemoveIntegrationMailConfig: (props: Object) => (entityGuid: string, configName: string, entity: Object) => {
      const { openModal, closeModal, removeIntegrationMailConfigRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_DEFAULT_EMAIL], entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const removeIntegrationMailConfig = () => {
        closeModal();
        removeIntegrationMailConfigRequest(entityGuid);
      };

      const modal = G.getConfirmDeleteModal(component, removeIntegrationMailConfig);

      openModal(modal);
    },
    handleRemoveMailTemplateConfig: (props: Object) => (entityGuid: string, configName: string, entity: Object) => {
      const { openModal, closeModal, removeMailTemplateConfigRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_NAME], entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const removeMailTemplateConfig = () => {
        closeModal();
        removeMailTemplateConfigRequest(entityGuid);
      };

      const modal = G.getConfirmDeleteModal(component, removeMailTemplateConfig);

      openModal(modal);
    },
    handleRemoveDocumentSendingConfig: (props: Object) => (entityGuid: string, configName: string, entity: Object) => {
      const { openModal, closeModal, removeDocumentSendingConfigRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_DOCUMENT_TYPE_NAME], entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const removeDocumentSendingConfig = () => {
        closeModal();
        removeDocumentSendingConfigRequest(entityGuid);
      };

      const modal = G.getConfirmDeleteModal(component, removeDocumentSendingConfig);

      openModal(modal);
    },
  }),
  pure,
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:communication'
    groupSettings={GC.COMMUNICATION_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  roleList: makeSelectRoleList()(state),
  documents: makeSelectDocumentList()(state),
  documentTypes: makeSelectDocumentTypes()(state),
  documentsFilter: makeSelectDocumentFilter()(state),
  statusMessages: makeSelectStatusMessagesList()(state),
  reasonCodesOptions: makeSelectReasonCodesOptions()(state),
  mailTemplateConfig: makeSelectMailTemplateConfig()(state),
  documentSendingList: makeSelectDocumentSendingList()(state),
  notificationOptions: makeSelectNotificationTriggers()(state),
  notificationList: makeSelectNotificationListTriggers()(state),
  filteredDocumentList: makeSelectFilteredDocumentList()(state),
  integrationMailConfig: makeSelectIntegrationMailConfig()(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes()(state),
  filteredNotificationList: makeSelectFilteredNotificationList()(state),
  currentCommunicationMails: makeSelectCurrentCommunicationMails()(state),
  notificationTriggersFilter: makeSelectNotificationTriggersFilter()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.COMMUNICATION_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.COMMUNICATION_CONFIG_GROUP),
  notificationObjectTypeFilter: makeSelectNotificationObjectTypeFilter()(state),
  initialValues: makeSelectConfigInitialValues(state, GC.COMMUNICATION_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.COMMUNICATION_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setDocumentsFilter,
  updateConfigsRequest,
  createDocumentRequest,
  updateDocumentRequest,
  deleteNotificationRequest,
  createNotificationRequest,
  createStatusMessageRequest,
  deleteStatusMessageRequest,
  updateStatusMessageRequest,
  deleteDocumentConfigRequest,
  setNotificationTriggersFilter,
  getNotificationTriggersRequest,
  setNotificationObjectTypeFilter,
  removeMailTemplateConfigRequest,
  updateNotificationConfigsRequest,
  removeIntegrationMailConfigRequest,
  removeDocumentSendingConfigRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateMailTemplateConfigRequest,
  createOrUpdateIntegrationMailConfigRequest,
  createOrUpdateDocumentSendingConfigRequest,
})(enhance(ConfigPageComponent));
