import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderComponent } from 'react-recompose';
// common
import { makeSelectInitialSplashScreen } from '../../common/selectors';
// components
import LoaderComponent from '../../components/loader';
import ImageSlider from '../../components/slider-component';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex} from '../../ui';
// auth settings
import {
  resetPasswordPageSettings,
  resetPasswordFieldSettings,
  defaultResetPasswordInitFields,
  resetPasswordValidationSchemaObject,
} from './settings';
// feature auth
import AuthForm from './auth-form';
import { FormWrapper } from './ui';
import { makeSelectAuthData } from './selectors';
import stylingPanelHoc from './styling-panel-hoc';
import { sendResetPasswordRequest } from './actions';
import { createOptions, setDefaultSetting } from './helpers';
//////////////////////////////////////////////////

const ResetPassword = (props: Object) => {
  const {
    auth,
    handleSubmit,
    handleChange,
    isSubmitting,
    splashScreenSetting,
    handleGetInfoPanelRef,
  } = props;

  if (R.path(['loggedIn'], auth)) return <Redirect to='/' />;

  const { title, action, subtitle, linkAction } = resetPasswordPageSettings;

  let options = [];

  if (G.isNotNil(R.path(['panels'], splashScreenSetting))) {
    const urlList = R.pick([
      'firstBackgroundImageUrl',
      'secondBackgroundImageUrl',
      'thirdBackgroundImageUrl',
    ], splashScreenSetting);

    options = createOptions(R.path(['panels'], splashScreenSetting), urlList);
  } else {
    options = setDefaultSetting(R.path(['firstBackgroundImageUrl'], splashScreenSetting));
  }

  const linkToLogin = (action: string) => (
    <Flex>
      <Box ml='5px' mr={10} transform='rotate(90deg)'>{I.arrowDown()}</Box>
      {action}
    </Flex>
  );

  return (
    <FormWrapper>
      <ImageSlider
        slides={options}
        splashScreenSetting={splashScreenSetting}
        handleGetInfoPanelRef={handleGetInfoPanelRef}
        color={R.path(['panelColor'], splashScreenSetting)}
      />
      <AuthForm
        mbLinkTo={24}
        title={title}
        props={props}
        heightLogo={150}
        textAlign='center'
        widthSubmitBtn={1}
        subtitle={subtitle}
        fontSizeSubmitBtn={18}
        actionSubmitBtn={action}
        displayInputsBlock='block'
        puddingSubmitBtn='8px 26px'
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isSubmitting={isSubmitting}
        imageSlidersOptions={options}
        pathLinkTo={GC.ROUTE_PATH_LOGIN}
        fields={resetPasswordFieldSettings}
        actionLinkTo={linkToLogin(linkAction)}
        splashScreenSetting={splashScreenSetting}
      />
    </FormWrapper>
  );
};

const enhance = compose(
  stylingPanelHoc,
  withFormik({
    enableReinitialize: true,
    validationSchema: resetPasswordValidationSchemaObject,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultResetPasswordInitFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { sendResetPasswordRequest } = props;

      setSubmitting(true);
      sendResetPasswordRequest({...values, callback: () => setSubmitting(false) });
    },
  }),
  branch(
    ({ splashScreenSetting }: Object) => R.not(R.path(['isLoaded'], splashScreenSetting)),
    renderComponent(LoaderComponent),
  ),
  pure,
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  auth: makeSelectAuthData(state),
  splashScreenSetting: makeSelectInitialSplashScreen(state),
}));

export default connect(mapStateToProps, {
  sendResetPasswordRequest,
})(enhance(ResetPassword));
