import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import {
  sendTelToMacroPointRequest,
  createQBIIFImportByInvoiceTypeRequest,
} from '../../../../common/actions';
// features
import {
  createFleetInvoiceByTelGuidRequest,
  createCarrierInvoiceByTelGuidRequest,
} from '../../../dispatch-board-new/load/actions';
// helpers/constants
import * as G from '../../../../helpers';
// feature dispatch details new
import * as C from '../../constants';
import { withAddReference } from './with-reference';
import { withAddCustomerRate } from './with-customer-rate';
import { withAddDriverInvoice } from './with-driver-invoice';
import { withAddStatusMessage } from './with-status-messages';
import { withAddCarrierInvoice } from './with-carrier-invoice';
import { withAddAdvancePayment } from './with-advance-payment';
import { withPostToLoadBoard } from './with-load-board-actions';
import { withAddCustomerInvoice } from './with-customer-invoice';
import { withAddCrossBorder } from './with-cross-border-integration';
import { withActivateMacroPoint } from './with-macro-point-row-actions';
import { withAddDriverOrCarrierRate } from './with-carrier-driver-rate';
import { withAddServiceVendorInvoice } from './with-service-vendor-invoice';
import {
  getMacroPointListRequest,
  createDriverInvoiceRequest,
  createLoadReferenceRequest,
  toggleExpandedListsSuccess,
  toggleLoadVehicleItemRequest,
  selectLoadCustomerRateRequest,
  createLoadCustomerRateRequest,
  getLoadNotificationListRequest,
  selectDriverCarrierRateRequest,
  createLoadStatusMessageRequest,
  createLoadCarrierInvoiceRequest,
  createLoadAdvancePaymentRequest,
  createLoadCustomerInvoiceRequest,
  createServiceVendorInvoiceRequest,
  createCrossBorderIntegrationRequest,
  createLoadMultipleStatusMessageRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddTableEntity = compose(
  connect(
    null,
    {
      getMacroPointListRequest,
      sendTelToMacroPointRequest,
      createDriverInvoiceRequest,
      createLoadReferenceRequest,
      toggleExpandedListsSuccess,
      toggleLoadVehicleItemRequest,
      createLoadCustomerRateRequest,
      selectLoadCustomerRateRequest,
      getLoadNotificationListRequest,
      createLoadStatusMessageRequest,
      selectDriverCarrierRateRequest,
      createLoadCarrierInvoiceRequest,
      createLoadAdvancePaymentRequest,
      createLoadCustomerInvoiceRequest,
      createServiceVendorInvoiceRequest,
      createFleetInvoiceByTelGuidRequest,
      createCrossBorderIntegrationRequest,
      createCarrierInvoiceByTelGuidRequest,
      createQBIIFImportByInvoiceTypeRequest,
      createLoadMultipleStatusMessageRequest,
    },
  ),
  withAddReference,
  withAddCrossBorder,
  withPostToLoadBoard,
  withAddCustomerRate,
  withAddDriverInvoice,
  withAddStatusMessage,
  withAddCarrierInvoice,
  withAddAdvancePayment,
  withActivateMacroPoint,
  withAddCustomerInvoice,
  withAddDriverOrCarrierRate,
  withAddServiceVendorInvoice,
  withHandlers({
    handleLoadMoreEntities: (props: Object) => () => {
      const { load, tableNumber, activeListName, getLoadNotificationListRequest } = props;

      if (R.equals(activeListName, C.ACTIVE_LIST_NOTIFICATIONS)) {
        const loadGuid = G.getGuidFromObject(load);

        return getLoadNotificationListRequest({ loadGuid, tableNumber, loadMoreEntities: true });
      }
    },
    handleAddTableEntity: (props: Object) => (options: Object) => {
      const {
        activeListName,
        handleAddReference,
        handleAddCrossBorder,
        handleAddCustomerRate,
        handleAddDriverInvoice,
        handleAddCarrierInvoice,
        handleAddAdvancePayment,
        handleActivateMacroPoint,
        handlePostTELToLoadBoard,
        handleOpenAddStatusMessage,
        handleAddDriverCarrierRate,
        handleAddCloCustomerInvoice,
        handleAddServiceVendorInvoice,
      } = props;

      const handlersMap = {
        [C.ACTIVE_LIST_REFERENCES]: handleAddReference,
        [C.ACTIVE_LIST_LOAD_BOARDS]: handlePostTELToLoadBoard,
        [C.ACTIVE_LIST_MACRO_POINT]: handleActivateMacroPoint,
        [C.ACTIVE_LIST_CUSTOMER_RATES]: handleAddCustomerRate,
        [C.ACTIVE_LIST_DRIVER_INVOICES]: handleAddDriverInvoice,
        [C.ACTIVE_LIST_ADVANCE_PAYMENT]: handleAddAdvancePayment,
        [C.ACTIVE_LIST_CARRIER_INVOICES]: handleAddCarrierInvoice,
        [C.ACTIVE_LIST_STATUS_MESSAGES]: handleOpenAddStatusMessage,
        [C.ACTIVE_LIST_CUSTOMER_INVOICES]: handleAddCloCustomerInvoice,
        [C.ACTIVE_LIST_CARRIER_FLEET_RATES]: handleAddDriverCarrierRate,
        [C.ACTIVE_LIST_CROSS_BORDER_INTEGRATION_LIST]: handleAddCrossBorder,
        [C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES]: handleAddServiceVendorInvoice,
      };

      handlersMap[activeListName](options);
    },
  }),
  pure,
);
