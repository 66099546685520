import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-profile
import * as A from '../actions';
//////////////////////////////////////////////////

// safety violation
const getSafetyViolationListByEntityTypeSuccess = (state: Object, { data, entityType, primaryObjectGuid }: Object) => {
  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(item, { index, expanded: false })),
  )(R.or(data, []));

  if (R.equals(entityType, 'safetyViolation')) return P.$set('driver.safetyViolation', list, state);

  return P.$set(`driver.safetyViolation.${primaryObjectGuid}.${entityType}s`, list, state);
};

const toggleSafetyViolationItem = (state: Object, { guid, componentType = null }: string) => {
  const item = R.pathOr({}, ['driver', 'safetyViolation', guid], state);

  if (G.isAllTrue(
    item.expanded,
    G.notEquals(componentType, item.componentType),
    R.includes(componentType, ['note', 'document']),
  )) return P.$set(`driver.safetyViolation.${guid}.componentType`, componentType, state);

  return (
    P.$all(
      P.$toggle(`driver.safetyViolation.${guid}.expanded`),
      P.$set(`driver.safetyViolation.${guid}.componentType`, componentType),
      state,
    )
  );
};

const createOrUpdateSafetyViolationEntityByEntityTypeSuccess = (state: Object, { data, entityType }: Object) => {
  const safetyViolation = R.path(['driver', 'safetyViolation'], state);

  const { guid, primaryObjectGuid, safetyViolationGuid } = data;

  if (R.equals(entityType, 'safetyViolation')) {
    const notes = R.pathOr({}, [guid, 'notes'], safetyViolation);
    const documents = R.pathOr({}, [guid, 'documents'], safetyViolation);
    const expanded = R.pathOr(false, [guid, 'expanded'], safetyViolation);
    const componentType = R.path([guid, 'componentType'], safetyViolation);
    const index = R.pathOr(R.length(R.values(safetyViolation)), [guid, 'index'], safetyViolation);

    return P.$set(
      `driver.safetyViolation.${guid}`,
      R.mergeRight(data, { notes, index, expanded, documents, componentType }),
      state,
    );
  }

  const correctSafetyViolationGuid = R.or(primaryObjectGuid, safetyViolationGuid);
  const path = ['driver', 'safetyViolation', correctSafetyViolationGuid, `${entityType}s`];
  const index = R.pathOr(R.length(R.values(R.path(path, state))), [...path, guid, 'index'], state);

  return P.$set(`${R.join('.', path)}.${guid}`, R.assoc('index', index, data), state);
};

const removeSafetyViolationEntityByEntityTypeSuccess = (state: Object, data: Object) => {
  const { guid, entityType, primaryObjectGuid } = data;

  const path = G.ifElse(
    R.equals(entityType, 'safetyViolation'),
    `driver.safetyViolation.${guid}`,
    `driver.safetyViolation.${primaryObjectGuid}.${entityType}s.${guid}`,
  );

  return P.$drop(path, state);
};

// users
const getGeneralUserListSuccess = (state: Object, data: Object) => P.$set('driver.generalUserList', data, state);

const getFleetDispatchingGroupSuccess = (state: Object, data: Object) => (
  P.$set('driver.dispatchingGroup', data, state)
);

// assignment
const getDriverAssignmentSuccess = (state: Object, data: Object) => P.$set('assignment', data, state);

const getAvailableAssignableSuccess = (state: Object, { options, groupName }: Object) => (
  P.$set(`availableAssignable.${groupName}`, options, state)
);

// monthly payroll deduction
const getDriverPayrollChargesIsPaidOnPayrollSuccess = (state: Object, data: Object) => (
  P.$set(
    'driver.monthlyPayrollDeductionList',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      R.path(['driver', 'monthlyPayrollDeductionList'], state),
    ),
    state,
  )
);

// payroll accessorial
const getDriverPayrollAccessorialsIsPaidOnPayrollSuccess = (state: Object, data: Object) => (
  P.$set(
    'driver.payrollAccessorialList',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      R.path(['driver', 'payrollAccessorialList'], state),
    ),
    state,
  )
);

// advance payment
const updateAdvancePaymentStatusSuccess = (state: Object, data: Object) => {
  const { advancePaymentList } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data)), GC.FIELD_GUID, advancePaymentList);

  return P.$set(`driver.advancePaymentList.${index}`, data, state);
};

// filters
const setUnavailablePeriodDatesFilter = (state: Object, filter: string) => (
  P.$set('driver.formGroupFilters.unavailablePeriodDatesFilter', filter, state)
);

const setUnavailablePeriodReasonFilter = (state: Object, filter: string) => (
  P.$set('driver.formGroupFilters.unavailablePeriodReasonFilter', filter, state)
);

// expenses
const getDriverExpenseTypesSuccess = (state: Object, data: Array) => (
  P.$set('driverExpenseTypes', data, state)
);

const deleteDriverExpenseDocumentSuccess = (state: Object, guid: string) => {
  const index = R.findIndex(
    R.propEq(guid, GC.FIELD_GUID),
    P.$get('driver.driverExpenseList', state),
  );

  return P.$all(
    P.$set(`driver.driverExpenseList.${index}.fileUri`, null),
    P.$set(`driver.driverExpenseList.${index}.fileName`, null),
    state,
  );
};

export const driverReducer = {
  // safety-violation
  [A.toggleSafetyViolationItem]: toggleSafetyViolationItem,
  [A.getSafetyViolationListByEntityTypeSuccess]: getSafetyViolationListByEntityTypeSuccess,
  [A.removeSafetyViolationEntityByEntityTypeSuccess]: removeSafetyViolationEntityByEntityTypeSuccess,
  [A.createOrUpdateSafetyViolationEntityByEntityTypeSuccess]: createOrUpdateSafetyViolationEntityByEntityTypeSuccess,
  // users
  [A.getGeneralUserListSuccess]: getGeneralUserListSuccess,
  [A.getFleetDispatchingGroupSuccess]: getFleetDispatchingGroupSuccess,
  // assignment
  [A.getDriverAssignmentSuccess]: getDriverAssignmentSuccess,
  [A.getAvailableAssignableSuccess]: getAvailableAssignableSuccess,
  // monthly payroll deduction
  [A.getDriverPayrollChargesIsPaidOnPayrollSuccess]: getDriverPayrollChargesIsPaidOnPayrollSuccess,
  // payroll accessorial
  [A.getDriverPayrollAccessorialsIsPaidOnPayrollSuccess]: getDriverPayrollAccessorialsIsPaidOnPayrollSuccess,
  // advance payment
  [A.updateAdvancePaymentStatusSuccess]: updateAdvancePaymentStatusSuccess,
  // filters
  [A.setUnavailablePeriodDatesFilter]: setUnavailablePeriodDatesFilter,
  [A.setUnavailablePeriodReasonFilter]: setUnavailablePeriodReasonFilter,
  // expenses
  [A.getDriverExpenseTypesSuccess]: getDriverExpenseTypesSuccess,
  [A.deleteDriverExpenseDocumentSuccess]: deleteDriverExpenseDocumentSuccess,
};
