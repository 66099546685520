// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'date',
    value: GC.FIELD_IFTA_DATE_FROM,
    name: G.getWindowLocale('titles:date-from', 'Date From'),
  },
  {
    type: 'date',
    value: GC.FIELD_IFTA_DATE_TO,
    name: G.getWindowLocale('titles:date-to', 'Date To'),
  },
  {
    type: 'string',
    value: GC.FIELD_NAME,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_STATUS,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-on', 'Created On'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
];

