import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet-profile
import * as A from '../actions';
//////////////////////////////////////////////////

// daily log
const getDailyLogListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const { dailyLog } = R.propOr({}, 'truck', state);

  const itemList = R.concat(R.propOr([], 'itemList', dailyLog), data);

  const resultLength = R.length(data);
  const itemListLength = R.length(itemList);

  const offset = R.add(resultLength, R.propOr(0, 'offset', dailyLog));

  const totalCount = G.ifElse(R.equals(resultLength, 20), R.inc(itemListLength), itemListLength);

  return P.$set('truck.dailyLog', { offset, itemList, totalCount }, state);
};

export const truckReducer = {
  // daily log
  [A.getDailyLogListSuccess]: getDailyLogListSuccess,
};
