import * as R from 'ramda';
import React from 'react';
// components
import {
  TableRow,
  TableColumn,
  TableContainer,
} from '../../../components/list';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { hideIf } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature configs
import * as H from '../helpers';
//////////////////////////////////////////////////

const EnhancedBox = hideIf(Box);

const Header = (props: Object) => (
  <TableRow
    top='0px'
    ta='left'
    py='10px'
    px='15px'
    key='header'
    display='flex'
    jc='flex-start'
    isHeader={false}
    position='sticky'
    bgColor={G.getTheme('tables.header.greyBgColor')}
    borderColor={G.getTheme('tables.rows.borderColor')}
  >
    <TableColumn width='60px' />
    {
      props.fields.map((field: Object, index: number) => {
        if (field.notRender) {
          return null;
        }
        return (
          <TableColumn
            key={index}
            width={R.or(R.or(field.tableColumnWith, props.tableColumnWith), '220px')}
          >
            {R.or(G.getWindowLocale(field.name), field.nameForAttribute)}
          </TableColumn>
        );
      })
    }
  </TableRow>
);

const renderColumnValue = (field: Object, entity: Object, props: Object) => {
  if (field.notView) {
    return null;
  }
  if (R.isNil(field.customLogic)) {
    if (field.isEnum) {
      return G.getEnumLocaleFromStringOrArray(R.prop(field.nameForAttribute, entity));
    }

    return H.convertDataTypeToUiTableField(
      R.prop(field.nameForAttribute, entity),
      {
        fieldName: field.nameForAttribute,
        params: G.ifElse(
          G.isTrue(props.paramIsEntity),
          entity,
          R.prop(field.nameForAttribute, entity),
        ),
        callbacksMap: props.callbacksMap,
      },
    );
  }
  return field.customLogic(field, entity, props);
};

const renderColumn = (entity: Object, props: Object) => {
  const { showRemove, configName, editPopupTitle, handleEditEntity, handleRemoveEntity } = props;

  const { guid, editable, deletable, parentGuid } = entity;

  if (R.and(G.isFalse(editable), G.isFalse(deletable))) return <TableColumn width={60} />;

  if (R.and(G.isTrue(showRemove), G.isFalse(editable))) {
    return (
      <TableColumn width={60}>
        <Box
          mr={10}
          cursor='pointer'
          display='inline-block'
          onClick={() => handleRemoveEntity(guid, configName, entity)}
        >
          {I.trash(G.getTheme('colors.dark.blue'))}
        </Box>
      </TableColumn>
    );
  }

  if (G.isFalse(editable)) return <TableColumn width={60} />;

  return (
    <TableColumn width={60}>
      <EnhancedBox
        mr={10}
        cursor='pointer'
        display='inline-block'
        hideComponent={G.isFalse(deletable)}
        onClick={() => handleRemoveEntity(guid, configName, entity)}
      >
        {I.trash(G.getTheme('colors.dark.blue'))}
      </EnhancedBox>
      <EnhancedBox
        mr={10}
        cursor='pointer'
        display='inline-block'
        hideComponent={G.isNotNil(parentGuid)}
        onClick={() => (
          handleEditEntity(entity, editPopupTitle, configName)
        )}
      >
        {I.pencil(G.getTheme('colors.dark.blue'))}
      </EnhancedBox>
    </TableColumn>
  );
};

const renderRows = (props: Object) => (
  props.entities.map((entity: Object, rowIndex: number) => (
    <TableRow
      ta='left'
      py='10px'
      px='15px'
      height='auto'
      display='flex'
      key={rowIndex}
      jc='flex-start'
    >
      {renderColumn(entity, props)}
      {
        props.fields.map((field: Object, index: number) => {
          if (field.notRender) {
            return null;
          }
          return (
            <TableColumn
              fieldName={field.nameForAttribute}
              key={`${field.nameForAttribute}${index}`}
              width={R.or(R.or(field.tableColumnWith, props.tableColumnWith), '220px')}
              specificStyles={{
                paddingRight: '10px',
                wordBreak: 'break-word',
              }}
            >
              {renderColumnValue(field, entity, props)}
            </TableColumn>
          );
        })
      }
    </TableRow>
  ))
);

const CustomConfigTableComponent = (props: Object) => {
  return (
    <TableContainer width='max-content' minWidth='100%'>
      <Header {...props} />
      {
        G.isNotNilAndNotEmpty(props.entities)
        && renderRows(props)
      }
    </TableContainer>
  );
};

export default CustomConfigTableComponent;
