import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const defaultValues = {
  [GC.FIELD_LATE_DATE]: '',
  [GC.FIELD_EARLY_DATE]: '',
  [GC.FIELD_TEMPLATE_GUID]: '',
};

const validateDate = (date: string) => G.isValidMomentWithFormat(
  G.convertInstanceToDefaultDateTimeFormat(date),
  GC.DEFAULT_DATE_TIME_FORMAT,
);

export const validationSchema = {
  [GC.FIELD_TEMPLATE_GUID]: G.yupStringRequired,
  [GC.FIELD_LATE_DATE]: G.yupStringRequired.test(GC.FIELD_LATE_DATE, G.getShouldBeValidDateLocaleTxt(), validateDate),
  [GC.FIELD_EARLY_DATE]: G.yupStringRequired.test(GC.FIELD_EARLY_DATE, G.getShouldBeValidDateLocaleTxt(), validateDate),
};

const inputWrapperStyles = { mb: 25, width: 250 };

export const getFieldSettings = (openedFromTemplatePage: boolean) => [
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'routeTemplates',
    disabled: openedFromTemplatePage,
    fieldName: GC.FIELD_TEMPLATE_GUID,
    inputWrapperStyles: { mb: 25, width: 520 },
    label: ['titles:route-template', 'Route Template'],
  },
  {
    isRequired: true,
    isClearable: true,
    inputWrapperStyles,
    type: 'datePicker',
    timeSelection: true,
    fieldName: GC.FIELD_EARLY_DATE,
    label: ['titles:first-stop-date-early', 'First Stop Date Early'],
    maxDate: ({ values: { lateDate } }: Object) => R.and(
      G.isNotNilAndNotEmpty(lateDate), G.makeMomentInstance(lateDate),
    ),
  },
  {
    isRequired: true,
    isClearable: true,
    inputWrapperStyles,
    type: 'datePicker',
    timeSelection: true,
    fieldName: GC.FIELD_LATE_DATE,
    label: ['titles:first-stop-date-late', 'First Stop Date Late'],
    minDate: ({ values: { earlyDate } }: Object) => R.and(
      G.isNotNilAndNotEmpty(earlyDate), G.makeMomentInstance(earlyDate),
    ),
  },
];
