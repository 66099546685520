import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////
const {
  setReports,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const setTruckGuid = createAction('setTruckGuid');
const setDriverGuid = createAction('setDriverGuid');
const getTruckListSuccess = createAction('getTruckListSuccess');
const toggleFuelCardDetails = createAction('toggleFuelCardDetails');
const updateFuelCardRequest = createAction('updateFuelCardRequest');
const createFuelCardRequest = createAction('createFuelCardRequest');
const deleteFuelCardsRequest = createAction('deleteFuelCardsRequest');
const deleteFuelCardsSuccess = createAction('deleteFuelCardsSuccess');
const visitFuelCardsOnFleetPage = createAction('visitFuelCardsOnFleetPage');
const receivedDriverListSuccess = createAction('receivedDriverListSuccess');
const updateTruckFuelCardRequest = createAction('updateTruckFuelCardRequest');
const setDriverAssignmentsTrucks = createAction('setDriverAssignmentsTrucks');
const updateDriverFuelCardRequest = createAction('updateDriverFuelCardRequest');
const toggleShowUnemployedDrivers = createAction('toggleShowUnemployedDrivers');
const getLinesByFuelCardGuidSuccess = createAction('getLinesByFuelCardGuidSuccess');
const toggleShowIsNotInServiceTrucks = createAction('toggleShowIsNotInServiceTrucks');
const getDriverPayrollNumberListByGuidsRequest = createAction('getDriverPayrollNumberListByGuidsRequest');
const getVendorPayrollNumberListByGuidsRequest = createAction('getVendorPayrollNumberListByGuidsRequest');
const receivedDriverPayrollNumberListByGuidsSuccess = createAction('receivedDriverPayrollNumberListByGuidsSuccess');
const receivedVendorPayrollNumberListByGuidsSuccess = createAction('receivedVendorPayrollNumberListByGuidsSuccess');

export {
  // report
  setReports,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  updateReportRequest,
  createReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  setTruckGuid,
  setDriverGuid,
  getTruckListSuccess,
  toggleFuelCardDetails,
  updateFuelCardRequest,
  createFuelCardRequest,
  deleteFuelCardsRequest,
  deleteFuelCardsSuccess,
  visitFuelCardsOnFleetPage,
  receivedDriverListSuccess,
  updateTruckFuelCardRequest,
  setDriverAssignmentsTrucks,
  updateDriverFuelCardRequest,
  toggleShowUnemployedDrivers,
  getLinesByFuelCardGuidSuccess,
  toggleShowIsNotInServiceTrucks,
  getDriverPayrollNumberListByGuidsRequest,
  getVendorPayrollNumberListByGuidsRequest,
  receivedDriverPayrollNumberListByGuidsSuccess,
  receivedVendorPayrollNumberListByGuidsSuccess,
};
