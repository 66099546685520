import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import React, { Fragment, useCallback } from 'react';
// components
import { Tabs2, withTabs2, ActionBox } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature fleet-profile
import { ReportFormGroupTable } from './report-form-group-table';
import { FormGroupTableWithSimpleCrudOperations } from '../components/form-group-table';
import { getItemListSuccess, removeEntityRequest, createOrUpdateEntityRequest } from '../actions';
/////////////////////////////////////////////

export const tabsGroupWrapperEnhance = compose(
  connect(null, { getItemListSuccess, removeEntityRequest, createOrUpdateEntityRequest }),
  withTabs2,
  pure,
);

export const Tab = (props: Object) => (
  <Fragment>
    {
      R.pathOr([], ['groups'], props).map((group: Object, index: number) => {
        const { isReport, Component } = group;

        if (G.isNotNil(Component)) return <Component {...group} {...props} key={index} />;

        const tableCommonProps = { ...group, ...R.omit(['groups', 'entities', 'collapsedGroup'], props) };

        if (isReport) return <ReportFormGroupTable {...tableCommonProps} key={index} />;

        return <FormGroupTableWithSimpleCrudOperations {...tableCommonProps} key={index} />;
      })
    }
  </Fragment>
);

const ViewButton = (props: Object) => {
  const { view, title, setView } = props;

  const handleSetView = useCallback(() => {
    setView(view);

    const saveSettingsToDB = async () => {
      await G.saveIndexedDBItem(GC.AmousUIIdbProps, { value: view, id: GC.fleetProfileTabsViewIdbId });
    };

    saveSettingsToDB();
  }, [view]);

  return (
    <ActionBox text={title} action={handleSetView} boxStyles={{ pb: '4px', width: 65 }} />
  );
};

export const TabsGroupWrapper = (props: Object) => {
  const { tabs, children, activeTab, setTabsView, tabsCollapsedView, handleSetActiveTab } = props;

  const COLLAPSED = 'COLLAPSED';
  const FULL_SCREEN = 'FULL_SCREEN';
  const whiteColor = G.getTheme('colors.white');

  return (
    <Fragment>
      <Flex pr={15} mb='3px' bg={whiteColor} justifyContent='space-between'>
        <Tabs2
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          tabsProps={{ variant: 'scrollable' }}
        />
        <ViewButton
          setView={setTabsView}
          view={G.ifElse(tabsCollapsedView, FULL_SCREEN, COLLAPSED)}
          title={G.getWindowLocale(
            ...G.ifElse(tabsCollapsedView, ['actions:expand', 'Expand'], ['actions:collapse', 'Collapse']),
          )}
        />
      </Flex>
      <Box
        bg={whiteColor}
        borderRadius='4px'
        boxShadow={`0 2px 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
      >
        {children}
      </Box>
    </Fragment>
  );
};
