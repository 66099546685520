import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature carrier-edi
import * as A from './actions';
import { FILTER_PARAMS } from './settings/filter-params';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

export function* handleGetItemListSaga({ payload }: boolean) {
  try {
    yield put(A.setListLoading(true));

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', R.path(['guid'], reportParams)),
    )) {
      return yield put(A.setListLoading(false));
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const systemFields = [
      GC.FIELD_GUID,
      GC.FIELD_ERRORS,
      GC.FIELD_STATUS,
      GC.FIELD_BRANCH_GUID,
      GC.FIELD_EDI_FILE_URI,
      GC.GRC.TEL_INFO_TEL_GUID,
      GC.FIELD_CREATED_INVOICE,
      GC.FIELD_EDI_TRANSACTION_TYPE,
      GC.FIELD_EDI_SENDER_QUALIFIER,
      GC.FIELD_EDI_ORIGINAL_FILE_NAME,
      GC.SYSTEM_OBJECT_STATUS_MESSAGES,
    ];

    const guids = R.pathOr(null, ['guids'], payload);

    const reqBody = {
      guids,
      orderFields,
      systemFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.addSystemFieldsToTableReport(
      G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      systemFields,
    );

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.ediCarrierInvoiceTransactionList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(R.assoc('currentBranch', currentBranchGuid, { data, guids })));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListRequest fail');
    }

    yield put(A.setListLoading(false));
  } catch (err) {
    yield call(G.handleException, err, 'handleGetItemListRequest exception');

    yield put(A.setListLoading(false));
  }
}

export function* handleDeleteListItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.ediCarrierInvoiceTransaction, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status, data)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteListItemRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteListItemRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleChangeDefaultReportSaga,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleAvailableReportsRequest,
} = getReportSagas(GC.INVOICE_EDI_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

function* handleDownloadEdiFileSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { fileUri, fileName } = payload;

    const options = {
      resType: 'arraybuffer',
      params: {
        fileUri,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.ediCarrierInvoiceTransactionDownloadFile, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      FileDownload(data, fileName);
    } else {
      yield call(G.handleFailResponse, res, 'handleDownloadEdiFileSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDownloadEdiFileSaga exception');
  }
}

function* assignEdiInvoiceToTripRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { params: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.ediCarrierInvoiceAssignToTrip, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'assignEdiInvoiceToTripRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'assignEdiInvoiceToTripRequest exception');
  }
}

function* handleUpdateCarrierInvoiceRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { entity, invoice } = payload;

    const options = { data: invoice };

    const res = yield call(sendRequest, 'put', endpointsMap.telCarrierInvoice, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateCarrierInvoiceSuccess({ data, entity }));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierInvoiceRequestSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCarrierInvoiceRequestSaga exception');
  }
}

export function* handleSetFilterParams() {
  yield put(A.setFilterProps(FILTER_PARAMS));
}

export function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CARRIER_EDI_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport(['additionalFilters']));
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });
    yield call(handleSetFilterParams);

    yield put(closeLoader());
    break;
  }
}

function* pageWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(GC.VISIT_CARRIER_EDI_LIST_PAGE, handleVisitPageSaga);
  yield takeLatest(A.downloadEdiFileRequest, handleDownloadEdiFileSaga);
  yield takeLatest(A.deleteListItemRequest, handleDeleteListItemRequest);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.assignEdiInvoiceToTripRequest, assignEdiInvoiceToTripRequest);
  yield takeLatest(A.updateCarrierInvoiceRequest, handleUpdateCarrierInvoiceRequestSaga);
}

export default pageWatcherSaga;
