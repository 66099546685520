import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// ui
import { Box, Text, CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    openModalHandler: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <Box>
          <FormGroupTitleMultiple
            mb='0'
            showArrowToggle={false}
            title='Form Group Title Multiple'
          />
          <Text p={15}>Modal Content...</Text>
        </Box>
      );

      const modal = {
        p: '0px',
        component,
        options: {
          width: 350,
          height: 200,
          btnWidth: 100,
          controlButtons: [
            {
              type: 'button',
              name: 'submit',
              action: () => closeModal(),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const FormGroupTitleMultipleComponent = (props: Object) => {
  const description = '<FormGroupTitleMultiple /> component is used for rendering a title for a form group.';

  const usage = (
    <ul>
      <li>Parameter `<i>title</i>` is the main text content of the form group title.</li>
      <li>Parameter `<i>endIcon</i>` is an optional icon displayed at the end of the title.</li>
      <li>Parameter `<i>endLabel</i>` is an optional label displayed at the end of the title.</li>
      <li>Parameter `<i>onClickLabel</i>` is a function to handle click events on the end label.</li>
      <li>Parameter `<i>frontIcon</i>` is an optional icon displayed at the beginning of the title.</li>
      <li>Parameter `<i>frontLabel</i>` is an optional label displayed at the beginning of the title.</li>
      <li>Parameter `<i>onClickFrontLabel</i>` is a function to handle click events on the front label.</li>
      <li>Parameter `<i>isOpened</i>` is a boolean indicating whether the form group is opened or closed.</li>
      <li>Parameter `<i>onToggleFormGroup</i>` is a function to handle click events on the toggle arrow icon.</li>
      <li>Parameter `<i>showArrowToggle</i>` is a boolean indicating whether to display an arrow icon for toggling the form group.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      description={description}
      name='FormGroupTitleMultiple'
      path='src/components/form-group-title-multiple'
    >
      <CustomButton onClick={props.openModalHandler}>Open modal with `Form Group Title Multiple`</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(FormGroupTitleMultipleComponent));
