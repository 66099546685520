import * as R from 'ramda';
import {
  all,
  put,
  call,
  select,
  takeLatest,
} from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-planner
import * as A from '../actions';
import * as H from '../helpers';
import { CLO_NAME } from '../constants';
import {
  makeSelectCurrentRoute,
  makeSelectCloEventList,
  makeSelectCloEventReport,
  makeSelectGlobalFilterValue,
  makeSelectCloEventFilterParams,
  makeSelectBranchGuidForRequest,
} from '../selectors';
//////////////////////////////////////////////////

const requiredCloEventFields = [
  GC.FIELD_CLO_GUID,
  GC.FIELD_TEL_GUID,
  GC.FIELD_EVENT_TYPE,
  GC.GRC.CLO_BRANCH_GUID,
  GC.GRC.CLO_PRIMARY_REFERENCE_VALUE,
  GC.GRC.LAST_TERMINAL_DROP_LATE_DATE,
  GC.GRC.LAST_TERMINAL_DROP_EARLY_DATE,
  GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID,
];

const fields = [
  GC.FIELD_CLO_GUID,
  GC.FIELD_EVENT_TYPE,
  GC.GRC.CLO_BRANCH_GUID,
  GC.GRC.LOCATION_LATITUDE,
  GC.GRC.LOCATION_LONGITUDE,
  GC.GRC.CLO_PRIMARY_REFERENCE_VALUE,
  GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID,
];

const pendingSearchCriteria = R.of(Array, {
  operation: 'in',
  dataType: 'string',
  propertyName: GC.FIELD_STATUS,
  stringValue: GC.STATUS_PENDING,
});

function* handleGetCloEventListForMapSaga() {
  try {
    yield put(A.setCloEventListForMapLoading(true));

    const report = yield select(makeSelectCloEventReport());
    const filterParams = yield select(makeSelectCloEventFilterParams());
    let currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());
    const branchGuidForRequest = yield select(makeSelectBranchGuidForRequest());

    if (G.isNotNilAndNotEmpty(branchGuidForRequest)) currentBranchGuid = branchGuidForRequest;

    const searchCriteria = transformSearchCriteriaBeforeReportPost(
      R.concat(G.getOrElse(report, 'searchCriteria', []), pendingSearchCriteria),
    );

    const reqBody = {
      offset: 0,
      limit: 200,
      searchCriteria,
      globalFilterValue,
      fields: G.addRequiredFields([], fields),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.getOrElse(report, 'orderFields', []),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: transformSearchCriteriaBeforeFilterPost(filterParams) }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloEventListLoadPlanner, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCloEventsListForMapSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCloEventListSaga fail');
    }

    yield put(A.setCloEventListForMapLoading(false));
  } catch (err) {
    yield put(A.setCloEventListLoading(false));

    yield call(G.handleException, err, 'handleGetCloEventListSaga exception');
  }
}

function* getStopInfoRequest({ payload }: Object) {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.getCloEventInfoForMapEndpoint(payload));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getStopInfoSuccess(R.assoc(GC.FIELD_GUID, payload, data)));
    } else {
      yield call(G.handleFailResponse, res, 'getStopInfoRequest fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'getStopInfoRequest exception');
  }
}

function* handleGetActiveCloEventListSaga(report: Object) {
  try {
    yield put(A.setCloEventListLoading(true));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const pagination = {
      offset: 0,
      limit: 200,
      hidePlanned: false,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const fields = G.addRequiredFields(G.getOrElse(report, 'fields', []), requiredCloEventFields);
    const options = { data: R.mergeRight(R.assoc('fields', fields, report), pagination) };

    const res = yield call(sendRequest, 'post', endpointsMap.cloEventListLoadPlanner, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setSelectedCloEventList(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetActiveCloEventListSaga fail');

      yield put(A.setCloEventListLoading(false));
    }

    yield put(A.setCloEventListLoading(false));
  } catch (err) {
    yield put(A.setCloEventListLoading(false));
    yield call(G.handleException, err, 'handleGetActiveCloEventListSaga exception');
  }
}

function* handleRefreshCloEventListWithRouteAndSelectedEvents(guids: Array) {
  try {
    const eventList = yield select(makeSelectCloEventList());
    const currentRoute = yield select(makeSelectCurrentRoute());
    const cloEventReport = yield select(makeSelectCloEventReport());

    const selectedEvents = R.filter(R.prop('selected'), eventList);

    const eventGuids = R.concat(
      guids,
      H.getEventGuidsFromListAndRoute(selectedEvents, currentRoute),
    );

    yield put(A.resetCloEventListAndPagination());

    if (G.isNotNilAndNotEmpty(eventGuids)) {
      const data = R.compose(
        R.assoc('eventGuids', eventGuids),
        R.dissoc('searchCriteria'),
      )(cloEventReport);

      yield call(handleGetActiveCloEventListSaga, data);
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleRefreshCloEventListWithSelectedEvents exception');
  }
}

function* handleAddMapCloEventToPlanner(payload: Object) {
  const { event, telGuid, details, matchEvent } = payload;
  const { guid, cloGuid } = event;

  yield put(A.getCloEventDetailsSuccess({ guid, cloGuid, data: details }));

  const templateId = R.prop(GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID, event);

  if (G.isNotNilAndNotEmpty(templateId)) {
    yield put(A.addTerminalOnEventAddOrMove({ telGuid, eventGuid: guid }));
  }

  yield put(A.selectCloEventForTelSuccess({ event, telGuid, templateId, matchEvent }));
}

function* handleAddStopsFromMapToPlannerSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { returnObject } = payload;
    const { callback, newEvents } = returnObject;

    const telGuid = R.path(['selectedValue', 'value'], payload);

    if (G.isNotNilAndNotEmpty(newEvents)) {
      const guids = R.map(G.getGuidFromObject, newEvents);

      yield call(handleRefreshCloEventListWithRouteAndSelectedEvents, guids);

      const options = R.compose(
        R.uniq,
        R.map((stop: Object) => G.getPropFromObject(GC.FIELD_CLO_GUID, stop)),
      )(newEvents);

      const res = yield call(sendRequest, 'post', endpointsMap.cloEventListByCloGuids, { data: options });

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        yield all(newEvents.map((event: Object) => {
          const { guid, cloGuid } = event;

          const details = R.path([cloGuid], data);
          const cloName = R.prop(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, event);

          let matchEvent = R.compose(
            R.find(R.propEq(guid, GC.FIELD_GUID)),
            R.path([cloGuid]),
          )(data);

          matchEvent = R.mergeRight(matchEvent, {
            [CLO_NAME]: cloName,
            [GC.FIELD_LOAD_ITEMS]: R.map(
              R.assoc(CLO_NAME, cloName),
              R.prop(GC.FIELD_LOAD_ITEMS, matchEvent),
            ),
          });

          return call(handleAddMapCloEventToPlanner, { event, telGuid, details, matchEvent });
        }));

        yield put(A.recalculateTelDistancesRequest(telGuid));

        G.callFunction(callback);
      } else {
        yield call(G.handleFailResponse, res, 'handleAddStopsFromMapToPlannerSaga fail');
      }

      yield put(closeLoader());
    }
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleAddStopsFromMapToPlannerSaga exception');
  }
}

export function* cloEventsMapWatcherSaga() {
  yield takeLatest(A.getStopInfoRequest, getStopInfoRequest);
  yield takeLatest(A.addStopsFromMapToPlanner, handleAddStopsFromMapToPlannerSaga);
  yield takeLatest(A.getCloEventsListForMapRequest, handleGetCloEventListForMapSaga);
}
