import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setUsedReport,
  setFilterProps,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
} = getReportActions();

const setUsedReportOnly = createAction('setUsedReportOnly');
const deleteListItemRequest = createAction('deleteListItemRequest');
const downloadEdiFileRequest = createAction('downloadEdiFileRequest');
const receivedEDIListSuccess = createAction('receivedEDIListSuccess');
const updateCarrierInvoiceRequest = createAction('updateCarrierInvoiceRequest');
const updateCarrierInvoiceSuccess = createAction('updateCarrierInvoiceSuccess');
const assignEdiInvoiceToTripRequest = createAction('assignEdiInvoiceToTripRequest');

export {
  // report
  setReports,
  selectItem,
  setUsedReport,
  setFilterProps,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  // report
  setUsedReportOnly,
  deleteListItemRequest,
  downloadEdiFileRequest,
  receivedEDIListSuccess,
  updateCarrierInvoiceRequest,
  updateCarrierInvoiceSuccess,
  assignEdiInvoiceToTripRequest,
};
