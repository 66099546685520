import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { SelectLocation } from '../../../forms/forms/select-location';
import { LocationTemplateSearch } from '../../../forms/forms/template-search/components/location-template-search';
// icons
import * as I from '../../../svgs';
// ui
import { IconWrapper, AbsoluteBox } from '../../../ui';
// feature fleet-profile
import SimpleForm from './';
import { makeSelectEntityBranchGuid } from '../selectors';
////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  branchGuid: makeSelectEntityBranchGuid(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withState('initialValues', 'setInitialValues', R.prop('initialValues')),
  withHandlers({
    handleSearchLocation: (props: Object) => () => {
      const { openModal, branchGuid, closeModal, setInitialValues } = props;

      const searchTemplateRequest = (location: Object) => {
        const neededValues = R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR, location);

        closeModal();
        setInitialValues((prev: Object) => R.mergeRight(prev, neededValues));
      };

      const component = (
        <LocationTemplateSearch
          closeModal={closeModal}
          branchGuid={branchGuid}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(component);

      openModal(modal);
    },
    handleSelectLocation: (props: Object) => (event: Event) => {
      const { openModal, closeModal, initialValues, setInitialValues } = props;

      G.stopPropagation(event);

      const searchTemplateRequest = (location: Object) => {
        const needValues = R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR, location);

        closeModal();
        setInitialValues((prev: Object) => R.mergeRight(prev, needValues));
      };

      const component = (
        <SelectLocation
          closeModal={closeModal}
          initialValues={initialValues}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(component);

      openModal(modal);
    },
  }),
  pure,
);

const whiteColor = G.getTheme('colors.white');

const LastKnownLocationForm = (props: Object) => (
  <Fragment>
    <AbsoluteBox
      top='0'
      pl={20}
      left='0'
      height={30}
      width='100%'
      fontWeight='bold'
      color={whiteColor}
      bg={G.getTheme('colors.mainBlue')}
    >
      {G.getWindowLocale('titles:add-last-known-location', 'Add Last Known Location')}
      <IconWrapper ml={10} onClick={props.handleSearchLocation}>
        {I.search(whiteColor)}
      </IconWrapper>
      <IconWrapper ml={10} onClick={props.handleSelectLocation}>
        {I.location(whiteColor)}
      </IconWrapper>
    </AbsoluteBox>
    <SimpleForm {...props} />
  </Fragment>
);

export default enhance(LastKnownLocationForm);
