import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectDispatchGroupStore = ({ dispatchGroup }: Object) => dispatchGroup;

const makeSelectIndexedList = () => createSelector(
  selectDispatchGroupStore,
  ({ itemList }: Object) => itemList,
);

const makeSelectItemList = () => createSelector(
  selectDispatchGroupStore,
  ({ itemList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(itemList),
);

export {
  makeSelectItemList,
  makeSelectIndexedList,
};
