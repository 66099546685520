import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, StickedFlex } from '../../../ui';
// feature audit2
import { getBorderStyles } from '../helpers';
import {
  renderHeader,
  auditDetailsFieldSettings,
} from '../settings/field-settings';
/////////////////////////////////////////////////

const AuditDetails = ({ fields }: Object) => (
  <Box ml={115} maxWidth='calc(100% - 115px)' data-testid='testid-auditDetails'>
    <StickedFlex
      pr={30}
      top={69}
      height={32}
      backgroundColor={G.getTheme('colors.bgGrey')}
    >
      {renderHeader(auditDetailsFieldSettings)}
    </StickedFlex>
    {
      fields.map((item: Object, i: number, arr: Array) => (
        <Flex key={i}>
          {
            G.getNewArrayFromObjectWithHandling(
              (field: Object, key: string) => (
                <Flex
                  key={key}
                  height={32}
                  minWidth={field.minWidth}
                  width={R.or(field.width, '100%')}
                  borderBottom={getBorderStyles(arr, i)}
                >
                  <TextComponent
                    fontSize={14}
                    title={item[key]}
                    withEllipsis={true}
                  >
                    {item[key]}
                  </TextComponent>
                </Flex>
              ),
              auditDetailsFieldSettings,
            )
          }
          <Box
            height={32}
            minWidth={30}
            borderBottom={getBorderStyles(arr, i)}
          />
        </Flex>
      ))
    }
  </Box>
);

export default AuditDetails;
