import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
import { DownloadWithLabel } from '../../../components/download-with-label';
// features
import * as H from '../../../features/configurations/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_DISTANCE_UOM_OPTIONS,
  factoringIntegrationTypeOptions,
  DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  triumphFactoringPaymentTermTypeOptions,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, StyledLink } from '../../../ui';
// feature carrier
import * as LC from '../constants';
import { PrimaryWrapper } from '../ui';
import { downloadCarrierDocRequest } from '../actions';
//////////////////////////////////////////////////

const processIcons = {
  API: I.uiTrue,
  EDI: I.uiTrue,
  INTERNAL: I.uiTrue,
  noProcess: I.uiFalse,
  EMAIL: I.mailengIcon,
};

const getProcessIcon = (process: Object, param: string = 'type') => {
  let iconType = 'noProcess';

  if (G.isNotNil(process)) {
    iconType = R.or(R.prop(param, process), 'noProcess');
  }

  return processIcons[iconType]();
};

const setColumnValues = (values: Array = []) => (
  values.map((hmClass: string, i: number) => (
    <span key={i}>
      {
        G.ifElse(
          R.equals(values.length - 1, i),
          hmClass,
          `${hmClass}, `,
        )
      }
      <br />
    </span>
  ))
);

export const customColumn = {
  getProcessIcon: (entity: Object, param: string) => getProcessIcon(entity, param),
  getMultiBoolean: (entity: Object, param: string) => (
    <div>
      {entity[param].map((value: boolean, i: number) => (
        <span key={i}>
          {G.ifElse(G.notEquals(i, 0), ', ', '')}
          {H.convertDataTypeToUiTableField(value)}
        </span>
      ))}
    </div>
  ),
};

export const columnSettings = {
  [GC.FIELD_CARRIER_NAME]: {
    width: 200,
    name: 'titles:company-name',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_IGNORE_DEACTIVATION_RULES]: {
    width: 200,
    type: 'boolean',
    name: 'titles:ignore-activation-deactivation-rules',
  },
  [GC.FIELD_CARRIER_DBA]: {
    width: 200,
    name: 'titles:dba',
  },
  [GC.FIELD_CARRIER_ADDED_TO_WATCH_LIST]: {
    width: 150,
    type: 'boolean',
    name: 'titles:watch',
  },
  [GC.FIELD_CARRIER_ACTIVE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:status',
  },
  [GC.FIELD_NOTES]: {
    width: 200,
    name: 'titles:notes',
  },
  [GC.FIELD_CARRIER_ADDED_TO_NETWORK]: {
    width: 150,
    type: 'boolean',
    name: 'titles:added-to-network',
  },
  [GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW]: {
    width: 150,
    type: 'boolean',
    name: 'titles:allowed-book-it-now',
  },
  [`${GC.FIELD_CARRIER_DEACTIVATED_REASON}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 250,
    name: 'titles:status-reason',
  },
  [GC.FIELD_CARRIER_USDOT_NUMBER]: {
    width: 200,
    name: 'titles:usdot-number',
  },
  [GC.FIELD_CARRIER_INTERNAL_ID]: {
    width: 250,
    name: 'titles:internal-id',
  },
  [GC.FIELD_CARRIER_SCAC]: {
    width: 250,
    name: 'titles:scac',
  },
  [GC.FIELD_CARRIER_MC_NUMBER]: {
    width: 250,
    name: 'titles:mc-number',
  },
  [GC.FIELD_ACCOUNTING_EXTERNAL_ID]: {
    width: 250,
    name: 'titles:accounting-external-id',
  },
  [GC.FIELD_EMAIL]: {
    width: 300,
    name: 'titles:email',
    customComponent: ({ data }: Object) => {
      const text = G.getReportColumnPropTextFromData(GC.FIELD_EMAIL, data);
      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_PHONE_NUMBER]: {
    width: 200,
    name: 'titles:phone-number',
  },
  [GC.FIELD_CARRIER_INTRASTATE_DOT]: {
    width: 250,
    name: 'titles:intrastate-dot',
  },
  [GC.FIELD_CARRIER_INTRASTATE_STATE]: {
    width: 250,
    name: 'titles:intrastate-state',
  },
  [GC.FIELD_CARRIER_FEDERAL_EIN]: {
    width: 250,
    name: 'titles:federal-ein',
  },
  [GC.FIELD_CARRIER_DUNS_NUMBER]: {
    width: 250,
    name: 'titles:duns-number',
  },
  [GC.FIELD_CARRIER_URL]: {
    width: 300,
    name: 'titles:url',
  },
  [`${GC.FIELD_CARRIER_TRANSPORTATION_MODES}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 250,
    customLogic: true,
    name: 'titles:transportation-mode',
  },
  [`${GC.FIELD_CARRIER_EQUIPMENTS}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 250,
    customLogic: true,
    name: 'titles:equipment',
  },
  [`${LC.CARRIER_FINANCE_FILTER_PREFIX}.${GC.FIELD_CURRENCY}`]: {
    width: 200,
    name: 'titles:currency',
  },
  [`${LC.CARRIER_FINANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE}`]: {
    width: 200,
    name: 'titles:deduction-percentage',
  },
  [`${LC.BRANCH_FILTER_PREFIX}.${GC.FIELD_BRANCH_NAME}`]: {
    width: 250,
    name: 'titles:branch',
  },
  [`${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 250,
    customLogic: true,
    isRedirectLink: true,
    name: ['titles:insurance', 'titles:type'],
  },
  [`${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE}`]: {
    width: 250,
    customLogic: true,
    name: ['titles:insurance', 'titles:effective-date'],
  },
  [`${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE}`]: {
    width: 250,
    customLogic: true,
    name: ['titles:insurance', 'titles:expiration-date'],
  },
  [`${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT}`]: {
    width: 250,
    customLogic: true,
    name: ['titles:insurance', 'titles:coverage-amount'],
  },
  [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_CERTIFIED}`]: {
    width: 150,
    type: 'boolean',
    label: 'getMultiBoolean',
    name: 'titles:certificate-certified',
    customComponent: ({ data }: Object) => G.ifElse(
      R.any(
        (item: boolean) => item,
        R.pathOr([], [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_CERTIFIED}`], data),
      ),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT}`]: {
    width: 250,
    customLogic: true,
    name: 'titles:certificate-driver-count',
  },
  [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE}`]: {
    width: 250,
    customLogic: true,
    name: 'titles:certificate-effective-date',
  },
  [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE}`]: {
    width: 250,
    customLogic: true,
    name: 'titles:certificate-expiration-date',
  },
  [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_PERMIT}`]: {
    width: 150,
    type: 'boolean',
    label: 'getMultiBoolean',
    name: 'titles:certificate-permit',
    customComponent: ({ data }: Object) => G.ifElse(
      R.any(
        (item: boolean) => item,
        R.pathOr([], [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_PERMIT}`], data),
      ),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN}`]: {
    width: 150,
    type: 'boolean',
    label: 'getMultiBoolean',
    name: 'titles:certificate-safety-plan',
    customComponent: ({ data }: Object) => G.ifElse(
      R.any(
        (item: boolean) => item,
        R.pathOr([], [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN}`], data),
      ),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [`${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_CLASS}`]: {
    width: 250,
    customLogic: true,
    name: 'titles:certificate-classes',
  },
  [GC.FIELD_ADDRESS]: {
    width: 200,
    name: 'titles:address',
  },
  [GC.FIELD_ADDRESS_2]: {
    width: 200,
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 200,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 200,
    name: 'titles:state',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    name: 'titles:country',
  },
  [GC.FIELD_ZIP]: {
    width: 200,
    name: 'titles:zip',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_NAME}`]: {
    width: 250,
    name: 'titles:primary-terminal-name',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_ACTIVE}`]: {
    width: 150,
    type: 'boolean',
    name: 'titles:primary-terminal-status',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_ADDRESS}`]: {
    width: 200,
    name: 'titles:primary-terminal-address',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CITY}`]: {
    width: 200,
    name: 'titles:primary-terminal-city',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_STATE}`]: {
    width: 200,
    name: 'titles:primary-terminal-state',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_ZIP}`]: {
    width: 200,
    name: 'titles:primary-terminal-zip',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_COUNTRY}`]: {
    width: 200,
    name: 'titles:primary-terminal-country',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS}.type`]: {
    width: 200,
    label: 'getProcessIcon',
    name: 'titles:primary-terminal-dispatch-process',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS}.type`]: {
    width: 200,
    label: 'getProcessIcon',
    name: 'titles:primary-terminal-bit-process',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.type`]: {
    width: 200,
    label: 'getProcessIcon',
    name: 'titles:primary-terminal-status-check-process',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${LC.TERMINAL_FINANCE_FILTER_PREFIX}.${GC.FIELD_CURRENCY}`]: {
    width: 200,
    name: 'titles:primary-terminal-currency',
  },
  [`${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${LC.TERMINAL_FINANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE}`]: {
    width: 200,
    name: 'titles:primary-terminal-quick-pay-deduction-percentage',
  },
  [GC.GRC.INTEGRATION_INFO_INTEGRATION_TYPE]: {
    width: 300,
    type: 'collectionOfEnums',
    name: ['titles:integration-info', 'titles:integration-type'],
  },
  [GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME]: {
    width: 300,
    name: ['titles:integration-info', 'titles:division'],
  },
  [GC.GRC.INTEGRATION_INFO_LAST_MODIFIED_DATE]: {
    width: 300,
    type: 'date',
    name: ['titles:integration-info', 'titles:update-on'],
  },
  [GC.GRC.INTEGRATION_INFO_INTEGRATION_ID]: {
    width: 300,
    name: ['titles:integration-info', 'titles:integration-id'],
  },
  [GC.GRC.DOCUMENTS_TYPE]: {
    width: 250,
    name: ['titles:documents', 'titles:type'],
  },
  [GC.GRC.DOCUMENTS_DESCRIPTION]: {
    width: 250,
    name: ['titles:documents', 'titles:description'],
  },
  [GC.GRC.DOCUMENTS_SIGNET_DATE]: {
    width: 250,
    name: ['titles:documents', 'titles:signed-date'],
  },
  [GC.GRC.DOCUMENTS_EXPIRATION_DATE]: {
    width: 250,
    name: ['titles:documents', 'titles:expiration-date'],
  },
};

export const associatedCompanySettings = {
  [GC.FIELD_CARRIER_ASSOC_COMPANY_NAME]: {
    width: 250,
    name: 'titles:name',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTERNAL_ID]: {
    width: 250,
    name: 'titles:internal-id',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_SCAC]: {
    width: 250,
    name: 'titles:scac',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_USDOT_NUMBER]: {
    width: 250,
    name: 'titles:usdot-number',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_DOT]: {
    width: 250,
    name: 'titles:intrastate-dot',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_STATE]: {
    width: 250,
    name: 'titles:intrastate-state',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_DUNS_NUMBER]: {
    width: 250,
    name: 'titles:duns-number',
  },
  [GC.FIELD_CARRIER_ASSOC_COMPANY_URL]: {
    width: 300,
    name: 'titles:url',
    label: ({ url }: Object) => (
      <a href={G.makeURLString(url)} target='_blank'>{url}</a> // eslint-disable-line
    ),
  },
};

export const loadBoardIntegrationInfoSettings = () => ({
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.FIELD_CARRIER_ADDED_TO_NETWORK]: {
    width: 150,
    name: 'titles:added-to-network',
  },
  [GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW]: {
    width: 150,
    name: 'titles:allowed-book-it-now',
  },
  [GC.BRANCH_GUID]: {
    width: 200,
    name: 'titles:branch',
    customLogic: (value: string) => R.pathOr('', [GC.BRANCH_NAME], G.getBranchFromWindowByGuid(value)),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
    customLogic: (value: string) => {
      if (G.isNilOrEmpty(value)) return null;

      return G.createLocalDateTimeFromInstanceOrISOString(
        value,
        GC.DEFAULT_DATE_TIME_FORMAT,
      );
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    name: 'titles:updated-date',
    customLogic: (value: string) => {
      if (G.isNilOrEmpty(value)) return null;

      return G.createLocalDateTimeFromInstanceOrISOString(
        value,
        GC.DEFAULT_DATE_TIME_FORMAT,
      );
    },
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
});

export const synchronizationInfoSettings = {
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_STATUS]: {
    width: 200,
    name: 'titles:synchronization-status',
  },
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_ERROR_MESSAGE]: {
    width: 400,
    name: 'titles:error-message',
  },
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_USER_LOGIN]: {
    width: 200,
    name: 'titles:synchronization-by',
  },
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_SYNCHRONIZATION_DATE]: {
    width: 200,
    name: 'titles:synchronization-date',
  },
};

export const integrationAuditSettings = {
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
    customLogic: (integrationType: string = '') => R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
    )(integrationType),
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:status',
    customLogic: (value: string) => G.getEnumLocale(value),
  },
  [GC.FIELD_EXTERNAL_DIVISION_NAME]: {
    width: 250,
    name: 'titles:division',
  },
  [GC.FIELD_EXPORTED_BY]: {
    width: 200,
    name: 'titles:exported-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 250,
    name: 'titles:update-on',
    customLogic: G.convertDateTimeToConfigFormat,
  },
  [GC.FIELD_ERROR]: {
    width: 250,
    name: 'titles:error',
  },
};

export const integrationInfoSettings = {
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
    customLogic: (integrationType: string = '') => R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
    )(integrationType),
  },
  [GC.FIELD_INTEGRATION_ID]: {
    width: 200,
    name: 'titles:integration-id',
  },
  [GC.FIELD_EXTERNAL_DIVISION_NAME]: {
    width: 250,
    name: 'titles:division',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 250,
    name: 'titles:created-on',
    customLogic: G.convertDateTimeToConfigFormat,
  },
  [GC.FIELD_CREATED_BY]: {
    width: 250,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 250,
    name: 'titles:update-on',
    customLogic: G.convertDateTimeToConfigFormat,
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 250,
    name: 'titles:updated-by',
  },
};

const safetyTypeMap = {
  [GC.SAFETY_TYPE_MODERATE]: 'Moderate',
  [GC.SAFETY_TYPE_ACCEPTABLE]: 'Acceptable',
  [GC.SAFETY_TYPE_UNACCEPTABLE]: 'Unacceptable',
};

const safetyRatingTypeMap = {
  [GC.SAFETY_RATING_TYPE_NOT_RATED]: 'Not Rated',
  [GC.SAFETY_RATING_TYPE_CONDITIONAL]: 'Conditional',
  [GC.SAFETY_RATING_TYPE_SATISFACTORY]: 'Satisfactory',
  [GC.SAFETY_RATING_TYPE_UNSATISFACTORY]: 'Unsatisfactory',
};

const safetyAuthorityTypes = {
  [GC.SAFETY_AUTHORITY_TYPE_NONE]: 'None',
  [GC.SAFETY_AUTHORITY_TYPE_ACTIVE]: 'Active',
  [GC.SAFETY_AUTHORITY_TYPE_INACTIVE]: 'Inactive',
};

export const saferWatchSafetyInfoSettings = {
  [GC.CARRIER_SAFETY_OVERALL_RISK_INDICATOR]: {
    width: 250,
    name: 'titles:overall-carrier-risk-indicator',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_AUTHORITY]: {
    width: 200,
    name: 'titles:authority',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_INSURANCE]: {
    width: 200,
    name: 'titles:insurance',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_SAFETY]: {
    width: 200,
    name: 'titles:safety-csa',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_OPERATIONS]: {
    width: 200,
    name: 'titles:operations',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_SAFETY_RATING]: {
    width: 200,
    name: 'titles:carrier-safety-rating',
    customLogic: (value: Object) => R.prop(value, safetyRatingTypeMap),
  },
  [GC.CARRIER_SAFETY_UNSAFE_DRIVING]: {
    width: 200,
    name: 'titles:unsafe-drv',
  },
  [GC.CARRIER_SAFETY_DRIVER_FITNESS]: {
    width: 200,
    name: 'titles:drv-fitness',
  },
  [GC.CARRIER_SAFETY_VEHICLE_MAINT]: {
    width: 200,
    name: 'titles:vehicle-maint',
  },
  [GC.CARRIER_SAFETY_DRUG_ALCOHOL]: {
    width: 200,
    name: 'titles:drug-alcohol',
  },
  [GC.CARRIER_SAFETY_COMMON_AUTHORITY]: {
    width: 200,
    name: 'titles:common-authority',
    customLogic: (value: Object) => R.prop(value, safetyAuthorityTypes),
  },
  [GC.CARRIER_SAFETY_BROKER_AUTHORITY]: {
    width: 200,
    name: 'titles:broker-authority',
    customLogic: (value: Object) => R.prop(value, safetyAuthorityTypes),
  },
  [GC.CARRIER_SAFETY_NUMBER_OF_TRUCKS]: {
    width: 200,
    name: 'titles:number-of-trucks',
  },
  [GC.CARRIER_SAFETY_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
};

export const rmisSafetyInfoSettings = {
  [GC.CARRIER_SAFETY_COMMON_AUTHORITY]: {
    width: 200,
    name: 'titles:common-authority',
    customLogic: (value: Object) => R.prop(value, safetyAuthorityTypes),
  },
  [GC.CARRIER_SAFETY_BROKER_AUTHORITY]: {
    width: 200,
    name: 'titles:broker-authority',
    customLogic: (value: Object) => R.prop(value, safetyAuthorityTypes),
  },
  [GC.CARRIER_SAFETY_NUMBER_OF_TRUCKS]: {
    width: 200,
    name: 'titles:number-of-trucks',
  },
  [GC.CARRIER_SAFETY_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.CARRIER_SAFETY_US_VEHICLE_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-vehicle-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_DRIVER_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-driver-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_HAZMAT_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-hazmat-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_IEP_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-iep-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_VEHICLE_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-vehicle-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_US_DRIVER_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-driver-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_US_HAZMAT_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-hazmat-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_US_IEP_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-iep-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_CAN_VEHICLE_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:can-vehicle-oos-percentage',
  },
  [GC.CARRIER_SAFETY_CAN_DRIVER_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:can-driver-oos-percentage',
  },
};

export const docSettings = {
  [GC.FIELD_CARRIER_DOCUMENT_TYPE]: {
    name: 'titles:document-type',
    width: 150,
  },
  [GC.FIELD_CARRIER_DOCUMENT_DESCRIPTION]: {
    name: 'titles:description',
    width: 400,
  },
  [GC.FIELD_CARRIER_DOCUMENT_SIGNED_DATE]: {
    name: 'titles:signed-date',
    width: 150,
  },
  [GC.FIELD_CARRIER_DOCUMENT_EXPIRATION_DATE]: {
    name: 'titles:expiration-date',
    width: 150,
  },
  [GC.FIELD_CARRIER_DOCUMENT_FILE]: {
    name: 'titles:file',
    width: 300,
    label: (doc: Object) => (
      <DownloadWithLabel icon='pdf' downloadAction={downloadCarrierDocRequest} entity={doc} />
    ),
  },
  [GC.FIELD_CARRIER_DOCUMENT_URL]: {
    name: 'titles:url',
    width: 300,
    label: ({ url }: Object) => (
      <a href={G.makeURLString(url)} target='_blank'>{url}</a> // eslint-disable-line
    ),
  },
};

const blueColor = G.getTheme('colors.light.blue');

const EmailsComponent = ({ emails }: Object) => (
  <Flex flexWrap='wrap'>
    {
      emails.map((email: string, index: number) => (
        <StyledLink
          pl={0}
          p='5px'
          key={index}
          target='_blank'
          color={blueColor}
          wordBreak='break-all'
          href={`mailto:${email}`}
        >
          {email}{R.and(R.lt(R.inc(index), R.length(emails)), ',')}
        </StyledLink>
      ))
    }
  </Flex>
);

export const mailSettings = {
  [GC.FIELD_TO]: {
    width: 300,
    name: 'titles:mail-to',
    label: ({ to }: Object) => G.isNotNilAndNotEmpty(to) && <EmailsComponent emails={to} />,
  },
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT]: {
    width: 300,
    name: 'titles:subject',
  },
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO]: {
    width: 300,
    name: 'titles:reply-to',
  },
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO]: {
    width: 300,
    name: 'titles:copy-to',
    label: ({ copyTo }: Object) => G.isNotNilAndNotEmpty(copyTo) && <EmailsComponent emails={copyTo} />,
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
};

export const certificateSettings = {
  [GC.FIELD_CARRIER_CERTIFICATE_CERTIFIED]: {
    name: 'titles:certified',
    width: 150,
    label: ({ certified }: Object) => (
      G.ifElse(
        certified,
        I.uiTrue(),
        I.uiFalse(),
      )
    ),
  },
  [GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE]: {
    name: 'titles:effective-date',
    width: 200,
  },
  [GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE]: {
    name: 'titles:expiration-date',
    width: 200,
  },
  [GC.FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT]: {
    name: 'titles:driver-count',
    width: 150,
  },
  [GC.FIELD_CARRIER_CERTIFICATE_CLASS]: {
    name: 'titles:class',
    width: 300,
    label: ({ hazMatClasses }: Object) => (<div>{setColumnValues(hazMatClasses)}</div>),
  },
  [GC.FIELD_CARRIER_CERTIFICATE_PERMIT]: {
    name: 'titles:permit-hm232',
    width: 150,
    label: ({ hm232Permit }: Object) => (
      G.ifElse(
        hm232Permit,
        I.uiTrue(),
        I.uiFalse(),
      )
    ),
  },
  [GC.FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN]: {
    name: 'titles:safety-plan',
    width: 150,
    label: ({ safetyPlan }: Object) => (
      G.ifElse(
        safetyPlan,
        I.uiTrue(),
        I.uiFalse(),
      )
    ),
  },
};

export const terminalSettings = {
  [GC.FIELD_CARRIER_TERMINAL_PRIMARY]: {
    name: 'titles:primary',
    width: 100,
    label: ({ primary }: Object) => (
      <PrimaryWrapper>
        {
          G.ifElse(
            primary,
            G.getWindowLocale('titles:primary', 'Primary'),
            '',
          )
        }
      </PrimaryWrapper>
    ),
  },
  [GC.FIELD_CARRIER_TERMINAL_NAME]: {
    name: 'titles:name',
    width: 250,
  },
  [GC.FIELD_CARRIER_TERMINAL_ACTIVE]: {
    name: 'titles:status',
    width: 100,
  },
  [GC.FIELD_ADDRESS]: {
    name: 'titles:address',
    width: 250,
    label: ({ address }: Object) => (
      <div>{I.onMap(G.getTheme('colors.dark.blue'))} {address}</div>
    ),
  },
  [GC.FIELD_CITY]: {
    name: 'titles:city',
    width: 250,
  },
  [GC.FIELD_ZIP]: {
    name: 'titles:zip',
    width: 150,
  },
  [GC.FIELD_COUNTRY]: {
    name: 'titles:country',
    width: 200,
  },
  [GC.FIELD_LATITUDE]: {
    name: 'titles:latitude',
    width: 150,
  },
  [GC.FIELD_LONGITUDE]: {
    name: 'titles:longitude',
    width: 150,
  },
  [GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS]: {
    name: 'titles:bit-process',
    width: 150,
    label: ({ quoteProcess }: Object) => (
      <div>{getProcessIcon(quoteProcess)}</div>
    ),
  },
  [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]: {
    name: 'titles:status-check-process',
    width: 150,
    label: ({ statusCheckProcess }: Object) => (
      <div>{getProcessIcon(statusCheckProcess)}</div>
    ),
  },
  [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: {
    name: 'titles:dispatch-process',
    width: 150,
    label: ({ dispatchProcess }: Object) => (
      <div>{getProcessIcon(dispatchProcess)}</div>
    ),
  },
};

export const terminalContactSettings = {
  [GC.FIELD_CARRIER_TERMINAL_CONTACT_PRIMARY]: {
    name: 'titles:primary',
    width: 100,
    label: ({ primary }: Object) => (
      <PrimaryWrapper>
        {
          G.ifElse(
            primary,
            G.getWindowLocale('titles:primary', 'Primary'),
            '',
          )
        }
      </PrimaryWrapper>
    ),
  },
  [GC.FIELD_CONTACT_NAME]: {
    name: 'titles:contact-name',
    width: 200,
  },
  [GC.FIELD_EMAIL]: {
    name: 'titles:email',
    width: 200,
  },
  [GC.FIELD_PHONE]: {
    name: 'titles:primary-phone',
    width: 200,
  },
  [GC.FIELD_PHONE_EXTENSION]: {
    width: 150,
    name: 'titles:phone-extension',
  },
  [GC.FIELD_CARRIER_TERMINAL_CONTACT_SECONDARY_PHONE]: {
    name: 'titles:secondary-phone',
    width: 200,
  },
  [GC.FIELD_FAX]: {
    name: 'titles:fax',
    width: 200,
  },
};

export const financeLocationSettings = {
  [GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED]: {
    width: 100,
    name: 'titles:selected',
  },
  [GC.FIELD_LOCATION_NAME]: {
    width: 200,
    name: 'titles:pay-to-name',
  },
  [GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE]: {
    width: 200,
    name: 'titles:pay-type',
  },
  [GC.FIELD_ADDRESS]: {
    width: 200,
    name: 'titles:address',
  },
  [GC.FIELD_ADDRESS_2]: {
    width: 200,
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 200,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 200,
    name: 'titles:state',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    name: 'titles:country',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    name: 'titles:zip',
  },
  contactEmails: {
    width: 300,
    name: 'titles:emails',
  },
  [GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE]: {
    width: 200,
    name: 'titles:start-date',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
};

export const accessorialsSettings = {
  [GC.FIELD_DISPLAYED_VALUE]: {
    width: 150,
    name: 'titles:assessorial',
  },
  shared: {
    width: 150,
    name: 'titles:shared',
    customLogic: (shared: boolean = false) => G.ifElse(
      shared,
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [GC.FIELD_NON_TAXABLE]: {
    width: 100,
    name: 'titles:non-taxable',
  },
  [GC.FIELD_FUEL_RELATED]: {
    width: 100,
    name: 'titles:fuel-related',
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 100,
    name: 'titles:rate',
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 100,
    name: 'titles:rate-type',
    options: DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 100,
    name: 'titles:rate-unit',
    options: DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_OPTIONS,
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 110,
    name: 'titles:min-rate',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 110,
    name: 'titles:max-rate',
  },
  [GC.FIELD_MIN_STOPS]: {
    width: 110,
    name: 'titles:min-stops',
  },
  [GC.FIELD_MAX_STOPS]: {
    width: 110,
    name: 'titles:max-stops',
  },
  [GC.FIELD_ACCESSORIAL_DISTRICT]: {
    width: 150,
    name: 'titles:district',
    customLogic: (district: Object) => R.pathOr(
      district,
      [district],
      GC.districtMap,
    ),
  },
  [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: {
    width: 150,
    name: 'titles:use-fuel-index',
  },
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: {
    width: 150,
    name: 'titles:fuel-index-quantity',
    customLogic: (entity: Object) => R.length(entity),
  },
};

const getDisplayedValueFromOptions = (value: string, options: Array) => R.compose(
  R.pathOr('', [GC.FIELD_LABEL]),
  R.find(R.propEq(value, GC.FIELD_VALUE)),
)(options);

export const carrierPaymentTermsSettings = {
  [GC.FIELD_FACTORING_TYPE]: {
    width: 200,
    name: 'titles:factoring-type',
    customLogic: (value: string) => getDisplayedValueFromOptions(value, factoringIntegrationTypeOptions),
  },
  [GC.FIELD_FACTORING_PAYMENT_TERM]: {
    width: 200,
    name: 'titles:factoring-payment-term',
    customLogic: (value: string) => getDisplayedValueFromOptions(value, triumphFactoringPaymentTermTypeOptions),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

export const customerContractSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_ACTIVE]: {
    width: 200,
    name: 'titles:active',
  },
  [GC.FIELD_TRANSPORTATION_MODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:mode',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 200,
    name: 'titles:min-rate',
  },
  [GC.FIELD_EFFECTIVE_DATE]: {
    width: 200,
    name: 'titles:effective-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 200,
    name: 'titles:expiration-date',
  },
  [GC.FIELD_CARRIER_CONTRACT_ROLE_GUIDS]: {
    width: 200,
    collection: true,
    name: 'titles:roles',
    customLogic: (values: Array, { roleOptions }: Object) => {
      const title = R.compose(
        R.join(', '),
        R.map(R.prop('label')),
        R.filter(({ value }: Object) => values.includes(value)),
      )(roleOptions);
      return (
        <TextComponent title={title} display='block' maxWidth='100%' withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
};

export const carrierContractSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  // TODO: uncomment when be will be done
  // [GC.FIELD_VENDOR]: {
  //   width: 200,
  //   name: 'titles:vendor',
  //   customLogic: (value: string) => G.getEnumLocale(value),
  // },
  [GC.FIELD_ACTIVE]: {
    width: 200,
    name: 'titles:active',
  },
  [GC.FIELD_TRANSPORTATION_MODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:mode',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 200,
    name: 'titles:min-rate',
  },
  [GC.FIELD_EFFECTIVE_DATE]: {
    width: 200,
    name: 'titles:effective-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 200,
    name: 'titles:expiration-date',
  },
  [GC.FIELD_CARRIER_CONTRACT_ROLE_GUIDS]: {
    width: 200,
    collection: true,
    name: 'titles:roles',
    customLogic: (values: Array, { roleOptions }: Object) => {
      const title = R.compose(
        R.join(', '),
        R.map(R.prop('label')),
        R.filter(({ value }: Object) => values.includes(value)),
      )(roleOptions);
      return (
        <TextComponent title={title} display='block' maxWidth='100%' withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
};

export const carrierContractRateSettings = {
  [GC.FIELD_CARRIER_RATE_NAME]: {
    width: 100,
    name: 'titles:name',
  },
  [GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE]: {
    width: 100,
    name: 'titles:effective-date',
  },
  [GC.FIELD_CARRIER_RATE_EXPIRATION_DATE]: {
    width: 100,
    name: 'titles:expiration-date',
  },
  [GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID]: {
    width: 200,
    name: 'titles:origin-zone-name',
  },
  [GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID]: {
    width: 200,
    name: 'titles:destination-zone-name',
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_CITIES}`]: {
    name: 'titles:origin-cities',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_STATES}`]: {
    name: 'titles:origin-states',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_COUNTRIES}`]: {
    name: 'titles:origin-countries',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_CODES}`]: {
    name: 'titles:origin-zip-codes',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`]: {
    name: 'titles:origin-zip-from',
    width: 100,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`]: {
    name: 'titles:origin-zip-to',
    width: 100,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_CITIES}`]: {
    name: 'titles:destination-cities',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_STATES}`]: {
    name: 'titles:destination-states',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_COUNTRIES}`]: {
    name: 'titles:destination-countries',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_CODES}`]: {
    name: 'titles:destination-zip-codes',
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`]: {
    name: 'titles:destination-zip-from',
    width: 100,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`]: {
    name: 'titles:destination-zip-to',
    width: 100,
  },
  [GC.FIELD_CHARGE_RATE]: {
    name: 'titles:rate',
    width: 100,
  },
  [`${GC.FIELD_CARRIER_EQUIPMENTS}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 100,
    name: 'titles:equipments',
  },
  [`${GC.FIELD_CARRIER_SERVICE_TYPES}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 100,
    name: 'titles:service-type',
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    name: 'titles:rate-type',
    width: 100,
    options: DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    name: 'titles:rate-unit',
    width: 100,
    options: DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    name: 'titles:min-rate',
    width: 110,
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    name: 'titles:max-rate',
    width: 110,
  },
  [GC.FIELD_TRANSIT_DAYS]: {
    name: 'titles:transit-days',
    width: 100,
  },
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM]: {
    name: 'titles:distance-range-from',
    width: 100,
  },
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_TO]: {
    name: 'titles:distance-range-to',
    width: 100,
  },
  [GC.FIELD_CARRIER_RATE_DISTANCE_OUM]: {
    name: 'titles:distance-range-uom',
    width: 100,
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
  },
  [GC.FIELD_MIN_WEIGHT]: {
    width: 100,
    name: 'titles:min-weight',
  },
  [GC.FIELD_MAX_WEIGHT]: {
    width: 100,
    name: 'titles:max-weight',
  },
  [GC.FIELD_WEIGHT_UOM]: {
    width: 100,
    name: 'titles:weight-uom',
  },
  [GC.FIELD_MIN_TEMPERATURE]: {
    width: 100,
    name: 'titles:min-temperature',
  },
  [GC.FIELD_MAX_TEMPERATURE]: {
    width: 100,
    name: 'titles:max-temperature',
  },
  [GC.FIELD_TEMPERATURE_UOM]: {
    width: 100,
    name: 'titles:temperature-uom',
  },
  [GC.FIELD_MIN_QUANTITY]: {
    width: 100,
    name: 'titles:min-quantity',
  },
  [GC.FIELD_MAX_QUANTITY]: {
    width: 100,
    name: 'titles:max-quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 100,
    name: 'titles:package-type',
  },
};

export const contractDiscountSettings = {
  [GC.FIELD_CHARGE_RATE]: {
    name: 'titles:rate',
    width: 200,
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    name: 'titles:rate-unit',
    width: 200,
  },
  [LC.FIELD_VALUE_FROM]: {
    name: 'titles:value-from',
    width: 200,
  },
  [LC.FIELD_VALUE_TO]: {
    name: 'titles:value-to',
    width: 200,
  },
};

export const contractAwardedLineSettings = {
  [GC.FIELD_NAME]: {
    name: 'titles:name',
    width: 150,
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    name: 'titles:min-rate',
    width: 110,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_CITIES}`]: {
    name: 'titles:origin-cities',
    width: 150,
    collection: true,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_STATES}`]: {
    name: 'titles:origin-states',
    width: 150,
    collection: true,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_COUNTRIES}`]: {
    name: 'titles:origin-countries',
    width: 150,
    collection: true,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_CODES}`]: {
    name: 'titles:origin-zip-codes',
    collection: true,
    width: 150,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`]: {
    name: 'titles:origin-zip-from',
    width: 100,
  },
  [`${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`]: {
    name: 'titles:origin-zip-to',
    width: 100,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_CITIES}`]: {
    name: 'titles:destination-cities',
    width: 150,
    collection: true,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_STATES}`]: {
    name: 'titles:destination-states',
    width: 150,
    collection: true,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_COUNTRIES}`]: {
    name: 'titles:destination-countries',
    width: 150,
    collection: true,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_CODES}`]: {
    name: 'titles:destination-zip-codes',
    width: 150,
    collection: true,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`]: {
    name: 'titles:destination-zip-from',
    width: 100,
  },
  [`${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`]: {
    name: 'titles:destination-zip-to',
    width: 100,
  },
};

export const carrierPortalLoginColumnSettings = {
  [GC.FIELD_USER_LOGIN_ID]: {
    width: 400,
    name: 'titles:user-id',
  },
  [GC.FIELD_ROLE_ROLES]: {
    width: 400,
    name: 'titles:roles',
    customLogic: (data: Object) => R.and(G.isArray(data), R.join(', ', R.map(({ name }: Object) => name, data))),
  },
};

// additional contacts
export const additionalContactSettings = {
  [GC.FIELD_CONTACT_NAME]: {
    width: 200,
    name: 'titles:contact-name',
  },
  [GC.FIELD_CONTACT_TYPE]: {
    width: 200,
    name: 'titles:contact-type',
  },
  [GC.FIELD_TITLE]: {
    width: 200,
    name: 'titles:position',
  },
  [GC.FIELD_PHONE]: {
    width: 200,
    name: 'titles:phone',
  },
  [GC.FIELD_PHONE_EXTENSION]: {
    width: 150,
    name: 'titles:phone-extension',
  },
  [GC.FIELD_EMAIL]: {
    width: 200,
    name: 'titles:email',
  },
  [GC.FIELD_FAX]: {
    width: 300,
    name: 'titles:fax',
  },
};

export const contractBillToColumnSettings = {
  [GC.FIELD_TEMPLATE_ID]: {
    width: 200,
    name: 'titles:template-id',
  },
  [GC.FIELD_PAYMENT_TERM]: {
    width: 200,
    name: 'titles:payment-term',
  },
  [GC.FIELD_LOCATION_TYPE]: {
    width: 200,
    name: 'titles:location-type',
    customLogic: (entity: Object) => R.pathOr('', [GC.FIELD_DISPLAYED_VALUE], entity),
  },
  [GC.FIELD_LOCATION_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_ADDRESS_1]: {
    width: 200,
    name: 'titles:address1',
  },
  [GC.FIELD_ADDRESS_2]: {
    name: 'titles:address2',
    width: 200,
  },
  [GC.FIELD_CITY]: {
    name: 'titles:city',
    width: 200,
  },
  [GC.FIELD_STATE]: {
    width: 200,
    name: 'titles:state',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    name: 'titles:country',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    name: 'titles:zip',
  },
  [GC.FIELD_CONTACT_NAME]: {
    width: 200,
    name: 'titles:contact-name',
  },
  [GC.FIELD_PHONE]: {
    width: 200,
    name: 'titles:phone',
  },
  [GC.FIELD_PHONE_EXTENSION]: {
    width: 200,
    name: 'titles:phone-extension',
  },
  [GC.FIELD_EMAILS]: {
    width: 200,
    name: 'titles:emails',
  },
  [GC.FIELD_FAX]: {
    width: 200,
    name: 'titles:fax',
  },
  [GC.FIELD_COMMENTS]: {
    width: 600,
    name: 'titles:comments',
  },
};

export const carrierInternalDispatchColumnSettings = {
  [GC.FIELD_SHOWN_DOCUMENT_TYPE_GUIDS]: {
    width: 600,
    name: 'titles:shown-document-types',
    customLogic: (data: Object, { documentTypeOptions }: Object) => {
      const options = R.indexBy(R.prop('value'), documentTypeOptions);

      return R.and(G.isArray(data), R.join(', ', R.map((guid: string) => R.path([guid, 'label'], options), data)));
    },
  },
};
