// feature fleet-profile/driver
export { licenseSettings } from './license';
export { expenseSettings } from './expenses';
export { deductionSettings } from './deduction';
export { dailyHoursSettings } from './daily-hours';
export { citizenshipSettings } from './citizenship';
export { driverHoursSettings } from './driver-hours';
export { certificationSettings } from './certifications';
export { advancePaymentSettings } from './advance-payment';
export { generalDetailsSettings } from './general-details';
export { safetyViolationSettings } from './safety-violation';
export { unavailablePeriodSettings } from './unavailable-periods';
export { payrollCompensationSettings } from './payroll-compensation';
//////////////////////////////////////////////////
