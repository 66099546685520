// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    name: G.getWindowLocale('titles:branch', 'Branch'),
    value: `${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`,
  },
  {
    type: 'string',
    value: `${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`,
    name: G.getWindowLocale('titles:account-number', 'Account Number'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TEL_CLOS_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:branch', 'Branch')
    }`,
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL,
    name: G.getWindowLocale('titles:invoice-total', 'Invoice Total'),
  },
  {
    type: 'number',
    value: GC.FIELD_MAIN_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:linehaul', 'Linehaul'),
  },
  {
    type: 'number',
    value: GC.FIELD_DISCOUNT_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    type: 'number',
    value: GC.FIELD_FUEL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:fuel', 'Fuel'),
  },
  {
    type: 'number',
    value: GC.FIELD_ADDITIONAL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:other-accessorials', 'Other Accessorials'),
  },
  {
    type: 'number',
    value: GC.FIELD_BALANCE,
    name: G.getWindowLocale('titles:balance', 'Balance'),
  },
  {
    type: 'string',
    value: GC.FIELD_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'number',
    value: GC.GRC.NORMALIZED_TOTAL_TOTAL,
    name: G.getWindowLocale('titles:normalized-total', 'Normalized Total'),
  },
  {
    type: 'string',
    value: GC.GRC.NORMALIZED_TOTAL_CURRENCY,
    name: G.getWindowLocale('titles:normalized-currency', 'Normalized Currency'),
  },
  {
    type: 'string',
    value: GC.FIELD_TYPE,
    name: G.getWindowLocale('titles:type', 'Type'),
  },
  {
    type: 'string',
    value: GC.GRC.STATUS_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'string',
    value: GC.FIELD_COMMENTS,
    name: G.getWindowLocale('titles:comments', 'Comments'),
  },
  {
    type: 'string',
    value: GC.GRC.MODE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:mode', 'Mode'),
  },
  {
    type: 'string',
    value: GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  {
    type: 'number',
    value: GC.FIELD_SERVICE_DAYS,
    name: G.getWindowLocale('titles:service-days', 'Service Days'),
  },
  {
    type: 'date',
    value: GC.FIELD_INVOICE_PAYMENT_DUE_DATE,
    name: G.getWindowLocale('titles:payment-due-date', 'Payment Due Date'),
  },
  {
    type: 'number',
    value: GC.FIELD_INVOICE_NET_DAYS,
    name: G.getWindowLocale('titles:net-days', 'Net Days'),
  },
  {
    type: 'string',
    value: GC.FIELD_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:invoice-number', 'Invoice #'),
  },
  {
    type: 'string',
    value: GC.GRC.GL_CODE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:gl-code', 'GL Code'),
  },
  {
    type: 'date',
    value: GC.FIELD_INVOICE_DATE,
    name: G.getWindowLocale('titles:invoice-date', 'Invoice Date'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.PAYMENTS_DEPOSIT_DATE,
    name: G.getWindowLocale('titles:deposit-date', 'Deposit Date'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_NUMBER,
    name: G.getWindowLocale('titles:check-number', 'Check Number'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_DATE,
    name: G.getWindowLocale('titles:check-date', 'Check Date'),
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_AMOUNT,
    name: G.getWindowLocale('titles:check-amount', 'Check Amount'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_ROUTE_NAME,
    name: G.getWindowLocale('titles:route-name', 'Route Name'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_LOGIN_ID,
    name: G.getWindowLocale('titles:login-id', 'Login ID'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_FULL_NAME,
    name: G.getWindowLocale('titles:driver-full-name', 'Driver Full Name'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_FIRST_NAME,
    name: G.getWindowLocale('titles:driver-first-name', 'Driver First Name'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_LAST_NAME,
    name: G.getWindowLocale('titles:driver-last-name', 'Driver Last Name'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_TRIP_WEIGHT,
    name: G.getWindowLocale('titles:total-trip-weight', 'Total Trip Weight'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    name: G.getWindowLocale('titles:total-trip-weight-uom', 'Total Trip Weight UOM'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_TRIP_DISTANCE,
    name: G.getWindowLocale('titles:total-trip-distance', 'Total Trip Distance'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    name: G.getWindowLocale('titles:total-trip-distance-uom', 'Total Trip Distance UOM'),
  },
  {
    type: 'number',
    value: GC.GRC.TEL_TOTAL_INCOME,
    name: G.getWindowLocale('titles:total-trip-income', 'Total Trip Income'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_STATUS,
    name: G.getWindowLocale('titles:integration-status', 'Integration Status'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_TYPE,
    name: G.getWindowLocale('titles:integration-type', 'Integration Type'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:integrated-by', 'Integrated By'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER,
    name: G.getWindowLocale('titles:ach-payment-confirmation', 'ACH Payment Confirmation'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_PAYROLL_NUMBER,
    name: G.getWindowLocale('titles:payroll-number', 'Payroll #'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_FLEET_VENDOR_NAME,
    name: G.getWindowLocale('titles:vendor-name', 'Vendor Name'),
  },
];
