import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const TableCell = ({ text, options }: Object) => {
  if (G.isNilOrEmpty(text)) return null;

  let displayedValue = text;

  if (G.isNotNilAndNotEmpty(options)) {
    if (G.isArray(text)) {
      displayedValue = R.compose(
        R.join(', '),
        R.filter(G.isNotNilAndNotEmpty),
        R.map((item: string) => R.prop(GC.FIELD_LABEL, R.find(R.propEq(item, GC.FIELD_VALUE), options))),
      )(text);
    } else {
      displayedValue = R.compose(
        R.prop(GC.FIELD_LABEL),
        R.find(R.propEq(text, GC.FIELD_VALUE)),
      )(options);
    }
  }

  return (
    <TextComponent
      maxWidth='90%'
      display='block'
      withEllipsis={true}
      title={displayedValue}
    >
      {displayedValue}
    </TextComponent>
  );
};
