import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { Tabs2 } from '../../../components/tabs-mui';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import { startPivotTable } from '../../pivot-table/actions';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectAllAvailableClosAndTelsReferenceTypes } from '../../reference/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// report-common
import { reportEnhancer } from '../../../report-common';
// icons
import * as I from '../../../svgs';
// ui
import {
  ListWrapper,
  IconWrapper,
  ZOrderWrapper,
} from '../../../ui';
// feature route list
import RowActions from './components/row-actions';
// feature dispatch-report
import { getTabs } from './helpers';
import { tableSettings } from '../settings/table-settings';
import { routeFilterProps } from '../settings/filter-params';
import { routeColumnSettings } from '../settings/column-settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  startRouteByLoad,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withPromptModal(routeFilterProps),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, { guid }: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            routeGuid={guid}
            openModal={props.openModal}
            closeFixedPopup={props.closeFixedPopup}
            getItemListRequest={props.getItemListRequest}
            resetListAndPagination={props.resetListAndPagination}
          />
        ),
      })
    ),
  }),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const rowActions = (route: Object) => (
    <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, route)}>
      {I.threeDots()}
    </IconWrapper>
  );

  const hasPinned = G.hasPinnedReports(reportList);
  const hasFilters = G.hasSearchCriteria(selectedReport, filterParams);

  let maxHeight = 'calc(100vh - 75px)';

  if (R.and(hasPinned, hasFilters)) {
    maxHeight = 'calc(100vh - 145px)';
  } else if (R.or(hasPinned, hasFilters)) {
    maxHeight = 'calc(100vh - 110px)';
  }

  const routeTableSettings = R.mergeRight(
    tableSettings,
    {
      maxHeight,
      allowSelectAll: false,
      checkBoxCellWidth: '0',
      allowSelectItems: false,
    },
  );

  const data = {
    loading,
    itemList,
    totalCount,
    pagination,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: false,
    handleTableTitleFilter,
    report: selectedReport,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: routeTableSettings,
    columnSettings: routeColumnSettings,
    renderRightStickedComponent: rowActions,
    handleLoadMoreEntities: getItemListRequest,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(routeFilterProps),
    ),
  };

  return <Table {...data} />;
};

export const RouteListComponent = (props: Object) => {
  const { totalCount, startPivotTable, startRouteByLoad, handleSetActiveTab } = props;

  const handleClickTab = (tabIndex: number) => {
    if (R.equals(tabIndex, 1)) startRouteByLoad();

    if (R.equals(tabIndex, 2)) startPivotTable(GC.PIVOT_ROUTE_BY_LOAD_REPORT);

    handleSetActiveTab(tabIndex);
  };

  const customTitleComponent = (
    <Tabs2
      activeTab={0}
      tabs={getTabs(0)}
      count={totalCount}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          withCount={true}
          type={GC.ROUTE_REPORT}
          popperWithCount={true}
          exportPermissions={null}
          hiddenRightFilterInfo={false}
          filterProps={routeFilterProps}
          customTitleComponent={customTitleComponent}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  refList: makeSelectAllAvailableClosAndTelsReferenceTypes(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  setUsedReport,
  startPivotTable,
  cleanQuickFilter,
  startRouteByLoad,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
})(enhance(RouteListComponent));
