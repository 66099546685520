import React from 'react';
import * as R from 'ramda';
// components
import { Label } from '../../../components/label';
// forms
// import { StyledReactSelect } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ReactSelect } from '../../../ui';
// feature fleet
import { GROUP_NAME_DOCUMENTS } from '../constants';
//////////////////////////////////////////////////

export const DocumentsTitlePanel = (props: Object) => {
  const {
    handleAddDoc,
    filterOptions,
    collapsedGroup,
    documentsFilter,
    setDocumentsFilter,
    handleToggleFormGroup,
  } = props;

  const whiteColor = G.getTheme('colors.white');
  const isOpened = G.getPropFromObject(GROUP_NAME_DOCUMENTS, collapsedGroup);

  return (
    <Flex
      pl={15}
      pr={16}
      minHeight={30}
      color={whiteColor}
      bg={G.getTheme('colors.mainBlue')}
    >
      <Flex width='max-content'>
        <Label endAction={handleAddDoc} endIcon={I.plusRound(whiteColor)}>
          {G.getWindowLocale('titles:documents', 'Documents')}
        </Label>
        {
          isOpened &&
          <ReactSelect
            height={30}
            value={documentsFilter}
            options={R.drop(1, R.or(filterOptions, []))}
            onChange={(option: Object) => setDocumentsFilter(R.pathOr(null, [GC.FIELD_VALUE], option))}
            additionalStyles={{
              container: (baseStyles: Object) => ({
                ...baseStyles,
                width: 230,
                margin: '5px 10px',
              }),
              menu: (baseStyles: Object) => ({
                ...baseStyles,
                zIndex: 11,
                color: G.getTheme('colors.black'),
              }),
            }}
          />
        }
      </Flex>
      <Box
        width='100%'
        onClick={() => handleToggleFormGroup(GROUP_NAME_DOCUMENTS)}
      >
        <Box ml='auto' width='max-content' transform={G.ifElse(isOpened, 'rotate(180deg)')}>
          {I.arrowDownSimple(whiteColor)}
        </Box>
      </Box>
    </Flex>
  );
};
