import React from 'react';
// components
import DrawingMap from '../../../components/drawing-map';
// ui
import { Box } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const mockLocations = [
  {
    'selected': false,
    'eventType': 'PICKUP',
    'location.latitude': 40.7643007,
    'location.longitude': -73.9542797,
    'clo.primaryReference.value': 'FOR CLO15',
    'lastTerminalDrop.location.templateId': null,
    'guid': '78cd6ddf-b154-48ce-892b-33f42e8667c2',
    'cloGuid': '11111111-1111-1111-1111-111111111111',
    'latLng': { 'lat': 40.7643007, 'lng': -73.9542797 },
    'clo.enterprise.guid': '00000000-0000-0000-0000-000000000000',
  },
];

const DrawingMapComponent = () => {
  const description = '<DrawingMap /> component is designed to display a map on a web application page.';

  const usage = (
    <ul>
      <li>Parameter `<i>height</i>` sets the height of the map.</li>
      <li>Parameter `<i>locations</i>` is an array of places or locations that can be displayed on the map.</li>
      <li>Parameter `<i>mapSource</i>` specifies the source of the map, which can be `googlemaps` or `leaflet`. `Leaflet` is by default.</li>
      <li>Parameter `<i>handleCompleteSelection</i>`  is a function that is called upon completing the selection of locations on the map.</li>
    </ul>
  );

  const handleAddStopsToPlanner = (data: Object) => window.alert(JSON.stringify(data));

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='DrawingMap'
      description={description}
      path='src/components/drawing-map'
    >
      <Box width={500}>
        <DrawingMap
          height='400px'
          // TODO: Google Maps doesn't work now
          // mapSource='googlemaps'
          locations={mockLocations}
          handleCompleteSelection={handleAddStopsToPlanner}
        />
      </Box>
    </ComponentStoryWrapper>
  );
};

export default DrawingMapComponent;
