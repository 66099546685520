import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature dispatch-group
import ItemForm from './item-form';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleEditItem: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        updateAction,
        initialValues,
        closeFixedPopup,
      } = props;

      if (G.isFunction(closeFixedPopup)) closeFixedPopup();

      const { drivers, dispatchers } = initialValues;
      const driverGuids = R.map(R.prop(GC.FIELD_GUID), drivers);
      const dispatcherGuids = R.map(R.prop(GC.FIELD_GUID), dispatchers);

      const component = (
        <ItemForm
          closeModal={closeModal}
          submitAction={updateAction}
          initialValues={{
            driverGuids,
            dispatcherGuids,
            ...initialValues,
          }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 740,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:dispatch-group', 'Dispatch Group'),
        },
      };

      openModal(modal);
    },
    handleDeleteItem: (props: Object) => () => {
      const { openModal, initialValues, closeFixedPopup, deleteAction } = props;
      const { guid, name } = initialValues;

      if (G.isFunction(closeFixedPopup)) closeFixedPopup();

      const textLocale = G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this');

      const component = <ConfirmComponent name={name} textLocale={textLocale} />;
      const modal = {
        component,
        options: {
          width: 720,
          controlButtons: [
            {
              type: 'button',
              action: () => deleteAction(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const getOptions = (handleEditItem: Function, handleDeleteItem: Function) => [
  {
    action: handleEditItem,
    text: G.getWindowLocale('titles:edit', 'Edit'),
    frontIcon: I.renderEditIcon(G.getTheme('icons.iconColor')),
  },
  {
    frontIcon: I.trash(),
    action: handleDeleteItem,
    text: G.getWindowLocale('titles:delete', 'Delete'),
  },
];

const RowActions = (props: Object) => {
  const { handleEditItem, handleDeleteItem } = props;

  return <ActionsElement options={getOptions(handleEditItem, handleDeleteItem)} />;
};

export default enhance(RowActions);
