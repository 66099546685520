import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, withState, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { notificationLevelOptions } from '../../../../../helpers/options';
// forms
import { Fieldset2 } from '../../../../../forms';
// ui
import { Box, scrollableContainerCss3px } from '../../../../../ui';
// utilities
import { sendRequest } from '../../../../../utilities/http';
import endpointsMap from '../../../../../utilities/endpoints';
// features
import { makeSelectRoleAvailableList } from '../../../../role/selectors';
import { makeSelectCurrentBranchGuid } from '../../../../branch/selectors';
// feature configs
import { makeSelectNotificationTriggers } from '../../../selectors';
import {
  getOptions,
  getDataTriggers,
  getValidationSchema,
  getEmailTypesOptions,
  defaultNotificationValues,
  getConditionValuesOptions,
  getFirstNotificationFieldset,
  getSecondNotificationFieldset,
  notificationMessageTypesOptions,
  getIsTriggerConditionTypeNumberField,
} from '../../../settings/notification-group';
// communication
import { withAsyncConditionValues } from '../hocs/with-async-condition-type';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncConditionValues,
  withFormik({
    validationSchema: ({ notificationOptions }: Object) => Yup.lazy((values: Object) => (
      Yup.object().shape(getValidationSchema(values, notificationOptions))
    )),
    enableReinitialize: true,
    mapPropsToValues: ({ name, initialValues }: Object) => {
      let initialValuesToUse = initialValues;

      if (R.equals(name, 'edit')) {
        const { value, trigger, operation } = initialValues;

        if (R.and(
          R.equals(operation, GC.FIELD_OPERATION_IN),
          G.isFalse(getIsTriggerConditionTypeNumberField(trigger)),
        )) {
          initialValuesToUse = R.assoc(
            [GC.FIELD_VALUE],
            R.split(',', value),
            initialValues,
          );
        }
      }

      return G.setInitialFormikValues(
        defaultNotificationValues,
        initialValuesToUse,
      );
    },
    handleSubmit: (values: Object, { props }: Object) => {
      const { branchGuid, submitActions } = props;

      const {
        value,
        roleGuids,
        emailTypes,
        staticEmails,
        messageTypes,
        mailTemplateGuid,
      } = values;

      let valueToUse = value;

      if (G.isArray(value)) {
        valueToUse = R.join(',', value);
      }

      let staticEmailsToUse = staticEmails;

      if (R.or(
        G.isNilOrEmpty(emailTypes),
        G.notContain(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES_STATIC, emailTypes),
      )) {
        staticEmailsToUse = [];
      }

      let mailTemplateGuidToUse = mailTemplateGuid;

      if (G.notContain(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_EMAIL, messageTypes)) {
        mailTemplateGuidToUse = '';
      }

      const data = R.mergeRight(
        values,
        {
          [GC.FIELD_VALUE]: valueToUse,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
          [GC.FIELD_ROLE_GUIDS]: R.or(roleGuids, []),
          [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_STATIC_EMAILS]: staticEmailsToUse,
          [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID]: mailTemplateGuidToUse,
        },
      );

      submitActions(data);
    },
  }),
  withState('roles', 'setRoles', null),
  withState('groups', 'setGroup', null),
  withState('objType', 'setObjType', null),
  withState('triggers', 'setTriggers', []),
  withState('condition', 'setCondition', null),
  withState('condValues', 'setCondValues', null),
  withState('condOperation', 'setOperation', null),
  withState('mailTemplates', 'setMailTemplates', []),
  withHandlers({
    handleGetAvailableMailTemplates: (props: Object) => async (objType: string) => {
      const { values, branchGuid, setMailTemplates, notificationOptions } = props;

      const triggerName = G.getPropFromObject(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER, values);

      const triggerMailTemplateTypes = R.compose(
        R.keys,
        R.indexBy(R.prop(GC.FIELD_NAME)),
        R.filter(R.propEq(objType, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE)),
        R.pathOr([], ['0', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_TYPES]),
        R.filter(({ trigger }: Object) => R.equals(trigger, triggerName)),
      )(notificationOptions);

      if (G.isNilOrEmpty(triggerMailTemplateTypes)) return setMailTemplates([]);

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
          types: R.join(',', triggerMailTemplateTypes),
        },
      };

      const res = await sendRequest('get', endpointsMap.getAvailableMailTemplates, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setMailTemplates(data);
      } else {
        G.handleException('error', 'handleGetAvailableMailTemplates exception');
      }
    },
  }),
  withPropsOnChange(
    ['notificationOptions', 'conditionValues'],
    (props: Object) => {
      const {
        name,
        values,
        setRoles,
        rolesList,
        setTriggers,
        setOperation,
        setCondValues,
        conditionValues,
        notificationOptions,
        postConditionValues,
        handleGetAvailableMailTemplates,
      } = props;

      const { trigger, objectType, conditionType } = values;

      const rolesOptions = R.values(G.mapNameGuidObjectPropsToLabelValueObject(rolesList));

      const nameTriggers = R.map(
        ({ group, trigger }: Object) => {
          if (R.equals(G.getPropFromObject('group', values), group)) {
            return { label: trigger, value: trigger };
          }

          return '';
        },
        notificationOptions,
      );

      if (R.and(R.equals(name, 'edit'), G.isNilOrEmpty(conditionValues))) {
        postConditionValues(props, conditionType);
      }

      if (G.isNotNilAndNotEmpty(conditionValues)) {
        const operations = R.map(
          (item: Object) => ({ value: item, label: G.getEnumLocale(item) }),
          G.getPropFromObject('operations', conditionValues),
        );

        const condKeys = R.keys(G.getPropFromObject('values', conditionValues));

        let values = R.map(
          (item: Object) => ({ label: R.path(['values', item], conditionValues), value: item }),
          condKeys,
        );

        if (G.isTrue(G.getPropFromObject('localized', conditionValues))) {
          values = R.map((item: string) => ({ label: item, value: item }), condKeys);
        }

        setOperation(operations);
        setCondValues(values);
      }

      if (G.isNotNilAndNotEmpty(objectType)) {
        handleGetAvailableMailTemplates(objectType);
      }

      setRoles(rolesOptions);
      setTriggers(nameTriggers);
      getDataTriggers(props, trigger);

      return '';
    },
  ),
  withHandlers({
    handleCustomChange: (props: Object) => (fieldValue: Object, fieldName: Object) => {
      const {
        values,
        setValues,
        setObjType,
        setCondition,
        setOperation,
        setCondValues,
        setFieldValue,
        setMailTemplates,
        postConditionValues,
        handleGetAvailableMailTemplates,
      } = props;

      const { trigger, objectType } = values;

      const newValues = {
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE]: null,
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL]: null,
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE]: null,
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES]: [],
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES]: [],
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE]: null,
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID]: null,
        [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION]: null,
      };

      const resetValues = R.mergeRight(values);

      if (R.equals(fieldName, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER)) {
        if (G.isNilOrEmpty(fieldValue)) {
          setObjType(null);
          setCondition(null);
          setCondValues(null);
        }

        setMailTemplates([]);
        getDataTriggers(props, fieldValue);

        if (R.and(G.isNotNilAndNotEmpty(trigger), G.notEquals(fieldValue, trigger))) {
          setValues(R.mergeRight(values, newValues));
        }
      }

      if (R.equals(fieldName, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE)) {
        postConditionValues(props, fieldValue);

        setValues(resetValues({
          [GC.FIELD_OPERATION]: null,
          [GC.FIELD_VALUE]: G.ifElse(getIsTriggerConditionTypeNumberField(trigger), '', null),
        }));

        setOperation(null);
        setCondValues(null);
      }

      if (R.equals(fieldName, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION)) {
        setValues(R.assoc(
          GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE,
          null,
          values,
        ));
      }

      if (R.equals(fieldName, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES)) {
        setValues(R.assoc(
          GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES,
          [],
          values,
        ));
      }

      if (R.and(
        R.equals(fieldName, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE),
        G.notEquals(fieldValue, objectType),
      )) {
        setValues(resetValues({
          [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES]: [],
          [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID]: null,
        }));

        handleGetAvailableMailTemplates(fieldValue);
      }

      setFieldValue(fieldName, fieldValue);
    },
  }),
  pure,
);

const mapObjectTypeOptions = (types: any) => R.map((item: string) => {
  if (G.isString(item)) {
    return G.getEnumLocale(item);
  }

  if (G.isObject(item)) {
    return R.assoc(GC.FIELD_LABEL, G.getEnumLocale(G.getPropFromObject(GC.FIELD_LABEL, item)), item);
  }

  return item;
}, R.or(types, []));

const getOptionsForSelect = (props: Object) => {
  const {
    roles,
    values,
    objType,
    triggers,
    condition,
    condValues,
    objectType,
    condOperation,
    mailTemplates,
    cloReferenceTypes,
    telReferenceTypes,
    notificationOptions,
  } = props;

  const { trigger, conditionType } = values;

  return ({
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ROLE]: roles,
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER]: getOptions(triggers),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL]: notificationLevelOptions(),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE]: mapObjectTypeOptions(objType),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION]: R.or(condOperation, []),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES]: notificationMessageTypesOptions,
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE]: getOptions(R.or(condition, [])),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID]: G.mapNameGuidToLabelValue(mailTemplates),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES]: getEmailTypesOptions(notificationOptions, trigger),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE]: getConditionValuesOptions(
      condValues,
      trigger,
      conditionType,
    ),
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES]: G.ifElse(
      R.equals(objectType, GC.NOTIFICATION_OBJECT_TYPE_TEL),
      telReferenceTypes,
      cloReferenceTypes,
    ),
  });
};

const fieldsWrapperStyles = { justifyContent: 'space-between' };

const NotificationForm = (props: Object) => {
  const { values, handleSubmit, handleCustomChange } = props;

  const optionsForSelect = getOptionsForSelect(props);

  const commonProps = {
    ...optionsForSelect,
    ...G.getFormikProps(props),
    handleCustomChange,
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        maxHeight='65vh'
        overflowY='auto'
        id='form_wrapper'
        p='15px 15px 0 15px'
        css={scrollableContainerCss3px}
      >
        <Fieldset2
          {...commonProps}
          fieldsWrapperStyles={{ ...fieldsWrapperStyles, mt: 15 }}
          fields={getFirstNotificationFieldset(values, optionsForSelect)}
        />
        <Fieldset2
          {...commonProps}
          fieldsWrapperStyles={fieldsWrapperStyles}
          fields={getSecondNotificationFieldset(props, optionsForSelect)}
        />
      </Box>
      <FormFooter2 boxStyles={{ p: '0 15px 15px' }} />
    </form>
  );
};

const mapStateToProps = (state: Object) => ({
  rolesList: makeSelectRoleAvailableList()(state),
  branchGuid: makeSelectCurrentBranchGuid()(state),
  notificationOptions: makeSelectNotificationTriggers()(state),
});

export default connect(mapStateToProps)(enhance(NotificationForm));
