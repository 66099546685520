import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { CancelSaveFooter } from '../../../components/form-footer';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, ReactSelect } from '../../../ui';
//////////////////////////////////////////////////

const defaultOptions = [
  G.createLabelValueOptionObject(
    G.getWindowLocale('titles:qb-override-url-references', 'QB Override Url References'),
    GC.OPTIONAL_QB_OVERRIDE_URL_REFERENCES,
  ),
  G.createLabelValueOptionObject(
    G.getWindowLocale('titles:jbhunt-calculator-enabled', 'JB Hunt Calculator Enabled'),
    GC.OPTIONAL_GBHUNT_CALCULATOR_ENABLED,
  ),
  G.createLabelValueOptionObject(
    G.getWindowLocale('titles:jbhunt-calculator-use-on-tel', 'JB Hunt Calculator Use On TEL'),
    GC.OPTIONAL_GBHUNT_CALCULATOR_USE_ON_TEL,
  ),
  G.createLabelValueOptionObject(
    G.getWindowLocale('titles:qb-confirmation-reference', 'QB Confirmation Reference'),
    GC.OPTIONAL_QB_OVERRIDE_URL_CONFIRMATION_REFERENCE,
  ),
];

const getOptions = (props: Object) => {
  const { mode, initialValues, inheritedValues } = props;
  const func = G.ifElse(R.equals(mode, 'add'), R.filter, R.reject);

  return func(
    ({ value }: Object) => G.checkIsConfigNotExisted(value, initialValues, inheritedValues),
    defaultOptions,
  );
};

const getRequestData = (props: Object) => {
  const { configName } = props;
  const defaultValueMap = {
    [GC.OPTIONAL_GBHUNT_CALCULATOR_ENABLED]: true,
    [GC.OPTIONAL_QB_OVERRIDE_URL_REFERENCES]: null,
    [GC.OPTIONAL_GBHUNT_CALCULATOR_USE_ON_TEL]: true,
    [GC.OPTIONAL_QB_OVERRIDE_URL_CONFIRMATION_REFERENCE]: null,
  };
  const value = R.path([configName], defaultValueMap);

  return { configs: [{ value, name: configName }] };
};

const withChangeHandler = compose(
  withState('configName', 'setConfigName', null),
  withHandlers({
    handleChangeConfig: (props: Object) => (data: any) => {
      const { setConfigName } = props;
      const value = R.path(['value'], data);
      setConfigName(value);
    },
    handleSubmitAction: (props: Object) => () => {
      const { mode, closeModal, configName, submitRequestAction } = props;
      closeModal();
      if (R.equals(mode, 'add')) return submitRequestAction(getRequestData(props));
      return submitRequestAction(configName);
    },
  }),
);

const ConfigNameSelect = withChangeHandler((props: Object) => {
  const { closeModal, handleSubmitAction, handleChangeConfig } = props;

  return (
    <Box>
      <ReactSelect
        isMulti={false}
        options={getOptions(props)}
        onChange={handleChangeConfig}
        placeholder={G.getWindowLocale('titles:config-name', 'Config Name')}
      />
      <CancelSaveFooter
        closeModal={closeModal}
        boxStyles={{ mt: '10px' }}
        submitAction={handleSubmitAction}
        saveBtnText={G.getWindowLocale('actions:add', 'Add')} />
    </Box>
  );
});

const withOptionalConfig = (mode: string) => compose(
  connect(
    null,
    { openModal, closeModal },
  ),
  withHandlers({
    handleClickOptionalConfig: (props: Object) => () => {
      const { openModal } = props;
      const modal = {
        p: 15,
        component: (
          <ConfigNameSelect {...props} mode={mode} />
        ),
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:optional-config', 'Optional Config'),
        },
      };
      openModal(modal);
    },
  }),
);

export const AddOptionalConfig = withOptionalConfig('add')((props: Object) => (
  <Box
    m={10}
    cursor='pointer'
    color={G.getTheme('colors.dark.blue')}
    onClick={props.handleClickOptionalConfig}
  >
    {G.getWindowLocale('titles:add-optional-config', 'Add Optional Config')}
  </Box>
));

export const RemoveOptionalConfig = withOptionalConfig('remove')((props: Object) => (
  <Box
    m={10}
    cursor='pointer'
    color={G.getTheme('colors.dark.blue')}
    onClick={props.handleClickOptionalConfig}
  >
    {G.getWindowLocale('titles:remove-optional-config', 'Remove Optional Config')}
  </Box>
));
