import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectDriverProfileSimpleStore = (state: Object) => state.driverProfileSimple;

const makeSelectOpenedDriverProfile = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ openedDriverProfile }: Object) => openedDriverProfile,
);

const makeSelectDriverProfileGuid = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverProfileGuid }: Object) => driverProfileGuid,
);

const makeSelectDriverGroupGuid = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverGroupGuid }: Object) => driverGroupGuid,
);

const makeSelectDriverPendingActivities = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverPendingActivities }: Object) => driverPendingActivities,
);

const makeSelectDriverUnavailablePeriods = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverUnavailablePeriods }: Object) => driverUnavailablePeriods,
);

const makeSelectDriverProfile = () => createSelector(
  selectDriverProfileSimpleStore,
  (state: Object) => {
    const { driverProfiles, driverProfileGuid } = state;

    const driverProfile = R.path([driverProfileGuid], driverProfiles);

    return driverProfile;
  },
);

const makeSelectDriverAvailabilityStatus = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverAvailabilityStatus }: Object) => driverAvailabilityStatus,
);

export {
  makeSelectDriverProfile,
  makeSelectDriverGroupGuid,
  makeSelectDriverProfileGuid,
  makeSelectOpenedDriverProfile,
  makeSelectDriverPendingActivities,
  makeSelectDriverUnavailablePeriods,
  makeSelectDriverAvailabilityStatus,
};
