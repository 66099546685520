import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../components/text';
import { FormFooter2 } from '../../components/form-footer';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { Fieldset } from '../new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { dayOptions } from '../../helpers/options';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
// feature business-hours
import daysMapSimple from './days-map-simple';
import { validationSchema, businessHoursFieldSettings, defaultBusinessHoursFields } from './field-settings';
//////////////////////////////////////////////////

const getDefinedDays = R.compose(
  R.flatten,
  R.filter(G.isNotNilAndNotEmpty),
  R.map(R.prop(GC.FIELD_DAYS)),
  R.pathOr([], ['values', GC.FIELD_OPERATION_HOUR]),
);

const defaultValues = { operationHour: R.of(Array, defaultBusinessHoursFields()) };

const blueColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const BusinessHoursSection = (props: Object) => {
  const { form, push, days, remove } = props;
  const { setValues } = form;

  const operationHour = R.pathOr([], ['values', GC.FIELD_OPERATION_HOUR], form);
  const businessHoursLength = R.length(operationHour);
  const handleRemoveRow = (index: number) => {
    if (R.equals(businessHoursLength, 1)) return setValues(defaultValues);

    remove(index);
  };
  const addRowCondition = R.and(
    R.gt(7, businessHoursLength),
    R.gt(7, R.length(getDefinedDays(form))),
  );
  const handleAddRow = () => {
    if (addRowCondition) push(defaultBusinessHoursFields());
  };

  return (
    <Box>
      <Flex
        cursor='pointer'
        fontWeight='bold'
        color={textColor}
        width='max-content'
        onClick={handleAddRow}
      >
        {G.getWindowLocale('titles:business-hours', 'Business Hours')}
        {
          addRowCondition && <Box ml={10}>{I.plusRound(blueColor)}</Box>
        }
      </Flex>
      {
        operationHour.map((_: any, index: number) =>
          <Flex key={index}>
            <Fieldset
              {...form}
              days={days}
              fieldsWrapperStyles={{ px: 0, pt: 24 }}
              fields={businessHoursFieldSettings(index)}
            />
            <Box ml={15} mt={20} cursor='pointer' onClick={() => handleRemoveRow(index)}>
              {I.trash(blueColor)}
            </Box>
          </Flex>,
        )
      }
    </Box>
  );
};

const formEnhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: ({ operationHour }: Object, { props }: Object) => props.submitAction(operationHour),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  withState('days', 'setDays', R.values(dayOptions())),
  withPropsOnChange(
    (props: Object, nextProps: Object) => G.notEquals(getDefinedDays(props), getDefinedDays(nextProps)),
    (props: Object) => {
      const { setFieldValue, values: { operationHour } } = props;

      const daysDefined = getDefinedDays(props);
      const days = R.compose(
        R.values,
        R.mergeRight(dayOptions()),
        R.map((item: Object) => R.assoc('isDisabled', true, item)),
        R.pick(daysDefined),
      )(dayOptions());

      if (R.equals(R.length(daysDefined), 7)) {
        const filteredBusinessHours = R.filter(
          ({ days }: Object) => G.isNotNilAndNotEmpty(days),
          operationHour,
        );

        setFieldValue(GC.FIELD_OPERATION_HOUR, filteredBusinessHours);
      }

      return { days };
    },
  ),
  pure,
);

const BusinessHoursForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldArray
      name={GC.FIELD_OPERATION_HOUR}
      render={(arrayHelpers: Object) =>
        <BusinessHoursSection {...arrayHelpers} days={props.days} />
      }
    />
    <FormFooter2
      closeModal={props.closeModal}
      boxStyles={{ pt: 20, bg: G.getTheme('colors.light.mainLight')}}
    />
  </form>
));

const LocationBusinessHours = ({
  iconColor,
  businessHours,
  handleOpenBusinessHours,
  handleRemoveBusinessHours,
}: Object) => (
  <Flex>
    <Flex pl={20} pr={15}>
      <Box mr={15} cursor='pointer' onClick={handleRemoveBusinessHours}>
        {I.trash(iconColor)}
      </Box>
      <Box cursor='pointer' onClick={() => handleOpenBusinessHours(true)}>
        {I.pencil(iconColor)}
      </Box>
    </Flex>
    <Box>
      {
        businessHours.map(({ days, workHours }: Object, index: number) => (
          <Flex py={10} key={index}>
            <Box width={242}>{workHours}</Box>
            <Box ml={20} width={460}>{days}</Box>
          </Flex>
        ))
      }
    </Box>
  </Flex>
);

const EventBusinessHours = ({
  iconsML,
  maxWidth,
  iconColor,
  businessHours,
  handleOpenBusinessHours,
  handleRemoveBusinessHours,
}: Object) => (
  <Flex>
    <Flex ml={R.or(iconsML, 10)}>
      <Box mr={10} cursor='pointer' onClick={handleRemoveBusinessHours}>
        {I.trash(iconColor)}
      </Box>
      <Box cursor='pointer' onClick={() => handleOpenBusinessHours(true)}>
        {I.pencil(iconColor)}
      </Box>
    </Flex>
    <Box>
      {
        businessHours.map(({ days, workHours }: Object, index: number) => (
          <Flex py='5px' key={index}>
            <TextComponent
              pl={10}
              zIndex={15}
              title={days}
              withEllipsis={true}
              maxWidth={maxWidth}
            >
              {days}
            </TextComponent>
            <Box mx='5px'>-</Box>
            <Box fontWeight='bold'>{workHours}</Box>
          </Flex>
        ))
      }
    </Box>
  </Flex>
);

const EventBusinessHoursSimple = ({ businessHours }: Object) => (
  <Box>
    {
      businessHours.map(({ days, workHours }: Object, index: number) => (
        <Flex py='4px' key={index}>
          <TextComponent
            title={days}
            maxWidth={130}
            withEllipsis={true}
          >
            {days}
          </TextComponent>
          <Box mx='2px'>-</Box>
          <Box>{workHours}</Box>
        </Flex>
      ))
    }
  </Box>
);

const BusinessHours = (props: Object) => {
  const {
    iconsML,
    maxWidth,
    operationHour,
    componentType,
    handleOpenBusinessHours,
    handleRemoveBusinessHours,
  } = props;

  if (G.isNilOrEmpty(operationHour)) return null;

  const iconColor = blueColor;
  const daysJoiner = G.ifElse(
    R.equals('eventSimple', componentType),
    './ ',
    ', ',
  );
  const businessHours = R.compose(
    R.values,
    R.mapObjIndexed((value: Array, key: string) => {
      const days = R.compose(
        R.join(daysJoiner),
        R.map((item: string) => G.ifElse(
          R.equals('eventSimple', componentType),
          G.getPropFromObject(item, daysMapSimple),
          G.getEnumLocale(item),
        )),
        R.filter((item: string) => R.includes(item, value)),
        R.keys,
      )(dayOptions());

      return { days, workHours: key };
    }),
    R.map(R.reduce((acc: Array, { days }: Object) => R.concat(days, acc), [])),
    R.groupBy(({ timeTo, timeFrom }: Object) => `${timeFrom} - ${timeTo}`),
    G.convertOperationHoursToConfigFormat,
  )(operationHour);
  const componentProps = {
    iconColor,
    businessHours,
    handleOpenBusinessHours,
    handleRemoveBusinessHours,
  };
  const componentMap = {
    location: <LocationBusinessHours {...componentProps} />,
    eventSimple: <EventBusinessHoursSimple businessHours={businessHours} />,
    event: (
      <EventBusinessHours
        {...componentProps}
        iconsML={iconsML}
        maxWidth={maxWidth}
        iconColor={iconColor}
      />
    ),
  };

  return G.getPropFromObject(componentType, componentMap);
};

export default BusinessHours;

export const withBusinessHours = compose(
  connect(null, { openModal, closeModal }),
  withHandlers({
    setBusinessHoursRequest: (props: Object) => (operationHour: Object, shouldCloseModal: boolean = true) => {
      const { closeModal, setFieldValue, setBusinessHoursRequest, useFormikSetFieldValue } = props;

      if (G.isTrue(useFormikSetFieldValue)) {
        setFieldValue(GC.FIELD_OPERATION_HOUR, operationHour);

        if (shouldCloseModal) closeModal();

        return;
      }

      setBusinessHoursRequest(operationHour);
    },
  }),
  withHandlers({
    handleRemoveBusinessHours: ({ setBusinessHoursRequest }: Object) => () =>
      setBusinessHoursRequest([], false),
    handleOpenBusinessHours: (props: Object) => (editMode: boolean = false) => {
      const { openModal, closeModal, operationHour, setBusinessHoursRequest } = props;

      const initialValues = G.isTrue(editMode) ?
        { operationHour: G.convertOperationHoursToConfigFormat(operationHour) }
        :
        null;

      const component = (
        <BusinessHoursForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={setBusinessHoursRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'auto',
          height: 'auto',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
