import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, lifecycle } from 'react-recompose';
// component
import { FormFooter } from '../../../components/form-footer';
// features
import { getItemListRequest } from '../../template/container-type/actions';
// features
import { ItemRow } from '../../new-do/forms/containers-array';
import { makeSelectItemList } from '../../template/container-type/selectors';
import { getNewContainerFields } from '../../new-do/settings/fields-settings';
import { validationSchemaSingleContainerForm } from '../../new-do/validation';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const enhance = compose(
  lifecycle({
    componentDidMount() {
      const { containerTypes, getItemListRequest } = this.props;

      if (G.isNotNilAndNotEmpty(containerTypes)) return;

      getItemListRequest();
    },
  }),
  withProps(({ initialContainer }: Object) => ({
    initialValues: { pickedUpContainers: R.of(Array, R.or(initialContainer, getNewContainerFields())) },
  })),
  withFormik({
    enableReinitialize: true,
    displayName: 'ContainerForm',
    validationSchema: validationSchemaSingleContainerForm,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(initialValues),
  }),
  pure,
);

const ContainerForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <ItemRow
      {...props}
      itemIndex={0}
      singleContainerForm={true}
      item={{[GC.FIELD_CONTAINER_INTERNAL_ID]: G.generateGuid()}}
      containerTypeOptions={G.addEmptyOptionToDropDown(G.mapNameGuidToLabelValue(props.containerTypes))} />
    <FormFooter closeModal={props.closeModal} />
  </form>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  containerTypes: makeSelectItemList(state),
});

export default connect(mapStateToProps, {
  getItemListRequest,
})(enhance(ContainerForm));
