import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import { Label } from '../../../components/label';
import { PageTitle } from '../../../components/page-title';
import { FormButtons2 } from '../../../components/form-buttons-2';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// features
import PC from '../../permission/role-permission';
import AdditionalLocations from './additional-locations';
import References from '../../reference/components/reference';
import { AuthWrapper, AuthByMasterBranch } from '../../permission';
// forms
import { Fieldset2, FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, ActionButton, PageHeaderWrapper } from '../../../ui';
// feature branch
import DocumentList from './document-list';
import BillToLocation from './bill-to-location';
import PrimaryLocation from './primary-location';
import { Form, TitleAction, ZIndexWrapper } from '../ui';
import { ROOT_BRANCH_GUID, BRANCH_SETTING_FORM_SECTION, COMPANY_DETAILS_FORM_SECTION } from '../constants';
import {
  settingsSectionFields,
  getFirstCompanyFields,
  getThirdCompanyFields,
  getSecondCompanyFields,
  getFourthCompanyFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const submitBtnStyles = {
  height: 32,
  width: 120,
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const SectionFormFooter = ({ submitting, handleClickUpdate }: Object) => (
  <Flex mt={20} justifyContent='flex-end'>
    <ActionButton
      {...submitBtnStyles}
      disabled={submitting}
      onClick={handleClickUpdate}
      cursor={G.ifElse(submitting, 'not-allowed', 'pointer')}
    >
      {G.getWindowLocale('actions:update', 'Update')}
    </ActionButton>
  </Flex>
);

const Action = (props: Object) => {
  const { companyDetails, handleEntStatus } = props;
  const { active, suspended } = companyDetails;

  const suspentionLabel = G.ifElse(
    suspended,
    'red-power-button',
    'green-power-button',
  );

  const suspentionText = G.ifElse(
    suspended,
    G.getWindowLocale('titles:suspended', 'Suspended'),
    G.getWindowLocale('titles:not-suspended', 'Not Suspended'),
  );

  return (
    <TitleAction
      active={active}
      suspention={suspended}
    >
      <AuthWrapper has={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
        <Label
          endLabel={suspentionLabel}
          endAction={() => handleEntStatus(companyDetails)}
        >
          {suspentionText}
        </Label>
      </AuthWrapper>
    </TitleAction>
  );
};

const fieldsWrapperStyles = {
  width: 800,
  justifyContent: 'space-between',
};

const CompanyDetailsSection = (props: Object) => {
  const {
    configs,
    submitting,
    permissions,
    isSuperAdmin,
    companyDetails,
    collapsedGroup,
    setFormSection,
    collapseBranchFormGroup,
    commissionAssignmentByType,
  } = props;

  const isAdmin = R.includes(PC.ROLE_LIUBANCHYK_SUPER_ADMIN, permissions);
  const isParentRoot = R.propEq(ROOT_BRANCH_GUID, GC.FIELD_PARENT_GUID, companyDetails);
  const isDivision = R.propEq(GC.BRANCH_TYPE_ENUM_DIVISION, GC.BRANCH_TYPE, companyDetails);

  let primaryDivisionName = null;

  if (R.not(G.getPropFromObject(GC.FIELD_PRIMARY_DIVISION, companyDetails))) {
    primaryDivisionName = G.getBranchNameFromWindowByGuid(G.getPropFromObject(
      GC.FIELD_PRIMARY_DIVISION_GUID,
      companyDetails,
    ));
  }

  const isMaster = R.or(
    R.equals(G.getGuidFromObject(companyDetails), ROOT_BRANCH_GUID),
    isParentRoot,
  );

  const allowDivision = R.and(
    G.checkStringsContains(R.of(Array, PC.CREATE_DIVISION_EXECUTE), permissions),
    R.not(R.propEq(GC.BRANCH_TYPE_ENUM_CUSTOMER, GC.FIELD_PARENT_TYPE, companyDetails)),
  );

  const isFieldEditible = R.or(G.isTrue(isAdmin), G.isFalse(isParentRoot));

  const hasBranchActivationPermissions = G.hasAmousCurrentUserPermissions(
    [PC.ACTIVATE_BRANCH_EXECUTE, PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
  );

  const hasCommissionAssignmentPermissions = G.hasAmousCurrentUserPermissions(
    [PC.CHANGE_COMMISSION_ASSIGNMENT_EXECUTE],
  );

  return (
    <ZIndexWrapper>
      <AuthWrapper has={[PC.BRANCH_WRITE]}>
        <FormGroupWrapper isOpened={collapsedGroup[`edit${COMPANY_DETAILS_FORM_SECTION}`]}>
          <FormGroupTitleComponent
            withArrowDown={true}
            isOpened={collapsedGroup[`edit${COMPANY_DETAILS_FORM_SECTION}`]}
            text={G.getWindowLocale('titles:company-details', 'Company Details')}
            onToggleFormGroup={() => (
              collapseBranchFormGroup(`edit${COMPANY_DETAILS_FORM_SECTION}`)
            )}
          />
          <Box
            p={15}
            width={830}
            bg={G.getTheme('colors.white')}
            boxShadow='0 2px 5px 0 rgba(0, 0, 0, 0.2)'
          >
            <Fieldset2
              {...G.getFormikProps(props)}
              fieldsWrapperStyles={fieldsWrapperStyles}
              fields={getFirstCompanyFields(hasBranchActivationPermissions, isDivision, primaryDivisionName)}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fieldsWrapperStyles={fieldsWrapperStyles}
              subTypeOptions={R.path(['subTypeOptions'], configs)}
              fields={getSecondCompanyFields(isFieldEditible, allowDivision, R.and(isSuperAdmin, isParentRoot))}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fieldsWrapperStyles={fieldsWrapperStyles}
              fields={getThirdCompanyFields(isFieldEditible, isMaster)}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              {...commissionAssignmentByType}
              fieldsWrapperStyles={fieldsWrapperStyles}
              fields={getFourthCompanyFields(isFieldEditible, hasCommissionAssignmentPermissions)}
            />
            <SectionFormFooter
              submitting={submitting}
              handleClickUpdate={() => setFormSection(COMPANY_DETAILS_FORM_SECTION)}
            />
          </Box>
        </FormGroupWrapper>
      </AuthWrapper>
    </ZIndexWrapper>
  );
};

const SettingsSection = (props: Object) => {
  const {
    submitting,
    importMappers,
    collapsedGroup,
    companyDetails,
    setFormSection,
    collapseBranchFormGroup,
  } = props;

  return (
    <ZIndexWrapper>
      <AuthWrapper has={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
        <AuthByMasterBranch
          shouldCheck={true}
          branchType={R.prop(GC.FIELD_BRANCH_BRANCH_TYPE, companyDetails)}
        >
          <FormGroupWrapper isOpened={collapsedGroup[`edit${BRANCH_SETTING_FORM_SECTION}`]}>
            <FormGroupTitleComponent
              withArrowDown={true}
              text={G.getWindowLocale('titles:settings', 'Settings')}
              isOpened={collapsedGroup[`edit${BRANCH_SETTING_FORM_SECTION}`]}
              onToggleFormGroup={() => (
                collapseBranchFormGroup(`edit${BRANCH_SETTING_FORM_SECTION}`)
              )}
            />
            <Box
              p={15}
              width={830}
              bg={G.getTheme('colors.white')}
              boxShadow='0 2px 5px 0 rgba(0, 0, 0, 0.2)'
            >
              <Fieldset2
                {...G.getFormikProps(props)}
                importMappers={importMappers}
                fields={settingsSectionFields}
                fieldsWrapperStyles={fieldsWrapperStyles}
              />
              <SectionFormFooter
                submitting={submitting}
                handleClickUpdate={() => setFormSection(BRANCH_SETTING_FORM_SECTION)}
              />
            </Box>
          </FormGroupWrapper>
        </AuthByMasterBranch>
      </AuthWrapper>
    </ZIndexWrapper>
  );
};

const BranchForm = (props: Object) => {
  const {
    configs,
    openModal,
    newValues,
    documents,
    closeModal,
    references,
    submitting,
    handleSubmit,
    companyDetails,
    collapsedGroup,
    downloadDocument,
    handleClickCancel,
    handleAddDocument,
    handleEditDocument,
    handleDeleteDocument,
    handleCreateReference,
    handleUpdateReference,
    handleRemoveReference,
    collapseBranchFormGroup,
  } = props;

  const referencesProps = {
    openModal,
    references,
    handleCreateReference,
    handleUpdateReference,
    handleRemoveReference,
  };

  const branchGuid = R.prop(GC.FIELD_GUID, companyDetails);
  const locationTypeOptions = R.path(['locationTypeOptions'], configs);

  const pageTitle = `
    ${G.getWindowLocale('titles:edit', 'Edit')} ${R.pathOr('', [GC.FIELD_BRANCH_NAME], companyDetails)}
  `;

  const entStatus = R.and(R.pathOr(false, ['values', COMPANY_DETAILS_FORM_SECTION, 'active'], newValues));

  return (
    <Fragment>
      <PageHeaderWrapper justify='space-between'>
        <PageTitle title={pageTitle} status={entStatus} showStatus={true} />
        {G.isNotNilAndNotEmpty(companyDetails) && <Action {...props} />}
      </PageHeaderWrapper>
      <Form onSubmit={handleSubmit}>
        <CompanyDetailsSection {...props} />
        <SettingsSection {...props} />
      </Form>
      <References {...referencesProps} scope={GC.REF_SCOPE_NAME_BRANCH} />
      <FormGroupWrapper isOpened={R.prop('editBillToLocation', collapsedGroup)}>
        <FormGroupTitleComponent
          withArrowDown={true}
          isOpened={R.prop('editBillToLocation', collapsedGroup)}
          text={G.getWindowLocale('titles:locations', 'Locations')}
          onToggleFormGroup={() => collapseBranchFormGroup('editBillToLocation')}
        />
        <BillToLocation branchGuid={branchGuid} locationTypeOptions={locationTypeOptions} />
        <PrimaryLocation branchGuid={branchGuid} locationTypeOptions={locationTypeOptions} />
      </FormGroupWrapper>
      <AdditionalLocations
        branchGuid={branchGuid}
        collapsedGroup={collapsedGroup}
        locationTypeOptions={locationTypeOptions}
        collapseBranchFormGroup={collapseBranchFormGroup}
      />
      <DocumentList
        documents={documents}
        openModal={openModal}
        closeModal={closeModal}
        collapsedGroup={collapsedGroup}
        downloadDocument={downloadDocument}
        handleAddDocument={handleAddDocument}
        handleEditDocument={handleEditDocument}
        handleDeleteDocument={handleDeleteDocument}
        collapseBranchFormGroup={collapseBranchFormGroup}
      />
      <FormButtons2
        submitting={submitting}
        justifyContent='flex-end'
        handleClickSave={handleSubmit}
        handleClickCancel={handleClickCancel}
        btnText={G.getWindowLocale('actions:save-and-close', 'Save And Close')}
      />
    </Fragment>
  );
};

export default BranchForm;
