import React from 'react';
import * as R from 'ramda';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { pure, compose, withState, withHandlers, withProps } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { AbsoluteBox } from '../../../ui';
// feature available-driver
import { StyledFormControl } from '../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('isFocused', 'setIsFocused', false),
  withProps(() => ({ configsNamesArray: R.of(Array, GC.TRUCK_TRUCK_TYPE) })),
  withAsyncConfigs,
  withHandlers({
    handleFocusTruckTypes: ({ setIsFocused }: Object) => () => setIsFocused(true),
    handleBlurTruckTypes: ({ setIsFocused }: Object) => () => setIsFocused(false),
  }),
  pure,
);

export const TruckTypesSelect = enhance((props: Object) => {
  const {
    isFocused,
    truckTypes,
    asyncConfigs,
    setTruckTypes,
    handleBlurTruckTypes,
    handleFocusTruckTypes,
    handleChangeTruckTypes,
  } = props;

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 185,
      },
    },
  };

  const muiFieldSize = 'small';
  const truckTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(asyncConfigs, GC.TRUCK_TRUCK_TYPE);

  return (
    <StyledFormControl
      size={muiFieldSize}
      focusedOrHasValue={R.or(isFocused, G.isNotNilAndNotEmpty(truckTypes))}
    >
      <InputLabel size={muiFieldSize} id='truck-type-label'>
        {G.getWindowLocale('titles:truck-types', 'Truck Types')}
      </InputLabel>
      <Select
        multiple={true}
        value={truckTypes}
        size={muiFieldSize}
        MenuProps={menuProps}
        id='truck-type-select'
        labelId='truck-type-label'
        onBlur={handleBlurTruckTypes}
        onFocus={handleFocusTruckTypes}
        onChange={handleChangeTruckTypes}
        input={<OutlinedInput label={G.getWindowLocale('titles:truck-types', 'Truck Types')} />}
        endAdornment={
          G.isNotNilAndNotEmpty(truckTypes) &&
          <AbsoluteBox
            right='0px'
            cursor='pointer'
            padding='10px 5px'
            onClick={() => setTruckTypes([])}
          >
            {I.closeIcon(G.getTheme('colors.dark.blue'))}
          </AbsoluteBox>
        }
      >
        {
          truckTypeOptions.map(({ label, value }: Object, index: number) => (
            <MenuItem value={value} key={`${index}${label}`}>
              {label}
            </MenuItem>
          ))
        }
      </Select>
    </StyledFormControl>
  );
});
