import * as R from 'ramda';
import { compose, withState, lifecycle } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const withRerenderMap = compose(
  withState('isActiveMap', 'setIsActiveMap', true),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const { setIsActiveMap } = this.props;

      const propsToCheck = ['fleetType', 'selectedDriver', 'trailers', 'drivers', 'trucks'];

      if (G.notEquals(R.pick(propsToCheck, this.props), R.pick(propsToCheck, prevProps))) {
        setIsActiveMap(false);
        window.requestAnimationFrame(() => setIsActiveMap(true));
      }
    },
  }),
);
