import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner
import * as H from './helpers';
import * as A from './actions';
import { TEL_MODE, PLANNER_TEL_MODES } from './constants';
//////////////////////////////////////////////////

const emptyRoute = {
  clos: {},
  tels: {},
};

const defaultPagination = {
  limit: 20,
  offset: 0,
};

const initialState = {
  crossDockCloList: [],
  crossDockCloTotal: 0,
  crossDockCloReport: null,
  crossDockCloFilterParams: {},
  crossDockCloListLoading: false,
  crossDockCloTitleSortValues: {},
  crossDockCloTableTitleFilters: {},
  crossDockCloPagination: defaultPagination,
  // crossdock clos
  cloList: [],
  telList: [],
  cloTotal: 0,
  telTotal: 0,
  draftList: [],
  draftsTotal: 0,
  cloReport: null,
  telReport: null,
  branchGuid: null,
  selectedTels: {},
  routeErrors: null,
  showDrafts: false,
  currentRoute: null,
  draftsReport: null,
  inboundTelTotal: 0,
  inboundTelList: [],
  outboundTelList: [],
  outboundTelTotal: 0,
  initRouteGuid: null,
  cloFilterParams: {},
  telFilterParams: {},
  openedFromPage: null,
  draftFilterParams: {},
  cloListLoading: false,
  telListLoading: false,
  currentRouteItems: {},
  inboundTelReport: null,
  inboundTerminals: null,
  cloTitleSortValues: {},
  telTitleSortValues: {},
  outboundTelReport: null,
  draftListLoading: false,
  availableCloReports: [],
  availableTelReports: [],
  outboundTerminals: null,
  cloTableTitleFilters: {},
  telTableTitleFilters: {},
  editTemplateRoute: false,
  primaryRefSequence: null,
  previousRouteState: null,
  bottomTableMode: TEL_MODE,
  branchGuidForRequest: null,
  currentBranchConfigs: null,
  currentRouteContainers: {},
  crossDockLocationList: null,
  currentRouteInitialItems: {},
  inboundTelListLoading: false,
  inboundTelTitleSortValues: {},
  outboundTelTitleSortValues: {},
  outboundTelListLoading: false,
  currentRouteInitialState: null,
  inboundTelTableTitleFilters: {},
  outboundTelTableTitleFilters: {},
  cloPagination: defaultPagination,
  telPagination: defaultPagination,
  currentRouteInitialContainers: {},
  draftsPagination: defaultPagination,
  inboundTelPagination: defaultPagination,
  outboundTelPagination: defaultPagination,
  plannerMode: PLANNER_TEL_MODES.ALL_EXPANDED,
};

const omitOnSetInitialState = [
  'openedFromPage',
  'editTemplateRoute',
  'currentBranchConfigs',
  'crossDockLocationList',
  'previousRouteState',
];

const initialItemProps = [
  {
    value: false,
    name: 'selected',
  },
  {
    value: false,
    name: 'expanded',
  },
];

const initialItemSelectedProps = [
  {
    value: true,
    name: 'selected',
  },
  {
    value: false,
    name: 'expanded',
  },
];

const setRouteBuilderInitialState = (state: Object) => R.mergeRight(
  R.pick(omitOnSetInitialState, state),
  R.omit(omitOnSetInitialState, initialState),
);

const setBranchGuidForRequest = (state: Object, data: string) => (
  P.$set('branchGuidForRequest', data, state)
);

const getConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('currentBranchConfigs', data, state)
);

const setInitialCurrentRoute = (state: Object, branchGuid: string) => (
  P.$all(
    P.$set('branchGuid', branchGuid),
    P.$set('currentRoute', R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, emptyRoute)),
    P.$set('currentRouteInitialState', R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, emptyRoute)),
    state,
  )
);

const setCurrentCloReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('cloReport', data),
    P.$set('cloPagination', initialState.cloPagination),
    P.$set('cloList', R.filter(R.propEq(true, 'selected'), state.cloList)),
    state,
  )
);

const setCurrentCrossDockCloReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('crossDockCloReport', data),
    P.$set('crossDockCloPagination', initialState.crossDockCloPagination),
    P.$set('crossDockCloList', R.filter(R.propEq(true, 'selected'), state.crossDockCloList)),
    state,
  )
);

const setCurrentTelReportWithRoute = (state: Object, data: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telReport', data),
    P.$set('selectedTels', {}),
    P.$set('routeErrors', null),
    P.$set('currentRouteItems', {}),
    P.$set('telPagination', defaultPagination),
    P.$set('inboundTelPagination', defaultPagination),
    P.$set('outboundTelPagination', defaultPagination),
    P.$set('currentRoute', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set('cloList', G.setPropsWithValuesToArrayItems(P.$get('cloList', state), initialItemProps)),
    P.$set('currentRouteInitialState', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set('inboundTelList', G.setPropsWithValuesToArrayItems(state.inboundTelList, initialItemProps)),
    P.$set('outboundTelList', G.setPropsWithValuesToArrayItems(state.outboundTelList, initialItemProps)),
    state,
  )
);

const setCurrentTelReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telReport', data),
    P.$set('inboundTelReport', data),
    P.$set('outboundTelReport', data),
    P.$set('telPagination', defaultPagination),
    P.$set('inboundTelPagination', defaultPagination),
    P.$set('outboundTelPagination', defaultPagination),
    P.$set('cloList', G.setPropsWithValuesToArrayItems(P.$get('cloList', state), initialItemProps)),
    state,
  )
);

const setCurrentInboundTelReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('inboundTelList', []),
    P.$set('inboundTelReport', data),
    P.$set('inboundTelPagination', initialState.inboundTelPagination),
    state,
  )
);

const setCurrentOutboundTelReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('outboundTelList', []),
    P.$set('outboundTelReport', data),
    P.$set('outboundTelPagination', initialState.outboundTelPagination),
    state,
  )
);

const setAvailableCloReports = (state: Object, data: Array) => (
  P.$set('availableCloReports', data, state)
);

const setAvailableTelReports = (state: Object, data: Array) => (
  P.$set('availableTelReports', data, state)
);

const setCloListLoading = (state: Object, data: boolean) => (
  P.$set('cloListLoading', data, state)
);

const setCrossDockCloListLoading = (state: Object, data: boolean) => (
  P.$set('crossDockCloListLoading', data, state)
);

const setTelListLoading = (state: Object, data: boolean) => (
  P.$set('telListLoading', data, state)
);

const setInboundTelListLoading = (state: Object, data: boolean) => (
  P.$set('inboundTelListLoading', data, state)
);

const setOutboundTelListLoading = (state: Object, data: boolean) => (
  P.$set('outboundTelListLoading', data, state)
);

const setCloTableTitleSort = (state: Object, data: Object) => {
  const { cloTitleSortValues } = state;

  const sortValues = G.getTableTitleSortValues(cloTitleSortValues, data);

  return P.$set('cloTitleSortValues', sortValues, state);
};

const setTelTableTitleSort = (state: Object, data: Object) => {
  const { telTitleSortValues } = state;

  const sortValues = G.getTableTitleSortValues(telTitleSortValues, data);

  return P.$set('telTitleSortValues', sortValues, state);
};

const setInboundTelTableTitleSort = (state: Object, data: Object) => {
  const { inboundTelTitleSortValues } = state;

  const sortValues = G.getTableTitleSortValues(inboundTelTitleSortValues, data);

  return P.$set('inboundTelTitleSortValues', sortValues, state);
};

const setOutboundTelTableTitleSort = (state: Object, data: Object) => {
  const { outboundTelTitleSortValues } = state;

  const sortValues = G.getTableTitleSortValues(outboundTelTitleSortValues, data);

  return P.$set('outboundTelTitleSortValues', sortValues, state);
};

const setCrossDockCloTableTitleSort = (state: Object, data: Object) => {
  const { crossDockCloTitleSortValues } = state;

  const sortValues = G.getTableTitleSortValues(crossDockCloTitleSortValues, data);

  return P.$set('crossDockCloTitleSortValues', sortValues, state);
};

const setCloTableTitleFilter = (state: Object, data: Object) => {
  const { cloTableTitleFilters } = state;

  const filterValues = G.getTableTitleFilterValues(cloTableTitleFilters, data);

  return P.$set('cloTableTitleFilters', filterValues, state);
};

const setTelTableTitleFilter = (state: Object, data: Object) => {
  const { telTableTitleFilters } = state;

  const filterValues = G.getTableTitleFilterValues(telTableTitleFilters, data);

  return P.$set('telTableTitleFilters', filterValues, state);
};

const setInboundTelTableTitleFilter = (state: Object, data: Object) => {
  const { inboundTelTableTitleFilters } = state;

  const filterValues = G.getTableTitleFilterValues(inboundTelTableTitleFilters, data);

  return P.$set('inboundTelTableTitleFilters', filterValues, state);
};

const setOutboundTelTableTitleFilter = (state: Object, data: Object) => {
  const { outboundTelTableTitleFilters } = state;

  const filterValues = G.getTableTitleFilterValues(outboundTelTableTitleFilters, data);

  return P.$set('outboundTelTableTitleFilters', filterValues, state);
};

const setCrossDockCloTableTitleFilter = (state: Object, data: Object) => {
  const { crossDockCloTableTitleFilters } = state;

  const filterValues = G.getTableTitleFilterValues(crossDockCloTableTitleFilters, data);

  return P.$set('crossDockCloTableTitleFilters', filterValues, state);
};

const setInitialRouteGuid = (state: Object, data: string) => (
  P.$set('initRouteGuid', data, state)
);

const setOpenedFromPage = (state: Object, data: string) => (
  P.$set('openedFromPage', data, state)
);

const setEditTemplateRoute = (state: Object, data: string) => (
  P.$set('editTemplateRoute', data, state)
);

const setCurrentRoute = (state: Object, data: Object) => {
  const previousRouteState = P.$get('previousRouteState', state);

  let dataToSet = data;

  if (G.isNotNil(previousRouteState)) {
    const prevTels = R.compose(
      R.sortBy(R.prop(GC.FIELD_ORDER)),
      R.values,
      R.prop(GC.SYSTEM_LIST_TELS),
    )(previousRouteState);

    const tels = R.map(
      (tel: Object) => {
        const { order } = tel;

        const prevRates = R.path([order, 'carrierRates'], prevTels);
        const dispatchType = R.pathOr(GC.DISPATCH_TYPE_COMMIT, [order, GC.FIELD_DISPATCH_TYPE], prevTels);

        const rates = G.mapIndexed(
          (rate: Object, i: number) => R.assoc(
            GC.FIELD_COMMITMENT_COUNT,
            R.pathOr(null, [i, GC.FIELD_COMMITMENT_COUNT], prevRates),
            rate,
          ),
          R.propOr([], GC.SYSTEM_LIST_RATES, tel),
        );

        return R.mergeRight(tel, {
          [GC.SYSTEM_LIST_RATES]: rates,
          [GC.FIELD_DISPATCH_TYPE]: dispatchType,
        });
      },
      R.prop(GC.SYSTEM_LIST_TELS, data),
    );

    dataToSet = R.assoc(
      GC.SYSTEM_LIST_TELS,
      tels,
      data,
    );
  }

  return (
    P.$all(
      P.$set('routeErrors', null),
      P.$set('currentRoute', dataToSet),
      P.$set('previousRouteState', null),
      P.$set('currentRouteInitialState', dataToSet),
      state,
    )
  );
};

const setPreviousRouteState = (state: Object, data: Object) => P.$set(
  'previousRouteState',
  data,
  state,
);

const setSelectedCloList = (state: Object, data: Object) => (
  P.$all(
    P.$set('cloTotal', R.path(['results', 'length'], data)),
    P.$set('cloList', G.setPropsWithValuesToArrayItems(data.results, initialItemSelectedProps)),
    state,
  )
);

const setSelectedCrossDockCloList = (state: Object, data: Object) => (
  P.$all(
    P.$set('crossDockCloTotal', R.path(['results', 'length'], data)),
    P.$set('crossDockCloList', G.setPropsWithValuesToArrayItems(data.results, initialItemSelectedProps)),
    state,
  )
);

const setSelectedTelList = (state: Object, data: Object) => (
  P.$all(
    P.$set('telTotal', data.totalCount),
    P.$set('telList', G.setPropsWithValuesToArrayItems(data.results, initialItemSelectedProps)),
    state,
  )
);

const setCloList = (state: Object, data: Object) => {
  const { cloList, cloPagination } = state;
  const newOffset = R.add(cloPagination.offset, cloPagination.limit);
  return P.$all(
    P.$set('cloTotal', data.totalCount),
    P.$set('cloPagination.total', data.totalCount),
    P.$set(
      'cloPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'cloList',
      R.concat(
        cloList,
        G.getArrayFilteredFromArrayByProp(
          G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
          cloList,
          'guid',
        ),
      ),
    ),
    state,
  );
};

const setCrossDockCloList = (state: Object, data: Object) => {
  const { crossDockCloList, crossDockCloPagination } = state;
  const { offset, limit } = crossDockCloPagination;

  const newOffset = R.add(offset, limit);

  return P.$all(
    P.$set('crossDockCloTotal', data.totalCount),
    P.$set('crossDockCloPagination.total', data.totalCount),
    P.$set(
      'crossDockCloPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'crossDockCloList',
      R.concat(
        crossDockCloList,
        G.getArrayFilteredFromArrayByProp(
          G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
          crossDockCloList,
          GC.FIELD_GUID,
        ),
      ),
    ),
    state,
  );
};

const setTelList = (state: Object, data: Object) => {
  const { telList, telPagination } = state;
  const newOffset = R.add(telPagination.offset, telPagination.limit);
  return P.$all(
    P.$set('telTotal', data.totalCount),
    P.$set(
      'telPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'telList',
      R.concat(
        telList,
        G.getArrayFilteredFromArrayByProp(
          G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
          telList,
          'guid',
        ),
      ),
    ),
    state,
  );
};

const prependTelToList = (state: Object, { results }: Object) => (
  P.$set('telList', R.concat(results, state.telList), state)
);

const setInboundTelList = (state: Object, data: Object) => {
  const { inboundTelList, inboundTelPagination } = state;
  const newOffset = R.add(inboundTelPagination.offset, inboundTelPagination.limit);
  return P.$all(
    P.$set('inboundTelTotal', data.totalCount),
    P.$set(
      'inboundTelPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'inboundTelList',
      R.concat(
        inboundTelList,
        H.setSelectedPropIfExist(
          G.getArrayFilteredFromArrayByProp(
            G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
            inboundTelList,
            GC.FIELD_GUID,
          ),
          R.values(state.selectedTels),
        ),
      ),
    ),
    state,
  );
};

const setOutboundTelList = (state: Object, data: Object) => {
  const { outboundTelList, outboundTelPagination } = state;
  const newOffset = R.add(outboundTelPagination.offset, outboundTelPagination.limit);
  return P.$all(
    P.$set('outboundTelTotal', data.totalCount),
    P.$set(
      'outboundTelPagination.offset',
      G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount),
    ),
    P.$set(
      'outboundTelList',
      R.concat(
        outboundTelList,
        H.setSelectedPropIfExist(
          G.getArrayFilteredFromArrayByProp(
            G.setPropsWithValuesToArrayItems(data.results, initialItemProps),
            outboundTelList,
            GC.FIELD_GUID,
          ),
          R.values(state.selectedTels),
        ),
      ),
    ),
    state,
  );
};

const resetCloPagination = (state: Object) => (
  P.$set('cloPagination', initialState.cloPagination, state)
);

const resetCrossDockCloPagination = (state: Object) => (
  P.$set('crossDockCloPagination', initialState.crossDockCloPagination, state)
);

const resetTelPagination = (state: Object) => (
  P.$set('telPagination', initialState.telPagination, state)
);

const selectClo = (state: Object, id: string) => (
  P.$set('cloList', H.selectItem(state.cloList, id), state)
);

const selectTel = (state: Object, data: Array = []) => (
  P.$all(
    P.$set('telList', H.selectTelItems(state.telList, data)),
    P.$set('inboundTelList', H.setSelectedPropIfExist(state.inboundTelList, data)),
    P.$set('outboundTelList', H.setSelectedPropIfExist(state.outboundTelList, data)),
    P.$set('selectedTels', R.reduce((acc: Object, guid: string) => R.assoc(guid, guid, acc), {}, data)),
    state,
  )
);

const selectTelOnAllLists = (state: Object, data: Array) => (
  P.$all(
    P.$set('telList', H.setSelectedPropIfExist(state.telList, data)),
    P.$set('inboundTelList', H.setSelectedPropIfExist(state.inboundTelList, data)),
    P.$set('outboundTelList', H.setSelectedPropIfExist(state.outboundTelList, data)),
    P.$set('selectedTels', R.reduce((acc: Object, guid: string) => R.assoc(guid, guid, acc), {}, data)),
    state,
  )
);

const toggleClo = (state: Object, id: string) => (
  P.$set(
    'cloList',
    R.map((item: Object) => {
      if (R.equals(R.prop('guid', item), id)) {
        return P.$set('expanded', R.not(R.prop('expanded', item)), item);
      }
      return item;
    }, state.cloList),
    state,
  )
);

const toggleCrossDockClo = (state: Object, id: string) => (
  P.$set(
    'crossDockCloList',
    R.map((item: Object) => {
      if (R.propEq(id, GC.FIELD_GUID, item)) {
        return P.$set('expanded', R.not(R.prop('expanded', item)), item);
      }

      return item;
    }, state.crossDockCloList),
    state,
  )
);

const getListname = ({ isInbound, isOutbound }: Object) => {
  if (isInbound) return 'inboundTelList';
  if (isOutbound) return 'outboundTelList';
  return 'telList';
};

const toggleTel = (state: Object, data: string) => {
  const listName = getListname(data);
  return P.$set(
    listName,
    R.map((item: Object) => {
      if (R.eqProps(GC.FIELD_GUID, item, data)) {
        return P.$set('expanded', R.not(R.prop('expanded', item)), item);
      }
      return item;
    }, state[listName]),
    state,
  );
};

const getCloDetailsSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set(
      'cloList',
      R.map((item: Object) => {
        if (R.eqProps(GC.FIELD_GUID, data, item)) return P.$set('details', data, item);

        return item;
      }, state.cloList),
    ),
    P.$set(
      'crossDockCloList',
      R.map((item: Object) => {
        if (R.eqProps(GC.FIELD_GUID, data, item)) return P.$set('details', data, item);

        return item;
      }, state.crossDockCloList),
    ),
    state,
  )
);

const uncheckCloAndRemoveEvents = (state: Object, data: Object) => (
  P.$all(
    P.$drop(`currentRoute.clos.${data.guid}`),
    P.$set('cloList', H.selectItem(state.cloList, R.prop('guid', data))),
    P.$set('currentRouteItems', H.filterCurrentRouteItems(state.currentRouteItems, data.guid)),
    P.$set('currentRoute.tels', H.removeCloEventsFromTels(data.guid, P.$get('currentRoute.tels', state))),
    state,
  )
);

const uncheckCrossDockClo = (state: Object, data: Object) => {
  const guid = G.getGuidFromObject(data);

  return P.$all(
    P.$drop(`currentRoute.linkedClos.${guid}`),
    P.$set('crossDockCloList', H.selectItem(state.crossDockCloList, guid)),
    P.$set('currentRoute.tels', R.map(
      (tel: Object) => R.assoc('linkedCloGuids', R.without(R.of(Array, guid), tel.linkedCloGuids), tel),
      state.currentRoute.tels,
    )),
    state,
  );
};

const uncheckCrossDockClos = (state: Object, data: Array) => (
  P.$all(
    P.$set('currentRoute.linkedClos', R.omit(data, R.path(['currentRoute', 'linkedClos'], state))),
    P.$set('currentRoute.tels', R.map(
      (tel: Object) => R.assoc('linkedCloGuids', R.without(data, tel.linkedCloGuids), tel),
      state.currentRoute.tels,
    )),
    P.$set('crossDockCloList', R.map(
      (item: Object) => {
        if (R.includes(G.getGuidFromObject(item), data)) {
          return R.assoc('selected', false, item);
        }

        return item;
      },
      state.crossDockCloList,
    )),
    state,
  )
);

const selectCloForTelSuccess = (state: Object, { telGuid, cloDetails }: Object) => (
  P.$all(
    P.$set(`currentRoute.clos.${cloDetails.guid}`, cloDetails),
    P.$set('cloList', H.selectItem(state.cloList, cloDetails.guid)),
    P.$set('currentRouteItems', H.addTelItems(state.currentRouteItems, cloDetails.items)),
    P.$set(
      `currentRoute.tels.${telGuid}.events`,
      H.createNewEventsForTel(cloDetails, P.$get(`currentRoute.tels.${telGuid}`, state)),
    ),
    P.$set(
      'currentRouteContainers',
      R.mergeRight(
        state.currentRouteContainers,
        H.mapRouteContainers(R.of(Array, R.pick([GC.FIELD_LOAD_STOPS], cloDetails))),
      ),
    ),
    state,
  )
);

const selectCrossDockCloSuccess = (state: Object, { telGuid, cloDetails }: Object) => {
  const guid = G.getGuidFromObject(cloDetails);

  return P.$all(
    P.$add(`currentRoute.tels.${telGuid}.linkedCloGuids`, guid),
    P.$set('crossDockCloList', H.selectItem(state.crossDockCloList, guid)),
    P.$set(
      'currentRoute.linkedClos',
      R.assoc(guid, cloDetails, R.pathOr([], ['currentRoute', 'linkedClos'], state)),
    ),
    state,
  );
};

const setDetailsToListItem = (data: Object, list: Array) => R.map((item: Object) => {
  if (R.eqProps(GC.FIELD_GUID, data, item)) return R.assoc('details', data, item);
  return item;
}, list);

const setTelDetails = (state: Object, data: Object) => (
  P.$all(
    P.$set('telList', setDetailsToListItem(data, state.telList)),
    P.$set('inboundTelList', setDetailsToListItem(data, state.inboundTelList)),
    P.$set('outboundTelList', setDetailsToListItem(data, state.outboundTelList)),
    state,
  )
);

const sortTelEvents = (state: Object, guid: string) => {
  const events = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, i: number) => R.assoc('telEventIndex', R.inc(i), item)),
    R.sort((prevEvent: Object, event: Object) => (
      G.getDateRange(G.getStopEarlyDate(event), G.getStopEarlyDate(prevEvent), GC.DEFAULT_DATE_TIME_FORMAT)
    )),
    R.values,
  )(R.pathOr({}, ['currentRoute', 'tels', guid, GC.FIELD_LOAD_STOPS], state));
  return P.$set(`currentRoute.tels.${guid}.events`, events, state);
};

const addNewTelToRouteBuilder = (state: Object, data: Object = {}) => {
  const order = R.length(R.values(P.$get('currentRoute.tels', state)));
  const newTel = H.createNewRouteTel(R.assoc('order', order, data));
  return P.$set(`currentRoute.tels.${newTel.guid}`, newTel, state);
};

const addNewStopToRouteBuilderTel = (state: Object, data: Object) => {
  const options = H.generateDataForNewTelStopFromStopForm(data, state);
  const newStop = H.createNewStopOnRouteTel(options);

  return P.$set(`currentRoute.tels.${data.loadGuid}.events.${newStop.guid}`, newStop, state);
};

const addNewCloStopToRouteBuilderTel = (state: Object, data: Object) => {
  const options = H.generateDataForNewCloStopFromStopForm(data, state);
  const newStop = H.createNewCloStopOnRouteTel(options);

  return P.$set(`currentRoute.tels.${data.telGuid}.events.${newStop.guid}`, newStop, state);
};

const updateStopOnRouteBuilderTelSuccess = (state: Object, data: Object) => {
  const pathToEvent = `currentRoute.tels.${data.loadGuid}.events.${data.eventGuid}`;
  const prevEvent = H.updateEventByStopType(P.$get(pathToEvent, state));

  const stopFieldsArray = [
    GC.FIELD_STOP_NUMBER,
    ...GC.GROUPED_FIELDS.APPOINTMENTS_ARR,
    ...GC.GROUPED_FIELDS.EVENT_DATES_TIME_ARR,
  ];

  const stopFields = R.pick(stopFieldsArray, data.location);
  const location = R.pick(GC.GROUPED_FIELDS.EVENT_FULL_LOCATION_ARR, data.location);
  const stopFieldsWithLocation = R.assoc('location', location, stopFields);
  const mergedData = R.mergeDeepRight(prevEvent, stopFieldsWithLocation);

  const stateWithUpdatedTelsTerminalStops = H.updateCurrentRouteTelsTerminalStops(
    prevEvent,
    R.omit([GC.FIELD_GUID, GC.FIELD_VERSION, GC.FIELD_COMMENTS, GC.FIELD_CONTACTS], location),
    state,
    data.eventGuid,
  );

  return P.$set(pathToEvent, mergedData, stateWithUpdatedTelsTerminalStops);
};

const addTerminalToRouteBuilderTel = (state: Object, data: Object) => {
  const stopId = G.generateGuid();
  const stateWithNewTerminalDrops = H.addTerminalDropsToCurrentRouteTels(stopId, data, state);
  return H.addTerminalPickupsToCurrentRouteTels(stopId, data, stateWithNewTerminalDrops);
};

const addExistedTerminalToTel = (state: Object, data: Object) => {
  const { telGuid } = data;
  const stopId = R.path(['terminal'], data);
  const eventType = R.path(['eventType'], data);
  const location = H.getFirstTerminalEventLocationByStopIdFromState(stopId, state);
  const options = {
    location,
    eventType,
    loadGuid: telGuid,
  };
  const stopData = H.generateDataForNewTelStopFromStopForm(options, state);
  const newStopData = R.assoc('stopId', stopId, stopData);
  const stopDataToSet = H.createTerminalStopWithExistedStopId(newStopData);
  return P.$set(`currentRoute.tels.${data.telGuid}.events.${stopDataToSet.guid}`, stopDataToSet, state);
};

const removeTelFromRouteBuilder = (state: Object, tel: Object) => {
  const itemsToFilter = R.uniq(R.reduce(
    (arr: Array = [], event: Object) => R.concat(arr, event.itemIds),
    [],
    R.values(tel[GC.FIELD_LOAD_STOPS]),
  ));
  const tels = R.map(
    (routeTel: Object) => {
      if (R.gte(routeTel.order, tel.order)) {
        return R.assoc(
          GC.FIELD_LOAD_STOPS,
          R.map(
            (telEvent: Object) => R.assoc('itemIds', R.difference(telEvent.itemIds, itemsToFilter), telEvent),
            routeTel[GC.FIELD_LOAD_STOPS],
          ),
          routeTel,
        );
      }
      return routeTel;
    },
    state.currentRoute.tels,
  );
  const itemsToRemove = H.getItemsToRemove(itemsToFilter, tels);
  return P.$all(
    P.$set('currentRoute.tels', R.omit(R.of(Array, tel.guid), tels)),
    P.$set('currentRouteItems', R.omit(itemsToRemove, state.currentRouteItems)),
    state,
  );
};

const removeStopFromRouteBuilderTel = (state: Object, data: Object) => {
  const itemsToFilter = R.prop('itemIds', data);
  const stopTel = R.path(['currentRoute', 'tels', data.telGuid], state);
  const tels = H.getTelsFilteredFromItems(
    R.pathOr({}, ['currentRoute', 'tels'], state),
    stopTel,
    data,
    itemsToFilter,
  );
  const itemsToRemove = H.getItemsToRemove(itemsToFilter, tels);
  return P.$all(
    P.$set('currentRoute.tels', tels),
    P.$drop(`currentRoute.tels.${data.telGuid}.events.${data.guid}`),
    P.$set('currentRouteItems', R.omit(itemsToRemove, state.currentRouteItems)),
    state,
  );
};

const removeItemFromStop = (state: Object, { id, stop }: Object) => {
  const { currentRoute, currentRouteItems } = state;

  const { telGuid, stopType } = stop;

  const itemsToFilter = R.of(Array, id);
  const stopTel = R.path([GC.SYSTEM_LIST_TELS, telGuid], currentRoute);

  const tels = H.getTelsFilteredFromItems(
    R.pathOr({}, [GC.SYSTEM_LIST_TELS], currentRoute),
    stopTel,
    stop,
    itemsToFilter,
  );

  const itemsToRemove = H.getItemsToRemove(itemsToFilter, tels);

  if (R.and(G.isNilOrEmpty(itemsToRemove), G.isStopTypeTerminal(stopType))) {
    const currentItem = G.getPropFromObject(id, currentRouteItems);

    return P.$all(
      P.$set('currentRoute.tels', tels),
      P.$set(`currentRouteItems.${id}`, R.assoc(GC.FIELD_SELECTED, G.isStopPickup(stop), currentItem)),
      state,
    );
  }

  return P.$all(
    P.$set('currentRoute.tels', tels),
    P.$set('currentRouteItems', R.omit(itemsToRemove, currentRouteItems)),
    state,
  );
};

const removeContainerFromStop = (state: Object, { id, stop, isPickupContainer }: Object) => {
  const containersToFilter = R.of(Array, id);
  const stopTel = R.path(['currentRoute', 'tels', stop.telGuid], state);
  const tels = H.getTelsFilteredFromContainers(
    R.pathOr({}, ['currentRoute', 'tels'], state),
    stopTel,
    stop,
    isPickupContainer,
    containersToFilter,
  );
  const telsToMap = R.compose(
    R.map((tel: Object) => R.assoc(GC.FIELD_LOAD_STOPS, R.values(R.prop(GC.FIELD_LOAD_STOPS, tel)), tel)),
    R.values,
  )(tels);

  return P.$all(
    P.$set('currentRoute.tels', tels),
    P.$set('currentRouteItems', H.mapRouteItems(telsToMap)),
    P.$set('currentRouteContainers', H.mapRouteContainers(telsToMap)),
    state,
  );
};

const removeTrailerFromStop = (state: Object, { tel, stop, trailer, pickedUp }: Object) => {
  const telGuid = G.getGuidFromObject(tel);
  const stopGuid = G.getGuidFromObject(stop);
  const trailerGuid = G.getGuidFromObject(trailer);

  const trailersProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailers', 'droppedTrailers');
  const trailerGuidsProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailerGuids', 'droppedTrailerGuids');

  return P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.${trailerGuidsProp}`,
      R.reject(
        (guid: string) => R.equals(guid, trailerGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, trailerGuidsProp], state),
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.${trailersProp}`,
      R.reject(
        ({ guid }: Object) => R.equals(guid, trailerGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, trailersProp], state),
      ),
    ),
    state,
  );
};

const removeCloItemFromStop = (state: Object, { stopGuid, cloGuid, telGuid }: Object) => (
  P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.items`,
      R.filter(
        ({ loadGuid }: Object) => G.notEquals(loadGuid, cloGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, 'items'], state),
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${stopGuid}.itemIds`,
      R.filter(
        (itemId: string) => G.notEquals(R.pathOr(null, ['currentRouteItems', itemId, 'loadGuid'], state), cloGuid),
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, stopGuid, 'itemIds'], state),
      ),
    ),
    state,
  )
);

const recalculateTelDistancesSuccess = (state: Object, data: Object) => (
  P.$set(`currentRoute.tels.${data.guid}.events`, data.events, state)
);

const recalculateTelDistancesWithMoveItemSuccess = (state: Object, data: Object) => {
  const { guid, events, routeBuilderStore } = data;
  if (R.isNil(R.path(['routeErrors', guid, 'eventsErrors'], routeBuilderStore))) {
    return P.$set(`currentRoute.tels.${guid}.events`, events, routeBuilderStore);
  }
  return P.$all(
    P.$drop(`routeErrors.${guid}`),
    P.$set(`currentRoute.tels.${guid}.events`, events),
    routeBuilderStore,
  );
};

const cleanCloQuickFilter = (state: Object) => (
  P.$all(
    P.$set('cloFilterParams', {}),
    P.$set('cloPagination', initialState.cloPagination),
    P.$set('cloList', R.filter(R.propEq(true, 'selected'), state.cloList)),
    state,
  )
);

const cleanCrossDockCloQuickFilter = (state: Object) => (
  P.$all(
    P.$set('crossDockCloFilterParams', {}),
    P.$set('crossDockCloPagination', initialState.crossDockCloPagination),
    P.$set('crossDockCloList', R.filter(R.propEq(true, 'selected'), state.crossDockCloList)),
    state,
  )
);

const setCloQuickFilterParams = (state: Object, data: Object) => (
  P.$set('cloFilterParams', data, state)
);

const setCrossDockCloQuickFilterParams = (state: Object, data: Object) => (
  P.$set('crossDockCloFilterParams', data, state)
);

const cleanTelQuickFilter = (state: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telFilterParams', {}),
    P.$set('telPagination', initialState.telPagination),
    state,
  )
);

const setTelQuickFilterParams = (state: Object, data: Object) => (
  P.$set('telFilterParams', data, state)
);

const resetCloListAndPagination = (state: Object) => (
  P.$all(
    P.$set('cloPagination', initialState.cloPagination),
    P.$set('cloList', R.filter(R.propEq(true, 'selected'), state.cloList)),
    state,
  )
);

const resetCrossDockCloListAndPagination = (state: Object) => (
  P.$all(
    P.$set('crossDockCloPagination', initialState.crossDockCloPagination),
    P.$set('crossDockCloList', R.filter(R.propEq(true, 'selected'), state.crossDockCloList)),
    state,
  )
);

const resetTelListAndPagination = (state: Object) => (
  P.$all(
    P.$set('telList', []),
    P.$set('telPagination', initialState.telPagination),
    state,
  )
);

const resetInboundTelListAndPagination = (state: Object) => (
  P.$all(
    P.$set('inboundTelList', []),
    P.$set('inboundTelPagination', initialState.inboundTelPagination),
    state,
  )
);

const resetOutboundTelListAndPagination = (state: Object) => (
  P.$all(
    P.$set('outboundTelList', []),
    P.$set('outboundTelPagination', initialState.outboundTelPagination),
    state,
  )
);

const setAvailableItems = (state: Object, data: Object) => (
  P.$all(
    P.$set('currentRouteItems', data),
    P.$set('currentRouteInitialItems', data),
    state,
  )
);

const setAvailableContainers = (state: Object, data: Object) => (
  P.$all(
    P.$set('currentRouteContainers', data),
    P.$set('currentRouteInitialContainers', data),
    state,
  )
);

const editItem = (state: Object, data: Object) => (
  P.$set(`currentRouteItems.${data.itemInternalId}`, data, state)
);

const updateContainer = (state: Object, data: Object) => (
  P.$set(`currentRouteContainers.${data.containerInternalId}`, data, state)
);

const addNewItemOnUI = (state: Object, props: Object) => {
  const { stop, data, telGuid, eventGuid } = props;

  const id = G.generateGuid();
  const isCloItem = G.isLoadTypeClo(stop);

  let item = R.mergeRight({
    guid: id,
    isNew: true,
    selected: false,
    itemInternalId: id,
  }, data);

  if (isCloItem) {
    const { cloGuid, telGuid, loadGuid } = stop;

    item = R.mergeRight(item, {
      cloGuid,
      telGuid,
      loadGuid,
      loadType: GC.LOAD_TYPE_CLO,
    });
  }

  return P.$all(
    P.$set(`currentRouteItems.${id}`, item),
    P.$add(`currentRoute.tels.${telGuid}.events.${eventGuid}.itemIds`, id),
    P.$add(`currentRoute.tels.${telGuid}.events.${eventGuid}.items`, item),
    state,
  );
};

const addItemsForDrop = (state: Object, data: Object) => {
  const itemIds = R.uniq(R.concat(
    R.pathOr(
      [],
      ['currentRoute', 'tels', data.telGuid, GC.FIELD_LOAD_STOPS, data.eventGuid, 'itemIds'],
      state,
    ),
    data.items,
  ));
  const items = R.map(
    (id: string) => R.path(['currentRouteItems', id], state),
    itemIds,
  );
  return P.$all(
    P.$set(`currentRoute.tels.${data.telGuid}.events.${data.eventGuid}.items`, items),
    P.$set(`currentRoute.tels.${data.telGuid}.events.${data.eventGuid}.itemIds`, itemIds),
    P.$set(
      'currentRouteItems',
      R.mergeRight(
        state.currentRouteItems,
        R.indexBy(
          R.prop('itemInternalId'),
          R.map(
            (id: string) => R.assoc('selected', true, state.currentRouteItems[id]),
            data.items,
          ),
        ),
      ),
    ),
    state,
  );
};

const addItemsClosForDrop = (state: Objext, { stop, items, loadItems }: Object) => {
  const { guid, telGuid } = stop;
  const newState = P.$all(
    P.$set(
      'currentRouteItems',
      R.mergeRight(
        state.currentRouteItems,
        R.indexBy(
          R.prop('itemInternalId'),
          R.map(
            (id: string) => R.assoc('selected', true, state.currentRouteItems[id]),
            items,
          ),
        ),
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${guid}.itemIds`,
      R.compose(
        R.uniq,
        R.concat(R.pathOr([], ['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS, guid, 'itemIds'], state)),
        R.concat(items),
        R.map((loadItem: Object) => loadItem.itemInternalId),
      )(loadItems),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${guid}.items`,
      loadItems,
    ),
    state,
  );
  if (G.isNotNil(state.routeErrors)) {
    return P.$drop(`routeErrors.${telGuid}`, newState);
  }
  return newState;
};

const addItemsForPickup = (state: Object, { items, telGuid, eventGuid }: Object) => {
  const { currentRoute, currentRouteItems } = state;

  const currentEvent = R.pathOr(
    [],
    [GC.SYSTEM_LIST_TELS, telGuid, GC.FIELD_LOAD_STOPS, eventGuid],
    currentRoute,
  );

  const newItems = R.map(
    (id: string) => R.assoc('selected', false, G.getPropFromObject(id, currentRouteItems)),
    items,
  );

  return (
    P.$all(
      P.$set(
        'currentRouteItems',
        R.mergeRight(currentRouteItems, R.indexBy(R.prop('itemInternalId'), newItems)),
      ),
      P.$set(
        `currentRoute.tels.${telGuid}.events.${eventGuid}.itemIds`,
        R.uniq(R.concat(R.pathOr([], [GC.FIELD_STOP_ITEM_IDS], currentEvent), items)),
      ),
      P.$set(
        `currentRoute.tels.${telGuid}.events.${eventGuid}.items`,
        R.uniq(R.concat(R.pathOr([], [GC.FIELD_STOP_ITEMS], currentEvent), newItems)),
      ),
      state,
    )
  );
};

const addItemsClosForPickup = (state: Objext, { stop, items, loadItems }: Object) => (
  P.$all(
    P.$set(
      'currentRouteItems',
      R.mergeRight(
        state.currentRouteItems,
        R.indexBy(
          R.prop('itemInternalId'),
          R.map(
            (id: string) => R.assoc('selected', false, state.currentRouteItems[id]),
            items,
          ),
        ),
      ),
    ),
    P.$set(
      `currentRoute.tels.${stop.telGuid}.events.${stop.guid}`,
      H.getUpdatedStopOnAddItem({ stop, state, loadItems, currentItems: items }),
    ),
    state,
  )
);

const setBottomTableMode = (state: Object, data: string) => (
  P.$set('bottomTableMode', data, state)
);

const cleanBuilder = (state: Object) => (
  P.$all(
    P.$set('selectedTels', {}),
    P.$set('routeErrors', null),
    P.$set('currentRouteItems', {}),
    P.$set('branchGuidForRequest', null),
    P.$set('draftsPagination', initialState.draftsPagination),
    P.$set('currentRoute', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set('cloList', G.setPropsWithValuesToArrayItems(P.$get('cloList', state), initialItemProps)),
    P.$set('telList', G.setPropsWithValuesToArrayItems(P.$get('telList', state), initialItemProps)),
    P.$set('currentRouteInitialState', R.assoc(GC.FIELD_BRANCH_GUID, state.branchGuid, emptyRoute)),
    P.$set('inboundTelList', G.setPropsWithValuesToArrayItems(P.$get('inboundTelList', state), initialItemProps)),
    P.$set('outboundTelList', G.setPropsWithValuesToArrayItems(P.$get('outboundTelList', state), initialItemProps)),
    P.$set('crossDockCloList', G.setPropsWithValuesToArrayItems(P.$get('crossDockCloList', state), initialItemProps)),
    state,
  )
);

const cleanDrafts = (state: Object) => (
  P.$set('draftList', [], state)
);

const getDraftListSuccess = (state: Object, data: Object) => {
  const { draftList, draftsPagination } = state;
  const newOffset = R.add(draftsPagination.offset, draftsPagination.limit);
  return P.$all(
    P.$set('draftsTotal', data.totalCount),
    P.$set('draftsPagination.offset', G.ifElse(R.gt(data.totalCount, newOffset), newOffset, data.totalCount)),
    P.$set('draftList', R.concat(draftList, G.setPropsWithValuesToArrayItems(data.results, initialItemProps))),
    state,
  );
};

const selectDraft = (state: Object, data: Object) => (
  P.$set('draftList', H.selectDraftItem(state.draftList, data.internalId), state)
);

const toggleDraft = (state: Object, id: string) => (
  P.$set(
    'draftList',
    R.map((item: Object) => {
      if (R.equals(R.prop('internalId', item), id)) {
        return P.$set('expanded', R.not(R.prop('expanded', item)), item);
      }
      return item;
    }, state.draftList),
    state,
  )
);

const setDraftListLoading = (state: Object, data: boolean) => (
  P.$set('draftListLoading', data, state)
);

const setCurrentRouteInitialState = (state: Object) => (
  P.$all(
    P.$set('currentRoute', state.currentRouteInitialState),
    P.$set('currentRouteItems', state.currentRouteInitialItems),
    P.$set('cloList', H.resetCloListToInitialRoute(state.currentRouteInitialState, state.cloList)),
    // TODO: set crossDockCloList
    state,
  )
);

const deleteDraft = (state: Object, data: string) => (
  P.$all(
    P.$set('draftsTotal', R.dec(state.draftsTotal)),
    P.$set('draftList', R.filter(({ draftGuid }: Object) => G.notEquals(draftGuid, data), state.draftList)),
    state,
  )
);

const setRouteErrors = (state: Object, data: any) => (
  P.$set('routeErrors', data, state)
);

const addNewRateToRouteBuilderTel = (state: Object, data: Object) => (
  P.$set(`currentRoute.tels.${data.telGuid}.rate`, data.rate, state)
);

const cleanTelRate = (state: Object, guid: Object) => (
  P.$set(`currentRoute.tels.${guid}.rate`, null, state)
);

const getCrossDockLocationsSuccess = (state: Object, data: Array = []) => (
  P.$set('crossDockLocationList', R.indexBy(R.prop(GC.FIELD_TEMPLATE_ID), data), state)
);

const setInboundTerminals = (state: Object, data: Array) => (
  P.$set('inboundTerminals', data, state)
);

const setOutboundTerminals = (state: Object, data: Array) => (
  P.$set('outboundTerminals', data, state)
);

const removeTelFromPlanner = (state: Object, guid: string) => H.removeTelFromPlanner(state, guid);

const setPlannerMode = (state: Object, mode: string) => (
  P.$set('plannerMode', mode, state)
);

const addDropContainersToStop = (state: Object, data: Object) => {
  const { telGuid, eventGuid, droppedContainers, droppedContainerIds } = data;

  return P.$all(
    P.$set(`currentRoute.tels.${telGuid}.events.${eventGuid}.droppedContainers`, droppedContainers),
    P.$set(`currentRoute.tels.${telGuid}.events.${eventGuid}.droppedContainerIds`, droppedContainerIds),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.droppedContainers`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'droppedContainers'], state),
        droppedContainers,
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.droppedContainerIds`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'droppedContainerIds'], state),
        droppedContainerIds,
      ),
    ),
    state,
  );
};

const addPickupContainersToStop = (state: Object, data: Object) => {
  const { telGuid, eventGuid, pickedUpContainers, pickedUpContainerIds } = data;

  return P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainers`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'pickedUpContainers'], state),
        pickedUpContainers,
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainerIds`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, 'pickedUpContainerIds'], state),
        pickedUpContainerIds,
      ),
    ),
    state,
  );
};

const addNewContainerToStop = (state: Object, data: Object) => {
  const { telGuid, eventGuid, container } = data;
  const currentRouteContainers = R.assoc(
    R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
    container,
    state.currentRouteContainers,
  );

  return P.$all(
    P.$set('currentRouteContainers', currentRouteContainers),
    P.$add(`currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainers`, container),
    P.$add(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.pickedUpContainerIds`,
      R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, container),
    ),
    state,
  );
};

const addTrailersToStop = (state: Object, data: Object) => {
  const { tel, event, guids, trailers, pickedUp } = data;

  const telGuid = G.getGuidFromObject(tel);
  const eventGuid = G.getGuidFromObject(event);

  const trailersProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailers', 'droppedTrailers');
  const trailerGuidsProp = G.ifElse(G.isTrue(pickedUp), 'pickedUpTrailerGuids', 'droppedTrailerGuids');

  return P.$all(
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.${trailersProp}`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, trailersProp], state),
        trailers,
      ),
    ),
    P.$set(
      `currentRoute.tels.${telGuid}.events.${eventGuid}.${trailerGuidsProp}`,
      R.concat(
        R.path(['currentRoute', 'tels', telGuid, 'events', eventGuid, trailerGuidsProp], state),
        guids,
      ),
    ),
    state,
  );
};

const resetAllListsAndPaginations = (state: Object) => (
  P.$all(
    P.$set('cloList', []),
    P.$set('telList', []),
    P.$set('inboundTelList', []),
    P.$set('outboundTelList', []),
    P.$set('crossDockCloList', []),
    P.$set('cloPagination', defaultPagination),
    P.$set('telPagination', defaultPagination),
    P.$set('inboundTelPagination', defaultPagination),
    P.$set('outboundTelPagination', defaultPagination),
    P.$set('crossDockCloPagination', defaultPagination),
    state,
  )
);

const saveMultipleCarrierRates = (state: Object, { rates, telGuid, dispatchType, deletedRateGuids }: Object) => {
  const currentDeletedRates = P.$get(`currentRoute.tels.${telGuid}.deletedRateGuids`, state);

  return P.$all(
    P.$set(`currentRoute.tels.${telGuid}.carrierRates`, rates),
    P.$set(`currentRoute.tels.${telGuid}.${GC.FIELD_DISPATCH_TYPE}`, dispatchType),
    P.$set(`currentRoute.tels.${telGuid}.deletedRateGuids`, R.concat(deletedRateGuids, R.or(currentDeletedRates, []))),
    state,
  );
};

export default createReducer({
  [A.editItem]: editItem,
  [A.selectClo]: selectClo,
  [A.selectTel]: selectTel,
  [A.toggleClo]: toggleClo,
  [A.toggleTel]: toggleTel,
  [A.setCloList]: setCloList,
  [A.setTelList]: setTelList,
  [A.cleanDrafts]: cleanDrafts,
  [A.selectDraft]: selectDraft,
  [A.toggleDraft]: toggleDraft,
  [A.deleteDraft]: deleteDraft,
  [A.cleanBuilder]: cleanBuilder,
  [A.cleanTelRate]: cleanTelRate,
  [A.setTelDetails]: setTelDetails,
  [A.sortTelEvents]: sortTelEvents,
  [A.setRouteErrors]: setRouteErrors,
  [A.addNewItemOnUI]: addNewItemOnUI,
  [A.setPlannerMode]: setPlannerMode,
  [A.addItemsForDrop]: addItemsForDrop,
  [A.setCurrentRoute]: setCurrentRoute,
  [A.updateContainer]: updateContainer,
  [A.prependTelToList]: prependTelToList,
  [A.addTrailersToStop]: addTrailersToStop,
  [A.addItemsForPickup]: addItemsForPickup,
  [A.setCloListLoading]: setCloListLoading,
  [A.setTelListLoading]: setTelListLoading,
  [A.setAvailableItems]: setAvailableItems,
  [A.setInboundTelList]: setInboundTelList,
  [A.setOpenedFromPage]: setOpenedFromPage,
  [A.resetCloPagination]: resetCloPagination,
  [A.setBottomTableMode]: setBottomTableMode,
  [A.resetTelPagination]: resetTelPagination,
  [A.setSelectedCloList]: setSelectedCloList,
  [A.setSelectedTelList]: setSelectedTelList,
  [A.removeItemFromStop]: removeItemFromStop,
  [A.setOutboundTelList]: setOutboundTelList,
  [A.getDraftListSuccess]: getDraftListSuccess,
  [A.setCurrentTelReport]: setCurrentTelReport,
  [A.setDraftListLoading]: setDraftListLoading,
  [A.addItemsClosForDrop]: addItemsClosForDrop,
  [A.setInitialRouteGuid]: setInitialRouteGuid,
  [A.setCurrentCloReport]: setCurrentCloReport,
  [A.cleanCloQuickFilter]: cleanCloQuickFilter,
  [A.cleanTelQuickFilter]: cleanTelQuickFilter,
  [A.selectTelOnAllLists]: selectTelOnAllLists,
  [A.setInboundTerminals]: setInboundTerminals,
  [A.setEditTemplateRoute]: setEditTemplateRoute,
  [A.getCloDetailsSuccess]: getCloDetailsSuccess,
  [A.setOutboundTerminals]: setOutboundTerminals,
  [A.removeTelFromPlanner]: removeTelFromPlanner,
  [A.setCloTableTitleSort]: setCloTableTitleSort,
  [A.setTelTableTitleSort]: setTelTableTitleSort,
  [A.addItemsClosForPickup]: addItemsClosForPickup,
  [A.removeCloItemFromStop]: removeCloItemFromStop,
  [A.addNewContainerToStop]: addNewContainerToStop,
  [A.removeTrailerFromStop]: removeTrailerFromStop,
  [A.setPreviousRouteState]: setPreviousRouteState,
  [A.setCloTableTitleFilter]: setCloTableTitleFilter,
  [A.setTelTableTitleFilter]: setTelTableTitleFilter,
  [A.setAvailableCloReports]: setAvailableCloReports,
  [A.setAvailableTelReports]: setAvailableTelReports,
  [A.selectCloForTelSuccess]: selectCloForTelSuccess,
  [A.setInitialCurrentRoute]: setInitialCurrentRoute,
  [A.setAvailableContainers]: setAvailableContainers,
  [A.setCloQuickFilterParams]: setCloQuickFilterParams,
  [A.setTelQuickFilterParams]: setTelQuickFilterParams,
  [A.addNewTelToRouteBuilder]: addNewTelToRouteBuilder,
  [A.addExistedTerminalToTel]: addExistedTerminalToTel,
  [A.setBranchGuidForRequest]: setBranchGuidForRequest,
  [A.removeContainerFromStop]: removeContainerFromStop,
  [A.addDropContainersToStop]: addDropContainersToStop,
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [A.setInboundTelListLoading]: setInboundTelListLoading,
  [A.saveMultipleCarrierRates]: saveMultipleCarrierRates,
  [A.setOutboundTelListLoading]: setOutboundTelListLoading,
  [A.addPickupContainersToStop]: addPickupContainersToStop,
  [A.uncheckCloAndRemoveEvents]: uncheckCloAndRemoveEvents,
  [A.resetCloListAndPagination]: resetCloListAndPagination,
  [A.resetTelListAndPagination]: resetTelListAndPagination,
  [A.removeTelFromRouteBuilder]: removeTelFromRouteBuilder,
  [A.setCurrentInboundTelReport]: setCurrentInboundTelReport,
  [A.resetAllListsAndPaginations]: resetAllListsAndPaginations,
  [A.setCurrentRouteInitialState]: setCurrentRouteInitialState,
  [A.addNewRateToRouteBuilderTel]: addNewRateToRouteBuilderTel,
  [A.addNewStopToRouteBuilderTel]: addNewStopToRouteBuilderTel,
  [A.setRouteBuilderInitialState]: setRouteBuilderInitialState,
  [A.setCurrentOutboundTelReport]: setCurrentOutboundTelReport,
  [A.setInboundTelTableTitleSort]: setInboundTelTableTitleSort,
  [A.setOutboundTelTableTitleSort]: setOutboundTelTableTitleSort,
  [A.addTerminalToRouteBuilderTel]: addTerminalToRouteBuilderTel,
  [A.setCurrentTelReportWithRoute]: setCurrentTelReportWithRoute,
  [A.getCrossDockLocationsSuccess]: getCrossDockLocationsSuccess,
  [A.setInboundTelTableTitleFilter]: setInboundTelTableTitleFilter,
  [A.removeStopFromRouteBuilderTel]: removeStopFromRouteBuilderTel,
  [A.setOutboundTelTableTitleFilter]: setOutboundTelTableTitleFilter,
  [A.recalculateTelDistancesSuccess]: recalculateTelDistancesSuccess,
  [A.addNewCloStopToRouteBuilderTel]: addNewCloStopToRouteBuilderTel,
  [A.resetInboundTelListAndPagination]: resetInboundTelListAndPagination,
  [A.resetOutboundTelListAndPagination]: resetOutboundTelListAndPagination,
  [A.updateStopOnRouteBuilderTelSuccess]: updateStopOnRouteBuilderTelSuccess,
  [A.recalculateTelDistancesWithMoveItemSuccess]: recalculateTelDistancesWithMoveItemSuccess,
  // cross dock clo
  [A.toggleCrossDockClo]: toggleCrossDockClo,
  [A.uncheckCrossDockClo]: uncheckCrossDockClo,
  [A.setCrossDockCloList]: setCrossDockCloList,
  [A.uncheckCrossDockClos]: uncheckCrossDockClos,
  [A.selectCrossDockCloSuccess]: selectCrossDockCloSuccess,
  [A.setCrossDockCloListLoading]: setCrossDockCloListLoading,
  [A.setSelectedCrossDockCloList]: setSelectedCrossDockCloList,
  [A.resetCrossDockCloPagination]: resetCrossDockCloPagination,
  [A.setCurrentCrossDockCloReport]: setCurrentCrossDockCloReport,
  [A.cleanCrossDockCloQuickFilter]: cleanCrossDockCloQuickFilter,
  [A.setCrossDockCloTableTitleSort]: setCrossDockCloTableTitleSort,
  [A.setCrossDockCloTableTitleFilter]: setCrossDockCloTableTitleFilter,
  [A.setCrossDockCloQuickFilterParams]: setCrossDockCloQuickFilterParams,
  [A.resetCrossDockCloListAndPagination]: resetCrossDockCloListAndPagination,
}, initialState);
