import React from 'react';
import * as R from 'ramda';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import {
  InfoPair,
  Switcher,
  FormFooter2,
  TextComponent,
  InputWithHandlers,
} from '../../../components';
// features
import CarrierRateForm, { EditCarrierRateForm } from '../../rate/carrier';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withDnDStartEnd } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedBox } from '../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const switcherOptions = [
  {
    width: 55,
    value: GC.DISPATCH_TYPE_COMMIT,
    name: G.getWindowLocale('titles:commit', 'Commit'),
  },
  {
    width: 55,
    value: GC.DISPATCH_TYPE_ROLL,
    name: G.getWindowLocale('titles:roll', 'Roll'),
  },
];

const switcherIndexMap = {
  [GC.DISPATCH_TYPE_COMMIT]: 0,
  [GC.DISPATCH_TYPE_ROLL]: 1,
};

const Rate = (props: Object) => {
  const {
    index,
    provided,
    currency,
    isCommit,
    handleEditRate,
    commitmentCount,
    handleRemoveRate,
    carrierAssignment,
    carrierRateCharges,
    handleSetCommitments,
  } = props;

  const {
    name = '-',
  } = carrierAssignment;

  const currencySymbol = G.getCurrencySymbol(currency);
  const total = G.getCalcTelRateChargesTotal(carrierRateCharges, currency);

  return (
    <Flex
      p='8px'
      my='4px'
      width={470}
      borderRadius='4px'
      boxShadow={`0 2px 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
    >
      <div {...provided.dragHandleProps}>
        {I.dnd(darkBlueColor, 20, 20)}
      </div>
      <TextComponent
        mx={10}
        title={name}
        fontSize={12}
        maxWidth={140}
        fontWeight='bold'
        color={textColor}
        withEllipsis={true}
      >
        {name}
      </TextComponent>
      <InfoPair
        width='auto'
        textMaxWidth={70}
        lineHeight='unset'
        text={`${currencySymbol} ${G.toFixed(total)}`}
        label={G.getWindowLocale('titles:total', 'Total')}
      />
      {
        isCommit &&
        <Flex ml='auto' fontSize={12}>
          <Box>{G.getWindowLocale('titles:commitments', 'Commitments')}</Box>
          <InputWithHandlers
            styles={{
              ml: '6px',
              width: 30,
              p: '0 3px',
              height: 24,
              border: '1px solid',
              borderRadius: '4px',
            }}
            value={commitmentCount || ''}
            inputBorderColor={G.getTheme('colors.dark.grey')}
            onChange={(value: string) => handleSetCommitments(value, index)}
          />
        </Flex>
      }
      <Box
        mr='6px'
        cursor='pointer'
        onClick={() => handleEditRate(index)}
        ml={G.ifElse(isCommit, '6px', 'auto')}
      >
        {I.pencil()}
      </Box>
      <Box
        cursor='pointer'
        onClick={() => handleRemoveRate(index)}
      >
        {I.trash()}
      </Box>
    </Flex>
  );
};

const Rates = (props: Object) => {
  const {
    rates,
    dispatchType,
    dndProcessing,
    handleEditRate,
    handleRemoveRate,
    handleSetCommitments,
  } = props;

  const isCommit = R.equals(dispatchType, GC.DISPATCH_TYPE_COMMIT);
  const border = G.ifElse(G.isTrue(dndProcessing), '1px dashed grey', 'none');

  return (
    <Flex
      px={15}
      border={border}
      flexDirection='column'
      minHeight={R.multiply(70, R.length(rates))}
    >
      {
        rates.map((rate: Object, index: number) => (
          <Draggable
            index={index}
            key={rate.draggableId}
            draggableId={rate.draggableId}
          >
            {(provided: Object, snapshot: Object) => (
              <div>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{ ...provided.draggableProps.style, position: snapshot.isDragging && 'static' }}
                >
                  <Rate
                    {...rate}
                    index={index}
                    provided={provided}
                    isCommit={isCommit}
                    handleEditRate={handleEditRate}
                    handleRemoveRate={handleRemoveRate}
                    handleSetCommitments={handleSetCommitments}
                  />
                </div>
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        ))
      }
    </Flex>
  );
};

const enhance = compose(
  withState('dispatchType', 'setDispatchType', ({ tel }: Object) => R.propOr(
    GC.DISPATCH_TYPE_COMMIT,
    GC.FIELD_DISPATCH_TYPE,
    tel,
  )),
  withState('rates', 'setRates', ({ tel: { rates, carrierRates } }: Object) => R.compose(
    G.mapIndexed((rate: Object, i: number) => ({
      ...rate,
      draggableId: `rate-list-item-${i}`,
      commitmentCount: R.propOr('', GC.FIELD_COMMITMENT_COUNT, rate),
    })),
    R.filter((rate: Object) => G.isNotNil(R.prop(GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, rate))),
  )(carrierRates || rates || [])),
  withState('deletedRateGuids', 'setDeletedRateGuids', []),
  withDnDStartEnd,
  withHandlers({
    onDragEnd: (props: Object) => (result: Object) => {
      const { rates, setRates, handleDragEnd } = props;

      const startIndex = R.path(['source', 'index'], result);
      const endIndex = R.path(['destination', 'index'], result);

      if (R.and(result.destination, G.notEquals(startIndex, endIndex))) {
        const item = R.path([startIndex], rates);

        const newList = R.compose(
          R.insert(endIndex, item),
          R.remove(startIndex, 1),
        )(rates);

        setRates(newList);
      }

      handleDragEnd(result);
    },
    handleSaveRates: (props: Object) => () => {
      const {
        tel,
        rates,
        saveRates,
        closeModal,
        dispatchType,
        deletedRateGuids,
      } = props;

      const isCommit = R.equals(dispatchType, GC.DISPATCH_TYPE_COMMIT);

      const ratesToSave = G.mapIndexed(
        (rate: Object, i: number) => {
          const data = R.assoc('sequence', i, rate);

          if (isCommit) {
            const { commitmentCount } = data;

            const count = R.or(R.equals(commitmentCount, '0'), R.isEmpty(commitmentCount)) ? null : commitmentCount;

            return R.assoc(GC.FIELD_COMMITMENT_COUNT, count, data);
          }

          return R.dissoc(GC.FIELD_COMMITMENT_COUNT, data);
        },
        rates,
      );

      saveRates({
        dispatchType,
        deletedRateGuids,
        rates: ratesToSave,
        telGuid: G.getGuidFromObject(tel),
      });

      closeModal();
    },
    handleAddRate: (props: Object) => () => {
      const {
        tel,
        rates,
        setRates,
        loadData,
        openModal,
        closeModal,
      } = props;

      const stops = R.compose(
        R.values,
        R.pathOr({}, [GC.FIELD_LOAD_STOPS]),
      )(tel);

      const handleSaveCarrierRate = (rate: Object) => {
        setRates(R.append({
          ...rate,
          draggableId: `rate-list-item-${R.length(rates)}`,
        }, rates));

        closeModal();
      };

      const modalContent = (
        <CarrierRateForm
          stops={stops}
          loadData={loadData}
          stopCount={R.length(stops)}
          telGuid={G.getGuidFromObject(tel)}
          handleSendTelRate={handleSaveCarrierRate}
          configsNamesArray={GC.ADD_CARRIER_RATE_CONFIGS_ARRAY}
          branchGuid={G.getPropFromObject(GC.FIELD_BRANCH_GUID, tel)}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleEditRate: (props: Object) => (index: number) => {
      const {
        tel,
        rates,
        setRates,
        loadData,
        openModal,
        closeModal,
      } = props;

      const rate = R.prop(index, rates);
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, tel);
      const initialValues = R.assoc(GC.FIELD_TEL_GUID, telGuid, rate);

      const handleSaveCarrierRate = (data: Object) => {
        setRates(R.assoc(index, R.mergeRight(rate, data), rates));

        closeModal();
      };

      const modalContent = (
        <EditCarrierRateForm
          telGuid={telGuid}
          loadData={loadData}
          branchGuid={branchGuid}
          initialValues={initialValues}
          handleSendTelRate={handleSaveCarrierRate}
          configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleRemoveRate: (props: Object) => (index: number) => {
      const {
        rates,
        setRates,
        deletedRateGuids,
        setDeletedRateGuids,
      } = props;

      setRates(R.remove(index, 1, rates));

      const rateGuid = R.path([index, GC.FIELD_GUID], rates);

      if (G.isNotNil(rateGuid)) setDeletedRateGuids(R.append(rateGuid, deletedRateGuids));
    },
    handleSetCommitments: (props: Object) => (value: string, index: number) => {
      const { rates, setRates } = props;

      const rate = R.prop(index, rates);

      const valueToSet = isNaN(value) ? rate.commitmentCount : value;

      setRates(R.assocPath([index, GC.FIELD_COMMITMENT_COUNT], valueToSet, rates));
    },
  }),
  pure,
);

const SortableRates = enhance((props: Object) => {
  const {
    rates,
    onDragEnd,
    dispatchType,
    handleAddRate,
    handleDragStart,
    handleSaveRates,
    setDispatchType,
  } = props;

  return (
    <Flex flexDirection='column' height='-webkit-fill-available'>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={(result: Object) => onDragEnd(result, props)}>
        <StickedBox
          p={15}
          top='0'
          width='100%'
          bg={whiteColor}
        >
          <Flex width='100%' cursor='pointer'>
            <Flex onClick={handleAddRate}>
              <Box mr={10}>{G.getWindowLocale('titles:add-rate', 'Add Rate')}</Box>
              {I.plusRound()}
            </Flex>
            <Switcher
              ml='auto'
              version={3}
              options={switcherOptions}
              onSwitch={(type: string) => setDispatchType(type)}
              selectedOptionIndex={switcherIndexMap[dispatchType]}
            />
          </Flex>
        </StickedBox>
        {
          G.isNotEmpty(rates) &&
          <Droppable droppableId='multiRates'>
            {(provided: Object, snapshot: Object) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Rates snapshot={snapshot} {...props} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        }
        <FormFooter2
          submitAction={handleSaveRates}
          submitBtnText={G.getWindowLocale('actions:save', 'Save')}
          boxStyles={{
            p: 15,
            mt: 'auto',
            bottom: '0',
            bg: whiteColor,
            position: 'sticky',
          }}
        />
      </DragDropContext>
    </Flex>
  );
});

export default SortableRates;
