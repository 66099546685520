import * as R from 'ramda';
import React from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// common
import { createLoadDocumentsRequest } from '../../../../common/actions';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import DispatchCardComponent from '../../../dispatch-details-new/components/cards/dispatch';
import DocumentsCardComponent from '../../../dispatch-details-new/components/cards/documents';
import { OrderPaymentsCardComponent } from '../../../dispatch-details-new/components/cards/payments';
import { LeftHeaderComponent } from '../../../dispatch-details-new/order/hocs/with-order-cards-props';
import {
  withOrderAddDocumentWithoutConnect,
  withOrderDocumentActionsWithoutConnect } from '../../../dispatch-details-new/order/hocs/with-document-actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, Grid, ActionButton } from '../../../../ui';
// feature dispatch-board-new/order
import { makeSelectUseBillToContactEmail } from '../../selectors';
import {
  updateOrderDocumentRequest,
  removeOrderDocumentRequest,
  getOrderDocumentListRequest,
  downloadOrderDocumentRequest } from '../actions';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const Card = ({ children }: Object) => (
  <Box
    width='100%'
    height={436}
    borderRadius='4px'
    background={whiteColor}
    boxShadow='0 0 5px 0 rgba(0, 0, 0, 0.2)'
  >
    {children}
  </Box>
);

const CardHeader = ({ children }: Object) => (
  <Flex
    px='8px'
    height={36}
    borderTopLeftRadius='4px'
    borderTopRightRadius='4px'
    justifyContent='space-between'
    background={G.getTheme('colors.lightGreenHalfOpacity')}
  >
    {children}
  </Flex>
);

const documentsMapStateToProps = (state: Object) => createStructuredSelector({
  useBillToContactEmail: makeSelectUseBillToContactEmail(state),
});

const enhanceDocumentsCard = compose(
  connect(
    documentsMapStateToProps,
    {
      updateOrderDocumentRequest,
      removeOrderDocumentRequest,
      createLoadDocumentsRequest,
      getOrderDocumentListRequest,
      downloadOrderDocumentRequest,
    },
  ),
  withOrderDocumentActionsWithoutConnect,
);

const headerActionButtonStyles = {
  px: '8px',
  height: 20,
  fontSize: 12,
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
};

const DocumentsHeaderActionButton = withOrderAddDocumentWithoutConnect(({ handleAddDocument }: Object) => (
  <AuthWrapper has={[PC.CLO_DOCUMENT_WRITE]}>
    <ActionButton {...headerActionButtonStyles} type='button' onClick={handleAddDocument}>
      {G.getWindowLocale('titles:add-document', 'Add Document')}
    </ActionButton>
  </AuthWrapper>
));

const DocumentsCard = enhanceDocumentsCard((props: Object) => (
  <Card>
    <CardHeader>
      <LeftHeaderComponent
        iconName='file'
        text={G.getWindowLocale('titles:documents', 'Documents')}
      />
      <DocumentsHeaderActionButton {...props} />
    </CardHeader>
    <DocumentsCardComponent {...props} />
  </Card>
));

const Cards = (props: Object) => {
  const {
    load,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    loadConfigs,
  } = props;

  const { invoices, documents, selectedRate, invoiceTotals } = load;

  const configCurrency = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY);

  const autodialApp = G.getConfigValueFromStore(
    GC.UI_LOADBOARD_AUTODIALAPPNAME,
    R.path(['configGroups', GC.UI_CONFIG_GROUP, 'configs'], loadConfigs),
  );

  const commonComponentProps = {
    load,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
  };

  const documentsProps = {
    ...commonComponentProps,
    documents: R.or(documents, []),
    mailPermissions: [PC.SEND_EMAIL_EXECUTE],
    documentPermissions: [PC.CLO_DOCUMENT_WRITE],
  };

  const paymentPermissions = [
    PC.CLO_RATE_READ,
    PC.CLO_RATE_WRITE,
    PC.CLO_BILL_TO_READ,
    PC.CLO_BILL_TO_WRITE,
  ];

  return (
    <Grid
      gridGap={20}
      gridTemplateColumns='repeat(auto-fill, minmax(320px, 1fr))'
    >
      <AuthWrapper width='100%' has={paymentPermissions}>
        <Card>
          <CardHeader>
            <LeftHeaderComponent
              iconName='dollar'
              text={G.getWindowLocale('titles:payments', 'Payments')}
            />
          </CardHeader>
          <OrderPaymentsCardComponent
            load={load}
            addDisabled={true}
            invoices={invoices}
            editDisabled={true}
            autodialApp={autodialApp}
            selectedRate={selectedRate}
            invoiceTotals={invoiceTotals}
            configCurrency={configCurrency}
          />
        </Card>
      </AuthWrapper>
      <AuthWrapper
        width='100%'
        has={[PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]}
      >
        <Card>
          <CardHeader>
            <LeftHeaderComponent
              iconName='dispatch'
              text={G.getWindowLocale('titles:dispatch', 'Dispatch')}
            />
          </CardHeader>
          <DispatchCardComponent
            autodialApp={autodialApp}
            showAcceptDeclineSection={false}
            loadGuid={G.getGuidFromObject(load)}
            selectedCarrierDriverRate={R.path([GC.SYSTEM_LIST_TELS, 0, GC.SYSTEM_OBJECT_RATE], load)}
          />
        </Card>
      </AuthWrapper>
      <AuthWrapper width='100%' has={[PC.CLO_DOCUMENT_READ, PC.CLO_DOCUMENT_WRITE]}>
        <DocumentsCard {...documentsProps} />
      </AuthWrapper>
    </Grid>
  );
};

export default Cards;
