// helpers/constants
import * as GC from '../../constants';
// report-common
import { getFilterParamsFromColumnSettings } from '../../report-common';
//////////////////////////////////////////////////

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  allowSelectAll: false,
  expandableItems: false,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

export const columnSettings = {
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    useExpandedContainer: true,
    guidPropName: GC.FIELD_TEL_GUID,
    name: 'titles:primary-ref-value',
    type: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
  },
  [GC.FIELD_TERMINAL_NAME]: {
    name: 'titles:terminal-name',
  },
  [GC.BRANCH_NAME]: {
    width: 200,
    name: 'titles:branch-name',
  },
  [`${GC.FIELD_MODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: 'titles:mode-of-transportation',
  },
  [GC.FIELD_REQUESTED_BY]: {
    width: 200,
    searchable: false,
    name: 'titles:requested-by',
  },
  [GC.FIELD_REQUESTED_DATE_TIME]: {
    type: 'date',
    name: 'titles:requested-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_EXPIRATION_DATE_TIME]: {
    type: 'date',
    name: 'titles:expiration-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_EMAIL_TO]: {
    width: 200,
    name: 'titles:email-to',
  },
  [GC.FIELD_MAIL_SENDING_REPLY_TO]: {
    width: 200,
    name: 'titles:reply-to',
  },
  [`${GC.FIELD_DECLINE_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: 'titles:decline-reason',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    searchable: false,
    name: 'titles:comments',
  },
};

export const FILTER_PARAMS = getFilterParamsFromColumnSettings(columnSettings);
