import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// common
import { globalCleanReports } from '../../common/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import * as GC from '../../constants';
// feature customer-portal
import { setInitialState, getCustomerPortalListRequest, refreshCustomerPortalPageRequest } from './actions';
//////////////////////////////////////////////////

const handleGetCustomerPortalListLogic = createLogic({
  type: 'GET_CUSTOMER_PORTAL_LIST',
  validate({ action }: Object, allow: void) {
    allow(action);
  },
  process(_: any, dispatch: void) {
    dispatch(getCustomerPortalListRequest({ refreshPage: true, startInterval: true }));
  },
});

const handleRefreshCustomerPortalLogic = createLogic({
  warnTimeout: 0,
  cancelType: GC.LOCATION_CHANGE_ACTION,
  type: refreshCustomerPortalPageRequest,
  validate({ action }: Object, allow: void) {
    allow(action);
  },
  process({ cancelled$ }: Object, dispatch: void) {
    const interval = setInterval(() => {
      dispatch({ type: 'GET_CUSTOMER_PORTAL_LIST', payload: { refreshPage: true } });
    }, 1200000);

    cancelled$.subscribe(() => {
      clearInterval(interval);
    });
  },
});

const handleChangeLocationLogic = createLogic({
  type: GC.LOCATION_CHANGE_ACTION,
  validate({ action }: Object, allow: void, reject: void) {
    if (R.pathEq('#close', ['location', 'hash'], window)) {
      reject();
    } else {
      allow(action);
    }
  },
  process(_: any, dispatch: void, done: void) {
    dispatch(setInitialState());
    done();
  },
});

const handleSwitchBranchLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    receivedSwitchBranchSuccess,
  ],
  validate({ action }: Object, allow: void) {
    allow(action);
  },
  process(_: any, dispatch: void, done: void) {
    dispatch(setInitialState());
    done();
  },
});

export default [handleSwitchBranchLogic, handleChangeLocationLogic];

