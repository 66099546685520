import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../../../components/text';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { StyledLink } from '../../../../../ui';
// feature fleet-driver
import { NoteRowActions, DocumentRowActions, SafetyViolationRowActions } from './row-actions';
//////////////////////////////////////////////////

const systemFields = {
  [GC.FIELD_CREATED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const TableCell = ({ text }: Object) => {
  if (G.isNilOrEmpty(text)) return null;

  return (
    <TextComponent
      title={text}
      maxWidth='90%'
      display='block'
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};

export const safetyViolationColumnSettings = {
  actions: {
    width: 150,
    name: 'actions',
    value: 'actions',
    customComponent: ({ data, callbackData }: Object) => <SafetyViolationRowActions {...callbackData} entity={data} />,
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    name: 'titles:type',
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_TYPE, GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  [GC.FIELD_SEVERITY]: {
    width: 200,
    name: 'titles:severity',
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_SEVERITY, GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  occurrenceDate: {
    width: 150,
    type: 'date',
    name: 'titles:occurrence-date',
  },
  disciplinaryAction: {
    width: 200,
    name: 'titles:disciplinary-action',
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', 'disciplinaryAction', GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  disciplinaryDate: {
    width: 150,
    type: 'date',
    name: 'titles:disciplinary-date',
  },
  eventDescription: {
    width: 400,
    name: 'titles:event-description',
  },
  ...systemFields,
};

export const documentColumnSettings = {
  actions: {
    width: 150,
    name: 'actions',
    value: 'actions',
    customComponent: ({ data, callbackData }: Object) => <DocumentRowActions {...callbackData} entity={data} />,
  },
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 300,
    name: 'titles:document-name',
    customComponent: ({ data }: Object) => {
      const url = G.getPropFromObject(GC.FIELD_DOCUMENT_URL, data);
      const documentFileName = G.getPropFromObject(GC.FIELD_DOCUMENT_FILE_NAME, data);
      const title = R.or(documentFileName, url);

      return (
        <TextComponent title={title} display='block' maxWidth='90%' withEllipsis={true}>
          {
            G.isNotNilAndNotEmpty(url) &&
            <StyledLink
              target='_blank'
              textDecoration='underline'
              href={G.makeURLString(url)}
              color={G.getTheme('colors.light.blue')}
            >
              {url}
            </StyledLink>
          }
          {G.isNotNilAndNotEmpty(documentFileName) && documentFileName}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    width: 250,
    name: 'titles:document-type',
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  ...systemFields,
};

export const noteColumnSettings = {
  actions: {
    width: 90,
    name: 'actions',
    value: 'actions',
    customComponent: ({ data, callbackData }: Object) => <NoteRowActions {...callbackData} entity={data} />,
  },
  authorType: {
    width: 200,
    type: 'enum',
    name: 'titles:author-type',
  },
  [GC.FIELD_TEXT]: {
    width: 300,
    name: 'titles:subject',
  },
  ...systemFields,
};
