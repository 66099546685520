import * as R from 'ramda';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, ActionButton } from '../../../../ui';
// fleet-profile
import { approveOrDeclineDriverExpenseRequest } from '../../actions';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');
const greyColor = G.getTheme('colors.greyMatterhorn');

const getButtonStyles = (color: string) => ({
  mr: '4px',
  px: '6px',
  fontSize: 12,
  height: 'auto',
  textColor: color,
  minWidth: 'unset',
  borderRadius: '5px',
  bgColor: whiteColor,
  border: `1px solid ${color}`,
});

const ApproveDeclineExpense = memo((props: Object) => {
  const { guid, status, payrollGuid, requirableType, vendorPayrollGuid } = props;

  const dispatch = useDispatch();

  const handleApproveOrDeclineDriverExpenseRequest = ({ guid, type }: Object) =>
    dispatch(approveOrDeclineDriverExpenseRequest({ guid, type }));

  const hasPayroll = R.or(G.isNotNil(payrollGuid), G.isNotNil(vendorPayrollGuid));

  const showStatus = G.isAnyTrue(
    hasPayroll,
    R.isNil(requirableType),
    R.includes(status, [GC.FIELD_EXPENSE_STATUS_APPROVED, GC.FIELD_EXPENSE_STATUS_DECLINED]),
  );

  const showApprove = G.isAllTrue(
    R.not(hasPayroll),
    G.isNotNil(requirableType),
    R.includes(status, [GC.FIELD_EXPENSE_STATUS_PENDING, GC.FIELD_EXPENSE_STATUS_DECLINED]),
  );

  const showDecline = G.isAllTrue(
    R.not(hasPayroll),
    G.isNotNil(requirableType),
    R.includes(status, [GC.FIELD_EXPENSE_STATUS_PENDING, GC.FIELD_EXPENSE_STATUS_APPROVED]),
  );

  return (
    <Flex justifyContent='space-between'>
      {
        showStatus &&
        <Box>{G.getEnumLocale(status)}</Box>
      }
      {
        showApprove &&
        <ActionButton
          {...getButtonStyles(blueColor)}
          type='button'
          onClick={() => handleApproveOrDeclineDriverExpenseRequest({ guid, type: 'approve' })}
        >
          {G.getWindowLocale('titles:approve', 'Approve')}
        </ActionButton>
      }
      {
        showDecline &&
        <ActionButton
          {...getButtonStyles(greyColor)}
          type='button'
          onClick={() => handleApproveOrDeclineDriverExpenseRequest({ guid, type: 'decline' })}
        >
          {G.getWindowLocale('titles:decline', 'Decline')}
        </ActionButton>
      }
    </Flex>
  );
});

export default ApproveDeclineExpense;
