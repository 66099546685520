import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const store = (state: Object) => state.dispatchBoardTel;

const makeSelectGroupBy = () => createSelector(
  store,
  ({ groupBy }: Object) => groupBy,
);

const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => G.ifElse(
    R.isNil(itemList),
    R.of(Array, { groupingObject: '', loads: {} }),
    itemList,
  ),
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectGlobalFilterValue = () => createSelector(
  store,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectSummary = () => createSelector(
  store,
  ({ summary }: Object) => summary,
);

const makeSelectTelNotifications = () => createSelector(
  store,
  ({ telNotifications }: Object) => telNotifications,
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectShipUnitsExpanded = () => createSelector(
  store,
  ({ shipUnitsExpanded }: Object) => shipUnitsExpanded,
);

const makeSelectCloEventReferences = () => createSelector(
  store,
  ({ cloEventReferences }: Object) => cloEventReferences,
);

const makeSelectGlobalFilterType = () => createSelector(
  store,
  ({ globalFilterType }: Object) => globalFilterType,
);

export {
  makeSelectGroupBy,
  makeSelectSummary,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectGlobalFilterType,
  makeSelectTelNotifications,
  makeSelectGlobalFilterValue,
  makeSelectTableTitleFilters,
  makeSelectShipUnitsExpanded,
  makeSelectCloEventReferences,
};
