import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
import { openModal, closeModal, closeAllModals } from '../../../components/modal/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature dispatch-planner-events
import LoadInfo from './load-info';
import AddTelToPlannerForm from './add-tel-to-planner-form';
import CrossDockLocationForm from './cross-dock-location-form';
import SelectLoadsForTerminal from './select-loads-for-terminal';
import { getLoadName, getTerminalInitialDates } from '../helpers';
import { addNewTelToRouteBuilder, addTerminalToLoadPlannerTel } from '../actions';
import {
  makeSelectCurrentRoute,
  makeSelectRouteTelConfigs,
  makeSelectDefaultUomFields,
  makeSelectOptionsForSelect,
  makeSelectCrossDockLocationList } from '../selectors';
//////////////////////////////////////////////////

const createTelOptions = (currentRoute: Object) => R.compose(
  R.map((tel: Object) => ({ label: getLoadName(tel), value: R.prop(GC.FIELD_GUID, tel) })),
  R.values,
  R.propOr({}, GC.SYSTEM_LIST_TELS),
)(currentRoute);

const enhance = compose(
  withHandlers({
    handleAddTelWithTerminalDrop: (props: Oject) => (data: Object) => {
      const {
        openModal,
        closeModal,
        crossDocks,
        currentBranchGuid,
        addNewTelToRouteBuilder } = props;
      const telInfo = R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, data);
      const options = G.addEmptyOptionToDropDown(G.getLocationOptions(R.values(crossDocks)));
      const createLoadRequest = (values: Object) => {
        addNewTelToRouteBuilder({
          telInfo,
          terminalInfo: G.ifElse(G.isNilOrEmpty(R.prop(GC.FIELD_LOCATION, values)), null, values),
        });
        closeModal();
      };
      const component = (
        <AddTelToPlannerForm
          closeModal={closeModal}
          locationOptions={options}
          createLoadRequest={createLoadRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 650,
          height: 'auto',
          title: G.getWindowLocale('titles:tel-info', 'TEL Information'),
        },
      };
      openModal(modal);
    },
    handleAddTerminalToLoads: (props: Object) => (templateId: Object) => {
      const {
        openModal,
        closeModal,
        crossDocks,
        currentRoute,
        closeAllModals,
        addTerminalToLoadPlannerTel } = props;
      const tels = R.sortBy(
        R.prop('order'),
        R.values(currentRoute.tels),
      );
      if (R.equals(R.length(tels), 1)) {
        const returnObject = {
          dropTels: R.of(Array, R.prop(GC.FIELD_GUID, R.head(tels))),
        };
        const dates = getTerminalInitialDates(returnObject, R.prop(GC.SYSTEM_LIST_TELS, currentRoute));
        addTerminalToLoadPlannerTel({
          returnObject,
          [GC.FIELD_EVENT_TYPE]: GC.STOP_TYPE_TERMINAL,
          [GC.FIELD_LOCATION]: R.mergeRight(R.prop(templateId, crossDocks), dates),
        });
        closeModal();
        return;
      }
      const submitAction = (values: Object) => {
        const dates = getTerminalInitialDates(values, R.prop(GC.SYSTEM_LIST_TELS, currentRoute));
        addTerminalToLoadPlannerTel({
          returnObject: values,
          [GC.FIELD_EVENT_TYPE]: GC.STOP_TYPE_TERMINAL,
          [GC.FIELD_LOCATION]: R.mergeRight(R.prop(templateId, crossDocks), dates),
        });
        closeAllModals();
      };
      const component = (
        <SelectLoadsForTerminal
          closeModal={closeModal}
          handleSubmitAction={submitAction}
          options={createTelOptions(currentRoute)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          movable: true,
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:choose-tels-for-terminal', 'Please, choose TELs for Terminal'),
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handleAddNewTel: (props: Object) => (withTerminalDrop: boolean=false) => {
      const {
        openModal,
        closeModal,
        routeTelConfigs,
        currentBranchGuid,
        addNewTelToRouteBuilder,
        handleAddTelWithTerminalDrop,
      } = props;

      const { autoTelPrimaryRef } = routeTelConfigs;

      if (G.isTrue(autoTelPrimaryRef)) {
        const sequence = R.length(R.values(R.path(['currentRoute', 'tels'], props)));

        const telInfo = {
          [GC.FIELD_PRIMARY_REFERENCE]: { value: `${G.getWindowLocale('titles:tel', 'Trip')}-${R.inc(sequence)}` },
        };

        if (withTerminalDrop) {
          handleAddTelWithTerminalDrop(telInfo);
        } else {
          addNewTelToRouteBuilder({
            telInfo: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, telInfo),
          });
        }

        return;
      }

      const createLoadRequest = (data: Object) => {
        if (withTerminalDrop) {
          handleAddTelWithTerminalDrop(data);
        } else {
          addNewTelToRouteBuilder({
            telInfo: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, data),
          });
        }
      };

      const component = (
        <LoadInfo
          closeModal={closeModal}
          branchGuid={currentBranchGuid}
          scopeName={GC.REF_SCOPE_NAME_TEL}
          createLoadRequest={createLoadRequest} />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:tel-info', 'TEL Information'),
        },
      };

      openModal(modal);
    },
    handleAddTerminalFromCrossDock: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        crossDocks,
        currentRoute,
        handleAddTerminalToLoads } = props;
      if (G.isNilOrEmpty(R.path([GC.SYSTEM_LIST_TELS], currentRoute))) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('titles:create-tel-first', 'You need to create a TEL first'),
        );
      }
      const options = G.addEmptyOptionToDropDown(G.getLocationOptions(R.values(crossDocks)));
      const component = (
        <CrossDockLocationForm
          singleSelect={true}
          closeModal={closeModal}
          locationOptions={options}
          setLocations={handleAddTerminalToLoads} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-location', 'Select Locations'),
        },
      };
      openModal(modal);
    },
    handleOpenDriverCards: ({ setExpandedContainerOptions }: Object) => () => (
      setExpandedContainerOptions({
        opened: true,
        componentType: GC.PAGE_DRIVERS_CARD,
        options: { fromPage: GC.PAGE_DISPATCH_PLANNER_EVENTS },
      })
    ),
  }),
  pure,
);

const getOptions = ({ handleAddNewTel, handleOpenDriverCards, handleAddTerminalFromCrossDock }: Object) => {
  const iconColor = G.getTheme('colors.dark.blue');

  return [
    {
      action: handleAddNewTel,
      frontIcon: I.tel(null, null, null, iconColor),
      text: G.getWindowLocale('titles:add-tel', 'Add TEL'),
    },
    {
      frontIcon: I.tel(null, null, null, iconColor),
      action: () => handleAddNewTel(true),
      text: G.getWindowLocale('titles:add-tel-with-td', 'Add TEL With TD'),
    },
    {
      action: handleAddTerminalFromCrossDock,
      frontIcon: I.createTruckIcon(iconColor, 18, 18),
      text: G.getWindowLocale('titles:add-cross-dock-terminal', 'Add Cross Dock Terminal'),
    },
    {
      action: handleOpenDriverCards,
      frontIcon: I.driver(iconColor, 17, 18),
      text: G.getWindowLocale('titles:driver-cards', 'Driver Cards'),
    },
  ];
};

const PlannerActions = enhance((props: Object) => (
  <ActionsElement
    version={2}
    optionPadding='0px'
    optionLineHeight={1.2}
    options={getOptions(props)} />
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  currentRoute: makeSelectCurrentRoute(state),
  routeTelConfigs: makeSelectRouteTelConfigs(state),
  crossDocks: makeSelectCrossDockLocationList(state),
  defaultUomFields: makeSelectDefaultUomFields(state),
  optionsForSelect: makeSelectOptionsForSelect(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  closeAllModals,
  addNewTelToRouteBuilder,
  addTerminalToLoadPlannerTel,
  setExpandedContainerOptions,
})(PlannerActions);
