import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultFields = {
  [GC.FIELD_TEXT]: null,
  [GC.FIELD_PINNED]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 380,
};

const fieldSettings = [
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_PINNED,
    label: ['titles:pinned', 'Pinned'],
  },
  {
    isRequired: true,
    type: 'textarea',
    inputWrapperStyles,
    fieldName: GC.FIELD_TEXT,
    inputStyles: { height: 120 },
    label: ['titles:note', 'Note'],
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_TEXT]: G.yupStringRequired.max(1000, G.getShouldBeFromToCharLocaleTxt(1, 1000)),
});

const NoteForm = (props: Object) => {
  const { closeModal, initialValues, handleActionLoadNote } = props;

  const onSubmit = (values: Object) => {
    closeModal();
    handleActionLoadNote(values);
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(formik)}
        fields={fieldSettings}
        fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
      />
      <FormFooter2 />
    </form>
  );
};

export default NoteForm;
