import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_AVERAGE_FUEL_PRICE_PRICE]: {
    width: 160,
    searchable: true,
    name: 'titles:price',
    value: GC.FIELD_AVERAGE_FUEL_PRICE_PRICE,
  },
  [GC.FIELD_AVERAGE_FUEL_PRICE_PRICE_DATE]: {
    width: 220,
    searchable: true,
    filterType: 'date',
    name: 'titles:price-date',
  },
  [GC.FIELD_AVERAGE_FUEL_PRICE_DISTRICT]: {
    width: 220,
    searchable: true,
    name: 'titles:district',
    customComponent: ({ data }: Object) => R.pathOr(
      R.prop(GC.FIELD_AVERAGE_FUEL_PRICE_DISTRICT, data),
      [R.prop(GC.FIELD_AVERAGE_FUEL_PRICE_DISTRICT, data)],
      GC.districtMap,
    ),
  },
};

export const FILTER_PARAMS = {
  [GC.FIELD_AVERAGE_FUEL_PRICE_PRICE]: {
    type: 'number',
    value: GC.FIELD_AVERAGE_FUEL_PRICE_PRICE,
  },
  [GC.FIELD_AVERAGE_FUEL_PRICE_PRICE_DATE]: {
    type: 'date',
    value: GC.FIELD_AVERAGE_FUEL_PRICE_PRICE_DATE,
  },
  [GC.FIELD_AVERAGE_FUEL_PRICE_DISTRICT]: {
    type: 'string',
    value: GC.FIELD_AVERAGE_FUEL_PRICE_DISTRICT,
  },
};

export const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_AVERAGE_FUEL_PRICE_PRICE },
    { sequence: 3, name: GC.FIELD_AVERAGE_FUEL_PRICE_DISTRICT },
    { sequence: 2, name: GC.FIELD_AVERAGE_FUEL_PRICE_PRICE_DATE },
  ],
};
