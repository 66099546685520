import React from 'react';
import * as R from 'ramda';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getCloGlobalSearchOptions, getTelGlobalSearchOptions } from '../../../helpers/options';
// ui
import { Box, ReactSelect } from '../../../ui';
/////////////////////////////////////////////////

const GlobalSearchType = (props: Object) => {
  const {
    loadType,
    globalFilterType,
    usedGlobalSearchTypes,
    handleChangeGlobalFilterType,
  } = props;

  const getFullOptionsByLoadType = G.ifElse(
    G.isLoadTypeClo(loadType),
    getCloGlobalSearchOptions,
    getTelGlobalSearchOptions,
  )();
  const options = R.filter(({ value }: Object) => G.isAnyTrue(
    G.isNilOrEmpty(usedGlobalSearchTypes),
    R.includes(value, usedGlobalSearchTypes),
    R.equals(value, GC.GLOBAL_SEARCH_TYPE_ALL),
    R.includes(GC.GLOBAL_SEARCH_TYPE_ALL, usedGlobalSearchTypes),
  ), getFullOptionsByLoadType);

  return (
    <Box px={10} mb='4px' width={150}>
      <TextComponent mb='5px' ml={15} fontSize={9} color={G.getTheme('colors.light.black')}>
        {G.getWindowLocale('titles:search-by', 'Search By')}
      </TextComponent>
      <ReactSelect
        height={30}
        options={options}
        isClearable={false}
        value={globalFilterType}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        onChange={handleChangeGlobalFilterType}
        components={{ IndicatorSeparator: () => null }}
      />
    </Box>
  );
};

const enhance = compose(
  withHandlers({
    handleChangeGlobalFilterType: (props: Object) => (field: Object) => {
      const { globalFilterValue, setGlobalFilterType, handleSetGlobalFilter } = props;

      setGlobalFilterType(G.getPropFromObject(GC.FIELD_VALUE, field));

      if (G.isNotNilAndNotEmpty(globalFilterValue)) handleSetGlobalFilter(globalFilterValue, true);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { globalFilterType, defaultSearchType, setGlobalFilterType } = this.props;

      if (G.isNilOrEmpty(globalFilterType)) setGlobalFilterType(defaultSearchType);
    },
  }),
  pure,
);

export default enhance(GlobalSearchType);
