import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'react-recompose';
// components
import { CountDown } from '../../../components/count-down';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { AbsoluteBox } from '../../../ui';
// feature customer-portal
import { makeSelectTableHeight } from '../selectors';
import { setTimerStarted, getCustomerPortalListRequest, cleanCustomerPortalListAndLocations } from '../actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  tableHeight: makeSelectTableHeight(state),
});

const enhance = compose(
  connect(mapStateToProps, { setTimerStarted, getCustomerPortalListRequest, cleanCustomerPortalListAndLocations }),
  withHandlers({
    handleReset: (props: Object) => () => {
      const { setTimerStarted, getCustomerPortalListRequest, cleanCustomerPortalListAndLocations } = props;

      setTimerStarted(false);
      cleanCustomerPortalListAndLocations();
      getCustomerPortalListRequest({ refreshPage: true });
    },
  }),
);

const isExpanded = R.equals(100);

const getStyles = (condition: boolean) => ({
  p: G.ifElse(condition, 10, 15),
  right: G.ifElse(condition, 450, 150),
  fontSize: G.ifElse(condition, 14, 16),
  flexDirection: G.ifElse(condition, 'row', 'column'),
  background: G.getThemeByCond(condition, 'colors.white', 'colors.white07'),
});

const NextRefresh = ({ tableHeight, handleReset }: Object) => (
  <AbsoluteBox
    {...getStyles(isExpanded(tableHeight))}
    top={15}
    zIndex={11}
    fontWeight='bold'
    borderRadius='8px'
    boxShadow={`0 2px 4px 0 ${G.getTheme('colors.boxShadowGrey')}`}
  >
    {G.getWindowLocale('titles:next-refresh', 'Next Refresh')}:
    <CountDown
      handleReset={handleReset}
      styles={{
        ml: G.ifElse(isExpanded(tableHeight), '5px', 0),
        mt: G.ifElse(isExpanded(tableHeight), 0, '5px'),
      }}
    />
  </AbsoluteBox>
);

export default enhance(NextRefresh);
