import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  onboardingPackageDocumentFields,
  defaultOnboardingPackageDocumentFields,
  onboardingPackageDocumentValidationSchemaObject } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultOnboardingPackageDocumentFields,
      initialValues,
    ),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(onboardingPackageDocumentValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props }: Object) => {
      const { endpoint, submitAction, primaryObjectGuid } = props;

      const data = R.compose(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, primaryObjectGuid),
        G.mapObjectEmptyStringFieldsToNull,
      )(values);

      submitAction({ data, endpoint });
    },
  }),
  withHandlers({
    handleDisableFileName: ({ values, initialValues }: Object) => () => R.and(
      G.isNotNilAndNotEmpty(R.prop(GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, values)),
      R.equals(
        R.prop(GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, values),
        R.prop(GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, initialValues),
      ),
    ),
  }),
  pure,
);

const OnboardingPackageDocumentForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={onboardingPackageDocumentFields(props.initialValues)}
        handlers={{ handleDisableFileName: props.handleDisableFileName }}
        fileName={R.pathOr(
          G.getWindowLocale('titles:select-file', 'Select File'),
          ['values', GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE], props)
        } />
      <FormFooter closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(OnboardingPackageDocumentForm);
