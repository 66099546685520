import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet/equipment-component
import EquipmentComponentForm from '../../fleet/components/equipment-component-form';
// feature fleet-profile
import { TableCell } from '../components/table-cells';
//////////////////////////////////////////////////

const columnSettings = {
  [GC.FIELD_COMPONENT_ID]: {
    width: 150,
    name: 'titles:component-id',
  },
  createdFromSharedComponent: {
    width: 150,
    type: 'boolean',
    name: 'titles:shared-component',
  },
  [GC.FIELD_COMPONENT_TYPE]: {
    width: 150,
    name: 'titles:type',
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_COMPONENT_TYPE, GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  [GC.FIELD_WARRANTY_EXPIRATION]: {
    width: 150,
    name: 'titles:warranty-expiration-date',
  },
  [GC.FIELD_INSTALL_DATE]: {
    width: 150,
    name: 'titles:installed-date',
  },
  [GC.FIELD_REPLACE_OR_REMOVE_DATE]: {
    width: 150,
    name: 'titles:removed-date',
  },
  [GC.FIELD_INSTALLED]: {
    width: 100,
    type: 'boolean',
    name: 'titles:installed',
  },
  [GC.FIELD_COMPONENT_MANUFACTURER]: {
    width: 150,
    name: 'titles:manufacturer',
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_COMPONENT_MANUFACTURER, GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  [GC.FIELD_SERIAL_NUMBER]: {
    width: 150,
    name: 'titles:serial-number',
  },
  [GC.FIELD_SPECIFICATION]: {
    width: 400,
    name: 'titles:specification',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(columnSettings)),
};

export const truckEquipmentComponentSettings = {
  report,
  columnSettings,
  groupName: 'equipmentComponent',
  includeItemListToTablePropsMemoDeps: true,
  itemTitleArr: ['titles:equipment-component', 'Equipment Component'],
  formGroupTitleArr: ['titles:equipment-component', 'Equipment Component'],
  endpoints: {
    list: 'truckComponentList',
    createOrUpdate: 'truckComponent',
    remove: 'getCurrentTruckComponentEndpoint',
  },
  // helpers
  rowBgFunction: (_: any, { installed }: Object) =>
    G.getThemeByCond(G.isFalse(installed), 'colors.lightGrey', 'colors.white'),
  makeInitialValues: (initialValues: Object) => {
    const { componentType, componentManufacturer } = initialValues;

    return {
      ...initialValues,
      [GC.FIELD_COMPONENT_TYPE]: G.getDropdownOptionGuidFromObject(componentType),
      [GC.FIELD_COMPONENT_MANUFACTURER]: G.getDropdownOptionGuidFromObject(componentManufacturer),
    };
  },
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    [GC.FIELD_COMPONENT_TYPE]: R.path(['dropdownOptions', GC.FLEET_COMPONENT_TYPE], configGroup),
    [GC.FIELD_COMPONENT_MANUFACTURER]: R.path(['dropdownOptions', GC.FLEET_COMPONENT_MANUFACTURER], configGroup),
  }),
  CustomForm: (props: Object) => (
    <EquipmentComponentForm
      {...props}
      configs={props.trailerConfigs}
      branchGuid={props.primaryObjectBranchGuid}
    />
  ),
};

export const trailerEquipmentComponentSettings = {
  ...truckEquipmentComponentSettings,
  endpoints: {
    list: 'trailerComponentList',
    createOrUpdate: 'trailerComponent',
    remove: 'getCurrentTrailerComponentEndpoint',
  },
};
