import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  advancePaymentFieldSettings,
  defaultAdvancePaymentFields,
  getAdvancePaymentValidationSchemaObject,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultAdvancePaymentFields,
      props.initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: ({ editMode }: Object) =>
      Yup.lazy(() => Yup.object().shape(getAdvancePaymentValidationSchemaObject(editMode))),
  }),
  pure,
);

const AdvancePaymentForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={advancePaymentFieldSettings}
        handlers={{ disableIntegrationType: () => props.editMode }}
        fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between'}}
      />
      <FormFooter2 />
    </form>
  </Box>
);

export default enhance(AdvancePaymentForm);
