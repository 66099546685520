import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { DEFAULT_QUANTITY_RATE_UNIT_OPTIONS } from '../../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 400,
};

const settings = {
  [GC.FIELD_ITEM_QUANTITY]: {
    inputWrapperStyles,
    label: ['titles:quantity'],
    fieldName: GC.FIELD_ITEM_QUANTITY,
  },
  [GC.FIELD_ITEM_PACKAGE_TYPE]: {
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:package-type'],
    fieldName: GC.FIELD_ITEM_PACKAGE_TYPE,
    options: R.drop(1, DEFAULT_QUANTITY_RATE_UNIT_OPTIONS),
  },
  [GC.FIELD_DAMAGED_VALUE]: {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:damaged-value'],
    fieldName: GC.FIELD_DAMAGED_VALUE,
  },
  [GC.FIELD_PRODUCT_VALUE]: {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:product-value'],
    fieldName: GC.FIELD_PRODUCT_VALUE,
  },
  [GC.FIELD_AWARDED_VALUE]: {
    inputWrapperStyles,
    label: ['titles:awarded-value'],
    fieldName: GC.FIELD_AWARDED_VALUE,
  },
  [GC.FIELD_CURRENCY]: {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:currency'],
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_DESCRIPTION]: {
    type: 'textarea',
    inputWrapperStyles,
    inputStyles: { height: 100 },
    label: ['titles:description'],
    fieldName: GC.FIELD_DESCRIPTION,
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CURRENCY]: G.yupStringRequired,
  [GC.FIELD_DESCRIPTION]: Yup.string().nullable(true).max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
  [GC.FIELD_AWARDED_VALUE]: G.yupNumberNotRequired
    .min(0, G.getShouldBePositiveLocaleTxt())
    .max(1000000000, G.getShouldBeLessOrEqualLocaleTxt(1000000000)),
  [GC.FIELD_DAMAGED_VALUE]: G.yupNumberRequired
    .min(1, G.getShouldBePositiveLocaleTxt())
    .max(1000000000, G.getShouldBeLessOrEqualLocaleTxt(1000000000)),
  [GC.FIELD_PRODUCT_VALUE]: G.yupNumberRequired
    .min(1, G.getShouldBePositiveLocaleTxt())
    .max(1000000000, G.getShouldBeLessOrEqualLocaleTxt(1000000000)),
});

const useForm = (props: Object) => {
  const { submitAction, initialValues } = props;

  const onSubmit = (values: Object) => submitAction(G.mapObjectEmptyStringFieldsToNull(values));

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      ...R.map(() => '', settings),
      ...initialValues,
    },
  });

  return formik;
};

const DamagedItemForm = (props: Object) => {
  const formik = useForm(props);

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        fields={R.values(settings)}
        fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
      />
      <FormFooter2 />
    </form>
  );
};

export default DamagedItemForm;
