import * as R from 'ramda';
import { useMemo } from 'react';
// features
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const tableSettings = {
  maxHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 50,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: false,
  checkBoxCellWidth: 54,
  allowSelectItems: false,
  minHeight: 'max-content',
  emptyListPadding: '10px 0',
  tableRowHeight: 'max-content',
  checkBoxCellJustifyContent: 'flex-start',
};

const makeReport = ({ report, itemList, columnSettings, hideEmptyFields }: Object) => {
  let reportToUse = report;

  if (R.isNil(report)) {
    reportToUse = {
      fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(columnSettings)),
    };
  }

  if (R.not(hideEmptyFields)) return reportToUse;

  const fields = R.filter(
    ({ name }: Object) => G.isNotNilAndNotEmpty(R.path([0, ...R.split('.', name)], itemList)),
    reportToUse.fields,
  );

  return { fields };
};

const systemFields = {
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

export const useTableProps = ({
  fields,
  report,
  itemList,
  dispatch,
  groupName,
  endpoints,
  hideEmptyFields,
  downloadOptions,
  omitColumns = [],
  handleRemoveItem,
  expandedContainer,
  getEditPermissions,
  primaryObjectGuidKey,
  getRemovePermissions,
  handlePreviewDocument,
  handleDownloadDocument,
  additionalTableSettings,
  handleCreateOrUpdateItem,
  actionsPicker = ['edit', 'remove'],
}: Object) => {
  const deps = G.ifElse(hideEmptyFields, [itemList, groupName], [groupName]);

  const tableProps = useMemo(() => {
    const handleGoToContractDetails = ({ guid, type = 'carrier' }: Object) => {
      if (expandedContainer) {
        const map = {
          carrier: GC.PAGE_CARRIER_CONTRACT_DETAILS,
          customer: GC.PAGE_CUSTOMER_CONTRACT_DETAILS,
        };

        return dispatch(setExpandedContainerOptions({
          opened: true,
          visitPageGuid: guid,
          componentType: R.prop(type, map),
        }));
      }

      const map = {
        carrier: routesMap.carrierContractDetails(guid),
        customer: routesMap.customerContractDetails(guid),
      };

      G.goToRoute(R.prop(type, map));
    };

    const actionButtonsMap = {
      edit: {
        iconName: 'pencil',
        getPermissions: getEditPermissions,
        action: (guid: string, entity: Object) => handleCreateOrUpdateItem(entity),
      },
      remove: {
        iconName: 'trash',
        action: handleRemoveItem,
        getPermissions: getRemovePermissions,
      },
      terminalProcess: {
        iconSize: [15, 15],
        iconColor: darkBlueColor,
        iconName: 'moneyWithArrow',
        action: (guid: string, entity: Object) => handleCreateOrUpdateItem({ ...entity, activeTab: 1 }),
      },
      terminalFinance: {
        iconSize: [15, 15],
        iconName: 'payment',
        iconColor: darkBlueColor,
        action: (guid: string, entity: Object) => handleCreateOrUpdateItem({ ...entity, activeTab: 2 }),
      },
      goToUserProfile: {
        iconName: 'pencil',
        action: (guid: string) => G.goToRoute(routesMap.getUserSettingsRoute(guid)),
      },
      goToCarrierContractDetails: {
        iconName: 'pencil',
        action: (guid: string) => handleGoToContractDetails({ guid }),
      },
      goToCustomerContractDetails: {
        iconName: 'pencil',
        action: (guid: string) => handleGoToContractDetails({ guid, type: 'customer' }),
      },
      downloadDocument: {
        iconName: 'downloadDocument',
        getPermissions: ({ fileUri }: Object) => G.ifElse(R.isNil(fileUri), ''),
        action: (guid: string, entity: Object) => handleDownloadDocument(
          entity,
          downloadOptions,
          { primaryObjectGuidKey, endpointName: G.getPropFromObject('downloadDocument', endpoints) },
        ),
      },
      previewDocument: {
        iconName: 'eye',
        getPermissions: ({ fileUri }: Object) => G.ifElse(R.isNil(fileUri), ''),
        action: (guid: string, entity: Object) => handlePreviewDocument(
          entity,
          downloadOptions,
          { primaryObjectGuidKey, endpointName: G.getPropFromObject('downloadDocument', endpoints) },
        ),
      },
      previewSharedAccessorial: {
        iconName: 'eye',
        getPermissions: ({ shared }: Object) => G.ifElse(R.not(shared), ''),
        action: (guid: string, entity: Object) => handleCreateOrUpdateItem(entity),
      },
    };

    const actionButtons = R.values(R.pick(actionsPicker, actionButtonsMap));

    const columnSettings = R.compose(
      R.omit(omitColumns),
      R.mergeLeft(systemFields),
      R.map(({ uiType, ...item }: Object) => ({
        ...R.pick(['width', GC.FIELD_NAME, 'customComponent'], item),
        [GC.FIELD_TYPE]: uiType,
      })),
    )(fields);

    return {
      actionButtons,
      columnSettings,
      tableSettings: R.mergeRight(tableSettings, additionalTableSettings),
      report: makeReport({ report, itemList, columnSettings, hideEmptyFields }),
    };
  }, deps);

  return tableProps;
};
