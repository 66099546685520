import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
import BranchTreeComponent from '../../branch/components/branch-tree';
import { createOrUpdateWorkOrderRequest } from '../../work-order/actions';
import { CreateWorkOrderForm } from '../../work-order/components/work-order-form';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { accountingIntegrationOptions } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-list
import { withSyncItemsByVIN } from '../hocs';
import AssignSharedComponentForm from './assign-shared-component';
import {
  printItemRequest,
  getItemXMLRequest,
  removeItemRequest,
  assignSharedComponentRequest,
  shareOrCancelSharingTrailerRequest,
} from '../actions';
import {
  getWritePermissionsByReportType,
  getRemovePermissionByReportType,
  getReadWritePermissionsByReportType,
} from '../settings';
//////////////////////////////////////////////////

const iconColor = G.getTheme('icons.iconColor');

const options = ({
  guid,
  entity,
  reportType,
  handleEdit,
  handlePrint,
  handleRemove,
  handleGetXML,
  handleEditForm,
  handleGenerateIfta,
  handleCreateWorkOrder,
  handleSyncSelectedItems,
  handleCreateRelatedTask,
  handleSendToIntegration,
  handleAssignSharedComponent,
  handleShareOrCancelSharingTrailerRequest,
}: Object) => ({
  edit: {
    action: handleEdit,
    frontIcon: I.pencil(iconColor),
    text: G.getWindowLocale('actions:edit', 'Edit'),
    permissions: getWritePermissionsByReportType(reportType),
  },
  sendToIntegration: {
    frontIcon: I.gear(iconColor),
    action: handleSendToIntegration,
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:send-to-integration', 'Send to Integration'),
  },
  editForm: {
    action: handleEditForm,
    frontIcon: I.pencil(iconColor),
    text: G.getWindowLocale('actions:edit', 'Edit'),
    permissions: getWritePermissionsByReportType(reportType),
  },
  remove: {
    action: handleRemove,
    frontIcon: I.trash(iconColor),
    text: G.getWindowLocale('actions:delete', 'Delete'),
    permissions: getRemovePermissionByReportType(reportType),
  },
  print: {
    action: handlePrint,
    frontIcon: I.printer(iconColor),
    text: G.getWindowLocale('actions:print', 'Print'),
    permissions: getReadWritePermissionsByReportType(reportType),
  },
  xml: {
    action: handleGetXML,
    frontIcon: I.downloadDocument(iconColor, 15, 17),
    permissions: getReadWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
  },
  ifta: {
    permissions: [PC.IFTA_EXECUTE],
    frontIcon: I.gearSolid(iconColor),
    action: () => handleGenerateIfta(false, R.of(Array, guid)),
    text: G.getWindowLocale('actions:generate-ifta', 'Generate IFTA'),
  },
  sync: {
    frontIcon: I.vin(iconColor, 20, 20),
    action: () => handleSyncSelectedItems(R.of(Array, guid)),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:vin-lookup', 'VIN Lookup'),
  },
  assignSharedComponentToTruck: {
    permissions: null,
    frontIcon: I.truck(iconColor),
    action: () => handleAssignSharedComponent('truck'),
    text: G.getWindowLocale('titles:assign-truck', 'Assign Truck'),
  },
  assignSharedComponentToTrailer: {
    permissions: null,
    frontIcon: I.trailer(iconColor),
    action: () => handleAssignSharedComponent('trailer'),
    text: G.getWindowLocale('titles:assign-trailer', 'Assign Trailer'),
  },
  createRelatedTask: {
    permissions: [PC.TASK_WRITE],
    frontIcon: I.plusRound(iconColor),
    action: () => handleCreateRelatedTask(entity),
    text: G.getWindowLocale('titles:create-task', 'Create Task'),
  },
  shareTrailer: {
    frontIcon: I.gearSolid(iconColor),
    permissions: [PC.FLEET_TRAILER_SHARING_EXECUTE],
    action: () => handleShareOrCancelSharingTrailerRequest(true),
    text: G.getWindowLocale('titles:share-trailer', 'Share Trailer'),
  },
  cancelSharing: {
    frontIcon: I.gearSolid(iconColor),
    action: () => handleShareOrCancelSharingTrailerRequest(),
    text: G.getWindowLocale('titles:cancel-sharing', 'Cancel Sharing'),
    permissions: G.ifElse(
      G.isNotNilAndNotEmpty(R.prop(GC.GRC.SHARED_TO_BRANCHES_GUID, entity)),
      [PC.FLEET_TRAILER_SHARING_EXECUTE],
      '',
    ),
  },
  createWorkOrder: {
    permissions: null,
    frontIcon: I.plusRound(iconColor),
    action: () => handleCreateWorkOrder(entity),
    text: G.getWindowLocale('titles:create-work-order', 'Create Work Order'),
  },
});

const actionsToPickMap = {
  [GC.FLEET_DRIVER_REPORT]: ['edit', 'print', 'xml'],
  [GC.FLEET_SERVICE_ISSUE_REPORT]: ['createWorkOrder'],
  [GC.FLEET_VENDOR_REPORT]: ['edit', 'sendToIntegration'],
  [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: ['createRelatedTask'],
  [GC.FLEET_TRUCK_REPORT]: ['edit', 'print', 'xml', 'ifta', 'sync'],
  [GC.FLEET_TRAILER_REPORT]: ['edit', 'print', 'xml', 'sync', 'shareTrailer', 'cancelSharing'],
  [GC.FLEET_SHARED_COMPONENT_REPORT]: [
    'assignSharedComponentToTruck',
    'assignSharedComponentToTrailer',
  ],
};

const enhance = compose(
  withSyncItemsByVIN,
  withHandlers({
    handleEdit: ({ guid, reportType, closeFixedPopup }: Object) => () => {
      G.callFunction(closeFixedPopup);

      const routes = {
        [GC.FLEET_DRIVER_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType()],
        [GC.FLEET_TRUCK_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType('truck')],
        [GC.FLEET_VENDOR_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType('vendor')],
        [GC.FLEET_TRAILER_REPORT]: routesMap[G.getFleetProfileRoutePathNameByFleetType('trailer')],
      };

      G.goToRoute(G.getPropFromObject(reportType, routes)(guid));
    },
    handlePrint: (props: Object) => () => {
      const {
        guid,
        openModal,
        reportType,
        closeModal,
        closeFixedPopup,
        printItemRequest,
        documentTemplates,
      } = props;

      closeFixedPopup();
      const documentTemplatesMap = {
        [GC.FLEET_TRUCK_REPORT]: G.getPropFromObject(GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK, documentTemplates),
        [GC.FLEET_DRIVER_REPORT]: G.getPropFromObject(GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DRIVER, documentTemplates),
        [GC.FLEET_TRAILER_REPORT]: G.getPropFromObject(GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER, documentTemplates),
      };

      if (G.isNilOrEmpty(G.getPropFromObject(reportType, documentTemplatesMap))) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      const options = R.compose(
        R.map(({ name, guid }: Object) => ({[GC.FIELD_VALUE]: guid, [GC.FIELD_LABEL]: name})),
        R.pathOr([], [reportType]),
      )(documentTemplatesMap);
      const submitAction = (templateGuid: string) => printItemRequest({ guid, templateGuid });
      const component = (
        <SelectDropdownForm
          fieldLabel=' '
          options={options}
          cancelAction={closeModal}
          submitAction={submitAction}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-template', 'Select Template'),
        },
      };

      openModal(modal);
    },
    handleGetXML: ({ guid, closeFixedPopup, getItemXMLRequest }: Object) => () => {
      closeFixedPopup();
      getItemXMLRequest(guid);
    },
    handleAssignSharedComponent: (props: Object) => (entityType: string) => {
      const { guid, openModal, closeModal, closeFixedPopup, assignSharedComponentRequest } = props;

      closeFixedPopup();
      const truckUnitId = R.path(['entity', GC.GRC.FLEET_TRUCK_UNIT_ID], props);
      const trailerUnitId = R.path(['entity', GC.GRC.FLEET_TRAILER_UNIT_ID], props);
      const componentProps = {
        closeModal,
        entityType,
        truckUnitId,
        trailerUnitId,
        initialValues: { guid },
        submitAction: assignSharedComponentRequest,
      };
      const component = <AssignSharedComponentForm {...componentProps} />;
      const title = G.getWindowLocale(...G.ifElse(
        R.equals(entityType, 'truck'),
        ['titles:assign-truck', 'Assign Truck'],
        ['titles:assign-trailer', 'Assign Trailer'],
      ));
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 600,
          height: 'auto',
        },
      };

      openModal(modal);
    },
    handleSendToIntegration: (props: Object) => () => {
      const {
        guid,
        openModal,
        closeModal,
        sendListToIntegrationRequest,
      } = props;

      const submitAction = (integrationType: string) =>
        sendListToIntegrationRequest({ selectedList: R.of(Array, guid), integrationType });

      const componentProps = {
        submitAction,
        fieldWidth: 300,
        optionRequired: true,
        cancelAction: closeModal,
        options: accountingIntegrationOptions(),
        fieldLabel: G.getWindowLocale('titles:integration-type', 'Integration Type'),
      };
      const modal = {
        p: 15,
        options: { width: 330 },
        component: <SelectDropdownForm {...componentProps} />,
      };

      openModal(modal);
    },
    handleShareOrCancelSharingTrailerRequest: (props: Object) => (share: boolean) => {
      const {
        entity,
        openModal,
        closeModal,
        closeFixedPopup,
        shareOrCancelSharingTrailerRequest,
      } = props;

      closeFixedPopup();

      const { guid, [GC.GRC.SHARED_TO_BRANCHES_GUID]: sharedToBranchGuids } = entity;

      const submitAction = (branchGuids: Array) => shareOrCancelSharingTrailerRequest({
        guid,
        share,
        [GC.BRANCH_GUIDS]: branchGuids,
      });

      const component = (
        <BranchTreeComponent
          width={580}
          cursor='pointer'
          allowSelect={true}
          rootDisabled={true}
          withoutCurrent={true}
          closeAction={closeModal}
          handleMultipleSelect={submitAction}
          branchTypeFilter={GC.BRANCH_TYPE_ENUM_DIVISION}
          initialSelectedList={G.ifElse(share, R.or(sharedToBranchGuids, []))}
        />
      );

      const title = G.getWindowLocale(...G.ifElse(
        share,
        ['titles:share-trailer', 'Share Trailer'],
        ['titles:cancel-sharing', 'Cancel Sharing'],
      ));

      const modal = {
        p: '0px',
        component,
        options: {
          title,
          height: 'auto',
        },
      };

      openModal(modal);
    },
    handleCreateWorkOrder: (props: Object) => (issue: Object) => {
      const {
        openModal,
        openLoader,
        closeLoader,
        closeFixedPopup,
        currentBranchGuid,
        createOrUpdateWorkOrderRequest,
      } = props;

      closeFixedPopup();

      const isTruck = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_TRUCK_GUID, issue));
      const fleetEntityType = G.ifElse(isTruck, GC.FIELD_TRUCK, GC.FIELD_TRAILER);
      const fleetEntityGuidField = G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID);
      const fleetEntityGuid = G.getPropFromObject(fleetEntityGuidField, issue);

      const initialValues = {
        [fleetEntityGuidField]: fleetEntityGuid,
        [GC.FIELD_ISSUE_GUIDS]: R.of(Array, G.getGuidFromObject(issue)),
      };

      const component = (
        <CreateWorkOrderForm
          isTruck={isTruck}
          openedFromIssue={true}
          openLoader={openLoader}
          closeLoader={closeLoader}
          entityType={fleetEntityType}
          initialValues={initialValues}
          branchGuid={currentBranchGuid}
          fleetEntityGuid={fleetEntityGuid}
          submitAction={createOrUpdateWorkOrderRequest}
          fleetEntityUnitId={G.getPropFromObject(`${fleetEntityType}.${GC.FIELD_UNIT_ID}`, issue)}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          title: G.getAddTitle(['titles:work-order', 'Work Order']),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const RowActions = (props: Object) => {
  const { reportType } = props;

  const getOptionsByReportType = R.compose(
    R.values,
    R.pick(G.getPropFromObject(reportType, actionsToPickMap)),
  )(options(props));

  return <ActionsElement options={getOptionsByReportType} />;
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  documentTemplates: makeSelectDocumentTemplates(state),
});

export default connect(mapStateToProps, {
  printItemRequest,
  getItemXMLRequest,
  removeItemRequest,
  assignSharedComponentRequest,
  createOrUpdateWorkOrderRequest,
  shareOrCancelSharingTrailerRequest,
})(enhance(RowActions));
