import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle, withState } from 'react-recompose';
// features
import { messageType } from '../../sockets-v2/constants';
import { socketPostMessage } from '../../sockets-v2/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, FixedFlex, PageWrapper, CancelButton } from '../../../ui';
// feature dispatch details new
import { makeSelectLoadGuid } from './selectors';
import DocumentsTab from './components/documents-tab';
import GeneralTabComponent from './components/general-tab';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  loadGuid: makeSelectLoadGuid(state),
});

const enhance = compose(
  connect(mapStateToProps, { socketPostMessage }),
  withState('activeTab', 'setActiveTab', 'general'),
  lifecycle({
    componentWillUnmount() {
      this.props.socketPostMessage({
        action: messageType.unsubscribe,
        destination: `/tel/${this.props.loadGuid}`,
      });
    },
  }),
  pure,
);

const commonBtnStyles = {
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const cancelStyles = {
  ...commonBtnStyles,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
};

const LoadDetailsNewComponent = ({
  activeTab,
  setActiveTab,
  closeExpandedContainer,
  expandedContainer = false,
}: Object) => (
  <PageWrapper
    bgColor={G.getTheme('colors.bgGrey')}
    p={G.ifElse(G.isTrue(expandedContainer), '0px', 20)}
    pb={G.ifElse(G.isTrue(expandedContainer), '0px', 60)}
    pl={G.ifElse(G.isTrue(expandedContainer), '0px', 75)}
  >
    <Box
      p={G.ifElse(G.isTrue(expandedContainer), 25, '0px')}
      overflow={G.ifElse(G.isTrue(expandedContainer), 'auto', 'unset')}
      height={G.ifElse(G.isTrue(expandedContainer), 'calc(100vh - 60px)', 'unset')}
    >
      {
        R.equals(activeTab, 'documents') && <DocumentsTab setActiveTab={setActiveTab} />
      }
      {
        R.equals(activeTab, 'general') &&
        <GeneralTabComponent expandedContainer={expandedContainer} setActiveTab={setActiveTab} />
      }
    </Box>
    {
      G.isTrue(expandedContainer) &&
      <FixedFlex
        right='0px'
        bottom='0px'
        p='14px 20px'
        justifyContent='flex-end'
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
        left={window.isMobile ? 60 : '0px'}
        background={G.getTheme('colors.white')}
        width={window.isMobile ? 'auto' : '100%'}
      >
        <CancelButton {...cancelStyles} onClick={closeExpandedContainer}>
          {G.getWindowLocale('actions:close', 'Close')}
        </CancelButton>
      </FixedFlex>
    }
  </PageWrapper>
);

export default enhance(LoadDetailsNewComponent);
