// helpers/constants
import * as GC from '../../../constants';
// feature configs
import { MultiselectFieldComponent } from '../ui';
//////////////////////////////////////////////////

const generalSection = {
  title: 'titles:general',
  fields: {
    [GC.OPTIONAL_QB_OVERRIDE_URL_REFERENCES]: {
      type: 'list',
      component: MultiselectFieldComponent,
      options: 'customerInvoiceReferenceTypes',
      name: 'titles:qb-override-url-references',
      nameForAttribute: GC.OPTIONAL_QB_OVERRIDE_URL_REFERENCES,
    },
    [GC.OPTIONAL_QB_OVERRIDE_URL_CONFIRMATION_REFERENCE]: {
      type: 'select',
      options: 'customerInvoiceReferenceTypes',
      name: 'titles:qb-confirmation-reference',
      nameForAttribute: GC.OPTIONAL_QB_OVERRIDE_URL_CONFIRMATION_REFERENCE,
    },
    [GC.OPTIONAL_GBHUNT_CALCULATOR_ENABLED]: {
      type: 'switcher',
      name: 'titles:jbhunt-calculator-enabled',
      nameForAttribute: GC.OPTIONAL_GBHUNT_CALCULATOR_ENABLED,
    },
    [GC.OPTIONAL_GBHUNT_CALCULATOR_USE_ON_TEL]: {
      type: 'switcher',
      name: 'titles:jbhunt-calculator-use-on-tel',
      nameForAttribute: GC.OPTIONAL_GBHUNT_CALCULATOR_USE_ON_TEL,
    },
  },
};

const ConfigOptionalGroup = {
  generalSection,
};

export {
  ConfigOptionalGroup,
};
