import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

const makeDropdownOptions = (dropdowns: Object) => R.compose(
  R.reduce((acc: Object, dropdownName: string) => R.assoc(
    dropdownName,
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid({ dropdowns }, dropdownName),
    acc,
  ), {}),
  R.keys,
)(dropdowns);

const setBranchConfigsToStoreByPath = ({ path, state, data }: Object) => {
  const configGroup = R.path(R.split('.', path), state);

  const { configs, dropdowns } = data;

  const dropdownOptions = makeDropdownOptions(dropdowns);

  if (R.isNil(configGroup)) {
    return P.$set(path, { configs, dropdownOptions }, state);
  }

  const { configs: existentConfigs, dropdownOptions: existentDropdownOptions } = configGroup;

  const newConfigGroup = {
    configs: R.mergeRight(existentConfigs, configs),
    dropdownOptions: R.mergeRight(existentDropdownOptions, dropdownOptions),
  };

  return P.$set(path, newConfigGroup, state);
};

export {
  makeDropdownOptions,
  setBranchConfigsToStoreByPath,
};
