import * as R from 'ramda';
import React from 'react';
import { css } from 'styled-components';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionBox } from '../../../components/action-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const infoWindow = css`
  font-size: 11px !important;
`;

const darkGreyColor = G.getTheme('colors.darkGrey');

const lastStatusCodeTxtLocale = G.getWindowLocale('titles:last-status-code', 'Last Status Code');
const lastUpdatedDateTxtLocale = G.getWindowLocale('titles:last-updated-date', 'Last Updated Date');
const lastStatusReasonCodeTxtLocale = G.getWindowLocale('titles:last-status-reason-code', 'Last Status Reason Code');
const firstEventEarlyDateTxtLocale = `${G.getWindowLocale('titles:first-event', 'First Event')} ${
  G.getWindowLocale('titles:early-date', 'Early Date')}`;

const MarkerInfo = (props: Object) => {
  const {
    guid,
    firstEvent,
    lastStatusMessage,
    primaryReferenceValue,
    handleClickOnCloPrimaryReference,
  } = props;

  const statusReasonCode = G.getPropFromObject('statusReasonCodeDisplayedValue', lastStatusMessage);

  return (
    <Box
      maxWidth={125}
      css={infoWindow}
      fontWeight='bold'
      wordBreak='break-all'
    >
      <ActionBox
        text={primaryReferenceValue}
        action={() => handleClickOnCloPrimaryReference(guid)}
      />
      {
        G.isNotNilAndNotEmpty(lastStatusMessage) &&
        <div>
          <Box my='5px' color={darkGreyColor}>
            {lastStatusCodeTxtLocale}:
          </Box>
          {G.getPropFromObject('statusCodeDisplayedValue', lastStatusMessage)}
          {
            G.isNotNilAndNotEmpty(statusReasonCode) &&
            <div>
              <Box my='5px' color={darkGreyColor}>
                {lastStatusReasonCodeTxtLocale}:
              </Box>
              {statusReasonCode}
            </div>
          }
          <Box my='5px' color={darkGreyColor}>
            {lastUpdatedDateTxtLocale}:
          </Box>
          {G.getPropFromObject(GC.FIELD_STATUS_MESSAGE_DATE, lastStatusMessage)}
        </div>
      }
      {
        R.isNil(lastStatusMessage) &&
        <div>
          <Box my='5px' wordBreak='break-all'>{G.concatLocationFields(firstEvent)}</Box>
          <Box color={darkGreyColor}>
            {firstEventEarlyDateTxtLocale}:
          </Box>
          {G.getPropFromObject(GC.FIELD_LOAD_EVENT_EARLY_DATE, firstEvent)}
        </div>
      }
    </Box>
  );
};

const enhance = compose(
  withHandlers({
    handleClickOnCloPrimaryReference: ({ setExpandedContainerOptions }: Object) => (guid: string) => {
      const openLoadInSidebar = G.getAmousConfigByNameFromWindow(GC.UI_OPEN_LOAD_IN_SIDEBAR);

      if (G.isFalse(openLoadInSidebar)) return G.goToLoadDetailsByConfigAndLoadType(routesMap, guid, true);

      return setExpandedContainerOptions({
        opened: true,
        visitPageGuid: guid,
        componentType: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
      });
    },
  }),
  pure,
);

export default enhance(MarkerInfo);
