import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Form, Fieldset2 } from '../../../forms';
// feature carrier
import {
  associatedCompanyFields,
  defaultAssociatedCompanyFields,
  validationSchemaAssociatedCompany,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaAssociatedCompany,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultAssociatedCompanyFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, carrierGuid, submitAction } = props;

      submitAction(R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, values));

      closeModal();
    },
  }),
  pure,
);

export const AssociatedCompanyForm = enhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={associatedCompanyFields}
        fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </Form>
  );
});
