import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  deleteItemRequest,
  deleteItemSuccess,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const setActiveTab = createAction('setActiveTab');
const setActivePage = createAction('setActivePage');
const getCarrierSuccess = createAction('getCarrierSuccess');
const setTerminalDetails = createAction('setTerminalDetails');
const createWatchRequest = createAction('createWatchRequest');
const changeBranchRequest = createAction('changeBranchRequest');
const createCarrierRequest = createAction('createCarrierRequest');
const updateCarrierRequest = createAction('updateCarrierRequest');
const updateCarrierSuccess = createAction('updateCarrierSuccess');
const getCarrierRefRequest = createAction('getCarrierRefRequest');
const getCarrierRefSuccess = createAction('getCarrierRefSuccess');
const receivedSafetySuccess = createAction('receivedSafetySuccess');
const updateTerminalRequest = createAction('updateTerminalRequest');
const updateTerminalSuccess = createAction('updateTerminalSuccess');
const getCarrierDocsRequest = createAction('getCarrierDocsRequest');
const getCarrierDocsSuccess = createAction('getCarrierDocsSuccess');
const toggleCarrierFormGroup = createAction('toggleCarrierFormGroup');
const updateCarrierDocRequest = createAction('updateCarrierDocRequest');
const deleteCarrierRefRequest = createAction('deleteCarrierRefRequest');
const deleteCarrierRefSuccess = createAction('deleteCarrierRefSuccess');
const deleteCarrierDocSuccess = createAction('deleteCarrierDocSuccess');
const deleteCarrierDocRequest = createAction('deleteCarrierDocRequest');
const createCarrierDocSuccess = createAction('createCarrierDocSuccess');
const updateCarrierDocSuccess = createAction('updateCarrierDocSuccess');
const updateCarrierRefRequest = createAction('updateCarrierRefRequest');
const updateCarrierRefSuccess = createAction('updateCarrierRefSuccess');
const createCarrierRefRequest = createAction('createCarrierRefRequest');
const createCarrierRefSuccess = createAction('createCarrierRefSuccess');
const createCarrierDocRequest = createAction('createCarrierDocRequest');
const receivedMailListSuccess = createAction('receivedMailListSuccess');
const getCarrierFinanceRequest = createAction('getCarrierFinanceRequest');
const getCarrierFinanceSuccess = createAction('getCarrierFinanceSuccess');
const getCarrierConfigsRequest = createAction('getCarrierConfigsRequest');
const getCarrierConfigsSuccess = createAction('getCarrierConfigsSuccess');
const getGeneralConfigsRequest = createAction('getGeneralConfigsRequest');
const getGeneralConfigsSuccess = createAction('getGeneralConfigsSuccess');
const selectCarrierContractRate = createAction('selectCarrierContractRate');
const getCarrierMailListRequest = createAction('getCarrierMailListRequest');
const getCarrierResponseSuccess = createAction('getCarrierResponseSuccess');
const getTemplateConfigsRequest = createAction('getTemplateConfigsRequest');
const getTerminalFinanceRequest = createAction('getTerminalFinanceRequest');
const getTerminalFinanceSuccess = createAction('getTerminalFinanceSuccess');
const downloadCarrierDocRequest = createAction('downloadCarrierDocRequest');
const getTemplateConfigsSuccess = createAction('getTemplateConfigsSuccess');
const getCarrierContractRequest = createAction('getCarrierContractRequest');
const getCarrierContractSuccess = createAction('getCarrierContractSuccess');
const addContractVariableValues = createAction('addContractVariableValues');
const getCustomerContractRequest = createAction('getCustomerContractRequest');
const getCustomerContractSuccess = createAction('getCustomerContractSuccess');
const downloadCarrierMailRequest = createAction('downloadCarrierMailRequest');
const getCarrierTerminalsRequest = createAction('getCarrierTerminalsRequest');
const getCarrierTerminalsSuccess = createAction('getCarrierTerminalsSuccess');
const getCarrierContractsRequest = createAction('getCarrierContractsRequest');
const getCarrierContractsSuccess = createAction('getCarrierContractsSuccess');
const getCarrierInsuranceRequest = createAction('getCarrierInsuranceRequest');
const getCarrierInsuranceSuccess = createAction('getCarrierInsuranceSuccess');
const getTerminalContactsRequest = createAction('getTerminalContactsRequest');
const getTerminalContactsSuccess = createAction('getTerminalContactsSuccess');
const editContractVariableValues = createAction('editContractVariableValues');
const updateCarrierFinanceSuccess = createAction('updateCarrierFinanceSuccess');
const updateCarrierFinanceRequest = createAction('updateCarrierFinanceRequest');
const getCarrierSafetyInfoRequest = createAction('getCarrierSafetyInfoRequest');
const getCustomersContractRequest = createAction('getCustomersContractRequest');
const getCustomersContractSuccess = createAction('getCustomersContractSuccess');
const getDispatchConfigListSuccess = createAction('getDispatchConfigListSuccess');
const getDispatchConfigListRequest = createAction('getDispatchConfigListRequest');
const deleteContractVariableValues = createAction('deleteContractVariableValues');
const deleteCarrierContractRequest = createAction('deleteCarrierContractRequest');
const deleteCarrierContractSuccess = createAction('deleteCarrierContractSuccess');
const updateTerminalContactRequest = createAction('updateTerminalContactRequest');
const updateTerminalContactSuccess = createAction('updateTerminalContactSuccess');
const createTerminalContactRequest = createAction('createTerminalContactRequest');
const createTerminalContactSuccess = createAction('createTerminalContactSuccess');
const createCarrierTerminalRequest = createAction('createCarrierTerminalRequest');
const createCarrierTerminalSuccess = createAction('createCarrierTerminalSuccess');
const updateTerminalFinanceRequest = createAction('updateTerminalFinanceRequest');
const deleteCarrierTerminalRequest = createAction('deleteCarrierTerminalRequest');
const deleteCarrierTerminalSuccess = createAction('deleteCarrierTerminalSuccess');
const deleteTerminalContactRequest = createAction('deleteTerminalContactRequest');
const deleteTerminalContactSuccess = createAction('deleteTerminalContactSuccess');
const createCarrierContractRequest = createAction('createCarrierContractRequest');
const createCarrierContractSuccess = createAction('createCarrierContractSuccess');
const updateCarrierContractRequest = createAction('updateCarrierContractRequest');
const updateCarrierContractSuccess = createAction('updateCarrierContractSuccess');
const addCarriersToWatchListRequest = createAction('addCarriersToWatchListRequest');
const createCustomerContractSuccess = createAction('createCustomerContractSuccess');
const updateCustomerContractRequest = createAction('updateCustomerContractRequest');
const updateCustomerContractSuccess = createAction('updateCustomerContractSuccess');
const deleteCustomerContractRequest = createAction('deleteCustomerContractRequest');
const deleteCustomerContractSuccess = createAction('deleteCustomerContractSuccess');
const getCarrierAssocCompanyRequest = createAction('getCarrierAssocCompanyRequest');
const getCarrierAssocCompanySuccess = createAction('getCarrierAssocCompanySuccess');
const getCarrierPayLocationsRequest = createAction('getCarrierPayLocationsRequest');
const getCarrierPayLocationsSuccess = createAction('getCarrierPayLocationsSuccess');
const getCarrierCertificatesRequest = createAction('getCarrierCertificatesRequest');
const getCarrierCertificatesSuccess = createAction('getCarrierCertificatesSuccess');
const getCarrierAssessorialsRequest = createAction('getCarrierAssessorialsRequest');
const getCarrierAssessorialsSuccess = createAction('getCarrierAssessorialsSuccess');
const createCarrierInsuranceRequest = createAction('createCarrierInsuranceRequest');
const updateCarrierInsuranceRequest = createAction('updateCarrierInsuranceRequest');
const updateCarrierInsuranceSuccess = createAction('updateCarrierInsuranceSuccess');
const createCarrierInsuranceSuccess = createAction('createCarrierInsuranceSuccess');
const deleteCarrierInsuranceRequest = createAction('deleteCarrierInsuranceRequest');
const deleteCarrierInsuranceSuccess = createAction('deleteCarrierInsuranceSuccess');
const getCarrierContractRatesRequest = createAction('getCarrierContractRatesRequest');
const getCarrierContractRatesSuccess = createAction('getCarrierContractRatesSuccess');
const getTerminalPayLocationsRequest = createAction('getTerminalPayLocationsRequest');
const getTerminalPayLocationsSuccess = createAction('getTerminalPayLocationsSuccess');
const createCarrierAssessorialRequest = createAction('createCarrierAssessorialRequest');
const createCarrierAssessorialSuccess = createAction('createCarrierAssessorialSuccess');
const updateCarrierAssessorialRequest = createAction('updateCarrierAssessorialRequest');
const updateCarrierAssessorialSuccess = createAction('updateCarrierAssessorialSuccess');
const deleteCarrierAssessorialRequest = createAction('deleteCarrierAssessorialRequest');
const deleteCarrierAssessorialSuccess = createAction('deleteCarrierAssessorialSuccess');
const deleteCarrierCertificateRequest = createAction('deleteCarrierCertificateRequest');
const deleteCarrierPayLocationRequest = createAction('deleteCarrierPayLocationRequest');
const deleteCarrierPayLocationSuccess = createAction('deleteCarrierPayLocationSuccess');
const getAccessorialConfigListRequest = createAction('getAccessorialConfigListRequest');
const getAccessorialConfigListSuccess = createAction('getAccessorialConfigListSuccess');
const createCarrierCertificateRequest = createAction('createCarrierCertificateRequest');
const createCarrierCertificateSuccess = createAction('createCarrierCertificateSuccess');
const createCarrierPayLocationRequest = createAction('createCarrierPayLocationRequest');
const createCarrierPayLocationSuccess = createAction('createCarrierPayLocationSuccess');
const updateCarrierPayLocationRequest = createAction('updateCarrierPayLocationRequest');
const updateCarrierPayLocationSuccess = createAction('updateCarrierPayLocationSuccess');
const updateCarrierCertificateRequest = createAction('updateCarrierCertificateRequest');
const updateCarrierCertificateSuccess = createAction('updateCarrierCertificateSuccess');
const deleteCarrierCertificateSuccess = createAction('deleteCarrierCertificateSuccess');
const createCarrierContractRateRequest = createAction('createCarrierContractRateRequest');
const createCarrierContractRateSuccess = createAction('createCarrierContractRateSuccess');
const updateCarrierContractRateRequest = createAction('updateCarrierContractRateRequest');
const updateCarrierContractRateSuccess = createAction('updateCarrierContractRateSuccess');
const deleteCarrierContractRateRequest = createAction('deleteCarrierContractRateRequest');
const deleteCarrierContractRateSuccess = createAction('deleteCarrierContractRateSuccess');
const createCarrierAssocCompanyRequest = createAction('createCarrierAssocCompanyRequest');
const createCarrierAssocCompanySuccess = createAction('createCarrierAssocCompanySuccess');
const createTerminalPayLocationRequest = createAction('createTerminalPayLocationRequest');
const createTerminalPayLocationSuccess = createAction('createTerminalPayLocationSuccess');
const updateCarrierAssocCompanyRequest = createAction('updateCarrierAssocCompanyRequest');
const updateCarrierAssocCompanySuccess = createAction('updateCarrierAssocCompanySuccess');
const updateTerminalPayLocationRequest = createAction('updateTerminalPayLocationRequest');
const updateTerminalPayLocationSuccess = createAction('updateTerminalPayLocationSuccess');
const deleteCarrierAssocCompanyRequest = createAction('deleteCarrierAssocCompanyRequest');
const deleteCarrierAssocCompanySuccess = createAction('deleteCarrierAssocCompanySuccess');
const deleteTerminalPayLocationRequest = createAction('deleteTerminalPayLocationRequest');
const deleteTerminalPayLocationSuccess = createAction('deleteTerminalPayLocationSuccess');
const removeCarrierContractRatesRequest = createAction('removeCarrierContractRatesRequest');
const getCarrierPortalLoginUsersRequest = createAction('getCarrierPortalLoginUsersRequest');
const getCarrierPortalLoginUsersSuccess = createAction('getCarrierPortalLoginUsersSuccess');
const updateCarrierGeneralDetailsRequest = createAction('updateCarrierGeneralDetailsRequest');
const createSendOnboardingPackageRequest = createAction('createSendOnboardingPackageRequest');
const createCarrierRatePriceExportRequest = createAction('createCarrierRatePriceExportRequest');
const deleteCarrierPortalLoginUserRequest = createAction('deleteCarrierPortalLoginUserRequest');
const deleteCarrierPortalLoginUserSuccess = createAction('deleteCarrierPortalLoginUserSuccess');
const getSequencesByTypeForOptionsSuccess = createAction('getSequencesByTypeForOptionsSuccess');
const addOrRemoveSharedAccessorialsRequest = createAction('addOrRemoveSharedAccessorialsRequest');
const getCarrierContractAwardedLineRequest = createAction('getCarrierContractAwardedLineRequest');
const getCarrierContractAssessorialsRequest = createAction('getCarrierContractAssessorialsRequest');
const getCarrierContractAssessorialsSuccess = createAction('getCarrierContractAssessorialsSuccess');
const getCarrierContractAwardedLinesRequest = createAction('getCarrierContractAwardedLinesRequest');
const addCarriersToWatchListByReportRequest = createAction('addCarriersToWatchListByReportRequest');
const resetCarrierContractListAndPagination = createAction('resetCarrierContractListAndPagination');
const getCarrierContractAwardedLinesSuccess = createAction('getCarrierContractAwardedLinesSuccess');
const getCustomerContractAwardedLinesRequest = createAction('getCustomerContractAwardedLinesRequest');
const getCustomerContractAwardedLinesSuccess = createAction('getCustomerContractAwardedLinesSuccess');
const handleSendCreateCarrierContractSuccess = createAction('handleSendCreateCarrierContractSuccess');
const getCustomerContractAssessorialsRequest = createAction('getCustomerContractAssessorialsRequest');
const getCustomerContractAssessorialsSuccess = createAction('getCustomerContractAssessorialsSuccess');
const handleSendCreateCustomerContractSuccess = createAction('handleSendCreateCustomerContractSuccess');
const receivedCarrierOnboardingPackageSuccess = createAction('receivedCarrierOnboardingPackageSuccess');
const createCarrierContractAssessorialRequest = createAction('createCarrierContractAssessorialRequest');
const createCarrierContractAssessorialSuccess = createAction('createCarrierContractAssessorialSuccess');
const updateCarrierContractAssessorialRequest = createAction('updateCarrierContractAssessorialRequest');
const updateCarrierContractAssessorialSuccess = createAction('updateCarrierContractAssessorialSuccess');
const deleteCarrierContractAssessorialRequest = createAction('deleteCarrierContractAssessorialRequest');
const deleteCarrierContractAssessorialSuccess = createAction('deleteCarrierContractAssessorialSuccess');
const createCarrierContractAwardedLineRequest = createAction('createCarrierContractAwardedLineRequest');
const createCarrierContractAwardedLineSuccess = createAction('createCarrierContractAwardedLineSuccess');
const updateCarrierContractAwardedLineRequest = createAction('updateCarrierContractAwardedLineRequest');
const updateCarrierContractAwardedLineSuccess = createAction('updateCarrierContractAwardedLineSuccess');
const deleteCarrierContractAwardedLineRequest = createAction('deleteCarrierContractAwardedLineRequest');
const deleteCarrierContractAwardedLineSuccess = createAction('deleteCarrierContractAwardedLineSuccess');
const createCustomerContractAssessorialRequest = createAction('createCustomerContractAssessorialRequest');
const createCustomerContractAssessorialSuccess = createAction('createCustomerContractAssessorialSuccess');
const updateCustomerContractAssessorialRequest = createAction('updateCustomerContractAssessorialRequest');
const updateCustomerContractAssessorialSuccess = createAction('updateCustomerContractAssessorialSuccess');
const deleteCustomerContractAssessorialRequest = createAction('deleteCustomerContractAssessorialRequest');
const deleteCustomerContractAssessorialSuccess = createAction('deleteCustomerContractAssessorialSuccess');
const createCustomerContractAwardedLineRequest = createAction('createCustomerContractAwardedLineRequest');
const createCustomerContractAwardedLineSuccess = createAction('createCustomerContractAwardedLineSuccess');
const updateCustomerContractAwardedLineRequest = createAction('updateCustomerContractAwardedLineRequest');
const updateCustomerContractAwardedLineSuccess = createAction('updateCarrierContractAwardedLineSuccess');
const deleteCustomerContractAwardedLineRequest = createAction('deleteCustomerContractAwardedLineRequest');
const deleteCustomerContractAwardedLineSuccess = createAction('deleteCustomerContractAwardedLineSuccess');
const createCarrierSaferWatchMassUpdateRequest = createAction('createCarrierSaferWatchMassUpdateRequest');
const getReferenceTypesByScopeForOptionsSuccess = createAction('getReferenceTypesByScopeForOptionsSuccess');
const createCarrierSaferWatchMassUpdateByReportRequest =
  createAction('createCarrierSaferWatchMassUpdateByReportRequest');
// carrier load board integration
const getLoadBoardIntegrationListRequest = createAction('getLoadBoardIntegrationListRequest');
const getLoadBoardIntegrationListSuccess = createAction('getLoadBoardIntegrationListSuccess');
const getAvailableForCarrierLoadBoardsSuccess = createAction('getAvailableForCarrierLoadBoardsSuccess');
const getAvailableForCarrierLoadBoardsRequest = createAction('getAvailableForCarrierLoadBoardsRequest');
const getParentBranchGuidsByCurrentBranchGuidRequest =
  createAction('getParentBranchGuidsByCurrentBranchGuidRequest');
const getParentBranchGuidsByCurrentBranchGuidSuccess =
  createAction('getParentBranchGuidsByCurrentBranchGuidSuccess');
// trucker tools
const addToNetworkCarriersRequest = createAction('addToNetworkCarriersRequest');
const allowBookItNowCarriersRequest = createAction('addToNetworkCarriersRequest');
const forbidBookItNowCarriersRequest = createAction('forbidBookItNowCarriersRequest');
const removeFromNetworkCarriersRequest = createAction('removeFromNetworkCarriersRequest');
const addOrRemoveToNetworkCarrierRequest = createAction('addOrRemoveToNetworkCarrierRequest');
const addToNetworkCarriersByReportRequest = createAction('addToNetworkCarriersByReportRequest');
const allowOrForbidBookItNowCarrierRequest = createAction('allowOrForbidBookItNowCarrierRequest');
const allowBookItNowCarriersByReportRequest = createAction('allowBookItNowCarriersByReportRequest');
// carrier synchronization
const syncCarrierListRequest = createAction('syncCarrierListRequest');
const syncCarrierByReportRequest = createAction('syncCarrierByReportRequest');
const addOrRemoveCarrierWatchRequest = createAction('addOrRemoveCarrierWatchRequest');
const addSyncCarriersToWatchListRequest = createAction('addSyncCarriersToWatchListRequest');
const addSyncCarriersToWatchListByReportRequest = createAction('addSyncCarriersToWatchListByReportRequest');
// additional contacts
const removeAdditionalContactRequest = createAction('removeAdditionalContactRequest');
const removeAdditionalContactSuccess = createAction('removeAdditionalContactSuccess');
const getAdditionalContactListRequest = createAction('getAdditionalContactListRequest');
const getAdditionalContactListSuccess = createAction('getAdditionalContactListSuccess');
const createOrUpdateAdditionalContactRequest = createAction('createOrUpdateAdditionalContactRequest');
const createOrUpdateAdditionalContactSuccess = createAction('createOrUpdateAdditionalContactSuccess');
// carrier integration
const getCarrierIntegrationListRequest = createAction('getCarrierIntegrationListRequest');
const getCarrierIntegrationListSuccess = createAction('getCarrierIntegrationListSuccess');
const sendCarrierListToIntegrationRequest = createAction('sendCarrierListToIntegrationRequest');
const sendCarrierListToIntegrationSuccess = createAction('sendCarrierListToIntegrationSuccess');
const getCarrierIntegrationAuditListRequest = createAction('getCarrierIntegrationAuditListRequest');
const getCarrierIntegrationAuditListSuccess = createAction('getCarrierIntegrationAuditListSuccess');
// available carrier integrations
const getAvailableCarrierIntegrationsRequest = createAction('getAvailableCarrierIntegrationsRequest');
const getAvailableCarrierIntegrationsSuccess = createAction('getAvailableCarrierIntegrationsSuccess');
// contract bill to
const getContractBillToByContractTypeRequest = createAction('getContractBillToByContractTypeRequest');
const getContractBillToByContractTypeSuccess = createAction('getContractBillToByContractTypeSuccess');
const removeContractBillToByContractTypeRequest = createAction('removeContractBillToByContractTypeRequest');
const removeContractBillToByContractTypeSuccess = createAction('removeContractBillToByContractTypeSuccess');
const createOrUpdateContractBillToByContractTypeRequest =
  createAction('createOrUpdateContractBillToByContractTypeRequest');
const createOrUpdateContractBillToByContractTypeSuccess =
  createAction('createOrUpdateContractBillToByContractTypeSuccess');
// carrier payment term
const removeCarrierPaymentTermRequest = createAction('removeCarrierPaymentTermRequest');
const removeCarrierPaymentTermSuccess = createAction('removeCarrierPaymentTermSuccess');
const getCarrierPaymentTermListRequest = createAction('getCarrierPaymentTermListRequest');
const getCarrierPaymentTermListSuccess = createAction('getCarrierPaymentTermListSuccess');
const createOrUpdateCarrierPaymentTermRequest = createAction('createOrUpdateCarrierPaymentTermRequest');
const createOrUpdateCarrierPaymentTermSuccess = createAction('createOrUpdateCarrierPaymentTermSuccess');
// carrier internal dispatch settings
const getCarrierInternalDispatchSettings = createAction('getCarrierInternalDispatchSettings');
const getCarrierInternalDispatchSettingsSuccess =
  createAction('getCarrierInternalDispatchSettingsSuccess');
const createOrUpdateCarrierInternalDispatchSettingsSuccess =
  createAction('createOrUpdateCarrierInternalDispatchSettingsSuccess');
const removeCarrierInternalDispatchSettingsSuccess =
  createAction('removeCarrierInternalDispatchSettingsSuccess');

const startCarrierInsurances = createAction('startCarrierInsurances');

export {
  // report
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  deleteItemRequest,
  deleteItemSuccess,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  setActiveTab,
  setActivePage,
  getCarrierSuccess,
  setTerminalDetails,
  createWatchRequest,
  changeBranchRequest,
  createCarrierRequest,
  updateCarrierRequest,
  updateCarrierSuccess,
  getCarrierRefRequest,
  getCarrierRefSuccess,
  receivedSafetySuccess,
  updateTerminalRequest,
  updateTerminalSuccess,
  getCarrierDocsRequest,
  getCarrierDocsSuccess,
  startCarrierInsurances,
  toggleCarrierFormGroup,
  updateCarrierDocRequest,
  deleteCarrierRefRequest,
  deleteCarrierRefSuccess,
  deleteCarrierDocSuccess,
  deleteCarrierDocRequest,
  createCarrierDocSuccess,
  updateCarrierDocSuccess,
  updateCarrierRefRequest,
  updateCarrierRefSuccess,
  createCarrierRefRequest,
  createCarrierRefSuccess,
  createCarrierDocRequest,
  receivedMailListSuccess,
  getCarrierFinanceRequest,
  getCarrierFinanceSuccess,
  getCarrierConfigsRequest,
  getCarrierConfigsSuccess,
  getGeneralConfigsRequest,
  getGeneralConfigsSuccess,
  selectCarrierContractRate,
  getCarrierMailListRequest,
  getCarrierResponseSuccess,
  getTemplateConfigsRequest,
  getTerminalFinanceRequest,
  getTerminalFinanceSuccess,
  downloadCarrierDocRequest,
  getTemplateConfigsSuccess,
  getCarrierContractRequest,
  getCarrierContractSuccess,
  addContractVariableValues,
  getCustomerContractRequest,
  getCustomerContractSuccess,
  downloadCarrierMailRequest,
  getCarrierTerminalsRequest,
  getCarrierTerminalsSuccess,
  getCarrierContractsRequest,
  getCarrierContractsSuccess,
  getCarrierInsuranceRequest,
  getCarrierInsuranceSuccess,
  getTerminalContactsRequest,
  getTerminalContactsSuccess,
  editContractVariableValues,
  updateCarrierFinanceSuccess,
  updateCarrierFinanceRequest,
  getCarrierSafetyInfoRequest,
  getCustomersContractRequest,
  getCustomersContractSuccess,
  getDispatchConfigListSuccess,
  getDispatchConfigListRequest,
  deleteContractVariableValues,
  deleteCarrierContractRequest,
  deleteCarrierContractSuccess,
  updateTerminalContactRequest,
  updateTerminalContactSuccess,
  createTerminalContactRequest,
  createTerminalContactSuccess,
  createCarrierTerminalRequest,
  createCarrierTerminalSuccess,
  updateTerminalFinanceRequest,
  deleteCarrierTerminalRequest,
  deleteCarrierTerminalSuccess,
  deleteTerminalContactRequest,
  deleteTerminalContactSuccess,
  createCarrierContractRequest,
  createCarrierContractSuccess,
  updateCarrierContractRequest,
  updateCarrierContractSuccess,
  addCarriersToWatchListRequest,
  createCustomerContractSuccess,
  updateCustomerContractRequest,
  updateCustomerContractSuccess,
  deleteCustomerContractRequest,
  deleteCustomerContractSuccess,
  getCarrierAssocCompanyRequest,
  getCarrierAssocCompanySuccess,
  getCarrierPayLocationsRequest,
  getCarrierPayLocationsSuccess,
  getCarrierCertificatesRequest,
  getCarrierCertificatesSuccess,
  getCarrierAssessorialsRequest,
  getCarrierAssessorialsSuccess,
  createCarrierInsuranceRequest,
  updateCarrierInsuranceRequest,
  updateCarrierInsuranceSuccess,
  createCarrierInsuranceSuccess,
  deleteCarrierInsuranceRequest,
  deleteCarrierInsuranceSuccess,
  getCarrierContractRatesRequest,
  getCarrierContractRatesSuccess,
  getTerminalPayLocationsRequest,
  getTerminalPayLocationsSuccess,
  createCarrierAssessorialRequest,
  createCarrierAssessorialSuccess,
  updateCarrierAssessorialRequest,
  updateCarrierAssessorialSuccess,
  deleteCarrierAssessorialRequest,
  deleteCarrierAssessorialSuccess,
  deleteCarrierCertificateRequest,
  deleteCarrierPayLocationRequest,
  deleteCarrierPayLocationSuccess,
  getAccessorialConfigListRequest,
  getAccessorialConfigListSuccess,
  createCarrierCertificateRequest,
  createCarrierCertificateSuccess,
  createCarrierPayLocationRequest,
  createCarrierPayLocationSuccess,
  updateCarrierPayLocationRequest,
  updateCarrierPayLocationSuccess,
  updateCarrierCertificateRequest,
  updateCarrierCertificateSuccess,
  deleteCarrierCertificateSuccess,
  createCarrierContractRateRequest,
  createCarrierContractRateSuccess,
  updateCarrierContractRateRequest,
  updateCarrierContractRateSuccess,
  deleteCarrierContractRateRequest,
  deleteCarrierContractRateSuccess,
  createCarrierAssocCompanyRequest,
  createCarrierAssocCompanySuccess,
  createTerminalPayLocationRequest,
  createTerminalPayLocationSuccess,
  updateCarrierAssocCompanyRequest,
  updateCarrierAssocCompanySuccess,
  updateTerminalPayLocationRequest,
  updateTerminalPayLocationSuccess,
  deleteCarrierAssocCompanyRequest,
  deleteCarrierAssocCompanySuccess,
  deleteTerminalPayLocationRequest,
  deleteTerminalPayLocationSuccess,
  removeCarrierContractRatesRequest,
  getCarrierPortalLoginUsersRequest,
  getCarrierPortalLoginUsersSuccess,
  updateCarrierGeneralDetailsRequest,
  createSendOnboardingPackageRequest,
  createCarrierRatePriceExportRequest,
  deleteCarrierPortalLoginUserRequest,
  deleteCarrierPortalLoginUserSuccess,
  getSequencesByTypeForOptionsSuccess,
  addOrRemoveSharedAccessorialsRequest,
  getCarrierContractAwardedLineRequest,
  getCarrierContractAssessorialsRequest,
  getCarrierContractAssessorialsSuccess,
  getCarrierContractAwardedLinesRequest,
  addCarriersToWatchListByReportRequest,
  resetCarrierContractListAndPagination,
  getCarrierContractAwardedLinesSuccess,
  getCustomerContractAwardedLinesRequest,
  getCustomerContractAwardedLinesSuccess,
  handleSendCreateCarrierContractSuccess,
  getCustomerContractAssessorialsRequest,
  getCustomerContractAssessorialsSuccess,
  handleSendCreateCustomerContractSuccess,
  receivedCarrierOnboardingPackageSuccess,
  createCarrierContractAssessorialRequest,
  createCarrierContractAssessorialSuccess,
  updateCarrierContractAssessorialRequest,
  updateCarrierContractAssessorialSuccess,
  deleteCarrierContractAssessorialRequest,
  deleteCarrierContractAssessorialSuccess,
  createCarrierContractAwardedLineRequest,
  createCarrierContractAwardedLineSuccess,
  updateCarrierContractAwardedLineRequest,
  updateCarrierContractAwardedLineSuccess,
  deleteCarrierContractAwardedLineRequest,
  deleteCarrierContractAwardedLineSuccess,
  createCustomerContractAssessorialRequest,
  createCustomerContractAssessorialSuccess,
  updateCustomerContractAssessorialRequest,
  updateCustomerContractAssessorialSuccess,
  deleteCustomerContractAssessorialRequest,
  deleteCustomerContractAssessorialSuccess,
  createCustomerContractAwardedLineRequest,
  createCustomerContractAwardedLineSuccess,
  updateCustomerContractAwardedLineRequest,
  updateCustomerContractAwardedLineSuccess,
  deleteCustomerContractAwardedLineRequest,
  deleteCustomerContractAwardedLineSuccess,
  createCarrierSaferWatchMassUpdateRequest,
  getReferenceTypesByScopeForOptionsSuccess,
  createCarrierSaferWatchMassUpdateByReportRequest,
  // carrier load board integration
  getLoadBoardIntegrationListRequest,
  getLoadBoardIntegrationListSuccess,
  getAvailableForCarrierLoadBoardsSuccess,
  getAvailableForCarrierLoadBoardsRequest,
  getParentBranchGuidsByCurrentBranchGuidRequest,
  getParentBranchGuidsByCurrentBranchGuidSuccess,
  // trucker tools
  addToNetworkCarriersRequest,
  allowBookItNowCarriersRequest,
  forbidBookItNowCarriersRequest,
  removeFromNetworkCarriersRequest,
  addOrRemoveToNetworkCarrierRequest,
  addToNetworkCarriersByReportRequest,
  allowOrForbidBookItNowCarrierRequest,
  allowBookItNowCarriersByReportRequest,
  // carrier synchronization
  syncCarrierListRequest,
  syncCarrierByReportRequest,
  addOrRemoveCarrierWatchRequest,
  addSyncCarriersToWatchListRequest,
  addSyncCarriersToWatchListByReportRequest,
  // additional contacts
  removeAdditionalContactRequest,
  removeAdditionalContactSuccess,
  getAdditionalContactListRequest,
  getAdditionalContactListSuccess,
  createOrUpdateAdditionalContactRequest,
  createOrUpdateAdditionalContactSuccess,
  // carrier integration
  getCarrierIntegrationListRequest,
  getCarrierIntegrationListSuccess,
  sendCarrierListToIntegrationRequest,
  sendCarrierListToIntegrationSuccess,
  getCarrierIntegrationAuditListRequest,
  getCarrierIntegrationAuditListSuccess,
  // available carrier integrations
  getAvailableCarrierIntegrationsRequest,
  getAvailableCarrierIntegrationsSuccess,
  // contract bill to
  getContractBillToByContractTypeRequest,
  getContractBillToByContractTypeSuccess,
  removeContractBillToByContractTypeRequest,
  removeContractBillToByContractTypeSuccess,
  createOrUpdateContractBillToByContractTypeRequest,
  createOrUpdateContractBillToByContractTypeSuccess,
  // carrier payment term
  removeCarrierPaymentTermRequest,
  removeCarrierPaymentTermSuccess,
  getCarrierPaymentTermListRequest,
  getCarrierPaymentTermListSuccess,
  createOrUpdateCarrierPaymentTermRequest,
  createOrUpdateCarrierPaymentTermSuccess,
  // carrier internal dispatch settings
  getCarrierInternalDispatchSettings,
  getCarrierInternalDispatchSettingsSuccess,
  removeCarrierInternalDispatchSettingsSuccess,
  createOrUpdateCarrierInternalDispatchSettingsSuccess,
};
