import { createLogic } from 'redux-logic';
// common
import { globalCleanReports } from '../../common/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// feature dispatch-group
import { setInitialState } from './actions';
//////////////////////////////////////////////////

export const handleSwitchBranchLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    receivedSwitchBranchSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(setInitialState());
    done();
  },
});

export default [handleSwitchBranchLogic];
