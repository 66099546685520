import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { FormSectionHeader } from '../../../components/form-section-header';
// ui
import { Box, CustomButton, Text } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpen: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <Box>
          <FormSectionHeader expanded={true} title='First Section Header'>
            <Text>Content...</Text>
          </FormSectionHeader>
          <FormSectionHeader expanded={false} title='Second Section Header'>
            <Text>Content...</Text>
          </FormSectionHeader>
        </Box>
      );

      const modal = {
        component,
        p: '0px 0px 70px 0px',
        options: {
          width: 400,
          version: 2,
          movable: false,
          title: 'Modal title',
          controlButtons: [
            {
              name: 'ok',
              type: 'button',
              action: closeModal,
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const FormSectionHeaderComponent = (props: Object) => {
  const description = '<FormSectionHeader /> component provides a header title for a form section with the ability to expand or collapse additional content.';

  const usage = (
    <ul>
      <li>Parameter `<i>title</i>` is a title displayed in the header.</li>
      <li>Parameter `<i>additionalStyles</i>` is an additional styles for the header section.</li>
      <li>Parameter `<i>action</i>` is a callback function triggered by clicking the action button.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='FormSectionHeader'
      description={description}
      path='src/components/form-section-header'
    >
      <CustomButton onClick={props.handleOpen}>Open modal with `Form Section Header`</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(FormSectionHeaderComponent));
