import React, { useState } from 'react';
// components
import { MuiTextInput } from '../../../components/mui-text-input';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const MuiTextInputComponent = () => {
  const description = '<MuiTextInput /> component provides a customizable text input field';

  const [inputValue, setInputValue] = useState('');

  const usage = (
    <ul>
      <li>Parameter `<i>type</i>` specifies the type of input.</li>
      <li>Parameter `<i>value</i>` is the current value of the input field.</li>
      <li>Parameter `<i>inputStyles</i>` custom styles for the input field.</li>
      <li>Parameter `<i>placeholder</i>` placeholder text for the input field.</li>
      <li>Parameter `<i>label</i>` is the label displayed above the input field.</li>
      <li>Parameter `<i>readOnly</i>` determines if the input field is read-only.</li>
      <li>Set `<i>onClick</i>` event handler for click events on the input field.</li>
      <li>Parameter `<i>onChange</i>` callback function triggered on input value change.</li>
      <li>Set `<i>onKeyPress</i>` event handler for key press events within the input field.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='MuiTextInput'
      description={description}
      path='src/components/mui-text-input'
    >
      <MuiTextInput
        type='text'
        label='Username'
        value={inputValue}
        placeholder='Enter your username'
        onChange={(e: Object) => setInputValue(e.target.value)}
      />
    </ComponentStoryWrapper>
  );
};

export default MuiTextInputComponent;
