import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_ACTIVE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:active',
    customComponent: ({ data }: Object) => G.ifElse(
      R.prop(GC.FIELD_ACTIVE, data),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [GC.GRC.MODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:mode',
  },
  [GC.GRC.CARRIER_NAME]: {
    width: 250,
    name: 'titles:carrier-name',
  },
  [GC.GRC.CARRIER_BRANCH_NAME]: {
    width: 200,
    name: 'titles:carrier-branch',
  },
  [GC.GRC.CARRIER_ACTIVE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:carrier-active',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 200,
    name: 'titles:min-rate',
  },
  [GC.FIELD_EFFECTIVE_DATE]: {
    name: 'titles:effective-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    name: 'titles:expiration-date',
  },
  [`${GC.SYSTEM_OBJECT_INTEGRATION_CONFIG}.${GC.FIELD_INTEGRATION_TYPE}`]: {
    width: 200,
    name: 'titles:integration-type',
    customComponent: ({ data }: Object) => G.getEnumLocale(
      R.prop(`${GC.SYSTEM_OBJECT_INTEGRATION_CONFIG}.${GC.FIELD_INTEGRATION_TYPE}`, data),
    ),
  },
};
