import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../../../components/table';
import { PageTitle } from '../../../../../components/page-title';
import { ConfirmComponent } from '../../../../../components/confirm';
import { FormGroupTitleComponent } from '../../../../../components/form-group-title';
// forms
import { FormGroupWrapper } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withConnectModalAndLoaderActions } from '../../../../../hocs';
// icons
import * as I from '../../../../../svgs';
// ui
import { Flex, RelativeBox, ActionButton } from '../../../../../ui';
// utilities
import { sendRequest } from '../../../../../utilities/http';
import endpointsMap from '../../../../../utilities/endpoints';
// feature fleet-driver
import FormComponent from './form';
import {
  noteColumnSettings,
  documentColumnSettings,
  safetyViolationColumnSettings,
} from './column-settings';
import {
  toggleSafetyViolationItem,
  getSafetyViolationListByEntityTypeSuccess,
  removeSafetyViolationEntityByEntityTypeRequest,
  createOrUpdateSafetyViolationEntityByEntityTypeRequest,
} from '../../../actions';
//////////////////////////////////////////////////

const tableSettings = {
  maxHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 40,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  allowSelectItems: false,
  minHeight: 'max-content',
  emptyListPadding: '10px 0',
};

const formGroupTableEnhance = compose(
  withConnectModalAndLoaderActions,
  connect(
    null,
    {
      getSafetyViolationListByEntityTypeSuccess,
      removeSafetyViolationEntityByEntityTypeRequest,
      createOrUpdateSafetyViolationEntityByEntityTypeRequest,
    },
  ),
  withHandlers({
    handleRemoveEntityByEntityType: (props: Object) => ({ guid }: Object) => {
      const {
        openModal,
        entityType,
        primaryObjectGuid,
        removeSafetyViolationEntityByEntityTypeRequest,
      } = props;

      const titleMap = {
        note: ['messages:remove-ref-confirmation-text', 'Are you sure you want delete this note?'],
        document: [
          'messages:documents:remove-confirmation-text',
          'Are you sure you want delete this document?',
        ],
        safetyViolation: [
          'messages:remove-safety-violation-confirmation-text',
          'Are you sure you want delete this safety violation?',
        ],
      };

      const component = <ConfirmComponent textLocale={G.getWindowLocale(...R.pathOr([], [entityType], titleMap))} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:remove', 'Remove'),
              action: () => removeSafetyViolationEntityByEntityTypeRequest({ guid, entityType, primaryObjectGuid }),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleCreateOrUpdateEntityByEntityType: (props: Object) => (entity: Object) => {
      const {
        openModal,
        entityType,
        closeModal,
        optionsForSelect,
        createOrUpdateSafetyViolationEntityByEntityTypeRequest,
      } = props;

      const component = (
        <FormComponent
          entity={entity}
          closeModal={closeModal}
          entityType={entityType}
          optionsForSelect={optionsForSelect}
          submitAction={(values: Object) =>
            createOrUpdateSafetyViolationEntityByEntityTypeRequest({ values, entityType })
          }
        />
      );

      const titleArrMap = {
        note: ['titles:note', 'note'],
        document: ['titles:document', 'Document'],
        safetyViolation: ['titles:safety-violation', 'Safety Violation'],
      };

      const modal = {
        p: 15,
        component,
        options: {
          title: G.ifElse(
            G.isNotNilAndNotEmpty(entity.version),
            G.getEditTitle,
            G.getAddTitle,
          )(G.getPropFromObject(entityType, titleArrMap)),
        },
      };

      openModal(modal);
    },
    getSafetyViolationListByEntityTypeRequest: (props: Object) => async () => {
      const {
        itemList,
        entityType,
        openLoader,
        closeLoader,
        primaryObjectGuid,
        primaryObjectGuidKey,
        getSafetyViolationListByEntityTypeSuccess,
      } = props;

      if (G.isNotNil(itemList)) return;

      openLoader();

      const safetyViolationEndpointsMap = {
        note: endpointsMap.safetyViolationNoteList,
        safetyViolation: endpointsMap.safetyViolationList,
        document: endpointsMap.safetyViolationDocumentList,
      };

      const endpoint = G.getPropFromObject(entityType, safetyViolationEndpointsMap);

      const options = {
        params: {
          [primaryObjectGuidKey]: primaryObjectGuid,
        },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        getSafetyViolationListByEntityTypeSuccess({ data, entityType, primaryObjectGuid });
      } else {
        G.handleFailResponseSimple(res, 'getSafetyViolationListByEntityTypeRequest fail');
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getSafetyViolationListByEntityTypeRequest();
    },
  }),
  pure,
);

const FormGroupTable = formGroupTableEnhance((props: Object) => {
  const {
    title,
    margin,
    itemList,
    openLoader,
    entityType,
    closeLoader,
    addButtonText,
    tableSettings,
    columnSettings,
    addButtonWidth,
    isOpened = true,
    optionsForSelect,
    primaryObjectGuid,
    handleDownloadFile,
    primaryObjectGuidKey,
    handleOpenFilePreview,
    withArrowDown = false,
    handleClickToggleGroup,
    useFormGroupTitle = false,
    toggleSafetyViolationItem,
    handleRemoveEntityByEntityType,
    handleCreateOrUpdateEntityByEntityType,
  } = props;

  const handleCreateEntity = () =>
    handleCreateOrUpdateEntityByEntityType({ [primaryObjectGuidKey]: primaryObjectGuid });

  return (
    <FormGroupWrapper m={margin} isOpened={isOpened}>
      {
        G.isTrue(useFormGroupTitle) &&
        <FormGroupTitleComponent
          mb='0px'
          text={title}
          isOpened={isOpened}
          withArrowDown={withArrowDown}
          onClickLabel={handleCreateEntity}
          onToggleFormGroup={handleClickToggleGroup}
          endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
        />
      }
      {
        G.isFalse(useFormGroupTitle) &&
        <Flex>
          <PageTitle title={title} />
          <ActionButton
            ml={20}
            height={30}
            borderRadius='3px'
            width={addButtonWidth}
            onClick={handleCreateEntity}
          >
            {addButtonText}
          </ActionButton>
        </Flex>
      }
      {
        G.isTrue(isOpened) &&
        <Table
          itemList={itemList}
          tableSettings={tableSettings}
          columnSettings={columnSettings}
          toggle={({ guid }: Object) => toggleSafetyViolationItem({ guid, componentType: 'all' })}
          callbackData={{
            openLoader,
            entityType,
            closeLoader,
            optionsForSelect,
            primaryObjectGuid,
            handleDownloadFile,
            primaryObjectGuidKey,
            handleOpenFilePreview,
            toggleSafetyViolationItem,
            handleRemoveEntityByEntityType,
            handleCreateOrUpdateEntityByEntityType,
          }}
          report={{
            fields: R.prepend(
              {
                sequence: 0,
                freezed: true,
                name: 'actions',
                reference: false,
              },
              G.mapIndexed(
                (name: string, sequence: number) => ({ name, sequence: R.inc(sequence) }),
                R.tail(R.keys(columnSettings)),
              ),
            ),
          }}
        />
      }
    </FormGroupWrapper>
  );
});

const Notes = (props: Object) => (
  <FormGroupTable
    margin={20}
    entityType='note'
    itemList={props.itemList}
    tableSettings={tableSettings}
    columnSettings={noteColumnSettings}
    primaryObjectGuidKey='safetyViolationGuid'
    primaryObjectGuid={props.primaryObjectGuid}
    title={G.getWindowLocale('titles:notes', 'Notes')}
    addButtonText={G.getWindowLocale('actions:add-note', 'Add Note')}
  />
);

const Documents = (props: Object) => (
  <FormGroupTable
    margin={20}
    addButtonWidth={120}
    entityType='document'
    itemList={props.itemList}
    tableSettings={tableSettings}
    columnSettings={documentColumnSettings}
    optionsForSelect={props.optionsForSelect}
    primaryObjectGuid={props.primaryObjectGuid}
    primaryObjectGuidKey={GC.FIELD_PRIMARY_OBJECT_GUID}
    title={G.getWindowLocale('titles:documents', 'Documents')}
    addButtonText={G.getWindowLocale('actions:add-document', 'Add Document')}
  />
);

const getItemListByListName = (props: Object, listName: string = 'notes') => {
  const list = R.prop(listName, props);

  if (R.isNil(list)) return list;

  return R.sortBy(R.prop('index'), R.values(list));
};

const ExpandedDetailsComponent = ({ parentProps, callbackData }: Object) => {
  const { guid, componentType } = parentProps;

  return (
    <RelativeBox zIndex={1}>
      {
        R.includes(componentType, ['all', 'document']) &&
        <Documents
          {...callbackData}
          primaryObjectGuid={guid}
          itemList={getItemListByListName(parentProps, 'documents')}
        />
      }
      {
        R.includes(componentType, ['all', 'note']) &&
        <Notes
          {...callbackData}
          primaryObjectGuid={guid}
          itemList={getItemListByListName(parentProps)}
        />
      }
    </RelativeBox>
  );
};

const SafetyViolation = (props: Object) => {
  const {
    isOpened,
    driverGuid,
    optionsForSelect,
    handleToggleFormGroup,
    toggleSafetyViolationItem,
  } = props;

  const safetyViolationTableSettings = {
    ...tableSettings,
    expandableItems: true,
    checkBoxCellWidth: 27,
    maxHeight: 'calc(100vh - 90px)',
    expandedDetailsComponent: ExpandedDetailsComponent,
  };

  return (
    <FormGroupTable
      isOpened={isOpened}
      useFormGroupTitle={true}
      entityType='safetyViolation'
      primaryObjectGuid={driverGuid}
      optionsForSelect={optionsForSelect}
      primaryObjectGuidKey={GC.FIELD_DRIVER_GUID}
      tableSettings={safetyViolationTableSettings}
      columnSettings={safetyViolationColumnSettings}
      toggleSafetyViolationItem={toggleSafetyViolationItem}
      itemList={getItemListByListName(props, 'safetyViolationList')}
      handleClickToggleGroup={() => handleToggleFormGroup('safetyViolation')}
      title={G.getWindowLocale('titles:safety-violation', 'Safety Violation')}
    />
  );
};

export default connect(null, { toggleSafetyViolationItem })(SafetyViolation);
