import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// common
export const setInitialState = createAction('setInitialState');
export const changeActiveList = createAction('changeActiveList');
export const getLoadDetailsSuccess = createAction('getLoadDetailsSuccess');
export const getLoadDetailsRequest = createAction('getLoadDetailsRequest');
export const changeActiveListSuccess = createAction('changeActiveListSuccess');
export const setDispatchPlannerOpened = createAction('setDispatchPlannerOpened');
export const updateLoadDetailsRequest = createAction('updateLoadDetailsRequest');
export const updateLoadDetailsSuccess = createAction('updateLoadDetailsSuccess');
export const toggleExpandedListsSuccess = createAction('toggleExpandedListsSuccess');
export const getLoadInvoiceTotalsRequest = createAction('getLoadInvoiceTotalsRequest');
export const getLoadInvoiceTotalsSuccess = createAction('getLoadInvoiceTotalsSuccess');
export const setDispatchPlannerEventsOpened = createAction('setDispatchPlannerEventsOpened');
export const getAvailableLoadCustomStatusListRequest = createAction('getAvailableLoadCustomStatusListRequest');
export const getAvailableLoadCustomStatusListSuccess = createAction('getAvailableLoadCustomStatusListSuccess');
// order
export const getLinkedOrderListRequest = createAction('getLinkedOrderListRequest');
export const getLinkedOrderListSuccess = createAction('getLinkedOrderListSuccess');
export const getRelatedOrderListRequest = createAction('getRelatedOrderListRequest');
export const getRelatedOrderListSuccess = createAction('getRelatedOrderListSuccess');
export const getCloReferencesByEventGuidsRequest = createAction('getCloReferencesByEventGuidsRequest');
export const getCloReferencesByEventGuidsSuccess = createAction('getCloReferencesByEventGuidsSuccess');
// containers
export const getLoadContainerListSuccess = createAction('getLoadContainerListSuccess');
// items
export const getLoadItemListRequest = createAction('getLoadItemListRequest');
export const getLoadItemListSuccess = createAction('getLoadItemListSuccess');
export const toggleLoadVehicleItemRequest = createAction('toggleLoadVehicleItemRequest');
export const toggleLoadVehicleItemSuccess = createAction('toggleLoadVehicleItemSuccess');
export const removeLoadVehicleItemDamageSuccess = createAction('removeLoadVehicleItemDamageSuccess');
export const createOrUpdateLoadVehicleItemDamageSuccess = createAction('createOrUpdateLoadVehicleItemDamageSuccess');
// notifications
export const hideLoadNotificationsSuccess = createAction('hideLoadNotificationsSuccess');
export const hideLoadNotificationsRequest = createAction('hideLoadNotificationsRequest');
export const getLoadNotificationListRequest = createAction('getLoadNotificationListRequest');
export const getLoadNotificationListSuccess = createAction('getLoadNotificationListSuccess');
export const markLoadNotificationAsReadRequest = createAction('markNotificationAsReadRequest');
export const markLoadNotificationAsReadSuccess = createAction('markNotificationAsReadSuccess');
// mails
export const getLoadMailListRequest = createAction('getLoadMailListRequest');
export const getLoadMailListSuccess = createAction('getLoadMailListSuccess');
// load board
export const getLoadBoardListRequest = createAction('getLoadBoardListRequest');
export const getLoadBoardListSuccess = createAction('getLoadBoardListSuccess');
export const getPostedShipmentsRequest = createAction('getPostedShipmentsRequest');
export const getPostedShipmentsSuccess = createAction('getPostedShipmentsSuccess');
export const deletePostedShipmentRequest = createAction('deletePostedShipmentRequest');
export const deletePostedShipmentSuccess = createAction('deletePostedShipmentSuccess');
export const updatePostedShipmentStatesRequest = createAction('updatePostedShipmentStatesRequest');
// references
export const updateLoadReferenceRequest = createAction('updateLoadReferenceRequest');
export const updateLoadReferenceSuccess = createAction('updateLoadReferenceSuccess');
export const createLoadReferenceRequest = createAction('createLoadReferenceRequest');
export const createLoadReferenceSuccess = createAction('createLoadReferenceSuccess');
export const removeLoadReferenceRequest = createAction('removeLoadReferenceRequest');
export const removeLoadReferenceSuccess = createAction('removeLoadReferenceSuccess');
export const getLoadReferenceListRequest = createAction('getLoadReferenceListRequest');
export const getLoadReferenceListSuccess = createAction('getLoadReferenceListSuccess');
// linked order references
export const getLinkedOrderRefListRequest = createAction('getLinkedOrderRefListRequest');
export const getLinkedOrderRefListSuccess = createAction('getLinkedOrderRefListSuccess');
// customer rates
export const selectLoadCustomerRateRequest = createAction('selectLoadCustomerRateRequest');
export const selectLoadCustomerRateSuccess = createAction('selectLoadCustomerRateSuccess');
export const removeLoadCustomerRateRequest = createAction('removeLoadCustomerRateRequest');
export const removeLoadCustomerRateSuccess = createAction('removeLoadCustomerRateSuccess');
export const updateLoadCustomerRateSuccess = createAction('updateLoadCustomerRateSuccess');
export const updateLoadCustomerRateRequest = createAction('updateLoadCustomerRateRequest');
export const createLoadCustomerRateSuccess = createAction('createLoadCustomerRateSuccess');
export const createLoadCustomerRateRequest = createAction('createLoadCustomerRateRequest');
export const getLoadCustomerRateListRequest = createAction('getLoadCustomerRateListRequest');
export const getLoadCustomerRateListSuccess = createAction('getLoadCustomerRateListSuccess');
// status messages
export const removeLoadStatusMessageRequest = createAction('removeLoadStatusMessageRequest');
export const removeLoadStatusMessageSuccess = createAction('removeLoadStatusMessageSuccess');
export const updateLoadStatusMessageRequest = createAction('updateLoadStatusMessageRequest');
export const createLoadStatusMessageRequest = createAction('createLoadStatusMessageRequest');
export const createLoadStatusMessageSuccess = createAction('createLoadStatusMessageSuccess');
export const updateLoadStatusMessageSuccess = createAction('updateLoadStatusMessageSuccess');
export const getLoadStatusMessageListRequest = createAction('getLoadStatusMessageListRequest');
export const getLoadStatusMessageListSuccess = createAction('getLoadStatusMessageListSuccess');
export const createLoadMultipleStatusMessageSuccess = createAction('createLoadMultipleStatusMessageSuccess');
export const createLoadMultipleStatusMessageRequest = createAction('createLoadMultipleStatusMessageRequest');
// driver invoices
export const updateDriverInvoiceRequest = createAction('updateDriverInvoiceRequest');
export const createDriverInvoiceRequest = createAction('createDriverInvoiceRequest');
export const createDriverInvoiceSuccess = createAction('createDriverInvoiceSuccess');
export const printLoadDriverInvoiceRequest = createAction('printLoadDriverInvoiceRequest');
export const updateLoadDriverInvoiceSuccess = createAction('updateLoadDriverInvoiceSuccess');
export const removeLoadDriverInvoiceSuccess = createAction('removeLoadDriverInvoiceSuccess');
export const removeLoadDriverInvoiceRequest = createAction('removeLoadDriverInvoiceRequest');
export const getLoadDriverInvoiceListRequest = createAction('getLoadDriverInvoiceListRequest');
export const getLoadDriverInvoiceListSuccess = createAction('getLoadDriverInvoiceListSuccess');
export const sendDriverInvoiceToFinancialRequest = createAction('sendDriverInvoiceToFinancialRequest');
// carrier invoices
export const sendCarrierToFinancialRequest = createAction('sendCarrierToFinancialRequest');
export const printLoadCarrierInvoiceRequest = createAction('printLoadCarrierInvoiceRequest');
export const removeLoadCarrierInvoiceRequest = createAction('removeLoadCarrierInvoiceRequest');
export const removeLoadCarrierInvoiceSuccess = createAction('removeLoadCarrierInvoiceSuccess');
export const updateLoadCarrierInvoiceSuccess = createAction('updateLoadCarrierInvoiceSuccess');
export const updateLoadCarrierInvoiceRequest = createAction('updateLoadCarrierInvoiceRequest');
export const createLoadCarrierInvoiceSuccess = createAction('createLoadCarrierInvoiceSuccess');
export const createLoadCarrierInvoiceRequest = createAction('createLoadCarrierInvoiceRequest');
export const getLoadCarrierInvoiceListRequest = createAction('getLoadCarrierInvoiceListRequest');
export const getLoadCarrierInvoiceListSuccess = createAction('getLoadCarrierInvoiceListSuccess');
// service vendor invoices
export const printServiceVendorInvoiceRequest = createAction('printServiceVendorInvoiceRequest');
export const createServiceVendorInvoiceRequest = createAction('createServiceVendorInvoiceRequest');
export const createServiceVendorInvoiceSuccess = createAction('createServiceVendorInvoiceSuccess');
export const updateServiceVendorInvoiceRequest = createAction('updateServiceVendorInvoiceRequest');
export const updateServiceVendorInvoiceSuccess = createAction('updateServiceVendorInvoiceSuccess');
export const removeServiceVendorInvoiceRequest = createAction('removeServiceVendorInvoiceRequest');
export const removeServiceVendorInvoiceSuccess = createAction('removeServiceVendorInvoiceSuccess');
export const getServiceVendorInvoiceListRequest = createAction('getServiceVendorInvoiceListRequest');
export const getServiceVendorInvoiceListSuccess = createAction('getServiceVendorInvoiceListSuccess');
export const sendServiceVendorInvoiceToFinancialRequest = createAction('sendServiceVendorInvoiceToFinancialRequest');
// customer invoices
export const sendCIToFinancialRequest = createAction('sendCIToFinancialRequest');
export const printLoadCustomerInvoiceRequest = createAction('printLoadCustomerInvoiceRequest');
export const updateLoadCustomerInvoiceRequest = createAction('updateLoadCustomerInvoiceRequest');
export const updateLoadCustomerInvoiceSuccess = createAction('updateLoadCustomerInvoiceSuccess');
export const createLoadCustomerInvoiceSuccess = createAction('createLoadCustomerInvoiceSuccess');
export const removeLoadCustomerInvoiceSuccess = createAction('removeLoadCustomerInvoiceSuccess');
export const createLoadCustomerInvoiceRequest = createAction('createLoadCustomerInvoiceRequest');
export const removeLoadCustomerInvoiceRequest = createAction('removeLoadCustomerInvoiceRequest');
export const getLoadCustomerInvoiceListRequest = createAction('getLoadCustomerInvoiceListRequest');
export const getLoadCustomerInvoiceListSuccess = createAction('getLoadCustomerInvoiceListSuccess');
export const getLinkedCustomerInvoiceListRequest = createAction('getLinkedCustomerInvoiceListRequest');
export const getLinkedCustomerInvoiceListSuccess = createAction('getLinkedCustomerInvoiceListSuccess');
// advance payment
export const createLoadAdvancePaymentRequest = createAction('createLoadAdvancePaymentRequest');
export const createLoadAdvancePaymentSuccess = createAction('createLoadAdvancePaymentSuccess');
export const getLoadAdvancePaymentListRequest = createAction('getLoadAdvancePaymentListRequest');
export const getLoadAdvancePaymentListSuccess = createAction('getLoadAdvancePaymentListSuccess');
export const updateLoadAdvancePaymentStatusSuccess = createAction('updateLoadAdvancePaymentStatusSuccess');
export const updateLoadAdvancePaymentStatusRequest = createAction('updateLoadAdvancePaymentStatusRequest');
// macro point
export const getMacroPointListRequest = createAction('getMacroPointListRequest');
export const getMacroPointListSuccess = createAction('getMacroPointListSuccess');
// driver/carrier rates
export const sendUpdateEdiOrApiRequest = createAction('sendUpdateEdiOrApiRequest');
export const createLoadDriverRateRequest = createAction('createLoadDriverRateRequest');
export const updateLoadDriverRateRequest = createAction('updateLoadDriverRateRequest');
export const removeLoadDriverRateRequest = createAction('removeLoadDriverRateRequest');
export const createLoadCarrierRateRequest = createAction('createLoadCarrierRateRequest');
export const updateLoadCarrierRateRequest = createAction('updateLoadCarrierRateRequest');
export const createLoadCarrierRateSuccess = createAction('createLoadCarrierRateSuccess');
export const removeLoadCarrierRateRequest = createAction('removeLoadCarrierRateRequest');
export const selectDriverCarrierRateSuccess = createAction('selectDriverCarrierRateSuccess');
export const selectDriverCarrierRateRequest = createAction('selectDriverCarrierRateRequest');
export const acceptLoadDriverCarrierRateRequest = createAction('acceptLoadDriverCarrierRateRequest');
export const updateLoadDriverCarrierRateSuccess = createAction('updateLoadDriverCarrierRateSuccess');
export const removeLoadDriverCarrierRateSuccess = createAction('removeLoadDriverCarrierRateSuccess');
export const createLoadDriverCarrierRateSuccess = createAction('createLoadDriverCarrierRateSuccess');
export const declineLoadDriverCarrierRateRequest = createAction('declineLoadDriverCarrierRateRequest');
export const getLoadDriverCarrierRateListRequest = createAction('getLoadDriverCarrierRateListRequest');
export const getLoadDriverCarrierRateListSuccess = createAction('getLoadDriverCarrierRateListSuccess');
// events
export const getLoadEventsRequest = createAction('getLoadEventsRequest');
export const getLoadEventsSuccess = createAction('getLoadEventsSuccess');
export const updateAppointmentRequest = createAction('updateAppointmentRequest');
export const updateAppointmentSuccess = createAction('updateAppointmentSuccess');
// configs
export const getLoadConfigsByNamesRequest = createAction('getLoadConfigsByNamesRequest');
export const getLoadConfigsByNamesSuccess = createAction('getLoadConfigsByNamesSuccess');
export const getLoadConfigByGroupNamesSuccess = createAction('getLoadConfigByGroupNamesSuccess');
export const getLoadConfigByGroupNamesRequest = createAction('getLoadConfigByGroupNamesRequest');
export const getStatusMessagesConfigListSuccess = createAction('getStatusMessagesConfigListSuccess');
export const getStatusMessagesConfigListRequest = createAction('getStatusMessagesConfigListRequest');
export const getDataAndConfigsByBranchGuidRequest = createAction('getDataAndConfigsByBranchGuidRequest');
// documents
export const printLoadRequest = createAction('printLoadRequest');
export const getLoadXMLRequest = createAction('getLoadXMLRequest');
export const printDocumentRequest = createAction('printDocumentRequest');
export const printLoadDocumentsRequest = createAction('printLoadDocumentsRequest');
export const removeLoadDocumentRequest = createAction('removeLoadDocumentRequest');
export const removeLoadDocumentSuccess = createAction('removeLoadDocumentSuccess');
export const updateLoadDocumentRequest = createAction('updateLoadDocumentRequest');
export const updateLoadDocumentSuccess = createAction('updateLoadDocumentSuccess');
export const getLoadDocumentListRequest = createAction('getLoadDocumentListRequest');
export const getLoadDocumentListSuccess = createAction('getLoadDocumentListSuccess');
export const downloadLoadDocumentRequest = createAction('downloadLoadDocumentRequest');
export const setDocumentTemplatesToStore = createAction('setDocumentTemplatesToStore');
export const getLoadLinkedOrdersDocumentListRequest = createAction('getLoadLinkedOrdersDocumentListRequest');
export const getLoadLinkedOrdersDocumentListSuccess = createAction('getLoadLinkedOrdersDocumentListSuccess');
// dispatch/approve
export const approveLoadRateRequest = createAction('approveLoadRateRequest');
export const dispatchLoadRateRequest = createAction('dispatchLoadRateRequest');
export const printDispatchDocumentRequest = createAction('printDispatchDocumentRequest');
export const dispatchLoadCarrierRateRequest = createAction('dispatchLoadCarrierRateRequest');
export const suspendResumeCarrierRateEmailsRequest = createAction('suspendResumeCarrierRateEmailsRequest');
// message center
export const removeLoadNoteRequest = createAction('removeLoadNoteRequest');
export const removeLoadNoteSuccess = createAction('removeLoadNoteSuccess');
export const createLoadNoteRequest = createAction('createLoadNoteRequest');
export const createLoadNoteSuccess = createAction('createLoadNoteSuccess');
export const updateLoadNoteRequest = createAction('updateLoadNoteRequest');
export const updateLoadNoteSuccess = createAction('updateLoadNoteSuccess');
export const getLoadNoteListRequest = createAction('getLoadNoteListRequest');
export const getLoadNoteListSuccess = createAction('getLoadNoteListSuccess');
export const updateLoadChatMessageRequest = createAction('updateLoadChatMessageRequest');
export const removeLoadChatMessageRequest = createAction('removeLoadChatMessageRequest');
export const changeMessageCenterActiveTab = createAction('changeMessageCenterActiveTab');
export const createLoadChatMessageRequest = createAction('createLoadChatMessageRequest');
export const getLoadChatMessageListRequest = createAction('getLoadChatMessageListRequest');
export const getLoadChatMessageListSuccess = createAction('getLoadChatMessageListSuccess');
export const changeMessageCenterActiveTabSuccess = createAction('changeMessageCenterActiveTabSuccess');
// payments
export const getLoadBillToSuccess = createAction('getLoadBillToSuccess');
export const createLoadBillToRequest = createAction('createLoadBillToRequest');
export const updateLoadBillToRequest = createAction('updateLoadBillToRequest');
// external system
export const getExternalSystemListRequest = createAction('getExternalSystemListRequest');
export const getExternalSystemListSuccess = createAction('getExternalSystemListSuccess');
export const removeTelFromExternalSystemRequest = createAction('removeTelFromExternalSystemRequest');
export const removeTelFromExternalSystemSuccess = createAction('removeTelFromExternalSystemSuccess');
// transportation mode grouping
export const getLoadTransportationModeGroupingListSuccess =
  createAction('getLoadTransportationModeGroupingListSuccess');
// claims
export const getClaimGeneralDetailsRequest = createAction('getClaimGeneralDetailsRequest');
export const getClaimGeneralDetailsSuccess = createAction('getClaimGeneralDetailsSuccess');
// cross border integration
export const getIsAnyEnabledCrossBorderRequest = createAction('getIsAnyEnabledCrossBorderRequest');
export const getIsAnyEnabledCrossBorderSuccess = createAction('getIsAnyEnabledCrossBorderSuccess');
export const createCrossBorderIntegrationRequest = createAction('createCrossBorderIntegrationRequest');
export const removeCrossBorderIntegrationRequest = createAction('removeCrossBorderIntegrationRequest');
export const removeCrossBorderIntegrationSuccess = createAction('removeCrossBorderIntegrationSuccess');
export const updateCrossBorderIntegrationRequest = createAction('updateCrossBorderIntegrationRequest');
export const updateCrossBorderIntegrationSuccess = createAction('updateCrossBorderIntegrationSuccess');
export const getTelCrossBorderIntegrationListRequest = createAction('getTelCrossBorderIntegrationListRequest');
export const getTelCrossBorderIntegrationListSuccess = createAction('getTelCrossBorderIntegrationListSuccess');
export const refreshTelCrossBorderIntegrationSuccess = createAction('refreshTelCrossBorderIntegrationSuccess');
