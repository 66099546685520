import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// ui
import { Flex } from '../../../ui';
/////////////////////////////////////////////////

const AuditItem = ({ width, title, minWidth, children, borderStyle }: Object) => (
  <Flex
    height={45}
    minWidth={minWidth}
    borderBottom={borderStyle}
    width={R.or(width, '100%')}
    data-testid='testid-auditItem'
  >
    <TextComponent
      title={title}
      maxWidth='95%'
      withEllipsis={true}
    >
      {children}
    </TextComponent>
  </Flex>
);

export default AuditItem;
