import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import { truckTypeOptionsMap } from '../constants';
import {
  defaultTruckTypeMappingFields,
  truckTypeMappingValidationSchema,
  getTruckTypeMappingFieldSettings,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: truckTypeMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultTruckTypeMappingFields,
      initialValues,
    ),
  }),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const currentIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], props);
      const nextIntegrationType = R.path(['values', GC.FIELD_INTEGRATION_TYPE], nextProps);

      return G.notEquals(currentIntegrationType, nextIntegrationType);
    },
    (props: Object) => {
      const { values, truckTypeConfigOptions } = props;

      const integrationType = G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, values);

      const truckTypeOptions = R.compose(
        R.values,
        R.mapObjIndexed((label: string, value: string) => ({ value, label })),
        R.pathOr([], [integrationType]),
      )(truckTypeOptionsMap);

      return {
        optionsForSelect: {
          truckTypeOptions,
          truckTypeConfigOptions: R.tail(truckTypeConfigOptions),
        },
      };
    },
  ),
  pure,
);

const TruckTypeMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props.optionsForSelect}
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={{ mt: 15 }}
      fields={getTruckTypeMappingFieldSettings(props.editMode)}
    />
    <FormFooter2 />
  </form>
);

export default enhance(TruckTypeMappingForm);
