import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// features
import SectionDivider from '../../new-do/components/section-divider';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const LocationLog = ({ locationLog }: Object) => {
  const logDate = R.prop(GC.FIELD_LOG_DATE, locationLog);
  const date = G.convertDateTimeToConfigFormat(logDate);
  const name = R.propOr('', 'geofencingLocation.name', locationLog);
  const text = `${date} | ${name}`;

  return (
    <Box>
      <TextComponent
        title={text}
        fontSize={14}
        withEllipsis={true}
      >
        {text}
      </TextComponent>
      <SectionDivider mt={8} mb={8} />
    </Box>
  );
};

const RouteHistoryList = ({
  routeHistoryList,
}: Object) => (
  <Box width='100%' overflow='auto' maxHeight='64vh'>
    {
      G.isNotNilAndNotEmpty(routeHistoryList) && R.map((locationLog: Object) => (
        <LocationLog key={G.getGuidFromObject(locationLog)} locationLog={locationLog} />
      ), routeHistoryList)
    }
  </Box>
);

export default RouteHistoryList;
