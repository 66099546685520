import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { BlankCardWrapper } from '../../components/virtualized-table/ui';
// helpers/constants
import * as G from '../../helpers';
// ui
import { PageWrapper } from '../../ui';
// feature dashboards-payroll
import {
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
} from './actions';
import { makeSelectPayroll } from './selectors';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import VirtualizedTable from '../../components/virtualized-table';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);
// This component with route dashboards-payroll
// is temporary available for virtualized list testing purpose
const DashboardPayrollComponent = enhance(({
  payroll,
  handleTableTitleFilter,
  expandedContainer = false,
} : Object) => {
  const report = {
    type: 'statistics',
    guid: 'dashboards/payroll',
  };

  return (
    <PageWrapper
      pb='0px'
      bgColor={G.getTheme('colors.bgGrey')}
      pl={G.ifElse(G.isTrue(expandedContainer), '0px', 60)}
    >
      <BlankCardWrapper>
        {
          G.isNotNilAndNotEmpty(payroll) && (
            <VirtualizedTable
              report={report}
              listData={payroll}
              columnSettings={columnSettings}
              handleTableTitleFilter={handleTableTitleFilter}
              onOptionClick={(id: string, item: Object) => {
                console.log(id, item);
              }}
              {...tableSettings}
            />
          )
        }
      </BlankCardWrapper>
    </PageWrapper>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  payroll: makeSelectPayroll(state),
});

export default connect(mapStateToProps, {
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
})(DashboardPayrollComponent);
