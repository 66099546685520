import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const getTabs = (name: string, activeTab: number) => [
  {
    text: name,
  },
  {
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:tel', 'TEL'),
  },
  {
    withCount: R.equals(activeTab, 2),
    text: G.getWindowLocale('titles:clo', 'CLO'),
  },
];
