import * as R from 'ramda';
import * as P from 'plow-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// icons
import * as I from '../../svgs';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature configs
import GroupsTab from './settings/groups-tab';
import { CONFIG_DROPDOWN_POPUP_FORM, MAIL_TEMPLATE_PLACEHOLDERS_MAP } from './constants';
//////////////////////////////////////////////////

export const convertFormValuesToConfigs =
  (values: Object) => {
    const configs = [];
    R.forEachObjIndexed((item: any, key: string) => {
      if (R.equals('inherited', item)) {
        configs.push({
          name: key,
          value: null,
        });
      } else if (R.or(G.isString(item), R.is(Boolean, item))) {
        configs.push({
          name: key,
          value: item,
        });
      } else if (R.is(Array, item)) {
        configs.push({
          name: key,
          value: G.ifElse(R.isEmpty(item), null, item),
        });
      } else if (G.isMomentInstance(item)) {
        configs.push({
          name: key,
          value: G.convertInstanceToLocalTime(item),
        });
      }
    }, values);
    return {
      configs,
    };
  };

export const addDropdownValueToConfigs = (values: Object, prevConfigValue: any) => (
  {
    dropdowns: [
      {
        ...prevConfigValue,
        options: R.concat([
          {
            storedValue: R.prop('storedValue', values),
            displayedValue: R.prop('displayedValue', values),
          },
        ], R.pathOr([], ['options'], prevConfigValue)),
      },
    ],
  }
);

export const updateDropdownValueInConfigs = (values: Object, prevConfigValue: any) => {
  const { guid, storedValue, displayedValue } = values;
  const options = R.compose(
    R.map((item: Object) => {
      if (R.equals(item.guid, guid)) {
        return {
          ...item,
          storedValue,
          displayedValue,
        };
      }

      return item;
    }),
    R.prop('options'),
  )(prevConfigValue);
  return {
    dropdowns: [
      {
        ...prevConfigValue,
        options,
      },
    ],
  };
};

export const removeDropdownValueFromConfig =
  (item: Object, configName: string, prevConfigValue: any) => (
    {
      dropdowns: [
        {
          ...prevConfigValue,
          options: R.without(R.of(Array, item), prevConfigValue.options),
        },
      ],
    }
  );

export const getConfigValueFromGroupStore = (dropdowns: Object, configName: string) => (
  R.pathOr([], [configName], dropdowns)
);

export const convertDataTypeToUiTableField = (field: any, callbackOpt: Object) => {
  if (R.is(Function, field)) {
    return field();
  }
  if (R.is(Boolean, field)) {
    return G.ifElse(
      R.equals(field, true),
      I.uiTrue,
      I.uiFalse,
    )();
  }
  if (R.is(Object, callbackOpt)) {
    const { fieldName, params, callbacksMap } = callbackOpt;
    if (G.isNotNil(callbacksMap)) {
      if (G.isNotNil(R.prop(fieldName, callbacksMap))) {
        return callbacksMap[fieldName](params);
      }
    }
  }
  return field;
};

export const setFieldSelectOptions = (props: Object) => {
  const options = props.options;
  if (R.is(String, options)) {
    return R.pathOr([], [options], props.configOptions);
  }
  if (G.isObject(options)) {
    const { from, dropdownName, customConfigNameOnProps } = options;

    if (R.equals(from, 'props')) {
      return G.mapCustomConfigOptionsFromProps(customConfigNameOnProps, props, false, true);
    }

    if (R.equals(from, 'dropdowns')) {
      return G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
        props,
        dropdownName,
      );
    }
  }
  return options;
};

export const setRefTypesToConfig = (state: Object, data: Object) => {
  const { scope, refTypes } = data;

  const referenceTypes = R.map(({ name, guid}: Object) => ({
    label: name,
    value: guid,
  }), refTypes);

  if (R.equals(scope, GC.REF_SCOPE_NAME_CLO)) {
    return P.$all(
      P.$set(`${GC.UI_CONFIG_GROUP}.options.cloReferenceTypes`, referenceTypes),
      P.$set(`${GC.CLO_CONFIG_GROUP}.options.cloReferenceTypes`, referenceTypes),
      P.$set(`${GC.TEL_CONFIG_GROUP}.options.cloReferenceTypes`, referenceTypes),
      P.$set(`${GC.INVOICE_CONFIG_GROUP}.options.cloReferenceTypes`, referenceTypes),
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.cloReferenceTypes`, referenceTypes),
      P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.options.cloReferenceTypes`, referenceTypes),
      state,
    );
  } else if (R.equals(scope, GC.REF_SCOPE_NAME_TEL)) {
    return P.$all(
      P.$set(`${GC.UI_CONFIG_GROUP}.options.telReferenceTypes`, referenceTypes),
      P.$set(`${GC.CLO_CONFIG_GROUP}.options.telReferenceTypes`, referenceTypes),
      P.$set(`${GC.TEL_CONFIG_GROUP}.options.telReferenceTypes`, referenceTypes),
      P.$set(`${GC.CARRIER_CONFIG_GROUP}.options.telReferenceTypes`, referenceTypes),
      P.$set(`${GC.MOBILEAPP_CONFIG_GROUP}.options.telReferenceTypes`, referenceTypes),
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.telReferenceTypes`, referenceTypes),
      P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.options.telReferenceTypes`, referenceTypes),
      state,
    );
  } else if (R.equals(scope, GC.REF_SCOPE_NAME_CUSTOMER_INVOICE)) {
    return P.$all(
      P.$set(`${GC.OPTIONAL_CONFIG_GROUP}.options.customerInvoiceReferenceTypes`, referenceTypes),
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.customerInvoiceReferenceTypes`, referenceTypes),
      state,
    );
  }
};

export const setSequencesToConfig = (state: Object, data: Object) => {
  const { type, sequences } = data;

  const mappedSequences = G.mapDisplayNameGuidObjectPropsToLabelValueObject(sequences);

  if (R.and(R.equals(type, GC.SEQUENCE_TYPE_CLO), R.propEq(GC.INTEGRATION_CONFIG_GROUP, 'currentGroupName', state))) {
    return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.cloSequences`, mappedSequences, state);
  }

  if (R.equals(type, GC.SEQUENCE_TYPE_BRANCH)) {
    return P.$all(
      P.$set(`${GC.GENERAL_CONFIG_GROUP}.options.branchSequences`, mappedSequences),
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.branchSequences`, mappedSequences),
      state,
    );
  }

  if (R.equals(type, GC.SEQUENCE_TYPE_CARRIER)) {
    return P.$all(
      P.$set(`${GC.SERVICE_VENDOR_CONFIG_GROUP}.options.sequences`, mappedSequences),
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.carrierSequences`, G.addEmptyOptionToDropDown(mappedSequences)),
      state,
    );
  }

  const groupMap = {
    [GC.SEQUENCE_TYPE_CLO]: GC.CLO_CONFIG_GROUP,
    [GC.SEQUENCE_TYPE_TEL]: GC.TEL_CONFIG_GROUP,
    [GC.SEQUENCE_TYPE_CLAIM]: GC.CLAIM_CONFIG_GROUP,
    [GC.SEQUENCE_TYPE_ROUTE]: GC.GENERAL_CONFIG_GROUP,
    [GC.SEQUENCE_TYPE_INVOICE]: GC.INVOICE_CONFIG_GROUP,
    [GC.SEQUENCE_TYPE_FLEET]: GC.FLEET_GENERAL_CONFIG_GROUP,
    [GC.SEQUENCE_TYPE_TEMPLATES]: GC.TEMPLATES_CONFIG_GROUP,
    [GC.SEQUENCE_TYPE_TASK]: GC.TASK_MANAGEMENT_CONFIG_GROUP,
  };

  const configGroup = R.prop(type, groupMap);

  return P.$set(`${configGroup}.options.sequences`, mappedSequences, state);
};

export const indexConfigOptionsWithParentGuid = R.compose(
  R.indexBy(R.prop('id')),
  R.map((item: Object) => (
    R.assoc('id', R.or(R.prop('parentGuid', item), R.prop('guid', item)), item)
  )),
);

export const mapStatusMsgResponse = (data: Object) => (
  R.map((item: Object) => {
    const reasonCodes = R.prop('reasonCodes', item);
    if (G.isNotNil(reasonCodes)) {
      return R.assoc('reasonCodes', JSON.parse(reasonCodes), item);
    }
    return item;
  }, data)
);

export const getModalOptions = (content: any, titleLocale: string) => ({
  component: content,
  options: {
    width: 330,
    height: 'auto',
    title: G.getWindowLocale(titleLocale),
    controlButtons: [
      {
        type: 'button',
        margin: '0 12px 0 0',
        id: CONFIG_DROPDOWN_POPUP_FORM,
        name: G.getWindowLocale('actions:save', 'Save'),
      },
    ],
  },
});

export const getDeactivationRuleIndexFromStore = (guid: string, state: Object) => R.findIndex(
  R.propEq(guid, GC.FIELD_GUID), R.pathOr([], [GC.CARRIER_CONFIG_GROUP, 'deactivationRuleList'], state),
);

export const setInvoiceStatusToConfigStoreByInvoiceName = (state: Object, status: Object, invoiceName: string) => {
  const { displayedValue, originalConfigGuid } = status;

  const invoiceStatuses = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, invoiceName], state);
  const invoiceStatusConfigOption = R.mergeRight(
    status,
    {
      [GC.FIELD_LABEL]: displayedValue,
      [GC.FIELD_VALUE]: originalConfigGuid,
    },
  );
  const index = R.findIndex(R.pathEq(originalConfigGuid, [GC.FIELD_ORIGINAL_CONFIG_GUID]), invoiceStatuses);
  if (R.lt(index, 0)) {
    return P.$all(
      P.$add(`${GC.INVOICE_CONFIG_GROUP}.${invoiceName}`, status),
      P.$add(`${GC.INVOICE_CONFIG_GROUP}.options.${invoiceName}`, invoiceStatusConfigOption),
      state,
    );
  }

  return P.$all(
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.${invoiceName}.${index}`, status),
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.options.${invoiceName}.${index}`, invoiceStatusConfigOption),
    state,
  );
};

export const removeInvoiceStatusToConfigStoreByInvoiceName = (
  state: Object,
  statusGuid: Object,
  invoiceName: string,
) => {
  const invoiceStatuses = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, invoiceName], state);
  const index = R.findIndex(R.pathEq(statusGuid, [GC.FIELD_GUID]), invoiceStatuses);

  return P.$all(
    P.$drop(`${GC.INVOICE_CONFIG_GROUP}.${invoiceName}.${index}`),
    P.$drop(`${GC.INVOICE_CONFIG_GROUP}.options.${invoiceName}.${index}`),
    state,
  );
};

export const mapNameToLabelValue = (items: Array) => {
  if (G.isNilOrEmpty(items)) return [];

  return R.map((({ name }: string) => ({ label: name, value: name })), items);
};

export const mapNameKeyToLabelValue = (items: Array) => {
  if (G.isNilOrEmpty(items)) return [];

  return R.map(
    (({ key, name }: string) => ({ label: name, value: key })),
    items,
  );
};

export const isBooleanPlaceholder = (key: string) => R.includes(
  key,
  [MAIL_TEMPLATE_PLACEHOLDERS_MAP.SHOW_ALL_EVENTS, MAIL_TEMPLATE_PLACEHOLDERS_MAP.USE_COMPANY_LOGO],
);

export const getPlaceholdersToUse = ({ values, editMode, mailTemplate, initialValues }: Object) => {
  const placeholdersKeys = R.pluck(GC.FIELD_KEY, R.pathOr([], ['placeholders'], mailTemplate));

  if (G.isTrue(editMode)) {
    return R.map(
      (item: Object) => {
        const { key } = item;

        let newValue = R.pathOr('', [key], values);

        if (isBooleanPlaceholder(key)) {
          newValue = G.ifElse(G.getPropFromObject(key, values), 'yes', 'no');
        }

        return R.assoc(GC.FIELD_VALUE, newValue, item);
      },
      R.pathOr([], ['placeholders'], initialValues),
    );
  }

  return R.compose(
    R.map(([key, value]: Array) => {
      let valueToUse = R.or(value, '');

      if (isBooleanPlaceholder(key)) {
        valueToUse = G.ifElse(G.isTrue(value), 'yes', 'no');
      }

      return ({ key, value: valueToUse });
    }),
    R.toPairs,
    R.pick(placeholdersKeys),
  )(values);
};

const getValueFromEditorState = (editorState: Object) => draftToHtml(convertToRaw(editorState.getCurrentContent()));

export const getValuesWithEditorFields = ({
  values,
  bodyEditorState,
  farewellEditorState,
}: Object) => R.mergeRight(values, {
  [MAIL_TEMPLATE_PLACEHOLDERS_MAP.BODY]: getValueFromEditorState(bodyEditorState),
  [MAIL_TEMPLATE_PLACEHOLDERS_MAP.FAREWELL]: getValueFromEditorState(farewellEditorState),
});

export const getActiveTab = (configGroup: string) => {
  if (G.isNilOrEmpty(configGroup)) return null;

  const tabsList = GroupsTab[configGroup];

  if (G.isNilOrEmpty(tabsList)) return null;

  const queryParams = G.parseQueryString();

  const tab = R.prop('tab', queryParams);

  if (G.isNilOrEmpty(tab)) return null;

  const index = R.findIndex(R.propEq(tab, 'tabId'), tabsList);

  if (R.gt(index, -1)) return index;
};

export const filterTabsWithoutPermissions = (tabs: Array) => R.filter((tab: Object) => {
  const permissions = R.prop('permissions', tab);

  if (G.isNilOrEmpty(permissions)) return true;

  return G.hasAmousCurrentUserPermissions(permissions);
}, tabs);
