import * as R from 'ramda';
import { css } from 'styled-components';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
// components
import { TextComponent } from '../../../../components/text';
import { AddressBlock } from '../../../../components/address-block';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import { setExpandedContainerOptions } from '../../../expanded-container/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, StyledLink, scrollableContainerCss4px } from '../../../../ui';
// feature claim-management
import { makeSelectClo, makeSelectTel } from '../../selectors';
//////////////////////////////////////////////////

const linkAdditionalStyles = css`
  &:hover {
    text-decoration: underline;
    color: ${R.path('theme', 'colors', 'dark', 'blue')};
  }
`;

const linkStyles = {
  fontSize: 12,
  display: 'flex',
  lineHeight: '18px',
  color: 'light.blue',
  wordBreak: 'break-all',
};

const InfoPair = (props: Object) => {
  const { text, label, maxWidth = 200, fontSize = 12, handleClick } = props;

  if (G.isNilOrEmpty(text)) return null;

  const withHandler = G.isFunction(handleClick);

  return (
    <Flex
      color='darkGrey'
      lineHeight='18px'
      fontSize={fontSize}
    >
      {label}:
      <TextComponent
        ml='5px'
        title={text}
        display='block'
        fontWeight='bold'
        maxWidth={maxWidth}
        withEllipsis={true}
        onClick={handleClick}
        cursor={G.ifElse(withHandler, 'pointer')}
        css={G.ifElse(withHandler, linkAdditionalStyles)}
        color={G.ifElse(withHandler, 'light.blue', 'greyMatterhorn')}
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

const PhoneNumber = (props: Object) => {
  const { phoneNumber } = props;

  if (G.isNilOrEmpty(phoneNumber)) return null;

  return (
    <StyledLink
      {...linkStyles}
      css={linkAdditionalStyles}
      href={`tel:${phoneNumber}`}
    >
      <Box mr='5px'>
        {I.phone(G.getTheme('colors.greyMatterhorn'), 10, 10)}
      </Box>
      {phoneNumber}
    </StyledLink>
  );
};

const datesLocaleMap = {
  [GC.EVENT_TYPE_DROP]: {
    [GC.FIELD_LOAD_EVENT_LATE_DATE]: ['titles:drop-range-late', 'Drop Range Late'],
    [GC.FIELD_LOAD_EVENT_EARLY_DATE]: ['titles:drop-range-early', 'Drop Range Early'],
  },
  [GC.EVENT_TYPE_PICKUP]: {
    [GC.FIELD_LOAD_EVENT_LATE_DATE]: ['titles:pickup-range-late', 'Pickup Range Late'],
    [GC.FIELD_LOAD_EVENT_EARLY_DATE]: ['titles:pickup-range-early', 'Pickup Range Early'],
  },
};

const DriverInfo = ({ driver, isPrimary, handleSetExpandedContainerOptions }: Object) => {
  if (R.isNil(driver)) return null;

  const { fullText } = G.getUserInfo(driver);

  const { guid, email, phoneNumber, secondaryPhoneNumber } = driver;

  const handleOpenDriverProfile = G.hasAmousCurrentUserPermissions(PC.FLEET_DRIVER_WRITE) ? (
    () => handleSetExpandedContainerOptions({
      opened: true,
      visitPageGuid: guid,
      componentType: GC.PAGE_FLEET_DRIVER_PROFILE_V2,
    })
  ) : null;

  return (
    <Fragment>
      <InfoPair
        text={fullText}
        maxWidth='calc(100% - 75px)'
        handleClick={handleOpenDriverProfile}
        label={G.getWindowLocale(...G.ifElse(
          isPrimary,
          ['titles:main-driver', 'Main Driver'],
          ['titles:team-driver', 'Team Driver'],
        ))}
      />
      <PhoneNumber phoneNumber={phoneNumber} />
      <PhoneNumber phoneNumber={secondaryPhoneNumber} />
      {
        R.isNotNil(email) &&
        <StyledLink
          {...linkStyles}
          href={`mailto:${email}`}
          css={linkAdditionalStyles}
        >
          {email}
        </StyledLink>
      }
    </Fragment>
  );
};

const TrailersInfo = ({ trailers, handleSetExpandedContainerOptions }: Object) => {
  if (G.isNilOrEmpty(trailers)) return null;

  const handleOpenTrailerProfile = (visitPageGuid: string) => handleSetExpandedContainerOptions({
    opened: true,
    visitPageGuid,
    componentType: GC.PAGE_FLEET_TRAILER_PROFILE_V2,
  });

  return (
    <Flex
      fontSize={12}
      flexWrap='wrap'
      color='darkGrey'
      lineHeight='18px'
    >
      {G.getWindowLocale('titles:trailers', 'Trailers')}:&nbsp;
      {
        trailers.map(({ guid, unitId }: Object) => (
          <TextComponent
            mr='5px'
            key={guid}
            title={unitId}
            maxWidth={130}
            display='block'
            cursor='pointer'
            fontWeight='bold'
            color='light.blue'
            withEllipsis={true}
            css={linkAdditionalStyles}
            onClick={() => handleOpenTrailerProfile(guid)}
          >
            {unitId}
          </TextComponent>
        ))
      }
    </Flex>
  );
};

const TruckTrailersInfo = ({ truck, trailers, handleSetExpandedContainerOptions }: Object) => {
  if (R.isNil(truck)) return null;

  const { guid, unitId, fleetVendor } = truck;

  const { name, guid: fleetVendorGuid } = R.or(fleetVendor, {});

  const handleOpenTruckProfile = G.hasAmousCurrentUserPermissions(PC.FLEET_TRUCK_WRITE) ? (
    () => handleSetExpandedContainerOptions({
      opened: true,
      visitPageGuid: guid,
      componentType: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
    })
  ) : null;

  const handleOpenVendorProfile = G.hasAmousCurrentUserPermissions(PC.FLEET_VENDOR_WRITE) ? (
    () => handleSetExpandedContainerOptions({
      opened: true,
      visitPageGuid: fleetVendorGuid,
      componentType: GC.PAGE_FLEET_VENDOR_PROFILE_V2,
    })
  ) : null;

  const makeTrailersString = R.compose(
    R.join(', '),
    R.filter(G.isNotNilAndNotEmpty),
    R.map(R.prop(GC.FIELD_UNIT_ID)),
  )(R.or(trailers, []));

  return (
    <Fragment>
      <InfoPair
        text={unitId}
        handleClick={handleOpenTruckProfile}
        label={G.getWindowLocale('titles:truck', 'Truck')}
      />
      <InfoPair
        text={name}
        handleClick={handleOpenVendorProfile}
        label={G.getWindowLocale('titles:vendor', 'Vendor')}
      />
      {
        G.hasNotAmousCurrentUserPermissions(PC.FLEET_TRAILER_WRITE) ? (
          <InfoPair
            text={makeTrailersString}
            label={G.getWindowLocale('titles:trailers', 'Trailers')}
          />
        ) : (
          <TrailersInfo trailers={trailers} handleSetExpandedContainerOptions={handleSetExpandedContainerOptions} />
        )
      }
    </Fragment>
  );
};

const FleetAssignmentSection = ({ fleetAssignment, handleSetExpandedContainerOptions }: Object) => {
  const { truck, trailers, primaryDriver, secondaryDriver } = fleetAssignment;

  return (
    <Box minWidth={210} width='calc(50% - 1px)'>
      <Box mt={20} mb={10} color='darkGrey'>
        {G.getWindowLocale('titles:carrier-driver', 'Carrier/Driver')}:
      </Box>
      <DriverInfo
        isPrimary={true}
        driver={primaryDriver}
        handleSetExpandedContainerOptions={handleSetExpandedContainerOptions}
      />
      <DriverInfo driver={secondaryDriver} handleSetExpandedContainerOptions={handleSetExpandedContainerOptions} />
      <TruckTrailersInfo
        truck={truck}
        trailers={trailers}
        handleSetExpandedContainerOptions={handleSetExpandedContainerOptions}
      />
    </Box>
  );
};

const CarrierSection = (props: Object) => {
  const {
    bolNumber,
    quoteNumber,
    carrierGuid,
    pickupNumber,
    trackingNumber,
    carrierSnapshot,
    originCarrierName,
    destinationCarrierName,
    transportingCarrierName,
    integrationAccountNumber,
    handleSetExpandedContainerOptions,
  } = props;

  const carrierName = R.prop(GC.FIELD_NAME, carrierSnapshot);
  const carrierScac = R.prop(GC.FIELD_CARRIER_SCAC, carrierSnapshot);

  const handleOpenCarrierProfile = G.hasAmousCurrentUserPermissions(PC.CARRIER_WRITE) ? (
    () => handleSetExpandedContainerOptions({
      opened: true,
      visitPageGuid: carrierGuid,
      componentType: GC.PAGE_CARRIER_PROFILE,
    })
  ) : null;

  return (
    <Fragment width='50%'>
      <InfoPair
        text={carrierName}
        handleClick={handleOpenCarrierProfile}
        label={G.getWindowLocale('titles:carrier-name', 'Carrier Name')}
      />
      <InfoPair
        text={carrierScac}
        label={G.getWindowLocale('titles:scac', 'SCAC')}
      />
      <InfoPair
        text={transportingCarrierName}
        label={G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}
      />
      <InfoPair
        text={originCarrierName}
        maxWidth='calc(100% - 120px)'
        label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
      />
      <InfoPair
        text={destinationCarrierName}
        maxWidth='calc(100% - 130px)'
        label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
      />
      <InfoPair
        text={bolNumber}
        label={G.getWindowLocale('titles:bol-number', 'BOL Number')}
      />
      <InfoPair
        text={trackingNumber}
        label={G.getWindowLocale('titles:tracking-number', 'Tracking Number')}
      />
      <InfoPair
        text={pickupNumber}
        label={G.getWindowLocale('titles:pickup-number', 'Pickup Number')}
      />
      <InfoPair
        text={quoteNumber}
        label={G.getWindowLocale('titles:quote-number', 'Quote Number')}
      />
      <AuthWrapper has={[PC.CARRIER_INTEGRATION_ACCOUNT_NUMBER_READ]}>
        <InfoPair
          text={integrationAccountNumber}
          label={G.getWindowLocale('titles:integration-account-number', 'Integration Account Number')}
        />
      </AuthWrapper>
    </Fragment>
  );
};

const PrimaryDriverSection = ({ primaryDriver, primaryDriverPhone }: Object) => {
  const noPrimaryDriverInfo = G.isAllNilOrEmpty([
    primaryDriver,
    primaryDriverPhone,
  ]);

  if (noPrimaryDriverInfo) return null;

  return (
    <Fragment>
      <InfoPair
        text={R.or(primaryDriver, '-')}
        label={G.getWindowLocale('titles:primary-driver', 'Primary Driver')}
      />
      <PhoneNumber
        phoneNumber={primaryDriverPhone}
        label={G.getWindowLocale('titles:phone-number', 'Phone Number')}
      />
    </Fragment>
  );
};

const TeamDriverSection = ({ secondaryDriver, secondaryDriverPhone }: Object) => {
  const noSecondaryDriverInfo = G.isAllNilOrEmpty([
    secondaryDriver,
    secondaryDriverPhone,
  ]);

  if (noSecondaryDriverInfo) return null;

  return (
    <Fragment>
      <InfoPair
        text={R.or(secondaryDriver, '-')}
        label={G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}
      />
      <PhoneNumber
        phoneNumber={secondaryDriverPhone}
        label={G.getWindowLocale('titles:phone-number', 'Phone Number')}
      />
    </Fragment>
  );
};

const TruckTrailerSection = ({ truck, trailer }: Object) => {
  if (G.isNilOrEmpty(truck)) return null;

  return (
    <Fragment>
      <InfoPair
        text={truck}
        label={G.getWindowLocale('titles:truck', 'Truck')}
      />
      <InfoPair
        text={trailer}
        maxWidth='calc(100% - 50px)'
        label={G.getWindowLocale('titles:trailers', 'Trailers')}
      />
    </Fragment>
  );
};

const CarrierAssignmentSection = ({ carrierAssignment, handleSetExpandedContainerOptions }: Object) => (
  <Box minWidth={210} width='calc(50% - 1px)'>
    <Box mt={20} mb={10} color='darkGrey'>
      {G.getWindowLocale('titles:carrier-driver', 'Carrier/Driver')}:
    </Box>
    <CarrierSection {...carrierAssignment} handleSetExpandedContainerOptions={handleSetExpandedContainerOptions} />
    <PrimaryDriverSection {...R.pick([GC.FIELD_PRIMARY_DRIVER, GC.FIELD_PRIMARY_DRIVER_PHONE], carrierAssignment)} />
    <TeamDriverSection {...R.pick([GC.FIELD_SECONDARY_DRIVER, GC.FIELD_SECONDARY_DRIVER_PHONE], carrierAssignment)} />
    <TruckTrailerSection {...R.pick([GC.FIELD_TRUCK, GC.FIELD_TRAILER], carrierAssignment)} />
  </Box>
);

const Card = (props: Object) => {
  const {
    guid,
    isClo,
    dispatch,
    lastEvent,
    firstEvent,
    fleetAssignment,
    carrierAssignment,
    primaryReferenceValue,
  } = props;

  const handleSetExpandedContainerOptions = (options: Object) => dispatch(setExpandedContainerOptions(options));

  const handleOpenTelOrCloDetails = () => handleSetExpandedContainerOptions({
    opened: true,
    visitPageGuid: guid,
    componentType: G.ifElse(
      isClo,
      GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
      GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
    ),
  });

  return (
    <Box
      p={15}
      bg='white'
      height={360}
      flexWrap='wrap'
      overflowY='auto'
      borderRadius='8px'
      border='1px solid'
      borderColor='#E0E0E0'
      css={scrollableContainerCss4px}
    >
      <InfoPair
        fontSize={16}
        text={primaryReferenceValue}
        handleClick={handleOpenTelOrCloDetails}
        label={`${G.getWindowLocale(...G.ifElse(isClo, ['titles:clo', 'Clo'], ['titles:tel', 'Tel']))} #`}
      />
      <Flex flexWrap='wrap' alignItems='flex-start'>
        <Box
          mr='1px'
          minWidth={210}
          width={G.ifElse(G.isAllNilOrEmpty([fleetAssignment, carrierAssignment]), '100%', '50%')}
        >
          {
            [firstEvent, lastEvent].map((item: Object, index: number) => {
              const { location, eventType, eventLateDate, eventEarlyDate } = item;

              return (
                <Fragment key={index}>
                  <Box mt={20} mb={10} color='darkGrey'>
                    {G.getWindowLocale(...G.ifElse(
                      G.isZero(index),
                      ['titles:first-pickup', 'First Pickup'],
                      ['titles:last-drop', 'Last Drop'],
                    ))}:
                  </Box>
                  <AddressBlock width='100%' location={location} />
                  <InfoPair
                    text={G.getDateTimeByConfigFormat(eventEarlyDate)}
                    label={G.getWindowLocale(
                      ...R.pathOr([], [eventType, GC.FIELD_LOAD_EVENT_EARLY_DATE], datesLocaleMap),
                    )}
                  />
                  <InfoPair
                    text={G.getDateTimeByConfigFormat(eventLateDate)}
                    label={G.getWindowLocale(
                      ...R.pathOr([], [eventType, GC.FIELD_LOAD_EVENT_LATE_DATE], datesLocaleMap),
                    )}
                  />
                </Fragment>
              );
            })
          }
        </Box>
        {
          R.isNotNil(fleetAssignment) &&
          <FleetAssignmentSection
            fleetAssignment={fleetAssignment}
            handleSetExpandedContainerOptions={handleSetExpandedContainerOptions}
          />
        }
        {
          R.isNotNil(carrierAssignment) &&
          <CarrierAssignmentSection
            carrierAssignment={carrierAssignment}
            handleSetExpandedContainerOptions={handleSetExpandedContainerOptions}
          />
        }
      </Flex>
    </Box>
  );
};

const CloCard = ({ dispatch }: Object) => {
  const clo = useSelector(makeSelectClo());

  if (R.isNil(clo)) return null;

  return <Card {...clo} isClo={true} dispatch={dispatch} />;
};

const TelCard = ({ dispatch }: Object) => {
  const tel = useSelector(makeSelectTel());
  const clo = useSelector(makeSelectClo());

  if (R.and(R.isNil(tel), R.isNil(clo))) return null;

  if (R.isNil(tel)) return <Box height={360} width='100%' />;

  return <Card {...tel} dispatch={dispatch} />;
};

export {
  CloCard,
  TelCard,
};
