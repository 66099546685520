import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { Map } from '../../../components/map';
import { FormFooter2 } from '../../../components/form-footer';
import { Directions } from '../../../components/map/components/directions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { MarkerWithInfo } from '../../../components/map/components/marker-with-info';
import { StopInfo, StopMarker } from '../../../components/map/components/stop-components';
// forms
import { Fieldset2, FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { enhanceLocationWithGoogleAutocomplete } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, IconWrapper, ActionButton, SimpleWrapper } from '../../../ui';
// feature dispatch-details-new
import { filterStatusCodeOptions } from '../helpers';
import {
  getLocationFields,
  getStatusMessageFields,
  defaultStatusMessageFields,
  initialGoogleStatusMessageFields,
  statusMessageFieldsValidationSchema,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: statusMessageFieldsValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleActionLoadStatusMessage } = props;

      const data = G.getValuesWithConvertedDateTimeFields({
        values,
        dateTimeFields: [GC.FIELD_STATUS_MESSAGE_DATE],
      });

      handleActionLoadStatusMessage(data);
    },
    mapPropsToValues: ({ initialValues }: Object) => {
      const statusReasonCodeGuid = R.path(
        [GC.FIELD_STATUS_MESSAGE_REASON_CODE, GC.FIELD_DROPDOWN_OPTION_GUID],
        initialValues,
      );

      const newInitialValues = R.mergeRight(
        initialValues,
        {
          [GC.FIELD_STATUS_MESSAGE_REASON_CODE]: statusReasonCodeGuid,
          [GC.FIELD_ADDRESS_1]: R.path(['statusMessageLocation', GC.FIELD_ADDRESS], initialValues),
        },
      );

      return G.setInitialFormikValues(
        defaultStatusMessageFields,
        newInitialValues,
      );
    },
  }),
  withState('isMapOpened', 'toggleMap', false),
  withState('isSettedUpStatusLocation', 'setStatusLocation', true),
  withState('isShowStatusReasonCode', 'setShowStatusReasonCode', false),
  withHandlers({
    afterSearchCallback: ({ setStatusLocation }: Object) => () => setStatusLocation(true),
  }),
  enhanceLocationWithGoogleAutocomplete,
  withHandlers({
    handleOnChangeEvent: (props: Object) => (value: string) => {
      const { load, values, setValues, isMapOpened, setStatusLocation } = props;

      if (isMapOpened) {
        setStatusLocation(false);
        setTimeout(() => setStatusLocation(true), 0);
      }

      if (R.isNil(value)) {
        return setValues(
          R.compose(
            R.assoc(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID, null),
            R.mergeRight(values),
          )(initialGoogleStatusMessageFields),
        );
      }

      const selectedEvent = R.find(
        ({ guid }: Object) => R.equals(value, guid),
        R.pathOr([], [GC.FIELD_LOAD_STOPS], load),
      );

      setValues(R.compose(
        R.mergeRight(values),
        R.assoc(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID, value),
        R.pick(R.keys(initialGoogleStatusMessageFields)),
        R.pathOr({}, [GC.FIELD_LOCATION]),
      )(selectedEvent));
    },
    handleSelectAddress: (props: Object) => (location: Object) => {
      const { values, setValues, isMapOpened, setStatusLocation } = props;

      if (isMapOpened) {
        setStatusLocation(false);
        setTimeout(() => setStatusLocation(true), 0);
      }

      const newValues = R.compose(
        R.mergeRight(values),
        R.pick(R.keys(initialGoogleStatusMessageFields)),
      )(location);

      setValues(newValues);
    },
    handleToggleMap: ({ toggleMap }: Object) => () => (
      toggleMap((prev: boolean) => R.not(prev))
    ),
    handleCleanForm: ({ values, setValues }: Object) => (value: string) => {
      const statusDate = R.path([GC.FIELD_STATUS_MESSAGE_DATE], values);

      const newValues = R.compose(
        R.assoc(GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE, value),
        R.assoc(GC.FIELD_STATUS_MESSAGE_DATE, statusDate),
      )(defaultStatusMessageFields);

      setValues(newValues);
    },
  }),
  pure,
);

const getSortedEventsByEventIndex = (events: Object, loadType: Object) => R.sortBy(
  R.prop(R.concat(loadType, 'EventIndex')),
  events,
);

const getStatusCodeOptions = (props: Object) => {
  const { entityName, statusMessagesConfigs } = props;

  if (G.isNilOrEmpty(statusMessagesConfigs)) return [];

  const statusMessagesOptions = filterStatusCodeOptions(props, R.equals(entityName, GC.FIELD_CLO));

  return R.map(({ guid, parentGuid, displayedValue }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: R.or(parentGuid, guid),
  }), statusMessagesOptions);
};

const formatStatusReasonCodeOptions = (items: Array) => {
  if (G.isNilOrEmpty(items)) return [];

  const options = R.compose(
    R.map((item: Object) => ({
      name: item.displayedValue,
      value: R.or(item.parentGuid, item.guid),
    })),
    R.filter((item: Object) => G.isNotNilAndNotEmpty(item)),
  )(items);

  return G.addEmptyOptionToDropDown(options);
};

const getStatusReasonCodeOptions = (props: Object) => {
  const {
    values,
    asyncConfigs,
    communicationsConfigs,
    statusMessagesConfigs,
    setShowStatusReasonCode,
  } = props;

  let fullStatusReasonCodeOptions = G.getFullOptionsFromDropdownConfigStore(
    communicationsConfigs,
    GC.COMMUNICATION_REASON_CODE,
  );

  if (R.and(
    G.isNilOrEmpty(fullStatusReasonCodeOptions),
    G.isNotNilAndNotEmpty(asyncConfigs),
  )) {
    fullStatusReasonCodeOptions = G.getFullOptionsFromDropdownConfigStore(
      asyncConfigs,
      GC.COMMUNICATION_REASON_CODE,
    );
  }

  const statusReasonCodeOptions = G.createIndexedOptions(fullStatusReasonCodeOptions);

  if (G.isNilOrEmpty(statusMessagesConfigs)) return;

  const selectedStatusMessage = statusMessagesConfigs.find(
    (statusCode: Object) => (
      R.or(
        R.equals(values.statusCode, statusCode.parentGuid),
        R.equals(values.statusCode, statusCode.guid),
      )
    ),
  );

  if (G.isNilOrEmpty(selectedStatusMessage)) return;

  const statusReasonCodeGuids = JSON.parse(selectedStatusMessage.reasonCodes);

  if (G.isNilOrEmpty(statusReasonCodeGuids)) {
    setShowStatusReasonCode(false);

    return;
  }

  const currentStatusReasonCodeOptions = statusReasonCodeGuids.map(
    (guid: string) => statusReasonCodeOptions[guid],
  );

  setShowStatusReasonCode(true);

  return formatStatusReasonCodeOptions(currentStatusReasonCodeOptions);
};

export const getStopOptions = (props: Object) => {
  const { entityName } = props;

  let events = getSortedEventsByEventIndex(R.path([GC.FIELD_LOAD, GC.FIELD_LOAD_STOPS], props), entityName);

  const proofType = R.path(['values', GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE], props);

  if (R.isNil(proofType)) return;

  if (R.equals(proofType, GC.DOCUMENT_PROOF_TYPE_POP)) {
    events = R.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_PICKUP), events);
  }

  if (R.equals(proofType, GC.DOCUMENT_PROOF_TYPE_POD)) {
    events = R.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_DROP), events);
  }

  const condition = R.equals(entityName, GC.FIELD_TEL);

  const stopOptions = R.map((item: Object) => {
    const { guid, status } = item;

    return {
      status,
      value: guid,
      label: `${G.getStopData(item, condition)} (${G.toTitleCase(status)})`,
    };
  }, events);

  return stopOptions;
};

const getDateRange = (stops: Array) => {
  const stopEarlyDate = R.compose(
    G.convertDateTimeToConfigFormat,
    R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE),
    R.head,
    R.values,
  )(stops);

  const stopLateDate = R.compose(
    G.convertDateTimeToConfigFormat,
    R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE),
    R.last,
    R.values,
  )(stops);

  return `${stopEarlyDate} - ${stopLateDate}`;
};

const renderStatusMessageFormHeader = (props: Object) => {
  const events = getSortedEventsByEventIndex(R.path([GC.FIELD_LOAD, GC.FIELD_LOAD_STOPS], props), props.entityName);

  return (
    <SimpleWrapper jc='space-between'>
      <Box fontSize='13px' fontWeight='bold'>
        {
          G.ifElse(
            G.isNotNil(G.getOrElse(props.initialValues, GC.FIELD_STATUS_MESSAGE_DATE, null)),
            G.getWindowLocale('titles:edit-message', 'Edit Message'),
            G.getWindowLocale('titles:add-messages', 'Add Message'),
          )
        }
      </Box>
      <SimpleWrapper width='50%' minWidth='max-content'>
        <Box fontSize='12px'>
          <div>{G.getWindowLocale('titles:transit-date-range', 'Transit Date Range')}: </div>
          <div>{getDateRange(events)}</div>
        </Box>
        <ActionButton
          ml={10}
          height={25}
          fontSize={12}
          type='button'
          borderRadius='4px'
          onClick={props.handleToggleMap}
        >
          <Flex>
            <IconWrapper mr='5px' cursor='pointer'>
              {I.onMap(G.getTheme('colors.white'))}
            </IconWrapper>
            {G.ifElse(
              props.isMapOpened,
              G.getWindowLocale('titles:hide-map', 'Hide Map'),
              G.getWindowLocale('titles:show-map', 'Show Map'),
            )}
          </Flex>
        </ActionButton>
      </SimpleWrapper>
    </SimpleWrapper>
  );
};

const locationsWithContent = (locations: Array) => (
  locations.map((oldLocation: Object) => {
    const location = R.clone(oldLocation);
    location.infoContent = (<StopInfo {...location} />);
    location.markerContent = (<StopMarker {...location} />);

    return location;
  })
);

const renderLocationsWithContent = (oldLocation: Object) => {
  const location = R.clone(oldLocation);
  location.markerContent = (<div />);

  return location;
};

const renderStatusMessageMarker = (props: Object) => {
  const values = R.prop('values', props);

  const hasLatLng = R.or(
    G.isNotNilAndNotEmpty(R.path([GC.FIELD_LATITUDE], values)),
    G.isNotNilAndNotEmpty(R.path([GC.FIELD_LONGITUDE], values)),
  );

  let locations = locationsWithContent(props.mapLocations);

  const markerColor = G.getTheme('map.redMarkerColor');

  if (hasLatLng) {
    const location = {
      ...values,
      color: markerColor,
      latLng: { lat: values.latitude, lng: values.longitude },
    };

    locations = R.concat(
      R.of(Array, renderLocationsWithContent(location)),
      locationsWithContent(props.mapLocations),
    );
  }

  return (
    <MarkerWithInfo
      color={markerColor}
      locations={G.makeLocationsWithTransform(locations)}
    />
  );
};

const renderMapComponent = (props: Object) => (
  <Box
    width='100%'
    height='100%'
    boxShadow='0 0 10px 0 rgba(205, 205, 205, 0.5)'
  >
    <Map height='500px'>
      <Directions
        locations={props.mapLocations.map((location: Object) => location.latLng)}
      />
      {renderStatusMessageMarker(props)}
    </Map>
  </Box>
);

const StatusMessageForm = enhance((props: Object) => {
  const {
    isMapOpened,
    handleSubmit,
    mapLocations,
    handleOnChangeEvent,
    handleSelectAddress,
    isSettedUpStatusLocation,
  } = props;

  return (
    <SimpleWrapper height='100%'>
      <form onSubmit={handleSubmit}>
        {renderStatusMessageFormHeader(props)}
        <FormGroupWrapper display='flex' flexDirection='column'>
          <Fieldset2
            {...G.getFormikProps(props)}
            stopOptions={getStopOptions(props)}
            fields={getStatusMessageFields(props)}
            handleCustomChange={handleOnChangeEvent}
            statusCode={getStatusCodeOptions(props)}
            fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
            statusReasonCodeOptions={getStatusReasonCodeOptions(props)}
          />
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={getLocationFields(props)}
            customSelectLocationFunction={handleSelectAddress}
            fieldsWrapperStyles={{ width: 450, justifyContent: 'space-between' }}
          />
        </FormGroupWrapper>
        <FormFooter2 />
      </form>
      {
        isMapOpened &&
        <SimpleWrapper pl={15} height='100%' width={500}>
          {R.and(mapLocations, isSettedUpStatusLocation) && renderMapComponent(props)}
        </SimpleWrapper>
      }
    </SimpleWrapper>
  );
});

export default connect(null, {
  openLoader,
  closeLoader,
})(StatusMessageForm);
