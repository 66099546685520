import * as R from 'ramda';
import React, { Fragment } from 'react';
import { withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex } from '../../../../../ui';
// utilities
import endpointsMap from '../../../../../utilities/endpoints';
import { sendRequestWithQSParamsSerializer } from '../../../../../utilities/http';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const getActiveColor = (active: boolean) => G.getThemeByCond(
  active,
  'icons.iconColor',
  'icons.iconColorGrey',
);

export const SafetyViolationRowActions = (props: Object) => {
  const {
    entity,
    toggleSafetyViolationItem,
    handleRemoveEntityByEntityType,
    handleCreateOrUpdateEntityByEntityType,
  } = props;
  const { guid, expanded, componentType } = entity;

  return (
    <Flex width={120} justifyContent='space-around'>
      <Box cursor='pointer' onClick={() => handleRemoveEntityByEntityType({ guid })}>
        {I.trash(darkBlueColor)}
      </Box>
      <Box
        cursor='pointer'
        onClick={() => handleCreateOrUpdateEntityByEntityType(
          R.omit(['notes', 'documents', 'expanded', 'componentType'], entity),
        )}
      >
        {I.pencil(darkBlueColor)}
      </Box>
      <Box
        height={15}
        cursor='pointer'
        onClick={() => toggleSafetyViolationItem({ guid, componentType: 'document' })}
      >
        {I.file(getActiveColor(R.and(expanded, R.includes(componentType, ['all', 'document']))))}
      </Box>
      <Box
        height={15}
        cursor='pointer'
        onClick={() => toggleSafetyViolationItem({ guid, componentType: 'note' })}
      >
        {I.renderFileIcon(getActiveColor(R.and(expanded, R.includes(componentType, ['all', 'note']))))}
      </Box>
    </Flex>
  );
};

const withDocumentActions = withHandlers({
  handleOpenFilePreview: (props: Object) => async ({ documentFilename }: Object) => {
    const { openLoader, closeLoader, primaryObjectGuid } = props;

    openLoader();
    const options = {
      resType: 'arraybuffer',
      params: { primaryObjectGuid, [GC.FIELD_FILE_NAME]: documentFilename },
    };
    const endpoint = endpointsMap.safetyViolationDocumentDownloadFile;

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.openFileInWindowFromArrayBufferResponse(res);
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  },
  handleDownloadFile: (props: Object) => async ({ documentFilename }: Object) => {
    const { openLoader, closeLoader, primaryObjectGuid } = props;

    openLoader();
    const options = {
      resType: 'arraybuffer',
      params: { primaryObjectGuid, [GC.FIELD_FILE_NAME]: documentFilename },
    };
    const endpoint = endpointsMap.safetyViolationDocumentDownloadFile;

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);
      G.showToastrMessageSimple(
        'success',
        G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
      );
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  },
});

export const DocumentRowActions = withDocumentActions((props: Object) => {
  const {
    entity,
    handleDownloadFile,
    handleOpenFilePreview,
    handleRemoveEntityByEntityType,
    handleCreateOrUpdateEntityByEntityType,
  } = props;
  const { guid, fileUri } = entity;

  return (
    <Flex mx='7px' width={120}>
      <Box mx='6px' cursor='pointer' onClick={() => handleRemoveEntityByEntityType({ guid })}>
        {I.trash(darkBlueColor)}
      </Box>
      <Box
        mx='6px'
        cursor='pointer'
        onClick={() => handleCreateOrUpdateEntityByEntityType(entity)}
      >
        {I.pencil(darkBlueColor)}
      </Box>
      {
        G.isNotNilAndNotEmpty(fileUri) &&
        <Fragment>
          <Box mx='6px' cursor='pointer' onClick={() => handleOpenFilePreview(entity)}>
            {I.eye(darkBlueColor)}
          </Box>
          <Box mx='6px' cursor='pointer' onClick={() => handleDownloadFile(entity)}>
            {I.downloadDocument(darkBlueColor)}
          </Box>
        </Fragment>
      }
    </Flex>
  );
});

export const NoteRowActions = (props: Object) => {
  const { entity, handleRemoveEntityByEntityType, handleCreateOrUpdateEntityByEntityType } = props;

  const { guid, authorType } = entity;

  if (G.notEquals(authorType, GC.USER_TYPE_GENERAL)) return null;

  return (
    <Flex mx='7px' width={60} justifyContent='space-around'>
      <Box
        cursor='pointer'
        onClick={() => handleRemoveEntityByEntityType({ guid })}
      >
        {I.trash(darkBlueColor)}
      </Box>
      <Box
        cursor='pointer'
        onClick={() => handleCreateOrUpdateEntityByEntityType(entity)}
      >
        {I.pencil(darkBlueColor)}
      </Box>
    </Flex>
  );
};
