import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import ColorPicker from '../../../components/color-picker';
// helpers/constants
import * as G from '../../../helpers';
// feature components-story
import { setColorPickerValue } from '../actions';
import { makeSelectColorPickerValue } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ColorPickerComponent = ({ colorPickerValue, setColorPickerValue }: Object) => {
  const description = '<ColorPicker /> component is designed to provide a color selection interface with customizable color options.';

  const usage = (
    <ul>
      <li>Set `<i>wrapperStyles</i>` to customize the styles of the color picker wrapper.</li>
      <li>Set `<i>itemStyles</i>` to customize the styles of individual color picker items.</li>
      <li>Parameter `<i>setFieldValue</i>` is a callback function called when a color is selected.</li>
      <li>Parameter `<i>changeColorCallback</i>` is a callback function called when a color changes.</li>
      <li>Parameter `<i>fieldName</i>` is a unique identifier or name associated with the color picker.</li>
      <li>Parameter `<i>value</i>` represents the currently selected color value from the color picker.</li>
    </ul>
  );

  const changeColorCallback = (color: Object) => setColorPickerValue(color);

  const pickerProps = {
    changeColorCallback,
    value: colorPickerValue,
    wrapperStyles: { width: 210, justifyContent: 'space-between' },
    itemStyles: { boxShadow: `4px 4px 6px 0px ${G.getTheme('colors.darkGrey')}` },
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ColorPicker'
      description={description}
      path='src/components/color-picker'
    >
      <ColorPicker {...pickerProps} />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  colorPickerValue: makeSelectColorPickerValue(state),
});

export default connect(mapStateToProps, {
  setColorPickerValue,
})(ColorPickerComponent);
