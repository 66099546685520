import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature fleet/driver
import {
  contactFieldSettings,
  defaultContactFields,
  contactValidationSchema } from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: contactValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      props.submitAction(values);
    },
    mapPropsToValues: ({ initialValues }: Object) =>
      G.setInitialFormikValues(defaultContactFields, initialValues),
  }),
  withHandlers({
    setValues: ({ setValues }: Object) => (values: Object) => {
      const keysMap = {
        [GC.FIELD_ADDRESS_1]: GC.FIELD_ADDRESS,
        [GC.FIELD_ZIP]: GC.FIELD_CONTACT_POSTAL_CODE,
      };

      setValues(G.renameKeys(keysMap, values));
    },
  }),
  pure,
);

const ContactForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Box overflowY='auto' maxHeight='calc(90vh - 130px)'>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fieldsGroupWidth='100%'
        fields={contactFieldSettings} />
    </Box>
    <FormFooter boxStyles={{ p: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(ContactForm);
