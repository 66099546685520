import React from 'react';
import { Tooltip, Legend, RadialBar, RadialBarChart } from 'recharts';
// features dashboards
import { CHART_COLORS } from '../constants';
//////////////////////////////////////////////////

const legendWrapperStyle = {
  top: 10,
  left: 320,
  lineHeight: '24px',
};

const RadialBarChartComponent = (props: Object) => {
  const { data, tooltipFormatter } = props;

  return (
    <RadialBarChart
      cx={150}
      cy={170}
      data={data}
      width={500}
      height={340}
      barSize={13}
      innerRadius={30}
      outerRadius={150}
    >
      <RadialBar
        minAngle={15}
        dataKey='value'
        clockWise={true}
        background={true}
        label={{ position: 'insideStart', fill: CHART_COLORS.white }}
      />
      <Tooltip formatter={tooltipFormatter} />
      <Legend
        width={120}
        height={340}
        iconSize={10}
        layout='vertical'
        verticalAlign='middle'
        wrapperStyle={legendWrapperStyle}
      />
    </RadialBarChart>
  );
};

export default RadialBarChartComponent;
