export const TEL_MODE = 'TEL_MODE';
export const CLO_NAME = 'CLO_NAME';
export const TEL_INFO_FORM = 'TEL_INFO_FORM';
export const ROUTE_BUILDER_PADDING_BOTTOM = 20;
export const TEL_INDEX_IN_ROUTE = 'indexInRoute';
export const DELETED_TEL_GUIDS = 'deletedTelGuids';
export const SELECT_ITEMS_FORM = 'SELECT_ITEMS_FORM';
export const CREATE_ROUTE_INFO_FORM = 'CREATE_ROUTE_INFO_FORM';
export const FORM_BOX_SHADOW = '0 2px 5px 0 rgba(0, 0, 0, 0.2)';
export const ADDED_TO_INTER_TERMINAL = 'ADDED_TO_INTER_TERMINAL';
export const PLANNER_TEL_MODES = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
  ALL_EXPANDED: 'ALL_EXPANDED',
  ALL_COLLAPSED: 'ALL_COLLAPSED',
};
export const TABS_MODE_OPTIONS = [
  PLANNER_TEL_MODES.ALL_EXPANDED,
  PLANNER_TEL_MODES.ALL_EXPANDED,
  PLANNER_TEL_MODES.INBOUND,
  PLANNER_TEL_MODES.OUTBOUND,
];
export const FIELD_TEL_MODE = 'FIELD_TEL_MODE';
export const TEL_MODE_TERMINAL_DROP = 'TEL_MODE_TERMINAL_DROP';
export const TEL_MODE_INTER_TERMINAL = 'TEL_MODE_INTER_TERMINAL';
export const TEL_MODE_TERMINAL_PICKUP = 'TEL_MODE_TERMINAL_PICKUP';
// planner modes
export const MAP_MODE = 'MAP_MODE';
export const COLLAPSED = 'COLLAPSED';
export const FULL_SCREEN = 'FULL_SCREEN';
export const HALF_SCREEN = 'HALF_SCREEN';
export const STICKED_RIGHT = 'STICKED_RIGHT';
export const ONE_THIRD_SCREEN = 'ONE_THIRD_SCREEN';
