import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature geo-fencing-location
import * as H from '../helpers';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

const defaultRadius = 1000;

export const getDefaultLocationValues = () => ({
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_NAME]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_ADDRESS_1]: '',
  [GC.FIELD_LONGITUDE]: '',
  [GC.FIELD_FLEET_LOCATION_RADIUS]: G.ifElse(G.isMetricUomSystem(), defaultRadius, H.metersToFeet(defaultRadius)),
});

export const getValidationSchemaObject = () => ({
  [GC.FIELD_LATITUDE]: G.yupStringRequired,
  [GC.FIELD_LONGITUDE]: G.yupStringRequired,
  [GC.FIELD_ZIP]: G.yupStringNotRequired
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_NAME]: G.yupStringRequired
    .max(100, G.getShouldBeFromToLocaleTxt(1, 100)),
  [GC.FIELD_FLEET_LOCATION_RADIUS]: G.yupNumberRequired
    .min(0, G.getShouldBeFromToCharLocaleTxt(1, 99999))
    .max(99999, G.getShouldBeFromToCharLocaleTxt(1, 99999)),
});

const getRadiusLabel = (radiusUom: string) =>
  `${G.getWindowLocale('titles:radius', 'Radius')} (${R.or(radiusUom, H.getDistanceUomLocale())})`;

export const getLocationFieldSettings = (radiusUom: string) => [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
  {
    isRequired: false,
    inputWrapperStyles,
    type: 'addressAutocomplete',
    fieldType: 'updateCallback',
    fieldName: GC.FIELD_ADDRESS_1,
    label: ['titles:address', 'Address'],
  },
  {
    isRequired: false,
    inputWrapperStyles,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    fieldType: 'updateCallback',
    label: ['titles:city', 'City'],
  },
  {
    type: 'text',
    isRequired: false,
    inputWrapperStyles,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
  },
  {
    isRequired: false,
    inputWrapperStyles,
    fieldName: GC.FIELD_ZIP,
    type: 'addressAutocomplete',
    fieldType: 'updateCallback',
    label: ['titles:zip', 'Zip'],
  },
  {
    isRequired: false,
    inputWrapperStyles,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    label: getRadiusLabel(radiusUom),
    fieldName: GC.FIELD_FLEET_LOCATION_RADIUS,
  },
];
