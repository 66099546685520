import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useMemo, useState, useCallback } from 'react';
// components
import { SelectMui } from '../../../components';
import { Switcher } from '../../../components/switcher';
import Map from '../../../components/simple-map-with-markers';
import { FleetMarkerWithInfo } from '../../../components/map/components/marker-with-info';
// forms
import { Toggle } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// icons
import { truckOnMap, fleetsOnMap, driverOnMap, trailerOnMap } from '../../../svgs';
// ui
import {
  Box,
  Flex,
  RelativeFlex,
  AbsoluteWrapper,
  RelativeWrapper,
} from '../../../ui';
// feature fleet-map
import { setEnterpriseFilter } from '../actions';
import InfoLengthContainer from './info-length-container';
import { FleetsContent, MultiFleetContent } from './fleets-content';
import {
  mapFleets,
  groupByLatLng,
  fleetLNLNameMap,
  fleetTypeObjectMap,
  getLatLngFromFleets,
  getMultiSwitchOptions,
  getDivisionBranchesOptions,
} from '../helpers';
//////////////////////////////////////////////////

const fleetMapIcons = {
  all: fleetsOnMap,
  trucks: truckOnMap,
  drivers: driverOnMap,
  trailers: trailerOnMap,
};

const mapFleetsToContent = (
  entities: Array,
  fleetType: string,
) => entities.map((entity: Array) => {
  const item = R.reduce((acc: Object, item: Object) => {
    const itemWithFleets = R.assoc('fleets', R.append(item.fleetObj, acc.fleets), item);

    return R.omit('fleetObj', itemWithFleets);
  }, { shortid: G.genShortId(), fleets: [] }, entity);

  const length = R.length(item.fleets);

  return {
    guid: item.shortid,
    latLng: item.latLng,
    infoContent: () => (
      <FleetsContent lnl={item.lnl} fleetType={fleetType} fleets={item.fleets} />
    ),
    markerContent: (
      <RelativeWrapper>
        {fleetMapIcons[fleetType]()}
        <InfoLengthContainer length={length} />
      </RelativeWrapper>
    ),
  };
});

const mapMultiFleetsToContent = (entities: Array) => entities.map((entity: Object) => {
  const { trucks, drivers, trailers } = entity;

  const driversLength = R.length(drivers);
  const trucksLength = R.length(trucks);
  const trailersLength = R.length(trailers);

  let latLng = null;

  if (R.gt(driversLength, 0)) {
    latLng = getLatLngFromFleets(drivers);
  } else if (R.gt(trucksLength, 0)) {
    latLng = getLatLngFromFleets(trucks);
  } else if (R.gt(trailersLength, 0)) {
    latLng = getLatLngFromFleets(trailers);
  }

  const length = R.sum([driversLength, trucksLength, trailersLength]);

  return {
    latLng,
    guid: G.genShortId(),
    infoContent: () => (
      <MultiFleetContent drivers={drivers} trucks={trucks} trailers={trailers} />
    ),
    markerContent: (
      <RelativeWrapper>
        {fleetMapIcons.all()}
        <InfoLengthContainer length={length} />
      </RelativeWrapper>
    ),
  };
});

const groupFleets = (name: string, fleets: Array, groupedObj: Object = {}) => {
  const grouped = R.clone(groupedObj);

  fleets.forEach((item: Object) => {
    const empty = {
      trucks: [],
      drivers: [],
      trailers: [],
    };

    const lat = R.path(['latLng', 'lat'], item);
    const lng = R.path(['latLng', 'lng'], item);
    const latLng = `${lat}${lng}`;

    if (R.has(latLng, grouped)) {
      grouped[latLng][name].push(item);
    } else {
      grouped[latLng] = R.clone(empty);
      grouped[latLng][name].push(item);
    }
  });

  return grouped;
};

const getLocations = (props: Object) => {
  const { trucks, fleets, drivers, trailers, fleetType } = props;

  if (R.and(R.equals(fleetType, 'all'), R.isNil(fleets))) {
    const grouped = groupFleets(
      'trailers',
      R.flatten(trailers),
      groupFleets(
        'trucks',
        R.flatten(trucks),
        groupFleets(
          'drivers',
          R.flatten(drivers),
        ),
      ),
    );

    return mapMultiFleetsToContent(R.values(grouped));
  }

  return mapFleetsToContent(fleets, fleetType);
};

const FleetsMapComponent = (props: Object) => {
  const {
    trucks,
    drivers,
    trailers,
    fleetsLoaded,
  } = props;

  const dispatch = useDispatch();

  const [activeFleetType, setActiveFleetType] = useState('all');
  const [withoutClustering, setWithoutClustering] = useState(false);

  const handleSwtWithoutClustering = useCallback(
    () => setWithoutClustering(R.not(withoutClustering)),
    [withoutClustering, setWithoutClustering],
  );

  const handleSetEnterpriseFilter = useCallback(
    (enterpriseGuid: any) => dispatch(setEnterpriseFilter(enterpriseGuid)),
    [],
  );

  const trucksToUse = useMemo(() => R.values(mapFleets('truckLatestLocation', trucks)), [trucks]);
  const driversToUse = useMemo(() => R.values(mapFleets('driverLatestLocation', drivers)), [drivers]);
  const trailersToUse = useMemo(() => R.values(mapFleets('trailerLatestLocation', trailers)), [trailers]);

  const fleets = useMemo(
    () => groupByLatLng(fleetLNLNameMap[activeFleetType], props[fleetTypeObjectMap[activeFleetType]]),
    [activeFleetType],
  );

  const markerIcon = useMemo(() => fleetMapIcons[activeFleetType], [activeFleetType]);

  const locations = useMemo(() => getLocations({
    fleets,
    trucks: trucksToUse,
    drivers: driversToUse,
    trailers: trailersToUse,
    fleetType: activeFleetType,
  }));

  const markersContainerForGM = (
    <FleetMarkerWithInfo
      locations={locations}
      infoBorderColor={G.getTheme('map.infoBoxBorderColor')}
    />
  );

  return (
    <RelativeFlex width='100%' height='100%'>
      {
        R.all(R.equals(true), R.values(fleetsLoaded)) &&
        <AbsoluteWrapper top='24px' left='80px' zIndex='11'>
          <Flex ml={16}>
            <Switcher
              version={3}
              selectedOptionIndex={0}
              onSwitch={setActiveFleetType}
              options={getMultiSwitchOptions(props)}
            />
            <Toggle
              ml={30}
              icons={false}
              checked={R.not(withoutClustering)}
              onChange={handleSwtWithoutClustering}
            />
            <Box
              ml={10}
              color={G.getTheme('colors.greyMatterhorn')}
            >
              {G.getWindowLocale('titles:cluster-locations', 'Cluster Locations')}
            </Box>
            <Box
              ml={30}
              borderRadius={4}
              backgroundColor={G.getTheme('colors.white')}
            >
              <SelectMui
                multiple={false}
                wrapperStyles={{ width: 175 }}
                options={getDivisionBranchesOptions()}
                handleChangeCallback={handleSetEnterpriseFilter}
                label={G.getWindowLocale('titles:division-name', 'Division Name')}
              />
            </Box>
          </Flex>
        </AbsoluteWrapper>
      }
      {
        R.all(R.equals(true), R.values(fleetsLoaded)) &&
        <Map
          width='100%'
          height='100%'
          locations={locations}
          markerIcon={markerIcon}
          withoutClustering={withoutClustering}
          markersContainerForGM={markersContainerForGM}
          markerIconColor={G.getTheme('colors.dark.blue')}
        />
      }
    </RelativeFlex>
  );
};

export default FleetsMapComponent;
