const dataReference = {
  'totalTripWeightUom': 'KG',
  'telGuid': 'tel-guid',
  'driver.fullName': 'Some name',
  'serviceDays': null,
  'driver.loginId': 'loginId',
  'status.displayedValue': 'On Hold',
  'tel.route.name': 'RO_R',
  'totalTripDistance': 100,
  'status.completedStatus': false,
  'total': 200.0,
  'transportationMode.displayedValue': 'LTL',
  'createdDate': null,
  'createdBy': 'autocreate',
  'driverPayroll.payrollNumber': null,
  'invoiceNumber': 'O_O_',
  'guid': 'guid-',
  'currency': 'USD',
  'serviceType.displayedValue': 'Standard',
  'tel.enterprise.enterpriseName': 'Ukraine',
  'totalTripDistanceUom': 'KM',
  'totalTripWeight': 600,
};

export default Array(1000).fill(0).map(() => {
  const date = new Date().toISOString();
  const id = Math.floor(Math.random() * 999999);
  const data = dataReference;
  data.guid += id;
  data.createdDate = date;

  return data;
});
