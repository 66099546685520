import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
////////////////////////////

export const loginPageSettings = {
  subtitle: null,
  action: G.getWindowLocale('actions:log-in', 'Log in'),
  title: G.getWindowLocale('titles:login-to-amous', 'Login to Amous'),
  linkAction: G.getWindowLocale('actions:forgot-password', 'Forgot your password?'),
};

export const loginFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_USERNAME,
    inputWrapperStyles: { mb: 32, width: '100%' },
    label: G.getWindowLocale('titles:username', 'Username'),
  },
  {
    type: 'password',
    isRequired: true,
    fieldName: GC.FIELD_PASSWORD,
    inputWrapperStyles: { width: '100%' },
    label: G.getWindowLocale('titles:password', 'Password'),
  },
];

export const defaultLoginInitFields = {
  [GC.FIELD_USERNAME]: '',
  [GC.FIELD_PASSWORD]: '',
};

export const loginValidationSchemaObject = Yup.object().shape({
  [GC.FIELD_USERNAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
});

export const resetPasswordPageSettings = {
  action: G.getWindowLocale('actions:reset', 'Reset'),
  linkAction: G.getWindowLocale('actions:sing-in', 'Sign in'),
  title: G.getWindowLocale('titles:reset-password', 'Forgot your password?'),
  subtitle: G.getWindowLocale(
    'titles:no-problem',
    'No problem. Enter your email here and we`ll send you a link to reset it.',
  ),
};

export const resetPasswordFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_USER_LOGIN_ID,
    inputWrapperStyles: { mb: 32, width: '100%' },
    label: G.getWindowLocale('titles:username', 'Username'),
  },
  {
    type: 'email',
    isRequired: true,
    fieldName: GC.FIELD_EMAIL,
    inputWrapperStyles: { width: '100%' },
    label: G.getWindowLocale('titles:email', 'Email'),
  },
];

export const defaultResetPasswordInitFields = {
  [GC.FIELD_EMAIL]: '',
  [GC.FIELD_USER_LOGIN_ID]: '',
};

export const resetPasswordValidationSchemaObject = Yup.object().shape({
  [GC.FIELD_USER_LOGIN_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
});

export const newPasswordPageSettings = {
  subtitle: null,
  action: G.getWindowLocale('actions:send', 'Send'),
  title: G.getWindowLocale('titles:new-password', 'New Password'),
};

export const newPasswordFieldSettings = [
  {
    type: 'password',
    isRequired: true,
    fieldName: GC.FIELD_NEW_PASSWORD,
    inputWrapperStyles: { mb: 24, width: '100%' },
    label: G.getWindowLocale('titles:new-password', 'New Password'),
  },
];

export const defaultNewPasswordInitFields = {
  [GC.FIELD_NEW_PASSWORD]: '',
};

export const newPasswordValidationSchemaObject = Yup.object().shape({
  [GC.FIELD_NEW_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
});

export const newPasswordMobilePageSettings = {
  subtitle: null,
  action: G.getWindowLocale('actions:save', 'Save'),
  title: G.getWindowLocale('titles:create-new-password', 'Create New Password'),
};

export const newPasswordMobileFieldSettings = [
  {
    isMobile: true,
    type: 'password',
    fieldName: GC.FIELD_NEW_PASSWORD,
    inputStyles: { fontSize: 36, height: 120 },
    inputWrapperStyles: { mb: 24, width: '100%' },
    placeholder: G.getWindowLocale('titles:new-password', 'New Password'),
  },
];

export const defaultNewPasswordMobileInitFields = {
  [GC.FIELD_NEW_PASSWORD]: '',
};

export const newPasswordMobileValidationSchemaObject = Yup.object().shape({
  [GC.FIELD_NEW_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
});
