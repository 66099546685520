import React from 'react';
import * as R from 'ramda';
// components
import { ActionsDropdown } from '../../../components';
// helpers
import * as G from '../../../helpers';
// svg
import * as I from '../../../svgs';
// ui
import { Button } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const buttonStyles = {
  height: '100%',
  cursor: 'pointer',
  background: 'none',
  padding: '8px 16px',
  color: G.getTheme('colors.nearDark'),
};

const ActionsDropdownComponent = () => {
  const description = '<ActionsDropdown /> the component is used to display a list of actions.';

  const usage = (
    <ul>
      <li>Set field `<i>type</i>` field determines how the list opens.</li>
      <li>Field `<i>FrontIcon</i>` displays an icon next to which a list opens.</li>
      <li>Property `<i>noPropagation</i>` controls the actions of the component.</li>
      <li>You can choose `<i>position</i>` which indicates the placement of the drop-down list.</li>
      <li>Property `<i>guid</i>` takes a value that is likely to be used as a component identifier.</li>
      <li>Set field `<i>onClick</i>` is responsible for the action that the component should perform.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ActionsDropDown'
      description={description}
      path='src/components/actions-dropdown'
    >
        <ActionsDropdown
          type='click'
          position='left'
          noPropagation={true}
          frontIcon={I.bellIcon('black')}
          guid={`show${R.or('options', '')}`}
        >
          <Button
            style={buttonStyles}
            onClick={() => G.showToastrMessageSimple('info', 'Done')}
          >
            Show Toaster Message
          </Button>
        </ActionsDropdown>
    </ComponentStoryWrapper>
  );
};

export default ActionsDropdownComponent;
