import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
// features
import { AuthWrapper } from '../../permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const detailsChargesTableSettings = {
  maxHeight: 400,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
};

const detailsChargesReport = {
  fields: [
    { sequence: 0, name: GC.FIELD_PAYROLL_NUMBER },
    { sequence: 1, name: GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL },
  ],
};

const getDetailsChargesColumnSettings = (currency: string) => ({
  [GC.FIELD_PAYROLL_NUMBER]: {
    width: 300,
    name: 'titles:payroll-number',
    customComponent: ({ data }: Object) => (
      <Box
        cursor='pointer'
        textDecoration='underline'
        color={G.getTheme('colors.dark.blue')}
        onClick={() => G.goToRoute(routesMap.driverPayrollList)}
      >
        {data.payrollNumber}
      </Box>
    ),
  },
  [GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL]: {
    width: 300,
    name: 'titles:total',
    customComponent: ({ data }: Object) => {
      const displayText = G.ifElse(
        R.equals(R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data), R.prop(GC.FIELD_TOTAL, data)),
        `${R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data)} ${currency}`,
        `${R.prop(GC.FIELD_TOTAL, data)} ${currency} (${R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data)} ${
          R.prop(GC.FIELD_CURRENCY, data)})`,
      );

      return (
        <Box>{displayText}</Box>
      );
    },
  },
});

const DetailsCharges = ({ data, parentProps }: Object) => (
  <Box ml={50}>
    <Table
      loading={false}
      itemList={data}
      report={detailsChargesReport}
      tableSettings={detailsChargesTableSettings}
      columnSettings={getDetailsChargesColumnSettings(R.prop(GC.FIELD_CURRENCY, parentProps))}
    />
  </Box>
);

export const payToLocationColumnSettings = {
  [GC.FIELD_LOCATION_NAME]: {
    width: 200,
    name: 'titles:pay-to-name',
  },
  [GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE]: {
    width: 200,
    uiType: 'enum',
    name: 'titles:pay-type',
  },
  [GC.FIELD_ADDRESS]: {
    width: 200,
    name: 'titles:address',
  },
  [GC.FIELD_ADDRESS_2]: {
    width: 200,
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 200,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 200,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 200,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    name: 'titles:country',
  },
  [GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE]: {
    width: 200,
    name: 'titles:start-date',
  },
};

export const contactColumnSettings = {
  [GC.FIELD_ADDRESS]: {
    width: 200,
    name: 'titles:address',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    name: 'titles:country',
  },
  [GC.FIELD_STATE]: {
    width: 200,
    name: 'titles:state',
  },
  [GC.FIELD_CITY]: {
    width: 200,
    name: 'titles:city',
  },
  postalCode: {
    width: 200,
    name: 'titles:postal-code',
  },
  [GC.FIELD_EMAIL]: {
    width: 200,
    name: 'titles:email',
  },
  [GC.FIELD_FLEET_MOBILE_PHONE]: {
    width: 200,
    name: 'titles:mobile-phone',
  },
  homePhone: {
    width: 200,
    name: 'titles:home-phone',
  },
  emergencyContactName: {
    width: 200,
    name: 'titles:emergency-contact-name',
  },
  emergencyPhone: {
    width: 200,
    name: 'titles:emergency-phone',
  },
};

export const accessorialsSettings = {
  [GC.FIELD_DISPLAYED_VALUE]: {
    width: 150,
    name: 'titles:assessorial',
  },
  [GC.FIELD_NON_TAXABLE]: {
    width: 100,
    name: 'titles:non-taxable',
  },
  [GC.FIELD_FUEL_RELATED]: {
    width: 100,
    name: 'titles:fuel-related',
  },
  [GC.FIELD_DEDUCTION]: {
    width: 100,
    name: 'titles:deduction',
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 100,
    name: 'titles:rate',
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 100,
    name: 'titles:rate-type',
    options: DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 100,
    name: 'titles:rate-unit',
    options: DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_OPTIONS,
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 110,
    name: 'titles:min-rate',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 110,
    name: 'titles:max-rate',
  },
  [GC.FIELD_MIN_STOPS]: {
    width: 110,
    name: 'titles:min-stops',
  },
  [GC.FIELD_MAX_STOPS]: {
    width: 110,
    name: 'titles:max-stops',
  },
  [GC.FIELD_ACCESSORIAL_DISTRICT]: {
    width: 150,
    name: 'titles:district',
    customLogic: (district: Object) => R.pathOr(
      district,
      [district],
      GC.districtMap,
    ),
  },
  [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: {
    width: 150,
    name: 'titles:use-fuel-index',
  },
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: {
    width: 150,
    name: 'titles:fuel-index-quantity',
    customLogic: (entity: Object) => R.length(entity),
  },
};

export const payrollAssessorialsTableSettings = {
  maxHeight: 800,
  cellFontSize: 14,
  titleFontSize: 14,
  tableRowHeight: 55,
  titleRowHeight: 55,
  expandableItems: true,
  checkBoxCellWidth: 30,
  allowSelectItems: false,
  expandedDetailsComponent: DetailsCharges,
};

const payrollAssessorialsFields = [
  { sequence: 0, name: 'actions' },
  { sequence: 1, name: GC.FIELD_DISPLAYED_VALUE },
  { sequence: 2, name: GC.FIELD_NON_TAXABLE },
  { sequence: 3, name: GC.FIELD_FUEL_RELATED },
  { sequence: 4, name: GC.FIELD_DEDUCTION },
  { sequence: 5, name: GC.FIELD_CHARGE_RATE },
  { sequence: 6, name: GC.FIELD_CURRENCY },
  { sequence: 7, name: GC.FIELD_CHARGE_RATE_TYPE },
  { sequence: 9, name: GC.FIELD_ACTIVE },
  { sequence: 10, name: GC.FIELD_LIMITED },
  { sequence: 11, name: GC.FIELD_TOTAL_AMOUNT },
  { sequence: 12, name: GC.FIELD_PAID_AMOUNT },
  { sequence: 13, name: GC.FIELD_HISTORICAL_PAID_AMOUNT },
  { sequence: 14, name: GC.FIELD_FULLY_PAID },
  { sequence: 15, name: GC.FIELD_COMMENTS },
];

export const payrollAssessorialsReport = {
  fields: payrollAssessorialsFields,
};

export const vendorPayrollAssessorialReport = {
  fields: R.append({ sequence: 8, name: GC.FIELD_PAY_FOR_UNIT }, payrollAssessorialsFields),
};

export const getPayrollAssessorialsColumnSettings = ({ handleEdit, handleDelete, deletePermission }: Object) => ({
  actions: {
    width: 80,
    customComponent: ({ data }: Object) => (
      <Flex>
        <AuthWrapper has={[deletePermission]}>
          <Box p='6px 8px' ml='6px' cursor='pointer' onClick={() => handleDelete(data)}>
            {I.trash()}
          </Box>
        </AuthWrapper>
        <Box p='6px' ml='4px' cursor='pointer' onClick={() => handleEdit('put', data)}>
          {I.pencil()}
        </Box>
      </Flex>
    ),
  },
  [GC.FIELD_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:accessorial',
  },
  [GC.FIELD_NON_TAXABLE]: {
    width: 100,
    type: 'boolean',
    name: 'titles:non-taxable',
  },
  [GC.FIELD_FUEL_RELATED]: {
    width: 100,
    type: 'boolean',
    name: 'titles:fuel-related',
  },
  [GC.FIELD_DEDUCTION]: {
    width: 100,
    type: 'boolean',
    name: 'titles:deduction',
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 200,
    name: 'titles:rate',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 200,
    name: 'titles:rate-type',
  },
  [GC.FIELD_ACTIVE]: {
    width: 100,
    type: 'boolean',
    name: 'titles:active',
  },
  [GC.FIELD_LIMITED]: {
    width: 100,
    type: 'boolean',
    name: 'titles:limited',
  },
  [GC.FIELD_TOTAL_AMOUNT]: {
    width: 200,
    name: 'titles:total-amount',
  },
  [GC.FIELD_PAID_AMOUNT]: {
    width: 200,
    name: 'titles:paid-amount',
  },
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: {
    width: 200,
    name: 'titles:historical-paid-amount',
  },
  [GC.FIELD_FULLY_PAID]: {
    width: 150,
    type: 'boolean',
    name: 'titles:fully-paid',
  },
  [GC.FIELD_COMMENTS]: {
    width: 200,
    name: 'titles:comments',
  },
  [GC.FIELD_PAY_FOR_UNIT]: {
    width: 200,
    type: 'enum',
    name: 'titles:pay-for-unit',
  },
});
