import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature carrier-statistic-report
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectCarrierGuid,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));
    const reportParams = yield select(makeSelectUsedReport());
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.pathEq(`${GC.CARRIER_RATE_REQUEST_REPORT}Default`, [GC.FIELD_GUID], reportParams),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );
    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );
    const fields = R.append(
      {
        sequence: 100,
        name: GC.FIELD_TEL_GUID,
      },
      R.pathOr([], ['fields'], reportParams),
    );
    const reqBody = {
      fields,
      orderFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };
    const options = {
      params: { carrierGuid },
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierStatisticList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
      yield put(A.setListLoading(false));
      yield put(closeLoader());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
      yield put(A.setListLoading(false));
      yield put(closeLoader());
    }
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.CARRIER_STATISTIC_REPORT, A, handleGetItemListSaga);

function* getReportDataRequest({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());
    yield put(A.setInitialState());
    yield put(A.setCarrierGuid(payload));
    yield put(A.setReportPending());
    yield put(A.setIgnorePromptStatus(false));
    yield call(handleAvailableReportsRequest, {});
    yield call(handleGetItemListSaga, { payload: true });
    yield put(closeLoader());
    break;
  }
}

function* carrierStatisticWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.getReportDataRequest, getReportDataRequest);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.getAvailableReportsRequest, handleAvailableReportsRequest);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default carrierStatisticWatcherSaga;
