import * as R from 'ramda';
import moment from 'moment';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature charts
import { CHART_CONFIGS_FORM } from './constants';
import {
  updateChartRequest,
  createChartRequest,
  getChartPreviewRequest } from './actions';
import { getSelectOptions } from './helpers';
//////////////////////////////////////////////////

const validate = ({ getState, action }: Object, allow: void, reject: void) => {
  const state = getState();
  const errors = R.pathOr(null, ['form', CHART_CONFIGS_FORM, 'syncErrors'], state);
  const { filters, touch } = action.payload;
  if (R.and(G.isNilOrEmpty(filters), G.isNilOrEmpty(errors))) {
    return allow(action);
  }
  const invalidFilterValue = [];
  filters.forEach((filter: Object) => {
    if (G.isNilOrEmpty(filter.dataType)) {
      invalidFilterValue.push('Please, select filter parameters and operation type');
    }
    if (R.equals(filter.dataType, 'string')) return;
    if (R.equals(filter.dataType, GC.FIELD_REFERENCE)) return;
    const formValue = state.form[CHART_CONFIGS_FORM];
    const field = R.find(
      R.propEq(filter.propertyName, GC.FIELD_VALUE),
      getSelectOptions({ formValue }, GC.FIELD_FILTERS)(),
    );
    const checkOnNumber = R.and(
      R.equals(filter.dataType, 'number'),
      G.isNotNilAndNotEmpty(filter.numberValue),
    );
    if (checkOnNumber) {
      const isNumber = /^\d+$/;
      if (G.isFalse(isNumber.test(filter.numberValue))) {
        invalidFilterValue.push(G.validationMessageWithFieldName(
          G.getWindowLocale('messages:validator:field:numeric', 'Field should be a numeric value'),
          G.getWindowLocale('messages:validator:field', 'Field'),
          R.prop(GC.FIELD_NAME, field),
        ));
      }
      return;
    }
    if (R.equals(filter.dataType, GC.FIELD_DATE)) {
      const timeUnitCondition = R.and(
        G.isNotNilAndNotEmpty(filter.timeUnit),
        R.or(
          G.isNotNilAndNotEmpty(filter.last),
          G.isNotNilAndNotEmpty(filter.next),
        ),
      );
      if (timeUnitCondition) {
        const isNumber = new RegExp(/^\d+$/);
        const isValid = R.or(
          R.and(G.isNotNilAndNotEmpty(filter.last), isNumber.test(filter.last)),
          R.and(G.isNotNilAndNotEmpty(filter.next), isNumber.test(filter.next)),
        );
        if (R.not(isValid)) {
          invalidFilterValue.push(G.validationMessageWithFieldName(
            G.getWindowLocale('messages:validator:field:numeric', 'Field should be a numeric value'),
            G.getWindowLocale('messages:validator:field', 'Field'),
            R.prop(GC.FIELD_NAME, field),
          ));
        }
        return;
      }
      const rangeCondition = R.and(
        G.isNotNilAndNotEmpty(filter.operation),
        R.and(
          G.isNotNilAndNotEmpty(filter.from),
          G.isNotNilAndNotEmpty(filter.to),
        ),
      );
      if (R.and(rangeCondition, moment(filter.to).isBefore(moment(filter.from)))) {
        invalidFilterValue.push(G.validationMessageWithFieldName(
          G.getWindowLocale('messages:validator:field:range', 'Field invalid range'),
          G.getWindowLocale('messages:validator:field', 'Field'),
          R.prop(GC.FIELD_NAME, field),
        ));
      }
    }
  });
  if (G.isNotEmpty(invalidFilterValue)) {
    const message = invalidFilterValue.reduce((msg: string, error: string, i: number) => (
      `${msg}${G.ifElse(R.equals(i, 0), '', ', ')}${error}`
    ), '');
    G.showToastrMessageSimple('info', message);
    return reject();
  }
  if (G.isNotNilAndNotEmpty(errors)) {
    R.forEachObjIndexed((_: any, key: string) => {
      touch(CHART_CONFIGS_FORM, key);
    }, errors);
    return reject();
  }
  allow(action);
};

const handleCreateChartRequest = createLogic({
  type: createChartRequest,
  validate,
});

const handleUpdateChartRequest = createLogic({
  type: updateChartRequest,
  validate,
});

const handleGetChartPreviewRequest = createLogic({
  type: getChartPreviewRequest,
  validate: ({ action }: Object, allow: void, reject: void) => {
    const { payload } = action;
    const invalidFields = [];
    if (G.isNilOrEmpty(payload.dataType)) {
      invalidFields.push(G.validationMessageWithFieldName(
        G.getRequiredLocaleTxt(),
        G.getWindowLocale('messages:validator:field', 'Field'),
        G.getWindowLocale('titles:data-type', 'Data Type'),
      ));
    }
    if (G.isNilOrEmpty(payload.groupByType)) {
      invalidFields.push(G.validationMessageWithFieldName(
        G.getRequiredLocaleTxt(),
        G.getWindowLocale('messages:validator:field', 'Field'),
        G.getWindowLocale('titles:group-by', 'Group By'),
      ));
    }
    if (G.isNilOrEmpty(payload.chartType)) {
      invalidFields.push(G.validationMessageWithFieldName(
        G.getRequiredLocaleTxt(),
        G.getWindowLocale('messages:validator:field', 'Field'),
        G.getWindowLocale('titles:chart-type', 'Chart Type'),
      ));
    }
    if (G.isNotEmpty(invalidFields)) {
      const message = invalidFields.reduce((msg: string, error: string, i: number) => (
        `${msg}${G.ifElse(R.equals(i, 0), '', ', ')}${error}`
      ), '');
      G.showToastrMessageSimple('info', message);
      return reject();
    }
    allow(action);
  },
});

export default [
  handleGetChartPreviewRequest,
  handleCreateChartRequest,
  handleUpdateChartRequest,
];
