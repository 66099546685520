import { createAction } from 'redux-act';
//////////////////////////////////////////////////

const getAppReleaseSuccess = createAction('getAppReleaseSuccess');
const createAppReleaseRequest = createAction('createAppReleaseRequest');
const createAppReleaseSuccess = createAction('createAppReleaseSuccess');
const updateAppReleaseRequest = createAction('updateAppReleaseRequest');
const updateAppReleaseSuccess = createAction('updateAppReleaseSuccess');
const getAppReleaseByExternalUrlSuccess = createAction('getAppReleaseByExternalUrlSuccess');

export {
  getAppReleaseSuccess,
  createAppReleaseRequest,
  createAppReleaseSuccess,
  updateAppReleaseRequest,
  updateAppReleaseSuccess,
  getAppReleaseByExternalUrlSuccess,
};
