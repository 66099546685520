import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withWindowSize, withIsOpenedModalStatus } from '../../../hocs';
// ui
import { Flex, RelativeBox } from '../../../ui';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// report-common
import { generateDefaultReport } from '../../../report-common';
// features
import { loadFilterProps } from '../../dispatch-report/settings/filter-params';
import { loadColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
// feature dispatch-planner
import CustomTitle from './custom-title';
import { pageSizes } from '../settings/page-settings';
import { telTableSettings } from '../settings/table-settings';
import CrossDockLocationSelect from './cross-dock-location-select';
import {
  addTelToPlanner,
  toggleTelDetails,
  setOutboundTerminals,
  removeTelFromPlanner,
  setAvailableTelReports,
  getOutboundTelNextPage,
  updateTelReportRequest,
  resetTelListAndPagination,
  setCurrentOutboundTelReport,
  setOutboundTelTableTitleSort,
  setOutboundTelTableTitleFilter,
  getCurrentRouteByTelGuidRequest,
  resetOutboundTelListAndPagination,
} from '../actions';
import {
  makeSelectOutboundTelList,
  makeSelectOutboundTelTotal,
  makeSelectTelFilterParams,
  makeSelectOutboundTelReport,
  makeSelectOutboundTerminals,
  makeSelectAvailableTelReports,
  makeSelectOutboundTelPagination,
  makeSelectCrossDockLocationList,
  makeSelectOutboundTelListLoading,
  makeSelectOutboundTelTableTitleFilters,
  makeSelectOutboundTelTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withWindowSize,
  withIsOpenedModalStatus,
  withHandlers({
    handleListRequest: ({ getOutboundTelNextPage }: Object) => () => getOutboundTelNextPage(),
    handleClickItem: (props: Object) => (guid: string, tel: Object) => {
      if (G.isTrue(tel.selected)) return props.removeTelFromPlanner(guid);
      props.addTelToPlanner(guid);
    },
    handleToggleDetails: (props: Object) => (tel: Object) => (
      props.toggleTelDetails(R.assoc('isOutbound', true, tel))
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        getOutboundTelNextPage,
        updateTelReportRequest,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.TEL_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          hideSaveAsNew={true}
          setReport={setReport}
          requestPending={requestPending}
          updateReportRequest={updateTelReportRequest}
          onReportSet={() => getOutboundTelNextPage(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), props.reportList);
      props.setReport(selectedReport);
      props.getOutboundTelNextPage(true);
    },
    handleSetLocations: (props: Object) => (locations: Array) => {
      props.setOutboundTerminals(locations);
      props.resetOutboundTelListAndPagination();
      props.getOutboundTelNextPage(true);
      props.closeModal();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    selectedReport,
    handleClickItem,
    titleSortValues,
    tableTitleFilters,
    handleToggleDetails,
    getOutboundTelNextPage,
    handleTableTitleFilter,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleClickItem,
    handleLoadMoreEntities: getOutboundTelNextPage,
    tableSettings: R.mergeRight(telTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(loadFilterProps)),
  };

  return <Table {...data} />;
};

const OutboundLoads = enhance((props: Object) => {
  const {
    locations,
    openModal,
    setReport,
    closeModal,
    totalCount,
    crossDockLocations,
    handleSetLocations,
    getOutboundTelNextPage,
    updateTelReportRequest,
    setAvailableTelReports,
  } = props;

  return (
    <div>
      <RelativeBox pr={15} zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          name='tel-report'
          type='outbound-tel'
          noExportable={true}
          hideFilterInfo={true}
          withoutQuickFilter={true}
          setUsedReport={setReport}
          filterProps={loadFilterProps}
          hiddenRightFilterInfo={false}
          setReports={setAvailableTelReports}
          height={pageSizes.tableHeaderHeight}
          getItemListRequest={getOutboundTelNextPage}
          updateReportRequest={updateTelReportRequest}
          customTitleComponent={
            <Flex>
              <CustomTitle
                mr={15}
                count={totalCount}
                text={G.getWindowLocale('titles:outbound-tels', 'Outbound TELs')}
              />
              <CrossDockLocationSelect
                openModal={openModal}
                locations={locations}
                closeModal={closeModal}
                setLocations={handleSetLocations}
                crossDockLocations={crossDockLocations}
                locationOptions={G.getLocationOptions(R.values(crossDockLocations))}
              />
            </Flex>
          }
        />
      </RelativeBox>
      <RelativeBox zIndex={1}>
        {renderTable(props)}
      </RelativeBox>
    </div>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectOutboundTelList(state),
  totalCount: makeSelectOutboundTelTotal(state),
  locations: makeSelectOutboundTerminals(state),
  filterParams: makeSelectTelFilterParams(state),
  loading: makeSelectOutboundTelListLoading(state),
  reportList: makeSelectAvailableTelReports(state),
  pagination: makeSelectOutboundTelPagination(state),
  selectedReport: makeSelectOutboundTelReport(state),
  crossDockLocations: makeSelectCrossDockLocationList(state),
  tableTitleFilters: makeSelectOutboundTelTableTitleFilters(state),
  titleSortValues: makeSelectOutboundTelTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  addTelToPlanner,
  toggleTelDetails,
  setOutboundTerminals,
  removeTelFromPlanner,
  setAvailableTelReports,
  getOutboundTelNextPage,
  updateTelReportRequest,
  resetTelListAndPagination,
  getCurrentRouteByTelGuidRequest,
  resetOutboundTelListAndPagination,
  setReport: setCurrentOutboundTelReport,
  getItemListRequest: getOutboundTelNextPage,
  setTableTitleSort: setOutboundTelTableTitleSort,
  setTableTitleFilter: setOutboundTelTableTitleFilter,
  changeDefaultReportRequest: setCurrentOutboundTelReport,
  resetListAndPagination: resetOutboundTelListAndPagination,
})(OutboundLoads);
