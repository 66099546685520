import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  onboardingPackageFields,
  defaultOnboardingPackageFields,
  onboardingPackageValidationSchemaObject } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(onboardingPackageValidationSchemaObject),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultOnboardingPackageFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) =>
      props.submitAction({ data: values, method: props.method }),
  }),
  pure,
);

const OnboardingPackageForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent {...G.getFormikProps(props)} fields={onboardingPackageFields} />
      <FormFooter closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(OnboardingPackageForm);
