import React from 'react';
// components
import { ItemIcons } from '../../../components/item-icons';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ItemIconsComponent = () => {
  const description = '<ItemIcons /> component is designed to display temperature icons along with optional temperature values and a hazardous condition icon.';

  const usage = (
    <ul>
      <li>Set `<i>width</i>` of the component width.</li>
      <li>Set `<i>temperatureLow</i>` as the low-temperature value.</li>
      <li>Set `<i>temperatureHigh</i>` as the high-temperature value.</li>
      <li>Set `<i>smallIcons</i>` if the icons should be displayed in a smaller size.</li>
      <li>Parameter `<i>anyHazardous</i>` indicates if any hazardous conditions exist.</li>
      <li>Parameter `<i>showText</i>` determines whether the temperature values should be displayed as text alongside the icons.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ItemIcons'
      description={description}
      path='src/components/item-icons'
    >
      <ItemIcons
        width={120}
        showText={true}
        anyHazardous={true}
        temperatureLow={-20}
        temperatureHigh={40}
      />
    </ComponentStoryWrapper>
  );
};

export default ItemIconsComponent;
