import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// feature dashboards-payroll
import * as A from './actions';
//////////////////////////////////////////////////

function* handleVisitDashboardPayrollPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DASHBOARDS_PAYROLL_PAGE);

    yield put(A.getFakePayrollData());

    yield put(closeLoader());

    break;
  }
}

export function* dashboardWatcherSaga() {
  yield takeLatest(GC.VISIT_DASHBOARDS_PAYROLL_PAGE, handleVisitDashboardPayrollPageSaga);
}

export default dashboardWatcherSaga;
