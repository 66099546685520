import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// ui
import { Flex } from '../../../ui';
// feature dispatch-planner
import Footer from './footer';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleFormSubmit: (props: Object) => () => {
      props.handleSubmitAction(props.submitData);
      props.closeModal();
    },
  }),
  pure,
);

const Confirm = enhance((props: Object) => (
  <Flex flexDirection='column'>
    <TextComponent>
      {props.text}
    </TextComponent>
    <Footer {...props} />
  </Flex>
));

export default Confirm;
