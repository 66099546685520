import React from 'react';
// helpers/constants
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature auth
import { StyledLinkTo } from '../ui';
//////////////////////////////////////////////////

export const LegalInfo = () => (
  <Box px={20} mt={70} textAlign='center'>
    By clicking Log in you agree to our
    <StyledLinkTo mx='5px' target='_blank' to={GC.ROUTE_PATH_TERMS_AND_CONDITIONS}>
        Terms & Conditions
    </StyledLinkTo>
    and
    <StyledLinkTo ml='5px' target='_blank' to={GC.ROUTE_PATH_PRIVACY_POLICY}>
        Privacy Policy
    </StyledLinkTo>
  </Box>
);
