import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const generalSection = {
  title: 'titles:general',
  fields: {
    [GC.CLAIM_CLAIM_NUMBER_AUTOGENERATED]: {
      type: 'switcher',
      name: 'titles:number-autogenerated',
      nameForAttribute: GC.CLAIM_CLAIM_NUMBER_AUTOGENERATED,
    },
    [GC.CLAIM_CLAIM_NUMBER_SEQUENCE]: {
      type: 'select',
      options: 'sequences',
      name: 'titles:number-sequence',
      nameForAttribute: GC.CLAIM_CLAIM_NUMBER_SEQUENCE,
    },
  },
};

const accidentTypeSection = {
  fields: [],
  configType: 'dropdown',
  addPopupTitle: 'titles:add',
  title: 'titles:accident-type',
  editPopupTitle: 'titles:edit',
  configName: GC.CLAIM_ACCIDENT_TYPE,
};

const documentTypeSection = {
  fields: [],
  configType: 'dropdown',
  addPopupTitle: 'titles:add',
  title: 'titles:document-type',
  editPopupTitle: 'titles:edit',
  configName: GC.CLAIM_DOCUMENT_TYPE,
};

const involvedPartyContactTypeSection = {
  fields: [],
  configType: 'dropdown',
  addPopupTitle: 'titles:add',
  editPopupTitle: 'titles:edit',
  title: 'titles:involved-party-contact-type',
  configName: GC.CLAIM_INVOLVED_PARTY_CONTACT_TYPE,
};

const statusSection = {
  fields: [],
  configType: 'dropdown',
  title: 'titles:status',
  addPopupTitle: 'titles:add',
  configName: GC.CLAIM_STATUS,
  editPopupTitle: 'titles:edit',
};

const subStatusSection = {
  fields: [],
  configType: 'dropdown',
  title: 'titles:sub-status',
  addPopupTitle: 'titles:add',
  editPopupTitle: 'titles:edit',
  configName: GC.CLAIM_SUB_STATUS,
};

const typeSection = {
  fields: [],
  title: 'titles:type',
  configType: 'dropdown',
  configName: GC.CLAIM_TYPE,
  addPopupTitle: 'titles:add',
  editPopupTitle: 'titles:edit',
};

const reasonSection = {
  fields: [],
  configType: 'dropdown',
  title: 'titles:reason',
  addPopupTitle: 'titles:add',
  configName: GC.CLAIM_REASON,
  editPopupTitle: 'titles:edit',
};

const ConfigClaimManagementGroup = {
  generalSection,
  accidentTypeSection,
  documentTypeSection,
  involvedPartyContactTypeSection,
  statusSection,
  subStatusSection,
  typeSection,
  reasonSection,
};

export {
  ConfigClaimManagementGroup,
};
