import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import EditReport from '../../../components/edit-report';
import { openModal } from '../../../components/modal/actions';
// helpers
import * as G from '../../../helpers';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import { setReport } from '../actions';
import ComponentStoryWrapper from '../component-story-wrapper';
import { makeSelectReport, makeSelectFilterProps } from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const { openModal, setReport, selectedReport } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setReport}
          usedReport={selectedReport}
          createReportRequest={setReport}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
  }),
  pure,
);

const EditReportComponent = ({ reportFields, handleEditReport }: Object) => {
  const description = '<EditReport /> component is composed of various functionalities and higher-order components to manage state, handle actions, and render different parts of the report editing interface.';

  const usage = (
    <ul>
      <li>Parameter `<i>setReport</i>` function used to set the currently used report.</li>
      <li>Parameter `<i>requestPending</i>` indicates whether there is a request pending.</li>
      <li>Parameter `<i>usedReport</i>` contains the selected or currently used report data.</li>
      <li>Parameter `<i>disableRefFilter</i>` specifies whether filters for reference list should be disabled.</li>
      <li>Parameter `<i>createReportRequest</i>` function is responsible for making a request to create a report.</li>
      <li>Parameter `<i>updateReportRequest</i>` function is responsible for making a request to update an existing report.</li>
      <li>Parameter `<i>fields</i>` contains fields related to the report, such as report configuration settings or field definitions.</li>
      <li>Parameter `<i>onReportSet</i>` is a callback function that triggers an action to retrieve the list of items when a report is set or updated.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='EditReport'
      description={description}
      path='src/components/edit-report'
    >
      <CustomButton onClick={() => handleEditReport(reportFields)}>
        Open `Edit Report` Form
      </CustomButton>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  selectedReport: makeSelectReport(state),
  reportFields: makeSelectFilterProps(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReport,
})(enhance(EditReportComponent));
