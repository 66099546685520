import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../../components/text';
import { LocalLoader, withLocalLoaderHoc } from '../../../../components/local-loader';
// forms
import { Label, SelectComponent, SelectWrapper } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withBooleanStatus } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// ui
import {
  Box,
  Flex,
  Grid,
  RelativeBox,
  AbsoluteBox,
  MainActionButton,
} from '../../../../ui';
// feature dispatch-board-new
import * as H from '../../helpers';
import * as C from '../../constants';
import PopperComponent from '../popper';
import { getSummaryOptions } from '../../settings';
import {
  Link,
  SummaryGroupItem,
  SummaryBodyWrapper,
  SummaryGroupWrapper,
} from './ui';
/////////////////////////////////////////////////

const StatusGroup = ({ name, color }: Object) => (
  <RelativeBox
    p='0 5px'
    color={color}
    overflow='hidden'
    border='1px solid'
    borderRadius='10px'
    borderColor={color}
  >
    <AbsoluteBox bg={color} top='0' left='0' width='100%' height='100%' opacity='0.1' />
    {G.getWindowLocale(GC.statusLocaleMap[name], name)}
  </RelativeBox>
);

const getCustomGroup = (name: any) => {
  const noEntity = G.getWindowLocale('titles:no-driver-carrier', 'No Driver/Carrier');
  let customGroup = {
    [C.SUMMARY_DRIVER]: { name: R.or(name, noEntity) },
    [C.SUMMARY_CARRIER]: { name: R.or(name, noEntity) },
    [C.SUMMARY_TRUCK]: { name: R.or(name, G.getWindowLocale('titles:no-truck', 'No Truck')) },
    [C.SUMMARY_STATUS]: {
      title: G.getWindowLocale(GC.statusLocaleMap[name], name),
      name: <StatusGroup name={name} color={G.getTheme(`status.${name}`)} />,
    },
  };
  if (G.isObject(name)) {
    customGroup = {
      [C.SUMMARY_DRIVER]: { name: R.pathOr(noEntity, ['fullName'], name) },
      [C.SUMMARY_CARRIER]: { name: R.pathOr(noEntity, ['fullName'], name) },
      [C.SUMMARY_BRANCH]: { name: R.pathOr('No Branch', [GC.FIELD_BRANCH_NAME], name) },
      [C.SUMMARY_TRUCK]: { name: R.pathOr(G.getWindowLocale('titles:no-truck', 'No Truck'), ['unitId'], name) },
      [C.SUMMARY_TRAILER]: { name: R.pathOr(G.getWindowLocale('titles:no-truck', 'No Truck'), ['unitId'], name) },
      [GC.ORGANIZE_BY_DISPATCHED_BY]: {
        name: `${R.pathOr('', [GC.FIELD_FIRST_NAME], name)} ${
          R.pathOr('', [GC.FIELD_LAST_NAME], name)} (${R.path([GC.FIELD_USER_LOGIN_ID], name)})`,
      },
    };
  }
  return customGroup;
};

const SummaryFilters = ({
  summary,
  summaryFilters,
  handleCleanSummaryFilter,
}: Object) => {
  if (G.isNilOrEmpty(summaryFilters)) return null;

  const items = H.getFilterItems(summary.entries);
  const summaryGroupFunctions = H.getSummaryGroupRenderFunctions(items);
  const blueColor = G.getTheme('colors.light.blue');

  return (
    <Box>
      <Flex p='5px' bg='lightgray' fontWeight='bold' justifyContent='space-between'>
        {G.getWindowLocale('titles:summary-filters', 'Summary Filters')}
        <Box ml='5px' cursor='pointer' onClick={() => handleCleanSummaryFilter('all')}>
          {I.crossInRound(blueColor)}
        </Box>
      </Flex>
      <Box p='5px'>
        {
          summaryFilters.map((filter: Object, i: number) => (
            <Flex
              alignItem='center'
              margin='2px 0 2px 8px'
              key={`summary-filter-${i}`}
              justifyContent='space-between'
            >
              <Box>
                {
                  H.getSummaryLocale(filter.summaryGroup)
                }: {
                  R.pathOr(
                    (value: any) => value,
                    [filter.summaryGroup],
                    summaryGroupFunctions,
                  )(R.or(filter.stringValue, filter.dateValue))
                }
              </Box>
              <Box ml='5px' cursor='pointer' onClick={() => handleCleanSummaryFilter(filter)}>
                {I.crossInRound(blueColor)}
              </Box>
            </Flex>
          ))
        }
      </Box>
    </Box>
  );
};

const ItemByGroup = (props: Object) => {
  const { entry, parent, isHeader, routeTab, selectedReport, handleSummaryFilter } = props;
  let name = entry.key;
  const customGroup = getCustomGroup(name);
  if (G.isObject(name)) {
    name = R.pathOr('', ['key', 'fullName'], entry);
  }
  const itemType = G.ifElse(isHeader, selectedReport.summaryGroup, selectedReport.summarySubgroup);
  const displayName = G.getEnumLocale(R.pathOr(entry.key, [itemType, 'name'], customGroup));
  const title = R.pathOr(displayName, [itemType, 'title'], customGroup);
  return (
    <SummaryGroupItem
      isHeader={isHeader}
      routeTab={routeTab}
      textColor={R.pathOr('bodyTextColor', [itemType, 'color'], customGroup)}
    >
      <TextComponent
        display='flex'
        title={title}
        cursor='default'
        withEllipsis={true}
        maxWidth='calc(100% - 27px)'
        color={G.getTheme('summary.bodyTextColor')}
        fontWeight={G.ifElse(isHeader, 'bold', 'normal')}
      >
        {isHeader && `${H.getSummaryGroupTitle(props.selectedReport, entry)}:`}
        <Box ml='5px'>{displayName}</Box>
      </TextComponent>
      <Box

        minWidth='20px'
        fontSize='11px'
        textAlign='center'
        onClick={() => handleSummaryFilter(itemType, entry, parent)}
      >
        {entry.count}
      </Box>
    </SummaryGroupItem>
  );
};

const SubentriesColumn = withBooleanStatus(false)(({
  parent,
  entries,
  routeTab,
  booleanStatus,
  selectedReport,
  handleSummaryFilter,
  handleOpenLocalLoader,
  handleCloseLocalLoader,
  handleToggleBooleanStatus,
}: Object) => {
  const items = R.or(entries, []);
  const length = items.length;
  const slicedItems = R.slice(0, 4, items);

  return (
    <Box px='7px'>
      {
        G.ifElse(booleanStatus, items, slicedItems).map((subentry: Object, i: number) => (
          <ItemByGroup
            key={i}
            parent={parent}
            isHeader={false}
            entry={subentry}
            routeTab={routeTab}
            selectedReport={selectedReport}
            handleSummaryFilter={handleSummaryFilter}
          />
        ))
      }
      {
        R.gte(length, 5) &&
        <Box
          p='8px 0'
          width='100%'
          color='blue'
          textAlign='center'
          onClick={() => {
            handleOpenLocalLoader();
            handleToggleBooleanStatus();
            handleCloseLocalLoader();
          }}
        >
          {G.ifElse(
            booleanStatus,
            G.getWindowLocale('titles:hide-items', 'Hide Items'),
            G.getWindowLocale('titles:view-all-items', 'View All Items'),
          )}
        </Box>
      }
    </Box>
  );
});

const Details = (props: Object) => {
  const {
    summary,
    routeTab,
    closePopper,
    selectedReport,
    handleSummaryFilter,
    handleOpenLocalLoader,
    handleCloseLocalLoader,
  } = props;

  const entriesLength = R.path(['entries', 'length'], summary);
  const columns = G.ifElse(R.gt(entriesLength, 6), 6, entriesLength);

  return (
    <Grid
      p='5px'
      my='10px'
      gridGap='20px'
      overflowX='scroll'
      gridTemplateColumns={`repeat(${columns}, auto)`}
      minWidth={G.ifElse(R.gte(columns, 6), '1000px', 'auto')}
    >
      {
        summary.entries.map((entry: Object, i: number) => (
          <SummaryGroupWrapper key={i} routeTab={routeTab}>
            <ItemByGroup
              entry={entry}
              isHeader={true}
              routeTab={routeTab}
              selectedReport={selectedReport}
              handleSummaryFilter={(group: string, groupValue: string, parent: Object) => {
                handleSummaryFilter(group, groupValue, parent);
                closePopper();
              }}
            />
            <SubentriesColumn
              parent={entry}
              routeTab={routeTab}
              entries={entry.entries}
              selectedReport={selectedReport}
              handleOpenLocalLoader={handleOpenLocalLoader}
              handleCloseLocalLoader={handleCloseLocalLoader}
              handleSummaryFilter={(group: string, groupValue: string, parent: Object) => {
                handleSummaryFilter(group, groupValue, parent);
                closePopper();
              }}
            />
          </SummaryGroupWrapper>
        ))
      }
    </Grid>
  );
};

const getFieldOptions = (type: Object, getOptions: Function) => R.prepend(
  { name: '', value: null },
  getOptions(type),
);

const SummaryDetails = (props: Object) => {
  const {
    summary,
    routeTab,
    selectedReport,
    getSummaryAction,
    handleEditReport,
    handleSetSummaryGroup } = props;
  const { count, entries } = summary;
  const { summaryGroup, summarySubgroup } = selectedReport;
  if (G.isNotNilAndNotEmpty(entries)) {
    const changeSummaryGroup = (e: Object) => {
      handleSetSummaryGroup(e.target.name, e.target.value);
      getSummaryAction();
    };
    const options = getFieldOptions(routeTab, getSummaryOptions);
    return (
      <Box maxHeight='calc(100vh - 140px)'>
        <Flex width='min-content' m='5px 5px 0' justifyContent='space-between'>
          <Label flexDirection='column'>
            {G.getWindowLocale('titles:group', 'Group')}
            <SelectWrapper>
              <SelectComponent
                mt='5px'
                pl='10px'
                width={150}
                name='summaryGroup'
                value={summaryGroup}
                onChange={changeSummaryGroup}
              >
                {
                  options.map(({ name, value }: string, i: number) => (
                    <option key={i} value={value}>{name}</option>
                  ))
                }
              </SelectComponent>
            </SelectWrapper>
          </Label>
          <Label flexDirection='column' ml='10px'>
            {G.getWindowLocale('titles:subgroup', 'Subgroup')}
            <SelectWrapper>
              <SelectComponent
                mt='5px'
                pl='10px'
                width={150}
                name='summarySubgroup'
                value={summarySubgroup}
                onChange={changeSummaryGroup}
              >
                {
                  options.map(({ name, value }: string, i: number) => (
                    <option key={i} value={value}>{name}</option>
                  ))
                }
              </SelectComponent>
            </SelectWrapper>
          </Label>
        </Flex>
        <Details {...props} />
      </Box>
    );
  }
  if (R.equals(count, 0)) {
    return (
      <Box>
        <Box fontWeight='bold'>
          {H.getGroupHeaderText(selectedReport)}
        </Box>
        <Box>
          {G.getWindowLocale('titles:empty-list', 'Empty List')}
        </Box>
      </Box>
    );
  }
  const reportName = R.prop(GC.FIELD_NAME, selectedReport);
  return (
    <Box>
      {G.getWindowLocale('messages:title-panel:summary:select-summary-group', 'Please Select Summary Group for')}
      {
        G.isNotNil(reportName) &&
        <Link onClick={handleEditReport}>
          {reportName}
        </Link>
      }
      {R.isNil(reportName) && G.getWindowLocale('titles:report-if-selected', 'report (if selected)')}
    </Box>
  );
};

const Summary = withLocalLoaderHoc(({
  selectedReport,
  localLoaderOpen,
  ...props
}: Object) => {
  const getSummaryAction = () => {
    props.handleOpenLocalLoader();
    props.getSummaryRequest({ onEndAction: props.handleCloseLocalLoader });
  };

  const countFilter = R.pathOr(0, ['summaryFilters', 'length'], selectedReport);

  return (
    <Box p='0' mt={12}>
      <PopperComponent
        type='click'
        position='bottom-start'
        actionOnHoverOrClick={getSummaryAction}
        content={({ closePopper }: Object) => (
          <LocalLoader
            width='max-content'
            height='max-content'
            localLoaderOpen={localLoaderOpen}
            minWidth={G.ifElse(localLoaderOpen, '280px', '0px')}
            maxHeight={G.ifElse(localLoaderOpen, '50px', 'auto')}
            overflow={G.ifElse(localLoaderOpen, 'hidden', 'auto')}
          >
            <SummaryBodyWrapper routeTab={props.routeTab}>
              <SummaryDetails
                {...props}
                closePopper={closePopper}
                selectedReport={selectedReport}
                getSummaryAction={getSummaryAction}
              />
            </SummaryBodyWrapper>
          </LocalLoader>
        )}
      >
        <MainActionButton p='6px 8px' width='auto' height='auto'>
          {G.getWindowLocale('titles:summary', 'SUMMARY', { caseAction: 'titleCase' })}
        </MainActionButton>
      </PopperComponent>
      {
        R.gt(countFilter, 0) &&
        <PopperComponent
          type='hover'
          minWidth='200px'
          position='bottom-start'
          content={
            <SummaryFilters
              summary={props.summary}
              handleCleanSummaryFilter={props.handleCleanSummaryFilter}
              summaryFilters={R.path(['summaryFilters'], selectedReport)}
            />
          }
        >
          <RelativeBox
            ml='5px'
            mt='5px'
            cursor='pointer'
            title={G.getWindowLocale('titles:summary-filters', 'Summary Filters')}
          >
            {I.filter(G.getTheme('icons.iconColor'))}
            <AbsoluteBox
              top='-3px'
              width={14}
              height={14}
              right='-3px'
              fontSize={11}
              display='flex'
              border='1px solid'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
              color={G.getTheme('colors.white')}
              bg={G.getTheme('colors.light.blue')}
              borderColor={G.getTheme('pages.listTitle.countBorderColor')}
            >
              {countFilter}
            </AbsoluteBox>
          </RelativeBox>
        </PopperComponent>
      }
    </Box>
  );
});

export default Summary;
