import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const field = (label: string) => ({
  label,
  isMulti: true,
  inputWrapperStyles,
  type: 'reactSelect',
  options: 'optionsForSelect',
  fieldName: GC.FIELD_COMMISSION_ASSIGNMENTS,
});

const enhance = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      { [GC.FIELD_COMMISSION_ASSIGNMENTS]: null },
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const CommissionAssigneeForm = (props: Object) => {
  const { label, handleSubmit, optionsForSelect } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 15 }}
        fields={R.of(Array, field(label))}
        optionsForSelect={R.path(['availableCommissionAssigneeOptions'], optionsForSelect)}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(CommissionAssigneeForm);
