import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { RelativeBox } from '../../../ui';
// component
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// report-common
import { generateDefaultReport } from '../../../report-common';
// features
import { orderFilterProps } from '../../dispatch-report/settings/filter-params';
import { orderColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
// feature dispatch-planner
import * as H from '../helpers';
import CustomTitle from './custom-title';
import Confirm from '../components/confirm';
import SelectLoadsForm from './select-loads-form';
import { pageSizes } from '../settings/page-settings';
import { cloTableSettings } from '../settings/table-settings';
import {
  selectCrossDockClo,
  uncheckCrossDockClo,
  getCloDetailsRequest,
  setAvailableCloReports,
  createCloReportRequest,
  updateCloReportRequest,
  getCrossDockCloNextPage,
  selectCrossDockCloRequest,
  toggleCrossDockCloDetails,
  setCurrentCrossDockCloReport,
  cleanCrossDockCloQuickFilter,
  changeDefaultCloReportRequest,
  setCrossDockCloTableTitleSort,
  setCrossDockCloTableTitleFilter,
  setCrossDockCloQuickFilterParams,
  resetCrossDockCloListAndPagination,
  refreshCrossDockCloListWithRouteClos,
} from '../actions';
import {
  makeSelectCurrentRoute,
  makeSelectCrossDockCloList,
  makeSelectCrossDockCloTotal,
  makeSelectCrossDockCloReport,
  makeSelectAvailableCloReports,
  makeSelectCrossDockCloPagination,
  makeSelectCrossDockCloListLoading,
  makeSelectCrossDockCloFilterParams,
  makeSelectCrossDockCloTableTitleFilters,
  makeSelectCrossDockCloTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const createOptions = (currentRoute: Object) => R.compose(
  R.map((tel: Object) => ({ label: H.getLoadName(tel), value: tel.guid })),
  R.values(),
  R.propOr({}, 'tels'),
)(currentRoute);

const enhance = compose(
  withHandlers({
    handleListRequest: ({ getCrossDockCloNextPage }: Object) => () => (
      getCrossDockCloNextPage()
    ),
    getQuickFilteredListRequest: (props: Object) => () => {
      const { resetCrossDockCloListAndPagination, refreshCrossDockCloListWithRouteClos } = props;

      resetCrossDockCloListAndPagination();
      refreshCrossDockCloListWithRouteClos();
    },
    handleSelectClo: (props: Object) => (cloGuid: string) => {
      let clo = null;
      const selectedClos = R.filter(
        (item: Object) => {
          if (R.equals(R.prop('guid', item), cloGuid)) clo = item;

          return R.pathOr(false, ['selected'], item);
        },
        props.itemList,
      );
      let component;
      const title = G.ifElse(
        clo.selected,
        G.getWindowLocale('messages:remove-clo', 'Are you sure you want to remove CLO?'),
        G.getWindowLocale('titles:choose-tel-for-clo', 'Please, choose TEL for selected CLO'),
      );

      if (clo.selected) {
        if (R.and(R.lte(selectedClos.length, 1), props.shouldNotUnselectLastClo)) return;
        component = (
          <Confirm
            submitData={clo}
            closeModal={props.closeModal}
            handleSubmitAction={props.uncheckCrossDockClo}
            cancelText={G.getWindowLocale('actions:cancel', 'Cancel')}
            actionText={G.getWindowLocale('actions:confirm', 'Confirm')}
            text={G.getWindowLocale(
              'titles:all-clo-events-will-be-removed',
              'All CLO events will be removed from route',
            )}
          />
        );
      } else {
        const tels = R.values(R.path(['currentRoute', 'tels'], props));

        if (R.equals(R.path(['length'], tels), 1)) {
          return props.selectCrossDockCloRequest({
            returnObject: { cloGuid },
            selectedValue: { value: R.path(['guid'], R.head(tels)) },
          });
        }
        component = (
          <SelectLoadsForm
            initialValue=''
            isClearable={true}
            returnObject={{ cloGuid }}
            closeModal={props.closeModal}
            options={createOptions(props.currentRoute)}
            handleSubmitAction={props.selectCrossDockCloRequest}
          />
        );
      }
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 'min-content',
          height: 'max-content',
        },
      };
      props.openModal(modal);
    },
    handleToggleDetails: (props: Object) => (clo: Object) => props.toggleCrossDockCloDetails(clo.guid),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        createCloReportRequest,
        updateCloReportRequest,
        refreshCrossDockCloListWithRouteClos,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.CLO_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          setReport={setReport}
          requestPending={requestPending}
          createReportRequest={createCloReportRequest}
          updateReportRequest={updateCloReportRequest}
          onReportSet={refreshCrossDockCloListWithRouteClos}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleCleanFilter: (props: Object) => () => {
      const { cleanCrossDockCloQuickFilter, refreshCrossDockCloListWithRouteClos } = props;

      cleanCrossDockCloQuickFilter();
      refreshCrossDockCloListWithRouteClos();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { setReport, reportList, refreshCrossDockCloListWithRouteClos } = props;

      const selectedReport = R.find(
        R.propEq(reportGuid, GC.FIELD_GUID),
        reportList,
      );
      setReport(selectedReport);
      refreshCrossDockCloListWithRouteClos();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    selectedReport,
    handleSelectClo,
    titleSortValues,
    tableTitleFilters,
    handleToggleDetails,
    handleTableTitleFilter,
    getCrossDockCloNextPage,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleSelectClo,
    handleLoadMoreEntities: getCrossDockCloNextPage,
    tableSettings: R.mergeRight(cloTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(orderFilterProps)),
  };

  return <Table {...data} />;
};

const CrossDockOrders = enhance((props: Object) => (
  <div>
    <RelativeBox pr={15} zIndex={2}>
      <TitlePanel
        {...props}
        pt='0px'
        type='cross-dock-clo'
        name='cross-dock-clo-report'
        hiddenRightFilterInfo={false}
        filterProps={orderFilterProps}
        setUsedReport={props.setReport}
        height={pageSizes.tableHeaderHeight}
        setReports={props.setAvailableCloReports}
        getItemListRequest={props.getCrossDockCloNextPage}
        customTitleComponent={
          <CustomTitle
            mr={10}
            count={props.totalCount}
            text={G.getWindowLocale('titles:crossdock-clos', 'Cross Dock Orders')}
          />
        }
      />
    </RelativeBox>
    <RelativeBox zIndex={1}>
      {renderTable(props)}
    </RelativeBox>
  </div>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  noExportable: () => true,
  currentRoute: makeSelectCurrentRoute(state),
  itemList: makeSelectCrossDockCloList(state),
  totalCount: makeSelectCrossDockCloTotal(state),
  reportList: makeSelectAvailableCloReports(state),
  loading: makeSelectCrossDockCloListLoading(state),
  pagination: makeSelectCrossDockCloPagination(state),
  selectedReport: makeSelectCrossDockCloReport(state),
  filterParams: makeSelectCrossDockCloFilterParams(state),
  tableTitleFilters: makeSelectCrossDockCloTableTitleFilters(state),
  titleSortValues: makeSelectCrossDockCloTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectCrossDockClo,
  uncheckCrossDockClo,
  getCloDetailsRequest,
  setAvailableCloReports,
  createCloReportRequest,
  updateCloReportRequest,
  getCrossDockCloNextPage,
  toggleCrossDockCloDetails,
  selectCrossDockCloRequest,
  cleanCrossDockCloQuickFilter,
  resetCrossDockCloListAndPagination,
  refreshCrossDockCloListWithRouteClos,
  setReport: setCurrentCrossDockCloReport,
  getItemListRequest: getCrossDockCloNextPage,
  setTableTitleSort: setCrossDockCloTableTitleSort,
  setTableTitleFilter: setCrossDockCloTableTitleFilter,
  setQuickFilterParams: setCrossDockCloQuickFilterParams,
  changeDefaultReportRequest: changeDefaultCloReportRequest,
  resetListAndPagination: resetCrossDockCloListAndPagination,
})(CrossDockOrders);
