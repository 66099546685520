import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { RelativeBox } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fuel-cards
import { getLinesByFuelCardGuidSuccess } from '../actions';
import { fuelDetailsReport, fuelDetailsTableSettings, fuelDetailsColumnSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const enhance = compose(
  connect(null, { getLinesByFuelCardGuidSuccess }),
  withHandlers({
    handleGetLinesByFuelCardGuid: (props: Object) => async () => {
      const { parentProps, callbackData, getLinesByFuelCardGuidSuccess } = props;

      const { openLoader, closeLoader } = R.or(callbackData, {});

      G.callFunction(openLoader);

      const guid = G.getGuidFromObject(parentProps);
      const endpoint = endpointsMap.fuelCardLinesByFuelCardGuid(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        getLinesByFuelCardGuidSuccess({ guid, lines: data });
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetLinesByFuelCardGuid fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { parentProps, handleGetLinesByFuelCardGuid } = this.props;

      if (R.isNil(R.prop(GC.FIELD_FUEL_CARDS_LINES, parentProps))) {
        handleGetLinesByFuelCardGuid();
      }
    },
  }),
  pure,
);

const FuelCardDetailsComponent = enhance((props: Object) => (
  <RelativeBox ml={25} zIndex='0'>
    <Table
      report={fuelDetailsReport}
      tableSettings={fuelDetailsTableSettings}
      columnSettings={fuelDetailsColumnSettings}
      itemList={R.pathOr([], ['parentProps', GC.FIELD_FUEL_CARDS_LINES], props)}
    />
  </RelativeBox>
));

export const tableSettings = {
  minHeight: 340,
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  expandableItems: true,
  checkBoxCellWidth: 90,
  searchableTitles: true,
  allowSelectItems: false,
  checkBoxCellJustifyContent: 'unset',
  expandedDetailsComponent: (props: Object) => <FuelCardDetailsComponent {...props} />,
};
