import * as R from 'ramda';
import { lifecycle } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const withPayrollMonthlyDeductionAndAccessorialDidUpdate = lifecycle({
  componentDidUpdate(prevProps: Object) {
    const props = this.props;

    const {
      getPayrollChargesIsPaidOnPayrollRequest,
      getPayrollAccessorialsIsPaidOnPayrollRequest,
    } = props;

    const getMonthlyPayrollDeductionList = R.compose(
      R.map(R.omit(['details', 'expanded', 'deductionDisabled'])),
      R.pathOr([], ['entities', 'monthlyPayrollDeductionList']),
    );

    const getPayrollAccessorialList = R.compose(
      R.map(R.omit([
        'details',
        'expanded',
        'deductionDisabled',
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
      ])),
      R.pathOr([], ['entities', 'payrollAccessorialList']),
    );

    const currentMonthlyPayrollDeductionList = getMonthlyPayrollDeductionList(props);
    const prevMonthlyPayrollDeductionList = getMonthlyPayrollDeductionList(prevProps);

    const currentPayrollAccessorialList = getPayrollAccessorialList(props);
    const prevPayrollAccessorialList = getPayrollAccessorialList(prevProps);

    if (R.and(
      G.isNotNilAndNotEmpty(currentMonthlyPayrollDeductionList),
      G.notEquals(prevMonthlyPayrollDeductionList, currentMonthlyPayrollDeductionList),
    )) {
      getPayrollChargesIsPaidOnPayrollRequest(R.map(G.getGuidFromObject, currentMonthlyPayrollDeductionList));
    }

    if (R.and(
      G.isNotNilAndNotEmpty(currentPayrollAccessorialList),
      G.notEquals(prevPayrollAccessorialList, currentPayrollAccessorialList),
    )) {
      getPayrollAccessorialsIsPaidOnPayrollRequest(R.map(G.getGuidFromObject, currentPayrollAccessorialList));
    }
  },
});
