import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Grid } from '../../../ui';
// feature ifta report
import Card from './card';
import {
  errorsReport,
  stateDetailsReport,
  errorColumnSettings,
  stateDetailsColumnSettings,
} from '../settings/column-settings';
//////////////////////////////////////////////////

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  checkBoxCellWidth: 70,
  expandableItems: true,
  searchableTitles: true,
  allowSelectItems: false,
  checkBoxCellJustifyContent: 'unset',
  expandedDetailsComponent: (props: Object) =>
    <DetailsComponent data={R.path(['parentProps'], props)} />,
};

const stateDetailsTableSettings = R.mergeRight(
  tableSettings,
  {
    maxHeight: '400px',
    checkBoxCellWidth: 0,
    expandableItems: false,
    expandedDetailsComponent: () => null,
  },
);

const StateDetailsComponent = ({
  guid,
  tels,
  trucks,
  telGuids,
  activeTab,
  truckGuid,
  stateDetails,
  fuelTransactions,
  fuelTransactionGuids,
}: Object) => (
  <Grid p={25} gridGap={50} gridTemplateColumns='810px 300px'>
    <Box mr={50} width={810}>
      <Table
        itemList={stateDetails}
        report={stateDetailsReport}
        tableSettings={stateDetailsTableSettings}
        columnSettings={stateDetailsColumnSettings}
      />
    </Box>
    <Card
      tels={tels}
      guid={guid}
      trucks={trucks}
      telGuids={telGuids}
      activeTab={activeTab}
      truckGuid={truckGuid}
      fuelTransactions={fuelTransactions}
      fuelTransactionGuids={fuelTransactionGuids}
    />
  </Grid>
);

const ErrorsDetailsComponent = ({ error }: Object) => (
  <Box ml={25}>
    {
      G.isNotNilAndNotEmpty(R.prop(GC.FIELD_MESSAGE, error)) &&
      <Box
        my={20}
        fontSize={12}
        fontWeight='bold'
        color={G.getTheme('colors.light.mainRed')}
      >
        {G.getWindowLocale('titles:global-error', 'Global Error')}: {
          R.prop(GC.FIELD_MESSAGE, error)
        }
      </Box>
    }
    <Table
      report={errorsReport}
      itemList={error.errors}
      columnSettings={errorColumnSettings}
      tableSettings={stateDetailsTableSettings}
    />
  </Box>
);

const DetailsComponent = ({ data }: Object) => {
  const { error, status } = data;

  if (R.equals(status, GC.IFTA_STATUS_TYPE_SUCCESS)) {
    return <StateDetailsComponent {...data} />;
  }

  if (R.equals(status, GC.IFTA_STATUS_TYPE_FAILED)) {
    return <ErrorsDetailsComponent error={error} />;
  }

  return null;
};

export default DetailsComponent;
