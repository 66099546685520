import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet/driver
import * as A from './actions.js';
//////////////////////////////////////////////////

export const initialState = {
  contacts: [],
  documents: [],
  fuelCards: [],
  assignInfo: {},
  driverHours: {},
  usedReport: null,
  accessorials: [],
  usersGeneral: [],
  driverConfigs: {},
  currentDriver: {},
  driverCitizen: {},
  driverLicense: {},
  compensations: [],
  branchConfigs: {},
  certifications: [],
  availableTrucks: [],
  integrationList: [],
  safetyViolation: {},
  fleetVendorList: [],
  availableDrivers: [],
  driverDailyHours: [],
  availableTrailers: [],
  documentsFilter: null,
  advancePaymentList: {},
  availableReports: null,
  latestKnownLocation: [],
  deductionSettingsList: [],
  payrollAssessorialList: [],
  monthlyPayrollDeduction: [],
  [GC.UI_FIELD_REFERENCES]: [],
  payrollCompensationRate: null,
  collapsedGroup: {
    contacts: true,
    documents: true,
    accounting: true,
    integration: true,
    driverHours: true,
    certification: true,
    driverDetails: true,
    payToLocation: true,
    driverLicense: true,
    advancePayment: true,
    safetyViolation: true,
    deductionSettings: true,
    driverCitizenship: true,
    payrollAssessorials: true,
    monthlyPayrollDeduction: true,
    driverLatestKnownLocation: true,
  },
};

// deduction settings
const getDeductionSettingsSuccess = (state: Object, data: Object) => (
  P.$set('deductionSettingsList', data, state)
);

const getUsersSuccess = (state: Object, data: Array) => (
  P.$set('usersGeneral', data, state)
);

const receivedDriverHoursSuccess = (state: Object, driverHours: Object) => (
  P.$set('driverHours', driverHours, state)
);

const receivedDriverCertificationSuccess = (state: Object, data: Array) => (
  P.$set('certifications', data, state)
);

const receivedCreateDriverCertificateSuccess = (state: Object, data: Object) => (
  P.$add('certifications', data, state)
);

const setDocumentsFilter = (state: Object, filter: string) => P.$set('documentsFilter', filter, state);

const receivedDriverDocSuccess = (state: Object, docs: Array) => (
  P.$set('documents', docs, state)
);

const receivedDriverCitizenSuccess = (state: Object, citizen: Object) => (
  P.$set('driverCitizen', citizen, state)
);

const receivedDriverLicenseSuccess = (state: Object, license: Object) => (
  P.$set('driverLicense', license, state)
);

const receivedDriverAccessorialsSuccess = (state: Object, data: Array) => (
  P.$set('accessorials', data, state)
);

const receivedDriverCompensationsSuccess = (state: Object, data: Array) => (
  P.$set('compensations', data, state)
);

const receivedGetAssignInfo = (state: Object, data: Object) => (
  P.$set('assignInfo', data, state)
);

const receivedGetAvailableDriverSuccess = (state: Object, data: Array) => (
  P.$set('availableDrivers', data, state)
);

const receivedGetAvailableTrucksSuccess = (state: Object, data: Array) => (
  P.$set('availableTrucks', data, state)
);

const receivedGetAvailableTrailersSuccess = (state: Object, data: Array) => (
  P.$set('availableTrailers', data, state)
);

const receivedGetDriverConfigsSuccess = (state: Object, data: Object) => (
  P.$set('driverConfigs', data, state)
);

const receivedUpdateDriverHoursSuccess = (state: Object, driverHours: Object) => (
  P.$set('driverHours', driverHours, state)
);

const receivedUpdateDriverDetailsSuccess = (state: Object, driver: Object) => (
  P.$set('currentDriver', driver, state)
);

const receivedDriverSuccess = (state: Object, driver: Object) => (
  P.$set('currentDriver', driver, state)
);

const receivedDriverRefSuccess = (state: Object, refs: Array) => (
  P.$set(GC.UI_FIELD_REFERENCES, refs, state)
);

const receivedCreatedDriverReferenceSuccess = (state: Object, data: Object) => (
  P.$add(GC.UI_FIELD_REFERENCES, data, state)
);

export const receivedUpdateDriverContactSuccess = (state: Object, { contact, index }: Object) => (
  P.$set(`contacts.${index}`, contact, state));

const receivedUpdateDriverReferenceSuccess = (state: Object, ref: Object) => (
  P.$set(`${GC.UI_FIELD_REFERENCES}.${ref.guid}`, ref, state)
);

const receivedUpdateDriverCitizenSuccess = (state: Object, data: Object) => (
  P.$set('driverCitizen', data, state)
);

export const receivedUpdateDriverLicenseSuccess = (state: Object, data: Object) => (
  P.$set('driverLicense', data, state));

const receivedUpdateDriverCertificateSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`certifications.${index}`, data, state)
);

const receivedUpdateDriverDocSuccess = (state: Object, data: Object) => {
  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), state.documents);

  return P.$set(`documents.${index}`, data, state);
};

const receivedDeleteDriverReferenceSuccess = (state: Object, refGuid: string) => (
  P.$drop(`${GC.UI_FIELD_REFERENCES}.${refGuid}`, state)
);

const receivedDriverContactSuccess = (state: Object, contacts: Array) => (
  P.$set('contacts', contacts, state)
);

const receivedDriverLatestKnownLocationSuccess = (state: Object, location: Array) => (
  P.$set('latestKnownLocation', location, state)
);

const receivedCreateDriverLatestKnownLocationSuccess = (state: Object, location: Array) => (
  P.$set('latestKnownLocation', location, state)
);

const receivedCreateDriverContactSuccess = (state: Object, contact: A) => (
  P.$add('contacts', contact, state)
);

const receivedCreateDriverDocSuccess = (state: Object, doc: Object) => (
  P.$add('documents', doc, state)
);

const receivedCreateDriverCompensationSuccess = (state: Object, data: Object) => (
  P.$add('compensations', data, state)
);

const receivedCreateDriverAccessorialSuccess = (state: Object, data: Object) => (
  P.$add('accessorials', data, state)
);

const receivedUpdateDriverCompensationSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`compensations.${index}`, data, state)
);

const receivedUpdateDriverAccessorialSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`accessorials.${index}`, data, state)
);

const receivedDeleteDriverContactSuccess = (state: Object, index: string) => (
  P.$drop(`contacts.${index}`, state)
);

const receivedDeleteDriverCertificateSuccess = (state: Object, index: string) => (
  P.$drop(`certifications.${index}`, state)
);

const receivedDeleteDriverCompensationSuccess = (state: Object, index: string) => (
  P.$drop(`compensations.${index}`, state)
);

const receivedDeleteDriverAccessorialSuccess = (state: Object, index: string) => (
  P.$drop(`accessorials.${index}`, state)
);

const receivedDeleteDriverDocSuccess = (state: Object, guid: string) => {
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), state.documents);

  return P.$drop(`documents.${index}`, state);
};

const toggleDriverFormGroup = (state: Object, groupName: string) => (
  P.$toggle(`collapsedGroup.${groupName}`, state)
);

const cleanFormStore = (state: Object) => (
  P.$all(
    P.$set('driverHours', initialState.driverHours),
    P.$set('driverLicense', initialState.driverLicense),
    P.$set('driverCitizen', initialState.driverCitizen),
    P.$set('currentDriver', initialState.currentDriver),
    P.$set('integrationList', initialState.integrationList),
    state,
  )
);

const getExternalDriverIdSuccess = (state: Object, data: string) => (
  P.$set('integrationList', data, state)
);

const receivedFuelCardListByDriverSuccess = (state: Object, data: Object) => {
  const indexedData = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.map((item: Object) => R.assoc('expanded', false, item)),
  )(data);
  return P.$set('fuelCards', indexedData, state);
};

const toggleFuelCardDetails = (state: Object, data: Object) => (
  P.$toggle(`fuelCards.${data.guid}.expanded`, state)
);

const getPayrollAssessorialListSuccess = (state: Object, data: Object) => (
  P.$set(
    'payrollAssessorialList',
    R.map(R.assoc('expanded', false), data),
    state,
  )
);

const getAdvancePaymentListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        index,
        [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_USER_LOGIN_ID], item),
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_LAST_NAME], item),
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_FIRST_NAME], item),
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: R.path(
          [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER],
          item,
        ),
      },
    )),
  )(data);

  return P.$set('advancePaymentList', list, state);
};

const getDriverPayrollNumberListByGuidsSuccess = (state: Object, data: Object) => {
  const { advancePaymentList } = state;

  const indexedPayrollNumberList = R.indexBy(R.prop(GC.FIELD_GUID), data);
  const newList = R.map(
    (item: Object) => {
      const { payrollGuid } = item;

      if (G.isNilOrEmpty(payrollGuid)) return item;
      const payrollNumber = R.path([payrollGuid, GC.FIELD_PAYROLL_NUMBER], indexedPayrollNumberList);

      return R.assoc(GC.FIELD_PAYROLL_NUMBER, payrollNumber, item);
    },
    advancePaymentList,
  );

  return P.$set('advancePaymentList', newList, state);
};

const updateAdvancePaymentStatusSuccess = (state: Object, data: Object) => (
  P.$merge(
    `advancePaymentList.${R.prop(GC.FIELD_GUID, data)}`,
    {
      [GC.FIELD_STATUS]: R.pathOr('', [GC.FIELD_STATUS], data),
      [GC.FIELD_ADVANCE_PAYMENT_CHANGE_STATUS_REASON]: R.pathOr(
        '',
        [GC.FIELD_ADVANCE_PAYMENT_CHANGE_STATUS_REASON],
        data,
      ),
    },
    state,
  )
);

const createAdvancePaymentSuccess = (state: Object, data: Object) => {
  const { advancePaymentList } = state;
  const { guid } = data;
  const item = R.mergeRight(
    data,
    {
      index: R.length(advancePaymentList),
      [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_FLEET_LOGIN_ID], data),
      [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_LAST_NAME], data),
      [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_FIRST_NAME], data),
      [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: R.path(
        [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER],
        data,
      ),
    },
  );
  const newList = R.assoc(guid, item, advancePaymentList);

  return P.$set('advancePaymentList', newList, state);
};

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('totalCount', 0),
    P.$set('filterParams', {}),
    state,
  )
);

const getDriverDailyHoursSuccess = (state: Object, data: Array) => (
  P.$set(
    'driverDailyHours',
    R.map(
      (entity: Object) => R.assoc(
        'expanded',
        R.pathOr(false, ['expanded'], entity),
        entity,
      ),
      data,
    ),
    state,
  )
);

const createDriverDailyHoursSuccess = (state: Object, data: Array) => {
  const { driverDailyHours } = state;

  const items = R.map((item: Object) => R.assoc('expanded', false, item), data);

  return P.$set('driverDailyHours', R.concat(R.or(driverDailyHours, []), items), state);
};

const updateDriverDailyHoursSuccess = (state: Object, data: Object) => {
  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), state.driverDailyHours);
  const expanded = R.pathOr(false, ['driverDailyHours', index, 'expanded'], state);
  const item = R.assoc('expanded', expanded, data);

  return P.$set(`driverDailyHours.${index}`, item, state);
};

const deleteDriverDailyHoursSuccess = (state: Object, data: string) => (
  P.$set(
    'driverDailyHours',
    R.filter(({ guid }: Object) => G.notEquals(guid, data), state.driverDailyHours),
    state,
  )
);

const getPayrollCompensationRateSuccess = (state: Object, data: Object) => (
  P.$set('payrollCompensationRate', data, state)
);

// monthly payroll deduction
const getMonthlyPayrollDeductionSuccess = (state: Object, data: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(R.assoc('expanded', false), data),
    state,
  )
);

const toggleMonthlyPayrollDeductionDetails = (state: Object, { guid }: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('expanded', R.not(entity.expanded), entity);
        }

        return entity;
      },
      state.monthlyPayrollDeduction,
    ),
    state,
  )
);

const toggleDriverDailyHoursDetails = (state: Object, { guid }: Object) => {
  const { driverDailyHours } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), driverDailyHours);

  return P.$toggle(`driverDailyHours.${index}.expanded`, state);
};

const getDriverPayrollChargesByMonthlyPaymentSuccess = (state: Object, { data, guid }: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('details', data, entity);
        }

        return entity;
      },
      state.monthlyPayrollDeduction,
    ),
    state,
  )
);

const getDriverPayrollChargesIsPaidOnPayrollSuccess = (state: Object, data: Object) => (
  P.$set(
    'monthlyPayrollDeduction',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      state.monthlyPayrollDeduction,
    ),
    state,
  )
);

const getPayrollChargesByPayrollAccessorialSuccess = (state: Object, { data, guid }: Object) => (
  P.$set(
    'payrollAssessorialList',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('details', data, entity);
        }

        return entity;
      },
      state.payrollAssessorialList,
    ),
    state,
  )
);

const getPayrollAccessorialIsPaidOnPayrollSuccess = (state: Object, data: Oject) => (
  P.$set(
    'payrollAssessorialList',
    R.map(
      (entity: Object) => R.assoc('deductionDisabled', R.prop(G.getGuidFromObject(entity), data), entity),
      state.payrollAssessorialList,
    ),
    state,
  )
);

const togglePayrollAssessorialDetails = (state: Object, { guid }: Object) => (
  P.$set(
    'payrollAssessorialList',
    R.map(
      (entity: Object) => {
        if (R.propEq(guid, GC.FIELD_GUID, entity)) {
          return R.assoc('expanded', R.not(entity.expanded), entity);
        }

        return entity;
      },
      state.payrollAssessorialList,
    ),
    state,
  )
);

// branch configs
const getBranchConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('branchConfigs', data, state)
);

// safetyViolation
const cleanSafetyViolation = (state: Object) => P.$set('safetyViolation', {}, state);

const getSafetyViolationListByEntityTypeSuccess = (state: Object, { data, entityType, primaryObjectGuid }: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(item, { index, expanded: false })),
  )(data);

  if (R.equals(entityType, 'safetyViolation')) return P.$set('safetyViolation', list, state);

  return P.$set(`safetyViolation.${primaryObjectGuid}.${entityType}s`, list, state);
};

const toggleSafetyViolationItem = (state: Object, { guid, componentType = null }: string) => {
  const item = R.pathOr({}, ['safetyViolation', guid], state);

  if (G.isAllTrue(
    item.expanded,
    G.notEquals(componentType, item.componentType),
    R.includes(componentType, ['note', 'document']),
  )) return P.$set(`safetyViolation.${guid}.componentType`, componentType, state);

  return (
    P.$all(
      P.$toggle(`safetyViolation.${guid}.expanded`),
      P.$set(`safetyViolation.${guid}.componentType`, componentType),
      state,
    )
  );
};

const createOrUpdateSafetyViolationEntityByEntityTypeSuccess = (state: Object, { data, entityType }: Object) => {
  const { safetyViolation } = state;
  const { guid, primaryObjectGuid, safetyViolationGuid } = data;

  if (R.equals(entityType, 'safetyViolation')) {
    const notes = R.pathOr({}, [guid, 'notes'], safetyViolation);
    const documents = R.pathOr({}, [guid, 'documents'], safetyViolation);
    const expanded = R.pathOr(false, [guid, 'expanded'], safetyViolation);
    const componentType = R.path([guid, 'componentType'], safetyViolation);
    const index = R.pathOr(R.length(R.values(safetyViolation)), [guid, 'index'], safetyViolation);

    return P.$set(
      `safetyViolation.${guid}`,
      R.mergeRight(data, { notes, index, expanded, documents, componentType }),
      state,
    );
  }

  const correctSafetyViolationGuid = R.or(primaryObjectGuid, safetyViolationGuid);
  const path = ['safetyViolation', correctSafetyViolationGuid, `${entityType}s`];
  const index = R.pathOr(R.length(R.values(R.path(path, state))), [...path, guid, 'index'], state);

  return P.$set(`${R.join('.', path)}.${guid}`, R.assoc('index', index, data), state);
};

const removeSafetyViolationEntityByEntityTypeSuccess = (state: Object, data: Object) => {
  const { guid, entityType, primaryObjectGuid } = data;

  const path = G.ifElse(
    R.equals(entityType, 'safetyViolation'),
    `safetyViolation.${guid}`,
    `safetyViolation.${primaryObjectGuid}.${entityType}s.${guid}`,
  );

  return P.$drop(path, state);
};

// fleet-vendor
const getFleetVendorListSuccess = (state: Object, data: Object) => P.$set('fleetVendorList', data, state);

export default createReducer({
  [A.cleanFormStore]: cleanFormStore,
  [A.getUsersSuccess]: getUsersSuccess,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setDocumentsFilter]: setDocumentsFilter,
  [A.toggleFuelCardDetails]: toggleFuelCardDetails,
  [A.receivedDriverSuccess]: receivedDriverSuccess,
  [A.receivedGetAssignInfo]: receivedGetAssignInfo,
  [A.toggleDriverFormGroup]: toggleDriverFormGroup,
  [A.receivedDriverRefSuccess]: receivedDriverRefSuccess,
  [A.receivedDriverDocSuccess]: receivedDriverDocSuccess,
  [A.getExternalDriverIdSuccess]: getExternalDriverIdSuccess,
  [A.receivedDriverHoursSuccess]: receivedDriverHoursSuccess,
  [A.getDriverDailyHoursSuccess]: getDriverDailyHoursSuccess,
  [A.createAdvancePaymentSuccess]: createAdvancePaymentSuccess,
  [A.getDeductionSettingsSuccess]: getDeductionSettingsSuccess,
  [A.receivedDriverContactSuccess]: receivedDriverContactSuccess,
  [A.receivedDriverCitizenSuccess]: receivedDriverCitizenSuccess,
  [A.receivedDriverLicenseSuccess]: receivedDriverLicenseSuccess,
  [A.getAdvancePaymentListSuccess]: getAdvancePaymentListSuccess,
  [A.createDriverDailyHoursSuccess]: createDriverDailyHoursSuccess,
  [A.updateDriverDailyHoursSuccess]: updateDriverDailyHoursSuccess,
  [A.deleteDriverDailyHoursSuccess]: deleteDriverDailyHoursSuccess,
  [A.toggleDriverDailyHoursDetails]: toggleDriverDailyHoursDetails,
  [A.receivedUpdateDriverDocSuccess]: receivedUpdateDriverDocSuccess,
  [A.receivedDeleteDriverDocSuccess]: receivedDeleteDriverDocSuccess,
  [A.receivedCreateDriverDocSuccess]: receivedCreateDriverDocSuccess,
  [A.receivedGetDriverConfigsSuccess]: receivedGetDriverConfigsSuccess,
  [A.togglePayrollAssessorialDetails]: togglePayrollAssessorialDetails,
  [A.receivedUpdateDriverHoursSuccess]: receivedUpdateDriverHoursSuccess,
  [A.getPayrollAssessorialListSuccess]: getPayrollAssessorialListSuccess,
  [A.getMonthlyPayrollDeductionSuccess]: getMonthlyPayrollDeductionSuccess,
  [A.getPayrollCompensationRateSuccess]: getPayrollCompensationRateSuccess,
  [A.receivedGetAvailableDriverSuccess]: receivedGetAvailableDriverSuccess,
  [A.receivedGetAvailableTrucksSuccess]: receivedGetAvailableTrucksSuccess,
  [A.receivedDriverAccessorialsSuccess]: receivedDriverAccessorialsSuccess,
  [A.updateAdvancePaymentStatusSuccess]: updateAdvancePaymentStatusSuccess,
  [A.receivedUpdateDriverContactSuccess]: receivedUpdateDriverContactSuccess,
  [A.receivedUpdateDriverLicenseSuccess]: receivedUpdateDriverLicenseSuccess,
  [A.receivedCreateDriverContactSuccess]: receivedCreateDriverContactSuccess,
  [A.receivedUpdateDriverDetailsSuccess]: receivedUpdateDriverDetailsSuccess,
  [A.receivedDriverCompensationsSuccess]: receivedDriverCompensationsSuccess,
  [A.receivedDriverCertificationSuccess]: receivedDriverCertificationSuccess,
  [A.receivedUpdateDriverCitizenSuccess]: receivedUpdateDriverCitizenSuccess,
  [A.receivedDeleteDriverContactSuccess]: receivedDeleteDriverContactSuccess,
  [A.receivedFuelCardListByDriverSuccess]: receivedFuelCardListByDriverSuccess,
  [A.receivedGetAvailableTrailersSuccess]: receivedGetAvailableTrailersSuccess,
  [A.receivedDeleteDriverReferenceSuccess]: receivedDeleteDriverReferenceSuccess,
  [A.receivedUpdateDriverReferenceSuccess]: receivedUpdateDriverReferenceSuccess,
  [A.toggleMonthlyPayrollDeductionDetails]: toggleMonthlyPayrollDeductionDetails,
  [A.receivedCreatedDriverReferenceSuccess]: receivedCreatedDriverReferenceSuccess,
  [A.receivedCreateDriverAccessorialSuccess]: receivedCreateDriverAccessorialSuccess,
  [A.receivedUpdateDriverCertificateSuccess]: receivedUpdateDriverCertificateSuccess,
  [A.receivedCreateDriverCertificateSuccess]: receivedCreateDriverCertificateSuccess,
  [A.receivedUpdateDriverAccessorialSuccess]: receivedUpdateDriverAccessorialSuccess,
  [A.receivedDeleteDriverCertificateSuccess]: receivedDeleteDriverCertificateSuccess,
  [A.receivedDeleteDriverAccessorialSuccess]: receivedDeleteDriverAccessorialSuccess,
  [A.receivedCreateDriverCompensationSuccess]: receivedCreateDriverCompensationSuccess,
  [A.receivedDeleteDriverCompensationSuccess]: receivedDeleteDriverCompensationSuccess,
  [A.receivedUpdateDriverCompensationSuccess]: receivedUpdateDriverCompensationSuccess,
  [A.receivedDriverLatestKnownLocationSuccess]: receivedDriverLatestKnownLocationSuccess,
  [A.getDriverPayrollNumberListByGuidsSuccess]: getDriverPayrollNumberListByGuidsSuccess,
  [A.getPayrollAccessorialIsPaidOnPayrollSuccess]: getPayrollAccessorialIsPaidOnPayrollSuccess,
  [A.getPayrollChargesByPayrollAccessorialSuccess]: getPayrollChargesByPayrollAccessorialSuccess,
  [A.getDriverPayrollChargesIsPaidOnPayrollSuccess]: getDriverPayrollChargesIsPaidOnPayrollSuccess,
  [A.receivedCreateDriverLatestKnownLocationSuccess]: receivedCreateDriverLatestKnownLocationSuccess,
  [A.getDriverPayrollChargesByMonthlyPaymentSuccess]: getDriverPayrollChargesByMonthlyPaymentSuccess,
  // branch configs
  [A.getBranchConfigsByNamesSuccess]: getBranchConfigsByNamesSuccess,
  // safety violation
  [A.cleanSafetyViolation]: cleanSafetyViolation,
  [A.toggleSafetyViolationItem]: toggleSafetyViolationItem,
  [A.getSafetyViolationListByEntityTypeSuccess]: getSafetyViolationListByEntityTypeSuccess,
  [A.removeSafetyViolationEntityByEntityTypeSuccess]: removeSafetyViolationEntityByEntityTypeSuccess,
  [A.createOrUpdateSafetyViolationEntityByEntityTypeSuccess]: createOrUpdateSafetyViolationEntityByEntityTypeSuccess,
  // fleet-vendor
  [A.getFleetVendorListSuccess]: getFleetVendorListSuccess,
}, initialState);
