import * as R from 'ramda';
import React from 'react';
// components
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, BoxHovered } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature driver profile simple
import { InfoPair } from './';
//////////////////////////////////////////////////

const actionColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const RegistrationInfo = ({
  titleState,
  titleNumber,
  licensePlate,
  registrationState,
  registrationExpirationDate,
}: Object) => (
  <Box p='8px' width={250}>
    <InfoPair
      width='100%'
      fontSize={11}
      text={licensePlate}
      label={G.getWindowLocale('titles:license-plate', 'License Plate')} />
    <InfoPair
      mt='8px'
      width='100%'
      fontSize={11}
      text={registrationState}
      label={G.getWindowLocale('titles:registration-state', 'Registration State')} />
    <Flex mt='8px' justifyContent='space-between'>
      <InfoPair
        fontSize={11}
        text={titleState}
        label={G.getWindowLocale('titles:title-state', 'Title State')} />
      <InfoPair
        fontSize={11}
        text={titleNumber}
        label={G.getWindowLocale('titles:title-number', 'Title Number')} />
    </Flex>
    <Flex mt='8px'>
      <Box mr='4px'>{I.calendar(textColor)}</Box>
      <InfoPair
        fontSize={11}
        width='calc(100% - 15px)'
        text={registrationExpirationDate}
        label={G.getWindowLocale('titles:registration-expiration-date', 'Registration expiration date')} />
    </Flex>
  </Box>
);

export const AssignInfoComponent = ({ truck, trailers }: Object) => {
  if (R.and(G.isNilOrEmpty(truck), G.isNilOrEmpty(trailers))) return null;

  return (
    <Flex
      mt={15}
      fontSize={12}
      fontWeight='bold'
      color={textColor}
    >
      {
        G.isNotNilAndNotEmpty(truck) &&
        <Flex maxWidth='45%'>
          <Box textTransform='uppercase'>
            {G.getWindowLocale('titles:truck', 'Truck')}:
          </Box>
          <PopperComponent
            type='hover'
            borderWidth='none'
            position='top-start'
            popperBorderRadius='4px'
            content={<RegistrationInfo {...truck} />}
            popperBoxShadow={`0 2px 10px 0 ${G.getTheme('colors.boxShadowGrey')}`}
          >
            <BoxHovered
              ml='5px'
              cursor='pointer'
              hoverColor={actionColor}
              textDecoration='underline'
              onClick={() =>
                G.goToRoute(routesMap[G.getFleetProfileRoutePathNameByFleetType('truck')](G.getGuidFromObject(truck)))
              }
            >
              {G.getPropFromObject(GC.FIELD_UNIT_ID, truck)}
            </BoxHovered>
          </PopperComponent>
        </Flex>
      }
      {
        G.isNotNilAndNotEmpty(trailers) &&
        <Flex ml={30} maxWidth='45%'>
          <Box textTransform='uppercase'>
            {G.getWindowLocale('titles:trailers', 'Trailers')}:
          </Box>
          <Box>
            {
              trailers.map((item: Object) => (
                <PopperComponent
                  type='hover'
                  borderWidth='none'
                  position='top-start'
                  popperBorderRadius='4px'
                  key={G.getGuidFromObject(item)}
                  content={<RegistrationInfo {...truck} />}
                  popperBoxShadow={`0 2px 10px 0 ${G.getTheme('colors.boxShadowGrey')}`}
                >
                  <BoxHovered
                    ml='5px'
                    cursor='pointer'
                    lineHeight={1.6}
                    wordBreak='break-all'
                    hoverColor={actionColor}
                    textDecoration='underline'
                    onClick={() => G.goToRoute(
                      routesMap[G.getFleetProfileRoutePathNameByFleetType('trailer')](G.getGuidFromObject(item)),
                    )}
                  >
                    {G.getPropFromObject(GC.FIELD_UNIT_ID, item)}
                  </BoxHovered>
                </PopperComponent>
              ))
            }
          </Box>
        </Flex>
      }
    </Flex>
  );
};

export default AssignInfoComponent;
