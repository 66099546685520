// helpers/constants
import * as GC from '../../../constants';
import { LOCATION_TEMPLATE_BRANCH_FILTER_TYPES } from '../../../helpers/options';
// feature configs
import { MultiselectFieldComponent } from '../ui';
//////////////////////////////////////////////////

const contactBookSection = {
  title: 'titles:contact-book',
  fields: {
    [GC.TEMPLATES_CONTACT_BOOK_AUTO_SAVED]: {
      type: 'switcher',
      name: 'titles:auto-save-email',
      nameForAttribute: GC.TEMPLATES_CONTACT_BOOK_AUTO_SAVED,
    },
  },
};

const locationTemplateSection = {
  title: 'titles:location-template',
  fields: {
    [GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED]: {
      type: 'switcher',
      name: 'titles:auto-location-template-id',
      nameForAttribute: GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    },
    [GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE]: {
      type: 'select',
      options: 'sequences',
      name: 'titles:location-template-id-sequence',
      nameForAttribute: GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
    },
    [GC.TEMPLATES_LOCATION_TEMPLATE_BRANCH_FILTER_TYPE]: {
      type: 'select',
      name: 'titles:branch-filter-type',
      options: LOCATION_TEMPLATE_BRANCH_FILTER_TYPES,
      nameForAttribute: GC.TEMPLATES_LOCATION_TEMPLATE_BRANCH_FILTER_TYPE,
    },
    [GC.TEMPLATES_LOCATION_TEMPLATE_BLOCKED_LOCATION_TYPES]: {
      type: 'list',
      options: 'availableLocationTypes',
      component: MultiselectFieldComponent,
      name: 'titles:blocked-location-types',
      shouldGetInheritedValueFromConfigOptions: true,
      nameForAttribute: GC.TEMPLATES_LOCATION_TEMPLATE_BLOCKED_LOCATION_TYPES,
    },
  },
};

const locationTypeSection = {
  fields: [],
  configType: 'dropdown',
  title: 'titles:location-type',
  configName: GC.TEMPLATES_LOCATION_TYPE,
};

const ConfigTemplatesGroup = {
  contactBookSection,
  locationTemplateSection,
  locationTypeSection,
};

export {
  ConfigTemplatesGroup,
};
