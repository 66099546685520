import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fieldsWrapperStyles = {
  flexDirection: 'column',
};

export const fields = {
  [GC.FIELD_CURRENCY]: {
    width: 100,
    type: 'select',
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE]: {
    name: 'titles:deduction-percentage',
    validate: G.curriedIsLessOrEqual(100),
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE]: G.yupNumberNotRequired
    .min(0, G.getShouldBePositiveLocaleTxt())
    .max(100, G.getShouldBeLessOrEqualLocaleTxt(100)),
});

const financeSettings = {
  fields,
  singleItem: '',
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'finance',
  actionsPicker: ['edit'],
  itemTitleArr: ['titles:finance', 'Finance'],
  formGroupTitleArr: ['titles:finance', 'Finance'],
  additionalTableSettings: { checkBoxCellWidth: 35 },
  endpoints: {
    list: 'carrierFinance',
    createOrUpdate: 'carrierFinance',
  },
};

const terminalFinanceSettings = {
  ...financeSettings,
  groupName: 'terminalFinance',
  primaryObjectGuidKey: 'carrierTerminalGuid',
  endpoints: {
    list: 'carrierTerminalFinance',
    createOrUpdate: 'carrierTerminalFinance',
  },
  // helpers
  makeRequestPayload: ({ primaryObjectGuid }: Object) => ({
    params: { [GC.FIELD_CARRIER_TERMINAL_GUID]: primaryObjectGuid },
  }),
};

export {
  financeSettings,
  terminalFinanceSettings,
};
