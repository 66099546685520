// helpers/constants
import * as G from '../../../helpers';
import { MultiselectFieldComponent } from '../ui';
// helpers/constants
import * as GC from '../../../constants';
import {
  getMobileAppHideDocumentBtnsEnumOptions,
  getMobileAppRequiredTrailerConfigEnumOptions,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const DRIVER_APP = [
  {
    title: 'titles:general',
    fields: {
      [GC.MOBILEAPP_DRIVER_SHOWN_REFERENCES]: {
        zIndex: 22,
        type: 'list',
        options: 'telReferenceTypes',
        name: 'titles:shown-references',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.MOBILEAPP_DRIVER_SHOWN_REFERENCES,
      },
      [GC.MOBILEAPP_DRIVER_DRIVER_PAYROLL_SHOWN_STATUSES]: {
        zIndex: 21,
        type: 'list',
        options: 'driverPayrollStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:driver-payroll-shown-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.MOBILEAPP_DRIVER_DRIVER_PAYROLL_SHOWN_STATUSES,
      },
      [GC.MOBILEAPP_DRIVER_VENDOR_PAYROLL_SHOWN_STATUSES]: {
        zIndex: 20,
        type: 'list',
        options: 'driverPayrollStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:vendor-payroll-shown-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.MOBILEAPP_DRIVER_VENDOR_PAYROLL_SHOWN_STATUSES,
      },
      [GC.MOBILEAPP_DRIVER_VIN_CODE_SCAN_REQUIRED]: {
        type: 'switcher',
        name: 'titles:scan-vin-code-required',
        nameForAttribute: GC.MOBILEAPP_DRIVER_VIN_CODE_SCAN_REQUIRED,
      },
      [GC.MOBILEAPP_DRIVER_REQUIRED_TRAILER]: {
        type: 'select',
        name: 'titles:required-trailer',
        nameForAttribute: GC.MOBILEAPP_DRIVER_REQUIRED_TRAILER,
        options: getMobileAppRequiredTrailerConfigEnumOptions(),
      },
      [GC.MOBILEAPP_INSPECTION_REQUIRED_ON_FIRST_PICKUP]: {
        type: 'switcher',
        name: 'titles:inspection-required-on-first-pickup',
        nameForAttribute: GC.MOBILEAPP_INSPECTION_REQUIRED_ON_FIRST_PICKUP,
      },
      [GC.MOBILEAPP_INSPECTION_REQUIRED_ON_LAST_DROP]: {
        type: 'switcher',
        name: 'titles:inspection-required-on-last-drop',
        nameForAttribute: GC.MOBILEAPP_INSPECTION_REQUIRED_ON_LAST_DROP,
      },
    },
  },
  {
    title: 'titles:documents',
    fields: {
      [GC.MOBILEAPP_DRIVER_HIDE_DOCUMENT_BUTTONS]: {
        zIndex: 19,
        type: 'list',
        name: 'titles:hide-app-buttons',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        options: getMobileAppHideDocumentBtnsEnumOptions(),
        nameForAttribute: GC.MOBILEAPP_DRIVER_HIDE_DOCUMENT_BUTTONS,
      },
      [GC.MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_PICKUP]: {
        type: 'select',
        options: 'availableDocumentTypes',
        name: 'title:default-document-type-pickup',
        nameForAttribute: GC.MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_PICKUP,
      },
      [GC.MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_DROP]: {
        type: 'select',
        options: 'availableDocumentTypes',
        name: 'title:default-document-type-drop',
        nameForAttribute: GC.MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_DROP,
      },
      [GC.MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_GENERAL]: {
        type: 'select',
        options: 'availableDocumentTypes',
        name: 'title:default-document-type-general',
        nameForAttribute: GC.MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_GENERAL,
      },
      [GC.MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_PICKUP]: {
        zIndex: 18,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:required-document-types-for-pickup',
        nameForAttribute: GC.MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_PICKUP,
      },
      [GC.MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_DROP]: {
        zIndex: 17,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:required-document-types-for-drop',
        nameForAttribute: GC.MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_DROP,
      },
      [GC.MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_LAST_DROP]: {
        zIndex: 16,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:required-document-types-for-last-drop',
        nameForAttribute: GC.MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_LAST_DROP,
      },
    },
  },
  {
    title: 'titles:check-status',
    fields: {
      [GC.MOBILEAPP_DRIVER_LOAD_STATUS_CHECK_AUTO_STATUS_CHECK_ENABLED]: {
        type: 'switcher',
        name: 'titles:auto-status-check-enabled',
        nameForAttribute: GC.MOBILEAPP_DRIVER_LOAD_STATUS_CHECK_AUTO_STATUS_CHECK_ENABLED,
      },
      [GC.MOBILEAPP_DRIVER_LOAD_STATUS_CHECK_FREQUENCY]: {
        type: 'input',
        validate: G.isEmptyOrInteger,
        name: 'titles:status-check-frequency',
        nameForAttribute: GC.MOBILEAPP_DRIVER_LOAD_STATUS_CHECK_FREQUENCY,
      },
      [GC.MOBILEAPP_DRIVER_STATUS_CHECK_DEFAULT_STATUS_CODE]: {
        type: 'select',
        options: 'trackingStatusMessageCodes',
        name: 'titles:default-status-message-code',
        nameForAttribute: GC.MOBILEAPP_DRIVER_STATUS_CHECK_DEFAULT_STATUS_CODE,
      },
      [GC.MOBILEAPP_DRIVER_STATUS_CODE_STOP_CHECK_IN]: {
        type: 'select',
        name: 'titles:status-code-for-check-in',
        options: 'checkedInCompletedStatusMessageCodes',
        nameForAttribute: GC.MOBILEAPP_DRIVER_STATUS_CODE_STOP_CHECK_IN,
      },
      [GC.MOBILEAPP_DRIVER_STATUS_CODE_PICKUP_CHECK_IN]: {
        type: 'select',
        options: 'pickupCheckedInStatusMessageCodes',
        name: 'titles:code-for-arrived-at-pickup-location',
        nameForAttribute: GC.MOBILEAPP_DRIVER_STATUS_CODE_PICKUP_CHECK_IN,
        infoText: 'Allows users to select the default Status Message Code for drivers checking in to a Pickup Event using the Mobile Application', // eslint-disable-line
      },
      [GC.MOBILEAPP_DRIVER_STATUS_CODE_DROP_CHECK_IN]: {
        type: 'select',
        options: 'dropCheckedInStatusMessageCodes',
        name: 'titles:code-for-arrived-at-delivery-location',
        nameForAttribute: GC.MOBILEAPP_DRIVER_STATUS_CODE_DROP_CHECK_IN,
        infoText: 'Allows users to select the default Status Message Code for drivers checking in to a Drop Event using the Mobile Application', // eslint-disable-line
      },
      [GC.MOBILEAPP_DRIVER_STATUS_CODE_PICKUP_COMPLETE]: {
        type: 'select',
        options: 'pickupCompletedStatusMessageCodes',
        name: 'titles:status-code-for-complete-pickup',
        nameForAttribute: GC.MOBILEAPP_DRIVER_STATUS_CODE_PICKUP_COMPLETE,
      },
      [GC.MOBILEAPP_DRIVER_STATUS_CODE_DROP_COMPLETE]: {
        type: 'select',
        options: 'dropCompletedStatusMessageCodes',
        name: 'titles:status-code-for-complete-drop',
        nameForAttribute: GC.MOBILEAPP_DRIVER_STATUS_CODE_DROP_COMPLETE,
      },
    },
  },
];

const CARRIER_APP = [
  {
    title: 'titles:general',
    fields: {
      [GC.MOBILEAPP_CARRIER_COMPANY_NAME]: {
        type: 'input',
        name: 'titles:company-name',
        nameForAttribute: GC.MOBILEAPP_CARRIER_COMPANY_NAME,
      },
      [GC.MOBILEAPP_CARRIER_SHOWN_DOCUMENT_TYPES]: {
        zIndex: 17,
        type: 'list',
        options: 'availableDocumentTypes',
        name: 'titles:shown-document-types',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.MOBILEAPP_CARRIER_SHOWN_DOCUMENT_TYPES,
      },
      [GC.MOBILEAPP_CARRIER_SHOWN_TRIP_REFERENCES]: {
        zIndex: 16,
        type: 'list',
        options: 'telReferenceTypes',
        component: MultiselectFieldComponent,
        name: 'titles:shown-trip-references',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.MOBILEAPP_CARRIER_SHOWN_TRIP_REFERENCES,
      },
      [GC.MOBILEAPP_CARRIER_HIDE_EVENT_TIME]: {
        type: 'switcher',
        name: 'titles:hide-event-time',
        nameForAttribute: GC.MOBILEAPP_CARRIER_HIDE_EVENT_TIME,
      },
      [GC.MOBILEAPP_CARRIER_USE_FLEET_TRAILER]: {
        type: 'switcher',
        name: 'titles:use-fleet-trailer',
        nameForAttribute: GC.MOBILEAPP_CARRIER_USE_FLEET_TRAILER,
        infoText: G.getWindowLocale(
          'messages:activate-use-fleet-trailer-on-carrier-mobile-app',
          'Allows users to manage Fleet Trailer Issues, Inspections and Documents directly within the Amous Carrier Tracking Mobile App. To activate this feature navigate to Configuration → Carrier → General → "Use Fleet Trailer" → switch to "Yes"',
        ),
      },
      [GC.MOBILEAPP_CARRIER_INSPECTION_REQUIRED_ON_FIRST_PICKUP]: {
        type: 'switcher',
        name: 'titles:inspection-required-on-first-pickup',
        nameForAttribute: GC.MOBILEAPP_CARRIER_INSPECTION_REQUIRED_ON_FIRST_PICKUP,
      },
      [GC.MOBILEAPP_CARRIER_INSPECTION_REQUIRED_ON_LAST_DROP]: {
        type: 'switcher',
        name: 'titles:inspection-required-on-last-drop',
        nameForAttribute: GC.MOBILEAPP_CARRIER_INSPECTION_REQUIRED_ON_LAST_DROP,
      },
    },
  },
  {
    title: 'titles:documents',
    fields: {
      [GC.MOBILEAPP_CARRIER_HIDE_DOCUMENT_BUTTONS]: {
        zIndex: 15,
        type: 'list',
        name: 'titles:hide-app-buttons',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        options: getMobileAppHideDocumentBtnsEnumOptions(),
        nameForAttribute: GC.MOBILEAPP_CARRIER_HIDE_DOCUMENT_BUTTONS,
      },
      [GC.MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_PICKUP]: {
        type: 'select',
        options: 'availableDocumentTypes',
        name: 'title:default-document-type-pickup',
        nameForAttribute: GC.MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_PICKUP,
      },
      [GC.MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_DROP]: {
        type: 'select',
        options: 'availableDocumentTypes',
        name: 'title:default-document-type-drop',
        nameForAttribute: GC.MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_DROP,
      },
      [GC.MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_GENERAL]: {
        type: 'select',
        options: 'availableDocumentTypes',
        name: 'title:default-document-type-general',
        nameForAttribute: GC.MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_GENERAL,
      },
      [GC.MOBILEAPP_CARRIER_REQUIRED_DOCUMENT_TYPE_PICKUP]: {
        zIndex: 14,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:required-document-types-for-pickup',
        nameForAttribute: GC.MOBILEAPP_CARRIER_REQUIRED_DOCUMENT_TYPE_PICKUP,
      },
      [GC.MOBILEAPP_CARRIER_REQUIRED_DOCUMENT_TYPE_DROP]: {
        zIndex: 13,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:required-document-types-for-drop',
        nameForAttribute: GC.MOBILEAPP_CARRIER_REQUIRED_DOCUMENT_TYPE_DROP,
      },
    },
  },
  {
    title: 'titles:check-status',
    fields: {
      [GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_AUTO_ENABLED]: {
        type: 'switcher',
        name: 'titles:auto-status-check-enabled',
        nameForAttribute: GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_AUTO_ENABLED,
      },
      [GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_FREQUENCY]: {
        type: 'input',
        validate: G.isEmptyOrInteger,
        name: 'titles:status-check-frequency-min',
        nameForAttribute: GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_FREQUENCY,
      },
      [GC.MOBILEAPP_CARRIER_LOAD_STATUS_SMS_ENABLED]: {
        type: 'switcher',
        name: 'titles:sms-notification',
        nameForAttribute: GC.MOBILEAPP_CARRIER_LOAD_STATUS_SMS_ENABLED,
        infoText: G.getWindowLocale(
          'messages:sms-notification',
          'Before enabling the current configuration, please go to Configurations->Carrier->Status Check and enable the "Load Check Process Enabled" configuration',
        ),
      },
      [GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_TRUCKING_STATUS_CODE]: {
        type: 'select',
        options: 'trackingStatusMessageCodes',
        name: 'titles:default-status-message-code',
        nameForAttribute: GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_TRUCKING_STATUS_CODE,
      },
      [GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_STOP_CHECK_IN]: {
        type: 'select',
        name: 'titles:status-code-for-check-in',
        options: 'checkedInCompletedStatusMessageCodes',
        nameForAttribute: GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_STOP_CHECK_IN,
      },
      [GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_PICKUP_COMPLETE]: {
        type: 'select',
        options: 'pickupCompletedStatusMessageCodes',
        name: 'titles:status-code-for-complete-pickup',
        nameForAttribute: GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_PICKUP_COMPLETE,
      },
      [GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_DROP_COMPLETE]: {
        type: 'select',
        options: 'dropCompletedStatusMessageCodes',
        name: 'titles:status-code-for-complete-drop',
        nameForAttribute: GC.MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_DROP_COMPLETE,
      },
    },
  },
];

const ConfigMobileAppGroup = {
  DRIVER_APP,
  CARRIER_APP,
};

export {
  ConfigMobileAppGroup,
};
