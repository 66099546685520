import React from 'react';
import * as R from 'ramda';
// components
import { Table } from '../../../../components/table';
// forms
import { Toggle } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
// feature fleet-profile
import PayrollCompensationForm from '../forms/payroll-compensation';
//////////////////////////////////////////////////

export const payrollCompensationFields = {
  [GC.FIELD_WORKING_DAYS]: {
    width: 150,
    name: 'titles:working-days',
  },
  [GC.FIELD_RATE_TYPE]: {
    width: 150,
    name: 'titles:rate-type',
  },
  [GC.FIELD_RATE_UNIT]: {
    width: 150,
    name: 'titles:rate-unit',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
};

export const payrollVariableValuesTableSettings = {
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  maxHeight: 'fit-content',
};

export const payrollVariableValuesReport = {
  fields: [
    { sequence: 0, name: GC.FIELD_CHARGE_NAME },
    { sequence: 1, name: GC.FIELD_RATE },
    { sequence: 2, name: GC.FIELD_VALUE_FROM },
    { sequence: 3, name: GC.FIELD_VALUE_TO },
  ],
};

export const payrollVariableValuesColumnSettings = {
  [GC.FIELD_CHARGE_NAME]: {
    width: 200,
    name: 'titles:charge-name',
  },
  [GC.FIELD_RATE]: {
    width: 100,
    name: 'titles:rate',
  },
  [GC.FIELD_VALUE_FROM]: {
    width: 100,
    name: 'titles:from',
  },
  [GC.FIELD_VALUE_TO]: {
    width: 100,
    name: 'titles:to',
  },
};

const settings = {
  [GC.FIELD_RATE_TYPE]: {
    width: 150,
    name: 'titles:rate-type',
  },
  [GC.FIELD_RATE_UNIT]: {
    width: 150,
    name: 'titles:rate-unit',
  },
  [GC.FIELD_WORKING_DAYS]: {
    width: 150,
    name: 'titles:working-days',
  },
  [GC.FIELD_CHARGE_NAME]: {
    width: 150,
    name: 'titles:charge-name',
  },
  [GC.FIELD_RATE]: {
    width: 150,
    name: 'titles:rate',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

const tableSettings = {
  maxHeight: 320,
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: false,
  allowSelectItems: false,
  checkBoxCellWidth: '0px',
  minHeight: 'max-content',
  emptyListPadding: '10px 0',
  tableRowHeight: 'max-content',
};

const expandedDetailsComponent = (props: Object) => {
  const itemList = R.path(['parentProps', 'variableValues'], props);

  if (G.isNilOrEmpty(itemList)) return null;

  return (
    <Box ml={25}>
      <Table
        itemList={itemList}
        tableSettings={tableSettings}
        report={payrollVariableValuesReport}
        columnSettings={payrollVariableValuesColumnSettings}
      />
    </Box>
  );
};

const AdditionalFormGroupTitleComponent = ({
  itemList,
  paidByTrip,
  handleCreateOrUpdateItem,
  handleUpdateGeneralDetails,
}: Object) => {
  const handleToggleTripPay = () => {
    if (G.isNilOrEmpty(itemList)) return handleCreateOrUpdateItem();

    handleUpdateGeneralDetails({ paidByTrip: R.not(paidByTrip) });
  };

  return (
    <Box ml={10}>
      <Toggle
        icons={false}
        checked={R.not(paidByTrip)}
        onChange={handleToggleTripPay}
      />
    </Box>
  );
};

export const payrollCompensationSettings = {
  report,
  columnSettings,
  singleItem: true,
  useGetListRequest: true,
  groupName: 'payrollCompensation',
  AdditionalFormGroupTitleComponent,
  CustomForm: PayrollCompensationForm,
  modalAdditionalOptions: { p: '15px 0' },
  includeItemListToTablePropsMemoDeps: true,
  actionsPicker: ['edit', 'removePayrollCompensation'],
  additionalTableSettings: { expandedDetailsComponent },
  itemTitleArr: ['titles:payroll-compensation', 'Payroll Compensation'],
  formGroupTitleArr: ['titles:payroll-compensation', 'Payroll Compensation'],
  endpoints: {
    list: 'payrollCompensation',
    createOrUpdate: 'payrollCompensation',
    remove: 'getPayrollCompensationEndpoint',
  },
  fieldsWrapperStyles: {
    width: 600,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // helpers
  makeInitialValues: (entity: Object, { branchConfigs }: Object) => ({
    ...entity,
    [GC.FIELD_CURRENCY]: R.pathOr(
      G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs),
      [GC.FIELD_CURRENCY],
      entity,
    ),
  }),
};
