import * as R from 'ramda';
import React from 'react';
import { OuterClick } from 'react-outer-click';
import { Manager, Target, Popper } from 'react-popper';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// features routes
import { PopperWrapper, PopperComponentWrapper } from './ui';
/////////////////////////////////////////////////

const getPopperAction = (props: Object) => () => {
  if (props.isPopperOpened) return;
  if (G.isNotNil(props.actionOnHoverOrClick)) {
    props.actionOnHoverOrClick();
  }
  props.handleSetPopperIsOpened(true);
};

const enhance = compose(
  withState('isPopperOpened', 'setOpen', false),
  withHandlers(() => {
    let timeout;
    let openTimeout;
    return {
      handleSetPopperIsOpened: (props: Object) => (value: boolean, clicked: boolean) => {
        clearTimeout(timeout);
        if (R.isNil(value)) {
          return props.setOpen((prev: boolean) => R.not(prev));
        }
        if (R.and(G.isFalse(value), G.isFalse(clicked))) {
          timeout = setTimeout(() => props.setOpen(value), 300);
          clearTimeout(openTimeout);
          return;
        }
        props.setOpen(value);
      },
      handleTogglePopper: ({ setOpen }: Object) => (value: Object) => {
        setOpen(R.not(value));
      },
      handleHoverPopper: (props: Object) => () => {
        const { setOpen, isPopperOpened, actionOnHoverOrClick } = props;
        clearTimeout(timeout);
        if (isPopperOpened) return;
        if (G.isNotNil(actionOnHoverOrClick)) {
          actionOnHoverOrClick();
        }
        openTimeout = setTimeout(() => setOpen(true), 700);
      },
    };
  }),
);

const PopperContent = (props: Object) => {
  const contentIsFunction = G.isFunction(props.content);
  return (
    <PopperWrapper {...props}>
      <Popper className='popper' placement={props.position}>
        {contentIsFunction && props.content({ closePopper: () => props.setOpen(false) })}
        {R.not(contentIsFunction) && props.content}
      </Popper>
    </PopperWrapper>
  );
};

const dropDownOnHover = (props: Object) => (
  <OuterClick onOuterClick={() => props.handleSetPopperIsOpened(false, true)}>
    <PopperComponentWrapper
      maxHeight={props.maxHeight}
      wrapperWidth={props.wrapperWidth}
      activeStyles={props.activeStyles}
      onMouseEnter={props.handleHoverPopper}
      wrapperMaxWidth={props.wrapperMaxWidth}
      onMouseLeave={() => props.handleSetPopperIsOpened(false, false)}
      onMouseOver={() => R.and(props.withCheckMouseOver, props.isPopperOpened) && props.handleHoverPopper()}
    >
      <Manager className='popper-container'>
        <Target className='popper-target'>
          {props.children}
        </Target>
        {
          props.isPopperOpened && <PopperContent {...props} />
        }
      </Manager>
    </PopperComponentWrapper>
  </OuterClick>
);

const dropDownOnClick = (props: Object) => (
  <OuterClick onOuterClick={() => props.handleSetPopperIsOpened(false, true)}>
    <PopperComponentWrapper
      zi={props.zi}
      cursor='pointer'
      maxHeight={props.maxHeight}
      onClick={getPopperAction(props)}
      activeStyles={props.activeStyles}
      wrapperWidth={props.wrapperWidth}
      wrapperMaxWidth={props.wrapperMaxWidth}
    >
      <Manager className='popper-container'>
        <Target className='popper-target'>
          {props.children}
        </Target>
        {
          props.isPopperOpened && <PopperContent {...props} />
        }
      </Manager>
    </PopperComponentWrapper>
  </OuterClick>
);

const PopperComponent = (props: Object) => {
  const dropDownType = {
    hover: dropDownOnHover,
    click: dropDownOnClick,
  };
  return dropDownType[props.type](props);
};

export default enhance(PopperComponent);
