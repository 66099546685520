import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, lifecycle, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { getConfirmModal } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
import { PageActions } from '../../../components/page-actions';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectExpandedContainerOptions } from '../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
import { reportEnhancer } from '../../../report-common';
// icons
import * as I from '../../../svgs';
// ui
import { IconWrapper, ListWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature claim-management
import ClaimForm from '../components/claim-form';
import { tableSettings, columnSettings, FILTER_PARAMS } from './settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectConfigGroup,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from '../selectors';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  createOrUpdateClaimRequest,
  changeDefaultReportRequest,
} from '../actions';
//////////////////////////////////////////////////

const RowActions = (props: Object) => {
  const { entity } = props;

  const options = [
    {
      permissions: null,
      frontIcon: I.claim(),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
      action: () => G.goToRoute(routesMap.claimManagementClaimDetails(G.getGuidFromObject(entity))),
    },
  ];

  return <ActionsElement options={options} entity={entity} />;
};


const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleCreateOrUpdateClaim: (props: Object) => (claimGuid: string) => {
      const {
        openModal,
        openLoader,
        configGroup,
        closeLoader,
        createOrUpdateClaimRequest,
      } = props;

      const component = (
        <ClaimForm
          claimGuid={claimGuid}
          openLoader={openLoader}
          configGroup={configGroup}
          closeLoader={closeLoader}
          submitAction={createOrUpdateClaimRequest}
        />
      );

      const options = {
        p: 15,
        component,
        options: {
          title: G.ifElse(
            G.isString(claimGuid),
            G.getEditTitle,
            G.getAddTitle,
          )(['titles:claim', 'Claim']),
        },
      };

      openModal(options);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          hasIssues={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          updateReportRequest={updateReportRequest}
          createReportRequest={createReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleDelete: ({ openModal, closeModal, deleteItemRequest }: Object) => (guid: string) => {
      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteItemRequest(guid),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
      });

      openModal(modalContent);
    },
    handleClickEditIcon: ({ openFixedPopup }: Object) => (e: Object, entity: Object) => {
      openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: <RowActions entity={entity} />,
      });
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const { expandedContainerOptions: prevExpandedContainerOptions } = prevProps;

      const { getItemListRequest, expandedContainerOptions } = this.props;

      if (G.isAllTrue(
        G.isFalse(R.prop('opened', expandedContainerOptions)),
        R.not(R.eqProps('opened', expandedContainerOptions, prevExpandedContainerOptions)),
        R.propEq(GC.PAGE_TYPE_CLAIM__DETAILS, 'componentType', prevExpandedContainerOptions),
      )) {
        getItemListRequest({ guids: R.of(Array, R.prop('visitPageGuid', prevExpandedContainerOptions)) });
      }
    },
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    handleDelete,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
    handleCreateOrUpdateClaim,
    setExpandedContainerOptions,
  } = props;

  if (R.not(selectedReport)) return null;

  const actionButtons = [
    {
      iconName: 'claim',
      permissions: [PC.CLAIM_READ, PC.CLAIM_WRITE],
      action: (guid: string) => setExpandedContainerOptions({
        opened: true,
        visitPageGuid: guid,
        componentType: GC.PAGE_TYPE_CLAIM__DETAILS,
      }),
    },
    {
      iconName: 'pencil',
      permissions: [PC.CLAIM_WRITE],
      action: handleCreateOrUpdateClaim,
    },
    {
      iconName: 'trash',
      action: handleDelete,
      permissions: [PC.CLAIM_WRITE],
    },
  ];

  const tableSettingsToUse = G.getTableSettingsWithMaxHeightByConditions({
    reportList,
    filterParams,
    tableSettings,
    selectedReport,
  });

  const elementActionsComponent = (entity: Object) => (
    <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, entity)}>
      {I.threeDots()}
    </IconWrapper>
  );

  const data = {
    itemList,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: tableSettingsToUse,
    handleLoadMoreEntities: getItemListRequest,
    loading: R.and(loading, G.isNilOrEmpty(itemList)),
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE),
      transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
  };

  return <Table {...data} />;
};

const ClaimManagementReport = (props: Object) => (
  <ListWrapper p={15}>
    <ZOrderWrapper zIndex={2}>
      <TitlePanel
        {...props}
        height='auto'
        withCount={true}
        noExportable={true}
        popperWithCount={true}
        type={GC.CLAIM_REPORT}
        filterProps={FILTER_PARAMS}
        hiddenRightFilterInfo={false}
        title={G.getWindowLocale('titles:claims', 'Claims')}
      />
    </ZOrderWrapper>
    <ZOrderWrapper zIndex={1}>
      {renderTable(props)}
    </ZOrderWrapper>
    <AuthWrapper has={[PC.CLAIM_WRITE]}>
      <PageActions
        mainAction={{
          action: () => props.handleCreateOrUpdateClaim(),
          text: G.getWindowLocale('actions:add-claim', 'Add Claim'),
        }}
      />
    </AuthWrapper>
  </ListWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  configGroup: makeSelectConfigGroup(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  openLoader,
  closeLoader,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
  createOrUpdateClaimRequest,
  setExpandedContainerOptions,
})(enhance(ClaimManagementReport));
