import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import { taskTypeFormSettings, taskStatusFormSettings } from './settings';
//////////////////////////////////////////////////

const makeRequestValues = ({ editorState, ...values }: Object) => ({
  ...values,
  [GC.FIELD_TASK_DESCRIPTION]: R.isNotNil(editorState) ? G.convertHtmlToString(editorState) : null,
});

const enhance = compose(
  withFormik({
    validationSchema: R.prop('validationSchema'),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(makeRequestValues(values)),
    mapPropsToValues: ({ defaultValues, initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  pure,
);

const Form = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      {...R.pathOr({}, ['optionsForSelect'], props)}
      fields={props.fieldSettings}
      fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
    />
    <FormFooter2 />
  </form>
));

export const TaskTypeForm = (props: Object) => <Form {...props} {...taskTypeFormSettings} />;

export const TaskStatusForm = (props: Object) => (
  <Form
    {...props}
    {...taskStatusFormSettings}
    optionsForSelect={{
      [GC.FIELD_TASK_TYPE_GUIDS]: G.mapNameGuidObjectPropsToLabelValueObject(R.propOr([], ['taskTypeList'], props)),
    }}
  />
);
