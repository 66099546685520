import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

// configs
const getBranchConfigsByNamesRequest = createAction('getBranchConfigsByNamesRequest');
const getBranchConfigsByNamesSuccess = createAction('getBranchConfigsByNamesSuccess');
const getDetailsBranchConfigsByNamesSuccess = createAction('getDetailsBranchConfigsByNamesSuccess');
// item
const createOrUpdateClaimRequest = createAction('createOrUpdateClaimRequest');
const createOrUpdateClaimSuccess = createAction('createOrUpdateClaimSuccess');
// details
const setDetailsInitialState = createAction('setDetailsInitialState');
const getClaimGeneralDetailsRequest = createAction('getClaimGeneralDetailsRequest');
const getClaimGeneralDetailsSuccess = createAction('getClaimGeneralDetailsSuccess');
// documents
const removeDocumentRequest = createAction('removeDocumentRequest');
const removeDocumentSuccess = createAction('removeDocumentSuccess');
const getDocumentListRequest = createAction('getDocumentListRequest');
const getDocumentListSuccess = createAction('getDocumentListSuccess');
const createOrUpdateDocumentRequest = createAction('createOrUpdateDocumentRequest');
const createOrUpdateDocumentSuccess = createAction('createOrUpdateDocumentSuccess');
// notes
const removeNoteRequest = createAction('removeNoteRequest');
const removeNoteSuccess = createAction('removeNoteSuccess');
const getNoteListRequest = createAction('getNoteListRequest');
const getNoteListSuccess = createAction('getNoteListSuccess');
const createOrUpdateNoteRequest = createAction('createOrUpdateNoteRequest');
const createOrUpdateNoteSuccess = createAction('createOrUpdateNoteSuccess');
// related tels
const getRelatedTelsRequest = createAction('getRelatedTelsRequest');
const getRelatedTelsSuccess = createAction('getRelatedTelsSuccess');
// damaged items
const toggleCloItem = createAction('toggleCloItem');
const getCloItemsRequest = createAction('getCloItemsRequest');
const getCloItemsSuccess = createAction('getCloItemsSuccess');
const removeDamagedItemRequest = createAction('removeDamagedItemRequest');
const removeDamagedItemSuccess = createAction('removeDamagedItemSuccess');
const createOrUpdateDamagedItemRequest = createAction('createOrUpdateDamagedItemRequest');
const createOrUpdateDamagedItemSuccess = createAction('createOrUpdateDamagedItemSuccess');
const getDamagedItemsByCloItemGuidRequest = createAction('getDamagedItemsByCloItemGuidRequest');
const getDamagedItemsByCloItemGuidSuccess = createAction('getDamagedItemsByCloItemGuidSuccess');
// accident
const getAccidentRequest = createAction('getAccidentRequest');
const getAccidentSuccess = createAction('getAccidentSuccess');
const removeAccidentRequest = createAction('removeAccidentRequest');
const createOrUpdateAccidentRequest = createAction('createOrUpdateAccidentRequest');
// involved party
const removeInvolvedPartyRequest = createAction('removeInvolvedPartyRequest');
const removeInvolvedPartySuccess = createAction('removeInvolvedPartySuccess');
const getInvolvedPartyListRequest = createAction('getInvolvedPartyListRequest');
const getInvolvedPartyListSuccess = createAction('getInvolvedPartyListSuccess');
const createOrUpdateInvolvedPartyRequest = createAction('createOrUpdateInvolvedPartyRequest');
const createOrUpdateInvolvedPartySuccess = createAction('createOrUpdateInvolvedPartySuccess');
// attorney
const removeAttorneyRequest = createAction('removeAttorneyRequest');
const removeAttorneySuccess = createAction('removeAttorneySuccess');
const getAttorneyListRequest = createAction('getAttorneyListRequest');
const getAttorneyListSuccess = createAction('getAttorneyListSuccess');
const createOrUpdateAttorneyRequest = createAction('createOrUpdateAttorneyRequest');
const createOrUpdateAttorneySuccess = createAction('createOrUpdateAttorneySuccess');

export {
  // report
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // configs
  getBranchConfigsByNamesRequest,
  getBranchConfigsByNamesSuccess,
  getDetailsBranchConfigsByNamesSuccess,
  // item
  createOrUpdateClaimRequest,
  createOrUpdateClaimSuccess,
  // details
  setDetailsInitialState,
  getClaimGeneralDetailsRequest,
  getClaimGeneralDetailsSuccess,
  // documents
  removeDocumentRequest,
  removeDocumentSuccess,
  getDocumentListRequest,
  getDocumentListSuccess,
  createOrUpdateDocumentRequest,
  createOrUpdateDocumentSuccess,
  // notes
  removeNoteRequest,
  removeNoteSuccess,
  getNoteListRequest,
  getNoteListSuccess,
  createOrUpdateNoteRequest,
  createOrUpdateNoteSuccess,
  // related tels
  getRelatedTelsRequest,
  getRelatedTelsSuccess,
  // damaged items
  toggleCloItem,
  getCloItemsRequest,
  getCloItemsSuccess,
  removeDamagedItemRequest,
  removeDamagedItemSuccess,
  createOrUpdateDamagedItemRequest,
  createOrUpdateDamagedItemSuccess,
  getDamagedItemsByCloItemGuidRequest,
  getDamagedItemsByCloItemGuidSuccess,
  // accident
  getAccidentRequest,
  getAccidentSuccess,
  removeAccidentRequest,
  createOrUpdateAccidentRequest,
  // involved party
  removeInvolvedPartyRequest,
  removeInvolvedPartySuccess,
  getInvolvedPartyListRequest,
  getInvolvedPartyListSuccess,
  createOrUpdateInvolvedPartyRequest,
  createOrUpdateInvolvedPartySuccess,
  // attorney
  removeAttorneyRequest,
  removeAttorneySuccess,
  getAttorneyListRequest,
  getAttorneyListSuccess,
  createOrUpdateAttorneyRequest,
  createOrUpdateAttorneySuccess,
};
