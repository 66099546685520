import React from 'react';
import * as R from 'ramda';
// components
import { TabsMui } from '../../../components/tabs-mui';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import routesMap from '../../../utilities/routes';
/////////////////////////////////////////////////

const getTabs = () => {
  const hiddenTabs = G.getHiddenTabsConfigFromWindow();

  const tabs = [
    {
      key: 'tabs:dispatch-tel',
      value: routesMap.routeListLoad,
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      text: G.getWindowLocale('titles:tel', 'TEL'),
    },
    {
      key: 'tabs:dispatch-clo',
      value: routesMap.routeListOrder,
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      text: G.getWindowLocale('titles:clo', 'CLO'),
    },
    {
      key: 'tabs:dispatch-edi',
      value: routesMap.ediList,
      text: G.getWindowLocale('titles:edi', 'EDI'),
      permissions: [PC.CLO_EDI_READ, PC.CLO_EDI_WRITE],
    },
    {
      value: routesMap.carrierEdiList,
      key: 'tabs:dispatch-carrier-edi',
      permissions: [PC.CARRIER_EDI_READ, PC.CARRIER_EDI_WRITE],
      text: G.getWindowLocale('titles:carrier-edi', 'Carrier EDI'),
    },
    {
      value: routesMap.publicCLOList,
      key: 'tabs:dispatch-order-api',
      text: G.getWindowLocale('titles:oder-api', 'Order API'),
      permissions: [PC.PUBLIC_API_ORDER_READ, PC.PUBLIC_API_ORDER_WRITE],
    },
    {
      value: routesMap.orderQuotes,
      key: 'tabs:dispatch-order-quotes',
      text: G.getWindowLocale('titles:quotes', 'Quotes'),
      permissions: [PC.ORDER_QUOTE_READ, PC.ORDER_QUOTE_WRITE],
    },
    {
      value: routesMap.getQuoteList,
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      key: 'tabs:dispatch-order-external-quotes',
      text: G.getWindowLocale('titles:external-quotes', 'External Quotes'),
    },
  ];

  const route = window.location.pathname;

  return R.filter(
    ({ key, value, permissions }: Object) => {
      if (R.equals(route, value)) return true;

      if (G.hasNotAmousCurrentUserPermissions(permissions)) return false;

      return G.notContain(key, hiddenTabs);
    },
    tabs,
  );
};

const DispatchBoardTileTabs = ({ marginLeft, totalCount }: Object) => {
  const tabsStyles = {
    marginLeft,
    maxHeight: 36,
    minHeight: 36,
    marginTop: 'auto',
    minWidth: 'unset',
    marginRight: '10px',
    width: 'max-content',
  };

  return (
    <TabsMui
      tabs={getTabs()}
      count={totalCount}
      tabsStyles={tabsStyles}
      activeMuiTab={window.location.pathname}
      setActiveMuiTab={(route: string) => G.goToRoute(route)}
      tabStyles={{ minHeight: 36, padding: '10px', minWidth: 'unset' }}
    />
  );
};

export default DispatchBoardTileTabs;
