import { createAction } from 'redux-act';
//////////////////////////////////////////////////
export * from './config-pages/ui/actions';
export * from './config-pages/clo/actions';
export * from './config-pages/tel/actions';
export * from './config-pages/driver/actions';
export * from './config-pages/rating/actions';
export * from './config-pages/carrier/actions';
export * from './config-pages/general/actions';
export * from './config-pages/invoice/actions';
export * from './config-pages/integration/actions';
export * from './config-pages/communication/actions';
export * from './config-pages/task-management/actions';
//////////////////////////////////////////////////

export const getSequencesByTypeForConfigRequest = createAction(
  'getSequencesByTypeForConfigRequest',
);
export const getSequencesByTypeForConfigSuccess = createAction(
  'getSequencesByTypeForConfigSuccess',
);
export const cleanConfigState = createAction('cleanConfigState');
export const getReferenceTypesGuidsNamesMapRequest = createAction(
  'getReferenceTypesGuidsNamesMapRequest',
);
export const getReferenceTypesGuidsNamesMapSuccess = createAction(
  'getReferenceTypesGuidsNamesMapSuccess',
);
export const getReferenceTypesByScopeForConfigRequest = createAction(
  'getReferenceTypesByScopeForConfigRequest',
);
export const getReferenceTypesByScopeForConfigSuccess = createAction(
  'getReferenceTypesByScopeForConfigSuccess',
);
export const setConfigWarning = createAction('setConfigWarning');
export const setConfigTabsVisited = createAction('setConfigTabsVisited');
export const updateConfigsSuccess = createAction('updateConfigsSuccess');
export const updateConfigsRequest = createAction('updateConfigsRequest');
export const getStatusCodeListSuccess = createAction('getStatusCodeList');
export const getStatusCodeListRequest = createAction('getStatusCodeList');
export const getConfigByGroupSuccess = createAction('getConfigByGroupSuccess');
export const getConfigByGroupRequest = createAction('getConfigByGroupRequest');
export const setAvailableLocationTypes = createAction('setAvailableLocationTypes');
export const getAvailableDocumentTypes = createAction('getAvailableDocumentTypes');
export const connectToFinancialSuccess = createAction('connectToFinancialSuccess');
export const connectToAccountingRequest = createAction('connectToAccountingRequest');
export const removeOptionalConfigRequest = createAction('removeOptionalConfigRequest');
export const getStatusMessageCodesSuccess = createAction('getStatusMessageCodesSuccess');
export const getSequencesGuidNameMapRequest = createAction('getSequencesGuidNameMapRequest');
export const getSequencesGuidNameMapSuccess = createAction('getSequencesGuidNameMapSuccess');
export const disconnectFromFinancialSuccess = createAction('disconnectFromFinancialSuccess');
export const disconnectFromAccountingRequest = createAction('disconnectFromAccountingRequest');
export const restoreInheritedDropdownRequest = createAction('restoreInheritedDropdownRequest');
export const restoreInheritedByConfigTypeRequest = createAction('restoreInheritedByConfigTypeRequest');
export const receivedAvailableDocumentTypesSuccess = createAction('receivedAvailableDocumentTypesSuccess');
// DISPATCH BOARD ACTION PANEL
export const getCloDispatchBoardActionListRequest = createAction('getCloDispatchBoardActionListRequest');
export const getCloDispatchBoardActionListSuccess = createAction('getCloDispatchBoardActionListSuccess');
export const getTelDispatchBoardActionListRequest = createAction('getTelDispatchBoardActionListRequest');
export const getTelDispatchBoardActionListSuccess = createAction('getTelDispatchBoardActionListSuccess');
export const saveDispatchBoardStatusActionRequest = createAction('saveDispatchBoardStatusActionRequest');
export const updateDispatchBoardStatusActionRequest = createAction('updateDispatchBoardStatusActionRequest');
export const deleteDispatchBoardStatusActionRequest = createAction('deleteDispatchBoardStatusActionRequest');
// terms and conditions
export const getTermsAndConditionsDocumentRequest = createAction('getTermsAndConditionsDocumentRequest');
export const getTermsAndConditionsDocumentSuccess = createAction('getTermsAndConditionsDocumentSuccess');
export const removeTermsAndConditionsDocumentRequest = createAction('removeTermsAndConditionsDocumentRequest');
export const createTermsAndConditionsDocumentRequest = createAction('createTermsAndConditionsDocumentRequest');
export const updateTermsAndConditionsDocumentRequest = createAction('updateTermsAndConditionsDocumentRequest');
// transportation mode config mapping
export const removeTransportationModeConfigMappingRequest =
  createAction('removeTransportationModeConfigMappingRequest');
export const removeTransportationModeConfigMappingSuccess =
  createAction('removeTransportationModeConfigMappingSuccess');
export const getTransportationModeConfigMappingListRequest =
  createAction('getTransportationModeConfigMappingListRequest');
export const getTransportationModeConfigMappingListSuccess =
  createAction('getTransportationModeConfigMappingListSuccess');
export const createOrUpdateTransportationModeConfigMappingRequest =
  createAction('createOrUpdateTransportationModeConfigMappingRequest');
export const createOrUpdateTransportationModeConfigMappingSuccess =
  createAction('createOrUpdateTransportationModeConfigMappingSuccess');
