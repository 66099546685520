import * as R from 'ramda';
import React from 'react';
// components
import { withTabs } from '../../../components/tabs';
// feature payroll
import Route from './route';
import RouteByLoad from './route-by-load';
import PivotRoute from './components/pivot-route';
//////////////////////////////////////////////////

const RouteWrapper = withTabs((props: Object) => {
  const { activeTab, handleSetActiveTab } = props;

  if (R.equals(activeTab, 0)) {
    return <Route activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  } else if (R.equals(activeTab, 1)) {
    return <RouteByLoad activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  }

  return <PivotRoute {...props} />;
});

export default RouteWrapper;
