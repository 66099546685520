import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Fieldset } from '../../../new-do/forms/formik/fieldset';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature fleet-list
import { setItemDetails } from '../../../fleet-list/actions';
// feature fleet
import ServiceVendorForm from '../components/service-vendor-form';
import { documentFieldsSettings, defaultDocumentFields, documentValidationSchema } from '../settings/field-settings';
//////////////////////////////////////////////////

export const withAsyncGetEquipmentServiceByEntityTypeAndServiceGuid = compose(
  withState('equipmentService', 'setEquipmentService', {}),
  withHandlers({
    handleGetEquipmentServiceByEntityTypeAndServiceGuid: (props: Object) => async () => {
      const {
        openLoader,
        entityType,
        closeLoader,
        setEquipmentService,
        equipmentServiceGuid,
      } = props;

      G.callFunction(openLoader);

      const endpoint = G.ifElse(
        R.equals(entityType, GC.FIELD_TRUCK),
        endpointsMap.getCurrentTruckServiceEndpoint,
        endpointsMap.getCurrentTrailerServiceEndpoint,
      )(equipmentServiceGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setEquipmentService(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { equipmentServiceGuid, handleGetEquipmentServiceByEntityTypeAndServiceGuid } = this.props;

      if (G.isNotNilAndNotEmpty(equipmentServiceGuid)) handleGetEquipmentServiceByEntityTypeAndServiceGuid();
    },
  }),
);

export const withAsyncGetAvailableForServiceEquipmentComponentsByEntityType = compose(
  withState('availableForServiceEquipmentComponents', 'setAvailableForServiceEquipmentComponents', []),
  withHandlers({
    handleGetAvailableForServiceEquipmentComponents: (props: Object) => async () => {
      const {
        openLoader,
        entityType,
        entityGuid,
        closeLoader,
        entityGuidType,
        setAvailableForServiceEquipmentComponents,
      } = props;

      G.callFunction(openLoader);

      const endpoint = G.ifElse(
        R.equals(entityType, GC.FIELD_TRUCK),
        endpointsMap.availableForServiceTruckComponentList,
        endpointsMap.availableForServiceTrailerComponentList,
      );

      const requestOptions = {
        params: {
          [entityGuidType]: entityGuid,
        },
      };

      const res = await sendRequest('get', endpoint, requestOptions);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const components = R.map((item: Object) => R.mergeRight(
          item,
          {
            [GC.FIELD_VALUE]: G.getGuidFromObject(item),
            [GC.FIELD_LABEL]: R.prop(GC.FIELD_COMPONENT_ID, item),
          },
        ), R.or(data, []));

        setAvailableForServiceEquipmentComponents(components);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetAvailableForServiceEquipmentComponents();
    },
  }),
);

const formEnhance = compose(
  withFormik({
    validationSchema: documentValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDocumentFields,
      initialValues,
    ),
  }),
  pure,
);

const DocumentForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset
      {...G.getFormikPropsToFieldset(props)}
      fields={R.values(documentFieldsSettings)}
      documentTypeOptions={props.documentTypeOptions}
      fieldsWrapperStyles={{ px: 0, pt: 15, pb: 20, flexDirection: 'column' }}
    />
    <FormFooter2 closeModal={props.closeModal} />
  </form>
));

export const withCreateOrUpdateEquipmentServiceDocument = compose(
  connect(null, { setItemDetails }),
  withHandlers({
    createOrUpdateEquipmentServiceDocumentByEntityTypeRequest: (props: Object) => async (values: Object) => {
      const {
        entityType,
        openLoader,
        closeModal,
        closeLoader,
        documentList,
        localItemList,
        setItemDetails,
        setLocalItemList,
        useComponentState,
        primaryObjectGuid,
      } = props;

      G.callFunction(openLoader);

      const isUpdate = G.isNotNilAndNotEmpty(values.version);

      const options = {
        data: G.makeDataForDocument(R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, primaryObjectGuid, values)),
      };

      const endpoint = G.ifElse(
        isUpdate,
        endpointsMap.updateFleetEquipmentServiceEntityDocument,
        endpointsMap.fleetEquipmentServiceEntityDocument,
      )(entityType);

      const res = await sendRequest('post', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        G.callFunction(closeModal);

        G.showToastrMessageSimple(
          'success',
          G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
        );

        if (isUpdate) {
          const getIndex = R.findIndex(R.propEq(G.getGuidFromObject(values), GC.FIELD_GUID));

          if (useComponentState) {
            const index = getIndex(localItemList);
            const details = R.update(index, data, localItemList);

            setLocalItemList(details);

            setItemDetails({ details, primaryObjectGuid });
          } else {
            const index = getIndex(documentList);

            setItemDetails({ primaryObjectGuid, details: R.update(index, data, documentList) });
          }
        } else {
          const details = R.append(data, G.ifElse(useComponentState, localItemList, documentList));

          setItemDetails({ details, primaryObjectGuid });

          if (useComponentState) setLocalItemList(details);
        }
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
  }),
  withHandlers({
    handleCreateOrUpdateEquipmentServiceDocument: (props: Object) => (entity: Object) => {
      const {
        openModal,
        closeModal,
        primaryObjectGuid,
        documentTypeOptions,
        createOrUpdateEquipmentServiceDocumentByEntityTypeRequest,
      } = props;

      let initialValues;
      const isCreate = G.isNilOrEmpty(entity);

      if (G.isFalse(isCreate)) {
        const file = G.getPropFromObject(GC.FIELD_DOCUMENT_FILE_NAME, entity);
        const documentType = R.path([GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], entity);

        initialValues = R.mergeRight(entity, { file, documentType });
      }

      const submitAction = (values: Object) => createOrUpdateEquipmentServiceDocumentByEntityTypeRequest(R.assoc(
          GC.FIELD_PRIMARY_OBJECT_GUID,
          primaryObjectGuid,
          G.mapObjectEmptyStringFieldsToNull(values),
      ));

      const title = G.ifElse(
        isCreate,
        G.getAddTitle,
        G.getEditTitle,
      )(['titles:equipment-service-document', 'Equipment Service Document']);

      const component = (
        <DocumentForm
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={initialValues}
          documentTypeOptions={documentTypeOptions}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title,
        },
      };

      openModal(modal);
    },
  }),
);

export const withAddServiceVendor = compose(
  withHandlers({
    submitAction: (props: Object) => async (values: Object) => {
      const { closeModal, openLoader, closeLoader, branchGuid, setFieldValue, setServiceVendorListAvailabe } = props;

      openLoader();
      const endpoint = endpointsMap.serviceVendor;

      const { payToLocation, primaryLocation } = values;

      const options = {
        data: {
          ...G.mapObjectEmptyStringFieldsToNull(
            R.omit([GC.SYSTEM_OBJECT_PAY_TO_LOCATION, GC.SYSTEM_OBJECT_PRIMARY_LOCATION], values),
          ),
          [GC.BRANCH_GUID]: branchGuid,
          [GC.SYSTEM_OBJECT_PAY_TO_LOCATION]: G.ifElse(
            G.isNilOrEmpty(payToLocation),
            null,
            G.mapObjectEmptyStringFieldsToNull(payToLocation),
          ),
          [GC.SYSTEM_OBJECT_PRIMARY_LOCATION]: G.ifElse(
            G.isNilOrEmpty(primaryLocation),
            null,
            G.mapObjectEmptyStringFieldsToNull(primaryLocation),
          ),
        },
      };

      const res = await sendRequest('post', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        closeModal();
        setServiceVendorListAvailabe(R.append(data));
        setFieldValue(GC.FIELD_SERVICE_VENDOR_GUID, G.getGuidFromObject(data));
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  withHandlers({
    handleAddServiceVendor: (props: Object) => () => {
      const { openModal, closeModal, submitAction, asyncConfigs } = props;

      const serviceVendorServiceTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
        asyncConfigs,
        GC.SERVICE_VENDOR_VENDOR_SERVICE_TYPE,
      );

      const component = (
        <ServiceVendorForm
          closeModal={closeModal}
          submitAction={submitAction}
          serviceVendorServiceTypeOptions={serviceVendorServiceTypeOptions}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getWindowLocale('titles:add-service-vendor', 'Add Service Vendor'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
