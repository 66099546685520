import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  renderNothing,
} from 'react-recompose';
// components
import { Tabs2 } from '../../../components/tabs-mui';
import { PageTitle } from '../../../components/page-title';
// features
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { PageWrapper, PageHeaderWrapper } from '../../../ui';
// feature configs
import { ConfigPageWrapper } from '../ui';
import ConfigWarning from './config-warning';
import { AddOptionalConfig, RemoveOptionalConfig } from './optional-config';
//////////////////////////////////////////////////

const enhance = compose(
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const getShowOptionalConfig = () => G.isAllTrue(
  G.getIsAmousUserSuperAdminFromWindow(),
  G.isEqualCurrentPathnameAndRouteName(GC.ROUTE_PATH_CONFIG_OPTIONAL),
);

const getActiveTabName = ({ tabs, useTabs, activeTab }: Object) => {
  if (G.isTrue(useTabs)) return R.pathOr(null, [GC.FIELD_NAME], R.nth(activeTab, tabs));
};

const ConfigPageWrapperComponent = (props: Object) => {
  const {
    tabs,
    useTabs,
    padding,
    children,
    pageTitle,
    activeTab,
    configWarning,
    groupSettings,
    initialValues,
    inheritedValues,
    handleSetActiveTab,
    updateConfigsRequest,
    removeOptionalConfigRequest,
  } = props;

  const tabName = getActiveTabName({ tabs, useTabs, activeTab });

  return (
    <ConfigPageWrapper>
      <PageWrapper
        zi={10}
        p={padding}
        bgColor={G.getTheme('pages.layOutBgColor')}
      >
        <PageHeaderWrapper>
          <PageTitle title={G.getWindowLocale(pageTitle)} />
          {
            G.isTrue(useTabs) &&
            <Tabs2
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={handleSetActiveTab}
              tabStyles={GC.COMMON_MUI_TAB_STYLES}
              tabsProps={{ variant: 'scrollable' }}
              tabsStyles={{ ...R.dissoc('flexShrink', GC.COMMON_MUI_TABS_STYLES), mb: '2px', ml: '10px' }}
            />
          }
          {
            getShowOptionalConfig() &&
            <AddOptionalConfig
              initialValues={initialValues}
              inheritedValues={inheritedValues}
              submitRequestAction={updateConfigsRequest}
            />
          }
          {
            getShowOptionalConfig() &&
            <RemoveOptionalConfig
              initialValues={initialValues}
              inheritedValues={inheritedValues}
              submitRequestAction={removeOptionalConfigRequest}
            />
          }
        </PageHeaderWrapper>
        {
          G.isAllNotNilOrNotEmpty([tabName, configWarning]) &&
          <ConfigWarning
            tabName={tabName}
            configGroup={groupSettings}
            configWarning={configWarning}
          />
        }
        {children}
      </PageWrapper>
    </ConfigPageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps)(enhance(ConfigPageWrapperComponent));
