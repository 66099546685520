import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// features drivers-card
import {
  createPendingActivitySuccess,
  updatePendingActivitySuccess,
  removePendingActivitySuccess,
} from '../actions';
import PendingActivityForm from '../forms/pending-activity-form';
//////////////////////////////////////////////////

const withConnectDriverCardsPendingActivityActions = connect(null, {
  createPendingActivitySuccess,
  updatePendingActivitySuccess,
  removePendingActivitySuccess,
});

const withPendingActivity = compose(
  withConnectModalAndLoaderActions,
  withConnectDriverCardsPendingActivityActions,
  withHandlers({
    handleAddPendingActivity: (props: Object) => (driverGuid: string) => {
      const {
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        driverGroupGuid,
        createPendingActivitySuccess,
      } = props;

      const submitHandler = async (values: Object) => {
        const { endDate, startDate } = values;

        G.callFunction(openLoader);

        const options = {
          data: {
            ...values,
            driverGuid,
            endDate: G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(true)),
            startDate: G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(true)),
          },
        };

        try {
          const res = await sendRequest('post', endpointsMap.routeFleetDriverPendingActivity, options);

          const { data, status } = res;

          if (G.isResponseSuccess(status, data)) {
            G.callFunction(closeModal);

            G.callFunctionWithArgs(createPendingActivitySuccess, R.assoc('groupGuid', driverGroupGuid, data));
          } else {
            G.handleFailResponseSimple(
              res,
              true,
              'withPendingActivity -> handleAddPendingActivity',
            );
          }

          G.callFunction(closeLoader);
        } catch (error) {
          G.callFunction(closeLoader);

          G.handleException(error, 'withPendingActivity -> handleAddPendingActivity');
        }
      };

      const modalContent = (
        <PendingActivityForm
          submitHandler={submitHandler}
        />
      );

      const modal = G.getDefaultModalOptions2(
        modalContent,
        G.getAddTitle(['titles:pending-activity', 'Pending Activity']),
      );

      openModal(modal);
    },
    handleUpdatePendingActivity: (props: Object) => (initialValues: Object) => {
      const {
        groupBy,
        groupGuid,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        updatePendingActivitySuccess,
      } = props;

      const submitHandler = async (values: Object) => {
        const { endDate, startDate } = values;

        G.callFunction(openLoader);

        const options = {
          data: {
            ...values,
            endDate: G.createLocalDateTimeFromInstanceOrISOString(endDate, G.getDateTimeFormat(true)),
            startDate: G.createLocalDateTimeFromInstanceOrISOString(startDate, G.getDateTimeFormat(true)),
          },
        };

        try {
          const res = await sendRequest('put', endpointsMap.routeFleetDriverPendingActivity, options);

          const { data, status } = res;

          if (G.isResponseSuccess(status, data)) {
            G.callFunction(closeModal);

            G.callFunctionWithArgs(updatePendingActivitySuccess, {...data, groupBy, groupGuid });
          } else {
            G.handleFailResponseSimple(
              res,
              true,
              'withPendingActivity -> handleUpdatePendingActivity',
            );
          }

          G.callFunction(closeLoader);
        } catch (error) {
          G.callFunction(closeLoader);

          G.handleException(error, 'withPendingActivity -> handleUpdatePendingActivity');
        }
      };

      const modalContent = (
        <PendingActivityForm
          submitHandler={submitHandler}
          initialValues={initialValues}
        />
      );

      const modal = G.getDefaultModalOptions2(
        modalContent,
        G.getEditTitle(['titles:pending-activity', 'Pending Activity']),
      );

      openModal(modal);
    },
    handleRemovePendingActivity: (props: Object) => (period: Object) => {
      const {
        groupBy,
        groupGuid,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        removePendingActivitySuccess,
      } = props;

      const submitHandler = async (values: Object) => {
        G.callFunction(openLoader);

        const guid = G.getGuidFromObject(values);

        try {
          const res = await sendRequest('delete', endpointsMap.getRemoveRouteFleetDriverPendingActivity(guid));

          const { data, status } = res;

          if (G.isResponseSuccess(status, data)) {
            G.callFunction(closeModal);

            G.callFunctionWithArgs(removePendingActivitySuccess, { ...period, groupBy, groupGuid });
          } else {
            G.handleFailResponseSimple(
              res,
              true,
              'withPendingActivity -> handleRemovePendingActivity',
            );
          }

          G.callFunction(closeLoader);
        } catch (error) {
          G.callFunction(closeLoader);

          G.handleException(error, 'withPendingActivity -> handleRemovePendingActivity');
        }
      };

      const title = G.getWindowLocale(
        'messages:delete-confirmation-text-pending-activity',
        'Are you sure you want delete this pending activity?',
      );

      const component = <ConfirmComponent textLocale={title} />;

      const modal = {
        component,
        options: {
          width: '600px',
          height: '100px',
          controlButtons: [
            {
              type: 'button',
              action: () => submitHandler(period),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ]
        },
      };

      openModal(modal);
    },
    patchUpdatePendingActivity: (props: Object) => async (values: Object, successCallback: Function) => {
      const {
        groupBy,
        groupGuid,
        openLoader,
        closeLoader,
        updatePendingActivitySuccess,
      } = props;

      G.callFunction(openLoader);

      try {
        const res = await sendRequest('put', endpointsMap.routeFleetDriverPendingActivity, { data: values });

        const { data, status } = res;

        if (G.isResponseSuccess(status, data)) {
          G.callFunctionWithArgs(updatePendingActivitySuccess, {...data, groupBy, groupGuid });
          G.callFunction(successCallback);
        } else {
          G.handleFailResponseSimple(
            res,
            true,
            'withPendingActivity -> patchUpdatePendingActivity',
          );
        }
        G.callFunction(closeLoader);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'withPendingActivity -> patchUpdatePendingActivity');
      }
    },
  }),
  pure,
);

export {
  withPendingActivity,
};
