import L from 'leaflet';
import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components';
import 'leaflet-draw/dist/leaflet.draw.css';
import { renderToString } from 'react-dom/server';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Marker, Popup, Polyline, FeatureGroup } from 'react-leaflet';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature geo-fencing-location
import MapWrapper from './map-wrapper';
//////////////////////////////////////////////////

const pathColor = G.getTheme('colors.light.blue');
const trailerMarkerColor = G.getTheme('colors.light.red');

const getMapCenterAndBounds = (locations: Object = {}) => {
  if (G.isNilOrEmpty(locations)) return [38.755157, -98.269035];

  const polyline = L.polyline(locations);

  return polyline.getBounds().getCenter();
};

const svgIcon = L.divIcon({
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  html: renderToString(I.stopMarker(trailerMarkerColor, 20, 20)),
});

const Route = ({ list, locations }: Object) => (
  <React.Fragment>
    <Polyline positions={locations} color={pathColor} smoothFactor={3} weight={2} />
    <MarkerClusterGroup>
      { G.mapIndexed((location: Object, index: number) => {
        const { latitude, longitude } = location;

        const position = [latitude, longitude];
        const logDate = R.prop(GC.FIELD_LOG_DATE, location);
        const date = G.convertDateTimeToConfigFormat(logDate);
        const name = R.prop('geofencingLocation.name', location);

        return (
          <Marker key={index} position={position} icon={svgIcon}>
            <Popup>
              <Box textAlign='center'>{name} <br /> ({date})</Box>
            </Popup>
          </Marker>
        );
      }, list) }
    </MarkerClusterGroup>
  </React.Fragment>
);

const RouteMap = (props: Object) => {
  const { routeHistoryList } = props;

  const locations = G.isNotNilAndNotEmpty(routeHistoryList) ?
    R.map(({ latitude, longitude }: Object) => [latitude, longitude], routeHistoryList) : null;

  const center = getMapCenterAndBounds(locations);

  return (
    <MapWrapper
      height='610px'
      center={center}
      bounds={locations}
      mapCss={css`
        .leaflet-div-icon {
          background: transparent;
          border: none;
        }
      }`}
    >
      <FeatureGroup>
        { G.isNotNilAndNotEmpty(locations) && <Route locations={locations} list={routeHistoryList} /> }
      </FeatureGroup>
    </MapWrapper>
  );
};

export default RouteMap;
