import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Form, Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultNoteField = {
  [GC.FIELD_TEXT]: '',
};

const getNoteField = (isRequired: boolean = true) => [
  {
    isRequired,
    type: 'textarea',
    fieldName: GC.FIELD_TEXT,
    label: ['titles:note', 'Note'],
    inputWrapperStyles: {
      width: 270,
    },
  },
];

const getValidationSchemaObject = (isRequired: boolean) => ({
  [GC.FIELD_TEXT]: G.ifElse(
    isRequired,
    G.yupStringRequired,
    G.yupStringNotRequired,
  ).max(1999, G.getShouldBeFromToCharLocaleTxt(1, 1999)),
});

export const enhanceForm = compose(
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: ({ isRequired }: Object) => Yup.object().shape(getValidationSchemaObject(isRequired)),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultNoteField,
      initialValues,
    ),
  }),
  pure,
);

export const NoteForm = enhanceForm((props: Object) => {
  const { isRequired, handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ my: 15 }}
        fields={getNoteField(isRequired)}
      />
      <FormFooter2 />
    </Form>
  );
});
