import React from 'react';
// components
import ErrorsPopover from '../../../components/errors-popover';
// ui
import { Flex } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ErrorsPopoverComponent = () => {
  const description = '<ErrorsPopover /> component is a tooltip-like component that displays a list of error messages when hovered over.';

  const usage = (
    <ul>
      <li>The parameter `<i>errors</i>` is a list of error messages.</li>
      <li>The parameter `<i>wrapperPadding</i>` is a padding for the wrapper Flex component, default is 10.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ErrorsPopover'
      description={description}
      path='src/components/errors-popover'
    >
      <Flex>
        <ErrorsPopover errors={['some error...']} wrapperPadding='3px' />
      </Flex>
    </ComponentStoryWrapper>
  );
};

export default ErrorsPopoverComponent;
