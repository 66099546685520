import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: '390px',
  errorTop: '110%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

const createDriverDefaultFields = {
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_PASSWORD]: null,
  [GC.FIELD_LAST_NAME]: null,
  [GC.FIELD_FIRST_NAME]: null,
  [GC.FIELD_FLEET_LOGIN_ID]: null,
  [GC.FIELD_ROLE_ROLES_GUIDS]: null,
};

const createDriverValidationSchemaObject = Yup.object().shape({
  [GC.FIELD_ROLE_ROLES_GUIDS]: G.yupArrayRequired,
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getShouldBeEmailLocaleTxt())
    .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  [GC.FIELD_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(128, G.getShouldBeLessOrEqualLocaleTxt(128)),
  [GC.FIELD_LAST_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  [GC.FIELD_FIRST_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  [GC.FIELD_FLEET_LOGIN_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(80, G.getShouldBeLessOrEqualLocaleTxt(80)),
});

const createDriverFieldSettings = [
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:first-name',
    fieldName: GC.FIELD_FIRST_NAME,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:last-name',
    fieldName: GC.FIELD_LAST_NAME,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:driver-id',
    fieldName: GC.FIELD_FLEET_LOGIN_ID,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PASSWORD,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:email',
    fieldName: GC.FIELD_EMAIL,
  },
  {
    ...defaultFieldStyles,
    type: 'multiselect',
    options: GC.FIELD_ROLE_ROLES_GUIDS,
    fieldName: GC.FIELD_ROLE_ROLES_GUIDS,
    loc: 'titles:select-user-permissions',
  },
];

const createTrailerValidationSchema = Yup.object().shape({
  [GC.FIELD_UNIT_ID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRAILER_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const createTrailerDefaultFields = {
  [GC.FIELD_UNIT_ID]: null,
  [GC.FIELD_TRAILER_TYPE]: null,
};

const createTrailerFieldSettings = [
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:unit-id',
    fieldName: GC.FIELD_UNIT_ID,
  },
  {
    ...defaultFieldStyles,
    type: 'select',
    loc: 'titles:type',
    options: GC.FIELD_TRAILER_TYPE,
    fieldName: GC.FIELD_TRAILER_TYPE,
  },
];

const createTruckValidationSchema = Yup.object().shape({
  [GC.FIELD_UNIT_ID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRUCK_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const createTruckDefaultFields = {
  [GC.FIELD_UNIT_ID]: null,
  [GC.FIELD_TRUCK_TYPE]: null,
};

export const createTruckFieldSettings = [
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:unit-id',
    fieldName: GC.FIELD_UNIT_ID,
  },
  {
    ...defaultFieldStyles,
    type: 'select',
    loc: 'titles:type',
    options: GC.FIELD_TRUCK_TYPE,
    fieldName: GC.FIELD_TRUCK_TYPE,
  },
];

const createVendorValidationSchema = Yup.lazy(({ mcNumber, accountNumber }: Object) => {
  let schema = {
    [GC.FIELD_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(250, G.getShouldBeLessOrEqualLocaleTxt(250)),
    [GC.FIELD_OWNER_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(250, G.getShouldBeLessOrEqualLocaleTxt(250)),
    [GC.FIELD_MC_NUMBER]: Yup.string()
      .nullable(true)
      .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
    [GC.FIELD_ACCOUNT_NUMBER]: Yup.string()
      .nullable(true)
      .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  };
  if (G.isAllNilOrEmpty([mcNumber, accountNumber])) {
    const mcNumber = Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(12, G.getShouldBeLessOrEqualLocaleTxt(12));
    const accountNumber = Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(40, G.getShouldBeLessOrEqualLocaleTxt(40));
    schema = R.mergeRight(schema, { mcNumber, accountNumber });
  }

  return Yup.object().shape(schema);
});

const createVendorDefaultFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_MC_NUMBER]: null,
  [GC.FIELD_OWNER_NAME]: null,
  [GC.FIELD_ACCOUNT_NUMBER]: null,
};

const createVendorFieldSettings = [
  {
    ...defaultFieldStyles,
    type: 'text',
    fieldName: GC.FIELD_NAME,
    loc: 'titles:company-name',
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:owner-name',
    fieldName: GC.FIELD_OWNER_NAME,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:mc-number',
    fieldName: GC.FIELD_CARRIER_MC_NUMBER,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:account-number',
    fieldName: GC.FIELD_ACCOUNT_NUMBER,
  },
];

export const createFormSettingsMap = {
  truck: {
    fieldSettings: createTruckFieldSettings,
    defaultFields: createTruckDefaultFields,
    validationSchema: createTruckValidationSchema,
  },
  trailer: {
    fieldSettings: createTrailerFieldSettings,
    defaultFields: createTrailerDefaultFields,
    validationSchema: createTrailerValidationSchema,
  },
  driver: {
    fieldSettings: createDriverFieldSettings,
    defaultFields: createDriverDefaultFields,
    validationSchema: createDriverValidationSchemaObject,
  },
  vendor: {
    fieldSettings: createVendorFieldSettings,
    defaultFields: createVendorDefaultFields,
    validationSchema: createVendorValidationSchema,
  },
};
