import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal } from '../../../../components/modal/actions';
// features
import AsyncPayroll from '../../../payroll/components/async-payroll';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box } from '../../../../ui';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
//////////////////////////////////////////////////

const enhance = compose(
  connect(null, { openModal }),
  withHandlers({
    handleOpenPayrollDetails: (props: Object) => () => {
      const { type, openModal, driverPayrollGuid, fleetVendorPayrollGuid } = props;

      const payrollGuid = G.ifElse(
        G.isInvoiceTypeFleetVendorInvoice(type),
        fleetVendorPayrollGuid,
        driverPayrollGuid,
      );
      const asyncEndpoint = G.ifElse(
        G.isInvoiceTypeFleetVendorInvoice(type),
        endpointsMap.getVendorPayrollDetailsEndpoint,
        endpointsMap.getDriverPayrollDetailsEndpoint,
      )(payrollGuid);

      const component = <AsyncPayroll type={type} payrollGuid={payrollGuid} asyncEndpoint={asyncEndpoint} />;
      const modal = {
        p: '0 8px',
        component,
        options: {
          minWidth: 800,
          height: 'auto',
          maxWidth: '95vw',
          overflow: 'auto',
          maxHeight: '80vh',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:payroll', 'Payroll'),
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const PayrollTableCell = ({ payrollNumber, driverPayrollNumber, handleOpenPayrollDetails }: Object) => {
  const blueColor = G.getTheme('colors.light.blue');

  if (G.isAllNilOrEmpty([payrollNumber, driverPayrollNumber])) {
    return <Box color={blueColor}>{G.getWindowLocale('titles:no-number', 'No number')}</Box>;
  }

  return (
    <Box
      cursor='pointer'
      color={blueColor}
      onClick={handleOpenPayrollDetails}
      title={G.getWindowLocale('titles:click-to-see-details', 'Click to see details')}
    >
      {R.or(payrollNumber, driverPayrollNumber)}
    </Box>
  );
};

export default enhance(PayrollTableCell);
