import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { ActionBox } from '../../../components/action-box';
import { ConfirmComponent } from '../../../components/confirm';
// hocs
import { withAsyncGetBranchListByType } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, IconWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import SimpleForm from '../forms';
import { changeAssignedToDivisionRequest } from '../actions';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const lightBlueColor = G.getTheme('colors.light.blue');

const wrapperStyles = {
  p: '5px',
  height: 30,
  border: '1px solid',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  color: lightBlueColor,
  textTransform: 'uppercase',
  borderColor: darkBlueColor,
  bg: G.getTheme('colors.white'),
};

const WorkingDivision = (props: Object) => {
  const {
    assignedToDivision,
    assignedToDivisionGuid,
    handleAssignWorkingDivision,
    handleUnassignWorkingDivision,
  } = props;

  if (G.isNilOrEmpty(assignedToDivisionGuid)) {
    return (
      <Flex
        {...wrapperStyles}
        cursor='pointer'
        fontWeight='bold'
        onClick={() => handleAssignWorkingDivision()}
      >
        {G.getWindowLocale('titles:change', 'Change')} {
        G.getWindowLocale('titles:working-division', 'Working Division')}
      </Flex>
    );
  }

  return (
    <Flex {...wrapperStyles}>
      <ActionBox
        boxStyles={{ maxWidth: 250, fontWeight: 'bold' }}
        action={() => G.goToRoute(routesMap.getEditBranchRoute(assignedToDivisionGuid))}
        text={`${G.getWindowLocale('titles:working-division', 'Working Division')} ${
          R.pathOr(G.getBranchNameFromWindowByGuid(assignedToDivisionGuid), [GC.BRANCH_NAME], assignedToDivision)}`
        }
      />
      <IconWrapper ml={10} cursor='pointer' onClick={() => handleAssignWorkingDivision()}>
        {I.pencil(darkBlueColor)}
      </IconWrapper>
      <IconWrapper ml={10} cursor='pointer' onClick={() => handleUnassignWorkingDivision()}>
        {I.trash(darkBlueColor)}
      </IconWrapper>
    </Flex>
  );
};

const assignedToDivisionField = {
  type: 'select',
  isRequired: true,
  options: 'branchListByTypeOptions',
  label: ['titles:division', 'Division'],
  inputWrapperStyles: { mb: 25, width: 270 },
  fieldName: GC.FIELD_ASSIGNED_TO_DIVISION_GUID,
};

const changeAssignedDivisionField = {
  type: 'toggle',
  inputWrapperStyles: { mb: 25, width: 270 },
  fieldName: GC.FIELD_CHANGE_ASSIGNED_UNIT_DIVISION,
  label: ['titles:change-assigned-unit-division', 'Change Assigned Unit Division'],
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_ASSIGNED_TO_DIVISION_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const formEnhance = compose(
  withAsyncGetBranchListByType(),
  withProps(({ branchListByTypeOptions }: Object) => ({
    optionsForSelect: { branchListByTypeOptions },
  })),
  pure,
);

const WorkingDivisionForm = formEnhance(SimpleForm);

const enhance = compose(
  connect(null, { changeAssignedToDivisionRequest }),
  withHandlers({
    handleAssignWorkingDivision: (props: Object) => () => {
      const {
        type,
        truck,
        trailers,
        openModal,
        openLoader,
        closeLoader,
        isPrimaryDriver,
        primaryObjectGuid,
        driverAssignmentGuid,
        assignedToDivisionGuid,
        changeAssignedToDivisionRequest,
      } = props;

      const changeAssignmentDivision = R.and(
        isPrimaryDriver,
        G.isOneNotNilOrNotEmpty([truck, trailers]),
      );

      const fieldSettings = G.ifElse(
        changeAssignmentDivision,
        [assignedToDivisionField, changeAssignedDivisionField],
        R.of(Array, assignedToDivisionField),
      );

      const initialValues = {
        assignedToDivisionGuid,
        [GC.FIELD_GUID]: primaryObjectGuid,
        [GC.FIELD_CHANGE_ASSIGNED_UNIT_DIVISION]: G.ifElse(changeAssignmentDivision, false),
      };

      const submitAction = (values: Object) => changeAssignedToDivisionRequest({
        type,
        values,
        driverAssignmentGuid,
      });

      const component = (
        <WorkingDivisionForm
          openLoader={openLoader}
          closeLoader={closeLoader}
          submitAction={submitAction}
          initialValues={initialValues}
          fieldSettings={fieldSettings}
          validationSchema={validationSchema}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocaleArr(['titles:assign', 'titles:working-division']),
        },
      };

      openModal(modal);
    },
    handleUnassignWorkingDivision: (props: Object) => () => {
      const {
        type,
        openModal,
        primaryObjectGuid,
        driverAssignmentGuid,
        assignedToDivisionGuid,
        changeAssignedToDivisionRequest,
      } = props;

      const name = G.getBranchNameFromWindowByGuid(assignedToDivisionGuid);

      const textLocale = G.getWindowLocale(
        'messages:driver-unassign:confirmation:text',
        'Are you sure, you want unassign',
      );

      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const action = () => changeAssignedToDivisionRequest({
        type,
        driverAssignmentGuid,
        values: {
          [GC.FIELD_GUID]: primaryObjectGuid,
          [GC.FIELD_ASSIGNED_TO_DIVISION_GUID]: null,
        },
      });

      const modal = {
        component,
        options: {
          width: 600,
          title: G.getWindowLocaleArr(['titles:unassign', 'titles:working-division']),
          controlButtons: [
            {
              action,
              type: 'button',
              name: G.getWindowLocale('actions:save', 'Save'),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default enhance(WorkingDivision);
