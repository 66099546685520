import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const defaultTelClosReport = {
  fields: [
    {
      name: 'status',
      freezed: false,
    },
    {
      freezed: false,
      name: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    },
    {
      freezed: false,
      name: GC.GRC.PRIMARY_REFERENCE_VALUE,
    },
  ],
};

export const defaultCloTelsReport = {
  fields: [
    {
      name: 'status',
      freezed: false,
    },
    {
      freezed: false,
      name: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    },
    {
      freezed: false,
      name: GC.GRC.PRIMARY_REFERENCE_VALUE,
    },
  ],
};
