import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const getPageTabs = (activeTab: number) => [
  {
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:rating', 'Rating'),
  },
  {
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:report', 'Report'),
  },
];
