import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import {
  defaultAccountingFields,
  getAccountingFieldSettings,
  getAccountingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getAccountingValidationSchema(R.prop(GC.FIELD_TYPE, values)))
    )),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultAccountingFields,
      initialValues,
    ),
  }),
  pure,
);

const AccountingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      handlers={{ disableIntegrationType: () => props.isEditMode }}
      fields={getAccountingFieldSettings(R.path(['values', GC.FIELD_TYPE], props))}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(AccountingForm);
