import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withSetExpandedContainerOptions } from '../../../../../hocs';
// ui
import { Box, RelativeBox } from '../../../../../ui';
// feature dispatch details new
import { InfoPair, ContactInfoPair, InfoPairWithList } from '../../info-pair';
import AcceptDeclineRate, { AcceptDeclineRateWithoutConnect } from './accept-decline-rate';
//////////////////////////////////////////////////

const borderColor = G.getTheme('colors.bgGrey');

const PrimaryDriverSection = withSetExpandedContainerOptions((props: Object) => {
  const {
    loadGuid,
    assignment,
    autodialApp,
    expandedContainerOptions,
    handleSetExpandedContainerOptions,
  } = props;

  const { primaryDriver } = assignment;

  const { fullText } = G.getUserInfo(primaryDriver);

  const { email, phoneNumber, secondaryPhoneNumber } = primaryDriver;

  const clickHandler = () => G.ifElse(
    G.isTrue(G.getPropFromObject('opened', expandedContainerOptions)),
    null,
    handleSetExpandedContainerOptions({
      parentPageGuid: loadGuid,
      componentType: GC.PAGE_FLEET_DRIVER_PROFILE_V2,
      visitPageGuid: G.getGuidFromObject(primaryDriver),
    }),
  );

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        text={fullText}
        clickHandler={clickHandler}
        label={G.getWindowLocale('titles:main-driver', 'Main Driver')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        text={phoneNumber}
        autodialApp={autodialApp}
        label={G.getWindowLocale('titles:phone-number', 'Phone Number')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        autodialApp={autodialApp}
        text={secondaryPhoneNumber}
        label={G.getWindowLocale('titles:secondary-phone', 'Secondary Phone')}
      />
      <ContactInfoPair
        text={email}
        autodialApp={autodialApp}
        label={G.getWindowLocale('titles:email', 'Email')}
      />
    </Box>
  );
});

const TeamDriverSection = withSetExpandedContainerOptions((props: Object) => {
  const {
    loadGuid,
    assignment,
    autodialApp,
    expandedContainerOptions,
    handleSetExpandedContainerOptions,
  } = props;

  if (G.isNilOrEmpty(R.prop(GC.SYSTEM_OBJECT_SECONDARY_DRIVER, assignment))) return null;

  const { secondaryDriver } = assignment;

  const { fullText } = G.getUserInfo(secondaryDriver);

  const { email, phoneNumber, secondaryPhoneNumber } = secondaryDriver;

  const clickHandler = () => G.ifElse(
    G.isTrue(G.getPropFromObject('opened', expandedContainerOptions)),
    null,
    handleSetExpandedContainerOptions({
      parentPageGuid: loadGuid,
      componentType: GC.PAGE_FLEET_DRIVER_PROFILE_V2,
      visitPageGuid: G.getGuidFromObject(secondaryDriver),
    }),
  );

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        text={fullText}
        clickHandler={clickHandler}
        label={G.getWindowLocale('titles:team-driver', 'Team Driver')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        text={phoneNumber}
        autodialApp={autodialApp}
        label={G.getWindowLocale('titles:phone-number', 'Phone Number')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        autodialApp={autodialApp}
        text={secondaryPhoneNumber}
        label={G.getWindowLocale('titles:secondary-phone', 'Secondary Phone')}
      />
      <ContactInfoPair
        text={email}
        autodialApp={autodialApp}
        label={G.getWindowLocale('titles:email', 'Email')}
      />
    </Box>
  );
});

const TruckTrailersSection = withSetExpandedContainerOptions((props: Object) => {
  const {
    loadGuid,
    assignment,
    expandedContainerOptions,
    handleSetExpandedContainerOptions,
  } = props;

  if (G.isNilOrEmpty(R.prop(GC.FIELD_TRUCK, assignment))) return null;

  const { trailers, truck, truck: { unitId, fleetVendor } } = assignment;

  const clickHandler = ({ item, componentType }: Object) => G.ifElse(
    G.isTrue(G.getPropFromObject('opened', expandedContainerOptions)),
    null,
    handleSetExpandedContainerOptions({
      componentType,
      parentPageGuid: loadGuid,
      visitPageGuid: G.getGuidFromObject(item),
    }),
  );

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        text={unitId}
        label={G.getWindowLocale('titles:truck', 'Truck')}
        clickHandler={() => clickHandler({
          item: truck,
          componentType: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
        })}
      />
      <InfoPair
        label={G.getWindowLocale('titles:vendor', 'Vendor')}
        text={G.getPropFromObject(GC.FIELD_NAME, fleetVendor)}
        clickHandler={() => clickHandler({
          item: fleetVendor,
          componentType: GC.PAGE_FLEET_VENDOR_PROFILE_V2,
        })}
      />
      <InfoPairWithList
        flexWrap='wrap'
        clickHandler={clickHandler}
        componentType={GC.PAGE_FLEET_TRAILER_PROFILE_V2}
        label={G.getWindowLocale('titles:trailers', 'Trailers')}
        list={R.map((trailer: Object) => R.assoc(GC.FIELD_DISPLAY_VALUE, trailer.unitId, trailer), trailers)}
      />
    </Box>
  );
});

const DispatchedBySection = ({ dispatchedBy, dispatchedDate }: Object) => (
  <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
    <InfoPair
      text={dispatchedBy}
      label={G.getWindowLocale('titles:dispatched-by', 'Dispatched By')}
    />
    <InfoPair
      text={G.convertDateTimeToConfigFormat(dispatchedDate)}
      label={G.getWindowLocale('titles:dispatched-date', 'Dispatched Date')}
    />
  </Box>
);

const ChassisSection = ({ chassisNumber, drayContainerDropNumber, drayContainerPickupNumber }: Object) => {
  const hideChassisSection = R.compose(
    G.isZero,
    R.length,
    R.values,
    R.filter(G.isNotNilAndNotEmpty),
  )([chassisNumber, drayContainerPickupNumber, drayContainerDropNumber]);

  if (hideChassisSection) return null;

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        flexWrap='wrap'
        text={chassisNumber}
        label={G.getWindowLocale('titles:chassis-number', 'Chassis Number')}
      />
      <InfoPair
        flexWrap='wrap'
        text={drayContainerPickupNumber}
        label={G.getWindowLocale('titles:dray-container-pick-up-number', 'Dray Container Pick Up Number')}
      />
      <InfoPair
        flexWrap='wrap'
        text={drayContainerDropNumber}
        label={G.getWindowLocale('titles:dray-container-drop-number', 'Dray Container Drop Number')}
      />
    </Box>
  );
};

const AcceptDeclineComponent = ({ guid, fromDispatchBoard, declineReasonOptions, acceptDeclineHandlers }: Object) => {
  if (fromDispatchBoard) {
    return (
      <AcceptDeclineRateWithoutConnect
        {...acceptDeclineHandlers}
        guid={guid}
        isCarrierRate={false}
        declineReasonOptions={declineReasonOptions}
      />
    );
  }

  return (
    <AcceptDeclineRate
      guid={guid}
      isCarrierRate={false}
      declineReasonOptions={declineReasonOptions}
    />
  );
};

const Fleet = (props: Object) => {
  const {
    guid,
    loadGuid,
    autodialApp,
    fleetAssignment,
    fromDispatchBoard,
    declineReasonOptions,
    acceptDeclineHandlers,
    showAcceptDeclineSection,
  } = props;

  const dispatchedBy = R.path([GC.FIELD_DISPATCHED_OR_CANCEL_BY], props);

  return (
    <RelativeBox
      height={400}
      overflowY={G.ifElse(showAcceptDeclineSection, 'hidden', 'auto')}
    >
      {
        showAcceptDeclineSection &&
        <AcceptDeclineComponent
          guid={guid}
          fromDispatchBoard={fromDispatchBoard}
          declineReasonOptions={declineReasonOptions}
          acceptDeclineHandlers={acceptDeclineHandlers}
        />
      }
      <PrimaryDriverSection
        loadGuid={loadGuid}
        autodialApp={autodialApp}
        assignment={fleetAssignment}
      />
      <TeamDriverSection
        loadGuid={loadGuid}
        autodialApp={autodialApp}
        assignment={fleetAssignment}
      />
      <TruckTrailersSection loadGuid={loadGuid} assignment={fleetAssignment} />
      {
        G.isNotNilAndNotEmpty(dispatchedBy) &&
        <DispatchedBySection
          dispatchedBy={dispatchedBy}
          dispatchedDate={R.path([GC.FIELD_DISPATCH_OR_CANCEL_DATE], props)}
        />
      }
      {
        G.getShowChassisSectionConfigFromWindow() &&
        <ChassisSection
          chassisNumber={R.prop(GC.FIELD_CHASSIS_NUMBER, fleetAssignment)}
          drayContainerDropNumber={R.prop(GC.FIELD_DRAY_CONTAINER_DROP_NUMBER, fleetAssignment)}
          drayContainerPickupNumber={R.prop(GC.FIELD_DRAY_CONTAINER_PICK_UP_NUMBER, fleetAssignment)}
        />
      }
    </RelativeBox>
  );
};

export default Fleet;
