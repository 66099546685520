// features dashboards
import { CHART_COLORS } from '../constants';
//////////////////////////////////////////////////

const largeChartWrapperCommonProps = {
  width: 1000,
  height: 400,
  margin: {
    top: 20,
    right: 0,
    bottom: 20,
    left: 0,
  },
};

const cartesianGridCommonProps = {
  vertical: false,
  strokeWidth: 0.5,
  stroke: CHART_COLORS.grey3,
};

const xAxisCommonProps = {
  axisLine: false,
  stroke: CHART_COLORS.grey2,
  // tick
  tickMargin: 15,
  minTickGap: 50,
  tickLine: false,
  interval: 'preserveStart',
  tick: { fontSize: '12px'},
};

const yAxisCommonProps = {
  axisLine: false,
  stroke: CHART_COLORS.grey2,
  // tick
  tickSize: 1,
  tickMargin: 5,
  tickLine: false,
  tick: { fontSize: '12px'},
};

const legendCommonProps = {
  iconSize: 6,
  align: 'right',
  iconType: 'circle',
  verticalAlign: 'top',
  wrapperStyle: { top: 0 },
};

export {
  xAxisCommonProps,
  yAxisCommonProps,
  legendCommonProps,
  cartesianGridCommonProps,
  largeChartWrapperCommonProps,
};
