import React from 'react';
import { pure } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// ui
import {
  ActionButton,
  CancelButton,
  FooterWrapper } from '../../../ui';
///////////////////////////////////////////////////////////////////////////////////////////////////

export const FooterBtns = pure((props: Object) => (
  <FooterWrapper p='10px 15px'>
    <ActionButton
      mr='15px'
      width='25%'
      p='8px 16px'
      type='submit'
      fontSize={18}
      onClick={props.searchTemplateRequest}
      disabled={G.ifElse(G.isTrue(props.isSubmitting), true, false)}
    >
      {G.getWindowLocale('actions:save', 'Save')}
    </ActionButton>
    <CancelButton
      width='25%'
      p='8px 16px'
      type='button'
      fontSize={18}
      onClick={props.closeModal}
    >
      {G.getWindowLocale('actions:cancel', 'Cansel')}
    </CancelButton>
  </FooterWrapper>
));
