import React from 'react';
import * as R from 'ramda';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const OrderTypeForm = ({ orderTypeOptions, defaultOrderType, handleActionUpdateLoad }: Object) => (
  <SelectDropdownForm
    optionRequired={false}
    options={orderTypeOptions}
    fieldsWrapperStyles={{ pt: '0px' }}
    submitAction={(orderType: string) => handleActionUpdateLoad({ orderType })}
    initialValues={{ option: R.prop(GC.FIELD_DROPDOWN_OPTION_GUID, defaultOrderType) }}
  />
);

export default OrderTypeForm;
