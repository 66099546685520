import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { useState, useEffect, useCallback } from 'react';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS } from '../../../../helpers/options';
// ui
import { Box, Flex, scrollableContainerCss4px } from '../../../../ui';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature carrier-profile
import { setAddressPointValues } from '../../helpers';
import SectionHeader from '../../components/section-header';
import { AccessorialFormGroupTable } from '../../settings/accessorial';
import {
  defaultValues,
  validationSchema,
  zoneFieldSettings,
  generalFieldSettings,
  compensationRangeSections,
  compensationDetailsFieldSettings,
} from './settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const defaultFieldsWrapperStyles = { px: '0px', pt: 15, width: 980, justifyContent: 'space-between' };

const ZoneSection = (props: Object) => (
  <Box
    width={480}
    p='15px 10px'
    borderRadius='8px'
    border='1px solid'
    borderColor='dark.blue'
  >
    <Box ml={10} mb={25} fontWeight='bold' color='light.blue'>
      {props.title}
    </Box>
    <Fieldset2
      {...props}
      fields={zoneFieldSettings(props.zoneType)}
      setValues={props.handleSetAddressPointValues}
      fieldsWrapperStyles={{ justifyContent: 'space-between' }}
    />
  </Box>
);

const getActionButtons = ({ isSubmitting, initialValues, setSaveAndAddNew }: Object) => {
  if (G.isNotNil(G.getGuidFromObject(initialValues))) return [];

  return [
    {
      type: 'submit',
      disabled: isSubmitting,
      action: () => setSaveAndAddNew(true),
      displayText: G.getWindowLocale('titles:save-and-add-new', 'Save and Add New'),
      buttonStyles: {
        mr: 20,
        ml: 'auto',
        bgColor: 'none',
        background: 'none',
        border: '1px solid',
        borderRadius: '5px',
        width: 'max-content',
        textColor: darkBlueColor,
        textTransform: 'uppercase',
        borderColor: darkBlueColor,
      },
    },
  ];
};

const useForm = (props: Object) => {
  const {
    openLoader,
    closeLoader,
    submitAction,
    initialValues,
    saveAndAddNew,
  } = props;

  const [entity, setEntity] = useState(initialValues);

  useEffect(() => {
    const getCarrierRatePriceRequest = async () => {
      const guid = G.getGuidFromObject(initialValues);

      if (R.isNil(guid)) return;

      openLoader();

      const endpoint = endpointsMap.getCarrierRatePriceEndpoint(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const entity = {
          ...data,
          [GC.FIELD_EQUIPMENTS]: R.map(
            R.prop(GC.FIELD_DROPDOWN_OPTION_GUID),
            R.propOr([], GC.FIELD_EQUIPMENTS, data),
          ),
          [GC.FIELD_SERVICE_TYPES]: R.map(
            R.prop(GC.FIELD_DROPDOWN_OPTION_GUID),
            R.propOr([], GC.FIELD_SERVICE_TYPES, data),
          ),
        };

        setEntity(entity);
      } else {
        G.handleFailResponseSimple(res, true, 'getAwardedLaneRequest fail');
      }

      closeLoader();
    };

    getCarrierRatePriceRequest();
  }, []);

  const onSubmit = useCallback((values: Object, { resetForm, setSubmitting }: Object) => {
    const { originZoneGuid, destinationZoneGuid } = values;

    const makeLocationValues = R.compose(
      G.mapObjectEmptyStringFieldsToNull,
      R.pick([
        GC.FIELD_GEO_FENCING_ZONE_STATES,
        GC.FIELD_GEO_FENCING_ZONE_CITIES,
        GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES,
        GC.FIELD_GEO_FENCING_ZONE_COUNTRIES,
        GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO,
        GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM,
      ]),
    );

    const origin = G.isNilOrEmpty(originZoneGuid) ? makeLocationValues(values.origin) : null;
    const destination = G.isNilOrEmpty(destinationZoneGuid) ? makeLocationValues(values.destination) : null;

    const data = {
      ...G.mapObjectEmptyStringFieldsToNull(R.omit(['origins', 'destinations'], values)),
      origin,
      destination,
    };

    const failCallback = () => setSubmitting(false);

    const successCallback = () => {
      setSubmitting(false);

      if (saveAndAddNew) resetForm();
    };

    const additionalOptions = {
      failCallback,
      successCallback,
      shouldCloseModal: G.isFalse(saveAndAddNew),
    };

    submitAction(data, additionalOptions);
  }, [saveAndAddNew]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: G.setInitialFormikValues(
      defaultValues,
      entity,
    ),
  });

  return { formik, initialValues: entity };
};

const ContractRateForm = (props: Object) => {
  const { optionsForSelect } = props;

  const [saveAndAddNew, setSaveAndAddNew] = useState(false);

  const form = useForm({ ...props, saveAndAddNew });

  const { formik, initialValues } = form;

  const { values, setValues, handleSubmit, isSubmitting } = formik;

  const handleSetAddressPointValues = useCallback(
    (entity: Object) => setAddressPointValues({ entity, values, setValues }),
    [values],
  );

  const rateGuid = R.path(['initialValues', GC.FIELD_GUID], props);

  return (
    <form onSubmit={handleSubmit}>
      <Box
        px='6px'
        overflowY='auto'
        overflowX='hidden'
        maxHeight='calc(100vh - 180px)'
        css={scrollableContainerCss4px}
      >
        <Fieldset2
          {...formik}
          {...optionsForSelect}
          fields={generalFieldSettings}
          fieldsWrapperStyles={defaultFieldsWrapperStyles}
        />
        <Flex mb={15} alignItems='stretch' justifyContent='space-between'>
          <ZoneSection
            {...formik}
            zoneType={GC.FIELD_ORIGIN}
            title={G.getWindowLocale('titles:origin', 'Origin')}
            handleSetAddressPointValues={handleSetAddressPointValues}
          />
          <ZoneSection
            {...formik}
            zoneType={GC.FIELD_DESTINATION}
            handleSetAddressPointValues={handleSetAddressPointValues}
            title={G.getWindowLocale('titles:destination', 'Destination')}
          />
        </Flex>
        <Fieldset2
          {...formik}
          {...optionsForSelect}
          fields={compensationDetailsFieldSettings}
          fieldsWrapperStyles={defaultFieldsWrapperStyles}
          rateUnit={R.pathOr(
            R.of(Array, GC.EMPTY_OPTION_OBJECT),
            [R.prop(GC.FIELD_CHARGE_RATE_TYPE, values)],
            DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
          )}
        />
        {
          compensationRangeSections.map(({ title, fields, prefix, fieldSettings }: Object, index: number) => (
            <SectionHeader
              key={index}
              title={G.getWindowLocale(title)}
              expanded={G.isOneNotNilOrNotEmpty(
                R.values(R.pick(fields, R.propOr(initialValues, prefix, initialValues))),
              )}
            >
              <Fieldset2
                {...formik}
                fields={fieldSettings}
                fieldsWrapperStyles={defaultFieldsWrapperStyles}
              />
            </SectionHeader>
          ))
        }
        {
          R.isNotNil(rateGuid) &&
          <AccessorialFormGroupTable
            {...props}
            isReport={false}
            primaryObjectGuid={rateGuid}
            groupName={`contractRate.${rateGuid}.accessorial`}
          />
        }
      </Box>
      <FormFooter2
        submitDisabled={isSubmitting}
        boxStyles={{ mt: 10, px: '5px' }}
        submitAction={() => setSaveAndAddNew(false)}
        actionButtons={getActionButtons({ ...props, setSaveAndAddNew })}
      />
    </form>
  );
};

export default ContractRateForm;
