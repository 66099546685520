import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('chargeReport');


const makeSelectTitleSortValues = () => createSelector(
  store,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectReportType = () => createSelector(
  store,
  ({ reportType }: Object) => reportType,
);

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectReportType,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
};
