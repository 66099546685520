import React from 'react';
import * as R from 'ramda';
import { pure, compose, withProps } from 'react-recompose';
// components
import { TabsMuiEnhanced } from '../../../../components';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// forms
import { Label, Checkbox } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, RelativeBox, ActionButton } from '../../../../ui';
// feature dispatch-details-new
import * as C from '../../constants';
import { withLoadAddDocument } from './with-document-actions';
import DispatchCardComponent from '../../components/cards/dispatch';
import { LoadPaymentsCardComponent } from '../../components/cards/payments';
import { LoadDocumentsCardComponent } from '../../components/cards/documents';
import { withLoadMessageCenterHeaderActions } from './with-message-center-actions';
import { LoadMessageCenterCardComponent } from '../../components/cards/message-center';
import { withAddDriverOrCarrierRate, withLoadDriverCarrierRateTableGroupRowActions } from './with-carrier-driver-rate';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');
const greyColor = G.getTheme('colors.greyMatterhorn');

const headerActionButtonStyles = {
  ml: '4px',
  px: '6px',
  height: 20,
  fontSize: 12,
  minWidth: 'unset',
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
};

const ActionButtonComponent = ({ text, handleClick, permissions }: Object) => (
  <AuthWrapper has={permissions}>
    <ActionButton {...headerActionButtonStyles} type='button' onClick={handleClick}>
      {text}
    </ActionButton>
  </AuthWrapper>
);

const DocumentRightHeader = withLoadAddDocument((props: Object) => {
  const {
    load,
    handlePrintLoad,
    handleAddDocument,
    handlePrintLoadDocumentsInvoice,
  } = props;

  return (
    <Flex>
      <AuthWrapper has={[PC.TEL_DOCUMENT_WRITE]}>
        <RelativeBox
          pl='5px'
          cursor='pointer'
          alignSelf='flex-start'
          onClick={handlePrintLoadDocumentsInvoice}
          title={G.getWindowLocale('titles:print-create-document', 'Print/Create Document')}
        >
          {I.renderPrintDocumentsIcon(blueColor)}
        </RelativeBox>
      </AuthWrapper>
      {
        R.not(G.isCurrentUserTypeCarrier()) &&
        <AuthWrapper has={[PC.TEL_DOCUMENT_WRITE]}>
          <RelativeBox
            ml='5px'
            cursor='pointer'
            onClick={() => handlePrintLoad(G.getGuidFromObject(load))}
            title={G.getWindowLocale('titles:select-template', 'Select Template')}
          >
            {I.renderPrintIcon(blueColor)}
          </RelativeBox>
        </AuthWrapper>
      }
      <ActionButtonComponent
        handleClick={handleAddDocument}
        permissions={[PC.TEL_DOCUMENT_WRITE]}
        text={G.getWindowLocale('titles:add-document', 'Add Document')}
      />
    </Flex>
  );
});

const EditRateActionButton = ({ rateType, handleClick }: Object) => {
  if (G.isNilOrEmpty(rateType)) return null;

  const carrierDriverRateTxtLocaleMap = {
    [GC.RATE_TYPE_FLEET_RATE]: G.getWindowLocale('titles:edit-driver-rate', 'Edit Driver Rate'),
    [GC.RATE_TYPE_CARRIER_RATE]: G.getWindowLocale('titles:edit-carrier-rate', 'Edit Carrier Rate'),
  };

  const text = carrierDriverRateTxtLocaleMap[rateType];

  return (
    <ActionButtonComponent
      text={text}
      handleClick={handleClick}
      permissions={G.ifElse(
        G.isRateTypeFleetRate(rateType),
        [PC.FLEET_RATE_WRITE],
        G.ifElse(G.isCurrentUserTypeCarrier(), [PC.CARRIER_RATE_READ], [PC.CARRIER_RATE_WRITE]),
      )}
    />
  );
};

const SuspendTelCarrierRateEmailsBtn = (props: Object) => {
  const { selectedRate, suspendResumeCarrierRateEmailsRequest } = props;

  const emailsSuspended = R.prop(GC.FIELD_EMAILS_SUSPENDED, selectedRate);
  const handleChange = () => {
    const guid = G.getGuidFromObject(selectedRate);
    const resumeActionData = { guid, endpointName: 'resumeTelCarrierRateEmails' };
    const suspendActionData = { guid, endpointName: 'suspendTelCarrierRateEmails' };

    if (emailsSuspended) {
      return suspendResumeCarrierRateEmailsRequest(resumeActionData);
    }

    suspendResumeCarrierRateEmailsRequest(suspendActionData);
  };

  return (
    <AuthWrapper has={[PC.CARRIER_RATE_WRITE]}>
      <Flex
        ml='8px'
        title={G.getWindowLocale(
          'messages:suspend-emails',
          'This will make the load active to track the status without sending a dispatch email to the Carrier.',
        )}
      >
        <Checkbox
          mr={10}
          type='checkbox'
          onChange={handleChange}
          checked={emailsSuspended}
          id={GC.FIELD_EMAILS_SUSPENDED}
        />
        <Label
          fontSize={11}
          cursor='pointer'
          color={blueColor}
          htmlFor={GC.FIELD_EMAILS_SUSPENDED}
        >
          {G.getWindowLocale('titles:internal-dispatch', 'Internal Dispatch')}
        </Label>
      </Flex>
    </AuthWrapper>
  );
};

const PrintIcon = ({ load, handlePrintDispatchDocument }: Object) => (
  <AuthWrapper has={[PC.TEL_DOCUMENT_WRITE]}>
    <RelativeBox
      ml='4px'
      cursor='pointer'
      onClick={() => handlePrintDispatchDocument(G.getGuidFromObject(load))}
      title={G.getWindowLocale('titles:print-invoice', 'Print Invoice')}
    >
      {I.renderPrintIcon(blueColor)}
    </RelativeBox>
  </AuthWrapper>
);

export const DispatchHeaderActionButtons = withLoadAddDocument((props: Object) => {
  const {
    load,
    rateType,
    loadStatus,
    printHidden,
    selectedRate,
    handleApprove,
    handleDispatch,
    handleEditRate,
    handleAddDriverRate,
    handleCancelDispatch,
    handleAddCarrierRate,
    handlePrintDispatchDocument,
    suspendResumeCarrierRateEmailsRequest,
  } = props;

  if (G.isNilOrEmpty(rateType)) {
    return (
      <Flex>
        <ActionButtonComponent
          handleClick={handleAddDriverRate}
          permissions={[PC.FLEET_RATE_WRITE]}
          text={G.getWindowLocale('titles:add-driver-rate', 'Add Driver Rate')}
        />
        <ActionButtonComponent
          permissions={[PC.CARRIER_RATE_WRITE]}
          handleClick={handleAddCarrierRate}
          text={G.getWindowLocale('titles:add-carrier-rate', 'Add Carrier Rate')}
        />
      </Flex>
    );
  }

  const printShown = R.and(R.not(G.isCurrentUserTypeCarrier()), R.not(printHidden));

  const showDispatchAction = R.includes(loadStatus, [
    GC.LOAD_STATUS_RATED,
    GC.LOAD_STATUS_DISPATCHED,
    GC.LOAD_STATUS_BOOKED_STATUS,
    GC.LOAD_STATUS_DISPATCH_REJECTED,
  ]);

  if (R.not(showDispatchAction)) {
    return (
      <Flex>
        <EditRateActionButton
          rateType={rateType}
          handleClick={handleEditRate}
        />
        {
          printShown &&
          <PrintIcon load={load} handlePrintDispatchDocument={handlePrintDispatchDocument} />
        }
      </Flex>
    );
  }

  const dispatched = R.includes(loadStatus, GC.TEL_STATUSES_TO_CANCEL);

  const internalDispatchStatuses = [
    ...GC.TEL_NEGATIVE_STATUSES,
    ...GC.TEL_STATUSES_TO_CANCEL,
    ...GC.TEL_STATUSES_TO_DISPATCH,
  ];

  const showInternalDispatch = R.and(
    R.equals(rateType, GC.RATE_TYPE_CARRIER_RATE),
    R.includes(loadStatus, internalDispatchStatuses),
  );

  let dispatchText;
  let dispatchPermissions = [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE];

  const {
    maxPayViolated,
    minMarginViolated,
    criticalMarginViolated,
    marginViolationApproved,
  } = selectedRate;

  const needApprove = R.and(
    G.isAnyTrue(maxPayViolated, minMarginViolated, criticalMarginViolated),
    G.isFalse(marginViolationApproved),
  );

  if (dispatched) {
    dispatchText = G.getWindowLocale('titles:cancel', 'Cancel');
  } else {
    dispatchText = G.getWindowLocale(...G.ifElse(
      R.equals(loadStatus, GC.LOAD_STATUS_DISPATCH_REJECTED),
      ['titles:redispatch', 'Redispatch'],
      G.ifElse(needApprove, ['titles:approve', 'Approve'], ['titles:dispatch', 'Dispatch']),
    ));

    if (R.and(needApprove, R.or(maxPayViolated, criticalMarginViolated))) {
      dispatchPermissions = [PC.MARGIN_VIOLATION_APPROVAL_EXECUTE];
    }
  }

  const dispatchHandler = G.ifElse(
    dispatched,
    handleCancelDispatch,
    G.ifElse(needApprove, handleApprove, handleDispatch),
  );

  return (
    <Flex>
      {
        showInternalDispatch &&
        <SuspendTelCarrierRateEmailsBtn
          selectedRate={selectedRate}
          suspendResumeCarrierRateEmailsRequest={suspendResumeCarrierRateEmailsRequest}
        />
      }
      <ActionButtonComponent
        text={dispatchText}
        handleClick={dispatchHandler}
        permissions={dispatchPermissions}
      />
      <EditRateActionButton
        rateType={rateType}
        handleClick={handleEditRate}
      />
      {
        printShown &&
        <PrintIcon load={load} handlePrintDispatchDocument={handlePrintDispatchDocument} />
      }
    </Flex>
  );
});

const LeftHeaderComponent = ({ text }: Object) => (
  <Flex
    fontSize={12}
    fontWeight='bold'
    color={greyColor}
    textTransform='uppercase'
  >
    {text}
  </Flex>
);

const getChatTabs = () => [
  {
    value: 'chatMessageList',
    text: G.getWindowLocale('titles:chat', 'Chat'),
    permissions: [PC.TEL_CHAT_MESSAGE_READ, PC.TEL_CHAT_MESSAGE_WRITE],
  },
  {
    value: 'noteList',
    notHasPermissions: [PC.ROLE_TYPE_CARRIER],
    text: G.getWindowLocale('titles:notes', 'Notes'),
  },
];

const ChatLeftHeaderComponent = withLoadMessageCenterHeaderActions((props: Object) => {
  const { messageCenter, changeMessageCenterActiveTab } = props;

  const { activeTab } = messageCenter;

  const handleClick = (tab: string) => {
    if (R.equals(tab, activeTab)) return;

    return changeMessageCenterActiveTab(tab);
  };

  return (
    <Box border={`1px solid ${G.getTheme('colors.bgGrey')}`}>
      <TabsMuiEnhanced
        tabs={getChatTabs()}
        defaultActiveMuiTab={activeTab}
        handleClickMuiTab={handleClick}
        tabStyles={{ minHeight: 36, padding: 0 }}
        tabsStyles={{ maxHeight: 36, minHeight: 36 }}
      />
    </Box>
  );
});

export const withLoadCardsProps = compose(
  withAddDriverOrCarrierRate,
  withLoadDriverCarrierRateTableGroupRowActions,
  withProps((props: Object) => {
    const {
      load,
      cardType,
      openModal,
      closeModal,
      autodialApp,
      loadConfigs,
      invoiceTotals,
      loadDocuments,
      relatedDataList,
      handleApproveRate,
      normalizedCurrency,
      handleAddDriverRate,
      handleAddCarrierRate,
      handleCancelDispatch,
      linkedOrdersDocuments,
      handleDispatchLoadRate,
      selectedCarrierDriverRate,
      handleEditCarrierDriverRate,
      suspendResumeCarrierRateEmailsRequest,
    } = props;

    const showAcceptDeclineSection = R.and(
      R.pathEq(GC.LOAD_STATUS_DISPATCHED, [GC.FIELD_STATUS], load),
      R.pathEq(GC.RATE_STATUS_PENDING, [GC.FIELD_STATUS], selectedCarrierDriverRate),
    );

    const declineReasonOptions = R.compose(
      R.prepend({
        [GC.FIELD_VALUE]: '',
        [GC.FIELD_LABEL]: G.getWindowLocale('titles:select-decline-reason', 'Select Decline Reason'),
      }),
      R.map((item: Object) => ({
        [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
        [GC.FIELD_LABEL]: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
      })),
      R.pathOr(
        [],
        [
          'configGroups',
          GC.COMMUNICATION_CONFIG_GROUP,
          'dropdowns',
          GC.COMMUNICATION_DISPATCH_DECLINE_REASON_CODE,
          'options',
        ],
      ),
    )(loadConfigs);

    const communicationConfigs = R.pathOr({}, ['configGroups', 'communication'], loadConfigs);

    const documentsProps = {
      load,
      openModal,
      closeModal,
      relatedDataList,
      linkedOrdersDocuments,
      documents: loadDocuments,
      mailPermissions: [PC.SEND_EMAIL_EXECUTE],
      documentPermissions: [PC.TEL_DOCUMENT_WRITE],
      useBillToContactEmail: G.getConfigValueFromStore(
        GC.COMMUNICATION_EMAIL_USE_BILL_TO_CONTACT_EMAIL,
        communicationConfigs,
      ),
    };

    const popDocuments = R.filter(R.propEq(GC.DOCUMENT_PROOF_TYPE_POP, GC.FIELD_PROOF_TYPE), R.or(loadDocuments, []));
    const podDocuments = R.filter(R.propEq(GC.DOCUMENT_PROOF_TYPE_POD, GC.FIELD_PROOF_TYPE), R.or(loadDocuments, []));

    const cardTypeMap = {
      [C.CARD_TYPE_DISPATCH]: {
        rightHeaderComponent: (
          <DispatchHeaderActionButtons
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            handleApprove={handleApproveRate}
            selectedRate={selectedCarrierDriverRate}
            handleAddDriverRate={handleAddDriverRate}
            loadStatus={R.prop(GC.FIELD_STATUS, load)}
            handleAddCarrierRate={handleAddCarrierRate}
            handleDispatch={() => handleDispatchLoadRate(load)}
            handleCancelDispatch={() => handleCancelDispatch(load)}
            handleEditRate={() => handleEditCarrierDriverRate(true)}
            rateType={R.path([GC.FIELD_TYPE], selectedCarrierDriverRate)}
            suspendResumeCarrierRateEmailsRequest={suspendResumeCarrierRateEmailsRequest}
          />
        ),
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:dispatch', 'Dispatch')} />
        ),
        component: (
          <DispatchCardComponent
            autodialApp={autodialApp}
            loadGuid={G.getGuidFromObject(load)}
            declineReasonOptions={declineReasonOptions}
            showAcceptDeclineSection={showAcceptDeclineSection}
            selectedCarrierDriverRate={selectedCarrierDriverRate}
          />
        ),
      },
      [C.CARD_TYPE_DOCUMENTS]: {
        component: <LoadDocumentsCardComponent {...documentsProps} showOrderName={true} />,
        leftHeaderComponent: (
          <LeftHeaderComponent
            text={G.getWindowLocale('titles:documents', 'Documents')}
          />
        ),
        rightHeaderComponent: (
          <DocumentRightHeader
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            documents={loadDocuments}
            configs={communicationConfigs}
          />
        ),
      },
      [C.CARD_TYPE_DOCUMENTS_POP]: {
        component: <LoadDocumentsCardComponent {...documentsProps} showOrderName={true} documents={popDocuments} />,
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:pop-documents', 'POP Documents')} />
        ),
        rightHeaderComponent: (
          <DocumentRightHeader
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            documents={loadDocuments}
            configs={communicationConfigs}
            proofType={GC.DOCUMENT_PROOF_TYPE_POP}
          />
        ),
      },
      [C.CARD_TYPE_DOCUMENTS_POD]: {
        component: <LoadDocumentsCardComponent {...documentsProps} showOrderName={true} documents={podDocuments} />,
        leftHeaderComponent: (
          <LeftHeaderComponent
            text={G.getWindowLocale('titles:pod-documents', 'POD Documents')}
          />
        ),
        rightHeaderComponent: (
          <DocumentRightHeader
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            documents={loadDocuments}
            configs={communicationConfigs}
            proofType={GC.DOCUMENT_PROOF_TYPE_POD}
          />
        ),
      },
      [C.CARD_TYPE_MESSAGE_CENTER]: {
        leftHeaderComponent: <ChatLeftHeaderComponent />,
        component: (
          <LoadMessageCenterCardComponent
            load={load}
            openModal={openModal}
            closeModal={closeModal}
          />
        ),
      },
      [C.CARD_TYPE_PAYMENTS]: {
        leftHeaderComponent: (
          <LeftHeaderComponent text={G.getWindowLocale('titles:payments', 'Payments')} />
        ),
        rightHeaderComponent: (
          <EditRateActionButton
            handleClick={() => handleEditCarrierDriverRate(true)}
            rateType={R.path([GC.FIELD_TYPE], selectedCarrierDriverRate)}
          />
        ),
        component: (
          <LoadPaymentsCardComponent
            load={load}
            openModal={openModal}
            closeModal={closeModal}
            autodialApp={autodialApp}
            invoiceTotals={invoiceTotals}
            normalizedCurrency={normalizedCurrency}
            selectedRate={selectedCarrierDriverRate}
            configCurrency={G.getConfigValueFromStore(
              GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
              R.path(['configGroups', GC.GENERAL_CONFIG_GROUP], loadConfigs),
              GC.DEFAULT_UI_CURRENCY,
            )}
          />
        ),
      },
    };

    return { card: cardTypeMap[cardType], headerBgColor: 'rgba(245, 0, 0, 0.1)' };
  }),
  pure,
);
