import React from 'react';
// components
import { CountDown } from '../../../components/count-down';
// helpers
import * as G from '../../../helpers';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const CountDownComponent = () => {
  const description = '<CountDown /> component is created to display real-time countdown.';

  const usage = (
    <ul>
      <li>Parameter `<i>handleReset</i>` is a function that is called when the timer reaches zero and needs to be reset.</li>
      <li>Parameter `<i>styles</i>` is an object of styles that can be applied to the block displaying the countdown timer.</li>
      <li>Parameter `<i>timer</i>` is specifies the initial countdown time in milliseconds (ms). The default is set to 1200000 ms (20 minutes).</li>
    </ul>
  );

  const styles = {
    fontWeight: 'bold',
    color: G.getTheme('colors.dark.blue'),
  };

  const handleReset = () => {
    window.location.reload();
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='CountDown'
      description={description}
      path='src/components/count-down'
    >
      <CountDown
        timer={3000000}
        styles={styles}
        handleReset={handleReset}
      />
    </ComponentStoryWrapper>
  );
};

export default CountDownComponent;
