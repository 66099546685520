import React from 'react';
import * as R from 'ramda';
import { put, all, fork, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openModal, closeModal } from '../../../../components/modal/actions';
import { closeLoader, openLoader } from '../../../../components/loader/actions';
// features
import PC from '../../../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// forms
import { SelectDropdownForm } from '../../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import { makeSelectTabsVisited } from '../../selectors';
import { PRIORITY_ONE_ACCESSORIAL_SERVICE_CODE_MAP } from './constants';
import {
  getConfigByGroupSaga,
  getCustomerBranchListRequest,
  getStatusMessagesForConfigSaga,
  getSequencesByTypeForConfigSaga,
  getReferenceTypesByScopeForConfigSaga,
  getTransportationModeConfigMappingListRequest,
} from '../../sagas';
//////////////////////////////////////////////////

function* getFuelCardListForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.fuelCardConfig,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getFuelCardListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getFuelCardListForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getFuelCardListForConfigSaga exception');
  }
}

function* createFuelCardRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.fuelCardConfig,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getFuelCardListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createFuelCardRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createFuelCardRequestSaga exception');
  }
}

function* updateFuelCardRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.fuelCardConfig,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedFuelCardSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createFuelCardRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createFuelCardRequestSaga exception');
  }
}

function* removeFuelCardRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.removeFuelCardConfig(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getFuelCardListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createFuelCardRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createFuelCardRequestSaga exception');
  }
}

function* getEdiConfigsListForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.loaderConfigList,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getEdiConfigsListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getEdiConfigsListForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getEdiConfigsListForConfigSaga exception');
  }
}

function* createEdiConfigsRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.loaderConfig,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getEdiConfigsListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createEdiConfigsRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createEdiConfigsRequestSaga exception');
  }
}

function* updateEdiConfigsRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.loaderConfig,
      { data: payload },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedEdiConfigsSuccess(data));

      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'updateEdiConfigsRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateEdiConfigsRequestSaga exception');
  }
}

function* removeEdiConfigsRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getRemoveLoaderConfig(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getEdiConfigsListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'removeEdiConfigsRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeEdiConfigsRequestSaga exception');
  }
}

// edi exporter config
function* getEdiExporterConfigListForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.exporterConfigConfigList,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getEdiExporterConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getEdiExporterConfigListForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getEdiExporterConfigListForConfigSaga exception');
  }
}

function* createEdiExporterConfigRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.exporterConfig,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getEdiExporterConfigListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createEdiExporterConfigRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createEdiExporterConfigRequestSaga exception');
  }
}

function* updateEdiExporterConfigRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };

    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.exporterConfig,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedEdiExporterConfigSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'updateEdiExporterConfigRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateEdiExporterConfigRequestSaga exception');
  }
}

function* removeEdiExporterConfigRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getRemoveExporterConfig(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getEdiExporterConfigListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'removeEdiExporterConfigRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeEdiExporterConfigRequestSaga exception');
  }
}

// edi extractor config
function* getEdiExtractorConfigListForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.extractorConfigList,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getEdiExtractorConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getEdiExtractorConfigListForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getEdiExtractorConfigListForConfigSaga exception');
  }
}

function* createEdiExtractorConfigRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.extractorConfig,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedEdiExtractorConfigSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createEdiExtractorConfigRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createEdiExtractorConfigRequestSaga exception');
  }
}
function* updateEdiExtractorConfigRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.extractorConfig,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedEdiExtractorConfigSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'updateEdiExtractorConfigRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateEdiExtractorConfigRequestSaga exception');
  }
}

function* removeEdiExtractorConfigRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getRemoveExtractorConfig(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getEdiExtractorConfigListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'removeEdiExtractorConfigRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeEdiExtractorConfigRequestSaga exception');
  }
}

function* getIntegrationConfigListForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.customerIntegrationConfigList,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mapped = R.map((item: Object) =>
        R.assoc('editable', R.propEq(branchGuid, GC.BRANCH_GUID, item), item),
        data,
      );
      yield put(A.getIntegrationConfigListSuccess(mapped));
    } else {
      yield call(G.handleException, 'error', 'getIntegrationConfigListForConfigSaga exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getIntegrationConfigListForConfigSaga exception');
  }
}

function* createIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const data = R.assoc(GC.BRANCH_GUID, branchGuid, payload);
    const res = yield call(sendRequest, 'post', endpointsMap.customerIntegrationConfig, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getIntegrationConfigListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'createIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createIntegrationConfigRequest exception');
  }
}

function* updateIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.customerIntegrationConfig,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedIntegrationConfigSuccess(data));
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'updateIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateIntegrationConfigRequest exception');
  }
}

function* removeIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.customerIntegrationConfig,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getIntegrationConfigListForConfigSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'removeIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeIntegrationConfigRequest exception');
  }
}

function* getCarrierRateIntegrationListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      parentSagaName: 'getCarrierRateIntegrationListRequest',
      endpoint: endpointsMap.carrierRateIntegrationConfigList,
      successAction: A.receivedCarrierRateIntegrationListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierRateIntegrationListRequest exception');
  }
}

function* createOrUpdateCarrierRateIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierRateIntegrationConfig,
      successAction: A.createOrUpdateCarrierRateIntegrationSuccess,
      parentSagaName: 'createOrUpdateCarrierRateIntegrationRequest',
    });
    if (R.propEq(GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE, GC.FIELD_FLEET_INTEGRATION_TYPE,
      payload,
    )) {
      yield call(G.showToastrMessage, 'info', 'messages:login:applied');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierRateIntegrationRequest exception');
  }
}

function* removeCarrierRateIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.carrierRateIntegrationConfig,
      successAction: A.removeCarrierRateIntegrationSuccess,
      parentSagaName: 'removeCarrierRateIntegrationRequest',
    });
    yield call(G.showToastrMessage, 'info', 'messages:login:applied');
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierRateIntegrationRequest exception');
  }
}

function* getUserMonitoringIntegrationListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.userMonitoringConfigList,
      parentSagaName: 'getUserMonitoringIntegrationListRequest',
      successAction: A.receivedUserMonitoringIntegrationListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getUserMonitoringIntegrationListRequest exception');
  }
}

function* createOrUpdateUserMonitoringIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.userMonitoringConfig,
      successAction: A.createOrUpdateUserMonitoringIntegrationSuccess,
      parentSagaName: 'createOrUpdateUserMonitoringIntegrationRequest',
    });
    yield call(G.showToastrMessage, 'info', 'messages:login:applied');
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateUserMonitoringIntegrationRequest exception');
  }
}

function* removeUserMonitoringIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.userMonitoringConfig,
      successAction: A.removeUserMonitoringIntegrationSuccess,
      parentSagaName: 'removeUserMonitoringIntegrationRequest',
    });
    yield call(G.showToastrMessage, 'info', 'messages:login:applied');
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeUserMonitoringIntegrationRequest exception');
  }
}

function* getServiceMappingListRequest() {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.serviceMappingList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      G.setItemToWindow('serviceMappingList', data);
      yield put(A.getServiceMappingListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getServiceMappingListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getServiceMappingListRequest exception');
  }
}

function* createServiceMappingListRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.serviceMappingMassCreate, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createServiceMappingListSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createServiceMappingListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createServiceMappingListRequest exception');
  }
}

function* createOrUpdateServiceMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.serviceMapping,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateServiceMappingSuccess,
      parentSagaName: 'createOrUpdateServiceMappingRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateServiceMappingRequest exception');
  }
}

function* removeServiceMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      endpoint: endpointsMap.serviceMapping,
      successMessage: 'messages:success:204',
      successAction: A.removeServiceMappingSuccess,
      parentSagaName: 'removeServiceMappingRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeServiceMappingRequest exception');
  }
}

const CONFIG_NAMES_ARR = [
  GC.GENERAL_SERVICES,
  GC.TRUCK_TRUCK_TYPE,
  GC.GENERAL_EQUIPMENTS,
  GC.TRAILER_TRAILER_TYPE,
  GC.COMMUNICATION_REASON_CODE,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.COMMUNICATION_DOCUMENT_TYPE,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
];

function* getBranchConfigsByNameRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        names: R.join(',', CONFIG_NAMES_ARR),
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedConfigs = R.compose(
        G.mapAsyncConfigsDropdowns,
        R.prop('dropdowns'),
        G.mapConfigValuesByName,
      )(data);
      yield put(A.receivedBranchConfigsByNameSuccess(mappedConfigs));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsByNameRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchConfigsByNameRequest exception');
  }
}

function* getAccessorialServiceCodesRequest() {
  try {
    const options = {
      params: {
        carrierRateVendor: GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.accessorialServiceCodes, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedAccesorialServiceCodes = R.compose(
        G.addEmptyOptionToDropDown,
        R.map((accessorialServiceCode: string) => ({
          [GC.FIELD_VALUE]: accessorialServiceCode,
          [GC.FIELD_LABEL]: R.prop(accessorialServiceCode, PRIORITY_ONE_ACCESSORIAL_SERVICE_CODE_MAP),
        })),
      )(data);
      yield put(A.receivedAccessorialServiceCodesSuccess(mappedAccesorialServiceCodes));
    } else {
      yield call(G.handleFailResponse, res, 'getAccessorialServiceCodesRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAccessorialServiceCodesRequest exception');
  }
}

function* getAdvancePaymentListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getAdvancePaymentListSuccess,
      parentSagaName: 'getAdvancePaymentListRequest',
      endpoint: endpointsMap.advancePaymentConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getAdvancePaymentListRequest exception');
  }
}

function* createOrUpdateAdvancePaymentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.advancePaymentConfig, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateAdvancePaymentSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (G.isNilOrEmpty(payload.version)) {
        const amousConfig = R.assocPath(
          ['configs', 'showAdvancePayment'],
          true,
          G.getAmousConfigFromWindow(),
        );
        yield call(G.setItemToWindow, 'amousConfig', amousConfig);
      }
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateAdvancePaymentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateAdvancePaymentRequest exception');
  }
}

function* removeAdvancePaymentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.advancePaymentConfig, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const amousConfig = R.assocPath(
        ['configs', 'showAdvancePayment'],
        false,
        G.getAmousConfigFromWindow(),
      );
      yield put(A.removeAdvancePaymentSuccess(payload));
      yield call(G.setItemToWindow, 'amousConfig', amousConfig);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeAdvancePaymentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeAdvancePaymentRequest exception');
  }
}

function* getLoadBoardIntegrationConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.loadBoardConfigNew,
      successAction: A.getLoadBoardIntegrationConfigListSuccess,
      parentSagaName: 'getLoadBoardIntegrationConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getLoadBoardIntegrationConfigListRequest exception');
  }
}

function* createOrUpdateLoadBoardIntegrationConfigRequest({ payload }: Object) {
  try {
    const { guid } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(guid),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.loadBoardConfigNew, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateLoadBoardIntegrationConfigSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateLoadBoardIntegrationConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateLoadBoardIntegrationConfigRequest exception');
  }
}

function* removeLoadBoardIntegrationConfigRequest({ payload }: Object) {
  try {
    const { guid } = payload;

    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.loadBoardConfigById(guid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeLoadBoardIntegrationConfigSuccess(guid));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadBoardIntegrationConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeLoadBoardIntegrationConfigRequest exception');
  }
}

function* getTollChargesConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.tollChargeConfigList,
      successAction: A.getTollChargesConfigListSuccess,
      parentSagaName: 'getTollChargesConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTollChargesConfigListRequest exception');
  }
}

function* createOrUpdateTollChargesConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.tollChargeConfig, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateTollChargesConfigSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateTollChargesConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateTollChargesConfigRequest exception');
  }
}

function* removeTollChargesConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.tollChargeConfig, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.removeTollChargesConfigSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeTollChargesConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeTollChargesConfigRequest exception');
  }
}

// dispatch integration
function* getDispatchIntegrationConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.dispatchIntegrationList,
      successAction: A.getDispatchIntegrationConfigListSuccess,
      parentSagaName: 'getDispatchIntegrationConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDispatchIntegrationConfigListRequest exception');
  }
}

function* createOrUpdateDispatchIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.dispatchIntegrationConfig, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateDispatchIntegrationConfigSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateDispatchIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateDispatchIntegrationConfigRequest exception');
  }
}

function* removeDispatchIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.dispatchIntegrationConfig, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.removeDispatchIntegrationConfigSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeDispatchIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeDispatchIntegrationConfigRequest exception');
  }
}

// terminal integration
function* getTerminalIntegrationConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.terminalIntegrationList,
      successAction: A.getTerminalIntegrationConfigListSuccess,
      parentSagaName: 'getTerminalIntegrationConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTerminalIntegrationConfigListRequest exception');
  }
}

function* createOrUpdateTerminalIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.terminalIntegrationConfig, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateTerminalIntegrationConfigSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateTerminalIntegrationConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateTerminalIntegrationConfigRequest exception');
  }
}

function* removeTerminalIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.terminalIntegrationConfigByGuid(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeTerminalIntegrationConfigSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeTerminalIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeTerminalIntegrationConfigRequest exception');
  }
}

// status code mapping
function* getStatusCodeMappingListRequest(action: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const mapper = (data: Object) => ({
      data,
      listName: G.ifElse(
        R.pathEq('outboundMapping', ['payload'], action),
        'statusCodeOutboundMappingList',
        'statusCodeMappingList',
      ),
    });

    yield call(crudSaga, {
      mapper,
      options,
      method: 'get',
      successAction: A.getStatusCodeMappingListSuccess,
      parentSagaName: 'getStatusCodeMappingListRequest',
      endpoint: endpointsMap.statusCodeMappingList(R.pathOr('inboundMapping', ['payload'], action)),
    });
  } catch (error) {
    yield call(G.handleException, error, 'getStatusCodeMappingListRequest exception');
  }
}

function* createOrUpdateStatusCodeMappingRequest({ payload }: Object) {
  try {
    const { data, mappingType } = payload;

    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(data.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, data),
    };
    const mapper = (data: Object) => ({
      data,
      listName: G.ifElse(
        R.equals(mappingType, 'outboundMapping'),
        'statusCodeOutboundMappingList',
        'statusCodeMappingList',
      ),
    });

    yield call(crudSaga, {
      mapper,
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.statusCodeMapping(mappingType),
      successAction: A.createOrUpdateStatusCodeMappingSuccess,
      parentSagaName: 'createOrUpdateStatusCodeMappingRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateStatusCodeMappingRequest exception');
  }
}

function* removeStatusCodeMappingRequest({ payload }: Object) {
  try {
    const { guid, mappingType } = payload;

    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        guid,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const mapper = () => ({
      guid,
      listName: G.ifElse(
        R.equals(mappingType, 'outboundMapping'),
        'statusCodeOutboundMappingList',
        'statusCodeMappingList',
      ),
    });

    yield call(crudSaga, {
      mapper,
      options,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeStatusCodeMappingSuccess,
      parentSagaName: 'removeStatusCodeMappingRequest',
      endpoint: endpointsMap.statusCodeMapping(mappingType),
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeStatusCodeMappingRequest exception');
  }
}

// status reason code mapping
function* getStatusReasonCodeMappingListRequest(action: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const mapper = (data: Object) => ({
      data,
      listName: G.ifElse(
        R.pathEq('outboundMapping', ['payload'], action),
        'statusReasonCodeOutboundMappingList',
        'statusReasonCodeMappingList',
      ),
    });

    yield call(crudSaga, {
      mapper,
      options,
      method: 'get',
      successAction: A.getStatusReasonCodeMappingListSuccess,
      parentSagaName: 'getStatusReasonCodeMappingListRequest',
      endpoint: endpointsMap.statusReasonCodeMappingList(R.pathOr('inboundMapping', ['payload'], action)),
    });
  } catch (error) {
    yield call(G.handleException, error, 'getStatusReasonCodeMappingListRequest exception');
  }
}

function* createOrUpdateStatusReasonCodeMappingRequest({ payload }: Object) {
  try {
    const { data, mappingType } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_VERSION, data)),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, data),
    };

    const mapper = (data: Object) => ({
      data,
      listName: G.ifElse(
        R.equals(mappingType, 'outboundMapping'),
        'statusReasonCodeOutboundMappingList',
        'statusReasonCodeMappingList',
      ),
    });

    yield call(crudSaga, {
      mapper,
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.statusReasonCodeMapping(mappingType),
      successAction: A.createOrUpdateStatusReasonCodeMappingSuccess,
      parentSagaName: 'createOrUpdateStatusReasonCodeMappingRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateStatusReasonCodeMappingRequest exception');
  }
}

function* removeStatusReasonCodeMappingRequest({ payload }: Object) {
  try {
    const { guid, mappingType } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        guid,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const mapper = () => ({
      guid,
      listName: G.ifElse(
        R.equals(mappingType, 'outboundMapping'),
        'statusReasonCodeOutboundMappingList',
        'statusReasonCodeMappingList',
      ),
    });

    yield call(crudSaga, {
      mapper,
      options,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeStatusReasonCodeMappingSuccess,
      parentSagaName: 'removeStatusReasonCodeMappingRequest',
      endpoint: endpointsMap.statusReasonCodeMapping(mappingType),
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeStatusCodeMappingRequest exception');
  }
}

// public clo
function* getPublicCLOConfigRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.publicCLOConfig,
      successAction: A.getPublicCLOConfigSuccess,
      parentSagaName: 'getPublicCLOConfigRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getPublicCLOConfigRequest exception');
  }
}

function* createOrUpdatePublicCLOConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.publicCLOConfig,
      successMessage: 'messages:success:200-201',
      successAction: A.getPublicCLOConfigSuccess,
      parentSagaName: 'createOrUpdatePublicCLOConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdatePublicCLOConfigRequest exception');
  }
}

function* removePublicCLOConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removePublicCLOConfig(payload);
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removePublicCLOConfigSuccess,
      parentSagaName: 'removePublicCLOConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removePublicCLOConfigRequest exception');
  }
}

// service type mapping
function* getServiceTypeMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.serviceTypeMappingList,
      successAction: A.getServiceTypeMappingListSuccess,
      parentSagaName: 'getServiceTypeMappingListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getServiceTypeMappingListRequest exception');
  }
}

function* createOrUpdateServiceTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.serviceTypeMapping,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateServiceTypeMappingSuccess,
      parentSagaName: 'createOrUpdateServiceTypeMappingRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateServiceTypeMappingRequest exception');
  }
}

function* removeServiceTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.serviceTypeMapping,
      successAction: A.removeServiceTypeMappingSuccess,
      parentSagaName: 'removeServiceTypeMappingRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeServiceTypeMappingRequest exception');
  }
}

// carrier synchronization
function* getCarrierSynchronizationConfigRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierSynchronizationConfig,
      successAction: A.getCarrierSynchronizationConfigSuccess,
      parentSagaName: 'getCarrierSynchronizationConfigRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierSynchronizationConfigRequest exception');
  }
}

function* createOrUpdateCarrierSynchronizationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierSynchronizationConfig,
      successAction: A.getCarrierSynchronizationConfigSuccess,
      parentSagaName: 'createOrUpdateCarrierSynchronizationConfigRequest',
    });

    if (G.isNilOrEmpty(payload.version)) {
      yield fork(G.setAmousConfigToWindow, { configs: { showCarrierSynchronization: true } });
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierSynchronizationConfigRequest exception');
  }
}

function* removeCarrierSynchronizationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    yield call(crudSaga, {
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeCarrierSynchronizationConfigSuccess,
      parentSagaName: 'removeCarrierSynchronizationConfigRequest',
      endpoint: endpointsMap.removeCarrierSynchronizationConfig(payload),
    });

    yield fork(G.setAmousConfigToWindow, { configs: { showCarrierSynchronization: false } });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeServiceTypeMappingRequest exception');
  }
}

// carrier integration override
function* getCarrierIntegrationOverrideListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getCarrierIntegrationOverrideListSuccess,
      parentSagaName: 'getCarrierIntegrationOverrideListRequest',
      endpoint: endpointsMap.carrierIntegrationCustomerConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierIntegrationOverrideListRequest exception');
  }
}

function* createOrUpdateCarrierIntegrationOverrideRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierIntegrationCustomerConfig,
      successAction: A.createOrUpdateCarrierIntegrationOverrideSuccess,
      parentSagaName: 'createOrUpdateCarrierIntegrationOverrideRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierIntegrationOverrideRequest exception');
  }
}

function* removeCarrierIntegrationOverrideRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.carrierIntegrationCustomerConfig,
      successAction: A.removeCarrierIntegrationOverrideSuccess,
      parentSagaName: 'removeCarrierIntegrationOverrideRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierIntegrationOverrideRequest exception');
  }
}

// document type mapping
function* getDocumentTypeMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.documentTypeMappingList,
      successAction: A.getDocumentTypeMappingListSuccess,
      parentSagaName: 'getDocumentTypeMappingListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDocumentTypeMappingListRequest exception');
  }
}

function* createOrUpdateDocumentTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.documentTypeMapping,
      successAction: A.createOrUpdateDocumentTypeMappingSuccess,
      parentSagaName: 'createOrUpdateDocumentTypeMappingRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateDocumentTypeMappingRequest exception');
  }
}

function* removeDocumentTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      payload,
      options,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.documentTypeMapping,
      successAction: A.removeDocumentTypeMappingSuccess,
      parentSagaName: 'removeDocumentTypeMappingRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeDocumentTypeMappingRequest exception');
  }
}

// document hub
function* getDocumentHubConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getDocumentHubConfigListSuccess,
      parentSagaName: 'getDocumentHubConfigListRequest',
      endpoint: endpointsMap.documentHubIntegrationConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDocumentHubConfigListRequest exception');
  }
}

function* createOrUpdateDocumentHubConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.documentHubIntegrationConfig,
      successAction: A.createOrUpdateDocumentHubConfigSuccess,
      parentSagaName: 'createOrUpdateDocumentHubConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateDocumentHubConfigRequest exception');
  }
}

function* removeDocumentHubConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeDocumentHubIntegrationConfig(payload);
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeDocumentHubConfigSuccess,
      parentSagaName: 'removeDocumentHubConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeDocumentHubConfigRequest exception');
  }
}

// accounting
function* getAccountingConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getAccountingConfigListSuccess,
      parentSagaName: 'getAccountingConfigListRequest',
      endpoint: endpointsMap.accountingIntegrationConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getAccountingConfigListRequest exception');
  }
}

function* createOrUpdateAccountingConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.accountingIntegrationConfig,
      successAction: A.createOrUpdateAccountingConfigSuccess,
      parentSagaName: 'createOrUpdateAccountingConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateAccountingConfigRequest exception');
  }
}

function* removeAccountingConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeAccountingIntegrationConfig(payload);
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeAccountingConfigSuccess,
      parentSagaName: 'removeAccountingConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeAccountingConfigRequest exception');
  }
}

// carrier pickup requester
function* getCarrierPickupRequesterListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierPickupRequesterList,
      successAction: A.getCarrierPickupRequesterListSuccess,
      parentSagaName: 'getCarrierPickupRequesterListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierPickupRequesterListRequest exception');
  }
}

function* createOrUpdateCarrierPickupRequesterRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierPickupRequester,
      successAction: A.createOrUpdateCarrierPickupRequesterSuccess,
      parentSagaName: 'createOrUpdateCarrierPickupRequesterRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierPickupRequesterRequest exception');
  }
}

function* removeCarrierPickupRequesterRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeCarrierPickupRequester(payload);
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeCarrierPickupRequesterSuccess,
      parentSagaName: 'removeCarrierPickupRequesterRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierPickupRequesterRequest exception');
  }
}

function* getAvailableCarrierPickupRequesterListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierPickupRequesterListAvailable,
      successAction: A.getAvailableCarrierPickupRequesterListSuccess,
      parentSagaName: 'getAvailableCarrierPickupRequesterListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getAvailableCarrierPickupRequesterListRequest exception');
  }
}

// carrier edi loader config
function* getCarrierEdiLoaderConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierEdiLoaderConfigList,
      successAction: A.getCarrierEdiLoaderConfigListSuccess,
      parentSagaName: 'getCarrierEdiLoaderConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierEdiLoaderConfigListRequest exception');
  }
}

function* createOrUpdateCarrierEdiLoaderConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierEdiLoaderConfig,
      successAction: A.createOrUpdateCarrierEdiLoaderConfigSuccess,
      parentSagaName: 'createOrUpdateCarrierEdiLoaderConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierEdiLoaderConfigRequest exception');
  }
}

function* removeCarrierEdiLoaderConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeCarrierEdiLoaderConfig(payload);
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeCarrierEdiLoaderConfigSuccess,
      parentSagaName: 'removeCarrierEdiLoaderConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierEdiLoaderConfigRequest exception');
  }
}

// carrier edi exporter config
function* getCarrierEdiExporterConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierEdiExporterConfigList,
      successAction: A.getCarrierEdiExporterConfigListSuccess,
      parentSagaName: 'getCarrierEdiExporterConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierEdiExporterConfigListRequest exception');
  }
}

function* createOrUpdateCarrierEdiExporterConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierEdiExporterConfig,
      successAction: A.createOrUpdateCarrierEdiExporterConfigSuccess,
      parentSagaName: 'createOrUpdateCarrierEdiExporterConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierEdiLoaderConfigRequest exception');
  }
}

function* removeCarrierEdiExporterConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeCarrierEdiExporterConfig(payload);
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeCarrierEdiExporterConfigSuccess,
      parentSagaName: 'removeCarrierEdiExporterConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierEdiExporterConfigRequest exception');
  }
}

function* getCarrierOptionsRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierNameMap,
      successAction: A.getCarrierOptionsSuccess,
      parentSagaName: 'getCarrierOptionsRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierOptionsRequest exception');
  }
}

// vin lookup
function* getVinLookupConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.fleetVinLookupConfig,
      parentSagaName: 'getVinLookupConfigListRequest',
      successAction: A.getVinLookupConfigListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getVinLookupConfigListRequest exception');
  }
}

function* createOrUpdateVinLookupConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.fleetVinLookupConfig,
      parentSagaName: 'createOrUpdateVinLookupConfigRequest',
      successAction: A.createOrUpdateVinLookupConfigSuccess,
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateVinLookupConfigRequest exception');
  }
}

function* removeVinLookupConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.fleetVinLookupConfig;
    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      options: { data: payload },
      successMessage: 'messages:success:204',
      successAction: A.removeVinLookupConfigSuccess,
      parentSagaName: 'removeVinLookupConfigRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeVinLookupConfigRequest exception');
  }
}

// factoring integration config
function* getFactoringIntegrationConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.factoringIntegrationConfigList,
      successAction: A.getFactoringIntegrationConfigListSuccess,
      parentSagaName: 'getFactoringIntegrationConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getVinLookupConfigListRequest exception');
  }
}

function* createOrUpdateFactoringIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.factoringIntegrationConfig,
      callbackSaga: getFactoringIntegrationConfigListRequest,
      parentSagaName: 'createOrUpdateFactoringIntegrationConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateFactoringIntegrationConfigRequest exception');
  }
}

function* removeFactoringIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeFactoringIntegrationConfig(payload);

    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeFactoringIntegrationConfigSuccess,
      parentSagaName: 'removeFactoringIntegrationConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeVinLookupConfigRequest exception');
  }
}

// equipment mapping config
function* getEquipmentMappingConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.equipmentMappingList,
      successAction: A.getEquipmentMappingConfigListSuccess,
      parentSagaName: 'getEquipmentMappingConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getEquipmentMappingConfigListRequest exception');
  }
}

function* createOrUpdateEquipmentMappingConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.equipmentMapping,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateEquipmentMappingConfigSuccess,
      parentSagaName: 'createOrUpdateEquipmentMappingConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateEquipmentMappingConfigRequest exception');
  }
}

function* removeEquipmentMappingConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.equipmentMapping;
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      payload,
      options,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeEquipmentMappingConfigSuccess,
      parentSagaName: 'removeEquipmentMappingConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeEquipmentMappingConfigRequest exception');
  }
}

// available edi export mappers
function* getAvailableEdiExportMappersRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.availableEdiExportMappers,
      successAction: A.getAvailableEdiExportMappersSuccess,
      parentSagaName: 'getAvailableEdiExportMappersRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getAvailableEdiExportMappersRequest exception');
  }
}

// trailer tracking integration config
function* getTrailerTrackingIntegrationConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.fleetTrailerTrackingIntegrationList,
      successAction: A.getTrailerTrackingIntegrationConfigListSuccess,
      parentSagaName: 'getTrailerTrackingIntegrationConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTrailerTrackingIntegrationConfigListRequest exception');
  }
}

function* createOrUpdateTrailerTrackingIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.fleetTrailerTrackingIntegration,
      successAction: A.createOrUpdateTrailerTrackingIntegrationConfigSuccess,
      parentSagaName: 'createOrUpdateTrailerTrackingIntegrationConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateTrailerTrackingIntegrationConfigRequest exception');
  }
}

function* removeTrailerTrackingIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.removeFleetTrailerTrackingIntegration(payload);

    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeTrailerTrackingIntegrationConfigSuccess,
      parentSagaName: 'removeTrailerTrackingIntegrationConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeTrailerTrackingIntegrationConfigRequest exception');
  }
}

// los subscription config
function* getLosSubscriptionConfigRequest() {
  try {
    if (R.not(G.getIsAmousUserSuperAdminFromWindow())) return false;

    yield call(crudSaga, {
      method: 'get',
      endpoint: endpointsMap.losSubscriptionConfig,
      successAction: A.getLosSubscriptionConfigSuccess,
      parentSagaName: 'getLosSubscriptionConfigRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getLosSubscriptionConfigRequest exception');
  }
}

function* updateLosSubscriptionConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    yield call(crudSaga, {
      method: 'put',
      shouldCloseModal: true,
      showSuccessMessage: true,
      options: { data: payload },
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.losSubscriptionConfig,
      successAction: A.updateLosSubscriptionConfigSuccess,
      parentSagaName: 'updateLosSubscriptionConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'updateLosSubscriptionConfigRequest exception');
  }
}

function* unlinkLosAccountRequest() {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'post',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successAction: A.unlinkLosAccountSuccess,
      parentSagaName: 'unlinkLosAccountRequest',
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.losSubscriptionUnlink,
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'unlinkLosAccountRequest exception');
  }
}

// los pricing plan
function* getLosPricingPlanConfigListRequest() {
  try {
    if (R.not(G.getIsAmousUserSuperAdminFromWindow())) return false;

    yield call(crudSaga, {
      method: 'get',
      endpoint: endpointsMap.losPricingPlanList,
      successAction: A.getLosPricingPlanConfigListSuccess,
      parentSagaName: 'getLosPricingPlanConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getLosPricingPlanConfigListRequest exception');
  }
}

function* createOrUpdateLosPricingPlanConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = { data: payload };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.losPricingPlan,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateLosPricingPlanConfigSuccess,
      parentSagaName: 'createOrUpdateLosPricingPlanConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateLosPricingPlanConfigRequest exception');
  }
}

function* removeLosPricingPlanConfigWithSubstitutionRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, substitutionGuid } = payload;

    const options = {
      params: { substitutionGuid },
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.losPricingPlanByGuid(guid), options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(A.removeLosPricingPlanConfigSuccess(guid));
    } else {
      yield call(G.handleFailResponse, res, 'removeLosPricingPlanConfigWithSubstitution fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeLosPricingPlanConfigWithSubstitution exception');
  }
}

function* removeLosPricingPlanConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, availablePricingPlansOptions, hanldeRemoveLosPricingPlanConfigWithSubstitution } = payload;

    const res = yield call(sendRequest, 'delete', endpointsMap.losPricingPlanByGuid(guid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(A.removeLosPricingPlanConfigSuccess(guid));
    } else if (R.equals(status, 400)) {
      const component = (
        <SelectDropdownForm
          fieldWidth={270}
          cancelAction={closeModal}
          options={availablePricingPlansOptions}
          fieldLabel={G.getWindowLocale('titles:substitution-plan', 'Substitution Plan')}
          submitAction={(substitutionGuid: string) =>
            hanldeRemoveLosPricingPlanConfigWithSubstitution({ guid, substitutionGuid })
          }
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
        },
      };

      yield put(openModal(modal));
    } else {
      yield call(G.handleFailResponse, res, 'removeLosPricingPlanConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeLosPricingPlanConfigRequest exception');
  }
}
// los pricing plan

// cross border integration config
function* getCrossBorderIntegrationConfigRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.crossBorderIntegrationConfigList,
      successAction: A.getCrossBorderIntegrationConfigSuccess,
      parentSagaName: 'getCrossBorderIntegrationConfigRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCrossBorderIntegrationConfigRequest exception');
  }
}

function* createOrUpdateCrossBorderIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.crossBorderIntegrationConfig,
      callbackSaga: getCrossBorderIntegrationConfigRequest,
      parentSagaName: 'createOrUpdateCrossBorderIntegrationConfigRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'createOrUpdateCrossBorderIntegrationConfigRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* removeCrossBorderIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.removeCrossBorderIntegrationConfig(payload);

    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeCrossBorderIntegrationConfigSuccess,
      parentSagaName: 'removeCrossBorderIntegrationConfigRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'removeCrossBorderIntegrationConfigRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

// truck type mapping config
function* getTruckTypeMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.truckTypeMappingList,
      successAction: A.getTruckTypeMappingListSuccess,
      parentSagaName: 'getTruckTypeMappingListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTruckTypeMappingListRequest exception');
  }
}

function* createOrUpdateTruckTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.truckTypeMapping,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateTruckTypeMappingSuccess,
      parentSagaName: 'createOrUpdateServiceTypeMappingRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'createOrUpdateTruckTypeMappingRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* removeTruckTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.truckTypeMapping,
      successAction: A.removeTruckTypeMappingSuccess,
      parentSagaName: 'removeTruckTypeMappingRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'removeTruckTypeMappingRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

// trailer type mapping config
function* getTrailerTypeMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.trailerTypeMappingList,
      successAction: A.getTrailerTypeMappingListSuccess,
      parentSagaName: 'getTrailerTypeMappingListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTrailerTypeMappingListRequest exception');
  }
}

function* createOrUpdateTrailerTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.trailerTypeMapping,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateTrailerTypeMappingSuccess,
      parentSagaName: 'createOrUpdateTrailerTypeMappingRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'createOrUpdateTrailerTypeMappingRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* removeTrailerTypeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.trailerTypeMapping,
      successAction: A.removeTrailerTypeMappingSuccess,
      parentSagaName: 'removeTrailerTypeMappingRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'removeTrailerTypeMappingRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* handleSetActiveConfigTabSaga({ payload }: Object) {
  try {
    const tabsVisited = yield select(makeSelectTabsVisited());

    if (R.includes(payload, tabsVisited)) return;

    let map = [
      [
        getAdvancePaymentListRequest,
        getFactoringIntegrationConfigListRequest,
      ],
      [
        getPublicCLOConfigRequest,
        getEdiConfigsListForConfigSaga,
        getAvailableEdiExportMappersRequest,
        getEdiExporterConfigListForConfigSaga,
        getIntegrationConfigListForConfigSaga,
        getEdiExtractorConfigListForConfigSaga,
      ],
      [
        getFuelCardListForConfigSaga,
        getTruckTypeMappingListRequest,
        getTollChargesConfigListRequest,
        getTrailerTypeMappingListRequest,
      ],
      [
        getCarrierOptionsRequest,
        getServiceMappingListRequest,
        getCustomerBranchListRequest,
        getCarrierEdiLoaderConfigListRequest,
        getEquipmentMappingConfigListRequest,
        getCarrierPickupRequesterListRequest,
        getCarrierRateIntegrationListRequest,
        getCarrierEdiExporterConfigListRequest,
        getCarrierSynchronizationConfigRequest,
        getDispatchIntegrationConfigListRequest,
        getCarrierIntegrationOverrideListRequest,
        getAvailableCarrierPickupRequesterListRequest,
        {
          arg: { payload: GC.INTEGRATION_CONFIG_GROUP },
          saga: getTransportationModeConfigMappingListRequest,
        },
      ],
      [],
      [
        getUserMonitoringIntegrationListRequest,
      ],
      [
        getDocumentHubConfigListRequest,
        getDocumentTypeMappingListRequest,
      ],
      [
        getAccountingConfigListRequest,
      ],
      [
        getVinLookupConfigListRequest,
      ],
      [
        getStatusCodeMappingListRequest,
        getStatusReasonCodeMappingListRequest,
        {
          arg: { payload: 'outboundMapping' },
          saga: getStatusCodeMappingListRequest,
        },
        {
          arg: { payload: 'outboundMapping' },
          saga: getStatusReasonCodeMappingListRequest,
        },
      ],
      [
        getLosSubscriptionConfigRequest,
        getLosPricingPlanConfigListRequest,
        {
          action: A.getCustomerInvoiceStatusesRequest,
        },
      ],
      [
        getTrailerTrackingIntegrationConfigListRequest,
      ],
      [
        getTerminalIntegrationConfigListRequest,
      ],
      [
        getCrossBorderIntegrationConfigRequest,
      ],
    ];

    if (G.hasNotAmousCurrentUserPermissions(PC.EXTERNAL_LOAD_BOARD_EXECUTE)) {
      map = R.remove(4, 1, map);
    }

    const requests = R.prop(payload, map);

    if (G.isNilOrEmpty(requests)) return false;

    yield put(openLoader());

    yield all(requests.map((item: any = {}) => {
      const { arg, saga, action } = item;

      if (action) return put(action());

      if (arg) return call(saga, arg);

      return call(item);
    }));

    yield put(closeLoader());
    yield put(A.setConfigTabsVisited(R.append(payload, tabsVisited)));
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleSetActiveConfigTabSaga exception');
  }
}

// TODO: with call on tab click
function* handleVisitConfigIntegrationPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_INTEGRATION_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield all([
      call(getBranchConfigsByNameRequest),
      call(getStatusMessagesForConfigSaga),
      call(getAccessorialServiceCodesRequest),
      call(getConfigByGroupSaga, GC.INTEGRATION_CONFIG_GROUP),
    ]);

    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_CLO });
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_BRANCH });
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_CARRIER });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CLO });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_TEL });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CUSTOMER_INVOICE });

    yield put(A.setConfigTabsVisited([]));
    yield put(A.setIntegrationActiveConfigTab(0));

    yield put(closeLoader());
    break;
  }
}

function* integrationConfigWatcherSaga() {
  yield takeLatest(A.removeFuelCardRequest, removeFuelCardRequestSaga);
  yield takeLatest(A.updateFuelCardRequest, updateFuelCardRequestSaga);
  yield takeLatest(A.createFuelCardRequest, createFuelCardRequestSaga);
  yield takeLatest(A.createEdiConfigsRequest, createEdiConfigsRequestSaga);
  yield takeLatest(A.updateEdiConfigsRequest, updateEdiConfigsRequestSaga);
  yield takeLatest(A.removeEdiConfigsRequest, removeEdiConfigsRequestSaga);
  yield takeLatest(A.getStatusCodeListRequest, getEdiConfigsListForConfigSaga);
  yield takeLatest(A.removeServiceMappingRequest, removeServiceMappingRequest);
  yield takeLatest(A.removeAdvancePaymentRequest, removeAdvancePaymentRequest);
  yield takeLatest(A.getAdvancePaymentListRequest, getAdvancePaymentListRequest);
  yield takeLatest(A.getServiceMappingListRequest, getServiceMappingListRequest);
  yield takeLatest(A.setIntegrationActiveConfigTab, handleSetActiveConfigTabSaga);
  yield takeLatest(A.removeIntegrationConfigRequest, removeIntegrationConfigRequest);
  yield takeLatest(A.updateIntegrationConfigRequest, updateIntegrationConfigRequest);
  yield takeLatest(A.createIntegrationConfigRequest, createIntegrationConfigRequest);
  yield takeLatest(A.removeTollChargesConfigRequest, removeTollChargesConfigRequest);
  yield takeLatest(A.createServiceMappingListRequest, createServiceMappingListRequest);
  yield takeLatest(A.getTollChargesConfigListRequest, getTollChargesConfigListRequest);
  yield takeLatest(A.updateEdiExporterConfigRequest, updateEdiExporterConfigRequestSaga);
  yield takeLatest(A.createEdiExporterConfigRequest, createEdiExporterConfigRequestSaga);
  yield takeLatest(A.removeEdiExporterConfigRequest, removeEdiExporterConfigRequestSaga);
  yield takeLatest(A.removeEdiExtractorConfigRequest, removeEdiExtractorConfigRequestSaga);
  yield takeLatest(A.updateEdiExtractorConfigRequest, updateEdiExtractorConfigRequestSaga);
  yield takeLatest(A.createEdiExtractorConfigRequest, createEdiExtractorConfigRequestSaga);
  yield takeLatest(GC.VISIT_CONFIG_INTEGRATION_PAGE, handleVisitConfigIntegrationPageSaga);
  yield takeLatest(A.getIntegrationConfigListRequest, getIntegrationConfigListForConfigSaga);
  yield takeLatest(A.createOrUpdateAdvancePaymentRequest, createOrUpdateAdvancePaymentRequest);
  yield takeLatest(A.removeCarrierRateIntegrationRequest, removeCarrierRateIntegrationRequest);
  yield takeLatest(A.createOrUpdateServiceMappingRequest, createOrUpdateServiceMappingRequest);
  yield takeLatest(A.getCarrierRateIntegrationListRequest, getCarrierRateIntegrationListRequest);
  yield takeLatest(A.createOrUpdateTollChargesConfigRequest, createOrUpdateTollChargesConfigRequest);
  yield takeLatest(A.removeLoadBoardIntegrationConfigRequest, removeLoadBoardIntegrationConfigRequest);
  yield takeLatest(A.getLoadBoardIntegrationConfigListRequest, getLoadBoardIntegrationConfigListRequest);
  yield takeLatest(A.createOrUpdateCarrierRateIntegrationRequest, createOrUpdateCarrierRateIntegrationRequest);
  yield takeLatest(A.createOrUpdateLoadBoardIntegrationConfigRequest, createOrUpdateLoadBoardIntegrationConfigRequest);
  // dispatch integration config
  yield takeLatest(A.removeDispatchIntegrationConfigRequest, removeDispatchIntegrationConfigRequest);
  yield takeLatest(A.getDispatchIntegrationConfigListRequest, getDispatchIntegrationConfigListRequest);
  yield takeLatest(A.createOrUpdateDispatchIntegrationConfigRequest, createOrUpdateDispatchIntegrationConfigRequest);
  // terminal integration config
  yield takeLatest(A.removeTerminalIntegrationConfigRequest, removeTerminalIntegrationConfigRequest);
  yield takeLatest(A.getTerminalIntegrationConfigListRequest, getTerminalIntegrationConfigListRequest);
  yield takeLatest(A.createOrUpdateTerminalIntegrationConfigRequest, createOrUpdateTerminalIntegrationConfigRequest);
  // user monitoring
  yield takeLatest(A.removeUserMonitoringIntegrationRequest, removeUserMonitoringIntegrationRequest);
  yield takeLatest(A.getUserMonitoringIntegrationListRequest, getUserMonitoringIntegrationListRequest);
  yield takeLatest(A.createOrUpdateUserMonitoringIntegrationRequest, createOrUpdateUserMonitoringIntegrationRequest);
  // status code mapping
  yield takeLatest(A.removeStatusCodeMappingRequest, removeStatusCodeMappingRequest);
  yield takeLatest(A.getStatusCodeMappingListRequest, getStatusCodeMappingListRequest);
  yield takeLatest(A.createOrUpdateStatusCodeMappingRequest, createOrUpdateStatusCodeMappingRequest);
  // status reason code mapping
  yield takeLatest(A.removeStatusReasonCodeMappingRequest, removeStatusReasonCodeMappingRequest);
  yield takeLatest(A.getStatusReasonCodeMappingListRequest, getStatusReasonCodeMappingListRequest);
  yield takeLatest(A.createOrUpdateStatusReasonCodeMappingRequest, createOrUpdateStatusReasonCodeMappingRequest);
  // public clo
  yield takeLatest(A.getPublicCLOConfigRequest, getPublicCLOConfigRequest);
  yield takeLatest(A.removePublicCLOConfigRequest, removePublicCLOConfigRequest);
  yield takeLatest(A.createOrUpdatePublicCLOConfigRequest, createOrUpdatePublicCLOConfigRequest);
  // service type mapping
  yield takeLatest(A.removeServiceTypeMappingRequest, removeServiceTypeMappingRequest);
  yield takeLatest(A.getServiceTypeMappingListRequest, getServiceTypeMappingListRequest);
  yield takeLatest(A.createOrUpdateServiceTypeMappingRequest, createOrUpdateServiceTypeMappingRequest);
  // carrier synchronization
  yield takeLatest(A.getCarrierSynchronizationConfigRequest, getCarrierSynchronizationConfigRequest);
  yield takeLatest(A.removeCarrierSynchronizationConfigRequest, removeCarrierSynchronizationConfigRequest);
  yield takeLatest(
    A.createOrUpdateCarrierSynchronizationConfigRequest,
    createOrUpdateCarrierSynchronizationConfigRequest,
  );
  // carrier integration override
  yield takeLatest(A.removeCarrierIntegrationOverrideRequest, removeCarrierIntegrationOverrideRequest);
  yield takeLatest(A.getCarrierIntegrationOverrideListRequest, getCarrierIntegrationOverrideListRequest);
  yield takeLatest(A.createOrUpdateCarrierIntegrationOverrideRequest, createOrUpdateCarrierIntegrationOverrideRequest);
  // document type mapping
  yield takeLatest(A.removeDocumentTypeMappingRequest, removeDocumentTypeMappingRequest);
  yield takeLatest(A.getDocumentTypeMappingListRequest, getDocumentTypeMappingListRequest);
  yield takeLatest(A.createOrUpdateDocumentTypeMappingRequest, createOrUpdateDocumentTypeMappingRequest);
  // document hub config
  yield takeLatest(A.removeDocumentHubConfigRequest, removeDocumentHubConfigRequest);
  yield takeLatest(A.createOrUpdateDocumentHubConfigRequest, createOrUpdateDocumentHubConfigRequest);
  // accounting
  yield takeLatest(A.removeAccountingConfigRequest, removeAccountingConfigRequest);
  yield takeLatest(A.createOrUpdateAccountingConfigRequest, createOrUpdateAccountingConfigRequest);
  // carrier pickup requester
  yield takeLatest(A.removeCarrierPickupRequesterRequest, removeCarrierPickupRequesterRequest);
  yield takeLatest(A.getCarrierPickupRequesterListRequest, getCarrierPickupRequesterListRequest);
  yield takeLatest(A.createOrUpdateCarrierPickupRequesterRequest, createOrUpdateCarrierPickupRequesterRequest);
  // carrier edi loader config
  yield takeLatest(A.removeCarrierEdiLoaderConfigRequest, removeCarrierEdiLoaderConfigRequest);
  yield takeLatest(A.createOrUpdateCarrierEdiLoaderConfigRequest, createOrUpdateCarrierEdiLoaderConfigRequest);
  // carrier edi exporter config
  yield takeLatest(A.removeCarrierEdiExporterConfigRequest, removeCarrierEdiExporterConfigRequest);
  yield takeLatest(A.createOrUpdateCarrierEdiExporterConfigRequest, createOrUpdateCarrierEdiExporterConfigRequest);
  // vin lookup config
  yield takeLatest(A.removeVinLookupConfigRequest, removeVinLookupConfigRequest);
  yield takeLatest(A.createOrUpdateVinLookupConfigRequest, createOrUpdateVinLookupConfigRequest);
  // factoring integration config
  yield takeLatest(A.removeFactoringIntegrationConfigRequest, removeFactoringIntegrationConfigRequest);
  yield takeLatest(A.getFactoringIntegrationConfigListRequest, getFactoringIntegrationConfigListRequest);
  yield takeLatest(A.createOrUpdateFactoringIntegrationConfigRequest, createOrUpdateFactoringIntegrationConfigRequest);
  // equipment mapping config
  yield takeLatest(A.removeEquipmentMappingConfigRequest, removeEquipmentMappingConfigRequest);
  yield takeLatest(A.getEquipmentMappingConfigListRequest, getEquipmentMappingConfigListRequest);
  yield takeLatest(A.createOrUpdateEquipmentMappingConfigRequest, createOrUpdateEquipmentMappingConfigRequest);
  // trailer tracking integration config
  yield takeLatest(A.removeTrailerTrackingIntegrationConfigRequest, removeTrailerTrackingIntegrationConfigRequest);
  yield takeLatest(
    A.createOrUpdateTrailerTrackingIntegrationConfigRequest,
    createOrUpdateTrailerTrackingIntegrationConfigRequest,
  );
  // los subscription
  yield takeLatest(A.unlinkLosAccountRequest, unlinkLosAccountRequest);
  yield takeLatest(A.getLosSubscriptionConfigRequest, getLosSubscriptionConfigRequest);
  yield takeLatest(A.updateLosSubscriptionConfigRequest, updateLosSubscriptionConfigRequest);
  // los pricing plan
  yield takeLatest(A.removeLosPricingPlanConfigRequest, removeLosPricingPlanConfigRequest);
  yield takeLatest(A.getLosPricingPlanConfigListRequest, getLosPricingPlanConfigListRequest);
  yield takeLatest(A.createOrUpdateLosPricingPlanConfigRequest, createOrUpdateLosPricingPlanConfigRequest);
  yield takeLatest(
    A.removeLosPricingPlanConfigWithSubstitutionRequest,
    removeLosPricingPlanConfigWithSubstitutionRequest,
  );
  // cross border integration config
  yield takeLatest(A.removeCrossBorderIntegrationConfigRequest, removeCrossBorderIntegrationConfigRequest);
  yield takeLatest(
    A.createOrUpdateCrossBorderIntegrationConfigRequest,
    createOrUpdateCrossBorderIntegrationConfigRequest,
  );
  // truck type mapping config
  yield takeLatest(A.removeTruckTypeMappingRequest, removeTruckTypeMappingRequest);
  yield takeLatest(A.getTruckTypeMappingListRequest, getTruckTypeMappingListRequest);
  yield takeLatest(A.createOrUpdateTruckTypeMappingRequest, createOrUpdateTruckTypeMappingRequest);
  // trailer type mapping config
  yield takeLatest(A.removeTrailerTypeMappingRequest, removeTrailerTypeMappingRequest);
  yield takeLatest(A.getTrailerTypeMappingListRequest, getTrailerTypeMappingListRequest);
  yield takeLatest(A.createOrUpdateTrailerTypeMappingRequest, createOrUpdateTrailerTypeMappingRequest);
}

export default integrationConfigWatcherSaga;
