import React from 'react';
import { connect } from 'react-redux';
// components
import Insurances from '../../../components/insurance';
import { openModal, closeModal } from '../../../components/modal/actions';
// ui
import { Box } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const insurances = [
  {
    'guid': '0',
    'version': 0,
    'notified': false,
    'carrierGuid': '1',
    'insurerName': 'Test',
    'coverageAmount': 500,
    'createdBy': 'Julianna',
    'lastModifiedBy': 'Julianna',
    'description': 'Lorem Lorem',
    'policyNumber': '1234567890',
    'effectiveDate': '01/03/2024',
    'expirationDate': '01/05/2024',
    'createdDate': '01/03/2024 5:30:12 PM +02',
    'lastModifiedDate': '01/03/2024 5:30:12 PM +02',
    'insuranceType': {
      'guid': '2',
      'storedValue': 'Auto',
      'displayedValue': 'Auto',
      'dropdownOptionGuid': '3',
    },
  },
];

const InsurancesComponent = ({ openModal, closeModal }: Object) => {
  const description = '<Insurances /> component is a composed component managing insurance-related data rendering within a table format using the FormGroupTable.';

  const usage = (
    <ul>
      <li>Parameter `<i>handleToggleFormGroup</i>` facilitates toggling the form group.</li>
      <li>Parameter `<i>collapsedGroup</i>` contains information about collapsed groups.</li>
      <li>Parameter `<i>handleAddInsurance</i>` responsible for adding new insurance entries.</li>
      <li>Parameter `<i>handleDeleteInsurance</i>` handles the deletion of insurance entries.</li>
      <li>Parameter `<i>handleEditInsurance</i>` manages the editing of existing insurance entries.</li>
      <li>Parameter `<i>insurances</i>` holds the list of insurance-related entities to be displayed within the table.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='Insurances'
      description={description}
      path='src/components/insurance'
    >
      <Box maxWidth={500}>
        <Insurances
          openModal={openModal}
          insurances={insurances}
          updateAction={closeModal}
          createAction={closeModal}
          removeAction={closeModal}
          handleToggleFormGroup={() => {}}
        />
      </Box>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(InsurancesComponent);
