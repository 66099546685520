// components
import { getTelRateOptions } from '../../components/filter/settings';
// helpers/constants
import * as GC from '../../constants';
// report-common
import { getFilterParamsFromColumnSettings } from '../../report-common';
//////////////////////////////////////////////////

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 12,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  allowSelectAll: false,
  expandableItems: false,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

export const columnSettings = {
  'carrierName': {
    width: 200,
    name: 'titles:company-name',
  },
  'dispatchDate': {
    width: 250,
    type: 'date',
    name: 'titles:dispatch-cancel-date',
    filter: {
      type: 'date',
    },
  },
  'tenderResponseTimeMinutes': {
    width: 250,
    name: 'titles:tender-response-time-mins',
    filter: {
      type: 'number',
    },
  },
  'events.eventType': {
    width: 200,
    name: ['titles:stops', 'titles:type'],
  },
  'events.latenessMinutes': {
    width: 200,
    name: ['titles:stops', 'titles:latenessMinutes'],
    filter: {
      type: 'number',
    },
  },
  'telRateStatus': {
    width: 200,
    type: 'enum',
    name: 'titles:load-rate-status',
    filter: {
      type: 'selectMultiple',
      options: getTelRateOptions(),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
  },
  [GC.GRC.MODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:transportation-mode',
  },
  [GC.GRC.EQUIPMENT_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:equipment',
  },
  [GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:service-type',
  },
  [GC.GRC.SERVICES]: {
    width: 250,
    name: 'titles:services',
    filter: {
      type: 'string',
      collection: true,
      value: GC.GRC.SERVICES,
    },
  },
};

export const FILTER_PARAMS = getFilterParamsFromColumnSettings(columnSettings);
