import * as R from 'ramda';
import React from 'react';
// components
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const ErrorMessage = ({ p, message }: Object) => (
  <Box p={p} color={G.getTheme('colors.light.mainRed')}>
    {message}
  </Box>
);

const RequestErrors = (props: Object) => {
  const data = R.pathOr(null, ['errors', 'requestErrors'], props);
  if (R.isNil(data)) return null;
  const message = R.pathOr(G.getWindowLocale('messages:unknown-error', 'Unknown Error'), ['message'], data);

  return (
    <PopperComponent
      type='hover'
      popperZIndex={11}
      position='right-start'
      content={<ErrorMessage p={10} message={message} />}
      borderColor={G.getTheme('listActions.borderColor')}
    >
      <Flex mb='8px' width={154} cursor='pointer' justifyContent='space-between'>
        <ErrorMessage message={message} />
        <Box>{I.warningIcon()}</Box>
      </Flex>
    </PopperComponent>
  );
};

export default RequestErrors;
