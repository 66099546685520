import React from 'react';
import * as R from 'ramda';
import { reduxForm } from 'redux-form';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { withTabs2 } from '../../components/tabs-mui';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature configs
import * as H from './helpers';
import { CONFIG_PAGE_FORM } from './constants';
import TermsAndConditionsDocumentForm from './components/terms-and-conditions-form';
import TransportationModeConfigMappingForm from './components/transportation-mode-mapping-form';
import DropdownConfigFormComponent, { getDefaultDropdownConfigFormComponent } from './components/dropdown-config-modal';
//////////////////////////////////////////////////

export const defaultPageConfigEnhancer = (configGroup: string) => compose(
  withState('defaultActiveTab', 'setDefaultActiveTab', () => H.getActiveTab(configGroup)),
  withTabs2,
  reduxForm({
    form: CONFIG_PAGE_FORM,
    enableReinitialize: true,
  }),
  withHandlers({
    handleFormSubmit: ({ updateConfigsRequest }: Object) => (values: any) => (
      updateConfigsRequest(H.convertFormValuesToConfigs(values))
    ),
    handleClickCancel: ({ history }: Object) => () => history.goBack(),
    handleClickReturnInherited: ({ restoreInheritedByConfigTypeRequest }: Object) => (groupName: string) =>
      restoreInheritedByConfigTypeRequest(groupName),
    handleSetActiveTab: ({ handleSetActiveTab, setActiveConfigTab }: Object) => (value: number) => {
      handleSetActiveTab(value);

      G.callFunctionWithArgs(setActiveConfigTab, value);
    },
  }),
  pure,
);

export const itemPageConfigEnhancer = withHandlers({
  handleRemoveItem: (props: Object) => (item: Object, configName: string) => {
    const { dropdowns, updateConfigsRequest } = props;

    const prevConfigValue = H.getConfigValueFromGroupStore(dropdowns, configName);

    updateConfigsRequest(H.removeDropdownValueFromConfig(item, configName, prevConfigValue));
  },
  handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
    const { openModal } = props;

    const title = R.or(popupTitle, 'titles:add-item');
    const modalContent = getDefaultDropdownConfigFormComponent(configName, props);
    const modal = H.getModalOptions(modalContent, title);

    openModal(modal);
  },
  handleEditItem: (props: Object) => (item: Object, configName: string, popupTitle: string) => {
    const { openModal, dropdowns, closeModal, updateConfigsRequest } = props;

    const modalContent = (
      <DropdownConfigFormComponent
        initialValues={item}
        dropdowns={dropdowns}
        configName={configName}
        shouldUpdateItem={true}
        closeModal={closeModal}
        updateConfigsRequest={updateConfigsRequest}
      />
    );

    const title = R.or(popupTitle, 'titles:edit-item');
    const modal = H.getModalOptions(modalContent, title);

    openModal(modal);
  },
});

export const withTermsAndConditionsDocument = (configGroup: string) => compose(
  withHandlers({
    handleAddTermsAndConditionsDocument: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        createTermsAndConditionsDocumentRequest,
      } = props;

      const component = (
        <TermsAndConditionsDocumentForm
          closeModal={closeModal}
          initialValues={{ configGroup }}
          submitAction={createTermsAndConditionsDocumentRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getAddTitle(['titles:terms-and-conditions-document', 'Terms & Conditions Document']),
        },
      };

      openModal(modal);
    },
    handleEditTermsAndConditionsDocument: (props: Object) => (entity: Object) => {
      const {
        openModal,
        closeModal,
        updateTermsAndConditionsDocumentRequest,
      } = props;

      const initialValues = R.mergeRight(entity, { configGroup, [GC.FIELD_FILE_NAME]: null });

      const component = (
        <TermsAndConditionsDocumentForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={updateTermsAndConditionsDocumentRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getEditTitle(['titles:terms-and-conditions-document', 'Terms & Conditions Document']),
        },
      };

      openModal(modal);
    },
    handleRemoveTermsAndConditionsDocument: ({ removeTermsAndConditionsDocumentRequest }: Object) => (guid: string) =>
      removeTermsAndConditionsDocumentRequest({ guid, configGroup }),
  }),
);

export const withTransportationModeMapping = (configGroup: string) => compose(
  withHandlers({
    handleAddTransportationModeMapping: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        configOptions,
        createOrUpdateTransportationModeConfigMappingRequest,
      } = props;

      const transportationModeOptions = G.ifElse(
        R.equals(configGroup, GC.INTEGRATION_CONFIG_GROUP),
        G.getPropFromObject(GC.GENERAL_MODE_TRANSPORTATION, configOptions),
        G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
          props,
          GC.GENERAL_MODE_TRANSPORTATION,
          true,
        ),
      );

      const component = (
        <TransportationModeConfigMappingForm
          closeModal={closeModal}
          initialValues={{ configGroup }}
          transportationModeOptions={transportationModeOptions}
          submitAction={createOrUpdateTransportationModeConfigMappingRequest}
        />
      );

      const title = G.getAddTitle(['titles:transportation-mode-mapping', 'Transportation Mode Mapping']);

      const modal = {
        p: 15,
        component,
        options: { title },
      };

      openModal(modal);
    },
    handleEditTransportationModeConfigMapping: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        configOptions,
        createOrUpdateTransportationModeConfigMappingRequest,
      } = props;

      const transportationModeOptions = G.ifElse(
        R.equals(configGroup, GC.INTEGRATION_CONFIG_GROUP),
        G.getPropFromObject(GC.GENERAL_MODE_TRANSPORTATION, configOptions),
        G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
          props,
          GC.GENERAL_MODE_TRANSPORTATION,
          true,
        ),
      );

      const component = (
        <TransportationModeConfigMappingForm
          closeModal={closeModal}
          transportationModeOptions={transportationModeOptions}
          initialValues={R.assoc('configGroup', configGroup, initialValues)}
          submitAction={createOrUpdateTransportationModeConfigMappingRequest}
        />
      );

      const title = G.getEditTitle(['titles:transportation-mode-mapping', 'Transportation Mode Mapping']);

      const modal = {
        p: 15,
        component,
        options: { title },
      };

      openModal(modal);
    },
    handleClickReturnInherited: ({ handleClickReturnInherited }: Object) => (groupName: string) => {
      const groupNameToUse = G.ifElse(
        R.and(
          R.equals(configGroup, GC.INTEGRATION_CONFIG_GROUP),
          R.equals(groupName, GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG),
        ),
        GC.CUSTOM_EXTERNAL_TRANSPORTATION_MODE_MAPPING_CONFIG,
        groupName,
      );

      handleClickReturnInherited(groupNameToUse);
    },
    handleRemoveTransportationModeConfigMapping: ({ removeTransportationModeConfigMappingRequest }: Object) => (guid: string) =>
      removeTransportationModeConfigMappingRequest({ guid, configGroup }),
  }),
);
