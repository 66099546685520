import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// feature dispatch details new
import * as C from '../../constants';
import { withAddClaim } from './with-claims';
import { withAddReference } from './with-reference';
import { withAddCustomerRate } from './with-customer-rate';
import { withAddStatusMessage } from './with-status-messages';
import { withAddCustomerInvoice } from './with-customer-invoice';
import {
  createOrUpdateClaimRequest,
  toggleExpandedListsSuccess,
  createOrderReferenceRequest,
  toggleOrderVehicleItemRequest,
  selectOrderCustomerRateRequest,
  createOrderCustomerRateRequest,
  createOrderStatusMessageRequest,
  createOrderCustomerInvoiceRequest,
  createOrderMultipleStatusMessageRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddTableEntity = compose(
  connect(
    null,
    {
      createOrUpdateClaimRequest,
      toggleExpandedListsSuccess,
      createOrderReferenceRequest,
      toggleOrderVehicleItemRequest,
      selectOrderCustomerRateRequest,
      createOrderCustomerRateRequest,
      createOrderStatusMessageRequest,
      createOrderCustomerInvoiceRequest,
      createOrderMultipleStatusMessageRequest,
    },
  ),
  withAddClaim,
  withAddReference,
  withAddCustomerRate,
  withAddStatusMessage,
  withAddCustomerInvoice,
  withHandlers({
    handleLoadMoreEntities: (props: Object) => () => {
      const { load, tableNumber, activeListName, getOrderNotificationListRequest } = props;
      if (R.equals(activeListName, C.ACTIVE_LIST_NOTIFICATIONS)) {
        const loadGuid = G.getGuidFromObject(load);

        return getOrderNotificationListRequest({ loadGuid, tableNumber, loadMoreEntities: true });
      }
    },
    handleAddTableEntity: (props: Object) => (options: Object) => {
      const {
        handleAddClaim,
        activeListName,
        handleAddReference,
        handleAddCustomerRate,
        handleOpenAddStatusMessage,
        handleAddCloCustomerInvoice,
      } = props;

      const handlersMap = {
        [C.ACTIVE_LIST_CLAIMS]: handleAddClaim,
        [C.ACTIVE_LIST_REFERENCES]: handleAddReference,
        [C.ACTIVE_LIST_CUSTOMER_RATES]: handleAddCustomerRate,
        [C.ACTIVE_LIST_STATUS_MESSAGES]: handleOpenAddStatusMessage,
        [C.ACTIVE_LIST_CUSTOMER_INVOICES]: handleAddCloCustomerInvoice,
      };

      handlersMap[activeListName](options);
    },
  }),
  pure,
);
