import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const getOrderTabs = (activeTab: number) => [
  {
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:clo', 'CLO'),
  },
  {
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:pivot-table', 'Pivot Table'),
  },
];
