import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const getBranchConfigsForRatingSuccess = createAction('getBranchConfigsForRatingSuccess');
// margin violation rule
export const removeMarginViolationRuleRequest = createAction('removeMarginViolationRuleRequest');
export const removeMarginViolationRuleSuccess = createAction('removeMarginViolationRuleSuccess');
export const getMarginViolationRuleListRequest = createAction('getMarginViolationRuleListRequest');
export const getMarginViolationRuleListSuccess = createAction('getMarginViolationRuleListSuccess');
export const createOrUpdateMarginViolationRuleRequest = createAction('createOrUpdateMarginViolationRuleRequest');
export const createOrUpdateMarginViolationRuleSuccess = createAction('createOrUpdateMarginViolationRuleSuccess');
