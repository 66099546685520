import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { memo, useMemo, Fragment } from 'react';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
// forms
import { FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { ZOrderWrapper, MainActionButton } from '../../../ui';
// feature fleet-profile
import { FormGroupTitle } from './form-group-title';
import { tableSettings } from '../settings/table-settings';
import { useReportFormGroupOperations } from '../hooks/use-report-form-group-operations';
import {
  makeSelectTotalCountByGroupName,
  makeSelectPaginationByGroupName,
  makeSelectUsedReportByGroupName,
  makeSelectListLoadingByGroupName,
  makeSelectTitleSortValuesByGroupName,
  makeSelectAvailableReportsByGroupName,
  makeSelectTableTitleFiltersByGroupName,
} from '../selectors';
//////////////////////////////////////////////////

const CustomTitleComponent = ({ itemTitleArr, handleCreateOrUpdateItem }: Object) => (
  <MainActionButton ml={20} mb='3px' height={30} onClick={() => handleCreateOrUpdateItem()}>
    {G.getAddTitle(itemTitleArr)}
  </MainActionButton>
);

const useTableProps = (props: Object) => {
  const {
    itemList,
    groupName,
    filterProps,
    columnSettings,
    handleRemoveItem,
    getRemovePermissions,
    handleCreateOrUpdateItem,
    tableCallbackDataProps = [],
    additionalTableSettings = {},
    handleToggleFormGroupTableDetails,
    actionsPicker = ['edit', 'remove'],
  } = props;

  const tableProps = useMemo(() => {
    const actionButtonsMap = {
      edit: {
        iconName: 'pencil',
        action: (guid: string, entity: Object) => handleCreateOrUpdateItem(entity),
      },
      remove: {
        iconName: 'trash',
        action: handleRemoveItem,
        getPermissions: getRemovePermissions,
      },
    };

    return {
      filterProps,
      columnSettings,
      toggle: handleToggleFormGroupTableDetails,
      callbackData: R.pick(tableCallbackDataProps, props),
      actionButtons: R.values(R.pick(actionsPicker, actionButtonsMap)),
      tableSettings: R.mergeRight(tableSettings, additionalTableSettings),
    };
  }, [itemList, groupName]);

  return tableProps;
};

export const ReportFormGroupTable = memo((props: Object) => {
  const {
    margin,
    report,
    itemList,
    groupName,
    reportType,
    filterProps,
    itemTitleArr,
    isOpened = true,
    withoutTitlePanel,
    noExportable = true,
    useSearchableColumns,
    withResizableColumns,
    handleLoadMoreEntities,
    useNewTitleFilterInputs,
    hasFormGroupTitle = true,
  } = props;

  const totalCount = useSelector(makeSelectTotalCountByGroupName(groupName));
  const pagination = useSelector(makeSelectPaginationByGroupName(groupName));
  const listLoading = useSelector(makeSelectListLoadingByGroupName(groupName));
  const selectedReport = useSelector(makeSelectUsedReportByGroupName(groupName));
  const reportList = useSelector(makeSelectAvailableReportsByGroupName(groupName));
  const titleSortValues = useSelector(makeSelectTitleSortValuesByGroupName(groupName));
  const tableTitleFilters = useSelector(makeSelectTableTitleFiltersByGroupName(groupName));

  const {
    handleRemoveItem,
    getItemListRequest,
    handleCreateOrUpdateItem,
    handleToggleFormGroupTableDetails,
    // report
    handleEditReport,
    handleSetReports,
    handleSelectReport,
    handleExportReport,
    handleSetUsedReport,
    handleTableTitleFilter,
    handleUpdateReportRequest,
    handleChangeDefaultReportRequest,
  } = useReportFormGroupOperations(props);

  const usedReport = G.ifElse(R.isNil(reportType), report, R.or(selectedReport, {}));

  const tableProps = useTableProps({
    ...props,
    handleRemoveItem,
    handleCreateOrUpdateItem,
    handleToggleFormGroupTableDetails,
  });

  if (R.and(G.isNilOrEmpty(selectedReport), R.isNotNil(reportType))) return null;

  return (
    <FormGroupWrapper m={margin} isOpened={isOpened}>
      {hasFormGroupTitle && <FormGroupTitle {...props} />}
      {
        isOpened &&
        <Fragment>
          <ZOrderWrapper zIndex='3'>
            {
              R.not(withoutTitlePanel) &&
              <TitlePanel
                type={reportType}
                usedReport={usedReport}
                reportList={reportList}
                withoutQuickFilter={true}
                usePortalForFilters={true}
                noExportable={noExportable}
                setReports={handleSetReports}
                hiddenRightFilterInfo={false}
                selectedReport={selectedReport}
                filterProps={R.values(filterProps)}
                setUsedReport={handleSetUsedReport}
                handleEditReport={handleEditReport}
                getItemListRequest={getItemListRequest}
                handleSelectReport={handleSelectReport}
                exportReportDataRequest={handleExportReport}
                updateReportRequest={handleUpdateReportRequest}
                changeDefaultReportRequest={handleChangeDefaultReportRequest}
                customTitleComponent={G.isNotNilAndNotEmpty(itemTitleArr) ? (
                  <CustomTitleComponent
                    itemTitleArr={itemTitleArr}
                    handleCreateOrUpdateItem={handleCreateOrUpdateItem}
                  />
                ) : <div />}
              />
            }
          </ZOrderWrapper>
          <ZOrderWrapper zIndex='2'>
            <Table
              {...tableProps}
              itemList={itemList}
              report={usedReport}
              loading={listLoading}
              pagination={pagination}
              totalCount={totalCount}
              titleSortValues={titleSortValues}
              tableTitleFilters={tableTitleFilters}
              withResizableColumns={withResizableColumns}
              useSearchableColumns={useSearchableColumns}
              handleTableTitleFilter={handleTableTitleFilter}
              useNewTitleFilterInputs={useNewTitleFilterInputs}
              handleLoadMoreEntities={R.or(handleLoadMoreEntities, getItemListRequest)}
            />
          </ZOrderWrapper>
        </Fragment>
      }
    </FormGroupWrapper>
  );
});
