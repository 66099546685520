import React from 'react';
// components
import HoveringTitle from '../../../components/hovering-title';
// helpers
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const HoveringTitleComponent = () => {
  const description = '<HoveringTitle /> component is designed to render a title that appears as a hovering box.';

  const usage = (
    <ul>
      <li>Parameter `<i>title</i>` is the text displayed as the title in the hovering box.</li>
      <li>Set callback function `<i>handleClick</i>` that triggered upon clicking the hovering title box.</li>
      <li>Parameter `<i>positionConfig</i>` is a configuration object specifying the positioning properties for the hovering box.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='HoveringTitle'
      description={description}
      path='src/components/hovering-title'
    >
      <HoveringTitle
        title={G.getWindowLocale('titles:search', 'Search')}
        positionConfig={{
          top: 30,
          left: '5px',
          width: 'max-content',
        }}
      >
        <Box cursor='pointer'>
          {I.search(G.getTheme('colors.dark.blue'))}
        </Box>
      </HoveringTitle>
    </ComponentStoryWrapper>
  );
};

export default HoveringTitleComponent;
