import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withProps, withHandlers } from 'react-recompose';
// component
import { Map } from '../../../components/map';
import { Directions } from '../../../components/map/components/directions';
import { MarkerWithInfo } from '../../../components/map/components/marker-with-info';
import { StopInfo, StopMarker } from '../../../components/map/components/stop-components';
// feature
import TrackingFormComponent from './tracking-form-component';
// hocs
import { enhanceLocationWithGoogleAutocomplete } from '../../../hocs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  RelativeBox,
  AbsoluteBox,
  IconWrapper,
} from '../../../ui';
// features carrier-portal
import { makeSelectTelMappedEventsForMap } from '../selectors';
import { centerGeoPoint, makeLocationNameTemplateIdString } from '../helpers';
import { defaultTrackingSettings, getInitialValidationSchema } from '../settings/fields-settings';
//////////////////////////////////////////////////

const getContainersTotalInfo = (containers: Array) => {
  const weightInfo = G.calcContainersTotalWeightWithoutQty(containers);

  if (R.isEmpty(weightInfo)) return null;

  return `
    ${R.prop(GC.FIELD_FULL_CONTAINER_WEIGHT, weightInfo)}
    ${R.prop(GC.FIELD_WEIGHT_UOM, weightInfo)}
  `;
};

const getItemsInfo = (items: any) => {
  const weight = G.calcItemsTotalWeightWithoutQty(items);
  const quantity = G.calculateTotalQuantity(items);
  const weightInfo = G.ifElse(
    G.isNotNilAndNotEmpty(R.prop(GC.FIELD_ITEM_WEIGHT, weight)),
    `${R.prop(GC.FIELD_ITEM_WEIGHT, weight)} ${R.prop(GC.FIELD_ITEM_WEIGHT_TYPE, weight)},`,
    '',
  );
  const quantityInfo = G.ifElse(
    G.isNotNilAndNotEmpty(R.prop(GC.FIELD_ITEM_QUANTITY, quantity)),
    `${R.prop(GC.FIELD_ITEM_QUANTITY, quantity)} ${R.prop(GC.FIELD_PACKAGE_TYPE, quantity)}`,
    '',
  );

  return {
    weightInfo,
    quantityInfo,
  };
};

const getItemsTotalInfo = (items: any) => {
  if (G.isString(items)) return items;

  const itemsInfo = getItemsInfo(items);
  const weightInfo = itemsInfo.weightInfo;
  const quantityInfo = itemsInfo.quantityInfo;

  if (R.and(R.isEmpty(weightInfo), R.isEmpty(quantityInfo))) {
    return G.getWindowLocale('titles:no-items', 'No Items');
  }

  return `
    ${weightInfo}
    ${quantityInfo}
  `;
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  events: makeSelectTelMappedEventsForMap(state),
});

const getTitle = ({ eventType }: Object, arrived: boolean) => {
  if (G.isEventTypePickup(eventType)) {
    if (arrived) {
      return G.getWindowLocale('titles:check-in-pickup', 'Check In Pickup', { caseAction: 'upperCase' });
    }

    return G.getWindowLocale('titles:complete-pickup', 'Complete Pickup', { caseAction: 'upperCase' });
  }

  if (G.isEventTypeDrop(eventType)) {
    if (arrived) {
      return G.getWindowLocale('titles:check-in-drop', 'Check In Drop', { caseAction: 'upperCase' });
    }

    return G.getWindowLocale('titles:complete-drop', 'Complete Drop', { caseAction: 'upperCase' });
  }

  return G.getWindowLocale('titles:add-status-message', 'Add Status Message', { caseAction: 'upperCase' });
};

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ stop, events }: Object) => {
    const validStop = R.find(R.propEq(G.getGuidFromObject(stop), GC.FIELD_GUID), events);

    return { stop: validStop };
  }),
  withState('openMobMap', 'setOpenMobMap', false),
  enhanceLocationWithGoogleAutocomplete,
  withHandlers({
    handleAddStatusMessage: (props: Object) => (arrived: boolean) => {
      const {
        stop,
        fields,
        loadGuid,
        openModal,
        closeModal,
        carrierMobView,
        allStatusCodes,
        statusCodeConfigs,
        statusReasonCodes,
        addCarrierStatusMessage,
      } = props;

      const checkInStatusCodes = R.filter(
        R.propEq(GC.STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN, GC.FIELD_STATUS_TYPE),
        allStatusCodes,
      );
      const locationData = {
        ...R.path([GC.SYSTEM_OBJECT_LOCATION], stop),
        [GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID]: R.path([GC.FIELD_GUID], stop),
        [GC.FIELD_ADDRESS]: R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_ADDRESS_1], stop),
        [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: G.ifElse(
          R.equals(R.path(['eventType'], stop), GC.EVENT_TYPE_PICKUP),
          GC.DOCUMENT_PROOF_TYPE_POP,
          GC.DOCUMENT_PROOF_TYPE_POD,
        ),
      };

      const modalContent = (
        <TrackingFormComponent
          fields={fields}
          loadGuid={loadGuid}
          checkInModal={false}
          closeModal={closeModal}
          initialValues={locationData}
          title={getTitle(stop, arrived)}
          carrierMobView={carrierMobView}
          statusReasonCodes={statusReasonCodes}
          defaultValues={defaultTrackingSettings}
          getValidationSchema={getInitialValidationSchema}
          addCarrierStatusMessage={addCarrierStatusMessage}
          statusCodeConfigs={G.ifElse(G.isTrue(arrived), checkInStatusCodes, statusCodeConfigs)}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          width: '100%',
          maxWidth: 768,
          height: '100%',
          movable: false,
        },
      };

      openModal(modal);
    },
  }),
);

const statusMessageBtnStyles = {
  px: 20,
  height: 35,
  fontSize: 16,
  borderRadius: 20,
  cursor: 'pointer',
  fontWeight: 'bolder',
  justifyContent: 'center',
  bg: G.getTheme('colors.cobalt'),
  color: G.getTheme('colors.white'),
  boxShadow: '5px 8px 9px -2px rgb(21,21,21)',
};

const infoWrapperStyles = {
  mb: 15,
  width: '90%',
  p: '10px 5px',
  minWidth: 300,
  minHeight: 40,
  height: 'max-content',
  boxShadow: '6px 5px 18px -3px rgb(21,21,21)',
};

const allowCompleteCheckIn = ({ stop, allowStopCompleteWithoutArrival }: Object, arrived: boolean) => {
  const { status } = stop;

  const stopStatusesForCompleteCheckIn = [
    GC.STOP_STATUS_TYPE_PENDING,
    GC.STOP_STATUS_TYPE_CHECKED_IN,
  ];

  if (G.isTrue(arrived)) {
    return R.includes(status, stopStatusesForCompleteCheckIn);
  }

  const completeStatuses = G.ifElse(
    G.isFalse(allowStopCompleteWithoutArrival),
    R.of(Array, GC.STOP_STATUS_TYPE_CHECKED_IN),
    stopStatusesForCompleteCheckIn,
  );

  return R.includes(status, completeStatuses);
};

const locationsWithContent = (locations: Array) => (
  locations.map((oldLocation: Object) => ({
    ...oldLocation,
    infoContent: <StopInfo {...oldLocation} />,
    markerContent: <StopMarker {...oldLocation} />,
  }))
);

const renderLocationsWithContent = (oldLocation: Object) => (
  {
    ...oldLocation,
    markerContent: (<div />),
  }
);

const renderStatusMessageMarker = (props: Object) => {
  const values = R.or(
    R.path(['values'], props),
    R.path(['statusMessageForm', 'values'], props),
  );
  const hasLatLng = R.or(
    G.isNotNilAndNotEmpty(R.path([GC.FIELD_LATITUDE], values)),
    G.isNotNilAndNotEmpty(R.path([GC.FIELD_LONGITUDE], values)),
  );
  let locations = locationsWithContent(props.mappedEventsForMap);
  if (hasLatLng) {
    const location = {
      ...values,
      color: G.getTheme('colors.light.mainRed'),
      latLng: { lat: values.latitude, lng: values.longitude },
    };
    locations = R.concat(
      R.of(Array, renderLocationsWithContent(location)),
      locationsWithContent(props.mappedEventsForMap),
    );
  }
  return (
    <MarkerWithInfo
      color={G.getTheme('colors.light.mainRed')}
      locations={G.makeLocationsWithTransform(locations)}
      infoBorderColor={G.getTheme('colors.light.mainRed')}
    />
  );
};

const renderMapComponent = (props: Object, mobMapHeight: string) => (
  <Box
    width='100%'
    height='100%'
    boxShadow='0 0 10px 0 rgba(205, 205, 205, 0.5)'
  >
    <Map
      center={props.center}
      openMobMap={props.openMobMap}
      defaultZoom={props.defaultZoom}
      height={R.or(mobMapHeight, '643px')}
    >
      <Directions
        locations={props.mappedEventsForMap.map((location: Object) => location.latLng)} />
      {renderStatusMessageMarker(props)}
    </Map>
  </Box>
);

export const MobMapComponent = (props: Object) => (
  <Flex
    width='100%'
    minWidth='300px'
    height={R.or(props.height, '100%')}
  >
    {
      R.and(props.mappedEventsForMap, props.isSettedUpStatusLocation)
      && renderMapComponent(props, '100%')
    }
  </Flex>
);

const StopModalComponent = enhance((props: Object) => {
  const {
    stop,
    title,
    closeModal,
    openMobMap,
    setOpenMobMap,
    handleOpenChatModal,
    handleAddStatusMessage,
  } = props;

  const { items, droppedContainers, pickedUpContainers } = stop;
  const withContainers = R.or(G.isNotNilAndNotEmpty(pickedUpContainers), G.isNotNilAndNotEmpty(droppedContainers));

  return (
    <AbsoluteBox
      top='0'
      left='0'
      zIndex={100}
      width='100%'
      height='100%'
      minWidth={300}
      flexDirection='column'
      justifyContent='space-between'
      bg={G.getTheme('colors.white')}
    >
      <Box width='100%' minHeight={310} height='max-content'>
        <Flex
          height={60}
          p='5px 15px'
          width='100%'
          minWidth={300}
          alignItems='center'
          fontWeight='bolder'
          justifyContent='flex-start'
          bg={G.getTheme('colors.cobalt')}
          color={G.getTheme('colors.white')}
        >
          <IconWrapper
            ml={15}
            pr={10}
            mr={30}
            cursor='pointer'
            onClick={() => closeModal()}
          >
            {I.arrowIconMob()}
          </IconWrapper>
          {R.toUpper(title)}
        </Flex>
        <Flex
          p='5px 15px'
          width='100%'
          minWidth={300}
          alignItems='center'
          height='max-content'
          flexDirection='column'
          justifyContent='flex-start'
          bg={G.getTheme('colors.cobalt')}
          color={G.getTheme('colors.white')}
          boxShadow='5px 5px 7px -3px rgb(21,21,21)'
        >
          {
            G.isTrue(withContainers) &&
            <Flex
              {...infoWrapperStyles}
            >
              <IconWrapper pr={10}>
                {I.itemBox(G.getTheme('colors.white'), 20, 20)}
              </IconWrapper>
              {
                getContainersTotalInfo(G.ifElse(
                  R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE, stop),
                  pickedUpContainers,
                  droppedContainers,
                ))
              }
            </Flex>
          }
          {
            G.isNotNilAndNotEmpty(items) &&
            <Flex
              {...infoWrapperStyles}
            >
              <IconWrapper pr={10}>
                {I.boxItem(G.getTheme('colors.white'), 20, 20)}
              </IconWrapper>
              {getItemsTotalInfo(items)}
            </Flex>
          }
          <Flex
            {...infoWrapperStyles}
            bg={G.getTheme('colors.demin')}
          >
            <IconWrapper pr={10}>
              {I.calendar(G.getTheme('colors.white'), 20, 20)}
            </IconWrapper>
            {
              `${G.createLocalDateTimeFromInstanceOrISOString(
                R.path(['stop', GC.FIELD_LOAD_EVENT_EARLY_DATE], props),
                'LLL',
              )} - ${
                G.createLocalDateTimeFromInstanceOrISOString(
                  R.path(['stop', GC.FIELD_LOAD_EVENT_LATE_DATE], props),
                  'LLL',
                )}`
            }
          </Flex>
          <Flex
            {...infoWrapperStyles}
            bg={G.getTheme('colors.demin')}
          >
            <IconWrapper pr={10}>
              {I.onMap(G.getTheme('colors.white'), 20, 20)}
            </IconWrapper>
            {G.renderFullAddressString(R.path(['stop', GC.SYSTEM_OBJECT_LOCATION], props))}
          </Flex>
        </Flex>
        <Flex
          height={55}
          p='5px 15px'
          width='100%'
          minWidth={300}
          alignItems='center'
          justifyContent='center'
          borderBottom='2px solid'
          borderColor={G.ifElse(G.isTrue(openMobMap), 'none', G.getTheme('colors.light.lighterGrey'))}
        >
          {
            allowCompleteCheckIn(props, true) &&
            <Flex
              {...statusMessageBtnStyles}
              mr={20}
              onClick={() => handleAddStatusMessage(true)}
            >
              {G.getWindowLocale('titles:arrived', 'Arrived')}
            </Flex>
          }
          {
            allowCompleteCheckIn(props, false) &&
            <Flex
              {...statusMessageBtnStyles}
              onClick={() => handleAddStatusMessage()}
            >
              {G.getWindowLocale('titles:completed', 'Completed')}
            </Flex>
          }
          {
            R.pathEq(true, ['stop', GC.FIELD_COMPLETED], props) &&
            <Flex
              {...statusMessageBtnStyles}
              onClick={() => handleAddStatusMessage()}
            >
              {G.getWindowLocale('titles:add-status-message', 'Add Status Message')}
            </Flex>
          }
        </Flex>
      </Box>
      {
        openMobMap &&
        <Flex
          width='90%'
          minWidth={300}
          borderRadius={20}
          overflowY='hidden'
          border='1px solid'
          minHeight='calc(100% - 430px)'
          borderColor={G.getTheme('colors.cobalt')}
        >
          <MobMapComponent
            defaultZoom={8}
            openMobMap={openMobMap}
            height='calc(100vh - 430px)'
            isSettedUpStatusLocation={true}
            values={R.path(['stop', GC.SYSTEM_OBJECT_LOCATION], props)}
            mappedEventsForMap={R.of(Array, G.getPropFromObject('stop', props))}
            center={centerGeoPoint(R.path(['stop', GC.SYSTEM_OBJECT_LOCATION], props))}
          />
        </Flex>
      }
      <RelativeBox
        width='100%'
        overflowY='scroll'
        display={G.ifElse(G.isFalse(openMobMap), 'flex', 'none')}
        minHeight={G.ifElse(
          R.and(G.isTrue(withContainers), G.isNotNilAndNotEmpty(items)),
          'calc(100% - 495px)',
          'calc(100% - 440px)',
        )}
      >
        <AbsoluteBox
          top='0'
          left='0'
          width='100%'
          height='100%'
          alignItems='center'
          flexDirection='column'
          justifyContent='flex-start'
        >
          {
            G.isNotNilAndNotEmpty(R.path(['stop', GC.FIELD_LOAD_APPOINTMENT_NUMBER], props)) &&
              <Flex
                width='90%'
                p='7px 15px'
                flexDirection='column'
                alignItems='flex-start'
                borderBottom='2px solid'
                justifyContent='flex-start'
                borderColor={G.getTheme('colors.light.lighterGrey')}
              >
                <Box
                  fontSize={16}
                  fontWeight='bold'
                  color={G.getTheme('colors.cobalt')}
                >
                  {`
                    ${G.getWindowLocale('titles:appointment', 'Appointment')}:
                    ${R.or(R.path(['stop', GC.FIELD_LOAD_APPOINTMENT_NUMBER], props), '')}
                  `}
                </Box>
                <Box
                  mt='7px'
                  fontSize={12}
                  color={G.getTheme('colors.greyMatterhorn')}
                >
                  {
                    G.createLocalDateTimeFromInstanceOrISOString(
                    R.find(
                    (item: string | null) => G.isNotNilAndNotEmpty(item),
                      [
                        R.path(['stop', GC.FIELD_LOAD_APPOINTMENT_DATE], props),
                        R.path(['stop', GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], props),
                        R.path(['stop', GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], props),
                      ]), 'LL')
                  }
                </Box>
              </Flex>
          }
          <Flex
            width='90%'
            p='7px 15px'
            flexDirection='column'
            alignItems='flex-start'
            borderBottom='2px solid'
            justifyContent='flex-start'
            borderColor={G.getTheme('colors.light.lighterGrey')}
          >
            <Box
              mb={10}
              fontSize={12}
              color={G.getTheme('colors.greyMatterhorn')}
            >
              {G.getWindowLocale('titles:contacts', 'Contacts')}
            </Box>
            {
              R.and(
                G.isNotNilAndNotEmpty(R.path(['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS], props)),
                G.isNotNilAndNotEmpty(R.path([GC.FIELD_CONTACT_NAME], R.head(
                  R.path(['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS], props),
                ),
              ))) &&
              <Flex
                width='100%'
                flexDirection='column'
                alignItems='flex-start'
              >
                <Box
                  mb={10}
                  width='100%'
                  fontSize={16}
                  fontWeight='bold'
                  color={G.getTheme('colors.cobalt')}
                >
                  {
                    R.pathOr(
                      '',
                      ['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS, 0, GC.FIELD_CONTACT_NAME],
                      props,
                    )
                  }
                </Box>
                {
                  G.isNotNilAndNotEmpty(R.path(
                    ['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS, 0, GC.FIELD_PHONE],
                    props,
                  )) &&
                  <Box
                    mb={10}
                    fontSize={12}
                    color={G.getTheme('colors.greyMatterhorn')}
                  >
                    {
                      `${G.getWindowLocale('titles:phone', 'Phone')
                      }: ${R.path(['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS, 0, GC.FIELD_PHONE], props)}`
                    }
                  </Box>
                }
                {
                  G.isNotNilAndNotEmpty(R.path(
                    ['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS, 0, GC.FIELD_EMAIL],
                    props,
                  )) &&
                  <Box
                    mb={10}
                    fontSize={12}
                    color={G.getTheme('colors.greyMatterhorn')}

                  >
                    {
                      `${G.getWindowLocale('titles:email', 'Email')
                      }: ${R.path(['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS, 0, GC.FIELD_EMAIL], props)}`
                    }
                  </Box>
                }
                {
                  G.isNotNilAndNotEmpty(R.path(
                    ['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS, 0, GC.FIELD_FAX],
                    props,
                  )) &&
                  <Box
                    mb={10}
                    fontSize={12}
                    color={G.getTheme('colors.greyMatterhorn')}

                  >
                    {
                      `${G.getWindowLocale('titles:fax', 'Fax')
                      }: ${R.path(['stop', GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_CONTACTS, 0, GC.FIELD_FAX], props)}`
                    }
                  </Box>
                }
              </Flex>
            }
          </Flex>
          <Flex
            width='90%'
            p='7px 15px'
            flexDirection='column'
            alignItems='flex-start'
            borderBottom='2px solid'
            justifyContent='flex-start'
            borderColor={G.getTheme('colors.light.lighterGrey')}
          >
            <Box
              mb={10}
              fontSize={12}
              color={G.getTheme('colors.greyMatterhorn')}
            >
              {G.getWindowLocale('titles:locations', 'Location')}
            </Box>
            <Box
              mb={10}
              width='100%'
              fontSize={16}
              fontWeight='bold'
              color={G.getTheme('colors.cobalt')}
            >
              {makeLocationNameTemplateIdString(props)}
            </Box>
            <Flex
              mb={10}
              fontSize={12}
              minWidth={300}
              height='max-content'
              color={G.getTheme('colors.greyMatterhorn')}
            >
              {G.renderFullAddressString(R.path(['stop', GC.SYSTEM_OBJECT_LOCATION], props))}
            </Flex>
            <Box
              fontSize={12}
              wordBreak='break-word'
              color={G.getTheme('colors.greyMatterhorn')}
            >
              {`
                ${G.getWindowLocale('titles:comments', 'Comments')}:
                ${R.pathOr('', ['stop', GC.FIELD_LOCATION, GC.FIELD_COMMENTS], props)}
              `}
            </Box>
          </Flex>
        </AbsoluteBox>
      </RelativeBox>
      <Flex
        width='100%'
        height={120}
        minHeight={120}
        alignItems='center'
        flexDirection='column'
        justifyContent='flex-start'
        bg={G.getTheme('colors.white')}
      >
        <Flex
          mb={15}
          mt='5px'
          width={280}
          height={35}
          fontSize={16}
          minWidth={250}
          cursor='pointer'
          borderRadius={20}
          fontWeight='bolder'
          alignItems='center'
          justifyContent='center'
          bg={G.getTheme('colors.cobalt')}
          color={G.getTheme('colors.white')}
          boxShadow='5px 8px 9px -2px rgb(21,21,21)'
          onClick={() => setOpenMobMap(R.not(openMobMap))}
        >
          <IconWrapper pr={10}>
            {I.onMap(G.getTheme('colors.white'), 20, 20)}
          </IconWrapper>
          {
            G.ifElse(G.isFalse(openMobMap),
              G.getWindowLocale('actions:open-map', 'OPEN MAP', { caseAction: 'upperCase' }),
              G.getWindowLocale('actions:close-map', 'CLOSE MAP', { caseAction: 'upperCase' }),
            )
          }
        </Flex>
        <Flex
          mr='10%'
          width={50}
          height={50}
          cursor='pointer'
          borderRadius='50%'
          alignItems='center'
          alignSelf='flex-end'
          justifyContent='center'
          bg={G.getTheme('colors.cobalt')}
          onClick={() => handleOpenChatModal()}
          boxShadow='5px 8px 9px -2px rgb(21,21,21)'
        >
          <IconWrapper mt='4px'>
            {I.chatIcon()}
          </IconWrapper>
        </Flex>
      </Flex>
    </AbsoluteBox>
  );
});

export default StopModalComponent;
