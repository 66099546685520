import * as R from 'ramda';
import styled from 'styled-components';
// helpers/constants
import * as G from '../../../../helpers';
/////////////////////////////////////////////////

export const PopperComponentWrapper = styled.div`
  cursor: default;
  max-width: 100%;
  & .popper-target {
    cursor: ${({ cursor }: Object) => cursor || 'default'};
    width:  ${({ wrapperWidth }: Object) => wrapperWidth || 'max-content'};;
    max-width: ${({ wrapperMaxWidth }: Object) => wrapperMaxWidth || '100%'};
  }
  &:hover .popper-target * {
    ${({ activeStyles }: Object) => R.or(activeStyles, '')};
  }
`;

export const PopperWrapper = styled.div`
  & .popper {
    width: max-content;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    z-index: ${({ zIndex }: Object) => zIndex || 20};
    max-height: ${({ maxHeight }: Object) => maxHeight};
    overflow: ${({ overflow }: Object) => overflow || 'auto'};
    min-width: ${({ minWidth }: Object) => minWidth || '230px'};
    background-color: ${() => G.getTheme('dispatchBoardPopper.bgColor')};
  }
`;
