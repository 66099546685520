import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  withProps,
  withHandlers,
  renderComponent,
} from 'react-recompose';
// components
import { PageTabs } from '../../../components/page-tabs';
import { PageTitle } from '../../../components/page-title';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import PopupWithReportAndQuickFilters from '../../../components/filter/report-and-quick-filters';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectQuickFilteredParams } from '../../report-format/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectExpandedContainerOpened } from '../../expanded-container/selectors';
import { getCarrierRateRequestReportDataRequest } from '../../carrier-rate-request-report/actions';
import { getReportDataRequest as getCarrierStatisticReportDataRequest } from '../../carrier-statistic-report/actions';
import {
  cleanQuickFilter,
  setQuickFilterParams,
  getQuickFilteredListRequest } from '../../report-format/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//  icons
import * as I from '../../../svgs';
// forms
import { Form } from '../../../forms';
// ui
import {
  Box,
  Flex,
  PageWrapper,
  PageHeaderWrapper,
} from '../../../ui';
// feature carrier
import { renderTab } from './render-tab';
import CarrierContractTab from './carrier-contract-tab';
import { EDIT_GENERAL_DETAILS_FORM } from '../constants';
import CustomerContractTab from './customer-contract-tab';
import GeneralDetailPageActions from './general-detail-header-actions';
import { getCarrierRateFilterParams } from '../settings/filter-params';
import {
  getFormTitle,
  getSelectDropDownFormModal,
  isMultipleLoadBoardSynchronizations,
  carrierLoadBoardSynchronizationSources,
  getAuthorizedCarrierLoadBoardSynchronization,
} from '../helpers';
import {
  makeSelectMailList,
  makeSelectActivePage,
  makeSelectRoleOptions,
  makeSelectCarrierDocs,
  makeSelectCarrierFinance,
  makeSelectCarrierConfigs,
  makeSelectCarrierDetails,
  makeSelectCollapsedGroup,
  makeSelectTerminalFinance,
  makeSelectCarrierContract,
  makeSelectDispatchConfigs,
  makeSelectCustomerContract,
  makeSelectCarrierActiveTab,
  makeSelectCarrierContracts,
  makeSelectCarrierFormValues,
  makeSelectCustomersContract,
  makeSelectCarrierAssessorials,
  makeSelectCarrierPayLocations,
  makeSelectIntegrationInfoList,
  makeSelectCarrierCertificates,
  makeSelectCarrierContractRate,
  makeSelectTerminalPayLocations,
  makeSelectIntegrationAuditList,
  makeSelectContractVariableValues,
  makeSelectAccessorialConfigParams,
  makeSelectCarrierIntegrationOptions,
  makeSelectCarrierContractAwardedLines,
  makeSelectCarrierContractAssessorials,
  makeSelectCustomerContractAwardedLines,
  makeSelectCustomerContractAssessorials,
  makeSelectAvailableForCarrierLoadBoards,
  makeSelectLoadBoardSynchronizationParams,
} from '../selectors';
import {
  setActiveTab,
  setActivePage,
  createWatchRequest,
  setTerminalDetails,
  updateCarrierRequest,
  getCarrierDocsRequest,
  updateTerminalRequest,
  toggleCarrierFormGroup,
  createCarrierDocRequest,
  updateCarrierDocRequest,
  deleteCarrierDocRequest,
  addContractVariableValues,
  getCarrierContractRequest,
  getCarrierContractSuccess,
  getCarrierMailListRequest,
  selectCarrierContractRate,
  getCustomerContractSuccess,
  getCarrierContractsRequest,
  editContractVariableValues,
  downloadCarrierMailRequest,
  getCustomerContractRequest,
  getCustomersContractRequest,
  getDispatchConfigListRequest,
  deleteContractVariableValues,
  createCarrierContractRequest,
  updateCarrierContractRequest,
  deleteCarrierContractRequest,
  updateCustomerContractRequest,
  getCarrierPayLocationsRequest,
  getCarrierAssessorialsRequest,
  getCarrierCertificatesRequest,
  deleteCustomerContractRequest,
  getTerminalPayLocationsRequest,
  getCarrierContractRatesSuccess,
  getTerminalPayLocationsSuccess,
  getCarrierContractRatesRequest,
  addOrRemoveCarrierWatchRequest,
  getAccessorialConfigListRequest,
  createCarrierAssessorialRequest,
  updateCarrierAssessorialRequest,
  deleteCarrierAssessorialRequest,
  getAdditionalContactListRequest,
  createCarrierPayLocationRequest,
  updateCarrierPayLocationRequest,
  createCarrierCertificateRequest,
  updateCarrierCertificateRequest,
  deleteCarrierPayLocationRequest,
  deleteCarrierCertificateRequest,
  createCarrierContractRateRequest,
  updateCarrierContractRateRequest,
  deleteCarrierContractRateRequest,
  createTerminalPayLocationRequest,
  getCarrierPaymentTermListRequest,
  updateTerminalPayLocationRequest,
  deleteTerminalPayLocationRequest,
  removeCarrierContractRatesRequest,
  updateCarrierGeneralDetailsRequest,
  addOrRemoveToNetworkCarrierRequest,
  createCarrierRatePriceExportRequest,
  allowOrForbidBookItNowCarrierRequest,
  addOrRemoveSharedAccessorialsRequest,
  getCarrierContractAwardedLineRequest,
  getCarrierContractAwardedLinesRequest,
  getCarrierContractAssessorialsRequest,
  resetCarrierContractListAndPagination,
  getCarrierContractAssessorialsSuccess,
  getCarrierContractAwardedLinesSuccess,
  getCustomerContractAssessorialsRequest,
  getAvailableCarrierIntegrationsRequest,
  getCustomerContractAssessorialsSuccess,
  handleSendCreateCarrierContractSuccess,
  getContractBillToByContractTypeRequest,
  getCustomerContractAwardedLinesSuccess,
  getCustomerContractAwardedLinesRequest,
  handleSendCreateCustomerContractSuccess,
  createCarrierContractAssessorialRequest,
  updateCarrierContractAssessorialRequest,
  deleteCarrierContractAssessorialRequest,
  createCarrierContractAwardedLineRequest,
  updateCarrierContractAwardedLineRequest,
  deleteCarrierContractAwardedLineRequest,
  createCustomerContractAwardedLineRequest,
  updateCustomerContractAwardedLineRequest,
  deleteCustomerContractAwardedLineRequest,
  createCustomerContractAssessorialRequest,
  updateCustomerContractAssessorialRequest,
  deleteCustomerContractAssessorialRequest,
} from '../actions';
//////////////////////////////////////////////////

const getCarrierGuid = R.path(['formValues', GC.FIELD_GUID]);

const carrierRightNav = (props: Object) => ([
  {
    frontIcon: G.ifElse(
      R.equals(props.activeTab, 'general'),
      I.searchDocument(G.getTheme('icons.iconColor')),
      I.searchDocument(G.getTheme('icons.iconColorGrey')),
    ),
    tabName: 'general',
    label: G.getWindowLocale('titles:general-details', 'General Details'),
    action: (tabName: string) => (
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
      })
    ),
  },
  {
    frontIcon: G.ifElse(
      R.equals(props.activeTab, 'docs'),
      I.documents(G.getTheme('icons.iconColor')),
      I.documents(G.getTheme('icons.iconColorGrey')),
    ),
    tabName: 'docs',
    label: G.getWindowLocale('titles:documents', 'Documents'),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () => {
          props.getCarrierDocsRequest(getCarrierGuid(props));
          props.getCarrierMailListRequest(getCarrierGuid(props));
        },
      });
    },
  },
  {
    frontIcon: G.ifElse(
      R.equals(props.activeTab, 'certificate'),
      I.toxic(G.getTheme('icons.iconColor')),
      I.toxic(G.getTheme('icons.iconColorGrey')),
    ),
    tabName: 'certificate',
    label: G.getWindowLocale('titles:certificate', 'Certificates'),
    action: (tabName: string) => (
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () =>
          props.getCarrierCertificatesRequest(getCarrierGuid(props)),
      })
    ),
  },
  {
    tabName: 'additionalContacts',
    label: G.getWindowLocale('titles:additional-contacts', 'Additional Contacts'),
    frontIcon: I.contact(
      G.getThemeByCond(R.equals(props.activeTab, 'additionalContacts'), 'icons.iconColor', 'icons.iconColorGrey'),
      17,
      20,
    ),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () => props.getAdditionalContactListRequest(),
      });
    },
  },
  {
    frontIcon: G.ifElse(
      R.equals(props.activeTab, 'carrierFinance'),
      I.payment(G.getTheme('icons.iconColor')),
      I.payment(G.getTheme('icons.iconColorGrey')),
    ),
    label: G.getWindowLocale('titles:rating-engine', 'Rating Engine'),
    tabName: 'carrierFinance',
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () => {
          props.getCarrierContractsRequest();
          props.getCustomersContractRequest();
          props.getAccessorialConfigListRequest();
          props.getCarrierAssessorialsRequest(getCarrierGuid(props));
          props.getCarrierPaymentTermListRequest(getCarrierGuid(props));
          props.getCarrierPayLocationsRequest(props.carrierFinance.guid);
        },
      });
    },
  },
  {
    tabName: 'carrierRateRequest',
    label: G.getWindowLocale('titles:bid-history', 'Bid History'),
    frontIcon: I.gearSolid(G.getThemeByCond(
      R.equals(props.activeTab, 'carrierRateRequest'),
      'icons.iconColor',
      'icons.iconColorGrey',
    )),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () => props.getCarrierRateRequestReportDataRequest(getCarrierGuid(props)),
      });
    },
  },
  {
    tabName: 'carrierStatistic',
    label: G.getWindowLocale('titles:statistic', 'Statistic'),
    frontIcon: I.analytic(G.getThemeByCond(
      R.equals(props.activeTab, 'carrierStatistic'),
      'icons.iconColor',
      'icons.iconColorGrey',
    ), 25, 25),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () => props.getCarrierStatisticReportDataRequest(getCarrierGuid(props)),
      });
    },
  },
  {
    tabName: 'scorecards',
    label: G.getWindowLocale('titles:scorecards', 'Scorecards'),
    frontIcon: I.scorecards(
      G.getThemeByCond(R.propEq('scorecards', 'activeTab', props), 'icons.iconColor', 'icons.iconColorGrey'),
      25,
      25,
    ),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        tabAction: () => null,
        formName: EDIT_GENERAL_DETAILS_FORM,
      });
    },
  },
]);

const terminalRightNav = (props: Object) => ([
  {
    frontIcon: G.ifElse(
      R.equals(props.activeTab, 'terminalGeneral'),
      I.searchDocument(G.getTheme('icons.iconColor')),
      I.searchDocument(G.getTheme('icons.iconColorGrey')),
    ),
    tabName: 'terminalGeneral',
    label: G.getWindowLocale('titles:terminal-details', 'Terminal Details'),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
      });
    },
  },
  {
    frontIcon: G.ifElse(
      R.equals(props.activeTab, 'terminalProcess'),
      I.moneyWithArrow(G.getTheme('icons.iconColor')),
      I.moneyWithArrow(G.getTheme('icons.iconColorGrey')),
    ),
    tabName: 'terminalProcess',
    label: G.getWindowLocale('titles:email-automation', 'Email Automation'),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () => {
          if (G.isNilOrEmpty(props.dispatchConfigs)) props.getDispatchConfigListRequest();
        },
      });
    },
  },
  {
    frontIcon: G.ifElse(
      R.equals(props.activeTab, 'terminalFinance'),
      I.payment(G.getTheme('icons.iconColor')),
      I.payment(G.getTheme('icons.iconColorGrey')),
    ),
    tabName: 'terminalFinance',
    label: G.getWindowLocale('titles:finance-info', 'Finance Info'),
    action: (tabName: string) => {
      props.setActiveTab({
        tabName,
        touch: props.touch,
        formName: EDIT_GENERAL_DETAILS_FORM,
        tabAction: () => (
          props.getTerminalPayLocationsRequest(props.terminalFinance.guid)
        ),
      });
    },
  },
]);

const enhance = compose(
  branch(
    (props: Object) => (
      R.not(props.initialDataLoaded)
      || R.isNil(props.initialValues.guid,
    )),
    renderComponent(() => <Box width='100%' height='100%' bg='white' />),
  ),
  reduxForm({
    change,
    enableReinitialize: true,
    form: EDIT_GENERAL_DETAILS_FORM,
  }),
  withProps(({ formValues }: Object) => {
    const selectedTrMode = R.map((item: Object) =>
      R.pathOr(item, [GC.FIELD_DROPDOWN_OPTION_GUID], item),
      R.pathOr([], [GC.FIELD_CARRIER_TRANSPORTATION_MODES], formValues),
    );

    return { selectedTrMode };
  }),
  withState('selectedEquipment', 'setEquipment', ({ initialValues }: Object) => (
    R.pathOr([], ['equipments'], initialValues).map(
      ({ dropdownOptionGuid }: Object) => dropdownOptionGuid)
  )),
  withHandlers({
    handleToggleFormGroup: ({ toggleCarrierFormGroup }: Object) => (fieldName: string) => (
      toggleCarrierFormGroup(fieldName)
    ),
    handleFormSubmit: (props: Object) => (values: Object) => {
      const {
        activePage,
        terminalFinance,
        updateCarrierRequest,
        updateTerminalRequest,
        closeExpandedContainer,
      } = props;

      if (R.equals(activePage, 'general')) {
        return updateCarrierRequest(R.assoc('closeExpandedContainer', closeExpandedContainer, values));
      }

      let terminal = values.terminal;

      const dispatchProcessCondition = R.pathEq(
        GC.TERMINAL_PROCESS_TYPE_API,
        [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE],
        terminal,
      );

      const statusCheckProcessCondition = R.pathEq(
        GC.TERMINAL_PROCESS_TYPE_API,
        [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS, GC.FIELD_TYPE],
        terminal,
      );

      if (dispatchProcessCondition) {
        const dispatchProcessValues = R.compose(
          R.pick([
            GC.FIELD_TERMINAL_PROCESS_TYPE,
            GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID,
            GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
          ]),
          R.path([GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]),
        )(terminal);

        terminal = R.assocPath([GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS], dispatchProcessValues, terminal);
      } else {
        terminal = R.dissocPath(
          [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID],
          terminal,
        );
      }
      if (statusCheckProcessCondition) {
        const statusCheckProcessValues = R.compose(
          R.pick([
            GC.AUTO_STATUS_CHECK_ENABLED,
            GC.FIELD_TERMINAL_PROCESS_TYPE,
            GC.FIELD_TERMINAL_PROCESS_ENABLED,
            GC.FIELD_TERMINAL_STATUS_CHECK_FREQUENCY,
            GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID,
          ]),
          R.path([GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]),
        )(terminal);

        terminal = R.assocPath([GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS], statusCheckProcessValues, terminal);
      } else {
        terminal = R.dissocPath(
          [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS, GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID],
          terminal,
        );
      }

      if (R.pathEq(
        GC.TERMINAL_PROCESS_TYPE_EDI,
        [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE],
        terminal,
      )) {
        terminal = {
          ...R.omit(
            [
              GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS,
              GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS,
              GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS,
            ],
            terminal,
          ),
          [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: {
            [GC.FIELD_TYPE]: GC.TERMINAL_PROCESS_TYPE_EDI,
          },
        };
      }

      if (R.pathEq(
        GC.TERMINAL_PROCESS_TYPE_INTERNAL,
        [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE],
        terminal,
      )) {
        terminal = {
          ...R.omit(
            [
              GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS,
              GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS,
              GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS,
            ],
            terminal,
          ),
          [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: {
            [GC.FIELD_TYPE]: GC.TERMINAL_PROCESS_TYPE_INTERNAL,
          },
        };
      }

      const reqBody = {
        ...terminal,
        terminalFinance: {
          ...terminalFinance,
          ...values.terminalFinance,
        },
      };

      updateTerminalRequest(reqBody);
    },
    handleSubmitAndStay: (props: Object) => () => (
      props.updateCarrierRequest(R.assoc('stayOnPage', true, props.formValues))
    ),
    handleTabNav: (props: Object) => () => {
      if (R.equals(props.activePage, 'terminal')) {
        return terminalRightNav(props);
      }

      return carrierRightNav(props);
    },
    handleTerminalClickCancel: (props: Object) => () => {
      props.setActiveTab({
        tabName: 'general',
        isCancel: true,
        tabAction: () => {
          props.change('terminal', {});
          props.setActivePage('general');
          props.change('terminalFinance', {});
          props.getTerminalPayLocationsSuccess([]);
        },
      });
    },
    handleCleanFilter: (props: Object) => () => {
      props.cleanQuickFilter();
      props.getCarrierContractRatesRequest();
      props.resetCarrierContractListAndPagination();
    },
    handleClickCancel: (props: Object) => () => {
      const {
        history,
        closeExpandedContainer,
        expandedContainerOpened,
      } = props;

      if (G.isTrue(expandedContainerOpened)) return closeExpandedContainer();

      history.goBack();
    },
    handleChangeDPEmailTo: (props: Object) => (id: any, value: Array) => (
      props.change(
        `${GC.SYSTEM_OBJECT_TERMINAL}.${GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS}.${GC.FIELD_EMAIL_TO}`,
        value,
      )
    ),
    handleChangeQPEmailTo: (props: Object) => (id: any, value: Array) => (
      props.change(
        `${GC.SYSTEM_OBJECT_TERMINAL}.${GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS}.${GC.FIELD_EMAIL_TO}`,
        value,
      )
    ),
    handleChangeSCPEmailTo: (props: Object) => (id: any, value: Array) => (
      props.change(
        `${GC.SYSTEM_OBJECT_TERMINAL}.${GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.${GC.FIELD_EMAIL_TO}`,
        value,
      )
    ),
    handleSendOnboardingPackage: (props: Object) => () => {
      const {
        formValues,
        sendPackage,
        updateCarrierRequest } = props;
      if (G.isFalse(sendPackage)) {
        const message = G.getWindowLocale(
          'messages:carrier-onboarding-package-is-not-configured',
          'Carrier onboarding package is not configured',
        );
        return G.showToastrMessageSimple('info', message);
      }
      if (G.isNilOrEmpty(R.prop(GC.FIELD_EMAIL, formValues))) {
        const message = G.getWindowLocale('messages:enter-email', 'Please, enter email');
        return G.showToastrMessageSimple('info', message);
      }
      const carrier = R.mergeRight(
        formValues,
        {
          stayOnPage: true,
          sendPackage: true,
        },
      );
      updateCarrierRequest(carrier);
    },
    handleGetCarrierSynchronizationAction: (props: Object) => (actionName: string, propName: string) => {
      const {
        openModal,
        closeModal,
        formValues,
        availableForCarrierLoadBoards,
        loadBoardSynchronizationParams,
      } = props;

      const args = {
        [GC.FIELD_CARRIER_GUID]: G.getGuidFromObject(formValues),
        [propName]: R.not(G.getPropFromObject(propName, loadBoardSynchronizationParams)),
      };
      const synchronizationAction = (source: string) => G.callFunctionWithArgs(
        G.getPropFromObject(actionName, props),
        R.assoc(GC.FIELD_SOURCE, source, args),
      );

      if (isMultipleLoadBoardSynchronizations(availableForCarrierLoadBoards)) {
        let options = carrierLoadBoardSynchronizationSources;

        if (R.equals(actionName, 'allowOrForbidBookItNowCarrierRequest')) {
          options = R.reject(R.propEq(GC.EXTERNAL_LOAD_BOARD_NEWTRUL, GC.FIELD_VALUE), options);
        }

        const componentProps = {
          options,
          fieldWidth: 300,
          optionRequired: true,
          cancelAction: closeModal,
          fieldLabel: G.getWindowLocale('titles:source', 'Source'),
          submitAction: (source: string) => {
            closeModal();
            synchronizationAction(source);
          },
        };
        const modal = getSelectDropDownFormModal({ width: 330 }, componentProps);

        return openModal(modal);
      }

      return synchronizationAction(getAuthorizedCarrierLoadBoardSynchronization(availableForCarrierLoadBoards));
    },
  }),
  pure,
);

const PageHeader = (props: Object) => (
  <PageHeaderWrapper
    zI={13}
    aI='center'
    position='relative'
    justify='flex-start'
  >
    <Flex width='max-content' whiteSpace='nowrap'>
      <PageTitle
        status={true}
        showStatus={true}
        title={getFormTitle(props)}
      />
      {
        R.equals(props.activeTab, 'general') &&
        <GeneralDetailPageActions
          change={props.change}
          formValues={props.formValues}
          createWatchRequest={props.createWatchRequest}
          handleSendOnboardingPackage={props.handleSendOnboardingPackage}
          availableForCarrierLoadBoards={props.availableForCarrierLoadBoards}
          addOrRemoveCarrierWatchRequest={props.addOrRemoveCarrierWatchRequest}
          loadBoardSynchronizationParams={props.loadBoardSynchronizationParams}
          addOrRemoveToNetworkCarrierRequest={props.addOrRemoveToNetworkCarrierRequest}
          updateCarrierGeneralDetailsRequest={props.updateCarrierGeneralDetailsRequest}
          allowOrForbidBookItNowCarrierRequest={props.allowOrForbidBookItNowCarrierRequest}
          handleGetCarrierSynchronizationAction={props.handleGetCarrierSynchronizationAction}
        />
      }
    </Flex>
    <Flex width='50%' maxWidth='50%' whiteSpace='nowrap'>
      {
        G.isNotNilAndNotEmpty(props.filterParams) &&
        <PopupWithReportAndQuickFilters
          version={2}
          filterCount={1}
          popperWithCount={true}
          filteredParams={props.filterParams}
          cleanFilter={props.handleCleanFilter}
          filterProps={G.setFilterByParams(props.refList, getCarrierRateFilterParams)}
        />
      }
    </Flex>
  </PageHeaderWrapper>
);

const CarrierEditComponent = (props: Object) => (
  <PageWrapper pl={G.ifElse(G.isTrue(props.expandedContainerOpened), 20, null)}>
    {
      G.notContain(props.activeTab, ['scorecards', 'carrierStatistic', 'carrierRateRequest']) &&
      <PageHeader {...props} />
    }
    {
      G.notContain(props.activeTab, ['carrierContract', 'customerContract']) &&
      <PageTabs
        zI={14}
        height={230}
        withAudit={true}
        isLabelExist={true}
        activeTab={props.activeTab}
        tabs={props.handleTabNav()}
        setActiveTab={props.setActiveTab}
      />
    }
    <Form
      minHeight='none'
      onSubmit={props.handleSubmit(props.handleFormSubmit)}
    >
      {renderTab(props)}
    </Form>
    {
      R.equals(props.activeTab, 'carrierContract') &&
      <CarrierContractTab
        {...props}
        groupName='contractGeneral'
        currentBranchGuid={props.currentBranchGuid}
        equipments={props.initialValues.equipments}
        branchGuid={props.initialValues.enterpriseGuid}
      />
    }
    {
      R.equals(props.activeTab, 'customerContract') &&
      <CustomerContractTab
        {...props}
        groupName='contractGeneral'
        currentBranchGuid={props.currentBranchGuid}
        equipments={props.initialValues.equipments}
        branchGuid={props.initialValues.enterpriseGuid}
      />
    }
  </PageWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  mailList: makeSelectMailList(state),
  activePage: makeSelectActivePage(state),
  roleOptions: makeSelectRoleOptions(state),
  carrierDocs: makeSelectCarrierDocs(state),
  activeTab: makeSelectCarrierActiveTab(state),
  initialValues: makeSelectCarrierDetails(state),
  formValues: makeSelectCarrierFormValues(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  carrierConfigs: makeSelectCarrierConfigs(state),
  carrierFinance: makeSelectCarrierFinance(state),
  carrierContract: makeSelectCarrierContract(state),
  dispatchConfigs: makeSelectDispatchConfigs(state),
  terminalFinance: makeSelectTerminalFinance(state),
  assessorials: makeSelectCarrierAssessorials(state),
  filterParams: makeSelectQuickFilteredParams(state),
  carrierContracts: makeSelectCarrierContracts(state),
  customerContract: makeSelectCustomerContract(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  customersContract: makeSelectCustomersContract(state),
  integrationInfoList: makeSelectIntegrationInfoList(state),
  carrierContractRate: makeSelectCarrierContractRate(state),
  carrierCertificates: makeSelectCarrierCertificates(state),
  carrierPayLocations: makeSelectCarrierPayLocations(state),
  awardedLines: makeSelectCarrierContractAwardedLines(state),
  terminalPayLocations: makeSelectTerminalPayLocations(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  integrationAuditList: makeSelectIntegrationAuditList(state),
  contractVariableValues: makeSelectContractVariableValues(state),
  accessorialConfigParams: makeSelectAccessorialConfigParams(state),
  expandedContainerOpened: makeSelectExpandedContainerOpened(state),
  contractAssessorials: makeSelectCarrierContractAssessorials(state),
  carrierIntegrationOptions: makeSelectCarrierIntegrationOptions(state),
  customerContractAssessorials: makeSelectCustomerContractAssessorials(state),
  customerContractAwardedLines: makeSelectCustomerContractAwardedLines(state),
  availableForCarrierLoadBoards: makeSelectAvailableForCarrierLoadBoards(state),
  loadBoardSynchronizationParams: makeSelectLoadBoardSynchronizationParams(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  setActiveTab,
  setActivePage,
  cleanQuickFilter,
  createWatchRequest,
  setTerminalDetails,
  updateCarrierRequest,
  setQuickFilterParams,
  getCarrierDocsRequest,
  updateTerminalRequest,
  toggleCarrierFormGroup,
  createCarrierDocRequest,
  updateCarrierDocRequest,
  deleteCarrierDocRequest,
  getCarrierContractSuccess,
  getCarrierContractRequest,
  selectCarrierContractRate,
  addContractVariableValues,
  getCarrierMailListRequest,
  getCustomerContractRequest,
  editContractVariableValues,
  getCarrierContractsRequest,
  downloadCarrierMailRequest,
  getCustomerContractSuccess,
  getCustomersContractRequest,
  getQuickFilteredListRequest,
  deleteContractVariableValues,
  updateCarrierContractRequest,
  createCarrierContractRequest,
  deleteCarrierContractRequest,
  getDispatchConfigListRequest,
  deleteCustomerContractRequest,
  getCarrierAssessorialsRequest,
  getCarrierCertificatesRequest,
  getCarrierPayLocationsRequest,
  updateCustomerContractRequest,
  addOrRemoveCarrierWatchRequest,
  getCarrierContractRatesSuccess,
  getCarrierContractRatesRequest,
  getTerminalPayLocationsSuccess,
  getTerminalPayLocationsRequest,
  createCarrierAssessorialRequest,
  getAdditionalContactListRequest,
  updateCarrierAssessorialRequest,
  deleteCarrierAssessorialRequest,
  createCarrierPayLocationRequest,
  updateCarrierPayLocationRequest,
  deleteCarrierPayLocationRequest,
  getAccessorialConfigListRequest,
  createCarrierCertificateRequest,
  updateCarrierCertificateRequest,
  deleteCarrierCertificateRequest,
  createCarrierContractRateRequest,
  updateCarrierContractRateRequest,
  deleteCarrierContractRateRequest,
  createTerminalPayLocationRequest,
  updateTerminalPayLocationRequest,
  deleteTerminalPayLocationRequest,
  getCarrierPaymentTermListRequest,
  removeCarrierContractRatesRequest,
  updateCarrierGeneralDetailsRequest,
  addOrRemoveToNetworkCarrierRequest,
  createCarrierRatePriceExportRequest,
  addOrRemoveSharedAccessorialsRequest,
  getCarrierContractAwardedLineRequest,
  allowOrForbidBookItNowCarrierRequest,
  getCarrierStatisticReportDataRequest,
  getCarrierContractAssessorialsSuccess,
  getCarrierContractAwardedLinesSuccess,
  getCarrierContractAwardedLinesRequest,
  getCarrierContractAssessorialsRequest,
  resetCarrierContractListAndPagination,
  getCustomerContractAssessorialsRequest,
  getCustomerContractAssessorialsSuccess,
  getCustomerContractAwardedLinesSuccess,
  getContractBillToByContractTypeRequest,
  getCustomerContractAwardedLinesRequest,
  getCarrierRateRequestReportDataRequest,
  handleSendCreateCarrierContractSuccess,
  getAvailableCarrierIntegrationsRequest,
  handleSendCreateCustomerContractSuccess,
  createCarrierContractAssessorialRequest,
  updateCarrierContractAssessorialRequest,
  deleteCarrierContractAssessorialRequest,
  createCarrierContractAwardedLineRequest,
  updateCarrierContractAwardedLineRequest,
  deleteCarrierContractAwardedLineRequest,
  createCustomerContractAwardedLineRequest,
  updateCustomerContractAwardedLineRequest,
  deleteCustomerContractAwardedLineRequest,
  createCustomerContractAssessorialRequest,
  updateCustomerContractAssessorialRequest,
  deleteCustomerContractAssessorialRequest,
})(enhance(CarrierEditComponent));
