import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose } from 'react-recompose';
// components
import { FormGroupTable } from '../../../../components/form-group-table';
// helpers/constants
import * as G from '../../../../helpers';
// feature fleet
import { withAddOrEditDocument } from '../../hocs';
// feature fleet/vendor
import { documentColumnSettings } from '../settings/column-settings';
import { makeSelectVendorDocuments, makeSelectVendorConfigs } from '../selectors';
import {
  createVendorDocumentRequest,
  updateVendorDocumentRequest,
  removeVendorDocumentRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withAddOrEditDocument({ fleetType: 'vendor' }),
  pure,
);

const EditVendorDocsComponent = (props: Object) => (
  <FormGroupTable
    groupName='documents'
    entities={props.documents}
    fields={props.collapsedGroup}
    handleAddClick={props.handleAddDoc}
    handleEditRow={props.handleEditDoc}
    entitiesFields={documentColumnSettings}
    handleDeleteRow={props.handleDeleteDoc}
    isOpened={R.path(['collapsedGroup', 'documents'], props)}
    panelTitle={G.getWindowLocale('titles:documents', 'Documents')}
    handleToggleFormGroup={() => props.handleToggleFormGroup('documents')} />
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  documents: makeSelectVendorDocuments(state),
  vendorConfigs: makeSelectVendorConfigs(state),
});

export default connect(mapStateToProps, {
  removeVendorDocumentRequest,
  createVendorDocumentRequest,
  updateVendorDocumentRequest,
})(enhance(EditVendorDocsComponent));
