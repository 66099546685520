import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportReducers } from '../../report-common';
// feature fuel-cards
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  truckList: {},
  driverList: {},
  truckGuid: null,
  filterProps: [],
  driverGuid: null,
  driverPayrollNumberList: {},
  vendorPayrollNumberList: {},
  driverAssignmentsTrucks: {},
  showUnemployedDrivers: false,
  showIsNotInServiceTrucks: false,
};

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setTruckGuid = (state: Object, truckGuid: string) => P.$set('truckGuid', truckGuid, state);

const setDriverGuid = (state: Object, driverGuid: string) => P.$set('driverGuid', driverGuid, state);

const getLinesByFuelCardGuidSuccess = (state: Object, { guid, lines }: Object) => (
  P.$set(`itemList.${guid}.lines`, lines, state)
);

const toggleFuelCardDetails = (state: Object, { guid }: Object) => (
  P.$set(`itemList.${guid}.expanded`, R.not(R.path(['itemList', guid, 'expanded'], state)), state)
);

const receivedDriverListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set('driverList', {}, state);

  const indexedDriverList = R.indexBy(R.prop(GC.FIELD_GUID), data);

  return P.$set('driverList', indexedDriverList, state);
};

const getTruckListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const indexedList = R.indexBy(R.prop(GC.FIELD_GUID), data);

  return P.$set('truckList', indexedList, state);
};

const receivedDriverPayrollNumberListByGuidsSuccess = (state: Object, data: Array) => {
  const { driverPayrollNumberList } = state;
  const mappedList = R.compose(
    R.map(({ payrollNumber }: Object) => payrollNumber),
    R.indexBy(R.prop(GC.FIELD_GUID)),
  )(data);
  const newList = R.mergeRight(driverPayrollNumberList, mappedList);

  return P.$set('driverPayrollNumberList', newList, state);
};

const receivedVendorPayrollNumberListByGuidsSuccess = (state: Object, data: Array) => {
  const { vendorPayrollNumberList } = state;
  const mappedList = R.compose(
    R.map(({ payrollNumber }: Object) => payrollNumber),
    R.indexBy(R.prop(GC.FIELD_GUID)),
  )(data);
  const newList = R.mergeRight(vendorPayrollNumberList, mappedList);

  return P.$set('vendorPayrollNumberList', newList, state);
};

export const setDriverAssignmentsTrucks = (state: Object, { truckGuid, driverGuid }: Array) => (
  P.$set(`driverAssignmentsTrucks.${driverGuid}`, truckGuid, state)
);

const toggleShowUnemployedDrivers = (state: Object) => P.$toggle('showUnemployedDrivers', state);

const toggleShowIsNotInServiceTrucks = (state: Object) => P.$toggle('showIsNotInServiceTrucks', state);

export default createReducer({
  [A.setReports]: setReports,
  [A.setTruckGuid]: setTruckGuid,
  [A.setDriverGuid]: setDriverGuid,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.getTruckListSuccess]: getTruckListSuccess,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.toggleFuelCardDetails]: toggleFuelCardDetails,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.receivedDriverListSuccess]: receivedDriverListSuccess,
  [A.setDriverAssignmentsTrucks]: setDriverAssignmentsTrucks,
  [A.toggleShowUnemployedDrivers]: toggleShowUnemployedDrivers,
  [A.getLinesByFuelCardGuidSuccess]: getLinesByFuelCardGuidSuccess,
  [A.toggleShowIsNotInServiceTrucks]: toggleShowIsNotInServiceTrucks,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  [A.receivedDriverPayrollNumberListByGuidsSuccess]: receivedDriverPayrollNumberListByGuidsSuccess,
  [A.receivedVendorPayrollNumberListByGuidsSuccess]: receivedVendorPayrollNumberListByGuidsSuccess,
}, initialState);
