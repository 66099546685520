// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// features fleet/driver
import * as LC from '../constants';
//////////////////////////////////////////////////

export const getCompFilterParams = [
  {
    type: 'string',
    value: GC.FIELD_DRIVER_COMPENSATION_NAME,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'date',
    value: GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE,
    name: G.getWindowLocale('titles:expiration-date', 'Expiration Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE,
    name: G.getWindowLocale('titles:effective-date', 'Effective Date'),
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:origin-cities', 'Origin Cities'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_ORIGIN}.${GC.FIELD_DRIVER_COMPENSATION_CITIES}`,
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:origin-states', 'Origin States'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_ORIGIN}.${GC.FIELD_DRIVER_COMPENSATION_STATES}`,
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:origin-countries', 'Origin Countries'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_ORIGIN}.${GC.FIELD_DRIVER_COMPENSATION_COUNTRIES}`,
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:orgin-zip-codes', 'Origin Zip Codes'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_ORIGIN}.${GC.FIELD_DRIVER_COMPENSATION_ZIP_CODES}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-from', 'Zip From'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_ORIGIN}.${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-to', 'Zip To'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_ORIGIN}.${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO}`,
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:destination-cities', 'Destination Cities'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_DESTINATION}.${GC.FIELD_DRIVER_COMPENSATION_CITIES}`,
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:destination-states', 'Destination States'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_DESTINATION}.${GC.FIELD_DRIVER_COMPENSATION_STATES}`,
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:destination-countries', 'Destination Countries'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_DESTINATION}.${GC.FIELD_DRIVER_COMPENSATION_COUNTRIES}`,
  },
  {
    type: 'string:array',
    name: G.getWindowLocale('titles:destination-zip-codes', 'Destination Zip Codes'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_DESTINATION}.${GC.FIELD_DRIVER_COMPENSATION_ZIP_CODES}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-from', 'Zip From'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_DESTINATION}.${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-to', 'Zip To'),
    value: `${LC.FIELD_DRIVER_COMPENSATION_DESTINATION}.${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO}`,
  },
  {
    type: 'number',
    value: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM,
    name: G.getWindowLocale('titles:distance-range-from', 'Distance Range From'),
  },
  {
    type: 'number',
    value: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO,
    name: G.getWindowLocale('titles:distance-range-to', 'Distance Range To'),
  },
  {
    type: 'string',
    value: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM,
    name: G.getWindowLocale('titles:distance-range-uom', 'Distance Range UOM'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_RATE,
    name: G.getWindowLocale('titles:rate', 'Rate'),
  },
  {
    type: 'string',
    value: GC.FIELD_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'string',
    value: GC.FIELD_CHARGE_RATE_TYPE,
    name: G.getWindowLocale('titles:rate-type', 'Rate Type'),
  },
  {
    type: 'string',
    value: GC.FIELD_CHARGE_RATE_UNIT,
    name: G.getWindowLocale('titles:rate-unit', 'Rate Unit'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_MIN_RATE,
    name: G.getWindowLocale('titles:rate-minimum', 'Rate Minimum'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_MAX_RATE,
    name: G.getWindowLocale('titles:rate-maximum', 'Rate Maximum'),
  },
  {
    type: 'number',
    value: GC.FIELD_TRANSIT_DAYS,
    name: G.getWindowLocale('titles:transit-days', 'Transit Days'),
  },
];
