import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { ReactSelect } from '../../../ui';
// feature new-do
import { InputWrapper } from '../../new-do/forms/formik/input-wrapper';
//////////////////////////////////////////////////

const BranchSearch = (props: Object) => {
  const { option, handleSelect, masterBranchListOptions } = props;

  return (
    <InputWrapper
      inputWrapperStyles={{ mr: 15, width: 160 }}
      label={G.getWindowLocale('titles:branch', 'Branch')}
    >
      <ReactSelect
        name='branch'
        value={option}
        isClearable={true}
        onChange={handleSelect}
        hasError={G.isNilOrEmpty(option)}
        options={masterBranchListOptions}
        shouldNotGetValueFromOptions={true}
      />
    </InputWrapper>
  );
};

export default BranchSearch;
