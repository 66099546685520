import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../../../components/text';
import { openModal, closeModal, closeAllModals } from '../../../components/modal/actions';
// features
import {
  makeSelectAllAvailableTelReferenceTypes,
  makeSelectAllAvailableCloReferenceTypes,
} from '../../reference/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, CustomButton } from '../../../ui';
// feature dispatch-planner
import { pageSizes } from '../settings/page-settings';
import withSaveCancelRoute from '../hocs/with-save-cancel-route';
import {
  cleanBuilder,
  validateRouteRequest,
  saveAsTemplateRequest,
  setCurrentRouteInitialState,
} from '../actions';
import {
  makeSelectEventsInfo,
  makeSelectTotalWeight,
  makeSelectRouteConfigs,
  makeSelectCurrentRoute,
  makeSelectRouteChanged,
  makeSelectTotalDistance,
  makeSelectTotalQuantity,
  makeSelectRouteFinancial,
  makeSelectCurrentRouteItems,
  makeSelectEditTemplateRoute,
  makeSelectCurrentRouteContainers,
} from '../selectors';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const StopsTotalInfo = ({ data }: number) => (
  <Flex m='5px 0' fontSize={11}>
    <Box mr='8px' cursor='pointer'>
      {I.renderSimpleRightArrowIcon(blueColor)}
    </Box>
    <Box>{`${data} ${G.getWindowLocale('titles:stops', 'Stops')}`}</Box>
    <Box ml='8px' cursor='pointer'>
      {I.renderSimpleRightArrowIcon(blueColor)}
    </Box>
  </Flex>
);

const EventInfo = ({ data, key }: Object) => {
  const blackColor = G.getTheme('colors.light.black');

  return (
    <Box>
      <Box mb='5px' fontSize={12}>
        <Box mr='4px' cursor='pointer' display='inline-block'>
          {I.locationMarker(blackColor)}
        </Box>
        {G.concatLocationFields(R.prop(GC.SYSTEM_OBJECT_LOCATION, data))}
      </Box>
      <Box mb='5px' fontSize={12}>
        <Box mr='4px' cursor='pointer' display='inline-block'>
          {I.calendar(blackColor)}
        </Box>
        {G.checkAndConvertMomentInstanceToFormattedDate(data[key], GC.DEFAULT_DATE_TIME_FORMAT)}
      </Box>
    </Box>
  );
};

const TotalDistanceInfo = ({ data }: Object) => {
  const text = `${G.getWindowLocale('titles:total-distance', 'Total Distance')}: ${
    R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, data)} ${R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, data)}`;
  return (
    <TextComponent
      m='5px 0'
      title={text}
      width='100%'
      fontSize={11}
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
    >
      {text}
    </TextComponent>
  );
};

const WeightQuantityInfo = ({ totalWeight, totalQuantity }: Object) => (
  <Box
    m='5px 0'
    p='5px 0'
    fontSize={12}
    borderTop='1px solid'
    borderBottom='1px solid'
    borderColor={G.getTheme('tables.rows.borderColor')}
  >
    <Box mr='4px' cursor='pointer' display='inline-block'>
      {I.renderItemIconSmall(G.getTheme('colors.light.black'))}
    </Box>
    {
      `${G.getWindowLocale('titles:total', 'Total')}:`
    } <b>{R.prop(GC.FIELD_ITEM_WEIGHT, totalWeight)}</b> {R.prop(GC.FIELD_ITEM_WEIGHT_TYPE, totalWeight)}
    {G.isNotNilAndNotEmpty(totalWeight[GC.FIELD_ITEM_WEIGHT]) && ','} <b>{
      totalQuantity[GC.FIELD_ITEM_QUANTITY]
    }</b> {R.prop(GC.FIELD_PACKAGE_TYPE, totalQuantity)}
  </Box>
);

const FinanceInfo = ({ data }: Object) => (
  <Box fontSize={11}>
    <TextComponent
      mb='2px'
      width='100%'
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
      title={G.getWindowLocale('titles:financials', 'Financials')}
    >
      {G.getWindowLocale('titles:financials', 'Financials')}
    </TextComponent>
    <Flex mb='2px'>
      <TextComponent
        mr='4px'
        maxWidth='50%'
        withEllipsis={true}
        title={`${G.getWindowLocale('titles:tel', 'TEL')}: `}
      >
        {`${G.getWindowLocale('titles:tel', 'TEL')}: `}
      </TextComponent>
      <TextComponent
        mr='4px'
        maxWidth='50%'
        fontWeight='bold'
        withEllipsis={true}
        title={`${data.tel} ${R.prop(GC.FIELD_CURRENCY, data)}`}
      >
        {`${data.tel} ${R.prop(GC.FIELD_CURRENCY, data)}`}
      </TextComponent>
    </Flex>
    <Flex mb='2px'>
      <TextComponent
        mr='4px'
        maxWidth='50%'
        withEllipsis={true}
        title={`${G.getWindowLocale('titles:clo', 'CLO')}: `}
      >
        {`${G.getWindowLocale('titles:clo', 'CLO')}: `}
      </TextComponent>
      <TextComponent
        mr='4px'
        maxWidth='50%'
        fontWeight='bold'
        withEllipsis={true}
        title={`${data.clo} ${R.prop(GC.FIELD_CURRENCY, data)}`}
      >
        {`${data.clo} ${R.prop(GC.FIELD_CURRENCY, data)}`}
      </TextComponent>
    </Flex>
    <Flex
      m='5px 0'
      borderTop='1px solid'
      borderBottom='1px solid'
      color={G.getTheme('colors.light.blue')}
      borderColor={G.getTheme('tables.rows.borderColor')}
    >
      <TextComponent
        mr='4px'
        maxWidth='30px'
        withEllipsis={true}
        title={`${G.getWindowLocale('titles:total', 'Total')}:`}
      >
        {`${G.getWindowLocale('titles:total', 'Total')}:`}
      </TextComponent>
      <TextComponent
        fontWeight='bold'
        withEllipsis={true}
        maxWidth='calc(100% - 71px)'
        title={`${data.total} ${R.prop(GC.FIELD_CURRENCY, data)}`}
      >
        {`${data.total} ${R.prop(GC.FIELD_CURRENCY, data)}`}
      </TextComponent>
    </Flex>
  </Box>
);

export const Summary = ({
  eventsInfo,
  financeInfo,
  totalWeight,
  totalDistance,
  totalQuantity,
}: Object) => (
  <Box>
    {
      eventsInfo.firstEvent &&
      <EventInfo data={eventsInfo.firstEvent} dateKey={GC.FIELD_LOAD_EVENT_EARLY_DATE} />
    }
    <StopsTotalInfo data={R.prop(GC.FIELD_TOTAL, eventsInfo)} />
    {
      eventsInfo.lastEvent &&
      <EventInfo data={eventsInfo.lastEvent} dateKey={GC.FIELD_LOAD_EVENT_LATE_DATE} />
    }
    <TotalDistanceInfo data={totalDistance} />
    <WeightQuantityInfo totalWeight={totalWeight} totalQuantity={totalQuantity} />
    <FinanceInfo data={financeInfo} />
  </Box>
);

const Header = (props: Object) => (
  <Flex justifyContent='space-between'>
    <Box p='2px' cursor='pointer'>{I.routesLoads(blueColor)}</Box>
    <TextComponent
      color={blueColor}
      withEllipsis={true}
      title={props.routeName}
      width='calc(100% - 30px)'
    >
      {props.routeName}
    </TextComponent>
  </Flex>
);

const Footer = (props: Object) => (
  <Box width='100%'>
    {
      G.isTrue(props.editTemplateRoute) &&
      <CustomButton
        p='4px'
        mb='8px'
        width='100%'
        fontSize={12}
        height='20px'
        type='button'
        onClick={props.handleScheduleTemporaryRoute}
        cursor={G.ifElse(props.routeChanged, 'not-allowed', 'pointer')}
      >
        {G.getWindowLocale('actions:schedule-route', 'Schedule Route')}
      </CustomButton>
    }
    <CustomButton
      p='4px'
      mb='8px'
      width='100%'
      fontSize={12}
      height='20px'
      type='button'
      onClick={props.handleSaveAsTemplate}
      cursor={G.ifElse(props.routeChanged, 'not-allowed', 'pointer')}
    >
      {G.getWindowLocale('actions:save-as-template', 'Save As Template')}
    </CustomButton>
    {
      R.not(G.isTrue(props.editTemplateRoute)) &&
      <CustomButton
        p='4px'
        mb='8px'
        width='100%'
        fontSize={12}
        height='20px'
        type='button'
        onClick={() => props.handleClickSaveRoute(false, false, true)}
      >
        {props.saveAndCloseBtnText}
      </CustomButton>
    }
    <Flex justifyContent='space-between'>
      <CustomButton
        p='4px'
        fontSize={12}
        height='20px'
        type='button'
        width='calc(50% - 4px)'
        onClick={() => props.handleClickSaveRoute(false, true)}
      >
        {props.saveBtnText}
      </CustomButton>
      <CustomButton
        p='4px'
        fontSize={12}
        height='20px'
        type='button'
        width='calc(50% - 4px)'
        onClick={props.handleClickCancelRoute}
        bgColor={G.getTheme('buttons.cancelBtn.bgColor')}
      >
        {G.getWindowLocale('actions:cancel', 'Cancel')}
      </CustomButton>
    </Flex>
  </Box>
);

const RouteSummary = withSaveCancelRoute((props: Object) => {
  const {
    routeChanged,
    editTemplateRoute,
    handleSaveAsTemplate,
    handleClickSaveRoute,
    handleClickCancelRoute,
    handleScheduleTemporaryRoute,
  } = props;

  const isNewRoute = G.isNilOrEmpty(R.path(['currentRoute', GC.FIELD_GUID], props));

  return (
    <Box
      zIndex={1}
      height='100%'
      overflow='auto'
      width={pageSizes.routeSummaryWidth}
      color={G.getTheme('colors.light.black')}
      bg={G.getTheme('colors.light.mainLight')}
      boxShadow='0 0 10px 0 rgba(204, 204, 204, 0.5)'
    >
      <Flex
        p='8px'
        width='100%'
        height='100%'
        flexDirection='column'
        minHeight='fit-content'
        justifyContent='space-between'
      >
        <Box mb={16} width='100%'>
          <Header routeName={R.pathOr('', ['currentRoute', 'name'], props)} />
          <Summary {...props} />
          {
            props.routeChanged &&
            <Box color={G.getTheme('colors.light.mainRed')}>
              {G.getWindowLocale(
                'titles:save-changes-before-leave-page',
                'You made changes to the route. Do not forget to save the changes before you leave the page',
              )}
            </Box>
          }
        </Box>
        <Footer
          routeChanged={routeChanged}
          editTemplateRoute={editTemplateRoute}
          handleSaveAsTemplate={handleSaveAsTemplate}
          handleClickSaveRoute={handleClickSaveRoute}
          handleClickCancelRoute={handleClickCancelRoute}
          handleScheduleTemporaryRoute={handleScheduleTemporaryRoute}
          saveBtnText={
            G.ifElse(
              isNewRoute,
              G.getWindowLocale('actions:save', 'Save'),
              G.getWindowLocale('actions:update', 'Update'),
            )
          }
          saveAndCloseBtnText={
            G.ifElse(
              isNewRoute,
              G.getWindowLocale('actions:save-and-close', 'Save And Close'),
              G.getWindowLocale('actions:update-and-close', 'Update And Close'),
            )
          }
        />
      </Flex>
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  eventsInfo: makeSelectEventsInfo(state),
  totalWeight: makeSelectTotalWeight(state),
  routeConfigs: makeSelectRouteConfigs(state),
  currentRoute: makeSelectCurrentRoute(state),
  routeChanged: makeSelectRouteChanged(state),
  financeInfo: makeSelectRouteFinancial(state),
  totalDistance: makeSelectTotalDistance(state),
  totalQuantity: makeSelectTotalQuantity(state),
  editTemplateRoute: makeSelectEditTemplateRoute(state),
  currentRouteItems: makeSelectCurrentRouteItems(state),
  cloRefTypes: makeSelectAllAvailableCloReferenceTypes(state),
  telRefTypes: makeSelectAllAvailableTelReferenceTypes(state),
  currentRouteContainers: makeSelectCurrentRouteContainers(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  cleanBuilder,
  closeAllModals,
  validateRouteRequest,
  saveAsTemplateRequest,
  setCurrentRouteInitialState,
})(RouteSummary);
