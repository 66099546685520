import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { LICENSE_CLASS_OPTIONS, LICENSE_RESTRICTION_OPTIONS } from '../../../../helpers/options';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
//////////////////////////////////////////////////

const calendarInputWrapperStyles = {
  width: '100%',
};

const settings = {
  [GC.FIELD_LICENSE_NUMBER]: {
    isRequired: true,
    name: 'titles:license-number',
  },
  [GC.FIELD_LICENSE_STATE]: {
    isRequired: true,
    type: 'addressAutocomplete',
    name: 'titles:license-state',
    additionalInputWrapperStyles: { zIndex: 16 },
    locationMapper: ({ state, country }: Object) => ({
      [GC.FIELD_LICENSE_STATE]: state,
      [GC.FILED_LICENSE_COUNTRY]: country,
    }),
  },
  [GC.FILED_LICENSE_COUNTRY]: {
    isRequired: true,
    type: 'countrySelect',
    name: 'titles:license-country',
  },
  licenseClass: {
    type: 'select',
    isRequired: true,
    name: 'titles:license-class',
    options: LICENSE_CLASS_OPTIONS,
    nameForAttribute: 'licenseClass',
  },
  issueDate: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:issue-date',
    calendarInputWrapperStyles,
    maxDate: (props: Object) => G.makeMomentInstance(R.pathOr(null, ['values', GC.FIELD_EXPIRATION_DATE], props)),
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    name: 'titles:expiration-date',
    minDate: (props: Object) => G.makeMomentInstance(R.pathOr(null, ['values', 'issueDate'], props)),
  },
  restrictions: {
    type: 'select',
    name: 'titles:restrictions',
    options: LICENSE_RESTRICTION_OPTIONS,
  },
  endorsements: {
    isMulti: true,
    type: 'reactSelect',
    name: 'titles:endorsements',
    additionalInputWrapperStyles: { zIndex: 11 },
    options: [
      {
        value: GC.DRIVER_ENDORSEMENTS_TANK,
        label: G.getWindowLocale('titles:tank', 'Tank'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_OTHER,
        label: G.getWindowLocale('titles:other', 'Other'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_TOW_TRUCK,
        label: G.getWindowLocale('titles:tow-truck', 'Tow Truck'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_METAL_COIL,
        label: G.getWindowLocale('titles:metal-coil', 'Metal Coil'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_TANK_VEHICLES,
        label: G.getWindowLocale('titles:tank-vehicles', 'Tank Vehicles'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_X_ENDORSEMENT,
        label: G.getWindowLocale('titles:x-endorsement', 'X Endorsement'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_NO_ENDORSEMENT,
        label: G.getWindowLocale('titles:no-endorsement', 'No Endorsement'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_DOUBLES_TRIPLES,
        label: G.getWindowLocale('titles:doubles-triples', 'Doubles/Triples'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_PASSENGER_TRANSPORT,
        label: G.getWindowLocale('titles:passenger-transport', 'Passenger Transport'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_HAZARDOUS_MATERIALS,
        label: G.getWindowLocale('titles:hazardous-materials', 'Hazardous Materials'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_FARM_CLASS_A_VEHICLES,
        label: G.getWindowLocale('titles:farm-class-a-vehicles', 'Farm Class A Vehicles'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_FARM_CLASS_B_VEHICLES,
        label: G.getWindowLocale('titles:farm-class-b-vehicles', 'Farm Class B Vehicles'),
      },
      {
        value: GC.DRIVER_ENDORSEMENTS_RECREATIONAL_VEHICLES_GWR,
        label: `${G.getWindowLocale(
          'titles:recreational-vehicles-gvwr',
          'Recreational Vehicles GVWR over 26,000 lbs',
          )
        }.`,
      },
    ],
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    type: 'file',
    name: 'titles:document-name',
    additionalInputWrapperStyles: {
      width: 600,
    },
  },
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    type: 'select',
    isRequired: true,
    name: 'titles:document-type',
    options: 'documentTypeOptions',
    additionalInputWrapperStyles: {
      display: (props: Object) => G.ifElse(
        G.isNotNilAndNotEmpty(R.path(['values', GC.FIELD_DOCUMENT_UPLOAD], props)),
        'block',
        'none',
      ),
    },
  },
};

const validationSchema = Yup.lazy(({ file }: Object) => Yup.object().shape({
  ...R.map(({ isRequired }: Object) => G.ifElse(isRequired, G.yupStringRequired, G.yupStringNotRequired), settings),
  endorsements: G.yupArrayNotRequired,
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.ifElse(
    G.isNotNilAndNotEmpty(file),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.FIELD_LICENSE_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
}));

const defaultInputWrapperStyles = {
  mb: 25,
  width: 280,
};

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
    R.omit([GC.FIELD_DOCUMENT_UPLOAD, GC.FIELD_DOCUMENT_DOCUMENT_TYPE]),
  )(settings),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    isMulti,
    options,
    isRequired,
    type = 'text',
    locationMapper,
    calendarInputWrapperStyles,
    additionalInputWrapperStyles,
  }: Object,
  fieldName: string,
  ) => ({
    type,
    isMulti,
    options,
    fieldName,
    isRequired,
    locationMapper,
    label: R.of(Array, name),
    calendarInputWrapperStyles,
    inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperStyles },
  })),
  R.omit([GC.FIELD_CREATED_BY, GC.FIELD_CREATED_DATE, GC.FIELD_LAST_MODIFIED_BY, GC.FIELD_LAST_MODIFIED_DATE]),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width', 'type', 'customComponent']), settings);

export const licenseSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  singleItem: true,
  groupName: 'license',
  actionsPicker: ['edit'],
  useGetListRequest: true,
  itemTitleArr: ['titles:license-details', 'License Details'],
  formGroupTitleArr: ['titles:license-details', 'License Details'],
  additionalTableSettings: {
    checkBoxCellJustifyContent: 'center',
  },
  endpoints: {
    getListByGuid: ({ primaryObjectGuid }: Object) => endpointsMap.driverLicenseEndpoint(primaryObjectGuid),
  },
  fieldsWrapperStyles: {
    width: 600,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    documentTypeOptions: R.path(['dropdownOptions', GC.DRIVER_DOCUMENT_TYPE], configGroup),
  }),
  makeInitialValues: (initialValues: Object) => R.assoc(
    GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
    null,
    initialValues,
  ),
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, getDocumentListRequest, createOrUpdateEntityRequest } = props;

    const { failCallback, successCallback: setSubmitting } = additionalOptions;

    const hasFile = G.isNotNil(R.prop(GC.FIELD_DOCUMENT_UPLOAD, values));

    const successCallback = () => {
      setSubmitting();

      if (hasFile) getDocumentListRequest();
    };

    createOrUpdateEntityRequest({
      method: 'post',
      groupName: 'license',
      endpoint: endpointsMap.driverLicense,
      additionalOptions: { failCallback, successCallback },
      values: G.makeDataForDocument(G.ifElse(hasFile, values, R.dissoc(GC.FIELD_DOCUMENT_DOCUMENT_TYPE, values))),
    });
  },
};
