import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withState, withHandlers, renderNothing } from 'react-recompose';
// components
import { PageTabs } from '../../../components/page-tabs';
import { PageTitle } from '../../../components/page-title';
import { makeSelectLoader } from '../../../components/loader/selectors';
import { openModal, closeModal } from '../../../components/modal/actions';
import { getConfirmModal, ConfirmComponent } from '../../../components/confirm';
// features
import Audit2 from '../../audit2';
import { makeSelectIsCurrentUserSuperAdmin } from '../../auth/selectors';
import { makeSelectAuthorities, makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { RelativeBox, PageWrapper, PageHeaderWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature branch
import BranchForm from './branch-form';
import BranchDocumentForm from './branch-document-form';
import { renderConfirmationModal } from './element-actions';
import { BRANCH_SETTING_FORM_SECTION, COMPANY_DETAILS_FORM_SECTION } from '../constants';
import { getDefaultBranchFields, branchValidationSchema } from '../settings/fields-settings';
import {
  makeSelectEditForm,
  makeSelectBranchRefs,
  makeSelectImportMappers,
  makeSelectBranchSetting,
  makeSelectCollapsedGroup,
  makeSelectBranchCompanyDetails,
  makeSelectCurrentBranchConfigs,
  makeSelectEditBranchDocumentList,
  makeSelectCommissionAssigneeByType,
} from '../selectors';
import {
  deleteDocument,
  downloadDocument,
  getBranchRequest,
  sendSuspendRequest,
  updateBranchRequest,
  sendActivateRequest,
  sendUnsuspendRequest,
  sendDeactivateRequest,
  deleteBranchRefRequest,
  createBranchRefRequest,
  updateBranchRefRequest,
  collapseBranchFormGroup,
  saveBranchDocumentRequest,
  updateBranchSettingsRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withState('submitting', 'setSubmitting', false),
  withState('formSection', 'setFormSection', null),
  withState('activeTab', 'setActiveTab', 'general'),
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(branchValidationSchema),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        formSection,
        branchSetting,
        setSubmitting,
        companyDetails,
        setFormSection,
        updateBranchRequest,
        updateBranchSettingsRequest,
    } = props;

      let dnsPrefix = R.pathOr('', ['dnsPrefix'], values).toLowerCase();

      if (G.isNilOrEmpty(dnsPrefix)) {
        dnsPrefix = null;
      }

      const BranchSetting = R.pick(
        R.keys(branchSetting),
        values,
      );

      const CompanyDetails = R.pick(
        R.keys(companyDetails),
        values,
      );

      const data = {
        formSection,
        [COMPANY_DETAILS_FORM_SECTION]: { ...CompanyDetails, dnsPrefix },
        [BRANCH_SETTING_FORM_SECTION]: {
          ...BranchSetting,
          [GC.FIELD_GUID]: G.getGuidFromObject(branchSetting),
          [GC.FIELD_VERSION]: R.prop(GC.FIELD_VERSION, branchSetting),
        },
      };

      setSubmitting(true);

      if (R.equals(formSection, BRANCH_SETTING_FORM_SECTION)) {
        updateBranchSettingsRequest(data);
      } else {
        updateBranchRequest(data);
      }

      setFormSection(null);

      setTimeout(() => setSubmitting(false), 3000);
    },
    mapPropsToValues: ({ branchSetting, isSuperAdmin, companyDetails }: Object) => {
      const branchSubtype = R.pathOr('', [GC.FIELD_BRANCH_SUBTYPE], companyDetails);

      return G.setInitialFormikValues(
        getDefaultBranchFields(isSuperAdmin),
        {
          ...branchSetting,
          ...companyDetails,
          [GC.FIELD_BRANCH_MAX_SMS_PRICE]: R.pathOr('', [GC.FIELD_BRANCH_MAX_SMS_PRICE], branchSetting),
          [GC.FIELD_BRANCH_NORMALIZED_CURRENCY]: R.pathOr('', [GC.FIELD_BRANCH_NORMALIZED_CURRENCY], branchSetting),
          [GC.FIELD_BRANCH_SUBTYPE]: G.ifElse(
            G.isObject(branchSubtype),
            G.getDropdownOptionGuidFromObject(branchSubtype),
            branchSubtype,
          ),
        },
      );
    },
  }),
  withHandlers({
    handleEntStatus: (props: Object) => (ent: Object) => {
      const { openModal, closeModal } = props;

      const modal = renderConfirmationModal({
        ...props,
        ent,
        action: G.ifElse(R.prop('suspended', ent), 'unsuspend', 'suspend'),
      });

      closeModal();
      openModal(modal);
    },
    handleCreateReference: (props: Object) => (values: Object) => {
      const { match, createBranchRefRequest } = props;

      const branchGuid = R.path(['params', 'guid'], match);
      const args = R.assoc('primaryObjectGuids', R.of(Array, branchGuid), values);

      createBranchRefRequest(args);
    },
    handleUpdateReference: ({ updateBranchRefRequest }: Object) => (data: Object) =>
      updateBranchRefRequest(data),
    handleRemoveReference: (props: Object) => (guid: string) => {
      const { openModal, closeModal, references, deleteBranchRefRequest } = props;

      const confirmationContent = (
        <ConfirmComponent
          name={R.path([guid, 'name'], references)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:remove', 'Remove'),
              action: () => {
                closeModal();
                deleteBranchRefRequest(guid);
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleAddDocument: (props: Object) => () => {
      const { configs, openModal, closeModal, companyDetails, saveBranchDocumentRequest} = props;

      const branchGuid = G.getGuidFromObject(companyDetails);

      const handleSave = (values: Object) => {
        saveBranchDocumentRequest({
          data: R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, branchGuid, values),
        });

        closeModal();
      };

      const component = (
        <BranchDocumentForm
          closeModal={closeModal}
          handleSaveDocument={handleSave}
          documentTypeOptions={R.pathOr([], ['documentTypeOptions'], configs)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 440,
          title: G.getWindowLocale('titles:add-doc', 'Add Document'),
        },
      };

      openModal(modal);
    },
    handleEditDocument: (props: Object) => (document: Object) => {
      const { configs, openModal, closeModal, saveBranchDocumentRequest } = props;

      const handleSave = (values: Object) => {
        saveBranchDocumentRequest({
          update: true,
          data: R.mergeRight(document, values),
        });

        closeModal();
      };

      const initialValues = R.mergeRight(
        document,
        {
          file: R.pathOr('', [GC.FIELD_DOCUMENT_FILE_NAME], document),
          [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: R.path(
            [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
            document,
          ),
        },
      );

      const component = (
        <BranchDocumentForm
          closeModal={closeModal}
          initialValues={initialValues}
          handleSaveDocument={handleSave}
          documentTypeOptions={R.pathOr([], ['documentTypeOptions'], configs)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 440,
          title: G.getWindowLocale('titles:edit-document', 'Edit Document'),
        },
      };

      openModal(modal);
    },
    handleDeleteDocument: (props: Object) => ({ guid, primaryObjectGuid }: Object) => {
      const { openModal, closeModal, deleteDocument } = props;

      const action = () => {
        deleteDocument({
          documentGuid: guid,
          branchGuid: primaryObjectGuid,
        });

        closeModal();
      };

      const modalContent = getConfirmModal({
        submitAction: action,
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale(
          'messages:documents:remove-confirmation-text',
          'Are you sure you want to delete this document?',
        ),
      });

      openModal(modalContent);
    },
    handleClickCancel: () => () => G.goToRoute(routesMap.branchList),
  }),
  branch(
    ({ companyDetails, initialDataLoaded }: Object) => (
      R.or(
        R.not(initialDataLoaded),
        G.isNilOrEmpty(companyDetails),
      )
    ),
    renderNothing,
  ),
  pure,
);

const tabs = (activeTab: string, setActiveTab: Function) => ([
  {
    action: () => setActiveTab('general'),
    label: G.getWindowLocale('titles:detail-page', 'Detail Page'),
    frontIcon: I.searchDocument(G.getThemeByCond(
      R.equals(activeTab, 'general'),
      'icons.iconColor',
      'icons.iconColorGrey',
    )),
  },
]);

const AuditTab = ({ branchName, requestOptions }: Object) => {
  const auditProps = {
    requestOptions,
    useFullScreen: true,
    maxHeight: 'calc(100vh - 215px)',
  };

  return (
    <div>
      <PageHeaderWrapper>
        <PageTitle title={`${G.getWindowLocale('titles:audit', 'Audit')}: ${branchName}`} />
      </PageHeaderWrapper>
      <Audit2 {...auditProps} />
    </div>
  );
};

const renderTab = (props: Object) => {
  const { activeTab, companyDetails } = props;

  const branchName = R.path([GC.BRANCH_NAME], companyDetails);

  const requestOptions = {
    [GC.FIELD_TYPE]: GC.AUDIT_TYPE_BRANCH,
    [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(companyDetails),
  };

  const tabs = {
    general: <BranchForm {...props} />,
    audit: <AuditTab branchName={branchName} requestOptions={requestOptions} />,
  };

  return tabs[activeTab];
};

const EditBranch = (props: Object) => {
  const { activeTab, setActiveTab } = props;

  return (
    <PageWrapper height='calc(100vh - 60px)'>
      <RelativeBox>
        <PageTabs
          zI={14}
          height={70}
          withAudit={true}
          isLabelExist={true}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs(activeTab, setActiveTab)}
        />
      </RelativeBox>
      {renderTab(props)}
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loader: makeSelectLoader(state),
  newValues: makeSelectEditForm(state),
  permissions: makeSelectAuthorities(state),
  importMappers: makeSelectImportMappers(state),
  branchSetting: makeSelectBranchSetting(state),
  configs: makeSelectCurrentBranchConfigs(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  documents: makeSelectEditBranchDocumentList(state),
  [GC.UI_FIELD_REFERENCES]: makeSelectBranchRefs(state),
  companyDetails: makeSelectBranchCompanyDetails(state),
  isSuperAdmin: makeSelectIsCurrentUserSuperAdmin(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  commissionAssignmentByType: makeSelectCommissionAssigneeByType(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  deleteDocument,
  downloadDocument,
  getBranchRequest,
  sendSuspendRequest,
  sendActivateRequest,
  updateBranchRequest,
  sendUnsuspendRequest,
  sendDeactivateRequest,
  deleteBranchRefRequest,
  createBranchRefRequest,
  updateBranchRefRequest,
  collapseBranchFormGroup,
  saveBranchDocumentRequest,
  updateBranchSettingsRequest,
})(enhance(EditBranch));
