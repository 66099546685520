import * as R from 'ramda';
import { compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import { defaultNotes } from './helpers';
//////////////////////////////////////////////////

export const stylingPanelHoc = compose(
  withState('infoPanelIsOpen', 'toggleInfoPanelIsOpen', true),
  withHandlers(() => {
    const refs = {};

    return {
      handleGetInfoPanelRef: (props: Object) => (ref: Object, name: string) => {
        refs[name] = ref;

        if (G.isNotNilAndNotEmpty(refs[name])) {
          if (G.isNilOrEmpty(R.path(['splashScreenSetting', 'panels'], props))) {
            const title = R.pathOr('', ['title'], R.head(defaultNotes));
            const subtitle = R.pathOr('', ['subtitle'], R.head(defaultNotes));
            refs[name].innerHTML = `${title} ${subtitle}`;

            return;
          }

          const title = R.pathOr('', ['splashScreenSetting', 'panels', name, 'title'], props);
          const subtitle = R.pathOr('', ['splashScreenSetting', 'panels', name, 'subtitle'], props);
          refs[name].innerHTML = `<h1>${title}</h1> <p>${subtitle}</p>`;
        }
      },
    };
  }),
  withPropsOnChange(['splashScreenSetting'], (props: Object) => {
    props.toggleInfoPanelIsOpen(G.getOrElse(props.splashScreenSetting, 'panelExpanded', true));
  }),
);

export default stylingPanelHoc;
