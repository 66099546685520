import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
//////////////////////////////////////////////////

export const setOptions = (configs: Object, { options }: Object) => (
  G.ifElse(
    G.isString(options),
    R.path([options], configs),
    options,
  )
);

export const getAdditionaDocumentColumnActions = ({
  endpointName,
  handlePreviewDocument,
  handleDownloadDocument,
}: Object) => {
  const getVisibility = ({ fileUri }: Object) => G.ifElse(
    G.isNilOrEmpty(fileUri),
    'hidden',
    'visible',
  );

  const downloadOptions = {
    endpointName,
    primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  };

  return [
    {
      getVisibility,
      icon: I.downloadDocument(),
      title: G.getWindowLocale('titles:download-file', 'Download File'),
      handleClick: (entity: Object) => handleDownloadDocument(entity, downloadOptions),
    },
    {
      icon: I.eye(),
      getVisibility,
      title: G.getWindowLocale('titles:click-for-preview', 'Click For Preview'),
      handleClick: (entity: Object) => handlePreviewDocument(entity, downloadOptions),
    },
  ];
};
