import * as R from 'ramda';
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const composeObjectPropsToString = (
  omitFields: Array = [],
  withoutValues: Array = [],
  joiner: string = '',
) => R.compose(
  R.toLower(),
  R.join(joiner),
  R.without(withoutValues),
  R.values(),
  R.omit(omitFields),
);

export const mapListObjectPropsToPropString = (filterPropName: string, composeProps: Object) => R.map(
  (item: Object) => R.assoc(
    filterPropName,
    composeObjectPropsToString(
      R.prop('omitFields', composeProps),
      R.prop('withoutValues', composeProps),
      R.prop('joiner', composeProps),
    )(item),
    item,
  ),
);

export const filterListOfObjectsByPropContains = (
  filterString: string,
  filterPropName: string,
) => R.filter(
  (item: Object) => R.propSatisfies(
    (prop: string) => R.includes(R.toLower(filterString), prop), filterPropName, item,
  ),
);

export const withFilterTableList = (
  filterPropName: string,
  pathToOriginList: string,
  composeProps: Object,
) => compose(
  withState('filterString', 'setFilterString', ''),
  withState('filteredTableList', 'setFilteredTableList', []),
  withHandlers({
    handleChangeFilterInput: (props: Object) => (value: string) => {
      props.setFilterString(value);
    },
  }),
  withPropsOnChange(['filterString', pathToOriginList], (props: Object) => {
    const { filterString, setFilteredTableList } = props;
    const keys = R.keys((R.path(pathToOriginList, props)));
    const originList = R.flatten(
      R.map(R.values, R.values(R.path(pathToOriginList, props)))
    );
    if (G.isNilOrEmpty(filterString)) {
      return setFilteredTableList(keys);
    }
    const mappedList = mapListObjectPropsToPropString(
      filterPropName,
      composeProps,
    )(originList);
    const filteredList = filterListOfObjectsByPropContains(
      filterString,
      filterPropName,
    )(mappedList);
    const filterObj = R.uniq(filteredList.map(({ itemId }: Object) => itemId));
    setFilteredTableList(filterObj);
  }),
);
