import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
///////////////////////////////////////////////////////////////////////////////////////////////////

// TODO add locale
export const documentValidation = (value: any, form: Object, _: Object, fieldName: string) => {
  if (R.and(G.isNotEmpty(value), G.isNotNil(value))) {
    return undefined;
  }
  switch (fieldName) {
    case 'url':
      return G.ifElse(
        R.and(R.not(value), form.documentFilename),
        undefined,
        'Required Url Field or Upload File',
      );
    case 'documentFilename':
      return G.ifElse(
        R.and(R.not(value), form.url),
        undefined,
        'Required Url Field or Upload File',
      );
    default:
      return '';
  }
};
