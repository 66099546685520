import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
// components
import { MessageItem } from '../../../../components/message-center';
// ui
import { Box } from '../../../../ui';
// feature claim-management
import { makeSelectPinnedNote } from '../../selectors';
import { createOrUpdateNoteRequest } from '../../actions';
//////////////////////////////////////////////////

const PinnedMessage = (props: Object) => {
  const { dispatch } = props;

  const pinnedNote = useSelector(makeSelectPinnedNote());

  const handlePinOrUnpinMessage = ({ pinned, ...entity }: Object) => {
    dispatch(createOrUpdateNoteRequest({ ...entity, pinned: R.not(pinned) }));
  };

  if (R.isNil(pinnedNote)) return null;

  return (
    <Box mb={30} ml='auto' minWidth={300} maxWidth='calc(50% - 20px)'>
      <MessageItem
        message={pinnedNote}
        isActiveTabNotes={true}
        handlePinMessage={handlePinOrUnpinMessage}
      />
    </Box>
  );
};

export default PinnedMessage;
