import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms/formik';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultValues = {
  [GC.FIELD_NOTE]: '',
  [GC.FIELD_REASON]: '',
  [GC.FIELD_LOCATION]: null,
  [GC.FIELD_END_DATE]: null,
  [GC.FIELD_START_DATE]: null,
};

const getFieldSettings = (isLocationRequired: boolean) => [
  {
    isRequired: true,
    type: 'datePicker',
    timeSelection: true,
    fieldName: GC.FIELD_START_DATE,
    label: ['titles:start-date', 'Start Date'],
    inputWrapperStyles: { mr: 15, mb: 25, width: 200 },

  },
  {
    isRequired: true,
    type: 'datePicker',
    timeSelection: true,
    fieldName: GC.FIELD_END_DATE,
    label: ['titles:end-date', 'End Date'],
    inputWrapperStyles: { mb: 25, width: 200 },
  },
  {
    concatLocationFields: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_LOCATION,
    isRequired: isLocationRequired,
    label: ['titles:location', 'Location'],
    inputWrapperStyles: { mr: 15, mb: 25, width: 200 },
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_REASON,
    options: GC.DRIVER_OUT_DUTY_REASON,
    label: ['titles:reason', 'Reason'],
    inputWrapperStyles: { mb: 25, width: 200 },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_NOTE,
    label: ['titles:note', 'Note'],
    inputWrapperStyles: { mb: 25, width: 415 },
  },
];

const getValidationSchema = (isLocationRequired: boolean) => {
  const locationFieldValidation = Yup.object().default(null).nullable(true).typeError(G.getSelectLocationLocaleTxt());

  return Yup.object().shape({
    [GC.FIELD_START_DATE]: Yup.date()
      .nullable(true)
      .typeError('${value}') // eslint-disable-line
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_END_DATE]: Yup.date()
      .nullable(true)
      .typeError('${value}') // eslint-disable-line
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_NOTE]: Yup.string().nullable(true).notRequired(),
    [GC.FIELD_REASON]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    [GC.FIELD_LOCATION]: G.ifElse(
      G.isTrue(isLocationRequired),
      locationFieldValidation.required(G.getRequiredLocaleTxt()),
      locationFieldValidation),
  });
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitHandler(values),
    validationSchema: ({ isLocationRequired }: Object) => getValidationSchema(isLocationRequired),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
  }),
  withProps({ configsNamesArray: [GC.DRIVER_OUT_DUTY_REASON] }),
  withAsyncConfigs,
  withProps(({ asyncConfigs }: Object) => {
    const options = G.mapDropdownConfigWithParentGuid(asyncConfigs, GC.DRIVER_OUT_DUTY_REASON);

    return {
      optionsForSelect: { [GC.DRIVER_OUT_DUTY_REASON]: options },
    };
  }),
  withHandlers({
    handleSelectLocation: (props: Object) => (location: string) => {
      const { setFieldValue } = props;

      setFieldValue(GC.FIELD_LOCATION, location);
    },
  }),
  pure,
);

const UnavailablePeriodForm = (props: Object) => {
  const { handleSubmit, optionsForSelect, isLocationRequired, handleSelectLocation } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fieldsWrapperStyles={{ mt: 10 }}
        fields={getFieldSettings(isLocationRequired)}
        customSelectLocationFunction={handleSelectLocation}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(UnavailablePeriodForm);
