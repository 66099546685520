import React from 'react';
import * as R from 'ramda';
// components
import { withTabs2 } from '../../../components/tabs-mui';
// feature dispatch-report
import Load from './load';
import TelEvents from './load-events';
import TelByClo from './load-by-order';
import PivotLoad from './components/pivot-load';
//////////////////////////////////////////////////

const LoadWrapper = withTabs2((props: Object) => {
  const { activeTab, handleSetActiveTab } = props;

  if (R.equals(activeTab, 0)) {
    return <Load activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  } else if (R.equals(activeTab, 1)) {
    return <TelByClo activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  } else if (R.equals(activeTab, 2)) {
    return <TelEvents activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  }

  return <PivotLoad {...props} />;
});

export default LoadWrapper;
