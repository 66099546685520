import * as R from 'ramda';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import { renderFormGroup } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
// feature fleet
import * as H from '../helpers';
import { FLEET_INSURANCE_FORM } from '../constants';
// feature fleet/truck
import { insuranceFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  reduxForm({
    form: FLEET_INSURANCE_FORM,
  }),
  withHandlers({
    handleFormSubmit: ({ closeModal, submitAction, requestGuid, requestGuidKey }: Object) => (values: Object) => {
      const reqBody = R.assoc(requestGuidKey, requestGuid, values);
      submitAction(reqBody);
      closeModal();
    },
  }),
  pure,
);

const InsuranceFormComponent = (props: Object) => (
  <section>
    <form
      id={FLEET_INSURANCE_FORM}
      onSubmit={props.handleSubmit(props.handleFormSubmit)}
    >
      <Flex flexWrap='wrap'>
        {
          insuranceFields.map((field: Object, index: number) => (
            <Field
              key={index}
              info={field.info}
              lineHeight='28px'
              margin='5px 10px'
              align={field.align}
              validate={field.validate}
              name={field.nameForAttribute}
              isClearable={field.isClearable}
              type={G.setFieldType(field.type)}
              width={R.or(field.width, '390px')}
              component={renderFormGroup(field.type)}
              direction={R.or(field.direction, 'row')}
              placeholder={G.getWindowLocale(field.name)}
              options={H.setOptions(props.configs, field)}
              errorMargin={R.or(field.errorMargin, '0 0 5px 0')}
            />
          ))
        }
      </Flex>
    </form>
  </section>
);

export default enhance(InsuranceFormComponent);
