import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const createDriverDefaultFields = {
  [GC.FIELD_EMAIL]: '',
  [GC.FIELD_PASSWORD]: '',
  [GC.FIELD_LAST_NAME]: '',
  [GC.FIELD_FIRST_NAME]: '',
  [GC.FIELD_FLEET_LOGIN_ID]: '',
  [GC.FIELD_ROLE_ROLES_GUIDS]: [],
};

const createDriverValidationSchemaObject = Yup.object().shape({
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getShouldBeEmailLocaleTxt())
    .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  [GC.FIELD_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(128, G.getShouldBeLessOrEqualLocaleTxt(128)),
  [GC.FIELD_LAST_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  [GC.FIELD_FIRST_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
  [GC.FIELD_ROLE_ROLES_GUIDS]: G.yupArrayRequired,
  [GC.FIELD_FLEET_LOGIN_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(32, G.getShouldBeLessOrEqualLocaleTxt(32)),
});

const createDriverFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:first-name'],
    fieldName: GC.FIELD_FIRST_NAME,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:last-name'],
    fieldName: GC.FIELD_LAST_NAME,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:driver-id'],
    fieldName: GC.FIELD_FLEET_LOGIN_ID,
  },
  {
    type: 'password',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:password'],
    fieldName: GC.FIELD_PASSWORD,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:email'],
    fieldName: GC.FIELD_EMAIL,
  },
  {
    isMulti: true,
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.FIELD_ROLE_ROLES_GUIDS,
    fieldName: GC.FIELD_ROLE_ROLES_GUIDS,
    label: ['titles:select-user-permissions'],
  },
];

const createTrailerValidationSchema = Yup.object().shape({
  [GC.FIELD_UNIT_ID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRAILER_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const createTrailerDefaultFields = {
  [GC.FIELD_UNIT_ID]: '',
  [GC.FIELD_TRAILER_TYPE]: '',
};

const createTrailerFieldSettings = [
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:unit-id'],
    fieldName: GC.FIELD_UNIT_ID,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:trailer-type'],
    options: GC.FIELD_TRAILER_TYPE,
    fieldName: GC.FIELD_TRAILER_TYPE,
  },
];

const createTruckValidationSchema = Yup.object().shape({
  [GC.FIELD_UNIT_ID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRUCK_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const createTruckDefaultFields = {
  [GC.FIELD_UNIT_ID]: '',
  [GC.FIELD_TRUCK_TYPE]: '',
};

export const createTruckFieldSettings = [
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:unit-id'],
    fieldName: GC.FIELD_UNIT_ID,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:truck-type'],
    options: GC.FIELD_TRUCK_TYPE,
    fieldName: GC.FIELD_TRUCK_TYPE,
  },
];

const createVendorValidationSchema = Yup.lazy(({ mcNumber, federalEin, accountNumber }: Object) => {
  let schema = {
    [GC.FIELD_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(250, G.getShouldBeLessOrEqualLocaleTxt(250)),
    [GC.FIELD_OWNER_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(250, G.getShouldBeLessOrEqualLocaleTxt(250)),
    [GC.FIELD_MC_NUMBER]: Yup.string()
      .nullable(true)
      .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
    [GC.FIELD_ACCOUNT_NUMBER]: Yup.string()
      .nullable(true)
      .max(40, G.getShouldBeLessOrEqualLocaleTxt(40)),
    [GC.FIELD_FEDERAL_EIN]: Yup.string()
      .nullable(true)
      .max(20, G.getShouldBeLessOrEqualLocaleTxt(20)),
  };

  if (G.isAllNilOrEmpty([mcNumber, federalEin, accountNumber])) {
    const mcNumber = Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(12, G.getShouldBeLessOrEqualLocaleTxt(12));

    const accountNumber = Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(40, G.getShouldBeLessOrEqualLocaleTxt(40));

    const federalEin = Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(20, G.getShouldBeLessOrEqualLocaleTxt(20));

    schema = R.mergeRight(schema, { mcNumber, federalEin, accountNumber });
  }

  return Yup.object().shape(schema);
});

const createVendorDefaultFields = {
  [GC.FIELD_NAME]: '',
  [GC.FIELD_MC_NUMBER]: '',
  [GC.FIELD_OWNER_NAME]: '',
  [GC.FIELD_FEDERAL_EIN]: '',
  [GC.FIELD_ACCOUNT_NUMBER]: '',
};

const createVendorFieldSettings = [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:company-name'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:owner-name'],
    fieldName: GC.FIELD_OWNER_NAME,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:mc-number'],
    fieldName: GC.FIELD_CARRIER_MC_NUMBER,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:account-number'],
    fieldName: GC.FIELD_ACCOUNT_NUMBER,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:federal-ein'],
    fieldName: GC.FIELD_FEDERAL_EIN,
  },
];

export const createFormSettingsMap = {
  truck: {
    fieldSettings: createTruckFieldSettings,
    defaultFields: createTruckDefaultFields,
    validationSchema: createTruckValidationSchema,
  },
  trailer: {
    fieldSettings: createTrailerFieldSettings,
    defaultFields: createTrailerDefaultFields,
    validationSchema: createTrailerValidationSchema,
  },
  driver: {
    fieldSettings: createDriverFieldSettings,
    defaultFields: createDriverDefaultFields,
    validationSchema: createDriverValidationSchemaObject,
  },
  vendor: {
    fieldSettings: createVendorFieldSettings,
    defaultFields: createVendorDefaultFields,
    validationSchema: createVendorValidationSchema,
  },
};

export const createFleetDefaultFields = (index: number) => ({
  index,
  id: G.genShortId(),
  [GC.FIELD_TRAILER]: R.assoc(GC.FIELD_ID, G.genShortId(), createTrailerDefaultFields),
  [GC.SYSTEM_OBJECT_TRUCK]: R.assoc(GC.FIELD_ID, G.genShortId(), createTruckDefaultFields),
  [GC.SYSTEM_OBJECT_DRIVER]: R.assoc(GC.FIELD_ID, G.genShortId(), createDriverDefaultFields),
});

export const createFleetValidationSchema = Yup.object().shape({
  fleetDtos: Yup.array().of(Yup.object().shape({
    [GC.SYSTEM_OBJECT_DRIVER]: createDriverValidationSchemaObject,
    [GC.SYSTEM_OBJECT_TRUCK]: Yup.lazy(({ unitId, truckType }: Object = {}) => {
      if (R.or(G.isNotNilAndNotEmpty(unitId), G.isNotNilAndNotEmpty(truckType))) return createTruckValidationSchema;

      return Yup.object().nullable(true);
    }),
    [GC.FIELD_TRAILER]: Yup.lazy(({ unitId, trailerType }: Object = {}) => {
      if (R.or(G.isNotNilAndNotEmpty(unitId), G.isNotNilAndNotEmpty(trailerType))) {
        return createTrailerValidationSchema;
      }

      return Yup.object().nullable(true);
    }),
  })),
});

const userPermissionsField = {
  isMulti: true,
  type: 'reactSelect',
  options: GC.FIELD_ROLE_ROLES_GUIDS,
  fieldName: GC.FIELD_ROLE_ROLES_GUIDS,
  label: ['titles:select-user-permissions'],
};

export const createFleetFieldSettings = {
  truck: R.map((item: Object) => ({
    ...item,
    inputWrapperStyles: { width: 220 },
    label: G.getWindowLocale(item.label),
    fieldName: `${GC.SYSTEM_OBJECT_TRUCK}.${item.fieldName}`,
  }), createTruckFieldSettings),
  trailer: R.map((item: Object) => ({
    ...item,
    inputWrapperStyles: { width: 220 },
    label: G.getWindowLocale(item.label),
    fieldName: `${GC.FIELD_TRAILER}.${item.fieldName}`,
  }), createTrailerFieldSettings),
  driver: R.compose(
    R.map((item: Object) => ({
      ...item,
      isRequired: true,
      label: G.getWindowLocale(item.label),
      fieldName: `${GC.SYSTEM_OBJECT_DRIVER}.${item.fieldName}`,
      inputWrapperStyles: {
        width: 220,
        zIndex: G.ifElse(R.propEq(GC.FIELD_ROLE_ROLES_GUIDS, 'fieldName', item), 12),
        mb: G.ifElse(G.notContain(item.fieldName, [GC.FIELD_EMAIL, GC.FIELD_ROLE_ROLES_GUIDS]), 30),
      },
    })),
    R.update(-1, userPermissionsField),
  )(createDriverFieldSettings),
};
