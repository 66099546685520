import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// components
import { Box, Flex } from '../../../ui';
import { TextComponent } from '../../../components/text';
// forms
import { Info } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  BRANCH_TYPE_OPTIONS,
  availableEdiExportMappers,
  BRANCH_TYPE_MASTER_OPTION,
  availableEdiLoaderExportMappers,
} from '../../../helpers/options';
// feature branch
import * as LC from '../constants';
import GenerateExcelWithCreditLimit from '../components/generate-excel-with-credit-limit';
//////////////////////////////////////////////////

const ReadOnlyField = ({ label, fieldValue, infoText }: Object) => {
  const getFieldValue = (fieldValue: any) => {
    if (G.isObject(fieldValue)) return G.getDisplayedValueFromObject(fieldValue);

    return R.or(fieldValue, '-');
  };

  return (
    <Flex>
      <Box
        pl='5px'
        fontSize={11}
        color={G.getTheme('colors.darkGrey')}
      >
        {label}:
      </Box>
      <TextComponent
        mx={8}
        maxWidth={300}
        fontWeight={700}
        withEllipsis={true}
        title={getFieldValue(fieldValue)}
      >
        {getFieldValue(fieldValue)}
      </TextComponent>
      {infoText && <Info text={infoText} />}
    </Flex>
  );
};


const defaultCompanyFields = {
  [GC.FIELD_ACTIVE]: false,
  [GC.FIELD_BRANCH_DBA]: '',
  [GC.FIELD_BRANCH_NAME]: '',
  [GC.FIELD_BRANCH_SCAC]: '',
  [GC.FIELD_BRANCH_NOTES]: '',
  [GC.FIELD_SALE_PERSONS]: '',
  [GC.FIELD_BROKER_AGENTS]: '',
  [GC.FIELD_BRANCH_SUBTYPE]: '',
  [GC.FIELD_BRANCH_CURRENCY]: '',
  [GC.FIELD_BRANCH_MC_NUMBER]: '',
  [GC.FIELD_BRANCH_DNS_PREFIX]: '',
  [GC.FIELD_BRANCH_BRANCH_TYPE]: '',
  [GC.FIELD_BRANCH_FEDERAL_EIN]: '',
  [GC.FIELD_BRANCH_DUNS_NUMBER]: '',
  [GC.FIELD_BRANCH_CREDIT_USED]: '',
  [GC.FIELD_BRANCH_DESCRIPTION]: '',
  [GC.FIELD_BRANCH_CREDIT_LIMIT]: '',
  [GC.FIELD_BRANCH_ACCOUNT_NAME]: '',
  [GC.FIELD_PRIMARY_DIVISION]: false,
  [GC.FIELD_BRANCH_US_DOT_NUMBER]: '',
  [GC.FIELD_INTEGRATION_ACCOUNT_NUMBER]: '',
  [GC.FIELD_BRANCH_DEACTIVATE_ON_CREDIT_LIMIT]: false,
};

const defaultSettingsSectionFields = {
  importMappers: '',
  customExcelExports: '',
  availableEdiExportMappers: '',
  [GC.FIELD_BRANCH_MAX_SMS_PRICE]: '',
  [GC.FIELD_BRANCH_NORMALIZED_CURRENCY]: '',
  [GC.FIELD_BRANCH_SETTINGS_IFTA_REPORT_ENABLED]: false,
  [GC.FIELD_BRANCH_SETTINGS_CARRIER_MOBILE_ENABLED]: false,
  [GC.FIELD_BRANCH_SETTINGS_FLEET_DRIVER_SMS_ENABLED]: false,
  [GC.FIELD_BRANCH_SETTINGS_CARRIER_DRIVER_SMS_ENABLED]: false,
  [GC.FIELD_BRANCH_SETTINGS_DISTANCE_TO_NEXT_STOP_ENABLED]: false,
};

export const branchValidationSchema = {
  [GC.FIELD_BRANCH_BRANCH_NAME]: Yup.string()
    .nullable(true)
    .min(3, G.getShouldBeFromToLocaleTxt(3, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(3, 40))
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_BRANCH_ACCOUNT_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(2, G.getShouldBeFromToLocaleTxt(2, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(2, 40)),
  [GC.FIELD_BRANCH_BRANCH_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_BRANCH_SCAC]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 6))
    .max(6, G.getShouldBeFromToLocaleTxt(0, 6)),
  [GC.FIELD_BRANCH_CREDIT_LIMIT]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBePositiveLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt()),
  [GC.FIELD_BRANCH_MAX_SMS_PRICE]: Yup.number()
    .nullable(true)
    .max(2, G.getShouldBeLessLocaleTxt(2))
    .min(0, G.getShouldBeBiggerLocaleTxt(0))
    .typeError(G.getShouldBeIntegerLocaleTxt()),
  [GC.FIELD_INTEGRATION_ACCOUNT_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_BRANCH_DNS_PREFIX]: Yup.string()
    .nullable(true)
    .matches(LC.DNS_PREFIX_REGEXP, G.getWindowLocale(
      'messages:validation:dns',
      'DNS prefix may not have 0-9:-:_ at the start and must have minimum 3 members',
    )),
};

export const getDefaultBranchFields = (isSuperAdmin: boolean) => {
  if (G.isTrue(isSuperAdmin)) {
    return {
      ...defaultCompanyFields,
      ...defaultSettingsSectionFields,
    };
  }

  return defaultCompanyFields;
};

const getFieldType = (isFieldEditible: boolean, type: string) => {
  if (G.isTrue(isFieldEditible)) return type;

  return 'customComponent';
};

const getLabel = (isFieldEditible: boolean, label: Array) => {
  if (G.isTrue(isFieldEditible)) return label;

  return null;
};

const inputWrapperStyles = { mb: 30, width: 250 };

export const getFirstCompanyFields = (hasPermissions: boolean, isDivision: boolean, primaryDivisionName: string) => {
  let firstCompanyFields = [
    {
      inputWrapperStyles,
      type: 'customComponent',
      fieldName: GC.FIELD_BRANCH_PARENT_NAME,
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:parent', 'Parent')}
          fieldValue={R.or(
            R.path([GC.FIELD_BRANCH_PARENT_NAME], values),
            G.getWindowLocale('messages:no-parent', 'No parent'),
          )}
          infoText={G.getWindowLocale(
            'messages:enterprise-parent-info',
            'Used to identify current company relationship to enterprise branch',
          )}
        />
      ),
    },
  ];

  if (G.isNotNilAndNotEmpty(primaryDivisionName)) {
    firstCompanyFields = R.append({
      inputWrapperStyles,
      type: 'customComponent',
      fieldName: GC.FIELD_PRIMARY_DIVISION_GUID,
      Component: () => (
        <ReadOnlyField
          fieldValue={primaryDivisionName}
          label={G.getWindowLocale('titles:primary-division-name', 'Primary Division Name')}
        />
      ),
    }, firstCompanyFields);
  }

  if (R.and(isDivision, G.isNilOrEmpty(primaryDivisionName))) {
    firstCompanyFields = R.append({
      type: 'toggle',
      inputWrapperStyles,
      fieldName: GC.FIELD_PRIMARY_DIVISION,
      label: ['titles:primary-division', 'Primary Division'],
    }, firstCompanyFields);
  }

  if (G.isTrue(hasPermissions)) {
    firstCompanyFields = R.append(
      {
        type: 'toggle',
        inputWrapperStyles,
        fieldName: GC.FIELD_ACTIVE,
        label: ['titles:active', 'Active'],
      },
      firstCompanyFields,
    );
  }

  return firstCompanyFields;
};

export const getSecondCompanyFields = (isFieldEditible: boolean, allowDivision: boolean, isSuperAdmin: boolean) => {
  const getBranchTypeOptions = () => {
    let branchTypeOptions = BRANCH_TYPE_OPTIONS;

    if (G.isFalse(allowDivision)) {
      branchTypeOptions = R.of(Array, R.head(BRANCH_TYPE_OPTIONS));
    }

    if (G.isTrue(isSuperAdmin)) {
      return R.append(BRANCH_TYPE_MASTER_OPTION, branchTypeOptions);
    }

    return branchTypeOptions;
  };

  return [
    {
      inputWrapperStyles,
      isRequired: isFieldEditible,
      fieldName: GC.FIELD_BRANCH_NAME,
      type: getFieldType(isFieldEditible, 'text'),
      label: getLabel(isFieldEditible, ['titles:name', 'Name']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:name', 'Name')}
          fieldValue={R.or(R.path([GC.FIELD_BRANCH_NAME], values), 'United')}
        />
      ),
    },
    {
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_DBA,
      type: getFieldType(isFieldEditible, 'text'),
      label: getLabel(isFieldEditible, ['titles:dba', 'DBA']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:dba', 'DBA')}
          fieldValue={R.path([GC.FIELD_BRANCH_DBA], values)}
        />
      ),
    },
    {
      inputWrapperStyles,
      isRequired: isFieldEditible,
      fieldName: GC.FIELD_BRANCH_ACCOUNT_NAME,
      type: getFieldType(isFieldEditible, 'text'),
      label: getLabel(isFieldEditible, ['titles:account-number', 'Account Number']),
      info: G.ifElse(
        isFieldEditible,
        G.getWindowLocale('messages:enterprise-account-number-info', 'Unique Number used to identify enterprise'),
        null,
      ),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          fieldValue={R.path([GC.FIELD_BRANCH_ACCOUNT_NAME], values)}
          label={G.getWindowLocale('titles:account-number', 'Account Number')}
          infoText={
            G.getWindowLocale('messages:enterprise-account-number-info', 'Unique Number used to identify enterprise')
          }
        />
      ),
    },
    {
      inputWrapperStyles,
      isRequired: isFieldEditible,
      options: getBranchTypeOptions(),
      fieldName: GC.FIELD_BRANCH_BRANCH_TYPE,
      type: getFieldType(isFieldEditible, 'select'),
      label: getLabel(isFieldEditible, ['titles:type', 'Type']),
      info: G.ifElse(
        isFieldEditible,
        G.getWindowLocale('messages:enterprise-type-info', 'Each Type has validation on create'),
        null,
      ),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:type', 'Type')}
          fieldValue={R.path([GC.FIELD_BRANCH_BRANCH_TYPE], values)}
          infoText={
            G.getWindowLocale('messages:enterprise-type-info', 'Each Type has validation on create')
          }
        />
      ),
    },
    {
      inputWrapperStyles,
      options: 'subTypeOptions',
      fieldName: GC.FIELD_BRANCH_SUBTYPE,
      type: getFieldType(isFieldEditible, 'select'),
      label: getLabel(isFieldEditible, ['titles:branch-subtype', 'Branch Subtype']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          fieldValue={R.path([GC.FIELD_BRANCH_SUBTYPE], values)}
          label={G.getWindowLocale('titles:branch-subtype', 'Branch Subtype')}
        />
      ),
    },
    {
      type: 'select',
      inputWrapperStyles,
      options: GC.CURRENCY_OPTIONS,
      fieldName: GC.FIELD_BRANCH_CURRENCY,
      label: ['titles:currency', 'Currency'],
    },
    {
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_MC_NUMBER,
      type: getFieldType(isFieldEditible, 'text'),
      label: getLabel(isFieldEditible, ['titles:mc-number', 'MC Number']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:mc-number', 'MC Number')}
          fieldValue={R.path([GC.FIELD_BRANCH_MC_NUMBER], values)}
        />
      ),
    },
    {
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_US_DOT_NUMBER,
      type: getFieldType(isFieldEditible, 'text'),
      label: getLabel(isFieldEditible, ['titles:usdot-number', 'USDOT Number']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:usdot-number', 'USDOT Number')}
          fieldValue={R.path([GC.FIELD_BRANCH_US_DOT_NUMBER], values)}
        />
      ),
    },
    {
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_SCAC,
      type: getFieldType(isFieldEditible, 'text'),
      label: getLabel(isFieldEditible, ['titles:scac', 'SCAC']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:scac', 'SCAC')}
          fieldValue={R.path([GC.FIELD_BRANCH_SCAC], values)}
        />
      ),
    },
  ];
};

export const getThirdCompanyFields = (isFieldEditible: boolean, isMaster: boolean) => {
  let thirdCompanyFields = [
    {
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_FEDERAL_EIN,
      type: getFieldType(isFieldEditible, 'text'),
      label: getLabel(isFieldEditible, ['titles:federal-ein', 'Federal EIN']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:federal-ein', 'Federal EIN')}
          fieldValue={R.path([GC.FIELD_BRANCH_FEDERAL_EIN], values)}
        />
      ),
    },
    {
      type: 'text',
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_DUNS_NUMBER,
      label: ['titles:duns-number', 'D-U-N-S Number'],
    },
  ];

  if (G.isTrue(isMaster)) {
    thirdCompanyFields = R.append(
      {
        inputWrapperStyles,
        fieldName: GC.FIELD_BRANCH_DNS_PREFIX,
        type: getFieldType(isFieldEditible, 'text'),
        label: getLabel(isFieldEditible, ['titles:dns-prefix', 'DNS Prefix']),
        Component: ({ values }: Object) => (
          <ReadOnlyField
            label={G.getWindowLocale('titles:dns-prefix', 'DNS Prefix')}
            fieldValue={R.path([GC.FIELD_BRANCH_DNS_PREFIX], values)}
          />
        ),
      },
      thirdCompanyFields,
    );
  }

  return thirdCompanyFields;
};

export const getFourthCompanyFields = (isFieldEditible: boolean, hasCommissionAssignmentPermissions: boolean) => {
  let fourthCompanyFields = [
    {
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_CREDIT_LIMIT,
      type: getFieldType(isFieldEditible, 'number'),
      label: getLabel(isFieldEditible, ['titles:credit-limit', 'Credit Limit']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:credit-limit', 'Credit Limit')}
          fieldValue={R.path([GC.FIELD_BRANCH_CREDIT_LIMIT], values)}
        />
      ),
    },
    {
      type: 'toggle',
      inputWrapperStyles,
      fieldName: GC.FIELD_BRANCH_DEACTIVATE_ON_CREDIT_LIMIT,
      label: ['titles:deactivate:on-credit-limit', 'Deactivate On Credit Limit'],
    },
    {
      inputWrapperStyles,
      type: 'customComponent',
      fieldName: GC.FIELD_BRANCH_CREDIT_USED,
      additionalLabelComponent: <GenerateExcelWithCreditLimit />,
      Component: ({ values }: Object) => (
        <ReadOnlyField
          label={G.getWindowLocale('titles:credit-used', 'Credit Used')}
          fieldValue={R.path([GC.FIELD_BRANCH_CREDIT_USED], values)}
        />
      ),
    },
    {
      type: 'textarea',
      fieldName: GC.FIELD_BRANCH_DESCRIPTION,
      inputWrapperStyles: { mb: 25, width: 385 },
      label: ['titles:description', 'Description'],
    },
    {
      type: 'textarea',
      fieldName: GC.FIELD_BRANCH_NOTES,
      inputWrapperStyles: { mb: 25, width: 385 },
      label: ['titles:display-notes', 'Display Notes'],
    },
    {
      inputWrapperStyles,
      type: getFieldType(isFieldEditible, 'text'),
      fieldName: GC.FIELD_INTEGRATION_ACCOUNT_NUMBER,
      label: getLabel(isFieldEditible, ['titles:order-import-account-number', 'Order Import Account Number']),
      Component: ({ values }: Object) => (
        <ReadOnlyField
          fieldValue={R.path([GC.FIELD_INTEGRATION_ACCOUNT_NUMBER], values)}
          label={G.getWindowLocale('titles:order-import-account-number', 'Order Import Account Number')}
        />
      ),
    },
  ];

  if (G.isTrue(hasCommissionAssignmentPermissions)) {
    fourthCompanyFields = R.insertAll(
      6,
      [
        {
          isMulti: true,
          type: 'reactSelect',
          fieldName: GC.FIELD_BROKER_AGENTS,
          label: ['titles:broker-agents', 'Broker Agents'],
          options: GC.COMMISSION_ASSIGNMENT_TYPE_BROKER_AGENT,
          inputWrapperStyles: { ...inputWrapperStyles, zIndex: 15 },
        },
        {
          isMulti: true,
          type: 'reactSelect',
          fieldName: GC.FIELD_TEL_SALE_REPRESENTATIVES,
          inputWrapperStyles: { ...inputWrapperStyles, zIndex: 15 },
          options: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE,
          label: `${G.getWindowLocale('titles:tel', 'TEL')} ${
            G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}`,
        },
        {
          isMulti: true,
          type: 'reactSelect',
          fieldName: GC.FIELD_CARRIER_REPRESENTATIVES,
          inputWrapperStyles: { ...inputWrapperStyles, zIndex: 14 },
          options: GC.COMMISSION_ASSIGNMENT_TYPE_CARRIER_REPRESENTATIVE,
          label: ['titles:carrier-representatives', 'Carrier Representatives'],
        },
        {
          isMulti: true,
          type: 'reactSelect',
          fieldName: GC.FIELD_SALE_PERSONS,
          label: ['titles:sale-persons', 'Sale Persons'],
          options: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_PERSON,
          inputWrapperStyles: { ...inputWrapperStyles, zIndex: 14 },
        },
        {
          isMulti: true,
          type: 'reactSelect',
          fieldName: GC.FIELD_CLO_SALE_REPRESENTATIVES,
          inputWrapperStyles: { ...inputWrapperStyles, zIndex: 14 },
          options: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE,
          label: `${G.getWindowLocale('titles:clo', 'CLO')} ${
            G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}`,
        },
        {
          isMulti: true,
          type: 'reactSelect',
          fieldName: GC.FIELD_ACCOUNT_MANAGERS,
          label: ['titles:account-managers', 'Account Managers'],
          options: GC.COMMISSION_ASSIGNMENT_TYPE_ACCOUNT_MANAGER,
          inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13, mb: '0px' },
        },
      ],
      fourthCompanyFields,
    );
  }

  return fourthCompanyFields;
};

export const settingsSectionFields = [
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:ai-enabled', 'AI Enabled'],
    fieldName: GC.FIELD_BRANCH_SETTINGS_AI_ENABLED,
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_SETTINGS_CARRIER_MOBILE_ENABLED,
    label: ['titles:carrier-mobile-enabled', 'Carrier Mobile Enabled'],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_SETTINGS_CARRIER_DRIVER_SMS_ENABLED,
    label: ['titles:carrier-driver-sms-enabled', 'Carrier Driver SMS Enabled'],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:ifta-report', 'IFTA Report'],
    fieldName: GC.FIELD_BRANCH_SETTINGS_IFTA_REPORT_ENABLED,
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_SETTINGS_IGNORE_LOS_SUBSCRIPTION_LIMITS,
    label: ['titles:ignore-los-subscription-limits', 'Ignore LOS Subscription Limits'],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_SETTINGS_FLEET_DRIVER_SMS_ENABLED,
    label: ['titles:fleet-driver-sms-enabled', 'Fleet Driver SMS Enabled'],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_SETTINGS_DISTANCE_TO_NEXT_STOP_ENABLED,
    label: ['titles:distance-to-next-stop-enabled', 'Distance To Next Stop Enabled'],
  },
  {
    type: 'number',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_MAX_SMS_PRICE,
    label: ['titles:max-sms-price', 'Max SMS Price'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_BRANCH_NORMALIZED_CURRENCY,
    label: ['titles:normalized-currency', 'Normalized Currency'],
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'importMappers',
    label: ['titles:import-mappers', 'Import Mappers'],
    fieldName: GC.FIELD_BRANCH_SETTINGS_AVAILABLE_IMPORT_MAPPERS,
  },
  {
    isMulti: true,
    type: 'reactSelect',
    fieldName: 'customExcelExports',
    inputWrapperStyles: { ...inputWrapperStyles, mb: 5 },
    label: ['titles:custom-excel-exports', 'Custom Excel Exports'],
    options: [
      {
        label: 'D + S Driver Daily Hours',
        value: GC.CUSTOM_EXCEL_EXPORT_TYPE_D_PLUS_S_DRIVER_DAILY_HOURS,
      },
    ],
  },
  {
    isMulti: true,
    type: 'reactSelect',
    fieldName: 'availableEdiExportMappers',
    label: ['titles:edi-export-mappers', 'EDI Mappers'],
    inputWrapperStyles: { ...inputWrapperStyles, mb: 5 },
    options: [...availableEdiExportMappers, ...availableEdiLoaderExportMappers],
  },
];

export const additionalLocationColumnSettings = {
  [GC.FIELD_TEMPLATE_ID]: {
    width: 200,
    name: 'titles:template-id',
  },
  [GC.FIELD_LOCATION_TYPE]: {
    width: 200,
    name: 'titles:location-type',
    customLogic: (entity: Object) => R.pathOr('', [GC.FIELD_DISPLAYED_VALUE], entity),
  },
  [GC.FIELD_LOCATION_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_ADDRESS_1]: {
    width: 200,
    name: 'titles:address1',
  },
  [GC.FIELD_ADDRESS_2]: {
    width: 200,
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 200,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 200,
    name: 'titles:state',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    name: 'titles:country',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    name: 'titles:zip',
  },
  [GC.FIELD_CONTACT_NAME]: {
    width: 200,
    name: 'titles:contact-name',
  },
  [GC.FIELD_PHONE]: {
    width: 200,
    name: 'titles:phone',
  },
  [GC.FIELD_PHONE_EXTENSION]: {
    width: 200,
    name: 'titles:phone-extension',
  },
  [GC.FIELD_EMAIL]: {
    width: 200,
    name: 'titles:email',
  },
  [GC.FIELD_FAX]: {
    width: 200,
    name: 'titles:fax',
  },
  [GC.FIELD_COMMENTS]: {
    width: 200,
    name: 'titles:comments',
  },
};
