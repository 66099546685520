import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
import { InputWrapper } from '../../../forms/formik/fieldset2/input-wrapper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, ReactSelect } from '../../../ui';
// feature claim-management
import {
  useGetClos,
  useGetTelsByClo,
  useGetClaimByClaimGuid,
  useGenerateClaimNumber,
  useGetAsyncConfigsForCloDetails,
} from '../hooks';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 200,
};

const settings = {
  [GC.FIELD_CLAIM_NUMBER]: {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:claim-number'],
    fieldName: GC.FIELD_CLAIM_NUMBER,
  },
  [GC.FIELD_CLAIM_DATE]: {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles,
    label: ['titles:claim-date'],
    fieldName: GC.FIELD_CLAIM_DATE,
  },
  [GC.FIELD_STATUS]: {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.CLAIM_STATUS,
    fieldName: GC.FIELD_STATUS,
    label: ['titles:claim-status'],
  },
  [GC.FIELD_SUB_STATUS]: {
    isRequired: false,
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.CLAIM_SUB_STATUS,
    fieldName: GC.FIELD_SUB_STATUS,
    label: ['titles:claim-sub-status'],
  },
  [GC.FIELD_TYPE]: {
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.CLAIM_TYPE,
    fieldName: GC.FIELD_TYPE,
    label: ['titles:claim-type'],
  },
  [GC.FIELD_REASON]: {
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.CLAIM_REASON,
    fieldName: GC.FIELD_REASON,
    label: ['titles:claim-reason'],
  },
};

const validationSchema = Yup.object().shape(R.map(({ isRequired }: Object) => G.ifElse(
  isRequired,
  G.yupStringRequired,
  G.yupStringNotRequired,
), settings));

const SelectClo = ({ cloGuid, setValues, isCloDisabled, initialCloGuid }: Object) => {
  const selectProps = useGetClos({ initialCloGuid });

  const handleChange = (option: Object) => {
    const value = R.propOr(null, GC.FIELD_VALUE, option);

    return setValues((prev: Object) => ({
      ...prev,
      [GC.FIELD_CLO_GUID]: R.propOr(null, GC.FIELD_VALUE, option),
      [GC.FIELD_TEL_GUID]: G.ifElse(R.equals(value, cloGuid), R.prop(GC.FIELD_TEL_GUID, prev), null),
    }));
  };

  return (
    <InputWrapper
      label={['titles:clo']}
      inputWrapperStyles={inputWrapperStyles}
    >
      <ReactSelect
        {...selectProps}
        value={cloGuid}
        onChange={handleChange}
        isDisabled={isCloDisabled}
        noOptionsMessage={() => G.getWindowLocale('titles:no-options', 'No Options')}
      />
    </InputWrapper>
  );
};

const SelectTel = ({ cloGuid, telGuid, setFieldValue, shouldNotAutofillTelGuid }: Object) => {
  const selectProps = useGetTelsByClo({ cloGuid, setFieldValue, shouldNotAutofillTelGuid });

  return (
    <InputWrapper
      label={['titles:tel']}
      inputWrapperStyles={inputWrapperStyles}
    >
      <ReactSelect
        {...selectProps}
        value={telGuid}
        isDisabled={G.isNilOrEmpty(cloGuid)}
        noOptionsMessage={() => G.getWindowLocale('titles:no-options', 'No Options')}
        onChange={(option: Object) => setFieldValue(GC.FIELD_TEL_GUID, R.propOr(null, GC.FIELD_VALUE, option))}
      />
    </InputWrapper>
  );
};

const useForm = (props: Object) => {
  const { submitAction, initialValues, autoGeneratedClaimNumber } = props;

  const onSubmit = ({ claimNumber, ...values }: Object) => {
    const data = {
      ...G.mapObjectEmptyStringFieldsToNull(values),
      [GC.FIELD_CLAIM_NUMBER]: G.ifElse(R.equals(claimNumber, autoGeneratedClaimNumber), null, claimNumber),
    };

    submitAction(data);
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      ...R.map(() => '', settings),
      ...initialValues,
      [GC.FIELD_CLAIM_NUMBER]: R.propOr(autoGeneratedClaimNumber, GC.FIELD_CLAIM_NUMBER, initialValues),
    },
  });

  return formik;
};

const Form = (props: Object) => {
  const { configGroup, isCloDisabled, initialValues } = props;

  const formik = useForm(props);

  const { values, setValues, handleSubmit, setFieldValue } = formik;

  const { cloGuid, telGuid } = values;

  const initialCloGuid = R.prop(GC.FIELD_CLO_GUID, initialValues);
  const initialTelGuid = R.prop(GC.FIELD_TEL_GUID, initialValues);

  const shouldNotAutofillTelGuid = R.and(R.equals(cloGuid, initialCloGuid), R.isNil(initialTelGuid));

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        {...R.propOr({}, 'dropdownOptions', configGroup)}
        fields={R.values(settings)}
        fieldsWrapperStyles={{ pt: 15, width: 420, justifyContent: 'space-between' }}
      />
      <Flex width={420} justifyContent='space-between'>
        <SelectClo
          cloGuid={cloGuid}
          setValues={setValues}
          isCloDisabled={isCloDisabled}
          initialCloGuid={initialCloGuid}
        />
        <SelectTel
          cloGuid={cloGuid}
          telGuid={telGuid}
          setFieldValue={setFieldValue}
          shouldNotAutofillTelGuid={shouldNotAutofillTelGuid}
        />
      </Flex>
      <FormFooter2 />
    </form>
  );
};

const CreateClaim = (props: Object) => {
  const { branchGuid, configGroup } = props;

  const autoGenerate = G.getConfigValueFromStore(
    GC.CLAIM_CLAIM_NUMBER_AUTOGENERATED,
    configGroup,
  );

  const sequenceGuid = G.getConfigValueFromStore(
    GC.CLAIM_CLAIM_NUMBER_SEQUENCE,
    configGroup,
  );

  const autoGeneratedClaimNumber = useGenerateClaimNumber({
    branchGuid,
    sequenceGuid,
    autoGenerate,
  });

  return <Form {...props} autoGeneratedClaimNumber={autoGeneratedClaimNumber} />;
};

const UpdateClaim = (props: Object) => {
  const initialValues = useGetClaimByClaimGuid(props);

  return <Form {...props} initialValues={initialValues} />;
};

const ClaimForm = (props: Object) => {
  const { claimGuid } = props;

  if (R.isNotNil(claimGuid)) return <UpdateClaim {...props} />;

  return <CreateClaim {...props} />;
};

export const ClaimFormForCloDetails = (props: Object) => {
  const { branchGuid } = props;

  const configGroup = useGetAsyncConfigsForCloDetails({
    branchGuid,
    names: [
      GC.CLAIM_TYPE,
      GC.CLAIM_STATUS,
      GC.CLAIM_REASON,
      GC.CLAIM_SUB_STATUS,
      GC.CLAIM_CLAIM_NUMBER_SEQUENCE,
      GC.CLAIM_CLAIM_NUMBER_AUTOGENERATED,
    ],
  });

  return <ClaimForm {...props} configGroup={configGroup} />;
};

export default ClaimForm;
