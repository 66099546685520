import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = { mb: 25, width: 200 };

const fieldSettings = [
  {
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
    label: ['titles:address', 'titles:address'],
    inputWrapperStyles: { ...inputWrapperStyles, width: '100%' },
    locationMapper: (location: Object) =>
      R.assoc(GC.FIELD_ADDRESS, G.getPropFromObject(GC.FIELD_ADDRESS_1, location), location),
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_CITY,
    label: ['titles:city', 'City'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ZIP,
    label: ['titles:zip', 'Zip'],
  },
  {
    inputWrapperStyles,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
  },
];

const coordinatesValidation = Yup.string().nullable(true).required(G.getSelectLocationLocaleTxt());

const validationSchema = Yup.object().shape({
  [GC.FIELD_ADDRESS]: G.yupStringRequired,
  [GC.FIELD_LATITUDE]: coordinatesValidation,
  [GC.FIELD_LONGITUDE]: coordinatesValidation,
});

const defaultLocationFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_ADDRESS]: null,
  [GC.FIELD_LATITUDE]: null,
  [GC.FIELD_LONGITUDE]: null,
};

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultLocationFields, initialValues),
  }),
  pure,
);

export const LocationForm = enhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        fields={fieldSettings}
        fieldsWrapperStyles={{ px: '0px', pt: 15, justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
});
