import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const validationSchema = Yup.object().shape({
  [GC.FIELD_SHOWN_DOCUMENT_TYPE_GUIDS]: G.yupArrayRequired,
});

const defaultFields = {
  [GC.FIELD_SHOWN_DOCUMENT_TYPE_GUIDS]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const fields = [{
  isMulti: true,
  isRequired: true,
  inputWrapperStyles,
  type: 'reactSelect',
  closeMenuOnSelect: false,
  options: 'documentTypeOptions',
  fieldName: GC.FIELD_SHOWN_DOCUMENT_TYPE_GUIDS,
  label: ['titles:shown-document-types', 'Shown Document Types'],
}];

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(values),
  }),
  pure,
);

const InternalDispatchSettingsForm = (props: Object) => {
  const { handleSubmit, documentTypeOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        fields={fields}
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 10 }}
        documentTypeOptions={documentTypeOptions}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(InternalDispatchSettingsForm);
