import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Form, Fieldset2 } from '../../../../forms';
// feature fleet
import * as C from '../../constants';
// feature fleet/driver
import {
  makeSelectAssignDriver,
  makeSelectAvailableTrucks,
  makeSelectAvailableDrivers,
  makeSelectAvailableTrailers,
} from '../selectors';
//////////////////////////////////////////////////

const setOptions = (props: Object, optionsType: string) => {
  const { name, availableDrivers } = props;

  const defaultOption = [{ name: '', values: ''}];
  let options = [];

  if (R.equals(name, C.FLEET_TEAM_DRIVER)) {
    options = R.map((driver: Object) => ({
      value: driver.guid,
      label: G.cutString(`${driver.firstName} ${driver.lastName}`, 20, true),
    }), availableDrivers);
  } else {
    options = R.map((entity: Object) => ({
      value: entity.guid,
      label: entity.unitId,
    }), R.prop(optionsType, props));
  }

  if (R.equals(name, C.FLEET_TRAILER)) return options;

  return R.concat(defaultOption, options);
};

const getDefaultFields = (name: string) => ({
  [`${name}Guid`]: '',
});

export const getValidationSchema = ({ name }: Object) => {
  const schemasMap = {
    [C.FLEET_TEAM_DRIVER]: {
      teamDriverGuid: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    },
    [C.FLEET_TRUCK]: {
      [GC.FIELD_TRUCK_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    },
    [C.FLEET_TRAILER]: {
      [GC.FIELD_TRAILER_GUID]: G.yupArrayRequired.max(3, G.getShouldBePickOnlyLocaleTxt(3)),
    },
  };

  return Yup.object().shape(R.path([name], schemasMap));
};

const getFieldSettings = (name: string) => {
  const isTrailer = R.equals(name, C.FLEET_TRAILER);

  const labelsMap = {
    [C.FLEET_TRUCK]: ['titles:truck', 'Truck'],
    [C.FLEET_TRAILER]: ['titles:trailers', 'Trailers'],
    [C.FLEET_TEAM_DRIVER]: ['titles:team-driver', 'Team Driver'],

  };

  return [
    {
      isRequired: true,
      isMulti: isTrailer,
      options: 'options',
      fieldName: `${name}Guid`,
      label: R.path([name], labelsMap),
      type: G.ifElse(isTrailer, 'reactSelect', 'select'),
      inputWrapperStyles: { mt: 15, mb: 25, width: 270 },
    },
  ];
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: getValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { name, closeModal, submitAction } = props;

      if (R.equals(name, C.FLEET_TRAILER)) {
        const guidTrailerList = R.pathOr([], [GC.FIELD_TRAILER_GUID], values);

        const dataList = {
          [GC.FIELD_TRAILER_GUIDS]: guidTrailerList,
          [GC.FIELD_DRIVER_GUID]: R.prop(GC.FIELD_DRIVER_GUID, props),
        };

        return submitAction(dataList);
      }

      const data = R.assoc(GC.FIELD_DRIVER_GUID, R.prop(GC.FIELD_DRIVER_GUID, props), values);

      submitAction(data);
      closeModal();
    },
    mapPropsToValues: ({ name, initialValues }: Object) => G.setInitialFormikValues(
      getDefaultFields(name),
      initialValues,
    ),
  }),
  lifecycle({
    componentDidMount() {
      const { driverGuid, getAvailable } = this.props;

      getAvailable(driverGuid);
    },
  }),
  pure,
);

const AssignForm = (props: Object) => {
  const { name, optionsType, handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={getFieldSettings(name)}
        options={setOptions(props, optionsType)}
      />
      <FormFooter2 />
    </Form>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  assignInfo: makeSelectAssignDriver(state),
  availableTrucks: makeSelectAvailableTrucks(state),
  availableDrivers: makeSelectAvailableDrivers(state),
  availableTrailers: makeSelectAvailableTrailers(state),
}));

export default connect(mapStateToProps)(enhance(AssignForm));
