import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// feature charge-report
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  reportType: null,
};

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);


const setReportType = (state: Object, data: string) => P.$set('reportType', data, state);

export default createReducer({
  [A.setReports]: setReports,
  [A.setReportType]: setReportType,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
}, initialState);
