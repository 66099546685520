import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
// ui
import { AmousBg } from './ui';
/////////////////////////////////////////////

const formatNotesToOptions = (infoPanelState: Object) => R.map((note: Object) => {
  let data = R.pick(['title', 'subtitle'], note);
  data = R.assoc('order', R.path(['position'], note), data);
  return data;
}, R.or(infoPanelState, []));

const defaultNotesObj = {
  title: '', subtitle: '', order: 1,
};

export const defaultNotes = [{
  order: 1,
  title: '',
  img: AmousBg,
  subtitle: '',
}];

export const setDefaultSetting = (img: string) => R.map((note: Object) => {
  let data = R.pick(['order', 'title', 'subtitle'], note);
  data = R.assoc('img', R.or(img, R.path(['img'], note)), data);
  return data;
}, defaultNotes);

export const createOptions = (panels: array, urlList: object) => {
  const sortByOrder = G.sortByProp('order');
  let options = formatNotesToOptions(panels);
  const isSelected = (key: string) => G.isNotNil(urlList[key]);
  const bgImages = R.map(
    (key: Object) => urlList[key],
    R.filter(isSelected, R.keys(urlList)));
  if (R.and(G.isNilOrEmpty(options), G.isNilOrEmpty(bgImages))) {
    return setDefaultSetting(null);
  }
  if (G.isNilOrEmpty(options)) {
    options = bgImages.map((imagUrl: string, i: number) => ({
      ...defaultNotesObj,
      img: imagUrl,
      order: R.inc(i),
    }));
    return sortByOrder(options);
  }
  if (G.isNilOrEmpty(bgImages)) {
    options = options.map((note: Object, i: number) => {
      let data = R.pick(['order', 'title', 'subtitle'], note);
      data = R.assoc('img', AmousBg, data);
      return data;
    });
    return sortByOrder(options);
  }
  options = options.map((note: Object, i: number) => {
    let data = R.pick(['order', 'title', 'subtitle'], note);
    data = R.assoc('img', R.pathOr(R.head(bgImages), [i], bgImages), data);
    return data;
  });
  if (R.gt(R.length(bgImages), R.length(options))) {
    const freePositions = R.filter(
      (position: number) => (
      R.not(R.any((note: Object) => R.equals(position, note.order), options))
    ), [1, 2, 3]);
    if (R.and(
      G.isNotNilAndNotEmpty(R.path(['secondBackgroundImageUrl'], urlList)),
      R.lt(R.length(options), 2))) {
      options.push({
        ...defaultNotesObj,
        order: R.head(freePositions),
        img: R.path(['secondBackgroundImageUrl'], urlList),
      });
    }
    if (R.and(
      G.isNotNilAndNotEmpty(R.path(['thirdBackgroundImageUrl'], urlList)),
      R.lt(R.length(options), 3))) {
      options.push({
        ...defaultNotesObj,
        order: R.head(freePositions),
        img: R.path(['thirdBackgroundImageUrl'], urlList),
      });
    }
  }
  return sortByOrder(options);
};
