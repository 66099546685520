import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setPageTab = createAction('setPageTab');
export const setActiveTab = createAction('setActiveTab');
export const setBranchName = createAction('setBranchName');
export const getIntlSuccess = createAction('getIntlSuccess');
export const getIntlRequest = createAction('getIntlRequest');
export const setInitialState = createAction('setInitialState');
export const updateIntlSuccess = createAction('updateIntlSuccess');
export const updateIntlRequest = createAction('updateIntlRequest');
export const deleteIntlSuccess = createAction('deleteIntlSuccess');
export const deleteIntlRequest = createAction('deleteIntlRequest');
export const setInputValueToStore = createAction('setInputValueToStore');
export const addNewItemLanguageRequest = createAction('addNewItemLanguage');
export const deleteCustomItemSuccess = createAction('deleteCustomItemSuccess');
export const deleteCustomItemRequest = createAction('deleteCustomItemRequest');
export const updateCustomIntlSuccess = createAction('updateCustomIntlSuccess');
export const updateCustomIntlRequest = createAction('updateCustomIntlRequest');
export const setListLanguageFromBack = createAction('setListLanguageFromBack');
export const getIntlCustomizedSuccess = createAction('getIntlCustomizedSuccess');
export const getIntlCustomizedRequest = createAction('getIntlCustomizedRequest');
export const getMastersBranchListSuccess = createAction('getMastersBranchListSuccess');
export const getAllCustomizedItemsSuccess = createAction('getAllCustomizedItemsSuccess');
export const getAllCustomizedItemsRequest = createAction('getAllCustomizedItemsRequest');
