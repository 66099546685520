import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature app-release
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  version: {
    notes: [],
    release: '',
  },
  latestRelease: null,
};

const getAppReleaseSuccess = (state: Object, data: Object) => (
  P.$set('version', data, state)
);

const getAppReleaseByExternalUrlSuccess = (state: Object, data: Object) => (
  P.$set('latestRelease', data, state)
);

const createAppReleaseSuccess = (state: Object, data: Object) => (
  P.$set('version', data, state)
);

const updateAppReleaseSuccess = (state: Object, data: Object) => (
  P.$set('version', data, state)
);

export default createReducer({
  [A.getAppReleaseSuccess]: getAppReleaseSuccess,
  [A.updateAppReleaseSuccess]: updateAppReleaseSuccess,
  [A.createAppReleaseSuccess]: createAppReleaseSuccess,
  [A.getAppReleaseByExternalUrlSuccess]: getAppReleaseByExternalUrlSuccess,
}, initialState);
