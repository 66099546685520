// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getFilterParamsFromColumnSettings } from '../../../report-common';
//////////////////////////////////////////////////

const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowEditBtn: true,
  moreBtnCellWidth: 50,
  allowSelectAll: false,
  checkBoxCellWidth: 80,
  expandableItems: false,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
  checkBoxCellJustifyContent: 'unset',
};

const columnSettings = {
  [GC.FIELD_CLAIM_NUMBER]: {
    width: 200,
    name: 'titles:claim-number',
  },
  [GC.FIELD_CLAIM_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:claim-date',
    filter: {
      type: 'date',
    },
  },
  [GC.GRC.STATUS_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:status',
  },
  [`${GC.FIELD_SUB_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: 'titles:sub-status',
  },
  [`${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: 'titles:type',
  },
  [`${GC.FIELD_REASON}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: 'titles:reason',
  },
  [`${GC.FIELD_CLO}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`]: {
    width: 200,
    useExpandedContainer: true,
    guidPropName: GC.GRC.CLO_GUID,
    type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    name: ['titles:clo', 'titles:primary-ref-value'],
  },
  [`${GC.FIELD_TEL}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`]: {
    width: 200,
    useExpandedContainer: true,
    guidPropName: GC.GRC.TEL_GUID,
    type: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
    name: ['titles:tel', 'titles:primary-ref-value'],
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    name: 'titles:branch',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
    filter: {
      type: 'date',
    },
  },
};

const FILTER_PARAMS = getFilterParamsFromColumnSettings(columnSettings);

export {
  tableSettings,
  FILTER_PARAMS,
  columnSettings,
};
