import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import {
  sageAccountMappingFieldSettings,
  sageAccountMappingAccountTypeKeysMap,
  defaultQBDistributionLineAccountMappingFields,
  qbDistributionLineAccountMappingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getOptionsForSelect = (props: Object) => {
  const { values, accountList, accessorialList, initialValues, accountMappingList } = props;

  const scope = G.getPropFromObject(GC.FIELD_SCOPE, values);
  const accessorialAccountTypes = R.compose(
    R.map(R.map(R.prop(GC.FIELD_ACCESSORIAL_CONFIG_GUID))),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.filter(R.propEq(GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, GC.FIELD_ACCOUNT_TYPE)),
  )(accountMappingList);
  const accountTypes = R.compose(
    R.map(R.map(
      R.keys,
      R.groupBy(R.prop(GC.FIELD_ACCOUNT_TYPE)),
    )),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.filter(({ accountType }: Object) => G.notEquals(accountType, GC.INVOICE_MAPPING_TYPE_ASSESSORIALS)),
  )(accountMappingList);
  const accountTypeOptions = R.compose(
    G.addEmptyOptionToDropDown,
    R.map((item: string) => {
      const label = G.getEnumLocale(item);

      return {
        label,
        [GC.FIELD_VALUE]: item,
        disabled: R.and(
          R.includes(item, R.pathOr([], [scope], accountTypes)),
          G.notEquals(item, G.getPropFromObject(GC.FIELD_ACCOUNT_TYPE, initialValues)),
        ),
      };
    }),
    R.pathOr([], [scope]),
  )(sageAccountMappingAccountTypeKeysMap);
  const accessorialOptions = R.compose(
    G.addEmptyOptionToDropDown,
    R.map(({ displayedValue, originalConfigGuid }: Object) => ({
      [GC.FIELD_LABEL]: displayedValue,
      [GC.FIELD_VALUE]: originalConfigGuid,
      disabled: R.and(
        R.includes(originalConfigGuid, R.pathOr([], [scope], accessorialAccountTypes)),
        G.notEquals(originalConfigGuid, G.getPropFromObject(GC.FIELD_ACCESSORIAL_CONFIG_GUID, initialValues)),
      ),
    })),
  )(accessorialList);
  const accountOptions = R.compose(
    G.addEmptyOptionToDropDown,
    R.map((item: Object) => ({
      [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
      [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, item),
    })),
  )(accountList);

  return { accountOptions, accountTypeOptions, accessorialOptions };
};

const enhance = compose(
  withFormik({
    validationSchema: qbDistributionLineAccountMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultQBDistributionLineAccountMappingFields,
      initialValues,
    ),
  }),
  withHandlers({
    handleChangeScope: ({ setValues }: Object) => (event: Object) => {
      const values = R.assoc(
        GC.FIELD_SCOPE,
        R.path(['currentTarget', GC.FIELD_VALUE], event),
        defaultQBDistributionLineAccountMappingFields,
      );

      setValues(values);
    },
  }),
  pure,
);

const AccountMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      handleCustomChange={props.handleChangeScope}
      optionsForSelect={getOptionsForSelect(props)}
      fields={sageAccountMappingFieldSettings(R.path(['values', GC.FIELD_ACCOUNT_TYPE], props))}
    />
    <FormFooter boxStyles={{ pt: 10, px: '5px' }} closeModal={props.closeModal} />
  </form>
);

export default enhance(AccountMappingForm);
