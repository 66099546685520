import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import ContainerForm from '../../../../forms/forms/container-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import { updateOrderContainerRequest } from '../actions';
//////////////////////////////////////////////////

export const withContainersTableGroupRowActions = compose(
  connect(
    null, { updateOrderContainerRequest },
  ),
  withHandlers({
    handleUpdateContainer: (props: Object) => () => {
      const { load, entity, openModal, closeModal, updateOrderContainerRequest } = props;

      const component = (
        <ContainerForm
          initialValues={entity}
          closeModal={closeModal}
          submitAction={updateOrderContainerRequest}
          branchGuid={G.getPropFromObject(GC.BRANCH_GUID, load)}
          containerTypes={G.getAmousContainerTypeListFromWindow()}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 'auto',
          height: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:edit-container', 'Edit Container'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
