import React from 'react';
// components
import { QuickFilterOutsideControl } from '../../../components/quick-filter';
// helpers
import * as G from '../../../helpers';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const QuickFilterOutsideControlComponent = () => {
  const description = '<QuickFilterOutsideControl /> component manages an input field used for filtering content.';

  const usage = (
    <ul>
      <li>Parameter `<i>width</i>` is the width of the input field.</li>
      <li>Parameter `<i>label</i>` is a text displayed as the label for the input field.</li>
      <li>Parameter `<i>handleSetFilter</i>` is a function to establish the filter value.</li>
      <li>Parameter `<i>placeholder</i>` is a text displayed as a placeholder within the input field.</li>
      <li>Parameter `<i>endIconName</i>` is an icon JSX element displayed at the end of the input field.</li>
      <li>Parameter `<i>outsideValue</i>` is a value sourced externally, not controlled by this component.</li>
      <li>Parameter `<i>inputWrapperStyles</i>` is a styling properties applied to the input wrapper encompassing the field.</li>
    </ul>
  );

  const styles = {
    width: 200,
    height: 25,
    version: 2,
    fontSize: 14,
    p: '0 0 0 8px',
    borderColor: G.getTheme('colors.light.grey'),
  };

  const handleSetFilter = (value: string) => {
    window.alert(`Filter value is ${value}`);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      description={description}
      name='QuickFilterOutsideControl'
      path='src/components/quick-filter'
    >
      <QuickFilterOutsideControl
        withoutLabel={true}
        inputProps={styles}
        boxStyles={{ mb: '4px' }}
        outsideValue='outsideValue'
        handleSetFilter={handleSetFilter}
        shouldClearTimeoutOnKeyPress={true}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
      />
    </ComponentStoryWrapper>
  );
};

export default QuickFilterOutsideControlComponent;
