import * as R from 'ramda';
import React, { useState, Fragment, useEffect, useCallback } from 'react';
// components
import { TabsMui } from '../../../../components/tabs-mui';
import { Document, tabsProps, AddDocument, getOperations } from '../../../../components/activities';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../../utilities/http';
// ui
import { Box, scrollableContainerCss4px } from '../../../../ui';
// feature fleet-list
import { ServiceVendorInfo } from './service-vendor-info';
//////////////////////////////////////////////////

const Documents = (props: Object) => {
  const { openModal, closeModal, documents, openLoader, closeLoader, setActivities, primaryObjectGuid } = props;

  if (G.isNilOrEmpty(primaryObjectGuid)) return null;

  const handleDocuments = useCallback(async (method: string, options: Object, documentGuid: string) => {
    openLoader();

    const operations = getOperations(documentGuid, true);

    const endpoints = {
      post: endpointsMap.fleetServiceIssueDocument,
      get: endpointsMap.fleetServiceIssueDocumentList,
      delete: endpointsMap.getFleetServiceIssueDocumentEndpoint(documentGuid),
    };

    const res = await sendRequest(method, R.prop(method, endpoints), { ...options, params: { primaryObjectGuid } });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const documents = R.call(R.prop(method, operations), R.prop(GC.FIELD_DOCUMENTS, prev), data);

        return R.assoc(GC.FIELD_DOCUMENTS, documents, prev);
      });
    } else {
      G.handleFailResponseSimple(res, 'handleDocuments fail');
    }

    closeLoader();
  }, [primaryObjectGuid]);

  useEffect(() => {
    if (R.isNil(documents)) handleDocuments('get');
  }, [documents, handleDocuments]);

  const handleDownloadOrPreviewDocument = useCallback(async ({ guid, actionType }: Object) => {
    openLoader();

    const options = {
      params: { guid },
      resType: 'arraybuffer',
    };

    const endpoint = endpointsMap.getFleetServiceIssueDocumentDownloadEndpoint(guid);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(actionType, 'download')) {
        G.saveFileFromResponse(res);
      } else {
        G.openFileInWindowFromArrayBufferResponse(res);
      }
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, [primaryObjectGuid]);

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        height='calc(100% - 139px)'
        css={scrollableContainerCss4px}
      >
        {
          R.or(documents, []).map((document: Object) => (
            <Document
              document={document}
              withThumbnail={true}
              openModal={openModal}
              closeModal={closeModal}
              handleDocuments={handleDocuments}
              key={G.getGuidFromObject(document)}
              handleDownloadOrPreviewDocument={handleDownloadOrPreviewDocument}
            />
          ))
        }
      </Box>
      <AddDocument handleDocuments={handleDocuments} />
    </Fragment>
  );
};

const ServiceVendor = (props: Object) => {
  const { openLoader, closeLoader, repairZones, setActivities, serviceVendor, serviceVendorGuid } = props;

  const handleGetServiceVendor = async () => {
    if (G.isNilOrEmpty(serviceVendorGuid)) return;

    openLoader();

    const res = await sendRequest('get', endpointsMap.getServiceVendorByGuid(serviceVendorGuid));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => R.assoc(GC.FIELD_SERVICE_VENDOR, data, prev));
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  };

  const handleGetServiceVendorRepairZones = async () => {
    if (G.isNilOrEmpty(serviceVendorGuid)) return;

    openLoader();

    const options = { params: { serviceVendorGuid } };

    const res = await sendRequest('get', endpointsMap.serviceVendorRepairZoneList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => R.assoc('repairZones', data, prev));
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  };

  useEffect(() => {
    if (R.or(
      R.or(R.isNil(repairZones), R.isNil(serviceVendor)),
      G.notEquals(serviceVendorGuid, G.getGuidFromObject(serviceVendor)),
    )) {
      handleGetServiceVendor();
      handleGetServiceVendorRepairZones();
    }
  }, [serviceVendorGuid]);

  return (
    <Box
      mr={10}
      overflowY='auto'
      p='15px 5px 0 15px'
      height='calc(100% - 65px)'
      css={scrollableContainerCss4px}
    >
      {
        G.isNotNilAndNotEmpty(serviceVendor) &&
        <ServiceVendorInfo repairZones={repairZones} serviceVendor={serviceVendor} />
      }
    </Box>
  );
};

const getTabs = (serviceVendorGuid: string) => {
  const tabs = [
    { text: G.getWindowLocale('titles:documents', 'Documents') },
    { text: G.getWindowLocale('titles:service-vendor', 'Service Vendor') },
  ];

  if (G.isNilOrEmpty(serviceVendorGuid)) return R.init(tabs);

  return tabs;
};

export const Activities = (props: Object) => {
  const {
    maxHeight,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    primaryObjectGuid,
    values: { serviceVendorGuid },
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [activities, setActivities] = useState({});

  const tabs = getTabs(serviceVendorGuid);
  const tabsLength = R.length(tabs);

  useEffect(() => {
    if (R.equals(1, activeTab)) setActiveTab(0);
  }, [tabsLength]);

  const commonProps = {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    setActivities,
    primaryObjectGuid,
  };

  const tabsContentMap = [
    <Documents {...commonProps} key={0} documents={R.prop(GC.FIELD_DOCUMENTS, activities)} />,
    <ServiceVendor
      {...commonProps}
      key={1}
      serviceVendorGuid={serviceVendorGuid}
      repairZones={R.prop('repairZones', activities)}
      serviceVendor={R.prop(GC.FIELD_SERVICE_VENDOR, activities)}
    />,
  ];

  return (
    <Box
      width={550}
      maxHeight={maxHeight}
      borderLeft='1px solid'
      bg={G.getTheme('colors.whiteGrey')}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <TabsMui
        tabs={tabs}
        tabsProps={tabsProps}
        activeMuiTab={activeTab}
        setActiveMuiTab={setActiveTab}
      />
      {R.prop(activeTab, tabsContentMap)}
    </Box>
  );
};
