import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import { vinLookupFields, defaultVinLookupFields, getVinLookupValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultVinLookupFields,
      props.initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: ({ isEditMode }: Object) => Yup.object().shape(getVinLookupValidationSchema(isEditMode)),
  }),
  pure,
);

const VinLookupForm = (props: Object) => (
  <Box mx='auto' width={270}>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={vinLookupFields}
        handlers={{ handleDisableType: () => props.isEditMode }}
      />
      <FormFooter boxStyles={{ py: 10 }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(VinLookupForm);
