import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const defaultFields = {
  [GC.FIELD_STATUS]: null,
  [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW]: null,
  [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW]: null,
};

const defaultStyles = {
  width: 210,
  errorTop: '101%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

export const fields = [
  {
    ...defaultStyles,
    type: 'select',
    errorTop: '105%',
    loc: 'titles:status',
    flexDirection: 'row',
    options: 'statusOptions',
    fieldName: GC.FIELD_STATUS,
    placeholder: 'titles:status',
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:first-row',
    options: 'firstRowOptions',
    fieldName: GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:second-row',
    options: 'secondRowOptions',
    fieldName: GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW,
  },
];

export const validationSchemaObject = {
  [GC.FIELD_STATUS]: G.yupStringRequired,
  [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW]: G.yupArrayRequired
    .max(3, G.getMaximumItemsToPickLocaleText(3)),
  [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW]: G.yupArrayNotRequired
    .max(3, G.getMaximumItemsToPickLocaleText(3)),
};

export const getRowOptions = (values: Object, loadType: string, isFirstRow: boolean) => {
  const statusValue = values[GC.FIELD_STATUS];
  if (G.isNilOrEmpty(statusValue)) return [];
  const actionOptions = G.getLoadStatusActionOptions(loadType, statusValue);
  const optionsToFilter = G.ifElse(
    isFirstRow,
    R.pathOr([], [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW], values),
    R.pathOr([], [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW], values),
  );
  const options = R.filter(
    (option: Object) => R.isNil(R.find(R.equals(option[GC.FIELD_VALUE]), optionsToFilter)),
    actionOptions,
  );
  return options;
};
