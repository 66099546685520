import React, { Fragment } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
// components
import { EmptyList } from '../../../components/table/ui';
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, StickedFlex } from '../../../ui';
// feature audit2
import AuditList from './audit-list';
import {
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectAuditLoading,
} from '../selectors';
/////////////////////////////////////////////////

const AuditGroup = (props: Object) => {
  const { groupedList, handleGetAuditList } = props;

  const loading = useSelector(makeSelectAuditLoading());
  const totalCount = useSelector(makeSelectTotalCount());
  const pagination = useSelector(makeSelectPagination());

  return (
    <Fragment>
      {
        groupedList.map((item: Object, i: number) => (
          <Box key={i} data-testid='testid-auditGroup'>
            <StickedFlex
              px={15}
              top={0}
              zIndex={3}
              height={24}
              color='dark.mainDark'
              background={G.getTheme('tables.subTitle.bgColor')}
            >
              {item.title}
            </StickedFlex>
            <AuditList list={item.list} {...props} />
          </Box>
        ))
      }
      {
        R.gt(totalCount, pagination.offset) &&
        <Waypoint onEnter={handleGetAuditList} />
      }
      {
        loading &&
        <LocalLoader localLoaderOpen={true} />
      }
      {
        R.and(G.isNilOrEmpty(groupedList), R.not(loading)) &&
        <EmptyList>{G.getWindowLocale('titles:emptyList', 'Empty List')}</EmptyList>
      }
    </Fragment>
  );
};

export default AuditGroup;
