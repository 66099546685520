import styled, { css } from 'styled-components';
import FormControl from '@mui/material/FormControl';
// helpers
import * as G from '../../helpers';
//////////////////////////////////////////////////

const azureColor = G.getTheme('colors.azure');
const darkGreyColor = G.getTheme('colors.dark.grey');

export const StyledFormControl = styled(FormControl)`
  height: 32px;
  width: 185px;
  margin-right: 20px;

  & .MuiSelect-icon {
    display: none;
  }

  & .MuiSelect-multiple {
    cursor: text;
  }

  & .MuiFormLabel-root {
    font-size: 12px;
  }

  & .MuiOutlinedInput-root {
    height: 32px;
    font-size: 12px;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${darkGreyColor};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${darkGreyColor};
    }
  }

  ${({ focusedOrHasValue }: Object) => focusedOrHasValue && css`
    & .MuiFormLabel-root {
      top: 2px;
      color: ${azureColor};
    }

    & .MuiOutlinedInput-root {
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${azureColor};
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: ${azureColor};
      }
    }
  `}
`;
