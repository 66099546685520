import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet
import ContactForm from './contact-form';
import { contactColumnSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const ContactsFormGroupTable = (props: Object) => (
  <FormGroupTable
    entities={props.entities}
    fields={props.collapsedGroup}
    groupName={GC.FIELD_CONTACTS}
    entitiesFields={contactColumnSettings}
    handleDeleteRow={props.handleRemoveContact}
    handleEditRow={props.handleCreateOrUpdateContact}
    handleAddClick={props.handleCreateOrUpdateContact}
    panelTitle={G.getWindowLocale('titles:contacts', 'Contacts')}
    isOpened={R.path(['collapsedGroup', GC.FIELD_CONTACTS], props)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(GC.FIELD_CONTACTS)} />
);

const enhance = compose(
  withHandlers({
    handleCreateOrUpdateContact: (props: Object) => (entity: Object = {}) => {
      const { openModal, closeModal, updateAction, createAction } = props;
      const isEditMode = G.isNotEmpty(entity);
      const submitAction = G.ifElse(
        isEditMode,
        updateAction,
        createAction,
      );
      const title = G.ifElse(
        isEditMode,
        G.getWindowLocale('titles:edit-contact', 'Edit Contact'),
        G.getWindowLocale('titles:add-contact', 'Add Contact'),
      );
      const component = <ContactForm closeModal={closeModal} initialValues={entity} submitAction={submitAction} />;
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 465,
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };
      openModal(modal);
    },
    handleRemoveContact: (props: Object) => ({ guid, emergencyContactName }: Object) => {
      const { openModal, closeModal, removeAction } = props;
      const message = G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove');
      const component = <ConfirmComponent textLocale={message} name={emergencyContactName} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                closeModal();
                removeAction(guid);
              },
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

export default enhance(ContactsFormGroupTable);
