import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// features
import NoteFormComponent from '../../forms/note-form';
import ChatMessageFormComponent from '../../forms/chat-message-form';
import { withMultipleMailSending } from '../../../mail-sending/hocs';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import * as C from '../../constants';
import { withLoadAddDocument } from './with-document-actions';
import {
  createLoadNoteRequest,
  createLoadChatMessageRequest } from '../actions';
//////////////////////////////////////////////////

export const withAddTableEntity = compose(
  withMultipleMailSending,
  withLoadAddDocument,
  connect(
    null,
    {
      createLoadNoteRequest,
      createLoadChatMessageRequest,
    },
  ),
  withHandlers({
    handleSendMail: ({ load, handleSendMultiMail }: Object) => () =>
      handleSendMultiMail(load, 'telDocumentList', G.getPropFromObject(GC.BRANCH_GUID, load)),
    handleAddNote: ({ load, openModal, closeModal, createLoadNoteRequest }: Object) => () => {
      const component = (
        <NoteFormComponent
          closeModal={closeModal}
          handleActionLoadNote={(data: Object) =>
            createLoadNoteRequest(R.assoc(GC.FIELD_LOAD_GUID, G.getGuidFromObject(load), data))}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '410px',
          height: 'max-content',
          title: G.getWindowLocale('actions:add-note', 'Add Note'),
        },
      };
      openModal(modal);
    },
    handleAddChatMessage: ({ load, openModal, closeModal, createLoadChatMessageRequest }: Object) => () => {
      const handleActionLoadChatMessage = ({ text }: Object) => {
        const currentUserGuid = G.getAmousCurrentUserGuidFromWindow();
        const data = {
          text,
          authorUserGuid: currentUserGuid,
          [GC.FIELD_TEL_GUID]: G.getGuidFromObject(load),
        };
        createLoadChatMessageRequest(data);
      };
      const component = (
        <ChatMessageFormComponent
          closeModal={closeModal}
          handleActionLoadChatMessage={handleActionLoadChatMessage}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '380px',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-message', 'Add Message'),
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handleAddTableEntity: (props: Object) => (options: Object) => {
      const {
        handleAddNote,
        handleSendMail,
        activeListName,
        handleAddDocument,
        handleAddChatMessage } = props;
      const handlersMap = {
        [C.ACTIVE_LIST_NOTES]: handleAddNote,
        [C.ACTIVE_LIST_MAILS]: handleSendMail,
        [C.ACTIVE_LIST_DOCUMENTS]: handleAddDocument,
        [C.ACTIVE_LIST_CHAT_MESSAGES]: handleAddChatMessage,
      };

      handlersMap[activeListName](options);
    },
  }),
  pure,
);
