import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import { getModalOptions } from '../../helpers';
import ConfigComponent from '../../components/config-component';
import MarginViolationRuleForm from './components/margin-violation-rule-form';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import { getDefaultDropdownConfigFormComponent } from '../../components/dropdown-config-modal';
import {
  updateConfigsRequest,
  removeMarginViolationRuleRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateMarginViolationRuleRequest,
} from '../../actions';
import {
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectConfigInitialValues,
  makeSelectConfigInheritedValues,
  makeSelectMarginViolationRuleList,
} from '../../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(),
  itemPageConfigEnhancer,
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const {
        openModal,
        closeModal,
        configOptions,
        marginViolationRuleList,
        createOrUpdateMarginViolationRuleRequest,
      } = props;

      if (R.equals(configName, GC.CUSTOM_MARGIN_VIOLATION_RULE_CONFIG)) {
        const component = (
          <MarginViolationRuleForm
            closeModal={closeModal}
            marginViolationRuleList={marginViolationRuleList}
            submitAction={createOrUpdateMarginViolationRuleRequest}
            transportationModes={R.pathOr([], ['transportationModes'], configOptions)}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 440,
            height: 'auto',
            title: G.getAddTitle(['titles:margin-violation-rule', 'Margin Violation Rule']),
          },
        };

        return openModal(modal);
      }

      const title = R.or(popupTitle, 'titles:add-item');
      const component = getDefaultDropdownConfigFormComponent(configName, props);
      const modal = getModalOptions(component, title);

      openModal(modal);
    },
    handleEditMarginViolationRule: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        configOptions,
        marginViolationRuleList,
        createOrUpdateMarginViolationRuleRequest,
      } = props;

      const component = (
        <MarginViolationRuleForm
          closeModal={closeModal}
          initialValues={initialValues}
          marginViolationRuleList={marginViolationRuleList}
          submitAction={createOrUpdateMarginViolationRuleRequest}
          transportationModes={R.pathOr([], ['transportationModes'], configOptions)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '440px',
          height: 'auto',
          title: G.getEditTitle(['titles:margin-violation-rule', 'Margin Violation Rule']),
        },
      };

      openModal(modal);
    },
    handleRemoveMarginViolationRule: ({ removeMarginViolationRuleRequest }: Object) => removeMarginViolationRuleRequest,
  }),
  pure,
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    pageTitle='titles:rating'
    groupSettings={GC.RATE_ENGINE_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  marginViolationRuleList: makeSelectMarginViolationRuleList()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.RATE_ENGINE_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.RATE_ENGINE_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.RATE_ENGINE_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.RATE_ENGINE_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  removeMarginViolationRuleRequest,
  restoreInheritedByConfigTypeRequest,
  createOrUpdateMarginViolationRuleRequest,
})(enhance(ConfigPageComponent));
