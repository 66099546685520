import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet
import PayToLocationForm from './pay-to-location-form';
import { payToLocationColumnSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const PayToLocationFormGroupTable = (props: Object) => {
  const {
    payToLocation,
    collapsedGroup,
    handleToggleFormGroup,
    handleDeletePayToLocation,
    handleAddOrEditPayToLocation,
  } = props;

  const isExistedPayToLocation = G.isNotNilAndNotEmpty(payToLocation);
  const entities = G.ifElse(
    isExistedPayToLocation,
    R.of(Array, payToLocation),
    [],
  );

  return (
    <FormGroupTable
      entities={entities}
      fields={collapsedGroup}
      groupName='payToLocation'
      isHiddenAddIcon={isExistedPayToLocation}
      handleDeleteRow={handleDeletePayToLocation}
      entitiesFields={payToLocationColumnSettings}
      handleEditRow={handleAddOrEditPayToLocation}
      handleAddClick={handleAddOrEditPayToLocation}
      isOpened={G.getPropFromObject('payToLocation', collapsedGroup)}
      handleToggleFormGroup={() => handleToggleFormGroup('payToLocation')}
      panelTitle={G.getWindowLocale('titles:pay-to-location', 'Pay to Location')}
    />
  );
};

const enhance = compose(
  withHandlers({
    handleDeletePayToLocation: (props: Object) => (entity: Object) => {
      const { openModal, removePayToLocation } = props;

      const component = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_LOCATION_NAME, entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removePayToLocation(entity),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleAddOrEditPayToLocation: (props: Object) => (entity: Object = {}) => {
      const {
        openModal,
        closeModal,
        isServiceVendor,
        addOrEditPayToLocation,
      } = props;

      let initialValues = entity;
      const isEditMode = G.isNotNilAndNotEmpty(initialValues);
      const title = G.ifElse(
        isEditMode,
        G.getWindowLocale('titles:edit-pay-to-location', 'Edit Pay To Location'),
        G.getWindowLocale('titles:add-pay-to-location', 'Add Pay To Location'),
      );

      if (isEditMode) {
        initialValues = R.assoc(
          GC.FIELD_ADDRESS_1,
          G.getPropFromObject(GC.FIELD_ADDRESS, initialValues),
          initialValues,
        );
      }

      const component = (
        <PayToLocationForm
          closeModal={closeModal}
          initialValues={initialValues}
          isServiceVendor={isServiceVendor}
          submitAction={addOrEditPayToLocation}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 390,
          height: 'auto',
          btnWidth: '190px',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default enhance(PayToLocationFormGroupTable);
