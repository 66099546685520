import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useMemo, useEffect } from 'react';
// components
import { Table } from '../../../../components/table';
// features
import { getColumnSettings } from '../../../dispatch-details-new/settings/related-data-table-settings';
// helpers/constants
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// feature claim-management
import { makeSelectRelatedTels } from '../../selectors';
import { getRelatedTelsRequest } from '../../actions';
//////////////////////////////////////////////////

const RelatedTels = (props: Object) => {
  const { dispatch, openFixedPopup } = props;

  const relatedTels = useSelector(makeSelectRelatedTels());

  useEffect(() => {
    if (R.isNil(relatedTels)) dispatch(getRelatedTelsRequest());
  }, []);

  const tableProps = useMemo(() => {
    const report = {
      fields: [
        { name: GC.FIELD_PRIMARY_REFERENCE_VALUE, sequence: 0 },
        { name: GC.FIELD_BRANCH_BRANCH_NAME, sequence: 1 },
        { name: GC.FIELD_FIRST_PICKUP, sequence: 2 },
        { name: GC.FIELD_LAST_DROP, sequence: 3 },
        { name: GC.FIELD_LOAD_CARRIER_FLEET, sequence: 4 },
      ],
    };

    const tableSettings = {
      titleRowHeight: 30,
      tableRowHeight: 65,
      allowEditBtn: false,
      useMainColors: true,
      checkBoxCellWidth: 0,
      allowSelectItems: false,
      maxHeight: 'fit-content',
    };

    const columnSettings = {
      ...getColumnSettings(GC.LOAD_TYPE_TEL, 'USD', openFixedPopup, true),
      [GC.FIELD_BRANCH_BRANCH_NAME]: {
        width: 200,
        name: 'titles:branch',
      },
    };

    return {
      report,
      tableSettings,
      columnSettings,
      loading: false,
      allChecked: false,
      withoutWaypoint: true,
      rowStyles: {
        my: '5px',
        borderTop: true,
        borderColor: 'colors.bgGrey',
        activeBorderColor: 'colors.bgGrey',
      },
    };
  }, []);

  return <Table {...tableProps} itemList={relatedTels} />;
};

export default withFixedPopover(RelatedTels);
