import * as R from 'ramda';
import intervalToDuration from 'date-fns/intervalToDuration';
// components
import { pickerColorGrey, pickerColorYellow } from '../../components/color-picker';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature drivers-card
import { GROUP_TYPE_NONE } from './constants';
import {
  getGroupedAssignedLoadsRequest,
  getNotGroupedAssignedLoadsRequest,
} from './actions';
//////////////////////////////////////////////////

const getDuration = (firstEventDate: string, lastEventDate: string) => {
  try {
    const duration = intervalToDuration({
      end: G.getNewDate(lastEventDate),
      start: G.getNewDate(firstEventDate),
    });

    return duration;
  } catch (error) {
    return null;
  }
};

const getSortedPeriodsFromDriverTelsAndUnavailablePeriods = (driver: Object) => {
  const tels = G.getPropFromObject2('tels', driver, []);
  const unavailablePeriods = G.getPropFromObject2('unavailablePeriods', driver, []);

  const telsToUse = R.map(
    (item: Object) => ({
      'lastEventLocation': R.path([GC.SYSTEM_OBJECT_LAST_EVENT, GC.FIELD_LOCATION], item),
      'lastEventLateDate': R.path([GC.SYSTEM_OBJECT_LAST_EVENT, GC.FIELD_LATE_DATE], item),
      'firstEventLocation': R.path([GC.SYSTEM_OBJECT_FIRST_EVENT, GC.FIELD_LOCATION], item),
      'firstEventEarlyDate': R.path([GC.SYSTEM_OBJECT_FIRST_EVENT, GC.FIELD_EARLY_DATE], item),
    }),
    tels,
  );

  const periodsToUse = R.map(
    (item: Object) => ({
      'lastEventLocation': R.path([GC.FIELD_LOCATION], item),
      'lastEventLateDate': R.path([GC.FIELD_END_DATE], item),
      'firstEventLocation': R.path([GC.FIELD_LOCATION], item),
      'firstEventEarlyDate': R.path([GC.FIELD_START_DATE], item),
    }),
    unavailablePeriods,
  );

  const sortedPeriods = G.sortListByDate(R.concat(telsToUse, periodsToUse), 'firstEventEarlyDate');

  return sortedPeriods;
};

const getEmptyPeriodsFromDriverLoads = (driver: Object, filters: Object) => {
  const { dateTo, dateFrom } = filters;

  const sortedPeriods = getSortedPeriodsFromDriverTelsAndUnavailablePeriods(driver);

  if (G.isNilOrEmpty(sortedPeriods)) {
    return R.of(Array, {
      'firstEventDate': G.toStartOfDayFromDate(dateFrom),
      'lastEventDate': G.toEndOfDayFromDate(dateTo),
    });
  }

  const emptyPeriods = [];

  sortedPeriods.forEach((item: Object, i: number, arr: Array) => {
    const lastEventLateDate = G.getPropFromObject('lastEventLateDate', item);

    const item2 = R.path([R.inc(i)], arr);

    if (G.isZero(i)) {
      emptyPeriods.push({
        isFirst: true,
        'firstEventDate': G.toStartOfDayFromDate(dateFrom),
        'lastEventDate': R.or(G.getPropFromObject('firstEventEarlyDate', item), dateTo),
      });
    }

    if (G.isNilOrEmpty(item2)) {
      return emptyPeriods.push({
        isLast: true,
        'firstEventDate': lastEventLateDate,
        'lastEventDate': G.toEndOfDayFromDate(dateTo),
        'lastEventLocation': R.path(['lastEventLocation'], item),
        'firstEventLocation': R.path(['firstEventLocation'], item),
      });
    }

    const nextEventEarlyDate = G.getPropFromObject('firstEventEarlyDate', item2);

    if (G.isBefore(nextEventEarlyDate, lastEventLateDate)) return;

    const duration = getDuration(lastEventLateDate, nextEventEarlyDate);

    if (G.isNilOrEmpty(duration)) return;

    const { days, hours } = duration;

    // TODO: check using here GC.UI_DRIVER_CARDS_DEFAULT_MIN_EMPTY_HOURS
    if (R.gt(R.add(hours, R.multiply(days, 24), 0))) {
      emptyPeriods.push({
        isLast: false,
        'firstEventDate': lastEventLateDate,
        'lastEventDate': nextEventEarlyDate,
        'lastEventLocation': R.path(['lastEventLocation'], item2),
        'firstEventLocation': R.path(['firstEventLocation'], item),
      });
    }
  });

  return emptyPeriods;
};

const mapDriverLoadsToEmptyPeriods = (driverLoads: Array, minEmptyHours: any, filters: Object) => {
  // TODO: check if we need using minEmptyHours
  // if (G.isNilOrEmpty(minEmptyHours)) return [];

  return R.compose(
    R.map((item: Object) => R.assoc(
      'emptyPeriods',
      getEmptyPeriodsFromDriverLoads(item, filters),
      item,
    )),
  )(driverLoads);
};

const filterDriverLoadsByLocationAndDate = (
  items: Array,
  locationResult: Object,
  searchRadiusValue: number,
  searchDate: string,
) => {
  const searchDayMidday = G.toMidday(searchDate);

  const filtered = R.filter((item: Object) => {
    const { emptyPeriods } = item;

    let filter = false;
    let filter2 = false;

    emptyPeriods.forEach((item: Object) => {
      const { lastEventDate, firstEventDate, firstEventLocation, lastEventLocation } = item;

      if (R.and(G.isNotNilAndNotEmpty(locationResult), G.isNotNilAndNotEmpty(searchRadiusValue))) {
        if (G.isNotNilAndNotEmpty(firstEventLocation)) {
          const distance = G.getDistanceBetweenTwoPoints(locationResult, firstEventLocation);

          if (R.lt(distance, searchRadiusValue)) filter = true;
        }

        if (G.isNotNilAndNotEmpty(lastEventLocation)) {
          const distance = G.getDistanceBetweenTwoPoints(locationResult, lastEventLocation);

          if (R.lt(distance, searchRadiusValue)) filter = true;
        }
      }

      if (G.isAllTrue(G.isValidMoment(searchDate), G.isValidMoment(firstEventDate), G.isValidMoment(lastEventDate))) {
        if (G.isBetween(searchDayMidday, firstEventDate, lastEventDate)) filter2 = true;

        if (G.isSameMoment(searchDate, firstEventDate, 'day')) {
          const midnight = G.isMidnight(firstEventDate);

          const date = G.toStartOfDayFromDate(firstEventDate);

          if (R.and(G.isFalse(midnight), G.isAfter(searchDayMidday, date, 'second'))) filter2 = true;
        }

        if (G.isSameMoment(searchDate, lastEventDate, 'day')) {
          const midnight = G.isMidnight(lastEventDate);

          const date = G.toEndOfDayFromDate(lastEventDate);

          if (R.and(G.isFalse(midnight), G.isBefore(searchDayMidday, date, 'second'))) filter2 = true;
        }
      }
    });

    if (G.isAllTrue(
      G.isValidMoment(searchDate),
      G.isNotNilAndNotEmpty(locationResult),
      G.isNotNilAndNotEmpty(searchRadiusValue),
    )) return R.and(filter, filter2);

    if (G.isValidMoment(searchDate)) return filter2;

    return filter;
  }, items);

  return filtered;
};

const subtractOneFromNumber = (item: number) => R.subtract(item, 1);

const getDefaultFilterDates = (dayFilter: number) => {
  const initialFilterDays = subtractOneFromNumber(R.or(dayFilter, 7));
  const currentDate = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);
  const dayToUse = R.divide(initialFilterDays, 2);

  const dateTo = G.addMomentTimeWithFormat(
    currentDate,
    dayToUse,
    'days',
    GC.DEFAULT_DATE_FORMAT,
  );

  const dateFrom = G.subtractMomentTimeWithFormat(
    currentDate,
    dayToUse,
    'days',
    GC.DEFAULT_DATE_FORMAT,
  );

  const minDate = G.subtractMomentTimeWithFormat(
    dateFrom,
    dayToUse,
    'days',
    GC.DEFAULT_DATE_FORMAT,
  );

  const maxDate = G.subtractMomentTimeWithFormat(
    dateTo,
    dayToUse,
    'days',
    GC.DEFAULT_DATE_FORMAT,
  );

  return { dateTo, dateFrom, minDate, maxDate, initialFilterDays: dayFilter };
};

const getDefaultFilterRequestDates = (dayFilter: number) => {
  const filters = getDefaultFilterDates(dayFilter);

  const { dateTo, dateFrom } = filters;

  return {
    filters,
    dateTo: G.createLocalDateTimeFromInstanceOrISOString(dateTo, G.getDateTimeFormat(true)),
    dateFrom: G.createLocalDateTimeFromInstanceOrISOString(dateFrom, G.getDateTimeFormat(true)),
  };
};

const getRequestFilters = (
  groupBy: string,
  filtersStore: Object,
  referenceValue: string,
  globalFilterValue: string,
  getGroupedAssignedLoadsRequest: Function,
  getNotGroupedAssignedLoadsRequest: Function,
) => {
  const { filter, dateTo, dateFrom } = filtersStore;

  const action = G.ifElse(
    R.equals(groupBy, GROUP_TYPE_NONE),
    getNotGroupedAssignedLoadsRequest,
    getGroupedAssignedLoadsRequest,
  );

  return {
    action,
    options: {
      data: {
        filter,
        groupBy,
        referenceValue,
        globalFilterValue,
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        dateTo: G.createLocalDateTimeFromInstanceOrISOString(dateTo, G.getDateTimeFormat(true)),
        dateFrom: G.createLocalDateTimeFromInstanceOrISOString(dateFrom, G.getDateTimeFormat(true)),
      },
    },
  };
};

const getGuidsFromGroupedAssignedLoads = (loads: Array) => R.compose(
  R.map(({ guid }: Object) => guid),
  R.reduce((acc: Array, group: Object) => R.concat(acc, R.pathOr([], ['tels'], group)), []),
)(loads);

const getGuidsFromNotGroupedAssignedLoads = (loads: Array) => R.compose(
  R.map(({ guid }: Object) => guid),
  R.reduce((acc: Array, group: Object) => R.concat(acc, R.pathOr([], ['tels'], group)), []),
  R.pathOr([], [0, 'driverLoads']),
)(loads);

const getMainCardWidth = (show24HoursView: boolean, zoom: number) => R.multiply(
  G.ifElse(G.isTrue(show24HoursView), 1200, 480),
  zoom,
);

const getCardDayMultiplier = (show24HoursView: boolean, zoom: number) => R.multiply(
  G.ifElse(G.isTrue(show24HoursView), 50, 20),
  zoom,
);

const white07Color = G.getTheme('colors.white07');
const mainDarkTransparent = G.getTheme('colors.mainDarkTransparent');

const getCardActionColor = (pickerColor: string) => {
  if (R.includes(pickerColor, [pickerColorGrey, pickerColorYellow])) {
    return mainDarkTransparent;
  }

  return white07Color;
};

const filterDriverLoadsByTeam = (teamFilterValue: boolean, driverLoads: Array) => {
  if (G.isFalse(teamFilterValue)) {
    return driverLoads.filter(({ primaryDriverGuid }: Object) => G.isNilOrEmpty(primaryDriverGuid));
  }

  return driverLoads;
};

const mapDriverLoadsWithFullSecondaryDriverInfo = (driverLoads: Array) => {
  return driverLoads.map((driver: Object) => {
    const { secondaryDriver } = driver;

    if (G.isNilOrEmpty(secondaryDriver)) return driver;

    const secondaryDriverGuid = G.getGuidFromObject(secondaryDriver);

    const searched = driverLoads.find((driver: Object) => R.equals(G.getGuidFromObject(driver), secondaryDriverGuid));

    if (G.isNilOrEmpty(searched)) return driver;

    return R.assoc('secondaryDriver', searched, driver);
  });
};

const getAmousBranchesOptions = () => {
  const amousBranches = G.getItemFromWindow('amousBranches');

  return R.map((branch: Object) => ({
    value: G.getGuidFromObject(branch),
    label: R.prop('enterpriseName', branch),
  }), R.values(amousBranches));
};

export {
  getDuration,
  getMainCardWidth,
  getRequestFilters,
  getCardActionColor,
  getCardDayMultiplier,
  getDefaultFilterDates,
  subtractOneFromNumber,
  filterDriverLoadsByTeam,
  getAmousBranchesOptions,
  mapDriverLoadsToEmptyPeriods,
  getDefaultFilterRequestDates,
  getGuidsFromGroupedAssignedLoads,
  filterDriverLoadsByLocationAndDate,
  getGuidsFromNotGroupedAssignedLoads,
  mapDriverLoadsWithFullSecondaryDriverInfo,
  getSortedPeriodsFromDriverTelsAndUnavailablePeriods,
};
