import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  COUNTRY_OPTIONS,
  ITEM_TYPE_OPTIONS,
  PAYMENT_TERM_OPTIONS,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  getCloGlobalSearchOptions,
  FLAT_PERCENT_RATE_UNIT_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  getCustomerContractUpliftApplyToEnumOptions,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, StyledLink } from '../../../ui';
// feature configs
import { MultiselectFieldComponent } from '../ui';
//////////////////////////////////////////////////

const generalTab = [
  {
    title: 'titles:primary-reference',
    fields: {
      [GC.CLO_PRIMARY_REFERENCE_TYPE]: {
        type: 'select',
        options: 'cloReferenceTypes',
        name: 'titles:primary-reference-type',
        nameForAttribute: GC.CLO_PRIMARY_REFERENCE_TYPE,
      },
      [GC.CLO_PRIMARY_REFERENCE_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-primary-reference',
        nameForAttribute: GC.CLO_PRIMARY_REFERENCE_AUTOGENERATED,
      },
      [GC.CLO_PRIMARY_REFERENCE_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:primary-reference-sequence',
        nameForAttribute: GC.CLO_PRIMARY_REFERENCE_SEQUENCE,
      },
      [GC.CLO_PRIMARY_REFERENCE_PREFIX]: {
        type: 'input',
        name: 'titles:primary-reference-prefix',
        nameForAttribute: GC.CLO_PRIMARY_REFERENCE_PREFIX,
      },
      [GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX]: {
        type: 'input',
        name: 'titles:primary-reference-division-prefix',
        nameForAttribute: GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
      },
      [GC.CLO_GENERAL_COPY_REFERENCES]: {
        zIndex: 14,
        type: 'list',
        options: 'telReferenceTypes',
        name: 'titles:show-tel-refs-on-clo',
        component: MultiselectFieldComponent,
        nameForAttribute: GC.CLO_GENERAL_COPY_REFERENCES,
      },
      [GC.CLO_GENERAL_COPY_ALL_REFERENCES]: {
        type: 'switcher',
        name: 'titles:show-all-trip-refs-on-order',
        nameForAttribute: GC.CLO_GENERAL_COPY_ALL_REFERENCES,
      },
    },
  },
  {
    title: 'titles:cross-division-dispatch',
    fields: {
      [GC.CLO_CROSS_DIVISION_COPY_PRIMARY_REF_FROM_TEL_AS_REFERENCE]: {
        type: 'switcher',
        name: 'titles:copy-primary-ref-from-trip-as-ref',
        nameForAttribute: GC.CLO_CROSS_DIVISION_COPY_PRIMARY_REF_FROM_TEL_AS_REFERENCE,
      },
      [GC.CLO_CROSS_DIVISION_PRIMARY_REFERENCE_COPY_TYPE]: {
        type: 'select',
        options: 'cloReferenceTypes',
        name: 'titles:primary-ref-copy-type',
        nameForAttribute: GC.CLO_CROSS_DIVISION_PRIMARY_REFERENCE_COPY_TYPE,
      },
      [GC.CLO_CROSS_DIVISION_DISPATCH_BILL_TO_LOCATION]: {
        type: 'input',
        name: 'titles:template-for-dispatched-order-bill-to',
        nameForAttribute: GC.CLO_CROSS_DIVISION_DISPATCH_BILL_TO_LOCATION,
        infoText: G.getWindowLocale(
          'messages:cross-division-dispatch-bill-to-location',
          'The location template entered here will be set as the Bill To for Orders that were created with Cross Division Dispatch',
        ),
      },
    },
  },
  {
    title: 'titles:dispatch-board',
    fields: {
      [GC.CLO_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES]: {
        zIndex: 13,
        type: 'list',
        component: MultiselectFieldComponent,
        options: getCloGlobalSearchOptions(),
        name: 'titles:used-global-search-types',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES,
      },
      [GC.CLO_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE]: {
        type: 'select',
        options: 'defaultCloGlobalSearchOptions',
        name: 'titles:default-global-search-type',
        nameForAttribute: GC.CLO_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE,
      },
    },
  },
  {
    title: 'titles:general',
    fields: {
      [GC.CLO_GENERAL_FILTER_BY_DIVISION]: {
        type: 'select',
        name: 'titles:filter-by-division',
        nameForAttribute: GC.CLO_GENERAL_FILTER_BY_DIVISION,
        options: [
          {
            value: GC.FILTER_BY_DIVISION_TYPE_NONE,
            label: G.getWindowLocale('titles:none', 'None'),
          },
          {
            value: GC.FILTER_BY_DIVISION_TYPE_TRIP_DIVISION,
            label: G.getWindowLocale('titles:trip-division', 'Trip Division'),
          },
          {
            value: GC.FILTER_BY_DIVISION_TYPE_ORIGINATED_DIVISION,
            label: G.getWindowLocale('titles:originated-division', 'Originated Division'),
          },
        ],
      },
      [GC.CLO_GENERAL_SHOW_TEL_DOCUMENTS_ON_CLO]: {
        zIndex: 12,
        type: 'list',
        component: MultiselectFieldComponent,
        options: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
        name: 'titles:show-tel-documents-on-clo',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_GENERAL_SHOW_TEL_DOCUMENTS_ON_CLO,
      },
      [GC.CLO_GENERAL_CUSTOMER_REFERENCE_TYPE]: {
        type: 'select',
        options: 'cloReferenceTypes',
        name: 'titles:customer-reference-type',
        nameForAttribute: GC.CLO_GENERAL_CUSTOMER_REFERENCE_TYPE,
      },
      [GC.CLO_GENERAL_DEFAULT_REFERENCES]: {
        type: 'list',
        options: 'cloReferenceTypes',
        name: 'titles:required-references',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_GENERAL_DEFAULT_REFERENCES,
      },
      [GC.CLO_GENERAL_CONFIRMATION_DOCUMENT_TYPE]: {
        type: 'select',
        options: 'availableDocumentTypes',
        name: 'titles:confirmation-document-type',
        nameForAttribute: GC.CLO_GENERAL_CONFIRMATION_DOCUMENT_TYPE,
      },
      [GC.CLO_GENERAL_DEFAULT_EQUIPMENT]: {
        type: 'select',
        options: 'equipmentType',
        name: 'titles:equipment',
        nameForAttribute: GC.CLO_GENERAL_DEFAULT_EQUIPMENT,
      },
      [GC.CLO_GENERAL_EVENTS_INTERVAL]: {
        type: 'input',
        name: 'titles:stops-interval',
        nameForAttribute: GC.CLO_GENERAL_EVENTS_INTERVAL,
        validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(0, 15)],
      },
      [GC.CLO_GENERAL_TIME_INTERVAL]: {
        type: 'input',
        name: 'titles:time-interval-mins',
        nameForAttribute: GC.CLO_GENERAL_TIME_INTERVAL,
        validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(0, 30)],
      },
      [GC.CLO_GENERAL_TEL_CREATION_MODE]: {
        type: 'select',
        name: 'titles:tel-creation-mode',
        nameForAttribute: GC.CLO_GENERAL_TEL_CREATION_MODE,
        options: [
          { label: G.getWindowLocale('titles:no-tel', 'No TEL'), value: GC.TEL_CREATION_MODE_NO_TEL },
          { label: G.getWindowLocale('titles:multi-trip', 'Multi Trip'), value: GC.TEL_CREATION_MODE_MULTI_TEL },
          { label: G.getWindowLocale('titles:single-tel', 'Single TEL'), value: GC.TEL_CREATION_MODE_SINGLE_TEL },
        ],
      },
      [GC.CLO_GENERAL_STATUS_MESSAGE_COPY_FROM_TEL]: {
        zIndex: 13,
        type: 'list',
        name: 'titles:copy-from-tel',
        options: 'statusMessageList',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_GENERAL_STATUS_MESSAGE_COPY_FROM_TEL,
      },
      [GC.CLO_GENERAL_STATUS_MESSAGE_EXCLUDE_COPY_FROM_TEL]: {
        zIndex: 12,
        type: 'list',
        options: 'statusMessageList',
        name: 'titles:exclude-copy-from-tel',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_GENERAL_STATUS_MESSAGE_EXCLUDE_COPY_FROM_TEL,
      },
      [GC.CLO_GENERAL_USE_LINKED_ORDERS]: {
        type: 'switcher',
        name: 'titles:use-linked-orders',
        nameForAttribute: GC.CLO_GENERAL_USE_LINKED_ORDERS,
      },
      [GC.CLO_GENERAL_APPROVAL_REQUIRED]: {
        type: 'switcher',
        name: 'titles:approval-required',
        nameForAttribute: GC.CLO_GENERAL_APPROVAL_REQUIRED,
      },
      [GC.CLO_GENERAL_MAKE_BOOKED_BY_ONE_TEL]: {
        type: 'switcher',
        name: 'titles:make-booked-by-one-trip',
        nameForAttribute: GC.CLO_GENERAL_MAKE_BOOKED_BY_ONE_TEL,
      },
    },
  },
  {
    title: 'titles:default',
    fields: {
      [GC.CLO_DEFAULT_PICKUP_LOCATION_TYPE]: {
        type: 'select',
        options: 'locationType',
        name: 'titles:pickup-location-type',
        nameForAttribute: GC.CLO_DEFAULT_PICKUP_LOCATION_TYPE,
      },
      [GC.CLO_DEFAULT_DROP_LOCATION_TYPE]: {
        type: 'select',
        options: 'locationType',
        name: 'titles:drop-location-type',
        nameForAttribute: GC.CLO_DEFAULT_DROP_LOCATION_TYPE,
      },
      [GC.CLO_DEFAULT_SPECIAL_INSTRUCTION]: {
        width: 400,
        type: 'textarea',
        name: 'titles:special-instructions',
        nameForAttribute: GC.CLO_DEFAULT_SPECIAL_INSTRUCTION,
      },
    },
  },
  {
    title: 'titles:origin',
    fields: {
      [GC.CLO_GENERAL_SHIP_FROM]: {
        type: 'input',
        name: 'titles:origin-template-id',
        nameForAttribute: GC.CLO_GENERAL_SHIP_FROM,
      },
      [GC.CLO_GENERAL_PICKUP_EARLY_TIME]: {
        type: 'time',
        name: 'titles:origin-early-time',
        nameForAttribute: GC.CLO_GENERAL_PICKUP_EARLY_TIME,
      },
      [GC.CLO_GENERAL_PICKUP_INTERVAL]: {
        type: 'input',
        validate: G.isEmptyOrNumeric,
        name: 'titles:origin-window',
        nameForAttribute: GC.CLO_GENERAL_PICKUP_INTERVAL,
      },
    },
  },
  {
    title: 'titles:destination',
    fields: {
      [GC.CLO_GENERAL_SHIP_TO]: {
        type: 'input',
        name: 'titles:destination-template-id',
        nameForAttribute: GC.CLO_GENERAL_SHIP_TO,
      },
      [GC.CLO_GENERAL_DROP_EARLY_TIME]: {
        type: 'time',
        name: 'titles:destination-early-time',
        nameForAttribute: GC.CLO_GENERAL_DROP_EARLY_TIME,
      },
      [GC.CLO_GENERAL_DROP_INTERVAL]: {
        type: 'input',
        validate: G.isEmptyOrNumeric,
        name: 'titles:destination-window',
        nameForAttribute: GC.CLO_GENERAL_DROP_INTERVAL,
      },
    },
  },
  {
    title: 'titles:ui',
    fields: {
      [GC.CLO_UI_MULTI_CREATE_FIELDS]: {
        type: 'list',
        name: 'titles:multi-create-fields',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_UI_MULTI_CREATE_FIELDS,
        options: [
          { label: G.getWindowLocale('titles:references', 'References'), value: GC.CONFIG_FIELD_REFERENCE },
          { label: G.getWindowLocale('titles:containers', 'Containers'), value: GC.CONFIG_FIELD_CONTAINER },
        ],
      },
      [GC.CLO_UI_SINGLE_APPOINTMENT_TIME]: {
        type: 'switcher',
        name: 'titles:single-appointment-time',
        nameForAttribute: GC.CLO_UI_SINGLE_APPOINTMENT_TIME,
      },
      [GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME]: {
        type: 'switcher',
        name: 'titles:separate-event-date-and-time',
        nameForAttribute: GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME,
      },
    },
  },
];

const orderTypeTab = [
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:order-types',
    configName: GC.CLO_ORDER_TYPE_DROPDOWN,
  },
  {
    title: 'titles:order-type',
    fields: {
      [GC.CLO_GENERAL_USE_ORDER_TYPE]: {
        type: 'switcher',
        name: 'titles:use',
        nameForAttribute: GC.CLO_GENERAL_USE_ORDER_TYPE,
      },
      [GC.CLO_GENERAL_DEFAULT_ORDER_TYPE]: {
        type: 'select',
        options: 'orderType',
        name: 'titles:default',
        nameForAttribute: GC.CLO_GENERAL_DEFAULT_ORDER_TYPE,
      },
      [GC.CLO_GENERAL_ORDER_TYPE_REQUIRED]: {
        type: 'switcher',
        name: 'titles:required',
        nameForAttribute: GC.CLO_GENERAL_ORDER_TYPE_REQUIRED,
      },
    },
  },
];


const billToTab = [
  {
    title: 'titles:general',
    fields: {
      [GC.CLO_GENERAL_BILL_TO]: {
        type: 'input',
        name: 'titles:bill-to-template-id',
        nameForAttribute: GC.CLO_GENERAL_BILL_TO,
      },
      [GC.CLO_GENERAL_USE_ENTERPRISE_BILL_TO]: {
        type: 'switcher',
        name: 'titles:use-branch-bill-to',
        nameForAttribute: GC.CLO_GENERAL_USE_ENTERPRISE_BILL_TO,
      },
      [GC.CLO_GENERAL_REMIT_TO]: {
        type: 'input',
        name: 'titles:remit-to-template-id',
        nameForAttribute: GC.CLO_GENERAL_REMIT_TO,
      },
      [GC.CLO_GENERAL_PAYMENT_TERMS]: {
        type: 'select',
        name: 'titles:payment-terms',
        options: PAYMENT_TERM_OPTIONS,
        nameForAttribute: GC.CLO_GENERAL_PAYMENT_TERMS,
      },
    },
  },
];

const itemFields = [
  {
    value: GC.FIELD_DESCRIPTION,
    label: G.getWindowLocale('titles:description', 'Description'),
  },
  {
    value: GC.FIELD_ITEM_FREIGHT_CLASS,
    label: G.getWindowLocale('titles:freight-class', 'Freight Class'),
  },
  {
    value: GC.FIELD_ITEM_QUANTITY,
    label: G.getWindowLocale('titles:package-quantity', 'Package Quantity'),
  },
  {
    value: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
    label: G.getWindowLocale('titles:dimensions', 'Dimensions'),
  },
  {
    value: GC.FIELD_ITEM_COMMODITY,
    label: G.getWindowLocale('titles:commodity', 'Commodity'),
  },
  {
    value: GC.FIELD_ITEM_MONETARY_VALUE,
    label: G.getWindowLocale('titles:monetary-value', 'Monetary Value'),
  },
  {
    value: GC.FIELD_ITEM_N_M_F_C,
    label: G.getWindowLocale('titles:nmfc', 'NMFC'),
  },
  {
    value: GC.FIELD_ITEM_CUSTOMER_PART_NUMBER,
    label: G.getWindowLocale('titles:customer-part-num', 'Customer Part Number'),
  },
  {
    value: GC.FIELD_ITEM_VOLUME,
    label: G.getWindowLocale('titles:volume', 'Volume'),
  },
  {
    value: GC.FIELD_ITEM_STACKABILITY,
    label: G.getWindowLocale('titles:stackability', 'Stackability'),
  },
  {
    value: GC.FIELD_ITEM_TEMPERATURE_TYPE,
    label: G.getWindowLocale('titles:temperature-type', 'Temperature Type'),
  },
  {
    value: GC.FIELD_ITEM_TEMPERATURE_LOW,
    label: G.getWindowLocale('titles:temperature-range', 'Temperature Range'),
  },
  {
    value: GC.FIELD_ITEM_TEMPERATURE_SENSOR,
    label: G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor'),
  },
  {
    value: GC.FIELD_ITEM_DECLARED_VALUE,
    label: G.getWindowLocale('titles:declared-value', 'Declared Value'),
  },
  // car hauler
  {
    value: GC.FIELD_VIN,
    label: G.getWindowLocale('titles:vin', 'Vin'),
  },
  {
    value: GC.FIELD_YEAR,
    label: G.getWindowLocale('titles:year', 'Year'),
  },
  {
    value: GC.FIELD_MANUFACTURER,
    label: G.getWindowLocale('titles:manufacturer', 'Manufacturer'),
  },
  {
    value: GC.FIELD_OPERATIONAL,
    label: G.getWindowLocale('titles:operational', 'Operational'),
  },
  {
    value: GC.FIELD_COLOR,
    label: G.getWindowLocale('titles:color', 'Color'),
  },
  {
    value: GC.FIELD_MAKE,
    label: G.getWindowLocale('titles:make', 'Make'),
  },
  {
    value: GC.FIELD_MODEL,
    label: G.getWindowLocale('titles:model', 'Model'),
  },
  {
    value: GC.FIELD_SUB_MODEL,
    label: G.getWindowLocale('titles:sub-model', 'Submodel'),
  },
  {
    value: GC.FIELD_ODOMETER,
    label: G.getWindowLocale('titles:odometer', 'Odometer'),
  },
  {
    value: GC.FIELD_BAY,
    label: G.getWindowLocale('titles:bay', 'Bay'),
  },
  {
    value: GC.FIELD_WHEELBASE,
    label: G.getWindowLocale('titles:wheelbase', 'Wheelbase'),
  },
  {
    value: GC.FIELD_LICENSE_PLATE,
    label: G.getWindowLocale('titles:license-plate', 'License Plate'),
  },
  {
    value: GC.FIELD_ORIGIN_STATE,
    label: G.getWindowLocale('titles:origin-state', 'Origin State'),
  },
  {
    value: GC.FIELD_ORIGIN_COUNTRY,
    label: G.getWindowLocale('titles:origin-country', 'Origin Country'),
  },
  {
    value: GC.FIELD_ORIGIN_OF_GOODS,
    label: G.getWindowLocale('titles:origin-of-goods', 'Origin Of Goods'),
  },
];

const itemContainerTab = [
  {
    title: 'titles:general',
    fields: {
      [GC.CLO_GENERAL_DEFAULT_ITEM]: {
        type: 'input',
        name: 'titles:default-item-id',
        nameForAttribute: GC.CLO_GENERAL_DEFAULT_ITEM,
      },
      [GC.CLO_ITEM_DEFAULT_WEIGHT_UOM]: {
        type: 'select',
        name: 'titles:default-item-weight-uom',
        options: R.tail(DEFAULT_ITEM_WEIGHT_UOM_OPTIONS),
        nameForAttribute: GC.CLO_ITEM_DEFAULT_WEIGHT_UOM,
      },
      [GC.CLO_ITEM_DEFAULT_PACKAGE_TYPE]: {
        type: 'select',
        name: 'titles:default-item-package-type',
        nameForAttribute: GC.CLO_ITEM_DEFAULT_PACKAGE_TYPE,
        options: R.tail(DEFAULT_QUANTITY_RATE_UNIT_OPTIONS),
      },
      [GC.CLO_ITEM_DEFAULT_TEMPERATURE_UOM]: {
        type: 'select',
        options: R.tail(ITEM_TEMPERATURE_OPTIONS),
        name: 'titles:default-item-temperature-uom',
        nameForAttribute: GC.CLO_ITEM_DEFAULT_TEMPERATURE_UOM,
      },
      [GC.CLO_ITEM_MAX_WIDTH]: {
        type: 'input',
        name: 'titles:max-width',
        validate: G.isEmptyOrInteger,
        nameForAttribute: GC.CLO_ITEM_MAX_WIDTH,
      },
      [GC.CLO_ITEM_MAX_HEIGHT]: {
        type: 'input',
        name: 'titles:max-height',
        validate: G.isEmptyOrInteger,
        nameForAttribute: GC.CLO_ITEM_MAX_HEIGHT,
      },
      [GC.CLO_ITEM_MAX_LENGTH]: {
        type: 'input',
        name: 'titles:max-length',
        validate: G.isEmptyOrInteger,
        nameForAttribute: GC.CLO_ITEM_MAX_LENGTH,
      },
      [GC.CLO_ITEM_DIMENSION_UOM]: {
        type: 'select',
        name: 'titles:dimension-uom',
        options: R.tail(ITEM_DIMENSIONS_OPTIONS),
        nameForAttribute: GC.CLO_ITEM_DIMENSION_UOM,
      },
      [GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR]: {
        type: 'select',
        name: 'titles:freight-class-calculator',
        nameForAttribute: GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR,
        options: [
          { value: GC.FREIGHT_CLASS_CALCULATOR_STANDARD, label: G.getWindowLocale('titles:standard', 'Standard') },
          { value: GC.FREIGHT_CLASS_CALCULATOR_FAK, label: G.getWindowLocale('titles:fak', 'FAK') },
        ],
      },
      [GC.CLO_ITEM_USE_DIFFERENT_TYPES]: {
        type: 'switcher',
        name: 'titles:use-different-types',
        nameForAttribute: GC.CLO_ITEM_USE_DIFFERENT_TYPES,
      },
      [GC.CLO_ITEM_DEFAULT_TYPE]: {
        type: 'select',
        name: 'titles:default-type',
        options: R.tail(ITEM_TYPE_OPTIONS),
        nameForAttribute: GC.CLO_ITEM_DEFAULT_TYPE,
      },
      [GC.CLO_ITEM_DEFAULT_COUNTRY]: {
        type: 'select',
        options: COUNTRY_OPTIONS,
        name: 'titles:default-country',
        nameForAttribute: GC.CLO_ITEM_DEFAULT_COUNTRY,
      },
    },
  },
  {
    title: 'titles:container',
    fields: {
      [GC.CLO_GENERAL_USE_CONTAINERS]: {
        type: 'switcher',
        name: 'titles:use-containers',
        nameForAttribute: GC.CLO_GENERAL_USE_CONTAINERS,
      },
      [GC.CLO_GENERAL_AUTO_CREATE_CONTAINER_ITEM]: {
        type: 'switcher',
        name: 'titles:auto-create-container-item',
        nameForAttribute: GC.CLO_GENERAL_AUTO_CREATE_CONTAINER_ITEM,
      },
    },
  },
  {
    title: 'titles:ui',
    fields: {
      [GC.CLO_UI_ADDITIONAL_ITEM_FIELDS]: {
        zIndex: 13,
        type: 'list',
        options: itemFields,
        component: MultiselectFieldComponent,
        name: 'titles:additional-item-fields',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_UI_ADDITIONAL_ITEM_FIELDS,
      },
      [GC.CLO_ITEM_REQUIRED_FIELDS]: {
        zIndex: 12,
        type: 'list',
        options: itemFields,
        name: 'titles:required-item-fields',
        component: MultiselectFieldComponent,
        nameForAttribute: GC.CLO_ITEM_REQUIRED_FIELDS,
        shouldGetInheritedValueFromConfigOptions: true,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:damage-report-area',
    configName: GC.CLO_ITEM_DAMAGE_REPORT_AREA,
  },
  {
    title: 'titles:damage-report-areas',
    fields: {
      [GC.CLO_ITEM_REQUIRED_DAMAGE_REPORT_AREAS]: {
        type: 'list',
        component: MultiselectFieldComponent,
        name: 'titles:required-damage-report-areas',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_ITEM_REQUIRED_DAMAGE_REPORT_AREAS,
        options: {
          from: 'dropdowns',
          dropdownName: GC.CLO_ITEM_DAMAGE_REPORT_AREA,
        },
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:damage-report-type',
    configName: GC.CLO_ITEM_DAMAGE_REPORT_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:damage-report-severity',
    configName: GC.CLO_ITEM_DAMAGE_REPORT_SEVERITY,
  },
];

const rateTab = [
  {
    title: 'titles:general',
    fields: {
      [GC.CLO_RATE_RECALCULATE_FUEL_CHARGE_ON_STATUSES]: {
        zIndex: 12,
        type: 'list',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:recalculate-fuel-charge-on-statuses',
        nameForAttribute: GC.CLO_RATE_RECALCULATE_FUEL_CHARGE_ON_STATUSES,
        options: [
          {
            value: GC.LOAD_STATUS_BOOKED_STATUS,
            label: G.getWindowLocale('titles:load-status-booked', 'Booked'),
          },
          {
            value: GC.LOAD_STATUS_IN_TRANSIT,
            label: G.getWindowLocale('titles:load-status-in-transit', 'In Transit'),
          },
          {
            value: GC.LOAD_STATUS_DELIVERED,
            label: G.getWindowLocale('titles:load-status-delivered', 'Delivered'),
          },
        ],
      },
      [GC.CLO_RATE_SYNC_RATE_FROM_CUSTOMER_INVOICE]: {
        type: 'switcher',
        name: 'titles:sync-rate-from-customer-invoice',
        nameForAttribute: GC.CLO_RATE_SYNC_RATE_FROM_CUSTOMER_INVOICE,
      },
    },
  },
  {
    title: 'titles:default',
    fields: {
      [GC.CLO_GENERAL_RATE_SERVICE_TYPE]: {
        type: 'select',
        options: 'serviceTypes',
        name: 'titles:service-type',
        nameForAttribute: GC.CLO_GENERAL_RATE_SERVICE_TYPE,
      },
      [GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE]: {
        type: 'select',
        options: 'transportationModes',
        name: 'titles:mode-of-transportation',
        nameForAttribute: GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE,
      },
      [GC.CLO_RATE_DEFAULT_FUEL_ASSESSORIAL]: {
        type: 'select',
        options: 'fuelAccessorialOptions',
        name: 'titles:default-fuel-accessorial',
        nameForAttribute: GC.CLO_RATE_DEFAULT_FUEL_ASSESSORIAL,
      },
    },
  },
  {
    title: 'titles:rate-shop',
    fields: {
      [GC.CLO_RATE_SHOP_QUOTE_ID_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:quote-id-sequence',
        nameForAttribute: GC.CLO_RATE_SHOP_QUOTE_ID_SEQUENCE,
      },
    },
  },
  {
    title: 'titles:sync-charges-from-trip',
    fields: {
      [GC.CLO_RATE_SYNC_CHARGES]: {
        type: 'switcher',
        name: 'titles:sync-charges',
        nameForAttribute: GC.CLO_RATE_SYNC_CHARGES,
      },
      [GC.CLO_RATE_SYNC_UPLIFT_VALUE]: {
        type: 'input',
        validate: G.isEmptyOrInteger,
        name: 'titles:uplift-value',
        nameForAttribute: GC.CLO_RATE_SYNC_UPLIFT_VALUE,
      },
      [GC.CLO_RATE_SYNC_UPLIFT_UNIT]: {
        type: 'select',
        name: 'titles:uplift-unit',
        options: FLAT_PERCENT_RATE_UNIT_OPTIONS,
        nameForAttribute: GC.CLO_RATE_SYNC_UPLIFT_UNIT,
      },
      [GC.CLO_RATE_SYNC_UPLIFT_APPLY_TO]: {
        type: 'select',
        name: 'titles:apply-to',
        nameForAttribute: GC.CLO_RATE_SYNC_UPLIFT_APPLY_TO,
        options: getCustomerContractUpliftApplyToEnumOptions(),
      },
      [GC.CLO_RATE_SYNC_DEFAULT_FUEL_ASSESSORIAL]: {
        type: 'select',
        options: 'accessorials',
        name: 'titles:default-fuel-accessorial',
        nameForAttribute: GC.CLO_RATE_SYNC_DEFAULT_FUEL_ASSESSORIAL,
      },
    },
  },
  {
    configType: 'custom',
    title: 'titles:additional-charges',
    configName: GC.CUSTOM_DEFAULT_ORDER_ACCESSORIAL_CONFIG,
    customConfigName: GC.CUSTOM_DEFAULT_ORDER_ACCESSORIAL_CONFIG,
    fields: [
      {
        name: 'titles:accessorial',
        nameForAttribute: GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
        customLogic: (_: any, { assessorialGuid }: Object, props: Object) =>
          R.path(['configOptions', 'accessorialConfigList', assessorialGuid, GC.FIELD_DISPLAYED_VALUE], props),
      },
      {
        name: 'titles:rate',
        nameForAttribute: GC.FIELD_CHARGE_RATE,
      },
      {
        name: 'titles:currency',
        nameForAttribute: GC.FIELD_CURRENCY,
      },
      {
        name: 'titles:rate-type',
        nameForAttribute: GC.FIELD_CHARGE_RATE_TYPE,
      },
      {
        name: 'titles:rate-unit',
        nameForAttribute: GC.FIELD_CHARGE_RATE_UNIT,
      },
    ],
  },
];

const cancelOrderTab = [
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:load-cancel-reason',
    configName: GC.CLO_GENERAL_LOAD_CANCEL_REASON,
  },
  {
    title: 'titles:general',
    fields: {
      [GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_ACCESSORIAL]: {
        type: 'select',
        options: 'accessorials',
        name: 'titles:default-charge-accessorial',
        nameForAttribute: GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_ACCESSORIAL,
      },
      [GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_CUSTOMER_RATE]: {
        type: 'input',
        validate: G.isEmptyOrInteger,
        name: 'titles:default-clo-charge',
        nameForAttribute: GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_CUSTOMER_RATE,
      },
      [GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_DRIVER_CARRIER_RATE]: {
        type: 'input',
        validate: G.isEmptyOrInteger,
        name: 'titles:default-tel-charge',
        nameForAttribute: GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_DRIVER_CARRIER_RATE,
      },
    },
  },
];

const quoteTab = [
  {
    title: 'titles:general',
    fields: {
      [GC.CLO_QUOTE_QUOTE_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:quote-number-sequence',
        nameForAttribute: GC.CLO_QUOTE_QUOTE_NUMBER_SEQUENCE,
      },
      [GC.CLO_QUOTE_EXPIRATION_DAYS]: {
        type: 'input',
        validate: G.isEmptyOrInteger,
        name: 'titles:expiration-days',
        nameForAttribute: GC.CLO_QUOTE_EXPIRATION_DAYS,
      },
      [GC.CLO_QUOTE_USE_QUOTE_NUMBER_AS_PRIMARY_REFERENCE]: {
        type: 'switcher',
        name: 'titles:use-quote-number-as-primary-reference',
        nameForAttribute: GC.CLO_QUOTE_USE_QUOTE_NUMBER_AS_PRIMARY_REFERENCE,
      },
      [GC.CLO_QUOTE_SHOW_TRIP_PRICING]: {
        type: 'switcher',
        name: 'titles:show-trip-pricing',
        nameForAttribute: GC.CLO_QUOTE_SHOW_TRIP_PRICING,
      },
      [GC.CLO_QUOTE_HIDE_ADDRESS_FIELDS]: {
        type: 'switcher',
        name: 'titles:hide-address-fields',
        nameForAttribute: GC.CLO_QUOTE_HIDE_ADDRESS_FIELDS,
      },
      [GC.CLO_QUOTE_ITEM_ADDITIONAL_ITEM_FIELDS]: {
        zIndex: 13,
        type: 'list',
        options: itemFields,
        component: MultiselectFieldComponent,
        name: 'titles:additional-item-fields',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_QUOTE_ITEM_ADDITIONAL_ITEM_FIELDS,
      },
      [GC.CLO_QUOTE_ITEM_REQUIRED_FIELDS]: {
        zIndex: 12,
        type: 'list',
        options: itemFields,
        name: 'titles:required-item-fields',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CLO_QUOTE_ITEM_REQUIRED_FIELDS,
      },
    },
  },
  {
    title: 'titles:email',
    fields: {
      [GC.CLO_QUOTE_EMAIL_DIVISION_NAME]: {
        type: 'input',
        name: 'titles:email-division-name',
        nameForAttribute: GC.CLO_QUOTE_EMAIL_DIVISION_NAME,
      },
      [GC.CLO_QUOTE_DEFAULT_EMAIL]: {
        type: 'input',
        name: 'titles:default-email',
        nameForAttribute: GC.CLO_QUOTE_DEFAULT_EMAIL,
      },
      [GC.CLO_QUOTE_DEFAULT_SUBJECT]: {
        type: 'input',
        name: 'titles:default-subject',
        nameForAttribute: GC.CLO_QUOTE_DEFAULT_SUBJECT,
      },
      [GC.CLO_QUOTE_DEFAULT_MESSAGE]: {
        type: 'input',
        name: 'titles:default-message',
        nameForAttribute: GC.CLO_QUOTE_DEFAULT_MESSAGE,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:decline-reason',
    configName: GC.CLO_QUOTE_DECLINE_REASON_CODE,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:cancel-reason',
    configName: GC.CLO_QUOTE_CANCEL_REASON_CODE,
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:terms-and-conditions-document',
    configName: GC.CUSTOM_ORDER_QUOTE_TERMS_AND_CONDITIONS_DOCUMENT,
    customConfigName: GC.CUSTOM_ORDER_QUOTE_TERMS_AND_CONDITIONS_DOCUMENT,
    hideTitleActions: ({ termsAndConditionsDocument }: Object) =>
      G.isNotNilAndNotEmpty(termsAndConditionsDocument),
    fields: [
      {
        tableColumnWith: '100%',
        name: 'titles:document-name',
        nameForAttribute: GC.FIELD_FILE_NAME,
        customLogic: (field: Object, { fileUrl, fileName }: Object) => (
          <StyledLink
            href={fileUrl}
            display='flex'
            alignItems='center'
            textDecoration='underline'
            color={G.getTheme('colors.light.blue')}
          >
            {I.pdf()}
            <Box ml={10}>{fileName}</Box>
          </StyledLink>
        ),
      },
    ],
  },
  {
    title: 'titles:external-quotes',
    fields: {
      [GC.CLO_QUOTE_REQUEST_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:quote-request-number-sequence',
        nameForAttribute: GC.CLO_QUOTE_REQUEST_NUMBER_SEQUENCE,
      },
    },
  },
];

const ConfigCloGroup = {
  generalTab,
  orderTypeTab,
  billToTab,
  itemContainerTab,
  rateTab,
  cancelOrderTab,
  quoteTab,
};

export {
  ConfigCloGroup,
};
