import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// forms
import { Checkbox } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedFlex, ActionButton, CancelButton } from '../../../ui';
// feature dispatch-planner
import { renderEvents } from './order-details';
//////////////////////////////////////////////////

const enhance = compose(
  withState('selectedList', 'setSelectedList', []),
  withHandlers({
    handleSelect: ({ selectedList, setSelectedList }: Object) => (guid: string) => {
      const newList = G.ifElse(
        R.includes(guid, selectedList),
        R.without(R.of(Array, guid), selectedList),
        R.append(guid, selectedList),
      );

      setSelectedList(newList);
    },
    handleRemoveFromTel: (props: Object) => () => {
      const { clos, openModal, closeModal, selectedList, uncheckCrossDockClos } = props;

      const name = R.compose(
        R.join(', '),
        R.filter(G.isNotNil),
        R.map((guid: string) => R.pathOr(null, [guid, GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], clos)),
      )(selectedList);

      const component = (
        <ConfirmComponent
          name={name}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:submit', 'Submit'),
              action: () => {
                uncheckCrossDockClos(selectedList);
                closeModal();
                closeModal();
              },
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const LinkedOrders = enhance((props: Object) => {
  const {
    clos,
    closeModal,
    selectedList,
    handleSelect,
    handleRemoveFromTel,
  } = props;

  const blueColor = G.getTheme('colors.dark.blue');

  return (
    <Box>
      <Box maxHeight='calc(100vh - 150px)' overflow='auto'>
        {
          R.values(clos).map((data: Object, i: number) => (
            <Box key={i}>
              <Box mt='4px' color={blueColor} textAlign='center'>
                {R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], data)}
              </Box>
              <Flex>
                <Checkbox
                  mr='8px'
                  type='checkbox'
                  borderColor='colors.dark.grey'
                  onChange={() => handleSelect(G.getGuidFromObject(data))}
                  checked={R.includes(G.getGuidFromObject(data), selectedList)}
                />
                {renderEvents({ data })}
              </Flex>
            </Box>
          ))
        }
      </Box>
      <StickedFlex width='100%' mt={15} bottom='0px' zIndex={100}>
        {
          G.isNotEmpty(selectedList) &&
          <ActionButton
            width='50%'
            p='8px 16px'
            fontSize={18}
            onClick={handleRemoveFromTel}
          >
            {G.getWindowLocale('actions:remove-from-trip', 'Remove From Trip')}
          </ActionButton>
        }
        <CancelButton
          ml='auto'
          width='auto'
          p='8px 16px'
          fontSize={14}
          onClick={closeModal}
        >
          {G.getWindowLocale('action:cancel', 'Cancel')}
        </CancelButton>
      </StickedFlex>
    </Box>
  );
});

export default LinkedOrders;

