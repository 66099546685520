import * as R from 'ramda';
import * as Yup from 'yup';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const validationSchema = Yup.lazy((values: Object) => Yup.object().shape({
  [GC.FIELD_NAME]: G.yupStringRequired.max(250, G.getShouldBeFromToCharLocaleTxt(1, 250)),
  [GC.FIELD_MC_NUMBER]: G.yupStringNotRequired.max(8, G.getShouldBeFromToCharLocaleTxt(0, 8)),
  [GC.FIELD_NOTES]: G.yupStringNotRequired.max(2000, G.getShouldBeFromToCharLocaleTxt(1, 2000)),
  [GC.FIELD_CARRIER_DEACTIVATED_REASON]: G.ifElse(
    R.prop(GC.FIELD_ACTIVE, values),
    G.yupStringNotRequired,
    G.yupStringRequired,
  ),
  [GC.FIELD_INTERNAL_DIVISION_GUID]: G.ifElse(
    R.prop(GC.FIELD_INTERNAL_DIVISION, values),
    G.yupStringRequired,
  ),
  [GC.FIELD_PHONE_NUMBER]: Yup.string()
    .notRequired()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
}));

const settings = {
  [GC.FIELD_ACTIVE]: {
    type: 'toggle',
    name: 'titles:active',
    additionalInputWrapperSettings: {
      justifyContent: 'center',
      display: () => G.ifElse(
        G.hasNotAmousCurrentUserPermissions(PC.ACTIVATE_CARRIER_EXECUTE),
        'none',
      ),
    },
  },
  [GC.FIELD_IGNORE_DEACTIVATION_RULES]: {
    type: 'toggle',
    name: 'titles:ignore-activation-deactivation-rules',
    additionalInputWrapperSettings: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      display: () => G.ifElse(
        G.hasNotAmousCurrentUserPermissions(PC.ACTIVATE_CARRIER_EXECUTE),
        'none',
      ),
    },
  },
  [GC.FIELD_CARRIER_OWN_COMPANY]: {
    type: 'toggle',
    name: 'titles:own-company',
    additionalInputWrapperSettings: {
      justifyContent: 'center',
    },
  },
  [GC.FIELD_CARRIER_DEACTIVATED_REASON]: {
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:status-reason',
    options: GC.CARRIER_DEACTIVATED_TYPE,
    additionalInputWrapperSettings: {
      visibility: (props: Object) => G.ifElse(
        R.and(
          R.pathEq(false, ['values', GC.FIELD_ACTIVE], props),
          G.hasAmousCurrentUserPermissions(PC.ACTIVATE_CARRIER_EXECUTE),
        ),
        'visible',
        'hidden',
      ),
    },
  },
  field2: {
    additionalInputWrapperSettings: {
      visibility: () => 'hidden',
    },
  },
  [GC.FIELD_NAME]: {
    isRequired: true,
    name: 'titles:company-name',
  },
  [GC.FIELD_CARRIER_DBA]: {
    name: 'titles:dba',
  },
  [GC.FIELD_TYPE]: {
    type: 'select',
    name: 'titles:type',
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.CARRIER_TYPE_INTERSTATE,
        label: G.getWindowLocale('titles:interstate', 'Interstate'),
      },
      {
        value: GC.CARRIER_TYPE_BROKER,
        label: G.getWindowLocale('titles:broker', 'Broker'),
      },
      {
        value: GC.CARRIER_TYPE_INTERSTATE_OR_BROKER,
        label: G.getWindowLocale('titles:interstate-or-broker', 'Interstate or Broker'),
      },
      {
        value: GC.CARRIER_TYPE_INTRASTATE,
        label: G.getWindowLocale('titles:intrastate', 'Intrastate'),
      },
      {
        value: GC.CARRIER_TYPE_OTHER,
        label: G.getWindowLocale('titles:other', 'Other'),
      },
    ],
  },
  [GC.FIELD_CARRIER_INTERNAL_ID]: {
    name: 'titles:internal-id',
  },
  [GC.FIELD_CARRIER_SCAC]: {
    name: 'titles:scac',
  },
  [GC.FIELD_CARRIER_USDOT_NUMBER]: {
    name: 'titles:usdot-number',
  },
  [GC.FIELD_MC_NUMBER]: {
    name: 'titles:mc-number',
  },
  [GC.FIELD_ACCOUNTING_EXTERNAL_ID]: {
    name: 'titles:accounting-external-id',
  },
  [GC.FIELD_CARRIER_INTRASTATE_DOT]: {
    name: 'titles:intrastate-dot',
  },
  [GC.FIELD_CARRIER_INTRASTATE_STATE]: {
    name: 'titles:intrastate-state',
  },
  [GC.FIELD_CARRIER_FEDERAL_EIN]: {
    name: 'titles:federal-ein',
  },
  [GC.FIELD_CARRIER_DUNS_NUMBER]: {
    name: 'titles:duns-number',
  },
  [GC.FIELD_CARRIER_TRANSPORTATION_MODES]: {
    isMulti: true,
    type: 'reactSelect',
    name: 'titles:transportation-mode',
    options: GC.GENERAL_MODE_TRANSPORTATION,
  },
  [GC.FIELD_CARRIER_EQUIPMENTS]: {
    isMulti: true,
    type: 'reactSelect',
    name: 'titles:equipment',
    options: GC.GENERAL_EQUIPMENTS,
  },
  field1: {
    additionalInputWrapperSettings: {
      visibility: () => 'hidden',
    },
  },
  [GC.FIELD_CARRIER_URL]: {
    name: 'titles:url',
  },
  [GC.FIELD_EMAIL]: {
    type: 'multiEmail',
    name: 'titles:emails',
    shouldOverrideEmails: true,
    inputStyles: { multiEmailWidth: '100%' },
  },
  [GC.FIELD_PHONE_NUMBER]: {
    type: 'phoneNumber',
    name: 'titles:phone-number',
  },
  [GC.FIELD_INTERNAL_DIVISION]: {
    type: 'toggle',
    shouldCustomChange: true,
    customChangeHandler: (event: Object, _: any, props: Object) => {
      const { setFieldValue, setInternalDivision } = props;

      const value = R.path(['target', 'checked'], event);

      setInternalDivision(value);
      setFieldValue(GC.FIELD_INTERNAL_DIVISION, value);
    },
    name: 'titles:use-internal-division',
    additionalInputWrapperSettings: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  [GC.FIELD_INTERNAL_DIVISION_GUID]: {
    type: 'select',
    isRequired: true,
    options: 'divisionOptions',
    name: 'titles:internal-division',
    additionalInputWrapperSettings: {
      visibility: (props: Object) => G.ifElse(
        R.pathEq(true, ['values', GC.FIELD_INTERNAL_DIVISION], props),
        'visible',
        'hidden',
      ),
    },
  },
  [GC.FIELD_ADDRESS]: {
    name: 'titles:address1',
    type: 'addressAutocomplete',
    addressFieldName: GC.FIELD_ADDRESS,
  },
  [GC.FIELD_ADDRESS_2]: {
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    type: 'countrySelect',
    name: 'titles:country',
  },
  [GC.FIELD_NOTES]: {
    type: 'textarea',
    name: 'titles:notes',
    inputStyles: { p: 15 },
    additionalInputWrapperSettings: { mb: '0px', width: '100%', maxWidth: 'unset' },
  },
};

const defaultInputWrapperStyles = {
  mb: 25,
  width: '19%',
  maxWidth: 280,
  minWidth: 125,
};

const fieldSettings = R.compose(
  R.map((fieldName: Object) => {
    const field = R.pathOr({}, [fieldName], settings);

    const {
      name,
      inputStyles,
      additionalLabelStyles,
      additionalLabelComponent,
      additionalInputWrapperSettings,
    } = field;

    return {
      ...field,
      fieldName,
      inputStyles,
      errorWidth: '100%',
      additionalLabelStyles,
      additionalLabelComponent,
      label: G.ifElse(G.isNotNil(name), R.of(Array, name)),
      inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperSettings },
    };
  }),
  R.keys,
)(settings);

export {
  fieldSettings,
  validationSchema,
};
