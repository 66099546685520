import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  compose,
  withState,
  lifecycle,
  withHandlers } from 'react-recompose';
// features
import { makeSelectCurrentUserName } from '../auth/selectors';
import { makeSelectCurrentBranch } from '../branch/selectors';
// components
import { Tabs } from '../../components/tabs';
import { Label } from '../../components/label';
import { FormButtons } from '../../components/form-buttons';
import { ConfirmComponent } from '../../components/confirm';
import { openModal, closeModal} from '../../components/modal/actions';
import { FormGroupTitleComponent } from '../../components/form-group-title';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { isDevelopment } from '../../helpers/env';
// forms
import { FormGroupWrapper } from '../../forms';
// hocs
import { withFormGroupOpenedStatus } from '../../hocs';
// icons
import * as I from '../../svgs';
import {
  Box,
  Flex,
  PageWrapper,
  SimpleWrapper,
  PageHeaderWrapper } from '../../ui';
// features intl
import TabBar from './components/tab-bar';
import IntlList from './components/intl-list';
import { withFilterTableList } from './filter';
import AddNewItem from './components/add-new-item';
import BranchSearch from './components/branch-search';
import {
  tabStyles,
  CUSTOM_TAB,
  DEFAULT_TAB,
  activeTabStyles,
  ADD_NEW_LANGUAGE_ITEM,
  ADD_NEW_CUSTOM_LANGUAGE_ITEM } from './constants';
import {
  makeSelectIntl,
  makeSelectActiveTab,
  makeSelectCustomKeys,
  makeSelectActivePageTab,
  makeSelectActiveBranchName,
  makeSelectMastersBranchList,
  makeSelectChangedLocaleItems } from './selectors';
import {
  setPageTab,
  setActiveTab,
  setBranchName,
  getIntlRequest,
  setInitialState,
  deleteIntlRequest,
  updateIntlRequest,
  deleteCustomItemRequest,
  updateCustomIntlRequest,
  getIntlCustomizedRequest,
  addNewItemLanguageRequest,
  getAllCustomizedItemsRequest } from './actions';
//////////////////////////////////////////////////

export const getTabs = () => G.ifElse(
  isDevelopment,
  [
    {
      text: G.getWindowLocale('titles:intl', 'Intl'),
    },
    {
      text: G.getWindowLocale('titles:custom-tab', 'Custom Tab'),
    },
  ],
  [
    {
      text: G.getWindowLocale('titles:custom-tab', 'Custom Tab'),
    },
  ],
);

export const enhance = compose(
  withFormGroupOpenedStatus,
  withState('option', 'setOption', {}),
  withHandlers({
    handleSelect: (props: Object) => (values: Object) => {
      const { setOption, setBranchName, setActiveTab, getAllCustomizedItemsRequest } = props;
      setBranchName(values);
      setOption(values);
      setActiveTab('custom');
      getAllCustomizedItemsRequest({ [GC.FIELD_BRANCH_GUID]: R.path(['value'], values) });
    },
  }),
  withHandlers({
    handleClickCancel: (props: Object) => () => {
      const modalContext = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirmation-leave-page',
            'All changes will be lost. Are you sure you want leave page?',
          )} />
      );
      const modal = {
        component: modalContext,
        options: {
          width: 600,
          movable: false,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:leave', 'Leave'),
              action: () => {
                props.history.goBack();
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleClickUpdate: (props: Object) => () => {
      const confirmationContent = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirm:update',
            'Are you sure you want update it?')} />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          movable: false,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:update', 'Update'),
              action: () => {
                props.updateIntlRequest();
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleClickCustomUpdate: (props: Object) => () => {
      const {
        intl,
        option,
        openModal,
        closeModal,
        customKeys,
        branchName,
        keyFieldsToUpdate,
        updateCustomIntlRequest } = props;
      const pathToObj = R.map((item: string) => R.split('.', item), keyFieldsToUpdate);
      const changedObj = pathToObj.map(
        (pathKey: string) => R.path([pathKey[0], pathKey[1]], intl));
      const customItems = R.reduce(
        (acc: Array, item: object) => {
          const isCustomExist = customKeys.includes(R.path(['itemId'], item));
          if (isCustomExist) {
            return R.append({
              ...item,
              [GC.FIELD_BRANCH_GUID]: R.path(['value'], option),
            }, acc);
          }
          const pickValue = R.pick([
            'value',
            'itemId',
            'language',
          ], item);
          return R.append({
            ...pickValue,
            [GC.FIELD_BRANCH_GUID]: R.path(['value'], option),
          }, acc);
        },
        [],
        changedObj);
      if (G.isNilOrEmpty(branchName)) {
        const confirmationContent = (
          <ConfirmComponent
            textLocale={G.getWindowLocale(
            'messages:confirm:select-branch-name',
            'Please, select Branch')} />
        );
        const modal = {
          component: confirmationContent,
          options: {
            width: '600px',
            movable: false,
            controlButtons: [
              {
                type: 'button',
                name: G.getWindowLocale('actions:close-modal', 'Close Modal'),
                action: () => {
                  closeModal();
                },
              },
            ],
          },
        };
        return openModal(modal);
      }
      const confirmationContent = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirm:update',
            'Are you sure you want update it?')} />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          movable: false,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:update', 'Update'),
              action: () => {
                updateCustomIntlRequest({
                  customItems,
                  [GC.FIELD_BRANCH_GUID]: R.path(['value'], option),
                });
                closeModal();
              },
            },
          ],
        },
      };
      openModal(modal);
    },
    handleRemoveItem: (props: Object) => (key: string) => {
      const confirmationContent = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:delete-confirmation-field',
            'Are you sure you want delete field?')} />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          movable: false,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteIntlRequest(key);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleRemoveCustomItem: (props: Object) => (key: string) => {
      const confirmationContent = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:delete-confirmation-field',
            'Are you sure you want delete field?')} />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          movable: false,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteCustomItemRequest({
                  key,
                  [GC.FIELD_BRANCH_GUID]: R.path(['option', 'value'], props),
                });
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleCustomUpdateItem: (props: Object) => (id: string, item: object) => {
      let initialValues = R.reduce(
        (acc: Array, item: object) => R.assoc(R.path(['language'], item), R.path(['value'], item), acc),
        {
          key: id,
        },
        R.values(R.path([id], props.intl)));
      initialValues = R.assoc(GC.FIELD_BRANCH_GUID, R.pathOr(null, ['branchName', 'value'], props), initialValues);
      const options = G.addEmptyOptionToDropDown(props.masterBranchListOptions);
      const component = (
        <AddNewItem
          item={item}
          custom={true}
          options={options}
          selectIntl={props.intl}
          initialValues={initialValues}
          customKeys={props.customKeys}
          closeModal={props.closeModal}
          handleSelect={props.handleSelect}
          formId={ADD_NEW_CUSTOM_LANGUAGE_ITEM}
          updateCustomIntlRequest={props.updateCustomIntlRequest} />
      );
      const modal = {
        component,
        options: {
          width: '440px',
          height: 'auto',
          padding: '5px',
          movable: false,
          footerPadding: '0px',
          footerPosition: 'static',
          controlButtons: [
            {
              type: 'button',
              margin: '0 12px 0 0',
              id: ADD_NEW_CUSTOM_LANGUAGE_ITEM,
              name: G.getWindowLocale('actions:save', 'Save'),
            },
          ],
        },
        p: '15px',
      };
      props.openModal(modal);
    },
    handleAddNewItem: (props: Object) => () => {
      const component = (
        <AddNewItem
          selectIntl={props.intl}
          closeModal={props.closeModal}
          formId={ADD_NEW_LANGUAGE_ITEM}
          addNewItemLanguageRequest={props.addNewItemLanguageRequest} />
      );
      const modal = {
        component,
        options: {
          width: '440px',
          height: 'auto',
          padding: '5px',
          movable: false,
          footerPadding: '0px',
          footerPosition: 'static',
          controlButtons: [
            {
              type: 'button',
              margin: '0 12px 0 0',
              id: ADD_NEW_LANGUAGE_ITEM,
              name: G.getWindowLocale('actions:save', 'Save'),
            },
          ],
        },
        p: '15px',
      };
      props.openModal(modal);
    },
    handleClickTitle: (props: Object) => (pattern: string) => {
      if (G.notEquals(pattern, props.activeTab)) {
        props.setActiveTab(pattern);
        if (R.and(R.equals(props.pageTab.type, CUSTOM_TAB), R.equals(pattern, 'custom'))) {
          if (G.isNilOrEmpty(R.path(['option'], props))) return;
          return props.getAllCustomizedItemsRequest({ [GC.FIELD_BRANCH_GUID]: R.path(['option', 'value'], props) });
        } else if (pattern.includes('custom')) {
          const pickPattern = R.last(R.split('/', pattern));
          return props.getIntlCustomizedRequest({
            pattern: pickPattern,
            [GC.FIELD_BRANCH_GUID]: R.path(['option', 'value'], props),
          });
        }
        props.getIntlRequest(pattern);
      }
    },
    handleSwitchTab: (props: Object) => (pageTab: string) => {
      props.setPageTab({
        index: pageTab,
        type: G.ifElse(R.equals(0, pageTab), DEFAULT_TAB, CUSTOM_TAB),
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { setPageTab } = this.props;
      if (R.not(isDevelopment)) return setPageTab({ index: 0, type: CUSTOM_TAB });
    },
  }),
);

const ActionsHeadComponent = (props: Object) => {
  const { pageTab, handleSwitchTab, currentUserName, handleAddNewItem } = props;
  if (R.equals('intl-user-michael', currentUserName)) {
    return (
      <Box mt='auto'>
        <Tabs
          tabs={getTabs()}
          tabStyles={tabStyles}
          activeTab={pageTab.index}
          handleClickTab={handleSwitchTab}
          activeTabStyles={activeTabStyles} />
      </Box>
    );
  }
  return (
    <SimpleWrapper>
      <Box mt='auto'>
        <Tabs
          tabs={getTabs()}
          tabStyles={tabStyles}
          activeTab={pageTab.index}
          handleClickTab={handleSwitchTab}
          activeTabStyles={activeTabStyles} />
      </Box>
      {
        R.and(
          R.equals(pageTab.type, DEFAULT_TAB),
          <Flex pl={15} >
            {G.getWindowLocale('titles:add-new-item', 'Add new item')}
            <Label ml='15px' frontIcon={I.plusRound()} frontAction={handleAddNewItem} />
          </Flex>
        )
      }
    </SimpleWrapper>
  );
};

const renderButtons = ({ branchName, handleClickUpdate, pageTab, handleClickCustomUpdate }: Object) => {
  if (R.equals(pageTab.type, CUSTOM_TAB)) {
    return ([
      {
        width: 'auto',
        type: 'button',
        action: handleClickCustomUpdate,
        disabled: G.isNilOrEmpty(branchName),
        btnText: G.getWindowLocale('actions:custom-update', 'Custom Update'),
      },
    ]);
  }
  return ([
    {
      width: 'auto',
      type: 'button',
      action: handleClickUpdate,
      btnText: G.getWindowLocale('actions:update', 'Update'),
    },
  ]);
};

export const IntlComponent = withFilterTableList(
  'searchString',
  ['intl'],
  {
    joiner: '',
    omitFields: [
      'guid',
      'version',
      'language',
      'createdBy',
      'createdDate',
      'lastModifiedBy',
      'lastModifiedDate',
    ],
    withoutValues: [null, undefined, ''],
  },
)((props: Object) => {
  const { pageTab, handleClickTitle } = props;
  return (
    <PageWrapper bgColor={G.getTheme('forms.bgColor')} style={{ overflow: 'hidden' }}>
      <PageHeaderWrapper aI='center' style={{ justifyContent: 'space-between' }}>
        <ActionsHeadComponent {...props} />
        {
          R.and(
            R.and(R.equals(pageTab.type, CUSTOM_TAB), G.isNilOrEmpty(props.branchName)),
            <Flex
              pl={15}
              fontSize='16px'
              color={G.getTheme('colors.darkGrey')}
            >
              {G.getWindowLocale('messages:confirm:select-branch-name', 'Please, select Branch')}
            </Flex>
          )
        }
        {R.and(R.equals(pageTab.type, CUSTOM_TAB), <BranchSearch {...props} />)}
      </PageHeaderWrapper>
      <FormGroupWrapper>
        <FormGroupTitleComponent mb='0' text={G.getWindowLocale('titles:intl', 'Intl')} />
        <TabBar pageTab={pageTab} handleClickTitle={handleClickTitle} />
        <IntlList {...props} />
        <FormButtons
          {...props}
          hideFormSaveBtn={true}
          buttons={renderButtons(props)}
          style={{ display: 'flex', justifyContent: 'flex-end' }} />
      </FormGroupWrapper>
    </PageWrapper>
  );
});

const mapStateToProps = (state: Object) => (createStructuredSelector({
  intl: makeSelectIntl(state),
  activeTab: makeSelectActiveTab(state),
  customKeys: makeSelectCustomKeys(state),
  pageTab: makeSelectActivePageTab(state),
  branchName: makeSelectActiveBranchName(state),
  currentBranch: makeSelectCurrentBranch(state),
  currentUserName: makeSelectCurrentUserName(state),
  keyFieldsToUpdate: makeSelectChangedLocaleItems(state),
  masterBranchListOptions: makeSelectMastersBranchList(state),
}));

export default connect(mapStateToProps, {
  openModal,
  setPageTab,
  closeModal,
  setActiveTab,
  setBranchName,
  getIntlRequest,
  setInitialState,
  updateIntlRequest,
  deleteIntlRequest,
  deleteCustomItemRequest,
  updateCustomIntlRequest,
  getIntlCustomizedRequest,
  addNewItemLanguageRequest,
  getAllCustomizedItemsRequest,
})(enhance(IntlComponent));
