import React from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
//////////////////////////////////////////////////

const darkGreyColor = G.getTheme('colors.#7D828C');

const textComponentStyles = {
  display: 'block',
  maxWidth: '100%',
  withEllipsis: true,
};

const renderAddressInfo = ({ address }: Object) => (
  <TextComponent {...textComponentStyles} title={address}>
    {G.getWindowLocale('titles:address', 'Address')}: {address}
  </TextComponent>
);

const renderLocationInfo = (location: Object) => {
  const locationString = G.concatLocationFields(location);

  return (
    <TextComponent {...textComponentStyles} mt='8px' color={darkGreyColor} title={locationString}>
      {locationString}
    </TextComponent>
  );
};

export const LocationSection = (props: Object) => {
  const { location, handleRemoveLocation, handleAddOrEditLocation } = props;

  return (
    <Box
      p={10}
      my={10}
      width='100%'
      borderRadius='8px'
      bg={G.getTheme('colors.whiteGrey')}
    >
      <Flex justifyContent='space-between'>
        {renderAddressInfo(location)}
        <Flex>
          <Box cursor='pointer' onClick={() => handleAddOrEditLocation(location)}>
            {I.pencil(darkGreyColor)}
          </Box>
          <Box ml={10} cursor='pointer' onClick={handleRemoveLocation}>
            {I.trash(darkGreyColor)}
          </Box>
        </Flex>
      </Flex>
      <Flex>
        {renderLocationInfo(location)}
      </Flex>
    </Box>
  );
};
