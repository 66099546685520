import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature average-fuel-price
import * as A from './actions';
import {
  makeSelectPagination,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const pagination = yield select(makeSelectPagination());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const orderFields = R.values(titleOrderFields);
    const searchCriteria = R.values(titleFilterParams);

    const { limit, offset } = pagination;

    const reqBody = {
      limit,
      offset,
      orderFields,
      searchCriteria,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.averageFuelPriceList, { data: reqBody });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (error) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetItemListSaga exception');
  }
}

function* createFuelPricesRequest({ payload }: Object) {
  try {
    const { prices, priceDate } = payload;

    yield put(openLoader());

    const options = {
      data: { priceDate, data: prices },
    };

    yield call(crudSaga, {
      options,
      method: 'post',
      shouldCloseModal: true,
      successAction: A.resetListAndPagination,
      parentSagaName: 'createFuelPricesRequest',
      endpoint: endpointsMap.averageFuelPriceManualCreate,
      callbackSaga: () => handleGetItemListSaga({ payload: { openLoader: true } }),
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getAverageFuelPriceListRequest exception');
  }
}

function* handleVisitAverageFuelPriceListSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_AVERAGE_FUEL_PRICE_PAGE);

    yield put(A.setInitialState());
    yield put(openLoader({ showDimmer: true }));

    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* AverageFuelPriceWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createFuelPricesRequest, createFuelPricesRequest);
  yield takeLatest(GC.VISIT_AVERAGE_FUEL_PRICE_PAGE, handleVisitAverageFuelPriceListSaga);
}

export default AverageFuelPriceWatcherSaga;
