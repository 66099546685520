import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers, withState, lifecycle } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// features
import { FormSection } from '../../rate/ui';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// schemes
import * as VS from '../../../schemes';
// ui
import { StickedBox, SectionsDivider } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fuel-cards
import LinesSection from './lines-section';
import { TRANSACTION_STOP_FIELDS } from '../constants';
import { setDriverAssignmentsTrucks } from '../actions';
import { makeSelectDriverAssignmentsTrucks } from '../selectors';
import {
  fuelCardSettings,
  defaultFuelCardFields,
  assignmentFieldSettings,
} from '../settings/edit-form-settings';
//////////////////////////////////////////////////

const getActionButtons = ({ handleSaveAndAddNewFuelCard }: Object) => [{
  type: 'submit',
  action: handleSaveAndAddNewFuelCard,
  buttonStyles: {
    mr: 20,
    ml: 'auto',
    bgColor: 'none',
    background: 'none',
    border: '1px solid',
    borderRadius: '5px',
    width: 'max-content',
    textTransform: 'uppercase',
    textColor: G.getTheme('colors.dark.blue'),
    borderColor: G.getTheme('colors.dark.blue'),
  },
  displayText: G.getWindowLocale('actions:save-and-add-new', 'Save and Add New'),
}];

const transactionDateFormat = 'MM/DD/YYYY LTS ZZ';

const FieldsSection = pure((props: Object) => {
  const {
    optionsForSelect,
    handleChangeDriver,
    handleDisableTruck,
    handleDisableSource,
    handleDisableDriver,
  } = props;

  return (
    <div>
      <FormSection>
        <Fieldset2
          {...optionsForSelect}
          {...G.getFormikProps(props)}
          fields={assignmentFieldSettings}
          handleCustomChange={handleChangeDriver}
          handlers={{ handleDisableTruck, handleDisableSource, handleDisableDriver }}
          fieldsWrapperStyles={{ my: 25, px: 15, flexGrow: 1, justifyContent: 'space-between' }}
        />
      </FormSection>
      <SectionsDivider py={10} borderColor={G.getTheme('colors.light.grey')} />
      <FormSection p='15px 15px 10px' justifyContent='space-between'>
        {
          fuelCardSettings.map(({ fields, flexDirection, justifyContent }: Object, i: number) => (
            <Fieldset2
              {...G.getFormikProps(props)}
              key={i}
              fields={fields}
              fieldsWrapperStyles={{ flexDirection, justifyContent }}
            />
        ))}
      </FormSection>
    </div>
  );
});

const validationSchemaObject = Yup.object()
  .shape(VS.validationSchemaTransactionStopObject)
  .shape(VS.validationSchemaFuelCardObject);

const mapStateToProps = (state: Object) => createStructuredSelector({
  driverAssignmentsTrucks: makeSelectDriverAssignmentsTrucks(state),
});

const enhanceCommon = compose(
  connect(mapStateToProps, { setDriverAssignmentsTrucks }),
  withHandlers({
    handleDisableSource: ({ isCreate }: Object) => () => R.not(isCreate),
    handleDisableTruck: ({ truckGuid }: Object) => () => G.isNotNilAndNotEmpty(truckGuid),
    handleDisableDriver: ({ driverGuid }: Object) => () => G.isNotNilAndNotEmpty(driverGuid),
    handleSaveFuelCard: ({ setFieldValue }: Object) => () => setFieldValue('saveAndAddNew', false),
    handleSaveAndAddNewFuelCard: ({ setFieldValue }: Object) => () => setFieldValue('saveAndAddNew', true),
    handleChangeDriver: (props: Object) => async (driverGuid: string) => {
      const {
        values,
        setValues,
        truckGuid,
        openLoader,
        closeLoader,
        setFieldValue,
        driverAssignmentsTrucks,
        setDriverAssignmentsTrucks,
      } = props;

      if (R.isNil(driverGuid)) {
        return setFieldValue(GC.FIELD_DRIVER_GUID, null);
      }

      if (G.isNotNilAndNotEmpty(truckGuid)) {
        return setFieldValue(GC.FIELD_DRIVER_GUID, driverGuid);
      }

      if (R.has(driverGuid, driverAssignmentsTrucks)) {
        return setValues(R.mergeRight(
          values,
          { driverGuid, [GC.FIELD_TRUCK_GUID]: R.prop(driverGuid, driverAssignmentsTrucks) },
        ));
      }

      G.callFunction(openLoader);

      const res = await sendRequest('get', endpointsMap.getAssignInfoEndpoint(driverGuid));

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const truckGuid = R.pathOr('', [GC.SYSTEM_OBJECT_TRUCK, GC.FIELD_GUID], data);

        setValues(R.mergeRight(values, { truckGuid, driverGuid }));
        setDriverAssignmentsTrucks({ truckGuid, driverGuid });
      } else {
        setFieldValue(GC.FIELD_DRIVER_GUID, driverGuid);
      }

      G.callFunction(closeLoader);
    },
  }),
);

const enhanceUpdate = compose(
  withState('fuelCard', 'setFuelCard', {}),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: ({ fuelCard }: Object) => ({
      ...R.prop(GC.FIELD_FUEL_CARDS_TRANSACTION_STOP, fuelCard),
      ...R.omit([GC.FIELD_VERSION, GC.FIELD_FUEL_CARDS_TRANSACTION_STOP], fuelCard),
    }),
    handleSubmit: (values: Object, { props }: Object) => {
      const { fuelCard, submitAction } = props;

      const omitFields = [
        'saveAndAddNew',
        ...TRANSACTION_STOP_FIELDS,
        GC.FIELD_ADDRESS_1,
        GC.FIELD_LATITUDE,
        GC.FIELD_LONGITUDE,
      ];

      const transactionStopGuid = R.path([GC.FIELD_FUEL_CARDS_TRANSACTION_STOP, GC.FIELD_GUID], fuelCard);

      const transactionDate = G.createLocalDateTimeFromInstanceOrISOString(
        G.getPropFromObject(GC.FIELD_FUEL_CARDS_TRANSACTION_DATE, values),
        transactionDateFormat,
      );

      const data = R.compose(
        R.filter(G.isNotNilAndNotEmpty),
        R.assoc(GC.FIELD_FUEL_CARDS_TRANSACTION_DATE, transactionDate),
        R.omit(omitFields),
        R.mergeRight(fuelCard),
        R.assoc(
          GC.FIELD_FUEL_CARDS_TRANSACTION_STOP,
          R.assoc(
            GC.FIELD_GUID,
            transactionStopGuid,
            R.pick(TRANSACTION_STOP_FIELDS, G.renameKeys({ [GC.FIELD_ADDRESS_1]: GC.FIELD_ADDRESS }, values)),
          ),
        ),
      )(values);

      submitAction(data);
    },
    displayName: 'FuelCardForm',
  }),
  withHandlers({
    handleGetFuelCard: (props: Object) => async () => {
      const { setFuelCard, fuelCardGuid, openLoader, closeLoader } = props;

      openLoader();

      const endpoint = endpointsMap.fuelCardByGuid(fuelCardGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setFuelCard(data);
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetFuelCard fail');
      }

      closeLoader();
    },
  }),
  enhanceCommon,
  lifecycle({
    componentDidMount() {
      this.props.handleGetFuelCard();
    },
  }),
  pure,
);

const enhanceCreate = compose(
  withFormik({
    validationSchema: validationSchemaObject,
    mapPropsToValues: ({
      truckGuid,
      driverGuid,
      assignedTruckGuid,
      assignedDriverGuid,
      defaultBranchCurrency,
    }: Object) => ({
      ...defaultFuelCardFields,
      [GC.FIELD_TRUCK_GUID]: R.or(truckGuid, assignedTruckGuid),
      [GC.FIELD_DRIVER_GUID]: R.or(driverGuid, assignedDriverGuid),
      [GC.FIELD_CURRENCY]: R.or(
        defaultBranchCurrency,
        G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
      ),
    }),
    handleSubmit: (values: Object, { props, resetForm }: Object) => {
      const { submitAction } = props;

      const transactionDate = G.createLocalDateTimeFromInstanceOrISOString(
        G.getPropFromObject(GC.FIELD_FUEL_CARDS_TRANSACTION_DATE, values),
        transactionDateFormat,
      );

      const transactionStop = R.pick(
        TRANSACTION_STOP_FIELDS,
        G.renameKeys({ [GC.FIELD_ADDRESS_1]: GC.FIELD_ADDRESS }, values),
      );

      const data = {
        ...R.omit([...TRANSACTION_STOP_FIELDS, 'saveAndAddNew'], values),
        transactionDate,
        transactionStop,
      };

      const filteredData = R.filter(G.isNotNilAndNotEmpty, data);

      if (G.isTrue(R.path(['saveAndAddNew'], values))) {
        submitAction({ resetForm, data: filteredData });
      } else {
        submitAction({ data: filteredData });
      }
    },
  }),
  enhanceCommon,
  pure,
);

const FuelCardForm = (props: Object) => {
  const {
    isCreate,
    titleText,
    handleSubmit,
    handleSaveFuelCard,
  } = props;

  return (
    <section>
      <StickedBox top='0px' zIndex={15}>
        <FormGroupTitleMultiple
          mb='0'
          title={titleText}
          showArrowToggle={false}
        />
      </StickedBox>
      <SectionsDivider />
      <form onSubmit={handleSubmit}>
        <FieldsSection {...props} />
        <LinesSection {...props} />
        <FormFooter2
          boxStyles={{ p: '0 15px 15px' }}
          submitAction={handleSaveFuelCard}
          submitBtnText={G.getWindowLocale('actions:save', 'Save')}
          actionButtons={G.ifElse(isCreate, getActionButtons(props), [])}
        />
      </form>
    </section>
  );
};

export default enhanceUpdate(FuelCardForm);

export const CreateFuelCardForm = enhanceCreate(FuelCardForm);
