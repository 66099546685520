// components
import {
  pickerColorRed,
  pickerColorGrey,
  pickerColorBlue,
  pickerColorGreen,
  pickerColorYellow,
} from '../../components/color-picker';
// helpers/constants
import * as G from '../../helpers';
import { ENUMS } from '../../constants/enums';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const mainDarkColor = G.getTheme('colors.dark.mainDark');

const CARD_HEIGHT_90 = 90;
const CARD_HEIGHT_170 = 170;

const HOUR_LINE_WIDTH = 60;

// filter
const FILTER_TYPE_NONE = ENUMS.ENUM_NONE;
const FILTER_TYPE_CURRENT_USER = ENUMS.ENUM_CURRENT_USER;
const FILTER_TYPE_CURRENT_GROUPS = ENUMS.ENUM_CURRENT_GROUPS;
// group by
const GROUP_TYPE_NONE = ENUMS.ENUM_NONE;
const GROUP_TYPE_DISPATCHER = ENUMS.ENUM_DISPATCHER;
const GROUP_TYPE_DISPATCHING_GROUP = ENUMS.ENUM_DISPATCHING_GROUP;
// zoom
const ZOOM_TYPE_1X = 1;
const ZOOM_TYPE_2X = 2;
const ZOOM_TYPE_3X = 3;

const PICKER_COLOR_TO_CARD_RGB_MAP = {
  [pickerColorGrey]: 'rgb(246,247,248)',
  [pickerColorRed]: 'rgb(253,243,242)',
  [pickerColorBlue]: 'rgb(221,235,252)',
  [pickerColorGreen]: 'rgb(244,249,243)',
  [pickerColorYellow]: 'rgb(254,251,242)',
};

const PICKER_COLOR_TO_TEXT_COLOR_MAP = {
  [pickerColorGrey]: mainDarkColor,
  [pickerColorRed]: whiteColor,
  [pickerColorBlue]: whiteColor,
  [pickerColorGreen]: whiteColor,
  [pickerColorYellow]: mainDarkColor,
};

export {
  HOUR_LINE_WIDTH,
  // card height
  CARD_HEIGHT_90,
  CARD_HEIGHT_170,
  // picker color
  PICKER_COLOR_TO_CARD_RGB_MAP,
  PICKER_COLOR_TO_TEXT_COLOR_MAP,
  // filter
  FILTER_TYPE_NONE,
  FILTER_TYPE_CURRENT_USER,
  FILTER_TYPE_CURRENT_GROUPS,
  // group
  GROUP_TYPE_NONE,
  GROUP_TYPE_DISPATCHER,
  GROUP_TYPE_DISPATCHING_GROUP,
  // zoom
  ZOOM_TYPE_1X,
  ZOOM_TYPE_2X,
  ZOOM_TYPE_3X,
};
