import * as R from 'ramda';
import * as Yup from 'yup';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  STATE_OPTIONS,
  COUNTRY_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_RATE_UNIT_GROUP_OPTIONS,
  DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
  DEFAULT_AWARDED_LINE_WEIGHT_UOM_OPTIONS,
  getCustomerContractUpliftApplyToEnumOptions,
  DEFAULT_CARRIER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  DEFAULT_CARRIER_COMPENSATION_RATE_TYPE_OPTIONS,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS,
} from '../../../helpers/options';
// feature carrier
import * as LC from '../constants';
import { documentValidation } from '../validation';
import { renderMultiSelect } from '../components/common';
//////////////////////////////////////////////////

const getMinMaxValidation = (min: number, max: number) => [G.required, G.curriedIsMinMax(min, max)];

const validators = {
  [GC.FIELD_CITY]: getMinMaxValidation(1, 100),
  [GC.FIELD_STATE]: getMinMaxValidation(1, 100),
  [GC.FIELD_COUNTRY]: getMinMaxValidation(1, 100),
  [GC.FIELD_ADDRESS]: getMinMaxValidation(1, 255),
  [GC.FIELD_ADDRESS_2]: getMinMaxValidation(1, 255),
  [GC.FIELD_CARRIER_SCAC]: getMinMaxValidation(1, 6),
  [GC.FIELD_CARRIER_NAME]: getMinMaxValidation(1, 250),
  [GC.FIELD_CARRIER_MC_NUMBER]: G.curriedIsMinMax(0, 8),
  [GC.FIELD_LOCATION_NAME]: getMinMaxValidation(1, 255),
  [GC.FIELD_CARRIER_USDOT_NUMBER]: getMinMaxValidation(1, 12),
  [GC.FIELD_CARRIER_TERMINAL_NAME]: getMinMaxValidation(1, 100),
};

const getTerminalDispatchConfigFieldValidation = (value: string, values: Object) => {
  if (R.pathEq(GC.TERMINAL_PROCESS_TYPE_API, [GC.FIELD_TERMINAL_PROCESS_TYPE], values)) {
    return G.required(value);
  }

  return undefined;
};

export const editCarrierFields = ({ formValues, authorities }: Object) => ({
  formGroup: 'generalDetails',
  title: G.getWindowLocale('titles:general-details', 'General Details'),
  fields: [
    {
      width: 400,
      type: 'text',
      name: 'titles:company-name',
      nameForAttribute: GC.FIELD_CARRIER_NAME,
      validate: validators[GC.FIELD_CARRIER_NAME],
    },
    {
      type: 'toggle',
      name: 'titles:active',
      nameForAttribute: GC.FIELD_CARRIER_ACTIVE,
      showField: R.includes(PC.ACTIVATE_CARRIER_EXECUTE, authorities),
    },
    {
      type: 'toggle',
      name: 'titles:ignore-activation-deactivation-rules',
      nameForAttribute: GC.FIELD_IGNORE_DEACTIVATION_RULES,
      showField: R.includes(PC.ACTIVATE_CARRIER_EXECUTE, authorities),
    },
    {
      type: 'toggle',
      name: 'titles:own-company',
      nameForAttribute: GC.FIELD_CARRIER_OWN_COMPANY,
    },
    {
      width: 230,
      type: 'select',
      validate: G.required,
      options: 'deactivateType',
      name: 'titles:status-reason',
      nameForAttribute: GC.FIELD_CARRIER_DEACTIVATED_REASON,
      showField: R.and(G.isFalse(formValues.active), R.includes(PC.ACTIVATE_CARRIER_EXECUTE, authorities)),
    },
    {
      type: 'text',
      name: 'titles:dba',
      nameForAttribute: GC.FIELD_CARRIER_DBA,
    },
    {
      type: 'select',
      name: 'titles:type',
      nameForAttribute: GC.FIELD_TYPE,
      options: [
        GC.EMPTY_OPTION_OBJECT,
        {
          value: GC.CARRIER_TYPE_INTERSTATE,
          label: G.getWindowLocale('titles:interstate', 'Interstate'),
        },
        {
          value: GC.CARRIER_TYPE_BROKER,
          label: G.getWindowLocale('titles:broker', 'Broker'),
        },
        {
          value: GC.CARRIER_TYPE_INTERSTATE_OR_BROKER,
          label: G.getWindowLocale('titles:interstate-or-broker', 'Interstate or Broker'),
        },
        {
          value: GC.CARRIER_TYPE_INTRASTATE,
          label: G.getWindowLocale('titles:intrastate', 'Intrastate'),
        },
        {
          value: GC.CARRIER_TYPE_OTHER,
          label: G.getWindowLocale('titles:other', 'Other'),
        },
      ],
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:internal-id',
      nameForAttribute: GC.FIELD_CARRIER_INTERNAL_ID,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:scac',
      nameForAttribute: GC.FIELD_CARRIER_SCAC,
    },
    {
      width: 400,
      type: 'text',
      name: 'titles:usdot-number',
      nameForAttribute: GC.FIELD_CARRIER_USDOT_NUMBER,
    },
    {
      width: 400,
      type: 'text',
      name: 'titles:mc-number',
      nameForAttribute: GC.FIELD_CARRIER_MC_NUMBER,
      validate: validators[GC.FIELD_CARRIER_MC_NUMBER],
    },
    {
      width: 400,
      type: 'text',
      name: 'titles:accounting-external-id',
      nameForAttribute: GC.FIELD_ACCOUNTING_EXTERNAL_ID,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:intrastate-dot',
      nameForAttribute: GC.FIELD_CARRIER_INTRASTATE_DOT,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:intrastate-state',
      nameForAttribute: GC.FIELD_CARRIER_INTRASTATE_STATE,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:federal-ein',
      nameForAttribute: GC.FIELD_CARRIER_FEDERAL_EIN,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:duns-number',
      nameForAttribute: GC.FIELD_CARRIER_DUNS_NUMBER,
    },
    {
      zI: 14,
      width: 230,
      type: 'select',
      display: 'flex',
      values: 'selectedTrMode',
      component: renderMultiSelect,
      options: 'transportationMode',
      name: 'titles:transportation-mode',
      selectAction: 'handleSelectTransportationMode',
      nameForAttribute: GC.FIELD_CARRIER_TRANSPORTATION_MODES,
    },
    {
      zI: 13,
      width: 230,
      type: 'select',
      display: 'flex',
      name: 'titles:equipment',
      options: 'equipmentType',
      values: 'selectedEquipment',
      component: renderMultiSelect,
      selectAction: 'handleSelectEquipment',
      nameForAttribute: GC.FIELD_CARRIER_EQUIPMENTS,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:url',
      nameForAttribute: GC.FIELD_CARRIER_URL,
    },
    {
      width: 230,
      type: 'multiEmail',
      name: 'titles:emails',
      shouldOverrideEmails: true,
      nameForAttribute: GC.FIELD_EMAIL,
      validate: G.isAllTrue(G.isEmail),
      selectAction: 'handleChangeEmail',
    },
    {
      type: 'phoneNumber',
      validate: G.isPhoneNumber,
      name: 'titles:phone-number',
      nameForAttribute: GC.FIELD_PHONE_NUMBER,
    },
    {
      zI: 12,
      name: 'titles:address1',
      type: 'addressAutocomplete',
      nameForAttribute: GC.FIELD_ADDRESS,
    },
    {
      zI: 12,
      type: 'text',
      name: 'titles:address2',
      nameForAttribute: GC.FIELD_ADDRESS_2,
    },
    {
      type: 'text',
      name: 'titles:city',
      nameForAttribute: GC.FIELD_CITY,
    },
    {
      type: 'text',
      name: 'titles:state',
      nameForAttribute: GC.FIELD_STATE,
    },
    {
      type: 'text',
      name: 'titles:zip',
      nameForAttribute: GC.FIELD_ZIP,
    },
    {
      type: 'countrySelect',
      name: 'titles:country',
      nameForAttribute: GC.FIELD_COUNTRY,
    },
    {
      type: 'textarea',
      name: 'titles:notes',
      nameForAttribute: GC.FIELD_NOTES,
      validate: G.curriedIsEmptyOrMinMax(0, 2000),
    },
    {
      type: 'toggle',
      name: 'titles:use-internal-division',
      nameForAttribute: GC.FIELD_INTERNAL_DIVISION,
    },
    {
      type: 'select',
      validate: G.required,
      useOptionsFromProps: true,
      name: 'titles:internal-division',
      options: 'branchListByTypeOptions',
      nameForAttribute: GC.FIELD_INTERNAL_DIVISION_GUID,
      showField: G.isTrue(G.getPropFromObject(GC.FIELD_INTERNAL_DIVISION, formValues)),
    },
  ],
});

export const validationSchemaAssociatedCompany = Yup.object().shape({
  [GC.FIELD_CARRIER_ASSOC_COMPANY_URL]: G.yupStringNotRequired.url(G.getShouldBeUrlLocaleTxt()),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_SCAC]: G.yupStringNotRequired.max(6, G.getShouldBeFromToCharLocaleTxt(0, 6)),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_NAME]: G.yupStringRequired.max(250, G.getShouldBeFromToCharLocaleTxt(1, 250)),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_USDOT_NUMBER]: G.yupStringRequired.max(12, G.getShouldBeFromToCharLocaleTxt(1, 12)),
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTERNAL_ID]: G.yupStringNotRequired.max(30, G.getShouldBeFromToCharLocaleTxt(0, 30)),
});

export const defaultAssociatedCompanyFields = {
  [GC.FIELD_CARRIER_ASSOC_COMPANY_URL]: '',
  [GC.FIELD_CARRIER_ASSOC_COMPANY_NAME]: '',
  [GC.FIELD_CARRIER_ASSOC_COMPANY_SCAC]: '',
  [GC.FIELD_CARRIER_ASSOC_COMPANY_DUNS_NUMBER]: '',
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTERNAL_ID]: '',
  [GC.FIELD_CARRIER_ASSOC_COMPANY_USDOT_NUMBER]: '',
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_DOT]: '',
  [GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_STATE]: '',
};

const associatedCompanyInputWrapperStyles = {
  mb: 25,
  width: 200,
};

export const associatedCompanyFields = [
  {
    type: 'text',
    isRequired: true,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_NAME,
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
  },
  {
    type: 'text',
    label: ['titles:internal-id', 'Internal ID'],
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_INTERNAL_ID,
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
  },
  {
    type: 'text',
    label: ['titles:scac', 'SCAC'],
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_SCAC,
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:usdot-number', 'USDOT Number'],
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_USDOT_NUMBER,
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
  },
  {
    type: 'text',
    label: ['titles:intrastate-dot', 'Intrastate DOT #'],
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_DOT,
  },
  {
    type: 'text',
    label: ['titles:intrastate-state', 'Intrastate State'],
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_STATE,
  },
  {
    type: 'text',
    label: ['titles:duns-number', 'D-U-N-S Number'],
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_DUNS_NUMBER,
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
  },
  {
    type: 'text',
    label: ['titles:url', 'URL'],
    fieldName: GC.FIELD_CARRIER_ASSOC_COMPANY_URL,
    inputWrapperStyles: associatedCompanyInputWrapperStyles,
  },
];

export const documentFields = {
  fields: [
    {
      type: 'select',
      validate: G.required,
      options: 'documentType',
      name: 'titles:document-type',
      nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_TYPE,
    },
    {
      type: 'textarea',
      name: 'titles:description',
      nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_DESCRIPTION,
    },
    {
      type: 'calendar',
      align: 'baseline',
      isClearable: true,
      name: 'titles:signed-date',
      nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_SIGNED_DATE,
      maxDateField: GC.FIELD_CARRIER_DOCUMENT_EXPIRATION_DATE,
    },
    {
      type: 'calendar',
      align: 'baseline',
      isClearable: true,
      name: 'titles:expiration-date',
      minDateField: GC.FIELD_CARRIER_DOCUMENT_SIGNED_DATE,
      nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_EXPIRATION_DATE,
    },
    {
      type: 'text',
      name: 'titles:url',
      validate: [G.isURL, documentValidation],
      nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_URL,
      disabled: (params: Object) => (
        R.and(
          G.isNotNil(params.documentFilename),
          G.isNotEmpty(params.documentFilename),
        )
      ),
    },
    {
      width: 400,
      type: 'file',
      validate: documentValidation,
      placeholder: 'actions:upload',
      nameForAttribute: GC.FIELD_CARRIER_DOCUMENT_FILE,
      disabled: (params: Object) => G.isNotNilAndNotEmpty(params.url),
    },
  ],
};

export const terminalCreateFields = {
  formGroup: 'terminalDetails',
  title: 'titles:terminal-details',
  fields: [
    {
      width: 230,
      type: 'toggle',
      name: 'titles:primary',
      nameForAttribute: GC.FIELD_CARRIER_TERMINAL_PRIMARY,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:name',
      nameForAttribute: GC.FIELD_CARRIER_TERMINAL_NAME,
      validate: validators[GC.FIELD_CARRIER_TERMINAL_NAME],
    },
    {
      width: 230,
      type: 'toggle',
      name: 'titles:status',
      nameForAttribute: GC.FIELD_CARRIER_TERMINAL_ACTIVE,
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:location-name',
      nameForAttribute: GC.FIELD_LOCATION_NAME,
      validate: validators[GC.FIELD_LOCATION_NAME],
    },
    {
      width: 230,
      type: 'select',
      validate: G.required,
      options: 'locationType',
      name: 'titles:location-type',
      nameForAttribute: GC.FIELD_LOCATION_TYPE,
    },
    {
      zI: 12,
      width: 230,
      name: 'titles:address',
      type: 'addressAutocomplete',
      nameForAttribute: GC.FIELD_ADDRESS,
      validate: validators[GC.FIELD_ADDRESS],
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:city',
      nameForAttribute: GC.FIELD_CITY,
      validate: validators[GC.FIELD_CITY],
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:state',
      nameForAttribute: GC.FIELD_STATE,
      validate: validators[GC.FIELD_STATE],
    },
    {
      width: 230,
      type: 'text',
      name: 'titles:country',
      nameForAttribute: GC.FIELD_COUNTRY,
      validate: validators[GC.FIELD_COUNTRY],
    },
    {
      width: 230,
      type: 'text',
      disabled: true,
      name: 'titles:latitude',
      nameForAttribute: GC.FIELD_LATITUDE,
    },
    {
      width: 230,
      type: 'text',
      disabled: true,
      name: 'titles:longitude',
      nameForAttribute: GC.FIELD_LONGITUDE,
    },
  ],
};

export const defaultTerminalContactFields = {
  [GC.FIELD_FAX]: '',
  [GC.FIELD_EMAIL]: '',
  [GC.FIELD_PHONE]: '',
  [GC.FIELD_CONTACT_NAME]: '',
  [GC.FIELD_PHONE_EXTENSION]: '',
  [GC.FIELD_CARRIER_TERMINAL_CONTACT_PRIMARY]: false,
  [GC.FIELD_CARRIER_TERMINAL_CONTACT_SECONDARY_PHONE]: '',
};

const inputWrapperStyles = {
  mb: 25,
  width: 360,
};

export const getTerminalContactFields = ({ email, phone }: Object) => [
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:primary', 'Primary'],
    fieldName: GC.FIELD_CARRIER_TERMINAL_CONTACT_PRIMARY,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_CONTACT_NAME,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_EMAIL,
    label: ['titles:email', 'Email'],
    isRequired: G.isNilOrEmpty(phone),
  },
  {
    inputWrapperStyles,
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    isRequired: G.isNilOrEmpty(email),
    label: ['titles:primary-phone', 'Primary Phone'],
  },
  {
    type: 'number',
    inputWrapperStyles,
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: ['titles:phone-extension', 'Phone Extension'],
  },
  {
    type: 'number',
    inputWrapperStyles,
    label: ['titles:secondary-phone', 'Secondary Phone'],
    fieldName: GC.FIELD_CARRIER_TERMINAL_CONTACT_SECONDARY_PHONE,
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax', 'Fax'],
  },
];

export const getTerminalContactValidationSchema = Yup.lazy(({ email, phone }: Object) => {
  let schema = {
    [GC.FIELD_EMAIL]: Yup.string()
      .nullable(true)
      .required(G.getEmailOrPrimaryPhoneMustBeFilled())
      .email(G.getShouldBeEmailLocaleTxt()),
    [GC.FIELD_FAX]: Yup.string()
      .nullable(true)
      .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
    [GC.FIELD_PHONE]: Yup.string()
      .nullable(true)
      .required(G.getEmailOrPrimaryPhoneMustBeFilled())
      .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
    [GC.FIELD_PHONE_EXTENSION]: Yup.string()
      .nullable(true)
      .max(5, G.getShouldBeFromToCharLocaleTxt(0, 5)),
    [GC.FIELD_CARRIER_TERMINAL_CONTACT_SECONDARY_PHONE]: Yup.string()
      .nullable(true)
      .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
    [GC.FIELD_CONTACT_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .min(1, G.getShouldBeFromToLocaleTxt(0, 50))
      .max(50, G.getShouldBeFromToLocaleTxt(1, 50)),
  };

  if (G.isNotNilAndNotEmpty(email)) {
    schema = R.assoc(
      [GC.FIELD_PHONE],
      Yup.string().nullable(true).matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
      schema,
    );
  }

  if (G.isNotNilAndNotEmpty(phone)) {
    schema = R.assoc(
      [GC.FIELD_EMAIL],
      Yup.string().nullable(true).email(G.getShouldBeEmailLocaleTxt()),
      schema,
    );
  }

  return Yup.object().shape(schema);
});

const processTypes = [
  GC.EMPTY_OPTION_OBJECT,
  { name: G.getWindowLocale('titles:email', 'Email'), value: GC.TERMINAL_PROCESS_TYPE_EMAIL },
  { name: G.getWindowLocale('titles:api', 'API'), value: GC.TERMINAL_PROCESS_TYPE_API },
  { name: G.getWindowLocale('titles:edi', 'EDI'), value: GC.TERMINAL_PROCESS_TYPE_EDI },
  { name: G.getWindowLocale('titles:internal', 'Internal'), value: GC.TERMINAL_PROCESS_TYPE_INTERNAL },
];

const quoteProcessTypes = [
  GC.EMPTY_OPTION_OBJECT,
  { name: G.getWindowLocale('titles:email', 'Email'), value: GC.TERMINAL_PROCESS_TYPE_EMAIL },
];

const options = [
  {
    width: 40,
    value: true,
    name: G.getWindowLocale('titles:yes', 'Yes'),
  },
  {
    width: 40,
    value: false,
    name: G.getWindowLocale('titles:no', 'No'),
  },
  {
    width: 60,
    value: null,
    name: G.getWindowLocale('titles:default', 'Default'),
  },
];

export const terminalDispatchProcessFields = {
  title: 'titles:dispatch-process',
  formGroup: 'terminalDispatchProcess',
  sectionName: 'terminal.dispatchProcess',
  fields: [
    {
      type: 'select',
      options: processTypes,
      name: 'titles:dispatch-type',
      nameForAttribute: GC.FIELD_TERMINAL_PROCESS_TYPE,
    },
    {
      type: 'select',
      display: 'none',
      options: 'dispatchConfigs',
      name: 'titles:carrier-integration',
      filterForOptions: 'supportDispatch',
      nameForAttribute: GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID,
      validate: (value: string, form: Object) => getTerminalDispatchConfigFieldValidation(
        value,
        R.path([GC.SYSTEM_OBJECT_TERMINAL, GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS], form),
      ),
    },
    {
      type: 'select',
      options: 'carrierSequences',
      name: 'titles:tracking-sequence',
      nameForAttribute: GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
    },
    {
      type: 'select',
      options: 'carrierReferenceTypes',
      name: 'titles:tracking-custom-field-type',
      nameForAttribute: GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_REF_TYPE_GUID,
    },
    {
      options,
      type: 'multiswitch',
      name: 'titles:auto-accept',
      nameForAttribute: GC.FIELD_TERMINAL_PROCESS_AUTO_ACCEPT,
    },
    {
      type: 'multiEmail',
      name: 'titles:email-to',
      nameForAttribute: GC.FIELD_EMAIL_TO,
      selectAction: 'handleChangeDPEmailTo',
    },
    {
      type: 'text',
      name: 'titles:subject',
      nameForAttribute: GC.FIELD_MESSAGE_SUBJECT,
    },
    {
      type: 'select',
      options: 'documentTemplates',
      name: 'titles:document-template',
      nameForAttribute: GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID,
    },
    {
      width: 400,
      type: 'textarea',
      name: 'titles:message',
      nameForAttribute: GC.FIELD_MESSAGE,
    },
  ],
};

export const terminalQuoteProcessFields = {
  title: 'titles:bid-process',
  formGroup: 'terminalQuoteProcess',
  sectionName: 'terminal.quoteProcess',
  fields: [
    {
      type: 'select',
      options: quoteProcessTypes,
      name: 'titles:communication-type',
      nameForAttribute: GC.FIELD_TERMINAL_PROCESS_TYPE,
    },
    {
      zIndex: 20,
      options: 'emailGroup',
      name: 'titles:email-group',
      component: renderMultiSelect,
      selectAction: 'handleSelectEmailGroup',
      nameForAttribute: GC.FIELD_CARRIER_EMAIL_GROUP_GUIDS,
    },
    {
      type: 'multiEmail',
      name: 'titles:email-to',
      nameForAttribute: GC.FIELD_EMAIL_TO,
      selectAction: 'handleChangeQPEmailTo',
    },
    {
      type: 'text',
      name: 'titles:subject',
      nameForAttribute: GC.FIELD_MESSAGE_SUBJECT,
    },
    {
      width: 400,
      type: 'textarea',
      name: 'titles:message',
      nameForAttribute: GC.FIELD_MESSAGE,
    },
  ],
};

export const terminalStatusCheckProcessFields = {
  title: 'titles:load-status-check',
  formGroup: 'terminalStatusCheckProcess',
  sectionName: 'terminal.statusCheckProcess',
  fields: [
    {
      options,
      type: 'multiswitch',
      name: 'titles:load-check-process-enabled',
      nameForAttribute: GC.FIELD_TERMINAL_PROCESS_ENABLED,
    },
    {
      type: 'select',
      options: R.take(3, processTypes),
      name: 'titles:communication-type',
      nameForAttribute: GC.FIELD_TERMINAL_PROCESS_TYPE,
    },
    {
      type: 'select',
      display: 'none',
      options: 'dispatchConfigs',
      name: 'titles:carrier-integration',
      filterForOptions: 'supportStatusCheck',
      nameForAttribute: GC.FIELD_TERMINAL_INTEGRATION_CONFIG_GUID,
      validate: (value: string, form: Object) => getTerminalDispatchConfigFieldValidation(
        value,
        R.path([GC.SYSTEM_OBJECT_TERMINAL, GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS], form),
      ),
    },
    {
      type: 'multiEmail',
      name: 'titles:email-to',
      nameForAttribute: GC.FIELD_EMAIL_TO,
      selectAction: 'handleChangeSCPEmailTo',
    },
    {
      type: 'text',
      name: 'titles:subject',
      nameForAttribute: GC.FIELD_MESSAGE_SUBJECT,
    },
    {
      width: 400,
      type: 'textarea',
      name: 'titles:message',
      nameForAttribute: GC.FIELD_MESSAGE,
    },
    {
      options,
      type: 'multiswitch',
      name: 'titles:auto-status-check-enabled',
      nameForAttribute: GC.AUTO_STATUS_CHECK_ENABLED,
    },
    {
      type: 'text',
      validate: G.isEmptyOrFloat,
      name: 'titles:status-check-frequency',
      nameForAttribute: GC.FIELD_TERMINAL_STATUS_CHECK_FREQUENCY,
    },
    {
      options,
      type: 'multiswitch',
      name: 'titles:sms-notification',
      nameForAttribute: GC.FIELD_TERMINAL_SMS_NOTIFICATION_ENABLED,
    },
  ],
};

export const financeFields = {
  title: 'titles:finance',
  fields: [
    {
      type: 'select',
      name: 'titles:currency',
      options: GC.CURRENCY_OPTIONS,
      nameForAttribute: GC.FIELD_CURRENCY,
    },
    {
      type: 'text',
      name: 'titles:deduction-percentage',
      validate: G.curriedIsLessOrEqual(100),
      nameForAttribute: GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE,
    },
  ],
};

export const processGroups = [
  terminalDispatchProcessFields,
  terminalQuoteProcessFields,
  terminalStatusCheckProcessFields,
];

export const dispatchProcessTypeEDIInternalGroup = [
  {
    ...terminalDispatchProcessFields,
    fields: R.take(1, terminalDispatchProcessFields.fields),
  },
];

export const defaultCertificateFields = {
  [GC.FIELD_CARRIER_CERTIFICATE_CLASS]: '',
  [GC.FIELD_CARRIER_CERTIFICATE_PERMIT]: false,
  [GC.FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT]: '',
  [GC.FIELD_CARRIER_CERTIFICATE_CERTIFIED]: false,
  [GC.FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN]: false,
  [GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE]: '',
  [GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE]: '',
};

export const certificateValidationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_CERTIFICATE_CLASS]: G.yupArrayRequired,
  [GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE]: G.yupStringRequired,
  [GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE]: G.yupStringRequired,
});

const certificateInputWrapperStyles = {
  mb: 25,
  width: 200,
};

export const certificateFields = [
  {
    type: 'toggle',
    label: ['titles:certified', 'Certified'],
    inputWrapperStyles: { mb: 25, width: '100%' },
    fieldName: GC.FIELD_CARRIER_CERTIFICATE_CERTIFIED,
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    label: ['titles:class', 'Class'],
    fieldName: GC.FIELD_CARRIER_CERTIFICATE_CLASS,
    inputWrapperStyles: { ...certificateInputWrapperStyles, zIndex: 11 },
    options: [
      // TODO add locale and new value
      {
        value: '1 - Explosives',
        label: '1 - Explosives',
      },
      {
        value: '1.1 Explosives_Mass explosion hazard',
        label: '1.1 Explosives_Mass explosion hazard',
      },
      {
        value: '1.2 - Explosives_Projection hazard',
        label: '1.2 - Explosives_Projection hazard',
      },
      {
        value: '1.3 - Explosives_Fire, minor blast, andor projection hazard',
        label: '1.3 - Explosives_Fire, minor blast, andor projection hazard',
      },
      {
        value: '1.4 - Explosives_No significant hazard',
        label: '1.4 - Explosives_No significant hazard',
      },
      {
        value: '1.5 - Explosives_Insensitive material with mass explosion hazard',
        label: '1.5 - Explosives_Insensitive material with mass explosion hazard',
      },
      {
        value: '1.6 - Explosives_Insenitive articles with no mass explosion hazard',
        label: '1.6 - Explosives_Insenitive articles with no mass explosion hazard',
      },
      {
        value: '2 - Gases',
        label: '2 - Gases',
      },
      {
        value: '2.1 Gases_Flammable Gas',
        label: '2.1 Gases_Flammable Gas',
      },
      {
        value: '2.2 - Gases_Non-flammable non poisonous',
        label: '2.2 - Gases_Non-flammable non poisonous',
      },
      {
        value: '2.3 - Gases_Poisonous or toxic gas',
        label: '2.3 - Gases_Poisonous or toxic gas',
      },
      {
        value: '3 - Flammable and Combustible Liquids',
        label: '3 - Flammable and Combustible Liquids',
      },
      {
        value: '4 - Flammable Solids',
        label: '4 - Flammable Solids',
      },
      {
        value: '4.1 - Flammable Solids_Flammable Solid',
        label: '4.1 - Flammable Solids_Flammable Solid',
      },
      {
        value: '4.2 - Flammable Solids_Spontaneously combustible material',
        label: '4.2 - Flammable Solids_Spontaneously combustible material',
      },
      {
        value: '4.3 - Flammable Solids_Dangerous when wet material',
        label: '4.3 - Flammable Solids_Dangerous when wet material',
      },
      {
        value: '5 - Oxidizers and Organic Peroxides',
        label: '5 - Oxidizers and Organic Peroxides',
      },
      {
        value: '5.1 - Oxidizers and Organic Peroxides_Oxidizer',
        label: '5.1 - Oxidizers and Organic Peroxides_Oxidizer',
      },
      {
        value: '5.2 - Oxidizers and Organic Peroxides_Organic Peroxide',
        label: '5.2 - Oxidizers and Organic Peroxides_Organic Peroxide',
      },
      {
        value: '6 - Poisonous Toxic and Infectious Substances',
        label: '6 - Poisonous Toxic and Infectious Substances',
      },
      {
        value: '6.1 - Poisonous Toxic and Infectious Substances_Poisonus or toxic substances',
        label: '6.1 - Poisonous Toxic and Infectious Substances_Poisonus or toxic substances',
      },
      {
        value: '6.2 - Poisonous Toxic and Infectious Substances_Infectious substances',
        label: '6.2 - Poisonous Toxic and Infectious Substances_Infectious substances',
      },
      {
        value: '7 - Radioactive Materials',
        label: '7 - Radioactive Materials',
      },
      {
        value: '8 - Corrosive Materials',
        label: '8 - Corrosive Materials',
      },
      {
        value: '9 - Miscellaneous Dangerous Substances or Articles',
        label: '9 - Miscellaneous Dangerous Substances or Articles',
      },
    ],
  },
  {
    isRequired: true,
    type: 'datePicker',
    label: ['titles:effective-date', 'Effective Date'],
    fieldName: GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE,
    inputWrapperStyles: { ...certificateInputWrapperStyles, zIndex: 11 },
    maxDate: ({ values: { expirationDate } }: Object) => R.and(
      G.isNotNilAndNotEmpty(expirationDate), G.subtractMomentTime(expirationDate, 1, GC.FIELD_DAYS),
    ),
  },
  {
    isRequired: true,
    type: 'datePicker',
    label: ['titles:expiration-date', 'Expiration Date'],
    fieldName: GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE,
    inputWrapperStyles: { ...certificateInputWrapperStyles, zIndex: 10 },
    minDate: ({ values: { effectiveDate } }: Object) => R.and(
      G.isNotNilAndNotEmpty(effectiveDate), G.addMomentTime(effectiveDate, 1, GC.FIELD_DAYS),
    ),
  },
  {
    type: 'number',
    label: ['titles:driver-count', 'Driver Count'],
    inputWrapperStyles: certificateInputWrapperStyles,
    fieldName: GC.FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT,
  },
  {
    type: 'toggle',
    label: ['titles:permit-hm232', 'HM232 Permit'],
    fieldName: GC.FIELD_CARRIER_CERTIFICATE_PERMIT,
    inputWrapperStyles: certificateInputWrapperStyles,
  },
  {
    type: 'toggle',
    label: ['titles:safety-plan', 'Safety Plan'],
    inputWrapperStyles: certificateInputWrapperStyles,
    fieldName: GC.FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN,
  },
];

export const validationSchemaRateDetailsObject = {
  [GC.FIELD_CHARGE_RATE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToLocaleTxt(0, 999999)),
  [GC.FIELD_CHARGE_RATE_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_RATE_UNIT]: Yup.string()
    .nullable(true),
  [GC.FIELD_CHARGE_MIN_RATE]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToLocaleTxt(0, 999999)),
  [GC.FIELD_CHARGE_MAX_RATE]: Yup.number()
    .nullable(true)
    .max(9999999, G.getShouldBeFromToLocaleTxt(0, 9999999)),
};


export const validationSchemaCarrierAssessorialObject = {
  ...validationSchemaRateDetailsObject,
  [GC.FIELD_DRIVER_ASSESSORIAL_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

const validationSchemaCarrierContractObject = {
  [GC.FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(250, G.getShouldBeFromToCharLocaleTxt(0, 250)),
  [GC.FIELD_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_MODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_RATE_EXPIRATION_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const validationSchemaCreateContract = Yup.object().shape(validationSchemaCarrierContractObject);

export const getUpdateCarrierContractValidationSchema = Yup.lazy((values: Object) => {
  let schema = validationSchemaCarrierContractObject;
  const upliftValue = R.path([LC.FIELD_CARRIER_CONTRACT_UPLIFT, LC.FIELD_CARRIER_CONTRACT_UPLIFT_VALUE], values);
  const discountType = R.path([LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);
  const variableType = R.path([LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_VARIABLE_TYPE], values);

  if (G.isNotNilAndNotEmpty(upliftValue)) {
    schema = {
      ...validationSchemaCarrierContractObject,
      [LC.FIELD_CARRIER_CONTRACT_UPLIFT]: Yup.object().shape({
        [LC.FIELD_CARRIER_CONTRACT_UPLIFT_RATE_UNIT]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      }),
    };
  }

  if (R.equals(discountType, GC.DISCOUNT_TYPE_FLAT)) {
    schema = {
      ...validationSchemaCarrierContractObject,
      [LC.FIELD_CARRIER_CONTRACT_DISCOUNT]: Yup.object()
        .shape({
          [GC.FIELD_RATE]: Yup.string()
            .nullable(true)
            .required(G.getRequiredLocaleTxt()),
          [GC.FIELD_RATE_UNIT]: Yup.string()
            .nullable(true)
            .required(G.getRequiredLocaleTxt()),
        }),
    };
  }

  if (R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE)) {
    schema = {
      ...validationSchemaCarrierContractObject,
      [LC.FIELD_CARRIER_CONTRACT_DISCOUNT]: Yup.object()
        .shape({
          [LC.FIELD_VARIABLE_TYPE]: Yup.string()
            .nullable(true)
            .required(G.getRequiredLocaleTxt()),
        }),
    };
  }

  if (R.or(
    R.equals(variableType, GC.VARIABLE_TYPE_WEIGHT),
    R.equals(variableType, GC.VARIABLE_TYPE_DISTANCE))) {
    schema = {
      ...validationSchemaCarrierContractObject,
      [LC.FIELD_CARRIER_CONTRACT_DISCOUNT]: Yup.object()
        .shape({
          [LC.FIELD_VARIABLE_UNIT]: Yup.string()
            .nullable(true)
            .required(G.getRequiredLocaleTxt()),
        }),
    };
  }

  return Yup.object().shape(schema);
});

export const validationSchemaCarrierAwardedLineObject = {
  [LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES]: G.yupArrayRequired,
  [LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES]: G.yupArrayRequired,
  [GC.FIELD_CARRIER_RATE_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToCharLocaleTxt(0, 85)),
  [GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_RATE_EXPIRATION_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const getCarrierAwardedLineValidationSchemaObject = (values: Object) => {
  let schema = validationSchemaCarrierAwardedLineObject;

  const distanceRangeFields = R.values(R.pick(
    [
      GC.FIELD_CARRIER_CONTRACT_MIN_DISTANCE,
      GC.FIELD_CARRIER_CONTRACT_MAX_DISTANCE,
    ],
    values,
  ));

  const weightRangeFields = R.values(R.pick(
    [
      GC.FIELD_MIN_WEIGHT,
      GC.FIELD_MAX_WEIGHT,
    ],
    values,
  ));

  const volumeRangeFields = R.values(R.pick([GC.FIELD_CARRIER_CONTRACT_MAX_VOLUME], values));

  if (R.any(G.isNotNilAndNotEmpty, distanceRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_CARRIER_RATE_DISTANCE_OUM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }

  if (R.any(G.isNotNilAndNotEmpty, weightRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_WEIGHT_UOM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }

  if (R.any(G.isNotNilAndNotEmpty, volumeRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_CARRIER_CONTRACT_VOLUME_UOM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }

  return schema;
};

export const validationSchemaCarrierRateObject = {
  ...validationSchemaRateDetailsObject,
  [LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES]: G.yupArrayRequired,
  [LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES]: G.yupArrayRequired,
  [GC.FIELD_CARRIER_RATE_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToCharLocaleTxt(0, 85)),
  [GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_RATE_EXPIRATION_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_FROM]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_TO]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_FROM]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_TO]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToLocaleTxt(0, 999999)),
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_TO]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToLocaleTxt(0, 999999)),
  [GC.FIELD_TRANSIT_DAYS]: Yup.number()
    .nullable(true)
    .max(99, G.getShouldBeFromToLocaleTxt(0, 999999)),
  [GC.FIELD_MIN_WEIGHT]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToLocaleTxt(0, 999999)),
  [GC.FIELD_MAX_WEIGHT]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToLocaleTxt(0, 999999)),
  [GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

export const getCarrierRateValidationSchemaObject = (values: Object) => {
  let schema = validationSchemaCarrierRateObject;

  const originZone = R.prop(GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID, values);
  const originCountries = R.prop(LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES, values);
  const destinationZone = R.prop(GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID, values);
  const destinationCountries = R.prop(LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES, values);

  if (G.isNotNilAndNotEmpty(originCountries)) {
    schema = R.dissoc(
      GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID,
      schema,
    );
  }

  if (G.isNotNilAndNotEmpty(destinationCountries)) {
    schema = R.dissoc(
      GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID,
      schema,
    );
  }

  if (G.isNotNilAndNotEmpty(originZone)) {
    schema = R.dissoc(
      LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES,
      schema,
    );
  }

  if (G.isNotNilAndNotEmpty(destinationZone)) {
    schema = R.dissoc(
      LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES,
      schema,
    );
  }

  const distanceRangeFields = R.values(R.pick(
    [
      GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_TO,
      GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM,
    ],
    values,
  ));

  const weightRangeFields = R.values(R.pick(
    [
      GC.FIELD_MIN_WEIGHT,
      GC.FIELD_MAX_WEIGHT,
    ],
    values,
  ));

  const quantityRangeFields = R.values(R.pick(
    [
      GC.FIELD_MIN_QUANTITY,
      GC.FIELD_MAX_QUANTITY,
    ],
    values,
  ));

  const temperatureRangeFields = R.values(R.pick(
    [
      GC.FIELD_MIN_TEMPERATURE,
      GC.FIELD_MAX_TEMPERATURE,
    ],
    values,
  ));

  if (R.any(G.isNotNilAndNotEmpty, distanceRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_CARRIER_RATE_DISTANCE_OUM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }

  if (R.any(G.isNotNilAndNotEmpty, weightRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_WEIGHT_UOM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }

  if (R.any(G.isNotNilAndNotEmpty, quantityRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_PACKAGE_TYPE]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }

  if (R.any(G.isNotNilAndNotEmpty, temperatureRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_TEMPERATURE_UOM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }

  return schema;
};

export const defaultRateFields = {
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_CHARGE_MIN_RATE]: '',
  [GC.FIELD_CHARGE_MAX_RATE]: '',
  [GC.FIELD_CHARGE_RATE_UNIT]: '',
  [GC.FIELD_CHARGE_RATE_TYPE]: '',
};

export const defaultCreateContractFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_MODE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE]: null,
  [GC.FIELD_CARRIER_RATE_EXPIRATION_DATE]: null,
};

export const defaultAccessorialsFields = {
  ...defaultRateFields,
  [GC.FIELD_DRIVER_ASSESSORIAL_GUID]: null,
};

export const defaultCarrierRateFields = {
  [GC.FIELD_MIN_WEIGHT]: '',
  [GC.FIELD_MAX_WEIGHT]: '',
  [GC.FIELD_WEIGHT_UOM]: '',
  [GC.FIELD_TRANSIT_DAYS]: '',
  [GC.FIELD_CARRIER_ACTIVE]: true,
  [GC.FIELD_CARRIER_RATE_NAME]: '',
  [GC.FIELD_CARRIER_RATE_DISTANCE_OUM]: '',
  [LC.FIELD_CARRIER_RATE_ORIGIN_CITY]: null,
  [LC.FIELD_CARRIER_RATE_ORIGIN_CITIES]: [],
  [GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE]: '',
  [LC.FIELD_CARRIER_RATE_ORIGIN_STATES]: null,
  [GC.FIELD_CARRIER_RATE_EXPIRATION_DATE]: '',
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES]: [],
  [GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID]: '',
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_TO]: '',
  [LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_CITY]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_CITIES]: [],
  [LC.FIELD_CARRIER_RATE_DESTINATION_STATES]: [],
  [GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM]: '',
  [GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID]: '',
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_TO]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES]: [],
  [LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES]: null,
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_FROM]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_TO]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_FROM]: null,
  ...defaultRateFields,
};

export const defaultAwardedLine = {
  [LC.FIELD_WEIGHT_UOM]: null,
  [LC.FIELD_VOLUME_UOM]: null,
  [LC.FIELD_DISTANCE_UOM]: null,
  [GC.FIELD_CARRIER_RATE_NAME]: null,
  [LC.FIELD_CARRIER_RATE_ORIGIN_CITY]: null,
  [LC.FIELD_CARRIER_RATE_ORIGIN_CITIES]: [],
  [LC.FIELD_CARRIER_RATE_ORIGIN_STATES]: [],
  [LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES]: [],
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES]: [],
  [GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE]: null,
  [GC.FIELD_CARRIER_RATE_EXPIRATION_DATE]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_CITY]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_CITIES]: [],
  [LC.FIELD_CARRIER_RATE_DESTINATION_STATES]: [],
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_TO]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES]: [],
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES]: [],
  [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_FROM]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_TO]: null,
  [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_FROM]: null,
};

export const compensationCommonFieldStyles = {
  width: 220,
  afterTop: 15,
  afterRight: 15,
  errorTop: '110%',
  errorLeft: '5px',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  inputsFlexDirection: 'column',
};

const compensationDatesFields = [
  {
    type: 'text',
    isRequired: true,
    loc: 'titles:name',
    ...compensationCommonFieldStyles,
    fieldName: GC.FIELD_CARRIER_RATE_NAME,
  },
  {
    ...compensationCommonFieldStyles,
    width: 250,
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: false,
    loc: 'titles:effective-date',
    fieldName: GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    width: 250,
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: false,
    minDate: G.getCurrentDate(),
    loc: 'titles:expiration-date',
    fieldName: GC.FIELD_CARRIER_RATE_EXPIRATION_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    width: 100,
    type: 'toggle',
    loc: 'titles:active',
    fieldName: GC.FIELD_CARRIER_ACTIVE,
  },
];

const contractGeneralFields = [
  {
    ...compensationCommonFieldStyles,
    zIndex: 20,
    type: 'text',
    isRequired: true,
    loc: 'titles:name',
    alignItems: 'center',
    flexDirection: 'row',
    fieldName: GC.FIELD_NAME,
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 19,
    width: 256,
    hideIcon: true,
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: false,
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:effective-date',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 18,
    width: 256,
    hideIcon: true,
    isRequired: true,
    type: 'calendar',
    isClearable: true,
    timeSelection: false,
    alignItems: 'center',
    flexDirection: 'row',
    minDate: G.getCurrentDate(),
    loc: 'titles:expiration-date',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 17,
    width: 214,
    type: 'toggle',
    loc: 'titles:active',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_ACTIVE,
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 16,
    type: 'select',
    isRequired: true,
    loc: 'titles:mode',
    shouldHandle: true,
    alignItems: 'center',
    flexDirection: 'row',
    fieldName: GC.FIELD_MODE,
    options: 'transportationMode',
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 15,
    type: 'select',
    isRequired: true,
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:currency',
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 14,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:roles',
    alignItems: 'center',
    flexDirection: 'row',
    options: 'roleOptions',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_ROLE_GUIDS,
  },
];

const displayDiscountFlat = (_: any, values: Object) => {
  const rateType = R.pathOr(null, [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

  if (R.or(
    G.isNilOrEmpty(rateType),
    G.notEquals(rateType, GC.DISCOUNT_TYPE_FLAT),
  )) return 'none';
};

const variableUnitOptions = {
  [GC.CHARGE_RATE_TYPE_WEIGHT]: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_DISTANCE]: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
};

const contractEditGeneralFields = [
  ...contractGeneralFields,
  {
    ...compensationCommonFieldStyles,
    zIndex: 10,
    step: '0.01',
    type: 'number',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:min-rate',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CHARGE_MIN_RATE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:discount-type',
    justifyContent: 'space-between',
    fieldName: LC.FIELD_CARRIER_CONTRACT_DISCOUNT_TYPE,
    options: [
      { label: '', value: '' },
      { label: 'Flat', value: GC.DISCOUNT_TYPE_FLAT },
      { label: 'Variable', value: GC.DISCOUNT_TYPE_VARIABLE },
    ],
  },
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:discount-rate',
    justifyContent: 'space-between',
    fieldGroupDisplay: displayDiscountFlat,
    fieldName: LC.FIELD_CARRIER_CONTRACT_RATE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    loc: 'titles:discount-rate-unit',
    fieldGroupDisplay: displayDiscountFlat,
    options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
    fieldName: LC.FIELD_CARRIER_CONTRACT_RATE_UNIT,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:variable-type',
    justifyContent: 'space-between',
    fieldName: LC.FIELD_CARRIER_CONTRACT_VARIABLE_TYPE,
    options: [
      { label: '', value: '' },
      { label: 'Weight', value: GC.CHARGE_RATE_TYPE_WEIGHT },
      { label: 'Distance', value: GC.CHARGE_RATE_TYPE_DISTANCE },
      { label: 'Stop', value: GC.CHARGE_RATE_TYPE_STOP },
      { label: 'Rate Total', value: GC.CHARGE_RATE_TYPE_RATE_TOTAL },
    ],
    fieldGroupDisplay: (_: any, values: Object) => {
      const rateType = R.pathOr(null, [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

      if (R.or(
        G.isNilOrEmpty(rateType),
        G.notEquals(rateType, GC.DISCOUNT_TYPE_VARIABLE),
      )) return 'none';
    },
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fieldName: LC.FIELD_CARRIER_CONTRACT_VARIABLE_UNIT,
    loc: 'titles:variable-unit',
    options: (values: Object) => {
      const variableType = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_VARIABLE_TYPE], values);

      return variableUnitOptions[variableType];
    },
    fieldGroupDisplay: (_: any, values: Object) => {
      const rateType = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

      const hasUnit = ['', GC.CHARGE_RATE_TYPE_QUANTITY, GC.CHARGE_RATE_TYPE_STOP, GC.CHARGE_RATE_TYPE_RATE_TOTAL]
        .includes(R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_VARIABLE_TYPE], values));

      if (R.or(hasUnit, G.notEquals(rateType, GC.DISCOUNT_TYPE_VARIABLE))) return 'none';
    },
  },
];

const contractUpliftFields = [
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:uplift-value',
    justifyContent: 'space-between',
    fieldName: `${LC.FIELD_CARRIER_CONTRACT_UPLIFT}.${LC.FIELD_CARRIER_CONTRACT_UPLIFT_VALUE}`,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:rate-unit',
    justifyContent: 'space-between',
    options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
    fieldName: `${LC.FIELD_CARRIER_CONTRACT_UPLIFT}.${LC.FIELD_CARRIER_CONTRACT_UPLIFT_RATE_UNIT}`,
  },
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:min-value',
    justifyContent: 'space-between',
    fieldName: `${LC.FIELD_CARRIER_CONTRACT_UPLIFT}.${LC.FIELD_CARRIER_CONTRACT_UPLIFT_MIN}`,
  },
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:max-value',
    justifyContent: 'space-between',
    fieldName: `${LC.FIELD_CARRIER_CONTRACT_UPLIFT}.${LC.FIELD_CARRIER_CONTRACT_UPLIFT_MAX}`,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:apply-to',
    justifyContent: 'space-between',
    options: G.prependEmptyLabelValueOption(getCustomerContractUpliftApplyToEnumOptions()),
    fieldName: `${LC.FIELD_CARRIER_CONTRACT_UPLIFT}.${LC.FIELD_CARRIER_CONTRACT_UPLIFT_APPLY_TO_}`,
  },
];

export const defaultContractDiscountFields = {
  [LC.FIELD_VALUE_TO]: null,
  [LC.FIELD_VALUE_FROM]: null,
  [GC.FIELD_CHARGE_RATE]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
};

export const contractDiscountDatesFields = [
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    loc: 'titles:rate',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CHARGE_RATE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:rate-unit',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
  },
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:value-from',
    justifyContent: 'space-between',
    fieldName: LC.FIELD_VALUE_FROM,
  },
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:value-to',
    fieldName: LC.FIELD_VALUE_TO,
    justifyContent: 'space-between',
  },
];

const compensationOriginFields = [
  {
    ...compensationCommonFieldStyles,
    shouldHandleEnter: true,
    loc: 'titles:search-city',
    type: 'addressAutocomplete',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_CITY,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:origin-cities',
    options: 'originCityOptions',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_CITIES,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'multiselect',
    options: STATE_OPTIONS,
    loc: 'titles:origin-states',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_STATES,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    isRequired: true,
    type: 'multiselect',
    options: COUNTRY_OPTIONS,
    loc: 'titles:origin-countries',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandleEnter: true,
    loc: 'titles:search-zip',
    type: 'addressAutocomplete',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_ZIP,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:origin-zip-codes',
    options: 'originZipCodesOptions',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:zip-from',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_FROM,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:zip-to',
    fieldName: LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_TO,
    disabled: (field: any, { originZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(originZoneGuid),
  },
];

const compensationDestinationFields = [
  {
    ...compensationCommonFieldStyles,
    shouldHandleEnter: true,
    loc: 'titles:search-city',
    type: 'addressAutocomplete',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_CITY,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:destination-cities',
    options: 'destinationCityOptions',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_CITIES,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'multiselect',
    options: STATE_OPTIONS,
    loc: 'titles:destination-states',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_STATES,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    isRequired: true,
    type: 'multiselect',
    options: COUNTRY_OPTIONS,
    loc: 'titles:destination-countries',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandleEnter: true,
    loc: 'titles:search-zip',
    type: 'addressAutocomplete',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_ZIP,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:destination-zip-codes',
    options: 'destinationZipCodesOptions',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:zip-from',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_FROM,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:zip-to',
    fieldName: LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_TO,
    disabled: (field: any, { destinationZoneGuid }: Object) =>
      G.isNotNilAndNotEmpty(destinationZoneGuid),
  },
];

const accessorialsMainFields = [
  {
    ...compensationCommonFieldStyles,
    pr: 20,
    type: 'select',
    isRequired: true,
    shouldCustomChange: true,
    loc: 'titles:assessorial',
    options: 'accessorialsOptions',
    fieldName: GC.FIELD_DRIVER_ASSESSORIAL_GUID,
  },
  {
    ...compensationCommonFieldStyles,
    width: 50,
    type: 'toggle',
    disabled: true,
    flexDirection: 'row',
    alignItems: 'center',
    loc: 'titles:fuel-related',
    fieldName: GC.FIELD_FUEL_RELATED,
  },
  {
    ...compensationCommonFieldStyles,
    width: 50,
    type: 'toggle',
    disabled: true,
    flexDirection: 'row',
    alignItems: 'center',
    loc: 'titles:non-taxable',
    fieldName: GC.FIELD_NON_TAXABLE,
  },
];

export const accessorialsMainSection = {
  fields: accessorialsMainFields,
  title: 'titles:compensation-details',
};

export const accessorialsRateDetailsSection = {
  title: 'titles:compensation-details',
  fields: [
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      isRequired: true,
      loc: 'titles:rate',
      fieldName: GC.FIELD_CHARGE_RATE,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      isRequired: true,
      loc: 'titles:currency',
      fieldName: GC.FIELD_CURRENCY,
      options: GC.CURRENCY_OPTIONS,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      isRequired: true,
      loc: 'titles:rate-type',
      shouldCustomChange: true,
      fieldName: GC.FIELD_CHARGE_RATE_TYPE,
      options: DEFAULT_CARRIER_ASSESSORIAL_RATE_TYPE_OPTIONS,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      shouldSetOptions: true,
      loc: 'titles:rate-unit',
      fieldName: GC.FIELD_CHARGE_RATE_UNIT,
      optionsGroups: DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS,
    },
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:min-rate',
      fieldName: GC.FIELD_CHARGE_MIN_RATE,
      disabled: (_: any, values: Object) => (
        R.equals(R.prop(GC.FIELD_CHARGE_RATE_TYPE, values), GC.CHARGE_RATE_TYPE_FLAT)
      ),
    },
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:max-rate',
      fieldName: GC.FIELD_CHARGE_MAX_RATE,
      disabled: (_: any, values: Object) => (
        R.equals(R.prop(GC.FIELD_CHARGE_RATE_TYPE, values), GC.CHARGE_RATE_TYPE_FLAT)
      ),
    },
  ],
};

export const compensationDetailsFields = [
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    isRequired: true,
    loc: 'titles:rate',
    fieldName: GC.FIELD_CHARGE_RATE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'multiselect',
    options: 'equipments',
    loc: 'titles:equipments',
    fieldName: GC.FIELD_CARRIER_EQUIPMENTS,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'multiselect',
    options: 'serviceType',
    loc: 'titles:service-type',
    fieldName: GC.FIELD_CARRIER_SERVICE_TYPES,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:rate-type',
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    options: DEFAULT_CARRIER_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    shouldSetOptions: true,
    loc: 'titles:rate-unit',
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    optionsGroups: DEFAULT_RATE_UNIT_GROUP_OPTIONS,
  },
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    loc: 'titles:min-rate',
    fieldName: GC.FIELD_CHARGE_MIN_RATE,
    disabled: (field: any, values: Object) => (
      R.equals(R.prop(GC.FIELD_CHARGE_RATE_TYPE, values), GC.CHARGE_RATE_TYPE_FLAT)
    ),
  },
  {
    ...compensationCommonFieldStyles,
    step: '0.01',
    type: 'number',
    loc: 'titles:max-rate',
    fieldName: GC.FIELD_CHARGE_MAX_RATE,
    disabled: (field: any, values: Object) => (
      R.equals(R.prop(GC.FIELD_CHARGE_RATE_TYPE, values), GC.CHARGE_RATE_TYPE_FLAT)
    ),
  },
  {
    ...compensationCommonFieldStyles,
    step: '1',
    type: 'number',
    loc: 'titles:transit-days',
    fieldName: GC.FIELD_TRANSIT_DAYS,
  },
];

export const carrierRateDatesSection = {
  title: 'titles:payment-date',
  fields: compensationDatesFields,
};

export const carrierRateGeoFencingZoneFields = [
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    options: 'geoFencingZoneList',
    loc: 'titles:origin-zone-name',
    fieldName: GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID,
    disabled: (field: any, values: Object) =>
      G.isNotNilAndNotEmpty(R.prop(LC.FIELD_CARRIER_RATE_ORIGIN_COUNTRIES, values)),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    options: 'geoFencingZoneList',
    loc: 'titles:destination-zone-name',
    fieldName: GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID,
    disabled: (field: any, values: Object) =>
      G.isNotNilAndNotEmpty(R.prop(LC.FIELD_CARRIER_RATE_DESTINATION_COUNTRIES, values)),
  },
];

export const carrierContractDatesSection = {
  title: 'titles:payment-date',
  fields: contractGeneralFields,
};

const carrierContractVendorField = {
  ...compensationCommonFieldStyles,
  zIndex: 10,
  type: 'select',
  loc: 'titles:vendor',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  options: 'carrierIntegrationOptions',
  fieldName: GC.FIELD_INTEGRATION_CONFIG_GUID,
};

export const carrierEditCarrierContractSection = {
  title: 'titles:general',
  fields: [
    ...R.insert(3, carrierContractVendorField, contractEditGeneralFields),
    {
      ...compensationCommonFieldStyles,
      width: 214,
      type: 'toggle',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      loc: 'titles:get-fuel-from-order',
      fieldName: GC.FIELD_GET_FUEL_FROM_ORDER,
    },
    {
      ...compensationCommonFieldStyles,
      width: 214,
      type: 'toggle',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      loc: 'titles:add-additional-charges-from-order',
      fieldName: GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER,
    },
  ],
};

export const carrierEditContractUpliftSection = {
  title: 'titles:uplift',
  fields: contractUpliftFields,
};

export const carrierEditCustomerContractSection = {
  title: 'titles:general',
  fields: [
    ...contractEditGeneralFields,
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      loc: 'titles:scope',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_SCOPE,
      options: [
        { label: '', value: '' },
        { label: G.getWindowLocale('titles:all-carriers', 'All Carriers'), value: 'ALL_CARRIERS' },
        { label: G.getWindowLocale('titles:specific-carriers', 'Specific Carriers'), value: 'SPECIFIC_CARRIERS' },
        { label: G.getWindowLocale('titles:specific-contracts', 'Specific Contracts'), value: 'SPECIFIC_CONTRACTS' },
      ],
    },
    {
      ...compensationCommonFieldStyles,
      zIndex: 14,
      shouldHandle: true,
      type: 'multiselect',
      alignItems: 'center',
      flexDirection: 'row',
      loc: 'titles:carriers',
      options: 'carrierOptions',
      justifyContent: 'space-between',
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_GUIDS,
      fieldGroupDisplay: (_: any, values: Object) => {
        const scope = R.pathOr(null, [GC.FIELD_CUSTOMER_CONTRACT_SCOPE], values);

        if (G.notEquals(scope, 'SPECIFIC_CARRIERS')) return 'none';
      },
    },
    {
      ...compensationCommonFieldStyles,
      shouldHandle: true,
      type: 'multiselect',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      loc: 'titles:carrier-contracts',
      options: 'carrierContractOptions',
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_CONTRACT_GUIDS,
      fieldGroupDisplay: (_: any, values: Object) => {
        const scope = R.pathOr(null, [GC.FIELD_CUSTOMER_CONTRACT_SCOPE], values);

        if (G.notEquals(scope, 'SPECIFIC_CONTRACTS')) return 'none';
      },
    },
    {
      ...compensationCommonFieldStyles,
      width: 214,
      type: 'toggle',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      loc: 'titles:use-carrier-contract-awarded-lines',
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_USE_AWARDED_LINES,
    },
    {
      ...compensationCommonFieldStyles,
      width: 214,
      type: 'toggle',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      loc: 'titles:use-carrier-price-for-trip-rate',
      fieldName: GC.FIELD_CUSTOMER_USE_CARRIER_PRICE_FOR_TRIP_RATE,
    },
    {
      ...compensationCommonFieldStyles,
      width: 214,
      type: 'toggle',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      loc: 'titles:allow-interline-carriers',
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_ALLOW_INTERLINE_CARRIERS,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      loc: 'titles:assessorial-exclusion',
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_ASSESSORIAL_EXCLUSION,
      options: [
        { label: '', value: '' },
        { label: G.getWindowLocale('titles:none', 'None'), value: 'NONE' },
        { label: G.getWindowLocale('titles:all', 'All'), value: 'ALL' },
        { label: G.getWindowLocale('titles:selected', 'Selected'), value: 'SELECTED' },
      ],
    },
    {
      ...compensationCommonFieldStyles,
      zIndex: 11,
      shouldHandle: true,
      type: 'multiselect',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      options: 'accessorialConfigOptions',
      loc: 'titles:excluded-assessorials',
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_EXCLUDED_ASSESSORIALS,
      fieldGroupDisplay: (_: any, values: Object) => {
        const assessorialExclusion = R.pathOr(null, [GC.FIELD_CUSTOMER_CONTRACT_CARRIER_ASSESSORIAL_EXCLUSION], values);
        if (G.notEquals(assessorialExclusion, 'SELECTED')) return 'none';
      },
    },
  ],
};

const CompensationOriginSection = {
  fields: compensationOriginFields,
  title: 'titles:origin-details',
};

const CompensationDestinationSection = {
  fields: compensationDestinationFields,
  title: 'titles:destination-details',
};

export const carrierRateLocationFields = [
  CompensationOriginSection,
  CompensationDestinationSection,
];

export const setInitialLocationFormikValues = (
  defaultValues: any,
  initialValues: any,
  searchedValues: any,
) => {
  if (G.isNotNilAndNotEmpty(searchedValues)) {
    if (G.isNotNilAndNotEmpty(initialValues)) {
      return R.mergeRight(
        R.mergeRight(defaultValues, initialValues),
        searchedValues,
      );
    }

    return R.mergeRight(defaultValues, searchedValues);
  }

  if (G.isNotNilAndNotEmpty(initialValues)) {
    return R.mergeRight(defaultValues, initialValues);
  }

  return defaultValues;
};

export const awardedLine = {
  fields: [
    {
      ...compensationCommonFieldStyles,
      type: 'text',
      isRequired: true,
      loc: 'titles:name',
      fieldName: GC.FIELD_CARRIER_RATE_NAME,
    },
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      fieldName: GC.FIELD_CHARGE_MIN_RATE,
      loc: 'titles:min-rate',
    },
    {
      ...compensationCommonFieldStyles,
      zIndex: 11,
      type: 'calendar',
      isRequired: true,
      isClearable: true,
      timeSelection: false,
      loc: 'titles:effective-date',
      fieldName: GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE,
      maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
    },
    {
      ...compensationCommonFieldStyles,
      zIndex: 11,
      type: 'calendar',
      isRequired: true,
      isClearable: true,
      timeSelection: false,
      minDate: G.getCurrentDate(),
      loc: 'titles:expiration-date',
      fieldName: GC.FIELD_CARRIER_RATE_EXPIRATION_DATE,
      maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
    },
  ],
};

const distanceFields = {
  title: 'titles:distance',
  fields: [
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:min-distance',
      fieldName: LC.FIELD_MIN_DISTANCE,
    },
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:max-distance',
      fieldName: LC.FIELD_MAX_DISTANCE,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      loc: 'titles:distance-uom',
      fieldName: LC.FIELD_DISTANCE_UOM,
      options: G.addEmptyOptionToDropDown(DEFAULT_DISTANCE_UOM_OPTIONS),
    },
  ],
};

const weightFields = {
  title: 'titles:weight',
  fields: [
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:min-weight',
      fieldName: LC.FIELD_MIN_WEIGHT,
    },
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:max-weight',
      fieldName: LC.FIELD_MAX_WEIGHT,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      loc: 'titles:weight-uom',
      fieldName: LC.FIELD_WEIGHT_UOM,
      options: DEFAULT_AWARDED_LINE_WEIGHT_UOM_OPTIONS,
    },
  ],
};

const quantityFields = {
  fields: [
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:min-quantity',
      fieldName: LC.FIELD_MIN_QUANTITY,
    },
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:max-quantity',
      fieldName: LC.FIELD_MAX_QUANTITY,
    },
  ],
  title: 'title:quantity',
};

const volumeFields = {
  fields: [
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:max-volume',
      fieldName: LC.FIELD_MAX_VOLUME,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      loc: 'titles:volume-uom',
      fieldName: LC.FIELD_VOLUME_UOM,
      options: DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
    },
  ],
  title: 'title:volume',
};

export const awardedLineParams = [
  distanceFields,
  weightFields,
  quantityFields,
  volumeFields,
];

export const awardedDiscountSettings = {
  title: 'titles:discount',
  fields: [
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      fieldName: LC.FIELD_CARRIER_CONTRACT_DISCOUNT_TYPE,
      loc: 'titles:discount-type',
      options: [
        { label: '', value: '' },
        { label: 'Flat', value: GC.DISCOUNT_TYPE_FLAT },
        { label: 'Variable', value: GC.DISCOUNT_TYPE_VARIABLE },
      ],
    },
    {
      ...compensationCommonFieldStyles,
      step: '0.01',
      type: 'number',
      loc: 'titles:discount-rate',
      fieldGroupDisplay: displayDiscountFlat,
      fieldName: LC.FIELD_CARRIER_CONTRACT_RATE,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      loc: 'titles:discount-rate-unit',
      fieldGroupDisplay: displayDiscountFlat,
      options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
      fieldName: LC.FIELD_CARRIER_CONTRACT_RATE_UNIT,
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      loc: 'titles:variable-type',
      fieldName: LC.FIELD_CARRIER_CONTRACT_VARIABLE_TYPE,
      options: [
        { label: '', value: '' },
        { label: 'Weight', value: GC.CHARGE_RATE_TYPE_WEIGHT },
        { label: 'Distance', value: GC.CHARGE_RATE_TYPE_DISTANCE },
        { label: 'Stop', value: GC.CHARGE_RATE_TYPE_STOP },
        { label: 'Quantity', value: GC.CHARGE_RATE_TYPE_QUANTITY },
        { label: 'Rate Total', value: GC.CHARGE_RATE_TYPE_RATE_TOTAL },
      ],
      fieldGroupDisplay: (_: any, values: Object) => {
        const rateType = R.pathOr(null, [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

        if (R.or(
          G.isNilOrEmpty(rateType),
          G.notEquals(rateType, GC.DISCOUNT_TYPE_VARIABLE),
        )) return 'none';
      },
    },
    {
      ...compensationCommonFieldStyles,
      type: 'select',
      loc: 'titles:variable-unit',
      fieldName: LC.FIELD_CARRIER_CONTRACT_VARIABLE_UNIT,
      options: (values: Object) => {
        const variableType = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_VARIABLE_TYPE], values);

        return variableUnitOptions[variableType];
      },
      fieldGroupDisplay: (_: any, values: Object) => {
        const rateType = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

        const hasUnit = ['', GC.CHARGE_RATE_TYPE_QUANTITY, GC.CHARGE_RATE_TYPE_STOP, GC.CHARGE_RATE_TYPE_RATE_TOTAL]
          .includes(R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_VARIABLE_TYPE], values));

        if (R.or(hasUnit, G.notEquals(rateType, GC.DISCOUNT_TYPE_VARIABLE))) return 'none';
      },
    },
  ],
};

// carrier-portal-login
const carrierPortalLoginDefaultStyles = {
  width: 360,
  afterTop: 15,
  afterRight: 15,
  errorTop: '110%',
  errorLeft: '5px',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  inputsFlexDirection: 'column',
};

export const carrierPortalLoginValidationSchemaObject = {
  [GC.FIELD_USER_LOGIN_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_USER_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_USER_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getFieldIsNotValidEmailTxt()),
  [GC.FIELD_ROLE_ROLES_GUIDS]: Yup.array().nullable(true),
};

export const defaultCarrierPortalLoginFields = {
  [GC.FIELD_USER_EMAIL]: null,
  [GC.FIELD_USER_LOGIN_ID]: null,
  [GC.FIELD_USER_PASSWORD]: null,
  [GC.FIELD_ROLE_ROLES_GUIDS]: null,
};

export const carrierPortalLoginFields = [
  {
    ...carrierPortalLoginDefaultStyles,
    type: 'text',
    autoComplete: 'off',
    loc: 'titles:user-id',
    fieldName: GC.FIELD_USER_LOGIN_ID,
  },
  {
    ...carrierPortalLoginDefaultStyles,
    type: 'password',
    autoComplete: 'off',
    validate: G.required,
    loc: 'titles:password',
    fieldName: GC.FIELD_USER_PASSWORD,
  },
  {
    ...carrierPortalLoginDefaultStyles,
    type: 'text',
    loc: 'titles:email',
    fieldName: GC.FIELD_USER_EMAIL,
  },
  {
    ...carrierPortalLoginDefaultStyles,
    type: 'multiselect',
    loc: 'titles:roles',
    options: GC.FIELD_ROLE_ROLES_GUIDS,
    fieldName: GC.FIELD_ROLE_ROLES_GUIDS,
  },
];

// additional contacts
const additionalContactStyles = {
  mb: 25,
  width: 180,
};

export const carrierAdditionalContactValidationSchemaObject = Yup.object().shape({
  [GC.FIELD_FAX]: Yup.string()
    .nullable(true)
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
  [GC.FIELD_PHONE]: Yup.string()
    .nullable(true)
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
  [GC.FIELD_TITLE]: Yup.string()
    .nullable(true)
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .email(G.getShouldBeEmailLocaleTxt())
    .max(255, G.getShouldBeFromToCharLocaleTxt(0, 255)),
  [GC.FIELD_CONTACT_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONTACT_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PHONE_EXTENSION]: Yup.string()
    .nullable(true)
    .max(5, G.getShouldBeFromToCharLocaleTxt(0, 5)),
});

export const defaultCarrierAdditionalContactFields = {
  [GC.FIELD_FAX]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_TITLE]: null,
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_CONTACT_NAME]: null,
  [GC.FIELD_CONTACT_TYPE]: null,
  [GC.FIELD_PHONE_EXTENSION]: null,
};

export const carrierAdditionalContactFields = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CONTACT_NAME,
    inputWrapperStyles: additionalContactStyles,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CONTACT_TYPE,
    inputWrapperStyles: additionalContactStyles,
    label: ['titles:contact-type', 'Contact Type'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_TITLE,
    label: ['titles:position', 'Position'],
    inputWrapperStyles: additionalContactStyles,
  },
  {
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: additionalContactStyles,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    inputWrapperStyles: additionalContactStyles,
    label: ['titles:phone-extension', 'Phone Extension'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    label: ['titles:email', 'Email'],
    inputWrapperStyles: additionalContactStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax', 'Fax'],
    inputWrapperStyles: additionalContactStyles,
  },
];

export const defaultTerminalFields = {
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_ADDRESS]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_LONGITUDE]: '',
  [GC.FIELD_LOCATION_TYPE]: '',
  [GC.FIELD_LOCATION_NAME]: '',
  [GC.FIELD_CARRIER_TERMINAL_NAME]: '',
  [GC.FIELD_CARRIER_TERMINAL_ACTIVE]: true,
  [GC.FIELD_CARRIER_TERMINAL_PRIMARY]: false,
};

const inputTerminalWrapperStyles = {
  mb: 25,
  width: 230,
};

export const terminalFields = [
  {
    type: 'toggle',
    isRequired: true,
    label: ['titles:primary', 'Primary'],
    fieldName: GC.FIELD_CARRIER_TERMINAL_PRIMARY,
    inputWrapperStyles: {
      ...inputTerminalWrapperStyles,
      width: 90,
    },
  },
  {
    type: 'toggle',
    isRequired: true,
    label: ['titles:active', 'Active'],
    fieldName: GC.FIELD_CARRIER_TERMINAL_ACTIVE,
    inputWrapperStyles: {
      ...inputTerminalWrapperStyles,
      width: 90,
    },
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_CARRIER_TERMINAL_NAME,
    inputWrapperStyles: inputTerminalWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_LOCATION_NAME,
    inputWrapperStyles: inputTerminalWrapperStyles,
    label: ['titles:location-name', 'Location Name'],
  },
  {
    type: 'select',
    isRequired: true,
    options: 'locationType',
    fieldName: GC.FIELD_LOCATION_TYPE,
    inputWrapperStyles: inputTerminalWrapperStyles,
    label: ['titles:location-type', 'Location Type'],
  },
  {
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
    addressFieldName: GC.FIELD_ADDRESS,
    label: ['titles:address', 'Address'],
    inputWrapperStyles: inputTerminalWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CITY,
    label: ['titles:city', 'City'],
    inputWrapperStyles: inputTerminalWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
    inputWrapperStyles: inputTerminalWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
    inputWrapperStyles: inputTerminalWrapperStyles,
  },
  {
    type: 'text',
    disabled: true,
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
    inputWrapperStyles: inputTerminalWrapperStyles,
  },
  {
    type: 'text',
    disabled: true,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
    inputWrapperStyles: inputTerminalWrapperStyles,
  },
];

export const getTerminalFieldsValidationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_TERMINAL_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  [GC.FIELD_LOCATION_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(255, G.getShouldBeFromToCharLocaleTxt(1, 255)),
  [GC.FIELD_LOCATION_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeFromToCharLocaleTxt(1, 40)),
  [GC.FIELD_ADDRESS]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(255, G.getShouldBeFromToCharLocaleTxt(1, 255)),
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
});
