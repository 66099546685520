import * as R from 'ramda';
import {
  put,
  call,
  race,
  take,
  select,
  takeLatest } from 'redux-saga/effects';
// common
import { initialDataLoadSuccess, initialDataLoadFail } from '../../common/actions';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
  // utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// features intl
import * as A from './actions';
import { makeCurrentIntObj } from './helpers';
import {
  makeSelectIntl,
  makeSelectActiveTab,
  makeSelectCurrentLanguage,
  makeSelectActiveBranchName,
  makeSelectChangedLocaleItems } from './selectors';
///////////////////////////////////////////////////////////////

function* getTranslationByPatternSaga() {
  try {
    const activePattern = yield select(makeSelectActiveTab());
    const branchName = yield select(makeSelectActiveBranchName());
    if (R.equals(activePattern, 'custom')) {
      return yield put(A.getAllCustomizedItemsRequest({
        [GC.FIELD_BRANCH_GUID]: R.path(['value'], branchName),
      }));
    }
    if (activePattern.includes('custom')) {
      const pickPattern = R.last(R.split('/', activePattern));
      return yield put(A.getIntlCustomizedRequest({
        pattern: pickPattern,
        [GC.FIELD_BRANCH_GUID]: R.path(['value'], branchName),
      }));
    }
    yield put(openLoader({ showDimmer: true }));
    const pattern = R.toLower(activePattern);
    const endpoint = endpointsMap.translationByPattern;
    const options = {
      params: {
        pattern: G.ifElse(R.equals(pattern, 'pattern'), '', pattern),
      },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getIntlSuccess(makeCurrentIntObj(R.values(data))));
      yield put(A.setListLanguageFromBack(R.keys(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getTranslationByPatternSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getTranslationByPatternSaga exception');
  }
}

function* getCustomizedItemsByEnterpriseGuidAndByPatternSaga(payload: object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.i18nCustomizedByPattern;
    const options = {
      params: {
        pattern: R.path(['payload', 'pattern'], payload),
        [GC.FIELD_BRANCH_GUID]: R.path(['payload', GC.FIELD_BRANCH_GUID], payload),
      },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const newData = R.groupWith((prev: object, next: object) => R.equals(prev.language, next.language), data);
      yield put(A.getIntlCustomizedSuccess(makeCurrentIntObj(newData)));
    } else {
      yield call(G.handleFailResponse, res, 'getAllCustomizedItemsByEnterpriseGuid fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getAllCustomizedItemsByEnterpriseGuid exception');
  }
}

function* getAllCustomizedItemsByEnterpriseGuid(payload: object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.getAllByEnterpriseGuid;
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: R.path(['payload', GC.FIELD_BRANCH_GUID], payload),
      },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const newData = R.groupWith((prev: object, next: object) => R.equals(prev.language, next.language), data);
      yield put(A.getIntlCustomizedSuccess(makeCurrentIntObj(newData)));
    } else {
      yield call(G.handleFailResponse, res, 'getAllCustomizedItemsByEnterpriseGuid fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getAllCustomizedItemsByEnterpriseGuid exception');
  }
}

function* getMasterBranchSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.mastersBranchList;
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getMastersBranchListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getMasterBranchSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getMasterBranchSaga exception');
  }
}

function* updateTranslationByPatternSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const repeatPayload = yield select(makeSelectActiveTab());
    const keyFieldsToUpdate = yield select(makeSelectChangedLocaleItems());
    const intlSettings = yield select(makeSelectIntl());
    const pathToObj = R.map((item: string) => R.split('.', item), keyFieldsToUpdate);
    const changedObj = pathToObj.map(
      (pathKey: string) => R.path([pathKey[0], pathKey[1]], intlSettings));
    const intl = R.reduce((prev: Object, item: Object) => {
      const prevClone = R.clone(prev);
      if (R.isNil(prevClone[item.language])) {
        prevClone[item.language] = [];
      }
      prevClone[item.language] = R.append(item, prevClone[item.language]);
      return prevClone;
    }, {}, changedObj);
    const res = yield call(sendRequest, 'put', endpointsMap.translation, { data: intl });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(getTranslationByPatternSaga, { payload: repeatPayload });
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateTranslationByPatternSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'updateTranslationByPatternSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* updateCustomTranslationByPatternSaga(payload: object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: R.path(['payload', 'customItems'], payload),
      params: {
        [GC.FIELD_BRANCH_GUID]: R.path(['payload', GC.FIELD_BRANCH_GUID], payload),
      },
    };
    const res = yield call(sendRequest, 'put', endpointsMap.i18nCustomizedSaveAll, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const newData = R.groupWith((prev: object, next: object) => R.equals(prev.language, next.language), data);
      yield put(A.updateIntlSuccess(newData));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateCustomTranslationByPatternSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'updateCustomTranslationByPatternSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}
function* setTranslationByPatternSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const repeatPayload = yield select(makeSelectActiveTab());
    const languageFromBack = yield select(makeSelectCurrentLanguage());
    const intl = R.mergeAll(languageFromBack.map(
      (key: string) => {
        const newKey = {
          itemId: payload.key,
          language: key,
          value: payload[key],
        };
        return R.assoc(key, R.append(newKey, []), {});
      }));
    const res = yield call(sendRequest, 'put', endpointsMap.translation, { data: intl });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(getTranslationByPatternSaga, { payload: repeatPayload });
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'setTranslationByPatternSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'setTranslationByPatternSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* deleteTranslationByPatternSaga({ payload }: Object) {
  try {
    const options = { params: { itemId: payload } };
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'delete', endpointsMap.translationDelete, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteIntlSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'deleteTranslationByPatternSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'deleteTranslationByPatternSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* deleteCustomItemSaga({ payload }: Object) {
  try {
    const options = {
      params: {
        itemId: R.path(['key'], payload),
        [GC.FIELD_BRANCH_GUID]: R.path([GC.FIELD_BRANCH_GUID], payload),
      },
    };
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'delete', endpointsMap.deleteI18nCustomizedItem, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteCustomItemSuccess(R.path(['key'], payload)));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'deleteCustomItemSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'deleteCustomItemSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleVisitIntlPageSaga() {
  while (true) {
    yield put(openLoader({ showDimmer: true }));
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    yield call(getMasterBranchSaga);
    yield call(getTranslationByPatternSaga);
    yield put(closeLoader());
    break;
  }
}

function* intlWatcherSaga() {
  yield takeLatest(A.getIntlRequest, getTranslationByPatternSaga);
  yield takeLatest(A.deleteCustomItemRequest, deleteCustomItemSaga);
  yield takeLatest(GC.VISIT_TRANSLATION_PAGE, handleVisitIntlPageSaga);
  yield takeLatest(A.updateIntlRequest, updateTranslationByPatternSaga);
  yield takeLatest(A.deleteIntlRequest, deleteTranslationByPatternSaga);
  yield takeLatest(A.addNewItemLanguageRequest, setTranslationByPatternSaga);
  yield takeLatest(A.updateCustomIntlRequest, updateCustomTranslationByPatternSaga);
  yield takeLatest(A.getAllCustomizedItemsRequest, getAllCustomizedItemsByEnterpriseGuid);
  yield takeLatest(A.getIntlCustomizedRequest, getCustomizedItemsByEnterpriseGuidAndByPatternSaga);
}

export default intlWatcherSaga;
