import * as GC from '../../constants';
//////////////////////////////////////////////////

export const CARRIER_DOCUMENT_FORM = 'CARRIER_DOCUMENT_FORM';
export const EDIT_GENERAL_DETAILS_FORM = 'EDIT_GENERAL_DETAILS_FORM';
export const ADD_CARRIER_INSURANCE_FORM = 'ADD_CARRIER_INSURANCE_FORM';

export const AWARDED_LINE_FORM = 'AWARDED_LINE_FORM';
export const CARRIER_RATE_FORM = 'CARRIER_RATE_FORM';
export const CARRIER_CONTRACT_PAGE = 'CARRIER_CONTRACT_PAGE';
export const CUSTOMER_CONTRACT_PAGE = 'CUSTOMER_CONTRACT_PAGE';
export const CARRIER_CONTRACT_DISCOUNT_FORM = 'CARRIER_CONTRACT_DISCOUNT_FORM';

export const INSURANCE_FILTER_PREFIX = 'insurances';
export const CERTIFICATE_FILTER_PREFIX = 'certificates';
export const BRANCH_FILTER_PREFIX = GC.BRANCH_LOWERCASE;
export const PRIMARY_TERMINAL_FILTER_PREFIX = 'primaryTerminal';
export const CARRIER_FINANCE_FILTER_PREFIX = 'carrierFinancialDetails';
export const TERMINAL_FINANCE_FILTER_PREFIX = 'terminalFinancialDetails';

export const CARRIER_GROUP_NAME_MAILS = 'mails';
export const CARRIER_GROUP_NAME_UPLIFT = 'uplift';
export const CARRIER_GROUP_NAME_DISCOUNT = 'discount';
export const CARRIER_GROUP_NAME_TERMINAL = 'terminal';
export const CARRIER_GROUP_NAME_DOCUMENTS = 'documents';
export const CARRIER_GROUP_NAME_SAFETY_INFO = 'safetyInfo';
export const CARRIER_GROUP_NAME_CERTIFICATE = 'certificate';
export const CARRIER_GROUP_NAME_CARRIER_RATE = 'carrierRate';
export const CARRIER_GROUP_NAME_ASSESSORIALS = 'assessorials';
export const CARRIER_GROUP_NAME_CONTRACT_BILL_TO = 'contractBillTo';
export const CARRIER_GROUP_NAME_INTEGRATION_INFO = 'integrationInfo';
export const CARRIER_GROUP_NAME_CONTRACT_GENERAL = 'contractGeneral';
export const CARRIER_GROUP_NAME_CARRIER_CONTRACT = 'carrierContract';
export const CARRIER_GROUP_NAME_TERMINAL_CONTACT = 'terminalContact';
export const CARRIER_GROUP_NAME_FINANCE_LOCATION = 'financeLocation';
export const CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE = 'awardedLine';
export const CARRIER_GROUP_NAME_INTEGRATION_AUDIT = 'integrationAudit';
export const CARRIER_GROUP_NAME_CUSTOMERS_CONTRACT = 'customersContract';
export const CARRIER_GROUP_NAME_ASSOCIATED_COMPANY = 'associatedCompany';
export const CARRIER_GROUP_NAME_ADDITIONAL_CONTACTS = 'additionalContacts';
export const CARRIER_GROUP_NAME_CARRIER_PAYMENT_TERM = 'carrierPaymentTerm';
export const CARRIER_GROUP_NAME_CARRIER_PORTAL_LOGIN = 'carrierPortalLogin';
export const CARRIER_GROUP_NAME_SYNCHRONIZATION_INFO = 'synchronizationInfo';
export const CARRIER_CONTRACT_GROUP_NAME_ASSESSORIALS = 'contractAssessorials';
export const CARRIER_GROUP_NAME_INTERNAL_DISPATCH_SETTINGS = 'internalDispatchSettings';
export const CARRIER_CONTRACT_RATE_GROUP_NAME_ASSESSORIALS = 'contractRateAssessorials';
export const CARRIER_GROUP_NAME_LOAD_BOARD_INTEGRATION_INFO = 'loadBoardIntegrationInfo';

export const ACCESSORIALS_FORM = 'ACCESSORIALS_FORM';
export const ORIGIN_CITY_OPTIONS = 'originCityOptions';
export const ORIGIN_ZIP_CODES_OPTIONS = 'originZipCodesOptions';
export const DESTINATION_CITY_OPTIONS = 'destinationCityOptions';
export const DESTINATION_ZIP_CODES_OPTIONS = 'destinationZipCodesOptions';
export const CARRIER_RATE_OPTIONS_PICK = [
  ORIGIN_CITY_OPTIONS,
  ORIGIN_ZIP_CODES_OPTIONS,
  DESTINATION_CITY_OPTIONS,
  DESTINATION_ZIP_CODES_OPTIONS,
];

/* eslint-disable */
export const FIELD_CARRIER_RATE_ORIGIN = 'origin';
export const FIELD_CARRIER_RATE_DESTINATION = 'destination';
export const FIELD_CARRIER_RATE_ORIGIN_ZIP = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_ZIP}`;
export const FIELD_CARRIER_RATE_ORIGIN_CITY = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_CITY}`;
export const FIELD_CARRIER_RATE_ORIGIN_CITIES = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_CITIES}`;
export const FIELD_CARRIER_RATE_ORIGIN_STATES = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_STATES}`;
export const FIELD_CARRIER_RATE_DESTINATION_ZIP = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_ZIP}`;
export const ORIGIN_GEO_FENCING_ZONE = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_GEO_FENCING_ZONE_GUID}`;
export const FIELD_CARRIER_RATE_DESTINATION_CITY = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_CITY}`;
export const FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_ZIP_CODES}`;
export const FIELD_CARRIER_RATE_ORIGIN_COUNTRIES = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_COUNTRIES}`;
export const FIELD_CARRIER_RATE_DESTINATION_CITIES = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_CITIES}`;
export const FIELD_CARRIER_RATE_DESTINATION_STATES = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_STATES}`;
export const FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_TO = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`;
export const DESTINATION_GEO_FENCING_ZONE = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_GEO_FENCING_ZONE_GUID}`;
export const FIELD_CARRIER_RATE_ORIGIN_ZIP_RANGE_FROM = `${FIELD_CARRIER_RATE_ORIGIN}:${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`;
export const FIELD_CARRIER_RATE_DESTINATION_COUNTRIES = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_COUNTRIES}`;
export const FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_ZIP_CODES}`;
export const FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID = `${FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_GEO_FENCING_ZONE_GUID}`;
export const FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_TO = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`;
export const FIELD_CARRIER_RATE_DESTINATION_ZIP_RANGE_FROM = `${FIELD_CARRIER_RATE_DESTINATION}:${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`;
export const FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID = `${FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_GEO_FENCING_ZONE_GUID}`;
/* eslint-disable */

// contract
export const FIELD_VALUE_TO = 'valueTo';
export const FIELD_VALUE_FROM = 'valueFrom';
export const FIELD_DISCOUNT_TYPE = 'discountType';
export const FIELD_VARIABLE_TYPE = 'variableType';
export const FIELD_VARIABLE_UNIT = 'variableUnit';
export const FIELD_VARIABLE_VALUES = 'variableValues';
export const FIELD_CARRIER_CONTRACT_UPLIFT = 'uplift';
export const FIELD_CARRIER_CONTRACT_DISCOUNT = 'discount';
export const FIELD_CARRIER_CONTRACT_UPLIFT_MIN = 'minUplift';
export const FIELD_CARRIER_CONTRACT_UPLIFT_MAX = 'maxUplift';
export const FIELD_CARRIER_CONTRACT_UPLIFT_VALUE = 'upliftValue';
export const FIELD_CARRIER_CONTRACT_UPLIFT_APPLY_TO_ = 'applyTo';
export const FIELD_CARRIER_CONTRACT_UPLIFT_RATE_UNIT = 'rateUnit';

// safer-watch
export const SAFER_WATCH_PROFILE_LINK_ADDRESS = (uSDotNumber: string) =>
  `https://www.saferwatch.com/swCarrierDetailsLink.php?&number=${uSDotNumber}`

export const FIELD_CARRIER_CONTRACT_RATE = `${FIELD_CARRIER_CONTRACT_DISCOUNT}.${GC.FIELD_CHARGE_RATE}`;
export const FIELD_CARRIER_CONTRACT_DISCOUNT_TYPE = `${FIELD_CARRIER_CONTRACT_DISCOUNT}.${FIELD_DISCOUNT_TYPE}`;
export const FIELD_CARRIER_CONTRACT_VARIABLE_TYPE = `${FIELD_CARRIER_CONTRACT_DISCOUNT}.${FIELD_VARIABLE_TYPE}`;
export const FIELD_CARRIER_CONTRACT_VARIABLE_UNIT = `${FIELD_CARRIER_CONTRACT_DISCOUNT}.${FIELD_VARIABLE_UNIT}`;
export const FIELD_CARRIER_CONTRACT_RATE_UNIT = `${FIELD_CARRIER_CONTRACT_DISCOUNT}.${GC.FIELD_CHARGE_RATE_UNIT}`;

export const FIELD_MIN_WEIGHT = 'minWeight';
export const FIELD_MAX_WEIGHT = 'maxWeight';
export const FIELD_WEIGHT_UOM = 'weightUom';
export const FIELD_MAX_VOLUME = 'maxVolume';
export const FIELD_VOLUME_UOM = 'volumeUom';
export const FIELD_MIN_DISTANCE = 'minDistance';
export const FIELD_MAX_DISTANCE = 'maxDistance';
export const FIELD_DISTANCE_UOM = 'distanceUom';
export const FIELD_MIN_QUANTITY = 'minQuantity';
export const FIELD_MAX_QUANTITY = 'maxQuantity';


export const CARRIER_RATE_MAIN_FIELDS = [
  GC.FIELD_CURRENCY,
  GC.FIELD_WEIGHT_UOM,
  GC.FIELD_MIN_WEIGHT,
  GC.FIELD_MAX_WEIGHT,
  GC.FIELD_CHARGE_RATE,
  GC.FIELD_MIN_QUANTITY,
  GC.FIELD_MAX_QUANTITY,
  GC.FIELD_TRANSIT_DAYS,
  GC.FIELD_PACKAGE_TYPE,
  GC.FIELD_CARRIER_ACTIVE,
  GC.FIELD_MIN_TEMPERATURE,
  GC.FIELD_MAX_TEMPERATURE,
  GC.FIELD_TEMPERATURE_UOM,
  GC.FIELD_CHARGE_MIN_RATE,
  GC.FIELD_CHARGE_MAX_RATE,
  GC.FIELD_CHARGE_RATE_TYPE,
  GC.FIELD_CHARGE_RATE_UNIT,
  GC.FIELD_CARRIER_RATE_GUID,
  GC.FIELD_CARRIER_RATE_NAME,
  GC.FIELD_CARRIER_EQUIPMENTS,
  GC.FIELD_CARRIER_RATE_VERSION,
  GC.FIELD_CARRIER_SERVICE_TYPES,
  GC.FIELD_CARRIER_RATE_DISTANCE_OUM,
  GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE,
  GC.FIELD_CARRIER_TRANSPORTATION_MODES,
  GC.FIELD_CARRIER_RATE_EXPIRATION_DATE,
  GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID,
  GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_TO,
  GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM,
  GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID,
];

export const AWARDED_LINE_MAIN_FIELDS = [
  GC.FIELD_NAME,
  FIELD_MIN_WEIGHT,
  FIELD_MAX_WEIGHT,
  FIELD_WEIGHT_UOM,
  FIELD_MAX_VOLUME,
  FIELD_VOLUME_UOM,
  FIELD_MIN_DISTANCE,
  FIELD_MAX_DISTANCE,
  FIELD_DISTANCE_UOM,
  FIELD_MIN_QUANTITY,
  FIELD_MAX_QUANTITY,
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
  GC.FIELD_CHARGE_MIN_RATE,
  FIELD_CARRIER_CONTRACT_DISCOUNT,
  GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE,
  GC.FIELD_CARRIER_RATE_EXPIRATION_DATE,
];

export const CARRIER_RATE_LOCATIONS_FIELDS = [
  GC.FIELD_CARRIER_RATE_CITIES,
  GC.FIELD_CARRIER_RATE_STATES,
  GC.FIELD_CARRIER_RATE_COUNTRIES,
  GC.FIELD_CARRIER_RATE_ZIP_CODES,
  GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO,
  GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM,
  GC.FIELD_CARRIER_RATE_GEO_FENCING_ZONE_GUID,
];

export const CARRIER_RATE_TYPE_CONTAIN = [
  GC.CHARGE_RATE_TYPE_TIME,
  GC.CHARGE_RATE_TYPE_WEIGHT,
  GC.CHARGE_RATE_TYPE_VOLUME,
  GC.CHARGE_RATE_TYPE_QUANTITY,
  GC.CHARGE_RATE_TYPE_DISTANCE,
  GC.CHARGE_RATE_TYPE_CUSTOMER_RATE,
  GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL,
];

export const CARRIER_RATE_MAIN_FIELDS_OMIT = [
  FIELD_CARRIER_RATE_ORIGIN_CITY,
  FIELD_CARRIER_RATE_DESTINATION_CITY,
  ...CARRIER_RATE_MAIN_FIELDS,
];

export const AWARDED_LINE_MAIN_FIELDS_OMIT = [
  FIELD_CARRIER_RATE_ORIGIN_CITY,
  FIELD_CARRIER_RATE_DESTINATION_CITY,
  ...AWARDED_LINE_MAIN_FIELDS,
];
