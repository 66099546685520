import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import RequestStatusModal from '../../../components/request-status-modal';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { CustomButton, Flex } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const requests = {
  [GC.STATUS_SUCCESS]: {
    errors: [],
    title: 'Request success',
    status: [GC.STATUS_SUCCESS],
    requestStatusInfo: 'Request status success',
  },
  [GC.STATUS_FAILED]: {
    title: 'Request failed',
    status: [GC.STATUS_FAILED],
    requestStatusInfo: 'Request status failed',
    errors: [{ key: 1, messageArray: ['test error'] }],
  },
  [GC.STATUS_PARTIAL_SUCCESS]: {
    errors: [],
    title: 'Request partial success',
    status: [GC.STATUS_PARTIAL_SUCCESS],
    requestStatusInfo: 'Request status partial success',
  },
};

const enhance = compose(
  withHandlers({
    handleOpen: (props: Object) => (request: Object) => {
      const { openModal, closeModal } = props;

      const { title, status, errors, requestStatusInfo } = request;

      const component = (
        <RequestStatusModal
          title={title}
          status={status}
          errors={errors}
          requestStatusInfo={requestStatusInfo}
        />
      );

      const modal = {
        component,
        p: '0px 0px 62px 0px',
        options: {
          width: 400,
          movable: false,
          controlButtons: [
            {
              type: 'button',
              name: 'submit',
              action: () => closeModal(),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const RequestStatusModalComponent = (props: Object) => {
  const { handleOpen } = props;

  const description = '<RequestStatusModal /> component for rendering information about a request status in a modal window.';

  const usage = (
    <ul>
      <li>Parameter `<i>title</i>` is a title text for the modal.</li>
      <li>Parameter `<i>status</i>` is the status of the request.</li>
      <li>Parameter `<i>errors</i>` is an array of objects containing error details.</li>
      <li>Parameter `<i>requestStatusInfo</i>` is an information related to the request status.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='RequestStatusModal'
      description={description}
      path='src/components/request-status-modal'
    >
      <Flex
        gap={10}
        alignItems='start'
        flexDirection='column'
      >
        <CustomButton
          bgColor={G.getTheme('colors.light.blue')}
          onClick={() => handleOpen(requests[GC.STATUS_SUCCESS])}
        >
          Open Request Status Modal with status success
        </CustomButton>
        <CustomButton
          bgColor={G.getTheme('colors.light.mainRed')}
          onClick={() => handleOpen(requests[GC.STATUS_FAILED])}
        >
          Open Request Status Modal with status failed
        </CustomButton>
        <CustomButton
          bgColor={G.getTheme('colors.dark.yellow')}
          onClick={() => handleOpen(requests[GC.STATUS_PARTIAL_SUCCESS])}
        >
          Open Request Status Modal with status partial success
        </CustomButton>
      </Flex>
    </ComponentStoryWrapper >
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(RequestStatusModalComponent));

