import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
// forms
import { FieldsetComponent } from '../../../../forms';
// ui
import { Box } from '../../../../ui';
// feature fleet/driver
import { deductionFieldSettings } from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => initialValues,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const DeductionSettingsForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        justifyContent='space-between'
        fields={deductionFieldSettings} />
      <FormFooter
        closeModal={props.closeModal}
        boxStyles={{ p: '15px 0 10px 0', justifyContent: 'space-around' }} />
    </form>
  </Box>
);

export default enhance(DeductionSettingsForm);
