import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet/driver
import { DRIVER_DAILY_HOURS } from '../constants';
import DriverDailyHoursSection from './driver-daily-hours-section';
import { validationSchemaDriverDailyHoursObject } from '../settings/fields-settings';
//////////////////////////////////////////////////

const mapper = (values: Object) => {
  const { onDutyHours, drivingHours, onDutyMinutes, drivingMinutes } = values;

  const fullOnDutyMinutes = R.add(
    R.or(onDutyMinutes, 0),
    R.multiply(R.or(onDutyHours, 0), 60),
  );

  const fullDrivingMinutes = R.add(
    R.or(drivingMinutes, 0),
    R.multiply(R.or(drivingHours, 0), 60),
  );

  return {
    ...values,
    [GC.FIELD_ON_DUTY_MINUTES]: fullOnDutyMinutes,
    [GC.FIELD_DRIVING_MINUTES]: fullDrivingMinutes,
  };
};

const mapDriverDailyHours = (values: Array) => R.map(
  (item: Object) => mapper(item),
  values,
);

const setInitialValues = (
  initialValues: any,
  isEditMode: any,
) => {
  if (G.isNilOrFalse(isEditMode)) return { [DRIVER_DAILY_HOURS]: R.of(Array, initialValues) };

  const { hours: onDutyHours, minutes: onDutyMinutes } = G.convertMinutesToHoursAndMinutes(initialValues.onDutyMinutes);
  const { hours: drivingHours, minutes: drivingMinutes } = G.convertMinutesToHoursAndMinutes(initialValues.drivingMinutes);

  const newValues = {
    ...initialValues,
    [GC.FIELD_ON_DUTY_HOURS]: onDutyHours,
    [GC.FIELD_DRIVING_HOURS]: drivingHours,
    [GC.FIELD_ON_DUTY_MINUTES]: onDutyMinutes,
    [GC.FIELD_DRIVING_MINUTES]: drivingMinutes,
  };

  return { [DRIVER_DAILY_HOURS]: R.of(Array, newValues) };
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ isEditMode, initialValues }: Object) => setInitialValues(initialValues, isEditMode),
    validationSchema: Yup.object().shape(validationSchemaDriverDailyHoursObject),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(
      mapDriverDailyHours(R.prop(DRIVER_DAILY_HOURS, values)),
    ),
  }),
  pure,
);

const AddDriverHoursForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <DriverDailyHoursSection {...props} />
    <FormFooter boxStyles={{ p: 15 }} closeModal={props.closeModal} />
  </form>
);

const enhanceUpdate = compose(
  withProps({ isEditMode: true }),
  enhance,
);

export const UpdateDriverHoursForm = enhanceUpdate(AddDriverHoursForm);

export default enhance(AddDriverHoursForm);
