import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../../../components/text';
import EditableBox from '../../../components/editable-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withHoveredZIndex } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  RelativeFlex,
} from '../../../ui';
// features drivers-card
import { makeSelectZoom } from '../selectors';
import { getCardWidth, getCardLeftPosition } from './card';
import { getMainCardWidth, getCardDayMultiplier } from '../helpers';
import { withUnavailablePeriod } from '../hocs/with-unavailable-period';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blackColor = G.getTheme('colors.black');
const white07Color = G.getTheme('colors.white07');
const subtextColor = G.getTheme('colors.#7D828C');
const darkGreyColor = G.getTheme('colors.darkGrey');
const darkGreyColorWithOpacity = G.getTheme('colors.#E0E0E0');

const getHeaderText = (reason: Object) => {
  const value = G.getDisplayedValueFromObject(reason);

  const title = G.getWindowLocale('titles:unavailable', 'Unavailable');

  if (G.isNilOrEmpty(value)) return title;

  return `${title}: ${value}`;
};

const getSubheaderText = (item: Object) => {
  const { endDate, location, startDate } = item;

  const subheaderText = G.concatLocationFields(location);

  // TODO: with state and country mapping
  const subheaderTitle = G.createStringFromArray([subheaderText, startDate, endDate], ' - ');

  return { subheaderText, subheaderTitle };
};

const UnavailablePeriod = (props: Object) => {
  const {
    zoom,
    width,
    period,
    cardIndex,
    cardHeight,
    filtersStore,
    hoveredZIndex,
    show24HoursView,
    handleZIndexOnHover,
    handleZIndexOnUnHover,
    patchUpdateUnavailablePeriod,
    handleUpdateUnavailablePeriod,
    handleRemoveUnavailablePeriod,
  } = props;

  const { note, reason, endDate, startDate } = period;

  const mainCardWidth = getMainCardWidth(show24HoursView, zoom);
  const cardDayMultiplier = getCardDayMultiplier(show24HoursView, zoom);

  const widthToUse = getCardWidth(
    width,
    startDate,
    endDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  );

  if (G.isNilOrEmpty(widthToUse)) return null;

  const zIndex = R.subtract(700, cardIndex);

  const left = getCardLeftPosition(
    true,
    filtersStore,
    startDate,
    mainCardWidth,
    cardDayMultiplier,
  );

  const { subheaderText, subheaderTitle } = getSubheaderText(period);

  const headerText = getHeaderText(reason);

  const textFontSize = G.ifElse(R.lt(widthToUse, 300), 9, 12);

  const changeNoteCallback = (note: string) => {
    patchUpdateUnavailablePeriod(R.assoc(GC.FIELD_NOTE, note, period));
  };

  return (
    <Flex
      left={left}
      alignItems='start'
      color={whiteColor}
      position='absolute'
      height={cardHeight}
      flexDirection='column'
      justifyContent='center'
      width={R.or(widthToUse, 'auto')}
      zIndex={R.or(hoveredZIndex, zIndex)}
      onMouseEnter={() => handleZIndexOnHover()}
      onMouseLeave={() => handleZIndexOnUnHover()}
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
    >
      <Box width='100%' height='100%' fontSize={textFontSize} justifyContent='space-between'>
        <RelativeFlex
          px='5px'
          height={24}
          width='100%'
          borderRadius='2px'
          bg={darkGreyColor}
          borderBottomLeftRadius='0px'
          borderBottomRightRadius='0px'
          justifyContent='space-between'
        >
          <AbsoluteBox
            top='-7px'
            right='25px'
            width='35px'
            height='7px'
            borderRadius='2px'
            bg={darkGreyColor}
          />
          <Box color={whiteColor}>
            <TextComponent
              display='block'
              title={headerText}
              withEllipsis={true}
              maxWidth={R.subtract(widthToUse, 55)}
              fontSize={G.ifElse(R.lt(widthToUse, 300), 11, 12)}
            >
              {headerText}
            </TextComponent>
          </Box>
          <Flex>
            <Box
              pr='5px'
              cursor='pointer'
              onClick={() => handleRemoveUnavailablePeriod(period)}
            >
              {I.trash(white07Color)}
            </Box>
            <Box
              cursor='pointer'
              onClick={() => handleUpdateUnavailablePeriod(
                R.assoc('reason', G.getDropdownOptionGuidFromObject(reason), period),
              )}
            >
              {I.pencil(white07Color)}
            </Box>
          </Flex>
        </RelativeFlex>
        <Flex
          p='5px'
          alignItems='start'
          color={blackColor}
          borderRadius='3px'
          flexDirection='column'
          bg={darkGreyColorWithOpacity}
          height={R.subtract(cardHeight, 24)}
        >
          <TextComponent
            mb='5px'
            fontSize={12}
            display='block'
            maxWidth='100%'
            withEllipsis={true}
            color={subtextColor}
            title={subheaderTitle}
          >
            {subheaderText}
          </TextComponent>
          {
            G.isNotNilAndNotEmpty(note) &&
            <Box
              fontSize={12}
              color={subtextColor}
            >
              {G.getWindowLocale('titles:notes', 'Notes')}:
            </Box>
          }
          <EditableBox
            value={note}
            bgColor={darkGreyColorWithOpacity}
            onBlurCallback={changeNoteCallback}
            wrapperStyles={{
              pt: '5px',
              width: '100%',
              height: R.subtract(cardHeight, 70),
              color: G.getTheme('colors.dark.mainDark'),
            }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  zoom: makeSelectZoom(state),
});

export default connect(mapStateToProps, {})(
  withHoveredZIndex({ zIndex: 1501 })(withUnavailablePeriod(UnavailablePeriod)),
);
