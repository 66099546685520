import * as R from 'ramda';
import React, { useState } from 'react';
// components
import { Table } from '../../../../components/table';
import { FormFooter2 } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex } from '../../../../ui';
//////////////////////////////////////////////////

const rowHeight = 30;
const tableMaxHeight = 'calc(100vh - 300px)';

const report = {
  fields: [
    { name: `${GC.SYSTEM_OBJECT_TRUCK}.${GC.FIELD_UNIT_ID}`, sequence: 1 },
    { name: GC.FIELD_LOG_DATE, sequence: 2 },
    { name: GC.FIELD_STORED_TOTAL_DISTANCE, sequence: 3 },
    { name: GC.FIELD_CREATED_BY, sequence: 4 },
    { name: GC.FIELD_CREATED_DATE, sequence: 5 },
  ],
};

const tableSettings = {
  allowEditBtn: false,
  allowSelectAll: true,
  checkBoxCellWidth: 40,
  allowSelectItems: true,
  maxHeight: tableMaxHeight,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

const columnSettings = {
  [`${GC.SYSTEM_OBJECT_TRUCK}.${GC.FIELD_UNIT_ID}`]: {
    width: 150,
    getDataByPath: true,
    name: 'titles:truck',
  },
  [GC.FIELD_LOG_DATE]: {
    width: 150,
    name: 'titles:log-date',
  },
  [GC.FIELD_STORED_TOTAL_DISTANCE]: {
    width: 150,
    type: GC.FIELD_DISTANCE,
    name: 'titles:total-distance',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
};

const DailyLogTable = (props: Object) => {
  const { itemList, handleSelectItem } = props;

  const data = {
    report,
    itemList,
    tableSettings,
    columnSettings,
    hasSelectable: false,
    totalCount: R.length(itemList),
    onOptionClick: handleSelectItem,
    allChecked: G.isAllChecked(itemList),
  };

  return <Table {...data} />;
};

const DriverDailyLogList = (props: Object) => {
  const { data, dailyLogCreateFromTruckLog } = props;

  const [submitting, setSubmitting] = useState(false);
  const [itemList, setItemList] = useState(() => R.map(R.assoc(GC.FIELD_SELECTED, false), data));

  const handleSelectItem = (data: string) => {
    if (R.equals(data, 'all')) {
      const value = R.not(R.all(({ selected }: Object) => selected, itemList));

      return setItemList(R.map((item: Object) => R.assoc(GC.FIELD_SELECTED, value, item), itemList));
    }

    return setItemList(R.map(
      (item: Object) => {
        const { guid, selected } = item;

        if (R.equals(data, guid)) {
          return R.assoc(GC.FIELD_SELECTED, R.not(selected), item);
        }

        return item;
      },
      itemList,
    ));
  };

  const handleSubmitDailyLog = () => {
    const selectedList = G.getSelectedItemGuids(itemList);

    if (R.propEq(0, GC.FIELD_LENGTH, selectedList)) {
      return G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
      );
    }

    setSubmitting(true);

    dailyLogCreateFromTruckLog({ selectedList, setSubmitting });
  };

  return (
    <Flex flexDirection='column'>
      <Box maxWidth='95vw'><DailyLogTable data={data} itemList={itemList} handleSelectItem={handleSelectItem} /></Box>
      <FormFooter2 boxStyles={{ pt: 15 }} submitDisabled={submitting} submitAction={handleSubmitDailyLog} />
    </Flex>
  );
};

export default DriverDailyLogList;
