import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, change, reduxForm } from 'redux-form';
import {
  pure,
  compose,
  withState,
  withHandlers,
  withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as I from '../../../svgs';
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { renderFormGroup } from '../../../forms';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  IconWrapper } from '../../../ui';
// features carrier portal
import { Button } from '../ui';
import { CARRIER_DETAIL_PAGE_DOC_FORM } from '../constants';
import { addCarrierDoc, setCarrierDocRequest } from '../actions';
import { getStopOptions, formatDocTypeOptions } from '../helpers';
import { documentFieldsCarrier } from '../settings/fields-settings';
import { makeSelectCarrierDetailPageFormDocForm } from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withState('isDisabledDocumentNameField', 'setIsDisabledDocumentNameField', true),
  withState('initialValues', 'setInitialValues', () => R.or(
    {[GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE'},
    {},
  )),
  reduxForm({
    form: CARRIER_DETAIL_PAGE_DOC_FORM,
    change,
  }),
  withState('file', 'setFile', null),
  withState('isSettedUpStatusLocation', 'setStatusLocation', true),
  withHandlers({
    handleFormSubmit: (props: Object) => () => {
      const { file, closeModal, docCarrierForm, setCarrierDocRequest } = props;

      let documentType = G.ifElse(
        R.and(
          R.is(String, R.pathOr('', ['values', 'documentType'], docCarrierForm)),
          R.isEmpty(R.pathOr('', ['values', 'documentType'], docCarrierForm)),
        ),
        null,
        R.pathOr('', ['values', 'documentType'], docCarrierForm),
      );

      if (R.is(String, documentType)) {
        const options = R.path(['detailPageInfo', 'availableDocumentTypes'], props);
        documentType = G.transformGuidToRequest(R.pathOr('', ['values', 'documentType'], docCarrierForm), options);
      }

      const primaryObjectGuid = R.path(['detailPageInfo', 'guid'], props);
      const newValue = R.pick(
        GC.GROUPED_FIELDS.ADD_DOCUMENT_PICK_VALUE,
        R.pathOr('', ['values'], docCarrierForm),
      );
      const data = {
        ...newValue,
        file,
        documentType,
        primaryObjectGuid,
        [GC.FIELD_COMMENTS]: R.path(['values', GC.FIELD_COMMENTS], docCarrierForm),
        [GC.FIELD_DOCUMENT_DOCUMENT_NAME]: R.path(['values', GC.FIELD_DOCUMENT_DOCUMENT_NAME], docCarrierForm),
      };
      setCarrierDocRequest(data);
      closeModal();
    },
    handleSelectFile: (props: Object) => (event: Object) => {
      props.change('file', R.path(['target', 'value'], event));
      props.setFile(R.path(['target', 'files', '0'], event));
    },
    handleCleanUpload: (props: Object) => () => {
      props.change('file', null);
      props.setFile(null);
    },
    handleOnChangeEvent: (props: Object) => (e: Object) => {
      props.setStatusLocation(false);
      if (R.equals(R.path(['target', 'name'], e), GC.FIELD_EVENT_GUID)) {
        const selectedEvent = R.find(
          (event: Object) => R.equals(e.target.value, event.guid),
          R.pathOr([], ['mappedEventsForMap'], props),
        );
        R.forEachObjIndexed(
          (value: any, key: string) => {
            props.change(key, value);
          },
          R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR, selectedEvent.location),
        );
      }
      props.setStatusLocation(true);
    },
  }),
  withPropsOnChange(
    ['file'],
    ({ file, setIsDisabledDocumentNameField }: Object) => setIsDisabledDocumentNameField(G.isNilOrEmpty(file)),
  ),
  pure,
);

const setDisableField = (fieldName: Object, isDisabledDocumentNameField: boolean) => R.and(
  R.equals(fieldName, GC.FIELD_DOCUMENT_DOCUMENT_NAME),
  isDisabledDocumentNameField,
);

const getOptions = (props: Object, field: Object) => {
  if (R.equals(field.nameForAttribute, 'proofType')) return field.options;
  const isNoNeedCreateStopOptions = R.or(
    R.isNil(props.docsForm),
    G.notEquals(field.nameForAttribute, 'eventGuid'),
  );
  if (isNoNeedCreateStopOptions) {
    return G.addEmptyOptionToDropDown(
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
        props.communicationsConfigs,
        field.options,
      ),
      G.getWindowLocale('titles:document-type', 'Document Type'),
    );
  }
  let events = props.load.events;
  if (R.equals(props.docsForm.values.proofType, GC.DOCUMENT_PROOF_TYPE_POP)) {
    events = events.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_PICKUP));
  }
  if (R.equals(props.docsForm.values.proofType, GC.DOCUMENT_PROOF_TYPE_POD)) {
    events = events.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_DROP));
  }
  let entityName = props.entityName;
  if (R.pathEq('clo', ['docsForm', 'values', 'loadType'], props)) {
    events = events.filter((event: Object) => G.isTrue(event.cloEvent));
    entityName = 'clo';
  }
  return G.createStopOptions(events, R.equals(entityName, GC.FIELD_TEL));
};

const renderDocumentField = (props: Object, field: Object, index: number) => {
  const { initialValues } = props;
  const { nameForAttribute } = field;

  const optionsForSelect = {
    [GC.FIELD_AVAILABLE_CARRIER_DOCUMENT_TYPE]: [],
  };

  if (G.isNotNil(initialValues)) {
    const isWithoutUrlField = R.and(
      G.isNotNil(initialValues.url),
      R.equals(nameForAttribute, 'file'),
    );
    const isWithoutFileField = R.and(
      G.isNotNil(initialValues.documentFilename),
      R.equals(nameForAttribute, 'url'),
    );

    if (R.or(isWithoutUrlField, isWithoutFileField)) return null;
  }

  if (R.equals(nameForAttribute, GC.FIELD_EVENT_GUID)) {
    optionsForSelect.stopOptions = getStopOptions(props, field);

    if (G.isNilOrEmpty(optionsForSelect.stopOptions)) return;
  }

  if (R.equals(nameForAttribute, GC.FIELD_CARRIER_DOCUMENT_TYPE)) {
    optionsForSelect[GC.FIELD_AVAILABLE_CARRIER_DOCUMENT_TYPE] = formatDocTypeOptions(
      R.path(['detailPageInfo', 'availableDocumentTypes'], props),
    );

    if (G.isNilOrEmpty(optionsForSelect[GC.FIELD_AVAILABLE_CARRIER_DOCUMENT_TYPE])) return;
  }

  const options = getOptions(props, field);

  if (R.and(G.isNotNil(field.options), R.isEmpty(options))) return null;

  return (
    <Box>
      {
        G.isNotNil(field.label) &&
        <Box mr='15px' fontSize='15px'>
          {field.label}
        </Box>
      }
      <Field
        key={index}
        width='310px'
        margin='5px 0'
        display='flex'
        info={field.info}
        errorFontSize={14}
        direction='column'
        change={props.change}
        errorMargin='0 0 5px 0'
        endLabel={field.endLabel}
        validate={field.validate}
        placeholder={field.placeholder}
        type={G.setFieldType(field.type)}
        onChange={props.handleOnChangeEvent}
        cleanUpload={props.handleCleanUpload}
        component={renderFormGroup(field.type)}
        selectFileAction={props.handleSelectFile}
        name={R.or(field.nameForAttribute, field.name)}
        labelPosition={G.ifElse(R.equals(field.type, 'toggle'), 'left', null)}
        documentFilename={R.pathOr('', ['initialValues', 'documentFilename'], props)}
        disabled={setDisableField(field.nameForAttribute, props.isDisabledDocumentNameField)}
        options={G.ifElse(G.isString(field.options), optionsForSelect[field.options], field.options)} />
    </Box>
  );
};

const DocModalComponent = (props: Object) => (
  <form p='15px' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
    <AbsoluteBox
      left='0'
      top='0px'
      zIndex='100'
      width='100%'
      height='100%'
      minWidth='320px'
      flexDirection='column'
      justifyContent='space-between'
      bg={G.getTheme('colors.white')}
    >
      <Flex
        p='5px 15px'
        width='100%'
        height='60px'
        minWidth='320px'
        alignItems='center'
        justifyContent='flex-start'
        bg={G.getTheme('colors.cobalt')}
        color={G.getTheme('colors.white')}
        boxShadow='5px 2px 26px -2px rgb(21,21,21)'
      >
        <IconWrapper
          ml='20px'
          pr='10px'
          onClick={() => props.closeModal()}
        >
          {I.arrowIconMob()}
        </IconWrapper>
      </Flex>
      <Flex
        flex='auto'
        p='5px 15px'
        width='100%'
        minWidth='320px'
        overflowY='scroll'
        flexDirection='column'
      >
        {
          documentFieldsCarrier.fields.map((field: Object, index: number) => (
            renderDocumentField(props, field, index)
          ))
        }
      </Flex>
      <Flex
        pb='20px'
        width='100%'
        height='60px'
        minWidth='320px'
        minHeight='60px'
        alignItems='center'
        justifyContent='center'
      >
        <Button>
          {G.getWindowLocale('titles:add-documents', 'ADD DOCUMENT', { caseAction: 'apperCase' })}
        </Button>
      </Flex>
    </AbsoluteBox>
  </form>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  docCarrierForm: makeSelectCarrierDetailPageFormDocForm(state),
}));

export default connect(mapStateToProps, {
  addCarrierDoc,
  setCarrierDocRequest,
})(enhance(DocModalComponent));
