import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// task type
export const removeTaskTypeRequest = createAction('removeTaskTypeRequest');
export const removeTaskTypeSuccess = createAction('removeTaskTypeSuccess');
export const getTaskTypeListRequest = createAction('getTaskTypeListRequest');
export const getTaskTypeListSuccess = createAction('getTaskTypeListSuccess');
export const createOrUpdateTaskTypeRequest = createAction('createOrUpdateTaskTypeRequest');
export const createOrUpdateTaskTypeSuccess = createAction('createOrUpdateTaskTypeSuccess');
// task status
export const removeTaskStatusRequest = createAction('removeTaskStatusRequest');
export const removeTaskStatusSuccess = createAction('removeTaskStatusSuccess');
export const getTaskStatusListRequest = createAction('getTaskStatusListRequest');
export const getTaskStatusListSuccess = createAction('getTaskStatusListSuccess');
export const createOrUpdateTaskStatusRequest = createAction('createOrUpdateTaskStatusRequest');
export const createOrUpdateTaskStatusSuccess = createAction('createOrUpdateTaskStatusSuccess');
