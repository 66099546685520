import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal } from '../../../../components/modal/actions';
// features
import AsyncMasterInvoice from '../../../master-invoice/components/async-master-invoice';
import { makeSelectExpandedContainerOptions } from '../../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature dispatch details new
import { makeSelectLoadDetails } from '../selectors';
import { makeSelectOrderDetails } from '../../order/selectors';
import { getLoadCustomerInvoiceListRequest } from '../actions';
import { getOrderCustomerInvoiceListRequest } from '../../order/actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  load: makeSelectLoadDetails(state),
  order: makeSelectOrderDetails(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

const enhance = compose(
  connect(mapStateToProps, { openModal, getLoadCustomerInvoiceListRequest, getOrderCustomerInvoiceListRequest }),
  withHandlers({
    handleOpenMasterInvoiceDetails: (props: Object) => () => {
      const {
        load,
        order,
        cloGuid,
        openModal,
        masterInvoiceGuid,
        expandedContainerOptions,
        getLoadCustomerInvoiceListRequest,
        getOrderCustomerInvoiceListRequest,
      } = props;

      const configsNamesArray = [
        GC.CARRIER_DOCUMENT_TYPE,
        GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES,
        GC.INVOICE_CLO_MASTER_INVOICE_INVOICE_FILTER_DATE_RANGE_DAYS,
      ];

      let isClo;
      let branchGuid;
      let getCloInvoicesRequest;

      if (G.isTrue(expandedContainerOptions.opened)) {
        isClo = R.pathEq(GC.PAGE_DISPATCH_DETAILS_NEW_ORDER, ['componentType'], expandedContainerOptions);
      } else {
        const route = G.getCurrentPathname();

        isClo = R.any((item: string) => R.includes(item, R.split('/', route)), [GC.FIELD_CLO, GC.FIELD_ORDER]);
      }

      if (isClo) {
        branchGuid = G.getBranchGuidFromObject(order);
        getCloInvoicesRequest = getOrderCustomerInvoiceListRequest;
      } else {
        getCloInvoicesRequest = getLoadCustomerInvoiceListRequest;
        branchGuid = R.compose(
          R.pathOr('', [GC.BRANCH_GUID]),
          R.find(R.propEq(cloGuid, GC.FIELD_GUID)),
          R.pathOr([], [GC.FIELD_CLOS]),
        )(load);
      }

      const component = (
        <AsyncMasterInvoice
          cloGuid={cloGuid}
          branchGuid={branchGuid}
          configsNamesArray={configsNamesArray}
          masterInvoiceGuid={masterInvoiceGuid}
          getCloInvoicesRequest={getCloInvoicesRequest}
          asyncEndpoint={endpointsMap.getCustomerMasterInvoiceEndpoint(masterInvoiceGuid)}
        />
      );

      const modal = {
        p: '0 8px',
        component,
        options: {
          minWidth: 800,
          height: 'auto',
          maxWidth: '95vw',
          overflow: 'auto',
          maxHeight: '90vh',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:master-invoice', 'Master Invoice'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const MasterInvoiceTableCell = ({ masterInvoiceNumber, handleOpenMasterInvoiceDetails }: Object) => {
  const blueColor = G.getTheme('colors.light.blue');

  if (G.isNilOrEmpty(masterInvoiceNumber)) {
    return <Box color={blueColor}>{G.getWindowLocale('titles:no-number', 'No number')}</Box>;
  }

  return (
    <Box
      cursor='pointer'
      color={blueColor}
      onClick={handleOpenMasterInvoiceDetails}
      title={G.getWindowLocale('titles:click-to-see-details', 'Click to see details')}
    >
      {masterInvoiceNumber}
    </Box>
  );
};

export default enhance(MasterInvoiceTableCell);
