import LoadDetails from '../components/load-details';
import OrderDetails from '../components/order-details';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const titleRowHeight = 55;
export const tableMaxHeight = 200;

const commonSettings = {
  titleRowHeight,
  expandableItems: true,
  checkBoxCellWidth: 70,
  coloredActiveRow: true,
  searchableTitles: true,
  maxHeight: tableMaxHeight,
  tableRowHeight: rowHeight,
};

export const cloTableSettings = {
  ...commonSettings,
  expandedDetailsComponent: OrderDetails,
};

export const telTableSettings = {
  ...commonSettings,
  useMainColors: false,
  expandedDetailsComponent: LoadDetails,
};
