// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants/system-fields';
// feature carrier
import * as LC from '../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_CARRIER_NAME,
    name: G.getWindowLocale('titles:company-name', 'Company Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_DBA,
    name: G.getWindowLocale('titles:dba', 'DBA'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_CARRIER_ADDED_TO_WATCH_LIST,
    name: G.getWindowLocale('titles:watch', 'Watch'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_CARRIER_ADDED_TO_NETWORK,
    name: G.getWindowLocale('titles:added-to-network', 'Added To Network'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW,
    name: G.getWindowLocale('titles:allowed-book-it-now', 'Allowed Book it Now'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_USDOT_NUMBER,
    name: G.getWindowLocale('titles:usdot-number', 'USDOT Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_MC_NUMBER,
    name: G.getWindowLocale('titles:mc-number', 'MC Numbers'),
  },
  {
    type: 'string',
    value: GC.FIELD_ACCOUNTING_EXTERNAL_ID,
    name: G.getWindowLocale('titles:accounting-external-id', 'Accounting External ID'),
  },
  {
    type: 'string',
    value: GC.FIELD_NOTES,
    name: G.getWindowLocale('titles:notes', 'Notes'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_CARRIER_ACTIVE,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_IGNORE_DEACTIVATION_RULES,
    name: G.getWindowLocale('titles:ignore-activation-deactivation-rules', 'Ignore Activation/Deactivation Rules'),
  },
  {
    type: 'string',
    value: `${LC.BRANCH_FILTER_PREFIX}.${GC.FIELD_BRANCH_NAME}`,
    name: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    type: 'string',
    value: `${GC.FIELD_CARRIER_DEACTIVATED_REASON}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: G.getWindowLocale('titles:status-reason', 'Status Reason'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_INTERNAL_ID,
    name: G.getWindowLocale('titles:internal-id', 'Internal Carrier ID'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_SCAC,
    name: G.getWindowLocale('titles:scac', 'SCAC'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_INTRASTATE_DOT,
    name: G.getWindowLocale('titles:intrastate-dot', 'Intrastate Dot'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_INTRASTATE_STATE,
    name: G.getWindowLocale('titles:intrastate-state', 'Intrastate State'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_FEDERAL_EIN,
    name: G.getWindowLocale('titles:federal-ein', 'Federal EIN'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_DUNS_NUMBER,
    name: G.getWindowLocale('titles:duns-number', 'D-U-N-S Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_URL,
    name: G.getWindowLocale('titles:url', 'Url'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.FIELD_EMAIL,
    name: G.getWindowLocale('titles:email', 'Email'),
  },
  {
    type: 'string',
    value: GC.FIELD_PHONE_NUMBER,
    name: G.getWindowLocale('titles:phone-number', 'Phone Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:updated-by', 'Updated By'),
  },
  {
    type: 'date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
    name: G.getWindowLocale('titles:updated-date', 'Updated Date'),
  },
  {
    type: 'string',
    collection: true,
    value: `${GC.FIELD_CARRIER_TRANSPORTATION_MODES}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: G.getWindowLocale(
      'titles:transportation-mode',
      'Transportation Mode',
    ),
  },
  {
    type: 'string',
    collection: true,
    name: G.getWindowLocale('titles:equipment', 'Equipments'),
    value: `${GC.FIELD_CARRIER_EQUIPMENTS}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    type: 'string',
    collection: true,
    value: `${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
       ${G.getWindowLocale('titles:type', 'Type')}
    `,
  },
  {
    type: 'number',
    collection: true,
    value: `${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT}`,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
       ${G.getWindowLocale('titles:coverage-amount', 'Coverage Amount')}
    `,
  },
  {
    type: 'date',
    collection: true,
    value: `${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE}`,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
       ${G.getWindowLocale('titles:effective-date', 'Effective Date')}
    `,
  },
  {
    type: 'date',
    collection: true,
    value: `${LC.INSURANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE}`,
    name: `
      ${G.getWindowLocale('titles:insurance', 'Insurance')}:
       ${G.getWindowLocale('titles:expiration-date', 'Expiration Date')}
    `,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:currency', 'Currency'),
    value: `${LC.CARRIER_FINANCE_FILTER_PREFIX}.${GC.FIELD_CURRENCY}`,
  },
  {
    type: 'number',
    value: `${LC.CARRIER_FINANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE}`,
    name: G.getWindowLocale('titles:deduction-percentage', 'Quick Pay Deduction Percentage'),
  },
  {
    type: 'boolean',
    collection: true,
    value: `${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_CERTIFIED}`,
    name: `${G.getWindowLocale('titles:certificate-certified', 'Certificate: Certified')}`,
  },
  {
    type: 'number',
    collection: true,
    value: `${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT}`,
    name: `${G.getWindowLocale('titles:certificate-driver-count', 'Certificate: Driver Count')}`,
  },
  {
    type: 'date',
    collection: true,
    value: `${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE}`,
    name: `${G.getWindowLocale('titles:certificate-effective-date', 'Certificate: Effective Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: `${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE}`,
    name: `${G.getWindowLocale('titles:certificate-expiration-date', 'Certificate: Expiration Date')}`,
  },
  {
    type: 'boolean',
    collection: true,
    value: `${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_PERMIT}`,
    name: `${G.getWindowLocale('titles:certificate-permit', 'Certificate: HM232 Permit')}`,
  },
  {
    type: 'boolean',
    collection: true,
    value: `${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN}`,
    name: `${G.getWindowLocale('titles:certificate-safety-plan', 'Certificate: Safety Plan')}`,
  },
  {
    type: 'string',
    collection: true,
    value: `${LC.CERTIFICATE_FILTER_PREFIX}.${GC.FIELD_CARRIER_CERTIFICATE_CLASS}`,
    name: `${G.getWindowLocale('titles:certificate-classes', 'Certificate: Classes')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_ADDRESS,
    name: `${G.getWindowLocale('titles:address', 'Address')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_ADDRESS_2,
    name: `${G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_CITY,
    name: `${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_STATE,
    name: `${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_ZIP,
    name: `${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_COUNTRY,
    name: `${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_NAME}`,
    name: `${G.getWindowLocale('titles:primary-terminal-name', 'Primary Terminal: Name')}`,
  },
  {
    type: 'boolean',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_ACTIVE}`,
    name: `${G.getWindowLocale('titles:primary-terminal-status', 'Primary Terminal: Status')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_ADDRESS}`,
    name: `${G.getWindowLocale('titles:primary-terminal-address', 'Primary Terminal: Address')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CITY}`,
    name: `${G.getWindowLocale('titles:primary-terminal-city', 'Primary Terminal: City')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_STATE}`,
    name: `${G.getWindowLocale('titles:primary-terminal-state', 'Primary Terminal: State')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_ZIP}`,
    name: `${G.getWindowLocale('titles:primary-terminal-zip', 'Primary Terminal: Zip')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_COUNTRY}`,
    name: `${G.getWindowLocale('titles:primary-terminal-country', 'Primary Terminal: Country')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS}.type`,
    name: `${G.getWindowLocale('titles:primary-terminal-dispatch-process', 'Primary Terminal: Dispatch Process')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS}.type`,
    name: `${G.getWindowLocale('titles:primary-terminal-bit-process', 'Primary Terminal: Bit Process')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.type`,
    name: `${G.getWindowLocale('titles:primary-terminal-status-check-process', 'Primary Terminal: Status Check Process')}`,
  },
  {
    type: 'string',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${LC.TERMINAL_FINANCE_FILTER_PREFIX}.${GC.FIELD_CURRENCY}`,
    name: `${G.getWindowLocale('titles:primary-terminal-currency', 'Primary Terminal: Currency')}`,
  },
  {
    type: 'number',
    value: `${LC.PRIMARY_TERMINAL_FILTER_PREFIX}.${LC.TERMINAL_FINANCE_FILTER_PREFIX}.${GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE}`,
    name: `${G.getWindowLocale('titles:primary-terminal-quick-pay-deduction-percentage', 'Primary Terminal: Quick Pay Deduction Percentage')}`,
  },
  {
    type: 'string',
    collection: true,
    value: `${GC.FIELD_INTEGRATION_INFO}.${GC.FIELD_INTEGRATION_TYPE}`,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:integration-type', 'Integration Type')}`,
  },
  {
    type: 'string',
    collection: true,
    value: `${GC.FIELD_INTEGRATION_INFO}.${GC.FIELD_INTEGRATION_ID}`,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:integration-id', 'Integration ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: `${GC.FIELD_INTEGRATION_INFO}.${GC.FIELD_EXTERNAL_DIVISION_NAME}`,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:division', 'Division')}`,
  },
  {
    type: 'date',
    value: `${GC.FIELD_INTEGRATION_INFO}.${GC.FIELD_LAST_MODIFIED_DATE}`,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:update-on', 'Updated On')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.DOCUMENTS_TYPE,
    name: `${G.getWindowLocale('titles: documents', 'Documents')}: ${
      G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.DOCUMENTS_DESCRIPTION,
    name: `${G.getWindowLocale('titles: documents', 'Documents')}: ${
      G.getWindowLocale('titles: description', 'Description')}`,
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.DOCUMENTS_SIGNET_DATE,
    name: `${G.getWindowLocale('titles: documents', 'Documents')}: ${
      G.getWindowLocale('titles: signed-date', 'Signed Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.DOCUMENTS_EXPIRATION_DATE,
    name: `${G.getWindowLocale('titles: documents', 'Documents')}: ${
      G.getWindowLocale('titles:expiration-date', 'Expiration Date')}`,
  },
];

// TODO: with right filter params
export const getCarrierRateFilterParams = [
  {
    type: 'string',
    value: GC.FIELD_CARRIER_RATE_NAME,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'date',
    value: GC.FIELD_CARRIER_RATE_EXPIRATION_DATE,
    name: G.getWindowLocale('titles:expiration-date', 'Expiration Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_CARRIER_RATE_EFFECTIVE_DATE,
    name: G.getWindowLocale('titles:effective-date', 'Effective Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID,
    name: G.getWindowLocale('titles:origin-zone-name', 'Origin Zone Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID,
    name: G.getWindowLocale('titles:destination-zone-name', 'Destination Zone Name'),
  },
  {
    collection: true,
    type: 'string:array',
    name: G.getWindowLocale('titles:origin-cities', 'Origin Cities'),
    value: `${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_CITIES}`,
  },
  {
    collection: true,
    type: 'string:array',
    name: G.getWindowLocale('titles:origin-states', 'Origin States'),
    value: `${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_STATES}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: `${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_COUNTRIES}`,
    name: G.getWindowLocale('titles:origin-countries', 'Origin Countries'),
  },
  {
    collection: true,
    type: 'string:array',
    name: G.getWindowLocale('titles:origin-zip-codes', 'Origin Zip Codes'),
    value: `${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_CODES}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-from', 'Zip From'),
    value: `${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-to', 'Zip To'),
    value: `${LC.FIELD_CARRIER_RATE_ORIGIN}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`,
  },
  {
    collection: true,
    type: 'string:array',
    name: G.getWindowLocale('titles:destination-cities', 'Destination Cities'),
    value: `${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_CITIES}`,
  },
  {
    collection: true,
    type: 'string:array',
    name: G.getWindowLocale('titles:destination-states', 'Destination States'),
    value: `${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_STATES}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: `${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_COUNTRIES}`,
    name: G.getWindowLocale('titles:destination-countries', 'Destination Countries'),
  },
  {
    collection: true,
    type: 'string:array',
    value: `${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_CODES}`,
    name: G.getWindowLocale('titles:destination-zip-codes', 'Destination Zip Codes'),
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-from', 'Zip From'),
    value: `${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_FROM}`,
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:zip-to', 'Zip To'),
    value: `${LC.FIELD_CARRIER_RATE_DESTINATION}.${GC.FIELD_CARRIER_RATE_ZIP_RANGE_TO}`,
  },
  {
    type: 'number',
    value: GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM,
    name: G.getWindowLocale('titles:distance-range-from', 'Distance Range From'),
  },
  {
    type: 'number',
    value: GC.FIELD_CARRIER_RATE_DISTANCE_RANGE_TO,
    name: G.getWindowLocale('titles:distance-range-to', 'Distance Range To'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_RATE_DISTANCE_OUM,
    name: G.getWindowLocale('titles:distance-range-uom', 'Distance Range UOM'),
  },
  {
    type: 'number',
    value: GC.FIELD_MIN_WEIGHT,
    name: G.getWindowLocale('titles:min-weight', 'Min Weight'),
  },
  {
    type: 'number',
    value: GC.FIELD_MAX_WEIGHT,
    name: G.getWindowLocale('titles:max-weight', 'Max Weight'),
  },
  {
    type: 'string',
    value: GC.FIELD_WEIGHT_UOM,
    name: G.getWindowLocale('titles:weight-uom', 'Weight UOM'),
  },
  {
    type: 'number',
    value: GC.FIELD_MIN_TEMPERATURE,
    name: G.getWindowLocale('titles:min-temperature', 'Min Temperature'),
  },
  {
    type: 'number',
    value: GC.FIELD_MAX_TEMPERATURE,
    name: G.getWindowLocale('titles:max-temperature', 'Max Temperature'),
  },
  {
    type: 'string',
    value: GC.FIELD_TEMPERATURE_UOM,
    name: G.getWindowLocale('titles:temperature-uom', 'Temperature UOM'),
  },
  {
    type: 'number',
    value: GC.FIELD_MIN_QUANTITY,
    name: G.getWindowLocale('titles:min-quantity', 'Min Quantity'),
  },
  {
    type: 'number',
    value: GC.FIELD_MAX_QUANTITY,
    name: G.getWindowLocale('titles:max-quantity', 'Max Quantity'),
  },
  {
    type: 'string',
    value: GC.FIELD_PACKAGE_TYPE,
    name: G.getWindowLocale('titles:package-type', 'Package Type'),
  },
  // TODO: check this one
  {
    type: 'number',
    value: GC.FIELD_CHARGE_RATE,
    name: G.getWindowLocale('titles:rate', 'Expired'),
  },
  {
    name: G.getWindowLocale('titles:currency', 'Currency'),
    type: 'string',
    value: GC.FIELD_CURRENCY,
  },
  {
    type: 'string',
    value: GC.FIELD_CHARGE_RATE_TYPE,
    name: G.getWindowLocale('titles:rate-type', 'Rate Type'),
  },
  {
    type: 'string',
    value: GC.FIELD_CHARGE_RATE_UNIT,
    name: G.getWindowLocale('titles:rate-unit', 'Rate Unit'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_MIN_RATE,
    name: G.getWindowLocale('titles:min-rate', 'Rate Minimum'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_MAX_RATE,
    name: G.getWindowLocale('titles:max-rate', 'Rate Maximum'),
  },
  {
    type: 'number',
    value: GC.FIELD_TRANSIT_DAYS,
    name: G.getWindowLocale('titles:transit-days', 'Transit Days'),
  },
  {
    type: 'string',
    collection: true,
    name: G.getWindowLocale('titles:equipments', 'Equipments'),
    value: `${GC.FIELD_CARRIER_EQUIPMENTS}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    type: 'string',
    collection: true,
    name: G.getWindowLocale('titles:service-type', 'Service Type'),
    value: `${GC.FIELD_CARRIER_SERVICE_TYPES}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
];
