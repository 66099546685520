import * as R from 'ramda';
import {
  all,
  put,
  fork,
  call,
  take,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAccessorialForConfigRequest } from '../configurations/actions';
import { getLoadDetailsRequest, setDispatchPlannerOpened } from '../dispatch-details-new/load/actions';
import { getAllAvailableCloRefTypesRequest, getAllAvailableTelRefTypesRequest } from '../reference/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature dispatch-planner
import * as A from './actions';
import * as H from './helpers';
import {
  makeSelectTelList,
  makeSelectDraftList,
  makeSelectCloByGuid,
  makeSelectCloReport,
  makeSelectTelReport,
  makeSelectCurrentRoute,
  makeSelectBuilderStore,
  makeSelectSelectedTels,
  makeSelectRouteChanged,
  makeSelectInitRouteGuid,
  makeSelectCloPagination,
  makeSelectTelPagination,
  makeSelectOpenedFromPage,
  makeSelectCloFilterParams,
  makeSelectTelFilterParams,
  makeSelectDraftPagination,
  makeSelectInboundTelReport,
  makeSelectInboundTerminals,
  makeSelectEditTemplateRoute,
  makeSelectOutboundTerminals,
  makeSelectOutboundTelReport,
  makeSelectDraftFilterParams,
  makeSelectCurrentRouteItems,
  makeSelectCloTableTitleFilters,
  makeSelectTelTableTitleFilters,
  makeSelectBranchGuidForRequest,
  makeSelectInboundTelPagination,
  makeSelectOutboundTelPagination,
  makeSelectCurrentRouteContainers,
  makeSelectCloTableTitleSortValues,
  makeSelectTelTableTitleSortValues,
  makeSelectCurrentRouteInitialState,
  makeSelectInboundTelTableTitleFilters,
  makeSelectOutboundTelTableTitleFilters,
  makeSelectInboundTelTableTitleSortValues,
  makeSelectOutboundTelTableTitleSortValues,
  makeSelectPrimaryReferenceAutogeneratedConfig,
  // cross dock clos
  makeSelectCrossDockCloReport,
  makeSelectCrossDockCloByGuid,
  makeSelectCrossDockCloPagination,
  makeSelectCrossDockCloFilterParams,
  makeSelectCrossDockCloTableTitleFilters,
  makeSelectCrossDockCloTableTitleSortValues,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetRouteByGuid(guid: string) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getRouteByGuidEndpoint(guid));
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setAvailableItems(H.mapRouteItems(data.tels)));
      yield put(A.setAvailableContainers(H.mapRouteContainers(data.tels)));
      yield put(A.setCurrentRoute(H.convertRouteResponseWithOrigRouteGuid(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetRouteByGuid fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetRouteByGuid exception');
  }
}

function* handleGetCloDetailsRequest(guid: string) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const clo = yield select(makeSelectCloByGuid(guid));
    const crossDockClo = yield select(makeSelectCrossDockCloByGuid(guid));
    const details = R.pathOr(
      R.path(['details'], crossDockClo),
      ['details'],
      clo,
    );

    if (G.isNotNilAndNotEmpty(details)) {
      yield put(A.getCloDetailsSuccess(details));
      yield put(closeLoader());

      return;
    }

    const params = { payload: guid };
    const res = yield call(sendRequest, 'get', endpointsMap.getRouteBuilderCloDetailsEndpoint(guid), { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCloDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCloDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetCloDetailsRequest exception');
  }
}

function* handleSelectCloForTelRequest({ payload }: Object) {
  const cloGuid = R.path(['returnObject', 'cloGuid'], payload);
  const telGuid = R.path(['selectedValue', 'value'], payload);

  try {
    if (R.and(G.isNotNilAndNotEmpty(cloGuid), G.isNotNilAndNotEmpty(telGuid))) {
      yield put(openLoader({ showDimmer: true }));
      yield call(handleGetCloDetailsRequest, cloGuid);
      const clo = yield select(makeSelectCloByGuid(cloGuid));

      if (G.isNotNilAndNotEmpty(R.prop('details', clo))) {
        yield put(A.selectCloForTelSuccess({ telGuid, cloDetails: clo.details }));
      }

      yield put(closeLoader());
    }
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleSelectCloForTelRequest exception');
  }
}

function* handleSelectCrossDockCloSaga({ payload }: Object) {
  const cloGuid = R.path(['returnObject', 'cloGuid'], payload);
  const telGuid = R.path(['selectedValue', 'value'], payload);

  try {
    if (R.and(G.isNotNilAndNotEmpty(cloGuid), G.isNotNilAndNotEmpty(telGuid))) {
      yield put(openLoader({ showDimmer: true }));
      yield call(handleGetCloDetailsRequest, cloGuid);
      const clo = yield select(makeSelectCrossDockCloByGuid(cloGuid));

      if (G.isNotNilAndNotEmpty(R.prop('details', clo))) {
        yield put(A.selectCrossDockCloSuccess({ telGuid, cloDetails: clo.details }));
      }

      yield put(closeLoader());
    }
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleSelectCrossDockCloSaga exception');
  }
}

function* handleToggleCloDetails({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    yield call(handleGetCloDetailsRequest, payload);
    yield put(A.toggleClo(payload));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleToggleCloDetails exception');
  }
}

function* handleToggleCrossDockCloDetails({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    yield call(handleGetCloDetailsRequest, payload);
    yield put(A.toggleCrossDockClo(payload));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleToggleCrossDockCloDetails exception');
  }
}

function* handleToggleTelDetails({ payload }: Object) {
  const { guid } = payload;
  yield put(A.toggleTel(payload));

  if (G.isNilOrEmpty(R.prop('details', payload))) {
    try {
      yield put(openLoader({ showDimmer: true }));
      const res = yield call(sendRequest, 'get', endpointsMap.getTelEndpoint(guid), { params: { guid } });
      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        yield put(A.setTelDetails(data));
      } else {
        yield call(G.handleFailResponse, res, 'handleToggleTelDetails fail');
      }

      yield put(closeLoader());
    } catch (err) {
      yield put(closeLoader());
      yield call(G.handleException, err, 'handleToggleTelDetails exception');
    }
  }
}

function* handleGetActiveCloListSaga(report: Object) {
  try {
    yield put(A.setCloListLoading(true));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const pagination = {
      limit: 100,
      offset: 0,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const options = { data: R.mergeRight(report, pagination) };
    const res = yield call(sendRequest, 'post', endpointsMap.cloListRouteBuilder, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setSelectedCloList(data));
      yield put(A.setCloListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetActiveCloListSaga fail');
      yield put(A.setCloListLoading(false));
    }
  } catch (err) {
    yield put(A.setCloListLoading(false));
    yield call(G.handleException, err, 'handleGetActiveCloListSaga exception');
  }
}

function* handleGetActiveCrossDockCloListSaga(report: Object) {
  try {
    const config = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_LINKED_ORDERS);

    if (R.not(config)) return;

    yield put(A.setCrossDockCloListLoading(true));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const pagination = {
      offset: 0,
      limit: 100,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const options = { data: R.mergeRight(report, pagination) };

    const res = yield call(sendRequest, 'post', endpointsMap.cloListRouteBuilder, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setSelectedCrossDockCloList(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetActiveCrossDockCloListSaga fail');
    }

    yield put(A.setCrossDockCloListLoading(false));
  } catch (err) {
    yield put(A.setCrossDockCloListLoading(false));
    yield call(G.handleException, err, 'handleGetActiveCrossDockCloListSaga exception');
  }
}

function* handleGetCloListSaga(isInitial: any) {
  try {
    yield put(A.setCloListLoading(true));

    const pagination = yield select(makeSelectCloPagination());

    const { total, limit, offset } = pagination;

    if (R.gte(offset, total)) return;

    const report = yield select(makeSelectCloReport());
    const filterParams = yield select(makeSelectCloFilterParams());
    const titleFilterParams = yield select(makeSelectCloTableTitleFilters());
    const titleOrderFields = yield select(makeSelectCloTableTitleSortValues());
    const branchGuidForRequest = yield select(makeSelectBranchGuidForRequest());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    let currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNotNilAndNotEmpty(branchGuidForRequest)) currentBranchGuid = branchGuidForRequest;

    const reqBody = {
      limit,
      offset,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloListRouteBuilder, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setCloList(data));
      yield put(A.setCloListLoading(false));

      const { offset } = yield select(makeSelectCloPagination());

      if (R.and(R.gt(G.getPropFromObject('totalCount', data), offset), G.isTrue(isInitial))) {
        yield put(A.getCloNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCloListSaga fail');

      yield put(A.setCloListLoading(false));
    }
  } catch (err) {
    yield put(A.setCloListLoading(false));

    yield call(G.handleException, err, 'handleGetCloListSaga exception');
  }
}

function* handleGetCrossDockCloListSaga(isInitial: any) {
  try {
    const config = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_LINKED_ORDERS);

    if (R.not(config)) return;

    yield put(A.setCrossDockCloListLoading(true));

    const pagination = yield select(makeSelectCrossDockCloPagination());

    const { total, limit, offset } = pagination;

    if (R.gte(offset, total)) return;

    const report = yield select(makeSelectCrossDockCloReport());
    const filterParams = yield select(makeSelectCrossDockCloFilterParams());
    const branchGuidForRequest = yield select(makeSelectBranchGuidForRequest());
    const titleFilterParams = yield select(makeSelectCrossDockCloTableTitleFilters());
    const titleOrderFields = yield select(makeSelectCrossDockCloTableTitleSortValues());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    let currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNotNilAndNotEmpty(branchGuidForRequest)) currentBranchGuid = branchGuidForRequest;

    const reqBody = {
      limit,
      offset,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloListRouteBuilder, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setCrossDockCloList(data));
      yield put(A.setCrossDockCloListLoading(false));

      const { offset } = yield select(makeSelectCrossDockCloPagination());

      if (R.and(R.gt(G.getPropFromObject('totalCount', data), offset), G.isTrue(isInitial))) {
        yield put(A.getCrossDockCloNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCrossDockCloListSaga fail');

      yield put(A.setCrossDockCloListLoading(false));
    }
  } catch (err) {
    yield put(A.setCrossDockCloListLoading(false));

    yield call(G.handleException, err, 'handleGetCrossDockCloListSaga exception');
  }
}

function* handleGetActiveTelListSaga(report: Object) {
  try {
    yield put(A.setTelListLoading(true));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const props = {
      limit: 100,
      offset: 0,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const options = { data: R.mergeRight(report, props) };
    const res = yield call(sendRequest, 'post', endpointsMap.telListRouteBuilder, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setSelectedTelList(data));
      yield put(A.setTelListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetActiveTelListSaga fail');
      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));
    yield call(G.handleException, err, 'handleGetActiveTelListSaga exception');
  }
}

function* getReportTelByGuid({ payload }: Object) {
  try {
    yield put(A.setTelListLoading(true));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const report = yield select(makeSelectTelReport());
    const props = {
      offset: 0,
      limit: 100,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: R.of(Array, {
        dataType: 'string',
        operation: 'equal',
        stringValue: payload,
        propertyName: GC.FIELD_GUID,
      }),
    };
    const options = { data: R.mergeRight(report, props) };
    const res = yield call(sendRequest, 'post', endpointsMap.telListRouteBuilder, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.prependTelToList(data));
      yield put(A.setTelListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'getReportTelByGuid fail');
      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));
    yield call(G.handleException, err, 'getReportTelByGuid exception');
  }
}

function* handleGetDraftListSaga({ payload }: Object) {
  try {
    yield put(A.setDraftListLoading(true));
    const { showOnlyOwn } = payload;
    const draftList = yield select(makeSelectDraftList());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const pagination = yield select(makeSelectDraftPagination());
    const filterParams = yield select(makeSelectDraftFilterParams());
    const reqBody = {
      showOnlyOwn,
      searchCriteria: {},
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const options = { data: G.setSearchCriteria({ filterParams, reqBody }) };
    const res = yield call(sendRequest, 'post', endpointsMap.draftRouteList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(
        A.getDraftListSuccess({
          totalCount: data.totalCount,
          results: H.mapDraftsForInternalUsage(data.results, draftList.length),
        }),
      );
      yield put(A.setDraftListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDraftListSaga fail');
      yield put(A.setDraftListLoading(false));
    }
  } catch (err) {
    yield put(A.setDraftListLoading(false));
    yield call(G.handleException, err, 'handleGetDraftListSaga exception');
  }
}

function* handleGetTelListSaga({ payload }: Object) {
  try {
    yield put(A.setTelListLoading(true));

    const report = yield select(makeSelectTelReport());
    const pagination = yield select(makeSelectTelPagination());
    const filterParams = yield select(makeSelectTelFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const titleFilterParams = yield select(makeSelectTelTableTitleFilters());
    const titleOrderFields = yield select(makeSelectTelTableTitleSortValues());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { limit, offset } = pagination;

    const reqBody = {
      limit,
      offset,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telListRouteBuilder, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setTelList(data));
      yield put(A.setTelListLoading(false));

      const { offset } = yield select(makeSelectTelPagination());

      if (R.and(R.gt(G.getPropFromObject('totalCount', data), offset), G.isTrue(payload))) {
        yield put(A.getTelNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelListSaga fail');

      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));

    yield call(G.handleException, err, 'handleGetTelListSaga exception');
  }
}

function* handleGetInboundTelListSaga({ payload }: Object) {
  try {
    yield put(A.setInboundTelListLoading(true));

    const report = yield select(makeSelectInboundTelReport());
    const filterParams = yield select(makeSelectTelFilterParams());
    const pagination = yield select(makeSelectInboundTelPagination());
    const terminalDropIds = yield select(makeSelectInboundTerminals());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const titleFilterParams = yield select(makeSelectInboundTelTableTitleFilters());
    const titleOrderFields = yield select(makeSelectInboundTelTableTitleSortValues());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { limit, offset } = pagination;

    const reqBody = {
      limit,
      offset,
      terminalDropIds,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telListRouteBuilder, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setInboundTelList(data));
      yield put(A.setInboundTelListLoading(false));

      const { offset } = yield select(makeSelectInboundTelPagination());

      if (R.and(R.gt(G.getPropFromObject('totalCount', data), offset), G.isTrue(payload))) {
        yield put(A.getInboundTelNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetInboundTelListSaga fail');

      yield put(A.setInboundTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setInboundTelListLoading(false));

    yield call(G.handleException, err, 'handleGetInboundTelListSaga exception');
  }
}

function* handleGetOutboundTelListSaga({ payload }: Object) {
  try {
    yield put(A.setOutboundTelListLoading(true));

    const report = yield select(makeSelectOutboundTelReport());
    const filterParams = yield select(makeSelectTelFilterParams());
    const pagination = yield select(makeSelectOutboundTelPagination());
    const terminalPickupIds = yield select(makeSelectOutboundTerminals());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const titleFilterParams = yield select(makeSelectOutboundTelTableTitleFilters());
    const titleOrderFields = yield select(makeSelectOutboundTelTableTitleSortValues());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { limit, offset } = pagination;

    const reqBody = {
      limit,
      offset,
      terminalPickupIds,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telListRouteBuilder, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setOutboundTelList(data));
      yield put(A.setOutboundTelListLoading(false));

      const { offset } = yield select(makeSelectOutboundTelPagination());

      if (R.and(R.gt(G.getPropFromObject('totalCount', data), offset), G.isTrue(payload))) {
        yield put(A.getOutboundTelNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetOutboundTelListSaga fail');

      yield put(A.setOutboundTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setOutboundTelListLoading(false));

    yield call(G.handleException, err, 'handleGetOutboundTelListSaga exception');
  }
}

function* handleRefreshCloListWithRouteClos() {
  try {
    const cloReport = yield select(makeSelectCloReport());
    const currentRoute = yield select(makeSelectCurrentRoute());
    const clos = R.values(currentRoute.clos);
    yield put(A.resetCloListAndPagination());

    if (G.isNotNilAndNotEmpty(clos)) {
      const reportData = R.assoc(
        'searchCriteria',
        R.of(Array, {
          operation: 'in',
          dataType: 'string',
          propertyName: 'guid',
          stringValue: R.join(',', R.map(({ guid }: Object) => guid, clos)),
        }),
        cloReport,
      );
      yield call(handleGetActiveCloListSaga, reportData);
    }

    yield call(handleGetCloListSaga, true);
  } catch (err) {
    yield call(G.handleException, err, 'handleRefreshCloListWithRouteClos exception');
  }
}

function* handleRefreshCrossDockCloListWithRouteClos() {
  try {
    const report = yield select(makeSelectCrossDockCloReport());
    const currentRoute = yield select(makeSelectCurrentRoute());
    const clos = R.values(currentRoute.linkedClos);
    yield put(A.resetCrossDockCloListAndPagination());

    if (G.isNotNilAndNotEmpty(clos)) {
      const reportData = R.assoc(
        'searchCriteria',
        R.of(Array, {
          operation: 'in',
          dataType: 'string',
          propertyName: 'guid',
          stringValue: R.join(',', R.map(({ guid }: Object) => guid, clos)),
        }),
        report,
      );
      yield call(handleGetActiveCrossDockCloListSaga, reportData);
    }

    yield call(handleGetCrossDockCloListSaga, true);
  } catch (err) {
    yield call(G.handleException, err, 'handleRefreshCrossDockCloListWithRouteClos exception');
  }
}

function* handleGetDraftRouteByDraftGuid({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const params = { draftRouteGuid: R.path(['draft', 'draftGuid'], payload) };
    const res = yield call(sendRequest, 'get', endpointsMap.draftRoute, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setAvailableItems(H.mapRouteItems(data.tels)));
      yield put(A.setCurrentRoute(H.convertDraftRouteResponse(data)));

      if (G.isNotNilAndNotEmpty(data.clos)) {
        yield call(handleRefreshCloListWithRouteClos, { clos: data.clos });
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDraftRouteByDraftGuid fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetDraftRouteByDraftGuid exception');
  }
}

function* handleRefreshCloListWithRouteGuid({ route }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const report = yield select(makeSelectCloReport());
    const reportData = H.getInitialReportFromResponse(
      R.of(Array, report),
      currentBranchGuid,
      route.guid,
    );
    yield put(A.resetCloListAndPagination());
    yield put(A.setCloListLoading(false));
    yield call(handleGetActiveCloListSaga, reportData.withSearchCriteria);
    yield call(handleGetCloListSaga, true);
  } catch (err) {
    yield call(G.handleException, err, 'handleRefreshCloListWithRouteGuid exception');
  }
}

function* handleGetRouteByTelGuid({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.route, { params: { telGuid: payload } });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.selectTel(R.map(
        ({ guid }: Object) => guid,
        data.tels,
      )));
      yield put(A.setAvailableItems(H.mapRouteItems(data.tels)));
      yield put(A.setCurrentRoute(H.convertRouteResponseWithOrigRouteGuid(data)));
      yield call(handleRefreshCloListWithRouteGuid, { route: data });

      if (G.isNotNilAndNotEmpty(data.linkedClos)) {
        yield call(handleRefreshCrossDockCloListWithRouteClos);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetRouteByTelGuid fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetRouteByTelGuid exception');
  }
}

function* handleGetRouteByTelGuidAndMergeSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.route, { params: { telGuid: payload } });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const currentRoute = yield select(makeSelectCurrentRoute());
      const selectedTels = yield select(makeSelectSelectedTels());
      const newSelectedTels = R.concat(R.values(selectedTels), R.map(
        ({ guid }: Object) => guid,
        data.tels,
      ));
      yield put(A.selectTelOnAllLists(newSelectedTels));
      const currentItems = yield select(makeSelectCurrentRouteItems());
      yield put(A.setAvailableItems(R.mergeRight(currentItems, H.mapRouteItems(data.tels))));
      const currentContainers = yield select(makeSelectCurrentRouteContainers());
      yield put(A.setAvailableContainers(R.mergeRight(currentContainers, H.mapRouteContainers(data.tels))));
      const currentTels = currentRoute[GC.SYSTEM_LIST_TELS];
      const condition = R.or(
        R.equals(R.length(R.values(currentTels)), 0),
        R.and(
          R.equals(R.length(R.values(currentTels)), 1),
          R.equals(R.length(R.values(R.path([0, GC.FIELD_LOAD_STOPS], R.values(currentTels)))), 0),
        ),
      );

      if (condition) {
        yield put(A.setCurrentRoute(H.convertRouteResponseWithOrigRouteGuid(data)));
      } else {
        const incomeRoute = H.convertRouteResponseWithOrigRouteGuid(
          data,
          R.length(R.values(currentRoute[GC.SYSTEM_LIST_TELS])),
        );
        const newRoute = R.mergeRight(
          currentRoute,
          {
            linkedClos: R.mergeRight(incomeRoute.linkedClos, currentRoute.linkedClos),
            [GC.SYSTEM_LIST_TELS]: R.mergeRight(currentTels, incomeRoute[GC.SYSTEM_LIST_TELS]),
            [GC.SYSTEM_LIST_CLOS]: R.mergeRight(currentRoute[GC.SYSTEM_LIST_CLOS], incomeRoute[GC.SYSTEM_LIST_CLOS]),
          },
        );
        yield put(A.setCurrentRoute(newRoute));
      }

      yield call(handleRefreshCloListWithRouteClos);
      yield call(handleRefreshCrossDockCloListWithRouteClos);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetRouteByTelGuidAndMergeSaga fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetRouteByTelGuidAndMergeSaga exception');
  }
}

function* handleGetAvailableCloReportSaga({ params, currentBranchGuid, routeGuid, notCallLists }: Object) {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = checkReportFunction(data);
      const reportData = H.getInitialReportFromResponse(report, currentBranchGuid, routeGuid);

      if (R.not(reportData.report)) {
        yield put(A.getAvailableCloReportSuccess());
        return;
      }

      yield put(A.setAvailableCloReports(report));
      yield put(A.setCurrentCloReport(reportData.report));
      yield put(A.setCurrentCrossDockCloReport(reportData.report));

      if (R.is(Object, reportData.withSearchCriteria)) {
        yield call(handleGetActiveCloListSaga, reportData.withSearchCriteria);
        yield call(handleRefreshCrossDockCloListWithRouteClos);
      }

      if (R.or(G.isNotNil(routeGuid), R.not(G.isTrue(notCallLists)))) {
        yield call(handleGetCloListSaga, true);
      }

      yield put(A.getAvailableCloReportSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableCloReportSaga fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetAvailableCloReportSaga exception');
  }
}

function* handleCreateCloReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const routeGuid = yield select(makeSelectInitRouteGuid());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.set(R.lensProp('searchCriteria'), searchCriteria, payload);
    const params = {
      reportType: GC.CLO_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newPayload) };
    const res = yield call(sendRequest, 'post', endpointsMap.report, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(
        handleGetAvailableCloReportSaga,
        { params, currentBranchGuid, routeGuid, notCallLists: true },
      );
      yield put(A.setCurrentCloReport(data));
      const currentRoute = yield select(makeSelectCurrentRoute());

      if (G.isNotNilAndNotEmpty(currentRoute.clos)) {
        yield call(handleRefreshCloListWithRouteClos);
      } else {
        yield call(handleGetCloListSaga, true);
      }

      if (G.isNotNilAndNotEmpty(currentRoute.linkedClos)) {
        yield call(handleRefreshCrossDockCloListWithRouteClos);
      } else {
        yield call(handleGetCrossDockCloListSaga, true);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCloReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCloReportRequestSaga exception');
  }
}

function* handleUpdateCloReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const routeGuid = yield select(makeSelectInitRouteGuid());
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = { data: R.set(R.lensProp('searchCriteria'), searchCriteria, payload) };
    const res = yield call(sendRequest, 'put', endpointsMap.report, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
      const params = {
        reportType: GC.CLO_REPORT,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      };
      yield call(
        handleGetAvailableCloReportSaga,
        { params, currentBranchGuid, routeGuid, notCallLists: true },
      );
      const currentRoute = yield select(makeSelectCurrentRoute());

      if (G.isNotNilAndNotEmpty(currentRoute.clos)) {
        yield call(handleRefreshCloListWithRouteClos);
      } else {
        yield call(handleGetCloListSaga, true);
      }

      if (G.isNotNilAndNotEmpty(currentRoute.linkedClos)) {
        yield call(handleRefreshCrossDockCloListWithRouteClos);
      } else {
        yield call(handleGetCrossDockCloListSaga, true);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCloReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateCloReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetAvailableTelReportSaga({ params, currentBranchGuid, routeGuid, initRouteGuid }: Object) {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = checkReportFunction(data);
      const reportData = H.getInitialReportFromResponse(report, currentBranchGuid, routeGuid);

      if (R.not(reportData.report)) return;

      yield put(A.setAvailableTelReports(report));

      if (initRouteGuid) {
        yield put(A.setCurrentTelReport(reportData.report));
      } else {
        yield put(A.setCurrentTelReportWithRoute(reportData.report));
      }

      yield put(A.setCurrentInboundTelReport(reportData.report));
      yield put(A.setCurrentOutboundTelReport(reportData.report));

      if (R.is(Object, reportData.withSearchCriteria)) {
        yield call(handleGetActiveTelListSaga, reportData.withSearchCriteria);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableTelReportSaga fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetAvailableTelReportSaga exception');
  }
}

function* handleCreateTelReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const routeGuid = yield select(makeSelectInitRouteGuid());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = {
      reportType: GC.TEL_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.set(R.lensProp('searchCriteria'), searchCriteria, payload);
    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newPayload) };
    const res = yield call(sendRequest, 'post', endpointsMap.report, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(
        handleGetAvailableTelReportSaga,
        { params, currentBranchGuid, routeGuid },
      );
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTelReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTelReportRequestSaga exception');
  }
}

function* handleUpdateTelReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const routeGuid = yield select(makeSelectInitRouteGuid());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = {
      reportType: GC.TEL_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = { data: R.set(R.lensProp('searchCriteria'), searchCriteria, payload) };
    const res = yield call(sendRequest, 'put', endpointsMap.report, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(
        handleGetAvailableTelReportSaga,
        { params, currentBranchGuid, routeGuid },
      );
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTelReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateTelReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAvailableReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const routeGuid = yield select(makeSelectInitRouteGuid());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const cloParams = {
      reportType: GC.CLO_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const telParams = {
      reportType: GC.TEL_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const notOnlyClo = R.not(R.path(['onlyClo'], payload));

    yield put(A.setRouteBuilderInitialState());
    yield put(A.setInitialCurrentRoute(currentBranchGuid));

    if (G.isNotNilAndNotEmpty(routeGuid)) {
      yield call(handleGetRouteByGuid, routeGuid);
    }

    const openedFromPage = yield select(makeSelectOpenedFromPage());

    if (R.isNil(openedFromPage)) {
      yield fork(
        handleGetAvailableCloReportSaga,
        { params: cloParams, currentBranchGuid, routeGuid },
      );

      if (notOnlyClo) {
        yield fork(
          handleGetAvailableTelReportSaga,
          { params: telParams, currentBranchGuid, routeGuid, initRouteGuid: routeGuid },
        );
      }
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleAvailableReportRequestSaga exception');
  }
}

function* handleSaveRouteRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const {
      withRefresh,
      currentRoute,
      withCloseModal,
      onSaveCallback,
      withCloseOnSave,
      currentRouteItems,
      currentRouteContainers,
    } = payload;

    const openedFromPage = yield select(makeSelectOpenedFromPage());
    const telPrimaryRefAuto = yield select(makeSelectPrimaryReferenceAutogeneratedConfig());
    const initialRoute = yield select(makeSelectCurrentRouteInitialState());

    let data = H.transformCurrentRouteForRequest(
      currentRoute,
      currentRouteItems,
      telPrimaryRefAuto,
      initialRoute,
      currentRouteContainers,
    );

    if (G.isNotNilAndNotEmpty(onSaveCallback)) {
      data = R.assoc(GC.SYSTEM_LIST_TELS, R.map(R.omit([GC.FIELD_RATE]), data.tels), data);
    }

    if (G.isNilOrEmpty(data[GC.FIELD_BRANCH_GUID])) {
      data = R.assoc(
        GC.FIELD_BRANCH_GUID,
        yield select(makeSelectCurrentBranchGuid()),
        data,
      );
    }

    const isNotNewRoute = G.isNotNilAndNotEmpty(R.prop(GC.FIELD_GUID, data));
    const method = G.ifElse(isNotNewRoute, 'put', 'post');

    const res = yield call(sendRequest, method, endpointsMap.route, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const localeKey = G.ifElse(
        G.isNilOrEmpty(R.prop(GC.FIELD_GUID, data)),
        'toasts:success:create',
        'toasts:success:update',
      );

      if (R.equals(openedFromPage, 'DISPATCH_DETAILS_NEW')) {
        yield put(setDispatchPlannerOpened(false));
        yield put(A.setOpenedFromPage(null));
        yield put(getLoadDetailsRequest());

        return yield put(closeLoader());
      }

      if (withCloseModal) yield put(closeModal());

      if (G.isNotNilAndNotEmpty(onSaveCallback)) yield put(onSaveCallback());

      if (G.isTrue(withRefresh)) {
        yield put(A.setPreviousRouteState(currentRoute));
        yield put(A.setInitialRouteGuid(res.data));
        yield put(A.getAvailableReportsRequest());
      }

      if (G.isTrue(withCloseOnSave)) {
        const report = yield select(makeSelectTelReport());

        yield put(A.setCurrentTelReportWithRoute(report));

        const branchGuid = yield select(makeSelectCurrentBranchGuid());

        yield put(A.addNewTelToRouteBuilder({
          [GC.FIELD_BRANCH_GUID]: branchGuid,
          primaryReference: { value: `${G.getWindowLocale('titles:tel', 'Trip')}-1` },
        }));

        yield put(A.getTelNextPage(true));
      }

      yield put(closeLoader());

      yield call(G.showToastrMessage, 'success', localeKey);
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveRouteRequest fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleSaveRouteRequest exception');
  }
}

function* handleSaveDraftRouteRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { currentRoute, currentRouteItems } = payload;
    let data = H.transformCurrentRouteForRequest(currentRoute, currentRouteItems);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(data[GC.FIELD_BRANCH_GUID])) {
      data = R.assoc(
        GC.FIELD_BRANCH_GUID,
        currentBranchGuid,
        data,
      );
    }

    data = R.assoc(GC.FIELD_DRAFT_BRANCH_GUID, currentBranchGuid, data);
    const method = G.ifElse(G.isNilOrEmpty(R.prop('draftGuid', currentRoute)), 'post', 'put');
    const res = yield call(sendRequest, method, endpointsMap.draftRoute, { data });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const localeKey = G.ifElse(
        G.isNilOrEmpty(R.prop('draftGuid', currentRoute)),
        'toasts:success:create',
        'toasts:success:update',
      );
      yield call(G.showToastrMessage, 'success', localeKey);
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveDraftRouteRequest fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleSaveDraftRouteRequest exception');
  }
}

function* handleDeleteDraft({ payload }: Object) {
  try {
    const res = yield call(sendRequest, 'delete', endpointsMap.draftRoute, { params: { draftRouteGuid: payload } });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toasts:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDraft fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleDeleteDraft exception');
  }
}

function* handleRecalculateTelDistances({ payload }: Object) {
  try {
    const currentRoute = yield select(makeSelectCurrentRoute());

    const currentTel = R.path(['tels', payload], currentRoute);
    const loadType = R.prop('loadType', currentTel);
    const stopPoints = H.getLoadEditModeStopsGeodata(currentTel);

    if (R.gt(R.length(stopPoints), 1)) {
      const options = {
        data: {
          stopPoints,
          [GC.FIELD_BRANCH_GUID]: currentTel[GC.FIELD_BRANCH_GUID],
        },
      };

      const res = yield call(sendRequest, 'post', endpointsMap.distanceCalculation, options);

      const { data, status } = res;

      if (R.isNil(R.prop(['stopResults'], data))) return yield put(closeLoader());

      const events = H.mapLoadEventsWithDistances(currentTel, loadType, data);

      if (G.isResponseSuccess(status)) {
        yield put(A.recalculateTelDistancesSuccess({
          events,
          guid: currentTel.guid,
        }));
      }
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleRecalculateTelDistances exception');
  }
}

function* handleUpdateStopOnRouteBuilderSaga({ payload }: Object) {
  const { stop, telGuid } = payload;
  yield put(A.updateStopOnRouteBuilderTelSuccess(stop));

  if (G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop))) {
    const currentRoute = yield select(makeSelectCurrentRoute());
    const telGuids = R.keys(R.prop(GC.SYSTEM_LIST_TELS, currentRoute));
    yield all(telGuids.map((guid: string) => put(A.recalculateTelDistancesRequest(guid))));
  } else {
    yield put(A.recalculateTelDistancesRequest(telGuid));
  }
}

function* handleMoveTelStop({ guid, routeBuilderStore }: Object) {
  try {
    const currentRoute = R.path(['currentRoute'], routeBuilderStore);
    const currentTel = R.path(['tels', guid], currentRoute);
    const loadType = R.prop('loadType', currentTel);
    const stopPoints = H.getLoadEditModeStopsGeodata(currentTel);

    if (G.isNilOrEmpty(stopPoints)) return true;

    if (R.equals(R.length(stopPoints), 1)) {
      const events = H.mapLoadEventsWithDistances(currentTel, loadType, { stopResults: [] });

      yield put(A.recalculateTelDistancesWithMoveItemSuccess({
        events,
        routeBuilderStore,
        [GC.FIELD_GUID]: G.getGuidFromObject(currentTel),
      }));
    } else {
      const options = {
        data: {
          stopPoints,
          [GC.FIELD_BRANCH_GUID]: G.getBranchGuidFromObject(currentTel),
        },
      };

      const res = yield call(sendRequest, 'post', endpointsMap.distanceCalculation, options);

      const { data, status } = res;

      const stopResults = R.pathOr([], ['stopResults'], data);
      const events = H.mapLoadEventsWithDistances(currentTel, loadType, { stopResults });

      if (G.isResponseSuccess(status)) {
        yield put(A.recalculateTelDistancesWithMoveItemSuccess({
          events,
          routeBuilderStore,
          [GC.FIELD_GUID]: G.getGuidFromObject(currentTel),
        }));
      }
    }
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleMoveTelStop exception');
  }
}

function* handleRecalculateTelDistancesWithMoveStop({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    let routeBuilderStore = yield select(makeSelectBuilderStore());

    if (H.isEqualSourceAndDestinationLoad(payload)) {
      routeBuilderStore = H.reorderTelEventsInState(routeBuilderStore, payload);

      yield call(handleMoveTelStop, {
        routeBuilderStore,
        [GC.FIELD_GUID]: R.path(['source', 'droppableId'], payload),
      });
    } else {
      routeBuilderStore = H.moveEventFromTelToTel(routeBuilderStore, payload);

      const isSourceTelWithoutEvents = yield call(handleMoveTelStop, {
        routeBuilderStore,
        [GC.FIELD_GUID]: R.path(['source', 'droppableId'], payload),
      });

      if (R.not(isSourceTelWithoutEvents)) routeBuilderStore = yield select(makeSelectBuilderStore());

      yield call(handleMoveTelStop, {
        routeBuilderStore,
        [GC.FIELD_GUID]: R.path(['destination', 'droppableId'], payload),
      });
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleRecalculateTelDistancesWithMoveStop');
  }
}

function* handleChangeDefaultCloReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
      const params = {
        reportType: GC.CLO_REPORT,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      };
      yield call(
        handleGetAvailableCloReportSaga,
        {
          params,
          routeGuid: null,
          currentBranchGuid,
        },
      );
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultCloReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleChangeDefaultCloReportSaga exception');
    yield put(closeLoader());
  }
}

function* handleChangeDefaultTelReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
      const params = {
        reportType: GC.TEL_REPORT,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      };
      yield call(
        handleGetAvailableTelReportSaga,
        {
          params,
          routeGuid: null,
          currentBranchGuid,
        },
      );
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultTelReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleChangeDefaultTelReportSaga exception');
  }
}

function* getConfigsByNamesSaga({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;
    const options = {
      params: { names, [GC.FIELD_BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      yield call(G.setAmousConfigToWindow, mappedConfigs);
      const defaultCrossDocks = R.path(
        ['configs', GC.TEL_ROUTE_BUILDER_DEFAULT_CROSSDOCK, GC.FIELD_VALUE],
        mappedConfigs,
      );

      if (G.isNotNilAndNotEmpty(defaultCrossDocks)) {
        yield put(A.setInboundTerminals(defaultCrossDocks));
        yield put(A.setOutboundTerminals(defaultCrossDocks));
      }
      yield put(A.getConfigsByNamesSuccess(mappedConfigs));
    } else {
      yield call(G.handleFailResponse, res, 'getConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsByNamesSaga exception');
  }
}

function* handleDispatchTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, dispatchAction } = payload;
    const endpoint = endpointsMap.getTelActionEndpoint(guid, dispatchAction);
    const res = yield call(sendRequest, 'put', endpoint);
    const { status} = res;

    if (G.isResponseSuccess(status)) {
      const currentRoute = yield select(makeSelectCurrentRoute());
      yield put(A.setInitialRouteGuid(currentRoute[GC.FIELD_GUID]));
      yield put(A.getAvailableReportsRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleDispatchTelRateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDispatchTelRateSaga exception');
  }
}

function* handleDispatchCarrierTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.telCarrierRateDispatch;
    const res = yield call(sendRequest, 'put', endpoint, { data: payload });
    const { status } = res;
    yield put(closeModal());

    if (G.isResponseSuccess(status)) {
      const currentRoute = yield select(makeSelectCurrentRoute());
      yield put(A.setInitialRouteGuid(currentRoute[GC.FIELD_GUID]));
      yield put(A.getAvailableReportsRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleDispatchCarrierTelRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDispatchCarrierTelRateSaga  exception');
  }
}

// route template
function* handleSaveAsTemplateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { callback, templateGuid, deleteOriginalRoute } = payload;

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(templateGuid),
      'put',
      'post',
    );

    const res = yield call(sendRequest, method, endpointsMap.routeTemplate, { data: payload });

    const { data, status } = res;

    yield put(closeModal());

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toasts:success:create');

      if (G.isFunction(callback)) {
        // TODO: remove after customer testing
        // yield call(G.callFunctionWithArgs, callback, data);
      } else if (deleteOriginalRoute) {
        yield call(G.goToRoute, GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveAsTemplateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSaveAsTemplateSaga  exception');
  }
}

const CONFIGS_ARR = [
  // trailer
  GC.TRAILER_MANAGE_TRAILERS_FROM,
  // clo
  GC.CLO_GENERAL_USE_CONTAINERS,
  GC.CLO_GENERAL_USE_LINKED_ORDERS,
  // tel
  GC.TEL_PRIMARY_REFERENCE_TYPE,
  GC.TEL_PRIMARY_REFERENCE_PREFIX,
  GC.TEL_PRIMARY_REFERENCE_SEQUENCE,
  GC.TEL_PRIMARY_REFERENCE_COPY_FROM_CLO,
  GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
  GC.TEL_ROUTE_BUILDER_DEFAULT_CROSSDOCK,
  // general
  GC.GENERAL_SERVICES,
  GC.GENERAL_EQUIPMENTS,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_DRAFT_ROUTE_NAME_PREFIX,
  GC.GENERAL_ROUTE_NAME_AUTOGENERATED,
  GC.GENERAL_DRAFT_ROUTE_NAME_SEQUENCE,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  GC.GENERAL_DRAFT_ROUTE_NAME_AUTOGENERATED,
];

function* handleGetRouteBuilderSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    yield put(openLoader({ showDimmer: true }));
    yield put(A.getConfigsByNamesRequest({
      branchGuid,
      names: R.join(',', CONFIGS_ARR),
    }));
    yield put(A.getAvailableReportsRequest({ onlyClo: true }));
    yield take(A.getAvailableCloReportSuccess);
    yield put(A.getCurrentRouteByTelGuidRequest(payload));
    yield put(closeLoader());
    break;
  }
}

function* handleGetCrossDockLocationsSaga(branchGuid: string) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.crossDockLocationList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCrossDockLocationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCrossDockLocationsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetCrossDockLocationsSaga exception');
  }
}

function* handleAddTelToPlannerByGuid({ payload }: Object) {
  try {
    const currentRoute = yield select(makeSelectCurrentRoute());

    if (G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_LIST_TELS, payload], currentRoute))) return;

    const telList = yield select(makeSelectTelList());
    const exists = R.find(
      R.propEq(payload, GC.FIELD_GUID),
      R.or(telList, []),
    );

    if (R.isNil(exists)) {
      yield call(getReportTelByGuid, { payload });
    }

    yield put(A.addTelToPlanner(payload));
  } catch (error) {
    yield call(G.handleException, error, 'handleAddTelToPlannerByGuid exception');
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_ROUTE_BUILDER_PAGE);

    yield put(openLoader({ showDimmer: true }));

    const page = R.path(['location', 'pathname'], window);

    yield put(getAccessorialForConfigRequest());

    if (R.equals(page, GC.ROUTE_PATH_ROUTE_BUILDER)) {
      yield put(A.setOpenedFromPage(null));
    }

    const currentRouteItems = yield select(makeSelectCurrentRouteItems());
    const routeChanged = yield select(makeSelectRouteChanged());
    const routeGuid = yield select(makeSelectInitRouteGuid());

    if (G.isAllTrue(G.isNilOrEmpty(routeGuid), routeChanged, G.isNotNilAndNotEmpty(currentRouteItems))) {
      yield put(closeLoader());

      return;
    }

    yield put(A.resetAllListsAndPaginations());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield call(getConfigsByNamesSaga, { payload: {
      branchGuid,
      names: R.join(',', CONFIGS_ARR),
    } });

    yield call(handleAvailableReportRequestSaga, { payload: null });

    const currentRoute = yield select(makeSelectCurrentRoute());

    if (G.isNilOrEmpty(R.values(R.pathOr({}, ['tels'], currentRoute)))) {
      const autoTelPrimaryRef = yield select(makeSelectPrimaryReferenceAutogeneratedConfig());
      const routeGuid = yield select(makeSelectInitRouteGuid());

      if (R.and(G.isTrue(autoTelPrimaryRef), G.isNilOrEmpty(routeGuid))) {
        yield put(A.addNewTelToRouteBuilder({
          [GC.FIELD_BRANCH_GUID]: branchGuid,
          primaryReference: { value: `${G.getWindowLocale('titles:tel', 'Trip')}-1` },
        }));
      }
    }

    yield call(handleGetCrossDockLocationsSaga, branchGuid);

    yield put(getAllAvailableCloRefTypesRequest());
    yield put(getAllAvailableTelRefTypesRequest());

    yield put(closeLoader());

    break;
  }
}

function* handleOpenDispatchPlannerSaga() {
  try {
    yield put({
      type: GC.VISIT_ROUTE_BUILDER_PAGE,
      payload: {
        route: GC.ROUTE_PATH_ROUTE_BUILDER,
      },
    });
  } catch (error) {
    yield call(G.handleException, error, 'handleOpenDispatchPlannerSaga exception');
  }
}

function* dispatchPlannerWatcherSaga() {
  yield takeLatest(GC.VISIT_ROUTE_BUILDER_PAGE, handleVisitPageSaga);
  //
  yield takeLatest(A.deleteDraft, handleDeleteDraft);
  yield takeLatest(A.getCloNextPage, handleGetCloListSaga);
  yield takeLatest(A.getTelNextPage, handleGetTelListSaga);
  yield takeLatest(A.getReportTelByGuid, getReportTelByGuid);
  yield takeLatest(A.toggleCloDetails, handleToggleCloDetails);
  yield takeLatest(A.toggleTelDetails, handleToggleTelDetails);
  yield takeLatest(A.saveRouteRequest, handleSaveRouteRequest);
  yield takeLatest(A.getRouteBuilder, handleGetRouteBuilderSaga);
  yield takeLatest(A.getDraftListRequest, handleGetDraftListSaga);
  yield takeLatest(A.getConfigsByNamesRequest, getConfigsByNamesSaga);
  yield takeLatest(A.getCloDetailsRequest, handleGetCloDetailsRequest);
  yield takeLatest(A.dispatchTelRateRequest, handleDispatchTelRateSaga);
  yield takeLatest(A.saveDraftRouteRequest, handleSaveDraftRouteRequest);
  yield takeLatest(A.getCurrentRouteByGuidRequest, handleGetRouteByGuid);
  yield takeLatest(A.getInboundTelNextPage, handleGetInboundTelListSaga);
  yield takeLatest(A.selectCloForTelRequest, handleSelectCloForTelRequest);
  yield takeLatest(A.getOutboundTelNextPage, handleGetOutboundTelListSaga);
  yield takeLatest(A.addTelToPlanner, handleGetRouteByTelGuidAndMergeSaga);
  yield takeLatest(A.addTelToRouteBuilderByGuid, handleAddTelToPlannerByGuid);
  yield takeLatest(A.getCurrentRouteByTelGuidRequest, handleGetRouteByTelGuid);
  yield takeLatest(A.createCloReportRequest, handleCreateCloReportRequestSaga);
  yield takeLatest(A.updateCloReportRequest, handleUpdateCloReportRequestSaga);
  yield takeLatest(A.createTelReportRequest, handleCreateTelReportRequestSaga);
  yield takeLatest(A.updateTelReportRequest, handleUpdateTelReportRequestSaga);
  yield takeLatest(A.openDispatchPlannerRequest, handleOpenDispatchPlannerSaga);
  yield takeLatest(A.getAvailableReportsRequest, handleAvailableReportRequestSaga);
  yield takeEvery(A.recalculateTelDistancesRequest, handleRecalculateTelDistances);
  yield takeLatest(A.refreshCloListWithRouteClos, handleRefreshCloListWithRouteClos);
  yield takeLatest(A.getDraftRouteByDraftGuidRequest, handleGetDraftRouteByDraftGuid);
  yield takeLatest(A.changeDefaultCloReportRequest, handleChangeDefaultCloReportSaga);
  yield takeLatest(A.changeDefaultTelReportRequest, handleChangeDefaultTelReportSaga);
  yield takeLatest(A.dispatchCarrierTelRateRequest, handleDispatchCarrierTelRateSaga);
  yield takeLatest(A.updateStopOnRouteBuilderTelRequest, handleUpdateStopOnRouteBuilderSaga);
  yield takeLatest(A.recalculateTelDistancesWithMoveStopRequest, handleRecalculateTelDistancesWithMoveStop);
  // cross dock clos
  yield takeLatest(A.getCrossDockCloNextPage, handleGetCrossDockCloListSaga);
  yield takeLatest(A.selectCrossDockCloRequest, handleSelectCrossDockCloSaga);
  yield takeLatest(A.toggleCrossDockCloDetails, handleToggleCrossDockCloDetails);
  yield takeLatest(A.refreshCrossDockCloListWithRouteClos, handleRefreshCrossDockCloListWithRouteClos);
  // route template
  yield takeLatest(A.saveAsTemplateRequest, handleSaveAsTemplateSaga);
}

export default dispatchPlannerWatcherSaga;
