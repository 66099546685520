import * as R from 'ramda';
import { all, put, call, race, take, select, takeEvery, takeLatest } from 'redux-saga/effects';
// common
import { initialDataLoadFail, initialDataLoadSuccess } from '../../common/actions';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
import {
  makeSelectAccessToPagesList,
  makeSelectInitialDataLoadedStatus,
} from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature dashboard
import * as A from './actions';
import { makeSelectDashboardList, makeSelectDashboardCharts } from './selectors';
//////////////////////////////////////////////////

function* handleGetChartDataSaga({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        guid: payload.chart.guid,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.chartData, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const chartsDetails = yield select(makeSelectDashboardCharts());
      const index = R.findIndex(
        ({ chart }: Object) => R.equals(chart.guid, payload.chart.guid),
        chartsDetails,
      );
      yield put(A.getChartDataSuccess({ data, index }));
    } else {
      yield put(A.getChartDataFailed(payload));
      yield call(G.handleFailResponse, res, 'handleGetChartDataSaga fail', true);
    }
  } catch (error) {
    yield put(A.getChartDataFailed(payload));
    yield call(G.handleException, error, 'handleGetChartDataSaga exception');
  }
}

function* handleGetDashboardListSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.dashboardList);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const currentDashboard = R.or(
        R.find(R.propEq(true, 'defaultDashboard'), data),
        G.ifElse(R.isEmpty(data), {}, data[0]),
      );
      yield put(A.getDashboardListSuccess(data));
      yield put(A.setCurrentDashboard(currentDashboard));
      if (G.isNotNilAndNotEmpty(currentDashboard)) {
        yield all(currentDashboard.chartsDetails.map((chart: Object) => (
          put(A.getChartDataRequest(chart))
        )));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDashboardListSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetDashboardListSaga exception');
  }
}

function* handleGetChatByCollectionSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = yield call(sendRequest, 'get', endpointsMap.chartListByCollection, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getChartsByCollectionSuccess(data));
    } else {
      yield put(A.getChartsByCollectionFailed());
      yield call(G.handleFailResponse, res, 'handleGetChatByCollectionSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetChatByCollectionSaga exception');
  }
}

function* handleGetDashboardSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'get', endpointsMap.getDashboardEndpoint(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.cleanDashboardCharts());
      yield put(A.setCurrentDashboard(data));
      if (G.isNotNilAndNotEmpty(data)) {
        yield all(data.chartsDetails.map((chart: Object) => (
          put(A.getChartDataRequest(chart))
        )));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDashboardSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetDashboardSaga exception');
  }
}

function* handleCreateDashboardSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqData = R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload);
    const res = yield call(sendRequest, 'post', endpointsMap.dashboard, { data: reqData });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (R.isNil(payload.saveAsNew)) {
        yield put(A.cleanDashboardCharts());
      }
      yield put(A.createDashboardSuccess(data));
      yield put(A.setCurrentDashboard(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDashboardSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateDashboardSaga exception');
  }
}

function* handleUpdateDashboardSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const chartsDetails = yield select(makeSelectDashboardCharts());
    const dashboard = R.assoc('chartsDetails', chartsDetails, payload);
    const res = yield call(sendRequest, 'put', endpointsMap.dashboard, { data: dashboard });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const dashboards = yield select(makeSelectDashboardList());
      const index = R.findIndex(R.propEq(data.guid, 'guid'), dashboards);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.updateDashboardSuccess({ data, index }));
      yield put(A.setCurrentDashboard(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDashboardSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateDashboardSaga exception');
  }
}

function* handleDeleteDashboardSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'delete', endpointsMap.getDashboardEndpoint(payload));
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const dashboards = yield select(makeSelectDashboardList());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), dashboards);
      const newCurrentDashboard = R.or(R.remove(deletedIndex, 1, dashboards)[0], {});
      yield put(A.setCurrentDashboard(newCurrentDashboard));
      yield put(A.cleanDashboardCharts());
      if (G.isNotNilAndNotEmpty(newCurrentDashboard)) {
        yield all(newCurrentDashboard.chartsDetails.map((chart: Object) => (
          put(A.getChartDataRequest(chart))
        )));
      }
      yield put(A.deleteDashboardSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDashboardSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteDashboardSaga exception');
  }
}

function* handleVisitAnalyticsPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());

    const { pathname } = payload;

    const isAllowed = R.includes(pathname, accessToPagesList);

    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }

    if (R.not(isAllowed)) {
      yield put({
        payload: pathname,
        type: GC.CHECK_VISIT_ANALYTICS_PAGE,
      });

      yield take(GC.CHECK_VISIT_ANALYTICS_PAGE_ALLOWED);
    }

    yield put(A.cleanAnalyticState());
    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_CLO));
    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_TEL));

    yield call(handleGetDashboardListSaga);

    yield put(A.getChartsByCollectionRequest());

    break;
  }
}

export function* dashboardWatcherSaga() {
  yield takeEvery(A.getChartDataRequest, handleGetChartDataSaga);
  yield takeLatest(A.getDashboardRequest, handleGetDashboardSaga);
  yield takeLatest(A.createDashboardRequest, handleCreateDashboardSaga);
  yield takeLatest(A.updateDashboardRequest, handleUpdateDashboardSaga);
  yield takeLatest(A.deleteDashboardRequest, handleDeleteDashboardSaga);
  yield takeLatest(GC.VISIT_ANALYTICS_PAGE, handleVisitAnalyticsPageSaga);
  yield takeLatest(A.getDashboardListRequest, handleGetDashboardListSaga);
  yield takeLatest(A.getChartsByCollectionRequest, handleGetChatByCollectionSaga);
}

export default dashboardWatcherSaga;
