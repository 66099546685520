import * as R from 'ramda';
// components
import {
  getDriverTypeOptions,
  getTripStatusOptions,
  getOrderStatusOptions,
  getTemperatureTypeOptions,
  getTemperatureSensorOptions,
} from '../../../components/filter/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_TYPE_OPTIONS,
  getFreightClassOptions,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
// feature dispatch-report
import { FIELD_CLO_TOTAL_WEIGHT } from '../constants';
//////////////////////////////////////////////////

export const RC = {
  CLO_COUNT: 'cloCount',
  TEL_COUNT: 'telCount',
  ROUTE_NAME: `${GC.SYSTEM_OBJECT_ROUTE}.${GC.FIELD_NAME}`,
  PINNED_NOTE_TEXT: `${GC.FIELD_PINNED_NOTE}.${GC.FIELD_TEXT}`,
  EQUIPMENT: `${GC.FIELD_LOAD_EQUIPMENT}.${GC.FIELD_DISPLAYED_VALUE}`,
  PRIMARY_REFERENCE_NAME: `${GC.FIELD_PRIMARY_REFERENCE}.${GC.FIELD_NAME}`,
  PRIMARY_REFERENCE_VALUE: `${GC.FIELD_PRIMARY_REFERENCE}.${GC.FIELD_VALUE}`,
  ROUTE_TEMPLATE_NAME: `${GC.SYSTEM_OBJECT_ROUTE}.${GC.FIELD_TEMPLATE_NAME}`,
  LOAD_CANCEL_REASON: `${GC.FIELD_LOAD_CANCEL_REASON}.${GC.FIELD_DISPLAYED_VALUE}`,
  PINNED_NOTE_NOTE_TYPE: `${GC.FIELD_PINNED_NOTE}.noteType.${GC.FIELD_DISPLAYED_VALUE}`,
  MASTER_INVOICE_NUMBER: `${GC.SYSTEM_LIST_INVOICES}.${
    GC.SYSTEM_OBJECT_MASTER_INVOICE}.${GC.FIELD_MASTER_INVOICE_NUMBER}`,
  // bill to
  BILL_TO_ZIP: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ZIP}`,
  BILL_TO_CITY: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_CITY}`,
  BILL_TO_STATE: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_STATE}`,
  BILL_TO_COUNTRY: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COUNTRY}`,
  BILL_TO_COMMENTS: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COMMENTS}`,
  BILL_TO_ADDRESS1: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_1}`,
  BILL_TO_ADDRESS2: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_2}`,
  BILL_TO_PAYMENT_TERM: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_PAYMENT_TERM}`,
  BILL_TO_LOCATION_NAME: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_NAME}`,
  BILL_TO_LOCATION_TYPE_VALUE: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  // load board shipments
  LOAD_BOARD_SHIPMENTS_STATUS: `${GC.SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS}.${GC.FIELD_STATUS}`,
  LOAD_BOARD_SHIPMENTS_SOURCE: `${GC.SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS}.${GC.FIELD_SOURCE}`,
  LOAD_BOARD_SHIPMENTS_CREATED_BY: `${GC.SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS}.${GC.FIELD_CREATED_BY}`,
  LOAD_BOARD_SHIPMENTS_CREATED_DATE: `${GC.SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS}.${GC.FIELD_CREATED_DATE}`,
  LOAD_BOARD_SHIPMENTS_LAST_MODIFIED_BY: `${GC.SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS}.${GC.FIELD_LAST_MODIFIED_BY}`,
  // fleet assignment
  FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_LOGIN_ID}`,
  FLEET_ASSIGNMENT_PR_DRIVER_DRIVER_TYPE: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_DRIVER_TYPE}`,
  FLEET_ASSIGNMENT_PR_DRIVER_EMAIL: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_EMAIL}`,
  FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_FIRST_NAME}`,
  FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_LAST_NAME}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_LOGIN_ID}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_DRIVER_TYPE: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_DRIVER_TYPE}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_EMAIL}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_FIRST_NAME}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_LAST_NAME}`,
  FLEET_ASSIGNMENT_TRUCK_UNIT_ID: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.FIELD_TRUCK}.${GC.FIELD_TRUCK_UNIT_ID}`,
  FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.FIELD_TRUCK}.${GC.SYSTEM_OBJECT_FLEET_VENDOR}.${GC.FIELD_NAME}`,
  FLEET_ASSIGNMENT_TRAILERS_UNIT_ID: `${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${GC.SYSTEM_LIST_TRAILERS}.${
    GC.FIELD_TRUCK_UNIT_ID}`,
  // first event
  FIRST_EVENT_EARLY_DATE: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_EARLY_DATE}`,
  FIRST_EVENT_LATE_DATE: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_LATE_DATE}`,
  FIRST_EVENT_APPOINTMENT_EARLY_DATE: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_APPOINTMENT_EARLY_DATE}`,
  FIRST_EVENT_LOCATION_LOCATION_TYPE: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${
    GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  FIRST_EVENT_LOCATION_TEMPLATE_ID: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${
    GC.FIELD_TEMPLATE_ID}`,
  FIRST_EVENT_LOCATION_NAME: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
  FIRST_EVENT_LOCATION_ADDRESS1: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_1}`,
  FIRST_EVENT_LOCATION_ADDRESS2: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_2}`,
  FIRST_EVENT_LOCATION_CITY: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_CITY}`,
  FIRST_EVENT_LOCATION_STATE: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  FIRST_EVENT_LOCATION_COUNTRY: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_COUNTRY}`,
  FIRST_EVENT_LOCATION_ZIP: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ZIP}`,
  FIRST_EVENT_COMPLETE_DATE: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_EVENT_COMPLETE_DATE}`,
  // last event
  LAST_EVENT_LOCATION_LOCATION_TYPE: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${
    GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  LAST_EVENT_LOCATION_TEMPLATE_ID: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${
    GC.FIELD_TEMPLATE_ID}`,
  LAST_EVENT_LOCATION_NAME: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
  LAST_EVENT_LOCATION_ADDRESS1: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_1}`,
  LAST_EVENT_LOCATION_ADDRESS2: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_2}`,
  LAST_EVENT_LOCATION_CITY: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_CITY}`,
  LAST_EVENT_LOCATION_STATE: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  LAST_EVENT_LOCATION_COUNTRY: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_COUNTRY}`,
  LAST_EVENT_LOCATION_ZIP: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ZIP}`,
  LAST_EVENT_COMPLETE_DATE: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_EVENT_COMPLETE_DATE}`,
  LAST_EVENT_EARLY_DATE: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_EARLY_DATE}`,
  LAST_EVENT_LATE_DATE: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_LATE_DATE}`,
  LAST_EVENT_APPOINTMENT_EARLY_DATE: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_APPOINTMENT_EARLY_DATE}`,
  // carrier assignment
  CARRIER_ASSIGNMENT_PICKUP_NUMBER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_PICKUP_NUMBER}`,
  CARRIER_ASSIGNMENT_TRACKING_NUMBER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_TRACKING_NUMBER}`,
  CARRIER_ASSIGNMENT_INTEGRATION_ACCOUNT_NUMBER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_INTEGRATION_ACCOUNT_NUMBER}`,
  CARRIER_ASSIGNMENT_SNAPSHOT_NAME: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_NAME}`,
  CARRIER_ASSIGNMENT_SNAPSHOT_SCAC: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_CARRIER_SCAC}`,
  CARRIER_ASSIGNMENT_SNAPSHOT_US_DOT_NUMBER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_US_DOT_NUMBER}`,
  CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_CARRIER_MC_NUMBER}`,
  CARRIER_ASSIGNMENT_TRUCK: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_TRUCK}`,
  CARRIER_ASSIGNMENT_TRAILER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_TRAILER}`,
  CARRIER_ASSIGNMENT_PRIMARY_DRIVER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_PRIMARY_DRIVER}`,
  CARRIER_ASSIGNMENT_SECONDARY_DRIVER: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_SECONDARY_DRIVER}`,
  CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_PRIMARY_DRIVER_PHONE}`,
  CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_SECONDARY_DRIVER_PHONE}`,
  CARRIER_ASSIGNMENT_DRIVER_TRACKING_APP_INSTALLED: `${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_DRIVER_TRACKING_APP_INSTALLED}`,
  // items
  ITEMS_VIN: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_VIN}`,
  ITEMS_BAY: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_BAY}`,
  ITEMS_YEAR: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_YEAR}`,
  ITEMS_MAKE: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_MAKE}`,
  ITEMS_MODEL: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_MODEL}`,
  ITEMS_COLOR: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_COLOR}`,
  ITEMS_ITEM_ID: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_ID}`,
  ITEMS_ODOMETER: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ODOMETER}`,
  ITEMS_WHEELBASE: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_WHEELBASE}`,
  ITEMS_ITEM_TYPE: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_TYPE}`,
  ITEMS_SUB_MODEL: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_SUB_MODEL}`,
  ITEMS_ORIGIN_STATE: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ORIGIN_STATE}`,
  ITEMS_ODOMETER_UOM: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ODOMETER_UOM}`,
  ITEMS_MANUFACTURER: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_MANUFACTURER}`,
  ITEMS_WHEELBASE_UOM: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_WHEELBASE_UOM}`,
  ITEMS_LICENSE_PLATE: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_LICENSE_PLATE}`,
  ITEMS_ITEM_HAZARDOUS: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZARDOUS}`,
  ITEMS_ORIGIN_COUNTRY: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ORIGIN_COUNTRY}`,
  ITEMS_ITEM_HAZMAT_CLASS: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_CLASS}`,
  ITEMS_TEMPERATURE_TYPE: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_TEMPERATURE_TYPE}`,
  ITEMS_ITEM_HAZMAT_COMMENT: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_COMMENT}`,
  ITEMS_ITEM_HAZMAT_PLACARDS: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_PLACARDS}`,
  ITEMS_ITEM_HAZMAT_FLASHPOINT: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_FLASHPOINT}`,
  ITEMS_ITEM_HAZMAT_EMS_NUMBER: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_EMS_NUMBER}`,
  ITEMS_ITEM_HAZMAT_CONTACT_NAME: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_CONTACT_NAME}`,
  ITEMS_ITEM_HAZMAT_PACKAGE_GROUP: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_PACKAGE_GROUP}`,
  ITEMS_ITEM_HAZMAT_CONTACT_PHONE: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_CONTACT_PHONE}`,
  ITEMS_ITEM_HAZMAT_SHIPPING_NAME: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_SHIPPING_NAME}`,
  ITEMS_ITEM_HAZMAT_FLASHPOINT_UOM: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM}`,
  ITEMS_ITEM_HAZMAT_UN_OR_NA_NUMBER: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER}`,
  ITEMS_ITEM_HAZMAT_PLACARDS_DETAILS: `${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_HAZMAT_PLACARDS_DETAILS}`,
  // events items
  EVENTS_ITEMS_VIN: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_VIN}`,
  EVENTS_ITEMS_BAY: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_BAY}`,
  EVENTS_ITEMS_YEAR: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_YEAR}`,
  EVENTS_ITEMS_MAKE: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_MAKE}`,
  EVENTS_ITEMS_MODEL: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_MODEL}`,
  EVENTS_ITEMS_COLOR: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_COLOR}`,
  EVENTS_ITEMS_ITEM_ID: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_ID}`,
  EVENTS_ITEMS_ODOMETER: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ODOMETER}`,
  EVENTS_ITEMS_WHEELBASE: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_WHEELBASE}`,
  EVENTS_ITEMS_ITEM_TYPE: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_TYPE}`,
  EVENTS_ITEMS_SUB_MODEL: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_SUB_MODEL}`,
  EVENTS_ITEMS_ORIGIN_STATE: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ORIGIN_STATE}`,
  EVENTS_ITEMS_ODOMETER_UOM: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ODOMETER_UOM}`,
  EVENTS_ITEMS_MANUFACTURER: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_MANUFACTURER}`,
  EVENTS_ITEMS_WHEELBASE_UOM: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_WHEELBASE_UOM}`,
  EVENTS_ITEMS_LICENSE_PLATE: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_LICENSE_PLATE}`,
  EVENTS_ITEMS_ORIGIN_COUNTRY: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ORIGIN_COUNTRY}`,
  EVENTS_ITEMS_TEMPERATURE_TYPE: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_TEMPERATURE_TYPE}`,
  EVENTS_ITEMS_ITEM_FREIGHT_CLASS: `${GC.FIELD_LOAD_STOPS}.${
    GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_FREIGHT_CLASS}`,
  EVENTS_ITEMS_ITEM_DIMENSIONS_UOM: `${GC.FIELD_LOAD_STOPS}.${
    GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_DIMENSIONS_UOM}`,
  EVENTS_ITEMS_ITEM_DIMENSIONS_WIDTH: `${GC.FIELD_LOAD_STOPS}.${
    GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_DIMENSIONS_WIDTH}`,
  EVENTS_ITEMS_ITEM_DIMENSIONS_HEIGHT: `${GC.FIELD_LOAD_STOPS}.${
    GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_DIMENSIONS_HEIGHT}`,
  EVENTS_ITEMS_ITEM_DIMENSIONS_LENGTH: `${GC.FIELD_LOAD_STOPS}.${
    GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_DIMENSIONS_LENGTH}`,
  // events location
  EVENTS_LOCATION_STATE: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  EVENTS_LOCATION_NAME: `${GC.FIELD_LOAD_STOPS}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
  // clo
  CLO_STATUS: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_STATUS}`,
  CLO_SENDER_ID: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_SENDER_ID}`,
  CLO_EXTERNAL_ID: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_EXTERNAL_ID}`,
  CLO_EVENT_COUNT: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_EVENT_COUNT}`,
  CLO_PACKAGE_TYPE: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_PACKAGE_TYPE}`,
  CLO_INVOICE_COUNT: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_INVOICE_COUNT}`,
  CLO_BRANCH_NAME: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`,
  CLO_TIME_TO_NEXT_STOP: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_TIME_TO_NEXT_STOP}`,
  CLO_QUOTE_REQUEST_NUMBER: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_QUOTE_REQUEST_NUMBER}`,
  CLO_RATE_TOTAL: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_TOTAL}`,
  CLO_TOTAL_PICKUP_QUANTITY: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_TOTAL_PICKUP_QUANTITY}`,
  CLO_BRANCH_ACCOUNT_NAME: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`,
  CLO_PRIMARY_REFERENCE_VALUE: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`,
  CLO_SERVICES: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_LOAD_SERVICES}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLO_EQUIPMENT: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_LOAD_EQUIPMENT}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLO_CONTAINERS_NUMBER: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_CONTAINERS}.${GC.FIELD_CONTAINER_NUMBER}`,
  CLO_PRIMARY_REFERENCE_NAME: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_PRIMARY_REFERENCE}.${GC.FIELD_NAME}`,
  CLO_ITEMS_FREIGHT_CLASS: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_FREIGHT_CLASS}`,
  CLO_RATE_MAIN_CHARGES_TOTAL: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_MAIN_CHARGES_TOTAL}`,
  CLO_RATE_FUEL_CHARGES_TOTAL: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_FUEL_CHARGES_TOTAL}`,
  CLO_INVOICES_INTEGRATED_DATE: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_LIST_INVOICES}.${GC.FIELD_INTEGRATED_DATE}`,
  CLO_RATE_ADDITIONAL_CHARGES_TOTAL: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_ADDITIONAL_CHARGES_TOTAL}`,
  // clos
  CLOS_TEMPLATE_NAME: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_TEMPLATE_NAME}`,
  CLOS_RATE_NORMALIZED_TOTAL_TOTAL: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_RATE}.${
    GC.SYSTEM_OBJECT_NORMALIZED_TOTAL}.${GC.FIELD_TOTAL}`,
  CLOS_STORED_TOTAL_DISTANCE: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_STORED_TOTAL_DISTANCE}`,
  CLOS_RATE_STORED_TOTAL_TRIP_DISTANCE: `${GC.SYSTEM_LIST_CLOS}.${GC.FIELD_RATE}.${
    GC.FIELD_STORED_TOTAL_TRIP_DISTANCE}`,
  // clos event
  CLOS_FIRST_EVENT_LOCATION_LOCATION_TYPE: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_FIRST_EVENT}.${
    GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLOS_FIRST_EVENT_LOCATION_TEMPLATE_ID: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_FIRST_EVENT}.${
    GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_TEMPLATE_ID}`,
  CLOS_FIRST_EVENT_LOCATION_NAME: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
  CLOS_FIRST_EVENT_LOCATION_ADDRESS1: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_1}`,
  CLOS_FIRST_EVENT_LOCATION_CITY: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_CITY}`,
  CLOS_FIRST_EVENT_LOCATION_STATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  CLOS_FIRST_EVENT_LOCATION_COUNTRY: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_COUNTRY}`,
  CLOS_FIRST_EVENT_LOCATION_ZIP: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ZIP}`,
  CLOS_FIRST_EVENT_COMPLETE_DATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_EVENT_COMPLETE_DATE}`,
  CLOS_LAST_EVENT_LOCATION_LOCATION_TYPE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${
    GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLOS_LAST_EVENT_LOCATION_TEMPLATE_ID: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_TEMPLATE_ID}`,
  CLOS_LAST_EVENT_LOCATION_NAME: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
  CLOS_LAST_EVENT_LOCATION_ADDRESS1: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_1}`,
  CLOS_LAST_EVENT_LOCATION_CITY: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_CITY}`,
  CLOS_LAST_EVENT_LOCATION_STATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  CLOS_LAST_EVENT_LOCATION_COUNTRY: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_COUNTRY}`,
  CLOS_LAST_EVENT_LOCATION_ZIP: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ZIP}`,
  CLOS_LAST_EVENT_COMPLETE_DATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_EVENT_COMPLETE_DATE}`,
  CLOS_FIRST_EVENT_EARLY_DATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_EARLY_DATE}`,
  CLOS_FIRST_EVENT_LATE_DATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_LATE_DATE}`,
  CLOS_FIRST_EVENT_APPOINTMENT_EARLY_DATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_APPOINTMENT_EARLY_DATE}`,
  CLOS_LAST_EVENT_EARLY_DATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_EARLY_DATE}`,
  CLOS_LAST_EVENT_LATE_DATE: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_LATE_DATE}`,
  CLOS_LAST_EVENT_APPOINTMENT_EARLY_DATE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_APPOINTMENT_EARLY_DATE}`,
  // clo bill to
  CLO_BILL_TO_ZIP: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ZIP}`,
  CLO_BILL_TO_CITY: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_CITY}`,
  CLO_BILL_TO_STATE: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_STATE}`,
  CLO_BILL_TO_COUNTRY: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COUNTRY}`,
  CLO_BILL_TO_COMMENTS: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COMMENTS}`,
  CLO_BILL_TO_ADDRESS1: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_1}`,
  CLO_BILL_TO_ADDRESS2: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_2}`,
  CLO_BILL_TO_PAYMENT_TERM: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_PAYMENT_TERM}`,
  CLO_BILL_TO_LOCATION_NAME: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_NAME}`,
  CLO_BILL_TO_LOCATION_TYPE_VALUE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  // tel
  TEL_STATUS: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_STATUS}`,
  TEL_EVENT_COUNT: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_EVENT_COUNT}`,
  TEL_PACKAGE_TYPE: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_PACKAGE_TYPE}`,
  TEL_INVOICE_COUNT: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_INVOICE_COUNT}`,
  TEL_BRANCH_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`,
  TEL_TIME_TO_NEXT_STOP: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_TIME_TO_NEXT_STOP}`,
  TEL_RATE_TOTAL: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_TOTAL}`,
  TEL_TOTAL_PICKUP_QUANTITY: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_TOTAL_PICKUP_QUANTITY}`,
  TEL_BRANCH_ACCOUNT_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`,
  TEL_PRIMARY_REFERENCE_VALUE: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_PRIMARY_REFERENCE_VALUE}`,
  TEL_SERVICES: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_LOAD_SERVICES}.${GC.FIELD_DISPLAYED_VALUE}`,
  TEL_EQUIPMENT: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_LOAD_EQUIPMENT}.${GC.FIELD_DISPLAYED_VALUE}`,
  TEL_PRIMARY_REFERENCE_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_PRIMARY_REFERENCE}.${GC.FIELD_NAME}`,
  TEL_RATE_FUEL_CHARGES_TOTAL: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_FUEL_CHARGES_TOTAL}`,
  TEL_RATE_MAIN_CHARGES_TOTAL: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_MAIN_CHARGES_TOTAL}`,
  TEL_RATE_ADDITIONAL_CHARGES_TOTAL: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_ADDITIONAL_CHARGES_TOTAL}`,
  // tels
  TELS_GUID: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_GUID}`,
  TELS_BRANCH_BRANCH_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_BRANCH}.${GC.FIELD_BRANCH_NAME}`,
  TELS_CARRIER_INTEGRATION_ACCOUNT_NUMBER: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_INTEGRATION_ACCOUNT_NUMBER}`,
  TELS_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_LOGIN_ID}`,
  TELS_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_EMAIL}`,
  TELS_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_FIRST_NAME}`,
  TELS_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_PRIMARY_DRIVER}.${GC.FIELD_FLEET_LAST_NAME}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_LOGIN_ID}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_EMAIL}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_FIRST_NAME}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_OBJECT_SECONDARY_DRIVER}.${GC.FIELD_FLEET_LAST_NAME}`,
  TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.FIELD_TRUCK}.${GC.FIELD_TRUCK_UNIT_ID}`,
  TELS_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.FIELD_TRUCK}.${GC.SYSTEM_OBJECT_FLEET_VENDOR}.${GC.FIELD_NAME}`,
  TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    GC.SYSTEM_LIST_TRAILERS}.${GC.FIELD_TRUCK_UNIT_ID}`,
  TELS_RATE_NORMALIZED_TOTAL_TOTAL: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_RATE}.${
    GC.SYSTEM_OBJECT_NORMALIZED_TOTAL}.${GC.FIELD_TOTAL}`,
  TELS_CARRIER_ASSIGNMENT_SNAPSHOT_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_NAME}`,
  TELS_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_CARRIER_SCAC}`,
  TELS_CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_CARRIER_MC_NUMBER}`,
  TELS_CARRIER_ASSIGNMENT_SNAPSHOT_ADDRESS: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_ADDRESS}`,
  TELS_CARRIER_ASSIGNMENT_SNAPSHOT_EMAILS_STRING: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.emailsString`,
  TELS_CARRIER_ASSIGNMENT_SNAPSHOT_FEDERAL_EIN: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_ROUTE_CARRIER_SNAPSHOT}.${GC.FIELD_FEDERAL_EIN}`,
  TELS_CARRIER_ASSIGNMENT_TRUCK: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_TRUCK}`,
  TELS_CARRIER_ASSIGNMENT_TRAILER: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${GC.FIELD_TRAILER}`,
  TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_PRIMARY_DRIVER}`,
  TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_SECONDARY_DRIVER}`,
  TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_PRIMARY_DRIVER_PHONE}`,
  TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    GC.FIELD_SECONDARY_DRIVER_PHONE}`,
  TELS_STORED_TOTAL_DISTANCE: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_STORED_TOTAL_DISTANCE}`,
  TELS_RATE_STORED_TOTAL_TRIP_DISTANCE: `${GC.SYSTEM_LIST_TELS}.${GC.FIELD_RATE}.${
    GC.FIELD_STORED_TOTAL_TRIP_DISTANCE}`,
  // tels event
  TELS_FIRST_EVENT_LOCATION_LOCATION_TYPE: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FIRST_EVENT}.${
    GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  TELS_FIRST_EVENT_LOCATION_TEMPLATE_ID: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_FIRST_EVENT}.${
    GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_TEMPLATE_ID}`,
  TELS_FIRST_EVENT_LOCATION_NAME: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
  TELS_FIRST_EVENT_LOCATION_ADDRESS1: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_1}`,
  TELS_FIRST_EVENT_LOCATION_CITY: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_CITY}`,
  TELS_FIRST_EVENT_LOCATION_STATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  TELS_FIRST_EVENT_LOCATION_COUNTRY: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_COUNTRY}`,
  TELS_FIRST_EVENT_LOCATION_ZIP: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ZIP}`,
  TELS_FIRST_EVENT_COMPLETE_DATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_EVENT_COMPLETE_DATE}`,
  TELS_LAST_EVENT_LOCATION_LOCATION_TYPE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  TELS_LAST_EVENT_LOCATION_TEMPLATE_ID: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_TEMPLATE_ID}`,
  TELS_LAST_EVENT_LOCATION_NAME: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
  TELS_LAST_EVENT_LOCATION_ADDRESS1: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ADDRESS_1}`,
  TELS_LAST_EVENT_LOCATION_CITY: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_CITY}`,
  TELS_LAST_EVENT_LOCATION_STATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_STATE}`,
  TELS_LAST_EVENT_LOCATION_COUNTRY: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_COUNTRY}`,
  TELS_LAST_EVENT_LOCATION_ZIP: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.SYSTEM_OBJECT_LOCATION}.${GC.FIELD_ZIP}`,
  TELS_LAST_EVENT_COMPLETE_DATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_EVENT_COMPLETE_DATE}`,
  TELS_FIRST_EVENT_EARLY_DATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_EARLY_DATE}`,
  TELS_FIRST_EVENT_LATE_DATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_LATE_DATE}`,
  TELS_FIRST_EVENT_APPOINTMENT_EARLY_DATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_APPOINTMENT_EARLY_DATE}`,
  TELS_LAST_EVENT_EARLY_DATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_EARLY_DATE}`,
  TELS_LAST_EVENT_LATE_DATE: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_LATE_DATE}`,
  TELS_LAST_EVENT_APPOINTMENT_EARLY_DATE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_APPOINTMENT_EARLY_DATE}`,
  // tel bill to
  TEL_BILL_TO_ZIP: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ZIP}`,
  TEL_BILL_TO_CITY: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_CITY}`,
  TEL_BILL_TO_STATE: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_STATE}`,
  TEL_BILL_TO_COUNTRY: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COUNTRY}`,
  TEL_BILL_TO_COMMENTS: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COMMENTS}`,
  TEL_BILL_TO_ADDRESS1: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_1}`,
  TEL_BILL_TO_ADDRESS2: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_2}`,
  TEL_BILL_TO_PAYMENT_TERM: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_PAYMENT_TERM}`,
  TEL_BILL_TO_LOCATION_NAME: `${GC.SYSTEM_LIST_TELS}.${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_NAME}`,
  TEL_BILL_TO_LOCATION_TYPE_VALUE: `${GC.SYSTEM_LIST_TELS}.${
    GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  // tel invoices
  TEL_FLEET_INVOICES_INTEGRATION_DATE: `${GC.SYSTEM_LIST_TELS}.fleetInvoices.${GC.FIELD_INTEGRATED_DATE}`,
  TEL_CARRIER_INVOICES_INTEGRATION_DATE: `${GC.SYSTEM_LIST_TELS}.carrierInvoices.${GC.FIELD_INTEGRATED_DATE}`,
  TEL_FLEET_VENDOR_INVOICES_INTEGRATION_DATE: `${GC.SYSTEM_LIST_TELS}.fleetVendorInvoices.${GC.FIELD_INTEGRATED_DATE}`,
  // rate
  RATE_TOTAL: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_TOTAL}`,
  RATE_SOURCE: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_SOURCE}`,
  RATE_CURRENCY: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_CURRENCY}`,
  RATE_MODE: `${GC.SYSTEM_OBJECT_RATE}.${GC.GRC.MODE_DISPLAYED_VALUE}`,
  RATE_SERVICE_DAYS: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_SERVICE_DAYS}`,
  RATE_DEADHEAD_DISTANCE: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_DEADHEAD_DISTANCE}`,
  RATE_FUEL_CHARGES_TOTAL: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_FUEL_CHARGES_TOTAL}`,
  RATE_MAIN_CHARGES_TOTAL: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_MAIN_CHARGES_TOTAL}`,
  RATE_DISPATCHED_BY: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_DISPATCHED_OR_CANCEL_BY}`,
  RATE_DISPATCHED_DATE: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_DISPATCH_OR_CANCEL_DATE}`,
  RATE_DISCOUNT_CHARGES_TOTAL: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_DISCOUNT_CHARGES_TOTAL}`,
  RATE_STORED_DEADHEAD_DISTANCE: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_STORED_DEADHEAD_DISTANCE}`,
  RATE_ADDITIONAL_CHARGES_TOTAL: `${GC.SYSTEM_OBJECT_RATE}.${GC.FIELD_ADDITIONAL_CHARGES_TOTAL}`,
  RATE_NORMALIZED_TOTAL_TOTAL: `${GC.SYSTEM_OBJECT_RATE}.${GC.SYSTEM_OBJECT_NORMALIZED_TOTAL}.${GC.FIELD_TOTAL}`,
  RATE_NORMALIZED_TOTAL_CURRENCY: `${GC.SYSTEM_OBJECT_RATE}.${GC.SYSTEM_OBJECT_NORMALIZED_TOTAL}.${GC.FIELD_CURRENCY}`,
  // event containers
  FIRST_EVENT_CONTAINER_NUMBER: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_NUMBER}`,
  LAST_EVENT_CONTAINER_NUMBER: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_NUMBER}`,
  NEXT_EVENT_CONTAINER_NUMBER: `${GC.SYSTEM_OBJECT_NEXT_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_NUMBER}`,
  FIRST_EVENT_CONTAINER_INITIAL: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_INITIAL}`,
  LAST_EVENT_CONTAINER_INITIAL: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_INITIAL}`,
  NEXT_EVENT_CONTAINER_INITIAL: `${GC.SYSTEM_OBJECT_NEXT_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_INITIAL}`,
  FIRST_EVENT_CONTAINER_NUMBER_WITH_INITIAL: `${GC.SYSTEM_OBJECT_FIRST_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_NUMBER_WITH_INITIAL}`,
  LAST_EVENT_CONTAINER_NUMBER_WITH_INITIAL: `${GC.SYSTEM_OBJECT_LAST_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_NUMBER_WITH_INITIAL}`,
  NEXT_EVENT_CONTAINER_NUMBER_WITH_INITIAL: `${GC.SYSTEM_OBJECT_NEXT_EVENT}.${GC.FIELD_CONTAINERS}.${
    GC.FIELD_CONTAINER}.${GC.FIELD_CONTAINER_NUMBER_WITH_INITIAL}`,
  // claim
  CLAIM_GUID: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_GUID}`,
  CLAIM_CLAIM_DATE: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_DATE}`,
  CLAIM_CLAIM_NUMBER: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}`,
  CLAIM_CLAIM_TYPE: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLAIM_CLAIM_REASON: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_REASON}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLAIM_CLAIM_STATUS: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLAIM_CLAIM_SUB_STATUS: `${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_SUB_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`,
  // clos claim
  CLOS_CLAIM_CLAIM_DATE: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_DATE}`,
  CLOS_CLAIM_CLAIM_NUMBER: `${GC.SYSTEM_LIST_CLOS}.${GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_CLAIM_NUMBER}`,
  CLOS_CLAIM_CLAIM_TYPE: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLOS_CLAIM_CLAIM_REASON: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_REASON}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLOS_CLAIM_CLAIM_STATUS: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`,
  CLOS_CLAIM_CLAIM_SUB_STATUS: `${GC.SYSTEM_LIST_CLOS}.${
    GC.SYSTEM_OBJECT_CLAIM}.${GC.FIELD_SUB_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`,
};

const firstFilterProps = [
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_ACCOUNT_NAME,
    name: G.getWindowLocale('titles:account-number', 'Account Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    type: 'string',
    value: GC.GRC.CUSTOM_STATUS_NAME,
    name: G.getWindowLocale('titles:custom-status', 'Custom Status'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_HOT,
    name: G.getWindowLocale('titles:hot-load', 'Hot Load'),
  },
  {
    type: 'string',
    value: RC.LOAD_CANCEL_REASON,
    name: G.getWindowLocale('titles:load-cancel-reason', 'Load Cancel Reason'),
  },
  {
    type: 'string',
    value: RC.ROUTE_NAME,
    name: G.getWindowLocale('titles:route-name', 'Route Name'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.SERVICES,
    name: G.getWindowLocale('titles:services', 'Services'),
  },
  {
    type: 'string',
    value: RC.BILL_TO_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_ADDRESS2,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')}`,
  },
  {
    type: 'string',
    value: RC.BILL_TO_LOCATION_TYPE_VALUE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: RC.PRIMARY_REFERENCE_NAME,
    name: G.getWindowLocale('titles:primary-ref-name', 'Primary Reference Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value'),
  },
  {
    type: 'string',
    value: GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS,
    name: G.getWindowLocale('titles:special-instructions', 'Special Instructions'),
  },
  {
    type: 'string',
    value: GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS,
    name: G.getWindowLocale('titles:internal-instructions', 'Internal Instructions'),
  },
  {
    type: 'string',
    value: RC.PINNED_NOTE_TEXT,
    name: `${G.getWindowLocale('titles:pinned-note', 'Pinned Note')}: ${
      G.getWindowLocale('titles:text', 'Text')}`,
  },
  {
    type: 'number',
    value: GC.GRC.RATE_SERVICE_DAYS,
    name: G.getWindowLocale('titles:service-days', 'Service Days'),
  },
  {
    type: 'string',
    value: GC.GRC.RATE_NORMALIZED_TOTAL_CURRENCY,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
      G.getWindowLocale('titles:normalized-currency', 'Normalized Currency')}`,
  },
  {
    type: 'number',
    value: GC.GRC.RATE_NORMALIZED_TOTAL,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
      G.getWindowLocale('titles:normalized-total', 'Normalized Total')}`,
  },
];

const secondFilterProps = [
  {
    type: 'number',
    value: GC.FIELD_EVENT_COUNT,
    name: G.getWindowLocale('titles:event-count', 'Event Count'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_MAIN_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:linehaul', 'Linehaul'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_FUEL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:fuel', 'Fuel'),
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.RATE_FUEL_CHARGES_RATE,
    name: `${G.getWindowLocale('titles:fuel', 'Fuel')}: ${
      G.getWindowLocale('titles:rate', 'Rate')}`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.RATE_FUEL_CHARGES_QUANTITY,
    name: `${G.getWindowLocale('titles:fuel', 'Fuel')}: ${
      G.getWindowLocale('titles:quantity', 'Quantity')}`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_PRICE,
    name: `${G.getWindowLocale('titles:fuel-index', 'Fuel Index')}: ${
      G.getWindowLocale('titles:price', 'Price')}`,
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_PRICE_DATE,
    name: `${G.getWindowLocale('titles:fuel-index', 'Fuel Index')}: ${
      G.getWindowLocale('titles:date', 'Date')}`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_RATE,
    name: `${G.getWindowLocale('titles:fuel-index', 'Fuel Index')}: ${
      G.getWindowLocale('titles:rate', 'Rate')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_DISTRICT,
    name: `${G.getWindowLocale('titles:fuel-index', 'Fuel Index')}: ${
      G.getWindowLocale('titles:district', 'District')}`,
  },
  {
    type: 'number',
    value: GC.GRC.RATE_ADDITIONAL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:other-accessorials', 'Other Accessorials'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_TOTAL,
    name: G.getWindowLocale('titles:total-rate', 'Total Rate'),
  },
  {
    type: 'string',
    value: GC.GRC.RATE_MODE,
    name: G.getWindowLocale('titles:mode', 'Mode'),
  },
  {
    type: 'string',
    value: GC.GRC.RATE_CURRENCY,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
      G.getWindowLocale('titles:currency', 'Currency')}`,
  },
  {
    type: 'number',
    value: GC.FIELD_MARGIN,
    name: G.getWindowLocale('titles:margin', 'Margin'),
  },
  {
    type: 'number',
    value: GC.FIELD_INVOICE_COUNT,
    name: G.getWindowLocale('titles:invoice-count', 'Invoice Count'),
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FIRST_EVENT_PICKUP_NUMBER,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:pick-up-number', 'Pick Up Number')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: RC.FIRST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Tempate ID')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: RC.LAST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:template-id', 'Tempate ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.NEXT_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: RC.FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: RC.FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_CHECK_IN_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:check-in-date', 'Check In Date')}`,
  },
  {
    type: 'date',
    value: RC.LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: RC.LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: RC.LAST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_CHECK_IN_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:check-in-date', 'Check In Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.NEXT_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.NEXT_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.NEXT_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.NEXT_EVENT_CHECK_IN_DATE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:check-in-date', 'Check In Date')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.FIRST_EVENT_CONTAINER_NUMBER,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:container-number', 'Container Number')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.FIRST_EVENT_CONTAINER_INITIAL,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:container-initial', 'Container Initial')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.FIRST_EVENT_CONTAINER_NUMBER_WITH_INITIAL,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:container-number-with-initial', 'Container Number With Initial')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.LAST_EVENT_CONTAINER_NUMBER,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:container-number', 'Container Number')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.LAST_EVENT_CONTAINER_INITIAL,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:container-initial', 'Container Initial')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.LAST_EVENT_CONTAINER_NUMBER_WITH_INITIAL,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:container-number-with-initial', 'Container Number With Initial')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.NEXT_EVENT_CONTAINER_NUMBER,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:container-number', 'Container Number')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.NEXT_EVENT_CONTAINER_INITIAL,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:container-initial', 'Container Initial')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.NEXT_EVENT_CONTAINER_NUMBER_WITH_INITIAL,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:container-number-with-initial', 'Container Number With Initial')}`,
  },
  {
    type: 'number',
    value: GC.FIELD_STORED_TOTAL_WEIGHT,
    name: G.getWindowLocale('titles:total-weight', 'Total Weight'),
  },
  {
    type: 'number',
    value: GC.FIELD_STORED_TOTAL_VOLUME,
    name: G.getWindowLocale('titles:total-volume', 'Total Volume'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_PICKUP_QUANTITY,
    name: G.getWindowLocale('titles:quantity', 'Quantity'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    name: G.getWindowLocale('titles:package-type', 'Package Type'),
  },
];

const telsDOFilterProps = [
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TELS_FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: RC.TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')} `,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:vendor', 'Vendor')} `,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
    name: `${G.getWindowLocale('titles:trailers', 'Trailers')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_CARRIER_TRACKING_NUMBER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:tracking-number', 'Tracking Number')
    }`,
  },
];

const fleetDMFilterProps = [
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'selectMultiple',
    options: getDriverTypeOptions(),
    value: RC.FLEET_ASSIGNMENT_PR_DRIVER_DRIVER_TYPE,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:driver-type', 'Driver Type')}`,
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_PR_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'selectMultiple',
    options: getDriverTypeOptions(),
    value: RC.FLEET_ASSIGNMENT_SEC_DRIVER_DRIVER_TYPE,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:driver-type', 'Driver Type')}`,
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')} `,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:vendor', 'Vendor')} `,
  },
  {
    type: 'string',
    collection: true,
    value: RC.FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
    name: `${G.getWindowLocale('titles:trailers', 'Trailers')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.FLEET_ASSIGNMENT_CURRENT_TRAILERS_UNIT_ID,
    name: `${G.getWindowLocale('titles:current-trailers', 'Current Trailers')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_TRACKING_NUMBER,
    name: G.getWindowLocale('titles:tracking-number', 'Tracking Number'),
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_PICKUP_NUMBER,
    name: G.getWindowLocale('titles:pickup-number', 'Pickup Number'),
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_INTEGRATION_ACCOUNT_NUMBER,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
      G.getWindowLocale('titles:integration-account-number', 'Integration Account Number')
    }`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_TRUCK,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:truck', 'Truck')}`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_TRAILER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:trailer', 'Trailer')}`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_PRIMARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_SECONDARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  // carrier invoices
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_NUMBER,
    name: G.getWindowLocale('titles:carrier-invoice-number', 'Carrier Invoice #'),
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_TOTAL,
    name: G.getWindowLocale('titles:carrier-invoice-total', 'Carrier Invoice Total'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_STATUS,
    name: G.getWindowLocale('titles:carrier-invoice-status', 'Carrier Invoice Status'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_DATE,
    name: G.getWindowLocale('titles:carrier-invoice-date', 'Carrier Invoice Date'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_DEPOSIT_DATE,
    name: G.getWindowLocale('titles:carrier-deposit-date', 'Carrier Deposit Date'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_CHECK_DATE,
    name: G.getWindowLocale('titles:carrier-check-date', 'Carrier Check Date'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_CHECK_NUMBER,
    name: G.getWindowLocale('titles:carrier-check-number', 'Carrier Check Number'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_INTEGRATION_DATE,
    name: G.getWindowLocale('titles:carrier-integrated-date', 'Carrier Integrated Date'),
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_NORMALIZED_TOTAL,
    name: `${G.getWindowLocale('titles:carrier-invoice', 'Carrier Invoice')}: ${
      G.getWindowLocale('titles:normalized-total', 'Normalized Total')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_INVOICES_NORMALIZED_CURRENCY,
    name: `${G.getWindowLocale('titles:carrier-invoice', 'Carrier Invoice')}: ${
      G.getWindowLocale('titles:normalized-currency', 'Normalized Currency')}`,
  },
  // fleet invoices
  {
    type: 'number',
    collection: true,
    value: GC.GRC.FLEET_INVOICES_NORMALIZED_TOTAL,
    name: `${G.getWindowLocale('titles:fleet-invoice', 'Fleet Invoice')}: ${
      G.getWindowLocale('titles:normalized-total', 'Normalized Total')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.FLEET_INVOICES_NORMALIZED_CURRENCY,
    name: `${G.getWindowLocale('titles:fleet-invoice', 'Fleet Invoice')}: ${
      G.getWindowLocale('titles:normalized-currency', 'Normalized Currency')}`,
  },
  //
  {
    type: 'string',
    value: GC.FIELD_INVOICE_TOTAL,
    name: `${G.getWindowLocale('titles:invoices')}: ${G.getWindowLocale('titles:total')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_INVOICE_CHECK_AMOUNT,
    name: `${G.getWindowLocale('titles:invoices')}: ${G.getWindowLocale('titles:check-amount')}`,
  },
];

const DOFilterProps = [
  ...firstFilterProps,
  ...telsDOFilterProps,
  ...secondFilterProps,
];

const DMFilterProps = [
  ...firstFilterProps,
  ...fleetDMFilterProps,
  ...secondFilterProps,
];

const loadUniqFilterProps = [
  {
    type: 'selectMultiple',
    value: GC.FIELD_LOAD_STATUS,
    options: getTripStatusOptions(),
    name: G.getWindowLocale('titles:status', 'Status'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:all-events', 'All Events')}: ${
      G.getWindowLocale('titles:location-states', 'Location States')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:all-events', 'All Events')}: ${
      G.getWindowLocale('titles:location-names', 'Location Names')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_PRIMARY_DIVISION_NAME,
    name: G.getWindowLocale('titles:primary-division-name', 'Primary Division Name'),
  },
  {
    type: 'number',
    value: GC.TOTAL_INCOME,
    name: `${G.getWindowLocale('titles:clos', 'Orders')}: ${
      G.getWindowLocale('titles:total', 'Total')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:clos', 'Orders')}: ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')}`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_SNAPSHOT_NAME,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:name', 'Name')
    }`,
  },
  {
    type: 'number',
    value: GC.GRC.RATE_CUSTOMER_TOTAL,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
      G.getWindowLocale('titles:customer-total', 'Customer Total')}`,
  },
  {
    type: 'selectMultiple',
    value: GC.GRC.RATE_SOURCE,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getWindowLocale('titles:source', 'Source')}`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
    options: [
      {
        value: GC.TEL_SOURCE_TYPE_MANUALLY,
        label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_MANUALLY),
      },
      {
        value: GC.TEL_SOURCE_TYPE_LOAD_BOARD,
        label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_LOAD_BOARD),
      },
      {
        value: GC.TEL_SOURCE_TYPE_EMAIL_QUOTE,
        label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_EMAIL_QUOTE),
      },
      {
        value: GC.TEL_SOURCE_TYPE_LOAD_BOARD_QUOTE,
        label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_LOAD_BOARD_QUOTE),
      },
      {
        value: GC.TEL_SOURCE_TYPE_TEMPLATE,
        label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_TEMPLATE),
      },
      {
        value: GC.TEL_SOURCE_TYPE_RATE_ENGINE,
        label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_RATE_ENGINE),
      },
    ],
  },
  {
    type: 'string',
    value: RC.EQUIPMENT,
    name: G.getWindowLocale('titles:ordered-equipment', 'Ordered Equipment'),
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_SNAPSHOT_SCAC,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:scac', 'SCAC')
    }`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:mc-number', 'MC Number')
    }`,
  },
  {
    type: 'string',
    value: RC.CARRIER_ASSIGNMENT_SNAPSHOT_US_DOT_NUMBER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:usdot-number', 'USDOT Number')
    }`,
  },
  {
    type: 'number',
    value: RC.CLO_COUNT,
    name: G.getWindowLocale('titles:clo-count', 'CLO Count'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_STORED_DEADHEAD_DISTANCE,
    name: G.getWindowLocale('titles:deadhead-distance', 'Deadhead Distance'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_DISTANCE_WITH_DEAD_HEAD,
    name: G.getWindowLocale('titles:distance-with-deadhead', 'Distance With Deadhead'),
  },
  {
    type: 'number',
    value: GC.FIELD_STORED_TOTAL_DISTANCE,
    name: G.getWindowLocale('titles:trip-stops-distance', 'Trip Stops Distance'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_STORED_TOTAL_TRIP_DISTANCE,
    name: G.getWindowLocale('titles:trip-rate-distance', 'Trip Rate Distance'),
  },
  {
    type: 'date',
    value: GC.GRC.RATE_DISPATCH_OR_CANCEL_DATE,
    name: G.getWindowLocale('titles:dispatched-date', 'Dispatched Date'),
  },
  {
    type: 'string',
    value: GC.GRC.RATE_DISPATCHED_OR_CANCELED_BY,
    name: G.getWindowLocale('titles:dispatched-by', 'Dispatched By'),
  },
  {
    type: 'boolean',
    value: GC.GRC.RATE_MAX_PAY_VIOLATED,
    name: `${G.getWindowLocale('titles:trip-max-pay', 'Trip Max Pay')}: ${
      G.getWindowLocale('titles:violated', 'Violated')}`,
  },
  {
    type: 'boolean',
    value: GC.GRC.RATE_MIN_MARGIN_VIOLATED,
    name: `${G.getWindowLocale('titles:min-margin', 'Min Margin')}: ${
      G.getWindowLocale('titles:violated', 'Violated')}`,
  },
  {
    type: 'boolean',
    value: GC.GRC.RATE_CRITICAL_MARGIN_VIOLATED,
    name: `${G.getWindowLocale('titles:critical-margin', 'Critical Margin')}: ${
      G.getWindowLocale('titles:violated', 'Violated')}`,
  },
  {
    type: 'boolean',
    value: GC.GRC.RATE_MARGIN_VIOLATION_APPROVED,
    name: G.getWindowLocale('titles:violation-approved', 'Violation Approved'),
  },
  {
    type: 'string',
    value: GC.GRC.RATE_MARGIN_VIOLATION_NOTE,
    name: G.getWindowLocale('titles:violation-note', 'Violation Note'),
  },
  {
    type: 'string',
    value: GC.GRC.RATE_MARGIN_VIOLATION_REASON,
    name: G.getWindowLocale('titles:violation-reason', 'Violation Reason'),
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.BROKER_AGENTS_NAME,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.BROKER_AGENTS_ID,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_ITEM_ID,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-id', 'Item ID')}`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    options: getTemperatureSensorOptions(),
    value: GC.GRC.EVENTS_ITEMS_TEMPERATURE_SENSOR,
    placeholder: {
      text: 'Temperature Sensor',
      key: 'titles:temperature-sensor',
    },
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.EVENTS_ITEMS_ITEM_DIMENSIONS_LENGTH,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:dimensions-length', 'Dimensions Length')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.EVENTS_ITEMS_ITEM_DIMENSIONS_WIDTH,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:dimensions-width', 'Dimensions Width')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.EVENTS_ITEMS_ITEM_DIMENSIONS_HEIGHT,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:dimensions-height', 'Dimensions Height')}`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    options: ITEM_DIMENSIONS_OPTIONS,
    value: GC.GRC.EVENTS_ITEMS_ITEM_DIMENSIONS_UOM,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:dimensions-uom', 'Dimensions UOM')}`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    collection: true,
    type: 'selectMultiple',
    options: getFreightClassOptions(),
    value: GC.GRC.EVENTS_ITEMS_ITEM_FREIGHT_CLASS,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:freight-class', 'Freight Class')}`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_MANUFACTURER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:manufacturer', 'Manufacturer')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.EVENTS_ITEMS_YEAR,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:year', 'Year')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_MAKE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:make', 'Make')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_MODEL,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:model', 'Model')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_SUB_MODEL,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:sub-model', 'Sub Model')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.EVENTS_ITEMS_WHEELBASE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase', 'Wheelbase')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    options: ITEM_DIMENSIONS_OPTIONS,
    value: RC.EVENTS_ITEMS_WHEELBASE_UOM,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase Uom')
    }`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_BAY,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:bay', 'Bay')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.EVENTS_ITEMS_ODOMETER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer', 'Odometer')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    options: ITEM_ODOMETER_UOM_OPTIONS,
    value: RC.EVENTS_ITEMS_ODOMETER_UOM,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer-uom', 'Odometer Uom')
    }`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_COLOR,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:color', 'Color')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_LICENSE_PLATE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:license-plate', 'License Plate')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_VIN,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:vin', 'VIN')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.EVENTS_ITEMS_ITEM_TYPE,
    options: R.drop(1, ITEM_TYPE_OPTIONS),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-type', 'Item Type')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_ORIGIN_STATE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-state', 'Origin State')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.EVENTS_ITEMS_ORIGIN_COUNTRY,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-country', 'Origin Country')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_TEMPLATE_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:template-name', 'Template Name')
    }`,
  },
  {
    type: 'string',
    value: GC.GRC.LAST_STATUS_MESSAGE_STATUS_REASON_CODE,
    name: G.getWindowLocale('titles:status-reason-code', 'Status Reason Code'),
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.LOAD_BOARD_SHIPMENTS_STATUS,
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: `${G.getWindowLocale('titles:load-board-shipments', 'Load Board Shipments')}: ${
      G.getWindowLocale('titles:status', 'Status')
    }`,
    options: [
      {
        value: GC.LOAD_BOARD_SHIPMENT_STATUS_POSTED,
        label: G.getEnumLocale(GC.LOAD_BOARD_SHIPMENT_STATUS_POSTED),
      },
      {
        value: GC.LOAD_BOARD_SHIPMENT_STATUS_REMOVED,
        label: G.getEnumLocale(GC.LOAD_BOARD_SHIPMENT_STATUS_REMOVED),
      },
      {
        value: GC.LOAD_BOARD_SHIPMENT_STATUS_COVERED,
        label: G.getEnumLocale(GC.LOAD_BOARD_SHIPMENT_STATUS_COVERED),
      },
    ],
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.LOAD_BOARD_SHIPMENTS_SOURCE,
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: `${G.getWindowLocale('titles:load-board-shipments', 'Load Board Shipments')}: ${
      G.getWindowLocale('titles:source', 'Source')
    }`,
    options: [
      {
        value: GC.EXTERNAL_LOAD_BOARD_DAT,
        label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_DAT),
      },
      {
        value: GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP,
        label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP),
      },
      {
        value: GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
        label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS),
      },
      {
        value: GC.EXTERNAL_LOAD_BOARD_PARADE,
        label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_PARADE),
      },
      {
        value: GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
        label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_NEWTRUL),
      },
    ],
  },
  {
    type: 'string',
    collection: true,
    value: RC.LOAD_BOARD_SHIPMENTS_CREATED_BY,
    name: `${G.getWindowLocale('titles:load-board-shipments', 'Load Board Shipments')}: ${
      G.getWindowLocale('titles:created-by', 'Created By')
    }`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.LOAD_BOARD_SHIPMENTS_CREATED_DATE,
    name: `${G.getWindowLocale('titles:load-board-shipments', 'Load Board Shipments')}: ${
      G.getWindowLocale('titles:created-date', 'Created Date')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.LOAD_BOARD_SHIPMENTS_LAST_MODIFIED_BY,
    name: `${G.getWindowLocale('titles:load-board-shipments', 'Load Board Shipments')}: ${
      G.getWindowLocale('titles:last-modified-by', 'Last Modified By')
    }`,
  },
  {
    type: 'date',
    value: GC.GRC.FIRST_EVENT_ARRIVE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:arrive-date', 'Arrive Date')
    }`,
  },
  {
    type: 'date',
    value: GC.GRC.LAST_EVENT_ARRIVE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:arrive-date', 'Arrive Date')
    }`,
  },
  {
    type: 'date',
    value: GC.GRC.NEXT_EVENT_ARRIVE_DATE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:arrive-date', 'Arrive Date')
    }`,
  },
  {
    type: 'date',
    value: GC.GRC.NEXT_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:next-event', 'Next Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER,
    name: G.getWindowLocale('titles:fleet-vendor-mc-number', 'Fleet Vendor MC #'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_SALE_PERSONS_NAME,
    name: G.getWindowLocale('titles:sale-persons', 'Sale Persons'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:name', 'name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_ACCOUNT_MANAGERS_NAME,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:name', 'name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_ACCOUNT_MANAGERS_ID,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.MACRO_POINT_INFO_STATUS,
    name: G.getWindowLocale('titles:macropoint-status', 'Macropoint Status'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_INVOICES_STATUS,
    name: `${G.getWindowLocale('titles:customer-invoice', 'Customer Invoice')}: ${
      G.getWindowLocale('titles:status', 'Status')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_INVOICES_CHECK_DATE,
    name: `${G.getWindowLocale('titles:customer-invoice', 'Customer Invoice')}: ${
      G.getWindowLocale('titles:check-date', 'Check Date')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_INVOICES_DEPOSIT_DATE,
    name: `${G.getWindowLocale('titles:customer-invoice', 'Customer Invoice')}: ${
      G.getWindowLocale('titles:deposit-date', 'Deposit Date')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_CLAIM_CLAIM_NUMBER,
    name: G.getWindowLocale('titles:claim-number', 'Claim Number'),
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_CLAIM_CLAIM_DATE,
    name: G.getWindowLocale('titles:claim-date', 'Claim Date'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_CLAIM_CLAIM_STATUS,
    name: G.getWindowLocale('titles:claim-status', 'Claim Status'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_CLAIM_CLAIM_SUB_STATUS,
    name: G.getWindowLocale('titles:claim-sub-status', 'Claim Sub Status'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_CLAIM_CLAIM_REASON,
    name: G.getWindowLocale('titles:claim-reason', 'Claim Reason'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_CLAIM_CLAIM_TYPE,
    name: G.getWindowLocale('titles:claim-type', 'Claim Type'),
  },
  {
    type: 'boolean',
    value: RC.CARRIER_ASSIGNMENT_DRIVER_TRACKING_APP_INSTALLED,
    name: G.getWindowLocale('titles:driver-tracking-app-installed', 'Driver Tracking App Installed'),
  },
  {
    type: 'string',
    value: RC.ROUTE_TEMPLATE_NAME,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${
      G.getWindowLocale('titles:template-name', 'Template Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_STORED_TOTAL_DISTANCE,
    name: `${G.getWindowLocale('titles:clos', 'Orders')}: ${
      G.getWindowLocale('titles:stops-distance', 'Stops Distance')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_RATE_STORED_TOTAL_TRIP_DISTANCE,
    name: `${G.getWindowLocale('titles:clos', 'Orders')}: ${
      G.getWindowLocale('titles:rate-distance', 'Rate Distance')}`,
  },
];

const orderUniqFilterProps = [
  {
    type: 'selectMultiple',
    value: GC.FIELD_LOAD_STATUS,
    options: getOrderStatusOptions(),
    name: G.getWindowLocale('titles:status', 'Status'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: GC.FIELD_PRIMARY_DIVISION_NAME,
    name: G.getWindowLocale('titles:primary-division-name', 'Primary Division Name'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_SPENDING,
    name: G.getWindowLocale('titles:total-spend', 'Total Spend'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:tels', 'Trips')}: ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')}`,
  },
  {
    type: 'string',
    value: RC.EQUIPMENT,
    name: G.getWindowLocale('titles:ordered-equipment', 'Ordered Equipment'),
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.ITEMS_TEMPERATURE_TYPE,
    options: getTemperatureTypeOptions(),
    name: G.getWindowLocale('titles:temperature-type', 'Temperature Type'),
    placeholder: {
      text: 'Temperature Type',
      key: 'titles:temperature-type',
    },
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: GC.GRC.ITEMS_TEMPERATURE_SENSOR,
    options: getTemperatureSensorOptions(),
    name: G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor'),
    placeholder: {
      text: 'Temperature Sensor',
      key: 'titles:temperature-sensor',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.MASTER_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:master-invoice-number', 'Master Invoice Number'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_DISCOUNT_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_TRIP_MAX_PAY,
    name: G.getWindowLocale('titles:trip-max-pay', 'Trip Max Pay'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_NORMALIZED_TRIP_MAX_PAY,
    name: G.getWindowLocale('titles:normalized-trip-max-pay', 'Normalized Trip Max Pay'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_NAME,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:name', 'Name')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:scac', 'SCAC')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:mc-number', 'MC Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_ADDRESS,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:address', 'Address')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_EMAILS_STRING,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:emails', 'Emails')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_FEDERAL_EIN,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:federal-ein', 'Federal EIN')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_TRUCK,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:truck', 'Truck')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_TRAILER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:trailer', 'Trailer')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_BRANCH_BRANCH_NAME,
    name: G.getWindowLocale('titles:current-divisions', 'Current Division(s)'),
  },
  {
    type: 'number',
    value: RC.TEL_COUNT,
    name: G.getWindowLocale('titles:tel-count', 'TEL Count'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
    name: G.getWindowLocale('titles:appointment-required', 'App Required'),
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.SALE_PERSONS_NAME,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.SALE_PERSONS_ID,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ACCOUNT_MANAGERS_NAME,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ACCOUNT_MANAGERS_ID,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_BROKER_AGENTS_NAME,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_BROKER_AGENTS_ID,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_CARRIER_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_CARRIER_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.ORDER_TYPE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:order-type', 'Order Type'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_MANUFACTURER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:manufacturer', 'Manufacturer')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_ID,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-id', 'Item ID')}`,
  },
  {
    type: 'boolean',
    collection: true,
    value: RC.ITEMS_ITEM_HAZARDOUS,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazardous', 'Hazardous')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_UN_OR_NA_NUMBER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-un-or-na-number', 'HazMat UN or NA Number')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_PACKAGE_GROUP,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-package-group', 'HazMat Package Group')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_CLASS,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-class', 'HazMat Class')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_SHIPPING_NAME,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-shipping-name', 'HazMat Shipping Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_CONTACT_NAME,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-contact-name', 'HazMat Contact Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_CONTACT_PHONE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-contact-phone', 'HazMat Contact Phone')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_EMS_NUMBER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-ems-number', 'HazMat EMS Number')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_PLACARDS_DETAILS,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-placard-details', 'HazMat Placard Details')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_FLASHPOINT,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-flashpoint', 'HazMat Flashpoint')}`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    options: ITEM_TEMPERATURE_OPTIONS,
    value: RC.ITEMS_ITEM_HAZMAT_FLASHPOINT_UOM,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-flashpoint-uom', 'HazMat Flashpoint UOM')}`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ITEM_HAZMAT_COMMENT,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:hazmat-comments', 'HazMat Comments')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.ITEMS_YEAR,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:year', 'Year')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_MAKE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:make', 'Make')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_MODEL,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:model', 'Model')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_SUB_MODEL,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:sub-model', 'Sub Model')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.ITEMS_WHEELBASE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase', 'Wheelbase')
    }`,
  },
  {
    type: 'selectMultiple',
    collection: true,
    value: RC.ITEMS_WHEELBASE_UOM,
    options: ITEM_DIMENSIONS_OPTIONS,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase Uom')
    }`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_BAY,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:bay', 'Bay')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.ITEMS_ODOMETER,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer', 'Odometer')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.ITEMS_ODOMETER_UOM,
    options: ITEM_ODOMETER_UOM_OPTIONS,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:odometer-uom', 'Odometer Uom')
    }`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_COLOR,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:color', 'Color')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_LICENSE_PLATE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:license-plate', 'License Plate')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ITEMS_STORED_WEIGHT,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:weight', 'Weight')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.HAZMAT_ITEMS_ITEM_ID,
    name: `${G.getWindowLocale('titles:hazmat-items', 'Hazmat Items')}: ${
      G.getWindowLocale('titles:item-id', 'Item ID')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.HAZMAT_ITEMS_STORED_WEIGHT,
    name: `${G.getWindowLocale('titles:hazmat-items', 'Hazmat Items')}: ${
      G.getWindowLocale('titles:weight', 'Weight')
    }`,
  },
  {
    type: 'number',
    value: GC.FIELD_STORED_HAZMAT_TOTAL_WEIGHT,
    name: `${G.getWindowLocale('titles:hazmat-items', 'Hazmat Items')}: ${
      G.getWindowLocale('titles:total-weight', 'Total Weight')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.NON_HAZMAT_ITEMS_ITEM_ID,
    name: `${G.getWindowLocale('titles:non-hazmat-items', 'Non Hazmat Items')}: ${
      G.getWindowLocale('titles:item-id', 'Item ID')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.NON_HAZMAT_ITEMS_STORED_WEIGHT,
    name: `${G.getWindowLocale('titles:non-hazmat-items', 'Non Hazmat Items')}: ${
      G.getWindowLocale('titles:weight', 'Weight')
    }`,
  },
  {
    type: 'number',
    value: GC.FIELD_STORED_NON_HAZMAT_TOTAL_WEIGHT,
    name: `${G.getWindowLocale('titles:non-hazmat-items', 'Non Hazmat Items')}: ${
      G.getWindowLocale('titles:total-weight', 'Total Weight')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_VIN,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:vin', 'VIN')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.ITEMS_ITEM_TYPE,
    options: R.drop(1, ITEM_TYPE_OPTIONS),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:item-type', 'Item Type')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ORIGIN_STATE,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-state', 'Origin State')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.ITEMS_ORIGIN_COUNTRY,
    name: `${G.getWindowLocale('titles:items', 'Items')}: ${
      G.getWindowLocale('titles:origin-country', 'Origin Country')
    }`,
  },
  {
    type: 'string',
    value: GC.FIELD_TEMPLATE_NAME,
    name: G.getWindowLocale('titles:template-name', 'Template Name'),
  },
  {
    type: 'string',
    value: GC.GRC.ORIGINATING_DIVISION_BRANCH_NAME,
    name: G.getWindowLocale('titles:originating-division', 'Originating Division'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_APPROVED,
    name: G.getWindowLocale('titles:approved', 'Approved'),
  },
  {
    type: 'string',
    value: GC.FIELD_APPROVED_BY,
    name: G.getWindowLocale('titles:approved-by', 'Approved By'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER,
    name: G.getWindowLocale('titles:fleet-vendor-mc-number', 'Fleet Vendor MC #'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INVOICES_NUMBER,
    name: G.getWindowLocale('titles:invoice-number', 'Invoice #'),
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.INVOICES_TOTAL,
    name: G.getWindowLocale('titles:invoice-total', 'Invoice Total'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INVOICES_STATUS,
    name: G.getWindowLocale('titles:invoice-status', 'Invoice Status'),
  },
  {
    type: 'string',
    disableField: true,
    value: GC.GRC.RATE_CHARGE_NAME,
    name: G.getWindowLocale('titles:rate-charge-name', 'Rate Charge Name'),
  },
  {
    type: 'string',
    value: RC.CLAIM_CLAIM_NUMBER,
    name: G.getWindowLocale('titles:claim-number', 'Claim Number'),
  },
  {
    type: 'date',
    value: RC.CLAIM_CLAIM_DATE,
    name: G.getWindowLocale('titles:claim-date', 'Claim Date'),
  },
  {
    type: 'string',
    value: RC.CLAIM_CLAIM_STATUS,
    name: G.getWindowLocale('titles:claim-status', 'Claim Status'),
  },
  {
    type: 'string',
    value: RC.CLAIM_CLAIM_SUB_STATUS,
    name: G.getWindowLocale('titles:claim-sub-status', 'Claim Sub Status'),
  },
  {
    type: 'string',
    value: RC.CLAIM_CLAIM_REASON,
    name: G.getWindowLocale('titles:claim-reason', 'Claim Reason'),
  },
  {
    type: 'string',
    value: RC.CLAIM_CLAIM_TYPE,
    name: G.getWindowLocale('titles:claim-type', 'Claim Type'),
  },
  {
    type: 'number',
    value: GC.FIELD_STORED_TOTAL_DISTANCE,
    name: G.getWindowLocale('titles:order-stops-distance', 'Order Stops Distance'),
  },
  {
    type: 'number',
    value: GC.GRC.RATE_STORED_TOTAL_TRIP_DISTANCE,
    name: G.getWindowLocale('titles:order-rate-distance', 'Order Rate Distance'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_STORED_TOTAL_DISTANCE,
    name: `${G.getWindowLocale('titles:tels', 'Trips')}: ${
      G.getWindowLocale('titles:stops-distance', 'Stops Distance')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_RATE_STORED_TOTAL_TRIP_DISTANCE,
    name: `${G.getWindowLocale('titles:tels', 'Trips')}: ${
      G.getWindowLocale('titles:rate-distance', 'Rate Distance')}`,
  },
];

export const orderFilterProps = R.concat(DOFilterProps, orderUniqFilterProps);
export const loadFilterProps = R.concat(DMFilterProps, loadUniqFilterProps);

const routeFields = [
  {
    type: 'string',
    value: GC.FIELD_NAME,
    name: `${G.getWindowLocale('titles:route', 'route')}: ${G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:route', 'route')}: ${G.getWindowLocale('titles:branch', 'Branch')}`,
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_STATUS,
    options: getTripStatusOptions(),
    name: `${G.getWindowLocale('titles:route', 'route')}: ${G.getWindowLocale('titles:status', 'Status')}`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_PRIMARY_DIVISION_NAME,
    name: G.getWindowLocale('titles:primary-division-name', 'Primary Division Name'),
  },
  {
    type: 'number',
    value: GC.FIELD_MARGIN,
    name: `${G.getWindowLocale('titles:route', 'route')}: ${G.getWindowLocale('titles:margin', 'Margin')}`,
  },
  {
    type: 'number',
    value: GC.FIELD_CLO_COUNT,
    name: G.getWindowLocale('titles:clo-count', 'CLO Count'),
  },
  {
    type: 'number',
    value: GC.FIELD_TEL_COUNT,
    name: G.getWindowLocale('titles:tel-count', 'TEL Count'),
  },
  {
    type: 'number',
    value: GC.TOTAL_INCOME,
    name: G.getWindowLocale('titles:total-income', 'Total Income'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_SPEND,
    name: G.getWindowLocale('titles:total-spend', 'Total Spend'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:created-by', 'Created By')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${G.getWindowLocale('titles:created-date', 'Created Date')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_LAST_MODIFIED_BY,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${
      G.getWindowLocale('titles:last-modified-by', 'Last Modified By')
    }`,
  },
  {
    type: 'date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
    name: `${G.getWindowLocale('titles:route', 'Route')}: ${
      G.getWindowLocale('titles:last-modified-date', 'Last Modified Date')
    }`,
  },
];

const routeCloFields = [
  {
    collection: true,
    value: RC.CLO_STATUS,
    type: 'selectMultiple',
    options: getOrderStatusOptions(),
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:status', 'Status')}`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_PRIMARY_REFERENCE_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:primary-ref-name', 'Primary Reference Name')
    }`,
  },
  {
    type: 'string',
    value: RC.CLO_CONTAINERS_NUMBER,
    name: `${G.getWindowLocale('titles:containers', 'Containers')}: ${
      G.getWindowLocale('titles:container-number', 'Container Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BRANCH_ACCOUNT_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:account-number', 'Account Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:branch', 'Branch')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_RATE_MODE,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:mode', 'Mode')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_TIME_TO_NEXT_STOP,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:time-to-next-stop', 'Time To Next Stop')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.CLO_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:package-type', 'Package Type')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_TOTAL_PICKUP_QUANTITY,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:quantity', 'Quantity')
    }`,
  },
  {
    type: 'number',
    value: FIELD_CLO_TOTAL_WEIGHT,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:total-weight', 'Total Weight')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.FIELD_CLO_TOTAL_VOLUME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:total-volume', 'Total Volume')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.CLO_ITEMS_FREIGHT_CLASS,
    options: getFreightClassOptions(),
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:items', 'Items')} ${
      G.getWindowLocale('titles:freight-class', 'Freight Class')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_SERVICES,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:services', 'Services')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_EQUIPMENT,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:equipments', 'Equipments')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_EXTERNAL_ID,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:external-id', 'External ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_SENDER_ID,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:sender-id', 'Sender ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_QUOTE_REQUEST_NUMBER,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:quote-request-number', 'Quote Request Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:state', 'State')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:city', 'City')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:address1', 'Address1')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_ADDRESS2,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:address2', 'Address2')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:zip', 'Zip')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:comments', 'Comments')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLO_BILL_TO_LOCATION_TYPE_VALUE,
    name: `${G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To')}: ${
      G.getWindowLocale('titles:type', 'Type')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_EVENT_COUNT,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:event-count', 'Event Count')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_RATE_MAIN_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:main-charges-total', 'Main Charges Total')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_RATE_ADDITIONAL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:additional-charges-total', 'Additional Charges Total')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_RATE_FUEL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:fuel-charges-total', 'Fuel Charges Total')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_RATE_TOTAL,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:total-rate', 'Total Rate')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLOS_RATE_NORMALIZED_TOTAL_TOTAL,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:normalized-total-rate', 'Normalized Total Rate')}
    `,
  },
  {
    type: 'number',
    collection: true,
    value: RC.CLO_INVOICE_COUNT,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:invoice-count', 'Invoice Count')}`,
  },
  {
    type: 'date',
    value: RC.CLO_INVOICES_INTEGRATED_DATE,
    name: `${G.getWindowLocale('titles:customer-invoices', 'Customer Invoice(s)')}: ${
      G.getWindowLocale('titles:integrated-date', 'Integrated Date')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_SALE_PERSONS_NAME,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_SALE_PERSONS_ID,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:name', 'name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_ACCOUNT_MANAGERS_NAME,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:name', 'name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLOS_ACCOUNT_MANAGERS_ID,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
];

const routeCloFirstLastEventFields = [
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Templete ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Tempate ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_LAST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_FIRST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'CLO First Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.CLOS_LAST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'CLO Last Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
];

const routeTelFields = [
  {
    collection: true,
    value: RC.TEL_STATUS,
    type: 'selectMultiple',
    options: getTripStatusOptions(),
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${G.getWindowLocale('titles:status', 'Status')}`,
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_PRIMARY_REFERENCE_NAME,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:primary-ref-name', 'Primary Reference Name')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BRANCH_ACCOUNT_NAME,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:account-number', 'Account Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:branch', 'Branch')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_RATE_MODE,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:mode', 'Mode')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_CARRIER_TRACKING_NUMBER,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:tracking-number', 'Tracking Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_INTEGRATION_ACCOUNT_NUMBER,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${G.getWindowLocale('titles:rate', 'Rate')}: ${
      G.getWindowLocale('titles:integration-account-number', 'Integration Account Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_CARRIER_BOL_NUMBER,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:bol-number', 'BOL Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_CARRIER_PICKUP_NUMBER,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:pickup-number', 'Pickup Number')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_TRUCK,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:truck', 'Truck')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_TRAILER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:trailer', 'Trailer')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE,
    name: `${G.getWindowLocale('titles:carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${G.getWindowLocale('titles:phone', 'Phone')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_TIME_TO_NEXT_STOP,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:time-to-next-stop', 'Time To Next Stop')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: GC.FIELD_TEL_TOTAL_DISTANCE,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:trip-distance', 'Trip Distance')
    }`,
  },
  {
    collection: true,
    type: 'selectMultiple',
    value: RC.TEL_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:package-type', 'Package Type')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_TOTAL_PICKUP_QUANTITY,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:quantity', 'Quantity')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_ADDRESS2,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_BILL_TO_LOCATION_TYPE_VALUE,
    name: `${G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To')}: ${
      G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_EVENT_COUNT,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${G.getWindowLocale('titles:event-count', 'Event Count')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_RATE_MAIN_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:main-charges-total', 'Main Charges Total')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_RATE_ADDITIONAL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:additional-charges-total', 'Additional Charges Total')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_RATE_FUEL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:fuel-charges-total', 'Fuel Charges Total')
    }`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_RATE_TOTAL,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${G.getWindowLocale('titles:total-rate', 'Total Rate')}`,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TELS_RATE_NORMALIZED_TOTAL_TOTAL,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:normalized-total-rate', 'Normalized Total Rate')}
    `,
  },
  {
    type: 'number',
    collection: true,
    value: RC.TEL_INVOICE_COUNT,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${G.getWindowLocale('titles:invoice-count', 'Invoice Count')}`,
  },
  {
    type: 'date',
    value: RC.TEL_FLEET_INVOICES_INTEGRATION_DATE,
    name: `${G.getWindowLocale('titles:driver-invoices', 'Driver Invoice(s)')}: ${
      G.getWindowLocale('titles:integrated-date', 'Integrated Date')}`,
  },
  {
    type: 'date',
    value: RC.TEL_CARRIER_INVOICES_INTEGRATION_DATE,
    name: `${G.getWindowLocale('titles:carrier-invoices', 'Carrier Invoice(s)')}: ${
      G.getWindowLocale('titles:integrated-date', 'Integrated Date')}`,
  },
  {
    type: 'date',
    value: RC.TEL_FLEET_VENDOR_INVOICES_INTEGRATION_DATE,
    name: `${G.getWindowLocale('titles:vendor-invoices', 'Vendor Invoice(s)')}: ${
      G.getWindowLocale('titles:integrated-date', 'Integrated Date')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_BROKER_AGENTS_NAME,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_BROKER_AGENTS_ID,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_CARRIER_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.TELS_CARRIER_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_TEMPLATE_NAME,
    name: G.getWindowLocale('titles:template-name', 'Template Name'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER,
    name: G.getWindowLocale('titles:fleet-vendor-mc-number', 'Fleet Vendor MC #'),
  },
];

const routeTelFirstLastEventFields = [
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Templete ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_FIRST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Tempate ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_LAST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_FIRST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:tel-first-event', 'TEL First Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    collection: true,
    value: RC.TELS_LAST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:tel-last-event', 'TEL Last Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
];

const routeTelFleetCarrierFields = [
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_NAME,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:name', 'Name')
    }`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:scac', 'SCAC')
    }`,
  },
  {
    type: 'date',
    collection: true,
    value: `${GC.SYSTEM_LIST_TELS}.${RC.RATE_DISPATCHED_DATE}`,
    name: G.getWindowLocale('titles:dispatched-date', 'Dispatched Date'),
  },
  {
    type: 'string',
    collection: true,
    value: `${GC.SYSTEM_LIST_TELS}.${RC.RATE_DISPATCHED_BY}`,
    name: G.getWindowLocale('titles:dispatched-by', 'Dispatched By'),
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:email', 'Email')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TELS_FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')} `,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:vendor', 'Vendor')} `,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
    name: `${G.getWindowLocale('titles:trailers', 'Trailers')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_SERVICES,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${G.getWindowLocale('titles:services', 'Services')}`,
  },
  {
    type: 'string',
    collection: true,
    value: RC.TEL_EQUIPMENT,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${G.getWindowLocale('titles:equipments', 'Equipments')}`,
  },
];

export const routeFilterProps = [
  ...routeFields,
  ...routeCloFields,
  ...routeCloFirstLastEventFields,
  ...routeTelFields,
  ...routeTelFirstLastEventFields,
  ...routeTelFleetCarrierFields,
];

export const loadFilterPropsForTitleFilter = R.map((obj: Object) => {
  if (R.equals(obj.value, GC.FIELD_LOAD_STATUS)) {
    return R.assoc('type', 'string', obj);
  }
  return obj;
},
  loadFilterProps,
);
