import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { RelativeBox } from '../../../ui';
// component
import DrawingMap from '../../../components/drawing-map';
import { openModal, closeModal } from '../../../components/modal/actions';
// feature dispatch-planner-events
import * as H from '../helpers';
import SelectLoadsForm from './select-loads-form';
import {
  addTelToPlanner,
  uncheckAndRemoveCloEvent,
  addStopsFromMapToPlanner,
  getCloEventDetailsRequest,
} from '../actions';
import {
  makeSelectCurrentRoute,
  makeSelectCloEventListForMap,
  makeSelectCloEventListForMapLoading,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleAddStopsToPlanner: (props: Object) => ({ results, callback }: Object) => {
      const {
        openModal,
        closeModal,
        currentRoute,
        addStopsFromMapToPlanner,
      } = props;

      const currentRouteCloEventGuids = H.getCurrentRouteCloEventGuids(currentRoute);

      const newEvents = R.compose(
        R.values,
        R.omit(currentRouteCloEventGuids),
        G.indexByGuid,
      )(results);

      if (R.isEmpty(newEvents)) return callback();

      const tels = R.values(R.path(['currentRoute', 'tels'], props));

      if (G.isNilOrEmpty(tels)) {
        callback();

        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('titles:create-tel-first', 'You need to create a TEL first'),
        );
      }

      if (R.equals(R.path(['length'], tels), 1)) {
        return addStopsFromMapToPlanner({
          returnObject: { callback, newEvents },
          selectedValue: { value: R.path([GC.FIELD_GUID], R.head(tels)) },
        });
      }

      const onCloseModal = () => {
        G.callFunction(callback);
        closeModal();
      };

      const component = (
        <SelectLoadsForm
          initialValue=''
          closeModal={onCloseModal}
          returnObject={{ callback, newEvents }}
          options={H.createTelOptions(currentRoute)}
          handleSubmitAction={addStopsFromMapToPlanner}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:select-tel', 'Please, select Trip'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const CloEventsMap = enhance(({ height, locations, handleAddStopsToPlanner }: Object) => (
  <RelativeBox zIndex={15}>
    <DrawingMap
      locations={locations}
      height={R.or(height, 'calc(100vh - 66px)')}
      handleCompleteSelection={handleAddStopsToPlanner}
    />
  </RelativeBox>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  currentRoute: makeSelectCurrentRoute(state),
  locations: makeSelectCloEventListForMap(state),
  loading: makeSelectCloEventListForMapLoading(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  addTelToPlanner,
  uncheckAndRemoveCloEvent,
  addStopsFromMapToPlanner,
  getCloEventDetailsRequest,
})(CloEventsMap);
