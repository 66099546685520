import React from 'react';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, BoxHovered } from '../../../ui';
//////////////////////////////////////////////////

const HeaderActions = (props: Object) => {
  const iconColor = G.getTheme('colors.dark.blue');

  return (
    <Flex
      p='5px 0'
      fontSize={14}
      alignItems='normal'
      flexDirection='column'
      color={G.getTheme('colors.light.black')}
    >
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.recalculateDistances}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.reloadCircle(iconColor, 16, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:recalculate-distances', 'Recalculate Distances')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.sortByDate}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.sortByDate(iconColor, 20, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:sort-by-date', 'Sort by Date')}</Box>
        </Flex>
      </BoxHovered>
      <AuthWrapper has={[PC.FLEET_RATE_WRITE]}>
        <BoxHovered
          p='4px 8px'
          width='100%'
          hoverColor={iconColor}
          onClick={props.assignDriver}
        >
          <Flex cursor='pointer'>
            <Flex width={20}>{I.plusRound(iconColor, 16, 16)}</Flex>
            <Box ml='6px'>{G.getWindowLocale('actions:assign-driver-rate', 'Assign Driver Rate')}</Box>
          </Flex>
        </BoxHovered>
      </AuthWrapper>
      <AuthWrapper has={[PC.CARRIER_RATE_WRITE]}>
        <BoxHovered
          p='4px 8px'
          width='100%'
          hoverColor={iconColor}
          onClick={props.assignCarrier}
        >
          <Flex cursor='pointer'>
            <Flex width={20}>{I.plusRound(iconColor, 16, 16)}</Flex>
            <Box ml='6px'>{G.getWindowLocale('actions:assign-carrier-rate', 'Assign Carrier Rate')}</Box>
          </Flex>
        </BoxHovered>
      </AuthWrapper>
      <AuthWrapper has={[PC.CARRIER_RATE_WRITE]}>
        <BoxHovered
          p='4px 8px'
          width='100%'
          hoverColor={iconColor}
          onClick={props.handleMultiCarrierRates}
        >
          <Flex cursor='pointer'>
            <Flex width={20}>{I.plusRound(iconColor, 16, 16)}</Flex>
            <Box ml='6px'>{G.getWindowLocale('actions:multi-carrier-rates', 'Multi Carrier Rates')}</Box>
          </Flex>
        </BoxHovered>
      </AuthWrapper>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.addTerminal}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.terminal(null, 16, 16, iconColor)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-an-existed-terminal', 'Add an Existed Terminal')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.addPickup}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.plusArrowUp(iconColor, 16, 16, null)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-a-pickup', 'Add a Pickup')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.addDrop}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.plusArrowDown(iconColor, 16, 16, null)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-a-drop', 'Add a Drop')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.addCloPickup}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.plusArrowUp(iconColor, 16, 16, null)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-clo-pickup', 'Add CLO Pickup')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.addCloDrop}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.plusArrowDown(iconColor, 16, 16, null)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:add-clo-drop', 'Add CLO Drop')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        hoverColor={iconColor}
        onClick={props.showOnMap}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.onMap(iconColor, 16, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:show-on-a-map', 'Show on a Map')}</Box>
        </Flex>
      </BoxHovered>
      <BoxHovered
        p='4px 8px'
        width='100%'
        onClick={props.remove}
        hoverColor={iconColor}
      >
        <Flex cursor='pointer'>
          <Flex width={20}>{I.trash(iconColor, 16, 16)}</Flex>
          <Box ml='6px'>{G.getWindowLocale('actions:remove-the-tel', 'Remove the TEL')}</Box>
        </Flex>
      </BoxHovered>
    </Flex>
  );
};

export default HeaderActions;
