import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const selectItem = createAction('selectItem');
export const setGroupBy = createAction('setGroupBy');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setFilterProps = createAction('setFilterProps');
export const setInitialState = createAction('setInitialState');
export const cancelCLORequest = createAction('cancelCLORequest');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const unquoteCLORequest = createAction('unquoteCLORequest');
export const deleteItemRequest = createAction('deleteItemRequest');
export const deleteItemSuccess = createAction('deleteItemSuccess');
export const getSummaryRequest = createAction('getSummaryRequest');
export const getSummarySuccess = createAction('getSummarySuccess');
export const setUsedReportOnly = createAction('setUsedReportOnly');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const setGlobalFilterType = createAction('setGlobalFilterType');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const updateCloRateRequest = createAction('updateCloRateRequest');
export const setShipUnitsExpanded = createAction('setShipUnitsExpanded');
export const setIgnorePromptStatus = createAction('setIgnorePromptStatus');
export const resetListAndPagination = createAction('resetListAndPagination');
export const createReferenceRequest = createAction('createReferenceRequest');
export const getOrderDetailsSuccess = createAction('getOrderDetailsSuccess');
export const getOrderDetailsRequest = createAction('getOrderDetailsRequest');
export const updateContainerCallback = createAction('updateContainerCallback');
export const createTelFromCloRequest = createAction('createTelFromCloRequest');
export const getOrderInvoicesRequest = createAction('getOrderInvoicesRequest');
export const getOrderInvoicesSuccess = createAction('getOrderInvoicesSuccess');
export const getOrdersShipUnitRequest = createAction('getOrdersShipUnitRequest');
export const getOrdersShipUnitSuccess = createAction('getOrdersShipUnitSuccess');
export const toggleOrderDetailsRequest = createAction('toggleOrderDetailsRequest');
export const toggleOrderDetailsSuccess = createAction('toggleOrderDetailsSuccess');
export const changeCancelStatusRequest = createAction('changeCancelStatusRequest');
export const updateOrderDetailsRequest = createAction('updateOrderDetailsRequest');
export const updateOrderDetailsSuccess = createAction('updateOrderDetailsSuccess');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const updateOrderDocumentRequest = createAction('updateOrderDocumentRequest');
export const updateOrderDocumentSuccess = createAction('updateOrderDocumentSuccess');
export const removeOrderDocumentRequest = createAction('removeOrderDocumentRequest');
export const removeOrderDocumentSuccess = createAction('removeOrderDocumentSuccess');
export const getOrderSelectedRateRequest = createAction('getOrderSelectedRateRequest');
export const getOrderSelectedRateSuccess = createAction('getOrderSelectedRateSuccess');
export const getOrderDocumentListRequest = createAction('getOrderDocumentListRequest');
export const getOrderDocumentListSuccess = createAction('getOrderDocumentListSuccess');
export const setDataToListItemByPropName = createAction('setDataToListItemByPropName');
export const setDataToItemListItemDetails = createAction('setDataToItemListItemDetails');
export const downloadOrderDocumentRequest = createAction('downloadOrderDocumentRequest');
export const getOrderInvoiceTotalsSuccess = createAction('getOrderInvoiceTotalsSuccess');
export const getOrderInvoiceTotalsRequest = createAction('getOrderInvoiceTotalsRequest');
export const createCustomerInvoiceRequest = createAction('createCustomerInvoiceRequest');
export const getOrderDispatchBoardDetailsRequest = createAction('getOrderDispatchBoardDetailsRequest');
export const getOrderDispatchBoardDetailsSuccess = createAction('getOrderDispatchBoardDetailsSuccess');
export const getBranchConfigsByCloBranchGuidSuccess = createAction('getBranchConfigsByCloBranchGuidSuccess');
