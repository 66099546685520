import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex, AbsoluteBox, ActionButton } from '../../../../../ui';
// feature dispatch-detail-new
import {
  acceptLoadDriverCarrierRateRequest,
  declineLoadDriverCarrierRateRequest } from '../../../load/actions';
//////////////////////////////////////////////////

const enhanceWithoutConnect = compose(
  withState('showDeclineReasonForm', 'setShowDeclineReasonForm', false),
  withHandlers({
    handleAcceptRate: (props: Object) => () => {
      const {
        guid,
        isCarrierRate,
        acceptLoadDriverCarrierRateRequest } = props;
      acceptLoadDriverCarrierRateRequest({ guid, isCarrierRate });
    },
    handleDeclineRate: (props: Object) => (declineReasonCodeGuid: string) => {
      const {
        guid,
        isCarrierRate,
        declineLoadDriverCarrierRateRequest } = props;
      declineLoadDriverCarrierRateRequest({ guid, isCarrierRate, declineReasonCodeGuid });
    },
  }),
  pure,
);

const enhance = compose(
  connect(
    null,
    {
      acceptLoadDriverCarrierRateRequest,
      declineLoadDriverCarrierRateRequest,
    },
  ),
  enhanceWithoutConnect,
);

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');

const actionButtonStyles = {
  mr: 15,
  px: '8px',
  height: 30,
  width: 150,
  fontSize: 14,
  type: 'button',
  minWidth: 'unset',
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: darkBlueColor,
  textTransform: 'uppercase',
  border: `1px solid ${darkBlueColor}`,
};

const ActionButtonComponent = ({ text, styles, handleClick }: Object) => (
  <ActionButton {...R.mergeRight(actionButtonStyles, styles)} onClick={handleClick}>
    {text}
  </ActionButton>
);

const declineReasonField = {
  width: 250,
  type: 'select',
  afterTop: '15px',
  afterRight: '15px',
  labelDisplay: 'none',
  options: 'declineReasonOptions',
  fieldName: GC.FIELD_DECLINE_REASON_CODE_GUID,
};

const formikEnhance = withFormik({
  mapPropsToValues: () => ({ declineStatusReason: null }),
  handleSubmit: ({ declineReasonCodeGuid }: Object, { props }: Object) =>
    props.submitAction(declineReasonCodeGuid),
});

const DeclineReasonForm = formikEnhance((props: Object) => (
  <Box width='100%' height='100%'>
    <Flex
      cursor='pointer'
      justifyContent='flex-end'
      onClick={() => props.setShowDeclineReasonForm(false)}
    >
      {I.renderCancelRecording(whiteColor, null, null, 'transparent', 'transparent')}
    </Flex>
    <form onSubmit={props.handleSubmit}>
      <Flex mt={100} flexDirection='column'>
        <FieldsetComponent
          {...G.getFormikProps(props)}
          fields={R.of(Array, declineReasonField)}
          optionsForSelect={{ declineReasonOptions: props.declineReasonOptions }} />
        <ActionButtonComponent
          styles={{ mt: 10, type: 'submit' }}
          text={G.getWindowLocale('titles:decline-load', 'Decline Load')} />
      </Flex>
    </form>
  </Box>
));

const AcceptSection = ({ handleAcceptRate, setShowDeclineReasonForm }: Object) => (
  <Flex width='100%' justifyContent='center'>
    <ActionButtonComponent
      handleClick={handleAcceptRate}
      text={G.getWindowLocale('titles:accept-load', 'Accept Load')} />
    <ActionButtonComponent
      handleClick={() => setShowDeclineReasonForm(true)}
      text={G.getWindowLocale('titles:decline-load', 'Decline Load')} />
  </Flex>
);

const AcceptDeclineRate = (props: Object) => (
  <AbsoluteBox
    p={15}
    zIndex={11}
    width='100%'
    height={400}
    bg='rgba(0, 0, 0, 0.23)'
  >
    {
      G.isFalse(props.showDeclineReasonForm) &&
      <AcceptSection
        handleAcceptRate={props.handleAcceptRate}
        setShowDeclineReasonForm={props.setShowDeclineReasonForm} />
    }
    {
      G.isTrue(props.showDeclineReasonForm) &&
      <DeclineReasonForm
        submitAction={props.handleDeclineRate}
        declineReasonOptions={props.declineReasonOptions}
        setShowDeclineReasonForm={props.setShowDeclineReasonForm} />
    }
  </AbsoluteBox>
);

export const AcceptDeclineRateWithoutConnect = enhanceWithoutConnect(AcceptDeclineRate);

export default enhance(AcceptDeclineRate);
