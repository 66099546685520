import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
// feature carrier-profile
import GeneralDetails from './general-details';
import { useProfile } from '../../hooks/use-profile';
import CarrierProfileWrapper from '../../components/wrapper';
import FormGroupTable, { ReportFormGroupTable } from '../../components/form-group-table';
import {
  AccessorialFormGroupTable,
  customerContractBillToSettings,
  customerContractAwardedLaneSettings,
} from '../../settings';
import {
  makeSelectConfigGroup,
  makeSelectGeneralDetails,
  makeSelectCustomerContractGeneralDetails,
} from '../../selectors';
//////////////////////////////////////////////////

const CarrierContract = (props: Object) => {
  const { handleGoBack, expandedContainer, closeExpandedContainer } = props;

  const configGroup = useSelector(makeSelectConfigGroup());
  const customerContractDetails = useSelector(makeSelectCustomerContractGeneralDetails());

  const { name, [GC.BRANCH_GUID]: carrierBranchGuid } = useSelector(makeSelectGeneralDetails());

  const {
    dispatch,
    handleClose,
    commonActions,
    setSaveAndClose,
    handleGoToCarrier,
    handleUpdateGeneralDetails,
  } = useProfile({
    handleGoBack,
    expandedContainer,
    closeExpandedContainer,
    type: 'customerContract',
    generalDetails: customerContractDetails,
  });

  const { guid, carrierGuid } = customerContractDetails;

  const initialValues = {
    ...customerContractDetails,
    [GC.FIELD_MODE]: R.path([GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], customerContractDetails),
  };

  if (G.isAnyNilOrEmpty([guid, configGroup, carrierBranchGuid])) return <Box width='100%' height='100vh' bg='bgGrey' />;

  const commonProps = {
    ...commonActions,
    dispatch,
    configGroup,
    carrierGuid,
    primaryObjectGuid: guid,
    primaryObjectBranchGuid: carrierBranchGuid,
  };

  return (
    <CarrierProfileWrapper
      pb={120}
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      id='customer_contract_wrapper'
      setSaveAndClose={setSaveAndClose}
      expandedContainer={expandedContainer}
      form='customer_contract_general_details'
    >
      <GeneralDetails
        {...commonActions}
        carrierName={name}
        dispatch={dispatch}
        initialValues={initialValues}
        handleGoToCarrier={handleGoToCarrier}
        carrierBranchGuid={carrierBranchGuid}
        submitAction={handleUpdateGeneralDetails}
      />
      <AccessorialFormGroupTable {...commonProps} groupName='customerContract.accessorial' />
      <FormGroupTable {...commonProps} group={customerContractBillToSettings} />
      <ReportFormGroupTable
        {...commonProps}
        filterSelectMaxMenuHeight={120}
        reportSelectMaxMenuHeight={120}
        group={customerContractAwardedLaneSettings}
        groupName={R.prop('groupName', customerContractAwardedLaneSettings)}
      />
    </CarrierProfileWrapper>
  );
};

export default CarrierContract;
