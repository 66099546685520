import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  distanceCalculatorConfigFieldSettings,
  defaultDistanceCalculatorConfigFields,
  getDistanceCalculatorConfigValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultDistanceCalculatorConfigFields,
      props.initialValues,
    ),
    validationSchema: ({ isEditMode }: Object) => Yup.lazy(
      ({ integrationType }: Object) => Yup.object().shape(
        getDistanceCalculatorConfigValidationSchema(isEditMode, integrationType),
      ),
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;
      submitAction(values);
    },
  }),
  pure,
);

const DistanceCalculatorConfigForm = (props: Object) => {
  const {
    isEditMode,
    closeModal,
    handleSubmit,
    optionsForSelect } = props;
  const formikProps = G.getFormikProps(props);

  return (
    <Box mx='auto' width={270}>
      <form onSubmit={handleSubmit}>
        <FieldsetComponent
          {...formikProps}
          isEditMode={isEditMode}
          optionsForSelect={optionsForSelect}
          fields={distanceCalculatorConfigFieldSettings}
          handlers={{ handleDisableIntegrationType: () => isEditMode }} />
        <FormFooter boxStyles={{ py: '10px' }} closeModal={closeModal} />
      </form>
    </Box>
  );
};

export default enhance(DistanceCalculatorConfigForm);
