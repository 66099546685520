import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../../common/selectors';
import { addCarrierRatesRequest, sendTelToMacroPointRequest } from '../../../../common/actions';
// components
import { Table } from '../../../../components/table';
import { Tabs2 } from '../../../../components/tabs-mui';
import { TitlePanel } from '../../../../components/title-panel';
import { EditReport } from '../../../../components/edit-report';
import { PageActions } from '../../../../components/page-actions';
import { withPromptModal } from '../../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../../components/edit-report/helpers';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import { startPivotTable } from '../../../pivot-table/actions';
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../../permission/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// report-common
import { reportEnhancer } from '../../../../report-common';
// icons
import * as I from '../../../../svgs';
// ui
import { ListWrapper, IconWrapper, ZOrderWrapper } from '../../../../ui';
// utilities
import { getOrderEntryRoute } from '../../../../utilities/routes';
// feature dispatch-report
import { cloTelTableSettings } from '../../settings/table-settings';
// feature dispatch-report/load
import { startTelEvents } from '../actions';
import { getListActionsOpt, getTabs } from '../helpers';
import { withPageActionsHandlers } from '../hocs/with-page-actions-handlers';
// feature dispatch-report/load/load-by-order
import { columnSettings, FILTER_PARAMS } from './settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  dispatchTelListRequest,
  createReferenceRequest,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  addCarrierRatesByReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withPageActionsHandlers,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const elementActionsComponent = (tel: Object) => (
    <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, tel)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  const tableSettings = G.getTableSettingsWithMaxHeightByConditions({
    reportList,
    filterParams,
    selectedReport,
    tableSettings: cloTelTableSettings,
  });

  const data = {
    itemList,
    allChecked,
    totalCount,
    pagination,
    tableSettings,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    loading: R.and(loading, G.isNilOrEmpty(itemList)),
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getItemListRequest,
    ),
  };

  return <Table {...data} />;
};

export const LoadListComponent = (props: Object) => {
  const {
    itemList,
    activeTab,
    totalCount,
    handlePrint,
    getXMLRequest,
    startTelEvents,
    startPivotTable,
    handleSetActiveTab,
    handleAddCarrierRate,
    handleDispatchTelList,
    handleAddReferenceList,
    handlePostTelToLoadBoard,
    handleSendTelToMacroPoint,
    handleAddCarrierRateByReport,
  } = props;

  const handleClickTab = (tabIndex: number) => {
    if (R.equals(tabIndex, 0)) G.goToRoute(GC.ROUTE_PATH_TEL_REPORT_LIST);

    if (R.equals(tabIndex, 2)) startTelEvents();

    if (R.equals(tabIndex, 3)) startPivotTable(GC.PIVOT_TEL_REPORT);

    handleSetActiveTab(tabIndex);
  };

  const customTitleComponent = (
    <Tabs2
      count={totalCount}
      activeTab={activeTab}
      tabs={getTabs(activeTab)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  const listActionsOpt = getListActionsOpt({
    handlePrint,
    getXMLRequest,
    handleAddCarrierRate,
    handleDispatchTelList,
    handleAddReferenceList,
    handlePostTelToLoadBoard,
    handleSendTelToMacroPoint,
    handleAddCarrierRateByReport,
  });

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          height='auto'
          withCount={true}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          type={GC.TEL_BY_CLO_REPORT}
          hiddenRightFilterInfo={false}
          customTitleComponent={customTitleComponent}
          exportPermissions={R.of(Array, PC.TEL_WRITE)}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.TEL_WRITE]}>
        <PageActions
          listActions={listActionsOpt}
          shadowColor={G.getTheme('createButton.shadowColor')}
          count={R.filter((item: Object) => item.selected, R.or(itemList, [])).length}
          mainAction={{
            permissions: [PC.CLO_WRITE],
            action: () => G.goToRoute(getOrderEntryRoute()),
            text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setReports,
  printRequest,
  getXMLRequest,
  setUsedReport,
  startTelEvents,
  startPivotTable,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  createReferenceRequest,
  resetListAndPagination,
  dispatchTelListRequest,
  addCarrierRatesRequest,
  exportReportDataRequest,
  sendTelToMacroPointRequest,
  changeDefaultReportRequest,
  addCarrierRatesByReportRequest,
})(enhance(LoadListComponent));
