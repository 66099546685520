import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
import { pure, compose, lifecycle, withState } from 'react-recompose';
// components
import { Switcher } from '../../../components/switcher';
import { DateRangeMui } from '../../../components/date-range';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncGetBranchChildrenWithShared } from '../../../hocs';
// forms
import { InputWrapper } from '../../../forms/formik/fieldset2/input-wrapper';
// ui
import { Flex, ReactSelect } from '../../../ui';
// feature dashboards
import { formatDate, getFormattedDateRangeFilters } from '../helpers';
import {
  DAY_3,
  DAY_7,
  DAY_14,
  DAY_30,
  DAY_RANGE,
} from '../constants';
//////////////////////////////////////////////////

const enhance = compose(
  withState('customer', 'setCustomer', null),
  withAsyncGetBranchChildrenWithShared,
  lifecycle({
    componentDidMount() {
      this.props.getBranchChildrenWithShared();
    },
  }),
  pure,
);

const EnterpriseSearch = enhance((props: Object) => {
  const { enterprise, setEnterprise, asyncBranchChildrenWithShared } = props;

  const sortByName = R.sortBy(R.compose(R.toLower, R.prop('label')));

  const options = R.compose(
    sortByName,
    R.uniqBy(R.prop(GC.FIELD_VALUE)),
    G.mapNameGuidObjectPropsToLabelValueObject,
  )(asyncBranchChildrenWithShared);

  const selectHandler = (option: Object) => {
    setEnterprise(G.getValueFromObject(option));
  };


  return (
    <InputWrapper
      inputWrapperStyles={{ width: 180 }}
      label={G.getWindowLocale('titles:customer', 'Customer')}
    >
      <ReactSelect
        name='customer'
        options={options}
        value={enterprise}
        isClearable={true}
        onChange={selectHandler}
      />
    </InputWrapper>
  );
});

const switchIndexMap = {
  [DAY_3]: 0,
  [DAY_7]: 1,
  [DAY_14]: 2,
  [DAY_30]: 3,
  [DAY_RANGE]: 4,
};

const switchOptions = [
  {
    value: DAY_3,
    width: 50,
    name: G.getWindowLocale('titles:3-days', '3 days'),
  },
  {
    value: DAY_7,
    width: 50,
    name: G.getWindowLocale('titles:week', 'week'),
  },
  {
    value: DAY_14,
    width: 50,
    name: G.getWindowLocale('titles:2-day', '2 week'),
  },
  {
    value: DAY_30,
    width: 50,
    name: G.getWindowLocale('titles:month', 'month'),
  },
  {
    value: DAY_RANGE,
    width: 50,
    name: G.getWindowLocale('titles:range', 'range', { caseAction: 'lowerCase' }),
  },
];

const DashboardFilters = (props: Object) => {
  const {
    filters,
    setDateFiltersToStore,
    setEnterpriseFilterToStore,
  } = props;

  const [showDateRange, setShowDateRange] = useState(false);

  const { days, enterprise } = filters;

  const onSwitchDays = useCallback((days: number) => {
    if (R.equals(days, DAY_RANGE)) return setShowDateRange(true);

    setShowDateRange(false);

    const { dateTo, dateFrom } = getFormattedDateRangeFilters(days);

    setDateFiltersToStore({ days, dateTo, dateFrom });
  }, []);

  const handleSetFilterDates = useCallback((dates: number) => {
    const dateTo = formatDate(G.toEndOfDayFromDate(dates.dateTo));
    const dateFrom = formatDate(G.toStartOfDayFromDate(dates.dateFrom));

    setDateFiltersToStore({
      dateTo,
      dateFrom,
      days: DAY_RANGE,
    });
  }, []);

  return (
    <Flex pb={10} gap={15}>
      <Switcher
        version={3}
        fontSize={10}
        options={switchOptions}
        onSwitch={onSwitchDays}
        selectedOptionIndex={switchIndexMap[days]}
      />
      {
        showDateRange &&
        <DateRangeMui
          {...filters}
          width={80}
          withIcon={true}
          useNewMuiInputField={true}
          popperPlacement='bottom-end'
          onSelectDateRange={handleSetFilterDates}
          maxDate={G.momentAddYearsFromCurrent(100)}
          labelTo={G.getWindowLocale('titles:to', 'To')}
          minDate={G.momentSubtractYearsFromCurrent(100)}
          labelFrom={G.getWindowLocale('titles:from', 'From')}
        />
      }
      <EnterpriseSearch enterprise={enterprise} setEnterprise={setEnterpriseFilterToStore} />
    </Flex>
  );
};

export default DashboardFilters;
