import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectStore = ({ commissionAssignment }: Object) => commissionAssignment;

const makeSelectCommissionAssignmentList = () => createSelector(
  selectStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['commissionAssignmentList']),
  ),
);

export { makeSelectCommissionAssignmentList };
