import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import { truckOnMap, fleetsOnMap, driverOnMap, trailerOnMap } from '../../svgs';
//////////////////////////////////////////////////

export const fleetTypeObjectMap = {
  all: 'all',
  trucks: 'trucks',
  drivers: 'drivers',
  trailers: 'trailers',
};

export const fleetLNLNameMap = {
  trucks: 'truckLatestLocation',
  drivers: 'driverLatestLocation',
  trailers: 'trailerLatestLocation',
};

export const fleetMapIcon = {
  all: fleetsOnMap,
  trucks: truckOnMap,
  drivers: driverOnMap,
  trailers: trailerOnMap,
};

export const renderFleetMapIcon = (fleetType: string) => (
  fleetMapIcon[fleetType]()
);

export const getLatLngFromFleets = (fleets: Array) => (
  R.compose(
    R.prop('latLng'),
    R.head(),
  )(fleets)
);

export const mapFleets = (lnlPropName: string, fleets: Array) => {
  const grouped = {};
  R.forEach((item: Object) => {
    const lnl = R.prop(lnlPropName, item);

    if (G.isNilOrEmpty(lnl)) return;

    const lat = Math.round(R.path([GC.FIELD_LATITUDE], lnl) * 1000) / 1000;
    const lng = Math.round(R.path([GC.FIELD_LONGITUDE], lnl) * 1000) / 1000;

    const resultItem = {
      lnl,
      latLng: { lat, lng },
      shortid: G.genShortId(),
      fleetObj: R.omit(lnlPropName, item),
    };

    let exist = false;

    R.forEachObjIndexed((value: Array, key: string) => {
      const item = R.find(R.propEq(resultItem.latLng, 'latLng'), value);

      if (G.isNotNil(item)) {
        exist = true;
        grouped[key].push(resultItem);
      }
    }, grouped);

    if (G.isFalse(exist)) {
      grouped[G.genShortId()] = R.of(Array, resultItem);
    }
  }, fleets);

  return grouped;
};

export const groupByLatLng = (
  propName: string,
  fleets: Array,
) => {
  if (R.and(R.isNil(propName), R.isNil(fleets))) return null;
  const grouped = mapFleets(propName, fleets);
  return R.values(grouped);
};

const getItemsLength = (items: Array) => (
  R.length(items)
);

export const getFleetsLength = (props: Object) => R.sum([
  getItemsLength(props.drivers),
  getItemsLength(props.trucks),
  getItemsLength(props.trailers),
]);

export const getMultiSwitchOptions = (props: Object) => [
  {
    width: 80,
    value: 'all',
    name: `${G.getWindowLocale('titles:all', 'All')} (${getFleetsLength(props)})`,
  },
  {
    width: 80,
    value: 'drivers',
    name: `${G.getWindowLocale('titles:drivers', 'Drivers')} (${getItemsLength(props.drivers)})`,
  },
  {
    width: 80,
    value: 'trucks',
    name: `${G.getWindowLocale('titles:trucks', 'Trucks')} (${getItemsLength(props.trucks)})`,
  },
  {
    width: 80,
    value: 'trailers',
    name: `${G.getWindowLocale('titles:trailers', 'trailers')} (${getItemsLength(props.trailers)})`,
  },
];

export const getDivisionBranchesOptions = () => {
  const amousBranches = G.getItemFromWindow('amousBranches');

  const divisionOptions = [];

  R.forEach(
    (branch: Object) => {
      if (R.propEq(GC.BRANCH_TYPE_ENUM_DIVISION, GC.BRANCH_TYPE, branch)) {
        divisionOptions.push({
          value: G.getGuidFromObject(branch),
          label: R.prop('enterpriseName', branch),
        });
      }
    },
    R.values(amousBranches),
  );

  return divisionOptions;
};

export const getFilteredFleetsEntity = R.curry(
  (enterpriseFilter: any, fleets: Object, type: string) => R.compose(
    (entities: Array) => G.ifElse(
      G.isNotNilAndNotEmpty(enterpriseFilter),
      R.filter(
        R.propEq(enterpriseFilter, GC.BRANCH_GUID),
        entities,
      ),
      entities,
    ),
    R.prop(type),
  )(fleets),
);
