import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Form, Fieldset2 } from '../../../forms';
// feature carrier
import {
  terminalFields,
  defaultTerminalFields,
  getTerminalFieldsValidationSchema,
} from '../settings/fields-settings';
// ui
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: () => defaultTerminalFields,
    validationSchema: getTerminalFieldsValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, carrierGuid, submitAction } = props;

      const reqBody = R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, values);

      submitAction(reqBody);

      closeModal();
    },
  }),
  pure,
);

export const CreateTerminalComponent = (props: Object) => (
  <Form onSubmit={props.handleSubmit}>
    <Fieldset2
      fields={terminalFields}
      {...G.getFormikProps(props)}
      locationType={R.path(['carrierConfigs', 'locationType'], props)}
      fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between' }}
    />
    <FormFooter2 />
  </Form>
);

export default enhance(CreateTerminalComponent);
