import * as LC from '../constants';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: '210px',
  errorTop: '110%',
  afterTop: '14px',
  labelWidth: '100%',
  flexDirection: 'column',
};

export const dashboardListField = [
  {
    ...defaultFieldStyles,
    zIndex: '12',
    type: 'searchselect',
    name: LC.DASHBOARD_LIST,
    shouldCustomChange: true,
    options: 'dashboardOptions',
    loc: 'titles:dashboard-list',
    fieldName: LC.DASHBOARD_LIST,
  },
];

export const searchGroupListField = [
  {
    ...defaultFieldStyles,
    type: 'text',
    name: LC.SEARCH_GROUP,
    shouldCustomChange: true,
    fieldName: LC.SEARCH_GROUP,
    loc: 'titles:search-group',
  },
];

export const defaultRightSidebarFields = {
  [LC.SEARCH_GROUP]: '',
  [LC.DASHBOARD_LIST]: '',
};
