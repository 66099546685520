import React from 'react';
import { connect } from 'react-redux';
// components
import { closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, ModalActionButton } from '../../../ui';
// feature dispatch-planner-events
import { setRouteErrors, refreshPlannerDataRequest } from '../actions';
//////////////////////////////////////////////////

const RefreshDataModal = ({ closeModal, setRouteErrors, refreshPlannerDataRequest }: Object) => {
  const handleRefreshData = () => {
    refreshPlannerDataRequest();
    setRouteErrors({ 'requestErrors': null });

    closeModal();
  };

  return (
    <Flex fontSize={18} alignItems='center' flexDirection='column'>
      <Box mb={20}>
        {
          `${G.getWindowLocale('message:data-changed', 'The data was changed')}. ${
            G.getWindowLocale('message:refresh-planning', 'Please, refresh the data and check your planning')}`
        }
      </Box>
      <ModalActionButton width={120} onClick={handleRefreshData}>
        {G.getWindowLocale('actions:refresh', 'Refresh')}
      </ModalActionButton>
    </Flex>
  );
};

export default connect(null, {
  closeModal,
  setRouteErrors,
  refreshPlannerDataRequest,
})(RefreshDataModal);
