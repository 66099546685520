import React from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// features
import { PayrollTotalsForDriverCardsProfile } from '../../payroll/components/payroll-totals';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withBooleanStatus, withConnectModalAndLoaderActions } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Span } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const bgGreyColor = G.getTheme('colors.bgGrey');
const actionColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const enhance = compose(
  withBooleanStatus(false),
  withConnectModalAndLoaderActions,
  withState('payrollInfo', 'setPayrollInfo', null),
  withHandlers({
    handleGetPayrollInfo: (props: Object) => async () => {
      const {
        openLoader,
        driverGuid,
        closeLoader,
        payrollInfo,
        setPayrollInfo,
      } = props;

      if (G.isNotNilAndNotEmpty(payrollInfo)) return;

      G.callFunction(openLoader);

      try {
        const enterpriseGuid = G.getAmousCurrentBranchGuidFromWindow();

        const options = { params: { enterpriseGuid }};

        const res = await sendRequest('get', endpointsMap.getTelDriverCardsDriverPayrollInfo(driverGuid), options);

        const { data, status } = res;

        if (G.isResponseSuccess(status, data)) {
          G.callFunctionWithArgs(setPayrollInfo, data);
        } else {
          G.handleFailResponseSimple(
            res,
            true,
            'withPayrollInfo -> handleGetPayrollInfo',
          );
        }

        G.callFunction(closeLoader);
      } catch (error) {
        G.callFunction(closeLoader);

        G.handleException(error, 'withPayrollInfo -> handleGetPayrollInfo');
      }
    },
  }),
  withHandlers({
    handleOpenPayrollInfo: (props: Object) => (event: Object) => {
      const { handleGetPayrollInfo, handleToggleBooleanStatus } = props;

      handleGetPayrollInfo();
      handleToggleBooleanStatus(event);
    },
  }),
  pure,
);

const PayrollInfo = ({
  payrollInfo,
  booleanStatus,
  handleOpenPayrollInfo,
}: Object) => (
  <Box
    p='20px 15px'
    color={textColor}
    borderBottom='1px solid'
    borderColor={bgGreyColor}
  >
    <Box
      fontSize={12}
      cursor='pointer'
      fontWeight='bold'
      textTransform='uppercase'
      onClick={handleOpenPayrollInfo}
    >
      {G.getWindowLocale('titles:payroll-info', 'Payroll Info')}
      <Span ml='5px'>
        {
          G.ifElse(
            booleanStatus,
            I.arrowUpSimple,
            I.arrowDownSimple,
          )(actionColor)
        }
      </Span>
    </Box>
    {
      booleanStatus && payrollInfo &&
      <Box mt={10} border={`1px solid ${bgGreyColor}`}>
        <PayrollTotalsForDriverCardsProfile payrollInfo={payrollInfo} />
      </Box>
    }
  </Box>
);

export default enhance(PayrollInfo);
