import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
// report-common
import { getReportReducers } from '../../report-common';
// feature dispatch-report/load
import * as A from './actions';
//////////////////////////////////////////////////

const {
  selectItem,
  initialState,
  setListLoading,
  setInitialState,
  resetListAndPagination,
} = getReportReducers();

const getItemListSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;

  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );

  const newItems = data.results.map((item: Object, i: number) => R.mergeRight(
    item,
    {
      selected: false,
      expanded: false,
      index: R.add(i, indexAdditional),
    },
  ));

  const list = R.mergeRight(itemList, G.indexByGuid(newItems));
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const deleteItemsSuccess = (state: Object, data: Arary) => (
  P.$all(
    P.$set('itemList', R.omit(data, state.itemList)),
    P.$set('totalCount', G.ifElse(R.equals(state.totalCount, 0), 0, R.subtract(state.totalCount, data.length))),
    state,
  )
);

const toggleDetails = (state: Object, { guid }: Object) => (
  P.$toggle(`itemList.${guid}.expanded`, state)
);

export default createReducer({
  [A.selectItem]: selectItem,
  [A.toggleDetails]: toggleDetails,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.deleteItemsSuccess]: deleteItemsSuccess,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.resetListAndPagination]: resetListAndPagination,
}, initialState);
