import React from 'react';
import * as R from 'ramda';
// components
import { TabsMui } from '../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const tabs = [
  {
    value: GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST,
    text: G.getWindowLocale('titles:driver-payroll-accessorial-list', 'Driver Payroll Accessorial List'),
  },
  {
    value: GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST,
    text: G.getWindowLocale('titles:vendor-payroll-accessorial-list', 'Vendor Payroll Accessorial List'),
  },
];

const PayrollAccessorialTabs = ({ totalCount }: Object) => {
  const pathname = window.location.pathname;
  const activeTabIndex = R.findIndex(R.propEq(pathname, 'value'), tabs);
  const correctTabs = R.assocPath([activeTabIndex, 'withCount'], true, tabs);

  const setActiveMuiTab = (route: Object) => {
    if (R.equals(route, pathname)) return;

    G.goToRoute(route);
  };

  return (
    <TabsMui
      count={totalCount}
      tabs={correctTabs}
      setActiveMuiTab={setActiveMuiTab}
      activeMuiTab={R.path([activeTabIndex, 'value'], tabs)}
    />
  );
};

export default PayrollAccessorialTabs;
