import React from 'react';
// components
import { TextCollapse } from '../../../components/text-collapse';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const TextCollapseComponent = () => {
  const description = '<TextCollapse /> component allows displaying collapsible text content with a "Show More/Show Less" functionality';

  const usage = (
    <ul>
      <li>Parameter `<i>rootProps</i>` is an additional styles to be applied to the root container.</li>
      <li>Parameter `<i>text</i>` is the text content to be displayed and collapsed when necessary.</li>
      <li>Parameter `<i>lessHeight</i>` is a height in pixels at which the text collapses to show a `Show More` link.</li>
    </ul>
  );

  const mockupText = `Contrary to popular belief, Lorem Ipsum is not simply random text.
                      It has roots in a piece of classical Latin literature from 45 BC,
                      making it over 2000 years old. Richard McClintock, a Latin professor
                      at Hampden-Sydney College in Virginia, looked up one of the more obscure
                      Latin words, consectetur, from a Lorem Ipsum passage, and going through
                      the cites of the word in classical literature, discovered the undoubtable
                      source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                      Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
                      This book is a treatise on the theory of ethics, very popular during the Renaissance.
                      The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
                      section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced
                      below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et
                      Malorum" by Cicero are also reproduced in their exact original form, accompanied by
                      English versions from the 1914 translation by H. Rackham.`;

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='TextCollapse'
      description={description}
      path='src/components/text-collapse'
    >
      <TextCollapse
        lessHeight={100}
        text={mockupText}
        rootProps={{
          width: 535,
          maxHeight: 200,
          overflow: 'auto',
        }}
      />
    </ComponentStoryWrapper>
  );
};

export default TextCollapseComponent;
