import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// hocs
import { withFixedPopover } from '../../../hocs';
// report-common
import { reportEnhancer } from '../../../report-common';
// ui
import {
  IconWrapper,
  ListWrapper,
  ZOrderWrapper,
} from '../../../ui';
// feature contracts
import { withAddContractAsync } from '../hocs';
import { RowActions } from './components/row-actions';
import { tableSettings } from '../settings/table-settings';
import { columnSettings } from './settings/column-settings';
import { FIELDS_AND_FILTER_PARAMS } from './settings/filter-params';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectFilterProps,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  resetListAndPaginationAndGetItemsRequest,
} from './actions';
//////////////////////////////////////////////////

const withReport = compose(
  withFixedPopover,
  reportEnhancer,
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            entity={data}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            getItemListRequest={props.getItemListRequest}
            resetListAndPagination={props.resetListAndPagination}
            handleDeleteEntity={(guid: string) => props.deleteItemRequest(R.of(Array, guid))}
          />
        ),
      })
    ),
  }),
  withPromptModal(FIELDS_AND_FILTER_PARAMS),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

// TODO: check moving to global report hocs
const withDeleteReportEntities = compose(
  withHandlers({
    handleOpenSubmitDeleteReportEntity: (props: Object) => () => {
      const {
        itemList,
        openModal,
        closeModal,
        deleteItemRequest,
      } = props;

      const selectedList = R.filter(R.prop('selected'), itemList);

      if (R.propEq(0, 'length', selectedList)) return;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: `${R.length(selectedList)} ${
          G.getWindowLocale('titles:carrier-contracts', 'Carrier Contract(s)')
        }`,
        submitAction: () => {
          deleteItemRequest(R.map(R.prop(GC.FIELD_GUID), selectedList));
          closeModal();
        },
      });

      openModal(modalContent);
    },
  }),
);

const enhance = R.compose(
  withAddContractAsync({ contractType: 'carrier', pageType: 'carrierContracts' }),
  withReport,
  withDeleteReportEntities,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
    setExpandedContainerOptions,
  } = props;

  if (R.not(selectedReport)) return null;

  const elementActionsComponent = (entity: Object) => (
    <AuthWrapper has={[PC.CARRIER_READ, PC.CARRIER_WRITE]}>
      {
        R.or(
          G.isTrue(R.prop('editable', entity)),
          G.getIsAmousUserSuperAdminFromWindow(),
        ) && (
          <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, entity)}>
            {I.threeDots()}
          </IconWrapper>
        )
      }
    </AuthWrapper>
  );

  const useNewCarrierProfile = G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_CARRIER_PROFILE);

  const actionButtons = [
    {
      iconName: 'pencil',
      getPermissions: ({ editable }: Object) => G.ifElse(
        R.or(G.isTrue(editable), G.getIsAmousUserSuperAdminFromWindow()),
        [PC.CARRIER_WRITE],
        '',
      ),
      action: (guid: string, entity: Object) => setExpandedContainerOptions({
        opened: true,
        visitPageGuid: G.ifElse(useNewCarrierProfile, guid, R.prop(GC.GRC.CARRIER_GUID, entity)),
        componentType: G.ifElse(useNewCarrierProfile, GC.PAGE_CARRIER_CONTRACT_DETAILS, GC.PAGE_CARRIER_PROFILE),
      }),
    },
  ];

  const allChecked = G.isAllChecked(itemList);

  const data = {
    itemList,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    withResizableColumns: true,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    loading: R.and(loading, G.isNilOrEmpty(itemList)),
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FIELDS_AND_FILTER_PARAMS),
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

export const CarrierContractList = (props: Object) => {
  const {
    itemList,
    handleClickAddContractAsync,
    handleOpenSubmitDeleteReportEntity,
  } = props;

  const mainLightColor = G.getTheme('colors.light.mainLight');

  const listActionsOpt = [
    {
      type: 'massAction',
      permissions: [PC.ITEM_TEMPLATE_WRITE],
      action: handleOpenSubmitDeleteReportEntity,
      icon: I.trash(mainLightColor, '20px', '20px'),
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];

  const count = R.compose(
    R.length,
    R.filter((item: Object) => item.selected),
  )(R.or(itemList, []));

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          hiddenRightFilterInfo={false}
          type={GC.CARRIER_CONTRACT_REPORT}
          exportPermissions={R.of(Array, PC.CARRIER_WRITE)}
          title={G.getWindowLocale('titles:carrier-contracts', 'Carrier Contract(s)')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.CARRIER_WRITE]}>
        <PageActions
          count={count}
          listActions={listActionsOpt}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            permissions: [PC.CARRIER_WRITE],
            action: handleClickAddContractAsync,
            icon: I.plusRound(mainLightColor, '25px', '25px'),
            text: G.getWindowLocale('actions:create-contract', 'Create Contract'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  filterProps: makeSelectFilterProps(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  setExpandedContainerOptions,
  resetListAndPaginationAndGetItemsRequest,
})(enhance(CarrierContractList));
