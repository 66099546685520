import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  STATE_OPTIONS,
  COUNTRY_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  DEFAULT_WEIGHT_UOM_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_CARRIER_COMPENSATION_RATE_TYPE_OPTIONS,
  DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
} from '../../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 220,
};

const inputWrapperStylesForWiderFields = {
  mb: 25,
  width: 300,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

export const generalFieldSettings = [
  {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_NAME,
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    calendarInputWrapperStyles,
    label: ['titles:effective-date'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    calendarInputWrapperStyles,
    minDate: G.getCurrentDate(),
    label: ['titles:expiration-date'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:active'],
    fieldName: GC.FIELD_ACTIVE,
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'geoFencingZoneList',
    label: ['titles:origin-zone-name'],
    fieldName: GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID,
    disabled: (_: any, values: Object) =>
      G.isNotNilAndNotEmpty(R.path([GC.FIELD_ORIGIN, GC.FIELD_GEO_FENCING_ZONE_COUNTRIES], values)),
    reactSelectAdditionalStyles: {
      menu: (baseStyles: Object) => ({
        ...baseStyles,
        zIndex: 15,
      }),
    },
  },
  {
    type: 'reactSelect',
    options: 'geoFencingZoneList',
    label: ['titles:destination-zone-name'],
    inputWrapperStyles: { ...inputWrapperStyles, mr: 507 },
    fieldName: GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID,
    disabled: (_: any, values: Object) =>
      G.isNotNilAndNotEmpty(R.path([GC.FIELD_DESTINATION, GC.FIELD_GEO_FENCING_ZONE_COUNTRIES], values)),
    reactSelectAdditionalStyles: {
      menu: (baseStyles: Object) => ({
        ...baseStyles,
        zIndex: 15,
      }),
    },
  },
];

export const zoneFieldSettings = (prefix: string) => [
  {
    type: 'addressAutocomplete',
    label: ['titles:search-city'],
    fieldName: `${prefix}.searchCity`,
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13 },
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
  },
  {
    type: 'multiItems',
    inputWrapperStyles,
    label: ['titles:cities'],
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`,
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
    placeholder: G.getWindowLocale('titles:enter-city-and-press-enter', 'Enter City and Press Enter'),
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: STATE_OPTIONS,
    label: ['titles:states'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`,
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: COUNTRY_OPTIONS,
    label: ['titles:countries'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`,
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
  },
  {
    inputWrapperStyles,
    type: 'addressAutocomplete',
    label: ['titles:search-zip'],
    fieldName: `${prefix}.searchZip`,
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
  },
  {
    isMulti: true,
    type: 'multiItems',
    inputWrapperStyles,
    label: ['titles:zip-codes'],
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`,
    placeholder: G.getWindowLocale('titles:enter-zip-and-press-enter', 'Enter Zip and Press Enter'),
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
  },
  {
    label: ['titles:zip-from'],
    inputWrapperStyles: { width: 220 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`,
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
  },
  {
    label: ['titles:zip-to'],
    inputWrapperStyles: { width: 220 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`,
    disabled: (_: any, values: Object) => G.isNotNilAndNotEmpty(R.prop(`${prefix}ZoneGuid`, values)),
  },
];

export const compensationDetailsFieldSettings = [
  {
    step: 'any',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:rate'],
    fieldName: GC.FIELD_CHARGE_RATE,
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:equipments'],
    options: GC.GENERAL_EQUIPMENTS,
    fieldName: GC.FIELD_EQUIPMENTS,
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:service-types'],
    fieldName: GC.FIELD_SERVICE_TYPES,
    options: GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:rate-type'],
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    options: DEFAULT_CARRIER_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:rate-unit'],
    options: GC.FIELD_CHARGE_RATE_UNIT,
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    optionsGroups: DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
    isRequired: ({ values }: Object) => {
      const rateType = G.getPropFromObject(GC.FIELD_CHARGE_RATE_TYPE, values);

      return R.and(G.isNotNilAndNotEmpty(rateType), G.notEquals(rateType, GC.CHARGE_RATE_TYPE_FLAT));
    },
  },
  {
    inputWrapperStyles,
    label: ['titles:rate-minimum'],
    fieldName: GC.FIELD_CHARGE_MIN_RATE,
    disabled: (field: any, values: Object) => R.propEq(GC.CHARGE_RATE_TYPE_FLAT, GC.FIELD_CHARGE_RATE_TYPE, values),
  },
  {
    inputWrapperStyles,
    label: ['titles:rate-maximum'],
    fieldName: GC.FIELD_CHARGE_MAX_RATE,
    disabled: (field: any, values: Object) => R.propEq(GC.CHARGE_RATE_TYPE_FLAT, GC.FIELD_CHARGE_RATE_TYPE, values),
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:transit-days'],
    fieldName: GC.FIELD_TRANSIT_DAYS,
  },
];

const weightRangeFieldSettings = [
  {
    label: ['titles:min-weight'],
    fieldName: GC.FIELD_MIN_WEIGHT,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    label: ['titles:max-weight'],
    fieldName: GC.FIELD_MAX_WEIGHT,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    type: 'select',
    label: ['titles:weight-uom'],
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_WEIGHT_UOM_OPTIONS,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
];

const temperatureRangeFieldSettings = [
  {
    label: ['titles:min-temperature'],
    fieldName: GC.FIELD_MIN_TEMPERATURE,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    label: ['titles:max-temperature'],
    fieldName: GC.FIELD_MAX_TEMPERATURE,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    type: 'select',
    label: ['titles:temperature-uom'],
    options: ITEM_TEMPERATURE_OPTIONS,
    fieldName: GC.FIELD_TEMPERATURE_UOM,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
];

const quantityRangeFieldSettings = [
  {
    label: ['titles:min-quantity'],
    fieldName: GC.FIELD_MIN_QUANTITY,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    label: ['titles:max-quantity'],
    fieldName: GC.FIELD_MAX_QUANTITY,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    type: 'select',
    label: ['titles:package-type'],
    fieldName: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
];

const distanceRangeFieldSettings = [
  {
    label: ['titles:distance-range-from'],
    inputWrapperStyles: inputWrapperStylesForWiderFields,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM,
  },
  {
    label: ['titles:distance-range-to'],
    inputWrapperStyles: inputWrapperStylesForWiderFields,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO,
  },
  {
    type: 'select',
    label: ['titles:distance-range-uom'],
    inputWrapperStyles: inputWrapperStylesForWiderFields,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM,
    options: G.addEmptyOptionToDropDown(DEFAULT_DISTANCE_UOM_OPTIONS),
  },
];

export const compensationRangeSections = [
  {
    title: 'titles:weight-range',
    sectionName: GC.FIELD_WEIGHT,
    fieldSettings: weightRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), weightRangeFieldSettings),
  },
  {
    title: 'titles:distance-range',
    sectionName: GC.FIELD_DISTANCE,
    fieldSettings: distanceRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), distanceRangeFieldSettings),
  },
  {
    title: 'titles:quantity-range',
    sectionName: GC.FIELD_QUANTITY,
    fieldSettings: quantityRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), quantityRangeFieldSettings),
  },
  {
    title: 'titles:temperature-range',
    sectionName: GC.FIELD_TEMPERATURE,
    fieldSettings: temperatureRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), temperatureRangeFieldSettings),
  },
];

const zoneSchema = (zoneGuid: string) => ({
  [GC.FIELD_DRIVER_COMPENSATION_COUNTRIES]: G.ifElse(G.isNilOrEmpty(zoneGuid), G.yupArrayRequired),
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
});

const minMaxNumberValidation = G.yupNumberNotRequired
  .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
  .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999));

export const validationSchema = Yup.lazy((values: Object) => {
  const {
    origin,
    rateType,
    minWeight,
    maxWeight,
    destination,
    minQuantity,
    maxQuantity,
    minTemperature,
    maxTemperature,
    distanceRangeTo,
    originZoneGuid,
    distanceRangeFrom,
    destinationZoneGuid,
  } = values;

  const { countries: originCountries } = R.or(origin, {});

  const { countries: destinationCountries } = R.or(destination, {});

  let schema = {
    [GC.FIELD_EFFECTIVE_DATE]: G.yupStringRequired,
    [GC.FIELD_EXPIRATION_DATE]: G.yupStringRequired,
    [GC.FIELD_ORIGIN]: Yup.object().shape(zoneSchema(originZoneGuid)),
    [GC.FIELD_DESTINATION]: Yup.object().shape(zoneSchema(destinationZoneGuid)),
    [GC.FIELD_NAME]: G.yupStringRequired.max(85, G.getShouldBeFromToCharLocaleTxt(0, 85)),
    [GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID]: G.ifElse(G.isNilOrEmpty(originCountries), G.yupStringRequired),
    [GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID]: G.ifElse(G.isNilOrEmpty(destinationCountries), G.yupStringRequired),
    [GC.FIELD_TRANSIT_DAYS]: G.yupNumberNotRequired
      .min(0, G.getShouldBeFromToCharLocaleTxt(0, 99))
      .max(99, G.getShouldBeFromToCharLocaleTxt(0, 99)),
    // range
    [GC.FIELD_MIN_WEIGHT]: minMaxNumberValidation,
    [GC.FIELD_MAX_WEIGHT]: minMaxNumberValidation,
    [GC.FIELD_MIN_QUANTITY]: minMaxNumberValidation,
    [GC.FIELD_MAX_QUANTITY]: minMaxNumberValidation,
    [GC.FIELD_MIN_TEMPERATURE]: minMaxNumberValidation,
    [GC.FIELD_MAX_TEMPERATURE]: minMaxNumberValidation,
    [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: minMaxNumberValidation,
    [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: minMaxNumberValidation,
    // rate
    [GC.FIELD_CHARGE_RATE_TYPE]: G.yupStringRequired,
    [GC.FIELD_CHARGE_MIN_RATE]: minMaxNumberValidation,
    [GC.FIELD_CHARGE_MAX_RATE]: minMaxNumberValidation,
    [GC.FIELD_CHARGE_RATE]: G.yupNumberRequired
      .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
      .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
    [GC.FIELD_RATE_UNIT]: G.ifElse(
      R.and(G.isNotNilAndNotEmpty(rateType), G.notEquals(rateType, GC.CHARGE_RATE_TYPE_FLAT)),
      G.yupStringRequired,
      G.yupStringNotRequired,
    ),
  };

  if (G.isOneNotNilOrNotEmpty([minWeight, maxWeight])) {
    schema = R.assoc(GC.FIELD_WEIGHT_UOM, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([minQuantity, maxQuantity])) {
    schema = R.assoc(GC.FIELD_PACKAGE_TYPE, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([minTemperature, maxTemperature])) {
    schema = R.assoc(GC.FIELD_TEMPERATURE_UOM, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([distanceRangeTo, distanceRangeFrom])) {
    schema = R.assoc(GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM, G.yupStringRequired, schema);
  }

  return Yup.object().shape(schema);
});

export const defaultValues = {
  [GC.FIELD_MODE]: '',
  [GC.FIELD_NAME]: '',
  [GC.FIELD_ACTIVE]: true,
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_TRANSIT_DAYS]: '',
  [GC.FIELD_SERVICE_TYPE]: '',
  [GC.FIELD_EFFECTIVE_DATE]: '',
  [GC.FIELD_CHARGE_MAX_RATE]: '',
  [GC.FIELD_EXPIRATION_DATE]: '',
  [GC.FIELD_CHARGE_MIN_RATE]: '',
  [GC.FIELD_CHARGE_RATE_TYPE]: '',
  [GC.FIELD_CHARGE_RATE_UNIT]: '',
  [GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID]: null,
  [GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID]: null,
  [GC.FIELD_ORIGIN]: {
    [GC.FIELD_CARRIER_RATE_COUNTRIES]: null,
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: '',
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: '',
  },
  [GC.FIELD_DESTINATION]: {
    [GC.FIELD_CARRIER_RATE_COUNTRIES]: null,
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: '',
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: '',
  },
};

