import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
// forms
import CompensationForm from '../../../forms/forms/compensation';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_TEMPERATURE_OPTIONS,
  DEFAULT_WEIGHT_UOM_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const getAddressPointFieldsByType = (prefix: string) => {
  const localePrefix = G.ifElse(
    R.equals(prefix, GC.FIELD_ORIGIN),
    'titles:origin',
    'titles:destination',
  );

  const getLocaleArr = (key: string) => [localePrefix, key];

  const TableCell = ({ data }: Object) => {
    if (G.isNilOrEmpty(data)) return null;

    const text = R.join(', ', data);

    return (
      <TextComponent
        title={text}
        maxWidth='90%'
        display='block'
        withEllipsis={true}
      >
        {text}
      </TextComponent>
    );
  };

  return {
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:cities'),
      customComponent: (row: Object) =>
        <TableCell data={R.path(['data', prefix, GC.FIELD_GEO_FENCING_ZONE_CITIES], row)} />,
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:states'),
      customComponent: (row: Object) =>
        <TableCell data={R.path(['data', prefix, GC.FIELD_GEO_FENCING_ZONE_STATES], row)} />,
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:countries'),
      customComponent: (row: Object) =>
        <TableCell data={R.path(['data', prefix, GC.FIELD_GEO_FENCING_ZONE_COUNTRIES], row)} />,
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:zip-codes'),
      customComponent: (row: Object) =>
        <TableCell data={R.path(['data', prefix, GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES], row)} />,
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`]: {
      name: getLocaleArr('titles:zip-from'),
      customComponent: R.path(['data', prefix, GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`]: {
      name: getLocaleArr('titles:zip-to'),
      customComponent: R.path(['data', prefix, GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]),
    },
  };
};

const getDisplayedValue = (options: Object, value: string) => R.compose(
  R.path([GC.FIELD_LABEL]),
  R.find(R.propEq(value, GC.FIELD_VALUE)),
)(options);

const settings = {
  [GC.FIELD_DRIVER_COMPENSATION_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:effective-date',
  },
  [GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:expiration-date',
  },
  ...getAddressPointFieldsByType(GC.FIELD_ORIGIN),
  ...getAddressPointFieldsByType(GC.FIELD_DESTINATION),
  [GC.FIELD_CHARGE_RATE]: {
    width: 200,
    name: 'titles:rate',
    filterType: 'number',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
    filterType: 'selectMultiple',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 200,
    name: 'titles:rate-type',
    filterType: 'selectMultiple',
    options: R.filter(R.prop(GC.FIELD_VALUE), DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS),
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
      R.path(['data', GC.FIELD_CHARGE_RATE_TYPE], row),
    ),
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 200,
    name: 'titles:rate-unit',
    filterType: 'selectMultiple',
    options: R.filter(R.prop(GC.FIELD_VALUE), DEFAULT_CONTRACT_RATE_UNIT_OPTIONS),
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
      R.path(['data', GC.FIELD_CHARGE_RATE_UNIT], row),
    ),
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:rate-minimum',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:rate-maximum',
  },
  [GC.GRC.MODE_DISPLAYED_VALUE]: {
    width: 200,
    sortable: false,
    name: 'titles:mode',
  },
  [GC.FIELD_TRANSIT_DAYS]: {
    width: 200,
    filterType: 'number',
    name: 'titles:transit-days',
  },
  [GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    sortable: false,
    name: 'titles:service-type',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: {
    width: 200,
    filterType: 'number',
    name: 'titles:distance-range-from',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: {
    width: 200,
    filterType: 'number',
    name: 'titles:distance-range-to',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: {
    width: 200,
    filterType: 'selectMultiple',
    name: 'titles:distance-range-uom',
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_DISTANCE_UOM_OPTIONS,
      R.path(['data', GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM], row),
    ),
  },
  [GC.FIELD_MIN_WEIGHT]: {
    width: 200,
    filterType: 'number',
    name: 'titles:min-weight',
  },
  [GC.FIELD_MAX_WEIGHT]: {
    width: 200,
    filterType: 'number',
    name: 'titles:max-weight',
  },
  [GC.FIELD_WEIGHT_UOM]: {
    width: 200,
    name: 'titles:weight-uom',
    filterType: 'selectMultiple',
    options: DEFAULT_WEIGHT_UOM_OPTIONS,
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_WEIGHT_UOM_OPTIONS,
      R.path(['data', GC.FIELD_WEIGHT_UOM], row),
    ),
  },
  [GC.FIELD_MIN_TEMPERATURE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:min-temperature',
  },
  [GC.FIELD_MAX_TEMPERATURE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:max-temperature',
  },
  [GC.FIELD_TEMPERATURE_UOM]: {
    width: 200,
    filterType: 'selectMultiple',
    name: 'titles:temperature-uom',
    options: ITEM_TEMPERATURE_OPTIONS,
  },
  [GC.FIELD_MIN_QUANTITY]: {
    width: 200,
    filterType: 'number',
    name: 'titles:min-quantity',
  },
  [GC.FIELD_MAX_QUANTITY]: {
    width: 200,
    filterType: 'number',
    name: 'titles:max-quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 200,
    name: 'titles:package-type',
    filterType: 'selectMultiple',
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
      R.path(['data', GC.FIELD_PACKAGE_TYPE], row),
    ),
  },
  [GC.FIELD_GET_FUEL_FROM_ORDER]: {
    width: 200,
    type: 'boolean',
    name: 'titles:get-fuel-from-order',
  },
  [GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER]: {
    width: 200,
    type: 'boolean',
    name: 'titles:add-additional-charges-from-order',
  },
};

const columnSettings = R.map(R.pick(['type', 'name', 'width', 'sortable', 'customComponent']), settings);

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = R.mapObjIndexed((
  { name, options, collection, filterType, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.ifElse(
    G.isArray(name),
    `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
    G.getWindowLocale(name),
  ),
}), settings);

export const FILTER_PARAMS = R.values(filterProps);

const makeRequestPayload = (props: Object) => {
  const { vendorBranchGuid, driverBranchGuid, primaryObjectGuid, primaryObjectGuidKey } = props;

  const systemFields = [
    GC.FIELD_ORIGIN,
    GC.FIELD_VERSION,
    GC.FIELD_DESTINATION,
  ];

  const data = {
    [primaryObjectGuidKey]: primaryObjectGuid,
    [GC.CURRENT_BRANCH]: R.or(driverBranchGuid, vendorBranchGuid),
  };

  return G.addSystemFieldsToTableReport(data, systemFields);
};

export const compensationSettings = {
  filterProps,
  columnSettings,
  isReport: true,
  useSearchableColumns: true,
  withResizableColumns: true,
  useNewTitleFilterInputs: true,
  groupName: 'compensationList',
  reportType: GC.FLEET_DRIVER_COMPENSATION_REPORT,
  additionalTableSettings: { searchableTitles: true },
  itemTitleArr: ['titles:compensation-details', 'Compensation Details'],
  formGroupTitleArr: ['titles:compensation-details', 'Compensation Details'],
  endpoints: {
    createOrUpdate: 'driverCompensation',
    list: 'driverCompensationListByReport',
    entity: 'getDriverCompensationEndpoint',
  },
  // components
  CustomForm: ({
    fleetType,
    isEditMode,
    submitAction,
    initialValues,
    optionsForSelect,
    primaryObjectGuidKey,
  }: Object) => (
    <CompensationForm
      isEditMode={isEditMode}
      submitAction={submitAction}
      initialValues={initialValues}
      fleetType={R.toUpper(fleetType)}
      optionsForSelect={optionsForSelect}
      primaryObjectGuidKey={primaryObjectGuidKey}
    />
  ),
  // helpers
  makeRequestPayload,
  makeInitialValues: (entity: Object, { branchConfigs }: Object) => ({
    ...entity,
    [GC.FIELD_MODE]: R.or(
      R.prop(GC.GRC.MODE_DROPDOWN_OPTION_GUID, entity),
      R.pathOr('', [GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], entity),
    ),
    [GC.FIELD_SERVICE_TYPE]: R.or(
      R.prop(GC.GRC.SERVICE_TYPE_DROPDOWN_OPTION_GUID, entity),
      R.pathOr('', [GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], entity),
    ),
    [GC.FIELD_CURRENCY]: R.pathOr(
      G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs),
      [GC.FIELD_CURRENCY],
      entity,
    ),
  }),
  makeOptionsForSelect: ({ branchConfigs, accessorialConfigs }: Object) => ({
    accessorialConfigs,
    [GC.FIELD_MODE]: R.path(['dropdownOptions', GC.GENERAL_MODE_TRANSPORTATION], branchConfigs),
    [GC.FIELD_SERVICE_TYPE]: R.path(['dropdownOptions', GC.GENERAL_TRANSPORTATION_SERVICE_TYPE], branchConfigs),
  }),
};

export const vendorCompensationSettings = {
  ...compensationSettings,
  reportType: GC.FLEET_VENDOR_COMPENSATION_REPORT,
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  endpoints: {
    entity: 'getVendorCompensationEndpoint',
    createOrUpdate: 'fleetVendorCompensation',
    list: 'fleetVendorCompensationListByReport',
  },
};
