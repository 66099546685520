import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withHandlers,
  renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import EditReport from '../../components/edit-report';
import { TitlePanel } from '../../components/title-panel';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import PC from '../permission/role-permission';
import { AuthWrapper } from '../permission/index';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import {
  IconWrapper,
  ListWrapper,
  ZOrderWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature chart
import { FILTER_PARAMS } from './settings/filter-params';
import ElementActions from './components/element-actions';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectAvailableReports } from './selectors';
import {
  setReports,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  deleteChartRequest,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  getListOfChartsRequest,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest } from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withState('originalReport', 'setOriginalReport', null),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withHandlers({
    handleListRequest: ({ getListOfChartsRequest }: Object) => (isInitial: boolean = false) =>
      getListOfChartsRequest(isInitial),
    getQuickFilteredListRequest: ({ resetListAndPagination, getListOfChartsRequest }: Object) => () => {
      resetListAndPagination();
      getListOfChartsRequest(true);
    },
    handleSelectReport: ({ reportList, setUsedReport, getListOfChartsRequest }: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);
      setUsedReport(selectedReport);
      getListOfChartsRequest(true);
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) =>
      setReportFromPrompt(data),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getListOfChartsRequest } = props;
      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getListOfChartsRequest(true);
      }
      setPromptStatus(false);
    },
    handleCleanFilter: ({ cleanQuickFilter, getListOfChartsRequest }: Object) => () => {
      cleanQuickFilter();
      getListOfChartsRequest(true);
    },
    handleMassDelete: (props: Object) => () => {
      const isSelected = (item: Object) => R.equals(item.selected, true);
      const itemList = R.values(R.path(['itemList'], props));
      const selectedList = R.map(
        (item: Object) => item.guid,
        R.filter(isSelected, itemList),
      );
      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:items:select-item', 'Please, select an Item!'),
        );
      }
      const modalContent = getConfirmModal({
        cancelAction: props.closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:delete', 'Delete'),
        text: G.getWindowLocale('messages:delete-confirmation-text', 'Are you sure you want delete chart?'),
        submitAction: () => {
          props.closeModal();
          props.deleteChartRequest(selectedList);
        },
      });
      return props.openModal(modalContent);
    },
    handleClickEditIcon: (props: Object) => (e: Object, chart: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <ElementActions
            chart={chart}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            handleDeleteChart={(guid: string) => props.deleteChartRequest(guid)} />
        ),
      })
    ),
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        createReportRequest,
        updateReportRequest,
        getListOfChartsRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getListOfChartsRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
  }),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      G.isNilOrEmpty(props.selectedReport),
    ),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      const { originalReport, setUsedReport, selectedReport } = this.props;
      if (R.equals(R.path([GC.FIELD_GUID], originalReport), R.path([GC.FIELD_GUID], selectedReport))) {
        setUsedReport(originalReport);
      }
    },
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    selectedReport,
    handleClickEditIcon,
    getListOfChartsRequest } = props;
  if (R.not(selectedReport)) return null;
  const elementActionsComponent = (chart: Object) => (
    <AuthWrapper has={[PC.CHART_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, chart)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );
  const allChecked = G.isAllChecked(itemList);
  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    tableSettings,
    columnSettings,
    hasSelectable: true,
    report: selectedReport,
    onOptionClick: selectItem,
    withResizableColumns: true,
    handleLoadMoreEntities: getListOfChartsRequest,
    renderRightStickedComponent: elementActionsComponent,
  };
  return <Table {...data} />;
};

const ChartsListComponent = (props: Object) => {
  const { itemList, handleMassDelete, getListOfChartsRequest } = props;

  const listActionsOpt = [
    {
      type: 'massAction',
      action: handleMassDelete,
      permissions: [PC.CHART_WRITE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
      icon: I.trash(G.getTheme('colors.white'), '20px', '20px'),
    },
  ];

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          type={GC.CHART_REPORT}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          getItemListRequest={getListOfChartsRequest}
          title={G.getWindowLocale('titles:chart-list', 'Chart List')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.CHART_WRITE]}>
        <PageActions
          listActions={listActionsOpt}
          shadowColor={G.getTheme('colors.light.grey')}
          count={R.filter((item: Object) => item.selected, R.or(itemList, [])).length}
          mainAction={{
            hideIfBranchTypeCustomer: true,
            action: () => G.goToRoute(routesMap.chartNew),
            text: G.getWindowLocale('titles:add-chart', 'Add Chart'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  deleteChartRequest,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  getListOfChartsRequest,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
})(enhance(ChartsListComponent));
