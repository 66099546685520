import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import MessageCenter from '../../../components/message-center';
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
import { openModal, closeModal } from '../../../components/modal/actions';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature components-story
import { makeSelectMessageCenterValues } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
import {
  setMessageCenterValues,
  updateMessageCenterValue,
  deleteMessageCenterValue,
  pinOrUnpinMessageCenterValue,
} from '../actions';
//////////////////////////////////////////////////

const getRowActionsOptions = (props: Object) => {
  const { entity, handlePinOrUnpinMessage, handleUpdateMessage, handleDeleteMessage } = props;

  const iconColor = G.getTheme('colors.light.blue');

  const pinTxtLocale = G.ifElse(
    entity.pinned,
    G.getWindowLocale('actions:unpin', 'Unpin'),
    G.getWindowLocale('actions:pin', 'Pin'),
  );

  const pinIconColor = G.getThemeByCond(
    entity.pinned,
    'colors.light.blue',
    'colors.dark.grey',
  );

  return [
    {
      permissions: null,
      frontIcon: I.pencil(iconColor),
      action: () => handleUpdateMessage(entity),
      text: G.getWindowLocale('actions:edit', 'Edit'),
    },
    {
      permissions: null,
      frontIcon: I.remove(iconColor),
      action: () => handleDeleteMessage(entity),
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
    {
      permissions: null,
      text: pinTxtLocale,
      frontIcon: I.pinNew(pinIconColor),
      action: () => handlePinOrUnpinMessage(entity),
    },
  ];
};

const formEnhance = compose(
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(initialValues),
  }),
  pure,
);

const MessageForm = formEnhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ my: 15 }}
        fields={[{
          type: 'textarea',
          fieldName: GC.FIELD_TEXT,
          inputWrapperStyles: { width: 270 },
          label: ['titles:message': 'Message'],
        }]}
      />
      <FormFooter2 />
    </form>
  );
});

const enhance = compose(
  withFixedPopover,
  withHandlers({
    handleAddMessage: (props: Object) => ({ message }: string) => props.setMessageCenterValues(
      {
        pinned: false,
        text: message,
        createdBy: 'User',
        guid: G.generateGuid(),
        createdDate: G.getCurrentDateWithFormat(GC.DEFAULT_DATE_TIME_FORMAT),
      },
    ),
    handleUpdateMessage: (props: Object) => (entity: Object) => {
      const { openModal, closeModal, updateMessageCenterValue } = props;

      const component = (
        <MessageForm
          isRequired={true}
          initialValues={entity}
          submitAction={(message: Object) => {
            updateMessageCenterValue(message);
            closeModal();
          }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:edit-message', 'Edit Message'),
        },
      };

      openModal(modal);
    },
    handleDeleteMessage: (props: Object) => ({ guid }: Object) => {
      const { openModal, closeModal, deleteMessageCenterValue } = props;

      const textLocale = G.getWindowLocale(
        'messages:chat-messages:remove-ref-confirmation-text',
        'Are you sure you want delete this chat message?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 460,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteMessageCenterValue(guid);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePinOrUnpinMessage: (props: Object) => ({ guid }: Object) => {
      const { pinOrUnpinMessageCenterValue, closeFixedPopup } = props;

      pinOrUnpinMessageCenterValue(guid);
      closeFixedPopup();
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => ({ currentTarget }: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: currentTarget,
        content: (
          <ActionsElement version={2} options={getRowActionsOptions({ ...props, entity })} />
        ),
      })
    ),
  }),
  pure,
);

const MessageCenterComponent = (props: Object) => {
  const {
    handleAddMessage,
    messageCenterValues,
    handleClickEditIcon,
  } = props;

  const description = '<MessageCenter /> component is responsible for rendering a message center with a textarea input for composing messages.';

  const usage = (
    <ul>
      <li>Parameter `<i>submitAction</i>` is a function triggered to add a new message.</li>
      <li>Parameter `<i>entities</i>` is array of objects representing individual messages.</li>
      <li>Parameter `<i>handleClickEditIcon</i>` is a function triggered when clicking the edit icon.</li>
    </ul>
  );

  const messageCenterStyle = {
    height: 400,
    padding: '8px',
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='MessageCenter'
      description={description}
      path='src/components/message-center'
    >
      <Box
        borderRadius='5px'
        background='white'
        border='1px solid silver'
      >
        <MessageCenter
          {...messageCenterStyle}
          submitAction={handleAddMessage}
          entities={messageCenterValues}
          handleClickEditIcon={handleClickEditIcon}
        />
      </Box>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  messageCenterValues: makeSelectMessageCenterValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setMessageCenterValues,
  deleteMessageCenterValue,
  updateMessageCenterValue,
  pinOrUnpinMessageCenterValue,
})(enhance(MessageCenterComponent));
