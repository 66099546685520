import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  withHandlers,
  renderNothing,
  withPropsOnChange,
} from 'react-recompose';
// components
import { PageTabs } from '../../../../components/page-tabs';
import { PageTitle } from '../../../../components/page-title';
import { ConfirmComponent } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
import PopupWithReportAndQuickFilters from '../../../../components/filter/report-and-quick-filters';
// features
import { cleanQuickFilter } from '../../../report-format/actions';
import { makeSelectQuickFilteredParams } from '../../../report-format/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../../permission/selectors';
import { makeSelectExpandedContainerOpened } from '../../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, FixedFlex, CancelButton, PageHeaderWrapper } from '../../../../ui';
// feature fleet
import * as C from '../../constants';
import { getCompFilterParams } from '../../driver/settings/filter-params';
// feature fleet/vendor
import CreateFleet from './create-fleet';
import EditDriverDetails from './edit-details-tab';
import EditVendorDocsComponent from './edit-docs-tab';
import CompensationTabComponent from './edit-compensation-tab';
import { withComplexInitialValues } from '../../hocs/complex-initial-values';
import {
  makeSelectFormErrors,
  makeSelectCurrentVendor,
  makeSelectCollapsedGroup,
  makeSelectVendorFormValues,
} from '../selectors';
import {
  toggleVendorFormGroup,
  getVendorDocumentListRequest,
  getVendorCompensationListRequest,
  getDataForCompensationTabRequest,
} from '../actions';
//////////////////////////////////////////////////

const renderTitle = ({ initialValues }: Object) => {
  const companyName = G.getPropFromObject(GC.FIELD_NAME, initialValues);
  const prefix = G.getWindowLocale('titles:fleet-vendor', 'Fleet Vendor');

  return `${prefix} ${companyName}`;
};

const enhance = compose(
  reduxForm({
    change,
    enableReinitialize: true,
    form: 'EDIT_VENDOR_DETAIL',
  }),
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withPropsOnChange(
    ['complexInitialValues'],
    (props: Object) => (
      props.setInitialValues(props.complexInitialValues)
    ),
  ),
  withHandlers({
    handleCleanFilter: (props: Object) => () => {
      props.cleanQuickFilter();
      props.getVendorCompensationListRequest();
    },
  }),
  withHandlers({
    handleSelectAddress: (props: Object) => async (value: string) => {
      const { change, openLoader, closeLoader } = props;

      openLoader({ showDimmer: true });

      const {
        zip,
        city,
        state,
        country,
        address,
      } = await G.geocodeByPlaceAddress(
        value,
        'fleet vendor -> EditFormComponent',
        null,
        { addressFieldName: GC.FIELD_ADDRESS },
      );

      const locationArr = [
        {
          value: address,
          fieldName: GC.FIELD_ADDRESS,
        },
        {
          value: city,
          fieldName: GC.FIELD_CITY,
        },
        {
          value: state,
          fieldName: GC.FIELD_STATE,
        },
        {
          value: zip,
          fieldName: GC.FIELD_ZIP,
        },
        {
          value: country,
          fieldName: GC.FIELD_COUNTRY,
        },
      ];

      R.forEach(({ value, fieldName }: Object) => change(fieldName, value), locationArr);

      closeLoader();
    },
    handleChangeAddress: ({ change }: Object) => (address: string) =>
      change(GC.FIELD_ADDRESS, address),
    handleToggleFormGroup: ({ toggleVendorFormGroup }: Object) => (fieldName: string) =>
      toggleVendorFormGroup(fieldName),
    handleTabNav: (props: Object) => () => {
      const {
        initialValues,
        stateActiveTab,
        cleanQuickFilter,
        handleSetActiveTab,
        getVendorDocumentListRequest,
        getDataForCompensationTabRequest,
      } = props;

      const vendorGuid = G.getGuidFromObject(initialValues);

      const paymentTabAction = () => {
        cleanQuickFilter();
        handleSetActiveTab(C.FLEET_TAB_PAYMENT);
        getDataForCompensationTabRequest(vendorGuid);
      };

      const iconColor = 'icons.iconColor';
      const iconColorGrey = 'icons.iconColorGrey';

      const tabs = [
        {
          tabName: C.FLEET_TAB_DETAILS,
          label: G.getWindowLocale('titles:general-details', 'General Details'),
          frontIcon: I.searchDocument(
            G.getThemeByCond(R.equals(stateActiveTab, C.FLEET_TAB_DETAILS), iconColor, iconColorGrey),
          ),
          action: () => {
            cleanQuickFilter();
            handleSetActiveTab(C.FLEET_TAB_DETAILS);
          },
        },
        {
          tabName: C.FLEET_TAB_DOCUMENTS,
          label: G.getWindowLocale('titles:documents', 'Documents'),
          frontIcon: I.documents(
            G.getThemeByCond(R.equals(stateActiveTab, C.FLEET_TAB_DOCUMENTS), iconColor, iconColorGrey),
          ),
          action: () => {
            cleanQuickFilter();
            getVendorDocumentListRequest();
            handleSetActiveTab(C.FLEET_TAB_DOCUMENTS);
          },
        },
        {
          tabName: C.FLEET_TAB_PAYMENT,
          action: () => paymentTabAction(),
          label: G.getWindowLocale('titles:rating-engine', 'Rating Engine'),
          frontIcon: I.payment(
            G.getThemeByCond(R.equals(stateActiveTab, C.FLEET_TAB_PAYMENT), iconColor, iconColorGrey),
          ),
        },
      ];

      return tabs;
    },
    handleClickCancel: (props: Object) => () => {
      const { openModal, closeModal, closeExpandedContainer, expandedContainerOpened } = props;

      if (expandedContainerOpened) return closeExpandedContainer();

      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirmation-leave-page',
            'All changes will be lost. Are you sure you want leave page?',
          )}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:leave', 'Leave'),
              action: () => {
                closeModal();

                G.historyGoBack();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleCancel: ({ expandedContainerOpened, closeExpandedContainer }: Object) => () => {
      if (expandedContainerOpened) return closeExpandedContainer();

      G.historyGoBack();
    },
  }),
  branch(
    (props: Object) => (
      R.and(
        R.not(props.initialDataLoaded),
        G.isNilOrEmpty(props.initialValues.guid),
      )
    ),
    renderNothing,
  ),
  pure,
);

const renderTab = (props: Object) => {
  const tabs = {
    [C.FLEET_TAB_DETAILS]: () => <EditDriverDetails {...props} />,
    [C.FLEET_TAB_DOCUMENTS]: () => <EditVendorDocsComponent {...props} />,
    [C.FLEET_TAB_PAYMENT]: () =>
      <CompensationTabComponent
        {...props}
        branchGuid={R.path(['initialValues', GC.BRANCH_GUID], props)}
      />,
  };

  return tabs[props.stateActiveTab]();
};

const PageHeader = (props: Object) => (
  <PageHeaderWrapper
    zI={13}
    aI='center'
    position='relative'
    justify='flex-start'
  >
    <PageTitle title={renderTitle(props)} />
    <CreateFleet openModal={props.openModal} />
    {
      G.isNotNilAndNotEmpty(props.filterParams) &&
      <PopupWithReportAndQuickFilters
        version={2}
        filterCount={1}
        popperWithCount={true}
        filteredParams={props.filterParams}
        cleanFilter={props.handleCleanFilter}
        filterProps={G.setFilterByParams([], getCompFilterParams)}
      />
    }
  </PageHeaderWrapper>
);

const EditFormComponent = enhance((props: Object) => {
  const { handleCancel, handleTabNav, stateActiveTab, expandedContainerOpened } = props;

  return (
    <Box
      mb={60}
      background={G.getTheme('colors.white')}
      pl={G.ifElse(expandedContainerOpened, 25)}
      pb={G.ifElse(expandedContainerOpened, 60)}
      height={G.ifElse(expandedContainerOpened, '100vh')}
      overflowY={G.ifElse(expandedContainerOpened, 'auto')}
    >
      <PageTabs zI={20} height={100} tabs={handleTabNav()} />
      <PageHeader {...props} />
      {renderTab(props)}
      {
        G.notEquals(stateActiveTab, C.FLEET_TAB_DETAILS) &&
        <FixedFlex
          pl={70}
          left='0px'
          height={60}
          bottom='0px'
          width='100%'
          zIndex={12}
          borderTop='1px solid'
          bg={G.getTheme('forms.actionsFooter.bgColor')}
          borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
        >
          <CancelButton width={100} onClick={() => handleCancel()}>
            {G.getWindowLocale('actions:cancel', 'Cancel')}
          </CancelButton>
        </FixedFlex>
      }
    </Box>
  );
});

const EditFormWithComplexInitialValues = withComplexInitialValues((props: Object) => (
  <EditFormComponent {...props} initialValues={props.initialValues} />
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  initialValues: makeSelectCurrentVendor(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  filterParams: makeSelectQuickFilteredParams(state),
  errors: makeSelectFormErrors(state, 'EDIT_VENDOR_DETAIL'),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  expandedContainerOpened: makeSelectExpandedContainerOpened(state),
  formValues: makeSelectVendorFormValues(state, 'EDIT_VENDOR_DETAIL'),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  cleanQuickFilter,
  toggleVendorFormGroup,
  getVendorDocumentListRequest,
  getDataForCompensationTabRequest,
  getVendorCompensationListRequest,
})(EditFormWithComplexInitialValues);
