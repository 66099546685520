import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const store = R.prop('carrierProfile');
const carrierContractStore = R.path(['carrierProfile', 'carrierContract']);
const customerContractStore = R.path(['carrierProfile', 'customerContract']);

// common
const makeSelectCollapsedGroup = () => createSelector(
  store,
  R.propOr({}, 'collapsedGroup'),
);

const makeSelectGeneralDetails = () => createSelector(
  R.path(['carrierProfile', 'generalDetails']),
  (generalDetails: Object) => {
    if (G.isNilOrEmpty(generalDetails)) return {};

    const { equipments, statusReason, transportationModes } = generalDetails;

    return {
      ...generalDetails,
      [GC.FIELD_EQUIPMENTS]: R.map(G.getDropdownOptionGuidFromObject, R.or(equipments, [])),
      [GC.FIELD_CARRIER_DEACTIVATED_REASON]: R.prop(GC.FIELD_DROPDOWN_OPTION_GUID, statusReason),
      [GC.FIELD_CARRIER_TRANSPORTATION_MODES]: R.map(G.getDropdownOptionGuidFromObject, R.or(transportationModes, [])),
    };
  },
);

const makeSelectCarrierGuid = () => createSelector(
  store,
  R.path(['generalDetails', GC.FIELD_GUID]),
);

const makeSelectCarrierBranchGuid = () => createSelector(
  store,
  R.path(['generalDetails', GC.BRANCH_GUID]),
);

// report
const makeSelectListLoadingByGroupName = (groupName: string) => createSelector(
  store,
  (state: Object) => R.path(['loading', ...R.split('.', groupName)], state),
);

const makeSelectTableTitleFiltersByGroupName = (groupName: string) => createSelector(
  store,
  (state: Object) => R.path(['tableTitleFilters', ...R.split('.', groupName)], state),
);

const makeSelectTitleSortValuesByGroupName = (groupName: string) => createSelector(
  store,
  (state: Object) => R.path(['tableTitleSortValues', ...R.split('.', groupName)], state),
);

const makeSelectPaginationByGroupName = (groupName: string) => createSelector(
  store,
  (state: Object) => R.path(['pagination', ...R.split('.', groupName)], state),
);

const makeSelectTotalCountByGroupName = (groupName: string) => createSelector(
  store,
  (state: Object) => R.path(['totalCount', ...R.split('.', groupName)], state),
);

const makeSelectUsedReportByGroupName = (groupName: string) => createSelector(
  store,
  (state: Object) => R.path(['usedReport', ...R.split('.', groupName)], state),
);

const makeSelectAvailableReportsByGroupName = (groupName: string) => createSelector(
  store,
  (state: Object) => R.path(['availableReports', ...R.split('.', groupName)], state),
);

const makeSelectAvailableForCarrierLoadBoards = () => createSelector(
  store,
  R.propOr([], 'availableForCarrierLoadBoards'),
);

const makeSelectLoadBoardSynchronizationParams = () => createSelector(
  [
    R.path(['carrierProfile', 'parentBranchGuids']),
    R.path(['carrierProfile', 'loadBoardIntegrationList']),
  ],
  (parentBranchGuids: Array, loadBoardIntegrationList: Array) => {
    if (G.isNilOrEmpty(loadBoardIntegrationList)) {
      return {
        [GC.FIELD_CARRIER_ADDED_TO_NETWORK]: false,
        [GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW]: false,
      };
    }

    const indexed = R.indexBy(R.prop(GC.BRANCH_GUID), loadBoardIntegrationList);

    const mapped = R.compose(
      R.map((item: string) => G.getPropFromObject(item, indexed)),
      R.filter((item: string) => R.has(item, indexed)),
    )(parentBranchGuids);

    const addedToNetwork = R.pathOr(false, [0, GC.FIELD_CARRIER_ADDED_TO_NETWORK], mapped);
    const allowedBookItNow = R.pathOr(false, [0, GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW], mapped);

    return { addedToNetwork, allowedBookItNow };
  },
);

const filterPathMap = {
  document: [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
};

const makeSelectIsOpenedFormGroup = createSelector(
  [
    R.path(['carrierProfile', 'collapsedGroup']),
    (_: Object, groupName: string) => groupName,
  ],
  (group: Object, groupName: string = '') => R.pathOr(true, R.split('.', groupName), group),
);

const makeSelectItemListByGroupName = createSelector(
  [
    store,
    (_: Object, groupName: string) => groupName,
  ],
  (state: Object, groupName: string = '') => {
    let itemList = R.path(R.split('.', groupName), state);

    if (R.isNil(itemList)) return itemList;

    itemList = G.isArray(itemList) ? itemList : R.of(Array, itemList);

    const filter = R.pathOr('', ['formGroupFilters', ...R.split('.', groupName)], state);

    if (G.isNotNilAndNotEmpty(filter)) {
      itemList = R.filter((item: Object) => {
        const path = R.prop(groupName, filterPathMap);

        return R.pathEq(filter, path, item);
      }, itemList);
    }

    return itemList;
  },
);

// configs
const makeSelectAccessorialConfigs = () => createSelector(
  store,
  R.path(['accessorialConfigs']),
);

const makeSelectSharedAccessorials = () => createSelector(
  store,
  R.prop('shardAccessorialList'),
);

const makeSelectBranchConfigs = () => createSelector(
  store,
  R.path(['branchConfigs']),
);

const makeSelectConfigGroup = () => createSelector(
  store,
  R.pathOr({}, ['configGroup']),
);

const makeSelectSendPackage = () => createSelector(
  store,
  R.prop('sendPackage'),
);

const makeSelectDispatchConfigs = () => createSelector(
  store,
  R.prop('dispatchConfigs'),
);

const makeSelectAvailableDocumentTypes = () => createSelector(
  store,
  R.prop('availableDocumentTypes'),
);

const makeSelectCarrierDispatchAvailableDocumentTemplates = () => createSelector(
  store,
  R.prop('carrierDispatchAvailableDocumentTemplates'),
);

const makeSelectDropdownOptionsByDropdownNames = createSelector(
  [
    R.path(['carrierProfile', 'configGroup', 'dropdownOptions']),
    (_: Object, dropdownNames: Array) => dropdownNames,
  ],
  (dropdownOptions: Object, dropdownNames: Array = []) => {
    if (G.isNilOrEmpty(dropdownNames)) return dropdownOptions;

    const dropdownOptionsByNames = R.pick(R.split(',', dropdownNames), R.or(dropdownOptions, {}));

    return dropdownOptionsByNames;
  },
);

// terminal
const makeSelectTerminalFinanceGuidByTerminalGuid = createSelector(
  [
    store,
    (_: Object, terminalGuid: Array) => terminalGuid,
  ],
  (state: Object, terminalGuid: string) => R.path(
    ['terminalDetails', terminalGuid, 'terminalFinance', 0, GC.FIELD_GUID],
    state,
  ),
);

// filters
const makeSelectFormGroupFilterByFilterName = createSelector(
  [
    store,
    (_: Object, filterName: string) => filterName,
  ],
  (state: Object, filterName: string = '') => R.path(['formGroupFilters', filterName], state),
);

// sequences
const makeSelectSequences = () => createSelector(
  store,
  R.prop('sequences'),
);

const makeSelectSequenceOptions = () => createSelector(
  store,
  R.compose(
    R.map(({ guid, displayName }: Object) => ({
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: displayName,
    })),
    R.propOr([], 'sequences'),
  ),
);

// references
const makeSelectReferenceTypes = () => createSelector(
  store,
  R.propOr([], 'referenceTypes'),
);

const makeSelectReferenceTypeOptions = () => createSelector(
  store,
  R.compose(
    R.map(({ guid, name }: Object) => ({ [GC.FIELD_VALUE]: guid, [GC.FIELD_LABEL]: name })),
    R.propOr([], 'referenceTypes'),
  ),
);

// branches
const makeSelectBranches = () => createSelector(
  store,
  R.prop('branches'),
);

// roles
const makeSelectRoleOptions = () => createSelector(
  R.prop('roles'),
  R.compose(
    G.mapNameGuidObjectPropsToLabelValueObject,
    R.values,
    R.prop('roleList'),
  ),
);

// contracts
const makeSelectTransportationModeOptionsForContracts = () => createSelector(
  [
    R.path(['carrierProfile', 'generalDetails', GC.FIELD_CARRIER_TRANSPORTATION_MODES]),
    R.path(['carrierProfile', 'configGroup', 'dropdownOptions', GC.GENERAL_MODE_TRANSPORTATION]),
  ],
  (transportationModes: Array, transportationModeOptions: Array) => {
    if (R.isNil(transportationModeOptions)) return null;

    const mapped = R.map(G.getDropdownOptionGuidFromObject, R.or(transportationModes, []));

    return R.filter(({ value }: Object) => R.includes(value, mapped), R.or(transportationModeOptions, []));
  },
);

const makeSelectCarrierContractGeneralDetails = () => createSelector(
  carrierContractStore,
  R.propOr({}, 'generalDetails'),
);

const makeSelectCarrierContractGuid = () => createSelector(
  carrierContractStore,
  R.path(['generalDetails', GC.FIELD_GUID]),
);

const makeSelectGeoFencingZoneList = () => createSelector(
  store,
  R.prop('geoFencingZoneList'),
);

const makeSelectAvailableCarrierIntegrations = () => createSelector(
  store,
  R.propOr([], 'availableCarrierIntegrations'),
);

const makeSelectCustomerContractGeneralDetails = () => createSelector(
  customerContractStore,
  R.propOr({}, 'generalDetails'),
);

const makeSelectCustomerContractGuid = () => createSelector(
  customerContractStore,
  R.path(['generalDetails', GC.FIELD_GUID]),
);

const makeSelectActiveRatingEngineTab = () => createSelector(
  store,
  R.prop('activeRatingEngineTab'),
);

const makeSelectContractPageVisited = () => createSelector(
  store,
  ({ carrierContract, customerContract }: Object) => R.or(G.isObject(carrierContract), G.isObject(customerContract)),
);

export {
  // common
  makeSelectCarrierGuid,
  makeSelectCollapsedGroup,
  makeSelectGeneralDetails,
  makeSelectIsOpenedFormGroup,
  makeSelectCarrierBranchGuid,
  makeSelectItemListByGroupName,
  // report
  makeSelectPaginationByGroupName,
  makeSelectTotalCountByGroupName,
  makeSelectUsedReportByGroupName,
  makeSelectListLoadingByGroupName,
  makeSelectTitleSortValuesByGroupName,
  makeSelectAvailableReportsByGroupName,
  makeSelectTableTitleFiltersByGroupName,
  // accessorial configs
  makeSelectAccessorialConfigs,
  // branch configs
  makeSelectBranchConfigs,
  // filters
  makeSelectFormGroupFilterByFilterName,
  // configs
  makeSelectConfigGroup,
  makeSelectSendPackage,
  makeSelectDispatchConfigs,
  makeSelectSharedAccessorials,
  makeSelectAvailableDocumentTypes,
  makeSelectDropdownOptionsByDropdownNames,
  makeSelectCarrierDispatchAvailableDocumentTemplates,
  // terminal
  makeSelectTerminalFinanceGuidByTerminalGuid,
  // sequences
  makeSelectSequences,
  makeSelectSequenceOptions,
  // reference types
  makeSelectReferenceTypes,
  makeSelectReferenceTypeOptions,
  // branches
  makeSelectBranches,
  // roles
  makeSelectRoleOptions,
  // synchronization
  makeSelectAvailableForCarrierLoadBoards,
  makeSelectLoadBoardSynchronizationParams,
  // contracts
  makeSelectGeoFencingZoneList,
  makeSelectCarrierContractGuid,
  makeSelectContractPageVisited,
  makeSelectCustomerContractGuid,
  makeSelectActiveRatingEngineTab,
  makeSelectAvailableCarrierIntegrations,
  makeSelectCarrierContractGeneralDetails,
  makeSelectCustomerContractGeneralDetails,
  makeSelectTransportationModeOptionsForContracts,
};
