import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// common
import { openModal, closeModal } from '../../../components/modal/actions';
// components
import { Table } from '../../../components/table';
import { ConfirmComponent } from '../../../components/confirm';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature geo-fencing-location
import * as A from '../actions';
import {
  locationTableSettings,
  locationColumnSettings,
} from '../settings/location-settings';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const LocationsList = (props: Object) => {
  const {
    loading,
    itemList,
    pagination,
    totalCount,
    filterProps,
    editLocation,
    viewLocation,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
  } = props;

  const dispatch = useDispatch();

  const handleConfirmAction = useCallback((guid: string) => {
    dispatch(A.deleteItemRequest(guid));

    dispatch(closeModal());
  }, []);

  const removeLocationText = G.getWindowLocale(
    'messages:remove-geo-fencing-location',
    'Are you sure you want delete this location?',
  );

  const handleOpenConfirmation = useCallback((guid: string) => {
    const modal = {
      component: <ConfirmComponent textLocale={removeLocationText} />,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => handleConfirmAction(guid),
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };

    dispatch(openModal(modal));
  }, []);

  const actionButtons = [
    {
      ml: 8,
      iconColor,
      iconName: 'eye2',
      action: (guid: string) => viewLocation(guid),
      permissions: [PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
      title: G.getWindowLocale('titles:geo-fencing-location-view', 'View Location'),
    },
    {
      iconName: 'pencil',
      action: editLocation,
      permissions: [PC.FLEET_TRAILER_WRITE],
      title: G.getWindowLocale('titles:geo-fencing-location-edit', 'Edit Location'),
    },
    {
      iconName: 'trash',
      enableIfEditable: true,
      action: handleOpenConfirmation,
      permissions: [PC.FLEET_TRAILER_WRITE],
      title: G.getWindowLocale('titles:geo-fencing-location-delete', 'Delete Location'),
    },
  ];

  const noWrite = G.hasNotAmousCurrentUserPermissions(PC.FLEET_TRAILER_WRITE);

  const tableSettings = {
    ...locationTableSettings,
    ...(noWrite && {
      checkBoxCellWidth: 0,
      allowSelectItems: false,
    }),
  };

  const selectItemHandler = useCallback((guid: string) => {
    dispatch(A.selectItem(guid));
  }, []);

  const handleTableTitleFilter = useCallback((data: Object) => {
    G.handleTableTitleFilter2(data, A, dispatch);

    getItemListRequest();
  }, []);

  const data = {
    loading,
    itemList,
    totalCount,
    pagination,
    actionButtons,
    tableSettings,
    titleSortValues,
    tableTitleFilters,
    handleTableTitleFilter,
    report: selectedReport,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    onOptionClick: selectItemHandler,
    allChecked: G.isAllChecked(itemList),
    columnSettings: locationColumnSettings,
    handleLoadMoreEntities: getItemListRequest,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(filterProps),
    ),
  };

  return <Table {...data} />;
};

export default LocationsList;
