import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withProps,
  withState,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
import { withSelectedFleetEntity } from '../../../../components/select-fleet-entity';
// features
import SectionDivider from '../../../new-do/components/section-divider';
import { getTruckGeneralDetailsRequest, getTrailerGeneralDetailsRequest } from '../../../fleet-profile/actions';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncConfigs } from '../../../../hocs';
import withAsyncSequence from '../../../../hocs/with-async-sequence';
import {
  withAsyncGetUserGeneralListFullName,
  withAsyncGetServiceVendorListAvailabe,
} from '../../../../hocs/with-async-get-endpoint-hocs';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
import { scrollableContainerCss4px } from '../../../../ui/common';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature fleet-list
import { Activities } from './activities';
import { LocationForm } from './location-form';
import { LocationSection } from './location-section';
import { defaultValues, getFieldSettings, validationSchema, serviceVendorFieldSettings } from './settings';
//////////////////////////////////////////////////

const serviceVendorTypes = R.join(
  ',',
  [GC.SERVICE_VENDOR_TYPE_GENERAL, GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE, GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE],
);

const enhance = compose(
  withSelectedFleetEntity,
  withAsyncGetServiceVendorListAvailabe(serviceVendorTypes),
  withProps(() => ({
    configsNamesArray: [GC.FLEET_GENERAL_SERVICE_ISSUE_TYPE, GC.FLEET_GENERAL_SERVICE_ISSUE_AUTO_ASSIGN_SERVICE_VENDOR],
  })),
  withAsyncConfigs,
  withProps(({ asyncConfigs }: Object) => {
    const serviceIssueTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.FLEET_GENERAL_SERVICE_ISSUE_TYPE,
    );

    return { serviceIssueTypeOptions };
  }),
  withAsyncGetUserGeneralListFullName,
  withPropsOnChange(['asyncUserGeneralListFullName'], ({ asyncUserGeneralListFullName }: Object) => {
    let assigneeOptions = [];

    if (G.isNotNilAndNotEmpty(asyncUserGeneralListFullName)) {
      assigneeOptions = R.map((item: Object) => {
        const { fullText } = G.getUserInfo(item);

        return {
          [GC.FIELD_LABEL]: fullText,
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
        };
      }, asyncUserGeneralListFullName);
    }

    return { assigneeOptions };
  }),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues, asyncSequence }: Object) => {
      const defaultFields = G.ifElse(
        G.isNotNilAndNotEmpty(asyncSequence),
        R.assoc(GC.FIELD_ISSUE_ID, asyncSequence, defaultValues),
        defaultValues,
      );

      return G.setInitialFormikValues(defaultFields, initialValues);
    },
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        isTruck,
        entityType,
        submitAction,
        asyncSequence,
        fleetEntityGuid,
        openedFromFleetProfile,
        deactivateOnServiceIssue,
        getTruckGeneralDetailsRequest,
        getTrailerGeneralDetailsRequest,
      } = props;

      let valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);

      if (R.pathEq(asyncSequence, [GC.FIELD_ISSUE_ID], values)) {
        valuesToUse = R.assoc(GC.FIELD_ISSUE_ID, null, valuesToUse);
      }

      const sucessCallback = R.and(openedFromFleetProfile, deactivateOnServiceIssue)
        ? () => {
          const getGeneralDetailsRequest = G.ifElse(
            isTruck,
            getTruckGeneralDetailsRequest,
            getTrailerGeneralDetailsRequest,
          );

          getGeneralDetailsRequest(fleetEntityGuid);
        }
        : null;

      submitAction({ entityType, sucessCallback, values: valuesToUse });
    },
  }),
  withHandlers({
    handleGetServiceVendorByRepairZone: (props: Object) => async (location: Object) => {
      if (G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_COUNTRY, location))) return;

      const { branchGuid, openLoader, closeLoader, setFieldValue } = props;

      G.callFunction(openLoader);

      const options = {
        data: R.assoc(
          GC.BRANCH_GUID,
          branchGuid,
          R.pick([GC.FIELD_ZIP, GC.FIELD_CITY, GC.FIELD_STATE, GC.FIELD_COUNTRY], location),
        ),
      };

      const res = await sendRequest('post', endpointsMap.serviceVendorListByRepairZone, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setFieldValue(GC.FIELD_SERVICE_VENDOR_GUID, R.pathOr(null, [0, GC.FIELD_GUID], data));
      } else {
        setFieldValue(GC.FIELD_SERVICE_VENDOR_GUID, null);

        G.handleFailResponseSimple(res, true, 'handleGetServiceVendorByRepairZone fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  withHandlers({
    handleSelectLocation: (props: Object) => (location: Object) => {
      const { values, setValues, closeModal, asyncConfigs, handleGetServiceVendorByRepairZone } = props;

      setValues(R.mergeRight(values, { location }));

      const autoAssignServiceVendor = G.getConfigValueFromStore(
        GC.FLEET_GENERAL_SERVICE_ISSUE_AUTO_ASSIGN_SERVICE_VENDOR,
        asyncConfigs,
        false,
      );

      closeModal();

      if (G.isTrue(autoAssignServiceVendor)) handleGetServiceVendorByRepairZone(location);
    },
  }),
  withHandlers({
    handleRemoveLocation: ({ values, setValues }: Object) => () =>
      setValues(R.mergeRight(values, { [GC.FIELD_LOCATION]: null })),
    handleAddOrEditLocation: ({ openModal, handleSelectLocation }: Object) => (location: Object) => {
      const component = (
        <LocationForm initialValues={location} submitAction={handleSelectLocation} />
      );

      const options = {
        p: 15,
        component,
        options: {
          width: 450,
          title: G.getAddTitle(['titles:location', 'Location']),
        },
      };

      openModal(options);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getAsyncUserGeneralListFullName } = this.props;

      getAsyncUserGeneralListFullName();
    },
  }),
  pure,
);

const enhanceCreate = compose(
  connect(null, { getTruckGeneralDetailsRequest, getTrailerGeneralDetailsRequest }),
  withProps(() => ({
    sequenceConfigName: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE,
    autogenerateConfigName: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED,
    configsNamesArray: [GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE, GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED],
  })),
  withAsyncSequence,
  enhance,
);

const enhanceEdit = compose(
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withHandlers({
    handleGetInitialValues: (props: Object) => async () => {
      const { guid, isTruck, openLoader, closeLoader, setInitialValues } = props;

      G.callFunction(openLoader);

      const endpoint = G.getPropFromObject(
        G.ifElse(isTruck, 'getTruckServiceIssueEndpoint', 'getTrailerServiceIssueEndpoint'),
        endpointsMap,
      )(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues({
          ...data,
          type: R.path([GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
        });
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetInitialValues fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  enhance,
  lifecycle({
    componentDidMount() {
      const { guid, handleGetInitialValues } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) handleGetInitialValues();
    },
  }),
);

const SectionHeader = (props: Object) => {
  const { title, action, hideAddBtn } = props;

  const darkBlueColor = G.getTheme('colors.dark.blue');

  return (
    <Flex
      p='5px'
      mb='5px'
      fontWeight='bold'
      color={darkBlueColor}
      justifyContent='center'
      textTransform='uppercase'
      bg={G.getTheme('colors.whiteGrey')}
    >
      <Box>{title}</Box>
      {
        R.not(hideAddBtn) &&
        <Flex ml={15} cursor='pointer' onClick={() => action()}>
          {I.plusRound(darkBlueColor)}
        </Flex>
      }
    </Flex>
  );
};

const formId = 'fleet-service-issue-form';

const FleetServiceIssueForm = (props: Object) => {
  const {
    handleSubmit,
    assigneeOptions,
    values: { location },
    handleRemoveLocation,
    serviceIssueTypeOptions,
    handleAddOrEditLocation,
    serviceVendorListAvailabeOptions,
  } = props;

  const isLocationSelected = G.isNotNilAndNotEmpty(location);

  return (
    <form id={formId} onSubmit={handleSubmit}>
      <Box width={480} p='25px 15px 0px'>
        <Fieldset2
          {...G.getFormikPropsToFieldset(props)}
          fields={getFieldSettings(props)}
          assigneeOptions={assigneeOptions}
          serviceIssueTypeOptions={serviceIssueTypeOptions}
          fieldsWrapperStyles={{ justifyContent: 'space-between' }}
        />
        <SectionDivider text={G.getWindowLocale('titles:service-vendor', 'Service Vendor')} />
        <Fieldset2
          {...G.getFormikPropsToFieldset(props)}
          fields={serviceVendorFieldSettings}
          serviceVendorListAvailabeOptions={serviceVendorListAvailabeOptions}
        />
        <SectionHeader
          hideAddBtn={isLocationSelected}
          action={() => handleAddOrEditLocation()}
          title={G.getWindowLocale('titles:location', 'Location')}
        />
        {
          isLocationSelected &&
          <LocationSection
            location={location}
            handleRemoveLocation={handleRemoveLocation}
            handleAddOrEditLocation={handleAddOrEditLocation}
          />
        }
      </Box>
    </form>
  );
};

const FleetServiceIssue = (props: Object) => {
  const { isEdit, initialValues, values: { location } } = props;

  return (
    <Box overflow='auto' maxHeight='87vh' css={scrollableContainerCss4px}>
      <Flex alignItems='stretch'>
        <FleetServiceIssueForm {...props} />
        {
          isEdit &&
          <Activities
            {...props}
            primaryObjectGuid={G.getGuidFromObject(initialValues)}
            maxHeight={G.ifElse(G.isNotNilAndNotEmpty(location), 580, 502)}
          />
        }
      </Flex>
      <FormFooter2
        formId={formId}
        boxStyles={{
          p: 15,
          borderTop: '1px solid',
          bg: G.getTheme('colors.whiteGrey'),
          borderColor: G.getTheme('colors.lightGrey'),
        }}
      />
    </Box>
  );
};

const EditFleetServiceIssueForm = enhanceEdit(FleetServiceIssue);
const CreateFleetServiceIssueForm = enhanceCreate(FleetServiceIssue);

export {
  EditFleetServiceIssueForm,
  CreateFleetServiceIssueForm,
};
