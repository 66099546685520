import React from 'react';
import * as R from 'ramda';
// components
import AddAdvancePaymentForm from '../../../../components/advance-payment/add-advance-payment-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const columnSettings = {
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_AMOUNT]: {
    width: 150,
    name: 'titles:check-amount',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_FEE]: {
    width: 150,
    name: 'titles:check-fee',
  },
  [GC.FIELD_ADVANCE_PAYMENT_TRIP_NUMBER]: {
    width: 150,
    name: 'titles:trip-number',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_BALANCE]: {
    width: 150,
    name: 'titles:check-balance',
  },
  [GC.FIELD_ADVANCE_PAYMENT_STATUS]: {
    width: 150,
    name: 'titles:status',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHANGE_STATUS_REASON]: {
    width: 300,
    name: 'titles:status-reason',
  },
  [GC.FIELD_ADVANCE_PAYMENT_INTEGRATION_TYPE]: {
    width: 150,
    name: 'titles:integration-type',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_CITY]: {
    width: 200,
    name: 'titles:last-use-city',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_LOCATION_CODE]: {
    width: 200,
    name: 'titles:last-use-location-code',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_DATE]: {
    width: 200,
    name: 'titles:last-use-date',
  },
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: {
    width: 150,
    name: 'titles:driver-first-name',
    customComponent: R.path(['data', GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_FIRST_NAME]),
  },
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: {
    width: 150,
    name: 'titles:driver-last-name',
    customComponent: R.path(['data', GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_LAST_NAME]),
  },
  [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: {
    width: 150,
    name: 'titles:driver-login-id',
    customComponent: ({ data }: Object) => R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_USER_LOGIN_ID], data),
  },
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: {
    width: 150,
    name: 'titles:driver-number',
    customComponent: ({ data }: Object) => R.path(
      [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER],
      data,
    ),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(columnSettings),
};

export const advancePaymentSettings = {
  report,
  columnSettings,
  groupName: 'advancePaymentList',
  actionsPicker: ['updateAdvancePaymentStatus'],
  itemTitleArr: ['titles:advance-payment', 'Advance Payment'],
  formGroupTitleArr: ['titles:advance-payment', 'Advance Payment'],
  endpoints: {
    list: 'advancePaymentList',
    createOrUpdate: 'advancePayment',
  },
  CustomForm: (props: Object) => <AddAdvancePaymentForm {...props} disableSelectDriverField={true} />,
  // helpers
  makeOptionsForSelect: (props: Object) => {
    const { guid, lastName, firstName } = R.pathOr({}, ['generalDetails'], props);

    return {
      selectedRateDriverOptions: [{
        [GC.FIELD_VALUE]: guid,
        [GC.FIELD_LABEL]: `${firstName} ${lastName}`,
      }],
    };
  },
  makeInitialValues: (initialValues: Object, props: Object) => {
    const {
      guid,
      loginId,
      lastName,
      firstName,
      driverNumber,
    } = R.pathOr({}, ['generalDetails'], props);

    const branchGuid = G.getPropFromObject('driverBranchGuid', props);

    return {
      [GC.FIELD_DRIVER_GUID]: guid,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
      [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: loginId,
      [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: lastName,
      [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: driverNumber,
      [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: firstName,
    };
  },
};
