import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { ENUMS } from '../../constants/enums';
// features
import {
  socketAvailableDriversNoteReceived,
  socketAvailableDriversStatusReceived,
  socketAvailableDriversLocationReceived,
  socketAvailableDriversReservationReceived,
} from '../sockets-v2/actions';
// feature available-driver
import * as A from './actions';
//////////////////////////////////////////////////

const commonStateProps = {
  totalCount: 0,
  itemList: null,
  loading: false,
};

const initialState = {
  ...commonStateProps,
  truckTypes: [],
  searchRadius: '',
  locationValue: '',
  pageVisited: false,
  driverInitials: '',
  searchLocation: null,
  searchDate: G.getCurrentDay(),
  activeList: 'availableDrivers',
  allDrivers: {
    ...commonStateProps,
    pagination: {
      limit: 20,
      offset: 0,
    },
  },
};

const setInitialState = () => (
  initialState
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const getItemListSuccess = (state: Object, { driverList }: Object) => {
  const list = R.indexBy(R.prop(GC.FIELD_GUID), driverList);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pageVisited', true),
    P.$set('totalCount', R.length(driverList)),
    state,
  );
};

const setSearchDate = (state: Object, data: string) => (
  P.$set('searchDate', data, state)
);

const setTruckTypes = (state: Object, data: Array) => (
  P.$set('truckTypes', data, state)
);

const setSearchRadius = (state: Object, data: number) => (
  P.$set('searchRadius', data, state)
);

const setLocationValue = (state: Object, data: string) => (
  P.$set('locationValue', data, state)
);

const setDriverInitials = (state: Object, data: string) => (
  P.$set('driverInitials', data, state)
);

const setSearchLocation = (state: Object, data: any) => (
  P.$set('searchLocation', data, state)
);

const setActiveList = (state: Object, data: string) => (
  P.$set('activeList', data, state)
);

// all drivers
const getAllDriversListSuccess = (state: Object, data: Object) => {
  const { allDrivers } = state;

  const { itemList, totalCount, pagination } = allDrivers;

  const { limit, offset } = pagination;

  const newOffset = R.add(offset, limit);

  const newList = R.mergeRight(itemList, R.indexBy(R.prop(GC.FIELD_GUID), data));

  return P.$all(
    P.$set('allDrivers.itemList', newList),
    P.$set('allDrivers.pagination.limit', 10),
    P.$set(
      'allDrivers.pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const setAllDriversListLoading = (state: Object, data: boolean) => (
  P.$set('allDrivers.loading', data, state)
);

const resetAllDriversListAndPagination = (state: Object) => (
  P.$all(
    P.$set('allDrivers.totalCount', 0),
    P.$set('allDrivers.itemList', null),
    P.$set('allDrivers.pagination', R.path(['allDrivers', 'pagination'], initialState)),
    state,
  )
);

const getDriverAvailabilityTotalCountSuccess = (state: Object, data: number) => (
  P.$set('allDrivers.totalCount', data, state)
);

// sockets
const getDriverPath = (state: Object, driverGuid: string) => G.ifElse(
  R.equals(P.$get('activeList', state), 'allDrivers'),
  `allDrivers.itemList.${driverGuid}`,
  `itemList.${driverGuid}`,
);

const handleWSAvailableDriversReservationReceived = (state: Object, { data, driverGuid }: Object) => {
  const driverPath = getDriverPath(state, driverGuid);

  const driver = P.$get(driverPath, state);

  if (R.isNil(driver)) return state;

  const { reserved, reservedBy, reservationEndDate } = data;

  return P.$all(
    P.$set(`${driverPath}.reserved`, reserved),
    P.$set(`${driverPath}.reservedBy`, reservedBy),
    P.$set(`${driverPath}.reservationEndDate`, reservationEndDate),
    state,
  );
};
const handleWSAvailableDriversNoteReceived = (state: Object, { data }: Object) => {
  const driverPath = getDriverPath(state, data.driverGuid);

  const driver = P.$get(driverPath, state);

  if (R.isNil(driver)) return state;

  return P.$set(`${driverPath}.note`, data, state);
};

const handleWSAvailableDriversLocationReceived = (state: Object, { data, driverGuid }: Object) => {
  const { searchLocation } = state;

  const driverPath = getDriverPath(state, driverGuid);

  const driver = P.$get(driverPath, state);

  if (R.or(R.isNil(driver), G.isNotNilAndNotEmpty(R.path([GC.FIELD_TEL_GUID], driver)))) return state;

  let distance = R.path([GC.FIELD_DISTANCE], driver);

  if (G.isNotNilAndNotEmpty(searchLocation)) {
    distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, data));
  }

  return P.$all(
    P.$set(`${driverPath}.location`, data),
    P.$set(`${driverPath}.distance`, distance),
    state,
  );
};

const handleWSAvailableDriversStatusReceived = (state: Object, { data, driverGuid }: Object) => {
  const { searchLocation } = state;

  const driverPath = getDriverPath(state, driverGuid);

  const driver = P.$get(driverPath, state);

  if (R.isNil(driver)) return state;

  const {
    status,
    statusSource,
    availableAtDate,
    availableAtLocation,
  } = data;

  if (G.isNotNilAndNotEmpty(R.path([GC.FIELD_TEL_GUID], driver))) {
    return P.$set(`${driverPath}.status`, status, state);
  }

  let distance = R.path([GC.FIELD_DISTANCE], driver);

  if (G.isNotNilAndNotEmpty(searchLocation)) {
    if (R.equals(status, ENUMS.ENUM_AVAILABLE_IN_FUTURE)) {
      distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, availableAtLocation));
    }

    const { futureAvailabilityLocation } = driver;

    if (R.and(R.equals(status, ENUMS.ENUM_UNAVAILABLE), G.isNotNilAndNotEmpty(futureAvailabilityLocation))) {
      const { location } = driver;

      distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, location));
    }
  }

  return P.$all(
    P.$set(`${driverPath}.status`, status),
    P.$set(`${driverPath}.distance`, distance),
    P.$set(`${driverPath}.statusSource`, statusSource),
    P.$set(`${driverPath}.futureAvailabilityDate`, availableAtDate),
    P.$set(`${driverPath}.futureAvailabilityLocation`, availableAtLocation),
    state,
  );
};

export default createReducer({
  [A.setSearchDate]: setSearchDate,
  [A.setTruckTypes]: setTruckTypes,
  [A.setActiveList]: setActiveList,
  [A.setListLoading]: setListLoading,
  [A.setSearchRadius]: setSearchRadius,
  [A.setInitialState]: setInitialState,
  [A.setLocationValue]: setLocationValue,
  [A.setDriverInitials]: setDriverInitials,
  [A.setSearchLocation]: setSearchLocation,
  [A.getItemListSuccess]: getItemListSuccess,
  // all drivers
  [A.setAllDriversListLoading]: setAllDriversListLoading,
  [A.getAllDriversListSuccess]: getAllDriversListSuccess,
  [A.resetAllDriversListAndPagination]: resetAllDriversListAndPagination,
  [A.getDriverAvailabilityTotalCountSuccess]: getDriverAvailabilityTotalCountSuccess,
  // sockets
  [socketAvailableDriversNoteReceived]: handleWSAvailableDriversNoteReceived,
  [socketAvailableDriversStatusReceived]: handleWSAvailableDriversStatusReceived,
  [socketAvailableDriversLocationReceived]: handleWSAvailableDriversLocationReceived,
  [socketAvailableDriversReservationReceived]: handleWSAvailableDriversReservationReceived,
}, initialState);
