import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// forms
import { Fieldset2 } from '../../../../../forms';
// ui
import { Box } from '../../../../../ui';
import {
  getDeactivationRuleFields,
  defaultDeactivationRuleValues,
  getDeactivationRuleValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getDeactivationRuleValidationSchema(values))
    )),
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDeactivationRuleValues,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      submitAction(values);
    },
  }),
  withHandlers({
    handleCustomChange: ({ setValues }: Object) => (fieldValue: Object) => (
      setValues(R.assoc(
        GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER,
        fieldValue,
        defaultDeactivationRuleValues,
      ))
    ),
  }),
  pure,
);

const fieldsWrapperStyles = { justifyContent: 'space-between' };

const DeactivationRuleForm = (props: Object) => {
  const { values, isEdit, handleSubmit, handleCustomChange, insuranceTypeOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Fieldset2
          {...G.getFormikProps(props)}
          handleCustomChange={handleCustomChange}
          insuranceTypeOptions={insuranceTypeOptions}
          fields={getDeactivationRuleFields(values, isEdit)}
          fieldsWrapperStyles={{ ...fieldsWrapperStyles, mt: 15 }}
        />
        <FormFooter2 />
      </Box>
    </form>
  );
};

export default enhance(DeactivationRuleForm);
