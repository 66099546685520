import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 400,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

export const fieldSettingsMap = {
  // documents
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    type: 'select',
    isRequired: true,
    options: 'documentType',
    inputWrapperStyles: { width: 400 },
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
    label: G.getWindowLocale('titles:document-type', 'Document Type'),
  },
  [GC.FIELD_DOCUMENT_URL]: {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_DOCUMENT_URL,
    label: G.getWindowLocale('titles:url', 'URL'),
    inputWrapperStyles: {
      mt: 25,
      width: 400,
      display: (props: Object) => {
        const { file, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(R.or(G.isNotNil(file), G.isNotNil(documentFilename)), 'none', 'block');
      },
    },
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    type: 'file',
    isRequired: true,
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    label: G.getWindowLocale('titles:upload-file', 'Upload File'),
    inputWrapperStyles: {
      mt: 25,
      width: 400,
      display: (props: Object) => {
        const { url, version, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(
          R.or(G.isNotNilAndNotEmpty(url), R.and(G.isNotNil(version), R.isNil(documentFilename))),
          'none',
          'block',
        );
      },
    },
  },
  // notes
  [GC.FIELD_TEXT]: {
    type: 'textarea',
    isRequired: true,
    inputStyles: { p: 15 },
    fieldName: GC.FIELD_TEXT,
    inputWrapperStyles: { width: 400 },
    label: G.getWindowLocale('titles:subject', 'Subject'),
  },
  // safety violation
  [GC.FIELD_TYPE]: {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_TYPE,
    options: 'safetyViolationType',
    label: G.getWindowLocale('titles:type', 'Type'),
  },
  [GC.FIELD_SEVERITY]: {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_SEVERITY,
    options: 'safetyViolationSeverity',
    label: G.getWindowLocale('titles:severity', 'Severity'),
  },
  occurrenceDate: {
    isRequired: true,
    timeIntervals: 1,
    customInput: true,
    type: 'datePicker',
    inputWrapperStyles,
    timeSelection: true,
    withClickZIndex: true,
    calendarInputWrapperStyles,
    fieldName: 'occurrenceDate',
    maxDate: G.getCurrentDate(),
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    label: G.getWindowLocale('titles:occurrence-date', 'Occurrence Date'),
  },
  disciplinaryAction: {
    type: 'select',
    inputWrapperStyles,
    fieldName: 'disciplinaryAction',
    options: 'safetyViolationDisciplinaryAction',
    label: G.getWindowLocale('titles:disciplinary-action', 'Disciplinary Action'),
  },
  disciplinaryDate: {
    timeIntervals: 1,
    customInput: true,
    type: 'datePicker',
    inputWrapperStyles,
    timeSelection: true,
    withClickZIndex: true,
    calendarInputWrapperStyles,
    maxDate: G.getCurrentDate(),
    fieldName: 'disciplinaryDate',
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    label: G.getWindowLocale('titles:disciplinary-date', 'Disciplinary Date'),
  },
  eventDescription: {
    type: 'textarea',
    inputStyles: { p: 15 },
    fieldName: 'eventDescription',
    inputWrapperStyles: { width: 400 },
    label: G.getWindowLocale('titles:event-description', 'Event Description'),
  },
};
