import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// icons
import * as I from '../../../../svgs';
// feature fleet-profile
import DailyHoursForm from '../forms/daily-hours';
import DriverHosLogList from '../components/driver-hos-log-list';
import { DailyHoursAdditionalFormGroupTitle } from '../components/daily-hours-additional-form-group-title';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_LOG_DATE]: {
    width: 100,
    name: 'titles:date',
  },
  [GC.FIELD_ON_DUTY_MINUTES]: {
    width: 100,
    name: 'titles:on-duty-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_ON_DUTY_MINUTES, data));

      return shortString;
    },
  },
  [GC.FIELD_DRIVING_MINUTES]: {
    width: 100,
    name: 'titles:driving-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_DRIVING_MINUTES, data));

      return shortString;
    },
  },
  [GC.FIELD_SOURCE]: {
    width: 100,
    name: 'titles:source',
  },
  [GC.FIELD_TOTAL_DISTANCE]: {
    width: 150,
    name: 'titles:total-distance',
  },
  [GC.FIELD_TOTAL_DISTANCE_UOM]: {
    width: 150,
    name: 'titles:total-distance-uom',
  },
  [GC.FIELD_TRUCK_DAILY_LOG_GUID]: {
    width: 150,
    name: 'titles:from-truck-log',
    customComponent: ({ data }: Object) => {
      const truckDailyLogGuid = G.getPropFromObject(GC.FIELD_TRUCK_DAILY_LOG_GUID, data);

      if (G.isNilOrEmpty(truckDailyLogGuid)) return I.uiFalse();

      return I.uiTrue();
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    name: 'titles:update-on',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

export const dailyHoursSettings = {
  report,
  columnSettings,
  CustomForm: DailyHoursForm,
  groupName: 'dailyHoursList',
  getItemListRequestMethod: 'post',
  itemTitleArr: ['titles:driver-daily-log', 'Driver Daily Log'],
  formGroupTitleArr: ['titles:driver-daily-log', 'Driver Daily Log'],
  modalAdditionalOptions: {
    p: '0 0 15px 0',
    options: { title: null },
  },
  endpoints: {
    list: 'driverDailyLogList',
    remove: 'driverDailyLogByGuid',
  },
  additionalTableSettings: {
    expandableItems: true,
    checkBoxCellWidth: 81,
    expandedDetailsComponent: ({ parentProps }: Object) => <DriverHosLogList {...parentProps} />,
  },
  AdditionalFormGroupTitleComponent: DailyHoursAdditionalFormGroupTitle,
  // helpers
  makeRequestPayload: ({ driverBranchGuid, primaryObjectGuid }: Object) => ({
    params: {
      [GC.FIELD_DRIVER_GUID]: primaryObjectGuid,
    },
    data: {
      offset: 0,
      limit: 300,
      pageable: false,
      [GC.CURRENT_BRANCH]: driverBranchGuid,
    },
  }),
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, getItemListRequest, createOrUpdateEntityRequest } = props;

    createOrUpdateEntityRequest({
      values,
      additionalOptions,
      getItemListRequest,
      groupName: 'dailyHoursList',
      method: G.ifElse(G.isNotNil(G.getGuidFromObject(values)), 'put', 'post'),
      endpoint: G.ifElse(
        G.isNotNil(G.getGuidFromObject(values)),
        endpointsMap.driverDailyLog,
        endpointsMap.driverDailyLogMassCreate,
      ),
    });
  },
};
