import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, all, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../../../components/loader/actions';
import { disconnectFromMailIntegrationSuccess } from '../../../../common/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga, getObjectWithCurrentBranchGuidParamSaga } from '../../../../sagas';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../../utilities/http';
// feature configs
import * as A from './actions';
import { makeSelectOnboardingPackageList } from '../../selectors';
import { getAvailableDocumentTypes, getCarrierInvoiceStatusesRequest } from '../../actions';
import {
  getConfigByGroupSaga,
  getStatusMessagesForConfigSaga,
  getTermsAndConditionsDocumentRequest,
  getReferenceTypesByScopeForConfigSaga,
} from '../../sagas';
//////////////////////////////////////////////////

const CONFIG_NAMES_ARR = [
  GC.GENERAL_MODE_TRANSPORTATION,
];

function* getBranchConfigsByNamesForCarrierGroupRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        names: R.join(',', CONFIG_NAMES_ARR),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsByNamesForCarrierGroupSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsByNameRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchConfigsByNameRequest exception');
  }
}

function* getCarrierDeactivationRuleListSaga() {
  try {
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierDeactivationRuleList,
      parentSagaName: 'getCarrierDeactivationRuleListSaga',
      successAction: A.getCarrierDeactivationRuleListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierDeactivationRuleListSaga exception');
  }
}

function* getParentBranchesByBranchGuidRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const endpoint = endpointsMap.getParentAndCustomerBranchesWithoutRootByBranchGuid(branchGuid);
    const mapper = R.map(({ guid, name }: Object) => ({
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: name,
    }));
    yield call(crudSaga, {
      mapper,
      endpoint,
      method: 'get',
      successAction: A.getParentBranchesByBranchGuidSuccess,
      parentSagaName: 'getParentBranchesByBranchGuidRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getParentBranchesByBranchGuidRequest exception');
  }
}

function* createCarrierDeactivationRuleSaga({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload) };
    yield call(crudSaga, {
      options,
      method: 'post',
      shouldCloseModal: true,
      endpoint: endpointsMap.carrierDeactivationRule,
      parentSagaName: 'createCarrierDeactivationRuleSaga',
      successAction: A.createCarrierDeactivationRuleSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'createCarrierDeactivationRuleSaga exception');
  }
}

function* updateCarrierDeactivationRuleSaga({ payload }: Object) {
  try {
    const options = { data: payload };
    yield call(crudSaga, {
      options,
      method: 'put',
      shouldCloseModal: true,
      endpoint: endpointsMap.carrierDeactivationRule,
      parentSagaName: 'updateCarrierDeactivationRuleSaga',
      successAction: A.updateCarrierDeactivationRuleSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'updateCarrierDeactivationRuleSaga exception');
  }
}

function* deleteCarrierDeactivationRuleSaga({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      endpoint: endpointsMap.carrierDeactivationRule,
      parentSagaName: 'deleteCarrierDeactivationRuleSaga',
      successAction: A.deleteCarrierDeactivationRuleSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'deleteCarrierDeactivationRuleSaga exception');
  }
}

function* handleDisconnectFromMailIntegrationSaga() {
  try {
    const onboardingPackage = yield select(makeSelectOnboardingPackageList());

    const replyTo = R.path([0, GC.FIELD_MAIL_SENDING_REPLY_TO], onboardingPackage);

    if (G.isNotNilAndNotEmpty(replyTo)) {
      yield put(disconnectFromMailIntegrationSuccess(replyTo));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleDisconnectFromMailIntegrationSaga exception');
  }
}

function* createOrUpdateCarrierOnboardingPackageRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { data, method } = payload;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = { data: R.assoc(GC.BRANCH_GUID, branchGuid, data) };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierOnboardingPackage,
      callbackSaga: handleDisconnectFromMailIntegrationSaga,
      successAction: A.createOrUpdateCarrierOnboardingPackageSuccess,
      parentSagaName: 'createOrUpdateCarrierOnboardingPackageRequestSaga',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateCarrierOnboardingPackageRequestSaga exception');
  }
}

function* createOrUpdateCarrierOnboardingPackageDocumentRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { data, endpoint } = payload;
    const options = { data: G.makeDataForDocument(data) };
    yield call(crudSaga, {
      options,
      payload,
      method: 'post',
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: R.prop(endpoint, endpointsMap),
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateCarrierOnboardingPackageDocumentSuccess,
      parentSagaName: 'createOrUpdateCarrierOnboardingPackageDocumentRequestSaga',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierOnboardingPackageDocumentRequestSaga exception');
  }
}

function* deleteCarrierOnboardingPackageRequestSaga({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.carrierOnboardingPackage,
      callbackSaga: handleDisconnectFromMailIntegrationSaga,
      successAction: A.deleteCarrierOnboardingPackageSuccess,
      parentSagaName: 'createCarrierOnboardingPackageRequestSaga',
    });
  } catch (error) {
    yield call(G.handleException, error, 'deleteCarrierDeactivationRuleSaga exception');
  }
}

function* deleteCarrierOnboardingPackageDocumentRequestSaga({ payload }: Object) {
  try {
    yield call(crudSaga, {
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.deleteCarrierOnboardingPackageDocumentSuccess,
      parentSagaName: 'deleteCarrierOnboardingPackageDocumentRequestSaga',
      endpoint: endpointsMap.deleteCarrierOnboardingPackageDocument(payload),
    });
  } catch (error) {
    yield call(G.handleException, error, 'deleteCarrierOnboardingPackageDocumentRequestSaga exception');
  }
}

function* getCarrierOnboardingPackageListRequestSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.carrierOnboardingPackageList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const onboardingPackageList = G.ifElse(
        G.isNotNilAndNotEmpty(data),
        R.assocPath(
          [0, 'editable'],
          R.pathEq(branchGuid, [0, GC.BRANCH_GUID], data),
          data,
        ),
        [],
      );
      yield put(A.receivedCarrierOnboardingListSuccess(onboardingPackageList));
      if (G.isNotNilAndNotEmpty(data)) {
        yield put(A.getCarrierOnboardingDocumentListRequest(R.path([0, GC.FIELD_GUID], data)));
      }
    } else {
      yield call(G.handleException, 'error', 'getCarrierOnboardingPackageListRequestSaga exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierOnboardingPackageListRequestSaga exception');
  }
}

function* getCarrierOnboardingPackageDocumentListRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.FIELD_PRIMARY_OBJECT_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.carrierOnboardingPackageDocumentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedList = G.ifElse(
        G.isNotNilAndNotEmpty(data),
        R.map((item: Object) => R.assoc(
          'editable',
          R.propEq(currentBranchGuid, GC.BRANCH_GUID, item),
          item,
        ), data),
        [],
      );
      yield put(A.receivedCarrierOnboardingDocumentListSuccess(mappedList));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierOnboardingPackageDocumentListRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getCarrierOnboardingPackageDocumentListRequestSaga exception');
  }
}

function* downloadOnboardingPackageDocumentRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { documentFilename, primaryObjectGuid } = payload;
    const options = {
      params: { primaryObjectGuid, [GC.FIELD_FILE_NAME]: documentFilename },
    };

    const res = yield call(
      sendRequestWithQSParamsSerializer,
      'get',
      endpointsMap.downloadCarrierOnboardingPackageDocument,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      FileDownload(data, documentFilename);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'downloadOnboardingPackageDocumentRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, 'downloadOnboardingPackageDocumentRequestSaga exception');
  }
}

// carrier scorecards config
function* getCarrierScorecardConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.carrierScorecardConfigList,
      successAction: A.getCarrierScorecardConfigListSuccess,
      parentSagaName: 'getCarrierScorecardConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierRateIntegrationListRequest exception');
  }
}

function* createOrUpdateCarrierScorecardConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.carrierScorecardConfig,
      successAction: A.createOrUpdateCarrierScoreCardconfigSuccess,
      parentSagaName: 'createOrUpdateCarrierScorecardConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCarrierScorecardConfigRequest exception');
  }
}

function* removeCarrierScorecardConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.carrierScorecardConfig,
      successAction: A.removeCarrierScorecardConfigSuccess,
      parentSagaName: 'removeCarrierRateIntegrationRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierScorecardConfigRequest exception');
  }
}

function* handleVisitConfigCarrierPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_CARRIER_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield all([
      call(getCarrierDeactivationRuleListSaga),
      call(getStatusMessagesForConfigSaga),
      put(getCarrierInvoiceStatusesRequest()),
      call(getParentBranchesByBranchGuidRequest),
      call(getCarrierScorecardConfigListRequest),
      call(getCarrierOnboardingPackageListRequestSaga),
      call(getConfigByGroupSaga, GC.CARRIER_CONFIG_GROUP),
      call(getBranchConfigsByNamesForCarrierGroupRequest),
      call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_TEL }),
      call(getTermsAndConditionsDocumentRequest, { payload: GC.CARRIER_CONFIG_GROUP }),
    ]);

    yield put(getAvailableDocumentTypes());
    yield put(closeLoader());

    break;
  }
}

function* carrierConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_CARRIER_PAGE, handleVisitConfigCarrierPageSaga);
  yield takeLatest(A.getCarrierDeactivationRuleListRequest, getCarrierDeactivationRuleListSaga);
  yield takeLatest(A.createCarrierDeactivationRuleRequest, createCarrierDeactivationRuleSaga);
  yield takeLatest(A.updateCarrierDeactivationRuleRequest, updateCarrierDeactivationRuleSaga);
  yield takeLatest(A.deleteCarrierDeactivationRuleRequest, deleteCarrierDeactivationRuleSaga);
  yield takeLatest(A.deleteCarrierOnboardingPackageRequest, deleteCarrierOnboardingPackageRequestSaga);
  yield takeLatest(A.getCarrierOnboardingPackageListRequest, getCarrierOnboardingPackageListRequestSaga);
  yield takeLatest(A.downloadOnboardingPackageDocumentRequest, downloadOnboardingPackageDocumentRequestSaga);
  yield takeLatest(A.getCarrierOnboardingDocumentListRequest, getCarrierOnboardingPackageDocumentListRequestSaga);
  yield takeLatest(A.deleteCarrierOnboardingPackageDocumentRequest, deleteCarrierOnboardingPackageDocumentRequestSaga);
  yield takeLatest(A.createOrUpdateCarrierOnboardingPackageRequest, createOrUpdateCarrierOnboardingPackageRequestSaga);
  yield takeLatest(
    A.createOrUpdateCarrierOnboardingPackageDocumentRequest,
    createOrUpdateCarrierOnboardingPackageDocumentRequestSaga,
  );
  // carrier scorecard config
  yield takeLatest(A.removeCarrierScorecardConfigRequest, removeCarrierScorecardConfigRequest);
  yield takeLatest(A.getCarrierScorecardConfigListRequest, getCarrierScorecardConfigListRequest);
  yield takeLatest(A.createOrUpdateCarrierScorecardConfigRequest, createOrUpdateCarrierScorecardConfigRequest);
}

export default carrierConfigWatcherSaga;
