import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-profile
import { TableCell } from '../components/table-cells';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fieldsWrapperStyles = {
  flexDirection: 'column',
};

export const fields = {
  [GC.FIELD_SHOWN_DOCUMENT_TYPE_GUIDS]: {
    width: 600,
    isMulti: true,
    type: 'reactSelect',
    options: 'availableDocumentTypes',
    name: 'titles:shown-document-types',
    customComponent: ({ data, callbackData }: Object) => {
      const documentTypes = R.prop(GC.FIELD_SHOWN_DOCUMENT_TYPE_GUIDS, data);
      const availableDocumentTypes = R.prop('availableDocumentTypes', callbackData);

      if (R.and(G.isNilOrEmpty(documentTypes), G.isNilOrEmpty(availableDocumentTypes))) return null;

      const indexed = R.indexBy(G.getParentGuidOrGuidFromObject, availableDocumentTypes);

      const text = R.compose(
        R.join(', '),
        R.filter(G.isNotEmpty),
        R.map((guid: string) => R.pathOr('', [guid, GC.FIELD_DISPLAYED_VALUE], indexed)),
      )(documentTypes);

      return <TableCell text={text} />;
    },
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE]: G.yupNumberNotRequired
    .min(0, G.getShouldBePositiveLocaleTxt())
    .max(100, G.getShouldBeLessOrEqualLocaleTxt(100)),
});

export const internalDispatchSettings = {
  fields,
  singleItem: true,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'internalDispatchSettings',
  tableCallbackDataProps: ['availableDocumentTypes'],
  itemTitleArr: ['titles:internal-dispatch-settings', 'Internal Dispatch Settings'],
  formGroupTitleArr: ['titles:internal-dispatch-settings', 'Internal Dispatch Settings'],
  endpoints: {
    list: 'carrierInternalDispatchSettings',
    remove: 'carrierInternalDispatchSettingsByGuid',
    createOrUpdate: 'carrierInternalDispatchSettings',
  },
  // helpers
  shouldNotRenderTable: ({ internalDivision, availableDocumentTypes }: Object) => R.or(
    R.not(internalDivision),
    R.isNil(availableDocumentTypes),
  ),
  makeOptionsForSelect: ({ availableDocumentTypes }: Object) => ({
    availableDocumentTypes: G.getOptionsFromConfigValueByParentGuidOrGuid(R.or(availableDocumentTypes, [])),
  }),
};
