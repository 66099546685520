import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
import ChangeEntityBranch from '../../../branch/components/change-entity-branch';
// forms
import CancelCLOForm from '../../../../forms/forms/cancel-clo-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncRequest } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// utilities
import routesMap, { getOrderBySourceRoute } from '../../../../utilities/routes';
import endpointsMap from '../../../../utilities/endpoints';
// feature dispatch-report
import * as LC from '../../constants';
import { RC } from '../../settings/filter-params';
import { OrderTemplateForm } from './order-template-form';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncRequest,
  withHandlers({
    handleGoToDetails: ({ entity, closeFixedPopup }: Object) => () => {
      closeFixedPopup();
      G.goToRoute(routesMap.dispatchDetailsOrder(G.getGuidFromObject(entity)));
    },
    handleDuplicateOrder: ({ entity, closeFixedPopup }: Object) => async () => {
      closeFixedPopup();

      const options = {
        [GC.FIELD_GUID]: G.getGuidFromObject(entity),
        [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_COPY,
      };

      G.goToRoute(getOrderBySourceRoute(options));
    },
    handleCreateTemplate: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        closeFixedPopup,
        makeAsyncRequest,
        currentBranchGuid,
        referenceTypeOptions,
      } = props;

      const initialValues = {
        [LC.FIELD_CLO_GUID]: entity.guid,
        [LC.FIELD_TEMPLATE_ENTERPRISE_GUID]: currentBranchGuid,
        [LC.FIELD_TEL_CREATION_MODE]: GC.TEL_CREATION_MODE_NO_TEL,
      };

      const component = (
        <OrderTemplateForm
          closeModal={closeModal}
          initialValues={initialValues}
          fieldsGroupWidth='min-content'
          optionsForSelect={{ referenceTypeOptions }}
          handleSubmitForm={(data: Object) => makeAsyncRequest(endpointsMap.cloTemplateFromClo, { data })}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('actions:create-clo-template', 'Create CLO Template'),
        },
      };

      closeFixedPopup();
      openModal(modal);
    },
    handleCancelOrRestoreLoadOnRouteList: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        closeFixedPopup,
        changeCancelStatusRequest,
      } = props;

      const canceled = R.equals(R.path([GC.FIELD_STATUS], entity), GC.LOAD_STATUS_CANCELED);
      const type = G.ifElse(canceled, 'restore', 'cancel');

      let modal;

      if (R.not(canceled)) {
        const { closeModal, cancelCLORequest } = props;

        const initialValues = { [GC.FIELD_GUID]: R.prop(GC.FIELD_GUID, entity) };
        const telGuid = R.path([RC.TELS_GUID], entity);

        const component = (
          <CancelCLOForm
            telGuid={telGuid}
            closeModal={closeModal}
            initialValues={initialValues}
            submitAction={cancelCLORequest}
            cloGuid={R.path([GC.FIELD_GUID], entity)}
            branchGuid={R.prop(GC.BRANCH_GUID, entity)}
          />
        );

        modal = { p: '0px', component, options: {} };
      } else {
        const component = <ConfirmComponent textLocale={G.getWindowLocale('messages:are-you-sure', 'Are you sure?')} />;

        modal = {
          component,
          options: {
            width: 600,
            controlButtons: [
              {
                type: 'button',
                name: G.getWindowLocale('actions:submit', 'Submit'),
                action: () => {
                  changeCancelStatusRequest({ type, data: entity });
                  closeModal();
                },
              },
            ],
          },
        };
      }

      closeFixedPopup();
      openModal(modal);
    },
    handleChangeBranch: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeFixedPopup,
        getItemListRequest,
        resetListAndPagination,
      } = props;

      const callback = () => {
        resetListAndPagination();
        getItemListRequest(true);
      };

      const component = (
        <ChangeEntityBranch
          guid={entity.guid}
          callback={callback}
          type={GC.LOAD_TYPE_CLO}
        />
      );

      const modal = {
        p: '0 8px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getWindowLocale('titles:change-branch', 'Change Branch'),
        },
      };

      closeFixedPopup();
      openModal(modal);
    },
  }),
  pure,
);

const getOptions = ({
  entity,
  handleGoToDetails,
  handleChangeBranch,
  handleDuplicateOrder,
  handleCreateTemplate,
  handleCancelOrRestoreLoadOnRouteList,
}: Object) => {
  const iconColor = G.getTheme('colors.dark.blue');
  const permissions = [PC.CLO_READ, PC.CLO_WRITE];

  return [
    {
      permissions,
      action: handleGoToDetails,
      frontIcon: I.routesLoads(iconColor),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
    },
    {
      action: handleDuplicateOrder,
      frontIcon: I.duplicateIcon(iconColor),
      permissions: [PC.CLO_DUPLICATE_EXECUTE],
      notHasPermissions: [PC.ROLE_TYPE_CUSTOMER],
      text: G.getWindowLocale('actions:duplicate-clo', 'Duplicate CLO'),
    },
    {
      permissions,
      action: handleCreateTemplate,
      frontIcon: I.routesLoads(iconColor),
      notHasPermissions: [PC.ROLE_TYPE_CUSTOMER],
      text: G.getWindowLocale('actions:create-template', 'Create Template'),
    },
    {
      permissions: [PC.CLO_WRITE],
      frontIcon: I.crossInRound(iconColor),
      action: handleCancelOrRestoreLoadOnRouteList,
      text: G.ifElse(
        G.notEquals(R.path(['status'], entity), GC.LOAD_STATUS_CANCELED),
        G.getWindowLocale('actions:clo-cancel', 'Cancel'),
        G.getWindowLocale('actions:clo-restore', 'Restore'),
      ),
    },
    {
      action: handleChangeBranch,
      frontIcon: I.company(iconColor),
      permissions: [PC.CLO_ENTERPRISE_WRITE],
      text: G.getWindowLocale('titles:change-branch', 'Change Branch'),
    },
  ];
};

export const RowActions = enhance((props: Object) => <ActionsElement version={2} options={getOptions(props)} />);
