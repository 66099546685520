import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../../../components/modal/actions';
// features
import BranchTree from '../../../../branch/components/branch-tree';
import { makeSelectBranchListFromCurrentAndDown } from '../../../../branch/selectors';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex } from '../../../../../ui';
// feature configs
import { ConfigGroupLeftSectionWrapper } from '../../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleEdit: (props: Object) => () => {
      const component = (
        <BranchTree
          width={600}
          allowSelect={true}
          rootDisabled={true}
          initiallyExpanded={true}
          useFromCurrentAndDown={true}
          closeAction={props.closeModal}
          branchTypeFilter={GC.BRANCH_TYPE_ENUM_DIVISION}
          initialSelectedList={R.pathOr([], ['initialValues', GC.CARRIER_ALLOW_EDITING_FOR_DIVISIONS], props)}
          handleMultipleSelect={(value: Array) => {
            props.handleFormSubmit({ [GC.CARRIER_ALLOW_EDITING_FOR_DIVISIONS]: value });
            props.closeModal();
          }} />
      );
      const modal = {
        p: '0px',
        component,
        options: {
          width: 500,
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:select-divisions', 'Select Divisions'),
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

const AllowedDivisions = (props: Object) => {
  const configList = R.pathOr([], ['initialValues', GC.CARRIER_ALLOW_EDITING_FOR_DIVISIONS], props);

  return (
    <Flex height={55}>
      <Flex>
        {
          R.isEmpty(configList) &&
          <Box>{G.getWindowLocale('titles:no-divisions', 'No Divisions')}</Box>
        }
        {
          configList.map((guid: string, i: number) => (
            <Box mr='6px' key={i}>
              {`${R.path(['branchList', guid, GC.BRANCH_NAME], props)}${
                G.ifElse(R.lt(i, R.dec(R.length(configList))), ',', '')
              }`}
            </Box>
          ))
        }
      </Flex>
      <Box ml={16} cursor='pointer' onClick={props.handleEdit}>
        {I.renderEditIcon(G.getTheme('icons.iconColor'))}
      </Box>
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  branchList: makeSelectBranchListFromCurrentAndDown(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
})(enhance(AllowedDivisions));
