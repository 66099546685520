import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { openModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import { ENUMS } from '../../../constants/enums';
// ui
import { Flex, ActionButton } from '../../../ui';
// feature available-driver
import { AvailabilityForm } from './availability-form';
import { setDriverAvailableRequest, setDriverUnavailableRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  connect(null, { openModal, setDriverAvailableRequest, setDriverUnavailableRequest }),
  withHandlers({
    handleSetDriverAvailableRequest: (props: Object) => ({ driverGuid, availableAt }: Object) => {
      const { openModal, setDriverAvailableRequest } = props;

      const component = (
        <AvailabilityForm
          driverGuid={driverGuid}
          withDate={G.isTrue(availableAt)}
          submitAction={setDriverAvailableRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'max-content',
          title: G.getWindowLocale('actions:driver-availability', 'Driver Availability'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const DriverAvailability = enhance((props: Object) => {
  const {
    status,
    driverGuid,
    mainActionButtonStyles,
    setDriverUnavailableRequest,
    handleSetDriverAvailableRequest,
  } = props;

  return (
    <AuthWrapper justifyContent='center' has={[PC.AVAILABLE_DRIVER_LIST_WRITE]}>
      <Flex mt={10} mb={15} width='100%' justifyContent='space-around'>
        {
          G.notEquals(status, ENUMS.ENUM_UNAVAILABLE) &&
          <ActionButton
            {...mainActionButtonStyles}
            cursor='pointer'
            bgColor={G.getTheme('colors.dark.mainRed')}
            onClick={() => setDriverUnavailableRequest({ driverGuid })}
          >
            {G.getWindowLocale('titles:unavailable', 'Unavailable')}
          </ActionButton>
        }
        {
          G.notEquals(status, ENUMS.ENUM_AVAILABLE_NOW) &&
          <ActionButton
            {...mainActionButtonStyles}
            cursor='pointer'
            onClick={() => handleSetDriverAvailableRequest({ driverGuid })}
          >
            {G.getWindowLocale('titles:available-now', 'Available Now')}
          </ActionButton>
        }
        <ActionButton
          {...mainActionButtonStyles}
          cursor='pointer'
          onClick={() => handleSetDriverAvailableRequest({ driverGuid, availableAt: true })}
        >
          {G.getWindowLocale('titles:available-At', 'Available At')}
        </ActionButton>
      </Flex>
    </AuthWrapper>
  );
});
