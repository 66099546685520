import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderComponent } from 'react-recompose';
// components
import Loader from '../../components/loader';
import { makeSelectLoader } from '../../components/loader/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { RelativeFlex, AbsoluteBox } from '../../ui';
// feature carrier-portal
import * as H from './helpers';
import { carrierResponseDeclineRequest } from './actions';
import { RateSection, DeclineSection, DeclinedRateSection } from './components/sections';
import { defaultDeclineDispatchFields, declineDispatchValidationSchema } from './settings/fields-settings';
import {
  makeSelectPortal,
  makeSelectPortalType,
  makeSelectDeclineReason,
  makeSelectDeclinedDispatch,
} from './selectors';
//////////////////////////////////////////////////

const getDeclineReasonOptions = (props: Object) => {
  const options = R.compose(
    R.map(({ guid, displayedValue }: Object) => ({
      value: guid,
      label: displayedValue,
    })),
    R.pathOr([], ['declineReasons']),
  )(props);

  return G.addEmptyOptionToDropDown(
    options,
    G.getWindowLocale('titles:select-decline-reason', 'Select Decline Reason'),
  );
};

const CarrierResponseDecline = (props: Object) => (
  <RelativeFlex width='100vw' alignItems='stretch'>
    {R.path(['loader', 'isOpened'], props) && <Loader />}
    <RateSection width='50%' portal={props.portal} portalType={props.portalType} />
    {
      G.isFalse(props.declinedDispatch) &&
      <DeclineSection {...props} declineReasonOptions={getDeclineReasonOptions(props)} />
    }
    {
      G.isTrue(props.declinedDispatch) &&
      <DeclinedRateSection portalType={props.portalType} />
    }
    <AbsoluteBox
      top='0'
      right='0'
      zIndex={9}
      width='50%'
      height='100%'
      minHeight='100vh'
      background={G.getTheme('colors.bgGrey')}
    />
  </RelativeFlex>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  loader: makeSelectLoader(state),
  portal: makeSelectPortal(state),
  portalType: makeSelectPortalType(state),
  declineReasons: makeSelectDeclineReason(state),
  declinedDispatch: makeSelectDeclinedDispatch(state),
});

const enhance = compose(
  connect(mapStateToProps, { carrierResponseDeclineRequest }),
  withFormik({
    enableReinitialize: true,
    validationSchema: declineDispatchValidationSchema,
    handleSubmit: (values: Object, { props, setSubmitting }: Object) =>
      props.carrierResponseDeclineRequest({ values, setSubmitting }),
    mapPropsToValues: ({ portalType, portal }: Object) => {
      const token = G.getPropFromObject('token', portal);

      if (H.isPortalTypeCarrier(portalType)) {
        return R.mergeRight(defaultDeclineDispatchFields, { token, portalType });
      }

      return {
        token,
        portalType,
        [GC.FIELD_COMMENT]: null,
        [GC.FIELD_STATUS_REASON_CODE_GUID]: null,
      };
    },
  }),
  branch(
    ({ portal }: Object) => G.isNilOrEmpty(portal),
    renderComponent(Loader),
  ),
  pure,
);

export default enhance(CarrierResponseDecline);
