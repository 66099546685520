import * as R from 'ramda';
import React from 'react';
// components
import { FormGroupTable } from '../../../components/form-group-table';
// helpers/constants
import * as G from '../../../helpers';
// feature carrier
import * as LC from '../constants';
import { contractDiscountSettings } from '../settings/column-settings';
import { enhanceContractDiscount } from '../hocs/enhance-contract-discount';
//////////////////////////////////////////////////

export const ContractDiscounts = enhanceContractDiscount((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    handleEditRow={props.handleEditDiscount}
    handleAddClick={props.handleAddDiscount}
    entitiesFields={contractDiscountSettings}
    groupName={LC.CARRIER_GROUP_NAME_DISCOUNT}
    handleDeleteRow={props.handleDeleteDiscount}
    entities={R.or(props.contractVariableValues, [])}
    isOpened={R.path([LC.CARRIER_GROUP_NAME_DISCOUNT], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:variable-discounts', 'Variable Discounts')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_DISCOUNT)}
  />
));
