import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { addPostedShipmentStateRequest } from '../../../../common/actions';
// components
import { InfoPair } from '../../../../components';
import { getConfirmModal } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
// features
import { makeSelectPostLoadBoards } from '../../../load-board/selectors';
// forms
import { SelectDropdownForm } from '../../../../forms/forms/select-dropdown-form';
import PostTelToLoadboardsForm from '../../../../forms/forms/post-tel-to-loadboard-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// feature dispatch-details-new
import { deletePostedShipmentRequest } from '../actions';
import { makeSelectPostedShipmentsData } from '../selectors';
//////////////////////////////////////////////////

export const getSelectDropDownFormModal = (options: Object, componentProps: Object) => ({
  p: 15,
  options,
  component: <SelectDropdownForm {...componentProps} />,
});

const enhance = compose(
  withHandlers({
    handleAddState: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        loadboards,
        postedShipment,
        addPostedShipmentStateRequest,
      } = props;

      const { states } = postedShipment;

      const options = R.filter(({ value }: Object) => R.and(
        G.isNotNil(R.find(R.propEq(value, GC.FIELD_TYPE), loadboards)),
        R.isNil(R.find(R.propEq(value, GC.FIELD_TYPE), states)),
      ), GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS);

      const submitAction = (type: string) => {
        addPostedShipmentStateRequest({
          type,
          postedShipmentGuid: G.getGuidFromObject(postedShipment),
        });
      };

      const componentProps = {
        options,
        submitAction,
        fieldWidth: 300,
        optionRequired: true,
        cancelAction: closeModal,
        fieldLabel: G.getWindowLocale('titles:load-boards', 'Load Boards'),
      };

      const modal = {
        p: 15,
        options: { width: 330 },
        component: <SelectDropdownForm {...componentProps} />,
      };

      openModal(modal);
    },
    handleDelete: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        postedShipment,
        deletePostedShipmentRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => deletePostedShipmentRequest(G.getGuidFromObject(postedShipment)),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:posted-shipment', 'Posted Shipment')}?`,
      });

      openModal(modalContent);
    },
    handleEdit: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        postedShipment,
      } = props;

      const { events, objectGuid, primaryReferenceValue } = postedShipment;

      const telsInfo = [{
        primaryReferenceValue,
        [GC.SYSTEM_OBJECT_LAST_EVENT]: {
          [GC.SYSTEM_OBJECT_LOCATION]: R.path([GC.SYSTEM_OBJECT_LOCATION], R.head(events)),
        },
        [GC.SYSTEM_OBJECT_FIRST_EVENT]: {
          [GC.SYSTEM_OBJECT_LOCATION]: R.path([GC.SYSTEM_OBJECT_LOCATION], R.last(events)),
        },
      }];

      const component = (
        <PostTelToLoadboardsForm
          isEdit={true}
          telsInfo={telsInfo}
          cancelAction={closeModal}
          guids={R.of(Array, objectGuid)}
          telsPostData={R.of(Array, postedShipment)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const getRenderData = (data: Object = {}) => {
  const {
    rate,
    length,
    maxPrice,
    minPrice,
    lengthUom,
    truckType,
    rateCurrency,
    publishToCarrier,
  } = data;

  return [
    {
      label: G.getWindowLocale('titles:truck', 'Truck'),
      text: R.pathOr(truckType, [truckType, GC.FIELD_LABEL], GC.indexedTruckOptions),
    },
    {
      text: `${rate} ${rateCurrency}`,
      label: G.getWindowLocale('titles:rate', 'Rate'),
    },
    {
      label: G.getWindowLocale('titles:price'),
      text: G.ifElse(
        R.or(G.isNotNilAndNotEmpty(minPrice), G.isNotNilAndNotEmpty(maxPrice)),
        `${R.or(minPrice, '_')} - ${R.or(maxPrice, '_')}`,
        null,
      ),
    },
    {
      label: G.getWindowLocale('titles:length', 'Length'),
      text: G.ifElse(R.isNil(length), null, `${length} ${G.getUomLocale(lengthUom)}`),
    },
    {
      label: G.getWindowLocale('titles:publish-to-carrier', 'Publish to Carrier'),
      text: G.ifElse(publishToCarrier, G.getWindowLocale('titles:yes', 'Yes'), G.getWindowLocale('titles:no', 'No')),
    },
  ];
};

const PostedShipments = (props: Object) => {
  const {
    handleEdit,
    handleDelete,
    postedShipment,
    handleAddState,
  } = props;

  if (G.isNilOrEmpty(postedShipment)) return null;

  const data = getRenderData(postedShipment);

  return (
    <Flex p='8px 0 4px'>
      <Box
        ml={10}
        cursor='pointer'
        onClick={handleAddState}
        color={G.getTheme('colors.dark.blue')}
      >
        {G.getWindowLocale('actions:add-load-board', 'Add Load Board')}
      </Box>
      <Box ml={10} cursor='pointer' onClick={handleEdit}>{I.pencil()}</Box>
      <Box ml={10} cursor='pointer' onClick={handleDelete}>{I.trash()}</Box>
      {
        data.map(({ text, label }: Object) => (
          <InfoPair
            key={label}
            text={text}
            label={label}
            margin='0 10px'
            lineHeight='unset'
            width='fit-content'
          />
        ))
      }
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loadboards: makeSelectPostLoadBoards(state),
  postedShipment: makeSelectPostedShipmentsData(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  deletePostedShipmentRequest,
  addPostedShipmentStateRequest,
})(enhance(PostedShipments));
