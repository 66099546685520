import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../../components/confirm';
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// utilities
import routesMap, { getOrderBySourceRoute } from '../../../../utilities/routes';
// feature dispatch-board
import { getCLODataRequest } from '../../helpers';
import { withSendCloToExternalSystem } from '../../hocs/with-send-clo-to-external-system';
//////////////////////////////////////////////////

const enhance = compose(
  withSendCloToExternalSystem,
  withHandlers({
    handleGoToDetails: (props: Object) => () => {
      const { entity, closeFixedPopup } = props;

      closeFixedPopup();

      G.goToRoute(routesMap.dispatchDetailsOrder(entity[GC.FIELD_GUID]));
    },
    handleDuplicateOrder: ({ entity, closeFixedPopup }: Object) => () => {
      closeFixedPopup();

      const options = {
        [GC.FIELD_GUID]: G.getGuidFromObject(entity),
        [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_COPY,
      };

      G.goToRoute(getOrderBySourceRoute(options));
    },
    handleAddReference: (props: Object) => () => {
      const { entity, addReference, closeFixedPopup } = props;

      closeFixedPopup();

      addReference(entity[GC.FIELD_GUID]);
    },
    handleCancelOrRestore: (props: Object) => async () => {
      const { entity, closeFixedPopup, handleCancelOrRestoreLoad } = props;

      closeFixedPopup();

      const clo = await getCLODataRequest(R.prop(GC.FIELD_GUID, entity));

      handleCancelOrRestoreLoad(clo);
    },
    handleDelete: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        closeFixedPopup,
        handleDeleteTel,
    } = props;

      const data = R.pick([GC.FIELD_GUID, 'rowIndex', 'tableIndex'], entity);

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => handleDeleteTel(data),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
      });

      closeFixedPopup();

      openModal(modalContent);
    },
    handleToggleHotLoad: (props: Object) => () => {
      const { closeFixedPopup, entity: { hot, guid, tableIndex }, toggleMarkAsHotLoadByLoadTypeRequest } = props;

      closeFixedPopup();

      toggleMarkAsHotLoadByLoadTypeRequest({
        guid,
        tableIndex,
        hot: R.not(hot),
        fromPage: 'dispatchBoard',
        [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
      });
    },
    handlePostEquipmentToLoadBoard: (props: Object) => () => {
      const { entity, closeFixedPopup, handlePostEquipment } = props;

      closeFixedPopup();
      handlePostEquipment(entity);
    },
  }),
  pure,
);

const getOptions = (props: Object) => {
  const {
    entity,
    handleDelete,
    handleGoToDetails,
    handleAddReference,
    handleToggleHotLoad,
    handleDuplicateOrder,
    handleCancelOrRestore,
    handleSendCloToExternalSystem,
  } = props;

  const iconColor = G.getTheme('colors.light.blue');

  return [
    {
      action: handleGoToDetails,
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      frontIcon: I.routesLoads(iconColor, 13, 13),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
    },
    {
      action: handleDuplicateOrder,
      permissions: [PC.CLO_DUPLICATE_EXECUTE],
      notHasPermissions: [PC.ROLE_TYPE_CUSTOMER],
      frontIcon: I.duplicateIcon(iconColor, 13, 13),
      text: G.getWindowLocale('actions:duplicate-clo', 'Duplicate CLO'),
    },
    {
      action: handleAddReference,
      permissions: [PC.CLO_REFERENCE_WRITE],
      frontIcon: I.plusRound(iconColor, 13, 13),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    },
    {
      permissions: [PC.CLO_WRITE],
      action: handleCancelOrRestore,
      frontIcon: I.crossInRound(iconColor, 13, 13),
      text: G.ifElse(
        R.pathEq(GC.LOAD_STATUS_CANCELED, [GC.FIELD_STATUS], entity),
        G.getWindowLocale('actions:restore', 'Restore'),
        G.getWindowLocale('actions:cancel', 'Cancel'),
      ),
    },
    {
      permissions: [PC.CLO_WRITE],
      frontIcon: I.locationMarker(iconColor, 13, 13),
      text: G.getWindowLocale('titles:send-to-external-system', 'Send To External System'),
      action: () => handleSendCloToExternalSystem({
        guid: G.getGuidFromObject(entity),
        fromPage: GC.PAGE_DISPATCH_BOARD_NEW,
      }),
    },
    {
      action: handleDelete,
      permissions: [PC.CLO_DELETE_EXECUTE],
      frontIcon: I.trash(iconColor, 11, 13),
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
    {
      permissions: [PC.CLO_WRITE],
      action: handleToggleHotLoad,
      frontIcon: I.hotLoad(iconColor),
      text: G.ifElse(
        G.isFalse(entity.hot),
        G.getWindowLocale('actions:mark-as-hot', 'Mark As Hot'),
        G.getWindowLocale('actions:remove-hot-load', 'Remove Hot Load'),
      ),
    },
  ];
};

export const RowActions = (props: Object) => <ActionsElement version={2} options={getOptions(props)} />;

export default enhance(RowActions);
