import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Form, renderFormGroup } from '../../../forms';
// feature dashboard
import { DASHBOARD_FORM } from '../constants';
//////////////////////////////////////////////////

const validate = [G.required, G.curriedIsEmptyOrMinMax(0, 100)];

const enhance = compose(
  reduxForm({
    form: DASHBOARD_FORM,
  }),
  withHandlers({
    handleFormSubmit: (props: Object) => (values: Object) => {
      props.closeModal();
      props.submitAction(values);
    },
  }),
  pure,
);

const CreateDashboard = (props: Object) => (
  <Form id={DASHBOARD_FORM} onSubmit={props.handleSubmit(props.handleFormSubmit)}>
    <Field
      type='text'
      name='name'
      margin='5px'
      width='265px'
      direction='column'
      errorFontSize={14}
      validate={validate}
      errorMargin='0 0 5px 0'
      component={renderFormGroup('text')}
      placeholder={G.getWindowLocale('titles:dashboard-name', 'Dashboard Name')} />
    <Field
      margin='5px'
      type='checkbox'
      direction='row'
      labelMargin='0 5px'
      labelPosition='right'
      name='defaultDashboard'
      component={renderFormGroup('checkbox')}
      label={G.getWindowLocale('titles:set-as-default', 'Set As Default')} />
  </Form>
);

export default enhance(CreateDashboard);
