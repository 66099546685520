import * as R from 'ramda';
import React from 'react';
import sizeMe from 'react-sizeme';
import { connect } from 'react-redux';
import ReactGL from 'react-grid-layout';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import Loader from '../../../components/loader';
import { Chart } from '../../charts/components/am-charts';
import { FormButtons } from '../../../components/form-buttons';
import { HoveringTitle } from '../../../components/hovering-title';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { FixedFlex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature dashboard
import {
  getDashboardRequest,
  setCurrentDashboard,
  cleanDashboardCharts,
  setDashboardChartsPosition } from '../actions';
import {
  reactGL,
  IconWrapper,
  ChartLayoutWrapper,
  ChartActionsWrapper,
  LayoutChartItemWrapper } from '../ui';
//////////////////////////////////////////////////

const chartActions = [
  {
    icon: 'pencil',
    handler: 'handleEditChart',
    title: 'actions:edit-chart',
    permissions: [PC.CHART_WRITE],
  },
  {
    icon: 'trash',
    handler: 'handleRemoveChart',
    permissions: [PC.DASHBOARD_WRITE],
    title: 'actions:remove-chart-from-dashboard',
  },
];

const DragZone = (props: Object) => (
  <FixedFlex
    zIndex='11'
    alignItems='center'
    justifyContent='center'
    width={props.size.width - 40}
    height={props.size.height - 40}
    background='rgba(32, 115, 161, .2)'
    border='1px dashed rgba(32, 115, 161, 1)'
    display={G.ifElse(props.snapshot.isDraggingOver, 'flex', 'none')}
  >
    {I.dnd()}
  </FixedFlex>
);

const renderActions = (actions: Array, { chart }: Object, props: Object) => (
  actions.map((action: Object, key: number) => (
    <AuthWrapper key={key} has={action.permissions}>
      <HoveringTitle
        title={G.getWindowLocale(action.title, '...')}
        handleClick={() => props[action.handler](chart)}
        positionConfig={{
          zIndex: 14,
          top: '100%',
          right: '30%',
          width: 'max-content',
        }}
      >
        <IconWrapper
          p={2}
          m='0 10px'
          iconWH='15px'
          wrapWH='19px'
          onClick={() => props[action.handler](chart)}
          title={G.getWindowLocale(action.title, '...')}
        >
          {I[action.icon]()}
        </IconWrapper>
      </HoveringTitle>
    </AuthWrapper>
  ))
);

const enhance = compose(
  withHandlers({
    handleOnLayoutChange: (props: Object) => (charts: Array) => {
      let isDnd = false;
      const dashCharts = props.charts.map((chartInfo: Object) => {
        isDnd = chartInfo.pending;
        const ch = R.find(R.propEq(chartInfo.chart.guid, 'i'), charts);
        const { x, y, w, h } = ch;
        return {
          ...chartInfo,
          y,
          x,
          h,
          w,
        };
      });
      if (G.isFalse(isDnd)) {
        props.setDashboardChartsPosition(dashCharts);
      }
    },
    handleRemoveChart: (props: Object) => ({ guid }: Object) => {
      const index = R.findIndex(({ chart }: Object) => R.equals(chart.guid, guid), props.charts);
      props.cleanDashboardCharts(index);
    },
    handleEditChart: () => ({ guid }: Object) => (
      G.goToRoute(routesMap.chartEdit(guid))
    ),
    handleClickCancel: (props: Object) => () => (
      props.getDashboardRequest(props.currentDashboard.guid)
    ),
  }),
  pure,
);

export const renderChart = (chartInfo: Object) => {
  const { data, chart, pending } = chartInfo;

  if (G.isTrue(pending)) {
    return <Loader position='absolute' />;
  }

  return (
    <Chart
      guid={chart.guid}
      title={chart.name}
      type={chart.chartType}
      height='calc(100% - 60px)'
      chartData={R.or(data, {})}
    />
  );
};

const ChartDroppable = (props: Object) => (
  <ChartLayoutWrapper>
    <DragZone {...props} />
    <ReactGL
      css={reactGL}
      layout={props.chartsLayout}
      width={props.size.width - 40}
      onLayoutChange={props.handleOnLayoutChange}
    >
      {
        props.charts.map((chartInfo: Object) => (
          <LayoutChartItemWrapper key={chartInfo.chart.guid}>
            <ChartActionsWrapper>{renderActions(chartActions, chartInfo, props)}</ChartActionsWrapper>
            {renderChart(chartInfo)}
          </LayoutChartItemWrapper>
        ))
      }
    </ReactGL>
    <FormButtons
      showSaveAsNew={true}
      handleClickCancel={props.handleClickCancel}
      handleClickSave={props.handleEditDashboard}
      pr={G.ifElse(props.isOpen, '250px', '200px')}
      handleClickSaveAsNew={props.handleSaveAsNewDashboard}
      btnText={G.getWindowLocale('actions:update', 'Update')}
    />
  </ChartLayoutWrapper>
);

export default connect(null, {
  setCurrentDashboard,
  getDashboardRequest,
  cleanDashboardCharts,
  setDashboardChartsPosition,
})(sizeMe({ monitorHeight: true })(enhance(ChartDroppable)));
