import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { RelativeBox } from '../../../ui';
// component
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// report-common
import { generateDefaultReport } from '../../../report-common';
// features
import { orderFilterProps } from '../../dispatch-report/settings/filter-params';
import { orderColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
// feature dispatch-planner
import * as H from '../helpers';
import CustomTitle from './custom-title';
import Confirm from '../components/confirm';
import SelectLoadsForm from './select-loads-form';
import { pageSizes } from '../settings/page-settings';
import { cloTableSettings } from '../settings/table-settings';
import {
  selectClo,
  getCloNextPage,
  toggleCloDetails,
  setCurrentCloReport,
  cleanCloQuickFilter,
  setCloTableTitleSort,
  getCloDetailsRequest,
  setCloTableTitleFilter,
  setAvailableCloReports,
  selectCloForTelRequest,
  createCloReportRequest,
  updateCloReportRequest,
  setCloQuickFilterParams,
  uncheckCloAndRemoveEvents,
  resetCloListAndPagination,
  refreshCloListWithRouteClos,
  changeDefaultCloReportRequest,
} from '../actions';
import {
  makeSelectTelList,
  makeSelectCloList,
  makeSelectCloTotal,
  makeSelectCloReport,
  makeSelectCurrentRoute,
  makeSelectCloPagination,
  makeSelectCloListLoading,
  makeSelectCloFilterParams,
  makeSelectAvailableCloReports,
  makeSelectCloTableTitleFilters,
  makeSelectCloTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const createOptions = (currentRoute: Object) => R.compose(
  R.map((tel: Object) => ({ label: H.getLoadName(tel), value: tel.guid })),
  R.values(),
  R.propOr({}, 'tels'),
)(currentRoute);

const enhance = compose(
  withHandlers({
    handleListRequest: ({ getCloNextPage }: Object) => () => (
      getCloNextPage()
    ),
    getQuickFilteredListRequest: (props: Object) => () => {
      props.resetCloListAndPagination();
      props.refreshCloListWithRouteClos();
    },
    handleSelectClo: (props: Object) => (cloGuid: string) => {
      let clo = null;
      const selectedClos = R.filter(
        (item: Object) => {
          if (R.equals(R.prop('guid', item), cloGuid)) clo = item;
          return R.pathOr(false, ['selected'], item);
        },
        props.itemList,
      );
      let component;
      const title = G.ifElse(
        clo.selected,
        G.getWindowLocale('messages:remove-clo', 'Are you sure you want to remove CLO?'),
        G.getWindowLocale('titles:choose-tel-for-clo', 'Please, choose TEL for selected CLO'),
      );
      if (clo.selected) {
        if (R.and(R.lte(selectedClos.length, 1), props.shouldNotUnselectLastClo)) return;
        component = (
          <Confirm
            submitData={clo}
            closeModal={props.closeModal}
            handleSubmitAction={props.uncheckCloAndRemoveEvents}
            cancelText={G.getWindowLocale('actions:cancel', 'Cancel')}
            actionText={G.getWindowLocale('actions:confirm', 'Confirm')}
            text={G.getWindowLocale(
              'titles:all-clo-events-will-be-removed',
              'All CLO events will be removed from route',
            )} />
        );
      } else {
        const tels = R.values(R.path(['currentRoute', 'tels'], props));
        if (R.equals(R.path(['length'], tels), 1)) {
          return props.selectCloForTelRequest({
            returnObject: { cloGuid },
            selectedValue: { value: R.path(['guid'], R.head(tels)) },
          });
        }
        component = (
          <SelectLoadsForm
            initialValue=''
            returnObject={{ cloGuid }}
            closeModal={props.closeModal}
            options={createOptions(props.currentRoute)}
            handleSubmitAction={props.selectCloForTelRequest} />
        );
      }
      const modal = {
        p: '15px',
        component,
        options: {
          title,
          width: 'min-content',
          height: 'max-content',
        },
      };
      props.openModal(modal);
    },
    handleToggleDetails: (props: Object) => (clo: Object) => props.toggleCloDetails(clo.guid),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        createCloReportRequest,
        updateCloReportRequest,
        refreshCloListWithRouteClos,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.CLO_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          setReport={setReport}
          requestPending={requestPending}
          onReportSet={refreshCloListWithRouteClos}
          createReportRequest={createCloReportRequest}
          updateReportRequest={updateCloReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleCleanFilter: (props: Object) => () => {
      props.cleanCloQuickFilter();
      props.refreshCloListWithRouteClos();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(
        R.propEq(reportGuid, 'guid'),
        props.reportList,
      );
      props.setReport(selectedReport);
      props.refreshCloListWithRouteClos();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    getCloNextPage,
    selectedReport,
    titleSortValues,
    handleSelectClo,
    tableTitleFilters,
    handleToggleDetails,
    handleTableTitleFilter,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  const primaryReferenceValueField = R.mergeRight(
    G.getPropFromObject(GC.FIELD_PRIMARY_REFERENCE_VALUE, columnSettings),
    {
      useExpandedContainer: true,
      guidPropName: GC.FIELD_GUID,
      type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    },
  );

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleSelectClo,
    handleLoadMoreEntities: getCloNextPage,
    tableSettings: R.mergeRight(cloTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(orderFilterProps)),
    columnSettings: R.assoc(GC.FIELD_PRIMARY_REFERENCE_VALUE, primaryReferenceValueField, columnSettings),
  };

  return <Table {...data} />;
};

const Orders = enhance((props: Object) => {
  const {
    setReport,
    totalCount,
    getCloNextPage,
    updateCloReportRequest,
    setAvailableCloReports,
  } = props;

  return (
    <div>
      <RelativeBox pr={15} zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          name='clo-report'
          type={GC.CLO_REPORT}
          setUsedReport={setReport}
          hiddenRightFilterInfo={false}
          filterProps={orderFilterProps}
          setReports={setAvailableCloReports}
          getItemListRequest={getCloNextPage}
          height={pageSizes.tableHeaderHeight}
          updateReportRequest={updateCloReportRequest}
          customTitleComponent={
            <CustomTitle
              mr={10}
              count={totalCount}
              text={G.getWindowLocale('titles:clo', 'Clo')}
            />
          }
        />
      </RelativeBox>
      <RelativeBox zIndex={1}>
        {renderTable(props)}
      </RelativeBox>
    </div>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  refList: () => [],
  noExportable: () => true,
  telList: makeSelectTelList(state),
  itemList: makeSelectCloList(state),
  totalCount: makeSelectCloTotal(state),
  loading: makeSelectCloListLoading(state),
  pagination: makeSelectCloPagination(state),
  selectedReport: makeSelectCloReport(state),
  currentRoute: makeSelectCurrentRoute(state),
  filterParams: makeSelectCloFilterParams(state),
  reportList: makeSelectAvailableCloReports(state),
  tableTitleFilters: makeSelectCloTableTitleFilters(state),
  titleSortValues: makeSelectCloTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  selectClo,
  openModal,
  closeModal,
  getCloNextPage,
  toggleCloDetails,
  cleanCloQuickFilter,
  getCloDetailsRequest,
  setAvailableCloReports,
  selectCloForTelRequest,
  createCloReportRequest,
  updateCloReportRequest,
  uncheckCloAndRemoveEvents,
  resetCloListAndPagination,
  refreshCloListWithRouteClos,
  setReport: setCurrentCloReport,
  getItemListRequest: getCloNextPage,
  setTableTitleSort: setCloTableTitleSort,
  setTableTitleFilter: setCloTableTitleFilter,
  setQuickFilterParams: setCloQuickFilterParams,
  resetListAndPagination: resetCloListAndPagination,
  changeDefaultReportRequest: changeDefaultCloReportRequest,
})(Orders);
