import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal, closeAllModals } from '../../../components/modal/actions';
// features
import { setDispatchPlannerEventsOpened } from '../../dispatch-details-new/load/actions';
// forms
import { SingleInputForm } from '../../../forms/forms/single-input-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, CustomButton } from '../../../ui';
// feature dispatch-planner-events
import RequestErrors from './request-errors';
import TelsForDelete from './tels-for-delete';
import PlannerActions from './planner-actions';
import SelectLoadsForm from './select-loads-form';
import { UnassignedEvents } from './event-details';
import { pageSizes } from '../settings/page-settings';
import withSaveRoute from '../hocs/with-save-cancel-route';
import { isPlannerEmpty, createTelOptions, getTelsWithTerminalEvents } from '../helpers';
import {
  makeSelectRouteErrors,
  makeSelectCurrentRoute,
  makeSelectOpenedFromPage,
  makeSelectCurrentRouteItems,
  makeSelectUnassignedCloEvents,
  makeSelectCrossDockLocationList,
} from '../selectors';
import {
  cleanBuilder,
  validateRouteRequest,
  refreshPlannerDataRequest,
  createRouteTemplateRequest,
  setCurrentRouteInitialState,
  addUnassignedEventToPlanner,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withSaveRoute,
  connect(null, { createRouteTemplateRequest, setDispatchPlannerEventsOpened }),
  withHandlers({
    handleCreateRouteTemplate: (props: Object) => () => {
      const { locations, openModal, closeModal, currentRoute, currentBranch, createRouteTemplateRequest } = props;

      const { tels } = currentRoute;

      const submitAction = (name: string) => {
        createRouteTemplateRequest({
          name,
          [GC.BRANCH_GUID]: currentBranch,
          [GC.SYSTEM_LIST_TELS]: getTelsWithTerminalEvents(R.values(tels), locations),
        });
      };

      const component = (
        <SingleInputForm
          fieldWidth={270}
          valueRequired={true}
          cancelAction={closeModal}
          submitAction={submitAction}
          fieldLabel={G.getWindowLocale('titles:template-name', 'Template Name')}
        />
      );

      const modal = G.getDefaultModalOptions(component);

      openModal(modal);
    },
  }),
  withHandlers({
    handleSaveAsRouteTemplate: (props: Object) => () => {
      const { currentRoute, validateRouteRequest, handleCreateRouteTemplate } = props;

      const { tels } = currentRoute;

      const terminalEvents = R.compose(
        R.filter(({ stopType }: Object) => G.isStopTypeTerminal(stopType)),
        R.flatten,
        R.map(({ events }: Object) => R.values(events)),
        R.values,
      )(tels);

      if (R.lt(R.length(terminalEvents), 3)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('titles:select-at-least-3-terminal-stops', 'Please, select at least 3 terminal stops'),
        );
      }

      validateRouteRequest({
        currentRoute,
        saveAsTemplate: true,
        successAction: handleCreateRouteTemplate,
      });
    },
    handleClickCancel: (props: Object) => () => {
      const { cleanBuilder, openedFromPage, setDispatchPlannerEventsOpened } = props;

      cleanBuilder();

      if (R.equals(openedFromPage, 'DISPATCH_DETAILS_NEW')) setDispatchPlannerEventsOpened(false);
    },
    handleAddUnassignedEventToPlanner: (props: Object) => (event: Object) => {
      const { openModal, closeModal, currentRoute, addUnassignedEventToPlanner } = props;

      const tels = R.values(R.path([GC.SYSTEM_LIST_TELS], currentRoute));

      if (R.equals(R.length(tels), 1)) {
        return addUnassignedEventToPlanner({
          returnObject: { event },
          selectedValue: { value: G.getGuidFromObject(R.head(tels)) },
        });
      }

      const title = G.getWindowLocale('titles:choose-tel-for-stop', 'Please, choose TEL for selected stop');

      const component = (
        <SelectLoadsForm
          initialValue=''
          closeModal={closeModal}
          returnObject={{ event }}
          options={createTelOptions(currentRoute)}
          handleSubmitAction={addUnassignedEventToPlanner}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 'min-content',
          height: 'max-content',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const Footer = (props: Object) => {
  const {
    currentRoute,
    openedFromPage,
    handleClickCancel,
    handleClickSavePlanner,
    refreshPlannerDataRequest,
    handleSaveAsRouteTemplate,
  } = props;

  const isEmpty = isPlannerEmpty(currentRoute);

  const cancelText = G.ifElse(
    R.equals(openedFromPage, 'DISPATCH_DETAILS_NEW'),
    G.getWindowLocale('actions:cancel', 'Cancel'),
    G.getWindowLocale('actions:clean', 'Clean'),
  );

  const commonBtnProps = {
    p: '4px',
    height: 20,
    width: '100%',
    fontSize: 12,
    type: 'button',
  };

  return (
    <Box mt='auto' width='100%'>
      <CustomButton {...commonBtnProps} mb='8px' onClick={handleSaveAsRouteTemplate}>
        {G.getWindowLocale('actions:save-as-route-template', 'Save As Route Template')}
      </CustomButton>
      <CustomButton {...commonBtnProps} mb='8px' onClick={refreshPlannerDataRequest}>
        {G.getWindowLocale('actions:refresh', 'Refresh')}
      </CustomButton>
      <CustomButton {...commonBtnProps} mb='8px' onClick={() => handleClickSavePlanner(true)}>
        {G.getWindowLocale('actions:save-and-close', 'Save And Close')}
      </CustomButton>
      <Flex justifyContent='space-between'>
        <CustomButton
          {...commonBtnProps}
          disabled={isEmpty}
          width='calc(50% - 4px)'
          cursor={G.ifElse(isEmpty, 'not-allowed')}
          onClick={() => {
            if (isEmpty) return;
            handleClickSavePlanner(false);
          }}
        >
          {G.getWindowLocale('actions:save', 'Save')}
        </CustomButton>
        <CustomButton
          {...commonBtnProps}
          width='calc(50% - 4px)'
          onClick={handleClickCancel}
          bgColor={G.getTheme('buttons.cancelBtn.bgColor')}
        >
          {cancelText}
        </CustomButton>
      </Flex>
    </Box>
  );
};

const PlannerLeftPanel = enhance((props: Object) => {
  const {
    errors,
    unassignedCloEvents,
    handleAddUnassignedEventToPlanner,
  } = props;

  return (
    <Box
      zIndex={1}
      height='100%'
      overflow='auto'
      color={G.getTheme('colors.light.black')}
      bg={G.getTheme('colors.light.mainLight')}
      boxShadow='0 0 10px 0 rgba(204, 204, 204, 0.5)'
      width={G.getPropFromObject('routeSummaryWidth', pageSizes)}
    >
      <Flex
        p='8px'
        width='100%'
        height='100%'
        flexDirection='column'
        minHeight='fit-content'
        justifyContent='space-between'
      >
        <Box mb={16} width='100%'>
          <PlannerActions />
        </Box>
        <TelsForDelete />
        <RequestErrors errors={errors} />
        <UnassignedEvents data={unassignedCloEvents} addEventToPlanner={handleAddUnassignedEventToPlanner} />
        <Footer {...props} />
      </Flex>
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  errors: makeSelectRouteErrors(state),
  currentRoute: makeSelectCurrentRoute(state),
  openedFromPage: makeSelectOpenedFromPage(state),
  locations: makeSelectCrossDockLocationList(state),
  currentRouteItems: makeSelectCurrentRouteItems(state),
  unassignedCloEvents: makeSelectUnassignedCloEvents(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  cleanBuilder,
  closeAllModals,
  validateRouteRequest,
  refreshPlannerDataRequest,
  setCurrentRouteInitialState,
  addUnassignedEventToPlanner,
})(PlannerLeftPanel);
