import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import { toastr } from 'react-redux-toastr';
// helpers/constants
import * as G from '../../helpers';
// feature fleet
import { validateBeforeChangeDriverTab } from './driver/actions';
import { validateBeforeChangeTruckTab } from './truck/actions';
import { validateBeforeChangeTrailerTab } from './trailer/actions';
//////////////////////////////////////////////////

const fleets = {
  driver: {
    details: [
      'email',
      'loginId',
      'lastName',
      'firstName',
      'phoneNumber',
      'outOfDutyReason',
      'onDutyHoursLeft',
      'offDutyHoursLeft',
      'unemploymentReason',
    ],
    docs: [
      'issueDate',
      'licenseClass',
      'restrictions',
      'endorsements',
      'licenseState',
      'licenseNumber',
      'licenseCountry',
      'expirationDate',
    ],
    payment: [],
  },
  truck: {
    details: [
      'year',
      'unitId',
      'truckType',
      'titleState',
      'titleNumber',
      'licensePlate',
      'registrationState',
      'outOfServiceReason',
      'unavailabilityReason',
      'registrationExpirationDate',
    ],
    docs: [],
    specification: [
      'gvwt',
      'width',
      'height',
      'length',
      'mpgLow',
      'mpgHigh',
      'widthUom',
      'lengthUom',
      'heightUom',
      'tareWeight',
      'paymentAmount',
      'gvwtWeightType',
      'tareWeightType',
      'fifthWheelWidth',
      'fifthWheelHeight',
      'registrationCost',
      'equipmentReturnCost',
      'fifthWheelWidthType',
      'fifthWheelHeightType',
      'equipmentReturnCostPaid',
      'doorDimension.doorWidth',
      'equipmentReturnCostRatio',
      'doorDimension.doorHeight',
      'equipmentReturnCostAccrued',
      'doorDimension.doorWidthhUom',
      'doorDimension.doorHeightUom',
      'equipmentReturnBalanceRequired',
      'equipmentReturnCostNotProvisioned',
    ],
    service: [],
  },
  trailer: {
    details: [
      'unitId',
      'outputYear',
      'titleState',
      'titleNumber',
      'trailerType',
      'licensePlate',
      'paymentAmount',
      'registrationCost',
      'registrationState',
      'outOfServiceReason',
      'equipmentReturnCost',
      'unavailabilityReason',
      'equipmentReturnCostPaid',
      'registrationExpiredDate',
      'equipmentReturnCostAccrued',
      'equipmentReturnBalanceRequired',
      'equipmentReturnCostNotProvisioned',
    ],
    docs: [
      'gvwt',
      'cube',
      'cubeType',
      'deckHeight',
      'tareWeight',
      'bulkCompCount',
      'deckHeightType',
      'tareWeightType',
      'gvwtWeightType',
      'bulkCompVolume',
      'bulkCompVolumeType',
      'refrigeration.model',
      'doorDimension.width',
      'refrigeration.hours',
      'refrigeration.series',
      'wellDimension.height',
      'refrigeration.carbId',
      'wellDimension.length',
      'doorDimension.height',
      'doorDimension.widthUOM',
      'refrigeration.truatcmId',
      'wellDimension.heightUOM',
      'internalDimension.width',
      'doorDimension.heightUOM',
      'externalDimension.width',
      'refrigeration.hoursDate',
      'wellDimension.lengthUOM',
      'internalDimension.height',
      'internalDimension.length',
      'externalDimension.length',
      'externalDimension.height',
      'refrigeration.outputYear',
      'refrigeration.tempRangeTo',
      'refrigeration.coolantType',
      'refrigeration.serialNumber',
      'externalDimension.widthUOM',
      'internalDimension.widthUOM',
      'internalDimension.heightUOM',
      'externalDimension.lengthUOM',
      'externalDimension.heightUOM',
      'refrigeration.tempRangeType',
      'internalDimension.lengthUOM',
      'refrigeration.tempRangeFrom',
      'refrigeration.refrigerationManufacturer',
    ],
    service: [],
  },
};

const validate = ({ getState, action }: Object, allow: void, reject: void) => {
  const { form } = getState();

  const { touch, fleet, tabName, formName, tabAction } = action.payload;

  const errors = R.pathOr({}, [formName, 'syncErrors'], form);

  const values = R.pathOr({}, [formName, 'values'], form);

  let isValid = true;

  R.forEach(
    (field: string) => {
      const error = R.pathOr(null, R.split('.', field), errors);

      const value = R.pathOr(null, R.split('.', field), values);

      if (R.and(G.isNotNil(error), G.isNilOrEmpty(value))) {
        isValid = false;

        touch(field);
      }
    },
    R.pathOr([], [fleet, tabName], fleets),
  );

  if (isValid) {
    tabAction();

    allow(action);
  } else {
    toastr.info(G.getWindowLocale('messages:valid-data', 'Please, provide valid data'));

    return reject();
  }
};

export const handleDriverChangeTab = createLogic({
  validate,
  type: validateBeforeChangeDriverTab,
});

export const handleTruckChangeTab = createLogic({
  validate,
  type: validateBeforeChangeTruckTab,
});
export const handleTrailerChangeTab = createLogic({
  validate,
  type: validateBeforeChangeTrailerTab,
});

export default [
  handleTruckChangeTab,
  handleDriverChangeTab,
  handleTrailerChangeTab,
];
