import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
// utilities
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  integrationFields,
  defaultIntegrationFields,
  truckIntegrationTypeOptions,
  driverIntegrationTypeOptions,
  trailerIntegrationTypeOptions,
  getValidationSchemaIntegrationFormObject,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultIntegrationFields,
      props.initialValues,
    ),
    validationSchema: () => Yup.lazy(({ type }: Object) =>
      Yup.object().shape(getValidationSchemaIntegrationFormObject(type))),
    handleSubmit: (values: Object, { props }: Object) => {
      const { objectKey, objectGuid, submitAction } = props;
      const { type, externalId, fuelCardId, transponderId } = values;

      let data = R.assoc(
        objectKey,
        objectGuid,
        R.pick([GC.FIELD_TYPE, GC.FIELD_GUID, GC.FIELD_VERSION], values),
      );

      switch (type) {
        case GC.FLEET_INTEGRATION_TYPE_EFS:
          data = R.assoc(GC.FIELD_FLEET_FUEL_CARD_ID, fuelCardId, data);
          break;
        case GC.FUEL_CARDS_TYPE_COMDATA:
          data = R.assoc(GC.FIELD_FLEET_FUEL_CARD_ID, fuelCardId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_I_PASS:
          data = R.assoc(GC.FIELD_FLEET_TRANSPONDER_ID, transponderId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_BESTPASS:
          data = R.assoc(GC.FIELD_FLEET_TRANSPONDER_ID, transponderId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_PRE_PASS:
          data = R.assoc(GC.FIELD_FLEET_TRANSPONDER_ID, transponderId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_SELF_TOLL:
          data = R.assoc(GC.FIELD_FLEET_TRANSPONDER_ID, transponderId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_SAMSARA:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.GPS_INTEGRATION_TYPE_GEO_TAB:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.FUEL_CARDS_TYPE_QUIKQ:
          data = R.assoc(GC.FIELD_FLEET_FUEL_CARD_ID, fuelCardId, data);
          break;
        case GC.FUEL_CARDS_TYPE_BVD:
          data = R.assoc(GC.FIELD_FLEET_FUEL_CARD_ID, fuelCardId, data);
          break;
        case GC.FUEL_CARDS_TYPE_SELF_FUELED:
          data = R.assoc(GC.FIELD_FLEET_FUEL_CARD_ID, fuelCardId, data);
          break;
        case GC.FUEL_CARDS_TYPE_RELAY:
          data = R.assoc(GC.FIELD_EXTERNAL_ID, externalId, data);
          break;
        case GC.GPS_INTEGRATION_TYPE_VERIZON:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.GPS_INTEGRATION_TYPE_CLUB_ELD:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.GPS_INTEGRATION_TYPE_BLUE_STAR:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.GPS_INTEGRATION_TYPE_MASTER_ELD:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.GPS_INTEGRATION_TYPE_OMNITRACS:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_SKYBITZ:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.FLEET_INTEGRATION_TYPE_POWER_FLEET:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.FLEET_INTEGRATION_SPIREON:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        case GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET:
          data = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, externalId, data);
          break;
        default: data = values;
      }

      submitAction(data);
    },
  }),
  withProps(({ objectKey, createdIntegrations }: Object) => {
    const optionsMap = {
      [GC.FIELD_TRUCK_GUID]: truckIntegrationTypeOptions,
      [GC.FIELD_DRIVER_GUID]: driverIntegrationTypeOptions,
      [GC.FIELD_TRAILER_GUID]: trailerIntegrationTypeOptions,
    };
    const integrationTypeOptions = R.compose(
      R.map((item: Object) => R.assoc(
        'disabled',
        R.includes(R.prop(GC.FIELD_VALUE, item), createdIntegrations),
        item,
      )),
      R.pathOr(driverIntegrationTypeOptions, [objectKey]),
    )(optionsMap);

    return { integrationTypeOptions };
  }),
  pure,
);

const IntegrationFormComponent = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={integrationFields}
        handlers={{ handleDisableIntegrationType: () => props.isEditMode }}
        optionsForSelect={{ [GC.FIELD_FLEET_INTEGRATION_TYPE]: props.integrationTypeOptions }} />
      <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(IntegrationFormComponent);
