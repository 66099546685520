/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import PopperColorPicker from '../../../components/color-picker/popper-color-picker';
// helpers
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
// feature components-story
import { setColorPickerValue } from '../actions';
import { makeSelectColorPickerValue } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const PopperColorPickerComponent = ({ colorPickerValue, setColorPickerValue }: Object) => {
  const description = '<PopperColorPicker /> component is a combination of a color picker UI and a popper (dropdown/tooltip).';

  const usage = (
    <div>
      <ul>
        <li>Parameter `<i>arrowColor</i>` defines the color of the dropdown arrow icon displayed next to the active box.</li>
        <li>Parameter `<i>activeBorder</i>` specifies the border style for the active box, which can override the default border.</li>
        <li>Set `<i>pickerProps</i>` to configure the color picker, including properties like the initial value or event handlers.</li>
        <li>Set `<i>popperProps</i>` to customize the behavior and styles of the popper component, such as its position, size, and shadow.</li>
        <li>Set `<i>activeBoxProps</i>` to customize the appearance of the active box displaying the selected color, including size, border, and margin.</li>
        <li>Parameter `<i>value</i>` represents the currently selected color, extracted from the pickerProps. It determines the background color of the active box.</li>
      </ul>
    </div>
  );

  const changeColorCallback = (color: Object) => setColorPickerValue(color);

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='PopperColorPicker'
      description={description}
      path='src/components/color-picker/popper-color-picker'
    >
      <Flex width={200} justifyContent='flex-end'>
        <PopperColorPicker
          activeBoxProps={{ height: 17 }}
          arrowColor={`${G.getTheme('colors.darkGrey')}`}
          activeBorder={`2px solid ${G.getTheme('colors.darkGrey')}`}
          pickerProps={{
            changeColorCallback,
            value: { colorPickerValue },
            wrapperStyles: { width: 150, justifyContent: 'space-between' },
            itemStyles: { width: 20, height: 20, opacity: 0.9, borderRadius: '4px' },
          }}
        />
      </Flex>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  colorPickerValue: makeSelectColorPickerValue(state),
});

export default connect(mapStateToProps, {
  setColorPickerValue,
})(PopperColorPickerComponent);
