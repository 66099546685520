import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// ui
import { Box, Grid } from '../../../ui';
// feature geo-fencing-location
import RouteMap from './route-map';
import HistoryList from './history-list';
import { makeSelectListLoading, makeSelectTrailerRouteHistory } from '../selectors';
//////////////////////////////////////////////////

const RouteHistoryPopup = (props: Object) => {
  const { closeModal} = props;

  const loading = useSelector(makeSelectListLoading());
  const routeHistoryList = useSelector(makeSelectTrailerRouteHistory());

  useEffect(() => () => closeModal(), []);

  return (
    <Box
      width={1020}
      maxHeight='85vh'
      overflow='hidden'
      p='20px 15px 15px 15px'
    >
      <Grid gap={15} gridTemplateColumns='350px 1fr'>
        <HistoryList loading={loading} routeHistoryList={routeHistoryList} />
        <RouteMap routeHistoryList={routeHistoryList} />
      </Grid>
    </Box>
  );
};

export default RouteHistoryPopup;
