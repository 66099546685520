import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

class LoadChatMessages extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncChatMessages: null };
    this.getLoadChatMessagesRequest = this.getLoadChatMessagesRequest.bind(this);
  }

  componentDidMount() {
    this.getLoadChatMessagesRequest(this.props);
  }

  async getLoadChatMessagesRequest(props: Object) {
    const { loadGuid, loadType } = props;

    const params = { loadGuid };
    const endpoint = G.ifElse(
      G.isLoadTypeClo(loadType),
      endpointsMap.cloChatMessageList,
      endpointsMap.telChatMessageList,
    );
    const res = await sendRequest('get', endpoint, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$set('asyncChatMessages', R.or(R.reverse(data), []), this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncLoadChatMessages -> getLoadChatMessagesRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getLoadChatMessagesRequest)}</div>;
  }
}

export const withAsyncLoadChatMessages = (Component: any) => class extends React.Component {
  render() {
    return (
      <LoadChatMessages
        loadGuid={this.props.loadGuid}
        loadType={this.props.loadType}
        render={(parentState: Object, getLoadChatMessagesRequest: Function) =>
          <Component
            {...this.props}
            asyncChatMessages={parentState.asyncChatMessages}
            getLoadChatMessagesRequest={getLoadChatMessagesRequest}
          />
          }
      />
    );
  }
  };
