import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-profile
import { TableCell } from '../components/table-cells';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_INTEGRATION_TYPE]: {
    name: 'titles:integration-type',
    customComponent: (row: Object) => R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_INTEGRATION_TYPE]),
    )(row),
  },
  [GC.FIELD_STATUS]: {
    uiType: 'enum',
    name: 'titles:status',
  },
  [GC.FIELD_INTEGRATION_ID]: {
    name: 'titles:integration-id',
  },
  [GC.FIELD_EXTERNAL_DIVISION_NAME]: {
    name: 'titles:division',
  },
  [GC.FIELD_EXPORTED_BY]: {
    width: 150,
    name: 'titles:exported-by',
  },
  [GC.FIELD_ERROR]: {
    width: 300,
    name: 'titles:error',
  },
  [GC.FIELD_CARRIER_ADDED_TO_NETWORK]: {
    width: 150,
    uiType: 'boolean',
    name: 'titles:added-to-network',
  },
  [GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW]: {
    width: 150,
    uiType: 'boolean',
    name: 'titles:allowed-book-it-now',
  },
  [GC.BRANCH_GUID]: {
    name: 'titles:branch',
    customComponent: (row: Object) =>
      <TableCell text={G.getBranchNameFromWindowByGuid(R.path(['data', GC.BRANCH_GUID], row))} />,
  },
};

const integrationInfoFields = R.pick(
  [
    GC.FIELD_INTEGRATION_TYPE,
    GC.FIELD_INTEGRATION_ID,
    GC.FIELD_EXTERNAL_DIVISION_NAME,
  ],
  settings,
);

const integrationAuditFields = R.pick(
  [
    GC.FIELD_INTEGRATION_TYPE,
    GC.FIELD_STATUS,
    GC.FIELD_EXTERNAL_DIVISION_NAME,
    GC.FIELD_EXPORTED_BY,
    GC.FIELD_ERROR,
  ],
  settings,
);

const loadBoardIntegrationInfoFields = R.pick(
  [
    GC.FIELD_INTEGRATION_TYPE,
    GC.FIELD_CARRIER_ADDED_TO_NETWORK,
    GC.FIELD_CARRIER_ALLOWED_BOOK_IT_NOW,
    GC.BRANCH_GUID,
  ],
  settings,
);

const integrationInfoSettings = {
  singleItem: '',
  actionsPicker: [],
  groupName: 'integrationInfo',
  fields: integrationInfoFields,
  formGroupTitleArr: ['titles:integration-info', 'Integration Info'],
  endpoints: {
    list: 'carrierIntegrationInfoList',
  },
};

const integrationAuditSettings = {
  singleItem: '',
  actionsPicker: [],
  groupName: 'integrationAudit',
  fields: integrationAuditFields,
  formGroupTitleArr: ['titles:integration-audit', 'Integration Audit'],
  endpoints: {
    list: 'carrierIntegrationAuditList',
  },
};

const synchronizationInfoFields = {
  [GC.FIELD_INTEGRATION_TYPE]: R.prop(GC.FIELD_INTEGRATION_TYPE, settings),
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:synchronization-status',
  },
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_ERROR_MESSAGE]: {
    width: 400,
    name: 'titles:error-message',
  },
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_USER_LOGIN]: {
    width: 200,
    name: 'titles:synchronization-by',
  },
  [GC.FIELD_CARRIER_SYNCHRONIZATION_INFO_SYNCHRONIZATION_DATE]: {
    width: 200,
    name: 'titles:synchronization-date',
  },
};

const loadBoardIntegrationInfoSettings = {
  singleItem: '',
  actionsPicker: [],
  groupName: 'loadBoardIntegrationInfo',
  fields: loadBoardIntegrationInfoFields,
  formGroupTitleArr: ['titles:load-board-integration-info', 'Load Board Integration'],
  endpoints: {
    list: 'carrierLoadBoardIntegrationList',
  },
  // helpers
  makeRequestPayload: ({ primaryObjectGuid, primaryObjectBranchGuid }: Object) => ({
    params: {
      [GC.BRANCH_GUID]: primaryObjectBranchGuid,
      [GC.FIELD_CARRIER_GUID]: primaryObjectGuid,
    },
  }),
};

const synchronizationInfoSettings = {
  singleItem: '',
  actionsPicker: [],
  hideIfEmptyList: true,
  groupName: 'synchronizationInfo',
  fields: synchronizationInfoFields,
  omitColumns: GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR_2,
  formGroupTitleArr: ['titles:synchronization-info', 'Synchronization Info'],
};

export {
  integrationInfoSettings,
  integrationAuditSettings,
  synchronizationInfoSettings,
  loadBoardIntegrationInfoSettings,
};
