import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, lifecycle, renderNothing } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { Tabs2 } from '../../../components/tabs-mui';
import EditReport from '../../../components/edit-report';
import { TitlePanel } from '../../../components/title-panel';
import { ActionsElement } from '../../../components/actions-element';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withShowListIssues,
  withSetExpandedContainerOptions,
} from '../../../hocs';
// icons
import * as I from '../../../svgs';
// report-common
import { reportEnhancer } from '../../../report-common';
// ui
import { IconWrapper, ListWrapper, ZOrderWrapper } from '../../../ui';
// feature carrier
import { getTabs } from '../helpers';
import { insurancesTableSettings } from '../settings/table-settings';
// feature carrier insurance
import { columnSettings, FILTER_PARAMS } from './settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withSetExpandedContainerOptions,
  withShowListIssues({ listName: 'carrierInsurances', getListRequestActionName: 'getItemListRequest' }),
  reportEnhancer,
  withHandlers({
    handleClickEditIcon: (props: Object) => ({ currentTarget }: Object, entity: Object) => {
      const options = [
        {
          permissions: [PC.CARRIER_WRITE],
          text: G.getWindowLocale('actions:edit', 'Edit'),
          frontIcon: I.pencil(G.getTheme('icons.iconColor')),
          action: () => G.goToCarrierProfileByConfig(R.prop(GC.GRC.CARRIER_GUID, entity)),
        },
      ];

      return (
        props.openFixedPopup({
          position: 'right',
          el: currentTarget,
          content: (
            <ActionsElement options={options} />
          ),
        })
      );
    },
    handleEdit: () => ({ currentTarget }: Object, entity: Object) =>
      G.goToCarrierProfileByConfig(R.prop(GC.GRC.CARRIER_GUID, entity)),
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          hasIssues={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const { getItemListRequest, expandedContainerOptions: { opened } = {} } = this.props;

      const { expandedContainerOptions: { opened: prevOpened, componentType } = {} } = prevProps;

      const insuranceGuid = R.path(['expandedContainerOptions', 'options', 'insuranceGuid'], prevProps);

      if (G.isAllTrue(
        prevOpened,
        G.isFalse(opened),
        G.isNotNilAndNotEmpty(insuranceGuid),
        R.equals(componentType, 'carrierProfile'),
      )) getItemListRequest({ guids: R.of(Array, insuranceGuid) });
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleShowListIssues,
    handleTableTitleFilter,
    setExpandedContainerOptions,
  } = props;

  if (R.not(selectedReport)) return null;

  const elementActionsComponent = (carrier: Object) => {
    const cond = R.not(R.or(
      G.isTrue(R.prop('editable', carrier)),
      G.getIsAmousUserSuperAdminFromWindow(),
    ));

    if (cond) return null;

    return (
      <AuthWrapper has={[PC.CARRIER_READ, PC.CARRIER_WRITE]}>
        <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, carrier)}>
          {I.threeDots()}
        </IconWrapper>
      </AuthWrapper>
    );
  };

  const tableSettingsToUse = G.getTableSettingsWithMaxHeightByConditions({
    reportList,
    filterParams,
    selectedReport,
    tableSettings: insurancesTableSettings,
  });

  const actionButtons = [
    {
      iconName: 'pencil',
      permissions: [PC.CARRIER_WRITE],
      action: (insuranceGuid: string, entity: Object) => setExpandedContainerOptions({
        opened: true,
        options: { insuranceGuid },
        componentType: GC.PAGE_CARRIER_PROFILE,
        visitPageGuid: R.prop(GC.GRC.CARRIER_GUID, entity),
      }),
    },
  ];

  const data = {
    itemList,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: false,
    handleShowListIssues,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: tableSettingsToUse,
    handleLoadMoreEntities: getItemListRequest,
    loading: R.and(loading, G.isNilOrEmpty(itemList)),
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS)),
  };

  return <Table {...data} />;
};

const CarrierInsurancesListComponent = (props: Object) => {
  const {
    itemList,
    activeTab,
    totalCount,
    selectedReport,
    getItemListRequest,
    handleSetActiveTab,
  } = props;

  const selectedListLength = R.length(R.filter(({ selected }: Object) => selected, R.or(itemList, [])));

  const handleClickTab = (tabIndex: number) => {
    if (R.equals(tabIndex, 0)) G.goToRoute(GC.ROUTE_PATH_CARRIERS_LIST);

    handleSetActiveTab(tabIndex);
  };

  const customTitleComponent = (
    <Tabs2
      count={totalCount}
      activeTab={activeTab}
      tabs={getTabs(activeTab)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          withoutQuickFilter={true}
          usedReport={selectedReport}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          type={PC.CARRIER_INVOICE_REPORT}
          getItemListRequest={getItemListRequest}
          selectedListLength={selectedListLength}
          customTitleComponent={customTitleComponent}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReports,
  closeModal,
  selectItem,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
})(enhance(CarrierInsurancesListComponent));
