import * as R from 'ramda';
import React from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// forms
import { SelectLocation } from '../../../forms/forms/select-location';
import { LocationTemplateSearch } from '../../../forms/forms/template-search/components/location-template-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const searchTemplateFieldsOmit = [
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
];

export const withInitialValuesFromSearchTemplate = compose(
  withState('initialValues', 'setInitialValues', ({ entity }: Object) => entity),
  withHandlers({
    handleSearchLocation: (props: Object) => (e: Event) => {
      G.stopPropagation(e);

      const searchTemplateRequest = (location: Object) => {
        const contacts = R.map(R.omit(searchTemplateFieldsOmit), R.pathOr([], ['contacts'], location));
        const locationToSave = R.mergeRight(R.omit(searchTemplateFieldsOmit, location), { contacts });

        props.setInitialValues(
          R.mergeRight(
            R.pick(
              searchTemplateFieldsOmit,
              R.pathOr({}, ['initialValues'], props),
            ),
            locationToSave,
          ),
        );

        props.closeModal();
      };

      const modalContent = (
        <LocationTemplateSearch
          closeModal={props.closeModal}
          branchGuid={props.branchGuid}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      props.openModal(modal);
    },
    handleSelectLocation: (props: Object) => (e: Event) => {
      G.stopPropagation(e);

      const searchTemplateRequest = (location: Object) => {
        const needValues = R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR, location);

        props.setInitialValues((prev: Object) => R.mergeRight(prev, needValues));

        props.closeModal();
      };

      const modalContent = (
        <SelectLocation
          closeModal={props.closeModal}
          initialValues={props.initialValues}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      props.openModal(modal);
    },
  }),
);
