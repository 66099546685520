import * as R from 'ramda';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
// components
import { TextComponent } from '../../../../components/text';
import { openModal } from '../../../../components/modal/actions';
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// fleet-profile
import {
  deleteDriverExpenseDocumentRequest,
  downloadDriverExpenseDocumentRequest,
} from '../../actions';
//////////////////////////////////////////////////

const ExpenseDocument = memo((props: Object) => {
  const { fileUri, fileName, hideDelete } = props;

  if (R.isNil(fileUri)) return null;

  const dispatch = useDispatch();

  const handleDelete = () => {
    const { guid } = props;

    const text = G.getWindowLocale(
      'messages:documents:remove-confirmation-text',
      'Are you sure you want delete this document?',
    );

    const component = <ConfirmComponent textLocale={text} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:remove', 'Remove'),
            action: () => dispatch(deleteDriverExpenseDocumentRequest(guid)),
          },
        ],
      },
    };

    dispatch(openModal(modal));
  };

  return (
    <Flex justifyContent='space-between'>
      <TextComponent
        display='block'
        title={fileName}
        withEllipsis={true}
        maxWidth='calc(100% - 40px)'
      >
        {fileName}
      </TextComponent>
      <Box
        cursor='pointer'
        onClick={() => dispatch(downloadDriverExpenseDocumentRequest({ fileUri, fileName }))}
      >
        {I.download()}
      </Box>
      {
        R.not(hideDelete) &&
        <Box cursor='pointer' onClick={handleDelete}>{I.trash()}</Box>
      }
    </Flex>
  );
});

export default ExpenseDocument;
