import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportReducers } from '../../report-common';
// feature edi-integration
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  filterProps: [],
  globalFilterValue: '',
};

const {
  selectItem,
  setReports,
  initialState,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setFilterProps = (state: Object, data: Array) => P.$set('filterProps', data, state);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('totalCount', 0),
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('titleSortValues', {}),
    P.$set('reportPending', false),
    P.$set('tableTitleFilters', {}),
    P.$set('globalFilterValue', ''),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setUsedReportOnly = (state: Object, data: Object) => (
  P.$all(
    P.$set('usedReport', data),
    P.$set('globalFilterValue', ''),
    state,
  )
);

const setGlobalFilterValue = (state: Object, data: string) => (
  P.$set('globalFilterValue', data, state)
);

const acceptLoadFromEDISuccess = (state: Object, { transactionGuid, status }: Object) => (
  P.$set(`itemList.${transactionGuid}.${GC.FIELD_EDI_STATUS}`, status, state)
);

const declineLoadFromEDISuccess = (state: Object, { transactionGuid, status }: Object) => (
  P.$set(`itemList.${transactionGuid}.${GC.FIELD_EDI_STATUS}`, status, state)
);

const setCloLoadFromEDIStatus = (state: Object, { transactionGuid, status }: Object) => (
  P.$set(`itemList.${transactionGuid}.${GC.FIELD_CLO}.${GC.FIELD_STATUS}`, status, state)
);

const setExternalIdFilter = (state: Object, externalId: string) => (
  P.$set('externalIdFilter', externalId, state)
);

export default createReducer({
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setFilterProps]: setFilterProps,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.setUsedReportOnly]: setUsedReportOnly,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.setExternalIdFilter]: setExternalIdFilter,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.setGlobalFilterValue]: setGlobalFilterValue,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setCloLoadFromEDIStatus]: setCloLoadFromEDIStatus,
  [A.acceptLoadFromEDISuccess]: acceptLoadFromEDISuccess,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [A.declineLoadFromEDISuccess]: declineLoadFromEDISuccess,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
}, initialState);
