import * as R from 'ramda';
// helpers/constants
import * as GC from '../../constants';
import { BRANCH_TYPE_MASTER_OPTION } from '../../helpers/options';
//////////////////////////////////////////////////

export const addMasterTypeToOptions = (fields: Object, isSuperAdmin: boolean) => R.map((field: Object) => {
  const fieldNameToUse = R.or(field.fieldName, field.nameForAttribute);

  if (R.and(R.equals(fieldNameToUse, GC.FIELD_BRANCH_BRANCH_TYPE), isSuperAdmin)) {
    return R.assoc('options', R.append(BRANCH_TYPE_MASTER_OPTION, field.options), field);
  }

  return field;
}, fields);
