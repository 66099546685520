import React from 'react';
import * as R from 'ramda';
// forms
import { LocationFormWithSearch } from '../../../forms/forms/location-form-with-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-profile
import { TableCell } from '../components/table-cells';
//////////////////////////////////////////////////

const fields = {
  [GC.FIELD_TEMPLATE_ID]: {
    width: 150,
    name: 'titles:template-id',
  },
  [GC.FIELD_PAYMENT_TERM]: {
    width: 150,
    name: 'titles:payment-term',
  },
  [GC.FIELD_LOCATION_TYPE]: {
    width: 200,
    name: 'titles:location-type',
    customComponent: ({ data }: Object) =>
      <TableCell text={R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DISPLAYED_VALUE], data)} />,
  },
  [GC.FIELD_LOCATION_NAME]: {
    width: 150,
    name: 'titles:name',
  },
  [GC.FIELD_ADDRESS_1]: {
    width: 200,
    name: 'titles:address1',
  },
  [GC.FIELD_ADDRESS_2]: {
    width: 150,
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 150,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 150,
    name: 'titles:state',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    name: 'titles:country',
  },
  [GC.FIELD_ZIP]: {
    width: 100,
    name: 'titles:zip',
  },
  [GC.FIELD_CONTACT_NAME]: {
    width: 150,
    name: 'titles:contact-name',
  },
  [GC.FIELD_PHONE]: {
    width: 150,
    name: 'titles:phone',
  },
  [GC.FIELD_PHONE_EXTENSION]: {
    width: 150,
    name: 'titles:phone-extension',
  },
  [GC.FIELD_EMAILS]: {
    width: 200,
    name: 'titles:emails',
  },
  [GC.FIELD_FAX]: {
    width: 100,
    name: 'titles:fax',
  },
  [GC.FIELD_COMMENTS]: {
    width: 600,
    name: 'titles:comments',
  },
};

const CustomForm = (props: Object) => {
  const {
    openModal,
    closeModal,
    configGroup,
    submitAction,
    initialValues,
    primaryObjectBranchGuid,
  } = props;

  const mode = G.ifElse(
    R.isNil(R.prop(GC.FIELD_VERSION, initialValues)),
    G.getAddTitle,
    G.getEditTitle,
  )(['titles:bill-to', 'Bill To']);

  return (
    <LocationFormWithSearch
      mode={mode}
      openModal={openModal}
      closeModal={closeModal}
      returnFullLocation={true}
      submitHandler={submitAction}
      submitAndSaveLocation={false}
      initialValues={initialValues}
      branchGuid={primaryObjectBranchGuid}
      formType={GC.LOCATION_FORM_TYPE_CONTRACT_BILL_TO_LOCATION}
      optionsForSelect={{
        [GC.FIELD_LOCATION_TYPE]: G.addEmptyOptionToDropDown(
          R.path(['dropdownOptions', GC.TEMPLATES_LOCATION_TYPE], configGroup),
        ),
      }}
    />
  );
};

const carrierContractBillToSettings = {
  fields,
  singleItem: true,
  groupName: 'carrierContract.billTo',
  itemTitleArr: ['titles:bill-to', 'Bill To'],
  formGroupTitleArr: ['titles:bill-to', 'Bill To'],
  primaryObjectGuidKey: GC.FIELD_CARRIER_CONTRACT_GUID,
  modalAdditionalOptions: {
    p: '0px',
    options: {
      overflow: 'auto',
      maxHeight: '90vh',
    },
  },
  endpoints: {
    list: 'carrierContractBillTo',
    remove: 'removeCarrierContractBillTo',
    createOrUpdate: 'carrierContractBillTo',
  },
  // components
  CustomForm,
  // helpers
  makeInitialValues: (initialValues: Object = {}) => {
    const locationType = R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues);

    if (R.isNil(locationType)) return initialValues;

    return { ...initialValues, locationType };
  },
};

const customerContractBillToSettings = {
  ...carrierContractBillToSettings,
  groupName: 'customerContract.billTo',
  primaryObjectGuidKey: GC.FIELD_CUSTOMER_CONTRACT_GUID,
  endpoints: {
    list: 'customerContractBillTo',
    remove: 'removeCustomerContractBillTo',
    createOrUpdate: 'customerContractBillTo',
  },
};

export {
  carrierContractBillToSettings,
  customerContractBillToSettings,
};
