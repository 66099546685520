import * as R from 'ramda';
import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature claim-management
import { useCreateDropdownOptionsFromAsyncConfigs } from '../../hooks';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 190,
};

const settings = {
  [GC.FIELD_FIRST_NAME]: {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:first-name'],
    fieldName: GC.FIELD_FIRST_NAME,
  },
  [GC.FIELD_LAST_NAME]: {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:last-name'],
    fieldName: GC.FIELD_LAST_NAME,
  },
  [GC.FIELD_CONTACT_TYPE]: {
    isRequired: true,
    type: 'reactSelect',
    label: ['titles:contact-type'],
    fieldName: GC.FIELD_CONTACT_TYPE,
    inputWrapperStyles: { mb: 25, width: 400 },
    options: GC.CLAIM_INVOLVED_PARTY_CONTACT_TYPE,
  },
  [GC.FIELD_EMAIL]: {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:email'],
    fieldName: GC.FIELD_EMAIL,
  },
  [GC.FIELD_PHONE]: {
    inputWrapperStyles,
    type: 'phoneNumber',
    label: ['titles:phone'],
    fieldName: GC.FIELD_PHONE,
  },
  [GC.FIELD_COMMENTS]: {
    type: 'textarea',
    label: ['titles:comments'],
    fieldName: GC.FIELD_COMMENTS,
    inputWrapperStyles: { mb: 25, width: 400 },
  },
};

const validationSchemaObject = {
  [GC.FIELD_LAST_NAME]: G.yupStringRequired,
  [GC.FIELD_FIRST_NAME]: G.yupStringRequired,
  [GC.FIELD_CONTACT_TYPE]: G.yupStringRequired,
  [GC.FIELD_EMAIL]: G.yupStringRequired.email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_COMMENTS]: Yup.string().nullable(true).max(1999, G.getShouldBeFromToCharLocaleTxt(1, 1999)),
};

const useForm = (props: Object, isAttorney: Boolean) => {
  const { submitAction, initialValues } = props;

  const onSubmit = (values: Object) => submitAction(G.mapObjectEmptyStringFieldsToNull(values));

  const formik = useFormik({
    onSubmit,
    validationSchema: Yup.object().shape(G.ifElse(
      isAttorney,
      R.dissoc(GC.FIELD_CONTACT_TYPE, validationSchemaObject),
      validationSchemaObject,
    )),
    initialValues: {
      ...R.map(() => null, settings),
      ...initialValues,
      [GC.FIELD_CONTACT_TYPE]: R.pathOr('', [GC.FIELD_CONTACT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues),
    },
  });

  return formik;
};

const InvolvedPartyForm = (props: Object) => {
  const { claimBranchGuid } = props;

  const formik = useForm(props);

  const { handleSubmit } = formik;

  const options = useCreateDropdownOptionsFromAsyncConfigs({
    branchGuid: claimBranchGuid,
    names: GC.CLAIM_INVOLVED_PARTY_CONTACT_TYPE,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        {...options}
        fields={R.values(settings)}
        fieldsWrapperStyles={{ pt: 15, width: 400, justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
};

const AttorneyForm = (props: Object) => {
  const formik = useForm(props, true);

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        fields={R.values(R.dissoc(GC.FIELD_CONTACT_TYPE, settings))}
        fieldsWrapperStyles={{ pt: 15, width: 400, justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
};

export {
  AttorneyForm,
  InvolvedPartyForm,
};
