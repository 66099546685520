import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../../components/table';
import { Label } from '../../../../components/label';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable, FormGroupTitlePanel } from '../../../../components/form-group-table';
// features
import PC from '../../../permission/role-permission';
import { setQuickFilterParams, getQuickFilteredListRequest } from '../../../report-format/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { withAsyncAccessorials } from '../../../../hocs/with-async-accessorials';
import { Toggle, ToggleWrapper, FormGroupTitleWrapper } from '../../../../forms';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// feature fleet
import {
  AccessorialsFormGroupTable,
  PayrollAccessorialsFormGroupTable,
} from '../../components/accessorials-form-group-table';
// feature fleet/driver
import * as LC from '../constants';
import DeductionSettingsForm from './deduction-settings-form';
import PayrollCompensationForm from './payroll-compensation-form';
import CompensationFormGroupTable from './compensation-form-group-table';
import MonthlyPayrollDeductionForm from './monthly-payroll-deduction-form';
import AddDriverHoursForm, { UpdateDriverHoursForm } from './add-driver-hours-form';
import { getDriverHoursInitFields, getPayrollCompensationRateInitFields } from '../settings/fields-settings';
import {
  deductionSettings,
  driverDailyHoursReport,
  payrollCompensationFields,
  payrollVariableValuesReport,
  monthlyPayrollDeductionReport,
  driverDailyHoursTableSettings,
  getDriverDailyHoursColumnSettings,
  payrollVariableValuesTableSettings,
  payrollVariableValuesColumnSettings,
  monthlyPayrollDeductionTableSettings,
  getMonthlyPayrollDeductionColumnSettings,
} from '../settings/column-settings';
import {
  makeSelectDriverDailyHours,
  makeSelectDriverAccessorials,
  makeSelectDriverCompensations,
  makeSelectDeductionSettingsList,
  makeSelectPayrollAssessorialList,
  makeSelectMonthlyPayrollDeduction,
  makeSelectPayrollCompensationRate,
} from '../selectors';
import {
  updateDriverDetailsRequest,
  createDriverDailyHoursRequest,
  updateDriverDailyHoursRequest,
  deleteDriverDailyHoursRequest,
  toggleDriverDailyHoursDetails,
  createDriverAccessorialRequest,
  updateDriverAccessorialRequest,
  deleteDriverAccessorialRequest,
  updateDeductionSettingsRequest,
  createDriverCompensationRequest,
  updateDriverCompensationRequest,
  deleteDriverCompensationRequest,
  removePayrollAssessorialRequest,
  togglePayrollAssessorialDetails,
  deletePayrollCompensationRequest,
  savePayrollCompensationRateRequest,
  deleteMonthlyPayrollDeductionRequest,
  toggleMonthlyPayrollDeductionDetails,
  createOrUpdatePayrollAssessorialRequest,
  createOrUpdateMonthlyPayrollDeductionRequest,
} from '../actions';
//////////////////////////////////////////////////

const renderPayrollCompensationTitle = ({ checked, withIcon, onChange }: Object) => (props: Object) => (
  <FormGroupTitleWrapper>
    <Flex
      pl={15}
      height={30}
      bg={G.getTheme('colors.mainBlue')}
      color={G.getTheme('forms.group.title.textColor')}
    >
      <Label
        endAction={props.handleAddClick}
        endIcon={withIcon && I.plusRound(G.getTheme('icons.iconColorWhite'))}
      >
        {G.getWindowLocale('titles:payroll-compensation-rate', 'Payroll Compensation Rate')}
      </Label>
      <ToggleWrapper
        m='0 8px'
        title={G.ifElse(
          checked,
          'Switch off to use trip pay',
          'Switch on to use payroll compensation rate', // TODO: clarify locale and add
        )}
      >
        <Toggle
          icons={false}
          checked={checked}
          onChange={onChange}
        />
      </ToggleWrapper>
    </Flex>
  </FormGroupTitleWrapper>
);

const addAssessorialInfoToEntity = (entities: Array = [], assessorialConfigs: Array) => {
  if (G.isNilOrEmpty(assessorialConfigs)) return entities;

  const indexedConfigs = R.indexBy(
    (config: Object) => G.getParentGuidOrGuidFromObject(config),
    assessorialConfigs,
  );

  return entities.map((item: Object) => {
    const assessorial = R.path([item.payrollAssessorialGuid], indexedConfigs);

    return R.mergeRight(assessorial, item);
  });
};

export const enhanceMonthlyPayrollDeduction = compose(
  withHandlers({
    handleDeleteMonthlyPayrollDeduction: (props: Object) => (entity: Object) => {
      const { openModal, deleteMonthlyPayrollDeductionRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_LOCATION_NAME, entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => deleteMonthlyPayrollDeductionRequest(entity),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleAddOrEditMonthlyPayrollDeduction: (props: Object) => (isEdit: boolean, entity: Object = {}) => {
      const {
        openModal,
        closeModal,
        defaultCurrency,
        accessorialsConfigs,
        createOrUpdateMonthlyPayrollDeductionRequest } = props;

      const requestMethod = G.ifElse(isEdit, 'put', 'post');

      const title = G.ifElse(
        isEdit,
        G.getWindowLocale('titles:edit-monthly-payroll-deduction', 'Edit Monthly Payroll Deduction'),
        G.getWindowLocale('titles:add-monthly-payroll-deduction', 'Add Monthly Payroll Deduction'),
      );

      const accessorialOptions = G.getOptionsFromConfigValueByParentGuidOrGuid(accessorialsConfigs);
      const currency = R.pathOr(defaultCurrency, [GC.FIELD_CURRENCY], entity);
      const initialValues = R.assoc([GC.FIELD_CURRENCY], currency, entity);

      const component = (
        <MonthlyPayrollDeductionForm
          editMode={isEdit}
          closeModal={closeModal}
          initialValues={initialValues}
          requestMethod={requestMethod}
          accessorialOptions={accessorialOptions}
          submitAction={createOrUpdateMonthlyPayrollDeductionRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 560,
          height: 'auto',
          btnWidth: '190px',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const enhance = compose(
  withAsyncAccessorials,
  withProps(({ branchConfigs }: Object) => {
    const defaultCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);

    return { defaultCurrency };
  }),
  enhanceMonthlyPayrollDeduction,
  withHandlers({
    handleAddPayrollCompensationRate: (props: Object) => (entity: any, savePaidByTrip: boolean = false) => {
      const {
        openModal,
        closeModal,
        formValues,
        defaultCurrency,
        payrollCompensationRate,
        savePayrollCompensationRateRequest,
      } = props;

      const initialValues = R.or(payrollCompensationRate, getPayrollCompensationRateInitFields(defaultCurrency));

      const submitAction = G.ifElse(
        savePaidByTrip,
        (data: Object) => {
          const driverData = R.mergeRight(formValues, {
            updated: true,
            [GC.FIELD_DRIVER_PAID_BY_TRIP]: R.not(R.prop(GC.FIELD_DRIVER_PAID_BY_TRIP, formValues)),
          });
          savePayrollCompensationRateRequest({ ...data, driverData });
        },
        savePayrollCompensationRateRequest,
      );

      const component = (
        <PayrollCompensationForm
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={initialValues}
        />
      );

      const modal = {
        component,
        p: '15px 0',
        options: {
          height: 'auto',
          title: G.ifElse(payrollCompensationRate, G.getEditTitle, G.getAddTitle)(
            ['titles: payroll-compensation', 'Payroll Compensation']),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleEditDeductionSettings: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, updateDeductionSettingsRequest } = props;

      const component = (
        <DeductionSettingsForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={updateDeductionSettingsRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 600,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-deduction-settings', 'Edit Deduction Settings'),
        },
      };

      openModal(modal);
    },
    handleCreateDriverDailyHours: (props: Object) => () => {
      const { openModal, closeModal, driverDailyHours, createDriverDailyHoursRequest } = props;

      const existingDates = R.map(R.prop(GC.FIELD_LOG_DATE), driverDailyHours);

      const component = (
        <AddDriverHoursForm
          closeModal={closeModal}
          existingDates={existingDates}
          initialValues={getDriverHoursInitFields()}
          submitAction={createDriverDailyHoursRequest}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 640,
          top: '100px',
          height: 'auto',
          title: G.getWindowLocale('titles:add-driver-daily-hours', 'Add Driver Daily Hours'),
        },
      };

      openModal(modal);
    },
    handleUpdateDriverDailyHours: (props: Object) => (entity: Object) => {
      const { openModal, closeModal, driverDailyHours, updateDriverDailyHoursRequest } = props;

      const existingDates = R.map(R.prop(GC.FIELD_LOG_DATE), driverDailyHours);

      const component = (
        <UpdateDriverHoursForm
          initialValues={entity}
          closeModal={closeModal}
          existingDates={existingDates}
          submitAction={updateDriverDailyHoursRequest}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 640,
          height: 'auto',
          title: G.getWindowLocale('titles:update-driver-daily-hours', 'Update Driver Daily Hours'),
        },
      };

      openModal(modal);
    },
    handleRemoveDriverDailyHours: (props: Object) => (entity: Object) => {
      const { openModal, deleteDriverDailyHoursRequest } = props;

      const component = (
        <ConfirmComponent
          textLocale={`${G.getWindowLocale(
            'messages:delete-confirmation-text-double',
            'Are you sure you want to delete',
          )} ${R.prop(GC.FIELD_LOG_DATE, entity)}?`}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteDriverDailyHoursRequest(G.getGuidFromObject(entity));
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleDeletePayrollCompensationRate: (props: Object) => () => {
      const { openModal, payrollCompensationRate, deletePayrollCompensationRequest } = props;

      const component = (
        <ConfirmComponent
          textLocale={`${G.getWindowLocale(
            'messages:delete-confirmation-text-double',
            'Are you sure you want to delete',
          )} ${G.getWindowLocale('titles:payroll-compensation-rate', 'Payroll Compensation Rate')}?`}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deletePayrollCompensationRequest(G.getGuidFromObject(payrollCompensationRate));
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleUseTripPayOrPayrollCompensation: (props: Object) => () => {
      const {
        formValues,
        payrollCompensationRate,
        updateDriverDetailsRequest,
        handleAddPayrollCompensationRate,
      } = props;

      if (R.and(R.isNil(payrollCompensationRate), R.prop(GC.FIELD_DRIVER_PAID_BY_TRIP, formValues))) {
        return handleAddPayrollCompensationRate(null, true);
      }

      updateDriverDetailsRequest(R.mergeRight(formValues, {
        updated: true,
        [GC.FIELD_DRIVER_PAID_BY_TRIP]: R.not(R.prop(GC.FIELD_DRIVER_PAID_BY_TRIP, formValues)),
      }));
    },
  }),
  pure,
);

const PayrollCompensationRateRows = ({ list }: Object) => (
  <Box ml={25}>
    <Table
      itemList={list}
      report={payrollVariableValuesReport}
      tableSettings={payrollVariableValuesTableSettings}
      columnSettings={payrollVariableValuesColumnSettings}
    />
  </Box>
);

const CompensationTabComponent = (props: Object) => {
  const {
    initialValues,
    collapsedGroup,
    defaultCurrency,
    driverDailyHours,
    accessorialsConfigs,
    deductionSettingsList,
    monthlyPayrollDeduction,
    payrollCompensationRate,
    handleEditDeductionSettings,
    handleCreateDriverDailyHours,
    handleUpdateDriverDailyHours,
    handleRemoveDriverDailyHours,
    toggleDriverDailyHoursDetails,
    updateDriverAccessorialRequest,
    deleteDriverAccessorialRequest,
    createDriverAccessorialRequest,
    updateDriverCompensationRequest,
    createDriverCompensationRequest,
    deleteDriverCompensationRequest,
    removePayrollAssessorialRequest,
    togglePayrollAssessorialDetails,
    handleAddPayrollCompensationRate,
    handleDeleteMonthlyPayrollDeduction,
    handleDeletePayrollCompensationRate,
    toggleMonthlyPayrollDeductionDetails,
    handleUseTripPayOrPayrollCompensation,
    handleAddOrEditMonthlyPayrollDeduction,
    createOrUpdatePayrollAssessorialRequest,
  } = props;

  const usedPayrollCompensation = R.not(R.prop(GC.FIELD_DRIVER_PAID_BY_TRIP, initialValues));
  const showPayrollCompensationRateRows = R.propEq(GC.CHARGE_RATE_TYPE_TIME, 'rateType', payrollCompensationRate);

  return (
    <div>
      <FormGroupTable
        notDeletable={true}
        isHiddenAddIcon={true}
        withoutArrowDown={true}
        fields={collapsedGroup}
        entitiesFields={deductionSettings}
        entities={R.of(Array, deductionSettingsList)}
        handleEditRow={handleEditDeductionSettings}
        groupName={LC.DRIVER_GROUP_NAME_DEDUCTION_SETTINGS}
        panelTitle={G.getWindowLocale('titles:deduction-settings', 'Deduction Settings')}
      />
      <FormGroupTable
        entities={null}
        groupName={LC.DRIVER_GROUP_NAME_MONTHLY_PAYROLL_DEDUCTION}
        handleAddClick={() => handleAddOrEditMonthlyPayrollDeduction(false)}
        panelTitle={G.getWindowLocale('titles:monthly-payroll-deduction', 'Monthly Payroll Deduction')}
      />
      {
        G.isNotNilAndNotEmpty(monthlyPayrollDeduction) &&
        <Table
          report={monthlyPayrollDeductionReport}
          toggle={toggleMonthlyPayrollDeductionDetails}
          itemList={addAssessorialInfoToEntity(monthlyPayrollDeduction, accessorialsConfigs)}
          tableSettings={monthlyPayrollDeductionTableSettings}
          columnSettings={getMonthlyPayrollDeductionColumnSettings({
            handleDelete: handleDeleteMonthlyPayrollDeduction,
            handleEdit: handleAddOrEditMonthlyPayrollDeduction,
            deletePermission: PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
          })}
        />
      }
      <PayrollAccessorialsFormGroupTable
        {...props}
        objKey={GC.FIELD_DRIVER_GUID}
        defaultCurrency={defaultCurrency}
        objGuid={G.getGuidFromObject(initialValues)}
        removeAction={removePayrollAssessorialRequest}
        handleToggleDetails={togglePayrollAssessorialDetails}
        createOrUpdateAction={createOrUpdatePayrollAssessorialRequest}
      />
      <AccessorialsFormGroupTable
        {...props}
        objKey={GC.FIELD_DRIVER_GUID}
        defaultCurrency={defaultCurrency}
        objGuid={G.getGuidFromObject(initialValues)}
        updateAction={updateDriverAccessorialRequest}
        createAction={createDriverAccessorialRequest}
        removeAction={deleteDriverAccessorialRequest}
      />
      <CompensationFormGroupTable
        {...props}
        compensationAccessorials={false}
        defaultCurrency={defaultCurrency}
        reportType={GC.DRIVER_COMPENSATION_REPORT}
        createCompensationAction={createDriverCompensationRequest}
        updateCompensationAction={updateDriverCompensationRequest}
        removeCompensationAction={deleteDriverCompensationRequest}
      />
      <FormGroupTable
        overflow='auto'
        fields={collapsedGroup}
        notDeletable={usedPayrollCompensation}
        entitiesFields={payrollCompensationFields}
        handleEditRow={handleAddPayrollCompensationRate}
        handleAddClick={handleAddPayrollCompensationRate}
        groupName={LC.DRIVER_GROUP_NAME_PAYROLL_COMPENSATION}
        handleDeleteRow={handleDeletePayrollCompensationRate}
        entities={G.ifElse(R.isNil(payrollCompensationRate), [], R.of(Array, payrollCompensationRate))}
        customPanel={renderPayrollCompensationTitle({
          checked: usedPayrollCompensation,
          withIcon: R.isNil(payrollCompensationRate),
          onChange: handleUseTripPayOrPayrollCompensation,
        })}
      />
      {
        showPayrollCompensationRateRows &&
        <PayrollCompensationRateRows
          list={R.prop(GC.FIELD_PAYROLL_VARIABLE_VALUES, payrollCompensationRate)}
        />
      }
      <FormGroupTitlePanel
        fields={collapsedGroup}
        groupName={LC.DRIVER_DAILY_HOURS}
        handleAddClick={handleCreateDriverDailyHours}
        panelTitle={G.getWindowLocale('titles:driver-daily-hours', 'Driver Daily Hours')}
      />
      <Table
        itemList={driverDailyHours}
        report={driverDailyHoursReport}
        toggle={toggleDriverDailyHoursDetails}
        tableSettings={driverDailyHoursTableSettings}
        driverDailyHoursTableSettings={driverDailyHoursTableSettings}
        columnSettings={getDriverDailyHoursColumnSettings({
          handleEdit: handleUpdateDriverDailyHours,
          handleDelete: handleRemoveDriverDailyHours,
        })}
      />
    </div>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  accessorials: makeSelectDriverAccessorials(state),
  compensations: makeSelectDriverCompensations(state),
  driverDailyHours: makeSelectDriverDailyHours(state),
  deductionSettingsList: makeSelectDeductionSettingsList(state),
  payrollAssessorialList: makeSelectPayrollAssessorialList(state),
  payrollCompensationRate: makeSelectPayrollCompensationRate(state),
  monthlyPayrollDeduction: makeSelectMonthlyPayrollDeduction(state),
}));

export default connect(mapStateToProps, {
  setQuickFilterParams,
  updateDriverDetailsRequest,
  getQuickFilteredListRequest,
  createDriverDailyHoursRequest,
  updateDriverDailyHoursRequest,
  toggleDriverDailyHoursDetails,
  deleteDriverDailyHoursRequest,
  createDriverAccessorialRequest,
  updateDriverAccessorialRequest,
  deleteDriverAccessorialRequest,
  updateDeductionSettingsRequest,
  createDriverCompensationRequest,
  updateDriverCompensationRequest,
  deleteDriverCompensationRequest,
  removePayrollAssessorialRequest,
  togglePayrollAssessorialDetails,
  deletePayrollCompensationRequest,
  savePayrollCompensationRateRequest,
  deleteMonthlyPayrollDeductionRequest,
  toggleMonthlyPayrollDeductionDetails,
  createOrUpdatePayrollAssessorialRequest,
  createOrUpdateMonthlyPayrollDeductionRequest,
})(enhance(CompensationTabComponent));
