import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature drivers-card
import { createDriverOnDriversCardRequest } from './actions';
/////////////////////////////////////////////////

// REFACTOR: create common logic with TEL details, route builder and dispatch board logic

export const isInvalidTelCarrierRate = (values: Object) => {
  const { carrierRateCharges } = values;

  const carrierMainChargeTotal = G.getChargeTotalFromCharges(
    carrierRateCharges,
    GC.CHARGE_TYPE_MAIN,
  );

  if (G.isNilOrZero(carrierMainChargeTotal)) {
    G.showToastrMessageSimple(
      'info',
      G.getWindowLocale('messages:provide-main-change-for-carrier', 'Please provide Main Charge'),
    );

    return true;
  }

  return false;
};

export const handleCreateTelRateOnDriversCardRequest = createLogic({
  type: createDriverOnDriversCardRequest,
  validate({ getState, action }: Object, allow: void, reject: void) {
    const values = action.payload;
    const { secondaryDriverGuid } = values;

    if (G.isInvalidDriverRate(values)) {
      return reject();
    }

    if (R.isNil(secondaryDriverGuid)) {
      return allow(createDriverOnDriversCardRequest(R.omit('secondaryDriverCharges', values)));
    }

    allow(action);
  },
});

export default [
  handleCreateTelRateOnDriversCardRequest,
];

