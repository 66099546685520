import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { EditReport } from '../../components/edit-report';
import { TitlePanel } from '../../components/title-panel';
import { PageActions } from '../../components/page-actions';
import { ConfirmComponent } from '../../components/confirm';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { Box, PageWrapper, ZOrderWrapper } from '../../ui';
// feature fuel cards
import { RowActions } from './components/row-actions';
import { FILTER_PARAMS } from './settings/filter-params';
import { getTruckOptions, getDriverOptions } from './helpers';
import { tableSettings } from './components/fuel-card-details';
import { report, columnSettings } from './settings/column-settings';
import EditFuelCardForm, { CreateFuelCardForm } from './components/edit-fuel-card-form';
import {
  makeSelectItemList,
  makeSelectTruckList,
  makeSelectTruckGuid,
  makeSelectDriverGuid,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectDriverList,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectDriverPayrollNumberList,
  makeSelectVendorPayrollNumberList,
} from './selectors';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  toggleFuelCardDetails,
  createFuelCardRequest,
  updateFuelCardRequest,
  resetListAndPagination,
  deleteFuelCardsRequest,
  exportReportDataRequest,
  changeDefaultReportRequest,
  updateDriverFuelCardRequest,
} from './actions';
//////////////////////////////////////////////////

const addFuelTransactionMapStateToProps = (state: Object) => createStructuredSelector({
  truckGuid: makeSelectTruckGuid(state),
  truckList: makeSelectTruckList(state),
  driverGuid: makeSelectDriverGuid(state),
  driverList: makeSelectDriverList(state),
});

export const withAddFuelTransaction = compose(
  connect(
    addFuelTransactionMapStateToProps,
    {
      openModal,
      closeModal,
      openLoader,
      closeLoader,
      createFuelCardRequest,
    },
  ),
  withHandlers({
    handleAddFuelCard: (props: Object) => () => {
      const {
        openModal,
        truckGuid,
        driverGuid,
        closeModal,
        openLoader,
        closeLoader,
        closeFixedPopup,
        assignedTruckGuid,
        assignedDriverGuid,
        defaultBranchCurrency,
        createFuelCardRequest,
      } = props;

      const optionsForSelect = {
        truckOptions: getTruckOptions(props),
        driverOptions: getDriverOptions(props),
      };

      const component = (
        <CreateFuelCardForm
          isCreate={true}
          truckGuid={truckGuid}
          driverGuid={driverGuid}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          optionsForSelect={optionsForSelect}
          submitAction={createFuelCardRequest}
          assignedTruckGuid={assignedTruckGuid}
          assignedDriverGuid={assignedDriverGuid}
          defaultBranchCurrency={defaultBranchCurrency}
          titleText={G.getAddTitle(['titles:fuel-transaction', 'Fuel Transaction'])}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
        },
      };

      G.callFunction(closeFixedPopup);

      openModal(modal);
    },
  }),
);

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withAddFuelTransaction,
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          disablePrompt={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest({ openLoader: true })}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleEditFuelCard: (props: Object) => (guid: string) => {
      const {
        itemList,
        openModal,
        truckGuid,
        closeModal,
        driverList,
        driverGuid,
        openLoader,
        closeLoader,
        closeFixedPopup,
        updateFuelCardRequest,
      } = props;

      const fuelCard = G.getPropFromObject(guid, itemList);
      const fuelCardDriverGuid = G.getPropFromObject(GC.FIELD_DRIVER_GUID, fuelCard);

      let title = G.getEditTitle(['titles:fuel-transaction', 'Fuel Transaction']);

      if (R.and(G.isNotNilAndNotEmpty(fuelCardDriverGuid), G.isNotNilAndNotEmpty(driverList))) {
        title = `${title} - ${
          R.pathOr('', [fuelCardDriverGuid, GC.FIELD_FIRST_NAME], driverList)
        } ${
          R.pathOr('', [fuelCardDriverGuid, GC.FIELD_LAST_NAME], driverList)
        } (${R.pathOr('', [fuelCardDriverGuid, GC.FIELD_USER_LOGIN_ID], driverList)})`;
      }

      const optionsForSelect = {
        truckOptions: getTruckOptions(props),
        driverOptions: getDriverOptions(props),
      };

      const component = (
        <EditFuelCardForm
          titleText={title}
          fuelCardGuid={guid}
          truckGuid={truckGuid}
          driverGuid={driverGuid}
          openLoader={openLoader}
          closeModal={closeModal}
          closeLoader={closeLoader}
          optionsForSelect={optionsForSelect}
          submitAction={updateFuelCardRequest}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
        },
      };

      closeFixedPopup();
      openModal(modal);
    },
    handleDeleteFuelCard: (props: Object) => (guid: string, fuelCard: Object) => {
      const {
        openModal,
        driverList,
        closeFixedPopup,
        deleteFuelCardsRequest,
      } = props;

      const fuelCardDriverGuid = G.getPropFromObject(GC.FIELD_DRIVER_GUID, fuelCard);

      const name = `${G.getWindowLocale('titles:fuel-card', 'Fuel Card')}: ${
        R.pathOr('', [fuelCardDriverGuid, GC.FIELD_FIRST_NAME], driverList)} ${
        R.pathOr('', [fuelCardDriverGuid, GC.FIELD_LAST_NAME], driverList)} (${
        R.pathOr('', [fuelCardDriverGuid, GC.FIELD_USER_LOGIN_ID], driverList)})
      `;

      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-text-double',
        'Are you sure you want to delete',
      );

      const component = (
        <ConfirmComponent
          name={name}
          textLocale={textLocale}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => deleteFuelCardsRequest(R.of(Array, guid)),
            },
          ],
        },
      };

      closeFixedPopup();
      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => ({ currentTarget }: Object, data: Object) => (
      props.openFixedPopup({
        zIndex: 2,
        position: 'right',
        el: currentTarget,
        content: (
          <RowActions
            openModal={props.openModal}
            truckList={props.truckList}
            truckGuid={props.truckGuid}
            driverList={props.driverList}
            openLoader={props.openLoader}
            closeModal={props.closeModal}
            driverGuid={props.driverGuid}
            truckUnitId={props.truckUnitId}
            closeLoader={props.closeLoader}
            guid={G.getGuidFromObject(data)}
            driverLastName={props.driverLastName}
            driverFirstName={props.driverFirstName}
            closeFixedPopup={props.closeFixedPopup}
            currentBranchGuid={props.currentBranchGuid}
            fuelCardTruckGuid={R.prop(GC.FIELD_TRUCK_GUID, data)}
            fuelCardDriverGuid={R.prop(GC.FIELD_DRIVER_GUID, data)}
            updateDriverFuelCardRequest={props.updateDriverFuelCardRequest}
            fuelCard={R.path(['itemList', G.getGuidFromObject(data)], props)}
          />
        ),
      })
    ),
  }),
  branch(
    ({ selectedReport }: Object) => G.isNilOrEmpty(selectedReport),
    renderNothing,
  ),
  pure,
);

const renderElementActions = (data: Object, handleClickEditIcon: Function) => {
  const condition = R.or(
    G.isNotNil(R.prop(GC.FIELD_PAYROLL_GUID, data)),
    G.isNotNil(R.prop(GC.FIELD_VENDOR_PAYROLL_GUID, data)),
  );

  if (condition) return null;

  return (
    <AuthWrapper has={[PC.FLEET_FUEL_CARD_WRITE]}>
      <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </Box>
    </AuthWrapper>
  );
};

const getFuelCardListData = ({
  itemList,
  driverPayrollNumberList,
  vendorPayrollNumberList,
}: Object) => R.compose(
  R.map((item: Object) => {
    const payrollGuid = R.path([R.prop(GC.FIELD_FUEL_CARDS_PAYROLL_GUID, item)], driverPayrollNumberList);
    const vendorPayrollGuid = R.path([R.prop(GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID, item)], vendorPayrollNumberList);


    return {
      ...item,
      payrollGuid,
      vendorPayrollGuid,
      disabled: R.or(
        G.isNotNilAndNotEmpty(payrollGuid),
        G.isNotNilAndNotEmpty(vendorPayrollGuid),
      ),
    };
  }),
  R.values,
)(itemList);

const FuelCardListComponent = (props: Object) => {
  const {
    pl,
    title,
    height,
    loading,
    minHeight,
    maxHeight,
    totalCount,
    pagination,
    openLoader,
    reportList,
    closeLoader,
    filterParams,
    selectedReport,
    tableMaxHeight,
    titleSortValues,
    pageActionsBottom,
    handleAddFuelCard,
    tableTitleFilters,
    withoutPageActions,
    handleEditFuelCard,
    getItemListRequest,
    handleClickEditIcon,
    handleDeleteFuelCard,
    toggleFuelCardDetails,
    openedFromFleetProfile,
    handleTableTitleFilter,
  } = props;

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEditFuelCard,
      permissions: [PC.FLEET_FUEL_CARD_WRITE],
    },
    {
      iconName: 'trash',
      action: handleDeleteFuelCard,
      permissions: [PC.FLEET_FUEL_CARD_WRITE],
    },
  ];

  const tableSettingsToUse = G.isTrue(openedFromFleetProfile)
    ? R.assoc('maxHeight', tableMaxHeight, tableSettings)
    : G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    });

  const reportToUse = R.assoc('fields', R.prop('fields', report), selectedReport);

  return (
    <PageWrapper
      pt={15}
      pr={15}
      pl={pl}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
    >
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          pt='0'
          withCount={true}
          popperWithCount={true}
          totalCount={totalCount}
          type={GC.FUEL_CARD_REPORT}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          getItemListRequest={() => getItemListRequest({ openLoader: true })}
          title={R.or(title, G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'))}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <Table
          loading={loading}
          report={selectedReport}
          totalCount={totalCount}
          pagination={pagination}
          useSearchableColumns={true}
          withResizableColumns={true}
          actionButtons={actionButtons}
          useNewTitleFilterInputs={true}
          toggle={toggleFuelCardDetails}
          columnSettings={columnSettings}
          titleSortValues={titleSortValues}
          tableSettings={tableSettingsToUse}
          itemList={getFuelCardListData(props)}
          tableTitleFilters={tableTitleFilters}
          callbackData={{ openLoader, closeLoader }}
          handleLoadMoreEntities={getItemListRequest}
          handleTableTitleFilter={handleTableTitleFilter}
          renderRightStickedComponent={(data: Object) => renderElementActions(data, handleClickEditIcon)}
          filterProps={R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS))}
        />
      </ZOrderWrapper>
      {
        R.not(withoutPageActions) &&
        <PageActions
          bottom={pageActionsBottom}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            action: handleAddFuelCard,
            text: G.getWindowLocale('actions:add-fuel-transaction', 'Add Fuel Transaction'),
          }}
        />
      }
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  pagination: makeSelectPagination(state),
  totalCount: makeSelectTotalCount(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  reportList: makeSelectAvailableReports(state),
  requestPending: makeSelectReportStatus(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  driverPayrollNumberList: makeSelectDriverPayrollNumberList(state),
  vendorPayrollNumberList: makeSelectVendorPayrollNumberList(state),
}));

export default connect(mapStateToProps, {
  openModal,
  setReports,
  closeModal,
  openLoader,
  closeLoader,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  updateFuelCardRequest,
  toggleFuelCardDetails,
  createFuelCardRequest,
  resetListAndPagination,
  deleteFuelCardsRequest,
  exportReportDataRequest,
  changeDefaultReportRequest,
  updateDriverFuelCardRequest,
})(enhance(FuelCardListComponent));
