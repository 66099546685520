import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
// features
import { AuthWrapper } from '../../permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, IconWrapper, RelativeFlex } from '../../../ui';
// feature dispatch-details-new
import * as C from '../constants';
import ToggleComponent from './toggle-component';
/////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');
const greyColor = G.getTheme('colors.greyMatterhorn');
const darkBlueColor = G.getTheme('colors.dark.darkBlue');
const boxShadowGrey = G.getTheme('colors.boxShadowGrey');

const boxShadow = `0 2px 5px 0 rgba ${boxShadowGrey}`;

const TableGroup = (props: Object) => {
  const {
    tabs,
    tableData,
    expandedList,
    activeListName,
    handleClickEditIcon,
    handleAddTableEntity,
    handleChangeActiveList,
    handleToggleExpandedList,
    handleOpenStatusMessagesMap,
  } = props;

  const {
    HeadComponent,
    rowActionsPermissions,
    notHasRowActionsPermissions,
  } = tableData;

  const tableDataWithHeight = R.assocPath(
    ['tableSettings', 'maxHeight'],
    G.ifElse(expandedList, 'calc(100vh - 200px)', '200px'),
    tableData,
  );

  const renderRowActions = (data: Object) => {
    const { authorUserGuid, withoutRowActions } = data;

    let editAllowed = true;

    if (G.isNotNil(authorUserGuid)) {
      editAllowed = G.isCurrentUserMessage(data);
    }

    if (R.or(R.not(editAllowed), withoutRowActions)) return null;

    return (
      <AuthWrapper has={rowActionsPermissions} notHas={notHasRowActionsPermissions}>
        <IconWrapper
          px={12}
          cursor='pointer'
          onClick={(event: Object) => handleClickEditIcon(event, data)}
        >
          {I.threeDots()}
        </IconWrapper>
      </AuthWrapper>
    );
  };

  return (
    <Box>
      <Flex overflow='auto'>
        {
          tabs.map((item: Object, index: number) => {
            const {
              label,
              value,
              permissions,
              withAddition,
              addPermissions,
              notHasPermissions,
            } = item;

            const activeItem = R.equals(value, activeListName);

            return (
              <AuthWrapper key={index} has={permissions} notHas={notHasPermissions}>
                <Flex
                  p='8px'
                  mr='5px'
                  bg={whiteColor}
                  cursor='pointer'
                  fontWeight='bold'
                  alignItems='center'
                  width='max-content'
                  boxShadow={boxShadow}
                  borderTopLeftRadius='8px'
                  borderTopRightRadius='8px'
                  color={G.ifElse(activeItem, blueColor, greyColor)}
                  onClick={() => handleChangeActiveList(value, activeItem)}
                >
                  {
                    R.and(activeItem, R.equals(activeListName, C.ACTIVE_LIST_STATUS_MESSAGES)) &&
                    <IconWrapper mr='4px' onClick={handleOpenStatusMessagesMap}>
                      {I.onMap(blueColor)}
                    </IconWrapper>
                  }
                  {G.getWindowLocale(...label)}
                  {
                    R.and(activeItem, withAddition) &&
                    <AuthWrapper has={addPermissions}>
                      <Box
                        ml='4px'
                        p='3px 4px'
                        fontSize={11}
                        borderRadius='4px'
                        height='fit-content'
                        color={darkBlueColor}
                        border={`1px solid ${darkBlueColor}`}
                        onClick={(event: Object) => handleAddTableEntity(event)}
                      >
                        {G.getWindowLocale('actions:add', 'Add')}
                      </Box>
                    </AuthWrapper>
                  }
                </Flex>
              </AuthWrapper>
            );
          })
        }
      </Flex>
      <RelativeFlex
        zIndex={0}
        minHeight={200}
        borderRadius='4px'
        alignItems='stretch'
        boxShadow={boxShadow}
        flexDirection='column'
        borderTopLeftRadius='0'
        background={whiteColor}
        justifyContent='space-between'
      >
        {
          G.isNotNil(HeadComponent) &&
          <HeadComponent />
        }
        <Table
          {...tableDataWithHeight}
          tableWrapperOverflow='auto'
          renderRightStickedComponent={renderRowActions}
        />
        <ToggleComponent
          opened={expandedList}
          toggle={handleToggleExpandedList}
          closedText={G.getWindowLocale('actions:expand', 'Expand')}
          openedText={G.getWindowLocale('actions:collapse', 'Collapse')}
        />
      </RelativeFlex>
    </Box>
  );
};

export default TableGroup;
