import React from 'react';
import * as R from 'ramda';
// features
import SectionDivider from '../../../../new-do/components/section-divider';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box, Flex } from '../../../../../ui';
// feature configs
import { Button } from '../../../ui';
import { referencesField } from '../settings';
import { copyToClipboard } from './interpolation-variables-section';
//////////////////////////////////////////////////

export const ReferenceVariablesSection = (props: Object) => {
  const {
    templateNameKey,
    cloReferenceTypes,
    telReferenceTypes,
    referenceVariables,
    handleCustomChange,
  } = props;

  const references = G.ifElse(R.includes(GC.FIELD_CLO, templateNameKey), cloReferenceTypes, telReferenceTypes);

  return (
    <Box>
      <SectionDivider
        mt='0px'
        text={G.getWindowLocale('titles:references', 'References', { caseAction: 'upperCase' })}
      />
      <Flex>
        <Fieldset2
          {...G.getFormikProps(props)}
          references={references}
          fields={referencesField}
          fieldsWrapperStyles={{ p: '0 15px'}}
          handleCustomChange={handleCustomChange}
        />
        {
          G.isNotNilAndNotEmpty(referenceVariables) &&
          <Flex flexWrap='wrap'>
            {
              referenceVariables.map((variable: string, i: number) => (
                <Button key={i} type='button' onClick={() => copyToClipboard(variable)}>
                  {variable}
                </Button>
              ))
            }
          </Flex>
        }
      </Flex>
    </Box>
  );
};
