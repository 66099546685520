import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../../components/actions-element';
import { withOpenTelRateRequestList } from '../../../../components/tel-rate-request-list';
// features
import PC from '../../../permission/role-permission';
import ChangeEntityBranch from '../../../branch/components/change-entity-branch';
// forms
import { PostTelToLoadboardsWithAsyncTELData } from '../../../../forms/forms/post-tel-to-loadboard-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// utilities
import routesMap from '../../../../utilities/routes';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withOpenTelRateRequestList,
  withHandlers({
    handleGoToDetails: (props: Object) => () => {
      const { entity, closeFixedPopup } = props;

      closeFixedPopup();
      G.goToLoadDetailsByConfigAndLoadType(routesMap, G.getGuidFromObject(entity));
    },
    handlePostTelToLoadBoard: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        closeFixedPopup,
      } = props;

      const modal = {
        p: 15,
        component: (
          <PostTelToLoadboardsWithAsyncTELData
            width={500}
            cancelAction={closeModal}
            guids={R.of(Array, entity.guid)}
          />
        ),
        options: {
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
        },
      };

      closeFixedPopup();

      openModal(modal);
    },
    handleChangeBranch: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeFixedPopup,
        getItemListRequest,
        resetListAndPagination,
      } = props;

      const callback = () => {
        resetListAndPagination();
        getItemListRequest(true);
      };

      const component = (
        <ChangeEntityBranch
          guid={entity.guid}
          callback={callback}
          type={GC.LOAD_TYPE_TEL}
        />
      );

      const modal = {
        p: '0 8px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getWindowLocale('titles:change-branch', 'Change Branch'),
        },
      };

      closeFixedPopup();

      openModal(modal);
    },
    handleSendTelToMacroPoint: ({ entity, closeFixedPopup, sendTelToMacroPointRequest }: Object) => () => {
      closeFixedPopup();
      sendTelToMacroPointRequest({ guids: R.of(Array, G.getGuidFromObject(entity)) });
    },
  }),
  pure,
);

const getOptions = ({
  entity,
  closeFixedPopup,
  handleGoToDetails,
  handleChangeBranch,
  handlePostTelToLoadBoard,
  handleSendTelToMacroPoint,
  handleOpenTelRateRequestList,
}: Object) => ([
  {
    action: handleGoToDetails,
    frontIcon: I.routesLoads(iconColor),
    permissions: [PC.TEL_READ, PC.TEL_WRITE],
    text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
  },
  {
    action: handlePostTelToLoadBoard,
    permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
    frontIcon: I.createTruckIcon(iconColor, 20, 20),
    text: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
  },
  {
    permissions: [PC.TEL_WRITE],
    action: handleSendTelToMacroPoint,
    frontIcon: I.callCheck(iconColor, 20, 20),
    text: G.getWindowLocale('actions:activate-macro-point', 'Activate MacroPoint'),
  },
  {
    action: handleChangeBranch,
    frontIcon: I.company(iconColor),
    permissions: [PC.TEL_ENTERPRISE_WRITE],
    text: G.getWindowLocale('titles:change-branch', 'Change Branch'),
  },
  {
    frontIcon: I.gearSolid(iconColor),
    permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
    text: G.getWindowLocale('titles:carriers-bids', 'Carriers Bids'),
    action: () => {
      closeFixedPopup();
      handleOpenTelRateRequestList(G.getGuidFromObject(entity));
    },
  },
]);

export const RowActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(RowActions);
