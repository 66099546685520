import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { RelativeBox } from '../../../ui';
// component
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
// report-common
import { generateDefaultReport } from '../../../report-common';
// feature dispatch-planner-events
import CustomTitle from './custom-title';
import { pageSizes } from '../settings/page-settings';
import { filterProps } from '../settings/clo-events-table-settings';
import {
  setCloEventsReports,
  setGlobalFilterValue,
  setCurrentCloEventReport,
  cleanCloEventQuickFilter,
  createCloEventsReportRequest,
  updateCloEventsReportRequest,
  setCloEventQuickFilterParams,
  getCloEventsListForMapRequest,
  changeDefaultCloEventReportRequest,
} from '../actions';
import {
  makeSelectCloEventReport,
  makeSelectGlobalFilterValue,
  makeSelectCloEventFilterParams,
  makeSelectCloEventListForMapTotal,
  makeSelectAvailableCloEventReports,
  makeSelectCloEventListForMapLoading,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleSetGlobalFilter: (props: Object) => (value: string) => {
      const { globalFilterValue, setGlobalFilterValue, getCloEventsListForMapRequest } = props;

      if (R.equals(globalFilterValue, value)) return;

      setGlobalFilterValue(value);
      getCloEventsListForMapRequest();
    },
    handleListRequest: ({ getCloEventsListForMapRequest }: Object) => () =>
    getCloEventsListForMapRequest(),
    getQuickFilteredListRequest: (props: Object) => () => {
      const { getCloEventsListForMapRequest } = props;

      getCloEventsListForMapRequest();
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        createCloEventsReportRequest,
        updateCloEventsReportRequest,
        getCloEventsListForMapRequest,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.CLO_EVENT_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          setReport={setReport}
          requestPending={requestPending}
          onReportSet={getCloEventsListForMapRequest}
          createReportRequest={createCloEventsReportRequest}
          updateReportRequest={updateCloEventsReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleCleanFilter: ({ cleanCloEventQuickFilter, getCloEventsListForMapRequest }: Object) => () => {
      cleanCloEventQuickFilter();
      getCloEventsListForMapRequest();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { setReport, reportList, getCloEventsListForMapRequest } = props;

      const selectedReport = R.find(
        R.propEq(reportGuid, GC.FIELD_GUID),
        reportList,
      );

      setReport(selectedReport);
      getCloEventsListForMapRequest();
    },
  }),
  pure,
);

const CloEventsMapTitle = enhance((props: Object) => {
  const {
    totalCount,
    selectedReport,
    globalFilterValue,
    setCloEventsReports,
    handleSetGlobalFilter,
  } = props;

  return (
    <RelativeBox zIndex={21}>
      <TitlePanel
        {...props}
        pt='0px'
        version={2}
        noExportable={true}
        hideFilterInfo={true}
        useExactFilters={true}
        name='clo-event-report'
        filterProps={filterProps}
        withoutQuickFilter={true}
        type='map-clo-event-report'
        usedReport={selectedReport}
        hiddenRightFilterInfo={false}
        setReports={setCloEventsReports}
        height={pageSizes.tableHeaderHeight}
        customTitleComponent={
          <CustomTitle
            count={totalCount}
            globalFilterValue={globalFilterValue}
            handleSetGlobalFilter={handleSetGlobalFilter}
            text={G.getWindowLocale('titles:clo-events', 'CLO Events')}
          />
        }
      />
    </RelativeBox>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  selectedReport: makeSelectCloEventReport(state),
  filterParams: makeSelectCloEventFilterParams(state),
  loading: makeSelectCloEventListForMapLoading(state),
  totalCount: makeSelectCloEventListForMapTotal(state),
  reportList: makeSelectAvailableCloEventReports(state),
  globalFilterValue: makeSelectGlobalFilterValue(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setCloEventsReports,
  setGlobalFilterValue,
  cleanCloEventQuickFilter,
  createCloEventsReportRequest,
  updateCloEventsReportRequest,
  getCloEventsListForMapRequest,
  setReport: setCurrentCloEventReport,
  setUsedReport: setCurrentCloEventReport,
  getItemListRequest: getCloEventsListForMapRequest,
  setQuickFilterParams: setCloEventQuickFilterParams,
  changeDefaultReportRequest: changeDefaultCloEventReportRequest,
})(CloEventsMapTitle);
