import * as R from 'ramda';
import React from 'react';
import { withState } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner
import * as H from '../helpers';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');
const boxBG = G.getTheme('colors.light.mainLight');
const boxBorderColor = G.getTheme('colors.light.darkGrey');

const renderWeight = (item: Object) => G.ifElse(
  G.isNotNil(item[GC.FIELD_ITEM_WEIGHT]),
  `${item[GC.FIELD_ITEM_WEIGHT]} ${item[GC.FIELD_ITEM_WEIGHT_TYPE]}`,
  '',
);

const renderQuantity = (item: Object) => G.ifElse(
  R.and(G.isNotNil(item[GC.FIELD_ITEM_QUANTITY]), G.isNotNil(item[GC.FIELD_PACKAGE_TYPE])),
  `, ${item[GC.FIELD_ITEM_QUANTITY]} ${item[GC.FIELD_PACKAGE_TYPE]}`,
  '',
);

const Item = ({ tel, item, isCloStop }: Object, i: number) => {
  const isCloItem = G.isLoadTypeClo(item);

  let loadName;

  if (isCloItem) {
    loadName = R.path(['cloPrimaryReferenceValues', G.getPropFromObject(GC.FIELD_LOAD_GUID, item)], tel);
  } else {
    loadName = H.getLoadName(tel);
  }

  const isVehicle = G.isItemTypeVehicle(item);

  const vinShort = R.compose(
    R.takeLast(6),
    R.pathOr('', [GC.FIELD_VIN]),
  )(item);

  let itemInfo = G.ifElse(
    isVehicle,
    `${vinShort}, ${G.filterAndJoinObjectFields(
      item,
      [
        GC.FIELD_YEAR,
        GC.FIELD_MAKE,
        GC.FIELD_MODEL,
      ],
    )}`,
    R.prop(GC.FIELD_ITEM_ID, item),
  );

  let title = itemInfo;

  if (isVehicle) {
    title = G.filterAndJoinObjectFields(
      item,
      [
        GC.FIELD_VIN,
        GC.FIELD_YEAR,
        GC.FIELD_MAKE,
        GC.FIELD_MODEL,
        GC.FIELD_COLOR,
      ],
    );
  }

  if (R.not(isCloStop)) {
    itemInfo = `${itemInfo} (${loadName})`;
    title = `${title} (${loadName})`;
  }

  return (
    <Box
      p='5px'
      key={i}
      bg={boxBG}
      width='100%'
      display='flex'
      borderBottom='1px solid'
      borderColor={boxBorderColor}
    >
      <TextComponent
        mr='6px'
        width='auto'
        fontSize={12}
        title={title}
        display='block'
        overflow='hidden'
        color={blueColor}
        withEllipsis={true}
      >
        {itemInfo}
      </TextComponent>
      <Flex ml='auto' whiteSpace='nowrap' fontSize={12} justifyContent='space-between'>
        <Box width='100%' wordBreak='break-all'>
          {`${renderWeight(item)} ${renderQuantity(item)}`}
        </Box>
      </Flex>
    </Box>
  );
};

const ItemsInfo = (props: Object) => {
  const { items } = props;

  const totalWeight = G.calcItemsTotalWeightWithoutQty(items);
  const weightText = `${R.pathOr('-', [GC.FIELD_ITEM_WEIGHT], totalWeight)} ${
    R.pathOr('', [GC.FIELD_ITEM_WEIGHT_TYPE], totalWeight)}`;

  return (
    <Flex>
      <Box mx='4px'>{R.length(items)} {G.getWindowLocale('titles:items', 'Items')}:</Box>
      <Box fontWeight='bold'>{weightText}</Box>
    </Flex>
  );
};

const StopItems = withState('expanded', 'setExpanded', false)((props: StopInfoPropsType) => {
  const { tel, stop, expanded, isCloStop, setExpanded } = props;

  const items = R.pathOr([], [GC.FIELD_LOAD_ITEMS], stop);
  const iconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  const expandButtonText = G.getWindowLocale(...G.ifElse(
    expanded,
    ['actions:hide', 'Hide'],
    ['actions:show', 'Show'],
  ));

  return (
    <Box
      width='100%'
      borderRadius={5}
      overflow='hidden'
      bg={G.getTheme('colors.light.lightGrey')}
    >
      <Flex px='8px' height={24} fontSize={12} justifyContent='space-between'>
        <ItemsInfo items={items} />
        <Flex>
          <Flex ml='auto' cursor='pointer' onClick={() => setExpanded(R.not(expanded))}>
            <Box color={blueColor}>{expandButtonText}</Box>
            <Box ml='3px'>{I[iconName](blueColor, 9, 6)}</Box>
          </Flex>
        </Flex>
      </Flex>
      {
        expanded &&
        <Box
          overflow='auto'
          maxHeight='200px'
          flexDirection='column'
        >
          {
            items.map((item: Object, i: number) => (
              <Item key={i} tel={tel} item={item} isCloStop={isCloStop} />
            ))
          }
        </Box>
      }
    </Box>
  );
});

export default StopItems;
