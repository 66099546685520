import * as R from 'ramda';
import { put, call, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import {
  getConfigByGroupSaga,
  getReferenceTypesByScopeForConfigSaga,
} from '../../sagas';
//////////////////////////////////////////////////

function* getConfigsForUiConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        names: GC.TRUCK_TRUCK_TYPE,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      mapper: G.mapConfigValuesByName,
      parentSagaName: 'getConfigsForUiConfigSaga',
      endpoint: endpointsMap.branchConfigsEndpoint,
      successAction: A.getConfigsForUiConfigSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsForUiConfigSaga exception');
  }
}

function* getCloDispatchBoardActionListSaga({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.getCloDispatchBoardActionList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCloDispatchBoardActionListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCloDispatchBoardActionListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getCloDispatchBoardActionListSaga exception');
  }
}

function* getTelDispatchBoardActionListSaga({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.getTelDispatchBoardActionList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTelDispatchBoardActionListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getTelDispatchBoardActionListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getTelDispatchBoardActionListSaga exception');
  }
}

function* handleSaveDispatchBoardStatusActionSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { data, loadType } = payload;

    const isTel = R.equals(loadType, GC.LOAD_TYPE_TEL);

    const endpoint = G.ifElse(
      isTel,
      endpointsMap.telDispatchBoardActionPanel,
      endpointsMap.cloDispatchBoardActionPanel,
    );

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, data),
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (isTel) {
        yield put(A.getTelDispatchBoardActionListRequest(branchGuid));
      } else {
        yield put(A.getCloDispatchBoardActionListRequest(branchGuid));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveDispatchBoardStatusActionSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleSaveDispatchBoardStatusActionSaga exception');
  }
}

function* handleUpdateDispatchBoardStatusActionSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { data, loadType } = payload;

    const isTel = R.equals(loadType, GC.LOAD_TYPE_TEL);

    const endpoint = G.ifElse(
      isTel,
      endpointsMap.telDispatchBoardActionPanel,
      endpointsMap.cloDispatchBoardActionPanel,
    );

    const res = yield call(sendRequest, 'put', endpoint, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      const branchGuid = yield select(makeSelectCurrentBranchGuid());

      if (isTel) {
        yield put(A.getTelDispatchBoardActionListRequest(branchGuid));
      } else {
        yield put(A.getCloDispatchBoardActionListRequest(branchGuid));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDispatchBoardStatusActionSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateDispatchBoardStatusActionSaga exception');
  }
}

function* handleDeleteDispatchBoardStatusActionSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, loadType } = payload;

    const isTel = R.equals(loadType, GC.LOAD_TYPE_TEL);

    const endpoint = G.ifElse(
      isTel,
      endpointsMap.getTelDispatchBoardActionEndpoint,
      endpointsMap.getCloDispatchBoardActionEndpoint,
    )(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      const branchGuid = yield select(makeSelectCurrentBranchGuid());

      if (isTel) {
        yield put(A.getTelDispatchBoardActionListRequest(branchGuid));
      } else {
        yield put(A.getCloDispatchBoardActionListRequest(branchGuid));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDispatchBoardStatusActionSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteDispatchBoardStatusActionSaga exception');
  }
}

function* handleVisitConfigUiPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_UI_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CLO });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_TEL });
    yield call(getConfigByGroupSaga, GC.UI_CONFIG_GROUP);

    yield fork(getConfigsForUiConfigSaga);

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(A.getCloDispatchBoardActionListRequest(branchGuid));
    yield put(A.getTelDispatchBoardActionListRequest(branchGuid));
    yield put(closeLoader());

    break;
  }
}

function* uiConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_UI_PAGE, handleVisitConfigUiPageSaga);
  yield takeLatest(A.getCloDispatchBoardActionListRequest, getCloDispatchBoardActionListSaga);
  yield takeLatest(A.getTelDispatchBoardActionListRequest, getTelDispatchBoardActionListSaga);
  yield takeLatest(A.saveDispatchBoardStatusActionRequest, handleSaveDispatchBoardStatusActionSaga);
  yield takeLatest(A.updateDispatchBoardStatusActionRequest, handleUpdateDispatchBoardStatusActionSaga);
  yield takeLatest(A.deleteDispatchBoardStatusActionRequest, handleDeleteDispatchBoardStatusActionSaga);
}

export default uiConfigWatcherSaga;
