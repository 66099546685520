import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState } from 'react-recompose';
// components
import { Tabs } from '../../../../../components/tabs';
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import {
  carrierEdiLoaderConfigFields,
  carrierEdiExporterConfigFields,
  defaultCarrierEdiLoaderConfigFields,
  defaultCarrierEdiExporterConfigFields,
  carrierEdiLoaderConfigValidationSchema,
  carrierEdiExporterConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getFieldSettings = (activeTab: number, filedKeys: Array, fieldSettings: Array) => R.compose(
  R.values,
  R.pick(R.propOr([], activeTab, filedKeys)),
  R.indexBy(R.prop('fieldName')),
)(fieldSettings);

const exporterTabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: `${G.getWindowLocale('titles:order', 'Order')} (204)` },
  { text: `${G.getWindowLocale('titles:functional-acknowledgment', 'Functional Acknowledgment')} (997)` },
];

const loaderTabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: `${G.getWindowLocale('titles:status-message', 'Status Message')} (214)` },
  { text: `${G.getWindowLocale('titles:invoice', 'Invoice')} (210)` },
];

const exporterFieldKeys = [
  [
    GC.FIELD_CARRIER_GUID,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
    GC.FIELD_TRANSACTION_TYPES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID,
    GC.FIELD_EDI_SENDER_QUALIFIER,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID,
    GC.FIELD_CONTENT_FORMAT,
  ],
  [
    GC.FIELD_EVENT_REFERENCES,
    GC.FIELD_TEL_REFERENCES,
  ],
  [
    GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_ID,
    GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_QUALIFIER,
  ],
];

const loaderFieldKeys = [
  [
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOAD_IDENTIFICATION_TYPE,
    GC.FIELD_CARRIER_GUID,
    GC.FIELD_INVOICE_DOCUMENT_TYPE_GUID,
    GC.FIELD_SEND_RESPONSE,
  ],
  [
    GC.FIELD_DEFAULT_TIME_ZONE,
    GC.FIELD_EVENT_MATCHING_REFERENCE,
    GC.FIELD_STOP_NUMBER_REFERENCE_CODE,
  ],
  [
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES,
  ],
];

const enhance = compose(
  withState('activeTab', 'setActiveTab', 0),
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: ({ ediType }: Object) => {
      if (R.equals(ediType, 'exporter')) {
        return carrierEdiExporterConfigValidationSchema();
      }

      return carrierEdiLoaderConfigValidationSchema;
    },
    mapPropsToValues: ({ ediType, initialValues }: Object) => G.setInitialFormikValues(
      G.ifElse(
        R.equals(ediType, 'exporter'),
        defaultCarrierEdiExporterConfigFields,
        defaultCarrierEdiLoaderConfigFields,
      ),
      initialValues,
    ),
  }),
  pure,
);

const Form = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Tabs
      tabs={props.tabs}
      wrapperStyles={{ mb: 15 }}
      activeTab={props.activeTab}
      handleClickTab={props.setActiveTab}
    />
    <FieldsetComponent
      {...G.getFormikProps(props)}
      handlers={props.handlers}
      justifyContent='space-between'
      optionsForSelect={props.optionsForSelect}
      fields={getFieldSettings(props.activeTab, props.filedKeys, props.fieldSettings)}
    />
    <FormFooter boxStyles={{ p: '10px 5px 0 5px' }} closeModal={props.closeModal} />
  </form>
));

export const CarrierEdiExporterConfigForm = (props: Object) => (
  <Form
    {...props}
    ediType='exporter'
    tabs={exporterTabs}
    filedKeys={exporterFieldKeys}
    fieldSettings={carrierEdiExporterConfigFields}
  />
);

export const CarrierEdiLoaderConfigForm = (props: Object) => (
  <Form
    {...props}
    ediType='loader'
    tabs={loaderTabs}
    filedKeys={loaderFieldKeys}
    fieldSettings={carrierEdiLoaderConfigFields}
  />
);
