import React from 'react';
// feature auth
import { Td, Table } from './ui';
//////////////////////////////////////////////////

export const InformationCategoriesTable = () => (
  <Table width='100%'>
    <thead>
      <tr>
        <Td width='30%' textAlign='center' fontWeight={700}>Category</Td>
        <Td width='60%' textAlign='center' fontWeight={700}>Examples</Td>
        <Td width='10%' textAlign='center' fontWeight={700}>Collected</Td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <Td>A. Identifiers</Td>
        <Td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name.</Td>
        <Td>YES</Td>
      </tr>
      <tr>
        <Td>B. Personal information categories listed in the California Customer Records statute</Td>
        <Td>Name, contact information, education, employment, employment history and financial information</Td>
        <Td>YES</Td>
      </tr>
      <tr>
        <Td>C. Protected classification characteristics under California or federal law</Td>
        <Td>Gender and date of birth</Td>
        <Td>YES</Td>
      </tr>
      <tr>
        <Td>D. Commercial information</Td>
        <Td>transaction information, purchase history, financial details and payment information</Td>
        <Td>YES</Td>
      </tr>
      <tr>
        <Td>E. Biometric information</Td>
        <Td>Fingerprints and voiceprints</Td>
        <Td>NO</Td>
      </tr>
      <tr>
        <Td>F. Internet or other similar network activity</Td>
        <Td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements</Td>
        <Td>YES</Td>
      </tr>
      <tr>
        <Td>G. Geolocation data</Td>
        <Td>Device location</Td>
        <Td>NO</Td>
      </tr>
      <tr>
        <Td>H. Audio, electronic, visual, thermal, olfactory, or similar information</Td>
        <Td>Images and audio, video or call recordings created in connection with our business activities</Td>
        <Td>NO</Td>
      </tr>
      <tr>
        <Td>I. Professional or employment-related information</Td>
        <Td>Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us</Td>
        <Td>YES</Td>
      </tr>
      <tr>
        <Td>J. Education Information</Td>
        <Td>Student records and directory information</Td>
        <Td>NO</Td>
      </tr>
      <tr>
        <Td>K. Inferences drawn from other personal information</Td>
        <Td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</Td>
        <Td>YES</Td>
      </tr>
    </tbody>
  </Table>
);
