import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withBooleanStatus } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const Info = (props: Object) => {
  const {
    tel,
    event,
    trailer,
    pickedUp,
    hideActions,
    removeTrailerFromStop,
  } = props;

  const { unitId } = trailer;

  return (
    <Flex py='4px' wordBreak='break-all'>
      <Box mx='4px' maxWidth='calc(50% - 20px)'>{unitId}</Box>
      {
        R.not(hideActions) &&
        <Flex ml='auto'>
          <Box
            cursor='pointer'
            onClick={() => removeTrailerFromStop({
              tel,
              trailer,
              pickedUp,
              stop: event,
            })}
          >
            {I.minusInRound(blueColor)}
          </Box>
        </Flex>
      }
    </Flex>
  );
};

const Content = (props: Object) => {
  const {
    tel,
    event,
    hideActions,
    handleClickAddTrailer,
    removeTrailerFromStop,
  } = props;

  const { droppedTrailers, pickedUpTrailers } = event;

  return (
    <Box
      px='5px'
      fontSize={12}
      overflow='auto'
      maxHeight='200px'
      flexDirection='column'
    >
      <Flex py='3px'>
        {
          R.not(hideActions) &&
          <Box
            cursor='pointer'
            onClick={() => handleClickAddTrailer({ tel, event, pickedUp: true })}
          >
            {I.plusRound(blueColor, 13, 13)}
          </Box>
        }
        <Box mx='4px'>{G.getWindowLocale('titles:picked-up', 'Picked Up')}:</Box>
      </Flex>
      {
        G.isNotNilAndNotEmpty(pickedUpTrailers) &&
        <Box py='2px'>
          {
            pickedUpTrailers.map((item: Object, i: number) => (
              <Info
                key={i}
                tel={tel}
                event={event}
                trailer={item}
                pickedUp={true}
                removeTrailerFromStop={removeTrailerFromStop}
              />
            ))
          }
        </Box>
      }
      <Flex py='3px'>
        {
          R.not(hideActions) &&
          <Box
            cursor='pointer'
            onClick={() => handleClickAddTrailer({ tel, event, pickedUp: false })}
          >
            {I.plusRound(blueColor, 13, 13)}
          </Box>
        }
        <Box mx='4px'>{G.getWindowLocale('titles:dropped', 'Dropped')}:</Box>
      </Flex>
      {
        G.isNotNilAndNotEmpty(droppedTrailers) &&
        <Box py='2px'>
          {
            droppedTrailers.map((item: Object, i: number) => (
              <Info
                key={i}
                tel={tel}
                event={event}
                trailer={item}
                pickedUp={false}
                hideActions={hideActions}
                removeTrailerFromStop={removeTrailerFromStop}
              />
            ))
          }
        </Box>
      }
    </Box>
  );
};

const Header = (props: Object) => {
  const { stop } = props;

  const count = R.compose(
    R.length,
    R.concat(R.pathOr([], [GC.FIELD_STOP_DROPPED_TRAILER_GUIDS], stop)),
    R.pathOr([], [GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS]),
  )(stop);

  return (
    <Box mx='4px'>{count} {G.getWindowLocale('titles:trailer(s)', 'Trailer(s)')}:</Box>
  );
};

const StopTrailers = withBooleanStatus(false)((props: Object) => {
  const {
    tel,
    clos,
    stop,
    hideActions,
    booleanStatus,
    handleClickAddTrailer,
    removeTrailerFromStop,
    handleToggleBooleanStatus,
  } = props;

  return (
    <Box
      width='100%'
      borderRadius={5}
      overflow='hidden'
      borderBottom='1px solid'
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Flex
        px='8px'
        height={24}
        fontSize={12}
        justifyContent='space-between'
        bg={G.getTheme('colors.light.lightGrey')}
      >
        <Header {...props} color={blueColor} />
        <Flex mr='6px' cursor='pointer' onClick={handleToggleBooleanStatus}>
          <Box color={blueColor}>
            {
              G.ifElse(
                booleanStatus,
                G.getWindowLocale('actions:hide', 'Hide'),
                G.getWindowLocale('actions:show', 'Show'),
              )
            }
          </Box>
          <Box ml='3px'>
            {G.ifElse(booleanStatus, I.arrowUpSimple, I.arrowDownSimple)(blueColor, 9, 6)}
          </Box>
        </Flex>
      </Flex>
      {
        booleanStatus &&
        <Content
          tel={tel}
          clos={clos}
          event={stop}
          hideActions={hideActions}
          handleClickAddTrailer={handleClickAddTrailer}
          removeTrailerFromStop={removeTrailerFromStop}
        />
      }
    </Box>
  );
});

export default StopTrailers;
