import * as R from 'ramda';
import { put, all, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectCurrentUserSettingsFields } from '../../profile/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
import { getAvailableReportGeoFencingZoneListRequest } from '../../geo-fencing-zone/report/actions';
import { getAvailableDocumentTypes, getCloDispatchBoardActionListRequest } from '../../configurations/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { generateDefaultReport } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-board
import {
  getConfigsByNamesRequest,
  getCrossDockLocationsRequest,
  getDispatchBoardConfigsRequest,
} from '../actions';
// feature dispatch-board/load
import * as A from './actions';
import * as H from '../helpers';
import {
  makeSelectGroupBy,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectGlobalFilterType,
  makeSelectGlobalFilterValue,
  makeSelectTableTitleFilters,
  makeSelectShipUnitsExpanded,
} from './selectors';
//////////////////////////////////////////////////

const reportPayload = {
  reportType: GC.ROUTE_CLO_REPORT,
  pathname: GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
};

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));

    const groupBy = yield select(makeSelectGroupBy());
    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      H.getTitleOrderFields(titleOrderFields, true),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const primaryReferenceFilterValue = R.pathOr(
      '',
      [GC.FIELD_PRIMARY_REFERENCE_VALUE, GC.FIELD_STRING_VALUE],
      titleFilterParams,
    );

    const { limit, offset } = pagination;

    const reqBodyWithPrimaryReferenceFilter = {
      limit,
      offset,
      groupBy,
      fields: [],
      orderFields,
      primaryReferenceFilterValue,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    let reqData = reqBodyWithPrimaryReferenceFilter;

    if (G.isNilOrEmpty(primaryReferenceFilterValue)) {
      const filterParams = yield select(makeSelectFilterParams());
      const globalFilterType = yield select(makeSelectGlobalFilterType());
      const globalFilterValue = yield select(makeSelectGlobalFilterValue());

      const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

      const searchCriteria = G.ifElse(
        G.isNotEmpty(titleFilterParams),
        H.getTitleFilterParams(titleFilterParams, true),
        G.getOrElse(reportParams, 'searchCriteria', []),
      );

      const reqBody = R.mergeRight(reqBodyWithPrimaryReferenceFilter, {
        globalFilterType,
        globalFilterValue,
        showLastDays: R.pathOr(30, ['showLastDays'], reportParams),
        summaryFilters: R.pathOr([], ['summaryFilters'], reportParams),
        searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      });

      reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });
    }

    const res = yield call(sendRequest, 'post', endpointsMap.cloDispatchBoard, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));

      const cloGuids = H.getGuidsFromRouteListResults(res.data.results);

      if (G.isNotEmpty(cloGuids)) {
        const shipUnitsExpanded = yield select(makeSelectShipUnitsExpanded());

        if (shipUnitsExpanded) {
          yield put(A.getOrdersShipUnitRequest(cloGuids));
        }
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleAvailableReportsRequest({ notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return false;

    const params = {
      reportType: GC.ROUTE_CLO_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);
      yield put(A.setReports(checkedReports));

      if (R.not(R.prop('length', reports))) {
        return yield put(A.setUsedReport(generateDefaultReport(GC.ROUTE_CLO_REPORT)));
      }

      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(GC.ROUTE_CLO_REPORT));
        const currentUser = yield select(makeSelectCurrentUserSettingsFields());
        const configGroupBy = G.getAmousConfigByNameFromWindow(GC.UI_ROUTE_LIST_CLO_DEFAULT_ORGANIZE_BY);
        const groupBy = R.pathOr(
          R.pathOr(
            R.or(configGroupBy, GC.ORGANIZE_BY_NO_GROUPING),
            ['cloRouteDefaultGroupBy'],
            currentUser,
          ),
          [GC.FIELD_GROUP_BY],
          usedReport,
        );
        yield put(A.setGroupBy(groupBy));
        yield put(A.setGroupBy(R.pathOr(GC.ORGANIZE_BY_NO_GROUPING, [GC.FIELD_GROUP_BY], usedReport)));
        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.set(R.lensProp('searchCriteria'), searchCriteria, payload);
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newPayload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.report, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.set(
        R.lensProp('searchCriteria'),
        R.path(['searchCriteria'], payload),
        data,
      ))));
      yield call(handleAvailableReportsRequest, { notSetUsedReport: true, payload: reportPayload });
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = {
      data: R.set(R.lensProp('searchCriteria'), searchCriteria, payload),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.report, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.set(
        R.lensProp('searchCriteria'),
        R.path(['searchCriteria'], payload),
        data,
      ))));
      yield call(handleAvailableReportsRequest, { notSetUsedReport: true, payload: reportPayload });
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleAvailableReportsRequest, { payload: reportPayload });
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetSummarySaga({ payload }: Object) {
  try {
    const reportParams = yield select(makeSelectUsedReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const onEndAction = R.path(['onEndAction'], payload);

    if (R.propEq(GC.ORGANIZE_BY_TRUCK, 'summaryGroup', reportParams)) {
      yield put(A.getSummarySuccess({}));

      if (G.isFunction(onEndAction)) onEndAction();

      return;
    }
    if (G.isNilOrEmpty(reportParams.summaryGroup)) {
      yield put(A.getSummarySuccess({}));

      if (G.isFunction(onEndAction)) onEndAction();

      return;
    }
    const reqBody = {
      group: reportParams.summaryGroup,
      subgroup: reportParams.summarySubgroup,
      showLastDays: reportParams.showLastDays,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.cloDispatchBoardSummary, { data: reqBody });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSummarySuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSummarySaga');
    }

    if (G.isFunction(onEndAction)) onEndAction();
  } catch (error) {
    yield call(G.handleException, error, 'handleGetSummarySaga');
  }
}

function* handleCreateReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.cloMassReference, options);
    const { data, status } = res;
    const resCondition = R.not(
      G.isResponseSuccess(status, data, 'Thanks, your data was created successfully'),
    );

    if (resCondition) {
      yield call(G.handleFailResponse, res, 'handleCreateReferenceSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateReferenceSaga');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteCloSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'delete', endpointsMap.getCloEntity(payload[GC.FIELD_GUID]));
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCloSaga');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteCloSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeCancelStatusSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const guid = R.path(['data', GC.FIELD_GUID], payload);
    let options;

    if (G.isNotNilAndNotEmpty(R.path([GC.FIELD_LOAD_CANCEL_REASON_GUID], payload))) {
      options = {
        params: R.pick([GC.FIELD_LOAD_CANCEL_REASON_GUID], payload),
      };
    }
    const endpoint = {
      cancel: endpointsMap.cloCancel(guid),
      restore: endpointsMap.cloRestore(guid),
    };
    const res = yield call(sendRequest, 'put', endpoint[payload.type], options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDetailsRequest(guid));
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeCancelStatusSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeCancelStatusSaga');
  }
}

function* cancelCLORequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.cancelCLO, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsRequest(G.getGuidFromObject(payload)));
    } else {
      yield call(G.handleFailResponse, res, 'cancelCLORequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'cancelCLORequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* unquoteCLORequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const cloGuid = G.getGuidFromObject(payload);

    const res = yield call(sendRequest, 'put', endpointsMap.cloUnquote(cloGuid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsRequest(cloGuid));
    } else {
      yield call(G.handleFailResponse, res, 'unquoteCLORequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'unquoteCLORequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetOrderDetailsSaga({ payload }: Object) {
  try {
    const guid = G.ifElse(
      G.isString(payload),
      payload,
      G.getGuidFromObject(payload),
    );
    const endpoint = endpointsMap.getCloEndpoint(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const references = R.sortBy((a: Object) => R.not(a.primary), data.references);
      const items = G.getItemsFromEventsUniqByGuid(data.events);
      const containers = G.getContainersFromEventsUniqByGuid(data.events);
      const load = R.assoc(GC.FIELD_LOAD_TYPE, GC.LOAD_TYPE_CLO, data);
      yield put(A.getOrderDetailsSuccess({ ...load, items, containers, references }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetOrderDetailsSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetOrderDetailsSaga exception');
  }
}

function* handleGetOrderDispatchBoardDetailsSaga({ payload }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const params = {
      [GC.FIELD_CLO_GUID]: payload,
      [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloDispatchBoardByCloGuid, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDispatchBoardDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetOrderDispatchBoardDetailsSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetOrderDispatchBoardDetailsSaga exception');
  }
}

function* handleGetOrderSelectedRateSaga({ payload }: Object) {
  try {
    const { guid, rateGuid } = payload;

    if (R.isNil(rateGuid)) return;

    const res = yield call(sendRequest, 'get', endpointsMap.getCloRateItemEndpoint(rateGuid));
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderSelectedRateSuccess({ guid, rateData: data }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLoadSelectedRateSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLoadSelectedRateSaga exception');
  }
}

function* handleGetOrdersShipUnitSaga({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.getShipUnitByCloGuid, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrdersShipUnitSuccess({ data, guids: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetOrdersShipUnitSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetOrdersShipUnitSaga exception');
  }
}

function* handleGetOrderDocumentListSaga({ payload }: Object) {
  try {
    const params = {
      [GC.FIELD_PRIMARY_OBJECT_GUID]: payload,
      [GC.FIELD_BRANCH_GUID]: yield select(makeSelectCurrentBranchGuid()),
    };
    const res = yield call(sendRequest, 'get', endpointsMap.cloDocumentList, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDocumentListSuccess({
        guid: payload,
        documents: data,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetOrderDocumentListSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetOrderDocumentListSaga exception');
  }
}

function* getOrderInvoicesSaga({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_INVOICE_READ,
      PC.CLO_INVOICE_WRITE,
      PC.CLO_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const options = {
      params: { cloGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.cloInvoice, options);

    if (G.isResponseSuccess(res.status)) {
      yield put(A.getOrderInvoicesSuccess({ guid: payload, invoices: res.data }));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderInvoicesSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderInvoicesSaga exception');
  }
}

function* getOrderInvoiceTotalsRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_INVOICE_READ,
      PC.CLO_INVOICE_WRITE,
      PC.CLO_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const options = {
      params: { [GC.FIELD_CLO_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloInvoiceTotals, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderInvoiceTotalsSuccess({ invoiceTotals: data, [GC.FIELD_GUID]: payload }));
    } else {
      yield call(G.handleException, 'error', 'getOrderInvoiceTotalsRequest exception');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderInvoiceTotalsRequest exception');
  }
}

function* handleGetBranchConfigsByCloBranchGuidSaga({ payload }: Object) {
  try {
    const { guid, branchGuid } = payload;

    const names = R.join(
      ',',
      [GC.CLO_GENERAL_APPROVAL_REQUIRED],
    );

    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsByCloBranchGuidSuccess({ guid, loadConfigs: G.mapConfigValuesByName(data) }));
    } else {
      yield call(G.handleException, 'error', 'handleGetBranchConfigsByCloBranchGuidSaga exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchConfigsByCloBranchGuidSaga exception');
  }
}

function* handleToggleOrderDetailsSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, data, noDetails, branchGuid } = payload;

    if (R.and(R.path(['expanded'], data), noDetails)) {
      if (R.pathEq('SHIP_UNIT', ['shownDetails'], data)) {
        yield call(handleGetOrdersShipUnitSaga, { payload: R.of(Array, guid) });
      } else {
        yield all([
          call(handleGetOrderDetailsSaga, { payload: guid }),
          call(handleGetOrderSelectedRateSaga, { payload }),
          call(getOrderInvoicesSaga, { payload: guid }),
          call(getOrderInvoiceTotalsRequest, { payload: guid }),
          call(handleGetOrderDocumentListSaga, { payload: guid }),
          call(
            handleGetBranchConfigsByCloBranchGuidSaga,
            { payload: { guid, branchGuid: R.pathOr(branchGuid, [GC.BRANCH_GUID], payload) } },
          ),
        ]);
      }
    }

    yield put(closeLoader());
    yield put(A.toggleOrderDetailsSuccess(payload));
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleToggleOrderDetailsSaga exception');
  }
}

function* handleCreateTelFromCloSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { clo } = payload;
    const cloGuid = G.getGuidFromObject(clo);
    const tel = R.pathOr({}, [GC.FIELD_TEL], payload);
    const options = {
      data: {
        ...tel,
        cloGuid,
        [GC.FIELD_BRANCH_GUID]: R.prop(GC.FIELD_BRANCH_GUID, clo),
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.tel, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDetailsRequest(cloGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTelFromCloSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTelFromCloSaga exception');
  }
}

function* handleUpdateCloRateSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { rateData, loadData } = payload;

    const options = {
      data: rateData,
    };

    const res = yield call(sendRequest, 'put', endpointsMap.cloRate, options);

    if (G.isResponseSuccess(res.status)) {
      if (G.isNotNil(loadData)) {
        yield put(A.updateOrderDetailsRequest(loadData));
      } else {
        yield put(A.getOrderDetailsRequest(R.prop(GC.FIELD_CLO_GUID, rateData)));
      }

      yield put(closeModal());

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCloRateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCloRateSaga exception');
  }
}

function* handleCreateCustomerInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.cloInvoice, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(A.getOrderInvoiceTotalsRequest(G.getPropFromObject(GC.FIELD_CLO_GUID, payload)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCustomerInvoiceSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCustomerInvoiceSaga exception');
  }
}

function* handleUpdateOrderDetailsSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const updateData = R.pick(GC.GROUPED_FIELDS.LOAD_PICK_ARR, payload);
    const options = { data: updateData };
    const res = yield call(sendRequest, 'put', endpointsMap.clo, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateOrderDetailsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateOrderDetailsSaga exception');
  }
}

function* updateOrderDocumentSaga({ payload }: Object) {
  const { callback, data: payloadData } = payload;

  try {
    yield put(openLoader());
    const reqData = G.makeDataForDocument(payloadData);
    const options = { data: reqData };
    const endpoint = G.ifElse(
      G.isLoadTypeClo(payloadData),
      endpointsMap.cloDocumentUpdate,
      endpointsMap.telDocumentUpdate,
    );

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateOrderDocumentSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      G.callFunction(callback);
      yield call(G.handleFailResponse, res, 'updateOrderDocumentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    G.callFunction(callback);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderDocumentSaga exception');
  }
}

function* removeOrderDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, loadGuid } = payload;
    const res = yield call(sendRequest, 'delete', endpointsMap.getCloDocumentEndpoint(guid));
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeOrderDocumentSuccess({ guid, loadGuid }));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderDocumentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderDocumentSaga exception');
  }
}

function* getDispatchBoardConfigsSaga(branchGuid: string) {
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.UI_CONFIG_GROUP,
  }));
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.GENERAL_CONFIG_GROUP,
  }));
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.CLO_CONFIG_GROUP,
  }));
  yield put(getConfigsByNamesRequest({
    branchGuid,
    names: R.join(',', [GC.GROUPED_CONFIGS.LOAD_CONFIGS_ARR]),
  }));
  yield put(getDispatchBoardConfigsRequest({
    branchGuid,
    groupName: GC.INVOICE_CONFIG_GROUP,
  }));
}

function* handleVisitDispatchBoardCloPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DISPATCH_BOARD_CLO_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield put(A.setIgnorePromptStatus(false));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(getCloDispatchBoardActionListRequest(branchGuid));
    yield put(getAvailableDocumentTypes());

    yield call(getDispatchBoardConfigsSaga, branchGuid);

    yield put(getCrossDockLocationsRequest(branchGuid));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload });

    yield put(getAllAvailableRefTypesByScopeRequest(GC.LOAD_TYPE_CLO));
    yield put(getAvailableReportGeoFencingZoneListRequest());

    yield call(handleGetItemListSaga, { payload: true });

    yield put(closeLoader());

    break;
  }
}

function* orderListWatcherSaga() {
  yield takeLatest(A.cancelCLORequest, cancelCLORequest);
  yield takeLatest(A.unquoteCLORequest, unquoteCLORequest);
  yield takeLatest(A.deleteItemRequest, handleDeleteCloSaga);
  yield takeLatest(A.getSummaryRequest, handleGetSummarySaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.updateCloRateRequest, handleUpdateCloRateSaga);
  yield takeLatest(A.getOrderInvoicesRequest, getOrderInvoicesSaga);
  yield takeLatest(A.getOrderDetailsRequest, handleGetOrderDetailsSaga);
  yield takeLatest(A.createReferenceRequest, handleCreateReferenceSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.createTelFromCloRequest, handleCreateTelFromCloSaga);
  yield takeLatest(A.removeOrderDocumentRequest, removeOrderDocumentSaga);
  yield takeLatest(A.updateOrderDocumentRequest, updateOrderDocumentSaga);
  yield takeLatest(A.getOrdersShipUnitRequest, handleGetOrdersShipUnitSaga);
  yield takeLatest(A.changeCancelStatusRequest, handleChangeCancelStatusSaga);
  yield takeLatest(A.toggleOrderDetailsRequest, handleToggleOrderDetailsSaga);
  yield takeLatest(A.updateOrderDetailsRequest, handleUpdateOrderDetailsSaga);
  yield takeLatest(A.getOrderInvoiceTotalsRequest, getOrderInvoiceTotalsRequest);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.getOrderDocumentListRequest, handleGetOrderDocumentListSaga);
  yield takeLatest(A.createCustomerInvoiceRequest, handleCreateCustomerInvoiceSaga);
  yield takeLatest(GC.VISIT_DISPATCH_BOARD_CLO_PAGE, handleVisitDispatchBoardCloPageSaga);
  yield takeLatest(A.getOrderDispatchBoardDetailsRequest, handleGetOrderDispatchBoardDetailsSaga);
}

export default orderListWatcherSaga;
