import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { reportEnhancer } from '../../report-common';
// icons
import * as I from '../../svgs';
// ui
import { ListWrapper, ZOrderWrapper } from '../../ui';
// feature inspections
import CheckList from './components/check-list';
import { tableSettings, columnSettings, FILTER_PARAMS } from './settings/table-settings';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  setUsedReport,
  toggleDetails,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemsRequest,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.mainBlue');

const enhance = compose(
  reportEnhancer,
  withHandlers({
    handleDeleteItems: (props: Object) => (guid: string) => {
      const { itemList, openModal, closeModal, deleteItemsRequest } = props;

      const selectedList = G.isString(guid) ? R.of(Array, guid) : G.getSelectedItemGuids(itemList);

      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteItemsRequest(selectedList),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: `${G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this')}?`,
      });

      openModal(modalContent);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleShowDetails: (props: Object) => (guid: string, entity: Object) => {
      const { openModal, closeModal } = props;
      const { truckGuid, trailerGuid } = entity;

      const width = 'calc(100vw - 100px)';

      const modal = {
        fixedWidth: true,
        wrapperStyles: { width },
        isExpandedContainer: true,
        component: (
          <CheckList
            guid={guid}
            truckGuid={truckGuid}
            closeModal={closeModal}
            trailerGuid={trailerGuid}
          />
        ),
        options: {
          minWidth: width,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    toggleDetails,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    handleShowDetails,
    handleDeleteItems,
    getItemListRequest,
    handleTableTitleFilter,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  const actionButtons = [
    {
      iconName: 'eye2',
      iconColor: blueColor,
      action: handleShowDetails,
    },
    {
      iconName: 'trash',
      iconColor: blueColor,
      enableIfEditable: true,
      action: handleDeleteItems,
    },
  ];

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    toggle: toggleDetails,
    report: selectedReport,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), FILTER_PARAMS),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const Inspections = enhance((props: Object) => {
  const { itemList, totalCount, getItemListRequest, handleDeleteItems, resetListAndPagination } = props;

  const listActions = [
    {
      action: handleDeleteItems,
      text: G.getWindowLocale('actions:delete', 'Delete'),
      icon: I.trash(G.getTheme('colors.light.mainLight'), 'transparent'),
    },
  ];

  const reloadAction = () => {
    resetListAndPagination();
    setTimeout(() => getItemListRequest(), 100);
  };

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          display='flex'
          withCount={true}
          withReload={true}
          noExportable={true}
          popperWithCount={true}
          totalCount={totalCount}
          filterProps={FILTER_PARAMS}
          reloadAction={reloadAction}
          type={GC.INSPECTION_REPORT}
          hiddenRightFilterInfo={false}
          title={G.getWindowLocale('titles:inspections-report', 'Inspections Report')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <PageActions
        version={2}
        listActions={listActions}
        shadowColor={G.getTheme('createButton.shadowColor')}
        count={R.filter(R.prop('selected'), R.or(itemList, [])).length}
      />
    </ListWrapper>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setReports,
  setUsedReport,
  toggleDetails,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemsRequest,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
})(Inspections);
