import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import {
  exportInvoicesToEDIRequest,
  sendInvoiceToBCByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import CustomerInvoiceForm from '../../../invoice/components/customer-form';
// forms
import { SelectGLPostedDateForm } from '../../../../forms/forms/select-gl-posted-date-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncEditCloInvoice } from '../../../../hocs/with-async-edit-clo-invoice';
// feature dispatch details new
import * as H from '../../helpers';
import PrintForm, { PrintFormWithCLODocuments } from '../../forms/print-form';
import {
  sendCIToFinancialRequest,
  printLoadCustomerInvoiceRequest,
  updateLoadCustomerInvoiceRequest,
  removeLoadCustomerInvoiceRequest,
  getLoadCustomerInvoiceListRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddCustomerInvoice = compose(
  withHandlers({
    handleAddCloCustomerInvoice: (props: Object) => (clo: Object) => {
      const {
        load,
        itemList,
        openModal,
        selectedCustomerRate,
        createLoadCustomerInvoiceRequest,
        loadConfigs: { configsByNames, configGroups },
      } = props;

      const cloGuid = R.prop(GC.FIELD_GUID, clo);
      const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, clo);

      if (G.isNilOrEmpty(selectedCustomerRate)) {
        const message = G.getWindowLocale(
          'messages:not-create-customer-invoice',
          'Sorry, you can not create a customer invoice without the selected rate',
        );

        return G.showToastrMessageSimple('info', message);
      }

      const cloTotalData = H.makeCloTotalData(clo, load);
      const relatedCloInvoicesLength = R.length(itemList);

      const invoiceNameAdditional = G.ifElse(
        G.isZero(relatedCloInvoicesLength),
        '',
        relatedCloInvoicesLength,
      );

      const defaultInvoiceName = `${
        R.path([GC.SYSTEM_OBJECT_PRIMARY_REFERENCE, GC.FIELD_VALUE], clo)
      }${invoiceNameAdditional}`;

      const handleSendCloInvoice = (data: Object) =>
        createLoadCustomerInvoiceRequest(R.assoc(GC.FIELD_CLO_GUID, cloGuid, data));

      const configs = R.path([GC.INVOICE_CONFIG_GROUP], configGroups);

      const defaultNetDays = G.getConfigValueFromStore(
        GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_NET_DAYS,
        configs,
      );

      const defaultStatus = G.getConfigValueFromStore(
        GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const defaultSecondInvoiceStatus = G.getConfigValueFromStore(
        GC.INVOICE_CLO_SECOND_CUSTOMER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const component = (
        <CustomerInvoiceForm
          clo={clo}
          branchGuid={branchGuid}
          defaultStatus={defaultStatus}
          defaultNetDays={defaultNetDays}
          cloGuid={G.getGuidFromObject(clo)}
          selectedRate={selectedCustomerRate}
          defaultInvoiceName={defaultInvoiceName}
          totalWeight={cloTotalData.cloTotalWeight}
          handleSendCloInvoice={handleSendCloInvoice}
          totalDistance={cloTotalData.cloTotalDistance}
          defaultSecondInvoiceStatus={defaultSecondInvoiceStatus}
          sequenceConfigName={GC.INVOICE_CLO_INVOICE_NUMBER_SEQUENCE}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          autogenerateConfigName={GC.INVOICE_CLO_INVOICE_NUMBER_AUTOGENERATED}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 900,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadCustomerInvoiceTableGroupRowActions = compose(
  connect(
    null,
    {
      sendCIToFinancialRequest,
      exportInvoicesToEDIRequest,
      printLoadCustomerInvoiceRequest,
      updateLoadCustomerInvoiceRequest,
      removeLoadCustomerInvoiceRequest,
      getLoadCustomerInvoiceListRequest,
      sendInvoiceToBCByInvoiceTypeRequest,
      sendInvoiceToSageByInvoiceTypeRequest,
    },
  ),
  withAsyncEditCloInvoice({ updateFrom: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD }),
  withHandlers({
    handleSendCIToSageIntacctAction: (props: Object) => (glPostedDate: any) => {
      const {
        load,
        entity,
        closeFixedPopup,
        getLoadCustomerInvoiceListRequest,
        sendInvoiceToSageByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(load);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getLoadCustomerInvoiceListRequest({ loadGuid });

      sendInvoiceToSageByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'customerInvoice',
        [GC.FIELD_GL_POSTED_DATE]: glPostedDate,
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
  }),
  withHandlers({
    handleSendCIToFinancial: ({ entity, closeFixedPopup, sendCIToFinancialRequest }: Object) => () => {
      sendCIToFinancialRequest(R.prop(GC.FIELD_GUID, entity));
      G.callFunction(closeFixedPopup);
    },
    handleExportInvoiceToEDI: ({ entity, closeFixedPopup, exportInvoicesToEDIRequest }: Object) => () => {
      const guids = R.of(Array, G.getGuidFromObject(entity));

      G.callFunction(closeFixedPopup);
      exportInvoicesToEDIRequest({ guids });
    },
    handleSendCustomerInvoiceToBC: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getLoadCustomerInvoiceListRequest,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(load);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getLoadCustomerInvoiceListRequest({ loadGuid });

      sendInvoiceToBCByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'customerInvoice',
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleSendCustomerInvoiceListToSageIntacct: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        handleSendCIToSageIntacctAction,
      } = props;

      const component = (
        <SelectGLPostedDateForm
          closeModal={closeModal}
          submitAction={(date: any) => handleSendCIToSageIntacctAction(date)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'auto',
          footerZIndex: 'unset',
          title: G.getWindowLocale('titles:gl-posted-date', 'GL Posted Date'),
        },
      };

      openModal(modal);
    },
    handleCreateQBIIFImportCustomerInvoice: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getLoadCustomerInvoiceListRequest,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      const callback = () =>
        getLoadCustomerInvoiceListRequest({ [GC.FIELD_LOAD_GUID]: G.getGuidFromObject(load) });

      const data = {
        callback,
        type: 'customer',
        guids: R.of(Array, G.getGuidFromObject(entity)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      };

      createQBIIFImportByInvoiceTypeRequest(data);
    },
    handleRemoveCustomerInvoice: ({ entity, openModal, removeLoadCustomerInvoiceRequest }: Object) => () => {
      const { guid } = entity;

      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-customer-invoice',
        'Do you want to delete this Customer Invoice?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeLoadCustomerInvoiceRequest(guid),
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePrintCustomerInvoice: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        entity: { guid, cloGuid },
        printLoadCustomerInvoiceRequest,
      } = props;

      const customerGuid = R.compose(
        R.path([cloGuid, GC.FIELD_BRANCH_GUID]),
        R.indexBy(R.prop(GC.FIELD_GUID)),
        R.prop(GC.SYSTEM_LIST_CLOS),
      )(load);

      const component = (
        <PrintForm
          cloGuid={cloGuid}
          selectedEntity={guid}
          closeModal={closeModal}
          branchGuid={customerGuid}
          useVendorDocuments={true}
          telGuid={G.getGuidFromObject(load)}
          submitAction={printLoadCustomerInvoiceRequest}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
    handlePrintCustomerInvoiceWithImages: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        printLoadCustomerInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);
      const cloGuid = G.getPropFromObject('cloGuid', entity);

      const printInvoiceProps = {
        cloGuid,
        closeModal,
        branchGuid,
        selectedEntity: guid,
        useVendorDocuments: true,
        [GC.BRANCH_GUID]: branchGuid,
        [GC.FIELD_PRIMARY_OBJECT_GUID]: cloGuid,
        submitAction: printLoadCustomerInvoiceRequest,
        printableSection: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE,
      };

      const component = <PrintFormWithCLODocuments {...printInvoiceProps} />;

      const modal = G.getNotMovableModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
  }),
);
