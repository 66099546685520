import * as R from 'ramda';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
// components
import { Tabs } from '../../../components/tabs';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
// import { PageTitleWrapper } from '../../../ui';
// feature analytics
import ChartsDroppable from './chart-droppable';
import { ANALYTICS_DND_ID } from '../constants';
import { getTabs } from '../settings/tabs-options';
import { IconWrapper, ChartViewWrapper } from '../ui';
//////////////////////////////////////////////////

const setLayout = (chartsList: Array = []) => (
  chartsList.map((chartInfo: Object) => ({
    minH: 2,
    minW: 3,
    name: chartInfo.chart.name,
    type: chartInfo.chart.chartType,
    w: R.or(chartInfo.w, 3),
    h: R.or(chartInfo.h, 2),
    y: R.or(chartInfo.y, 0),
    x: R.or(chartInfo.x, 0),
    i: chartInfo.chart.guid,
  }))
);

const ChartComponent = (props: Object) => (
  <ChartViewWrapper isOpen={props.isOpen}>
    <div>
      <Tabs
        activeTab={props.activeTab}
        handleClickTab={props.handleClickTab}
        tabs={getTabs(props.currentDashboard.name)} />
      <AuthWrapper has={[PC.DASHBOARD_WRITE]}>
        <IconWrapper
          p={5}
          m='0 15px'
          iconWH='22px'
          wrapWH='32px'
          onClick={props.handleDeleteDashboard}
          title={G.getWindowLocale('actions:delete', 'Delete')}
        >
          {I.trash()}
        </IconWrapper>
      </AuthWrapper>
    </div>
    <div />
    <Droppable droppableId={ANALYTICS_DND_ID}>
      {(provided: Object, snapshot: Object) => (
        <div ref={provided.innerRef}>
          <ChartsDroppable
            snapshot={snapshot}
            isOpen={props.isOpen}
            charts={props.dashboardCharts}
            currentDashboard={props.currentDashboard}
            chartsLayout={setLayout(props.dashboardCharts)}
            handleEditDashboard={props.handleEditDashboard}
            handleSaveAsNewDashboard={props.handleSaveAsNewDashboard} />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </ChartViewWrapper>
);

export default ChartComponent;
