import React from 'react';
import * as R from 'ramda';
// components
import { withTabs2 } from '../../components/tabs-mui';
// feature carrier
import Carrier from './carrier';
import CarrierInsurances from './insurances';
//////////////////////////////////////////////////

const CarrierWrapper = withTabs2((props: Object) => {
  const { activeTab, handleSetActiveTab } = props;

  if (R.equals(activeTab, 0)) {
    return <Carrier activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
  }

  return <CarrierInsurances activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />;
});

export default CarrierWrapper;
