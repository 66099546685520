import * as R from 'ramda';
import L from 'leaflet';
import { css } from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import React, { useRef, useEffect } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature customer portal
import MarkerInfo from './marker-info';
// icons
import * as I from '../../../svgs';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const darkGreyColor = G.getTheme('colors.darkGrey');

const lastStatusCodeTxtLocale = G.getWindowLocale('titles:last-status-code', 'Last Status Code');
const lastUpdatedDateTxtLocale = G.getWindowLocale('titles:last-updated-date', 'Last Updated Date');
const lastStatusReasonCodeTxtLocale = G.getWindowLocale('titles:last-status-reason-code', 'Last Status Reason Code');
const firstEventEarlyDateTxtLocale = `${G.getWindowLocale('titles:first-event', 'First Event')} ${
  G.getWindowLocale('titles:early-date', 'Early Date')}`;

// TODO: add colors to theme if needed
const markersSettings = {
  [GC.TRANSPORTATION_MODE_TYPE_LTL]: {
    icon: I.ltl,
    background: '#B07968',
  },
  [GC.TRANSPORTATION_MODE_TYPE_AIR]: {
    icon: I.plain,
    background: '#54987B',
  },
  [GC.TRANSPORTATION_MODE_TYPE_RAIL]: {
    icon: I.train,
    background: '#347E95',
  },
  [GC.TRANSPORTATION_MODE_TYPE_OCEAN]: {
    icon: I.boat,
    background: '#578DD1',
  },
  [GC.TRANSPORTATION_MODE_TYPE_DRAYAGE]: {
    icon: I.crane,
    background: '#C85555',
  },
  [GC.TRANSPORTATION_MODE_TYPE_SMALL_TRUCK]: {
    icon: I.van,
    background: '#C656A0',
  },
  [GC.TRANSPORTATION_MODE_TYPE_TRUCK_LOAD]: {
    icon: I.truckLoad,
    background: '#8D57B7',
  },
  [GC.TRANSPORTATION_MODE_TYPE_BOX_SHIPMENTS]: {
    icon: I.parcel,
    background: '#B68F2D',
  },
};

const whiteColor = G.getTheme('colors.white');

const makeCustomMarkerByTransportationMode = ({ active, transportationModeType }: Object) => {
  const { icon, background } = R.pathOr({}, [transportationModeType], markersSettings);

  const iconColor = G.ifElse(active, background, whiteColor);

  const marker = () => (
    <Flex
      width='100%'
      height='100%'
      border='2px solid'
      borderRadius='50%'
      justifyContent='center'
      borderColor={iconColor}
      bg={G.ifElse(active, whiteColor, background)}
    >
      {icon(iconColor, '60%', '60%')}
    </Flex>
  );

  return new L.DivIcon({
    className: 'custom-marker',
    html: ReactDOMServer.renderToString(marker()),
    iconSize: L.point(...G.ifElse(active, [45, 45], [30, 30]), true),
  });
};

const iconCreateFunction = (cluster: Object) => {
  const count = cluster.getChildCount();

  let clusterSize = 'small';

  if (R.gt(count, 10)) clusterSize = 'medium';

  if (R.gt(count, 100)) clusterSize = 'large';

  const clusterPosition = cluster.getBounds().getCenter();

  const locations = R.compose(
    R.map(R.prop('_latlng')),
    R.uniqBy(R.prop('_latlng')),
    R.append({ _latlng: clusterPosition }),
  )(cluster.getAllChildMarkers());

  const clusterGroup = JSON.stringify(locations);

  return L.divIcon({
    iconSize: L.point(40, 40, true),
    className: `${`marker-cluster marker-cluster-${clusterSize}`}`,
    html: `<div id=${clusterGroup} class='animated-clustering-group'><span>${count}</span></div>`,
  });
};

const makePopupContent = ({ firstEvent, lastStatusMessage, primaryReferenceValue }: Object) => (`
  <div
    style='font-size: 11px; max-width: 125px; font-weight: bold; word-break: break-all;'
  >
    <div style='color: ${G.getTheme('colors.light.blue')};'>${primaryReferenceValue}</div>
    ${G.ifElse(
      R.isNil(lastStatusMessage),
      `
        <div style='margin: 5px 0; word-break: break-all;'>${G.concatLocationFields(firstEvent)}</div>
        <div style='color: ${darkGreyColor}'>
          ${firstEventEarlyDateTxtLocale}:
        </div>
        ${G.getPropFromObject(GC.FIELD_LOAD_EVENT_EARLY_DATE, firstEvent)}
      `,
      `
        <div style='color: ${darkGreyColor}'>
          ${lastStatusCodeTxtLocale}:
        </div>
        ${G.getPropFromObject('statusCodeDisplayedValue', lastStatusMessage)}
        ${G.ifElse(
          G.isNotNilAndNotEmpty(G.getPropFromObject('statusReasonCodeDisplayedValue', lastStatusMessage)),
          `
            <div style='margin: 5px 0; color: ${darkGreyColor}'>
              ${lastStatusReasonCodeTxtLocale}:
            </div>
            ${G.getPropFromObject('statusReasonCodeDisplayedValue', lastStatusMessage)}
          `,
          '',
        )}
        <div style='margin: 5px 0; color: ${darkGreyColor}'>
          ${lastUpdatedDateTxtLocale}:
        </div>
        ${G.getPropFromObject(GC.FIELD_STATUS_MESSAGE_DATE, lastStatusMessage)}
      `,
  )}
  </div>
`);

const CustomMarker = (props: Object) => {
  const {
    map,
    active,
    location,
    setExpandedContainerOptions,
    activeClusteringGroupPosition,
  } = props;

  const { latLng, transportationModeType } = location;

  const ref = useRef(null);

  const { current } = ref;

  useEffect(() => {
    if (G.isNotNil(current)) {
      const { leafletElement: marker } = current;

      if (G.isNotNil(map)) {
        if (active) {
          if (marker._icon) return marker.openPopup();

          map.openPopup(
            makePopupContent(location),
            R.or(activeClusteringGroupPosition, marker.getLatLng()),
            { autoPan: false },
          );
        } else {
          map.closePopup();
        }
      }
    }
  }, [active, current]);

  return (
    <Marker ref={ref} icon={makeCustomMarkerByTransportationMode({ active, transportationModeType })} position={latLng}>
      <Popup autoPan={false}>
        <MarkerInfo {...location} setExpandedContainerOptions={setExpandedContainerOptions} />
      </Popup>
    </Marker>
  );
};

const LeafletMap = (props: Object) => {
  const {
    flyToGuid,
    locations,
    tableHeight,
    setFlyToGuid,
    activeLocation,
    setExpandedContainerOptions,
    activeClusteringGroupPosition,
  } = props;

  const mapRef = useRef(null);

  const map = R.pathOr({}, ['current', 'leafletElement'], mapRef);

  useEffect(() => {
    if (R.isNil(flyToGuid)) return;

    const location = R.find(R.propEq(flyToGuid, GC.FIELD_GUID), locations);

    setFlyToGuid(null);
    map.flyTo(location.latLng, 13, { duration: 2 });
    map.openPopup(makePopupContent(location), location.latLng);
  }, [flyToGuid]);

  return (
    <Map
      zoom={4}
      maxZoom={18}
      ref={mapRef}
      center={[38.755157, -98.269035]}
      // demo 3
      css={css`
        z-index: 1;
        width: 100%;
        height: ${G.ifElse(R.equals(tableHeight, 50), '55vh', '100vh')}
      `}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {
        G.isNotNilAndNotEmpty(locations) &&
        <MarkerClusterGroup iconCreateFunction={iconCreateFunction}>
          {
            locations.map((location: Object, index: number) => (
              <CustomMarker
                map={map}
                key={index}
                location={location}
                setExpandedContainerOptions={setExpandedContainerOptions}
                active={R.propEq(activeLocation, GC.FIELD_GUID, location)}
                activeClusteringGroupPosition={activeClusteringGroupPosition}
              />
            ))
          }
        </MarkerClusterGroup>
      }
    </Map>
  );
};

export default LeafletMap;
