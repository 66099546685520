import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { Toggle, FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature fuel-cards
import { getDriverOptions } from '../helpers';
import { toggleShowUnemployedDrivers } from '../actions';
import { makeSelectShowUnemployedDrivers } from '../selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  showUnemployedDrivers: makeSelectShowUnemployedDrivers(state),
});

const assignDriverField = {
  width: '300px',
  errorTop: '110%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  type: 'searchselect',
  flexDirection: 'column',
  options: 'driverOptions',
  errorPosition: 'absolute',
  loc: 'titles:select-driver',
  fieldName: GC.FIELD_DRIVER_GUID,
};

const enhance = compose(
  connect(mapStateToProps, { toggleShowUnemployedDrivers }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => initialValues,
    handleSubmit: ({ driverGuid }: Object, { props }: Object) => {
      const { guid, submitAction } = props;

      submitAction({ guid, driverGuid });
    },
  }),
  withHandlers({
    handleToggleShowUnemployedDrivers: (props: Object) => () => {
      const {
        setFieldValue,
        showUnemployedDrivers,
        toggleShowUnemployedDrivers,
      } = props;

      toggleShowUnemployedDrivers();

      if (showUnemployedDrivers) setFieldValue(GC.FIELD_DRIVER_GUID, null);
    },
  }),
  pure,
);

export const AssignDriverComponent = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Flex pl='5px' my={10}>
      <Toggle
        icons={false}
        checked={props.showUnemployedDrivers}
        onChange={props.handleToggleShowUnemployedDrivers}
      />
      <Box
        ml={10}
        cursor='pointer'
        onClick={props.handleToggleShowUnemployedDrivers}
      >
        {G.getWindowLocale('titles:show-all-driver', 'Show All Drivers')}
      </Box>
    </Flex>
    <FieldsetComponent
      fields={R.of(Array, assignDriverField)}
      {...G.getFormikPropsToFieldset(props)}
      optionsForSelect={{ driverOptions: getDriverOptions(props) }}
    />
    <FormFooter boxStyles={{ p: '5px' }} closeModal={props.closeModal} />
  </form>
);

export default enhance(AssignDriverComponent);
