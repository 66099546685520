import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as GC from '../../constants';
// feature route
import * as A from './actions';
/////////////////////////////////////////////////

export const initialState = {
  configsByNames: {},
  crossDockLocationList: [],
  configs: {
    [GC.UI_CONFIG_GROUP]: {},
    [GC.CLO_CONFIG_GROUP]: {},
    [GC.GENERAL_CONFIG_GROUP]: {},
    [GC.COMMUNICATION_CONFIG_GROUP]: {},
  },
};

const getDispatchBoardConfigsSuccess = (state: Object, data: Array) => (
  P.$set(`configs.${data.groupName}`, data.res, state)
);

const getCrossDockLocationsSuccess = (state: Object, data: Array) => (
  P.$set('crossDockLocationList', data, state)
);

const getConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('configsByNames', data, state)
);

export default createReducer({
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [A.getCrossDockLocationsSuccess]: getCrossDockLocationsSuccess,
  [A.getDispatchBoardConfigsSuccess]: getDispatchBoardConfigsSuccess,
}, initialState);
