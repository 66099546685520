import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
// components
import { ProgressBar } from '../../../components/progress-bar';
// helpers
import * as G from '../../../helpers';
// feature components-story
import { setProgressValue } from '../actions';
import { makeSelectProgressValue } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ProgressBarComponent = ({ progressValue, setProgressValue }: Object) => {
  const description = '<ProgressBar /> component is a visual representation of a progress bar.';

  const usage = (
    <ul>
      <li>Parameter `<i>progress</i>` represents the progress percentage to display.</li>
      <li>Parameter `<i>isShowProgress</i>` determines whether to display the progress percentage.</li>
    </ul>
  );

  useEffect(() => {
    if (R.lt(progressValue, 100)) {
      const intervalId = setInterval(() => {
        setProgressValue(R.inc(progressValue));
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [progressValue]);

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ProgressBar'
      description={description}
      path='src/components/progress-bar'
    >
      <ProgressBar
        isShowProgress={true}
        progress={progressValue}
        progressColor={G.getTheme('colors.navyBlue')}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  progressValue: makeSelectProgressValue(state),
});

export default connect(mapStateToProps, {
  setProgressValue,
})(ProgressBarComponent);
