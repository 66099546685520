import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const phoneNumber = (value: any, form: Object, _: Object, fieldName: string) => {
  if (G.isNotNilAndNotEmpty(value)) return G.isPhoneNumber(value);
  switch (fieldName) {
    case GC.FIELD_FLEET_MOBILE_PHONE:
      return G.ifElse(
        R.and(R.not(value), form.homePhone),
        undefined,
        'Please set Home or Mobile number',
      );
    case 'homePhone':
      return G.ifElse(
        R.and(R.not(value), form.mobilePhone),
        undefined,
        'Please set Home or Mobile number',
      );
    default:
      return 'Please set Home or Mobile number';
  }
};

export const licenseValidation = (value: any, form: Object, { stateActiveTab }: Object, fieldName: string) => {
  const fields = [
    'licenseState',
    'licenseClass',
    'licenseNumber',
    'licenseCountry',
    'expirationDate',
  ];
  if (R.and(fields.includes(fieldName), R.equals(stateActiveTab, 'docs'))) {
    return G.ifElse(
      G.isNotNilAndNotEmpty(form[fieldName]),
      undefined,
      G.getRequiredLocaleTxt(),
    );
  }
};

export const documentValidation = (value: any, form: Object, _: Object, fieldName: string) => {
  if (G.isNotNilAndNotEmpty(value)) return undefined;
  const errorMessage = G.getWindowLocale('message:validator:url-or-file', 'Please enter an URL or Upload File');
  switch (fieldName) {
    case GC.FIELD_DOCUMENT_URL:
      return G.ifElse(
        R.and(R.not(value), form.documentFilename),
        undefined,
        errorMessage,
      );
    case 'documentFilename':
      return G.ifElse(
        R.and(R.not(value), form.url),
        undefined,
        errorMessage,
      );
    default:
      return '';
  }
};

export const locationValidation = (value: any, form: Object, _: Object, fieldName: string) => {
  const fieldPrefix = G.ifElse(
    G.stringContains(fieldName, 'origin'),
    'origin',
    'destination',
  );
  const hasZipRange = R.or(
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}ZipRangeFrom`]),
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}ZipRangeTo`]),
  );
  const hasLocation = R.or(
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}City`]),
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}State`]),
  );
  if (hasZipRange) {
    return undefined;
  } else if (hasLocation) {
    return G.ifElse(G.isNotNilAndNotEmpty(value), undefined, ' ');
  } else if (R.and(G.isFalse(hasLocation), G.isFalse(hasZipRange))) {
    return ' ';
  }
};

export const zipRangeValidation = (value: any, form: Object, _: Object, fieldName: string) => {
  const fieldPrefix = G.ifElse(
    G.stringContains(fieldName, 'origin'),
    'origin',
    'destination',
  );
  const hasZipRange = R.or(
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}ZipRangeFrom`]),
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}ZipRangeTo`]),
  );
  const hasLocation = R.or(
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}City`]),
    G.isNotNilAndNotEmpty(form[`${fieldPrefix}State`]),
  );
  if (hasLocation) {
    return undefined;
  } else if (hasZipRange) {
    return G.ifElse(G.isNotNilAndNotEmpty(value), undefined, ' ');
  } else if (R.and(G.isFalse(hasLocation), G.isFalse(hasZipRange))) {
    return ' ';
  }
};
