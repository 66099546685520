import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet/driver
import {
  EDIT_DRIVER_DETAIL,
  DRIVER_DOCUMENT_FORM,
  DRIVER_CERTIFICATION_FORM,
} from './constants';
//////////////////////////////////////////////////

const selectFormStore = (state: Object) => (state.form);
const selectFleetDriversStore = (state: Object) => state.fleetDriver;

const makeSelectInitialValues = () => createSelector(
  selectFleetDriversStore,
  ({ driverHours, driverCitizen, driverLicense, currentDriver }: Object) => {
    if (G.isNilOrEmpty(currentDriver)) return null;

    return {
      ...driverHours,
      hoursGuid: driverHours.guid,
      ...driverCitizen,
      citizenGuid: driverCitizen.guid,
      ...driverLicense,
      licenseGuid: driverLicense.guid,
      ...currentDriver,
      driverVersion: driverHours.version,
      licenseVersion: driverLicense.version,
      citizenVersion: driverCitizen.version,
    };
  },
);

const makeSelectCurrentDriverGuid = () => createSelector(
  selectFleetDriversStore,
  ({ currentDriver }: Object) => G.getGuidFromObject(currentDriver),
);

const makeSelectCurrentDriverBranchGuid = () => createSelector(
  selectFleetDriversStore,
  ({ currentDriver }: Object) => G.getBranchGuidFromObject(currentDriver),
);

const makeSelectUsersGeneral = () => createSelector(
  selectFleetDriversStore,
  ({ usersGeneral }: Object) => usersGeneral,
);

const makeSelectDriverRefList = () => createSelector(
  selectFleetDriversStore,
  ({ references }: Object) => references,
);

const makeSelectDriverContactList = () => createSelector(
  selectFleetDriversStore,
  ({ contacts }: Object) => contacts,
);

const makeSelectLatestKnownLocationList = () => createSelector(
  selectFleetDriversStore,
  ({ latestKnownLocation }: Object) => latestKnownLocation,
);

const makeSelectIntegrationList = () => createSelector(
  selectFleetDriversStore,
  ({ integrationList }: Object) => integrationList,
);

const makeSelectCollapsedGroup = () => createSelector(
  selectFleetDriversStore,
  ({ collapsedGroup }: Object) => collapsedGroup,
);

const makeSelectDeductionSettingsList = () => createSelector(
  selectFleetDriversStore,
  ({ deductionSettingsList }: Object) => deductionSettingsList,
);

const makeSelectDriverConfigs = () => createSelector(
  selectFleetDriversStore,
  ({ driverConfigs }: Object) => ({
    citizen: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_CITIZEN),
    contactType: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_CONTACT_TYPE),
    documentType: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_DOCUMENT_TYPE),
    outOfDutyReason: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_OUT_DUTY_REASON),
    domicileTerminal: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_DOMICILE_TERMINAL),
    assignedDivision: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_ASSIGNED_DIVISION),
    unemploymentReason: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_UNEMPLOYED_REASON),
    certificationType: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_CERTIFICATION_TYPE),
    certificationName: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_CERTIFICATION_NAME),
    // safety violation
    safetyViolationType: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_SAFETY_VIOLATION_TYPE),
    safetyViolationSeverity: G.mapDropdownConfigWithParentGuid(driverConfigs, GC.DRIVER_SAFETY_VIOLATION_SEVERITY),
    safetyViolationDisciplinaryAction: G.mapDropdownConfigWithParentGuid(
      driverConfigs,
      GC.DRIVER_SAFETY_VIOLATION_DISCIPLINARY_ACTION,
    ),
  }),
);

const makeSelectDriverFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => (R.pathOr({}, [EDIT_DRIVER_DETAIL, 'values'], form)));

const makeSelectDriverCertification = () => (createSelector(
  selectFleetDriversStore,
  ({ certifications }: Object) => certifications,
));

const makeSelectAssignDriver = () => (createSelector(
  selectFleetDriversStore,
  ({ assignInfo }: Object) => assignInfo,
));

const makeSelectDriveDocumentsFilter = () => createSelector(
  selectFleetDriversStore,
  ({ documentsFilter }: Object) => documentsFilter,
);

const makeSelectDriverDocuments = () => createSelector(
  selectFleetDriversStore,
  ({ documents, documentsFilter }: Object) => {
    if (G.isAnyNilOrEmpty([documents, documentsFilter])) return documents;

    return R.filter(
      ({ documentType }: Object) => R.propEq(documentsFilter, GC.FIELD_DROPDOWN_OPTION_GUID, documentType),
      documents,
    );
  },
);

const makeSelectDriverAccessorials = () => createSelector(
  selectFleetDriversStore,
  ({ accessorials }: Object) => accessorials,
);

const makeSelectDriverCompensations = () => (createSelector(
  selectFleetDriversStore,
  ({ compensations }: Object) => compensations,
));

const makeSelectAssignInfo = () => (createSelector(
  selectFleetDriversStore,
  ({ assignInfo }: Object) => assignInfo,
));

const makeSelectAvailableDrivers = () => (createSelector(
  selectFleetDriversStore,
  ({ availableDrivers }: Object) => availableDrivers,
));

const makeSelectAvailableTrucks = () => (createSelector(
  selectFleetDriversStore,
  ({ availableTrucks }: Object) => availableTrucks,
));

const makeSelectAvailableTrailers = () => (createSelector(
  selectFleetDriversStore,
  ({ availableTrailers }: Object) => availableTrailers,
));

const makeSelectDriverDocFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => (R.pathOr({}, [DRIVER_DOCUMENT_FORM, 'values'], form)));

const makeSelectDriverCertificationFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => (R.pathOr({}, [DRIVER_CERTIFICATION_FORM, 'values'], form)));

const makeSelectDriverDropDowns = () => (createSelector(
  selectFleetDriversStore,
  ({ driverConfigs }: Object) => driverConfigs.dropdowns,
));

const makeSelectFormErrors = () => createSelector(
  selectFormStore,
  (form: Object) => (
    G.ifElse(
      G.isNotNil(form, EDIT_DRIVER_DETAIL),
      G.getOrElse(form[EDIT_DRIVER_DETAIL], 'syncErrors', {}),
      {},
    )),
);

const makeSelectFuelCardList = () => createSelector(
  selectFleetDriversStore,
  ({ fuelCards }: Object) => fuelCards,
);

const makeSelectPayrollAssessorialList = () => createSelector(
  selectFleetDriversStore,
  ({ payrollAssessorialList }: Object) => payrollAssessorialList,
);

const makeSelectAdvancePaymentList = () => createSelector(
  selectFleetDriversStore,
  ({ advancePaymentList }: Object) => R.sortBy(R.prop('index'), R.values(advancePaymentList)),
);

const makeSelectDriverDailyHours = () => createSelector(
  selectFleetDriversStore,
  R.compose(
    R.sort((first: Object, second: Object) => R.subtract(
      R.prop(GC.FIELD_LOG_DATE, first),
      R.prop(GC.FIELD_LOG_DATE, second),
    )),
    R.pathOr([], ['driverDailyHours']),
  ),
);

const makeSelectPayrollCompensationRate = () => createSelector(
  selectFleetDriversStore,
  ({ payrollCompensationRate }: Object) => payrollCompensationRate,
);

const makeSelectBranchConfigs = () => createSelector(
  selectFleetDriversStore,
  ({ branchConfigs }: Object) => branchConfigs,
);

const makeSelectMonthlyPayrollDeduction = () => createSelector(
  selectFleetDriversStore,
  ({ monthlyPayrollDeduction }: Object) => monthlyPayrollDeduction,
);

const makeSelectFilterParams = () => createSelector(
  selectFleetDriversStore,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectSafetyViolation = () => createSelector(
  selectFleetDriversStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr([], ['safetyViolation']),
  ),
);

const makeSelectFleetVendorList = () => createSelector(
  selectFleetDriversStore,
  R.path(['fleetVendorList']),
);

export {
  makeSelectAssignInfo,
  makeSelectFormErrors,
  makeSelectUsersGeneral,
  makeSelectFuelCardList,
  makeSelectFilterParams,
  makeSelectAssignDriver,
  makeSelectInitialValues,
  makeSelectBranchConfigs,
  selectFleetDriversStore,
  makeSelectDriverRefList,
  makeSelectDriverConfigs,
  makeSelectCollapsedGroup,
  makeSelectDriverDocuments,
  makeSelectAvailableTrucks,
  makeSelectDriverDropDowns,
  makeSelectIntegrationList,
  makeSelectSafetyViolation,
  makeSelectFleetVendorList,
  makeSelectDriverFormValues,
  makeSelectAvailableDrivers,
  makeSelectDriverDailyHours,
  makeSelectAvailableTrailers,
  makeSelectDriverContactList,
  makeSelectCurrentDriverGuid,
  makeSelectAdvancePaymentList,
  makeSelectDriverAccessorials,
  makeSelectDriverCertification,
  makeSelectDriverDocFormValues,
  makeSelectDriverCompensations,
  makeSelectDriveDocumentsFilter,
  makeSelectDeductionSettingsList,
  makeSelectPayrollAssessorialList,
  makeSelectPayrollCompensationRate,
  makeSelectLatestKnownLocationList,
  makeSelectMonthlyPayrollDeduction,
  makeSelectCurrentDriverBranchGuid,
  makeSelectDriverCertificationFormValues,
};
