import * as R from 'ramda';
import React from 'react';
import { Field } from 'redux-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import {
  Error,
  styles,
  FormGroup,
  ErrorsWrapper,
  renderFormGroup,
} from '../../../../forms';
// feature fleet
import { setOptions } from '../../helpers';
import { setRenderComponent } from '../../index';
// feature fleet trailer
import { Label } from '../ui';
//////////////////////////////////////////////////

export const renderField = (props: Object, field: Object, index: number) => (
  <Field
    key={index}
    width='242px'
    direction='row'
    margin='5px 10px'
    errorFontSize={14}
    labelFontSize={16}
    labelMargin='5px 0'
    labelPosition='left'
    inputs={field.inputs}
    errorMargin='0 0 5px 0'
    validate={field.validate}
    disabled={field.disabled}
    type={R.or(field.type, '')}
    isClearable={field.isClearable}
    additionClass={styles.inputGroup}
    label={G.getWindowLocale(field.name)}
    component={setRenderComponent(field.type)}
    onChangeAddress={props.handleChangeAddress}
    onSelectAddress={props.handleSelectAddress}
    options={setOptions(props.trailerConfigs, field)}
    additionalLabelComponent={field.additionalLabelComponent}
    name={R.pathOr(R.prop(GC.FIELD_NAME, field), ['nameForAttribute'], field)}
  />
);

const renderMultipleErrors = (props: Object, field: Name) => (
  G.isNotNilAndNotEmpty(props.fieldsErrors)
  && (
    <ErrorsWrapper>
      {
        R.values(R.pathOr([], ['fieldsErrors', field.name], props)).map((error: string, index: number) => (
          <Error key={index} fontSize={14}>{error}</Error>
        ))
      }
    </ErrorsWrapper>
  )
);

export const renderFormGroupWithMultipleInputs = (
  props: Object,
  field: Object,
  index: number,
) => (
  <FormGroup
    key={index}
    direction='row'
    className={styles.inputGroup}
  >
    {
      G.notEquals(G.getWindowLocale(field.name), 'no-locale')
      && <Label m='5px 0' fontSize={16}>{G.getWindowLocale(field.name)}</Label>
    }
    {
      field.inputs.map((input: Object, inputIndex: number) => {
        const withError = G.isNotNil(
          G.getOrElse(
            G.getOrElse(props.fieldsErrors, R.prop(GC.FIELD_NAME, field), null),
            input.nameForAttribute,
            null,
          ));
        return (
          <Field
            key={inputIndex}
            margin='5px 10px'
            errorFontSize={14}
            labelPosition='left'
            padding='0 0 0 15px'
            withError={withError}
            errorMargin='0 0 5px 0'
            options={input.options}
            inputWidth={input.width}
            validate={input.validate}
            separator={field.separator}
            isClearable={field.isClearable}
            additionClass={styles.inputGroup}
            type={G.setFieldType(field.type)}
            width={R.or(input.width, '242px')}
            withLabel={R.equals(inputIndex, 0)}
            component={renderFormGroup(`${input.type}multiple`)}
            withSeparator={R.and(field.separator, input.separate)}
            name={R.pathOr(R.prop(GC.FIELD_NAME, input), ['nameForAttribute'], input)}
            onClearError={() => props.handleClearFieldError(field.name, input.nameForAttribute)}
            onHasError={(error: string) => props.handleSetFieldError(
              {
                error,
                groupName: R.prop(GC.FIELD_NAME, field),
                [GC.FIELD_NAME]: input.nameForAttribute,
              },
            )}
          />);
      })
    }
    {renderMultipleErrors(props, field)}
  </FormGroup>
);
