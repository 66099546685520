import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { triumphFactoringPaymentTermTypeOptions } from '../../../helpers/options';
// forms
import { FieldsetComponent } from '../../../forms';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: 270,
  errorTop: '110%',
  afterTop: '14px',
  errorTitle: true,
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorWhiteSpace: 'nowrap',
  errorPosition: 'absolute',
  errorTextOverflow: 'ellipsis',
};

const fieldSettings = [
  {
    ...defaultFieldStyles,
    type: 'select',
    loc: 'titles:factoring-type',
    fieldName: GC.FIELD_FACTORING_TYPE,
    options: [
      {
        label: 'Triumph',
        value: GC.FACTORING_COMPANY_TYPE_TRIUMPH,
      },
    ],
  },
  {
    ...defaultFieldStyles,
    type: 'select',
    loc: 'titles:factoring-payment-term',
    fieldName: GC.FIELD_FACTORING_PAYMENT_TERM,
    options: triumphFactoringPaymentTermTypeOptions,
  },
];

const defaultFields = {
  [GC.FIELD_FACTORING_TYPE]: GC.FACTORING_COMPANY_TYPE_TRIUMPH,
  [GC.FIELD_FACTORING_PAYMENT_TERM]: GC.FACTORING_PAYMENT_TERM_TYPE_TRIUMPH_STANDARD_PAY,
};

const enhance = compose(
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const CarrierPaymentTermForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      fields={fieldSettings}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(CarrierPaymentTermForm);
