import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, call, take, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import * as A from '../actions';
import {
  makeSelectVinLookup,
  makeSelectGeneralDetails,
  makeSelectEntityBranchGuid,
} from '../selectors';
//////////////////////////////////////////////////

const configNames = [
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
];

function* getTruckBranchConfigsRequest() {
  try {
    const branchGuid = yield select(makeSelectEntityBranchGuid());

    const options = {
      params: {
        names: R.join(', ', configNames),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getTruckBranchConfigsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getTruckBranchConfigsRequest exception');
  }
}

function* getTruckGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getCurrentTruckEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getTruckGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getTruckGeneralDetailsRequest exception');
  }
}


function* syncTruckByVINRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_TRUCK_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.syncTruck, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'syncTruckByVINRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'syncTruckByVINRequest exception');
  }
}

function* updateTruckGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const vinLookup = yield select(makeSelectVinLookup());
    const initialValues = yield select(makeSelectGeneralDetails());

    const { values, options, handleClose, saveAndClose } = payload;

    const { guid, serialNumber } = values;

    if (R.and(vinLookup, R.eqProps(GC.FIELD_SERIAL_NUMBER, values, initialValues))) {
      yield put(closeLoader());
      yield put(A.setVinLookup(false));

      if (G.isNilOrEmpty(serialNumber)) {
        return yield call(G.showToastrMessage, 'error', 'messages:serial-number-is-required');
      }

      return yield call(syncTruckByVINRequest, { payload: guid });
    }

    const res = yield call(sendRequest, 'put', endpointsMap.truck, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (vinLookup) {
        yield delay(1000);

        yield put(closeLoader());
        yield put(A.setVinLookup(false));

        yield call(syncTruckByVINRequest, { payload: guid });

        return;
      }

      if (R.pathEq(true, ['options', 'shouldCloseModal'], payload)) {
        yield put(closeModal());
      }

      if (saveAndClose) {
        yield put(closeLoader());

        G.callFunction(handleClose);

        return false;
      }

      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      G.callFunctionWithArgs(options.setSubmitting, false);

      yield call(G.handleFailResponse, res, 'updateTruckGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateTruckGeneralDetailsRequest exception');
  }
}

// daily log
function* getDailyLogListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: payload,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.truckDailyLogList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDailyLogListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDailyLogListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDailyLogListRequest exception');
  }
}

// visit page
function* visitFleetTruckProfilePage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_PROFILE_TRUCK_PAGE);

    yield put(A.setInitialState('truck'));

    yield fork(getTruckGeneralDetailsRequest, { payload: G.getGuidFromObject(payload) });

    const generalDetails = yield take(A.getGeneralDetailsSuccess);

    const branchGuid = R.path(['payload', GC.BRANCH_GUID], generalDetails);

    yield put(A.getFleetProfileConfigsRequest({ [GC.BRANCH_GUID]: branchGuid, group: GC.TRUCK_CONFIG_GROUP }));

    break;
  }
}

export function* fleetProfileDriverWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_FLEET_PROFILE_TRUCK_PAGE, visitFleetTruckProfilePage);
  // general
  yield takeLatest(A.getTruckGeneralDetailsRequest, getTruckGeneralDetailsRequest);
  yield takeLatest(A.updateTruckGeneralDetailsRequest, updateTruckGeneralDetailsRequest);
  // configs
  yield takeLatest(A.getTruckBranchConfigsRequest, getTruckBranchConfigsRequest);
  // daily log
  yield takeLatest(A.getDailyLogListRequest, getDailyLogListRequest);
}

export default fleetProfileDriverWatcherSaga;
