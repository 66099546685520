import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../components';
// features
import {
  defaultCreateContractFields,
  validationSchemaCreateContract,
} from '../../carrier/settings/fields-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature contracts
import { createContractFormFields, createContractFormFieldsAsync } from '../settings/fields-settings';
//////////////////////////////////////////////////

const contracts = ['carrierContracts', 'customerContracts'];

const setCarrierGuidToValues = (pageType: string, carrierGuid: string, values: Object) => G.ifElse(
  R.includes(pageType, contracts),
  values,
  R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, values),
);

const getFields = (pageType: string) => G.ifElse(
  R.includes(pageType, contracts),
  createContractFormFieldsAsync,
  createContractFormFields,
);

const enhanceContractForm = withHandlers({
  handleSendCreateContract: (props: Object) => async (data: Object, contractType: string) => {
    const { closeModal, openLoader, closeLoader, callbackAction } = props;

    const endpoint = G.ifElse(
      G.isContractTypeCustomer(contractType),
      endpointsMap.customerContract,
      endpointsMap.carrierContract,
    );

    try {
      openLoader({ showDimmer: true });

      const res = await sendRequest('post', endpoint, { data });

      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple(
          'success',
          G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
        );

        callbackAction(res.data);
        closeModal();
      } else {
        G.handleFailResponseSimple(res);
      }
    } catch (error) {
      G.catchSendRequestSimple(error, 'handleCreateCarrierContract');
    } finally {
      closeLoader();
    }
  },
});

const enhance = compose(
  withState('transportationModeOptions', 'setTransportationModeOptions', []),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaCreateContract,
    mapPropsToValues: ({ initialValues }: Object) =>
      G.setInitialFormikValues(defaultCreateContractFields, initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        pageType,
        carrierGuid,
        contractType,
        handleSendCreateContract,
      } = props;

      const data = setCarrierGuidToValues(pageType, carrierGuid, values);

      handleSendCreateContract(data, contractType);
    },
  }),
  withHandlers({
    handleCustomChange: (props: Object) => (fieldValue: Object) => {
      const {
        values,
        setValues,
        optionsForSelect,
        setTransportationModeOptions,
      } = props;

      const newValues = {
        [GC.FIELD_MODE]: null,
        [GC.FIELD_CARRIER_GUID]: fieldValue,
      };

      setValues(R.mergeRight(values, newValues));
      setTransportationModeOptions(G.mapDropdownObjectPropsToLabelValueObject(
        R.pathOr([], [fieldValue, 'transportationModes'], R.prop(GC.FIELD_MODE, optionsForSelect))));
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        carrierGuid,
        optionsForSelect,
        setTransportationModeOptions,
      } = this.props;

      if (G.isNotNilAndNotEmpty(carrierGuid)) {
        setTransportationModeOptions(
          R.prop(GC.FIELD_MODE, optionsForSelect),
        );
      }
    },
  }),
  pure,
);

export const CreateContractForm = enhanceContractForm(enhance((props: Object) => {
  const {
    pageType,
    handleSubmit,
    optionsForSelect,
    handleCustomChange,
    transportationModeOptions,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={getFields(pageType)}
        fieldsWrapperStyles={{ mt: 10 }}
        handleCustomChange={handleCustomChange}
        transportationModes={transportationModeOptions}
        roleOptions={R.prop('roleOptions', optionsForSelect)}
        carrierOptions={R.prop('carrierOptions', optionsForSelect)}
      />
      <FormFooter2 />
    </form>
  );
}));
