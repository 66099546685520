import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useMemo, useCallback } from 'react';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { customerIntegrationConfigTypes } from '../../../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 290,
};

const defaultCustomerIntegrationConfigFields = {
  loadSendingClientId: null,
  loadSendingCarrierId: null,
  loadSendingCarrierName: null,
  loadSendingClientSecret: null,
  loadSendingReferenceGuid: null,
  loadSendingAccountNumber: null,
  trackingFrequencyInMinutes: 15,
  [GC.FIELD_ACCOUNT_NUMBER]: null,
  hotLoadTrackingFrequencyInMinutes: 15,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_ENABLED]: true,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_SHIPPER]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_CODE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_SECRET]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_DOT_NUMBER]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_LOAD_NUMBER_REFERENCE_GUID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE]:
    GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_FOUR_KITES,
};

const validationSchema = Yup.lazy((values: Object) => {
  const { version, integrationType, enabledLoadSending } = values;

  const isEditMode = R.isNotNil(version);

  let schema = {
    [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_NAME]: G.yupStringRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_ID]: G.yupStringRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE]: G.yupStringRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_LOAD_NUMBER_REFERENCE_GUID]: G.yupStringRequired,
  };

  if (G.isFalse(isEditMode)) {
    schema = R.assoc(
      GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_SECRET,
      G.yupStringRequired,
      schema,
    );
  }

  if (R.equals(integrationType, GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_FOUR_KITES)) {
    schema = R.assoc(
      GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_SHIPPER,
      G.yupStringRequired,
      schema,
    );
  }

  if (R.equals(integrationType, GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_CH_ROBINSON)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_CODE]: G.yupStringRequired,
        [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_NAME]: G.yupStringRequired,
        [GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_DOT_NUMBER]: G.yupStringRequired,
      },
    );
  }

  if (R.equals(integrationType, GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_MACRO_POINT)) {
    schema = {
      ...schema,
      [GC.FIELD_ACCOUNT_NUMBER]: G.yupStringRequired,
    };

    if (enabledLoadSending) {
      schema = {
        ...schema,
        loadSendingClientId: G.yupStringRequired,
        loadSendingReferenceGuid: G.yupStringRequired,
        loadSendingAccountNumber: G.yupStringRequired,
        loadSendingClientSecret: G.ifElse(G.isFalse(isEditMode), G.yupStringRequired),
        loadSendingCarrierId: G.yupStringRequired.max(50, G.getShouldBeFromToCharLocaleTxt(0, 50)),
        loadSendingCarrierName: G.yupStringRequired.max(50, G.getShouldBeFromToCharLocaleTxt(0, 50)),
        trackingFrequencyInMinutes: G.yupNumberRequired
          .min(15, G.getShouldBeFromToLocaleTxt(15, 1440))
          .max(1440, G.getShouldBeFromToLocaleTxt(15, 1440)),
        hotLoadTrackingFrequencyInMinutes: G.yupNumberRequired
          .min(15, G.getShouldBeFromToLocaleTxt(15, 1440))
          .max(1440, G.getShouldBeFromToLocaleTxt(15, 1440)),
      };
    }
  }

  return Yup.object().shape(schema);
});

const CustomerIntegrationConfigsForm = (props: Object) => {
  const {
    isEditMode,
    submitAction,
    initialValues,
    optionsForSelect,
  } = props;

  const onSubmit = useCallback((values: Object) => {
    const { integrationType, enabledLoadSending } = values;

    const commonFields = [
      ...GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR,
      GC.FIELD_NAME,
      GC.FIELD_ENABLED,
      GC.FIELD_CLIENT_ID,
      GC.FIELD_CLIENT_SECRET,
      GC.FIELD_INTEGRATION_TYPE,
      GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_LOAD_NUMBER_REFERENCE_GUID,
    ];

    const fieldsMap = {
      [GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_PROJECT_44]: commonFields,
      [GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_FOUR_KITES]: [
        ...commonFields,
        GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_SHIPPER,
      ],
      [GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_CH_ROBINSON]: [
        ...commonFields,
        GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_CODE,
        GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_NAME,
        GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_DOT_NUMBER,
      ],
      [GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_MACRO_POINT]: [
        ...commonFields,
        'enabledLoadSending',
        GC.FIELD_ACCOUNT_NUMBER,
        ...G.ifElse(
          enabledLoadSending,
          [
            'loadSendingClientId',
            'loadSendingCarrierId',
            'loadSendingCarrierName',
            'loadSendingClientSecret',
            'loadSendingReferenceGuid',
            'loadSendingAccountNumber',
            'trackingFrequencyInMinutes',
            'enabledLoadSendingAutomatically',
            'hotLoadTrackingFrequencyInMinutes',
          ],
          [],
        ),
      ],
    };

    const data = R.pick(R.prop(integrationType, fieldsMap), values);

    submitAction(data);
  }, []);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: G.setInitialFormikValues(
      defaultCustomerIntegrationConfigFields,
      initialValues,
    ),
  });

  const { handleSubmit, values: { integrationType, enabledLoadSending } = {} } = formik;

  const isMacroPoint = R.equals(integrationType, GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_MACRO_POINT);
  const isMacroPointAdditionalFieldsVisible = R.and(isMacroPoint, enabledLoadSending);

  const fieldSettings = useMemo(() => {
    const isP44 = R.equals(integrationType, GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_PROJECT_44);
    const isCHRobinson = R.equals(integrationType, GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_CH_ROBINSON);

    const commonFields = [
      {
        type: 'toggle',
        inputWrapperStyles,
        label: ['titles:active'],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_ENABLED,
      },
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: ['titles:config-name'],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_NAME,
      },
      {
        type: 'select',
        isRequired: true,
        disabled: isEditMode,
        label: ['titles:integration-type'],
        options: customerIntegrationConfigTypes,
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE,
        inputWrapperStyles: {
          ...inputWrapperStyles,
          mr: G.ifElse(isMacroPointAdditionalFieldsVisible, 31),
        },
      },
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: [G.ifElse(isP44, 'titles:client-id', 'titles:login')],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_ID,
      },
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: [G.ifElse(isP44, 'titles:client-secret', 'titles:password')],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_SECRET,
      },
      {
        type: 'text',
        isRequired: true,
        label: ['titles:shipper-name'],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_SHIPPER,
        inputWrapperStyles: {
          ...inputWrapperStyles,
          display: G.ifElse(
            R.equals(integrationType, GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_FOUR_KITES),
            'flex',
            'none',
          ),
        },
      },
      {
        type: 'text',
        isRequired: true,
        options: 'cloReferenceTypes',
        label: ['titles:account-number'],
        fieldName: GC.FIELD_ACCOUNT_NUMBER,
        inputWrapperStyles: {
          ...inputWrapperStyles,
          display: G.ifElse(isMacroPoint, 'flex', 'none'),
        },
      },
      {
        type: 'text',
        isRequired: true,
        label: ['titles:carrier-name'],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_NAME,
        inputWrapperStyles: {
          ...inputWrapperStyles,
          display: G.ifElse(isCHRobinson, 'flex', 'none'),
        },
      },
      {
        type: 'text',
        isRequired: true,
        label: ['titles:carrier-code'],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_CODE,
        inputWrapperStyles: {
          ...inputWrapperStyles,
          display: G.ifElse(isCHRobinson, 'flex', 'none'),
        },
      },
      {
        type: 'text',
        isRequired: true,
        label: ['titles:carrier-dot-number'],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_DOT_NUMBER,
        inputWrapperStyles: {
          ...inputWrapperStyles,
          display: G.ifElse(isCHRobinson, 'flex', 'none'),
        },
      },
      {
        type: 'select',
        isRequired: true,
        options: 'cloReferenceTypes',
        label: ['titles:clo-reference'],
        fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_LOAD_NUMBER_REFERENCE_GUID,
        inputWrapperStyles: {
          width: 290,
          mb: G.ifElse(isMacroPointAdditionalFieldsVisible, 15, 25),
        },
      },
      {
        type: 'toggle',
        fieldName: 'enabledLoadSending',
        label: ['titles:enabled-order-sending', 'Enabled Order Sending'],
        inputWrapperStyles: {
          ...inputWrapperStyles,
          display: G.ifElse(isMacroPoint, 'flex', 'none'),
        },
      },
    ];

    if (isMacroPointAdditionalFieldsVisible) {
      return [
        ...commonFields,
        {
          type: 'toggle',
          inputWrapperStyles,
          fieldName: 'enabledLoadSendingAutomatically',
          label: ['titles:enabled-order-sending-automatically', 'Enabled Order Sending Automatically'],
        },
        {
          type: 'text',
          isRequired: true,
          inputWrapperStyles,
          fieldName: 'loadSendingAccountNumber',
          label: ['titles:order-sending-account-number', 'Order Sending Account Number'],
        },
        {
          type: 'text',
          isRequired: true,
          inputWrapperStyles,
          fieldName: 'loadSendingClientId',
          label: ['titles:order-sending-client-id', 'Order Sending Client ID'],
        },
        {
          type: 'text',
          isRequired: true,
          inputWrapperStyles,
          fieldName: 'loadSendingClientSecret',
          label: ['titles:order-sending-client-secret', 'Order Sending Client Secret'],
        },
        {
          type: 'select',
          isRequired: true,
          inputWrapperStyles,
          options: 'cloReferenceTypes',
          fieldName: 'loadSendingReferenceGuid',
          label: ['titles:order-sending-reference', 'Order Sending Reference'],
        },
        {
          type: 'text',
          isRequired: true,
          inputWrapperStyles,
          fieldName: 'trackingFrequencyInMinutes',
          label: ['titles:tracking-frequency-in-minutes', 'Tracking Frequency in Minutes'],
        },
        {
          type: 'text',
          isRequired: true,
          inputWrapperStyles,
          fieldName: 'hotLoadTrackingFrequencyInMinutes',
          label: ['titles:hot-order-tracking-frequency-in-minutes', 'Hot Order Tracking Frequency in Minutes'],
        },
        {
          type: 'text',
          isRequired: true,
          fieldName: 'loadSendingCarrierName',
          label: ['titles:carrier-name-override', 'Carrier Name Override'],
          inputWrapperStyles: {
            ...inputWrapperStyles,
            display: G.ifElse(isMacroPoint, 'flex', 'none'),
          },
        },
        {
          type: 'text',
          isRequired: true,
          fieldName: 'loadSendingCarrierId',
          label: ['titles:carrier-id-override', 'Carrier ID Override'],
          inputWrapperStyles: {
            ...inputWrapperStyles,
            display: G.ifElse(isMacroPoint, 'flex', 'none'),
          },
        },
      ];
    }

    return commonFields;
  }, [integrationType, enabledLoadSending]);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        {...optionsForSelect}
        fields={fieldSettings}
        optionsForSelect={optionsForSelect}
        handlers={{ handleDisableIntegrationType: () => isEditMode }}
        fieldsWrapperStyles={{
          mt: 10,
          justifyContent: 'space-between',
          width: G.ifElse(isMacroPointAdditionalFieldsVisible, 610, 290),
        }}
      />
      <FormFooter />
    </form>
  );
};

export default CustomerIntegrationConfigsForm;
