import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature icons
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  searchFilter: '',
};

export const setSearchFilter = (state: Object, search: string) => (
  P.$set('searchFilter', search, state)
);

export default createReducer({
  [A.setSearchFilter]: setSearchFilter,
}, initialState);
