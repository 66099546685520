import * as Yup from 'yup';
import * as R from 'ramda';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
//////////////////////////////////////////////////

const validationSchema = Yup.object().shape({
  [GC.FIELD_EMAIL]: Yup.string().nullable(true).email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBeFromToLocaleTxt(0, 250))
    .max(250, G.getShouldBeFromToLocaleTxt(0, 250)),
  [GC.FIELD_OWNER_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_TARGET_RPM]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 100))
    .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
  [GC.FIELD_VENDOR_GST_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 100))
    .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
  [GC.FIELD_CARRIER_USDOT_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 12))
    .max(12, G.getShouldBeFromToLocaleTxt(0, 12)),
  [GC.FIELD_CARRIER_MC_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 12))
    .max(12, G.getShouldBeFromToLocaleTxt(0, 12)),
  [GC.FIELD_ACCOUNT_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_FLEET_PHONE_NUMBER]: Yup.string()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
  [GC.FIELD_NOTES]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 2000))
    .max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
});

const accountingPermissions = [PC.FLEET_VENDOR_ACCOUNTING_READ, PC.FLEET_VENDOR_ACCOUNTING_WRITE];

const additionalInputWrapperSettings = {
  width: '19%',
  minWidth: 115,
};

const settings = {
  [GC.FIELD_NAME]: {
    type: 'text',
    isRequired: true,
    name: 'titles:company-name',
    additionalInputWrapperSettings,
  },
  [GC.FIELD_OWNER_NAME]: {
    type: 'text',
    isRequired: true,
    name: 'titles:owner-name',
    additionalInputWrapperSettings,
  },
  [GC.FIELD_CARRIER_DBA]: {
    type: 'text',
    name: 'titles:dba',
    additionalInputWrapperSettings,
  },
  [GC.FIELD_EMAIL]: {
    type: 'text',
    name: 'titles:email',
    additionalInputWrapperSettings,
  },
  [GC.FIELD_VENDOR_GST_NUMBER]: {
    type: 'text',
    name: 'titles:gst-number',
    additionalInputWrapperSettings,
  },
  [GC.FIELD_CARRIER_USDOT_NUMBER]: {
    type: 'text',
    name: 'titles:usdot-number',
  },
  [GC.FIELD_CARRIER_MC_NUMBER]: {
    type: 'text',
    name: 'titles:mc-number',
  },
  [GC.FIELD_ACCOUNT_NUMBER]: {
    type: 'text',
    name: 'titles:account-number',
  },
  [GC.FIELD_CARRIER_DUNS_NUMBER]: {
    type: 'text',
    name: 'titles:duns-number',
  },
  [GC.FIELD_FEDERAL_EIN]: {
    type: 'text',
    name: 'titles:federal-ein',
  },
  [GC.FIELD_ADDRESS]: {
    zIndex: 12,
    name: 'titles:address1',
    type: 'addressAutocomplete',
    addressFieldName: GC.FIELD_ADDRESS,
    additionalInputWrapperSettings: { zIndex: 11 },
  },
  [GC.FIELD_ADDRESS_2]: {
    type: 'text',
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    type: 'text',
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    type: 'text',
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    type: 'text',
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    type: 'countrySelect',
    name: 'titles:country',
  },
  [GC.FIELD_TARGET_RPM]: {
    type: 'text',
    name: 'titles:target-rpm',
  },
  [GC.FIELD_BANK_ACCOUNT_NUMBER]: {
    name: 'titles:bank-account-number',
    disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_VENDOR_ACCOUNTING_WRITE),
    additionalInputWrapperSettings: {
      visibility: () => G.ifElse(
        G.hasNotAmousCurrentUserPermissions(accountingPermissions),
        'hidden',
      ),
    },
  },
  [GC.FIELD_BANK_ROUTING_NUMBER]: {
    name: 'titles:bank-routing-number',
    disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_VENDOR_ACCOUNTING_WRITE),
    additionalInputWrapperSettings: {
      visibility: () => G.ifElse(
        G.hasNotAmousCurrentUserPermissions(accountingPermissions),
        'hidden',
      ),
    },
  },
  [GC.FIELD_FLEET_PHONE_NUMBER]: {
    type: 'phoneNumber',
    name: 'titles:phone-number',
  },
  [GC.FIELD_NOTES]: {
    type: 'textarea',
    name: 'titles:notes',
    inputStyles: { p: 15 },
    additionalInputWrapperSettings: { mb: '0px', width: '100%', maxWidth: 'unset' },
  },
};

const fieldsWrapperStyles = { mt: 10, justifyContent: 'space-between' };

const defaultInputWrapperStyles = {
  mb: 25,
  width: '19%',
  maxWidth: 280,
  minWidth: 125,
};

const fieldSettings = R.map((fieldName: string) => {
  const field = R.pathOr({}, [fieldName], settings);

  const { name, inputStyles, additionalLabelComponent, additionalInputWrapperSettings } = field;

  return {
    ...field,
    fieldName,
    inputStyles,
    errorWidth: '100%',
    label: R.of(Array, name),
    additionalLabelComponent,
    inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperSettings },
  };
}, R.keys(settings));

export const generalDetailsSettings = {
  validationSchema,
  fieldsGroups: [{ fieldSettings: R.drop(5, fieldSettings), fieldsWrapperStyles }],
  firstFields: {
    fieldSettings: R.take(5, fieldSettings),
    fieldsWrapperStyles: { width: '100%', justifyContent: 'space-between' },
  },
};
