import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import { LoadReferencesForm } from '../../../reference/components/load-references-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import { removeLoadReferenceRequest, updateLoadReferenceRequest } from '../actions';
//////////////////////////////////////////////////

export const withAddReference = compose(
  withHandlers({
    handleAddReference: (props: Object) => () => {
      const { load, openModal, closeModal, createLoadReferenceRequest } = props;
      const { loadType } = load;

      const initialValues = {
        [GC.FIELD_REFERENCES]: R.of(Array, {
          [GC.FIELD_VALUE]: null,
          [GC.FIELD_PRIMARY]: false,
          [GC.FIELD_REFERENCE_TYPE_GUID]: null,
        }),
      };
      const component = (
        <LoadReferencesForm
          loadType={loadType}
          closeModal={closeModal}
          useSingleReference={true}
          initialValues={initialValues}
          title={G.getWindowLocale('titles:add-reference', 'Add Reference')}
          submitAction={({ references }: Object) => createLoadReferenceRequest(R.head(references))}
        />
      );
      const modal = {
        p: '0',
        component,
        options: {
          width: 550,
          height: 'auto',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withReferenceTableGroupRowActions = compose(
  connect(
    null,
    {
      updateLoadReferenceRequest,
      removeLoadReferenceRequest,
    },
  ),
  withHandlers({
    handleEditReference: (props: Object) => () => {
      const { load, entity, openModal, closeModal, updateLoadReferenceRequest } = props;
      const { loadType } = load;

      const initialValues = {
        [GC.FIELD_REFERENCES]: R.of(Array, entity),
      };

      const component = (
        <LoadReferencesForm
          loadType={loadType}
          closeModal={closeModal}
          useSingleReference={true}
          initialValues={initialValues}
          title={G.getWindowLocale('titles:edit-reference', 'Edit Reference')}
          submitAction={({ references }: Object) => updateLoadReferenceRequest(R.head(references))}
        />
      );
      const modal = {
        p: '0',
        component,
        options: {
          width: 550,
          height: 'auto',
        },
      };

      openModal(modal);
    },
    handleRemoveReference: ({ entity, openModal, removeLoadReferenceRequest }: Object) => () => {
      const { guid, name, primary } = entity;

      if (primary) {
        return G.showToastrMessageFromLocale('info', 'messages:references:warning-message-2');
      }

      const component = (
        <ConfirmComponent
          name={name}
          textLocale={G.getWindowLocale('messages:before:remove')}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeLoadReferenceRequest(guid),
              name: G.getWindowLocale('actions:remove', 'Remove'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);
