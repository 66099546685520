import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import BranchSearch from './branch-search';
import { getTripStatusOptions } from '../../../components/filter/settings';
import {
  SelectMui,
  DatePickerMui,
  QuickFilterOutsideControl3,
  PlacesAutocompleteInputMui,
} from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ActionButton, RelativeFlex } from '../../../ui';
// features drivers-card
import { enhanceActions } from '../hocs';
import UnassignedCard from './unassigned-card';
import { getAmousBranchesOptions } from '../helpers';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const UnassignedLoadsList = (props: Object) => {
  const {
    openModal,
    closeModal,
    enterprise,
    openLoader,
    branchList,
    closeLoader,
    setStatuses,
    searchRadius,
    setEnterprise,
    searchDateValue,
    setSearchRadius,
    unassignedLoads,
    expandedContainer,
    setReferenceValue,
    handleSearchAction,
    setSearchDateValue,
    handleClickEditIcon,
    handleOpenTelDetails,
    toggleUnassignedLoads,
    handleAddTelDriverRate,
    setSearchPlaceLocation,
    searchPlaceAutocomplete,
    setSearchPlaceAutocomplete,
    handlePlaceAutocompleteResultCallback,
  } = props;

  const radiusPlaceholder = G.getDistanceUomLocale();
  const searchDisabled = G.isNilOrEmpty(searchRadius);

  return (
    <Box
      px={10}
      width={500}
      height='100%'
      overflow='auto'
      bg={G.getTheme('colors.light.mainLight')}
    >
      <Flex p={15} mb={10} justifyContent='space-between'>
        <Box fontSize='20px' fontWeight={600} color={darkBlueColor}>
          {G.getWindowLocale('titles:unassigned-loads', 'Unassigned Loads')}
        </Box>
        <Box cursor='pointer' onClick={toggleUnassignedLoads}>{I.closeIcon(darkBlueColor)}</Box>
      </Flex>
      <RelativeFlex mb={20} zIndex={17}>
        <PlacesAutocompleteInputMui
          width={150}
          withCloseIcon={true}
          useOutsideValue={true}
          inputWrapperStyles={{ mr: 15 }}
          onCloseCallback={() => {
            setSearchPlaceLocation(null);
            setSearchPlaceAutocomplete(null);
          }}
          outsideValue={searchPlaceAutocomplete}
          setOutsideValue={setSearchPlaceAutocomplete}
          resultCallback={handlePlaceAutocompleteResultCallback}
          label={G.getWindowLocale('titles:search-location', 'Search Location')}
        />
        <QuickFilterOutsideControl3
          delayTime={300}
          inputStyles={{ width: 80 }}
          outsideValue={searchRadius}
          placeholder={radiusPlaceholder}
          handleSetFilter={setSearchRadius}
          label={G.getWindowLocale('titles:radius', 'Radius')}
        />
        <DatePickerMui
          width={100}
          m='0px 15px'
          isClearable={true}
          value={searchDateValue}
          withCalendarIcon={false}
          useNewMuiInputField={true}
          label={G.getWindowLocale('titles:search-date', 'Search Date')}
          onChange={(date: Object) => G.isNilOrEmpty(date) && setSearchDateValue(null)}
          onAccept={(date: Object) =>
            G.isNotNilAndNotEmpty(date) && setSearchDateValue(G.convertInstanceToDefaultDateFormat(date))}
        />
        <QuickFilterOutsideControl3
          delayTime={300}
          inputStyles={{ width: 105 }}
          handleSetFilter={setReferenceValue}
          label={`${G.getWindowLocale('titles:filter-by', 'Filter By')} ${G.getWindowLocale('titles:ref', 'Ref:')}`}
        />
      </RelativeFlex>
      <Flex mb={15} justifyContent='space-between' gap={10} color={G.getTheme('colors.greyMatterhorn')}>
        <BranchSearch
          value={enterprise}
          selectHandler={setEnterprise}
          options={getAmousBranchesOptions()}
        />
        <SelectMui
          wrapperStyles={{ width: 175 }}
          options={getTripStatusOptions()}
          handleChangeCallback={setStatuses}
          label={G.getWindowLocale('titles:status-filter', 'Status Filter')}
        />
        <ActionButton
          height={32}
          p='4px 8px'
          fontSize={12}
          borderRadius='5px'
          disabled={searchDisabled}
          textTransform='uppercase'
          textColor={darkBlueColor}
          onClick={handleSearchAction}
          bgColor={G.getTheme('colors.white')}
          border={`1px solid ${darkBlueColor}`}
        >
          {G.getWindowLocale('titles:search', 'Search')}
        </ActionButton>
      </Flex>
      {
        G.isNotNilAndNotEmpty(unassignedLoads) &&
        unassignedLoads.map((item: Object) => (
          <UnassignedCard
            {...item}
            openModal={openModal}
            closeModal={closeModal}
            openLoader={openLoader}
            branchList={branchList}
            closeLoader={closeLoader}
            key={G.getGuidFromObject(item)}
            expandedContainer={expandedContainer}
            handleClickEditIcon={handleClickEditIcon}
            handleOpenTelDetails={handleOpenTelDetails}
            handleAddTelDriverRate={() => handleAddTelDriverRate(item)}
          />
        ))
      }
    </Box>
  );
};

const getUnassignedLoadsRequestOptions = (props: Object) => {
  const {
    statuses,
    enterprise,
    searchRadius,
    filtersStore,
    referenceValue,
    searchDateValue,
    searchPlaceLocation,
  } = props;
  const { dateTo, dateFrom, byCurrentUser } = filtersStore;

  const radius = G.ifElse(G.isNotNilAndNotEmpty(searchRadius), G.toNumber(searchRadius), 0);
  const enterpriseGuid = G.ifElse(Array.isArray(enterprise), null, enterprise);

  return {
    data: {
      ...filtersStore,
      radius,
      statuses,
      byCurrentUser,
      enterpriseGuid,
      referenceValue,
      showLoader: true,
      date: searchDateValue,
      location: searchPlaceLocation,
      currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      dateTo: G.createLocalDateTimeFromInstanceOrISOString(dateTo, G.getDateTimeFormat(true)),
      dateFrom: G.createLocalDateTimeFromInstanceOrISOString(dateFrom, G.getDateTimeFormat(true)),
    },
  };
};

export const enhance = compose(
  enhanceActions,
  withState('statuses', 'setStatuses', []),
  withState('enterprise', 'setEnterprise', null),
  withState('searchRadius', 'setSearchRadius', 100),
  withState('referenceValue', 'setReferenceValue', ''),
  withState('searchDateValue', 'setSearchDateValue', null),
  withState('searchPlaceLocation', 'setSearchPlaceLocation', null),
  withState('searchPlaceAutocomplete', 'setSearchPlaceAutocomplete', null),
  withHandlers({
    handlePlaceAutocompleteResultCallback: (props: Object) => (result: Object) => {
      const { setSearchPlaceLocation } = props;

      if (G.isNilOrEmpty(result)) return;

      const { latitude, longitude } = result;

      const location = { latitude, longitude };

      setSearchPlaceLocation(location);
    },
    handleSearchAction: (props: Object) => () => {
      const { searchRadius, getUnassignedLoadsRequest } = props;

      if (G.isNilOrEmpty(searchRadius)) return;

      getUnassignedLoadsRequest(getUnassignedLoadsRequestOptions(props));
    },
  }),
  lifecycle({
    componentDidMount() {
      const props = this.props;

      const { getUnassignedLoadsRequest } = props;

      getUnassignedLoadsRequest(getUnassignedLoadsRequestOptions(props));
    },
    componentDidUpdate(prevProps: Object) {
      if (R.equals(prevProps.filtersStore, this.props.filtersStore)) return;

      this.props.getUnassignedLoadsRequest(getUnassignedLoadsRequestOptions(this.props));
    },
  }),
  pure,
);

export default enhance(UnassignedLoadsList);
