import React from 'react';
import * as R from 'ramda';
// features
import { commonBtnStyles } from '../../drivers-card/components/header';
// helpers/constants
import * as G from '../../../helpers';
import { ENUMS } from '../../../constants/enums';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, ActionButton } from '../../../ui';
//////////////////////////////////////////////////

export const RowActions = (props: Object) => {
  const { data, userName, handleOpenNotes, handleDeleteReservation, handleCreateOrUpdateReservation } = props;

  const { note, status, reserved, reservedBy } = data;

  const iconColor = G.getThemeByCond(
    G.isNotNilAndNotEmpty(note),
    'colors.mainBlue',
    'colors.light.grey',
  );

  const isReservedByCurrentUser = R.and(G.isTrue(reserved), R.equals(userName, reservedBy));

  const btnTitle = G.getWindowLocale(...G.ifElse(
    isReservedByCurrentUser,
    ['actions:edit', 'Edit'],
    ['actions:reserve', 'Reserve'],
  ));

  const isUnavailableOrReservedByAnotherUser = R.or(
    R.equals(status, ENUMS.ENUM_UNAVAILABLE),
    R.and(G.isTrue(reserved), G.notEquals(userName, reservedBy)),
  );

  const btnColor = G.getThemeByCond(isUnavailableOrReservedByAnotherUser, 'colors.dark.darkGrey', 'colors.dark.blue');

  return (
    <Flex width={170} justifyContent='flex-end'>
      <Flex
        mr={15}
        cursor='pointer'
        title={G.getWindowLocale('titles:notes', 'Notes')}
        onClick={(event: Object) => handleOpenNotes(event, data)}
      >
        {I.renderFileIcon(iconColor, 18, 22)}
      </Flex>
      {
        isReservedByCurrentUser &&
        <Flex
          mr={15}
          cursor='pointer'
          onClick={() => handleDeleteReservation(data)}
          title={G.getWindowLocale('titles:delete-reservation', 'Delete Reservation')}
        >
          {I.clean(G.getTheme('colors.mainBlue'), 18, 22)}
        </Flex>
      }
      <ActionButton
        {...commonBtnStyles}
        height={26}
        width={100}
        fontSize={12}
        textColor={btnColor}
        borderColor={btnColor}
        disabled={isUnavailableOrReservedByAnotherUser}
        onClick={() => handleCreateOrUpdateReservation(data)}
        cursor={G.ifElse(isUnavailableOrReservedByAnotherUser, 'not-allowed', 'pointer')}
      >
        {btnTitle}
      </ActionButton>
    </Flex>
  );
};
