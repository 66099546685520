import React from 'react';
// components
import {
  getEventTypeOptions,
  getLoadStatusOptions,
  getStopStatusOptions,
} from '../../../../components/filter/settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { DEFAULT_QUANTITY_RATE_UNIT_OPTIONS } from '../../../../helpers/options';
// feature dispatch-report
import { TableField } from '../../components/table-fields';
//////////////////////////////////////////////////

export const columnSettings = {
  // EVENTS
  [GC.FIELD_EVENT_TYPE]: {
    width: 200,
    type: 'enum',
    name: ['titles:event', 'titles:type'],
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    name: ['titles:event', 'titles:status'],
  },
  [GC.FIELD_LATE_DATE]: {
    name: ['titles:event', 'titles:late-date'],
  },
  [GC.FIELD_EARLY_DATE]: {
    name: ['titles:event', 'titles:early-date'],
  },
  [GC.FIELD_APPOINTMENT_LATE_DATE]: {
    name: ['titles:event', 'titles:appointment-late-date'],
  },
  [GC.FIELD_APPOINTMENT_EARLY_DATE]: {
    name: ['titles:event', 'titles:appointment-early-date'],
  },
  [GC.FIELD_EVENT_COMPLETE_DATE]: {
    name: ['titles:event', 'titles:complete-date'],
  },
  [GC.FIELD_STOP_NUMBER]: {
    width: 200,
    name: 'titles:pickup-drop-number',
  },
  [GC.GRC.LOCATION_ZIP]: {
    width: 200,
    name: ['titles:location', 'titles:zip'],
  },
  [GC.GRC.LOCATION_CITY]: {
    width: 200,
    name: ['titles:location', 'titles:city'],
  },
  [GC.GRC.LOCATION_STATE]: {
    width: 200,
    name: ['titles:location', 'titles:state'],
  },
  [GC.GRC.LOCATION_COUNTRY]: {
    width: 200,
    name: ['titles:location', 'titles:country'],
  },
  [GC.GRC.LOCATION_NAME]: {
    width: 200,
    name: ['titles:location', 'titles:location-name'],
  },
  [GC.GRC.LOCATION_ADDRESS1]: {
    width: 200,
    name: ['titles:location', 'titles:address1'],
  },
  [GC.GRC.LOCATION_TEMPLATE_ID]: {
    width: 200,
    name: ['titles:location', 'titles:template-id'],
  },
  [GC.GRC.LOCATION_TYPE]: {
    width: 200,
    name: ['titles:location', 'titles:location-type'],
  },
  // CLO
  [GC.GRC.CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    name: ['titles:clo', 'titles:primary-ref-value'],
  },
  [GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER]: {
    width: 200,
    name: ['titles:clo', 'titles:container-number'],
  },
  [GC.GRC.CLO_CONTAINERS_CONTAINER_INITIAL]: {
    width: 200,
    name: ['titles:clo', 'titles:container-initial'],
  },
  [GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL]: {
    width: 200,
    name: ['titles:clo', 'titles:container-number-with-initial'],
  },
  [GC.GRC.CLO_STATUS]: {
    width: 200,
    type: 'enum',
    name: ['titles:clo', 'titles:status'],
  },
  [GC.GRC.CLO_CREATED_BY]: {
    width: 200,
    name: ['titles:clo', 'titles:created-by'],
  },
  [GC.GRC.CLO_CREATED_DATE]: {
    type: 'date',
    name: ['titles:clo', 'titles:created-date'],
    customComponent: ({ data }: Object) =>
      G.createLocalDateTimeFromInstanceOrISOString(
        data[GC.GRC.CLO_CREATED_DATE],
        G.getDateTimeFormat(true),
      ),
  },
  [GC.GRC.CLO_BRANCH_NAME]: {
    width: 200,
    name: ['titles:clo', 'titles:branch'],
  },
  [GC.GRC.CLO_STORED_TOTAL_WEIGHT]: {
    width: 200,
    type: GC.FIELD_WEIGHT,
    name: 'titles:order-total-weight',
  },
  [GC.GRC.CLO_PINNED_NOTE_TEXT]: {
    width: 200,
    name: ['titles:clo', 'titles:pinned-note'],
  },
  [GC.GRC.CLO_PACKAGE_TYPE]: {
    width: 200,
    type: 'enum',
    name: ['titles:clo', 'titles:package-type'],
  },
  [GC.GRC.CLO_QUANTITY]: {
    width: 200,
    name: ['titles:clo', 'titles:quantity'],
  },
  // TEL
  [GC.GRC.TEL_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    name: ['titles:tel', 'titles:primary-ref-value'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_NAME]: {
    width: 200,
    name: 'titles:carrier-name',
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER]: {
    width: 200,
    name: ['titles:carrier', 'titles:primary-driver'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER]: {
    width: 200,
    name: ['titles:carrier', 'titles:secondary-driver'],
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_TRUCK]: {
    width: 200,
    name: 'titles:carrier-truck',
  },
  [GC.GRC.TEL_CARRIER_ASSIGNMENT_TRAILER]: {
    width: 200,
    name: 'titles:carrier-trailer',
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME]: {
    width: 200,
    name: ['titles:primary-driver', 'titles:first-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME]: {
    width: 200,
    name: ['titles:primary-driver', 'titles:last-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID]: {
    width: 200,
    name: ['titles:primary-driver', 'titles:login-id'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FULL_NAME]: {
    width: 200,
    name: ['titles:primary-driver', 'titles:full-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME]: {
    width: 200,
    name: ['titles:secondary-driver', 'titles:first-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME]: {
    width: 200,
    name: ['titles:secondary-driver', 'titles:last-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID]: {
    width: 200,
    name: ['titles:secondary-driver', 'titles:login-id'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FULL_NAME]: {
    width: 200,
    name: ['titles:secondary-driver', 'titles:full-name'],
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_TRUCK_UNIT_ID]: {
    width: 200,
    name: 'titles:driver-truck',
  },
  [GC.GRC.TEL_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID]: {
    width: 200,
    name: 'titles:driver-trailer',
  },
  [GC.GRC.TEL_RATE_STORED_DEADHEAD_DISTANCE]: {
    width: 200,
    name: 'titles:deadhead-distance',
    customComponent: (data: Object) =>
      <TableField data={data} type={GC.FIELD_DISTANCE} />,
  },
  [GC.GRC.TEL_STORED_TOTAL_DISTANCE]: {
    width: 200,
    name: 'titles:trip-distance',
    customComponent: (data: Object) =>
      <TableField data={data} type={GC.FIELD_DISTANCE} />,
  },
  [GC.GRC.TEL_TOTAL_INCOME]: {
    width: 200,
    name: 'titles:total-income',
  },
  [GC.GRC.TEL_RATE_TOTAL]: {
    width: 120,
    name: ['titles:rate', 'titles:total'],
  },
  [GC.GRC.TEL_MARGIN]: {
    width: 200,
    name: 'titles:margin',
  },
};

export const FILTER_PARAMS = [
  // EVENTS
  {
    type: 'selectMultiple',
    value: GC.FIELD_EVENT_TYPE,
    options: getEventTypeOptions(),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: `${G.getWindowLocale('titles: event', 'Event')}: ${
      G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_STATUS,
    options: getStopStatusOptions(),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: `${G.getWindowLocale('titles: event', 'Event')}: ${
      G.getWindowLocale('titles:status', 'Status')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_LATE_DATE,
    name: `${G.getWindowLocale('titles: event', 'Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_EARLY_DATE,
    name: `${G.getWindowLocale('titles: event', 'Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_APPOINTMENT_LATE_DATE,
    name: `${G.getWindowLocale('titles: event', 'Event')}: ${
      G.getWindowLocale('titles:appointment-late-date', 'Appointment LATE Date')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles: event', 'Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles: event', 'Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_STOP_NUMBER,
    name: G.getWindowLocale('titles:pickup-drop-number', 'Pickup drop number'),
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_CITY,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_STATE,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_NAME,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:location', 'Location')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  // CLO
  {
    type: 'string',
    value: GC.GRC.CLO_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:container-number', 'Container Number')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CONTAINERS_CONTAINER_INITIAL,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:container-initial', 'Container Initial')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:container-number-with-initial', 'Container Number With Initial')}`,
  },
  {
    type: 'selectMultiple',
    value: GC.GRC.CLO_STATUS,
    options: getLoadStatusOptions(),
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:status', 'Status')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CREATED_BY,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:created-by', 'Created By')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_CREATED_DATE,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:created-date', 'Created Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:branch', 'Branch')}`,
  },
  {
    type: 'number',
    value: GC.GRC.CLO_STORED_TOTAL_WEIGHT,
    name: G.getWindowLocale('titles:order-total-weight', 'Order Total Weight'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_PINNED_NOTE_TEXT,
    name: `${G.getWindowLocale('titles:clo', 'Order')}:
      ${G.getWindowLocale('titles:pinned-note', 'Pinned Note')}`,
  },
  {
    type: 'selectMultiple',
    value: GC.GRC.CLO_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    placeholder: { text: 'Choose a status', key: 'titles:choose-a-status' },
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:package-type', 'Package Type')}`,
  },
  {
    type: 'number',
    value: GC.GRC.CLO_QUANTITY,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:quantity', 'Quantity')}`,
  },
  // TEL
  {
    type: 'string',
    value: GC.GRC.TEL_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles: tel', 'Trip')}: ${
      G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_SNAPSHOT_NAME,
    name: G.getWindowLocale('titles:carrier-name', 'Carrier Name'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:primary-driver', 'Primary Driver')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER,
    name: `${G.getWindowLocale('titles:carrier', 'Carrier')}: ${
      G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_TRUCK,
    name: G.getWindowLocale('titles:carrier-truck', 'Carrier Truck'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CARRIER_ASSIGNMENT_TRAILER,
    name: G.getWindowLocale('titles:carrier-trailer', 'Carrier Trailer'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_PR_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
    name: G.getWindowLocale('titles:driver-truck', 'Driver Truck'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
    name: G.getWindowLocale('titles:driver-trailer', 'Driver Trailer'),
  },
  {
    type: 'number',
    value: GC.GRC.TEL_RATE_STORED_DEADHEAD_DISTANCE,
    name: G.getWindowLocale('titles:deadhead-distance', 'Deadhead Distance'),
  },
  {
    type: 'number',
    value: GC.GRC.TEL_STORED_TOTAL_DISTANCE,
    name: G.getWindowLocale('titles:trip-distance', 'Trip Distance'),
  },
  {
    type: 'number',
    value: GC.GRC.TEL_TOTAL_INCOME,
    name: G.getWindowLocale('titles:total-income', 'Total Income'),
  },
  {
    type: 'number',
    value: GC.GRC.TEL_RATE_TOTAL,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
      G.getWindowLocale('titles:total', 'Total')}`,
  },
  {
    type: 'number',
    value: GC.GRC.TEL_MARGIN,
    name: G.getWindowLocale('titles:margin', 'Margin'),
  },
];
