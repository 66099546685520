import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature carrier-edi
import ActionsCell from '../components/actions-cell';
import InvoiceNumberCell from '../components/invoice-number-cell';
//////////////////////////////////////////////////

export const columnSettings = {
  actions: {
    width: 120,
    customComponent: (entity: Object) => <ActionsCell entity={entity} />,
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    sortable: false,
    searchable: true,
    name: 'titles:status',
  },
  [GC.FIELD_EDI_PROCESSED]: {
    width: 150,
    type: 'boolean',
    searchable: true,
    name: 'titles:processed',
    customComponent: ({ data }: Object) => G.ifElse(
      R.prop(GC.FIELD_EDI_PROCESSED, data),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [GC.FIELD_EDI_SENDER_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:sender-id',
  },
  [GC.FIELD_EDI_EXTERNAL_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:external-id',
  },
  [GC.FIELD_EDI_ORIGINAL_FILE_NAME]: {
    width: 250,
    searchable: true,
    name: 'titles:file-name',
  },
  [GC.FIELD_EDI_RECEIVED_DATE]: {
    width: 200,
    type: 'date',
    searchable: true,
    name: 'titles:received-date',
  },
  [GC.FIELD_EDI_TRANSACTION_TYPE]: {
    width: 200,
    type: 'enum',
    sortable: false,
    searchable: true,
    name: 'titles:transaction-type',
  },
  [GC.FIELD_EDI_TRANSACTION_DATE]: {
    width: 200,
    type: 'date',
    searchable: true,
    name: 'titles:transaction-date',
  },
  [GC.GRC.INVOICE_INVOICE_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:invoice-number',
    customComponent: ({ data }: Object) => (
      <InvoiceNumberCell
        entity={data}
        fromPage={GC.PAGE_INVOICE_EDI_REPORT}
        invoiceNumber={R.pathOr(null, [GC.GRC.INVOICE_INVOICE_NUMBER], data)}
      />
    ),
  },
  [GC.GRC.INVOICE_INVOICE_DATE]: {
    type: 'date',
    searchable: true,
    name: 'titles:invoice-date',
  },
  [GC.GRC.INVOICE_INVOICE_TOTAL]: {
    width: 200,
    searchable: true,
    name: 'titles:invoice-total',
  },
  [GC.GRC.INVOICE_INVOICE_CURRENCY]: {
    width: 200,
    searchable: true,
    name: 'titles:invoice-currency',
  },
  [GC.GRC.TEL_INFO_TEL_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    searchable: true,
    name: 'titles:tel-number',
    guidPropName: GC.GRC.TEL_INFO_TEL_GUID,
    type: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
  },
  [GC.FIELD_EDI_SHIPMENT_EXTERNAL_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:shipment-external-id',
  },
  [GC.FIELD_DIRECTION]: {
    width: 150,
    type: 'enum',
    sortable: false,
    searchable: true,
    name: 'titles:direction',
  },
};

export const statusMessageColumnSettings = {
  [GC.FIELD_STATUS_CODE]: {
    width: 200,
    name: 'titles:detail',
  },
  [GC.FIELD_DATE_TIME]: {
    width: 130,
    name: 'titles:date',
  },
  [GC.FIELD_LOCATION]: {
    width: 250,
    name: 'titles:location-stop',
  },
  [GC.FIELD_STATUS_MESSAGE_REASON_CODE]: {
    width: 200,
    name: 'titles:status-reason-code',
  },
  [GC.FIELD_STOP_SEQUENCE_NUMBER]: {
    width: 150,
    name: 'titles:stop-sequence-number',
  },
};
