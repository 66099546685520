import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { InputWithHandlers } from '../../../components/input-with-handlers';
// ui
import { Text } from '../../../ui';
// feature components-story
import { setInputValue } from '../actions';
import { makeSelectInputValue } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const InputWithHandlersComponent = ({ inputValue, setInputValue }: Object) => {
  const description = '<InputWithHandlers /> component is designed to handle input events when interacting with input fields.';

  const usage = (
    <ul>
      <li>Parameter `<i>value</i>` is the value of the input.</li>
      <li>Parameter `<i>placeholder</i>` is a placeholder text for the input field.</li>
      <li>Parameter `<i>styles</i>` is a styling properties for the input component.</li>
      <li>Parameter `<i>validate</i>` is a validation function to check input validity.</li>
      <li>Parameter `<i>onChange</i>` is a callback function triggered on input change.</li>
      <li>Parameter `<i>submitValidation</i>` indicates whether to perform validation after submission.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='InputWithHandlers'
      description={description}
      path='src/components/input-with-handlers'
    >
      <InputWithHandlers
        value={inputValue}
        placeholder='type text'
        onChange={setInputValue}
        styles={{
          width: 120,
          p: '0 3px',
          height: 20,
          border: 'none',
          borderRadius: '0px',
          borderBottom: '1px solid',
        }}
      />
      {inputValue && <Text mt={10}>{inputValue}</Text>}
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  inputValue: makeSelectInputValue(state),
});

export default connect(mapStateToProps, {
  setInputValue,
})(InputWithHandlersComponent);
