import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// feature fleet
import LatestKnownLocationFormComponent from '../components/latest-known-location-form';
//////////////////////////////////////////////////

export const withAddOrEditLastLocation = compose(
  withHandlers({
    handleClickAddOrEditLatestKnownLocation: (props: Object) => (values: Object = null) => {
      const {
        openModal,
        openLoader,
        branchGuid,
        closeModal,
        closeLoader,
        handleActionForAddOrEditLastLocationHoc,
      } = props;

      const modalContext = (
        <LatestKnownLocationFormComponent
          openModal={openModal}
          initialValues={values}
          openLoader={openLoader}
          branchGuid={branchGuid}
          closeModal={closeModal}
          closeLoader={closeLoader}
          submitAction={handleActionForAddOrEditLastLocationHoc}
        />
      );

      const modal = {
        p: '0',
        component: modalContext,
        options: { height: 'auto' },
      };

      openModal(modal);
    },
  }),
);

export default withAddOrEditLastLocation;
