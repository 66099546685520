import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// component
import CommonModal from '../../../components/modal';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { enhanceLocationWithGoogleAutocomplete } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  RelativeBox,
  AbsoluteBox,
  IconWrapper,
} from '../../../ui';
import { centerGeoPoint } from '../helpers';
import { CarrierDetailFormWrapper } from '../ui';
import ChatModalWindowComponent from './chat-window';
import DocModalComponent from './add-doc-mob-carrier-form';
import TrackingFormComponent from './tracking-form-component';
import StopModalComponent, { MobMapComponent } from './route-stop-detail-mob-page';
import {
  checkInSettings,
  defaultTrackingSettings,
  getInitialValidationSchema,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const cobaltColor = G.getTheme('colors.cobalt');
const silverColor = G.getTheme('colors.silver');
const greenColor = G.getTheme('colors.light.green');
const mainRedColor = G.getTheme('colors.light.mainRed');

const calcWidth = (mappedEventsForMap: Array = [], index: number) => (
  G.ifElse(R.equals(R.subtract(R.length(mappedEventsForMap), 1), index), '0', '2px')
);

async function fillAddressInput(props: Object) {
  try {
    const { setInitialValues, setFormattedAddress, setGeoLocationProcess } = props;

    const { coords } = await G.getGeolocation();

    const result = await G.googleMapsGeocode(coords, 'carrier-portal CarrierDetailPage');

    if (G.isNilOrEmpty(result)) return;

    const fields = G.getGoogleGeocodeByAddressDataFromResult(result);

    const {
      zip,
      city,
      state,
      country,
      address1,
      latitude,
      longitude,
    } = fields;

    setFormattedAddress(`${city}, ${state}`);
    setGeoLocationProcess(true);

    setInitialValues({
      [GC.FIELD_ZIP]: zip,
      [GC.FIELD_CITY]: city,
      [GC.FIELD_STATE]: state,
      [GC.FIELD_COUNTRY]: country,
      [GC.FIELD_ADDRESS]: address1,
      [GC.FIELD_LATITUDE]: latitude,
      [GC.FIELD_LONGITUDE]: longitude,
    });
  } catch (error) {
    console.error(error);

    props.setGeoLocationProcess(false);
  }
}

const enhance = compose(
  withState('file', 'setFile', null),
  withState('isMapOpened', 'toggleMap', true),
  withState('blockHeight', 'setBlockHeight', {}),
  withState('openMobMap', 'setOpenMobMap', false),
  withState('initialValues', 'setInitialValues', null),
  withState('formattedAddress', 'setFormattedAddress', null),
  withState('infoPanelIsOpen', 'toggleInfoPanelIsOpen', true),
  withState('geoLocationProcess', 'setGeoLocationProcess', false),
  withState('isSettedUpStatusLocation', 'setStatusLocation', true),
  enhanceLocationWithGoogleAutocomplete,
  withHandlers({
    handleOpenChatModal: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <ChatModalWindowComponent
          closeModal={closeModal}
          title={G.getWindowLocale('titles:communication', 'Communication')}
        />
      );

      const modal = {
        component,
        options: {
          width: '100%',
          height: '100%',
          movable: false,
          maxWidth: '768px',
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleOpenStopModal: (props: Object) => (stop: Object) => {
      const {
        openModal,
        closeModal,
        setOpenMobMap,
        detailPageInfo,
        carrierMobView,
        statusReasonCodes,
        handleOpenChatModal,
        addCarrierStatusMessage,
      } = props;

      const title = `Stop ${R.path(['telEventIndex'], stop)}: ${R.path(['eventType'], stop)}`;

      const getOption = (options: Object) => {
        let statusMessages;

        if (R.equals(stop.eventType, GC.EVENT_TYPE_PICKUP)) {
          statusMessages = R.filter(
            (code: Object) => R.equals(code.statusType, GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED),
            options,
          );
        }

        if (R.equals(stop.eventType, GC.EVENT_TYPE_DROP)) {
          statusMessages = R.filter(
            (code: Object) => R.equals(code.statusType, GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED),
            options,
          );
        }

        return statusMessages;
      };

      const modalContent = (
        <StopModalComponent
          stop={stop}
          title={title}
          openModal={openModal}
          closeModal={closeModal}
          fields={checkInSettings}
          setOpenMobMap={setOpenMobMap}
          carrierMobView={carrierMobView}
          statusReasonCodes={statusReasonCodes}
          handleOpenChatModal={handleOpenChatModal}
          loadGuid={R.path(['guid'], detailPageInfo)}
          addCarrierStatusMessage={addCarrierStatusMessage}
          allStatusCodes={R.pathOr([], ['statusCodeConfigs'], detailPageInfo)}
          statusCodeConfigs={getOption(R.path(['statusCodeConfigs'], detailPageInfo))}
          allowStopCompleteWithoutArrival={detailPageInfo.allowStopCompleteWithoutArrival}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          width: '100%',
          movable: false,
          height: '100%',
          maxWidth: '768px',
        },
      };

      openModal(modal);
    },
    handleOpenCheckInModal: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        branchGuid,
        carrierToken,
        initialValues,
        carrierMobView,
        detailPageInfo,
        formattedAddress,
        statusReasonCodes,
        geoLocationProcess,
        addCarrierStatusMessage,
      } = props;

      const getOption = (options: Object) => {
        if (G.isNilOrEmpty(options)) return [];

        const statusMessages = R.filter(
          ({ statusType }: Object) => R.and(
            G.notEquals(statusType, GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED),
            G.notEquals(statusType, GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED),
          ),
          options,
        );

        return statusMessages;
      };

      const component = (
        <TrackingFormComponent
          checkInModal={true}
          closeModal={closeModal}
          fields={checkInSettings}
          carrierToken={carrierToken}
          enterpriseGuid={branchGuid}
          initialValues={initialValues}
          carrierMobView={carrierMobView}
          formattedAddress={formattedAddress}
          statusReasonCodes={statusReasonCodes}
          defaultValues={defaultTrackingSettings}
          geoLocationProcess={geoLocationProcess}
          getValidationSchema={getInitialValidationSchema}
          addCarrierStatusMessage={addCarrierStatusMessage}
          loadGuid={R.path([GC.FIELD_GUID], detailPageInfo)}
          statusCodeConfigs={getOption(R.path(['statusCodeConfigs'], detailPageInfo))}
          title={G.getWindowLocale('titles:check-in', 'CHECK IN', { caseAction: 'upperCase' })}
        />
      );

      const modal = {
        component,
        options: {
          width: '100%',
          movable: false,
          height: '100%',
          maxWidth: '768px',
        },
      };

      openModal(modal);
    },
    handleOpenStatusModal: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const modal = {
        component: (<DocModalComponent closeModal={closeModal} />),
        options: {
          width: '100%',
          movable: false,
          height: '100%',
          maxWidth: '768px',
        },
      };

      openModal(modal);
    },
    handleOpenDocModal: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const modal = {
        component: (<DocModalComponent {...props} closeModal={closeModal} />),
        options: {
          width: '100%',
          movable: false,
          height: '100%',
          maxWidth: '768px',
        },
      };

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidMount() {
      fillAddressInput(this.props);
    },
  }),
  pure,
);

const Stop = ({ stop, index, mappedEventsForMap, handleOpenStopModal }: Object) => {
  const isCompleted = G.isTrue(R.path(['completed'], stop));
  const bgColor = G.ifElse(isCompleted, greenColor, mainRedColor);

  return (
    <Flex width='100%' alignItems='stretch'>
      <Flex
        width='10%'
        minWidth='20px'
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
      >
        <Box
          bg={bgColor}
          height='calc((100% - 10px) / 2)'
          width={G.ifElse(R.equals(0, index), '0', '2px')}
        />
        <Box
          width='10px'
          bg={bgColor}
          height='10px'
          borderRadius='50%'
        />
        <Box
          bg={bgColor}
          height='calc((100% - 10px) / 2)'
          width={calcWidth(mappedEventsForMap, index)}
        />
      </Flex>
      <Flex
        mt='15px'
        mb='10px'
        width='100%'
        minWidth='300px'
        cursor='pointer'
        flexDirection='column'
        alignItems='flex-start'
        borderColor={silverColor}
        borderBottom='1.5px solid'
        onClick={() => handleOpenStopModal(stop)}
      >
        <Flex
          width='100%'
          justifyContent='flex-start'
        >
          <Flex
            width='70%'
            flexDirection='column'
          >
            <Flex
              mb='10px'
              width='100%'
              fontSize='14px'
              textAlign='left'
              color={whiteColor}
              fontWeight='bolder'
            >
              {`Stop ${R.path(['telEventIndex'], stop)}: ${R.path(['eventType'], stop)}`}
            </Flex>
            <Flex
              mb='7px'
              width='100%'
              fontSize='12px'
              color={whiteColor}
            >
              {
                `${G.createLocalDateTimeFromInstanceOrISOString(
                  R.path([GC.FIELD_LOAD_EVENT_EARLY_DATE], stop),
                  'LLL',
                )} - ${
                  G.createLocalDateTimeFromInstanceOrISOString(
                    R.path([GC.FIELD_LOAD_EVENT_LATE_DATE], stop),
                    'LLL',
                  )}`
              }
            </Flex>
          </Flex>
          <Flex
            p='5px'
            bg={bgColor}
            width='130px'
            height='30px'
            fontSize='14px'
            minWidth='120px'
            fontWeight='700'
            color={whiteColor}
            borderRadius='20px'
            alignItems='center'
            justifyContent='center'
          >
            {
              G.ifElse(
                isCompleted,
                G.getWindowLocale('titles:completed', 'Completed'),
                G.getWindowLocale('titles:not-completed', 'Not Completed'),
              )
            }
          </Flex>
        </Flex>
        <Flex mb='7px' color={whiteColor}>
          {G.renderFullAddressString(R.path(['location'], stop))}
        </Flex>
      </Flex>
    </Flex>
  );
};

const RoutePointComponent = (props: Object) => (
  <RelativeBox
    flex='auto'
    width='100%'
    minWidth='310px'
    overflowY='scroll'
    minHeight='calc(100% - 330px)'
    display={G.ifElse(G.isFalse(props.openMobMap), 'flex', 'none')}
  >
    <AbsoluteBox
      top='0'
      left='0'
      width='100%'
      height='max-content'
      flexDirection='column'
    >
      {
        R.pathOr([], ['mappedEventsForMap'], props).map((stop: Object, index: number) => (
          <Stop
            key={index}
            stop={stop}
            index={index}
            handleOpenStopModal={props.handleOpenStopModal}
            mappedEventsForMap={R.pathOr([], ['mappedEventsForMap'], props)}
          />
        ))
      }
    </AbsoluteBox>
  </RelativeBox>
);

const CarrierDetailPage = enhance((props: Object) => (
  <CarrierDetailFormWrapper
    position='relative'
    flexDirection='column'
    justifyContent='space-between'
    currentTheme={props.currentTheme}
  >
    {
      props.modal.isOpened &&
      props.modal.modal.map((modal: Object, index: number) => (
        <Box key={index} mr='auto'>
          <CommonModal index={index} maxWidth={768} params={{ ...props.modal, modal }} />
        </Box>
      ))
    }
    <Box width='100%'>
      <Flex
        p='5px 15px'
        width='100%'
        height='80px'
        minWidth='320px'
        bg={cobaltColor}
        color={whiteColor}
        flexDirection='column'
        boxShadow='5px 8px 9px -2px rgb(21,21,21)'
      >
        <Flex
          width='100%'
          height='50%'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box
            fontSize='20px'
            fontWeight='bolder'
          >
            {G.getWindowLocale('titles:current-loads', 'Current Loads')}
          </Box>
          <Box
            cursor='pointer'
            onClick={props.handleOpenDocModal}
          >
            <IconWrapper pr='10px'>
              {I.documents(whiteColor)}
            </IconWrapper>
          </Box>
        </Flex>
        <Flex
          width='100%'
          height='50%'
          fontSize='12px'
          color={whiteColor}
          fontWeight='bolder'
          alignItems='center'
          justifyContent='flex-start'
        >
          {`
            ${G.getWindowLocale('titles:load-number', 'Load Number')}:
            ${R.pathOr('', ['detailPageInfo', 'primaryReference', 'value'], props)}
          `}
        </Flex>
      </Flex>
      <Flex
        width='100%'
        p='10px 15px'
        height='130px'
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        borderBottom='1px solid'
        borderColor={G.ifElse(G.isTrue(props.openMobMap), 'none', silverColor)}
      >
        <Box
          width='100%'
          fontSize='20px'
          textAlign='left'
          color={whiteColor}
          fontWeight='bolder'
        >
          {G.getWindowLocale('titles:stops', 'Stops')}
        </Box>
        <Flex
          mt='15px'
          width='280px'
          height='35px'
          fontSize='16px'
          minWidth='250px'
          cursor='pointer'
          bg={cobaltColor}
          color={whiteColor}
          borderRadius='20px'
          fontWeight='bolder'
          alignItems='center'
          justifyContent='center'
          onClick={props.handleOpenCheckInModal}
          boxShadow='5px 8px 9px -2px rgb(21,21,21)'
        >
          <IconWrapper pr='10px'>
            {I.locationMarker(whiteColor, '20px', '20px')}
          </IconWrapper>
          {G.getWindowLocale('titles:check-in', 'CHECK IN', { caseAction: 'upperCase' })}
        </Flex>
      </Flex>
    </Box>
    {
      props.openMobMap &&
      <Flex
        width='90%'
        minWidth='300px'
        overflowY='hidden'
        border='1px solid'
        flexDirection='row'
        borderRadius='20px'
        borderColor={cobaltColor}
        minHeight='calc(100vh - 330px)'
      >
        <MobMapComponent
          {...props}
          defaultZoom={8}
          height='calc(100vh - 330px)'
          center={centerGeoPoint(R.path([GC.SYSTEM_OBJECT_LOCATION], R.head(R.path(['mappedEventsForMap'], props))))}
        />
      </Flex>
    }
    <RoutePointComponent {...props} />
    <Flex
      p='10px 0'
      width='100%'
      height='120px'
      minHeight='120px'
      alignItems='center'
      flexDirection='column'
      justifyContent='flex-start'
      bg={G.getTheme('colors.light.black')}
    >
      <Flex
        mt='5px'
        mb='15px'
        width='280px'
        height='35px'
        fontSize='16px'
        minWidth='250px'
        cursor='pointer'
        bg={cobaltColor}
        color={whiteColor}
        borderRadius='20px'
        fontWeight='bolder'
        alignItems='center'
        justifyContent='center'
        boxShadow='5px 8px 9px -2px rgb(21,21,21)'
        onClick={() => props.setOpenMobMap(R.not(props.openMobMap))}
      >
        <IconWrapper pr='10px'>
          {I.onMap(whiteColor, 20, 20)}
        </IconWrapper>
        {
          G.ifElse(
            props.openMobMap,
            G.getWindowLocale('actions:close-map', 'CLOSE MAP', { caseAction: 'upperCase' }),
            G.getWindowLocale('actions:open-map', 'OPEN MAP', { caseAction: 'upperCase' }),
          )
        }
      </Flex>
      <Flex
        mr='10%'
        width='50px'
        height='50px'
        cursor='pointer'
        bg={cobaltColor}
        borderRadius='50%'
        alignItems='center'
        alignSelf='flex-end'
        justifyContent='center'
        onClick={props.handleOpenChatModal}
        boxShadow='5px 8px 9px -2px rgb(21,21,21)'
      >
        <IconWrapper mt='4px'>
          {I.chatIcon()}
        </IconWrapper>
      </Flex>
    </Flex>
  </CarrierDetailFormWrapper>
));

export default CarrierDetailPage;
