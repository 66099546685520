import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { documentSendingTypeOptions } from '../../../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fieldSettings = [
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'documentTypeOptions',
    fieldName: GC.FIELD_DOCUMENT_TYPE_GUID,
    label: ['titles:document-type', 'Document Type'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_SENDING_TYPE,
    options: documentSendingTypeOptions,
    label: ['titles:sending-type', 'Sending Type'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_EXPIRATION_DAYS,
    label: ['titles:expiration-days', 'Expiration Days'],
  },
];

const defaultValues = {
  [GC.FIELD_EXPIRATION_DAYS]: null,
  [GC.FIELD_DOCUMENT_TYPE_GUID]: null,
  [GC.FIELD_SENDING_TYPE]: GC.DOCUMENT_SENDING_TYPE_LINK,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_DOCUMENT_TYPE_GUID]: G.yupStringRequired,
  [GC.FIELD_EXPIRATION_DAYS]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .integer(G.getShouldBeIntegerLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 100))
    .max(100, G.getShouldBeFromToLocaleTxt(1, 100)),
});

const DocumentSendingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      documentTypeOptions={props.documentTypeOptions}
      fields={fieldSettings}
      fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
    />
    <FormFooter2 />
  </form>
);

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  pure,
);

export default enhance(DocumentSendingForm);
