import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// common
import { changeCommissionAssignmentRequest } from '../../../../common/actions';
// components
import Events from '../../../../components/events';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import LoadHeader from '../../../dispatch-details-new/components/load-header';
import { makeSelectAvailableReferenceTypesByScope } from '../../../reference/selectors';
import { withRightLoadHeaderActionsWithoutConnect }
  from '../../../dispatch-details-new/order/hocs/with-right-load-header-actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, StickedBox } from '../../../../ui';
// feature dispatch-board-new
import ShipUnitDetails from '../../components/ship-unit-details';
// feature dispatch-board-new/order
import Cards from './cards';
import { getOrderDetailsRequest, updateOrderDetailsRequest } from '../actions';
/////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  availableRefTypes: makeSelectAvailableReferenceTypesByScope(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    getOrderDetailsRequest,
    updateOrderDetailsRequest,
    changeCommissionAssignmentRequest,
  }),
  pure,
);

const EnhancedLoadHeader = withRightLoadHeaderActionsWithoutConnect(LoadHeader);

const AllDetails = (props: Object) => {
  const {
    load,
    openModal,
    closeModal,
    tableIndex,
    openLoader,
    closeLoader,
    autodialApp,
    eventsToUse,
    referenceTypes,
    getOrderDetailsRequest,
    updateOrderDetailsRequest,
    changeCommissionAssignmentRequest,
  } = props;

  const { guid, tels, events, loadType, selectedRate, loadConfigs } = load;

  const branchGuid = R.prop(GC.BRANCH_GUID, load);

  const distances = R.compose(
    R.filter(G.isNotNilAndNotEmpty),
    R.map(R.prop(GC.FIELD_DISTANCE_CLO)),
  )(events);

  const totalDistance = G.calculateTotalDistance(distances);

  return (
    <StickedBox left='0px' p='8px 16px' width='fit-content'>
      <EnhancedLoadHeader
        load={load}
        openModal={openModal}
        closeModal={closeModal}
        openLoader={openLoader}
        tableIndex={tableIndex}
        fromPage='dispatchBoard'
        closeLoader={closeLoader}
        selectedRate={selectedRate}
        totalDistance={totalDistance}
        changeCommissionAssignmentRequest={changeCommissionAssignmentRequest}
        approvalRequired={G.getConfigValueFromStore(GC.CLO_GENERAL_APPROVAL_REQUIRED, loadConfigs)}
        updateOrderDetailsRequest={(values: Object) => updateOrderDetailsRequest(R.mergeRight(load, values))}
        customerRateTransportationModeGuid={R.path(
          ['selectedRate', GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID],
          load,
        )}
      />
      <Box height='8px' />
      <Events
        loads={tels}
        loadGuid={guid}
        loadType={loadType}
        events={eventsToUse}
        branchGuid={branchGuid}
        autodialApp={autodialApp}
        referenceTypes={referenceTypes}
        fromPage={GC.PAGE_DISPATCH_BOARD_NEW}
        getLoadDetailsRequest={getOrderDetailsRequest} />
      <Box mt={15} width='calc(100vw - 110px)'>
        <Cards {...props} />
      </Box>
    </StickedBox>
  );
};

const EventsDetails = (props: Object) => {
  const { load, autodialApp, eventsToUse, referenceTypes, getOrderDetailsRequest } = props;
  const { guid, tels, loadType } = load;
  const branchGuid = R.prop(GC.BRANCH_GUID, load);

  return (
    <StickedBox left='0px' p='8px 16px' width='fit-content'>
      <Events
        loads={tels}
        loadGuid={guid}
        loadType={loadType}
        events={eventsToUse}
        branchGuid={branchGuid}
        autodialApp={autodialApp}
        referenceTypes={referenceTypes}
        fromPage={GC.PAGE_DISPATCH_BOARD_NEW}
        getLoadDetailsRequest={getOrderDetailsRequest} />
    </StickedBox>
  );
};

const LoadDetails = (props: Object) => {
  const { load, shownDetails, availableRefTypes } = props;

  if (R.equals(shownDetails, 'SHIP_UNIT')) {
    return (
      <ShipUnitDetails
        items={G.getPropFromObject(GC.SYSTEM_LIST_ITEMS, load)}
        containers={G.getPropFromObject(GC.FIELD_CONTAINERS, load)}
      />
    );
  }

  const { events } = load;

  const eventsToUse = R.map(
    (event: Object) => R.assoc(
      GC.FIELD_LOAD_REFERENCES,
      R.filter(
        R.propEq(G.getGuidFromObject(event), GC.FIELD_EVENT_GUID),
        R.pathOr([], [GC.FIELD_LOAD_REFERENCES], load),
      ),
      event,
    ),
    events,
  );
  const referenceTypes = R.map(
    (item: Object) => ({ label: R.prop(GC.FIELD_NAME, item), value: R.prop(GC.FIELD_GUID, item) }),
    availableRefTypes,
  );

  if (R.equals(shownDetails, 'ALL')) {
    return <AllDetails {...props} eventsToUse={eventsToUse} referenceTypes={referenceTypes} />;
  } else if (R.equals(shownDetails, 'EVENTS')) {
    return <EventsDetails {...props} eventsToUse={eventsToUse} referenceTypes={referenceTypes} />;
  } else if (R.equals(shownDetails, 'CARDS')) {
    return (
      <StickedBox left='0px' p='8px 16px' width='calc(100vw - 80px)'>
        <Cards {...props} />
      </StickedBox>
    );
  }
  return <div>TODO:</div>;
};

export default enhance(LoadDetails);
