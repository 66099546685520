import { fork } from 'redux-saga/effects';
// feature configs
import uiConfigWatcherSaga from './ui/sagas';
import telConfigWatcherSaga from './tel/sagas';
import cloConfigWatcherSaga from './clo/sagas';
import truckConfigWatcherSaga from './truck/sagas';
import ratingConfigWatcherSaga from './rating/sagas';
import driverConfigWatcherSaga from './driver/sagas';
import trailerConfigWatcherSaga from './trailer/sagas';
import carrierConfigWatcherSaga from './carrier/sagas';
import generalConfigWatcherSaga from './general/sagas';
import invoicesConfigWatcherSaga from './invoice/sagas';
import optionalConfigWatcherSaga from './optional/sagas';
import passwordConfigWatcherSaga from './password/sagas';
import templatesConfigWatcherSaga from './templates/sagas';
import mobileAppConfigWatcherSaga from './mobileapp/sagas';
import claimManagementConfigWatcherSaga from './claim/sagas';
import integrationConfigWatcherSaga from './integration/sagas';
import fleetGeneralConfigWatcherSaga from './fleet-general/sagas';
import communicationConfigWatcherSaga from './communication/sagas';
import serviceVendorConfigWatcherSaga from './service-vendor/sagas';
import taskManagementConfigWatcherSaga from './task-management/sagas';
//////////////////////////////////////////////////

function* configPagesWatcherSaga() {
  yield fork(uiConfigWatcherSaga);
  yield fork(cloConfigWatcherSaga);
  yield fork(telConfigWatcherSaga);
  yield fork(truckConfigWatcherSaga);
  yield fork(driverConfigWatcherSaga);
  yield fork(ratingConfigWatcherSaga);
  yield fork(carrierConfigWatcherSaga);
  yield fork(generalConfigWatcherSaga);
  yield fork(trailerConfigWatcherSaga);
  yield fork(optionalConfigWatcherSaga);
  yield fork(passwordConfigWatcherSaga);
  yield fork(invoicesConfigWatcherSaga);
  yield fork(templatesConfigWatcherSaga);
  yield fork(mobileAppConfigWatcherSaga);
  yield fork(integrationConfigWatcherSaga);
  yield fork(fleetGeneralConfigWatcherSaga);
  yield fork(communicationConfigWatcherSaga);
  yield fork(serviceVendorConfigWatcherSaga);
  yield fork(taskManagementConfigWatcherSaga);
  yield fork(claimManagementConfigWatcherSaga);
}

export default configPagesWatcherSaga;
