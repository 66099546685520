import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import AddAdvancePaymentForm from '../../../../components/advance-payment/add-advance-payment-form';
import UpdateAdvancePaymentStatusForm from '../../../../components/advance-payment/update-advance-payment-status-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import { updateLoadAdvancePaymentStatusRequest } from '../actions';
//////////////////////////////////////////////////

export const withAddAdvancePayment = compose(
  withHandlers({
    handleAddAdvancePayment: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        selectedCarrierDriverRate,
        createLoadAdvancePaymentRequest } = props;
      let selectedRateDrivers = {};
      let selectedRateDriverOptions = R.of(Array, GC.EMPTY_OPTION_OBJECT);
      if (R.propEq(GC.RATE_TYPE_FLEET_RATE, GC.FIELD_TYPE, selectedCarrierDriverRate)) {
        const {
          fleetAssignment: {
            primaryDriver,
            secondaryDriver,
            primaryDriverGuid,
            secondaryDriverGuid,
          },
        } = selectedCarrierDriverRate;
        const primaryDriverFullName = R.compose(
          R.join(', '),
          R.values,
          R.pick([GC.FIELD_FIRST_NAME, GC.FIELD_LAST_NAME]),
        )(primaryDriver);
        const primaryDriverOption = {
          [GC.FIELD_VALUE]: primaryDriverGuid,
          [GC.FIELD_LABEL]: primaryDriverFullName,
        };
        selectedRateDriverOptions = R.append(primaryDriverOption, selectedRateDriverOptions);
        selectedRateDrivers = R.assoc(primaryDriverGuid, primaryDriver, selectedRateDrivers);
        if (G.isNotNilAndNotEmpty(secondaryDriverGuid)) {
          const secondaryDriverFullName = R.compose(
            R.join(', '),
            R.values,
            R.pick([GC.FIELD_FIRST_NAME, GC.FIELD_LAST_NAME]),
          )(secondaryDriver);
          const secondaryDriverOption = {
            [GC.FIELD_VALUE]: secondaryDriverGuid,
            [GC.FIELD_LABEL]: secondaryDriverFullName,
          };
          selectedRateDriverOptions = R.append(secondaryDriverOption, selectedRateDriverOptions);
          selectedRateDrivers = R.assoc(secondaryDriverGuid, secondaryDriver, selectedRateDrivers);
        }
      }
      const initialValues = {
        [GC.FIELD_TEL_GUID]: R.prop(GC.FIELD_GUID, load),
        [GC.FIELD_ADVANCE_PAYMENT_TRIP_NUMBER]: R.prop(GC.FIELD_PRIMARY_REFERENCE_VALUE, load),
      };
      const component = (
        <AddAdvancePaymentForm
          closeModal={closeModal}
          initialValues={initialValues}
          disableSelectDriverField={false}
          selectedRateDrivers={selectedRateDrivers}
          submitAction={createLoadAdvancePaymentRequest}
          optionsForSelect={{ selectedRateDriverOptions }} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 660,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:add-advance-payment', 'Add Advance Payment'),
        },
      };
      openModal(modal);
    },
  }),
);

export const withLoadAdvancePaymentTableGroupRowActions = compose(
  connect(null, { updateLoadAdvancePaymentStatusRequest }),
  withHandlers({
    handleUpdateAdvancePaymentStatus: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        updateLoadAdvancePaymentStatusRequest } = props;
      const { guid } = entity;
      const initialValues = { guid };
      const component = (
        <UpdateAdvancePaymentStatusForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={updateLoadAdvancePaymentStatusRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 320,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:update-advance-payment-status', 'Update Advance Payment Status'),
        },
      };
      openModal(modal);
    },
  }),
  pure,
);
