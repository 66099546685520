import * as R from 'ramda';
import { initialize } from 'redux-form';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { handleCreateNewLocationRequest } from '../../location/sagas';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectExpandedContainerOpened } from '../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
import { openWindowSaga } from '../../../sagas/common';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// fleet
import { getFleetConfigByGroupSaga } from '../sagas';
// feature fleet/driver
import { getAssignInfo, receivedGetAssignInfo } from '../driver/actions';
// feature fleet/vendor
import { getVendorAssignmentRequest } from '../vendor/actions';
// feature fleet/truck
import * as A from './actions';
import {
  makeSelectTruckDocuments,
  makeSelectTruckLocations,
  makeSelectTruckInsurances,
  makeSelectTruckFormValues,
  makeSelectTruckEquipComponent,
} from './selectors';
//////////////////////////////////////////////////

export function* handleGetTruckRequestSaga({ guid }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.getCurrentTruckEndpoint(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNil(data.driverGuid)) yield put(getAssignInfo(data.driverGuid));

      const branchGuid = G.getBranchGuidFromObject(data);

      yield all([
        put(A.getTruckRefRequest(guid)),
        put(A.receivedGetTruckSuccess(data)),
        put(A.getTruckInsuranceRequest(guid)),
        put(A.getExternalTruckIdRequest(guid)),
        put(A.getTruckConfigsRequest(branchGuid)),
        put(A.getTruckLatestKnownLocationRequest(guid)),
        put(getVendorAssignmentRequest({ guid, [GC.FIELD_TYPE]: 'truck' })),
        put(A.getBranchConfigsByNamesRequest(G.getBranchGuidFromObject(data))),
        put(A.getDailyLogListRequest({ limit: 20, offset: 0, [GC.FIELD_TRUCK_GUID]: guid })),
      ]);

      yield put(A.getFleetGeneralConfigsRequest(branchGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckRequestSaga exception');
  }
}

export function* handleGetTruckInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.truckInsuranceList;
    const options = {
      params: { truckGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckInsuranceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckInsuranceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckInsuranceSaga exception');
  }
}

export function* handleGetTruckRefRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.truckReferenceList;
    const options = {
      params: { truckGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckRefSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckRefRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckRefRequestSaga exception');
  }
}

export function* handleGetTruckDocsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        primaryObjectGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.truckDocList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckDocsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckDocsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckDocsSaga exception');
  }
}

export function* handleGetTruckLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        truckGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.truckLocations, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const branchGuid = yield select(makeSelectCurrentBranchGuid());
      yield put(A.receivedGetTruckLocationSuccess(data));
      const options = {
        names: GC.TEMPLATES_LOCATION_TYPE,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      };
      const reqRes = yield call(getFleetConfigByGroupSaga, options);
      if (G.isResponseSuccess(reqRes.status)) {
        const values = G.mapConfigValuesByName(reqRes.data).dropdowns[GC.TEMPLATES_LOCATION_TYPE];
        yield put(A.receivedGetLocationTypesSuccess(values));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckLocationSaga exception');
  }
}

export function* handleGetTruckOwnershipSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        truckGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.truckOwnership, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckOwnershipSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckOwnershipSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckOwnershipSaga exception');
  }
}

export function* handleGetTruckSpecificationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        truckGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.truckSpecification, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckSpecificationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckSpecificationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckSpecificationSaga exception');
  }
}

export function* handleGetTruckComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        truckGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.truckComponentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckComponentSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckComponentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckComponentSaga exception');
  }
}

export function* handleGetTruckConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      group: GC.TRUCK_CONFIG_GROUP,
      [GC.FIELD_BRANCH_GUID]: payload,
    };
    const res = yield call(getFleetConfigByGroupSaga, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckConfigsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTruckConfigsSaga exception');
  }
}

const configNames = [GC.GENERAL_BRANCH_DEFAULT_CURRENCY];

function* getBranchConfigsByNamesRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: payload,
        names: R.join(', ', configNames),
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsByNamesSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsByNamesRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getBranchConfigsByNamesRequest exception');
  }
}

function* getFleetGeneralConfigsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      [GC.FIELD_BRANCH_GUID]: payload,
      group: GC.FLEET_GENERAL_CONFIG_GROUP,
    };
    const res = yield call(getFleetConfigByGroupSaga, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTruckConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetGeneralConfigsRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetGeneralConfigsRequest exception');
  }
}

export function* handleGetTruckLatestKnownLocationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.truckLatestLocation;
    const options = {
      params: { truckGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const locationData = G.ifElse(G.isNilOrEmpty(data), [], R.of(Array, data));
      yield put(A.receivedGetTruckLatestKnownLocationSuccess(locationData));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckLatestKnownLocationRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetTruckLatestKnownLocationRequestSaga exception');
  }
}

export function* handleCreateTruckRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({showDimmer: true}));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const { values, fromVendorPage } = payload;
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.truck, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const url = routesMap.editTruckPage(G.getGuidFromObject(data));
      if (G.isTrue(fromVendorPage)) {
        yield call(openWindowSaga, { url, name: 'truckDetailPage', reloadLocation: false });
      } else {
        yield call(G.goToRoute, url);
      }
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTruckRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTruckRequestSaga exception');
  }
}

export function* handleCreateTruckReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { values, selectedList } = payload;

    const options = {
      data: R.assoc('primaryObjectGuids', selectedList, values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.truckReference, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedCreateTrucksReferenceSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTruckReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTruckReferenceSaga exception');
  }
}

export function* handleCreateTruckInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.truckInsurance, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedCreateTruckInsuranceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTruckInsuranceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTruckInsuranceSaga exception');
  }
}

export function* handleCreateTruckDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.truckDoc,
      { data },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedCreateTruckDocSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTruckDocSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTruckDocSaga exception');
  }
}

export function* handleCreateTruckLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.truckLocations, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isTrue(G.getPropFromObject('saveToTemplate', payload))) {
        yield call(handleCreateNewLocationRequest, { payload });
      }

      yield put(closeModal());
      yield put(A.receivedCreateTruckLocationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTruckLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTruckLocationSaga exception');
  }
}

export function* handleCreateTruckComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.truckComponent, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.getTruckComponentRequest(G.getPropFromObject(GC.FIELD_TRUCK_GUID, data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTruckComponentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTruckComponentSaga exception');
  }
}

export function* handleCreateTruckLatestKnownLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.truckLatestLocation,
      { data: payload },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const locationData = G.ifElse(G.isNilOrEmpty(data), [], R.of(Array, data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedCreateTruckLatestKnownLocationSuccess(locationData));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTruckLatestKnownLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTruckLatestKnownLocationSaga exception');
  }
}

export function* handleUpdateTruckReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.truckReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTruckReferenceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTruckReferenceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckReferenceSaga exception');
  }
}

export function* handleUpdateTruckInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'put', endpointsMap.truckInsurance, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const insurances = yield select(makeSelectTruckInsurances());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), insurances);
      yield put(A.receivedUpdateTruckInsuranceSuccess({ data, index}));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTruckInsuranceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckInsuranceSaga exception');
  }
}

export function* handleUpdateTruckDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(sendRequest, 'post', endpointsMap.truckDocUpdate, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      const docs = yield select(makeSelectTruckDocuments());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), docs);
      yield put(A.receivedUpdateTruckDocSuccess({ data: res.data, index}));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTruckDocSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckDocSaga exception');
  }
}

export function* handleUpdateTruckDetailsSaga({ payload }: Object) {
  try {
    const res = yield call(sendRequest, 'put', endpointsMap.truck, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTruckDetailsSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (R.propEq(true, GC.FIELD_SYNC, payload)) {
        yield put(A.syncTruckByVINRequest(G.getGuidFromObject(payload)));
      }
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateTruckDetailsSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckDetailsSaga exception');
    return false;
  }
}

const setOwnershipBody = (data: Object) => ({
  data: {
    endDate: data.endDate,
    guid: data.ownershipGuid,
    startDate: data.startDate,
    truckGuid: data.truckGuid,
    version: data.ownershipVersion,
    returnedDate: data.returnedDate,
    contractName: data.contractName,
    paymentAmount: data.paymentAmount,
    ownershipType: data.ownershipType,
    paymentMethod: data.paymentMethod,
    contractNumber: data.contractNumber,
    paymentInterval: data.paymentInterval,
    registrationCost: data.registrationCost,
    equipmentReturnCost: data.equipmentReturnCost,
    maintenanceCostMethod: data.maintenanceCostMethod,
    maintenanceCostInterval: data.maintenanceCostInterval,
    equipmentReturnCostPaid: data.equipmentReturnCostPaid,
    equipmentReturnCostRatio: data.equipmentReturnCostRatio,
    equipmentReturnCostAccrued: data.equipmentReturnCostAccrued,
    registrationPaymentInterval: data.registrationPaymentInterval,
    equipmentReturnBalanceRequired: data.equipmentReturnBalanceRequired,
    equipmentReturnCostNotProvisioned: data.equipmentReturnCostNotProvisioned,
  },
});

export function* handleUpdateTruckOwnershipSaga(payload: Object) {
  try {
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.truckOwnership,
      setOwnershipBody(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTruckOwnershipSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateTruckOwnershipSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckOwnershipSaga exception');
    return false;
  }
}

const setSpecificationBody = (data: Object) => ({
  data: {
    gvwt: data.gvwt,
    width: data.width,
    height: data.height,
    length: data.length,
    mpgLow: data.mpgLow,
    mpgHigh: data.mpgHigh,
    sleeper: data.sleeper,
    fuelType: data.fuelType,
    widthUom: data.widthUom,
    heightUom: data.heightUom,
    lengthUom: data.lengthUom,
    axleCount: data.axleCount,
    truckGuid: data.truckGuid,
    axleConfig: data.axleConfig,
    tareWeight: data.tareWeight,
    guid: data.specificationGuid,
    doorDimension: data.doorDimension,
    version: data.specificationVersion,
    tareWeightType: data.tareWeightType,
    gvwtWeightType: data.gvwtWeightType,
    fifthWheelWidth: data.fifthWheelWidth,
    fifthWheelHeight: data.fifthWheelHeight,
    specificationClass: data.specificationClass,
    fifthWheelWidthType: data.fifthWheelWidthType,
    fifthWheelHeightType: data.fifthWheelHeightType,
  },
});

export function* handleUpdateTruckSpecificationSaga(payload: Object) {
  try {
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.truckSpecification,
      setSpecificationBody(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTruckSpecificationSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateTruckSpecificationSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckSpecificationSaga exception');
    return false;
  }
}

export function* handleUpdateTruckSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const detailsResults = yield call(handleUpdateTruckDetailsSaga, { payload });

    let specResults = [];

    if (G.isNotNil(payload.ownershipGuid)) {
      specResults = yield all([
        call(handleUpdateTruckOwnershipSaga, payload),
        call(handleUpdateTruckSpecificationSaga, payload),
      ]);
    }

    if (G.notContain(false, R.concat(R.of(Array, detailsResults), specResults))) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      const expandedContainerOpened = yield select(makeSelectExpandedContainerOpened());

      if (expandedContainerOpened) {
        yield put(setExpandedContainerOptions({
          window: null,
          options: null,
          opened: false,
          componentType: null,
          visitPageGuid: G.getGuidFromObject(payload),
        }));
      } else {
        G.historyGoBack();
      }
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckSaga exception');
  }
}

export function* handleUpdateTruckLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const locations = yield select(makeSelectTruckLocations());

    const res = yield call(sendRequest, 'put', endpointsMap.truckLocations, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const index = R.findIndex(R.propEq(G.getGuidFromObject(payload), GC.FIELD_GUID), locations);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(closeModal());
      yield put(A.receivedUpdateTruckLocationSuccess({ data, index }));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTruckLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckLocationSaga exception');
  }
}

export function* handleUpdateTruckComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'put', endpointsMap.truckComponent, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const components = yield select(makeSelectTruckEquipComponent());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), components);

      yield put(closeModal());
      yield put(A.receivedUpdateTruckComponentSuccess({ data, index}));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTruckComponentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTruckComponentSaga exception');
  }
}

export function* handleDeleteTruckReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTruckRefEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDeleteTruckReferenceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTruckReferenceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTruckReferenceSaga exception');
  }
}

export function* handleDeleteTruckInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTruckInsuranceEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const insurances = yield select(makeSelectTruckInsurances());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), insurances);
      yield put(A.receivedDeleteTruckInsuranceSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTruckInsuranceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTruckInsuranceSaga exception');
  }
}

export function* handleDeleteTruckDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTruckDocEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const docs = yield select(makeSelectTruckDocuments());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), docs);
      yield put(A.receivedDeleteTruckDocSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTruckDocSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTruckDocSaga exception');
  }
}

export function* handleDeleteTruckLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTruckLocationEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const locations = yield select(makeSelectTruckLocations());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), locations);
      yield put(A.receivedDeleteTruckLocationSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTruckLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTruckLocationSaga exception');
  }
}

export function* handleDeleteTruckComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTruckComponentEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const components = yield select(makeSelectTruckEquipComponent());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), components);
      yield put(A.receivedDeleteTruckComponentSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTruckComponentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTruckComponentSaga exception');
  }
}

export function* handleCreateExternalTruckIdSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.truckCreateExternalId,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.getExternalTruckIdRequest(R.prop(GC.FIELD_TRUCK_GUID, payload)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateExternalTruckIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateExternalTruckIdSaga exception');
  }
}

export function* handleGetExternalTruckIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.truckChangeExternalId(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getExternalTruckIdSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetExternalTruckIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetExternalTruckIdSaga exception');
  }
}

export function* handleDeleteExternalTruckIdSaga({ payload }: Object) {
  try {
    const { guid, truckGuid } = payload;
    yield put(openLoader());
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.truckChangeExternalId(guid),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalTruckIdRequest(truckGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteExternalTruckIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteExternalTruckIdSaga exception');
  }
}

export function* handleUpdateExternalTruckIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.truckCreateExternalId,
      { data: payload },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalTruckIdRequest(payload.truckGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateExternalTruckIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateExternalTruckIdSaga exception');
  }
}

function* handlePrintTruckSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: { ...payload },
      resType: 'arraybuffer',
    };
    const res = yield call(sendRequest, 'post', endpointsMap.truckPrint, options);
    const { data, status, request, headers } = res;
    if (G.isResponseSuccess(status)) {
      const file = new window.Blob(
        R.of(Array, data),
        { type: R.pathOr('application/pdf', ['content-type'], headers) },
      );
      const filename = R.or(G.getFileNameFromResponseDispositionHeader(request), 'truck.pdf');
      FileSaver.saveAs(file, filename);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintTruckSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handlePrintTruckSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

// working division
function* changeAssignedToDivisionRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const formValues = yield select(makeSelectTruckFormValues());
    const res = yield call(sendRequest, 'put', endpointsMap.changeTruckAssignedToDivision, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(initialize('EDIT_TRUCK_FORM', null));
      yield put(receivedGetAssignInfo({}));
      yield put(A.cleanFormStore());
      yield call(handleGetTruckRequestSaga, { guid: G.getGuidFromObject(formValues) });
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'changeAssignedToDivisionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeAssignedToDivisionRequest exception');
  }
}

// sync truck
function* syncTruckByVINRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_TRUCK_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.syncTruck, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleGetTruckRequestSaga, { [GC.FIELD_GUID]: payload });
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'syncTrucksByVINRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'syncTrucksByVINRequest exception');
  }
}

// daily log
function* getDailyLogListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: payload,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.truckDailyLogList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDailyLogListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDailyLogListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDailyLogListRequest exception');
  }
}

export function* handleVisitEditTruckPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_TRUCK_EDIT_PAGE);
    yield put(A.setInitialState());
    yield put(receivedGetAssignInfo({}));
    yield put(A.cleanFormStore());
    yield call(handleGetTruckRequestSaga, payload);
    break;
  }
}

export function* trucksWatcherSaga() {
  yield takeLatest(A.printTruck, handlePrintTruckSaga);
  yield takeLatest(A.updateTruckRequest, handleUpdateTruckSaga);
  yield takeLatest(A.getTruckDocsRequest, handleGetTruckDocsSaga);
  yield takeLatest(A.deleteTruckDocRequest, handleDeleteTruckDocSaga);
  yield takeLatest(A.updateTruckDocRequest, handleUpdateTruckDocSaga);
  yield takeLatest(A.createTruckDocRequest, handleCreateTruckDocSaga);
  yield takeLatest(A.getTruckRefRequest, handleGetTruckRefRequestSaga);
  yield takeLatest(A.createTruckRequest, handleCreateTruckRequestSaga);
  yield takeLatest(A.getTruckConfigsRequest, handleGetTruckConfigsSaga);
  yield takeLatest(A.getTruckLocationRequest, handleGetTruckLocationSaga);
  yield takeLatest(A.getTruckInsuranceRequest, handleGetTruckInsuranceSaga);
  yield takeLatest(A.getTruckComponentRequest, handleGetTruckComponentSaga);
  yield takeLatest(A.getTruckOwnershipRequest, handleGetTruckOwnershipSaga);
  yield takeLatest(A.updateTruckDetailsRequest, handleUpdateTruckDetailsSaga);
  yield takeLatest(A.getExternalTruckIdRequest, handleGetExternalTruckIdSaga);
  yield takeLatest(A.updateTruckLocationRequest, handleUpdateTruckLocationSaga);
  yield takeLatest(A.createTruckLocationRequest, handleCreateTruckLocationSaga);
  yield takeLatest(A.deleteTruckLocationRequest, handleDeleteTruckLocationSaga);
  yield takeLatest(GC.VISIT_FLEET_TRUCK_EDIT_PAGE, handleVisitEditTruckPageSaga);
  yield takeLatest(A.createTruckInsuranceRequest, handleCreateTruckInsuranceSaga);
  yield takeLatest(A.deleteTruckReferenceRequest, handleDeleteTruckReferenceSaga);
  yield takeLatest(A.deleteTruckInsuranceRequest, handleDeleteTruckInsuranceSaga);
  yield takeLatest(A.updateTruckReferenceRequest, handleUpdateTruckReferenceSaga);
  yield takeLatest(A.createTruckComponentRequest, handleCreateTruckComponentSaga);
  yield takeLatest(A.updateTruckInsuranceRequest, handleUpdateTruckInsuranceSaga);
  yield takeLatest(A.updateTruckComponentRequest, handleUpdateTruckComponentSaga);
  yield takeLatest(A.deleteTruckComponentRequest, handleDeleteTruckComponentSaga);
  yield takeLatest(A.getFleetGeneralConfigsRequest, getFleetGeneralConfigsRequest);
  yield takeLatest(A.createTrucksReferenceRequest, handleCreateTruckReferenceSaga);
  yield takeLatest(A.getTruckSpecificationRequest, handleGetTruckSpecificationSaga);
  yield takeLatest(A.updateExternalTruckIdRequest, handleUpdateExternalTruckIdSaga);
  yield takeLatest(A.deleteExternalTruckIdRequest, handleDeleteExternalTruckIdSaga);
  yield takeLatest(A.createExternalTruckIdRequest, handleCreateExternalTruckIdSaga);
  yield takeLatest(A.getBranchConfigsByNamesRequest, getBranchConfigsByNamesRequest);
  yield takeLatest(A.createTruckLatestKnownLocationRequest, handleCreateTruckLatestKnownLocationSaga);
  yield takeLatest(A.getTruckLatestKnownLocationRequest, handleGetTruckLatestKnownLocationRequestSaga);
  // working division
  yield takeLatest(A.changeAssignedToDivisionRequest, changeAssignedToDivisionRequest);
  // sync truck
  yield takeLatest(A.syncTruckByVINRequest, syncTruckByVINRequest);
  // daily log
  yield takeLatest(A.getDailyLogListRequest, getDailyLogListRequest);
}

export default trucksWatcherSaga;
