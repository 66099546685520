// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
} = getReportSelectors('averageFuelPrice');

export {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
};
