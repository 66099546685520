import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Fieldset2 } from '../../../forms';
// feature carrier
import {
  getTerminalContactFields,
  defaultTerminalContactFields,
  getTerminalContactValidationSchema,
} from '../settings/fields-settings';
///////////////////////////////////////////////////////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: getTerminalContactValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const reqBody = R.assoc('carrierTerminalGuid', props.terminalGuid, values);
      props.submitAction(reqBody);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultTerminalContactFields,
      initialValues,
    ),
  }),
  pure,
);

const ContactFormComponent = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={getTerminalContactFields(props.values)}
    />
    <FormFooter2 />
  </form>
);

export default enhance(ContactFormComponent);
