import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// forms
import { Toggle } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const eldIntegrationTypes = [
  GC.FUEL_CARDS_TYPE_RELAY,
  GC.FLEET_INTEGRATION_SPIREON,
  GC.GPS_INTEGRATION_TYPE_GEO_TAB,
  GC.GPS_INTEGRATION_TYPE_VERIZON,
  GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
  GC.FLEET_INTEGRATION_TYPE_SAMSARA,
  GC.FLEET_INTEGRATION_TYPE_SKYBITZ,
  GC.GPS_INTEGRATION_TYPE_OMNITRACS,
  GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
  GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
  GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
  GC.FLEET_INTEGRATION_TYPE_POWER_FLEET,
  GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
  GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
];

const fuelCardTypes = [
  GC.FUEL_CARDS_TYPE_BVD,
  GC.FUEL_CARDS_TYPE_QUIKQ,
  GC.FUEL_CARDS_TYPE_COMDATA,
  GC.FLEET_INTEGRATION_TYPE_EFS,
  GC.FUEL_CARDS_TYPE_SELF_FUELED,
];

const tollChargeTypes = [
  GC.FLEET_INTEGRATION_TYPE_I_PASS,
  GC.FLEET_INTEGRATION_TYPE_BESTPASS,
  GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
  GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
];

const driverIntegrationTypeOptions = [
  {
    label: 'Keep Truckin',
    value: GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
  },
  {
    label: 'Samsara',
    value: GC.FLEET_INTEGRATION_TYPE_SAMSARA,
  },
  {
    label: 'Verizon',
    value: GC.GPS_INTEGRATION_TYPE_VERIZON,
  },
  {
    label: 'Track Ensure',
    value: GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
  },
  {
    label: 'Omnitracs',
    value: GC.GPS_INTEGRATION_TYPE_OMNITRACS,
  },
  {
    label: 'GEOTAB',
    value: GC.GPS_INTEGRATION_TYPE_GEO_TAB,
  },
  {
    label: 'Club ELD',
    value: GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
  },
  {
    label: 'Blue Star',
    value: GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
  },
  {
    label: 'Master ELD',
    value: GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
  },
  {
    label: 'Tenstreet',
    value: GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
  },
  {
    label: 'EFS',
    value: GC.FLEET_INTEGRATION_TYPE_EFS,
  },
  {
    label: 'QUIKQ',
    value: GC.FUEL_CARDS_TYPE_QUIKQ,
  },
  {
    value: GC.FUEL_CARDS_TYPE_COMDATA,
    label: G.getWindowLocale('titles:comdata', 'Comdata'),
  },
  {
    label: 'Self Fuel',
    value: GC.FUEL_CARDS_TYPE_SELF_FUELED,
  },
  {
    label: 'BVD Group',
    value: GC.FUEL_CARDS_TYPE_BVD,
  },
  {
    label: 'Relay',
    value: GC.FUEL_CARDS_TYPE_RELAY,
  },
  {
    label: 'I-Pass',
    value: GC.FLEET_INTEGRATION_TYPE_I_PASS,
  },
  {
    label: 'Bestpass',
    value: GC.FLEET_INTEGRATION_TYPE_BESTPASS,
  },
  {
    label: 'PrePass',
    value: GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
  },
  {
    label: 'Self Toll',
    value: GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
  },
];

const truckIntegrationTypeOptions = [
  {
    label: 'Keep Truckin',
    value: GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
  },
  {
    label: 'Samsara',
    value: GC.FLEET_INTEGRATION_TYPE_SAMSARA,
  },
  {
    label: 'Verizon',
    value: GC.GPS_INTEGRATION_TYPE_VERIZON,
  },
  {
    label: 'Track Ensure',
    value: GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
  },
  {
    label: 'Omnitracs',
    value: GC.GPS_INTEGRATION_TYPE_OMNITRACS,
  },
  {
    label: 'GEOTAB',
    value: GC.GPS_INTEGRATION_TYPE_GEO_TAB,
  },
  {
    label: 'Club ELD',
    value: GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
  },
  {
    label: 'Blue Star',
    value: GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
  },
  {
    label: 'Master ELD',
    value: GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
  },
  {
    label: 'I-Pass',
    value: GC.FLEET_INTEGRATION_TYPE_I_PASS,
  },
  {
    label: 'Bestpass',
    value: GC.FLEET_INTEGRATION_TYPE_BESTPASS,
  },
  {
    label: 'PrePass',
    value: GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
  },
  {
    label: 'Self Toll',
    value: GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
  },
];

const trailerIntegrationTypeOptions = [
  {
    label: 'Samsara',
    value: GC.FLEET_INTEGRATION_TYPE_SAMSARA,
  },
  {
    label: 'Skybitz',
    value: GC.FLEET_INTEGRATION_TYPE_SKYBITZ,
  },
  {
    label: 'Power Fleet',
    value: GC.FLEET_INTEGRATION_TYPE_POWER_FLEET,
  },
  {
    label: 'Spireon',
    value: GC.FLEET_INTEGRATION_SPIREON,
  },
];

const ToggleFuelCard = (props: Object) => {
  const {
    type,
    guid,
    enabled,
    driverGuid,
    getItemListRequest,
    createOrUpdateEntityRequest,
  } = props;

  if (G.notEquals(type, GC.FLEET_INTEGRATION_TYPE_EFS)) return null;

  const enabledIcon = G.ifElse(enabled, I.uiTrue, I.uiFalse)();

  return (
    <Flex mx='auto' width={100} alignItems='baseline' justifyContent='space-around'>
      <Box>{enabledIcon}</Box>
      <AuthWrapper has={[PC.ENABLE_FUEL_CARD_EXECUTE]}>
        <Toggle
          icons={false}
          checked={enabled}
          onChange={() => createOrUpdateEntityRequest({
            method: 'put',
            getItemListRequest,
            groupName: 'integrationList',
            requestPayload: {
              params: { driverGuid },
            },
            endpoint: G.ifElse(
              enabled,
              endpointsMap.fleetDriverExternalDisableByGuid,
              endpointsMap.fleetDriverExternalEnableByGuid,
            )(guid),
          })}
        />
      </AuthWrapper>
    </Flex>
  );
};

const settings = {
  [GC.FIELD_FLEET_INTEGRATION_TYPE]: {
    width: 150,
    isRequired: true,
    type: 'reactSelect',
    options: 'integrationOptions',
    name: 'titles:integration-type',
    additionalInputWrapperStyles: { zIndex: 12 },
    disabled: (_: any, { guid }: Object) => G.isNotNilAndNotEmpty(guid),
    customComponent: (row: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(R.path(['data', GC.FIELD_TYPE], row), GC.FIELD_VALUE)),
      R.concat(trailerIntegrationTypeOptions),
    )(driverIntegrationTypeOptions),
  },
  [GC.FIELD_FLEET_EXTERNAL_ID]: {
    width: 150,
    name: 'titles:external-id',
    additionalInputWrapperStyles: {
      display: (props: Object) => G.ifElse(
        R.includes(R.path(['values', GC.FIELD_TYPE], props), eldIntegrationTypes),
        'flex',
        'none',
      ),
    },
  },
  [GC.FIELD_FLEET_FUEL_CARD_ID]: {
    width: 150,
    name: 'titles:fuel-card-id',
    additionalInputWrapperStyles: {
      display: (props: Object) => G.ifElse(
        R.includes(R.path(['values', GC.FIELD_TYPE], props), fuelCardTypes),
        'flex',
        'none',
      ),
    },
  },
  [GC.FIELD_ENABLED]: {
    width: 120,
    name: 'titles:fuel-card-enabled',
    customComponent: ({ data, callbackData }: Object) => (
      <ToggleFuelCard
        {...data}
        {...R.pick(['getItemListRequest', 'createOrUpdateEntityRequest'], callbackData)}
      />
    ),
  },
  [GC.FIELD_FLEET_TRANSPONDER_ID]: {
    width: 150,
    name: 'titles:transponder-id',
    additionalInputWrapperStyles: {
      display: (props: Object) => G.ifElse(
        R.includes(R.path(['values', GC.FIELD_TYPE], props), tollChargeTypes),
        'flex',
        'none',
      ),
    },
  },
  [GC.FIELD_TEAM_DRIVER]: {
    width: 200,
    name: 'titles:team-driver-external-id',
    customComponent: (row: Object) => {
      const { lastName, firstName, externalId } = R.pathOr({}, ['data', GC.FIELD_TEAM_DRIVER], row);

      if (G.isNilOrEmpty(externalId)) return null;

      return `${firstName} ${lastName} (${externalId})`;
    },
  },
  [GC.FIELD_TRUCK]: {
    width: 200,
    name: 'titles:truck-external-id',
    customComponent: (row: Object) => {
      const { unitId, externalId } = R.pathOr({}, ['data', GC.FIELD_TRUCK], row);

      if (G.isNilOrEmpty(externalId)) return null;

      return `${unitId} (${externalId})`;
    },
  },
  [GC.FIELD_TRAILERS]: {
    width: 300,
    name: 'titles:trailers-external-id',
    customComponent: (row: Object) => {
      const trailers = R.path(['data', GC.FIELD_TRAILERS], row);

      if (G.isNilOrEmpty(trailers)) return null;

      return R.compose(
        R.join(', '),
        R.map(({ unitId, externalId }: Object) => `${unitId} (${externalId})`),
        R.filter(R.prop(GC.FIELD_FLEET_EXTERNAL_ID)),
      )(trailers);
    },
  },
};

const validationSchema = Yup.lazy(({ type }: Object) => {
  let schema = {
    [GC.FIELD_TYPE]: G.yupStringRequired,
  };

  if (R.includes(type, eldIntegrationTypes)) {
    schema = R.assoc(GC.FIELD_FLEET_EXTERNAL_ID, G.yupStringRequired, schema);
  }

  if (R.includes(type, fuelCardTypes)) {
    schema = R.assoc(GC.FIELD_FLEET_FUEL_CARD_ID, G.yupStringRequired, schema);
  }

  if (R.includes(type, tollChargeTypes)) {
    schema = R.assoc(GC.FIELD_FLEET_TRANSPONDER_ID, G.yupStringRequired, schema);
  }

  return Yup.object().shape(schema);
});

const defaultInputWrapperStyles = {
  mb: 25,
  width: 300,
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    disabled,
    type = 'text',
    isRequired = false,
    additionalInputWrapperStyles = {},
  }: Object, fieldName: string) => ({
    type,
    options,
    disabled,
    fieldName,
    isRequired,
    label: G.getWindowLocale(name),
    inputWrapperStyles: R.mergeRight(defaultInputWrapperStyles, additionalInputWrapperStyles),
  })),
  R.pick([
    GC.FIELD_TYPE,
    GC.FIELD_FLEET_EXTERNAL_ID,
    GC.FIELD_FLEET_FUEL_CARD_ID,
    GC.FIELD_FLEET_TRANSPONDER_ID,
  ]),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

const makeOptionsForSelect = (itemList: Array, integrationTypeOptions: Array) => {
  const existentIntegrations = R.map(R.prop(GC.FIELD_TYPE), itemList);

  const integrationOptions = R.map((item: Object) => R.assoc(
    'isDisabled',
    R.includes(R.prop(GC.FIELD_VALUE, item), existentIntegrations),
    item,
  ), integrationTypeOptions);

  return { integrationOptions };
};

export const integrationSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  useGetListRequest: true,
  groupName: 'integrationList',
  hasRemoveRequestOptions: false,
  includeItemListToTablePropsMemoDeps: true,
  itemTitleArr: ['titles:integration', 'integration'],
  formGroupTitleArr: ['titles:integration', 'Integration'],
  tableCallbackDataProps: ['getItemListRequest', 'createOrUpdateEntityRequest'],
  endpoints: {
    remove: 'fleetDriverExternalByGuid',
    createOrUpdate: 'fleetDriverExternal',
    getListByGuid: ({ primaryObjectGuid }: Object) => endpointsMap.fleetDriverExternalByGuid(primaryObjectGuid),
  },
  // helpers
  makeOptionsForSelect: ({ itemList }: Object) => makeOptionsForSelect(itemList, driverIntegrationTypeOptions),
};

export const truckIntegrationOptions = {
  ...integrationSettings,
  // helpers
  makeOptionsForSelect: ({ itemList }: Object) => makeOptionsForSelect(itemList, truckIntegrationTypeOptions),
  endpoints: {
    remove: 'truckChangeExternalId',
    createOrUpdate: 'truckCreateExternalId',
    getListByGuid: ({ primaryObjectGuid }: Object) => endpointsMap.truckChangeExternalId(primaryObjectGuid),
  },
};

export const trailerIntegrationOptions = {
  ...integrationSettings,
  // helpers
  makeOptionsForSelect: ({ itemList }: Object) => makeOptionsForSelect(itemList, trailerIntegrationTypeOptions),
  endpoints: {
    list: 'fleetExternalTrailerList',
    remove: 'trailerChangeExternalId',
    createOrUpdate: 'trailerCreateExternalId',
  },
};
