import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature config
import { EXTERNAL_DOCUMENT_TYPE_MAP } from '../constants';
import {
  documentTypeMappingFieldSettings,
  defaultDocumentTypeMappingFields,
  documentTypeMappingValidationSchema } from '../settings';
//////////////////////////////////////////////////

const getDocumentTypeOptions = (integrationType: string) => R.compose(
  G.addEmptyOptionToDropDown,
  R.values,
  R.mapObjIndexed((label: string, value: string) => ({ value, label })),
  R.pathOr({}, [integrationType]),
)(EXTERNAL_DOCUMENT_TYPE_MAP);

const enhance = compose(
  withFormik({
    validationSchema: documentTypeMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDocumentTypeMappingFields,
      initialValues,
    ),
  }),
  pure,
);

const DocumentTypeMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      fields={documentTypeMappingFieldSettings}
      handlers={{ disableIntegrationType: () => props.isEditMode }}
      optionsForSelect={{
        documentTypeConfigOptions: props.documentTypeConfigOptions,
        documentTypeOptions: getDocumentTypeOptions(
          R.pathOr(GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN, ['values', GC.FIELD_INTEGRATION_TYPE], props),
        ),
      }}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(DocumentTypeMappingForm);
