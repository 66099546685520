import React from 'react';
// components
import { InfoPairWithLabelAction } from '../../dispatch-details-new/components/info-pair';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const InfoPairComponent = () => {
  const description = '<InfoPair /> is a component that presents a labeled piece of information alongside an actionable label.';

  const usage = (
    <ul>
      <li>Parameter `<i>text</i>` is the information to display.</li>
      <li>Parameter `<i>label</i>` is the label describing the information.</li>
      <li>Parameter `<i>labelActionText</i>` is the label text for the action.</li>
      <li>Parameter `<i>action</i>` is the action triggered on clicking the action label.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='InfoPair'
      description={description}
      path='src/components/info-pair'
    >
      <InfoPairWithLabelAction
        label='Order'
        text='$ 354.00'
        labelActionText='954'
        action={() => window.alert('Lorem lorem')}
      />
    </ComponentStoryWrapper>
  );
};

export default InfoPairComponent;
