import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
// components
import DateSelector from '../../../components/date-range';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature components-story
import { setDateSelectorValues } from '../actions';
import { makeSelectDateSelectorValues } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const DateSelectorComponent = ({ dateSelectorValues, setDateSelectorValues }: Object) => {
  const description = '<DateSelector /> component is used for selecting date ranges.';

  const usage = (
    <ul>
      <li>Parameter `<i>label</i>` sets a label for the component.</li>
      <li>Parameter `<i>dateTo</i>` represents the selected end date.</li>
      <li>Parameter `<i>dateFrom</i>` represents the selected start date.</li>
      <li>Parameter `<i>width</i>` sets the width of the date input field.</li>
      <li>Parameter `<i>quickDaysValue</i>` sets the selected value for quick day selection.</li>
      <li>Parameter `<i>popperPlacement</i>` specifies the placement of the date picker popup.</li>
      <li>Parameter `<i>setQuickDays</i>` is a function to set the value for quick day selection.</li>
      <li>Parameter `<i>quickDays</i>` is an array of values for quick selection of the number of days.</li>
      <li>Parameter `<i>withIcon</i>` determines whether to display a calendar icon next to the input field.</li>
      <li>Parameter `<i>onSelectDateRange</i>` is a higher-order function that is used as a callback to handle the selection of a date range.</li>
    </ul>
  );

  const handleSetFilterQuickDays = (value: number) => setDateSelectorValues(
    {
      ...dateSelectorValues,
      quickDaysValue: value,
      dateFrom: G.subtractMomentTime(R.prop('dateTo', dateSelectorValues), value, 'days'),
    },
  );

  useEffect(() => {
    const currentDate = G.getCurrentDate();

    setDateSelectorValues({
      quickDaysValue: 14,
      dateTo: currentDate,
      dateFrom: G.subtractMomentTime(currentDate, 14, 'days'),
    });
  }, []);

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='DateSelector'
      description={description}
      path='src/components/date-range'
    >
      <DateSelector
        {...dateSelectorValues}
        width={120}
        withIcon={true}
        popperPlacement='bottom-start'
        quickDays={GC.QUICK_DAYS_FILTER}
        setQuickDays={handleSetFilterQuickDays}
        onSelectDateRange={setDateSelectorValues}
        label={G.getWindowLocale('titles:date-range', 'Date Range')}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  dateSelectorValues: makeSelectDateSelectorValues(state),
});

export default connect(mapStateToProps, {
  setDateSelectorValues,
})(DateSelectorComponent);
