import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const safetyTypeMap = {
  [GC.SAFETY_TYPE_MODERATE]: 'Moderate',
  [GC.SAFETY_TYPE_ACCEPTABLE]: 'Acceptable',
  [GC.SAFETY_TYPE_UNACCEPTABLE]: 'Unacceptable',
};

const safetyRatingTypeMap = {
  [GC.SAFETY_RATING_TYPE_NOT_RATED]: 'Not Rated',
  [GC.SAFETY_RATING_TYPE_CONDITIONAL]: 'Conditional',
  [GC.SAFETY_RATING_TYPE_SATISFACTORY]: 'Satisfactory',
  [GC.SAFETY_RATING_TYPE_UNSATISFACTORY]: 'Unsatisfactory',
};

const safetyAuthorityTypes = {
  [GC.SAFETY_AUTHORITY_TYPE_NONE]: 'None',
  [GC.SAFETY_AUTHORITY_TYPE_ACTIVE]: 'Active',
  [GC.SAFETY_AUTHORITY_TYPE_INACTIVE]: 'Inactive',
};

const fields = {
  [GC.CARRIER_SAFETY_COMMON_AUTHORITY]: {
    width: 200,
    name: 'titles:common-authority',
    customLogic: (value: Object) => R.prop(value, safetyAuthorityTypes),
  },
  [GC.CARRIER_SAFETY_BROKER_AUTHORITY]: {
    width: 200,
    name: 'titles:broker-authority',
    customLogic: (value: Object) => R.prop(value, safetyAuthorityTypes),
  },
  [GC.CARRIER_SAFETY_NUMBER_OF_TRUCKS]: {
    width: 200,
    name: 'titles:number-of-trucks',
  },
  [GC.CARRIER_SAFETY_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.CARRIER_SAFETY_US_VEHICLE_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-vehicle-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_DRIVER_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-driver-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_HAZMAT_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-hazmat-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_IEP_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:us-iep-oos-percentage',
  },
  [GC.CARRIER_SAFETY_US_VEHICLE_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-vehicle-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_US_DRIVER_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-driver-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_US_HAZMAT_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-hazmat-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_US_IEP_NATL_EVG_PERCENTAGE]: {
    width: 250,
    name: 'titles:us-iep-natl-evg-percentage',
  },
  [GC.CARRIER_SAFETY_CAN_VEHICLE_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:can-vehicle-oos-percentage',
  },
  [GC.CARRIER_SAFETY_CAN_DRIVER_OOS_PERCENTAGE]: {
    width: 200,
    name: 'titles:can-driver-oos-percentage',
  },
  // saferWatch fields
  [GC.CARRIER_SAFETY_OVERALL_RISK_INDICATOR]: {
    width: 250,
    name: 'titles:overall-carrier-risk-indicator',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_AUTHORITY]: {
    width: 200,
    name: 'titles:authority',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_INSURANCE]: {
    width: 200,
    name: 'titles:insurance',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_SAFETY]: {
    width: 200,
    name: 'titles:safety-csa',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_OPERATIONS]: {
    width: 200,
    name: 'titles:operations',
    customLogic: (value: Object) => R.prop(value, safetyTypeMap),
  },
  [GC.CARRIER_SAFETY_SAFETY_RATING]: {
    width: 200,
    name: 'titles:carrier-safety-rating',
    customLogic: (value: Object) => R.prop(value, safetyRatingTypeMap),
  },
  [GC.CARRIER_SAFETY_UNSAFE_DRIVING]: {
    width: 200,
    name: 'titles:unsafe-drv',
  },
  [GC.CARRIER_SAFETY_DRIVER_FITNESS]: {
    width: 200,
    name: 'titles:drv-fitness',
  },
  [GC.CARRIER_SAFETY_VEHICLE_MAINT]: {
    width: 200,
    name: 'titles:vehicle-maint',
  },
  [GC.CARRIER_SAFETY_DRUG_ALCOHOL]: {
    width: 200,
    name: 'titles:drug-alcohol',
  },
};

export const safetySettings = {
  fields,
  singleItem: '',
  actionsPicker: [],
  groupName: 'safety',
  hideIfEmptyList: true,
  hideEmptyFields: true,
  formGroupTitleArr: ['titles:safety-information', 'Safety Information'],
  endpoints: {
    list: 'carrierSafety',
  },
};
