import * as R from 'ramda';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import React, { Fragment, useMemo, useEffect } from 'react';
// components
import { PageTitle } from '../../../components/page-title';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, ActionButton } from '../../../ui';
// feature carrier-profile
import PageActions from '../components/page-actions';
import { getBranchListByBranchTypeRequest } from '../actions';
import { fieldSettings, validationSchema } from '../settings/general-details';
import { makeSelectBranches, makeSelectDropdownOptionsByDropdownNames } from '../selectors';
//////////////////////////////////////////////////

const submitStyles = {
  mt: 20,
  ml: 'auto',
  height: 32,
  width: 120,
  p: '4px 8px',
  color: 'white',
  bg: 'dark.blue',
  display: 'block',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const GeneralDetails = (props: Object) => {
  const {
    dispatch,
    openModal,
    closeModal,
    submitAction,
    initialValues,
    setInternalDivision,
  } = props;

  const { guid } = initialValues;

  const branches = useSelector(makeSelectBranches());

  const configOptions = useSelector((state: Object) => makeSelectDropdownOptionsByDropdownNames(
    state,
    `${GC.GENERAL_EQUIPMENTS},${GC.CARRIER_DEACTIVATED_TYPE},${GC.GENERAL_MODE_TRANSPORTATION}`,
  ));

  const divisions = R.prop(GC.BRANCH_TYPE_ENUM_DIVISION, branches);

  const divisionOptions = useMemo(
    () => R.compose(
      G.addEmptyOptionToDropDown,
      R.map(({ guid, name }: Object) => ({ [GC.FIELD_VALUE]: guid, [GC.FIELD_LABEL]: name })),
    )(R.or(divisions, [])),
    [divisions],
  );

  useEffect(() => {
    if (R.isNil(guid)) return;

    if (R.isNil(divisions)) {
      dispatch(getBranchListByBranchTypeRequest());
    }
  }, [guid, divisions]);

  const onSubmit = (values: Object, { setSubmitting }: Object) =>
    submitAction(G.mapObjectEmptyStringFieldsToNull(values), { setSubmitting });

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const { values, isSubmitting, handleSubmit } = formik;

  const { name, active } = initialValues;

  return (
    <Fragment>
      <Flex>
        <PageTitle
          mr={10}
          title={name}
          status={active}
          showStatus={true}
          display='inline-flex'
        />
        <PageActions
          values={values}
          dispatch={dispatch}
          openModal={openModal}
          closeModal={closeModal}
          initialValues={initialValues}
        />
      </Flex>
      <Box mb={20} bg='white' p={20}>
        <form onSubmit={handleSubmit} id='general_details'>
          <Fieldset2
            {...G.getFormikPropsToFieldset(formik)}
            {...configOptions}
            fields={fieldSettings}
            divisionOptions={divisionOptions}
            setInternalDivision={setInternalDivision}
            fieldsWrapperStyles={{
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          />
          <ActionButton
            {...submitStyles}
            disabled={R.or(isSubmitting, R.equals(values, initialValues))}
          >
            {G.getWindowLocale('actions:update', 'Update')}
          </ActionButton>
        </form>
      </Box>
    </Fragment>
  );
};

export default GeneralDetails;
