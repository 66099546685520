import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
// components
import { Label } from '../../../components/label';
// forms
import AccessorialForm from '../../../forms/forms/accessorial-form';
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// icons
import * as I from '../../../svgs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature carrier-profile
import FormGroupTable from '../components/form-group-table';
import { makeSelectSharedAccessorials, makeSelectAccessorialConfigs } from '../selectors';
import {
  removeEntityRequest,
  createOrUpdateEntityRequest,
  getAccessorialConfigListRequest,
  getSharedAccessorialsListRequest,
} from '../actions';
//////////////////////////////////////////////////

const fields = {
  [GC.FIELD_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:assessorial',
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      return R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_DISPLAYED_VALUE],
        callbackData,
      );
    },
  },
  shared: {
    width: 100,
    uiType: 'boolean',
    name: 'titles:shared',
  },
  [GC.FIELD_FUEL_RELATED]: {
    width: 100,
    name: 'titles:fuel-related',
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const fuelRelated = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        callbackData,
      );

      return G.ifElse(fuelRelated, I.uiTrue, I.uiFalse)();
    },
  },
  [GC.FIELD_NON_TAXABLE]: {
    width: 100,
    name: 'titles:non-taxable',
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const nonTaxable = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_NON_TAXABLE],
        callbackData,
      );

      return G.ifElse(nonTaxable, I.uiTrue, I.uiFalse)();
    },
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 100,
    name: 'titles:rate',
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 150,
    name: 'titles:rate-type',
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 150,
    name: 'titles:rate-unit',
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 110,
    name: 'titles:min-rate',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 110,
    name: 'titles:max-rate',
  },
  [GC.FIELD_MIN_STOPS]: {
    width: 110,
    name: 'titles:min-stops',
  },
  [GC.FIELD_MAX_STOPS]: {
    width: 110,
    name: 'titles:max-stops',
  },
  [GC.FIELD_ACCESSORIAL_DISTRICT]: {
    width: 150,
    name: 'titles:district',
    customComponent: ({ data }: Object) => {
      const district = G.getPropFromObject(GC.FIELD_ACCESSORIAL_DISTRICT, data);

      return R.pathOr(district, [district], GC.districtMap);
    },
  },
  [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: {
    width: 150,
    uiType: 'boolean',
    name: 'titles:use-fuel-index',
  },
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: {
    width: 150,
    name: 'titles:fuel-index-quantity',
    customComponent: R.compose(
      R.length,
      R.path(['data', GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]),
    ),
  },
};

const SharedAccessorialForm = (props: Object) => {
  const { submitAction, getSharedAccessorialsListRequest} = props;

  const [sharedAccessorials, setSharedAccessorials] = useState(R.prop('sharedAccessorials', props));

  useEffect(() => {
    if (R.isNil(sharedAccessorials)) {
      getSharedAccessorialsListRequest(setSharedAccessorials);
    }
  }, []);

  const options = G.mapNameGuidObjectPropsToLabelValueObject(R.or(sharedAccessorials, []));

  return (
    <SelectDropdownForm
      isMulti={true}
      options={options}
      submitAction={submitAction}
      fieldLabel={['titles:shared-accessorials']}
    />
  );
};

const AdditionalFormGroupTitleComponent = (props: Object) => {
  const {
    dispatch,
    openModal,
    groupName,
    primaryObjectGuid,
    getItemListRequest,
    primaryObjectBranchGuid,
  } = props;

  const sharedAccessorials = useSelector(makeSelectSharedAccessorials());

  const handleAddSharedAccessorial = useCallback(() => {
    const map = {
      accessorial: 'addCarrierSharedAccessorials',
      'carrierContract.accessorial': 'addCarrierContractSharedAccessorials',
      'customerContract.accessorial': 'addCustomerContractSharedAccessorials',
      [`contractRate.${primaryObjectGuid}.accessorial`]: 'addRatePriceSharedAccessorials',
      [`awardedLine.${primaryObjectGuid}.accessorial`]: 'addAwardedLineSharedAccessorials',
    };

    const submitAction = (sharedAssesorialsGuids: Array) => dispatch(createOrUpdateEntityRequest({
      method: 'post',
      getItemListRequest,
      additionalOptions: {},
      groupName: 'accessorial',
      endpoint: endpointsMap[R.prop(groupName, map)],
      values: { primaryObjectGuid, sharedAssesorialsGuids },
    }));

    const handleGetSharedAccessorialsListRequest = (callback: Function) =>
      dispatch(getSharedAccessorialsListRequest({ callback, branchGuid: primaryObjectBranchGuid }));

    const component = (
      <SharedAccessorialForm
        submitAction={submitAction}
        sharedAccessorials={sharedAccessorials}
        getSharedAccessorialsListRequest={handleGetSharedAccessorialsListRequest}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        title: G.getAddTitle(['titles:shared-accessorials', 'Shared Accessorials']),
      },
    };

    openModal(modal);
  }, [sharedAccessorials]);

  return (
    <Label
      ml={15}
      width={160}
      endAction={() => handleAddSharedAccessorial()}
      endIcon={I.plusRound(G.getTheme('colors.white'))}
    >
      {G.getWindowLocale('titles:shared-accessorials', 'Shared Accessorials')}
    </Label>
  );
};

const group = {
  fields,
  groupName: 'accessorial',
  primaryObjectGuidKey: GC.FIELD_CARRIER_GUID,
  tableCallbackDataProps: ['accessorialConfigs'],
  itemTitleArr: ['titles:accessorial', 'Accessorial'],
  actionsPicker: ['edit', 'previewSharedAccessorial', 'remove'],
  formGroupTitleArr: ['titles:carrier-accessorials', 'Carrier Accessorials'],
  endpoints: {
    list: 'carrierAssessorialList',
    createOrUpdate: 'carrierAssessorial',
    remove: 'getCarrierAssessorialEndpoint',
  },
  // components
  AdditionalFormGroupTitleComponent,
  CustomForm: ({
    closeModal,
    submitAction,
    initialValues,
    optionsForSelect,
    primaryObjectGuid,
    primaryObjectGuidKey,
  }: Object) => (
    <AccessorialForm
      {...optionsForSelect}
      entity='carrier'
      closeModal={closeModal}
      submitAction={submitAction}
      initialValues={R.assoc(primaryObjectGuidKey, primaryObjectGuid, initialValues)}
    />
  ),
  // helpers
  makeOptionsForSelect: R.pathOr({}, ['accessorialConfigs']),
  getEditPermissions: ({ shared }: Object) => G.ifElse(shared, ''),
  customRemoveHandler: (props: Object, item: Object) => {
    const { dispatch, groupName, primaryObjectGuid } = props;

    const { guid, shared } = item;

    const map = {
      shared: {
        accessorial: endpointsMap.removeCarrierSharedAccessorials,
        'carrierContract.accessorial': endpointsMap.removeCarrierContractSharedAccessorials,
        'customerContract.accessorial': endpointsMap.removeCustomerContractSharedAccessorials,
        [`contractRate.${primaryObjectGuid}.accessorial`]: endpointsMap.removeRatePriceSharedAccessorials,
        [`awardedLine.${primaryObjectGuid}.accessorial`]: endpointsMap.removeAwardedLineSharedAccessorials,
      },
      general: {
        accessorial: endpointsMap.getCarrierAssessorialEndpoint(guid),
        'carrierContract.accessorial': endpointsMap.carrierContractAssessorial,
        'customerContract.accessorial': endpointsMap.customerContractAssessorial,
        [`contractRate.${primaryObjectGuid}.accessorial`]: endpointsMap.getCarrierContractRateAssessorial(guid),
        [`awardedLine.${primaryObjectGuid}.accessorial`]: endpointsMap.getCarrierAwardedLineAssessorialEndpoint(guid),
      },
    };

    const method = G.ifElse(shared, 'post', 'delete');
    const endpoint = R.path([G.ifElse(shared, 'shared', 'general'), groupName], map);

    const requestOptions = G.ifElse(
      shared,
      {
        data: {
          primaryObjectGuid,
          [GC.FIELD_ACCESSORIAL_SHARED_ACCESSORIAL_GUIDS]: R.of(Array, guid),
        },
      },
      { data: R.of(Array, guid) },
    );

    dispatch(removeEntityRequest({ guid, method, endpoint, groupName, requestOptions }));
  },
  makeInitialValues: (initialValues: Object = {}, { configGroup, accessorialConfigs }: Object) => {
    const { currency, assessorialGuid } = initialValues;

    const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, configGroup);

    return {
      ...initialValues,
      [GC.FIELD_CURRENCY]: R.or(currency, branchCurrency),
      [GC.FIELD_FUEL_RELATED]: R.pathOr(
        false,
        ['accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        accessorialConfigs,
      ),
      [GC.FIELD_NON_TAXABLE]: R.pathOr(
        false,
        ['accessorialConfigList', assessorialGuid, GC.FIELD_NON_TAXABLE],
        accessorialConfigs,
      ),
    };
  },
};

const carrierContractGroup = {
  ...group,
  groupName: 'carrierContract.accessorial',
  primaryObjectGuidKey: GC.FIELD_CONTRACT_GUID,
  endpoints: {
    list: 'carrierContractAssessorialList',
    createOrUpdate: 'carrierContractAssessorial',
    remove: 'getCarrierContractAssessorialEndpoint',
  },
};

const carrierContractAwardedLineGroup = (primaryObjectGuid: Object) => ({
  ...group,
  tableInnerWidth: 980,
  primaryObjectGuidKey: 'awardedLineGuid',
  groupName: `awardedLine.${primaryObjectGuid}.accessorial`,
  endpoints: {
    list: 'carrierContractAwardedLineAssessorialList',
    remove: 'getCarrierAwardedLineAssessorialEndpoint',
    createOrUpdate: 'carrierContractAwardedLineAssessorial',
  },
});

const carrierContractRateGroup = (primaryObjectGuid: Object) => ({
  ...group,
  tableInnerWidth: 980,
  primaryObjectGuidKey: 'ratePriceGuid',
  groupName: `contractRate.${primaryObjectGuid}.accessorial`,
  endpoints: {
    list: 'carrierContractRateAssessorialList',
    remove: 'getCarrierContractRateAssessorial',
    createOrUpdate: 'carrierContractRateAssessorial',
  },
});

const customerContractGroup = {
  ...group,
  groupName: 'customerContract.accessorial',
  primaryObjectGuidKey: GC.FIELD_CONTRACT_GUID,
  endpoints: {
    list: 'customerContractAssessorialList',
    createOrUpdate: 'customerContractAssessorial',
    remove: 'getCustomerContractAssessorialEndpoint',
  },
};

const AccessorialFormGroupTable = (props: Object) => {
  const {
    dispatch,
    primaryObjectGuid,
    primaryObjectBranchGuid,
    groupName = 'accessorial',
  } = props;

  const accessorialConfigs = useSelector(makeSelectAccessorialConfigs());

  const groupMap = {
    accessorial: group,
    'carrierContract.accessorial': carrierContractGroup,
    'customerContract.accessorial': customerContractGroup,
    [`contractRate.${primaryObjectGuid}.accessorial`]: carrierContractRateGroup(primaryObjectGuid),
    [`awardedLine.${primaryObjectGuid}.accessorial`]: carrierContractAwardedLineGroup(primaryObjectGuid),
  };

  useEffect(() => {
    if (R.isNil(accessorialConfigs)) {
      dispatch(getAccessorialConfigListRequest(primaryObjectBranchGuid));
    }
  }, []);

  if (R.isNil(accessorialConfigs)) return null;

  return (
    <FormGroupTable
      {...props}
      groupName={groupName}
      group={R.prop(groupName, groupMap)}
      accessorialConfigs={accessorialConfigs}
    />
  );
};

const accessorialSettings = {
  groupName: 'accessorial',
  Component: AccessorialFormGroupTable,
};

export {
  accessorialSettings,
  AccessorialFormGroupTable,
};
