import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { catchSaga } from '../../sagas';
// utilities
import endpointsMap from '../../utilities/endpoints';
import { sendRequest, fetchRequestByExternalUrl } from '../../utilities/http';
// feature app-release
import * as A from './actions';
//////////////////////////////////////////////////

function* getAppReleaseSaga() {
  try {
    const endpoint = endpointsMap.appRelease;

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAppReleaseSuccess(data));
    } else if (G.notEquals(data.error, GC.ACCESS_DENIED)) {
      yield call(G.handleFailResponse, res, 'handleGetAppReleaseSaga fail', false);
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetAppReleaseSaga exception');
  }
}

function* getAppReleaseSagaByExternalUrl() {
  try {
    const endpoint = endpointsMap.appReleaseByExternalUrl;

    const res = yield call(fetchRequestByExternalUrl, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAppReleaseByExternalUrlSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAppReleaseSagaByExternalUrl fail', false);
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getAppReleaseSagaByExternalUrl exception');
  }
}

function* handleCreateAppReleaseSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.appRelease, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createAppReleaseSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateAppReleaseSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(catchSaga, { error, name: 'handleCreateAppReleaseSaga' });
  }
}

function* handleUpdateAppReleaseSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'put', endpointsMap.appRelease, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.updateAppReleaseSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateAppReleaseSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(catchSaga, { error, name: 'handleUpdateAppReleaseSaga' });
  }
}

function* AppReleaseWatcherSaga() {
  yield takeLatest(A.createAppReleaseRequest, handleCreateAppReleaseSaga);
  yield takeLatest(A.updateAppReleaseRequest, handleUpdateAppReleaseSaga);
}

export default AppReleaseWatcherSaga;

export {
  getAppReleaseSaga,
  handleCreateAppReleaseSaga,
  handleUpdateAppReleaseSaga,
  getAppReleaseSagaByExternalUrl,
};
