import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier-profile
import { TableCell } from '../components/table-cells';
import { makeSelectItemListByGroupName } from '../selectors';
import FormGroupTable from '../components/form-group-table';
//////////////////////////////////////////////////

const payTypeOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: GC.PAYMENT_TERM_OPTION_FACTORING_COMPANY,
    label: G.getWindowLocale('titles:factoring-company', 'Factoring Company'),
  },
  {
    value: GC.PAYMENT_TERM_OPTION_DIRECT_PAY,
    label: G.getWindowLocale('titles:direct-pay', 'Direct Pay'),
  },
];

const fields = {
  [GC.FIELD_SELECTED]: {
    width: 100,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:selected',
    additionalInputWrapperStyles: { width: 'max-content' },
  },
  [GC.FIELD_LOCATION_NAME]: {
    width: 150,
    isRequired: true,
    name: 'titles:pay-to-name',
  },
  [GC.FIELD_PAY_TYPE]: {
    width: 150,
    type: 'select',
    isRequired: true,
    name: 'titles:pay-type',
    options: payTypeOptions,
    additionalInputWrapperStyles: { mr: 188 },
    customComponent: ({ data }: Object) =>
      <TableCell options={payTypeOptions} text={R.prop(GC.FIELD_PAY_TYPE, data)} />,
  },
  [GC.FIELD_ADDRESS]: {
    isRequired: true,
    name: 'titles:address',
    type: 'addressAutocomplete',
    addressFieldName: GC.FIELD_ADDRESS,
  },
  [GC.FIELD_ADDRESS_2]: {
    name: 'titles:address2',
  },
  [GC.FIELD_CITY]: {
    width: 150,
    isRequired: true,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 100,
    isRequired: true,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 100,
    isRequired: true,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    isRequired: true,
    type: 'countrySelect',
    name: 'titles:country',
  },
  [GC.FIELD_START_DATE]: {
    width: 150,
    type: 'datePicker',
    name: 'titles:start-date',
    calendarInputWrapperStyles: {
      width: '100%',
    },
  },
  contactEmails: {
    width: 300,
    type: 'multiEmail',
    name: 'titles:emails',
    additionalInputWrapperStyles: {
      mr: 295,
    },
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    type: 'textarea',
    name: 'titles:comments',
    additionalInputWrapperStyles: {
      width: '100%',
    },
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_ZIP]: G.yupStringRequired,
  [GC.FIELD_CITY]: G.yupStringRequired,
  [GC.FIELD_STATE]: G.yupStringRequired,
  [GC.FIELD_COUNTRY]: G.yupStringRequired,
  [GC.FIELD_ADDRESS]: G.yupStringRequired,
  [GC.FIELD_PAY_TYPE]: G.yupStringRequired,
  [GC.FIELD_LOCATION_NAME]: G.yupStringRequired,
});

const inputWrapperStyles = {
  mb: 25,
  width: 280,
};

const fieldsWrapperStyles = {
  width: 870,
};

const terminalPayToLocationSettings = {
  fields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'payToLocation',
  primaryObjectGuidKey: 'terminalFinancialGuid',
  omitFormFields: ['contactEmails', GC.FIELD_COMMENTS],
  itemTitleArr: ['titles:pay-to-location', 'Pay To Location'],
  formGroupTitleArr: ['titles:pay-to-location', 'Pay To Location'],
  omitColumns: ['contactEmails', GC.FIELD_COMMENTS, ...GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR_2],
  endpoints: {
    list: 'carrierTerminalPayLocationList',
    remove: 'getTerminalPayLocationEndpoint',
    createOrUpdate: 'carrierTerminalPayLocation',
  },
};

const payToLocationGroup = {
  ...terminalPayToLocationSettings,
  groupName: 'payToLocation',
  primaryObjectGuidKey: 'carrierFinancialGuid',
  endpoints: {
    list: 'carrierPayLocationList',
    createOrUpdate: 'carrierPayLocation',
    remove: 'getCarrierPayLocationEndpoint',
  },
};

const PayToLocationFormGroupTable = (props) => {
  const financeList = useSelector((state: Object) => makeSelectItemListByGroupName(state, 'finance'));

  const financeGuid = R.path([0, GC.FIELD_GUID], financeList);

  if (R.isNil(financeGuid)) return null;

  return <FormGroupTable {...props} group={payToLocationGroup} primaryObjectGuid={financeGuid} />;
};

const payToLocationSettings = {
  groupName: 'payToLocation',
  Component: PayToLocationFormGroupTable,
};

export {
  payToLocationSettings,
  terminalPayToLocationSettings,
};
