import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
import {
  addCarrierRatesRequest,
  callStatusCheckTelRequest,
  sendTelToMacroPointRequest,
  toggleMarkAsHotLoadByLoadTypeRequest,
} from '../../../common/actions';
// components
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { PageActions } from '../../../components/page-actions';
import { ConfirmComponent } from '../../../components/confirm';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import SendQuoteRequestForm from '../../../components/send-quote-request-form';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { setInitialRouteGuid } from '../../dispatch-planner/actions';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { setInitialPlannerData } from '../../dispatch-planner-events/actions';
import ReferenceFormComponent from '../../reference/components/reference-form';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
import { makeSelectTelDispatchBoardActionPanel } from '../../configurations/selectors';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
import MassCreateCarrierRateForm from '../../../forms/forms/mass-carrier-rate-form';
import { withUpdateContainerForm } from '../../../forms/hocs/with-update-container-form';
import { PostTelToLoadboardsWithAsyncTELData } from '../../../forms/forms/post-tel-to-loadboard-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// // hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { ListWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import routesMap, { getOrderEntryRoute } from '../../../utilities/routes';
// feature dispatch-board-new
import * as H from '../helpers';
import GroupTable from '../components/table';
import RowActions from './components/row-actions';
import { TOGGLE_SHIP_UNIT_CELL } from '../constants';
import { getTELFilterParams } from '../settings/filter-params';
import { getColumnSettings } from '../settings/table-settings';
import LeftTitleSection from '../components/left-title-section';
import { getGroupByOptions, getSummaryOptions } from '../settings';
import {
  withSelectLoads,
  withPostToLoadBoard,
  withLoadMessageNoteClick,
  withSendTelsToExternalSystem,
} from '../hocs';
import {
  makeSelectModeOptions,
  makeSelectConfigCurrency,
  makeSelectServiceOptions,
  makeSelectConfigAutodialName,
  makeSelectCrossDockLocationOptions,
  makeSelectConfigsForTelGlobalSearch,
  makeSelectConfigShowTransportationMode,
} from '../selectors';
import {
  makeSelectSummary,
  makeSelectGroupBy,
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTelNotifications,
  makeSelectGlobalFilterType,
  makeSelectGlobalFilterValue,
  makeSelectTableTitleFilters,
  makeSelectShipUnitsExpanded,
} from './selectors';
import {
  selectItem,
  setReports,
  setGroupBy,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  getSummaryRequest,
  setUsedReportOnly,
  setTableTitleSort,
  getItemListRequest,
  setGlobalFilterType,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setGlobalFilterValue,
  setShipUnitsExpanded,
  createReferenceRequest,
  dispatchTelListRequest,
  resetListAndPagination,
  updateContainerCallback,
  getLoadsShipUnitRequest,
  toggleLoadDetailsRequest,
  toggleLoadDetailsSuccess,
  changeDefaultReportRequest,
  hideTelNotificationsRequest,
  addCarrierRatesByReportRequest,
  markAsReadTelNotificationRequest,
} from './actions';
/////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withState('originalReport', 'setOriginalReport', null),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withSelectLoads,
  withUpdateContainerForm,
  withPostToLoadBoard,
  withSendTelsToExternalSystem,
  withHandlers({
    handleListRequest: ({ getItemListRequest }: Object) => (isInitial: boolean = false) => (
      getItemListRequest(isInitial)
    ),
    getQuickFilteredListRequest: (props: Object) => () => {
      props.resetListAndPagination();
      props.getItemListRequest(true);
    },
    handleAddReference: (props: Object) => (guid: string) => {
      const { openModal, selectedList, createReferenceRequest } = props;

      const list = G.ifElse(
        G.isString(guid),
        R.of(Array, guid),
        selectedList,
      );

      if (R.isEmpty(list)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const component = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_TEL}
          submitAction={
            (values: Object) => createReferenceRequest(R.assoc('primaryObjectGuids', list, values))
          }
        />
      );
      const modal = G.createAddReferenceModalOptions(component);
      openModal(modal);
    },
    handleEditReport: (props: Object) => () => {
      const {
        openModal,
        modeOptions,
        setUsedReport,
        selectedReport,
        requestPending,
        serviceOptions,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
        crossDockLocationOptions,
      } = props;

      const component = (
        <EditReport
          showReportSummary={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          disableSetReportFields={true}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
          groupByOptions={getGroupByOptions(GC.LOAD_TYPE_TEL)}
          summaryOptions={getSummaryOptions(GC.LOAD_TYPE_TEL)}
          fields={getTELFilterParams({ modeOptions, serviceOptions, crossDockLocationOptions })}
        />
      );

      const modal = G.getDefaultReportModal(component, 1175);

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), props.reportList);
      props.setUsedReport(selectedReport);
      props.getItemListRequest(true);
    },
    handleChangeReportParams: (props: Object) => (data: Object) => (
      props.setReportFromPrompt(data)
    ),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest(true);
      }

      setPromptStatus(false);
    },
    handleCleanFilter: (props: Object) => () => {
      props.cleanQuickFilter();
      props.getItemListRequest(true);
    },
    handleDispatchTelList: (props: Object) => () => {
      const { openModal, closeModal, selectedList, documentTemplates, dispatchTelListRequest } = props;

      if (G.isNilOrEmpty(selectedList)) {
        const message = `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')} ${
          G.getWindowLocale('titles:tel', 'TEL')}!`;

        return G.showToastrMessageSimple('info', message);
      }

      const options = G.mapNameGuidToLabelValue(
          G.getPropFromObject(GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS, documentTemplates),
      );
      const submitAction = (documentTemplateGuid: string) => {
        let data = { [GC.FIELD_TEL_GUIDS]: selectedList };

        if (G.isNotNilAndNotEmpty(documentTemplateGuid)) {
          data = R.assoc(GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID, documentTemplateGuid, data);
        }

        dispatchTelListRequest(data);
      };
      const component = (
        <SelectDropdownForm
          options={options}
          optionRequired={false}
          cancelAction={closeModal}
          submitAction={submitAction}
          fieldLabel={G.getWindowLocale('titles:dispatch-document', 'Dispatch Document')}
        />
      );
      const modal = { p: 15, component, options: {} };

      openModal(modal);
    },
    handlePostTelToLoadBoard: (props: Object) => (entity: Object, massAction: boolean = true) => {
      const { openModal, closeModal, selectedList } = props;

      if (R.and(R.isEmpty(selectedList), massAction)) {
        return G.showToastrMessageSimple(
          'info',
          `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')}
           ${R.toUpper(G.getWindowLocale('titles:tel', 'TEL'))}!`,
        );
      }

      let guids = [];

      if (G.isFalse(massAction)) {
        guids = R.of(Array, G.getGuidFromObject(entity));
      } else {
        guids = selectedList;
      }

      const modal = {
        p: 15,
        component: (
          <PostTelToLoadboardsWithAsyncTELData
            width={500}
            guids={guids}
            cancelAction={closeModal}
          />
        ),
        options: {
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
        },
      };

      openModal(modal);
    },
    handleCallStatusCheck: (props: Object) => (guid: string) => {
      const { openModal, closeModal, selectedList, callStatusCheckTelRequest } = props;

      const list = G.ifElse(
        G.isString(guid),
        R.of(Array, guid),
        selectedList,
      );

      if (G.isNilOrEmpty(list)) {
        return G.showToastrMessageSimple(
          'info',
          `${G.getWindowLocale('titles:please-select-a', 'Please, select a')}
            ${R.toUpper(G.getWindowLocale('titles:tel', 'TEL'))}`,
        );
      }

      const component = (
        <ConfirmComponent textLocale={G.getWindowLocale('message:common-confirmation', 'Are you sure?')} />
      );
      const actions = () => {
        callStatusCheckTelRequest(list);
        closeModal();
      };
      const modal = {
        component,
        options: {
          width: 440,
          height: 'auto',
          movable: false,
          title: G.getWindowLocale('titles:check-status', 'Check Status'),
          controlButtons: [
            {
              type: 'button',
              action: actions,
              margin: '0 12px 0 0',
              name: G.getWindowLocale('actions:send', 'Send'),
            },
          ],
        },
      };
      openModal(modal);
    },
    handleSetGlobalFilter: (props: Object) => (value: string, changedGlobalFilterType: boolean) => {
      if (R.and(R.equals(props.globalFilterValue, value), R.not(changedGlobalFilterType))) return;

      props.setGlobalFilterValue(value);
      props.resetListAndPagination();
      props.getItemListRequest(true);
    },
    handleSetGroupBy: (props: Object) => (group: Object) => {
      props.setGroupBy(group.value);
      props.resetListAndPagination();
      props.getItemListRequest(true);
    },
    handleSummaryFilter: (props: Object) => (group: string, groupValue: string, parent: Object) => {
      const report = H.getReportWithSummary(group, groupValue, parent, props.selectedReport);
      props.setUsedReport(report);
      props.getItemListRequest(true);
    },
    handleSetSummaryGroup: (props: Object) => (name: string, groupValue: string) => {
      const report = R.assoc(name, groupValue, props.selectedReport);
      props.setUsedReportOnly(report);
    },
    handleCleanSummaryFilter: (props: Object) => (filter: string | Object) => {
      let summaryFilters = [];

      if (G.notEquals(filter, 'all')) {
        summaryFilters = R.filter(
          (summaryFilter: Object) => R.not(R.propEq(R.path(['summaryGroup'], filter), 'summaryGroup', summaryFilter)),
          R.path(['selectedReport', 'summaryFilters'], props),
        );
      }

      const report = R.assoc('summaryFilters', summaryFilters, props.selectedReport);
      props.setUsedReport(report);
      props.getItemListRequest(true);
    },
    handleSendTelToMacroPoint: (props: Object) => (guid: string) => {
      const { selectedList, closeFixedPopup, sendTelToMacroPointRequest } = props;

      closeFixedPopup();
      const guids = G.ifElse(
        G.isString(guid),
        R.of(Array, guid),
        selectedList,
      );

      if (G.isNilOrEmpty(guids)) {
        return G.showToastrMessageSimple(
          'info',
          `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')}
           ${R.toUpper(G.getWindowLocale('titles:tel', 'TEL'))}!`,
        );
      }

      sendTelToMacroPointRequest({ guids });
    },
    handleAddCarrierRate: (props: Object) => () => {
      const { openModal, closeModal, selectedList, addCarrierRatesRequest } = props;

      if (G.isNilOrEmpty(selectedList)) {
        const message = `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')} ${
          G.getWindowLocale('titles:tel', 'TEL')}!`;

        return G.showToastrMessageSimple('info', message);
      }

      const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
      const submitAction = (values: Object) => addCarrierRatesRequest({
        ...values,
        telGuids: selectedList,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      });
      const component = (
        <MassCreateCarrierRateForm
          branchGuid={branchGuid}
          closeModal={closeModal}
          submitAction={submitAction}
        />
      );
      const modal = {
        p: '0',
        component,
        options: {
          width: 300,
          title: G.getWindowLocale('titles:mass-add-carrier-rate', 'Mass Add Carrier Rate'),
        },
      };

      openModal(modal);
    },
    handleAddCarrierRateByReport: (props: Object) => () => {
      const { openModal, closeModal, addCarrierRatesByReportRequest} = props;

      const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
      const submitAction = (values: Object) => (
        addCarrierRatesByReportRequest(R.assoc(GC.FIELD_CURRENT_BRANCH, branchGuid, values))
      );
      const component = (
        <MassCreateCarrierRateForm
          branchGuid={branchGuid}
          closeModal={closeModal}
          submitAction={submitAction}
        />
      );
      const modal = {
        p: '0',
        component,
        options: {
          width: 300,
          title: G.getWindowLocale('titles:mass-add-carrier-rate-by-report', 'Mass Add Carrier Rate By Report'),
        },
      };

      openModal(modal);
    },
    handleSendQuoteRequests: (props: Object) => (guid: string) => {
      const { openModal, selectedList, closeFixedPopup } = props;

      closeFixedPopup();
      const guids = G.ifElse(
        G.isString(guid),
        R.of(Array, guid),
        selectedList,
      );

      if (G.isNilOrEmpty(guids)) {
        return G.showToastrMessageSimple(
          'info',
          `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')} ${
            R.toUpper(G.getWindowLocale('titles:tel', 'TEL'))}!`,
        );
      }

      const component = <SendQuoteRequestForm telGuids={guids} />;

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:send-carriers-bids', 'Send Carriers Bids'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        version: 2,
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            entity={data}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            addReference={props.handleAddReference}
            callStatusCheck={props.handleCallStatusCheck}
            setInitialRouteGuid={props.setInitialRouteGuid}
            handlePostEquipment={props.handlePostEquipment}
            postToLoadBoard={props.handlePostTelToLoadBoard}
            setInitialPlannerData={props.setInitialPlannerData}
            handleSendQuoteRequests={props.handleSendQuoteRequests}
            handleSendTelToMacroPoint={props.handleSendTelToMacroPoint}
            handleSendTelToExternalSystem={props.handleSendTelToExternalSystem}
            toggleMarkAsHotLoadByLoadTypeRequest={props.toggleMarkAsHotLoadByLoadTypeRequest}
            handleDeleteTel={(data: Object) => {
              props.setSelectedList(R.without(data[GC.FIELD_GUID], props.selectedList));
              props.deleteItemRequest(data);
            }}
          />
        ),
      })
    ),
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleTitleCellClick: (props: Object) => (fieldName: string) => {
      if (R.equals(fieldName, TOGGLE_SHIP_UNIT_CELL)) {
        const { itemList, shipUnitsExpanded, setShipUnitsExpanded, getLoadsShipUnitRequest } = props;

        if (shipUnitsExpanded) {
          return setShipUnitsExpanded(false);
        }

        getLoadsShipUnitRequest(H.getItemListGuids(itemList));
        setShipUnitsExpanded(true);
      }
    },
  }),
  withLoadMessageNoteClick,
  withPromptModal(getTELFilterParams({ modeOptions: [], serviceOptions: [], crossDockLocationOptions: [] })),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      G.isNilOrEmpty(props.selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const RouteListLoad = (props: Object) => {
  const {
    summary,
    groupBy,
    itemList,
    openModal,
    closeModal,
    totalCount,
    pagination,
    actionPanel,
    autodialApp,
    modeOptions,
    selectedList,
    notifications,
    selectedReport,
    configCurrency,
    openFixedPopup,
    serviceOptions,
    titleSortValues,
    closeFixedPopup,
    handleSelectAll,
    handleSelectLoad,
    handleSetGroupBy,
    handleEditReport,
    globalFilterType,
    globalFilterValue,
    getSummaryRequest,
    tableTitleFilters,
    shipUnitsExpanded,
    getItemListRequest,
    handleAddReference,
    handleClickEditIcon,
    setGlobalFilterType,
    handleSummaryFilter,
    handleAddCarrierRate,
    handleTitleCellClick,
    handleUpdateContainer,
    handleDispatchTelList,
    handleSetGlobalFilter,
    handleCallStatusCheck,
    handleSetSummaryGroup,
    handleMessageNoteClick,
    handleTableTitleFilter,
    showTransportationMode,
    handleSendQuoteRequests,
    handlePostTelToLoadBoard,
    handleCleanSummaryFilter,
    crossDockLocationOptions,
    toggleLoadDetailsRequest,
    handleShowLoadReferences,
    configsForTelGlobalSearch,
    handleSendTelToMacroPoint,
    hideTelNotificationsRequest,
    handleAddCarrierRateByReport,
    handleSendTelsToExternalSystem,
    markAsReadTelNotificationRequest,
  } = props;

  const mainLightColor = G.getTheme('colors.light.mainLight');

  const listActionsOpt = [
    {
      type: 'massAction',
      action: handlePostTelToLoadBoard,
      icon: I.plusRound(mainLightColor, 25, 25),
      permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
      text: G.getWindowLocale('titles:post-to-loadboard', 'Post to Load Board'),
    },
    {
      type: 'massAction',
      permissions: [PC.TEL_WRITE],
      action: handleSendTelToMacroPoint,
      icon: I.callCheck(mainLightColor, 22, 22),
      text: G.getWindowLocale('actions:activate-macro-point', 'Activate MacroPoint'),
    },
    {
      type: 'massAction',
      permissions: [PC.TEL_WRITE],
      action: handleSendTelsToExternalSystem,
      icon: I.locationMarker(mainLightColor, 22, 22),
      text: G.getWindowLocale('titles:send-to-external-system', 'Send to External System'),
    },
    {
      type: 'massAction',
      permissions: [PC.TEL_WRITE],
      action: handleAddReference,
      icon: I.plusRound(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    },
    {
      type: 'massAction',
      permissions: [PC.TEL_WRITE],
      action: handleCallStatusCheck,
      text: G.getWindowLocale('actions:status-check', 'Call Status Check'),
      icon: I.callCheck(G.getTheme('dispatchBoardPopper.whiteTextColor'), 22, 22),
    },
    {
      permissions: [PC.ORDER_QUOTE_WRITE],
      action: () => G.goToRoute(GC.ROUTE_PATH_DO_QUOTE),
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('actions:create-quote', 'Create Quote'),
    },
    {
      type: 'massAction',
      action: handleDispatchTelList,
      permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('actions:mass-dispatch', 'Mass Dispatch'),
    },
    {
      type: 'massAction',
      action: handleAddCarrierRate,
      permissions: [PC.CARRIER_RATE_WRITE],
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('titles:mass-add-carrier-rate', 'Mass Add Carrier Rate'),
    },
    {
      permissions: [PC.CARRIER_RATE_WRITE],
      action: handleAddCarrierRateByReport,
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('titles:mass-add-carrier-rate-by-report', 'Mass Add Carrier Rate By Report'),
    },
    {
      type: 'massAction',
      action: handleSendQuoteRequests,
      icon: I.gearSolid(mainLightColor),
      permissions: [PC.CARRIER_RATE_WRITE],
      text: G.getWindowLocale('titles:send-carriers-bids', 'Send Carriers Bids'),
    },
  ];

  const customTitleComponent = (
    <LeftTitleSection
      groupBy={groupBy}
      summary={summary}
      totalCount={totalCount}
      loadType={GC.LOAD_TYPE_TEL}
      selectedReport={selectedReport}
      globalFilterType={globalFilterType}
      handleEditReport={handleEditReport}
      globalFilterValue={globalFilterValue}
      getSummaryRequest={getSummaryRequest}
      handleSelectGroupBy={handleSetGroupBy}
      handleSummaryFilter={handleSummaryFilter}
      setGlobalFilterType={setGlobalFilterType}
      handleSetGlobalFilter={handleSetGlobalFilter}
      handleSetSummaryGroup={handleSetSummaryGroup}
      configsForGlobalSearch={configsForTelGlobalSearch}
      handleCleanSummaryFilter={handleCleanSummaryFilter}
    />
  );

  const columnSettingsData = {
    openModal,
    closeModal,
    autodialApp,
    selectedList,
    notifications,
    configCurrency,
    openFixedPopup,
    closeFixedPopup,
    handleSelectLoad,
    handleUpdateContainer,
    handleCallStatusCheck,
    handleMessageNoteClick,
    handleShowLoadReferences,
    loadType: GC.LOAD_TYPE_TEL,
    toggle: toggleLoadDetailsRequest,
    hideNotifications: hideTelNotificationsRequest,
    markAsReadTelNotification: markAsReadTelNotificationRequest,
    actionPanel: R.indexBy(R.prop(GC.FIELD_STATUS), actionPanel),
  };

  const rowBgFunction = (guid: string) => H.getRouteRowBgColor(R.values(R.pathOr({}, [guid], notifications)));

  return (
    <ListWrapper p={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          version={2}
          noExportable={true}
          popperWithCount={true}
          type={GC.ROUTE_TEL_REPORT}
          hiddenRightFilterInfo={false}
          customTitleComponent={customTitleComponent}
          exportPermissions={R.of(Array, PC.TEL_WRITE)}
          title={G.getWindowLocale('titles:tel', 'TEL')}
          pinnedReportsPanelAction={() => G.goToRoute(routesMap.telList)}
          filterProps={getTELFilterParams({ modeOptions, serviceOptions, crossDockLocationOptions })}
          pinnedReportsPanelActionTitle={G.getWindowLocale('titles:show-historical-trips', 'Show Historical Trips')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <GroupTable
          groupBy={groupBy}
          groups={itemList}
          totalCount={totalCount}
          pagination={pagination}
          modeOptions={modeOptions}
          routeTab={GC.LOAD_TYPE_TEL}
          selectedList={selectedList}
          rowBgFunction={rowBgFunction}
          titleSortValues={titleSortValues}
          handleSelectAll={handleSelectAll}
          tableTitleFilters={tableTitleFilters}
          shipUnitsExpanded={shipUnitsExpanded}
          handleClickEditIcon={handleClickEditIcon}
          handleTitleCellClick={handleTitleCellClick}
          handleLoadMoreEntities={getItemListRequest}
          handleTableTitleFilter={handleTableTitleFilter}
          showTransportationMode={showTransportationMode}
          columnSettings={getColumnSettings(columnSettingsData)}
        />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.TEL_WRITE]}>
        <PageActions
          version={2}
          listActions={listActionsOpt}
          count={R.length(selectedList)}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            permissions: [PC.CLO_WRITE],
            action: () => G.goToRoute(getOrderEntryRoute()),
            text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  groupBy: makeSelectGroupBy(state),
  summary: makeSelectSummary(state),
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  modeOptions: makeSelectModeOptions(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  configCurrency: makeSelectConfigCurrency(state),
  serviceOptions: makeSelectServiceOptions(state),
  notifications: makeSelectTelNotifications(state),
  autodialApp: makeSelectConfigAutodialName(state),
  titleSortValues: makeSelectTitleSortValues(state),
  globalFilterType: makeSelectGlobalFilterType(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  globalFilterValue: makeSelectGlobalFilterValue(state),
  shipUnitsExpanded: makeSelectShipUnitsExpanded(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  actionPanel: makeSelectTelDispatchBoardActionPanel(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  crossDockLocationOptions: makeSelectCrossDockLocationOptions(state),
  configsForTelGlobalSearch: makeSelectConfigsForTelGlobalSearch(state),
  showTransportationMode: makeSelectConfigShowTransportationMode(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setGroupBy,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  getSummaryRequest,
  setUsedReportOnly,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setGlobalFilterType,
  setInitialRouteGuid,
  setTableTitleFilter,
  setQuickFilterParams,
  setGlobalFilterValue,
  setShipUnitsExpanded,
  setInitialPlannerData,
  createReferenceRequest,
  resetListAndPagination,
  dispatchTelListRequest,
  addCarrierRatesRequest,
  updateContainerCallback,
  getLoadsShipUnitRequest,
  toggleLoadDetailsRequest,
  toggleLoadDetailsSuccess,
  callStatusCheckTelRequest,
  sendTelToMacroPointRequest,
  changeDefaultReportRequest,
  hideTelNotificationsRequest,
  setExpandedContainerOptions,
  addCarrierRatesByReportRequest,
  markAsReadTelNotificationRequest,
  toggleMarkAsHotLoadByLoadTypeRequest,
})(enhance(RouteListLoad));
