import * as R from 'ramda';
import React, { useCallback } from 'react';
// features
import References from '../../reference/components/reference';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature carrier-profile
import { useFormGroupOperations } from '../hooks/use-form-group-operations';
//////////////////////////////////////////////////
const ReferenceFormGroupTable = (props: Object) => {
  const { openModal, closeModal, primaryObjectGuid } = props;

  const {
    itemList,
    handleRemoveItem,
    handleCreateOrUpdateEntityRequest,
  } = useFormGroupOperations(props);

  const handleCreateOrUpdateReference = useCallback((values: Object) => handleCreateOrUpdateEntityRequest({
    groupName: 'references',
    endpoint: R.prop('carrierReference', endpointsMap),
    method: G.ifElse(R.has(GC.FIELD_VERSION, values), 'put', 'post'),
    values: R.assoc('primaryObjectGuids', R.of(Array, primaryObjectGuid), values),
  }), []);

  return (
    <References
      openModal={openModal}
      references={itemList}
      closeModal={closeModal}
      scope={GC.REF_SCOPE_NAME_CARRIER}
      handleRemoveReference={handleRemoveItem}
      handleCreateReference={handleCreateOrUpdateReference}
      handleUpdateReference={handleCreateOrUpdateReference}
    />
  );
};

export const referenceSettings = {
  groupName: 'references',
  Component: ReferenceFormGroupTable,
  itemTitleArr: ['titles:reference', 'reference'],
  primaryObjectGuidKey: GC.FIELD_GUID,
  endpoints: {
    list: 'carrierReferenceList',
    remove: 'getCarrierRefEndpoint',
    createOrUpdate: 'carrierReference',
  },
};
