import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// common
import { createLoadDocumentsRequest } from '../../../common/actions';
// components
import { LocalLoader } from '../../../components/local-loader';
import { ConfirmComponent } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { EditCustomerRateForm } from '../../rate/customer';
import { setInitialRouteGuid } from '../../dispatch-planner/actions';
import CustomerInvoiceForm from '../../invoice/components/customer-form';
import { setInitialPlannerData } from '../../dispatch-planner-events/actions';
import { makeSelectAvailableDocumentTypes } from '../../configurations/selectors';
import RouteTelForm, { getTelRouteModalOptions } from '../../new-do/forms/route-tel-form';
// forms
import CancelCLOForm from '../../../forms/forms/cancel-clo-form';
import AddDocumentsForm from '../../../forms/forms/add-load-documents-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncCloEvents } from '../../../hocs';
// utilities
import routesMap, { getOrderBySourceRoute } from '../../../utilities/routes';
// dispatch-board-new
import * as H from '../helpers';
import { withOrderDetails } from './with-order-details';
import { withSendCloToExternalSystem } from './with-send-clo-to-external-system';
import {
  makeSelectConfigsByGroup,
  makeSelectRouteTelConfigs,
  makeSelectConfigDefaultUOMSystem,
} from '../selectors';
import {
  cancelCLORequest,
  unquoteCLORequest,
  updateCloRateRequest,
  createTelFromCloRequest,
  changeCancelStatusRequest,
  createCustomerInvoiceRequest,
} from '../order/actions';
//////////////////////////////////////////////////

const DocumentFormWithAsyncEvents = withAsyncCloEvents((props: Object) => (
  <LocalLoader width={400} localLoaderOpen={R.isNil(props.asyncEvents)}>
    <AddDocumentsForm
      {...props}
      load={R.assoc(GC.FIELD_LOAD_STOPS, props.asyncEvents, props.load)}
    />
  </LocalLoader>
));

const getTotalWeight = (clo: Object) => {
  const items = R.pathOr([], [GC.FIELD_LOAD_ITEMS], clo);
  if (G.isNotNilAndNotEmpty(items)) {
    const total = G.calcItemsTotalWeightWithoutQty(items);
    return G.renameKeys({
      [GC.FIELD_ITEM_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
      [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    }, total);
  }
  return {};
};

const getTotalDistance = (clo: Object) => {
  const events = R.pathOr([], [GC.FIELD_LOAD_STOPS], clo);
  const distances = R.compose(
    R.filter((item: Object) => G.isNotNilAndNotEmpty(item)),
    R.map((item: Object) => R.prop(GC.FIELD_DISTANCE_CLO, item)),
    R.values,
  )(events);
  return G.calculateTotalDistance(distances);
};

const getData = (details: Object) => {
  if (R.isNil(details)) return;

  return {
    totalWeight: getTotalWeight(details),
    totalDistance: getTotalDistance(details),
    itemsVolume: G.calculateTotalVolumeWithoutQty(R.pathOr([], [GC.FIELD_LOAD_ITEMS], details)),
  };
};

const EditCustomerRateFormWithOrderDetails = withOrderDetails((props: Object) => {
  const { cloGuid, details, updateCloRateRequest } = props;

  const data = getData(details);

  const handleSendCloRate = (rateData: Object, loadData: Object) => updateCloRateRequest({
    rateData: R.assoc(GC.FIELD_CLO_GUID, cloGuid, rateData),
    loadData: G.ifElse(G.isNotNil(loadData), R.mergeRight(details, loadData)),
  });

  return (
    <LocalLoader bg='white' width={755} localLoaderOpen={R.isNil(details)}>
      <EditCustomerRateForm
        {...props}
        {...data}
        handleSendCloRate={handleSendCloRate}
        load={R.pick([GC.FIELD_SERVICES, GC.FIELD_LOAD_EQUIPMENT], R.or(details, {}))}
      />
    </LocalLoader>
  );
});

const CustomerInvoiceFormWithOrderDetails = withOrderDetails((props: Object) => {
  const { cloGuid, details, handleSendCloInvoice } = props;

  const data = getData(details);

  return (
    <LocalLoader localLoaderOpen={R.isNil(details)}>
      <CustomerInvoiceForm
        {...props}
        totalWeight={R.path(['totalWeight'], data)}
        totalDistance={R.path(['totalDistance'], data)}
        handleSendCloInvoice={(rateData: Object) => (
          handleSendCloInvoice(R.assoc('cloGuid', cloGuid, rateData))
        )}
      />
    </LocalLoader>
  );
});

const withOrderActionsWithoutConnectAndRateEnhance = compose(
  withSendCloToExternalSystem,
  withHandlers({
    handleCreateDocument: (props: Object) => (defaultProof: string, clo: Object) => {
      const { openModal, createLoadDocumentsRequest, getOrderDocumentListRequest } = props;
      const cloGuid = G.getGuidFromObject(clo);
      const handleCreateLoadDocument = (values: Object, callback: Function) => {
        const valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);
        let data;
        if (G.isNotNilAndNotEmpty(R.prop(GC.FIELD_DOCUMENT_UPLOAD, valuesToUse))) {
          const commonProps = R.omit([GC.FIELD_DOCUMENT_UPLOAD], valuesToUse);
          data = R.compose(
            R.map((file: Object) => R.mergeRight({
              [GC.FIELD_DOCUMENT_UPLOAD]: file,
              [GC.FIELD_PRIMARY_OBJECT_GUID]: cloGuid,
            }, commonProps)),
            R.prop(GC.FIELD_DOCUMENT_UPLOAD),
          )(valuesToUse);
        } else {
          data = R.of(Array, R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, cloGuid, valuesToUse));
        }
        createLoadDocumentsRequest({
          data,
          callback,
          loadType:
          GC.LOAD_TYPE_CLO,
          refreshCallback: () => G.callFunctionWithArgs(getOrderDocumentListRequest, cloGuid),
        });
      };
      let component;
      if (G.isNotNilAndNotEmpty(clo.events)) {
        component = (
          <AddDocumentsForm
            {...props}
            load={clo}
            cloGuid={cloGuid}
            entityName={GC.FIELD_CLO}
            handleActionLoadDocument={handleCreateLoadDocument}
            initialValues={{ [GC.FIELD_DOCUMENT_PROOF_TYPE]: R.or(defaultProof, 'NONE') }} />
        );
      } else {
        component = (
          <DocumentFormWithAsyncEvents
            {...props}
            load={clo}
            cloGuid={cloGuid}
            entityName={GC.FIELD_CLO}
            handleActionLoadDocument={handleCreateLoadDocument}
            initialValues={{ [GC.FIELD_DOCUMENT_PROOF_TYPE]: R.or(defaultProof, 'NONE') }} />
        );
      }
      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-doc', 'Add Doc'),
        },
      };
      openModal(modal);
    }
  }),
  withHandlers({
    [GC.OPEN_IN_ROUTE_BUILDER_ACTION]: ({ setInitialRouteGuid, setInitialPlannerData }: Object) => (data: Object) => {
      const routeGuid = R.path([GC.FIELD_ROUTE_GUID], data);

      if (routeGuid) {
        setInitialRouteGuid(routeGuid);
        setTimeout(() => G.goToRoute(routesMap.routeBuilder), 0);
      } else {
        setInitialPlannerData({
          [GC.FIELD_CLO_GUID]: G.getGuidFromObject(data),
          [GC.FIELD_STATUS]: G.getPropFromObject(GC.FIELD_STATUS, data),
        });

        setTimeout(() => G.goToRoute(routesMap.routeBuilderEvents), 0);
      }
    },
    [GC.EDIT_CLO_RATE_ACTION]: (props: Object) => (clo: Object) => {
      const { openModal, updateCloRateRequest, handleEditCustomerRate } = props;

      if (G.isFunction(handleEditCustomerRate)) {
        return handleEditCustomerRate(true);
      }

      const stopsQuantity = R.add(
        R.pathOr(0, [GC.FIELD_EVENTS_INFO, GC.FIELD_DROP_COUNT], clo),
        R.pathOr(0, [GC.FIELD_EVENTS_INFO, GC.FIELD_PICKUP_COUNT], clo),
      );

      const callback = (rate: Object) => {
        const modalContent = (
          <EditCustomerRateFormWithOrderDetails
            initialValues={rate}
            stopsQuantity={stopsQuantity}
            cloGuid={G.getGuidFromObject(clo)}
            updateCloRateRequest={updateCloRateRequest}
            branchGuid={R.prop(GC.FIELD_BRANCH_GUID, clo)}
          />
        );

        const modal = G.getRateModalWithContent(modalContent);

        openModal(modal);
      };

      H.asyncGetCloRate(R.prop(GC.FIELD_SELECTED_RATE_GUID, clo), callback);
    },
    [GC.CANCEL_CLO_ACTION]: (props: Object) => (data: Object) => {
      const { openModal, closeModal, cancelCLORequest } = props;
      const initialValues = { [GC.FIELD_GUID]: R.prop(GC.FIELD_GUID, data) };
      const costAllocationsLength = R.pathOr(
        0,
        [GC.FIELD_FINANCE_COST_ALLOCATIONS, 'length'],
        data,
      );
      const telGuid = G.ifElse(
        R.equals(costAllocationsLength, 1),
        R.path([GC.FIELD_FINANCE_COST_ALLOCATIONS, 0, GC.FIELD_FINANCE_RELATION, GC.FIELD_TEL_GUID], data),
        null,
      );
      const component = (
        <CancelCLOForm
          telGuid={telGuid}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={cancelCLORequest}
          cloGuid={R.path([GC.FIELD_GUID], data)}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, data)} />
      );
      const modal = { p: '0px', component, options: {} };
      openModal(modal);
    },
    [GC.CREATE_CUSTOMER_INVOICE_ACTION]: (props: Object) => (clo: Object) => {
      const { handleAddCloCustomerInvoice } = props;

      if (G.isFunction(handleAddCloCustomerInvoice)) {
        return handleAddCloCustomerInvoice();
      }

      const callback = (selectedCustomerRate: Object) => {
        const {
          openModal,
          closeModal,
          configsByGroup,
          defaultUomSystem,
          createCustomerInvoiceRequest,
        } = props;

        const cloGuid = R.prop(GC.FIELD_GUID, clo);
        const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, clo);

        if (G.isNilOrEmpty(selectedCustomerRate)) {
          const message = G.getWindowLocale(
            'messages:not-create-customer-invoice',
            'Sorry, you can not create a customer invoice without the selected rate',
          );

          return G.showToastrMessageSimple('info', message);
        }

        const defaultInvoiceName = R.path([GC.SYSTEM_OBJECT_PRIMARY_REFERENCE, GC.FIELD_VALUE], clo);
        const configs = R.path([GC.INVOICE_CONFIG_GROUP], configsByGroup);

        const defaultNetDays = G.getConfigValueFromStore(
          GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_NET_DAYS,
          configs,
        );

        const defaultStatus = G.getConfigValueFromStore(
          GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_STATUS,
          configs,
        );

        const defaultSecondInvoiceStatus = G.getConfigValueFromStore(
          GC.INVOICE_CLO_SECOND_CUSTOMER_INVOICE_DEFAULT_STATUS,
          configs,
        );

        const handleSendCloInvoice = (data: Object) =>
          createCustomerInvoiceRequest(R.assoc(GC.FIELD_CLO_GUID, cloGuid, data));

        const component = (
          <CustomerInvoiceFormWithOrderDetails
            cloGuid={cloGuid}
            branchGuid={branchGuid}
            closeModal={closeModal}
            defaultStatus={defaultStatus}
            defaultNetDays={defaultNetDays}
            selectedRate={selectedCustomerRate}
            defaultUomFields={defaultUomSystem}
            defaultInvoiceName={defaultInvoiceName}
            handleSendCloInvoice={handleSendCloInvoice}
            defaultSecondInvoiceStatus={defaultSecondInvoiceStatus}
            sequenceConfigName={GC.INVOICE_CLO_INVOICE_NUMBER_SEQUENCE}
            autogenerateConfigName={GC.INVOICE_CLO_INVOICE_NUMBER_AUTOGENERATED}
          />
        );

        const modal = {
          p: '0',
          component,
          options: {
            width: 900,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '80vh',
          },
        };

        openModal(modal);
      };

      H.asyncGetCloRate(R.prop(GC.FIELD_SELECTED_RATE_GUID, clo), callback);
    },
    [GC.ADD_DOCUMENT_POD_ACTION]: (props: Object) => (data: Object) => (
      props.handleCreateDocument(GC.DOCUMENT_PROOF_TYPE_POD, data)
    ),
    [GC.ADD_DOCUMENT_ACTION]: (props: Object) => (data: Object) => (
      props.handleCreateDocument(null, data)
    ),
    [GC.CREATE_TEL_ACTION]: (props: Object) => (clo: Object) => {
      const { openModal, closeModal, routeTelConfigs, createTelFromCloRequest } = props;

      const { autoRouteName, autoTelPrimaryRef, telPrimCopyFromClo } = routeTelConfigs;

      if (G.shouldHideRouteTelModalOnCreateClo(routeTelConfigs)) {
        return createTelFromCloRequest({ clo });
      }

      const modalContent = (
        <RouteTelForm
          closeModal={closeModal}
          autoRouteName={autoRouteName}
          scopeName={GC.REF_SCOPE_NAME_TEL}
          autoTelPrimaryRef={autoTelPrimaryRef}
          telPrimCopyFromClo={telPrimCopyFromClo}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, clo)}
          createLoadRequest={(data: Object) => createTelFromCloRequest({ ...data, clo })}
        />
      );

      const modal = getTelRouteModalOptions(modalContent);

      return openModal(modal);
    },
    [GC.RESTORE_CLO_ACTION]: (props: Object) => (data: Object) => {
      const { openModal, closeModal, changeCancelStatusRequest } = props;

      const options = {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:submit', 'Submit'),
            action: () => {
              closeModal();
              changeCancelStatusRequest({ data, type: 'restore' });
            },
          },
        ],
      };

      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale('message:common-confirmation', 'Are you sure ?')}
        />
      );

      openModal({ options, component });
    },
    [GC.DUPLICATE_ORDER_ACTION]: () => (data: Object) => {
      const options = {
        [GC.FIELD_GUID]: G.getGuidFromObject(data),
        [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_COPY,
      };

      G.goToRoute(getOrderBySourceRoute(options));
    },
    [GC.UNQUOTE_CLO_ACTION]: ({ unquoteCLORequest }: Object) => (data: Object) => (
      unquoteCLORequest(data)
    ),
    [GC.SEND_CLO_TO_EXTERNAL_SYSTEM_ACTION]: (props: Object) => (data: Object) => {
      const { handleSendCloToExternalSystem } = props;

      handleSendCloToExternalSystem(data);
    },
  }),
  withHandlers({
    handleActionClick: (props: Object) => (actionName: string, data: Object) => (
      props[actionName](data)
    ),
  }),
);

export const withOrderActionsWithoutConnect = compose(
  withOrderActionsWithoutConnectAndRateEnhance,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  configsByGroup: makeSelectConfigsByGroup(state),
  routeTelConfigs: makeSelectRouteTelConfigs(state),
  defaultUomSystem: makeSelectConfigDefaultUOMSystem(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes(state),
});

const withDispatchBoardOrderActions = compose(
  connect(mapStateToProps, {
    openModal,
    closeModal,
    cancelCLORequest,
    unquoteCLORequest,
    setInitialRouteGuid,
    updateCloRateRequest,
    setInitialPlannerData,
    createTelFromCloRequest,
    changeCancelStatusRequest,
    createLoadDocumentsRequest,
    createCustomerInvoiceRequest,
  }),
  withOrderActionsWithoutConnect,
);

export default withDispatchBoardOrderActions;
