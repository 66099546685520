import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../../components/edit-report/helpers';
// features
import { getConfigByGroupSaga } from '../../../configurations/sagas';
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
import { getAccessorialForConfigRequest } from '../../../configurations/actions';
import { getAllAvailableRefTypesByScopeRequest } from '../../../reference/actions';
import { getAvailableReportGeoFencingZoneListRequest } from '../../../geo-fencing-zone/report/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// report-common
import { getReportSagas } from '../../../../report-common';
// sagas
import { crudSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature dispatch-report/load
import { startTelByClo } from '../actions';
// feature dispatch-report/load/load-by-order
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.telByClo, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const filterParams = yield select(makeSelectFilterParams());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);


    const { fields, fileType, orderFields } = payload;

    const reqBody = {
      fields,
      orderFields,
      searchCriteria,
      currentEnterprise,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const params = { format: fileType };

    const sendData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const options = {
      params,
      data: sendData,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telByCloExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetXMLSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('cloDefault', reportParams.guid),
    )) {
      return yield put(closeLoader());
    }

    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };

    const options = {
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ reqBody, filterParams: requestFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.getTelListXML, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.downloadFile(data, 'telbyclolist.xml');

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetXMLSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetXMLSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handlePrintSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('cloDefault', reportParams.guid),
    )) {
      return yield put(closeLoader());
    }

    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };

    const options = {
      params: payload,
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ reqBody, filterParams: requestFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telDocumentPrintByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handlePrintSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'post', endpointsMap.telMassReference, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createReferenceSuccess(R.head(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateReferenceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* dispatchTelListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.massDispatch, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getItemListRequest(true));
      yield put(A.resetListAndPagination());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isNotNilAndNotEmpty(data)) {
        const message = R.compose(
          R.join('. '),
          R.map(({ error, primaryReferenceValue }: Object) => `${primaryReferenceValue} - ${error}`),
        )(data);

        yield call(G.showToastrMessage, 'error', message);
      }
    } else {
      yield call(G.handleFailResponse, res, 'dispatchTelListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'dispatchTelListRequest exception');
  }
}

function* massCreateCarrierRateByReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const report = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(report, 'searchCriteria', []),
    );

    const reqBody = {
      ...payload,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };

    yield call(crudSaga, {
      options,
      method: 'post',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'massCreateCarrierRateByReportSaga',
      endpoint: endpointsMap.telCarrierRateMassCreateByReport,
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.TEL_BY_CLO_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

export function* handleStartTelByCloSaga() {
  try { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());

    yield call(getConfigByGroupSaga, GC.GENERAL_CONFIG_GROUP);

    yield put(getAccessorialForConfigRequest());
    yield put(A.setIgnorePromptStatus(false));
    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_TEL));
    yield put(getAvailableReportGeoFencingZoneListRequest());
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload: { reportType: GC.TEL_BY_CLO_REPORT } });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleStartTelByCloSaga exception');
  }
}

function* telByCloReportListWatcherSaga() {
  yield takeLatest(A.printRequest, handlePrintSaga);
  yield takeLatest(A.getXMLRequest, handleGetXMLSaga);
  yield takeLatest(startTelByClo, handleStartTelByCloSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.dispatchTelListRequest, dispatchTelListRequest);
  yield takeLatest(A.createReferenceRequest, handleCreateReferenceSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.addCarrierRatesByReportRequest, massCreateCarrierRateByReportSaga);
}

export default telByCloReportListWatcherSaga;
