import * as R from 'ramda';
import React from 'react';
import { compose } from 'react-recompose';
// components
import { LocalLoader } from '../../components/local-loader';
// features
import DocumentForm from '../dispatch-details-new/forms/document-form';
import StatusMessageForm from '../dispatch-details-new/forms/status-message-form';
// forms
import { SelectDropdownForm } from '../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import {
  withAsyncTelEvents,
  withReasonCodeConfigs,
  withAsyncStatusMessages,
  withAsyncAvailableDocumentTypes,
} from '../../hocs';
//////////////////////////////////////////////////

export const EnhancedSelectDropdownForm = withReasonCodeConfigs((props: Object) =>
  <SelectDropdownForm {...props} options={R.path(['declineLoadReasons'], props)} />,
);

export const EnhancedDocumentForm = withAsyncTelEvents(withAsyncAvailableDocumentTypes((props: Object) => (
  <LocalLoader localLoaderOpen={R.isNil(props.asyncEvents)}>
    <DocumentForm
      {...props}
      load={R.assoc(GC.FIELD_LOAD_STOPS, props.asyncEvents, props.load)}
    />
  </LocalLoader>
)));

const enhanceStatusMessageForm = compose(
  withReasonCodeConfigs,
  withAsyncStatusMessages,
  withAsyncTelEvents,
);

export const EnhancedStatusMessageForm = enhanceStatusMessageForm((props: Object) => {
  const { load, asyncEvents, statusMessagesConfigs } = props;
  const loading = R.isNil(asyncEvents);
  const width = G.ifElse(loading, 500, 'auto');
  const statusMessagesOptions = R.or(statusMessagesConfigs, []);
  return (
    <LocalLoader p={15} width={width} localLoaderOpen={loading}>
      <StatusMessageForm
        {...props}
        statusMessagesOptions={statusMessagesOptions}
        load={R.assoc(GC.FIELD_LOAD_STOPS, asyncEvents, load)}
        mapLocations={G.mapTelEventsForMap(R.or(asyncEvents, []))}
      />
    </LocalLoader>
  );
});
