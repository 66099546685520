import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// helpers/constants
import * as G from '../../../helpers';
// feature carrier
import { CertificateForm } from './certificate-form';
import { CARRIER_GROUP_NAME_CERTIFICATE } from '../constants';
import { certificateSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleAddCertificate: ({ openModal, initialValues, createCarrierCertificateRequest }: Object) => () => {
      const component = (
        <CertificateForm
          submitAction={createCarrierCertificateRequest}
          carrierGuid={G.getGuidFromObject(initialValues)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 460,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:add-certificate', 'Add Certificate'),
        },
      };

      openModal(modal);
    },
    handleEditCertificate: (props: Object) => (certificate: Object) => {
      const { openModal, initialValues, updateCarrierCertificateRequest } = props;

      const component = (
        <CertificateForm
          initialValues={certificate}
          submitAction={updateCarrierCertificateRequest}
          carrierGuid={G.getGuidFromObject(initialValues)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 460,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:edit-certificate', 'Edit Certificate'),
        },
      };

      openModal(modal);
    },
    handleDeleteCertificate: (props: Object) => ({ guid }: Object) => {
      const { openModal, closeModal, deleteCarrierCertificateRequest } = props;

      const component = (
        <ConfirmComponent
          display='block'
          textLocale={G.getWindowLocale(
            'messages:certificate-delete-confirmation',
            'Are you sure you want to delete this certificate?',
          )}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteCarrierCertificateRequest(guid);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const CertificateTabComponent = (props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entitiesFields={certificateSettings}
    entities={props.carrierCertificates}
    handleAddClick={props.handleAddCertificate}
    handleEditRow={props.handleEditCertificate}
    groupName={CARRIER_GROUP_NAME_CERTIFICATE}
    handleDeleteRow={props.handleDeleteCertificate}
    panelTitle={G.getWindowLocale('titles:certificate', 'Certificates')}
    isOpened={R.path([CARRIER_GROUP_NAME_CERTIFICATE], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_CERTIFICATE)}
  />
);

export default enhance(CertificateTabComponent);
