import React from 'react';
import * as R from 'ramda';
import titleCase from 'voca/title_case';
// components
import { TextComponent } from '../../../components/text';
import ErrorsPopover from '../../../components/errors-popover';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  getFreightClassOptions,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
  triumphFactoringPaymentTermTypeOptions,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, StyledLink } from '../../../ui';
// feature dispatch-details new
import PayrollTableCell from '../load/components/payroll-table-cell';
import DocumentFileTableCell from '../load/components/document-file-table-cell';
import MasterInvoiceTableCell from '../load/components/master-invoice-table-cell';
//////////////////////////////////////////////////

const invoicePaymentFields = {
  [GC.FIELD_INVOICE_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.FIELD_INVOICE_CHECK_DATE]: {
    width: 200,
    name: 'titles:check-date',
  },
  [GC.FIELD_CHECK_AMOUNT]: {
    width: 200,
    name: 'titles:check-amount',
  },
  [GC.FIELD_INVOICE_DEPOSIT_DATE]: {
    width: 200,
    name: 'titles:deposit-date',
  },
  [GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER]: {
    width: 200,
    name: 'titles:ach-payment-confirmation',
  },
};

const mappedInvoicePaymentFields = R.mapObjIndexed((field: Object, fieldName: string) => {
  const customComponent = (row: Object) => {
    const text = R.compose(
      R.join(', '),
      R.map(R.prop(fieldName)),
      R.pathOr([], ['data', GC.FIELD_PAYMENTS]),
    )(row);

    return (
      <TextComponent title={text} display='block' maxWidth='90%' withEllipsis={true}>
        {text}
      </TextComponent>
    );
  };

  return R.assoc('customComponent', customComponent, field);
}, invoicePaymentFields);

export const statusMessageColumnSettings = {
  [GC.FIELD_STATUS_MESSAGE_CODE]: {
    width: 200,
    name: 'titles:detail',
    customComponent: ({ data }: Object) =>
      R.pathOr('', [GC.FIELD_STATUS_MESSAGE_CODE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_STATUS_MESSAGE_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:date',
  },
  [GC.FIELD_LOCATION]: {
    width: 350,
    name: 'titles:location-stop',
    customComponent: ({ data }: Object) => {
      const { loadEvent, cloStatusMessageLocation, telStatusMessageLocation } = data;

      if (G.isNotNilAndNotEmpty(loadEvent)) return loadEvent;

      const location = G.ifElse(G.isLoadTypeClo(data), cloStatusMessageLocation, telStatusMessageLocation);
      const fieldsToPick = [GC.FIELD_ADDRESS, GC.FIELD_CITY, GC.FIELD_STATE, GC.FIELD_ZIP, GC.FIELD_COUNTRY];

      return G.concatLocationFields(location, fieldsToPick);
    },
  },
  [GC.FIELD_STATUS_FROM_INTEGRATION]: {
    width: 150,
    name: 'titles:integration-status',
  },
  [GC.FIELD_INFO_FROM_INTEGRATION]: {
    width: 300,
    name: 'titles:integration-info',
  },
  [GC.FIELD_STATUS_MESSAGE_REASON_CODE]: {
    width: 200,
    name: 'titles:status-reason-code',
    customComponent: ({ data }: Object) =>
      R.path([GC.FIELD_STATUS_MESSAGE_REASON_CODE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_STATUS_MESSAGE_TRACKING]: {
    width: 200,
    name: 'titles:tracking',
    customComponent: ({ data }: Object) => G.ifElse(
      G.isTrue(R.path([GC.FIELD_STATUS_MESSAGE_CODE, GC.FIELD_STATUS_MESSAGE_TRACKING], data)),
      I.checkMark(),
      '',
    ),
  },
  [GC.FIELD_SOURCE]: {
    width: 150,
    type: 'enum',
    name: 'titles:source',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
};

export const itemsSettings = {
  [GC.FIELD_ACTIVE]: {
    width: 50,
    name: 'titles:active',
    customComponent: ({ data }: Object) => G.ifElse(
      R.prop(GC.FIELD_ACTIVE, data),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  [GC.FIELD_ITEM_ID]: {
    width: 150,
    name: 'titles:item-id',
  },
  [GC.FIELD_ITEM_FREIGHT_CLASS]: {
    width: 150,
    name: 'titles:freight-class',
    customComponent: ({ data }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.pathEq(R.prop(GC.FIELD_ITEM_FREIGHT_CLASS, data), [GC.FIELD_VALUE])),
    )(getFreightClassOptions()),
  },
  [GC.FIELD_ITEM_WEIGHT]: {
    width: 100,
    name: 'titles:weight',
  },
  [GC.FIELD_ITEM_PICKED_UP_WEIGHT]: {
    width: 100,
    name: 'titles:actual-weight',
  },
  [GC.FIELD_ITEM_WEIGHT_TYPE]: {
    width: 100,
    name: 'titles:weight-type',
    customComponent: ({ data }: Object) => G.getUomLocale(R.prop(GC.FIELD_ITEM_WEIGHT_TYPE, data)),
  },
  [GC.FIELD_ITEM_QUANTITY]: {
    width: 70,
    name: 'titles:quantity',
  },
  [GC.FIELD_ITEM_PICKED_UP_QUANTITY]: {
    width: 110,
    name: 'titles:actual-quantity',
  },
  [GC.FIELD_ITEM_PACKAGE_TYPE]: {
    width: 150,
    type: 'enumLocale',
    name: 'titles:package-type',
  },
  [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: {
    width: 150,
    name: 'titles:dimensions-length',
  },
  [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: {
    width: 150,
    name: 'titles:dimensions-width',
  },
  [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: {
    width: 150,
    name: 'titles:dimensions-height',
  },
  [GC.FIELD_ITEM_DIMENSIONS_UOM]: {
    width: 110,
    type: 'uomLocale',
    name: 'titles:dimensions-uom',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 250,
    name: 'titles:description',
  },
  [GC.FIELD_ORIGIN_OF_GOODS]: {
    width: 150,
    name: 'titles:origin-of-goods',
  },
  [GC.FIELD_ITEM_COMMODITY]: {
    width: 150,
    name: 'titles:commodity',
  },
  [GC.FIELD_ITEM_MONETARY_VALUE]: {
    width: 100,
    name: 'titles:monetary-value',
  },
  [GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY]: {
    width: 100,
    name: 'titles:monetary-currency',
  },
  [GC.FIELD_ITEM_VOLUME]: {
    width: 60,
    name: 'titles:volume',
  },
  [GC.FIELD_ITEM_PICKED_UP_VOLUME]: {
    width: 100,
    name: 'titles:actual-volume',
  },
  [GC.FIELD_ITEM_VOLUME_UOM]: {
    width: 100,
    type: 'uomLocale',
    name: 'titles:volume-uom',
  },
  [GC.FIELD_ITEM_DECLARED_VALUE]: {
    width: 100,
    name: 'titles:declared-value',
  },
  [GC.FIELD_ITEM_DECLARED_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
  },
  [GC.FIELD_ITEM_STACKABILITY]: {
    width: 150,
    type: 'boolean',
    name: 'titles:stackability',
  },
  [GC.FIELD_ITEM_STACKABILITY_CONDITION]: {
    width: 150,
    name: 'titles:stackability-condition',
  },
  [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: {
    width: 150,
    name: 'titles:customer-part-num',
  },
  [GC.FIELD_ITEM_TEMPERATURE_TYPE]: {
    width: 150,
    type: 'enumLocale',
    name: 'titles:temperature-type',
  },
  [GC.FIELD_ITEM_TEMPERATURE_SENSOR]: {
    width: 150,
    type: 'enumLocale',
    name: 'titles:temperature-sensor',
  },
  [GC.FIELD_ITEM_TEMPERATURE_LOW]: {
    width: 150,
    name: 'titles:temperature-minimum',
  },
  [GC.FIELD_ITEM_TEMPERATURE_HIGH]: {
    width: 150,
    name: 'titles:temperature-maximum',
  },
  [GC.FIELD_ITEM_TEMPERATURE_UOM]: {
    width: 150,
    name: 'titles:temperature-uom',
  },
  [GC.FIELD_ITEM_N_M_F_C]: {
    width: 150,
    name: 'titles:nmfc',
  },
  [GC.FIELD_ITEM_S_T_C_C]: {
    width: 150,
    name: 'titles:stcc',
  },
  [GC.FIELD_ITEM_HARMONIZED_TARIFF]: {
    width: 150,
    name: 'titles:harmonized-tariff',
  },
  [GC.FIELD_ITEM_COMMODITY_TYPE]: {
    width: 150,
    name: 'titles:domestic-foreign-military',
  },
  [GC.FIELD_ITEM_E_C_C_N]: {
    width: 150,
    name: 'titles:eccn',
  },
  [GC.FIELD_ITEM_HAZARDOUS]: {
    width: 150,
    type: 'boolean',
    name: 'titles:hazardous',
  },
  [GC.FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER]: {
    width: 150,
    name: 'titles:hazmat-un-or-na-number',
  },
  [GC.FIELD_ITEM_HAZMAT_PACKAGE_GROUP]: {
    width: 150,
    name: 'titles:hazmat-package-group',
  },
  [GC.FIELD_ITEM_HAZMAT_CLASS]: {
    width: 250,
    name: 'titles:hazmat-class',
  },
  [GC.FIELD_ITEM_HAZMAT_SHIPPING_NAME]: {
    width: 150,
    name: 'titles:hazmat-shipping-name',
  },
  [GC.FIELD_ITEM_HAZMAT_CONTACT_NAME]: {
    width: 150,
    name: 'titles:hazmat-contact-name',
  },
  [GC.FIELD_ITEM_HAZMAT_CONTACT_PHONE]: {
    width: 150,
    name: 'titles:hazmat-contact-phone',
  },
  [GC.FIELD_ITEM_HAZMAT_EMS_NUMBER]: {
    width: 150,
    name: 'titles:hazmat-ems-number',
  },
  [GC.FIELD_ITEM_HAZMAT_PLACARDS]: {
    width: 150,
    type: 'boolean',
    name: 'titles:hazmat-placards',
  },
  [GC.FIELD_ITEM_HAZMAT_PLACARDS_DETAILS]: {
    width: 150,
    name: 'titles:hazmat-placard-details',
  },
  [GC.FIELD_ITEM_HAZMAT_FLASHPOINT]: {
    width: 150,
    name: 'titles:hazmat-flashpoint',
  },
  [GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM]: {
    width: 150,
    name: 'titles:hazmat-flashpoint-uom',
  },
  [GC.FIELD_ITEM_HAZMAT_COMMENT]: {
    width: 400,
    name: 'titles:hazmat-comments',
  },
};

export const vehicleItemColumnSettings = {
  [GC.FIELD_VIN]: {
    width: 150,
    name: 'titles:vin',
  },
  [GC.FIELD_MANUFACTURER]: {
    width: 200,
    name: 'titles:manufacturer',
  },
  [GC.FIELD_YEAR]: {
    width: 100,
    name: 'titles:year',
  },
  [GC.FIELD_MAKE]: {
    width: 150,
    name: 'titles:make',
  },
  [GC.FIELD_MODEL]: {
    width: 150,
    name: 'titles:model',
  },
  [GC.FIELD_SUB_MODEL]: {
    width: 150,
    name: 'titles:sub-model',
  },
  [GC.FIELD_COLOR]: {
    width: 150,
    name: 'titles:color',
  },
  [GC.FIELD_WHEELBASE]: {
    width: 150,
    name: 'titles:wheelbase',
  },
  [GC.FIELD_ODOMETER_UOM]: {
    width: 150,
    name: 'titles:odometer-uom',
    customComponent: ({ data }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_ODOMETER_UOM, data), GC.FIELD_VALUE)),
    )(ITEM_ODOMETER_UOM_OPTIONS),
  },
  [GC.FIELD_BAY]: {
    width: 150,
    name: 'titles:bay',
  },
  [GC.FIELD_WHEELBASE_UOM]: {
    width: 150,
    name: 'titles:wheelbase-uom',
    customComponent: ({ data }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_WHEELBASE_UOM, data), GC.FIELD_VALUE)),
    )(ITEM_DIMENSIONS_OPTIONS),
  },
  [GC.FIELD_ODOMETER]: {
    width: 150,
    name: 'titles:odometer',
  },
  [GC.FIELD_LICENSE_PLATE]: {
    width: 150,
    name: 'titles:license-plate',
  },
  [GC.FIELD_ORIGIN_STATE]: {
    width: 150,
    name: 'titles:origin-state',
  },
  [GC.FIELD_ORIGIN_COUNTRY]: {
    width: 150,
    name: 'titles:origin-country',
  },
  [GC.FIELD_OPERATIONAL]: {
    width: 100,
    type: 'boolean',
    name: 'titles:operational',
  },
};

export const referenceColumnSettings = {
  [GC.FIELD_NAME]: {
    width: 150,
    name: 'titles:name',
  },
  [GC.FIELD_VALUE]: {
    width: 150,
    name: 'titles:reference',
  },
  [GC.FIELD_PRIMARY]: {
    width: 60,
    name: 'titles:primary',
    customComponent: ({ data }: Object) => G.ifElse(
      R.prop(GC.FIELD_PRIMARY, data),
      I.checkMark(),
      '',
    ),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

export const driverCarrierRateColumnSettings = {
  [GC.FIELD_TYPE]: {
    name: 'titles:rate-source',
    width: 150,
    customComponent: ({ data }: Object) => {
      const { type } = data;
      const params = {
        [GC.RATE_TYPE_FLEET_RATE]: G.getWindowLocale('titles:driver-rate', 'Driver Rate'),
        [GC.RATE_TYPE_CARRIER_RATE]: G.getWindowLocale('titles:carrier-rate', 'Carrier Rate'),
      };

      return R.or(params[type], type);
    },
  },
  [GC.FIELD_VENDOR]: {
    width: 200,
    name: 'titles:vendor',
    customComponent: ({ data }: Object) => {
      const value = R.pathOr(
        '',
        [
          GC.FIELD_FLEET_ASSIGNMENT,
          GC.SYSTEM_OBJECT_TRUCK,
          GC.SYSTEM_OBJECT_FLEET_VENDOR,
          GC.FIELD_NAME,
        ],
        data,
      );

      return (
        <TextComponent title={value} withEllipsis={true}>
          {value}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CARRIER_NAME]: {
    width: 200,
    name: 'titles:carrier-name',
    customComponent: ({ data }: Object) => {
      const value = R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_NAME], data);

      return (
        <TextComponent title={value} withEllipsis={true}>
          {value}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_PRIMARY_DRIVER]: {
    width: 200,
    name: 'titles:main-driver',
    customComponent: ({ data }: Object) => {
      if (R.pathEq(GC.RATE_TYPE_FLEET_RATE, [GC.FIELD_TYPE], data)) {
        const { fleetAssignment: { primaryDriver } } = data;

        if (R.isNil(primaryDriver)) return '';

        const { lastName, firstName } = primaryDriver;

        const value = `${firstName} ${lastName}`;

        return (
          <TextComponent title={value} withEllipsis={true}>
            {value}
          </TextComponent>
        );
      }

      const value = R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_PRIMARY_DRIVER], data);

      return (
        <TextComponent title={value} withEllipsis={true}>
          {value}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_SECONDARY_DRIVER]: {
    width: 200,
    name: 'titles:team-driver',
    customComponent: ({ data }: Object) => {
      if (R.pathEq(GC.RATE_TYPE_FLEET_RATE, [GC.FIELD_TYPE], data)) {
        const { fleetAssignment: { secondaryDriver } } = data;

        if (R.isNil(secondaryDriver)) return '';

        const { lastName, firstName } = secondaryDriver;

        const value = `${firstName} ${lastName}`;

        return (
          <TextComponent title={value} withEllipsis={true}>
            {value}
          </TextComponent>
        );
      }

      const value = R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_SECONDARY_DRIVER], data);

      return (
        <TextComponent title={value} withEllipsis={true}>
          {value}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:rate-status',
    customComponent: ({ data }: Object) => titleCase(R.prop(GC.FIELD_STATUS, data)),
  },
  [`${GC.FIELD_DECLINE_REASON_CODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    getDataByPath: true,
    name: 'titles:decline-reason',
  },
  [GC.FIELD_APPROVED_BY]: {
    width: 200,
    name: 'titles:approved-by',
  },
  [GC.FIELD_TOTAL]: {
    width: 150,
    name: 'titles:total',
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
  },
  [GC.FIELD_SERVICE_TYPE]: {
    width: 200,
    name: 'titles:service-type',
    customComponent: ({ data }: Object) =>
      R.pathOr('', [GC.FIELD_SERVICE_TYPE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_SERVICE_DAYS]: {
    width: 100,
    name: 'titles:service-days',
  },
  [GC.FIELD_MODE]: {
    width: 200,
    name: 'titles:mode',
    customComponent: ({ data }: Object) =>
      R.pathOr('', [GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
    width: 200,
    name: 'titles:total-trip-distance',
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: {
    width: 200,
    name: 'titles:total-items-weight',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.FIELD_SOURCE]: {
    width: 200,
    name: 'titles:source',
    customComponent: R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_SOURCE]),
    ),
  },
  [GC.FIELD_TRANSPORTING_CARRIER_NAME]: {
    width: 200,
    name: 'titles:transporting-carrier',
    customComponent: ({ data }: Object) =>
      R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_TRANSPORTING_CARRIER_NAME], data),
  },
};

export const advancePaymentColumnSettings = {
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_AMOUNT]: {
    width: 150,
    name: 'titles:check-amount',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_FEE]: {
    width: 150,
    name: 'titles:check-fee',
  },
  [GC.FIELD_ADVANCE_PAYMENT_TRIP_NUMBER]: {
    width: 150,
    name: 'titles:trip-number',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_BALANCE]: {
    width: 150,
    name: 'titles:check-balance',
  },
  [GC.FIELD_ADVANCE_PAYMENT_STATUS]: {
    width: 150,
    name: 'titles:status',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHANGE_STATUS_REASON]: {
    width: 300,
    name: 'titles:status-reason',
  },
  [GC.FIELD_ADVANCE_PAYMENT_INTEGRATION_TYPE]: {
    width: 150,
    name: 'titles:integration-type',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_CITY]: {
    width: 200,
    name: 'titles:last-use-city',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_LOCATION_CODE]: {
    width: 200,
    name: 'titles:last-use-location-code',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_DATE]: {
    width: 200,
    name: 'titles:last-use-date',
  },
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: {
    width: 150,
    name: 'titles:driver-first-name',
    customComponent: ({ data }: Object) => R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_FIRST_NAME], data),
  },
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: {
    width: 150,
    name: 'titles:driver-last-name',
    customComponent: ({ data }: Object) => R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_LAST_NAME], data),
  },
  [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: {
    width: 150,
    name: 'titles:driver-id',
    customComponent: ({ data }: Object) => R.path([GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_USER_LOGIN_ID], data),
  },
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: {
    width: 150,
    name: 'titles:driver-number',
    customComponent: ({ data }: Object) => R.path(
      [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER],
      data,
    ),
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

export const customerRateColumnSettings = {
  [GC.FIELD_TOTAL]: {
    width: 200,
    name: 'titles:total',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_SERVICE_TYPE]: {
    width: 200,
    name: 'titles:service-type',
    customComponent: ({ data }: Object) =>
      R.pathOr('', [GC.FIELD_SERVICE_TYPE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_SERVICE_DAYS]: {
    width: 200,
    name: 'titles:service-days',
  },
  [GC.FIELD_MODE]: {
    width: 200,
    name: 'titles:mode',
    customComponent: ({ data }: Object) =>
      R.pathOr('', [GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
    width: 200,
    name: 'titles:total-trip-distance',
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: {
    width: 200,
    name: 'titles:total-items-weight',
  },
  [GC.FIELD_CONTACT_NAME]: {
    width: 200,
    name: 'titles:contact-name',
  },
  [GC.FIELD_PHONE]: {
    width: 200,
    name: 'titles:contact-phone',
  },
  [GC.FIELD_EMAIL]: {
    width: 200,
    name: 'titles:contact-email',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.FIELD_TRANSPORTING_CARRIER_NAME]: {
    width: 200,
    name: 'titles:transporting-carrier',
  },
};

export const invoiceSettings = {
  [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: {
    width: 200,
    name: 'titles:master-invoice',
    customComponent: ({ data }: Object) => (
      <MasterInvoiceTableCell
        {...data}
        cloGuid={R.prop(GC.FIELD_CLO_GUID, data)}
        masterInvoiceNumber={R.prop(GC.FIELD_MASTER_INVOICE_NUMBER, data)}
        masterInvoiceGuid={R.prop(GC.FIELD_INVOICE_MASTER_INVOICE_GUID, data)}
      />
    ),
  },
  [GC.FIELD_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:invoice-number',
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:invoice-status',
    customComponent: ({ data }: Object) => R.path([GC.FIELD_STATUS, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_TOTAL]: {
    width: 200,
    name: 'titles:total',
  },
  [GC.FIELD_BALANCE]: {
    width: 200,
    name: 'titles:balance',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [`${GC.SYSTEM_OBJECT_NORMALIZED_TOTAL}.${GC.FIELD_TOTAL}`]: {
    width: 200,
    getDataByPath: true,
    name: 'titles:normalized-total',
  },
  [`${GC.SYSTEM_OBJECT_NORMALIZED_TOTAL}.${GC.FIELD_CURRENCY}`]: {
    width: 200,
    getDataByPath: true,
    name: 'titles:normalized-currency',
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_STATUS]: {
    width: 200,
    name: 'titles:integration-status',
    customComponent: ({ data }: Object) => {
      const status = R.path([GC.SYSTEM_OBJECT_ACCOUNTING_INTEGRATION, GC.FIELD_STATUS], data);

      if (G.isNilOrEmpty(status)) return;

      const text = G.getWindowLocale(GC.statusLocaleMap[status], status);

      return text;
    },
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
    customComponent: ({ data }: Object) => R.path(
      [GC.SYSTEM_OBJECT_ACCOUNTING_INTEGRATION, GC.FIELD_INTEGRATION_TYPE],
      data,
    ),
  },
  [GC.FIELD_EXTERNAL_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:external-invoice-number',
  },
  [GC.FIELD_GL_CODE]: {
    width: 200,
    name: 'titles:gl-code',
    customComponent: ({ data }: Object) => R.path([GC.FIELD_GL_CODE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_FACTORING_PAYMENT_TERM]: {
    width: 200,
    name: 'titles:factoring-payment-term',
    customComponent: ({ data }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_FACTORING_PAYMENT_TERM, data), GC.FIELD_VALUE)),
    )(triumphFactoringPaymentTermTypeOptions),
  },
  [GC.FIELD_SERVICE_TYPE]: {
    width: 200,
    name: 'titles:service-type',
    customComponent: ({ data }: Object) => R.path([GC.FIELD_SERVICE_TYPE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_SERVICE_DAYS]: {
    width: 200,
    name: 'titles:service-days',
  },
  [GC.FIELD_MODE]: {
    width: 200,
    name: 'titles:mode',
    customComponent: ({ data }: Object) => R.path([GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
    width: 200,
    name: 'titles:total-trip-distance',
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: {
    width: 200,
    name: 'titles:total-items-weight',
  },
  [GC.FIELD_INVOICE_DATE]: {
    width: 200,
    name: 'titles:invoice-date',
  },
  [GC.FIELD_INTEGRATED_DATE]: {
    width: 200,
    name: 'titles:integrated-date',
  },
  [GC.FIELD_INVOICE_NET_DAYS]: {
    width: 200,
    name: 'titles:invoice-net-days',
  },
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: {
    width: 200,
    name: 'titles:due-date',
  },
  ...mappedInvoicePaymentFields,
  [GC.FIELD_CONTACT_NAME]: {
    width: 200,
    name: 'titles:contact-name',
  },
  [GC.FIELD_PHONE]: {
    width: 200,
    name: 'titles:contact-phone',
  },
  [GC.FIELD_EMAIL]: {
    width: 200,
    name: 'titles:contact-email',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
};

export const driverInvoiceColumnSettings = {
  driverInfo: {
    width: 200,
    name: 'titles:driver',
    customComponent: ({ data }: Object) => {
      const { driver } = data;
      if (G.isNilOrEmpty(driver)) return '';
      const { lastName, firstName } = driver;

      return `${firstName} ${lastName}`;
    },
  },
  [GC.FIELD_VENDOR]: {
    width: 200,
    name: 'titles:vendor',
    customComponent: ({ data }: Object) =>
      R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR, GC.FIELD_NAME], data),
  },
  [GC.FIELD_INVOICE_DRIVER_PAYROLL_GUID]: {
    width: 200,
    name: 'titles:payroll',
    customComponent: ({ data }: Object) => (
      <PayrollTableCell
        {...data}
        driverPayrollGuid={R.path([GC.FIELD_INVOICE_DRIVER_PAYROLL_GUID], data)}
        driverPayrollNumber={R.path([GC.FIELD_INVOICE_DRIVER_PAYROLL_NUMBER], data)} />
    ),
    customLogic: (value: Object, props: Object) => (
      <Box
        cursor='pointer'
        onClick={() => props.handleOpenPayrollDetails(value)}
        title={G.getWindowLocale('titles:click-to-see-details', 'Click to see details')}
      >
        {R.pathOr(G.getWindowLocale('titles:no-number', 'No number'), ['entity', 'driverPayrollNumber'], props)}
      </Box>
    ),
  },
  ...R.omit([
    GC.FIELD_PHONE,
    GC.FIELD_EMAIL,
    GC.FIELD_CONTACT_NAME,
    GC.FIELD_FACTORING_PAYMENT_TERM,
    GC.FIELD_INVOICE_MASTER_INVOICE_GUID,
  ], invoiceSettings),
};

export const serviceVendorInvoiceColumnSettings = {
  [GC.FIELD_SERVICE_VENDOR]: {
    width: 200,
    name: 'titles:service-vendor',
    customComponent: ({ data }: Object) => R.pathOr(
      '',
      [
        GC.FIELD_SERVICE_VENDOR,
        GC.FIELD_NAME,
      ],
      data,
    ),
  },
  ...R.omit([
    GC.FIELD_MODE,
    GC.FIELD_PHONE,
    GC.FIELD_EMAIL,
    GC.FIELD_SERVICE_DAYS,
    GC.FIELD_CONTACT_NAME,
    GC.FIELD_FACTORING_PAYMENT_TERM,
    GC.FIELD_INVOICE_MASTER_INVOICE_GUID,
  ], invoiceSettings),
};

export const carrierInvoiceSettings = {
  carrierName: {
    width: 250,
    name: 'titles:carrier',
    customComponent: ({ data }: Object) => R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER, GC.FIELD_NAME], data),
  },
  ...R.omit(
    [
      GC.FIELD_PHONE,
      GC.FIELD_EMAIL,
      GC.FIELD_CONTACT_NAME,
      GC.FIELD_INVOICE_MASTER_INVOICE_GUID,
    ],
    invoiceSettings,
  ),
};

export const loadBoardsSettings = {
  [GC.FIELD_TYPE]: {
    width: 200,
    name: 'titles:source',
  },
  [GC.FIELD_SHIPMENT_ID]: {
    width: 200,
    name: 'titles:shipment-id',
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    name: 'titles:status',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:last-modified-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:last-modified-date',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 200,
    name: 'titles:description',
  },
};

export const notificationSettings = {
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER]: {
    width: 200,
    name: 'titles:trigger',
  },
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL]: {
    width: 200,
    name: 'titles:type',
    customComponent: ({ data }: Object) => {
      const warningLevel = G.getPropFromObject(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL, data);
      const colorsMap = {
        [GC.NOTIFICATION_WARNING_LEVEL_TYPE_INFO]: G.getTheme('colors.light.blue'),
        [GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING]: G.getTheme('colors.light.yellow'),
        [GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL]: G.getTheme('colors.light.mainRed'),
      };

      return (
        <Box fontWeight='bold' color={G.getPropFromObject(warningLevel, colorsMap)}>
          {titleCase(warningLevel)}
        </Box>
      );
    },
  },
  [GC.FIELD_MESSAGE]: {
    width: 400,
    name: 'titles:message',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:last-modified-date',
  },
};

export const documentSettings = {
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 200,
    name: 'titles:file-name',
    customComponent: ({ data }: Object) => <DocumentFileTableCell document={data} />,
  },
  [GC.FIELD_DOCUMENT_URL]: {
    width: 200,
    name: 'titles:file-link',
    customComponent: ({ data }: Object) => {
      const url = G.getPropFromObject(GC.FIELD_DOCUMENT_URL, data);
      if (G.isNilOrEmpty(url)) return null;

      return (
        <TextComponent title={url} display='block' maxWidth='90%' withEllipsis={true}>
          <StyledLink
            target='_blank'
            textDecoration='underline'
            href={G.makeURLString(url)}
            color={G.getTheme('colors.light.blue')}
          >
            {url}
          </StyledLink>
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    width: 200,
    name: 'titles:type',
    customComponent: ({ data }: Object) =>
      R.path([GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], data),
  },
  [GC.FIELD_SOURCE]: {
    width: 200,
    name: 'titles:source',
    customComponent: R.compose(
      G.toTitleCase,
      R.pathOr('', ['data', GC.FIELD_SOURCE]),
    ),
  },
  [GC.FIELD_DOCUMENT_SENT_TO]: {
    width: 200,
    name: 'titles:sent-to',
  },
  [GC.FIELD_DOCUMENT_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
};

export const mailSettings = {
  [GC.FIELD_MAIL_SENDING_TO]: {
    width: 200,
    name: 'titles:mail-to',
    customComponent: ({ data }: Object) => {
      const mailTo = G.getPropFromObject(GC.FIELD_MAIL_SENDING_TO, data);
      const joinedData = (G.ifElse(G.isArray(mailTo), R.join(', ', mailTo), mailTo));

      return (
        <TextComponent
          title={mailTo}
          display='block'
          maxWidth='100%'
          withEllipsis={true}
        >
          {joinedData}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_MAIL_SENDING_SUBJECT]: {
    width: 200,
    name: 'titles:subject',
  },
  [GC.FIELD_MAIL_SENDING_REPLY_TO]: {
    width: 200,
    name: 'titles:reply-to',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
};

export const noteSettings = {
  pinned: {
    width: 100,
    name: 'titles:pinned',
    customComponent: ({ data }: Object) => G.ifElse(
      G.isTrue(data.pinned),
      I.checkMark(),
      '',
    ),
  },
  [GC.FIELD_TEXT]: {
    width: 300,
    name: 'titles:subject',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
};

export const chatSettings = {
  [GC.FIELD_TEXT]: {
    width: 300,
    name: 'titles:chats',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

export const macroPointColumnSettings = {
  [GC.FIELD_STATUS]: {
    width: 150,
    name: 'titles:status',
  },
  [GC.FIELD_STATUS_CODE]: {
    width: 150,
    name: 'titles:status-code',
  },
  [GC.FIELD_STATUS_DESCRIPTION]: {
    width: 200,
    name: 'titles:status-description',
  },
  macroPointOrderId: {
    width: 250,
    name: 'titles:macro-point-order-id',
  },
  [GC.FIELD_ERRORS]: {
    width: 400,
    name: 'titles:errors',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

export const externalSystemColumnSettings = {
  actions: {
    width: 50,
    freezed: true,
    name: 'actions',
    customComponent: (row: Object) =>
      <ErrorsPopover wrapperPadding='0px' errors={R.path(['data', 'errors'], row)} />,
  },
  [GC.FIELD_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
    customComponent: R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_INTEGRATION_TYPE]),
    ),
  },
  [GC.FIELD_EXTERNAL_ID]: {
    width: 200,
    name: 'titles:external-id',
  },
  [GC.FIELD_STOP]: {
    width: 500,
    name: 'titles:stop',
  },
  [GC.FIELD_STATUS]: {
    width: 400,
    name: 'titles:status',
  },
  [GC.FIELD_SENT_BY]: {
    width: 200,
    name: 'titles:sent-by',
  },
  [GC.FIELD_SENT_DATE]: {
    width: 200,
    name: 'titles:sent-date',
  },
  [GC.FIELD_ERRORS]: {
    width: 600,
    name: 'titles:errors',
    customComponent: ({ data }: Object) => R.join(', ', R.pathOr([], [GC.FIELD_ERRORS], data)),
  },
};

export const containerColumnSettings = {
  [GC.FIELD_CONTAINER_TYPE_GUID]: {
    width: 200,
    name: 'titles:container-type',
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_NAME]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_CONTAINER_TYPE_GUID, data), GC.FIELD_GUID)),
    )(G.getAmousContainerTypeListFromWindow()),
  },
  [GC.FIELD_CONTAINER_NUMBER]: {
    width: 200,
    name: 'titles:container-number',
  },
  [GC.FIELD_CONTAINER_INITIAL]: {
    width: 200,
    name: 'titles:container-initial',
  },
  [GC.FIELD_RAIL_BILLING_STATUS]: {
    width: 150,
    type: 'enumLocale',
    name: 'titles:rail-billing',
  },
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: {
    width: 150,
    name: 'titles:empty-weight',
  },
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: {
    width: 150,
    name: 'titles:full-weight',
  },
  [GC.FIELD_WEIGHT_UOM]: {
    width: 150,
    type: 'uomLocale',
    name: 'titles:weight-uom',
  },
  [GC.FIELD_WIDTH]: {
    width: 150,
    name: 'titles:width',
  },
  [GC.FIELD_HEIGHT]: {
    width: 150,
    name: 'titles:height',
  },
  [GC.FIELD_LENGTH]: {
    width: 150,
    name: 'titles:length',
  },
  [GC.FIELD_DIMENSIONS_UOM]: {
    width: 150,
    type: 'uomLocale',
    name: 'titles:dimensions-uom',
  },
};

export const crossBorderIntegrationColumnSettings = {
  actions: {
    width: 50,
    freezed: true,
    name: 'actions',
    customComponent: (row: Object) =>
      <ErrorsPopover wrapperPadding='0px' errors={R.path(['data', 'errors'], row)} />,
  },
  [GC.FIELD_INTEGRATION_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:integration-type',
  },
  [GC.FIELD_TEL_NUMBER]: {
    width: 200,
    name: 'titles:tel-number',
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:status',
  },
  [GC.FIELD_MANIFEST_TYPE]: {
    width: 200,
    name: 'titles:manifest-type',
  },
  [GC.FIELD_ENTRY_PORT]: {
    width: 200,
    name: 'titles:entry-port',
    customComponent: (row: Object) => {
      const manifestType = R.path(['data', GC.FIELD_MANIFEST_TYPE], row);

      const options = G.ifElse(
        R.equals(manifestType, GC.ACI),
        GC.ACIEntryPort,
        GC.ACEPortOfEntry,
      );

      return options[row.fieldData];
    },
  },
  [GC.FIELD_RELEASE_OFFICE]: {
    width: 200,
    name: 'titles:release-office',
    customComponent: (row: Object) => GC.ACIReleaseOffice[row.fieldData],
  },
  [GC.FIELD_ESTIMATED_ARRIVAL_DATE_TIME]: {
    width: 200,
    type: 'date',
    name: 'titles:estimated-arrival-date-time',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};
