import * as R from 'ramda';
import React from 'react';
import { space } from 'styled-system';
import styled from 'styled-components';
// components
import { Label } from '../../../components/label';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const FormGroupTitle = styled.div`
  ${space}

  height: 30px;
  line-height: 30px;
  padding-left: 15px;

  background-color: ${() => G.getTheme('colors.mainBlue')};
  color: ${() => G.getTheme('forms.group.title.textColor')};
`;

const FormGroupTitleWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background-color: ${() => G.getTheme('colors.mainBlue')};
`;

const ToggleArrowIcon = styled.div`
  top: 8px;
  right: 16px;
  position: absolute;
  transform: ${({ isOpened }: Object) => isOpened && 'rotate(180deg)'};
`;

const FormGroupTitleMultipleComponent = (props: Object) => (
  <FormGroupTitleWrapper>
    <FormGroupTitle mb={R.or(props.mb, '5px')}>
      <Label
        endAction={props.onClickLabel}
        endIcon={R.or(props.endIcon, null)}
        frontAction={props.onClickFrontLabel}
        frontIcon={R.or(props.frontIcon, null)}
      >
        {props.title}
      </Label>
    </FormGroupTitle>
    <ToggleArrowIcon
      alt='toggle'
      isOpened={props.isOpened}
      onClick={props.onToggleFormGroup}
    >
      {
        props.showArrowToggle &&
        I.arrowDownSimple(G.getTheme('forms.group.title.textColor'))
      }
    </ToggleArrowIcon>
    {props.children}
  </FormGroupTitleWrapper>
);

export default FormGroupTitleMultipleComponent;
