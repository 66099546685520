import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature commission-assignment
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  commissionAssignmentList: {},
};

const setInitialState = () => initialState;

const getCommissionAssignmentListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.assoc(
      'index',
      index,
      item,
    )),
  )(data);

  return P.$set('commissionAssignmentList', list, state);
};

const createOrUpdateCommissionAssignmentSuccess = (state: Object, data: Object) => {
  const { guid } = data;
  const { commissionAssignmentList } = state;

  if (R.has(guid, commissionAssignmentList)) {
    return P.$merge(`commissionAssignmentList.${guid}`, data, state);
  }
  const index = R.length(R.values(commissionAssignmentList));
  const item = R.assoc('index', index, data);

  return P.$set(`commissionAssignmentList.${guid}`, item, state);
};

const removeCommissionAssignmentSuccess = (state: Object, guid: string) => (
  P.$drop(`commissionAssignmentList.${guid}`, state)
);

export default createReducer({
  [A.setInitialState]: setInitialState,
  [A.removeCommissionAssignmentSuccess]: removeCommissionAssignmentSuccess,
  [A.getCommissionAssignmentListSuccess]: getCommissionAssignmentListSuccess,
  [A.createOrUpdateCommissionAssignmentSuccess]: createOrUpdateCommissionAssignmentSuccess,
}, initialState);

