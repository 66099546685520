import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../../components/confirm';
import { ActionsElement } from '../../../../components/actions-element';
import { withOpenTelRateRequestList } from '../../../../components/tel-rate-request-list';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// utilities
import routesMap from '../../../../utilities/routes';
//////////////////////////////////////////////////

const enhance = compose(
  withOpenTelRateRequestList,
  withHandlers({
    handleGoToDetails: (props: Object) => () => {
      const { entity, closeFixedPopup } = props;

      closeFixedPopup();
      G.goToRoute(routesMap.dispatchDetailsLoad(entity[GC.FIELD_GUID]));
    },
    handleAddReference: (props: Object) => () => {
      const { entity, addReference, closeFixedPopup } = props;

      closeFixedPopup();
      addReference(entity[GC.FIELD_GUID]);
    },
    handlePostToLoadBoard: (props: Object) => () => {
      const { entity, postToLoadBoard, closeFixedPopup } = props;

      closeFixedPopup();
      postToLoadBoard(entity, false);
    },
    handleGoToRouteBuilder: ({ entity, setInitialRouteGuid, setInitialPlannerData }: Object) => () => {
      const { guid, status, events, routeGuid } = entity;

      if (routeGuid) {
        setInitialRouteGuid(routeGuid);
        setTimeout(() => G.goToRoute(routesMap.routeBuilder), 0);
      } else {
        if (R.includes(status, [GC.LOAD_STATUS_CANCELED, GC.LOAD_STATUS_DELIVERED])) {
          const message = G.getWindowLocale(
            'messages:cant-use-load-planner-for-trip-in-status',
            'Sorry, you can not use the Load Planner for a trip in this status',
          );

          return G.showToastrMessageSimple('info', message);
        }

        setInitialPlannerData({ telGuid: guid });
        setTimeout(() => G.goToRoute(routesMap.routeBuilderEvents), 0);
      }
    },
    handleDelete: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        closeFixedPopup,
        handleDeleteTel,
      } = props;

      const data = R.pick([GC.FIELD_GUID, 'rowIndex', 'tableIndex'], entity);
      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => handleDeleteTel(data),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
      });
      closeFixedPopup();
      openModal(modalContent);
    },
    handleCallStatusCheck: (props: Object) => () => {
      const { entity, closeFixedPopup, callStatusCheck } = props;

      closeFixedPopup();
      callStatusCheck(entity[GC.FIELD_GUID]);
    },
    handleToggleHotLoad: (props: Object) => () => {
      const { closeFixedPopup, entity: { hot, guid, tableIndex }, toggleMarkAsHotLoadByLoadTypeRequest } = props;

      closeFixedPopup();
      toggleMarkAsHotLoadByLoadTypeRequest({
        guid,
        tableIndex,
        hot: R.not(hot),
        fromPage: 'dispatchBoard',
        [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL,
      });
    },
    handlePostEquipmentToLoadBoard: (props: Object) => () => {
      const { entity, closeFixedPopup, handlePostEquipment } = props;

      closeFixedPopup();
      handlePostEquipment(entity);
    },
  }),
  pure,
);

const getOptions = (props: Object) => {
  const {
    entity,
    handleDelete,
    closeFixedPopup,
    handleGoToDetails,
    handleAddReference,
    handleToggleHotLoad,
    handleCallStatusCheck,
    handlePostToLoadBoard,
    handleGoToRouteBuilder,
    handleSendQuoteRequests,
    handleSendTelToMacroPoint,
    handleOpenTelRateRequestList,
    handleSendTelToExternalSystem,
    handlePostEquipmentToLoadBoard,
  } = props;

  const iconColor = G.getTheme('colors.light.blue');
  let actions = [
    {
      action: handleGoToDetails,
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      frontIcon: I.routesLoads(iconColor, 13, 13),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
    },
    {
      action: handleAddReference,
      permissions: [PC.TEL_WRITE],
      frontIcon: I.plusRound(iconColor, 13, 13),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    },
    {
      permissions: [PC.TEL_WRITE],
      action: handleGoToRouteBuilder,
      frontIcon: I.routeBuilder(iconColor, 13, 13),
      text: G.getWindowLocale(...G.ifElse(
        G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_ROUTE_GUID, entity)),
        ['actions:go-to-load-planner', 'Go To Load Planner'],
        ['actions:go-to-route-builder', 'Go To Route Builder'],
      )),
    },
    {
      action: handlePostToLoadBoard,
      permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
      frontIcon: I.createTruckIcon(iconColor, 16, 16),
      text: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
    },
    {
      action: handlePostEquipmentToLoadBoard,
      frontIcon: I.loadSearch(iconColor, 16, 16),
      permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
      text: G.getWindowLocale('titles:post-equipment', 'Post Equipment'),
    },
    {
      permissions: [PC.TEL_WRITE],
      frontIcon: I.callCheck(iconColor, 13, 13),
      action: () => handleSendTelToMacroPoint(G.getGuidFromObject(entity)),
      text: G.getWindowLocale('actions:activate-macro-point', 'Activate MacroPoint'),
    },
    {
      frontIcon: I.locationMarker(iconColor, 13, 13),
      permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
      text: G.getWindowLocale('titles:send-to-external-system', 'Send To External System'),
      action: () => handleSendTelToExternalSystem({
        telGuid: G.getGuidFromObject(entity),
        fromPage: GC.PAGE_DISPATCH_BOARD_NEW,
      }),
    },
    {
      action: handleDelete,
      permissions: [PC.TEL_DELETE_EXECUTE],
      frontIcon: I.trash(iconColor, 11, 13),
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
    {
      action: handleCallStatusCheck,
      frontIcon: I.callCheck(iconColor, 13, 13),
      text: G.getWindowLocale('actions:status-check', 'Call Status Check'),
      permissions: R.and(R.includes(R.path([GC.FIELD_STATUS], entity), GC.TEL_STATUS_CHECK), [PC.TEL_WRITE]),
    },
    {
      permissions: [PC.TEL_WRITE],
      action: handleToggleHotLoad,
      frontIcon: I.hotLoad(iconColor),
      text: G.ifElse(
        G.isFalse(entity.hot),
        G.getWindowLocale('actions:mark-as-hot', 'Mark As Hot'),
        G.getWindowLocale('actions:remove-hot-load', 'Remove Hot Load'),
      ),
    },
    {
      permissions: [PC.CARRIER_RATE_WRITE],
      frontIcon: I.gearSolid(iconColor, 13, 13),
      text: G.getWindowLocale('titles:carriers-bids', 'Carriers Bids'),
      action: () => {
        closeFixedPopup();
        handleOpenTelRateRequestList(G.getGuidFromObject(entity));
      },
    },
  ];

  const sendQuoteRequestAction = {
    frontIcon: I.gear(iconColor),
    permissions: [PC.CARRIER_RATE_WRITE],
    action: () => handleSendQuoteRequests(G.getGuidFromObject(entity)),
    text: G.getWindowLocale('titles:send-carries-bids', 'Send Carriers Bids'),
  };

  if (R.includes(
    G.getPropFromObject(GC.FIELD_STATUS, entity),
    GC.TES_STATUSES_TO_SEND_QUOTE_REQUEST,
  )) actions = R.append(sendQuoteRequestAction, actions);

  return actions;
};

export const RowActions = (props: Object) => <ActionsElement version={2} options={getOptions(props)} />;

export default enhance(RowActions);
