import React from 'react';
import { connect } from 'react-redux';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { ActionButton } from '../../../ui';
// feature branch
import { generateExcelWithCreditLimitRequest } from '../actions';
//////////////////////////////////////////////////

const GenerateExcelWithCreditLimit = ({ generateExcelWithCreditLimitRequest }: Object) => (
  <ActionButton
    ml={15}
    width={90}
    height={16}
    fontSize={11}
    type='button'
    minWidth='unset'
    borderRadius='3px'
    onClick={generateExcelWithCreditLimitRequest}
  >
    {G.getWindowLocale('actions:generate-excel', 'Generate Excel')}
  </ActionButton>
);

export default connect(null, { generateExcelWithCreditLimitRequest })(GenerateExcelWithCreditLimit);
