import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// features
import NoteForm from '../../dispatch-details-new/forms/note-form';
import { withAsyncLoadNotes } from '../../dispatch-board-new/hocs/with-async-load-notes';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover, withConnectModalAndLoaderActions } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
/////////////////////////////////////////////////

// TODO: with own refresh logic and catch

const withNoteCRUD = compose(
  withConnectModalAndLoaderActions,
  withFixedPopover,
  withAsyncLoadNotes,
  withHandlers({
    createNote: ({ loadGuid, loadType, getLoadNotesRequest }: Object) => async ({ message }: Object) => {
      const data = {
        loadGuid,
        pinned: false,
        [GC.FIELD_TEXT]: message,
      };

      await sendRequest('post', endpointsMap.telNote, { data });

      getLoadNotesRequest({ loadGuid, loadType });
    },
    updateNote: (props: Object) => (entity: Object) => {
      const { openModal, closeModal, closeContent } = props;

      const component = (
        <NoteForm
          initialValues={entity}
          closeModal={closeModal}
          handleActionLoadNote={async (data: Object) => {
            await sendRequest('put', endpointsMap.telNote, { data });

            closeModal();
          }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: G.getWindowLocale('actions:edit-note', 'Edit Note'),
        },
      };

      closeContent();

      openModal(modal);
    },
    deleteNote: (props: Object) => (entity: Object) => {
      const { openModal, closeModal, closeContent } = props;

      const textLocale = G.getWindowLocale(
        'messages:remove-ref-confirmation-text',
        'Are you sure you want delete this note?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: async () => {
                const endpoint = endpointsMap.getTelNoteEndpoint(G.getGuidFromObject(entity));

                await sendRequest('delete', endpoint);

                closeModal();
              },
            },
          ],
        },
      };

      closeContent();

      openModal(modal);
    },
    pinNote: (props: Object) => async (entity: Object) => {
      const { loadGuid, loadType, closeFixedPopup, getLoadNotesRequest } = props;

      const { pinned } = entity;

      const data = R.assoc('pinned', R.not(pinned), entity);

      const endpoint = G.ifElse(G.isLoadTypeClo(loadType), endpointsMap.cloNote, endpointsMap.telNote);

      await sendRequest('put', endpoint, { data });

      closeFixedPopup();

      getLoadNotesRequest({ loadGuid, loadType });
    },
  }),
  pure,
);

export {
  withNoteCRUD,
};
