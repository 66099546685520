import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { FooterBtns } from '../../../../components/footer-btns';
import CompensationRangeGroup from '../../../../components/compensation-range-group';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  STATE_OPTIONS,
  COUNTRY_OPTIONS,
  DRIVER_COMPENSATION_RATE_TYPE_CONTAIN,
} from '../../../../helpers/options';
// forms
import { FieldsetComponent } from '../../../../forms';
// hocs
import { withAsyncConfigs } from '../../../../hocs';
// ui
import { SimpleWrapper } from '../../../../ui';
// feature fleet
import { CompensationAccessorialsFormGroupTable } from '../../components/accessorials-form-group-table';
// feature fleet/driver
import * as LC from '../constants';
import {
  Title,
  LocationFieldsWrapper,
  DriverCompensationForm,
  LocationSectionWrapper,
} from '../ui';
import {
  CompensationDatesSection,
  compensationDetailsFields,
  defaultCompensationFields,
  compensationLocationFields,
  getDriverComponsationValidationSchemaObject,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const setLocationValue = (
  values: Object,
  valuePropName: string,
  fieldName: string,
  setFieldValue: Function,
) => {
  const value = R.prop(valuePropName, values);
  const currentValuesByPath = R.pathOr([], [fieldName], values);

  if (R.and(
    G.isNotNilAndNotEmpty(value),
    G.notContain(value, currentValuesByPath),
  )) {
    setFieldValue(fieldName, R.append(value, currentValuesByPath));
  }
};

const setInitialOptions = (constant: string) => (props: Object) => (R.map(
  (value: string) => ({label: value, value}),
  R.pathOr([], ['initialValues', constant], props),
));

const setOptionsAndValue = (
  fieldId: string,
  newValue: string,
  formValues: Object,
  props: Object,
) => {
  const labelStrings = R.split(':', fieldId);
  const locationType = R.head(labelStrings);
  const property = R.last(labelStrings);
  const cityField = R.equals(property, GC.FIELD_CITY);
  const path = G.ifElse(
    cityField,
    `${locationType}:${GC.FIELD_DRIVER_COMPENSATION_CITIES}`,
    `${locationType}:${GC.FIELD_DRIVER_COMPENSATION_ZIP_CODES}`,
  );
  const currentValuesByPath = R.pathOr([], [path], formValues);
  const functionsForSetOptions = {
    [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITY]: props.setOriginCityOptions,
    [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP]: props.setOriginZipCodesOptions,
    [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITY]: props.setDestinationCityOptions,
    [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP]: props.setDestinationZipCodesOptions,
  };

  if (R.and(
    G.notContain(newValue, currentValuesByPath),
    G.isNotNilAndNotEmpty(newValue),
  )) {
    R.propOr(() => {}, fieldId, functionsForSetOptions)(
      (prev: Array) => R.append({label: newValue, value: newValue}, prev),
    );
    props.setFieldValue(path, R.append(newValue, currentValuesByPath));
  }

  if (cityField) {
    if (R.includes(R.prop(GC.FIELD_STATE, formValues), R.map(R.prop('value'), STATE_OPTIONS))) {
      setLocationValue(
        formValues,
        GC.FIELD_STATE,
        `${locationType}:${GC.FIELD_DRIVER_COMPENSATION_STATES}`,
        props.setFieldValue,
      );
    }

    if (R.includes(R.prop(GC.FIELD_COUNTRY, formValues), R.map(R.prop('value'), COUNTRY_OPTIONS))) {
      setLocationValue(
        formValues,
        GC.FIELD_COUNTRY,
        `${locationType}:${GC.FIELD_DRIVER_COMPENSATION_COUNTRIES}`,
        props.setFieldValue,
      );
    }
  }
  props.setFieldValue(fieldId, '');
};

const makeValuesForRequest = (values: Object) => {
  let valuesForRequest = R.pick(
    GC.GROUPED_FIELDS.DRIVER_COMPENSATION_MAIN_FIELDS,
    values,
  );
  R.forEachObjIndexed((value: any, label: string) => {
    const labelStrings = R.split(':', label);

    if (R.or(
      R.includes(LC.FIELD_DRIVER_COMPENSATION_ORIGIN, labelStrings),
      R.includes(LC.FIELD_DRIVER_COMPENSATION_DESTINATION, labelStrings),
    )) {
      valuesForRequest = R.assocPath(labelStrings, value, valuesForRequest);
    }
  }, R.omit(LC.DRIVER_COMPENSATION_MAIN_FIELDS_OMIT, values));

  return valuesForRequest;
};

const enhance = compose(
  withAsyncConfigs,
  withState(
    LC.ORIGIN_ZIP_CODES_OPTIONS,
    'setOriginZipCodesOptions',
    setInitialOptions(LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_CODES),
  ),
  withState(
    LC.DESTINATION_ZIP_CODES_OPTIONS,
    'setDestinationZipCodesOptions',
    setInitialOptions(LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_CODES),
  ),
  withState(
    LC.ORIGIN_CITY_OPTIONS,
    'setOriginCityOptions',
    setInitialOptions(LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITIES),
  ),
  withState(
    LC.DESTINATION_CITY_OPTIONS,
    'setDestinationCityOptions',
    setInitialOptions(LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITIES),
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => {
      const { initialValues, searchedValues } = props;

      const transportationMode = R.pathOr('', ['initialValues', GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], props);
      const serviceType = R.pathOr('', ['initialValues', GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], props);

      return G.setInitialFormikValues(
        defaultCompensationFields,
        R.mergeRight(initialValues, { serviceType, transportationMode }),
        searchedValues,
      );
    },
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getDriverComponsationValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props, resetForm, setSubmitting }: Object) => {
      const { saveAndAddNew } = values;
      const { driverGuid, submitAction } = props;

      const valuesForRequest = {
        ...makeValuesForRequest(values),
        resetForm,
        driverGuid,
        saveAndAddNew,
        setSubmitting,
      };

      submitAction(valuesForRequest);
    },
    displayName: LC.DRIVER_COMPENSATION_FORM,
  }),
  withHandlers({
    handleCustomSetValues: (props: Object) => (values: Object) => {
      const labelStrings = R.split(':', values.label);
      const property = R.last(labelStrings);
      setOptionsAndValue(values.label, R.prop(property, values), values, props);
    },
    handleChangeSelect: (props: Object) => (id: any, data: Array) => {
      const functionsForSetOptions = {
        [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITIES]: props.setOriginCityOptions,
        [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_CODES]: props.setOriginZipCodesOptions,
        [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITIES]: props.setDestinationCityOptions,
        [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_CODES]: props.setDestinationZipCodesOptions,
      };
      R.propOr(() => {}, id, functionsForSetOptions)(
        (prev: Array) => prev.filter((option: Object) => (
          R.includes(option.value, data)
        ),
      ));
    },
    handleCustomChange: (props: Object) => (e: Object) => {
      if (R.equals(R.path(['target', 'id'], e), GC.FIELD_CHARGE_RATE_TYPE)) {
        const newValues = {
          [GC.FIELD_CHARGE_RATE_TYPE]: R.path(['target', 'value'], e),
          [GC.FIELD_CHARGE_RATE_UNIT]: '',
        };

        if (R.equals(R.path(['target', 'value'], e), GC.CHARGE_RATE_TYPE_FLAT)) {
          newValues[GC.FIELD_CHARGE_MIN_RATE] = '';
          newValues[GC.FIELD_CHARGE_MAX_RATE] = '';
        }

        return props.setValues(R.mergeRight(props.values, newValues));
      }
      props.handleChange(e);
    },
    handleEnter: (props: Object) => (value: string, id: string) =>
      setOptionsAndValue(id, value, props.values, props),
  }),
  pure,
);

const setOptions = (props: Object, field: Object) => {
  const shouldSetOptions = R.and(
    R.equals(field.fieldName, GC.FIELD_CHARGE_RATE_UNIT),
    R.includes(
      R.path(['values', GC.FIELD_CHARGE_RATE_TYPE], props),
      DRIVER_COMPENSATION_RATE_TYPE_CONTAIN,
    ),
  );

  if (shouldSetOptions) {
    return R.path(['optionsGroups', R.path(['values', GC.FIELD_CHARGE_RATE_TYPE], props)], field);
  }

  return field.options;
};


const getLoadOptionsForRateSelect = (props: Object) => {
  const transportationMode = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_MODE_TRANSPORTATION,
    true,
  );
  const serviceType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
    true,
  );

  return { serviceType, transportationMode };
};

const DriverCompensation = (props: Object) => (
  <DriverCompensationForm>
    <form onSubmit={props.handleSubmit}>
      <FooterBtns
        jc='center'
        width='25%'
        closeModal={props.closeModal}
        isSubmitting={props.isSubmitting}
        saveAndAddNew={G.isFalse(props.editMode)}
        submitAction={() => props.setFieldValue('saveAndAddNew', false)}
        handleSaveAndAddNew={() => props.setFieldValue('saveAndAddNew', true)}
      />
      {
        R.and(G.isTrue(props.editMode), G.isTrue(props.compensationAccessorials)) &&
        <CompensationAccessorialsFormGroupTable {...props} />
      }
      <CompensationRangeGroup
        {...G.getFormikProps(props)}
        initialValues={props.initialValues}
      />
      <SimpleWrapper
        p='0 15px 10px'
        borderTop='solid 1px'
        flexDirection='column'
        alignItems='flex-start'
      >
        <Title m='10px 0 5px 10px'>
          {G.getWindowLocale('titles:compensation-details', 'Compensation Details')}
        </Title>
        <FieldsetComponent
          {...props}
          fieldsetWidth='720px'
          justifyContent='space-between'
          fields={compensationDetailsFields}
          setValues={props.handleCustomSetValues}
          handleChange={props.handleCustomChange}
          optionsForSelect={getLoadOptionsForRateSelect(props)}
          setOptionsFunction={(field: Object) => setOptions(props, field)}
        />
      </SimpleWrapper>
      <LocationSectionWrapper>
        {compensationLocationFields.map((item: Object, index: number) => (
          <LocationFieldsWrapper key={index}>
            <Title m='10px 0 5px 10px'>
              {G.getWindowLocale(item.title)}
            </Title>
            <FieldsetComponent
              {...props}
              fields={item.fields}
              fieldsGroupWidth='50%'
              fieldsetPadding='0 0 0 5px'
              setValues={props.handleCustomSetValues}
              optionsForSelect={R.pick(LC.COMPENSATION_OPTIONS_PICK, props)}
              handlers={{
                handleEnter: props.handleEnter,
                handleChangeSelect: props.handleChangeSelect,
              }}
            />
          </LocationFieldsWrapper>
        ))}
      </LocationSectionWrapper>
      <SimpleWrapper p='10px 15px' display='block'>
        <FieldsetComponent
          {...props}
          setValues={props.handleCustomSetValues}
          fields={CompensationDatesSection.fields}
        />
      </SimpleWrapper>
    </form>
  </DriverCompensationForm>
);

export default enhance(DriverCompensation);
