import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers/constants
import * as G from '../../../helpers';
// feature carrier
import DocForm from './doc-form';
import { docSettings, mailSettings } from '../settings/column-settings';
import {
  CARRIER_DOCUMENT_FORM,
  CARRIER_GROUP_NAME_MAILS,
  CARRIER_GROUP_NAME_DOCUMENTS } from '../constants';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleDownloadMail: ({ downloadCarrierMailRequest }: Object) => ({ guid }: Object) =>
      downloadCarrierMailRequest(guid),
    handleAddDoc: (props: Object) => () => {
      const modalContext = (
        <DocForm
          closeModal={props.closeModal}
          carrierConfigs={props.carrierConfigs}
          carrierGuid={props.initialValues.guid}
          submitAction={props.createCarrierDocRequest} />
      );
      const modal = {
        component: modalContext,
        options: {
          height: 'auto',
          width: '440px',
          movable: true,
          btnWidth: '200px',
          title: G.getWindowLocale('titles:add-doc', 'Add Document'),
          controlButtons: [
            {
              type: 'button',
              id: CARRIER_DOCUMENT_FORM,
              name: G.getWindowLocale('actions:create', 'Create'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleEditDoc: (props: Object) => (doc: Object) => {
      const modalContext = (
        <DocForm
          initialValues={doc}
          closeModal={props.closeModal}
          carrierConfigs={props.carrierConfigs}
          carrierGuid={props.initialValues.guid}
          submitAction={props.updateCarrierDocRequest} />
      );
      const modal = {
        component: modalContext,
        options: {
          height: 'auto',
          width: '440px',
          btnWidth: '200px',
          title: G.getWindowLocale('titles:edit-document', 'Edit Document'),
          controlButtons: [
            {
              type: 'button',
              id: CARRIER_DOCUMENT_FORM,
              name: G.getWindowLocale('actions:update', 'Update'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleDeleteDoc: (props: Object) => (doc: Object) => {
      const confirmationContent = (
        <ConfirmComponent
          display='block'
          name={R.path(['documentType', 'displayedValue'], doc)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteCarrierDocRequest(doc.guid);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

const CustomPanel = (props: Object) => (
  <FormGroupTitleComponent
    mb='0px'
    endIcon={null}
    withArrowDown={true}
    isOpened={props.isOpened}
    onClickLabel={props.handleAddClick}
    onToggleFormGroup={props.handleToggleFormGroup}
    text={G.getWindowLocale('titles:mails', 'mails')} />
);

const DocsTabComponent = (props: Object) => (
  <div>
    <FormGroupTable
      entities={props.carrierDocs}
      entitiesFields={docSettings}
      fields={props.collapsedGroup}
      handleAddClick={props.handleAddDoc}
      handleEditRow={props.handleEditDoc}
      handleDeleteRow={props.handleDeleteDoc}
      groupName={CARRIER_GROUP_NAME_DOCUMENTS}
      panelTitle={G.getWindowLocale('titles:documents', 'Documents')}
      isOpened={R.path([CARRIER_GROUP_NAME_DOCUMENTS], props.collapsedGroup)}
      handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_DOCUMENTS)} />
    <FormGroupTable
      notEditable={true}
      notDeletable={true}
      customPanel={CustomPanel}
      entities={props.mailList}
      entitiesFields={mailSettings}
      fields={props.collapsedGroup}
      groupName={CARRIER_GROUP_NAME_MAILS}
      handleDownloadMail={props.handleDownloadMail}
      panelTitle={G.getWindowLocale('titles:mails', 'Mails')}
      isOpened={R.path([CARRIER_GROUP_NAME_DOCUMENTS], props.collapsedGroup)}
      handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_DOCUMENTS)} />
  </div>
);

export default enhance(DocsTabComponent);
