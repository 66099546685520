import styled from 'styled-components';
import {
  space,
  width,
  height,
} from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
  background-color: ${G.getTheme('colors.light.lightGreen')};
`;

export const Description = styled.div`
  ${space}
  ${width}
  ${height}

  cursor: text;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 20px;
  user-select: none;
  border-radius: 4px;
  font-family: monospace;
  letter-spacing: 0.25px;

  color: ${G.getTheme('colors.white')};
  border: 1px solid ${G.getTheme('colors.cobalt')};
  background-color: ${G.getTheme('colors.neroBlack')};

  & span {
    font-size: 15px;
  }

  & ul {
    margin: 5px;
    font-size: 14px;
    list-style: none;
    padding-left: 10px;
  }

  & ul li::before {
    top: -1px;
    width: 1em;
    content: '●';
    font-weight: bold;
    margin-left: -1em;
    position: relative;
    display: inline-block;
    color: ${G.getTheme('colors.light.mainRed')};
  }

  & ul i {
    font-style: normal;
    color: ${G.getTheme('colors.light.lightGreen')};
  }
`;

Description.defaultProps = {
  width: '100%',
};
