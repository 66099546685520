import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import { tollChargeFields, defaultTollChargesFields, getTollChargesValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultTollChargesFields,
      props.initialValues,
    ),
    validationSchema: getTollChargesValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const TollChargeForm = (props: Object) => (
  <Box mx='auto' width={270}>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={tollChargeFields}
        handlers={{ handleDisableIntegrationType: () => props.isEditMode }} />
      <FormFooter boxStyles={{ py: 10 }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(TollChargeForm);
