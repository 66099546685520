import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// common
export const setInitialState = createAction('setInitialState');
export const changeActiveList = createAction('changeActiveList');
export const cancelCLORequest = createAction('cancelCLORequest');
export const unquoteCLORequest = createAction('unquoteCLORequest');
export const getOrderDetailsSuccess = createAction('getOrderDetailsSuccess');
export const getOrderDetailsRequest = createAction('getOrderDetailsRequest');
export const changeActiveListSuccess = createAction('changeActiveListSuccess');
export const createTelFromCloRequest = createAction('createTelFromCloRequest');
export const updateOrderDetailsRequest = createAction('updateOrderDetailsRequest');
export const updateOrderDetailsSuccess = createAction('updateOrderDetailsSuccess');
export const changeCancelStatusRequest = createAction('changeCancelStatusRequest');
export const toggleExpandedListsSuccess = createAction('toggleExpandedListsSuccess');
export const getOrderInvoiceTotalsSuccess = createAction('getOrderInvoiceTotalsSuccess');
export const getOrderInvoiceTotalsRequest = createAction('getOrderInvoiceTotalsRequest');
export const getAvailableLoadCustomStatusListRequest = createAction('getAvailableLoadCustomStatusListRequest');
export const getAvailableLoadCustomStatusListSuccess = createAction('getAvailableLoadCustomStatusListSuccess');
// cost allocations
export const getOrderCostAllocationsRequest = createAction('getOrderCostAllocationsRequest');
export const getOrderCostAllocationsSuccess = createAction('getOrderCostAllocationsSuccess');
// notifications
export const hideOrderNotificationsRequest = createAction('hideOrderNotificationsRequest');
export const hideOrderNotificationsSuccess = createAction('hideOrderNotificationsSuccess');
export const getOrderNotificationListRequest = createAction('getOrderNotificationListRequest');
export const getOrderNotificationListSuccess = createAction('getOrderNotificationListSuccess');
export const markOrderNotificationAsReadRequest = createAction('markOrderNotificationAsReadRequest');
export const markOrderNotificationAsReadSuccess = createAction('markOrderNotificationAsReadSuccess');
// configs
export const getOrderConfigsByNamesRequest = createAction('getOrderConfigsByNamesRequest');
export const getOrderConfigsByNamesSuccess = createAction('getOrderConfigsByNamesSuccess');
export const getOrderConfigByGroupNamesSuccess = createAction('getOrderConfigByGroupNamesSuccess');
export const getOrderConfigByGroupNamesRequest = createAction('getOrderConfigByGroupNamesRequest');
export const getStatusMessagesConfigListSuccess = createAction('getStatusMessagesConfigListSuccess');
export const getStatusMessagesConfigListRequest = createAction('getStatusMessagesConfigListRequest');
export const getDataAndConfigsByBranchGuidRequest = createAction('getDataAndConfigsByBranchGuidRequest');
// order
export const getOrderStatusRequest = createAction('getOrderStatusRequest');
export const getOrderStatusSuccess = createAction('getOrderStatusSuccess');
// load
export const deleteTelFromCloRequest = createAction('deleteTelFromCloRequest');
export const getLinkedLoadListRequest = createAction('getLinkedLoadListRequest');
export const getLinkedLoadListSuccess = createAction('getLinkedLoadListSuccess');
export const getRelatedLoadListRequest = createAction('getRelatedLoadListRequest');
export const getRelatedLoadListSuccess = createAction('getRelatedLoadListSuccess');
// containers
export const updateOrderContainerRequest = createAction('updateOrderContainerRequest');
export const getOrderContainerListSuccess = createAction('getOrderContainerListSuccess');
// items
export const updateOrderItemRequest = createAction('updateOrderItemRequest');
export const getOrderItemListSuccess = createAction('getOrderItemListSuccess');
export const toggleOrderVehicleItemRequest = createAction('toggleOrderVehicleItemRequest');
export const toggleOrderVehicleItemSuccess = createAction('toggleOrderVehicleItemSuccess');
export const removeOrderVehicleItemDamageSuccess = createAction('removeOrderVehicleItemDamageSuccess');
export const createOrUpdateOrderVehicleItemDamageSuccess = createAction('createOrUpdateOrderVehicleItemDamageSuccess');
// references
export const updateOrderReferenceRequest = createAction('updateOrderReferenceRequest');
export const updateOrderReferenceSuccess = createAction('updateOrderReferenceSuccess');
export const createOrderReferenceRequest = createAction('createOrderReferenceRequest');
export const createOrderReferenceSuccess = createAction('createOrderReferenceSuccess');
export const removeOrderReferenceRequest = createAction('removeOrderReferenceRequest');
export const removeOrderReferenceSuccess = createAction('removeOrderReferenceSuccess');
export const getOrderReferenceListRequest = createAction('getOrderReferenceListRequest');
export const getOrderReferenceListSuccess = createAction('getOrderReferenceListSuccess');
// customer rates
export const selectOrderCustomerRateRequest = createAction('selectOrderCustomerRateRequest');
export const selectOrderCustomerRateSuccess = createAction('selectOrderCustomerRateSuccess');
export const removeOrderCustomerRateRequest = createAction('removeOrderCustomerRateRequest');
export const removeOrderCustomerRateSuccess = createAction('removeOrderCustomerRateSuccess');
export const updateOrderCustomerRateSuccess = createAction('updateOrderCustomerRateSuccess');
export const updateOrderCustomerRateRequest = createAction('updateOrderCustomerRateRequest');
export const createOrderCustomerRateSuccess = createAction('createOrderCustomerRateSuccess');
export const createOrderCustomerRateRequest = createAction('createOrderCustomerRateRequest');
export const getOrderCustomerRateListRequest = createAction('getOrderCustomerRateListRequest');
export const getOrderCustomerRateListSuccess = createAction('getOrderCustomerRateListSuccess');
// status messages
export const removeOrderStatusMessageRequest = createAction('removeOrderStatusMessageRequest');
export const removeOrderStatusMessageSuccess = createAction('removeOrderStatusMessageSuccess');
export const updateOrderStatusMessageRequest = createAction('updateOrderStatusMessageRequest');
export const createOrderStatusMessageRequest = createAction('createOrderStatusMessageRequest');
export const createOrderStatusMessageSuccess = createAction('createOrderStatusMessageSuccess');
export const updateOrderStatusMessageSuccess = createAction('updateOrderStatusMessageSuccess');
export const getOrderStatusMessageListRequest = createAction('getOrderStatusMessageListRequest');
export const getOrderStatusMessageListSuccess = createAction('getOrderStatusMessageListSuccess');
export const createOrderMultipleStatusMessageRequest = createAction('createOrderMultipleStatusMessageRequest');
export const createOrderMultipleStatusMessageSuccess = createAction('createOrderMultipleStatusMessageSuccess');
// customer invoices
export const sendCIToFinancialRequest = createAction('sendCIToFinancialRequest');
export const printOrderCustomerInvoiceRequest = createAction('printOrderCustomerInvoiceRequest');
export const updateOrderCustomerInvoiceRequest = createAction('updateOrderCustomerInvoiceRequest');
export const updateOrderCustomerInvoiceSuccess = createAction('updateOrderCustomerInvoiceSuccess');
export const createOrderCustomerInvoiceSuccess = createAction('createOrderCustomerInvoiceSuccess');
export const removeOrderCustomerInvoiceSuccess = createAction('removeOrderCustomerInvoiceSuccess');
export const createOrderCustomerInvoiceRequest = createAction('createOrderCustomerInvoiceRequest');
export const removeOrderCustomerInvoiceRequest = createAction('removeOrderCustomerInvoiceRequest');
export const getOrderCustomerInvoiceListRequest = createAction('getOrderCustomerInvoiceListRequest');
export const getOrderCustomerInvoiceListSuccess = createAction('getOrderCustomerInvoiceListSuccess');
// events
export const getOrderEventsRequest = createAction('getOrderEventsRequest');
export const getOrderEventsSuccess = createAction('getOrderEventsSuccess');
export const updateAppointmentRequest = createAction('updateAppointmentRequest');
export const updateAppointmentSuccess = createAction('updateAppointmentSuccess');
// documents
export const getOrderXMLRequest = createAction('getOrderXMLRequest');
export const printDocumentRequest = createAction('printDocumentRequest');
export const printLoadDocumentsRequest = createAction('printLoadDocumentsRequest');
export const removeOrderDocumentSuccess = createAction('removeOrderDocumentSuccess');
export const updateOrderDocumentSuccess = createAction('updateOrderDocumentSuccess');
export const createOrderDocumentSuccess = createAction('createOrderDocumentSuccess');
export const removeOrderDocumentRequest = createAction('removeOrderDocumentRequest');
export const updateOrderDocumentRequest = createAction('updateOrderDocumentRequest');
export const createOrderDocumentRequest = createAction('createOrderDocumentRequest');
export const getOrderDocumentListRequest = createAction('getOrderDocumentListRequest');
export const getOrderDocumentListSuccess = createAction('getOrderDocumentListSuccess');
export const downloadOrderDocumentRequest = createAction('downloadOrderDocumentRequest');
export const printDispatchDocumentRequest = createAction('printDispatchDocumentRequest');
// message center
export const removeOrderNoteRequest = createAction('removeOrderNoteRequest');
export const removeOrderNoteSuccess = createAction('removeOrderNoteSuccess');
export const createOrderNoteRequest = createAction('createOrderNoteRequest');
export const createOrderNoteSuccess = createAction('createOrderNoteSuccess');
export const updateOrderNoteRequest = createAction('updateOrderNoteRequest');
export const updateOrderNoteSuccess = createAction('updateOrderNoteSuccess');
export const getOrderNoteListRequest = createAction('getOrderNoteListRequest');
export const getOrderNoteListSuccess = createAction('getOrderNoteListSuccess');
export const changeMessageCenterActiveTab = createAction('changeMessageCenterActiveTab');
export const updateOrderChatMessageSuccess = createAction('updateOrderChatMessageSuccess');
export const removeOrderChatMessageSuccess = createAction('removeOrderChatMessageSuccess');
export const createOrderChatMessageSuccess = createAction('createOrderChatMessageSuccess');
export const updateOrderChatMessageRequest = createAction('updateOrderChatMessageRequest');
export const removeOrderChatMessageRequest = createAction('removeOrderChatMessageRequest');
export const createOrderChatMessageRequest = createAction('createOrderChatMessageRequest');
export const getOrderChatMessageListRequest = createAction('getOrderChatMessageListRequest');
export const getOrderChatMessageListSuccess = createAction('getOrderChatMessageListSuccess');
export const changeMessageCenterActiveTabSuccess = createAction('changeMessageCenterActiveTabSuccess');
// payments
export const updateOrderBillToRequest = createAction('updateOrderBillToRequest');
export const updateOrderBillToSuccess = createAction('updateOrderBillToSuccess');
// mails
export const getOrderMailListSuccess = createAction('getOrderMailListSuccess');
export const getOrderMailListRequest = createAction('getOrderMailListRequest');
// approve clo
export const approveCloRequest = createAction('approveCloRequest');
export const approveCloSuccess = createAction('approveCloSuccess');
// transportation mode grouping
export const getOrderTransportationModeGroupingListSuccess =
  createAction('getOrderTransportationModeGroupingListSuccess');
// claim
export const setClaimSuccess = createAction('setClaimSuccess');
export const removeClaimRequest = createAction('removeClaimSuccess');
export const getClaimDetailsRequest = createAction('getClaimDetailsRequest');
export const getClaimDetailsSuccess = createAction('getClaimDetailsSuccess');
export const createOrUpdateClaimRequest = createAction('createOrUpdateClaimRequest');
export const getClaimGeneralDetailsRequest = createAction('getClaimGeneralDetailsRequest');
export const getClaimGeneralDetailsSuccess = createAction('getClaimGeneralDetailsSuccess');
// external system
export const getExternalSystemListRequest = createAction('getExternalSystemListRequest');
export const getExternalSystemListSuccess = createAction('getExternalSystemListSuccess');
export const removeCloFromExternalSystemRequest = createAction('removeCloFromExternalSystemRequest');
export const removeCloFromExternalSystemSuccess = createAction('removeCloFromExternalSystemSuccess');
