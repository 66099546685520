import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
import { ActionBox } from '../../../components/action-box';
import { PopperComponent } from '../../../components/popper';
import {
  getInspectionTypeOptions,
  getTruckingMethodOptions,
  getInspectionStatusOptions,
  getInspectionSourceOptions,
} from '../../../components/filter/settings';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import RelatedTasksTableCell from '../../task-management/task/related-tasks';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DRIVER_TYPE_OPTIONS,
  priorityTypeOptions,
  workOrderStatusOptions,
  DUTY_STATUS_CODE_OPTIONS,
  fleetServiceIssueStatusOptions,
  driverOnboardingIntegrationTypeOptions,
  vendorPayrollAccessorialPayForUnitTypeOptions,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature fleet
import CountUp from '../components/count-up';
import { AddDocumentButton } from '../../fleet/equipment-service/components/documents';
//////////////////////////////////////////////////

const getEquipmentServiceEntityData = (equipmentService: Object) => {
  const truckGuid = G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, equipmentService);
  const trailerGuid = G.getPropFromObject(GC.GRC.FLEET_TRAILER_GUID, equipmentService);

  const isEntityTypeTruck = G.isNotNil(truckGuid);
  const equipmentServiceEntityGuid = R.or(truckGuid, trailerGuid);
  const equipmentServiceEntityType = G.ifElse(isEntityTypeTruck, GC.FIELD_TRUCK, GC.FIELD_TRAILER);
  const equipmentServiceEntityGuidType = G.ifElse(isEntityTypeTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID);

  return {
    isEntityTypeTruck,
    equipmentServiceEntityGuid,
    equipmentServiceEntityType,
    equipmentServiceEntityGuidType,
  };
};

const EquipmentServiceActions = (props: Object) => {
  const {
    entityType,
    documentList,
    equipmentService,
    documentTypeOptions,
    equipmentServiceGuid,
    handlePrintEquipmentService,
    handleRemoveEquipmentService,
    handleCreateOrUpdateEquipmentService,
  } = props;

  const {
    equipmentServiceEntityGuid,
    equipmentServiceEntityType,
    equipmentServiceEntityGuidType,
  } = getEquipmentServiceEntityData(equipmentService);

  const serviceData = {
    documentList,
    equipmentServiceGuid,
    equipmentServiceEntityGuid,
    equipmentServiceEntityType,
    equipmentServiceEntityGuidType,
  };

  const entityTypeToUse = R.or(entityType, equipmentServiceEntityType);

  const documentTypeDropdownName = G.ifElse(
    R.equals(entityTypeToUse, GC.FIELD_TRUCK),
    GC.TRUCK_DOCUMENT_TYPE,
    GC.TRAILER_DOCUMENT_TYPE,
  );

  const darkBlueColor = G.getTheme('colors.dark.blue');
  const documentTypeOptionsToUse = R.pathOr(documentTypeOptions, ['documentTypes', documentTypeDropdownName], props);

  return (
    <Flex mx='7px' width={170} justifyContent='space-around'>
      <Box cursor='pointer' onClick={() => handleRemoveEquipmentService(serviceData)}>
        {I.trash(darkBlueColor)}
      </Box>
      <Box
        cursor='pointer'
        onClick={() => handleCreateOrUpdateEquipmentService(serviceData, documentTypeOptionsToUse)}
      >
        {I.pencil(darkBlueColor)}
      </Box>
      <Box height={15} cursor='pointer' onClick={() => handlePrintEquipmentService(serviceData)}>
        {I.printer(darkBlueColor)}
      </Box>
      <AddDocumentButton
        {...props}
        entityType={entityTypeToUse}
        primaryObjectGuid={equipmentServiceGuid}
        documentTypeOptions={documentTypeOptionsToUse}
      />
    </Flex>
  );
};

const TableCell = ({ text }: Object) => {
  if (G.isNilOrEmpty(text)) return null;

  return (
    <TextComponent
      title={text}
      maxWidth='90%'
      display='block'
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};

const settings = [
  // driver
  {
    name: 'titles:driver-id',
    useExpandedContainer: true,
    value: GC.FIELD_FLEET_LOGIN_ID,
    cellPermissions: PC.FLEET_DRIVER_WRITE,
    fieldType: () => G.ifElse(
      G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_FLEET_PROFILE),
      GC.PAGE_FLEET_DRIVER_PROFILE_V2,
      GC.PAGE_FLEET_DRIVER_PROFILE,
    ),
  },
  {
    width: 200,
    searchable: true,
    name: ['titles:pay-to', 'titles:name'],
    value: GC.GRC.PAY_TO_LOCATION_LOCATION_NAME,
  },
  {
    searchable: true,
    fieldType: 'enumLocale',
    value: GC.GRC.PAY_TO_TYPE,
    name: ['titles:pay-to', 'titles:type'],
  },
  {
    name: 'titles:driver-number',
    value: GC.FIELD_FLEET_DRIVER_NUMBER,
  },
  {
    name: 'titles:owning-branch',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  },
  {
    name: 'titles:driver-type',
    value: GC.FIELD_DRIVER_TYPE,
    filterType: 'selectMultiple',
    options: R.values(DRIVER_TYPE_OPTIONS),
    customComponent: ({ data }: Object) =>
      R.path([G.getPropFromObject(GC.FIELD_DRIVER_TYPE, data), GC.FIELD_LABEL], DRIVER_TYPE_OPTIONS),
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
  },
  {
    value: 'employeeNumber',
    name: 'titles:employee-number',
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:employed',
    value: GC.FIELD_DRIVER_EMPLOYED,
  },
  {
    name: 'titles:nick-name',
    value: GC.FIELD_NICK_NAME,
  },
  {
    name: 'titles:first-name',
    value: GC.FIELD_FIRST_NAME,
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:show-vendor-payroll',
    value: GC.FIELD_SHOW_VENDOR_PAYROLL,
  },
  {
    name: 'titles:last-name',
    value: GC.FIELD_LAST_NAME,
  },
  {
    name: 'titles:full-name',
    value: GC.FIELD_FULL_NAME,
  },
  {
    name: 'titles:email',
    value: GC.FIELD_EMAIL,
  },
  {
    name: 'titles:primary-phone',
    value: GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER,
  },
  {
    value: 'secondaryPhoneNumber',
    name: 'titles:secondary-phone',
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    value: 'autoAcceptRate',
    name: 'titles:auto-accept-rate',
  },
  {
    value: 'gender',
    name: 'titles:gender',
  },
  {
    name: 'titles:dispatching-group',
    value: GC.GRC.DISPATCHING_GROUP_NAME,
  },
  {
    name: 'titles:assigned-division',
    value: 'assignedDivision.displayedValue',
  },
  {
    name: 'titles:unemployed-reason',
    value: 'unemploymentReason.displayedValue',
  },
  {
    name: 'titles:domicile-terminal',
    value: 'domicileTerminal.displayedValue',
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:team-driver',
    value: GC.FIELD_TEAM_DRIVER,
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:expedited-division',
    value: GC.FIELD_EXPEDITED_DIVISION,
  },
  {
    filterType: 'date',
    name: 'titles:birth-date',
    value: GC.FIELD_FLEET_BIRTH_DATE,
  },
  {
    filterType: 'date',
    name: 'titles:current-hire-date',
    value: GC.FIELD_FLEET_CURRENT_HIRE_DATE,
  },
  {
    filterType: 'date',
    name: 'titles:previous-hire-date',
    value: GC.FIELD_FLEET_PREVIOUS_HIRE_DATE,
  },
  {
    filterType: 'date',
    name: 'titles:original-hire-date',
    value: GC.FIELD_FLEET_ORIGINAL_HIRE_DATE,
  },
  {
    filterType: 'date',
    name: 'titles:termination-date',
    value: GC.FIELD_FLEET_TERMINATION_DATE,
  },
  {
    name: 'titles:out-of-duty-reason',
    value: 'driverHours.outOfDutyReason.displayedValue',
  },
  {
    name: 'titles:duty-status-code',
    value: 'driverHours.dutyStatusCode',
    customComponent: ({ data }: Object) => {
      const dutyStatusCode = G.getPropFromObject('driverHours.dutyStatusCode', data);

      if (G.isNilOrEmpty(dutyStatusCode)) return null;

      const displayedValue = R.compose(
        R.pathOr(dutyStatusCode, [GC.FIELD_LABEL]),
        R.find(R.propEq(dutyStatusCode, GC.FIELD_VALUE)),
      )(DUTY_STATUS_CODE_OPTIONS);

      return (
        <TextComponent
          maxWidth='90%'
          display='block'
          withEllipsis={true}
          title={displayedValue}
        >
          {displayedValue}
        </TextComponent>
      );
    },
  },
  {
    filterType: 'number',
    name: 'titles:time-in-current-status',
    value: 'driverHours.timeInCurrentStatus',
  },
  {
    filterType: 'number',
    name: 'titles:time-until-break',
    value: 'driverHours.timeUntilBreak',
  },
  {
    filterType: 'number',
    name: 'titles:shift-drive-remaining',
    value: 'driverHours.shiftDriveRemaining',
  },
  {
    filterType: 'number',
    name: 'titles:shift-remaining',
    value: 'driverHours.shiftRemaining',
  },
  {
    filterType: 'number',
    name: 'titles:cycle-remaining',
    value: 'driverHours.cycleRemaining',
  },
  {
    filterType: 'number',
    name: 'titles:cycle-tomorrow',
    value: 'driverHours.cycleTomorrow',
  },
  {
    name: 'titles:license-number',
    value: 'licenseDetails.licenseNumber',
  },
  {
    name: 'titles:license-country',
    value: 'licenseDetails.licenseCountry',
  },
  {
    name: 'titles:license-state',
    value: 'licenseDetails.licenseState',
  },
  {
    name: 'titles:license-class',
    value: 'licenseDetails.licenseClass',
  },
  {
    name: 'titles:restrictions',
    value: 'licenseDetails.restrictions',
  },
  // NOTE: commented due to it is not working on current endpoint
  // {
  //   collection: true,
  //   name: 'titles:endorsements,
  //   value: 'licenseDetails.endorsements',
  // },
  {
    filterType: 'date',
    name: 'titles:issue-date',
    value: 'licenseDetails.issueDate',
  },
  {
    name: 'titles:model',
    value: GC.FIELD_MODEL,
  },
  {
    filterType: 'date',
    name: 'titles:expiration-date',
    value: 'licenseDetails.expirationDate',
  },
  {
    name: 'titles:citizen',
    value: 'citizenshipDetails.citizen.displayedValue',
  },
  {
    name: 'titles:passport-number',
    value: 'citizenshipDetails.passportNumber',
  },
  {
    name: 'titles:passport-country',
    value: 'citizenshipDetails.passportCountry',
  },
  {
    filterType: 'date',
    name: 'titles:passport-expiration',
    value: 'citizenshipDetails.passportExpiration',
  },
  {
    name: 'titles:visa-number',
    value: 'citizenshipDetails.visaNumber',
  },
  {
    name: 'titles:visa-country',
    value: 'citizenshipDetails.visaCountry',
  },
  {
    filterType: 'date',
    name: 'titles:visa-expiration',
    value: 'citizenshipDetails.visaExpiration',
  },
  {
    name: 'titles:green-card-number',
    value: 'citizenshipDetails.greenCardNumber',
  },
  {
    filterType: 'date',
    name: 'titles:green-card-expiration',
    value: 'citizenshipDetails.greenCardExpiration',
  },
  {
    value: 'driverLatestLocation.country',
    name: 'titles:last-known-location-country',
  },
  {
    value: 'driverLatestLocation.state',
    name: 'titles:last-known-location-state',
  },
  {
    value: 'driverLatestLocation.city',
    name: 'titles:last-known-location-city',
  },
  {
    value: 'driverLatestLocation.zip',
    name: 'titles:last-known-location-zip',
  },
  {
    value: 'driverAssignment.truck.unitId',
    name: ['titles:truck', 'titles:unit-id'],
  },
  {
    collection: true,
    value: 'driverAssignment.trailers.unitId',
    name: ['titles:trailers', 'titles:unit-id'],
  },
  {
    width: 400,
    name: 'titles:comments',
    value: GC.FIELD_COMMENTS,
  },
  {
    useExpandedContainer: true,
    guidPropName: GC.GRC.VENDOR_GUID,
    cellPermissions: PC.FLEET_VENDOR_WRITE,
    value: GC.GRC.FLEET_VENDOR_COMPANY_NAME,
    name: ['titles:vendor', 'titles:company-name'],
    fieldType: () => G.ifElse(
      G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_FLEET_PROFILE),
      GC.PAGE_FLEET_VENDOR_PROFILE_V2,
      GC.PAGE_FLEET_VENDOR_PROFILE,
    ),
  },
  {
    value: GC.GRC.VENDOR_OWNER_NAME,
    name: ['titles:vendor', 'titles:owner-name'],
  },
  {
    value: GC.GRC.FLEET_VENDOR_DBA,
    name: ['titles:vendor', 'titles:dba'],
  },
  {
    value: GC.GRC.FLEET_VENDOR_US_DOT_NUMBER,
    name: ['titles:vendor', 'titles:usdot-number'],
  },
  {
    value: GC.GRC.FLEET_VENDOR_MC_NUMBER,
    name: ['titles:vendor', 'titles:mc-number'],
  },
  {
    value: GC.GRC.FLEET_VENDOR_ACCOUNT_NUMBER,
    name: ['titles:vendor', 'titles:account-number'],
  },
  {
    value: GC.GRC.FLEET_VENDOR_DUNS_NUMBER,
    name: ['titles:vendor', 'titles:duns-number'],
  },
  {
    value: GC.GRC.CERTIFICATION_TYPE,
    name: ['titles:certification', 'titles:type'],
  },
  {
    value: GC.GRC.CERTIFICATION_NAME,
    name: ['titles:certification', 'titles:name'],
  },
  {
    filterType: 'date',
    value: GC.GRC.CERTIFICATION_EXPIRATION_DATE,
    name: ['titles:certification', 'titles:expiration-date'],
  },
  {
    filterType: 'date',
    value: GC.GRC.CERTIFICATION_DATE,
    name: ['titles:certification', 'titles:date'],
  },
  {
    value: GC.GRC.CERTIFICATION_NOTES,
    name: ['titles:certification', 'titles:notes'],
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    value: GC.GRC.CERTIFICATION_OOS_ITEM,
    name: ['titles:certification', 'titles:oos-item'],
  },
  {
    collection: true,
    value: GC.GRC.DOCUMENTS_TYPE,
    name: ['titles:documents', 'titles:type'],
  },
  {
    collection: true,
    fieldType: 'enum',
    value: GC.GRC.DOCUMENTS_STATUS,
    name: ['titles:documents', 'titles:status'],
  },
  {
    collection: true,
    value: GC.GRC.DOCUMENTS_DESCRIPTION,
    name: ['titles:documents', 'titles:description'],
    customComponent: ({ data, fieldData }: Object) =>
      <TableCell text={G.createStringFromArray(R.pathOr(fieldData, [GC.GRC.DOCUMENTS_DESCRIPTION], data), ', ')} />,
  },
  {
    collection: true,
    filterType: 'date',
    value: GC.GRC.DOCUMENTS_SIGNET_DATE,
    name: ['titles:documents', 'titles:signed-date'],
    customComponent: ({ data, fieldData }: Object) =>
      <TableCell text={G.createStringFromArray(R.pathOr(fieldData, [GC.GRC.DOCUMENTS_SIGNET_DATE], data), ', ')} />,
  },
  {
    collection: true,
    filterType: 'date',
    value: GC.GRC.DOCUMENTS_EXPIRATION_DATE,
    name: ['titles:documents', 'titles:expiration-date'],
    customComponent: ({ data, fieldData }: Object) =>
      <TableCell text={G.createStringFromArray(R.pathOr(fieldData, [GC.GRC.DOCUMENTS_EXPIRATION_DATE], data), ', ')} />,
  },
  // trailer
  {
    name: 'titles:unit-id',
    value: GC.FIELD_UNIT_ID,
    useExpandedContainer: true,
    cellPermissions: {
      [GC.FLEET_TRUCK_REPORT]: PC.FLEET_TRUCK_WRITE,
      [GC.FLEET_TRAILER_REPORT]: PC.FLEET_TRAILER_WRITE,
    },
    fieldType: (reportType: string) => {
      const map = {
        v1: {
          [GC.FLEET_TRUCK_REPORT]: GC.PAGE_FLEET_TRUCK_PROFILE,
          [GC.FLEET_TRAILER_REPORT]: GC.PAGE_FLEET_TRAILER_PROFILE,
        },
        v2: {
          [GC.FLEET_TRUCK_REPORT]: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
          [GC.FLEET_TRAILER_REPORT]: GC.PAGE_FLEET_TRAILER_PROFILE_V2,
        },
      };

      const version = G.ifElse(
        G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_FLEET_PROFILE),
        'v2',
        'v2',
      );

      return R.path([version, reportType], map);
    },
  },
  {
    width: 150,
    searchable: false,
    disableFilter: true,
    fieldType: 'boolean',
    name: 'titles:shared',
    filterType: 'boolean',
    value: GC.FIELD_SHARED,
  },
  {
    collection: true,
    name: 'titles:shared-to-branches',
    value: GC.GRC.SHARED_TO_BRANCHES_BRANCH_NAME,
  },
  {
    filterType: 'date',
    value: 'inServiceDate',
    name: 'titles:in-service-date',
  },
  {
    width: 150,
    value: 'inService',
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:in-service',
  },
  {
    name: 'titles:type',
    value: 'trailerType.displayedValue',
  },
  {
    name: 'titles:model',
    value: 'trailerModel',
  },
  {
    value: 'modelSeries',
    name: 'titles:series',
  },
  {
    name: 'titles:year',
    filterType: 'number',
    value: GC.FIELD_YEAR,
  },
  {
    value: 'outputYear',
    name: 'titles:year',
    filterType: 'number',
  },
  {
    value: 'registrationState',
    name: 'titles:registration-state',
  },
  {
    filterType: 'date',
    value: 'outServiceDate',
    name: 'titles:out-service-date',
  },
  {
    filterType: 'date',
    value: 'registrationExpiredDate',
    name: 'titles:registration-expired-date',
  },
  {
    value: 'trailerLatestLocation.country',
    name: 'titles:last-known-location-country',
  },
  {
    value: 'trailerLatestLocation.state',
    name: 'titles:last-known-location-state',
  },
  {
    value: 'trailerLatestLocation.city',
    name: 'titles:last-known-location-city',
  },
  {
    value: 'trailerLatestLocation.zip',
    name: 'titles:last-known-location-zip',
  },
  {
    name: 'titles:created-by',
    value: GC.FIELD_CREATED_BY,
  },
  {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:created-date',
    value: GC.FIELD_CREATED_DATE,
  },
  {
    name: 'titles:updated-by',
    value: GC.FIELD_LAST_MODIFIED_BY,
  },
  {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:updated-date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
  },
  {
    value: GC.GRC.TRAILER_OWNERSHIP_CONTRACT_NAME,
    name: ['titles:ownership', 'titles:contact-name'],
  },
  {
    name: 'titles:driver-id',
    useExpandedContainer: true,
    value: GC.GRC.DRIVER_LOGIN_ID,
    guidPropName: GC.GRC.DRIVER_GUID,
    cellPermissions: PC.FLEET_DRIVER_WRITE,
    fieldType: () => G.ifElse(
      G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_FLEET_PROFILE),
      GC.PAGE_FLEET_DRIVER_PROFILE_V2,
      GC.PAGE_FLEET_DRIVER_PROFILE,
    ),
  },
  {
    name: 'titles:driver-full-name',
    value: GC.GRC.DRIVER_FULL_NAME,
  },
  {
    value: GC.GRC.DRIVER_FIRST_NAME,
    name: 'titles:driver-first-name',
  },
  {
    value: GC.GRC.DRIVER_LAST_NAME,
    name: 'titles:driver-last-name',
  },
  {
    name: 'titles:geo-fencing-location',
    value: `trailerGeofencingLocation.${GC.SYSTEM_OBJECT_GEOFENCING_LOCATION}.${GC.FIELD_NAME}`,
  },
  {
    searchable: false,
    disableFilter: true,
    name: ['titles:geo-fencing-location', 'titles:age'],
    value: `trailerGeofencingLocation.${GC.FIELD_CREATED_DATE}`,
    customComponent: ({ fieldData }: Object) => {
      if (R.isNil(fieldData)) return null;

      const startTime = G.getNewDate(fieldData);

      return <CountUp startTime={startTime} />;
    },
  },
  // truck
  {
    width: 250,
    name: 'titles:type',
    value: 'truckType.displayedValue',
  },
  {
    name: 'titles:working-division',
    value: `${GC.SYSTEM_OBJECT_ASSIGNED_TO_DIVISION}.${GC.BRANCH_NAME}`,
  },
  {
    width: 250,
    value: 'legacyUnitId',
    name: 'titles:legacy-unit-id',
  },
  {
    width: 250,
    value: GC.FIELD_SERIAL_NUMBER,
    name: 'titles:serial-number-vin',
  },
  {
    name: 'titles:manufacturer',
    value: GC.FIELD_MANUFACTURER,
  },
  {
    value: 'series',
    name: 'titles:series',
  },
  {
    width: 200,
    filterType: 'boolean',
    name: 'titles:in-service',
    value: GC.FIELD_FLEET_IN_SERVICE_DATE,
  },
  {
    value: 'outOfServiceReason.displayedValue',
    name: 'titles:out-of-service-status-reason',
  },
  {
    width: 150,
    value: 'available',
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:available',
  },
  {
    name: 'titles:unavailable-status-reason',
    value: 'unavailabilityReason.displayedValue',
  },
  {
    filterType: 'number',
    name: 'titles:odometer',
    value: GC.FIELD_ODOMETER,
  },
  {
    filterType: 'date',
    value: 'outOfServiceDate',
    name: 'titles:out-service-date',
  },
  {
    value: 'licensePlate',
    name: 'titles:license-plate',
  },
  {
    filterType: 'date',
    value: 'registrationExpirationDate',
    name: 'titles:registration-expiration-date',
  },
  {
    value: 'titleState',
    name: 'titles:title-state',
  },
  {
    value: 'titleNumber',
    name: 'titles:title-number',
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    value: 'iftaRequired',
    name: 'titles:ifta-required',
  },
  {
    value: 'iftaDate',
    filterType: 'date',
    name: 'titles:ifta-date',
  },
  {
    value: GC.FIELD_IFTA_DECAL,
    name: 'titles:ifta-decal-number',
  },
  {
    value: 'truckLatestLocation.country',
    name: 'titles:last-known-location-country',
  },
  {
    value: 'truckLatestLocation.state',
    name: 'titles:last-known-location-state',
  },
  {
    value: 'truckLatestLocation.city',
    name: 'titles:last-known-location-city',
  },
  {
    value: 'truckLatestLocation.zip',
    name: 'titles:last-known-location-zip',
  },
  // vendor
  {
    value: GC.FIELD_NAME,
    useExpandedContainer: true,
    name: 'titles:company-name',
    cellPermissions: PC.FLEET_VENDOR_WRITE,
    fieldType: () => G.ifElse(
      G.getAmousConfigByNameFromWindow(GC.UI_USE_NEW_FLEET_PROFILE),
      GC.PAGE_FLEET_VENDOR_PROFILE_V2,
      GC.PAGE_FLEET_VENDOR_PROFILE,
    ),
  },
  {
    name: 'titles:dba',
    value: GC.FIELD_CARRIER_DBA,
  },
  {
    name: 'titles:owner-name',
    value: GC.FIELD_OWNER_NAME,
  },
  {
    name: 'titles:usdot-number',
    value: GC.FIELD_CARRIER_USDOT_NUMBER,
  },
  {
    width: 250,
    name: 'titles:mc-number',
    value: GC.FIELD_CARRIER_MC_NUMBER,
  },
  {
    name: 'titles:account-number',
    value: GC.FIELD_ACCOUNT_NUMBER,
  },
  {
    name: 'titles:duns-number',
    value: GC.FIELD_CARRIER_DUNS_NUMBER,
  },
  {
    name: 'titles:gst-number',
    value: GC.FIELD_VENDOR_GST_NUMBER,
  },
  {
    name: 'titles:federal-ein',
    value: GC.FIELD_FEDERAL_EIN,
  },
  {
    value: GC.GRC.ASSIGNED_DISPATCHER_FIRST_NAME,
    name: ['titles:dispatcher', 'titles:first-name'],
  },
  {
    value: GC.GRC.ASSIGNED_DISPATCHER_LAST_NAME,
    name: ['titles:dispatcher', 'titles:last-name'],
  },
  {
    value: GC.GRC.ASSIGNED_DISPATCHER_LOGIN_ID,
    name: ['titles:dispatcher', 'titles:login-id'],
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INTEGRATION_INFO_INTEGRATION_TYPE,
    name: ['titles:integration-info', 'titles:integration-type'],
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INTEGRATION_INFO_INTEGRATION_ID,
    name: ['titles:integration-info', 'titles:integration-id'],
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME,
    name: ['titles:integration-info', 'titles:division'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INTEGRATION_INFO_LAST_MODIFIED_DATE,
    name: ['titles:integration-info', 'titles:update-on'],
  },
  {
    type: 'string',
    name: 'titles:phone-number',
    value: GC.FIELD_FLEET_PHONE_NUMBER,
  },
  // shared component
  {
    name: 'titles:component-id',
    value: GC.FIELD_COMPONENT_ID,
  },
  {
    name: 'titles:type',
    value: `${GC.FIELD_COMPONENT_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
    customComponent: ({ data, fieldData }: Object) =>
      <TableCell text={R.pathOr(fieldData, [GC.FIELD_COMPONENT_TYPE, GC.FIELD_DISPLAYED_VALUE], data)} />,
  },
  {
    name: 'titles:manufacturer',
    value: `${GC.FIELD_COMPONENT_MANUFACTURER}.${GC.FIELD_DISPLAYED_VALUE}`,
    customComponent: ({ data, fieldData }: Object) =>
      <TableCell text={R.pathOr(fieldData, [GC.FIELD_COMPONENT_MANUFACTURER, GC.FIELD_DISPLAYED_VALUE], data)} />,
  },
  {
    name: 'titles:specification',
    value: GC.FIELD_SPECIFICATION,
  },
  {
    filterType: 'date',
    value: GC.FIELD_WARRANTY_EXPIRATION,
    name: 'titles:warranty-expiration-date',
  },
  {
    width: 150,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:installed',
    value: GC.FIELD_INSTALLED,
  },
  {
    width: 300,
    value: GC.GRC.FLEET_TRUCK_UNIT_ID,
    name: ['titles:truck', 'titles:unit-id'],
    customComponent: ({ data, callbackData }: Object) => {
      const { reportType, withoutExpandedContainer, setExpandedContainerOptions } = callbackData;

      const truckUnitId = G.getPropFromObject(GC.GRC.FLEET_TRUCK_UNIT_ID, data);

      if (G.isNilOrEmpty(truckUnitId)) return null;

      if (R.or(G.isTrue(withoutExpandedContainer), G.hasNotAmousCurrentUserPermissions([PC.FLEET_TRUCK_WRITE]))) {
        return (
          <TextComponent maxWidth='90%' display='block' title={truckUnitId} withEllipsis={true}>
            {truckUnitId}
          </TextComponent>
        );
      }

      const truckGuid = G.getPropFromObject(
        G.ifElse(R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT), GC.GRC.FLEET_TRUCK_GUID, GC.FIELD_TRUCK_GUID),
        data,
      );

      return (
        <ActionBox
          text={truckUnitId}
          action={() => setExpandedContainerOptions({
            opened: true,
            visitPageGuid: truckGuid,
            componentType: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
          })}
        />
      );
    },
  },
  {
    width: 300,
    value: GC.GRC.FLEET_TRAILER_UNIT_ID,
    name: ['titles:trailer', 'titles:unit-id'],
    customComponent: ({ data, callbackData }: Object) => {
      const { reportType, withoutExpandedContainer, setExpandedContainerOptions } = callbackData;

      const trailerUnitId = G.getPropFromObject(GC.GRC.FLEET_TRAILER_UNIT_ID, data);

      if (G.isNilOrEmpty(trailerUnitId)) return null;

      if (R.or(
        G.isTrue(withoutExpandedContainer),
        G.hasNotAmousCurrentUserPermissions([PC.FLEET_TRAILER_WRITE]),
      )) {
        return (
          <TextComponent maxWidth='90%' display='block' title={trailerUnitId} withEllipsis={true}>
            {trailerUnitId}
          </TextComponent>
        );
      }

      const trailerGuid = G.getPropFromObject(G.ifElse(
        R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT),
        GC.GRC.FLEET_TRAILER_GUID,
        GC.FIELD_TRAILER_GUID,
      ), data);

      return (
        <ActionBox
          text={trailerUnitId}
          action={() => setExpandedContainerOptions({
            opened: true,
            visitPageGuid: trailerGuid,
            componentType: GC.PAGE_FLEET_TRAILER_PROFILE_V2,
          })}
        />
      );
    },
  },
  // equipment service
  {
    name: 'titles:service-id',
    value: GC.FIELD_SERVICE_ID,
  },
  {
    filterType: 'date',
    value: GC.FIELD_SERVICE_DATE,
    name: 'titles:planned-service-date',
  },
  {
    name: 'titles:approved-by',
    value: GC.FIELD_APPROVED_BY,
  },
  {
    name: 'titles:service-type',
    value: `${GC.FIELD_SERVICE_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    name: ['titles:service-vendor', 'titles:company-name'],
    value: `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_NAME}`,
  },
  {
    name: ['titles:service-vendor', 'titles:dba'],
    value: `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_DBA}`,
  },
  {
    name: ['titles:service-vendor', 'titles:duns-number'],
    value: `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_DUNS_NUMBER}`,
  },
  {
    name: ['titles:service-vendor', 'titles:mc-number'],
    value: `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_MC_NUMBER}`,
  },
  {
    filterType: 'number',
    name: 'titles:interval',
    value: GC.FIELD_INTERVAL,
  },
  {
    fieldType: 'uomLocale',
    name: 'titles:interval-uom',
    value: GC.FIELD_INTERVAL_UOM,
    filterType: 'selectMultiple',
    options: [
      { value: GC.UOM_DAYS, label: G.getUomLocale(GC.UOM_DAYS) },
      { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
      { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
      { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
    ],
  },
  {
    name: 'titles:comments',
    value: GC.FIELD_COMMENTS,
  },
  {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:performed-date',
    value: GC.FIELD_PERFORMED_DATE,
  },
  {
    filterType: 'number',
    name: 'titles:performed-value',
    value: GC.FIELD_PERFORMED_VALUE,
  },
  {
    fieldType: 'uomLocale',
    filterType: 'selectMultiple',
    name: 'titles:performed-type',
    value: GC.FIELD_PERFORMED_TYPE,
    options: [
      { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
      { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
      { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
    ],
  },
  {
    collection: true,
    name: 'titles:equipment-components',
    value: `${GC.SYSTEM_LIST_COMPONENTS}.${GC.FIELD_COMPONENT_ID}`,
  },
  {
    width: 200,
    name: 'actions',
    notResizable: true,
    value: 'fleetEquipmentServiceActions',
    customComponent: ({ data, callbackData }: Object) => {
      if (G.isTrue(G.getPropFromObject('disabled', data))) return null;

      const truckGuid = G.getPropFromObject(GC.GRC.FLEET_TRUCK_GUID, data);

      return (
        <AuthWrapper has={G.ifElse(G.isNotNil(truckGuid), [PC.FLEET_TRUCK_WRITE], [PC.FLEET_TRAILER_WRITE])}>
          <EquipmentServiceActions
            {...callbackData}
            equipmentService={data}
            documentList={R.pathOr([], ['details'], data)}
            equipmentServiceGuid={G.getGuidFromObject(data)}
          />
        </AuthWrapper>
      );
    },
  },
  {
    filterType: 'number',
    value: GC.GRC.INVOICE_INVOICE_TOTAL,
    name: ['titles:invoice', 'titles:total'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INVOICE_INVOICE_DATE,
    name: ['titles:invoice', 'titles:invoice-date'],
  },
  {
    filterType: 'number',
    value: GC.GRC.INVOICE_INVOICE_NET_DAYS,
    name: ['titles:invoice', 'titles:net-days'],
  },
  {
    filterType: 'selectMultiple',
    value: GC.GRC.INVOICE_INVOICE_CURRENCY,
    options: R.drop(1, GC.CURRENCY_OPTIONS),
    name: ['titles:invoice', 'titles:currency'],
  },
  {
    value: GC.GRC.INVOICE_INVOICE_PO_NUMBER,
    name: ['titles:invoice', 'titles:po-number'],
  },
  {
    value: GC.GRC.INVOICE_INVOICE_NUMBER,
    name: ['titles:invoice', 'titles:invoice-number'],
  },
  {
    value: GC.GRC.INVOICE_INVOICE_CHECK_NUMBER,
    name: ['titles:invoice', 'titles:check-number'],
  },
  {
    width: 400,
    name: 'titles:related-tasks',
    value: GC.FIELD_RELATED_TASKS,
    customComponent: (row: Object) => {
      const relatedTasks = R.path(['data', GC.FIELD_RELATED_TASKS], row);

      if (R.and(
        G.isNotNilAndNotEmpty(relatedTasks),
        G.hasAmousCurrentUserPermissions([PC.TASK_READ, PC.TASK_WRITE]),
      )) {
        return <RelatedTasksTableCell relatedTasks={relatedTasks} />;
      }

      return null;
    },
  },
  {
    collection: true,
    name: 'titles:equipment-components',
    value: `${GC.SYSTEM_LIST_COMPONENTS}.${GC.FIELD_COMPONENT_ID}`,
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INVOICE_INVOICE_CHECK_DATE,
    name: ['titles:invoice', 'titles:check-date'],
  },
  {
    value: GC.GRC.INVOICE_INVOICE_CHECK_AMOUNT,
    name: ['titles:invoice', 'titles:check-amount'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INVOICE_INVOICE_DEPOSIT_DATE,
    name: ['titles:invoice', 'titles:deposit-date'],
  },
  {
    width: 400,
    value: GC.GRC.INVOICE_INVOICE_COMMENTS,
    name: ['titles:invoice', 'titles:comments'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INVOICE_INVOICE_PAYMENT_DUE_DATE,
    name: ['titles:invoice', 'titles:payment-due-date'],
  },
  {
    value: GC.GRC.INVOICE_INVOICE_STATUS,
    name: ['titles:invoice', 'titles:status'],
  },
  {
    value: GC.GRC.INVOICE_INVOICE_GL_CODE,
    name: ['titles:invoice', 'titles:gl-code'],
  },
  {
    width: 300,
    name: ['titles:invoice', 'titles:ach-payment-confirmation'],
    value: GC.GRC.INVOICE_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER,
  },
  // driver onboarding
  {
    filterType: 'selectMultiple',
    name: 'titles:integration-type',
    value: GC.FIELD_INTEGRATION_TYPE,
    options: driverOnboardingIntegrationTypeOptions,
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, data), GC.FIELD_VALUE)),
    )(driverOnboardingIntegrationTypeOptions),
  },
  {
    width: 180,
    name: 'titles:status',
    value: GC.FIELD_STATUS,
    filterType: 'selectMultiple',
    selectOptions: fleetServiceIssueStatusOptions,
    selectPermissions: ({ truckGuid }: Object) => G.ifElse(
      G.isNotNilAndNotEmpty(truckGuid), [PC.FLEET_TRUCK_WRITE], [PC.FLEET_TRAILER_WRITE],
    ),
    fieldType: {
      [GC.FLEET_DRIVER_ONBOARDING_REPORT]: 'enum',
      [GC.FLEET_SERVICE_ISSUE_REPORT]: 'selectDropdown',
    },
    selectAction: (status: string, { guid, truckGuid }: Object, { changeFleetServiceIssueStatusRequest }: Object) => {
      changeFleetServiceIssueStatusRequest({
        values: { guid, status },
        entityType: G.ifElse(G.isNotNilAndNotEmpty(truckGuid), GC.FIELD_TRUCK, GC.FIELD_TRAILER),
      });
    },
    options: (reportType: string) => {
      if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) return fleetServiceIssueStatusOptions;

      if (R.equals(reportType, GC.FLEET_DRIVER_ONBOARDING_REPORT)) {
        return [
          { value: GC.STATUS_SUCCESS, label: G.getEnumLocale(GC.STATUS_SUCCESS) },
          { value: GC.STATUS_PARTIALLY_SUCCESS, label: G.getEnumLocale(GC.STATUS_PARTIALLY_SUCCESS) },
          { value: GC.STATUS_FAILED, label: G.getEnumLocale(GC.STATUS_FAILED) },
        ];
      }
    },
  },
  {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:received-date',
    value: GC.FIELD_EDI_RECEIVED_DATE,
  },
  // payroll accessorial
  {
    fieldType: 'string',
    name: 'titles:accessorial2',
    filterType: 'selectMultiple',
    value: GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
    options: () => R.or(G.getItemFromWindow('accessorialConfigOptions'), []),
    handleDisableOptions: (option: Object, value: Array) => R.gte(R.length(value), 10),
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      return R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_DISPLAYED_VALUE],
        callbackData,
      );
    },
  },
  {
    width: 150,
    searchable: false,
    notResizable: true,
    fieldType: 'toggle',
    disableFilter: true,
    filterType: 'boolean',
    name: 'titles:fuel-related',
    value: GC.FIELD_FUEL_RELATED,
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const fuelRelated = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        callbackData,
      );

      return G.ifElse(fuelRelated, I.uiTrue, I.uiFalse)();
    },
  },
  {
    width: 150,
    searchable: false,
    notResizable: true,
    fieldType: 'toggle',
    disableFilter: true,
    filterType: 'boolean',
    name: 'titles:non-taxable',
    value: GC.FIELD_NON_TAXABLE,
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const fuelRelated = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        callbackData,
      );

      return G.ifElse(fuelRelated, I.uiTrue, I.uiFalse)();
    },
  },
  {
    width: 150,
    notResizable: true,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:deduction',
    value: GC.FIELD_DEDUCTION,
  },
  {
    width: 150,
    notResizable: true,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:active',
    value: GC.FIELD_ACTIVE,
  },
  {
    width: 150,
    filterType: 'string',
    name: 'titles:currency',
    value: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
  },
  {
    fieldType: 'number',
    name: 'titles:rate',
    filterType: 'number',
    value: GC.FIELD_RATE,
  },
  {
    width: 150,
    searchable: false,
    disableFilter: true,
    filterType: 'string',
    name: 'titles:rate-type',
    value: GC.FIELD_CHARGE_RATE_TYPE,
  },
  {
    width: 150,
    notResizable: true,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:limited',
    value: GC.FIELD_LIMITED,
  },
  {
    fieldType: 'number',
    filterType: 'number',
    name: 'titles:total-amount',
    value: GC.FIELD_TOTAL_AMOUNT,
  },
  {
    fieldType: 'number',
    filterType: 'number',
    name: 'titles:historical-paid-amount',
    value: GC.FIELD_HISTORICAL_PAID_AMOUNT,
  },
  {
    fieldType: 'number',
    filterType: 'number',
    name: 'titles:paid-amount',
    value: GC.FIELD_PAID_AMOUNT,
  },
  {
    width: 150,
    notResizable: true,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:fully-paid',
    value: GC.FIELD_FULLY_PAID,
  },
  {
    name: 'titles:comments',
    value: GC.FIELD_COMMENTS,
  },
  {
    fieldType: 'enum',
    name: 'titles:pay-for-unit',
    value: GC.FIELD_PAY_FOR_UNIT,
    options: vendorPayrollAccessorialPayForUnitTypeOptions,
  },
  // service issue
  {
    name: 'titles:issue-id',
    value: GC.FIELD_ISSUE_ID,
  },
  {
    name: 'titles:issue-type',
    value: `${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    name: 'titles:summary',
    value: GC.FIELD_SUMMARY,
  },
  {
    width: 140,
    fieldType: 'enum',
    name: 'titles:priority',
    value: GC.FIELD_PRIORITY,
    filterType: 'selectMultiple',
    options: priorityTypeOptions,
  },
  {
    name: ['titles:assignee', 'titles:first-name'],
    value: `${GC.FIELD_ASSIGNEE}.${GC.FIELD_FIRST_NAME}`,
  },
  {
    name: ['titles:assignee', 'titles:last-name'],
    value: `${GC.FIELD_ASSIGNEE}.${GC.FIELD_LAST_NAME}`,
  },
  {
    name: ['titles:assignee', 'titles:login-id'],
    value: `${GC.FIELD_ASSIGNEE}.${GC.FIELD_USER_LOGIN_ID}`,
  },
  {
    name: 'titles:description',
    value: GC.FIELD_DESCRIPTION,
  },
  {
    value: GC.GRC.WORK_ORDER_NAME,
    name: 'titles:work-order-number',
  },
  {
    fieldType: 'enum',
    filterType: 'selectMultiple',
    options: workOrderStatusOptions,
    value: GC.GRC.WORK_ORDER_STATUS,
    name: ['titles:work-order', 'titles:status'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.WORK_ORDER_DUE_DATE,
    name: ['titles:work-order', 'titles:due-date'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.WORK_ORDER_EXPECTED_COMPLETED_DATE,
    name: ['titles:work-order', 'titles:expected-completed-date'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.WORK_ORDER_ACTUAL_COMPLETED_DATE,
    name: ['titles:work-order', 'titles:actual-completed-date'],
  },
  {
    value: GC.GRC.WORK_ORDER_SERVICE_VENDOR_NAME,
    name: ['titles:work-order', 'titles:service-vendor'],
  },
  {
    name: ['titles:location', 'titles:address'],
    value: `${GC.FIELD_LOCATION}.${GC.FIELD_ADDRESS}`,
  },
  {
    value: GC.GRC.LOCATION_CITY,
    name: ['titles:location', 'titles:city'],
  },
  {
    value: GC.GRC.LOCATION_STATE,
    name: ['titles:location', 'titles:state'],
  },
  {
    value: GC.GRC.LOCATION_ZIP,
    name: ['titles:location', 'titles:zip'],
  },
  {
    value: GC.GRC.LOCATION_COUNTRY,
    name: ['titles:location', 'titles:country'],
  },
  {
    value: GC.GRC.INSPECTION_NAME,
    name: ['titles:inspection', 'titles:name'],
  },
  {
    fieldType: 'enum',
    value: GC.GRC.INSPECTION_STATUS,
    options: getInspectionStatusOptions(),
    name: ['titles:inspection', 'titles:status'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INSPECTION_START_DATE,
    name: ['titles:inspection', 'titles:start-date'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INSPECTION_END_DATE,
    name: ['titles:inspection', 'titles:end-date'],
  },
  {
    fieldType: 'enum',
    value: GC.GRC.INSPECTION_SOURCE,
    options: getInspectionSourceOptions(),
    name: ['titles:inspection', 'titles:source'],
  },
  {
    fieldType: 'enum',
    options: getTruckingMethodOptions(),
    value: GC.GRC.INSPECTION_TRUCKING_METHOD,
    name: ['titles:inspection', 'titles:trucking-method'],
  },
  {
    fieldType: 'enum',
    value: GC.GRC.INSPECTION_TYPE,
    options: getInspectionTypeOptions(),
    name: ['titles:inspection', 'titles:type'],
  },
  {
    value: GC.GRC.INSPECTION_CREATED_BY,
    name: ['titles:inspection', 'titles:created-by'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INSPECTION_CREATED_DATE,
    name: ['titles:inspection', 'titles:created-date'],
  },
  {
    value: GC.GRC.INSPECTION_LAST_MODIFIED_BY,
    name: ['titles:inspection', 'titles:updated-by'],
  },
  {
    fieldType: 'date',
    filterType: 'date',
    value: GC.GRC.INSPECTION_LAST_MODIFIED_DATE,
    name: ['titles:inspection', 'titles:update-on'],
  },
  {
    value: GC.GRC.INSPECTION_DESCRIPTION,
    name: ['titles:inspection', 'titles:description'],
  },
  {
    value: GC.GRC.INSPECTION_TEMPLATE_NAME,
    name: ['titles:inspection', 'titles:template'],
  },
  {
    name: 'titles:branch-name',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  },
];

const defaultSystemFields = [
  GC.FIELD_CREATED_BY,
  GC.FIELD_CREATED_DATE,
  GC.FIELD_LAST_MODIFIED_BY,
  GC.FIELD_LAST_MODIFIED_DATE,
];

const vendorDefaultFields = [
  GC.GRC.FLEET_VENDOR_COMPANY_NAME,
  GC.GRC.VENDOR_OWNER_NAME,
  GC.GRC.FLEET_VENDOR_DBA,
  GC.GRC.FLEET_VENDOR_US_DOT_NUMBER,
  GC.GRC.FLEET_VENDOR_MC_NUMBER,
  GC.GRC.FLEET_VENDOR_ACCOUNT_NUMBER,
  GC.GRC.FLEET_VENDOR_DUNS_NUMBER,
];

const documentFields = [
  GC.GRC.DOCUMENTS_TYPE,
  GC.GRC.DOCUMENTS_STATUS,
  GC.GRC.DOCUMENTS_DESCRIPTION,
  GC.GRC.DOCUMENTS_SIGNET_DATE,
  GC.GRC.DOCUMENTS_EXPIRATION_DATE,
];

const driverDefaultFields = [
  GC.GRC.DRIVER_LOGIN_ID,
  GC.GRC.DRIVER_FULL_NAME,
  GC.GRC.DRIVER_FIRST_NAME,
  GC.GRC.DRIVER_LAST_NAME,
];

const driverFields = [
  GC.FIELD_FLEET_LOGIN_ID,
  GC.FIELD_FLEET_DRIVER_NUMBER,
  GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  `${GC.SYSTEM_OBJECT_ASSIGNED_TO_DIVISION}.${GC.BRANCH_NAME}`,
  GC.FIELD_DRIVER_TYPE,
  'employeeNumber',
  GC.FIELD_DRIVER_EMPLOYED,
  GC.FIELD_SHOW_VENDOR_PAYROLL,
  GC.FIELD_FULL_NAME,
  GC.FIELD_NICK_NAME,
  GC.FIELD_FIRST_NAME,
  GC.FIELD_LAST_NAME,
  GC.FIELD_EMAIL,
  GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER,
  'secondaryPhoneNumber',
  'autoAcceptRate',
  'gender',
  GC.GRC.DISPATCHING_GROUP_NAME,
  GC.GRC.ASSIGNED_DISPATCHER_FIRST_NAME,
  GC.GRC.ASSIGNED_DISPATCHER_LAST_NAME,
  GC.GRC.ASSIGNED_DISPATCHER_LOGIN_ID,
  'assignedDivision.displayedValue',
  'unemploymentReason.displayedValue',
  'domicileTerminal.displayedValue',
  GC.FIELD_TEAM_DRIVER,
  GC.FIELD_EXPEDITED_DIVISION,
  GC.FIELD_FLEET_BIRTH_DATE,
  GC.FIELD_FLEET_CURRENT_HIRE_DATE,
  GC.FIELD_FLEET_PREVIOUS_HIRE_DATE,
  GC.FIELD_FLEET_ORIGINAL_HIRE_DATE,
  GC.FIELD_FLEET_TERMINATION_DATE,
  'driverHours.outOfDutyReason.displayedValue',
  'driverHours.dutyStatusCode',
  'driverHours.timeInCurrentStatus',
  'driverHours.timeUntilBreak',
  'driverHours.shiftDriveRemaining',
  'driverHours.shiftRemaining',
  'driverHours.cycleRemaining',
  'driverHours.cycleTomorrow',
  'licenseDetails.licenseNumber',
  'licenseDetails.licenseCountry',
  'licenseDetails.licenseState',
  'licenseDetails.licenseClass',
  'licenseDetails.restrictions',
  'licenseDetails.issueDate',
  'licenseDetails.expirationDate',
  'citizenshipDetails.citizen.displayedValue',
  'citizenshipDetails.passportNumber',
  'citizenshipDetails.passportCountry',
  'citizenshipDetails.passportExpiration',
  'citizenshipDetails.visaNumber',
  'citizenshipDetails.visaCountry',
  'citizenshipDetails.visaExpiration',
  'citizenshipDetails.greenCardNumber',
  'citizenshipDetails.greenCardExpiration',
  'driverLatestLocation.country',
  'driverLatestLocation.state',
  'driverLatestLocation.city',
  'driverLatestLocation.zip',
  'driverAssignment.truck.unitId',
  'driverAssignment.trailers.unitId',
  GC.FIELD_COMMENTS,
  ...vendorDefaultFields,
  GC.GRC.CERTIFICATION_TYPE,
  GC.GRC.CERTIFICATION_NAME,
  GC.GRC.CERTIFICATION_EXPIRATION_DATE,
  GC.GRC.CERTIFICATION_DATE,
  GC.GRC.CERTIFICATION_NOTES,
  GC.GRC.CERTIFICATION_OOS_ITEM,
  ...documentFields,
  ...defaultSystemFields,
  GC.GRC.PAY_TO_LOCATION_LOCATION_NAME,
];

const truckFields = [
  GC.FIELD_UNIT_ID,
  'truckType.displayedValue',
  GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  `${GC.SYSTEM_OBJECT_ASSIGNED_TO_DIVISION}.${GC.BRANCH_NAME}`,
  'legacyUnitId',
  GC.FIELD_SERIAL_NUMBER,
  GC.FIELD_MANUFACTURER,
  GC.FIELD_YEAR,
  GC.FIELD_MODEL,
  'series',
  GC.FIELD_FLEET_IN_SERVICE,
  GC.FIELD_FLEET_IN_SERVICE_DATE,
  'outOfServiceReason.displayedValue',
  GC.FIELD_AVAILABLE,
  'unavailabilityReason.displayedValue',
  GC.FIELD_FLEET_OUT_OF_SERVICE_DATE,
  GC.FIELD_ODOMETER,
  'licensePlate',
  'registrationState',
  'registrationExpirationDate',
  'titleState',
  'titleNumber',
  'iftaRequired',
  'iftaDate',
  GC.FIELD_IFTA_DECAL,
  'truckLatestLocation.country',
  'truckLatestLocation.state',
  'truckLatestLocation.city',
  'truckLatestLocation.zip',
  ...driverDefaultFields,
  ...vendorDefaultFields,
  ...documentFields,
  ...defaultSystemFields,
];

const trailerFields = [
  GC.FIELD_UNIT_ID,
  GC.FIELD_AVAILABLE,
  GC.FIELD_FLEET_IN_SERVICE,
  GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  `${GC.SYSTEM_OBJECT_ASSIGNED_TO_DIVISION}.${GC.BRANCH_NAME}`,
  'trailerType.displayedValue',
  'trailerModel',
  'modelSeries',
  GC.FIELD_MANUFACTURER,
  GC.FIELD_SERIAL_NUMBER,
  'legacyUnitId',
  'licensePlate',
  'outputYear',
  'titleState',
  'titleNumber',
  'unavailabilityReason.displayedValue',
  'outOfServiceReason.displayedValue',
  'registrationState',
  GC.FIELD_FLEET_IN_SERVICE_DATE,
  GC.FIELD_FLEET_OUT_SERVICE_DATE,
  'registrationExpiredDate',
  'trailerLatestLocation.country',
  'trailerLatestLocation.state',
  'trailerLatestLocation.city',
  'trailerLatestLocation.zip',
  GC.GRC.TRAILER_OWNERSHIP_CONTRACT_NAME,
  GC.FIELD_SHARED,
  GC.GRC.SHARED_TO_BRANCHES_BRANCH_NAME,
  `trailerGeofencingLocation.${GC.SYSTEM_OBJECT_GEOFENCING_LOCATION}.${GC.FIELD_NAME}`,
  `trailerGeofencingLocation.${GC.FIELD_CREATED_DATE}`,
  ...documentFields,
  ...vendorDefaultFields,
  ...driverDefaultFields,
  ...defaultSystemFields,
];

const vendorFields = [
  GC.FIELD_NAME,
  GC.FIELD_CARRIER_DBA,
  GC.FIELD_FLEET_PHONE_NUMBER,
  GC.FIELD_EMAIL,
  GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  GC.FIELD_OWNER_NAME,
  GC.FIELD_CARRIER_USDOT_NUMBER,
  GC.FIELD_CARRIER_MC_NUMBER,
  GC.FIELD_ACCOUNT_NUMBER,
  GC.FIELD_CARRIER_DUNS_NUMBER,
  GC.FIELD_VENDOR_GST_NUMBER,
  GC.FIELD_FEDERAL_EIN,
  ...documentFields,
  ...defaultSystemFields,
  GC.GRC.PAY_TO_TYPE,
  GC.GRC.PAY_TO_LOCATION_LOCATION_NAME,
  GC.GRC.INTEGRATION_INFO_INTEGRATION_TYPE,
  GC.GRC.INTEGRATION_INFO_INTEGRATION_ID,
  GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME,
  GC.GRC.INTEGRATION_INFO_LAST_MODIFIED_DATE,
];

const sharedComponentSettings = [
  GC.FIELD_INSTALLED,
  GC.FIELD_COMPONENT_ID,
  GC.FIELD_SERIAL_NUMBER,
  GC.FIELD_SPECIFICATION,
  GC.GRC.FLEET_TRUCK_UNIT_ID,
  GC.FIELD_WARRANTY_EXPIRATION,
  GC.GRC.FLEET_TRAILER_UNIT_ID,
  `${GC.FIELD_COMPONENT_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  `${GC.FIELD_COMPONENT_MANUFACTURER}.${GC.FIELD_DISPLAYED_VALUE}`,
  ...defaultSystemFields,
];

const equipmentServiceFields = [
  GC.FIELD_SERVICE_ID,
  GC.FIELD_SERVICE_DATE,
  `${GC.FIELD_SERVICE_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  GC.FIELD_APPROVED_BY,
  `${GC.SYSTEM_LIST_COMPONENTS}.${GC.FIELD_COMPONENT_ID}`,
  GC.GRC.FLEET_TRUCK_UNIT_ID,
  GC.GRC.FLEET_TRAILER_UNIT_ID,
  GC.FIELD_INTERVAL,
  GC.FIELD_INTERVAL_UOM,
  GC.FIELD_COMMENTS,
  GC.FIELD_PERFORMED_DATE,
  GC.FIELD_PERFORMED_VALUE,
  GC.FIELD_PERFORMED_TYPE,
  GC.FIELD_RELATED_TASKS,
  `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_NAME}`,
  `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_DBA}`,
  `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_DUNS_NUMBER}`,
  `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_MC_NUMBER}`,
  GC.GRC.INVOICE_INVOICE_TOTAL,
  GC.GRC.INVOICE_INVOICE_CURRENCY,
  GC.GRC.INVOICE_INVOICE_NUMBER,
  GC.GRC.INVOICE_INVOICE_DATE,
  GC.GRC.INVOICE_INVOICE_CHECK_NUMBER,
  GC.GRC.INVOICE_INVOICE_STATUS,
  GC.GRC.INVOICE_INVOICE_NET_DAYS,
  GC.GRC.INVOICE_INVOICE_CHECK_DATE,
  GC.GRC.INVOICE_INVOICE_GL_CODE,
  GC.GRC.INVOICE_INVOICE_PAYMENT_DUE_DATE,
  GC.GRC.INVOICE_INVOICE_CHECK_AMOUNT,
  GC.GRC.INVOICE_INVOICE_PO_NUMBER,
  GC.GRC.INVOICE_INVOICE_DEPOSIT_DATE,
  GC.GRC.INVOICE_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER,
  GC.GRC.INVOICE_INVOICE_COMMENTS,
  ...defaultSystemFields,
];

const payrollAccessorialFields = [
  GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
  GC.FIELD_FUEL_RELATED,
  GC.FIELD_NON_TAXABLE,
  GC.FIELD_DEDUCTION,
  GC.FIELD_ACTIVE,
  GC.FIELD_CURRENCY,
  GC.FIELD_RATE,
  GC.FIELD_CHARGE_RATE_TYPE,
  GC.FIELD_LIMITED,
  GC.FIELD_TOTAL_AMOUNT,
  GC.FIELD_HISTORICAL_PAID_AMOUNT,
  GC.FIELD_PAID_AMOUNT,
  GC.FIELD_FULLY_PAID,
  GC.FIELD_COMMENTS,
  ...defaultSystemFields,
];

const driverPayrollAccessorialFields = [
  ...payrollAccessorialFields,
  GC.GRC.DRIVER_LOGIN_ID,
];

const vendorPayrollAccessorialFields = [
  ...payrollAccessorialFields,
  GC.GRC.FLEET_VENDOR_COMPANY_NAME,
];

const driverOnboardingFields = [
  GC.FIELD_STATUS,
  GC.FIELD_INTEGRATION_TYPE,
  GC.FIELD_EDI_RECEIVED_DATE,
];

const serviceIssueFields = [
  GC.FIELD_ISSUE_ID,
  `${GC.FIELD_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
  GC.FIELD_SUMMARY,
  GC.GRC.FLEET_TRUCK_UNIT_ID,
  GC.GRC.FLEET_TRAILER_UNIT_ID,
  GC.FIELD_STATUS,
  GC.FIELD_PRIORITY,
  GC.FIELD_DESCRIPTION,
  `${GC.SYSTEM_OBJECT_SERVICE_VENDOR}.${GC.FIELD_NAME}`,
  `${GC.FIELD_ASSIGNEE}.${GC.FIELD_FIRST_NAME}`,
  `${GC.FIELD_ASSIGNEE}.${GC.FIELD_LAST_NAME}`,
  `${GC.FIELD_ASSIGNEE}.${GC.FIELD_USER_LOGIN_ID}`,
  GC.GRC.WORK_ORDER_NAME,
  GC.GRC.WORK_ORDER_STATUS,
  GC.GRC.WORK_ORDER_DUE_DATE,
  GC.GRC.WORK_ORDER_EXPECTED_COMPLETED_DATE,
  GC.GRC.WORK_ORDER_ACTUAL_COMPLETED_DATE,
  GC.GRC.WORK_ORDER_SERVICE_VENDOR_NAME,
  `${GC.FIELD_LOCATION}.${GC.FIELD_ADDRESS}`,
  GC.GRC.LOCATION_CITY,
  GC.GRC.LOCATION_STATE,
  GC.GRC.LOCATION_ZIP,
  GC.GRC.LOCATION_COUNTRY,
  GC.GRC.INSPECTION_NAME,
  GC.GRC.INSPECTION_STATUS,
  GC.GRC.INSPECTION_START_DATE,
  GC.GRC.INSPECTION_END_DATE,
  GC.GRC.INSPECTION_SOURCE,
  GC.GRC.INSPECTION_TRUCKING_METHOD,
  GC.GRC.INSPECTION_TYPE,
  GC.GRC.INSPECTION_CREATED_BY,
  GC.GRC.INSPECTION_CREATED_DATE,
  GC.GRC.INSPECTION_LAST_MODIFIED_BY,
  GC.GRC.INSPECTION_LAST_MODIFIED_DATE,
  GC.GRC.INSPECTION_DESCRIPTION,
  GC.GRC.INSPECTION_TEMPLATE_NAME,
  GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  ...defaultSystemFields,
];

const fieldsMap = {
  [GC.FLEET_TRUCK_REPORT]: truckFields,
  [GC.FLEET_DRIVER_REPORT]: driverFields,
  [GC.FLEET_VENDOR_REPORT]: vendorFields,
  [GC.FLEET_TRAILER_REPORT]: trailerFields,
  [GC.FLEET_SERVICE_ISSUE_REPORT]: serviceIssueFields,
  [GC.FLEET_DRIVER_ONBOARDING_REPORT]: driverOnboardingFields,
  [GC.FLEET_SHARED_COMPONENT_REPORT]: sharedComponentSettings,
  [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: equipmentServiceFields,
  [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: driverPayrollAccessorialFields,
  [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: vendorPayrollAccessorialFields,
};

const getFieldsByReportType = (reportType: string) => G.getPropFromObject(reportType, fieldsMap);

const getTitle = (title: any) => {
  if (G.isArray(title)) return R.join(': ', R.map(G.getWindowLocale, title));

  return G.getWindowLocale(title);
};

export const getFilterParamsByReportType = (reportType: string) => R.compose(
  R.map((item: string) => {
    const field = R.find(R.propEq(item, GC.FIELD_VALUE), settings);

    const { name, value, options, placeholder, collection, disableFilter, handleDisableOptions, filterType = 'string' } = field;

    return {
      value,
      collection,
      disableFilter,
      handleDisableOptions,
      [GC.FIELD_TYPE]: filterType,
      [GC.FIELD_NAME]: getTitle(name),
      options: R.equals(value, GC.FIELD_STATUS) ? options(reportType) : options,
      placeholder: G.ifElse(
        R.equals(filterType, 'selectMultiple'),
        R.or(placeholder, { text: 'Choose', key: 'titles:choose' }),
      ),
    };
  }),
)(getFieldsByReportType(reportType));

const pivotTypeMap = {
  'date': GC.PIVOT_TYPE_YQMD,
  'number': GC.PIVOT_TYPE_NUMBER,
};

export const getColumnSettingsByReportType = (reportType: string) => R.compose(
  R.indexBy(R.prop('fieldName')),
  R.map((item: string) => {
    const field = R.find(R.propEq(item, GC.FIELD_VALUE), settings);

    const {
      name,
      fieldType,
      filterType,
      width = 220,
      guidPropName,
      notResizable,
      selectAction,
      selectOptions,
      cellPermissions,
      customComponent,
      searchable = true,
      selectPermissions,
      useExpandedContainer,
    } = field;

    let type = R.pathOr(fieldType, [reportType], fieldType);

    if (G.isFunction(fieldType)) type = fieldType(reportType);

    return {
      type,
      name,
      width,
      searchable,
      notResizable,
      guidPropName,
      selectAction,
      selectOptions,
      customComponent,
      fieldName: item,
      selectPermissions,
      useExpandedContainer,
      pivotType: R.prop(filterType, pivotTypeMap),
      cellPermissions: R.pathOr(cellPermissions, [reportType], cellPermissions),
    };
  }),
  R.append('fleetEquipmentServiceActions'),
)(getFieldsByReportType(reportType));

export const getWritePermissionsByReportType = (reportType: string) => {
  const permissionsMap = {
    [GC.FLEET_SHARED_COMPONENT_REPORT]: null,
    [GC.FLEET_DRIVER_ONBOARDING_REPORT]: null,
    [GC.FLEET_TRUCK_REPORT]: [PC.FLEET_TRUCK_WRITE],
    [GC.FLEET_DRIVER_REPORT]: [PC.FLEET_DRIVER_WRITE],
    [GC.FLEET_VENDOR_REPORT]: [PC.FLEET_VENDOR_WRITE],
    [GC.FLEET_TRAILER_REPORT]: [PC.FLEET_TRAILER_WRITE],
    [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: [PC.FLEET_DRIVER_WRITE],
    [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: [PC.FLEET_VENDOR_WRITE],
  };

  return G.getPropFromObject(reportType, permissionsMap);
};

export const getFleetServiceIssuePermissions = (item: Object) => G.ifElse(
  G.isNotNilAndNotEmpty(G.getPropFromObject(GC.GRC.FLEET_TRUCK_UNIT_ID, item)),
  [PC.FLEET_TRUCK_WRITE],
  [PC.FLEET_TRAILER_WRITE],
);

export const getWritePermissionsByReportTypeForTableActionButtons = ({ item, reportType }: Object) => {
  if (R.equals(reportType, GC.FLEET_DRIVER_ONBOARDING_REPORT)) return false;

  if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) return getFleetServiceIssuePermissions(item);

  const permissionsMap = {
    [GC.FLEET_SHARED_COMPONENT_REPORT]: null,
    [GC.FLEET_TRUCK_REPORT]: [PC.FLEET_TRUCK_WRITE],
    [GC.FLEET_DRIVER_REPORT]: [PC.FLEET_DRIVER_WRITE],
    [GC.FLEET_VENDOR_REPORT]: [PC.FLEET_VENDOR_WRITE],
    [GC.FLEET_TRAILER_REPORT]: [PC.FLEET_TRAILER_WRITE],
    [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: [PC.FLEET_DRIVER_WRITE],
    [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: [PC.FLEET_VENDOR_WRITE],
  };

  return G.getPropFromObject(reportType, permissionsMap);
};

export const getWritePermissionsByReportTypeForElementActions = ({ item, props, reportType }: Object) => {
  if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) {
    return getFleetServiceIssuePermissions(item, props);
  }

  if (R.equals(reportType, GC.FLEET_EQUIPMENT_SERVICE_REPORT)) {
    return [PC.TASK_WRITE];
  }

  return getWritePermissionsByReportType(reportType);
};

export const getRemovePermissionByReportType = ({ item, reportType }: Object) => {
  if (R.includes(
    reportType,
    [
      GC.FLEET_SHARED_COMPONENT_REPORT,
      GC.FLEET_DRIVER_ONBOARDING_REPORT,
      GC.FLEET_EQUIPMENT_SERVICE_REPORT,
    ],
  )) return null;

  if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) return getFleetServiceIssuePermissions(item);

  if (R.includes(
    reportType,
    [
      GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
      GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
    ],
  )) return G.ifElse(R.propEq(true, 'withoutRemove', item), '', null);

  return R.of(Array, PC.ROLE_LIUBANCHYK_SUPER_ADMIN);
};

export const getReadWritePermissionsByReportType = (reportType: string) => {
  const permissionsMap = {
    [GC.FLEET_SHARED_COMPONENT_REPORT]: null,
    [GC.FLEET_DRIVER_ONBOARDING_REPORT]: null,
    [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: [PC.FLEET_DRIVER_WRITE],
    [GC.FLEET_TRUCK_REPORT]: [PC.FLEET_DRIVER_READ, PC.FLEET_TRUCK_WRITE],
    [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: [PC.FLEET_VENDOR_WRITE],
    [GC.FLEET_DRIVER_REPORT]: [PC.FLEET_TRUCK_READ, PC.FLEET_DRIVER_WRITE],
    [GC.FLEET_VENDOR_REPORT]: [PC.FLEET_VENDOR_READ, PC.FLEET_VENDOR_WRITE],
    [GC.FLEET_TRAILER_REPORT]: [PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
  };

  return G.getPropFromObject(reportType, permissionsMap);
};

export const getFreezedFieldByReportType = (reportType: string) => {
  const map = {
    [GC.FLEET_EQUIPMENT_SERVICE_REPORT]: {
      sequence: 1,
      freezed: true,
      reference: false,
      name: 'fleetEquipmentServiceActions',
    },
  };

  return R.propOr(null, reportType, map);
};

const driverOnboardingAmousDriverFields = [
  GC.FIELD_FIRST_NAME,
  GC.FIELD_LAST_NAME,
  GC.FIELD_MIDDLE_INITIAL,
  GC.FIELD_NICK_NAME,
  GC.FIELD_FLEET_LOGIN_ID,
  GC.FIELD_FLEET_DRIVER_NUMBER,
  GC.FIELD_EMAIL,
  GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER,
  'employeeNumber',
  'gender',
  GC.FIELD_FLEET_BIRTH_DATE,
  GC.FIELD_FLEET_CURRENT_HIRE_DATE,
  GC.FIELD_FLEET_PREVIOUS_HIRE_DATE,
  GC.FIELD_FLEET_ORIGINAL_HIRE_DATE,
  GC.FIELD_FLEET_TERMINATION_DATE,
  GC.FIELD_TEAM_DRIVER,
  GC.FIELD_PAYED_HOURS,
  'registrationForm2290',
  GC.FIELD_DRIVER_EMPLOYED,
];

const makeDriverOnboardingColumnSettingsSettings = (fields: Object) => R.compose(
  R.mapObjIndexed(({
    name,
    fieldType,
    width = 150,
    customComponent,
  }: Object) => ({
    name,
    width,
    customComponent,
    type: fieldType,
  })),
  R.pick(fields),
  R.indexBy(R.prop(GC.FIELD_VALUE)),
)(settings);

const Error = ({ error }: Object) => (
  <Box p='5px' color={G.getTheme('colors.light.mainRed')}>
    {error}
  </Box>
);

export const ErrorMessage = ({ errorMessage }: Object) => {
  if (G.isNilOrEmpty(errorMessage)) return null;

  return (
    <PopperComponent
      type='hover'
      position='right'
      content={<Error error={errorMessage} />}
      borderColor={G.getTheme('listActions.borderColor')}
    >
      <Box mx={10}>{I.warningIcon()}</Box>
    </PopperComponent>
  );
};

export const driversOnboardingColumnSettings = {
  errorMessage: {
    width: 50,
    customComponent: (row: Object) => <ErrorMessage errorMessage={R.path(['data', 'errorMessage'], row)} />,
  },
  [GC.FIELD_STATUS]: {
    width: 120,
    type: 'enum',
    name: 'titles:status',
  },
  [GC.FIELD_EXTERNAL_ID]: {
    width: 120,
    name: 'titles:external-id',
  },
  ...makeDriverOnboardingColumnSettingsSettings(driverOnboardingAmousDriverFields),
  licenseNumber: {
    name: 'titles:license-number',
  },
  licenseCountry: {
    name: 'titles:license-country',
  },
  licenseState: {
    name: 'titles:license-state',
  },
  licenseClass: {
    name: 'titles:license-class',
  },
  restrictions: {
    name: 'titles:restrictions',
  },
  issueDate: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:issue-date',
  },
};

export const getItemDisabledByReportType = ({ item, reportType, isTrailerShared }: Object) => {
  if (R.includes(reportType, [GC.FLEET_SERVICE_ISSUE_REPORT, GC.FLEET_EQUIPMENT_SERVICE_REPORT])) {
    if (isTrailerShared) {
      return G.notPropEq(G.getAmousCurrentUserLoginIdFromWindow(), GC.FIELD_CREATED_BY, item);
    }

    return G.isFalse(G.getPropFromObject('editable', item));
  }

  return R.and(
    R.not(G.getIsAmousUserSuperAdminFromWindow()),
    R.and(
      R.isNotNil(G.getBranchGuidFromObject(item)),
      G.notEquals(G.getBranchGuidFromObject(item), G.getAmousCurrentBranchGuidFromWindow()),
    ),
  );
};
