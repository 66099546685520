import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature charts
import { chartNameErrorClass } from '../ui';
import { renderMultiSelect } from '../components/common';
import { setOptions, getSelectOptions } from '../helpers';
import {
  TEL_COUNT,
  CLO_COUNT,
  TEL_SPEND,
  CLO_MARGIN,
  TEL_MARGIN,
  CLO_INCOME,
  EVENT_ON_TIME_LATE } from '../constants';
//////////////////////////////////////////////////

const dataTypeParams = [
  { label: '', value: '' },
  {
    value: TEL_COUNT,
    label: G.getWindowLocale('titles:tel-count', 'TEL Count'),
  },
  {
    value: CLO_COUNT,
    label: G.getWindowLocale('titles:clo-count', 'CLO Count'),
  },
  {
    value: TEL_MARGIN,
    label: G.getWindowLocale('titles:tel-margin', 'TEL Margin'),
  },
  {
    value: CLO_MARGIN,
    label: G.getWindowLocale('titles:clo-margin', 'CLO Margin'),
  },
  {
    value: TEL_SPEND,
    label: G.getWindowLocale('titles:tel-total-spend', 'TEL Spend'),
  },
  {
    value: CLO_INCOME,
    label: G.getWindowLocale('titles:clo-total-income', 'CLO Total Income'),
  },
  {
    value: EVENT_ON_TIME_LATE,
    label: G.getWindowLocale('titles:event-on-time-late', 'Event on Time Late'),
  },
];

const nameValidation = [G.required, G.curriedIsMinMax(1, 40)];

export const mainFilters = (props: Object) => {
  const { roles } = props;
  return {
    fields: [
      {
        type: 'text',
        name: 'titles:name',
        validate: nameValidation,
        nameForAttribute: GC.FIELD_NAME,
        errorClass: chartNameErrorClass,
      },
      {
        zI: '14',
        isMulti: true,
        type: 'select',
        margin: '5px 0',
        placeholder: '',
        display: 'flex',
        labelWidth: '188px',
        name: 'titles:scope-roles',
        handler: 'handleSelectRoles',
        labelMargin: '5px 10px 5px 0',
        renderComponent: renderMultiSelect,
        options: setOptions(R.values(roles)),
        nameForAttribute: GC.SYSTEM_OBJECT_ROLES,
      },
      {
        type: 'textarea',
        name: 'titles:description',
        nameForAttribute: GC.FIELD_DESCRIPTION,
      },
      {
        type: 'select',
        validate: G.required,
        name: 'titles:collection',
        options: 'collectionType',
        nameForAttribute: GC.FIELD_COLLECTION,
      },
      {
        type: 'select',
        validate: G.required,
        options: dataTypeParams,
        name: 'titles:data-type',
        handler: 'handleSelectDataType',
        nameForAttribute: GC.FIELD_CHART_DATA_TYPE,
      },
      {
        type: 'select',
        validate: G.required,
        name: 'titles:chart-type',
        handler: 'handleSelectChartType',
        nameForAttribute: GC.FIELD_CHART_CHART_TYPE,
        options: getSelectOptions(props, 'chartTypes')(),
      },
      {
        type: 'select',
        validate: G.required,
        name: 'titles:group-by',
        nameForAttribute: GC.FIELD_CHART_GROUP_BY_TYPE,
        options: getSelectOptions(props, 'groupBy')(props),
      },
    ],
  };
};
