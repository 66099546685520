import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const styles = {
  width: 400,
  inputWrapperStyles: {
    mb: 25,
  },
};

export const fieldSettingsMap = {
  // documents
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    width: 400,
    type: 'select',
    isRequired: true,
    options: 'documentType',
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
    label: G.getWindowLocale('titles:document-type', 'Document Type'),
  },
  [GC.FIELD_DOCUMENT_URL]: {
    width: 400,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_DOCUMENT_URL,
    label: G.getWindowLocale('titles:url', 'URL'),
    inputWrapperStyles: {
      mt: 25,
      display: (props: Object) => {
        const { file, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(R.or(G.isNotNil(file), G.isNotNil(documentFilename)), 'none', 'block');
      },
    },
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    width: 400,
    type: 'file',
    isRequired: true,
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    label: G.getWindowLocale('titles:upload-file', 'Upload File'),
    inputWrapperStyles: {
      mt: 25,
      display: (props: Object) => {
        const { url, version, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(
          R.or(G.isNotNilAndNotEmpty(url), R.and(G.isNotNil(version), R.isNil(documentFilename))),
          'none',
          'block',
        );
      },
    },
  },
  // notes
  [GC.FIELD_TEXT]: {
    p: 15,
    width: 400,
    errorTop: 81,
    type: 'textarea',
    isRequired: true,
    fieldName: GC.FIELD_TEXT,
    label: G.getWindowLocale('titles:subject', 'Subject'),
  },
  // safety violation
  [GC.FIELD_TYPE]: {
    ...styles,
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_TYPE,
    options: 'safetyViolationType',
    label: G.getWindowLocale('titles:type', 'Type'),
  },
  [GC.FIELD_SEVERITY]: {
    ...styles,
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_SEVERITY,
    options: 'safetyViolationSeverity',
    label: G.getWindowLocale('titles:severity', 'Severity'),
  },
  occurrenceDate: {
    ...styles,
    isRequired: true,
    timeIntervals: 1,
    customInput: true,
    type: 'datePicker',
    timeSelection: true,
    withClickZIndex: true,
    fieldName: 'occurrenceDate',
    maxDate: G.getCurrentDate(),
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    label: G.getWindowLocale('titles:occurrence-date', 'Occurrence Date'),
  },
  disciplinaryAction: {
    ...styles,
    type: 'select',
    fieldName: 'disciplinaryAction',
    options: 'safetyViolationDisciplinaryAction',
    label: G.getWindowLocale('titles:disciplinary-action', 'Disciplinary Action'),
  },
  disciplinaryDate: {
    ...styles,
    timeIntervals: 1,
    customInput: true,
    type: 'datePicker',
    timeSelection: true,
    withClickZIndex: true,
    maxDate: G.getCurrentDate(),
    fieldName: 'disciplinaryDate',
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    label: G.getWindowLocale('titles:disciplinary-date', 'Disciplinary Date'),
  },
  eventDescription: {
    p: 15,
    width: 400,
    type: 'textarea',
    fieldName: 'eventDescription',
    label: G.getWindowLocale('titles:event-description', 'Event Description'),
  },
};
