// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

// TODO: with filters, check getCarrierRateFilterParams on carrier feature

export const FIELDS_AND_FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_NAME,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_ACTIVE,
    name: G.getWindowLocale('titles:active', 'Active'),
  },
  {
    type: 'string',
    value: GC.GRC.MODE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:mode', 'Mode'),
  },
  {
    type: 'string',
    value: GC.GRC.CARRIER_NAME,
    name: G.getWindowLocale('titles:carrier-name', 'Carrier Name'),
  },
  {
    type: 'string',
    value: GC.GRC.CARRIER_BRANCH_NAME,
    name: G.getWindowLocale('titles:carrier-branch', 'Carrier Branch'),
  },
  {
    type: 'boolean',
    value: GC.GRC.CARRIER_ACTIVE,
    name: G.getWindowLocale('titles:carrier-active', 'Carrier Active'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.GRANTED_ENTERPRISES_NAME,
    name: G.getWindowLocale('titles:granted-branches', 'Granted Branches'),
  },
  {
    type: 'string',
    value: GC.FIELD_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_MIN_RATE,
    name: G.getWindowLocale('titles:min-rate', 'Min Rate'),
  },
  {
    type: 'date',
    value: GC.FIELD_EFFECTIVE_DATE,
    name: G.getWindowLocale('titles:effective-date', 'Effective Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_EXPIRATION_DATE,
    name: G.getWindowLocale('titles:expiration-date', 'Expiration Date'),
  },
];
