import React from 'react';
import * as R from 'ramda';
import { withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { PopperComponent } from '../../../components/popper';
import { HighlightedText } from '../../../components/highlighted-text';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  IconWrapper,
  RelativeFlex,
  scrollableContainerCss4px,
} from '../../../ui';
// utilities
import T from '../../../theme';
// features drivers-card
import { getClosInfo } from './card';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const lightBlueColor = G.getTheme('colors.light.blue');
const mainLightColor = G.getTheme('colors.light.mainLight');
const stopRejectColor = G.getTheme('dispatchBoardPopper.stopRejectColor');
const stopSuccessColor = G.getTheme('dispatchBoardPopper.stopSuccessColor');

const enhance = withHandlers({
  onClickOpenTelDetails: (props: Object) => () => props.handleOpenTelDetails(props),
  onClickEditIcon: (props: Object) => (e: Object) => props.handleClickEditIcon(e, props),
});

const UnassignedCard = enhance((props: Object) => {
  const {
    clos,
    status,
    lastEvent,
    firstEvent,
    enterprise,
    onClickEditIcon,
    onClickOpenTelDetails,
    primaryReferenceValue,
    handleAddTelDriverRate,
  } = props;

  const closInfo = getClosInfo(clos);
  const lastEventDate = R.prop('lateDate', lastEvent);
  const firstEventDate = R.prop('earlyDate', firstEvent);
  const statusBorderColor = R.path(['status', status], T);
  const branchName = R.pathOr('', [GC.FIELD_BRANCH_NAME], enterprise);
  const itemsCount = R.pathOr(0, [GC.FIELD_ITEMS_INFO, GC.FIELD_COUNT], props);

  return (
    <Flex
      p='2px'
      mb={15}
      ml='0px'
      minHeight={60}
      alignItems='start'
      borderRadius='3px'
      border='1px solid'
      bg={mainLightColor}
      flexDirection='column'
      borderLeft='3px solid'
      justifyContent='space-between'
      borderLeftColor={statusBorderColor}
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
      borderColor={G.getTheme('colors.light.darkGrey')}
    >
      <Box width='100%' height='100%' overflow='auto'>
        <Flex overflow='auto' width='100%' justifyContent='space-between'>
          <Flex
            overflow='auto'
            maxWidth='calc(100% - 80px)'
            css={scrollableContainerCss4px}
          >
            <Box
              mr={10}
              fontWeight={600}
              cursor='pointer'
              color={darkBlueColor}
              textDecoration='underline'
              onClick={onClickOpenTelDetails}
              title={G.getWindowLocale('actions:go-to-tel', 'Go to TEL')}
            >
              <HighlightedText
                text={primaryReferenceValue}
                highlightBgColor={G.getTheme('colors.light.yellow')}
              />
            </Box>
            <Box
              mr={10}
              fontWeight={600}
              cursor='pointer'
              color={darkBlueColor}
              textDecoration='underline'
            >
              <HighlightedText
                text={branchName}
                highlightBgColor={G.getTheme('colors.light.yellow')}
              />
            </Box>
            <Box mr='5px' color={statusBorderColor} fontSize={14}>
              {G.getWindowLocale(GC.statusLocaleMap[status])}
            </Box>
            {
              closInfo &&
              <Flex mr={10} fontWeight={600}>
                <TextComponent
                  maxWidth={145}
                  title={closInfo}
                  withEllipsis={true}
                  color={darkBlueColor}
                  display='inline-block'
                >
                  {closInfo}
                </TextComponent>
              </Flex>
            }
          </Flex>
          <Flex pt='3px'>
            <AuthWrapper has={[PC.FLEET_RATE_WRITE]}>
              <IconWrapper
                cursor='pointer'
                onClick={handleAddTelDriverRate}
                title={G.getWindowLocale('actions:assign-driver-rate', 'Assign Driver Rate')}
              >
                {I.driver(lightBlueColor, 13, 13)}
              </IconWrapper>
            </AuthWrapper>
            <RelativeFlex mx='6px' title={`${G.getWindowLocale('titles:items')} - ${itemsCount}`}>
              {I.itemBox(lightBlueColor, 13, 13)}
              <AbsoluteBox
                height={13}
                zIndex={11}
                minWidth={13}
                fontSize='9px'
                borderRadius='8px'
                border='1px solid'
                bg={lightBlueColor}
                color={mainLightColor}
                left='calc(100% - 7px)'
                justifyContent='center'
                bottom='calc(100% - 7px)'
                borderColor={mainLightColor}
              >
                {itemsCount}
              </AbsoluteBox>
            </RelativeFlex>
            <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
              <IconWrapper
                pr='1px'
                pl='3px'
                cursor='pointer'
                onClick={onClickEditIcon}
              >
                {I.threePointer(darkBlueColor, 12, 12)}
              </IconWrapper>
            </AuthWrapper>
          </Flex>
        </Flex>
        <Flex width='100%' justifyContent='space-between'>
          <Box flexGrow={1}>
            <Flex fontSize={12} justifyContent='space-between'>
              <Flex mr={10}>
                <PopperComponent
                  zi={1002}
                  type='hover'
                  position='top'
                  content={
                    <Box>
                      <Box>{firstEventDate}</Box>
                      <Box>{G.concatLocationFields(firstEvent.location)}</Box>
                    </Box>
                  }
                  borderColor={G.getTheme('listActions.borderColor')}
                >
                  <Flex
                    mr='5px'
                    p='1px 4px'
                    fontSize={11}
                    fontWeight='bold'
                    border='1px solid'
                    borderRadius='8px'
                    color={stopSuccessColor}
                    borderColor={stopSuccessColor}
                    bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                  >
                    P-{R.pathOr(1, ['eventsInfo', 'pickupCount'], props)}
                  </Flex>
                </PopperComponent>
                <Box>
                  <Box>{firstEventDate}</Box>
                  <Box>{G.concatLocationFields(firstEvent.location, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
                </Box>
              </Flex>
              <Flex>
                <PopperComponent
                  zi={1002}
                  type='hover'
                  position='top'
                  content={
                    <Box>
                      <Box>{lastEventDate}</Box>
                      <Box>{G.concatLocationFields(lastEvent.location)}</Box>
                    </Box>
                  }
                  borderColor={G.getTheme('listActions.borderColor')}
                >
                  <Flex
                    mr='5px'
                    p='1px 4px'
                    fontSize={11}
                    fontWeight='bold'
                    border='1px solid'
                    borderRadius='8px'
                    color={stopRejectColor}
                    borderColor={stopRejectColor}
                    bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                  >
                    D-{R.pathOr(1, ['eventsInfo', 'dropCount'], props)}
                  </Flex>
                </PopperComponent>
                <Box>
                  <Box>{lastEventDate}</Box>
                  <Box>{G.concatLocationFields(lastEvent.location, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
});

export default UnassignedCard;
