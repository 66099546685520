import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet
import { LOCATION_FORM } from '../constants';
// feature fleet/truck
import {
  EDIT_TRUCK_FORM,
  TRUCK_DOCUMENT_FORM } from './constants';
//////////////////////////////////////////////////

const selectFormStore = (state: Object) => (state.form);
const selectTruckStore = (state: Object) => state.fleetTruck;

const makeSelectTruckDetails = () => createSelector(
  selectTruckStore,
  ({ truckDetails, truckOwnership, truckSpecification }: Object) => {
    if (G.isNilOrEmpty(truckDetails)) return null;

    const truckAdditionalEquipment = R.pathOr([], [GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT], truckDetails);

    return {
      ...truckOwnership,
      ownershipVersion: truckOwnership.version,
      ownershipGuid: truckOwnership.guid,
      ...truckSpecification,
      specificationVersion: truckSpecification.version,
      specificationGuid: truckSpecification.guid,
      ...truckDetails,
      [GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT]: R.map(R.prop(GC.FIELD_DROPDOWN_OPTION_GUID), truckAdditionalEquipment),
    };
  },
);

const makeSelectTruckFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => (
    G.ifElse(
      G.isNotNil(form, EDIT_TRUCK_FORM),
      G.getOrElse(form[EDIT_TRUCK_FORM], 'values', {}),
      {},
    )),
);

const makeSelectIntegrationSamsaraList = () => createSelector(
  selectTruckStore,
  ({ integrationSamsara }: Object) => integrationSamsara,
);

const makeSelectTruckRefList = () => createSelector(
  selectTruckStore,
  (fleetTruck: Object) => fleetTruck[GC.UI_FIELD_REFERENCES],
);

const makeSelectTruckInsurances = () => createSelector(
  selectTruckStore,
  ({ insurances }: Object) => insurances,
);

const makeSelectCollapsedGroup = () => createSelector(
  selectTruckStore,
  ({ collapsedGroup }: Object) => collapsedGroup,
);

const makeSelectTruckConfigs = () => createSelector(
  selectTruckStore,
  ({ truckConfigs }: Object) => ({
    truckType: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.TRUCK_TRUCK_TYPE),
    documentType: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.TRUCK_DOCUMENT_TYPE),
    insuranceType: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.TRUCK_INSURANCE_TYPE),
    componentType: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.FLEET_COMPONENT_TYPE),
    outOfServiceReason: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.TRUCK_OUT_SERVICE_REASON),
    unavailabilityReason: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.TRUCK_UNAVAILABILITY_REASON),
    equipmentServiceType: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.FLEET_EQUIPMENT_SERVICE_TYPE),
    componentManufacturer: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.FLEET_COMPONENT_MANUFACTURER),
    [GC.TEMPLATES_LOCATION_TYPE]: G.mapDropdownConfigWithParentGuid(truckConfigs, GC.TEMPLATES_LOCATION_TYPE),
    [GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE]: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      truckConfigs,
      GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE,
    ),
  }),
);

const makeSelectTruckDocuments = () => createSelector(
  selectTruckStore,
  ({ documents, documentsFilter }: Object) => {
    if (G.isAnyNilOrEmpty([documents, documentsFilter])) return documents;

    return R.filter(
      ({ documentType }: Object) => R.propEq(documentsFilter, GC.FIELD_DROPDOWN_OPTION_GUID, documentType),
      documents,
    );
  },
);

const makeSelectTruckDocumentsFilter = () => createSelector(
  selectTruckStore,
  ({ documentsFilter }: Object) => documentsFilter,
);

const makeSelectTruckLocations = () => createSelector(
  selectTruckStore,
  ({ locations }: Object) => locations,
);

const makeSelectTruckLocationsValues = () => createSelector(
  selectFormStore,
  (forms: Object) => forms[LOCATION_FORM],
);

const makeSelectTruckActiveTab = () => createSelector(
  selectTruckStore,
  ({ activeTab }: Object) => activeTab,
);

const makeSelectTruckEquipComponent = () => createSelector(
  selectTruckStore,
  R.compose(
    R.map((item: Object) => R.assoc(
      'backgroundColor',
      G.getThemeByCond(R.propEq(false, GC.FIELD_INSTALLED, item), 'colors.lightGrey', 'colors.white'),
      item,
    )),
    R.pathOr([], ['equipComponent']),
  ),
);

const makeSelectTruckDocFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => (
    G.ifElse(
      G.isNotNil(form, TRUCK_DOCUMENT_FORM),
      G.getOrElse(form[TRUCK_DOCUMENT_FORM], 'values', {}),
      {},
    )),
);

const makeSelectTruckDropDowns = () => (createSelector(
  selectTruckStore,
  ({ truckConfigs }: Object) => truckConfigs.dropdowns,
));

const makeSelectLatestKnownLocationList = () => createSelector(
  selectTruckStore,
  ({ latestKnownLocation }: Object) => latestKnownLocation,
);

const makeSelectBranchConfigs = () => createSelector(
  selectTruckStore,
  ({ branchConfigs }: Object) => branchConfigs,
);

const makeSelectDailyLog = () => createSelector(
  selectTruckStore,
  ({ dailyLog }: Object) => dailyLog,
);

export {
  selectTruckStore,
  makeSelectDailyLog,
  makeSelectTruckDetails,
  makeSelectTruckRefList,
  makeSelectTruckConfigs,
  makeSelectBranchConfigs,
  makeSelectTruckDocuments,
  makeSelectCollapsedGroup,
  makeSelectTruckLocations,
  makeSelectTruckActiveTab,
  makeSelectTruckDropDowns,
  makeSelectTruckFormValues,
  makeSelectTruckInsurances,
  makeSelectTruckDocFormValues,
  makeSelectTruckEquipComponent,
  makeSelectTruckDocumentsFilter,
  makeSelectTruckLocationsValues,
  makeSelectIntegrationSamsaraList,
  makeSelectLatestKnownLocationList,
};
