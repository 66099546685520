import React from 'react';
import * as R from 'ramda';
import sizeMe from 'react-sizeme';
import { pure } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box } from '../../../../ui';
// feature charts
import * as LC from '../../constants';
import { Wrapper, TotalWrapper } from './ui';
import { withChartConfiguration } from './with-chart-configuration';
//////////////////////////////////////////////////////

const ChartRenderer = withChartConfiguration(({ guid, type }: Object) => {
  const isPie = R.or(R.equals(type, LC.CHART_TYPE_PIE), R.equals(type, LC.CHART_TYPE_PIE3D));

  return (
    <Wrapper isPie={isPie}>
      <Box id={guid} />
      {isPie && <Box><Box id={`legenddiv-${guid}`} /></Box>}
    </Wrapper>
  );
});

const Total = (props: Object) => {
  const { title, chartData } = props;

  return (
    <TotalWrapper>
      <Box fontSize={24}>{title}</Box>
      <Box fontSize={72} >{R.path(['data', 0, chartData.axesY], chartData)}</Box>
      <Box>
        {
          G.getWindowLocale(R.path([chartData.axesY], LC.AXIS_X_TITLES), '...')
        }
      </Box>
    </TotalWrapper>
  );
};

const ChartComponent = (props: Object) => {
  const { type } = props;

  if (R.equals(type, LC.CHART_TYPE_TOTAL)) return <Total {...props} />;

  return <ChartRenderer {...props} />;
};

export const Chart = sizeMe({ monitorHeight: true })(pure(ChartComponent));
