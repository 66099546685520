import * as R from 'ramda';
import React from 'react';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncGetTransportationModeGrouping } from '../../../../hocs';
// feature dispatch details new
import * as C from '../../constants';
import RowActions from '../components/row-actions';
import { vehicleTableSettings } from '../../components/damage-table';
import { orderTabs, orderListMap, tableSettings } from '../../settings/table-settings';
//////////////////////////////////////////////////


export const withTableGroupProps = compose(
  withProps(({ load, activeListName }: Object) => ({
    branchGuid: G.getBranchGuidFromObject(load),
    shouldNotGetTransportationModeList: G.notEquals(activeListName, C.ACTIVE_LIST_CUSTOMER_RATES),
  })),
  withAsyncGetTransportationModeGrouping,
  withHandlers({
    handleChangeActiveList: (props: Object) => (listName: string, activeItem: doolean) => {
      const { tableNumber, changeActiveList, toggleExpandedListsSuccess } = props;

      if (G.isFalse(activeItem)) {
        changeActiveList({ listName, tableNumber });
        toggleExpandedListsSuccess({ tableNumber, expanded: false });
      }
    },
    handleToggleExpandedList: ({ tableNumber, toggleExpandedListsSuccess }: Object) => () =>
      toggleExpandedListsSuccess({ tableNumber }),
    handleSelectTableEntity: (props: Object) => (guid: string, entity: Object) => {
      const {
        itemList,
        activeListName,
        selectOrderCustomerRateRequest,
        handleCheckModeGroupingAndShowConfirmMessage,
      } = props;

      const isAlreadySelect = R.compose(
        R.prop('selected'),
        R.find(R.pathEq(guid, [GC.FIELD_GUID])),
      )(itemList);

      if (isAlreadySelect) return;

      if (R.equals(activeListName, C.ACTIVE_LIST_CUSTOMER_RATES)) {
        const callback = (loadData: Object) => selectOrderCustomerRateRequest({ entity, loadData });

        return handleCheckModeGroupingAndShowConfirmMessage({
          callback,
          [GC.FIELD_MODE]: R.path([GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], entity),
        });
      }
    },
    handleToggle: (props: Object) => (row: Object) => {
      const { tableNumber, activeListName, toggleOrderVehicleItemRequest } = props;

      if (R.equals(activeListName, C.ACTIVE_LIST_VEHICLE_ITEMS)) {
        toggleOrderVehicleItemRequest(R.assoc('tableNumber', tableNumber, row));
      }
    },
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <RowActions
            entity={entity}
            load={props.load}
            events={props.events}
            itemList={props.itemList}
            openModal={props.openModal}
            closeModal={props.closeModal}
            itemsVolume={props.itemsVolume}
            loadConfigs={props.loadConfigs}
            loadDocuments={props.loadDocuments}
            activeListName={props.activeListName}
            loadTotalWeight={props.loadTotalWeight}
            closeFixedPopup={props.closeFixedPopup}
            loadTotalDistance={props.loadTotalDistance}
            transportationModeGroupingList={props.transportationModeGroupingList}
            getTransportationModeGroupingListSuccess={props.getTransportationModeGroupingListSuccess}
          />
        ),
      })
    ),
  }),
  withProps((props: Object) => {
    const {
      itemList,
      hiddenTabs,
      loadConfigs,
      handleToggle,
      activeListName,
      handleLoadMoreEntities,
      handleSelectTableEntity,
    } = props;

    const generateReport = (settings: Array) => R.compose(
      G.mapIndexed((item: string, index: number) => ({ sequence: index, [GC.FIELD_NAME]: item })),
      R.keys,
    )(settings);

    let tabs = R.filter(({ value }: Object) => G.isFalse(R.pathOr(false, [value], hiddenTabs)), orderTabs);

    if (R.equals(activeListName, C.ACTIVE_LIST_CLAIMS)) {
      const index = R.findIndex(R.propEq(C.ACTIVE_LIST_CLAIMS, GC.FIELD_VALUE), tabs);

      tabs = R.assocPath([index, 'withAddition'], G.isNilOrEmpty(itemList), tabs);
    }

    let columnSettings = R.path([activeListName, 'columnSettings'], orderListMap);

    if (G.isFunction(columnSettings)) {
      if (R.includes(activeListName, [C.ACTIVE_LIST_ITEMS, C.ACTIVE_LIST_VEHICLE_ITEMS])) {
        const keysWithEmptyValues = G.getKeysWithEmptyValues(itemList);

        columnSettings = columnSettings(keysWithEmptyValues);
      } else {
        columnSettings = columnSettings();
      }
    }

    const rowActionsPermissions = R.path([activeListName, 'rowActionsPermissions'], orderListMap);
    const report = { fields: generateReport(columnSettings) };
    const allowSelectItems = R.all(({ selected }: Object) => G.isNotNil(selected), itemList);

    const tableSettingsToUse = G.ifElse(
      R.equals(activeListName, C.ACTIVE_LIST_VEHICLE_ITEMS),
      R.assoc(
        'expandableItems',
        G.hasAmousCurrentUserPermissions([PC.ITEM_DAMAGE_READ, PC.ITEM_DAMAGE_WRITE]),
        vehicleTableSettings,
      ),
      tableSettings,
    );

    const tableSettingsWithSelectItemProp = R.assoc('allowSelectItems', allowSelectItems, tableSettingsToUse);

    const tableData = {
      report,
      columnSettings,
      withoutBorder: true,
      toggle: handleToggle,
      rowActionsPermissions,
      handleLoadMoreEntities,
      itemList: R.or(itemList, []),
      tableWrapperOverflow: 'none',
      onOptionClick: handleSelectTableEntity,
      tableSettings: tableSettingsWithSelectItemProp,
      callbackData: {
        loadConfigs,
        [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
      },
    };

    return { tabs, tableData };
  }),
  pure,
);
