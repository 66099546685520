import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner
import * as A from './actions';
import * as H from './helpers';
import { DELETED_TEL_GUIDS } from './constants';
//////////////////////////////////////////////////

export const handleRemoveTelFromPlanner = createLogic({
  type: A.removeTelFromPlanner,
  validate({ action, getState }: Object, allow: void, reject: void) {
    const state = getState();
    const isInDeletedList = R.compose(
      G.isNotNil,
      R.find(R.equals(action.payload)),
      R.pathOr([], ['dispatchPlannerEvents', 'currentRoute', DELETED_TEL_GUIDS]),
    )(state);
    if (isInDeletedList) return allow(action);
    const tel = R.path(['dispatchPlannerEvents', 'currentRoute', GC.SYSTEM_LIST_TELS, action.payload], state);
    const telEvents = R.values(R.prop(GC.FIELD_LOAD_STOPS, tel));
    const originalTelEvents = R.compose(
      R.path(['details', GC.FIELD_LOAD_STOPS]),
      R.find(R.eqProps(GC.FIELD_GUID, tel)),
      R.path(['dispatchPlannerEvents', 'telList']),
    )(state);
    const matchEvents = R.and(
      R.eqProps('length', telEvents, originalTelEvents),
      R.isEmpty(R.filter(
        (event: Object) => R.isNil(R.find(
          R.eqProps(GC.FIELD_GUID, event),
          telEvents,
        )),
        originalTelEvents,
      )),
    );
    if (R.not(matchEvents)) {
      G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('titles:can-not-unassign-modified-tel', 'Sorry, you can not unassign modified TEL'),
      );
      return reject();
    }
    allow(action);
  },
});

const isInvalidDistances = (events: Object) => G.isNotNilAndNotEmpty(
  events.find((event: Object, index: number) => (
    R.and(
      R.lt(index, R.dec(events.length)),
      G.isNilOrEmpty(event.telDistanceToNextStop),
    )
  )),
);

const validate = ({ currentRoute }: Object) => {
  let isValid = true;
  const errors = {};
  R.values(currentRoute.tels).forEach((tel: Object) => {
    const telEvents = R.sortBy(
      R.prop('telEventIndex'),
      R.values(tel[GC.FIELD_LOAD_STOPS]),
    );
    const eventsErrors = H.getEventsErrors(telEvents);
    const distanceError = isInvalidDistances(telEvents);
    const eventsCountError = R.lt(telEvents.length, 2);
    if (R.any(G.isTrue, [
      distanceError,
      eventsCountError,
      G.isNotEmpty(eventsErrors),
    ])) {
      errors[tel.guid] = {
        eventsErrors,
        distanceError,
        eventsCountError,
      };
    }
  });
  if (G.isNotEmpty(errors)) isValid = false;
  return { errors, isValid };
};

export const saveRoute = createLogic({
  type: A.validateRouteRequest,
  process({ action }: Object, dispatch: void, done: void) {
    const { payload } = action;

    const { errors, isValid } = validate(payload);

    if (R.not(isValid)) {
      dispatch(A.setRouteErrors(errors));
    } else {
      dispatch(A.setRouteErrors(null));

      if (G.isTrue(G.getPropFromObject('saveAsTemplate', payload))) {
        const { successAction } = payload;

        G.callFunction(successAction);
      } else {
        dispatch(A.savePlannerRequest(payload));
      }
    }

    done();
  },
});

export default [
  saveRoute,
  handleRemoveTelFromPlanner,
];
