import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Tabs2 } from '../../../../components/tabs-mui';
// features
import { startPivotTable } from '../../../pivot-table/actions';
import { makeSelectPivotTableTotalCount } from '../../../pivot-table/selectors';
import PivotTableWrapper from '../../../pivot-table/components/pivot-table-wrapper';
import {
  makeSelectAvailableReferenceTypesByScope,
  makeSelectAllAvailableReferenceTypesByScopeName,
} from '../../../reference/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch-report/load
import { getTabs } from '../helpers';
import { startTelByClo, startTelEvents } from '../actions';
//////////////////////////////////////////////////

const PivotLoad = (props: Object) => {
  const {
    activeTab,
    startTelByClo,
    startTelEvents,
    handleSetActiveTab,
    pivotTableTotalCount,
  } = props;

  const handleClickTab = (tabIndex: number) => {
    if (R.equals(tabIndex, 0)) G.goToRoute(GC.ROUTE_PATH_TEL_REPORT_LIST);

    if (R.equals(tabIndex, 1)) startTelByClo();

    if (R.equals(tabIndex, 2)) startTelEvents();

    handleSetActiveTab(tabIndex);
  };
  const customTitleComponent = (
    <Tabs2
      activeTab={activeTab}
      tabs={getTabs(activeTab)}
      count={pivotTableTotalCount}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}

    />
  );

  return (
    <PivotTableWrapper
      {...props}
      tabs={customTitleComponent}
      relativeFlexStyles={{ paddingTop: 15 }}
    />
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  refList: makeSelectAvailableReferenceTypesByScope(state),
  pivotTableTotalCount: makeSelectPivotTableTotalCount(state),
  allAvailableRefTypesByScopeName: makeSelectAllAvailableReferenceTypesByScopeName(state),
});

export default connect(mapStateToProps, {
  startTelByClo,
  startTelEvents,
  startPivotTable,
})(PivotLoad);
