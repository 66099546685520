import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import { loadColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
import { loadFilterProps, loadFilterPropsForTitleFilter } from '../../dispatch-report/settings/filter-params';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withWindowSize, withIsOpenedModalStatus } from '../../../hocs';
// report-common
import { generateDefaultReport } from '../../../report-common';
// ui
import { RelativeBox } from '../../../ui';
// feature dispatch-planner
import CustomTitle from './custom-title';
import { pageSizes } from '../settings/page-settings';
import { telTableSettings } from '../settings/table-settings';
import {
  cleanBuilder,
  setTelReports,
  getTelNextPage,
  addTelToPlanner,
  toggleTelDetails,
  cleanTelQuickFilter,
  setTelTableTitleSort,
  removeTelFromPlanner,
  createTelReportRequest,
  setTelTableTitleFilter,
  updateTelReportRequest,
  setGlobalFilterValueET,
  setTelQuickFilterParams,
  resetTelListAndPagination,
  setCurrentTelReportWithRoute,
  changeDefaultTelReportRequest,
} from '../actions';
import {
  makeSelectTelList,
  makeSelectTelTotal,
  makeSelectTelReport,
  makeSelectTelPagination,
  makeSelectTelListLoading,
  makeSelectTelFilterParams,
  makeSelectGlobalFilterValueET,
  makeSelectAvailableTelReports,
  makeSelectTelTableTitleFilters,
  makeSelectTelTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withWindowSize,
  withIsOpenedModalStatus,
  withHandlers({
    handleSetGlobalFilter: (props: Object) => (value: string) => {
      if (R.equals(props.globalFilterValue, value)) return;
      props.resetTelListAndPagination();
      props.setGlobalFilterValueET(value);
      props.getTelNextPage(true);
    },
    handleListRequest: ({ getTelNextPage }: Object) => (isInitial: boolean = false) =>
      getTelNextPage(isInitial),
    getQuickFilteredListRequest: (props: Object) => () => {
      props.resetTelListAndPagination();
      props.cleanBuilder();
      props.getTelNextPage();
    },
    handleSelectItem: (props: Object) => (guid: string, tel: Object) => {
      if (G.isTrue(tel.selected)) return props.removeTelFromPlanner(guid);
      props.addTelToPlanner(guid);
    },
    handleToggleDetails: (props: Object) => (tel: Object) => (
      props.toggleTelDetails(tel)
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        getTelNextPage,
        createTelReportRequest,
        updateTelReportRequest,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.TEL_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          setReport={setReport}
          requestPending={requestPending}
          onReportSet={() => getTelNextPage(true)}
          createReportRequest={createTelReportRequest}
          updateReportRequest={updateTelReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, 'guid'), props.reportList);
      props.setReport(selectedReport);
      props.getTelNextPage(true);
    },
    handleCleanFilter: (props: Object) => () => {
      props.cleanTelQuickFilter();
      props.cleanBuilder();
      props.getTelNextPage();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    getTelNextPage,
    selectedReport,
    titleSortValues,
    handleSelectItem,
    tableTitleFilters,
    handleToggleDetails,
    handleTableTitleFilter } = props;
  const allChecked = G.isAllChecked(itemList);
  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleSelectItem,
    tableSettings: R.mergeRight(telTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(loadFilterPropsForTitleFilter)),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getTelNextPage,
    ),
  };

  return <Table {...data} />;
};

const Loads = enhance((props: Object) => {
  const {
    totalCount,
    setTelReports,
    selectedReport,
    globalFilterValue,
    handleSetGlobalFilter,
    updateTelReportRequest,
  } = props;

  return (
    <RelativeBox minWidth={700}>
      <RelativeBox pr={15} zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          version={2}
          loading={false} // HACK: some UI error block title panel - remove after fix
          name='tel-report'
          type={GC.TEL_REPORT}
          hideFilterInfo={true}
          useExactFilters={true}
          withoutQuickFilter={true}
          setReports={setTelReports}
          usedReport={selectedReport}
          filterProps={loadFilterProps}
          hiddenRightFilterInfo={false}
          height={pageSizes.tableHeaderHeight}
          updateReportRequest={updateTelReportRequest}
          customTitleComponent={
            <CustomTitle
              count={totalCount}
              globalFilterValue={globalFilterValue}
              handleSetGlobalFilter={handleSetGlobalFilter}
              text={G.getWindowLocale('titles:existing-tels', 'Existing TELs')}
            />
          }
        />
      </RelativeBox>
      <RelativeBox zIndex={1}>
        {renderTable(props)}
      </RelativeBox>
    </RelativeBox>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  refList: () => [],
  noExportable: () => true,
  itemList: makeSelectTelList(state),
  totalCount: makeSelectTelTotal(state),
  loading: makeSelectTelListLoading(state),
  pagination: makeSelectTelPagination(state),
  selectedReport: makeSelectTelReport(state),
  filterParams: makeSelectTelFilterParams(state),
  reportList: makeSelectAvailableTelReports(state),
  globalFilterValue: makeSelectGlobalFilterValueET(state),
  tableTitleFilters: makeSelectTelTableTitleFilters(state),
  titleSortValues: makeSelectTelTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  cleanBuilder,
  setTelReports,
  getTelNextPage,
  addTelToPlanner,
  toggleTelDetails,
  cleanTelQuickFilter,
  removeTelFromPlanner,
  createTelReportRequest,
  updateTelReportRequest,
  setGlobalFilterValueET,
  resetTelListAndPagination,
  getItemListRequest: getTelNextPage,
  setTableTitleSort: setTelTableTitleSort,
  setReport: setCurrentTelReportWithRoute,
  setTableTitleFilter: setTelTableTitleFilter,
  setUsedReport: setCurrentTelReportWithRoute,
  setQuickFilterParams: setTelQuickFilterParams,
  resetListAndPagination: resetTelListAndPagination,
  changeDefaultReportRequest: changeDefaultTelReportRequest,
})(Loads);
