import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, withHandlers, lifecycle } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// features
import PC from '../../permission/role-permission';
// forms
import AccessorialForm from '../../../forms/forms/accessorial-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet
import PayrollAssessorialForm from './payroll-accessorial-form';
import {
  accessorialsSettings,
  payrollAssessorialsReport,
  vendorPayrollAssessorialReport,
  payrollAssessorialsTableSettings,
  getPayrollAssessorialsColumnSettings,
} from '../settings/column-settings';
//////////////////////////////////////////////////

const addAssessorialInfoToEntity = (entities: Array = [], assessorialConfigs: Array) => {
  if (G.isNilOrEmpty(assessorialConfigs)) return entities;

  const indexedConfigs = R.indexBy(
    (config: Object) => G.getParentGuidOrGuidFromObject(config),
    assessorialConfigs,
  );

  return entities.map((item: Object) => {
    const assessorial = R.path([item.assessorialGuid], indexedConfigs);

    return R.mergeRight(assessorial, item);
  });
};

const getAccessorialConfigs = (accessorialsConfigs: Object = []) => {
  const accessorialConfigList = R.compose(
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
    R.map((item: Object) => R.compose(
      R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
      R.pick([
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
        GC.FIELD_DISPLAYED_VALUE,
        GC.FIELD_CHARGE_RATE_TYPE,
        GC.FIELD_CHARGE_RATE_UNIT,
      ]),
    )(item)),
  )(accessorialsConfigs);
  const accessorialConfigOptions = R.map(({ displayedValue, originalConfigGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: originalConfigGuid,
  }), accessorialsConfigs);

  return { accessorialConfigList, accessorialConfigOptions };
};

const payrollAccessorialEnhance = compose(
  withHandlers({
    handleCreateOrUpdatePayrollAssessorial: (props: Object) => (requestMethod: string, entity: Object = {}) => {
      const {
        objKey,
        objGuid,
        openModal,
        closeModal,
        branchGuid,
        defaultCurrency,
        accessorialsConfigs,
        createOrUpdateAction,
      } = props;

      const payrollAccessorialTypeVendor = R.equals(objKey, GC.FIELD_FLEET_VENDOR_GUID);
      const initialValues = R.mergeRight({[objKey]: objGuid, [GC.FIELD_CURRENCY]: defaultCurrency}, entity);
      const accessorialConfigs = R.filter((item: Object) =>
        R.propEq(GC.FIELD_DRIVER_ACCESSORIAL_RATE_TYPE_FLAT, GC.FIELD_DRIVER_ACCESSORIAL_RATE_TYPE, item),
        accessorialsConfigs,
      );
      const accessorialOptions = R.map((item: Object) => (
        { label: R.prop(GC.FIELD_DISPLAYED_VALUE, item), value: G.getParentGuidOrGuidFromObject(item) }
      ), accessorialsConfigs);

      const component = (
        <PayrollAssessorialForm
          closeModal={closeModal}
          branchGuid={branchGuid}
          initialValues={initialValues}
          requestMethod={requestMethod}
          submitAction={createOrUpdateAction}
          isEdit={R.equals(requestMethod, 'put')}
          accessorialOptions={accessorialOptions}
          accessorialConfigs={accessorialConfigs}
          payrollAccessorialTypeVendor={payrollAccessorialTypeVendor}
        />
      );
      const title = G.ifElse(
        R.equals(requestMethod, 'post'),
        G.getWindowLocale('titles:add-payroll-accessorial', 'Add Payroll Accessorial'),
        G.getWindowLocale('titles:edit-payroll-accessorial', 'Edit Payroll Accessorial'),
      );
      const modal = {
        p: '15px',
        component,
        options: {
          title,
          width: '720px',
          overflow: 'auto',
          maxHeight: '87vh',
        },
      };
      openModal(modal);
    },
    handleRemovePayrollAccessorial: (props: Object) => (entity: Object) => {
      const { objKey, objGuid, openModal, removeAction } = props;

      const component = (
        <ConfirmComponent
          textLocale={
            G.getWindowLocale('messages:delete-payroll-accessorial', 'Are you sure you want delete Payroll Accessorial')
          }
        />
      );
      const requestData = {
        [objKey]: objGuid,
        [GC.FIELD_GUID]: R.prop(GC.FIELD_GUID, entity),
      };
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeAction(requestData),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const accessorialsEnhance = compose(
  withHandlers({
    handleAddAccessorial: (props: Object) => () => {
      const {
        objKey,
        objGuid,
        isVendor,
        openModal,
        closeModal,
        createAction,
        defaultCurrency,
        accessorialsConfigs,
      } = props;

      const { accessorialConfigList, accessorialConfigOptions } = getAccessorialConfigs(accessorialsConfigs);

      const initialValues = { [objKey]: objGuid, [GC.FIELD_CURRENCY]: defaultCurrency };

      const component = (
        <AccessorialForm
          isVendor={isVendor}
          closeModal={closeModal}
          isDriver={R.not(isVendor)}
          submitAction={createAction}
          initialValues={initialValues}
          accessorialConfigList={accessorialConfigList}
          accessorialConfigOptions={accessorialConfigOptions}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:add-accessorial', 'Add Accessorial'),
        },
      };
      openModal(modal);
    },
    handleEditAccessorial: (props: Object) => (entity: Object) => {
      const {
        objKey,
        objGuid,
        isVendor,
        openModal,
        closeModal,
        updateAction,
        accessorialsConfigs,
      } = props;

      const { accessorialConfigList, accessorialConfigOptions } = getAccessorialConfigs(accessorialsConfigs);

      const initialValues = R.assoc(objKey, objGuid, entity);

      const component = (
        <AccessorialForm
          isVendor={isVendor}
          closeModal={closeModal}
          isDriver={R.not(isVendor)}
          submitAction={updateAction}
          initialValues={initialValues}
          accessorialConfigList={accessorialConfigList}
          accessorialConfigOptions={accessorialConfigOptions}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:edit-accessorial', 'Edit Accessorial'),
        },
      };
      openModal(modal);
    },
    handleRemoveAccessorial: ({ openModal, removeAction }: Object) => ({ guid }: Object) => {
      const textLocale = G.getWindowLocale(
        'messages:fleet:driver:delete-assessorial',
        'Are you sure you want delete Assessorial?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeAction(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const compensationAccessorialsEnhance = compose(
  withState('accessorials', 'setAccessorials', []),
  withHandlers({
    createAction: (props: Object) => async (values: Object) => {
      const {
        closeModal,
        openLoader,
        closeLoader,
        accessorials,
        setAccessorials,
        compensationGuid } = props;

      G.callFunction(openLoader);
      const options = {
        data: R.assoc(GC.FIELD_COMPENSATION_GUID, compensationGuid, values),
      };

      const res = await sendRequest('post', endpointsMap.fleetVendorCompensationAccessorial, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        closeModal();
        setAccessorials(R.append(data, accessorials));
      } else {
        G.handleFailResponseSimple(res);
      }
      G.callFunction(closeLoader);
    },
    updateAction: (props: Object) => async (values: Object) => {
      const {
        closeModal,
        openLoader,
        closeLoader,
        accessorials,
        setAccessorials,
        compensationGuid,
      } = props;

      G.callFunction(openLoader);
      const options = {
        data: R.assoc(GC.FIELD_COMPENSATION_GUID, compensationGuid, values),
      };

      const res = await sendRequest('put', endpointsMap.fleetVendorCompensationAccessorial, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), accessorials);
        const newAccessorials = R.assocPath([index], data, accessorials);
        closeModal();
        setAccessorials(newAccessorials);
      } else {
        G.handleFailResponseSimple(res);
      }
      G.callFunction(closeLoader);
    },
    removeAction: (props: Object) => async (guid: Object) => {
      const {
        closeModal,
        openLoader,
        closeLoader,
        accessorials,
        setAccessorials } = props;

      G.callFunction(openLoader);
      const endpoint = endpointsMap.removeVendorCompensationAccessorial(guid);

      const res = await sendRequest('delete', endpoint);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), accessorials);
        const newAccessorials = R.dissocPath([index], accessorials);
        closeModal();
        setAccessorials(newAccessorials);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
    handleGetCompensationAccessorials: (props: Object) => async () => {
      const { openLoader, closeLoader, setAccessorials, compensationGuid } = props;

      G.callFunction(openLoader);
      const options = {
        params: { compensationGuid },
      };

      const res = await sendRequest('get', endpointsMap.fleetVendorCompensationAccessorialList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAccessorials(data);
      } else {
        G.handleFailResponseSimple(res);
      }
      G.callFunction(closeLoader);
    },

  }),
  accessorialsEnhance,
  lifecycle({
    componentDidMount() {
      this.props.handleGetCompensationAccessorials();
    },
  }),
);

export const PayrollAccessorialsFormGroupTable = payrollAccessorialEnhance((props: Object) => {
  const {
    objKey,
    accessorialsConfigs,
    handleToggleDetails,
    payrollAssessorialList,
    handleRemovePayrollAccessorial,
    handleCreateOrUpdatePayrollAssessorial,
  } = props;

  const isVendor = R.equals(objKey, GC.FIELD_FLEET_VENDOR_GUID);
  const deletePermission = G.ifElse(isVendor, PC.FLEET_VENDOR_WRITE, PC.FLEET_DRIVER_WRITE);
  const report = G.ifElse(isVendor, vendorPayrollAssessorialReport, payrollAssessorialsReport);

  return (
    <Box>
      <FormGroupTable
        entities={null}
        withoutArrowDown={true}
        handleAddClick={() => props.handleCreateOrUpdatePayrollAssessorial('post')}
        panelTitle={G.getWindowLocale('titles-payroll-accessorials', 'Payroll Accessorials')}
      />
      {
        G.isNotNilAndNotEmpty(payrollAssessorialList) &&
        <Table
          report={report}
          toggle={handleToggleDetails}
          itemList={addAssessorialInfoToEntity(payrollAssessorialList, accessorialsConfigs)}
          tableSettings={payrollAssessorialsTableSettings}
          columnSettings={getPayrollAssessorialsColumnSettings({
            deletePermission,
            handleDelete: handleRemovePayrollAccessorial,
            handleEdit: handleCreateOrUpdatePayrollAssessorial,
          })}
        />
      }
    </Box>
  );
});

export const AccessorialsFormGroupTable = accessorialsEnhance((props: Object) => (
  <FormGroupTable
    withoutArrowDown={true}
    entitiesFields={accessorialsSettings}
    handleAddClick={props.handleAddAccessorial}
    handleEditRow={props.handleEditAccessorial}
    handleDeleteRow={props.handleRemoveAccessorial}
    panelTitle={G.getWindowLocale('titles:accessorials', 'Accessorials')}
    entities={addAssessorialInfoToEntity(props.accessorials, props.accessorialsConfigs)}
  />
));

export const CompensationAccessorialsFormGroupTable = compensationAccessorialsEnhance(AccessorialsFormGroupTable);
