import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import { commissionAssignmentOptions } from '../../../helpers/options';
// forms
import { Fieldset2 } from '../../../forms';
// feature commission-assignment
import { fieldSettings, defaultFields, validationSchema } from '../settings/field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const CommissionAssignmentForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      fieldsWrapperStyles={{ mt: 10 }}
      commissionAssignmentOptions={commissionAssignmentOptions()}
    />
    <FormFooter2 />
  </form>
);

export default enhance(CommissionAssignmentForm);
