import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../../components/form-buttons';
import { FormGroupTitleComponent } from '../../../../components/form-group-title';
// helpers/constants
import * as G from '../../../../helpers';
// forms
import { Form, TabFormWrapper } from '../../../../forms';
// feature fleet/trailer
import { TrailerGroupWrapper } from '../ui';
import { renderField, renderFormGroupWithMultipleInputs } from './render-field';
import { refrigerationFields, mainSpecificationFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const renderGroup = (props: Object, group: Object) => (
  group.fields.map((field: Object, index: number) => {
    if (G.showFormField(field.showField, props.formValues)) {
      return G.ifElse(
        G.isNotNil(field.inputs),
        renderFormGroupWithMultipleInputs,
        renderField,
      )(props, field, index);
    }

    return null;
  })
);

const setFormGroup = ({ formValues }: Object) => (
  G.ifElse(
    G.isTrue(formValues.temperatureControl),
    [mainSpecificationFields, refrigerationFields],
    [mainSpecificationFields],
  )
);

const enhance = compose(
  withHandlers({
    handleFormSubmit: ({ updateTrailerRequest }: Object) => (values: Object) =>
      updateTrailerRequest(values),
  }),
  pure,
);

const TrailerSpecificationTabComponent = (props: Object) => (
  <TabFormWrapper>
    <Form minHeight='max-content' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
      {
        setFormGroup(props).map((group: Object, index: number) => (
          <TrailerGroupWrapper
            key={index}
            isOpened={R.path(['collapsedGroup', group.name], props)}
          >
            <FormGroupTitleComponent
              withArrowDown={true}
              text={G.getWindowLocale(group.title)}
              isOpened={R.path(['collapsedGroup', group.name], props)}
              onToggleFormGroup={() => props.handleToggleFormGroup(group.name)}
            />
            {renderGroup(props, group)}
          </TrailerGroupWrapper>
        ))
      }
      <FormButtons
        {...props}
        zIndex={20}
        width='auto'
        btnText={G.getWindowLocale('actions:update', 'Update')}
      />
    </Form>
  </TabFormWrapper>
);

export default enhance(TrailerSpecificationTabComponent);
