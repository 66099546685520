import * as R from 'ramda';
import { useState, useEffect } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const useFleetProfileTabsView = () => {
  const COLLAPSED = 'COLLAPSED';

  const [tabsView, setTabsView] = useState(COLLAPSED);

  const tabsCollapsedView = R.equals(tabsView, COLLAPSED);

  useEffect(() => {
    const fetchSettings = async () => {
      const setting = await G.getIndexedDBItem(GC.AmousUIIdbProps, GC.fleetProfileTabsViewIdbId);

      if (setting) {
        const { value } = setting;

        setTabsView(value);
      }
    };

    fetchSettings();
  }, []);

  return { setTabsView, tabsCollapsedView };
};
