import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../../components/text';
import { DownloadWithLabel } from '../../../../components/download-with-label';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Flex } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature fleet/vendor
import { downloadVendorDocumentRequest } from '../actions';
//////////////////////////////////////////////////

export const documentColumnSettings = {
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    width: 150,
    name: 'titles:type',
  },
  [GC.FIELD_STATUS]: {
    width: 150,
    name: 'titles:status',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 300,
    name: 'titles:description',
  },
  [GC.FIELD_DOCUMENT_SIGNED_DATE]: {
    width: 150,
    name: 'titles:signed-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 150,
    name: 'titles:expired-date',
  },
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 200,
    name: 'titles:file',
    label: (doc: Object) =>
      <DownloadWithLabel icon='pdf' entity={doc} downloadAction={downloadVendorDocumentRequest} />,
  },
  [GC.FIELD_DOCUMENT_URL]: {
    width: 250,
    name: 'titles:url',
    label: ({ url }: Object) =>
      <a target='_blank' href={G.makeURLString(url)}>{url}</a>, // eslint-disable-line
  },
  [GC.FIELD_ATTACHABLE_TO_CUSTOMER_INVOICE]: {
    width: 150,
    name: 'titles:attachable-to-customer-invoice',
  },
};

const fleetAssignmentTextStyles = {
  maxWidth: 250,
  display: 'block',
  cursor: 'pointer',
  withEllipsis: true,
  color: G.getTheme('colors.light.blue'),
};

export const fleetAssignmentColumnSettings = {
  [GC.FIELD_PRIMARY_DRIVER]: {
    width: 250,
    name: 'titles:primary-driver',
    customLogic: (entity: Object) => {
      const { fullText } = G.getUserInfo(entity);

      const guid = G.getGuidFromObject(entity);

      return (
        <TextComponent
          {...fleetAssignmentTextStyles}
          title={fullText}
          onClick={() => G.goToRoute(routesMap[G.getFleetProfileRoutePathNameByFleetType()](guid))}
        >
          {fullText}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_SECONDARY_DRIVER]: {
    width: 250,
    name: 'titles:secondary-driver',
    customLogic: (entity: Object) => {
      const { fullText } = G.getUserInfo(entity);

      const guid = G.getGuidFromObject(entity);

      return (
        <TextComponent
          {...fleetAssignmentTextStyles}
          title={fullText}
          onClick={() => G.goToRoute(routesMap[G.getFleetProfileRoutePathNameByFleetType()](guid))}
        >
          {fullText}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_TRUCK]: {
    width: 250,
    name: 'titles:truck',
    customLogic: ({ guid, unitId }: Object) => (
      <TextComponent
        {...fleetAssignmentTextStyles}
        title={unitId}
        onClick={() => G.goToRoute(routesMap.editTruckPage(guid))}
      >
        {unitId}
      </TextComponent>
    ),
  },
  [GC.FIELD_TRAILERS]: {
    width: 400,
    name: 'titles:trailers',
    customLogic: (trailers: Array = []) => (
      <Flex flexWrap='wrap'>
        {
          trailers.map(({ guid, unitId }: Object, index: number) => (
            <TextComponent
              {...fleetAssignmentTextStyles}
              mr={10}
              key={guid}
              title={unitId}
              onClick={() => G.goToRoute(routesMap.editTrailerPage(guid))}
            >
              {unitId}{R.and(R.lt(R.inc(index), R.length(trailers)), ',')}
            </TextComponent>
          ))
        }
      </Flex>
    ),
  },
};
