import * as R from 'ramda';
import FileDownload from 'js-file-download';
import {
  put,
  all,
  call,
  take,
  fork,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { ROOT_BRANCH_GUID } from '../branch/constants';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectExpandedContainerOpened } from '../expanded-container/selectors';
import { getSequencesByTypeRequest, getSequencesByTypeSuccess } from '../sequence/actions';
import {
  getAllAvailableRefTypesByScopeRequest,
  getAllAvailableRefTypesByScopeRequestSuccess,
} from '../reference/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { getCarrierRateIntegrationOptions } from '../../helpers/options';
// report-common
import { generateDefaultReport } from '../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature carrier-profile
import * as A from './actions';
import * as H from './helpers';
import {
  makeSelectCarrierGuid,
  makeSelectGeneralDetails,
  makeSelectCarrierBranchGuid,
  makeSelectCarrierContractGuid,
  makeSelectContractPageVisited,
  makeSelectPaginationByGroupName,
  makeSelectUsedReportByGroupName,
  makeSelectTitleSortValuesByGroupName,
  makeSelectAvailableReportsByGroupName,
  makeSelectTableTitleFiltersByGroupName,
} from './selectors';
//////////////////////////////////////////////////

// common
function* getGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getCarrierEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getGeneralDetailsRequest exception');
  }
}

function* updateGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, sendPackage, successCallback } = payload;

    const { name, usDotNumber, addedToWatchList } = values;

    if (R.and(G.isNilOrEmpty(usDotNumber), addedToWatchList)) {
      const message = `${G.getWindowLocale('titles:carrier', 'Carrier')} ${name} ${
        G.getWindowLocale('messages:removed-from-watch-list')
      }`;

      yield call(G.showToastrMessage, 'info', message);
    }

    const options = {
      data: values,
    };

    const res = yield call(sendRequest, 'put', endpointsMap.carrier, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));


      if (sendPackage) {
        yield put(A.sendOnboardingPackageRequest(G.getGuidFromObject(data)));
      }

      if (R.propEq(false, GC.FIELD_ACTIVE, data)) {
        yield put(A.getLoadBoardIntegrationListRequest());
      }

      G.callFunction(successCallback);
    } else {
      yield call(G.handleFailResponse, res, 'updateGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    G.callFunction(payload.failCallback);

    yield put(closeLoader());

    yield call(G.handleException, error, 'updateGeneralDetailsRequest exception');
  }
}

function* createOrUpdateEntityRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const {
      values,
      method,
      endpoint,
      groupName,
      requestPayload,
      getItemListRequest,
      additionalOptions = {},
    } = payload;

    const { failCallback, successCallback, shouldCloseModal = true } = additionalOptions;

    const options = G.ifElse(G.isNotNilAndNotEmpty(requestPayload), requestPayload, { data: values });

    const res = yield call(sendRequest, method, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (shouldCloseModal) yield put(closeModal());

      if (G.isFunction(successCallback)) successCallback();

      if (G.isFunction(getItemListRequest)) {
        getItemListRequest();
      } else {
        yield put(A.createOrUpdateEntitySuccess({
          groupName,
          data: G.ifElse(G.isArray(data), R.head(data), data),
        }));
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateEntityRequest fail');

      if (G.isFunction(failCallback)) failCallback();
    }

    yield put(closeLoader());
  } catch (error) {
    if (G.isFunction(payload.failCallback)) payload.failCallback();

    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateEntityRequest exception');
  }
}

function* removeEntityRequest({ payload }: Object) {
  const {
    guid,
    endpoint,
    isReport,
    groupName,
    requestOptions,
    method = 'delete',
    getItemListRequest,
  } = payload;

  try {
    yield put(openLoader());

    if (isReport) yield put(A.setListLoading({ groupName, loading: true }));

    const res = yield call(sendRequest, method, endpoint, requestOptions);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (G.isFunction(getItemListRequest)) {
        getItemListRequest();
      } else {
        yield put(A.removeEntitySuccess({ guid, isReport, groupName }));
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeEntityRequest fail');
    }

    yield put(closeLoader());

    if (isReport) yield put(A.setListLoading({ groupName, loading: false }));
  } catch (error) {
    yield put(closeLoader());

    if (isReport) yield put(A.setListLoading({ groupName, loading: false }));

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeEntityRequest exception');
  }
}

function* sendOnboardingPackageRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_CARRIER_GUID]: payload },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.sendOnboardingPackage, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'sendOnboardingPackageRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'sendOnboardingPackageRequest exception');
  }
}

// report
function* getReportItemListRequest({ payload }: Object) {
  try {
    const { endpoint, groupName, reqParams, reportType } = payload;

    yield put(A.setListLoading({ groupName, loading: true }));

    const reportParams = yield select(makeSelectUsedReportByGroupName(groupName));
    const availableReports = yield select(makeSelectAvailableReportsByGroupName(groupName));

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.pathEq(`${reportType}Default`, [GC.FIELD_GUID], reportParams),
    )) return yield put(A.setListLoading({ groupName, loading: false }));

    const pagination = yield select(makeSelectPaginationByGroupName(groupName));
    const titleOrderFields = yield select(makeSelectTitleSortValuesByGroupName(groupName));
    const tableTitleFilters = yield select(makeSelectTableTitleFiltersByGroupName(groupName));

    const searchCriteria = G.ifElse(
      G.isNotEmpty(tableTitleFilters),
      R.values(tableTitleFilters),
      R.pathOr([], ['searchCriteria'], reportParams),
    );

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      R.pathOr([], ['orderFields'], reportParams),
    );

    const { params, reportFieldsToOmit, ...reqPayload } = reqParams;

    const reportFields = R.pathOr([], ['fields'], reportParams);

    const reqData = {
      ...reqPayload,
      ...pagination,
      orderFields,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria, reportType),
      fields: G.isAnyNilOrEmpty([reportFields, reportFieldsToOmit])
        ? reportFields
        : R.reject(({ name }: Object) => R.includes(name, reportFieldsToOmit), reportFields),
    };

    const res = yield call(sendRequest, 'post', endpoint, { params, data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getReportItemListSuccess({ data, groupName }));
    } else {
      yield call(G.handleFailResponse, res, 'getReportItemListRequest fail');
    }

    yield put(A.setListLoading({ groupName, loading: false }));
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getReportItemListRequest exception');
  }
}

function* getAvailableReportsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { callback, groupName, reportType, setUsedReport } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);

      yield put(A.setReports({ groupName, reports: checkedReports }));

      if (R.not(R.prop('length', reports))) {
        yield put(closeLoader());

        return yield put(A.setUsedReport({ groupName, usedReport: generateDefaultReport(reportType) }));
      }

      if (G.isTrue(setUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(reportType));

        yield put(A.setUsedReport({ groupName, usedReport }));
      }

      G.callFunction(callback);
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableReportsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAvailableReportsRequest exception');
  }
}

function* createReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { groupName, newReport, reportType, getItemListRequest } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newReport),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, data)));

      yield put(A.setUsedReport({ groupName, usedReport: G.getReportSortedBySeqFreez(report) }));

      yield put(A.getAvailableReportsRequest({
        groupName,
        reportType,
        setUsedReport: false,
        callback: getItemListRequest,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'createReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createReportRequest exception');
  }
}

function* updateReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { groupName, newReport, reportType, getItemListRequest } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: newReport });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, data)));

      yield put(A.setUsedReport({ groupName, usedReport: G.getReportSortedBySeqFreez(report) }));

      yield put(A.getAvailableReportsRequest({
        groupName,
        reportType,
        setUsedReport: false,
        callback: getItemListRequest,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'updateReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateReportRequest exception');
  }
}

function* changeDefaultReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { data, groupName, reportType, getItemListRequest } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination({ groupName }));

      yield put(A.getAvailableReportsRequest({
        groupName,
        reportType,
        setUsedReport: false,
        callback: getItemListRequest,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'changeDefaultReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeDefaultReportRequest exception');
  }
}

// configs
function* getCarrierProfileConfigsRequest({ payload }: Object) {
  try {
    const { group, names, callback, branchGuid } = payload;

    const carrierBranchGuid = yield select(makeSelectCarrierBranchGuid());

    const options = {
      params: {
        group,
        names: G.isArray(names) ? R.join(',', names) : '',
        [GC.BRANCH_GUID]: R.or(branchGuid, carrierBranchGuid),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const configsByNames = G.mapConfigValuesByName(data);

      yield put(A.getCarrierProfileConfigsSuccess(configsByNames));

      if (G.isFunction(callback)) callback(H.makeDropdownOptions(R.propOr([], 'dropdowns', configsByNames)));
    } else {
      yield call(G.handleException, 'error', 'getCarrierProfileConfigsRequest');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getCarrierProfileConfigsRequest');
  }
}

function* getDispatchConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierIntegrationDispatchConfigList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const getIntegrationName = (integrationType: string) => R.compose(
        R.propOr('', GC.FIELD_LABEL),
        R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
      )(getCarrierRateIntegrationOptions());

      const mappedDispatchConfigs = R.map((item: Object) => {
        const { guid, name, integrationType, supportDispatch, supportStatusCheck } = item;

        const integrationName = getIntegrationName(integrationType);

        const label = G.ifElse(G.isNilOrEmpty(name), integrationName, `${name} (${integrationName})`);

        return { label, supportDispatch, supportStatusCheck, [GC.FIELD_VALUE]: guid };
      }, data);

      yield put(A.getDispatchConfigListSuccess(mappedDispatchConfigs));
    } else {
      yield call(G.handleException, 'error', 'getDispatchConfigListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, 'error', 'getDispatchConfigListRequest exception');
  }
}

function* getCarrierOnboardingPackageRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierOnboardingPackageList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierOnboardingPackageSuccess(G.isNotNilAndNotEmpty(data)));
    } else {
      yield call(G.handleException, 'error', 'getCarrierOnboardingPackageRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierOnboardingPackageRequest exception');
  }
}

function* getAccessorialConfigListRequest({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    const branchGuidToUse = R.or(branchGuid, payload);

    if (R.isNil(branchGuidToUse)) return;

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuidToUse },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.accessorialsListEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAccessorialConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAccessorialConfigListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAccessorialConfigListRequest exception');
  }
}

function* getSharedAccessorialsListRequest({ payload }: Object) {
  try {
    const { callback, branchGuid } = payload;

    const carrierBranchGuid = yield select(makeSelectCarrierBranchGuid());

    const branchGuidToUse = R.or(branchGuid, carrierBranchGuid);

    if (R.isNil(branchGuidToUse)) return;

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuidToUse },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.sharedAccessorialGuidNameMap, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSharedAccessorialsListSuccess(data));

      G.callFunctionWithArgs(callback, data);
    } else {
      yield call(G.handleFailResponse, res, 'getSharedAccessorialsListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getSharedAccessorialsListRequest exception');
  }
}

function* getAvailableDocumentTypesRequest() {
  try {
    // NOTE: Replace with carrier branch guid if needed
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableDocumentTypes,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableDocumentTypesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableDocumentTypesRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAvailableDocumentTypesRequest exception');
  }
}

function* getReferenceTypesByScopeForOptionsRequest() {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    yield put(getAllAvailableRefTypesByScopeRequest({ [GC.FIELD_BRANCH_GUID]: branchGuid, scopeName: 'carrier'}));

    const res = yield take(getAllAvailableRefTypesByScopeRequestSuccess);

    const { payload } = res;

    yield put(A.getReferenceTypesByScopeForOptionsSuccess(payload));
  } catch (error) {
    yield call(G.handleException, error, 'getReferenceTypesByScopeForOptionsSaga exception');
  }
}

function* getSequencesByScopeForOptionsRequest() {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    yield put(getSequencesByTypeRequest({ branchGuid, type: GC.SEQUENCE_TYPE_CARRIER}));

    const res = yield take(getSequencesByTypeSuccess);

    const { payload } = res;

    yield put(A.getSequencesByScopeForOptionsSuccess(payload));
  } catch (error) {
    yield call(G.handleException, error, 'getSequencesByScopeForOptionsSaga exception');
  }
}

export function* getBranchListByBranchTypeRequest({ payload = GC.BRANCH_TYPE_ENUM_DIVISION }: Object) {
  try {
    // NOTE: Replace with carrier branch guid if needed
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_TYPE]: payload,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getBranchListByBranchTypeSuccess,
      parentSagaName: 'getBranchListByBranchTypeRequest',
      endpoint: endpointsMap.getBranchListByType(branchGuid),
      mapper: (branchList: Array) => ({ branchList, branchType: payload }),
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCustomerBranchListRequest exception');
  }
}

function* getParentBranchGuidsByCurrentBranchGuidRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const endpoint = endpointsMap.getParentBranchGuidsByBranchGuid(currentBranchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const branchGuids = R.without([ROOT_BRANCH_GUID], R.prepend(currentBranchGuid, R.or(data, [])));

      yield put(A.getParentBranchGuidsByCurrentBranchGuidSuccess(branchGuids));
    } else {
      yield call(G.handleFailResponse, res, 'getParentBranchGuidsByCurrentBranchGuidRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, 'getParentBranchGuidsByCurrentBranchGuidRequest exception');
  }
}

// mails
function* downloadCarrierMailRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.downloadCarrierMail(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      FileDownload(data, 'mail.eml');

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'downloadCarrierMailRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'downloadCarrierMailRequest exception');
  }
}

// synchronization
function* getLoadBoardIntegrationListRequest() {
  try {
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        carrierGuid,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierLoadBoardIntegrationList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadBoardIntegrationListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getLoadBoardIntegrationListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, 'error', 'getLoadBoardIntegrationListRequest exception');
  }
}

function* allowOrForbidBookItNowCarrierRequest({ payload }: Object) {
  try {
    const { source, carrierGuid, allowedBookItNow } = payload;

    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.of(Array, carrierGuid),
      params: { source, [GC.BRANCH_GUID]: currentBranchGuid },
    };

    const endpoint = R.prop(G.ifElse(allowedBookItNow, 'forbidBookItNow', 'allowBookItNow'), endpointsMap);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data)) {
        const message = R.compose(
          R.head,
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', message);
      } else {
        yield call(getLoadBoardIntegrationListRequest);

        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'allowOrForbidBookItNowCarrierRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'allowOrForbidBookItNowCarrierRequest exception');
  }
}

function* addOrRemoveToNetworkCarrierRequest({ payload }: Object) {
  try {
    const { source, carrierGuid, addedToNetwork } = payload;

    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.of(Array, carrierGuid),
      params: { source, [GC.BRANCH_GUID]: currentBranchGuid },
    };

    const endpoint = R.prop(G.ifElse(addedToNetwork, 'removeFromNetwork', 'addToNetwork'), endpointsMap);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data)) {
        const message = R.compose(
          R.head,
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', message);
      } else {
        yield call(getLoadBoardIntegrationListRequest);

        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'addOrRemoveToNetworkCarrierRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'addOrRemoveToNetworkCarrierRequest exception');
  }
}

function* createWatchRequest({ payload }: Object) {
  try {
    const { watch, usDotNumber } = payload;

    const carrierGuid = yield select(makeSelectCarrierGuid());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    let method = 'post';
    let params = { usDotNumber, [GC.BRANCH_GUID]: branchGuid };
    let endpointName = G.ifElse(watch, 'addToWatchList', 'removeFromWatchList');

    if (G.showCarrierSynchronization()) {
      method = 'put';
      params = { carrierGuid };

      endpointName = G.ifElse(
        watch,
        'carrierSynchronizationAddToWatchList',
        'carrierSynchronizationRemoveFromWatchList',
      );
    }

    if (R.and(watch, G.isNilOrEmpty(usDotNumber))) {
      return yield call(G.showToastrMessage, 'error', 'validation:usdot-number');
    }

    yield put(openLoader());

    const endpoint = R.prop(endpointName, endpointsMap);

    const options = { params };

    const res = yield call(sendRequest, method, endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createWatchSuccess(watch));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createWatchRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'createWatchRequest exception');
  }
}

function* getCarrierSafetyInfoRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_CARRIER_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierSafety, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, groupName: 'safety' }));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierSafetyInfoRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, 'getCarrierSafetyInfoRequest exception');
  }
}

function* getCarrierInsuranceListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_CARRIER_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierInsuranceList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, groupName: 'insurance' }));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierInsuranceListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getCarrierInsuranceListRequest exception');
  }
}

function* syncWithIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const values = yield select(makeSelectGeneralDetails());

    if (G.notEquals(values, payload)) yield call(updateGeneralDetailsRequest, { payload: { values: payload } });

    const carrierGuid = G.getGuidFromObject(values);

    const endpoint = G.ifElse(
      G.showCarrierSynchronization(),
      endpointsMap.syncCarrier,
      endpointsMap.carrierSaferWatch,
    );

    const options = {
      params: { carrierGuid },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.error)) {
        yield put(closeLoader());

        return yield call(G.showToastrMessage, 'error', data.error);
      }

      yield all([
        put(A.getGeneralDetailsSuccess(R.mergeRight(values, data))),
        call(getCarrierSafetyInfoRequest, { payload: carrierGuid }),
        call(getCarrierInsuranceListRequest, { payload: carrierGuid }),
      ]);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'syncWithIntegrationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'syncWithIntegrationRequest exception');
  }
}

function* getAvailableForCarrierLoadBoardsRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.availableForCarrierLoadBoards, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableForCarrierLoadBoardsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getAvailableForCarrierLoadBoardsRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, 'error', 'getAvailableForCarrierLoadBoardsRequest exception');
  }
}

// contracts
function* getContractGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, type } = payload;

    const endpointName = G.ifElse(
      R.equals(type, 'carrierContract'),
      'getCarrierContract',
      'getCustomerContract',
    );

    const endpoint = endpointsMap[endpointName](guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getContractGeneralDetailsSuccess({ data, type }));
    } else {
      yield call(G.handleFailResponse, res, 'getContractGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getContractGeneralDetailsRequest exception');
  }
}

function* updateContractGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { type, values, successCallback } = payload;

    const endpoint = R.prop(type, endpointsMap);

    const res = yield call(sendRequest, 'put', endpoint, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getContractGeneralDetailsSuccess({ data, type }));

      G.callFunction(successCallback);
    } else {
      G.callFunction(payload.failCallback);

      yield call(G.handleFailResponse, res, 'getContractGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    G.callFunction(payload.failCallback);

    yield call(G.handleException, error, 'getContractGeneralDetailsRequest exception');
  }
}

function* carrierRatePriceExportRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      resType: 'arraybuffer',
      params: { contractGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.exportRatePrice, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'carrierRatePriceExportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'carrierRatePriceExportRequest exception');
  }
}

function* getAvailableCarrierIntegrationsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.BRANCH_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.availableCarrierIntegrationList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const carrierIntegrationOptions = R.map(({ guid, name, integrationType }: Object) => {
        const integrationTypeDisplayedValue = R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(getCarrierRateIntegrationOptions());

        const label = G.ifElse(
          G.isNilOrEmpty(name),
          integrationTypeDisplayedValue,
          `${name} (${integrationTypeDisplayedValue})`,
        );

        return { label, [GC.FIELD_VALUE]: guid };
      }, R.or(data, []));

      yield put(A.getAvailableCarrierIntegrationsSuccess(carrierIntegrationOptions));
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableCarrierIntegrationsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getAvailableCarrierIntegrationsRequest exception');
  }
}

function* getAvailableGeoFencingZoneListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.BRANCH_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.availableGeoFencingZone, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableGeoFencingZoneListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableGeoFencingZoneListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getAvailableGeoFencingZoneListRequest exception');
  }
}

function* massDeleteCarrierContractRatePricesRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const contractGuid = yield select(makeSelectCarrierContractGuid());
    const carrierBranchGuid = yield select(makeSelectCarrierBranchGuid());

    const options = { data: payload };

    const res = yield call(sendRequest, 'delete', endpointsMap.deleteCarrierRatePrices, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const groupName = 'carrierContract.ratePrice';

      yield put(closeModal());
      yield put(A.resetListAndPagination({ groupName }));

      yield put(A.getReportItemListRequest({
        groupName,
        endpoint: endpointsMap.carrierRatePriceList,
        reportType: GC.CARRIER_CONTRACT_RATE_PRICE_REPORT,
        reqParams: {
          params: { contractGuid },
          [GC.CURRENT_BRANCH]: carrierBranchGuid,
        },
      }));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeCarrierContractRatesRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeCarrierContractRatesRequest exeption');
  }
}

// visit pages
function* visitCarrierProfilePage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_PROFILE_DRIVER_PAGE);

    const contractPageVisited = yield select(makeSelectContractPageVisited());

    yield put(A.setInitialState({}));
    yield put(A.setActiveRatingEngineTab(contractPageVisited));

    let generalDetails = yield select(makeSelectGeneralDetails());

    if (G.isNilOrEmpty(generalDetails)) {
      yield fork(getGeneralDetailsRequest, { payload: G.getGuidFromObject(payload) });

      const action = yield take(A.getGeneralDetailsSuccess);

      generalDetails = action.payload;
    }

    const branchGuid = G.getBranchGuidFromObject(generalDetails);

    if (G.isFalse(contractPageVisited)) {
      yield all([
        call(getCarrierOnboardingPackageRequest),
        call(getAvailableForCarrierLoadBoardsRequest),
        call(getParentBranchGuidsByCurrentBranchGuidRequest),
      ]);
    }

    yield put(A.getCarrierProfileConfigsRequest({
      [GC.BRANCH_GUID]: branchGuid,
      names: [
        GC.GENERAL_EQUIPMENTS,
        GC.CARRIER_DOCUMENT_TYPE,
        GC.CARRIER_DEACTIVATED_TYPE,
        GC.GENERAL_MODE_TRANSPORTATION,
        GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
      ],
    }));

    break;
  }
}

function* visitCarrierContractDetailsPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CARRIER_CONTRACT_DETAILS_PAGE);

    yield put(A.setInitialState({ type: 'carrierContract' }));

    const { guid } = payload;

    yield fork(getContractGeneralDetailsRequest, { payload: { guid, type: 'carrierContract' } });

    const contractGeneralDetails = yield take(A.getContractGeneralDetailsSuccess);

    const carrierGuid = R.path(['payload', 'data', GC.FIELD_CARRIER_GUID], contractGeneralDetails);

    let carrier = yield select(makeSelectGeneralDetails());

    if (G.isNilOrEmpty(carrier)) {
      yield fork(getGeneralDetailsRequest, { payload: carrierGuid });

      const { payload } = yield take(A.getGeneralDetailsSuccess);

      carrier = payload;
    }

    const branchGuid = G.getBranchGuidFromObject(carrier);

    yield put(A.getCarrierProfileConfigsRequest({
      [GC.BRANCH_GUID]: branchGuid,
      names: [
        GC.GENERAL_EQUIPMENTS,
        GC.TEMPLATES_LOCATION_TYPE,
        GC.GENERAL_MODE_TRANSPORTATION,
        GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
        GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
      ],
    }));

    yield call(getAvailableGeoFencingZoneListRequest, { payload: branchGuid });
    yield call(getAvailableCarrierIntegrationsRequest, { payload: branchGuid });

    break;
  }
}

function* visitCustomerContractDetailsPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE);

    yield put(A.setInitialState({ type: 'customerContract' }));

    const { guid } = payload;

    yield fork(getContractGeneralDetailsRequest, { payload: { guid, type: 'customerContract' } });

    const contractGeneralDetails = yield take(A.getContractGeneralDetailsSuccess);

    const carrierGuid = R.path(['payload', 'data', GC.FIELD_CARRIER_GUID], contractGeneralDetails);

    let carrier = yield select(makeSelectGeneralDetails());

    if (G.isNilOrEmpty(carrier)) {
      yield fork(getGeneralDetailsRequest, { payload: carrierGuid });

      const { payload } = yield take(A.getGeneralDetailsSuccess);

      carrier = payload;
    }

    const branchGuid = G.getBranchGuidFromObject(carrier);

    yield put(A.getCarrierProfileConfigsRequest({
      [GC.BRANCH_GUID]: branchGuid,
      names: [
        GC.TEMPLATES_LOCATION_TYPE,
        GC.GENERAL_MODE_TRANSPORTATION,
        GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
      ],
    }));

    break;
  }
}

export function* carrierProfileWatcherSaga() {
  // visit page
  yield takeLatest(GC.VISIT_CARRIER_PROFILE_PAGE, visitCarrierProfilePage);
  yield takeLatest(GC.VISIT_CARRIER_CONTRACT_DETAILS_PAGE, visitCarrierContractDetailsPage);
  yield takeLatest(GC.VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE, visitCustomerContractDetailsPage);
  // common
  yield takeLatest(A.removeEntityRequest, removeEntityRequest);
  yield takeLatest(A.getGeneralDetailsRequest, getGeneralDetailsRequest);
  yield takeLatest(A.updateGeneralDetailsRequest, updateGeneralDetailsRequest);
  yield takeLatest(A.createOrUpdateEntityRequest, createOrUpdateEntityRequest);
  yield takeLatest(A.sendOnboardingPackageRequest, sendOnboardingPackageRequest);
  // report
  yield takeLatest(A.createReportRequest, createReportRequest);
  yield takeLatest(A.updateReportRequest, updateReportRequest);
  yield takeEvery(A.getReportItemListRequest, getReportItemListRequest);
  yield takeEvery(A.getAvailableReportsRequest, getAvailableReportsRequest);
  yield takeLatest(A.changeDefaultReportRequest, changeDefaultReportRequest);
  // configs
  yield takeLatest(A.getDispatchConfigListRequest, getDispatchConfigListRequest);
  yield takeLatest(A.getCarrierProfileConfigsRequest, getCarrierProfileConfigsRequest);
  yield takeLatest(A.getAccessorialConfigListRequest, getAccessorialConfigListRequest);
  yield takeLatest(A.getAvailableDocumentTypesRequest, getAvailableDocumentTypesRequest);
  yield takeLatest(A.getSharedAccessorialsListRequest, getSharedAccessorialsListRequest);
  // reference types
  yield takeLatest(A.getReferenceTypesByScopeForOptionsRequest, getReferenceTypesByScopeForOptionsRequest);
  // sequences
  yield takeLatest(A.getSequencesByScopeForOptionsRequest, getSequencesByScopeForOptionsRequest);
  // branches
  yield takeLatest(A.getBranchListByBranchTypeRequest, getBranchListByBranchTypeRequest);
  // mails
  yield takeLatest(A.downloadCarrierMailRequest, downloadCarrierMailRequest);
  // synchronization
  yield takeLatest(A.createWatchRequest, createWatchRequest);
  yield takeLatest(A.syncWithIntegrationRequest, syncWithIntegrationRequest);
  yield takeLatest(A.getLoadBoardIntegrationListRequest, getLoadBoardIntegrationListRequest);
  yield takeLatest(A.addOrRemoveToNetworkCarrierRequest, addOrRemoveToNetworkCarrierRequest);
  yield takeLatest(A.allowOrForbidBookItNowCarrierRequest, allowOrForbidBookItNowCarrierRequest);
  // contracts
  yield takeLatest(A.carrierRatePriceExportRequest, carrierRatePriceExportRequest);
  yield takeLatest(A.updateContractGeneralDetailsRequest, updateContractGeneralDetailsRequest);
  yield takeLatest(A.massDeleteCarrierContractRatePricesRequest, massDeleteCarrierContractRatePricesRequest);
}

export default carrierProfileWatcherSaga;
