import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { openModal, closeModal, closeAllModals } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, StickedBox, CustomButton } from '../../../ui';
// feature dispatch-planner
import withSaveCancelRoute from '../hocs/with-save-cancel-route';
import {
  cleanBuilder,
  validateRouteRequest,
  saveDraftRouteRequest,
  setCurrentRouteInitialState } from '../actions';
import {
  makeSelectEventsInfo,
  makeSelectTotalWeight,
  makeSelectRouteConfigs,
  makeSelectCurrentRoute,
  makeSelectTotalDistance,
  makeSelectTotalQuantity,
  makeSelectRouteFinancial,
  makeSelectDraftRouteConfigs,
  makeSelectCurrentRouteItems } from '../selectors';
//////////////////////////////////////////////////

const DefaultFooter = withSaveCancelRoute((props: Object) => (
  <StickedBox
    pb='10px'
    pt='20px'
    bottom='0'
    width='100%'
    borderTop='1px solid'
    bg={G.getTheme('pages.layOutBgColor')}
    borderColor={G.getTheme('tables.rows.borderColor')}
  >
    <Flex justifyContent='center'>
      <CustomButton
        p='8px 16px'
        width='200px'
        height='40px'
        type='button'
        fontSize={18}
        onClick={() => props.handleClickSaveRoute(true)}
      >
        {G.getWindowLocale('actions:save', 'Save')}
      </CustomButton>
      <CustomButton
        ml='20px'
        p='8px 16px'
        width='200px'
        height='40px'
        type='button'
        fontSize={18}
        onClick={props.handleClickCancelRouteClo}
        bgColor={G.getTheme('buttons.cancelBtn.bgColor')}
      >
        {G.getWindowLocale('actions:cancel', 'Cancel')}
      </CustomButton>
    </Flex>
  </StickedBox>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  eventsInfo: makeSelectEventsInfo(state),
  totalWeight: makeSelectTotalWeight(state),
  routeConfigs: makeSelectRouteConfigs(state),
  currentRoute: makeSelectCurrentRoute(state),
  financeInfo: makeSelectRouteFinancial(state),
  totalDistance: makeSelectTotalDistance(state),
  totalQuantity: makeSelectTotalQuantity(state),
  draftRouteConfigs: makeSelectDraftRouteConfigs(state),
  currentRouteItems: makeSelectCurrentRouteItems(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  cleanBuilder,
  closeAllModals,
  validateRouteRequest,
  saveDraftRouteRequest,
  setCurrentRouteInitialState,
})(DefaultFooter);
