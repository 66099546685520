import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { closeModal } from '../../components/modal/actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import {
  FormSaveButton,
  FormCancelButton,
  FormSaveAsNewButton,
} from '../../ui';
// feature app-release
import { makeSelectAppVersion } from './selectors';
import { createAppReleaseRequest, updateAppReleaseRequest } from './actions';
import {
  EditBtn,
  TextArea,
  NoteText,
  FakeLink,
  InputEdit,
  TextHeader,
  WrapperBtn,
  CloseModal,
  HeaderWrap,
  HeaderContent,
  VersionNumber,
  AppVersionWrap,
  WrapperBtnsAdd,
  HeaderLeftBlock,
  TextAreaWrapper,
} from './ui';
//////////////////////////////////////////////////

// TODO: refactor with hooks
const enhance = compose(
  withState('isEditMode', 'toggleEditMode', false),
  withState(
    'versionNotes',
    'setVersionNotes',
    ({ versionState }: Object) => versionState.notes,
  ),
  withState(
    'versionRelease',
    'setVersionRelease',
    ({ versionState }: string) => versionState.release,
  ),
  withPropsOnChange(['versionState'], (props: Object) => {
    props.setVersionRelease(props.versionState.release);
    props.setVersionNotes(props.versionState.notes);
  }),
  withHandlers({
    handleToggleEditMode: (props: Object) => () => {
      props.toggleEditMode(R.not(props.isEditMode));
    },
    handleChangeRelease: (props: Object) => (event: Object) => {
      props.setVersionRelease(event.target.value);
    },
    handleSetVersionNotes: (props: Object) => (e: Object, index: number) => {
      const value = props.versionNotes;
      value[index] = e.currentTarget.value;
      props.setVersionNotes(value);
    },
    handleAddNewValue: (props: Object) => () => (
      props.setVersionNotes(R.append('', props.versionNotes))
    ),
    handleDeleteValue: (props: Object) => (index: number) => (
      props.setVersionNotes(R.remove(index, 1, props.versionNotes))
    ),
    handleClickSaveNew: (props: Object) => () => {
      const data = {
        notes: props.versionNotes,
        release: props.versionRelease,
      };
      props.createAppReleaseRequest(data);
      props.toggleEditMode(false);
    },
    handleClickUpdate: (props: Object) => () => {
      const data = {
        ...props.versionState,
        notes: props.versionNotes,
        release: props.versionRelease,
      };
      props.updateAppReleaseRequest(data);
      props.toggleEditMode(false);
    },
  }),
);

const renderButtons = ({
  isEditMode,
  setVersionNotes,
  handleAddNewValue,
  handleClickUpdate,
  handleClickSaveNew,
  handleToggleEditMode,
}: Object) => (
    isEditMode &&
    <WrapperBtnsAdd>
      <FakeLink onClick={() => handleAddNewValue(setVersionNotes.length)}>
        {G.getWindowLocale('titles:add-new-feature', 'Add New Feature')}
      </FakeLink>
      <WrapperBtn>
        <FormSaveAsNewButton
          m='10px 0 0'
          type='submit'
          width='175px'
          fontSize={18}
          onClick={handleClickSaveNew}
        >
          {G.getWindowLocale('actions:save-as-new', 'Save As New')}
        </FormSaveAsNewButton>
        <FormSaveButton
          m='10px 0 0'
          type='submit'
          width='175px'
          fontSize={18}
          onClick={handleClickUpdate}
        >
          {G.getWindowLocale('actions:update', 'Update')}
        </FormSaveButton>
        <FormCancelButton
          m='10px 0 0'
          type='button'
          width='175px'
          fontSize={18}
          onClick={handleToggleEditMode}
        >
          {G.getWindowLocale('actions:cancel', 'Cancel')}
        </FormCancelButton>
      </WrapperBtn>
    </WrapperBtnsAdd>
);

const renderVersionNotes = (props: Object) => {
  const { isEditMode, versionNotes, handleDeleteValue, handleSetVersionNotes } = props;

  if (G.isNotNilAndNotEmpty(versionNotes)) {
    return versionNotes.map((text: string, i: number) => {
      if (isEditMode) {
        return (
          <TextAreaWrapper key={i}>
            <TextArea
              value={text}
              onChange={(e: Object) => handleSetVersionNotes(e, i)}
            />
            <FakeLink onClick={() => handleDeleteValue(i)}>
              {I.trash(G.getTheme('icons.iconColor'))}
            </FakeLink>
          </TextAreaWrapper>
        );
      }

      return <NoteText key={i}>{text}</NoteText>;
    });
  }
};

const renderRelease = (props: Object) => {
  const { isEditMode, versionRelease, handleChangeRelease } = props;

  if (isEditMode) {
    return (
      <InputEdit value={versionRelease} onChange={handleChangeRelease} />
    );
  }

  return versionRelease;
};

const AppReleaseComponent = (props: Object) => (
  <AppVersionWrap data-testid='testid-AppReleaseComponent-wrapper'>
    <HeaderWrap>
      <HeaderLeftBlock>
        <HeaderContent>
          {`Amous International - ${G.getWindowLocale('titles:version', 'Version', { caseAction: 'titleCase' })}`}
          <VersionNumber>{renderRelease(props)}</VersionNumber>
        </HeaderContent>
        <AuthWrapper has={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
          <EditBtn onClick={props.handleToggleEditMode} data-testid='testid-AppReleaseComponent-EditBtn'>
            {I.pencil(G.getTheme('icons.iconColor'))}
          </EditBtn>
        </AuthWrapper>
      </HeaderLeftBlock>
      <CloseModal onClick={props.closeModal}>
        {I.closeIcon(G.getTheme('icons.iconColor'))}
      </CloseModal>
    </HeaderWrap>
    <TextHeader>
      {G.getWindowLocale('titles:main-features', 'Main Features')}:
    </TextHeader>
    {renderVersionNotes(props)}
    {renderButtons(props)}
  </AppVersionWrap>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  versionState: makeSelectAppVersion(state),
});

export default connect(mapStateToProps, {
  closeModal,
  updateAppReleaseRequest,
  createAppReleaseRequest,
})(enhance(AppReleaseComponent));
