import * as R from 'ramda';
import React from 'react';
// components
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// feature dispatch details new
import * as C from '../../constants';
import { MessageCenterRowActions } from '../hocs/with-message-center-actions';
import {
  withDownloadMail,
  withOrderDocumentActions,
  withItemsTableGroupRowActions,
  withClaimTableGroupRowActions,
  withReferenceTableGroupRowActions,
  withContainersTableGroupRowActions,
  withOrderExternalTableGroupRowActions,
  withStatusMessageTableGroupRowActions,
  withLoadCustomerRateTableGroupRowActions,
  withLoadCustomerInvoiceTableGroupRowActions,
} from '../hocs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');

const editIcon = I.pencil(iconColor);
const removeIcon = I.remove(iconColor);
const quickBooksIcon = I.quickbook(iconColor);
const printIcon = I.renderPrintIcon(iconColor);
const printWithDocumentsIcon = I.renderPrintDocumentsIcon(iconColor);
const editTxtLocale = () => G.getWindowLocale('actions:edit', 'Edit');
const deleteTxtLocale = () => G.getWindowLocale('actions:delete', 'Delete');

const getReferenceOptions = (props: Object) => {
  const { entity, handleEditReference, handleRemoveReference } = props;

  let actions = [
    {
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: handleRemoveReference,
      permissions: [PC.CLO_REFERENCE_WRITE],
    },
  ];

  if (R.and(
    G.isNilOrEmpty(G.getPropFromObject('invoiceGuid', entity)),
    G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_PARENT_REFERENCE_GUID, entity)),
  )) {
    actions = R.prepend({
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: handleEditReference,
      permissions: [PC.CLO_REFERENCE_WRITE],
    }, actions);
  }

  return actions;
};

const getStatusMessageOptions = ({ handleUpdateStatusMessage, handleRemoveStatusMessage }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: handleUpdateStatusMessage,
    permissions: [PC.CLO_STATUS_MESSAGE_WRITE],
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveStatusMessage,
    permissions: [PC.CLO_STATUS_MESSAGE_WRITE],
  },
];

const getItemOptions = ({ handleUpdateItem }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: handleUpdateItem,
    permissions: [PC.CLO_WRITE],
  },
];

const getContainerOptions = ({ handleUpdateContainer }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    permissions: [PC.CLO_WRITE],
    action: handleUpdateContainer,
  },
];

const getCustomerRateOptions = ({ handleEditCustomerRate, handleRemoveCustomerRate }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: handleEditCustomerRate,
    permissions: [PC.CLO_RATE_WRITE],
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveCustomerRate,
    permissions: [PC.CLO_RATE_WRITE],
  },
];

const getCustomerInvoiceOptions = ({
  entity,
  handleEditCloInvoice,
  handleSendCIToFinancial,
  handleExportInvoiceToEDI,
  handlePrintCustomerInvoice,
  handleRemoveCustomerInvoice,
  handleSendCustomerInvoiceToBC,
  handlePrintCustomerInvoiceWithImages,
  handleCreateQBIIFImportCustomerInvoice,
  handleSendCustomerInvoiceListToSageIntacct,
}: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    action: () => handleEditCloInvoice(entity),
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveCustomerInvoice,
    permissions: [PC.CLO_INVOICE_DELETE_EXECUTE],
  },
  // TODO: add locale for print, print with documents
  {
    frontIcon: quickBooksIcon,
    action: handleSendCIToFinancial,
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
  },
  {
    frontIcon: quickBooksIcon,
    action: handleCreateQBIIFImportCustomerInvoice,
    text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
    permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    frontIcon: I.gear(iconColor),
    action: handleSendCustomerInvoiceToBC,
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
  },
  {
    frontIcon: I.gear(iconColor),
    action: handleSendCustomerInvoiceListToSageIntacct,
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send to Sage Intacct'),
  },
  {
    frontIcon: I.gear(iconColor),
    action: handleExportInvoiceToEDI,
    text: G.getWindowLocale('actions:export-to-edi', 'Export to EDI'),
    permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    text: 'Print',
    permissions: null,
    frontIcon: printIcon,
    action: handlePrintCustomerInvoice,
  },
  {
    permissions: null,
    text: 'Print with images',
    frontIcon: printWithDocumentsIcon,
    action: handlePrintCustomerInvoiceWithImages,
  },
];

const getDocumentOptions = ({ entity, handleEditDocument, handleRemoveDocument }: Object) => [
  {
    frontIcon: editIcon,
    text: editTxtLocale(),
    permissions: [PC.CLO_DOCUMENT_WRITE],
    action: () => handleEditDocument(entity),
  },
  {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    permissions: [PC.CLO_DOCUMENT_WRITE],
    action: () => handleRemoveDocument(entity),
  },
];

const getMailOptions = ({ handleDownloadMail }: Object) => [
  {
    action: handleDownloadMail,
    permissions: [PC.SEND_EMAIL_EXECUTE],
    frontIcon: I.downloadMail(iconColor),
    text: G.getWindowLocale('titles:download-mail', 'Download Mail'),
  },
];

const getClaimOptions = ({ entity, handleEditClaim, handleRemoveClaim }: Object) => {
  const removeAction = {
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    permissions: [PC.CLO_DOCUMENT_WRITE],
    action: () => handleRemoveClaim(entity),
  };

  const editAction = {
    frontIcon: editIcon,
    text: editTxtLocale(),
    permissions: [PC.CLO_DOCUMENT_WRITE],
    action: () => handleEditClaim(entity),
  };

  return G.ifElse(
    R.includes('claim_details', G.getWindowLocationPathname()),
    [removeAction],
    [editAction, removeAction],
  );
};

const getExternalSystemOptions = ({
  handleSendToExternalSystem,
  handleRemoveCloFromExternalSystem,
}: Object) => [
  {
    permissions: null,
    action: handleSendToExternalSystem,
    frontIcon: I.reloadCircle(iconColor),
    text: G.getWindowLocale('actions:update', 'Update'),
  },
  {
    permissions: null,
    frontIcon: removeIcon,
    text: deleteTxtLocale(),
    action: handleRemoveCloFromExternalSystem,
  },
];

const ReferenceRowActions = withReferenceTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getReferenceOptions(props)} />,
);

const StatusMessageRowActions = withStatusMessageTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getStatusMessageOptions(props)} />,
);

const ItemRowActions = withItemsTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getItemOptions(props)} />,
);

const ContainerRowActions = withContainersTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getContainerOptions(props)} />,
);

const CustomerRateRowActions = withLoadCustomerRateTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getCustomerRateOptions(props)} />,
);

const CustomerInvoiceRowActions = withLoadCustomerInvoiceTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getCustomerInvoiceOptions(props)} />,
);

const DocumentRowActions = withOrderDocumentActions((props: Object) =>
  <ActionsElement version={2} options={getDocumentOptions(props)} />,
);

const MailRowActions = withDownloadMail((props: Object) =>
  <ActionsElement version={2} options={getMailOptions(props)} />,
);

const ClaimRowAction = withClaimTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getClaimOptions(props)} />,
);

const ExternalSystemRowActions = withOrderExternalTableGroupRowActions((props: Object) =>
  <ActionsElement version={2} options={getExternalSystemOptions(props)} />,
);

const RowActions = (props: Object) => {
  const { activeListName } = props;
  const rowActionsMap = {
    [C.ACTIVE_LIST_ITEMS]: <ItemRowActions {...props} />,
    [C.ACTIVE_LIST_MAILS]: <MailRowActions {...props} />,
    [C.ACTIVE_LIST_CLAIMS]: <ClaimRowAction {...props} />,
    [C.ACTIVE_LIST_VEHICLE_ITEMS]: <ItemRowActions {...props} />,
    [C.ACTIVE_LIST_DOCUMENTS]: <DocumentRowActions {...props} />,
    [C.ACTIVE_LIST_NOTES]: <MessageCenterRowActions {...props} />,
    [C.ACTIVE_LIST_REFERENCES]: <ReferenceRowActions {...props} />,
    [C.ACTIVE_LIST_CONTAINERS]: <ContainerRowActions {...props} />,
    [C.ACTIVE_LIST_CHAT_MESSAGES]: <MessageCenterRowActions {...props} />,
    [C.ACTIVE_LIST_CUSTOMER_RATES]: <CustomerRateRowActions {...props} />,
    [C.ACTIVE_LIST_STATUS_MESSAGES]: <StatusMessageRowActions {...props} />,
    [C.ACTIVE_LIST_CUSTOMER_INVOICES]: <CustomerInvoiceRowActions {...props} />,
    [C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST]: <ExternalSystemRowActions {...props} />,
  };

  return rowActionsMap[activeListName];
};

export default RowActions;
