import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import MessageCenter from '../../../components/message-center';
import { LocalLoader } from '../../../components/local-loader';
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import NoteForm from '../../dispatch-details-new/forms/note-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// dispatch-board-new
import { withAsyncLoadNotes } from '../hocs/with-async-load-notes';
//////////////////////////////////////////////////

const getRowActionsOptions = (props: Object) => {
  const {
    entity,
    handlePinMessage,
    handleEditMessage,
    handleRemoveMessage } = props;

  const iconColor = G.getTheme('colors.light.blue');
  const editIcon = I.pencil(iconColor);
  const removeIcon = I.remove(iconColor);
  const editTxtLocale = () => G.getWindowLocale('actions:edit', 'Edit');
  const deleteTxtLocale = () => G.getWindowLocale('actions:delete', 'Delete');
  const pinIconColor = G.getThemeByCond(
    entity.pinned,
    'colors.light.blue',
    'colors.dark.grey',
  );
  const pinTxtLocale = G.ifElse(
    entity.pinned,
    G.getWindowLocale('actions:unpin', 'Unpin'),
    G.getWindowLocale('actions:pin', 'Pin'),
  );

  return [
    {
      permissions: null,
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: () => handleEditMessage(entity),
    },
    {
      permissions: null,
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: () => handleRemoveMessage(entity),
    },
    {
      permissions: null,
      text: pinTxtLocale,
      frontIcon: I.pinNew(pinIconColor),
      action: () => handlePinMessage(entity),
    },
  ];
};

const enhance = compose(
  withFixedPopover,
  withAsyncLoadNotes,
  withHandlers({
    handleAddNote: ({ loadGuid, loadType, getLoadNotesRequest }: Object) => async ({ message }: Object) => {
      const data = {
        loadGuid,
        pinned: false,
        [GC.FIELD_TEXT]: message,
      };
      const endpoint = G.ifElse(G.isLoadTypeClo(loadType), endpointsMap.cloNote, endpointsMap.telNote);
      await sendRequest('post', endpoint, { data });
      getLoadNotesRequest({ loadGuid, loadType });
    },
    handleEditMessage: (props: Object) => (entity: Object) => {
      const { loadGuid, loadType, openModal, closeModal, refreshLoad, closeContent } = props;
      const component = (
        <NoteForm
          initialValues={entity}
          closeModal={closeModal}
          handleActionLoadNote={async (data: Object) => {
            const endpoint = G.ifElse(G.isLoadTypeClo(loadType), endpointsMap.cloNote, endpointsMap.telNote);
            await sendRequest('put', endpoint, { data });
            refreshLoad({
              guid: loadGuid,
              data: { [GC.FIELD_PINNED_NOTE_TEXT]: G.ifElse(data.pinned, data.text, null) },
            });
            closeModal();
          }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: G.getWindowLocale('actions:edit-note', 'Edit Note'),
        },
      };
      closeContent();
      openModal(modal);
    },
    handleRemoveMessage: (props: Object) => (entity: Object) => {
      const {
        loadType,
        loadGuid,
        openModal,
        closeModal,
        refreshLoad,
        closeContent,
      } = props;

      const { text, pinned } = entity;

      const textLocale = G.getWindowLocale(
        'messages:remove-ref-confirmation-text',
        'Are you sure you want delete this note?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: async () => {
                const endpoint = G.ifElse(
                  G.isLoadTypeClo(loadType),
                  endpointsMap.getCloNoteEndpoint,
                  endpointsMap.getTelNoteEndpoint,
                )(G.getGuidFromObject(entity));

                await sendRequest('delete', endpoint);

                if (G.isTrue(pinned)) {
                  refreshLoad({
                    guid: loadGuid,
                    data: { [GC.FIELD_PINNED_NOTE_TEXT]: G.ifElse(pinned, null, text) },
                  });
                }

                closeModal();
              },
            },
          ],
        },
      };
      closeContent();
      openModal(modal);
    },
    handlePinMessage: (props: Object) => async (entity: Object) => {
      const { loadGuid, loadType, refreshLoad, closeFixedPopup, getLoadNotesRequest } = props;
      const { text, pinned } = entity;
      const data = R.assoc('pinned', R.not(pinned), entity);
      const endpoint = G.ifElse(G.isLoadTypeClo(loadType), endpointsMap.cloNote, endpointsMap.telNote);
      await sendRequest('put', endpoint, { data });
      refreshLoad({
        guid: loadGuid,
        data: { [GC.FIELD_PINNED_NOTE_TEXT]: G.ifElse(pinned, null, text) },
      });
      closeFixedPopup();
      getLoadNotesRequest({ loadGuid, loadType });
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <ActionsElement version={2} options={getRowActionsOptions({...props, entity})} />
        ),
      })
    ),
  }),
  pure,
);

const AsyncNotes = enhance((props: Object) => (
  <LocalLoader width={350} height={400} localLoaderOpen={R.isNil(props.asyncNotes)}>
    <MessageCenter
      {...props}
      entities={props.asyncNotes}
      submitAction={props.handleAddNote}
      handleClickEditIcon={props.handleClickEditIcon}
    />
  </LocalLoader>
));

export default AsyncNotes;
