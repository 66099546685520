export const TEL_MODE = 'TEL_MODE';
export const TEL_INFO_FORM = 'TEL_INFO_FORM';
export const ROUTE_BUILDER_PADDING_BOTTOM = 20;
export const OWN_DRAFTS_MODE = 'OWN_DRAFTS_MODE';
export const TEL_INDEX_IN_ROUTE = 'indexInRoute';
export const SELECT_ITEMS_FORM = 'SELECT_ITEMS_FORM';
export const BRANCH_DRAFTS_MODE = 'BRANCH_DRAFTS_MODE';
export const ORIGINAL_ROUTE_GUID = 'originalRouteGuid';
export const ORIGINAL_ROUTE_NAME = 'originalRouteName';
export const CREATE_ROUTE_INFO_FORM = 'CREATE_ROUTE_INFO_FORM';
export const PLANNER_TEL_MODES = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
  ALL_EXPANDED: 'ALL_EXPANDED',
  ALL_COLLAPSED: 'ALL_COLLAPSED',
};
export const TABS_MODE_OPTIONS = [
  PLANNER_TEL_MODES.ALL_EXPANDED,
  PLANNER_TEL_MODES.ALL_EXPANDED,
  PLANNER_TEL_MODES.INBOUND,
  PLANNER_TEL_MODES.OUTBOUND,
];
