import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import LocalLoader from '../../../components/local-loader';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import { setShowLocalLoader } from '../actions';
import { makeSelectShowLocalLoader } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const LocalLoaderComponent = ({ showLocalLoader, setShowLocalLoader }: Object) => {
  const description = '<LocalLoader /> component provides a local loader feature';

  const usage = (
    <ul>
      <li>Parameter `<i>localLoaderOpen</i>` indicates whether the local loader is open or closed.</li>
    </ul>
  );

  const showLocalLoaderHandler = () => {
    setShowLocalLoader();

    setTimeout(() => {
      setShowLocalLoader();
    }, 1000);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='LocalLoader'
      description={description}
      path='src/components/local-loader'
    >
      <LocalLoader localLoaderOpen={showLocalLoader}>
        <CustomButton onClick={showLocalLoaderHandler}>
          Show Local Loader
        </CustomButton>
      </LocalLoader>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  showLocalLoader: makeSelectShowLocalLoader(state),
});

export default connect(mapStateToProps, {
  setShowLocalLoader,
})(LocalLoaderComponent);

