import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// common
import { sendCloToExternalSystemRequest } from '../../../common/actions';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
//////////////////////////////////////////////////

export const withSendCloToExternalSystem = compose(
  connect(null, {
    openModal,
    closeModal,
    sendCloToExternalSystemRequest,
  }),
  withHandlers({
    handleSendCloToExternalSystem: (props: Object) => ({ fromPage, ...data }: Object = {}) => {
      const { openModal, closeFixedPopup, sendCloToExternalSystemRequest } = props;

      G.callFunction(closeFixedPopup);

      const cloGuid = G.getGuidFromObject(data);

      const externalSystems = [
        {
          label: 'MacroPoint',
          value: GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT,
        },
      ];

      const initialValues = {
        option: GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT,
      };

      const component = (
        <SelectDropdownForm
          optionRequired={true}
          options={externalSystems}
          initialValues={initialValues}
          fieldLabel={G.getWindowLocale('titles:integration-type', 'Integration Type')}
          submitAction={(integrationType: string) =>
            sendCloToExternalSystemRequest({ cloGuid, fromPage, integrationType })}
        />
      );

      const modal = {
        p: 15,
        component,
        options: { width: 330 },
      };

      openModal(modal);
    },
  }),
);
