import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const FIELD_RETAINED_CLO_REFERENCES = 'retainedCloReferences';
const FIELD_RETAINED_TEL_REFERENCES = 'retainedTelReferences';

export const validationSchema = Yup.object().shape({
  [GC.FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [FIELD_RETAINED_CLO_REFERENCES]: Yup.array()
    .notRequired()
    .nullable(true),
  [FIELD_RETAINED_TEL_REFERENCES]: Yup.array()
    .notRequired()
    .nullable(true),
  [GC.FIELD_DELETE_ORIGINAL_ROUTE]: Yup.boolean()
    .notRequired()
    .nullable(true),
});

const defaultFormikValues = {
  [GC.FIELD_NAME]: null,
  [FIELD_RETAINED_CLO_REFERENCES]: null,
  [FIELD_RETAINED_TEL_REFERENCES]: null,
  [GC.FIELD_DELETE_ORIGINAL_ROUTE]: false,
};

const commonProps = {
  width: 380,
  fontSize: 12,
  afterTop: 13,
  afterRight: 13,
  labelPl: '0px',
  errorTop: '103%',
  isRequired: false,
  labelWidth: '100%',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
  errorPosition: 'absolute',
};

const fields = [
  {
    ...commonProps,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_NAME,
    loc: 'titles:template-name',
  },
  {
    ...commonProps,
    type: 'multiselect',
    useMultiSelectMaxHeight: true,
    options: 'cloReferenceTypeOptions',
    loc: 'titles:retained-clo-references',
    fieldName: FIELD_RETAINED_CLO_REFERENCES,
  },
  {
    ...commonProps,
    type: 'multiselect',
    useMultiSelectMaxHeight: true,
    options: 'telReferenceTypeOptions',
    loc: 'titles:retained-tel-references',
    fieldName: FIELD_RETAINED_TEL_REFERENCES,
  },
  {
    type: 'toggle',
    loc: 'titles:delete-original-route',
    fieldName: GC.FIELD_DELETE_ORIGINAL_ROUTE,
  },
];

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    displayName: 'CREATE_ROUTE_TEMPLATE_FORM',
    mapPropsToValues: (props: Object) => R.mergeRight(defaultFormikValues, props.initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction, templateGuid } = props;

      submitAction({
        ...values,
        templateGuid,
      });
    },
  }),
  pure,
);

export const RouteTemplateForm = enhance((props: Object) => {
  const {
    values,
    closeModal,
    handleChange,
    handleSubmit,
    templateName,
    templateGuid,
    submitAction,
    masterTemplateGuid,
    hideDeleteOriginRoute,
  } = props;

  let actionButtons = [];

  const fromTemplate = G.isNotNilAndNotEmpty(templateGuid);

  const fieldsToUse = G.isTrue(hideDeleteOriginRoute) ? R.dropLast(1, fields) : fields;

  if (R.and(fromTemplate, R.not(R.pathEq(templateName, [GC.FIELD_NAME], values)))) {
    actionButtons = [{
      displayText: G.getWindowLocale('actions:save-as-new', 'Save As New'),
      action: () => submitAction({
        ...values,
        [GC.FIELD_MASTER_TEMPLATE_GUID]: R.or(masterTemplateGuid, templateGuid),
      }),
    }];
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box py='8px'>
        <FieldsetComponent
          {...props}
          labelFontSize={12}
          fields={fieldsToUse}
          onChange={handleChange}
          justifyContent='space-between'
        />
      </Box>
      <FormFooter
        closeModal={closeModal}
        boxStyles={{ mt: '10px' }}
        actionButtons={actionButtons}
        submitBtnText={G.getWindowLocale(...G.ifElse(
          fromTemplate,
          ['actions:update', 'Update'],
          ['actions:save', 'Save'],
        ))}
      />
    </form>
  );
});
