import React from 'react';
import { connect } from 'react-redux';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
// helpers/constants
import * as GC from '../../../../constants';
// feature configs
import { updateConfigsRequest } from '../../actions';
import { defaultPageConfigEnhancer } from '../../hocs';
import ConfigComponent from '../../components/config-component';
import {
  makeSelectConfigWarning,
  makeSelectConfigOptions,
  makeSelectConfigInitialValues,
  makeSelectConfigInheritedValues,
} from '../../selectors';
//////////////////////////////////////////////////

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:mobile-application'
    groupSettings={GC.MOBILEAPP_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  configOptions: makeSelectConfigOptions(state, GC.MOBILEAPP_CONFIG_GROUP),
  configWarning: makeSelectConfigWarning(state, GC.MOBILEAPP_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.MOBILEAPP_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.MOBILEAPP_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  updateConfigsRequest,
})(defaultPageConfigEnhancer(GC.MOBILEAPP_CONFIG_GROUP)(ConfigPageComponent));
