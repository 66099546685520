import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { connect } from 'react-redux';
import { withFormik, FieldArray } from 'formik';
import { createStructuredSelector } from 'reselect';
import { pure, compose, branch, withHandlers, renderNothing } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
import { PageActions } from '../../../components/page-actions';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { ROOT_BRANCH_GUID } from '../../branch/constants';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectIsCurrentUserSuperAdmin } from '../../auth/selectors';
// forms
import { FieldsetComponent } from '../../../forms';
import { districtOptions } from '../../../forms/forms/accessorial-form/field-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature average-fuel-prices
import { createFuelPricesRequest } from '../actions';
//////////////////////////////////////////////////

const styles = {
  errorTop: '110%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  errorPosition: 'absolute',
};

const priceDateField = {
  ...styles,
  width: 250,
  zIndex: 20,
  type: 'calendar',
  options: 'driverOptions',
  loc: 'titles:price-date',
  fieldName: GC.FIELD_PRICE_DATE,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_PRICE_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  prices: Yup.array().of(Yup.object().shape({
    [GC.FIELD_PRICE]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .positive(G.getShouldBePositiveLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt()),
  })),
});

const formEnhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: () => ({
      [GC.FIELD_PRICE_DATE]: null,
      prices: R.map(({ value }: Object) => ({
        [GC.FIELD_PRICE]: null,
        [GC.FIELD_ACCESSORIAL_DISTRICT]: value,
      }), R.tail(districtOptions)),
    }),
  }),
  pure,
);

const PricesSection = ({ form }: Object) => {
  const fieldSettings = G.mapIndexed(({ value, label }: Object, index: number) => ({
    type: 'multi',
    labelDisplay: 'none',
    fields: [
      {
        type: 'custom',
        labelDisplay: 'none',
        key: `prices.${index}.${value}`,
        component: () => <Box pl='5px' mr={20} width={150}>{label}</Box>,
      },
      {
        ...styles,
        width: 250,
        type: 'text',
        labelDisplay: 'none',
        key: `prices.${index}.${GC.FIELD_PRICE}`,
        fieldName: `prices.${index}.${GC.FIELD_PRICE}`,
      },
    ],
  }), R.tail(districtOptions));

  return (
    <Box overflowY='auto' maxHeight='calc(100vh - 250px)'>
      <Flex
        p={10}
        my={10}
        zIndex={30}
        width='100%'
        fontWeight='bold'
        bg={G.getTheme('colors.whiteGrey')}
        color={G.getTheme('colors.dark.blue')}
      >
        <Box width={170}>
          {G.getWindowLocale('titles:district', 'District')}
        </Box>
        <Box>
          {G.getWindowLocale('titles:price', 'Price')}
        </Box>
      </Flex>
      <FieldsetComponent {...form} fields={fieldSettings} flexDirection='column' />
    </Box>
  );
};

const AverageFuelPriceForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      fields={R.of(Array, priceDateField)}
      {...G.getFormikPropsToFieldset(props)}
    />
    <FieldArray name='prices' render={PricesSection} />
    <FormFooter
      closeModal={props.closeModal}
      boxStyles={{ p: '10px 5px 0 10px' }}
    />
  </form>
));

const AddFuelPrices = ({ handleAddAverageFuelPrices }: Object) => (
  <PageActions
    mainAction={{
      action: handleAddAverageFuelPrices,
      text: G.getAddTitle(['titles:average-fuel-prices', 'Average Fuel Prices']),
    }}
  />
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  isCurrentUserSuperAdmin: makeSelectIsCurrentUserSuperAdmin(state),
});

const enhance = compose(
  connect(mapStateToProps, { openModal, closeModal, createFuelPricesRequest }),
  withHandlers({
    handleAddAverageFuelPrices: (props: Object) => () => {
      const { openModal, closeModal, createFuelPricesRequest } = props;

      const component = <AverageFuelPriceForm closeModal={closeModal} submitAction={createFuelPricesRequest} />;
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getAddTitle(['titles:average-fuel-prices', 'Average Fuel Prices']),
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ currentBranchGuid, isCurrentUserSuperAdmin }: Object) => G.isFalse(R.and(
      isCurrentUserSuperAdmin,
      R.equals(ROOT_BRANCH_GUID, currentBranchGuid),
    )),
    renderNothing,
  ),
  pure,
);

export default enhance(AddFuelPrices);
