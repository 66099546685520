import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withState } from 'react-recompose';
// components
import { TextComponent } from '../../../../components/text';
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, AbsoluteBox, FlexHovered } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature fleet
import * as C from '../../constants';
import WorkingDivision from '../../components/working-division';
// feature fleet/vendor
import VendorAssignment from '../../vendor/components/assign-vendor';
// feature fleet/driver
import AssignForm from './assign-form';
import { makeSelectFleetVendorList } from '../selectors';
import {
  assignTruckRequest,
  unassignTruckRequest,
  assignTrailerRequest,
  unassignTrailerRequest,
  assignTeamDriverRequest,
  unassignTeamDriverRequest,
  getAvailableTrucksRequest,
  getAvailableDriversRequest,
  getAvailableTrailersRequest,
 } from '../actions';
//////////////////////////////////////////////////

const compileDriverName = (entity: Object, isPrimary: string) => {
  if (R.and(R.isNil(entity.teamDriver), R.not(isPrimary))) return '';

  if (R.not(isPrimary)) {
    return `
      ${G.getOrElse(entity.teamDriver, GC.FIELD_FIRST_NAME, '')}
      ${G.getOrElse(entity.teamDriver, GC.FIELD_LAST_NAME, '')}
    `;
  }

  return `${R.prop(GC.FIELD_FIRST_NAME, entity)} ${R.prop(GC.FIELD_LAST_NAME, entity)}`;
};

const blueColor = G.getTheme('colors.light.blue');

const setPanelActions = (props: Object) => {
  const {
    handleAssignTruck,
    handleChangeTruck,
    handleAssignDriver,
    handleChangeDriver,
    handleUnassignTruck,
    handleAssignTrailer,
    handleChangeTrailer,
    handleUnassignDriver,
    handleUnassignTrailer,
  } = props;

  return [
    {
      primaryGuid: R.path([C.FLEET_ASSIGN_INFO, GC.FIELD_GUID], props),
      guid: R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER, GC.FIELD_GUID], props),
      display: G.ifElse(R.path(['initialValues', C.FLEET_TEAM_DRIVER], props), 'flex', 'none'),
      value: compileDriverName(
        R.prop(C.FLEET_ASSIGN_INFO, props),
        G.isNotNilAndNotEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
      ),
      type: G.ifElse(
        G.isNotNilAndNotEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
        G.getWindowLocale('titles:primary-driver', 'Primary Driver'),
        G.getWindowLocale('titles:team-driver', 'Team Driver'),
      ),
      actions: [
        {
          icon: 'plusRound',
          action: handleAssignDriver,
          showAction: R.and(
            R.and(
              G.isNilOrEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER], props)),
              G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
            ),
            G.isTrue(R.path(['initialValues', C.FLEET_TEAM_DRIVER], props)),
          ),
        },
        {
          icon: 'pencil',
          action: handleChangeDriver,
          showAction: R.and(
            G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER], props)),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
        {
          icon: 'trash',
          action: handleUnassignDriver,
          showAction: R.and(
            G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER], props)),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
      ],
    },
    {
      type: G.getWindowLocale('titles:truck', 'Truck'),
      guid: R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK, GC.FIELD_GUID], props),
      value: R.pathOr('', [C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK, GC.FIELD_TRUCK_UNIT_ID], props),
      actions: [
        {
          icon: 'plusRound',
          action: handleAssignTruck,
          showAction: R.and(
            G.isNilOrEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK], props)),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
        {
          icon: 'pencil',
          action: handleChangeTruck,
          showAction: R.and(
            G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK], props)),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
        {
          icon: 'trash',
          action: handleUnassignTruck,
          showAction: R.and(
            G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK], props)),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
      ],
    },
    {
      type: G.getWindowLocale('titles:trailers', 'Trailers'),
      value: R.pathOr([], [C.FLEET_ASSIGN_INFO, C.FLEET_TRAILERS], props),
      actions: [
        {
          icon: 'plusRound',
          action: handleAssignTrailer,
          showAction: R.and(
            R.lt(R.length(R.pathOr([], [C.FLEET_ASSIGN_INFO, C.FLEET_TRAILERS], props)), 1),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
        {
          icon: 'pencil',
          action: handleChangeTrailer,
          showAction: R.and(
            R.gt(R.length(R.pathOr([], [C.FLEET_ASSIGN_INFO, C.FLEET_TRAILERS], props)), 0),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
        {
          icon: 'trash',
          action: handleUnassignTrailer,
          showAction: R.and(
            G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRAILERS], props)),
            G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_PRIMARY_DRIVER_GUID], props)),
          ),
        },
      ],
    },
  ];
};

const renderModal = (
  component: Object,
  controlButtons: Array,
  props: Object,
  p: string,
  title: string,
) => {
  const { openModal } = props;

  const modal = {
    p,
    component,
    options: {
      title,
      width: 440,
      btnWidth: 190,
      height: 'auto',
      controlButtons,
    },
  };

  openModal(modal);
};

const goToRoute = (data: Object) => {
  const { type, guid, primaryGuid } = data;

  const driverRoute = routesMap[G.getFleetProfileRoutePathNameByFleetType()];

  if (R.equals(type, 'Team Driver')) {
    return G.goToRoute(driverRoute(guid));
  }

  if (R.equals(type, 'Primary Driver')) {
    return G.goToRoute(driverRoute(primaryGuid));
  }

  if (R.equals(type, 'Truck')) {
    return G.goToRoute(routesMap.editTruckPage(guid));
  }
};

const renderValue = (item: any, props: Object) => {
  const { setShowAll, showAllTrailers, expandedContainerOpened } = props;

  const hasAction = G.isFalse(expandedContainerOpened);

  const titleValue = R.map(
    (data: string) => {
      if (G.isArray(item.value)) return data.unitId;
    },
    item.value,
  );

  if (G.isString(item.value)) {
    return (
      <TextComponent
        maxWidth={250}
        cursor='pointer'
        title={item.value}
        withEllipsis={true}
        display='inline-block'
        color={G.ifElse(hasAction, blueColor)}
        onClick={() => {
          if (hasAction) goToRoute(item);
        }}
      >
        {item.value}
      </TextComponent>
    );
  }

  return (
    <TextComponent
      maxWidth={250}
      cursor='pointer'
      title={titleValue}
      withEllipsis={true}
      display='inline-block'
      onMouseEnter={() => setShowAll(true)}
      onMouseLeave={() => setShowAll(false)}
      color={G.getTheme('colors.light.blue')}
    >
      {
        item.value.map((data: Object, i: number) => (
          <Box
            key={i}
            mr='3px'
            flexDirection='row'
            display='inline-block'
            color={G.ifElse(hasAction, blueColor)}
            onClick={() => {
              if (hasAction) G.goToRoute(routesMap.editTrailerPage(data.guid));
            }}
          >
            {R.prop(GC.FIELD_TRUCK_UNIT_ID, data)}
            {R.and(G.notEquals(i, R.subtract(R.length(item.value), 1)), ',')}
          </Box>
        ))
      }
      {
        R.and(showAllTrailers, R.gt(R.length(item.value), 1))
        && (
          <AbsoluteBox
            p='0px'
            top={46}
            bg='white'
            minWidth={150}
            border='1px solid'
            borderRadius='3px'
            height='max-content'
            flexDirection='column'
            onMouseLeave={() => setShowAll(false)}
          >
            {
              item.value.map((data: Object, i: number) => (
                <FlexHovered
                  key={i}
                  p='5px'
                  title=''
                  width='100%'
                  cursor='pointer'
                  transition='all 0.5s'
                  bgColor={G.getTheme('colors.light.lightGrey')}
                  onClick={() => {
                    if (hasAction) G.goToRoute(routesMap.editTrailerPage(R.prop(GC.FIELD_GUID, data)));
                  }}
                >
                  {R.prop(GC.FIELD_TRUCK_UNIT_ID, data)}
                </FlexHovered>
              ))
            }
          </AbsoluteBox>
        )
      }
    </TextComponent>
  );
};

const enhance = compose(
  withState('showAllTrailers', 'setShowAll', false),
  withHandlers({
    handleAssignDriver: (props: Object) => () => {
      const { openModal, closeModal, assignTeamDriverRequest, getAvailableDriversRequest } = props;

      if (G.isFalse(R.path(['initialValues', 'employed'], props))) {
        return toastr.info(G.getWindowLocale('messages:fleet:update-driver', 'Please, update Driver before!'));
      }

      const component = (
        <AssignForm
          name='teamDriver'
          closeModal={closeModal}
          submitAction={assignTeamDriverRequest}
          getAvailable={getAvailableDriversRequest}
          driverGuid={R.path(['initialValues', 'guid'], props)}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:assign-team-driver', 'Assign Team Driver'),
      );

      openModal(modal);
    },
    handleChangeDriver: (props: Object) => () => {
      const { openModal, closeModal, assignTeamDriverRequest, getAvailableDriversRequest } = props;

      const component = (
        <AssignForm
          name='teamDriver'
          closeModal={closeModal}
          submitAction={assignTeamDriverRequest}
          getAvailable={getAvailableDriversRequest}
          driverGuid={R.path(['initialValues', 'guid'], props)}
          initialValues={{
            teamDriverGuid: R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER, GC.FIELD_GUID], props),
          }}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:assign-team-driver', 'Assign Team Driver'),
      );

      openModal(modal);
    },
    handleUnassignDriver: (props: Object) => () => {
      const { closeModal, initialValues, unassignTeamDriverRequest } = props;

      const driver = R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER], props);

      const component = (
        <ConfirmComponent
          name={`${R.prop(GC.FIELD_FIRST_NAME, driver)} ${R.prop(GC.FIELD_LAST_NAME, driver)}`}
          textLocale={
            G.getWindowLocale('messages:driver-unassign:confirmation:text', 'Are you sure, you want unassign')
          }
        />
      );

      const controlButtons = [{
        type: 'button',
        name: G.getWindowLocale('actions:save', 'Save'),
        action: () => {
          closeModal();
          unassignTeamDriverRequest(G.getGuidFromObject(initialValues));
        },
      }];

      const title = G.getWindowLocaleArr(['titles:unassign', 'titles:team-driver']);

      renderModal(component, controlButtons, props, '15px 15px 60px', title);
    },
    handleAssignTruck: (props: Object) => () => {
      const { openModal, closeModal, initialValues, assignTruckRequest, getAvailableTrucksRequest } = props;

      if (G.isFalse(R.path(['initialValues', GC.FIELD_DRIVER_EMPLOYED], props))) {
        return toastr.info(G.getWindowLocale('messages:fleet:update-driver', 'Please, update Driver before!'));
      }

      const component = (
        <AssignForm
          name='truck'
          closeModal={closeModal}
          optionsType='availableTrucks'
          submitAction={assignTruckRequest}
          getAvailable={getAvailableTrucksRequest}
          driverGuid={G.getGuidFromObject(initialValues)}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:assign-truck', 'Assign Truck'),
      );

      openModal(modal);
    },
    handleChangeTruck: (props: Object) => () => {
      const { openModal, closeModal, initialValues, assignTruckRequest, getAvailableTrucksRequest } = props;

      const component = (
        <AssignForm
          name='truck'
          closeModal={closeModal}
          optionsType='availableTrucks'
          submitAction={assignTruckRequest}
          getAvailable={getAvailableTrucksRequest}
          driverGuid={G.getGuidFromObject(initialValues)}
          initialValues={{ truckGuid: R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK, GC.FIELD_GUID], props) }}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:assign-truck', 'Assign Truck'),
      );

      openModal(modal);
    },
    handleUnassignTruck: (props: Object) => () => {
      const { closeModal, initialValues, unassignTruckRequest } = props;

      const truck = R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK], props);

      const component = (
        <ConfirmComponent
          name={`${R.prop(GC.FIELD_TRUCK_UNIT_ID, truck)}`}
          textLocale={
            G.getWindowLocale('messages:driver-unassign:confirmation:text', 'Are you sure, you want unassign')
          }
        />
      );

      const controlButtons = [{
        type: 'button',
        name: G.getWindowLocale('actions:save', 'Save'),
        action: () => {
          closeModal();
          unassignTruckRequest(G.getGuidFromObject(initialValues));
        },
      }];

      const title = G.getWindowLocaleArr(['titles:unassign', 'titles:truck']);

      renderModal(component, controlButtons, props, '15px 15px 60px', title);
    },
    handleAssignTrailer: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        initialValues,
        assignTrailerRequest,
        getAvailableTrailersRequest,
      } = props;

      if (G.isFalse(R.path(['initialValues', GC.FIELD_DRIVER_EMPLOYED], props))) {
        return toastr.info(G.getWindowLocale('messages:fleet:update-driver', 'Please, update Driver before!'));
      }

      const component = (
        <AssignForm
          name='trailer'
          closeModal={closeModal}
          optionsType='availableTrailers'
          submitAction={assignTrailerRequest}
          getAvailable={getAvailableTrailersRequest}
          driverGuid={G.getGuidFromObject(initialValues)}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocaleArr(['titles:assign', 'titles:trailers']),
      );

      openModal(modal);
    },
    handleChangeTrailer: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        assignInfo,
        initialValues,
        assignTrailerRequest,
        getAvailableTrailersRequest,
      } = props;

      const trailerGuids = R.map(G.getGuidFromObject, R.prop(C.FLEET_TRAILERS, assignInfo));

      const component = (
        <AssignForm
          name='trailer'
          closeModal={closeModal}
          optionsType='availableTrailers'
          submitAction={assignTrailerRequest}
          getAvailable={getAvailableTrailersRequest}
          initialValues={{ trailerGuid: trailerGuids }}
          driverGuid={G.getGuidFromObject(initialValues)}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocaleArr(['titles:assign', 'titles:trailers']),
      );

      openModal(modal);
    },
    handleUnassignTrailer: (props: Object) => () => {
      const { closeModal, initialValues, unassignTrailerRequest } = props;

      const trailer = R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRAILERS], props);

      const component = (
        <ConfirmComponent
          name={`${R.pipe(R.map(R.prop(GC.FIELD_UNIT_ID)), R.join(', '))(trailer)}`}
          textLocale={
            G.getWindowLocale('messages:driver-unassign:confirmation:text', 'Are you sure, you want unassign')
          }
        />
      );

      const controlButtons = [{
        type: 'button',
        name: G.getWindowLocale('actions:save', 'Save'),
        action: () => {
          closeModal();
          unassignTrailerRequest(G.getGuidFromObject(initialValues));
        },
      }];

      const title = G.getWindowLocaleArr(['titles:unassign', 'titles:trailers']);

      renderModal(component, controlButtons, props, '15px 15px 60px', title);
    },
  }),
);

const AssignPanelComponent = (props: Object) => {
  const {
    openModal,
    closeModal,
    assignInfo,
    formValues,
    initialValues,
    fleetVendorList,
    expandedContainerOpened,
    changeAssignedToDivisionRequest,
  } = props;

  const hasAction = G.isFalse(expandedContainerOpened);
  const driverGuid = G.getGuidFromObject(initialValues);
  const branchGuid = G.getBranchGuidFromObject(initialValues);
  const fleetVendorGuid = G.getPropFromObject(GC.FIELD_FLEET_VENDOR_GUID, initialValues);

  const fleetVendorName = R.compose(
    R.path([GC.FIELD_NAME]),
    R.find(R.propEq(fleetVendorGuid, GC.FIELD_GUID)),
  )(fleetVendorList);

  return (
    <Flex
      pr='5px'
      width='100%'
      flexWrap='wrap'
      justifyContent='flex-start'
    >
      {
        setPanelActions(props).map((item: Object, index: number) => (
          <Flex
            mr='5px'
            py='5px'
            key={index}
            maxWidth='30%'
            justifyContent='flex-start'
            display={R.or(item.display, 'flex')}
          >
            <Box pl='2px' mr='7px'>{`${item.type}${G.ifElse(G.isNotEmpty(item.value), ': ', '')}`}</Box>
            {renderValue(item, props)}
            {
              item.actions.map((action: Object, i: number) => (
                G.ifElse(
                  G.isTrue(action.showAction),
                  <Box px='5px' key={i} cursor='pointer' onClick={action.action}>
                    {I[action.icon](G.getTheme('icons.iconColor'))}
                  </Box>,
                  null,
                )
              ))
            }
          </Flex>
        ))
      }
      <VendorAssignment
        objGuid={driverGuid}
        type={C.FLEET_DRIVER}
        hasAction={hasAction}
        branchGuid={branchGuid}
        fleetVendorList={fleetVendorList}
        fleetVendorName={fleetVendorName}
        fleetVendorGuid={fleetVendorGuid}
        payable={R.pathOr(false, [GC.FIELD_PAYABLE], formValues)}
        version={R.path(['initialValues', GC.FIELD_VERSION], props)}
      />
      <WorkingDivision
        {...assignInfo}
        guid={driverGuid}
        openModal={openModal}
        hasAction={hasAction}
        closeModal={closeModal}
        branchGuid={branchGuid}
        changeAssignedToDivisionRequest={changeAssignedToDivisionRequest}
        assignedToDivision={G.getPropFromObject('assignedToDivision', formValues)}
        isPrimaryDriver={G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_PRIMARY_DRIVER_GUID, initialValues))}
      />
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  fleetVendorList: makeSelectFleetVendorList(state),
});

export default connect(mapStateToProps, {
  assignTruckRequest,
  unassignTruckRequest,
  assignTrailerRequest,
  unassignTrailerRequest,
  assignTeamDriverRequest,
  getAvailableTrucksRequest,
  unassignTeamDriverRequest,
  getAvailableDriversRequest,
  getAvailableTrailersRequest,
})(enhance(AssignPanelComponent));
