import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withState, withHandlers, renderNothing } from 'react-recompose';
// features
import { setExpandedContainerOptions } from '../expanded-container/actions';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { PageWrapper } from '../../ui';
// feature customer-portal
import NextRefresh from './components/next-refresh';
import GoogleMap from './components/google-map';
import LeafletMap from './components/leaflet-map';
import CurrentOrdersReport from './components/current-orders-report';
import {
  makeSelectMapOpened,
  makeSelectLocations,
  makeSelectTableHeight,
  makeSelectTimerStarted,
} from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withState('flyToGuid', 'setFlyToGuid', null),
  withState('activeLocation', 'setActiveLocation', null),
  withState('activeClusteringGroupPosition', 'setActiveClusteringGroupPosition', null),
  withHandlers({
    handleSetActiveLocation: (props: Object) => ({ guid }: Object, eventType: string) => {
      const { locations, setActiveLocation, setActiveClusteringGroupPosition } = props;

      const isMouseLeave = R.equals(eventType, 'mouseLeave');

      setActiveLocation(G.ifElse(isMouseLeave, null, guid));

      const latLng = R.compose(
        R.path(['latLng']),
        R.find(R.propEq(guid, GC.FIELD_GUID)),
      )(locations);

      const clusters = document.getElementsByClassName('animated-clustering-group');

      const index = R.findIndex(({ id }: Object) => {
        if (G.isNilOrEmpty(id)) return false;

        return R.any(R.equals(latLng), JSON.parse(id));
      }, clusters);

      if (R.gte(index, 0)) {
        clusters[index].style.transform = G.ifElse(isMouseLeave, 'initial', 'scale(1.7)');

        setActiveClusteringGroupPosition(G.ifElse(
          isMouseLeave,
          null,
          R.last(JSON.parse(R.path([index, GC.FIELD_ID], clusters))),
        ));
      }
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const CustomerPortal = (props: Object) => {
  const {
    flyToGuid,
    mapOpened,
    locations,
    tableHeight,
    setFlyToGuid,
    timerStarted,
    activeLocation,
    handleSetActiveLocation,
    setExpandedContainerOptions,
    activeClusteringGroupPosition,
  } = props;

  const useGoogleMap = G.isTrue(G.getAmousConfigByNameFromWindow(GC.UI_CUSTOMER_PORTAL_USE_GOOGLE_MAP));

  return (
    <PageWrapper pb='0px'>
      {
        R.and(useGoogleMap, G.isTrue(mapOpened)) &&
        <GoogleMap locations={locations} setExpandedContainerOptions={setExpandedContainerOptions} />
      }
      {G.isTrue(timerStarted) && <NextRefresh />}
      {
        R.not(useGoogleMap) &&
        <LeafletMap
          flyToGuid={flyToGuid}
          locations={locations}
          tableHeight={tableHeight}
          setFlyToGuid={setFlyToGuid}
          activeLocation={activeLocation}
          setExpandedContainerOptions={setExpandedContainerOptions}
          activeClusteringGroupPosition={activeClusteringGroupPosition}
        />
      }
      <CurrentOrdersReport
        tableHeight={tableHeight}
        setFlyToGuid={setFlyToGuid}
        useGoogleMap={useGoogleMap}
        handleSetActiveLocation={handleSetActiveLocation}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  locations: makeSelectLocations(state),
  mapOpened: makeSelectMapOpened(state),
  tableHeight: makeSelectTableHeight(state),
  timerStarted: makeSelectTimerStarted(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, { setExpandedContainerOptions })(enhance(CustomerPortal));
