import * as R from 'ramda';
import React from 'react';
// components
import MessageCenter from '../../../../../components/message-center';
// features
import PC from '../../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../../helpers';
// feature dispatch details new
import { withLoadMessageCenterActions } from '../../../load/hocs/with-message-center-actions';
import { withOrderMessageCenterActions } from '../../../order/hocs/with-message-center-actions';
//////////////////////////////////////////////////

export const LoadMessageCenterCardComponent = withLoadMessageCenterActions((props: Object) => {
  const {
    handleAddMessage,
    handlePinMessage,
    handleClickEditIcon,
    messageCenterList: { entities, activeTab },
  } = props;

  const chatMessageWritePermission = [PC.TEL_CHAT_MESSAGE_WRITE];
  const notesPermission = [PC.TEL_WRITE];

  const isActiveTabNotes = R.equals(activeTab, 'noteList');

  const addPermissions = G.ifElse(isActiveTabNotes, notesPermission, chatMessageWritePermission);
  const editPermissions = G.ifElse(isActiveTabNotes, notesPermission, [PC.TEL_ADMINISTRATION_EXECUTE]);

  const addMessageText = G.ifElse(
    isActiveTabNotes,
    G.getWindowLocale('actions:post', 'Post'),
    G.getWindowLocale('actions:send', 'Send'),
  );

  return (
    <MessageCenter
      width='100%'
      height={400}
      padding='8px'
      entities={entities}
      addMessageText={addMessageText}
      addPermissions={addPermissions}
      submitAction={handleAddMessage}
      editPermissions={editPermissions}
      handlePinMessage={handlePinMessage}
      isActiveTabNotes={isActiveTabNotes}
      handleClickEditIcon={handleClickEditIcon}
    />
  );
});

export const OrderMessageCenterCardComponent = withOrderMessageCenterActions((props: Object) => {
  const {
    handleAddMessage,
    handlePinMessage,
    handleClickEditIcon,
    messageCenterList: { entities, activeTab },
  } = props;

  const chatMessageWritePermission = [PC.CLO_CHAT_MESSAGE_WRITE];

  const isActiveTabNotes = R.equals(activeTab, 'noteList');

  const addPermissions = G.ifElse(isActiveTabNotes, null, chatMessageWritePermission);
  const editPermissions = G.ifElse(isActiveTabNotes, null, [PC.CLO_ADMINISTRATION_EXECUTE]);

  const addMessageText = G.ifElse(
    isActiveTabNotes,
    G.getWindowLocale('actions:post', 'Post'),
    G.getWindowLocale('actions:send', 'Send'),
  );

  return (
    <MessageCenter
      width='100%'
      height={400}
      padding='8px'
      entities={entities}
      addMessageText={addMessageText}
      addPermissions={addPermissions}
      submitAction={handleAddMessage}
      editPermissions={editPermissions}
      handlePinMessage={handlePinMessage}
      isActiveTabNotes={isActiveTabNotes}
      handleClickEditIcon={handleClickEditIcon}
    />
  );
});
