import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const shareBranch = createAction('shareBranch');
const shareBranches = createAction('shareBranches');
const deleteDocument = createAction('deleteDocument');
const unshareBranches = createAction('unshareBranches');
const downloadDocument = createAction('downloadDocument');
const getBranchRequest = createAction('getBranchRequest');
const sendDeleteRequest = createAction('sendDeleteRequest');
const sendRestoreRequest = createAction('sendRestoreRequest');
const sendSuspendRequest = createAction('sendSuspendRequest');
const changeEntityBranch = createAction('changeEntityBranch');
const getBranchRefRequest = createAction('getBranchRefRequest');
const updateBranchRequest = createAction('updateBranchRequest');
const switchBranchRequest = createAction('switchBranchRequest');
const sendActivateRequest = createAction('sendActivationRequest');
const sendUnsuspendRequest = createAction('sendUnsuspendRequest');
const receivedBranchSuccess = createAction('receivedBranchSuccess');
const receivedBranchRefSuccess = createAction('getBranchRefSuccess');
const sendDeactivateRequest = createAction('sendDeactivationRequest');
const receivedSuspendSuccess = createAction('receivedSuspendSuccess');
const createNewBranchRequest = createAction('createNewBranchRequest');
const createBranchRefRequest = createAction('createBranchRefRequest');
const createBranchRefSuccess = createAction('createBranchRefSuccess');
const updateBranchRefRequest = createAction('updateBranchRefRequest');
const updateBranchRefSuccess = createAction('updateBranchRefSuccess');
const deleteBranchRefRequest = createAction('deleteBranchRefRequest');
const deleteBranchRefSuccess = createAction('deleteBranchRefSuccess');
const getDocumentListRequest = createAction('getDocumentListRequest');
const getDocumentListSuccess = createAction('getDocumentListSuccess');
const collapseBranchFormGroup = createAction('collapseBranchFormGroup');
const receivedActivateSuccess = createAction('receivedActivateSuccess');
const getBranchSettingRequest = createAction('getBranchSettingRequest');
const receivedNewBranchSuccess = createAction('receivedNewBranchSuccess');
const receivedUnsuspendSuccess = createAction('receivedUnsuspendSuccess');
const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
const saveBranchDocumentRequest = createAction('saveBranchDocumentRequest');
const receivedDeactivateSuccess = createAction('receivedDeactivateSuccess');
const openAddBranchModalRequest = createAction('openAddBranchModalRequest');
const receivedBranchesTreeSuccess = createAction('receivedBranchesTreeSuccess');
const updateBranchSettingsRequest = createAction('updateBranchSettingsRequest');
const receivedUpdateBranchSuccess = createAction('receivedUpdateBranchSuccess');
const receivedSwitchBranchSuccess = createAction('receivedSwitchBranchSuccess');
const getListOfBranchTypesRequest = createAction('getListOfBranchTypesRequest');
const receivedBranchSettingSuccess = createAction('receivedBranchSettingSuccess');
const getInitialBranchConfigsSuccess = createAction('getInitialBranchConfigsSuccess');
const getBranchConfigsByNamesRequest = createAction('getBranchConfigsByNamesRequest');
const getBranchConfigsByNamesSuccess = createAction('getBranchConfigsByNamesSuccess');
const receivedUpdateBranchSettingsSuccess = createAction('receivedUpdateBranchSettings');
const createBranchBillToLocationRequest = createAction('createBranchBillToLocationRequest');
const deleteBranchBillToLocationRequest = createAction('deleteBranchBillToLocationRequest');
const receivedBranchImportMappersSuccess = createAction('receivedBranchImportMappersSuccess');
const createBranchPrimaryLocationRequest = createAction('createBranchPrimaryLocationRequest');
const deleteBranchPrimaryLocationRequest = createAction('deleteBranchPrimaryLocationRequest');
const resetBranchSettingAndCompanyDetails = createAction('resetBranchSettingAndCompanyDetails');
const generateExcelWithCreditLimitRequest = createAction('generateExcelWithCreditLimitRequest');
const receivedBranchBillToLocationSuccess = createAction('receivedBranchBillToLocationSuccess');
const receivedBranchPrimaryLocationSuccess = createAction('receivedBranchPrimaryLocationSuccess');
const createBranchAdditionalLocationRequest = createAction('createBranchAdditionalLocationRequest');
const deleteBranchAdditionalLocationRequest = createAction('deleteBranchAdditionalLocationRequest');
const deleteBranchAdditionalLocationSuccess = createAction('deleteBranchAdditionalLocationSuccess');
const receivedBranchAdditionalLocationSuccess = createAction('receivedBranchAdditionalLocationSuccess');
const receivedBranchAdditionalLocationListSuccess = createAction('receivedBranchAdditionalLocationListSuccess');
// commission assignment
const getAvailableCommissionAssignmentListRequest = createAction('getAvailableCommissionAssignmentListRequest');
const getAvailableCommissionAssignmentListSuccess = createAction('getAvailableCommissionAssignmentListSuccess');
// master settings
const getBranchMasterSettingsRequest = createAction('getBranchMasterSettingsRequest');
const receivedBranchMasterSettingsSuccess = createAction('receivedBranchMasterSettingsSuccess');

export {
  // report
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  shareBranch,
  shareBranches,
  deleteDocument,
  unshareBranches,
  downloadDocument,
  getBranchRequest,
  sendDeleteRequest,
  sendRestoreRequest,
  sendSuspendRequest,
  changeEntityBranch,
  getBranchRefRequest,
  updateBranchRequest,
  switchBranchRequest,
  sendActivateRequest,
  sendUnsuspendRequest,
  receivedBranchSuccess,
  sendDeactivateRequest,
  receivedSuspendSuccess,
  createNewBranchRequest,
  createBranchRefRequest,
  createBranchRefSuccess,
  updateBranchRefRequest,
  updateBranchRefSuccess,
  deleteBranchRefRequest,
  deleteBranchRefSuccess,
  getDocumentListRequest,
  getDocumentListSuccess,
  collapseBranchFormGroup,
  receivedActivateSuccess,
  getBranchSettingRequest,
  receivedBranchRefSuccess,
  receivedNewBranchSuccess,
  receivedUnsuspendSuccess,
  getConfigsByNamesRequest,
  getConfigsByNamesSuccess,
  saveBranchDocumentRequest,
  receivedDeactivateSuccess,
  openAddBranchModalRequest,
  receivedBranchesTreeSuccess,
  updateBranchSettingsRequest,
  receivedUpdateBranchSuccess,
  receivedSwitchBranchSuccess,
  getListOfBranchTypesRequest,
  receivedBranchSettingSuccess,
  getBranchMasterSettingsRequest,
  getInitialBranchConfigsSuccess,
  getBranchConfigsByNamesRequest,
  getBranchConfigsByNamesSuccess,
  createBranchBillToLocationRequest,
  deleteBranchBillToLocationRequest,
  receivedBranchImportMappersSuccess,
  createBranchPrimaryLocationRequest,
  deleteBranchPrimaryLocationRequest,
  receivedBranchMasterSettingsSuccess,
  resetBranchSettingAndCompanyDetails,
  generateExcelWithCreditLimitRequest,
  receivedBranchBillToLocationSuccess,
  receivedUpdateBranchSettingsSuccess,
  receivedBranchPrimaryLocationSuccess,
  createBranchAdditionalLocationRequest,
  deleteBranchAdditionalLocationRequest,
  deleteBranchAdditionalLocationSuccess,
  receivedBranchAdditionalLocationSuccess,
  receivedBranchAdditionalLocationListSuccess,
  getAvailableCommissionAssignmentListRequest,
  getAvailableCommissionAssignmentListSuccess,
 };
