import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import React, { Fragment, useCallback } from 'react';
// components
import { ActionBox } from '../../../../components/action-box';
import { PageTitle } from '../../../../components/page-title';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_VARIABLE_TYPE_OPTIONS,
} from '../../../../helpers/options';
// ui
import { Box, Flex, ActionButton, scrollableContainerCss4px } from '../../../../ui';
// feature carrier-profile
import { variableValuesSettings } from '../../settings';
import FormGroupTable from '../../components/form-group-table';
import {
  makeSelectRoleOptions,
  makeSelectAvailableCarrierIntegrations,
  makeSelectTransportationModeOptionsForContracts,
} from '../../selectors';
//////////////////////////////////////////////////

const validationSchema = Yup.lazy((values: Object) => {
  const { discount } = values;

  const { rate, discountType, variableType } = R.or(discount, {});

  const schema = {
    [GC.FIELD_NAME]: G.yupStringRequired,
    [GC.FIELD_MODE]: G.yupStringRequired,
    [GC.FIELD_CURRENCY]: G.yupStringRequired,
    [GC.FIELD_EFFECTIVE_DATE]: G.yupStringRequired,
    [GC.FIELD_EXPIRATION_DATE]: G.yupStringRequired,
    [GC.FIELD_CHARGE_DISCOUNT]: Yup.object().nullable(true).shape({
      [GC.FIELD_RATE]: G.yupNumberNotRequired
        .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
        .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
      [GC.FIELD_VARIABLE_TYPE]: G.ifElse(
        R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE),
        G.yupStringRequired,
      ),
      [GC.FIELD_RATE_UNIT]: G.ifElse(
        R.and(R.equals(discountType, GC.DISCOUNT_TYPE_FLAT), G.isNotNilAndNotEmpty(rate)),
        G.yupStringRequired,
      ),
      [GC.FIELD_VARIABLE_UNIT]: G.ifElse(
        R.and(
          R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE),
          R.includes(
            variableType,
            [GC.CHARGE_RATE_TYPE_WEIGHT, GC.CHARGE_RATE_TYPE_DISTANCE],
          ),
        ),
        G.yupStringRequired,
      ),
    }),
  };

  return Yup.object().shape(schema);
});

const inputWrapperStyles = {
  mr: 20,
  width: 220,
};

const closeMenuOnScroll = R.pathEq('carrier_contract_wrapper', ['target', 'id']);

const sections = [
  [
    {
      type: 'toggle',
      label: ['titles:active'],
      inputWrapperStyles: { mr: 20 },
      fieldName: GC.FIELD_CARRIER_ACTIVE,
    },
    {
      type: 'toggle',
      inputWrapperStyles: { mr: 20 },
      label: ['titles:get-fuel-from-order'],
      fieldName: GC.FIELD_GET_FUEL_FROM_ORDER,
    },
    {
      type: 'toggle',
      label: ['titles:add-additional-charges-from-order'],
      fieldName: GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER,
    },
  ],
  [
    {
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:name'],
      fieldName: GC.FIELD_NAME,
    },
    {
      isRequired: true,
      isClearable: true,
      type: 'datePicker',
      inputWrapperStyles,
      justifyContent: 'space-between',
      label: ['titles:effective-date'],
      fieldName: GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE,
      maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
    },
    {
      isRequired: true,
      isClearable: true,
      type: 'datePicker',
      inputWrapperStyles,
      minDate: G.getCurrentDate(),
      label: ['titles:expiration-date'],
      fieldName: GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE,
      maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
    },
    {
      closeMenuOnScroll,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      label: ['titles:integration'],
      fieldName: GC.FIELD_INTEGRATION_CONFIG_GUID,
      options: 'availableCarrierIntegrationOptions',
      inputWrapperStyles: R.dissoc('mr', inputWrapperStyles),
    },
  ],
  [
    {
      isRequired: true,
      closeMenuOnScroll,
      inputWrapperStyles,
      type: 'reactSelect',
      label: ['titles:mode'],
      fieldName: GC.FIELD_MODE,
      useMenuPortalTarget: true,
      options: 'transportationModeOptions',
      reactSelectAdditionalStyles: {
        menu: (baseStyles: Object) => ({
          ...baseStyles,
          zIndex: 15,
        }),
      },
    },
    {
      type: 'select',
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:currency'],
      fieldName: GC.FIELD_CURRENCY,
      options: GC.CURRENCY_OPTIONS,
    },
    {
      isMulti: true,
      closeMenuOnScroll,
      inputWrapperStyles,
      type: 'reactSelect',
      options: 'roleOptions',
      label: ['titles:roles'],
      useMenuPortalTarget: true,
      fieldName: GC.FIELD_CARRIER_CONTRACT_ROLE_GUIDS,
    },
    {
      label: ['titles:min-rate'],
      fieldName: GC.FIELD_CHARGE_MIN_RATE,
      inputWrapperStyles: R.dissoc('mr', inputWrapperStyles),
    },
  ],
  [
    {
      type: 'select',
      inputWrapperStyles,
      label: ['titles:discount-type'],
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_DISCOUNT_TYPE}`,
      options: [
        GC.EMPTY_OPTION_OBJECT,
        { value: GC.DISCOUNT_TYPE_FLAT, label: G.getWindowLocale('titles:flat', 'Flat') },
        { value: GC.DISCOUNT_TYPE_VARIABLE, label: G.getWindowLocale('titles:variable', 'Variable') },
      ],
    },
    {
      label: ['titles:discount-rate'],
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_RATE}`,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'select',
      label: ['titles:discount-rate-unit'],
      options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_RATE_UNIT}`,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        mr: '0px',
        display: (props: Object) => G.ifElse(
          R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'select',
      shouldCustomChange: true,
      label: ['titles:variable-type'],
      justifyContent: 'space-between',
      options: DEFAULT_DISCOUNT_VARIABLE_TYPE_OPTIONS,
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_VARIABLE_TYPE}`,
      customChangeHandler: (event: Object, _: any, { handleChange, setFieldValue }: Object) => {
        handleChange(event);
        setFieldValue(`${GC.FIELD_DISCOUNT}.${GC.FIELD_VARIABLE_UNIT}`, '');
      },
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq(GC.DISCOUNT_TYPE_VARIABLE, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'select',
      isRequired: true,
      options: 'variableUnitOptions',
      label: ['titles:variable-unit'],
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_VARIABLE_UNIT}`,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.and(
            R.pathEq(GC.DISCOUNT_TYPE_VARIABLE, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
            R.includes(
              R.path(['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_VARIABLE_TYPE], props),
              [GC.CHARGE_RATE_TYPE_WEIGHT, GC.CHARGE_RATE_TYPE_DISTANCE],
            ),
          ),
          'block',
          'none',
        ),
      },
    },
  ],
];

const submitStyles = {
  mt: 20,
  ml: 'auto',
  height: 32,
  width: 120,
  p: '4px 8px',
  color: 'white',
  bg: 'dark.blue',
  display: 'block',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const variableUnitOptionsMap = {
  [GC.CHARGE_RATE_TYPE_WEIGHT]: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_DISTANCE]: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
};

const GeneralDetails = (props: Object) => {
  const {
    carrierName,
    submitAction,
    initialValues,
    handleGoToCarrier,
  } = props;

  const roleOptions = useSelector(makeSelectRoleOptions());
  const transportationModeOptions = useSelector(makeSelectTransportationModeOptionsForContracts());
  const availableCarrierIntegrationOptions = useSelector(makeSelectAvailableCarrierIntegrations());

  const onSubmit = useCallback((values: Object, { setSubmitting }: Object) => {
    let data = G.mapObjectEmptyStringFieldsToNull(values);

    const discountType = R.path([GC.FIELD_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], values);

    if (G.isNilOrEmpty(discountType)) {
      data = R.assoc(GC.FIELD_DISCOUNT, null, data);
    } else {
      const discount = R.prop(GC.FIELD_DISCOUNT, values);

      if (R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE)) {
        data.discount = R.omit([GC.FIELD_RATE, GC.FIELD_RATE_UNIT], discount);
      }

      if (R.equals(discountType, GC.DISCOUNT_TYPE_FLAT)) {
        data.discount = R.omit(
          [GC.FIELD_VARIABLE_TYPE, GC.FIELD_VARIABLE_UNIT, GC.FIELD_PAYROLL_VARIABLE_VALUES],
          discount,
        );
      }
    }

    submitAction(G.mapObjectEmptyStringFieldsToNull(data), { setSubmitting });
  }, [submitAction]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      [GC.FIELD_DISCOUNT]: {
        [GC.FIELD_RATE]: '',
        [GC.FIELD_RATE_UNIT]: '',
        [GC.FIELD_VARIABLE_TYPE]: '',
        [GC.FIELD_VARIABLE_UNIT]: '',
        [GC.FIELD_DISCOUNT_TYPE]: '',
        [GC.FIELD_PAYROLL_VARIABLE_VALUES]: [],
      },
      ...G.ifElse(
        R.isNil(R.prop(GC.FIELD_DISCOUNT, initialValues)),
        R.dissoc(GC.FIELD_DISCOUNT, initialValues),
        initialValues,
      ),
    },
  });

  const { values, setValues, isSubmitting, handleSubmit } = formik;

  const { name, active } = initialValues;

  const variableType = R.path([GC.FIELD_DISCOUNT, GC.FIELD_VARIABLE_TYPE], values);
  const variableUnitOptions = R.propOr([], variableType, variableUnitOptionsMap);
  const variableValues = R.pathOr([], [GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_PAYROLL_VARIABLE_VALUES], values);

  const discountTypeVariable = R.pathEq(
    GC.DISCOUNT_TYPE_VARIABLE,
    [GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE],
    values,
  );

  return (
    <Fragment>
      <Flex>
        <PageTitle
          mr={20}
          title={name}
          status={active}
          showStatus={true}
          display='inline-flex'
        />
        {G.getWindowLocale('titles:carrier', 'Carrier')}:
        <ActionBox
          text={carrierName}
          action={() => handleGoToCarrier()}
          boxStyles={{ ml: 10, maxWidth: 300, fontWeight: 'bold' }}
        />
      </Flex>
      <Box p={20} mb={20} bg='white' maxWidth={980}>
        <form onSubmit={handleSubmit} id='carrier_contract_general_details'>
          <Box overflowX='auto' css={scrollableContainerCss4px}>
            {
              sections.map((fieldSettings: Array, index: number) => (
                <Fieldset2
                  {...G.getFormikPropsToFieldset(formik)}
                  key={index}
                  fields={fieldSettings}
                  roleOptions={roleOptions}
                  variableUnitOptions={variableUnitOptions}
                  transportationModeOptions={transportationModeOptions}
                  availableCarrierIntegrationOptions={availableCarrierIntegrationOptions}
                  fieldsWrapperStyles={{
                    pb: 15,
                    width: 940,
                    pt: G.ifElse(G.isZero(index), '0px', 10),
                  }}
                />
              ))
            }
          </Box>
          {
            discountTypeVariable &&
            <FormGroupTable
              {...props}
              margin='25px 0 0 0'
              setValues={setValues}
              itemList={variableValues}
              group={variableValuesSettings}
            />
          }
          <ActionButton
            {...submitStyles}
            disabled={R.or(isSubmitting, R.equals(values, initialValues))}
          >
            {G.getWindowLocale('actions:update', 'Update')}
          </ActionButton>
        </form>
      </Box>
    </Fragment>
  );
};

export default GeneralDetails;
