import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReport = createAction('setReport');
export const setActiveTab = createAction('setActiveTab');
export const setShowLoader = createAction('setShowLoader');
export const setInputValue = createAction('setInputValue');
export const setSwitchValue = createAction('setSwitchValue');
export const setFilterParam = createAction('setFilterParam');
export const setSearchFilter = createAction('setSearchFilter');
export const setShowHideText = createAction('setShowHideText');
export const setChatMessages = createAction('setChatMessages');
export const setProgressValue = createAction('setProgressValue');
export const setToggleBtnValue = createAction('setToggleBtnValue');
export const setShowLocalLoader = createAction('setShowLocalLoader');
export const setDateRangeValues = createAction('setDateRangeValues');
export const setToggleFormGroup = createAction('setToggleFormGroup');
export const setColorPickerValue = createAction('setColorPickerValue');
export const setSelectedCarriers = createAction('setSelectedCarriers');
export const setAdditionalFilters = createAction('setAdditionalFilters');
export const setDateRangeMuiValues = createAction('setDateRangeMuiValues');
export const setDatepickerMuiValue = createAction('setDatepickerMuiValue');
export const setDateSelectorValues = createAction('setDateSelectorValues');
export const setMessageCenterValues = createAction('setMessageCenterValues');
export const updateMessageCenterValue = createAction('updateMessageCenterValue');
export const deleteMessageCenterValue = createAction('deleteMessageCenterValue');
export const pinOrUnpinMessageCenterValue = createAction('pinOrUnpinMessageCenterValue');
