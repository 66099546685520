import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, withProps, withHandlers } from 'react-recompose';
// components
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import * as C from '../../constants';
import RowActions from '../components/row-actions';
import { tableSettings, orderDocumentTabs, orderDocumentsListMap } from '../../settings/table-settings';
//////////////////////////////////////////////////

export const withTableGroupProps = compose(
  withState('activeListName', 'setActiveListName', C.ACTIVE_LIST_DOCUMENTS),
  withHandlers({
    handleChangeActiveList: (props: Object) => (listName: string, activeItem: Boolean) => {
      const { mailList, setActiveListName, getOrderMailListRequest } = props;
      if (G.isAllTrue(
        G.isFalse(activeItem),
        G.isNilOrEmpty(mailList),
        R.equals(listName, C.ACTIVE_LIST_MAILS),
      )) getOrderMailListRequest();
      if (G.isFalse(activeItem)) setActiveListName(listName);
    },
    handleToggleExpandedList: ({ toggleExpandedListsSuccess }: Object) => () =>
      toggleExpandedListsSuccess({ tableNumber: 2 }),
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <RowActions
            entity={entity}
            load={props.load}
            events={props.events}
            openModal={props.openModal}
            closeModal={props.closeModal}
            loadConfigs={props.loadConfigs}
            activeTab={props.activeListName}
            loadDocuments={props.loadDocuments}
            activeListName={props.activeListName}
            closeFixedPopup={props.closeFixedPopup} />
        ),
      })
    ),
  }),
  withProps((props: Object) => {
    const {
      mailList,
      documents,
      pagination,
      totalCount,
      messageCenter,
      activeListName,
      handleLoadMoreEntities } = props;
    const generateReport = (settings: Array) => R.compose(
      G.mapIndexed((item: string, index: number) => ({ sequence: index, [GC.FIELD_NAME]: item })),
      R.keys,
    )(settings);
    const itemListMap = {
      [C.ACTIVE_LIST_MAILS]: mailList,
      [C.ACTIVE_LIST_DOCUMENTS]: documents,
      [C.ACTIVE_LIST_NOTES]: messageCenter.noteList,
      [C.ACTIVE_LIST_CHAT_MESSAGES]: messageCenter.chatMessageList,
    };
    const columnSettings = R.path([activeListName, 'columnSettings'], orderDocumentsListMap);
    const rowActionsPermissions = R.path([activeListName, 'rowActionsPermissions'], orderDocumentsListMap);
    const report = { fields: generateReport(columnSettings) };
    const tabs = R.filter(({ value }: Object) => G.ifElse(
      R.equals(value, 'noteList'),
      R.not(G.isCurrentUserTypeCustomer()),
      true,
    ), orderDocumentTabs);
    const tableData = {
      report,
      pagination,
      totalCount,
      tableSettings,
      columnSettings,
      withoutBorder: true,
      rowActionsPermissions,
      handleLoadMoreEntities,
      tableWrapperOverflow: 'none',
      itemList: R.pathOr([], [activeListName], itemListMap),
    };

    return { tabs, tableData };
  }),
  pure,
);
