// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const editVendorFieldSettings = {
  name: 'vendorDetails',
  title: 'titles:general-details',
  fields: [
    {
      type: 'text',
      name: 'titles:company-name',
      nameForAttribute: GC.FIELD_NAME,
    },
    {
      type: 'text',
      name: 'titles:owner-name',
      nameForAttribute: GC.FIELD_OWNER_NAME,
    },
    {
      type: 'text',
      name: 'titles:dba',
      validate: G.curriedIsMinMax(0, 70),
      nameForAttribute: GC.FIELD_CARRIER_DBA,
    },
    {
      type: 'text',
      name: 'titles:usdot-number',
      validate: G.curriedIsMinMax(0, 12),
      nameForAttribute: GC.FIELD_CARRIER_USDOT_NUMBER,
    },
    {
      type: 'text',
      name: 'titles:mc-number',
      validate: G.curriedIsMinMax(0, 12),
      nameForAttribute: GC.FIELD_CARRIER_MC_NUMBER,
    },
    {
      type: 'text',
      name: 'titles:account-number',
      validate: G.curriedIsMinMax(0, 40),
      nameForAttribute: GC.FIELD_ACCOUNT_NUMBER,
    },
    {
      type: 'text',
      name: 'titles:duns-number',
      validate: G.curriedIsMinMax(0, 100),
      nameForAttribute: GC.FIELD_CARRIER_DUNS_NUMBER,
    },
    {
      type: 'text',
      name: 'titles:federal-ein',
      nameForAttribute: GC.FIELD_FEDERAL_EIN,
    },
    {
      name: 'titles:gst-number',
      validate: G.curriedIsMinMax(0, 100),
      nameForAttribute: GC.FIELD_VENDOR_GST_NUMBER,
    },
    {
      zIndex: 12,
      name: 'titles:address1',
      type: 'addressAutocomplete',
      nameForAttribute: GC.FIELD_ADDRESS,
    },
    {
      type: 'text',
      name: 'titles:address2',
      nameForAttribute: GC.FIELD_ADDRESS_2,
    },
    {
      type: 'text',
      name: 'titles:city',
      nameForAttribute: GC.FIELD_CITY,
    },
    {
      type: 'text',
      name: 'titles:state',
      nameForAttribute: GC.FIELD_STATE,
    },
    {
      type: 'text',
      name: 'titles:zip',
      nameForAttribute: GC.FIELD_ZIP,
    },
    {
      type: 'countrySelect',
      name: 'titles:country',
      nameForAttribute: GC.FIELD_COUNTRY,
    },
    {
      type: 'text',
      name: 'titles:target-rpm',
      nameForAttribute: GC.FIELD_TARGET_RPM,
      validate: [G.curriedIsEmptyOrMinMaxNumber(0, 100)],
    },
    {
      type: 'textarea',
      name: 'titles:notes',
      nameForAttribute: GC.FIELD_NOTES,
      validate: G.curriedIsEmptyOrMinMax(0, 2000),
    },
  ],
};

export const accountingFields = {
  name: 'accounting',
  title: 'titles:accounting',
  permissions: [PC.FLEET_VENDOR_ACCOUNTING_READ, PC.FLEET_VENDOR_ACCOUNTING_WRITE],
  fields: [
    {
      type: 'text',
      name: 'titles:bank-account-number',
      nameForAttribute: GC.FIELD_BANK_ACCOUNT_NUMBER,
      disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_VENDOR_ACCOUNTING_WRITE),
    },
    {
      type: 'text',
      name: 'titles:bank-routing-number',
      nameForAttribute: GC.FIELD_BANK_ROUTING_NUMBER,
      disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_VENDOR_ACCOUNTING_WRITE),
    },
  ],
};
