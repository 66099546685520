import * as R from 'ramda';
import React from 'react';
// components
import { Map } from '../../../components/map';
import { Directions } from '../../../components/map/components/directions';
import { MarkerWithInfo } from '../../../components/map/components/marker-with-info';
import { StopInfo, StopMarker } from '../../../components/map/components/stop-components';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, AbsoluteBox, CancelButton } from '../../../ui';
// feature dispatch-planner
import * as H from '../helpers';
//////////////////////////////////////////////////

const locationsWithContent = (stops: Array) => (
  R.map((stop: Object) => ({
    ...stop,
    markerContent: <StopMarker {...stop} />,
    infoContent: <StopInfo {...stop} items={stop.items} />,
  }), R.values(stops))
);

const LoadMap = ({ close, events }: Object) => {
  const stops = H.convertLoadEventsForMap(R.or(events, []));
  return (
    <Flex
      flexDirection='column'
      justifyContent='flex-end'
      height='calc(100vh - 140px)'
    >
      <AbsoluteBox
        top='0px'
        left='0px'
        width='100%'
        transition='left .2s linear, width .2s linear'
      >
        <Map>
          <MarkerWithInfo locations={G.makeLocationsWithTransform(locationsWithContent(stops))} />
          <Directions locations={stops.map(({ latLng }: Object) => latLng)} />
        </Map>
      </AbsoluteBox>
      <AbsoluteBox
        right={70}
        zIndex={12}
        bottom={20}
      >
        <CancelButton
          p='8px 16px'
          width='200px'
          fontSize={18}
          onClick={close}
        >
          {G.getWindowLocale('actions:cancel', 'Cancel')}
        </CancelButton>
      </AbsoluteBox>
    </Flex>
  );
};

export default LoadMap;
