import * as R from 'ramda';
import FileSaver from 'file-saver';
import FileDownload from 'js-file-download';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { generateDefaultReport } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature ifta-report
import * as A from './actions';
import {
  makeSelectTruckGuid,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
  makeSelectAdditionalFilters,
} from './selectors';
//////////////////////////////////////////////////

function* getIftaListRequest({ payload }: Object) {
  try {
    if (G.isTrue(payload)) yield put(openLoader());

    yield put(A.setListLoading(true));

    const truckGuid = yield select(makeSelectTruckGuid());
    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const additionalFilters = yield select(makeSelectAdditionalFilters());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const reqBody = {
      fields: [],
      orderFields,
      ...pagination,
      ...additionalFilters,
      guids: R.pathOr(null, ['guids'], payload),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    let options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    if (G.isNotNilAndNotEmpty(truckGuid)) {
      const params = { truckGuid };
      options = R.assoc('params', params, options);
    }

    const res = yield call(sendRequest, 'post', endpointsMap.iftaList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getIftaListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getTollChargesListRequest fail');
    }

    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (error) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'getTollChargesListRequest exception'));
  }
}

function* changeActiveTab({ payload }: Object) {
  try {
    const { row, tabName } = payload;
    const { guid, telGuids, fuelTransactionGuids } = row;
    const list = G.getPropFromObject(tabName, row);
    if (G.isNotNilAndNotEmpty(list)) {
      yield put(A.changeActiveTabSuccess({ guid, list, tabName }));
    } else {
      const endpoints = {
        [GC.SYSTEM_LIST_TELS]: endpointsMap.getTelPrimaryReferenceValue,
        [GC.SYSTEM_LIST_FUEL_TRANSACTIONS]: endpointsMap.getFuelCardTransactionNumbersByGuids,
      };
      const guidList = {
        [GC.SYSTEM_LIST_TELS]: telGuids,
        [GC.SYSTEM_LIST_FUEL_TRANSACTIONS]: fuelTransactionGuids,
      };
      const endpoint = G.getPropFromObject(tabName, endpoints);
      const options = {
        data: G.getPropFromObject(tabName, guidList),
      };
      const res = yield call(sendRequest, 'post', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        yield put(A.changeActiveTabSuccess({ guid, tabName, list: data }));
      } else {
        yield call(G.handleFailResponse, res, 'changeActiveTab fail');
      }
    }
  } catch (error) {
    yield call(G.handleException(error, 'changeActiveTab exception'));
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* removeIFTARequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.removeIFTA(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeIFTASuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeIFTARequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeIFTARequest exception');
  }
}

function* getIFTAXmlRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getIFTAXml(payload);
    const res = yield call(sendRequest, 'get', endpoint, { resType: 'arraybuffer' });
    const { data, status, request } = res;
    if (G.isResponseSuccess(status)) {
      const filename = G.getFileNameFromResponseDispositionHeader(request, 'ifta.xml');
      FileDownload(data, filename);
    } else {
      yield call(G.handleFailResponse, res, 'getIFTAXmlRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getIFTAXmlRequest exception');
  }
}

function* printIFTARequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { format, requestData } = payload;
    const options = {
      data: requestData,
      params: { format },
      resType: 'arraybuffer',
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.exportIFTA,
      options,
    );
    const { data, status, request, headers } = res;
    if (G.isResponseSuccess(status)) {
      const file = new window.Blob(
        R.of(Array, data),
        { type: R.pathOr('application/pdf', ['content-type'], headers) },
      );
      const filename = G.getFileNameFromResponseDispositionHeader(request, 'ifta.pdf');
      FileSaver.saveAs(file, filename);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'printIFTARequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printIFTARequest exception');
  }
}

function* handleAvailableReportsRequest({ payload, notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reportType = R.prop(GC.REPORT_TYPE, payload);
    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      yield put(A.setReports(reports));
      if (G.isNilOrEmpty(reports)) {
        return yield put(A.setUsedReport(generateDefaultReport(GC.IFTA_REPORT)));
      }
      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(reports);
        const usedReport = R.or(defaultReport, generateDefaultReport(GC.IFTA_REPORT));
        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload) };
    const res = yield call(sendRequest, 'post', endpointsMap.report, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(data)));
      yield call(
        handleAvailableReportsRequest,
        {
          notSetUsedReport: true,
          payload: {
            reportType: GC.IFTA_REPORT,
            pathname: GC.ROUTE_PATH_IFTA_REPORT,
          },
        },
      );
      yield call(getIftaListRequest, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(data)));
      yield call(
        handleAvailableReportsRequest,
        {
          notSetUsedReport: true,
          payload: {
            reportType: GC.IFTA_REPORT,
            pathname: GC.ROUTE_PATH_IFTA_REPORT,
          },
        },
      );
      yield call(getIftaListRequest, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.IFTA_REPORT,
            pathname: GC.ROUTE_PATH_IFTA_REPORT,
          },
        },
      );
      yield call(getIftaListRequest, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}

function* visitIftaReportOnFleetPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());
    yield put(A.setInitialState());
    const { truckGuid } = payload;
    yield call(handleAvailableReportsRequest, { payload: { [GC.REPORT_TYPE]: GC.IFTA_REPORT } });
    yield put(A.setTruckGuid(truckGuid));
    yield call(getIftaListRequest, { payload: true });
    yield put(closeLoader());
    break;
  }
}

function* visitIftaReportPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_IFTA_REPORT_PAGE);
    yield put(openLoader());
    yield put(A.setInitialState());
    yield call(handleAvailableReportsRequest, { payload });
    yield call(getIftaListRequest, { payload: true });
    yield put(closeLoader());
    break;
  }
}

function* iftaReportWatcherSaga() {
  yield takeLatest(A.changeActiveTab, changeActiveTab);
  yield takeLatest(A.printIFTARequest, printIFTARequest);
  yield takeLatest(A.getIFTAXmlRequest, getIFTAXmlRequest);
  yield takeLatest(A.removeIFTARequest, removeIFTARequest);
  yield takeLatest(A.getIftaListRequest, getIftaListRequest);
  yield takeLatest(GC.VISIT_IFTA_REPORT_PAGE, visitIftaReportPageSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.visitIftaReportOnFleetPage, visitIftaReportOnFleetPage);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default iftaReportWatcherSaga;
