import * as R from 'ramda';
import { all, put, call, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import { getConfigByGroupSaga } from '../../sagas';
import * as A from './actions';
//////////////////////////////////////////////////

// margin violation rule
function* getMarginViolationRuleListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.marginViolationRuleList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getMarginViolationRuleListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getMarginViolationRuleListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getMarginViolationRuleListRequest exception');
  }
}

function* createOrUpdateMarginViolationRuleRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );
    const branchGuid = R.pathOr(currentBranchGuid, [GC.BRANCH_GUID], payload);
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.marginViolationRule, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateMarginViolationRuleSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateMarginViolationRuleRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateMarginViolationRuleRequest exception');
  }
}

function* removeMarginViolationRuleRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.marginViolationRule, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeMarginViolationRuleSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeMarginViolationRuleRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeMarginViolationRuleRequest exception');
  }
}

function* getBranchConfigsForRatingSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        names: GC.GENERAL_MODE_TRANSPORTATION,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      shouldCloseModal: true,
      mapper: G.mapConfigValuesByName,
      endpoint: endpointsMap.branchConfigsEndpoint,
      parentSagaName: 'getBranchConfigsForRatingSaga',
      successAction: A.getBranchConfigsForRatingSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getBranchConfigsForRatingSaga exception');
  }
}

function* handleVisitConfigRatesPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_RATING_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield fork(getBranchConfigsForRatingSaga);
    yield call(getConfigByGroupSaga, GC.RATE_ENGINE_CONFIG_GROUP);
    yield all([
      call(getConfigByGroupSaga, GC.RATE_ENGINE_CONFIG_GROUP),
      call(getMarginViolationRuleListRequest),
    ]);
    yield put(closeLoader());
    break;
  }
}

function* ratingConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_RATING_PAGE, handleVisitConfigRatesPageSaga);
  // margin violation rule
  yield takeLatest(A.removeMarginViolationRuleRequest, removeMarginViolationRuleRequest);
  yield takeLatest(A.getMarginViolationRuleListRequest, getMarginViolationRuleListRequest);
  yield takeLatest(A.createOrUpdateMarginViolationRuleRequest, createOrUpdateMarginViolationRuleRequest);
}

export default ratingConfigWatcherSaga;
