import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
// feature dispatch-planner
import { rowHeight, tableMaxHeight } from './table-settings';
import { ROUTE_BUILDER_PADDING_BOTTOM } from '../constants';
//////////////////////////////////////////////////

export const PAGE_HEADER_WRAPPER_HEIGHT = 40;

export const mainSettings = {
  pagePt: 15,
  pagePb: null,
  onlyClo: false,
  contentP: null,
  pageHeight: null,
  totalOrder: null,
  builderOrder: null,
  cloTableHeight: null,
  builderMaxWidth: null,
  withoutSummary: false,
  builderMaxHeight: null,
  withoutRightBar: false,
  withoutPageHeader: false,
  withoutPageMargin: false,
  saveRouteWithoutRates: false,
  shouldNotUnselectLastClo: false,
  pageWrapperMt: GC.PAGE_WRAPPER_MARGIN_TOP,
};

export const pageSizes = {
  rowHeight,
  tableMaxHeight,
  tableHeaderHeight: 40,
  routeSummaryWidth: 170,
  outerHeight: R.sum([
    GC.PAGE_WRAPPER_MARGIN_TOP,
    ROUTE_BUILDER_PADDING_BOTTOM,
    PAGE_HEADER_WRAPPER_HEIGHT,
    100,
  ]),
};
