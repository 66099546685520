import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as C from '../../constants';
// features intl
import * as A from './actions';
import { DEFAULT_TAB } from './constants';
///////////////////////////////////////////////////////////////

const languageTitle = {
  [C.LANG_TYPE_GERMAN]: C.LANG_TYPE_GERMAN,
  [C.LANG_TYPE_ENGLISH]: C.LANG_TYPE_ENGLISH,
  [C.LANG_TYPE_UKRAINIAN]: C.LANG_TYPE_UKRAINIAN,
};

const tabs = [
  { titleLocaleKey: 'titles:enum', pattern: 'enum' },
  { titleLocaleKey: 'titles:menus', pattern: 'menu' },
  { titleLocaleKey: 'titles:actions', pattern: 'action' },
  { titleLocaleKey: 'titles:messages', pattern: 'message' },
  { titleLocaleKey: 'titles:validation', pattern: 'validation' },
  { titleLocaleKey: 'titles:common', pattern: 'common' },
  { titleLocaleKey: 'titles:configs', pattern: 'configs' },
  {
    titleLocaleKey: 'titles:fields',
    subMenu: [
      { titleLocaleKey: 'menu:views:branches', pattern: 'fields:enterprise:' },
      { titleLocaleKey: 'menu:views:users', pattern: 'fields:user' },
      { titleLocaleKey: 'menu:views:roles', pattern: 'fields:role' },
      { titleLocaleKey: 'menu:views:tel', pattern: 'fields:tel' },
      { titleLocaleKey: 'menu:views:clo', pattern: 'fields:clo' },
      { titleLocaleKey: 'menu:views:carriers', pattern: 'fields:carriers' },
      { titleLocaleKey: 'menu:views:locations', pattern: 'fields:location' },
      { titleLocaleKey: 'menu:views:reference-types', pattern: 'fields:reference-types' },
      { titleLocaleKey: 'menu:views:sequences', pattern: 'fields:sequences' },
      { titleLocaleKey: 'menu:views:charts', pattern: 'fields:charts' },
      { titleLocaleKey: 'menu:views:items', pattern: 'fields:item' },
      { titleLocaleKey: 'menu:fleet:driver', pattern: 'fields:fleet-driver' },
      { titleLocaleKey: 'menu:fleet:truck', pattern: 'fields:fleet-truck' },
      { titleLocaleKey: 'menu:fleet:trailer', pattern: 'fields:fleet-trailer' },
      { titleLocaleKey: 'menu:invoices', pattern: 'fields:invoice' },
      { titleLocaleKey: 'titles:all-keys', pattern: 'fields' },

    ],
  },
  {
    titleLocaleKey: 'titles:titles',
    subMenu: [
      { titleLocaleKey: 'titles:a', pattern: 'titles:a' },
      { titleLocaleKey: 'titles:b', pattern: 'titles:b' },
      { titleLocaleKey: 'titles:c', pattern: 'titles:c' },
      { titleLocaleKey: 'titles:d', pattern: 'titles:d' },
      { titleLocaleKey: 'titles:e', pattern: 'titles:e' },
      { titleLocaleKey: 'titles:f', pattern: 'titles:f' },
      { titleLocaleKey: 'titles:g', pattern: 'titles:g' },
      { titleLocaleKey: 'titles:h', pattern: 'titles:h' },
      { titleLocaleKey: 'titles:i', pattern: 'titles:i' },
      { titleLocaleKey: 'titles:j', pattern: 'titles:j' },
      { titleLocaleKey: 'titles:k', pattern: 'titles:k' },
      { titleLocaleKey: 'titles:l', pattern: 'titles:l' },
      { titleLocaleKey: 'titles:m', pattern: 'titles:m' },
      { titleLocaleKey: 'titles:n', pattern: 'titles:n' },
      { titleLocaleKey: 'titles:o', pattern: 'titles:o' },
      { titleLocaleKey: 'titles:p', pattern: 'titles:p' },
      { titleLocaleKey: 'titles:q', pattern: 'titles:q' },
      { titleLocaleKey: 'titles:r', pattern: 'titles:r' },
      { titleLocaleKey: 'titles:s', pattern: 'titles:s' },
      { titleLocaleKey: 'titles:t', pattern: 'titles:t' },
      { titleLocaleKey: 'titles:u', pattern: 'titles:u' },
      { titleLocaleKey: 'titles:v', pattern: 'titles:v' },
      { titleLocaleKey: 'titles:w', pattern: 'titles:w' },
      { titleLocaleKey: 'titles:x', pattern: 'titles:x' },
      { titleLocaleKey: 'titles:y', pattern: 'titles:y' },
      { titleLocaleKey: 'titles:z', pattern: 'titles:z' },
      { titleLocaleKey: 'titles:all-keys', pattern: 'titles:'},
    ],
  },
  {
    titleLocaleKey: 'titles:all-keys',
    subMenu: [
      { titleLocaleKey: 'titles:a', pattern: 'a' },
      { titleLocaleKey: 'titles:b', pattern: 'b' },
      { titleLocaleKey: 'titles:c', pattern: 'c' },
      { titleLocaleKey: 'titles:d', pattern: 'd' },
      { titleLocaleKey: 'titles:e', pattern: 'e' },
      { titleLocaleKey: 'titles:f', pattern: 'f' },
      { titleLocaleKey: 'titles:g', pattern: 'g' },
      { titleLocaleKey: 'titles:h', pattern: 'h' },
      { titleLocaleKey: 'titles:i', pattern: 'i' },
      { titleLocaleKey: 'titles:j', pattern: 'j' },
      { titleLocaleKey: 'titles:k', pattern: 'k' },
      { titleLocaleKey: 'titles:l', pattern: 'l' },
      { titleLocaleKey: 'titles:m', pattern: 'm' },
      { titleLocaleKey: 'titles:n', pattern: 'n' },
      { titleLocaleKey: 'titles:o', pattern: 'o' },
      { titleLocaleKey: 'titles:p', pattern: 'p' },
      { titleLocaleKey: 'titles:q', pattern: 'q' },
      { titleLocaleKey: 'titles:r', pattern: 'r' },
      { titleLocaleKey: 'titles:s', pattern: 's' },
      { titleLocaleKey: 'titles:t', pattern: 't' },
      { titleLocaleKey: 'titles:u', pattern: 'u' },
      { titleLocaleKey: 'titles:v', pattern: 'v' },
      { titleLocaleKey: 'titles:w', pattern: 'w' },
      { titleLocaleKey: 'titles:x', pattern: 'x' },
      { titleLocaleKey: 'titles:y', pattern: 'y' },
      { titleLocaleKey: 'titles:z', pattern: 'z' },
      { titleLocaleKey: 'titles:all-keys', pattern: ''},
    ],
  },
  {
    isDefaultView: true,
    titleLocaleKey: 'titles:custom-locale',
    subMenu: [
      { titleLocaleKey: 'titles:all-keys', pattern: 'custom' },
      { titleLocaleKey: 'titles:a', pattern: 'custom/a' },
      { titleLocaleKey: 'titles:b', pattern: 'custom/b' },
      { titleLocaleKey: 'titles:c', pattern: 'custom/c' },
      { titleLocaleKey: 'titles:d', pattern: 'custom/d' },
      { titleLocaleKey: 'titles:e', pattern: 'custom/e' },
      { titleLocaleKey: 'titles:f', pattern: 'custom/f' },
      { titleLocaleKey: 'titles:g', pattern: 'custom/g' },
      { titleLocaleKey: 'titles:h', pattern: 'custom/h' },
      { titleLocaleKey: 'titles:i', pattern: 'custom/i' },
      { titleLocaleKey: 'titles:j', pattern: 'custom/j' },
      { titleLocaleKey: 'titles:k', pattern: 'custom/k' },
      { titleLocaleKey: 'titles:l', pattern: 'custom/l' },
      { titleLocaleKey: 'titles:m', pattern: 'custom/m' },
      { titleLocaleKey: 'titles:n', pattern: 'custom/n' },
      { titleLocaleKey: 'titles:o', pattern: 'custom/o' },
      { titleLocaleKey: 'titles:p', pattern: 'custom/p' },
      { titleLocaleKey: 'titles:q', pattern: 'custom/q' },
      { titleLocaleKey: 'titles:r', pattern: 'custom/r' },
      { titleLocaleKey: 'titles:s', pattern: 'custom/s' },
      { titleLocaleKey: 'titles:t', pattern: 'custom/t' },
      { titleLocaleKey: 'titles:u', pattern: 'custom/u' },
      { titleLocaleKey: 'titles:v', pattern: 'custom/v' },
      { titleLocaleKey: 'titles:w', pattern: 'custom/w' },
      { titleLocaleKey: 'titles:x', pattern: 'custom/x' },
      { titleLocaleKey: 'titles:y', pattern: 'custom/y' },
      { titleLocaleKey: 'titles:z', pattern: 'custom/z' },
      { titleLocaleKey: 'titles:all-keys', pattern: ''},
    ],
  },
];

const initialState = {
  tabs,
  keys: [],
  languageTitle,
  branchName: null,
  intlSettings: null,
  pageTab: {
    index: 0,
    type: DEFAULT_TAB,
  },
  customKeys: [],
  changedLocaleItems: [],
  masterBranchList: null,
  listLanguageFromBack: [],
  activeTab: R.prop('pattern', R.head(tabs)),
};

const getIntlSuccess = (state: Object, res: Object) => (
  P.$all(
    P.$set('intlSettings', res),
    P.$set('keys', R.keys(res)),
    P.$set('changedLocaleItems', []),
    state,
  )
);

const updateItem = (existingItem: Object, newItem: Object) => {
  if (R.and(
    existingItem,
    R.equals(G.getPropFromObject(C.FIELD_GUID, existingItem), G.getPropFromObject(C.FIELD_GUID, newItem)),
  )) {
    return R.mergeRight(existingItem, newItem);
  }

  return existingItem;
};

const setUpdatedIntlSettings = (state: Array, res: Object) => R.reduce((acc: Array, newItem: Object) => {
  const language = G.getPropFromObject('language', newItem);
  const itemId = G.getPropFromObject(C.FIELD_ITEM_ID, newItem);
  const existingItem = R.path(['intlSettings', itemId, language], acc);

  return R.assocPath(['intlSettings', itemId, language], updateItem(existingItem, newItem), acc);
}, state, R.flatten(res));

const updateIntlSuccess = (state: Array, res: Object) => {
  const updatedIntlSettings = setUpdatedIntlSettings(state, res);

  return P.$all(
    P.$set('intlSettings', R.prop('intlSettings', updatedIntlSettings)),
    P.$set('changedLocaleItems', []),
    updatedIntlSettings,
  );
};

const getIntlCustomizedSuccess = (state: Object, res: Object) => (
  P.$all(
    P.$set('intlSettings', res),
    P.$set('keys', R.keys(res)),
    P.$set('customKeys', R.keys(res)),
    P.$set('changedLocaleItems', []),
    state,
  )
);

const setActiveTab = (state: Object, payload: string) => (
  P.$set('activeTab', payload, state)
);

const getMastersBranchListSuccess = (state: Object, payload: string) => (
  P.$set('masterBranchList', payload, state)
);

const setListLanguageFromBack = (state: Object, payload: string) => (
  P.$set('listLanguageFromBack', payload, state)
);

const setBranchName = (state: Object, payload: string) => (
  P.$set('branchName', payload, state)
);

const deleteIntlSuccess = (state: Object, itemId: string) => (
  P.$set('intlSettings', R.omit([itemId], state.intlSettings), state)
);

const deleteCustomItemSuccess = (state: Object, itemId: string) => (
  P.$all(
    P.$set('intlSettings', R.omit([itemId], state.intlSettings)),
    P.$set('keys', R.without([itemId], state.keys)),
    P.$set('customKeys', R.without([itemId], state.customKeys)),
    state,
  )
);

const setPageTab = (state: Object, pageTab: string) => (
  P.$set('pageTab', pageTab, state)
);

const setInputValueToStore = (state: Object, data: Object) => {
  const newChangedLocaleItems = G.ifElse(
    R.includes(data.name, state.changedLocaleItems),
    state.changedLocaleItems,
    R.append(data.name, state.changedLocaleItems),
  );
  const checkEmptyValue = G.ifElse(R.equals(null, data.value), ' ', data.value);
  return (P.$all(
    P.$set('changedLocaleItems', newChangedLocaleItems),
    P.$set(`intlSettings.${data.name}.value`, checkEmptyValue),
    state,
  ));
};
const setInitialState = () => initialState;

export default createReducer({
  [A.setPageTab]: setPageTab,
  [A.setActiveTab]: setActiveTab,
  [A.setBranchName]: setBranchName,
  [A.getIntlSuccess]: getIntlSuccess,
  [A.setInitialState]: setInitialState,
  [A.deleteIntlSuccess]: deleteIntlSuccess,
  [A.updateIntlSuccess]: updateIntlSuccess,
  [A.setInputValueToStore]: setInputValueToStore,
  [A.deleteCustomItemSuccess]: deleteCustomItemSuccess,
  [A.setListLanguageFromBack]: setListLanguageFromBack,
  [A.getIntlCustomizedSuccess]: getIntlCustomizedSuccess,
  [A.getMastersBranchListSuccess]: getMastersBranchListSuccess,
}, initialState);
