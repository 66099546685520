import React from 'react';
// components
import { PopperWrapper } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Text } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const PopperWrapperComponent = () => {
  const description = '<PopperWrapper /> component is a wrapper for configuring and displaying a popper element.';

  const usage = (
    <ul>
      <li>Parameter `<i>text</i>` is the text to display in the popper.</li>
      <li>Parameter `<i>type</i>` specifies the interaction type for the popper.</li>
      <li>Parameter `<i>additionalStyles</i>` is additional styles to apply to the popper.</li>
      <li>Parameter `<i>icon</i>` is an icon element to display alongside the text in the popper.</li>
      <li>Parameter `<i>popperWithCount</i>` indicates whether the popper includes a count badge.</li>
      <li>Parameter `<i>filterCount</i>` is a count to display as a filter badge within the popper.</li>
      <li>Parameter `<i>popperContent</i>` is the content to render within the popper when it`s active.</li>
    </ul>
  );

  const dummyComponent = (
    <Box p={15}>
      <Text>Filter`s content...</Text>
    </Box>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='PopperWrapper'
      description={description}
      path='src/components/popper'
    >
      <PopperWrapper
        type='common'
        filterCount={5}
        popperWithCount={true}
        icon={I.arrowDownSimple()}
        popperContent={dummyComponent}
        text={G.getWindowLocale('titles:filters', 'Filters')}
      />
    </ComponentStoryWrapper>
  );
};

export default PopperWrapperComponent;
