// components
import { getBranchTypeOptions } from '../../../components/filter/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_BRANCH_ACCOUNT_NAME,
    name: G.getWindowLocale('titles:account-number', 'Account Number'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_ACTIVE,
    name: G.getWindowLocale('titles:active', 'Active'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_PRIMARY_DIVISION,
    name: G.getWindowLocale('titles:primary-division', 'Primary Division'),
  },
  {
    type: 'string',
    value: GC.FIELD_PRIMARY_DIVISION_NAME,
    name: G.getWindowLocale('titles:primary-division-name', 'Primary Division Name'),
  },
  {
    type: 'string',
    value: GC.GRC.PARENT_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:parent', 'Parent')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'boolean',
    value: GC.FIELD_DELETED,
    name: G.getWindowLocale('titles:deleted', 'Deleted'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_SCAC,
    name: G.getWindowLocale('titles:scac', 'SCAC'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_BRANCH_DELETE_DATE,
    name: G.getWindowLocale('titles:delete-date', 'Delete Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DESCRIPTION,
    name: G.getWindowLocale('titles:description', 'Description'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DUNS_NUMBER,
    name: G.getWindowLocale('titles:duns-number', 'D-U-N-S Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_NAME,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DBA,
    name: G.getWindowLocale('titles:dba', 'DBA'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_FEDERAL_EIN,
    name: G.getWindowLocale('titles:federal-ein', 'Federal EIN'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_MC_NUMBER,
    name: G.getWindowLocale('titles:mc-number', 'MC Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_NOTES,
    name: G.getWindowLocale('titles:display-notes', 'Display Notes'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.BROKER_AGENTS_NAME,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.BROKER_AGENTS_ID,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TEL_SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TEL_SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:tel', 'TEL')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CARRIER_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.SALE_PERSONS_NAME,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.SALE_PERSONS_ID,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLO_SALE_REPRESENTATIVES_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLO_SALE_REPRESENTATIVES_ID,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:sale-representatives', 'Sale Representatives')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ACCOUNT_MANAGERS_NAME,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.ACCOUNT_MANAGERS_ID,
    name: `${G.getWindowLocale('titles:account-managers', 'Account Managers')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'boolean',
    value: GC.FIELD_BRANCH_SUSPENDED,
    name: G.getWindowLocale('titles:suspended', 'Suspended'),
  },
  {
    type: 'selectMultiple',
    options: getBranchTypeOptions(),
    value: GC.FIELD_BRANCH_BRANCH_TYPE,
    name: G.getWindowLocale('titles:type', 'Type'),
  },
  {
    type: 'string',
    value: `${GC.FIELD_BRANCH_SUBTYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: G.getWindowLocale('titles:branch-subtype', 'Branch Subtype'),
  },
  {
    type: 'number',
    value: GC.FIELD_BRANCH_CREDIT_LIMIT,
    name: G.getWindowLocale('titles:credit-limit', 'Credit Limit'),
  },
  {
    type: 'number',
    value: GC.FIELD_BRANCH_CREDIT_USED,
    name: G.getWindowLocale('titles:credit-used', 'Credit Used'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_BRANCH_DEACTIVATE_ON_CREDIT_LIMIT,
    name: G.getWindowLocale('titles:deactivate:on-credit-limit', 'Deactivate On Credit Limit'),
  },
  {
    type: 'string',
    value: GC.FIELD_INTEGRATION_ACCOUNT_NUMBER,
    name: G.getWindowLocale('titles:order-import-account-number', 'Order Import Account Number'),
  },
  // NOTE: not implemented on BE for old report. Uncomment when page will be reworked with new report
  // {
  // collection: true,
  // type: 'string:array',
  //   value: `${GC.FIELD_SHARED_TO_DIVISIONS}.${GC.BRANCH_NAME}`,
  //   name: G.getWindowLocale('titles:shared-with-divisions', 'Shared with Divisions'),
  // },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_ZIP}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_CITY}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_STATE}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_COUNTRY}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_COMMENTS}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_ADDRESS_1}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_LOCATION_NAME}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_CONTACT_NAME,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:contact-name', 'titles:contact-name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PRIMARY_LOCATION_EMAILS_FIELD,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:emails', 'Emails')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_PHONE,
    name: `${G.getWindowLocale('titles:primary-location', 'Primary Location')}: ${
      G.getWindowLocale('titles:phone', 'titles:phone')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ZIP}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_CITY}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_STATE}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COUNTRY}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COMMENTS}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_1}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_NAME}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_CONTACT_NAME,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:contact-name', 'titles:contact-name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.BILL_TO_EMAILS_FIELD,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:emails', 'Emails')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_PHONE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:phone', 'titles:phone')}`,
  },
];
