// lists
export const ACTIVE_LIST_MAILS = 'mails';
export const ACTIVE_LIST_ITEMS = 'itemList';
export const ACTIVE_LIST_NOTES = 'noteList';
export const ACTIVE_LIST_CLAIMS = 'claimList';
export const ACTIVE_LIST_DOCUMENTS = 'documents';
export const ACTIVE_LIST_CONTAINERS = 'containerList';
export const ACTIVE_LIST_REFERENCES = 'referenceList';
export const ACTIVE_LIST_LOAD_BOARDS = 'loadBoardList';
export const ACTIVE_LIST_MACRO_POINT = 'macroPointList';
export const ACTIVE_LIST_CHAT_MESSAGES = 'chatMessages';
export const ACTIVE_LIST_VEHICLE_ITEMS = 'vehicleItemList';
export const ACTIVE_LIST_NOTIFICATIONS = 'notificationList';
export const ACTIVE_LIST_CUSTOMER_RATES = 'customerRateList';
export const ACTIVE_LIST_DRIVER_INVOICES = 'driverInvoiceList';
export const ACTIVE_LIST_STATUS_MESSAGES = 'statusMessageList';
export const ACTIVE_LIST_ADVANCE_PAYMENT = 'advancePaymentList';
export const ACTIVE_LIST_CARRIER_INVOICES = 'carrierInvoiceList';
export const ACTIVE_LIST_CUSTOMER_INVOICES = 'customerInvoiceList';
export const ACTIVE_LIST_EXTERNAL_SYSTEM_LIST = 'externalSystemList';
export const ACTIVE_LIST_LINKED_ORDER_REF_LIST = 'linkedOrderRefList';
export const ACTIVE_LIST_CARRIER_FLEET_RATES = 'driverCarrierRateList';
export const ACTIVE_LIST_SERVICE_VENDOR_INVOICES = 'serviceVendorInvoiceList';
export const ACTIVE_LIST_LINKED_CUSTOMER_INVOICES = 'linkedCustomerInvoiceList';
export const ACTIVE_LIST_CROSS_BORDER_INTEGRATION_LIST = 'crossBorderIntegrationList';
// cards
export const CARD_TYPE_PAYMENTS = 'PAYMENTS';
export const CARD_TYPE_DISPATCH = 'DISPATCH';
export const CARD_TYPE_DOCUMENTS = 'DOCUMENTS';
export const CARD_TYPE_DOCUMENTS_POP = 'DOCUMENTS_POP';
export const CARD_TYPE_DOCUMENTS_POD = 'DOCUMENTS_POD';
export const CARD_TYPE_MESSAGE_CENTER = 'MESSAGE_CENTER';
