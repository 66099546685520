import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import {
  sendInvoiceToBCByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import CarrierInvoiceForm from '../../../invoice/components/carrier-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncEditCarrierInvoice } from '../../../../hocs/with-async-edit-carrier-invoice';
// feature dispatch details new
import * as H from '../../helpers';
import { PrintTelDocumentsForm } from '../../forms/print-form';
import {
  sendCarrierToFinancialRequest,
  printLoadCarrierInvoiceRequest,
  removeLoadCarrierInvoiceRequest,
  updateLoadCarrierInvoiceRequest,
  getLoadCarrierInvoiceListRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddCarrierInvoice = compose(
  withHandlers({
    handleAddCarrierInvoice: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        loadTotalWeight,
        loadTotalDistance,
        selectedCarrierDriverRate,
        createLoadCarrierInvoiceRequest,
        createCarrierInvoiceByTelGuidRequest,
        loadConfigs: { configGroups, configsByNames },
      } = props;

      const branchGuid = G.getBranchGuidFromObject(load);
      const telGuid = G.getGuidFromObject(load);
      const totalCustomersRate = H.getTotalCustomerRatesFromClos(R.prop(GC.SYSTEM_LIST_CLOS, load));
      const defaultInvoiceName = R.pathOr('invoice', [GC.FIELD_PRIMARY_REFERENCE_VALUE], load);
      const configs = R.path([GC.INVOICE_CONFIG_GROUP], configGroups);

      const defaultNetDays = G.getConfigValueFromStore(
        GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_NET_DAYS,
        configs,
      );

      const defaultStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const defaultSecondInvoiceStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const separateByCurrency = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SEPARATE_PAYMENT_BY_CURRENCY,
        configs,
      );

      if (separateByCurrency) return createCarrierInvoiceByTelGuidRequest({ telGuid, fromPage: 'details' });

      const handleSendCarrierInvoice = (values: Object) => {
        const data = R.assoc(GC.FIELD_TEL_GUID, telGuid, values);

        createLoadCarrierInvoiceRequest(data);
      };

      const component = (
        <CarrierInvoiceForm
          tel={load}
          closeModal={closeModal}
          branchGuid={branchGuid}
          totalWeight={loadTotalWeight}
          defaultStatus={defaultStatus}
          defaultNetDays={defaultNetDays}
          totalDistance={loadTotalDistance}
          telGuid={G.getGuidFromObject(load)}
          totalCustomersRate={totalCustomersRate}
          defaultInvoiceName={defaultInvoiceName}
          selectedRate={selectedCarrierDriverRate}
          handleSendCarrierInvoice={handleSendCarrierInvoice}
          defaultSecondInvoiceStatus={defaultSecondInvoiceStatus}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadCarrierInvoiceTableGroupRowActions = compose(
  connect(
    null,
    {
      sendCarrierToFinancialRequest,
      printLoadCarrierInvoiceRequest,
      updateLoadCarrierInvoiceRequest,
      removeLoadCarrierInvoiceRequest,
      getLoadCarrierInvoiceListRequest,
      sendInvoiceToBCByInvoiceTypeRequest,
      createQBIIFImportByInvoiceTypeRequest,
      sendInvoiceToSageByInvoiceTypeRequest,
    },
  ),
  withAsyncEditCarrierInvoice({ updateFrom: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD }),
  withHandlers({
    handleSendCarrierInvoiceToFinancial: ({ entity, sendCarrierToFinancialRequest }: Object) => () =>
      sendCarrierToFinancialRequest(R.prop(GC.FIELD_GUID, entity)),
    handleImportCarrierInvoiceQBIIF: (props: Object) => () => {
      const { entity, closeFixedPopup, createQBIIFImportByInvoiceTypeRequest } = props;

      createQBIIFImportByInvoiceTypeRequest({
        type: 'carrier',
        guids: R.of(Array, G.getGuidFromObject(entity)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });

      G.callFunction(closeFixedPopup);
    },
    handleSendCarrierInvoiceToBC: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getLoadCarrierInvoiceListRequest,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(load);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getLoadCarrierInvoiceListRequest({ loadGuid });

      sendInvoiceToBCByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'carrierInvoice',
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleSendCarrierInvoiceToSage: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getLoadCarrierInvoiceListRequest,
        sendInvoiceToSageByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(load);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getLoadCarrierInvoiceListRequest({ loadGuid });

      sendInvoiceToSageByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'carrierInvoice',
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleRemoveCarrierInvoice: (props: Object) => () => {
      const { openModal, entity: { guid }, removeLoadCarrierInvoiceRequest } = props;

      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-invoice',
        'Do you want to delete this Invoice?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeLoadCarrierInvoiceRequest(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePrintCarrierInvoice: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        printLoadCarrierInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);

      const component = (
        <PrintTelDocumentsForm
          selectedEntity={guid}
          branchGuid={branchGuid}
          closeModal={closeModal}
          telGuid={G.getGuidFromObject(load)}
          submitAction={printLoadCarrierInvoiceRequest}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
    handlePrintCarrierInvoiceWithImages: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        loadDocuments,
        printLoadCarrierInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);
      const documentsOptions = G.getPrintDocumentOptions(loadDocuments);

      const printInvoiceProps = {
        closeModal,
        branchGuid,
        documentsOptions,
        withDocuments: true,
        selectedEntity: guid,
        submitAction: printLoadCarrierInvoiceRequest,
        [GC.FIELD_TEL_GUID]: G.getGuidFromObject(load),
        printableSection: GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE,
      };

      const component = <PrintTelDocumentsForm {...printInvoiceProps} />;

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
  }),
);
