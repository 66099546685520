import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { FooterBtns } from '../../../components/footer-btns';
import CompensationRangeGroup from '../../../components/compensation-range-group';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { STATE_OPTIONS, COUNTRY_OPTIONS } from '../../../helpers/options';
// forms
import { FieldsetComponent } from '../../../forms';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature carrier
import * as LC from '../constants';
import { LocationFieldsWrapper } from '../ui';
import { CarrierAssessorials } from './carrier-assessorial';
import {
  carrierRateDatesSection,
  defaultCarrierRateFields,
  carrierRateLocationFields,
  compensationDetailsFields,
  setInitialLocationFormikValues,
  carrierRateGeoFencingZoneFields,
  getCarrierRateValidationSchemaObject } from '../settings/fields-settings';
///////////////////////////////////////////////////////////////////////////////////////////////////

const setLocationValue = (
  values: Object,
  valuePropName: string,
  fieldName: string,
  setFieldValue: Function,
) => {
  const value = R.prop(valuePropName, values);
  const currentValuesByPath = R.pathOr([], [fieldName], values);
  if (R.and(
    G.isNotNilAndNotEmpty(value),
    G.notContain(value, currentValuesByPath),
  )) {
    setFieldValue(fieldName, R.append(value, currentValuesByPath));
  }
};

export const setInitialOptions = (constant: string) => (props: Object) => (R.map(
  (value: string) => ({ label: value, value }),
  R.pathOr([], ['initialValues', constant], props),
));

export const setOptionsAndValue = (
  fieldId: string,
  newValue: string,
  formValues: Object,
  props: Object,
) => {
  const labelStrings = R.split(':', fieldId);
  const locationType = R.head(labelStrings);
  const property = R.last(labelStrings);
  const cityField = R.equals(property, GC.FIELD_CARRIER_RATE_CITY);
  const path = G.ifElse(
    cityField,
    `${locationType}:${GC.FIELD_CARRIER_RATE_CITIES}`,
    `${locationType}:${GC.FIELD_CARRIER_RATE_ZIP_CODES}`,
  );
  const currentValuesByPath = R.pathOr([], [path], formValues);
  const functionsForSetOptions = {
    [LC.FIELD_CARRIER_RATE_ORIGIN_CITY]: props.setOriginCityOptions,
    [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP]: props.setOriginZipCodesOptions,
    [LC.FIELD_CARRIER_RATE_DESTINATION_CITY]: props.setDestinationCityOptions,
    [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP]: props.setDestinationZipCodesOptions,
  };
  if (R.and(
    G.notContain(newValue, currentValuesByPath),
    G.isNotNilAndNotEmpty(newValue),
  )) {
    R.propOr(() => {}, fieldId, functionsForSetOptions)(
      (prev: Array) => R.append({label: newValue, value: newValue}, prev),
    );
    props.setFieldValue(path, R.append(newValue, currentValuesByPath));
  }
  if (cityField) {
    if (R.includes(R.prop('state', formValues), R.map(R.prop('value'), STATE_OPTIONS))) {
      setLocationValue(
        formValues,
        'state',
        `${locationType}:${GC.FIELD_CARRIER_RATE_STATES}`,
        props.setFieldValue,
      );
    }
    if (R.includes(R.prop('country', formValues), R.map(R.prop('value'), COUNTRY_OPTIONS))) {
      setLocationValue(
        formValues,
        'country',
        `${locationType}:${GC.FIELD_CARRIER_RATE_COUNTRIES}`,
        props.setFieldValue,
      );
    }
  }
  props.setFieldValue(fieldId, '');
};

export const makeValuesForRequest = (values: Object, mainFields: Array, omitFields: Array) => {
  let valuesForRequest = R.pick(mainFields, values);
  R.forEachObjIndexed((value: any, label: string) => {
    const labelStrings = R.split(':', label);
    if (R.or(
      R.includes(LC.FIELD_CARRIER_RATE_ORIGIN, labelStrings),
      R.includes(LC.FIELD_CARRIER_RATE_DESTINATION, labelStrings),
    )) {
      valuesForRequest = R.assocPath(labelStrings, value, valuesForRequest);
    }
  }, R.omit(omitFields, values));

  return valuesForRequest;
};

const rateAssessorialsCRUD = withHandlers({
  handleAddSharedAccessorials: ({ setRateAssessorials }: Object) => async (guid: Object) => {
    const options = { params: { ratePriceGuid: guid }};
    const { data } = await sendRequest('get', endpointsMap.carrierContractRateAssessorialList, options);
    setRateAssessorials(data);
  },
  handleCreateRateAssessorial: (props: Object) => async (data: Object) => {
    try {
      props.openLoader();
      const res = await sendRequest('post', endpointsMap.carrierContractRateAssessorial, { data });
      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple('success', G.getWindowLocale('messages:success:200-201', 'The request has succeeded'));
        const newValues = R.concat(props.rateAssessorials, R.of(Array, res.data));
        props.setRateAssessorials(newValues);
        props.closeModal();
      } else {
        G.handleFailResponseSimple(res);
      }
      props.closeLoader();
    } catch (error) {
      props.closeLoader();
      G.handleException(error, 'handleCreateRateAssessorial');
      G.showToastrMessageSimple(
        'error',
        G.getWindowLocale('messages:error:unknown', 'Oops! The system is experiencing a problem. The issue has been reported.')
      );
    }
  },
  handleUpdateRateAssessorial: (props: Object) => async (data: Object) => {
    try {
      props.openLoader();
      const res = await sendRequest('put', endpointsMap.carrierContractRateAssessorial, { data });
      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple('success', G.getWindowLocale('messages:success:200-201', 'The request has succeeded'));
        const newValues = R.map((assessorial: Object) => {
          if (G.notEquals(data.guid, assessorial.guid)) return assessorial;
          return data;
        }, props.rateAssessorials);
        props.setRateAssessorials(newValues);
        props.closeModal();
      } else {
        G.handleFailResponseSimple(res);
      }
      props.closeLoader();
    } catch (error) {
      props.closeLoader();
      G.handleException(error, 'handleUpdateRateAssessorial');
      G.showToastrMessageSimple(
        'error',
        G.getWindowLocale('messages:error:unknown', 'Oops! The system is experiencing a problem. The issue has been reported.')
      );
    }
  },
  handleDeleteRateAssessorial: (props: Object) => async (entityGuid: Object) => {
    try {
      props.openLoader();
      const res = await sendRequest('delete', endpointsMap.getCarrierContractRateAssessorial(entityGuid));
      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple('success', G.getWindowLocale('messages:success:204', 'Successfully deleted'));
        const newValues = R.filter(({ guid }: Object) => G.notEquals(guid, entityGuid), props.rateAssessorials);
        props.setRateAssessorials(newValues);
      } else {
        G.handleFailResponseSimple(res);
      }
      props.closeLoader();
    } catch (error) {
      props.closeLoader();
      G.handleException(error, 'handleDeleteRateAssessorial');
      G.showToastrMessageSimple(
        'error',
        G.getWindowLocale('messages:error:unknown', 'Oops! The system is experiencing a problem. The issue has been reported.')
      );
    }
  },
});

const enhance = compose(
  withAsyncConfigs,
  withState(
    LC.ORIGIN_ZIP_CODES_OPTIONS,
    'setOriginZipCodesOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES),
  ),
  withState(
    LC.DESTINATION_ZIP_CODES_OPTIONS,
    'setDestinationZipCodesOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES),
  ),
  withState(
    LC.ORIGIN_CITY_OPTIONS,
    'setOriginCityOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_ORIGIN_CITIES),
  ),
  withState(
    LC.DESTINATION_CITY_OPTIONS,
    'setDestinationCityOptions',
    setInitialOptions(LC.FIELD_CARRIER_RATE_DESTINATION_CITIES),
  ),
  withState(
    LC.CARRIER_CONTRACT_RATE_GROUP_NAME_ASSESSORIALS,
    'setToggle',
    true,
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => {
      const services = R.map(
        R.prop(GC.FIELD_DROPDOWN_OPTION_GUID),
        R.pathOr([], [GC.FIELD_CARRIER_SERVICE_TYPES], props.initialValues),
      );
      const equipments = R.map(
        R.prop(GC.FIELD_DROPDOWN_OPTION_GUID),
        R.pathOr([], [GC.FIELD_CARRIER_EQUIPMENTS], props.initialValues),
      );
      return setInitialLocationFormikValues(
        defaultCarrierRateFields,
        {
          ...props.initialValues,
          [GC.FIELD_CARRIER_EQUIPMENTS]: equipments,
          [GC.FIELD_CARRIER_SERVICE_TYPES]: services,
        },
        props.searchedValues,
      );
    },
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getCarrierRateValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props, resetForm, setSubmitting }: Object) => {
      const { saveAndAddNew } = values;
      const { carrierGuid, contractGuid, submitAction } = props;

      const valuesForRequest = makeValuesForRequest(
        values,
        LC.CARRIER_RATE_MAIN_FIELDS,
        LC.CARRIER_RATE_MAIN_FIELDS_OMIT,
      );
      const origin = G.ifElse(
        G.isNilOrEmpty(R.prop(GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID, values)),
        R.prop(LC.FIELD_CARRIER_RATE_ORIGIN, valuesForRequest),
        null,
      );
      const destination = G.ifElse(
        G.isNilOrEmpty(R.prop(GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID, values)),
        R.prop(LC.FIELD_CARRIER_RATE_DESTINATION, valuesForRequest),
        null,
      );
      const originZoneGuid = G.ifElse(
        G.isNilOrEmpty(R.path([GC.FIELD_CARRIER_RATE_COUNTRIES], origin)),
        R.prop(GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID, valuesForRequest),
        null,
      );
      const destinationZoneGuid = G.ifElse(
        G.isNilOrEmpty(R.path([GC.FIELD_CARRIER_RATE_COUNTRIES], destination)),
        R.prop(GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID, valuesForRequest),
        null,
      );

      submitAction({
        ...R.mergeRight(valuesForRequest, { origin, destination, originZoneGuid, destinationZoneGuid }),
        resetForm,
        carrierGuid,
        contractGuid,
        setSubmitting,
        saveAndAddNew,
      });
    },
    displayName: LC.CARRIER_RATE_FORM,
  }),
  withState('rateAssessorials', 'setRateAssessorials', ({ assessorials }: Object) => assessorials),
  withHandlers({
    customSetValues: (props: Object) => (values: Object) => {
      const labelStrings = R.split(':', values.label);
      const property = R.last(labelStrings);
      setOptionsAndValue(values.label, values[property], values, props);
    },
    handleChangeSelect: (props: Object) => (id: any, data: Array) => {
      const functionsForSetOptions = {
        [LC.FIELD_CARRIER_RATE_ORIGIN_CITIES]: props.setOriginCityOptions,
        [LC.FIELD_CARRIER_RATE_ORIGIN_ZIP_CODES]: props.setOriginZipCodesOptions,
        [LC.FIELD_CARRIER_RATE_DESTINATION_CITIES]: props.setDestinationCityOptions,
        [LC.FIELD_CARRIER_RATE_DESTINATION_ZIP_CODES]: props.setDestinationZipCodesOptions,
      };
      R.propOr(() => {}, id, functionsForSetOptions)(
        (prev: Array) => prev.filter((option: Object) => (
          R.includes(option.value, data)
        ),
      ));
    },
    handleCustomChange: (props: Object) => (e: Object) => {
      if (R.equals(R.path(['target', 'id'], e), GC.FIELD_CHARGE_RATE_TYPE)) {
        const newValues = {
          [GC.FIELD_CHARGE_RATE_TYPE]: R.path(['target', 'value'], e),
          [GC.FIELD_CHARGE_RATE_UNIT]: '',
        };
        if (R.equals(R.path(['target', 'value'], e), GC.CHARGE_RATE_TYPE_FLAT)) {
          newValues[GC.FIELD_CHARGE_MIN_RATE] = '';
          newValues[GC.FIELD_CHARGE_MAX_RATE] = '';
        }
        return props.setValues(R.mergeRight(props.values, newValues));
      }
      props.handleChange(e);
    },
    handleEnter: (props: Object) => (value: string, id: string) => {
      setOptionsAndValue(id, value, props.values, props);
    },
  }),
  rateAssessorialsCRUD,
  pure,
);

export const setOptions = (props: Object, field: Object) => {
  const shouldSetOptions = R.and(
    R.equals(field.fieldName, GC.FIELD_CHARGE_RATE_UNIT),
    R.includes(
      R.path(['values', GC.FIELD_CHARGE_RATE_TYPE], props),
      LC.CARRIER_RATE_TYPE_CONTAIN,
    ),
  );
  if (shouldSetOptions) {
    return R.path(['optionsGroups', R.path(['values', GC.FIELD_CHARGE_RATE_TYPE], props)], field);
  }
  return field.options;
};

export const getLoadOptionsForRateSelect = (props: Object) => {
  const serviceType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  );
  const equipments = props.equipments.map(({ displayedValue, dropdownOptionGuid }: Object) => ({
    label: displayedValue,
    value: dropdownOptionGuid,
  }));
  return { serviceType, equipments };
};

const darkBlueColor = G.getTheme('colors.dark.blue');

export const CarrierRateForm = (props: Object) => (
  <Box maxHeight='90vh' overflow='auto'>
    <form onSubmit={props.handleSubmit}>
      <Flex alignItems='flex-start' flexDirection='column'>
        <Box p='10px 15px' zIndex='15'>
          <FieldsetComponent
            {...props}
            setValues={props.customSetValues}
            fields={carrierRateDatesSection.fields} />
        </Box>
        <Box
          zIndex='14'
          width='100%'
          p='10px 15px'
          borderTop='solid 1px'
          borderColor={G.getTheme('colors.light.darkGrey')}
        >
          <FieldsetComponent
            {...G.getFormikProps(props)}
            fields={carrierRateGeoFencingZoneFields}
            optionsForSelect={{ geoFencingZoneList: G.addEmptyOptionToDropDown(props.geoFencingZoneList) }} />
        </Box>
        <Flex
          p='10px'
          zIndex='14'
          borderTop='solid 1px'
          alignItems='flex-start'
          borderColor={G.getTheme('colors.light.darkGrey')}
        >
          {carrierRateLocationFields.map((item: Object, index: number) => (
            <LocationFieldsWrapper key={index}>
              <Box fontSize='14px' fontWeight='bold' m='10px 0 5px 10px' color={darkBlueColor}>
                {G.getWindowLocale(item.title)}
              </Box>
              <FieldsetComponent
                {...props}
                fields={item.fields}
                fieldsGroupWidth='50%'
                fieldsetPadding='0 0 0 5px'
                setValues={props.customSetValues}
                optionsForSelect={R.pick(LC.CARRIER_RATE_OPTIONS_PICK, props)}
                handlers={{
                  handleEnter: props.handleEnter,
                  handleChangeSelect: props.handleChangeSelect,
                }} />
            </LocationFieldsWrapper>
          ))}
        </Flex>
        <Flex
          zIndex={13}
          p='0 15px 10px'
          borderTop='solid 1px'
          flexDirection='column'
          alignItems='flex-start'
        >
          <Box fontSize='14px' fontWeight='bold' m='10px 0 5px 10px' color={darkBlueColor}>
            {G.getWindowLocale('titles:compensation-details', 'Compensation Details')}
          </Box>
          <FieldsetComponent
            {...props}
            setValues={props.customSetValues}
            fields={compensationDetailsFields}
            handleChange={props.handleCustomChange}
            optionsForSelect={getLoadOptionsForRateSelect(props)}
            setOptionsFunction={(field: Object) => setOptions(props, field)} />
        </Flex>
        <CompensationRangeGroup
          {...G.getFormikProps(props)}
          initialValues={props.initialValues} />
        {
          props.isEditMode &&
          <Box width='100%'>
            <CarrierAssessorials
              {...props}
              relatedEntityField='ratePriceGuid'
              entities={props.rateAssessorials}
              deleteAction={props.handleDeleteRateAssessorial}
              updateAction={props.handleUpdateRateAssessorial}
              createAction={props.handleCreateRateAssessorial}
              relatedGuid={R.pathOr('', ['initialValues', 'guid'], props)}
              groupName={LC.CARRIER_CONTRACT_RATE_GROUP_NAME_ASSESSORIALS}
              addSharedAccessorialsEndpoint='addRatePriceSharedAccessorials'
              successSharedAccessorialsAction={props.handleAddSharedAccessorials}
              removeSharedAccessorialsEndpoint='removeRatePriceSharedAccessorials'
              collapsedGroup={R.pick([LC.CARRIER_CONTRACT_RATE_GROUP_NAME_ASSESSORIALS], props)}
              panelTitle={G.getWindowLocale('titles:carrier-contract-accessorials', 'Carrier Contract Accessorials')}
              handleToggleFormGroup={() =>
                props.setToggle(R.not(props[LC.CARRIER_CONTRACT_RATE_GROUP_NAME_ASSESSORIALS]))} />
          </Box>
        }
        <FooterBtns
          closeModal={props.closeModal}
          isSubmitting={props.isSubmitting}
          saveAndAddNew={R.not(props.isEditMode)}
          submitAction={() => props.setFieldValue('saveAndAddNew', false)}
          handleSaveAndAddNew={() => props.setFieldValue('saveAndAddNew', true)} />
      </Flex>
    </form>
  </Box>
);

export default enhance(CarrierRateForm);
