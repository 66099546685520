
import React, { useEffect } from 'react';
// features
import CarrierStatisticReport from '../../carrier-statistic-report';
import { getReportDataRequest } from '../../carrier-statistic-report/actions';
//////////////////////////////////////////////////

const Component = ({ dispatch, carrierName, primaryObjectGuid }: Object) => {
  useEffect(() => {
    dispatch(getReportDataRequest(primaryObjectGuid));
  }, []);

  return <CarrierStatisticReport padding={15} carrierName={carrierName} maxHeight='calc(100% - 860px)' />;
};

export const statisticSettings = {
  Component,
  groupName: 'statistic',
};
