import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
// features
import { loadColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, RelativeBox } from '../../../ui';
// feature dispatch-planner
import { defaultCloTelsReport } from '../settings/default-report';
//////////////////////////////////////////////////

const rowHeight = 30;

const cloTelsTableSettings = {
  coloredHeader: true,
  useMainColors: true,
  allowSelectItems: false,
  checkBoxCellWidth: '0px',
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

const mapData = (tels: Array = []) => R.map(
  (tel: Object) => ({
    [GC.FIELD_LOAD_STATUS]: R.prop('telStatus', tel),
    [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: R.prop(GC.FIELD_BRANCH_NAME, tel),
    [GC.GRC.PRIMARY_REFERENCE_VALUE]: R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_REFERENCE, GC.FIELD_VALUE], tel),
  }),
  tels,
);

const eventOptions = [
  {
    name: 'titles:stop',
    key: GC.FIELD_CLO_EVENT_INDEX,
  },
  { key: 'eventType' },
  {
    key: GC.FIELD_EARLY_DATE,
    name: 'titles:early-date',
  },
  {
    name: 'titles:late-date',
    key: GC.FIELD_LOAD_EVENT_LATE_DATE,
  },
];

const renderTable = ({ data }: Object) => {
  if (G.isNilOrEmpty(data)) return null;
  const tableData = {
    columnSettings,
    totalCount: data.length,
    report: defaultCloTelsReport,
    itemList: mapData(data.tels),
    tableSettings: cloTelsTableSettings,
    pagination: {
      limit: data.length,
      offset: data.length,
    },
  };
  return (
    <Table {...tableData} />
  );
};

export const renderEvents = (props: Object) => {
  const { data } = props;

  if (G.isNilOrEmpty(data.events)) return null;

  return (
    <Box fontSize={12}>
      {
        data.events.map((event: Object, i: number) => (
          <Flex p='6px' key={`route-order-event-${i}-${event.guid}`}>
            {
              eventOptions.map((option: Object, optionIndex: number) => (
                <Flex mr='6px' key={`route-order-event-option-${optionIndex}-${event.guid}`}>
                  {
                    G.isNotNil(option.name) &&
                    <Box mr='6px' fontWeight={700}>{G.getWindowLocale(option.name, '')}:</Box>
                  }
                  <Box>{R.propOr('-', option.key, event)},</Box>
                </Flex>
              ))
            }
            <Box mr='6px' fontWeight={700}>{G.getWindowLocale('titles:address', 'Address')}:</Box>
            <Box mr='6px'>{G.concatLocationFirstAndSecondAddresses(R.prop(GC.FIELD_LOCATION, event))}</Box>
            <Box>{G.concatLocationFields(R.prop(GC.FIELD_LOCATION, event))}</Box>
          </Flex>
        ))
      }
    </Box>
  );
};

const OrderDetails = (props: Object) => (
  <RelativeBox
    zIndex='0'
    key={props.key}
    pl={props.margin}
    width='fit-content'
    boxShadow='inset -1px -1px 0px 1px rgba(221, 221, 221, 0.5)'
  >
    {renderEvents(props)}
    {renderTable(props)}
  </RelativeBox>
);

export default OrderDetails;

