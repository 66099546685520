import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 1000,
    name: 'titles:name',
  },
};

export const report = {
  fields: R.of(Array, { sequence: 1, name: GC.FIELD_NAME }),
};

export const geoFencingZoneDetailsColumnSettings = {
  [GC.FIELD_GEO_FENCING_ZONE_CITIES]: {
    width: 500,
    name: 'titles:cities',
    customComponent: ({ data }: Object) =>
      R.join(', ', R.prop(GC.FIELD_GEO_FENCING_ZONE_CITIES, data)),
  },
  [GC.FIELD_GEO_FENCING_ZONE_STATES]: {
    width: 500,
    name: 'titles:states',
    customComponent: ({ data }: Object) =>
      R.join(', ', R.prop(GC.FIELD_GEO_FENCING_ZONE_STATES, data)),
  },
  [GC.FIELD_GEO_FENCING_ZONE_COUNTRIES]: {
    width: 500,
    name: 'titles:countries',
    customComponent: ({ data }: Object) =>
      R.join(', ', R.prop(GC.FIELD_GEO_FENCING_ZONE_COUNTRIES, data)),
  },
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES]: {
    width: 300,
    name: 'titles:zip-codes',
    customComponent: ({ data }: Object) =>
      R.join(', ', R.prop(GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES, data)),
  },
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: {
    width: 100,
    name: 'titles:zip-to',
  },
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: {
    width: 100,
    name: 'titles:zip-from',
  },
};

const commonReportFields = [
  { sequence: 2, name: GC.FIELD_GEO_FENCING_ZONE_STATES },
  { sequence: 3, name: GC.FIELD_GEO_FENCING_ZONE_COUNTRIES },
  { sequence: 4, name: GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES },
  { sequence: 6, name: GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO },
  { sequence: 5, name: GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM },
];

export const ratingGeoFencingZoneDetailsReport = {
  fields: R.prepend({ sequence: 1, name: GC.FIELD_GEO_FENCING_ZONE_CITIES }, commonReportFields),
};

export const reportGeoFencingZoneDetailsReport = {
  fields: commonReportFields,
};
