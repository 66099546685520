import * as R from 'ramda';
import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// components
import { TextComponent } from '../../../../components/text';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
import { changeUserPasswordRequest } from '../../../profile/actions';
// forms
import { ChangePasswordForm } from '../../../../forms/forms/change-password-form';
// helpers/constants
import * as G from '../../../../helpers';
// hooks
import { useModalActions } from '../../../../hooks';
// ui
import { ActionButton } from '../../../../ui';
// feature fleet-profile
import { selectUserGuid } from '../../selectors';
//////////////////////////////////////////////////

const ChangePassword = () => {
  const userGuid = useSelector(selectUserGuid);

  const dispatch = useDispatch();

  const { openModal, closeModal } = useModalActions();

  const handleOpenChangePasswordForm = () => {
    const submitAction = (values: Object) => dispatch(changeUserPasswordRequest(R.assoc('userGuid', userGuid, values)));

    const component = <ChangePasswordForm closeModal={closeModal} changePasswordRequest={submitAction} />;

    const modal = G.getDefaultModalOptions(
      component,
      G.getWindowLocale('titles:change-password', 'Change Password'),
    );

    openModal(modal);
  };

  return (
    <Fragment>
      <TextComponent
        fontSize={11}
        display='block'
        withEllipsis={true}
        maxWidth='calc(100% - 105px)'
        color={G.getTheme('colors.greyMatterhorn')}
      >
        {G.getWindowLocale('titles:driver-login-id', 'Driver Login ID')}
      </TextComponent>
      <AuthWrapper has={[PC.USER_WRITE]}>
        <ActionButton
          height={16}
          fontSize={11}
          type='button'
          minWidth='unset'
          borderRadius='3px'
          onClick={handleOpenChangePasswordForm}
        >
          {G.getWindowLocale('actions:change-password', 'Change Password')}
        </ActionButton>
      </AuthWrapper>
    </Fragment>
  );
};

export default ChangePassword;
