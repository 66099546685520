import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature fleet-list
import * as A from './actions';
import { getItemDisabledByReportType } from './settings';
//////////////////////////////////////////////////

const defaultState = {
  itemList: {},
  totalCount: 0,
  reportType: null,
  filterParams: {},
  usedReport: null,
  filterProps: null,
  titleSortValues: {},
  reportPending: false,
  tableTitleFilters: {},
  availableReports: null,
  globalFilterValue: null,
  pagination: { limit: 20, offset: 0 },
};

const fleetProfileInitSettings = {
  fleetProfileType: null,
  fleetProfileGuid: null,
  fleetProfileGuidType: null,
};

export const initialState = {
  reportType: null,
  fleetProfileReportType: null,
  fleetProfileSettings: fleetProfileInitSettings,
};

const setInitialState = (state: Object, reportType: Object) => (
  P.$all(
    P.$set('reportType', reportType),
    P.$set(reportType, defaultState),
    P.$set('fleetProfileSettings', fleetProfileInitSettings),
    state,
  )
);

// report
const getItemListSuccess = (state: Object, data: Object) => {
  const { reportType } = state;

  const { itemList, pagination } = R.pathOr({}, [reportType], state);

  const { results, totalCount } = data;

  const isTrailerShared = R.path(['fleetProfileSettings', 'isTrailerShared'], state);

  if (R.equals(totalCount, 1)) {
    const item = R.head(results);

    const { guid } = item;

    const existentItem = R.prop(guid, itemList);

    if (G.isNotNil(existentItem)) {
      return P.$set(`${reportType}.itemList.${guid}`, R.mergeRight(existentItem, item), state);
    }

    const reportItem = {
      ...item,
      selected: false,
      expanded: false,
      index: R.length(R.values(itemList)),
      disabled: getItemDisabledByReportType({ item, reportType, isTrailerShared }),
      notExpandable: R.and(
        isTrailerShared,
        G.notPropEq(G.getAmousCurrentUserLoginIdFromWindow(), GC.FIELD_CREATED_BY, item),
      ),
    };

    return P.$all(
      P.$set(`${reportType}.itemList.${guid}`, reportItem),
      P.$set(`${reportType}.pagination.offset`, R.inc(pagination.offset)),
      P.$set(`${reportType}.totalCount`, R.inc(R.pathOr(0, [reportType, 'totalCount'], state))),
      state,
    );
  }

  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );
  const list = R.compose(
    R.mergeRight(itemList),
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) =>
      R.mergeRight(
        item,
        {
          selected: false,
          expanded: false,
          index: R.add(index, indexAdditional),
          disabled: getItemDisabledByReportType({ item, reportType, isTrailerShared }),
          notExpandable: R.and(
            isTrailerShared,
            G.notPropEq(G.getAmousCurrentUserLoginIdFromWindow(), GC.FIELD_CREATED_BY, item),
          ),
        }),
    ),
  )(results);
  const newOffset = R.sum(R.values(pagination));

  return P.$all(
    P.$set(`${reportType}.itemList`, list),
    P.$set(`${reportType}.totalCount`, totalCount),
    P.$set(
      `${reportType}.pagination.offset`,
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const resetListAndPagination = (state: Object) => {
  const { reportType } = state;

  return P.$all(
    P.$set(`${reportType}.itemList`, {}),
    P.$set(`${reportType}.totalCount`, 0),
    P.$set(`${reportType}.pagination`, defaultState.pagination),
    state,
  );
};

const selectItem = (state: Object, id: Object) => {
  const { reportType } = state;

  const { itemList } = R.pathOr({}, [reportType], state);

  if (R.equals(id, 'all')) {
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));

    return P.$set(
      `${reportType}.itemList`,
      R.map((item: Object) => R.assoc('selected', value, item), itemList),
      state,
    );
  }

  return P.$toggle(`${reportType}.itemList.${id}.selected`, state);
};

const setUsedReport = (state: Object, data: Object) => {
  const { reportType } = state;

  return P.$all(
    P.$set(`${reportType}.itemList`, {}),
    P.$set(`${reportType}.totalCount`, 0),
    P.$set(`${reportType}.usedReport`, data),
    P.$set(`${reportType}.titleSortValues`, {}),
    P.$set(`${reportType}.reportPending`, false),
    P.$set(`${reportType}.tableTitleFilters`, {}),
    P.$set(`${reportType}.globalFilterValue`, ''),
    P.$set(`${reportType}.pagination`, defaultState.pagination),
    state,
  );
};

const setReportPending = (state: Object) => P.$set(`${state.reportType}.reportPending`, true, state);

const setListLoading = (state: Object, loading: boolean) => P.$set(`${state.reportType}.loading`, loading, state);

const setReports = (state: Object, reports: Array) => P.$set(`${state.reportType}.availableReports`, reports, state);

const setReportType = (state: Object, reportType: boolean) => P.$set('reportType', reportType, state);

const setFilterProps = (state: Object, data: Object) => P.$set(`${state.reportType}.filterProps`, data, state);

const setGlobalFilterValue = (state: Object, data: string) =>
  P.$set(`${state.reportType}.globalFilterValue`, data, state);
// report

// item
const removeItemSuccess = (state: Object, data: Array) => {
  const { reportType } = state;

  const { itemList, totalCount } = R.pathOr({}, [reportType], state);

  const count = R.length(data);
  const newList = R.omit(data, itemList);
  const newTotalCount = R.subtract(totalCount, count);
  const newOffset = R.subtract(R.path(['pagination', 'offset'], state), count);

  return P.$all(
    P.$set(`${reportType}.itemList`, newList),
    P.$set(`${reportType}.totalCount`, newTotalCount),
    P.$set(`${reportType}.pagination.offset`, newOffset),
    state,
  );
};

const createFleetItemSuccess = (state: Object, data: Object) => {
  const { reportType } = state;

  const { itemList, totalCount, pagination } = R.pathOr({}, [reportType], state);

  const item = R.mergeRight(data, { selected: false, index: R.length(R.values(itemList)) });

  return P.$all(
    P.$set(`${reportType}.totalCount`, R.inc(totalCount)),
    P.$set(`${reportType}.itemList.${G.getGuidFromObject(data)}`, item),
    P.$set(`${reportType}.pagination.offset`, R.inc(pagination.offset)),
    state,
  );
};

const updateFleetItemSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const { reportType } = state;

  const { itemList } = R.pathOr({}, [reportType], state);

  const item = R.mergeRight(G.getPropFromObject(guid, itemList), data);

  return P.$set(`${reportType}.itemList.${guid}`, item, state);
};

const toggleItemDetails = (state: Object, item: Object) => (
  P.$toggle(`${state.reportType}.itemList.${G.getGuidFromObject(item)}.expanded`, state)
);

const setItemDetails = (state: Object, { details, primaryObjectGuid }: Object) => (
  P.$set(`${state.reportType}.itemList.${primaryObjectGuid}.details`, details, state)
);

// fleet profile
const setFleetProfileSettings = (state: Object, settings: Object = fleetProfileInitSettings) => (
  P.$set('fleetProfileSettings', settings, state)
);

// driver onboarding
const getDriverListByDriverOnboardingGuidSuccess = (state: Object, { data, driverOnboardingGuid }: Object) => {
  const { reportType } = state;

  const drivers = R.map((item: Object) => {
    const amousDriver = R.pathOr({}, ['amousDriver'], item);
    const contactDetails = R.pathOr({}, ['contactDetails'], item);
    const licenseDetails = R.pathOr({}, ['licenseDetails'], item);

    return R.mergeAll([
      contactDetails,
      licenseDetails,
      amousDriver,
      R.pick(['errorMessage', GC.FIELD_STATUS, GC.FIELD_EXTERNAL_ID], item),
    ]);
  }, R.or(data, []));

  return P.$set(`${reportType}.itemList.${driverOnboardingGuid}.drivers`, drivers, state);
};

// configs
const getConfigsByNamesSuccess = (state: Object, data: Object) => {
  const { reportType } = state;

  return P.$set(`${reportType}.configsByNames`, data, state);
};

const getAccessorialConfigListSuccess = (state: Object, data: Object) => {
  const accessorialConfigList = R.compose(
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
    R.map((item: Object) => R.compose(
      R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
      R.pick([
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
        GC.FIELD_DISPLAYED_VALUE,
        GC.FIELD_CHARGE_RATE_TYPE,
        GC.FIELD_CHARGE_RATE_UNIT,
      ]),
    )(item)),
  )(data);

  const accessorialConfigOptions = R.map(({ displayedValue, originalConfigGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: originalConfigGuid,
  }), data);

  G.setItemToWindow('accessorialConfigOptions', accessorialConfigOptions);

  return P.$set('accessorialConfigs', { accessorialConfigList, accessorialConfigOptions }, state);
};

const setTableTitleFilter = (state: Object, data: Object) => {
  const { reportType } = state;

  const { tableTitleFilters } = R.pathOr({}, [reportType], state);

  const filterValues = G.getTableTitleFilterValues(tableTitleFilters, data);

  return P.$set(`${reportType}.tableTitleFilters`, filterValues, state);
};

const setTableTitleSort = (state: Object, data: Object) => {
  const { reportType } = state;

  const { titleSortValues } = R.pathOr({}, [reportType], state);

  const sortValues = G.getTableTitleSortValues(titleSortValues, data);

  return P.$set(`${reportType}.titleSortValues`, sortValues, state);
};

// payroll accessorial
const getPayrollAccessorialChargesByPayrollAccessorialGuidSuccess = (state: Object, options: Object) => {
  const { reportType } = state;

  const { data, payrollAssessorialGuid } = options;

  return P.$set(`${reportType}.itemList.${payrollAssessorialGuid}.details`, data, state);
};

export default createReducer({
  // report
  [A.selectItem]: selectItem,
  [A.setReports]: setReports,
  [A.setReportType]: setReportType,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setFilterProps]: setFilterProps,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setGlobalFilterValue]: setGlobalFilterValue,
  [A.resetListAndPagination]: resetListAndPagination,
  // item
  [A.setItemDetails]: setItemDetails,
  [A.removeItemSuccess]: removeItemSuccess,
  [A.toggleItemDetails]: toggleItemDetails,
  [A.updateFleetItemSuccess]: updateFleetItemSuccess,
  [A.createFleetItemSuccess]: createFleetItemSuccess,
  // fleet profile
  [A.setFleetProfileSettings]: setFleetProfileSettings,
  // driver onboarding
  [A.getDriverListByDriverOnboardingGuidSuccess]: getDriverListByDriverOnboardingGuidSuccess,
  // configs
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [A.getAccessorialConfigListSuccess]: getAccessorialConfigListSuccess,
  // payroll accessorial
  [A.getPayrollAccessorialChargesByPayrollAccessorialGuidSuccess]:
    getPayrollAccessorialChargesByPayrollAccessorialGuidSuccess,
}, initialState);
