import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import {
  sendInvoiceToBCByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import ServiceVendorInvoiceForm from '../../../invoice/components/service-vendor-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncEditServiceVendorInvoice } from '../../../../hocs/with-async-edit-service-vendor-invoice';
// feature dispatch details new
import * as H from '../../helpers';
import { PrintTelDocumentsForm } from '../../forms/print-form';
import {
  printServiceVendorInvoiceRequest,
  updateServiceVendorInvoiceRequest,
  removeServiceVendorInvoiceRequest,
  getServiceVendorInvoiceListRequest,
  sendServiceVendorInvoiceToFinancialRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddServiceVendorInvoice = compose(
  withHandlers({
    handleAddServiceVendorInvoice: (props: Object) => () => {
      const {
        load,
        openModal,
        loadTotalWeight,
        loadTotalDistance,
        createServiceVendorInvoiceRequest,
      } = props;

      const branchGuid = G.getBranchGuidFromObject(load);
      const telGuid = G.getGuidFromObject(load);
      const totalCustomersRate = H.getTotalCustomerRatesFromClos(R.prop(GC.SYSTEM_LIST_CLOS, load));

      const handleSendServiceVendorInvoice = (values: Object) => {
        const data = R.assoc(GC.FIELD_TEL_GUID, telGuid, values);

        createServiceVendorInvoiceRequest(data);
      };

      const component = (
        <ServiceVendorInvoiceForm
          tel={load}
          telGuid={telGuid}
          branchGuid={branchGuid}
          totalWeight={loadTotalWeight}
          totalDistance={loadTotalDistance}
          totalCustomersRate={totalCustomersRate}
          handleSendFleetInvoice={handleSendServiceVendorInvoice}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withServiceVendorInvoiceTableGroupRowActions = compose(
  connect(
    null,
    {
      printServiceVendorInvoiceRequest,
      updateServiceVendorInvoiceRequest,
      removeServiceVendorInvoiceRequest,
      getServiceVendorInvoiceListRequest,
      sendInvoiceToBCByInvoiceTypeRequest,
      sendInvoiceToSageByInvoiceTypeRequest,
      sendServiceVendorInvoiceToFinancialRequest,
    },
  ),
  withAsyncEditServiceVendorInvoice({ updateFrom: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD }),
  withHandlers({
    handleSendServiceVendorInvoiceToFinancial: ({ entity, sendServiceVendorInvoiceToFinancialRequest }: Object) => () =>
      sendServiceVendorInvoiceToFinancialRequest(G.getGuidFromObject(entity)),
    handleCreateQBIIFImportServiceVendorInvoice: (props: Object) => () => {
      const { entity, closeFixedPopup, createQBIIFImportByInvoiceTypeRequest } = props;

      createQBIIFImportByInvoiceTypeRequest({
        type: 'serviceVendor',
        guids: R.of(Array, G.getGuidFromObject(entity)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });

      G.callFunction(closeFixedPopup);
    },
    handleSendServiceVendorInvoiceToBC: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getServiceVendorInvoiceListRequest,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const loadGuid = G.getGuidFromObject(load);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getServiceVendorInvoiceListRequest({ loadGuid });

      sendInvoiceToBCByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'serviceVendor',
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleSendServiceVendorInvoiceToSage: (props: Object) => () => {
      const { entity, closeFixedPopup, sendInvoiceToSageByInvoiceTypeRequest } = props;

      G.callFunction(closeFixedPopup);

      sendInvoiceToSageByInvoiceTypeRequest({
        invoiceType: 'serviceVendor',
        guids: R.of(Array, G.getGuidFromObject(entity)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleRemoveServiceVendorInvoice: (props: Object) => () => {
      const { openModal, entity: { guid, type }, removeServiceVendorInvoiceRequest } = props;

      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-invoice',
        'Do you want to delete this Invoice?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeServiceVendorInvoiceRequest({ guid, type }),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePrintServiceVendorInvoice: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        printServiceVendorInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);

      const component = (
        <PrintTelDocumentsForm
          selectedEntity={guid}
          branchGuid={branchGuid}
          closeModal={closeModal}
          telGuid={G.getGuidFromObject(load)}
          submitAction={printServiceVendorInvoiceRequest}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
    handlePrintServiceVendorInvoiceWithImages: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        loadDocuments,
        printServiceVendorInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);
      const documentsOptions = G.getPrintDocumentOptions(loadDocuments);

      const printInvoiceProps = {
        closeModal,
        branchGuid,
        documentsOptions,
        withDocuments: true,
        selectedEntity: guid,
        submitAction: printServiceVendorInvoiceRequest,
        [GC.FIELD_TEL_GUID]: G.getGuidFromObject(load),
        printableSection: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE.anchor,
      };

      const component = <PrintTelDocumentsForm {...printInvoiceProps} />;

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
  }),
);
