import * as R from 'ramda';
import React from 'react';
// components
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// dispatch-board-new
import { withAsyncDataOnDidMount } from '../hocs/with-async-data-on-did-mount';
//////////////////////////////////////////////////

const AsyncReferences = withAsyncDataOnDidMount(({ asyncData, asyncLoading }: Object) => (
  <LocalLoader width={350} height='auto' localLoaderOpen={asyncLoading}>
    <Box width='100%' fontSize='13px'>
      <Box p='4px 10px' bg={G.getTheme('colors.light.lightGrey')}>
        {G.getWindowLocale('titles:references', 'References')}
      </Box>
      {
        G.isNotNilAndNotEmpty(asyncData) &&
        asyncData.map((ref: Object, i: number) => (
          <Flex p='4px 10px' key={i}>
            <Box mr='5px' fontWeight='bold'>
              {R.prop(GC.FIELD_NAME, ref)}:
            </Box>
            {R.prop(GC.FIELD_VALUE, ref)}
          </Flex>
        ))
      }
    </Box>
  </LocalLoader>
));

export default AsyncReferences;
