import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { ENUMS } from '../../../../constants/enums';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
//////////////////////////////////////////////////

const calendarInputWrapperStyles = {
  width: '100%',
};

const settings = {
  uscisNumber: {
    max: 40,
    width: 150,
    name: 'titles:uscis-number',
  },
  citizen: {
    width: 150,
    type: 'select',
    name: 'titles:citizen',
    options: 'citizenOptions',
    customComponent: R.path(['data', 'citizen', GC.FIELD_DISPLAYED_VALUE]),
  },
  passportNumber: {
    max: 40,
    width: 150,
    name: 'titles:passport-number',
  },
  passportCountry: {
    type: 'countrySelect',
    name: 'titles:passport-country',
  },
  passportExpiration: {
    width: 150,
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    name: 'titles:passport-expiration',
  },
  visaNumber: {
    max: 40,
    width: 150,
    name: 'titles:visa-number',
  },
  visaCountry: {
    width: 150,
    type: 'countrySelect',
    name: 'titles:visa-country',
  },
  visaExpiration: {
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    name: 'titles:visa-expiration',
  },
  greenCardNumber: {
    max: 40,
    width: 150,
    name: 'titles:green-card-number',
  },
  greenCardExpiration: {
    width: 150,
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    name: 'titles:green-card-expiration',
  },
  workAuthorizationNumber: {
    max: 40,
    name: 'titles:work-authorization-number',
  },
  wanExpirationDate: {
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    name: 'titles:work-auth-expiration-date',
  },
  residenceStatus: {
    width: 150,
    type: 'select',
    name: 'titles:residence-status',
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: ENUMS.ENUM_TEMPORARY,
        label: G.getWindowLocale('titles:temporary', 'Temporary'),
      },
      {
        value: ENUMS.ENUM_PERMANENT,
        label: G.getWindowLocale('titles:permanent', 'Permanent'),
      },
      {
        value: ENUMS.ENUM_CITIZENSHIP,
        label: G.getWindowLocale('titles:citizenship', 'Citizenship'),
      },
    ],
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    type: 'file',
    name: 'titles:document-name',
    additionalInputWrapperStyles: {
      width: 600,
    },
  },
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    type: 'select',
    isRequired: true,
    name: 'titles:document-type',
    options: 'documentTypeOptions',
    additionalInputWrapperStyles: {
      display: (props: Object) => G.ifElse(
        G.isNotNilAndNotEmpty(R.path(['values', GC.FIELD_DOCUMENT_UPLOAD], props)),
        'block',
        'none',
      ),
    },
  },
};

const validationSchema = Yup.lazy(({ file }: Object) => Yup.object().shape({
  ...R.map(({ max }: Object) => {
    if (R.isNil(max)) return G.yupStringNotRequired;

    return Yup.string().nullable(true).max(40, G.getShouldBeFromToLocaleTxt(0, max));
  }, settings),
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.ifElse(
    G.isNotNilAndNotEmpty(file),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

const defaultInputWrapperStyles = {
  mb: 25,
  width: 280,
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    isRequired,
    isClearable,
    type = 'text',
    calendarInputWrapperStyles,
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    type,
    options,
    fieldName,
    isRequired,
    isClearable,
    label: R.of(Array, name),
    calendarInputWrapperStyles,
    inputWrapperStyles: R.mergeRight(defaultInputWrapperStyles, additionalInputWrapperStyles),
  })),
  R.omit([GC.FIELD_CREATED_BY, GC.FIELD_CREATED_DATE, GC.FIELD_LAST_MODIFIED_BY, GC.FIELD_LAST_MODIFIED_DATE]),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

export const citizenshipSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  singleItem: true,
  actionsPicker: ['edit'],
  useGetListRequest: true,
  groupName: 'citizenship',
  itemTitleArr: ['titles:citizenship-details', 'Citizenship Details'],
  formGroupTitleArr: ['titles:citizenship-details', 'Citizenship Details'],
  additionalTableSettings: {
    checkBoxCellJustifyContent: 'center',
  },
  endpoints: {
    list: 'driverCitizen',
  },
  fieldsWrapperStyles: {
    width: 600,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    citizenOptions: R.path(['dropdownOptions', GC.DRIVER_CITIZEN], configGroup),
    documentTypeOptions: R.path(['dropdownOptions', GC.DRIVER_DOCUMENT_TYPE], configGroup),
  }),
  makeInitialValues: (initialValues: Object) => {
    const { citizen } = initialValues;

    return R.mergeRight(
      initialValues,
      {
        [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: null,
        citizen: G.getDropdownOptionGuidFromObject(citizen),
      },
    );
  },
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, getDocumentListRequest, createOrUpdateEntityRequest } = props;

    const { failCallback, successCallback: setSubmitting } = additionalOptions;

    const hasFile = G.isNotNil(R.prop(GC.FIELD_DOCUMENT_UPLOAD, values));

    const successCallback = () => {
      setSubmitting();

      if (hasFile) getDocumentListRequest();
    };

    createOrUpdateEntityRequest({
      method: 'post',
      groupName: 'citizenship',
      endpoint: endpointsMap.updateDriverCitizen,
      additionalOptions: { failCallback, successCallback },
      values: G.makeDataForDocument(G.ifElse(hasFile, values, R.dissoc(GC.FIELD_DOCUMENT_DOCUMENT_TYPE, values))),
    });
  },
};
