import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  pure,
  branch,
  compose,
  withState,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import Resizable from '../../components/resizable';
import { Tabs, withTabs } from '../../components/tabs';
import { HoveringTitle } from '../../components/hovering-title';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// forms
import { SelectDropdownForm } from '../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withWindowSize, withIsOpenedModalStatus } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import {
  Box,
  Text,
  Flex,
  PageWrapper,
  RelativeBox,
  SimpleWrapper,
  PageTitleWrapper,
  PageHeaderWrapper,
} from '../../ui';
// feature dispatch-planner-events
import * as C from './constants';
import TelList from './components/loads';
import Planner from './components/planner';
import CloEventList from './components/clo-events';
import CloEventsMap from './components/clo-events-map';
import InboundTelList from './components/inbound-loads';
import OutboundTelList from './components/outbound-loads';
import RelatedCloTels from './components/related-clo-tels';
import CloEventsMapTitle from './components/clo-events-map-title';
import { pageSizes, mainSettings, PAGE_HEADER_WRAPPER_HEIGHT } from './settings/page-settings';
import { setPlannerMode, getCloEventsListForMapRequest, getCloTelsAndSetToStoreRequest } from './actions';
import {
  makeSelectTelTotal,
  makeSelectTelReport,
  makeSelectPlannerMode,
  makeSelectCloEventTotal,
  makeSelectCloEventReport,
  makeSelectInboundTelReport,
  makeSelectTelsByCloGuidList,
  makeSelectOutboundTelReport,
  makeSelectCloEventListForMap,
  makeSelectAvailableTelReports,
  makeSelectCurrentBranchConfigs,
  makeSelectCurrentRouteCloOptions,
  makeSelectAvailableCloEventReports,
} from './selectors';
//////////////////////////////////////////////////

const getTabs = (hideInboundOutboundTabs: boolean = false) => {
  const tabs = [
    { text: G.getWindowLocale('titles:clo-events', 'CLO Events') },
    { text: G.getWindowLocale('titles:existing-tels', 'Existing TELs') },
  ];

  if (hideInboundOutboundTabs) return tabs;

  return [
    ...tabs,
    { text: G.getWindowLocale('titles:inbound-tels', 'Inbound TELs') },
    { text: G.getWindowLocale('titles:outbound-tels', 'Outbound TELs') },
  ];
};

const topValuesMap = {
  [C.HALF_SCREEN]: '30vh',
  [C.ONE_THIRD_SCREEN]: '60vh',
  [C.COLLAPSED]: 'calc(100% - 25px)',
};

const getListMaxHeightMap = (hasPinned: boolean, hasSearchCriteria: boolean) => {
  let heightSubtract = 0;

  if (hasPinned) heightSubtract = R.add(heightSubtract, 20);
  if (hasSearchCriteria) heightSubtract = R.add(heightSubtract, 34);

  return {
    [C.STICKED_RIGHT]: 'calc(100vh - 145px)',
    [C.HALF_SCREEN]: `calc(30vh - ${R.add(heightSubtract, 52)}px)`,
    [C.COLLAPSED]: `calc(100vh - ${R.add(heightSubtract, 141)}px)`,
    [C.ONE_THIRD_SCREEN]: `calc(60vh - ${R.add(heightSubtract, 52)}px)`,
  };
};

const enhance = compose(
  withTabs,
  withWindowSize,
  withProps((props: Object) => ({ settings: R.mergeRight(mainSettings, props.settings) })),
  withState(
    'plannerView',
    'setPlannerView',
    R.or(G.getItemFromLocalStorage('plannerView'), C.HALF_SCREEN),
  ),
  withState('itemsExpanded', 'toggleItemsExpanded', false),
  withState('relatedTelsData', 'setRelatedTelsData', null),
  withIsOpenedModalStatus,
  withHandlers({
    handleShowRelatedCloTels: (props: Object) => (cloGuid: string, options: Array) => {
      const {
        telsByCloGuidList,
        setRelatedTelsData,
        getCloTelsAndSetToStoreRequest,
      } = props;

      if (G.isNilOrEmpty(G.getPropFromObject(cloGuid, telsByCloGuidList))) {
        getCloTelsAndSetToStoreRequest(cloGuid);
      }

      const cloName = R.compose(
        R.prop('label'),
        R.find(R.propEq(cloGuid, 'value')),
      )(options);

      setRelatedTelsData({ cloGuid, cloName, shown: true });
    },
  }),
  withHandlers({
    handleSetActiveTabAndPlannerMode: (props: Object) => (index: number) => (
      props.handleSetActiveTab(index)
    ),
    handleToggleTels: ({ mode, setPlannerMode }: Object) => () => {
      const newMode = G.ifElse(
        G.notEquals(mode, C.PLANNER_TEL_MODES.ALL_EXPANDED),
        C.PLANNER_TEL_MODES.ALL_EXPANDED,
        C.PLANNER_TEL_MODES.ALL_COLLAPSED,
      );

      setPlannerMode(newMode);
    },
    handleSelectCloToShowRelatedTels: (props: Object) => (options: Array) => {
      const {
        openModal,
        closeModal,
        handleShowRelatedCloTels,
      } = props;

      if (R.equals(R.length(options), 1)) {
        return handleShowRelatedCloTels(R.path([0, 'value'], options), options);
      }

      const component = (
        <SelectDropdownForm
          options={options}
          cancelAction={props.closeModal}
          fieldLabel={G.getWindowLocale('titles:select-clo', 'Select CLO')}
          submitAction={(value: string) => {
            closeModal();
            handleShowRelatedCloTels(value, options);
          }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
        },
      };
      openModal(modal);
    },
    handleShowDrawingMap: (props: Object) => () => {
      const { eventsForMap, setPlannerView, getCloEventsListForMapRequest } = props;

      setPlannerView(C.MAP_MODE);
      G.setItemToLocalStorage('plannerView', C.MAP_MODE);

      if (R.isEmpty(eventsForMap)) getCloEventsListForMapRequest();
    },
  }),
  branch(
    ({ initialDataLoaded, currentBranchConfigs }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(currentBranchConfigs),
    ),
    renderNothing,
  ),
  pure,
);

const PlannerViewButton = (props: Object) => {
  const {
    title,
    styles,
    height,
    plannerView,
    setPlannerView,
  } = props;

  const handleSetPlannerView = () => {
    setPlannerView(plannerView);
    G.setItemToLocalStorage('plannerView', plannerView);
  };

  return (
    <HoveringTitle
      title={title}
      handleClick={handleSetPlannerView}
      positionConfig={{
        zIndex: 14,
        right: '70%',
        bottom: '-37%',
        width: 'max-content',
      }}
    >
      <Box
        {...styles}
        height={height}
        onClick={handleSetPlannerView}
      />
    </HoveringTitle>
  );
};

const DataComponent = (props: Object) => {
  const {
    settings,
    activeTab,
    maxHeight,
    plannerView,
    contentHeight,
  } = props;

  if (R.equals(plannerView, C.MAP_MODE)) {
    return <CloEventsMap height={contentHeight} />;
  } else if (R.equals(activeTab, 0)) {
    return (
      <CloEventList
        maxHeight={maxHeight}
        shouldNotUnselectLastClo={R.path(['shouldNotUnselectLastClo'], settings)}
      />
    );
  } else if (R.equals(activeTab, 1)) {
    return <TelList maxHeight={maxHeight} />;
  } else if (R.equals(activeTab, 2)) {
    return <InboundTelList maxHeight={maxHeight} />;
  } else if (R.equals(activeTab, 3)) {
    return <OutboundTelList maxHeight={maxHeight} />;
  }

  return null;
};

const Content = (props: Object) => {
  const {
    mode,
    settings,
    activeTab,
    telReport,
    cloOptions,
    telReports,
    windowWidth,
    plannerView,
    itemsExpanded,
    setPlannerView,
    cloEventReport,
    cloEventReports,
    handleToggleTels,
    inboundTelReport,
    currentBranchGuid,
    telsByCloGuidList,
    outboundTelReport,
    toggleItemsExpanded,
    currentBranchConfigs,
    handleShowDrawingMap,
    handleRecalculateTelDistances,
    handleSelectCloToShowRelatedTels,
  } = props;

  const tabReportArray = [cloEventReport, telReport, inboundTelReport, outboundTelReport];
  const tabReports = G.ifElse(R.equals(activeTab, 0), cloEventReports, telReports);
  const hasSearchCriteria = G.isNotNilAndNotEmpty(R.path([activeTab, 'searchCriteria'], tabReportArray));
  const hasPinned = G.hasPinnedReports(R.or(tabReports, []));

  const sideBarWidth = 75;
  const { routeSummaryWidth } = pageSizes;
  const blueColor = G.getTheme('colors.light.blue');
  const colorDark = G.getTheme('colors.light.black');
  const isMapMode = R.equals(plannerView, C.MAP_MODE);
  const isFullScreen = R.equals(plannerView, C.FULL_SCREEN);
  const listMaxHeightMap = getListMaxHeightMap(hasPinned, hasSearchCriteria);
  const listMaxHeight = R.prop(plannerView, listMaxHeightMap);
  const isStickedRight = R.or(
    isMapMode,
    R.equals(plannerView, C.STICKED_RIGHT),
  );

  const loadsContentMaxWidth = R.subtract(
    windowWidth,
    R.sum([
      sideBarWidth,
      routeSummaryWidth,
    ]),
  );

  const plannerResizeBtnsStyles = {
    ml: 10,
    width: 16,
    cursor: 'pointer',
    borderColor: colorDark,
    borderTop: '3px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
  };

  const toggleItemsText = G.ifElse(
    itemsExpanded,
    G.getWindowLocaleArr('titles:collapse-items', 'Collapse Items'),
    G.getWindowLocaleArr('titles:expand-items', 'Expand Items'),
  );

  let wrapperStyles = {};
  let contentHeightSubtract = 60;

  if (hasPinned) contentHeightSubtract = R.add(contentHeightSubtract, 32);

  if (hasSearchCriteria) contentHeightSubtract = R.add(contentHeightSubtract, 34);

  if (isMapMode) {
    let top = 56;

    if (hasPinned) top = R.add(top, 32);

    if (hasSearchCriteria) top = R.add(top, 34);

    wrapperStyles = {
      top,
      height: `calc(100% - ${top}px)`,
    };
  }

  const contentHeight = `calc(100vh - ${contentHeightSubtract}px)`;

  return (
    <RelativeBox height={G.ifElse(isMapMode, contentHeight, 'calc(100vh - 60px)')}>
      <DataComponent
        activeTab={activeTab}
        maxHeight={listMaxHeight}
        plannerView={plannerView}
        contentHeight={contentHeight}
        telsByCloGuidList={telsByCloGuidList}
      />
      <Resizable isResizable={isStickedRight} wrapperStyles={wrapperStyles}>
        <Box
          right='0px'
          bottom='0px'
          bg={G.getTheme('colors.white')}
          zIndex={G.ifElse(isFullScreen, 2, '0')}
          left={G.ifElse(isFullScreen, 55, '0px')}
          height={G.ifElse(isStickedRight, '100%', null)}
          position={G.ifElse(isFullScreen, 'fixed', 'absolute')}
          top={G.ifElse(isFullScreen, '0px', topValuesMap[plannerView])}
        >
          <Flex height='100%' flexDirection='column'>
            <Flex
              ml='auto'
              height={25}
              p='3px 10px'
              width='100%'
              color={colorDark}
              alignItems='center'
              borderTop='1px solid'
              justifyContent='flex-end'
              bg={G.getTheme('colors.poloBlue')}
              boxShadow={`3px -1px 10px 0px ${colorDark}`}
              borderColor={G.getTheme('colors.lightGrey')}
            >
              {
                R.gt(R.length(cloOptions), 0) &&
                <Box
                  mr={20}
                  fontSize={12}
                  cursor='pointer'
                  color={blueColor}
                  onClick={() => handleSelectCloToShowRelatedTels(cloOptions)}
                >
                  {G.getWindowLocale('titles:show-related-trips', 'Show Related Trips')}
                </Box>
              }
              <Box
                mr={16}
                fontSize={12}
                cursor='pointer'
                color={blueColor}
                onClick={() => toggleItemsExpanded(R.not(itemsExpanded))}
              >
                {toggleItemsText}
              </Box>
              <HoveringTitle
                handleClick={handleToggleTels}
                positionConfig={{
                  zIndex: 16,
                  right: '100%',
                  width: 'max-content',
                }}
                title={G.ifElse(
                  G.notEquals(mode, C.PLANNER_TEL_MODES.ALL_EXPANDED),
                  G.getWindowLocale('actions:collapse-all-tels', 'Collapse All TELs'),
                  G.getWindowLocale('actions:uncollapse-all-tels', 'Uncollapse All TELs'),
                )}
              >
                <Flex
                  mr={10}
                  cursor='pointer'
                  onClick={handleToggleTels}
                  flexDirection={G.ifElse(
                    G.notEquals(mode, C.PLANNER_TEL_MODES.ALL_EXPANDED),
                    'column-reverse',
                    'column',
                  )}
                >
                  {I.arrowDownSimple(colorDark, 16, 11)}
                  {I.arrowUpSimple(colorDark, 16, 11)}
                </Flex>
              </HoveringTitle>
              <Flex alignItems='flex-end'>
                {
                  G.notEquals(plannerView, C.MAP_MODE) &&
                  <Box
                    px={10}
                    cursor='pointer'
                    onClick={handleShowDrawingMap}
                    title={G.getWindowLocale('actions:show-on-a-map', 'Show on a Map')}
                  >
                    {I.onMap(colorDark)}
                  </Box>
                }
                {
                  G.notEquals(plannerView, C.COLLAPSED) &&
                  <PlannerViewButton
                    height={16}
                    borderBottom='4px solid'
                    plannerView={C.COLLAPSED}
                    setPlannerView={setPlannerView}
                    title={G.getWindowLocale('actions:expand-list', 'Expand List')}
                    styles={R.compose(
                      R.assoc('borderBottom', '4px solid'),
                      R.omit(['borderTop', 'borderLeft', 'borderRight']),
                    )(plannerResizeBtnsStyles)}
                  />
                }
                {
                  G.notEquals(plannerView, C.ONE_THIRD_SCREEN) &&
                  <PlannerViewButton
                    height={10}
                    setPlannerView={setPlannerView}
                    styles={plannerResizeBtnsStyles}
                    plannerView={C.ONE_THIRD_SCREEN}
                    title={G.ifElse(
                      R.equals(plannerView, C.COLLAPSED),
                      G.getWindowLocale('actions:collapse-list', 'Collapse List'),
                      G.getWindowLocale('actions:collapse-canvas', 'Collapse Canvas'))
                    }
                  />
                }
                {
                  G.notEquals(plannerView, C.HALF_SCREEN) &&
                  <PlannerViewButton
                    height={13}
                    plannerView={C.HALF_SCREEN}
                    setPlannerView={setPlannerView}
                    styles={plannerResizeBtnsStyles}
                    title={G.ifElse(
                      R.equals(plannerView, C.COLLAPSED),
                      G.getWindowLocale('actions:collapse-list', 'Collapse List'),
                      G.getWindowLocale('actions:collapse-canvas', 'Collapse Canvas'))
                    }
                  />
                }
                {
                  R.not(isFullScreen) &&
                  <PlannerViewButton
                    height={16}
                    plannerView={C.FULL_SCREEN}
                    setPlannerView={setPlannerView}
                    styles={plannerResizeBtnsStyles}
                    title={G.getWindowLocale('actions:expand-canvas', 'Expand Canvas')}
                  />
                }
                {
                  G.notEquals(plannerView, C.STICKED_RIGHT) &&
                  <PlannerViewButton
                    height={16}
                    plannerView={C.STICKED_RIGHT}
                    setPlannerView={setPlannerView}
                    title={G.getWindowLocale('actions:stick-to-right', 'Stick To Right')}
                    styles={R.compose(
                      R.assoc('borderTop', '1px solid'),
                      R.assoc('borderRight', '4px solid'),
                    )(plannerResizeBtnsStyles)}
                  />
                }
              </Flex>
            </Flex>
            <Planner
              settings={settings}
              itemsExpanded={itemsExpanded}
              currentBranchGuid={currentBranchGuid}
              loadsContentMaxWidth={loadsContentMaxWidth}
              currentBranchConfigs={currentBranchConfigs}
              collapsed={R.equals((plannerView, C.COLLAPSED))}
              handleRecalculateTelDistances={handleRecalculateTelDistances}
              maxHeight={R.or(settings.builderMaxHeight, 'calc(100% - 20px)')}
            />
          </Flex>
        </Box>
      </Resizable>
    </RelativeBox>
  );
};

const Content2 = (props: Object) => {
  const sideBarWidth = 75;
  const { routeSummaryWidth } = pageSizes;
  const loadsContentMaxWidth = R.subtract(
    props.windowWidth,
    R.sum([
      sideBarWidth,
      routeSummaryWidth,
    ]),
  );

  return (
    <RelativeBox height='calc(100vh - 60px)'>
      <Box
        top='0px'
        left={55}
        right='0px'
        zIndex={13}
        bottom='0px'
        position='fixed'
        bg={G.getTheme('colors.white')}
      >
        <Flex height='100%' flexDirection='column'>
          <Planner
            maxHeight='100%'
            collapsed={false}
            settings={props.settings}
            currentBranchGuid={props.currentBranchGuid}
            loadsContentMaxWidth={loadsContentMaxWidth}
            currentBranchConfigs={props.currentBranchConfigs}
            handleRecalculateTelDistances={props.handleRecalculateTelDistances}
          />
        </Flex>
      </Box>
    </RelativeBox>
  );
};

const DispatchPlanner = enhance((props: Object) => {
  const {
    settings,
    activeTab,
    plannerView,
    fullScreenView,
    relatedTelsData,
    handleSetActiveTab,
    setRelatedTelsData,
    currentBranchConfigs,
  } = props;

  const relatedTelsShown = R.pathOr(false, ['shown'], relatedTelsData);

  const hideInboundOutboundTabs = G.getConfigValueFromStore(
    GC.UI_LOAD_PLANNER_HIDE_INBOUND_OUTBOUND_TABS,
    currentBranchConfigs,
  );

  return (
    <Box
      maxWidth='100%'
      overflow='auto'
      maxHeight='calc(100% - 40px)'
    >
      <PageWrapper
        zi={1}
        pt={16}
        pb='0px'
        overflow='hidden'
        mt={settings.pageWrapperMt}
        height={settings.pageHeight}
        bgColor={G.getTheme('pages.layOutBgColor')}
        withoutPageMargin={settings.withoutPageMargin}
      >
        {
          G.isAllTrue(
            R.not(fullScreenView),
            R.not(settings.withoutPageHeader),
            G.notEquals(plannerView, C.MAP_MODE),
          ) &&
          <PageHeaderWrapper
            pt='0px'
            position='relative'
            height={PAGE_HEADER_WRAPPER_HEIGHT}
          >
            <SimpleWrapper>
              <PageTitleWrapper height={40}>
                <Flex justifyContent='center' flexDirection='column'>
                  <Text fontSize={20}>
                    {G.getWindowLocale('titles:load-planner', 'Load Planner')}
                  </Text>
                </Flex>
              </PageTitleWrapper>
            </SimpleWrapper>
            <Tabs tabs={getTabs(hideInboundOutboundTabs)} activeTab={activeTab} handleClickTab={handleSetActiveTab} />
          </PageHeaderWrapper>
        }
        {
          R.equals(plannerView, C.MAP_MODE) &&
          <CloEventsMapTitle />
        }
        <Content {...props} />
        {
          relatedTelsShown &&
          <Resizable wrapperStyles={{ zIndex: 21 }}>
            <RelatedCloTels cloGuid={relatedTelsData.cloGuid} handleClose={() => setRelatedTelsData(null)} />
          </Resizable>
        }
      </PageWrapper>
    </Box>
  );
});

const DispatchPlanner2 = enhance((props: Object) => (
  <Box
    maxWidth='100%'
    overflow='auto'
    maxHeight='100%'
  >
    <PageWrapper
      zi={1}
      pt={16}
      pb='0px'
      mt={props.settings.pageWrapperMt}
      height={props.settings.pageHeight}
      bgColor={G.getTheme('pages.layOutBgColor')}
      withoutPageMargin={props.settings.withoutPageMargin}
    >
      <Content2 {...props} />
    </PageWrapper>
  </Box>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  mode: makeSelectPlannerMode(state),
  telTotal: makeSelectTelTotal(state),
  telReport: makeSelectTelReport(state),
  cloEventTotal: makeSelectCloEventTotal(state),
  cloEventReport: makeSelectCloEventReport(state),
  telReports: makeSelectAvailableTelReports(state),
  eventsForMap: makeSelectCloEventListForMap(state),
  cloOptions: makeSelectCurrentRouteCloOptions(state),
  inboundTelReport: makeSelectInboundTelReport(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  telsByCloGuidList: makeSelectTelsByCloGuidList(state),
  outboundTelReport: makeSelectOutboundTelReport(state),
  cloEventReports: makeSelectAvailableCloEventReports(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  currentBranchConfigs: makeSelectCurrentBranchConfigs(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setPlannerMode,
  getCloEventsListForMapRequest,
  getCloTelsAndSetToStoreRequest,
})(DispatchPlanner);

export const DispatchPlannerEventsForTelDetails = connect(mapStateToProps, { setPlannerMode })(DispatchPlanner2);
