import * as R from 'ramda';
import React, { memo } from 'react';
import { Waypoint } from 'react-waypoint';
// components
import { LocalLoader } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedFlex, scrollableContainerCss6px } from '../../../ui';
// feature drivers-card
import {
  DriverTelsCards,
  DriverPendingActivities,
  DriverUnavailablePeriods,
} from './driver-items-lists';
import Driver from './driver';
import Filters from './filters';
import { FILTER_TYPE_NONE } from '../constants';
import CalendarLine, { CalendarLine2 } from './calendar-line';
import { filterDriverLoadsByTeam, mapDriverLoadsWithFullSecondaryDriverInfo } from '../helpers';
//////////////////////////////////////////////////

const getDriverLoads = (props: Object) => {
  const {
    searchDate,
    teamFilterValue,
    notGroupedAssignedLoads,
    searchPlaceAutocompleteValue,
    filteredByPlaceAutocompleteDriverLoads,
  } = props;

  const driverLoadsToUse = G.ifElse(
    R.and(
      G.isNotNil(filteredByPlaceAutocompleteDriverLoads),
      R.or(
        G.isNotNilAndNotEmpty(searchPlaceAutocompleteValue),
        R.and(G.isNotNilAndNotEmpty(searchDate), G.isValidMoment(searchDate)),
      ),
    ),
    filteredByPlaceAutocompleteDriverLoads,
    mapDriverLoadsWithFullSecondaryDriverInfo(notGroupedAssignedLoads),
  );

  return filterDriverLoadsByTeam(teamFilterValue, driverLoadsToUse);
};

export default memo((props: Object) => {
  const {
    cardHeight,
    whiteColor,
    searchDate,
    filtersStore,
    openDriverProfile,
    notGroupedAssignedLoads,
    searchPlaceAutocompleteValue,
    getMoreNotGroupedAssignedLoads,
    notGroupedAssignedLoadsPagination,
  } = props;

  const driverLoads = getDriverLoads(props);

  const withWaypoint = G.isAllTrue(
    G.isNilOrEmpty(searchPlaceAutocompleteValue),
    R.and(G.isNilOrEmpty(searchDate), R.not(G.isValidMoment(searchDate))),
    R.equals(filtersStore.filter, FILTER_TYPE_NONE),
    R.gt(
      R.path(['totalCount'], notGroupedAssignedLoadsPagination),
      R.path(['offset'], notGroupedAssignedLoadsPagination),
    ),
    R.gte(
      R.path(['offset'], notGroupedAssignedLoadsPagination),
      R.path(['limit'], notGroupedAssignedLoadsPagination),
    ),
  );

  return (
    <Flex
      height='100%'
      overflow='auto'
      bg={whiteColor}
      flexDirection='row'
      alignItems='flex-start'
      css={scrollableContainerCss6px}
    >
      {
        <StickedFlex
          pr={15}
          left='0'
          zIndex={2100}
          minHeight='100%'
          flexDirection='column'
          bg={G.getTheme('colors.bgGrey')}
        >
          <Filters {...props} />
          {
            driverLoads.map((driver: Object) => (
              <Box mt={10} key={R.prop(GC.FIELD_GUID, driver)}>
                <Driver
                  driver={driver}
                  cardHeight={cardHeight}
                  openDriverProfile={openDriverProfile}
                  notGroupedAssignedLoads={notGroupedAssignedLoads}
                />
              </Box>
            ))
          }
        </StickedFlex>
      }
      {
        <Flex
          bg={whiteColor}
          flexDirection='column'
          alignItems='flex-start'
          width='calc(100vw - 350px)'
        >
          <CalendarLine {...props} />
          <CalendarLine2 {...props} />
          {
            driverLoads.map((driver: Object) => (
              <Flex
                mt={10}
                ml={10}
                alignItems='start'
                position='relative'
                height={cardHeight}
                key={R.prop(GC.FIELD_GUID, driver)}
              >
                {
                  G.isNotNilAndNotEmpty(R.path(['tels'], driver)) &&
                  <DriverTelsCards
                    {...props}
                    tels={R.path(['tels'], driver)}
                    driverGuid={G.getGuidFromObject(driver)}
                  />
                }
                {
                  G.isNotNilAndNotEmpty(R.path(['pendingActivities'], driver)) &&
                  <DriverPendingActivities
                    {...props}
                    driver={driver}
                    pendingActivities={R.path(['pendingActivities'], driver)}
                  />
                }
                {
                  G.isNotNilAndNotEmpty(R.path(['unavailablePeriods'], driver)) &&
                  <DriverUnavailablePeriods
                    {...props}
                    unavailablePeriods={R.path(['unavailablePeriods'], driver)}
                  />
                }
              </Flex>
            ))
          }
          {
            withWaypoint &&
            <Box width='100%'>
              <Waypoint topOffset='100px' onEnter={getMoreNotGroupedAssignedLoads} />
              <Box>
                <LocalLoader minWidth={340} localLoaderOpen={true} />
              </Box>
            </Box>
          }
        </Flex>
      }
    </Flex>
  );
});
