import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectIconsStore = (state: Object) => state.icons;

const makeSelectIconsSearch = () => createSelector(
  selectIconsStore,
  ({ searchFilter }: Object) => searchFilter,
);

export {
  makeSelectIconsSearch,
};
