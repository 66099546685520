import * as R from 'ramda';
import React from 'react';
// components
import { DownloadWithLabel } from '../../../components/download-with-label';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature configs
import { indexConfigOptionsWithParentGuid } from '../helpers';
// communication
import CustomConfigTableComponent from './custom-config-table';
import { downloadOnboardingPackageDocumentRequest } from '../actions';
import { downloadDocumentTemplateRequest } from '../config-pages/communication/actions';
//////////////////////////////////////////////////

const mapStatusMsgReasonCodes = R.curry((indexedCodeReasons: Object, reasonCodes: Array) => {
  if (Array.isArray(reasonCodes)) {
    const mappedReasonCodes = R.map((item: string) => {
      const reason = R.prop(item, indexedCodeReasons);
      if (R.isNil(reason)) {
        return null;
      }
      const { storedValue, displayedValue } = reason;
      return `${storedValue} (${displayedValue})`;
    }, reasonCodes);
    return G.createStringFromArray(mappedReasonCodes, ', ');
  }
  return '';
});

const mapAccessorialService = R.curry((indexedFullServices: Object, itemService: Array) => {
  if (G.isNotNilAndNotEmpty(itemService)) {
    const option = R.prop(itemService, indexedFullServices);
    if (R.isNil(option)) {
      return null;
    }
    const { storedValue, displayedValue } = option;
    return `${storedValue} (${displayedValue})`;
  }
  return '';
});

const renderComponent = (props: Object) => {
  const { customConfigName } = props;

  if (R.equals(props.customConfigName, GC.CUSTOM_ACCESSORIALS_CONFIG)) {
    const indexedServicesOptions = indexConfigOptionsWithParentGuid(props.servicesOptions);
    const callbacksMap = {
      service: mapAccessorialService(indexedServicesOptions),
    };
    return (
      <CustomConfigTableComponent
        {...props}
        callbacksMap={callbacksMap}
        entities={props.accessorialsList}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_STATUS_MESSAGES_CONFIG)) {
    const indexedCodeReasons = indexConfigOptionsWithParentGuid(props.reasonCodesOptions);
    const callbacksMap = {
      reasonCodes: mapStatusMsgReasonCodes(indexedCodeReasons),
    };
    return (
      <CustomConfigTableComponent
        {...props}
        callbacksMap={callbacksMap}
        entities={props.statusMessages}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_DOCUMENT_CONFIG)) {
    const callbacksMap = {
      documentTypeGuid: (entity: Object) => {
        const docType = R.find(
          R.propEq(entity.documentTypeGuid, 'value'),
          props.documentTypes.documentTypeGuid,
        );
        return (G.getOrElse(docType, 'label', ''));
      },
      fileName: (entity: Object) => (
        <DownloadWithLabel
          icon='pdf'
          name='fileName'
          entity={entity}
          downloadAction={downloadDocumentTemplateRequest}
        />
      ),
    };
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        paramIsEntity={true}
        callbacksMap={callbacksMap}
        entities={props.filteredDocumentList}
        handleEditEntity={props.handleEditDocument}
        handleRemoveEntity={props.handleDeleteDocument}
      />
    );
  } else if (R.equals(props.customConfigName, GC.INVOICE_CI_STATUS)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={props.customerInvoiceStatuses}
      />
    );
  } else if (R.equals(props.customConfigName, GC.INVOICE_DP_STATUS)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={props.driverPayrollStatuses}
      />
    );
  } else if (R.equals(props.customConfigName, GC.INVOICE_CARRIER_STATUS)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={props.carrierInvoiceStatuses}
      />
    );
  } else if (R.propEq(GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={props.fleetServiceInvoiceStatuses}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_ADD_NOTIFICATION_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        paramIsEntity={true}
        entities={props.filteredNotificationList}
        handleEditEntity={props.handleEditTrigger}
        handleRemoveEntity={props.handleDeleteNotification}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CARRIER_DEACTIVATION_RULES)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.deactivationRuleList}
        insuranceTypes={R.pathOr([], ['dropdowns', GC.CARRIER_INSURANCE_TYPE, 'options'], props)}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_FUEL_CARD_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={R.values(props.fuelCardList)}
        handleEditEntity={props.handleEditFuelCard}
        handleRemoveEntity={props.handleRemoveFuelCard}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_EDI_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={R.values(props.ediConfigsList)}
        handleEditEntity={props.handleEditEdiConfigs}
        handleRemoveEntity={props.handleRemoveEdiConfigs}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_EDI_EXTRACTOR_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={R.values(props.ediExtractorConfigList)}
        handleEditEntity={props.handleEditEdiExtractorConfig}
        handleRemoveEntity={props.handleRemoveEdiExtractorConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_CARRIER_INTEGRATION_OVERRIDE_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={R.values(props.carrierIntegrationOverrideList)}
        handleEditEntity={props.handleEditCarrierIntegrationOverride}
        handleRemoveEntity={props.handleRemoveCarrierIntegrationOverride}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_EDI_EXPORTER_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={R.values(props.ediExporterConfigList)}
        handleEditEntity={props.handleEditEdiExporterConfig}
        handleRemoveEntity={props.handleRemoveEdiExporterConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_INTEGRATION_CONFIGS_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={R.values(props.integrationConfigList)}
        handleEditEntity={props.handleEditIntegrationConfig}
        handleRemoveEntity={props.handleRemoveIntegrationConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_DISTANCE_CALCULATOR_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={false}
        entities={props.distanceCalculatorConfigList}
        handleEditEntity={props.handleEditDistanceCalculatorConfig}
        handleRemoveEntity={props.handleRemoveDistanceCalculatorConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CARRIER_ONBOARDING_PACKAGE)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.onboardingPackageList}
        handleEditEntity={props.handleEditOnboardingPackage}
        handleRemoveEntity={props.handleRemoveOnboardingPackage}
      />
    );
  } else if (R.and(
    G.isNotNilAndNotEmpty(props.onboardingPackageList),
    R.equals(props.customConfigName, GC.CARRIER_ONBOARDING_PACKAGE_DOCUMENT),
  )) {
    const callbacksMap = {
      [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_FILE_NAME]: (entity: Object) => (
        <DownloadWithLabel
          icon='pdf'
          entity={entity}
          downloadAction={downloadOnboardingPackageDocumentRequest}
          name={GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_FILE_NAME}
        />
      ),
    };
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        paramIsEntity={true}
        callbacksMap={callbacksMap}
        entities={props.onboardingPackageDocumentList}
        handleEditEntity={props.handleEditOnboardingPackageDocument}
        handleRemoveEntity={props.handleRemoveOnboardingPackageDocument}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_CARRIER_RATE_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.carrierRateIntegrationList}
        handleEditEntity={props.handleEditCarrierRateIntegration}
        handleRemoveEntity={props.handleRemoveCarrierRateIntegration}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_USER_MONITORING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.userMonitoringIntegrationList}
        handleEditEntity={props.handleEditUserMonitoringIntegration}
        handleRemoveEntity={props.handleRemoveUserMonitoringIntegration}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_SERVICE_MAPPING_CONFIG_SECTION)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        handleEditEntity={props.handleEditServiceMappers}
        entities={props.filteredServiceCodeMappingConfigList}
        handleRemoveEntity={props.handleRemoveServiceMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_ADVANCE_PAYMENT_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.advancePaymentList}
        handleEditEntity={props.handleEditAdvancePayment}
        handleRemoveEntity={props.handleRemoveAdvancePayment}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_EXPENSE_TYPES_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.expenseTypeList}
        handleEditEntity={props.handleEditExpenseType}
        handleRemoveEntity={props.handleRemoveExpenseType}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_LOADBOARD_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.loadBoardConfigList}
        handleEditEntity={props.handleEditLoadBoardIntegrationConfig}
        handleRemoveEntity={props.handleRemoveLoadBoardIntegrationConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_TOLL_CHARGES_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.tollChargeList}
        handleEditEntity={props.handleEditTollChargeConfig}
        handleRemoveEntity={props.handleRemoveTollChargeConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_GPS_INTEGRATION_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.gpsIntegrationList}
        handleEditEntity={props.handleEditGpsIntegrationConfig}
        handleRemoveEntity={props.handleRemoveGpsIntegrationConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_DISPATCH_INTEGRATION_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.dispatchIntegrationList}
        handleEditEntity={props.handleEditDispatchIntegrationConfig}
        handleRemoveEntity={props.handleRemoveDispatchIntegrationConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_STATUS_CODE_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.statusCodeMappingList}
        handleEditEntity={props.handleEditStatusCodeMappingConfig}
        handleRemoveEntity={props.handleRemoveStatusCodeMappingConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.statusCodeOutboundMappingList}
        handleRemoveEntity={props.handleRemoveStatusCodeOutboundMappingConfig}
        handleEditEntity={(entity: Object) =>
          props.handleEditStatusCodeMappingConfig(entity, 'outboundMapping')
        }
      />
    );
  } else if (R.equals(customConfigName, GC.CUSTOM_STATUS_REASON_CODE_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.statusReasonCodeMappingList}
        handleEditEntity={props.handleEditStatusReasonCodeMappingConfig}
        handleRemoveEntity={props.handleRemoveStatusReasonCodeMappingConfig}
      />
    );
  } else if (R.equals(customConfigName, GC.CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.statusReasonCodeOutboundMappingList}
        handleRemoveEntity={props.handleRemoveStatusReasonCodeOutboundMappingConfig}
        handleEditEntity={(entity: Object) =>
          props.handleEditStatusReasonCodeMappingConfig(entity, 'outboundMapping')
        }
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_PUBLIC_CLO_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.publicCLOConfig}
        handleEditEntity={props.handleEditPublicCLOConfig}
        handleRemoveEntity={props.handleRemovePublicCLOConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_QUICK_BOOKS_ACCOUNT_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.qbAccountMappingList}
        handleEditEntity={props.handleEditQBAccountMapping}
        handleRemoveEntity={props.handleRemoveAccountMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_SAGE_ACCOUNT_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.sageAccountMappingList}
        handleEditEntity={props.handleEditSageAccountMapping}
        handleRemoveEntity={props.handleRemoveSageAccountMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_CUSTOMER_ID_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.customerIdMappingList}
        handleEditEntity={props.handleEditCustomerIdMapping}
        handleRemoveEntity={props.handleRemoveCustomerIdMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_DEPARTMENT_ID_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.departmentIdMappingList}
        handleEditEntity={props.handleEditDepartmentIdMapping}
        handleRemoveEntity={props.handleRemoveDepartmentIdMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CARRIER_TERMS_AND_CONDITIONS_DOCUMENT)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.termsAndConditionsDocument}
        handleEditEntity={props.handleEditTermsAndConditionsDocument}
        handleRemoveEntity={props.handleRemoveTermsAndConditionsDocument}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_CARRIER_SYNCHRONIZATION_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={props.carrierSynchronization}
        handleEditEntity={props.handleEditCarrierSynchronization}
        handleRemoveEntity={props.handleRemoveCarrierSynchronization}
        showRemove={R.pathEq(
          G.getAmousCurrentBranchGuidFromWindow(),
          ['carrierSynchronization', 0, GC.BRANCH_GUID],
          props,
        )}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_SERVICE_TYPE_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.serviceTypeMappingList}
        handleEditEntity={props.handleEditServiceTypeMapping}
        handleRemoveEntity={props.handleRemoveServiceTypeMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_DEFAULT_ORDER_ACCESSORIAL_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.defaultOrderAccessorialList}
        handleEditEntity={props.handleEditDefaultOrderAccessorial}
        handleRemoveEntity={props.handleRemoveDefaultOrderAccessorial}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_DEFAULT_CARRIER_ACCESSORIAL_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.defaultCarrierAccessorialList}
        handleEditEntity={props.handleEditDefaultCarrierAccessorial}
        handleRemoveEntity={props.handleRemoveDefaultCarrierAccessorial}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_GL_CODE_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.glCodeMappingList}
        handleEditEntity={props.handleEditGLCodeMapping}
        handleRemoveEntity={props.handleRemoveGLCodeMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_INTEGRATION_MAIL_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        entities={props.integrationMailConfig}
        handleEditEntity={props.handleEditIntegrationMailConfig}
        handleRemoveEntity={props.handleRemoveIntegrationMailConfig}
        showRemove={R.pathEq(
          G.getAmousCurrentBranchGuidFromWindow(),
          ['integrationMailConfig', 0, GC.BRANCH_GUID],
          props,
        )}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_INTEGRATION_DOCUMENT_TYPE_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.documentTypeMappingList}
        handleEditEntity={props.handleEditDocumentTypeMapping}
        handleRemoveEntity={props.handleRemoveDocumentTypeMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_INTEGRATION_DOCUMENT_HUB_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.documentHubConfigList}
        handleEditEntity={props.handleEditDocumentHubConfig}
        handleRemoveEntity={props.handleRemoveDocumentHubConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_INTEGRATION_ACCOUNTING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.accountingConfigList}
        handleEditEntity={props.handleEditAccountingConfig}
        handleRemoveEntity={props.handleRemoveAccountingConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_CARRIER_PICKUP_REQUESTER_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.carrierPickupRequesterList}
        handleEditEntity={props.handleEditCarrierPickupRequester}
        handleRemoveEntity={props.handleRemoveCarrierPickupRequester}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_INVOICE_STATUS_RULE_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.invoiceStatusRuleList}
        handleEditEntity={props.handleEditInvoiceStatusRule}
        handleRemoveEntity={props.handleRemoveInvoiceStatusRule}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_CARRIER_EDI_LOADER_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.carrierEdiLoaderConfigList}
        handleEditEntity={props.handleEditCarrierEdiLoaderConfig}
        handleRemoveEntity={props.handleRemoveCarrierEdiLoaderConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_CARRIER_EDI_EXPORTER_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.carrierEdiExporterConfigList}
        handleEditEntity={props.handleEditCarrierEdiExporterConfig}
        handleRemoveEntity={props.handleRemoveCarrierEdiExporterConfig}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.transportationModeConfigMappingList}
        handleEditEntity={props.handleEditTransportationModeConfigMapping}
        handleRemoveEntity={props.handleRemoveTransportationModeConfigMapping}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_ORDER_QUOTE_TERMS_AND_CONDITIONS_DOCUMENT)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.termsAndConditionsDocument}
        handleEditEntity={props.handleEditTermsAndConditionsDocument}
        handleRemoveEntity={props.handleRemoveTermsAndConditionsDocument}
      />
    );
  } else if (R.equals(props.customConfigName, GC.CUSTOM_VIN_LOOKUP_CONFIG)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.vinLookupConfigList}
        handleEditEntity={props.handleEditVinLookupConfig}
        handleRemoveEntity={props.handleRemoveVinLookupConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_LOAD_CUSTOM_STATUS_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.loadCustomStatusConfigList}
        handleEditEntity={props.handleEditLoadCustomStatusConfig}
        handleRemoveEntity={props.handleRemoveLoadCustomStatusConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_FACTORING_INTEGRATION_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.factoringIntegrationConfigList}
        handleEditEntity={props.handleEditFactoringIntegrationConfig}
        handleRemoveEntity={props.handleRemoveFactoringIntegrationConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_EQUIPMENT_MAPPING_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.equipmentMappingConfigList}
        handleEditEntity={props.handleEditEquipmentMappingConfig}
        handleRemoveEntity={props.handleRemoveEquipmentMappingConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_MARGIN_VIOLATION_RULE_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.marginViolationRuleList}
        handleEditEntity={props.handleEditMarginViolationRule}
        handleRemoveEntity={props.handleRemoveMarginViolationRule}
      />
    );
  } else if (R.propEq(GC.CUSTOM_INTEGRATION_TRAILER_TRACKING_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.trailerTrackingIntegrationList}
        handleEditEntity={props.handleEditTrailerTrackingIntegration}
        handleRemoveEntity={props.handleRemoveTrailerTrackingIntegrationConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_DRIVER_ONBOARDING_INTEGRATION_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.onboardingIntegrationList}
        handleEditEntity={props.handleEditOnboardingIntegrationConfig}
        handleRemoveEntity={props.handleRemoveOnboardingIntegrationConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_LOS_SUBSCRIPTION_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={false}
        handleEditEntity={props.handleEditLosSubscriptionConfig}
        entities={G.ifElse(G.isNotNilAndNotEmpty(props.losSubscriptionConfig), R.of(Array, props.losSubscriptionConfig), [])}
      />
    );
  } else if (R.propEq(GC.CUSTOM_LOS_PRICING_PLAN_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.losPricingPlanList}
        handleEditEntity={props.handleEditLosPricingPlanConfig}
        handleRemoveEntity={props.handleRemoveLosPricingPlanConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_CARRIER_SCORECARD_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.carrierScorecardConfigList}
        handleEditEntity={props.handleEditCarrierScorecardConfig}
        handleRemoveEntity={props.handleRemoveCarrierScorecardConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_TERMINAL_INTEGRATION_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.terminalIntegrationList}
        handleEditEntity={props.handleEditTerminalIntegrationConfig}
        handleRemoveEntity={props.handleRemoveTerminalIntegrationConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_MAIL_TEMPLATE_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.mailTemplateConfig}
        handleEditEntity={props.handleEditMailTemplateConfig}
        handleRemoveEntity={props.handleRemoveMailTemplateConfig}
      />
    );
  } else if (R.includes(customConfigName, [GC.CUSTOM_TASK_TYPE_CONFIG, GC.CUSTOM_TASK_STATUS_CONFIG])) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={R.propOr([], `${customConfigName}List`, props)}
        handleEditEntity={(initialValues: Object) =>
          props.handleEditTaskManagementConfig({ initialValues, configName: customConfigName })
        }
        handleRemoveEntity={(guid: string) =>
          props.handleRemoveTaskManagementConfig({ guid, configName: customConfigName })
        }
      />
    );
  } else if (R.propEq(GC.CUSTOM_DOCUMENT_SENDING_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.documentSendingList}
        handleEditEntity={props.handleEditDocumentSendingConfig}
        handleRemoveEntity={props.handleRemoveDocumentSendingConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_TRANSPORTATION_MODE_GROUPING_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.transportationModeGroupingList}
        handleEditEntity={props.handleEditTransportationModeGrouping}
        handleRemoveEntity={props.handleRemoveTransportationModeGrouping}
      />
    );
  } else if (R.propEq(GC.CUSTOM_CROSS_BORDER_INTEGRATION_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.crossBorderIntegrationConfigList}
        handleEditEntity={props.handleEditCrossBorderIntegrationConfig}
        handleRemoveEntity={props.handleRemoveCrossBorderIntegrationConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_TRUCK_TYPE_MAPPING_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.truckTypeMappingList}
        handleEditEntity={props.handleEditTruckTypeMappingConfig}
        handleRemoveEntity={props.handleRemoveTruckTypeMappingConfig}
      />
    );
  } else if (R.propEq(GC.CUSTOM_TRAILER_TYPE_MAPPING_CONFIG, 'customConfigName', props)) {
    return (
      <CustomConfigTableComponent
        {...props}
        showRemove={true}
        entities={props.trailerTypeMappingList}
        handleEditEntity={props.handleEditTrailerTypeMappingConfig}
        handleRemoveEntity={props.handleRemoveTrailerTypeMappingConfig}
      />
    );
  }

  return null;
};

const CustomConfigGroupComponent = (props: Object) => (
  <Box width='100%' height='auto' overflow='auto' bg={G.getTheme('colors.white')}>
    {renderComponent(props)}
  </Box>
);

export default CustomConfigGroupComponent;
