import { connect } from 'react-redux';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
import { setInitialRouteGuid } from '../../../dispatch-planner/actions';
import ChangeEntityBranch from '../../../branch/components/change-entity-branch';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// utilities
import routesMap from '../../../../utilities/routes';
//////////////////////////////////////////////////

const enhance = compose(
  connect(null, { setInitialRouteGuid }),
  withHandlers({
    handleGoToRouteBuilder: (props: Object) => () => {
      const { routeGuid, setInitialRouteGuid } = props;

      if (G.isNotNilAndNotEmpty(routeGuid)) {
        setInitialRouteGuid(routeGuid);
        setTimeout(() => G.goToRoute(routesMap.routeBuilder), 0);
      }
    },
    handleChangeBranch: (props: Object) => () => {
      const {
        routeGuid,
        openModal,
        closeFixedPopup,
        getItemListRequest,
        resetListAndPagination } = props;
      const callback = () => {
        resetListAndPagination();
        getItemListRequest(true);
      };
      const component = (
        <ChangeEntityBranch
          type={'route'}
          guid={routeGuid}
          callback={callback} />
      );
      const modal = {
        p: '0 8px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getWindowLocale('titles:change-branch', 'Change Branch'),
        },
      };
      closeFixedPopup();
      openModal(modal);
    },
  }),
  pure,
);

const getOptions = ({ handleChangeBranch, handleGoToRouteBuilder }: Object) => {
  const iconColor = G.getTheme('colors.dark.blue');

  return [
    {
      permissions: null,
      action: handleGoToRouteBuilder,
      frontIcon: I.routeBuilder(iconColor),
      text: G.getWindowLocale('actions:go-to-route-builder', 'Go To Route Builder'),
    },
    {
      action: handleChangeBranch,
      permissions: [PC.TEL_WRITE],
      frontIcon: I.company(iconColor),
      text: G.getWindowLocale('titles:change-branch', 'Change Branch'),
    },
  ];
};

export const RowActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(RowActions);
