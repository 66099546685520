import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner
import * as C from './constants';
//////////////////////////////////////////////////

// COMMON
export const getLoadName = (load: Object, newNameText: string = '') => R.pathOr(
  newNameText,
  ['primaryReference', 'value'],
  load,
);

export const mergeArrayItemsProp = (arr: Array = [], prop: string) => (
  R.reduce(
    R.mergeRight,
    {},
    R.map((entity: Object = {}) => entity[prop], arr),
  )
);

export const concatArrayItemsProp = (arr: Array = [], prop: string) => (
  R.reduce(
    R.concat,
    [],
    R.map((entity: Object = {}) => entity[prop], arr),
  )
);

export const indexObjectArrayPropByPropName = (
  propName: string,
  pathToProp: string,
  obj: Object,
) => R.assoc(
  pathToProp,
  R.indexBy(R.prop(propName), R.prop(pathToProp, obj)),
  obj,
);

export const getCloItemsInTelByEventType = (tel: Object, eventType: string) => R.compose(
  R.filter(G.isLoadTypeClo),
  (events: Array) => concatArrayItemsProp(events, 'items'),
  R.filter((event: Object) => R.propEq(eventType, 'eventType', event)),
  R.values,
)(tel[GC.FIELD_LOAD_STOPS]);

export const filterPickedDroppedItems = (arr1: Array=[], arr2: Array=[]) => R.filter(
  (item: Object) => G.isNilOrEmpty(R.find(
    (filterItem: Object) => R.and(
      R.eqProps(GC.FIELD_LOAD_GUID, item, filterItem),
      R.eqProps(GC.FIELD_ITEM_INTERNAL_ID, item, filterItem),
    ),
    arr2,
  )),
  arr1,
);

const getTelCloItemsByType = (tel: Object, type: string) => R.compose(
  R.filter(G.isLoadTypeClo),
  R.reduce((acc: Array, stop: Object) => R.concat(acc, stop.items), []),
  R.filter(R.propEq(type, GC.FIELD_EVENT_TYPE)),
  R.values,
  R.prop(GC.FIELD_LOAD_STOPS),
)(tel);

const getTerminalCloItemsByType = (tels: Array, telGuid: string, stopId: string, type: string) => {
  let telsToUse = tels;

  if (R.equals(type, GC.EVENT_TYPE_DROP)) {
    telsToUse = R.filter(({ guid }: Object) => G.notEquals(guid, telGuid), tels);
  }

  return R.compose(
    R.filter(G.isLoadTypeClo),
    R.reduce((acc: Array, { items }: Object) => R.concat(acc, items), []),
    R.filter((stop: Object) => R.and(
      R.propEq(stopId, GC.FIELD_STOP_ID, stop),
      R.propEq(type, GC.FIELD_EVENT_TYPE, stop),
    )),
    R.reduce((acc: Array, { events }: Object) => R.concat(acc, R.values(events)), []),
  )(telsToUse);
};

export const getAvailableTerminalPickupClos = (tel: Object, tels: Array, stopId: string) => {
  const telGuid = G.getGuidFromObject(tel);

  const terminalDropedItems = getTerminalCloItemsByType(tels, telGuid, stopId, GC.EVENT_TYPE_DROP);
  const terminalPickedItems = getTerminalCloItemsByType(tels, telGuid, stopId, GC.EVENT_TYPE_PICKUP);
  const availableTerminalItems = filterPickedDroppedItems(terminalDropedItems, terminalPickedItems);

  const pickedTelItems = getTelCloItemsByType(tel, GC.EVENT_TYPE_PICKUP);
  const droppedTelItems = getTelCloItemsByType(tel, GC.EVENT_TYPE_DROP);
  const availableTelItems = filterPickedDroppedItems(droppedTelItems, pickedTelItems);

  const availableItems = R.compose(
    R.values,
    R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
    R.concat(availableTelItems),
  )(availableTerminalItems);

  if (G.isNilOrEmpty(availableItems)) return null;

  const clos = {};

  availableItems.forEach((item: string) => {
    const cloGuid = R.path([GC.FIELD_LOAD_GUID], item);

    if (R.not(R.has(cloGuid, clos))) clos[cloGuid] = [];

    clos[cloGuid].push(item);
  });

  return clos;
};

export const getAvailableDropClosWithItems = (tel: Object) => {
  const droppedItems = getCloItemsInTelByEventType(tel, GC.EVENT_TYPE_DROP);
  const pickedItems = getCloItemsInTelByEventType(tel, GC.EVENT_TYPE_PICKUP);
  const availableItems = filterPickedDroppedItems(pickedItems, droppedItems);
  if (G.isNilOrEmpty(availableItems)) return null;
  const clos = {};
  availableItems.forEach((item: string) => {
    const cloGuid = R.path(['loadGuid'], item);
    if (R.not(R.has(cloGuid, clos))) clos[cloGuid] = [];
    clos[cloGuid].push(item);
  });
  return clos;
};

export const getAvailableItemsForPickup = (stop: Object, items: Array) => (
  R.filter(
    (item: Object) => G.isNilOrEmpty(
      R.find(
        (id: Object) => R.equals(R.prop('itemInternalId', item), id),
        stop.itemIds,
      ),
    ),
    items,
  )
);

export const getTelItemsForDrop = (props: Object) => {
  const { tel, items } = props;
  const pickups = R.filter(
    (event: Object) => R.and(
      R.not(R.path(['cloEvent'], event)),
      R.propEq(GC.EVENT_TYPE_PICKUP, 'eventType', event),
    ),
    R.values(tel[GC.FIELD_LOAD_STOPS]),
  );
  const drops = R.filter(
    (event: Object) => R.and(
      R.not(R.path(['cloEvent'], event)),
      R.propEq(GC.EVENT_TYPE_DROP, 'eventType', event),
    ),
    R.values(tel[GC.FIELD_LOAD_STOPS]),
  );
  const pickedItemIds = R.reduce((acc: Array, pickup: Array) => R.concat(acc, pickup.itemIds), [], pickups);
  const droppedItemIds = R.reduce((acc: Array, pickup: Array) => R.concat(acc, pickup.itemIds), [], drops);
  const itemIds = R.difference(pickedItemIds, droppedItemIds);
  return R.compose(
    R.values,
    R.pick(itemIds),
    R.filter(({ loadType }: Object) => R.not(G.isLoadTypeClo(loadType))),
  )(items);
};

const getItemsByEventTypeAndLoadGuid = (tels: Object, dropStop: Object, eventType: string) => R.compose(
  R.reduce((acc: Array, stop: Object) => R.concat(acc, stop.itemIds), []),
  R.filter((stop: Object) => R.and(
    R.eqProps(GC.FIELD_LOAD_GUID, stop, dropStop),
    R.equals(eventType, stop.eventType),
  )),
  R.values,
  R.reduce((acc: Object, { events }: Array) => R.mergeRight(acc, events), {}),
  R.values,
)(tels);

export const getCloItemsForDrop = (props: Object) => {
  const dropStop = props.stop;
  const tels = R.path(['currentRoute', GC.SYSTEM_LIST_TELS], props);
  const pickedItems = getItemsByEventTypeAndLoadGuid(tels, dropStop, GC.EVENT_TYPE_PICKUP);
  const droppedItems = getItemsByEventTypeAndLoadGuid(tels, dropStop, GC.EVENT_TYPE_DROP);
  const itemIds = R.difference(pickedItems, droppedItems);
  return R.map((id: string) => R.path(['items', id], props), itemIds);
};

export const getTerminalInitialDates = ({ dropTels, pickupTels }: Object = {}, tels: Object = {}) => {
  const currentDateTime = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_TIME_FORMAT);
  let dates = {
    eventEarlyDate: currentDateTime,
    eventLateDate: G.addMomentTimeWithFormat(currentDateTime, 8),
  };
  if (R.and(
    G.isNotNilAndNotEmpty(dropTels),
    G.isNotNilAndNotEmpty(tels[R.head(dropTels)]),
  )) {
    const lastPickup = R.compose(
      R.last,
      R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
      R.filter((event: Object) => R.and(
        R.or(event.cloEvent, R.equals(R.prop('stopType', event), GC.STOP_TYPE_TERMINAL)),
        R.equals(event.eventType, GC.EVENT_TYPE_PICKUP),
      )),
      R.values,
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tels[R.head(dropTels)]);
    if (G.isNotNilAndNotEmpty(lastPickup)) {
      const lastEventDate = R.or(G.getStopLateDate(lastPickup), G.getStopEarlyDate(lastPickup));
      dates = {
        eventEarlyDate: G.addMomentTimeWithFormat(lastEventDate, 8),
        eventLateDate: G.addMomentTimeWithFormat(lastEventDate, 16),
      };
    }
  }
  return dates;
};

export const sortTelEvents = (events: Object) => (
  R.sort(
    (prevStop: Object, stop: Object) => R.subtract(prevStop.telEventIndex, stop.telEventIndex),
    R.values(events),
  )
);

export const createContainerFromFormValuesAndStop = (data: Object, stop: Object) => R.mergeRight(
  G.mapObjectEmptyStringFieldsToNull(data),
  {
    isNew: true,
    [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
    [GC.FIELD_CONTAINER_INTERNAL_ID]: G.generateGuid(),
    [GC.FIELD_LOAD_GUID]: R.prop(GC.FIELD_CLO_GUID, stop),
  },
);
// COMMON

// SAGA
export const getInitialReportFromResponse = (reports: Array, guid: string, routeGuid: string) => {
  const defaultReport = R.or(R.find(R.pathEq(true, ['defaultReport']))(reports), reports[0]);
  return {
    withSearchCriteria: G.ifElse(
      G.isNilOrEmpty(routeGuid),
      false,
      R.assoc(
        'searchCriteria',
        R.of(Array, {
          dataType: 'string',
          operation: 'equal',
          stringValue: routeGuid,
          propertyName: 'route.guid',
        }),
        defaultReport,
      ),
    ),
    empty: defaultReport,
    report: defaultReport,
  };
};

export const getItemsByIds = (ids: Array, items: Object, isNewEvent: boolean, shouldOmitLoadGuid: boolean) => {
  let omitFields = [
    'isNew',
    'selected',
    GC.FIELD_TEL_GUID,
    GC.FIELD_CLO_GUID,
    GC.FIELD_BRANCH_GUID,
  ];
  if (isNewEvent) omitFields = R.append(GC.FIELD_EVENT_GUID, omitFields);
  if (shouldOmitLoadGuid) omitFields = R.append(GC.FIELD_LOAD_GUID, omitFields);

  return R.map((id: string) => {
    const item = items[id];
    if (G.isTrue(R.path(['isNew'], item))) {
      return R.omit([GC.FIELD_GUID, ...omitFields], item);
    }
    return R.omit(omitFields, item);
  }, ids);
};

export const getEventWithItems = (
  event: Object,
  currentRouteItems: Object,
  shouldOmitLoadGuid: boolean = false,
) => R.assoc(
  'items',
  getItemsByIds(event.itemIds, currentRouteItems, R.prop('isNew', event), shouldOmitLoadGuid),
  event,
);

const getContainers = (ids: Array, containers: Object) => R.map(
  (id: string) => R.prop(id, containers),
  ids,
);

const getEventWithContainers = (
  event: Object,
  containers: Object,
) => R.mergeRight(
  event,
  {
    droppedContainers: getContainers(R.prop(GC.FIELD_STOP_DROPPED_CONTAINER_IDS, event), containers),
    pickedUpContainers: getContainers(R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, event), containers),
  },
);

export const transformEventsForRequest = (
  tel: Object,
  currentRouteItems: Object,
  currentRouteContainers: Object,
) => R.assoc(
  GC.FIELD_LOAD_STOPS,
  R.map(
    (stop: Object) => {
      const event = G.formatStopDateTimeValues(G.mapObjectEmptyStringFieldsToNull(stop));
      const isNewTel = G.isTrue(R.prop('isNew', tel));
      const pathToTemplateId = [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_TEMPLATE_ID];
      const pathToOperationHour = [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_OPERATION_HOUR];
      const eventWithOperationHour = R.assocPath(
        pathToOperationHour,
        R.compose(
          G.convertOperationHoursToDefaultFormat,
          R.pathOr([], pathToOperationHour),
        )(event),
        G.setStringValueInsteadObjectValueByPath(event, pathToTemplateId, GC.FIELD_VALUE),
      );
      const shouldOmitLoadGuid = R.and(isNewTel, R.propEq(GC.STOP_TYPE_INDEPENDENT, GC.FIELD_STOP_TYPE, event));
      const eventWithContainers = getEventWithContainers(
        eventWithOperationHour,
        currentRouteContainers,
        shouldOmitLoadGuid,
      );
      let eventWithItems = getEventWithItems(eventWithContainers, currentRouteItems, shouldOmitLoadGuid);

      if (isNewTel) {
        eventWithItems = R.omit(['telGuid', C.ORIGINAL_ROUTE_GUID], eventWithItems);
      }

      if (G.isTrue(R.prop('isNew', eventWithItems))) {
        return R.omit(['isNew', GC.FIELD_GUID, C.ORIGINAL_ROUTE_GUID], eventWithItems);
      }

      return R.omit([C.ORIGINAL_ROUTE_GUID], eventWithItems);
    },
    R.values(tel[GC.FIELD_LOAD_STOPS]),
  ),
  tel,
);

export const transformTelRateForRequest = (tel: Object, initRate: any) => {
  const rate = R.path([GC.FIELD_RATE], tel);
  if (R.equals(rate, initRate)) return R.omit([GC.FIELD_RATE], tel);
  if (G.isNilOrEmpty(rate)) return tel;
  if (G.isNotNilAndNotEmpty(R.prop('carrierAssignment', rate))) {
    return G.renameKeys({ rate: 'carrierRate' }, tel);
  }
  if (G.isNilOrEmpty(R.prop('fleetAssignment', rate))) {
    return R.assoc(
      'fleetRate',
      R.assoc(
        'fleetAssignment',
        R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, rate),
        rate,
      ),
      R.omit('rate', tel),
    );
  }
  return G.renameKeys({ rate: 'fleetRate' }, tel);
};

const getPrimaryReferenceValue = (telWithRate: Object, telPrimaryRefAuto: any, primaryReferenceTypeGuid: any) => {
  if (R.and(G.isTrue(telPrimaryRefAuto), G.isNilOrEmpty(primaryReferenceTypeGuid))) return null;
  return R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], telWithRate);
};

export const transformTelsForRequest = (
  route: Object,
  currentRouteItems: Object,
  telPrimaryRefAuto: any,
  initialRoute: Object,
  currentRouteContainers: Object,
) => R.assoc(
  GC.SYSTEM_LIST_TELS,
  R.compose(
    R.map((tel: Object) => {
      const telWithEvents = transformEventsForRequest(tel, currentRouteItems, currentRouteContainers);
      const telWithRate = transformTelRateForRequest(
        telWithEvents,
        R.path([GC.SYSTEM_LIST_TELS, G.getGuidFromObject(tel), GC.FIELD_RATE], initialRoute),
      );
      const primaryReferenceTypeGuid = R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_REFERENCE_TYPE_GUID], telWithRate);
      const telWithPrimaryReference = R.mergeRight(
        telWithRate,
        {
          primaryReferenceTypeGuid,
          [C.TEL_INDEX_IN_ROUTE]: tel.order,
          primaryReferenceValue: getPrimaryReferenceValue(telWithRate, telPrimaryRefAuto, primaryReferenceTypeGuid),
        },
      );
      if (G.isTrue(R.prop('isNew', telWithPrimaryReference))) {
        return R.omit([GC.FIELD_GUID, C.ORIGINAL_ROUTE_GUID, C.ORIGINAL_ROUTE_NAME], telWithPrimaryReference);
      }
      return R.omit([C.ORIGINAL_ROUTE_GUID, C.ORIGINAL_ROUTE_NAME], telWithPrimaryReference);
    }),
    R.filter((tel: Object) => G.isNotNilAndNotEmpty(R.values(tel[GC.FIELD_LOAD_STOPS]))),
  )(R.values(route.tels)),
  route,
);

export const setTelEventItemsForRateAssignRequest = (telEvents: Array, currentRouteItems: Object) => R.map(
  (event: Object) => getEventWithItems(event, currentRouteItems),
  telEvents,
);

export const transformCurrentRouteForRequest = (
  route: Object,
  currentRouteItems: Object,
  telPrimaryRefAuto: any,
  initialRoute: Object,
  currentRouteContainers: Object,
) => (
  R.omit(
    ['isNew', GC.SYSTEM_LIST_CLOS],
    transformTelsForRequest(route, currentRouteItems, telPrimaryRefAuto, initialRoute, currentRouteContainers),
  )
);

export const mapDraftsForInternalUsage = (data: Object, startIndex: number) => (
  data.map((draft: Object, index: number) => R.mergeRight(
    draft,
    {
      internalId: `draft-${R.add(startIndex, index)}`,
      details: { tels: draft.tels },
    },
  ))
);

const keysMap = {
  distance: GC.FIELD_DISTANCE_SYSTEM,
  unit: GC.FIELD_DISTANCE_SYSTEM_UOM,
};

const emptyDistance = {
  [GC.FIELD_DISTANCE_SYSTEM]: null,
  [GC.FIELD_DISTANCE_SYSTEM_UOM]: null,
};

export const mapLoadEventsWithDistances = (
  load: Object,
  loadType: string,
  distanceRes: Object,
) => {
  const distancesArr = R.compose(
    R.append(emptyDistance),
    R.map((item: Object) => G.renameKeys(keysMap, item)),
    R.map((item: Object) => R.pick(['distance', 'unit'], item)),
    R.prop('stopResults'),
  )(distanceRes);
  const events = R.compose(
    R.sortBy(R.prop(G.getEventIndexPropByLoadType(loadType))),
    R.values(),
    R.prop(GC.FIELD_LOAD_STOPS),
  )(load);
  const zippedEvents = R.zipWith((event: Object, distance: Object) => {
    const distanceToNextStop = R.prop(G.getDistancePropByLoadType(loadType), event);
    const newDistanceToNextStop = R.mergeRight(distanceToNextStop, distance);
    return R.assoc(G.getDistancePropByLoadType(loadType), newDistanceToNextStop, event);
  }, events, distancesArr);
  return R.indexBy(R.prop(GC.FIELD_GUID), zippedEvents);
};

export const getLoadEditModeStopsGeodata = (load: Object) => {
  const { events, loadType } = load;
  return R.compose(
    R.reduce((acc: Array, event: Object) => {
      if (R.isNil(acc)) return null;
      return G.getStopPointsDataAccForDistanceCalculator(event, acc);
    }, []),
    R.sortBy(R.prop(G.getEventIndexPropByLoadType(loadType))),
    R.values(),
  )(events);
};

export const mapRouteItems = (tels: Array = []) => {
  const items = R.compose(
    R.map((item: Object) => R.mergeRight(item, {
      isNew: false,
      selected: true,
    })),
    R.uniqBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
  )(concatArrayItemsProp(concatArrayItemsProp(tels, GC.FIELD_LOAD_STOPS), GC.FIELD_LOAD_ITEMS));
  return R.indexBy(
    R.prop(GC.FIELD_ITEM_INTERNAL_ID),
    items,
  );
};

export const mapRouteContainers = (tels: Array = []) => R.compose(
  R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
  R.map((item: Object) => R.mergeRight(item, {
    isNew: false,
    selected: true,
  })),
  R.uniqBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
)(concatArrayItemsProp(concatArrayItemsProp(tels, GC.FIELD_LOAD_STOPS), GC.FIELD_STOP_PICKED_UP_CONTAINERS));

const extendDataWithConvertedTels = (data: Object, tels: Array) => (
  R.mergeRight(
    data,
    {
      clos: R.indexBy(
        R.prop(GC.FIELD_GUID),
        R.map(
          (clo: Object) => R.assoc('loadName', getLoadName(clo), clo),
          R.or(data.clos, []),
        ),
      ),
      tels: R.indexBy(
        R.prop(GC.FIELD_GUID),
        tels,
      ),
    },
  )
);

const extendRouteTel = (tel: Object, index: number) => R.mergeRight(
  tel,
  {
    loadName: G.ifElse(
      G.isNotNilAndNotEmpty(R.prop('primaryReferenceValue', tel)),
      tel.primaryReferenceValue,
      getLoadName(tel),
    ),
    order: R.or(tel.order, index),
  },
);

export const convertRouteResponse = (data: Object, indexAdditional: number) => {
  const mapIndexed = R.addIndex(R.map);
  const telsWithIndexedEvents = mapIndexed(
    (tel: Object, index: number) => indexObjectArrayPropByPropName(
      GC.FIELD_GUID,
      GC.FIELD_LOAD_STOPS,
      extendRouteTel(tel, R.add(index, indexAdditional)),
    ),
    data.tels,
  );
  return extendDataWithConvertedTels(data, telsWithIndexedEvents);
};

export const convertRouteResponseWithOrigRouteGuid = (data: Object, indexAdditional: number) => {
  const extendedData = R.mergeRight(data, {
    linkedClos: G.indexByGuid(data.linkedClos),
    [GC.SYSTEM_LIST_TELS]: R.map(
      (tel: Object) => R.mergeRight(
        tel,
        {
          [C.ORIGINAL_ROUTE_GUID]: data[GC.FIELD_GUID],
          [C.ORIGINAL_ROUTE_NAME]: data[GC.FIELD_NAME],
          [GC.FIELD_LOAD_STOPS]: R.map(
            R.assoc(C.ORIGINAL_ROUTE_GUID, data.guid),
            tel[GC.FIELD_LOAD_STOPS],
          ),
        },
      ),
      data[GC.SYSTEM_LIST_TELS],
    ),
  });

  return convertRouteResponse(extendedData, R.or(indexAdditional, 0));
};

const mapDraftTels = (tels: Array) => R.map(
  (tel: Object) => {
    const telWithSortedEvents = R.assoc(
      GC.FIELD_LOAD_STOPS,
      R.sortBy(
        R.prop(GC.FIELD_TEL_EVENT_INDEX),
        tel[GC.FIELD_LOAD_STOPS],
      ),
      tel,
    );
    if (G.isNotNilAndNotEmpty(R.prop('guid', telWithSortedEvents))) return telWithSortedEvents;
    return R.mergeRight(telWithSortedEvents, {
      isNew: true,
      guid: G.generateGuid(),
    });
  },
  tels,
);

const extendDraftTel = (tel: Object, index: number) => (
  R.mergeRight(
    tel,
    {
      loadType: 'tel',
      loadName: G.ifElse(
        G.isNotNilAndNotEmpty(R.prop('primaryReferenceValue', tel)),
        tel.primaryReferenceValue,
        getLoadName(tel),
      ),
      order: R.or(tel.order, index),
      events: tel[GC.FIELD_LOAD_STOPS].map((event: Object) => {
        if (G.isNotNilAndNotEmpty(R.prop('guid', event))) {
          return R.mergeRight(event, {
            telGuid: tel.guid,
          });
        }
        return R.mergeRight(event, {
          isNew: true,
          telGuid: tel.guid,
          guid: G.generateGuid(),
        });
      }),
    },
  )
);

export const convertDraftRouteResponse = (data: Object) => {
  const tels = mapDraftTels(data.tels);
  const telsWithIndexedEvents = tels.map((tel: Object, index: number) => indexObjectArrayPropByPropName(
      'guid',
      GC.FIELD_LOAD_STOPS,
      extendDraftTel(tel, index),
    ),
  );
  return extendDataWithConvertedTels(data, telsWithIndexedEvents);
};
// SAGA

// REDUCER
export const setSelectedPropIfExist = (tels: any, data: Array) => {
  if (G.isNilOrEmpty(tels)) return tels;
  return tels.map((tel: Object) => R.assoc(
    'selected',
    G.ifElse(R.includes(R.prop(GC.FIELD_GUID, tel), data), true, false),
    tel,
  ));
};

export const selectTelItems = (tels: Array, ids: Array) => (
  R.map((tel: Object) => (
    P.$set(
      'selected',
      G.isNotNilAndNotEmpty(R.find(
        (id: string) => R.equals(R.prop('guid', tel), id),
        ids,
      )),
      tel,
    )
  ), tels)
);

export const selectDraftItem = (list: Array, id: string) => (
  R.map((item: Object) => (
    P.$set(
      'selected',
      G.ifElse(
        R.equals(R.prop('internalId', item), id),
        R.not(R.prop('selected', item)),
        false,
      ),
      item,
    )
  ), list)
);

export const selectItem = (list: Array, id: string) => (
  R.map((item: Object) => (
    P.$set(
      'selected',
      G.ifElse(
        R.equals(R.prop('guid', item), id),
        R.not(R.prop('selected', item)),
        R.prop('selected', item),
      ),
      item,
    )
  ), list)
);

export const filterCurrentRouteItems = (items: Object, cloGuid: string) => R.compose(
  R.indexBy(R.prop('itemInternalId')),
  R.filter((item: Object) => G.notEquals(item.loadGuid, cloGuid)),
  R.values,
)(items);

export const resetCloListToInitialRoute = (currentRoute: Object, cloList: Array) => (
  R.map(
    (clo: Object) => R.assoc(
      'selected',
      G.isNotNilAndNotEmpty(R.path(['clos', R.prop('guid', clo)], currentRoute)),
      clo,
    ),
    cloList,
  )
);

export const createNewRouteTel = (options: Object) => (
  R.mergeRight(
    {
      rate: null,
      events: {},
      isNew: true,
      linkedCloGuids: [],
      guid: G.generateGuid(),
      loadType: GC.LOAD_TYPE_TEL,
    },
    options,
  )
);

export const createNewStopOnRouteTel = ({
  drop,
  pickup,
  telGuid,
  location,
  stopType,
  eventType,
  eventLateDate,
  telEventIndex,
  eventEarlyDate,
  appointmentDate,
  appointmentNumber,
  appointmentLateTime,
  appointmentEarlyTime,
}: Object) => ({
  drop,
  pickup,
  telGuid,
  location,
  items: [],
  eventType,
  itemIds: [],
  isNew: true, // TODO: check it
  cloGuid: null,
  eventLateDate,
  telEventIndex,
  eventEarlyDate,
  comments: null,
  cloEvent: false,
  appointmentDate,
  completed: false,
  trailerGuids: [],
  appointmentNumber,
  cloEventIndex: null,
  appointmentLateTime,
  appointmentEarlyTime,
  guid: G.generateGuid(),
  eventCompleteDate: null,
  cloDistanceToNextStop: null,
  telDistanceToNextStop: null, // TODO: with Object
  stopType: R.or(stopType, GC.STOP_TYPE_INDEPENDENT),
  // containers
  [GC.FIELD_STOP_DROPPED_CONTAINERS]: [],
  [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: [],
  [GC.FIELD_STOP_DROPPED_CONTAINER_IDS]: [],
  [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS]: [],
  // trailers
  [GC.FIELD_STOP_DROPPED_TRAILERS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILERS]: [],
  [GC.FIELD_STOP_DROPPED_TRAILER_GUIDS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS]: [],
});

export const createNewTerminalOnRouteTel = (options: Object) => {
  const newStop = createNewStopOnRouteTel(options);
  const guid = G.generateGuid();
  return {
    ...newStop,
    guid,
    stopId: options.stopId,
    stopType: R.or(options.stopType, GC.STOP_TYPE_TERMINAL),
  };
};

export const getNextTelEventIndex = (telGuid: string, state: Object) => {
  const length = R.length(
    R.values(R.pathOr({}, ['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS], state),
  ));
  return R.add(length, 1);
};

export const omitLocationFields = (location: Object) => R.omit(
  R.concat(GC.GROUPED_FIELDS.EVENT_DATES_ARR, GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
  R.mergeRight(location, {
    [GC.FIELD_CONTACTS]: R.map(
      R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
      R.pathOr([], [GC.FIELD_CONTACTS], location),
    ),
    [GC.FIELD_OPERATION_HOUR]: R.map(
      R.omit([GC.FIELD_GUID]),
      R.pathOr([], [GC.FIELD_OPERATION_HOUR], location),
    ),
  }),
);

export const updateEventByStopType = (event: Object) => {
  if (R.equals(event.stopType, GC.STOP_TYPE_TERMINAL)) {
    return R.assoc(
      'location',
      R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR, event.location),
      event,
    );
  }
  return event;
};

export const generateDataForNewTelStopFromStopForm = (data: Object, state: Object) => {
  const telGuid = data.loadGuid;
  const eventType = data.eventType;
  const location = omitLocationFields(data.location);
  const drop = R.equals(data.eventType, GC.EVENT_TYPE_DROP);
  const telEventIndex = getNextTelEventIndex(telGuid, state);
  const pickup = R.equals(data.eventType, GC.EVENT_TYPE_PICKUP);
  const defaultDate = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_TIME_FORMAT);
  const appointmentDate = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOAD_APPOINTMENT_DATE], data);
  const appointmentNumber = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOAD_APPOINTMENT_NUMBER], data);
  const eventLateDate = R.pathOr(defaultDate, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOAD_EVENT_LATE_DATE], data);
  const eventEarlyDate = R.pathOr(defaultDate, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOAD_EVENT_EARLY_DATE], data);
  const appointmentLateTime = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], data);
  const appointmentEarlyTime = R.pathOr(null, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], data);

  return {
    drop,
    pickup,
    telGuid,
    location,
    eventType,
    telEventIndex,
    eventLateDate,
    eventEarlyDate,
    appointmentDate,
    appointmentNumber,
    appointmentLateTime,
    appointmentEarlyTime,
  };
};

export const createNewCloStopOnRouteTel = (options: Object) => ({
  isNew: true,
  [GC.FIELD_STOP_ITEMS]: [],
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_CLO_EVENT]: true,
  [GC.FIELD_STOP_TYPE]: null,
  [GC.FIELD_COMPLETED]: false,
  [GC.FIELD_STOP_ITEM_IDS]: [],
  [GC.FIELD_TRAILER_GUIDS]: [],
  [GC.FIELD_DISTANCE_CLO]: null,
  [GC.FIELD_DISTANCE_TEL]: null,
  [GC.FIELD_CLO_EVENT_INDEX]: null,
  [GC.FIELD_GUID]: G.generateGuid(),
  [GC.FIELD_EVENT_COMPLETE_DATE]: null,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  [GC.FIELD_LOCATION]: R.prop(GC.FIELD_LOCATION, options),
  [GC.FIELD_CLO_GUID]: R.prop(GC.FIELD_CLO_GUID, options),
  [GC.FIELD_TEL_GUID]: R.prop(GC.FIELD_TEL_GUID, options),
  [GC.FIELD_LOAD_GUID]: R.prop(GC.FIELD_CLO_GUID, options),
  [GC.FIELD_STOP_DROP]: R.prop(GC.FIELD_STOP_DROP, options),
  [GC.FIELD_EVENT_TYPE]: R.prop(GC.FIELD_EVENT_TYPE, options),
  [GC.FIELD_STOP_PICKUP]: R.prop(GC.FIELD_STOP_PICKUP, options),
  [GC.FIELD_TEL_EVENT_INDEX]: R.prop(GC.FIELD_TEL_EVENT_INDEX, options),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE, options),
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE, options),
  [GC.FIELD_LOAD_APPOINTMENT_DATE]: R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, options),
  [GC.FIELD_LOAD_APPOINTMENT_NUMBER]: R.prop(GC.FIELD_LOAD_APPOINTMENT_NUMBER, options),
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: R.prop(GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, options),
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: R.prop(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, options),
  // containers
  [GC.FIELD_STOP_DROPPED_CONTAINERS]: [],
  [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: [],
  [GC.FIELD_STOP_DROPPED_CONTAINER_IDS]: [],
  [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS]: [],
  // trailers
  [GC.FIELD_STOP_DROPPED_TRAILERS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILERS]: [],
  [GC.FIELD_STOP_DROPPED_TRAILER_GUIDS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS]: [],
});

export const generateDataForNewCloStopFromStopForm = (data: Object, state: Object) => {
  const { telGuid, loadGuid, location, eventType } = data;

  const telEventIndex = getNextTelEventIndex(telGuid, state);
  const drop = G.isEventTypeDrop(eventType);
  const pickup = G.isEventTypePickup(eventType);
  const locationData = omitLocationFields(location);
  const eventEarlyDate = R.pathOr(null, [GC.FIELD_LOAD_EVENT_EARLY_DATE], location);
  const eventLateDate = R.pathOr(null, [GC.FIELD_LOAD_EVENT_LATE_DATE], location);
  const appointmentDate = R.pathOr(null, [GC.FIELD_LOAD_APPOINTMENT_DATE], location);
  const appointmentEarlyTime = R.pathOr(null, [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], location);
  const appointmentLateTime = R.pathOr(null, [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], location);
  const appointmentNumber = R.pathOr(null, [GC.FIELD_LOAD_APPOINTMENT_NUMBER], location);

  return {
    drop,
    pickup,
    telGuid,
    loadGuid,
    eventType,
    telEventIndex,
    eventLateDate,
    eventEarlyDate,
    appointmentDate,
    cloGuid: loadGuid,
    appointmentNumber,
    appointmentLateTime,
    appointmentEarlyTime,
    location: locationData,
  };
};

const getCloItemsFromStoreByEventTypeAndTelGuid = (eventType: string, telGuid: string, state: Object) => R.compose(
  R.filter(G.isLoadTypeClo),
  R.reduce((acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_ITEMS, event)), []),
  R.filter((event: Object) => R.propEq(eventType, GC.FIELD_EVENT_TYPE, event)),
  R.values,
  R.path(['currentRoute', 'tels', telGuid, 'events']),
)(state);

const getItemsFromStoreByEventTypeAndTelGuid = (eventType: string, telGuid: string, state: Object) => R.compose(
  R.reduce((acc: Array, event: Object) => R.concat(acc, event.items), []),
  R.filter(R.propEq(eventType, GC.FIELD_EVENT_TYPE)),
  R.values,
  R.path(['currentRoute', 'tels', telGuid, 'events']),
)(state);

const filterItems = (items1: Array=[], items2: Array=[]) => R.filter(
  (item: Object) => R.isNil(R.find(R.eqProps(GC.FIELD_GUID, item), items2)),
  items1,
);

export const getAvailableContainersForDrop = (tel: Object) => {
  const events = R.compose(
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const pickedUpContainers = R.reduce(
    (acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, event)),
    [],
    events,
  );
  const droppedContainerIds = R.reduce(
    (acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_DROPPED_CONTAINER_IDS, event)),
    [],
    events,
  );
  return R.compose(
    R.values,
    R.omit(droppedContainerIds),
    R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
  )(pickedUpContainers);
};

export const getAvailableContainersForCloDrop = (tels: Object, cloGuid: string) => {
  const events = R.compose(
    R.filter(R.propEq(cloGuid, GC.FIELD_CLO_GUID)),
    R.reduce((acc: Array, tel: Object) => R.concat(acc, R.values(tel.events)), []),
    R.values,
  )(tels);

  return getAvailableContainersForDrop({ events });
};

export const addTerminalDropsToCurrentRouteTels = (
  stopId: string,
  data: Object,
  state: Object,
) => {
  const dropTelsGuids = R.pathOr([], ['returnObject', 'dropTels'], data);
  const stopData = R.compose(
    R.assoc('eventType', GC.EVENT_TYPE_DROP),
    R.omit('returnObject'),
  )(data);
  const terminalDrops = R.compose(
    R.indexBy(R.prop(GC.FIELD_TEL_GUID)),
    R.map((telGuid: string) => {
      const stopDataWithTelGuid = R.assoc(GC.FIELD_LOAD_GUID, telGuid, stopData);
      const stopOptions = generateDataForNewTelStopFromStopForm(stopDataWithTelGuid, state);
      let newTerminal = createNewTerminalOnRouteTel(R.assoc('stopId', stopId, stopOptions));
      const cloPickupItems = getCloItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_PICKUP, telGuid, state);
      const cloDropItems = getItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_DROP, telGuid, state);
      const availableItems = filterItems(cloPickupItems, cloDropItems);
      if (G.isNotNilAndNotEmpty(availableItems)) {
        newTerminal = R.mergeRight(
          newTerminal,
          {
            items: availableItems,
            itemIds: R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID), availableItems),
          },
        );
      }
      const availableContainers = getAvailableContainersForDrop(R.path(['currentRoute', 'tels', telGuid], state));
      if (G.isNotEmpty(availableContainers)) {
        newTerminal = R.mergeRight(
          newTerminal,
          {
            [GC.FIELD_STOP_DROPPED_CONTAINERS]: availableContainers,
            [GC.FIELD_STOP_DROPPED_CONTAINER_IDS]: R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), availableContainers),
          },
        );
      }
      return newTerminal;
    }),
  )(dropTelsGuids);
  const newCurrentRouteTels = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.map((tel: Object) => {
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      if (R.includes(telGuid, dropTelsGuids)) {
        const terminalDrop = R.prop(telGuid, terminalDrops);
        return R.assocPath([GC.FIELD_LOAD_STOPS, R.prop(GC.FIELD_GUID, terminalDrop)], terminalDrop, tel);
      }
      return tel;
    }),
    R.values,
    R.path(['currentRoute', 'tels']),
  )(state);
  return P.$set('currentRoute.tels', newCurrentRouteTels, state);
};

export const getTelAvailableContainersForPickup = (tel: Object) => {
  const events = R.compose(
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const droppedContainers = R.reduce(
    (acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_DROPPED_CONTAINERS, event)),
    [],
    events,
  );
  const pickedUpContainerIds = R.reduce(
    (acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, event)),
    [],
    events,
  );
  return R.compose(
    R.values,
    R.omit(pickedUpContainerIds),
    R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
  )(droppedContainers);
};

export const getAvailableContainersForPickup = (stop: Object, currentRoute: Object) => {
  const terminalDropContainers = R.compose(
    R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
    R.reduce((acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_DROPPED_CONTAINERS, event)), []),
    R.filter((event: Object) => R.and(
      R.eqProps(GC.FIELD_STOP_ID, event, stop),
      G.isStopDrop(event),
    )),
    R.values,
    R.reduce((acc: Object, tel: Object) => R.mergeRight(acc, R.prop(GC.FIELD_LOAD_STOPS, tel)), {}),
    R.values,
    R.prop(GC.SYSTEM_LIST_TELS),
  )(currentRoute);
  const terminalPickedContainerIds = R.compose(
    R.uniq,
    R.reduce((acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, event))),
    R.filter((event: Object) => R.and(
      R.eqProps(GC.FIELD_STOP_ID, event, stop),
      G.isStopPickup(event),
    )),
    R.values,
    R.reduce((acc: Object, tel: Object) => R.mergeRight(acc, R.prop(GC.FIELD_LOAD_STOPS, tel))),
    R.values,
    R.prop(GC.SYSTEM_LIST_TELS),
  )(currentRoute);

  return R.compose(
    R.values,
    R.omit(R.concat(terminalPickedContainerIds, R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, stop))),
  )(terminalDropContainers);
};

const getAllStopsFromStateByStopId = (stopId: string, state: Object) => R.compose(
  R.filter(R.propEq(stopId, GC.FIELD_STOP_ID)),
  R.values,
  R.reduce((acc: Object, { events }: Object) => R.mergeRight(acc, events), {}),
  R.values,
  R.path(['currentRoute', GC.SYSTEM_LIST_TELS]),
)(state);

const getAvailableTerminalPickupItems = (stopId: string, events: Array) => {
  const droppedItems = R.compose(
    R.reduce((acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_ITEMS, event)), []),
    R.filter(R.propEq(GC.EVENT_TYPE_DROP, GC.FIELD_EVENT_TYPE)),
  )(events);
  const pickedUpItemIds = R.compose(
    R.reduce((acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_ITEM_IDS, event)), []),
    R.filter(R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE)),
  )(events);

  return R.compose(
    R.values,
    R.omit(pickedUpItemIds),
    R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
  )(droppedItems);
};

const getAvailableTerminalPickupContainers = (stopId: string, events: Array) => {
  const droppedContainers = R.reduce(
    (acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_DROPPED_CONTAINERS, event)),
    [],
    events,
  );
  const pickedUpContainerIds = R.reduce(
    (acc: Array, event: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, event)),
    [],
    events,
  );

  return R.compose(
    R.values,
    R.omit(pickedUpContainerIds),
    R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
  )(droppedContainers);
};

export const addTerminalPickupsToCurrentRouteTels = (
  stopId: string,
  data: Object,
  state: Object,
) => {
  const pickupTelsGuids = R.pathOr([], ['returnObject', 'pickupTels'], data);
  const singlePickup = R.equals(R.length(pickupTelsGuids), 1);
  const stopData = R.compose(
    R.assoc('eventType', GC.EVENT_TYPE_PICKUP),
    R.omit('returnObject'),
  )(data);
  const terminalPickups = R.compose(
    R.indexBy(R.prop('telGuid')),
    R.map((telGuid: string) => {
      const stopDataWithTelGuid = R.assoc('loadGuid', telGuid, stopData);
      const stopOptions = generateDataForNewTelStopFromStopForm(stopDataWithTelGuid, state);
      let newTerminal = createNewTerminalOnRouteTel(R.assoc('stopId', stopId, stopOptions));
      let availableItems = [];
      let availableContainers = [];

      if (singlePickup) {
        const relatedStops = getAllStopsFromStateByStopId(stopId, state);
        availableItems = getAvailableTerminalPickupItems(stopId, relatedStops);
        availableContainers = getAvailableTerminalPickupContainers(stopId, relatedStops);
      } else {
        const cloDropItems = getCloItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_DROP, telGuid, state);
        const cloPickupItems = getItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_PICKUP, telGuid, state);
        availableItems = filterItems(cloDropItems, cloPickupItems);
        availableContainers = getTelAvailableContainersForPickup(R.path(['currentRoute', 'tels', telGuid], state));
      }

      if (G.isNotNilAndNotEmpty(availableItems)) {
        newTerminal = R.mergeRight(
          newTerminal,
          {
            items: availableItems,
            itemIds: R.map((item: Object) => item.itemInternalId, availableItems),
          },
        );
      }

      if (G.isNotEmpty(availableContainers)) {
        newTerminal = R.mergeRight(
          newTerminal,
          {
            [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: availableContainers,
            [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS]: R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID), availableContainers),
          },
        );
      }
      return newTerminal;
    }),
  )(pickupTelsGuids);
  const newCurrentRouteTels = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.map((tel: Object) => {
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      if (R.includes(telGuid, pickupTelsGuids)) {
        const terminalPickup = R.prop(telGuid, terminalPickups);
        const newTel = R.assocPath([GC.FIELD_LOAD_STOPS, R.prop(GC.FIELD_GUID, terminalPickup)], terminalPickup, tel);
        const newTelEvents = R.compose(
          R.indexBy(R.prop(GC.FIELD_GUID)),
          R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
          R.map((event: Object) => {
            if (R.equals(event.guid, terminalPickup.guid)) {
              return R.assoc(GC.FIELD_TEL_EVENT_INDEX, 1, event);
            }
            return R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(event.telEventIndex), event);
          }),
          R.values(),
          R.prop(GC.FIELD_LOAD_STOPS),
        )(newTel);
        return R.assoc(GC.FIELD_LOAD_STOPS, newTelEvents, newTel);
      }
      return tel;
    }),
    R.values(),
    R.path(['currentRoute', 'tels']),
  )(state);
  return P.$set('currentRoute.tels', newCurrentRouteTels, state);
};

export const updateCurrentRouteTelsTerminalStops = (
  eventToUpdate: Object,
  newLocation: Object,
  state: Object,
  eventGuid: string,
) => {
  const { stopType, stopId } = eventToUpdate;
  if (R.equals(stopType, GC.STOP_TYPE_TERMINAL)) {
    const newCurrentRouteTels = R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      R.map((tel: Object) => {
        const newTelEvents = R.compose(
          R.indexBy(R.prop(GC.FIELD_GUID)),
          R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
          R.map((event: Object) => {
            if (R.and(R.equals(stopId, R.prop('stopId', event)), G.notEquals(G.getGuidFromObject(event), eventGuid))) {
              const location = R.compose(
                R.mergeRight(newLocation),
                R.pick([GC.FIELD_GUID, GC.FIELD_VERSION, GC.FIELD_CONTACTS, GC.FIELD_COMMENTS]),
                R.prop(GC.FIELD_LOCATION),
              )(event);
              return R.assoc(GC.FIELD_LOCATION, location, event);
            }
            return event;
          }),
          R.values,
          R.prop(GC.FIELD_LOAD_STOPS),
        )(tel);
        return R.assoc(GC.FIELD_LOAD_STOPS, newTelEvents, tel);
      }),
      R.values(),
      R.path(['currentRoute', 'tels']),
    )(state);
    return P.$set('currentRoute.tels', newCurrentRouteTels, state);
  }
  return state;
};

export const createTerminalStopWithExistedStopId = (options: Object) => {
  const newStop = createNewStopOnRouteTel(options);
  return {
    ...newStop,
    stopId: options.stopId,
    stopType: GC.STOP_TYPE_TERMINAL,
  };
};

export const getFirstTerminalEventLocationByStopIdFromState = (stopId: Object, state: Object) => R.compose(
  R.prop('location'),
  R.head(),
  R.reduce((acc: Array, item: Array) => R.concat(acc, item), []),
  R.map((tel: Object) => R.compose(
    R.filter(R.propEq(stopId, 'stopId')),
    R.values(),
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel)),
  R.values(),
  R.path(['currentRoute', 'tels']),
)(state);

export const isEqualSourceAndDestinationLoad = (result: Object) => (
  R.equals(
    R.path(['source', 'droppableId'], result),
    R.path(['destination', 'droppableId'], result),
  )
);

export const recreateTelEventsAfterDnD = R.compose(
  R.indexBy(R.prop(GC.FIELD_GUID)),
  R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
  G.mapIndexed((event: Object, index: number) => R.assoc('telEventIndex', R.inc(index), event)),
);

export const getIndexesFromDnDResult = (result: Object) => {
  const sourceIndex = R.path(['source', 'index'], result);
  const destinationIndex = R.path(['destination', 'index'], result);
  const destinationIndexChecked = G.ifElse(
    R.equals(destinationIndex, -1),
    0,
    destinationIndex,
  );
  return {
    sourceIndex,
    destinationIndexChecked,
  };
};

export const reorderTelEvents = (
  sourceIndex: number,
  destinationIndex: number,
  events: Object,
) => {
  const eventsArray = R.values(events);
  const [removedEvent] = eventsArray.splice(sourceIndex, 1);
  eventsArray.splice(destinationIndex, 0, removedEvent);
  return recreateTelEventsAfterDnD(eventsArray);
};

export const reorderTelEventsInState = (state: Object, result: Object) => {
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const sourceTelGuid = R.path(['source', 'droppableId'], result);
  const telEventsState = R.path(['currentRoute', 'tels', sourceTelGuid, GC.FIELD_LOAD_STOPS], state);
  const newTelEvents = reorderTelEvents(sourceIndex, destinationIndexChecked, telEventsState);
  return P.$set(
    `currentRoute.tels.${sourceTelGuid}.events`,
    newTelEvents,
    state,
  );
};

export const reorderTelEventsInCurrentRoute = (currentRoute: Object, result: Object) => {
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const sourceTelGuid = R.path(['source', 'droppableId'], result);
  const telEventsState = R.path(['tels', sourceTelGuid, GC.FIELD_LOAD_STOPS], currentRoute);
  const newTelEvents = reorderTelEvents(sourceIndex, destinationIndexChecked, telEventsState);
  return P.$set(
    `tels.${sourceTelGuid}.events`,
    newTelEvents,
    currentRoute,
  );
};

const getCloItemsFromEventsByEventType = (events: Array, eventType: string) => R.compose(
  R.reduce((acc: Array, event: Object) => R.concat(acc, event.items), []),
  R.filter((event: Object) => R.and(
    R.propEq(eventType, GC.FIELD_EVENT_TYPE, event),
    R.propEq(GC.LOAD_TYPE_CLO, GC.FIELD_LOAD_TYPE, event),
  )),
)(events);

const getContainerIdsFromItems = (items: Array) => R.compose(
  R.uniq,
  R.filter(G.isNotNilAndNotEmpty),
  R.map(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
)(items);

const getContainersFromEvents = (events: Array, propName: string) => R.reduce(
  (acc: Array, event: Object) => R.concat(acc, R.pathOr([], [propName], event)),
  [],
  events,
);

const checkTelEventsItems = (telEvents: Array, containers: Object) => {
  const terminals = R.compose(
    R.map((terminal: Object) => {
      const cloPickupItems = getCloItemsFromEventsByEventType(telEvents, GC.EVENT_TYPE_PICKUP);
      const cloDropItems = getCloItemsFromEventsByEventType(telEvents, GC.EVENT_TYPE_DROP);
      const pickedUpContainers = getContainersFromEvents(telEvents, GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS);
      const droppedContainers = getContainersFromEvents(telEvents, GC.FIELD_STOP_DROPPED_CONTAINER_IDS);
      let availableItems;
      let containersData = {};

      if (G.isStopPickup(terminal)) {
        availableItems = filterItems(cloDropItems, cloPickupItems);
        const terminalContainers = R.pathOr([], [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS], terminal);
        const containerIds = R.without(
          R.difference(terminalContainers, droppedContainers),
          terminalContainers,
        );
        containersData = {
          [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS]: containerIds,
          [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: R.values(R.pick(containerIds, containers)),
        };
      } else {
        availableItems = filterItems(cloPickupItems, cloDropItems);
        const terminalContainers = R.pathOr([], [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS], terminal);
        const containerIds = R.without(
          R.difference(terminalContainers, pickedUpContainers),
          terminalContainers,
        );
        containersData = {
          [GC.FIELD_STOP_DROPPED_CONTAINER_IDS]: containerIds,
          [GC.FIELD_STOP_DROPPED_CONTAINERS]: R.values(R.pick(containerIds, containers)),
        };
      }

      return R.mergeRight(
        terminal,
        {
          ...containersData,
          items: availableItems,
          itemIds: R.map((item: Object) => item.itemInternalId, availableItems),
        },
      );
    }),
    R.filter(R.propEq(GC.STOP_TYPE_TERMINAL, GC.FIELD_STOP_TYPE)),
  )(telEvents);
  if (R.isEmpty(terminals)) return telEvents;
  return R.map(
    (event: Object) => {
      const existInTerminals = R.find(R.eqProps(GC.FIELD_GUID, event), terminals);
      if (existInTerminals) return existInTerminals;
      return event;
    },
    telEvents,
  );
};

export const moveEventFromTelToTel = (state: Object, result: Object) => {
  const sourceTelGuid = R.path(['source', 'droppableId'], result);
  const destinationTelGuid = R.path(['destination', 'droppableId'], result);
  const sourceTelEventsArray = R.values(R.path(
    ['currentRoute', 'tels', sourceTelGuid, GC.FIELD_LOAD_STOPS],
    state,
  ));
  const destinationTelEventsArray = R.values(R.path(
    ['currentRoute', 'tels', destinationTelGuid, GC.FIELD_LOAD_STOPS],
    state,
  ));
  const containers = R.path(['currentRouteContainers'], state);
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const [removedEvent] = sourceTelEventsArray.splice(sourceIndex, 1);
  destinationTelEventsArray.splice(destinationIndexChecked, 0, removedEvent);
  const sourceEvents = checkTelEventsItems(sourceTelEventsArray, containers);
  const destinationEvents = checkTelEventsItems(destinationTelEventsArray, containers);
  return P.$all(
    P.$set(
      `currentRoute.tels.${sourceTelGuid}.events`,
      recreateTelEventsAfterDnD(sourceEvents),
    ),
    P.$set(
      `currentRoute.tels.${destinationTelGuid}.events`,
      recreateTelEventsAfterDnD(R.map(
        R.assoc('telGuid', destinationTelGuid),
        destinationEvents,
      )),
    ),
    state,
  );
};

export const mapCloDetailsEvents = (eventsCount: number, events: Array, telGuid: string) => G.mapIndexed(
  (event: Object, index: number) => R.assoc(
    'telGuid',
    telGuid,
    R.assoc(
      'telEventIndex',
      R.add(
        R.inc(index),
        eventsCount,
      ),
      event,
    ),
  ), events);

export const createNewEventsForTel = (cloDetails: Object, tel: Object) => {
  const telEvents = R.values(R.prop(GC.FIELD_LOAD_STOPS, tel));
  const telEventsCount = R.length(telEvents);
  const mappedCloEvents = mapCloDetailsEvents(
    telEventsCount,
    R.prop(GC.FIELD_LOAD_STOPS, cloDetails),
    tel.guid,
  );
  return R.indexBy(R.prop(GC.FIELD_GUID), R.concat(telEvents, mappedCloEvents));
};

export const addTelItems = (currentItems: Object = {}, items: Object = []) => (
  R.mergeRight(
    currentItems,
    R.indexBy(
      R.prop('itemInternalId'),
      R.map(
        (item: Object) => R.mergeRight({
          isNew: false,
          selected: true,
        }, item),
        items,
      ),
    ),
  )
);

export const getItemsToRemove = (itemsToFilter: Array, tels: Object) => R.filter(
  (itemId: string) => R.not(R.any(
    (tel: Object) => R.any(
      (event: Object) => R.any(
        (id: string) => R.equals(id, itemId),
        event.itemIds,
      ),
      R.values(tel[GC.FIELD_LOAD_STOPS]),
    ),
    R.values(tels),
  )),
  itemsToFilter,
);

const filterAndMergeTelEventItems = (telEvent: Object, itemsToFilter: Array) => R.mergeRight(telEvent, {
  itemIds: R.difference(telEvent.itemIds, itemsToFilter),
  items: R.filter(
    (item: Object) => R.all(
      (id: string) => R.not(R.propEq(id, GC.FIELD_ITEM_INTERNAL_ID, item)),
      itemsToFilter,
    ),
    telEvent.items,
  ),
});

export const getTelsFilteredFromItems = (
  tels: Object,
  stopTel: Object,
  stop: Object,
  itemsToFilter: Array,
) => {
  const isCloStop = G.isLoadTypeClo(stop);
  const isCloPickup = R.and(isCloStop, G.isStopPickup(stop));
  if (isCloStop) {
    return R.map(
      (routeTel: Object) => R.assoc(
        GC.FIELD_LOAD_STOPS,
        R.map(
          (telEvent: Object) => {
            if (R.or(isCloPickup, R.eqProps(GC.FIELD_GUID, telEvent, stop))) {
              return filterAndMergeTelEventItems(telEvent, itemsToFilter);
            }
            return telEvent;
          },
          routeTel[GC.FIELD_LOAD_STOPS],
        ),
        routeTel,
      ),
      tels,
    );
  }
  return R.map(
    (routeTel: Object) => {
      if (R.gte(routeTel.order, stopTel.order)) {
        return R.assoc(
          GC.FIELD_LOAD_STOPS,
          R.map(
            (telEvent: Object) => {
              if (R.or(
                G.isLoadTypeClo(telEvent),
                R.and(
                  R.eqProps(GC.FIELD_GUID, routeTel, stopTel),
                  R.lt(telEvent.telEventIndex, stop.telEventIndex),
                ),
              )) return telEvent;
              return filterAndMergeTelEventItems(telEvent, itemsToFilter);
            },
            routeTel[GC.FIELD_LOAD_STOPS],
          ),
          routeTel,
        );
      }
      return routeTel;
    },
    tels,
  );
};

const filterTelEventContainers = (event: Object, containerIds: Array) => R.mergeRight(event, {
  [GC.FIELD_STOP_DROPPED_CONTAINER_IDS]: R.without(
    containerIds,
    R.prop(GC.FIELD_STOP_DROPPED_CONTAINER_IDS, event),
  ),
  [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS]: R.without(
    containerIds,
    R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, event),
  ),
  [GC.FIELD_STOP_DROPPED_CONTAINERS]: R.compose(
    R.values,
    R.omit(containerIds),
    R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
    R.prop(GC.FIELD_STOP_DROPPED_CONTAINERS),
  )(event),
  [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: R.compose(
    R.values,
    R.omit(containerIds),
    R.indexBy(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID)),
    R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS),
  )(event),
});

const filterEventItemsFromContainers = (event: Object, containerIds: Array) => R.assoc(
  GC.FIELD_LOAD_ITEMS,
  R.map(
    (item: Object) => {
      const { containerInternalId } = item;
      if (R.includes(containerInternalId, containerIds)) {
        return R.mergeRight(item, {
          [GC.FIELD_CONTAINER_GUID]: null,
          [GC.FIELD_CONTAINER_INTERNAL_ID]: null,
        });
      }
      return item;
    },
    R.prop(GC.FIELD_LOAD_ITEMS, event),
  ),
  event,
);

export const getTelsFilteredFromContainers = (
  tels: Object,
  stopTel: Object,
  stop: Object,
  isPickupContainer: boolean,
  containersToFilter: Array,
) => {
  const isCloStop = G.isLoadTypeClo(stop);
  const isPickup = G.isStopPickup(stop);

  return R.map(
    (routeTel: Object) => R.assoc(
      GC.FIELD_LOAD_STOPS,
      R.map(
        (telEvent: Object) => {
          const cond1 = R.and(isCloStop, isPickupContainer);
          const cond2 = R.eqProps(GC.FIELD_GUID, telEvent, stop);
          const cond3 = R.and(R.not(isPickup), R.not(G.isLoadTypeClo(telEvent)));

          if (G.isAnyTrue(cond1, cond2, cond3)) {
            const eventToUse = G.ifElse(
              cond1,
              filterEventItemsFromContainers(telEvent, containersToFilter),
              telEvent,
            );

            return filterTelEventContainers(eventToUse, containersToFilter);
          }

          return telEvent;
        },
        routeTel[GC.FIELD_LOAD_STOPS],
      ),
      routeTel,
    ),
    tels,
  );
};

export const removeCloItemsFromEvent = (event: Object, cloGuid: string) => {
  const itemIds = R.compose(
    R.map((item: Object) => item.itemInternalId),
    R.filter((item: Object) => R.equals(item.loadGuid, cloGuid)),
  )(event.items);
  return R.mergeRight(
    event,
    {
      itemIds: R.without(itemIds, event.itemIds),
      items: R.filter(
        (item: Object) => G.notEquals(item.loadGuid, cloGuid),
        event.items,
      ),
    },
  );
};

export const removeCloEventsFromTels = (cloGuid: Object, tels: Array) => (
  R.mapObjIndexed((tel: Object) => (
    R.assoc(
      GC.FIELD_LOAD_STOPS,
      R.indexBy(
        R.prop(GC.FIELD_GUID),
        sortTelEvents(
          R.filter((event: Object) => R.not(R.equals(event.cloGuid, cloGuid)), tel[GC.FIELD_LOAD_STOPS]),
        ).map(
          (event: Object, index: number) => R.assoc(
            'telEventIndex',
            R.inc(index),
            removeCloItemsFromEvent(event, cloGuid),
          ),
        ),
      ),
      tel,
    )
  ), tels)
);

const removeRouteEventsFromTels = (routeGuid: Object, tels: Array) => R.compose(
  R.indexBy(R.prop(GC.FIELD_GUID)),
  R.filter((tel: Object) => R.not(R.and(
    G.isTrue(tel.isNew),
    R.isEmpty(R.values(tel[GC.FIELD_LOAD_STOPS])),
  ))),
  G.mapIndexed(
    (tel: Object, i: number) => {
      let newTel = R.assoc(
        GC.FIELD_LOAD_STOPS,
        R.indexBy(
          R.prop(GC.FIELD_GUID),
          sortTelEvents(R.filter(
            (event: Object) => R.not(R.equals(event[C.ORIGINAL_ROUTE_GUID], routeGuid)),
            tel[GC.FIELD_LOAD_STOPS],
          )).map((event: Object, index: number) => R.assoc(
            'telEventIndex',
            R.inc(index),
            event,
          )),
        ),
        tel,
      );
      if (R.equals(tel[C.ORIGINAL_ROUTE_GUID], routeGuid)) {
        newTel = R.mergeRight(
          R.omit([C.ORIGINAL_ROUTE_GUID, C.ORIGINAL_ROUTE_NAME], newTel),
          {
            isNew: true,
            loadName: `TEL-${R.inc(i)}`,
            primaryReference: { value: `TEL-${R.inc(i)}` },
          },
        );
      }
      return newTel;
    },
  ),
  R.values,
)(tels);

export const removeTelFromPlanner = (state: Object, guid: string) => {
  const { currentRoute } = state;
  const routeGuid = R.path([GC.SYSTEM_LIST_TELS, guid, C.ORIGINAL_ROUTE_GUID], currentRoute);
  let closToRemove = [];
  let telsToRemove = [];
  R.values(currentRoute[GC.SYSTEM_LIST_TELS]).forEach((tel: Object) => {
    if (R.equals(tel[C.ORIGINAL_ROUTE_GUID], routeGuid)) telsToRemove = R.append(tel[GC.FIELD_GUID], telsToRemove);
    R.values(tel[GC.FIELD_LOAD_STOPS]).forEach((event: Object) => {
      if (R.equals(routeGuid, event[C.ORIGINAL_ROUTE_GUID])) {
        if (G.isLoadTypeClo(event)) {
          closToRemove = R.append(event[GC.FIELD_LOAD_GUID], closToRemove);
        }
      }
    });
  });
  const selectedTels = R.omit(telsToRemove, state.selectedTels);
  const telList = setSelectedPropIfExist(state.telList, R.values(selectedTels));
  const inboundTelList = setSelectedPropIfExist(state.inboundTelList, R.values(selectedTels));
  const outboundTelList = setSelectedPropIfExist(state.outboundTelList, R.values(selectedTels));
  closToRemove = R.uniq(closToRemove);
  const clos = R.omit(closToRemove, state.currentRoute[GC.SYSTEM_LIST_CLOS]);
  const cloList = R.map(
    (clo: Object) => R.assoc(
      'selected',
      G.ifElse(
        R.includes(clo[GC.FIELD_GUID], closToRemove),
        false,
        R.prop('selected', clo),
      ),
      clo,
    ),
    state.cloList,
  );
  const currentRouteItems = R.compose(
    R.indexBy(R.prop('itemInternalId')),
    R.filter((item: Object) => G.notContain(item.loadGuid, closToRemove)),
    R.values,
  )(state.currentRouteItems);
  let tels = currentRoute[GC.SYSTEM_LIST_TELS];
  closToRemove.forEach((cloGuid: string) => {
    tels = removeCloEventsFromTels(cloGuid, tels);
  });
  tels = removeRouteEventsFromTels(routeGuid, tels);
  return R.mergeRight(
    state,
    {
      cloList,
      telList,
      selectedTels,
      inboundTelList,
      outboundTelList,
      currentRouteItems,
      currentRoute: R.mergeRight(currentRoute, { clos, tels }),
    },
  );
};

export const getUpdatedStopOnAddItem = ({ stop, state, loadItems, currentItems }: Object) => {
  const items = R.uniqBy(R.prop(GC.FIELD_GUID), loadItems);
  const itemIds = R.compose(
    R.uniq,
    R.concat(R.pathOr(
      [],
      ['currentRoute', 'tels', stop.telGuid, GC.FIELD_LOAD_STOPS, stop.guid, 'itemIds'],
      state,
    )),
    R.concat(currentItems),
    R.map((loadItem: Object) => loadItem.itemInternalId),
  )(loadItems);
  if (G.isStopTypeTerminal(stop.stopType)) {
    const containers = state.currentRouteContainers;
    let containerIds = getContainerIdsFromItems(items);
    if (G.isStopPickup(stop)) {
      containerIds = R.compose(
        R.uniq,
        R.concat(R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS, stop)),
      )(containerIds);

      return R.mergeRight(stop, {
        items,
        itemIds,
        [GC.FIELD_STOP_PICKED_UP_CONTAINER_IDS]: containerIds,
        [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: R.values(R.pick(containerIds, containers)),
      });
    }
    containerIds = R.compose(
      R.uniq,
      R.concat(R.prop(GC.FIELD_STOP_DROPPED_CONTAINER_IDS, stop)),
    )(containerIds);

    return R.mergeRight(stop, {
      items,
      itemIds,
      [GC.FIELD_STOP_DROPPED_CONTAINER_IDS]: containerIds,
      [GC.FIELD_STOP_DROPPED_CONTAINERS]: R.values(R.pick(containerIds, containers)),
    });
  }

  return R.mergeRight(stop, { items, itemIds });
};
// REDUCER

// SELECTORS
export const getRouteTotalDistance = (loads: Object, distanceName: string) => {
  const loadsDistances = R.map(
    (load: Object) => G.getLoadTotalDistance(load, distanceName),
    R.values(loads),
  );
  const totalTripDistance = R.sum(R.map(R.prop('totalTripDistance'), loadsDistances));
  const totalTripDistanceUom = G.ifElse(
    R.equals(NaN, totalTripDistance),
    '',
    R.pathOr(GC.UOM_MILE, [0, 'totalTripDistanceUom'], loadsDistances),
  );
  return {
    totalTripDistanceUom,
    totalTripDistance: G.ifElse(
      R.equals(NaN, totalTripDistance),
      'N/A',
      totalTripDistance.toFixed(2),
    ),
  };
};
// SELECTORS

// UI
export const sortIndexedListByProp = (list: any, prop: string) => (
  R.compose(
    R.sortBy(R.prop(prop)),
    R.values(),
    R.filter((item: Object) => G.isNotNil(item)),
  )(list)
);

export const convertLoadEventsForMap = (events: Array) => {
  const stops = R.map((stop: Object) => {
    const lat = R.path(['location', 'latitude'], stop);
    const lng = R.path(['location', 'longitude'], stop);
    if (R.or(R.isNil(lat), R.isNil(lng))) return null;
    return {
      ...stop,
      guid: stop.guid,
      title: `${G.toTitleCase(stop.eventType)} ${stop.telEventIndex}`,
      latLng: { lat, lng },
    };
  }, events);
  return sortIndexedListByProp(stops, 'telEventIndex');
};

export const getTelTotalInfo = (tel: Object, items: Object) => {
  if (G.isNilOrEmpty(tel[GC.FIELD_LOAD_STOPS])) return false;
  const itemIds = R.uniq(R.reduce(
    R.concat,
    [],
    R.map(
      (event: Object) => event.itemIds,
      R.values(tel[GC.FIELD_LOAD_STOPS]),
    ),
  ));
  const telItems = R.filter(
    (item: any) => G.isNotNilAndNotEmpty(item),
    R.map(
      (id: string) => R.prop(id, items),
      itemIds,
    ),
  );
  if (G.isNilOrEmpty(telItems)) return false;
  return {
    itemsTotal: telItems.length,
    totalQuantity: G.calculateTotalQuantity(telItems),
    totalWeight: G.calcItemsTotalWeightWithoutQty(telItems),
    totalDistance: G.getLoadTotalDistance(tel, 'telDistanceToNextStop'),
  };
};

export const getAvailableExistedTerminalOptions = (options: Array, events: Object) => {
  const stopIds = R.compose(
    R.map((event: Object) => event.stopId),
    R.filter((event: Object) => R.equals(event.stopType, GC.STOP_TYPE_TERMINAL)),
    R.values,
  )(events);
  if (G.isNilOrEmpty(stopIds)) return options;
  return R.filter(
    (option: Object) => R.not(R.any(
      (stopId: string) => R.equals(option.value, stopId),
      stopIds,
    )),
    options,
  );
};

export const getStopDistanceInfo = (manual: string, distance: Object) => {
  if (G.isNotNil(manual)) {
    return `
      ${R.pathOr('', [GC.FIELD_DISTANCE_MANUAL], distance)}
      ${R.pathOr('', [GC.FIELD_DISTANCE_MANUAL_UOM], distance)}
    `;
  }
  return `
    ${R.pathOr('', [GC.FIELD_DISTANCE_SYSTEM], distance)}
    ${R.pathOr('', [GC.FIELD_DISTANCE_SYSTEM_UOM], distance)}
  `;
};

export const getStopItemsCount = (stop: Object={}) => {
  if (R.or(
    G.isTrue(R.path(['cloEvent'], stop)),
    G.notEquals(R.path(['stopType'], stop), GC.STOP_TYPE_TERMINAL),
  )) {
    return R.length(stop.itemIds);
  }
  return R.compose(
    R.add(R.length(stop.items)),
    R.length,
    R.filter(
      (id: string) => G.isNilOrEmpty(R.find(
        ({ itemInternalId }: Object) => R.equals(itemInternalId, id),
        stop.items,
      )),
    ),
  )(stop.itemIds);
};

export const checkTelExpandedOnPlanner = (stops: Array, mode: string, visibility: string) => {
  if (G.isNotNilAndNotEmpty(visibility)) {
    return G.ifElse(R.equals(visibility, 'visible'), true, false);
  }
  if (R.equals(mode, C.PLANNER_TEL_MODES.ALL_EXPANDED)) return true;
  if (R.equals(mode, C.PLANNER_TEL_MODES.ALL_COLLAPSED)) return false;
  const typeMap = {
    [C.PLANNER_TEL_MODES.INBOUND]: GC.EVENT_TYPE_DROP,
    [C.PLANNER_TEL_MODES.OUTBOUND]: GC.EVENT_TYPE_PICKUP,
  };
  const terminals = R.filter(
    (stop: Object) => R.equals(stop.stopType, GC.STOP_TYPE_TERMINAL),
    stops,
  );
  return R.all(
    R.propEq(typeMap[mode], GC.FIELD_EVENT_TYPE),
    terminals,
  );
};

export const getContainerInfo = (container: Object) => {
  const { weightUom, containerNumber, containerInitial, fullContainerWeight } = container;
  const info1 = `${G.createStringFromArray([containerInitial, containerNumber], '')}, `;
  const info2 = G.createStringFromArray([fullContainerWeight, weightUom], ' - ');

  return `${info1} ${info2}`;
};
// UI

// logic
const isWrongEventDate = (event: Object, index: number, events: Array) => {
  const eventAppointment = G.ifElse(
    R.and(
      G.isNotNilAndNotEmpty(event[GC.FIELD_LOAD_APPOINTMENT_DATE]),
      G.isNotNilAndNotEmpty(event[GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]),
    ),
    `${event[GC.FIELD_LOAD_APPOINTMENT_DATE]} ${event[GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]}`,
    null,
  );
  const prevEventAppointment = G.ifElse(
    R.and(
      G.isNotNilAndNotEmpty(R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_DATE], events)),
      G.isNotNilAndNotEmpty(R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], events)),
    ),
    `${R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_DATE], events)} ${
      R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], events)}`,
    null,
  );

  return R.and(
    R.gt(index, 0),
    G.isBefore(
      R.or(eventAppointment, event[GC.FIELD_LOAD_EVENT_LATE_DATE]),
      R.or(prevEventAppointment, R.path([R.dec(index), GC.FIELD_LOAD_EVENT_EARLY_DATE], events)),
    ),
  );
};

const getWrongPickupItems = (event: Object, index: number, events: Array) => {
  const dropItems = R.compose(
    R.reduce(R.concat, []),
    R.map(R.prop(GC.FIELD_LOAD_ITEMS)),
    R.filter(R.propEq(GC.EVENT_TYPE_DROP, GC.FIELD_EVENT_TYPE)),
    R.slice(R.inc(index), Infinity),
  )(events);

  return R.filter(
    (item: Object) => G.isNilOrEmpty(R.find(
      R.eqProps(GC.FIELD_GUID, item),
      dropItems,
    )),
    R.prop(GC.FIELD_LOAD_ITEMS, event),
  );
};

const getWrongDropItems = (event: Object, index: number, events: Array) => {
  const pickupItems = R.compose(
    R.reduce(R.concat, []),
    R.map(R.prop(GC.FIELD_STOP_ITEM_IDS)),
    R.filter(R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE)),
    R.take(index),
  )(events);

  return R.filter(
    (item: Object) => G.isNilOrEmpty(R.find(
      R.equals(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item)),
      pickupItems,
    )),
    R.prop(GC.FIELD_LOAD_ITEMS, event),
  );
};

const getWrongItemContainers = (itemIds: Array, items: Object) => R.filter(
  (itemId: Object) => G.isNilOrEmpty(R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, R.prop(itemId, items))),
  itemIds,
);

export const getEventsErrors = (events: Array, currentRouteItems: Object) => {
  let errors = {};
  events.forEach((event: Object, index: number) => {
    const isPickup = G.isEventTypePickup(R.prop(GC.FIELD_EVENT_TYPE, event));
    const isWrongDate = isWrongEventDate(event, index, events);
    if (isWrongDate) {
      errors = R.assocPath([R.prop(GC.FIELD_GUID, event), GC.FIELD_DATE], true, errors);
    }

    const wrongItems = G.ifElse(
      isPickup,
      getWrongPickupItems,
      getWrongDropItems,
    )(event, index, events);
    if (G.isNotEmpty(wrongItems)) {
      errors = R.assocPath([R.prop(GC.FIELD_GUID, event), GC.FIELD_LOAD_ITEMS], wrongItems, errors);
    }

    // TODO: clarify and improve
    // if (G.hasEventContainers(event)) {
    //   const itemContainers = getWrongItemContainers(R.prop(GC.FIELD_STOP_ITEM_IDS, event), currentRouteItems);
    //   if (G.isNotEmpty(itemContainers)) {
    //     errors = R.assocPath([R.prop(GC.FIELD_GUID, event), 'itemContainers'], itemContainers, errors);
    //   }
    // }
  });

  return errors;
};

export const getContainersError = (tel: Object) => {
  const pickupContainers = getTelAvailableContainersForPickup(tel);
  const dropContainers = getAvailableContainersForDrop(tel);

  return R.or(G.isNotEmpty(pickupContainers), G.isNotEmpty(dropContainers));
};

export const checkRouteRates = (tels: Array) => {
  const commitmentsArray = R.map(
    ({ rates, carrierRates }: Object) => {
      if (G.isNotNil(carrierRates)) {
        return R.reduce(
          (acc: Array, { commitmentCount }: Object) => R.add(acc, R.or(commitmentCount, 0)),
          0,
          carrierRates,
        );
      }

      return R.compose(
        R.reduce((acc: Array, { commitmentCount }: Object) => R.add(acc, R.or(commitmentCount, 0)), 0),
        R.filter((rate: Object) => G.isNotNil(R.prop(GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, rate))),
      )(R.or(rates, []));
    },
    tels,
  );

  return commitmentsArray.every((val: number, i: number, arr: Array) => R.equals(val, arr[0]));
};

export const getRateGuidToCommitmentCountMap = (tels: Array) => {
  const map = {};

  tels.forEach(({ rates }: Object) => (
    R.or(rates, []).forEach(({ guid, commitmentCount }: Object) => map[guid] = commitmentCount)
  ));

  return map;
};
// logic
