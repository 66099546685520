import React from 'react';
import * as R from 'ramda';
import { compose } from 'react-recompose';
// components
import TextComponent from '../../../../components/text';
import { DateSelector, DateRangeMui } from '../../../../components/date-range';
import UnavailablePeriodForm from '../../../drivers-card/forms/unavailable-period-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../../hocs';
// ui
import { Box, Flex, ReactSelect } from '../../../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withComponentDidUpdatePropCallback({
    callbackName: 'getItemListRequest',
    propName: 'unavailablePeriodDatesFilter',
  }),
  withComponentDidUpdatePropCallback({
    callbackName: 'getItemListRequest',
    propName: 'unavailablePeriodReasonFilter',
  }),
);

const AdditionalFormGroupTitleComponent = enhance((props: Object) => {
  const {
    configGroup,
    unavailablePeriodDatesFilter,
    unavailablePeriodReasonFilter,
    setUnavailablePeriodDatesFilter,
    setUnavailablePeriodReasonFilter,
  } = props;

  const useMuiCalendar = G.isTrue(G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR));

  const DateRangeComponent = G.ifElse(useMuiCalendar, DateRangeMui, DateSelector);

  const dateRangeProps = {
    width: 115,
    margin: '0',
    isClearable: true,
    withIcon: useMuiCalendar,
    maxDate: G.momentAddYearsFromCurrent(100),
    iconColor: G.getThemeColor('colors.white'),
    onSelectDateRange: setUnavailablePeriodDatesFilter,
    dateTo: R.pathOr(null, [GC.FIELD_DATE_TO], unavailablePeriodDatesFilter),
    dateFrom: R.pathOr(null, [GC.FIELD_DATE_FROM], unavailablePeriodDatesFilter),
    inputSettings: {
      height: 28,
      fontSize: 14,
      borderRadius: '2px',
      borderColor: G.getTheme('forms.inputs.borderColor'),
    },
  };

  return (
    <Flex ml={15} color={G.getThemeColor('colors.black')}>
      <DateRangeComponent {...dateRangeProps} />
      <ReactSelect
        value={R.or(unavailablePeriodReasonFilter, '')}
        placeholder={G.getWindowLocale('titles:reason', 'Reason')}
        options={R.drop(1, R.pathOr([], ['dropdownOptions', GC.DRIVER_OUT_DUTY_REASON], configGroup))}
        onChange={(option: Object) => setUnavailablePeriodReasonFilter(R.pathOr('', [GC.FIELD_VALUE], option))}
        additionalStyles={{
          container: (baseStyles: Object) => ({
            ...baseStyles,
            width: 230,
            margin: '5px 10px',
          }),
          menu: (baseStyles: Object) => ({
            ...baseStyles,
            zIndex: 11,
            color: G.getTheme('colors.black'),
          }),
        }}
      />
    </Flex>
  );
});

const commonTextProps = { display: 'block', withEllipsis: true };

const columnSettings = {
  [GC.FIELD_START_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:start-date',
  },
  [GC.FIELD_END_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:end-date',
  },
  [GC.FIELD_REASON]: {
    width: 150,
    name: 'titles:reason',
    customComponent: ({ data }: Object) => {
      const reason = R.pathOr('', [GC.FIELD_REASON], data);

      if (G.isNilOrEmpty(reason)) return null;

      const reasonDisplayedValue = G.getDisplayedValueFromObject(reason);

      return <TextComponent {...commonTextProps} title={reasonDisplayedValue}>{reasonDisplayedValue}</TextComponent>;
    },
  },
  [GC.FIELD_NOTE]: {
    width: 300,
    name: 'titles:note',
  },
  [GC.FIELD_LOCATION]: {
    width: 300,
    name: 'titles:location',
    customComponent: ({ data }: Object) => {
      const location = G.getPropFromObject(GC.FIELD_LOCATION, data);

      if (G.isNilOrEmpty(location)) return null;

      const locationString = G.concatLocationFields(location);

      return <TextComponent {...commonTextProps} title={locationString}>{locationString}</TextComponent>;
    },
  },
};

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(columnSettings),
};

const tittleArr = ['titles:unavailable-period', 'Unavailable Period'];

export const unavailablePeriodSettings = {
  report,
  columnSettings,
  itemTitleArr: tittleArr,
  formGroupTitleArr: tittleArr,
  getItemListRequestMethod: 'post',
  AdditionalFormGroupTitleComponent,
  groupName: 'unavailablePeriodList',
  endpoints: {
    list: 'routeFleetDriverUnavailablePeriodList',
    createOrUpdate: 'routeFleetDriverUnavailablePeriod',
    remove: 'getRemoveRouteFleetDriverUnavailablePeriod',
  },
  CustomForm: (props: Object) => {
    const { submitAction, initialValues, primaryObjectGuid } = props;

    const reason = R.pathOr({}, [GC.FIELD_REASON], initialValues);

    return (
      <Box width={415}>
        <UnavailablePeriodForm
          initialValues={R.assoc(GC.FIELD_REASON, G.getDropdownOptionGuidFromObject(reason), initialValues)}
          submitHandler={(values: Object) => {
            const { endDate, location, startDate } = values;

            const data = {
              ...values,
              [GC.FIELD_DRIVER_GUID]: primaryObjectGuid,
              [GC.FIELD_END_DATE]: G.convertInstanceToDefaultDateTimeFormat(endDate),
              [GC.FIELD_START_DATE]: G.convertInstanceToDefaultDateTimeFormat(startDate),
              [GC.FIELD_LOCATION]: G.ifElse(G.isNotEmptyString(location), location, null),
            };

            submitAction(data);
          }}
        />
      </Box>
    );
  },
  // helpers
  makeRequestPayload: (props: Object) => {
    const { primaryObjectGuid, unavailablePeriodDatesFilter, unavailablePeriodReasonFilter } = props;

    // TODO: implement the pagination after adding this logic on the front-end
    const data = {
      limit: 300,
      pageable: false,
      [GC.FIELD_DRIVER_GUID]: primaryObjectGuid,
      [GC.FIELD_REASON]: R.or(unavailablePeriodReasonFilter, null),
      toDate: R.pathOr(null, [GC.FIELD_DATE_TO], unavailablePeriodDatesFilter),
      fromDate: R.pathOr(null, [GC.FIELD_DATE_FROM], unavailablePeriodDatesFilter),
    };

    return { data };
  },
};
