import React from 'react';
import {
  Area,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ComposedChart,
  CartesianGrid,
} from 'recharts';
// feature dashboards
import { CHART_COLORS } from '../constants';
import { getDataForAreaAndLineChartFromDashboard } from '../helpers';
import {
  xAxisCommonProps,
  yAxisCommonProps,
  legendCommonProps,
  cartesianGridCommonProps,
  largeChartWrapperCommonProps,
} from '../settings';
//////////////////////////////////////////////////

const ticks = [0, 20, 40, 60, 80, 100];

const ComposedAreaAndLineChart = (props: Object) => {
  const {
    data,
    ticksY,
    lineName,
    areaName,
    lineDataKey,
    areaDataKey,
    tickFormatter,
  } = props;

  const dataToUse = getDataForAreaAndLineChartFromDashboard(data);

  return (
    <ComposedChart
      data={dataToUse}
      {...largeChartWrapperCommonProps}
    >
      <CartesianGrid {...cartesianGridCommonProps} />
      <XAxis
        dataKey='name'
        {...xAxisCommonProps}
      />
      <YAxis
        ticks={ticksY || ticks}
        allowDataOverflow={true}
        tickFormatter={tickFormatter}
        {...yAxisCommonProps}
      />
      <Tooltip />
      <Legend {...legendCommonProps} />
      <defs>
        <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
          <stop offset='10%' stopColor={CHART_COLORS.blue2} stopOpacity={0.61} />
          <stop offset='90%' stopColor={CHART_COLORS.blue2} stopOpacity={0.1} />
        </linearGradient>
      </defs>
      <Area
        name={areaName}
        type='monotone'
        strokeWidth={3}
        dataKey={areaDataKey}
        fill='url(#splitColor)'
        stroke={CHART_COLORS.blue2}
      />
      <Line
        dot={false}
        strokeWidth={3}
        name={lineName}
        type='monotone'
        dataKey={lineDataKey}
        stroke={CHART_COLORS.grey1}
      />
    </ComposedChart>
  );
};

export default ComposedAreaAndLineChart;
