import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature config
import {
  statusReasonCodeMappingFieldSettings,
  defaultStatusReasonCodeMappingFields,
  statusReasonCodeMappingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: statusReasonCodeMappingValidationSchema,
    handleSubmit: (values: Object, { props: { mappingType, submitAction } }: Object) =>
      submitAction({ mappingType, data: values }),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultStatusReasonCodeMappingFields,
      initialValues,
    ),
  }),
  pure,
);

export const StatusReasonCodeMappingForm = enhance((props: Object) => {
  const { isEditMode, handleSubmit, statusReasonCodeOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={statusReasonCodeMappingFieldSettings}
        handlers={{ disableIntegrationType: () => isEditMode }}
        fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
        statusReasonCodeOptions={R.tail(statusReasonCodeOptions)}
      />
      <FormFooter2 />
    </form>
  );
});
