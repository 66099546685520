import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
// feature charts
import { selectOptionsByDataType } from './settings/filter-params';
//////////////////////////////////////////////////

export const getValue = (input: Object, options: Array) => (
  R.find((option: Object) => (
      R.or(
        R.equals(option.label, input.value),
        R.equals(option.value, input.value),
      )),
    options,
  )
);

export const setOptions = (options: Object) => {
  return options.map((option: Object) => ({
    label: option.name,
    value: option.guid,
  }));
};

export const setAdditionBtns = (props: Object) => (
  G.notEquals(props.match.path, routesMap.chartNew)
);

export const getCondition = ({ formValue }: Object) => (
  G.ifElse(R.pathOr('', ['values', 'dataType'], formValue), true, null)
);

export const getSelectOptions = ({ formValue }: Object, param: string) => {
  const dataType = R.pathOr('defaultParams', ['values', 'dataType'], formValue);
  return R.or(selectOptionsByDataType[dataType], selectOptionsByDataType.defaultParams)[param];
};

export const setDisabledStatus = ({ initialValues }: Object) => {
  const currentBranchGuid = G.getCurrentBranchGuid();
  const chartBranchGuid = R.pathOr('', [GC.FIELD_OWNING_BRANCH_GUID], initialValues);
  if (G.isNilOrEmpty(chartBranchGuid)) return false;
  if (G.notEquals(currentBranchGuid, chartBranchGuid)) {
    return true;
  }
};

export const changeConstToLocaleInPreview = (data: Object, options: Array) => {
  if (G.isNotNilAndNotEmpty(data)) {
    const dataX = R.clone(R.prop('data', data));
    const newData = dataX.map((item: Object) => G.ifElse(
      R.hasIn(R.toUpper(GC.FIELD_STATUS), item),
      R.assoc(R.toUpper(GC.FIELD_STATUS), G.getWindowLocale(options[item.STATUS], item.STATUS), item),
      item,
    ));
    return R.assoc('data', newData, data);
  }
  return data;
};

export const getFormattedNumber = (value: number) => {
  if (R.and(R.gt(1000, value), R.gt(value, 0))) {
    return value;
  } else if (R.and(R.gt(1000000, value), R.gte(value, 1000))) {
    return `${R.divide(value, 1000).toFixed(2)}k`;
  } else if (R.and(R.gt(1000000000, value), R.gte(value, 1000000))) {
    return `${R.divide(value, 1000000).toFixed(2)}M`;
  } else if (R.and(R.gte(value, -1000), R.gt(1000, value))) {
    return value;
  } else if (R.and(R.gte(value, -1000000), R.gt(-1000, value))) {
    return `${R.divide(value, 1000).toFixed(2)}k`;
  } else if (R.and(R.gte(value, -1000000000), R.gt(-1000000, value))) {
    return `${R.divide(value, 1000000).toFixed(2)}M`;
  }
  return value;
};
