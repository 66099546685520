import * as R from 'ramda';
import React, { Fragment, useState, useEffect } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const SectionHeader = (props: Object) => {
  const { title, expanded, children } = props;

  const [opened, setOpened] = useState(expanded);

  useEffect(() => {
    setOpened(expanded);
  }, [expanded]);

  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Fragment>
      <Flex
        p='5px'
        my={10}
        bg='whiteGrey'
        fontWeight='bold'
        color='dark.blue'
        justifyContent='center'
        alignItems='flex-start'
        textTransform='uppercase'
      >
        <Box mr={15} ml='auto'>{title}</Box>
        <Box
          px={6}
          ml='auto'
          cursor='pointer'
          onClick={() => setOpened(R.not)}
        >
          {I[toggleIconName](darkBlueColor)}
        </Box>
      </Flex>
      {G.isTrue(opened) && children}
    </Fragment>
  );
};

export default SectionHeader;
