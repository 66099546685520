/* eslint-disable max-len */
import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState } from 'react-recompose';
import { Marker, InfoWindow } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
// components
import { Map } from '../../../components/map';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature customer-portal
import MarkerInfo from './marker-info';
//////////////////////////////////////////////////

const markersMap = {
  [GC.TRANSPORTATION_MODE_TYPE_LTL]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/6fcb3e96-5618-e022-5786-fceaec77b9c1.png',
  [GC.TRANSPORTATION_MODE_TYPE_AIR]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/6996586c-0456-8fcf-14ad-2f01c5ca89c9.png',
  [GC.TRANSPORTATION_MODE_TYPE_RAIL]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/4046eb85-e116-f479-cc30-a5750fa04390.png',
  [GC.TRANSPORTATION_MODE_TYPE_OCEAN]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/097f14f0-877c-9603-2af7-3163ed32de4e.png',
  [GC.TRANSPORTATION_MODE_TYPE_DRAYAGE]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/6fcb3e96-5618-e022-5786-fceaec77b9c1.png',
  [GC.TRANSPORTATION_MODE_TYPE_TRUCK_LOAD]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/c6cabdad-be06-8754-9688-1de017019cea.png',
  [GC.TRANSPORTATION_MODE_TYPE_SMALL_TRUCK]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/33205372-3212-37ba-7495-87898296e285.png',
  [GC.TRANSPORTATION_MODE_TYPE_BOX_SHIPMENTS]: 'https://mcusercontent.com/517fba53d1fc092f04ec50e82/images/77034c66-7e36-10cc-403a-ae7e44dcf52f.png',
};

const enhance = compose(
  withState('locationGuid', 'setLocationGuid', null),
  pure,
);

const OrderMarkerWithInfo = enhance(({
  locations,
  locationGuid,
  setLocationGuid,
  setExpandedContainerOptions,
}: Object) => (
  <MarkerClusterer gridSize={60} averageCenter={true}>
    {
      locations.map((location: Object, index: number) => {
        const {
          guid,
          latLng,
          transportationModeType,
        } = location;

        const sizes = G.ifElse(
          R.equals(transportationModeType, GC.TRANSPORTATION_MODE_TYPE_TRUCK_LOAD),
          [40, 40],
          [80, 80],
        );

        return (
          <Marker
            key={index}
            position={latLng}
            onClick={() => setLocationGuid(guid)}
            icon={{
              // eslint-disable-next-line no-undef
              scaledSize: new google.maps.Size(...sizes),
              url: G.getPropFromObject(transportationModeType, markersMap),
            }}
          >
            {
              R.equals(locationGuid, guid) &&
              <InfoWindow onCloseClick={() => setLocationGuid(null)}>
                <MarkerInfo {...location} setExpandedContainerOptions={setExpandedContainerOptions} />
              </InfoWindow>
            }
          </Marker>
        );
      })
    }
  </MarkerClusterer>
));

const GoogleMap = ({ height, locations, setExpandedContainerOptions }: Object) => (
  <Map
    defaultZoom={3}
    trafficLayer={true}
    height={R.or(height, '100vh')}
    options={{
      zoomControlOptions: { position: 5 },
      streetViewControlOptions: { position: 5 },
    }}
  >
    {
      G.isNotNilAndNotEmpty(locations) &&
      <OrderMarkerWithInfo locations={locations} setExpandedContainerOptions={setExpandedContainerOptions} />
    }
  </Map>
);

export default GoogleMap;
