import React from 'react';
// components
import EditableBox from '../../../components/editable-box';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const EditableBoxComponent = () => {
  const description = '<EditableBox /> component enables users to edit and display text in a box.';

  const usage = (
    <ul>
      <li>Set value from editable box to store via `<i>onBlurCallback</i>` callback.</li>
      <li>The parameter `<i>value</i>` sets the initial value displayed in the editable box.</li>
      <li>The parameter `<i>wrapperStyles</i>` defines the styling for the wrapper around the editable box.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='EditableBox'
      description={description}
      path='src/components/editable-box'
    >
      <EditableBox
        wrapperStyles={{ p: '5px', height: 80, width: 240 }}
        value='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      />
    </ComponentStoryWrapper>
  );
};

export default EditableBoxComponent;
