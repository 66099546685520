import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, lifecycle, withState, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// features
import PC from '../../permission/role-permission';
import { setDataToListItemByPropName as tripCustomStatusCallback } from '../../dispatch-board-new/load/actions';
import { setDataToListItemByPropName as orderCustomStatusCallback } from '../../dispatch-board-new/order/actions';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withConnectModalAndLoaderActions } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const fieldSettings = [
  {
    type: 'reactSelect',
    label: ['titles:status', 'Status'],
    options: 'loadCustomStatusOptions',
    inputWrapperStyles: { width: 250 },
    fieldName: GC.FIELD_CUSTOM_STATUS_GUID,
  },
];

const enhance = compose(
  withState('loadCustomStatusOptions', 'setLoadCustomStatusOptions', R.pathOr([], ['loadCustomStatuses'])),
  withHandlers({
    handleGetLoadCustomStatusOptions: (props: Object) => async () => {
      const { loadType, callback, branchGuid, openLoader, closeLoader, setLoadCustomStatusOptions } = props;

      G.callFunction(openLoader);

      const options = {
        params: { loadType, [GC.BRANCH_GUID]: branchGuid },
      };

      const res = await sendRequest('get', endpointsMap.loadCustomStatusListAvailable, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const options = R.map(
          ({ guid, name }: Object) => ({
            guid,
            name,
            [GC.FIELD_VALUE]: guid,
            [GC.FIELD_LABEL]: name,
          }),
          R.or(data, []),
        );

        G.callFunctionWithArgs(callback, options);

        setLoadCustomStatusOptions(options);
      } else {
        G.handleException('error', 'LoadCustomStatusForm exception');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { loadCustomStatusOptions, handleGetLoadCustomStatusOptions } = this.props;

      if (G.isNilOrEmpty(loadCustomStatusOptions)) handleGetLoadCustomStatusOptions();
    },
  }),
  withFormik({
    mapPropsToValues: ({ customStatusGuid }: Object) => ({ customStatusGuid }),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: Yup.object().shape({
      [GC.FIELD_CUSTOM_STATUS_GUID]: Yup.string().nullable(true).notRequired(),
    }),
  }),
  pure,
);

const LoadCustomStatusForm = enhance((props: Object) => {
  const { handleSubmit, loadCustomStatusOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fieldSettings}
        fieldsWrapperStyles={{ pt: 10 }}
        loadCustomStatusOptions={loadCustomStatusOptions}
      />
      <FormFooter2 boxStyles={{ pt: 15 }} />
    </form>
  );
});

const loadCustomStatusEnhance = compose(
  withHandlers({
    handleSetLoadCustomStatus: (props: Object) => () => {
      const {
        loadType,
        openModal,
        closeModal,
        branchGuid,
        openLoader,
        closeLoader,
        customStatus,
        setCustomStatus,
        loadCustomStatuses,
        getAvailableLoadCustomStatusListSuccess,
      } = props;

      const component = (
        <LoadCustomStatusForm
          loadType={loadType}
          closeModal={closeModal}
          branchGuid={branchGuid}
          openLoader={openLoader}
          closeLoader={closeLoader}
          submitAction={setCustomStatus}
          loadCustomStatuses={loadCustomStatuses}
          callback={getAvailableLoadCustomStatusListSuccess}
          customStatusGuid={R.pathOr(null, [GC.FIELD_GUID], customStatus)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:load-custom-status'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const loadCustomStatusEnhance2 = compose(
  connect(null, { tripCustomStatusCallback, orderCustomStatusCallback }),
  withConnectModalAndLoaderActions,
  withHandlers({
    handleUpdateLoadCustomStatus: (props: Object) => async ({ customStatusGuid }: Object) => {
      const {
        isClo,
        closeModal,
        openLoader,
        closeLoader,
        tripCustomStatusCallback,
        orderCustomStatusCallback,
      } = props;

      const loadData = G.getDataFromObject(props);

      const guid = G.getGuidFromObject(loadData);

      const endpoint = G.ifElse(
        isClo,
        endpointsMap.getCloChangeCustomStatus,
        endpointsMap.getTelChangeCustomStatus,
      )(guid);

      const callback = G.ifElse(isClo, orderCustomStatusCallback, tripCustomStatusCallback);

      G.callFunction(openLoader);

      const options = { params: { customStatusGuid } };

      const res = await sendRequest('put', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        G.callFunctionWithArgs(
          callback,
          { guid, data, propName: 'customStatus' },
        );
      } else {
        G.handleFailResponse(res);
      }

      G.callFunction(closeModal);
      G.callFunction(closeLoader);
    },
  }),
  withHandlers({
    handleSetLoadCustomStatus: (props: Object) => () => {
      const {
        data,
        isClo,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        loadCustomStatuses,
        handleUpdateLoadCustomStatus,
      } = props;

      const { customStatus } = data;

      const loadType = G.ifElse(isClo, GC.LOAD_TYPE_CLO, GC.LOAD_TYPE_TEL);

      const branchGuid = R.pathOr(R.path(['branchGuid'], data), [GC.FIELD_BRANCH, GC.FIELD_GUID], data);

      const component = (
        <LoadCustomStatusForm
          loadType={loadType}
          closeModal={closeModal}
          branchGuid={branchGuid}
          openLoader={openLoader}
          closeLoader={closeLoader}
          loadCustomStatuses={loadCustomStatuses}
          submitAction={handleUpdateLoadCustomStatus}
          customStatusGuid={R.pathOr(null, [GC.FIELD_GUID], customStatus)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:load-custom-status'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const LoadCustomStatus = ({ customStatus, handleSetLoadCustomStatus }: Object) => (
  <Flex mt='8px' fontSize={12}>
    <Box
      fontWeight='bold'
      textTransform='uppercase'
      color={G.getTheme('colors.greyMatterhorn')}
    >
      {G.getWindowLocale('titles:custom-status', 'Custom Status')}
    </Box>
    {
      G.isNotNilAndNotEmpty(customStatus) &&
      <Flex
        ml={10}
        p='5px'
        minWidth='70px'
        fontWeight='bold'
        border='1px solid'
        borderRadius='16px'
        justifyContent='center'
        color={G.getLoadCustomStatusColor(customStatus)}
        borderColor={G.getLoadCustomStatusColor(customStatus)}
      >
        {G.getPropFromObject(GC.FIELD_NAME, customStatus)}
      </Flex>
    }
    {
      G.hasAmousCurrentUserPermissions(PC.TEL_WRITE) &&
      <Box ml='10px' cursor='pointer' onClick={handleSetLoadCustomStatus}>
        {I.pencil(G.getTheme('colors.light.blue'))}
      </Box>
    }
  </Flex>
);

export default loadCustomStatusEnhance(LoadCustomStatus);

export {
  loadCustomStatusEnhance2,
};
