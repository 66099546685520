import * as GC from '../../constants';
//////////////////////////////////////////////////

export const CHART_CONFIGS_FORM = 'CHART_CONFIGS_FORM';

// DATA TYPE CONSTANTS
export const TEL_COUNT = 'TEL_COUNT';
export const CLO_COUNT = 'CLO_COUNT';
export const TEL_MARGIN = 'TEL_MARGIN';
export const CLO_MARGIN = 'CLO_MARGIN';
export const TEL_SPEND = 'TEL_TOTAL_SPEND';
export const CLO_INCOME = 'CLO_TOTAL_INCOME';
export const EVENT_ON_TIME_LATE = 'EVENT_ON_TIME_LATE';

// CHART TYPE CONSTANTS
export const PIE = 'PIE';
export const BAR = 'BAR';
export const LINE = 'LINE';
export const PIE3D = 'PIE3D';
export const BAR3D = 'BAR3D';
export const TOTAL = 'TOTAL';

// GROUP TYPE CONSTANTS
export const DRIVER = 'DRIVER';
export const STATUS = 'STATUS';
export const NO_GROUP = 'NO_GROUP';
export const BRANCH = GC.BRANCH_UPPERCASE;
export const FIRST_DATE_DAY = 'FIRST_DATE_DAY';

export const dataTypeLocaleMap = {
  [TEL_COUNT]: 'titles:tel-count',
  [CLO_COUNT]: 'titles:clo-count',
  [TEL_MARGIN]: 'titles:tel-margin',
  [CLO_MARGIN]: 'titles:clo-margin',
  [TEL_SPEND]: 'titles:tel-total-spend',
  [CLO_INCOME]: 'titles:clo-total-income',
  [EVENT_ON_TIME_LATE]: 'titles:event-on-time-late',
};

export const chartTypeLocaleMap = {
  [PIE]: 'titles:pie',
  [BAR]: 'titles:bar',
  [LINE]: 'titles:line',
  [TOTAL]: 'titles:total',
  [BAR3D]: 'titles:bar-3d',
  [PIE3D]: 'titles:pie-3d',
};

export const groupTypeLocaleMap = {
  [DRIVER]: 'titles:driver',
  [BRANCH]: 'titles:branch',
  [STATUS]: 'titles:status',
  [NO_GROUP]: 'titles:no-group',
  [FIRST_DATE_DAY]: 'titles:date-day',
};


export const optionsLocaleMap = {
  ...dataTypeLocaleMap,
  ...GC.statusLocaleMap,
  ...groupTypeLocaleMap,
  ...chartTypeLocaleMap,
};

// CHART DATA TYPES
export const CHART_DATA_TYPE_TEL_COUNT = 'TEL_COUNT';
export const CHART_DATA_TYPE_CLO_COUNT = 'CLO_COUNT';
export const CHART_DATA_TYPE_TEL_MARGIN = 'TEL_MARGIN';
export const CHART_DATA_TYPE_CLO_MARGIN = 'CLO_MARGIN';
export const CHART_DATA_TYPE_TEL_SPEND = 'TEL_TOTAL_SPEND';
export const CHART_DATA_TYPE_CLO_INCOME = 'CLO_TOTAL_INCOME';
export const CHART_DATA_TYPE_EVENT_ON_TIME_LATE = 'EVENT_ON_TIME_LATE';
// CHART TYPES
export const CHART_TYPE_PIE = 'PIE';
export const CHART_TYPE_BAR = 'BAR';
export const CHART_TYPE_LINE = 'LINE';
export const CHART_TYPE_PIE3D = 'PIE3D';
export const CHART_TYPE_BAR3D = 'BAR3D';
export const CHART_TYPE_TOTAL = 'TOTAL';
// CHART GROUP TYPES
export const CHART_GROUP_TYPE_DRIVER = 'DRIVER';
export const CHART_GROUP_TYPE_STATUS = 'STATUS';
export const CHART_GROUP_TYPE_NO_GROUP = 'NO_GROUP';
export const CHART_GROUP_TYPE_BRANCH = GC.BRANCH_UPPERCASE;
export const CHART_GROUP_TYPE_FIRST_DATE_DAY = 'FIRST_DATE_DAY';

export const CHART_DRIVER_LATE = 'DRIVER_LATE';
export const CHART_DRIVER_ON_TIME = 'DRIVER_ON_TIME';

export const AXIS_X_TITLES = {
  [CHART_DRIVER_LATE]: 'titles:driver-late',
  [CHART_GROUP_TYPE_BRANCH]: 'titles:branch',
  [CHART_GROUP_TYPE_DRIVER]: 'titles:driver',
  [CHART_GROUP_TYPE_STATUS]: 'titles:status',
  [CHART_DRIVER_ON_TIME]: 'titles:driver-on-time',
  [CHART_DATA_TYPE_TEL_COUNT]: 'titles:tel-count',
  [CHART_DATA_TYPE_CLO_COUNT]: 'titles:clo-count',
  [CHART_DATA_TYPE_TEL_MARGIN]: 'titles:tel-margin',
  [CHART_DATA_TYPE_CLO_MARGIN]: 'titles:clo-margin',
  [CHART_GROUP_TYPE_FIRST_DATE_DAY]: 'titles:date-day',
  [CHART_DATA_TYPE_TEL_SPEND]: 'titles:tel-total-spend',
  [CHART_DATA_TYPE_CLO_INCOME]: 'titles:clo-total-income',
};
