import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withWindowSize, withIsOpenedModalStatus } from '../../../hocs';
// ui
import { Flex, RelativeBox } from '../../../ui';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// report-common
import { generateDefaultReport } from '../../../report-common';
// features
import { loadColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
import { loadFilterProps, loadFilterPropsForTitleFilter } from '../../dispatch-report/settings/filter-params';
// feature dispatch-planner
import CustomTitle from './custom-title';
import { pageSizes } from '../settings/page-settings';
import { telTableSettings } from '../settings/table-settings';
import CrossDockLocationSelect from './cross-dock-location-select';
import {
  setTelReports,
  addTelToPlanner,
  toggleTelDetails,
  setOutboundTerminals,
  removeTelFromPlanner,
  getOutboundTelNextPage,
  updateTelReportRequest,
  setGlobalFilterValueOT,
  resetTelListAndPagination,
  setOutboundTableTitleSort,
  setOutboundTableTitleFilter,
  setCurrentOutboundTelReport,
  resetOutboundTelListAndPagination,
} from '../actions';
import {
  makeSelectOutboundTelList,
  makeSelectTelFilterParams,
  makeSelectOutboundTelTotal,
  makeSelectOutboundTelReport,
  makeSelectOutboundTerminals,
  makeSelectAvailableTelReports,
  makeSelectGlobalFilterValueOT,
  makeSelectOutboundTelPagination,
  makeSelectCrossDockLocationList,
  makeSelectOutboundTelListLoading,
  makeSelectOutboundTableTitleFilters,
  makeSelectOutboundTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withWindowSize,
  withIsOpenedModalStatus,
  withHandlers({
    handleSetGlobalFilter: (props: Object) => (value: string) => {
      if (R.equals(props.globalFilterValue, value)) return;
      props.resetOutboundTelListAndPagination();
      props.setGlobalFilterValueOT(value);
      props.getOutboundTelNextPage(true);
    },
    handleListRequest: ({ getOutboundTelNextPage }: Object) => (isInitial: boolean = false) =>
      getOutboundTelNextPage(isInitial),
    handleClickItem: (props: Object) => (guid: string, tel: Object) => {
      if (G.isTrue(tel.selected)) return props.removeTelFromPlanner(guid);
      props.addTelToPlanner(guid);
    },
    handleToggleDetails: (props: Object) => (tel: Object) => (
      props.toggleTelDetails(R.assoc('isOutbound', true, tel))
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        getOutboundTelNextPage,
        updateTelReportRequest,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.TEL_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          hideSaveAsNew={true}
          setReport={setReport}
          requestPending={requestPending}
          updateReportRequest={updateTelReportRequest}
          onReportSet={() => getOutboundTelNextPage(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), props.reportList);
      props.setReport(selectedReport);
      props.getOutboundTelNextPage(true);
    },
    handleSetLocations: (props: Object) => (locations: Array) => {
      props.setOutboundTerminals(locations);
      props.resetOutboundTelListAndPagination();
      props.getOutboundTelNextPage(true);
      props.closeModal();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    selectedReport,
    titleSortValues,
    handleClickItem,
    tableTitleFilters,
    handleToggleDetails,
    getOutboundTelNextPage,
    handleTableTitleFilter } = props;
  const allChecked = G.isAllChecked(itemList);
  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    report: selectedReport,
    handleTableTitleFilter,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleClickItem,
    tableSettings: R.mergeRight(telTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(loadFilterPropsForTitleFilter)),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getOutboundTelNextPage,
    ),
  };

  return <Table {...data} />;
};

const OutboundLoads = enhance((props: Object) => {
  const {
    locations,
    openModal,
    closeModal,
    totalCount,
    setTelReports,
    selectedReport,
    globalFilterValue,
    handleSetLocations,
    crossDockLocations,
    handleSetGlobalFilter,
    updateTelReportRequest,
  } = props;

  return (
    <RelativeBox minWidth={700}>
      <RelativeBox pr={15} zIndex={2}>
        <TitlePanel
          {...props}
          pt='0px'
          version={2}
          loading={false} // HACK: some UI error block title panel - remove after fix
          name='tel-report'
          type='outbound-tel'
          noExportable={true}
          hideFilterInfo={true}
          useExactFilters={true}
          withoutQuickFilter={true}
          setReports={setTelReports}
          usedReport={selectedReport}
          filterProps={loadFilterProps}
          hiddenRightFilterInfo={false}
          height={pageSizes.tableHeaderHeight}
          updateReportRequest={updateTelReportRequest}
          customTitleComponent={
            <Flex>
              <CustomTitle
                count={totalCount}
                globalFilterValue={globalFilterValue}
                handleSetGlobalFilter={handleSetGlobalFilter}
                text={G.getWindowLocale('titles:outbound-tels', 'Outbound TELs')}
              />
              <CrossDockLocationSelect
                openModal={openModal}
                locations={locations}
                closeModal={closeModal}
                setLocations={handleSetLocations}
                crossDockLocations={crossDockLocations}
                locationOptions={G.getLocationOptions(R.values(crossDockLocations))}
              />
            </Flex>
          }
        />
      </RelativeBox>
      <RelativeBox zIndex={1}>
        {renderTable(props)}
      </RelativeBox>
    </RelativeBox>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectOutboundTelList(state),
  totalCount: makeSelectOutboundTelTotal(state),
  locations: makeSelectOutboundTerminals(state),
  filterParams: makeSelectTelFilterParams(state),
  loading: makeSelectOutboundTelListLoading(state),
  reportList: makeSelectAvailableTelReports(state),
  pagination: makeSelectOutboundTelPagination(state),
  selectedReport: makeSelectOutboundTelReport(state),
  globalFilterValue: makeSelectGlobalFilterValueOT(state),
  crossDockLocations: makeSelectCrossDockLocationList(state),
  tableTitleFilters: makeSelectOutboundTableTitleFilters(state),
  titleSortValues: makeSelectOutboundTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setTelReports,
  addTelToPlanner,
  toggleTelDetails,
  setOutboundTerminals,
  removeTelFromPlanner,
  getOutboundTelNextPage,
  updateTelReportRequest,
  setGlobalFilterValueOT,
  resetTelListAndPagination,
  resetOutboundTelListAndPagination,
  setReport: setCurrentOutboundTelReport,
  getItemListRequest: getOutboundTelNextPage,
  setUsedReport: setCurrentOutboundTelReport,
  setTableTitleSort: setOutboundTableTitleSort,
  setTableTitleFilter: setOutboundTableTitleFilter,
  changeDefaultReportRequest: setCurrentOutboundTelReport,
  resetListAndPagination: resetOutboundTelListAndPagination,
})(OutboundLoads);
