import * as R from 'ramda';
import React from 'react';
import { withState } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import { TextComponent } from '../../../components/text';
// ui
import { Box, Flex, FlexHovered, AbsoluteBox } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet
import * as C from '../constants';
import WorkingDivision from './working-division';
import VendorAssignment from '../vendor/components/assign-vendor';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');

const withShowAllTrailers = withState('showAllTrailers', 'setShowAllTrailers', false);

const CompileNameComponent = ({ type, guid, entity, hasAction }: Object) => (
  <Flex mx='10px'>
    <Box width='max-content'>{type}:</Box>
    <TextComponent
      pl='5px'
      maxWidth={250}
      display='block'
      cursor='pointer'
      withEllipsis={true}
      color={G.ifElse(hasAction, blueColor)}
      onClick={() => {
        if (hasAction) G.goToRoute(routesMap[G.getFleetProfileRoutePathNameByFleetType()](guid));
      }}
    >
      {`${R.prop(GC.FIELD_FLEET_FIRST_NAME, entity)} ${R.prop(GC.FIELD_FLEET_LAST_NAME, entity)}`}
    </TextComponent>
  </Flex>
);

const MultiValuesComponent = ({ type, values, hasAction, showAllTrailers, setShowAllTrailers }: Object) => (
  <Flex mx={10}>
    {type}:
    <TextComponent
      pl='5px'
      maxWidth={200}
      display='block'
      cursor='pointer'
      withEllipsis={true}
      color={G.ifElse(hasAction, blueColor)}
      onMouseEnter={() => setShowAllTrailers(true)}
      onMouseLeave={() => setShowAllTrailers(false)}
    >
      {
        values.map((value: Object, i: number) => (
          <span
            key={i}
            onClick={() => {
              if (hasAction) G.goToRoute(routesMap.editTrailerPage(value.guid));
            }}
          >
            {R.prop(GC.FIELD_TRUCK_UNIT_ID, value)}
            {R.and(R.gt(R.length(values), R.add(i, 1)), ', ')}
          </span>
        ))
      }
      {
        R.and(showAllTrailers, R.gt(R.length(values), 1))
        && (
          <AbsoluteBox
            p='0px'
            top={46}
            bg='white'
            minWidth={150}
            border='1px solid'
            borderRadius='3px'
            height='max-content'
            flexDirection='column'
            onMouseLeave={() => setShowAllTrailers(false)}
          >
            {
              values.map((data: Object, i: number) => (
                <FlexHovered
                  p='5px'
                  key={i}
                  width='100%'
                  cursor='pointer'
                  transition='all 0.5s'
                  bgColor={G.getTheme('colors.light.lightGrey')}
                  onClick={() => {
                    if (hasAction) G.goToRoute(routesMap.editTrailerPage(data.guid));
                  }}
                >
                  {R.prop(GC.FIELD_TRUCK_UNIT_ID, data)}
                </FlexHovered>
              ))
            }
          </AbsoluteBox>
        )
      }
    </TextComponent>
  </Flex>
);

const getAvailableTrailersFromAssignInfo = (props: Object) => R.compose(
  R.values(),
  R.dissoc(R.path(['initialValues', GC.FIELD_GUID], props)),
  R.indexBy(R.prop(GC.FIELD_GUID)),
  R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRAILERS]),
)(props);

const AssignInfoComponent = (props: Object) => {
  const {
    type,
    objGuid,
    version,
    trailer,
    openModal,
    closeModal,
    branchGuid,
    showAllTrailers,
    setShowAllTrailers,
    assignedToDivision,
    expandedContainerOpened,
    changeAssignedToDivisionRequest,
  } = props;

  const hasAction = G.isFalse(expandedContainerOpened);
  const truckGuid = R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK, GC.FIELD_GUID], props);

  return (
    <Flex flexWrap='wrap'>
      {
        G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO], props))
        && (
          <CompileNameComponent
            hasAction={hasAction}
            entity={R.path([C.FLEET_ASSIGN_INFO], props)}
            guid={R.path([C.FLEET_ASSIGN_INFO, GC.FIELD_GUID], props)}
            type={G.getWindowLocale('titles:primary-driver', 'Primary Driver')}
          />
        )
      }
      {
        G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO, 'teamDriver'], props))
        && (
          <CompileNameComponent
            hasAction={hasAction}
            type={G.getWindowLocale('titles:team-driver', 'Team Driver')}
            entity={R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER], props)}
            guid={R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TEAM_DRIVER, GC.FIELD_GUID], props)}
          />
        )
      }
      {
        R.and(G.isNotNilAndNotEmpty(truckGuid), trailer)
        && (
          <Flex mx={10}>
            {G.getWindowLocale('titles:truck', 'Truck')}:
            <TextComponent
              pl='5px'
              maxWidth={250}
              display='block'
              cursor='pointer'
              withEllipsis={true}
              color={G.ifElse(hasAction, blueColor)}
              onClick={() => {
                if (hasAction) G.goToRoute(routesMap.editTruckPage(truckGuid));
              }}
            >
              {R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRUCK, GC.FIELD_TRUCK_UNIT_ID], props)}
            </TextComponent>
          </Flex>
        )
      }
      {
        R.and(G.isNotNilAndNotEmpty(R.path([C.FLEET_ASSIGN_INFO, C.FLEET_TRAILERS], props)), R.not(trailer))
        && (
          <MultiValuesComponent
            hasAction={hasAction}
            showAllTrailers={showAllTrailers}
            setShowAllTrailers={setShowAllTrailers}
            values={getAvailableTrailersFromAssignInfo(props)}
            type={G.getWindowLocale('titles:trailers', 'Trailers')}
          />
        )
      }
      <VendorAssignment
        type={type}
        objGuid={objGuid}
        version={version}
        branchGuid={branchGuid}
        hasAction={G.isFalse(expandedContainerOpened)}
      />
      <WorkingDivision
        guid={objGuid}
        openModal={openModal}
        hasAction={hasAction}
        isPrimaryDriver={false}
        closeModal={closeModal}
        branchGuid={branchGuid}
        assignedToDivision={assignedToDivision}
        changeAssignedToDivisionRequest={changeAssignedToDivisionRequest}
      />
    </Flex>
  );
};

export default withShowAllTrailers(AssignInfoComponent);
