import React from 'react';
// components
import { LoadStatusActions } from '../../../components/load-status-actions';
// constants
import * as GC from '../../../constants';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const mockData = {
  [GC.FIELD_LOAD_STATUS]: [GC.LOAD_STATUS_IN_TRANSIT],
  [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW]: [
    [GC.ADD_DOCUMENT_ACTION],
    [GC.CREATE_CUSTOMER_INVOICE_ACTION],
  ],
  [GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW]: [
    [GC.DUPLICATE_ORDER_ACTION],
    [GC.OPEN_IN_ROUTE_BUILDER_ACTION],
  ],
};

const LoadStatusActionsComponent = () => {
  const description = '<LoadStatusActions /> component dynamically renders load status actions.';

  const usage = (
    <ul>
      <li>Parameter `<i>loadType</i>` is a type of load status.</li>
      <li>Parameter `<i>data</i>` is the data used to render the load status actions.</li>
      <li>Parameter `<i>withoutActions</i>` specifies if the actions should be or not.</li>
      <li>Parameter `<i>fromDetails</i>` indicates whether the actions are displayed from details.</li>
      <li>Set callback function `<i>handleActionClick</i>` to trigger action after clicking on the load status action.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='LoadStatusActions'
      description={description}
      path='src/components/load-status-actions'
    >
      <LoadStatusActions
        data={mockData}
        fromDetails={true}
        loadType={GC.LOAD_TYPE_CLO}
        handleActionClick={(actionName: string) => window.alert(`Action Name: ${actionName}`)}
      />
    </ComponentStoryWrapper>
  );
};

export default LoadStatusActionsComponent;
