import * as R from 'ramda';
import { withFormik } from 'formik';
import React, { Fragment } from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { ChargeFormFooter } from '../../../../components/form-footer';
// forms
import { Fieldset } from '../../../new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, StickedBox } from '../../../../ui';
// feature fleet
import {
  defaultServiceVendorFields,
  serviceVendorFieldSettings,
  serviceVendorValidationSchema,
  defaultServiceVendorPayToLocationFields,
  serviceVendorPayToLocationFieldSettings,
  serviceVendorPrimaryLocationFieldSettings,
  defaultServiceVendorPrimaryLocationFields,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const makeLocationFields = (fields: Array, prefix: string) => R.map((item: Object) => R.assoc(
  'fieldName',
  `${prefix}.${item.fieldName}`,
  item,
), fields);

const darkBlueColor = G.getTheme('colors.dark.blue');

const SectionHeader = ({ title, expanded, children, setExpanded }: Object) => {
  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Fragment>
      <Flex
        p='5px'
        my={10}
        fontWeight='bold'
        color={darkBlueColor}
        justifyContent='center'
        alignItems='flex-start'
        textTransform='uppercase'
        bg={G.getTheme('colors.whiteGrey')}
      >
        <Box mr={15} ml='auto'>{title}</Box>
        <Box
          px='6px'
          ml='auto'
          cursor='pointer'
          onClick={() => setExpanded(R.not)}
        >
          {I[toggleIconName](darkBlueColor)}
        </Box>
      </Flex>
      {G.isTrue(expanded) && children}
    </Fragment>
  );
};

const enhance = compose(
  withState('payToLocationExpanded', 'setPayToLocationExpanded', false),
  withState('generalSectionExpanded', 'setGeneralSectionExpanded', true),
  withState('primaryLocationExpanded', 'setPrimaryLocationExpanded', false),
  withFormik({
    enableReinitialize: true,
    validationSchema: serviceVendorValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultServiceVendorFields,
      initialValues,
    ),
  }),
  withHandlers({
    handleToggleLocationSectionByLocationType: (props: Object) => (locationType: string) => {
      const {
        values,
        touched,
        setTouched,
        setFieldValue,
        payToLocationExpanded,
        primaryLocationExpanded,
        setPayToLocationExpanded,
        setPrimaryLocationExpanded,
      } = props;

      const isPayToLocation = R.equals(locationType, GC.SYSTEM_OBJECT_PAY_TO_LOCATION);
      const toggleValue = G.ifElse(isPayToLocation, payToLocationExpanded, primaryLocationExpanded);

      const handleToggle = () => {
        if (isPayToLocation) return setPayToLocationExpanded(R.not);

        setPrimaryLocationExpanded(R.not);
      };

      const defaultFields = G.ifElse(
        isPayToLocation,
        defaultServiceVendorPayToLocationFields,
        defaultServiceVendorPrimaryLocationFields,
      );

      handleToggle();

      const location = R.prop(locationType, values);

      if (R.and(G.isFalse(toggleValue), G.isNilOrEmpty(location))) {
        setFieldValue(locationType, defaultFields);

        if (R.propEq(true, locationType, touched)) {
          const locationFieldsTouched = R.map(() => true, defaultFields);

          setTouched(R.assoc(locationType, locationFieldsTouched, touched));
        }
      }

      if (R.and(G.isTrue(toggleValue), G.isAllNilOrEmpty(R.values(location)))) {
        setFieldValue(locationType, null);
      }
    },
    handleSetLocationValuesByLocationType: (props: Object) => (values: Object, locationType: string) => {
      const { setFieldValue } = props;

      const location = R.mergeRight(
        R.pathOr({}, ['values', locationType], props),
        R.assoc(GC.FIELD_ADDRESS, R.prop(GC.FIELD_ADDRESS_1, values), values),
      );

      setFieldValue(locationType, location);
    },
  }),
  pure,
);

const ServiceVendorForm = (props: Object) => {
  const {
    closeModal,
    handleSubmit,
    payToLocationExpanded,
    generalSectionExpanded,
    primaryLocationExpanded,
    setGeneralSectionExpanded,
    serviceVendorServiceTypeOptions,
    handleSetLocationValuesByLocationType,
    handleToggleLocationSectionByLocationType,
  } = props;

  const allExpanded = R.all(
    (item: boolean) => item,
    [generalSectionExpanded, payToLocationExpanded, primaryLocationExpanded],
  );

  return (
    <Box
      width={840}
      maxHeight='87vh'
      overflowY={G.ifElse(allExpanded, 'auto', 'unset')}
    >
      <form onSubmit={handleSubmit}>
        <SectionHeader
          expanded={generalSectionExpanded}
          setExpanded={setGeneralSectionExpanded}
          title={G.getWindowLocale('titles:general-details', 'General Details')}
        >
          <Fieldset
            {...G.getFormikProps(props)}
            fields={serviceVendorFieldSettings}
            serviceVendorServiceTypeOptions={serviceVendorServiceTypeOptions}
            fieldsWrapperStyles={{ px: 15, my: 25, justifyContent: 'space-between' }}
          />
        </SectionHeader>
        <SectionHeader
          expanded={payToLocationExpanded}
          title={G.getWindowLocale('titles:pay-to-location', 'Pay To Location')}
          setExpanded={() => handleToggleLocationSectionByLocationType(GC.SYSTEM_OBJECT_PAY_TO_LOCATION)}
        >
          <Fieldset
            {...G.getFormikProps(props)}
            fieldsWrapperStyles={{ px: 15, my: 25, justifyContent: 'space-between' }}
            fields={makeLocationFields(serviceVendorPayToLocationFieldSettings, GC.SYSTEM_OBJECT_PAY_TO_LOCATION)}
            customSelectLocationFunction={(location: Object) =>
              handleSetLocationValuesByLocationType(location, GC.SYSTEM_OBJECT_PAY_TO_LOCATION)
            }
          />
        </SectionHeader>
        <SectionHeader
          expanded={primaryLocationExpanded}
          title={G.getWindowLocale('titles:primary-location', 'Primary Location')}
          setExpanded={() => handleToggleLocationSectionByLocationType(GC.SYSTEM_OBJECT_PRIMARY_LOCATION)}
        >
          <Fieldset
            {...G.getFormikProps(props)}
            fieldsWrapperStyles={{ px: 15, my: 25, justifyContent: 'space-between' }}
            fields={makeLocationFields(serviceVendorPrimaryLocationFieldSettings, GC.SYSTEM_OBJECT_PRIMARY_LOCATION)}
            customSelectLocationFunction={(location: Object) =>
              handleSetLocationValuesByLocationType(location, GC.SYSTEM_OBJECT_PRIMARY_LOCATION)
            }
          />
        </SectionHeader>
        <StickedBox bottom='0px' zIndex={G.ifElse(allExpanded, 1001, 12)}>
          <ChargeFormFooter closeModal={closeModal} />
        </StickedBox>
      </form>
    </Box>
  );
};

export default enhance(ServiceVendorForm);
