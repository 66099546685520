import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import CustomerRateForm, { EditCustomerRateForm } from '../../../rate/customer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch-details-new
import * as H from '../../helpers';
import {
  removeOrderCustomerRateRequest,
  updateOrderCustomerRateRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddCustomerRate = compose(
  withHandlers({
    handleAddCustomerRate: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        itemsVolume,
        orderTotalWeight,
        orderTotalDistance,
        createOrderCustomerRateRequest,
        loadConfigs: { configsByNames, configGroups },
      } = props;

      const cloGuid = G.getGuidFromObject(load);

      const equipment = R.path([GC.FIELD_LOAD_EQUIPMENT], load);
      const services = R.pathOr([], [GC.FIELD_LOAD_SERVICES], load);
      const stopCount = R.pathOr(0, [GC.FIELD_STOPS, 'length'], load);

      const equipments = G.ifElse(G.isNilOrEmpty(equipment), [], R.of(Array, equipment));

      const defaultCurrency = G.getConfigValueFromStore(
        GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
        R.path([GC.GENERAL_CONFIG_GROUP], configGroups),
      );

      const totalPickupQuantity = G.makeTotalPickupQuantityObjectFromItems(R.prop(GC.FIELD_LOAD_ITEMS, load));

      const component = (
        <CustomerRateForm
          cloGuid={cloGuid}
          stopCount={stopCount}
          closeModal={closeModal}
          stopsQuantity={stopCount}
          itemsVolume={itemsVolume}
          totalWeight={orderTotalWeight}
          defaultCurrency={defaultCurrency}
          totalDistance={orderTotalDistance}
          totalPickupQuantity={totalPickupQuantity}
          handleSendCloRate={createOrderCustomerRateRequest}
          branchGuid={R.pathOr('', [GC.FIELD_BRANCH_GUID], load)}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          services={G.mapArrayOfObjectsToPropArray(GC.FIELD_DROPDOWN_OPTION_GUID, services)}
          equipments={G.mapArrayOfObjectsToPropArray(GC.FIELD_DROPDOWN_OPTION_GUID, equipments)}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadCustomerRateTableGroupRowActions = compose(
  connect(
    null,
    {
      removeOrderCustomerRateRequest,
      updateOrderCustomerRateRequest,
    },
  ),
  withHandlers({
    handleEditCustomerRate: (props: Object) => (useSelectedCustomerRate: boolean = false) => {
      const {
        load,
        entity,
        openModal,
        orderTotalWeight,
        orderTotalDistance,
        selectedCustomerRate,
        updateOrderCustomerRateRequest,
        transportationModeGroupingList,
        loadConfigs: { configsByNames },
        getTransportationModeGroupingListSuccess,
      } = props;

      const initialValues = G.ifElse(
        useSelectedCustomerRate,
        selectedCustomerRate,
        entity,
      );

      const handleSendCloRate = (values: Object, loadData: Object) =>
        updateOrderCustomerRateRequest({ values, loadData });

      const component = (
        <EditCustomerRateForm
          load={load}
          initialValues={initialValues}
          totalWeight={orderTotalWeight}
          totalDistance={orderTotalDistance}
          cloGuid={G.getGuidFromObject(load)}
          handleSendCloRate={handleSendCloRate}
          branchGuid={R.path([GC.FIELD_BRANCH_GUID], load)}
          stopsQuantity={R.pathOr(0, [GC.FIELD_STOPS, 'length'], load)}
          transportationModeGroupingList={transportationModeGroupingList}
          defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
          getTransportationModeGroupingListSuccess={getTransportationModeGroupingListSuccess}
        />
      );

      const modal = G.getRateModalWithContent(component);

      openModal(modal);
    },
    handleRemoveCustomerRate: (props: Object) => () => {
      const {
        entity,
        openModal,
        removeOrderCustomerRateRequest,
      } = props;

      const textLocale = G.getWindowLocale('messages:delete-confirmation-rate', 'Do you want to delete this Rate?');

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeOrderCustomerRateRequest(G.getGuidFromObject(entity)),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
);
