import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectGeoFencingZoneStore = (state: Object) => state.ratingGeoFencingZone;

const makeSelectIndexedList = () => createSelector(
  selectGeoFencingZoneStore,
  ({ geofencingZoneList }: Object) => geofencingZoneList,
);

const makeSelectItemList = () => createSelector(
  selectGeoFencingZoneStore,
  ({ geofencingZoneList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(geofencingZoneList),
);

export {
  makeSelectItemList,
  makeSelectIndexedList,
};
