import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import { updateConfigsRequest, restoreInheritedByConfigTypeRequest } from '../../actions';
import {
  makeSelectConfigDropdowns,
  makeSelectConfigInitialValues,
  makeSelectConfigInheritedValues,
} from '../../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(),
  itemPageConfigEnhancer,
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    pageTitle='titles:trailer'
    groupSettings={GC.TRAILER_CONFIG_GROUP}
    configOptions={{
      documentTypes: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(props, GC.TRAILER_DOCUMENT_TYPE),
      outOfServiceReasons: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(props, GC.TRAILER_OUT_SERVICE_REASON),
      branchList: R.compose(
        R.map(({ guid: value, [GC.BRANCH_NAME]: label }: Object) => ({ value, label })),
        R.filter(({ guid, active, [GC.BRANCH_TYPE]: branchType }: Object) => G.ifElse(
          R.or(
            R.equals(GC.BRANCH_TYPE_ENUM_MASTER, branchType),
            R.equals(guid, G.getAmousCurrentBranchGuidFromWindow()),
          ),
          false,
          active,
        )),
        R.values,
      )(G.getItemFromWindow('amousBranches')),
    }}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.TRAILER_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.TRAILER_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.TRAILER_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
})(enhance(ConfigPageComponent));
