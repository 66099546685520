import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../../components/table';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
//////////////////////////////////////////////////

const tableSettings = {
  maxHeight: 300,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
};

const report = {
  fields: [
    { sequence: 0, name: GC.FIELD_LOG_DATE_TIME },
    { sequence: 1, name: GC.FIELD_END_LOG_DATE_TIME },
    { sequence: 2, name: GC.FIELD_DURATION_MINUTES },
    { sequence: 3, name: GC.FIELD_STATUS },
    { sequence: 4, name: 'remark' },
    { sequence: 5, name: 'shortBreak' },
    { sequence: 6, name: GC.FIELD_SOURCE },
    { sequence: 7, name: GC.FIELD_CREATED_DATE },
    { sequence: 8, name: GC.FIELD_CREATED_BY },
    { sequence: 9, name: GC.FIELD_LAST_MODIFIED_DATE },
    { sequence: 10, name: GC.FIELD_LAST_MODIFIED_BY },
  ],
};

const columnSettings = {
  [GC.FIELD_LOG_DATE_TIME]: {
    width: 150,
    name: 'titles:date',
    customComponent: ({ data }: Object) => {
      const date = G.getPropFromObject(GC.FIELD_LOG_DATE_TIME, data);

      if (G.isNilOrEmpty(date)) return '';

      return G.createLocalDateTimeString(date, GC.DEFAULT_DATE_TIME_FORMAT);
    },
  },
  [GC.FIELD_END_LOG_DATE_TIME]: {
    width: 150,
    name: 'titles:end-date',
    customComponent: ({ data }: Object) => {
      const date = G.getPropFromObject(GC.FIELD_END_LOG_DATE_TIME, data);

      if (G.isNilOrEmpty(date)) return '';

      return G.createLocalDateTimeString(date, GC.DEFAULT_DATE_TIME_FORMAT);
    },
  },
  [GC.FIELD_DURATION_MINUTES]: {
    width: 150,
    name: 'titles:duration',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_DURATION_MINUTES, data));

      return shortString;
    },
  },
  [GC.FIELD_STATUS]: {
    width: 150,
    name: 'titles:status',
    customComponent: R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_STATUS]),
    ),
  },
  remark: {
    width: 150,
    name: 'titles:remark',
  },
  shortBreak: {
    width: 150,
    type: 'boolean',
    name: 'titles:short-break',
  },
  [GC.FIELD_SOURCE]: {
    width: 150,
    name: 'titles:source',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 150,
    name: 'titles:created-on',
    customComponent: ({ data }: Object) => {
      const date = G.getPropFromObject(GC.FIELD_CREATED_DATE, data);

      if (G.isNilOrEmpty(date)) return '';

      return G.createLocalDateTimeString(date, GC.DEFAULT_DATE_TIME_FORMAT);
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 150,
    name: 'titles:update-on',
    customComponent: ({ data }: Object) => {
      const date = G.getPropFromObject(GC.FIELD_LAST_MODIFIED_DATE, data);

      if (G.isNilOrEmpty(date)) return '';

      return G.createLocalDateTimeString(date, GC.DEFAULT_DATE_TIME_FORMAT);
    },
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const DriverHosLogList = ({ modal, itemList }: Object) => (
  <Box ml={G.ifElse(G.isTrue(modal), '0px', 50)}>
    <Table
      loading={false}
      report={report}
      itemList={itemList}
      columnSettings={columnSettings}
      tableSettings={G.ifElse(G.isTrue(modal), R.assoc('maxHeight', 'unset', tableSettings), tableSettings)}
      rowBgFunction={(_: any, { shortBreak }: Object) =>
        G.getThemeByCond(G.isTrue(shortBreak), 'colors.light.criticalBackground', 'colors.white')
      }
    />
  </Box>
);

const enhance = compose(
  connect(null, { openLoader, closeLoader }),
  withState('itemList', 'setItemList', []),
  withHandlers({
    getItemListRequest: (props: Object) => async () => {
      const {
        driver,
        logDate,
        itemList,
        driverGuid,
        openLoader,
        closeLoader,
        setItemList,
      } = props;

      const driverBranchGuid = R.or(G.getBranchGuidFromObject(driver), G.getAmousCurrentBranchGuidFromWindow());

      openLoader();
      const options = {
        params: { [GC.FIELD_DRIVER_GUID]: R.or(G.getGuidFromObject(driver), driverGuid) },
        data: {
          fields: [],
          pageable: false,
          orderFields: [],
          [GC.CURRENT_BRANCH]: driverBranchGuid,
          searchCriteria: [{
            dataType: 'date',
            collection: false,
            dateRelative: false,
            to: `${logDate} 11:59 PM`,
            from: `${logDate} 12:00 AM`,
            propertyName: GC.FIELD_LOG_DATE,
          }],
        },
      };

      const res = await sendRequest('post', endpointsMap.driverHosLogList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setItemList(R.concat(data.results, itemList));
      } else {
        G.handleFailResponse(res);
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getItemListRequest();
    },
  }),
  pure,
);

export default enhance(DriverHosLogList);
