import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState } from 'react-recompose';
// components
import { Tabs } from '../../../../../components/tabs';
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withAsyncGetBranchListByType } from '../../../../../hocs';
// feature config
import { CUSTOMER_EDI_PICKUP_DROP_STOP_TYPES_MAP } from '../constants';
import { ediConfigsFields, defaultEdiConfigsFields, ediConfigsValidationSchema } from '../settings';
//////////////////////////////////////////////////

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: G.getWindowLocale('titles:order', 'Order') },
];

const filedKeysMap = [
  [
    GC.FIELD_CUSTOMER_GUID,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_REFERENCE,
    GC.FIELD_SEND_RESPONSE,
  ],
  [
    GC.FIELD_PICKUP_STOP_TYPES,
    GC.FIELD_DROP_STOP_TYPES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_DEFAULT_ITEM_ID,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_NUMBER,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PO_NUMBER_REFERENCE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_ORDER_IDENTIFICATION_REFERENCE,
    GC.FIELD_CUSTOM_MAPPER,
    GC.FIELD_AUTO_UPDATE_EVENT_DATES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_ITEM_TEMPLATES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_LOCATION_TEMPLATES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_OVERRIDE_CONFIGURED_BILL_TO,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_AUTO_ACCEPT,
    GC.FIELD_AUTO_CREATE_ORDER,
    GC.FIELD_USE_SHIPMENT_NUMBER_AS_PICKUP_NUMBER,
    GC.FIELD_GENERATE_PRO_NUMBER,
    GC.FIELD_PRO_NUMBER_REFERENCE,
    GC.FIELD_PRO_NUMBER_SEQUENCE,
    'instructionsField',
  ],
];

const getFieldSettingsByActiveTab = (activeTab: number) => R.compose(
  R.values,
  R.pick(R.propOr([], activeTab, filedKeysMap)),
  R.indexBy(R.prop('fieldName')),
)(ediConfigsFields);

const enhance = compose(
  withState('activeTab', 'setActiveTab', 0),
  withAsyncGetBranchListByType(GC.BRANCH_TYPE_ENUM_CUSTOMER),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultEdiConfigsFields,
      props.initialValues,
    ),
    validationSchema: ediConfigsValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const getAvailableStopTypeOptions = (stopTypes: Array) => R.compose(
  R.values,
  R.mapObjIndexed((label: string, value: string) => ({ value, [GC.FIELD_LABEL]: `${label} (${value})` })),
  R.omit(stopTypes),
)(CUSTOMER_EDI_PICKUP_DROP_STOP_TYPES_MAP);


const EdiConfigsForm = (props: Object) => {
  const {
    values,
    handlers,
    activeTab,
    closeModal,
    handleSubmit,
    setActiveTab,
    optionsForSelect,
    branchListByType,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        wrapperStyles={{ mb: 15 }}
        handleClickTab={setActiveTab}
      />
      <FieldsetComponent
        {...G.getFormikProps(props)}
        handlers={handlers}
        justifyContent='space-between'
        fields={getFieldSettingsByActiveTab(activeTab)}
        optionsForSelect={R.mergeRight(
          optionsForSelect,
          {
            branchListByType,
            availableDropStopTypes: getAvailableStopTypeOptions(R.pathOr([], [GC.FIELD_PICKUP_STOP_TYPES], values)),
            availablePickupStopTypes: getAvailableStopTypeOptions(R.pathOr([], [GC.FIELD_DROP_STOP_TYPES], values)),
          },
        )}
      />
      <FormFooter boxStyles={{ py: '10px' }} closeModal={closeModal} />
    </form>
  );
};

export default enhance(EdiConfigsForm);
