import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Switcher } from '../../../components/switcher';
// feature components-story
import { setSwitchValue } from '../actions';
import { makeSelectSwitchValue } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const options = [
  { value: 0, width: 80, name: 'option 1' },
  { value: 1, width: 80, name: 'option 2' },
  { value: 2, width: 80, name: 'option 3' },
];

const SwitcherComponent = ({ switchValue, setSwitchValue }: Object) => {
  const description = '<Switcher /> component is designed to render a switcher interface that allows users to select options.';

  const usage = (
    <ul>
      <li>Parameter `<i>version</i>` is a version of the switcher design.</li>
      <li>Parameter `<i>fontSize</i>` sets the font size for switcher buttons.</li>
      <li>Parameter `<i>textColor</i>` sets the text color for switcher buttons.</li>
      <li>Parameter `<i>value</i>` sets the value of the currently selected option.</li>
      <li>Parameter `<i>onSwitch</i>` is a callback triggered when an option is selected.</li>
      <li>Parameter `<i>selectedOptionIndex</i>`  is an index of the initially selected option.</li>
      <li>Parameter `<i>options</i>` is an array of objects representing the available switcher options.</li>
      <li>Parameter `<i>mb</i>`, `<i>mr</i>`, `<i>ml</i>` are the margin values for the switcher component.</li>
      <li>Parameter `<i>itemZIndex</i>`, `<i>switchElementZIndex</i>` are Z-index values for different elements.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='Switcher'
      description={description}
      path='src/components/switcher'
    >
      <Switcher
        version={3}
        options={options}
        onSwitch={setSwitchValue}
        selectedOptionIndex={switchValue}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  switchValue: makeSelectSwitchValue(state),
});

export default connect(mapStateToProps, {
  setSwitchValue,
})(SwitcherComponent);
