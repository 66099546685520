import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// forms
import { Label, FormGroup, renderFormGroup } from '../../forms';
// icons
import * as I from '../../svgs';
// ui
import { Box, ReactSelect } from '../../ui';
// feature fleet
import { Option } from './ui';
//////////////////////////////////////////////////

const renderOptionComponent = (props: Object, input: Object) => (
  <Option
    onClick={() => {
      props.selectOption(props.data);
      input.onChange(props.data.value);
    }}
  >
    {I[props.children]()}
  </Option>
);

const renderValueComponent = (props: Object) => (
  <div css={css`grid-area: 1/1/2/3;`}>
    {I[props.data.label]()}
  </div>
);

export const renderCustomSelect = ({
  width,
  label,
  input,
  options,
  display,
  direction,
  fieldClass,
  labelMargin,
  additionClass,
  labelFontSize,
  labelPosition,
}: Object) => (
  <FormGroup
    additionClass={additionClass}
    display={R.or(display, 'flex')}
    direction={R.or(direction, 'column')}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    <Box m='5px 10px' width={width}>
      <ReactSelect
        options={options}
        isClearable={false}
        value={input.value}
        components={{
          SingleValue: renderValueComponent,
          Option: (props: Object) => renderOptionComponent(props, input),
        }}
        additionalStyles={{
          menu: (baseStyles: Object) => ({
            ...baseStyles,
            zIndex: 15,
          }),
          control: (baseStyles: Object) => ({
            ...baseStyles,
            minHeight: 30,
            borderRadius: 2,
            height: 'max-content',
            borderColor: G.getTheme('colors.light.grey'),
          }),
        }}
      />
    </Box>
  </FormGroup>
);


export const setRenderComponent = (type: string) => {
  if (R.equals(type, 'customSelect')) {
    return renderCustomSelect;
  }

  return renderFormGroup(type);
};
