import React from 'react';
// components
import { ActionsElement } from '../../../components/actions-element';
// helpers
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ActionsElementComponent = () => {
  const description = '<ActionsElement /> component is for creating an interactive list of options that can be used for performing various actions';

  const usage = (
    <ul>
      <li>The parameter `<i>version</i>` changes the theme version.</li>
      <li>Set `<i>optionLineHeight</i>` for the text line height of the option.</li>
      <li>Set `<i>optionPadding</i>` for internal padding for each option in the list.</li>
      <li>The parametr `<i>options</i>` takes an array of objects, where each object represents an individual option to be displayed in the list.</li>
    </ul>
  );

  const getOptions = () => {
    const iconColor = G.getTheme('colors.dark.blue');

    return [
      {
        text: 'First action element',
        frontIcon: I.driver(iconColor, 17, 18),
        action: () => window.alert('First action'),
      },
      {
        text: 'Second action element',
        action: () => window.alert('Second action'),
        frontIcon: I.tel(null, null, null, iconColor),
      },
      {
        text: 'Thirth action element',
        action: () => window.alert('Thirth action'),
        frontIcon: I.createTruckIcon(iconColor, 18, 18),
      },
    ];
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ActionsElement'
      description={description}
      path='src/components/actions-element'
    >
      <ActionsElement
        version={2}
        optionPadding='0px'
        optionLineHeight={1.2}
        options={getOptions()}
      />
    </ComponentStoryWrapper>
  );
};

export default ActionsElementComponent;
