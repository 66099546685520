import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('carrierStatisticReport');

const makeSelectCarrierGuid = () => createSelector(
  store,
  ({ carrierGuid }: Object) => carrierGuid,
);

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectCarrierGuid,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
};
