import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const validationSchema = Yup.object().shape({
  [GC.FIELD_OPERATION_HOUR]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_DAYS]: G.yupArrayRequired,
    [GC.FIELD_TIME_TO]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    [GC.FIELD_TIME_FROM]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  })),
});

export const defaultBusinessHoursFields = () => ({
  [GC.FIELD_DAYS]: null,
  [GC.FIELD_TIME_TO]: null,
  [GC.FIELD_TIME_FROM]: null,
  [GC.FIELD_ID]: G.genShortId(),
});

export const businessHoursFieldSettings = (index: number) => [
  {
    width: 250,
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: GC.FIELD_DAYS,
    label: G.getWindowLocale('titles:days', 'Days'),
    inputWrapperStyles: { mr: 20, zIndex: R.subtract(100, index) },
    fieldName: `${GC.FIELD_OPERATION_HOUR}.${index}.${GC.FIELD_DAYS}`,
  },
  {
    width: 160,
    isRequired: true,
    type: 'timeInput',
    inputWrapperStyles: { mr: 20 },
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:from', 'From'),
    fieldName: `${GC.FIELD_OPERATION_HOUR}.${index}.${GC.FIELD_TIME_FROM}`,
  },
  {
    width: 160,
    isRequired: true,
    type: 'timeInput',
    label: G.getWindowLocale('titles:to', 'To'),
    placeholder: GC.DEFAULT_TIME_PM_PLACEHOLDER,
    fieldName: `${GC.FIELD_OPERATION_HOUR}.${index}.${GC.FIELD_TIME_TO}`,
  },
];
