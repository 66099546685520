import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature commission-assignment
import * as A from './actions';
//////////////////////////////////////////////////

function* getCommissionAssignmentListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.commissionAssigneeList,
      successAction: A.getCommissionAssignmentListSuccess,
      parentSagaName: 'getCommissionAssignmentListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCommissionAssignmentListRequest exception');
  }
}

function* createOrUpdateCommissionAssignmentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(R.prop(GC.FIELD_VERSION, payload)),
      'put',
      'post',
    );
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      options,
      method,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.commissionAssignee,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateCommissionAssignmentSuccess,
      parentSagaName: 'createOrUpdateCommissionAssignmentRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateCommissionAssignmentRequest exception');
  }
}

function* removeCommissionAssignmentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: { [GC.FIELD_GUID]: payload },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.commissionAssignee,
      successAction: A.removeCommissionAssignmentSuccess,
      parentSagaName: 'removeCommissionAssignmentRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCommissionAssignmentRequest exception');
  }
}

function* handleVisitSharedAccessorialListSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_COMMISSION_ASSIGNMENT_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getCommissionAssignmentListRequest);
    yield put(closeLoader());
    break;
  }
}

function* commissionAssignmentWatcherSaga() {
  yield takeLatest(A.removeCommissionAssignmentRequest, removeCommissionAssignmentRequest);
  yield takeLatest(GC.VISIT_COMMISSION_ASSIGNMENT_PAGE, handleVisitSharedAccessorialListSaga);
  yield takeLatest(A.createOrUpdateCommissionAssignmentRequest, createOrUpdateCommissionAssignmentRequest);
}

export default commissionAssignmentWatcherSaga;
