import * as R from 'ramda';
import React from 'react';
// components
import { Switcher } from '../../../components/switcher';
import { QuickFilterOutsideControl } from '../../../components/quick-filter';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  Text,
  SimpleWrapper,
  PageTitleCount,
  PageTitleWrapper } from '../../../ui';
//////////////////////////////////////////////////

const CustomTitle = (props: Object) => {
  const {
    text,
    count,
    withMultiSwitch,
    multiSwitchProps,
    globalFilterValue,
    handleSetGlobalFilter } = props;
  const searchInputColor = G.getTheme('colors.dark.grey');
  return (
    <SimpleWrapper mt={10} height={30}>
      <PageTitleWrapper
        m='0px'
        p='0 8px'
        height='100%'
        width='max-content'
      >
        <PageTitleCount
          br='10px'
          t='-10px'
          p='0 5px'
          r='unset'
          height={20}
          width='auto'
          fontSize={11}
          l='calc(100% - 10px)'
        >
          {R.or(count, 0)}
        </PageTitleCount>
        <Flex
          flexDirection='column'
          justifyContent='center'
        >
          <Text fontSize={14} textTransform='uppercase'>
            {text}
          </Text>
        </Flex>
      </PageTitleWrapper>
      {
        withMultiSwitch &&
        <Switcher
          ml={20}
          version={2}
          options={multiSwitchProps.options}
          onSwitch={multiSwitchProps.onSwitch}
          selectedOptionIndex={multiSwitchProps.selectedOptionIndex} />
      }
      <QuickFilterOutsideControl
        withoutLabel={true}
        outsideValue={globalFilterValue}
        handleSetFilter={handleSetGlobalFilter}
        endLabel={<Box mr='8px'>{I.search(searchInputColor)}</Box>}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        inputProps={{ p: '0 0 0 8px', height: '28px', withoutBorder: true }}
        boxStyles={{
          mb: '8px',
          border: '1px solid',
          borderRadius: '2px',
          borderColor: searchInputColor,
          ml: G.ifElse(withMultiSwitch, 16, 25),
          bg: G.getTheme('colors.light.mainLight'),
        }}
      />
    </SimpleWrapper>
  );
};

export default CustomTitle;
