import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature carrier-edi
import * as A from './actions';
// report-common
import { getReportReducers } from '../../report-common';
//////////////////////////////////////////////////

const initial = {
  filterProps: [],
  globalFilterValue: '',
  pagination: {
    limit: 15,
    offset: 0,
  },
};

const {
  selectItem,
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  getItemListSuccess,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
} = getReportReducers(initial);

const setFilterProps = (state: Object, data: Array) => P.$set('filterProps', data, state);

const setUsedReportOnly = (state: Object, data: Object) => (
  P.$all(
    P.$set('usedReport', data),
    P.$set('globalFilterValue', ''),
    state,
  )
);

const updateCarrierInvoiceSuccess = (state: Object, { data, entity }: Object) => {
  const updatedKeys = R.compose(
    R.filter(R.includes(GC.SYSTEM_OBJECT_INVOICE)),
    R.map(R.split('.')),
    R.keys,
  )(entity);

  const updatedValues = R.reduce((acc: Object, item: string) => {
    const key = R.join('.', item);
    const value = G.getPropFromObject([R.last(item)], data);

    return R.assoc(key, value, acc);
  }, {}, updatedKeys);

  return P.$merge(`itemList.${G.getGuidFromObject(entity)}`, updatedValues, state);
};

export default createReducer({
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setFilterProps]: setFilterProps,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setUsedReportOnly]: setUsedReportOnly,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [A.updateCarrierInvoiceSuccess]: updateCarrierInvoiceSuccess,
}, initialState);
