import React, { useState } from 'react';
// components
import { QuickFilterOutsideControl2 } from '../../../components/quick-filter';
// helpers
import * as G from '../../../helpers';
// ui
import { Text } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const QuickFilterOutsideControl2Component = () => {
  const [searchValue, setSearchValue] = useState('');

  const description = '<QuickFilterOutsideControl2 /> component manages an input field used for filtering content.';

  const usage = (
    <ul>
      <li>Parameter `<i>width</i>` is the width of the input field.</li>
      <li>Set `<i>handleSetFilter</i>` function for establish the filter value.</li>
      <li>Parameter `<i>label</i>` is the text displayed as the label for the input field.</li>
      <li>Parameter `<i>placeholder</i>` is the text displayed as a placeholder within the input field.</li>
      <li>Parameter `<i>endIconName</i>` is an icon JSX element displayed at the end of the input field.</li>
      <li>Parameter `<i>outsideValue</i>` is a value sourced externally, not controlled by this component.</li>
      <li>Parameter `<i>inputWrapperStyles</i>` is a styling properties applied to the input wrapper encompassing the field.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      description={description}
      name='QuickFilterOutsideControl2'
      path='src/components/quick-filter'
    >
      <QuickFilterOutsideControl2
        width={200}
        label='Filter by Name'
        handleSetFilter={setSearchValue}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
      />
      <Text mt={10}>{`Search value: ${searchValue}`}</Text>
    </ComponentStoryWrapper>
  );
};

export default QuickFilterOutsideControl2Component;
