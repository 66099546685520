import * as R from 'ramda';
import * as Yup from 'yup';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  STATE_OPTIONS,
  COUNTRY_OPTIONS,
  DRIVER_TYPE_OPTIONS,
  LICENSE_CLASS_OPTIONS,
  DUTY_STATUS_CODE_OPTIONS,
  LICENSE_RESTRICTION_OPTIONS,
  DEFAULT_RATE_UNIT_GROUP_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
  DEFAULT_PAYROLL_COMPENSATION_RATE_TYPE_OPTIONS,
} from '../../../../helpers/options';
// feature fleet/driver
import * as LC from '../constants';
import { licenseValidation, documentValidation } from '../validation';
//////////////////////////////////////////////////

export const editDriverFields = {
  name: 'driverDetails',
  title: 'titles:general-details',
  fields: [
    {
      type: 'text',
      name: 'titles:first-name',
      nameForAttribute: 'firstName',
      validate: [G.required, G.curriedIsEmptyOrMinMax(1, 40)],
    },
    {
      type: 'text',
      name: 'titles:last-name',
      nameForAttribute: 'lastName',
      validate: [G.required, G.curriedIsEmptyOrMinMax(1, 40)],
    },
    {
      type: 'text',
      name: 'titles:middle-initial',
      validate: G.curriedIsEmptyOrMinMax(0, 1),
      nameForAttribute: GC.FIELD_MIDDLE_INITIAL,
    },
    {
      type: 'text',
      name: 'titles:nick-name',
      nameForAttribute: 'nickName',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'text',
      name: 'titles:driver-number',
      nameForAttribute: 'driverNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'text',
      disabled: true,
      name: 'titles:driver-id',
      nameForAttribute: 'loginId',
      validate: [G.required, G.curriedIsEmptyOrMinMax(1, 32)],
    },
    {
      type: 'select',
      options: 'usersGeneral',
      name: 'titles:dispatcher',
      nameForAttribute: 'assignedDispatcherGuid',
    },
    {
      type: 'select',
      name: 'titles:dispatching-group',
      options: 'dispatchingGroupOptions',
      nameForAttribute: 'dispatchingGroupGuid',
    },
    {
      type: 'text',
      name: 'titles:email',
      nameForAttribute: 'email',
      validate: [G.isEmail, G.curriedIsEmptyOrMinMax(1, 40)],
    },
    {
      type: 'phoneNumber',
      name: 'titles:primary-phone',
      nameForAttribute: 'primaryPhoneNumber',
      validate: [G.isPhoneNumber, G.curriedIsEmptyOrMinMax(1, 32)],
    },
    {
      type: 'phoneNumber',
      name: 'titles:secondary-phone',
      nameForAttribute: 'secondaryPhoneNumber',
      validate: [G.isPhoneNumber, G.curriedIsEmptyOrMinMax(1, 32)],
    },
    {
      type: 'select',
      name: 'titles:driver-type',
      nameForAttribute: 'driverType',
      options: G.addEmptyOptionToDropDown(R.values(DRIVER_TYPE_OPTIONS)),
    },
    {
      type: 'text',
      name: 'titles:employee-number',
      nameForAttribute: 'employeeNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'select',
      name: 'titles:gender',
      nameForAttribute: 'gender',
      options: [
        GC.EMPTY_OPTION_OBJECT,
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
      ],
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:birth-date',
      maxDate: G.getCurrentDate(),
      nameForAttribute: 'birthDate',
    },
    {
      type: 'toggle',
      name: 'titles:employed',
      nameForAttribute: 'employed',
      handler: 'handleEmployedStatus',
      info: 'messages:driver:info:employe',
    },
    {
      type: 'toggle',
      name: 'titles:show-vendor-payroll',
      nameForAttribute: GC.FIELD_SHOW_VENDOR_PAYROLL,
    },
    {
      type: 'select',
      validate: G.required,
      name: 'titles:status-reason',
      options: 'unemploymentReason',
      nameForAttribute: 'unemploymentReason',
      showField: (params: Object) => R.not(params.employed),
    },
    {
      isClearable: true,
      type: 'datePicker',
      handler: 'handleHireDate',
      name: 'titles:current-hire-date',
      nameForAttribute: 'currentHireDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:previous-hire-date',
      nameForAttribute: 'previousHireDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:original-hire-date',
      nameForAttribute: 'originalHireDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:termination-date',
      handler: 'handleTerminationDate',
      nameForAttribute: 'terminationDate',
    },
    {
      type: 'select',
      options: 'domicileTerminal',
      name: 'titles:domicile-terminal',
      nameForAttribute: 'domicileTerminal',
    },
    {
      type: 'select',
      options: 'assignedDivision',
      name: 'titles:assigned-division',
      nameForAttribute: 'assignedDivision',
    },
    {
      type: 'toggle',
      name: 'titles:team-driver',
      nameForAttribute: 'teamDriver',
      info: 'messages:driver:info:team-driver',
    },
    {
      type: 'text',
      name: 'titles:paid-hours',
      nameForAttribute: 'payedHours',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 24)],
    },
    {
      type: 'toggle',
      name: 'titles:auto-accept-rate',
      nameForAttribute: 'autoAcceptRate',
    },
    {
      type: 'toggle',
      name: 'titles:registration-form-2290',
      nameForAttribute: 'registrationForm2290',
    },
    {
      type: 'textarea',
      name: 'titles:comments',
      nameForAttribute: GC.FIELD_COMMENTS,
    },
  ],
};

export const accountingFields = {
  name: 'accounting',
  title: 'titles:accounting',
  permissions: [PC.FLEET_DRIVER_ACCOUNTING_READ, PC.FLEET_DRIVER_ACCOUNTING_WRITE],
  fields: [
    {
      type: 'text',
      name: 'titles:bank-account-number',
      nameForAttribute: GC.FIELD_BANK_ACCOUNT_NUMBER,
      disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_DRIVER_ACCOUNTING_WRITE),
    },
    {
      type: 'text',
      name: 'titles:bank-routing-number',
      nameForAttribute: GC.FIELD_BANK_ROUTING_NUMBER,
      disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_DRIVER_ACCOUNTING_WRITE),
    },
  ],
};

export const editDriverHoursFields = {
  name: 'driverHours',
  title: 'titles:driver-hours',
  fields: [
    {
      type: 'text',
      name: 'titles:time-in-current-status',
      nameForAttribute: 'timeInCurrentStatus',
      validate: [G.curriedIsEmptyOrMinMaxNumber(0, 200)],
    },
    {
      type: 'text',
      name: 'titles:time-until-break',
      nameForAttribute: 'timeUntilBreak',
      validate: [G.curriedIsEmptyOrMinMaxNumber(0, 100)],
    },
    {
      type: 'text',
      name: 'titles:shift-drive-remaining',
      nameForAttribute: 'shiftDriveRemaining',
      validate: [G.curriedIsEmptyOrMinMaxNumber(0, 100)],
    },
    {
      type: 'text',
      name: 'titles:shift-remaining',
      nameForAttribute: 'shiftRemaining',
      validate: [G.curriedIsEmptyOrMinMaxNumber(0, 100)],
    },
    {
      type: 'text',
      name: 'titles:cycle-remaining',
      nameForAttribute: 'cycleRemaining',
      validate: [G.curriedIsEmptyOrMinMaxNumber(0, 100)],
    },
    {
      type: 'text',
      name: 'titles:cycle-tomorrow',
      nameForAttribute: 'cycleTomorrow',
      validate: [G.curriedIsEmptyOrMinMaxNumber(0, 100)],
    },
    {
      type: 'select',
      name: 'titles:duty-status-code',
      nameForAttribute: 'dutyStatusCode',
      options: DUTY_STATUS_CODE_OPTIONS,
    },
    {
      type: 'select',
      options: 'outOfDutyReason',
      name: 'titles:out-of-duty-reason',
      nameForAttribute: 'outOfDutyReason',
    },
  ],
};

export const addCertificationFields = {
  fields: [
    {
      type: 'select',
      name: 'titles:type',
      validate: G.required,
      options: 'certificationType',
      nameForAttribute: 'certificationType',
    },
    {
      type: 'select',
      name: 'titles:name',
      validate: G.required,
      options: 'certificationName',
      nameForAttribute: 'certificationName',
    },
    {
      align: 'baseline',
      isClearable: true,
      type: 'datePicker',
      validate: G.required,
      maxDateField: 'expirationDate',
      name: 'titles:certification-date',
      nameForAttribute: 'certificationDate',
    },
    {
      align: 'baseline',
      isClearable: true,
      type: 'datePicker',
      validate: G.required,
      name: 'titles:expiration-date',
      minDateField: 'certificationDate',
      nameForAttribute: 'expirationDate',
    },
    {
      type: 'textarea',
      name: 'titles:notes',
      nameForAttribute: 'notes',
      validate: [G.curriedIsEmptyOrMinMax(0, 500)],
    },
    {
      type: 'toggle',
      name: 'titles:oos-item',
      nameForAttribute: 'oosItem',
    },
    {
      type: 'file',
      width: '390px',
      nameForAttribute: GC.FIELD_FILE_NAME,
      placeholder: 'actions:attach-document',
    },
  ],
};

export const addDocumentFields = {
  fields: [
    {
      type: 'select',
      name: 'titles:type',
      validate: G.required,
      options: 'documentType',
      nameForAttribute: 'documentType',
    },
    {
      type: 'select',
      validate: G.required,
      name: 'titles:status',
      nameForAttribute: 'status',
      options: ['', 'CURRENT', 'HISTORICAL'],
    },
    {
      type: 'textarea',
      name: 'titles:description',
      nameForAttribute: 'description',
      validate: G.curriedIsEmptyOrMinMax(0, 2000),
    },
    {
      align: 'baseline',
      isClearable: true,
      type: 'datePicker',
      validate: G.required,
      name: 'titles:expiration-date',
      minDateField: 'certificationDate',
      nameForAttribute: 'expirationDate',
    },
    {
      align: 'baseline',
      isClearable: true,
      type: 'datePicker',
      minDateField: 'signedDate',
      name: 'titles:expired-date',
      nameForAttribute: 'expirationDate',
    },
    {
      type: 'text',
      name: 'titles:url',
      nameForAttribute: 'url',
      validate: documentValidation,
      disabled: (params: Object) => G.isNotNilAndNotEmpty(params.documentFilename),
    },
    {
      type: 'file',
      width: '390px',
      validate: documentValidation,
      placeholder: 'actions:upload',
      nameForAttribute: 'documentFilename',
      disabled: (params: Object) => G.isNotNilAndNotEmpty(params.url),
    },
  ],
};

export const licenseFields = {
  name: 'driverLicense',
  title: 'titles:license-details',
  fields: [
    {
      type: 'text',
      name: 'titles:license-number',
      nameForAttribute: 'licenseNumber',
      validate: [licenseValidation, G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      zIndex: 16,
      type: 'addressAutocomplete',
      name: 'titles:license-state',
      nameForAttribute: 'licenseState',
      validate: [licenseValidation, G.curriedIsEmptyOrMinMax(0, 100)],
    },
    {
      type: 'countrySelect',
      name: 'titles:license-country',
      nameForAttribute: 'licenseCountry',
      validate: [licenseValidation, G.curriedIsEmptyOrMinMax(0, 100)],
    },
    {
      type: 'select',
      validate: licenseValidation,
      name: 'titles:license-class',
      options: LICENSE_CLASS_OPTIONS,
      nameForAttribute: 'licenseClass',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:issue-date',
      validate: licenseValidation,
      nameForAttribute: 'issueDate',
      maxDateField: 'expirationDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      minDateField: 'issueDate',
      validate: licenseValidation,
      name: 'titles:expiration-date',
      nameForAttribute: 'expirationDate',
    },
    {
      type: 'select',
      validate: licenseValidation,
      name: 'titles:restrictions',
      nameForAttribute: 'restrictions',
      options: LICENSE_RESTRICTION_OPTIONS,
    },
    {
      zIndex: 11,
      type: 'multiselect',
      name: 'titles:endorsements',
      validate: licenseValidation,
      selectAction: 'handleChangeEndorsements',
      nameForAttribute: LC.FIELD_DRIVER_ENDORSEMENTS,
      options: [
        {
          value: GC.DRIVER_ENDORSEMENTS_TANK,
          label: G.getWindowLocale('titles:tank', 'Tank'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_OTHER,
          label: G.getWindowLocale('titles:other', 'Other'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_TOW_TRUCK,
          label: G.getWindowLocale('titles:tow-truck', 'Tow Truck'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_METAL_COIL,
          label: G.getWindowLocale('titles:metal-coil', 'Metal Coil'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_TANK_VEHICLES,
          label: G.getWindowLocale('titles:tank-vehicles', 'Tank Vehicles'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_X_ENDORSEMENT,
          label: G.getWindowLocale('titles:x-endorsement', 'X Endorsement'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_NO_ENDORSEMENT,
          label: G.getWindowLocale('titles:no-endorsement', 'No Endorsement'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_DOUBLES_TRIPLES,
          label: G.getWindowLocale('titles:doubles-triples', 'Doubles/Triples'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_PASSENGER_TRANSPORT,
          label: G.getWindowLocale('titles:passenger-transport', 'Passenger Transport'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_HAZARDOUS_MATERIALS,
          label: G.getWindowLocale('titles:hazardous-materials', 'Hazardous Materials'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_FARM_CLASS_A_VEHICLES,
          label: G.getWindowLocale('titles:farm-class-a-vehicles', 'Farm Class A Vehicles'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_FARM_CLASS_B_VEHICLES,
          label: G.getWindowLocale('titles:farm-class-b-vehicles', 'Farm Class B Vehicles'),
        },
        {
          value: GC.DRIVER_ENDORSEMENTS_RECREATIONAL_VEHICLES_GWR,
          label: `${G.getWindowLocale(
            'titles:recreational-vehicles-gvwr',
            'Recreational Vehicles GVWR over 26,000 lbs',
            )
          }.`,
        },
      ],
    },
  ],
};

export const citizenshipFields = {
  name: 'driverCitizenship',
  title: 'titles:citizenship-details',
  fields: [
    {
      type: 'text',
      name: 'titles:uscis-number',
      nameForAttribute: 'uscisNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'select',
      options: 'citizen',
      name: 'titles:citizen',
      nameForAttribute: 'citizen',
    },
    {
      type: 'text',
      name: 'titles:passport-number',
      nameForAttribute: 'passportNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'countrySelect',
      name: 'titles:passport-country',
      nameForAttribute: 'passportCountry',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:passport-expiration',
      nameForAttribute: 'passportExpiration',
    },
    {
      type: 'text',
      name: 'titles:visa-number',
      nameForAttribute: 'visaNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'countrySelect',
      name: 'titles:visa-country',
      nameForAttribute: 'visaCountry',
      validate: [G.curriedIsEmptyOrMinMax(0, 100)],
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:visa-expiration',
      nameForAttribute: 'visaExpiration',
    },
    {
      type: 'text',
      name: 'titles:green-card-number',
      nameForAttribute: 'greenCardNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:green-card-expiration',
      nameForAttribute: 'greenCardExpiration',
    },
    {
      type: 'text',
      name: 'titles:work-authorization-number',
      nameForAttribute: 'workAuthorizationNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      isClearable: true,
      type: 'datePicker',
      nameForAttribute: 'wanExpirationDate',
      name: 'titles:work-auth-expiration-date',
    },
  ],
};

export const validationSchemaRateDetailsObject = {
  [GC.FIELD_CHARGE_RATE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .typeError('${type}') // eslint-disable-line
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  [GC.FIELD_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_RATE_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_RATE_UNIT]: Yup.string()
    .nullable(true),
  [GC.FIELD_CHARGE_MIN_RATE]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  [GC.FIELD_CHARGE_MAX_RATE]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
};

export const validationSchemaDriverComponsationObject = {
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_COUNTRIES]: G.yupArrayRequired,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_COUNTRIES]: G.yupArrayRequired,
  [GC.FIELD_DRIVER_COMPENSATION_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToCharLocaleTxt(0, 85)),
  [GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_FROM]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_TO]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_FROM]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_TO]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  [GC.FIELD_MIN_WEIGHT]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  [GC.FIELD_MAX_WEIGHT]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  [GC.FIELD_TRANSIT_DAYS]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 99))
    .max(99, G.getShouldBeFromToCharLocaleTxt(0, 99)),
  [GC.FIELD_MODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  ...validationSchemaRateDetailsObject,
};

export const getDriverComponsationValidationSchemaObject = (values: Object) => {
  let schema = validationSchemaDriverComponsationObject;
  const distanceRangeFields = R.values(R.pick(
    [
      GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO,
      GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM,
    ],
    values,
  ));
  const weightRangeFields = R.values(R.pick(
    [
      GC.FIELD_MIN_WEIGHT,
      GC.FIELD_MAX_WEIGHT,
    ],
    values,
  ));
  if (R.any(G.isNotNilAndNotEmpty, distanceRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }
  if (R.any(G.isNotNilAndNotEmpty, weightRangeFields)) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_WEIGHT_UOM]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
      },
    );
  }
  return schema;
};

export const defaultRateFields = {
  [GC.FIELD_CURRENCY]: '',
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_CHARGE_MAX_RATE]: '',
  [GC.FIELD_CHARGE_MIN_RATE]: '',
  [GC.FIELD_CHARGE_RATE_TYPE]: '',
  [GC.FIELD_CHARGE_RATE_UNIT]: '',
};

export const defaultCompensationFields = {
  [GC.FIELD_MODE]: '',
  [GC.FIELD_TRANSIT_DAYS]: '',
  [GC.FIELD_GET_FUEL_FROM_ORDER]: false,
  [GC.FIELD_DRIVER_COMPENSATION_NAME]: '',
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITY]: null,
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITIES]: null,
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_STATES]: null,
  [GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE]: '',
  [GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE]: '',
  [GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER]: false,
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_CODES]: null,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITY]: null,
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_COUNTRIES]: null,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITIES]: null,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_STATES]: null,
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_TO]: null,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_CODES]: null,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_COUNTRIES]: null,
  [LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_FROM]: null,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_TO]: null,
  [LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_FROM]: null,
  ...defaultRateFields,
};

export const compensationCommonFieldStyles = {
  width: '220px',
  afterTop: '15px',
  errorTop: '110%',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
  inputsFlexDirection: 'column',
};

const compensationDatesFields = [
  {
    type: 'text',
    isRequired: true,
    loc: 'titles:name',
    ...compensationCommonFieldStyles,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_NAME,
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 10,
    width: '250px',
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    timeSelection: false,
    loc: 'titles:effective-date',
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 10,
    width: '250px',
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    timeSelection: false,
    minDate: G.getCurrentDate(),
    loc: 'titles:expiration-date',
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
];

const compensationOriginFields = [
  {
    shouldHandleEnter: true,
    loc: 'titles:search-city',
    type: 'addressAutocomplete',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITY,
  },
  {
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:origin-cities',
    options: 'originCityOptions',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_CITIES,
  },
  {
    type: 'multiselect',
    options: STATE_OPTIONS,
    loc: 'titles:origin-states',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_STATES,
  },
  {
    isRequired: true,
    type: 'multiselect',
    options: COUNTRY_OPTIONS,
    loc: 'titles:origin-countries',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_COUNTRIES,
  },
  {
    shouldHandleEnter: true,
    loc: 'titles:search-zip',
    type: 'addressAutocomplete',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP,
  },
  {
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:origin-zip-codes',
    options: 'originZipCodesOptions',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_CODES,
  },
  {
    type: 'text',
    loc: 'titles:zip-from',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_FROM,
  },
  {
    type: 'text',
    loc: 'titles:zip-to',
    ...compensationCommonFieldStyles,
    fieldName: LC.FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_TO,
  },
];

const compensationDestinationFields = [
  {
    ...compensationCommonFieldStyles,
    shouldHandleEnter: true,
    loc: 'titles:search-city',
    type: 'addressAutocomplete',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITY,
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:destination-cities',
    options: 'destinationCityOptions',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_CITIES,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'multiselect',
    options: STATE_OPTIONS,
    loc: 'titles:destination-states',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_STATES,
  },
  {
    ...compensationCommonFieldStyles,
    isRequired: true,
    type: 'multiselect',
    options: COUNTRY_OPTIONS,
    loc: 'titles:destination-countries',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_COUNTRIES,
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandleEnter: true,
    loc: 'titles:search-zip',
    type: 'addressAutocomplete',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP,
  },
  {
    ...compensationCommonFieldStyles,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:destination-zip-codes',
    options: 'destinationZipCodesOptions',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_CODES,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:zip-from',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_FROM,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:zip-to',
    fieldName: LC.FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_TO,
  },
];

export const compensationDetailsFields = [
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    zIndex: 'unset',
    isRequired: true,
    loc: 'titles:rate',
    fieldName: GC.FIELD_CHARGE_RATE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    zIndex: 'unset',
    isRequired: true,
    loc: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    zIndex: 'unset',
    isRequired: true,
    loc: 'titles:rate-type',
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    options: DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    shouldSetOptions: true,
    loc: 'titles:rate-unit',
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    optionsGroups: DEFAULT_RATE_UNIT_GROUP_OPTIONS,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:rate-minimum',
    fieldName: GC.FIELD_CHARGE_MIN_RATE,
    disabled: (field: any, values: Object) =>
      R.equals(R.prop(GC.FIELD_CHARGE_RATE_TYPE, values), GC.CHARGE_RATE_TYPE_FLAT),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:rate-maximum',
    fieldName: GC.FIELD_CHARGE_MAX_RATE,
    disabled: (field: any, values: Object) =>
      R.equals(R.prop(GC.FIELD_CHARGE_RATE_TYPE, values), GC.CHARGE_RATE_TYPE_FLAT),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:mode',
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    loc: 'titles:transit-days',
    fieldName: GC.FIELD_TRANSIT_DAYS,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    loc: 'titles:service-type',
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'toggle',
    width: '50px',
    flexDirection: 'row',
    alignItems: 'center',
    loc: 'titles:get-fuel-from-order',
    fieldName: GC.FIELD_GET_FUEL_FROM_ORDER,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'toggle',
    width: '50px',
    flexDirection: 'row',
    alignItems: 'center',
    loc: 'titles:add-additional-charges-from-order',
    fieldName: GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER,
  },
];

export const CompensationDatesSection = {
  title: 'titles:payment-date',
  fields: compensationDatesFields,
};

const CompensationOriginSection = {
  title: 'titles:origin-details',
  fields: compensationOriginFields,
};

const CompensationDestinationSection = {
  title: 'titles:destination-details',
  fields: compensationDestinationFields,
};

export const compensationLocationFields = [
  CompensationOriginSection,
  CompensationDestinationSection,
];

export const latestKnownLocationFields = {
  fields: [
    {
      name: 'titles:city',
      nameForAttribute: 'city',
      type: 'addressAutocomplete',
      validate: [G.required, G.curriedIsEmptyOrMinMax(1, 100)],
    },
    {
      type: 'text',
      name: 'titles:state',
      nameForAttribute: 'state',
      validate: [G.required, G.curriedIsEmptyOrMinMax(1, 100)],
    },
    {
      type: 'text',
      name: 'titles:zip',
      nameForAttribute: 'zip',
      validate: [G.curriedIsEmptyOrMinMax(1, 40)],
    },
    {
      type: 'countrySelect',
      name: 'titles:country',
      nameForAttribute: 'country',
      validate: [G.required, G.curriedIsEmptyOrMinMax(1, 100)],
    },
    {
      type: 'text',
      validate: G.required,
      name: 'titles:latitude',
      nameForAttribute: 'latitude',
    },
    {
      type: 'text',
      validate: G.required,
      name: 'titles:longitude',
      nameForAttribute: 'longitude',
    },
  ],
};

const showIntegrationIdInput = (integrationType: string, fieldName: string) => {
  let display = 'none';
  if (R.and(
    R.equals(fieldName, GC.FIELD_FLEET_EXTERNAL_ID),
    R.includes(integrationType, [GC.FLEET_INTEGRATION_TYPE_SAMSARA, GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN]),
  )) {
    display = 'flex';
  } else if (R.and(
    R.equals(fieldName, GC.FIELD_FLEET_FUEL_CARD_ID),
    R.includes(integrationType, [GC.FLEET_INTEGRATION_TYPE_EFS, GC.FUEL_CARDS_TYPE_QUIKQ]),
  )) {
    display = 'flex';
  }
  return display;
};

export const getValidationSchemaIntegrationFormObject = (integrationType: string) => {
  const requiredFieldValidation = Yup.string().required(G.getRequiredLocaleTxt()).nullable(true);
  let schema = {
    [GC.FIELD_TYPE]: requiredFieldValidation,
  };
  if (R.includes(integrationType, [GC.FLEET_INTEGRATION_TYPE_SAMSARA, GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN])) {
    schema = R.mergeRight(schema, { [GC.FIELD_FLEET_EXTERNAL_ID]: requiredFieldValidation });
  } else if (R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_EFS)) {
    schema = R.mergeRight(schema, { [GC.FIELD_FLEET_FUEL_CARD_ID]: requiredFieldValidation });
  }
  return schema;
};

export const defaultIntegrationFields = {
  [GC.FIELD_TYPE]: null,
  [GC.FIELD_FLEET_EXTERNAL_ID]: null,
  [GC.FIELD_FLEET_FUEL_CARD_ID]: null,
};

const defaultIntegrationFieldStyles = {
  width: '300px',
  errorTop: '110%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

export const integrationTypeOptions = [
  { value: '', label: '' },
  {
    value: GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
    label: G.getWindowLocale('titles:keep-truckin', 'Keep Truckin'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_SAMSARA,
    label: G.getWindowLocale('titles:samsara', 'Samsara'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_EFS,
    label: G.getWindowLocale('titles:efs', 'EFS'),
  },
  {
    value: GC.FUEL_CARDS_TYPE_QUIKQ,
    label: G.getWindowLocale('titles:quikq', 'QUIKQ'),
  },
  {
    value: GC.FUEL_CARDS_TYPE_SELF_FUELED,
    label: G.getWindowLocale('titles:self-fueled', 'Self Fueled'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_I_PASS,
    label: G.getWindowLocale('titles:i-pass', 'I-Pass'),
  },
];

export const integrationFields = [
  {
    ...defaultIntegrationFieldStyles,
    type: 'select',
    loc: 'titles:integration-type',
    options: GC.FIELD_FLEET_INTEGRATION_TYPE,
    fieldName: GC.FIELD_FLEET_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
  },
  {
    ...defaultIntegrationFieldStyles,
    type: 'text',
    loc: 'titles:external-id',
    fieldName: GC.FIELD_FLEET_EXTERNAL_ID,
    fieldGroupDisplay: (_: Object, values: Object) =>
      showIntegrationIdInput(R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE, values), GC.FIELD_FLEET_EXTERNAL_ID),
  },
  {
    ...defaultIntegrationFieldStyles,
    type: 'text',
    loc: 'titles:fuel-card-id',
    fieldName: GC.FIELD_FLEET_FUEL_CARD_ID,
    fieldGroupDisplay: (_: Object, values: Object) =>
      showIntegrationIdInput(R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE, values), GC.FIELD_FLEET_FUEL_CARD_ID),
  },
  {
    ...defaultIntegrationFieldStyles,
    type: 'text',
    loc: 'titles:transponder-id',
    fieldName: GC.FIELD_FLEET_TRANSPONDER_ID,
    fieldGroupDisplay: (_: Object, values: Object) => G.ifElse(
      R.pathEq(GC.FLEET_INTEGRATION_TYPE_I_PASS, [GC.FIELD_FLEET_INTEGRATION_TYPE], values),
      'flex',
      'none',
    ),
  },
];

export const payrollAssessorialFields = [
  {
    ...compensationCommonFieldStyles,
    zIndex: 10,
    isRequired: true,
    type: 'complexSelect',
    shouldCustomChange: true,
    loc: 'titles:accessorial',
    options: 'accessorialOptions',
    fieldName: GC.FIELD_DRIVER_ASSESSORIAL_GUID,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'toggle',
    width: '50px',
    disabled: true,
    flexDirection: 'row',
    alignItems: 'center',
    loc: 'titles:fuel-related',
    fieldName: GC.FIELD_FUEL_RELATED,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'toggle',
    width: '50px',
    disabled: true,
    flexDirection: 'row',
    alignItems: 'center',
    loc: 'titles:non-taxable',
    fieldName: GC.FIELD_NON_TAXABLE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'toggle',
    width: '50px',
    flexDirection: 'row',
    alignItems: 'center',
    loc: 'titles:deduction',
    fieldName: GC.FIELD_DEDUCTION,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    isRequired: true,
    loc: 'titles:rate',
    fieldName: GC.FIELD_CHARGE_RATE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:currency',
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    disabled: true,
    loc: 'titles:rate-type',
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
  },
  {
    ...compensationCommonFieldStyles,
    width: '680px',
    type: 'textarea',
    loc: 'titles:comments',
    fieldName: GC.FIELD_COMMENTS,
  },
];

export const defaultPayrollAssessorialFields = {
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_DEDUCTION]: false,
  [GC.FIELD_NON_TAXABLE]: null,
  [GC.FIELD_CHARGE_RATE]: null,
  [GC.FIELD_FUEL_RELATED]: null,
  [GC.FIELD_DRIVER_ASSESSORIAL_GUID]: null,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.FIELD_DRIVER_ACCESSORIAL_RATE_TYPE_FLAT,
};

export const payrollAssessorialValidationSchemaObject = {
  [GC.FIELD_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DRIVER_ASSESSORIAL_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_COMMENTS]: Yup.string()
    .nullable(true)
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
  [GC.FIELD_CHARGE_RATE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .typeError('${type}') // eslint-disable-line
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
};

export const deductionFieldSettings = [
  {
    ...compensationCommonFieldStyles,
    width: 140,
    type: 'toggle',
    loc: 'titles:include-fuel-charge',
    fieldName: GC.FIELD_INCLUDE_FUEL_CHARGE,
  },
  {
    ...compensationCommonFieldStyles,
    width: 140,
    type: 'toggle',
    loc: 'titles:include-toll-charge',
    fieldName: GC.FIELD_INCLUDE_TOLL_CHARGE,
  },
  {
    ...compensationCommonFieldStyles,
    width: 175,
    type: 'toggle',
    loc: 'titles:include-advance-payment',
    fieldName: GC.FIELD_INCLUDE_ADVANCE_PAYMENT,
  },
];

export const defaultDeductionFields = {
  [GC.FIELD_INCLUDE_FUEL_CHARGE]: false,
  [GC.FIELD_INCLUDE_TOLL_CHARGE]: false,
  [GC.FIELD_INCLUDE_ADVANCE_PAYMENT]: false,
};

export const driverHoursFieldSettings = [
  {
    width: 160,
    isRequired: true,
    isClearable: true,
    loc: 'titles:date',
    type: 'datePicker',
    timeSelection: false,
    maxDate: G.getCurrentDate(),
    fieldName: GC.FIELD_LOG_DATE,
  },
  {
    type: 'text',
    fieldWidth: 100,
    isRequired: true,
    placeholder: 'titles:hours',
    loc: 'titles:on-duty-hours',
    fieldName: GC.FIELD_ON_DUTY_HOURS,
  },
  {
    type: 'text',
    fieldWidth: 100,
    loc: 'titles:on-duty-mins',
    placeholder: 'titles:minutes',
    fieldName: GC.FIELD_ON_DUTY_MINUTES,
  },
  {
    type: 'text',
    fieldWidth: 100,
    placeholder: 'titles:hours',
    loc: 'titles:driving-hours',
    fieldName: GC.FIELD_DRIVING_HOURS,
  },
  {
    type: 'text',
    fieldWidth: 100,
    loc: 'titles:driving-mins',
    placeholder: 'titles:minutes',
    fieldName: GC.FIELD_DRIVING_MINUTES,
  },
];

export const getDriverHoursInitFields = () => ({
  id: G.genShortId(),
  [GC.FIELD_LOG_DATE]: null,
  [GC.FIELD_DRIVING_HOURS]: 0,
  [GC.FIELD_DRIVING_MINUTES]: 0,
  [GC.FIELD_ON_DUTY_MINUTES]: 0,
  [GC.FIELD_ON_DUTY_HOURS]: null,
});

export const validationSchemaDriverDailyHoursObject = {
  [LC.DRIVER_DAILY_HOURS]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_LOG_DATE]: G.yupStringRequired,
    [GC.FIELD_ON_DUTY_HOURS]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(24, G.getShouldBeLessOrEqualLocaleTxt(24))
      .typeError(G.getShouldBeIntegerLocaleTxt()),
    [GC.FIELD_ON_DUTY_MINUTES]: Yup.number()
      .nullable(true)
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(59, G.getShouldBeLessOrEqualLocaleTxt(59))
      .typeError(G.getShouldBeIntegerLocaleTxt()),
    [GC.FIELD_DRIVING_HOURS]: Yup.number()
      .nullable(true)
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(24, G.getShouldBeLessOrEqualLocaleTxt(24))
      .typeError(G.getShouldBeIntegerLocaleTxt()),
    [GC.FIELD_DRIVING_MINUTES]: Yup.number()
      .nullable(true)
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(59, G.getShouldBeLessOrEqualLocaleTxt(59))
      .typeError(G.getShouldBeIntegerLocaleTxt()),
  })),
};

// payroll compensation
const rateInputWrapperStyles = { mb: 25, width: 120 };
const payrollCompensationInputWrapperStyles1 = { width: 120 };
const payrollCompensationInputWrapperStyles2 = { width: 100 };

export const getPayrollRateInitFields = () => ({
  id: G.genShortId(),
  [GC.FIELD_RATE]: null,
  [GC.FIELD_VALUE_TO]: null,
  [GC.FIELD_VALUE_FROM]: null,
  [GC.FIELD_CHARGE_NAME]: null,
});

export const getPayrollCompensationRateInitFields = (defaultCurrency: string) => ({
  [GC.FIELD_WORKING_DAYS]: null,
  [GC.FIELD_CURRENCY]: defaultCurrency,
  [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_TIME,
  [GC.FIELD_RATE_UNIT]: GC.CHARGE_RATE_UNIT_TYPE_HOUR,
  [GC.FIELD_PAYROLL_VARIABLE_VALUES]: R.of(Array, getPayrollRateInitFields()),
});

export const defaultPayrollValues1 = {
  [GC.FIELD_RATE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_CHARGE_NAME]: null,
  [GC.FIELD_WORKING_DAYS]: null,
  [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_TIME,
  [GC.FIELD_RATE_UNIT]: GC.CHARGE_RATE_UNIT_TYPE_HOUR,
  [GC.FIELD_PAYROLL_VARIABLE_VALUES]: R.of(Array, getPayrollRateInitFields()),
};

export const defaultPayrollValues2 = {
  [GC.FIELD_RATE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_CHARGE_NAME]: null,
  [GC.FIELD_WORKING_DAYS]: null,
  [GC.FIELD_RATE_UNIT]: GC.UOM_MILE,
  [GC.FIELD_PAYROLL_VARIABLE_VALUES]: null,
  [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_DISTANCE,
};

export const validationSchemaPayrollCompensationObject = Yup.object().shape({
  [GC.FIELD_CURRENCY]: G.yupStringRequired,
  [GC.FIELD_RATE_UNIT]: G.yupStringRequired,
  [GC.FIELD_RATE_TYPE]: G.yupStringRequired,
  [GC.FIELD_WORKING_DAYS]: Yup.string().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_TIME),
      then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
  [GC.FIELD_RATE]: Yup.string().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_DISTANCE),
      then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
  [GC.FIELD_CHARGE_NAME]: Yup.string().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_DISTANCE),
      then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
  [GC.FIELD_PAYROLL_VARIABLE_VALUES]: Yup.array().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_TIME),
      then: (schema: Object) => schema.of(Yup.object().shape({
        [GC.FIELD_RATE]: G.yupStringRequired,
        [GC.FIELD_VALUE_FROM]: G.yupStringRequired,
        [GC.FIELD_VALUE_TO]: G.yupStringNotRequired,
        [GC.FIELD_CHARGE_NAME]: G.yupStringRequired,
      })),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
});

const customChangeHandler = (event: Object, field: Object, props: Object) => {
  const { values, setValues } = props;

  const { currency, driverGuid } = values;

  const value = G.getEventTargetValue(event);

  if (R.equals(value, GC.CHARGE_RATE_TYPE_DISTANCE)) {
    return setValues({
      ...defaultPayrollValues2,
      driverGuid,
      [GC.FIELD_RATE_TYPE]: value,
      [GC.FIELD_CURRENCY]: currency,
    });
  }

  return setValues({
    ...defaultPayrollValues1,
    driverGuid,
    [GC.FIELD_RATE_TYPE]: value,
    [GC.FIELD_CURRENCY]: currency,
  });
};

export const getPayrollCompensationFieldSettings1 = ({ version }: Object) => [
  {
    type: 'select',
    isRequired: true,
    customChangeHandler,
    shouldCustomChange: true,
    fieldName: GC.FIELD_RATE_TYPE,
    disabled: R.not(R.isNil(version)),
    label: ['titles:rate-type', 'Rate Type'],
    options: DEFAULT_PAYROLL_COMPENSATION_RATE_TYPE_OPTIONS,
    inputWrapperStyles: payrollCompensationInputWrapperStyles1,
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_RATE_UNIT,
    label: ['titles:rate-unit', 'Rate Unit'],
    inputWrapperStyles: payrollCompensationInputWrapperStyles1,
    options: R.of(Array, { label: 'Per Hour', value: GC.CHARGE_RATE_UNIT_TYPE_HOUR }),
  },
  {
    type: 'select',
    isRequired: true,
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
    inputWrapperStyles: { width: 210 },
    label: ['titles:currency', 'Currency'],
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_WORKING_DAYS,
    label: ['titles:working-days', 'Working Days'],
    inputWrapperStyles: payrollCompensationInputWrapperStyles1,
  },
];

export const getPayrollCompensationFieldSettings2 = ({ version }: Object) => [
  {
    type: 'select',
    isRequired: true,
    customChangeHandler,
    shouldCustomChange: true,
    fieldName: GC.FIELD_RATE_TYPE,
    disabled: R.not(R.isNil(version)),
    label: ['titles:rate-type', 'Rate Type'],
    options: DEFAULT_PAYROLL_COMPENSATION_RATE_TYPE_OPTIONS,
    inputWrapperStyles: payrollCompensationInputWrapperStyles2,
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_RATE_UNIT,
    label: ['titles:rate-unit', 'Rate Unit'],
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
    inputWrapperStyles: payrollCompensationInputWrapperStyles2,
  },
  {
    type: 'select',
    isRequired: true,
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
    inputWrapperStyles: { width: 150 },
    label: ['titles:currency', 'Currency'],
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CHARGE_NAME,
    label: ['titles:charge-name', 'Charge Name'],
    inputWrapperStyles: payrollCompensationInputWrapperStyles2,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_RATE,
    label: ['titles:rate', 'Rate'],
    inputWrapperStyles: payrollCompensationInputWrapperStyles2,
  },
];

export const payrollCompensationRateFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CHARGE_NAME,
    inputWrapperStyles: { mb: 25, width: 200 },
    label: ['titles:charge-name', 'Charge Name'],
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_RATE,
    label: ['titles:rate', 'Rate'],
    inputWrapperStyles: rateInputWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:from', 'From'],
    fieldName: GC.FIELD_VALUE_FROM,
    inputWrapperStyles: rateInputWrapperStyles,
  },
  {
    type: 'text',
    label: ['titles:to', 'To'],
    fieldName: GC.FIELD_VALUE_TO,
    inputWrapperStyles: rateInputWrapperStyles,
  },
];

// pay to location
export const payToLocationDefaultFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_ADDRESS_1]: null,
  [GC.FIELD_LOCATION_NAME]: null,
  [GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE]: null,
  [GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE]: null,
};

export const payLocationFieldSettings = [
  {
    ...compensationCommonFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:pay-to-name',
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    ...compensationCommonFieldStyles,
    width: 350,
    type: 'select',
    loc: 'titles:pay-type',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE,
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.PAYMENT_TERM_OPTION_FACTORING_COMPANY,
        label: G.getWindowLocale('titles:factoring-company', 'Factoring Company'),
      },
      {
        value: GC.PAYMENT_TERM_OPTION_DIRECT_PAY,
        label: G.getWindowLocale('titles:direct-pay', 'Direct Pay'),
      },
    ],
  },
  {
    ...compensationCommonFieldStyles,
    zI: 12,
    width: 350,
    loc: 'titles:address',
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
  },
  {
    ...compensationCommonFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:city',
    fieldName: GC.FIELD_CITY,
  },
  {
    ...compensationCommonFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:state',
    fieldName: GC.FIELD_STATE,
  },
  {
    ...compensationCommonFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:zip',
    fieldName: GC.FIELD_ZIP,
  },
  {
    ...compensationCommonFieldStyles,
    width: 350,
    type: 'countrySelect',
    loc: 'titles:country',
    fieldName: GC.FIELD_COUNTRY,
  },
  {
    ...compensationCommonFieldStyles,
    width: 350,
    zIndex: 13,
    type: 'datePicker',
    loc: 'titles:start-date',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE,
  },
];

export const payToLocationValidationSchema = {
  [GC.FIELD_ZIP]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CITY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_STATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_COUNTRY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_ADDRESS_1]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOCATION_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

// MonthlyPayrollDeductionForm
const monthlyPayrollDeductionCommonStyles = {
  ...compensationCommonFieldStyles,
  width: 250,
};

export const getMonthlyPayrollDeductionFirstFieldset = (
  { limited }: Object,
  editMode: boolean,
  deductionDisabled: boolean,
) => [
  {
    ...monthlyPayrollDeductionCommonStyles,
    isRequired: true,
    type: 'complexSelect',
    loc: 'titles:accessorial',
    options: 'accessorialOptions',
    fieldName: GC.FIELD_PAYROLL_ASSESSORIAL_GUID,
  },
  {
    ...monthlyPayrollDeductionCommonStyles,
    type: 'number',
    isRequired: true,
    loc: 'titles:amount',
    shouldCustomChange: true,
    fieldName: GC.FIELD_AMOUNT,
  },
  {
    ...compensationCommonFieldStyles,
    width: 250,
    type: 'number',
    isRequired: true,
    shouldCustomChange: true,
    loc: 'titles:total-amount',
    fieldName: GC.FIELD_TOTAL_AMOUNT,
    fieldGroupDisplay: G.ifElse(limited, 'flex', 'none'),
  },
  {
    ...monthlyPayrollDeductionCommonStyles,
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    timeSelection: false,
    withClickZIndex: true,
    loc: 'titles:date-from',
    shouldCustomChange: true,
    fieldName: GC.FIELD_DATE_FROM,
  },
  {
    ...monthlyPayrollDeductionCommonStyles,
    type: 'toggle',
    loc: 'titles:deduction',
    disabled: deductionDisabled,
    fieldName: GC.FIELD_DEDUCTION,
  },
  {
    ...monthlyPayrollDeductionCommonStyles,
    type: 'toggle',
    loc: 'titles:active',
    fieldName: GC.FIELD_ACTIVE,
  },
];

export const getMonthlyPayrollDeductionSecondFieldset = (values: Object, editMode: boolean = false) => {
  const { limited } = values;

  const secondFieldset = [
    {
      ...compensationCommonFieldStyles,
      width: 250,
      type: 'select',
      isRequired: true,
      disabled: editMode,
      loc: 'titles:currency',
      options: GC.CURRENCY_OPTIONS,
      fieldName: GC.FIELD_CURRENCY,
    },
    {
      ...monthlyPayrollDeductionCommonStyles,
      width: '100%',
      type: 'toggle',
      disabled: editMode,
      loc: 'titles:limited',
      fieldsGroupMargin: '11px',
      fieldName: GC.FIELD_LIMITED,
    },
    {
      ...compensationCommonFieldStyles,
      width: 250,
      type: 'number',
      shouldCustomChange: true,
      loc: 'titles:historical-paid-amount',
      fieldName: GC.FIELD_HISTORICAL_PAID_AMOUNT,
      fieldGroupDisplay: G.ifElse(limited, 'flex', 'none'),
    },
    {
      ...compensationCommonFieldStyles,
      width: 250,
      isRequired: true,
      isClearable: true,
      type: 'datePicker',
      timeSelection: false,
      loc: 'titles:date-to',
      withClickZIndex: true,
      shouldCustomChange: true,
      fieldName: GC.FIELD_DATE_TO,
      fieldGroupDisplay: G.ifElse(limited, 'flex', 'none'),
    },
  ];

  if (editMode) {
    return R.append(
      {
        ...monthlyPayrollDeductionCommonStyles,
        type: 'number',
        disabled: true,
        loc: 'titles:paid-amount',
        fieldName: GC.FIELD_PAID_AMOUNT,
      },
      secondFieldset,
    );
  }

  return secondFieldset;
};

export const commentsField = {
  ...compensationCommonFieldStyles,
  width: 520,
  type: 'textarea',
  loc: 'titles:comments',
  fieldName: GC.FIELD_COMMENTS,
};

export const defaultMonthlyPayrollDeductionFields = {
  [GC.FIELD_AMOUNT]: null,
  [GC.FIELD_DATE_TO]: null,
  [GC.FIELD_LIMITED]: true,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_DATE_FROM]: null,
  [GC.FIELD_DEDUCTION]: false,
  [GC.FIELD_PAID_AMOUNT]: null,
  [GC.FIELD_FULLY_PAID]: false,
  [GC.FIELD_TOTAL_AMOUNT]: null,
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: null,
  [GC.FIELD_PAYROLL_ASSESSORIAL_GUID]: null,
};

const monthlyPayrollDeductiinValidationSchemaObject = {
  [GC.FIELD_AMOUNT]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .typeError('${type}') // eslint-disable-line
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  [GC.FIELD_COMMENTS]: Yup.string().nullable(true).notRequired(),
  [GC.FIELD_DEDUCTION]: Yup.boolean().nullable(true).notRequired(),
  [GC.FIELD_PAID_AMOUNT]: Yup.number().nullable(true).notRequired(),
  [GC.FIELD_FULLY_PAID]: Yup.boolean().nullable(true).notRequired(),
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: Yup.number().nullable(true).notRequired(),
  [GC.FIELD_CURRENCY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DATE_FROM]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PAYROLL_ASSESSORIAL_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

export const getMonthlyPayrollDeductiinValidationSchemaObject = (values: Object) => {
  let schema = monthlyPayrollDeductiinValidationSchemaObject;

  if (G.isTrue(R.prop(GC.FIELD_LIMITED, values))) {
    schema = R.mergeRight(schema, {
      [GC.FIELD_DATE_TO]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      [GC.FIELD_TOTAL_AMOUNT]: Yup.number()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .typeError('${type}') // eslint-disable-line
        .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
        .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
    });
  }

  return schema;
};
// MonthlyPayrollDeductionForm
