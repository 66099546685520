import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const makeDropdownOptions = (dropdowns: Object) => R.compose(
  R.reduce((acc: Object, dropdownName: string) => R.assoc(
    dropdownName,
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid({ dropdowns }, dropdownName),
    acc,
  ), {}),
  R.keys,
)(dropdowns);

const setAddressPointValues = ({ entity, values, setValues }: Object) => {
  const filteredEntity = R.filter(G.isNotNilAndNotEmpty, entity);

  if (G.isNilOrEmpty(filteredEntity)) return;

  const { zip, label, city, state, country } = entity;

  let sectionValues = null;

  const pathArray = R.split('.', label);
  const sectionName = R.head(pathArray);
  const searchedFieldName = R.last(pathArray);
  const section = G.getPropFromObject(sectionName, values);

  if (R.equals(searchedFieldName, 'searchCity')) {
    const cities = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(city),
    )(section.cities);

    const states = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(state),
    )(section.states);

    const countries = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(country),
    )(section.countries);

    sectionValues = R.mergeRight(section, { cities, states, countries, searchCity: null });
  }

  if (R.equals(searchedFieldName, 'searchZip')) {
    const zipCodes = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(zip),
    )(section.zipCodes);
    sectionValues = R.mergeRight(section, { zipCodes, searchZip: null });
  }

  const newValues = R.assoc(sectionName, sectionValues, values);

  setValues(newValues);
};

export {
  makeDropdownOptions,
  setAddressPointValues,
};
