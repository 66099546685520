import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Label } from '../../../components/label';
import { FormButtons } from '../../../components/form-buttons';
import { FormGroupTable } from '../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Form, FieldsetComponent, FormGroupTitle, FormGroupTitleWrapper } from '../../../forms';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature carrier
import * as LC from '../constants';
import UpliftSection from './uplift-section';
import ContractBillTo from './contract-bill-to';
import { customIfElse, addAssessorialInfoToEntity } from '../helpers';
import { enhanceContractDiscount } from '../hocs/enhance-contract-discount';
import { carrierAccessorialsEnhance } from '../hocs/carrier-assessorial-enhance';
import {
  enhanceAwardedLine,
  withCarrierListForContract,
} from '../hocs';
import {
  accessorialsSettings,
  contractDiscountSettings,
  contractAwardedLineSettings,
} from '../settings/column-settings';
import {
  carrierEditCustomerContractSection,
  getUpdateCarrierContractValidationSchema,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const AccessorialTitlePanel = (props: Object) => {
  const whiteColor = G.getTheme('colors.white');
  const { handleClickAddAssessorial, handleAddSharedAccessorials } = props;

  return (
    <FormGroupTitleWrapper>
      <FormGroupTitle>
        <Label
          endIcon={I.plusRound(whiteColor)}
          endAction={handleClickAddAssessorial}
        >
          {G.getWindowLocale('titles:contract-accessorials', 'Contract Accessorials')}
        </Label>
        <Label
          ml={15}
          endIcon={I.plusRound(whiteColor)}
          endAction={handleAddSharedAccessorials}
        >
          {G.getWindowLocale('titles:shared-accessorials', 'Shared Accessorials')}
        </Label>
      </FormGroupTitle>
    </FormGroupTitleWrapper>
  );
};

export const CarrierAssessorials = carrierAccessorialsEnhance((props: Object) => (
  <FormGroupTable
    groupName={props.groupName}
    fields={props.collapsedGroup}
    panelTitle={props.panelTitle}
    entitiesFields={accessorialsSettings}
    handleEditRow={props.handleEditAssessorial}
    handleAddClick={props.handleClickAddAssessorial}
    isOpened={R.path([props.groupName], props.collapsedGroup)}
    successSharedAccessorialsAction={props.successSharedAccessorialsAction}
    handleToggleFormGroup={() => props.handleToggleFormGroup(props.groupName)}
    handlePreview={(entity: Object) => props.handleEditAssessorial(
      entity,
      'titles:view-shared-accessorial',
    )}
    entities={addAssessorialInfoToEntity(
      R.or(props.entities, []),
      R.path(['accessorialConfigParams', 'accessorialConfigList'], props),
    )}
    handleDeleteRow={(entity: Object) => G.ifElse(
      entity.shared,
      props.handleRemoveSharedAccessorial,
      props.handleDeleteAssessorial,
    )(entity)}
    customPanel={() =>
      <AccessorialTitlePanel
        handleClickAddAssessorial={props.handleClickAddAssessorial}
        handleAddSharedAccessorials={props.handleAddSharedAccessorials} />
    } />
));

export const ContractDiscounts = enhanceContractDiscount((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    handleEditRow={props.handleEditDiscount}
    handleAddClick={props.handleAddDiscount}
    entitiesFields={contractDiscountSettings}
    groupName={LC.CARRIER_GROUP_NAME_DISCOUNT}
    handleDeleteRow={props.handleDeleteDiscount}
    entities={R.or(props.contractVariableValues, [])}
    isOpened={R.path([LC.CARRIER_GROUP_NAME_DISCOUNT], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:variable-discounts', 'Variable Discounts')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_DISCOUNT)} />
));

const AwardedLine = enhanceAwardedLine({
  contractType: 'customer',
})((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    handleEditRow={props.handleEditAwardedLine}
    handleAddClick={props.handleAddAwardedLine}
    entitiesFields={contractAwardedLineSettings}
    handleDeleteRow={props.handleDeleteAwardedLine}
    entities={R.or(props.customerContractAwardedLines, [])}
    groupName={LC.CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE}
    isOpened={R.path([LC.CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:contract-awarded-lines', 'Contract Awarded Lines')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE)} />
));

const enhance = compose(
  withCarrierListForContract,
  withFormik({
    validationSchema: getUpdateCarrierContractValidationSchema,
    mapPropsToValues: ({ customerContract }: Object) => ({
      ...customerContract,
      transportationMode: R.pathOr('', ['transportationMode', 'dropdownOptionGuid'], customerContract),
    }),
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => {
      let reqBody = values;

      const discount = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT], reqBody);
      const discountType = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

      if (R.equals(discountType, GC.DISCOUNT_TYPE_FLAT)) {
        reqBody = R.assoc(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT],
          R.omit([LC.FIELD_VARIABLE_TYPE, LC.FIELD_VARIABLE_UNIT, LC.FIELD_VARIABLE_VALUES], discount),
          reqBody,
        );
      }

      if (R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE)) {
        reqBody = R.assoc(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT],
            R.compose(
              R.omit([GC.FIELD_RATE, GC.FIELD_RATE_UNIT]),
              R.assoc([LC.FIELD_VARIABLE_VALUES], props.contractVariableValues),
            )(discount),
          reqBody,
        );
      }

      if (R.equals(discountType, '')) {
        reqBody = R.assocPath(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT],
          null,
          reqBody,
        );
      }

      props.updateCustomerContractRequest(reqBody);
    },
    displayName: LC.CUSTOMER_CONTRACT_PAGE,
  }),
  withHandlers({
    handleContractClickCancel: (props: Object) => () => {
      props.setActiveTab({
        tabName: 'carrierFinance',
        tabAction: () => {
          props.getCustomerContractSuccess(null);
          props.getCustomerContractAssessorialsSuccess([]);
          props.getCustomerContractAwardedLinesSuccess([]);
        },
      });
    },
  }),
  pure,
);

const getCarrierContractOptions = (props: Object) => {
  const { carrierContracts } = props;
  if (G.isNotNilAndNotEmpty(carrierContracts)) {
    return G.mapNameGuidObjectPropsToLabelValueObject(carrierContracts);
  }
  return [];
};

const CustomerContractTab = (props: Object) => (
  <Form onSubmit={props.handleSubmit}>
    <Box
      height={customIfElse(props, '32px', 'auto')}
      overflow={customIfElse(props, 'hidden', 'visible')}
      background='linear-gradient(to right, #f3f3f3 300px, #fff 0)'
    >
      <FormGroupTitleComponent
        withArrowDown={true}
        isOpened={props.collapsedGroup[props.groupName]}
        text={G.getWindowLocale(carrierEditCustomerContractSection.title)}
        onToggleFormGroup={() => props.handleToggleFormGroup(props.groupName)} />
      <FieldsetComponent
        {...props}
        fieldsetWidth={530}
        flexDirection='column'
        fields={carrierEditCustomerContractSection.fields}
        optionsForSelect={{
          roleOptions: props.roleOptions,
          carrierOptions: props.carrierOptions,
          carrierContractOptions: getCarrierContractOptions(props),
          accessorialConfigOptions: R.pathOr([], ['accessorialConfigParams', 'accessorialConfigOptions'], props),
          transportationMode: G.prependEmptyLabelValueOption(R.filter(
            ({ value }: Object) => (props.selectedTrMode.includes(value)),
            props.carrierConfigs.transportationMode)),
        }} />
    </Box>
    {
      R.equals(
        R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], props.values),
        GC.DISCOUNT_TYPE_VARIABLE,
      )
      && <ContractDiscounts {...props} />
    }
    <UpliftSection {...props} />
    <ContractBillTo
      openModal={props.openModal}
      closeModal={props.closeModal}
      handleToggleFormGroup={props.handleToggleFormGroup}
      branchGuid={R.path(['formValues', GC.BRANCH_GUID], props)}
      contractGuid={R.pathOr('', ['customerContract', GC.FIELD_GUID], props)}
      locationType={R.pathOr([], ['carrierConfigs', GC.FIELD_LOCATION_TYPE], props)} />
    <CarrierAssessorials
      {...props}
      relatedEntityField='contractGuid'
      entities={props.customerContractAssessorials}
      groupName={LC.CARRIER_CONTRACT_GROUP_NAME_ASSESSORIALS}
      deleteAction={props.deleteCustomerContractAssessorialRequest}
      updateAction={props.updateCustomerContractAssessorialRequest}
      createAction={props.createCustomerContractAssessorialRequest}
      relatedGuid={R.pathOr('', ['customerContract', 'guid'], props)}
      addSharedAccessorialsEndpoint='addCustomerContractSharedAccessorials'
      removeSharedAccessorialsEndpoint='removeCustomerContractSharedAccessorials'
      successSharedAccessorialsAction={props.getCustomerContractAssessorialsRequest}
      panelTitle={G.getWindowLocale('titles:contract-accessorials', 'Contract Accessorials')} />
    <AwardedLine {...props} />
    <FormButtons
      {...props}
      zIndex={15}
      width='auto'
      handleClickCancel={props.handleContractClickCancel}
    />
  </Form>
);

export default enhance(CustomerContractTab);
