import * as R from 'ramda';
import { put, call, take, fork, select, takeLatest } from 'redux-saga/effects';
// features
import PC from '../../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// feature fleet-profile
import * as A from '../actions';
import { makeSelectEntityBranchGuid, makeSelectPrimaryObjectGuid } from '../selectors';
//////////////////////////////////////////////////

const configNames = [
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.INVOICE_TEL_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
];

function* getDriverBranchConfigsRequest() {
  try {
    const branchGuid = yield select(makeSelectEntityBranchGuid());

    const options = {
      params: {
        names: R.join(', ', configNames),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverBranchConfigsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverBranchConfigsRequest exception');
  }
}

function* getDriverGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getCurrentDriverEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getGeneralDetailsRequest exception');
  }
}

function* updateDriverGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, options, handleClose, saveAndClose } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.driver, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.propEq(GC.FIELD_DRIVER_EMPLOYED, false, values)) yield put(A.getDriverAssignmentSuccess({}));

      if (R.pathEq(true, ['options', 'shouldCloseModal'], payload)) {
        yield put(closeModal());
      }

      if (saveAndClose) {
        yield put(closeLoader());

        G.callFunction(handleClose);

        return false;
      }

      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'updateDriverGeneralDetailsRequest fail');
    }

    const setSubmitting = R.prop('setSubmitting', options);

    if (G.isFunction(setSubmitting)) setSubmitting(false);

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateDriverGeneralDetailsRequest exception');
  }
}

// safety violation
function* createOrUpdateSafetyViolationEntityByEntityTypeRequest({ payload }: Object) {
  try {
    const { values, entityType } = payload;

    yield put(openLoader());

    const isUpdate = G.isNotNilAndNotEmpty(values.version);
    const method = G.ifElse(R.and(isUpdate, G.notEquals(entityType, 'document')), 'put', 'post');

    const safetyViolationEndpointsMap = {
      note: endpointsMap.safetyViolationNote,
      safetyViolation: endpointsMap.safetyViolation,
      document: G.ifElse(
        isUpdate,
        endpointsMap.safetyViolationDocumentUpdate,
        endpointsMap.safetyViolationDocument,
      ),
    };

    let requestData = values;

    if (R.equals(entityType, 'document')) requestData = G.makeDataForDocument(values);

    const options = { data: requestData };
    const endpoint = G.getPropFromObject(entityType, safetyViolationEndpointsMap);

    const res = yield call(sendRequest, method, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.createOrUpdateSafetyViolationEntityByEntityTypeSuccess({ data, entityType }));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateSafetyViolationEntityByEntityTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateSafetyViolationEntityByEntityTypeRequest exception');
  }
}

function* removeSafetyViolationEntityByEntityTypeRequest({ payload }: Object) {
  try {
    const { guid, entityType } = payload;

    yield put(openLoader());

    const safetyViolationEndpointsMap = {
      note: endpointsMap.removeSafetyViolationNoteByGuid,
      safetyViolation: endpointsMap.removeSafetyViolationByGuid,
      document: endpointsMap.removeSafetyViolationDocumentByGuid,
    };

    const endpoint = G.getPropFromObject(entityType, safetyViolationEndpointsMap)(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeSafetyViolationEntityByEntityTypeSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeSafetyViolationEntityByEntityTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeSafetyViolationEntityByEntityTypeRequest exception');
  }
}

// users
function* getGeneralUserListRequest() {
  try {
    if (G.hasNotAmousCurrentUserPermissions(PC.USER_WRITE)) return false;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listUsersGeneral, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralUserListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getGeneralUserListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getGeneralUserListRequest exception');
  }
}

function* getFleetDispatchingGroupRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.fleetDispatchingGroupByBranchGuid, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getFleetDispatchingGroupSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetDispatchingGroupRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getFleetDispatchingGroupRequest exception');
  }
}

// visit page
function* visitFleetDriverProfilePage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_PROFILE_DRIVER_PAGE);

    yield put(A.setInitialState('driver'));

    yield fork(getDriverGeneralDetailsRequest, { payload: G.getGuidFromObject(payload) });

    const generalDetails = yield take(A.getGeneralDetailsSuccess);

    const branchGuid = R.path(['payload', GC.BRANCH_GUID], generalDetails);

    yield call(getGeneralUserListRequest);
    yield call(getFleetDispatchingGroupRequest);

    yield put(A.getFleetProfileConfigsRequest({
      [GC.BRANCH_GUID]: branchGuid,
      group: GC.DRIVER_CONFIG_GROUP,
    }));

    break;
  }
}

// assignment
function* getDriverAssignmentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'get', endpointsMap.getAssignInfoEndpoint(payload));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverAssignmentSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverAssignmentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverAssignmentRequest exception');
  }
}

function* getAvailableAssignableRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const {
      groupName,
      endpointName,
      currentDriverGuid,
      currentPrimaryDriverGuid,
    } = payload;

    const branchGuid = yield select(makeSelectEntityBranchGuid());

    const options = {
      params: {
        currentDriverGuid,
        currentPrimaryDriverGuid,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', G.getPropFromObject(endpointsMap, endpointName), options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableAssignableSuccess({ data, groupName }));
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableAssignableRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDriverAssignmentRequest exception');
  }
}

function* assignOrUnassignEntityToDriverRequest({ payload }: Object) {
  const {
    endpoint,
    requestPayload,
    driverAssignmentGuid,
    options: { failCallback } = {},
  } = payload;

  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpoint, requestPayload);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getDriverAssignmentRequest(driverAssignmentGuid));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      G.callFunction(failCallback);

      yield call(G.handleFailResponse, res, 'assignOrUnassignEntityToDriverRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    G.callFunction(failCallback);

    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'assignOrUnassignEntityToDriverRequest exception');
  }
}

// assign vendor
function* assignVendorToDriverRequest({ payload }: Object) {
  try {
    const driverGuid = yield select(makeSelectPrimaryObjectGuid());

    yield put(openLoader());

    const options = { data: R.assoc(GC.FIELD_DRIVER_GUID, driverGuid, payload) };

    const res = yield call(sendRequest, 'put', endpointsMap.assignVendorToDriver, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(getDriverGeneralDetailsRequest, { payload: driverGuid });
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'assignVendorToDriverRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'assignVendorToDriverRequest exception');
  }
}

function* unAssignVendorFromDriverRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const driverGuid = yield select(makeSelectPrimaryObjectGuid());

    const endpoint = endpointsMap.unassignVendor('driver', driverGuid);

    const options = {
      params: { [GC.FIELD_VENDOR_GUID]: payload },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(getDriverGeneralDetailsRequest, { payload: driverGuid });
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'unAssignVendorFromDriverRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'unAssignVendorFromDriverRequest exception');
  }
}

function* getDriverPayrollChargesIsPaidOnPayrollRequest({ payload }: Object) {
  try {
    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.driverPayrollChargesIsPaidOnPayroll, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverPayrollChargesIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverPayrollChargesIsPaidOnPayrollSaga fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getDriverPayrollChargesIsPaidOnPayrollSaga exception'));
  }
}

// payroll accessorial
function* getDriverPayrollAccessorialsIsPaidOnPayrollRequest({ payload }: Object) {
  try {
    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.driverPayrollAssessorialsPaidOnPayroll, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverPayrollAccessorialsIsPaidOnPayrollSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverPayrollAccessorialsIsPaidOnPayrollRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getDriverPayrollAccessorialsIsPaidOnPayrollRequest exception'));
  }
}

// advance payment
function* updateAdvancePaymentStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.advancePaymentChangeStatus, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateAdvancePaymentStatusSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateAdvancePaymentStatusRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'updateAdvancePaymentStatusRequest exception');
  }
}

// expense
function* createOrUpdateDriverExpenseRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { version } = payload;

    const isUpdate = G.isNotNilAndNotEmpty(version);
    const options = { data: G.makeDataForMultipleDocuments(payload) };
    const method = G.ifElse(isUpdate, 'put', 'post');

    const res = yield call(sendRequest, method, endpointsMap.driverExpense, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateEntitySuccess({ data, groupName: 'driverExpenseList' }));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateDriverExpenseRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateDriverExpenseRequest exception');
  }
}

function* approveOrDeclineDriverExpenseRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, type } = payload;

    const endpointName = G.ifElse(R.equals(type, 'approve'), 'getDriverExpenseApprove', 'getDriverExpenseDecline');

    const res = yield call(sendRequest, 'put', endpointsMap[endpointName](guid));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateEntitySuccess({ data, groupName: 'driverExpenseList' }));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'approveOrDeclineDriverExpenseRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'approveOrDeclineDriverExpenseRequest exception');
  }
}

function* downloadDriverExpenseDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { fileUri, fileName } = payload;

    const options = {
      params: { fileUri },
      resType: 'arraybuffer',
    };

    const res = yield call(
      sendRequestWithQSParamsSerializer,
      'get',
      endpointsMap.driverExpenseDocumentDownload,
      options,
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, fileName);
    } else {
      yield call(G.handleFailResponse, res, 'downloadDriverExpenseDocumentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'downloadDriverExpenseDocumentRequest exception');
  }
}

function* deleteDriverExpenseDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { driverExpenseGuid: payload },
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.driverExpenseDocument, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.deleteDriverExpenseDocumentSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'deleteDriverExpenseDocumentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'deleteDriverExpenseDocumentRequest exception');
  }
}

export function* fleetProfileDriverWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_FLEET_PROFILE_DRIVER_PAGE, visitFleetDriverProfilePage);
  // general
  yield takeLatest(A.getDriverGeneralDetailsRequest, getDriverGeneralDetailsRequest);
  yield takeLatest(A.updateDriverGeneralDetailsRequest, updateDriverGeneralDetailsRequest);
  // configs
  yield takeLatest(A.getDriverBranchConfigsRequest, getDriverBranchConfigsRequest);
  // safety violation
  yield takeLatest(A.removeSafetyViolationEntityByEntityTypeRequest, removeSafetyViolationEntityByEntityTypeRequest);
  yield takeLatest(
    A.createOrUpdateSafetyViolationEntityByEntityTypeRequest,
    createOrUpdateSafetyViolationEntityByEntityTypeRequest,
  );
  // assignment
  yield takeLatest(A.getDriverAssignmentRequest, getDriverAssignmentRequest);
  yield takeLatest(A.getAvailableAssignableRequest, getAvailableAssignableRequest);
  yield takeLatest(A.assignOrUnassignEntityToDriverRequest, assignOrUnassignEntityToDriverRequest);
  // vendor assignment
  yield takeLatest(A.assignVendorToDriverRequest, assignVendorToDriverRequest);
  yield takeLatest(A.unAssignVendorFromDriverRequest, unAssignVendorFromDriverRequest);
  // monthly payroll deduction
  yield takeLatest(A.getDriverPayrollChargesIsPaidOnPayrollRequest, getDriverPayrollChargesIsPaidOnPayrollRequest);
  // payroll accessorial
  yield takeLatest(
    A.getDriverPayrollAccessorialsIsPaidOnPayrollRequest,
    getDriverPayrollAccessorialsIsPaidOnPayrollRequest,
  );
  // advance payment
  yield takeLatest(A.updateAdvancePaymentStatusRequest, updateAdvancePaymentStatusRequest);
  // expenses
  yield takeLatest(A.createOrUpdateDriverExpenseRequest, createOrUpdateDriverExpenseRequest);
  yield takeLatest(A.deleteDriverExpenseDocumentRequest, deleteDriverExpenseDocumentRequest);
  yield takeLatest(A.downloadDriverExpenseDocumentRequest, downloadDriverExpenseDocumentRequest);
  yield takeLatest(A.approveOrDeclineDriverExpenseRequest, approveOrDeclineDriverExpenseRequest);
}

export default fleetProfileDriverWatcherSaga;
