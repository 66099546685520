
import React from 'react';
// features
import CarrierScorecards from '../../carrier-scorecards';
//////////////////////////////////////////////////

export const scorecardsSettings = {
  groupName: 'scorecards',
  Component: ({ carrierName, primaryObjectGuid }: Object) => (
    <CarrierScorecards
      minHeight={200}
      carrierName={carrierName}
      maxHeight='calc(100% - 860px)'
      carrierGuid={primaryObjectGuid}
    />
  ),
};
