import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getSpecificDocumentFeaturesOptions } from '../../../helpers/options';
//////////////////////////////////////////////////

export const defaultDocumentFields = {
  customerGuid: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NAME]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FILE]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NOTES]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_SPECIFIC_DOCUMENT_FEATURES]: null,
};

const defaultStyles = {
  width: 210,
  errorTop: '105%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

export const documentFields = [
  {
    ...defaultStyles,
    type: 'file',
    loc: 'titles:file',
    placeholder: 'actions:upload',
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FILE,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'dataSourceList',
    loc: 'titles:data-source',
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:printable-section',
    customDisabledFunction: 'handleDisablePrintableSection',
    options: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION,
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:document-format',
    customDisabledFunction: 'handleDocumentFormat',
    options: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT,
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:type',
    options: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID,
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:name',
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NAME,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:specific-document-features',
    options: getSpecificDocumentFeaturesOptions(),
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_SPECIFIC_DOCUMENT_FEATURES,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:customer',
    fieldName: 'customerGuid',
    options: 'customerBranchList',
  },
  {
    ...defaultStyles,
    width: 440,
    type: 'textarea',
    loc: 'titles:notes',
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NOTES,
  },
];

export const documentTemplateValidationSchemaObject = {
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(2, G.getShouldBeFromToCharLocaleTxt(2, 40))
    .max(40, G.getShouldBeFromToCharLocaleTxt(2, 40)),
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FILE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NOTES]: Yup.string()
    .notRequired()
    .nullable(true)
    .min(2, G.getShouldBeFromToCharLocaleTxt(2, 250))
    .max(250, G.getShouldBeFromToCharLocaleTxt(2, 250)),
  customerGuid: Yup.string()
    .nullable(true)
    .notRequired(),
};

export const printableSectionLocaleMap = {
  [GC.DOCUMENT_PRINTABLE_SECTION_TEL]: G.getWindowLocale('titles:tel', 'TEL'),
  [GC.DOCUMENT_PRINTABLE_SECTION_CLO]: G.getWindowLocale('titles:clo', 'CLO'),
  [GC.DOCUMENT_PRINTABLE_SECTION_ROUTE]: G.getWindowLocale('titles:route', 'Route'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK]: G.getWindowLocale('titles:truck', 'Truck'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DRIVER]: G.getWindowLocale('titles:driver', 'Driver'),
  [GC.DOCUMENT_PRINTABLE_SECTION_TEL_REPORT]: G.getWindowLocale('titles:tel-list', 'TEL List'),
  [GC.DOCUMENT_PRINTABLE_SECTION_CLO_REPORT]: G.getWindowLocale('titles:clo-list', 'CLO List'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER]: G.getWindowLocale('titles:trailer', 'Trailer'),
  [GC.DOCUMENT_PRINTABLE_SECTION_ORDER_QUOTE]: G.getWindowLocale('titles:order-quote', 'Order Quote'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE]: G.getWindowLocale('titles:fleet-invoice', 'Fleet Invoice'),
  [GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL]: G.getWindowLocale('titles:driver-payroll', 'Driver Payroll'),
  [GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE]: G.getWindowLocale('titles:carrier-invoice', 'Carrier Invoice'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK_SERVICE]: G.getWindowLocale('titles:truck-service', 'Truck Service'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL]: G.getWindowLocale('titles:vendor-payroll', 'Vendor Payroll'),
  [GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE]: G.getWindowLocale('titles:customer-invoice', 'Customer Invoice'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL_NEW]:
    G.getWindowLocale('titles:vendor-payroll-new', 'Vendor Payroll New'),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER_SERVICE]: G.getWindowLocale(
    'titles:trailer-service',
    'Trailer Service',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE]: G.getWindowLocale(
    'titles:customer-master-invoice',
    'Customer Master Invoice',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DISPATCH_DOCUMENTS]: G.getWindowLocale(
    'titles:fleet-dispatch-document',
    'Fleet Dispatch Document',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS]: G.getWindowLocale(
    'titles:carrier-dispatch-document',
    'Carrier Dispatch Document',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE_REPORT]: G.getWindowLocale(
    'titles:customer-master-invoice-report',
    'Customer Master Invoice Report',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL_REPORT]: G.getWindowLocale(
    'titles:driver-payroll-report',
    'Driver Payroll Report',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_VENDOR_DRIVER_RATE_LIST]: G.getWindowLocale(
    'titles:vendor-driver-rate-list',
    'Vendor Driver Rate List',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL_REPORT]: G.getWindowLocale(
    'titles:vendor-payroll-report',
    'Vendor Payroll Report',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_INVOICE_REPORT]: G.getWindowLocale(
    'titles:vendor-invoice-report',
    'Vendor Invoice Report',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE_REPORT]: G.getWindowLocale(
    'titles:carrier-invoice-report',
    'Carrier Invoice Report',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE_REPORT]: G.getWindowLocale(
    'titles:fleet-invoice-report',
    'Fleet Invoice Report',
  ),
  [GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE_REPORT]: G.getWindowLocale(
    'titles:customer-invoice-report',
    'Customer Invoice Report',
  ),
};

export const printableSection = {
  [GC.DOCUMENT_PRINTABLE_SECTION_ROUTE]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_ROUTE,
      label: G.getWindowLocale('titles:route', 'Route'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_IFTA]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_IFTA,
      label: G.getWindowLocale('titles:ifta', 'IFTA'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_CLO]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CLO,
      label: G.getWindowLocale('titles:clo', 'CLO'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CLO_REPORT,
      label: G.getWindowLocale('titles:clo-list', 'CLO List'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE,
      label: G.getWindowLocale('titles:customer-invoice', 'Customer Invoice'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE_REPORT,
      label: G.getWindowLocale('titles:customer-invoice-report', 'Customer Invoice Report'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_TEL]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_TEL,
      label: G.getWindowLocale('titles:tel', 'TEL'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_TEL_REPORT,
      label: G.getWindowLocale('titles:tel-list', 'TEL List'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE,
      label: G.getWindowLocale('titles:fleet-invoice', 'Fleet Invoice'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE_REPORT,
      label: G.getWindowLocale('titles:fleet-invoice-report', 'Fleet Invoice Report'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL,
      label: G.getWindowLocale('titles:driver-payroll', 'Driver Payroll'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE,
      label: G.getWindowLocale('titles:carrier-invoice', 'Carrier Invoice'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE_REPORT,
      label: G.getWindowLocale('titles:carrier-invoice-report', 'Carrier Invoice Report'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DISPATCH_DOCUMENTS,
      label: G.getWindowLocale('titles:fleet-dispatch-document', 'Fleet Dispatch Document'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS,
      label: G.getWindowLocale('titles:carrier-dispatch-document', 'Carrier Dispatch Document'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL,
      label: G.getWindowLocale('titles:driver-payroll', 'Driver Payroll'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL_REPORT,
      label: G.getWindowLocale('titles:driver-payroll-report', 'Driver Payroll Report'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE,
      label: G.getWindowLocale('titles:customer-master-invoice', 'Customer Master Invoice'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE_REPORT,
      label: G.getWindowLocale('titles:customer-master-invoice-report', 'Customer Master Invoice Report'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DRIVER]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DRIVER,
      label: G.getWindowLocale('titles:driver', 'Driver'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK,
      label: G.getWindowLocale('titles:truck', 'Truck'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK_SERVICE,
      label: G.getWindowLocale('titles:truck-service', 'Truck Service'),
    },
  ],
  [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER]: [
    { value: '', label: '' },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER,
      label: G.getWindowLocale('titles:trailer', 'Trailer'),
    },
    {
      value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER_SERVICE,
      label: G.getWindowLocale('titles:trailer-service', 'Trailer Service'),
    },
  ],
};

export const getValidOptions = R.compose(
  G.addEmptyOptionToDropDown,
  R.map((item: Object) => ({ value: item, label: R.propOr(item, item, printableSectionLocaleMap) })),
);

export const getPrintableSectionOptions = (dataSource: string, indexedDataSourceList: Object) => R.compose(
  getValidOptions,
  R.keys(),
  R.pathOr([], [dataSource, 'printableSectionFormats']),
)(indexedDataSourceList);

export const getDocumentFormatOptions = (values: Object, indexedDataSourceList: Object) => R.compose(
  G.addEmptyOptionToDropDown,
  R.pathOr(
    [],
    [
      R.prop(GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE, values),
      'printableSectionFormats',
      R.prop(GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION, values),
    ],
  ),
)(indexedDataSourceList);
