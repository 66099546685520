import * as R from 'ramda';
import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { css } from 'styled-components';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fieldSettings = [
  {
    isRequired: true,
    type: 'reactSelect',
    options: GC.GENERAL_MODE_TRANSPORTATION,
    fieldName: GC.FIELD_TRANSPORTATION_MODE_GUID,
    label: ['titles:transportation-mode', 'Transportation Mode'],
    inputWrapperStyles: R.assoc('zIndex', 12, inputWrapperStyles),
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: GC.GENERAL_EQUIPMENTS,
    fieldName: GC.FIELD_EQUIPMENTS,
    label: ['titles:equipments', 'Equipments'],
    inputWrapperStyles: R.assoc('zIndex', 11, inputWrapperStyles),
  },
  {
    isMulti: true,
    type: 'reactSelect',
    fieldName: GC.FIELD_SERVICES,
    options: GC.GENERAL_SERVICES,
    label: ['titles:services', 'Services'],
    inputWrapperStyles: R.assoc('zIndex', 10, inputWrapperStyles),
  },
];

const defaultValues = {
  [GC.FIELD_EQUIPMENTS]: null,
  [GC.FIELD_SERVICE_TYPES]: null,
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: null,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_EQUIPMENTS]: G.yupArrayRequired,
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: G.yupStringRequired,
});

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  pure,
);

const makeOptionsForSelect = R.compose(
  R.map(({ options }: Object) => G.getOptionsFromConfigValueByParentGuidOrGuid(options)),
  R.pick([
    GC.GENERAL_SERVICES,
    GC.GENERAL_EQUIPMENTS,
    GC.GENERAL_MODE_TRANSPORTATION,
  ]),
  R.prop('dropdowns'),
);

const getOverflowY = ({ equipments, serviceTypes }: Object) => {
  const formHeight = R.add(
    R.multiply(R.add(R.length(equipments), R.length(serviceTypes)), 31),
    230,
  );

  return G.ifElse(R.gt(formHeight, R.subtract(window.innerHeight, 120)), 'auto', 'unset');
};

const TransportationModeGroupingForm = (props: Object) => (
  <form
    onSubmit={props.handleSubmit}
    css={css`
      padding-right: 5px;
      max-height: calc(100vh - 120px);
      overflow-y: ${getOverflowY(props.values)};

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    `}
  >
    <Fieldset2
      {...G.getFormikProps(props)}
      {...makeOptionsForSelect(props)}
      {...R.pathOr({}, ['optionsForSelect'], props)}
      fields={fieldSettings}
      fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
    />
    <FormFooter2 />
  </form>
);

export default enhance(TransportationModeGroupingForm);
