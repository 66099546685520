import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature carrier
import * as A from './actions';
import * as LC from './constants';
//////////////////////////////////////////////////

const initial = {
  safety: [],
  mailList: [],
  terminals: [],
  documents: [],
  insurances: [],
  saferWatch: {},
  certificates: [],
  carrierConfigs: {},
  generalConfigs: {},
  carrierDetails: {},
  carrierFinance: {},
  variableValues: [],
  assocCompanies: [],
  sendPackage: false,
  contractBillTo: [],
  dispatchConfigs: [],
  templateConfigs: {},
  terminalDetails: {},
  terminalFinance: {},
  carrierContracts: [],
  carrierSequences: [],
  activeTab: 'general',
  terminalContacts: [],
  activePage: 'general',
  customersContract: [],
  carrierContract: null,
  parentBranchGuids: [],
  carrierPaymentTerms: [],
  carrierAssessorials: [],
  integrationInfoList: [],
  carrierPayLocations: [],
  terminalPayLocations: [],
  integrationAuditList: [],
  accessorialConfigList: {},
  additionalContactList: {},
  carrierReferenceTypes: [],
  carrierPortalLoginUsers: [],
  accessorialConfigOptions: [],
  loadBoardIntegrationList: [],
  [GC.UI_FIELD_REFERENCES]: [],
  carrierIntegrationOptions: [],
  carrierContractAssessorials: [],
  carrierContractAwardedLines: [],
  customerContractAwardedLines: [],
  availableForCarrierLoadBoards: [],
  carrierInternalDispatchSettings: [],
  carrierContractRate: {
    list: [],
    totalCount: 0,
    pagination: {
      limit: 20,
      offset: 0,
    },
  },
  collapsedGroup: {
    terminal: true,
    documents: true,
    insurances: true,
    certificate: true,
    carrierRates: true,
    generalDetails: true,
    carrierFinance: true,
    terminalFinance: true,
    financeLocation: true,
    terminalDetails: true,
    terminalContact: true,
    associatedCompany: true,
    terminalQuoteProcess: true,
    terminalDispatchProcess: true,
    loadBoardIntegrationInfo: true,
    terminalStatusCheckProcess: true,
    [LC.CARRIER_GROUP_NAME_MAILS]: true,
    [LC.CARRIER_GROUP_NAME_UPLIFT]: true,
    [LC.CARRIER_GROUP_NAME_DISCOUNT]: true,
    [LC.CARRIER_GROUP_NAME_SAFETY_INFO]: true,
    [LC.CARRIER_GROUP_NAME_ASSESSORIALS]: true,
    [LC.CARRIER_GROUP_NAME_INTEGRATION_INFO]: true,
    [LC.CARRIER_GROUP_NAME_CARRIER_CONTRACT]: true,
    [LC.CARRIER_GROUP_NAME_CONTRACT_BILL_TO]: true,
    [LC.CARRIER_GROUP_NAME_CONTRACT_GENERAL]: true,
    [LC.CARRIER_GROUP_NAME_INTEGRATION_AUDIT]: true,
    [LC.CARRIER_GROUP_NAME_CUSTOMERS_CONTRACT]: true,
    [LC.CARRIER_GROUP_NAME_ADDITIONAL_CONTACTS]: true,
    [LC.CARRIER_GROUP_NAME_SYNCHRONIZATION_INFO]: true,
    [LC.CARRIER_GROUP_NAME_CARRIER_PORTAL_LOGIN]: true,
    [LC.CARRIER_GROUP_NAME_CARRIER_PAYMENT_TERM]: true,
    [LC.CARRIER_CONTRACT_GROUP_NAME_ASSESSORIALS]: true,
    [LC.CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE]: true,
    [LC.CARRIER_GROUP_NAME_INTERNAL_DISPATCH_SETTINGS]: true,
  },
};

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const getDispatchConfigListSuccess = (state: Object, data: Object) => (
  P.$set('dispatchConfigs', data, state)
);

const setActivePage = (state: Object, activePage: string) => (
  P.$set('activePage', activePage, state)
);

const receivedSafetySuccess = (state: Object, data: Object) => (
  P.$set('safety', G.ifElse(G.isNotNilAndNotEmpty(data), R.of(Array, data), []), state)
);

const getCarrierSuccess = (state: Object, carrier: Object) => (
  P.$set('carrierDetails', carrier, state));

const getCarrierConfigsSuccess = (state: Object, data: Object) => (
  P.$set('carrierConfigs', data, state));

const getGeneralConfigsSuccess = (state: Object, data: Object) => (
  P.$set('generalConfigs', data, state));

const getTemplateConfigsSuccess = (state: Object, data: Object) => (
  P.$set('templateConfigs', data, state));

const getCarrierRefSuccess = (state: Object, references: Array) => (
  P.$set(GC.UI_FIELD_REFERENCES, references, state));

const getCarrierAssocCompanySuccess = (state: Object, companies: Array) => (
  P.$set('assocCompanies', companies, state));

const getCarrierTerminalsSuccess = (state: Object, terminals: Array) => (
  P.$set('terminals', terminals, state));

const getCarrierInsuranceSuccess = (state: Object, insurances: Array) => (
  P.$set('insurances', insurances, state));

const getCarrierDocsSuccess = (state: Object, docs: Array) => (
  P.$set('documents', docs, state));

const getTerminalPayLocationsSuccess = (state: Object, data: Array) => (
  P.$set('terminalPayLocations', data, state));

const getCarrierPayLocationsSuccess = (state: Object, data: Array) => (
  P.$set('carrierPayLocations', data, state));

const getCarrierCertificatesSuccess = (state: Object, data: Array) => (
  P.$set('certificates', data, state));

const getCarrierContractRatesSuccess = (state: Object, data: Array) => {
  if (G.isNilOrEmpty(data.results)) return state;
  const { results, totalCount } = data;
  const { carrierContractRate: { list, pagination: { limit, offset } } } = state;
  const mappedResults = R.map(R.assoc('selected', false), results);
  const newOffset = R.add(offset, limit);
  const newList = R.concat(list, mappedResults);

  return P.$all(
    P.$set('carrierContractRate.totalCount', totalCount),
    P.$set(
      'carrierContractRate.list',
      newList,
    ),
    P.$set(
      'carrierContractRate.pagination.offset',
      G.ifElse(R.gt(totalCount, newOffset), newOffset, totalCount),
    ),
    state,
  );
};

const selectCarrierContractRate = (state: Object, id: string) => {
  const { carrierContractRate: { list } } = state;
  if (R.equals(id, 'all')) {
    const selected = R.not(R.all(({ selected }: Object) => selected, list));
    const mappedList = R.map(R.assoc('selected', selected), list);

    return P.$set('carrierContractRate.list', mappedList, state);
  }
  const index = R.findIndex(R.propEq(id, GC.FIELD_GUID), list);

  return P.$toggle(`carrierContractRate.list.${index}.selected`, state);
};

const resetCarrierContractListAndPagination = (state: Object) => (
  P.$set('carrierContractRate', initialState.carrierContractRate, state)
);

const getCarrierContractsSuccess = (state: Object, data: Array) => (
  P.$set('carrierContracts', data, state)
);

const getCustomersContractSuccess = (state: Object, data: Array) => (
  P.$set('customersContract', data, state)
);

const getCarrierContractAssessorialsSuccess = (state: Object, data: Array) => (
  P.$set('carrierContractAssessorials', data, state));

const getCarrierContractSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('carrierContract', data),
    P.$set('variableValues', R.pathOr([], ['discount', 'variableValues'], data)),
    state,
  )
);

const getCarrierContractAwardedLinesSuccess = (state: Object, data: Object) => (
  P.$set('carrierContractAwardedLines', data, state));

const createCarrierContractAwardedLineSuccess = (state: Object, data: Object) => (
  P.$add('carrierContractAwardedLines', data, state));

const updateCarrierContractAwardedLineSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`carrierContractAwardedLines.${index}`, data, state));

const deleteCarrierContractAwardedLineSuccess = (state: Object, index: number) => (
  P.$drop(`carrierContractAwardedLines.${index}`, state));

const createCarrierContractRateSuccess = (state: Object, data: Object) => (
  P.$add('carrierContractRate.list', R.assoc('selected', false, data), state)
);

const createCarrierContractSuccess = (state: Object, data: Object) => (
  P.$add('carrierContracts', data, state));

const createCarrierContractAssessorialSuccess = (state: Object, data: Object) => (
  P.$add('carrierContractAssessorials', data, state));

const updateCarrierContractRateSuccess = (state: Object, { data, index }: Object) => {
  const selected = R.pathOr(false, ['carrierContractRate', 'list', index, 'selected'], state);

  return P.$set(`carrierContractRate.list.${index}`, R.assoc('selected', selected, data), state);
};

const updateCarrierContractSuccess = (state: Object, { data, index }: Object) => (
  P.$all(
    P.$set('carrierContract', null),
    P.$set(
      `carrierContracts.${index}`,
      R.assoc(
        GC.FIELD_TRANSPORTATION_MODE_DISPLAYED_VALUE,
        R.path([GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], data),
        data,
      ),
    ),
    state,
));

const deleteCarrierContractRateSuccess = (state: Object, guid: string) => (
  P.$drop(`carrierContractRate.list.${guid}`, state));

const deleteCarrierContractSuccess = (state: Object, guid: string) => (
  P.$drop(`carrierContracts.${guid}`, state));

const getCarrierAssessorialsSuccess = (state: Object, data: Array) => (
  P.$set('carrierAssessorials', data, state));

const createCarrierAssessorialSuccess = (state: Object, data: Object) => (
  P.$add('carrierAssessorials', data, state));

const updateCarrierAssessorialSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`carrierAssessorials.${index}`, data, state));

const updateCarrierContractAssessorialSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`carrierContractAssessorials.${index}`, data, state));

const deleteCarrierAssessorialSuccess = (state: Object, guid: string) => (
  P.$drop(`carrierAssessorials.${guid}`, state));

const deleteCarrierContractAssessorialSuccess = (state: Object, index: string) => (
  P.$drop(`carrierContractAssessorials.${index}`, state));

const createCarrierRefSuccess = (state: Object, data: Object) => (
  P.$add(GC.UI_FIELD_REFERENCES, data, state));

const createCarrierAssocCompanySuccess = (state: Object, data: Object) => (
  P.$add('assocCompanies', data, state));

const createCarrierInsuranceSuccess = (state: Object, data: Object) => (
  P.$add('insurances', data, state));

const createCarrierDocSuccess = (state: Object, data: Object) => (
  P.$add('documents', data, state));

const getTerminalContactsSuccess = (state: Object, data: Object) => (
  P.$set('terminalContacts', data, state));

const getTerminalFinanceSuccess = (state: Object, data: Object) => (
  P.$set('terminalFinance', data, state));

const getCarrierFinanceSuccess = (state: Object, data: Object) => {
  const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, state.generalConfigs);
  const financeCurrency = R.pathOr(branchCurrency, [GC.FIELD_CURRENCY], data);
  const carrierFinance = R.assoc(GC.FIELD_CURRENCY, financeCurrency, data);

  return P.$set('carrierFinance', carrierFinance, state);
};

const createCarrierTerminalSuccess = (state: Object, data: Object) => (
  P.$add('terminals', data, state));

const createTerminalContactSuccess = (state: Object, data: Array) => (
  P.$set('terminalContacts', data, state));

const createTerminalPayLocationSuccess = (state: Object, data: Object) => (
  P.$add('terminalPayLocations', data, state));

const createCarrierCertificateSuccess = (state: Object, data: Object) => (
  P.$add('certificates', data, state));

const createCarrierPayLocationSuccess = (state: Object, data: Object) => (
  P.$add('carrierPayLocations', data, state));

const updateCarrierSuccess = (state: Object, carrier: Object) => (
  P.$set('carrierDetails', carrier, state));

const updateCarrierFinanceSuccess = (state: Object, finance: Object) => (
  P.$set('carrierFinance', finance, state));

const updateCarrierRefSuccess = (state: Object, ref: Object) => (
  P.$set(`${GC.UI_FIELD_REFERENCES}.${ref.guid}`, ref, state));

const updateCarrierAssocCompanySuccess = (state: Object, { data, index }: Object) => (
  P.$set(`assocCompanies.${index}`, data, state));

const updateCarrierInsuranceSuccess = (state: Object, data: Object) => {
  const { insurances } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), insurances);

  return P.$set(`insurances.${index}`, data, state);
};

const getAccessorialConfigListSuccess = (state: Object, data: Object) => {
  const accessorialConfigOptions = R.map(({ displayedValue, originalConfigGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: originalConfigGuid,
  }), data);
  const accessorialConfigList = R.compose(
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
    R.map((item: Object) => R.compose(
      R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
      R.pick([
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
        GC.FIELD_DISPLAYED_VALUE,
        GC.FIELD_CHARGE_RATE_TYPE,
        GC.FIELD_CHARGE_RATE_UNIT,
      ]),
    )(item)),
  )(data);

  return P.$all(
    P.$set('accessorialConfigList', accessorialConfigList),
    P.$set('accessorialConfigOptions', accessorialConfigOptions),
    state,
  );
};

const updateCarrierDocSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`documents.${index}`, data, state));

const updateTerminalContactSuccess = (state: Object, data: Array) => (
  P.$set('terminalContacts', data, state));

const updateTerminalSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`terminals.${index}`, data, state));

const updateTerminalPayLocationSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`terminalPayLocations.${index}`, data, state));

const updateCarrierCertificateSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`certificates.${index}`, data, state));

const updateCarrierPayLocationSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`carrierPayLocations.${index}`, data, state));

const deleteCarrierRefSuccess = (state: Object, refGuid: string) => (
  P.$drop(`${GC.UI_FIELD_REFERENCES}.${refGuid}`, state));

const deleteCarrierAssocCompanySuccess = (state: Object, index: string) => (
  P.$drop(`assocCompanies.${index}`, state));

const deleteCarrierInsuranceSuccess = (state: Object, index: string) => (
  P.$drop(`insurances.${index}`, state));

const deleteCarrierDocSuccess = (state: Object, index: string) => (
  P.$drop(`documents.${index}`, state));

const deleteCarrierTerminalSuccess = (state: Object, index: string) => (
  P.$drop(`terminals.${index}`, state));

const deleteTerminalContactSuccess = (state: Object, index: string) => (
  P.$drop(`terminalContacts.${index}`, state));

const deleteTerminalPayLocationSuccess = (state: Object, index: string) => (
  P.$drop(`terminalPayLocations.${index}`, state));

const deleteCarrierPayLocationSuccess = (state: Object, index: string) => (
  P.$drop(`carrierPayLocations.${index}`, state));

const deleteCarrierCertificateSuccess = (state: Object, index: string) => (
  P.$drop(`certificates.${index}`, state));

const toggleCarrierFormGroup = (state: Object, groupName: string) => (
  P.$toggle(`collapsedGroup.${groupName}`, state));

const setTerminalDetails = (state: Object, terminal: Object = {}) => (
  P.$set('terminalDetails', terminal, state));

const getReferenceTypesByScopeForOptionsSuccess = (state: Object, data: Object) => (
  P.$set('carrierReferenceTypes', data, state)
);

const getSequencesByTypeForOptionsSuccess = (state: Object, data: Object) => (
  P.$set('carrierSequences', data, state)
);

const setActiveTab = (state: Object, tab: string) => (
  P.$set('activeTab', R.or(tab.tabName, tab), state));

const addContractVariableValues = (state: Object, variableValues: Array) => (
  P.$set('variableValues', variableValues, state));

const editContractVariableValues = (state: Object, variableValues: Array) => (
  P.$set('variableValues', variableValues, state));

const deleteContractVariableValues = (state: Object, variableValues: Array) => (
  P.$set('variableValues', variableValues, state));

const getCarrierPortalLoginUsersSuccess = (state: Object, data: Object) => (
  P.$set('carrierPortalLoginUsers', data, state)
);

const deleteCarrierPortalLoginUserSuccess = (state: Object, userGuid: Object) => {
  const { carrierPortalLoginUsers } = state;
  const removedUser = R.find(R.propEq(userGuid, GC.FIELD_GUID), carrierPortalLoginUsers);
  const updatedCarrierPortalLoginUsers = R.without([removedUser], carrierPortalLoginUsers);
  return P.$set('carrierPortalLoginUsers', updatedCarrierPortalLoginUsers, state);
};

const receivedMailListSuccess = (state: Object, data: Object) => (
  P.$set('mailList', data, state)
);

const receivedCarrierOnboardingPackageSuccess = (state: Object, data: Object) => (
  P.$set('sendPackage', data, state)
);

// CUSTOMERS CONTRACT
const createCustomerContractSuccess = (state: Object, data: Object) => (
  P.$add('customersContract', data, state));

const deleteCustomerContractSuccess = (state: Object, guid: string) => (
  P.$drop(`customersContract.${guid}`, state));

const getCustomerContractSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('customerContract', data),
    P.$set('variableValues', R.pathOr([], ['discount', 'variableValues'], data)),
    state,
  )
);

const updateCustomerContractSuccess = (state: Object, { data, index }: Object) => (
  P.$all(
    P.$set('customerContract', null),
    P.$set(
      `customersContract.${index}`,
      R.assoc(
        GC.FIELD_TRANSPORTATION_MODE_DISPLAYED_VALUE,
        R.path([GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], data),
        data,
      ),
    ),
    state,
));

const getCustomerContractAwardedLinesSuccess = (state: Object, data: Object) => (
  P.$set('customerContractAwardedLines', data, state));

const createCustomerContractAwardedLineSuccess = (state: Object, data: Object) => (
  P.$add('customerContractAwardedLines', data, state));

const updateCustomerContractAwardedLineSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`customerContractAwardedLines.${index}`, data, state));

const deleteCustomerContractAwardedLineSuccess = (state: Object, index: number) => (
  P.$drop(`customerContractAwardedLines.${index}`, state));

const getCustomerContractAssessorialsSuccess = (state: Object, data: Array) => (
  P.$set('customerContractAssessorials', data, state));

const createCustomerContractAssessorialSuccess = (state: Object, data: Object) => (
  P.$add('customerContractAssessorials', data, state));

const updateCustomerContractAssessorialSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`customerContractAssessorials.${index}`, data, state));

const deleteCustomerContractAssessorialSuccess = (state: Object, index: string) => (
  P.$drop(`customerContractAssessorials.${index}`, state));
// CUSTOMERS CONTRACT

// additional contacts
const getAdditionalContactListSuccess = (state: Object, data: Object) => {
  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.assoc('index', index, item)),
  )(data);

  return P.$set('additionalContactList', list, state);
};

const createOrUpdateAdditionalContactSuccess = (state: Object, data: Object) => {
  const { additionalContactList } = state;

  const index = R.length(R.values(additionalContactList));
  const additionalContact = R.assoc('index', index, data);

  return P.$set(`additionalContactList.${G.getGuidFromObject(data)}`, additionalContact, state);
};

const removeAdditionalContactSuccess = (state: Object, guid: string) => (
  P.$drop(`additionalContactList.${guid}`, state)
);

// carrier payment term
const removeCarrierPaymentTermSuccess = (state: Object) => P.$set('carrierPaymentTerms', [], state);

const getCarrierPaymentTermListSuccess = (state: Object, data: Object) => P.$set('carrierPaymentTerms', data, state);

const createOrUpdateCarrierPaymentTermSuccess = (state: Object, data: Object) => (
  P.$set('carrierPaymentTerms', R.of(Array, data), state)
);

// carrier integration
const getCarrierIntegrationListSuccess = (state: Object, data: Object) => (
  P.$set('integrationInfoList', data, state)
);

const getCarrierIntegrationAuditListSuccess = (state: Object, data: Object) => (
  P.$set('integrationAuditList', data, state)
);

const sendCarrierListToIntegrationSuccess = (state: Object, data: Object) => P.$set(
  'itemList',
  R.map(
    (entity: Object) => {
      const guid = G.getGuidFromObject(entity);
      const branches = R.pathOr(null, [guid], data);

      if (R.isNil(branches)) return entity;

      return R.assoc(GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME, R.prop(guid, data), entity);
    },
    state.itemList,
  ),
  state,
);

// available carrier integrations
const getAvailableCarrierIntegrationsSuccess = (state: Object, data: Object) => (
  P.$set('carrierIntegrationOptions', data, state)
);

// contract bill to
const getContractBillToByContractTypeSuccess = (state: Object, data: Object) => (
  P.$set('contractBillTo', G.ifElse(G.isNotNilAndNotEmpty(data), R.of(Array, data), []), state)
);

// carrier load board integration
const getLoadBoardIntegrationListSuccess = (state: Object, data: Object) => (
  P.$set('loadBoardIntegrationList', data, state)
);

const getParentBranchGuidsByCurrentBranchGuidSuccess = (state: Object, data: Object) => (
  P.$set('parentBranchGuids', data, state)
);

const getAvailableForCarrierLoadBoardsSuccess = (state: Object, data: Object) => (
  P.$set('availableForCarrierLoadBoards', data, state)
);

// carrier internal dispatch settings
const getCarrierInternalDispatchSettingsSuccess = (state: Object, data: Object) => P.$set(
  'carrierInternalDispatchSettings', G.ifElse(G.isNotNilAndNotEmpty(data), R.of(Array, data), []), state,
);

const createOrUpdateCarrierInternalDispatchSettingsSuccess = (state: Object, data: Object) => (
  P.$set('carrierInternalDispatchSettings', R.of(Array, data), state)
);

const removeCarrierInternalDispatchSettingsSuccess = (state: Object) => P.$set(
  'carrierInternalDispatchSettings', [], state,
);

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.setActiveTab]: setActiveTab,
  [A.setActivePage]: setActivePage,
  [A.getCarrierSuccess]: getCarrierSuccess,
  [A.setTerminalDetails]: setTerminalDetails,
  [A.getCarrierRefSuccess]: getCarrierRefSuccess,
  [A.updateCarrierSuccess]: updateCarrierSuccess,
  [A.receivedSafetySuccess]: receivedSafetySuccess,
  [A.getCarrierDocsSuccess]: getCarrierDocsSuccess,
  [A.updateTerminalSuccess]: updateTerminalSuccess,
  [A.toggleCarrierFormGroup]: toggleCarrierFormGroup,
  [A.toggleCarrierFormGroup]: toggleCarrierFormGroup,
  [A.receivedMailListSuccess]: receivedMailListSuccess,
  [A.createCarrierDocSuccess]: createCarrierDocSuccess,
  [A.updateCarrierRefSuccess]: updateCarrierRefSuccess,
  [A.updateCarrierDocSuccess]: updateCarrierDocSuccess,
  [A.deleteCarrierRefSuccess]: deleteCarrierRefSuccess,
  [A.createCarrierRefSuccess]: createCarrierRefSuccess,
  [A.deleteCarrierDocSuccess]: deleteCarrierDocSuccess,
  [A.createCarrierRefSuccess]: createCarrierRefSuccess,
  [A.createCarrierDocSuccess]: createCarrierDocSuccess,
  [A.getCarrierFinanceSuccess]: getCarrierFinanceSuccess,
  [A.getCarrierConfigsSuccess]: getCarrierConfigsSuccess,
  [A.getGeneralConfigsSuccess]: getGeneralConfigsSuccess,
  [A.getTerminalFinanceSuccess]: getTerminalFinanceSuccess,
  [A.getTemplateConfigsSuccess]: getTemplateConfigsSuccess,
  [A.getCarrierContractSuccess]: getCarrierContractSuccess,
  [A.addContractVariableValues]: addContractVariableValues,
  [A.selectCarrierContractRate]: selectCarrierContractRate,
  [A.getCustomerContractSuccess]: getCustomerContractSuccess,
  [A.getCarrierInsuranceSuccess]: getCarrierInsuranceSuccess,
  [A.editContractVariableValues]: editContractVariableValues,
  [A.getCarrierTerminalsSuccess]: getCarrierTerminalsSuccess,
  [A.getTerminalContactsSuccess]: getTerminalContactsSuccess,
  [A.getCarrierContractsSuccess]: getCarrierContractsSuccess,
  [A.updateCarrierFinanceSuccess]: updateCarrierFinanceSuccess,
  [A.getCustomersContractSuccess]: getCustomersContractSuccess,
  [A.deleteContractVariableValues]: deleteContractVariableValues,
  [A.createCarrierTerminalSuccess]: createCarrierTerminalSuccess,
  [A.createTerminalContactSuccess]: createTerminalContactSuccess,
  [A.deleteCarrierTerminalSuccess]: deleteCarrierTerminalSuccess,
  [A.createCarrierContractSuccess]: createCarrierContractSuccess,
  [A.createTerminalContactSuccess]: createTerminalContactSuccess,
  [A.getDispatchConfigListSuccess]: getDispatchConfigListSuccess,
  [A.deleteTerminalContactSuccess]: deleteTerminalContactSuccess,
  [A.updateTerminalContactSuccess]: updateTerminalContactSuccess,
  [A.updateCarrierContractSuccess]: updateCarrierContractSuccess,
  [A.deleteCarrierContractSuccess]: deleteCarrierContractSuccess,
  [A.updateCustomerContractSuccess]: updateCustomerContractSuccess,
  [A.createCustomerContractSuccess]: createCustomerContractSuccess,
  [A.deleteCustomerContractSuccess]: deleteCustomerContractSuccess,
  [A.getCarrierAssessorialsSuccess]: getCarrierAssessorialsSuccess,
  [A.deleteCarrierInsuranceSuccess]: deleteCarrierInsuranceSuccess,
  [A.updateCarrierInsuranceSuccess]: updateCarrierInsuranceSuccess,
  [A.createCarrierInsuranceSuccess]: createCarrierInsuranceSuccess,
  [A.getCarrierAssocCompanySuccess]: getCarrierAssocCompanySuccess,
  [A.getCarrierCertificatesSuccess]: getCarrierCertificatesSuccess,
  [A.getCarrierPayLocationsSuccess]: getCarrierPayLocationsSuccess,
  [A.createCarrierInsuranceSuccess]: createCarrierInsuranceSuccess,
  [A.getCarrierContractRatesSuccess]: getCarrierContractRatesSuccess,
  [A.getTerminalPayLocationsSuccess]: getTerminalPayLocationsSuccess,
  [A.deleteCarrierAssessorialSuccess]: deleteCarrierAssessorialSuccess,
  [A.updateCarrierCertificateSuccess]: updateCarrierCertificateSuccess,
  [A.updateCarrierPayLocationSuccess]: updateCarrierPayLocationSuccess,
  [A.deleteCarrierCertificateSuccess]: deleteCarrierCertificateSuccess,
  [A.deleteCarrierPayLocationSuccess]: deleteCarrierPayLocationSuccess,
  [A.createCarrierAssessorialSuccess]: createCarrierAssessorialSuccess,
  [A.createCarrierPayLocationSuccess]: createCarrierPayLocationSuccess,
  [A.createCarrierCertificateSuccess]: createCarrierCertificateSuccess,
  [A.deleteCarrierCertificateSuccess]: deleteCarrierCertificateSuccess,
  [A.deleteCarrierPayLocationSuccess]: deleteCarrierPayLocationSuccess,
  [A.updateCarrierAssessorialSuccess]: updateCarrierAssessorialSuccess,
  [A.getAccessorialConfigListSuccess]: getAccessorialConfigListSuccess,
  [A.createTerminalPayLocationSuccess]: createTerminalPayLocationSuccess,
  [A.createCarrierAssocCompanySuccess]: createCarrierAssocCompanySuccess,
  [A.updateCarrierAssocCompanySuccess]: updateCarrierAssocCompanySuccess,
  [A.createTerminalPayLocationSuccess]: createTerminalPayLocationSuccess,
  [A.updateTerminalPayLocationSuccess]: updateTerminalPayLocationSuccess,
  [A.deleteCarrierAssocCompanySuccess]: deleteCarrierAssocCompanySuccess,
  [A.deleteTerminalPayLocationSuccess]: deleteTerminalPayLocationSuccess,
  [A.updateCarrierContractRateSuccess]: updateCarrierContractRateSuccess,
  [A.createCarrierAssocCompanySuccess]: createCarrierAssocCompanySuccess,
  [A.deleteCarrierContractRateSuccess]: deleteCarrierContractRateSuccess,
  [A.createCarrierContractRateSuccess]: createCarrierContractRateSuccess,
  [A.getCarrierPortalLoginUsersSuccess]: getCarrierPortalLoginUsersSuccess,
  [A.deleteCarrierPortalLoginUserSuccess]: deleteCarrierPortalLoginUserSuccess,
  [A.getSequencesByTypeForOptionsSuccess]: getSequencesByTypeForOptionsSuccess,
  [A.sendCarrierListToIntegrationSuccess]: sendCarrierListToIntegrationSuccess,
  [A.resetCarrierContractListAndPagination]: resetCarrierContractListAndPagination,
  [A.getCarrierContractAwardedLinesSuccess]: getCarrierContractAwardedLinesSuccess,
  [A.getCarrierContractAssessorialsSuccess]: getCarrierContractAssessorialsSuccess,
  [A.getCustomerContractAwardedLinesSuccess]: getCustomerContractAwardedLinesSuccess,
  [A.getCustomerContractAssessorialsSuccess]: getCustomerContractAssessorialsSuccess,
  [A.createCarrierContractAwardedLineSuccess]: createCarrierContractAwardedLineSuccess,
  [A.receivedCarrierOnboardingPackageSuccess]: receivedCarrierOnboardingPackageSuccess,
  [A.updateCarrierContractAwardedLineSuccess]: updateCarrierContractAwardedLineSuccess,
  [A.deleteCarrierContractAwardedLineSuccess]: deleteCarrierContractAwardedLineSuccess,
  [A.createCarrierContractAssessorialSuccess]: createCarrierContractAssessorialSuccess,
  [A.updateCarrierContractAssessorialSuccess]: updateCarrierContractAssessorialSuccess,
  [A.deleteCarrierContractAssessorialSuccess]: deleteCarrierContractAssessorialSuccess,
  [A.createCustomerContractAssessorialSuccess]: createCustomerContractAssessorialSuccess,
  [A.updateCustomerContractAssessorialSuccess]: updateCustomerContractAssessorialSuccess,
  [A.deleteCustomerContractAssessorialSuccess]: deleteCustomerContractAssessorialSuccess,
  [A.createCustomerContractAwardedLineSuccess]: createCustomerContractAwardedLineSuccess,
  [A.updateCustomerContractAwardedLineSuccess]: updateCustomerContractAwardedLineSuccess,
  [A.deleteCustomerContractAwardedLineSuccess]: deleteCustomerContractAwardedLineSuccess,
  [A.getReferenceTypesByScopeForOptionsSuccess]: getReferenceTypesByScopeForOptionsSuccess,
  // additional contacts
  [A.removeAdditionalContactSuccess]: removeAdditionalContactSuccess,
  [A.getAdditionalContactListSuccess]: getAdditionalContactListSuccess,
  [A.createOrUpdateAdditionalContactSuccess]: createOrUpdateAdditionalContactSuccess,
  // carrier integration
  [A.getCarrierIntegrationListSuccess]: getCarrierIntegrationListSuccess,
  [A.getCarrierIntegrationAuditListSuccess]: getCarrierIntegrationAuditListSuccess,
  // available carrier integrations
  [A.getAvailableCarrierIntegrationsSuccess]: getAvailableCarrierIntegrationsSuccess,
  // contract bill to
  [A.getContractBillToByContractTypeSuccess]: getContractBillToByContractTypeSuccess,
  // load board integration
  [A.getLoadBoardIntegrationListSuccess]: getLoadBoardIntegrationListSuccess,
  [A.getAvailableForCarrierLoadBoardsSuccess]: getAvailableForCarrierLoadBoardsSuccess,
  [A.getParentBranchGuidsByCurrentBranchGuidSuccess]: getParentBranchGuidsByCurrentBranchGuidSuccess,
  // carrier payment term
  [A.removeCarrierPaymentTermSuccess]: removeCarrierPaymentTermSuccess,
  [A.getCarrierPaymentTermListSuccess]: getCarrierPaymentTermListSuccess,
  [A.createOrUpdateCarrierPaymentTermSuccess]: createOrUpdateCarrierPaymentTermSuccess,
  // carrier internal dispatch settings
  [A.getCarrierInternalDispatchSettingsSuccess]: getCarrierInternalDispatchSettingsSuccess,
  [A.removeCarrierInternalDispatchSettingsSuccess]: removeCarrierInternalDispatchSettingsSuccess,
  [A.createOrUpdateCarrierInternalDispatchSettingsSuccess]: createOrUpdateCarrierInternalDispatchSettingsSuccess,
}, initialState);
