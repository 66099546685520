import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../components/confirm';
// components
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../hocs';
// features
import { CreateContractForm } from './forms/create-contract-form';
// utilities
import endpointsMap from '../../utilities/endpoints';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const enhanceContractFormWithAsyncRequest = compose(
  connect(null, { openLoader, closeLoader, openModal, closeModal }),
  withProps(() => {
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const asyncOptions = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    return {
      asyncOptions,
      asyncEndpoint: endpointsMap.carrierListForContract,
    };
  }),
  withAsyncInitialDataOnDidMount,
  withProps(({ asyncInitialData }: Object) => {
    const data = R.pathOr([], ['data'], asyncInitialData);

    return {
      optionsForSelect: {
        carrierOptions: G.mapNameGuidObjectPropsToLabelValueObject(data),
        transportationMode: R.indexBy(R.prop(GC.FIELD_GUID), data),
      },
    };
  }),
  pure,
);

const EnhancedCreateContractForm = enhanceContractFormWithAsyncRequest(CreateContractForm);

export const withAddContractAsync = ({ pageType, contractType }: Object) => compose(
  withHandlers({
    handleClickAddContractAsync: (props: Object) => () => {
      const {
        openModal,
        handleSendCreateCarrierContractSuccess,
        handleSendCreateCustomerContractSuccess,
        resetListAndPaginationAndGetItemsRequest,
      } = props;

      const callbackAction = G.ifElse(
        R.or(R.equals(pageType, 'carrierContracts'), R.equals(pageType, 'customerContracts')),
        resetListAndPaginationAndGetItemsRequest,
        G.ifElse(
          G.isContractTypeCarrier(contractType),
          handleSendCreateCarrierContractSuccess,
          handleSendCreateCustomerContractSuccess,
        ),
      );

      const initialValues = {
        [GC.FIELD_CURRENCY]: G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
      };

      const modalContext = (
        <EnhancedCreateContractForm
          pageType={pageType}
          contractType={contractType}
          initialValues={initialValues}
          callbackAction={callbackAction}
          asyncEndpoint={endpointsMap.cloTemplateListAvailable}
        />
      );

      const modal = {
        p: 15,
        component: modalContext,
        options: {
          width: 330,
          height: 'auto',
          title: G.getWindowLocale('titles:add:contract', 'Add Contract'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);


export const contractListEnhance = ({ contractType }: Object) => compose(
  withHandlers({
    handleClickAddContract: (props: Object) => () => {
      const {
        openModal,
        openLoader,
        closeModal,
        roleOptions,
        closeLoader,
        carrierConfigs,
        selectedTrMode,
        handleSendCreateCarrierContractSuccess,
        handleSendCreateCustomerContractSuccess,
      } = props;

      const options = {
        roleOptions,
        transportationMode: R.filter(
          ({ value }: Object) => selectedTrMode.includes(value),
          carrierConfigs.transportationMode),
      };

      const callbackAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        handleSendCreateCarrierContractSuccess,
        handleSendCreateCustomerContractSuccess,
      );

      const initialValues = {
        [GC.FIELD_CURRENCY]: R.path(['carrierConfigs', 'defaultBranchCurrency'], props),
      };

      const modalContext = (
        <CreateContractForm
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          optionsForSelect={options}
          contractType={contractType}
          initialValues={initialValues}
          callbackAction={callbackAction}
          carrierGuid={props.initialValues.guid}
        />
      );

      const modal = {
        p: 15,
        component: modalContext,
        options: {
          width: 330,
          height: 'auto',
          title: G.getWindowLocale('titles:add:contract', 'Add Contract'),
        },
      };

      openModal(modal);
    },
    handleDeleteContract: (props: Object) => (entity: Object) => {
      const {
        openModal,
        closeModal,
        deleteCarrierContractRequest,
        deleteCustomerContractRequest,
      } = props;

      const confirmationContent = (
        <ConfirmComponent
          textLocale={G.getWindowLocale('messages:carrier:delete-contract', 'Are you sure you want delete contract ?')}
        />
      );

      const deleteAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        deleteCarrierContractRequest,
        deleteCustomerContractRequest,
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteAction(entity.guid);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleEditContract: (props: Object) => (contract: Object) => {
      const {
        setActiveTab,
        carrierIntegrationOptions,
        getCarrierContractRequest,
        getCustomerContractRequest,
        getCarrierContractRatesRequest,
        getCarrierContractAwardedLinesRequest,
        getCarrierContractAssessorialsRequest,
        resetCarrierContractListAndPagination,
        getContractBillToByContractTypeRequest,
        getAvailableCarrierIntegrationsRequest,
        getCustomerContractAwardedLinesRequest,
        getCustomerContractAssessorialsRequest,
      } = props;

      const tabName = G.ifElse(
        G.isContractTypeCarrier(contractType),
        'carrierContract',
        'customerContract',
      );

      const contractGuid = G.getGuidFromObject(contract);

      const tabAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        () => {
          resetCarrierContractListAndPagination();
          getCarrierContractRequest(contractGuid);
          getCarrierContractRatesRequest({ contractGuid });
          getCarrierContractAssessorialsRequest(contractGuid);
          getCarrierContractAwardedLinesRequest(contractGuid);
          getContractBillToByContractTypeRequest({
            [GC.FIELD_CARRIER_CONTRACT_GUID]: contractGuid,
          });
          if (G.isNilOrEmpty(carrierIntegrationOptions)) {
            getAvailableCarrierIntegrationsRequest(R.path(['formValues', GC.BRANCH_GUID], props));
          }
        },
        () => {
          getCustomerContractRequest(contractGuid);
          getCustomerContractAwardedLinesRequest(contractGuid);
          getCustomerContractAssessorialsRequest(contractGuid);
          getContractBillToByContractTypeRequest({
            [GC.FIELD_CUSTOMER_CONTRACT_GUID]: contractGuid,
          });
        },
      );
      setActiveTab({ tabName, tabAction });
    },
  }),
  pure,
);
