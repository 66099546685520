import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../../components/modal/actions';
import { makeSelectLoader } from '../../../../components/loader/selectors';
// forms
import { DefaultAccessorialForm } from '../../../../forms/forms/accessorial-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { getTelGlobalSearchOptions } from '../../../../helpers/options';
// feature configs
import { defaultPageConfigEnhancer } from '../../hocs';
import ConfigComponent from '../../components/config-component';
import {
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  removeDefaultCarrierAccessorialRequest,
  createOrUpdateDefaultCarrierAccessorialRequest,
} from '../../actions';
import {
  makeSelectFormValues,
  makeSelectConfigOptions,
  makeSelectConfigInitialValues,
  makeSelectConfigGuidsToItemsMap,
  makeSelectConfigInheritedValues,
  makeSelectDefaultCarrierAccessorialList,
} from '../../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(GC.TEL_CONFIG_GROUP),
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      const {
        openModal,
        closeModal,
        configOptions,
        handleClickEndLabel,
        createOrUpdateDefaultCarrierAccessorialRequest,
      } = props;

      if (R.equals(configName, GC.CUSTOM_DEFAULT_CARRIER_ACCESSORIAL_CONFIG)) {
        const component = (
          <DefaultAccessorialForm
            closeModal={closeModal}
            accessorialConfigOptions={configOptions.noFuelAccessorials}
            accessorialConfigList={configOptions.accessorialConfigList}
            submitAction={createOrUpdateDefaultCarrierAccessorialRequest}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            height: 'auto',
            title: G.getAddTitle(['titles:carrier-additional-charge', 'Carrier Additional Charge']),
          },
        };

        return openModal(modal);
      }

      return handleClickEndLabel(configName, popupTitle);
    },
    handleEditDefaultCarrierAccessorial: (props: Object) => (initialValues: Object) => {
      const {
        openModal,
        closeModal,
        configOptions,
        createOrUpdateDefaultCarrierAccessorialRequest,
      } = props;

      const component = (
        <DefaultAccessorialForm
          closeModal={closeModal}
          initialValues={initialValues}
          accessorialConfigOptions={configOptions.noFuelAccessorials}
          accessorialConfigList={configOptions.accessorialConfigList}
          submitAction={createOrUpdateDefaultCarrierAccessorialRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getEditTitle(['titles:carrier-additional-charge', 'Carrier Additional Charge']),
        },
      };

      return openModal(modal);
    },
    handleRemoveDefaultCarrierAccessorial: ({ removeDefaultCarrierAccessorialRequest }: Object) => (guid: string) =>
      removeDefaultCarrierAccessorialRequest(guid),
  }),
  pure,
);

const getConfigOptions = ({ formValues, configOptions }: Object) => {
  const usedGlobalSearchTypes = R.pathOr([], [GC.TEL_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES], formValues);
  const defaultTelGlobalSearchOptions = R.filter(({ value }: Object) => G.isAnyTrue(
    G.isNilOrEmpty(usedGlobalSearchTypes),
    R.includes(value, usedGlobalSearchTypes),
    R.equals(value, GC.GLOBAL_SEARCH_TYPE_ALL),
    R.includes(GC.GLOBAL_SEARCH_TYPE_ALL, usedGlobalSearchTypes),
  ), getTelGlobalSearchOptions());

  return R.assoc('defaultTelGlobalSearchOptions', defaultTelGlobalSearchOptions, configOptions);
};

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:tel'
    groupSettings={GC.TEL_CONFIG_GROUP}
    configOptions={getConfigOptions(props)}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  formValues: makeSelectFormValues()(state),
  configOptions: makeSelectConfigOptions(state, GC.TEL_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.TEL_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.TEL_CONFIG_GROUP),
  guidsToItemsMap: makeSelectConfigGuidsToItemsMap(state, GC.TEL_CONFIG_GROUP),
  defaultCarrierAccessorialList: makeSelectDefaultCarrierAccessorialList()(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  removeDefaultCarrierAccessorialRequest,
  createOrUpdateDefaultCarrierAccessorialRequest,
})(enhance(ConfigPageComponent));
