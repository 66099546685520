import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../../helpers';
// forms
import { Fieldset2 } from '../../../../../forms';
// feature configs
import { getExpenseTypeFields, defaultExpenseTypeFields, expenseTypeValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: expenseTypeValidationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultExpenseTypeFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      submitAction(values);
    },
  }),
  pure,
);

const ExpenseTypeForm = (props: Object) => {
  const { isEdit, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 15 }}
        fields={getExpenseTypeFields(isEdit)}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(ExpenseTypeForm);
