import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature geo-fencing-zone
import * as A from './actions';
//////////////////////////////////////////////////

function* getGeoFencingZoneListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.geoFencingZoneList,
      parentSagaName: 'getGeoFencingZoneListRequest',
      successAction: A.receivedGeoFencingZoneListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getGeoFencingZoneListRequest exception');
  }
}

function* createGeoFencingZoneRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNilOrEmpty(payload.version),
      'post',
      'put',
    );

    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.geoFencingZone,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'createGeoFencingZoneRequest',
      successAction: A.receivedGeoFencingZoneSuccess,
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createGeoFencingZoneRequest exception');
  }
}

function* deleteGeoFencingZoneRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.geoFencingZoneByGuid(payload);

    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.deleteGeoFencingZoneSuccess,
      parentSagaName: 'deleteGeoFencingZoneRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'deleteGeoFencingZoneRequest exception');
  }
}

function* createGeoFencingZoneExportRequest() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      resType: 'arraybuffer',
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.exportGeoFencingZone, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createCarrierRatePriceExportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createCarrierRatePriceExportRequest exception');
  }
}

function* handleVisitGeoFencingZoneSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_RATING_GEO_FENCING_ZONE_PAGE);

    yield put(openLoader());

    yield call(getGeoFencingZoneListRequest);

    yield put(closeLoader());

    break;
  }
}

function* geoFencingZoneWatcherSaga() {
  yield takeLatest(A.deleteGeoFencingZoneRequest, deleteGeoFencingZoneRequest);
  yield takeLatest(A.createGeoFencingZoneRequest, createGeoFencingZoneRequest);
  yield takeLatest(GC.VISIT_RATING_GEO_FENCING_ZONE_PAGE, handleVisitGeoFencingZoneSaga);
  yield takeLatest(A.createGeoFencingZoneExportRequest, createGeoFencingZoneExportRequest);
}

export default geoFencingZoneWatcherSaga;
