import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// crossdock clos
export const selectCrossDockClo = createAction('selectCrossDockClo');
export const toggleCrossDockClo = createAction('toggleCrossDockClo');
export const setCrossDockCloList = createAction('setCrossDockCloList');
export const uncheckCrossDockClo = createAction('uncheckCrossDockClo');
export const uncheckCrossDockClos = createAction('uncheckCrossDockClos');
export const getCrossDockCloNextPage = createAction('getCrossDockCloNextPage');
export const toggleCrossDockCloDetails = createAction('toggleCrossDockCloDetails');
export const selectCrossDockCloRequest = createAction('selectCrossDockCloRequest');
export const selectCrossDockCloSuccess = createAction('selectCrossDockCloSuccess');
export const setCrossDockCloListLoading = createAction('setCrossDockCloListLoading');
export const setSelectedCrossDockCloList = createAction('setSelectedCrossDockCloList');
export const resetCrossDockCloPagination = createAction('resetCrossDockCloPagination');
export const setCurrentCrossDockCloReport = createAction('setCurrentCrossDockCloReport');
export const cleanCrossDockCloQuickFilter = createAction('cleanCrossDockCloQuickFilter');
export const setCrossDockCloTableTitleSort = createAction('setCrossDockCloTableTitleSort');
export const setCrossDockCloTableTitleFilter = createAction('setCrossDockCloTableTitleFilter');
export const setCrossDockCloQuickFilterParams = createAction('setCrossDockCloQuickFilterParams');
export const resetCrossDockCloListAndPagination = createAction('resetCrossDockCloListAndPagination');
export const refreshCrossDockCloListWithRouteClos = createAction('refreshCrossDockCloListWithRouteClos');
//
export const editItem = createAction('editItem');
export const selectClo = createAction('selectClo');
export const selectTel = createAction('selectTel');
export const toggleClo = createAction('toggleClo');
export const toggleTel = createAction('toggleTel');
export const setCloList = createAction('setCloList');
export const setTelList = createAction('setTelList');
export const cleanDrafts = createAction('cleanDrafts');
export const selectDraft = createAction('selectDraft');
export const toggleDraft = createAction('toggleDraft');
export const deleteDraft = createAction('deleteDraft');
export const cleanBuilder = createAction('cleanBuilder');
export const cleanTelRate = createAction('cleanTelRate');
export const setTelDetails = createAction('setTelDetails');
export const sortTelEvents = createAction('sortTelEvents');
export const setRouteErrors = createAction('setRouteErrors');
export const getCloNextPage = createAction('getCloNextPage');
export const getTelNextPage = createAction('getTelNextPage');
export const addNewItemOnUI = createAction('addNewItemOnUI');
export const setPlannerMode = createAction('setPlannerMode');
export const addItemsForDrop = createAction('addItemsForDrop');
export const getRouteBuilder = createAction('getRouteBuilder');
export const setCurrentRoute = createAction('setCurrentRoute');
export const addTelToPlanner = createAction('addTelToPlanner');
export const updateContainer = createAction('updateContainer');
export const saveRouteRequest = createAction('saveRouteRequest');
export const toggleCloDetails = createAction('toggleCloDetails');
export const toggleTelDetails = createAction('toggleTelDetails');
export const prependTelToList = createAction('prependTelToList');
export const addTrailersToStop = createAction('addTrailersToStop');
export const setOpenedFromPage = createAction('setOpenedFromPage');
export const setAvailableItems = createAction('setAvailableItems');
export const setCloListLoading = createAction('setCloListLoading');
export const setTelListLoading = createAction('setTelListLoading');
export const addItemsForPickup = createAction('addItemsForPickup');
export const setInboundTelList = createAction('setInboundTelList');
export const setSelectedCloList = createAction('setSelectedCloList');
export const setSelectedTelList = createAction('setSelectedTelList');
export const resetCloPagination = createAction('resetCloPagination');
export const resetTelPagination = createAction('resetTelPagination');
export const setBottomTableMode = createAction('setBottomTableMode');
export const removeItemFromStop = createAction('removeItemFromStop');
export const setOutboundTelList = createAction('setOutboundTelList');
export const getReportTelByGuid = createAction('getReportTelByGuid');
export const setInitialRouteGuid = createAction('setInitialRouteGuid');
export const setCurrentCloReport = createAction('setCurrentCloReport');
export const getDraftListRequest = createAction('getDraftListRequest');
export const getDraftListSuccess = createAction('getDraftListSuccess');
export const setDraftListLoading = createAction('setDraftListLoading');
export const setCurrentTelReport = createAction('setCurrentTelReport');
export const cleanCloQuickFilter = createAction('cleanCloQuickFilter');
export const cleanTelQuickFilter = createAction('cleanTelQuickFilter');
export const addItemsClosForDrop = createAction('addItemsClosForDrop');
export const selectTelOnAllLists = createAction('selectTelOnAllLists');
export const setInboundTerminals = createAction('setInboundTerminals');
export const setEditTemplateRoute = createAction('setEditTemplateRoute');
export const getCloDetailsRequest = createAction('getCloDetailsRequest');
export const getCloDetailsSuccess = createAction('getCloDetailsSuccess');
export const validateRouteRequest = createAction('validateRouteRequest');
export const removeTelFromPlanner = createAction('removeTelFromPlanner');
export const setOutboundTerminals = createAction('setOutboundTerminals');
export const setCloTableTitleSort = createAction('setCloTableTitleSort');
export const setTelTableTitleSort = createAction('setTelTableTitleSort');
export const removeTrailerFromStop = createAction('removeTrailerFromStop');
export const getInboundTelNextPage = createAction('getInboundTelNextPage');
export const saveDraftRouteRequest = createAction('saveDraftRouteRequest');
export const addItemsClosForPickup = createAction('addItemsClosForPickup');
export const removeCloItemFromStop = createAction('removeCloItemFromStop');
export const addNewContainerToStop = createAction('addNewContainerToStop');
export const saveAsTemplateRequest = createAction('saveAsTemplateRequest');
export const setPreviousRouteState = createAction('setPreviousRouteState');
export const setCloTableTitleFilter = createAction('setCloTableTitleFilter');
export const setTelTableTitleFilter = createAction('setTelTableTitleFilter');
export const setAvailableCloReports = createAction('setAvailableCloReports');
export const setAvailableTelReports = createAction('setAvailableTelReports');
export const selectCloForTelRequest = createAction('selectCloForTelRequest');
export const selectCloForTelSuccess = createAction('selectCloForTelSuccess');
export const createCloReportRequest = createAction('createCloReportRequest');
export const updateCloReportRequest = createAction('updateCloReportRequest');
export const createTelReportRequest = createAction('createTelReportRequest');
export const updateTelReportRequest = createAction('updateTelReportRequest');
export const setInitialCurrentRoute = createAction('setInitialCurrentRoute');
export const getOutboundTelNextPage = createAction('getOutboundTelNextPage');
export const dispatchTelRateRequest = createAction('dispatchTelRateRequest');
export const setAvailableContainers = createAction('setAvailableContainers');
export const setBranchGuidForRequest = createAction('setBranchGuidForRequest');
export const setCloQuickFilterParams = createAction('setCloQuickFilterParams');
export const setTelQuickFilterParams = createAction('setTelQuickFilterParams');
export const addNewTelToRouteBuilder = createAction('addNewTelToRouteBuilder');
export const addExistedTerminalToTel = createAction('addExistedTerminalToTel');
export const removeContainerFromStop = createAction('removeContainerFromStop');
export const addDropContainersToStop = createAction('addDropContainersToStop');
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
export const setInboundTelListLoading = createAction('setInboundTelListLoading');
export const saveMultipleCarrierRates = createAction('saveMultipleCarrierRates');
export const addPickupContainersToStop = createAction('addPickupContainersToStop');
export const resetCloListAndPagination = createAction('resetCloListAndPagination');
export const resetTelListAndPagination = createAction('resetTelListAndPagination');
export const removeTelFromRouteBuilder = createAction('removeTelFromRouteBuilder');
export const uncheckCloAndRemoveEvents = createAction('uncheckCloAndRemoveEvents');
export const setOutboundTelListLoading = createAction('setOutboundTelListLoading');
export const openDispatchPlannerRequest = createAction('openDispatchPlannerRequest');
export const getAvailableReportsRequest = createAction('getAvailableReportsRequest');
export const setCurrentInboundTelReport = createAction('setCurrentInboundTelReport');
export const addTelToRouteBuilderByGuid = createAction('addTelToRouteBuilderByGuid');
export const setInboundTelTableTitleSort = createAction('setInboundTelTableTitleSort');
export const resetAllListsAndPaginations = createAction('resetAllListsAndPaginations');
export const setRouteBuilderInitialState = createAction('setRouteBuilderInitialState');
export const refreshCloListWithRouteClos = createAction('refreshCloListWithRouteClos');
export const setCurrentRouteInitialState = createAction('setCurrentRouteInitialState');
export const addNewStopToRouteBuilderTel = createAction('addNewStopToRouteBuilderTel');
export const addNewRateToRouteBuilderTel = createAction('addNewRateToRouteBuilderTel');
export const setCurrentOutboundTelReport = createAction('setCurrentOutboundTelReport');
export const setOutboundTelTableTitleSort = createAction('setOutboundTelTableTitleSort');
export const getCurrentRouteByGuidRequest = createAction('getCurrentRouteByGuidRequest');
export const getAvailableCloReportSuccess = createAction('getAvailableCloReportSuccess');
export const setCurrentTelReportWithRoute = createAction('setCurrentTelReportWithRoute');
export const addTerminalToRouteBuilderTel = createAction('addTerminalToRouteBuilderTel');
export const getCrossDockLocationsSuccess = createAction('getCrossDockLocationsSuccess');
export const setInboundTelTableTitleFilter = createAction('setInboundTelTableTitleFilter');
export const changeDefaultCloReportRequest = createAction('changeDefaultCloReportRequest');
export const changeDefaultTelReportRequest = createAction('changeDefaultTelReportRequest');
export const removeStopFromRouteBuilderTel = createAction('removeStopFromRouteBuilderTel');
export const dispatchCarrierTelRateRequest = createAction('dispatchCarrierTelRateRequest');
export const setOutboundTelTableTitleFilter = createAction('setOutboundTelTableTitleFilter');
export const recalculateTelDistancesRequest = createAction('recalculateTelDistancesRequest');
export const recalculateTelDistancesSuccess = createAction('recalculateTelDistancesSuccess');
export const addNewCloStopToRouteBuilderTel = createAction('addNewCloStopToRouteBuilderTel');
export const getCurrentRouteByTelGuidRequest = createAction('getCurrentRouteByTelGuidRequest');
export const getDraftRouteByDraftGuidRequest = createAction('getDraftRouteByDraftGuidRequest');
export const resetInboundTelListAndPagination = createAction('resetInboundTelListAndPagination');
export const resetOutboundTelListAndPagination = createAction('resetOutboundTelListAndPagination');
export const updateStopOnRouteBuilderTelRequest = createAction('updateStopOnRouteBuilderTelRequest');
export const updateStopOnRouteBuilderTelSuccess = createAction('updateStopOnRouteBuilderTelSuccess');
export const recalculateTelDistancesWithMoveStopRequest = createAction('recalculateTelDistancesWithMoveStopRequest');
export const recalculateTelDistancesWithMoveItemSuccess = createAction('recalculateTelDistancesWithMoveItemSuccess');
