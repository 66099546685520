
import React from 'react';
// features
import Audit2 from '../../audit2';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const Component = ({ primaryObjectGuid }: Object) => (
  <Audit2
    useFullScreen={true}
    requestOptions={{
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_CARRIER,
      [GC.FIELD_OBJECT_GUID]: primaryObjectGuid,
    }}
  />
);

export const auditSettings = {
  Component,
  groupName: 'bidHistory',
};
