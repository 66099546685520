export const SPLITTER = '::';
export const FIELD_PRICE = 'PRICE';
export const FIELD_PARAMS = 'params';
export const BRANCH_TYPE_NAME_MST = 'MST';
export const BRANCH_TYPE_NAME_BRK = 'BRK';
export const BRANCH_TYPE_NAME_CST = 'CST';
export const BRANCH_TYPE_NAME_DVS = 'DVS';
export const BRANCH_TYPE_NAME_TRM = 'TRM';
export const BRANCH_TYPE_NAME_WHS = 'WHS';
export const ADD_BRANCH_FORM = 'ADD_BRANCH_FORM';
export const EDIT_BRANCH_FORM = 'EDIT_BRANCH_FORM';
export const BRANCH_SETTING_FORM_SECTION = 'BranchSetting';
export const COMPANY_DETAILS_FORM_SECTION = 'CompanyDetails';
export const ROOT_BRANCH_GUID = 'd2523b3c-e0e4-42e6-acc2-0292a0acda97';
export const DNS_PREFIX_REGEXP = /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$/;
