import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectIftaReportStore = (state: Object) => state.iftaReport;

const makeSelectItemList = () => createSelector(
  selectIftaReportStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.path(['itemList']),
  ),
);

const makeSelectAdditionalFilters = () => createSelector(
  selectIftaReportStore,
  ({ additionalFilters }: Object) => additionalFilters,
);

const makesSelectTotalCount = () => createSelector(
  selectIftaReportStore,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectPagination = () => createSelector(
  selectIftaReportStore,
  ({ pagination }: Object) => pagination,
);

const makeSelectTruckGuid = () => createSelector(
  selectIftaReportStore,
  ({ truckGuid }: Object) => truckGuid,
);

const makeSelectListLoading = () => createSelector(
  selectIftaReportStore,
  ({ loading }: Object) => loading,
);

const makeSelectAvailableReports = () => createSelector(
  selectIftaReportStore,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  selectIftaReportStore,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  selectIftaReportStore,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectReportStatus = () => createSelector(
  selectIftaReportStore,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectTitleSortValues = () => createSelector(
  selectIftaReportStore,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectTableTitleFilters = () => createSelector(
  selectIftaReportStore,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

export {
  makeSelectItemList,
  makeSelectTruckGuid,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectListLoading,
  makesSelectTotalCount,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectAdditionalFilters,
};
