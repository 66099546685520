import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier
import AdditionalContactForm from './additional-contact-form';
import { makeSelectAdditionalContactList } from '../selectors';
import { CARRIER_GROUP_NAME_ADDITIONAL_CONTACTS } from '../constants';
import { additionalContactSettings } from '../settings/column-settings';
import { removeAdditionalContactRequest, createOrUpdateAdditionalContactRequest } from '../actions';
///////////////////////////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  additionalContactList: makeSelectAdditionalContactList(state),
});

const enhance = compose(
  connect(
    mapStateToProps,
    { removeAdditionalContactRequest, createOrUpdateAdditionalContactRequest },
  ),
  withHandlers({
    handleAddAdditionalContact: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        carrierGuid,
        createOrUpdateAdditionalContactRequest } = props;

      const submitAction = (values: Object) =>
        createOrUpdateAdditionalContactRequest(R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, values));
      const component = (
        <AdditionalContactForm closeModal={closeModal} submitAction={submitAction} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: '630px',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:add-additional-contact', 'Add Additional Contact'),
        },
      };
      openModal(modal);
    },
    handleEditAdditionalContact: (props: Object) => (initialValues: Object) => {
      const { openModal, closeModal, createOrUpdateAdditionalContactRequest } = props;

      const component = (
        <AdditionalContactForm
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={createOrUpdateAdditionalContactRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: '630px',
          overflow: 'auto',
          maxHeight: '90vh',
          footerBottom: 'unset',
          title: G.getWindowLocale('titles:edit-additional-contact', 'Edit Additional Contact'),
        },
      };
      openModal(modal);
    },
    handleRemoveAdditionalContact: ({ openModal, removeAdditionalContactRequest }: Object) => ({ guid }: Object) => {
      const textLocale = G.getWindowLocale('messages:delete-contact', 'Do you want to delete this contact');
      const confirmationContent = <ConfirmComponent textLocale={textLocale} />;
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          controlButtons: [
            {
              type: 'button',
              action: () => removeAdditionalContactRequest(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const AdditionalContactTab = (props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={props.additionalContactList}
    entitiesFields={additionalContactSettings}
    handleAddClick={props.handleAddAdditionalContact}
    handleEditRow={props.handleEditAdditionalContact}
    groupName={CARRIER_GROUP_NAME_ADDITIONAL_CONTACTS}
    handleDeleteRow={props.handleRemoveAdditionalContact}
    isOpened={R.path([CARRIER_GROUP_NAME_ADDITIONAL_CONTACTS], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:additional-contacts', 'Additional Contacts')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_ADDITIONAL_CONTACTS)} />
);

export default enhance(AdditionalContactTab);
