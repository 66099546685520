import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// report-common
import { getReportReducers } from '../../report-common';
// helpers/constants
import * as G from '../../helpers';
// feature average-fuel-price
import * as A from './actions';
//////////////////////////////////////////////////

const {
  initialState,
  setListLoading,
  setInitialState,
  setReportPending,
  setTableTitleSort,
  setTableTitleFilter,
  resetListAndPagination,
} = getReportReducers();

const getItemListSuccess = (state: Object, data: Object) => {
  const { results, totalCount } = data;

  const { itemList, pagination } = state;

  const newOffset = R.sum(R.values(pagination));

  const offsetToUse = G.ifElse(
    R.gt(totalCount, newOffset),
    newOffset,
    totalCount,
  );

  const list = R.concat(R.or(itemList, []), results);

  return P.$all(
    P.$set('itemList', list),
    P.$set('totalCount', totalCount),
    P.$set('pagination.offset', offsetToUse),
    state,
  );
};

export default createReducer({
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.resetListAndPagination]: resetListAndPagination,
}, initialState);

