import React from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as GC from '../../../../constants';
// feature configs
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import {
  updateConfigsRequest,
  removeOptionalConfigRequest,
  restoreInheritedByConfigTypeRequest } from '../../actions';
import {
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectConfigInitialValues,
  makeSelectConfigGuidsToItemsMap,
  makeSelectConfigInheritedValues } from '../../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(),
  itemPageConfigEnhancer,
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    pageTitle='titles:optional'
    groupSettings={GC.OPTIONAL_CONFIG_GROUP} />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.OPTIONAL_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.OPTIONAL_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.OPTIONAL_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.OPTIONAL_CONFIG_GROUP),
  guidsToItemsMap: makeSelectConfigGuidsToItemsMap(state, GC.OPTIONAL_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  removeOptionalConfigRequest,
  restoreInheritedByConfigTypeRequest,
})(enhance(ConfigPageComponent));
