import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import Chat from '../../../components/chat';
// helpers/constants
import * as G from '../../../helpers';
// feature components-story
import { setChatMessages } from '../actions';
import { makeSelectChatMessages } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ChatComponent = ({ chatMessages, setChatMessages }: Object) => {
  const description = '<ChatComponent /> is a component designed for implementing a chat interface.';

  const usage = (
    <ul>
      <li>Parameter `<i>width</i>` defines the width of the chat component.</li>
      <li>Parameter `<i>height</i>` specifies the height of the chat component.</li>
      <li>Parameter `<i>headerBgColor</i>` specifies the background color of the chat header.</li>
      <li>Parameter `<i>textareaPermissions</i>` controls the permissions related to using the chat textarea.</li>
      <li>Parameter `<i>chatMessages</i>` represents an array of chat messages that will be displayed in the chat.</li>
      <li>Parameter `<i>currentUserGuid</i>` represents the unique identifier of the current user who is interacting with the chat.</li>
      <li>Parameter `<i>addNewMessageAction</i>` is a callback function that is called when a new message needs to be added to the chat.</li>
    </ul>
  );

  const currentUserGuid = G.getAmousCurrentUserGuidFromWindow();

  const handleCreateChatMessage = (data: Object) => {
    const message = {
      text: data.message,
      authorLastName: 'User',
      authorFirstName: 'Amous',
      authorLoginId: 'Amous User',
    };

    setChatMessages(message);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ChatComponent'
      description={description}
      path='src/components/chat'
    >
      <Chat
        width={250}
        height={350}
        chatMessages={chatMessages}
        currentUserGuid={currentUserGuid}
        headerBgColor={G.getTheme('colors.navyBlue')}
        addNewMessageAction={handleCreateChatMessage}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  chatMessages: makeSelectChatMessages(state),
});

export default connect(mapStateToProps, {
  setChatMessages,
})(ChatComponent);
