import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Info, Error, Label, FormGroup } from '../../../forms';
// ui
import { Box, ReactSelect } from '../../../ui';
//////////////////////////////////////////////////

export const renderMultiSelect = ({
  jc,
  zI,
  info,
  align,
  input,
  width,
  label,
  margin,
  display,
  options,
  direction,
  fieldClass,
  errorClass,
  labelMargin,
  errorMargin,
  placeholder,
  groupPadding,
  selectAction,
  labelPosition,
  labelFontSize,
  errorFontSize,
  additionClass,
  selectedValues,
  meta: { error, touched },
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    display={display || 'flex'}
    additionClass={additionClass}
    direction={direction || 'column'}
  >
    {
      R.and(
        G.isNotNil(labelPosition),
        <Label
          m={labelMargin}
          fontSize={labelFontSize}
          className={G.ifElse(G.isString(fieldClass), fieldClass)}
        >
          {label}
        </Label>,
      )
    }
    <Box
      m={margin}
      zIndex={zI}
      width={width}
    >
      <ReactSelect
        isMulti={true}
        name={input.name}
        options={options}
        isClearable={true}
        onChange={selectAction}
        placeholder={placeholder}
        value={R.or(selectedValues, input.value)}
        additionalStyles={{
          control: (baseStyles: Object) => ({
            ...baseStyles,
            minHeight: 30,
            borderRadius: 2,
            height: 'max-content',
            borderColor: G.getTheme('colors.light.grey'),
          }),
        }}
      />
    </Box>
    {info && <Info text={info} />}
    {
      touched && error &&
      <Error
        m={errorMargin}
        errorClass={errorClass}
        fontSize={errorFontSize}
        className={G.ifElse(G.isString(errorClass), errorClass)}
      >
        {error}
      </Error>
    }
  </FormGroup>
);
