import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { Tabs } from '../../../../components/tabs';
import { TabsMui } from '../../../../components/tabs-mui';
// features
import { startPivotTable } from '../../../pivot-table/actions';
import { makeSelectPivotTableTotalCount } from '../../../pivot-table/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  pivotTableTotalCount: makeSelectPivotTableTotalCount(state),
});

const enhance = compose(
  connect(mapStateToProps, { startPivotTable }),
  pure,
);

const getTabs = (activeTab: number) => [
  {
    value: 0,
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:fleet-equipment-service', 'Fleet: Equipment Service'),
  },
  {
    value: 1,
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:pivot-table', 'Pivot Table'),
  },
];


export const EquipmentServiceTabs = enhance((props: Object) => {
  const { activeTab, totalCount, startPivotTable, handleSetActiveTab, pivotTableTotalCount } = props;

  const isList = R.equals(activeTab, 0);

  const handleClickTab = (i: number) => {
    if (isList) startPivotTable(GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT);

    handleSetActiveTab(i);
  };

  return (
    <TabsMui
      activeMuiTab={activeTab}
      tabs={getTabs(activeTab)}
      setActiveMuiTab={handleClickTab}
      count={G.ifElse(isList, totalCount, pivotTableTotalCount)}
    />
  );
});
