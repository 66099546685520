import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setPortalType = createAction('setPortalType');
export const addCarrierDoc = createAction('addCarrierDoc');
export const carrierMobView = createAction('carrierMobView');
export const setMessageStatus = createAction('setMessageStatus');
export const getPreviousPathName = createAction('getPreviousPathName');
export const setCarrierDocRequest = createAction('setCarrierDocRequest');
export const acceptDispatchSuccess = createAction('acceptDispatchSuccess');
export const declineDispatchSuccess = createAction('declineDispatchSuccess');
export const getBranchLogoUrlRequest = createAction('getBranchLogoUrlRequest');
export const getBranchLogoUrlSuccess = createAction('getBranchLogoUrlSuccess');
export const addCarrierStatusMessage = createAction('addCarrierStatusMessage');
export const getCarrierResponseStatus = createAction('getCarrierResponseStatus');
export const sendCarrierMessageRequest = createAction('sendCarrierMessageRequest');
export const getCarrierPortalInfoSuccess = createAction('getCarrierPortalInfoSuccess');
export const getCarrierPortalInfoRequest = createAction('getCarrierPortalInfoRequest');
export const getChatMessageCarrierRequest = createAction('getChatMessageCarrierRequest');
export const carrierResponseAcceptRequest = createAction('carrierResponseAcceptRequest');
export const getChatMessageCarrierSuccess = createAction('getChatMessageCarrierSuccess');
export const carrierResponseDeclineRequest = createAction('carrierResponseDeclineRequest');
export const getCarrierResponseTokenSuccess = createAction('getCarrierResponseTokenSuccess');
export const getPortalInfoByPortalTypeRequest = createAction('getPortalInfoByPortalTypeRequest');
export const getPortalInfoByPortalTypeSuccess = createAction('getPortalInfoByPortalTypeSuccess');
export const getAllAvailableCloRefTypesRequest = createAction('getAllAvailableCloRefTypesRequest');
export const getAllAvailableCloRefTypesSuccess = createAction('getAllAvailableCloRefTypesSuccess');
export const downloadRateConfirmationDocumentRequest = createAction('downloadRateConfirmationDocumentRequest');
export const getDeclineOptionsConfigsSuccess = createAction('getDeclineOptionsConfigsSuccess');
export const previewCarrierTermsAndConditionsDocumentRequest =
  createAction('previewCarrierTermsAndConditionsDocumentRequest');
