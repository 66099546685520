import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectGeoFencingZoneStore = (state: Object) => state.reportGeoFencingZone;

const makeSelectIndexedList = () => createSelector(
  selectGeoFencingZoneStore,
  ({ itemList }: Object) => itemList,
);

const makeSelectItemList = () => createSelector(
  selectGeoFencingZoneStore,
  ({ itemList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(itemList),
);

const makeSelectAvailableReportGeoFencingZoneList = () => createSelector(
  selectGeoFencingZoneStore,
  ({ availableList }: Object) => availableList,
);

export {
  makeSelectItemList,
  makeSelectIndexedList,
  makeSelectAvailableReportGeoFencingZoneList,
};
