// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const commonFields = {
  [GC.FIELD_NAME]: {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
  [GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE]: {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    maxDate: G.momentAddYearsFromCurrent(3),
    label: ['titles:effective-date', 'Effective Date'],
    fieldName: GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE,
  },
  [GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE]: {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    minDate: G.getCurrentDate(),
    maxDate: G.momentAddYearsFromCurrent(3),
    label: ['titles:expiration-date', 'Expiration Date'],
    fieldName: GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE,
  },
  [GC.FIELD_CARRIER_ACTIVE]: {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:active', 'Active'],
    fieldName: GC.FIELD_CARRIER_ACTIVE,
  },
  [GC.FIELD_MODE]: {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: GC.FIELD_MODE,
    useMenuPortalTarget: true,
    options: 'transportationModes',
    label: ['titles:mode', 'Mode'],
  },
  [GC.FIELD_CURRENCY]: {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
  },
};

export const createContractFormFields = [
  commonFields[GC.FIELD_NAME],
  commonFields[GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE],
  commonFields[GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE],
  commonFields[GC.FIELD_CARRIER_ACTIVE],
  commonFields[GC.FIELD_MODE],
  commonFields[GC.FIELD_CURRENCY],
  {
    isMulti: true,
    inputWrapperStyles,
    shouldHandle: true,
    type: 'reactSelect',
    options: 'roleOptions',
    useMenuPortalTarget: true,
    label: ['titles:roles', 'Roles'],
    fieldName: GC.FIELD_CARRIER_CONTRACT_ROLE_GUIDS,
  },
];

export const createContractFormFieldsAsync = [
  commonFields[GC.FIELD_NAME],
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    shouldCustomChange: true,
    options: 'carrierOptions',
    fieldName: GC.FIELD_CARRIER_GUID,
    label: ['titles:carrier', 'Carrier'],
  },
  commonFields[GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE],
  commonFields[GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE],
  commonFields[GC.FIELD_CARRIER_ACTIVE],
  commonFields[GC.FIELD_MODE],
  commonFields[GC.FIELD_CURRENCY],
];
