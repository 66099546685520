import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const getChartDataFailed = createAction('getChartDataFailed');
export const cleanAnalyticState = createAction('cleanAnalyticState');
export const getChartDataRequest = createAction('getChartDataRequest');
export const getChartDataSuccess = createAction('getChartDataSuccess');
export const getDashboardRequest = createAction('getDashboardRequest');
export const getDashboardSuccess = createAction('getDashboardSuccess');
export const setCurrentDashboard = createAction('setCurrentDashboard');
export const cleanDashboardCharts = createAction('cleanDashboardCharts');
export const updateDashboardRequest = createAction('updateDashboardRequest');
export const createDashboardRequest = createAction('createDashboardRequest');
export const updateDashboardSuccess = createAction('updateDashboardSuccess');
export const createDashboardSuccess = createAction('createDashboardSuccess');
export const deleteDashboardRequest = createAction('deleteDashboardRequest');
export const deleteDashboardSuccess = createAction('deleteDashboardSuccess');
export const getDashboardListRequest = createAction('getDashboardListRequest');
export const getDashboardListSuccess = createAction('getDashboardListSuccess');
export const setDashboardChartsPosition = createAction('setDashboardChartsPosition');
export const getChartsByCollectionFailed = createAction('getChartsByCollectionFailed');
export const getChartsByCollectionRequest = createAction('getChartsByCollectionRequest');
export const getChartsByCollectionSuccess = createAction('getChartsByCollectionSuccess');
