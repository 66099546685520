import * as Yup from 'yup';
import * as R from 'ramda';
import moment from 'moment';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { payToTypeOptions, vendorPayrollAccessorialPayForUnitTypeOptions } from '../../../helpers/options';
// feature fleet
import { customRequired, customIsNumeric } from '../validation';
//////////////////////////////////////////////////

export const defaultLatestKnownLocationFields = {
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_LONGITUDE]: '',
};

const latestKnownLocationInputWrapperStyles = {
  mb: 25,
  width: 300,
};

export const latestKnownLocationFields = [
  {
    isRequired: true,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: ['titles:city', 'City'],
    inputWrapperStyles: latestKnownLocationInputWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
    inputWrapperStyles: latestKnownLocationInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ZIP,
    label: ['titles:zip', 'Zip'],
    inputWrapperStyles: latestKnownLocationInputWrapperStyles,
  },
  {
    isRequired: true,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
    inputWrapperStyles: latestKnownLocationInputWrapperStyles,
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
    inputWrapperStyles: latestKnownLocationInputWrapperStyles,
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
    inputWrapperStyles: latestKnownLocationInputWrapperStyles,
  },
];

export const latestKnownLocationValidationSchema = Yup.object().shape({
  [GC.FIELD_CITY]: G.yupStringRequired,
  [GC.FIELD_STATE]: G.yupStringRequired,
  [GC.FIELD_COUNTRY]: G.yupStringRequired,
});

export const integrationSamsaraFields = {
  fields: [
    {
      type: 'input',
      validate: G.required,
      name: 'titles:external-id',
      nameForAttribute: GC.FIELD_FLEET_EXTERNAL_ID,
    },
  ],
};

export const insuranceFields = [
  {
    type: 'select',
    align: 'start',
    direction: 'column',
    validate: G.required,
    options: 'insuranceType',
    errorMargin: '0 0 5px 10px',
    name: 'titles:insurance-type',
    nameForAttribute: 'insuranceType',
  },
  {
    width: 185,
    isClearable: true,
    type: 'datePicker',
    validate: customRequired,
    name: 'titles:effective-date',
    nameForAttribute: 'effectiveDate',
  },
  {
    width: 185,
    isClearable: true,
    type: 'datePicker',
    validate: customRequired,
    name: 'titles:expiration-date',
    nameForAttribute: 'expirationDate',
  },
  {
    width: 185,
    type: 'text',
    align: 'start',
    direction: 'column',
    name: 'titles:payment',
    errorMargin: '0 0 5px 10px',
    nameForAttribute: 'paymentAmount',
    validate: [G.required, customIsNumeric],
  },
  {
    width: 185,
    type: 'select',
    align: 'start',
    direction: 'column',
    validate: G.required,
    errorMargin: '0 0 5px 10px',
    name: 'titles:payment-interval',
    nameForAttribute: 'paymentInterval',
    options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
  },
  {
    type: 'textarea',
    name: 'titles:description',
    nameForAttribute: 'description',
  },
];

export const latestKnownLocationSettings = {
  source: {
    width: 200,
    name: 'titles:source',
  },
  country: {
    width: 200,
    name: 'titles:country',
  },
  state: {
    width: 200,
    name: 'titles:state',
  },
  city: {
    width: 200,
    name: 'titles:city',
  },
  zip: {
    width: 200,
    name: 'titles:postal-code',
  },
  latitude: {
    width: 150,
    name: 'titles:latitude',
  },
  longitude: {
    width: 150,
    name: 'titles:longitude',
  },
  lastModifiedDate: {
    width: 200,
    name: 'titles:update-on',
  },
  lastModifiedBy: {
    width: 150,
    name: 'titles:updated-by',
  },
  createdDate: {
    width: 200,
    name: 'titles:created-date',
  },
  createdBy: {
    width: 150,
    name: 'titles:created-by',
  },
};

const defaultFieldStyles = {
  width: 190,
  afterTop: 14,
  errorTop: '110%',
  errorFontSize: 12,
  labelWidth: '100%',
  flexDirection: 'column',
  errorMargin: '0 0 5px 0',
  errorPosition: 'absolute',
};

const emptyFileOrUrl = () =>
  G.getWindowLocale('messages:empty-file-or-url', 'Please, select a file or enter file URL');

export const defaultValidationSchemaDocumentFormObject = {
  [GC.FIELD_DOCUMENT_DESCRIPTION]: Yup.string()
    .nullable(true),
  [GC.FIELD_DOCUMENT_STATUS]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DOCUMENT_URL]: Yup.string()
    .nullable(true)
    .url(G.getShouldBeUrlLocaleTxt()),
};

export const getDocumentFormValidationSchemaObject = (values: Object, edit: any) => {
  const requiredFields = [R.prop(GC.FIELD_DOCUMENT_URL, values), R.prop(GC.FIELD_DOCUMENT_UPLOAD, values)];

  if (R.any(G.isNotNilAndNotEmpty)(requiredFields)) return defaultValidationSchemaDocumentFormObject;

  const requiredFieldsValidationSchemaObject = {
    [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string()
      .nullable(true)
      .required(G.ifElse(
        G.isTrue(edit),
        G.getShouldBeFileLocaleTxt(),
        emptyFileOrUrl(),
      )),
    [GC.FIELD_DOCUMENT_URL]: Yup.string()
      .nullable(true)
      .required(G.ifElse(
        G.isTrue(edit),
        G.getShouldBeUrlLocaleTxt(),
        emptyFileOrUrl(),
      )),
  };

  return R.mergeRight(defaultValidationSchemaDocumentFormObject, requiredFieldsValidationSchemaObject);
};

export const getDocFields = ({ fleetType, signedDate, expirationDate }: Object, initialValues: Object) => ([
  {
    ...defaultFieldStyles,
    type: 'select',
    loc: 'titles:type',
    options: 'documentType',
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
  },
  {
    ...defaultFieldStyles,
    type: 'select',
    loc: 'titles:status',
    fieldName: GC.FIELD_DOCUMENT_STATUS,
    options: [
      { value: '', label: '' },
      {
        value: GC.DOCUMENT_STATUS_CURRENT,
        label: G.getWindowLocale('titles:current', 'Current'),
      },
      {
        value: GC.DOCUMENT_STATUS_HISTORICAL,
        label: G.getWindowLocale('titles:historical', 'Historical'),
      },
    ],
  },
  {
    ...defaultFieldStyles,
    width: 390,
    type: 'textarea',
    direction: 'column',
    loc: 'titles:description',
    fieldName: GC.FIELD_DOCUMENT_DESCRIPTION,
  },
  {
    ...defaultFieldStyles,
    zIndex: 11,
    isClearable: true,
    type: 'datePicker',
    loc: 'titles:signed-date',
    fieldName: GC.FIELD_DOCUMENT_SIGNED_DATE,
    maxDate: R.and(G.isNotNilAndNotEmpty(expirationDate), moment(expirationDate)),
  },
  {
    ...defaultFieldStyles,
    zIndex: 11,
    isClearable: true,
    type: 'datePicker',
    calendarIconMr: '0',
    loc: 'titles:expired-date',
    fieldName: GC.FIELD_DOCUMENT_EXPIRATION_DATE,
    minDate: R.and(G.isNotNilAndNotEmpty(signedDate), moment(signedDate)),
  },
  {
    ...defaultFieldStyles,
    type: 'toggle',
    loc: 'titles:attachable-to-customer-invoice',
    fieldName: GC.FIELD_ATTACHABLE_TO_CUSTOMER_INVOICE,
    minDate: R.and(G.isNotNilAndNotEmpty(signedDate), moment(signedDate)),
    fieldGroupDisplay: G.ifElse(R.equals(fleetType, GC.FIELD_VENDOR), 'flex', 'none'),
  },
  {
    ...defaultFieldStyles,
    width: 390,
    type: 'text',
    loc: 'titles:url',
    fieldName: GC.FIELD_DOCUMENT_URL,
    fieldGroupDisplay: (_: any, values: Object) =>
      G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_UPLOAD, initialValues),
  },
  {
    ...defaultFieldStyles,
    width: 390,
    type: 'file',
    loc: 'titles:document-template',
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    fieldGroupDisplay: (_: any, values: Object) =>
      G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_URL, initialValues),
  },
]);

export const defaultDocFields = {
  [GC.FIELD_DOCUMENT_URL]: '',
  [GC.FIELD_DOCUMENT_STATUS]: '',
  [GC.FIELD_DOCUMENT_UPLOAD]: null,
  [GC.FIELD_DOCUMENT_SIGNED_DATE]: '',
  [GC.FIELD_DOCUMENT_DESCRIPTION]: '',
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: '',
  [GC.FIELD_DOCUMENT_EXPIRATION_DATE]: '',
  [GC.FIELD_ATTACHABLE_TO_CUSTOMER_INVOICE]: false,
};

// integration
const showIntegrationIdInput = (integrationType: string, fieldName: string) => {
  let display = 'none';
  if (R.and(
    R.equals(fieldName, GC.FIELD_FLEET_EXTERNAL_ID),
    R.includes(
      integrationType,
      [
        GC.FUEL_CARDS_TYPE_RELAY,
        GC.FLEET_INTEGRATION_SPIREON,
        GC.GPS_INTEGRATION_TYPE_GEO_TAB,
        GC.GPS_INTEGRATION_TYPE_VERIZON,
        GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
        GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
        GC.FLEET_INTEGRATION_TYPE_SAMSARA,
        GC.FLEET_INTEGRATION_TYPE_SKYBITZ,
        GC.GPS_INTEGRATION_TYPE_OMNITRACS,
        GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
        GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
        GC.FLEET_INTEGRATION_TYPE_POWER_FLEET,
        GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
        GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
      ],
    ),
  )) {
    display = 'flex';
  } else if (R.and(
    R.equals(fieldName, GC.FIELD_FLEET_FUEL_CARD_ID),
    R.includes(
      integrationType,
      [
        GC.FUEL_CARDS_TYPE_BVD,
        GC.FUEL_CARDS_TYPE_QUIKQ,
        GC.FUEL_CARDS_TYPE_COMDATA,
        GC.FLEET_INTEGRATION_TYPE_EFS,
        GC.FUEL_CARDS_TYPE_SELF_FUELED,
      ],
    ),
  )) {
    display = 'flex';
  }
  return display;
};

export const getValidationSchemaIntegrationFormObject = (integrationType: string) => {
  let schema = {
    [GC.FIELD_TYPE]: G.yupStringRequired,
  };

  const tollChargesIntegrationTypes = [
    GC.FLEET_INTEGRATION_TYPE_I_PASS,
    GC.FLEET_INTEGRATION_TYPE_BESTPASS,
    GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
    GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
  ];

  const fuelCardsIntegrationTypes = [
    GC.FUEL_CARDS_TYPE_BVD,
    GC.FUEL_CARDS_TYPE_QUIKQ,
    GC.FUEL_CARDS_TYPE_COMDATA,
    GC.FLEET_INTEGRATION_TYPE_EFS,
    GC.FUEL_CARDS_TYPE_SELF_FUELED,
  ];

  const gpsIntegrationTypes = [
    GC.FUEL_CARDS_TYPE_RELAY,
    GC.FLEET_INTEGRATION_SPIREON,
    GC.GPS_INTEGRATION_TYPE_VERIZON,
    GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
    GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
    GC.GPS_INTEGRATION_TYPE_OMNITRACS,
    GC.FLEET_INTEGRATION_TYPE_SAMSARA,
    GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
    GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
    GC.FLEET_INTEGRATION_TYPE_POWER_FLEET,
    GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
    GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
  ];

  if (R.includes(integrationType, gpsIntegrationTypes)) {
    schema = R.mergeRight(schema, { [GC.FIELD_FLEET_EXTERNAL_ID]: G.yupStringRequired });
  } else if (R.includes(integrationType, fuelCardsIntegrationTypes)) {
    schema = R.mergeRight(schema, { [GC.FIELD_FLEET_FUEL_CARD_ID]: G.yupStringRequired });
  } else if (R.includes(integrationType, tollChargesIntegrationTypes)) {
    schema = R.mergeRight(schema, { [GC.FIELD_FLEET_TRANSPONDER_ID]: G.yupStringRequired });
  }

  return schema;
};

export const defaultIntegrationFields = {
  [GC.FIELD_TYPE]: null,
  [GC.FIELD_FLEET_EXTERNAL_ID]: null,
  [GC.FIELD_FLEET_FUEL_CARD_ID]: null,
  [GC.FIELD_FLEET_TRANSPONDER_ID]: null,
};

const defaultIntegrationFieldStyles = {
  width: 300,
  afterTop: 14,
  errorLeft: 10,
  errorTop: '110%',
  labelWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

export const driverIntegrationTypeOptions = [
  { value: '', label: '' },
  {
    value: GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
    label: G.getWindowLocale('titles:keep-truckin', 'Keep Truckin'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_SAMSARA,
    label: G.getWindowLocale('titles:samsara', 'Samsara'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_VERIZON,
    label: G.getWindowLocale('titles:verizon', 'Verizon'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
    label: G.getWindowLocale('titles:track-ensure', 'Track Ensure'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_OMNITRACS,
    label: G.getWindowLocale('titles:omnitracs', 'Omnitracs'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_GEO_TAB,
    label: G.getWindowLocale('titles:geo-tab', 'GEOTAB'),
  },
  {
    label: 'Tenstreet',
    value: GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
  },
  {
    label: 'Club ELD',
    value: GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
  },
  {
    label: 'Blue Star',
    value: GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
  },
  {
    label: 'Master ELD',
    value: GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_EFS,
    label: G.getWindowLocale('titles:efs', 'EFS'),
  },
  {
    value: GC.FUEL_CARDS_TYPE_QUIKQ,
    label: G.getWindowLocale('titles:quikq', 'QUIKQ'),
  },
  {
    value: GC.FUEL_CARDS_TYPE_COMDATA,
    label: G.getWindowLocale('titles:comdata', 'Comdata'),
  },
  {
    value: GC.FUEL_CARDS_TYPE_SELF_FUELED,
    label: G.getWindowLocale('titles:self-fueled', 'Self Fueled'),
  },
  {
    label: 'BVD Group',
    value: GC.FUEL_CARDS_TYPE_BVD,
  },
  {
    label: 'Relay',
    value: GC.FUEL_CARDS_TYPE_RELAY,
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_I_PASS,
    label: G.getWindowLocale('titles:i-pass', 'I-Pass'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_BESTPASS,
    label: G.getWindowLocale('titles:bestpass', 'Bestpass'),
  },
  {
    label: 'PrePass',
    value: GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
  },
  {
    label: 'Self Toll',
    value: GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
  },
];

export const truckIntegrationTypeOptions = [
  { value: '', label: '' },
  {
    value: GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN,
    label: G.getWindowLocale('titles:keep-truckin', 'Keep Truckin'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_SAMSARA,
    label: G.getWindowLocale('titles:samsara', 'Samsara'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_VERIZON,
    label: G.getWindowLocale('titles:verizon', 'Verizon'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
    label: G.getWindowLocale('titles:track-ensure', 'Track Ensure'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_OMNITRACS,
    label: G.getWindowLocale('titles:omnitracs', 'Omnitracs'),
  },
  {
    value: GC.GPS_INTEGRATION_TYPE_GEO_TAB,
    label: G.getWindowLocale('titles:geo-tab', 'GEOTAB'),
  },
  {
    label: 'Club ELD',
    value: GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
  },
  {
    label: 'Blue Star',
    value: GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
  },
  {
    label: 'Master ELD',
    value: GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_I_PASS,
    label: G.getWindowLocale('titles:i-pass', 'I-Pass'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_BESTPASS,
    label: G.getWindowLocale('titles:bestpass', 'Bestpass'),
  },
  {
    label: 'PrePass',
    value: GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
  },
  {
    label: 'Self Toll',
    value: GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
  },
];

export const trailerIntegrationTypeOptions = [
  { value: '', label: '' },
  {
    value: GC.FLEET_INTEGRATION_TYPE_SAMSARA,
    label: G.getWindowLocale('titles:samsara', 'Samsara'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_SKYBITZ,
    label: G.getWindowLocale('titles:skybitz', 'Skybitz'),
  },
  {
    value: GC.FLEET_INTEGRATION_TYPE_POWER_FLEET,
    label: G.getWindowLocale('titles:power-fleet', 'Power Fleet'),
  },
  {
    value: GC.FLEET_INTEGRATION_SPIREON,
    label: G.getWindowLocale('titles:spireon', 'Spireon'),
  },
];

export const integrationFields = [
  {
    ...defaultIntegrationFieldStyles,
    type: 'select',
    loc: 'titles:integration-type',
    options: GC.FIELD_FLEET_INTEGRATION_TYPE,
    fieldName: GC.FIELD_FLEET_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
  },
  {
    ...defaultIntegrationFieldStyles,
    type: 'text',
    loc: 'titles:external-id',
    fieldName: GC.FIELD_FLEET_EXTERNAL_ID,
    fieldGroupDisplay: (_: Object, values: Object) =>
      showIntegrationIdInput(R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE, values), GC.FIELD_FLEET_EXTERNAL_ID),
  },
  {
    ...defaultIntegrationFieldStyles,
    type: 'text',
    loc: 'titles:fuel-card-id',
    fieldName: GC.FIELD_FLEET_FUEL_CARD_ID,
    fieldGroupDisplay: (_: Object, values: Object) =>
      showIntegrationIdInput(R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE, values), GC.FIELD_FLEET_FUEL_CARD_ID),
  },
  {
    ...defaultIntegrationFieldStyles,
    type: 'text',
    loc: 'titles:transponder-id',
    fieldName: GC.FIELD_FLEET_TRANSPONDER_ID,
    fieldGroupDisplay: (_: Object, { type }: Object) => G.ifElse(
      R.includes(
        type,
        [
          GC.FLEET_INTEGRATION_TYPE_I_PASS,
          GC.FLEET_INTEGRATION_TYPE_BESTPASS,
          GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
          GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
        ],
      ),
      'flex',
      'none',
    ),
  },
];

export const iftaFields = [
  {
    ...defaultIntegrationFieldStyles,
    zIndex: 12,
    iconColor: '',
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: false,
    loc: 'titles:date-from',
    fieldName: GC.FIELD_IFTA_DATE_FROM,
  },
  {
    ...defaultIntegrationFieldStyles,
    zIndex: 12,
    iconColor: '',
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: false,
    loc: 'titles:date-to',
    maxDate: G.getCurrentDate(),
    fieldName: GC.FIELD_IFTA_DATE_TO,
  },
];

export const defaultIftaFields = {
  [GC.FIELD_IFTA_DATE_TO]: null,
  [GC.FIELD_IFTA_DATE_FROM]: null,
};

export const validationSchemaIftaForm = Yup.object().shape({
  [GC.FIELD_IFTA_DATE_TO]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_IFTA_DATE_FROM]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

// pay to location
export const payToLocationDefaultFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_GUID]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_NET_DAYS]: null,
  [GC.FIELD_ADDRESS_1]: null,
  [GC.FIELD_ADDRESS_2]: null,
  [GC.FIELD_LOCATION_NAME]: null,
  [GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE]: null,
  [GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE]: null,
};

export const payLocationFieldSettings = [
  {
    ...defaultFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:pay-to-name',
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    ...defaultFieldStyles,
    width: 350,
    type: 'select',
    loc: 'titles:pay-type',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE,
    options: G.prependEmptyLabelValueOption(payToTypeOptions),
  },
  {
    ...defaultFieldStyles,
    zI: 12,
    width: 350,
    loc: 'titles:address',
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
  },
  {
    zI: 12,
    width: 350,
    type: 'text',
    loc: 'titles:address2',
    fieldName: GC.FIELD_ADDRESS_2,
  },
  {
    ...defaultFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:city',
    fieldName: GC.FIELD_CITY,
  },
  {
    ...defaultFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:state',
    fieldName: GC.FIELD_STATE,
  },
  {
    ...defaultFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:zip',
    fieldName: GC.FIELD_ZIP,
  },
  {
    ...defaultFieldStyles,
    width: 350,
    type: 'countrySelect',
    loc: 'titles:country',
    fieldName: GC.FIELD_COUNTRY,
  },
  {
    ...defaultFieldStyles,
    width: 350,
    zIndex: 13,
    type: 'datePicker',
    loc: 'titles:start-date',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE,
  },
];

export const payLocationFieldSettings2 = [
  ...payLocationFieldSettings,
  {
    ...defaultFieldStyles,
    width: 350,
    type: 'text',
    loc: 'titles:net-days',
    fieldName: GC.FIELD_NET_DAYS,
  },
];

export const payToLocationValidationSchema = {
  [GC.FIELD_NET_DAYS]: Yup.string().nullable(true).notRequired(),
  [GC.FIELD_ADDRESS_2]: Yup.string().nullable(true).notRequired(),
  [GC.FIELD_ZIP]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CITY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_STATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_COUNTRY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_ADDRESS_1]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOCATION_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

// contact
const contactStyles = {
  ...defaultFieldStyles,
  width: 200,
  labelWidth: '50%',
  flexDirection: 'row',
};

export const contactFieldSettings = [
  {
    ...contactStyles,
    flexWrap: 'nowrap',
    loc: 'titles:address',
    shouldCustomChange: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
  },
  {
    ...contactStyles,
    type: 'text',
    loc: 'titles:city',
    fieldName: GC.FIELD_CITY,
  },
  {
    ...contactStyles,
    type: 'text',
    loc: 'titles:state',
    fieldName: GC.FIELD_STATE,
  },
  {
    ...contactStyles,
    type: 'text',
    loc: 'titles:postal-code',
    fieldName: GC.FIELD_CONTACT_POSTAL_CODE,
  },
  {
    ...contactStyles,
    type: 'countrySelect',
    loc: 'titles:country',
    fieldName: GC.FIELD_COUNTRY,
  },
  {
    ...contactStyles,
    type: 'text',
    loc: 'titles:emergency-contact-name',
    fieldName: GC.FIELD_EMERGENCY_CONTACT_NAME,
  },
  {
    ...contactStyles,
    type: 'phoneNumber',
    loc: 'titles:emergency-phone',
    fieldName: GC.FIELD_EMERGENCY_PHONE,
  },
  {
    ...contactStyles,
    type: 'phoneNumber',
    loc: 'titles:mobile-phone',
    fieldName: GC.FIELD_FLEET_MOBILE_PHONE,
  },
  {
    ...contactStyles,
    type: 'text',
    loc: 'titles:home-phone',
    fieldName: GC.FIELD_HOME_PHONE,
  },
  {
    ...contactStyles,
    type: 'text',
    loc: 'titles:email',
    fieldName: GC.FIELD_EMAIL,
  },
];

export const defaultContactFields = {
  [GC.FIELD_CITY]: null,
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_ADDRESS]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_HOME_PHONE]: null,
  [GC.FIELD_EMERGENCY_PHONE]: null,
  [GC.FIELD_FLEET_MOBILE_PHONE]: null,
  [GC.FIELD_CONTACT_POSTAL_CODE]: null,
  [GC.FIELD_EMERGENCY_CONTACT_NAME]: null,
};

export const contactValidationSchema = Yup.object().shape({
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_ADDRESS]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONTACT_POSTAL_CODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_EMERGENCY_CONTACT_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_EMAIL]: Yup.string()
    .notRequired()
    .nullable(true)
    .email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_HOME_PHONE]: Yup.string()
    .notRequired()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
  [GC.FIELD_FLEET_MOBILE_PHONE]: Yup.string()
    .notRequired()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
  [GC.FIELD_EMERGENCY_PHONE]: Yup.string()
    .required(G.getRequiredLocaleTxt())
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
});

// payroll accessorials
const payrollAccessorialStyles = {
  ...defaultFieldStyles,
  width: 215,
};

export const getPayrollAssessorialFields = ({ isEdit, limited, deductionDisabled }: Object) => [
  {
    ...payrollAccessorialStyles,
    zIndex: 14,
    isRequired: true,
    type: 'complexSelect',
    shouldCustomChange: true,
    loc: 'titles:accessorial',
    options: 'accessorialOptions',
    fieldName: GC.FIELD_DRIVER_ASSESSORIAL_GUID,
  },
  {
    ...payrollAccessorialStyles,
    width: 50,
    type: 'toggle',
    disabled: true,
    flexDirection: 'column',
    alignItems: 'flex-start',
    loc: 'titles:fuel-related',
    fieldName: GC.FIELD_FUEL_RELATED,
  },
  {
    ...payrollAccessorialStyles,
    width: 50,
    type: 'toggle',
    disabled: true,
    flexDirection: 'column',
    alignItems: 'flex-start',
    loc: 'titles:non-taxable',
    fieldName: GC.FIELD_NON_TAXABLE,
  },
  {
    ...payrollAccessorialStyles,
    width: 50,
    type: 'toggle',
    flexDirection: 'column',
    alignItems: 'flex-start',
    loc: 'titles:deduction',
    disabled: deductionDisabled,
    fieldName: GC.FIELD_DEDUCTION,
  },
  {
    ...payrollAccessorialStyles,
    width: 50,
    type: 'toggle',
    loc: 'titles:active',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fieldName: GC.FIELD_ACTIVE,
  },
  {
    ...payrollAccessorialStyles,
    type: 'number',
    isRequired: true,
    loc: 'titles:rate',
    fieldName: GC.FIELD_CHARGE_RATE,
  },
  {
    ...payrollAccessorialStyles,
    type: 'select',
    disabled: isEdit,
    isRequired: true,
    loc: 'titles:currency',
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
  },
  {
    ...payrollAccessorialStyles,
    type: 'text',
    disabled: true,
    loc: 'titles:rate-type',
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
  },
  {
    ...payrollAccessorialStyles,
    type: 'toggle',
    disabled: isEdit,
    loc: 'titles:limited',
    fieldName: GC.FIELD_LIMITED,
  },
  {
    ...payrollAccessorialStyles,
    type: 'number',
    isRequired: limited,
    loc: 'titles:total-amount',
    fieldName: GC.FIELD_TOTAL_AMOUNT,
    fieldGroupDisplay: G.ifElse(limited, 'flex', 'none'),
  },
  {
    ...payrollAccessorialStyles,
    type: 'number',
    loc: 'titles:historical-paid-amount',
    fieldName: GC.FIELD_HISTORICAL_PAID_AMOUNT,
    fieldGroupDisplay: G.ifElse(limited, 'flex', 'none'),
  },
  {
    ...payrollAccessorialStyles,
    type: 'number',
    disabled: true,
    loc: 'titles:paid-amount',
    fieldName: GC.FIELD_PAID_AMOUNT,
    fieldGroupDisplay: G.ifElse(R.and(limited, isEdit), 'flex', 'none'),
  },
  {
    ...payrollAccessorialStyles,
    width: '100%',
    type: 'toggle',
    disabled: true,
    loc: 'titles:fully-paid',
    fieldName: GC.FIELD_FULLY_PAID,
    fieldGroupDisplay: G.ifElse(R.and(limited, isEdit), 'flex', 'none'),
  },
  {
    ...payrollAccessorialStyles,
    width: 680,
    type: 'textarea',
    loc: 'titles:comments',
    fieldName: GC.FIELD_COMMENTS,
  },
];

const payForUnitField = {
  ...payrollAccessorialStyles,
  type: 'select',
  shouldCustomChange: true,
  loc: 'titles:pay-for-unit',
  fieldName: GC.FIELD_PAY_FOR_UNIT,
  options: vendorPayrollAccessorialPayForUnitTypeOptions,
  customChangeHandler: (event: Object, field: any, props: Object) => {
    const { values, setValues } = props;

    const newValues = R.mergeRight(
      values,
      {
        [GC.FIELD_TRUCK_GUIDS]: null,
        [GC.FIELD_PAY_FOR_UNIT]: R.path(['currentTarget', GC.FIELD_VALUE], event),
      },
    );

    setValues(newValues);
  },
};

const trucksField = {
  ...payrollAccessorialStyles,
  mr: 232,
  isRequired: true,
  type: 'multiselect',
  loc: 'titles:trucks',
  options: 'truckOptions',
  fieldName: GC.FIELD_TRUCK_GUIDS,
};

const driversField = {
  ...payrollAccessorialStyles,
  mr: 232,
  isRequired: true,
  type: 'multiselect',
  loc: 'titles:drivers',
  options: 'driverOptions',
  fieldName: GC.FIELD_DRIVER_GUIDS,
};

export const getVendorPayrollAccessorialFieldSettings = (values: Object) => {
  const payForUnitValue = R.prop(GC.FIELD_PAY_FOR_UNIT, values);

  const vendorPayrollAccessorialPayForUnitTypeArray = [
    GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS,
    GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
  ];

  const payForUnitFieldUse = G.ifElse(
    G.notContain(payForUnitValue, vendorPayrollAccessorialPayForUnitTypeArray),
    { ...payForUnitField, mr: 460 },
    payForUnitField,
  );

  const selectedField = R.cond([
    [R.equals(vendorPayrollAccessorialPayForUnitTypeArray[0]), R.always(trucksField)],
    [R.equals(vendorPayrollAccessorialPayForUnitTypeArray[1]), R.always(driversField)],
    [R.T, R.always(null)],
  ])(payForUnitValue);

  return R.pipe(
    R.insert(8, payForUnitFieldUse),
    G.ifElse(
      R.isNil(selectedField),
      R.identity,
      R.insert(9, selectedField),
    ),
  )(getPayrollAssessorialFields(values));
};

export const defaultPayrollAssessorialFields = {
  [GC.FIELD_LIMITED]: false,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_DEDUCTION]: false,
  [GC.FIELD_NON_TAXABLE]: null,
  [GC.FIELD_CHARGE_RATE]: null,
  [GC.FIELD_TRUCK_GUIDS]: null,
  [GC.FIELD_DRIVER_GUIDS]: null,
  [GC.FIELD_FUEL_RELATED]: null,
  [GC.FIELD_TOTAL_AMOUNT]: null,
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: null,
  [GC.FIELD_DRIVER_ASSESSORIAL_GUID]: null,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.FIELD_DRIVER_ACCESSORIAL_RATE_TYPE_FLAT,
  [GC.FIELD_PAY_FOR_UNIT]: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_NONE,
};

const payrollAssessorialValidationSchemaObject = {
  [GC.FIELD_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DRIVER_ASSESSORIAL_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_COMMENTS]: Yup.string()
    .nullable(true)
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
  [GC.FIELD_CHARGE_RATE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .typeError('${type}') // eslint-disable-line
    .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
};

export const getPayrollAssessorialValidationSchemaObject = (values: Object) => {
  let schema = payrollAssessorialValidationSchemaObject;

  if (R.propEq(GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS, GC.FIELD_PAY_FOR_UNIT, values)) {
    schema = R.assoc(
      GC.FIELD_TRUCK_GUIDS,
      G.yupArrayRequired,
      schema,
    );
  }

  if (R.propEq(GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS, GC.FIELD_PAY_FOR_UNIT, values)) {
    schema = R.assoc(
      GC.FIELD_DRIVER_GUIDS,
      G.yupArrayRequired,
      schema,
    );
  }

  if (G.isTrue(R.prop(GC.FIELD_LIMITED, values))) {
    schema = R.assoc(
      GC.FIELD_TOTAL_AMOUNT,
      Yup.number()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .typeError('${type}') // eslint-disable-line
        .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
        .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
      schema,
    );
  }

  return schema;
};
