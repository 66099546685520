// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getCarrierEdiTransactionTypeOptions } from '../../../helpers/options';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'selectMultiple',
    value: GC.FIELD_STATUS,
    name: G.getWindowLocale('titles:status', 'Status'),
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
    options: [
      {
        value: GC.INVOICE_EDI_STATUS_ERROR,
        label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_ERROR),
      },
      {
        value: GC.INVOICE_EDI_STATUS_NO_MATCH,
        label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_NO_MATCH),
      },
      {
        value: GC.INVOICE_EDI_STATUS_PROCESSED,
        label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_PROCESSED),
      },
      {
        value: GC.INVOICE_EDI_STATUS_TRANSACTION_UNASSIGNED,
        label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_TRANSACTION_UNASSIGNED),
      },
    ],
  },
  {
    type: 'boolean',
    value: GC.FIELD_EDI_PROCESSED,
    name: G.getWindowLocale('titles:processed', 'Processed'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_DIRECTION,
    name: G.getWindowLocale('titles:direction', 'Direction'),
    options: [
      {
        value: GC.EDI_DIRECTION_TYPE_INBOUND,
        label: G.getEnumLocale(GC.EDI_DIRECTION_TYPE_INBOUND),
      },
      {
        value: GC.EDI_DIRECTION_TYPE_OUTBOUND,
        label: G.getEnumLocale(GC.EDI_DIRECTION_TYPE_OUTBOUND),
      },
    ],
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_SENDER_ID,
    name: G.getWindowLocale('titles:sender-id', 'Sender ID'),
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_EXTERNAL_ID,
    name: G.getWindowLocale('titles:external-id', 'External ID'),
  },
  {
    type: 'date',
    value: GC.FIELD_EDI_RECEIVED_DATE,
    name: G.getWindowLocale('titles:received-date', 'Received Date'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_EDI_TRANSACTION_TYPE,
    options: getCarrierEdiTransactionTypeOptions(),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: G.getWindowLocale('titles:transaction-type', 'Transaction Type'),
  },
  {
    type: 'date',
    value: GC.FIELD_EDI_TRANSACTION_DATE,
    name: G.getWindowLocale('titles:transaction-date', 'Transaction Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_ORIGINAL_FILE_NAME,
    name: G.getWindowLocale('titles:file-name', 'File Name'),
  },
  {
    type: 'string',
    value: GC.GRC.INVOICE_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:invoice-number', 'Invoice Number'),
  },
  {
    type: 'date',
    value: GC.GRC.INVOICE_INVOICE_DATE,
    name: G.getWindowLocale('titles:invoice-date', 'Invoice Date'),
  },
  {
    type: 'number',
    value: GC.GRC.INVOICE_INVOICE_TOTAL,
    name: G.getWindowLocale('titles:invoice-total', 'Invoice Total'),
  },
  {
    type: 'string',
    value: GC.GRC.INVOICE_INVOICE_CURRENCY,
    name: G.getWindowLocale('titles:invoice-currency', 'Invoice Currency'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_INFO_TEL_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:titles:tel-number', 'Trip #'),
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_SHIPMENT_EXTERNAL_ID,
    name: G.getWindowLocale('titles:shipment-external-id', 'Shipment External ID'),
  },
];
