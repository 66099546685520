import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// settings
import { locationSectionSettings2 } from '../../../settings';
//////////////////////////////////////////////////

export const fuelCardInitFields = {
  [GC.FIELD_TRIP_ID]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_FUEL_CARDS_CARD_ID]: null,
  [GC.FIELD_FUEL_CARDS_TRANSACTION_DATE]: null,
  [GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER]: null,
};

export const getLineInitFields = () => ({
  id: G.genShortId(),
  [GC.FIELD_FUEL_CARDS_TOTAL]: 0,
  [GC.FIELD_FUEL_CARDS_PRICE]: 0,
  [GC.FIELD_FUEL_CARDS_UOM]: '',
  [GC.FIELD_FUEL_CARDS_QUANTITY]: 1,
  [GC.FIELD_FUEL_CARDS_PRODUCT_TYPE]: '',
  [GC.FIELD_FUEL_CARDS_PRODUCT_NAME]: '',
});

const inputWrapperStyles = { mb: 25, width: 305 };
const inputWrapperStyles2 = { mr: 20, flexGrow: 1, flexBasis: 140 };

const firstFieldset = {
  flexDirection: 'column',
  fields: [
    {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      fieldName: GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER,
      label: ['titles:transaction-number', 'Transaction Number'],
    },
    {
      isRequired: true,
      isClearable: true,
      type: 'datePicker',
      timeSelection: true,
      placeholder: 'Select a weekday',
      fieldName: GC.FIELD_FUEL_CARDS_TRANSACTION_DATE,
      label: ['titles:transaction-date', 'Transaction Date'],
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13 },
    },
    {
      type: 'text',
      inputWrapperStyles,
      fieldName: GC.FIELD_TRIP_ID,
      label: ['titles:trip-id', 'Trip ID'],
    },
    {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:card-id', 'Card ID'],
      fieldName: GC.FIELD_FUEL_CARDS_CARD_ID,
    },
    {
      type: 'select',
      isRequired: true,
      inputWrapperStyles,
      options: GC.CURRENCY_OPTIONS,
      fieldName: GC.FIELD_PAYROLL_CURRENCY,
      label: ['titles:currency', 'Currency'],
    },
  ],
};

const productTypeOptions = [
  GC.EMPTY_OPTION_NULLABLE_OBJECT,
  { label: G.getWindowLocale('titles:fuel', 'Fuel'), value: GC.FUEL_CARDS_PRODUCT_TYPE_FUEL },
  { label: G.getWindowLocale('titles:non-fuel', 'Non-Fuel'), value: GC.FUEL_CARDS_PRODUCT_TYPE_NON_FUEL },
  { label: G.getWindowLocale('titles:reefer', 'Reefer'), value: GC.FUEL_CARDS_PRODUCT_TYPE_REEFER },
  { label: G.getWindowLocale('titles:discount', 'Discount'), value: GC.FUEL_CARDS_PRODUCT_TYPE_DISCOUNT },
];

const uomOptions = [
  GC.EMPTY_OPTION_NULLABLE_OBJECT,
  { label: 'Gallon Liquid', value: GC.UOM_GALLON_LIQUID },
  { label: 'Liters', value: GC.UOM_LITER },
];

const setDisabled = ({ lines, index }: Object) =>
  R.pathEq(GC.FUEL_CARDS_PRODUCT_TYPE_DISCOUNT, [index, GC.FIELD_FUEL_CARDS_PRODUCT_TYPE], lines);

export const fuelCardLineFieldset = (index: number) => [
  {
    type: 'select',
    isRequired: true,
    options: productTypeOptions,
    inputWrapperStyles: inputWrapperStyles2,
    label: ['titles:product-type', 'Product Type'],
    fieldName: `${GC.FIELD_FUEL_CARDS_LINES}.${index}.${GC.FIELD_FUEL_CARDS_PRODUCT_TYPE}`,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: inputWrapperStyles2,
    label: ['titles:product-name', 'Product Name'],
    fieldName: `${GC.FIELD_FUEL_CARDS_LINES}.${index}.${GC.FIELD_FUEL_CARDS_PRODUCT_NAME}`,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:price', 'Price'],
    inputWrapperStyles: inputWrapperStyles2,
    fieldName: `${GC.FIELD_FUEL_CARDS_LINES}.${index}.${GC.FIELD_FUEL_CARDS_PRICE}`,
  },
  {
    type: 'select',
    options: uomOptions,
    label: ['titles:uom', 'UOM'],
    inputWrapperStyles: inputWrapperStyles2,
    disabled: (_: any, { lines }: Object) => setDisabled({ lines, index }),
    fieldName: `${GC.FIELD_FUEL_CARDS_LINES}.${index}.${GC.FIELD_FUEL_CARDS_UOM}`,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:quantity', 'Quantity'],
    inputWrapperStyles: { ...inputWrapperStyles2, mr: 0 },
    disabled: (_: any, { lines }: Object) => setDisabled({ lines, index }),
    fieldName: `${GC.FIELD_FUEL_CARDS_LINES}.${index}.${GC.FIELD_FUEL_CARDS_QUANTITY}`,
  },
];

export const assignmentFieldSettings = [
  {
    type: 'reactSelect',
    options: 'driverOptions',
    shouldCustomChange: true,
    fieldName: GC.FIELD_DRIVER_GUID,
    label: ['titles:driver', 'Driver'],
    customDisabledFunction: 'handleDisableDriver',
    inputWrapperStyles: { width: 305, zIndex: 14 },
  },
  {
    type: 'reactSelect',
    options: 'truckOptions',
    fieldName: GC.FIELD_TRUCK_GUID,
    label: ['titles:truck', 'Truck'],
    customDisabledFunction: 'handleDisableTruck',
    inputWrapperStyles: { width: 305, zIndex: 14 },
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_SOURCE,
    label: ['titles:source', 'Source'],
    inputWrapperStyles: { width: 305 },
    customDisabledFunction: 'handleDisableSource',
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.FLEET_INTEGRATION_TYPE_EFS,
        label: G.getWindowLocale('titles:efs', 'EFS'),
      },
      {
        value: GC.FUEL_CARDS_TYPE_QUIKQ,
        label: G.getWindowLocale('titles:quikq', 'QUIKQ'),
      },
      {
        value: GC.FUEL_CARDS_TYPE_SELF_FUELED,
        label: G.getWindowLocale('titles:self-fueled', 'Self Fueled'),
      },
      {
        value: GC.FUEL_CARDS_TYPE_COMDATA,
        label: G.getWindowLocale('titles:comdata', 'Comdata'),
      },
      {
        label: 'BVD Group',
        value: GC.FUEL_CARDS_TYPE_BVD,
      },
      {
        label: 'Relay',
        value: GC.FUEL_CARDS_TYPE_RELAY,
      },
    ],
  },
];

export const fuelCardSettings = [
  firstFieldset,
  ...locationSectionSettings2,
];

const lineDefaultFields = {
  [GC.FIELD_FUEL_CARDS_UOM]: '',
  [GC.FIELD_FUEL_CARDS_TOTAL]: 0,
  [GC.FIELD_FUEL_CARDS_PRICE]: 0,
  [GC.FIELD_FUEL_CARDS_QUANTITY]: 1,
  [GC.FIELD_FUEL_CARDS_PRODUCT_TYPE]: '',
  [GC.FIELD_FUEL_CARDS_PRODUCT_NAME]: '',
};

export const defaultFuelCardFields = {
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  saveAndAddNew: false,
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_TRIP_ID]: '',
  [GC.FIELD_ADDRESS]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_LONGITUDE]: '',
  [GC.FIELD_LOCATION_NAME]: '',
  [GC.FIELD_PAYROLL_CURRENCY]: '',
  [GC.FIELD_FUEL_CARDS_CARD_ID]: '',
  [GC.FIELD_FUEL_CARDS_TRANSACTION_DATE]: '',
  [GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER]: '',
  [GC.FIELD_SOURCE]: GC.FUEL_CARDS_TYPE_SELF_FUELED,
  [GC.FIELD_FUEL_CARDS_LINES]: R.of(Array, getLineInitFields()),
};
