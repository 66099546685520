import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
// components
import { LocalLoader } from '../../../components/local-loader';
import { Table, TitleRowComponent } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetFileAndCreateSrc } from '../../../helpers/api-async';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// ui
import { Box, Flex, FixedBox, ActionButton } from '../../../ui';
// feature inspections
import ImagePreview from './image-preview';
import { report, tableSettings, columnSettings } from '../settings/check-list-table-settings';
//////////////////////////////////////////////////

const bgBlue = G.getTheme('colors.bgBlue');
const whiteColor = G.getTheme('colors.white');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const closeButtonStyles = {
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 15,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textColor: greyMatterhornColor,
  borderColor: greyMatterhornColor,
};

const Section = ({ name, document, components }: Object) => {
  const subTitle = (
    <Box bg={bgBlue}>
      <Flex height={40} fontSize={16} boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'>
        <Box pl={30} pr={20} fontWeight='bold' color={greyMatterhornColor}>
          {G.getWindowLocale('titles:section', 'Section')}: {name}
        </Box>
        {document && <ImagePreview {...document} />}
      </Flex>
    </Box>
  );

  if (G.isNilOrEmpty(components)) return subTitle;

  const tableData = {
    report,
    subTitle,
    tableSettings,
    columnSettings,
    withoutBorder: true,
    itemList: components,
    withoutWaypoint: true,
    tableWrapperOverflow: 'unset',
  };

  return <Table {...tableData} />;
};

const CheckList = (props: Object) => {
  const {
    guid,
    truckGuid,
    closeModal,
  } = props;

  const [inspection, setInspection] = useState(null);
  const [signatureSrc, setSignatureSrc] = useState(null);

  useEffect(() => {
    const getInspectionDetails = async () => {
      try {
        const endpoint = G.isNotNil(truckGuid) ?
          endpointsMap.getTruckInspectionDetails(guid) :
          endpointsMap.getTrailerInspectionDetails(guid);

        const { data, status } = await sendRequest('get', endpoint);

        if (R.and(G.isNotNilAndNotEmpty(data), G.isResponseSuccess(status))) {
          const { issues, sections } = data;

          const indexedIssues = R.indexBy(R.prop(GC.FIELD_INSPECTION_COMPONENT_GUID), issues);

          const dataToSet = R.assoc(
            'sections',
            R.map((section: Object) => {
              const { components } = section;

              return R.assoc(
                'components',
                R.map((component: Object) => R.assoc(
                  'issue',
                  R.pathOr(null, [G.getGuidFromObject(component)], indexedIssues),
                  component,
                ), components),
                section,
              );
            }, sections),
            data,
          );

          setInspection(dataToSet);

          if (G.isNotNilAndNotEmpty(R.prop(GC.FIELD_SIGNATURE_FILE_NAME, data))) {
            const src = await asyncGetFileAndCreateSrc(endpointsMap.getFleetInspectionsDownloadSignature(guid));

            setSignatureSrc(src);
          }
        }
      } catch (e) {
        G.handleException('error', 'getInspectionDetails exception');
      }
    };

    getInspectionDetails();
  }, []);

  return (
    <Flex
      flexDirection='column'
      alignItems='flex-start'
      height='-webkit-fill-available'
    >
      {
        G.isNotNil(inspection) &&
        <Flex
          p={15}
          height={60}
          width='calc(100% - 30px)'
          color={greyMatterhornColor}
        >
          <Box fontSize={20}>
            {G.getWindowLocale('titles:inspection', 'Inspection')} - {R.prop(GC.FIELD_NAME, inspection)}
          </Box>
          <Box mr={15} ml='auto'>
            {G.getWindowLocale('titles:created-by', 'Created By')}: {R.prop(GC.FIELD_CREATED_BY, inspection)}
          </Box>
          {
            signatureSrc &&
            <img
              height='60px'
              src={signatureSrc}
              alt={R.prop(GC.FIELD_SIGNATURE_FILE_NAME, inspection)}
            />
          }
        </Flex>
      }
      {
        G.isNotNil(inspection) ?
          <Box pb={60} width='calc(100% - 32px)' overflow='auto'>
            <TitleRowComponent
              report={report}
              tableSettings={tableSettings}
              columnSettings={columnSettings}
            />
            {
              G.isNotNilAndNotEmpty(inspection.sections) &&
              inspection.sections.map((data: Object, i: number) => (
                <Section key={i} {...data} />
              ))
            }
          </Box> :
          <LocalLoader localLoaderOpen={true} />
      }
      <FixedBox
        p={15}
        mt='auto'
        bottom='0'
        width='100%'
        bg={whiteColor}
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
      >
        <ActionButton {...closeButtonStyles} onClick={closeModal}>
          {G.getWindowLocale('actions:close', 'Close')}
        </ActionButton>
      </FixedBox>
    </Flex>
  );
};

export default CheckList;
