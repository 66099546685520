import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
// feature fleet
import { FLEET_INSURANCE_FORM } from '../constants';
import InsuranceFormComponent from '../components/insurance-form';
//////////////////////////////////////////////////

export const withAddOrEditInsurance = ({ fleetType }: Object) => compose(
  withHandlers({
    handleAddInsurance: (props: Object) => () => {
      const modalContentMap = {
        truck: {
          requestGuidKey: 'truckGuid',
          configs: R.path(['truckConfigs'], props),
          submitAction: R.path(['createTruckInsuranceRequest'], props),
        },
        trailer: {
          requestGuidKey: 'trailerGuid',
          configs: props.trailerConfigs,
          submitAction: props.createTrailerInsuranceRequest,
        },
      };
      const component = (
        <InsuranceFormComponent
          closeModal={props.closeModal}
          requestGuid={R.path(['initialValues', 'guid'], props)}
          configs={R.path([fleetType, 'configs'], modalContentMap)}
          submitAction={R.path([fleetType, 'submitAction'], modalContentMap)}
          requestGuidKey={R.path([fleetType, 'requestGuidKey'], modalContentMap)} />
      );
      const modal = {
        component,
        options: {
          width: '440px',
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:add-insurance', 'Add Insurance'),
          controlButtons: [
            {
              type: 'button',
              id: FLEET_INSURANCE_FORM,
              name: G.getWindowLocale('actions:add', 'Add'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleEditInsurance: (props: Object) => (insurance: Object) => {
      const modalContentMap = {
        truck: {
          requestGuidKey: 'truckGuid',
          configs: props.truckConfigs,
          submitAction: props.updateTruckInsuranceRequest,
        },
        trailer: {
          requestGuidKey: 'trailerGuid',
          configs: props.trailerConfigs,
          submitAction: props.updateTrailerInsuranceRequest,
        },
      };
      const component = (
        <InsuranceFormComponent
          initialValues={insurance}
          closeModal={props.closeModal}
          requestGuid={R.path(['initialValues', 'guid'], props)}
          configs={R.path([fleetType, 'configs'], modalContentMap)}
          submitAction={R.path([fleetType, 'submitAction'], modalContentMap)}
          requestGuidKey={R.path([fleetType, 'requestGuidKey'], modalContentMap)} />
      );
      const modal = {
        component,
        options: {
          width: '440px',
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:edit-insurance', 'Edit Insurance'),
          controlButtons: [
            {
              type: 'button',
              id: FLEET_INSURANCE_FORM,
              name: G.getWindowLocale('actions:update', 'Update'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleDeleteInsurance: (props: Object) => (insurance: Object) => {
      const { openModal, closeModal } = props;
      const modalContentMap = {
        truck: {
          action: () => {
            closeModal();
            props.deleteTruckInsuranceRequest(insurance.guid);
          },
        },
        trailer: {
          action: () => {
            closeModal();
            props.deleteTrailerInsuranceRequest(insurance.guid);
          },
        },
      };
      const component = (
        <ConfirmComponent
          name={R.path(['insuranceType', 'displayedValue'], insurance)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
      );
      const modal = {
        component,
        options: {
          width: '600px',
          height: '160px',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: R.path([fleetType, 'action'], modalContentMap),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);
