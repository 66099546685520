import React from 'react';
import * as R from 'ramda';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import { createStructuredSelector } from 'reselect';
import { withFormik, setNestedObjectValues } from 'formik';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
import { openLoader, closeLoader } from '../../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../../branch/selectors';
import SectionDivider from '../../../../new-do/components/section-divider';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../../../hocs';
// ui
import { Box } from '../../../../../ui';
// utilities
import { sendRequest } from '../../../../../utilities/http';
import endpointsMap from '../../../../../utilities/endpoints';
// feature configs
import { PreviewWrapper } from '../../../ui';
import { getOptions } from '../../../settings/notification-group';
import { MAIL_TEMPLATE_PLACEHOLDERS_MAP } from '../../../constants';
import { ReferenceVariablesSection } from './reference-variables-section';
import { InterpolationVariablesSection } from './interpolation-variables-section';
import {
  mapNameToLabelValue,
  getPlaceholdersToUse,
  isBooleanPlaceholder,
  mapNameKeyToLabelValue,
  getValuesWithEditorFields,
 } from '../../../helpers';
import {
  getMailTemplateTypesRequest,
  getMailTemplateByKeyRequest,
  getMailTemplateNamesByTypeRequest,
} from '../actions';
import {
  makeSelectMailTemplateNames,
  makeSelectMailTemplateTypes,
  makeSelectMailTemplateByKey,
} from '../../../selectors';
import {
  getMainGroupFields,
  getSignatureGroupFields,
  getMailTemplateFieldSettings,
  getDefaultMailTemplateFields,
  getMailTemplateValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getActionButtons = ({ handleShowPreviewData, handleShowMailTemplatePreview }: Object) => {
  const buttonStyles = {
    bgColor: 'none',
    background: 'none',
    border: '1px solid',
    borderRadius: '5px',
    textColor: G.getTheme('colors.dark.blue'),
    borderColor: G.getTheme('colors.dark.blue'),
  };

  return [
    {
      buttonStyles,
      action: handleShowPreviewData,
      displayText: G.getWindowLocale('titles:preview-data', 'Preview Data'),
    },
    {
      buttonStyles,
      action: handleShowMailTemplatePreview,
      displayText: G.getWindowLocale('actions:preview', 'Preview'),
    },
  ];
};

const enhance = compose(
  withState('bodyEditorState', 'setBodyEditorState', {}),
  withState('referenceVariables', 'setReferenceVariables', []),
  withState('farewellEditorState', 'setFarewellEditorState', {}),
  withState('interpolationVariables', 'setInterpolationVariables', []),
  withState(
    'templateType',
    'setTemplateType',
    ({ initialValues }: Object) => R.pathOr('', [GC.FIELD_TEMPLATE_TYPE, GC.FIELD_NAME], initialValues),
  ),
  withState('templateNameKey', 'setTemplateNameKey', ({ initialValues }: Object) => R.pathOr(
    '',
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_TEMPLATE_KEY],
    initialValues,
  )),
  withFormik({
    enableReinitialize: true,
    validationSchema: ({ mailTemplate }: Object) => getMailTemplateValidationSchema(mailTemplate),
    handleSubmit: (values: Object, { props }: Object) => {
      const { editMode, mailTemplate, submitAction, initialValues, bodyEditorState, farewellEditorState } = props;

      const placeholdersKeys = R.pluck(GC.FIELD_KEY, R.pathOr([], ['placeholders'], mailTemplate));

      const valuesWithEditorFields = getValuesWithEditorFields({ values, bodyEditorState, farewellEditorState });

      const placeholders = getPlaceholdersToUse({
        editMode,
        mailTemplate,
        initialValues,
        values: valuesWithEditorFields,
      });

      const data = {
        ...R.omit(
          placeholdersKeys,
          R.omit([GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES], values),
        ),
        placeholders,
      };

      submitAction(data);
    },
    mapPropsToValues: (props: Object) => {
      const initialValues = R.pathOr({}, ['initialValues'], props);

      const placeholders = R.compose(
        R.fromPairs,
        R.map(({ key, value }: Object) => {
          let valueToUse = value;

          if (isBooleanPlaceholder(key)) {
            valueToUse = R.equals(value, 'yes');
          }

          return [key, valueToUse];
        }),
        R.pathOr([], ['placeholders']),
      )(initialValues);

      const initialValuesToUse = {
        ...initialValues,
        ...placeholders,
        [GC.FIELD_TEMPLATE_TYPE]: R.pathOr('', [GC.FIELD_TEMPLATE_TYPE], props),
      };

      return G.setInitialFormikValues(
        getDefaultMailTemplateFields(props),
        initialValuesToUse,
      );
    },
  }),
  withHandlers({
    handleGetInterpolationVariables: (props: Object) => async () => {
      const { templateNameKey, setInterpolationVariables } = props;

      const options = {
        params: {
          [GC.FIELD_KEY]: templateNameKey,
        },
      };

      const res = await sendRequest('get', endpointsMap.mailTemplateVariables, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInterpolationVariables(data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  withHandlers({
    handleCustomChange: (props: Object) => (fieldValue: Object, fieldName: Object) => {
      const {
        setFieldValue,
        templateNameKey,
        cloReferenceTypes,
        telReferenceTypes,
        setReferenceVariables,
      } = props;

      setFieldValue(fieldName, fieldValue);

      const references = G.ifElse(R.includes(GC.FIELD_CLO, templateNameKey), cloReferenceTypes, telReferenceTypes);

      const referenceVariables = R.compose(
        R.map(({ label }: Object) => {
          const labelToUse = R.replace(/[^a-zA-Z0-9\u00C0-\u1FFF]/g, '', label);

          return `\${REF_${labelToUse}}`;
        }),
        R.filter(({ value }: Object) => R.includes(value, fieldValue)),
      )(references);

      setReferenceVariables(referenceVariables);
    },
    handleDisableTemplateName: ({ editMode }: Object) => ({ templateType }: Object) => R.or(
      G.isTrue(editMode),
      G.isNilOrEmpty(templateType),
    ),
    handleShowPreviewData: (props: Object) => async () => {
      const { openModal, openLoader, closeLoader, templateNameKey } = props;

      openLoader();

      const options = {
        params: {
          [GC.FIELD_KEY]: templateNameKey,
        },
      };

      const res = await sendRequest('get', endpointsMap.mailTemplatePreviewDataFile, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const component = (
          <ReactJson
            src={data}
            name={false}
            quotesOnKeys={false}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {
            width: 450,
            overflow: 'auto',
            maxHeight: '80vh',
            outsideCloseButton: true,
            title: G.getWindowLocale('titles:preview-data', 'Preview Data'),
          },
        };

        openModal(modal);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
    handleShowMailTemplatePreview: (props: Object) => async () => {
      const {
        errors,
        values,
        editMode,
        openModal,
        setTouched,
        openLoader,
        closeLoader,
        mailTemplate,
        initialValues,
        templateNameKey,
        bodyEditorState,
        farewellEditorState,
        currentEnterpriseGuid,
      } = props;

      if (G.isNotNilAndNotEmpty(errors)) return setTouched(setNestedObjectValues(values, true));

      openLoader();

      const valuesWithEditorFields = getValuesWithEditorFields({ values, bodyEditorState, farewellEditorState });

      const placeholders = getPlaceholdersToUse({
        editMode,
        mailTemplate,
        initialValues,
        values: valuesWithEditorFields,
      });

      const options = {
        data: {
          currentEnterpriseGuid,
          [GC.FIELD_KEY]: templateNameKey,
          [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_SUBJECT]: G.getPropFromObject(
            GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_SUBJECT,
            values,
          ),
          placeholders,
        },
      };

      const res = await sendRequest('post', endpointsMap.mailTemplatePreview, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const { html, subject } = data;

        const component = (
          <Box p={15} maxHeight='80vh' overflowY='auto'>
            <Box mb={20}>{subject}</Box>
            <PreviewWrapper>{parse(R.or(html, ''))}</PreviewWrapper>
          </Box>
        );

        const modal = {
          p: '0px',
          component,
          options: {
            width: 700,
            height: 'auto',
            outsideCloseButton: true,
            title: `${G.getWindowLocale('actions:preview', 'Preview')}: ${R.pathOr('', [GC.FIELD_NAME], values)}`,
          },
        };

        openModal(modal);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
    handleGetMailTemplateNamesByTypeRequest: (props: Object) => () => {
      const { editMode, templateType, getMailTemplateNamesByTypeRequest } = props;

      if (G.isNotNilAndNotEmpty(templateType)) getMailTemplateNamesByTypeRequest({ editMode, templateType });
    },
    handleGetMailTemplateWithInterpolationVariables: (props: Object) => () => {
      const { templateNameKey, getMailTemplateByKeyRequest, handleGetInterpolationVariables } = props;

      if (G.isNotNilAndNotEmpty(templateNameKey)) {
        getMailTemplateByKeyRequest(templateNameKey);
        handleGetInterpolationVariables();
      }
    },
    handleCustomChangeEditor: (props: Object) => ({ value, fieldName }: Object) => {
      const { setBodyEditorState, setFarewellEditorState } = props;

      if (R.equals(fieldName, MAIL_TEMPLATE_PLACEHOLDERS_MAP.BODY)) {
        setBodyEditorState(value);
      }

      if (R.equals(fieldName, MAIL_TEMPLATE_PLACEHOLDERS_MAP.FAREWELL)) {
        setFarewellEditorState(value);
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'templateType',
    callbackName: 'handleGetMailTemplateNamesByTypeRequest',
  }),
  withComponentDidUpdatePropCallback({
    propName: 'templateNameKey',
    callbackName: 'handleGetMailTemplateWithInterpolationVariables',
  }),
  lifecycle({
    componentDidMount() {
      const {
        editMode,
        getMailTemplateTypesRequest,
        handleGetMailTemplateNamesByTypeRequest,
        handleGetMailTemplateWithInterpolationVariables,
      } = this.props;

      getMailTemplateTypesRequest(editMode);

      if (G.isTrue(editMode)) {
        handleGetMailTemplateNamesByTypeRequest();
        handleGetMailTemplateWithInterpolationVariables();
      }
    },
  }),
  pure,
);

const PlaceholdersSection = (props: Object) => {
  const { mailTemplate, interpolationVariables, handleCustomChangeEditor } = props;

  const placeholders = R.pathOr([], ['placeholders'], mailTemplate);

  const mainGroupPlaceholders = R.filter(R.propEq('main', 'groupName'), placeholders);
  const signatureGroupPlaceholders = R.filter(R.propEq('signature', 'groupName'), placeholders);

  return (
    <Box>
      <InterpolationVariablesSection interpolationVariables={interpolationVariables} />
      <ReferenceVariablesSection {...props} />
      <SectionDivider
        mt='0px'
        text={G.getWindowLocale('titles:body', 'Body', { caseAction: 'upperCase' })}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ p: '0 15px'}}
        handleCustomChange={handleCustomChangeEditor}
        fields={getMainGroupFields(mainGroupPlaceholders)}
      />
      <SectionDivider
        mt='0px'
        text={G.getWindowLocale('titles:signature', 'Signature', { caseAction: 'upperCase' })}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={getSignatureGroupFields(signatureGroupPlaceholders)}
        fieldsWrapperStyles={{ p: '0 15px', justifyContent: 'space-between' }}
      />
    </Box>
  );
};

const MailTemplateForm = (props: Object) => {
  const {
    editMode,
    handleSubmit,
    templateNameKey,
    setTemplateType,
    mailTemplateTypes,
    mailTemplateNames,
    setTemplateNameKey,
    setReferenceVariables,
    handleShowPreviewData,
    setInterpolationVariables,
    handleDisableTemplateName,
    handleShowMailTemplatePreview,
  } = props;

  const mailTemplateTypesOptions = getOptions(mapNameToLabelValue(mailTemplateTypes));
  const mailTemplateNamesOptions = getOptions(mapNameKeyToLabelValue(mailTemplateNames));

  return (
    <Box maxHeight='87vh' overflowY='auto'>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikProps(props)}
          setTemplateType={setTemplateType}
          setTemplateNameKey={setTemplateNameKey}
          handlers={{ handleDisableTemplateName }}
          fieldsWrapperStyles={{ mt: 30, p: '0 15px'}}
          setReferenceVariables={setReferenceVariables}
          setInterpolationVariables={setInterpolationVariables}
          fields={getMailTemplateFieldSettings({ templateNameKey, disabled: editMode })}
          mailTemplateTypesOptions={G.addEmptyOptionToDropDown(mailTemplateTypesOptions)}
          mailTemplateNamesOptions={G.addEmptyOptionToDropDown(mailTemplateNamesOptions)}
        />
        {G.isNotNilAndNotEmpty(templateNameKey) && <PlaceholdersSection {...props} />}
        <FormFooter2
          boxStyles={{ p: '0 15px 15px 15px' }}
          actionButtons={G.ifElse(
            G.isNilOrEmpty(templateNameKey),
            [],
            getActionButtons({ handleShowPreviewData, handleShowMailTemplatePreview }),
          )}
        />
      </form>
    </Box>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  mailTemplate: makeSelectMailTemplateByKey(state),
  mailTemplateTypes: makeSelectMailTemplateTypes(state),
  mailTemplateNames: makeSelectMailTemplateNames(state),
  currentEnterpriseGuid: makeSelectCurrentBranchGuid(state),
}));


export default connect(mapStateToProps, {
  openLoader,
  closeLoader,
  getMailTemplateTypesRequest,
  getMailTemplateByKeyRequest,
  getMailTemplateNamesByTypeRequest,
})(enhance(MailTemplateForm));
