import React from 'react';
// forms
import { SingleInputForm } from '../../../forms/forms/single-input-form';
// helpers/constants
//////////////////////////////////////////////////

const RouteInfo = ({ label, closeModal, createRequest }: Object) => (
  <SingleInputForm
    fieldWidth='100%'
    fieldLabel={label}
    submitAction={(routeName: string) => {
      closeModal();
      createRequest({ routeName });
    }}
  />
);

export default RouteInfo;
