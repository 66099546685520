import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// forms
import AccessorialForm from '../../../forms/forms/accessorial-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncSharedAccessorials } from '../../../hocs';
// feature carrier
import SharedAccessorialForm from '../components/shared-accessorial-form';
//////////////////////////////////////////////////

export const carrierAccessorialsEnhance = compose(
  withAsyncSharedAccessorials,
  withHandlers({
    handleAddSharedAccessorials: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        relatedGuid,
        sharedAccessorials,
        addSharedAccessorialsEndpoint,
        successSharedAccessorialsAction,
        addOrRemoveSharedAccessorialsRequest,
      } = props;

      const component = (
        <SharedAccessorialForm
          closeModal={closeModal}
          relatedGuid={relatedGuid}
          sharedAccessorials={sharedAccessorials}
          submitAction={addOrRemoveSharedAccessorialsRequest}
          addSharedAccessorialsEndpoint={addSharedAccessorialsEndpoint}
          successSharedAccessorialsAction={successSharedAccessorialsAction}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          title: G.getWindowLocale('titles:add-shared-accessorials ', 'Add Shared Accessorials'),
        },
      };

      openModal(modal);
    },
    handleRemoveSharedAccessorial: (props: Object) => ({ guid }: Object) => {
      const {
        openModal,
        relatedGuid,
        successSharedAccessorialsAction,
        removeSharedAccessorialsEndpoint,
        addOrRemoveSharedAccessorialsRequest,
      } = props;

      const removeData = {
        successSharedAccessorialsAction,
        endpoint: removeSharedAccessorialsEndpoint,
        data: {
          [GC.FIELD_PRIMARY_OBJECT_GUID]: relatedGuid,
          [GC.FIELD_ACCESSORIAL_SHARED_ACCESSORIAL_GUIDS]: R.of(Array, guid),
        },
      };

      const component = (
        <ConfirmComponent
          textLocale={
            G.getWindowLocale('messages:fleet:driver:delete-assessorial', 'Are you sure you want delete Assessorial?')
          }
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => addOrRemoveSharedAccessorialsRequest(removeData),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleClickAddAssessorial: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        relatedGuid,
        createAction,
        relatedEntityField,
        accessorialConfigParams,
      } = props;

      const initialValues = { [relatedEntityField]: relatedGuid };

      const component = (
        <AccessorialForm
          {...accessorialConfigParams}
          entity='carrier'
          closeModal={closeModal}
          submitAction={createAction}
          initialValues={initialValues}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:add-accessorial ', 'Add Accessorial'),
        },
      };

      openModal(modal);
    },
    handleEditAssessorial: (props: Object) => (entity: Object, titleKey: string) => {
      const {
        openModal,
        closeModal,
        relatedGuid,
        updateAction,
        relatedEntityField,
        accessorialConfigParams,
      } = props;

      const initialValues = R.assoc(relatedEntityField, relatedGuid, entity);

      const component = (
        <AccessorialForm
          {...accessorialConfigParams}
          entity='carrier'
          closeModal={closeModal}
          submitAction={updateAction}
          initialValues={initialValues}
        />
      );

      const title = G.getWindowLocale(R.or(titleKey, 'titles:edit-accessorial'), 'Edit Accessorial');

      const modal = {
        p: 15,
        component,
        options: {
          title,
          height: 'auto',
        },
      };

      openModal(modal);
    },
    handleDeleteAssessorial: (props: Object) => ({ guid }: Object) => {
      const {openModal, closeModal, deleteAction } = props;

      const confirmationContent = (
        <ConfirmComponent
          textLocale={
            G.getWindowLocale('messages:fleet:driver:delete-assessorial', 'Are you sure you want delete Assessorial?')
          }
        />
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteAction(guid);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
