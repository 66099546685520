import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// components
import TextComponent from '../../../../components/text';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { DRIVER_TYPE_OPTIONS, ITEM_ODOMETER_UOM_OPTIONS } from '../../../../helpers/options';
// feature fleet-profile
import ChangePassword from '../components/change-password';
//////////////////////////////////////////////////

const validationSchema = Yup.lazy((values: Object) => Yup.object().shape({
  [GC.FIELD_FIRST_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_LAST_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_MIDDLE_INITIAL]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 1))
    .max(1, G.getShouldBeFromToLocaleTxt(0, 1)),
  [GC.FIELD_NICK_NAME]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_FLEET_DRIVER_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER]: Yup.string()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
  [GC.FIELD_SECONDARY_PHONE_NUMBER]: Yup.string()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
  [GC.FIELD_EMPLOYEE_NUMBER]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_PAYED_HOURS]: Yup.number()
    .nullable(true)
    .notRequired()
    .positive(G.getShouldBePositiveLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(0, 24))
    .max(24, G.getShouldBeLessOrEqualLocaleTxt(24)),
  [GC.FIELD_UNEMPLOYMENT_REASON]: G.ifElse(
    R.propEq(false, GC.FIELD_DRIVER_EMPLOYED, values),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.FIELD_PREFER_DISTANCE_RATE]: Yup.number()
    .nullable(true)
    .positive(G.getShouldBeIntegerLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt())
    .max(999, G.getShouldBeLessOrEqualLocaleTxt(999)),
  [GC.FIELD_PREFER_DISTANCE_RATE_UOM]: G.ifElse(
    G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_PREFER_DISTANCE_RATE, values)),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

const handleChangeTerminationDate = ({ value, props }: Object) => {
  const { values, setValues, setFieldValue } = props;

  if (R.not(G.isValidMoment(value))) {
    return setFieldValue(GC.FIELD_FLEET_TERMINATION_DATE, G.ifElse(R.isEmpty(value), null, value));
  }

  const currentHireDateFormValue = G.getPropFromObject(GC.FIELD_FLEET_CURRENT_HIRE_DATE, values);
  const currentHireDate = G.makeMomentInstance(G.ifElse(G.isNotNilAndNotEmpty(currentHireDateFormValue), currentHireDateFormValue));

  const currentHireDateFormattedValue = currentHireDate.format(GC.DEFAULT_DATE_FORMAT);

  const terminationDate = G.makeMomentInstance(value);
  const terminationDateFormattedValue = terminationDate.format(GC.DEFAULT_DATE_FORMAT);

  const condition = R.or(
    G.isNilOrEmpty(currentHireDateFormValue),
    R.gt(terminationDate.diff(currentHireDate), 0),
  );

  if (condition) {
    return setValues(R.mergeRight(
      values,
      {
        [GC.FIELD_DRIVER_EMPLOYED]: false,
        [GC.FIELD_FLEET_CURRENT_HIRE_DATE]: null,
        [GC.DUTY_STATUS_CODE_TYPE_ON_DUTY]: false,
        [GC.FIELD_FLEET_TERMINATION_DATE]: terminationDateFormattedValue,
        [GC.FIELD_FLEET_PREVIOUS_HIRE_DATE]: currentHireDateFormattedValue,
      },
    ));
  }

  return setFieldValue(GC.FIELD_FLEET_TERMINATION_DATE, terminationDateFormattedValue);
};

const handleChangeHireDate = ({ value, props }: Object) => {
  const { values, setValues, setFieldValue } = props;

  if (R.not(G.isValidMoment(value))) {
    return setFieldValue(GC.FIELD_FLEET_CURRENT_HIRE_DATE, G.ifElse(R.isEmpty(value), null, value));
  }

  const currentHireDate = G.makeMomentInstance(value);
  const currentHireDateValue = currentHireDate.format(GC.DEFAULT_DATE_FORMAT);
  const currentTerminationDate = G.getPropFromObject(GC.FIELD_FLEET_TERMINATION_DATE, values);
  const terminationDate = R.propOr(G.getCurrentDate(), GC.FIELD_FLEET_TERMINATION_DATE, values);
  const condition = R.or(G.isNilOrEmpty(currentTerminationDate), R.gt(currentHireDate.diff(terminationDate), 0));

  if (condition) {
    return setValues(R.mergeRight(
      values,
      {
        [GC.FIELD_DRIVER_EMPLOYED]: true,
        [GC.FIELD_FLEET_TERMINATION_DATE]: null,
        [GC.FIELD_FLEET_CURRENT_HIRE_DATE]: currentHireDateValue,
      },
    ));
  }

  return setFieldValue(GC.FIELD_FLEET_CURRENT_HIRE_DATE, currentHireDateValue);
};

const accountingPermissions = [PC.FLEET_DRIVER_ACCOUNTING_READ, PC.FLEET_DRIVER_ACCOUNTING_WRITE];

const preferDisranceRateUomTitle =
  G.getWindowLocale('titles:preferred-distance-rate-uom', 'Preferred Distance Rate UOM');

export const settings = {
  [GC.FIELD_FIRST_NAME]: {
    isRequired: true,
    name: 'titles:first-name',
  },
  [GC.FIELD_LAST_NAME]: {
    isRequired: true,
    name: 'titles:last-name',
  },
  [GC.FIELD_MIDDLE_INITIAL]: {
    name: 'titles:middle-initial',
  },
  [GC.FIELD_NICK_NAME]: {
    name: 'titles:nick-name',
  },
  [GC.FIELD_FLEET_DRIVER_NUMBER]: {
    name: 'titles:driver-number',
  },
  [GC.FIELD_USER_LOGIN_ID]: {
    disabled: true,
    additionalLabelComponent: <ChangePassword />,
    additionalLabelStyles: {
      right: 0,
      left: '5px',
      width: 'calc(100% - 10px)',
      justifyContent: 'space-between',
    },
  },
  assignedDispatcherGuid: {
    type: 'select',
    options: 'usersGeneral',
    name: 'titles:dispatcher',
  },
  dispatchingGroupGuid: {
    type: 'select',
    name: 'titles:dispatching-group',
    options: 'dispatchingGroupOptions',
  },
  [GC.FIELD_EMAIL]: {
    isRequired: true,
    name: 'titles:email',
  },
  [GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER]: {
    type: 'phoneNumber',
    name: 'titles:primary-phone',
  },
  [GC.FIELD_SECONDARY_PHONE_NUMBER]: {
    type: 'phoneNumber',
    name: 'titles:secondary-phone',
  },
  [GC.FIELD_DRIVER_TYPE]: {
    type: 'select',
    name: 'titles:driver-type',
    options: G.addEmptyOptionToDropDown(R.values(DRIVER_TYPE_OPTIONS)),
  },
  [GC.FIELD_EMPLOYEE_NUMBER]: {
    name: 'titles:employee-number',
    validate: [G.curriedIsEmptyOrMinMax(0, 40)],
  },
  gender: {
    type: 'select',
    name: 'titles:gender',
    options: ['', 'Male', 'Female'],
  },
  [GC.FIELD_FLEET_BIRTH_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:birth-date',
    maxDate: G.getCurrentDate(),
  },
  [GC.FIELD_DRIVER_EMPLOYED]: {
    type: 'toggle',
    name: 'titles:employed',
    info: G.getWindowLocale('messages:driver:info:employe'),
    additionalInputWrapperSettings: { mr: 20, mb: 10, height: 36, minWidth: 'unset', width: 'max-content' },
  },
  [GC.FIELD_SHOW_VENDOR_PAYROLL]: {
    type: 'toggle',
    name: 'titles:show-vendor-payroll',
    fieldName: GC.FIELD_SHOW_VENDOR_PAYROLL,
    additionalInputWrapperSettings: { mr: 20, mb: 10, width: 'max-content' },
  },
  [GC.FIELD_UNEMPLOYMENT_REASON]: {
    type: 'select',
    isRequired: true,
    name: 'titles:status-reason',
    options: 'unemploymentReason',
    additionalInputWrapperSettings: {
      mr: 20,
      mb: 10,
      width: 200,
      display: (props: Object) => G.ifElse(
        R.pathEq(true, ['values', GC.FIELD_DRIVER_EMPLOYED], props),
        'none',
      ),
      visibility: (props: Object) => G.ifElse(
        R.pathEq(false, ['values', GC.FIELD_DRIVER_EMPLOYED], props),
        'visible',
        'hidden',
      ),
    },
  },
  [GC.FIELD_FLEET_CURRENT_HIRE_DATE]: {
    isClearable: true,
    type: 'datePicker',
    shouldCustomChange: true,
    name: 'titles:current-hire-date',
    customChangeHandler2: handleChangeHireDate,
    customChangeHandler: (event: Object, _: any, props: Object) =>
      handleChangeHireDate({ props, [GC.FIELD_VALUE]: G.getEventTargetValue(event) }),
  },
  [GC.FIELD_FLEET_PREVIOUS_HIRE_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:previous-hire-date',
  },
  [GC.FIELD_FLEET_ORIGINAL_HIRE_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:original-hire-date',
  },
  [GC.FIELD_FLEET_TERMINATION_DATE]: {
    isClearable: true,
    type: 'datePicker',
    shouldCustomChange: true,
    name: 'titles:termination-date',
    handler: 'handleTerminationDate',
    customChangeHandler2: handleChangeTerminationDate,
    customChangeHandler: (event: Object, _: any, props: Object) =>
      handleChangeTerminationDate({ props, [GC.FIELD_VALUE]: G.getEventTargetValue(event) }),
  },
  [GC.FIELD_DOMICILE_TERMINAL]: {
    type: 'select',
    options: 'domicileTerminal',
    name: 'titles:domicile-terminal',
  },
  [GC.FIELD_ASSIGNED_DIVISION]: {
    type: 'select',
    options: 'assignedDivision',
    name: 'titles:assigned-division',
  },
  [GC.FIELD_TEAM_DRIVER]: {
    type: 'toggle',
    name: 'titles:team-driver',
    info: G.getWindowLocale('messages:driver:info:team-driver'),
    additionalInputWrapperSettings: {
      mr: 20,
      mb: 10,
      height: 36,
      minWidth: 'unset',
      width: 'max-content',
    },
  },
  [GC.FIELD_EXPEDITED_DIVISION]: {
    type: 'toggle',
    name: 'titles:expedited-division',
    additionalInputWrapperSettings: { mr: 20, mb: 10, width: 'max-content' },
  },
  [GC.FIELD_AUTO_ACCEPT_RATE]: {
    type: 'toggle',
    name: 'titles:auto-accept-rate',
    additionalInputWrapperSettings: { mr: 20, mb: 10, width: 'max-content' },
  },
  registrationForm2290: {
    type: 'toggle',
    name: 'titles:registration-form-2290',
    additionalInputWrapperSettings: { mr: 20, mb: 10, width: 'max-content' },
  },
  [GC.FIELD_BANK_ACCOUNT_NUMBER]: {
    name: 'titles:bank-account-number',
    disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_DRIVER_ACCOUNTING_WRITE),
    additionalInputWrapperSettings: {
      visibility: () => G.ifElse(
        G.hasNotAmousCurrentUserPermissions(accountingPermissions),
        'hidden',
      ),
    },
  },
  [GC.FIELD_BANK_ROUTING_NUMBER]: {
    name: 'titles:bank-routing-number',
    disabled: () => G.hasNotAmousCurrentUserPermissions(PC.FLEET_DRIVER_ACCOUNTING_WRITE),
    additionalInputWrapperSettings: {
      visibility: () => G.ifElse(
        G.hasNotAmousCurrentUserPermissions(accountingPermissions),
        'hidden',
      ),
    },
  },
  [GC.FIELD_PAYED_HOURS]: {
    type: 'text',
    name: 'titles:paid-hours',
  },
  [GC.FIELD_PREFER_DISTANCE_RATE]: {
    type: 'number',
    name: 'titles:preferred-distance-rate',
  },
  [GC.FIELD_PREFER_DISTANCE_RATE_UOM]: {
    type: 'select',
    options: ITEM_ODOMETER_UOM_OPTIONS,
    additionalLabelStyles: { top: -13, left: '5px' },
    additionalLabelComponent: (
      <TextComponent
        fontSize={11}
        display='block'
        withEllipsis={true}
        maxWidth='calc(100% - 10px)'
        title={preferDisranceRateUomTitle}
        color={G.getTheme('colors.darkGrey')}
      >
        {preferDisranceRateUomTitle}
      </TextComponent>
    ),
  },
  [GC.FIELD_COMMENTS]: {
    type: 'textarea',
    inputStyles: { p: 15 },
    name: 'titles:comments',
    additionalInputWrapperSettings: { mb: '0px', width: '100%', maxWidth: 'unset' },
  },
};

const fieldsWrapperStyles = { justifyContent: 'space-between' };

const groups = [
  {
    fields: [
      GC.FIELD_TEAM_DRIVER,
      GC.FIELD_EXPEDITED_DIVISION,
      GC.FIELD_AUTO_ACCEPT_RATE,
      GC.FIELD_SHOW_VENDOR_PAYROLL,
      'registrationForm2290',
      GC.FIELD_DRIVER_EMPLOYED,
      GC.FIELD_UNEMPLOYMENT_REASON,
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      GC.FIELD_USER_LOGIN_ID,
      GC.FIELD_FIRST_NAME,
      GC.FIELD_LAST_NAME,
      GC.FIELD_MIDDLE_INITIAL,
      GC.FIELD_NICK_NAME,
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      'gender',
      GC.FIELD_FLEET_BIRTH_DATE,
      GC.FIELD_FLEET_DRIVER_NUMBER,
      GC.FIELD_EMPLOYEE_NUMBER,
      GC.FIELD_DRIVER_TYPE,
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      'assignedDispatcherGuid',
      'dispatchingGroupGuid',
      GC.FIELD_FLEET_PRIMARY_PHONE_NUMBER,
      GC.FIELD_SECONDARY_PHONE_NUMBER,
      GC.FIELD_EMAIL,
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      GC.FIELD_FLEET_CURRENT_HIRE_DATE,
      GC.FIELD_FLEET_PREVIOUS_HIRE_DATE,
      GC.FIELD_FLEET_ORIGINAL_HIRE_DATE,
      GC.FIELD_FLEET_TERMINATION_DATE,
      GC.FIELD_DOMICILE_TERMINAL,
      GC.FIELD_ASSIGNED_DIVISION,
    ],
  },
  {
    fieldsWrapperStyles,
    fields: [
      GC.FIELD_PAYED_HOURS,
      GC.FIELD_PREFER_DISTANCE_RATE,
      GC.FIELD_PREFER_DISTANCE_RATE_UOM,
      GC.FIELD_BANK_ACCOUNT_NUMBER,
      GC.FIELD_BANK_ROUTING_NUMBER,
      GC.FIELD_COMMENTS,
    ],
  },
];

const defaultInputWrapperStyles = {
  mb: 25,
  width: '19%',
  maxWidth: 280,
  minWidth: 125,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

const mappedGroups = R.map((group: Object) => {
  const { fields } = group;

  const fieldSettings = R.map((fieldName: Object) => {
    const field = R.pathOr({}, [fieldName], settings);

    const {
      name,
      inputStyles,
      additionalLabelStyles,
      additionalLabelComponent,
      additionalInputWrapperSettings,
    } = field;

    return {
      ...field,
      fieldName,
      inputStyles,
      errorWidth: '100%',
      additionalLabelStyles,
      additionalLabelComponent,
      calendarInputWrapperStyles,
      label: G.ifElse(G.isNotNil(name), R.of(Array, name)),
      inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperSettings },
    };
  }, fields);

  return R.assoc('fieldSettings', fieldSettings, group);
}, groups);

export const generalDetailsSettings = {
  validationSchema,
  firstFields: R.head(mappedGroups),
  fieldsGroups: R.tail(mappedGroups),
};
