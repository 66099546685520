import React from 'react';
// components
import Events from '../../../components/events';
// constants
import * as GC from '../../../constants';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const tels = [{
  'rate': {},
  'guid': '1',
  'primaryReference': {},
  'telStatus': 'DELIVERED',
  'enterpriseName': 'User User',
}];

const eventsToUse = [{
  'items': [],
  'itemIds': [],
  'fcfs': false,
  'stopId': null,
  'guid': '11111',
  'itemGuids': [],
  'comments': null,
  'references': [],
  'cloEvent': true,
  'loadType': 'CLO',
  'stopType': 'CLO',
  'completed': true,
  'cloGuid': '00000',
  'telGuid': '22222',
  'cloEventIndex': 1,
  'telEventIndex': 1,
  'stopNumber': null,
  'arriveDate': null,
  'loadGuid': '00000',
  'status': 'COMPLETED',
  'eventType': 'PICKUP',
  'droppedTrailers': [],
  'relatedDropGuids': [],
  'pickedUpTrailers': [],
  'droppedContainers': [],
  'createdBy': 'Julianna',
  'locationGuid': '33333',
  'appointmentDate': null,
  'appointmentNumber': null,
  'closItemInternalIds': [],
  'droppedTrailerGuids': [],
  'droppedContainerIds': [],
  'pickedUpTrailerGuids': [],
  'appointmentLateTime': null,
  'lastTerminalDropGuid': null,
  'appointmentEarlyTime': null,
  'lastModifiedBy': 'Julianna',
  'appointmentRequired': false,
  'pickedUpContainerIds': ['77777'],
  'lateDate': '10/23/2023 10:00 AM',
  'earlyDate': '10/23/2023 10:00 AM',
  'completedStatusSource': 'DISPATCHER',
  'eventLateDate': '10/23/2023 10:00 AM',
  'eventEarlyDate': '10/23/2023 10:00 AM',
  'eventCheckinDate': '10/21/2023 12:00 AM',
  'eventCompleteDate': '10/11/2023 12:00 AM',
  'createdDate': '10/17/2023 11:57:43 AM +03',
  'lastModifiedDate': '10/23/2023 4:05:21 PM +03',
  'cloDistanceToNextStop': { 'systemDistance': 4180.12, 'systemDistanceUom': 'MILE', 'storedSystemDistance': 6727.251 },
  'telDistanceToNextStop': { 'systemDistance': 4180.12, 'systemDistanceUom': 'MILE', 'storedSystemDistance': 6727.251 },
  'location': {
    'version': 2,
    'zip': '28046',
    'contacts': [],
    'guid': '33333',
    'city': 'Madrid',
    'country': 'Spain',
    'externalId': null,
    'address2': 'Normal',
    'templateId': '6352',
    'residential': false,
    'integrationId': null,
    'locationName': '6532',
    'latitude': 40.4324778,
    'createdBy': 'Julianna',
    'longitude': -3.6881029,
    'integrationType': null,
    'hoursOfOperation': null,
    'comments': 'mock comment',
    'lastModifiedBy': 'Julianna',
    'coordinatesPopulated': true,
    'state': 'Comunidad de Madrid',
    'address1': '36 Paseo de la Castellana',
    'createdDate': '10/17/2023 11:57:43 AM +03',
    'lastModifiedDate': '10/23/2023 4:05:21 PM +03',
    'operationHour': [{ 'guid': '1', 'days': ['SUNDAY'], 'operationIndex': 0, 'timeTo': '10:00 PM', 'timeFrom': '10:00 AM' }],
  },
  'pickedUpContainers': [{
    'id': '789',
    'version': 2,
    'width': null,
    'length': null,
    'height': null,
    'guid': '232323',
    'weightUom': 'KG',
    'loadType': 'CLO',
    'storedWidth': null,
    'loadGuid': '00000',
    'type': 'CONTAINER',
    'storedLength': null,
    'storedHeight': null,
    'internalId': '77777',
    'createdBy': 'Julianna',
    'dimensionsUom': 'FOOT',
    'containerNumber': '789',
    'containerInitial': null,
    'railBillingStatus': null,
    'fullContainerWeight': 500,
    'emptyContainerWeight': 500,
    'lastModifiedBy': 'Julianna',
    'containerTypeGuid': '848484',
    'containerInternalId': '77777',
    'storedFullContainerWeight': 500,
    'storedEmptyContainerWeight': 500,
    'containerNumberWithInitial': '789',
    'createdDate': '10/17/2023 11:57:43 AM +03',
    'lastModifiedDate': '10/23/2023 4:05:21 PM +03',
  }],
},
{
  'items': [],
  'version': 6,
  'fcfs': false,
  'itemIds': [],
  'stopId': null,
  'itemGuids': [],
  'references': [],
  'guid': '999999',
  'comments': null,
  'cloEvent': true,
  'completed': true,
  'stopType': 'CLO',
  'loadType': 'CLO',
  'arriveDate': null,
  'cloGuid': '00000',
  'telGuid': '22222',
  'cloEventIndex': 2,
  'telEventIndex': 2,
  'stopNumber': null,
  'eventType': 'DROP',
  'loadGuid': '00000',
  'droppedTrailers': [],
  'status': 'COMPLETED',
  'relatedDropGuids': [],
  'pickedUpTrailers': [],
  'appointmentDate': null,
  'createdBy': 'Julianna',
  'pickedUpContainers': [],
  'locationGuid': '919191',
  'closItemInternalIds': [],
  'droppedTrailerGuids': [],
  'pickedUpTrailerGuids': [],
  'pickedUpContainerIds': [],
  'appointmentNumber': null,
  'appointmentLateTime': null,
  'appointmentRequired': false,
  'lastModifiedBy': 'Julianna',
  'lastTerminalDropGuid': null,
  'appointmentEarlyTime': null,
  'telDistanceToNextStop': null,
  'cloDistanceToNextStop': null,
  'droppedContainerIds': ['77777'],
  'lateDate': '11/25/2023 10:00 PM',
  'earlyDate': '11/25/2023 10:00 PM',
  'completedStatusSource': 'DISPATCHER',
  'eventLateDate': '11/25/2023 10:00 PM',
  'eventEarlyDate': '11/25/2023 10:00 PM',
  'eventCheckinDate': '12/13/2023 12:00 AM',
  'eventCompleteDate': '12/13/2023 12:00 AM',
  'createdDate': '10/17/2023 11:57:43 AM +03',
  'lastModifiedDate': '12/13/2023 12:00:45 PM +02',
  'location': {
    'version': 2,
    'zip': '60616',
    'contacts': [],
    'guid': '919191',
    'address2': null,
    'address3': null,
    'address4': null,
    'comments': null,
    'city': 'Chicago',
    'templateId': 'T5',
    'externalId': null,
    'state': 'Illinois',
    'operationHour': [],
    'residential': false,
    'integrationId': null,
    'latitude': 41.8313967,
    'createdBy': 'Julianna',
    'integrationType': null,
    'longitude': -87.6272292,
    'hoursOfOperation': null,
    'locationName': 'Chicago',
    'country': 'United States',
    'coordinatesPopulated': true,
    'lastModifiedBy': 'Julianna',
    'address1': '10 West 35th Street',
    'createdDate': '10/17/2023 11:57:43 AM +03',
    'lastModifiedDate': '10/23/2023 4:05:21 PM +03',
    'locationType': { 'guid': '4', 'storedValue': 'Airport', 'displayedValue': 'Airport', 'dropdownOptionGuid': '5' },
  },
  'droppedContainers': [{
    'id': '789',
    'version': 2,
    'width': null,
    'length': null,
    'height': null,
    'guid': '232323',
    'weightUom': 'KG',
    'loadType': 'CLO',
    'storedWidth': null,
    'loadGuid': '00000',
    'type': 'CONTAINER',
    'storedLength': null,
    'storedHeight': null,
    'internalId': '77777',
    'createdBy': 'Julianna',
    'dimensionsUom': 'FOOT',
    'containerInitial': null,
    'containerNumber': '789',
    'railBillingStatus': null,
    'fullContainerWeight': 500,
    'emptyContainerWeight': 500,
    'lastModifiedBy': 'Julianna',
    'containerTypeGuid': '848484',
    'containerInternalId': '77777',
    'storedFullContainerWeight': 500,
    'storedEmptyContainerWeight': 500,
    'containerNumberWithInitial': '789',
    'createdDate': '10/17/2023 11:57:43 AM +03',
    'lastModifiedDate': '10/23/2023 4:05:21 PM +03',
  }],
}];

const referenceTypes = [
  { 'value': '1', 'label': 'clo_primary_ref_type' },
  { 'value': '2', 'label': 'TestComapnyCloRefType' },
];

const EventsComponent = () => {
  const description = '<Events /> component displaying event details, providing users with an interface to interact with and manage various aspects of events and loads.';

  const usage = (
    <ul>
      <li>Parameter `<i>loadType</i>` represents the type of the load or trip.</li>
      <li>Parameter `<i>loads</i>` is an array representing loads associated with the events.</li>
      <li>Parameter `<i>events</i>` is an array of events that the component will render and manage.</li>
      <li>Parameter `<i>branchGuid</i>` is a string representing the unique identifier for a branch or location.</li>
      <li>Parameter `<i>fromPage</i>` indicates the source or origin page from where this component is rendered.</li>
      <li>Parameter `<i>referenceTypes</i>` is a data structure defining various types of references associated with the events.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='Events'
      description={description}
      path='src/components/events'
    >
      <Events
        loads={tels}
        branchGuid='1'
        events={eventsToUse}
        loadType={GC.LOAD_TYPE_CLO}
        referenceTypes={referenceTypes}
        fromPage={GC.PAGE_DISPATCH_BOARD_NEW}
      />
    </ComponentStoryWrapper>
  );
};

export default EventsComponent;
