import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import Filter from '../../../components/filter';
// helpers
import * as G from '../../../helpers';
// ui
import { Box, Text } from '../../../ui';
// feature components-story
import { setFilterParam } from '../actions';
import ComponentStoryWrapper from '../component-story-wrapper';
import { makeSelectReport, makeSelectFilterProps, makeSelectFilterParam } from '../selectors';
//////////////////////////////////////////////////

const FilterComponent = ({ filterParam, filterProps, setFilterParam, selectedReport }: Object) => {
  const description = '<Filter /> component handles the logic for filtering data based on user-selected conditions and fields.';

  const usage = (
    <ul>
      <li>Parameter `<i>version</i>` indicates a version for the component.</li>
      <li>Parameter `<i>filterColor</i>` is a color configuration for the filter component.</li>
      <li>Parameter `<i>setFilterParam</i>` function is used to set parameters for filtering.</li>
      <li>Parameter `<i>filterAction</i>` function triggered when a filter action is performed.</li>
      <li>Parameter `<i>usedReport</i>` is a data or configuration related to a report being used.</li>
      <li>Parameter `<i>shouldSubmitFilterForm </i>` use it if need to submit the inner `filter form`.</li>
      <li>Parameter `<i>filterBy</i>` contains data about available filter fields or options for filtering.</li>
      <li>Parameter `<i>disableRefFilter</i>` specifies whether filters for reference list should be disabled.</li>
      <li>Parameter `<i>position</i>` specifies the position where the filter popper should be displayed relative to its target element.</li>
    </ul>
  );

  const renderFilterParam = () => {
    if (G.isNotNilAndNotEmpty(filterParam)) {
      return (
        <Box mt={10}>
          <Text fontWeight='bold'>You set the next filter params:</Text>
          <pre>
            {JSON.stringify(filterParam, null, 2)}
          </pre>
        </Box>
      );
    }

    return null;
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='Filter'
      description={description}
      path='src/components/filter'
    >
      <Box>
        <Box ml={250}>
          <Filter
            version={2}
            filterBy={filterProps}
            filterAction={() => {}}
            usedReport={(selectedReport)}
            setFilterParam={setFilterParam}
          />
        </Box>
        {renderFilterParam()}
      </Box>

    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  selectedReport: makeSelectReport(state),
  filterProps: makeSelectFilterProps(state),
  filterParam: makeSelectFilterParam(state),
});

export default connect(mapStateToProps, {
  setFilterParam,
})(FilterComponent);
