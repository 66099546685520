import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature dispatch-detail-new
import Fleet from './fleet';
import Carrier from './carrier';
//////////////////////////////////////////////////

const DispatchCardComponent = (props: Object) => {
  const {
    loadGuid,
    autodialApp,
    fromDispatchBoard,
    declineReasonOptions,
    showAcceptDeclineSection,
    selectedCarrierDriverRate,
    acceptLoadDriverCarrierRateRequest,
    declineLoadDriverCarrierRateRequest,
  } = props;
  if (G.isNilOrEmpty(selectedCarrierDriverRate)) return null;
  const acceptDeclineHandlers = G.ifElse(
    fromDispatchBoard,
    { acceptLoadDriverCarrierRateRequest, declineLoadDriverCarrierRateRequest },
    {},
  );
  if (R.pathEq(GC.RATE_TYPE_FLEET_RATE, [GC.FIELD_TYPE], selectedCarrierDriverRate)) {
    return (
      <Fleet
        {...selectedCarrierDriverRate}
        autodialApp={autodialApp}
        fromDispatchBoard={fromDispatchBoard}
        declineReasonOptions={declineReasonOptions}
        acceptDeclineHandlers={acceptDeclineHandlers}
        showAcceptDeclineSection={showAcceptDeclineSection} />
    );
  }

  return (
    <Carrier
      {...selectedCarrierDriverRate}
      loadGuid={loadGuid}
      autodialApp={autodialApp}
      fromDispatchBoard={fromDispatchBoard}
      declineReasonOptions={declineReasonOptions}
      acceptDeclineHandlers={acceptDeclineHandlers}
      showAcceptDeclineSection={showAcceptDeclineSection} />
  );
};

export default DispatchCardComponent;
