import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderComponent } from 'react-recompose';
// common
import { makeSelectInitialSplashScreen } from '../../common/selectors';
// components
import LoaderComponent from '../../components/loader';
import ImageSlider from '../../components/slider-component';
// helpers/constants
import * as G from '../../helpers';
// auth settings
import {
  newPasswordPageSettings,
  newPasswordFieldSettings,
  defaultNewPasswordInitFields,
  newPasswordValidationSchemaObject,
} from './settings';
// feature auth
import AuthForm from './auth-form';
import { FormWrapper } from './ui';
import { makeSelectAuthData } from './selectors';
import stylingPanelHoc from './styling-panel-hoc';
import { sendCreateNewPasswordRequest } from './actions';
import { createOptions, setDefaultSetting } from './helpers';
//////////////////////////////////////////////////

const NewPassword = (props: Object) => {
  const {
    auth,
    handleSubmit,
    handleChange,
    isSubmitting,
    splashScreenSetting,
    handleGetInfoPanelRef,
  } = props;

  if (R.path(['loggedIn'], auth)) return <Redirect to='/' />;

  const { title, action } = newPasswordPageSettings;

  let options = [];

  if (G.isNotNil(splashScreenSetting.panels)) {
    const urlList = R.pick([
      'firstBackgroundImageUrl',
      'secondBackgroundImageUrl',
      'thirdBackgroundImageUrl',
    ], splashScreenSetting);

    options = createOptions(R.path(['panels'], splashScreenSetting), urlList);
  } else {
    options = setDefaultSetting(R.path(['firstBackgroundImageUrl'], splashScreenSetting));
  }

  return (
    <FormWrapper>
      <ImageSlider
        slides={options}
        splashScreenSetting={splashScreenSetting}
        handleGetInfoPanelRef={handleGetInfoPanelRef}
        color={R.path(['panelColor'], splashScreenSetting)}
      />
      <AuthForm
        title={title}
        props={props}
        heightLogo={150}
        textAlign='center'
        widthSubmitBtn={1}
        displayLinkTo='none'
        displaySubtitle='none'
        fontSizeSubmitBtn={16}
        actionSubmitBtn={action}
        displayInputsBlock='block'
        puddingSubmitBtn='8px 26px'
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isSubmitting={isSubmitting}
        imageSlidersOptions={options}
        fields={newPasswordFieldSettings}
        splashScreenSetting={splashScreenSetting}
      />
    </FormWrapper>
  );
};

const enhance = compose(
  stylingPanelHoc,
  withFormik({
    enableReinitialize: true,
    validationSchema: newPasswordValidationSchemaObject,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultNewPasswordInitFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { sendCreateNewPasswordRequest } = props;

      const params = G.parseQueryString();

      setSubmitting(true);
      sendCreateNewPasswordRequest({ ...values, ...params, callback: () => setSubmitting(false) });
    },
  }),
  branch(
    ({ splashScreenSetting }: Object) => R.not(R.path(['isLoaded'], splashScreenSetting)),
    renderComponent(LoaderComponent),
  ),
  pure,
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  auth: makeSelectAuthData(state),
  splashScreenSetting: makeSelectInitialSplashScreen(state),
}));

export default connect(mapStateToProps, {
  sendCreateNewPasswordRequest,
})(enhance(NewPassword));
