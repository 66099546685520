import * as R from 'ramda';
import * as P from 'plow-js';
// common
import { updateReportFromReportListSuccess } from '../../../common/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportReducers } from '../../../report-common';
// feature claim-management
import * as A from '../actions';
//////////////////////////////////////////////////

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers({ claimDetails: {} });

const reportReducer = {
  // report
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
};

export {
  initialState,
  reportReducer,
};
