import * as R from 'ramda';
import FileSaver from 'file-saver';
import { initialize } from 'redux-form';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { handleCreateNewLocationRequest } from '../../location/sagas';
import { getAssignInfo, receivedGetAssignInfo } from '../driver/actions';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectExpandedContainerOpened } from '../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
import { openWindowSaga } from '../../../sagas/common';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// fleet
import { getFleetConfigByGroupSaga } from '../sagas';
// feature fleet/vendor
import { getVendorAssignmentRequest } from '../vendor/actions';
// feature fleet/trailer
import * as A from './actions';
import {
  makeSelectTrailerDocuments,
  makeSelectTrailerLocations,
  makeSelectTrailerFormValues,
  makeSelectTrailerInsurances,
  makeSelectTrailerEquipComponent,
} from './selectors';
//////////////////////////////////////////////////

export function* handleGetTrailerRequestSaga({ guid }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.getCurrentTrailerEndpoint(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNil(data.driverGuid)) {
        yield put(getAssignInfo(data.driverGuid));
      }

      const branchGuid = G.getBranchGuidFromObject(data);

      yield all([
        put(A.getTrailerRefRequest(guid)),
        put(A.receivedGetTrailerSuccess(data)),
        put(A.getTrailerLocationRequest(guid)),
        put(A.getTrailerDocumentRequest(guid)),
        put(A.getTrailerOwnershipRequest(guid)),
        put(A.getTrailerInsuranceRequest(guid)),
        put(A.getExternalTrailerIdRequest(guid)),
        put(A.getTrailerConfigsRequest(branchGuid)),
        put(A.getTrailerLatestKnownLocationRequest(guid)),
        put(getVendorAssignmentRequest({ guid, [GC.FIELD_TYPE]: 'trailer' })),
      ]);

      yield put(A.getFleetGeneralConfigsRequest(branchGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerRequestSaga exception');
  }
}

export function* handleGetTrailerOwnershipSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        trailerGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.trailerOwnership, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerOwnershipSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerOwnershipSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerOwnershipSaga exception');
  }
}

export function* handleGetTrailerRefRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.trailerReferenceList;
    const options = {
      params: { trailerGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerRefSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerRefRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerRefRequestSaga exception');
  }
}

export function* handleGetTrailerInsuranceRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.trailerInsuranceList;
    const options = {
      params: { trailerGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerInsuranceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerInsuranceRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerInsuranceRequestSaga exception');
  }
}

export function* handleGetTrailerDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        primaryObjectGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.trailerDocList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerDocumentSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerDocumentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerDocumentSaga exception');
  }
}

export function* handleGetTrailerLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        trailerGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.trailerLocations, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const branchGuid = yield select(makeSelectCurrentBranchGuid());
      yield put(A.receivedGetTrailerLocationSuccess(data));
      const options = {
        names: GC.TEMPLATES_LOCATION_TYPE,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      };
      const reqRes = yield call(getFleetConfigByGroupSaga, options);
      if (G.isResponseSuccess(reqRes.status)) {
        const values = G.mapConfigValuesByName(reqRes.data).dropdowns[GC.TEMPLATES_LOCATION_TYPE];
        yield put(A.receivedGetLocationTypesSuccess(values));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerLocationSaga  exception');
  }
}

export function* handleGetTrailerSpecificationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        trailerGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.trailerSpecification, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerSpecificationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerSpecificationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerSpecificationSaga exception');
  }
}

export function* handleGetTrailerComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: {
        trailerGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.trailerComponentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerComponentSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerSpecificationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerSpecificationSaga exception');
  }
}

export function* handleGetTrailerConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      group: GC.TRAILER_CONFIG_GROUP,
      [GC.FIELD_BRANCH_GUID]: payload,
    };
    const res = yield call(getFleetConfigByGroupSaga, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerConfigsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTrailerConfigsSaga exception');
  }
}

function* getFleetGeneralConfigsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      [GC.FIELD_BRANCH_GUID]: payload,
      group: GC.FLEET_GENERAL_CONFIG_GROUP,
    };

    const res = yield call(getFleetConfigByGroupSaga, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedGetTrailerConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetGeneralConfigsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetGeneralConfigsRequest exception');
  }
}

export function* handleGetTrailerLatestKnownLocationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.trailerLatestLocation;
    const options = {
      params: { trailerGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const locationData = G.ifElse(G.isNilOrEmpty(data), [], R.of(Array, data));
      yield put(A.receivedTrailerLatestKnownLocationSuccess(locationData));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTrailerLatestKnownLocationRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetTrailerLatestKnownLocationRequestSaga exception');
  }
}

export function* handleCreateTrailerSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { values, fromVendorPage } = payload;
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.trailer, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const url = routesMap.editTrailerPage(G.getGuidFromObject(data));
      if (G.isTrue(fromVendorPage)) {
        yield call(openWindowSaga, { url, name: 'trailerDetailPage', reloadLocation: false });
      } else {
        yield call(G.goToRoute, url);
      }
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTrailerSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTrailerSaga exception');
  }
}

export function* handleCreateTrailerReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { values, selectedList } = payload;

    const options = {
      data: R.assoc('primaryObjectGuids', selectedList, values),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.trailerReference, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedCreateTrailerReferenceSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTrailerReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTrailerReferenceSaga exception');
  }
}

export function* handleCreateTrailerInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.trailerInsurance, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedCreateTrailerInsuranceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTrailerInsuranceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTrailerInsuranceSaga exception');
  }
}

export function* handleCreateTrailerDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.trailerDoc,
      { data },
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.receivedCreateTrailerDocumentSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTrailerDocumentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTrailerDocumentSaga exception');
  }
}

export function* handleCreateTrailerLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.trailerLocations, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isTrue(G.getPropFromObject('saveToTemplate', payload))) {
        yield call(handleCreateNewLocationRequest, { payload });
      }

      yield put(closeModal());
      yield put(A.receivedCreateTrailerLocationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTrailerLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTrailerLocationSaga exception');
  }
}

export function* handleCreateTrailerComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.trailerComponent, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.getTrailerComponentRequest(G.getPropFromObject(GC.FIELD_TRAILER_GUID, data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTrailerComponentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTrailerComponentSaga exception');
  }
}

export function* handleCreateTrailerLatestKnownLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.trailerLatestLocation,
      { data: payload },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      const locationData = G.ifElse(G.isNilOrEmpty(data), [], R.of(Array, data));
      yield put(A.receivedCreateTrailerLatestKnownLocationSuccess(locationData));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTrailerLatestKnownLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTrailerLatestKnownLocationSaga exception');
  }
}

export function* handleUpdateTrailerDetailsSaga({ payload }: Object) {
  try {
    const res = yield call(sendRequest, 'put', endpointsMap.trailer, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTrailerDetailsSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (R.propEq(true, GC.FIELD_SYNC, payload)) {
        yield put(A.syncTrailerByVINRequest(G.getGuidFromObject(payload)));
      }
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateTrailerDetailsSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerDetailsSaga exception');
    return false;
  }
}

const setOwnershipBody = (data: Object) => ({
  data: {
    ...R.pick(
      [
        'endDate',
        'startDate',
        'trailerGuid',
        'contractName',
        'returnedDate',
        'ownershipType',
        'paymentAmount',
        'paymentMethod',
        'contractNumber',
        'paymentInterval',
        'registrationCost',
        'equipmentReturnCost',
        'maintenanceCostMethod',
        'equipmentReturnCostPaid',
        'maintenanceCostInterval',
        'equipmentReturnCostRatio',
        'equipmentReturnCostAccrued',
        'registrationPaymentInterval',
        'equipmentReturnBalanceRequired',
        'equipmentReturnCostNotProvisioned',
      ],
      data,
    ),
    guid: data.ownershipGuid,
    version: data.ownershipVersion,
  },
});

export function* handleUpdateTrailerOwnershipSaga(payload: Object) {
  try {
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.trailerOwnership,
      setOwnershipBody(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTrailerOwnershipSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateTrailerOwnershipSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerOwnershipSaga exception');
    return false;
  }
}

const setSpecBody = (data: Object) => ({
  data: {
    ...R.pick(
      [
        'gvwt',
        'cube',
        'cubeType',
        'doorType',
        'axleCount',
        'deckHeight',
        'tareWeight',
        'axleConfig',
        'trailerGuid',
        'bodyMaterial',
        'refrigeration',
        'bulkCompCount',
        'wellDimension',
        'doorDimension',
        'weightTypeGVWT',
        'deckHeightType',
        'bulkCompVolume',
        'weightTareType',
        'externalDimension',
        'internalDimension',
        'bulkCompVolumeType',
        'specificationClass',
        'temperatureControl',
      ],
      data,
    ),
    guid: data.specGuid,
    version: data.specVersion,
  },
});

export function* handleUpdateTrailerSpecificationSaga(payload: Object) {
  try {
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.trailerSpecification,
      setSpecBody(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTrailerSpecificationSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      return true;
    }
    yield call(G.handleFailResponse, res, 'handleUpdateTrailerSpecificationSaga fail');
    return false;
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerSpecificationSaga exception');
    return false;
  }
}

export function* handleUpdateTrailerSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const detailsResults = yield all([
      call(handleUpdateTrailerDetailsSaga, { payload }),
      call(handleUpdateTrailerOwnershipSaga, payload),
    ]);

    const specResults = [];

    if (G.isNotNil(payload.specGuid)) {
      const specData = R.assoc('refrigeration', payload.refrigeration, payload);
      specResults[0] = yield call(handleUpdateTrailerSpecificationSaga, specData);
    }

    if (G.notContain(false, R.concat(detailsResults, specResults))) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      const expandedContainerOpened = yield select(makeSelectExpandedContainerOpened());

      if (expandedContainerOpened) {
        yield put(setExpandedContainerOptions({
          window: null,
          options: null,
          opened: false,
          componentType: null,
          visitPageGuid: G.getGuidFromObject(payload),
        }));
      } else {
        G.historyGoBack();
      }

      yield put(A.setActiveTab('details'));
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerSaga exception');
  }
}

export function* handleUpdateTrailerReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.trailerReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedUpdateTrailerReferenceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTrailerReferenceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerReferenceSaga exception');
  }
}

export function* handleUpdateTrailerInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'put', endpointsMap.trailerInsurance, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const insurances = yield select(makeSelectTrailerInsurances());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), insurances);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.receivedUpdateTrailerInsuranceSuccess({ data, index}));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTrailerInsuranceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerInsuranceSaga exception');
  }
}

export function* handleUpdateTrailerDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = new window.FormData();
    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));
    const res = yield call(sendRequest, 'post', endpointsMap.trailerDocUpdate, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const docs = yield select(makeSelectTrailerDocuments());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), docs);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(closeModal());
      yield put(A.receivedUpdateTrailerDocumentSuccess({ index, data: res.data}));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTrailerDocumentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerDocumentSaga exception');
  }
}

export function* handleUpdateTrailerLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const locations = yield select(makeSelectTrailerLocations());

    const res = yield call(sendRequest, 'put', endpointsMap.trailerLocations, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const index = R.findIndex(R.propEq(G.getGuidFromObject(payload), GC.FIELD_GUID), locations);

      yield put(closeModal());
      yield put(A.receivedUpdateTrailerLocationSuccess({ data, index }));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTrailerLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerLocationSaga exception');
  }
}

export function* handleUpdateTrailerComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'put', endpointsMap.trailerComponent, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const components = yield select(makeSelectTrailerEquipComponent());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), components);

      yield put(closeModal());
      yield put(A.receivedUpdateTrailerComponentSuccess({ data, index}));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTrailerComponentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateTrailerComponentSaga exception');
  }
}

export function* handleDeleteTrailerReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTrailerRefEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDeleteTrailerReferenceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTrailerReferenceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTrailerReferenceSaga exception');
  }
}

export function* handleDeleteTrailerInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTrailerInsuranceEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const insurances = yield select(makeSelectTrailerInsurances());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), insurances);
      yield put(A.receivedDeleteTrailerInsuranceSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTrailerInsuranceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTrailerInsuranceSaga exception');
  }
}

export function* handleDeleteTrailerDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTrailerDocEndpoint(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const docs = yield select(makeSelectTrailerDocuments());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), docs);
      yield put(A.receivedDeleteTrailerDocumentSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTrailerDocumentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTrailerDocumentSaga exception');
  }
}

export function* handleDeleteTrailerLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTrailerLocationEndpoint(payload),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const locations = yield select(makeSelectTrailerLocations());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), locations);
      yield put(A.receivedDeleteTrailerLocationSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTrailerLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTrailerLocationSaga exception');
  }
}

export function* handleDeleteTrailerComponentSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCurrentTrailerComponentEndpoint(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const components = yield select(makeSelectTrailerEquipComponent());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), components);
      yield put(A.receivedDeleteTrailerComponentSuccess(deletedIndex));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTrailerComponentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteTrailerComponentSaga exception');
  }
}

export function* handleCreateExternalTrailerIdSaga({ payload }: Object) {
  try {
    const { trailerGuid } = payload;

    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.trailerCreateExternalId, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalTrailerIdRequest(trailerGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateExternalTrailerIdSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateExternalTrailerIdSaga exception');
  }
}

export function* handleGetExternalTrailerIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: {
        [GC.FIELD_TRAILER_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.fleetExternalTrailerList, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getExternalTrailerIdSuccess(res.data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetExternalTrailerIdSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetExternalTrailerIdSaga exception');
  }
}

export function* handleDeleteExternalTrailerIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.trailerChangeExternalId(payload),
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteExternalTrailerIdSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteExternalTrailerIdSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteExternalTrailerIdSaga exception');
  }
}

export function* handleUpdateExternalTrailerIdSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.trailerCreateExternalId,
      { data: payload },
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getExternalTrailerIdRequest(payload.trailerGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateExternalTrailerIdSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateExternalTrailerIdSaga exception');
  }
}

function* handlePrintTrailerSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: { ...payload },
      resType: 'arraybuffer',
    };
    const res = yield call(sendRequest, 'post', endpointsMap.trailerPrint, options);
    const { data, status, request, headers } = res;
    if (G.isResponseSuccess(status)) {
      const file = new window.Blob(
        R.of(Array, data),
        { type: R.pathOr('application/pdf', ['content-type'], headers) },
      );
      const filename = R.or(G.getFileNameFromResponseDispositionHeader(request), 'trailer.pdf');
      FileSaver.saveAs(file, filename);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintTrailerSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handlePrintTrailerSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

// working division
function* changeAssignedToDivisionRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const formValues = yield select(makeSelectTrailerFormValues());
    const res = yield call(sendRequest, 'put', endpointsMap.changeTrailerAssignedToDivision, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(initialize('EDIT_TRAILER_FORM', null));
      yield put(receivedGetAssignInfo({}));
      yield put(A.cleanFormStore());
      yield call(handleGetTrailerRequestSaga, { guid: G.getGuidFromObject(formValues) });
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'changeAssignedToDivisionRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeAssignedToDivisionRequest exception');
  }
}

function* syncTrailerByVINRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_TRAILER_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.syncTrailer, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleGetTrailerRequestSaga, { [GC.FIELD_GUID]: payload });
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'syncTrailerByVINRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'syncTrailerByVINRequest exception');
  }
}

export function* handlerVisitEditTrailerPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_TRAILER_EDIT_PAGE);
    yield put(A.setInitialState());
    yield put(receivedGetAssignInfo({}));
    yield put(A.cleanFormStore());
    yield call(handleGetTrailerRequestSaga, payload);
    break;
  }
}

export function* trucksWatcherSaga() {
  yield takeLatest(A.printTrailer, handlePrintTrailerSaga);
  yield takeLatest(A.createTrailerRequest, handleCreateTrailerSaga);
  yield takeLatest(A.updateTrailerRequest, handleUpdateTrailerSaga);
  yield takeLatest(A.getTrailerRefRequest, handleGetTrailerRefRequestSaga);
  yield takeLatest(A.getTrailerConfigsRequest, handleGetTrailerConfigsSaga);
  yield takeLatest(A.getTrailerDocumentRequest, handleGetTrailerDocumentSaga);
  yield takeLatest(A.getTrailerLocationRequest, handleGetTrailerLocationSaga);
  yield takeLatest(A.getTrailerOwnershipRequest, handleGetTrailerOwnershipSaga);
  yield takeLatest(A.getTrailerComponentRequest, handleGetTrailerComponentSaga);
  yield takeLatest(A.getExternalTrailerIdRequest, handleGetExternalTrailerIdSaga);
  yield takeLatest(A.updateTrailerDetailsRequest, handleUpdateTrailerDetailsSaga);
  yield takeLatest(A.getFleetGeneralConfigsRequest, getFleetGeneralConfigsRequest);
  yield takeLatest(A.updateTrailerDocumentRequest, handleUpdateTrailerDocumentSaga);
  yield takeLatest(A.updateTrailerLocationRequest, handleUpdateTrailerLocationSaga);
  yield takeLatest(A.createTrailerDocumentRequest, handleCreateTrailerDocumentSaga);
  yield takeLatest(A.createTrailerLocationRequest, handleCreateTrailerLocationSaga);
  yield takeLatest(A.deleteTrailerDocumentRequest, handleDeleteTrailerDocumentSaga);
  yield takeLatest(A.deleteTrailerLocationRequest, handleDeleteTrailerLocationSaga);
  yield takeLatest(A.createTrailerReferenceRequest, handleCreateTrailerReferenceSaga);
  yield takeLatest(GC.VISIT_FLEET_TRAILER_EDIT_PAGE, handlerVisitEditTrailerPageSaga);
  yield takeLatest(A.createTrailerInsuranceRequest, handleCreateTrailerInsuranceSaga);
  yield takeLatest(A.createTrailerComponentRequest, handleCreateTrailerComponentSaga);
  yield takeLatest(A.updateTrailerReferenceRequest, handleUpdateTrailerReferenceSaga);
  yield takeLatest(A.updateTrailerInsuranceRequest, handleUpdateTrailerInsuranceSaga);
  yield takeLatest(A.deleteTrailerReferenceRequest, handleDeleteTrailerReferenceSaga);
  yield takeLatest(A.deleteTrailerInsuranceRequest, handleDeleteTrailerInsuranceSaga);
  yield takeLatest(A.updateTrailerComponentRequest, handleUpdateTrailerComponentSaga);
  yield takeLatest(A.deleteTrailerComponentRequest, handleDeleteTrailerComponentSaga);
  yield takeLatest(A.getTrailerInsuranceRequest, handleGetTrailerInsuranceRequestSaga);
  yield takeLatest(A.getTrailerSpecificationRequest, handleGetTrailerSpecificationSaga);
  yield takeLatest(A.updateExternalTrailerIdRequest, handleUpdateExternalTrailerIdSaga);
  yield takeLatest(A.deleteExternalTrailerIdRequest, handleDeleteExternalTrailerIdSaga);
  yield takeLatest(A.createExternalTrailerIdRequest, handleCreateExternalTrailerIdSaga);
  yield takeLatest(A.createTrailerLatestKnownLocationRequest, handleCreateTrailerLatestKnownLocationSaga);
  yield takeLatest(A.getTrailerLatestKnownLocationRequest, handleGetTrailerLatestKnownLocationRequestSaga);
  // working division
  yield takeLatest(A.changeAssignedToDivisionRequest, changeAssignedToDivisionRequest);
  // sync trailer
  yield takeLatest(A.syncTrailerByVINRequest, syncTrailerByVINRequest);
}

export default trucksWatcherSaga;
