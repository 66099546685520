import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// forms
import { Fieldset2 } from '../../../../../forms';
// ui
import { Box } from '../../../../../ui';
// feature configs
import { makeSelectReasonsForStatusCode } from '../../../selectors';
import { defaultStatusMessageFields, getStatusMessageFieldSettings, statusMessageValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: statusMessageValidationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultStatusMessageFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        closeModal,
        shouldUpdate,
        reasonsOptions,
        updateStatusMessageRequest,
        createStatusMessageRequest,
      } = props;

      closeModal();

      if (G.isTrue(shouldUpdate)) {
        const reasonCodes = R.pathOr([], ['reasonCodes'], values);
        const reasonGuids = R.pathOr([], ['reasonGuids'], reasonsOptions);

        const filteredCodes = R.filter(
          (item: Object) => R.includes(item, reasonGuids),
          reasonCodes,
        );

        return updateStatusMessageRequest(R.assoc('reasonCodes', filteredCodes, values));
      }

      createStatusMessageRequest(values);
    },
  }),
  pure,
);

const StatusesForm = (props: Object) => {
  const { values, handleSubmit, reasonsOptions } = props;


  return (
    <Box maxHeight='87vh' overflowY='auto'>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikProps(props)}
          fieldsWrapperStyles={{ mt: 30, p: '0 15px' }}
          reasonsOptions={G.getPropFromObject('multiOptions', reasonsOptions)}
          fields={getStatusMessageFieldSettings(G.getPropFromObject(GC.FIELD_STATUS_MESSAGE_REASON_REQUIRED, values))}
        />
        <FormFooter2 boxStyles={{ p: '25px 15px 15px' }} />
      </form>
    </Box>
  );
};

const mapStateToProps = (state: Object) => ({
  reasonsOptions: makeSelectReasonsForStatusCode()(state),
});

export default connect(mapStateToProps)(enhance(StatusesForm));
