import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectAppVersionStore = (state: Object) => state.appRelease;

const makeSelectAppVersion = () => createSelector(
  selectAppVersionStore,
  (store: Object) => store.version,
);

const makeSelectAppVersionNotes = () => createSelector(
  selectAppVersionStore,
  (store: Object) => store.version.notes,
);

const makeSelectAppVersionRelease = () => createSelector(
  selectAppVersionStore,
  (store: Object) => store.version.release,
);

const makeSelectAppReleaseByExternalUrl = () => createSelector(
  selectAppVersionStore,
  (store: Object) => store.latestRelease,
);

export {
  makeSelectAppVersion,
  makeSelectAppVersionNotes,
  makeSelectAppVersionRelease,
  makeSelectAppReleaseByExternalUrl,
};
