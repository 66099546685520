import * as R from 'ramda';
// features
import { columnSettings } from '../../inspections/settings/table-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
    R.omit([GC.GRC.FLEET_TRAILER_UNIT_ID, GC.GRC.FLEET_TRUCK_UNIT_ID]),
  )(columnSettings),
};

export const getInspectionsSettings = (isTruck: boolean = false) => ({
  report,
  columnSettings,
  singleItem: true,
  groupName: 'inspections',
  hasFormGroupTitle: false,
  getItemListRequestMethod: 'post',
  actionsPicker: ['remove', 'showDetails'],
  modalAdditionalOptions: { p: '0 0 15px 0' },
  itemTitleArr: ['titles:inspection', 'Inspection'],
  formGroupTitleArr: ['titles:inspections', 'Inspections'],
  endpoints: {
    list: isTruck ? 'truckInspections' : 'trailerInspections',
    remove: isTruck ? 'getTruckInspectionEndpoint' : 'getTrailerInspectionEndpoint',
  },
  additionalTableSettings: {
    titleRowHeight: 30,
    allowEditBtn: false,
    checkBoxCellWidth: 55,
    checkBoxCellJustifyContent: 'center',
  },
  makeRequestPayload: ({ primaryObjectGuid, primaryObjectBranchGuid }: Object) => ({
    params: isTruck ?
      { [GC.FIELD_TRUCK_GUID]: primaryObjectGuid } :
      { [GC.FIELD_TRAILER_GUID]: primaryObjectGuid },
    data: {
      offset: 0,
      limit: 300,
      pageable: false,
      fields: report.fields,
      [GC.CURRENT_BRANCH]: primaryObjectBranchGuid,
      systemFields: [
        { sequence: 0, name: GC.FIELD_BRANCH_GUID },
      ],
    },
  }),
});
