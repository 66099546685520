import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// common
import { changeCommissionAssignmentRequest } from '../../../../common/actions';
// components
import Events from '../../../../components/events';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// hocs
import { withAsyncConfigs, withAsyncStatusMessages } from '../../../../hocs';
// features
import LoadHeader from '../../../dispatch-details-new/components/load-header';
import { makeSelectAvailableReferenceTypesByScope } from '../../../reference/selectors';
import { withRightLoadHeaderActionsWithoutConnect }
  from '../../../dispatch-details-new/load/hocs/with-right-load-header-actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, StickedBox } from '../../../../ui';
// feature dispatch-board-new
import ShipUnitDetails from '../../components/ship-unit-details';
// feature dispatch-board-new/load
import Cards from './cards';
import { makeSelectCloEventReferences } from '../selectors';
import { getLoadDetailsRequest, updateLoadDetailsRequest, createLoadMultipleStatusMessageRequest } from '../actions';
/////////////////////////////////////////////////


const eventsConfigNamesArray = [
  GC.COMMUNICATION_REASON_CODE,
  GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
];

const eventsEnhance = compose(
  withAsyncStatusMessages,
  withAsyncConfigs,
);

const EnhancedEvents = eventsEnhance(Events);

const mapStateToProps = (state: Object) => createStructuredSelector({
  cloEventReferences: makeSelectCloEventReferences(state),
  availableRefTypes: makeSelectAvailableReferenceTypesByScope(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    getLoadDetailsRequest,
    updateLoadDetailsRequest,
    changeCommissionAssignmentRequest,
    createLoadMultipleStatusMessageRequest,
  }),
  pure,
);

const EnhancedLoadHeader = withRightLoadHeaderActionsWithoutConnect(LoadHeader);

const AllDetails = (props: Object) => {
  const {
    load,
    openModal,
    closeModal,
    tableIndex,
    openLoader,
    autodialApp,
    closeLoader,
    eventsToUse,
    referenceTypes,
    getLoadDetailsRequest,
    updateLoadDetailsRequest,
    changeCommissionAssignmentRequest,
    createLoadMultipleStatusMessageRequest,
  } = props;

  const { clos, guid, status, events, loadType, selectedRate } = load;

  const branchGuid = R.prop(GC.BRANCH_GUID, load);

  const distances = R.compose(
    R.filter(G.isNotNilAndNotEmpty),
    R.map(R.prop(GC.FIELD_DISTANCE_TEL)),
  )(R.or(events, []));

  const totalDistance = G.calculateTotalDistance(distances);

  const normalizedCurrency = R.path(
    [0, GC.SYSTEM_OBJECT_RATE, GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_CURRENCY],
    clos,
  );

  const customerRateTransportationModeGuid = R.path(
    [0, GC.SYSTEM_OBJECT_RATE, GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID],
    clos,
  );

  return (
    <StickedBox left='0px' p='8px 16px' width='fit-content'>
      <EnhancedLoadHeader
        load={load}
        openModal={openModal}
        openLoader={openLoader}
        closeModal={closeModal}
        tableIndex={tableIndex}
        fromPage='dispatchBoard'
        closeLoader={closeLoader}
        selectedRate={selectedRate}
        totalDistance={totalDistance}
        changeCommissionAssignmentRequest={changeCommissionAssignmentRequest}
        customerRateTransportationModeGuid={customerRateTransportationModeGuid}
        updateLoadDetailsRequest={(values: Object) => updateLoadDetailsRequest(R.mergeRight(load, values))}
      />
      <Box height='8px' />
      <EnhancedEvents
        loads={clos}
        loadGuid={guid}
        loadStatus={status}
        loadType={loadType}
        events={eventsToUse}
        branchGuid={branchGuid}
        autodialApp={autodialApp}
        referenceTypes={referenceTypes}
        fromPage={GC.PAGE_DISPATCH_BOARD_NEW}
        configsNamesArray={eventsConfigNamesArray}
        getLoadDetailsRequest={getLoadDetailsRequest}
        createMultipleStatusMessage={(data: Object) => createLoadMultipleStatusMessageRequest(R.assoc(
          GC.FIELD_LOAD_GUID,
          guid,
          data,
        ))}
      />
      <Box mt={15} width='calc(100vw - 110px)'>
        <Cards {...props} normalizedCurrency={normalizedCurrency} />
      </Box>
    </StickedBox>
  );
};

const EventsDetails = (props: Object) => {
  const {
    load,
    autodialApp,
    eventsToUse,
    referenceTypes,
    getLoadDetailsRequest,
    createLoadMultipleStatusMessageRequest } = props;
  const { clos, guid, status, loadType } = load;

  const branchGuid = R.prop(GC.BRANCH_GUID, load);

  return (
    <StickedBox left='0px' p='8px 16px' width='fit-content'>
      <EnhancedEvents
        loads={clos}
        loadGuid={guid}
        loadType={loadType}
        events={eventsToUse}
        loadStatus={status}
        branchGuid={branchGuid}
        autodialApp={autodialApp}
        referenceTypes={referenceTypes}
        fromPage={GC.PAGE_DISPATCH_BOARD_NEW}
        configsNamesArray={eventsConfigNamesArray}
        getLoadDetailsRequest={getLoadDetailsRequest}
        createMultipleStatusMessage={(data: Object) => createLoadMultipleStatusMessageRequest(R.assoc(
          GC.FIELD_LOAD_GUID,
          guid,
          data,
        ))} />
    </StickedBox>
  );
};

const LoadDetails = (props: Object) => {
  const { load, shownDetails, availableRefTypes, cloEventReferences } = props;

  if (R.equals(shownDetails, 'SHIP_UNIT')) {
    return (
      <ShipUnitDetails
        items={G.getPropFromObject(GC.SYSTEM_LIST_ITEMS, load)}
        containers={G.getPropFromObject(GC.FIELD_CONTAINERS, load)}
      />
    );
  }

  const { events } = load;

  const eventsToUse = R.map((event: Object) => {
    const { guid, loadType } = event;

    let references = R.filter(
      R.propEq(G.getGuidFromObject(event), GC.FIELD_EVENT_GUID),
      R.pathOr([], [GC.FIELD_LOAD_REFERENCES], load),
    );

    if (R.and(G.isLoadTypeClo(loadType), R.has(guid, cloEventReferences))) {
      references = G.getPropFromObject(guid, cloEventReferences);
    }

    return R.assoc(GC.FIELD_LOAD_REFERENCES, references, event);
  }, R.or(events, []));

  const referenceTypes = R.map(
    (item: Object) => ({ label: R.prop(GC.FIELD_NAME, item), value: R.prop(GC.FIELD_GUID, item) }),
    availableRefTypes,
  );

  if (R.equals(shownDetails, 'ALL')) {
    return <AllDetails {...props} eventsToUse={eventsToUse} referenceTypes={referenceTypes} />;
  } else if (R.equals(shownDetails, 'EVENTS')) {
    return <EventsDetails {...props} eventsToUse={eventsToUse} referenceTypes={referenceTypes} />;
  } else if (R.equals(shownDetails, 'CARDS')) {
    return (
      <StickedBox left='0px' p='8px 16px' width='calc(100vw - 80px)'>
        <Cards {...props} />
      </StickedBox>
    );
  }

  return <div>TODO:</div>;
};

export default enhance(LoadDetails);
