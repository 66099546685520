export const FLEET_TRUCK = 'truck';
export const FLEET_DRIVER = 'driver';
export const FLEET_VENDOR = 'vendor';
export const FLEET_SERVICE = 'service';
export const FLEET_TRAILER = 'trailer';
export const CREATE_FORM = 'CREATE_FORM';
export const FLEET_TRAILERS = 'trailers';
export const FLEET_TRUCK_GUID = 'truckGuid';
export const LOCATION_FORM = 'LOCATION_FORM';
export const FLEET_ASSIGN_INFO = 'assignInfo';
export const FLEET_TEAM_DRIVER = 'teamDriver';
export const FLEET_OWNERSHIP_TYPE_OWN = 'Own';
export const GROUP_NAME_SERVICES = 'services';
export const GROUP_NAME_DAILY_LOG = 'dailyLog';
export const FLEET_TRAILER_GUID = 'trailerGuid';
export const GROUP_NAME_INSURANCE = 'insurance';
export const GROUP_NAME_DOCUMENTS = 'documents';
export const GROUP_NAME_LOCATIONS = 'locations';
export const FLEET_TRUCK_CONFIGS = 'truckConfigs';
export const FLEET_PRIMARY_DRIVER = 'primaryDiver';
export const FLEET_COMPONENT_TYPE = 'componentType';
export const FLEET_TRAILER_CONFIGS = 'trailerConfigs';
export const FLEET_DOCUMENT_FORM = 'FLEET_DOCUMENT_FORM';
export const FLEET_INSURANCE_FORM = 'FLEET_INSURANCE_FORM';
export const GROUP_NAME_EQUIPMENT_COMPONENTS = 'components';
export const EQUIPMENT_SERVICE_FORM = 'EQUIPMENT_SERVICE_FORM';
export const GROUP_NAME_INTEGRATION_SAMSARA = 'integrationSamsara';
export const EQUIPMENT_COMPONENT_FORM = 'EQUIPMENT_COMPONENT_FORM';
// tabs
export const FLEET_TAB_DOCUMENTS = 'docs';
export const FLEET_TAB_SERVICE = 'service';
export const FLEET_TAB_DETAILS = 'details';
export const FLEET_TAB_PAYMENT = 'payment';
export const FLEET_TAB_FUEL_CARDS = 'fuelCards';
export const FLEET_TAB_IFTA_REPORT = 'iftaReport';
export const FLEET_TAB_TOLL_CHARGES = 'tollCharges';
export const FLEET_TAB_ADVANCE_PAYMENT = 'advancePayment';
export const FLEET_TAB_TRUCK_SPECIFICATION = 'specification';
