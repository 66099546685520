import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
// components
import { DateRange } from '../../../components/date-range';
// helpers
import * as G from '../../../helpers';
// feature components-story
import { setDateRangeValues } from '../actions';
import { makeSelectDateRangeValues } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const DateRangeComponent = ({ dateRangeValues, setDateRangeValues }: Object) => {
  const description = '<DateRange /> component is used for selecting date ranges.';

  const usage = (
    <ul>
      <li>Parameter `<i>label</i>` sets a label for the component.</li>
      <li>Parameter `<i>minDate</i>` is the minimum selectable date.</li>
      <li>Parameter `<i>maxDate</i>` is the maximum selectable date.</li>
      <li>Parameter `<i>dateTo</i>` represents the selected end date.</li>
      <li>Parameter `<i>dateFrom</i>` represents the selected start date.</li>
      <li>Parameter `<i>width</i>` sets the width of the date input field.</li>
      <li>Parameter `<i>popperPlacement</i>` specifies the placement of the date picker popup.</li>
      <li>Parameter `<i>withIcon</i>` determines whether to display a calendar icon next to the input field.</li>
      <li>Parameter `<i>onSelectDateRange</i>` is a higher-order function that is used as a callback to handle the selection of a date range.</li>
    </ul>
  );

  useEffect(() => {
    const currentDate = G.getCurrentDate();

    setDateRangeValues({
      dateFrom: currentDate,
      dateTo: G.addMomentTime(currentDate, 3, 'days'),
    });
  }, []);

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='DateRange'
      description={description}
      path='src/components/date-range'
    >
      <DateRange
        {...dateRangeValues}
        width={120}
        withIcon={true}
        popperPlacement='bottom-start'
        maxDate={G.momentAddYearsFromCurrent(5)}
        onSelectDateRange={setDateRangeValues}
        minDate={G.momentSubtractYearsFromCurrent(5)}
        label={G.getWindowLocale('titles:date-range', 'Date Range')}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  dateRangeValues: makeSelectDateRangeValues(state),
});

export default connect(mapStateToProps, {
  setDateRangeValues,
})(DateRangeComponent);
