import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
// components
import { TabsMui } from '../../../components/tabs-mui';
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hooks
import { useAsyncDocumentTemplates } from '../../../hooks';
// ui
import { Box } from '../../../ui';
// feature carrier-profile
import TerminalForm from '../forms/terminal';
import FormGroupTable from '../components/form-group-table';
import { terminalFinanceTabSettings, terminalGeneralTabSettings } from '../settings';
import {
  makeSelectSequenceOptions,
  makeSelectDispatchConfigs,
  makeSelectReferenceTypeOptions,
  makeSelectDropdownOptionsByDropdownNames,
  makeSelectTerminalFinanceGuidByTerminalGuid,
  makeSelectCarrierDispatchAvailableDocumentTemplates,
} from '../selectors';
import {
  getDispatchConfigListRequest,
  getCarrierProfileConfigsRequest,
  getAvailableDocumentTemplatesSuccess,
  getSequencesByScopeForOptionsRequest,
  getReferenceTypesByScopeForOptionsRequest,
} from '../actions';
/////////////////////////////////////////////

const makeGroups = (options: Object) => {
  const {
    terminalGuid,
    groupsSettings,
    collapsedGroup,
  } = options;

  const groups = R.map((item: Object) => {
    const { groupName, primaryObjectGuidKey = GC.FIELD_CARRIER_TERMINAL_GUID } = item;

    const terminalGroupName = `terminalDetails.${terminalGuid}.${groupName}`;

    return {
      ...item,
      primaryObjectGuidKey,
      groupName: terminalGroupName,
      isOpened: R.propOr(true, terminalGroupName, collapsedGroup),
    };
  }, groupsSettings);

  return groups;
};

const Tab = (props: Object) => (
  <Box mb={25} mt={G.ifElse(R.propEq(2, 'activeMuiTab', props), '5px')}>
    {
      R.pathOr([], ['groups'], props).map((group: Object, index: number) => {
        const { Component } = group;

        if (G.isNotNil(Component)) return <Component {...props} group={group} key={index} />;

        return (
          <FormGroupTable
            {...R.omit(['groups', 'collapsedGroup'], props)}
            key={index}
            group={group}
          />
        );
      })
    }
  </Box>
);

const Terminal = (props: Object) => {
  const { openLoader, closeLoader, initialValues, primaryObjectBranchGuid } = props;

  const dispatch = useDispatch();

  const [collapsedGroup, setCollapsedGroup] = useState({});
  const [activeMuiTab, setActiveMuiTab] = useState(R.propOr(0, 'activeTab', initialValues));

  const terminalGuid = G.getGuidFromObject(initialValues);

  const sequenceOptions = useSelector(makeSelectSequenceOptions());
  const dispatchConfigs = useSelector(makeSelectDispatchConfigs());
  const referenceTypeOptions = useSelector(makeSelectReferenceTypeOptions());
  const documentTemplates = useSelector(makeSelectCarrierDispatchAvailableDocumentTemplates());

  const terminalFinanceGuid = useSelector((state: Object) => makeSelectTerminalFinanceGuidByTerminalGuid(
    state,
    terminalGuid,
  ));

  const configOptions = useSelector((state: Object) => makeSelectDropdownOptionsByDropdownNames(
    state,
    // NOTE: https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
    `${GC.CARRIER_EMAIL_GROUP},${GC.TEMPLATES_LOCATION_TYPE}`,
  ));

  useAsyncDocumentTemplates({
    openLoader,
    closeLoader,
    branchGuid: primaryObjectBranchGuid,
    shouldNotGet: R.isNotNil(documentTemplates),
    callback: (data: Array) => dispatch(getAvailableDocumentTemplatesSuccess(data)),
  });

  const tabs = useMemo(() => [
    {
      value: 0,
      groupsSettings: terminalGeneralTabSettings,
      text: G.getWindowLocale('titles:terminal-details', 'Terminal Details'),
    },
    {
      value: 1,
      text: G.getWindowLocale('titles:email-automation', 'Email Automation'),
    },
    {
      value: 2,
      groupsSettings: terminalFinanceTabSettings,
      text: G.getWindowLocale('titles:finance-info', 'Finance Info'),
    },
  ], []);

  const dispatchConfigOptions = useMemo(() => ({
    dispatchConfigOptions: R.filter(R.prop('supportDispatch'), R.or(dispatchConfigs, [])),
    statusCheckDispatchConfigOptions: R.filter(R.prop('supportStatusCheck'), R.or(dispatchConfigs, [])),
  }), [dispatchConfigs]);

  const handleToggleFormGroup = useCallback((groupName: string) => {
    setCollapsedGroup((prev: Object) => R.assoc(groupName, R.not(R.propOr(true, groupName, prev), prev), prev));
  }, []);

  useEffect(() => {
    if (R.isNil(dispatchConfigs)) {
      dispatch(getDispatchConfigListRequest());
      dispatch(getSequencesByScopeForOptionsRequest());
      dispatch(getReferenceTypesByScopeForOptionsRequest());
      dispatch(getCarrierProfileConfigsRequest({ names: [GC.CARRIER_EMAIL_GROUP, GC.TEMPLATES_LOCATION_TYPE] }));
    }
  }, []);

  let groupsSettings = R.pathOr([], [activeMuiTab, 'groupsSettings'], tabs);

  if (R.and(R.equals(activeMuiTab, 2), R.isNil(terminalFinanceGuid))) {
    groupsSettings = R.take(1, groupsSettings);
  }

  const groups = makeGroups({
    terminalGuid,
    collapsedGroup,
    groupsSettings,
  });

  return (
    <Box width={1100}>
      <TabsMui
        tabs={tabs}
        activeMuiTab={activeMuiTab}
        setActiveMuiTab={setActiveMuiTab}
      />
      <TerminalForm
        {...props}
        dispatch={dispatch}
        activeTab={activeMuiTab}
        optionsForSelect={{
          ...configOptions,
          ...dispatchConfigOptions,
          sequenceOptions,
          referenceTypeOptions,
          documentTemplates: R.or(documentTemplates, []),
        }}
      />
      {
        G.notEquals(activeMuiTab, 1) &&
        <Tab
          {...props}
          groups={groups}
          dispatch={dispatch}
          activeMuiTab={activeMuiTab}
          collapsedGroup={collapsedGroup}
          handleToggleFormGroup={handleToggleFormGroup}
          primaryObjectGuid={G.ifElse(
            R.and(R.equals(activeMuiTab, 2), R.isNotNil(terminalFinanceGuid)),
            terminalFinanceGuid,
            terminalGuid,
          )}
        />
      }
      <FormFooter2
        formId='terminal_form'
        boxStyles={{ p: 15, bg: '#F6F6F6', borderTop: '1px solid', borderColor: '#E4E4E4' }}
      />
    </Box>
  );
};

export default Terminal;
