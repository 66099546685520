import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../components/form-footer';
import { LocalLoader } from '../../../../components/local-loader';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable } from '../../../../components/form-group-table';
// forms
import { FieldsetComponent } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../../hocs';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature fleet/vendor
import { makeSelectFleetAssignment } from '../selectors';
import { fleetAssignmentColumnSettings } from '../settings/column-settings';
import { changeFleetAssignmentRequest } from '../actions';
import { fleetAssignmentFieldSettings, defaultFleetAssignmentFields } from '../settings/formik-settings';
//////////////////////////////////////////////////

const formEnhance = compose(
  withState('fleetAssignable', 'setFleetAssignable', {}),
  withState('optionsForSelect', 'setOptionsForSelect', {}),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFleetAssignmentFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;
      const { showSecondaryDriver } = values;

      let data = R.dissoc('showSecondaryDriver', values);
      if (G.isFalse(showSecondaryDriver)) {
        data = R.omit(['newSecondaryDriverGuid', 'oldSecondaryDriverGuid', 'secondaryDriverPayable'], data);
      }

      submitAction(data);
    },
  }),
  withHandlers({
    handleGetOptionsForSelect: (props: Object) => async () => {
      const { entity, vendorGuid, branchGuid, setFleetAssignable } = props;
      const trailerOptions = {
        params: { vendorGuid },
      };
      const options = {
        params: { vendorGuid, [GC.BRANCH_GUID]: branchGuid },
      };
      const driverRes = await sendRequest('get', endpointsMap.getAvailableDrivers, options);
      const truckRes = await sendRequest('get', endpointsMap.getAvailableTrucks, options);
      const trailerRes = await sendRequest('get', endpointsMap.getAvailableTrailers, trailerOptions);
      let fleetAssignable = {
        truck: truckRes.data,
        driver: driverRes.data,
        trailer: trailerRes.data,
      };
      if (G.isNotNilAndNotEmpty(entity)) {
        const {
          truck,
          trailers,
          primaryDriver,
          secondaryDriver } = entity;

        if (G.isNotNilAndNotEmpty(truck)) {
          const truckAssignable = R.prepend(truck, fleetAssignable.truck);
          fleetAssignable = R.assoc('truck', truckAssignable, fleetAssignable);
        }
        if (G.isNotNilAndNotEmpty(primaryDriver)) {
          const primaryDriverAssignable = R.prepend(primaryDriver, fleetAssignable.driver);
          fleetAssignable = R.assoc('driver', primaryDriverAssignable, fleetAssignable);
        }
        if (G.isNotNilAndNotEmpty(secondaryDriver)) {
          const secondaryDriverAssignable = R.prepend(secondaryDriver, fleetAssignable.driver);
          fleetAssignable = R.assoc('driver', secondaryDriverAssignable, fleetAssignable);
        }
        if (G.isNotNilAndNotEmpty(trailers)) {
          const trailerAssignable = R.concat(trailers, fleetAssignable.trailer);
          fleetAssignable = R.assoc('trailer', trailerAssignable, fleetAssignable);
        }
      }

      setFleetAssignable(fleetAssignable);
    },
    handleCustomChange: (props: Object) => ({ field }: Object) => {
      const {
        values,
        setValues,
        setFieldValue,
        fleetAssignable,
        optionsForSelect,
        setOptionsForSelect } = props;

      const fieldName = R.compose(
        R.head,
        R.keys,
      )(field);
      const fieldValue = G.getPropFromObject(fieldName, field);
      const driverAssignable = G.getPropFromObject('driver', fleetAssignable);
      if (R.equals(fieldName, 'newPrimaryDriverGuid')) {
        const newPrimaryDriverGuid = fieldValue;
        const showSecondaryDriver = R.compose(
          R.pathOr(false, [GC.FIELD_TEAM_DRIVER]),
          R.find(R.propEq(newPrimaryDriverGuid, GC.FIELD_GUID)),
        )(driverAssignable);
        const newValues = R.mergeRight(values, { showSecondaryDriver, newPrimaryDriverGuid });
        const secondaryDriverOptions = R.compose(
          R.map((item: Object) => ({
            [GC.FIELD_VALUE]: G.getGuidFromObject(item),
            [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
          })),
          R.filter(({ guid, teamDriver }: Object) =>
            R.and(G.notEquals(guid, newPrimaryDriverGuid), G.isTrue(teamDriver)),
          ),
        )(driverAssignable);
        const newOptionsForSelect = R.assoc('secondaryDriverOptions', secondaryDriverOptions, optionsForSelect);

        setValues(newValues);
        setOptionsForSelect(newOptionsForSelect);
      }
      if (R.equals(fieldName, 'newSecondaryDriverGuid')) {
        const primaryDriverOptions = R.compose(
          R.map((item: Object) => ({
            [GC.FIELD_VALUE]: G.getGuidFromObject(item),
            [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
          })),
          R.filter(({ guid }: Object) => G.notEquals(guid, fieldValue)),
        )(driverAssignable);
        const newOptionsForSelect = R.assoc('primaryDriverOptions', primaryDriverOptions, optionsForSelect);

        setFieldValue(fieldName, fieldValue);
        setOptionsForSelect(newOptionsForSelect);
      }
    },
    handleChangePrimaryDriver: (props: Object) => ({ field }: Object) => {
      const { values, setValues, optionsForSelect, setOptionsForSelect } = props;

      const secondaryDriverOptions = R.pathOr([], ['secondaryDriverOptions'], optionsForSelect);
      const newPrimaryDriverGuid = G.getPropFromObject('newPrimaryDriverGuid', field);
      const showSecondaryDriver = R.compose(
        R.includes(newPrimaryDriverGuid),
        R.map(R.prop(GC.FIELD_VALUE)),
      )(secondaryDriverOptions);
      const newValues = R.mergeRight(values, { showSecondaryDriver, newPrimaryDriverGuid });
      const newSecondaryDriverOptions = R.filter(
        ({ value }: Object) => G.notEquals(value, newPrimaryDriverGuid),
        secondaryDriverOptions,
      );
      const newOptionsForSelect = R.assoc('secondaryDriverOptions', newSecondaryDriverOptions, optionsForSelect);

      setValues(newValues);
      setOptionsForSelect(newOptionsForSelect);
    },
    handleSetInitialFormData: (props: Object) => () => {
      const { entity, setValues, fleetAssignable, setOptionsForSelect } = props;

      const editMode = G.isNotNilAndNotEmpty(entity);
      const drivers = R.compose(
        R.uniqBy(R.prop(GC.FIELD_GUID)),
        R.pathOr([], ['driver']),
      )(fleetAssignable);
      const getPrimaryDriverOptions = R.compose(
        R.map((item: Object) => ({
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
          [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
        })),
        R.filter(({ fleetVendorGuid }: Object) => G.isNilOrEmpty(fleetVendorGuid)),
      )(drivers);
      const getSecondaryDriverOptions = R.compose(
        R.map((item: Object) => ({
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
          [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
        })),
        R.filter(R.prop(GC.FIELD_TEAM_DRIVER)),
      )(drivers);
      const getTruckOptions = R.map(
        ({ guid, unitId }: Object) => ({
          [GC.FIELD_VALUE]: guid,
          [GC.FIELD_LABEL]: unitId,
        }),
        R.pathOr([], ['truck'], fleetAssignable),
      );
      const getTrailerOptions = R.map(
        ({ guid, unitId }: Object) => ({
          [GC.FIELD_VALUE]: guid,
          [GC.FIELD_LABEL]: unitId,
        }),
        R.pathOr([], ['trailer'], fleetAssignable),
      );
      const optionsForSelect = {
        truckOptions: getTruckOptions,
        trailerOptions: getTrailerOptions,
        primaryDriverOptions: getPrimaryDriverOptions,
        secondaryDriverOptions: getSecondaryDriverOptions,
      };
      if (G.isTrue(editMode)) {
        const {
          oldTruckGuid,
          primaryDriver,
          oldTrailerGuids,
          secondaryDriver,
          oldPrimaryDriverGuid,
          oldSecondaryDriverGuid } = entity;
        const primaryDriverPayable = R.pathOr(false, [GC.FIELD_PAYABLE], primaryDriver);
        const secondaryDriverPayable = R.pathOr(false, [GC.FIELD_PAYABLE], secondaryDriver);

        const initialValues = {
          oldTruckGuid,
          oldTrailerGuids,
          oldPrimaryDriverGuid,
          primaryDriverPayable,
          secondaryDriverPayable,
          oldSecondaryDriverGuid,
          newTruckGuid: oldTruckGuid,
          newTrailerGuids: oldTrailerGuids,
          newPrimaryDriverGuid: oldPrimaryDriverGuid,
          newSecondaryDriverGuid: oldSecondaryDriverGuid,
          showSecondaryDriver: G.isNotNilAndNotEmpty(secondaryDriver),
        };

        setValues(initialValues);
      }

      setOptionsForSelect(optionsForSelect);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'fleetAssignable',
    callbackName: 'handleSetInitialFormData',
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetOptionsForSelect();
    },
  }),
  pure,
);

const FleetAssignmentForm = formEnhance((props: Object) => (
  <LocalLoader
    minWidth={440}
    localLoaderOpen={G.isNilOrEmpty(props.fleetAssignable)}
  >
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={fleetAssignmentFieldSettings}
        optionsForSelect={props.optionsForSelect}
        handlers={{ handleCustomChange: props.handleCustomChange }} />
      <FormFooter closeModal={props.closeModal} boxStyles={{ p: '10px 5px' }} />
    </form>
  </LocalLoader>
));

const FleetAssignmentFormGroupTable = (props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={props.fleetAssignment}
    entitiesFields={fleetAssignmentColumnSettings}
    handleDeleteRow={props.handleRemoveAssignment}
    handleEditRow={props.handleOpenFleetAssignmentForm}
    handleAddClick={props.handleOpenFleetAssignmentForm}
    isOpened={R.path(['collapsedGroup', 'fleetAssignment'], props)}
    handleToggleFormGroup={() => props.handleToggleFormGroup('fleetAssignment')}
    panelTitle={G.getWindowLocale('titles:fleet-assignment', 'Fleet Assignment')} />
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  fleetAssignment: makeSelectFleetAssignment(state),
});

const enhance = compose(
  connect(mapStateToProps, { changeFleetAssignmentRequest }),
  withHandlers({
    handleRemoveAssignment: (props: Object) => (entity: Object) => {
      const { openModal, changeFleetAssignmentRequest } = props;

      const data = R.pick(
        ['oldTruckGuid', 'oldTrailerGuids', 'oldPrimaryDriverGuid', 'oldSecondaryDriverGuid'],
        entity,
      );
      const txtLocale = G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove');
      const component = <ConfirmComponent textLocale={txtLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => changeFleetAssignmentRequest(data),
              name: G.getWindowLocale('actions:remove', 'Remove'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleOpenFleetAssignmentForm: (props: Object) => (entity: Object) => {
      const {
        openModal,
        branchGuid,
        closeModal,
        vendorGuid,
        changeFleetAssignmentRequest } = props;

      const editMode = G.isNotNilAndNotEmpty(entity);
      const title = G.ifElse(
        editMode,
        G.getWindowLocale('titles:edit-fleet-assignment', 'Edit Fleet Assignment'),
        G.getWindowLocale('titles:add-fleet-assignment', 'Add Fleet Assignment'),
      );
      const component = (
        <FleetAssignmentForm
          entity={entity}
          vendorGuid={vendorGuid}
          closeModal={closeModal}
          branchGuid={branchGuid}
          submitAction={changeFleetAssignmentRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 470,
          height: 'auto',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default enhance(FleetAssignmentFormGroupTable);
