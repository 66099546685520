import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const store = ({ fleetList }: Object) => fleetList;

const makeSelectUsedReport = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'usedReport'], state);
  },
);

const makeSelectAvailableReports = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'availableReports'], state);
  },
);

const makeSelectGlobalFilterValue = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'globalFilterValue'], state);
  },
);

const makeSelectPagination = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'pagination'], state);
  },
);

const makeSelectFilterParams = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'filterParams'], state);
  },
);

const makeSelectReportType = () => createSelector(
  store,
  ({ reportType }: Object) => reportType,
);

const makeSelectItemList = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.compose(
      R.sortBy(R.prop('index')),
      R.values,
      R.pathOr([], [reportType, 'itemList']),
    )(state);
  },
);

const makeSelectTotalCount = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'totalCount'], state);
  },
);

const makeSelectListLoading = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'loading'], state);
  },
);

const makeSelectReportStatus = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'reportPending'], state);
  },
);

const makeSelectSelectedList = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.compose(
      R.keys,
      R.filter(({ selected }: Object) => selected),
      R.pathOr([], [reportType, 'itemList']),
    )(state);
  },
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'tableTitleFilters'], state);
  },
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'titleSortValues'], state);
  },
);

const makeSelectFilterProps = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.path([reportType, 'filterProps'], state);
  },
);

const makeSelectFleetProfileSettings = () => createSelector(
  store,
  ({ fleetProfileSettings }: Object) => fleetProfileSettings,
);

const makeSelectDocumentTypes = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    const configsByNames = R.pathOr({}, [reportType, 'configsByNames'], state);

    if (R.isEmpty(configsByNames)) return null;

    return {
      [GC.TRUCK_DOCUMENT_TYPE]: G.mapDropdownConfigWithParentGuid(configsByNames, GC.TRUCK_DOCUMENT_TYPE),
      [GC.TRAILER_DOCUMENT_TYPE]: G.mapDropdownConfigWithParentGuid(configsByNames, GC.TRAILER_DOCUMENT_TYPE),
    };
  },
);

const makeSelectConfigByNames = () => createSelector(
  store,
  (state: Object) => {
    const { reportType } = state;

    return R.pathOr({}, [reportType, 'configsByNames'], state);
  },
);

const makeSelectAccessorialConfigs = () => createSelector(
  store,
  R.propOr({}, 'accessorialConfigs'),
);

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectReportType,
  makeSelectListLoading,
  makeSelectFilterProps,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectSelectedList,
  makeSelectDocumentTypes,
  makeSelectConfigByNames,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectGlobalFilterValue,
  makeSelectAccessorialConfigs,
  makeSelectFleetProfileSettings,
};
