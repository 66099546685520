import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import { ActionBox } from '../../../components/action-box';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncEditCarrierInvoice } from '../../../hocs/with-async-edit-carrier-invoice';
// feature carrier-edi
import { updateCarrierInvoiceRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      updateCarrierInvoiceRequest,
    },
  ),
  withAsyncEditCarrierInvoice({ updateFrom: GC.FIELD_CARRIER_EDI }),
  pure,
);

const InvoiceNumberCell = ({ entity, invoiceNumber, handleEditCarrierInvoice }: Object) => {
  if (G.isNilOrEmpty(G.getPropFromObject(GC.GRC.CREATED_INVOICE_INVOICE_GUID, entity))) {
    return <div>{invoiceNumber}</div>;
  }

  if (G.hasNotAmousCurrentUserPermissions([PC.TEL_CARRIER_INVOICE_READ, PC.TEL_CARRIER_INVOICE_WRITE])) {
    return <div>{invoiceNumber}</div>;
  }

  return <ActionBox text={invoiceNumber} action={() => handleEditCarrierInvoice(entity)} />;
};

export default enhance(InvoiceNumberCell);
