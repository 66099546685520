import * as R from 'ramda';
import styled from 'styled-components';
// helpers/constants
import * as G from '../../../../helpers';
/////////////////////////////////////////////////

export const SummaryBodyWrapper = styled.div`
  padding: 5px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  min-height: 50px;
  max-height: 90vh;
  border-radius: 3px;
  position: relative;
  width: max-content;
  align-items: center;
  max-width: calc(100vw - 80px);
  color: ${G.getTheme('summary.bodyTextColor')};
  background: ${G.getTheme('summary.bodyBgColor')};
  & > div > span:nth-child(2) {
    margin: 0 10px;
    padding: 1px 10px;
    border-radius: 3px;
    color: ${G.getTheme('summary.bodyTextColor')};
    background: ${({ routeTab }: Object) => G.getTheme(`summary.bodyTextColor.${routeTab}LabelColor`)};
  }
`;

export const SummaryGroupWrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  background-color: ${G.getTheme('summary.groupBodyBgColor')};
  & > div {
    width: 100%;
    &:first-child {
      border: none;
      display: flex;
      margin-left: 0;
      padding: 8px 7px 8px 10px;
      justify-content: space-between;
      background-color: ${G.getTheme('summary.groupHeaderBgColor')};
    }
  }
`;

const renderBgColor = ({ routeTab, isHeader }: Object) => (
  G.getTheme(`summary.${R.toLower(routeTab)}Label${G.ifElse(isHeader, '', 'Item')}Color`)
);

export const SummaryGroupItem = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${G.getTheme('summary.groupItemBorderColor')};
  & > div:last-child {
    padding: 3px 0;
    margin-left: 10px;
    border-radius: 3px;
    background-color: ${(props: Object) => renderBgColor(props)};
    &:hover {
      cursor: pointer;
      background-color: ${({ routeTab }: Object) => renderBgColor({ routeTab, isHeader: true })};
    }
  }
`;

export const Link = styled.span`
  padding: 0 3px;
  color: ${G.getTheme('colors.light.blue')};
  &:hover {
    cursor: pointer;
  }
`;
