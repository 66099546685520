import React from 'react';
// components
import { QuickFilter } from '../../../components/quick-filter';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const QuickFilterComponent = () => {
  const description = '<QuickFilter /> component serves as a controlled input that facilitates user input for filtering data.';

  const usage = (
    <ul>
      <li>Parameter `<i>label</i>` is the label text for the input field.</li>
      <li>Parameter `<i>placeholder</i>` is placeholder text for the input field.</li>
      <li>Parameter `<i>handleSetFilter</i>` is a function to set the filter value.</li>
      <li>Parameter `<i>inputProps</i>` is an additional props for the Input component.</li>
      <li>Parameter `<i>delayTime</i>` is a time delay before triggering the filter change.</li>
      <li>Parameter `<i>allowSingleCharacter</i>` is a flag to allow single-character input.</li>
      <li>Parameter `<i>boxStyles</i>` is styling properties for the wrapping Flex component.</li>
      <li>Parameter `<i>endLabel</i>` is the JSX element to be displayed after the input field.</li>
      <li>Parameter `<i>withoutLabel</i>` is a flag to determine whether the label should be displayed.</li>
      <li>Parameter `<i>allowSetAdditionalFiltersImmediately</i>` if `true`, the handleSetFilter function is called immediately with the current filter value.</li>
    </ul>
  );

  const handleSetFilter = (value: string) => {
    window.alert(`Filter value is ${value}`);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='QuickFilter'
      description={description}
      path='src/components/quick-filter'
    >
      <QuickFilter
        label='Filter by Name'
        handleSetFilter={handleSetFilter}
      />
    </ComponentStoryWrapper>
  );
};

export default QuickFilterComponent;
