import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

export const fieldSettings = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_TYPE,
    label: ['titles:type', 'Type'],
    options: 'commissionAssignmentOptions',
  },
  {
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_ID,
    label: ['titles:id', 'ID'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
];

export const defaultFields = {
  [GC.FIELD_ID]: null,
  [GC.FIELD_TYPE]: null,
  [GC.FIELD_NAME]: null,
};

export const validationSchema = Yup.object().shape({
  [GC.FIELD_ID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});
