import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, withProps } from 'react-recompose';
// components
import { FormFooter } from '../../../../components/form-footer';
import { openModal, closeModal } from '../../../../components/modal/actions';
// forms
import { FieldsetComponent } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncRolesByUserType } from '../../../../hocs';
// feature fleet
import { createFormSettingsMap } from './field-settings';
import { createTruckRequest } from '../../truck/actions';
import { createVendorRequest } from '../../vendor/actions';
import { createDriverRequest } from '../../driver/actions';
import { createTrailerRequest } from '../../trailer/actions';
//////////////////////////////////////////////////

const formEnhance = compose(
  withFormik({
    mapPropsToValues: ({ defaultFields }: Object) => defaultFields,
    validationSchema: ({ validationSchema }: Object) => validationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction, fromVendorPage } = props;

      submitAction({ values, fromVendorPage });
    },
  }),
  pure,
);

const getOptionsForSelect = ({ rolesGuids, asyncConfigs }: Object) => {
  const truckType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.TRUCK_TRUCK_TYPE,
    true,
  );
  const trailerType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.TRAILER_TRAILER_TYPE,
    true,
  );

  return { rolesGuids, truckType, trailerType };
};

const CreateForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={props.fieldSettings}
      optionsForSelect={getOptionsForSelect(props)} />
    <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={props.closeModal} />
  </form>
));

const createDriverEnhance = compose(
  connect(null, { openModal, closeModal, createDriverRequest }),
  withProps(({ createDriverRequest }: Object) => {
    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const settings = G.getPropFromObject('driver', createFormSettingsMap);

    return {
      ...settings,
      currentBranchGuid,
      submitAction: createDriverRequest,
    };
  }),
  withAsyncRolesByUserType({ [GC.FIELD_ROLE_ROLE_TYPE]: GC.USER_ROLE_TYPE_DRIVER }),
);

const createTruckEnhance = compose(
  connect(null, { openModal, closeModal, createTruckRequest }),
  withProps(({ createTruckRequest }: Object) => {
    const configsNamesArray = R.of(Array, GC.TRUCK_TRUCK_TYPE);
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const settings = G.getPropFromObject('truck', createFormSettingsMap);

    return {
      ...settings,
      branchGuid,
      configsNamesArray,
      submitAction: createTruckRequest,
    };
  }),
  withAsyncConfigs,
  withHandlers({
    handleSetOptions: ({ asyncConfigs }: Object) => () =>
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(asyncConfigs, GC.TRUCK_TRUCK_TYPE, true),
  }),
);

const createTrailerEnhance = compose(
  connect(null, { openModal, closeModal, createTrailerRequest }),
  withProps(({ createTrailerRequest }: Object) => {
    const configsNamesArray = R.of(Array, GC.TRAILER_TRAILER_TYPE);
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const settings = G.getPropFromObject('trailer', createFormSettingsMap);

    return {
      ...settings,
      branchGuid,
      configsNamesArray,
      submitAction: createTrailerRequest,
    };
  }),
  withAsyncConfigs,
);

const createVendorEnhance = compose(
  connect(null, { openModal, closeModal, createVendorRequest }),
  withProps(({ createVendorRequest }: Object) => ({
    ...G.getPropFromObject('vendor', createFormSettingsMap),
    submitAction: createVendorRequest,
  })),
);

export const CreateTruck = createTruckEnhance(CreateForm);
export const CreateDriver = createDriverEnhance(CreateForm);
export const CreateVendor = createVendorEnhance(CreateForm);
export const CreateTrailer = createTrailerEnhance(CreateForm);
