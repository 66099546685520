import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, FixedFlex, RelativeBox, MainActionButton } from '../../../ui';
//////////////////////////////////////////////////

const buttonStyles = {
  width: 150,
  background: 'none',
  border: '1px solid',
  textTransform: 'uppercase',
};

const CarrierProfileWrapper = (props: Object) => {
  const {
    id,
    pb = 80,
    children,
    handleClose,
    handleGoBack,
    setSaveAndClose,
    expandedContainer,
    form = 'general_details',
  } = props;

  return (
    <RelativeBox
      p={20}
      id={id}
      pb={pb}
      bg='bgGrey'
      height='100vh'
      overflow='auto'
      pl={G.ifElse(G.isTrue(expandedContainer), 30, 75)}
    >
      <Box mx='auto' maxWidth={1550}>
        {children}
      </Box>
      <FixedFlex
        pl={70}
        left='0px'
        bg='white'
        zIndex={12}
        bottom='0px'
        width='100%'
        p='14px 20px'
        justifyContent='flex-end'
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
      >
        {
          G.isFunction(handleGoBack) &&
          <MainActionButton
            {...buttonStyles}
            mr={20}
            form={form}
            width={160}
            color='greyMatterhorn'
            borderColor='greyMatterhorn'
            onClick={() => setSaveAndClose([])}
          >
            {G.getWindowLocale('actions:save-and-go-back', 'Save and Go Back')}
          </MainActionButton>
        }
        <MainActionButton
          {...buttonStyles}
          mr={20}
          type='button'
          onClick={handleClose}
          color='greyMatterhorn'
          borderColor='greyMatterhorn'
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </MainActionButton>
        <MainActionButton {...buttonStyles} form={form} onClick={() => setSaveAndClose(true)}>
          {G.getWindowLocale('actions:save-and-close', 'Save and Close')}
        </MainActionButton>
      </FixedFlex>
    </RelativeBox>
  );
};

export default CarrierProfileWrapper;

