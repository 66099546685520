import { createAction } from 'redux-act';
//////////////////////////////////////////////////

const setExpandedContainerOptions = createAction('setExpandedContainerOptions');
const setInitialExpandedContainer = createAction('setInitialExpandedContainer');

export {
  setExpandedContainerOptions,
  setInitialExpandedContainer,
};
