import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../../components/text';
import { FormFooter2 } from '../../../../components/form-footer';
import { ConfirmComponent } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncGetFleetVendorCompanyNameList } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature fleet/vendor
import { makeSelectVendorAssignment } from '../selectors';
import { assignVendorRequest, unAssignVendorRequest, assignVendorToDriverRequest } from '../actions';
//////////////////////////////////////////////////

const vendorField = {
  type: 'select',
  isRequired: true,
  options: 'vendorOptions',
  fieldName: GC.FIELD_VENDOR_GUID,
  label: ['titles:select-vendor', 'Select Vendor'],
  inputWrapperStyles: { mt: 15, mb: 25, width: 270 },
};

const fields = [
  vendorField,
  {
    type: 'toggle',
    fieldName: GC.FIELD_PAYABLE,
    inputWrapperStyles: { mb: 25, width: 270 },
    label: ['titles:create-driver-invoice', 'Create Driver Invoice'],
  },
];

const formEnhance = compose(
  withAsyncGetFleetVendorCompanyNameList,
  withFormik({
    mapPropsToValues: ({ vendorGuid = null }: Object) => ({ vendorGuid }),
    validationSchema: Yup.object().shape({
      [GC.FIELD_VENDOR_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    }),
    handleSubmit: ({ vendorGuid }: Object, { props }: Object) => {
      const {
        type,
        version,
        objGuid,
        submitAction,
        fleetVendorCompanyNameList,
      } = props;

      const requestData = R.compose(
        R.mergeRight({ type, version, objGuid }),
        R.find(R.propEq(vendorGuid, GC.FIELD_GUID)),
      )(fleetVendorCompanyNameList);

      submitAction(requestData);
    },
  }),
);

const AssignVendorForm = formEnhance((props: Object) => {
  const { handleSubmit, fleetVendorCompanyNameList } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={R.of(Array, vendorField)}
        vendorOptions={G.addEmptyOptionToDropDown(fleetVendorCompanyNameList)}
      />
      <FormFooter2 />
    </form>
  );
});

const driverFormEnhance = compose(
  withAsyncGetFleetVendorCompanyNameList,
  withFormik({
    mapPropsToValues: ({ payable = false, vendorGuid = null }: Object) => ({ payable, vendorGuid }),
    validationSchema: Yup.object().shape({
      [GC.FIELD_PAYABLE]: Yup.boolean().nullable(true).notRequired(),
      [GC.FIELD_VENDOR_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    }),
    handleSubmit: ({ payable, vendorGuid }: Object, { props }: Object) => {
      const {
        type,
        version,
        objGuid,
        submitAction,
        fleetVendorCompanyNameList,
      } = props;

      const requestData = R.compose(
        R.mergeRight({ type, payable, version, objGuid }),
        R.find(R.propEq(vendorGuid, GC.FIELD_GUID)),
      )(fleetVendorCompanyNameList);

      submitAction(requestData);
    },
  }),
);

const AssignVendorToDriverForm = driverFormEnhance((props: Object) => {
  const { handleSubmit, fleetVendorCompanyNameList } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        vendorOptions={G.addEmptyOptionToDropDown(fleetVendorCompanyNameList)}
      />
      <FormFooter2 />
    </form>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  assignment: makeSelectVendorAssignment(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    openModal,
    closeModal,
    assignVendorRequest,
    unAssignVendorRequest,
    assignVendorToDriverRequest,
  }),
  withHandlers({
    handleAssignVendor: (props: Object) => (vendorGuid: Object) => {
      const {
        type,
        payable,
        version,
        objGuid,
        openModal,
        closeModal,
        branchGuid,
        fleetVendorList,
        assignVendorRequest,
        assignVendorToDriverRequest,
      } = props;

      let component;

      if (R.equals(type, 'driver')) {
        component = (
          <AssignVendorToDriverForm
            type={type}
            payable={payable}
            version={version}
            objGuid={objGuid}
            branchGuid={branchGuid}
            vendorGuid={vendorGuid}
            closeModal={closeModal}
            fleetVendorList={fleetVendorList}
            submitAction={assignVendorToDriverRequest}
          />
        );
      } else {
        component = (
          <AssignVendorForm
            type={type}
            version={version}
            objGuid={objGuid}
            branchGuid={branchGuid}
            vendorGuid={vendorGuid}
            closeModal={closeModal}
            submitAction={assignVendorRequest}
          />
        );
      }

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:assign-vendor', 'Assign Vendor'),
      );

      openModal(modal);
    },
    handleUnAssignVendor: (props: Object) => ({ guid, name }: Object) => {
      const {
        type,
        version,
        objGuid,
        openModal,
        unAssignVendorRequest,
      } = props;

      const textLocale = G.getWindowLocale(
        'messages:driver-unassign:confirmation:text',
        'Are you sure, you want unassign',
      );

      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          title: G.getWindowLocale('titles:unassign-vendor', 'Unassign Vendor'),
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:save', 'Save'),
              action: () => unAssignVendorRequest({ type, guid, version, objGuid }),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const VendorAssignment = (props: Object) => {
  const {
    type,
    hasAction,
    assignment,
    fleetVendorName,
    fleetVendorGuid,
    handleAssignVendor,
    handleUnAssignVendor,
  } = props;

  const name = R.pathOr(fleetVendorName, [type, GC.FIELD_NAME], assignment);
  const guid = R.pathOr(fleetVendorGuid, [type, GC.FIELD_GUID], assignment);

  return (
    <Flex py='5px' mx={10} maxWidth={250}>
      <Box>
        {G.getWindowLocale('titles:vendor', 'Vendor')}:
      </Box>
      {
        G.isNotNilAndNotEmpty(guid) &&
        <TextComponent
          ml='8px'
          title={name}
          maxWidth={160}
          display='block'
          cursor='pointer'
          withEllipsis={true}
          color={G.ifElse(hasAction, G.getTheme('colors.light.blue'))}
          onClick={() => {
            if (hasAction) G.goToRoute(routesMap[G.getFleetProfileRoutePathNameByFleetType('vendor')](guid));
          }}
        >
          {name}
        </TextComponent>
      }
      <Box ml='8px' cursor='pointer' onClick={() => handleAssignVendor(guid)}>
        {G.ifElse(
          G.isNotNilAndNotEmpty(guid),
          I.pencil,
          I.plusRound,
        )()}
      </Box>
      {
        G.isNotNilAndNotEmpty(guid) &&
        <Box ml='8px' cursor='pointer' onClick={() => handleUnAssignVendor({ guid, name })}>
          {I.trash()}
        </Box>
      }
    </Flex>
  );
};

export default enhance(VendorAssignment);
