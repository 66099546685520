import React from 'react';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
//////////////////////////////////////////////////

const SelectLoadsFor = ({ options, closeModal, returnObject, handleSubmitAction }: Object) => (
  <SelectDropdownForm
    fieldWidth={420}
    options={options}
    optionRequired={false}
    fieldsWrapperStyles={{ pt: '0px' }}
    submitAction={(value: string) => {
      closeModal();
      handleSubmitAction({ returnObject, selectedValue: { value } });
    }}
  />
);

export default SelectLoadsFor;
