import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Span, Flex, scrollableContainerCss4px } from '../../../ui';
//////////////////////////////////////////////////

const ScorecardValue = ({ value, percentValue }: Object) => {
  if (G.isNilOrZero(value)) return <TextComponent color='dark.darkBlue'>N/A</TextComponent>;

  return (
    <TextComponent color='dark.darkBlue'>
      {
        G.isNotNil(percentValue) &&
        <Span mr={15} fontWeight='bold' color='dark.darkGrey'>({percentValue})%</Span>
      }
      {value}
    </TextComponent>
  );
};

const Scorecard = (props: Object) => {
  const { data, prefixSymbol } = props;

  return (
    <Box mt={10} width='100%' maxHeight='100%' overflowY='auto' css={scrollableContainerCss4px}>
      {
        data.map(({ name, value, percentValue }: Object, index: number) => (
          <Flex
            p={10}
            mb={14}
            key={index}
            fontWeight='bold'
            borderRadius={12}
            bg='transparentDarkBlue'
            justifyContent='space-between'
          >
            <TextComponent color='dark.darkGrey'>{name}</TextComponent>
            <ScorecardValue value={prefixSymbol ? `${prefixSymbol} ${value}` : value} percentValue={percentValue} />
          </Flex>
        ))
      }
    </Box>
  );
};

export default Scorecard;
