import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../../components/form-footer';
import { LocalLoader } from '../../../../../components/local-loader';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withAsyncGetRoles } from '../../../../../hocs';
// ui
import { Box } from '../../../../../ui';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 30,
  width: 250,
};

const fieldSettings = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_MASTER_BRANCH_SUFFIX,
    label: ['titles:master-branch-suffix', 'Master Branch Suffix'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_CUSTOMER_BRANCH_SUFFIX,
    label: ['titles:customer-branch-suffix', 'Customer Branch Suffix'],

  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: 'sequenceOptions',
    fieldName: GC.FIELD_ACCOUNT_SEQUENCE_GUID,
    label: ['titles:account-number-sequence', 'Account Number Sequence'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: 'asyncRoleOptions',
    fieldName: GC.FIELD_MAIN_USER_ROLE_GUID,
    label: ['titles:main-user-role', 'Main User Role'],
  },
  {
    isMulti: true,
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'asyncRoleOptions',
    fieldName: GC.FIELD_GRANTED_ROLES,
    label: ['titles:granted-roles', 'Granted Roles'],
  },
];

const defaultFields = {
  [GC.FIELD_GRANTED_ROLES]: [],
  [GC.FIELD_MAIN_USER_ROLE_GUID]: null,
  [GC.FIELD_MASTER_BRANCH_SUFFIX]: null,
  [GC.FIELD_CUSTOMER_BRANCH_SUFFIX]: null,
};

export const validationSchema = () => {
  const schema = {
    [GC.FIELD_GRANTED_ROLES]: G.yupArrayRequired,
    [GC.FIELD_MAIN_USER_ROLE_GUID]: G.yupStringRequired,
    [GC.FIELD_MASTER_BRANCH_SUFFIX]: G.yupStringRequired,
    [GC.FIELD_CUSTOMER_BRANCH_SUFFIX]: G.yupStringRequired,
  };

  return Yup.object().shape(schema);
};

const enhance = compose(
  withAsyncGetRoles(),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const LosSubscriptionForm = (props: Object) => {
  const { handleSubmit, loadingState, sequenceOptions, asyncRoleOptions } = props;

  const formikProps = G.getFormikProps(props);

  return (
    <Box mt={15} width={250}>
      <LocalLoader localLoaderOpen={(loadingState)}>
        <form onSubmit={handleSubmit}>
          <Fieldset2
            {...formikProps}
            fields={fieldSettings}
            sequenceOptions={sequenceOptions}
            asyncRoleOptions={asyncRoleOptions}
          />
          <FormFooter2 />
        </form>
      </LocalLoader>
    </Box>
  );
};

export default enhance(LosSubscriptionForm);
