import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const getTabs = (activeTab: number) => [
  {
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:route-list', 'Route List'),
  },
  {
    withCount: false,
    text: G.getWindowLocale('titles:route-by-load-list', 'Route By Load List'),
  },
  {
    withCount: false,
    text: `${G.getWindowLocale('titles:pivot-table', 'Pivot Table')} ${
      G.getWindowLocale('titles:route-by-load-list', 'Route By Load List')}`,
  },
];
