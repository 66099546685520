import * as R from 'ramda';
import * as Charts from '@amcharts/amcharts4/charts';
import * as ChartCore from '@amcharts/amcharts4/core';
// helpers/constants
import * as G from '../../../../helpers';
// feature charts
import * as LC from '../../constants';
//////////////////////////////////////////////////////

const axisYConfigs = (props: Object, chart: Object) => {
  const axisY = chart.yAxes.push(new Charts.ValueAxis());
  axisY.title.text = R.compose(
    R.join('/'),
    R.map((y: string = '') => G.getWindowLocale(R.path([y], LC.AXIS_X_TITLES), '...')),
  )(props.chartData.axesY);
  if (R.isEmpty(props.chartData.data)) return;
  const max = R.compose(
    R.reduce((value: number, arr: Array) => R.max(value, ...arr), 0),
    R.map((param: Object) => R.map(
      (y: Object) => param[y],
      props.chartData.axesY,
    )),
  )(props.chartData.data);
  const min = R.compose(
    R.reduce((value: number, arr: Array) => R.min(value, ...arr), max),
    R.map((param: Object) => R.map(
      (y: Object) => param[y],
      props.chartData.axesY,
    )),
  )(props.chartData.data);
  for (let i = 0.1; i < 0.5; i += 0.1) {
    if (R.lt(min, R.multiply(max, i))) {
      axisY.min = min;
      axisY.max = R.multiply(max, 1.1);
      axisY.strictMinMax = true;
      const axisBreak = axisY.axisBreaks.create();
      axisBreak.startValue = R.multiply(max, i);
      axisBreak.endValue = R.multiply(max, 0.9);
      axisBreak.breakSize = 0.02;
      const hoverState = axisBreak.states.create('hover');
      hoverState.properties.breakSize = 1;
      hoverState.properties.opacity = 0.1;
      hoverState.transitionDuration = 1500;
      axisBreak.defaultState.transitionDuration = 1000;
      break;
    }
  }
};

const axisXConfigs = (props: Object, chart: Object) => {
  chart.scrollbarX = new ChartCore.Scrollbar(); // eslint-disable-line
  const axisX = chart.xAxes.push(new Charts.CategoryAxis());
  axisX.renderer.minGridDistance = 30;
  axisX.renderer.cellEndLocation = 0.9;
  axisX.renderer.cellStartLocation = 0.1;
  axisX.renderer.labels.template.rotation = 45;
  axisX.renderer.labels.template.maxWidth = 100;
  axisX.renderer.labels.template.truncate = true;
  axisX.dataFields.category = props.chartData.axisX;
  axisX.renderer.labels.template.horizontalCenter = 'left';
  axisX.renderer.labels.template.verticalCenter = 'middle';
  axisX.renderer.labels.template.tooltipText = '{category}';
  axisX.title.text = G.getWindowLocale(R.path([props.chartData.axisX], LC.AXIS_X_TITLES), '...');
};

const createBarCharts = (props: Object, chart: Object, field: string, name: string) => {
  let barCharts = null;
  if (R.equals(LC.CHART_TYPE_BAR3D, props.type)) {
    barCharts = chart.series.push(new Charts.ColumnSeries3D());
  } else {
    barCharts = chart.series.push(new Charts.ColumnSeries());
  }
  barCharts.dataFields.valueY = field;
  barCharts.dataFields.categoryX = props.chartData.axisX;
  barCharts.columns.template.column.fillOpacity = 0.6;
  barCharts.name = name;
  barCharts.columns.template.strokeWidth = 0;
  barCharts.columns.template.tooltipText = '[bold]{categoryX}:[/]\n  {name} - {valueY}';
  if (R.equals(props.chartData.axesY.length, 1)) {
    barCharts.columns.template.adapter.add(
      'fill',
      (fill: Object, target: Object) => chart.colors.getIndex(target.dataItem.index),
    );
  }
  const hoverState = barCharts.columns.template.column.states.create('hover');
  hoverState.properties.fillOpacity = 1;
};

const createLineCharts = (props: Object, chart: Object, field: string, name: string) => {
  const lineCharts = chart.series.push(new Charts.LineSeries());
  lineCharts.name = name;
  lineCharts.dataFields.valueY = field;
  lineCharts.dataFields.categoryX = props.chartData.axisX;
  lineCharts.strokeWidth = 2;
  const circleBullet = lineCharts.bullets.push(new Charts.CircleBullet());
  circleBullet.circle.stroke = ChartCore.color('#fff');
  circleBullet.circle.strokeWidth = 2;
  circleBullet.tooltipText = '{categoryX}: [bold]{valueY}[/]';
};

const barTypeConfigs = (props: Object, chart: Object) => {
  axisXConfigs(props, chart);
  axisYConfigs(props, chart);
  R.forEach(
    (field: string) => createBarCharts(
      props,
      chart,
      field,
      G.getWindowLocale(R.path([field], LC.AXIS_X_TITLES), '...')
    ),
    props.chartData.axesY,
  );
};

const pieTypeConfigs = (props: Object, chart: Object) => {
  let pieCharts = null;
  if (R.equals(LC.CHART_TYPE_PIE3D, props.type)) {
    pieCharts = chart.series.push(new Charts.PieSeries3D());
  } else {
    pieCharts = chart.series.push(new Charts.PieSeries());
    pieCharts.slices.template.stroke = ChartCore.color('#fff');
    pieCharts.slices.template.strokeWidth = 1;
    pieCharts.slices.template.strokeOpacity = 1;
  }
  pieCharts.dataFields.value = props.chartData.axesY;
  pieCharts.dataFields.category = props.chartData.axisX;
  pieCharts.labels.template.disabled = true;
  pieCharts.ticks.template.disabled = true;
  pieCharts.slices.template.tooltipText = '{category}: [bold]{value}';
  chart.legend = new Charts.Legend(); // eslint-disable-line
  const legendContainer = ChartCore.create(`legenddiv-${props.guid}`, ChartCore.Container);
  legendContainer.width = 170;
  legendContainer.height = ChartCore.percent(100);
  chart.legend.parent = legendContainer; // eslint-disable-line
  chart.legend.labels.template.truncate = true; // eslint-disable-line
  chart.legend.labels.template.minWidth = 170; // eslint-disable-line
  chart.legend.labels.template.tooltipText = '{category}'; // eslint-disable-line
  const resizeLegend = () => {
    document.getElementById(`legenddiv-${props.guid}`).style.height = `${chart.legend.contentHeight}px`;
  };
  chart.events.on('datavalidated', resizeLegend);
  chart.events.on('maxsizechanged', resizeLegend);
};

const lineTypeConfigs = (props: Object, chart: Object) => {
  axisXConfigs(props, chart);
  axisYConfigs(props, chart);
  R.forEach(
    (field: string) => createLineCharts(
      props,
      chart,
      field,
      G.getWindowLocale(R.path([field], LC.AXIS_X_TITLES), '...'),
    ),
    props.chartData.axesY,
  );
};

export const chartConfigsByType = {
  [LC.CHART_TYPE_BAR]: {
    create: 'XYChart',
    configs: barTypeConfigs,
  },
  [LC.CHART_TYPE_BAR3D]: {
    create: 'XYChart3D',
    configs: barTypeConfigs,
  },
  [LC.CHART_TYPE_PIE]: {
    create: 'PieChart',
    configs: pieTypeConfigs,
  },
  [LC.CHART_TYPE_PIE3D]: {
    create: 'PieChart3D',
    configs: pieTypeConfigs,
  },
  [LC.CHART_TYPE_LINE]: {
    create: 'XYChart',
    configs: lineTypeConfigs,
  },
};
