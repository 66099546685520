import * as R from 'ramda';
import React from 'react';
// components
import { EmptyList } from '../../../../../components/list';
import { LoadStatusActions } from '../../../../../components/load-status-actions';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex } from '../../../../../ui';
// feature configurations
import { ConfigGroupLeftSectionWrapper } from '../../../ui';
//////////////////////////////////////////////////

const DispatchPanelConfig = (props: Object) => {
  const {
    loadType,
    cloPanel,
    telPanel,
    handleEditEntity,
    deleteDispatchBoardStatusActionRequest } = props;
  const panel = G.ifElse(R.equals(loadType, GC.LOAD_TYPE_TEL), telPanel, cloPanel);
  const iconColor = G.getTheme('icons.iconColor');
  return (
    <Box>
      {
        G.isNilOrEmpty(panel) &&
        <EmptyList width='100%'>
          {G.getWindowLocale('titles:empty-list', 'Empty List')}
        </EmptyList>
      }
      {
        G.isNotNilAndNotEmpty(panel) &&
        panel.map((statusActionsObject: Object, i: number) => (
          <Flex
            key={i}
            height={55}
            alignItems='stretch'
            borderBottom='1px solid'
            borderColor={G.getTheme('colors.lightGrey')}
          >
            <ConfigGroupLeftSectionWrapper>
              {G.getWindowLocale(GC.statusLocaleMap[statusActionsObject.status], statusActionsObject.status)}
            </ConfigGroupLeftSectionWrapper>
            <Flex flexGrow='1' p='0px 15px' justifyContent='space-between'>
              <Flex>
                <Flex fontSize={12} alignItems='stretch' flexDirection='column'>
                  <Flex mb='8px' mr={16} height={20}>
                    {G.getWindowLocale('titles:first-row', 'First Row')}:
                  </Flex>
                  <Flex mr={16} height={20}>
                    {G.getWindowLocale('titles:second-row', 'Second Row')}:
                  </Flex>
                </Flex>
                <LoadStatusActions loadType={loadType} data={statusActionsObject} withoutActions={true} />
              </Flex>
              <Flex>
                <Box ml='8px' cursor='pointer' onClick={() => handleEditEntity(statusActionsObject, loadType)}>
                  {I.renderEditIcon(iconColor)}
                </Box>
                <Box
                  ml='8px'
                  cursor='pointer'
                  onClick={() => deleteDispatchBoardStatusActionRequest({
                    loadType,
                    guid: statusActionsObject[GC.FIELD_GUID],
                  })}
                >
                  {I.trash(iconColor)}
                </Box>
              </Flex>
            </Flex>
          </Flex>
        ))
      }
    </Box>
  );
};

export default DispatchPanelConfig;
