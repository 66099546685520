import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { BRANCH_TYPE_OPTIONS } from '../../../helpers/options';
//////////////////////////////////////////////////

const BranchValidationSchema = {
  [GC.FIELD_BRANCH_BRANCH_NAME]: Yup.string()
    .nullable(true)
    .min(3, G.getShouldBeFromToLocaleTxt(3, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(3, 40))
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_BRANCH_ACCOUNT_NAME]: Yup.string()
    .nullable(true)
    .min(2, G.getShouldBeFromToLocaleTxt(2, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(2, 40))
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_BRANCH_BRANCH_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_BRANCH_CREDIT_LIMIT]: Yup.number()
    .nullable(true)
    .typeError(G.getShouldBeIntegerLocaleTxt()),
};

const getCustomerDivisionValidationSchema = () => BranchValidationSchema;

const getMasterValidationSchema = () => {
  const defaultSchema = BranchValidationSchema;

  return R.mergeRight(
    defaultSchema,
    {
      [GC.FIELD_BRANCH_MC_NUMBER]: Yup.string()
        .nullable(true)
        .required(G.getWindowLocale('validation:mc-or-usdot-number', 'Please, enter an MC Number or USDOT Number')),
      [GC.FIELD_BRANCH_US_DOT_NUMBER]: Yup.string()
        .nullable(true)
        .required(G.getWindowLocale('validation:mc-or-usdot-number', 'Please, enter MC Number or USDOT Number')),
      [GC.FIELD_BRANCH_FEDERAL_EIN]: Yup.string()
        .nullable(true)
        .required(G.getWindowLocale('validation:federal-ein', 'Please, enter a Federal EIN')),
    },
  );
};

export const getValidationSchema = (values: Object) => {
  const condition = values[GC.FIELD_BRANCH_BRANCH_TYPE];

  switch (condition) {
    case GC.BRANCH_TYPE_ENUM_MASTER:
      return getMasterValidationSchema();
    default:
      return getCustomerDivisionValidationSchema();
  }
};

export const defaultBranchValues = {
  [GC.FIELD_BRANCH_NOTES]: '',
  [GC.FIELD_BRANCH_CURRENCY]: '',
  [GC.FIELD_BRANCH_MC_NUMBER]: '',
  [GC.FIELD_BRANCH_FEDERAL_EIN]: '',
  [GC.FIELD_BRANCH_DESCRIPTION]: '',
  [GC.FIELD_BRANCH_DUNS_NUMBER]: '',
  [GC.FIELD_BRANCH_BRANCH_NAME]: '',
  [GC.FIELD_BRANCH_ACCOUNT_NAME]: '',
  [GC.FIELD_BRANCH_CREDIT_LIMIT]: '',
  [GC.FIELD_BRANCH_US_DOT_NUMBER]: '',
  [GC.FIELD_BRANCH_DEACTIVATE_ON_CREDIT_LIMIT]: false,
  [GC.FIELD_BRANCH_BRANCH_TYPE]: GC.BRANCH_TYPE_ENUM_CUSTOMER,
};

export const setInitialBranchFormikValues = (
  defaultValues: any,
  initialValues: any,
) => {
  if (G.isNotNilAndNotEmpty(initialValues)) {
    return R.mergeRight(defaultValues, initialValues);
  }

  return defaultValues;
};

const inputWrapperStyles = { mb: 25, width: 250 };

export const getBranchFormikFields = (allowDivision: boolean) => [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_BRANCH_BRANCH_NAME,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:dba', 'DBA'],
    fieldName: GC.FIELD_BRANCH_DBA,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_BRANCH_ACCOUNT_NAME,
    label: ['titles:account-number', 'Account Number'],
    inputWrapperStyles: {...inputWrapperStyles, zIndex: 12},
    info: G.getWindowLocale(
      'messages:enterprise-account-number-info',
      'Unique Number used to identify enterprise',
    ),
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:type', 'Type'],
    fieldName: GC.FIELD_BRANCH_BRANCH_TYPE,
    options: G.ifElse(
      allowDivision,
      BRANCH_TYPE_OPTIONS,
      R.of(Array, R.head(BRANCH_TYPE_OPTIONS)),
    ),
    info: G.getWindowLocale(
      'messages:enterprise-type-info',
      'Each Type has validation on create',
    ),
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: 'subTypeOptions',
    fieldName: GC.FIELD_BRANCH_SUBTYPE,
    label: ['titles:branch-subtype', 'Branch Subtype'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_MC_NUMBER,
    label: ['titles:mc-number', 'MC Number'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_US_DOT_NUMBER,
    label: ['titles:usdot-number', 'USDOT Number'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_FEDERAL_EIN,
    label: ['titles:federal-ein', 'Federal EIN'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_DESCRIPTION,
    label: ['titles:description', 'Description'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_NOTES,
    label: ['titles:display-notes', 'Display Notes'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_BRANCH_CURRENCY,
    label: ['titles:currency', 'Currency'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_DUNS_NUMBER,
    label: ['titles:duns-number', 'D-U-N-S Number'],
  },
  {
    type: 'number',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_CREDIT_LIMIT,
    label: ['titles:credit-limit', 'Credit Limit'],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_BRANCH_DEACTIVATE_ON_CREDIT_LIMIT,
    label: ['titles:deactivate:on-credit-limit', 'Deactivate On Credit Limit'],
  },
];
