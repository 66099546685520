import * as R from 'ramda';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
// common
import { setSocketConnection } from '../../common/actions';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { messageType } from '../sockets-v2/constants';
import { socketPostMessage } from '../sockets-v2/actions';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getDriverAvailabilityStatusRequest } from '../driver-profile-simple/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature available-driver
import * as A from './actions';
import {
  makeSelectItemList,
  makeSelectActiveList,
  makeSelectPageVisited,
  makeSelectAllDriversList,
  makeSelectAllDriversPagination,
} from './selectors';
//////////////////////////////////////////////////

function* subscribeToSocketSaga() {
  const activeList = yield select(makeSelectActiveList());
  const list = yield select(makeSelectItemList());
  const allList = yield select(makeSelectAllDriversList());

  const listToUse = R.equals(activeList, 'allDrivers') ? R.or(allList, {}) : R.or(list, {});

  yield all(R.map((item: string) => put(socketPostMessage({
    action: messageType.subscribe,
    destination: `/driver/${G.getGuidFromObject(item)}/availableDrivers`,
  })), R.values(listToUse)));
}

function* socketUnsubscribeSaga() {
  const list = yield select(makeSelectItemList());
  const allList = yield select(makeSelectAllDriversList());
  const listToUse = R.values(R.mergeRight(R.or(list, {}), R.or(allList, {})));

  yield all(R.map((item: string) => put(socketPostMessage({
    action: messageType.unsubscribe,
    destination: `/driver/${G.getGuidFromObject(item)}/availableDrivers`,
  })), listToUse));
}

function* handleGetItemListSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        ...payload,
        [GC.BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.availableDriverList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const prevDriverList = yield select(makeSelectItemList());

      // yield put(socketAvailableDriversConnectRequest(R.map(R.prop(GC.FIELD_GUID), data)));
      yield all(R.map((item: string) => put(socketPostMessage({
        action: messageType.subscribe,
        destination: `/driver/${G.getGuidFromObject(item)}/availableDrivers`,
      })), data));

      yield put(A.getItemListSuccess({ driverList: data, prevDriverListGuids: R.keys(prevDriverList) }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleGetDriverAvailabilityTotalCountRequestSaga() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.driverAvailabilityTotalCount, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverAvailabilityTotalCountSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverAvailabilityTotalCountRequestSaga fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetDriverAvailabilityTotalCountRequestSaga exception');
  }
}

function* handleChangeActiveListSaga({ payload }: Object) {
  try {
    yield call(socketUnsubscribeSaga);

    yield put(A.setActiveList(payload));

    if (R.equals(payload, 'allDrivers')) {
      yield put(openLoader({ showDimmer: true }));
      yield put(A.getDriverAvailabilityTotalCountRequest());
      yield put(A.getAllDriversListRequest(true));
    } else {
      const allDriversList = yield select(makeSelectAllDriversList());

      yield put(A.resetAllDriversListAndPagination(R.keys(allDriversList)));
    }
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleChangeActiveListSaga exception');
  }
}

export function* handleGetAllDriversListRequestSaga({ payload }: Object) {
  try {
    yield put(A.setAllDriversListLoading(true));

    const pagination = yield select(makeSelectAllDriversPagination());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const { limit, offset } = pagination;

    const options = {
      data: {
        limit,
        offset,
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.driverAvailabilityAllDriversList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAllDriversListSuccess(data));
      // yield put(socketAllDriversConnectRequest(R.map(R.prop(GC.FIELD_GUID), data)));

      yield all(R.map((item: string) => put(socketPostMessage({
        action: messageType.subscribe,
        destination: `/driver/${G.getGuidFromObject(item)}/availableDrivers`,
      })), data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAllDriversListRequestSaga fail');
    }

    yield put(A.setAllDriversListLoading(false));

    if (G.isTrue(payload)) yield put(closeLoader());
  } catch (error) {
    yield put(A.setAllDriversListLoading(false));

    if (G.isTrue(payload)) yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetAllDriversListRequestSaga exception');
  }
}

function* handleCreateOrUpdateReservationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { isCreate } = payload;

    const reqData = R.omit(['isCreate'], payload);
    const method = G.ifElse(G.isTrue(isCreate), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.availableDriverReserve, { data: reqData });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateOrUpdateReservationRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateOrUpdateReservationRequestSaga exception');
  }
}

function* handleDeleteReservationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'post', endpointsMap.availableDriverDeleteReservation, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteReservationRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteReservationRequestSaga exception');
  }
}

function* handleCreateOrUpdateDriverNoteRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { isCreate } = payload;

    const reqData = R.omit(['isCreate'], payload);
    const method = G.ifElse(G.isTrue(isCreate), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.availableDriverNote, { data: reqData });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateOrUpdateDriverNoteRequestSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateOrUpdateDriverNoteRequestSaga exception');
  }
}

function* handleDeleteDriverNoteRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.availableDriverNote, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverNoteRequestSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverNoteRequestSaga exception');
  }
}

function* handleGetDriverUnavailablePeriodRequestSaga({ payload }: Object) {
  try {
    const { callback, driverGuid } = payload;

    const options = { params: { driverGuid } };

    const res = yield call(sendRequest, 'get', endpointsMap.routeFleetDriverUnavailablePeriodList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, data);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverUnavailablePeriodRequestSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDriverUnavailablePeriodRequestSaga exception');
  }
}

function* handleSetDriverUnavailableRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.driverAvailabilityUnavailable, { params: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(getDriverAvailabilityStatusRequest(G.getPropFromObject(GC.FIELD_DRIVER_GUID, payload)));
    } else {
      yield call(G.handleFailResponse, res, 'handleSetDriverUnavailableRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSetDriverUnavailableRequestSaga exception');
  }
}

function* handleSetDriverAvailableRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { location, driverGuid, availableDate } = payload;

    const options = {
      params: { driverGuid },
      data: { location, availableDate },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.driverAvailabilityAvailable, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(getDriverAvailabilityStatusRequest(driverGuid));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleSetDriverAvailableRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSetDriverAvailableRequestSaga exception');
  }
}

function* handleGetSearchLocationRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        templateId: payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.location, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { zip, city, state, country, address1, latitude, longitude } = data;

      const location = `${address1}, ${city}, ${state}, ${country}, ${zip}`;

      yield put(A.setLocationValue(location));
      yield put(A.setSearchLocation({ latitude, longitude }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSearchLocationRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetSearchLocationRequestSaga exception');
  }
}

function* handleVisitAvailableDriverListSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_ROUTE_AVAILABLE_DRIVER_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    yield put(openLoader({ showDimmer: true }));

    if (G.isFalse(pageVisited)) yield put(A.setInitialState());

    yield takeLatest(setSocketConnection, subscribeToSocketSaga);

    yield put(closeLoader());

    break;
  }
}

function* availableDriverWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.changeActiveList, handleChangeActiveListSaga);
  yield takeLatest(A.deleteDriverNoteRequest, handleDeleteDriverNoteRequestSaga);
  yield takeLatest(A.getAllDriversListRequest, handleGetAllDriversListRequestSaga);
  yield takeLatest(A.getSearchLocationRequest, handleGetSearchLocationRequestSaga);
  yield takeLatest(A.deleteReservationRequest, handleDeleteReservationRequestSaga);
  yield takeLatest(A.setDriverAvailableRequest, handleSetDriverAvailableRequestSaga);
  yield takeLatest(A.setDriverUnavailableRequest, handleSetDriverUnavailableRequestSaga);
  yield takeLatest(GC.VISIT_ROUTE_AVAILABLE_DRIVER_PAGE, handleVisitAvailableDriverListSaga);
  yield takeLatest(A.createOrUpdateDriverNoteRequest, handleCreateOrUpdateDriverNoteRequestSaga);
  yield takeLatest(A.createOrUpdateReservationRequest, handleCreateOrUpdateReservationRequestSaga);
  yield takeLatest(A.getDriverUnavailablePeriodRequest, handleGetDriverUnavailablePeriodRequestSaga);
  yield takeLatest(A.getDriverAvailabilityTotalCountRequest, handleGetDriverAvailabilityTotalCountRequestSaga);
}

export default availableDriverWatcherSaga;
