import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StyledLink } from '../../../ui';
//////////////////////////////////////////////////

// TODO: use global InfoPair component

const actionColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

export const EmailsInfoPair = ({ label, emails }: Object) => {
  if (G.isNilOrEmpty(emails)) return null;

  return (
    <Flex
      py='4px'
      fontSize={12}
      flexWrap='wrap'
      lineHeight={1.6}
      color={textColor}
      alignItems='flex-start'
    >
      <Box mr='5px' color={textColor}>
        {label}:
      </Box>
      <Box>
        {
          emails.map((email: string, index: number) => (
            <StyledLink
              key={index}
              display='block'
              fontWeight='bold'
              color={actionColor}
              wordBreak='break-all'
              href={`mailto:${email}`}
            >
              {email}
            </StyledLink>
          ))
        }
      </Box>
    </Flex>
  );
};

export const ContactInfoPair = (props: Object) => {
  const { text, phone, label, iconMargin, autodialApp, phoneNumber } = props;

  if (G.isNilOrEmpty(text)) return null;
  let href = `mailto:${text}`;
  if (G.isTrue(phone)) href = G.getCallUrl(autodialApp, R.or(phoneNumber, text));

  return (
    <Flex
      py='4px'
      fontSize={12}
      lineHeight={1.6}
      color={textColor}
    >
      {G.isNotNilAndNotEmpty(label) && <Box>{label}: </Box>}
      <StyledLink
        ml='5px'
        href={href}
        display='flex'
        fontWeight='bold'
        color={actionColor}
        wordBreak='break-all'
      >
        {text}
        {
          phone &&
          <Box ml={R.or(iconMargin, '5px')}>
            {I.phone(actionColor, 10, 10)}
          </Box>
        }
      </StyledLink>
    </Flex>
  );
};

export const InfoPair = ({ text, label, textMaxWidth, flexWrap, clickHandler }: Object) => {
  if (G.isNilOrEmpty(text)) return null;
  const withHandler = G.isFunction(clickHandler);
  const color = G.ifElse(withHandler, actionColor, null);

  return (
    <Flex
      py='4px'
      width='100%'
      fontSize={12}
      lineHeight={1.6}
      color={textColor}
      flexWrap={flexWrap}
    >
      {label}:
      <TextComponent
        ml='5px'
        title={text}
        color={color}
        fontWeight='bold'
        withEllipsis={true}
        display='inline-block'
        maxWidth={R.or(textMaxWidth, 200)}
        onClick={() => G.callFunction(clickHandler)}
        cursor={G.ifElse(withHandler, 'pointer', 'unset')}
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

export const InfoPairWithLabelAction = ({ text, label, action, labelActionText }: Object) => (
  <Flex
    py='4px'
    width='100%'
    fontSize={12}
    flexWrap='wrap'
    lineHeight={1.6}
    color={textColor}
  >
    <TextComponent
      title={label}
      maxWidth={45}
      withEllipsis={true}
      display='inline-block'
    >
      {label}
    </TextComponent>
    <TextComponent
      ml='5px'
      maxWidth={200}
      cursor='pointer'
      onClick={action}
      fontWeight='bold'
      color={actionColor}
      withEllipsis={true}
      display='inline-block'
      title={labelActionText}
      textDecoration='underline'
    >
      {labelActionText}
    </TextComponent>:
    <Box ml='5px' fontWeight='bold'>{text}</Box>
  </Flex>
);

export const InfoPairWithList = (props: Object) => {
  const { list, label, flexWrap, clickHandler, textMaxWidth, componentType } = props;

  if (G.isNilOrEmpty(list)) return null;

  const listLength = R.length(list);
  const withHandler = G.isFunction(clickHandler);

  return (
    <Flex
      py='4px'
      width='100%'
      fontSize={12}
      lineHeight={1.6}
      color={textColor}
      flexWrap={flexWrap}
    >
      {label}:
      {
        list.map((item: Object, index: number) => (
          <TextComponent
            ml='5px'
            key={item.guid}
            fontWeight='bold'
            withEllipsis={true}
            display='inline-block'
            maxWidth={R.or(textMaxWidth, 200)}
            title={R.path([GC.FIELD_DISPLAY_VALUE], item)}
            color={G.ifElse(withHandler, actionColor, null)}
            cursor={G.ifElse(withHandler, 'pointer', 'unset')}
            onClick={() => G.callFunctionWithArgs(clickHandler, { item, componentType })}
          >
            {R.path([GC.FIELD_DISPLAY_VALUE], item)}
            {G.notEquals(index, listLength - 1) && ','}
          </TextComponent>
        ))
      }
    </Flex>
  );
};
