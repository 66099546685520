import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const selectItem = createAction('selectItem');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const getChartRequest = createAction('getChartRequest');
export const getChartSuccess = createAction('getChartSuccess');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setRequestPending = createAction('setRequestPending');
export const createChartRequest = createAction('createChartRequest');
export const updateChartRequest = createAction('updateChartRequest');
export const deleteChartRequest = createAction('deleteChartRequest');
export const deleteChartSuccess = createAction('deleteChartSuccess');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const getChartPreviewFailed = createAction('getChartPreviewFailed');
export const setIgnorePromptStatus = createAction('setIgnorePromptStatus');
export const getListOfChartsRequest = createAction('getListOfChartsRequest');
export const getListOfChartsSuccess = createAction('getListOfChartsSuccess');
export const getChartConfigsRequest = createAction('getChartConfigsRequest');
export const getChartConfigsSuccess = createAction('getChartConfigsSuccess');
export const getChartPreviewRequest = createAction('getChartPreviewRequest');
export const getChartPreviewSuccess = createAction('getChartPreviewSuccess');
export const createReferenceSuccess = createAction('createReferenceSuccess');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
