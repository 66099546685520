import * as R from 'ramda';
import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectReportType,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('ediIntegration');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectGroupBy = () => createSelector(
  store,
  ({ groupBy }: Object) => groupBy,
);

const makeSelectGlobalFilterValue = () => createSelector(
  store,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectFilterProps = () => createSelector(
  store,
  ({ filterProps }: Object) => filterProps,
);

const makeSelectExternalIdFilter = () => createSelector(
  store,
  ({ externalIdFilter }: Object) => externalIdFilter,
);

export {
  makeSelectGroupBy,
  makeSelectItemList,
  makeSelectReportType,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectExternalIdFilter,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectGlobalFilterValue,
};
