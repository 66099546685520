import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
import { DUTY_STATUS_CODE_OPTIONS } from '../../../helpers/options';
//////////////////////////////////////////////////

// driver configs
export const DRIVER_ACCESSORIALS_CONFIGS = 'accessorialsConfigs';

export const ADD_DRIVER_FORM = 'ADD_DRIVER_FORM';
export const EDIT_DRIVER_DETAIL = 'EDIT_DRIVER_DETAIL';
export const ORIGIN_CITY_OPTIONS = 'originCityOptions';
export const DRIVER_CONTACT_FORM = 'DRIVER_CONTACT_FORM';
export const DRIVER_DOCUMENT_FORM = 'DRIVER_DOCUMENT_FORM';
export const ORIGIN_ZIP_CODES_OPTIONS = 'originZipCodesOptions';
export const DRIVER_ACCESSORIALS_OPTIONS = 'accessorialsOptions';
export const DRIVER_INDEXED_ACCESSORIALS = 'indexedAccessorials';
export const DESTINATION_CITY_OPTIONS = 'destinationCityOptions';
export const DRIVER_COMPENSATION_FORM = 'DRIVER_COMPENSATION_FORM';
export const DRIVER_CERTIFICATION_FORM = 'DRIVER_CERTIFICATION_FORM';
export const LATEST_KNOWN_LOCATION_FORM = 'LATEST_KNOWN_LOCATION_FORM';
export const DESTINATION_ZIP_CODES_OPTIONS = 'destinationZipCodesOptions';
export const COMPENSATION_OPTIONS_PICK = [
  ORIGIN_CITY_OPTIONS,
  DESTINATION_CITY_OPTIONS,
  ORIGIN_ZIP_CODES_OPTIONS,
  DESTINATION_ZIP_CODES_OPTIONS,
];
/* eslint-disable */
export const FIELD_DRIVER_ENDORSEMENTS = 'endorsements';
export const FIELD_DRIVER_COMPENSATION_ORIGIN = 'origin';
export const FIELD_DRIVER_COMPENSATION_DESTINATION = 'destination';
export const FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_ZIP}`;
export const FIELD_DRIVER_COMPENSATION_ORIGIN_CITY = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_CITY}`;
export const FIELD_DRIVER_COMPENSATION_ORIGIN_CITIES = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_CITIES}`;
export const FIELD_DRIVER_COMPENSATION_ORIGIN_STATES = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_STATES}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_ZIP}`;
export const FIELD_DRIVER_COMPENSATION_ORIGIN_COUNTRIES = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_COUNTRIES}`;
export const FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_CODES = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_ZIP_CODES}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_CITY = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_CITY}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_CITIES = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_CITIES}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_STATES = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_STATES}`;
export const FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_TO = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_COUNTRIES = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_COUNTRIES}`;
export const FIELD_DRIVER_COMPENSATION_ORIGIN_ZIP_RANGE_FROM = `${FIELD_DRIVER_COMPENSATION_ORIGIN}:${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_CODES = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_ZIP_CODES}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_TO = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO}`;
export const FIELD_DRIVER_COMPENSATION_DESTINATION_ZIP_RANGE_FROM = `${FIELD_DRIVER_COMPENSATION_DESTINATION}:${GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM}`;
/* eslint-disable */

export const DRIVER_COMPENSATION_MAIN_FIELDS_OMIT = [
  FIELD_DRIVER_COMPENSATION_ORIGIN_CITY,
  FIELD_DRIVER_COMPENSATION_DESTINATION_CITY,
  ...GC.GROUPED_FIELDS.DRIVER_COMPENSATION_MAIN_FIELDS,
];

export const INDEXED_DUTY_STATUS_CODE_OPTIONS = R.compose(
  R.map(R.prop('label')),
  R.indexBy(R.prop('value')),
)(DUTY_STATUS_CODE_OPTIONS);

// group constants
export const DRIVER_GROUP_NAME_CONTACTS = 'contacts';
export const DRIVER_GROUP_ACCESSORIALS = 'accessorials';
export const DRIVER_GROUP_ASSESSORIALS = 'assessorials';
export const DRIVER_GROUP_NAME_INTEGRATION = 'integration';
export const DRIVER_GROUP_OPTIONS_GROUPS = 'optionsGroups';
export const DRIVER_GROUP_NAME_COMPENSATION = 'compensation';
export const DRIVER_GROUP_NAME_CERTIFICATION = 'certification';
export const DRIVER_GROUP_NAME_ADVANCE_PAYMENT = 'advancePayment';
export const DRIVER_GROUP_PAYROLL_ASSESSORIALS = 'payrolAssessorials';
export const DRIVER_GROUP_NAME_DEDUCTION_SETTINGS = 'deductionSettings';
export const DRIVER_GROUP_NAME_PAYROLL_COMPENSATION = 'payrollCompensation';
export const DRIVER_GROUP_NAME_LATES_KNOWN_LOCATIONS = 'driverLatestKnownLocation';
export const DRIVER_GROUP_NAME_MONTHLY_PAYROLL_DEDUCTION = 'monthlyPayrollDeduction';

export const DRIVER_DAILY_HOURS = 'DRIVER_DAILY_HOURS';
