import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature carrier-profile
import { removeEntityRequest } from '../actions';
import AwarderLaneForm from '../forms/awarded-lane';
//////////////////////////////////////////////////

const getAddressPointFieldsByType = (prefix: string) => {
  const localePrefix = G.ifElse(
    R.equals(prefix, GC.FIELD_ORIGIN),
    'titles:origin',
    'titles:destination',
  );

  const getLocaleArr = (key: string) => [localePrefix, key];

  return {
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:cities'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:states'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:countries'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`]: {
      width: 200,
      collection: true,
      name: getLocaleArr('titles:zip-codes'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`]: {
      name: getLocaleArr('titles:zip-from'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`]: {
      name: getLocaleArr('titles:zip-to'),
    },
  };
};

const fields = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:min-rate',
  },
  [GC.FIELD_EFFECTIVE_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:effective-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:expiration-date',
  },
  ...getAddressPointFieldsByType(GC.FIELD_ORIGIN),
  ...getAddressPointFieldsByType(GC.FIELD_DESTINATION),
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const makeRequestPayload = ({ primaryObjectGuid, primaryObjectBranchGuid }: Object) => ({
  [GC.CURRENT_BRANCH]: primaryObjectBranchGuid,
  params: { [GC.FIELD_CONTRACT_GUID]: primaryObjectGuid },
});

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = R.mapObjIndexed((
  { name, options, collection, filterType, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.ifElse(
    G.isArray(name),
    `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
    G.getWindowLocale(name),
  ),
}), fields);

const carrierContractAwardedLaneSettings = {
  fields,
  filterProps,
  isReport: true,
  useGetListRequest: true,
  useSearchableColumns: true,
  withResizableColumns: true,
  useNewTitleFilterInputs: true,
  getItemListRequestMethod: 'post',
  groupName: 'carrierContract.awardedLane',
  primaryObjectGuidKey: GC.FIELD_CONTRACT_GUID,
  omitFormFields: GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR,
  reportType: GC.CARRIER_CONTRACT_AWARDED_LINE_REPORT,
  additionalTableSettings: { searchableTitles: true },
  itemTitleArr: ['titles:awarded-lane', 'Awarded Lane'],
  formGroupTitleArr: ['titles:contract-awarded-lanes', 'Contract Awarded Lanes'],
  endpoints: {
    list: 'carrierContractAwardedLineList',
    createOrUpdate: 'carrierContractAwardedLine',
  },
  // components
  CustomForm: AwarderLaneForm,
  // helpers
  makeRequestPayload,
  makeInitialValues: (initialValues: any, { carrierGuid, primaryObjectGuid }: Object) => ({
    ...initialValues,
    carrierGuid,
    [GC.FIELD_CONTRACT_GUID]: primaryObjectGuid,
  }),
  customRemoveHandler: ({ dispatch, groupName }: Object, { guid }: Object) => {
    const map = {
      'carrierContract.awardedLane': 'carrierContractAwardedLine',
      'customerContract.awardedLane': 'customerContractAwardedLine',
    };

    const endpointName = R.prop(groupName, map);
    const endpoint = R.prop(endpointName, endpointsMap);
    const requestOptions = { data: R.of(Array, guid) };

    dispatch(removeEntityRequest({ guid, isReport: true, method: 'delete', endpoint, groupName, requestOptions }));
  },
};

const customerContractAwardedLaneSettings = {
  ...carrierContractAwardedLaneSettings,
  groupName: 'customerContract.awardedLane',
  reportType: GC.CUSTOMER_CONTRACT_AWARDED_LINE_REPORT,
  endpoints: {
    list: 'customerContractAwardedLineList',
    createOrUpdate: 'customerContractAwardedLine',
  },
};

export {
  filterProps,
  carrierContractAwardedLaneSettings,
  customerContractAwardedLaneSettings,
};
