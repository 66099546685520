import * as R from 'ramda';
import { FieldArray } from 'formik';
import { withState } from 'react-recompose';
import React, { Fragment, useState, useEffect } from 'react';
// components
import { ItemInfo, ContainerInfo } from '../../../components/events';
// features
import { Fieldset } from '../../new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Span, Flex, StyledLink, ActionButton } from '../../../ui';
// feature carrier-portal
import * as H from '../helpers';
import { DeclineDispatchForm } from '../ui';
import {
  eventFieldSettings,
  chargesFieldSettings,
  currencyFieldSettings,
  commentsFieldSettings,
  referenceFieldSettings,
  additionalIfoFieldSettings,
  acceptDispatchFieldSections,
  declineDispatchFieldSettings,
  additionalChargesFieldSettings,
  declineOrderQuoteFieldSettings,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const redColor = G.getTheme('colors.light.mainRed');
const darkBlueColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.greyMatterhorn');
const lightGreenColor = G.getTheme('colors.light.green');

const defaultBoxStyles = {
  mt: 10,
  color: textColor,
  wordBreak: 'break-all',
};

const boxStylesWithBlueColor = {
  mt: 15,
  color: darkBlueColor,
};

const InfoPair = ({ text, label, boxStyles }: Object) => {
  if (G.isNilOrEmpty(text)) return null;

  return (
    <Box {...R.mergeRight(defaultBoxStyles, boxStyles)}>
      <Span mr='4px' fontWeight='bold'>{label}:</Span>
      {text}
    </Box>
  );
};

const EventInfo = ({ label, endDate, address, startDate }: Object) => (
  <Box mt={20} p={15} width='100%' borderRadius='8px' background='rgba(1, 82, 149, .1)'>
    <InfoPair boxStyles={{ mt: 0 }} label={label} text={G.renderFullAddressString(address)} />
    <InfoPair
      label={G.getWindowLocale('titles:date-time', 'Date/Time')}
      text={R.join(' - ', R.filter(G.isNotNilAndNotEmpty, [startDate, endDate]))}
    />
  </Box>
);

const FooterStamp = ({ linkStyles }: Object) => (
  <StyledLink
    {...linkStyles}
    display='flex'
    target='blank'
    alignItems='center'
    href='https://amousinternational.com/'
  >
    <Box color={darkBlueColor}>
      {G.getWindowLocale('titles:powered-by-amous-tms', 'Powered by Amous TMS')}
      <Box
        mt={10}
        target='blank'
        color={darkBlueColor}
        href='https://amousinternational.com/'
      >
        amousinternational.com
      </Box>
    </Box>
    <Box ml={15}>
      <img alt='Logo' width={50} src={G.getNewLogoLink()} />
    </Box>
  </StyledLink>
);

const totalRateBoxStyles = {
  mr: 10,
  mt: 20,
  fontSize: 28,
  fontWeight: 'bold',
  color: darkBlueColor,
};

const withToggleChargeInformation = withState('chargeInformation', 'toggleChargeInformation', false);

const renderCharges = (charges: Object, rateCurrency: string) => R.compose(
  R.map(({ guid, total, currency, displayedValue }: Object) =>
    <InfoPair
      key={guid}
      label={displayedValue}
      boxStyles={boxStylesWithBlueColor}
      text={`${G.getCurrencySymbol(R.or(currency, rateCurrency))} ${total}`}
    />,
  ),
  R.filter(({ total }: Object) => G.isNotNilAndNotZero(total)),
)(charges);

const Items = ({ mt, items }: Object) => (
  <Box mt={R.or(mt, 30)} color={textColor}>
    <Box mb={10} fontWeight='bold'>
      {G.getWindowLocale('titles:items', 'Items')}:
    </Box>
    {
      items.map((item: Object, index: number) => (
        <ItemInfo {...item} key={index} singleItem={false} />
      ))
    }
  </Box>
);

const Containers = ({ containers }: Object) => (
  <Box mt={30} color={textColor}>
    <Box mb={10} fontWeight='bold'>
      {G.getWindowLocale('titles:containers', 'Containers')}:
    </Box>
    {
      containers.map((container: Object, index: number) => (
        <ContainerInfo {...container} key={index} labelStart='' />
      ))
    }
  </Box>
);

const expirationDateLocaleMap = {
  [GC.PORTAL_TYPE_CUSTOMER]: ['messages:quote-expires-on', 'Quote expires on'],
  [GC.PORTAL_TYPE_CARRIER]: ['messages:please-respond-by', 'Please respond by'],
  [GC.PORTAL_TYPE_CARRIER_QUOTE]: ['messages:bid-quote-expires-on', 'Bid/Quote expires on'],
};

export const RateSection = withToggleChargeInformation((props: Object) => {
  const { width, portal, portalType, fromAcceptPage, chargeInformation, toggleChargeInformation } = props;
  const {
    items,
    origin,
    charges,
    logoUrl,
    currency,
    totalRate,
    containers,
    loadNumber,
    destination,
    quoteNumber,
    billToAddress,
    expirationDate,
    orderedServices,
    orderedEquipment,
    orderQuoteNumber,
    specialInstructions,
    sendTenderDocumentOnAccept,
  } = portal;

  let billTo = billToAddress;

  if (G.isNotNilAndNotEmpty(billTo)) billTo = G.renderFullAddressString(billTo);

  const services = R.compose(
    R.join(', '),
    R.map(R.prop(GC.FIELD_DISPLAYED_VALUE)),
  )(R.or(orderedServices, []));
  const transportationMode = R.path([GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], portal);

  return (
    <Box p='3vw' width={width} background={G.getTheme('colors.white')}>
      <Flex
        ml='auto'
        maxWidth={600}
        flexDirection='column'
        justifyContent='center'
      >
        {G.isNotNilAndNotEmpty(logoUrl) && <img height={80} alt='logo' src={logoUrl} />}
        {
          G.isNotNilAndNotEmpty(loadNumber) &&
          <Box mt={30} fontSize={20} fontWeight='bold' color={textColor}>
            {G.getWindowLocale('titles:load-number', 'Load Number')}: {loadNumber}
          </Box>
        }
        {
          G.isNotNilAndNotEmpty(orderQuoteNumber) &&
          <Box mt={30} fontSize={20} fontWeight='bold' color={textColor}>
            {G.getWindowLocale('titles:quote-number', 'Quote Number')}: {orderQuoteNumber}
          </Box>
        }
        {
          G.isNotNilAndNotEmpty(quoteNumber) &&
          <Box mt={30} fontSize={20} fontWeight='bold' color={textColor}>
            {G.getWindowLocale('titles:quote-number', 'Quote Number')}: {quoteNumber}
          </Box>
        }
        {
          G.isNotNilAndNotEmpty(transportationMode) &&
          <Box mt={15} fontSize={16} fontWeight='bold' color={textColor}>
            {G.getWindowLocale('titles:mode-of-transportation', 'Mode of Transportation')}: {transportationMode}
          </Box>
        }
        <Flex>
          <InfoPair
            boxStyles={totalRateBoxStyles}
            label={G.getWindowLocale('titles:total-rate', 'Total Rate')}
            text={G.ifElse(
              G.isNilOrEmpty(totalRate),
              null,
              `${G.getCurrencySymbol(currency)} ${totalRate}`,
            )}
          />
          {
            R.and(G.isNotNilAndNotEmpty(charges), G.isNotNilAndNotEmpty(totalRate)) &&
            <Box
              mt={20}
              cursor='pointer'
              onClick={() => toggleChargeInformation(R.not)}
              transform={G.ifElse(G.isFalse(chargeInformation), 'rotate(180deg)', undefined)}
            >
              {I.triangleUp()}
            </Box>
          }
        </Flex>
        {
          chargeInformation && renderCharges(charges, currency)
        }
        <EventInfo {...origin} label={G.getWindowLocale('titles:pickup', 'Pickup')} />
        <Box mx='auto' mt={10} width='max-content'>{I.arrowDown(textColor, 20, 20)}</Box>
        <EventInfo {...destination} label={G.getWindowLocale('titles:drop', 'Drop')} />
        <Box width='100%' mt={10}>
          <InfoPair text={billTo} label={G.getWindowLocale('titles:bill-to', 'Bill To')} />
          {G.isNotNilAndNotEmpty(items) && <Items items={items} />}
          {G.isNotNilAndNotEmpty(containers) && <Containers containers={containers} />}
          <InfoPair
            text={G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, orderedEquipment)}
            label={G.getWindowLocale('titles:ordered-equipment', 'Ordered Equipment')}
          />
          <InfoPair text={services} label={G.getWindowLocale('titles:ordered-services', 'Ordered Services')} />
          <InfoPair
            text={specialInstructions}
            label={G.getWindowLocale('titles:special-instructions', 'Special Instructions')}
          />
        </Box>
        {
          H.isPortalTypeCarrier(portalType) &&
          <StyledLink
            mt={20}
            mr='auto'
            color='white'
            p='10px 15px'
            display='flex'
            target='_blank'
            cursor='pointer'
            borderRadius='4px'
            width='max-content'
            alignSelf='flex-start'
            background={darkBlueColor}
            href='https://www.youtube.com/watch?v=xpVg5ZVhk-s'
          >
            <Box mr='4px' height={12}>{I.youtube()}</Box>
            {G.getWindowLocale('titles:watch-tutorial', 'Watch Tutorial')}
          </StyledLink>
        }
        {
          G.isNotNilAndNotEmpty(expirationDate) &&
          <Box mt={40} width='100%' color={redColor}>
            {G.getWindowLocale(...G.getPropFromObject(portalType, expirationDateLocaleMap))} {expirationDate}
            {
              H.isPortalTypeCustomer(portalType) &&
              <Box mt={20}>
                {G.getWindowLocale(
                  'messages:booking-confirmation',
                  'Booking Confirmation with tracking info will be provided once the form is submitted with all necessary information.',
                )}
              </Box>
            }
            {
              H.isPortalTypeBidQuote(portalType) &&
              <Box mt={20}>
                {G.getWindowLocale(
                  'messages:selected-quote',
                  'You will be notified if your Bid/Quote has been selected for the transport of this load.',
                )}
              </Box>
            }
          </Box>
        }
        {
          R.and(G.isTrue(sendTenderDocumentOnAccept), G.isTrue(fromAcceptPage)) &&
          <Box width='100%' mt={20} color={redColor}>
            {G.getWindowLocale(
              'messages:rate-confirmation-details',
              'All details and Rate Confirmation will be released once the acceptance form is submitted.',
            )}
          </Box>
        }
      </Flex>
    </Box>
  );
});

export const CarrierSection = (props: Object) => {
  const [disabledFields, setDisabledFields] = useState({
    [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER]: false,
    [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER]: false,
    [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME]: false,
    [GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER]: false,
  });

  const { portal, previewCarrierTermsAndConditionsDocumentRequest } = props;

  const { requiredFields, lockPopulatedFields } = portal;

  const sections = acceptDispatchFieldSections(requiredFields, disabledFields);
  const existsTermsAndConditions = G.getPropFromObject('existsTermsAndConditions', portal);

  useEffect(() => {
    const updateFieldStatus = (value: boolean, key: string) => {
      let fieldValue = portal[H.fromSnakeToCamelCase(key)];

      if (R.equals(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME, key)) {
        fieldValue = portal[H.fromSnakeToCamelCase(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_NAME)];
      }

      return G.ifElse(
        R.and(R.includes(key, lockPopulatedFields), G.isNotNilAndNotEmpty(fieldValue)),
        true,
        value,
      );
    };

    setDisabledFields(R.mapObjIndexed(updateFieldStatus, disabledFields));
  }, []);

  return (
    <Box p='3vw' width='50%' minHeight='100vh' zIndex={10}>
      <Box width={640} alignItems='flex-start'>
        <Box mt={40} fontSize={28} color={textColor}>
          {
            G.getWindowLocale('messages:fill-information-with-*', 'Please fill in all of the information with a')
          }
          <Span color={redColor}>*</Span>
        </Box>
        <form onSubmit={props.handleSubmit}>
          {
            sections.map(({ title, fields }: Object, index: number) => (
              <Box mt={40} key={index}>
                <Box mb={20} fontSize={20} color={textColor}>
                  {title}
                </Box>
                <Fieldset {...props} fields={fields} fieldsWrapperStyles={{ px: 0, width: 640 }} />
              </Box>
            ))
          }
          <ActionButton
            mt={40}
            mx='auto'
            width={200}
            height={40}
            type='submit'
            fontSize={16}
            display='block'
            borderRadius='4px'
            textColor={whiteColor}
            bgColor={lightGreenColor}
            disabled={props.isSubmitting}
          >
            {G.getWindowLocale('titles:accept-load', 'Accept Load')}
          </ActionButton>
        </form>
        {
          G.isTrue(existsTermsAndConditions) &&
          <Box mt={40} fontSize={12} textAlign='center'>
            {G.getWindowLocale('messages:accepting-dispatch-agreement', 'By accepting this dispatch, you agree to the')}
            <Span
              ml='4px'
              mt={40}
              cursor='pointer'
              textTransform='underline'
              color={G.getTheme('colors.light.blue')}
              onClick={() => previewCarrierTermsAndConditionsDocumentRequest({ token: portal.token })}
            >
              {G.getWindowLocale('titles:terms-and-conditions', 'Terms & Conditions')}
            </Span>
          </Box>
        }
        <FooterStamp linkStyles={{ mt: 40, justifyContent: 'flex-end' }} />
      </Box>
    </Box>
  );
};

const getAcceptedRateText = ({ portalType, emailDivisionName, carrierCompanyName }: Object) => {
  if (H.isPortalTypeCustomer(portalType)) {
    return `Thank you for Booking with ${emailDivisionName}! You will receive additional information shortly.`;
  }

  if (H.isPortalTypeBidQuote(portalType)) {
    return (
      <Fragment>
        Thank you for submitting your Bid/Quote with <br />
        {carrierCompanyName}! <br />
        You will be notified if your Bid/Quote has been selected for the transport of this load.
      </Fragment>
    );
  }

  return 'Load Accepted!';
};

export const AcceptedRateSection = ({
  portalType,
  emailDivisionName,
  carrierCompanyName,
  existsRateConfirmation,
  downloadRateConfirmationDocumentRequest,
}: Object) => (
  <Flex width='50%' p='3vw' alignItems='flex-end' zIndex={10}>
    <Flex width='100%' height='50%' maxWidth={640} flexDirection='column' justifyContent='space-between'>
      <Box>
        <Box mb={20} fontSize={28} textAlign='center' lineHeight='48px' color={lightGreenColor}>
          {getAcceptedRateText({ portalType, emailDivisionName, carrierCompanyName })}
        </Box>
        {
          G.isTrue(existsRateConfirmation) &&
          <ActionButton
            mt={30}
            mx='auto'
            width={300}
            height={40}
            type='button'
            fontSize={16}
            display='block'
            borderRadius='4px'
            textColor={whiteColor}
            bgColor={lightGreenColor}
            onClick={downloadRateConfirmationDocumentRequest}
          >
            {G.getWindowLocale('titles:download-rate-confirmation', 'Download Rate Confirmation')}
          </ActionButton>
        }
      </Box>
      <FooterStamp linkStyles={{ width: '100%', justifyContent: 'flex-end' }} />
    </Flex>
  </Flex>
);

const declineSectionTittleMap = {
  [GC.PORTAL_TYPE_CUSTOMER]: ['titles:decline-quote', 'Decline Quote'],
  [GC.PORTAL_TYPE_CARRIER]: ['titles:decline-dispatch', 'Decline dispatch'],
  [GC.PORTAL_TYPE_CARRIER_QUOTE]: ['titles:decline-bid-quote', 'Decline Bid/Quote'],
};

export const DeclineSection = (props: Object) => (
  <Box p='3vw' zIndex={10} width='50%'>
    <Flex
      height='100%'
      maxWidth={640}
      flexWrap='wrap'
      alignItems='flex-end'
      justifyContent='flex-end'
    >
      <DeclineDispatchForm onSubmit={props.handleSubmit}>
        <Box mx='auto' width={300}>
          <Box mb={20} fontSize={28} color={textColor} textAlign='center'>
            {G.getWindowLocale(...G.getPropFromObject(props.portalType, declineSectionTittleMap))}
          </Box>
          <Fieldset
            {...props}
            fieldsWrapperStyles={{ px: 0, width: 300 }}
            fields={G.ifElse(
              H.isPortalTypeCarrier(props.portalType),
              declineDispatchFieldSettings,
              declineOrderQuoteFieldSettings,
            )}
          />
        </Box>
        <ActionButton
          mt={30}
          mx='auto'
          width={300}
          height={40}
          type='submit'
          fontSize={16}
          display='block'
          borderRadius='4px'
          textColor={whiteColor}
          disabled={props.isSubmitting}
          bgColor={G.getTheme('colors.light.mainRed')}
        >
          {G.getWindowLocale('titles:decline', 'Decline')}
        </ActionButton>
      </DeclineDispatchForm>
      <FooterStamp linkStyles={{ justifyContent: 'flex-end' }} />
    </Flex>
  </Box>
);

export const DeclinedRateSection = ({ portalType }: Object) => (
  <Flex width='50%' p='3vw' alignItems='flex-end' zIndex={10}>
    <Flex width='100%' height='50%' maxWidth={640} flexDirection='column' justifyContent='space-between'>
      <Box mb={20} fontSize={28} textAlign='center' color={redColor}>
        {G.getWindowLocale(...G.ifElse(
          H.isPortalTypeCustomer(portalType),
          ['titles:quote-declined', 'Quote Declined'],
          ['titles:load-declined', 'Load Declined'],
        ))}
      </Box>
      <FooterStamp linkStyles={{ width: '100%', justifyContent: 'flex-end' }} />
    </Flex>
  </Flex>
);

const EventsSection = ({ form, items, events }: Object) => {
  const groupedEvents = R.groupBy(R.prop(GC.FIELD_EVENT_TYPE), events);

  return (
    <div>
      {
        R.keys(groupedEvents).map((eventType: string, index: number) => (
          <Box key={index} mt={30}>
            <Box fontSize={28} color={textColor} textAlign='center'>
              {G.toTitleCase(eventType)}/Item Information
            </Box>
            {
              R.pathOr([], [eventType], groupedEvents).map((event: Object, eventIndex: number) => {
                const { address, cloEventIndex, closItemInternalIds } = event;

                const mappedItemIds = R.map((item: string) =>
                  R.find(R.propEq(item, GC.FIELD_ITEM_INTERNAL_ID), items),
                  closItemInternalIds,
                );

                return (
                  <Box mt={20} key={eventIndex}>
                    <Box color={textColor} fontWeight='bold'>
                      {G.toTitleCase(eventType)} {R.inc(eventIndex)}
                    </Box>
                    <Fieldset
                      {...form}
                      fieldsWrapperStyles={{ px: 0, width: 640 }}
                      fields={eventFieldSettings(R.dec(cloEventIndex), G.isEventTypePickup(eventType))}
                    />
                    <InfoPair label='Location' text={G.renderFullAddressString(address)} />
                    <Items mt={10} items={mappedItemIds} />
                  </Box>
                );
              })
            }
          </Box>
        ))
      }
    </div>
  );
};

const ReferencesSection = ({ push, form, remove, cloRefTypes = [] }: Object) => {
  const references = R.pathOr([], ['values', GC.FIELD_REFERENCES], form);
  const getAllowedValuesOptions = (index: Object) => {
    const refTypeGuid = R.path([index, GC.FIELD_REFERENCE_TYPE_GUID], references);
    const allowedValues = R.pathOr([], ['allowedValues'], R.find(R.propEq(refTypeGuid, GC.FIELD_GUID), cloRefTypes));

    return R.map((value: string) => ({ value, [GC.FIELD_LABEL]: value }), allowedValues);
  };
  const getFieldSettings = (index: number) => {
    let fields = referenceFieldSettings(index, R.subtract(R.length(references), index));

    if (G.isNotNilAndNotEmpty(getAllowedValuesOptions(index))) {
      fields = R.assocPath([1, 'type'], 'reactSelect', fields);
    }

    return fields;
  };
  const refTypeOptions = R.map(({ name, guid }: Object) => ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: name,
  }), cloRefTypes);

  return (
    <Box mt={20} width={640}>
      <Flex
        cursor='pointer'
        color={textColor}
        fontWeight='bold'
        width='max-content'
        onClick={() => push({ [GC.FIELD_VALUE]: null, [GC.FIELD_REFERENCE_TYPE_GUID]: null })}
      >
        {G.getWindowLocale('titles:references', 'References')} <Box ml={10}>{I.plusRound()}</Box>
      </Flex>
      {
        references.map((_: any, index: number) => (
          <Flex key={index}>
            <Box mt={30} mr={10} cursor='pointer' onClick={() => remove(index)}>
              {I.trash()}
            </Box>
            <Fieldset
              {...form}
              refTypeOptions={refTypeOptions}
              fields={getFieldSettings(index)}
              fieldsWrapperStyles={{ px: 0, width: 640 }}
              allowedValues={getAllowedValuesOptions(index)}
            />
          </Flex>
        ))
      }
    </Box>
  );
};

export const OrderQuoteSection = (props: Object) => {
  const { portal = {}, cloRefTypes, previewCarrierTermsAndConditionsDocumentRequest } = props;
  const { items, events, existsTermsAndConditions } = portal;

  return (
    <Box p='3vw' width='50%' zIndex={10} minHeight='100vh'>
      <Box maxWidth={640}>
        <form onSubmit={props.handleSubmit}>
          <FieldArray
            name={GC.FIELD_LOAD_STOPS}
            render={(arrayHelpers: Object) => (
              <EventsSection {...arrayHelpers} items={items} events={events} />
            )}
          />
          <Box mt={30} fontSize={28} color={textColor} textAlign='center'>
            {G.getWindowLocale('titles:additional-info', 'Additional Info')}
          </Box>
          <Box mt={20}>
            <Fieldset
              {...G.getFormikPropsToFieldset(props)}
              fields={additionalIfoFieldSettings}
              fieldsWrapperStyles={{ px: 0, width: 640 }}
            />
            <FieldArray
              name={GC.FIELD_REFERENCES}
              render={(arrayHelpers: Object) => <ReferencesSection {...arrayHelpers} cloRefTypes={cloRefTypes} />}
            />
          </Box>
          <ActionButton
            mt={40}
            mx='auto'
            width={200}
            height={40}
            type='submit'
            fontSize={16}
            display='block'
            borderRadius='4px'
            textColor={whiteColor}
            bgColor={lightGreenColor}
            disabled={props.isSubmitting}
          >
            {G.getWindowLocale('titles:accept-quote', 'Accept Quote')}
          </ActionButton>
          {
            G.isTrue(existsTermsAndConditions) &&
            <Box mt={40} fontSize={12} textAlign='center'>
              {G.getWindowLocale('titles:by-accepting-quote', 'By accepting this quote, you agree to the')}
              <Span
                mt={40}
                ml='4px'
                cursor='pointer'
                textTransform='underline'
                color={G.getTheme('colors.light.blue')}
                onClick={() => previewCarrierTermsAndConditionsDocumentRequest({
                  portalType: 'customer',
                  branchGuid: G.getBranchGuidFromObject(portal),
                })}
              >
                {G.getWindowLocale('titles:terms-and-conditions', 'Terms & Conditions')}
              </Span>
            </Box>
          }
          <FooterStamp linkStyles={{ mt: 40, justifyContent: 'flex-end' }} />
        </form>
      </Box>
    </Box>
  );
};

const AdditionalChargesSection = ({ form, push, remove, additionalChargeAssessorials }: Object) => {
  const additionalCharges = R.compose(
    R.filter(R.propEq(GC.CHARGE_TYPE_ADDITIONAL, GC.FIELD_TYPE)),
    R.pathOr([], ['values', GC.FIELD_CHARGES]),
  )(form);
  const accessorialOptions = R.compose(
    R.map(({ originGuid, displayedValue }: Object) => ({
      [GC.FIELD_VALUE]: originGuid,
      [GC.FIELD_LABEL]: displayedValue,
    })),
    R.uniqBy(R.prop(GC.FIELD_DISPLAYED_VALUE)),
  )(R.or(additionalChargeAssessorials, []));
  const handleAddAdditionalCharge = () => push({
    [GC.FIELD_TOTAL]: null,
    [GC.FIELD_TYPE]: GC.CHARGE_TYPE_ADDITIONAL,
    [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
    [GC.FIELD_ACCESSORIAL_CONFIG_GUID]: R.path([0, GC.FIELD_VALUE], accessorialOptions),
  });

  return (
    <Box>
      <Fieldset
        {...form}
        fields={chargesFieldSettings}
        fieldsWrapperStyles={{ px: 0, width: 640, justifyContent: 'center' }}
      />
      <Flex
        mt={20}
        mx='auto'
        cursor='pointer'
        color={textColor}
        fontWeight='bold'
        width='max-content'
        onClick={handleAddAdditionalCharge}
      >
        {G.getWindowLocale('actions:additional-charges', 'Additional Charges')} <Box ml={10}>{I.plusRound()}</Box>
      </Flex>
      {
        additionalCharges.map((_: any, index: number) => (
          <Flex key={index}>
            <Box mt={30} mr={10} cursor='pointer' onClick={() => remove(R.add(index, 2))}>
              {I.trash()}
            </Box>
            <Fieldset
              {...form}
              accessorialOptions={accessorialOptions}
              fieldsWrapperStyles={{ px: 0, width: 640 }}
              fields={additionalChargesFieldSettings(index)}
            />
          </Flex>
        ))
      }
    </Box>
  );
};

export const BidQuoteSection = (props: Object) => {
  const { values, portal, handleSubmit } = props;

  const total = R.compose(
    R.sum,
    R.prepend(G.NaNToZero(G.getPropFromObject(GC.CHARGE_TYPE_MAIN, values))),
    R.map(({ type, total }: Object) => {
      const value = G.toNumber(total);

      if (G.isNaN(value)) return 0;

      if (R.equals(type, GC.CHARGE_TYPE_DISCOUNT)) return R.negate(value);

      return value;
    }),
    R.pathOr([], [GC.FIELD_CHARGES]),
  )(values);

  return (
    <Box p='3vw' width='50%' zIndex={10} minHeight='100vh'>
      <Box maxWidth={640}>
        <Box mt={40} fontSize={28} color={textColor} textAlign='center'>
          {G.getWindowLocale('titles:bid-quote-entry', 'Bid/Quote Entry')}
        </Box>
        <form onSubmit={handleSubmit}>
          <Fieldset
            {...G.getFormikPropsToFieldset(props)}
            fields={currencyFieldSettings}
            fieldsWrapperStyles={{ px: 0, width: 640, justifyContent: 'center' }}
          />
          <FieldArray
            {...G.getFormikPropsToFieldset(props)}
            name={GC.FIELD_CHARGES}
            fieldsWrapperStyles={{ px: 0, width: 640 }}
            render={(arrayHelpers: Object) => (
              <AdditionalChargesSection
                {...arrayHelpers}
                additionalChargeAssessorials={portal.additionalChargeAssessorials}
              />
            )}
          />
          <Fieldset
            {...G.getFormikPropsToFieldset(props)}
            fields={commentsFieldSettings}
            fieldsWrapperStyles={{ px: 0, width: 640, justifyContent: 'center' }}
          />
          <InfoPair
            boxStyles={{ ...totalRateBoxStyles, mr: 0, textAlign: 'center' }}
            label={G.getWindowLocale('titles:total-bid-quote', 'Total Bid/Quote')}
            text={`${G.getCurrencySymbol(G.getCurrencyFromObject(values))} ${G.mathRoundNumber(total)}`}
          />
          <ActionButton
            mt={40}
            mx='auto'
            width={200}
            height={40}
            type='submit'
            fontSize={16}
            display='block'
            borderRadius='4px'
            textColor={whiteColor}
            bgColor={lightGreenColor}
          >
            {G.getWindowLocale('titles:submit-bid-quote', 'Submit Bid/Quote')}
          </ActionButton>
        </form>
      </Box>
    </Box>
  );
};
