import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { getSageAccountMappingScopeOptions, getQBAccountMappingScopeOptions } from '../../../../helpers/options';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '190px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
};

const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

export const qbAccountMappingAccountTypeKeysMap = {
  [GC.INVOICE_SCOPE_TYPE_DRIVER]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_TOLL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_FUEL_CARD,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ADVANCED_PAYMENT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_EXPENSE_DISTRIBUTION_LINE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_EXPENSE_DISTRIBUTION_LINE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE,
  ],
  [GC.INVOICE_SCOPE_TYPE_CARRIER]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_EXPENSE_DISTRIBUTION_LINE,
  ],
  [GC.INVOICE_SCOPE_TYPE_CUSTOMER]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_RECEIVABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_INCOME_DISTRIBUTION_LINE,
  ],
  [GC.INVOICE_SCOPE_TYPE_VENDOR]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_EXPENSE_DISTRIBUTION_LINE,
  ],
  [GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_SERVICE_VENDOR_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_SERVICE_VENDOR_EXPENSE_DISTRIBUTION_LINE,
  ],
};

const qbAccountTypeInfoMap = {
  // TODO: uncomment when info will be done
  // [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_TOLL]: 'titles:toll-charge',
  // [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT]: 'titles:discount',
  // [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL]: 'titles:line-haul',
  // [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_FUEL_CARD]: 'titles:fuel-card',
  // [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS]: 'titles:accessorial',
  // [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE]: 'titles:invoice-charge',
  // [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ADVANCED_PAYMENT]: 'titles:advance-payment',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_PAYABLE]: 'messages:qb-driver-payable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_PAYABLE]: 'messages:qb-vendor-payable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_PAYABLE]: 'messages:qb-carrier-payable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_PAYABLE]: 'messages:qb-contractor-payable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_RECEIVABLE]: 'messages:qb-customer-receivable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_PAYABLE]: 'messages:qb-owner-operator-payable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_EXPENSE_DISTRIBUTION_LINE]:
    'messages:qb-vendor-expense-distribution-line-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_EXPENSE_DISTRIBUTION_LINE]:
    'messages:qb-driver-expense-distribution-line-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_INCOME_DISTRIBUTION_LINE]:
    'messages:qb-customer-income-distribution-line-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_EXPENSE_DISTRIBUTION_LINE]:
    'messages:qb-carrier-expense-distribution-line-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_EXPENSE_DISTRIBUTION_LINE]:
    'messages:qb-contractor-expense-distribution-line-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE]:
    'messages:qb-owner-operator-expense-distribution-line-account-name',
};

const sageAccountTypeInfoMap = {
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_PAYABLE]: 'messages:sage-vendor-payable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_PAYABLE]: 'messages:sage-carrier-payable-account-name',
  [GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_RECEIVABLE]: 'messages:sage-customer-receivable-account-name',
};

export const qbDistributionLineAccountMappingFieldSettings = (accountType: string) => [
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:scope',
    shouldCustomChange: true,
    fieldName: GC.FIELD_SCOPE,
    options: getQBAccountMappingScopeOptions(),
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:account-type',
    options: 'accountTypeOptions',
    fieldName: GC.FIELD_ACCOUNT_TYPE,
    info: R.pathOr(null, [accountType], qbAccountTypeInfoMap),
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'qbAccountOptions',
    loc: 'titles:quick-books-account',
    fieldName: GC.FIELD_ACCOUNT_NAME_GUID,
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'accessorialOptions',
    loc: 'titles:accessorial-config',
    fieldName: GC.FIELD_ACCESSORIAL_CONFIG_GUID,
    fieldGroupDisplay: G.ifElse(
      R.equals(accountType, GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS),
      'flex',
      'none',
    ),
  },
];

export const defaultQBDistributionLineAccountMappingFields = {
  [GC.FIELD_ACCOUNT_TYPE]: '',
  [GC.FIELD_ACCOUNT_NAME_GUID]: '',
  [GC.FIELD_ACCESSORIAL_CONFIG_GUID]: '',
  [GC.FIELD_SCOPE]: GC.INVOICE_SCOPE_TYPE_DRIVER,
};

export const qbDistributionLineAccountMappingValidationSchema = Yup.lazy(({ accountType }: Object) => {
  let schema = {
    [GC.FIELD_ACCOUNT_TYPE]: fieldRequired,
    [GC.FIELD_ACCOUNT_NAME_GUID]: fieldRequired,
  };
  if (R.equals(accountType, GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS)) {
    schema = R.assoc(GC.FIELD_ACCESSORIAL_CONFIG_GUID, fieldRequired, schema);
  }

  return Yup.object().shape(schema);
});

// sage account mapping
export const sageAccountMappingAccountTypeKeysMap = {
  [GC.INVOICE_SCOPE_TYPE_CUSTOMER]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_RECEIVABLE,
  ],
  [GC.INVOICE_SCOPE_TYPE_CARRIER]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_PAYABLE,
  ],
  [GC.INVOICE_SCOPE_TYPE_VENDOR]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
  ],
  [GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR]: [
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_SERVICE_VENDOR_PAYABLE,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
    GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
  ],
};

export const sageAccountMappingFieldSettings = (accountType: string) => [
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:scope',
    shouldCustomChange: true,
    fieldName: GC.FIELD_SCOPE,
    options: getSageAccountMappingScopeOptions(),
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:account-type',
    options: 'accountTypeOptions',
    fieldName: GC.FIELD_ACCOUNT_TYPE,
    info: R.pathOr(null, [accountType], sageAccountTypeInfoMap),
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'accountOptions',
    loc: 'titles:sage-intacct-account',
    fieldName: GC.FIELD_ACCOUNT_NAME_GUID,
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'accessorialOptions',
    loc: 'titles:accessorial-config',
    fieldName: GC.FIELD_ACCESSORIAL_CONFIG_GUID,
    fieldGroupDisplay: G.ifElse(
      R.equals(accountType, GC.INVOICE_MAPPING_TYPE_ASSESSORIALS),
      'flex',
      'none',
    ),
  },
];

// customer id mapping
export const customerIdMappingFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:customer',
    options: 'customerBranchList',
    fieldName: GC.FIELD_CUSTOMER_GUID,
  },
  {
    ...defaultStyles,
    type: 'text',
    width: '400px',
    loc: 'titles:customer-id',
    fieldName: GC.FIELD_CUSTOMER_ID,
  },
];

export const defaultCustomerIdMappingFields = {
  [GC.FIELD_CUSTOMER_ID]: '',
  [GC.FIELD_CUSTOMER_GUID]: '',
};

export const customerIdMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_CUSTOMER_GUID]: fieldRequired,
  [GC.FIELD_CUSTOMER_ID]: Yup.string()
    .nullable(true)
    .min(1, G.getShouldBeFromToLocaleTxt(1, 20))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 20))
    .required(G.getRequiredLocaleTxt()),
});

// department id mapping
export const departmentIdMappingFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'transportationModes',
    loc: 'titles:transportation-mode',
    fieldName: GC.FIELD_TRANSPORTATION_MODE_GUID,
  },
  {
    ...defaultStyles,
    type: 'text',
    width: '400px',
    loc: 'titles:department-id',
    fieldName: GC.FIELD_DEPARTMENT_ID,
  },
];

export const defaultDepartmentIdMappingFields = {
  [GC.FIELD_DEPARTMENT_ID]: '',
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: '',
};

export const departmentIdMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_TRANSPORTATION_MODE_GUID]: fieldRequired,
  [GC.FIELD_DEPARTMENT_ID]: Yup.string()
    .nullable(true)
    .min(1, G.getShouldBeFromToLocaleTxt(1, 20))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 20))
    .required(G.getRequiredLocaleTxt()),
});

// gl code mapping
export const glCodeMappingFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:scope',
    shouldCustomChange: true,
    fieldName: GC.FIELD_SCOPE,
    options: getQBAccountMappingScopeOptions(),
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'glCodeTypeOptions',
    fieldName: GC.FIELD_GL_CODE_TYPE,
    loc: 'titles:invoice-gl-code-type',
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:order-type',
    options: 'orderTypeOptions',
    fieldName: GC.FIELD_ORDER_TYPE_GUID,
    fieldGroupDisplay: (_: any, { scope, glCodeType }: Object) => G.ifElse(
      R.and(
        R.equals(scope, GC.INVOICE_SCOPE_TYPE_CUSTOMER),
        R.equals(glCodeType, GC.INVOICE_MAPPING_TYPE_INVOICE),
      ),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'glCodeOptions',
    loc: 'titles:invoice-gl-code',
    fieldName: GC.FIELD_GL_CODE_GUID,
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    options: 'accessorialOptions',
    loc: 'titles:accessorial-config',
    fieldName: GC.FIELD_ACCESSORIAL_CONFIG_GUID,
    fieldGroupDisplay: (_: any, { glCodeType }: Object) => G.ifElse(
      R.equals(glCodeType, GC.INVOICE_MAPPING_TYPE_ASSESSORIALS),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'select',
    width: '400px',
    loc: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
  },
];

export const defaultGLCodeMappingFields = {
  [GC.FIELD_GL_CODE_TYPE]: '',
  [GC.FIELD_GL_CODE_GUID]: '',
  [GC.FIELD_ORDER_TYPE_GUID]: '',
  [GC.FIELD_ACCESSORIAL_CONFIG_GUID]: '',
  [GC.FIELD_SCOPE]: GC.INVOICE_SCOPE_TYPE_DRIVER,
};

export const glCodeMappingValidationSchema = Yup.lazy(({ accountType }: Object) => {
  let schema = {
    [GC.FIELD_GL_CODE_TYPE]: fieldRequired,
    [GC.FIELD_GL_CODE_GUID]: fieldRequired,
  };
  if (R.equals(accountType, GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS)) {
    schema = R.assoc(GC.FIELD_ACCESSORIAL_CONFIG_GUID, fieldRequired, schema);
  }

  return Yup.object().shape(schema);
});

// invoice status rule
export const invoiceStatusRuleFieldSettings = [
  [
    {
      ...defaultStyles,
      type: 'select',
      loc: 'titles:priority',
      options: 'priorityOptions',
      fieldName: GC.FIELD_PRIORITY,
    },
    {
      ...defaultStyles,
      type: 'select',
      loc: 'titles:scope',
      shouldCustomChange: true,
      fieldName: GC.FIELD_SCOPE,
      options: getQBAccountMappingScopeOptions(),
      customChangeHandler: (event: Object, field: any, props: Object) => {
        const { values, setValues } = props;

        const newValues = R.mergeRight(
          values,
          {
            [GC.FIELD_INVOICE_STATUS_GUID]: '',
            [GC.FIELD_SCOPE]: R.path(['currentTarget', GC.FIELD_VALUE], event),
          },
        );

        setValues(newValues);
      },
    },
    {
      ...defaultStyles,
      type: 'select',
      loc: 'titles:invoice-status',
      options: 'invoiceStatusOptions',
      fieldName: GC.FIELD_INVOICE_STATUS_GUID,
    },
  ],
  [
    {
      ...defaultStyles,
      type: 'multiselect',
      loc: 'titles:conditions',
      shouldCustomChange: true,
      fieldName: GC.FIELD_CONDITIONS,
      options: [
        {
          value: GC.INVOICE_STATUS_RULE_CONDITION_TYPE_RATE_TOTAL_MISMATCH,
          label: G.getEnumLocale(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_RATE_TOTAL_MISMATCH),
        },
        {
          value: GC.INVOICE_STATUS_RULE_CONDITION_TYPE_ADDITIONAL_INVOICE,
          label: G.getEnumLocale(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_ADDITIONAL_INVOICE),
        },
        {
          value: GC.INVOICE_STATUS_RULE_CONDITION_TYPE_LOAD_STATUS,
          label: G.getEnumLocale(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_LOAD_STATUS),
        },
        {
          value: GC.INVOICE_STATUS_RULE_CONDITION_TYPE_TRANSPORTATION_MODE,
          label: G.getEnumLocale(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_TRANSPORTATION_MODE),
        },
      ],
    },
    {
      ...defaultStyles,
      type: 'select',
      loc: 'titles:rate-mismatch-type',
      fieldName: GC.FIELD_RATE_MISMATCH_TYPE,
      fieldGroupDisplay: (_: any, { conditions }: Object) => G.ifElse(
        R.includes(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_RATE_TOTAL_MISMATCH, R.or(conditions, [])),
        'flex',
        'none',
      ),
      options: [
        GC.EMPTY_OPTION_OBJECT,
        {
          value: GC.INVOICE_STATUS_RULE_RATE_MISMATCH_TYPE_FLAT,
          label: G.getEnumLocale(GC.INVOICE_STATUS_RULE_RATE_MISMATCH_TYPE_FLAT),
        },
        {
          value: GC.INVOICE_STATUS_RULE_RATE_MISMATCH_TYPE_PERCENT,
          label: G.getEnumLocale(GC.INVOICE_STATUS_RULE_RATE_MISMATCH_TYPE_PERCENT),
        },
      ],
    },
    {
      ...defaultStyles,
      type: 'number',
      loc: 'titles:rate-mismatch-value',
      fieldName: GC.FIELD_RATE_MISMATCH_VALUE,
      fieldGroupDisplay: (_: any, { conditions }: Object) => G.ifElse(
        R.includes(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_RATE_TOTAL_MISMATCH, R.or(conditions, [])),
        'flex',
        'none',
      ),
    },
    {
      ...defaultStyles,
      type: 'multiselect',
      loc: 'titles:clo-status',
      fieldName: GC.FIELD_CLO_STATUSES,
      options: G.getStatusOptions(GC.LOAD_TYPE_CLO, false),
      fieldGroupDisplay: (_: any, { conditions }: Object) => G.ifElse(
        R.includes(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_LOAD_STATUS, R.or(conditions, [])),
        'flex',
        'none',
      ),
    },
    {
      ...defaultStyles,
      type: 'multiselect',
      loc: 'titles:tel-status',
      fieldName: GC.FIELD_TEL_STATUSES,
      options: G.getStatusOptions(GC.LOAD_TYPE_TEL, false),
      fieldGroupDisplay: (_: any, { conditions }: Object) => G.ifElse(
        R.includes(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_LOAD_STATUS, R.or(conditions, [])),
        'flex',
        'none',
      ),
    },
    {
      ...defaultStyles,
      type: 'multiselect',
      options: 'transportationModes',
      loc: 'titles:transportation-modes',
      fieldName: GC.FIELD_TRANSPORTATION_MODE_GUIDS,
      fieldGroupDisplay: (_: any, { conditions }: Object) => G.ifElse(
        R.includes(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_TRANSPORTATION_MODE, R.or(conditions, [])),
        'flex',
        'none',
      ),
    },
  ],
];

export const defaultInvoiceStatusRuleFields = {
  [GC.FIELD_PRIORITY]: null,
  [GC.FIELD_CONDITIONS]: null,
  [GC.FIELD_CLO_STATUSES]: null,
  [GC.FIELD_TEL_STATUSES]: null,
  [GC.FIELD_RATE_MISMATCH_VALUE]: 0,
  [GC.FIELD_RATE_MISMATCH_TYPE]: null,
  [GC.FIELD_INVOICE_STATUS_GUID]: null,
  [GC.FIELD_SCOPE]: GC.INVOICE_SCOPE_TYPE_DRIVER,
};

export const invoiceStatusRuleValidationSchema = Yup.lazy(({ conditions, telStatuses, cloStatuses }: Object) => {
  let schema = {
    [GC.FIELD_PRIORITY]: fieldRequired,
    [GC.FIELD_CONDITIONS]: G.yupArrayRequired,
    [GC.FIELD_INVOICE_STATUS_GUID]: fieldRequired,
  };
  if (R.includes(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_RATE_TOTAL_MISMATCH, R.or(conditions, []))) {
    const rateMismatchValidationSchema = {
      [GC.FIELD_RATE_MISMATCH_TYPE]: fieldRequired,
      [GC.FIELD_RATE_MISMATCH_VALUE]: Yup.number()
        .required(G.getRequiredLocaleTxt())
        .positive(G.getShouldBePositiveLocaleTxt()),
    };

    schema = R.mergeRight(schema, rateMismatchValidationSchema);
  }
  if (R.includes(GC.INVOICE_STATUS_RULE_CONDITION_TYPE_LOAD_STATUS, R.or(conditions, []))) {
    const statusesValidation = G.ifElse(
      G.isAnyNilOrEmpty([telStatuses, cloStatuses]),
      G.yupArrayRequired,
      G.yupArrayNotRequired,
    );
    const loadStatusesValidationSchema = {
      [GC.FIELD_TEL_STATUSES]: statusesValidation,
      [GC.FIELD_CLO_STATUSES]: statusesValidation,
    };

    schema = R.mergeRight(schema, loadStatusesValidationSchema);
  }

  return Yup.object().shape(schema);
});

const invoiceIntegrationOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: GC.INVOICE_INTEGRATION_STATUS_SENT,
    label: G.getWindowLocale('titles:sent', 'Sent'),
  },
  {
    value: GC.INVOICE_INTEGRATION_STATUS_EXPORTED,
    label: G.getWindowLocale('titles:exported', 'Exported'),
  },
  {
    value: GC.INVOICE_INTEGRATION_STATUS_EXPORT_FAILED,
    label: G.getWindowLocale('titles:export-failed', 'Export Failed'),
  },
  {
    value: GC.INVOICE_INTEGRATION_STATUS_EXTRACTED,
    label: G.getWindowLocale('titles:extracted', 'Extracted'),
  },
];

export const defaultStatusFields = {
  [GC.FIELD_STORED_VALUE]: '',
  [GC.FIELD_SYSTEM_STATUS]: '',
  [GC.FIELD_DISPLAYED_VALUE]: '',
  [GC.FIELD_INTEGRATION_STATUS]: '',
};

const statusFieldValidation = G.yupStringRequired
  .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
  .max(40, G.getShouldBeFromToLocaleTxt(1, 40));

export const statusValidationSchema = Yup.object().shape({
  [GC.FIELD_STORED_VALUE]: statusFieldValidation,
  [GC.FIELD_DISPLAYED_VALUE]: statusFieldValidation,
});

const statusInputWrapperStyles = {
  mb: 25,
  width: 270,
};

const completedOption = {
  value: GC.INVOICE_SYSTEM_STATUS_COMPLETED,
  label: G.getWindowLocale('titles:completed', 'Completed'),
};

const readyForBillingOption = {
  value: GC.INVOICE_SYSTEM_STATUS_READY_FOR_BILLING,
  label: G.getWindowLocale('titles:ready-for-billing', 'Ready For Billing'),
};

const rejectedOption = {
  value: GC.INVOICE_SYSTEM_STATUS_REJECTED,
  label: G.getWindowLocale('titles:rejected', 'Rejected'),
};

const approvedOption = {
  value: GC.INVOICE_SYSTEM_STATUS_APPROVED,
  label: G.getWindowLocale('titles:approved', 'Approved'),
};

const driverPayrollSystemStatusOptions = [completedOption];
const carrierInvoiceSystemStatusOptions = [approvedOption, rejectedOption, completedOption];

const optionsMap = {
  [GC.INVOICE_DP_STATUS]: driverPayrollSystemStatusOptions,
  [GC.INVOICE_CARRIER_STATUS]: carrierInvoiceSystemStatusOptions,
  [GC.INVOICE_CI_STATUS]: R.append(readyForBillingOption, driverPayrollSystemStatusOptions),
  [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: R.append(readyForBillingOption, carrierInvoiceSystemStatusOptions),
};

const customerInvoiceIntegrationOptions = R.insert(
  4,
  {
    value: GC.INVOICE_INTEGRATION_STATUS_EXPORTED_TO_FACTORING,
    label: G.getWindowLocale('titles:export-to-factoring', 'Exported to Factoring'),
  },
  invoiceIntegrationOptions,
);

export const getStatusFields = (configName: string) => [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STORED_VALUE,
    inputWrapperStyles: statusInputWrapperStyles,
    label: ['titles:stored-value', 'Stored Value'],
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_DISPLAYED_VALUE,
    inputWrapperStyles: statusInputWrapperStyles,
    label: ['titles:user-displayed-value', 'User Displayed Value'],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_INTEGRATION_STATUS,
    inputWrapperStyles: statusInputWrapperStyles,
    label: ['titles:integration-status', 'Integration Status'],
    options: G.ifElse(
      R.equals(configName, GC.INVOICE_CI_STATUS),
      customerInvoiceIntegrationOptions,
      invoiceIntegrationOptions,
    ),
  },
  {
    type: 'select',
    fieldName: GC.FIELD_SYSTEM_STATUS,
    inputWrapperStyles: statusInputWrapperStyles,
    label: ['titles:system-status', 'System Status'],
    options: G.addEmptyOptionToDropDown(G.getPropFromObject(configName, optionsMap)),
  },
];

const getStatusLocale = (fieldValue: string) => G.ifElse(
  G.isNotNilAndNotEmpty(fieldValue),
  G.getWindowLocale(G.getPropFromObject(fieldValue, GC.statusLocaleMap), fieldValue),
  '',
);

export const invoiceStatusesFields = [
  {
    name: 'titles:stored-value',
    nameForAttribute: GC.FIELD_STORED_VALUE,
  },
  {
    name: 'titles:user-displayed-value',
    nameForAttribute: GC.FIELD_DISPLAYED_VALUE,
  },
  {
    name: 'titles:integration-status',
    nameForAttribute: GC.FIELD_INTEGRATION_STATUS,
    customLogic: (field: Object, { integrationStatus }: Object) => getStatusLocale(integrationStatus),
  },
  {
    name: 'titles:system-status',
    nameForAttribute: GC.FIELD_SYSTEM_STATUS,
    customLogic: (field: Object, { systemStatus }: Object) => getStatusLocale(systemStatus),
  },
];

export const expenseTypeValidationSchema = Yup.object().shape({
  [GC.FIELD_NAME]: fieldRequired,
  [GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE]: Yup.string()
    .nullable(true)
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40))
    .required(G.getRequiredLocaleTxt()),
});

export const defaultExpenseTypeFields = {
  [GC.FIELD_NAME]: '',
  [GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE]: '',
};

export const getExpenseTypeFields = (isEdit: boolean = false) => [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_NAME,
    inputWrapperStyles: statusInputWrapperStyles,
    label: ['titles:name', 'Name'],
  },
  {
    type: 'select',
    disabled: isEdit,
    isRequired: true,
    inputWrapperStyles: statusInputWrapperStyles,
    fieldName: GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE,
    label: ['titles:maintenance-type', 'Maintenance Type'],
    options: [
      { label: '', value: '' },
      { label: G.getWindowLocale('titles:general', 'General'), value: GC.CONFIGURATION_MAINTENANCE_TYPE_GENERAL },
      { label: G.getWindowLocale('titles:truck', 'Truck'), value: GC.CONFIGURATION_MAINTENANCE_TYPE_TRUCK },
      { label: G.getWindowLocale('titles:trailer', 'Trailer'), value: GC.CONFIGURATION_MAINTENANCE_TYPE_TRAILER },
    ],
  },
];
