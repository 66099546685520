import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// feature configs
import {
  customerIdMappingFieldSettings,
  defaultCustomerIdMappingFields,
  customerIdMappingValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getOptions = ({ customerBranchList, customerIdMappingList }: Object) => R.compose(
  G.addEmptyOptionToDropDown,
  R.map(
    (branch: Object) => R.assoc(
      'disabled',
      G.isNotNil(R.find(R.propEq(G.getGuidFromObject(branch), GC.FIELD_CUSTOMER_GUID), customerIdMappingList)),
      branch,
    ),
  ),
)(customerBranchList);

const enhance = compose(
  withFormik({
    validationSchema: customerIdMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultCustomerIdMappingFields,
      initialValues,
    ),
  }),
  pure,
);

const CustomerIdMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={customerIdMappingFieldSettings}
      optionsForSelect={{ customerBranchList: getOptions(props) }}
    />
    <FormFooter boxStyles={{ pt: 10, px: '5px' }} closeModal={props.closeModal} />
  </form>
);

export default enhance(CustomerIdMappingForm);
