import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
    customComponent: R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_INTEGRATION_TYPE]),
    ),
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    name: 'titles:status',
  },
  [GC.FIELD_EXTERNAL_DIVISION_NAME]: {
    width: 250,
    name: 'titles:division',
  },
  [GC.FIELD_EXPORTED_BY]: {
    width: 200,
    name: 'titles:exported-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 250,
    type: 'date',
    name: 'titles:update-on',
  },
  [GC.FIELD_ERROR]: {
    width: 250,
    name: 'titles:error',
  },
  [GC.FIELD_INTEGRATION_ID]: {
    width: 200,
    name: 'titles:integration-id',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 250,
    type: 'date',
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 250,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 250,
    name: 'titles:updated-by',
  },
};

const makeReportFields = G.mapIndexed((name: string, sequence: number) => ({ name, sequence }));

const integrationInfoReport = {
  fields: makeReportFields([
    GC.FIELD_INTEGRATION_TYPE,
    GC.FIELD_INTEGRATION_ID,
    GC.FIELD_EXTERNAL_DIVISION_NAME,
    GC.FIELD_CREATED_DATE,
    GC.FIELD_CREATED_BY,
    GC.FIELD_LAST_MODIFIED_DATE,
    GC.FIELD_LAST_MODIFIED_BY,
  ]),
};

const integrationAuditReport = {
  fields: makeReportFields([
    GC.FIELD_INTEGRATION_TYPE,
    GC.FIELD_STATUS,
    GC.FIELD_EXTERNAL_DIVISION_NAME,
    GC.FIELD_EXPORTED_BY,
    GC.FIELD_LAST_MODIFIED_DATE,
    GC.FIELD_ERROR,
  ]),
};

export const integrationInfoSettings = {
  singleItem: '',
  actionsPicker: [],
  columnSettings: settings,
  groupName: 'integrationInfo',
  report: integrationInfoReport,
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  formGroupTitleArr: ['titles:integration-info', 'Integration Info'],
  endpoints: {
    list: 'fleetVendorIntegrationInfoList',
  },
};

export const integrationAuditSettings = {
  singleItem: '',
  actionsPicker: [],
  columnSettings: settings,
  groupName: 'integrationAudit',
  report: integrationAuditReport,
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  formGroupTitleArr: ['titles:integration-audit', 'Integration Audit'],
  endpoints: {
    list: 'fleetVendorIntegrationAuditList',
  },
};
