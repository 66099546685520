import React from 'react';
import { connect } from 'react-redux';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex } from '../../../../ui';
// feature dispatch-details-new
import { downloadLoadDocumentRequest } from '../actions';
//////////////////////////////////////////////////

const DocumentFileTableCell = ({ document, downloadLoadDocumentRequest }: Object) => {
  const fileName = G.getPropFromObject(GC.FIELD_DOCUMENT_FILE_NAME, document);
  if (G.isNilOrEmpty(fileName)) return null;
  const blueColor = G.getTheme('colors.light.blue');

  return (
    <Flex
      cursor='pointer'
      color={blueColor}
      wordBreak='break-all'
      onClick={() => downloadLoadDocumentRequest(document)}
    >
      <Box mr={10} height={20}>
        {G.getDocumentIconByDocumentFileName(fileName)}
      </Box>
      <Box>{fileName}</Box>
    </Flex>
  );
};

export default connect(null, { downloadLoadDocumentRequest })(DocumentFileTableCell);
