import React from 'react';
import * as R from 'ramda';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
// features
import References from '../../reference/components/reference';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const endpoints = {
  driver: {
    list: 'driverReferenceList',
    createOrUpdate: 'driverReference',
    remove: 'getCurrentReferenceEndpoint',
  },
  vendor: {
    list: 'vendorReferenceList',
    remove: 'removeVendorReference',
    createOrUpdate: 'vendorReference',
  },
  truck: {
    list: 'truckReferenceList',
    createOrUpdate: 'truckReference',
    remove: 'getCurrentTruckRefEndpoint',
  },
  trailer: {
    list: 'trailerReferenceList',
    createOrUpdate: 'trailerReference',
    remove: 'getCurrentTrailerRefEndpoint',
  },
};

const enhance = compose(
  withHandlers({
    handleGetRefListRequest: (props: Object) => async () => {
      const {
        fleetType,
        openLoader,
        closeLoader,
        primaryObjectGuid,
        getItemListSuccess,
        primaryObjectGuidKey = GC.FIELD_DRIVER_GUID,
    } = props;

      const options = {
        params: { [primaryObjectGuidKey]: primaryObjectGuid },
      };

      openLoader();

      const endpointName = R.path([fleetType, 'list'], endpoints);

      const res = await sendRequest('get', R.prop(endpointName, endpointsMap), options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        getItemListSuccess({ data, groupName: 'references' });
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
    handleCreateOrUpdateReference: (props: Object) => (values: Object) => {
      const { fleetType, primaryObjectGuid, createOrUpdateEntityRequest } = props;

      const endpointName = R.path([fleetType, 'createOrUpdate'], endpoints);

      createOrUpdateEntityRequest({
        groupName: 'references',
        endpoint: R.prop(endpointName, endpointsMap),
        method: G.ifElse(R.has(GC.FIELD_VERSION, values), 'put', 'post'),
        values: R.assoc('primaryObjectGuids', R.of(Array, primaryObjectGuid), values),
      });
    },
    handleRemoveReference: (props: Object) => (guid: string) => {
      const { openModal, fleetType, removeEntityRequest } = props;

      const component = (
        <ConfirmComponent
          name={G.getWindowLocale('titles:reference', 'Reference')}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const endpointName = R.path([fleetType, 'remove'], endpoints);
      const endpoint = R.prop(endpointName, endpointsMap)(guid);

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:remove', 'Remove'),
              action: () => removeEntityRequest({ guid, endpoint, groupName: 'references' }),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { itemList, handleGetRefListRequest } = this.props;

      if (R.isNil(itemList)) handleGetRefListRequest();
    },
  }),
  pure,
);

const ReferenceFormGroupTable = ({
  itemList,
  openModal,
  fleetType,
  closeModal,
  handleRemoveReference,
  handleCreateOrUpdateReference,
}: Object) => (
  <References
    openModal={openModal}
    references={itemList}
    closeModal={closeModal}
    scope={`fleet-${fleetType}`}
    handleRemoveReference={handleRemoveReference}
    handleCreateReference={handleCreateOrUpdateReference}
    handleUpdateReference={handleCreateOrUpdateReference}
  />
);

export default enhance(ReferenceFormGroupTable);
