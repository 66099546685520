import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
///////////////////////////////////////////////////////////////

export const FieldContainer = styled.div`
  left: 320px;
  width: 100%;
  display: flex;
  overflow: auto;
  overflow-x: auto;
  min-width: 320px;
  position: relative;
  flex-flow: row nowrap;
  max-height: max-content;
  height: calc(100vh - 150px);
  transition: width .2s linear;
  max-width: calc(100vw - 375px);
  min-height: calc(100vh - 150px);
  box-shadow: rgba(175, 175, 175, 0.5);
  & * {
    z-index: unset;
  }
`;

// REFACTOR: use common input
export const FieldBox = styled.input`
  width: 232px;
  height: 35px;
  display: flex;
  border: 1px solid;
  padding-left: 10px;
  border-radius: 4px;
  align-items: center;
  margin-right: ${({ mr }: Object) => mr};
  border-color: ${() => G.getTheme('colors.dark.grey')};
`;
