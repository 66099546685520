import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
///////////////////////////////////////////////////////////////////////////////////////////////////

const defaultFieldStyles = {
  width: 280,
  errorTop: '110%',
  afterTop: '14px',
  errorTitle: true,
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorWhiteSpace: 'nowrap',
  errorPosition: 'absolute',
  errorTextOverflow: 'ellipsis',
};

const fieldSettings = [
  {
    ...defaultFieldStyles,
    width: 80,
    type: 'toggle',
    defaultValue: false,
    loc: 'titles:selected',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    isRequired: true,
    loc: 'titles:pay-to-name',
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    ...defaultFieldStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:pay-type',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE,
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.PAYMENT_TERM_OPTION_DIRECT_PAY,
        label: G.getWindowLocale('titles:direct-pay', 'Direct Pay'),
      },
      {
        value: GC.PAYMENT_TERM_OPTION_FACTORING_COMPANY,
        label: G.getWindowLocale('titles:factoring-company', 'Factoring Company'),
      },
    ],
  },
  {
    ...defaultFieldStyles,
    isRequired: true,
    loc: 'titles:address',
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
    addressFieldName: GC.FIELD_ADDRESS,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    loc: 'titles:address2',
    fieldName: GC.FIELD_ADDRESS_2,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    isRequired: true,
    loc: 'titles:city',
    fieldName: GC.FIELD_CITY,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    isRequired: true,
    loc: 'titles:state',
    fieldName: GC.FIELD_STATE,
  },
  {
    ...defaultFieldStyles,
    type: 'text',
    isRequired: true,
    loc: 'titles:zip',
    fieldName: GC.FIELD_ZIP,
  },
  {
    ...defaultFieldStyles,
    isRequired: true,
    type: 'countrySelect',
    loc: 'titles:country',
    fieldName: GC.FIELD_COUNTRY,
  },
  {
    ...defaultFieldStyles,
    type: 'multiEmail',
    loc: 'titles:email',
    fieldName: 'contactEmails',
    fieldGroupDisplay: (_: any, values: Object, { isTerminal }: Object) => G.ifElse(
      G.isTrue(isTerminal),
      'none',
      'flex',
    ),
  },
  {
    ...defaultFieldStyles,
    type: 'datePicker',
    loc: 'titles:start-date',
    fieldName: GC.FIELD_CARRIER_FINANCE_LOCATION_START_DATE,
  },
  {
    ...defaultFieldStyles,
    type: 'textarea',
    loc: 'titles:comments',
    fieldName: GC.FIELD_COMMENTS,
    fieldGroupDisplay: (_: any, values: Object, { isTerminal }: Object) => G.ifElse(
      G.isTrue(isTerminal),
      'none',
      'flex',
    ),
  },
];

const defaultFields = R.reduce(
  (acc: Object, { fieldName, defaultValue = null }: Object) => R.assoc(fieldName, defaultValue, acc),
  {},
  fieldSettings,
);

const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const validationSchema = Yup.object().shape(R.reduce(
  (acc: Object, { isRequired, fieldName }: Object) => R.assoc(
    fieldName,
    G.ifElse(G.isTrue(isRequired), fieldRequired),
    acc,
  ),
  {},
  fieldSettings,
));

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const PayLocationFormComponent = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent {...G.getFormikProps(props)} fields={fieldSettings} isTerminal={props.isTerminal} />
    <FormFooter boxStyles={{ py: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(PayLocationFormComponent);
