import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import MessageCenter from '../../../components/message-center';
import { LocalLoader } from '../../../components/local-loader';
import { ActionsElement } from '../../../components/actions-element';
// features
import { withAsyncLoadNotes } from '../../dispatch-board-new/hocs/with-async-load-notes';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// feature driver-cards
import { withNoteCRUD } from '../hocs/with-note-crud';
//////////////////////////////////////////////////

const getRowActionsOptions = (props: Object) => {
  const {
    entity,
    pinNote,
    updateNote,
    deleteNote,
  } = props;

  const iconColor = G.getTheme('colors.light.blue');

  const editIcon = I.pencil(iconColor);
  const removeIcon = I.remove(iconColor);

  const editTxtLocale = () => G.getWindowLocale('actions:edit', 'Edit');
  const deleteTxtLocale = () => G.getWindowLocale('actions:delete', 'Delete');

  const pinIconColor = G.getThemeByCond(
    entity.pinned,
    'colors.light.blue',
    'colors.dark.grey',
  );

  const pinTxtLocale = G.ifElse(
    entity.pinned,
    G.getWindowLocale('actions:unpin', 'Unpin'),
    G.getWindowLocale('actions:pin', 'Pin'),
  );

  return [
    {
      permissions: null,
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: () => updateNote(entity),
    },
    {
      permissions: null,
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: () => deleteNote(entity),
    },
    {
      permissions: null,
      text: pinTxtLocale,
      action: () => pinNote(entity),
      frontIcon: I.pinNew(pinIconColor),
    },
  ];
};

const enhance = compose(
  withFixedPopover,
  withAsyncLoadNotes,
  withNoteCRUD,
  withHandlers({
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <ActionsElement version={2} options={getRowActionsOptions({...props, entity})} />
        ),
      })
    ),
  }),
  pure,
);

const CardNotes = enhance((props: Object) => {
  const { createNote, asyncNotes, handleClickEditIcon } = props;

  return (
    <LocalLoader width={350} height={400} localLoaderOpen={R.isNil(asyncNotes)}>
      <MessageCenter
        {...props}
        entities={asyncNotes}
        submitAction={createNote}
        handleClickEditIcon={handleClickEditIcon}
      />
    </LocalLoader>
  );
});

export default CardNotes;
