import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../../components/form-buttons';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable } from '../../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../../components/form-group-title';
// features
import References from '../../../reference/components/reference';
import FleetProfilePhoto from '../../../fleet-profile/components/profile-photo';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Form, TabFormWrapper } from '../../../../forms';
// ui
import { Box, Flex } from '../../../../ui';
// feature fleet
import { withAddOrEditInsurance } from '../../hocs';
import IntegrationFormComponent from '../../components/integration-form';
import { latestKnownLocationSettings } from '../../settings/fields-settings';
import { withAddOrEditLastLocation } from '../../hocs/add-or-edit-last-location';
// feature fleet/truck
import * as LC from '../../constants';
import { TruckGroupWrapper } from '../ui';
import { renderField } from './render-field';
import { truckDetailsFields, truckRegistrationFields } from '../settings/fields-settings';
import { GROUP_NAME_TRUCK_LATES_KNOWN_LOCATION, GROUP_NAME_TRUCK_SAMSARA } from '../constants';
import { makeSelectDailyLog, makeSelectTruckRefList, makeSelectTruckInsurances } from '../selectors';
import {
  insuranceColumns,
  dailyLogSettings,
  integrationSamsaraColumnSettings,
} from '../settings/column-settings';
import {
  updateTruckRequest,
  getDailyLogListRequest,
  createTruckInsuranceRequest,
  updateTruckInsuranceRequest,
  deleteTruckInsuranceRequest,
  deleteTruckReferenceRequest,
  updateTruckReferenceRequest,
  createExternalTruckIdRequest,
  deleteExternalTruckIdRequest,
  updateExternalTruckIdRequest,
  createTrucksReferenceRequest,
  createTruckLatestKnownLocationRequest,
} from '../actions';
//////////////////////////////////////////////////

const renderReferences = (props: Object) => (
  <References
    openModal={props.openModal}
    closeModal={props.closeModal}
    scope={GC.REF_SCOPE_NAME_FLEET_TRUCK}
    references={props[GC.UI_FIELD_REFERENCES]}
    handleCreateReference={props.handleCreateReference}
    handleUpdateReference={props.handleUpdateReference}
    handleRemoveReference={props.handleDeleteReference} />
);

const renderInsuranceGroup = (props: Object) => (
  <FormGroupTable
    entities={props.insurances}
    fields={props.collapsedGroup}
    entitiesFields={insuranceColumns}
    groupName={LC.GROUP_NAME_INSURANCE}
    handleEditRow={props.handleEditInsurance}
    handleAddClick={props.handleAddInsurance}
    handleDeleteRow={props.handleDeleteInsurance}
    panelTitle={G.getWindowLocale('titles:insurance', 'Insurance')}
    isOpened={R.path([LC.GROUP_NAME_INSURANCE], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.GROUP_NAME_INSURANCE)}
  />
);

const renderLatestKnownLocation = (props: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    fields={props.collapsedGroup}
    entities={props.latestKnownLocations}
    entitiesFields={latestKnownLocationSettings}
    groupName={GROUP_NAME_TRUCK_LATES_KNOWN_LOCATION}
    handleEditRow={props.handleClickAddOrEditLatestKnownLocation}
    handleAddClick={props.handleClickAddOrEditLatestKnownLocation}
    isOpened={R.path(['collapsedGroup', GROUP_NAME_TRUCK_LATES_KNOWN_LOCATION], props)}
    panelTitle={G.getWindowLocale('titles:last-known-location', 'Last Known Location')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(GROUP_NAME_TRUCK_LATES_KNOWN_LOCATION)}
  />
);

const renderTruckDailyLog = (props: Object) => {
  const {
    dailyLog,
    initialValues,
    collapsedGroup,
    handleToggleFormGroup,
    getDailyLogListRequest,
  } = props;

  if (G.isNilOrEmpty(dailyLog.entities)) return null;

  const handleLoadMoreEntities = () => getDailyLogListRequest({
    limit: 20,
    offset: R.path(['pagination', 'offset'], dailyLog),
    [GC.FIELD_TRUCK_GUID]: G.getGuidFromObject(initialValues),
  });

  return (
    <FormGroupTable
      {...dailyLog}
      notEditable={true}
      notDeletable={true}
      withPagination={true}
      tableMaxHeight='500px'
      isHiddenAddIcon={true}
      fields={collapsedGroup}
      entitiesFields={dailyLogSettings}
      groupName={LC.GROUP_NAME_DAILY_LOG}
      handleLoadMoreEntities={handleLoadMoreEntities}
      isOpened={R.prop(LC.GROUP_NAME_DAILY_LOG, collapsedGroup)}
      panelTitle={G.getWindowLocale('titles:daily-log', 'Daily Log')}
      handleToggleFormGroup={() => handleToggleFormGroup(LC.GROUP_NAME_DAILY_LOG)}
    />
  );
};

const renderIntegrationSamsara = (props: Object) => {
  const {
    assignInfo,
    collapsedGroup,
    integrationSamsara,
    handleToggleFormGroup,
    handleDeleteSamsaraRow,
    handleClickAddExternalId,
    handleClickEditExternalId } = props;
  const primaryDriver = R.pick(
    [GC.FIELD_FIRST_NAME, GC.FIELD_LAST_NAME, GC.FIELD_FLEET_EXTERNAL_ID],
    assignInfo,
  );
  const entities = R.map((item: Object) => G.ifElse(
    R.includes(
      R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE, item),
      [GC.FLEET_INTEGRATION_TYPE_SAMSARA, GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN],
    ),
    R.mergeRight(
      item,
      {
        ...R.pick([GC.SYSTEM_LIST_TRAILERS, GC.FIELD_TEAM_DRIVER], assignInfo),
        primaryDriver,
      },
    ),
    item,
  ), integrationSamsara);

  return (
    <FormGroupTable
      entities={entities}
      fields={collapsedGroup}
      groupName={GROUP_NAME_TRUCK_SAMSARA}
      handleDeleteRow={handleDeleteSamsaraRow}
      handleEditRow={handleClickEditExternalId}
      handleAddClick={handleClickAddExternalId}
      entitiesFields={integrationSamsaraColumnSettings}
      isOpened={R.path([GROUP_NAME_TRUCK_SAMSARA], collapsedGroup)}
      panelTitle={G.getWindowLocale('titles:integration', 'Integration')}
      handleToggleFormGroup={() => handleToggleFormGroup(GROUP_NAME_TRUCK_SAMSARA)} />
  );
};

const enhance = compose(
  withAddOrEditInsurance({ fleetType: 'truck' }),
  withHandlers({
    handleFormSubmit: ({ updateTruckRequest }: Object) => (values: Object) => updateTruckRequest(values),
    handleCreateReference: (props: Object) => (values: Object) => {
      const reqBody = {
        values,
        selectedList: R.of(Array, R.path(['initialValues', GC.FIELD_GUID], props)),
      };
      props.createTrucksReferenceRequest(reqBody);
    },
    handleUpdateReference: ({ updateTruckReferenceRequest }: Object) => (reqBody: Object) =>
      updateTruckReferenceRequest(reqBody),
    handleDeleteReference: (props: Object) => (guid: Object) => {
      const component = (
        <ConfirmComponent
          name={R.path([GC.UI_FIELD_REFERENCES, guid, GC.FIELD_NAME], props)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.closeModal();
                props.deleteTruckReferenceRequest(guid);
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleActionForAddOrEditLastLocationHoc: (props: Object) => (values: Object) =>
      props.createTruckLatestKnownLocationRequest(
        R.assoc(GC.FIELD_TRUCK_GUID, R.path(['initialValues', GC.FIELD_GUID], props), values),
    ),
    handleClickAddExternalId: (props: Object) => () => {
      const createdIntegrations = R.compose(
        R.map(R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE)),
        R.pathOr([], ['integrationSamsara']),
      )(props);
      const component = (
        <IntegrationFormComponent
          isEditMode={false}
          closeModal={props.closeModal}
          objectKey={GC.FIELD_TRUCK_GUID}
          createdIntegrations={createdIntegrations}
          submitAction={props.createExternalTruckIdRequest}
          objectGuid={R.path(['initialValues', GC.FIELD_GUID], props)} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          title: G.getWindowLocale('titles:add-integration', 'Add Integration'),
        },
      };
      props.openModal(modal);
    },
    handleClickEditExternalId: (props: Object) => (initialValues: Object) => {
      const component = (
        <IntegrationFormComponent
          isEditMode={true}
          createdIntegrations={[]}
          closeModal={props.closeModal}
          initialValues={initialValues}
          objectKey={GC.FIELD_TRUCK_GUID}
          submitAction={props.updateExternalTruckIdRequest}
          objectGuid={R.path(['initialValues', GC.FIELD_GUID], props)} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          btnWidth: '190px',
          title: G.getWindowLocale('titles:edit-integration', 'Edit Integration'),
        },
      };
      props.openModal(modal);
    },
    handleDeleteSamsaraRow: (props: Object) => (entity: Object) => {
      const { initialValues, deleteExternalTruckIdRequest } = props;
      const guid = G.getGuidFromObject(entity);
      const truckGuid = G.getGuidFromObject(initialValues);
      const name = R.or(
        R.path([GC.FIELD_FLEET_EXTERNAL_ID], entity),
        R.path([GC.FIELD_FLEET_TRANSPONDER_ID], entity),
      );
      const txtLocale = G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove');
      const component = <ConfirmComponent name={name} textLocale={txtLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => deleteExternalTruckIdRequest({ guid, truckGuid }),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleChangeAdditionalEquipments: ({ change }: Object) => (values: Array) =>
      change(GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT, R.map(R.prop(GC.FIELD_VALUE), values)),
  }),
  withAddOrEditLastLocation,
  pure,
);

const renderGroup = (props: Object, group: Object) => (
  group.fields.map((field: Object, index: number) => {
    if (G.showFormField(field.showField, props.formValues)) {
      return renderField(props, field, index);
    }
    return null;
  })
);

const TruckDetailsTabComponent = (props: Object) => (
  <TabFormWrapper>
    <Form minHeight='max-content' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
      {
        [truckDetailsFields, truckRegistrationFields].map((group: Object, index: number) => (
          <TruckGroupWrapper
            key={index}
            isOpened={R.path(['collapsedGroup', group.name], props)}
          >
            <FormGroupTitleComponent
              withArrowDown={true}
              text={G.getWindowLocale(group.title)}
              isOpened={R.path(['collapsedGroup', group.name], props)}
              onToggleFormGroup={() => props.handleToggleFormGroup(group.name)}
            />
            <Flex alignItems='flex-start'>
              <div>{renderGroup(props, group)}</div>
              {
                R.and(G.isZero(index), G.isNotNil(props.truckGuid)) &&
                <Box mt={10} ml={20}>
                  <FleetProfilePhoto type='truck' truckGuid={props.truckGuid} />
                </Box>
              }
            </Flex>
          </TruckGroupWrapper>
        ))
      }
      <FormButtons
        {...props}
        width='auto'
        btnText={G.getWindowLocale('actions:update', 'Update')} />
    </Form>
    {renderIntegrationSamsara(props)}
    {renderTruckDailyLog(props)}
    {renderLatestKnownLocation(props)}
    {renderInsuranceGroup(props)}
    {renderReferences(props)}
  </TabFormWrapper>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  insurances: makeSelectTruckInsurances(state),
  dailyLog: makeSelectDailyLog(state),
  [GC.UI_FIELD_REFERENCES]: makeSelectTruckRefList(state),
}));

export default connect(mapStateToProps, {
  updateTruckRequest,
  getDailyLogListRequest,
  createTruckInsuranceRequest,
  updateTruckInsuranceRequest,
  deleteTruckInsuranceRequest,
  deleteTruckReferenceRequest,
  updateTruckReferenceRequest,
  createTrucksReferenceRequest,
  deleteExternalTruckIdRequest,
  createExternalTruckIdRequest,
  updateExternalTruckIdRequest,
  createTruckLatestKnownLocationRequest,
})(enhance(TruckDetailsTabComponent));
