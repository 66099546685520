import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature config
import {
  documentHubConfigFieldSettings,
  defaultDocumentHubConfigFields,
  documentHubConfigValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: documentHubConfigValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDocumentHubConfigFields,
      initialValues,
    ),
  }),
  pure,
);

const DocumentHubConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      fields={documentHubConfigFieldSettings}
      optionsForSelect={props.optionsForSelect}
      handlers={{ disableIntegrationType: () => props.isEditMode }} />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(DocumentHubConfigForm);
