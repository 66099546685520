import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature configs
import {
  fields,
  defaultFields,
  getRowOptions,
  validationSchemaObject } from '../settings';
//////////////////////////////////////////////////

export const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultFields,
      props.initialValues,
    ),
    validationSchema: Yup.object().shape(validationSchemaObject),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const StatusActionsConfigForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={fields}
        justifyContent='space-between'
        handleChange={props.handleChange}
        optionsForSelect={{
          statusOptions: props.statusOptions,
          firstRowOptions: (
            getRowOptions(props.values, props.loadType, true)
          ),
          secondRowOptions: (
            getRowOptions(props.values, props.loadType, false)
          ),
        }} />
      <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(StatusActionsConfigForm);
