import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box, Flex } from '../../../ui';
// feature carrier
import {
  contractDiscountDatesFields,
  defaultContractDiscountFields } from '../settings/fields-settings';
import {
    FIELD_VALUE_TO,
    FIELD_VALUE_FROM,
    CARRIER_CONTRACT_DISCOUNT_FORM } from '../constants';
///////////////////////////////////////////////////////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => R.or(initialValues, defaultContractDiscountFields),
    validationSchema: Yup.object().shape({
      [GC.FIELD_CHARGE_RATE]: Yup.number()
        .nullable(true)
        .required('Rate field is required!')
        .max(9999999, 'Field number should be from 0 to 9999999'),
      [GC.FIELD_CHARGE_RATE_UNIT]: Yup.string()
        .nullable(true)
        .required('Rate field is required!'),
      [FIELD_VALUE_TO]: Yup.number()
        .nullable(true)
        .required('Rate field is required!')
        .max(9999999, 'Field number should be from 0 to 9999999'),
      [FIELD_VALUE_FROM]: Yup.number()
        .nullable(true)
        .required('Rate field is required!')
        .max(9999999, 'Field number should be from 0 to 9999999'),
    }),
    handleSubmit: (values: Object, { props }: Object) => {
      let newDiscounts = [];
      if (G.isTrue(props.editMode)) {
        newDiscounts = R.map(
          (discount: Object) => {
            if (R.equals(discount.guid, values.guid)) return values;
            return discount;
          },
          props.discounts,
        );
        props.submitAction(newDiscounts);
      } else {
        newDiscounts = R.concat(props.discounts, R.of(Array, { ...values, guid: `draft-${props.discounts.length}`}));
        props.submitAction(newDiscounts);
      }
      props.closeModal();
    },
    displayName: CARRIER_CONTRACT_DISCOUNT_FORM,
  }),
  pure,
);

const DiscountFormComponent = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <Flex p='0 15px' zIndex='13' display='block'>
        <FieldsetComponent
          {...props}
          flexDirection='column'
          fields={contractDiscountDatesFields} />
      </Flex>
      <FormFooter
        closeModal={props.closeModal}
        boxStyles={{ p: 10, justifyContent: 'space-around' }} />
    </form>
  </Box>
);

export default enhance(DiscountFormComponent);
