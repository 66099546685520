import * as R from 'ramda';
import React from 'react';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// icons
import * as I from '../../svgs';
// features auth
import { LogoWrapper, HighlightedText } from './ui';
//////////////////////////////////////////////////

const audienceNames = ['shippers', 'carriers', 'brokers', '3PLs'];

const enhance = compose(
  withState('index', 'setIndex', 0),
  withHandlers({
    changeIndex: ({ setIndex }: Object) => () => {
      setIndex((prevIndex: number) => {
        if (R.equals(prevIndex, R.dec(R.length(audienceNames)))) return 0;

        return R.add(prevIndex, 1);
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { changeIndex } = this.props;

      this.interval = setInterval(() => changeIndex(), 2000);
    },
    componentWillUnmount() {
      clearInterval(this.interval);
    },
  }),
);

export const LoginPageTitle = enhance(({ index }: Object) => (
  <h1>
    <LogoWrapper>{I.amousNewTxtLogo()}</LogoWrapper>
    drives <HighlightedText>{audienceNames[index]}</HighlightedText> forward
  </h1>
));
