// helpers/constants
import * as G from '../../../../helpers';
//////////////////////////////////////////////////

const defaultStyles = {
  width: 210,
  errorTop: '105%',
  afterTop: '14px',
  errorLeft: '10px',
  isClearable: true,
  labelWidth: '100%',
  errorWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

export const fleetAssignmentFieldSettings = [
  {
    ...defaultStyles,
    type: 'complexSelect',
    shouldCustomChange: true,
    loc: 'titles:primary-driver',
    options: 'primaryDriverOptions',
    fieldName: 'newPrimaryDriverGuid',
  },
  {
    ...defaultStyles,
    type: 'toggle',
    fieldName: 'primaryDriverPayable',
    loc: 'titles:create-primary-driver-invoice',
  },
  {
    ...defaultStyles,
    type: 'complexSelect',
    shouldCustomChange: true,
    loc: 'titles:secondary-driver',
    options: 'secondaryDriverOptions',
    fieldName: 'newSecondaryDriverGuid',
    fieldGroupDisplay: (_: any, { showSecondaryDriver }: Object) => G.ifElse(
      G.isTrue(showSecondaryDriver),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'toggle',
    fieldName: 'secondaryDriverPayable',
    loc: 'titles:create-secondary-driver-invoice',
    fieldGroupDisplay: (_: any, { showSecondaryDriver }: Object) => G.ifElse(
      G.isTrue(showSecondaryDriver),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    loc: 'titles:truck',
    type: 'complexSelect',
    options: 'truckOptions',
    fieldName: 'newTruckGuid',
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:trailers',
    options: 'trailerOptions',
    fieldName: 'newTrailerGuids',
  },
];

export const defaultFleetAssignmentFields = {
  newTruckGuid: null,
  newTrailerGuids: null,
  newPrimaryDriverGuid: null,
  primaryDriverPayable: false,
  newSecondaryDriverGuid: null,
};
