import * as R from 'ramda';
import React from 'react';
// ui
import {
  Flex,
  Text,
  SimpleWrapper,
  PageTitleCount,
  PageTitleWrapper } from '../../../ui';
//////////////////////////////////////////////////

const CustomTitle = ({ mr, text, count }: Object) => (
  <SimpleWrapper mt={10} mr={mr} height={30}>
    <PageTitleWrapper
      m='0px'
      p='0 8px'
      height='100%'
      width='max-content'
    >
      <PageTitleCount
        br='10px'
        t='-10px'
        p='0 5px'
        r='unset'
        height={20}
        width='auto'
        fontSize={11}
        l='calc(100% - 10px)'
      >
        {R.or(count, 0)}
      </PageTitleCount>
      <Flex
        flexDirection='column'
        justifyContent='center'
      >
        <Text fontSize={14} textTransform='uppercase'>
          {text}
        </Text>
      </Flex>
    </PageTitleWrapper>
  </SimpleWrapper>
);

export default CustomTitle;
