import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import { toastr } from 'react-redux-toastr';
// features
import { sendLogOutRequest } from '../auth/actions';
import { globalCleanReports } from '../../common/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import * as G from '../../helpers';
// feature carrier
import { setActiveTab, setInitialState } from './actions';
///////////////////////////////////////////////////////////////////////////////////////////////////

const carrierTabs = {
  general: ['name', 'scac', 'usDotNumber', 'mcNumber'],
  docs: [],
  audit: [],
  certificate: [],
  carrierFinance: ['carrierFinance.quickPayValue'],
  terminalGeneral: ['terminal.name', 'terminal.locationName', 'terminal.locationType',
    'terminal.address', 'terminal.city', 'terminal.state', 'terminal.country'],
  terminalProcess: ['terminal.dispatchProcess.emailTo', 'terminal.dispatchProcess.emailFrom',
    'terminal.dispatchProcess.emailTo', 'terminal.dispatchProcess.emailFrom',
    'terminal.statusCheckProcess.emailTo', 'terminal.statusCheckProcess.emailFrom'],
  terminalFinance: ['terminalFinance.quickPayValue'],
};

const validate = ({ getState, action }: Object, allow: void, reject: void) => {
  const { form, carrier } = getState();
  const { formName, touch, tabAction = () => {}, tabName, isCancel } = action.payload;
  if (G.isTrue(isCancel)) {
    tabAction();
    return allow(action);
  }
  if (R.equals(tabName, carrier.activeTab)) {
    return allow(action);
  }
  const errors = R.pathOr({}, [formName, 'syncErrors'], form);
  let isValid = true;
  R.forEach(
    (field: string) => {
      const error = R.pathOr(null, R.split('.', field), errors);
      if (G.isNotNil(error)) {
        isValid = false;
        touch(field);
      }
    },
    R.propOr([], carrier.activeTab, carrierTabs),
  );
  if (isValid) {
    tabAction();
    allow(action);
  } else {
    toastr.info(G.getWindowLocale('messages:valid-data', 'Please, provide valid data'));
    return reject();
  }
};

const handleSwitchBranchLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    receivedSwitchBranchSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(setInitialState());
    done();
  },
});

export const handleCarrierChangeTab = createLogic({
  type: setActiveTab,
  validate,
});

export default [handleCarrierChangeTab, handleSwitchBranchLogic];
