import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, all, call, fork, take, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { closeLoader, openLoader } from '../../../components/loader/actions';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { createMailSendingSuccess } from '../../mail-sending/actions';
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
import { markAllAsReadSuccess, getUnreadCountRequest } from '../../notification/actions';
import {
  getAvailableDocumentTypes,
  getServiceMappingListRequest,
  getCloDispatchBoardActionListRequest,
} from '../../configurations/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// feature order-details-new
import * as A from './actions';
import * as C from '../constants';
import { getSyncRateFromCustomerInvoiceValue } from '../helpers';
import {
  makeSelectLists,
  makeSelectOrderGuid,
  makeSelectLoadConfigs,
  makeSelectOrderDetails,
  makeSelectNotification,
  makeSelectMessageCenter,
} from './selectors';
//////////////////////////////////////////////////

function* getOrderDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getCloEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const references = R.sortBy((a: Object) => R.not(a.primary), data.references);
      const items = G.getItemsFromEventsUniqByGuid(data.events);
      const containers = G.getContainersFromEventsUniqByGuid(data.events);
      yield put(A.getOrderEventsSuccess(data.events));
      const load = R.assoc(GC.FIELD_LOAD_TYPE, GC.LOAD_TYPE_CLO, data);
      yield put(A.getOrderItemListSuccess(items));
      yield put(A.getOrderInvoiceTotalsRequest(payload));
      yield put(A.getOrderContainerListSuccess(containers));
      yield put(A.getOrderReferenceListSuccess({ data: references }));
      yield put(A.getOrderDetailsSuccess({ ...load, items, references }));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderDetailsRequest exception');
  }
}

function* getOrderInvoiceTotalsRequest({ payload }: Object) {
  try {
    const orderGuid = yield select(makeSelectOrderGuid());
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_INVOICE_READ,
      PC.CLO_INVOICE_WRITE,
      PC.CLO_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const options = {
      params: { [GC.FIELD_CLO_GUID]: R.or(payload, orderGuid) },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloInvoiceTotals, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderInvoiceTotalsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getOrderInvoiceTotalsRequest exception');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderInvoiceTotalsRequest exception');
  }
}

function* updateOrderDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const order = yield select(makeSelectOrderDetails());
    const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, order);
    const updateData = R.pick(GC.GROUPED_FIELDS.LOAD_PICK_ARR, order);
    const options = {
      data: {
        ...updateData,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        ...payload,
        billToGuid: R.path([GC.SYSTEM_OBJECT_BILL_TO, GC.FIELD_GUID], order),
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.clo, options);

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateOrderDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderDetailsRequest exception');
  }
}

function* getOrderStatusRequest({ payload }: Object) {
  try {
    const orderGuid = yield select(makeSelectOrderGuid());
    const endpoint = endpointsMap.getCloStatusEndpoint(R.or(payload, orderGuid));
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderStatusSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderStatusRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderStatusRequest exception');
  }
}

// cost allocations
function* getOrderCostAllocationsRequest({ payload }: Object) {
  try {
    const orderGuid = yield select(makeSelectOrderGuid());
    const endpoint = endpointsMap.getCloCostAllocationsEndpoint(R.or(payload, orderGuid));
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderCostAllocationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderCostAllocationsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderCostAllocationsRequest exception');
  }
}

// events
function* getOrderEventsRequest({ payload }: Object) {
  try {
    const params = { [GC.FIELD_CLO_GUID]: payload };
    const endpoint = endpointsMap.cloEventList;
    const res = yield call(sendRequest, 'get', endpoint, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderEventsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderEventsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getOrderEventsRequest exception');
  }
}

// documents
function* openOrderDocumentRequest({ documentOptions, downloadDocumentEndpoint }: Object) {
  try {
    const orderGuid = yield select(makeSelectOrderGuid());
    const options = {
      resType: 'arraybuffer',
      params: documentOptions,
    };
    const res = yield call(sendRequest, 'get', downloadDocumentEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const file = new window.Blob([data], { type: 'application/pdf' });
      G.openFile(file);
      yield put(closeModal());
      yield put(A.getOrderDocumentListRequest(orderGuid));
    } else {
      yield call(G.handleFailResponse, res, 'openOrderDocumentRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'openOrderDocumentRequest exception');
  }
}

function* printDocumentRequest({ endpoint, options, loadType }: Object) {
  try {
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const primaryObjectGuid = R.prop(GC.FIELD_PRIMARY_OBJECT_GUID, data);
      const documentOptions = {
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.prop(GC.FIELD_DOCUMENT_FILE_NAME, data),
      };
      const downloadDocumentEndpoint = G.ifElse(
        R.equals(loadType, GC.LOAD_TYPE_TEL),
        endpointsMap.telDocumentDownloadFile,
        endpointsMap.cloDocumentDownloadFile,
      );
      yield call(openOrderDocumentRequest, { documentOptions, downloadDocumentEndpoint });
    } else {
      yield call(G.handleFailResponse, res, 'printDocumentRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printDocumentRequest exception');
  }
}

function* handlePrintLoadDocumentsSaga({ payload }: Object) {
  try {
    const { loadGuid, fileName, documents, documentType } = payload;
    const options = {
      data: {
        loadGuid,
        fileName,
        documentType,
        format: 'pdf',
        documentsGuids: documents,
      },
    };
    const endpoint = endpointsMap.cloExportDocuments;
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO });
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintLoadDocumentsSaga exception');
  }
}

function* printDispatchDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: {
        format: 'pdf',
        guid: R.head(payload.guids),
        ...R.pick([GC.FIELD_TEMPLATE_GUID, 'eventGuids'], payload),
      },
    };
    const endpoint = endpointsMap.cloExport;
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO });
    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printDispatchDocumentRequest exception');
  }
}

export function* getOrderDocumentListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_DOCUMENT_READ,
      PC.CLO_DOCUMENT_WRITE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
        [GC.FIELD_PRIMARY_OBJECT_GUID]: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.cloDocumentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDocumentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderDocumentListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getOrderDocumentListRequest exception');
  }
}

function* createOrderDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const reqData = G.makeDataForDocument(payload);
    const options = { data: reqData };
    const res = yield call(sendRequest, 'post', endpointsMap.cloDocument, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrderDocumentSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createOrderDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderDocumentRequest exception');
  }
}

function* updateOrderDocumentRequest({ payload }: Object) {
  const { data, callback } = payload;

  try {
    yield put(openLoader());
    const reqData = G.makeDataForDocument(data);
    const options = { data: reqData };
    const endpoint = G.ifElse(
      G.isLoadTypeClo(data),
      endpointsMap.cloDocumentUpdate,
      endpointsMap.telDocumentUpdate,
    );
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateOrderDocumentSuccess(res.data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      G.callFunction(callback);
      yield call(G.handleFailResponse, res, 'updateOrderDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    G.callFunction(callback);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderDocumentRequest exception');
  }
}

function* removeOrderDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid } = payload;
    const endpoint = G.ifElse(
      G.isLoadTypeClo(payload),
      endpointsMap.getCloDocumentEndpoint,
      endpointsMap.getTelDocumentEndpoint,
    )(guid);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeOrderDocumentSuccess(guid));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderDocumentRequest exception');
  }
}

function* downloadOrderDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { documentFilename, primaryObjectGuid } = payload;
    const endpoint = G.ifElse(
      G.isLoadTypeClo(payload),
      endpointsMap.cloDocumentDownloadFile,
      endpointsMap.telDocumentDownloadFile,
    );
    const options = {
      resType: 'arraybuffer',
      params: {
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: documentFilename,
      },
    };
    const res = yield call(sendRequestWithQSParamsSerializer, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      FileDownload(data, documentFilename);
    } else {
      yield call(G.handleFailResponse, res, 'downloadOrderDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'downloadOrderDocumentRequest exception');
  }
}

function* getOrderXMLRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, fileName } = payload;
    const options = {
      resType: 'arraybuffer',
    };
    const endpoint = endpointsMap.getCloXMLEndpoint(guid);
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      FileDownload(data, `${fileName}.xml`);
    } else {
      yield call(G.handleFailResponse, res, 'getOrderXMLRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderXMLRequest exception');
  }
}

// references
function* getOrderReferenceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const { loadGuid, tableNumber } = payload;
    const options = {
      params: { [GC.FIELD_CLO_GUID]: loadGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.cloReferenceList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const sortedRefs = R.sortBy((a: Object) => R.not(a.primary), data);
      yield put(A.getOrderReferenceListSuccess({ tableNumber, data: sortedRefs }));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderReferenceListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderReferenceListRequest exception');
  }
}

function* createOrderReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const orderGuid = yield select(makeSelectOrderGuid());
    const options = {
      data: R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, orderGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.cloReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrderReferenceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createOrderReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderReferenceRequest exception');
  }
}

function* updateOrderReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.cloReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateOrderReferenceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderReferenceRequest exception');
  }
}

function* removeOrderReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getCloReferenceEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeOrderReferenceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderReferenceRequest exception');
  }
}

// status messages
function* getOrderStatusMessageListRequest({ payload }: Object) {
  try {
    const { loadGuid, tableNumber } = payload;
    const options = {
      params: { loadGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.cloStatusMessageList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderStatusMessageListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderStatusMessageListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderStatusMessageListRequest exception');
  }
}

function* createOrderStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const orderGuid = yield select(makeSelectOrderGuid());
    const options = { data: R.assoc(GC.FIELD_LOAD_GUID, orderGuid, payload) };
    const res = yield call(sendRequest, 'post', endpointsMap.cloStatusMessage, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDetailsRequest(orderGuid));
      yield put(A.createOrderStatusMessageSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'createOrderStatusMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderStatusMessageRequest exception');
  }
}

function* createOrderMultipleStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { callback, statusMessages } = payload;
    const completedStatusMessages = [];
    const orderGuid = yield select(makeSelectOrderGuid());
    for (let i = 0; R.lt(i, R.length(statusMessages)); i++) {
      const statusMessage = G.getPropFromObject(i, statusMessages);
      const options = { data: R.assoc(GC.FIELD_LOAD_GUID, orderGuid, statusMessage) };
      const res = yield call(sendRequest, 'post', endpointsMap.cloStatusMessage, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        completedStatusMessages.push(data);
      } else {
        G.callFunctionWithArgs(callback, completedStatusMessages);
        yield call(G.handleFailResponse, res, 'createOrderMultipleStatusMessageRequest fail');
        break;
      }
    }
    if (G.isNotEmpty(completedStatusMessages)) {
      yield put(A.getOrderDetailsRequest(orderGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(A.createOrderMultipleStatusMessageSuccess(completedStatusMessages));
      if (R.equals(R.length(statusMessages), R.length(completedStatusMessages))) yield put(closeModal());
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderMultipleStatusMessageRequest exception');
  }
}

function* updateOrderStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const orderGuid = yield select(makeSelectOrderGuid());

    const locationArr = [
      GC.FIELD_ZIP,
      GC.FIELD_CITY,
      GC.FIELD_STATE,
      GC.FIELD_COUNTRY,
      GC.FIELD_LATITUDE,
      GC.FIELD_ADDRESS_1,
      GC.FIELD_LONGITUDE,
      GC.FIELD_PROOF_TYPE,
    ];

    const useStatusMessageLocation = R.compose(
      R.omit([GC.FIELD_ADDRESS_1]),
      R.assoc(GC.FIELD_ADDRESS, payload.address1),
      R.mergeRight(R.prop('statusMessageLocation', payload)),
      R.pick(locationArr),
    )(payload);

    const options = {
      data: R.compose(
        R.assoc('cloStatusMessageLocation', useStatusMessageLocation),
        R.omit(locationArr),
      )(payload),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.cloStatusMessage, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDetailsRequest(orderGuid));
      yield put(A.updateOrderStatusMessageSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderStatusMessageRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderStatusMessageRequest exception');
  }
}

function* removeOrderStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const orderGuid = yield select(makeSelectOrderGuid());
    const endpoint = endpointsMap.getCloStatusMessageEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsRequest(orderGuid));
      yield put(A.removeOrderStatusMessageSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderStatusMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderStatusMessageRequest exception');
  }
}

// items
function* updateOrderItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const orderGuid = yield select(makeSelectOrderGuid());
    const options = {
      data: R.assoc(GC.FIELD_LOAD_TYPE, GC.LOAD_TYPE_CLO, payload),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.routeItem, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsRequest(orderGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderItemRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderItemRequest exception');
  }
}

function* toggleOrderVehicleItemRequest({ payload }: Object) {
  try {
    const { guid, damages, expanded, tableNumber } = payload;

    if (R.or(G.isTrue(expanded), G.isNotNilAndNotEmpty(damages))) {
      return yield put(A.toggleOrderVehicleItemSuccess({ damages, tableNumber, itemGuid: guid }));
    }

    yield put(openLoader());
    const options = {
      data: { elements: R.of(Array, guid) },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.getDamageReportListByItemGuids, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.toggleOrderVehicleItemSuccess({ tableNumber, damages: data, itemGuid: guid }));
    } else {
      yield call(G.handleFailResponse, res, 'toggleOrderVehicleItemRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'toggleOrderVehicleItemRequest exception');
  }
}

// containers
function* updateOrderContainerRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const orderGuid = yield select(makeSelectOrderGuid());
    const options = {
      data: R.assoc(GC.FIELD_LOAD_TYPE, GC.LOAD_TYPE_CLO, payload),
    };
    const res = yield call(sendRequest, 'put', endpointsMap.routeContainer, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsRequest(orderGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderContainerRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderContainerRequest exception');
  }
}

// customer rates
function* getOrderCustomerRateListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_RATE_READ,
      PC.CLO_RATE_WRITE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const { loadGuid, tableNumber } = payload;
    const options = {
      params: { [GC.FIELD_CLO_GUID]: loadGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloRate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderCustomerRateListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderCustomerRateListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderCustomerRateListRequest exception');
  }
}

function* createOrderCustomerRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const cloGuid = yield select(makeSelectOrderGuid());
    const options = {
      data: R.assoc('cloGuid', cloGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloRate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderStatusRequest());
      yield put(A.createOrderCustomerRateSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createOrderCustomerRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderCustomerRateRequest exception');
  }
}

function* updateOrderCustomerRateRequest({ payload }: Object) {
  try {
    const { values, loadData } = payload;

    const { cloGuid, selected, carrierContractGuid, customerContractGuid } = values;

    yield put(openLoader());

    const options = { data: values };

    const res = yield call(sendRequest, 'put', endpointsMap.cloRate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderStatusRequest());
      yield put(A.getOrderCostAllocationsRequest());
      yield put(A.updateOrderCustomerRateSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');

      if (G.isNotNil(loadData)) yield put(A.updateOrderDetailsRequest(loadData));

      if (R.and(
        G.isTrue(selected),
        G.isAllNotNilOrNotEmpty([carrierContractGuid, customerContractGuid]),
      )) {
        yield fork(getOrderDetailsRequest, { payload: cloGuid });
        const getCloAction = yield take(A.getOrderDetailsSuccess);
        const billTo = R.path(['payload', GC.SYSTEM_OBJECT_BILL_TO], getCloAction);

        yield put(A.updateOrderBillToSuccess(billTo));
      }
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderCustomerRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderCustomerRateRequest exception');
  }
}

function* removeOrderCustomerRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getCloRateItemEndpoint(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeOrderCustomerRateSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderCustomerRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderCustomerRateRequest exception');
  }
}

function* selectOrderCustomerRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { entity, loadData } = payload;

    const { guid, cloGuid, carrierContractGuid, customerContractGuid } = entity;

    const endpoint = endpointsMap.getCloRateSelectEndpoint(guid);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.selectOrderCustomerRateSuccess(entity));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      if (G.isNotNil(loadData)) yield put(A.updateOrderDetailsRequest(loadData));

      if (G.isAllNotNilOrNotEmpty([carrierContractGuid, customerContractGuid])) {
        yield fork(getOrderDetailsRequest, { payload: cloGuid });

        const getCloAction = yield take(A.getOrderDetailsSuccess);

        const billTo = R.path(['payload', GC.SYSTEM_OBJECT_BILL_TO], getCloAction);

        yield put(A.updateOrderBillToSuccess(billTo));
      }
    } else {
      yield call(G.handleFailResponse, res, 'selectOrderCustomerRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'selectOrderCustomerRateRequest exception');
  }
}

// customer invoices
export function* getOrderCustomerInvoiceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_INVOICE_READ,
      PC.CLO_INVOICE_WRITE,
      PC.CLO_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const { loadGuid, tableNumber } = payload;

    const orderGuid = yield select(makeSelectOrderGuid());
    const options = {
      params: {
        [GC.FIELD_CLO_GUID]: G.ifElse(
          G.isNotNilAndNotEmpty(loadGuid),
          loadGuid,
          orderGuid,
        ),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloInvoice, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderCustomerInvoiceListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderCustomerInvoiceListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderCustomerInvoiceListRequest exception');
  }
}

function* createOrderCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const orderGuid = yield select(makeSelectOrderGuid());
    const loadConfigs = yield select(makeSelectLoadConfigs());

    const options = {
      data: R.assoc('cloGuid', orderGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloInvoice, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderInvoiceTotalsRequest());
      yield put(A.createOrderCustomerInvoiceSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');

      if (G.isTrue(getSyncRateFromCustomerInvoiceValue(loadConfigs, true))) {
        yield put(A.getOrderCustomerRateListRequest({ [GC.FIELD_LOAD_GUID]: orderGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'createOrderCustomerInvoiceRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderCustomerInvoiceRequest exception');
  }
}

function* updateOrderCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const orderGuid = yield select(makeSelectOrderGuid());
    const loadConfigs = yield select(makeSelectLoadConfigs());

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.cloInvoice, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderInvoiceTotalsRequest());
      yield put(A.updateOrderCustomerInvoiceSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      if (G.isTrue(getSyncRateFromCustomerInvoiceValue(loadConfigs, true))) {
        yield put(A.getOrderCustomerRateListRequest({ [GC.FIELD_LOAD_GUID]: orderGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderCustomerInvoiceRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderCustomerInvoiceRequest exception');
  }
}

function* removeOrderCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const data = {
      currentEnterprise,
      guids: R.of(Array, payload),
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.cloInvoice, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderInvoiceTotalsRequest());
      yield put(A.removeOrderCustomerInvoiceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderCustomerInvoiceRequest fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderCustomerInvoiceRequest exception');
  }
}

function* sendCIToFinancialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const orderGuid = yield select(makeSelectOrderGuid());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        currentEnterprise,
        guids: R.of(Array, payload),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.customerInvoiceFinancialExport, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:update');
      }
      yield put(A.getOrderCustomerInvoiceListRequest({ [GC.FIELD_LOAD_GUID]: orderGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleSendCIToFinancialSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSendCIToFinancialSaga exception');
  }
}

function* printOrderCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.customerInvoiceExport;
    const options = {
      data: {
        format: 'pdf',
        guid: R.head(payload.guids),
        templateGuid: payload.templateGuid,
        documentsGuids: R.or(payload.documents, []),
        [GC.FIELD_VENDOR_DOCUMENT_GUIDS]: R.pathOr([], [GC.FIELD_VENDOR_DOCUMENT_GUIDS], payload),
      },
    };
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printOrderCustomerInvoiceRequest exception');
  }
}

// configs
function* getStatusMessagesConfigListRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.statusCodeList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getStatusMessagesConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getStatusMessagesConfigListRequest');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getStatusMessagesConfigListRequest');
  }
}

function* getOrderConfigsByNamesRequest({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;

    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      const defaultNotesCard = G.getConfigValueFromStore(GC.UI_ORDER_TRIP_DEFAULT_NOTES_CARD, mappedConfigs);

      yield call(G.setAmousConfigToWindow, mappedConfigs);

      yield put(A.getOrderConfigsByNamesSuccess(mappedConfigs));

      if (R.and(G.isTrue(defaultNotesCard), R.not(G.isCurrentUserTypeCustomer()))) {
        yield put(A.changeMessageCenterActiveTabSuccess('noteList'));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getOrderConfigsByNamesRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getOrderConfigsByNamesRequest exception');
  }
}

function* getOrderConfigByGroupNamesRequest({ payload }: Object) {
  try {
    const groups = [
      GC.UI_CONFIG_GROUP,
      GC.CLO_CONFIG_GROUP,
      GC.GENERAL_CONFIG_GROUP,
      GC.INVOICE_CONFIG_GROUP,
      GC.COMMUNICATION_CONFIG_GROUP,
    ];
    const res = yield all(groups.map((group: Object) => call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      {
        params: {
          group,
          [GC.BRANCH_GUID]: payload,
        },
      },
    )));
    const status = R.all(({ status }: Object) => G.isResponseSuccess(status), res);
    if (status) {
      const configs = R.compose(
        R.map(({ data }: Object) => G.mapConfigValuesByName(data)),
        R.indexBy(R.path(['config', 'params', 'group'])),
      )(res);
      yield put(A.getOrderConfigByGroupNamesSuccess(configs));
    } else {
      yield all(res.map((item: Object) => call(G.handleFailResponse, item, 'getOrderConfigByGroupNamesRequest fail')));
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderConfigByGroupNamesRequest exception');
  }
}

function* getDataAndConfigsByBranchGuidRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.getOrderConfigByGroupNamesRequest(payload));
    yield put(A.getStatusMessagesConfigListRequest(payload));
    yield put(A.getOrderConfigsByNamesRequest({
      branchGuid: payload,
      names: R.join(',', [GC.GROUPED_CONFIGS.LOAD_CONFIGS_ARR_FOR_DISPATCH_DETAILS]),
    }));
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getDataAndConfigsByBranchGuidRequest exception');
  }
}

// mails
function* getOrderMailListRequest() {
  try {
    const loadGuid = yield select(makeSelectOrderGuid());
    if (G.isNilOrEmpty(loadGuid)) return false;
    const options = {
      params: { loadGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.mailSending, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderMailListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderMailListRequest fail', false);
    }
  } catch (error) {
    yield call(G.handleException, error, 'getOrderMailListRequest exception');
  }
}

// message center
function* getOrderChatMessageListRequest({ payload, notSwitchTab }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());

    const permissions = [
      PC.CLO_CHAT_MESSAGE_READ,
      PC.CLO_CHAT_MESSAGE_WRITE,
    ];

    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return yield put(A.getOrderNoteListRequest(payload));

    const options = {
      params: { [GC.FIELD_LOAD_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloChatMessageList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderChatMessageListSuccess(data));

      if (R.not(notSwitchTab)) yield put(A.changeMessageCenterActiveTabSuccess('chatMessageList'));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderChatMessageListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getOrderChatMessageListRequest exception');
  }
}

function* getOrderNoteListRequest({ payload, notSwitchTab }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_LOAD_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloNoteList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderNoteListSuccess(data));

      if (R.not(notSwitchTab)) yield put(A.changeMessageCenterActiveTabSuccess('noteList'));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderNoteListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderNoteListRequest exception');
  }
}

function* changeMessageCenterActiveTab({ payload }: Object) {
  try {
    const orderGuid = yield select(makeSelectOrderGuid());
    const messageCenter = yield select(makeSelectMessageCenter());

    if (G.isNilOrEmpty(messageCenter[payload])) {
      const messageCenterActionMap = {
        noteList: A.getOrderNoteListRequest,
        chatMessageList: A.getOrderChatMessageListRequest,
      };

      yield put(messageCenterActionMap[payload](orderGuid));
    } else {
      yield put(A.changeMessageCenterActiveTabSuccess(payload));
    }
  } catch (error) {
    yield call(G.handleException, error, 'changeMessageCenterActiveTab exception');
  }
}

function* createOrderNoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.cloNote, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.createOrderNoteSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createOrderNoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderNoteRequest exception');
  }
}

function* createOrderChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'post', endpointsMap.cloChatMessage, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      yield put(A.createOrderChatMessageSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'createOrderChatMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrderChatMessageRequest exception');
  }
}

function* updateOrderChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.cloChatMessage, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateOrderChatMessageSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderChatMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderChatMessageRequest exception');
  }
}

function* updateOrderNoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.cloNote, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateOrderNoteSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderNoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderNoteRequest exception');
  }
}

function* removeOrderChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getCloChatMessageEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeOrderChatMessageSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderChatMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderChatMessageRequest exception');
  }
}

function* removeOrderNoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getCloNoteEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeOrderNoteSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeOrderNoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOrderNoteRequest exception');
  }
}

// payments
function* updateOrderBillToRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.cloBillTo, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateOrderBillToSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateOrderBillToRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateOrderBillToRequest exception');
  }
}

// appointment
const fieldsToPickUpdateAppts = [GC.FIELD_STOP_NUMBER, ...GC.GROUPED_FIELDS.APPOINTMENTS_PICK_ARR];

function* handleUpdateAppointmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const data = R.pick(fieldsToPickUpdateAppts, payload);
    const options = { data };
    const res = yield call(sendRequest, 'put', endpointsMap.cloEventAppointment, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateAppointmentSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateAppointmentSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateAppointmentSaga');
  }
}

// notifications
function* getOrderNotificationListRequest({ payload }: Object) {
  try {
    const options = {
      params: { offset: 0, limit: 1000, objectGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.notificationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderNotificationListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderNotificationListRequest');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getOrderNotificationListRequest');
  }
}

function* hideOrderNotificationsRequest() {
  try {
    yield put(openLoader());
    const { list } = yield select(makeSelectNotification());
    const unreadNotifications = R.compose(
      R.map(G.getGuidFromObject),
      R.filter(({ read }: Object) => G.isFalse(read)),
    )(list);
    const data = { read: true, guids: unreadNotifications };
    const options = { data };
    const res = yield call(sendRequest, 'put', endpointsMap.notificationMark, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(getUnreadCountRequest());
      yield put(markAllAsReadSuccess(data));
      yield put(A.hideOrderNotificationsSuccess());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'hideOrderNotificationsRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'hideOrderNotificationsRequest exception');
  }
}

function* handleReadOrderNotification({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid } = payload;

    const options = {
      data: {
        read: true,
        guids: [guid],
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.notificationMark, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.markOrderNotificationAsReadSuccess(guid));
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleReadOrderNotification exception');
  } finally {
    yield put(closeLoader());
  }
}

// lists
const getListActionsMap = {
  [C.ACTIVE_LIST_CLAIMS]: A.getClaimDetailsRequest,
  [C.ACTIVE_LIST_REFERENCES]: A.getOrderReferenceListRequest,
  [C.ACTIVE_LIST_CUSTOMER_RATES]: A.getOrderCustomerRateListRequest,
  [C.ACTIVE_LIST_STATUS_MESSAGES]: A.getOrderStatusMessageListRequest,
  [C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST]: A.getExternalSystemListRequest,
  [C.ACTIVE_LIST_CUSTOMER_INVOICES]: A.getOrderCustomerInvoiceListRequest,
};

function* changeActiveList({ payload }: Object) {
  try {
    const { listName, tableNumber } = payload;
    const lists = yield select(makeSelectLists());
    const loadGuid = yield select(makeSelectOrderGuid());
    const list = R.or(R.path([listName, 'list'], lists), R.path([listName], lists));
    if (R.and(
      G.isNilOrEmpty(list),
      G.notContain(listName, [C.ACTIVE_LIST_ITEMS, C.ACTIVE_LIST_VEHICLE_ITEMS, C.ACTIVE_LIST_CONTAINERS]),
    )) {
      yield put(getListActionsMap[listName]({ loadGuid, tableNumber }));
    } else {
      yield put(A.changeActiveListSuccess({ listName, tableNumber }));
    }

    yield put(A.changeActiveListSuccess({ listName, tableNumber }));
  } catch (error) {
    yield call(G.handleException, error, 'changeActiveList exception');
  }
}

function* handleChangeCancelStatusSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const guid = R.path(['data', GC.FIELD_GUID], payload);
    let options;
    if (G.isNotNilAndNotEmpty(R.path([GC.FIELD_LOAD_CANCEL_REASON_GUID], payload))) {
      options = {
        params: R.pick([GC.FIELD_LOAD_CANCEL_REASON_GUID], payload),
      };
    }
    const endpoint = {
      cancel: endpointsMap.cloCancel(guid),
      restore: endpointsMap.cloRestore(guid),
    };
    const res = yield call(sendRequest, 'put', endpoint[payload.type], options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getOrderDetailsRequest(guid));
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeCancelStatusSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeCancelStatusSaga');
  }
}

function* cancelCLORequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.cancelCLO, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsRequest(G.getGuidFromObject(payload)));
    } else {
      yield call(G.handleFailResponse, res, 'cancelCLORequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'cancelCLORequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* unquoteCLORequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const cloGuid = G.getGuidFromObject(payload);

    const res = yield call(sendRequest, 'put', endpointsMap.cloUnquote(cloGuid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getOrderDetailsRequest(G.getGuidFromObject(payload)));
    } else {
      yield call(G.handleFailResponse, res, 'unquoteCLORequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'unquoteCLORequest saga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateTelFromCloSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { clo } = payload;

    const cloGuid = G.getGuidFromObject(clo);

    const tel = R.pathOr({}, [GC.FIELD_TEL], payload);

    const options = {
      data: {
        ...tel,
        cloGuid,
        [GC.FIELD_BRANCH_GUID]: R.prop(GC.FIELD_BRANCH_GUID, clo),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.tel, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield put(A.getOrderDetailsRequest(cloGuid));
      yield put(A.getRelatedLoadListRequest(cloGuid));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTelFromCloSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTelFromCloSaga exception');
  }
}

// related loads
function* handleGetRelatedLoadListSaga({ payload }: Object) {
  if (G.hasNotAmousCurrentUserPermissions([PC.TEL_READ, PC.TEL_WRITE])) return;

  try {
    const options = {
      params: {
        cloGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.telDispatchBoardByCloGuid, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getRelatedLoadListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetRelatedLoadListSaga');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetRelatedLoadListSaga');
  }
}

// linked loads
function* handleGetLinkedLoadListSaga({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.TEL_READ, PC.TEL_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const options = {
      params: {
        cloGuid: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telLinkedCloTelInfos, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLinkedLoadListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLinkedOrderListSaga');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLinkedOrderListSaga');
  }
}

function* deleteTelFromCloSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.getTelEntity(payload);
    const cloGuid = yield select(makeSelectOrderGuid());
    const options = { params: { cloGuid } };
    const res = yield call(sendRequest, 'delete', endpoint, options);
    if (G.isResponseSuccess(res.status)) {
      yield put(A.getRelatedLoadListSuccess(null));
      yield put(A.getOrderDetailsRequest(cloGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'deleteTelFromCloSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'deleteTelFromCloSaga exception');
  }
}

// approve clo
function* approveCloRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.approveClo(G.getGuidFromObject(payload)));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.approveCloSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'approveCloRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'approveCloRequest exception');
  }
}

// claim
function* getClaimDetailsRequest({ payload }: Object) {
  try {
    const { loadGuid, tableNumber, updateClaimGeneralDetails } = payload;

    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_CLO_GUID]: loadGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claim, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getClaimDetailsSuccess({ tableNumber, data: G.ifElse(G.isNilOrEmpty(data), [], R.of(Array, data)) }));

      if (updateClaimGeneralDetails) yield put(A.getClaimGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getClaimDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getClaimDetailsRequest exception');
  }
}

function* getClaimGeneralDetailsRequest({ payload }: Object) {
  try {
    if (G.hasNotAmousCurrentUserPermissions([PC.CLAIM_READ, PC.CLAIM_WRITE])) return;

    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_CLO_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimGeneralDetailsForDispatchDetails, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getClaimGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getClaimGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getClaimGeneralDetailsRequest exception');
  }
}

function* createOrUpdateClaimRequest({ payload }: Object) {
  try {
    const { version } = payload;

    yield put(openLoader());

    const options = { data: payload };
    const method = G.ifElse(R.isNil(version), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.claim, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.setClaimSuccess(data));
      yield put(A.getClaimGeneralDetailsSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateClaimRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateClaimRequest exception');
  }
}

function* removeClaimRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.getClaimByGuid(payload));

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.setClaimSuccess());
      yield put(A.getClaimGeneralDetailsSuccess({}));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeClaimRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeClaimRequest exception');
  }
}

// external system
function* getExternalSystemListRequest({ payload }: Object) {
  try {
    const { loadGuid, tableNumber } = payload;

    const options = {
      params: { [GC.FIELD_CLO_GUID]: loadGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloExternalSystemList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getExternalSystemListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getExternalSystemListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getExternalSystemListRequest exception');
  }
}

function* removeCloFromExternalSystemRequest({ payload }: Object) {
  try {
    const { guid } = payload;

    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.removeCloFromExternalSystemByGuid(guid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeCloFromExternalSystemSuccess(guid));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeTelFromExternalSystemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeTelFromExternalSystemRequest exception');
  }
}

// visit page
function* visitOrderDetailsPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DISPATCH_DETAILS_ORDER_PAGE);

    yield put(openLoader());
    yield put(A.setInitialState());

    const { guid } = payload;

    yield fork(getOrderDetailsRequest, { payload: guid });

    const getCloAction = yield take(A.getOrderDetailsSuccess);
    const branchGuid = R.path(['payload', GC.BRANCH_GUID], getCloAction);

    yield put(A.getOrderCustomerInvoiceListRequest({ [GC.FIELD_LOAD_GUID]: guid }));

    yield all([
      put(getAvailableDocumentTypes()),
      put(getServiceMappingListRequest()),
      put(A.getLinkedLoadListRequest(guid)),
      put(A.getRelatedLoadListRequest(guid)),
      put(A.getOrderDocumentListRequest(guid)),
      put(A.getClaimGeneralDetailsRequest(guid)),
      put(A.getOrderNotificationListRequest(guid)),
      put(getCloDispatchBoardActionListRequest(branchGuid)),
      put(A.getDataAndConfigsByBranchGuidRequest(branchGuid)),
      put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_CLO)),
      put(A.getOrderCustomerRateListRequest({ [GC.FIELD_LOAD_GUID]: guid })),
      call(getOrderNoteListRequest, { payload: guid, notSwitchTab: true }),
      call(getOrderChatMessageListRequest, { payload: guid, notSwitchTab: true }),
    ]);

    yield put(closeLoader());
    break;
  }
}

function* orderDetailsNewWatcherSaga() {
  yield takeLatest(A.cancelCLORequest, cancelCLORequest);
  yield takeLatest(A.changeActiveList, changeActiveList);
  yield takeLatest(A.unquoteCLORequest, unquoteCLORequest);
  yield takeLatest(A.getOrderStatusRequest, getOrderStatusRequest);
  yield takeLatest(A.getOrderDetailsRequest, getOrderDetailsRequest);
  yield takeLatest(A.createTelFromCloRequest, handleCreateTelFromCloSaga);
  yield takeLatest(A.updateOrderDetailsRequest, updateOrderDetailsRequest);
  yield takeLatest(A.changeCancelStatusRequest, handleChangeCancelStatusSaga);
  yield takeLatest(GC.VISIT_DISPATCH_DETAILS_ORDER_PAGE, visitOrderDetailsPage);
  yield takeLatest(A.getOrderInvoiceTotalsRequest, getOrderInvoiceTotalsRequest);
  // cost allocations
  yield takeLatest(A.getOrderCostAllocationsRequest, getOrderCostAllocationsRequest);
  // notifications
  yield takeLatest(A.hideOrderNotificationsRequest, hideOrderNotificationsRequest);
  yield takeLatest(A.markOrderNotificationAsReadRequest, handleReadOrderNotification);
  yield takeLatest(A.getOrderNotificationListRequest, getOrderNotificationListRequest);
  // configs
  yield takeLatest(A.getOrderConfigsByNamesRequest, getOrderConfigsByNamesRequest);
  yield takeLatest(A.getOrderConfigByGroupNamesRequest, getOrderConfigByGroupNamesRequest);
  yield takeLatest(A.getStatusMessagesConfigListRequest, getStatusMessagesConfigListRequest);
  yield takeLatest(A.getDataAndConfigsByBranchGuidRequest, getDataAndConfigsByBranchGuidRequest);
  // events
  yield takeLatest(A.getOrderEventsRequest, getOrderEventsRequest);
  // references
  yield takeLatest(A.updateOrderReferenceRequest, updateOrderReferenceRequest);
  yield takeLatest(A.createOrderReferenceRequest, createOrderReferenceRequest);
  yield takeLatest(A.removeOrderReferenceRequest, removeOrderReferenceRequest);
  yield takeLatest(A.getOrderReferenceListRequest, getOrderReferenceListRequest);
  // status messages
  yield takeLatest(A.removeOrderStatusMessageRequest, removeOrderStatusMessageRequest);
  yield takeLatest(A.updateOrderStatusMessageRequest, updateOrderStatusMessageRequest);
  yield takeLatest(A.createOrderStatusMessageRequest, createOrderStatusMessageRequest);
  yield takeLatest(A.getOrderStatusMessageListRequest, getOrderStatusMessageListRequest);
  yield takeLatest(A.createOrderMultipleStatusMessageRequest, createOrderMultipleStatusMessageRequest);
  // items
  yield takeLatest(A.updateOrderItemRequest, updateOrderItemRequest);
  yield takeLatest(A.toggleOrderVehicleItemRequest, toggleOrderVehicleItemRequest);
  // containers
  yield takeLatest(A.updateOrderContainerRequest, updateOrderContainerRequest);
  // customer invoices
  yield takeLatest(A.sendCIToFinancialRequest, sendCIToFinancialRequest);
  yield takeLatest(A.printOrderCustomerInvoiceRequest, printOrderCustomerInvoiceRequest);
  yield takeLatest(A.updateOrderCustomerInvoiceRequest, updateOrderCustomerInvoiceRequest);
  yield takeLatest(A.createOrderCustomerInvoiceRequest, createOrderCustomerInvoiceRequest);
  yield takeLatest(A.removeOrderCustomerInvoiceRequest, removeOrderCustomerInvoiceRequest);
  yield takeLatest(A.getOrderCustomerInvoiceListRequest, getOrderCustomerInvoiceListRequest);
  // customer rates
  yield takeLatest(A.selectOrderCustomerRateRequest, selectOrderCustomerRateRequest);
  yield takeLatest(A.removeOrderCustomerRateRequest, removeOrderCustomerRateRequest);
  yield takeLatest(A.updateOrderCustomerRateRequest, updateOrderCustomerRateRequest);
  yield takeLatest(A.createOrderCustomerRateRequest, createOrderCustomerRateRequest);
  yield takeLatest(A.getOrderCustomerRateListRequest, getOrderCustomerRateListRequest);
  // documents
  yield takeLatest(A.getOrderXMLRequest, getOrderXMLRequest);
  yield takeLatest(A.printDocumentRequest, printDocumentRequest);
  yield takeLatest(A.removeOrderDocumentRequest, removeOrderDocumentRequest);
  yield takeLatest(A.updateOrderDocumentRequest, updateOrderDocumentRequest);
  yield takeLatest(A.createOrderDocumentRequest, createOrderDocumentRequest);
  yield takeLatest(A.printLoadDocumentsRequest, handlePrintLoadDocumentsSaga);
  yield takeLatest(A.getOrderDocumentListRequest, getOrderDocumentListRequest);
  yield takeLatest(A.downloadOrderDocumentRequest, downloadOrderDocumentRequest);
  yield takeLatest(A.printDispatchDocumentRequest, printDispatchDocumentRequest);
  // mails
  yield takeLatest(createMailSendingSuccess, getOrderMailListRequest);
  yield takeLatest(A.getOrderMailListRequest, getOrderMailListRequest);
  // message center
  yield takeLatest(A.updateOrderNoteRequest, updateOrderNoteRequest);
  yield takeLatest(A.removeOrderNoteRequest, removeOrderNoteRequest);
  yield takeLatest(A.createOrderNoteRequest, createOrderNoteRequest);
  yield takeLatest(A.getOrderNoteListRequest, getOrderNoteListRequest);
  yield takeLatest(A.updateOrderChatMessageRequest, updateOrderChatMessageRequest);
  yield takeLatest(A.removeOrderChatMessageRequest, removeOrderChatMessageRequest);
  yield takeLatest(A.changeMessageCenterActiveTab, changeMessageCenterActiveTab);
  yield takeLatest(A.createOrderChatMessageRequest, createOrderChatMessageRequest);
  yield takeLatest(A.getOrderChatMessageListRequest, getOrderChatMessageListRequest);
  // payments
  yield takeLatest(A.updateOrderBillToRequest, updateOrderBillToRequest);
  // appointment
  yield takeLatest(A.updateAppointmentRequest, handleUpdateAppointmentSaga);
  // related/linked loads
  yield takeLatest(A.deleteTelFromCloRequest, deleteTelFromCloSaga);
  yield takeLatest(A.getLinkedLoadListRequest, handleGetLinkedLoadListSaga);
  yield takeLatest(A.getRelatedLoadListRequest, handleGetRelatedLoadListSaga);
  // approve clo
  yield takeLatest(A.approveCloRequest, approveCloRequest);
  // claim
  yield takeLatest(A.removeClaimRequest, removeClaimRequest);
  yield takeLatest(A.getClaimDetailsRequest, getClaimDetailsRequest);
  yield takeLatest(A.createOrUpdateClaimRequest, createOrUpdateClaimRequest);
  yield takeLatest(A.getClaimGeneralDetailsRequest, getClaimGeneralDetailsRequest);
  // external system
  yield takeLatest(A.getExternalSystemListRequest, getExternalSystemListRequest);
  yield takeLatest(A.removeCloFromExternalSystemRequest, removeCloFromExternalSystemRequest);
}

export default orderDetailsNewWatcherSaga;
