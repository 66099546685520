import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Tabs } from '../../../components/tabs';
// features
import PC from '../../permission/role-permission';
import { makeSelectActiveTab } from '../selectors';
// utilities
import * as G from '../../../helpers';
// feature components story tabs
import { setActiveTab } from '../actions';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const tabStyles = {
  mr: '4px',
  height: 36,
  p: '4px 6px',
  fontSize: 24,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  bg: G.getTheme('colors.white'),
  color: G.getTheme('colors.dark.grey'),
  boxShadow: `2px -2px 5px -2px ${G.getTheme('colors.boxShadowGrey')}`,
};

const activeTabStyles = R.mergeRight(tabStyles, {
  color: G.getTheme('colors.black'),
  bg: G.getTheme('colors.lightLime'),
});

const countStyles = {
  mt: '4px',
  ml: '4px',
  fontSize: 18,
};

const TabsComponent = ({
  activeTab,
  setActiveTab,
}: Object) => {
  const tabs = [
    {
      text: 'Tab 1',
      withCount: true,
      key: 'tabs:tab1',
      permissions: [PC.ANY],
      isActive: R.equals(activeTab, 0),
      clickHandler: () => setActiveTab(0),
    },
    {
      text: 'Tab 2',
      withCount: false,
      key: 'tabs:tab2',
      permissions: [PC.ANY],
      isActive: R.equals(activeTab, 1),
      clickHandler: () => setActiveTab(1),
    },
    {
      text: 'Tab 3',
      withCount: false,
      key: 'tabs:tab3',
      permissions: [PC.ANY],
      isActive: R.equals(activeTab, 2),
      clickHandler: () => setActiveTab(2),
    },
    {
      text: 'Tab 4',
      withCount: false,
      key: 'tabs:tab4',
      permissions: [PC.ANY],
      isActive: R.equals(activeTab, 3),
      clickHandler: () => setActiveTab(3),
    },
  ];

  const description = '<Tabs /> component is used to switch between different routes.';

  const usage = (
    <ul>
      <li>You can specify `<i>route</i>` field as `<i>routesMap.someRoute`</i></li>
      <li>Or use `<i>clickHandler</i>` to do something when tab is clicked</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      name='Tabs'
      usage={usage}
      path='src/components/tabs'
      description={description}
    >
      <Tabs
        count={2}
        tabs={tabs}
        tabStyles={tabStyles}
        countStyles={countStyles}
        activeTab={tabs[activeTab]}
        activeTabStyles={activeTabStyles}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  activeTab: makeSelectActiveTab(state),
}));

export default connect(mapStateToProps, {
  setActiveTab,
})(TabsComponent);
