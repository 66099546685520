import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature dashboards
import * as A from './actions';
import { makeSelectDashboardsStore } from './selectors';
//////////////////////////////////////////////////

function* getDashboardRequest() {
  try {
    yield put(openLoader({ showDimmer: true }));

    yield put(A.getNormalizedCurrencyRequest());

    const store = yield select(makeSelectDashboardsStore());

    const { filters, dashboardType } = store;
    const { dateTo, dateFrom, enterprise } = filters;

    const options = {
      data: {
        dateTo,
        dateFrom,
        type: dashboardType,
        enterpriseGuid: R.or(enterprise, G.getAmousCurrentBranchGuidFromWindow()),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.statisticDashboard, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const dashboard = R.indexBy(R.prop('chartType'), data);

      yield put(A.getDashboardSuccess(dashboard));
    } else {
      yield call(G.handleFailResponse, res, 'getDashboardRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getDashboardRequest exception');
  }
}

function* getChartByFiltersRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { chartType, numberRange } = payload;

    const store = yield select(makeSelectDashboardsStore());

    const { filters, dashboardType } = store;

    const { dateTo, dateFrom, enterprise } = filters;

    const options = {
      data: {
        dateTo,
        dateFrom,
        type: dashboardType,
        enterpriseGuid: R.or(enterprise, G.getAmousCurrentBranchGuidFromWindow()),
        //
        chartType,
        numberRange,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.statisticDashboardChart, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getChartByFiltersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getChartByFiltersRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getChartByFiltersRequest exception');
  }
}

function* generateExcelWithChartData({ payload }: Object) {
  try {
    yield put(openLoader());

    const store = yield select(makeSelectDashboardsStore());

    const { filters, dashboardType } = store;
    const { dateTo, dateFrom, enterprise } = filters;

    const options = {
      resType: 'arraybuffer',
      data: {
        dateTo,
        dateFrom,
        numberRange: null,
        chartType: payload,
        type: dashboardType,
        enterpriseGuid: R.or(enterprise, G.getAmousCurrentBranchGuidFromWindow()),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.statisticDashboardChartExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'generateExcelWithChartData fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'generateExcelWithChartData exception');
  }
}

function* getNormalizedCurrencyRequest() {
  try {
    const store = yield select(makeSelectDashboardsStore());

    const { filters, normalizedCurrency } = store;

    const { enterprise } = filters;

    const enterpriseGuid = R.or(enterprise, G.getAmousCurrentBranchGuidFromWindow());

    if (G.isNotNilAndNotEmpty(G.getPropFromObject(enterpriseGuid, normalizedCurrency))) return;

    const options = { params: { enterpriseGuid } };

    const res = yield call(sendRequest, 'get', endpointsMap.branchNormalizedCurrency, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getNormalizedCurrencySuccess({ enterpriseGuid, data: R.or(data, GC.DEFAULT_UI_CURRENCY) }));
    } else {
      yield call(G.handleFailResponse, res, 'getNormalizedCurrencyRequest fail', true);
    }
  } catch (error) {
    yield call(G.handleException, error, 'getNormalizedCurrencyRequest exception');
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DASHBOARDS_PAGE);

    yield put(A.getDashboardRequest());

    break;
  }
}

export function* dashboardWatcherSaga() {
  yield takeLatest(GC.VISIT_DASHBOARDS_PAGE, handleVisitPageSaga);
  //
  yield takeLatest(A.getDashboardRequest, getDashboardRequest);
  yield takeLatest(A.setDateFiltersToStore, getDashboardRequest);
  yield takeLatest(A.setDashboardTypeToStore, getDashboardRequest);
  yield takeLatest(A.setEnterpriseFilterToStore, getDashboardRequest);
  yield takeLatest(A.getChartByFiltersRequest, getChartByFiltersRequest);
  yield takeLatest(A.generateExcelWithChartData, generateExcelWithChartData);
  yield takeLatest(A.getNormalizedCurrencyRequest, getNormalizedCurrencyRequest);
}

export default dashboardWatcherSaga;
