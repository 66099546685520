import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const createAccessorialRequest = createAction('createAccessorialRequest');
export const createAccessorialSuccess = createAction('createAccessorialSuccess');
export const updateAccessorialRequest = createAction('updateAccessorialRequest');
export const updateAccessorialSuccess = createAction('updateAccessorialSuccess');
export const deleteAccessorialRequest = createAction('deleteAccessorialRequest');
export const deleteAccessorialSuccess = createAction('deleteAccessorialSuccess');
export const getAccessorialForConfigRequest = createAction('getAccessorialForConfigRequest');
export const getAccessorialForConfigSuccess = createAction('getAccessorialForConfigSuccess');
export const returnInheritedAccessorialsSuccess = createAction('returnInheritedAccessorialsSuccess');
export const createDistanceCalculatorConfigRequest = createAction('createDistanceCalculatorConfigRequest');
export const updateDistanceCalculatorConfigRequest = createAction('updateDistanceCalculatorConfigRequest');
export const updateDistanceCalculatorConfigSuccess = createAction('updateDistanceCalculatorConfigSuccess');
export const removeDistanceCalculatorConfigRequest = createAction('removeDistanceCalculatorConfigRequest');
export const getDistanceCalculatorConfigListRequest = createAction('getDistanceCalculatorConfigListRequest');
export const getDistanceCalculatorConfigListSuccess = createAction('getDistanceCalculatorConfigListSuccess');
export const getStatusMessagesForGeneralConfigSuccess = createAction('getStatusMessagesForGeneralConfigSuccess');
// load custom status
export const removeLoadCustomStatusRequest = createAction('removeCustomLoadStatusRequest');
export const removeLoadCustomStatusSuccess = createAction('removeLoadCustomStatusSuccess');
export const getLoadCustomStatusListRequest = createAction('getLoadCustomStatusListRequest');
export const getLoadCustomStatusListSuccess = createAction('getLoadCustomStatusListSuccess');
export const createOrUpdateLoadCustomStatusRequest = createAction('createOrUpdateLoadCustomStatusRequest');
export const createOrUpdateLoadCustomStatusSuccess = createAction('createOrUpdateLoadCustomStatusSuccess');
// transportation mode grouping
export const removeTransportationModeGroupingRequest = createAction('removeTransportationModeGroupingRequest');
export const removeTransportationModeGroupingSuccess = createAction('removeTransportationModeGroupingSuccess');
export const getTransportationModeGroupingListRequest = createAction('getTransportationModeGroupingListRequest');
export const getTransportationModeGroupingListSuccess = createAction('getTransportationModeGroupingListSuccess');
export const createOrUpdateTransportationModeGroupingSuccess =
  createAction('createOrUpdateTransportationModeGroupingSuccess');
export const createOrUpdateTransportationModeGroupingRequest =
  createAction('createOrUpdateTransportationModeGroupingRequest');
