import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    confirmHandler: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const modalContent = getConfirmModal({
        withComment: true,
        cancelAction: closeModal,
        submitAction: closeModal,
        name: 'additional name or label',
        text: 'message for confirm modal...',
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
      });

      openModal(modalContent);
    },
  }),
  pure,
);

const ConfirmComponent = (props: Object) => {
  const description = '<ConfirmModal /> component is designed to provide a flexible confirmation modal with options for including a comment input field.';

  const usage = (
    <React.Fragment>
      <p>Use this component through a function `<i>getConfirmModal()</i>`</p>
      <ul>
        <li>Parameter `<i>cancelText</i>` is the text displayed on the confirmation modal cancel button.</li>
        <li>Parameter `<i>submitText</i>` is the text displayed on the confirmation modal submit button.</li>
        <li>Parameter `<i>name</i>` is an additional name or label to be included in the confirmation message.</li>
        <li>Parameter `<i>text</i>` is a main text message or content to be displayed in the confirmation modal.</li>
        <li>Parameter `<i>cancelAction</i>` is a callback function that is executed when the user clicks the cancel button.</li>
        <li>Parameter `<i>submitAction</i>` is a callback function that is executed when the user clicks the submit button.</li>
        <li>Parameter `<i>withComment</i>` is a boolean flag that determines whether to include a comment input field in the confirmation modal.</li>
      </ul>
    </React.Fragment>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ConfirmModal'
      description={description}
      path='src/components/confirm'
    >
      <CustomButton onClick={props.confirmHandler}>Open confirm modal</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(ConfirmComponent));
