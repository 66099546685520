import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-list
import { generateIftaRequest } from '../actions';
//////////////////////////////////////////////////

const defaultStyles = {
  width: 300,
  zIndex: 12,
  afterTop: 14,
  iconColor: '',
  errorLeft: 10,
  errorTop: '110%',
  isRequired: true,
  isClearable: true,
  labelWidth: '100%',
  timeSelection: false,
  flexDirection: 'column',
  errorPosition: 'absolute',
};

export const iftaFields = [
  {
    ...defaultStyles,
    type: 'datePicker',
    loc: 'titles:date-from',
    fieldName: GC.FIELD_IFTA_DATE_FROM,
  },
  {
    ...defaultStyles,
    type: 'datePicker',
    loc: 'titles:date-to',
    maxDate: G.getCurrentDate(),
    fieldName: GC.FIELD_IFTA_DATE_TO,
  },
];

export const defaultIftaFields = {
  [GC.FIELD_IFTA_DATE_TO]: null,
  [GC.FIELD_IFTA_DATE_FROM]: null,
};

export const validationSchemaIftaForm = Yup.object().shape({
  [GC.FIELD_IFTA_DATE_TO]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_IFTA_DATE_FROM]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const enhance = compose(
  connect(null, { generateIftaRequest }),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaIftaForm,
    handleSubmit: (values: Object, { props }: Object) =>
      props.generateIftaRequest({ data: values, byReport: props.byReport }),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultIftaFields,
      initialValues,
    ),
  }),
  pure,
);

const IftaForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent {...G.getFormikProps(props)} fields={iftaFields} />
    <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={props.closeModal} />
  </form>
);

export default enhance(IftaForm);
