import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, RelativeBox, AbsoluteBox } from '../../../ui';
// feature configs
import { setConfigWarning } from '../actions';
//////////////////////////////////////////////////

const errorColor = G.getTheme('colors.light.mainRed');
const mainLightColor = G.getTheme('colors.light.mainLight');

const ConfigWarning = (props: Object) => {
  const { tabName, configGroup, configWarning, setConfigWarning } = props;

  const message = R.pathOr(null, [tabName], configWarning);

  if (G.isNilOrEmpty(message)) return null;

  const cleanConfigWarning = () => {
    setConfigWarning({ tabName, configGroup, message: null });
  };

  return (
    <RelativeBox
      p={15}
      my='5px'
      bg={errorColor}
      borderRadius='4px'
      justifyContent='center'
    >
      <AbsoluteBox
        top={10}
        right={10}
        cursor='pointer'
        onClick={cleanConfigWarning}
      >
        {I.closeIcon(mainLightColor, 15, 15)}
      </AbsoluteBox>
      <Box color={mainLightColor}>
        {message}
      </Box>
    </RelativeBox>
  );
};

export default connect(null, { setConfigWarning })(ConfigWarning);
