import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-profile
export * from './truck';
export * from './driver';
export * from './vendor';
export * from './trailer';
//////////////////////////////////////////////////

const store = ({ fleetProfile }: Object) => fleetProfile;

// common
const makeSelectCollapsedGroup = () => createSelector(
  store,
  R.pathOr({}, ['collapsedGroup']),
);

const makeSelectGeneralDetails = () => createSelector(
  store,
  ({ type, ...state }: Object) => R.path([type, 'generalDetails'], state),
);

const selectGeneralDetails = createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'generalDetails'], fleetProfile),
  (generalDetails: Object) => generalDetails,
);

const makeSelectPrimaryObjectGuid = () => createSelector(
  store,
  (state: Object) => {
    const { type } = state;

    return R.path([type, 'generalDetails', GC.FIELD_GUID], state);
  },
);

const makeSelectEntityBranchGuid = () => createSelector(
  store,
  (state: Object) => {
    const { type } = state;

    return R.path([type, 'generalDetails', GC.BRANCH_GUID], state);
  },
);

const makeSelectVinLookup = () => createSelector(
  store,
  R.prop('vinLookup'),
);

// driver assignment
const makeSelectAssignment = () => createSelector(
  store,
  R.path(['assignment']),
);

const makeSelectAssignedTruckGuid = () => createSelector(
  store,
  R.path(['assignment', GC.SYSTEM_OBJECT_TRUCK, GC.FIELD_GUID]),
);

const makeSelectAvailableAssignable = () => createSelector(
  store,
  R.path(['availableAssignable']),
);

// vendor
const makeSelectFleetVendorList = () => createSelector(
  store,
  R.path(['fleetVendorList']),
);

// configs
const makeSelectAccessorialConfigs = () => createSelector(
  store,
  R.path(['accessorialConfigs']),
);

const makeSelectBranchConfigs = () => createSelector(
  store,
  R.path(['branchConfigs']),
);

const makeSelectConfigGroup = () => createSelector(
  store,
  R.pathOr({}, ['configGroup']),
);

// report
const makeSelectListLoadingByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'loading'], fleetProfile),
  (loading: Object) => G.getPropFromObject(groupName, loading),
);

const makeSelectTableTitleFiltersByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'tableTitleFilters'], fleetProfile),
  (tableTitleFilters: Object) => G.getPropFromObject(groupName, tableTitleFilters),
);

const makeSelectTitleSortValuesByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'tableTitleSortValues'], fleetProfile),
  (tableTitleSortValues: Object) => G.getPropFromObject(groupName, tableTitleSortValues),
);

const makeSelectPaginationByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'pagination'], fleetProfile),
  (pagination: Object) => G.getPropFromObject(groupName, pagination),
);

const makeSelectTotalCountByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'totalCount'], fleetProfile),
  (totalCount: Object) => G.getPropFromObject(groupName, totalCount),
);

const makeSelectUsedReportByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'usedReport'], fleetProfile),
  (usedReport: Object) => G.getPropFromObject(groupName, usedReport),
);

const makeSelectAvailableReportsByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'availableReports'], fleetProfile),
  (availableReports: Object) => G.getPropFromObject(groupName, availableReports),
);

const makeSelectReportStatusByGroupName = (groupName: string) => createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'reportPending'], fleetProfile),
  (reportPending: Object) => G.getPropFromObject(groupName, reportPending),
);

// filters
const makeSelectDocumentsFilter = () => createSelector(
  store,
  (state: Object) => {
    const { type } = state;

    return R.pathOr('', [type, 'formGroupFilters', 'documentsFilter'], state);
  },
);

const selectFormGroupFilters = createSelector(
  ({ fleetProfile: { type, ...fleetProfile } }: Object) => R.path([type, 'formGroupFilters'], fleetProfile),
  (formGroupFilters: Object) => formGroupFilters,
);

export {
  // common
  makeSelectVinLookup,
  selectGeneralDetails,
  makeSelectConfigGroup,
  makeSelectCollapsedGroup,
  makeSelectGeneralDetails,
  makeSelectEntityBranchGuid,
  makeSelectPrimaryObjectGuid,
  // assignment
  makeSelectAssignment,
  makeSelectAssignedTruckGuid,
  makeSelectAvailableAssignable,
  // vendor
  makeSelectFleetVendorList,
  // accessorial configs
  makeSelectAccessorialConfigs,
  // branch configs
  makeSelectBranchConfigs,
  // report
  makeSelectPaginationByGroupName,
  makeSelectTotalCountByGroupName,
  makeSelectUsedReportByGroupName,
  makeSelectListLoadingByGroupName,
  makeSelectReportStatusByGroupName,
  makeSelectTitleSortValuesByGroupName,
  makeSelectAvailableReportsByGroupName,
  makeSelectTableTitleFiltersByGroupName,
  // filters
  selectFormGroupFilters,
  makeSelectDocumentsFilter,
};
