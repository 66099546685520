import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// features
import { withVendorPayrollAccessorial } from '../../fleet-profile/vendor/forms/payroll-compensation';
import {
  schema,
  defaultValues,
  fieldSettings,
  vendorFieldSettings,
} from '../../fleet-profile/settings/payroll-accessorial';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const validationSchema = ({ isVendor }: Object) => Yup.lazy((values: Object) => {
  const { limited, payForUnit } = values;

  return Yup.object().shape({
    ...schema(limited, payForUnit),
    [GC.FIELD_FLEET_VENDOR_GUID]: G.ifElse(isVendor, G.yupStringRequired),
    [GC.FIELD_DRIVER_GUID]: G.ifElse(R.not(isVendor), G.yupStringRequired),
  });
});

const formEnhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: R.propOr({}, 'initialValues'),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const data = G.mapObjectEmptyStringFieldsToNull(values);

      submitAction(data);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const { isVendor, values, setValues, getInitialDataRequest } = this.props;

      const { fleetVendorGuid } = values;

      if (R.not(isVendor)) return;

      const { values: { fleetVendorGuid: prevFleetVendorGuid } = {} } = prevProps;

      if (G.notEquals(fleetVendorGuid, prevFleetVendorGuid)) {
        const asyncOptions = {
          params: {
            vendorGuid: fleetVendorGuid,
            [GC.BRANCH_GUID]: G.ifElse(R.isNil(fleetVendorGuid), G.getAmousCurrentUserBranchGuidFromWindow()),
          },
        };

        getInitialDataRequest(endpointsMap.listFleetTrucks, asyncOptions);
        setValues(R.mergeRight(values, { [GC.FIELD_TRUCK_GUIDS]: null, [GC.FIELD_DRIVER_GUIDS]: null }));
      }
    },
  }),
  pure,
);

const fieldsWrapperStyles = {
  pt: 25,
  width: 870,
  overflow: 'auto',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxHeight: 'calc(100vh - 155px)',
};

const getFirstFields = (isVendor: boolean) => [
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'primaryObjectOptions',
    inputWrapperStyles: { width: 270 },
    disabled: (_: any, { version }: Object) => R.isNotNil(version),
    fieldName: G.ifElse(isVendor, GC.FIELD_FLEET_VENDOR_GUID, GC.FIELD_DRIVER_GUID),
    label: G.ifElse(
      isVendor,
      ['titles:select-vendor', 'Select Vendor'],
      ['titles:select-driver', 'Select Driver'],
    ),
    reactSelectAdditionalStyles: {
      menu: (baseStyles: Object) => ({
        ...baseStyles,
        zIndex: 15,
      }),
    },
  },
];

const Form = formEnhance((props: Object) => {
  const {
    isVendor,
    handleSubmit,
    fieldSettings,
    optionsForSelect,
    primaryObjectOptions,
    accessorialConfigOptions,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        fieldsWrapperStyles={{ pt: 15 }}
        fields={getFirstFields(isVendor)}
        primaryObjectOptions={primaryObjectOptions}
      />
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        {...optionsForSelect}
        fields={fieldSettings}
        fieldsWrapperStyles={fieldsWrapperStyles}
        accessorialConfigOptions={accessorialConfigOptions}
      />
      <FormFooter2 />
    </form>
  );
});

const VendorPayrollAccessorialForm = withVendorPayrollAccessorial(Form);

const PayrollAccessorialForm = (props: Object) => {
  const { isVendor } = props;

  if (isVendor) {
    return <VendorPayrollAccessorialForm {...props} fieldSettings={vendorFieldSettings} />;
  }

  return <Form {...props} fieldSettings={fieldSettings} />;
};

const enhance = compose(
  withState('primaryObjectOptions', 'setPrimaryDriverOptions', []),
  withState(
    'initialValues',
    'setInitialValues',
    ({ fleetVendorGuid = null }: Object) => ({
      ...defaultValues,
      fleetVendorGuid,
      [GC.FIELD_DRIVER_GUID]: null,
      [GC.FIELD_CURRENCY]: G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
    }),
  ),
  withHandlers({
    handleGetInitialValues: (props: Object) => async () => {
      const { guid, isVendor, openLoader, closeLoader, setInitialValues } = props;

      if (R.isNil(guid)) return;

      G.callFunction(openLoader);

      const endpointName = G.ifElse(isVendor, 'getVendorPayrollAccessorialEndpoint', 'getPayrollAssessorialEndpoint');
      const endpoint = R.prop(endpointName, endpointsMap)(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues(data);
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetInitialValues fail');
      }

      G.callFunction(closeLoader);
    },
    handleGetPrimaryObjectOptions: (props: Object) => async () => {
      const { isVendor, branchGuid, openLoader, closeLoader, setPrimaryDriverOptions } = props;

      G.callFunction(openLoader);

      const endpointName = G.ifElse(isVendor, 'fleetVendorList', 'listFleetDriversFullName');
      const endpoint = R.prop(endpointName, endpointsMap);

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
        },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        let options;

        if (isVendor) {
          options = G.mapNameGuidToLabelValue(data);
        } else {
          options = R.map(({ guid, loginId, lastName, firstName }: Object) => ({
            [GC.FIELD_VALUE]: guid,
            [GC.FIELD_LABEL]: `${firstName} ${lastName} (${loginId})`,
          }), R.or(data, []));
        }

        setPrimaryDriverOptions(options);
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetInitialValues fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleGetInitialValues, handleGetPrimaryObjectOptions } = this.props;

      handleGetInitialValues();
      handleGetPrimaryObjectOptions();
    },
  }),
);

export default enhance(PayrollAccessorialForm);
