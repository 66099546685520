import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// configs
export const getVendorConfigsRequest = createAction('getVendorConfigsRequest');
export const getVendorConfigsSuccess = createAction('getVendorConfigsSuccess');
// vendor
export const cleanFormStore = createAction('cleanFormStore');
export const updateVendorRequest = createAction('updateVendorRequest');
export const updateVendorSuccess = createAction('updateVendorSuccess');
export const createVendorRequest = createAction('createVendorRequest');
export const getVendorListRequest = createAction('getVendorListRequest');
export const getVendorListSuccess = createAction('getVendorListSuccess');
export const getFleetVendorSuccess = createAction('getFleetVendorSuccess');
export const toggleVendorFormGroup = createAction('toggleVendorFormGroup');
export const sendListToIntegrationRequest = createAction('sendListToIntegrationRequest');
export const sendListToIntegrationSuccess = createAction('sendListToIntegrationSuccess');
export const getVendorIntegrationListRequest = createAction('getVendorIntegrationListRequest');
export const getVendorIntegrationListSuccess = createAction('getVendorIntegrationListSuccess');
export const getDataForCompensationTabRequest = createAction('getDataForCompensationTabRequest');
export const getVendorIntegrationAuditListRequest = createAction('getVendorIntegrationAuditListRequest');
export const getVendorIntegrationAuditListSuccess = createAction('getVendorIntegrationAuditListSuccess');
// reference
export const updateVendorReferenceSuccess = createAction('updateVendorReferenceSuccess');
export const updateVendorReferenceRequest = createAction('updateVendorReferenceRequest');
export const createVendorReferenceSuccess = createAction('createVendorReferenceSuccess');
export const createVendorReferenceRequest = createAction('createVendorReferenceRequest');
export const deleteVendorReferenceRequest = createAction('deleteVendorReferenceRequest');
export const deleteVendorReferenceSuccess = createAction('deleteVendorReferenceSuccess');
export const getVendorReferenceListRequest = createAction('getVendorReferenceListRequest');
export const getVendorReferenceListSuccess = createAction('getVendorReferenceListSuccess');
// contact
export const createVendorContactRequest = createAction('createVendorContactRequest');
export const createVendorContactSuccess = createAction('createVendorContactSuccess');
export const updateVendorContactRequest = createAction('updateVendorContactRequest');
export const updateVendorContactSuccess = createAction('updateVendorContactSuccess');
export const removeVendorContactRequest = createAction('removeVendorContactRequest');
export const removeVendorContactSuccess = createAction('removeVendorContactSuccess');
export const getVendorContactListRequest = createAction('getVendorContactListRequest');
export const getVendorContactListSuccess = createAction('getVendorContactListSuccess');
// pay to location
export const getVendorPayToLocationRequest = createAction('getVendorPayToLocationRequest');
export const getVendorPayToLocationSuccess = createAction('getVendorPayToLocationSuccess');
export const removeVendorPayToLocationRequest = createAction('removeVendorPayToLocationRequest');
export const removeVendorPayToLocationSuccess = createAction('removeVendorPayToLocationSuccess');
export const createOrUpdateVendorPayToLocationRequest = createAction('createOrUpdateVendorPayToLocationRequest');
// insurance
export const createVendorInsuranceRequest = createAction('createVendorInsuranceRequest');
export const createVendorInsuranceSuccess = createAction('createVendorInsuranceSuccess');
export const updateVendorInsuranceRequest = createAction('updateVendorInsuranceRequest');
export const updateVendorInsuranceSuccess = createAction('updateVendorInsuranceSuccess');
export const removeVendorInsuranceRequest = createAction('removeVendorInsuranceRequest');
export const removeVendorInsuranceSuccess = createAction('removeVendorInsuranceSuccess');
export const getVendorInsuranceListRequest = createAction('getVendorInsuranceListRequest');
export const getVendorInsuranceListSuccess = createAction('getVendorInsuranceListSuccess');
// payroll accessorial
export const removePayrollAssessorialRequest = createAction('removePayrollAssessorialRequest');
export const togglePayrollAssessorialDetails = createAction('togglePayrollAssessorialDetails');
export const getPayrollAssessorialListRequest = createAction('getPayrollAssessorialListRequest');
export const getPayrollAssessorialListSuccess = createAction('getPayrollAssessorialListSuccess');
export const createOrUpdatePayrollAssessorialRequest = createAction('createOrUpdatePayrollAssessorialRequest');
export const getPayrollAccessorialIsPaidOnPayrollRequest =
  createAction('getPayrollAccessorialIsPaidOnPayrollRequest');
export const getPayrollAccessorialIsPaidOnPayrollSuccess =
  createAction('getPayrollAccessorialIsPaidOnPayrollSuccess');
export const getPayrollChargesByPayrollAccessorialRequest =
  createAction('getPayrollChargesByPayrollAccessorialRequest');
export const getPayrollChargesByPayrollAccessorialSuccess =
  createAction('getPayrollChargesByPayrollAccessorialSuccess');
// accessorials
export const removeVendorAccessorialRequest = createAction('removeVendorAccessorialRequest');
export const removeVendorAccessorialSuccess = createAction('removeVendorAccessorialSuccess');
export const updateVendorAccessorialRequest = createAction('updateVendorAccessorialRequest');
export const updateVendorAccessorialSuccess = createAction('updateVendorAccessorialSuccess');
export const createVendorAccessorialRequest = createAction('createVendorAccessorialRequest');
export const createVendorAccessorialSuccess = createAction('createVendorAccessorialSuccess');
export const getVendorAccessorialListRequest = createAction('getVendorAccessorialListRequest');
export const getVendorAccessorialListSuccess = createAction('getVendorAccessorialListSuccess');
// compensation
export const createVendorCompensationRequest = createAction('createVendorCompensationRequest');
export const createVendorCompensationSuccess = createAction('createVendorCompensationSuccess');
export const updateVendorCompensationRequest = createAction('updateVendorCompensationRequest');
export const updateVendorCompensationSuccess = createAction('updateVendorCompensationSuccess');
export const removeVendorCompensationRequest = createAction('removeVendorCompensationRequest');
export const removeVendorCompensationSuccess = createAction('removeVendorCompensationSuccess');
export const getVendorCompensationListRequest = createAction('getVendorCompensationListRequest');
export const getVendorCompensationListSuccess = createAction('getVendorCompensationListSuccess');
// documents
export const createVendorDocumentRequest = createAction('createVendorDocumentRequest');
export const createVendorDocumentSuccess = createAction('createVendorDocumentSuccess');
export const updateVendorDocumentRequest = createAction('updateVendorDocumentRequest');
export const updateVendorDocumentSuccess = createAction('updateVendorDocumentSuccess');
export const removeVendorDocumentRequest = createAction('removeVendorDocumentRequest');
export const removeVendorDocumentSuccess = createAction('removeVendorDocumentSuccess');
export const getVendorDocumentListRequest = createAction('getVendorDocumentListRequest');
export const getVendorDocumentListSuccess = createAction('getVendorDocumentListSuccess');
export const downloadVendorDocumentRequest = createAction('downloadVendorDocumentRequest');
// assignment
export const assignVendorRequest = createAction('assignVendorRequest');
export const assignVendorSuccess = createAction('assignVendorSuccess');
export const unAssignVendorRequest = createAction('unAssignVendorRequest');
export const unAssignVendorSuccess = createAction('unAssignVendorSuccess');
export const getVendorAssignmentRequest = createAction('getVendorAssignmentRequest');
export const assignVendorToDriverRequest = createAction('assignVendorToDriverRequest');
// fleet assignment
export const changeFleetAssignmentRequest = createAction('changeFleetAssignmentRequest');
export const changeFleetAssignmentSuccess = createAction('changeFleetAssignmentSuccess');
export const getFleetAssignmentListRequest = createAction('getFleetAssignmentListRequest');
export const getFleetAssignmentListSuccess = createAction('getFleetAssignmentListSuccess');
// table title filtering
export const setTableTitleSort = createAction('setTableTitleSort');
export const setTableTitleFilter = createAction('setTableTitleFilter');
// branch configs
export const getBranchConfigsByNamesRequest = createAction('getBranchConfigsByNamesRequest');
export const getBranchConfigsByNamesSuccess = createAction('getBranchConfigsByNamesSuccess');
// monthly payroll deduction
export const getMonthlyPayrollDeductionRequest = createAction('getMonthlyPayrollDeductionRequest');
export const getMonthlyPayrollDeductionSuccess = createAction('getMonthlyPayrollDeductionSuccess');
export const deleteMonthlyPayrollDeductionRequest = createAction('deleteMonthlyPayrollDeductionRequest');
export const toggleMonthlyPayrollDeductionDetails = createAction('toggleMonthlyPayrollDeductionDetails');
export const createOrUpdateMonthlyPayrollDeductionRequest =
  createAction('createOrUpdateMonthlyPayrollDeductionRequest');
export const getVendorPayrollChargesIsPaidOnPayrollRequest =
  createAction('getVendorPayrollChargesIsPaidOnPayrollRequest');
export const getVendorPayrollChargesIsPaidOnPayrollSuccess =
  createAction('getVendorPayrollChargesIsPaidOnPayrollSuccess');
export const getVendorPayrollChargesByMonthlyPaymentRequest =
  createAction('getVendorPayrollChargesByMonthlyPaymentRequest');
export const getVendorPayrollChargesByMonthlyPaymentSuccess =
  createAction('getVendorPayrollChargesByMonthlyPaymentSuccess');
