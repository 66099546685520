import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature carrier-profile
import Form from '../forms';
import Terminal from '../components/terminal';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

const getProcessIcon = (process: string) => {
  let iconName = 'uiFalse';

  if (R.includes(process, ['API', 'EDI', 'INTERNAL'])) iconName = 'uiTrue';

  if (R.equals(process, 'EMAIL')) iconName = 'mailengIcon';

  return I[iconName]();
};

const fields = {
  [GC.FIELD_PRIMARY]: {
    width: 100,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:primary',
    additionalInputWrapperStyles: { width: 113 },
    customComponent: (row: Object) => {
      if (G.isFalse(R.pathOr(false, ['data', GC.FIELD_PRIMARY], row))) return null;

      return (
        <Box fontWeight='bold' color='light.green'>
          {G.getWindowLocale('titles:primary', 'Primary')}
        </Box>
      );
    },
  },
  [GC.FIELD_ACTIVE]: {
    width: 100,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:active',
    additionalInputWrapperStyles: { width: 113 },
  },
  [GC.FIELD_NAME]: {
    isRequired: true,
    name: 'titles:terminal-name',
  },
  [GC.FIELD_LOCATION_TYPE]: {
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:location-type',
    options: GC.TEMPLATES_LOCATION_TYPE,
  },
  [GC.FIELD_LOCATION_NAME]: {
    isRequired: true,
    name: 'titles:location-name',
  },
  [GC.FIELD_ADDRESS]: {
    width: 250,
    isRequired: true,
    name: 'titles:address',
    type: 'addressAutocomplete',
    addressFieldName: GC.FIELD_ADDRESS,
  },
  [GC.FIELD_ADDRESS_2]: {
    type: 'text',
    name: 'titles:address2',
    addressFieldName: GC.FIELD_ADDRESS,
    additionalInputWrapperStyles: { mr: 546 },
  },
  [GC.FIELD_CITY]: {
    width: 150,
    isRequired: true,
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 150,
    isRequired: true,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    isRequired: true,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    isRequired: true,
    type: 'countrySelect',
    name: 'titles:country',
  },
  [GC.FIELD_LATITUDE]: {
    width: 100,
    disabled: true,
    name: 'titles:latitude',
  },
  [GC.FIELD_LONGITUDE]: {
    width: 100,
    disabled: true,
    name: 'titles:longitude',
    additionalInputWrapperStyles: { mr: 546 },
  },
  [GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS]: {
    width: 120,
    name: 'titles:bid-process',
    customComponent: (row: Object) =>
      getProcessIcon(R.path(['data', GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS, GC.FIELD_TYPE], row)),
  },
  [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]: {
    width: 120,
    name: 'titles:status-check-process',
    customComponent: (row: Object) =>
      getProcessIcon(R.path(['data', GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS, GC.FIELD_TYPE], row)),
  },
  [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: {
    name: 'titles:dispatch-process',
    width: 120,
    customComponent: (row: Object) =>
      getProcessIcon(R.path(['data', GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE], row)),
  },
};

const schema = {
  [GC.FIELD_ZIP]: G.yupStringRequired,
  [GC.FIELD_CARRIER_TERMINAL_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  [GC.FIELD_LOCATION_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(255, G.getShouldBeFromToCharLocaleTxt(1, 255)),
  [GC.FIELD_LOCATION_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  [GC.FIELD_ADDRESS]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(255, G.getShouldBeFromToCharLocaleTxt(1, 255)),
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToCharLocaleTxt(1, 100)),
};

const validationSchema = Yup.object().shape(schema);

const updateTerminalValidationSchema = Yup.lazy((values: Object) => {
  const dispatchProcessType = R.path([GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE], values);
  const statusCheckProcessType = R.path([GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS, GC.FIELD_TYPE], values);

  return Yup.object().shape({
    ...schema,
    [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: Yup.object()
      .nullable(true)
      .shape({
        [GC.FIELD_INTEGRATION_CONFIG_GUID]: G.ifElse(
          R.equals(dispatchProcessType, GC.TERMINAL_PROCESS_TYPE_API),
          G.yupStringRequired,
          G.yupNumberNotRequired,
        ),
      }),
    [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]: Yup.object()
      .nullable(true)
      .shape({
        [GC.FIELD_TERMINAL_STATUS_CHECK_FREQUENCY]: G.yupNumberNotRequired.min(0, G.getShouldBePositiveLocaleTxt()),
        [GC.FIELD_INTEGRATION_CONFIG_GUID]: G.ifElse(
          R.equals(statusCheckProcessType, GC.TERMINAL_PROCESS_TYPE_API),
          G.yupStringRequired,
          G.yupNumberNotRequired,
        ),
      }),
  });
});

export const terminalSettings = {
  fields,
  groupName: 'terminal',
  useGetListRequest: true,
  omitColumns: [GC.FIELD_LOCATION_TYPE],
  itemTitleArr: ['titles:terminal', 'Terminal'],
  configOptionNames: [GC.TEMPLATES_LOCATION_TYPE],
  additionalTableSettings: { checkBoxCellWidth: 100 },
  formGroupTitleArr: ['titles:terminals', 'Terminals'],
  actionsPicker: ['edit', 'remove', 'terminalProcess', 'terminalFinance'],
  endpoints: {
    list: 'carrierTerminalList',
    createOrUpdate: 'carrierTerminal',
    remove: 'getCarrierTerminalEndpoint',
  },
  // components
  CustomForm: (props: Object) => (
    R.isNotNil(R.path(['initialValues', GC.FIELD_VERSION], props)) ? (
      <Terminal {...props} validationSchema={updateTerminalValidationSchema} />
    ) : (
      <Form
        {...props}
        fields={fields}
        validationSchema={validationSchema}
        fieldsWrapperStyles={{ width: 1070 }}
        inputWrapperStyles={inputWrapperStyles}
        configOptionNames={[GC.TEMPLATES_LOCATION_TYPE]}
        omitFormFields={[
          GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS,
          GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS,
          GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS,
        ]}
      />
    )
  ),
  // helpers
  modalAdditionalOptions: (isEditMode: boolean) => G.ifElse(
    isEditMode,
    {
      p: '0px',
      options: { title: null },
    },
  ),
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    [GC.TEMPLATES_LOCATION_TYPE]: R.path(['dropdownOptions', GC.TEMPLATES_LOCATION_TYPE], configGroup),
  }),
  makeInitialValues: (initialValues: Object = {}) => ({
    ...initialValues,
    [GC.FIELD_LOCATION_TYPE]: R.path(
      [GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
      initialValues,
    ),
  }),
};
