import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { visitPageSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import { getDriverPayrollStatusesSaga } from '../invoice/sagas';
import { getConfigByGroupSaga, getStatusMessagesConfigSaga, getReferenceTypesByScopeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

function* getStatusMessageCodesRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.statusMessagesListEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getStatusMessageCodesSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getStatusMessageCodesRequest');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getStatusMessageCodesRequest');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown exception');
  }
}

function* getBranchCarrierMobileEnabledRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchCarrierMobileEnabled, options);

    const { data, status } = res;

    if (R.and(G.isResponseSuccess(status), G.isFalse(data))) {
      const warning = {
        configGroup: GC.MOBILEAPP_CONFIG_GROUP,
        tabName: GC.MOBILEAPP_CONFIG_TAB_NAME_CARRIER,
        message: G.getWindowLocale(
          'messages:ask-to-activate-mobile-app',
          'Please ask your admin to activate the carrier mobile app',
        ),
      };

      yield put(A.setConfigWarning(warning));
    } else {
      yield call(G.handleException, 'error', 'getBranchCarrierMobileEnabledRequest');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchCarrierMobileEnabledRequest');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown exception');
  }
}

export function* handleVisitConfigMobileAppPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_MOBILEAPP_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.getAvailableDocumentTypes());

    yield call(getStatusMessageCodesRequest);
    yield call(getStatusMessagesConfigSaga);
    yield call(getConfigByGroupSaga, GC.MOBILEAPP_CONFIG_GROUP);
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_TEL });
    yield call(getDriverPayrollStatusesSaga);
    yield call(getBranchCarrierMobileEnabledRequest);

    yield put(closeLoader());

    break;
  }
}

export function* riverConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_MOBILEAPP_PAGE, handleVisitConfigMobileAppPageSaga);
}

export default riverConfigWatcherSaga;
