import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { PageActions } from '../../components/page-actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, PageWrapper, ZOrderWrapper } from '../../ui';
// feature dispatch-group
import ItemFrom from './components/item-form';
import RowActions from './components/row-actions';
import { tableSettings } from './settings/table-settings';
import { report, columnSettings } from './settings/column-settings';
import { makeSelectItemList, makeSelectIndexedList } from './selectors';
import {
  toggleDispatchGroupDetails,
  createDispatchGroupRequest,
  deleteDispatchGroupRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withHandlers({
    handleCreateDispatchGroup: (props: Object) => () => {
      const { openModal, closeModal, createDispatchGroupRequest } = props;

      const component = <ItemFrom closeModal={closeModal} submitAction={createDispatchGroupRequest} />;

      const modal = {
        p: 15,
        component,
        options: {
          width: 740,
          title: G.getWindowLocale('titles:dispatch-group', 'Dispatch Group'),
        },
      };

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (event: Object, group: Object) => {
      const {
        openModal,
        closeModal,
        branchGuid,
        openFixedPopup,
        closeFixedPopup,
        createDispatchGroupRequest,
        deleteDispatchGroupRequest,
      } = props;

      openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <RowActions
            initialValues={group}
            openModal={openModal}
            closeModal={closeModal}
            branchGuid={branchGuid}
            closeFixedPopup={closeFixedPopup}
            deleteAction={deleteDispatchGroupRequest}
            updateAction={createDispatchGroupRequest}
          />
        ),
      });
    },
  }),
  pure,
);

const renderRowActions = (group: Object, branchGuid: string, handleClickEditIcon: Function) => G.ifElse(
  R.propEq(branchGuid, GC.BRANCH_GUID, group),
  <AuthWrapper has={[PC.FLEET_DRIVER_WRITE]}>
    <Box px={12} cursor='pointer' onClick={(event: Object) => handleClickEditIcon(event, group)}>
      {I.threeDots()}
    </Box>
  </AuthWrapper>,
  null,
);

const DispatchGroupList = (props: Object) => {
  const {
    itemList,
    branchGuid,
    handleClickEditIcon,
    handleCreateDispatchGroup,
    toggleDispatchGroupDetails,
  } = props;

  return (
    <PageWrapper pt={15} pr={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          withCount={true}
          filterProps={[]}
          noEditReport={true}
          noExportable={true}
          hideFilterInfo={true}
          popperWithCount={true}
          noSelectDropdown={true}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={false}
          totalCount={R.length(itemList)}
          title={G.getWindowLocale('titles:dispatch-group', 'Dispatch Group')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <Table
          report={report}
          loading={false}
          itemList={itemList}
          tableSettings={tableSettings}
          columnSettings={columnSettings}
          toggle={toggleDispatchGroupDetails}
          renderRightStickedComponent={(group: Object) =>
            renderRowActions(group, branchGuid, handleClickEditIcon)
          }
        />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.FLEET_DRIVER_WRITE]}>
        <PageActions
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            hideIfBranchTypeCustomer: true,
            action: handleCreateDispatchGroup,
            text: G.getWindowLocale('actions:add-dispatch-group', 'Add Dispatch Group'),
          }}
        />
      </AuthWrapper>
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  indexedList: makeSelectIndexedList(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  toggleDispatchGroupDetails,
  createDispatchGroupRequest,
  deleteDispatchGroupRequest,
})(enhance(DispatchGroupList));
