import * as R from 'ramda';
import styled from 'styled-components';
import { width, space, fontSize, lineHeight } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// forms
import { FormGroupTitle } from '../../forms';
//////////////////////////////////////////////////

export const renderBorderColor = ({ hasError }: Object) => G.getTheme(
  G.ifElse(
    hasError,
    'forms.inputs.borderColorErr',
    'forms.inputs.borderColor',
  ),
);

export const Option = styled.div`
  padding: 10px;

  &:hover {
    background: ${() => G.getTheme('colors.light.lightGrey')};
  }
`;

export const SimpleWrapper = styled.div`
  ${width}
  ${space}

  transition: width 0.2s linear;

  border: ${({ border }: Object) => border};
  height: ${({ height }: Object) => height};
  justify-content: ${({ jc }: Object) => jc};
  z-index: ${({ zIndex }: Object) => zIndex};
  min-width: ${({ minWidth }: Object) => minWidth};
  max-width: ${({ maxWidth }: Object) => maxWidth};
  min-height: ${({ minHeight }: Object) => minHeight};
  background-color: ${({ bgColor }: Object) => bgColor};
  white-space: ${({ whiteSpace }: Object) => whiteSpace};
  cursor: ${({ cursor }: Object) => R.or(cursor, 'default')};
  display: ${({ display }: Object) => R.or(display, 'flex')};
  flex-direction: ${({ flexDirection }: Object) => flexDirection};
  align-items: ${({ alignItems }: Object) => R.or(alignItems, 'center')};
`;


export const Text = styled.div`
  ${width}
  ${space}
  ${fontSize}
  ${lineHeight}

  color: ${({ color }: Object) => color};
  font-weight: ${({ fontWeight }: Object) => fontWeight};
`;

export const CustomFormGroupTitle = styled(FormGroupTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & #dropdown-branch-filter {
    height: 20px;
  }
  & #dropdown-branch-filter + div {
    color: ${() => G.getTheme('downFilter.textColor')};
  }
  & div.react-datepicker-popper {
    z-index: 12;
    left: auto !important;
    right: -15px !important;
    &[data-placement^="bottom"] {
      width: 300px !important;
    }
  }
`;
