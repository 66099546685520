import React from 'react';
import * as R from 'ramda';
// components
import { Label } from '../../../../components/label';
// utilities
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import { truckIntegrationTypeOptions } from '../../settings/fields-settings';
//////////////////////////////////////////////////

export const insuranceColumns = {
  insuranceType: {
    width: 200,
    name: 'titles:insurance-type',
  },
  effectiveDate: {
    width: 200,
    name: 'titles:effective-date',
  },
  expirationDate: {
    width: 200,
    name: 'titles:expiration-date',
  },
  description: {
    width: 600,
    name: 'titles:description',
  },
  paymentInterval: {
    width: 200,
    name: 'titles:payment-interval',
  },
  paymentAmount: {
    width: 150,
    name: 'titles:payment',
  },
};

export const docsSettings = {
  documentType: {
    width: 150,
    name: 'titles:type',
  },
  status: {
    width: 150,
    name: 'titles:status',
  },
  description: {
    width: 400,
    name: 'titles:description',
  },
  signedDate: {
    width: 150,
    name: 'titles:signed-date',
  },
  expirationDate: {
    width: 150,
    name: 'titles:expired-date',
  },
  documentFilename: {
    width: 300,
    name: 'titles:file',
  },
  url: {
    width: 300,
    name: 'titles:url',
    label: ({ url }: Object) =>
      <a target='_blank' href={G.makeURLString(url)}>{url}</a>, // eslint-disable-line
  },
};

export const locationSettings = {
  locationType: {
    width: 150,
    name: 'titles:type',
  },
  templateId: {
    width: 150,
    name: 'titles:template-id',
  },
  locationName: {
    width: 150,
    name: 'titles:name',
  },
  address1: {
    width: 150,
    name: 'titles:address1',
  },
  address2: {
    width: 150,
    name: 'titles:address2',
  },
  city: {
    width: 150,
    name: 'titles:city',
  },
  state: {
    width: 150,
    name: 'titles:state',
  },
  zip: {
    width: 150,
    name: 'titles:zip',
  },
  country: {
    width: 150,
    name: 'titles:country',
  },
  latitude: {
    width: 150,
    name: 'titles:latitude',
  },
  longitude: {
    width: 150,
    name: 'titles:longitude',
  },
  residential: {
    width: 150,
    name: 'titles:residential',
    label: (loc: Object) =>
      <Label frontLabel={G.ifElse(loc.residential, 'active', 'deactive')} />,
  },
  comments: {
    width: 150,
    name: 'titles:comments',
  },
};

export const componentColumns = {
  componentId: {
    width: 150,
    name: 'titles:component-id',
  },
  createdFromSharedComponent: {
    width: 150,
    name: 'titles:shared-component',
  },
  componentType: {
    width: 150,
    name: 'titles:type',
  },
  installDate: {
    width: 150,
    name: 'titles:installed-date',
  },
  replaceOrRemoveDate: {
    width: 150,
    name: 'titles:removed-date',
  },
  installed: {
    width: 100,
    name: 'titles:installed',
  },
  componentManufacturer: {
    width: 150,
    name: 'titles:manufacturer',
  },
  serialNumber: {
    width: 150,
    name: 'titles:serial-number',
  },
  specification: {
    width: 150,
    name: 'titles:specification',
  },
  warrantyExpiration: {
    width: 150,
    name: 'titles:warranty-expiration-date',
  },
};

export const integrationSamsaraColumnSettings = {
  [GC.FIELD_FLEET_EXTERNAL_ID]: {
    width: 150,
    name: 'titles:external-id',
  },
  [GC.FIELD_FLEET_TRANSPONDER_ID]: {
    width: 150,
    name: 'titles:transponder-id',
  },
  [GC.FIELD_FLEET_INTEGRATION_TYPE]: {
    width: 150,
    name: 'titles:integration-type',
    customLogic: (item: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(item, GC.FIELD_VALUE)),
    )(truckIntegrationTypeOptions),
  },
  [GC.FIELD_PRIMARY_DRIVER]: {
    width: 200,
    name: 'titles:primary-driver-external-id',
    customLogic: (item: Object) => {
      if (G.isNilOrEmpty(R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item))) return;
      const fullName = R.join('', [R.prop(GC.FIELD_FIRST_NAME, item), R.prop(GC.FIELD_LAST_NAME, item)]);
      return R.join('', [fullName, `(${R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item)})`]);
    },
  },
  [GC.FIELD_TEAM_DRIVER]: {
    width: 300,
    name: 'titles:team-driver-external-id',
    customLogic: (item: Object) => {
      if (G.isNilOrEmpty(R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item))) return;
      const fullName = R.join('', [R.prop(GC.FIELD_FIRST_NAME, item), R.prop(GC.FIELD_LAST_NAME, item)]);
      return R.join('', [fullName, `(${R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item)})`]);
    },
  },
  [GC.FIELD_TRAILERS]: {
    width: 500,
    name: 'titles:trailers-external-id',
    customLogic: (item: Object) => R.compose(
      R.join(', '),
      R.map((item: Object) => R.join(
        '',
        [R.prop(GC.FIELD_TRUCK_UNIT_ID, item), `(${R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item)})`],
      )),
      R.filter((item: Object) => G.isNotNilAndNotEmpty(R.prop(GC.FIELD_FLEET_EXTERNAL_ID, item))),
    )(item),
  },
};

export const dailyLogSettings = {
  [GC.FIELD_SOURCE]: {
    width: 200,
    name: 'titles:source',
  },
  [GC.FIELD_LOG_DATE]: {
    width: 200,
    name: 'titles:date',
  },
  [GC.FIELD_IFTA_TOTAL_DISTANCE]: {
    width: 150,
    name: 'titles:total-distance',
  },
  [GC.FIELD_IFTA_TOTAL_DISTANCE_UOM]: {
    width: 150,
    name: 'titles:total-distance-uom',
  },
  [GC.FIELD_ODOMETER]: {
    width: 120,
    name: 'titles:odometer',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
    customLogic: (date: string) => G.createLocalDateTimeString(date, GC.DEFAULT_DATE_TIME_FORMAT),
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    name: 'titles:update-on',
    customLogic: (date: string) => G.createLocalDateTimeString(date, GC.DEFAULT_DATE_TIME_FORMAT),
  },
};
