import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setActiveTab = createAction('setActiveTab');
export const printTrailer = createAction('printTrailer');
export const cleanFormStore = createAction('cleanFormStore');
export const setInitialState = createAction('setInitialState');
export const setDocumentsFilter = createAction('setDocumentsFilter');
export const getTrailerRefRequest = createAction('getTrailerRefRequest');
export const createTrailerRequest = createAction('createTrailerRequest');
export const updateTrailerRequest = createAction('updateTrailerRequest');
export const toggleTrailerFormGroup = createAction('toggleTrailerFormGroup');
export const getTrailerConfigsRequest = createAction('getTrailerConfigsRequest');
export const receivedGetTrailerSuccess = createAction('receivedGetTrailerSuccess');
export const getTrailerLocationRequest = createAction('getTrailerLocationRequest');
export const getTrailerDocumentRequest = createAction('getTrailerDocumentRequest');
export const receivedTrailerListSuccess = createAction('receivedTrailerListSuccess');
export const getTrailerOwnershipRequest = createAction('getTrailerOwnershipRequest');
export const getTrailerInsuranceRequest = createAction('getTrailerInsuranceRequest');
export const getTrailerComponentRequest = createAction('getTrailerComponentRequest');
export const updateTrailerDetailsRequest = createAction('updateTrailerDetailsRequest');
export const getExternalTrailerIdRequest = createAction('getExternalTrailerIdRequest');
export const getExternalTrailerIdSuccess = createAction('getExternalTrailerIdSuccess');
export const receivedGetTrailerRefSuccess = createAction('receivedGetTrailerRefSuccess');
export const createTrailerDocumentRequest = createAction('createTrailerDocumentRequest');
export const receivedCreateTrailerSuccess = createAction('receivedCreateTrailerSuccess');
export const createTrailerLocationRequest = createAction('createTrailerLocationRequest');
export const updateTrailerDocumentRequest = createAction('updateTrailerDocumentRequest');
export const receivedDeleteTrailerSuccess = createAction('receivedDeleteTrailerSuccess');
export const updateTrailerLocationRequest = createAction('updateTrailerLocationRequest');
export const deleteTrailerDocumentRequest = createAction('deleteTrailerDocumentRequest');
export const deleteTrailerLocationRequest = createAction('deleteTrailerLocationRequest');
export const getFleetGeneralConfigsRequest = createAction('getFleetGeneralConfigsRequest');
export const createTrailerReferenceRequest = createAction('createTrailerReferenceRequest');
export const createTrailerInsuranceRequest = createAction('createTrailerInsuranceRequest');
export const updateTrailerReferenceRequest = createAction('updateTrailerReferenceRequest');
export const updateTrailerComponentRequest = createAction('updateTrailerComponentRequest');
export const updateTrailerInsuranceRequest = createAction('updateTrailerInsuranceRequest');
export const deleteTrailerReferenceRequest = createAction('deleteTrailerReferenceRequest');
export const deleteTrailerInsuranceRequest = createAction('deleteTrailerInsuranceRequest');
export const deleteTrailerComponentRequest = createAction('deleteTrailerComponentRequest');
export const createTrailerComponentRequest = createAction('createTrailerComponentRequest');
export const getTrailerSpecificationRequest = createAction('getTrailerSpecificationRequest');
export const createExternalTrailerIdRequest = createAction('createExternalTrailerIdRequest');
export const createExternalTrailerIdSuccess = createAction('createExternalTrailerIdSuccess');
export const updateExternalTrailerIdRequest = createAction('updateExternalTrailerIdRequest');
export const updateExternalTrailerIdSuccess = createAction('updateExternalTrailerIdSuccess');
export const deleteExternalTrailerIdRequest = createAction('deleteExternalTrailerIdRequest');
export const deleteExternalTrailerIdSuccess = createAction('deleteExternalTrailerIdSuccess');
export const validateBeforeChangeTrailerTab = createAction('validateBeforeChangeTrailerTab');
export const receivedGetLocationTypesSuccess = createAction('receivedGetLocationTypesSuccess');
export const receivedGetTrailerConfigsSuccess = createAction('receivedGetTrailerConfigsSuccess');
export const receivedGetTrailerLocationSuccess = createAction('receivedGetTrailerLocationSuccess');
export const receivedGetTrailerDocumentSuccess = createAction('receivedGetTrailerDocumentSuccess');
export const receivedGetTrailerInsuranceSuccess = createAction('receivedGetTrailerInsuranceSuccess');
export const receivedGetTrailerComponentSuccess = createAction('receivedGetTrailerComponentSuccess');
export const receivedGetTrailerOwnershipSuccess = createAction('receivedGetTrailerOwnershipSuccess');
export const receivedUpdateTrailerDetailsSuccess = createAction('receivedUpdateTrailerDetailsSuccess');
export const receivedCreateTrailerDocumentSuccess = createAction('receivedCreateTrailerDocumentSuccess');
export const receivedCreateTrailerLocationSuccess = createAction('receivedCreateTrailerLocationSuccess');
export const getTrailerLatestKnownLocationRequest = createAction('getTrailerLatestKnownLocationRequest');
export const receivedDeleteTrailerLocationSuccess = createAction('receivedDeleteTrailerLocationSuccess');
export const receivedUpdateTrailerDocumentSuccess = createAction('receivedUpdateTrailerDocumentSuccess');
export const receivedUpdateTrailerLocationSuccess = createAction('receivedUpdateTrailerLocationSuccess');
export const receivedDeleteTrailerDocumentSuccess = createAction('receivedDeleteTrailerDocumentSuccess');
export const receivedCreateTrailerReferenceSuccess = createAction('receivedCreateTrailerReferenceSuccess');
export const receivedCreateTrailerInsuranceSuccess = createAction('receivedCreateTrailerInsuranceSuccess');
export const receivedUpdateTrailerReferenceSuccess = createAction('receivedUpdateTrailerReferenceSuccess');
export const receivedUpdateTrailerInsuranceSuccess = createAction('receivedUpdateTrailerInsuranceSuccess');
export const receivedUpdateTrailerComponentSuccess = createAction('receivedUpdateTrailerComponentSuccess');
export const receivedDeleteTrailerReferenceSuccess = createAction('receivedDeleteTrailerReferenceSuccess');
export const receivedDeleteTrailerInsuranceSuccess = createAction('receivedDeleteTrailerInsuranceSuccess');
export const receivedDeleteTrailerComponentSuccess = createAction('receivedDeleteTrailerComponentSuccess');
export const receivedUpdateTrailerOwnershipSuccess = createAction('receivedUpdateTrailerOwnershipSuccess');
export const receivedGetTrailerSpecificationSuccess = createAction('receivedGetTrailerSpecificationSuccess');
export const createTrailerLatestKnownLocationRequest = createAction('createTrailerLatestKnownLocationRequest');
export const receivedUpdateTrailerSpecificationSuccess = createAction('receivedUpdateTrailerSpecificationSuccess');
export const receivedTrailerLatestKnownLocationSuccess = createAction('receivedTrailerLatestKnownLocationSuccess');
export const receivedCreateTrailerLatestKnownLocationSuccess = createAction(
  'receivedCreateTrailerLatestKnownLocationSuccess',
);
// working division
export const changeAssignedToDivisionRequest = createAction('changeAssignedToDivisionRequest');
// sync trailer
export const syncTrailerByVINRequest = createAction('syncTrailerByVINRequest');
