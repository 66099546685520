import React from 'react';
// components
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, BoxHovered } from '../../../ui';
// feature driver profile simple
import { InfoPair } from './';
//////////////////////////////////////////////////

const actionColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const LicenseInfo = ({
  issueDate,
  licenseClass,
  licenseState,
  licenseNumber,
  expirationDate,
  licenseCountry,
}: Object) => (
  <Box p='8px' width={300}>
    <InfoPair
      width='100%'
      fontSize={11}
      text={licenseNumber}
      label={G.getWindowLocale('titles:license-number', 'License Number')} />
    <InfoPair
      mt='8px'
      width='100%'
      fontSize={11}
      text={licenseClass}
      label={G.getWindowLocale('titles:license-class', 'License Class')} />
    <InfoPair
      mt='8px'
      width='100%'
      fontSize={11}
      text={`${licenseState} ${licenseCountry}`}
      label={`
        ${G.getWindowLocale('titles:license-state', 'License State')}/${
          G.getWindowLocale('titles:country', 'Country')
        }
      `} />
    <Flex mt='8px'>
      <Box mr='4px'>{I.calendar(textColor)}</Box>
      <InfoPair
        fontSize={11}
        text={issueDate}
        width='calc(100% - 15px)'
        label={G.getWindowLocale('titles:issue-date', 'Issue Date')} />
    </Flex>
    <Flex mt='8px'>
      <Box mr='4px'>{I.calendar(textColor)}</Box>
      <InfoPair
        fontSize={11}
        text={expirationDate}
        width='calc(100% - 15px)'
        label={G.getWindowLocale('titles:expiration-date', 'Expiration Date')} />
    </Flex>
  </Box>
);

export const LicenseNumberComponent = ({ license }: Object) => {
  if (G.isNilOrEmpty(license)) {
    return (
      <InfoPair
        text={G.getPropFromObject(GC.FIELD_LICENSE_NUMBER, license)}
        label={G.getWindowLocale('titles:license-number', 'License #')} />
    );
  }

  return (
    <Flex
      mt='8px'
      width='45%'
      fontSize={12}
      flexWrap='wrap'
      fontWeight='bold'
      color={textColor}
    >
      <Box mr='5px'>
        {G.getWindowLocale('titles:license-number', 'License #')}:
      </Box>
      <PopperComponent
        type='hover'
        borderWidth='none'
        position='top-start'
        popperBorderRadius='4px'
        content={<LicenseInfo {...license} />}
        popperBoxShadow={`0 2px 10px 0 ${G.getTheme('colors.boxShadowGrey')}`}
      >
        <BoxHovered
          cursor='pointer'
          wordBreak='break-all'
          hoverColor={actionColor}
          textDecoration='underline'
        >
          {G.getPropFromObject(GC.FIELD_LICENSE_NUMBER, license)}
        </BoxHovered>
      </PopperComponent>
    </Flex>
  );
};

export default LicenseNumberComponent;
