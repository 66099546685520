import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import ToggleBtn from '../../../components/toggle-btn';
// helpers
import * as G from '../../../helpers';
// feature components-story
import { setToggleBtnValue } from '../actions';
import { makeSelectToggleBtnValue } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ToggleBtnComponent = ({ toggleBtnValue, setToggleBtnValue }: Object) => {
  const description = '<ToggleBtn /> component renders a toggle button with customizable styles and behaviors.';

  const usage = (
    <ul>
      <li>Parameter `<i>title</i>` set the text displayed on the toggle button.</li>
      <li>Parameter `<i>checked</i>` is a boolean indicating the current state of the toggle button.</li>
      <li>Parameter `<i>onToggleHandler</i>` is the function triggered when the toggle button is clicked.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ToggleBtn'
      description={description}
      path='src/components/toggle-btn'
    >
      <ToggleBtn
        checked={toggleBtnValue}
        onToggleHandler={setToggleBtnValue}
        title={G.ifElse(toggleBtnValue, 'active', 'inactive')}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  toggleBtnValue: makeSelectToggleBtnValue(state),
});

export default connect(mapStateToProps, {
  setToggleBtnValue,
})(ToggleBtnComponent);
