import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const sharedAccessorialFieldSettings = {
  width: '300px',
  type: 'multiselect',
  labelWidth: 'max-content',
  options: 'sharedAccessorials',
  loc: 'titles:shared-accessorials',
  fieldName: GC.FIELD_ACCESSORIAL_SHARED_ACCESSORIAL_GUIDS,
};

const enhance = compose(
  withFormik({
    validationSchema: Yup.object().shape({
      [GC.FIELD_ACCESSORIAL_SHARED_ACCESSORIAL_GUIDS]: G.yupArrayRequired,
    }),
    mapPropsToValues: ({ relatedGuid }: Object) => ({
      [GC.FIELD_PRIMARY_OBJECT_GUID]: relatedGuid,
      [GC.FIELD_ACCESSORIAL_SHARED_ACCESSORIAL_GUIDS]: null,
    }),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction, successSharedAccessorialsAction, addSharedAccessorialsEndpoint } = props;
      submitAction({ successSharedAccessorialsAction, data: values, endpoint: addSharedAccessorialsEndpoint });
    },
  }),
  pure,
);

const SharedAccessorialForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={R.of(Array, sharedAccessorialFieldSettings)}
        optionsForSelect={{ sharedAccessorials: props.sharedAccessorials }} />
      <FormFooter boxStyles={{ py: 10 }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(SharedAccessorialForm);
