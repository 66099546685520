import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  publicCLOFieldSettings,
  defaultPublicCLOFields,
  publicCLOValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: publicCLOValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultPublicCLOFields,
      props.initialValues,
    ),
  }),
  pure,
);

const PublicCLOForm = (props: Object) => (
  <Box mx='auto' width='270px'>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...props}
        fields={publicCLOFieldSettings}
        optionsForSelect={props.optionsForSelect}
        handlers={{ handleDisableUserLoginId: () => G.isFalse(props.isSuperAdmin) }} />
      <FormFooter boxStyles={{ py: 10 }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(PublicCLOForm);
