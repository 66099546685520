import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
// features intl
import {
  getFields,
  defaultFields,
  defaultValidationSchemaObject } from '../settings/fields-settings';
import {
  makeSelectLanguage,
  makeSelectCurrentLanguage } from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({
      custom,
      initialValues,
      languageKeyFromBack,
    }: Object) => G.setInitialFormikValues(
      defaultFields(languageKeyFromBack, custom),
      initialValues,
    ),
    validationSchema: ({ custom, languageKeyFromBack }: Object) => Yup.lazy(() => (
      Yup.object().shape(defaultValidationSchemaObject(languageKeyFromBack, custom))
    )),
    handleSubmit: (values: Object, { props }: Object) => {
      const itemOld = R.path(['selectIntl', R.path(['key'], values)], props);
      props.closeModal();
      if (props.custom) {
        const isCustomExist = props.customKeys.includes(R.path(['key'], values));
        const customItems = R.reduce(
          (acc: Array, key: string) => {
            if (isCustomExist) {
              return R.append({
                ...itemOld[key],
                language: key,
                value: R.path([key], values),
                itemId: R.path(['key'], values),
                [GC.FIELD_BRANCH_GUID]: R.path([GC.FIELD_BRANCH_GUID], values),
              }, acc);
            }
            return R.append({
              language: key,
              value: R.path([key], values),
              itemId: R.path(['key'], values),
              [GC.FIELD_BRANCH_GUID]: R.path([GC.FIELD_BRANCH_GUID], values),
            }, acc);
          },
          [],
          props.languageKeyFromBack,
        );
        return props.updateCustomIntlRequest({
          customItems,
          [GC.FIELD_BRANCH_GUID]: R.path([GC.FIELD_BRANCH_GUID], values),
        });
      }
      props.addNewItemLanguageRequest(values);
    },
  }),
);

const renderFields = (props: Object) => {
  const { custom, language, languageKeyFromBack } = props;

  return getFields(language, languageKeyFromBack, custom);
};
const AddNewItem = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit} id={props.formId}>
      <Box p='5px' pb='16px' fontWeight='bold'>
        {
          G.ifElse(
            props.custom,
            G.getWindowLocale('titles:customize-item', 'Customize Item'),
            G.getWindowLocale('titles:add-new-item', 'Add new Item'),
          )
        }
      </Box>
      <Box mb='25px'>
        <FieldsetComponent
          {...props}
          fields={renderFields(props)}
          optionsForSelect={{
            [GC.FIELD_BRANCH_GUID]: props.options,
          }}
          handlers={{
            handleChangeSelect: props.handleSelect,
          }} />
      </Box>
    </form>
  </Box>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  language: makeSelectLanguage(state),
  languageKeyFromBack: makeSelectCurrentLanguage(state),
}));

export default connect(mapStateToProps)(enhance(AddNewItem));
