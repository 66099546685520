import React from 'react';
import * as R from 'ramda';
// components
import { Label } from '../../../../components/label';
// utilities
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import { trailerIntegrationTypeOptions } from '../../settings/fields-settings';
//////////////////////////////////////////////////

export const documentColumns = {
  documentType: {
    width: 150,
    name: 'titles:type',
  },
  status: {
    name: 'titles:status',
    width: 150,
  },
  description: {
    width: 400,
    name: 'titles:description',
  },
  signedDate: {
    width: 150,
    name: 'titles:signed-date',
  },
  expirationDate: {
    width: 150,
    name: 'titles:expired-date',
  },
  documentFilename: {
    width: 300,
    name: 'titles:file',
  },
  url: {
    width: 300,
    name: 'titles:url',
    label: ({ url }: Object) =>
      <a href={G.makeURLString(url)} target='_blank'>{url}</a>, // eslint-disable-line
  },
};

export const insuranceColumns = {
  insuranceType: {
    width: 200,
    name: 'titles:insurance-type',
  },
  effectiveDate: {
    width: 200,
    name: 'titles:effective-date',
  },
  expirationDate: {
    width: 200,
    name: 'titles:expiration-date',
  },
  description: {
    width: 600,
    name: 'titles:description',
  },
  paymentInterval: {
    width: 200,
    name: 'titles:payment-interval',
  },
  paymentAmount: {
    width: 150,
    name: 'titles:payment-amount',
  },
};

export const locationColumns = {
  locationType: {
    width: 150,
    name: 'titles:type',
  },
  templateId: {
    width: 150,
    name: 'titles:template-id',
  },
  locationName: {
    width: 150,
    name: 'titles:name',
  },
  address1: {
    width: 150,
    name: 'titles:address1',
  },
  address2: {
    width: 150,
    name: 'titles:address2',
  },
  city: {
    width: 150,
    name: 'titles:city',
  },
  state: {
    width: 150,
    name: 'titles:state',
  },
  zip: {
    width: 150,
    name: 'titles:zip',
  },
  country: {
    width: 150,
    name: 'titles:country',
  },
  latitude: {
    width: 150,
    name: 'titles:latitude',
  },
  longitude: {
    width: 150,
    name: 'titles:longitude',
  },
  residential: {
    width: 150,
    name: 'titles:residential',
    label: (loc: Object) =>
      <Label frontLabel={G.ifElse(loc.residential, 'active', 'deactive')} />,
  },
  comments: {
    width: 150,
    name: 'titles:comments',
  },
};

export const componentColumns = {
  componentId: {
    width: 150,
    name: 'titles:component-id',
  },
  createdFromSharedComponent: {
    width: 150,
    name: 'titles:shared-component',
  },
  componentType: {
    width: 150,
    name: 'titles:type',
  },
  installDate: {
    width: 150,
    name: 'titles:installed-date',
  },
  replaceOrRemoveDate: {
    width: 150,
    name: 'titles:removed-date',
  },
  installed: {
    width: 100,
    name: 'titles:installed',
  },
  componentManufacturer: {
    width: 150,
    name: 'titles:manufacturer',
  },
  serialNumber: {
    width: 150,
    name: 'titles:serial-number',
  },
  specification: {
    width: 150,
    name: 'titles:specification',
  },
  warrantyExpiration: {
    width: 150,
    name: 'titles:warranty-expiration-date',
  },
};

export const integrationSamsaraColumnSettings = {
  [GC.FIELD_FLEET_EXTERNAL_ID]: {
    width: 150,
    name: 'titles:external-id',
  },
  [GC.FIELD_FLEET_INTEGRATION_TYPE]: {
    width: 150,
    name: 'titles:integration-type',
    customLogic: (item: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(item, GC.FIELD_VALUE)),
    )(trailerIntegrationTypeOptions),
  },
  [GC.FIELD_PRIMARY_DRIVER]: {
    width: 300,
    name: 'titles:primary-driver-external-id',
    customLogic: (item: Object = {}) => {
      const { lastName, firstName, externalId } = item;

      if (G.isNilOrEmpty(externalId)) return null;

      return `${firstName} ${lastName}(${externalId})`;
    },
  },
  [GC.FIELD_TEAM_DRIVER]: {
    width: 300,
    name: 'titles:team-driver-external-id',
    customLogic: (item: Object = {}) => {
      const { lastName, firstName, externalId } = item;

      if (G.isNilOrEmpty(externalId)) return null;

      return `${firstName} ${lastName}(${externalId})`;
    },
  },
  [GC.FIELD_TRUCK]: {
    width: 200,
    name: 'titles:truck-external-id',
    customLogic: (item: Object = {}) => {
      const { unitId, externalId } = item;

      if (G.isNilOrEmpty(externalId)) return null;

      return `${unitId}(${externalId})`;
    },
  },
};
