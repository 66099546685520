import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
// features dashboards
import LargeCard from './large-card';
import TotalCard from './total-card';
import MiddleCard from './middle-card';
import { TITLES_MAP } from '../constants';
import {
  chartDateMapper,
  getTotalsForCard,
  getChartTitleWithCurrency,
  getFromLastDaysDescription,
  getDomainYAndTicksYByChartData,
  getChartTickFormatterWithCurrency,
} from '../helpers';
//////////////////////////////////////////////////

const AccountingCharts = (props: Object) => {
  const { filters, dashboard, currencySymbol } = props;

  const { days } = filters;

  const payrollData = R.compose(
    R.map(chartDateMapper),
    R.pathOr([], ['PAYROLLS', 'groups']),
  )(dashboard);

  const cloInvoicesPerModeData = R.compose(
    R.map(chartDateMapper),
    R.pathOr([], ['CLO_INVOICES_PER_MODE', 'groups']),
  )(dashboard);

  const telRPMData = R.compose(
    R.map(chartDateMapper),
    R.pathOr([], ['CARRIER_INVOICES_PER_MODE', 'groups']),
  )(dashboard);

  const notInvoicedClos = R.compose(
    R.map((item: Object) => R.assoc('name', TITLES_MAP[item.name], item)),
    R.pathOr([], ['NOT_INVOICED_CLOS', 'groups']),
  )(dashboard);

  const cloInvoiceStatuses = R.compose(
    R.map((item: Object) => R.assoc(
      'name',
      G.isNotNilAndNotEmpty(item.name) ? item.name : G.getWindowLocale('titles:no-status', 'No Status'),
      item,
    )),
    R.pathOr([], ['CLO_INVOICE_STATUSES', 'groups']),
  )(dashboard);

  const carrierInvoiceStatuses = R.compose(
    R.map((item: Object) => R.assoc(
      'name',
      G.isNotNilAndNotEmpty(item.name) ? item.name : G.getWindowLocale('titles:no-status', 'No Status'),
      item,
    )),
    R.pathOr([], ['CARRIER_INVOICE_STATUSES', 'groups']),
  )(dashboard);

  const fromLastDaysDescription = getFromLastDaysDescription(days);

  return (
    <Flex p={20} alignItems='start'>
      <Box width={1080}>
        <Flex mb={20}>
          <TotalCard
            totalPrefix={currencySymbol}
            title={TITLES_MAP.expectedIncome}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_CLO_INVOICES', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.payroll}
            totalPrefix={currencySymbol}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_PAYROLLS', dashboard)}
          />
          <TotalCard
            totalPrefix={currencySymbol}
            title={TITLES_MAP.expectedProfit}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_EXPECTED_PROFIT', dashboard)}
          />
          <TotalCard
            totalPrefix={currencySymbol}
            title={TITLES_MAP.fleetServiceTotal}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_FLEET_SERVICE', dashboard)}
          />
        </Flex>
        <Flex mb={20}>
          <TotalCard
            totalPrefix={currencySymbol}
            description={fromLastDaysDescription}
            title={TITLES_MAP.carrierInvoiceTotal}
            {...getTotalsForCard('TOTAL_CARRIER_INVOICE', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.fuel}
            totalPrefix={currencySymbol}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_FUEL', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.toll}
            totalPrefix={currencySymbol}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_TOLL', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.ordersZeroRate}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_CLOS_WITH_ZERO_RATE', dashboard)}
          />
        </Flex>
        <Flex alignItems='start' flexDirection='column'>
          <LargeCard
            {...getDomainYAndTicksYByChartData(G.getPropFromObject('PAYROLLS', dashboard))}
            chartType='2_LINE'
            data={payrollData}
            line2DataKey='vendor'
            lineDataKey='ownFleet'
            lineName={TITLES_MAP.own}
            line2Name={TITLES_MAP.vendor}
            tickFormatter={getChartTickFormatterWithCurrency(currencySymbol)}
            title={getChartTitleWithCurrency(TITLES_MAP.payroll, currencySymbol)}
          />
          <LargeCard
            {...getDomainYAndTicksYByChartData(G.getPropFromObject('CLO_INVOICES_PER_MODE', dashboard))}
            chartType='BAR'
            data={cloInvoicesPerModeData}
            tickFormatter={getChartTickFormatterWithCurrency(currencySymbol)}
            title={getChartTitleWithCurrency(TITLES_MAP.customerInvoicePerMode, currencySymbol)}
          />
          <LargeCard
            {...getDomainYAndTicksYByChartData(G.getPropFromObject('CARRIER_INVOICES_PER_MODE', dashboard))}
            chartType='BAR'
            data={telRPMData}
            reverseColors={true}
            tickFormatter={getChartTickFormatterWithCurrency(currencySymbol)}
            title={getChartTitleWithCurrency(TITLES_MAP.carrierInvoicePerMode, currencySymbol)}
          />
        </Flex>
      </Box>
      <Box width={520}>
        <Flex alignItems='start' flexDirection='column'>
          <MiddleCard
            chartType='PIE'
            data={notInvoicedClos}
            title={TITLES_MAP.ordersNotInvoiced}
            pieProps={{ innerRadius: 70, paddingAngle: 10 }}
            labelFormatter={(value: number) => `${currencySymbol} ${G.toLocaleString(value)}`}
          />
          <MiddleCard
            height={422}
            chartType='SCORE'
            data={cloInvoiceStatuses}
            prefixSymbol={currencySymbol}
            title={TITLES_MAP.customerInvoiceStatus}
          />
          <MiddleCard
            height={422}
            chartType='SCORE'
            prefixSymbol={currencySymbol}
            data={carrierInvoiceStatuses}
            title={TITLES_MAP.carrierInvoiceStatus}
          />
          <MiddleCard
            height={422}
            chartType='SCORE'
            prefixSymbol={currencySymbol}
            title={TITLES_MAP.payrollPerCharge}
            data={R.pathOr([], ['PAYROLLS_PER_CHARGE', 'groups'], dashboard)}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default AccountingCharts;
