import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature geo-fencing-zone
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  geofencingZoneList: {},
};

const setInitialState = () => initialState;

const deleteGeoFencingZoneSuccess = (state: Object, guid: Object) => P.$drop(`geofencingZoneList.${guid}`, state);

const toggleGeoFencingZoneDetails = (state: Object, { guid }: Object) => (
  P.$toggle(`geofencingZoneList.${guid}.expanded`, state)
);

const receivedGeoFencingZoneListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set('geofencingZoneList', {}, state);

  const indexedList = R.compose(
    G.indexByGuid,
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, expanded: false, disabled: G.notPropEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set('geofencingZoneList', indexedList, state);
};

const receivedGeoFencingZoneSuccess = (state: Object, data: Object) => {
  const { guid, [GC.BRANCH_GUID]: branchGuid } = data;

  const { geofencingZoneList } = state;

  const expanded = R.pathOr(false, [guid, 'expanded'], geofencingZoneList);
  const disabled = G.notEquals(G.getAmousCurrentBranchGuidFromWindow(), branchGuid);
  const index = R.pathOr(R.length(geofencingZoneList), [guid, 'index'], geofencingZoneList);
  const dataWithTableProps = R.mergeRight(data, { index, expanded, disabled });
  const newList = R.assoc(guid, dataWithTableProps, geofencingZoneList);

  return P.$set('geofencingZoneList', newList, state);
};

export {
  deleteGeoFencingZoneSuccess,
  toggleGeoFencingZoneDetails,
  receivedGeoFencingZoneSuccess,
  receivedGeoFencingZoneListSuccess,
};

export default createReducer({
  [A.setInitialState]: setInitialState,
  [A.deleteGeoFencingZoneSuccess]: deleteGeoFencingZoneSuccess,
  [A.toggleGeoFencingZoneDetails]: toggleGeoFencingZoneDetails,
  [A.receivedGeoFencingZoneSuccess]: receivedGeoFencingZoneSuccess,
  [A.receivedGeoFencingZoneListSuccess]: receivedGeoFencingZoneListSuccess,
}, initialState);

