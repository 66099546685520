import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature fleet-list
import { syncItemsByVINRequest } from './actions';
//////////////////////////////////////////////////

export const withSyncItemsByVIN = compose(
  connect(null, { syncItemsByVINRequest }),
  withHandlers({
    handleSyncItemsByReport: ({ selectedReport, currentBranchGuid, syncItemsByVINRequest }: Object) => () => {
      if (G.isNilOrEmpty(selectedReport)) {
        const message = G.getWindowLocale(
          'messages:add-field-to-current-report',
          'Please, Select Report or Add Fields to Current Report',
        );

        return G.showToastrMessageSimple('info', message);
      }

      const report = R.assoc(GC.CURRENT_BRANCH, currentBranchGuid, selectedReport);

      syncItemsByVINRequest({ report, byReport: true });
    },
    handleSyncSelectedItems: ({ closeFixedPopup, syncItemsByVINRequest }: Object) => (guids: Array) => {
      G.callFunction(closeFixedPopup);

      if (G.isNilOrEmpty(guids)) {
        const message = G.getWindowLocale('messages:select-item', 'Please, select at least one item');

        return G.showToastrMessageSimple('info', message);
      }

      syncItemsByVINRequest({ guids, byReport: false });
    },
  }),
  pure,
);
