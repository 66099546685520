import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = { mb: 25, width: 220 };

export const validationSchemaObject = {
  [GC.FIELD_NAME]: G.yupStringRequired,
  driverGuids: Yup.array().of(Yup.string()).nullable(true),
  dispatcherGuids: Yup.array().of(Yup.string()).nullable(true),
};

export const defaultFields = {
  driverGuids: [],
  dispatcherGuids: [],
  [GC.FIELD_NAME]: null,
};

export const itemFormFields = [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'driverList',
    fieldName: 'driverGuids',
    useMenuPortalTarget: true,
    valueContainerMaxHeight: 200,
    useMultiSelectMaxHeight: true,
    label: ['titles:drivers', 'Drivers'],
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'dispatcherList',
    useMenuPortalTarget: true,
    fieldName: 'dispatcherGuids',
    valueContainerMaxHeight: 200,
    useMultiSelectMaxHeight: true,
    label: ['titles:dispatchers', 'Dispatchers'],
  },
];
