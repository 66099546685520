import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-profile
import LastKnownLocationForm from '../forms/last-known-location';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_SOURCE]: {
    name: 'titles:source',
  },
  [GC.FIELD_CITY]: {
    width: 150,
    name: 'titles:city',
    type: 'addressAutocomplete',
    additionalInputWrapperStyles: { zIndex: 13 },
    locationMapper: R.pick(
      [
        GC.FIELD_ZIP,
        GC.FIELD_CITY,
        GC.FIELD_STATE,
        GC.FIELD_COUNTRY,
        GC.FIELD_LATITUDE,
        GC.FIELD_LONGITUDE,
      ],
    ),
  },
  [GC.FIELD_STATE]: {
    width: 150,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    isRequired: false,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    type: 'countrySelect',
    name: 'titles:country',
  },
  [GC.FIELD_LONGITUDE]: {
    width: 100,
    disabled: true,
    name: 'titles:longitude',
  },
  [GC.FIELD_LATITUDE]: {
    width: 100,
    disabled: true,
    name: 'titles:latitude',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 150,
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 150,
    name: 'titles:update-on',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_ZIP]: G.yupStringRequired,
  [GC.FIELD_CITY]: G.yupStringRequired,
  [GC.FIELD_STATE]: G.yupStringRequired,
  [GC.FIELD_COUNTRY]: G.yupStringRequired,
});

const defaultInputWrapperStyles = {
  mb: 25,
  width: 300,
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    disabled,
    type = 'text',
    locationMapper,
    isRequired = true,
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    type,
    options,
    disabled,
    fieldName,
    isRequired,
    locationMapper,
    label: R.of(Array, name),
    inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperStyles },
  })),
  R.omit([
    GC.FIELD_SOURCE,
    GC.FIELD_CREATED_BY,
    GC.FIELD_CREATED_DATE,
    GC.FIELD_LAST_MODIFIED_BY,
    GC.FIELD_LAST_MODIFIED_DATE,
  ]),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

export const lastKnownLocationSettings = {
  report,
  defaultValues,
  columnSettings,
  useGetListRequest: true,
  groupName: 'lastKnownLocation',
  actionsPicker: ['lastKnownLocationAudit'],
  formGroupTitleArr: ['titles:last-known-location', 'Last Known Location'],
  additionalTableSettings: {
    checkBoxCellJustifyContent: 'center',
  },
  endpoints: {
    list: 'driverLatestLocation',
    createOrUpdate: 'driverLatestLocation',
  },
  CustomForm: (props: Object) => (
    <LastKnownLocationForm
      {...props}
      fieldSettings={fieldSettings}
      fieldsWrapperStyles={{ pt: 45 }}
      validationSchema={validationSchema}
    />
  ),
};

export const truckLastKnownLocationSettings = {
  ...lastKnownLocationSettings,
  actionsPicker: [],
  endpoints: {
    list: 'truckLatestLocation',
    createOrUpdate: 'truckLatestLocation',
  },
};

export const trailerLastKnownLocationSettings = {
  ...lastKnownLocationSettings,
  actionsPicker: [],
  endpoints: {
    list: 'trailerLatestLocation',
    createOrUpdate: 'trailerLatestLocation',
  },
};
