import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
// components
import { openLoader, closeLoader } from '../../../components/loader/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// feature claim-management
import { makeDropdownOptions } from '../helpers';
import { getDetailsBranchConfigsByNamesSuccess } from '../actions';
import { makeSelectDropdownOptionsByDropdownNames } from '../selectors';
//////////////////////////////////////////////////

const useGenerateClaimNumber = ({ branchGuid, autoGenerate, sequenceGuid }: Object) => {
  const [claimNumber, setClaimNumber] = useState(null);

  useEffect(() => {
    if (R.not(autoGenerate)) return;

    const handleGenerateClaimNumber = async () => {
      const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const options = {
        params: { [GC.FIELD_BRANCH_GUID]: R.or(branchGuid, currentBranchGuid) },
      };

      const res = await sendRequest('get', endpointsMap.getSequenceEndpoint(sequenceGuid), options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const { prefix, suffix, approxValue } = data;

        const value = G.createStringFromArray([prefix, approxValue, suffix], '');

        setClaimNumber(value);
      } else {
        G.handleFailResponseSimple(res, true, 'useGenerateClaimNumber');
      }
    };

    handleGenerateClaimNumber();
  }, [autoGenerate]);

  return claimNumber;
};

const useGetClos = ({ branchGuid, initialCloGuid }: Object) => {
  const [clos, setClos] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const groupId = R.isNil(initialCloGuid) ? null : G.generateGuid();

  const handleGetCloList = useCallback(async ({
    offset = 0,
    primaryRefValue,
    useInitialCloGuid,
  }: Object = {}) => {
    setIsLoading(true);

    const makeSearchCriteria = () => {
      const cloFilter = {
        groupId,
        dataType: 'string',
        operation: 'equal',
        stringValue: initialCloGuid,
        propertyName: GC.FIELD_GUID,
      };

      if (useInitialCloGuid) return R.of(Array, cloFilter);

      const withoutClaimFilter = {
        groupId,
        stringValue: null,
        dataType: 'string',
        operation: 'equal',
        propertyName: `claim.${GC.FIELD_GUID}`,
      };

      let searchCriteria = R.of(Array, withoutClaimFilter);

      if (R.isNotNil(primaryRefValue)) {
        searchCriteria = R.append(
          {
            dataType: 'string',
            operation: 'contain',
            stringValue: primaryRefValue,
            propertyName: GC.FIELD_PRIMARY_REFERENCE_VALUE,
          },
          searchCriteria,
        );
      }

      if (R.isNotNil(initialCloGuid)) return R.append(cloFilter, searchCriteria);

      return searchCriteria;
    };

    const options = {
      data: {
        offset,
        limit: 10,
        searchCriteria: makeSearchCriteria(),
        [GC.CURRENT_BRANCH]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
        fields: [
          {
            sequence: 0,
            name: GC.FIELD_PRIMARY_REFERENCE_VALUE,
          },
        ],
      },
    };

    const res = await sendRequest('post', endpointsMap.cloChildrenAndCurrentList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedClos = R.map(({ guid, primaryReferenceValue }: Object = {}) => ({
        [GC.FIELD_VALUE]: guid,
        [GC.FIELD_LABEL]: primaryReferenceValue,
      }), R.propOr([], 'results', data));

      setTotalCount(data.totalCount);

      setClos((prev: Array) => (
        G.isZero(offset) ? mappedClos : R.uniqBy(R.prop(GC.FIELD_VALUE), R.concat(prev, mappedClos))
      ));
    }

    setIsLoading(false);
  }, [initialCloGuid]);

  const handleScroll = useCallback(() => {
    if (R.gt(totalCount, R.length(clos))) {
      handleGetCloList({ offset: R.length(clos), primaryRefValue: inputValue });
    }
  }, [totalCount, R.length(clos), inputValue, handleGetCloList]);

  const handleSearchClo = useCallback((inputValue: string, { action, prevInputValue }: Object) => {
    if (R.and(R.isEmpty(prevInputValue), R.isEmpty(inputValue))) return;

    setInputValue(inputValue);
    handleGetCloList({ primaryRefValue: inputValue });
  }, [handleGetCloList]);

  const handleOpenCloSelectMenu = useCallback(() => {
    if (R.lte(totalCount, 1)) handleGetCloList({ offset: totalCount });
  }, [totalCount]);

  useEffect(() => {
    if (R.isNotNil(initialCloGuid)) handleGetCloList({ useInitialCloGuid: true });
  }, [initialCloGuid]);

  return {
    isLoading,
    handleScroll,
    options: clos,
    onMenuScrollToBottom: handleScroll,
    onMenuOpen: handleOpenCloSelectMenu,
    onInputChange: G.setDebounce(handleSearchClo, 500),
  };
};

const useGetTelsByClo = ({ cloGuid, setFieldValue, shouldNotAutofillTelGuid }: Object) => {
  const [telsByClos, setTelsByClos] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const options = R.prop(cloGuid, telsByClos);

  const handleGetTelListByClo = useCallback(async (guid: string) => {
    if (G.isNotNilAndNotEmpty(options)) return;

    setIsLoading(true);

    const requestOptions = {
      params: {
        [GC.FIELD_CLO_GUID]: guid,
      },
    };

    const res = await sendRequest('get', endpointsMap.relatedTelsByClo, requestOptions);

    const { data } = res;

    const mappedTels = R.map(({ guid, primaryReferenceValue }: Object = {}) => ({
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: primaryReferenceValue,
    }), R.or(data, []));

    setIsLoading(false);
    setTelsByClos((prev: Object) => R.assoc(guid, mappedTels, prev));
  }, [options]);

  useEffect(() => {
    if (R.isNotNil(cloGuid)) handleGetTelListByClo(cloGuid);
  }, [cloGuid]);

  useEffect(() => {
    if (shouldNotAutofillTelGuid) return;

    if (R.equals(R.length(options), 1)) {
      setFieldValue(GC.FIELD_TEL_GUID, R.path([0, GC.FIELD_VALUE], options));
    }
  }, [options, shouldNotAutofillTelGuid]);

  return { options, isLoading };
};

const useGetClaimByClaimGuid = ({ claimGuid, openLoader, closeLoader }: Object) => {
  const [claim, setClaim] = useState({});

  useEffect(() => {
    const handleGetClaimByClaimGuid = async () => {
      openLoader();

      const endpoint = endpointsMap.getClaimByGuid(claimGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setClaim({
          ...data,
          [GC.FIELD_TYPE]: R.path([GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
          [GC.FIELD_REASON]: R.path([GC.FIELD_REASON, GC.FIELD_DROPDOWN_OPTION_GUID], data),
          [GC.FIELD_STATUS]: R.path([GC.FIELD_STATUS, GC.FIELD_DROPDOWN_OPTION_GUID], data),
          [GC.FIELD_SUB_STATUS]: R.path([GC.FIELD_SUB_STATUS, GC.FIELD_DROPDOWN_OPTION_GUID], data),
        });
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetClaimByClaimGuid fail');
      }

      closeLoader();
    };

    handleGetClaimByClaimGuid();
  }, []);

  return claim;
};

const getConfigsByConfigsNamesRequest = async ({ dispatch, callback, branchGuid, configNames }: Object) => {
  dispatch(openLoader());

  const options = {
    params: {
      names: configNames,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    },
  };

  const res = await sendRequest('get', endpointsMap.branchConfigsEndpoint, options);

  const { data, status } = res;

  if (G.isResponseSuccess(status)) {
    const configs = G.mapConfigValuesByName(data);

    callback(configs);
  } else {
    G.handleException('useCreateDropdownOptionsFromAsyncConfigs exception');
  }

  dispatch(closeLoader());
};

const useCreateDropdownOptionsFromAsyncConfigs = ({ names, branchGuid }: Object) => {
  const dispatch = useDispatch();

  const configNames = G.isArray(names) ? R.join(',', names) : names;

  const options = useSelector((state: Object) => makeSelectDropdownOptionsByDropdownNames(state, configNames));

  const namesToCall = R.compose(
    R.join(','),
    R.keys,
    R.filter(R.isNil),
  )(options);

  useEffect(() => {
    if (R.isEmpty(namesToCall)) return;

    getConfigsByConfigsNamesRequest({
      dispatch,
      branchGuid,
      configNames: namesToCall,
      callback: (configs: Object) => dispatch(getDetailsBranchConfigsByNamesSuccess(configs)),
    });
  }, []);

  return options;
};

const useGetAsyncConfigsForCloDetails = ({ names, branchGuid }: Object) => {
  const dispatch = useDispatch();

  const configNames = G.isArray(names) ? R.join(',', names) : names;

  const [configGroup, setConfigGroup] = useState({});

  useEffect(() => {
    const callback = ({ configs, dropdowns }: Object) => {
      const dropdownOptions = makeDropdownOptions(dropdowns);

      return setConfigGroup({ configs, dropdownOptions });
    };
    getConfigsByConfigsNamesRequest({
      dispatch,
      callback,
      branchGuid,
      configNames,
    });
  }, []);

  return configGroup;
};

const usePreviewOrDownloadDocument = ({ openLoader, closeLoader, primaryObjectGuid }: Object) => {
  const handlePreviewOrDownloadDocument = useCallback(async (entity: Object, actionType: string) => {
    const { fileUri, fileName, documentFilename } = entity;

    openLoader();

    const options = {
      resType: 'arraybuffer',
      params: {
        fileUri,
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.or(fileName, documentFilename),
      },
    };

    const res = await sendRequestWithQSParamsSerializer('get', endpointsMap.claimDocumentDownloadFile, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(actionType, 'preview')) {
        G.openFileInWindowFromArrayBufferResponse(res);
      } else {
        G.saveFileFromResponse(res);
      }
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, []);

  return handlePreviewOrDownloadDocument;
};

export {
  useGetClos,
  useGetTelsByClo,
  useGetClaimByClaimGuid,
  useGenerateClaimNumber,
  usePreviewOrDownloadDocument,
  useGetAsyncConfigsForCloDetails,
  useCreateDropdownOptionsFromAsyncConfigs,
};
