import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { branch, renderNothing } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { PageTitle } from '../../../../components/page-title';
// features
import Audit2 from '../../../audit2';
import { makeSelectCurrentBranch } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { PageWrapper, PageHeaderWrapper } from '../../../../ui';
//////////////////////////////////////////////////

const enhance = branch(
  ({ currentBranch }: Object) => G.isNilOrEmpty(currentBranch),
  renderNothing,
);

const getPageTitle = R.compose(
  R.join(': '),
  R.prepend(G.getWindowLocale('titles:audit', 'Audit')),
  R.of(Array),
  R.path(['currentBranch', GC.BRANCH_NAME]),
);

const ConfigAuditPage = (props: Object) => {
  const { currentBranch } = props;

  const auditProps = {
    useFullScreen: true,
    maxHeight: 'calc(100vh - 170px)',
    requestOptions: {
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_BRANCH_CONFIG,
      [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(currentBranch),
    },
  };

  return (
    <PageWrapper pt={15} pr={15}>
      <PageHeaderWrapper>
        <PageTitle withCount={false} title={getPageTitle(props)} />
      </PageHeaderWrapper>
      <Audit2 {...auditProps} />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  currentBranch: makeSelectCurrentBranch(state),
});

export default connect(mapStateToProps, {})(enhance(ConfigAuditPage));
