import React from 'react';
import * as R from 'ramda';
import { useFormik } from 'formik';
// components
import { FormFooter2 } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms';
//////////////////////////////////////////////////

const inputWrapperStyles = { mt: 15, width: 420 };

const fieldSettings = [
  {
    isMulti: true,
    type: 'reactSelect',
    options: 'itemOptions',
    label: ['titles:items'],
    fieldName: GC.SYSTEM_LIST_ITEMS,
    placeholder: G.getWindowLocale('titles:select-items', 'Select Items'),
    inputWrapperStyles: {
      ...inputWrapperStyles,
      mb: 10,
      display: ({ options }: Object) => G.ifElse(G.isNilOrEmpty(options), 'none'),
    },
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: 'cloOptions',
    label: ['titles:clos'],
    fieldName: GC.FIELD_CLOS,
    placeholder: G.getWindowLocale('titles:select-clos', 'Select CLOs'),
    inputWrapperStyles: {
      ...inputWrapperStyles,
      mb: 25,
      display: ({ options }: Object) => G.ifElse(
        G.isNilOrEmpty(options),
        'none',
      ),
    },
  },
];

const SelectClosItems = (props: Object) => {
  const { closObj, cloOptions, closeModal, itemOptions, handleActionLoadItem } = props;

  const onSubmit = ({ clos, items }: Object) => {
    let loadItems = [];

    clos.forEach((cloId: string) => loadItems = R.concat(loadItems, closObj[cloId]));

    closeModal();
    handleActionLoadItem({ items, loadItems });
  };

  const formik = useFormik({
    onSubmit,
    initialValues: { items: [], clos: [] },
  });

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        fields={fieldSettings}
        cloOptions={cloOptions}
        itemOptions={itemOptions}
        fieldsWrapperStyles={{ flexDirection: 'column' }}
      />
      <FormFooter2 submitBtnText={G.getWindowLocale('actions:select', 'Select')} />
    </form>
  );
};

export default SelectClosItems;
