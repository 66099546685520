// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const DASHBOARD_TYPE_FLEET_GENERAL = 'FLEET_GENERAL';
const DASHBOARD_TYPE_FLEET_TRAILER = 'FLEET_TRAILER';
const DASHBOARD_TYPE_FLEET_ACCOUNTING = 'FLEET_ACCOUNTING';

const DAY_3 = 3;
const DAY_7 = 7;
const DAY_14 = 14;
const DAY_30 = 30;
const DAY_RANGE = 'RANGE';

const LESS_THEN_A_DAY = 'LESS_THEN_A_DAY';
const FROM_SEVEN_DAYS = 'FROM_SEVEN_DAYS';
const FROM_FOUR_TO_SIX_DAYS = 'FROM_FOUR_TO_SIX_DAYS';
const FROM_ONE_TO_THREE_DAYS = 'FROM_ONE_TO_THREE_DAYS';

const dataRangeEnumLocale = {
  [LESS_THEN_A_DAY]: G.getWindowLocale('titles:less-then-a-day', 'Less then a day'),
  [FROM_SEVEN_DAYS]: G.getWindowLocale('titles:from-seven-days', 'From seven days'),
  [FROM_FOUR_TO_SIX_DAYS]: G.getWindowLocale('titles:from-four-to-six-days', 'From four to six days'),
  [FROM_ONE_TO_THREE_DAYS]: G.getWindowLocale('titles:from-one-to-three-days', 'From one to three days'),
};

const CHART_COLORS = {
  white: '#ffffff',
  grey1: '#a6a5a9',
  grey2: '#989898',
  grey3: '#EAEAEA',
  blue1: '#4880FF',
  blue2: '#8280FF',
  blue3: '#8884d8',
  green1: '#00B69B',
  PIE_BAR_COLORS: [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
  ],
  RADIAL_BAR_COLORS: [
    '#8884d8',
    '#83a6ed',
    '#8dd1e1',
    '#82ca9d',
    '#a4de6c',
    '#d0ed57',
    '#ffc658',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
  ],
  BAR_COLORS: [
    '#67b7dc',
    '#6794dc',
    '#6770dc',
    '#8067dc',
    '#a367dc',
    '#c767dc',
    '#dc67ce',
    '#dc67ab',
    '#dc6788',
    '#dc6967',
    '#dc8c67',
    '#dcaf67',
    '#dcd267',
    '#c3dc67',
    '#a0dc67',
    '#7ddc66',
    '#67dc75',
    '#67dc98',
    '#67dcbb',
    '#67dadc',
  ],
};

// TODO: not added to locale API
const TITLES_MAP = {
  own: G.getWindowLocale('titles:own', 'Own'),
  trip: G.getWindowLocale('titles:trip', 'Trip'),
  toll: G.getWindowLocale('titles:toll', 'Toll'),
  fuel: G.getWindowLocale('titles:fuel', 'Fuel'),
  order: G.getWindowLocale('titles:order', 'Order'),
  drops: G.getWindowLocale('titles:drops', 'Drops'),
  vendor: G.getWindowLocale('titles:vendor', 'Vendor'),
  booked: G.getWindowLocale('titles:booked', 'Booked'),
  pickups: G.getWindowLocale('titles:pickups', 'Pickups'),
  payroll: G.getWindowLocale('titles:payroll', 'Payroll'),
  covered: G.getWindowLocale('titles:covered', 'Covered'),
  vacation: G.getWindowLocale('titles:vacation', 'Vacation'),
  canceled: G.getWindowLocale('titles:canceled', 'Canceled'),
  noDriver: G.getWindowLocale('titles:noDriver', 'No Driver'),
  newLoads: G.getWindowLocale('titles:newLoads', 'New Loads'),
  delivered: G.getWindowLocale('titles:delivered', 'Delivered'),
  orderTrip: G.getWindowLocale('titles:orderTrip', 'Order/Trip'),
  inTransit: G.getWindowLocale('titles:inTransit', 'In Transit'),
  latePercent: G.getWindowLocale('titles:latePercent', 'Late %'),
  needALoad: G.getWindowLocale('titles:needALoad', 'Need a Load'),
  tripStatus: G.getWindowLocale('titles:tripStatus', 'Trip Status'),
  ordersTotal: G.getWindowLocale('titles:ordersTotal', 'Orders Total'),
  orderStatus: G.getWindowLocale('titles:orderStatus', 'Order Status'),
  marginTotal: G.getWindowLocale('titles:marginTotal', 'Margin Total'),
  issuesFound: G.getWindowLocale('titles:issues-found', 'Issues Found'),
  lateForDrop: G.getWindowLocale('titles:lateForDrop', 'Late for Drop'),
  onTimePercentS: G.getWindowLocale('titles:onTimePercentS', 'On Time %'),
  outOfService: G.getWindowLocale('titles:outOfService', 'Out Of Service'),
  expensesTotal: G.getWindowLocale('titles:expensesTotal', 'Expenses Total'),
  activeDrivers: G.getWindowLocale('titles:activeDrivers', 'Active Drivers'),
  lateForPickUp: G.getWindowLocale('titles:lateForPickUp', 'Late for PickUp'),
  fuelTollSpend: G.getWindowLocale('titles:fuelTollSpend', 'Fuel/Toll Spend'),
  days: G.getWindowLocale('titles:days', 'days', { caseAction: 'lowerCase' }),
  expectedProfit: G.getWindowLocale('titles:expectedProfit', 'Expected Profit'),
  expectedIncome: G.getWindowLocale('titles:expectedIncome', 'Expected Income'),
  ordersZeroRate: G.getWindowLocale('titles:ordersZeroRate', 'Orders Zero Rate'),
  issuesByVendor: G.getWindowLocale('titles:issuesByVendor', 'Issues By Vendor'),
  orderRatesTotal: G.getWindowLocale('titles:orderRatesTotal', 'Order Rates Total'),
  payrollPerCharge: G.getWindowLocale('titles:payrollPerCharge', 'Payroll Per Charge'),
  averageIssuesAge: G.getWindowLocale('titles:averageIssuesAge', 'Average Issues Age'),
  idleTrailersTime: G.getWindowLocale('titles:idle-trailers-time', 'Idle Trailers Time'),
  trailersByCarrier: G.getWindowLocale('titles:trailersByCarrier', 'Trailers By Carrier'),
  availableTrailers: G.getWindowLocale('titles:available-trailers', 'Available Trailers'),
  ordersNotInvoiced: G.getWindowLocale('titles:ordersNotInvoiced', 'Orders Not Invoiced'),
  fleetServiceTotal: G.getWindowLocale('titles:fleetServiceTotal', 'Fleet Service Total'),
  inServiceTrailers: G.getWindowLocale('titles:inServiceTrailers', 'In Service Trailers'),
  fromLast: G.getWindowLocale('titles:from-last', 'from last', { caseAction: 'lowerCase' }),
  trailersByLocation: G.getWindowLocale('titles:trailersByLocation', 'Trailers By Location'),
  trailersByIdleTime: G.getWindowLocale('titles:trailersByIdleTime', 'Trailers By Idle Time'),
  driversAvailability: G.getWindowLocale('titles:driversAvailability', 'Drivers Availability'),
  carrierInvoiceTotal: G.getWindowLocale('titles:carrierInvoiceTotal', 'Carrier Invoice Total'),
  fromSelectedRange: G.getWindowLocale('titles:from-selected-range', 'from selected date range'),
  completedInspections: G.getWindowLocale('titles:completedInspections', 'Completed Inspections'),
  carrierInvoiceStatus: G.getWindowLocale('titles:carrierInvoiceStatus', 'Carrier Invoice Status'),
  customerInvoiceStatus: G.getWindowLocale('titles:customerInvoiceStatus', 'Customer Invoice Status'),
  averageInspectionTime: G.getWindowLocale('titles:averageInspectionTime', 'Average Inspection Time'),
  carrierInvoicePerMode: G.getWindowLocale('titles:carrierInvoicePerMode', 'Carrier Invoice per Mode'),
  customerInvoicePerMode: G.getWindowLocale('titles:customerInvoicePerMode', 'Customer Invoice per Mode'),
  fromYesterday: G.getWindowLocale('titles:fromYesterday', 'from yesterday', { caseAction: 'lowerCase' }),
  openedIssuesByPriority: G.getWindowLocale('titles:openedIssuesByPriority', 'Opened Issues By Priority'),
  inOutOfServiceTrailers: G.getWindowLocale('titles:inOutOfServiceTrailers', 'In/Out Of Service Trailers'),
};

export {
  DAY_3,
  DAY_7,
  DAY_14,
  DAY_30,
  DAY_RANGE,
  TITLES_MAP,
  CHART_COLORS,
  dataRangeEnumLocale,
  DASHBOARD_TYPE_FLEET_GENERAL,
  DASHBOARD_TYPE_FLEET_TRAILER,
  DASHBOARD_TYPE_FLEET_ACCOUNTING,
};
