import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// helpers/constants
import * as GC from '../../../../constants';
// sagas
import { visitPageSaga } from '../../../../sagas';
// feature configs
import { getConfigByGroupSaga } from '../../sagas';
//////////////////////////////////////////////////

function* handleVisitConfigPasswordPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_PASSWORD_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getConfigByGroupSaga, GC.PASSWORD_CONFIG_GROUP);
    yield put(closeLoader());
    break;
  }
}

function* passwordConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_PASSWORD_PAGE, handleVisitConfigPasswordPageSaga);
}

export default passwordConfigWatcherSaga;
