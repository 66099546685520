import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useRef, useState, useCallback, useEffect } from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Flex, MainActionButton } from '../../../../ui';
// feature claim-management
import ClaimForm from '../../components/claim-form';
import { makeSelectHeaderInfo } from '../../selectors';
import { createOrUpdateClaimRequest } from '../../actions';
import { useCreateDropdownOptionsFromAsyncConfigs } from '../../hooks';
//////////////////////////////////////////////////

const UpdateClaimForm = (props: Object) => {
  const { dispatch, claimGuid, openLoader, closeLoader, claimBranchGuid } = props;

  const dropdownOptions = useCreateDropdownOptionsFromAsyncConfigs({
    branchGuid: claimBranchGuid,
    names: [GC.CLAIM_TYPE, GC.CLAIM_STATUS, GC.CLAIM_REASON, GC.CLAIM_SUB_STATUS],
  });

  const submitAction = (values: Object) => dispatch(createOrUpdateClaimRequest({ ...values, fromDetails: true }));

  return (
    <ClaimForm
      claimGuid={claimGuid}
      openLoader={openLoader}
      closeLoader={closeLoader}
      submitAction={submitAction}
      claimBranchGuid={claimBranchGuid}
      configGroup={{ dropdownOptions }}
    />
  );
};

const InfoPair = ({ text, label }: Object) => {
  if (R.isNil(text)) return null;

  return (
    <Flex mr={15} fontSize={16} color='darkGrey'>
      {label}:
      <TextComponent
        ml='5px'
        title={text}
        maxWidth={200}
        fontWeight='bold'
        withEllipsis={true}
        color='greyMatterhorn'
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

const Header = (props: Object) => {
  const { dispatch, claimGuid, setHeight, openModal, openLoader, closeLoader, claimBranchGuid } = props;

  const ref = useRef();

  const headerInfo = useSelector(makeSelectHeaderInfo());

  const { claimDate, claimNumber, claimType, claimReason, claimStatus, claimSubStatus } = headerInfo;

  const handleUpdateClaim = useCallback(() => {
    const component = (
      <UpdateClaimForm
        dispatch={dispatch}
        claimGuid={claimGuid}
        openLoader={openLoader}
        closeLoader={closeLoader}
        claimBranchGuid={claimBranchGuid}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        title: G.getEditTitle(['titles:claim', 'Claim']),
      },
    };

    openModal(modal);
  }, []);

  useEffect(() => {
    const handleResize = (entries: Array) => {
      setHeight(R.path([0, 'contentRect', 'height'], entries));
    };

    const observer = new ResizeObserver(G.setDebounce(handleResize, 100));

    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Flex py={25} bg='white'>
      <Flex
        px={25}
        mx='auto'
        ref={ref}
        width='100%'
        maxWidth={1250}
        flexWrap='wrap'
      >
        <Flex mr={15} fontSize={22} fontWeight='bold' color='greyMatterhorn'>
          {G.getWindowLocale('titles:claim', 'Claim')}:
          <TextComponent
            ml='5px'
            maxWidth={300}
            withEllipsis={true}
            title={claimNumber}
          >
            {claimNumber}
          </TextComponent>
        </Flex>
        <InfoPair text={claimStatus} label={G.getWindowLocale('titles:status', 'Status')} />
        <InfoPair text={claimSubStatus} label={G.getWindowLocale('titles:sub-status', 'Sub Status')} />
        <InfoPair text={claimType} label={G.getWindowLocale('titles:type', 'type')} />
        <InfoPair text={claimReason} label={G.getWindowLocale('titles:reason', 'Reason')} />
        <InfoPair text={claimDate} label={G.getWindowLocale('titles:date', 'Date')} />
        <MainActionButton height={28} width={82} onClick={() => handleUpdateClaim()}>
          {G.getWindowLocale('titles:edit-claim', 'Edit Claim')}
        </MainActionButton>
      </Flex>
    </Flex>
  );
};

export default Header;
