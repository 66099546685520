import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormGroupTable } from '../../../../components/form-group-table';
import AddAdvancePaymentForm from '../../../../components/advance-payment/add-advance-payment-form';
import UpdateAdvancePaymentStatusForm from '../../../../components/advance-payment/update-advance-payment-status-form';
// features
import { advancePaymentColumnSettings } from '../../../dispatch-details-new/settings/column-settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-driver
import { makeSelectAdvancePaymentList } from '../selectors';
import { DRIVER_GROUP_NAME_ADVANCE_PAYMENT } from '../constants';
import { createAdvancePaymentRequest, updateAdvancePaymentStatusRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleAddAdvancePayment: (props: Object) => () => {
      const { formValues, openModal, closeModal, createAdvancePaymentRequest } = props;
      const driverFullName = `${R.prop(GC.FIELD_FIRST_NAME, formValues)} ${R.prop(GC.FIELD_LAST_NAME, formValues)}`;
      const initialValues = {
        [GC.FIELD_DRIVER_GUID]: R.prop(GC.FIELD_GUID, formValues),
        [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: R.prop(GC.FIELD_FLEET_LOGIN_ID, formValues),
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: R.prop(GC.FIELD_LAST_NAME, formValues),
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: R.prop(GC.FIELD_FIRST_NAME, formValues),
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: R.prop(GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER, formValues),
      };
      const selectedRateDriverOptions = [{
        [GC.FIELD_LABEL]: driverFullName,
        [GC.FIELD_VALUE]: R.prop(GC.FIELD_GUID, formValues),
      }];
      const component = (
        <AddAdvancePaymentForm
          closeModal={closeModal}
          initialValues={initialValues}
          disableSelectDriverField={true}
          submitAction={createAdvancePaymentRequest}
          optionsForSelect={{ selectedRateDriverOptions }} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 660,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:add-advance-payment', 'Add Advance Payment'),
        },
      };
      openModal(modal);
    },
    handleUpdateAdvancePaymentStatus: (props: Object) => ({ guid, status }: string) => {
      const { closeModal, openModal, updateAdvancePaymentStatusRequest } = props;
      const initialValues = { guid };
      const component = (
        <UpdateAdvancePaymentStatusForm
          status={status}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={updateAdvancePaymentStatusRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 320,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:update-advance-payment-status', 'Update Advance Payment Status'),
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const AdvancePaymentTab = (props: Object) => (
  <FormGroupTable
    notDeletable={true}
    fields={props.collapsedGroup}
    entities={props.advancePaymentList}
    entitiesFields={advancePaymentColumnSettings}
    groupName={DRIVER_GROUP_NAME_ADVANCE_PAYMENT}
    handleAddClick={props.handleAddAdvancePayment}
    handleEditRow={props.handleUpdateAdvancePaymentStatus}
    panelTitle={G.getWindowLocale('titles:advance-payment', 'Advance Payment')}
    isOpened={R.path(['collapsedGroup', DRIVER_GROUP_NAME_ADVANCE_PAYMENT], props)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(DRIVER_GROUP_NAME_ADVANCE_PAYMENT)} />
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  advancePaymentList: makeSelectAdvancePaymentList(state),
}));

export default connect(mapStateToProps, {
  createAdvancePaymentRequest,
  updateAdvancePaymentStatusRequest,
})(enhance(AdvancePaymentTab));
