import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// common
import { openModal } from '../../../../components/modal/actions';
// features
import RouteMap from '../../../geo-fencing-location/components/route-map';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { ENUMS } from '../../../../constants/enums';
// icons
import * as I from '../../../../svgs';
// ui
import { IconWrapper } from '../../../../ui';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.white');

const settings = {
  [GC.FIELD_LOG_DATE]: {
    type: 'date',
    name: 'titles:date',
  },
  [`${GC.SYSTEM_OBJECT_GEOFENCING_LOCATION}.${GC.FIELD_NAME}`]: {
    width: 150,
    name: 'titles:location-name',
  },
  [GC.FIELD_TYPE]: {
    type: 'enum',
    name: 'titles:type',
    filterType: 'string',
    options: [
      {
        value: ENUMS.ENUM_ARRIVED,
        label: G.getEnumLocale(ENUMS.ENUM_ARRIVED),
      },
      {
        value: ENUMS.ENUM_DEPARTED,
        label: G.getEnumLocale(ENUMS.ENUM_DEPARTED),
      },
    ],
  },
  [GC.FIELD_CITY]: {
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    width: 120,
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    width: 120,
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    width: 150,
    name: 'titles:country',
  },
  [GC.FIELD_LONGITUDE]: {
    width: 150,
    filterType: 'number',
    name: 'titles:longitude',
  },
  [GC.FIELD_LATITUDE]: {
    width: 150,
    filterType: 'number',
    name: 'titles:latitude',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:updated-date',
  },
};

const columnSettings = R.map(R.pick(['type', 'name', 'width', 'sortable', 'searchable', 'customComponent']), settings);

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = R.mapObjIndexed((
  { name, options, collection, filterType, disableFilter, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  disableFilter,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.getWindowLocale(name),
}), settings);

const reportFields = G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings));

const makeRequestPayload = (props: Object) => {
  const { primaryObjectGuid, primaryObjectBranchGuid } = props;

  return {
    fields: reportFields,
    [GC.CURRENT_BRANCH]: primaryObjectBranchGuid,
    searchCriteria: [
      {
        operation: 'equal',
        dataType: 'string',
        stringValue: primaryObjectGuid,
        propertyName: GC.FIELD_TRAILER_GUID,
      },
    ],
  };
};

const AdditionalFormGroupTitleComponent = ({
  itemList,
}: Object) => {
  const dispatch = useDispatch();

  const handleShowMap = useCallback(() => {
    const component = (
      <RouteMap routeHistoryList={itemList} />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        movable: false,
        height: 'auto',
        width: '660px',
        withCloseIcon: true,
        title: G.getWindowLocale('titles:trailer-tracking', 'Trailer Tracking'),
      },
    };

    dispatch(openModal(modal));
  }, [itemList]);

  if (G.isNilOrEmpty(itemList)) return null;

  return (
    <IconWrapper
      ml={10}
      cursor='pointer'
      onClick={handleShowMap}
      title={G.getWindowLocale('actions:show-on-a-map', 'Show Map')}
    >
      {I.onMap(iconColor, 18, 18)}
    </IconWrapper>
  );
};

export const trailerTrackingSettings = {
  filterProps,
  columnSettings,
  isReport: true,
  actionsPicker: [],
  withoutTitlePanel: true,
  useSearchableColumns: true,
  withResizableColumns: true,
  useNewTitleFilterInputs: true,
  report: { fields: reportFields },
  groupName: 'trailerTrackingList',
  AdditionalFormGroupTitleComponent,
  additionalTableSettings: { searchableTitles: true },
  itemTitleArr: ['titles:trailer-tracking', 'Trailer Tracking'],
  formGroupTitleArr: ['titles:trailer-tracking', 'Trailer Tracking'],
  endpoints: {
    list: 'trailerMilestoneLogList',
  },
  // helpers
  makeRequestPayload,
};
