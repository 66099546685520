import * as R from 'ramda';
import React, { useState, Fragment } from 'react';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
//////////////////////////////////////////////////

const blackColor = G.getTheme('colors.black');

export const SectionHeader = (props: Object) => {
  const { title, children } = props;

  const [expanded, setExpanded] = useState(R.pathOr(false, ['expanded'], props));

  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Fragment>
      <Flex
        p='5px'
        mb='5px'
        cursor='pointer'
        color={blackColor}
        justifyContent='center'
        onClick={() => setExpanded(R.not)}
        bg={G.getTheme('colors.whiteGrey')}
      >
        <Box ml='auto'>{title}</Box>
        <Flex px='6px' ml='auto'>
          {I[toggleIconName](blackColor)}
        </Flex>
      </Flex>
      {G.isTrue(expanded) && children}
    </Fragment>
  );
};
