import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const fields = {
  [GC.FIELD_CONTACT_NAME]: {
    isRequired: true,
    name: 'titles:contact-name',
  },
  [GC.FIELD_CONTACT_TYPE]: {
    isRequired: true,
    name: 'titles:contact-type',
  },
  [GC.FIELD_TITLE]: {
    name: 'titles:position',
  },
  [GC.FIELD_PHONE]: {
    type: 'phoneNumber',
    name: 'titles:phone',
  },
  [GC.FIELD_PHONE_EXTENSION]: {
    type: 'number',
    name: 'titles:phone-extension',
  },
  [GC.FIELD_EMAIL]: {
    name: 'titles:email',
  },
  [GC.FIELD_FAX]: {
    name: 'titles:fax',
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CONTACT_NAME]: G.yupStringRequired,
  [GC.FIELD_CONTACT_TYPE]: G.yupStringRequired,
  [GC.FIELD_PHONE_EXTENSION]: Yup.string()
    .nullable(true)
    .max(5, G.getShouldBeFromToCharLocaleTxt(0, 5)),
  [GC.FIELD_FAX]: Yup.string()
    .nullable(true)
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
  [GC.FIELD_PHONE]: Yup.string()
    .nullable(true)
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
  [GC.FIELD_TITLE]: Yup.string()
    .nullable(true)
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .email(G.getShouldBeEmailLocaleTxt())
    .max(255, G.getShouldBeFromToCharLocaleTxt(0, 255)),
});

const inputWrapperStyles = {
  mb: 25,
  width: 200,
};

const fieldsWrapperStyles = {
  width: 660,
};

export const additionalContactSettings = {
  fields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'additionalContact',
  itemTitleArr: ['titles:additional-contact', 'Additional Contact'],
  formGroupTitleArr: ['titles:additional-contacts', 'Additional Contacts'],
  endpoints: {
    list: 'additionalCarrierContactList',
    remove: 'removeAdditionalCarrierContact',
    createOrUpdate: 'additionalCarrierContact',
  },
};
