import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, ActionButton } from '../../../../ui';
// feature dispatch details new
import { approveCloRequest } from '../actions';
//////////////////////////////////////////////////

const CloApproval = ({
  guid,
  approved,
  fromPage,
  tableIndex,
  approvedBy,
  approveCloRequest,
}: Object) => (
  <Flex
    mb='8px'
    fontSize={12}
    fontWeight='bold'
    textTransform='uppercase'
    color={G.getTheme('colors.greyMatterhorn')}
  >
    {G.getWindowLocale('titles:approved', 'Approved')}
    <Box ml='5px'>{G.ifElse(approved, I.uiTrue, I.uiFalse)()}</Box>
    <Box ml={10}>
      {approved && `${G.getWindowLocale('titles:approved-by', 'Approved By')}: ${approvedBy}`}
      {
        R.and(G.isFalse(approved), G.hasAmousCurrentUserPermissions(PC.CLO_APPROVE_EXECUTE)) &&
        <ActionButton
          height={20}
          type='button'
          fontSize={12}
          minWidth={80}
          fontWeight='bold'
          borderRadius='4px'
          onClick={() => approveCloRequest({ guid, fromPage, tableIndex })}
        >
          {G.getWindowLocale('titles:approve', 'Approve')}
        </ActionButton>
      }
    </Box>
  </Flex>
);

const enhance = compose(
  connect(null, { approveCloRequest }),
  pure,
);

export default enhance(CloApproval);
