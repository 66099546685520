import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// helpers/constants
import * as GC from '../../../../constants';
// sagas
import { visitPageSaga } from '../../../../sagas';
// feature configs
import { getConfigByGroupSaga, getSequencesByTypeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield call(getConfigByGroupSaga, GC.CLAIM_CONFIG_GROUP);
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_CLAIM });

    yield put(closeLoader());
    break;
  }
}

function* claimManagementConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE, handleVisitPageSaga);
}

export default claimManagementConfigWatcherSaga;
