import * as R from 'ramda';
import randomColor from 'randomcolor';
import { connect } from 'react-redux';
import React, { memo, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { ItemIcons } from '../../../components/item-icons';
import { PopperComponent } from '../../../components/popper';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withHoveredZIndex } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  RelativeBox,
  AbsoluteBox,
  IconWrapper,
  RelativeFlex,
  PageTitleCount,
  IconWrapperPositioned,
  scrollableContainerCss3px,
} from '../../../ui';
// utilities
import T from '../../../theme';
// features drivers-card
import { makeSelectZoom } from '../selectors';
import { CARD_HEIGHT_170 } from '../constants';
import { getDuration, getMainCardWidth, getCardDayMultiplier } from '../helpers';
//////////////////////////////////////////////////

export const getCardWidth = (
  width: number,
  firstEventDate: string,
  lastEventDate: string,
  mainCardWidth: number,
  cardDayMultiplier: number,
  show24HoursView: boolean,
  filtersStore: number,
) => {
  if (R.equals(width, 'calculate')) {
    const dateTo = R.path(['dateTo'], filtersStore);

    const endOfDateTo = G.toEndOfDayFromDate(dateTo);

    const lastEventDateToUse = G.ifElse(
      G.isAfter(lastEventDate, endOfDateTo),
      endOfDateTo,
      lastEventDate,
    );

    const duration = getDuration(firstEventDate, lastEventDateToUse);

    if (G.isNilOrEmpty(duration)) return null;

    const { days, hours, minutes } = duration;

    const minutesToUse = Math.round(R.multiply(R.divide(cardDayMultiplier, 60), minutes));

    const widthToUse = R.add(
      R.add(R.multiply(days, mainCardWidth), R.multiply(hours, cardDayMultiplier)),
      minutesToUse,
    );

    if (R.and(R.lte(widthToUse, 240), G.isNilOrFalse(show24HoursView))) return 240;

    // NOTE: min card UI for not overlaps
    if (R.lte(widthToUse, 100)) return 100;

    return widthToUse;
  }

  return width;
};

export const getCardLeftPosition = (
  fromCards: any,
  filtersStore: number,
  firstEventDate: string,
  mainCardWidth: number,
  cardDayMultiplier: number,
) => {
  if (G.isTrue(fromCards)) {
    const { dateFrom } = filtersStore;

    const duration = getDuration(dateFrom, firstEventDate);

    // TODO: check what return here or validate start/end dates
    if (G.isNilOrEmpty(duration)) return null;

    const { days, hours, minutes } = duration;

    const minutesToUse = Math.round(R.multiply(R.divide(cardDayMultiplier, 60), minutes));
    const left = R.add(
      R.add(R.multiply(days, mainCardWidth), R.multiply(hours, cardDayMultiplier)),
      minutesToUse,
    );

    if (G.isBefore(firstEventDate, dateFrom)) return R.negate(left);

    return left;
  }

  return null;
};

export const getClosInfo = (clos: Array) => {
  if (G.isNilOrEmpty(clos)) return null;

  const string = G.createStringFromArrayWithProp(clos, ', ', GC.FIELD_BRANCH_BRANCH_NAME);

  if (G.isNilOrEmpty(string)) return null;

  return string;
};

const darkBlueColor = G.getTheme('colors.dark.blue');
const lightBlueColor = G.getTheme('colors.light.blue');
const mainLightColor = G.getTheme('colors.light.mainLight');
const stopRejectColor = G.getTheme('dispatchBoardPopper.stopRejectColor');
const stopSuccessColor = G.getTheme('dispatchBoardPopper.stopSuccessColor');

const enhance = compose(
  withPropsOnChange(['guid'], () => ({ focusRandomColor: randomColor() })),
  withHandlers({
    onClickOpenTelDetails: (props: Object) => () => {
      const { editable, handleOpenTelDetails } = props;

      if (G.isFalse(editable)) return;

      handleOpenTelDetails(props);
    },
    onClickShowNotifications: (props: Object) => (e: Object) => {
      const { guid, telNotifications, handleShowNotifications } = props;

      const notifications = R.pathOr({}, [guid], telNotifications);

      handleShowNotifications(e, notifications, guid);
    },
    onClickOpenChatMessages: (props: Object) => () => props.handleOpenChatMessages(props),
    onClickEditIcon: (props: Object) => (e: Object) => props.handleClickEditIcon(e, props),
    onClickShowEvents: (props: Object) => () => {
      const { guid, enterprise, handleShowEvents } = props;

      const branchGuid = R.pathOr('', [GC.FIELD_GUID], enterprise);

      handleShowEvents(guid, branchGuid);
    },
  }),
);

const Card = enhance(memo((props: Object) => {
  const {
    clos,
    zoom,
    guid,
    width,
    status,
    editable,
    cardIndex,
    lastEvent,
    routeGuid,
    cardHeight,
    firstEvent,
    enterprise,
    filtersStore,
    hoveredZIndex,
    show24HoursView,
    onClickEditIcon,
    focusRandomColor,
    telNotifications,
    expandedContainer,
    onClickShowEvents,
    handleZIndexOnHover,
    onClickOpenTelDetails,
    handleZIndexOnUnHover,
    primaryReferenceValue,
    onClickOpenChatMessages,
    onClickShowNotifications,
    expandedContainerOptions,
    allContainersRailBillingStatusCompleted,
  } = props;

  const zIndex = R.subtract(1000, cardIndex);
  const mainCardWidth = getMainCardWidth(show24HoursView, zoom);
  const cardDayMultiplier = getCardDayMultiplier(show24HoursView, zoom);
  const closInfo = getClosInfo(clos);
  const lastEventDate = R.prop('lateDate', lastEvent);
  const firstEventDate = R.prop('earlyDate', firstEvent);
  const statusBorderColor = R.path(['status', status], T);
  const branchName = R.pathOr('', [GC.FIELD_BRANCH_NAME], enterprise);

  const widthToUse = useMemo(() => getCardWidth(
    width,
    firstEventDate,
    lastEventDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  ), [
    width,
    firstEventDate,
    lastEventDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  ]);

  if (R.and(R.equals(width, 'calculate'), G.isNilOrEmpty(widthToUse))) return null;

  const showInfo = R.or(G.isNilOrEmpty(widthToUse), R.gt(widthToUse, 400));
  const showMainInfo = R.gt(widthToUse, 200);

  const left = useMemo(() => getCardLeftPosition(
    true,
    filtersStore,
    firstEventDate,
    mainCardWidth,
    cardDayMultiplier,
  ), [
    filtersStore,
    firstEventDate,
    mainCardWidth,
    cardDayMultiplier,
  ]);

  const notifications = R.pathOr({}, [guid], telNotifications);

  const notificationCount = R.compose(
    R.length,
    R.filter((item: Object) => G.isFalse(item.warningHidden)),
    R.values,
  )(notifications);

  const itemsCount = R.pathOr(0, [GC.FIELD_ITEMS_INFO, GC.FIELD_COUNT], props);
  const anyHazardous = R.path([GC.FIELD_ITEMS_INFO, GC.FIELD_ANY_HAZARDOUS], props);
  const temperatureLow = R.path([GC.FIELD_ITEMS_INFO, GC.FIELD_ITEM_TEMPERATURE_LOW], props);
  const temperatureHigh = R.path([GC.FIELD_ITEMS_INFO, GC.FIELD_ITEM_TEMPERATURE_HIGH], props);

  const fromPage = R.path(['options', 'fromPage'], expandedContainerOptions);

  const notEditableFromExpanded = R.and(
    expandedContainer,
    R.or(
      R.and(R.equals(fromPage, GC.PAGE_DISPATCH_PLANNER), R.isNil(routeGuid)),
      R.and(R.equals(fromPage, GC.PAGE_DISPATCH_PLANNER_EVENTS), G.isNotNil(routeGuid)),
    ),
  );

  const focusRight = R.add(5, R.multiply(cardIndex, 10));

  return (
    <Flex
      p='2px'
      left={left}
      alignItems='start'
      borderRadius='3px'
      border='1px solid'
      bg={mainLightColor}
      position='absolute'
      height={cardHeight}
      flexDirection='column'
      borderLeft='3px solid'
      justifyContent='space-between'
      width={R.or(widthToUse, 'auto')}
      onMouseEnter={handleZIndexOnHover}
      borderLeftColor={statusBorderColor}
      onMouseLeave={handleZIndexOnUnHover}
      zIndex={R.or(hoveredZIndex, zIndex)}
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
      borderColor={G.getTheme('colors.light.darkGrey')}
    >
      <Box width='100%' height='100%' overflow='auto' css={scrollableContainerCss3px}>
        <Flex width='100%' justifyContent='space-between'>
          <Flex maxWidth='calc(100% - 80px)'>
            <Box
              mr={10}
              fontWeight={600}
              cursor='pointer'
              color={darkBlueColor}
              textDecoration='underline'
              onClick={onClickOpenTelDetails}
              title={G.getWindowLocale('actions:go-to-tel', 'Go to TEL')}
            >
              <TextComponent
                maxWidth={150}
                display='block'
                withEllipsis={true}
                title={primaryReferenceValue}
                fontSize={G.ifElse(R.lt(widthToUse, 300), '10px', '14px')}
              >
                {primaryReferenceValue}
              </TextComponent>
            </Box>
            {
              showMainInfo && branchName &&
              <PopperComponent
                zi={1002}
                type='hover'
                position='top'
                content={
                  <Box>
                    {`${G.getWindowLocale('titles:branch', 'Branch')}: ${branchName}`}
                  </Box>
                }
                borderColor={G.getTheme('listActions.borderColor')}
              >
                <IconWrapper pr={10}>{I.hierarchy(darkBlueColor)}</IconWrapper>
              </PopperComponent>
            }
            {
              showMainInfo &&
              <Box mr='5px' color={statusBorderColor} fontSize={G.ifElse(R.lt(widthToUse, 300), '10px', '14px')}>
                {G.getWindowLocale(GC.statusLocaleMap[status])}
              </Box>
            }
            {
              showInfo && closInfo &&
              <Flex mr={10} fontWeight={600}>
                <TextComponent
                  maxWidth={145}
                  title={closInfo}
                  withEllipsis={true}
                  color={darkBlueColor}
                  display='inline-block'
                >
                  {closInfo}
                </TextComponent>
              </Flex>
            }
          </Flex>
          <Flex pt='3px'>
            <RelativeFlex mx='6px' title={`${G.getWindowLocale('titles:items')} - ${itemsCount}`}>
              {I.itemBox(lightBlueColor, 13, 13)}
              <AbsoluteBox
                height={13}
                zIndex={11}
                minWidth={13}
                fontSize='9px'
                borderRadius='8px'
                border='1px solid'
                bg={lightBlueColor}
                color={mainLightColor}
                left='calc(100% - 7px)'
                justifyContent='center'
                bottom='calc(100% - 7px)'
                borderColor={mainLightColor}
              >
                {itemsCount}
              </AbsoluteBox>
            </RelativeFlex>
            {
              showMainInfo && G.isTrue(editable) && G.isNotNilAndNotEmpty(notifications) &&
              <RelativeFlex
                mr='6px'
                ml='auto'
                onClick={onClickShowNotifications}
              >
                {I.bellIcon(lightBlueColor, 11, 13)}
                {
                  G.isNotNilAndNotZero(notificationCount) &&
                  <AbsoluteBox
                    height={16}
                    minWidth={16}
                    fontSize='9px'
                    borderRadius='8px'
                    border='2px solid'
                    bg={lightBlueColor}
                    color={mainLightColor}
                    left='calc(100% - 7px)'
                    justifyContent='center'
                    bottom='calc(100% - 7px)'
                    borderColor={mainLightColor}
                  >
                    {notificationCount}
                  </AbsoluteBox>
                }
              </RelativeFlex>
            }
            {
              showMainInfo && G.isTrue(editable) &&
              <IconWrapperPositioned
                pt='5px'
                mx='5px'
                position='relative'
                onClick={onClickOpenChatMessages}
              >
                {
                  G.isNotNilAndNotEmpty(R.path(['lastChatAuthorType'], props)) &&
                  <PageTitleCount
                    r='-5px'
                    t='-5px'
                    width={16}
                    height={16}
                    fontSize='9px'
                    bgColor={lightBlueColor}
                  >
                    1
                  </PageTitleCount>
                }
                {
                  I.renderChatIcon(lightBlueColor, 15, 13)
                }
              </IconWrapperPositioned>
            }
            {
              R.and(G.isTrue(editable), R.not(notEditableFromExpanded)) &&
              <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>
                <IconWrapper
                  pr='1px'
                  pl='3px'
                  cursor='pointer'
                  onClick={onClickEditIcon}
                >
                  {I.threePointer(darkBlueColor, 12, 12)}
                </IconWrapper>
              </AuthWrapper>
            }
          </Flex>
        </Flex>
        <Flex
          width='100%'
          zIndex={zIndex}
          height={R.subtract(cardHeight, 32)}
        >
          <Flex
            py={10}
            width='100%'
            height='100%'
            fontSize={12}
            alignItems='start'
            justifyContent='space-between'
            flexDirection={G.ifElse(R.equals(cardHeight, CARD_HEIGHT_170), 'column', 'row')}
          >
            <Flex mr={10}>
              <PopperComponent
                zi={1002}
                type='hover'
                position='top'
                content={
                  <Box>
                    <Box>{firstEventDate}</Box>
                    <Box>{G.concatLocationFields(firstEvent.location)}</Box>
                  </Box>
                }
                borderColor={G.getTheme('listActions.borderColor')}
              >
                <Flex
                  mr='5px'
                  p='1px 4px'
                  fontSize={11}
                  fontWeight='bold'
                  border='1px solid'
                  borderRadius='8px'
                  color={stopSuccessColor}
                  borderColor={stopSuccessColor}
                  bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                >
                  P-{R.pathOr(1, ['eventsInfo', 'pickupCount'], props)}
                </Flex>
              </PopperComponent>
              <Box>{G.concatLocationFields(firstEvent.location, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
            </Flex>
            {
              showMainInfo && G.isTrue(editable) &&
              <Flex mb='-3px' minWidth={75} flexDirection='column'>
                {
                  G.isOneNotNilOrNotEmpty([anyHazardous, temperatureLow, temperatureHigh]) &&
                  <ItemIcons
                    smallIcons={true}
                    anyHazardous={anyHazardous}
                    temperatureLow={temperatureLow}
                    temperatureHigh={temperatureHigh}
                  />
                }
                <Flex
                  fontSize={10}
                  cursor='pointer'
                  color={darkBlueColor}
                  onClick={onClickShowEvents}
                >
                  <Box>{G.getWindowLocale('titles:show-stops', 'Show Stops')}</Box>
                  {
                    allContainersRailBillingStatusCompleted &&
                    <Box
                      pl='5px'
                      title={G.getWindowLocale('titles:rail-billing-completed', 'Rail Billing Completed')}
                    >
                      {I.checkMarkInRound()}
                    </Box>
                  }
                </Flex>
              </Flex>
            }
            <Flex>
              <PopperComponent
                zi={1002}
                type='hover'
                position='top'
                content={
                  <Box>
                    <Box>{lastEventDate}</Box>
                    <Box>{G.concatLocationFields(lastEvent.location)}</Box>
                  </Box>
                }
                borderColor={G.getTheme('listActions.borderColor')}
              >
                <Flex
                  mr='5px'
                  p='1px 4px'
                  fontSize={11}
                  fontWeight='bold'
                  border='1px solid'
                  borderRadius='8px'
                  color={stopRejectColor}
                  borderColor={stopRejectColor}
                  bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                >
                  D-{R.pathOr(1, ['eventsInfo', 'dropCount'], props)}
                </Flex>
              </PopperComponent>
              <Box>{G.concatLocationFields(lastEvent.location, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Flex width='100%' justifyContent='flex-end'>
        <RelativeBox>
          <AbsoluteBox
            top='2px'
            width={20}
            height='5px'
            borderRadius='3px'
            right={focusRight}
            bg={focusRandomColor}
          />
        </RelativeBox>
      </Flex>
    </Flex>
  );
}));

const mapStateToProps = (state: Object) => createStructuredSelector({
  zoom: makeSelectZoom(state),
});

export default connect(mapStateToProps, {})(withHoveredZIndex({ zIndex: 1001 })(Card));
