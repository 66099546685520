import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// features
import { makeSelectDatepickerMuiValue } from '../selectors';
// forms
import { DatePickerMui } from '../../../components/datepicker-mui';
// feature components story datepicker
import { setDatepickerMuiValue } from '../actions';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const DatePicker = ({
  datepickerMuiValue,
  setDatepickerMuiValue,
}: Object) => {
  const setMaxDate = () => moment().add(100, 'years');

  const setMinDate = () => moment().subtract(100, 'years');

  const handleChange = (value: Object) => setDatepickerMuiValue(value);

  return (
    <DatePickerMui
      m={0}
      width={200}
      disablePortal={true}
      maxDate={setMaxDate()}
      minDate={setMinDate()}
      withCalendarIcon={true}
      onChange={handleChange}
      value={datepickerMuiValue}
      placeholder={'Set any date...'}
      inputSettings={{
        fontSize: 12,
        p: '0 0 0 3px',
      }}
    />
  );
};

const DatePickerMuiComponent = ({
  datepickerMuiValue,
  setDatepickerMuiValue,
}: Object) => {
  const description = '<DatePickerMui /> component is used to set a date.';

  const usage = (
    <ul>
      <li>Set field `<i>value</i>` as default value.</li>
      <li>Set selected date value to store via `<i>onChange</i>` callback.</li>
      <li>You can set `<i>minDate</i>` & `<i>maxDate</i>` or leave them empty.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='DatePickerMui'
      description={description}
      path='src/components/datepicker-mui'
    >
      <DatePicker
        datepickerMuiValue={datepickerMuiValue}
        setDatepickerMuiValue={setDatepickerMuiValue}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  datepickerMuiValue: makeSelectDatepickerMuiValue(state),
}));

export default connect(mapStateToProps, {
  setDatepickerMuiValue,
})(DatePickerMuiComponent);
