import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setSearchDate = createAction('setSearchDate');
export const setTruckTypes = createAction('setTruckTypes');
export const setActiveList = createAction('setActiveList');
export const setListLoading = createAction('setListLoading');
export const setSearchRadius = createAction('setSearchRadius');
export const setInitialState = createAction('setInitialState');
export const changeActiveList = createAction('changeActiveList');
export const setLocationValue = createAction('setLocationValue');
export const setDriverInitials = createAction('setDriverInitials');
export const setSearchLocation = createAction('setSearchLocation');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const deleteDriverNoteRequest = createAction('deleteDriverNoteRequest');
export const deleteReservationRequest = createAction('deleteReservationRequest');
export const getSearchLocationRequest = createAction('getSearchLocationRequest');
export const setDriverAvailableRequest = createAction('setDriverAvailableRequest');
export const setDriverUnavailableRequest = createAction('setDriverUnavailableRequest');
export const createOrUpdateDriverNoteRequest = createAction('createOrUpdateDriverNoteRequest');
export const createOrUpdateReservationRequest = createAction('createOrUpdateReservationRequest');
export const getDriverUnavailablePeriodRequest = createAction('getDriverUnavailablePeriodRequest');
// all drivers
export const setAllDriversListLoading = createAction('setAllDriversListLoading');
export const getAllDriversListRequest = createAction('getAllDriversListRequest');
export const getAllDriversListSuccess = createAction('getAllDriversListSuccess');
export const resetAllDriversListAndPagination = createAction('resetAllDriversListAndPagination');
export const getDriverAvailabilityTotalCountRequest = createAction('getDriverAvailabilityTotalCountRequest');
export const getDriverAvailabilityTotalCountSuccess = createAction('getDriverAvailabilityTotalCountSuccess');
