import { space } from 'styled-system';
import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { PageWrapper } from '../../ui';
//////////////////////////////////////////////////

export const AnalyticsPageWrapper = styled(PageWrapper)`
  overflow: hidden;
  padding-bottom: 0;
`;

export const ChartViewWrapper = styled.div`
  transition: margin-right 0.2s linear;
  margin-right: ${({ isOpen }: Object) => G.ifElse(isOpen, '250px', '0')};
  & > div:first-child {
    align-items: center;
    display: flex;
    justify-content: space-between;
    & > div:nth-child(2) > div {
      display: flex;
      margin: 0 15px;
    }
  }
  & > div:nth-child(2) {
    border: ${() => `1px solid ${G.getTheme('charts.borderColor')}`};
  }
`;

export const RightSidebarWrapper = styled.div`
  top: 0;
  width: 250px;
  position: absolute;
  height: calc(100vh - 60px);
  padding: 10px 15px 0 15px;
  transition: right 0.2s linear;
  background: ${() => G.getTheme('colors.dark.lightGrey')};
  right: ${({ isOpen }: Object) => G.ifElse(isOpen, '0', '-250px')};
`;

export const ToggleButton = styled.div`
  top: 100px;
  width: 20px;
  left: -20px;
  height: 50px;
  padding: 16px 0;
  position: absolute;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: ${() => G.getTheme('colors.dark.lightGrey')};
`;

export const ArrowWrapper = styled.div`
  & > svg {
    width: 20px;
    height: 11px;
    & > path {
      stroke: ${() => G.getTheme('colors.light.darkRed')};
    }
    transform: rotate(${({ isOpen }: Object) => G.ifElse(isOpen, '270deg', '90deg')});
  }
`;

export const ListGroupWrapper = styled.div`
  padding-top: 10px;
  overflow-y: scroll;
  height: calc(100vh - 200px);
`;

export const GroupItemWrapper = styled.div`
  & > div:first-child {
    display: flex;
    padding: 5px 0;
    align-items: center;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
      & > div > span:first-child {
        color: ${() => G.getTheme('colors.light.darkRed')};
      }
      & > div > span:nth-child(2) {
        & svg  > path {
          stroke: ${() => G.getTheme('colors.light.darkRed')};
        }
      }
  }
  & > span:first-child {
    color: ${({ isOpen }: Object) => (
      G.ifElse(
        isOpen,
        G.getTheme('colors.light.darkRed'),
        G.getTheme('colors.light.black'),
      )
    )};
    font-weight: ${({ isOpen }: Object) => G.ifElse(isOpen, 'bold', 'normal')};
  }
  & > span:nth-child(2) {
    width: 16px;
    & svg {
      & > path {
        stroke: ${({ isOpen }: Object) => (
          G.ifElse(
            isOpen,
            G.getTheme('colors.light.darkRed'),
            G.getTheme('colors.light.black'),
          )
        )};
      }
    }
  }}
  & > div:not(:first-child) {
    width: 180px;
  }
`;

export const ChartItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: grab;
  }
  & > div:first-child {
    width: 180px;
    height: 180px;
    background: ${() => G.getTheme('colors.light.mainLight')};
    border: ${() => `1px solid ${G.getTheme('colors.light.darkRed')}`};
  }
  & > div:nth-child(2) {
    padding: 5px 0;
  }
`;

export const SidebarHeaderWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: ${() => `1px solid ${G.getTheme('colors.light.darkRed')}`};
`;

export const EmptyDashboard = styled.div`
  margin: 0;
  display: flex;
  font-size: 38px;
  padding: 0 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px);
  transition: margin-right 0.2s linear;
  color: ${() => G.getTheme('charts.bgTextColor')};
  background: ${() => G.getTheme('charts.bgColor')};
  margin-right: ${({ isOpen }) => G.ifElse(isOpen, '250px', '0')};
`;

export const ChartLayoutWrapper = styled.div`
  padding: 20px;
  overflow-y: scroll;
  height: calc(100vh - 122px);
  & > div.react-grid-layout {
    position: relative;
  }
`;

export const ChartActionsWrapper = styled.div`
  width: 100%;
  z-index: 11;
  height: 25px;
  padding: 3px;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  background: ${() => G.getTheme('colors.light.lightGrey')};
  box-shadow: inset 0 0 7px 0 ${() => G.getTheme('colors.light.mainDark')};
`;

export const LayoutChartItemWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: ${() => `1px solid ${G.getTheme('colors.light.darkRed')}26`};
  & > div:first-child {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  &:hover {
    border: ${() => `1px solid ${G.getTheme('colors.light.darkRed')}`};
    & > div:first-child {
      opacity: 0.8;
    }
    & > span.react-resizable-handle {
      right: 0;
      bottom: 0;
      width: 15px;
      height: 15px;
      cursor: se-resize;
      position: absolute;
      &:after {
        right: 3px;
        bottom: 3px;
        width: 10px;
        content: "";
        height: 10px;
        position: absolute;
        border-bottom-right-radius: 10px;
        border-right: 3px solid ${() => G.getTheme('charts.borderColor')};
        border-bottom: 3px solid ${() => G.getTheme('charts.borderColor')};
      }
    }
  }
`;

export const IconWrapper = styled.div`
  margin: ${({ m }: Object) => m};
  padding: ${({ p }: Object) => `${p}px`};
  width: ${({ wrapWH }: Object) => wrapWH};
  height: ${({ wrapWH }: Object) => wrapWH};

  & > svg {
    width: ${({ iconWH }: Object) => iconWH};
    height: ${({ iconWH }: Object) => iconWH};
  }

  &:hover {
    cursor: pointer;
    border-radius: 5px;

    padding: ${({ p }: Object) => `${p - 1}px`};
    border: ${() => `1px solid ${G.getTheme('colors.light.darkRed')}`};
    box-shadow: ${() => `0 0 1px 0px  ${G.getTheme('colors.light.darkRed')}`};
  }
`;

export const reactGL = css`
  margin-bottom: 60px;
`;
