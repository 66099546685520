import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as GC from '../../../constants';
// report-common
import { getReportSelectors } from '../../../report-common';
//////////////////////////////////////////////////

const configStore = ({ config }: Object) => config;

const {
  store,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('orderReport');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectGeneralConfig = () => createSelector(
  configStore,
  ({ general }: Object) => R.path(['initialValues', GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM], general),
);

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectGeneralConfig,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
};
