import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { FormSectionHeader } from '../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const intervalOptions = ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'];

const costMethodOptions = ['', 'ACH', 'Invoice'];

const minmaxValidation = (max: number, min: number = 0) => Yup.number()
  .notRequired()
  .nullable(true)
  .typeError(G.getShouldBeNumericLocaleTxt())
  .min(0, G.getShouldBeFromToLocaleTxt(min, max))
  .max(max, G.getShouldBeFromToLocaleTxt(min, max))
  .transform((value: number) => G.ifElse(G.isNotNaN(value), value));

const validationSchema = Yup.object().shape({
  [GC.FIELD_PAYMENT_AMOUNT]: minmaxValidation(1000000000),
  [GC.FIELD_EQUIPMENT_RETURN_COST]: minmaxValidation(1000000000, 1),
  [GC.FIELD_EQUIPMENT_RETURN_COST_PAID]: minmaxValidation(1000000000, 1),
  [GC.FIELD_EQUIPMENT_RETURN_COST_ACCRUED]: minmaxValidation(1000000000, 1),
  [GC.FIELD_EQUIPMENT_RETURN_BALANCE_REQUIRED]: minmaxValidation(1000000000, 1),
  [GC.FIELD_EQUIPMENT_RETURN_COST_NOT_PROVISIONED]: minmaxValidation(1000000000, 1),
  [GC.FIELD_REGISTRATION_COST]: Yup.number()
    .notRequired()
    .nullable(true)
    .typeError(G.getShouldBeNumericLocaleTxt())
    .transform((value: number) => G.ifElse(G.isNotNaN(value), value)),
});

const calendarInputWrapperStyles = { width: '100%' };

const settings = {
  // general
  [GC.FIELD_OWNERSHIP_TYPE]: {
    type: 'select',
    name: 'titles:ownership-type',
    options: ['Own', 'Lease', 'Rental'],
  },
  [GC.FIELD_CONTRACT_NAME]: {
    name: 'titles:contract-name',
  },
  [GC.FIELD_CONTRACT_NUMBER]: {
    name: 'titles:contract-number',
  },
  // cost details
  [GC.FIELD_PAYMENT_AMOUNT]: {
    name: 'titles:payment-amount',
  },
  [GC.FIELD_PAYMENT_INTERVAL]: {
    type: 'select',
    options: intervalOptions,
    name: 'titles:payment-interval',
  },
  [GC.FIELD_PAYMENT_METHOD]: {
    type: 'select',
    options: costMethodOptions,
    name: 'titles:payment-method',
  },
  [GC.FIELD_MAINTENANCE_COST_INTERVAL]: {
    type: 'select',
    options: intervalOptions,
    name: 'titles:maintenance-cost-interval',
  },
  [GC.FIELD_MAINTENANCE_COST_METHOD]: {
    type: 'select',
    options: costMethodOptions,
    name: 'titles:maintenance-cost-method',
    additionalInputWrapperStyles: { mr: 280 },
  },
  [GC.FIELD_REGISTRATION_COST]: {
    name: 'titles:registration-cost',
  },
  [GC.FIELD_REGISTRATION_PAYMENT_INTERVAL]: {
    type: 'select',
    options: intervalOptions,
    name: 'titles:registration-interval',
    additionalInputWrapperStyles: { mr: 280 },
  },
  // term details
  [GC.FIELD_START_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:start-date',
    calendarInputWrapperStyles,
  },
  [GC.FIELD_END_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:end-date',
    calendarInputWrapperStyles,
  },
  [GC.FIELD_RETURNED_DATE]: {
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    name: 'titles:date-returned',
  },
  // equipment return
  [GC.FIELD_EQUIPMENT_RETURN_COST]: {
    name: 'titles:cost',
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_RATIO]: {
    name: 'titles:cost-ratio',
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_ACCRUED]: {
    name: 'titles:cost-accrued',
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_PAID]: {
    name: 'titles:cost-paid',
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_NOT_PROVISIONED]: {
    name: 'titles:cost-not-provisioned',
  },
  [GC.FIELD_EQUIPMENT_RETURN_BALANCE_REQUIRED]: {
    name: 'titles:balance-required',
  },
};

const defaultInputWrapperStyles = {
  mb: 25,
  width: 250,
};

const fieldSettings = R.mapObjIndexed((item: Object, fieldName: string) => {
  const { name, options, isRequired, type = 'text', calendarInputWrapperStyles, additionalInputWrapperStyles } = item;

  return {
    type,
    options,
    fieldName,
    isRequired,
    calendarInputWrapperStyles,
    label: G.ifElse(G.isNotNil(name), R.of(Array, name)),
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...G.spreadUiStyles(additionalInputWrapperStyles),
    },
  };
}, settings);

const getFieldSettings = (fields: Object) => R.compose(
  R.values,
  R.pick(fields),
)(fieldSettings);

const useForm = (props: Object) => {
  const { submitAction, initialValues } = props;

  const onSubmit = (values: Object, { setSubmitting }: Object) => {
    const failCallback = () => setSubmitting(false);
    const successCallback = () => setSubmitting(false);

    submitAction(G.mapObjectEmptyStringFieldsToNull(values), { failCallback, successCallback });
  };

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const ownershipType = R.path(['values', GC.FIELD_OWNERSHIP_TYPE], formik);

  const fieldsGroups = useMemo(() => {
    if (R.equals(ownershipType, 'Own')) {
      return [
        {
          fieldSettings: getFieldSettings([GC.FIELD_OWNERSHIP_TYPE]),
        },
        {
          formSectionTitle: G.getWindowLocale('titles:cost-details', 'Cost Details'),
          fieldSettings: getFieldSettings([
            GC.FIELD_PAYMENT_AMOUNT,
            GC.FIELD_PAYMENT_INTERVAL,
            GC.FIELD_PAYMENT_METHOD,
            GC.FIELD_REGISTRATION_COST,
            GC.FIELD_REGISTRATION_PAYMENT_INTERVAL,
          ]),
        },
      ];
    }

    return [
      {
        fieldSettings: getFieldSettings([
          GC.FIELD_OWNERSHIP_TYPE,
          GC.FIELD_CONTRACT_NAME,
          GC.FIELD_CONTRACT_NUMBER,
        ]),
      },
      {
        formSectionTitle: G.getWindowLocale('titles:term-details', 'Term Details'),
        fieldSettings: getFieldSettings([
          GC.FIELD_START_DATE,
          GC.FIELD_END_DATE,
          GC.FIELD_RETURNED_DATE,
        ]),
      },
      {
        formSectionTitle: G.getWindowLocale('titles:cost-details', 'Cost Details'),
        fieldSettings: getFieldSettings([
          GC.FIELD_PAYMENT_AMOUNT,
          GC.FIELD_PAYMENT_INTERVAL,
          GC.FIELD_PAYMENT_METHOD,
          GC.FIELD_MAINTENANCE_COST_INTERVAL,
          GC.FIELD_MAINTENANCE_COST_METHOD,
          GC.FIELD_REGISTRATION_COST,
          GC.FIELD_REGISTRATION_PAYMENT_INTERVAL,
        ]),
      },
      {
        formSectionTitle: G.getWindowLocale('titles:equipment-return', 'Equipment Return'),
        fieldSettings: getFieldSettings([
          GC.FIELD_EQUIPMENT_RETURN_COST,
          GC.FIELD_EQUIPMENT_RETURN_COST_RATIO,
          GC.FIELD_EQUIPMENT_RETURN_COST_ACCRUED,
          GC.FIELD_EQUIPMENT_RETURN_COST_PAID,
          GC.FIELD_EQUIPMENT_RETURN_COST_NOT_PROVISIONED,
          GC.FIELD_EQUIPMENT_RETURN_BALANCE_REQUIRED,
        ]),
      },
    ];
  }, [ownershipType]);

  return { ...formik, fieldsGroups };
};

const fieldsWrapperStyles = {
  mt: 25,
  px: 15,
  width: 840,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

const OwnershipDetailsForm = (props: Object) => {
  const form = useForm(props);

  const { isSubmitting, handleSubmit, fieldsGroups } = form;

  return (
    <form onSubmit={handleSubmit}>
      {
        fieldsGroups.map(({ fieldSettings, formSectionTitle }: Object, index: number) => {
          const fields = (
            <Fieldset2
              {...G.getFormikPropsToFieldset(form)}
              key={index}
              fields={fieldSettings}
              fieldsWrapperStyles={fieldsWrapperStyles}
            />
          );

          if (R.isNil(formSectionTitle)) return fields;

          return (
            <FormSectionHeader
              key={index}
              expanded={true}
              title={formSectionTitle}
            >
              {fields}
            </FormSectionHeader>
          );
        })
      }
      <FormFooter2 boxStyles={{ px: 15, width: 840 }} submitDisabled={isSubmitting} />
    </form>
  );
};

export default OwnershipDetailsForm;
