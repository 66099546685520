import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const setMapOpened = createAction('setMapOpened');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setTableHeight = createAction('setTableHeight');
export const setTimerStarted = createAction('setTimerStarted');
export const setInitialState = createAction('setInitialState');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setReportPending = createAction('setReportPending');
export const setTableTitleSort = createAction('setTableTitleSort');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const setIgnorePromptStatus = createAction('setIgnorePromptStatus');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const getCustomerPortalListSuccess = createAction('getCustomerPortalListSuccess');
export const getCustomerPortalListRequest = createAction('getCustomerPortalListRequest');
export const refreshCustomerPortalPageRequest = createAction('refreshCustomerPortalPageRequest');
export const cleanCustomerPortalListAndLocations = createAction('cleanCustomerPortalListAndLocations');
export const getTransportationModeMappingSuccess = createAction('getTransportationModeMappingSuccess');
