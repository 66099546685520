import { connect } from 'react-redux';
import React, { useState } from 'react';
// components
import { Switcher } from '../../../components/switcher';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
// feature dashboards
import { getChartByFiltersRequest } from '../actions';
import { getChartNumberRangeFilter } from '../helpers';
//////////////////////////////////////////////////

const switchIndexMap = {
  'all': 0,
  0: 1,
  200: 2,
  500: 3,
};

// TODO: check names
const switchOptions = [
  {
    value: 'all',
    width: 60,
    name: `${G.getWindowLocale('titles:all', 'All')} ${G.getDistanceUomLocale()}`,
  },
  {
    value: 0,
    width: 40,
    name: '< 200',
  },
  {
    value: 200,
    width: 50,
    name: '200-500',
  },
  {
    value: 500,
    width: 40,
    name: '> 500',
  },
];

const ChartFilters = (props: Object) => {
  const {
    chartType,
    getChartByFiltersRequest,
  } = props;

  const [value, setValue] = useState('all');

  const onSwitchDays = (value: number) => {
    const numberRange = getChartNumberRangeFilter(value);

    setValue(value);

    getChartByFiltersRequest({ chartType, numberRange });
  };

  return (
    <Flex>
      <Switcher
        ml={50}
        version={3}
        fontSize={10}
        options={switchOptions}
        onSwitch={onSwitchDays}
        selectedOptionIndex={switchIndexMap[value]}
      />
    </Flex>
  );
};

export default connect(null, {
  getChartByFiltersRequest,
})(ChartFilters);
