import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// common
const setInitialState = createAction('setInitialState');
const getItemListSuccess = createAction('getItemListSuccess');
const getItemListRequest = createAction('getItemListRequest');
const removeEntityRequest = createAction('removeEntityRequest');
const removeEntitySuccess = createAction('removeEntitySuccess');
const toggleFormGroupTable = createAction('toggleFormGroupTable');
const getGeneralDetailsRequest = createAction('getGeneralDetailsRequest');
const getGeneralDetailsSuccess = createAction('getGeneralDetailsSuccess');
const toggleFormGroupTableDetails = createAction('toggleFormGroupTableDetails');
const createOrUpdateEntityRequest = createAction('createOrUpdateEntityRequest');
const createOrUpdateEntitySuccess = createAction('createOrUpdateEntitySuccess');
const updateGeneralDetailsSuccess = createAction('updateGeneralDetailsSuccess');
const updateGeneralDetailsRequest = createAction('updateGeneralDetailsRequest');
const sendOnboardingPackageRequest = createAction('sendOnboardingPackageRequest');
// report
const setReports = createAction('setReports');
const selectItem = createAction('selectItem');
const setUsedReport = createAction('setUsedReport');
const setListLoading = createAction('setListLoading');
const setTableTitleFilter = createAction('setTableTitleFilter');
const createReportRequest = createAction('createReportRequest');
const updateReportRequest = createAction('updateReportRequest');
const resetListAndPagination = createAction('resetListAndPagination');
const setTableTitleSortValues = createAction('setTableTitleSortValues');
const getReportItemListRequest = createAction('getReportItemListRequest');
const getReportItemListSuccess = createAction('getReportItemListSuccess');
const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
const getAvailableReportsRequest = createAction('getAvailableReportsRequest');
// configs
const getDispatchConfigListRequest = createAction('getDispatchConfigListRequest');
const getDispatchConfigListSuccess = createAction('getDispatchConfigListSuccess');
const getCarrierProfileConfigsRequest = createAction('getCarrierProfileConfigsRequest');
const getCarrierProfileConfigsSuccess = createAction('getCarrierProfileConfigsSuccess');
const getAvailableDocumentTypesRequest = createAction('getAvailableDocumentTypesRequest');
const getAvailableDocumentTypesSuccess = createAction('getAvailableDocumentTypesSuccess');
const getCarrierOnboardingPackageSuccess = createAction('getCarrierOnboardingPackageSuccess');
const getAvailableDocumentTemplatesSuccess = createAction('getAvailableDocumentTemplatesSuccess');
// accessorial configs
const getAccessorialConfigListRequest = createAction('getAccessorialConfigListRequest');
const getAccessorialConfigListSuccess = createAction('getAccessorialConfigListSuccess');
const getSharedAccessorialsListRequest = createAction('getSharedAccessorialsListRequest');
const getSharedAccessorialsListSuccess = createAction('getSharedAccessorialsListSuccess');
// filters
const setFormGroupFilterByFilterName = createAction('setDocumentsFilter');
// reference types
const getReferenceTypesByScopeForOptionsRequest = createAction('getReferenceTypesByScopeForOptionsRequest');
const getReferenceTypesByScopeForOptionsSuccess = createAction('getReferenceTypesByScopeForOptionsSuccess');
// sequences
const getSequencesByScopeForOptionsRequest = createAction('getSequencesByScopeForOptionsRequest');
const getSequencesByScopeForOptionsSuccess = createAction('getSequencesByScopeForOptionsSuccess');
// branches
const getBranchListByBranchTypeRequest = createAction('getBranchListByBranchTypeRequest');
const getBranchListByBranchTypeSuccess = createAction('getBranchListByBranchTypeSuccess');
const getParentBranchGuidsByCurrentBranchGuidRequest = createAction('getParentBranchGuidsByCurrentBranchGuidRequest');
const getParentBranchGuidsByCurrentBranchGuidSuccess = createAction('getParentBranchGuidsByCurrentBranchGuidSuccess');
// mails
const downloadCarrierMailRequest = createAction('downloadCarrierMailRequest');
// synchronization
const createWatchRequest = createAction('createWatchRequest');
const createWatchSuccess = createAction('createWatchSuccess');
const syncWithIntegrationRequest = createAction('syncWithIntegrationRequest');
const getLoadBoardIntegrationListRequest = createAction('getLoadBoardIntegrationListRequest');
const addOrRemoveToNetworkCarrierRequest = createAction('addOrRemoveToNetworkCarrierRequest');
const getLoadBoardIntegrationListSuccess = createAction('getLoadBoardIntegrationListSuccess');
const allowOrForbidBookItNowCarrierRequest = createAction('allowOrForbidBookItNowCarrierRequest');
const getAvailableForCarrierLoadBoardsSuccess = createAction('getAvailableForCarrierLoadBoardsSuccess');
// contracts
const setActiveRatingEngineTab = createAction('setActiveRatingEngineTab');
const carrierRatePriceExportRequest = createAction('carrierRatePriceExportRequest');
const getContractGeneralDetailsSuccess = createAction('getContractGeneralDetailsSuccess');
const updateContractGeneralDetailsRequest = createAction('updateContractGeneralDetailsRequest');
const setCarrierBranchGuidToContractSuccess = createAction('setCarrierBranchGuidToContractSuccess');
const getAvailableGeoFencingZoneListSuccess = createAction('getAvailableGeoFencingZoneListSuccess');
const getAvailableCarrierIntegrationsSuccess = createAction('getAvailableCarrierIntegrationsSuccess');
const massDeleteCarrierContractRatePricesRequest = createAction('massDeleteCarrierContractRatePricesRequest');

export {
  setInitialState,
  getItemListSuccess,
  getItemListRequest,
  removeEntitySuccess,
  removeEntityRequest,
  toggleFormGroupTable,
  setActiveRatingEngineTab,
  getGeneralDetailsRequest,
  getGeneralDetailsSuccess,
  toggleFormGroupTableDetails,
  createOrUpdateEntityRequest,
  createOrUpdateEntitySuccess,
  updateGeneralDetailsSuccess,
  updateGeneralDetailsRequest,
  sendOnboardingPackageRequest,
  // report
  selectItem,
  setReports,
  setUsedReport,
  setListLoading,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  resetListAndPagination,
  setTableTitleSortValues,
  getReportItemListRequest,
  getReportItemListSuccess,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // configs
  getDispatchConfigListRequest,
  getDispatchConfigListSuccess,
  getCarrierProfileConfigsSuccess,
  getCarrierProfileConfigsRequest,
  getAvailableDocumentTypesRequest,
  getAvailableDocumentTypesSuccess,
  getCarrierOnboardingPackageSuccess,
  getAvailableDocumentTemplatesSuccess,
  // accessorial configs
  getAccessorialConfigListRequest,
  getAccessorialConfigListSuccess,
  getSharedAccessorialsListRequest,
  getSharedAccessorialsListSuccess,
  // filters
  setFormGroupFilterByFilterName,
  // reference types
  getReferenceTypesByScopeForOptionsRequest,
  getReferenceTypesByScopeForOptionsSuccess,
  // sequences
  getSequencesByScopeForOptionsRequest,
  getSequencesByScopeForOptionsSuccess,
  // branches
  getBranchListByBranchTypeRequest,
  getBranchListByBranchTypeSuccess,
  getParentBranchGuidsByCurrentBranchGuidRequest,
  getParentBranchGuidsByCurrentBranchGuidSuccess,
  // mails
  downloadCarrierMailRequest,
  // synchronization
  createWatchRequest,
  createWatchSuccess,
  syncWithIntegrationRequest,
  getLoadBoardIntegrationListSuccess,
  addOrRemoveToNetworkCarrierRequest,
  getLoadBoardIntegrationListRequest,
  allowOrForbidBookItNowCarrierRequest,
  getAvailableForCarrierLoadBoardsSuccess,
  // contracts
  carrierRatePriceExportRequest,
  getContractGeneralDetailsSuccess,
  updateContractGeneralDetailsRequest,
  setCarrierBranchGuidToContractSuccess,
  getAvailableGeoFencingZoneListSuccess,
  getAvailableCarrierIntegrationsSuccess,
  massDeleteCarrierContractRatePricesRequest,
};
