
import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature carrier
import * as A from './actions';
import * as H from './helpers';
//////////////////////////////////////////////////

const initialState = {
  generalDetails: {},
  collapsedGroup: {},
};

const initialPagination = {
  limit: 10,
  offset: 0,
};

// common
const setInitialState = (state: Object, options: string) => {
  if (R.isNil(options)) return initialState;

  const { type } = options;

  if (R.isNil(type)) return state;

  return P.$all(
    P.$set(type, {}),
    P.$set('configGroup', null),
    state,
  );
};

const getGeneralDetailsSuccess = (state: Object, data: Object) => {
  const { synchronizationInfo } = data;

  return P.$all(
    P.$set('generalDetails', R.dissoc('synchronizationInfo', data)),
    P.$set('synchronizationInfo', G.isNilOrEmpty(synchronizationInfo) ? null : R.of(Array, synchronizationInfo)),
    state,
  );
};

const toggleFormGroupTable = (state: Object, groupName: string) => (
  P.$set(
    `collapsedGroup.${groupName}`,
    R.not(R.pathOr(true, ['collapsedGroup', ...R.split('.', groupName)], state)),
    state,
  )
);

const getItemListSuccess = (state: Object, { data, groupName }: Object) => {
  const itemList = R.pathOr(data, ['results'], data);

  if (G.isNilOrEmpty(itemList)) return P.$set(`${groupName}`, [], state);

  return P.$set(`${groupName}`, G.ifElse(G.isArray(itemList), itemList, R.of(Array, itemList)), state);
};

const createOrUpdateEntitySuccess = (state: Object, { data, groupName }: Object) => {
  const guid = G.getGuidFromObject(data);
  const list = R.path(R.split('.', groupName), state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.equals(index, -1)) return P.$add(groupName, data, state);

  return P.$set(`${groupName}.${index}`, data, state);
};

const removeEntitySuccess = (state: Object, { guid, isReport, groupName }: Object) => {
  const path = R.split('.', groupName);
  const list = R.path(path, state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (isReport) {
    const totalCount = R.dec(R.pathOr(1, ['totalCount', ...path], state));

    return P.$all(
      P.$drop(`${groupName}.${index}`),
      P.$set(`totalCount.${groupName}`, totalCount),
      state,
    );
  }

  return P.$drop(`${groupName}.${index}`, state);
};

// report
const setTableTitleSortValues = (state: Object, { data, groupName }: Object) => {
  const path = `tableTitleSortValues.${groupName}`;

  const titleSortValues = R.pathOr({}, R.split('.', path), state);

  const sortValues = G.getTableTitleSortValues(titleSortValues, data);

  return P.$set(path, sortValues, state);
};

const setTableTitleFilter = (state: Object, { data, groupName }: Object) => {
  const path = `tableTitleFilters.${groupName}`;

  const tableTitleFilters = R.pathOr({}, R.split('.', path), state);

  const filterValues = G.getTableTitleFilterValues(tableTitleFilters, data);

  return P.$set(path, filterValues, state);
};

const resetListAndPagination = (state: Object, { groupName }: Object) => (
  P.$all(
    P.$set(`${groupName}`, null),
    P.$set(`pagination.${groupName}`, initialPagination),
    state,
  )
);

const setListLoading = (state: Object, { loading, groupName }: Object) => (
  P.$set(`loading.${groupName}`, loading, state)
);

const setReports = (state: Object, { reports, groupName }: Object) => (
  P.$set(`availableReports.${groupName}`, reports, state)
);

const setUsedReport = (state: Object, { groupName, usedReport }: Object) => (
  P.$all(
    P.$set(groupName, []),
    P.$set(`tableTitleFilters.${groupName}`, {}),
    P.$set(`usedReport.${groupName}`, usedReport),
    P.$set(`tableTitleSortValues.${groupName}`, {}),
    P.$set(`pagination.${groupName}`, initialPagination),
    state,
  )
);

const getReportItemListSuccess = (state: Object, { data, groupName }: Object) => {
  const { results, totalCount } = data;

  const path = R.split('.', groupName);

  const sum = R.sum(R.values(R.pathOr({}, ['pagination', ...path], state)));

  const offset = G.ifElse(R.gt(totalCount, sum), sum, totalCount);

  const itemList = R.concat(R.pathOr([], path, state), results);

  return P.$all(
    P.$set(groupName, itemList),
    P.$set(`totalCount.${groupName}`, totalCount),
    P.$set(`pagination.${groupName}.offset`, offset),
    state,
  );
};

const selectItem = (state: Object, { id, groupName }: Object) => {
  const itemList = R.path(R.split('.', groupName), state);

  if (R.equals(id, 'all')) {
    const value = R.not(R.all(({ selected }: Object) => selected, itemList));
    const newItemList = R.map((item: Object) => R.assoc('selected', value, item), itemList);

    return P.$set(groupName, newItemList, state);
  }

  const index = R.findIndex(R.propEq(id, GC.FIELD_GUID), itemList);

  return P.$set(`${groupName}.${index}.selected`, R.not(R.path([index, 'selected'], itemList)), state);
};

// configs
const getCarrierProfileConfigsSuccess = (state: Object, data: Object) => {
  const { configGroup } = state;

  const { configs, dropdowns } = data;

  const dropdownOptions = H.makeDropdownOptions(dropdowns);

  if (R.isNil(configGroup)) {
    return P.$set('configGroup', { configs, dropdownOptions }, state);
  }

  const { configs: existentConfigs, dropdownOptions: existentDropdownOptions } = configGroup;

  const newConfigGroup = {
    configs: R.mergeRight(existentConfigs, configs),
    dropdownOptions: R.mergeRight(existentDropdownOptions, dropdownOptions),
  };

  return P.$set('configGroup', newConfigGroup, state);
};

const getCarrierOnboardingPackageSuccess = (state: Object, sendPackage: boolean) => (
  P.$set('sendPackage', sendPackage, state)
);

const getDispatchConfigListSuccess = (state: Object, data: Array) => (
  P.$set('dispatchConfigs', data, state)
);

const getAvailableDocumentTypesSuccess = (state: Object, data: Array) => (
  P.$set('availableDocumentTypes', data, state)
);

const getAvailableDocumentTemplatesSuccess = (state: Object, data: Array) => (
  P.$set(
    'carrierDispatchAvailableDocumentTemplates',
    G.mapNameGuidToLabelValue(R.propOr([], GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS, data)),
    state,
  )
);

const getAccessorialConfigListSuccess = (state: Object, data: Object) => {
  const accessorialConfigList = R.compose(
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
    R.map((item: Object) => R.compose(
      R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
      R.pick([
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
        GC.FIELD_DISPLAYED_VALUE,
        GC.FIELD_CHARGE_RATE_TYPE,
        GC.FIELD_CHARGE_RATE_UNIT,
      ]),
    )(item)),
  )(data);

  const accessorialConfigOptions = R.map(({ displayedValue, originalConfigGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: originalConfigGuid,
  }), data);

  return P.$set('accessorialConfigs', { accessorialConfigList, accessorialConfigOptions }, state);
};

const getSharedAccessorialsListSuccess = (state: Object, data: Array) => (
  P.$set('shardAccessorialList', data, state)
);

// filters
const setFormGroupFilterByFilterName = (state: Object, { filterName, filterValue }: Object) => (
  P.$set(`formGroupFilters.${filterName}`, filterValue, state)
);

// reference types
const getReferenceTypesByScopeForOptionsSuccess = (state: Object, data: Object) => (
  P.$set('referenceTypes', data, state)
);

// sequences
const getSequencesByScopeForOptionsSuccess = (state: Object, data: Object) => (
  P.$set('sequences', data, state)
);

// branches
const getBranchListByBranchTypeSuccess = (state: Object, { branchType, branchList }: Object) => (
  P.$set(`branches.${branchType}`, branchList, state)
);

const getParentBranchGuidsByCurrentBranchGuidSuccess = (state: Object, data: Object) => (
  P.$set('parentBranchGuids', data, state)
);

// synchronization
const getLoadBoardIntegrationListSuccess = (state: Object, data: Array) => (
  P.$set('loadBoardIntegrationInfo', data, state)
);

const createWatchSuccess = (state: Object, addedToWatchList: boolean) => (
  P.$set('generalDetails.addedToWatchList', addedToWatchList, state)
);

const getAvailableForCarrierLoadBoardsSuccess = (state: Object, data: Array) => (
  P.$set('availableForCarrierLoadBoards', data, state)
);

// contracts
const getContractGeneralDetailsSuccess = (state: Object, { type, data }: Object) => (
  P.$set(`${type}.generalDetails`, data, state)
);

const setCarrierBranchGuidToContractSuccess = (state: Object, { type, branchGuid }: Object) => (
  P.$set(`${type}.branchGuid`, branchGuid, state)
);

const getAvailableGeoFencingZoneListSuccess = (state: Object, data: Object) => (
  P.$set('geoFencingZoneList', G.mapNameGuidToLabelValue(R.or(data, [])), state)
);

const getAvailableCarrierIntegrationsSuccess = (state: Object, data: Object) => (
  P.$set('availableCarrierIntegrations', data, state)
);

const setActiveRatingEngineTab = (state: Object, value: boolean) => (
  P.$set('activeRatingEngineTab', value, state)
);

export default createReducer({
  // common
  [A.setInitialState]: setInitialState,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.removeEntitySuccess]: removeEntitySuccess,
  [A.toggleFormGroupTable]: toggleFormGroupTable,
  [A.getGeneralDetailsSuccess]: getGeneralDetailsSuccess,
  [A.createOrUpdateEntitySuccess]: createOrUpdateEntitySuccess,
  // report
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setTableTitleSortValues]: setTableTitleSortValues,
  [A.getReportItemListSuccess]: getReportItemListSuccess,
  // configs
  [A.getDispatchConfigListSuccess]: getDispatchConfigListSuccess,
  [A.getAccessorialConfigListSuccess]: getAccessorialConfigListSuccess,
  [A.getCarrierProfileConfigsSuccess]: getCarrierProfileConfigsSuccess,
  [A.getAvailableDocumentTypesSuccess]: getAvailableDocumentTypesSuccess,
  [A.getSharedAccessorialsListSuccess]: getSharedAccessorialsListSuccess,
  [A.getCarrierOnboardingPackageSuccess]: getCarrierOnboardingPackageSuccess,
  [A.getAvailableDocumentTemplatesSuccess]: getAvailableDocumentTemplatesSuccess,
  // filters
  [A.setFormGroupFilterByFilterName]: setFormGroupFilterByFilterName,
  // references
  [A.getReferenceTypesByScopeForOptionsSuccess]: getReferenceTypesByScopeForOptionsSuccess,
  // sequences
  [A.getSequencesByScopeForOptionsSuccess]: getSequencesByScopeForOptionsSuccess,
  // branches
  [A.getBranchListByBranchTypeSuccess]: getBranchListByBranchTypeSuccess,
  [A.getParentBranchGuidsByCurrentBranchGuidSuccess]: getParentBranchGuidsByCurrentBranchGuidSuccess,
  // synchronization
  [A.createWatchSuccess]: createWatchSuccess,
  [A.getLoadBoardIntegrationListSuccess]: getLoadBoardIntegrationListSuccess,
  [A.getAvailableForCarrierLoadBoardsSuccess]: getAvailableForCarrierLoadBoardsSuccess,
  // contracts
  [A.setActiveRatingEngineTab]: setActiveRatingEngineTab,
  [A.getContractGeneralDetailsSuccess]: getContractGeneralDetailsSuccess,
  [A.getAvailableGeoFencingZoneListSuccess]: getAvailableGeoFencingZoneListSuccess,
  [A.setCarrierBranchGuidToContractSuccess]: setCarrierBranchGuidToContractSuccess,
  [A.getAvailableCarrierIntegrationsSuccess]: getAvailableCarrierIntegrationsSuccess,
}, initialState);
