import * as R from 'ramda';
import * as Yup from 'yup';
import React, { Fragment } from 'react';
// components
import { ActionBox } from '../../../components/action-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { vendorPayrollAccessorialPayForUnitTypeOptions } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, RelativeBox } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import VendorPayrollCompensationForm from '../vendor/forms/payroll-compensation';
import { FormGroupTableWithSimpleCrudOperations } from '../components/form-group-table';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: {
    sortable: false,
    isRequired: true,
    type: 'reactSelect',
    shouldCustomChange: true,
    name: 'titles:accessorial2',
    options: 'accessorialConfigOptions',
    additionalInputWrapperStyles: { zIndex: 12 },
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      return R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_DISPLAYED_VALUE],
        callbackData,
      );
    },
    customChangeHandler: (value: Object, _: any, props: Object) => {
      const { values, setValues, accessorialConfigList } = props;

      const accessorial = R.assoc(
        GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
        value,
        R.pathOr({}, [value], accessorialConfigList),
      );

      const newValues = R.mergeRight(
        values,
        R.pick([GC.FIELD_FUEL_RELATED, GC.FIELD_NON_TAXABLE, GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID], accessorial),
      );

      setValues(newValues);
    },
  },
  [GC.FIELD_FUEL_RELATED]: {
    width: 100,
    disabled: true,
    type: 'boolean',
    searchable: false,
    defaultValue: false,
    disableFilter: true,
    fieldType: 'toggle',
    name: 'titles:fuel-related',
    additionalInputWrapperStyles: { width: 140 },
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const fuelRelated = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        callbackData,
      );

      return G.ifElse(fuelRelated, I.uiTrue, I.uiFalse)();
    },
  },
  [GC.FIELD_NON_TAXABLE]: {
    width: 100,
    disabled: true,
    type: 'boolean',
    searchable: false,
    fieldType: 'toggle',
    defaultValue: false,
    disableFilter: true,
    name: 'titles:non-taxable',
    additionalInputWrapperStyles: { width: 140 },
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const nonTaxable = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_NON_TAXABLE],
        callbackData,
      );

      return G.ifElse(nonTaxable, I.uiTrue, I.uiFalse)();
    },
  },
  [GC.FIELD_DEDUCTION]: {
    width: 150,
    type: 'boolean',
    fieldType: 'toggle',
    defaultValue: false,
    name: 'titles:deduction',
    additionalInputWrapperStyles: { width: 140 },
    disabled: (_: any, { deductionDisabled = false }: Object) => deductionDisabled,
  },
  [GC.FIELD_ACTIVE]: {
    width: 150,
    type: 'boolean',
    fieldType: 'toggle',
    defaultValue: false,
    name: 'titles:active',
    additionalInputWrapperStyles: { width: 140 },
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 180,
    isRequired: true,
    fieldType: 'number',
    name: 'titles:rate',
    filterType: 'number',
  },
  [GC.FIELD_CURRENCY]: {
    isRequired: true,
    fieldType: 'select',
    name: 'titles:currency',
    filterType: 'selectMultiple',
    options: GC.CURRENCY_OPTIONS,
    disabled: (_: any, { version }: Object) => G.isNotNil(version),
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    disabled: true,
    fieldType: 'text',
    searchable: false,
    name: 'titles:rate-type',
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    defaultValue: GC.CHARGE_RATE_TYPE_FLAT,
  },
  [GC.FIELD_LIMITED]: {
    width: 150,
    type: 'boolean',
    fieldType: 'toggle',
    name: 'titles:limited',
    fieldName: GC.FIELD_LIMITED,
    disabled: (_: any, { version }: Object) => G.isNotNil(version),
  },
  [GC.FIELD_TOTAL_AMOUNT]: {
    width: 180,
    type: 'number',
    isRequired: true,
    filterType: 'number',
    name: 'titles:total-amount',
    additionalInputWrapperStyles: {
      visibility: ({ values }: Object) => G.ifElse(
        G.isTrue(G.getPropFromObject(GC.FIELD_LIMITED, values)),
        'visible',
        'hidden',
      ),
    },
  },
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: {
    width: 180,
    type: 'number',
    filterType: 'number',
    name: 'titles:historical-paid-amount',
    additionalInputWrapperStyles: {
      visibility: ({ values }: Object) => G.ifElse(
        G.isTrue(G.getPropFromObject(GC.FIELD_LIMITED, values)),
        'visible',
        'hidden',
      ),
    },
  },
  [GC.FIELD_PAID_AMOUNT]: {
    width: 180,
    type: 'number',
    disabled: true,
    filterType: 'number',
    name: 'titles:paid-amount',
    additionalInputWrapperStyles: {
      display: ({ values }: Object) => G.ifElse(
        R.and(
          G.isTrue(G.getPropFromObject(GC.FIELD_LIMITED, values)),
          G.isNotNil(G.getPropFromObject(GC.FIELD_VERSION, values)),
        ),
        'flex',
        'none',
      ),
    },
  },
  [GC.FIELD_FULLY_PAID]: {
    width: 150,
    type: 'boolean',
    disabled: true,
    hasLabel: false,
    name: 'titles:fully-paid',
    fieldType: 'customComponent',
    additionalInputWrapperStyles: {
      width: 600,
      display: ({ values }: Object) => G.ifElse(
        R.and(
          G.isTrue(G.getPropFromObject(GC.FIELD_LIMITED, values)),
          G.isNotNil(G.getPropFromObject(GC.FIELD_VERSION, values)),
        ),
        'flex',
        'none',
      ),
    },
    Component: ({ value }: Object) => {
      if (G.isNilOrEmpty(value)) return null;

      return (
        <Fragment>
          <Box px={20}>{G.getWindowLocale('titles:fully-paid', 'Fully Paid')}</Box>
          {
            I[G.ifElse(G.isTrue(value), 'uiTrue', 'uiFalse')]()
          }
        </Fragment>
      );
    },
  },
  [GC.FIELD_COMMENTS]: {
    width: 300,
    fieldType: 'textarea',
    name: 'titles:comments',
    inputStyles: { height: 100 },
    additionalInputWrapperStyles: { width: 870 },
  },
  [GC.GRC.DRIVER_LOGIN_ID]: {
    name: 'titles:driver-id',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const lastFields = [
  GC.FIELD_LIMITED,
  GC.FIELD_TOTAL_AMOUNT,
  GC.FIELD_HISTORICAL_PAID_AMOUNT,
  GC.FIELD_PAID_AMOUNT,
  GC.FIELD_FULLY_PAID,
  GC.FIELD_COMMENTS,
];

const vendorSettings = {
  ...R.omit([...lastFields, GC.GRC.DRIVER_LOGIN_ID], settings),
  [GC.FIELD_PAY_FOR_UNIT]: {
    type: 'enum',
    fieldType: 'select',
    shouldCustomChange: true,
    name: 'titles:pay-for-unit',
    filterType: 'selectMultiple',
    options: vendorPayrollAccessorialPayForUnitTypeOptions,
    defaultValue: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_NONE,
    customChangeHandler: (event: Object, _: any, props: Object) => {
      const { values, setValues } = props;

      const newValues = R.mergeRight(
        values,
        {
          [GC.FIELD_TRUCK_GUIDS]: null,
          [GC.FIELD_DRIVER_GUIDS]: null,
          [GC.FIELD_PAY_FOR_UNIT]: R.path(['currentTarget', GC.FIELD_VALUE], event),
        },
      );

      setValues(newValues);
    },
  },
  [GC.FIELD_TRUCK_GUIDS]: {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:trucks',
    options: 'truckOptions',
    additionalInputWrapperStyles: {
      mr: 300,
      zIndex: 11,
      visibility: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'visible',
        'hidden',
      ),
      display: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'none',
      ),
    },
  },
  [GC.FIELD_DRIVER_GUIDS]: {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:drivers',
    options: 'driverOptions',
    fieldName: GC.FIELD_DRIVER_GUIDS,
    additionalInputWrapperStyles: {
      mr: 300,
      zIndex: 11,
      visibility: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'visible',
        'hidden',
      ),
      display: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'flex',
        'none',
      ),
    },
  },
  ...R.pick(lastFields, settings),
  [GC.GRC.FLEET_VENDOR_COMPANY_NAME]: {
    name: 'titles:company-name',
  },
};

const totalsValidation = Yup.number()
  .nullable(true)
  .required(G.getRequiredLocaleTxt())
  .typeError(G.getShouldBeIntegerLocaleTxt())
  .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
  .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999));

export const schema = (limited: boolean, payForUnit: string) => ({
  [GC.FIELD_CHARGE_RATE]: totalsValidation,
  [GC.FIELD_CURRENCY]: G.yupStringRequired,
  [GC.FIELD_DRIVER_ASSESSORIAL_GUID]: G.yupStringRequired,
  [GC.FIELD_TOTAL_AMOUNT]: G.ifElse(G.isTrue(limited), totalsValidation, G.yupStringNotRequired),
  [GC.FIELD_DRIVER_GUIDS]: G.ifElse(
    R.equals(payForUnit, GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS),
    G.yupArrayRequired,
    G.yupArrayNotRequired,
  ),
  [GC.FIELD_TRUCK_GUIDS]: G.ifElse(
    R.equals(payForUnit, GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS),
    G.yupArrayRequired,
    G.yupArrayNotRequired,
  ),
  [GC.FIELD_COMMENTS]: Yup.string()
    .nullable(true)
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
});

const validationSchema = Yup.lazy(({ limited, payForUnit }: Object) =>
  Yup.object().shape(schema(limited, payForUnit)),
);

const defaultInputWrapperStyles = {
  mb: 25,
  width: 270,
};

export const defaultValues = R.map(({ defaultValue = null }: Object) => defaultValue, settings);

const makeFieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    isMulti,
    options,
    disabled,
    fieldType,
    Component,
    inputStyles,
    defaultValue,
    type = 'text',
    hasLabel = true,
    shouldCustomChange,
    isRequired = false,
    customChangeHandler,
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    type,
    isMulti,
    options,
    disabled,
    fieldName,
    Component,
    isRequired,
    inputStyles,
    defaultValue,
    shouldCustomChange,
    customChangeHandler,
    [GC.FIELD_TYPE]: R.or(fieldType, type),
    label: G.ifElse(hasLabel, R.of(Array, name)),
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...G.spreadUiStyles(additionalInputWrapperStyles),
    },
  })),
  R.omit([
    GC.FIELD_CREATED_BY,
    GC.FIELD_CREATED_DATE,
    GC.GRC.DRIVER_LOGIN_ID,
    GC.FIELD_LAST_MODIFIED_BY,
    GC.FIELD_LAST_MODIFIED_DATE,
    GC.GRC.FLEET_VENDOR_COMPANY_NAME,
  ]),
);

export const fieldSettings = makeFieldSettings(settings);
export const vendorFieldSettings = makeFieldSettings(vendorSettings);

const makeColumnSettings = R.map(R.pick(['type', 'name', 'width', 'sortable', 'searchable', 'customComponent']));

const columnSettings = makeColumnSettings(settings);
const vendorColumnSettings = makeColumnSettings(vendorSettings);

const payrollChargesColumnSettings = {
  [GC.FIELD_PAYROLL_NUMBER]: {
    name: 'titles:payroll-number',
    customComponent: ({ data }: Object) =>
      <ActionBox text={data.payrollNumber} action={() => G.goToRoute(routesMap.driverPayrollList)} />,
  },
  [GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL]: {
    name: 'titles:total',
    customComponent: ({ data = {}, callbackData }: Object) => {
      const { total, currency, priceSheetCurrencyTotal } = data;

      const parentCurrency = G.getPropFromObject(GC.FIELD_CURRENCY, callbackData);

      const displayText = G.ifElse(
        R.equals(total, priceSheetCurrencyTotal),
        `${priceSheetCurrencyTotal} ${parentCurrency}`,
        `${total} ${currency} (${R.prop(GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL, data)} ${
          R.prop(GC.FIELD_CURRENCY, data)})`,
      );

      return displayText;
    },
  },
};

const payrollChargesReport = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(payrollChargesColumnSettings)),
};

const payrollChargesSettings = {
  actionsPicker: [],
  report: payrollChargesReport,
  tableCallbackDataProps: [GC.FIELD_CURRENCY],
  columnSettings: payrollChargesColumnSettings,
  primaryObjectGuidKey: GC.FIELD_PAYROLL_ASSESSORIAL_GUID,
  endpoints: {
    list: 'driverPayrollChargesByPayrollAssessorial',
  },
};

const vendorPayrollChargesSettings = {
  ...payrollChargesSettings,
  endpoints: {
    list: 'vendorPayrollChargesByPayrollAssessorial',
  },
};

const PayrollAccessorialDetails = ({ data, settings, parentProps, callbackData }: Object) => {
  const primaryObjectGuid = G.getGuidFromObject(parentProps);
  const index = R.findIndex(R.propEq(primaryObjectGuid, GC.FIELD_GUID), R.pathOr([], ['itemList'], callbackData));
  const groupName = `payrollAccessorialList.${index}.details`;

  return (
    <RelativeBox zIndex={1}>
      <FormGroupTableWithSimpleCrudOperations
        {...settings}
        {...R.pick(['openLoader', 'closeLoader', 'getItemListSuccess'], callbackData)}
        margin={20}
        itemList={data}
        isOpened={true}
        groupName={groupName}
        hasFormGroupTitle={false}
        primaryObjectGuid={primaryObjectGuid}
        currency={G.getPropFromObject(GC.FIELD_CURRENCY, parentProps)}
      />
    </RelativeBox>
  );
};

const fieldsWrapperStyles = {
  width: 870,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = (props: Object) => {
  const accessorialOptions = R.pathOr([], ['accessorialConfigs', 'accessorialConfigOptions'], props);

  const isVendor = R.propEq(GC.FIELD_VENDOR, 'fleetType', props);

  const mapped = R.compose(
    R.mapObjIndexed(({ name, options, filterType, disableFilter, type = 'string' }: Object, value: string) => ({
      value,
      options,
      placeholder,
      disableFilter,
      type: R.or(filterType, type),
      [GC.FIELD_NAME]: G.ifElse(
        G.isArray(name),
        `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
        G.getWindowLocale(name),
      ),
    })),
    R.omit([
      GC.FIELD_TRUCK_GUIDS,
      GC.FIELD_DRIVER_GUIDS,
    ]),
  )(G.ifElse(isVendor, vendorSettings, settings));

  return {
    ...mapped,
    [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: {
      placeholder,
      type: 'selectMultiple',
      options: accessorialOptions,
      value: GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
      disableFilter: R.prop('disableAccessorialFilter', props),
      name: G.getWindowLocale('titles:accessorial', 'Accessorial'),
      handleDisableOptions: (option: Object, value: Array) => R.gte(R.length(value), 10),
    },
  };
};

export const FILTER_PARAMS = (props: Object) => R.values(filterProps(props));

const getRemovePermissions = (item: Object, props: Object) => {
  const { fleetType, branchConfigs } = props;

  const { deductionDisabled, historicalPaidAmount } = item;

  const configName = G.ifElse(
    R.equals(fleetType, 'driver'),
    GC.INVOICE_TEL_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
    GC.INVOICE_FLEET_VENDOR_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
  );

  const config = G.getConfigValueFromStore(configName, branchConfigs);

  if (R.and(G.isFalse(config), R.or(deductionDisabled, R.gt(historicalPaidAmount, 0)))) return '';
};

const makeRequestPayload = (props: Object) => {
  const {
    vendorBranchGuid,
    driverBranchGuid,
    primaryObjectGuid,
    primaryObjectGuidKey,
  } = props;

  const systemFields = [{ sequence: 0, name: GC.FIELD_HISTORICAL_PAID_AMOUNT }];

  return {
    systemFields,
    [primaryObjectGuidKey]: primaryObjectGuid,
    [GC.CURRENT_BRANCH]: R.or(driverBranchGuid, vendorBranchGuid),
    reportFieldsToOmit: [GC.FIELD_NON_TAXABLE, GC.FIELD_FUEL_RELATED],
  };
};

export const payrollAccessorialSettings = {
  filterProps,
  defaultValues,
  fieldSettings,
  columnSettings,
  isReport: true,
  validationSchema,
  fieldsWrapperStyles,
  getRemovePermissions,
  useGetListRequest: true,
  useSearchableColumns: true,
  withResizableColumns: true,
  useNewTitleFilterInputs: true,
  groupName: 'payrollAccessorialList',
  reportType: GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
  itemTitleArr: ['titles-payroll-accessorial', 'Payroll Accessorial'],
  formGroupTitleArr: ['titles-payroll-accessorials', 'Payroll Accessorials'],
  additionalTableSettings: {
    expandableItems: true,
    checkBoxCellWidth: 81,
    searchableTitles: true,
    expandedDetailsComponent: (props: Object) =>
      <PayrollAccessorialDetails {...props} settings={payrollChargesSettings} />,
  },
  endpoints: {
    list: 'payrollAssessorialList',
    createOrUpdate: 'payrollAssessorial',
    entity: 'getPayrollAssessorialEndpoint',
  },
  tableCallbackDataProps: [
    'itemList',
    'openLoader',
    'closeLoader',
    'accessorialConfigs',
    'getItemListSuccess',
  ],
  // helpers
  makeRequestPayload,
  makeOptionsForSelect: R.pathOr({}, ['accessorialConfigs']),
  makeInitialValues: (initialValues: Object = {}, { branchConfigs, accessorialConfigs }: Object) => {
    const { currency, assessorialGuid } = initialValues;

    const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);

    return {
      ...initialValues,
      [GC.FIELD_CURRENCY]: R.or(currency, branchCurrency),
      [GC.FIELD_FUEL_RELATED]: R.pathOr(
        false,
        ['accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        accessorialConfigs,
      ),
      [GC.FIELD_NON_TAXABLE]: R.pathOr(
        false,
        ['accessorialConfigList', assessorialGuid, GC.FIELD_NON_TAXABLE],
        accessorialConfigs,
      ),
    };
  },
};

export const vendorPayrollAccessorialSettings = {
  ...payrollAccessorialSettings,
  fieldSettings: vendorFieldSettings,
  columnSettings: vendorColumnSettings,
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  reportType: GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
  endpoints: {
    list: 'fleetVendorPayrollAccessorialList',
    entity: 'getVendorPayrollAccessorialEndpoint',
    createOrUpdate: 'fleetVendorPayrollAccessorial',
  },
  additionalTableSettings: {
    expandableItems: true,
    checkBoxCellWidth: 81,
    searchableTitles: true,
    expandedDetailsComponent: (props: Object) =>
      <PayrollAccessorialDetails {...props} settings={vendorPayrollChargesSettings} />,
  },
  CustomForm: (props: Object) => (
    <VendorPayrollCompensationForm
      {...props}
      isVendor={true}
      validationSchema={validationSchema}
      fieldSettings={vendorFieldSettings}
      fieldsWrapperStyles={{
        ...fieldsWrapperStyles,
        overflow: 'auto',
        maxHeight: 'calc(100vh - 155px)',
      }}
    />
  ),
};
