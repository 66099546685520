import React from 'react';
import * as R from 'ramda';
// components
import { getTripStatusOptions } from '../../../../components/filter/settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// features
import PC from '../../../permission/role-permission';
import { AuthWrapper } from '../../../permission/index';
// feature dispatch-report
import { RC } from '../../settings/filter-params';
import { TableField } from '../../components/table-fields';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.GRC.COST_ALLOCATION_CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    searchable: false,
    name: ['titles:clo', 'titles:primary-reference-value'],
  },
  [GC.GRC.COST_ALLOCATION_CLO_RATE_TOTAL]: {
    width: 160,
    searchable: false,
    name: ['titles:clo', 'titles:total-rate'],
  },
  [GC.GRC.COST_ALLOCATION_CLO_TOTAL_SPENDING]: {
    width: 160,
    searchable: false,
    name: ['titles:clo', 'titles:total-spend'],
  },
  [GC.GRC.COST_ALLOCATION_CLO_STORED_TOTAL_DISTANCE]: {
    width: 160,
    searchable: false,
    type: GC.FIELD_DISTANCE,
    name: ['titles:clo', 'titles:total-distance'],
  },
  [GC.GRC.COST_ALLOCATION_CLO_COST]: {
    width: 160,
    searchable: false,
    name: 'titles:clo-income',
  },
  [GC.GRC.COST_ALLOCATION_TEL_COST]: {
    width: 160,
    searchable: false,
    name: 'titles:tel-spend',
  },
  [GC.GRC.COST_ALLOCATION_MARGIN]: {
    width: 160,
    searchable: false,
    name: 'titles:margin',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 250,
    name: 'titles:created-by',
  },
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    name: 'titles:primary-ref-value',
  },
  [GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME]: {
    width: 220,
    name: ['titles:primary-driver', 'titles:full-name'],
  },
  [RC.FLEET_ASSIGNMENT_TRUCK_UNIT_ID]: {
    width: 220,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [RC.FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    name: ['titles:first-event', 'titles:address1'],
  },
  [RC.FIRST_EVENT_LOCATION_ADDRESS2]: {
    width: 200,
    name: ['titles:first-event', 'titles:address2'],
  },
  [RC.FIRST_EVENT_LOCATION_CITY]: {
    width: 200,
    name: ['titles:first-event', 'titles:city'],
  },
  [RC.FIRST_EVENT_LOCATION_STATE]: {
    width: 200,
    name: ['titles:first-event', 'titles:state'],
  },
  [RC.FIRST_EVENT_LOCATION_ZIP]: {
    width: 200,
    name: ['titles:first-event', 'titles:zip'],
  },
  [RC.FIRST_EVENT_LATE_DATE]: {
    name: ['titles:first-event', 'titles:late-date'],
  },
  [RC.LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    name: ['titles:last-event', 'titles:address1'],
  },
  [RC.LAST_EVENT_LOCATION_ADDRESS2]: {
    width: 200,
    name: ['titles:last-event', 'titles:address2'],
  },
  [RC.LAST_EVENT_LOCATION_CITY]: {
    width: 200,
    name: ['titles:last-event', 'titles:city'],
  },
  [RC.LAST_EVENT_LOCATION_STATE]: {
    width: 200,
    name: ['titles:last-event', 'titles:state'],
  },
  [RC.LAST_EVENT_LOCATION_ZIP]: {
    width: 200,
    name: ['titles:last-event', 'titles:zip'],
  },
  [RC.LAST_EVENT_LATE_DATE]: {
    name: ['titles:last-event', 'titles:late-date'],
  },
  [GC.FIELD_STORED_TOTAL_DISTANCE]: {
    width: 200,
    name: 'titles:trip-distance',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_DISTANCE} />,
  },
  [GC.FIELD_TOTAL_DISTANCE_WITH_DEAD_HEAD]: {
    width: 200,
    name: 'titles:distance-with-deadhead',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_DISTANCE} />,
  },
  [GC.FIELD_TOTAL_PICKUP_QUANTITY]: {
    width: 200,
    name: 'titles:quantity',
  },
  [RC.CLO_COUNT]: {
    width: 150,
    name: 'titles:clo-count',
    styles: { titleInputMaxWidth: 100 },
  },
  [GC.FIELD_MARGIN]: {
    width: 200,
    name: 'titles:total-tel-margin',
    customComponent: ({ data }: Object) =>
      <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>{R.prop(GC.FIELD_MARGIN, data)}</AuthWrapper>,
  },
  [GC.FIELD_LOAD_STATUS]: {
    width: 180,
    type: 'enum',
    searchable: true,
    name: 'titles:status',
  },
};

export const FILTER_PARAMS = [
  {
    type: 'string',
    disableSort: true,
    disableFilter: true,
    value: GC.GRC.COST_ALLOCATION_CLO_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:primary-reference-value', 'Primary Reference Value')}`,
  },
  {
    type: 'string',
    disableSort: true,
    disableFilter: true,
    value: GC.GRC.COST_ALLOCATION_CLO_RATE_TOTAL,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:total-rate', 'Total Rate')}`,
  },
  {
    type: 'string',
    disableSort: true,
    disableFilter: true,
    value: GC.GRC.COST_ALLOCATION_CLO_TOTAL_SPENDING,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:total-spend', 'Total Spend')}`,
  },
  {
    type: 'string',
    disableSort: true,
    disableFilter: true,
    value: GC.GRC.COST_ALLOCATION_CLO_STORED_TOTAL_DISTANCE,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:total-distance', 'Total Distance')}`,
  },
  {
    type: 'string',
    disableSort: true,
    disableFilter: true,
    value: GC.GRC.COST_ALLOCATION_CLO_COST,
    name: `${G.getWindowLocale('titles:clo-income', 'Order Income')}`,
  },
  {
    type: 'string',
    disableSort: true,
    disableFilter: true,
    value: GC.GRC.COST_ALLOCATION_TEL_COST,
    name: `${G.getWindowLocale('titles:tel-spend', 'Trip Spend')}`,
  },
  {
    type: 'string',
    disableSort: true,
    disableFilter: true,
    value: GC.GRC.COST_ALLOCATION_MARGIN,
    name: `${G.getWindowLocale('titles:margin', 'Margin')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'string',
    value: GC.FIELD_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value'),
  },
  {
    type: 'string',
    value: GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:full-name', 'Full Name')}`,
  },
  {
    type: 'string',
    value: RC.FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${
      G.getWindowLocale('titles:unit-id', 'Unit ID')} `,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: RC.FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: RC.FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('ctitles:city', 'City')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: RC.LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: RC.LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'number',
    value: GC.FIELD_STORED_TOTAL_DISTANCE,
    name: G.getWindowLocale('titles:trip-distance', 'Trip Distance'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_DISTANCE_WITH_DEAD_HEAD,
    name: G.getWindowLocale('titles:distance-with-deadhead', 'Distance With Deadhead'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_PICKUP_QUANTITY,
    name: G.getWindowLocale('titles:quantity', 'Quantity'),
  },
  {
    type: 'number',
    value: RC.CLO_COUNT,
    name: G.getWindowLocale('titles:clo-count', 'CLO Count'),
  },
  {
    type: 'number',
    value: GC.FIELD_MARGIN,
    name: G.getWindowLocale('titles:total-tel-margin', 'Total Trip Margin'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_LOAD_STATUS,
    options: getTripStatusOptions(),
    name: G.getWindowLocale('titles:status', 'Status'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
];
