import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const defaultStyles = {
  width: 370,
  labelPl: 10,
  afterTop: 15,
  afterRight: 15,
  errorTop: '105%',
  errorLeft: '5px',
  errorTitle: true,
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

export const defaultOnboardingPackageFields = {
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_NAME]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO]: null,
};

export const defaultOnboardingPackageDocumentFields = {
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DESCRIPTION]: null,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_NAME]: null,
};

export const onboardingPackageValidationSchemaObject = {
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO]: G.yupArrayNotRequired,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE]: G.yupStringNotRequired,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT]: G.yupStringNotRequired,
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO]: G.yupStringNotRequired.email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_NAME]: G.yupStringRequired.max(255, G.getShouldBeFromToLocaleTxt(0, 255)),
};

export const onboardingPackageDocumentValidationSchemaObject = ({ url, file }: Object) => {
  const defaultSchema = {
    [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE]: Yup.string().nullable(true),
    [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DESCRIPTION]: Yup.string().nullable(true),
    [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL]: Yup.string().nullable(true).url(G.getShouldBeUrlLocaleTxt()),
    [GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_NAME]: Yup.string()
      .nullable(true)
      .max(255, G.getShouldBeFromToLocaleTxt(0, 255)),
  };
  if (R.any(G.isNotNilAndNotEmpty, [url, file])) return defaultSchema;
  const requiredFields = {
    [GC.FIELD_DOCUMENT_URL]: Yup.string().nullable(true).required(G.getShouldBeFileOrUrlLocaleTxt()),
    [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string().nullable(true).required(G.getShouldBeFileOrUrlLocaleTxt()),
  };
  return R.mergeRight(defaultSchema, requiredFields);
};

export const onboardingPackageDocumentFields = (initialValues: Object) => [
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:document-name',
    customDisabledFunction: 'handleDisableFileName',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_NAME,
    fieldGroupDisplay: (_: any, values: Object) =>
      G.setFieldsGroupDisplay(values, GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL, initialValues),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:url',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL,
    fieldGroupDisplay: (_: any, values: Object) =>
      G.setFieldsGroupDisplay(values, GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE, initialValues),
  },
  {
    ...defaultStyles,
    type: 'file',
    loc: 'titles:upload-file',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE,
    fieldGroupDisplay: (_: any, values: Object) =>
      G.setFieldsGroupDisplay(values, GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL, initialValues),
  },
  {
    ...defaultStyles,
    type: 'textarea',
    loc: 'titles:description',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DESCRIPTION,
  },
];

export const onboardingPackageFields = [
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:name',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_NAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:reply-to',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO,
  },
  {
    ...defaultStyles,
    type: 'multiEmail',
    loc: 'titles:copy-to',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:subject',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT,
  },
  {
    ...defaultStyles,
    type: 'textarea',
    loc: 'titles:message',
    fieldName: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE,
  },
];

export const termsAndConditionsDocumentFieldSettings = [
  {
    ...defaultStyles,
    type: 'file',
    loc: 'titles:upload-file',
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
  },
];

export const defaultTermsAndConditionsDocumentFields = {
  [GC.FIELD_DOCUMENT_UPLOAD]: null,
};

export const termsAndConditionsDocumentValidationSchema = Yup.object().shape({
  [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

export const defaultDeactivationRuleValues = {
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERCENT]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT]: null,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const triggerOptions = [
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DECLINE,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DECLINE),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_TEL,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_TEL),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_PICKUP,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_PICKUP),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_DROP,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_DROP),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_EVENT_COMPLETE_BY_CARRIER,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_EVENT_COMPLETE_BY_CARRIER),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_INVOICE_TIME,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_INVOICE_TIME),
  },
  {
    value: GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DOCUMENT_TIME,
    label: G.getEnumLocale(GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DOCUMENT_TIME),
  },
];

const percentTriggers = [
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DECLINE,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_TEL,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_DROP,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_PICKUP,
  GC.CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_EVENT_COMPLETE_BY_CARRIER,
];

export const getDeactivationRuleFields = (values: Object, isEdit: boolean = false) => {
  const { trigger } = values;

  const triggerField = {
    isRequired: true,
    disabled: isEdit,
    type: 'reactSelect',
    options: triggerOptions,
    shouldCustomChange: true,
    label: ['titles:trigger', 'Trigger'],
    fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER,
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 11 },
  };

  if (G.isNilOrEmpty(trigger)) return R.of(Array, triggerField);

  if (R.equals(trigger, GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION)) {
    return [
      triggerField,
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: ['titles:deactivation-days', 'Deactivation Days'],
        fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS,
      },
      {
        isMulti: true,
        type: 'reactSelect',
        options: 'insuranceTypeOptions',
        label: ['titles:insurance-types', 'Insurance Types'],
        inputWrapperStyles: { ...inputWrapperStyles, zIndex: 10 },
        fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS,
      },
    ];
  }

  const commonFields = [
    {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:minimum-load-count', 'Minimum Load Count'],
      fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT,
    },
    {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:period-in-days', 'Period In Days'],
      fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS,
    },
  ];

  if (R.includes(trigger, percentTriggers)) {
    return [
      triggerField,
      ...commonFields,
      {
        type: 'text',
        isRequired: true,
        inputWrapperStyles,
        label: ['titles:percent', 'Percent'],
        fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_PERCENT,
      },
    ];
  }

  return [
    triggerField,
    ...commonFields,
    {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:average-hours', 'Average Hours'],
      fieldName: GC.FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS,
    },
  ];
};

const insuranceExpirationValidationSchema = {
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER]: G.yupStringRequired,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS]: G.yupArrayRequired,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBeFromToLocaleTxt(0, 365))
    .max(365, G.getShouldBeFromToLocaleTxt(0, 365)),
};

const commonValidationSchema = {
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER]: G.yupStringRequired,
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT]: Yup.number()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBeFromToLocaleTxt(0, 1000))
    .max(1000, G.getShouldBeFromToLocaleTxt(0, 1000)),
  [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS]: Yup.number()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBeFromToLocaleTxt(0, 365))
    .max(365, G.getShouldBeFromToLocaleTxt(0, 365)),
};

export const getDeactivationRuleValidationSchema = ({ trigger }: Object) => {
  if (R.equals(trigger, GC.CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION)) {
    return insuranceExpirationValidationSchema;
  }

  if (R.includes(trigger, percentTriggers)) {
    return {
      ...commonValidationSchema,
      [GC.FIELD_CARRIER_DEACTIVATION_RULE_PERCENT]: Yup.number()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .min(0, G.getShouldBeFromToLocaleTxt(0, 100))
        .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
    };
  }

  return {
    ...commonValidationSchema,
    [GC.FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .min(0, G.getShouldBeFromToLocaleTxt(0, 672))
      .max(672, G.getShouldBeFromToLocaleTxt(0, 672)),
  };
};
