import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
// components
import MessageCenter from '../../../../components/message-center';
import { ConfirmComponent } from '../../../../components/confirm';
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, scrollableContainerCss4px } from '../../../../ui';
// feature claim-management
import NoteForm from '../forms/note';
import { makeSelectNotes } from '../../selectors';
import { removeNoteRequest, getNoteListRequest, createOrUpdateNoteRequest } from '../../actions';
//////////////////////////////////////////////////

const NotesCard = (props: Object) => {
  const { dispatch, openModal, claimGuid, openFixedPopup, closeFixedPopup } = props;

  const notes = useSelector(makeSelectNotes());

  useEffect(() => {
    if (R.isNil(notes)) dispatch(getNoteListRequest());
  }, []);

  const handleAddMessage = useCallback(({ message }: Object) => {
    dispatch(createOrUpdateNoteRequest({ claimGuid, [GC.FIELD_TEXT]: message }));
  }, []);

  const handlePinOrUnpinMessage = useCallback(({ pinned, ...entity }: Object) => {
    dispatch(createOrUpdateNoteRequest({ ...entity, pinned: R.not(pinned) }));
  }, []);

  const handleEditNote = useCallback((entity: Object) => {
    closeFixedPopup();

    const component = (
      <NoteForm
        initialValues={entity}
        submitAction={(values: Object) => dispatch(createOrUpdateNoteRequest(values))}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        title: G.getEditTitle(['titles:note', 'Note']),
      },
    };

    openModal(modal);
  }, []);

  const handleRemoveNote = useCallback((guid: string) => {
    closeFixedPopup();

    const textLocale = G.getWindowLocale(
      'messages:remove-ref-confirmation-text',
      'Are you sure you want delete this note?',
    );

    const component = <ConfirmComponent textLocale={textLocale} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => dispatch(removeNoteRequest(guid)),
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };

    openModal(modal);
  }, []);

  const handleClickEditIcon = useCallback(({ currentTarget }: Object, entity: Object) => {
    const iconColor = G.getTheme('colors.light.blue');

    const options = [
      {
        permissions: null,
        frontIcon: I.pencil(iconColor),
        action: () => handleEditNote(entity),
        text: G.getWindowLocale('actions:edit', 'Edit'),
      },
      {
        permissions: null,
        frontIcon: I.trash(iconColor),
        text: G.getWindowLocale('actions:delete', 'Delete'),
        action: () => handleRemoveNote(G.getGuidFromObject(entity)),
      },
    ];

    openFixedPopup({
      position: 'right',
      el: currentTarget,
      content: <ActionsElement version={2} options={options} />,
    });
  }, []);

  return (
    <Box
      bg='white'
      height={400}
      borderRadius='8px'
      border='1px solid'
      borderColor='#E0E0E0'
    >
      <Flex
        px={15}
        height={45}
        fontSize={16}
        bg='steelBlue02'
        fontWeight='bold'
        color='greyMatterhorn'
        borderTopLeftRadius='8px'
        borderTopRightRadius='8px'
      >
        {G.getWindowLocale('titles:notes', 'Notes', { caseAction: 'upperCase' })}
      </Flex>
      <MessageCenter
        width='100%'
        height={355}
        padding='8px'
        isActiveTabNotes={true}
        entities={R.or(notes, [])}
        submitAction={handleAddMessage}
        addPermissions={[PC.CLAIM_WRITE]}
        editPermissions={[PC.CLAIM_WRITE]}
        handleClickEditIcon={handleClickEditIcon}
        handlePinMessage={handlePinOrUnpinMessage}
        additionalStyles={scrollableContainerCss4px}
        addMessageText={G.getWindowLocale('actions:post', 'Post')}
      />
    </Box>
  );
};

export default withFixedPopover(NotesCard);
