import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Text } from '../../../ui';
//////////////////////////////////////////////////

const renderItemInfo = (
  index: number,
  fleet: Object,
  fleetType: string,
  lnl: Object,
) => {
  const { lastModifiedDate } = lnl;

  const { email, unitId, lastName, firstName, phoneNumber } = fleet;

  if (R.equals(fleetType, 'drivers')) {
    return (
      <div key={index}>
        <Text fontWeight='bold'>{`${firstName} ${lastName}`}</Text>
        <div>{G.concatLocationFields(lnl)}</div>
        <div>{lastModifiedDate}</div>
        <div>{phoneNumber}</div>
        <div>{email}</div>
      </div>
    );
  }

  return (
    <div key={index}>
      <Text fontWeight='bold'>{unitId}</Text>
      <div>{G.concatLocationFields(lnl)}</div>
      <div>{lastModifiedDate}</div>
    </div>
  );
};

const FleetsContent = ({ lnl, fleets, fleetType }: Object) => (
  <Box
    height='100%'
    overflow='auto'
    maxHeight={400}
  >
    {
      fleets.map((fleet: Object, index: number) => (
        renderItemInfo(index, fleet, fleetType, lnl)
      ))
    }
  </Box>
);

const renderMultiContent = (fleetType: string, fleets: Array) => fleets.map((item: Object, index: number) => {
  const fleet = R.prop('fleetObj', item);
  const lnl = R.prop('lnl', item);

  return renderItemInfo(index, fleet, fleetType, lnl);
});

const MultiFleetContent = ({ drivers, trucks, trailers }: Object) => (
  <Box
    height='100%'
    overflow='auto'
    maxHeight={400}
  >
    {G.isNotNilAndNotEmpty(drivers) && renderMultiContent('drivers', drivers)}
    {G.isNotNilAndNotEmpty(trucks) && renderMultiContent('trucks', trucks)}
    {G.isNotNilAndNotEmpty(trailers) && renderMultiContent('trailers', trailers)}
  </Box>
);

export { FleetsContent, MultiFleetContent };
