import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// features
import { setEdiFileToStore } from '../new-do/actions';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature edi-integration
import * as A from './actions';
import { FILTER_PARAMS } from './settings/filter-params';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectExternalIdFilter,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

export function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const systemFields = [
      GC.FIELD_GUID,
      GC.FIELD_ERRORS,
      GC.FIELD_STATUS,
      GC.FIELD_BRANCH_GUID,
      GC.FIELD_EDI_FILE_URI,
      GC.GRC.EDI_CLO_STATUS,
      GC.GRC.EDI_CREATED_CLO_GUID,
      GC.GRC.EDI_CREATED_CLO_STATUS,
      GC.FIELD_EDI_SENDER_QUALIFIER,
      GC.FIELD_EDI_ORIGINAL_FILE_NAME,
      GC.GRC.EDI_CLO_TRANSACTION_ACTION,
    ];

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      systemFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.addSystemFieldsToTableReport(
      G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      systemFields,
    );

    const res = yield call(sendRequest, 'post', endpointsMap.receivedCloList, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(R.assoc('currentBranch', currentBranchGuid, { data, guids })));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }
    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

export function* handleDeleteItemRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.receivedClo, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemRequest fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteItemRequest exception');
  }
}

function* handleAcceptLoadFromEDIRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.setListLoading(true));

    const res = yield call(sendRequest, 'put', endpointsMap.receivedCloAccept, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const transactionGuid = R.prop(GC.FIELD_EDI_TRANSACTION_GUID, payload);

      yield put(A.acceptLoadFromEDISuccess({ status: data, transactionGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleAcceptLoadFromEDIRequestSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAcceptLoadFromEDIRequestSaga exception');
  }
}

function* handleDeclineLoadFromEDIRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.receivedCloDecline, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const transactionGuid = R.prop(GC.FIELD_EDI_TRANSACTION_GUID, payload);

      yield put(A.declineLoadFromEDISuccess({ status: data, transactionGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeclineLoadFromEDIRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeclineLoadFromEDIRequestSaga exception');
  }
}

export function* handleCancelLoadFromEDIRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { cloGuid, transactionGuid } = payload;

    const options = {
      data: { [GC.FIELD_GUID]: cloGuid },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.cancelCLO, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setCloLoadFromEDIStatus({ status: data, transactionGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleCancelLoadFromEDIRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCancelLoadFromEDIRequestSaga');
  }
}

function* handleDownloadEdiFileSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { action, fileUri, fileName } = payload;

    const options = {
      params: { fileUri },
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'get', endpointsMap.receivedCloDownloadFile, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(action, 'download')) {
        FileDownload(data, fileName);
      } else {
        yield put(setEdiFileToStore(G.decodeArrayBuffer(data)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDownloadEdiFileSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDownloadEdiFileSaga exception');
  }
}

export function* handleSetFilterParams() {
  yield put(A.setFilterProps(FILTER_PARAMS));
}

function* handleSetExternalFilterToUsedReport({ externalId }: Object) {
  try {
    const usedReport = yield select(makeSelectUsedReport());

    const searchCriteria = R.compose(
      R.append({
        dataType: 'string',
        operation: 'equal',
        stringValue: externalId,
        propertyName: GC.FIELD_EXTERNAL_ID,
      }),
      R.reject(R.propEq(GC.FIELD_EXTERNAL_ID, 'propertyName')),
      R.propOr([], 'searchCriteria'),
    )(usedReport);

    yield put(A.setUsedReport(R.assoc('searchCriteria', searchCriteria, usedReport)));
  } catch (error) {
    yield call(G.handleException, error, 'handleSetExternalFilterToUsedReport exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.EDI_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

export function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_EDI_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());
    const externalIdFilter = yield select(makeSelectExternalIdFilter());

    yield put(A.setExternalIdFilter(null));

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport(['additionalFilters']));
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });

    if (R.isNotNil(externalIdFilter)) {
      yield call(handleSetExternalFilterToUsedReport, { externalId: externalIdFilter });
    }

    yield call(handleGetItemListSaga, { payload: { openLoader: true } });
    yield call(handleSetFilterParams);

    yield put(closeLoader());

    break;
  }
}

function* pageWatcherSaga() {
  yield takeLatest(GC.VISIT_EDI_LIST_PAGE, handleVisitPageSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.downloadEdiFileRequest, handleDownloadEdiFileSaga);
  yield takeLatest(A.deleteItemRequest, handleDeleteItemRequest);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.cancelLoadFromEDIRequest, handleCancelLoadFromEDIRequestSaga);
  yield takeLatest(A.acceptLoadFromEDIRequest, handleAcceptLoadFromEDIRequestSaga);
  yield takeLatest(A.declineLoadFromEDIRequest, handleDeclineLoadFromEDIRequestSaga);
}

export default pageWatcherSaga;
