import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 280,
};

const fieldsWrapperStyles = {
  flexDirection: 'column',
};

const fields = {
  [GC.FIELD_RATE]: {
    width: 100,
    type: 'number',
    isRequired: true,
    name: 'titles:rate',
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 150,
    type: 'select',
    isRequired: true,
    name: 'titles:rate-unit',
    options: [
      GC.EMPTY_OPTION_OBJECT,
      { value: GC.DISCOUNT_TYPE_FLAT, label: G.getWindowLocale('titles:flat', 'Flat') },
      { value: GC.CHARGE_RATE_UNIT_TYPE_PERCENT, label: G.getWindowLocale('titles:percent', 'Percent') },
    ],
  },
  [GC.FIELD_VALUE_FROM]: {
    width: 110,
    type: 'number',
    isRequired: true,
    name: 'titles:value-from',
  },
  [GC.FIELD_VALUE_TO]: {
    width: 110,
    type: 'number',
    isRequired: true,
    name: 'titles:value-to',
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CHARGE_RATE_UNIT]: G.yupStringRequired,
  [GC.FIELD_RATE]: G.yupNumberRequired.max(9999999, G.getShouldBeLessOrEqualLocaleTxt(9999999)),
  [GC.FIELD_VALUE_TO]: G.yupNumberRequired.max(9999999, G.getShouldBeLessOrEqualLocaleTxt(9999999)),
  [GC.FIELD_VALUE_FROM]: G.yupNumberRequired.max(9999999, G.getShouldBeLessOrEqualLocaleTxt(9999999)),
});

const variableValuesSettings = {
  fields,
  endpoints: {},
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  tableInnerWidth: 940,
  groupName: 'variableDiscounts',
  itemTitleArr: ['titles:discount', 'Discount'],
  omitColumns: GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR_2,
  formGroupTitleArr: ['titles:variable-discounts', 'Variable Discounts'],
  // helpers
  customRemoveHandler: ({ setValues, closeModal }: Object, { guid }: Object) => setValues((prevValues: Object) => {
    const variableValues = R.reject(
      R.propEq(guid, GC.FIELD_GUID),
      R.path([GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_PAYROLL_VARIABLE_VALUES], prevValues),
    );

    if (G.isZero(R.length(variableValues))) {
      G.showToastrMessageSimple('error', 'At least one of range values is required');

      return prevValues;
    }

    closeModal();

    return R.assocPath([GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_PAYROLL_VARIABLE_VALUES], variableValues, prevValues);
  }),
  customCreateOrUpdateHandler: ({ values, setValues, closeModal }: Object) => setValues((prevValues: Object) => {
    const { guid } = values;

    const variableValues = R.pathOr([], [GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_PAYROLL_VARIABLE_VALUES], prevValues);

    closeModal();

    if (R.isNotNil(guid)) {
      const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), variableValues);

      return R.assocPath([GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_PAYROLL_VARIABLE_VALUES, index], values, prevValues);
    }

    const item = G.ifElse(
      R.isNil(G.getGuidFromObject(values)),
      R.assoc(GC.FIELD_GUID, G.generateGuid(), values),
      values,
    );

    return R.assocPath(
      [GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_PAYROLL_VARIABLE_VALUES],
      R.append(item, variableValues),
      prevValues,
    );
  }),
};

const awardedLaneVariableDiscountSettings = {
  ...variableValuesSettings,
  tableInnerWidth: 980,
  groupName: 'awardedLaneVariableDiscounts',
};

const customerContractVariableDiscountSettings = {
  ...variableValuesSettings,
  groupName: 'customerContractVariableDiscounts',
};

export {
  variableValuesSettings,
  awardedLaneVariableDiscountSettings,
  customerContractVariableDiscountSettings,
};
