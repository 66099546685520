import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { PageActions } from '../../components/page-actions';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import PC from '../permission/role-permission';
import { AuthWrapper } from '../permission/index';
import ReferenceFormComponent from '../reference/components/reference-form';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../reference/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { IconWrapper, ListWrapper, ZOrderWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature branch
import BranchTree from './components/branch-tree';
import { FILTER_PARAMS } from './settings/filter-params';
import BranchActions from './components/element-actions';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import ShareWithDivisions from './components/share-with-divisions';
import {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectSelectedList,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  shareBranches,
  setUsedReport,
  unshareBranches,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  switchBranchRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  createBranchRefRequest,
  exportReportDataRequest,
  openAddBranchModalRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withHandlers({
    handleAddReferenceList: ({ openModal, selectedList, createBranchRefRequest }: Object) => () => {
      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const multiAddingEntRef = (values: Object) => {
        const params = R.assoc('primaryObjectGuids', selectedList, values);
        createBranchRefRequest(params);
      };

      const modalContent = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_BRANCH}
          submitAction={(params: Object) => multiAddingEntRef(params)}
        />
      );

      const modal = G.createAddReferenceModalOptions(modalContent);

      openModal(modal);
    },
    handleSharing: (props: Object) => (share: boolean) => {
      const { openModal, closeModal, shareBranches, selectedList, listOfBranches, unshareBranches } = props;

      const action = G.ifElse(share, shareBranches, unshareBranches);

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const customerGuids = R.compose(
        R.map(R.prop(GC.FIELD_GUID)),
        R.filter((branch: Object) => R.propEq(GC.BRANCH_TYPE_ENUM_CUSTOMER, GC.BRANCH_TYPE, branch)),
        R.values,
        R.pick(selectedList),
      )(listOfBranches);

      if (R.isEmpty(customerGuids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:division-cant-be-shared', 'Branch type DIVISION can not be shared'),
        );
      }

      const title = G.ifElse(
        share,
        G.getWindowLocale('actions:share-with-divisions', 'Share With Divisions'),
        G.getWindowLocale('actions:remove-sharing', 'Remove Sharing'),
      );

      const modal = {
        p: '0px',
        component: (
          <BranchTree
            width={600}
            allowSelect={true}
            rootDisabled={true}
            initiallyExpanded={true}
            closeAction={closeModal}
            branchTypeFilter={GC.BRANCH_TYPE_ENUM_DIVISION}
            handleMultipleSelect={(divisionGuids: Array) => action({
              divisionGuids,
              customerGuids,
            })}
          />
        ),
        options: {
          title,
          width: 500,
          height: 'auto',
          minWidth: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleEdit: () => (guid: string) => {
      G.goToRoute(routesMap.getEditBranchRoute(guid));
    },
    handleShowChild: (props: Object) => () => {
      const { openModal, closeModal, closeFixedPopup, switchBranchRequest } = props;

      closeFixedPopup();

      const modal = {
        p: '0px',
        component: (
          <BranchTree
            width={500}
            allowPin={true}
            cursor='pointer'
            allowActions={true}
            closeAction={closeModal}
            onClickBranch={(guid: string) => {
              closeModal();
              switchBranchRequest(guid);
            }}
          />
        ),
        options: {
          width: 500,
          height: 'auto',
          minWidth: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleShareWithDivisions: (props: Object) => (guid: string) => {
      const { openModal } = props;

      const modal = {
        p: '0px',
        component: (
          <ShareWithDivisions branchGuid={guid} />
        ),
        options: {
          width: 500,
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('actions:share-with-divisions', 'Share With Divisions'),
        },
      };

      openModal(modal);
    },
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, ent: Object, callback: Function) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <BranchActions
            parent={ent}
            prefix='list'
            callback={callback}
            closeFixedPopup={props.closeFixedPopup}
            handleShowChild={props.handleShowChild}
            handleShareWithDivisions={props.handleShareWithDivisions}
          />
        ),
      })
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

export const renderTable = (props: Object) => {
  const {
    loading,
    handleEdit,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    listOfBranches,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const itemList = R.values(listOfBranches);

  const elementActionsComponent = (ent: Object) => (
    <AuthWrapper has={[PC.BRANCH_WRITE]}>
      <IconWrapper p='0 10px' cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, ent)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEdit,
      permissions: [PC.BRANCH_WRITE],
    },
  ];

  const data = {
    loading,
    itemList,
    totalCount,
    allChecked,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    renderRightStickedComponent: elementActionsComponent,
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getItemListRequest,
    ),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

export const BranchListComponent = (props: Object) => {
  const {
    reportList,
    selectedList,
    handleSharing,
    getItemListRequest,
    handleAddReferenceList,
    openAddBranchModalRequest,
  } = props;

  const mainLight = G.getTheme('colors.light.mainLight');

  const listActionsOpt = [
    {
      type: 'massAction',
      action: handleAddReferenceList,
      permissions: [PC.BRANCH_WRITE],
      icon: I.plusRound(mainLight, 25, 25),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    },
    {
      type: 'massAction',
      permissions: [PC.BRANCH_WRITE],
      action: () => handleSharing(true),
      icon: I.grantTo(mainLight, 25, 25),
      text: G.getWindowLocale('actions:share-with-divisions', 'Share With Divisions'),
    },
    {
      type: 'massAction',
      permissions: [PC.BRANCH_WRITE],
      action: () => handleSharing(false),
      icon: I.grantTo(mainLight, 25, 25),
      text: G.getWindowLocale('actions:remove-sharing', 'Remove Sharing'),
    },
  ];

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          type={GC.BRANCH_REPORT}
          reportOptions={reportList}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          getItemListRequest={getItemListRequest}
          title={G.getWindowLocale('titles:list-of-branches', 'List of Companies')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.BRANCH_WRITE]}>
        <PageActions
          listActions={listActionsOpt}
          count={R.length(selectedList)}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            text: G.getWindowLocale('actions:create-branch', 'Create Branch'),
            action: () => openAddBranchModalRequest({ type: GC.BRANCH_TYPE_CUSTOMER_STORED_VALUE }),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  listOfBranches: makeSelectItemList(state),
  filterParams: makeSelectFilterParams(state),
  selectedList: makeSelectSelectedList(state),
  selectedReport: makeSelectUsedReport(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  selectItem,
  setReports,
  closeModal,
  setUsedReport,
  shareBranches,
  unshareBranches,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  updateReportRequest,
  createReportRequest,
  setTableTitleFilter,
  switchBranchRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  createBranchRefRequest,
  exportReportDataRequest,
  openAddBranchModalRequest,
  changeDefaultReportRequest,
})(enhance(BranchListComponent));
