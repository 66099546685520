import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box } from '../../../../ui';
// feature claim-management
import AccidentForm from '../forms/accident';
import { makeSelectAccident } from '../../selectors';
import { getAccidentRequest, removeAccidentRequest, createOrUpdateAccidentRequest } from '../../actions';
//////////////////////////////////////////////////

const AccidentDetails = (props: Object) => {
  const {
    dispatch,
    claimGuid,
    openModal,
    claimBranchGuid,
  } = props;

  const accident = useSelector(makeSelectAccident());

  useEffect(() => {
    if (R.isNil(accident)) dispatch(getAccidentRequest());
  }, []);

  const handleCreateOrUpdateAccidentRequest = (values: Object) => dispatch(createOrUpdateAccidentRequest(values));

  const handleRemoveAccident = useCallback((guid: string) => {
    if (R.isNil(guid)) return;

    const textLocale = G.getWindowLocale(
      'messages:confirm-delete-entity',
      'Are you sure you want to delete this entity?',
    );

    const component = <ConfirmComponent textLocale={textLocale} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => dispatch(removeAccidentRequest(guid)),
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };

    openModal(modal);
  }, []);

  return (
    <Box bg='white' border='1px solid' borderColor='#EDEFF1' borderRadius='8px'>
      <AccidentForm
        claimGuid={claimGuid}
        initialValues={accident}
        claimBranchGuid={claimBranchGuid}
        removeAction={handleRemoveAccident}
        submitAction={handleCreateOrUpdateAccidentRequest}
      />
    </Box>
  );
};

export default AccidentDetails;
