import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers
import * as G from '../../../helpers';
// forms
import { FormGroupWrapper } from '../../../forms';
// ui
import { Box, Text } from '../../../ui';
// feature components-story
import { setToggleFormGroup } from '../actions';
import { makeSelectCollapsedGroup } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const COMPANY_DETAIL = 'companyDetail';

const FormGroupTitle = (props: Object) => {
  const { collapsedGroup, setToggleFormGroup } = props;

  const description = '<FormGroupTitle /> component is used to display a title for a form group.';

  const usage = (
    <ul>
      <li>Parameter `<i>mt</i>` is a margin top for the form group title.</li>
      <li>Parameter `<i>mb</i>` is a margin bottom for the form group title.</li>
      <li>Parameter `<i>text</i>` the main text content of the form group title.</li>
      <li>Parameter `<i>endIcon</i>` is an optional icon displayed at the end of the title.</li>
      <li>Parameter `<i>endLabel</i>` is an optional label displayed at the end of the title.</li>
      <li>Parameter `<i>onClickLabel</i>` a function to handle click events on the end label.</li>
      <li>Parameter `<i>frontIcon</i>` is an optional icon displayed at the beginning of the title.</li>
      <li>Parameter `<i>onClickFrontLabel</i>` a function to handle click events on the front label.</li>
      <li>Parameter `<i>frontLabel</i>` is an optional label displayed at the beginning of the title.</li>
      <li>Parameter `<i>withArrowDown</i>` is a boolean indicating whether to display a down arrow icon.</li>
      <li>Parameter `<i>isOpened</i>` is a boolean indicating whether the form group is opened or closed.</li>
      <li>Parameter `<i>onToggleFormGroup</i>` a function to handle click events on the form group title.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='FormGroupTitle'
      description={description}
      path='src/components/form-group-title'
    >
      <FormGroupWrapper isOpened={R.path([COMPANY_DETAIL], collapsedGroup)}>
        <FormGroupTitleComponent
          withArrowDown={true}
          isOpened={R.path([COMPANY_DETAIL], collapsedGroup)}
          onToggleFormGroup={() => setToggleFormGroup(COMPANY_DETAIL)}
          text={G.getWindowLocale('titles:company-details', 'Company Details')}
        />
        <Box width={500}>
          <Text>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus PageMaker including
            versions of Lorem Ipsum.
          </Text>
        </Box>
      </FormGroupWrapper>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  collapsedGroup: makeSelectCollapsedGroup(state),
});

export default connect(mapStateToProps, { setToggleFormGroup })(FormGroupTitle);
