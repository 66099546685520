import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import isToday from 'date-fns/isToday';
import { createStructuredSelector } from 'reselect';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, RelativeFlex, StickedFlex, AbsoluteBox } from '../../../ui';
// features drivers-card
import { makeSelectZoom } from '../selectors';
import { getMainCardWidth } from '../helpers';
import { HOUR_LINE_WIDTH } from '../constants';
//////////////////////////////////////////////////

const darkGreyColor = G.getTheme('colors.dark.grey');

const HoursLine = ({ zoom }: Object) => (
  <AbsoluteBox top='-38px' width='100%'>
    <Flex pl={0} width='100%' justifyContent='space-between'>
      {
        R.times(R.identity, 24).map((item: number) => (
          <Flex
            key={item}
            flexDirection='column'
            alignItems='flex-start'
            width={`${R.multiply(HOUR_LINE_WIDTH, zoom)}px`}
          >
            <Box width='1px' height={15} bg={darkGreyColor} />
            <Box pt='3px' fontSize={10} color={darkGreyColor}>
              {`${item}:00`}
            </Box>
          </Flex>
        ))
      }
    </Flex>
  </AbsoluteBox>
);

const HoursLine2 = ({ zoom, expandedContainer }: Object) => (
  <AbsoluteBox top='-38px' width='100%'>
    <Flex pl={0} width='100%' justifyContent='space-between'>
      {
        R.times(R.identity, 24).map((item: number) => (
          <Flex
            key={item}
            flexDirection='column'
            alignItems='flex-start'
            width={`${R.multiply(HOUR_LINE_WIDTH, zoom)}px`}
          >
            <AbsoluteBox
              top='0px'
              opacity={0.4}
              border='1px dotted'
              borderColor={darkGreyColor}
              height={`calc(100vh - ${G.ifElse(expandedContainer, '255px', '165px')})`}
            />
          </Flex>
        ))
      }
    </Flex>
  </AbsoluteBox>
);

const getDates = (props: Object) => {
  const dateTo = R.path(['filtersStore', 'dateTo'], props);
  const dateFrom = R.path(['filtersStore', 'dateFrom'], props);
  let dates = [];

  if (R.and(G.isNotNilAndNotEmpty(dateFrom), G.isNotNilAndNotEmpty(dateTo))) {
    dates = eachDayOfInterval({
      end: G.getNewDate(dateTo),
      start: G.getNewDate(dateFrom),
    });
  }

  return dates;
};

const CalendarLine = (props: Object) => {
  const { zoom, show24HoursView } = props;

  const dates = getDates(props);
  const today = G.getCurrentDate();
  const todayDate = today.date();
  const todayText = today.format('dddd');
  const whiteColor = G.getTheme('colors.white');
  const darkGreyColor = G.getTheme('colors.darkGrey');
  const darkBlueColor = G.getTheme('colors.dark.blue');
  const mainCardWidth = getMainCardWidth(show24HoursView, zoom);

  return (
    <StickedFlex
      p={10}
      top='0'
      height={60}
      zIndex={2001}
      minWidth='100%'
      borderRadius='1px'
      alignItems='flex-end'
      bg={G.getTheme('colors.bgGrey')}
    >
      {
        dates.map((item: number, index: number) => {
          const instance = G.makeMomentInstance(item);
          const date = instance.date();
          const dateText = instance.format('dddd');
          const monthText = instance.format('MMMM');

          if (isToday(item)) {
            return (
              <RelativeFlex key={index} width={mainCardWidth} fontWeight='bold' id='driver-card-scroll-to-id'>
                {
                  G.isTrue(show24HoursView) && <HoursLine zoom={zoom} />
                }
                <Flex mt='-6px' alignItems='center'>
                  <Box
                    mr={10}
                    p='3px'
                    borderRadius='4px'
                    color={whiteColor}
                    bg={darkBlueColor}
                  >
                    {`${todayDate} ${monthText} (${todayText})`}
                  </Box>
                </Flex>
              </RelativeFlex>
            );
          }

          return (
            <RelativeFlex key={index} width={mainCardWidth} fontWeight='bold'>
              {
                G.isTrue(show24HoursView) && <HoursLine zoom={zoom} />
              }
              <Box color={darkGreyColor}>
                {`${date} ${monthText} (${dateText})`}
              </Box>
            </RelativeFlex>
          );
        })
      }
    </StickedFlex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  zoom: makeSelectZoom(state),
});

export const CalendarLine2 = connect(mapStateToProps, {})(
  (props: Object) => {
    const { zoom, show24HoursView, expandedContainer } = props;

    const dates = getDates(props);
    const mainCardWidth = getMainCardWidth(show24HoursView, zoom);

    return (
      <StickedFlex px={10} top='0px'>
        {
          dates.map((item: number, index: number) => (
            <RelativeFlex key={index} width={mainCardWidth} fontWeight='bold'>
              <AbsoluteBox
                top='0px'
                opacity={0.4}
                border='1px dashed'
                borderColor={darkGreyColor}
                height={`calc(100vh - ${G.ifElse(expandedContainer, '295px', '200px')})`}
              />
              {
                G.isTrue(show24HoursView) && <HoursLine2 zoom={zoom} />
              }
            </RelativeFlex>
          ))
        }
      </StickedFlex>
    );
  },
);

export default connect(mapStateToProps, {})(CalendarLine);
