import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable } from '../../../../components/form-group-table';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet
import * as C from '../../constants';
import EquipmentComponentForm from '../../components/equipment-component-form';
import EquipmentServiceReport from '../../equipment-service/components/report';
// feature fleet/trailer
import { componentColumns } from '../settings/column-settings';
import { makeSelectTrailerEquipComponent } from '../selectors';
import {
  printTrailer,
  createTrailerComponentRequest,
  updateTrailerComponentRequest,
  deleteTrailerComponentRequest,
} from '../actions';
//////////////////////////////////////////////////

const eqComponentEnhance = compose(
  withHandlers({
    handleClickAddComponent: (props: Object) => () => {
      const component = (
        <EquipmentComponentForm
          closeModal={props.closeModal}
          branchGuid={props.branchGuid}
          openLoader={props.openLoader}
          configs={props.trailerConfigs}
          closeLoader={props.closeLoader}
          requestGuidKey={GC.FIELD_TRAILER_GUID}
          submitAction={props.createTrailerComponentRequest}
          availableSharedComponents={props.availableSharedComponents}
          requestGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:add-equipment-component', 'Add Equipment Component'),
        },
      };
      props.openModal(modal);
    },
    handleEditComponent: (props: Object) => (data: Object) => {
      const initialValues = R.mergeRight(
        data,
        {
          [GC.FIELD_COMPONENT_TYPE]: R.path([GC.FIELD_COMPONENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
          [GC.FIELD_COMPONENT_MANUFACTURER]: R.path(
            [GC.FIELD_COMPONENT_MANUFACTURER, GC.FIELD_DROPDOWN_OPTION_GUID],
            data,
          ),
        },
      );
      const component = (
        <EquipmentComponentForm
          closeModal={props.closeModal}
          initialValues={initialValues}
          branchGuid={props.branchGuid}
          openLoader={props.openLoader}
          configs={props.trailerConfigs}
          closeLoader={props.closeLoader}
          requestGuidKey={GC.FIELD_TRAILER_GUID}
          submitAction={props.updateTrailerComponentRequest}
          availableSharedComponents={props.availableSharedComponents}
          requestGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:edit-equipment-component', 'Edit Equipment Component'),
        },
      };
      props.openModal(modal);
    },
    handleDeleteComponent: (props: Object) => (data: Object) => {
      const component = (
        <ConfirmComponent
          name={R.path([C.FLEET_COMPONENT_TYPE, GC.FIELD_DISPLAYED_VALUE], data)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
      );
      const modal = {
        component,
        options: {
          width: '600px',
          height: '160px',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.closeModal();
                props.deleteTrailerComponentRequest(data.guid);
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
);

const EquipmentComponent = eqComponentEnhance((props: Object) => (
  <FormGroupTable
    zI={1}
    position='relative'
    fields={props.collapsedGroup}
    entities={props.equipComponent}
    entitiesFields={componentColumns}
    handleEditRow={props.handleEditComponent}
    handleDeleteRow={props.handleDeleteComponent}
    groupName={C.GROUP_NAME_EQUIPMENT_COMPONENTS}
    handleAddClick={props.handleClickAddComponent}
    isOpened={R.path(['collapsedGroup', C.GROUP_NAME_EQUIPMENT_COMPONENTS], props)}
    panelTitle={G.getWindowLocale('titles:equipment-component', 'Equipment Component')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(C.GROUP_NAME_EQUIPMENT_COMPONENTS)} />
));

const TrailerEquipmentComponent = (props: Object) => (
  <div>
    <EquipmentServiceReport
      openModal={props.openModal}
      openLoader={props.openLoader}
      closeModal={props.closeModal}
      entityType={GC.FIELD_TRAILER}
      closeLoader={props.closeLoader}
      entityGuidType={GC.FIELD_TRAILER_GUID}
      entityGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
      branchGuid={R.path(['initialValues', GC.BRANCH_GUID], props)}
      isOpened={R.path(['collapsedGroup', C.GROUP_NAME_SERVICES], props)}
      handleToggleFormGroup={() => props.handleToggleFormGroup(C.GROUP_NAME_SERVICES)}
      documentTypeOptions={R.pathOr([], ['trailerConfigs', GC.FIELD_DOCUMENT_DOCUMENT_TYPE], props)}
    />
    <EquipmentComponent {...props} />
  </div>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  equipComponent: makeSelectTrailerEquipComponent(state),
}));

export default connect(mapStateToProps, {
  printTrailer,
  createTrailerComponentRequest,
  updateTrailerComponentRequest,
  deleteTrailerComponentRequest,
})(TrailerEquipmentComponent);
