import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  selectItem,
  setReports,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
} = getReportActions();

const setUsedReportOnly = createAction('setUsedReportOnly');
const setExternalIdFilter = createAction('setExternalIdFilter');
const setGlobalFilterValue = createAction('setGlobalFilterValue');
const downloadEdiFileRequest = createAction('downloadEdiFileRequest');
const receivedEDIListSuccess = createAction('receivedEDIListSuccess');
const setCloLoadFromEDIStatus = createAction('setCloLoadFromEDIStatus');
const cancelLoadFromEDIRequest = createAction('cancelLoadFromEDIRequest');
const acceptLoadFromEDIRequest = createAction('acceptLoadFromEDIRequest');
const acceptLoadFromEDISuccess = createAction('acceptLoadFromEDISuccess');
const declineLoadFromEDIRequest = createAction('declineLoadFromEDIRequest');
const declineLoadFromEDISuccess = createAction('declineLoadFromEDISuccess');

export {
  // report
  setReports,
  selectItem,
  setUsedReport,
  setFilterProps,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  // report
  setUsedReportOnly,
  setExternalIdFilter,
  setGlobalFilterValue,
  downloadEdiFileRequest,
  receivedEDIListSuccess,
  setCloLoadFromEDIStatus,
  cancelLoadFromEDIRequest,
  acceptLoadFromEDIRequest,
  acceptLoadFromEDISuccess,
  declineLoadFromEDIRequest,
  declineLoadFromEDISuccess,
};
