import React from 'react';
// components
import Text from '../../../components/text';
// helpers
import * as G from '../../../helpers';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const TextComponent = () => {
  const description = '<Text /> component allows the flexibility to display styled text for specific actions.';

  const usage = (
    <ul>
      <li>Parameter `<i>title</i>` represents the title of the text.</li>
      <li>Parameter `<i>children</i>` represents the content to be displayed within the text component.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      name='Text'
      usage={usage}
      description={description}
      path='src/components/text'
    >
      <Text
        fontSize={14}
        fontWeight='bold'
        textTransform='uppercase'
        color={G.getTheme('colors.navyBlue')}
        title={G.getWindowLocale('titles:auth:titles:login', 'Log into your Amous International account')}
      >
        {G.getWindowLocale('titles:auth:titles:login', 'Log into your Amous International account')}
      </Text>
    </ComponentStoryWrapper>
  );
};

export default TextComponent;
