import React from 'react';
import {
  Line,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ComposedChart,
} from 'recharts';
// feature dashboards
import { CHART_COLORS } from '../constants';
import {
  xAxisCommonProps,
  yAxisCommonProps,
  cartesianGridCommonProps,
  largeChartWrapperCommonProps,
} from '../settings';
//////////////////////////////////////////////////

const ticks = [0, 20, 40, 60, 80, 100];

const ComposedLineWithAreaChart = (props: Object) => {
  const { data, tickFormatter } = props;

  return (
    <ComposedChart
      data={data}
      {...largeChartWrapperCommonProps}
    >
      <CartesianGrid {...cartesianGridCommonProps} />
      <XAxis
        dataKey='name'
        {...xAxisCommonProps}
      />
      <YAxis
        ticks={ticks}
        tickFormatter={tickFormatter}
        {...yAxisCommonProps}
      />
      <Tooltip />
      <defs>
        <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
          <stop offset='10%' stopColor={CHART_COLORS.blue1} stopOpacity={0.6} />
          <stop offset='90%' stopColor={CHART_COLORS.blue1} stopOpacity={0.1} />
        </linearGradient>
      </defs>
      <Area
        type='linear'
        dataKey='value'
        stroke={false}
        fill='url(#splitColor)'
      />
      <Line
        type='linear'
        dot={{ r: 3 }}
        dataKey='value'
        strokeWidth={2}
        activeDot={{ r: 6 }}
        fill={CHART_COLORS.blue1}
        stroke={CHART_COLORS.blue1}
      />
    </ComposedChart>
  );
};

export default ComposedLineWithAreaChart;
