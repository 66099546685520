import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const terminalDetails = {
  title: G.getWindowLocale('titles:terminal-details', 'Terminal Details'),
  fields: {
    [GC.FIELD_PRIMARY]: {
      width: 100,
      type: 'toggle',
      label: 'titles:primary',
      additionalInputWrapperStyles: { width: 113 },
    },
    [GC.FIELD_ACTIVE]: {
      width: 100,
      type: 'toggle',
      label: 'titles:active',
      additionalInputWrapperStyles: { width: 113 },
    },
    [GC.FIELD_NAME]: {
      isRequired: true,
      label: 'titles:terminal-name',
    },
    [GC.FIELD_LOCATION_TYPE]: {
      isRequired: true,
      type: 'reactSelect',
      label: 'titles:location-type',
      options: GC.TEMPLATES_LOCATION_TYPE,
      reactSelectAdditionalStyles: {
        menu: (baseStyles: Object) => ({
          ...baseStyles,
          zIndex: 11,
        }),
      },
    },
    [GC.FIELD_LOCATION_NAME]: {
      isRequired: true,
      label: 'titles:location-name',
    },
    [GC.FIELD_ADDRESS]: {
      isRequired: true,
      label: 'titles:address',
      type: 'addressAutocomplete',
      addressFieldName: GC.FIELD_ADDRESS,
    },
    [GC.FIELD_ADDRESS_2]: {
      type: 'text',
      label: 'titles:address2',
      addressFieldName: GC.FIELD_ADDRESS,
      additionalInputWrapperStyles: { mr: 546 },
    },
    [GC.FIELD_CITY]: {
      width: 150,
      isRequired: true,
      label: 'titles:city',
    },
    [GC.FIELD_STATE]: {
      width: 150,
      isRequired: true,
      label: 'titles:state',
    },
    [GC.FIELD_ZIP]: {
      width: 150,
      isRequired: true,
      label: 'titles:zip',
    },
    [GC.FIELD_COUNTRY]: {
      width: 150,
      isRequired: true,
      type: 'countrySelect',
      label: 'titles:country',
    },
    [GC.FIELD_LATITUDE]: {
      width: 100,
      disabled: true,
      label: 'titles:latitude',
    },
    [GC.FIELD_LONGITUDE]: {
      width: 100,
      disabled: true,
      label: 'titles:longitude',
      additionalInputWrapperStyles: { mr: 546 },
    },
  },
};

const multiSwitchOptions = [
  {
    width: 40,
    value: true,
    name: G.getWindowLocale('titles:yes', 'Yes'),
  },
  {
    width: 40,
    value: false,
    name: G.getWindowLocale('titles:no', 'No'),
  },
  {
    width: 60,
    value: null,
    name: G.getWindowLocale('titles:default', 'Default'),
  },
];

const processTypeOptions = [
  GC.EMPTY_OPTION_OBJECT,
  { name: G.getWindowLocale('titles:email', 'Email'), value: GC.TERMINAL_PROCESS_TYPE_EMAIL },
  { name: G.getWindowLocale('titles:api', 'API'), value: GC.TERMINAL_PROCESS_TYPE_API },
  { name: G.getWindowLocale('titles:edi', 'EDI'), value: GC.TERMINAL_PROCESS_TYPE_EDI },
  { name: G.getWindowLocale('titles:internal', 'Internal'), value: GC.TERMINAL_PROCESS_TYPE_INTERNAL },
];

const emailAutomationDetails = {
  [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: {
    title: G.getWindowLocale('titles:dispatch-process', 'Dispatch Process'),
    fields: {
      [GC.FIELD_TYPE]: {
        type: 'select',
        shouldCustomChange: true,
        options: processTypeOptions,
        label: ['titles:dispatch-type'],
        infoFunction: ({ values }: Object) => (
          R.pathEq(GC.TERMINAL_PROCESS_TYPE_EDI, [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE], values) ? (
            G.getWindowLocale(
              'messages:add-exporter-config',
              'Please add Carrier Edi Exporter for the Dispatch Process Type EDI',
            )
          ) : null
        ),
        customChangeHandler: (event: Object, _: any, { values, setValues }: Object) => {
          const { dispatchProcess, statusCheckProcess } = values;

          const value = G.getEventTargetValue(event);

          const newValues = {
            ...values,
            [GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS]: R.assoc(GC.FIELD_TYPE, value, dispatchProcess),
            [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]: R.mergeRight(
              statusCheckProcess,
              {
                [GC.FIELD_TYPE]: value,
                [GC.AUTO_STATUS_CHECK_ENABLED]: G.ifElse(
                  R.equals(value, GC.TERMINAL_PROCESS_TYPE_API),
                  true,
                  R.prop(GC.AUTO_STATUS_CHECK_ENABLED, statusCheckProcess),
                ),
              },
            ),
          };

          setValues(newValues);
        },
      },
      [GC.FIELD_INTEGRATION_CONFIG_GUID]: {
        type: 'reactSelect',
        shouldCustomChange: true,
        options: 'dispatchConfigOptions',
        label: 'titles:carrier-integration',
        customChangeHandler: (value: Object, _: any, props: Object) => {
          const { setFieldValue, statusCheckDispatchConfigOptions } = props;

          const supportStatusCheck = R.compose(
            R.isNotNil,
            R.find(R.propEq(value, GC.FIELD_VALUE)),
          )(statusCheckDispatchConfigOptions);

          setFieldValue(`${GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS}.${GC.FIELD_INTEGRATION_CONFIG_GUID}`, value);

          if (R.or(R.isNil(value, supportStatusCheck))) {
            setFieldValue(
              `${GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.${GC.FIELD_INTEGRATION_CONFIG_GUID}`,
              value,
            );
          }
        },
      },
      [GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE]: {
        type: 'reactSelect',
        options: 'sequenceOptions',
        label: 'titles:tracking-sequence',
      },
      [GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_REF_TYPE_GUID]: {
        type: 'reactSelect',
        options: 'referenceTypeOptions',
        label: 'titles:tracking-custom-field-type',
      },
      [GC.FIELD_TERMINAL_PROCESS_AUTO_ACCEPT]: {
        type: 'multiswitch',
        label: 'titles:auto-accept',
        options: multiSwitchOptions,
        additionalInputWrapperStyles: {
          ml: 50,
          width: 200,
          visibility: R.ifElse(
            R.pathEq(
              GC.TERMINAL_PROCESS_TYPE_API,
              ['values', GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE],
            ),
            R.always('hidden'),
            R.always('visible'),
          ),
        },
      },
      [GC.FIELD_EMAIL_TO]: {
        type: 'multiEmail',
        label: 'titles:email-to',
      },
      [GC.FIELD_MESSAGE_SUBJECT]: {
        label: 'titles:subject',
      },
      [GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID]: {
        type: 'reactSelect',
        options: 'documentTemplates',
        label: 'titles:document-template',
        additionalInputWrapperStyles: {
          mr: 273,
          visibility: R.ifElse(
            R.pathEq(
              GC.TERMINAL_PROCESS_TYPE_EMAIL,
              ['values', GC.FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS, GC.FIELD_TYPE],
            ),
            R.always('visible'),
            R.always('hidden'),
          ),
        },
      },
      [GC.FIELD_MESSAGE]: {
        type: 'textarea',
        label: 'titles:message',
        additionalInputWrapperStyles: { width: '100%' },
      },
    },
  },
  [GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS]: {
    title: G.getWindowLocale('titles:bid-process', 'Bid Process'),
    fields: {
      [GC.FIELD_TYPE]: {
        type: 'select',
        shouldCustomChange: true,
        label: 'titles:communication-type',
        options: [
          GC.EMPTY_OPTION_OBJECT,
          { value: GC.TERMINAL_PROCESS_TYPE_EMAIL, label: G.getWindowLocale('titles:email', 'Email') },
        ],
        customChangeHandler: (event: Object, _: any, { values, setValues }: Object) => {
          const { quoteProcess, statusCheckProcess } = values;

          const value = G.getEventTargetValue(event);

          const newValues = {
            ...values,
            [GC.FIELD_CARRIER_TERMINAL_QUOTE_PROCESS]: R.assoc(GC.FIELD_TYPE, value, quoteProcess),
            [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]: R.assoc(GC.FIELD_TYPE, value, statusCheckProcess),
          };

          setValues(newValues);
        },
      },
      [GC.FIELD_CARRIER_EMAIL_GROUP_GUIDS]: {
        isMulti: true,
        type: 'reactSelect',
        label: 'titles:email-group',
        options: GC.CARRIER_EMAIL_GROUP,
      },
      [GC.FIELD_EMAIL_TO]: {
        type: 'multiEmail',
        options: 'emailGroup',
        label: 'titles:email-to',
      },
      [GC.FIELD_MESSAGE_SUBJECT]: {
        label: 'titles:subject',
      },
      [GC.FIELD_MESSAGE]: {
        type: 'textarea',
        label: 'titles:message',
        additionalInputWrapperStyles: { width: '100%' },
      },
    },
  },
  [GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS]: {
    title: G.getWindowLocale('titles:status-check-process', 'Status Check Process'),
    fields: {
      [GC.FIELD_ENABLED]: {
        type: 'multiswitch',
        options: multiSwitchOptions,
        label: 'titles:load-check-process-enabled',
        additionalInputWrapperStyles: { width: 'max-content' },
      },
      [GC.AUTO_STATUS_CHECK_ENABLED]: {
        type: 'multiswitch',
        options: multiSwitchOptions,
        label: 'titles:auto-status-check-enabled',
        additionalInputWrapperStyles: { width: 'max-content' },
      },
      [GC.FIELD_TERMINAL_SMS_NOTIFICATION_ENABLED]: {
        type: 'multiswitch',
        options: multiSwitchOptions,
        label: 'titles:sms-notification',
        additionalInputWrapperStyles: { width: 'max-content' },
      },
      [GC.FIELD_TERMINAL_STATUS_CHECK_FREQUENCY]: {
        label: 'titles:status-check-frequency',
      },
      [GC.FIELD_TYPE]: {
        type: 'select',
        label: 'titles:communication-type',
        options: R.take(3, processTypeOptions),
      },
      [GC.FIELD_TYPE]: {
        type: 'select',
        shouldCustomChange: true,
        label: 'titles:communication-type',
        options: R.take(3, processTypeOptions),
        customChangeHandler: (event: Object, _: any, { setFieldValue }: Object) => {
          const value = G.getEventTargetValue(event);

          setFieldValue(`${GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.${GC.FIELD_TYPE}`, value);

          if (R.equals(value, GC.TERMINAL_PROCESS_TYPE_API)) {
            setFieldValue(`${GC.FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS}.${GC.AUTO_STATUS_CHECK_ENABLED}`, true);
          }
        },
      },
      [GC.FIELD_INTEGRATION_CONFIG_GUID]: {
        type: 'reactSelect',
        useMenuPortalTarget: true,
        label: 'titles:carrier-integration',
        options: 'statusCheckDispatchConfigOptions',
        closeMenuOnScroll: R.pathEq('terminal_form', ['target', 'id']),
      },
      [GC.FIELD_EMAIL_TO]: {
        type: 'multiEmail',
        label: 'titles:email-to',
      },
      [GC.FIELD_MESSAGE_SUBJECT]: {
        label: 'titles:subject',
        additionalInputWrapperStyles: { mr: 546 },
      },
      [GC.FIELD_MESSAGE]: {
        type: 'textarea',
        label: 'titles:message',
        additionalInputWrapperStyles: { width: '100%' },
      },
    },
  },
};

export {
  terminalDetails,
  emailAutomationDetails,
};
