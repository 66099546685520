import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../components/form-buttons';
import { FormGroupTable } from '../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Form, FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
// feature carrier
import * as LC from '../constants';
import ContractBillTo from './contract-bill-to';
import { CustomTitle, customIfElse } from '../helpers';
import { ContractDiscounts } from './contract-discounts';
import { CarrierAssessorials } from './carrier-assessorial';
import { carrierRateEnhance, enhanceAwardedLine } from '../hocs';
import { contractAwardedLineSettings, carrierContractRateSettings } from '../settings/column-settings';
import {
  carrierEditCarrierContractSection,
  getUpdateCarrierContractValidationSchema,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const getCarrierRateEntities = (carrierRates: Object, indexedGeoFencingZoneList: Object) => R.map(
  (item: Object) => R.mergeRight(
    item,
    {
      [GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID]: R.path(
        [R.prop(GC.FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID, item)],
        indexedGeoFencingZoneList,
      ),
      [GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID]: R.path(
        [R.prop(GC.FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID, item)],
        indexedGeoFencingZoneList,
      ),
    },
  ),
  R.or(carrierRates, []),
);

const makeContractRatesTitle = (totalCount: number) => {
  const text = G.getWindowLocale('titles:contract-rates', 'Contract Rates');
  if (G.isZero(totalCount)) return text;

  return `${text} (${totalCount})`;
};

const CarrierRate = carrierRateEnhance((props: Object) => (
  <FormGroupTable
    selectable={true}
    withPagination={true}
    tableColumnWidth={100}
    fields={props.collapsedGroup}
    handleEditRow={props.handleEditCarrierRate}
    handleAddClick={props.handleAddCarrierRate}
    entitiesFields={carrierContractRateSettings}
    groupName={LC.CARRIER_GROUP_NAME_CARRIER_RATE}
    handleDeleteRow={props.handleDeleteCarrierRate}
    totalCount={props.carrierContractRate.totalCount}
    pagination={props.carrierContractRate.pagination}
    setQuickFilterParams={props.setQuickFilterParams}
    handleSelectEntity={props.selectCarrierContractRate}
    handleLoadMoreEntities={props.handleLoadMoreEntities}
    getQuickFilteredListRequest={props.getQuickFilteredListRequest}
    entities={getCarrierRateEntities(props.carrierContractRate.list, props.indexedGeoFencingZoneList)}
    customPanel={(params: Object) => (
      <CustomTitle
        {...params}
        withFilter={true}
        ratePriceImport={true}
        ratePriceExport={true}
        shouldSubmitFilterForm={true}
        handleSetQuickFilter={props.handleSetQuickFilter}
        handleOpenRatePriceImport={props.handleOpenRatePriceImport}
        contractGuid={R.path(['carrierContract', GC.FIELD_GUID], props)}
        getCarrierContractRatesRequest={props.getCarrierContractRatesRequest}
        handleRemoveCarrierContractRates={props.handleRemoveCarrierContractRates}
        createCarrierRatePriceExportRequest={props.createCarrierRatePriceExportRequest}
        title={makeContractRatesTitle(R.pathOr(0, ['carrierContractRate', 'totalCount'], props))}
      />
    )}
  />
));

const AwardedLine = enhanceAwardedLine({
  contractType: 'carrier',
})((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={R.or(props.awardedLines, [])}
    handleEditRow={props.handleEditAwardedLine}
    handleAddClick={props.handleAddAwardedLine}
    entitiesFields={contractAwardedLineSettings}
    handleDeleteRow={props.handleDeleteAwardedLine}
    groupName={LC.CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE}
    panelTitle={G.getWindowLocale('titles:contract-awarded-lines', 'Contract Awarded Lines')}
    isOpened={R.path([LC.CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_CONTRACT_GROUP_NAME_AWARDED_LINE)}
  />
));

const getMapPropsToValues = ({ carrierContract }: Object) => {
  const uplift = R.pathOr(null, [LC.FIELD_CARRIER_CONTRACT_UPLIFT], carrierContract);
  const transportationMode = R.pathOr('', [GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], carrierContract);
  let values = R.assoc(GC.FIELD_MODE, transportationMode, carrierContract);
  if (R.isNil(uplift)) {
    values = R.assoc(
      LC.FIELD_CARRIER_CONTRACT_UPLIFT,
      { [LC.FIELD_CARRIER_CONTRACT_UPLIFT_RATE_UNIT]: null },
      values,
    );
  }

  return values;
};

const enhance = compose(
  withFormik({
    mapPropsToValues: getMapPropsToValues,
    validationSchema: getUpdateCarrierContractValidationSchema,
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => {
      let reqBody = values;

      const discount = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT], reqBody);
      const discountType = R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], values);

      if (R.equals(discountType, GC.DISCOUNT_TYPE_FLAT)) {
        reqBody = R.assoc(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT],
          R.omit([LC.FIELD_VARIABLE_TYPE, LC.FIELD_VARIABLE_UNIT, LC.FIELD_VARIABLE_VALUES], discount),
          reqBody,
        );
      }

      if (R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE)) {
        reqBody = R.assoc(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT],
          R.compose(
            R.omit([GC.FIELD_RATE, GC.FIELD_RATE_UNIT]),
            R.assoc([LC.FIELD_VARIABLE_VALUES], props.contractVariableValues),
          )(discount),
          reqBody,
        );
      }

      if (R.equals(discountType, '')) {
        reqBody = R.assocPath(
          [LC.FIELD_CARRIER_CONTRACT_DISCOUNT],
          null,
          reqBody,
        );
      }

      if (G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_INTEGRATION_CONFIG_GUID, reqBody))) {
        reqBody = R.assoc(GC.FIELD_INTEGRATION_CONFIG_GUID, null, reqBody);
      }

      props.updateCarrierContractRequest(reqBody);
    },
    displayName: LC.CARRIER_CONTRACT_PAGE,
  }),
  withHandlers({
    handleContractClickCancel: (props: Object) => () => {
      props.setActiveTab({
        tabName: 'carrierFinance',
        tabAction: () => {
          props.cleanQuickFilter();
          props.getCarrierContractSuccess(null);
          props.resetCarrierContractListAndPagination();
          props.getCarrierContractAssessorialsSuccess([]);
          props.getCarrierContractAwardedLinesSuccess([]);
        },
      });
    },
  }),
  pure,
);

const CarrierContractTab = (props: Object) => (
  <Form zIndex={9} onSubmit={props.handleSubmit}>
    <Box
      pb={10}
      height={customIfElse(props, '32px', 'auto')}
      overflow={customIfElse(props, 'hidden', 'visible')}
      background='linear-gradient(to right, #f3f3f3 300px, #fff 0)'
    >
      <FormGroupTitleComponent
        withArrowDown={true}
        isOpened={props.collapsedGroup[props.groupName]}
        text={G.getWindowLocale(carrierEditCarrierContractSection.title)}
        onToggleFormGroup={() => props.handleToggleFormGroup(props.groupName)}
      />
      <FieldsetComponent
        {...props}
        fieldsetWidth={530}
        flexDirection='column'
        fields={carrierEditCarrierContractSection.fields}
        optionsForSelect={{
          roleOptions: props.roleOptions,
          carrierIntegrationOptions: props.carrierIntegrationOptions,
          transportationMode: R.concat(
            R.of(Array, { label: '', value: '' }),
            R.filter(
              ({ value }: Object) => (props.selectedTrMode.includes(value)),
              props.carrierConfigs.transportationMode),
          ),
        }}
      />
    </Box>
    {
      R.equals(
        R.pathOr('', [LC.FIELD_CARRIER_CONTRACT_DISCOUNT, LC.FIELD_DISCOUNT_TYPE], props.values),
        'VARIABLE',
      )
      && <ContractDiscounts {...props} />
    }
    <ContractBillTo
      type='carrier'
      openModal={props.openModal}
      closeModal={props.closeModal}
      handleToggleFormGroup={props.handleToggleFormGroup}
      branchGuid={R.path(['formValues', GC.BRANCH_GUID], props)}
      contractGuid={R.pathOr('', ['carrierContract', GC.FIELD_GUID], props)}
      locationType={R.pathOr([], ['carrierConfigs', GC.FIELD_LOCATION_TYPE], props)}
    />
    <CarrierAssessorials
      {...props}
      relatedEntityField='contractGuid'
      entities={props.contractAssessorials}
      groupName={LC.CARRIER_CONTRACT_GROUP_NAME_ASSESSORIALS}
      deleteAction={props.deleteCarrierContractAssessorialRequest}
      updateAction={props.updateCarrierContractAssessorialRequest}
      createAction={props.createCarrierContractAssessorialRequest}
      relatedGuid={R.pathOr('', ['carrierContract', 'guid'], props)}
      addSharedAccessorialsEndpoint='addCarrierContractSharedAccessorials'
      removeSharedAccessorialsEndpoint='removeCarrierContractSharedAccessorials'
      successSharedAccessorialsAction={props.getCarrierContractAssessorialsRequest}
      panelTitle={G.getWindowLocale('titles:contract-accessorials', 'Contract Accessorials')}
    />
    <AwardedLine {...props} />
    {
      G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_INTEGRATION_CONFIG_GUID], props)) &&
      <CarrierRate {...props} />
    }
    <FormButtons
      {...props}
      width='auto'
      handleClickCancel={props.handleContractClickCancel}
    />
  </Form>
);

export default enhance(CarrierContractTab);
