import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { PageTitle } from '../../components/page-title';
import { QuickFilterOutsideControl } from '../../components/quick-filter';
// features
import { makeSelectIconsSearch } from './selectors';
// icons
import * as I from '../../svgs';
// ui
import { Box, FlexHovered, Grid, Text, PageWrapper, PageHeaderWrapper } from '../../ui';
// utilities
import * as G from '../../helpers';
// feature icons
import { setSearchFilter } from './actions';
//////////////////////////////////////////////////

const IconsGrid = ({ searchFilter }: Object) => {
  const iconsList = Object.keys(I).filter((iconName: string) =>
    G.notEquals(R.indexOf(searchFilter, iconName), -1));

  const color = G.getTheme('icons.iconColor');

  const copyToClipboard = async (iconName: string) => {
    try {
      // eslint-disable-next-line
      await navigator.clipboard.writeText(iconName);
      console.log(`Successfully copied icon '${iconName}'`);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const IconsListMapped = iconsList.map((iconName: Function, index: number) => {
    const icon = I[iconName];

    return (
      <FlexHovered
        key={index}
        width='200px'
        height='60px'
        cursor='copy'
        flexDirection='column'
        justifyContent='center'
        onClick={() => copyToClipboard(iconName)}
        bgColor={G.getTheme('colors.light.lightGrey')}
        title={G.getWindowLocale('icons:copy', 'Click to copy')}
      >
        <Box>{icon(color, 24, 24, 'transparent')}</Box>
        <Text
          mt='10px'
          fontSize='12px'
          fontWeight='bold'
          color={G.getTheme('colors.darkGrey')}
        >
          {iconName}
        </Text>
      </FlexHovered>
    );
  });

  return IconsListMapped;
};

export const IconsComponent = ({
  searchFilter,
  setSearchFilter,
}: Object) => {
  const searchInputColor = G.getTheme('colors.dark.grey');

  return (
    <PageWrapper bgColor={G.getTheme('forms.bgColor')}>
      <PageHeaderWrapper aI='center'>
        <PageTitle title={G.getWindowLocale('titles:icons', 'Icons')} />
        <QuickFilterOutsideControl
          noBlur={true}
          delayTime={500}
          withoutLabel={true}
          outsideValue={searchFilter}
          handleSetFilter={setSearchFilter}
          placeholder={G.getWindowLocale('titles:search', 'Search')}
          endLabel={<Box mr='8px'>{I.search(searchInputColor)}</Box>}
          inputProps={{ p: '0 0 0 8px', height: '28px', withoutBorder: true }}
          boxStyles={{
            mr: 20,
            right: 0,
            border: '1px solid',
            borderRadius: '2px',
            position: 'absolute',
            borderColor: searchInputColor,
            bg: G.getTheme('colors.light.mainLight'),
          }}
        />
      </PageHeaderWrapper>
      <Grid
        p='5px'
        mt='20px'
        gridGap='10px'
        gridTemplateRows='60px'
        gridTemplateColumns={'repeat(auto-fit, minmax(200px, 1fr))'}
      >
        <IconsGrid searchFilter={searchFilter} />
      </Grid>
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  searchFilter: makeSelectIconsSearch(state),
}));

export default connect(mapStateToProps, {
  setSearchFilter,
})(IconsComponent);
