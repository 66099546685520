import React from 'react';
import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
import { getLBDisplayValue } from '../../load-board/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  accountingOptions,
  encodingTypeOptions,
  availableEdiExportMappers,
  documentTypeMappingOptions,
  statusCodeMappingTypeOptions,
  customerIntegrationConfigTypes,
  factoringIntegrationTypeOptions,
  availableEdiLoaderExportMappers,
  getCarrierRateIntegrationOptions,
  crossBorderIntegrationTypeOptions,
  statusReasonCodeMappingTypeOptions,
  getEDIConfigLoadIdentificationTypes,
  statusCodeOutboundMappingTypeOptions,
  getEDIConfigSenderIdentificationTypes,
  serviceTypeMappingIntegrationTypeOptions,
  getCarrierEdiExporterTransactionTypeOptions,
  getCustomerEdiExporterTransactionTypeOptions,
  getCustomerEdiExporterReceiverQualifierTypeOptions,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// feature configs
import LinkToLosComponent from '../components/link-to-los';
import { externalTransportationModeOptionsMap } from '../constants';
import { ReactSelectComponent, MultiselectFieldComponent } from '../ui';
import {
  paymentTermsMap,
  labelTypeKeysMap,
  equipmentOptionsMap,
  TIME_ZONE_TYPES_MAP,
  truckTypeOptionsMap,
  statusCodeOptionsMap,
  serviceTypeOptionsMap,
  trailerTypeOptionsMap,
  EXTERNAL_DOCUMENT_TYPE_MAP,
  integrationTypeToServiceCodeMap,
  CUSTOMER_EDI_PICKUP_DROP_STOP_TYPES_MAP,
} from '../config-pages/integration/constants';
//////////////////////////////////////////////////

const getDisplayedValueFromConfigOptions = (field: Object, entity: Object, props: Object) => {
  const { options = [], optionsName, nameForAttribute } = field;

  const value = G.isArray(nameForAttribute) ?
    R.path(nameForAttribute, entity) : G.getPropFromObject(nameForAttribute, entity);

  const optionsToUse = R.pathOr(options, ['configOptions', optionsName], props);

  if (G.isAnyNilOrEmpty([value, optionsToUse])) return '';

  if (G.isArray(value)) {
    return G.createStringFromArrayWithProp(
      R.map((item: string) => R.find(R.propEq(item, GC.FIELD_VALUE), optionsToUse), value),
      ', ',
      GC.FIELD_LABEL,
    );
  }

  const option = R.find(R.propEq(value, GC.FIELD_VALUE), optionsToUse);

  return R.or(G.getPropFromObject(GC.FIELD_LABEL, option), G.getPropFromObject(GC.FIELD_NAME, option));
};

const carrierIntegrationFields = [
  {
    name: 'titles:integration-type',
    nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
    options: getCarrierRateIntegrationOptions(),
    customLogic: getDisplayedValueFromConfigOptions,
  },
  {
    name: 'titles:name',
    nameForAttribute: GC.FIELD_NAME,
  },
  {
    name: 'titles:active',
    nameForAttribute: GC.FIELD_ENABLED,
    customLogic: (field: Object, entity: Object) => G.ifElse(
      R.prop(GC.FIELD_ENABLED, entity),
      I.uiTrue,
      I.uiFalse,
    )(),
  },
  {
    name: 'titles:user-name',
    tableColumnWith: '440px',
    nameForAttribute: GC.FIELD_USERNAME_CAMEL_CASE,
  },
  {
    name: 'titles:payment-term',
    nameForAttribute: GC.FIELD_PAYMENT_TERM,
    customLogic: (_: any, { paymentTerm }: Object) => G.getPropFromObject(
      paymentTerm,
      paymentTermsMap,
    ),
  },
  {
    name: 'titles:label-type',
    nameForAttribute: GC.FIELD_LABEL_TYPE,
    customLogic: (_: any, { labelType }: Object) => G.getPropFromObject(labelType, labelTypeKeysMap),
  },
  {
    name: 'titles:label-document-type',
    optionsName: GC.COMMUNICATION_DOCUMENT_TYPE,
    customLogic: getDisplayedValueFromConfigOptions,
    nameForAttribute: GC.FIELD_LABEL_DOCUMENT_TYPE_GUID,
  },
  {
    name: 'titles:bol-document-type',
    optionsName: GC.COMMUNICATION_DOCUMENT_TYPE,
    customLogic: getDisplayedValueFromConfigOptions,
    nameForAttribute: GC.FIELD_BOL_DOCUMENT_TYPE_GUID,
  },
  {
    name: 'titles:bol-payment-term',
    nameForAttribute: GC.FIELD_BOL_PAYMENT_TERM,
    customLogic: (_: any, { bolPaymentTerm }: Object) => G.getPropFromObject(
      bolPaymentTerm,
      paymentTermsMap,
    ),
  },
  {
    name: 'titles:carrier-pickup-requester',
    nameForAttribute: GC.FIELD_PICKUP_REQUESTER_GUID,
    customLogic: (_: any, { pickupRequesterGuid }: Object, props: Object) => R.pathOr(
      '',
      ['configOptions', 'availableCarrierPickupRequesterList', pickupRequesterGuid, GC.FIELD_NAME],
      props,
    ),
  },
  {
    options: 'carrierSequences',
    optionsName: 'carrierSequences',
    name: 'titles:tracking-sequence',
    customLogic: getDisplayedValueFromConfigOptions,
    nameForAttribute: GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
  },
  {
    name: 'titles:account-country',
    nameForAttribute: GC.FIELD_ACCOUNT_COUNTRY,
  },
];

const carrierIntegrationCustomerField = {
  name: 'titles:customer',
  optionsName: 'customerBranchList',
  nameForAttribute: GC.CUSTOMER_BRANCH_GUID,
  customLogic: (field: Object, entity: Object, props: Object) => R.compose(
    R.pathOr('', [GC.FIELD_NAME]),
    R.find(R.propEq(G.getPropFromObject(GC.CUSTOMER_BRANCH_GUID, entity), GC.FIELD_GUID)),
    R.pathOr([], ['configOptions', 'customerBranchList']),
  )(props),
};

const carrierIntegrationCustomerFields = R.compose(
  R.insert(1, carrierIntegrationCustomerField),
  R.values,
  R.omit([GC.FIELD_ENABLED, GC.FIELD_BOL_DOCUMENT_TYPE_GUID, GC.FIELD_LABEL_DOCUMENT_TYPE_GUID]),
  R.indexBy(R.prop('nameForAttribute')),
)(carrierIntegrationFields);

const INTEGRATION_FINANCIAL = [
  {
    configType: 'custom',
    title: 'titles:advance-payment',
    configName: GC.CUSTOM_ADVANCE_PAYMENT_CONFIG,
    customConfigName: GC.CUSTOM_ADVANCE_PAYMENT_CONFIG,
    fields: [
      {
        name: 'titles:account-code',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_ACCOUNT_CODE,
      },
      {
        name: 'titles:customer-id',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_CUSTOMER_ID,
      },
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_INTEGRATION_TYPE,
      },
      {
        name: 'titles:fees',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_PLUS_LESS_FEES,
      },
      {
        name: 'titles:wss-sign-on-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_NAME,
      },
      {
        name: 'titles:sign-on-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_NAME,
      },
      {
        name: 'titles:security-info',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SECURITY_INFO,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:factoring-integration-config',
    configName: GC.CUSTOM_FACTORING_INTEGRATION_CONFIG,
    customConfigName: GC.CUSTOM_FACTORING_INTEGRATION_CONFIG,
    fields: [
      {
        name: 'titles:factoring-type',
        nameForAttribute: GC.FIELD_FACTORING_TYPE,
        customLogic: (_: any, { factoringType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(factoringType, GC.FIELD_VALUE)),
        )(factoringIntegrationTypeOptions),
      },
      {
        name: 'titles:enabled',
        nameForAttribute: GC.FIELD_ENABLED,
      },
      {
        name: 'titles:enabled-sending',
        nameForAttribute: GC.FIELD_ENABLED_SENDING,
      },
      {
        name: 'titles:clo-reference',
        optionsName: 'cloReferenceTypes',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_CLO_REFERENCE_TYPE_GUID,
      },
      {
        name: 'titles:invoice-references',
        optionsName: 'customerInvoiceReferenceTypes',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_INVOICE_REFERENCE_TYPE_GUIDS,
      },
      {
        name: 'titles:export-as',
        nameForAttribute: GC.FIELD_EXPORT_AS,
        customLogic: (_: any, { exportAs, factoringType }: Object) => {
          if (R.and(G.isNotNilAndNotEmpty(exportAs), R.equals(factoringType, GC.FACTORING_COMPANY_TYPE_TRIUMPH))) {
            return G.getEnumLocale(exportAs);
          }

          return null;
        },
      },
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_USERNAME,
      },
      {
        name: 'titles:client-name',
        nameForAttribute: GC.FIELD_CLIENT_NAME,
      },
      {
        name: 'titles:customer-name-type',
        nameForAttribute: GC.FIELD_CUSTOMER_NAME_TYPE,
        customLogic: (_: any, { customerNameType }: Object) => G.getEnumLocale(customerNameType),
      },
      {
        name: 'titles:customer-code',
        nameForAttribute: GC.FIELD_CUSTOMER_CODE,
      },
    ],
  },
];

const makePickupOrDropStopTypesString = (stopTypes: Array) => R.compose(
  R.join(', '),
  R.values,
  R.mapObjIndexed((description: string, code: string) => `${description} ${code}`),
  R.pick(stopTypes),
)(CUSTOMER_EDI_PICKUP_DROP_STOP_TYPES_MAP);

const INTEGRATION_CUSTOMER = [
  {
    title: 'titles:customer-integration',
    fields: {
      [GC.INTEGRATION_CUSTOMER_INTEGRATION_STATUS_MESSAGE_ENABLED]: {
        type: 'switcher',
        name: 'titles:enabled',
        nameForAttribute: GC.INTEGRATION_CUSTOMER_INTEGRATION_STATUS_MESSAGE_ENABLED,
      },
      [GC.INTEGRATION_CUSTOMER_INTEGRATION_INTEGRATION_CONFIG]: {
        type: 'select',
        options: 'integrationConfigs',
        name: 'titles:integration-config',
        nameForAttribute: GC.INTEGRATION_CUSTOMER_INTEGRATION_INTEGRATION_CONFIG,
      },
    },
  },
  {
    showRemove: true,
    configType: 'custom',
    title: 'titles:customer-integration-configs',
    configName: GC.CUSTOM_INTEGRATION_CONFIGS_CONFIG,
    customConfigName: GC.CUSTOM_INTEGRATION_CONFIGS_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE,
        customLogic: (field: Object, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(customerIntegrationConfigTypes),
      },
      {
        name: 'titles:active',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_ENABLED,
      },
      {
        name: 'titles:config-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_NAME,
      },
      {
        name: 'titles:login',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_ID,
      },
      {
        name: 'titles:shipper-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_SHIPPER,
      },
      {
        name: 'titles:carrier-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_NAME,
      },
      {
        name: 'titles:carrier-code',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_CODE,
      },
      {
        name: 'titles:carrier-dot-number',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_DOT_NUMBER,
      },
      {
        name: 'titles:clo-reference',
        optionsName: 'cloReferenceTypes',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_LOAD_NUMBER_REFERENCE_GUID,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    hideTitleActionsForNotSuperAdmin: true,
    configName: GC.CUSTOM_PUBLIC_CLO_CONFIG,
    title: 'titles:public-api-order-config',
    customConfigName: GC.CUSTOM_PUBLIC_CLO_CONFIG,
    fields: [
      {
        name: 'titles:login-id',
        nameForAttribute: GC.FIELD_PUBLIC_CLO_USER_LOGIN_ID,
      },
      {
        optionsName: 'cloReferenceTypes',
        name: 'titles:shipment-number-reference',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_REFERENCE,
      },
      {
        name: 'titles:shipment-primary-number',
        nameForAttribute: GC.FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_PRIMARY,
      },
      {
        name: 'titles:auto-create-order',
        nameForAttribute: GC.FIELD_PUBLIC_API_AUTO_CREATE_ORDER,
      },
      {
        name: 'titles:enabled',
        nameForAttribute: GC.FIELD_ENABLED,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:edi-loader-config',
    configName: GC.CUSTOM_EDI_CONFIG,
    hideTitleActionsForNotSuperAdmin: true,
    customConfigName: GC.CUSTOM_EDI_CONFIG,
    fields: [
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object, props: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_BRANCH_GUID, entity)),
        ),
      },
      {
        name: 'titles:customer',
        nameForAttribute: GC.FIELD_CUSTOMER_GUID,
        customLogic: (_: any, entity: Object, props: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_CUSTOMER_GUID, entity)),
        ),
      },
      {
        name: 'titles:folderName',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
      },
      {
        name: 'titles:sender-identification',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE,
      },
      {
        name: 'titles:sender-identification-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE,
      },
      {
        name: 'titles:pickup-stop-types',
        nameForAttribute: GC.FIELD_PICKUP_STOP_TYPES,
        customLogic: (_: any, { pickupStopTypes }: Object) => {
          if (G.isNilOrEmpty(pickupStopTypes)) return null;

          return makePickupOrDropStopTypesString(pickupStopTypes);
        },
      },
      {
        name: 'titles:drop-stop-types',
        nameForAttribute: GC.FIELD_DROP_STOP_TYPES,
        customLogic: (_: any, { dropStopTypes }: Object) => {
          if (G.isNilOrEmpty(dropStopTypes)) return null;

          return makePickupOrDropStopTypesString(dropStopTypes);
        },
      },
      {
        name: 'titles:defaultItemId',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_DEFAULT_ITEM_ID,
      },
      {
        name: 'titles:primary-number',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_NUMBER,
      },
      {
        optionsName: 'cloReferenceTypes',
        name: 'titles:shipment-number-reference',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_REFERENCE,
      },
      {
        optionsName: 'cloReferenceTypes',
        name: 'titles:po-number-reference',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PO_NUMBER_REFERENCE,
      },
      {
        optionsName: 'cloReferenceTypes',
        name: 'titles:orderIdentificationReference',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_ORDER_IDENTIFICATION_REFERENCE,
      },
      {
        name: 'titles:auto-update-event-dates',
        nameForAttribute: GC.FIELD_AUTO_UPDATE_EVENT_DATES,
      },
      {
        name: 'titles:useItemTemplates',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_ITEM_TEMPLATES,
      },
      {
        name: 'titles:useLocationTemplates',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_LOCATION_TEMPLATES,
      },
      {
        name: 'titles:overrideBillTo',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_OVERRIDE_CONFIGURED_BILL_TO,
      },
      {
        name: 'titles:lastTwoNumbersArePennies',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES,
      },
      {
        name: 'titles:auto-accept',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_AUTO_ACCEPT,
      },
      {
        name: 'titles:send-response',
        nameForAttribute: GC.FIELD_SEND_RESPONSE,
      },
      {
        name: 'titles:auto-create-order',
        nameForAttribute: GC.FIELD_AUTO_CREATE_ORDER,
      },
      {
        name: 'titles:useShipmentNumberAsPickupNumber',
        nameForAttribute: GC.FIELD_USE_SHIPMENT_NUMBER_AS_PICKUP_NUMBER,
      },
      {
        name: 'titles:custom-mapper',
        nameForAttribute: GC.FIELD_CUSTOM_MAPPER,
        customLogic: getDisplayedValueFromConfigOptions,
        options: R.concat(
          availableEdiExportMappers,
          availableEdiLoaderExportMappers,
        ),
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:edi-exporter-config',
    hideTitleActionsForNotSuperAdmin: true,
    configName: GC.CUSTOM_EDI_EXPORTER_CONFIG,
    customConfigName: GC.CUSTOM_EDI_EXPORTER_CONFIG,
    fields: [
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object, props: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_BRANCH_GUID, entity)),
        ),
      },
      {
        name: 'titles:customer',
        nameForAttribute: GC.FIELD_CUSTOMER_GUID,
        customLogic: (_: any, entity: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_CUSTOMER_GUID, entity)),
        ),
      },
      {
        name: 'titles:folderName',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME,
      },
      {
        name: 'titles:transaction-types',
        nameForAttribute: GC.FIELD_TRANSACTION_TYPES,
        customLogic: (_: any, { transactionTypes }: Object) => R.compose(
          R.join(', '),
          R.map((item: Object) => R.compose(
            R.path([GC.FIELD_LABEL]),
            R.find(R.propEq(item, GC.FIELD_VALUE)),
          )(getCustomerEdiExporterTransactionTypeOptions())),
        )(R.or(transactionTypes, [])),
      },
      {
        name: 'titles:protocol',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
      },
      {
        name: 'titles:remote-host',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
      },
      {
        name: 'titles:remote-port',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT,
      },
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
      },
      {
        name: 'titles:sender-id',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID,
      },
      {
        name: 'titles:sender-qualifier',
        nameForAttribute: GC.FIELD_EDI_SENDER_QUALIFIER,
        customLogic: (_: any, { senderQualifier }: Object) => R.compose(
          R.path([GC.FIELD_LABEL]),
          R.find(R.propEq(senderQualifier, GC.FIELD_VALUE)),
        )(getCustomerEdiExporterReceiverQualifierTypeOptions()),
      },
      {
        name: 'titles:receiverId',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID,
      },
      {
        name: 'titles:receiver-qualifier',
        nameForAttribute: GC.FIELD_RECEIVER_QUALIFIER,
        customLogic: (_: any, { receiverQualifier }: Object) => R.compose(
          R.path([GC.FIELD_LABEL]),
          R.find(R.propEq(receiverQualifier, GC.FIELD_VALUE)),
        )(getCustomerEdiExporterReceiverQualifierTypeOptions()),
      },
      {
        name: 'titles:content-format',
        nameForAttribute: GC.FIELD_CONTENT_FORMAT,
        customLogic: (_: any, { contentFormat }: Object) => G.getEnumLocale(contentFormat),
      },
      {
        optionsName: 'cloReferenceTypes',
        name: 'titles:shipment-number-reference',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PRIMARY_REFERENCE,
      },
      {
        optionsName: 'cloReferenceTypes',
        name: 'titles:status-message-event-references',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_EVENT_REFERENCES,
      },
      {
        optionsName: 'cloReferenceTypes',
        name: 'titles:status-message-order-references',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_ORDER_REFERENCES,
      },
      {
        name: 'titles:tender-response-reference',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_TENDER_RESPONSE_REFERENCE,
      },
      {
        name: 'titles:longitude-reference-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXPORTER_LONGITUDE_REFERENCE_NAME,
      },
      {
        name: 'titles:latitude-reference-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXPORTER_LATITUDE_REFERENCE_NAME,
      },
      {
        name: 'titles:send-status-codes',
        nameForAttribute: GC.FIELD_SENT_STATUS_CODES,
        customLogic: (_: any, { sentStatusCodes }: Object, props: Object) => R.map(
          (item: string) => R.compose(
            R.path([GC.FIELD_LABEL]),
            R.find(R.propEq(item, GC.FIELD_VALUE)),
            R.pathOr([], ['configOptions', 'allStatusCodeListWithStoredValues']),
          )(props),
          R.or(sentStatusCodes, []),
        ),
      },
      {
        name: 'titles:status-message-default-time-zone',
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_DEFAULT_TIME_ZONE,
        customLogic: (_: any, { statusMessageDefaultTimeZone }: Object) =>
          R.pathOr('', [statusMessageDefaultTimeZone], TIME_ZONE_TYPES_MAP),
      },
      {
        name: 'titles:status-message-send-first-last-event',
        nameForAttribute: GC.FIELD_STATUS_MESSAGE_SEND_FIRST_LAST_EVENT,
      },
      {
        name: 'titles:encoding-type',
        options: encodingTypeOptions,
        nameForAttribute: GC.FIELD_ENCODING_TYPE,
        customLogic: getDisplayedValueFromConfigOptions,
      },
      {
        name: 'titles:custom-mapper',
        options: availableEdiExportMappers,
        nameForAttribute: GC.FIELD_CUSTOM_MAPPER,
        customLogic: getDisplayedValueFromConfigOptions,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:edi-extractor-config',
    hideTitleActionsForNotSuperAdmin: true,
    configName: GC.CUSTOM_EDI_EXTRACTOR_CONFIG,
    customConfigName: GC.CUSTOM_EDI_EXTRACTOR_CONFIG,
    fields: [
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object, props: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_BRANCH_GUID, entity)),
        ),
      },
      {
        name: 'titles:local-folder',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_LOCAL_FOLDER,
      },
      {
        name: 'titles:remote-folder',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_FOLDER,
      },
      {
        name: 'titles:remote-host',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
      },
      {
        name: 'titles:port',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PORT,
      },
      {
        name: 'titles:protocol',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
      },
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
      },
    ],
  },
];

const INTEGRATION_FLEET_VENDOR = [
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:fuel-cards',
    configName: GC.CUSTOM_FUEL_CARD_CONFIG,
    customConfigName: GC.CUSTOM_FUEL_CARD_CONFIG,
    fields: [
      {
        name: 'titles:fuel-card-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_TYPE,
      },
      {
        name: 'titles:enabled',
        nameForAttribute: GC.FIELD_ENABLED,
      },
      {
        name: 'titles:login',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_LOGIN,
      },
      {
        name: 'titles:carrier-id',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_CARRIER_ID,
      },
      {
        name: 'titles:start-code',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_START_CODE,
      },
      {
        name: 'titles:account-id',
        nameForAttribute: GC.FIELD_ACCOUNT_ID,
      },
      {
        name: 'titles:deactivate-card-after-minutes',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_DEACTIVATE_CARD_AFTER_MINUTER,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:toll-charges',
    configName: GC.CUSTOM_TOLL_CHARGES_CONFIG,
    customConfigName: GC.CUSTOM_TOLL_CHARGES_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
      },
      {
        name: 'titles:api-key',
        nameForAttribute: GC.INTEGRATION_TOLL_CHARGES_ACCOUNT_ID,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:truck-type-mapping',
    configName: GC.CUSTOM_TRUCK_TYPE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_TRUCK_TYPE_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(crossBorderIntegrationTypeOptions),
      },
      {
        name: 'titles:truck-type-code',
        nameForAttribute: GC.FIELD_TRUCK_TYPE,
        customLogic: (_: any, { integrationType, integrationTruckType }: Object) =>
          R.pathOr(
            '',
            [integrationType, integrationTruckType],
            truckTypeOptionsMap,
          ),
      },
      {
        name: 'titles:truck-type-config',
        optionsName: GC.TRUCK_TRUCK_TYPE,
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_TRUCK_TYPE_CONFIG_GUID,
      },
      {
        name: 'titles:created-on',
        nameForAttribute: GC.FIELD_CREATED_DATE,
      },
      {
        name: 'titles:created-by',
        nameForAttribute: GC.FIELD_CREATED_BY,
      },
      {
        name: 'titles:update-on',
        nameForAttribute: GC.FIELD_LAST_MODIFIED_DATE,
      },
      {
        name: 'titles:updated-by',
        nameForAttribute: GC.FIELD_LAST_MODIFIED_BY,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:trailer-type-mapping',
    configName: GC.CUSTOM_TRAILER_TYPE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_TRAILER_TYPE_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(crossBorderIntegrationTypeOptions),
      },
      {
        name: 'titles:trailer-type-code',
        nameForAttribute: GC.FIELD_SERVICE_TYPE,
        customLogic: (_: any, { integrationType, integrationTrailerType }: Object) =>
          R.pathOr(
            '',
            [integrationType, integrationTrailerType],
            trailerTypeOptionsMap,
          ),
      },
      {
        name: 'titles:trailer-type-config',
        optionsName: GC.TRAILER_TRAILER_TYPE,
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_TRAILER_TYPE_CONFIG_GUID,
      },
      {
        name: 'titles:created-on',
        nameForAttribute: GC.FIELD_CREATED_DATE,
      },
      {
        name: 'titles:created-by',
        nameForAttribute: GC.FIELD_CREATED_BY,
      },
      {
        name: 'titles:update-on',
        nameForAttribute: GC.FIELD_LAST_MODIFIED_DATE,
      },
      {
        name: 'titles:updated-by',
        nameForAttribute: GC.FIELD_LAST_MODIFIED_BY,
      },
    ],
  },
];

const INTEGRATION_CARRIER_VENDOR = [
  {
    title: 'titles:default-status-code',
    fields: {
      [GC.INTEGRATION_DEFAULT_STATUS_CODE]: {
        type: 'select',
        options: 'statusCodeList',
        name: 'titles:status-code',
        nameForAttribute: GC.INTEGRATION_DEFAULT_STATUS_CODE,
      },
      [GC.INTEGRATION_DEFAULT_TRACKING_STATUS_CODE]: {
        type: 'select',
        options: 'statusCodeList',
        name: 'titles:tracking-status-code',
        nameForAttribute: GC.INTEGRATION_DEFAULT_TRACKING_STATUS_CODE,
      },
    },
  },
  {
    title: 'titles:saferwatch',
    fields: {
      [GC.INTEGRATION_SAFER_WATCH_CUSTOMER_KEY]: {
        type: 'text',
        name: 'titles:customer-key',
        nameForAttribute: GC.INTEGRATION_SAFER_WATCH_CUSTOMER_KEY,
      },
    },
  },
  {
    title: 'titles:default-tracking-system',
    fields: {
      [GC.INTEGRATION_TRACKING_SYSTEM_DEFAULT_CONFIG]: {
        type: 'select',
        options: 'trackingSystemOptions',
        name: 'titles:integration-config',
        nameForAttribute: GC.INTEGRATION_TRACKING_SYSTEM_DEFAULT_CONFIG,
      },
    },
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:carrier-synchronization',
    configName: GC.CUSTOM_CARRIER_SYNCHRONIZATION_CONFIG,
    customConfigName: GC.CUSTOM_CARRIER_SYNCHRONIZATION_CONFIG,
    hideTitleActions: ({ carrierSynchronization }: Object) => {
      if (G.notEquals(
        G.getAmousCurrentBranchGuidFromWindow(),
        R.path([0, GC.BRANCH_GUID], carrierSynchronization),
      )) return false;

      return G.isNotNilAndNotEmpty(carrierSynchronization);
    },
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => G.getEnumLocale(integrationType),
      },
      {
        name: 'titles:client-id',
        nameForAttribute: GC.FIELD_CLIENT_ID,
      },
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_USERNAME_CAMEL_CASE,
      },
      {
        name: 'titles:remove-after-inactivity-days',
        nameForAttribute: GC.FIELD_REMOVE_AFTER_INACTIVITY_DAYS,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:transportation-mode-mapping',
    configName: GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
      },
      {
        name: 'titles:mode',
        optionsName: GC.GENERAL_MODE_TRANSPORTATION,
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_TRANSPORTATION_MODE_GUID,
      },
      {
        name: 'titles:external-mode',
        nameForAttribute: GC.EXTERNAL_TRANSPORTATION_MODE,
        customLogic: (_: any, { integrationType, externalTransportationMode }: Object) => R.pathOr(
          externalTransportationMode,
          [integrationType, externalTransportationMode],
          externalTransportationModeOptionsMap,
        ),
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:equipment-mapping',
    configName: GC.CUSTOM_EQUIPMENT_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_EQUIPMENT_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
      },
      {
        name: 'titles:equipment',
        optionsName: GC.GENERAL_EQUIPMENTS,
        nameForAttribute: GC.FIELD_EQUIPMENT_GUID,
        customLogic: getDisplayedValueFromConfigOptions,
      },
      {
        name: 'titles:external-equipment',
        nameForAttribute: GC.FIELD_EXTERNAL_EQUIPMENT,
        customLogic: (_: any, { integrationType, externalEquipment }: Object) => R.pathOr(
          externalEquipment,
          [integrationType, externalEquipment],
          equipmentOptionsMap,
        ),
      },
    ],
  },
  {
    configType: 'custom',
    fields: carrierIntegrationFields,
    title: 'titles:carrier-integration',
    configName: GC.CUSTOM_CARRIER_RATE_CONFIG,
    customConfigName: GC.CUSTOM_CARRIER_RATE_CONFIG,
  },
  {
    configType: 'custom',
    fields: carrierIntegrationCustomerFields,
    title: 'titles:carrier-integration-override',
    configName: GC.CUSTOM_CARRIER_INTEGRATION_OVERRIDE_CONFIG,
    customConfigName: GC.CUSTOM_CARRIER_INTEGRATION_OVERRIDE_CONFIG,
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:carrier-pickup-requester',
    configName: GC.CUSTOM_CARRIER_PICKUP_REQUESTER_CONFIG,
    customConfigName: GC.CUSTOM_CARRIER_PICKUP_REQUESTER_CONFIG,
    fields: [
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_NAME,
      },
      {
        name: 'titles:location-name',
        nameForAttribute: GC.FIELD_LOCATION_NAME,
      },
      {
        name: 'titles:address',
        tableColumnWith: '400px',
        nameForAttribute: GC.FIELD_ADDRESS,
      },
      {
        name: 'titles:city',
        nameForAttribute: GC.FIELD_CITY,
      },
      {
        name: 'titles:state',
        nameForAttribute: GC.FIELD_STATE,
      },
      {
        name: 'titles:postal-code',
        nameForAttribute: GC.FIELD_ZIP,
      },
      {
        name: 'titles:country',
        nameForAttribute: GC.FIELD_COUNTRY,
      },
      {
        name: 'titles:contact-name',
        nameForAttribute: GC.FIELD_CONTACT_NAME,
      },
      {
        name: 'titles:phone',
        nameForAttribute: GC.FIELD_PHONE,
      },
      {
        name: 'titles:email',
        nameForAttribute: GC.FIELD_EMAIL,
      },
      {
        name: 'titles:fax',
        nameForAttribute: GC.FIELD_FAX,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:carrier-service-mapping',
    configName: GC.CUSTOM_SERVICE_MAPPING_CONFIG_SECTION,
    customConfigName: GC.CUSTOM_SERVICE_MAPPING_CONFIG_SECTION,
    additionalTitleComponent: ({ serviceMappingFilter, setServiceMappingFilter }: Object) =>
      <ReactSelectComponent
        value={serviceMappingFilter}
        handleChange={setServiceMappingFilter}
        options={R.drop(1, getCarrierRateIntegrationOptions())}
        placeholder={G.getWindowLocale('titles:filter-by-integration-type', 'Filter By Integration Type')}
      />,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        options: getCarrierRateIntegrationOptions(),
        customLogic: getDisplayedValueFromConfigOptions,
      },
      {
        name: 'titles:vendor-service-code',
        nameForAttribute: GC.FIELD_SERVICE_MAPPING_SERVICE_CODE,
        customLogic: (field: Object, entity: Object) => R.pathOr(
          '',
          [R.prop(GC.FIELD_INTEGRATION_TYPE, entity), R.prop(GC.FIELD_SERVICE_MAPPING_SERVICE_CODE, entity)],
          integrationTypeToServiceCodeMap,
        ),
      },
      {
        name: 'titles:service-config',
        optionsName: GC.GENERAL_SERVICES,
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID,
      },
    ],
  },
  {
    showRemove: true,
    configType: 'custom',
    title: 'titles:service-type-mapping',
    configName: GC.CUSTOM_SERVICE_TYPE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_SERVICE_TYPE_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(serviceTypeMappingIntegrationTypeOptions()),
      },
      {
        name: 'titles:service-type-code',
        nameForAttribute: GC.FIELD_SERVICE_TYPE,
        customLogic: (_: any, { serviceType, integrationType }: Object) => R.pathOr(
          '',
          [integrationType, serviceType],
          serviceTypeOptionsMap,
        ),
      },
      {
        name: 'titles:service-type-config',
        customLogic: getDisplayedValueFromConfigOptions,
        optionsName: GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
        nameForAttribute: GC.FIELD_SERVICE_TYPE_CONFIG_GUID,
      },
      {
        name: 'titles:created-on',
        nameForAttribute: GC.FIELD_CREATED_DATE,
      },
      {
        name: 'titles:created-by',
        nameForAttribute: GC.FIELD_CREATED_BY,
      },
      {
        name: 'titles:update-on',
        nameForAttribute: GC.FIELD_LAST_MODIFIED_DATE,
      },
      {
        name: 'titles:updated-by',
        nameForAttribute: GC.FIELD_LAST_MODIFIED_BY,
      },
    ],
  },
  {
    showRemove: true,
    configType: 'custom',
    title: 'titles:tracking-management',
    configName: GC.CUSTOM_DISPATCH_INTEGRATION_CONFIG,
    customConfigName: GC.CUSTOM_DISPATCH_INTEGRATION_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_TYPE,
      },
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_NAME,
      },
      {
        name: 'titles:login',
        nameForAttribute: GC.FIELD_LOGIN,
      },
      {
        name: 'titles:integration-id',
        nameForAttribute: GC.FIELD_INTEGRATION_ID,
      },
      {
        optionsName: 'allStatusCodeList',
        name: 'titles:tracking-status-code',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_TRACKING_STATUS_CODE,
      },
      {
        name: 'titles:tracking-frequency-in-minutes',
        nameForAttribute: GC.FIELD_TRACKING_FREQUENCY_IN_MINUTES,
      },
      {
        name: 'titles:hot-load-tracking-frequency-in-minutes',
        nameForAttribute: GC.FIELD_HOT_LOAD_TRACKING_FREQUENCY_IN_MINUTES,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    hideTitleActionsForNotSuperAdmin: true,
    title: 'titles:carrier-edi-loader-config',
    configName: GC.CUSTOM_CARRIER_EDI_LOADER_CONFIG,
    customConfigName: GC.CUSTOM_CARRIER_EDI_LOADER_CONFIG,
    fields: [
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object, props: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_BRANCH_GUID, entity)),
        ),
      },
      {
        name: 'titles:folderName',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
      },
      {
        name: 'titles:sender-identification',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE,
      },
      {
        name: 'titles:sender-identification-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE,
        customLogic: (_: any, { senderIdentificationType }: Object) => R.compose(
          R.path([GC.FIELD_LABEL]),
          R.find(R.propEq(senderIdentificationType, GC.FIELD_VALUE)),
        )(getEDIConfigSenderIdentificationTypes()),
      },
      {
        name: 'titles:load-identification-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOAD_IDENTIFICATION_TYPE,
        customLogic: (_: any, { loadIdentificationType }: Object) => R.compose(
          R.path([GC.FIELD_LABEL]),
          R.find(R.propEq(loadIdentificationType, GC.FIELD_VALUE)),
        )(getEDIConfigLoadIdentificationTypes()),
      },
      {
        name: 'titles:carrier',
        optionsName: 'carriers',
        nameForAttribute: GC.FIELD_CARRIER_GUID,
        customLogic: getDisplayedValueFromConfigOptions,
      },
      {
        name: 'titles:invoice-document-type',
        optionsName: GC.COMMUNICATION_DOCUMENT_TYPE,
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_INVOICE_DOCUMENT_TYPE_GUID,
      },
      {
        name: 'titles:default-time-zone',
        nameForAttribute: GC.FIELD_DEFAULT_TIME_ZONE,
      },
      {
        name: 'titles:lastTwoNumbersArePennies',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES,
      },
      {
        name: 'titles:send-response',
        nameForAttribute: GC.FIELD_SEND_RESPONSE,
      },
      {
        optionsName: 'telReferenceTypes',
        name: 'titles:event-matching-reference',
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_EVENT_MATCHING_REFERENCE,
      },
      {
        name: 'titles:stop-number-reference-code',
        nameForAttribute: GC.FIELD_STOP_NUMBER_REFERENCE_CODE,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    hideTitleActionsForNotSuperAdmin: true,
    title: 'titles:carrier-edi-exporter-config',
    configName: GC.CUSTOM_CARRIER_EDI_EXPORTER_CONFIG,
    customConfigName: GC.CUSTOM_CARRIER_EDI_EXPORTER_CONFIG,
    fields: [
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object, props: Object) => R.path(
          [GC.FIELD_BRANCH_NAME],
          G.getBranchFromWindowByGuid(G.getPropFromObject(GC.FIELD_BRANCH_GUID, entity)),
        ),
      },
      {
        name: 'titles:carrier',
        optionsName: 'carriers',
        nameForAttribute: GC.FIELD_CARRIER_GUID,
        customLogic: getDisplayedValueFromConfigOptions,
      },
      {
        name: 'titles:folderName',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME,
      },
      {
        name: 'titles:transaction-types',
        nameForAttribute: GC.FIELD_TRANSACTION_TYPES,
        customLogic: (_: any, { transactionTypes }: Object) => R.compose(
          R.join(', '),
          R.map((item: Object) => R.compose(
            R.path([GC.FIELD_LABEL]),
            R.find(R.propEq(item, GC.FIELD_VALUE)),
          )(getCarrierEdiExporterTransactionTypeOptions())),
        )(R.or(transactionTypes, [])),
      },
      {
        name: 'titles:protocol',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
      },
      {
        name: 'titles:remote-host',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
      },
      {
        name: 'titles:remote-port',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT,
      },
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
      },
      {
        name: 'titles:sender-id',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID,
      },
      {
        name: 'titles:sender-qualifier',
        nameForAttribute: GC.FIELD_EDI_SENDER_QUALIFIER,
        customLogic: (_: any, { senderQualifier }: Object) => R.compose(
          R.path([GC.FIELD_LABEL]),
          R.find(R.propEq(senderQualifier, GC.FIELD_VALUE)),
        )(getCustomerEdiExporterReceiverQualifierTypeOptions()),
      },
      {
        name: 'titles:receiverId',
        nameForAttribute: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID,
      },
      {
        name: 'titles:content-format',
        nameForAttribute: GC.FIELD_CONTENT_FORMAT,
        customLogic: (_: any, { contentFormat }: Object) => G.getEnumLocale(contentFormat),
      },
      {
        name: 'titles:event-references',
        optionsName: 'telReferenceTypes',
        nameForAttribute: GC.FIELD_EVENT_REFERENCES,
        customLogic: getDisplayedValueFromConfigOptions,
      },
      {
        name: 'titles:trip-references',
        optionsName: 'telReferenceTypes',
        nameForAttribute: GC.FIELD_TEL_REFERENCES,
        customLogic: getDisplayedValueFromConfigOptions,
      },
    ],
  },
];

const LOAD_BOARDS_CONFIGURATION = {
  permissions: PC.EXTERNAL_LOAD_BOARD_EXECUTE,
  settings: [
    {
      hideInherited: true,
      configType: 'custom',
      title: 'titles:external-load-boards',
      configName: GC.CUSTOM_LOADBOARD_CONFIG,
      customConfigName: GC.CUSTOM_LOADBOARD_CONFIG,
      fields: [
        {
          name: 'titles:integration-type',
          nameForAttribute: GC.INTEGRATION_LOADBOARD_TYPE,
          customLogic: (_: Object, { type }: Object) => getLBDisplayValue(type),
        },
        {
          name: 'titles:status',
          nameForAttribute: GC.FIELD_STATUS,
          customLogic: (_: Object, { status }: Object) => G.getEnumLocale(status),
        },
        {
          name: 'titles:client-id',
          nameForAttribute: GC.FIELD_USERNAME_CAMEL_CASE,
        },
        {
          name: 'titles:account-id',
          nameForAttribute: GC.INTEGRATION_LOADBOARD_ACCOUNT_ID,
        },
        {
          name: 'titles:transportation-mode',
          optionsName: GC.GENERAL_MODE_TRANSPORTATION,
          customLogic: getDisplayedValueFromConfigOptions,
          nameForAttribute: ['data', GC.INTEGRATION_LOADBOARD_TRANSPORTATION_MODE_GUID],
        },
        {
          name: 'titles:show-shipper',
          nameForAttribute: GC.INTEGRATION_LOADBOARD_SHOW_SHIPPER,
          customLogic: (_: Object, entity: Object) => {
            const type = R.prop(GC.FIELD_LOAD_BOARD_TYPE, entity);
            const showShipper = R.path(['data', 'showShipper'], entity);

            if (G.notEquals(type, GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS)) return '';

            return G.ifElse(
              showShipper,
              I.uiTrue,
              I.uiFalse,
            )();
          },
        },
        {
          name: 'titles:show-customer-rate',
          nameForAttribute: GC.FIELD_SHOW_CUSTOMER_RATE,
          customLogic: (_: Object, entity: Object) => {
            const type = R.prop(GC.FIELD_LOAD_BOARD_TYPE, entity);
            const showCustomerRate = R.path(['data', 'showCustomerRate'], entity);

            if (G.notEquals(type, GC.EXTERNAL_LOAD_BOARD_PARADE)) return '';

            return G.ifElse(
              showCustomerRate,
              I.uiTrue,
              I.uiFalse,
            )();
          },
        },
        {
          name: 'titles:required-start-price-max-price',
          nameForAttribute: GC.FIELD_REQUIRED_MIN_MAX_PRICE,
          customLogic: (_: Object, entity: Object) => {
            const type = R.prop(GC.FIELD_LOAD_BOARD_TYPE, entity);
            const requiredMinMaxPrice = R.path(['data', 'requiredMinMaxPrice'], entity);

            if (G.notEquals(type, GC.EXTERNAL_LOAD_BOARD_PARADE)) return '';

            return G.ifElse(
              requiredMinMaxPrice,
              I.uiTrue,
              I.uiFalse,
            )();
          },
        },
        {
          tableColumnWith: 320,
          name: 'titles:description',
          nameForAttribute: GC.FIELD_DESCRIPTION,
        },
      ],
    },
  ],
};

const INTEGRATION_ANALYTICS = [
  {
    configType: 'custom',
    title: 'titles:user-monitoring',
    configName: GC.CUSTOM_USER_MONITORING_CONFIG,
    customConfigName: GC.CUSTOM_USER_MONITORING_CONFIG,
    fields: [
      {
        name: 'titles:monitoring-app',
        nameForAttribute: GC.FIELD_USER_MONITORING_APP,
        customLogic: (field: Object, entity: Object) => G.getEnumLocale(R.prop(GC.FIELD_USER_MONITORING_APP, entity)),
      },
      {
        name: 'titles:active',
        nameForAttribute: GC.FIELD_ENABLED,
        customLogic: (field: Object, entity: Object) => G.ifElse(
          R.prop(GC.FIELD_ENABLED, entity),
          I.uiTrue,
          I.uiFalse,
        )(),
      },
    ],
  },
];

const INTEGRATION_DOCUMENTS = [
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:document-hub',
    configName: GC.CUSTOM_INTEGRATION_DOCUMENT_HUB_CONFIG,
    customConfigName: GC.CUSTOM_INTEGRATION_DOCUMENT_HUB_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(documentTypeMappingOptions()),
      },
      {
        tableColumnWith: '400px',
        name: 'titles:send-trip-references',
        fieldName: GC.FIELD_SEND_REFERENCES_GUIDS,
        customLogic: (_: any, { sendReferencesGuids }: Object, props: Object) => R.compose(
          R.join(', '),
          R.map((item: Object) => R.compose(
            R.path([GC.FIELD_LABEL]),
            R.find(R.propEq(item, GC.FIELD_VALUE)),
            R.pathOr([], ['configOptions', 'telReferenceTypes']),
          )(props)),
        )(R.or(sendReferencesGuids, [])),
      },
      {
        optionsName: GC.COMMUNICATION_DOCUMENT_TYPE,
        name: 'titles:proof-of-delivery-document-type',
        customLogic: getDisplayedValueFromConfigOptions,
        fieldName: GC.FIELD_PROOF_OF_DELIVERY_DOCUMENT_TYPE,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:document-type-mapping',
    configName: GC.CUSTOM_INTEGRATION_DOCUMENT_TYPE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_INTEGRATION_DOCUMENT_TYPE_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(documentTypeMappingOptions()),
      },
      {
        name: 'titles:external-document-type',
        nameForAttribute: GC.FIELD_DOCUMENT_EXTERNAL_DOCUMENT_TYPE,
        customLogic: (_: any, { integrationType, externalDocumentType }: Object) => R.pathOr(
          '',
          [integrationType, externalDocumentType],
          EXTERNAL_DOCUMENT_TYPE_MAP,
        ),
      },
      {
        name: 'titles:document-type-config',
        optionsName: GC.COMMUNICATION_DOCUMENT_TYPE,
        customLogic: getDisplayedValueFromConfigOptions,
        nameForAttribute: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID,
      },
    ],
  },
];

const INTEGRATION_ACCOUNTING = [
  {
    title: 'titles:business-central',
    fields: {
      [GC.INTEGRATION_BUSINESS_CENTRAL_DIVISION_NAME]: {
        name: 'titles:division-name',
        nameForAttribute: GC.INTEGRATION_BUSINESS_CENTRAL_DIVISION_NAME,
      },
    },
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:accounting',
    configName: GC.CUSTOM_INTEGRATION_ACCOUNTING_CONFIG,
    customConfigName: GC.CUSTOM_INTEGRATION_ACCOUNTING_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_TYPE,
        customLogic: (_: any, { type }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(type, GC.FIELD_VALUE)),
        )(accountingOptions()),
      },
      {
        name: 'titles:client-id',
        nameForAttribute: GC.FIELD_CLIENT_ID,
      },
      {
        name: 'titles:company-id',
        nameForAttribute: GC.FIELD_COMPANY_ID,
      },
    ],
  },
];

const INTEGRATION_VEHICLE = [
  {
    configType: 'custom',
    title: 'titles:vin-lookup',
    configName: GC.CUSTOM_VIN_LOOKUP_CONFIG,
    customConfigName: GC.CUSTOM_VIN_LOOKUP_CONFIG,
    fields: [
      {
        name: 'titles:type',
        nameForAttribute: GC.FIELD_TYPE,
      },
      {
        name: 'titles:api-key',
        nameForAttribute: GC.FIELD_API_KEY,
      },
    ],
  },
];

const statusCodeMappingFields = [
  {
    name: 'titles:integration-type',
    nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
    customLogic: (_: any, { integrationType }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
      R.concat(statusCodeOutboundMappingTypeOptions),
    )(statusCodeMappingTypeOptions()),
  },
  {
    name: 'titles:status-code',
    nameForAttribute: GC.FIELD_STATUS_CODE,
    customLogic: (_: any, { statusCode, integrationType }: Object) => R.pathOr(
      statusCode,
      [integrationType, statusCode],
      statusCodeOptionsMap,
    ),
  },
  {
    optionsName: 'allStatusCodeList',
    name: 'titles:status-code-config',
    nameForAttribute: GC.FIELD_STATUS_CODE_GUID,
    customLogic: getDisplayedValueFromConfigOptions,
  },
  {
    name: 'titles:created-on',
    nameForAttribute: GC.FIELD_CREATED_DATE,
  },
  {
    name: 'titles:created-by',
    nameForAttribute: GC.FIELD_CREATED_BY,
  },
  {
    name: 'titles:update-on',
    nameForAttribute: GC.FIELD_LAST_MODIFIED_DATE,
  },
  {
    name: 'titles:updated-by',
    nameForAttribute: GC.FIELD_LAST_MODIFIED_BY,
  },
];

const statusReasonCodeMappingFields = [
  {
    name: 'titles:integration-type',
    nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
    customLogic: (_: any, { integrationType }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
    )(statusReasonCodeMappingTypeOptions),
  },
  {
    name: 'titles:status-reason-code',
    nameForAttribute: GC.FIELD_STATUS_REASON_CODE,
  },
  {
    name: 'titles:status-reason-code-config',
    optionsName: GC.COMMUNICATION_REASON_CODE,
    customLogic: getDisplayedValueFromConfigOptions,
    nameForAttribute: GC.FIELD_STATUS_REASON_CODE_GUID,
  },
  {
    name: 'titles:created-on',
    nameForAttribute: GC.FIELD_CREATED_DATE,
  },
  {
    name: 'titles:created-by',
    nameForAttribute: GC.FIELD_CREATED_BY,
  },
  {
    name: 'titles:update-on',
    nameForAttribute: GC.FIELD_LAST_MODIFIED_DATE,
  },
  {
    name: 'titles:updated-by',
    nameForAttribute: GC.FIELD_LAST_MODIFIED_BY,
  },
];

const INTEGRATION_STATUS_CODE_MAPPING = [
  {
    showRemove: true,
    configType: 'custom',
    fields: statusCodeMappingFields,
    title: 'titles:status-code-inbound-mapping',
    configName: GC.CUSTOM_STATUS_CODE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_STATUS_CODE_MAPPING_CONFIG,
  },
  {
    showRemove: true,
    configType: 'custom',
    fields: statusCodeMappingFields,
    title: 'titles:status-code-outbound-mapping',
    configName: GC.CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG,
  },
  {
    showRemove: true,
    configType: 'custom',
    fields: statusReasonCodeMappingFields,
    title: 'titles:status-reason-code-inbound-mapping',
    configName: GC.CUSTOM_STATUS_REASON_CODE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_STATUS_REASON_CODE_MAPPING_CONFIG,
  },
  {
    showRemove: true,
    configType: 'custom',
    fields: statusReasonCodeMappingFields,
    title: 'titles:status-reason-code-outbound-mapping',
    configName: GC.CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG,
  },
];

const INTEGRATION_BOSCH = [
  {
    title: 'titles:general',
    showOnlyOnMasterBranch: true,
    fields: {
      [GC.INTEGRATION_LOS_ACCOUNT_LINKED]: {
        type: 'customComponent',
        hideInheritedValue: true,
        name: 'titles:link-to-los',
        component: () => <LinkToLosComponent />,
        nameForAttribute: GC.INTEGRATION_LOS_ACCOUNT_LINKED,
        infoText: G.getWindowLocale(
          'messages:statistic-link-to-los',
          'Used to connect Amous TMS with your LOS account',
        ),
      },
    },
  },
  {
    title: 'titles:statistic',
    fields: {
      [GC.INTEGRATION_BOSCH_STATISTIC_LAST_DAYS]: {
        name: 'titles:last-days',
        nameForAttribute: GC.INTEGRATION_BOSCH_STATISTIC_LAST_DAYS,
      },
      [GC.INTEGRATION_BOSCH_STATISTIC_NEGATIVE_INVOICE_STATUSES]: {
        type: 'list',
        options: 'customerInvoiceStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:negative-invoice-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INTEGRATION_BOSCH_STATISTIC_NEGATIVE_INVOICE_STATUSES,
      },
    },
  },
  {
    hideInherited: true,
    configType: 'custom',
    hideForNotSuperAdmin: true,
    hideTitleActions: () => true,
    title: 'titles:los-subscription',
    configName: GC.CUSTOM_LOS_SUBSCRIPTION_CONFIG,
    customConfigName: GC.CUSTOM_LOS_SUBSCRIPTION_CONFIG,
    fields: [
      {
        name: 'titles:master-branch-suffix',
        nameForAttribute: GC.FIELD_MASTER_BRANCH_SUFFIX,
      },
      {
        name: 'titles:customer-branch-suffix',
        nameForAttribute: GC.FIELD_CUSTOMER_BRANCH_SUFFIX,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    hideForNotSuperAdmin: true,
    title: 'titles:los-pricing-plan',
    configName: GC.CUSTOM_LOS_PRICING_PLAN_CONFIG,
    customConfigName: GC.CUSTOM_LOS_PRICING_PLAN_CONFIG,
    fields: [
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_NAME,
      },
      {
        name: 'titles:max-number-of-trucks',
        nameForAttribute: GC.FIELD_MAX_NUMBER_OF_TRUCKS,
      },
    ],
  },
];

const INTEGRATION_TRAILER = [
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:trailer-tracking-integration',
    configName: GC.CUSTOM_INTEGRATION_TRAILER_TRACKING_CONFIG,
    customConfigName: GC.CUSTOM_INTEGRATION_TRAILER_TRACKING_CONFIG,
    fields: [
      {
        name: 'titles:branch-name',
        nameForAttribute: GC.FIELD_BRANCH_GUID,
        customLogic: (_: any, entity: Object) =>
          G.getBranchNameFromWindowByGuid(G.getBranchGuidFromObject(entity)),
      },
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => G.toTitleCase(integrationType),
      },
      {
        name: 'titles:active',
        nameForAttribute: GC.FIELD_ENABLED,
      },
      {
        name: 'titles:client-id',
        nameForAttribute: GC.FIELD_CLIENT_ID,
      },
    ],
  },
];

const WMS_INTEGRATION = [
  {
    showRemove: true,
    hideInherited: true,
    configType: 'custom',
    title: 'titles:terminal-management',
    configName: GC.CUSTOM_TERMINAL_INTEGRATION_CONFIG,
    customConfigName: GC.CUSTOM_TERMINAL_INTEGRATION_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
      },
      {
        name: 'titles:enabled',
        nameForAttribute: GC.FIELD_ENABLED,
      },
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_USERNAME_CAMEL_CASE,
      },
      {
        name: 'titles:system-id',
        nameForAttribute: GC.FIELD_SYSTEM_ID,
      },
      {
        name: 'titles:domain',
        nameForAttribute: GC.FIELD_DOMAIN,
      },
      {
        name: 'titles:owner-id',
        nameForAttribute: GC.FIELD_OWNER_ID,
      },
    ],
  },
];

const INTEGRATION_CROSS_BORDER = [
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:cross-border-integration-config',
    configName: GC.CUSTOM_CROSS_BORDER_INTEGRATION_CONFIG,
    customConfigName: GC.CUSTOM_CROSS_BORDER_INTEGRATION_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (_: any, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(crossBorderIntegrationTypeOptions),
      },
      {
        name: 'titles:accountName',
        nameForAttribute: GC.FIELD_ACCOUNT_NAME,
      },
      {
        name: 'titles:active',
        nameForAttribute: GC.FIELD_ENABLED,
      },
    ],
  },
];

const ConfigIntegrationGroup = {
  INTEGRATION_FINANCIAL,
  INTEGRATION_CUSTOMER,
  INTEGRATION_FLEET_VENDOR,
  INTEGRATION_CARRIER_VENDOR,
  LOAD_BOARDS_CONFIGURATION,
  INTEGRATION_ANALYTICS,
  INTEGRATION_DOCUMENTS,
  INTEGRATION_ACCOUNTING,
  INTEGRATION_VEHICLE,
  INTEGRATION_STATUS_CODE_MAPPING,
  INTEGRATION_BOSCH,
  INTEGRATION_TRAILER,
  WMS_INTEGRATION,
  INTEGRATION_CROSS_BORDER,
};

export {
  ConfigIntegrationGroup,
};
