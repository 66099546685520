import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncGetCommissionAssigneeListByLoadType } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-details-new
import CommissionAssigneeForm from '../forms/commission-assignee';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');

const enhance = compose(
  withAsyncGetCommissionAssigneeListByLoadType,
  withHandlers({
    handleOpenSalesAgentsForm: (props: Object) => ({ key, type, value, label }: Object) => {
      const {
        load,
        isAgent,
        fromPage,
        openModal,
        tableIndex,
        closeModal,
        changeCommissionAssignmentRequest,
        availableCommissionAssigneeOptions,
      } = props;

      const initialValues = { [GC.FIELD_COMMISSION_ASSIGNMENTS]: R.map(G.getGuidFromObject, value) };

      const optionsForSelect = {
        availableCommissionAssigneeOptions: R.prop(type, availableCommissionAssigneeOptions),
      };

      const submitAction = ({ commissionAssignments }: Object) => {
        const data = {
          type,
          fromPage,
          tableIndex,
          fieldName: key,
          commissionAssignments,
          [GC.FIELD_LOAD_GUID]: G.getGuidFromObject(load),
          [GC.FIELD_LOAD_TYPE]: G.getPropFromObject(GC.FIELD_LOAD_TYPE, load),
        };

        changeCommissionAssignmentRequest(data);
      };

      const component = (
        <CommissionAssigneeForm
          label={label}
          isAgent={isAgent}
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          availableCommissionAssigneeOptions={availableCommissionAssigneeOptions}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: label,
          width: 'auto',
          height: 'auto',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const map = {
  [GC.LOAD_TYPE_TEL]: [
    {
      key: GC.FIELD_BROKER_AGENTS,
      type: GC.COMMISSION_ASSIGNMENT_TYPE_BROKER_AGENT,
      label: G.getWindowLocale('titles:broker-agents', 'Broker Agents'),
    },
    {
      key: GC.FIELD_SALE_REPRESENTATIVES,
      type: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE,
      label: G.getWindowLocale('titles:sale-representatives', 'Sale Representatives'),
    },
    {
      key: GC.FIELD_CARRIER_REPRESENTATIVES,
      type: GC.COMMISSION_ASSIGNMENT_TYPE_CARRIER_REPRESENTATIVE,
      label: G.getWindowLocale('titles:carrier-representatives', 'Carrier Representatives'),
    },
  ],
  [GC.LOAD_TYPE_CLO]: [
    {
      key: GC.FIELD_SALE_PERSONS,
      type: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_PERSON,
      label: G.getWindowLocale('titles:sale-persons', 'Sale Persons'),
    },
    {
      key: GC.FIELD_SALE_REPRESENTATIVES,
      type: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE,
      label: G.getWindowLocale('titles:sale-representatives', 'Sale Representatives'),
    },
    {
      key: GC.FIELD_ACCOUNT_MANAGERS,
      type: GC.COMMISSION_ASSIGNMENT_TYPE_ACCOUNT_MANAGER,
      label: G.getWindowLocale('titles:account-managers', 'Account Managers'),
    },
  ],
};

const CommissionAssignee = (props: Object) => {
  const { load, isAgent, handleOpenSalesAgentsForm } = props;

  const { loadType } = load;

  if (R.and(G.isTrue(isAgent), G.isCurrentUserTypeCarrier())) return null;

  return (
    <Fragment>
      {
        R.prop(loadType, map).map(({ key, type, label }: Object, index: number) => {
          const value = R.propOr([], key, load);
          const valueStr = R.map(({ id, name }: Object) => `${name} (${id})`, value);

          return (
            <Flex mr={10} key={index}>
              <TextComponent
                maxWidth={190}
                withEllipsis={true}
                title={`${label}: ${valueStr}`}
              >
                {label}: {valueStr}
              </TextComponent>
              <AuthWrapper has={[PC.TEL_WRITE]}>
                <Box
                  ml='5px'
                  cursor='pointer'
                  onClick={() => handleOpenSalesAgentsForm({ key, type, value, label })}
                >
                  {I.pencil(iconColor)}
                </Box>
              </AuthWrapper>
            </Flex>
          );
        })
      }
    </Fragment>
  );
};

export default enhance(CommissionAssignee);
