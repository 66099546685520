import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const selectLoadDetailsStore = (state: Object) => state.loadDetails;

const makeSelectEvents = () => createSelector(
  selectLoadDetailsStore,
  ({ events }: Object) => R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX), R.values(events)),
);

const makeSelectLoadDetails = () => createSelector(
  selectLoadDetailsStore,
  ({ details }: Object) => details,
);

const makeSelectDispatchPlannerOpened = () => createSelector(
  selectLoadDetailsStore,
  ({ dispatchPlannerOpened }: Object) => dispatchPlannerOpened,
);

const makeSelectDispatchPlannerEventsOpened = () => createSelector(
  selectLoadDetailsStore,
  ({ dispatchPlannerEventsOpened }: Object) => dispatchPlannerEventsOpened,
);

const makeSelectLists = () => createSelector(
  selectLoadDetailsStore,
  ({ lists }: Object) => lists,
);

const makeSelectLoadGuid = () => createSelector(
  selectLoadDetailsStore,
  ({ details }: Object) => R.path([GC.FIELD_GUID], details),
);

const makeSelectLoadConfigs = () => createSelector(
  selectLoadDetailsStore,
  ({ loadConfigs }: Object) => loadConfigs,
);

const makeSelectCharges = () => createSelector(
  selectLoadDetailsStore,
  ({ charges }: Object) => charges,
);

const makeSelectSelectedCustomerRate = () => createSelector(
  selectLoadDetailsStore,
  R.compose(
    R.find(R.pathEq(true, ['selected'])),
    R.pathOr([], ['lists', 'customerRateList']),
  ),
);

const makeSelectSelectedCarrierDriverRate = () => createSelector(
  selectLoadDetailsStore,
  R.compose(
    R.find(R.pathEq(true, ['selected'])),
    R.pathOr([], ['lists', 'driverCarrierRateList']),
  ),
);

const makeSelectSelectedCarrierDriverRateGuid = () => createSelector(
  selectLoadDetailsStore,
  R.compose(
    G.getGuidFromObject,
    R.find(R.pathEq(true, ['selected'])),
    R.pathOr([], ['lists', 'driverCarrierRateList']),
  ),
);

const makeSelectActiveListNames = () => createSelector(
  selectLoadDetailsStore,
  ({ activeLists }: Object) => activeLists,
);

const makeSelectActiveLists = () => createSelector(
  selectLoadDetailsStore,
  ({ lists, activeLists }: Object) => R.compose(
    R.indexBy(R.prop('listName')),
    R.map((listName: Object) => ({
      listName,
      pagination: R.path([listName, 'pagination'], lists),
      totalCount: R.path([listName, 'totalCount'], lists),
      list: R.or(R.path([listName, 'list'], lists), R.path([listName], lists)),
    })),
    R.uniq,
  )(activeLists),
);

const makeSelectLoadTotalWeight = () => createSelector(
  selectLoadDetailsStore,
  ({ details }: Object) => {
    const items = R.pathOr([], [GC.FIELD_LOAD_ITEMS], details);
    if (G.isNotNilAndNotEmpty(items)) {
      const total = G.calcItemsTotalWeightWithoutQty(items);

      return G.renameKeys({
        [GC.FIELD_ITEM_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
        [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
      }, total);
    }

    return {};
  },
);

const makeSelectLoadTotalDistance = () => createSelector(
  selectLoadDetailsStore,
  ({ details }: Object) => {
    const distances = R.compose(
      R.filter(G.isNotNilAndNotEmpty),
      R.map(R.prop(GC.FIELD_DISTANCE_TEL)),
      R.pathOr([], [GC.FIELD_LOAD_STOPS]),
    )(details);

    return G.calculateTotalDistance(distances);
  },
);

const makeSelectLoadDocuments = () => createSelector(
  selectLoadDetailsStore,
  ({ documents }: Object) => documents,
);

const makeSelectLinkedOrdersDocuments = () => createSelector(
  selectLoadDetailsStore,
  ({ linkedOrdersDocuments }: Object) => linkedOrdersDocuments,
);

const makeSelectDeclineReasonOptions = () => createSelector(
  selectLoadDetailsStore,
  ({ loadConfigs }: Object) => R.compose(
    R.map((item: Object) => ({
      [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
      [GC.FIELD_LABEL]: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
    })),
    R.pathOr(
      [],
      [
        'configGroups',
        GC.COMMUNICATION_CONFIG_GROUP,
        'dropdowns',
        GC.COMMUNICATION_DISPATCH_DECLINE_REASON_CODE,
        'options',
      ],
    ),
  )(loadConfigs),
);

const makeSelectStatusMessageConfigs = () => createSelector(
  selectLoadDetailsStore,
  ({ loadConfigs: { statusMessageConfigs } }: Object) => statusMessageConfigs,
);

const makeSelectMessageCenter = () => createSelector(
  selectLoadDetailsStore,
  ({ messageCenter }: Object) => messageCenter,
);

const makeSelectMessageCenterList = () => createSelector(
  selectLoadDetailsStore,
  ({ messageCenter }: Object) => {
    const { activeTab } = messageCenter;
    const entities = R.pathOr([], [activeTab], messageCenter);

    return { entities, activeTab };
  },
);

const makeSelectNotification = () => createSelector(
  selectLoadDetailsStore,
  ({ notification }: Object) => notification,
);

const makeSelectPinnedNote = () => createSelector(
  selectLoadDetailsStore,
  ({ messageCenter }: Object) => R.compose(
    R.find(R.propEq(true, 'pinned')),
    R.prop('noteList'),
  )(messageCenter),
);

const makeSelectMailList = () => createSelector(
  selectLoadDetailsStore,
  ({ mailList }: Object) => mailList,
);

const makeSelectRelatedOrderList = () => createSelector(
  selectLoadDetailsStore,
  ({ relatedOrderList }: Object) => relatedOrderList,
);

const makeSelectRelatedOrderListLoading = () => createSelector(
  selectLoadDetailsStore,
  ({ relatedOrderListLoading }: Object) => relatedOrderListLoading,
);

const makeSelectLinkedOrderList = () => createSelector(
  selectLoadDetailsStore,
  ({ linkedOrderList }: Object) => linkedOrderList,
);

const makeSelectCloEventReferences = () => createSelector(
  selectLoadDetailsStore,
  ({ cloEventReferences }: Object) => cloEventReferences,
);

const makeSelectExpandedLists = () => createSelector(
  selectLoadDetailsStore,
  ({ expandedLists }: Object) => expandedLists,
);

const makeSelectHiddenTabs = () => createSelector(
  selectLoadDetailsStore,
  ({ hiddenTabs }: Object) => hiddenTabs,
);

const makeSelectTelDocumentTemplates = () => createSelector(
  selectLoadDetailsStore,
  ({ documentTemplates }: Object) => documentTemplates,
);

const makeSelectLoadInvoiceTotals = () => createSelector(
  selectLoadDetailsStore,
  ({ invoiceTotals }: Object) => invoiceTotals,
);

const makeSelectLoadCustomStatuses = () => createSelector(
  selectLoadDetailsStore,
  ({ loadCustomStatuses }: Object) => loadCustomStatuses,
);

const makeSelectLoadTransportationModeGroupingList = () => createSelector(
  selectLoadDetailsStore,
  ({ transportationModeGroupingList }: Object) => transportationModeGroupingList,
);

const makeSelectPostedShipmentsData = () => createSelector(
  selectLoadDetailsStore,
  ({ postedShipmentsData }: Object) => postedShipmentsData,
);

const makeSelectClaimGeneralDetails = () => createSelector(
  selectLoadDetailsStore,
  ({ claimGeneralDetails }: Object) => (
    R.equals(R.length(claimGeneralDetails), 1) ? R.head(claimGeneralDetails) : claimGeneralDetails
  ),
);

const makeSelectIsAnyEnabledCrossBorder = () => createSelector(
  selectLoadDetailsStore,
  ({ isAnyEnabledCrossBorder }: Object) => isAnyEnabledCrossBorder,
);

export {
  makeSelectLists,
  makeSelectEvents,
  makeSelectCharges,
  makeSelectLoadGuid,
  makeSelectMailList,
  makeSelectPinnedNote,
  makeSelectHiddenTabs,
  makeSelectLoadDetails,
  makeSelectLoadConfigs,
  makeSelectActiveLists,
  makeSelectNotification,
  makeSelectExpandedLists,
  makeSelectLoadDocuments,
  makeSelectMessageCenter,
  makeSelectActiveListNames,
  makeSelectLoadTotalWeight,
  makeSelectLinkedOrderList,
  makeSelectRelatedOrderList,
  makeSelectMessageCenterList,
  makeSelectLoadInvoiceTotals,
  makeSelectLoadTotalDistance,
  makeSelectCloEventReferences,
  makeSelectLoadCustomStatuses,
  makeSelectPostedShipmentsData,
  makeSelectClaimGeneralDetails,
  makeSelectTelDocumentTemplates,
  makeSelectSelectedCustomerRate,
  makeSelectDeclineReasonOptions,
  makeSelectStatusMessageConfigs,
  makeSelectDispatchPlannerOpened,
  makeSelectLinkedOrdersDocuments,
  makeSelectIsAnyEnabledCrossBorder,
  makeSelectRelatedOrderListLoading,
  makeSelectSelectedCarrierDriverRate,
  makeSelectDispatchPlannerEventsOpened,
  makeSelectSelectedCarrierDriverRateGuid,
  makeSelectLoadTransportationModeGroupingList,
};
