import React from 'react';
import * as R from 'ramda';
// ui
import { Box, Flex } from '../../../ui';
// feature dashboards
import Scorecard from './scorecard';
import PieChartComponent from './pie-chart';
import Composed2LineChart from './composed-2-line-chart';
import RadialBarChartComponent from './radial-bar-chart';
//////////////////////////////////////////////////

const Chart = (props: Object) => {
  const { chartType } = props;

  if (R.equals(chartType, 'PIE')) {
    return <PieChartComponent {...props} />;
  }

  if (R.equals(chartType, 'RADIAL_BAR')) {
    return <RadialBarChartComponent {...props} />;
  }

  if (R.equals(chartType, 'SCORE')) {
    return <Scorecard {...props} />;
  }

  if (R.equals(chartType, '2_LINE')) {
    return <Composed2LineChart {...props} cardType='middle' />;
  }

  return null;
};

const MiddleCard = (props: Object) => {
  const { mb, title, height, chartType } = props;

  return (
    <Flex
      p={15}
      bg='white'
      width={520}
      mb={mb || 20}
      borderRadius={10}
      alignItems='start'
      height={height || 375}
      flexDirection='column'
      justifyContent={R.equals(chartType, 'SCORE') ? null : 'space-between'}
    >
      <Box
        fontSize={14}
        fontWeight='bold'
        color='dark.mainDark'
      >
        {title}
      </Box>
      <Chart {...props} />
    </Flex>
  );
};

export default MiddleCard;
