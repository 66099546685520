import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { SelectCarriers } from '../../../components/select-carriers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature components-story
import { setSelectedCarriers } from '../actions';
import { makeSelectSelectedCarriers } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const SelectCarriersComponent = ({ selectedCarriers, setSelectedCarriers }: Object) => {
  const description = '<SelectCarriers /> component is a multi-select field for selecting carriers.';

  const usage = (
    <ul>
      <li>Parameter `<i>fieldName</i>` sets the name of the field.</li>
      <li>Parameter `<i>mr</i>` applies a margin right to the component.</li>
      <li>Parameter `<i>isLoading</i>` is a boolean indicating loading status.</li>
      <li>Parameter `<i>value</i>` is a current selected value(s) of the carrier(s).</li>
      <li>Parameter `<i>placeholder</i>` is a placeholder text when no carrier is selected.</li>
      <li>Parameter `<i>carriers</i>` is an array of carrier objects to be displayed as options.</li>
      <li>Parameter `<i>disabled</i>` is a boolean indicating whether the component is disabled.</li>
      <li>Parameter `<i>clearable</i>`  is a boolean indicating whether the selection can be cleared.</li>
      <li>Parameter `<i>multi</i>` is a boolean indicating whether it allows multiple carrier selections.</li>
      <li>Parameter `<i>additionalBorderColor</i>`, `<i>useAdditionalBorderColor</i>` styling props for border color.</li>
      <li>Parameter `<i>width</i>`, `<i>height</i>`, `<i>overflow</i>` and `<i>maxHeight</i>`  are dimensions and styles for the component.</li>
    </ul>
  );

  const handleSetFieldValue = (fieldName: string, data: Array) => {
    setSelectedCarriers({ fieldName, data });
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='SelectCarriers'
      description={description}
      path='src/components/select-carriers'
    >
      <SelectCarriers
        width={200}
        isMulti={true}
        isClearable={true}
        borderRadius='4px'
        useAdditionalBorderColor={true}
        fieldName={GC.FIELD_CARRIER_GUIDS}
        setFieldValue={handleSetFieldValue}
        additionalBorderColor={G.getTheme('colors.dark.grey')}
        value={R.prop(GC.FIELD_CARRIER_GUIDS, selectedCarriers)}
      />
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  selectedCarriers: makeSelectSelectedCarriers(state),
});

export default connect(mapStateToProps, {
  setSelectedCarriers,
})(SelectCarriersComponent);
