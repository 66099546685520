import * as R from 'ramda';
import { lifecycle } from 'react-recompose';
//////////////////////////////////////////////////

export const withBranchAndAccessorialConfigs = lifecycle({
  componentDidMount() {
    const { branchConfigs, accessorialConfigs, getBranchConfigsRequest, getAccessorialConfigListRequest } = this.props;

    if (R.isNil(branchConfigs)) getBranchConfigsRequest();

    if (R.isNil(accessorialConfigs)) getAccessorialConfigListRequest();
  },
});
