import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
// components
import { ActionBox } from '../../../../components/action-box';
import { PageTitle } from '../../../../components/page-title';
import { FormSectionHeader } from '../../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_VARIABLE_TYPE_OPTIONS,
  getCustomerContractUpliftApplyToEnumOptions,
} from '../../../../helpers/options';
// ui
import { Box, Flex, ActionButton, scrollableContainerCss4px } from '../../../../ui';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature carrier-profile
import FormGroupTable from '../../components/form-group-table';
import { customerContractVariableDiscountSettings } from '../../settings';
import {
  makeSelectRoleOptions,
  makeSelectAccessorialConfigs,
  makeSelectAvailableCarrierIntegrations,
  makeSelectTransportationModeOptionsForContracts,
} from '../../selectors';
//////////////////////////////////////////////////

const minMaxValidation = G.yupNumberNotRequired
  .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
  .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999));

const validationSchema = Yup.lazy((values: Object) => {
  const { scope, uplift, discount, assessorialExclusion } = values;

  const { rate, discountType, variableType } = R.or(discount, {});

  const { upliftValue } = R.or(uplift, {});

  const schema = {
    [GC.FIELD_NAME]: G.yupStringRequired,
    [GC.FIELD_MODE]: G.yupStringRequired,
    [GC.FIELD_CURRENCY]: G.yupStringRequired,
    [GC.FIELD_EFFECTIVE_DATE]: G.yupStringRequired,
    [GC.FIELD_EXPIRATION_DATE]: G.yupStringRequired,
    [GC.FIELD_CARRIER_GUIDS]: G.ifElse(R.equals(scope, 'SPECIFIC_CARRIERS'), G.yupArrayRequired),
    [GC.FIELD_CUSTOMER_CONTRACT_CARRIER_CONTRACT_GUIDS]: G.ifElse(
      R.equals(scope, 'SPECIFIC_CONTRACTS'),
      G.yupArrayRequired,
    ),
    [GC.FIELD_CUSTOMER_CONTRACT_CARRIER_EXCLUDED_ASSESSORIALS]: G.ifElse(
      R.equals(assessorialExclusion, 'SELECTED'),
      G.yupArrayRequired,
    ),
    [GC.FIELD_CHARGE_DISCOUNT]: Yup.object().nullable(true).shape({
      [GC.FIELD_RATE]: minMaxValidation,
      [GC.FIELD_VARIABLE_TYPE]: G.ifElse(
        R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE),
        G.yupStringRequired,
      ),
      [GC.FIELD_RATE_UNIT]: G.ifElse(
        R.and(R.equals(discountType, GC.DISCOUNT_TYPE_FLAT), G.isNotNilAndNotEmpty(rate)),
        G.yupStringRequired,
      ),
      [GC.FIELD_VARIABLE_UNIT]: G.ifElse(
        R.and(
          R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE),
          R.includes(
            variableType,
            [GC.CHARGE_RATE_TYPE_WEIGHT, GC.CHARGE_RATE_TYPE_DISTANCE],
          ),
        ),
        G.yupStringRequired,
      ),
    }),
    [GC.FIELD_UPLIFT]: Yup.object().nullable(true).shape({
      [GC.FIELD_MIN_UPLIFT]: minMaxValidation,
      [GC.FIELD_MAX_UPLIFT]: minMaxValidation,
      [GC.FIELD_UPLIFT_VALUE]: minMaxValidation,
      [GC.FIELD_RATE_UNIT]: G.ifElse(G.isNotNilAndNotEmpty(upliftValue), G.yupStringRequired),
    }),
  };

  return Yup.object().shape(schema);
});

const inputWrapperStyles = {
  mr: 20,
  width: 220,
};

const closeMenuOnScroll = R.pathEq('customer_contract_wrapper', ['target', 'id']);

const sections = [
  [
    {
      type: 'toggle',
      label: ['titles:active'],
      inputWrapperStyles: { mr: 20 },
      fieldName: GC.FIELD_CARRIER_ACTIVE,
    },
    {
      type: 'toggle',
      inputWrapperStyles: { mr: 20 },
      label: ['titles:use-carrier-contract-awarded-lines'],
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_USE_AWARDED_LINES,
    },
    {
      type: 'toggle',
      inputWrapperStyles: { mr: 20 },
      label: ['titles:use-carrier-price-for-trip-rate'],
      fieldName: GC.FIELD_CUSTOMER_USE_CARRIER_PRICE_FOR_TRIP_RATE,
    },
    {
      type: 'toggle',
      label: ['titles:allow-interline-carriers'],
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_ALLOW_INTERLINE_CARRIERS,
    },
  ],
  [
    {
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:name'],
      fieldName: GC.FIELD_NAME,
    },
    {
      isRequired: true,
      isClearable: true,
      type: 'datePicker',
      inputWrapperStyles,
      justifyContent: 'space-between',
      label: ['titles:effective-date'],
      fieldName: GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE,
      maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
    },
    {
      isRequired: true,
      isClearable: true,
      type: 'datePicker',
      inputWrapperStyles,
      minDate: G.getCurrentDate(),
      label: ['titles:expiration-date'],
      fieldName: GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE,
      maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
    },
  ],
  [
    {
      isRequired: true,
      closeMenuOnScroll,
      inputWrapperStyles,
      type: 'reactSelect',
      label: ['titles:mode'],
      fieldName: GC.FIELD_MODE,
      useMenuPortalTarget: true,
      options: 'transportationModeOptions',
    },
    {
      type: 'select',
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:currency'],
      fieldName: GC.FIELD_CURRENCY,
      options: GC.CURRENCY_OPTIONS,
    },
    {
      isMulti: true,
      closeMenuOnScroll,
      inputWrapperStyles,
      type: 'reactSelect',
      options: 'roleOptions',
      label: ['titles:roles'],
      useMenuPortalTarget: true,
      fieldName: GC.FIELD_CARRIER_CONTRACT_ROLE_GUIDS,
    },
    {
      label: ['titles:min-rate'],
      fieldName: GC.FIELD_CHARGE_MIN_RATE,
      inputWrapperStyles: R.dissoc('mr', inputWrapperStyles),
    },
  ],
  [
    {
      type: 'select',
      inputWrapperStyles,
      label: ['titles:scope'],
      fieldName: GC.FIELD_SCOPE,
      options: [
        GC.EMPTY_OPTION_OBJECT,
        { value: 'ALL_CARRIERS', label: G.getWindowLocale('titles:all-carriers', 'All Carriers') },
        { value: 'SPECIFIC_CARRIERS', label: G.getWindowLocale('titles:specific-carriers', 'Specific Carriers') },
        { value: 'SPECIFIC_CONTRACTS', label: G.getWindowLocale('titles:specific-contracts', 'Specific Contracts') },
      ],
    },
    {
      isMulti: true,
      isRequired: true,
      closeMenuOnScroll,
      type: 'reactSelect',
      options: 'carrierOptions',
      useMenuPortalTarget: true,
      label: ['titles:carriers'],
      fieldName: GC.FIELD_CARRIER_GUIDS,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq('SPECIFIC_CARRIERS', ['values', GC.FIELD_CUSTOMER_CONTRACT_SCOPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      isMulti: true,
      isRequired: true,
      closeMenuOnScroll,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      options: 'carrierContractOptions',
      label: ['titles:carrier-contracts'],
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_CONTRACT_GUIDS,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq('SPECIFIC_CONTRACTS', ['values', GC.FIELD_CUSTOMER_CONTRACT_SCOPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'select',
      inputWrapperStyles,
      label: ['titles:assessorial-exclusion'],
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_ASSESSORIAL_EXCLUSION,
      options: [
        GC.EMPTY_OPTION_OBJECT,
        { value: 'NONE', label: G.getWindowLocale('titles:none', 'None') },
        { value: 'ALL', label: G.getWindowLocale('titles:all', 'All') },
        { value: 'SELECTED', label: G.getWindowLocale('titles:selected', 'Selected') },
      ],
    },
    {
      isMulti: true,
      isRequired: true,
      closeMenuOnScroll,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      options: 'accessorialConfigOptions',
      label: ['titles:excluded-assessorials'],
      fieldName: GC.FIELD_CUSTOMER_CONTRACT_CARRIER_EXCLUDED_ASSESSORIALS,
      reactSelectAdditionalStyles: {
        menu: (baseStyles: Object) => ({
          ...baseStyles,
          zIndex: 15,
        }),
      },
      inputWrapperStyles: {
        ...inputWrapperStyles,
        mr: 0,
        display: (props: Object) => G.ifElse(
          R.pathEq('SELECTED', ['values', GC.FIELD_CUSTOMER_CONTRACT_CARRIER_ASSESSORIAL_EXCLUSION], props),
          'block',
          'none',
        ),
      },
    },
  ],
  [
    {
      type: 'select',
      inputWrapperStyles,
      label: ['titles:discount-type'],
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_DISCOUNT_TYPE}`,
      options: [
        GC.EMPTY_OPTION_OBJECT,
        { value: GC.DISCOUNT_TYPE_FLAT, label: G.getWindowLocale('titles:flat', 'Flat') },
        { value: GC.DISCOUNT_TYPE_VARIABLE, label: G.getWindowLocale('titles:variable', 'Variable') },
      ],
    },
    {
      label: ['titles:discount-rate'],
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_RATE}`,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'select',
      label: ['titles:discount-rate-unit'],
      options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_RATE_UNIT}`,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        mr: '0px',
        display: (props: Object) => G.ifElse(
          R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'select',
      shouldCustomChange: true,
      label: ['titles:variable-type'],
      justifyContent: 'space-between',
      options: DEFAULT_DISCOUNT_VARIABLE_TYPE_OPTIONS,
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_VARIABLE_TYPE}`,
      customChangeHandler: (event: Object, _: any, { handleChange, setFieldValue }: Object) => {
        handleChange(event);
        setFieldValue(`${GC.FIELD_DISCOUNT}.${GC.FIELD_VARIABLE_UNIT}`, '');
      },
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq(GC.DISCOUNT_TYPE_VARIABLE, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'select',
      isRequired: true,
      options: 'variableUnitOptions',
      label: ['titles:variable-unit'],
      fieldName: `${GC.FIELD_DISCOUNT}.${GC.FIELD_VARIABLE_UNIT}`,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.and(
            R.pathEq(GC.DISCOUNT_TYPE_VARIABLE, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
            R.includes(
              R.path(['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_VARIABLE_TYPE], props),
              [GC.CHARGE_RATE_TYPE_WEIGHT, GC.CHARGE_RATE_TYPE_DISTANCE],
            ),
          ),
          'block',
          'none',
        ),
      },
    },
  ],
];

const upliftFieldSettings = [
  {
    inputWrapperStyles,
    label: ['titles:uplift-value'],
    fieldName: `${GC.FIELD_UPLIFT}.${GC.FIELD_UPLIFT_VALUE}`,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:rate-unit'],
    options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
    fieldName: `${GC.FIELD_UPLIFT}.${GC.FIELD_RATE_UNIT}`,
  },
  {
    inputWrapperStyles,
    label: ['titles:min-value'],
    fieldName: `${GC.FIELD_UPLIFT}.${GC.FIELD_MIN_UPLIFT}`,
  },
  {
    label: ['titles:max-value'],
    inputWrapperStyles: R.dissoc('mr', inputWrapperStyles),
    fieldName: `${GC.FIELD_UPLIFT}.${GC.FIELD_MAX_UPLIFT}`,
  },
  {
    type: 'select',
    label: ['titles:apply-to'],
    fieldName: `${GC.FIELD_UPLIFT}.${GC.FIELD_APPLY_TO}`,
    inputWrapperStyles: { ...inputWrapperStyles, mt: 25 },
    options: G.prependEmptyLabelValueOption(getCustomerContractUpliftApplyToEnumOptions()),
  },
];

const submitStyles = {
  height: 32,
  width: 120,
  p: '4px 8px',
  color: 'white',
  bg: 'dark.blue',
  display: 'block',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  m: '20px 20px 0px auto',
  textTransform: 'uppercase',
};

const variableUnitOptionsMap = {
  [GC.CHARGE_RATE_TYPE_WEIGHT]: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_DISTANCE]: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
};

const GeneralDetails = (props: Object) => {
  const {
    carrierName,
    submitAction,
    initialValues,
    handleGoToCarrier,
    carrierBranchGuid,
  } = props;

  const [carrierOptions, setCarrierOptions] = useState(null);
  const [carrierContractOptions, setCarrierContractOptions] = useState(null);

  const roleOptions = useSelector(makeSelectRoleOptions());
  const accessorialConfigs = useSelector(makeSelectAccessorialConfigs());
  const availableCarrierIntegrationOptions = useSelector(makeSelectAvailableCarrierIntegrations());
  const transportationModeOptions = useSelector(makeSelectTransportationModeOptionsForContracts());

  const onSubmit = useCallback((values: Object, { setSubmitting }: Object) => {
    let data = G.mapObjectEmptyStringFieldsToNull(values);

    const discountType = R.path([GC.FIELD_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], values);

    if (G.isNilOrEmpty(discountType)) {
      data = R.assoc(GC.FIELD_DISCOUNT, null, data);
    } else {
      const discount = R.prop(GC.FIELD_DISCOUNT, values);

      if (R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE)) {
        data.discount = R.omit([GC.FIELD_RATE, GC.FIELD_RATE_UNIT], discount);
      }

      if (R.equals(discountType, GC.DISCOUNT_TYPE_FLAT)) {
        data.discount = R.omit(
          [GC.FIELD_VARIABLE_TYPE, GC.FIELD_VARIABLE_UNIT, GC.FIELD_PAYROLL_VARIABLE_VALUES],
          discount,
        );
      }
    }

    submitAction(G.mapObjectEmptyStringFieldsToNull(data), { setSubmitting });
  }, [submitAction]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      [GC.FIELD_DISCOUNT]: {
        [GC.FIELD_RATE]: '',
        [GC.FIELD_RATE_UNIT]: '',
        [GC.FIELD_VARIABLE_TYPE]: '',
        [GC.FIELD_VARIABLE_UNIT]: '',
        [GC.FIELD_DISCOUNT_TYPE]: '',
        [GC.FIELD_PAYROLL_VARIABLE_VALUES]: [],
      },
      ...G.ifElse(
        R.isNil(R.prop(GC.FIELD_DISCOUNT, initialValues)),
        R.dissoc(GC.FIELD_DISCOUNT, initialValues),
        initialValues,
      ),
    },
  });

  const { values, setValues, isSubmitting, handleSubmit } = formik;

  const { name, active, carrierGuid } = initialValues;

  const { scope } = values;

  const variableType = R.path([GC.FIELD_DISCOUNT, GC.FIELD_VARIABLE_TYPE], values);
  const variableUnitOptions = R.propOr([], variableType, variableUnitOptionsMap);
  const variableValues = R.pathOr([], [GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_PAYROLL_VARIABLE_VALUES], values);

  const discountTypeVariable = R.pathEq(
    GC.DISCOUNT_TYPE_VARIABLE,
    [GC.CHARGE_TYPE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE],
    values,
  );

  useEffect(() => {
    if (R.or(
      R.and(R.isNil(carrierOptions), R.equals(scope, 'SPECIFIC_CARRIERS')),
      R.and(R.isNil(carrierContractOptions), R.equals(scope, 'SPECIFIC_CONTRACTS')),
    )) {
      const getOptionsByScopeRequest = async () => {
        const map = {
          'SPECIFIC_CARRIERS': {
            method: 'get',
            setOptions: setCarrierOptions,
            endpointName: 'carrierListForContract',
            params: { [GC.FIELD_BRANCH_GUID]: carrierBranchGuid },
          },
          'SPECIFIC_CONTRACTS': {
            method: 'post',
            params: { carrierGuid },
            setOptions: setCarrierContractOptions,
            endpointName: 'carrierContractsByCarrierGuid',
          },
        };

        const { method, params, setOptions, endpointName } = map[scope];

        const res = await sendRequest(method, R.prop(endpointName, endpointsMap), { params });

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          const options = G.mapNameGuidToLabelValue(data);

          setOptions(options);
        } else {
          G.handleFailResponseSimple(res, false, 'getOptionsByScopeRequest fail');
        }
      };

      getOptionsByScopeRequest();
    }
  }, [scope, carrierOptions, carrierContractOptions]);

  return (
    <Fragment>
      <Flex>
        <PageTitle
          mr={20}
          title={name}
          status={active}
          showStatus={true}
          display='inline-flex'
        />
        {G.getWindowLocale('titles:carrier', 'Carrier')}:
        <ActionBox
          text={carrierName}
          action={() => handleGoToCarrier()}
          boxStyles={{ ml: 10, maxWidth: 300, fontWeight: 'bold' }}
        />
      </Flex>
      <Box pb={20} mb={20} bg='white' maxWidth={980}>
        <form onSubmit={handleSubmit} id='customer_contract_general_details'>
          <Box overflowX='auto' css={scrollableContainerCss4px}>
            <FormSectionHeader
              expanded={true}
              additionalStyles={{ width: 980 }}
              title={G.getWindowLocale('titles:general', 'General')}
            >
              {
                sections.map((fieldSettings: Array, index: number) => (
                  <Fieldset2
                    {...G.getFormikPropsToFieldset(formik)}
                    {...accessorialConfigs}
                    key={index}
                    fields={fieldSettings}
                    roleOptions={roleOptions}
                    carrierOptions={R.or(carrierOptions, [])}
                    variableUnitOptions={variableUnitOptions}
                    transportationModeOptions={transportationModeOptions}
                    carrierContractOptions={R.or(carrierContractOptions, [])}
                    availableCarrierIntegrationOptions={availableCarrierIntegrationOptions}
                    fieldsWrapperStyles={{
                      px: 20,
                      width: 980,
                      pt: G.ifElse(G.isZero(index), 20, 25),
                      pb: G.ifElse(R.equals(index, 4), 25, '0px'),
                    }}
                  />
                ))
              }
              {
                discountTypeVariable &&
                <FormGroupTable
                  {...props}
                  p='0 20px'
                  setValues={setValues}
                  itemList={variableValues}
                  group={customerContractVariableDiscountSettings}
                />
              }
            </FormSectionHeader>
            <FormSectionHeader
              expanded={true}
              title={G.getWindowLocale('titles:uplift', 'Uplift')}
              additionalStyles={{ width: 980, mt: G.ifElse(discountTypeVariable, 25) }}
            >
              <Fieldset2
                {...G.getFormikPropsToFieldset(formik)}
                fields={upliftFieldSettings}
                fieldsWrapperStyles={{ width: 980, flexWrap: 'wrap', p: '25px 20px 0 20px' }}
              />
            </FormSectionHeader>
          </Box>
          <ActionButton
            {...submitStyles}
            disabled={R.or(isSubmitting, R.equals(values, initialValues))}
          >
            {G.getWindowLocale('actions:update', 'Update')}
          </ActionButton>
        </form>
      </Box>
    </Fragment>
  );
};

export default GeneralDetails;
