import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature components story
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  activeTab: 0,
  switchValue: 1,
  inputValue: '',
  searchFilter: '',
  progressValue: 0,
  filterParam: null,
  showLoader: false,
  showHideText: false,
  toggleBtnValue: false,
  showLocalLoader: false,
  datepickerMuiValue: null,
  colorPickerValue: '#E15141',
  dateRangeValues: { dateTo: null, dateFrom: null },
  selectedCarriers: { [GC.FIELD_CARRIER_GUIDS]: [] },
  collapsedGroup: { users: true, companyDetail: true },
  dateRangeMuiValues: { dateTo: null, dateFrom: null },
  dateSelectorValues: { dateTo: null, dateFrom: null, quickDaysValue: null },
  additionalFilters: { additionalFilterType: null, additionalFilterValue: null },
  chatMessages: [
    {
      authorLastName: 'Dou',
      authorFirstName: 'Jon',
      authorLoginId: 'JonDou',
      text: 'Hello, how can I help you?',
    },
  ],
  filterProps: [
    {
      'name': 'Name',
      'type': 'string',
      'value': 'accountName',
    },
    {
      'type': 'string',
      'name': 'Password',
      'value': 'password',
    },
  ],
  userList: [
    {
      'index': 0,
      'guid': '1',
      'selected': false,
      'password': 'qwerty',
      'accountName': 'Admin',
    },
    {
      'index': 1,
      'guid': '2',
      'selected': false,
      'accountName': 'User',
      'password': 'password',
    },
  ],
  userColumnSettings: {
    [GC.FIELD_ACCOUNT_NAME]: {
      width: 120,
      type: 'string',
      searchable: false,
      name: 'titles:accountName',
    },
    [GC.FIELD_PASSWORD]: {
      width: 120,
      type: 'string',
      searchable: false,
      name: 'titles:password',
    },
  },
  messageCenterValues: [
    {
      guid: 1,
      pinned: true,
      createdBy: 'User',
      text: 'First test note',
      createdDate: '11/17/2023 4:43:23 PM',
    },
    {
      guid: 2,
      pinned: false,
      createdBy: 'User',
      text: 'Second test note',
      createdDate: '11/17/2023 4:45:10 PM',
    },
  ],
  report: {
    'guid': '0',
    'version': 1,
    'prompt': true,
    'owner': 'User',
    'roleGuids': [],
    'groupBy': null,
    'description': '',
    'viewType': 'LIST',
    'createdBy': 'User',
    'showIssues': false,
    'type': 'MOCK_TYPE',
    'customerGuids': [],
    'summaryGroup': null,
    'showLastDays': null,
    'enterpriseGuid': '2',
    'name': 'mock_report',
    'defaultReport': true,
    'pinnedReport': false,
    'summarySubgroup': null,
    'lastModifiedBy': 'User',
    'pinnedReportIndex': null,
    'createdDate': '12/13/2023 11:57:26',
    'avoidRelatedValueDuplicates': false,
    'lastModifiedDate': '12/13/2023 12:07:55',
    'orderFields': [
      {
        'guid': '1',
        'sequence': 1,
        'order': 'DESC',
        'reference': false,
        'name': 'accountName',
      },
    ],
    'fields': [
      {
        'guid': '5',
        'sequence': 1,
        'freezed': false,
        'reference': false,
        'collection': false,
        'name': 'accountName',
      },
      {
        'guid': '6',
        'sequence': 2,
        'freezed': false,
        'name': 'password',
        'reference': false,
        'collection': false,
      },
    ],
    'searchCriteria': [
      {
        'last': 0,
        'next': 0,
        'to': null,
        'from': null,
        'guid': '555',
        'groupId': null,
        'timeUnit': null,
        'stringRange': null,
        'numberValue': null,
        'numberRange': null,
        'booleanValue': null,
        'dataType': 'string',
        'dateRelative': false,
        'referenceName': null,
        'operation': 'contain',
        'referenceValue': null,
        'stringValue': 'Admin',
        'propertyName': 'accountName',
      }],
  },
};

export const setSearchFilter = (state: Object, search: string) => (
  P.$set('searchFilter', search, state)
);

export const setActiveTab = (state: Object, index: number) => (
  P.$set('activeTab', index, state)
);

export const setDatepickerMuiValue = (state: Object, value: Object) => (
  P.$set('datepickerMuiValue', value, state)
);

export const setAdditionalFilters = (state: Object, value: Object) => (
  P.$set('additionalFilters', value, state)
);

export const setShowHideText = (state: Object) => (
  P.$toggle('showHideText', state)
);

export const setColorPickerValue = (state: Object, value: Object) => (
  P.$set('colorPickerValue', value, state)
);

export const setChatMessages = (state: Object, value: Object) => (
  P.$set('chatMessages', R.append(value, R.prop('chatMessages', state)), state)
);

export const setDateRangeValues = (state: Object, dateRangeValues: Object) => (
  P.$set('dateRangeValues', dateRangeValues, state)
);

export const setDateRangeMuiValues = (state: Object, dateRangeMuiValues: Object) => (
  P.$set('dateRangeMuiValues', dateRangeMuiValues, state)
);

export const setDateSelectorValues = (state: Object, dateSelectorValues: Object) => (
  P.$set('dateSelectorValues', dateSelectorValues, state)
);

export const setShowLoader = (state: Object) => (
  P.$toggle('showLoader', state)
);

export const setShowLocalLoader = (state: Object) => (
  P.$toggle('showLocalLoader', state)
);

export const setMessageCenterValues = (state: Object, message: Object) => (
  P.$set('messageCenterValues', R.append(message, R.prop('messageCenterValues', state)), state)
);

export const updateMessageCenterValue = (state: Object, message: Object) => {
  const index = R.findIndex(R.propEq(message.guid, 'guid'))(state.messageCenterValues);

  return P.$set(`messageCenterValues.${index}`, message, state);
};

export const deleteMessageCenterValue = (state: Object, guid: number) => (
  P.$drop(`messageCenterValues.${R.findIndex(R.propEq(guid, 'guid'))(state.messageCenterValues)}`, state)
);

export const pinOrUnpinMessageCenterValue = (state: Object, guid: number) => {
  const pinned = R.compose(
    R.map((item: Object) =>
      R.assoc('pinned', R.and(R.propEq(guid, GC.FIELD_GUID, item), G.isFalse(R.prop('pinned', item))),
        item)),
    R.prop('messageCenterValues'),
  )(state);

  return P.$set('messageCenterValues', pinned, state);
};

export const setProgressValue = (state: Object, progressValue: Object) => (
  P.$set('progressValue', progressValue, state)
);

export const setSelectedCarriers = (state: Object, selectedCarriers: Object) => (
  P.$set(`selectedCarriers.${R.prop('fieldName', selectedCarriers)}`, R.prop('data', selectedCarriers), state)
);

export const setSwitchValue = (state: Object, switchValue: number) => (
  P.$set('switchValue', switchValue, state)
);

export const setToggleBtnValue = (state: Object) => (
  P.$toggle('toggleBtnValue', state)
);

export const setReport = (state: Object, newReport: Object) => (
  P.$set('report', newReport, state)
);

export const setFilterParam = (state: Object, filterParam: Object) => (
  P.$set('filterParam', filterParam, state)
);

export const setInputValue = (state: Object, inputValue: string) => (
  P.$set('inputValue', inputValue, state)
);

export const setToggleFormGroup = (state: Object, groupName: string) => (
  P.$toggle(`collapsedGroup.${groupName}`, state)
);

export default createReducer({
  [A.setReport]: setReport,
  [A.setActiveTab]: setActiveTab,
  [A.setInputValue]: setInputValue,
  [A.setShowLoader]: setShowLoader,
  [A.setFilterParam]: setFilterParam,
  [A.setSwitchValue]: setSwitchValue,
  [A.setChatMessages]: setChatMessages,
  [A.setSearchFilter]: setSearchFilter,
  [A.setShowHideText]: setShowHideText,
  [A.setProgressValue]: setProgressValue,
  [A.setToggleBtnValue]: setToggleBtnValue,
  [A.setDateRangeValues]: setDateRangeValues,
  [A.setToggleFormGroup]: setToggleFormGroup,
  [A.setShowLocalLoader]: setShowLocalLoader,
  [A.setColorPickerValue]: setColorPickerValue,
  [A.setSelectedCarriers]: setSelectedCarriers,
  [A.setAdditionalFilters]: setAdditionalFilters,
  [A.setDateRangeMuiValues]: setDateRangeMuiValues,
  [A.setDateSelectorValues]: setDateSelectorValues,
  [A.setDatepickerMuiValue]: setDatepickerMuiValue,
  [A.setMessageCenterValues]: setMessageCenterValues,
  [A.updateMessageCenterValue]: updateMessageCenterValue,
  [A.deleteMessageCenterValue]: deleteMessageCenterValue,
  [A.pinOrUnpinMessageCenterValue]: pinOrUnpinMessageCenterValue,
}, initialState);
