import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import {
  sendInvoiceToBCByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import DriverInvoiceForm from '../../../invoice/components/driver-form';
// forms
import { TelInvoiceByCurrencyForm } from '../../../../forms/forms/tel-invoice-by-currency-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncEditDriverInvoice } from '../../../../hocs/with-async-edit-driver-invoice';
// feature dispatch details new
import * as H from '../../helpers';
import { PrintTelDocumentsForm } from '../../forms/print-form';
import {
  updateDriverInvoiceRequest,
  printLoadDriverInvoiceRequest,
  removeLoadDriverInvoiceRequest,
  getLoadDriverInvoiceListRequest,
  sendDriverInvoiceToFinancialRequest,
} from '../actions';
//////////////////////////////////////////////////

export const withAddDriverInvoice = compose(
  withHandlers({
    handleAddDriverInvoice: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        loadTotalWeight,
        loadTotalDistance,
        selectedCarrierDriverRate,
        createDriverInvoiceRequest,
        createFleetInvoiceByTelGuidRequest,
        loadConfigs: { configGroups, configsByNames },
      } = props;

      const branchGuid = G.getBranchGuidFromObject(load);
      const telGuid = G.getGuidFromObject(load);
      const totalCustomersRate = H.getTotalCustomerRatesFromClos(R.prop(GC.SYSTEM_LIST_CLOS, load));
      const configs = R.path([GC.INVOICE_CONFIG_GROUP], configGroups);

      const defaultNetDays = G.getConfigValueFromStore(
        GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_NET_DAYS,
        configs,
      );

      const defaultStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const defaultSecondInvoiceStatus = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SECOND_DRIVER_INVOICE_DEFAULT_STATUS,
        configs,
      );

      const defaultInvoiceName = R.compose(
        R.pathOr('invoice', [GC.FIELD_VALUE]),
        R.find(R.propEq(true, GC.FIELD_PRIMARY)),
        R.prop(GC.FIELD_REFERENCES),
      )(load);

      const separateByCurrency = G.getConfigValueFromStore(
        GC.INVOICE_TEL_SEPARATE_PAYMENT_BY_CURRENCY,
        configs,
      );

      let modal;

      if (separateByCurrency) {
        const component = (
          <TelInvoiceByCurrencyForm
            telGuid={telGuid}
            closeModal={closeModal}
            submitAction={(data: Object) => createFleetInvoiceByTelGuidRequest({ data, fromPage: 'details' })}
          />
        );

        modal = G.getDefaultModalOptions(
          component,
          G.getWindowLocale('titles:create-trip-invoices', 'Create Trip Invoices'),
        );
      } else {
        const handleSendDriverInvoice = (values: Object) => {
          const data = R.assoc(GC.FIELD_TEL_GUID, telGuid, values);

          createDriverInvoiceRequest(data);
        };

        const component = (
          <DriverInvoiceForm
            tel={load}
            telGuid={telGuid}
            branchGuid={branchGuid}
            closeModal={closeModal}
            defaultStatus={defaultStatus}
            totalWeight={loadTotalWeight}
            defaultNetDays={defaultNetDays}
            totalDistance={loadTotalDistance}
            defaultInvoiceName={defaultInvoiceName}
            totalCustomersRate={totalCustomersRate}
            handleSendFleetInvoice={handleSendDriverInvoice}
            defaultSecondInvoiceStatus={defaultSecondInvoiceStatus}
            sequenceConfigName={GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_SEQUENCE}
            defaultUomFields={H.getDefaultUomFieldsFromConfigs(configsByNames)}
            autogenerateConfigName={GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_AUTOGENERATED}
            selectedRate={G.makeTelRateWithCorrectChargesForFleetVendorInvoice(selectedCarrierDriverRate)}
          />
        );

        modal = {
          p: '0',
          component,
          options: {
            width: 900,
            height: 'auto',
            overflow: 'auto',
            maxHeight: '90vh',
          },
        };
      }

      openModal(modal);
    },
  }),
  pure,
);

export const withLoadDriverInvoiceTableGroupRowActions = compose(
  connect(
    null,
    {
      updateDriverInvoiceRequest,
      printLoadDriverInvoiceRequest,
      removeLoadDriverInvoiceRequest,
      getLoadDriverInvoiceListRequest,
      sendDriverInvoiceToFinancialRequest,
      sendInvoiceToBCByInvoiceTypeRequest,
      sendInvoiceToSageByInvoiceTypeRequest,
    },
  ),
  withAsyncEditDriverInvoice({ updateFrom: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD }),
  withHandlers({
    handleSendDriverInvoiceToFinancial: ({ entity, sendDriverInvoiceToFinancialRequest }: Object) => () =>
      sendDriverInvoiceToFinancialRequest({
        guids: R.of(Array, G.getGuidFromObject(entity)),
        [GC.FIELD_TYPE]: G.getPropFromObject(GC.FIELD_TYPE, entity),
      }),
    handleSendDriverOrVendorInvoiceToSage: (props: Object) => () => {
      const { entity, closeFixedPopup, sendInvoiceToSageByInvoiceTypeRequest } = props;

      G.callFunction(closeFixedPopup);

      const invoiceType = G.ifElse(
        G.isInvoiceTypeFleetVendorInvoice(G.getPropFromObject(GC.FIELD_TYPE, entity)),
        'vendorInvoice',
        'driverInvoice',
      );

      sendInvoiceToSageByInvoiceTypeRequest({
        invoiceType,
        guids: R.of(Array, G.getGuidFromObject(entity)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleSendVendorInvoiceToBC: (props: Object) => () => {
      const {
        load,
        entity,
        closeFixedPopup,
        getLoadDriverInvoiceListRequest,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const guids = R.of(Array, G.getGuidFromObject(entity));
      const callback = () => getLoadDriverInvoiceListRequest({ loadGuid: G.getGuidFromObject(load) });

      sendInvoiceToBCByInvoiceTypeRequest({
        guids,
        callback,
        invoiceType: 'vendorInvoice',
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      });
    },
    handleCreateQBIIFImportDriverInvoice: (props: Object) => () => {
      const {
        load,
        entity,
        getLoadDriverInvoiceListRequest,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      const invoiceType = G.ifElse(
        G.isInvoiceTypeFleetVendorInvoice(G.getPropFromObject(GC.FIELD_TYPE, entity)),
        'vendor',
        'driver',
      );

      const callback = () =>
        getLoadDriverInvoiceListRequest({ [GC.FIELD_LOAD_GUID]: G.getGuidFromObject(load) });

      const data = {
        callback,
        type: invoiceType,
        guids: R.of(Array, G.getGuidFromObject(entity)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      };

      createQBIIFImportByInvoiceTypeRequest(data);
    },
    handleRemoveDriverInvoice: (props: Object) => () => {
      const { openModal, entity: { guid, type }, removeLoadDriverInvoiceRequest } = props;

      const textLocale = G.getWindowLocale(
        'messages:delete-confirmation-invoice',
        'Do you want to delete this Invoice?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeLoadDriverInvoiceRequest({ guid, type }),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handlePrintDriverInvoice: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        printLoadDriverInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);

      const component = (
        <PrintTelDocumentsForm
          selectedEntity={guid}
          branchGuid={branchGuid}
          closeModal={closeModal}
          telGuid={G.getGuidFromObject(load)}
          submitAction={printLoadDriverInvoiceRequest}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE}
        />
      );

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
    handlePrintDriverInvoiceWithImages: (props: Object) => () => {
      const {
        load,
        entity,
        openModal,
        closeModal,
        loadDocuments,
        printLoadDriverInvoiceRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);
      const branchGuid = G.getBranchGuidFromObject(load);
      const documentsOptions = G.getPrintDocumentOptions(loadDocuments);

      const printInvoiceProps = {
        closeModal,
        branchGuid,
        documentsOptions,
        withDocuments: true,
        selectedEntity: guid,
        submitAction: printLoadDriverInvoiceRequest,
        [GC.FIELD_TEL_GUID]: G.getGuidFromObject(load),
        printableSection: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE.anchor,
      };

      const component = <PrintTelDocumentsForm {...printInvoiceProps} />;

      const modal = G.getDefaultModalOptions(
        component,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
  }),
);
