import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// helpers/constants
import * as GC from '../../../../constants';
// sagas
import { visitPageSaga } from '../../../../sagas';
// feature configs
import { getConfigByGroupSaga, getSequencesByTypeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

function* handleVisitConfigFleetGeneralPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_FLEET_GENERAL_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield call(getConfigByGroupSaga, GC.FLEET_GENERAL_CONFIG_GROUP);
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_FLEET });

    yield put(closeLoader());

    break;
  }
}

function* fleetGeneralConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_FLEET_GENERAL_PAGE, handleVisitConfigFleetGeneralPageSaga);
}

export default fleetGeneralConfigWatcherSaga;
