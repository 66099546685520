import { withProps } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const withDocumentAdditionalTableSettings = withProps(
  ({ tabsCollapsedView }: Object) => ({
    additionalTableSettings: {
      checkBoxCellWidth: 100,
      maxHeight: G.ifElse(tabsCollapsedView, 320, 'calc(100vh - 250px)'),
    },
  }),
);

export const withHeightProps = withProps(
  ({ tabsCollapsedView }: Object) => ({
    height: 'auto',
    minHeight: '450px',
    openedFromFleetProfile: true,
    maxHeight: 'calc(100vh - 200px)',
    tableMaxHeight: `calc(100vh - ${G.ifElse(tabsCollapsedView, 600, 390)}px)`,
  }),
);
