import React from 'react';
import * as R from 'ramda';
// feature fleet-profile/driver
import ReferenceFormGroupTable from '../components/reference';
//////////////////////////////////////////////////

export const referenceSettings = {
  groupName: 'references',
  Component: (props: Object) => (
    <ReferenceFormGroupTable
      {...R.pick(
        [
          'itemList',
          'fleetType',
          'openModal',
          'openLoader',
          'closeLoader',
          'primaryObjectGuid',
          'getItemListSuccess',
          'removeEntityRequest',
          'primaryObjectGuidKey',
          'createOrUpdateEntityRequest',
        ],
        props,
      )}
    />
  ),
};
