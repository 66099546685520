// helpers/constants
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const TRANSACTION_STOP_FIELDS = [
  GC.FIELD_ZIP,
  GC.FIELD_CITY,
  GC.FIELD_STATE,
  GC.FIELD_ADDRESS,
  GC.FIELD_COUNTRY,
  GC.FIELD_LATITUDE,
  GC.FIELD_LONGITUDE,
  GC.FIELD_TIME_ZONE,
  GC.FIELD_LOCATION_NAME,
];

export const RC = {
  TRUCK_UNIT_ID: `${GC.FIELD_TRUCK}.${GC.FIELD_TRUCK_UNIT_ID}`,
  FUEL_CARDS_LINES_UOM: `${GC.FIELD_FUEL_CARDS_LINES}.${GC.FIELD_FUEL_CARDS_UOM}`,
  FUEL_CARDS_LINES_QUANTITY: `${GC.FIELD_FUEL_CARDS_LINES}.${GC.FIELD_FUEL_CARDS_QUANTITY}`,
  FUEL_CARDS_TRANSACTION_STOP_CITY: `${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_CITY}`,
  FUEL_CARDS_TRANSACTION_STOP_STATE: `${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_STATE}`,
  FUEL_CARDS_LINES_PRODUCT_NAME: `${GC.FIELD_FUEL_CARDS_LINES}.${GC.FIELD_FUEL_CARDS_PRODUCT_NAME}`,
  FUEL_CARDS_TRANSACTION_STOP_COUNTRY: `${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_COUNTRY}`,
  FUEL_CARDS_TRANSACTION_STOP_ADDRESS: `${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_ADDRESS}`,
  FUEL_CARDS_TRANSACTION_STOP_LATITUDE: `${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_LATITUDE}`,
  FUEL_CARDS_TRANSACTION_STOP_LONGITUDE: `${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_LONGITUDE}`,
  FUEL_CARDS_TRANSACTION_STOP_LOCATION_NAME: `${GC.FIELD_FUEL_CARDS_TRANSACTION_STOP}.${GC.FIELD_LOCATION_NAME}`,
};

