import * as R from 'ramda';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import { withDocumentActions } from '../../hocs/with-document-actions';
import { withDocumentAdditionalTableSettings } from '../../hocs/with-report-common-props';
import { withBranchAndAccessorialConfigs } from '../../hocs/with-branch-and-accessorial-configs';
import {
  withPayrollMonthlyDeductionAndAccessorialDidUpdate,
} from '../../hocs/with-payroll-monthly-deduction-and-accessorial-did-update';
import {
  insuranceSettings,
  fleetAssignmentSettings,
  integrationInfoSettings,
  integrationAuditSettings,
} from '../settings';
import {
  getAccessorialConfigListRequest,
  getVendorPayrollChargesIsPaidOnPayrollRequest,
  getVendorPayrollAccessorialsIsPaidOnPayrollRequest,
} from '../../actions';
import {
  makeSelectAccessorialConfigs,
  makeSelectDocumentsTabEntities,
  makeSelectVendorGeneralTabEntities,
  makeSelectVendorPayrollTabEntities,
  makeSelectVendorRatingEngineTabEntities,
} from '../../selectors';
import {
  referenceSettings,
  vendorContactSettings,
  vendorDocumentSettings,
  vendorAccessorialSettings,
  vendorCompensationSettings,
  vendorPayToLocationsSettings,
  vendorPayrollAccessorialSettings,
  vendorMonthlyPayrollDeductionSettings,
} from '../../settings';
//////////////////////////////////////////////////

const withTabGroupSettings = (groupsSettings: Object) => compose(
  withProps((props: Object) => {
    const { entities, collapsedGroup } = props;

    const groups = R.map((item: Object) => {
      const { groupName, filterProps, getRemovePermissions, primaryObjectGuidKey = GC.FIELD_VENDOR_GUID } = item;

      return {
        ...item,
        primaryObjectGuidKey,
        isOpened: R.pathOr(true, [groupName], collapsedGroup),
        itemList: R.or(R.prop(groupName, entities), R.prop(groupName, props)),
        filterProps: G.isFunction(filterProps) ? filterProps(props) : filterProps,
        getRemovePermissions: G.isFunction(getRemovePermissions) ? (
          (item: Object) => getRemovePermissions(item, props)
        ) : getRemovePermissions,
      };
    }, groupsSettings);

    return { groups };
  }),
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectVendorGeneralTabEntities(state),
});

export const withGeneralSettings = compose(
  connect(mapStateToProps),
  withTabGroupSettings([
    fleetAssignmentSettings,
    vendorPayToLocationsSettings,
    vendorContactSettings,
    insuranceSettings,
    referenceSettings,
    integrationInfoSettings,
    integrationAuditSettings,
  ]),
  pure,
);

const documentsMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectDocumentsTabEntities(state),
});

export const withDocumentsSettings = compose(
  connect(documentsMapStateToProps),
  withTabGroupSettings([vendorDocumentSettings]),
  withDocumentAdditionalTableSettings,
  withDocumentActions,
  pure,
);

const ratingEngineMapStateToProps = (state: Object) => createStructuredSelector({
  accessorialConfigs: makeSelectAccessorialConfigs(state),
  entities: makeSelectVendorRatingEngineTabEntities(state),
});

export const withRatingEngineSettings = compose(
  connect(ratingEngineMapStateToProps, { getAccessorialConfigListRequest }),
  withTabGroupSettings([vendorAccessorialSettings, vendorCompensationSettings]),
  withBranchAndAccessorialConfigs,
  pure,
);

const payrollMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectVendorPayrollTabEntities(state),
  accessorialConfigs: makeSelectAccessorialConfigs(state),
});

export const withPayrollSettings = compose(
  connect(
    payrollMapStateToProps,
    {
      getAccessorialConfigListRequest,
      getPayrollChargesIsPaidOnPayrollRequest: getVendorPayrollChargesIsPaidOnPayrollRequest,
      getPayrollAccessorialsIsPaidOnPayrollRequest: getVendorPayrollAccessorialsIsPaidOnPayrollRequest,
    },
  ),
  withTabGroupSettings([vendorMonthlyPayrollDeductionSettings, vendorPayrollAccessorialSettings]),
  withBranchAndAccessorialConfigs,
  withPayrollMonthlyDeductionAndAccessorialDidUpdate,
  pure,
);
