import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const renderEndIcon = ({ itemList, isReport, singleItem, withoutAddition }: Object) => {
  if (R.or(G.isTrue(isReport), withoutAddition)) return null;

  if (R.or(R.isNil(singleItem), R.and(G.isTrue(singleItem), G.isNilOrEmpty(itemList)))) {
    const icon = I.plusRound(G.getTheme('icons.iconColorWhite'));

    return icon;
  }

  return null;
};

export const FormGroupTitle = (props: Object) => {
  const {
    itemList,
    isReport,
    groupName,
    singleItem,
    withoutAddition,
    isOpened = true,
    formGroupTitleArr,
    handleCreateOrUpdateItem,
    handleToggleFormGroupTable,
    AdditionalFormGroupTitleComponent,
  } = props;

  const text = G.getWindowLocale(...G.ifElse(G.isNotNilAndNotEmpty(formGroupTitleArr), formGroupTitleArr, []));

  return (
    <Flex
      pl={15}
      pr={16}
      minHeight={30}
      bg={G.getTheme('colors.mainBlue')}
    >
      <Flex width='max-content'>
        <Box px='5px' width='max-content' color={whiteColor}>{text}</Box>
        <Box mr='5px' cursor='pointer' onClick={handleCreateOrUpdateItem}>
          {renderEndIcon({ itemList, isReport, singleItem, withoutAddition })}
        </Box>
        {
          R.and(isOpened, G.isNotNil(AdditionalFormGroupTitleComponent)) &&
          <AdditionalFormGroupTitleComponent {...props} />
        }
      </Flex>
      <Box width='100%' onClick={() => handleToggleFormGroupTable(groupName)}>
        <Box ml='auto' width='max-content' transform={G.ifElse(isOpened, 'rotate(180deg)')}>
          {I.arrowDownSimple(whiteColor)}
        </Box>
      </Box>
    </Flex>
  );
};
