import React from 'react';
import * as R from 'ramda';
// components
import { InfoPair2 } from '../../../components/info-pair';
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Span } from '../../../ui';
// feature inspections
import ImagePreview from '../components/image-preview';
//////////////////////////////////////////////////

const orangeColor = G.getTheme('colors.orange');
const darkRedColor = G.getTheme('colors.darkRed');
const redColor = G.getTheme('colors.light.mainRed');
const yellowColor = G.getTheme('colors.light.yellow');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const priorityColorsMap = {
  [GC.PRIORITY_TYPE_LOW]: yellowColor,
  [GC.PRIORITY_TYPE_HIGH]: darkRedColor,
  [GC.PRIORITY_TYPE_MEDIUM]: orangeColor,
};

const DocumentsCell = ({ documents }: Object) => {
  if (G.isNilOrEmpty(documents)) return null;

  const PopperContent = () => (
    <Flex
      p={15}
      gap={15}
      flexWrap='wrap'
      maxHeight={445}
      flexDirection='column'
    >
      {
        documents.map((document: Object, i: number) => (
          <ImagePreview
            key={i}
            {...document}
            height={200}
          />
        ))
      }
    </Flex>
  );

  return (
    <Flex gap='5px'>
      {
        R.take(4, documents).map((document: Object, i: number) => (
          <ImagePreview
            key={i}
            {...document}
            height={35}
          />
        ))
      }
      <Box>({R.length(documents)})</Box>
      <PopperComponent type='click' content={PopperContent}>
        {I.nextShape(greyMatterhornColor)}
      </PopperComponent>
    </Flex>
  );
};

const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 250,
    name: 'titles:name',
  },
  failed: {
    width: 80,
    name: 'titles:passed',
    type: 'booleanReverse',
  },
  [GC.FIELD_TEMPLATE_DESCRIPTION]: {
    width: 450,
    name: 'titles:template-description',
  },
  [GC.FIELD_COMMENTS]: {
    width: 200,
    name: 'titles:comment',
  },
  [GC.FIELD_DOCUMENTS]: {
    width: 200,
    name: 'titles:photos',
    customComponent: ({ data }: Object) => <DocumentsCell documents={R.path([GC.FIELD_DOCUMENTS], data)} />,
  },
  issue: {
    width: 300,
    name: 'titles:issue',
    customComponent: ({ data }: Object) => {
      const { issue } = data;

      if (R.isNil(issue)) return null;

      const {
        status,
        summary,
        issueId,
        priority,
        location,
        description,
      } = issue;

      const infoPairProps = {
        fontWeight: 400,
        labelFontWeight: 'bold',
      };

      const PopperContent = () => (
        <Box p='8px'>
          <Box mb='6px' fontSize={13} fontWeight='bold' color={greyMatterhornColor}>
            {issueId}
          </Box>
          <InfoPair2
            {...infoPairProps}
            text={summary}
            label={G.getWindowLocale('titles:summary', 'Summary')}
          />
          <InfoPair2
            {...infoPairProps}
            text={G.getEnumLocale(status)}
            label={G.getWindowLocale('titles:status', 'Status')}
          />
          <InfoPair2
            {...infoPairProps}
            text={G.getEnumLocale(priority)}
            label={G.getWindowLocale('titles:priority', 'Priority')}
          />
          <InfoPair2
            {...infoPairProps}
            text={G.renderSmallAddressString(location)}
            label={G.getWindowLocale('titles:location', 'Location')}
          />
          <InfoPair2
            {...infoPairProps}
            text={description}
            label={G.getWindowLocale('titles:description', 'Description')}
          />
        </Box>
      );

      return (
        <PopperComponent type='click' content={PopperContent}>
          <Box
            maxWidth={284}
            wordBreak='break-word'
            color={priorityColorsMap[priority]}
          >
            {summary}
          </Box>
        </PopperComponent>
      );
    },
  },
  options: {
    width: 400,
    name: 'titles:options',
    customComponent: ({ data }: Object) => {
      const options = R.compose(
        R.filter(R.prop('selected')),
        R.pathOr([], ['options']),
      )(data);

      if (G.isNilOrEmpty(options)) return null;

      const length = R.length(options);

      return (
        <Box>
          {
            options.map(({ label, failItemIfSelected }: Object, i: number) => (
              <Span key={i} mr='6px' color={failItemIfSelected ? redColor : greyMatterhornColor}>
                {label}{G.notEquals(R.inc(i), length) && ','}
              </Span>
            ))
          }
        </Box>
      );
    },
  },
};

const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_NAME },
    { sequence: 2, name: 'failed' },
    { sequence: 3, name: GC.FIELD_COMMENTS },
    { sequence: 4, name: GC.FIELD_DOCUMENTS },
    { sequence: 5, name: 'issue' },
    { sequence: 6, name: 'options', p: '3px 8px' },
    { sequence: 7, name: GC.FIELD_TEMPLATE_DESCRIPTION },
  ],
};

const tableSettings = {
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 30,
  tableRowHeight: 40,
  withTitleRow: false,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  minHeight: 'fit-content',
};


export {
  report,
  tableSettings,
  columnSettings,
};
