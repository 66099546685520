import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// utilities
import { sendRequest } from '../../utilities/http';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature carrier-rating-report
import * as A from './actions';
import { makeSelectPagination } from './selectors';
//////////////////////////////////////////////////

function* handleGetCarrierRatingReportListSaga() {
  try {
    yield put(A.setListLoading(true));

    const pagination = yield select(makeSelectPagination());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const { limit, offset } = pagination;

    const reqData = {
      limit,
      offset,
      currentEnterprise,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telCarrierRateRatingReportList, {data: reqData});

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierRatingReportListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetCarrierRatingReportListSaga exception');
  }
}

function* handleDeleteCarrierRatingReportItemsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      successAction: A.deleteItemsSuccess,
      endpoint: endpointsMap.telCarrierRateRatingReport,
      parentSagaName: 'deleteCarrierRatingReportItemSaga',
    });

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierRatingReportItemsSaga exception');
  }
}

function* handleVisitCarrierRatingReportListSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CARRIER_RATING_REPORT_LIST_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield put(A.getItemListRequest());
    yield put(closeLoader());

    break;
  }
}

function* CarrierRatingReportListWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetCarrierRatingReportListSaga);
  yield takeLatest(A.deleteItemsRequest, handleDeleteCarrierRatingReportItemsSaga);
  yield takeLatest(GC.VISIT_CARRIER_RATING_REPORT_LIST_PAGE, handleVisitCarrierRatingReportListSaga);
}

export default CarrierRatingReportListWatcherSaga;
