import React from 'react';
import * as R from 'ramda';
// components
// forms
import AccessorialForm from '../../../forms/forms/accessorial-form';
import { districtOptions } from '../../../forms/forms/accessorial-form/field-settings';
// icons
import * as I from '../../../svgs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: {
    sortable: false,
    name: 'titles:accessorial',
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      return R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_DISPLAYED_VALUE],
        callbackData,
      );
    },
  },
  [GC.FIELD_FUEL_RELATED]: {
    width: 100,
    type: 'boolean',
    searchable: false,
    disableFilter: true,
    name: 'titles:fuel-related',
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const fuelRelated = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        callbackData,
      );

      return G.ifElse(fuelRelated, I.uiTrue, I.uiFalse)();
    },
  },
  [GC.FIELD_NON_TAXABLE]: {
    width: 100,
    type: 'boolean',
    searchable: false,
    disableFilter: true,
    name: 'titles:non-taxable',
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { assessorialGuid } = data;

      const nonTaxable = R.path(
        ['accessorialConfigs', 'accessorialConfigList', assessorialGuid, GC.FIELD_NON_TAXABLE],
        callbackData,
      );

      return G.ifElse(nonTaxable, I.uiTrue, I.uiFalse)();
    },
  },
  [GC.FIELD_DEDUCTION]: {
    width: 150,
    type: 'boolean',
    name: 'titles:deduction',
  },
  [GC.FIELD_ONLY_INCLUDE_TO_INVOICE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:only-include-to-invoice',
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 180,
    name: 'titles:rate',
    filterType: 'number',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
    filterType: 'selectMultiple',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    name: 'titles:rate-type',
    filterType: 'selectMultiple',
    options: R.filter(R.prop(GC.FIELD_VALUE), DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS),
    customComponent: ({ data: { rateType } = {} }: Object) => R.compose(
      R.propOr(rateType, GC.FIELD_LABEL),
      R.find(R.propEq(rateType, GC.FIELD_VALUE)),
    )(DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS),
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    name: 'titles:rate-unit',
    filterType: 'selectMultiple',
    options: R.filter(R.prop(GC.FIELD_VALUE), DEFAULT_CONTRACT_RATE_UNIT_OPTIONS),
    customComponent: ({ data: { rateUnit } = {} }: Object) => R.compose(
      R.propOr(rateUnit, GC.FIELD_LABEL),
      R.find(R.propEq(rateUnit, GC.FIELD_VALUE)),
    )(DEFAULT_CONTRACT_RATE_UNIT_OPTIONS),
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 180,
    filterType: 'number',
    name: 'titles:min-rate',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 180,
    filterType: 'number',
    name: 'titles:max-rate',
  },
  [GC.FIELD_MIN_STOPS]: {
    width: 180,
    filterType: 'number',
    name: 'titles:min-stops',
  },
  [GC.FIELD_MAX_STOPS]: {
    width: 180,
    filterType: 'number',
    name: 'titles:max-stops',
  },
  [GC.FIELD_ACCESSORIAL_DISTRICT]: {
    name: 'titles:district',
    options: districtOptions,
    filterType: 'selectMultiple',
    customComponent: ({ data }: Object) => {
      const district = G.getPropFromObject(GC.FIELD_ACCESSORIAL_DISTRICT, data);

      return R.pathOr(district, [district], GC.districtMap);
    },
  },
  [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: {
    width: 150,
    type: 'boolean',
    name: 'titles:use-fuel-index',
  },
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: {
    width: 150,
    collection: true,
    searchable: false,
    disableFilter: true,
    name: 'titles:fuel-index-quantity',
    customComponent: R.compose(
      R.length,
      R.pathOr([], ['data', GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]),
    ),
  },
};

const columnSettings = R.map(R.pick(['type', 'name', 'width', 'sortable', 'searchable', 'customComponent']), settings);

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = (props: Object) => {
  const accessorialOptions = R.pathOr([], ['accessorialConfigs', 'accessorialConfigOptions'], props);

  const isDriver = R.propEq(GC.FIELD_DRIVER, 'fleetType', props);

  const mapped = R.compose(
    R.mapObjIndexed((
      { name, options, collection, filterType, disableFilter, type = 'string' }: Object,
      value: string,
    ) => ({
      value,
      options,
      collection,
      placeholder,
      disableFilter,
      type: R.or(filterType, type),
      [GC.FIELD_NAME]: G.ifElse(
        G.isArray(name),
        `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
        G.getWindowLocale(name),
      ),
    })),
    R.omit(G.ifElse(isDriver, [GC.FIELD_ONLY_INCLUDE_TO_INVOICE], [])),
  )(settings);

  return {
    ...mapped,
    [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: {
      placeholder,
      type: 'selectMultiple',
      options: accessorialOptions,
      value: GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
      disableFilter: R.prop('disableAccessorialFilter', props),
      name: G.getWindowLocale('titles:accessorial', 'Accessorial'),
      handleDisableOptions: (option: Object, value: Array) => R.gte(R.length(value), 10),
    },
  };
};

export const FILTER_PARAMS = (props: Object) => R.values(filterProps(props));

const makeRequestPayload = (props: Object) => {
  const { vendorBranchGuid, driverBranchGuid, primaryObjectGuid, primaryObjectGuidKey } = props;

  return {
    [primaryObjectGuidKey]: primaryObjectGuid,
    [GC.CURRENT_BRANCH]: R.or(driverBranchGuid, vendorBranchGuid),
    reportFieldsToOmit: [GC.FIELD_NON_TAXABLE, GC.FIELD_FUEL_RELATED],
    systemFields: [
      { sequence: 0, name: GC.FIELD_VERSION },
      { sequence: 1, collection: true, name: GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES },
    ],
  };
};

export const accessorialSettings = {
  columnSettings,
  isReport: true,
  useSearchableColumns: true,
  withResizableColumns: true,
  groupName: 'accessorialList',
  useNewTitleFilterInputs: true,
  reportType: GC.FLEET_DRIVER_ASSESSORIAL_REPORT,
  additionalTableSettings: { searchableTitles: true },
  itemTitleArr: ['titles-accessorial', 'Accessorial'],
  formGroupTitleArr: ['titles-accessorials', 'Accessorials'],
  tableCallbackDataProps: ['itemList', 'accessorialConfigs'],
  endpoints: {
    list: 'driverAccessorialsList',
    createOrUpdate: 'driverAccessorial',
    entity: 'getDriverAccessorialEndpoint',
  },
  // components
  CustomForm: ({ submitAction, initialValues, optionsForSelect, primaryObjectGuid }: Object) => (
    <AccessorialForm
      {...optionsForSelect}
      isDriver={true}
      submitAction={submitAction}
      initialValues={R.assoc(GC.FIELD_DRIVER_GUID, primaryObjectGuid, initialValues)}
    />
  ),
  // helpers
  filterProps,
  makeRequestPayload,
  makeOptionsForSelect: R.pathOr({}, ['accessorialConfigs']),
  makeInitialValues: (initialValues: Object = {}, { branchConfigs, accessorialConfigs }: Object) => {
    const { currency, assessorialGuid } = initialValues;

    const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);

    return {
      ...initialValues,
      [GC.FIELD_CURRENCY]: R.or(currency, branchCurrency),
      [GC.FIELD_FUEL_RELATED]: R.pathOr(
        false,
        ['accessorialConfigList', assessorialGuid, GC.FIELD_FUEL_RELATED],
        accessorialConfigs,
      ),
      [GC.FIELD_NON_TAXABLE]: R.pathOr(
        false,
        ['accessorialConfigList', assessorialGuid, GC.FIELD_NON_TAXABLE],
        accessorialConfigs,
      ),
    };
  },
};

export const vendorAccessorialSettings = {
  ...accessorialSettings,
  reportType: GC.FLEET_VENDOR_ASSESSORIAL_REPORT,
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  endpoints: {
    list: 'fleetVendorAccessorialList',
    entity: 'getVendorAccessorialEndpoint',
    createOrUpdate: 'fleetVendorAccessorial',
  },
  CustomForm: ({ submitAction, initialValues, optionsForSelect, primaryObjectGuid }: Object) => (
    <AccessorialForm
      {...optionsForSelect}
      isVendor={true}
      submitAction={submitAction}
      initialValues={R.assoc(GC.FIELD_FLEET_VENDOR_GUID, primaryObjectGuid, initialValues)}
    />
  ),
};

export const vendorCompensationAccessorialSettings = {
  ...R.omit(
    [
      'isReport',
      'reportType',
      'filterProps',
      'makeRequestPayload',
      'useSearchableColumns',
      'withResizableColumns',
      'additionalTableSettings',
      'useNewTitleFilterInputs',
    ],
    vendorAccessorialSettings,
  ),
  includeItemListToTablePropsMemoDeps: true,
  primaryObjectGuidKey: GC.FIELD_COMPENSATION_GUID,
  report: {
    fields: R.compose(
      G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
      R.keys,
    )(settings),
  },
  endpoints: {
    remove: 'removeVendorCompensationAccessorial',
    list: 'fleetVendorCompensationAccessorialList',
    createOrUpdate: 'fleetVendorCompensationAccessorial',
  },
  CustomForm: ({ submitAction, initialValues, optionsForSelect, primaryObjectGuid }: Object) => (
    <AccessorialForm
      {...optionsForSelect}
      isVendor={true}
      submitAction={submitAction}
      initialValues={R.assoc(GC.FIELD_COMPENSATION_GUID, primaryObjectGuid, initialValues)}
    />
  ),
};
