import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  compose,
  withState,
  lifecycle } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// component dispatch-board-new
import { makeSelectItemList } from '../order/selectors';
import { getOrderDetailsRequest } from '../order/actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  orderList: makeSelectItemList(state),
});

export const withOrderDetails = compose(
  connect(mapStateToProps, {
    getOrderDetailsRequest,
  }),
  withState('details', 'setDetails', null),
  lifecycle({
    componentWillMount() {
      this.props.getOrderDetailsRequest(this.props.cloGuid);
    },
    componentWillReceiveProps(nextProps: Object) {
      const details = R.compose(
        R.path([this.props.cloGuid, 'details']),
        R.reduce((acc: Object, group: Object) => R.mergeRight(acc, group.loads), {}),
        R.prop('orderList'),
      )(nextProps);
      if (R.and(G.isNotNil(details), G.notEquals(nextProps.details, details))) {
        this.props.setDetails(details);
      }
    },
  }),
);
