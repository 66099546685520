import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const ToggleComponent = ({ p, opened, toggle, fontSize, openedText, closedText }: Object) => {
  const arrow = G.ifElse(opened, I.arrowUpSimple, I.arrowDownSimple);
  const textColor = G.getTheme('colors.light.mainLight');
  const text = G.ifElse(opened, openedText, closedText);

  return (
    <Box
      width='100%'
      cursor='pointer'
      onClick={toggle}
      p={R.or(p, '4px')}
      borderTop='1px solid'
      borderBottomLeftRadius='3px'
      borderBottomRightRadius='3px'
      bg={G.getTheme('colors.dark.blue')}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Flex mx='auto' width='fit-content'>
        {
          G.isNotNil(text) &&
          <Box mr='4px' color={textColor} fontSize={R.or(fontSize, 12)}>{text}</Box>
        }
        {arrow(textColor)}</Flex>
    </Box>
  );
};

export default ToggleComponent;
