import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-profile
import OwnershipDetailsForm from '../forms/ownership';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_OWNERSHIP_TYPE]: {
    width: 150,
    name: 'titles:ownership-type',
  },
  [GC.FIELD_CONTRACT_NAME]: {
    width: 150,
    name: 'titles:contract-name',
  },
  [GC.FIELD_CONTRACT_NUMBER]: {
    name: 'titles:contract-number',
  },
  [GC.FIELD_PAYMENT_AMOUNT]: {
    width: 150,
    name: 'titles:payment-amount',
  },
  [GC.FIELD_PAYMENT_INTERVAL]: {
    width: 150,
    name: 'titles:payment-interval',
  },
  [GC.FIELD_PAYMENT_METHOD]: {
    width: 150,
    name: 'titles:payment-method',
  },
  [GC.FIELD_MAINTENANCE_COST_INTERVAL]: {
    width: 150,
    name: 'titles:maintenance-cost-interval',
  },
  [GC.FIELD_MAINTENANCE_COST_METHOD]: {
    width: 150,
    name: 'titles:maintenance-cost-method',
  },
  [GC.FIELD_REGISTRATION_COST]: {
    width: 150,
    name: 'titles:registration-cost',
  },
  [GC.FIELD_REGISTRATION_PAYMENT_INTERVAL]: {
    width: 150,
    name: 'titles:registration-interval',
  },
  [GC.FIELD_START_DATE]: {
    width: 150,
    name: ['titles:term-details', 'titles:start-date'],
  },
  [GC.FIELD_END_DATE]: {
    width: 150,
    name: ['titles:term-details', 'titles:end-date'],
  },
  [GC.FIELD_RETURNED_DATE]: {
    width: 180,
    name: ['titles:term-details', 'titles:date-returned'],
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST]: {
    name: ['titles:equipment-return', 'titles:cost'],
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_RATIO]: {
    name: ['titles:equipment-return', 'titles:cost-ratio'],
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_ACCRUED]: {
    name: ['titles:equipment-return', 'titles:cost-accrued'],
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_PAID]: {
    name: ['titles:equipment-return', 'titles:cost-paid'],
  },
  [GC.FIELD_EQUIPMENT_RETURN_COST_NOT_PROVISIONED]: {
    name: ['titles:equipment-return', 'titles:cost-not-provisioned'],
  },
  [GC.FIELD_EQUIPMENT_RETURN_BALANCE_REQUIRED]: {
    name: ['titles:equipment-return', 'titles:balance-required'],
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
};

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(settings),
};

export const truckOwnershipSettings = {
  report,
  singleItem: true,
  hideEmptyFields: true,
  groupName: 'ownership',
  actionsPicker: ['edit'],
  columnSettings: settings,
  modalAdditionalOptions: { p: '0 0 15px 0' },
  itemTitleArr: ['titles:ownership-details', 'Ownership Details'],
  additionalTableSettings: { checkBoxCellJustifyContent: 'center' },
  formGroupTitleArr: ['titles:ownership-details', 'Ownership Details'],
  endpoints: {
    list: 'truckOwnership',
    createOrUpdate: 'truckOwnership',
  },
  // helpers
  CustomForm: OwnershipDetailsForm,
};

export const trailerOwnershipSettings = {
  ...truckOwnershipSettings,
  endpoints: {
    list: 'trailerOwnership',
    createOrUpdate: 'trailerOwnership',
  },
};
