import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, renderComponent } from 'react-recompose';
// common
import { makeSelectInitialSplashScreen } from '../../common/selectors';
// components
import LoaderComponent from '../../components/loader';
import ImageSlider from '../../components/slider-component';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// auth settings
import {
  loginPageSettings,
  loginFieldSettings,
  defaultLoginInitFields,
  loginValidationSchemaObject,
} from './settings';
// feature auth
import AuthForm from './auth-form';
import { FormWrapper } from './ui';
import { sendLogInRequest } from './actions';
import { makeSelectAuthData } from './selectors';
import stylingPanelHoc from './styling-panel-hoc';
import { createOptions, setDefaultSetting } from './helpers';
//////////////////////////////////////////////////

const Login = (props: Object) => {
  const {
    handleSubmit,
    handleChange,
    isSubmitting,
    splashScreenSetting,
    handleGetInfoPanelRef,
  } = props;

  const { title, action, subtitle, linkAction } = loginPageSettings;

  let options = [];

  if (G.isNotNil(R.path(['panels'], splashScreenSetting))) {
    const urlList = R.pick([
      'firstBackgroundImageUrl',
      'secondBackgroundImageUrl',
      'thirdBackgroundImageUrl',
    ], splashScreenSetting);

    options = createOptions(R.path(['panels'], splashScreenSetting), urlList);
  } else {
    options = setDefaultSetting(R.path(['firstBackgroundImageUrl'], splashScreenSetting));
  }

  return (
    <FormWrapper>
      <ImageSlider
        slides={options}
        splashScreenSetting={splashScreenSetting}
        handleGetInfoPanelRef={handleGetInfoPanelRef}
        color={R.path(['panelColor'], splashScreenSetting)}
      />
      <AuthForm
        props={props}
        mbLinkTo={24}
        heightLogo={150}
        textAlign='center'
        widthSubmitBtn={1}
        subtitle={subtitle}
        withLegalInfo={true}
        fontSizeSubmitBtn={18}
        actionSubmitBtn={action}
        actionLinkTo={linkAction}
        displayInputsBlock='block'
        puddingSubmitBtn='8px 26px'
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isSubmitting={isSubmitting}
        fields={loginFieldSettings}
        imageSlidersOptions={options}
        splashScreenSetting={splashScreenSetting}
        pathLinkTo={GC.ROUTE_PATH_RESET_PASSWORD}
        title={R.or(R.path(['welcomeText'], splashScreenSetting), title)}
      />
    </FormWrapper>
  );
};

const enhance = compose(
  stylingPanelHoc,
  withFormik({
    enableReinitialize: true,
    validationSchema: loginValidationSchemaObject,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultLoginInitFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { sendLogInRequest } = props;

      setSubmitting(true);
      sendLogInRequest({...values, callback: () => setSubmitting(false) });
    },
  }),
  branch(
    ({ auth, splashScreenSetting }: Object) => R.or(
      R.not(R.path(['isLoaded'], splashScreenSetting)),
      R.path(['loggedIn'], auth)),
    renderComponent(LoaderComponent),
  ),
  pure,
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  auth: makeSelectAuthData(state),
  splashScreenSetting: makeSelectInitialSplashScreen(state),
}));

export default connect(mapStateToProps, {
  sendLogInRequest,
})(enhance(Login));
