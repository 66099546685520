import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import AdditionalFilters from '../../../components/additional-filters';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, Text } from '../../../ui';
// feature components-story
import { setAdditionalFilters } from '../actions';
import { makeSelectAdditionalFilters } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const AdditionalFiltersComponent = ({ additionalFilters, setAdditionalFilters }: Object) => {
  const description = '<AdditionalFilters /> component is designed to create an interface for selecting and applying additional filters in application';

  const additionalFilterOptions = [
    {
      value: GC.ADDITIONAL_FILTER_TYPE_TRUCK,
      label: G.getWindowLocale('titles:truck', 'Truck'),
    },
  ];

  const usage = (
    <ul>
      <li>The parameter `<i>additionalFilterOptions</i>` changes default options for additional filters.</li>
      <li>Use `<i>additionalFiltersApplyHandler</i>` to execute the function when the user applies a filters.</li>
      <li>Parameter `<i>allowSetAdditionalFiltersImmediately</i>` if `true`, the handleSetFilter function is called immediately with the current filter value.</li>
    </ul>
  );

  const renderAdditionalFilters = () => {
    const { additionalFilterType } = additionalFilters;

    if (G.isNotNilAndNotEmpty(additionalFilterType)) {
      return (
        <Box mt={10}>
          <Text fontWeight='bold'>You set the next additional filters:</Text>
          <pre>
            {JSON.stringify(additionalFilters, null, 2)}
          </pre>
        </Box>

      );
    }

    return null;
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='AdditionalFilters'
      description={description}
      path='src/components/additional-filters'
    >
      <Flex alignItems='flex-start' flexDirection='column' >
        <AdditionalFilters
          additionalFilterOptions={additionalFilterOptions}
          additionalFiltersApplyHandler={setAdditionalFilters}
        />
        {renderAdditionalFilters()}
      </Flex>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  additionalFilters: makeSelectAdditionalFilters(state),
});

export default connect(mapStateToProps, {
  setAdditionalFilters,
})(AdditionalFiltersComponent);
