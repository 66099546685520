import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = { mb: 25, mr: 10, width: 220 };

export const validationSchemaGeoFencingZoneObject = {
  [GC.FIELD_GEO_FENCING_ZONE_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_GEO_FENCING_ZONE_COUNTRIES]: G.yupArrayRequired,
    [GC.FIELD_GEO_FENCING_ZONE_STATES]: Yup.array().nullable(true),
    [GC.FIELD_GEO_FENCING_ZONE_CITIES]: Yup.array().nullable(true),
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES]: Yup.array().nullable(true),
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: Yup.string().nullable(true),
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: Yup.string().nullable(true),
  })),
};

export const defaultZoneAddressPointsFields = () => ({
  id: G.genShortId(),
  [GC.FIELD_GEO_FENCING_ZONE_CITIES]: [],
  [GC.FIELD_GEO_FENCING_ZONE_STATES]: [],
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES]: [],
  [GC.FIELD_GEO_FENCING_ZONE_COUNTRIES]: null,
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: null,
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: null,
});

export const defaultGeoFencingZoneFields = {
  [GC.FIELD_GEO_FENCING_ZONE_NAME]: null,
  [GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS]: R.of(Array, defaultZoneAddressPointsFields()),
};

export const geoFencingZoneFieldName = {
  type: 'text',
  isRequired: true,
  label: ['titles:name', 'Name'],
  fieldName: GC.FIELD_GEO_FENCING_ZONE_NAME,
  inputWrapperStyles: { mb: 10, width: 220 },
};

const getAddressCommonFields = (index: number, type: string) => [
  {
    inputWrapperStyles,
    useMenuPortalTarget: true,
    type: 'addressAutocomplete',
    label: ['titles:search-city', 'Search City'],
    placeholder: G.getWindowLocale('titles:search-location', 'Search Location'),
    closeMenuOnScroll: R.pathEq('zone_address_points_wrapper', ['target', 'id']),
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.searchCity`,
  },
  {
    type: 'multiItems',
    inputWrapperStyles,
    label: ['titles:states', 'States'],
    placeholder: G.getWindowLocale('titles:enter-state-and-press-enter', 'Enter state and press Enter'),
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`,
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: 'countryOptions',
    useMenuPortalTarget: true,
    label: ['titles:countries', 'Countries'],
    closeMenuOnScroll: R.pathEq('zone_address_points_wrapper', ['target', 'id']),
    inputWrapperStyles: {...inputWrapperStyles, mr: G.ifElse(R.equals(type, 'report'), 40, '0px') },
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`,
  },
  {
    inputWrapperStyles,
    useMenuPortalTarget: true,
    type: 'addressAutocomplete',
    label: ['titles:search-zip', 'Search Zip'],
    placeholder: G.getWindowLocale('titles:search-location', 'Search Location'),
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.searchZip`,
  },
  {
    type: 'multiItems',
    inputWrapperStyles,
    label: ['titles:zip-codes', 'Zip Codes'],
    placeholder: G.getWindowLocale('titles:enter-zip-and-press-enter', 'Enter zip and press Enter'),
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`,
  },
  {
    inputWrapperStyles,
    label: ['titles:zip-from', 'Zip From'],
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`,
  },
  {
    label: ['titles:zip-to', 'Zip To'],
    inputWrapperStyles: { mb: 25, width: 220 },
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`,
  },
];

export const zoneAddressPointsFields = (index: number, type: string) => {
  const fields = getAddressCommonFields(index, type);

  if (R.equals(type, 'report')) return fields;

  return R.insert(1, {
    type: 'multiItems',
    inputWrapperStyles,
    label: ['titles:cities', 'Cities'],
    placeholder: G.getWindowLocale('titles:enter-city-and-press-enter', 'Enter city and press Enter'),
    fieldName: `${GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS}.${index}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`,
  }, fields);
};
