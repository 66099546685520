import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
import { preferredUsernameFormatOptions, driverOnboardingIntegrationTypeOptions } from '../../../../../helpers/options';
// hocs
import { withAsyncRolesByUserType } from '../../../../../hocs';
//////////////////////////////////////////////////

const defaultStyles = {
  width: 270,
  labelPl: 10,
  afterTop: 15,
  afterRight: 15,
  errorTop: '105%',
  errorLeft: '5px',
  errorTitle: true,
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

const fieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: driverOnboardingIntegrationTypeOptions,
    customDisabledFunction: 'handleDisableIntegrationType',
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:active',
    fieldName: GC.FIELD_ENABLED,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:company-id',
    fieldName: GC.FIELD_COMPANY_ID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:default-driver-password',
    fieldName: GC.FIELD_DEFAULT_DRIVER_PASSWORD,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:preferred-username-format',
    options: preferredUsernameFormatOptions(),
    fieldName: GC.FIELD_PREFERRED_USERNAME_FORMAT,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    options: 'driverRoles',
    loc: 'titles:select-user-permissions',
    fieldName: GC.FIELD_DRIVER_ROLES_GUIDS,
  },
];

const defaultFields = {
  [GC.FIELD_ENABLED]: true,
  [GC.FIELD_COMPANY_ID]: null,
  [GC.FIELD_DRIVER_ROLES_GUIDS]: null,
  [GC.FIELD_DEFAULT_DRIVER_PASSWORD]: null,
  [GC.FIELD_PREFERRED_USERNAME_FORMAT]: GC.PREFERRED_USER_NAME_TYPE_EMAIL,
  [GC.FIELD_INTEGRATION_TYPE]: GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
};

const fieldNotRequired = Yup.string().nullable(true);
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const validationSchema = ({ isEditMode }: Object) => Yup.lazy(() => Yup.object().shape({
  [GC.FIELD_COMPANY_ID]: fieldRequired,
  [GC.FIELD_DRIVER_ROLES_GUIDS]: G.yupArrayRequired.max(3, G.getShouldBePickOnlyLocaleTxt(3)),
  [GC.FIELD_DEFAULT_DRIVER_PASSWORD]: G.ifElse(G.isTrue(isEditMode), fieldNotRequired, fieldRequired),
}));

const enhance = compose(
  withProps(() => ({
    currentBranchGuid: G.getAmousCurrentBranchGuidFromWindow(),
  })),
  withAsyncRolesByUserType({ [GC.FIELD_ROLE_ROLE_TYPE]: GC.USER_ROLE_TYPE_DRIVER }),
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const OnboardingIntegrationConfigForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      flexDirection='column'
      handlers={{ handleDisableIntegrationType: () => props.isEditMode }}
      optionsForSelect={{ driverRoles: G.getPropFromObject(GC.FIELD_ROLE_ROLES_GUIDS, props) }}
    />
    <FormFooter boxStyles={{ mt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(OnboardingIntegrationConfigForm);
