import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-profile
import { TableCell } from '../components/table-cells';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_INSURANCE_TYPE]: {
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:insurance-type',
    options: 'insuranceTypeOptions',
    additionalInputWrapperStyles: { width: 400 },
    customComponent: (row: Object) =>
      <TableCell text={R.path(['data', GC.FIELD_INSURANCE_TYPE, GC.FIELD_DISPLAYED_VALUE], row)} />,
  },
  [GC.FIELD_EFFECTIVE_DATE]: {
    width: 150,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:effective-date',
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 150,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:expiration-date',
  },
  [GC.FIELD_PAYMENT_AMOUNT]: {
    width: 150,
    isRequired: true,
    name: 'titles:payment',
  },
  [GC.FIELD_PAYMENT_INTERVAL]: {
    width: 150,
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:payment-interval',
    options: [
      {
        value: 'Weekly',
        label: 'Weekly',
      },
      {
        value: 'Monthly',
        label: 'Monthly',
      },
      {
        value: 'Quarterly',
        label: 'Quarterly',
      },
      {
        value: 'Annually',
        label: 'Annually',
      },
    ],
  },
  [GC.FIELD_DESCRIPTION]: {
    type: 'textarea',
    inputStyles: { p: 15 },
    name: 'titles:description',
    additionalInputWrapperStyles: { width: 400 },
  },
};

const validationSchema = Yup.object().shape({
  ...R.map(({ isRequired }: Object) => G.ifElse(isRequired, G.yupStringRequired, G.yupStringNotRequired), settings),
  [GC.FIELD_DESCRIPTION]: Yup.string()
    .nullable(true)
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
  [GC.FIELD_PAYMENT_AMOUNT]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .typeError(G.getShouldBeNumericLocaleTxt())
    .min(0, G.getShouldBeFromToLocaleTxt(0, 1000000000))
    .max(1000000000, G.getShouldBeFromToLocaleTxt(0, 1000000000))
    .transform((value: number) => G.ifElse(R.equals(value, ''), null, value)),
});

const defaultInputWrapperStyles = {
  mb: 25,
  width: 190,
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const defaultValues = R.map(() => null, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    inputStyles,
    type = 'text',
    locationMapper,
    isRequired = false,
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    type,
    options,
    fieldName,
    isRequired,
    inputStyles,
    locationMapper,
    label: R.of(Array, name),
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...G.spreadUiStyles(additionalInputWrapperStyles),
    },
  })),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width', 'customComponent']), settings);

export const truckInsuranceSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  groupName: 'insurances',
  includeItemListToTablePropsMemoDeps: true,
  itemTitleArr: ['titles:insurance', 'Insurance'],
  formGroupTitleArr: ['titles:insurance', 'Insurance'],
  endpoints: {
    list: 'truckInsuranceList',
    createOrUpdate: 'truckInsurance',
    remove: 'getCurrentTruckInsuranceEndpoint',
  },
  fieldsWrapperStyles: {
    width: 400,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    insuranceTypeOptions: R.drop(1, R.pathOr([], ['dropdownOptions', GC.TRUCK_INSURANCE_TYPE], configGroup)),
  }),
  makeInitialValues: (initialValues: Object) => {
    const { insuranceType } = initialValues;

    return R.assoc(GC.FIELD_INSURANCE_TYPE, G.getDropdownOptionGuidFromObject(insuranceType), initialValues);
  },
};

export const trailerInsuranceSettings = {
  ...truckInsuranceSettings,
  endpoints: {
    list: 'trailerInsuranceList',
    createOrUpdate: 'trailerInsurance',
    remove: 'getCurrentTrailerInsuranceEndpoint',
  },
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    insuranceTypeOptions: R.drop(1, R.pathOr([], ['dropdownOptions', GC.TRAILER_INSURANCE_TYPE], configGroup)),
  }),
};
