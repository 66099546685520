import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// component
import { HoveringTitle } from '../../../components/hovering-title';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import StopForm from '../../new-do/outside-use/stop-form';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// hocs
import { Box, Flex } from '../../../ui';
// feature dispatch-planner
import * as H from '../helpers';
import LoadInfo from './load-info';
import { pageSizes } from '../settings/page-settings';
import CrossDockLocationForm from './cross-dock-location-form';
import { TEL_INFO_FORM, PLANNER_TEL_MODES } from '../constants';
import SelectLoadsForTerminal from './select-loads-for-terminal';
import { addNewTelToRouteBuilder, addTerminalToRouteBuilderTel } from '../actions';
import {
  makeSelectCurrentRoute,
  makeSelectRouteTelConfigs,
  makeSelectDefaultUomFields,
  makeSelectOptionsForSelect,
  makeSelectCrossDockLocationList } from '../selectors';
//////////////////////////////////////////////////

export const createOptionsForSelectTel = (currentRoute: Object) => R.compose(
  R.map((tel: Object) => ({ label: H.getLoadName(tel), value: tel.guid })),
  R.values,
  R.propOr({}, 'tels'),
)(currentRoute);

export const enhance = compose(
  withHandlers({
    handleAddNewTelWithInfo: (props: Object) => (data: Object) => (
      props.addNewTelToRouteBuilder(R.assoc(GC.FIELD_BRANCH_GUID, props.currentBranchGuid, data))
    ),
    handleClickAddNewTerminal: (props: Object) => (options: Object) => {
      const {
        openModal,
        closeModal,
        currentRoute,
        currentBranchGuid } = props;

      const dates = H.getTerminalInitialDates(options, currentRoute.tels);
      const initialValues = {
        ...dates,
        [GC.FIELD_LOAD_FCFS]: true,
        [GC.FIELD_TEL_EVENT_INDEX]: 0,
        eventType: GC.STOP_TYPE_TERMINAL,
      };

      const handleSubmit = (location: Object) => {
        closeModal();
        props.addTerminalToRouteBuilderTel({
          location,
          returnObject: options,
          eventType: GC.STOP_TYPE_TERMINAL,
        });
      };
      const modalContent = (
        <StopForm
          {...props}
          withoutReferences={true}
          submitHandler={handleSubmit}
          initialValues={initialValues}
          branchGuid={currentBranchGuid}
          configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]} />
      );
      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          width: 700,
          overflow: 'auto',
          maxHeight: '80vh',
        },
      };
      openModal(modal);
    },
    handleAddTerminalFromCrossDock: (props: Object) => ({ templateId, returnObject }: Object) => {
      const { closeModal, crossDocks, addTerminalToRouteBuilderTel } = props;
      const dates = H.getTerminalInitialDates(returnObject, props.currentRoute.tels);
      const terminal = R.mergeRight(R.prop(templateId, crossDocks), dates);
      addTerminalToRouteBuilderTel({
        returnObject,
        [GC.FIELD_LOCATION]: terminal,
        [GC.FIELD_EVENT_TYPE]: GC.STOP_TYPE_TERMINAL,
      });
      closeModal();
    },
  }),
  withHandlers({
    handleClickAddNewTel: (props: Object) => () => {
      const { autoTelPrimaryRef } = props.routeTelConfigs;
      if (G.isTrue(autoTelPrimaryRef)) {
        const sequence = R.length(R.values(R.path(['currentRoute', 'tels'], props)));
        return props.addNewTelToRouteBuilder({
          [GC.FIELD_BRANCH_GUID]: props.currentBranchGuid,
          [GC.FIELD_PRIMARY_REFERENCE]: { value: `${G.getWindowLocale('titles:tel', 'Trip')}-${R.inc(sequence)}` },
        });
      }
      const component = (
        <LoadInfo
          closeModal={props.closeModal}
          scopeName={GC.REF_SCOPE_NAME_TEL}
          branchGuid={props.currentBranchGuid}
          createLoadRequest={props.handleAddNewTelWithInfo} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:tel-info', 'TEL Information'),
        },
      };
      return props.openModal(modal);
    },
    handleClickAddTerminal: (props: Object) => () => {
      const tels = R.path(['currentRoute', GC.SYSTEM_LIST_TELS], props);
      if (G.isNilOrEmpty(tels)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('titles:create-tel-first', 'You need to create a TEL first'),
        );
      }
      if (R.equals(R.length(R.values(tels)), 2)) {
        const tels = R.sortBy(
          R.prop('order'),
          R.values(props.currentRoute.tels),
        );
        return props.handleClickAddNewTerminal({
          dropTels: R.of(Array, R.prop(GC.FIELD_GUID, R.head(tels))),
          pickupTels: R.of(Array, R.prop(GC.FIELD_GUID, R.last(tels))),
        });
      }
      const component = (
        <SelectLoadsForTerminal
          closeModal={props.closeModal}
          handleSubmitAction={props.handleClickAddNewTerminal}
          options={createOptionsForSelectTel(props.currentRoute)}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          movable: true,
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:choose-tels-for-terminal', 'Please, choose TELs for Terminal'),
        },
      };
      props.openModal(modal);
    },
    handleSelectTelsToAddTerminalFromCrossDock: (props: Object) => (templateId: string) => {
      const {
        openModal,
        closeModal,
        currentRoute,
        handleAddTerminalFromCrossDock } = props;
      const tels = R.path([GC.SYSTEM_LIST_TELS], currentRoute);
      if (R.equals(R.length(R.values(tels)), 2)) {
        const tels = R.sortBy(
          R.prop('order'),
          R.values(props.currentRoute.tels),
        );
        return handleAddTerminalFromCrossDock({
          templateId,
          returnObject: {
            dropTels: R.of(Array, R.prop(GC.FIELD_GUID, R.head(tels))),
            pickupTels: R.of(Array, R.prop(GC.FIELD_GUID, R.last(tels))),
          },
        });
      }
      const component = (
        <SelectLoadsForTerminal
          closeModal={closeModal}
          options={createOptionsForSelectTel(currentRoute)}
          handleSubmitAction={(returnObject: Object) => (
            handleAddTerminalFromCrossDock({ templateId, returnObject })
          )}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          movable: true,
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:choose-tels-for-terminal', 'Please, choose TELs for Terminal'),
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handleClickAddTerminalFromCrossDock: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        crossDocks,
        currentRoute,
        handleSelectTelsToAddTerminalFromCrossDock } = props;
      if (G.isNilOrEmpty(R.path([GC.SYSTEM_LIST_TELS], currentRoute))) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('titles:create-tel-first', 'You need to create a TEL first'),
        );
      }
      const options = G.addEmptyOptionToDropDown(G.getLocationOptions(R.values(crossDocks)));
      const component = (
        <CrossDockLocationForm
          singleSelect={true}
          closeModal={closeModal}
          locationOptions={options}
          setLocations={handleSelectTelsToAddTerminalFromCrossDock} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-location', 'Select Locations!'),
        },
      };
      openModal(modal);
    },
    handleOpenDriverCards: ({ setExpandedContainerOptions }: Object) => () => (
      setExpandedContainerOptions({
        opened: true,
        componentType: GC.PAGE_DRIVERS_CARD,
        options: { fromPage: GC.PAGE_DISPATCH_PLANNER },
      })
    ),
  }),
  pure,
);

const RightPanel = enhance((props: Object) => {
  const iconColor = G.getTheme('colors.dark.blue');

  return (
    <Box zIndex={11} boxShadow='0 0 10px 0 rgba(204, 204, 204, 0.5)'>
      <Flex
        pt='7px'
        height='100%'
        alignItems='center'
        flexDirection='column'
        justifyContent='flex-start'
        width={pageSizes.rightBarWidth}
      >
        <HoveringTitle
          handleClick={props.handleToggleTels}
          positionConfig={{
            zIndex: 16,
            right: '100%',
            width: 'max-content',
          }}
          title={G.ifElse(
            G.notEquals(props.mode, PLANNER_TEL_MODES.ALL_EXPANDED),
            G.getWindowLocale('actions:collapse-all-tels', 'Collapse All TELs'),
            G.getWindowLocale('actions:uncollapse-all-tels', 'Uncollapse All TELs'),
          )}
        >
          <Flex
            p='3px'
            cursor='pointer'
            onClick={props.handleToggleTels}
            flexDirection={G.ifElse(
              G.notEquals(props.mode, PLANNER_TEL_MODES.ALL_EXPANDED),
              'column-reverse',
              'column',
            )}
          >
            {I.arrowDownSimple(iconColor, 16, 11)}
            {I.arrowUpSimple(iconColor, 16, 11)}
          </Flex>
        </HoveringTitle>
        <HoveringTitle
          handleClick={props.handleClickAddNewTel}
          title={G.getWindowLocale('actions:add-tel', 'Add TEL')}
          positionConfig={{
            zIndex: 16,
            right: '100%',
            width: 'max-content',
          }}
        >
          <Box
            cursor='pointer'
            onClick={props.handleClickAddNewTel}
          >
            {I.tel(null, 24, 24, iconColor)}
          </Box>
        </HoveringTitle>
        <HoveringTitle
          handleClick={props.handleClickAddTerminal}
          title={G.getWindowLocale('actions:add-terminal', 'Add Terminal')}
          positionConfig={{
            zIndex: 16,
            right: '100%',
            width: 'max-content',
          }}
        >
          <Box
            cursor='pointer'
            onClick={props.handleClickAddTerminal}
          >
            {I.terminal(null, 24, 24, iconColor)}
          </Box>
        </HoveringTitle>
        <HoveringTitle
          handleClick={props.handleClickAddTerminalFromCrossDock}
          title={G.getWindowLocale('actions:add-favorite-terminal', 'Add a Favorite Terminal')}
          positionConfig={{
            zIndex: 16,
            right: '100%',
            width: 'max-content',
          }}
        >
          <Box
            cursor='pointer'
            onClick={props.handleClickAddTerminalFromCrossDock}
          >
            {I.crossDockTerminal(iconColor)}
          </Box>
        </HoveringTitle>
        <HoveringTitle
          handleClick={props.handleOpenDriverCards}
          title={G.getWindowLocale('titles:driver-cards', 'Driver Cards')}
          positionConfig={{
            zIndex: 16,
            right: '100%',
            width: 'max-content',
          }}
        >
          <Box
            cursor='pointer'
            onClick={props.handleOpenDriverCards}
          >
            {I.driver(iconColor, 17, 18)}
          </Box>
        </HoveringTitle>
      </Flex>
    </Box>
  );
});

const mapStateToProps = (state: Object) => (createStructuredSelector({
  currentRoute: makeSelectCurrentRoute(state),
  routeTelConfigs: makeSelectRouteTelConfigs(state),
  crossDocks: makeSelectCrossDockLocationList(state),
  defaultUomFields: makeSelectDefaultUomFields(state),
  optionsForSelect: makeSelectOptionsForSelect(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  addNewTelToRouteBuilder,
  setExpandedContainerOptions,
  addTerminalToRouteBuilderTel,
})(RightPanel);
