import * as R from 'ramda';
import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import Insurances from '../../../components/insurance';
import { FormButtons } from '../../../components/form-buttons';
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// features
import { createNewUserRequest } from '../../user/actions';
import References from '../../reference/components/reference';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// forms
import CreateUserForm from '../../../forms/forms/create-user-form';
import { styles, renderFormGroup, FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncRolesByUserType,
  withAsyncGetBranchListByType,
  withAsyncAvailableDocumentTypes,
} from '../../../hocs';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
// feature carrier
import { GeneralGroup } from '../ui';
import { withInternalDispatchCRUD } from '../hocs';
import CreateTerminalForm from './create-terminal-form';
import { setOptions, getAdditionBtns } from '../helpers';
import { editCarrierFields } from '../settings/fields-settings';
import { AssociatedCompanyForm } from './associated-company-form';
import InternalDispatchSettingsForm from './internal-dispatch-settings-form';
import {
  terminalSettings,
  rmisSafetyInfoSettings,
  integrationInfoSettings,
  integrationAuditSettings,
  associatedCompanySettings,
  synchronizationInfoSettings,
  saferWatchSafetyInfoSettings,
  loadBoardIntegrationInfoSettings,
  carrierPortalLoginColumnSettings,
  carrierInternalDispatchColumnSettings,
} from '../settings/column-settings';
import {
  makeSelectSafety,
  makeSelectCarrierGuid,
  makeSelectCarrierRefList,
  makeSelectCarrierTerminals,
  makeSelectCarrierInsurances,
  makeSelectOnboardingPackage,
  makeSelectCarrierAssocCompanies,
  makeSelectCarrierPortalLoginUsers,
  makeSelectLoadBoardIntegrationList,
  makeSelectCarrierInternalDispatchSettings,
} from '../selectors';
import {
  EDIT_GENERAL_DETAILS_FORM,
  CARRIER_GROUP_NAME_TERMINAL,
  CARRIER_GROUP_NAME_SAFETY_INFO,
  CARRIER_GROUP_NAME_INTEGRATION_INFO,
  CARRIER_GROUP_NAME_INTEGRATION_AUDIT,
  CARRIER_GROUP_NAME_ASSOCIATED_COMPANY,
  CARRIER_GROUP_NAME_SYNCHRONIZATION_INFO,
  CARRIER_GROUP_NAME_CARRIER_PORTAL_LOGIN,
  CARRIER_GROUP_NAME_INTERNAL_DISPATCH_SETTINGS,
  CARRIER_GROUP_NAME_LOAD_BOARD_INTEGRATION_INFO,
} from '../constants';
import {
  setTerminalDetails,
  updateCarrierSuccess,
  createCarrierRefRequest,
  updateCarrierRefRequest,
  deleteCarrierRefRequest,
  getTerminalFinanceRequest,
  getCarrierInsuranceRequest,
  getTerminalContactsRequest,
  getCarrierSafetyInfoRequest,
  createCarrierTerminalRequest,
  deleteCarrierTerminalRequest,
  createCarrierInsuranceRequest,
  updateCarrierInsuranceRequest,
  deleteCarrierInsuranceRequest,
  createCarrierAssocCompanyRequest,
  updateCarrierAssocCompanyRequest,
  deleteCarrierAssocCompanyRequest,
  deleteCarrierPortalLoginUserRequest,
  removeCarrierInternalDispatchSettingsSuccess,
  createOrUpdateCarrierInternalDispatchSettingsSuccess,
} from '../actions';
//////////////////////////////////////////////////

const refEnhance = compose(
  withHandlers({
    handleCreateReference: (props: Object) => (values: Object) => {
      const reqBody = R.assoc(
        'primaryObjectGuids',
        R.of(Array, R.path(['formValues', GC.FIELD_GUID], props)),
        values,
      );

      props.createCarrierRefRequest(reqBody);
    },
    handleUpdateReference: (props: Object) => (values: Object) => (
      props.updateCarrierRefRequest(values)
    ),
    handleDeleteReference: (props: Object) => (guid: string) => {
      const confirmationContent = (
        <ConfirmComponent
          name={R.path([GC.UI_FIELD_REFERENCES, guid, 'name'], props)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteCarrierRefRequest(guid);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

export const CarrierReferences = refEnhance((props: Object) => (
  <References
    scope='carrier'
    openModal={props.openModal}
    closeModal={props.closeModal}
    references={props[GC.UI_FIELD_REFERENCES]}
    handleCreateReference={props.handleCreateReference}
    handleUpdateReference={props.handleUpdateReference}
    handleRemoveReference={props.handleDeleteReference}
  />
));

const assocCompanyEnhance = compose(
  withHandlers({
    handleAddAssociateCompany: (props: Object) => () => {
      const { openModal, closeModal, initialValues, createCarrierAssocCompanyRequest } = props;

      const modalContext = (
        <AssociatedCompanyForm
          closeModal={closeModal}
          submitAction={createCarrierAssocCompanyRequest}
          carrierGuid={G.getGuidFromObject(initialValues)}
        />
      );

      const modal = {
        p: 15,
        component: modalContext,
        options: {
          width: 460,
          height: 'auto',
          title: G.getWindowLocale('titles:add-associated-company', 'Add Associated Company'),
        },
      };

      openModal(modal);
    },
    handleEditAssociateCompany: (props: Object) => (company: Object) => {
      const { openModal, closeModal, initialValues, updateCarrierAssocCompanyRequest } = props;

      const modalContext = (
        <AssociatedCompanyForm
          initialValues={company}
          closeModal={closeModal}
          submitAction={updateCarrierAssocCompanyRequest}
          carrierGuid={G.getGuidFromObject(initialValues)}
        />
      );

      const modal = {
        p: 15,
        component: modalContext,
        options: {
          width: 460,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-associated-company', 'Edit Associated Company'),
        },
      };

      openModal(modal);
    },
    handleDeleteAssociateCompany: (props: Object) => (company: Object) => {
      const confirmationContent = (
        <ConfirmComponent
          name={company.name}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteCarrierAssocCompanyRequest(company.guid);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

export const AssociatedCompany = assocCompanyEnhance((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={props.associatedCompanies}
    entitiesFields={associatedCompanySettings}
    handleAddClick={props.handleAddAssociateCompany}
    handleEditRow={props.handleEditAssociateCompany}
    groupName={CARRIER_GROUP_NAME_ASSOCIATED_COMPANY}
    handleDeleteRow={props.handleDeleteAssociateCompany}
    isOpened={R.path([CARRIER_GROUP_NAME_ASSOCIATED_COMPANY], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:associated-companies', 'Associated Companies')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_ASSOCIATED_COMPANY)}
  />
));

const enhance = compose(
  withAsyncGetBranchListByType(),
  withHandlers({
    handleSelectTransportationMode: (props: Object) => (options: Array) => {
      const { change } = props;

      const newSelectedTrMode = options.map((option: Object) => option.value);

      change('transportationModes', newSelectedTrMode);
    },
    handleSelectEquipment: (props: Object) => (options: Array) => {
      const { change, setEquipment } = props;

      const newSelectedEquipment = options.map((option: Object) => option.value);

      setEquipment(newSelectedEquipment);

      change('equipments', newSelectedEquipment);
    },
    handleChangeEmail: (props: Object) => (id: string, value: Array) => props.change(GC.FIELD_EMAIL, value),
    handleSelectAddress: (props: Object) => async (address: string) => {
      const { change, openLoader, closeLoader } = props;

      openLoader({ showDimmer: true });

      const {
        zip,
        city,
        state,
        country,
        address1,
      } = await G.geocodeByPlaceAddress(
        address,
        'carrier -> GeneralDetails',
        null,
      );

      const locationArr = [
        {
          value: address1,
          fieldName: GC.FIELD_ADDRESS,
        },
        {
          value: city,
          fieldName: GC.FIELD_CITY,
        },
        {
          value: state,
          fieldName: GC.FIELD_STATE,
        },
        {
          value: zip,
          fieldName: GC.FIELD_ZIP,
        },
        {
          value: country,
          fieldName: GC.FIELD_COUNTRY,
        },
      ];

      R.forEach(({ value, fieldName }: Object) => change(fieldName, value), locationArr);

      closeLoader();
    },
    handleChangeAddress: ({ change }: Object) => (address: string) =>
      change(GC.FIELD_ADDRESS, address),
  }),
  pure,
);

export const GeneralDetails = enhance((props: Object) => (
  <GeneralGroup
    zI={11}
    minHeight='max-content'
    onSubmit={props.handleSubmit(props.handleFormSubmit)}
  >
    <FormGroupWrapper isOpened={props.collapsedGroup[editCarrierFields(props).formGroup]}>
      <FormGroupTitleComponent
        withArrowDown={true}
        text={editCarrierFields(props).title}
        isOpened={props.collapsedGroup[editCarrierFields(props).formGroup]}
        onToggleFormGroup={() => (
          props.handleToggleFormGroup(editCarrierFields(props).formGroup)
        )}
      />
      {
        editCarrierFields(props).fields.map((field: Object, index: number) => {
          if (R.and(G.isNotNil(field.showField), G.isFalse(field.showField))) return null;

          return (
            <Field
              key={index}
              width='242px'
              zI={field.zI}
              direction='row'
              margin='5px 10px'
              labelFontSize={16}
              errorFontSize={14}
              labelMargin='5px 0'
              labelPosition='left'
              inputs={field.inputs}
              errorMargin='0 0 5px 0'
              display={field.display}
              validate={field.validate}
              disabled={field.disabled}
              type={R.or(field.type, '')}
              additionClass={styles.inputGroup}
              options={setOptions(props, field)}
              selectedValues={props[field.values]}
              label={G.getWindowLocale(field.name)}
              saferWatchSection={G.showSaferWatch()}
              selectAction={props[field.selectAction]}
              onChangeAddress={props.handleChangeAddress}
              onSelectAddress={props.handleSelectAddress}
              name={R.or(field.nameForAttribute, field.name)}
              shouldOverrideEmails={field.shouldOverrideEmails}
              component={field.component || renderFormGroup(field.type)}
            />
          );
        })
      }
    </FormGroupWrapper>
  </GeneralGroup>
));

const terminalEnhance = compose(
  withHandlers({
    handleAddTerminal: (props: Object) => () => {
      const modalContext = (
        <CreateTerminalForm
          openLoader={props.openLoader}
          closeModal={props.closeModal}
          closeLoader={props.closeLoader}
          carrierConfigs={props.carrierConfigs}
          carrierGuid={props.initialValues.guid}
          submitAction={props.createCarrierTerminalRequest}
        />
      );
      const modal = {
        p: 15,
        component: modalContext,
        options: {
          width: 505,
          height: 'auto',
          title: G.getWindowLocale('titles:add-terminal', 'Add Terminal'),
        },
      };
      props.openModal(modal);
    },
    handleDeleteTerminal: (props: Object) => ({ name, guid }: Object) => {
      const confirmationContent = (
        <ConfirmComponent
          name={name}
          display='block'
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteCarrierTerminalRequest(guid);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleEditTerminal: (props: Object) => (terminal: Object, tabName: string = 'terminalGeneral') => {
      props.setActiveTab({
        tabName,
        formName: EDIT_GENERAL_DETAILS_FORM,
        touch: props.touch,
        tabAction: () => {
          props.setActivePage('terminal');
          props.setTerminalDetails(terminal);
          props.change('terminal', terminal);
          props.change('terminalFinance', props.terminalFinance);
          props.getTerminalFinanceRequest(terminal.guid);
          props.getTerminalContactsRequest(terminal.guid);

          if (G.isNilOrEmpty(props.dispatchConfigs)) props.getDispatchConfigListRequest();
        },
      });
    },
  }),
  pure,
);

const iconColor = G.getTheme('colors.dark.blue');

const getAdditionalColumnActions = (handleEditTerminal: Function) => [
  {
    icon: I.moneyWithArrow(iconColor, 15, 15),
    handleClick: (entity: Object) => handleEditTerminal(entity, 'terminalProcess'),
  },
  {
    icon: I.payment(iconColor, 15, 15),
    handleClick: (entity: Object) => handleEditTerminal(entity, 'terminalFinance'),
  },
];

export const Terminal = terminalEnhance((props: Object) => (
  <FormGroupTable
    tableColumnWidth={120}
    entities={props.terminals}
    fields={props.collapsedGroup}
    entitiesFields={terminalSettings}
    groupName={CARRIER_GROUP_NAME_TERMINAL}
    handleAddClick={props.handleAddTerminal}
    handleEditRow={props.handleEditTerminal}
    handleDeleteRow={props.handleDeleteTerminal}
    panelTitle={G.getWindowLocale('titles:terminals', 'Terminals')}
    isOpened={R.path([CARRIER_GROUP_NAME_TERMINAL], props.collapsedGroup)}
    additionalColumnActions={getAdditionalColumnActions(props.handleEditTerminal)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_TERMINAL)}
  />
));

const internalDispatchSettingsEnhancer = compose(
  withAsyncAvailableDocumentTypes,
  withInternalDispatchCRUD,
  withProps(({ availableDocumentTypes }: Object) => ({
    documentTypeOptions: G.getOptionsFromConfigValueByParentGuidOrGuid(availableDocumentTypes),
  })),
  withHandlers({
    handleAddDispatchSettings: (props: Object) => () => {
      const { openModal, handleCreate, carrierGuid, documentTypeOptions } = props;

      const modalContent = (
        <InternalDispatchSettingsForm
          documentTypeOptions={documentTypeOptions}
          submitAction={(values: Object) => handleCreate(R.assoc('carrierGuid', carrierGuid, values))}
        />
      );

      const modal = G.getDefaultModalOptions(
        modalContent,
        G.getWindowLocale('titles:internal-dispatch-settings', 'Internal Dispatch Settings'),
      );

      openModal(modal);
    },
    handleUpdateDispatchSettings: (props: Object) => (entity: Object) => {
      const { openModal, handleUpdate, documentTypeOptions } = props;

      const modalContent = (
        <InternalDispatchSettingsForm
          initialValues={entity}
          submitAction={handleUpdate}
          documentTypeOptions={documentTypeOptions}
        />
      );

      const modal = G.getDefaultModalOptions(
        modalContent,
        G.getWindowLocale('titles:internal-dispatch-settings', 'Internal Dispatch Settings'),
      );

      openModal(modal);
    },
    handleRemoveDispatchSettings: (props: Object) => (item: Object) => {
      const { openModal, handleDelete } = props;

      const component = (
        <ConfirmComponent
          name={G.getWindowLocale('titles:settings', 'Settings')}
          textLocale={G.getWindowLocale('messages:before:remove')}
        />
      );

      const modal = {
        component,
        options: {
          height: 'auto',
          width: '600px',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => handleDelete(G.getGuidFromObject(item)),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const InternalDispatchSettings = internalDispatchSettingsEnhancer((props: Object) => (
  <FormGroupTable
    documentTypeOptions={props.documentTypeOptions}
    entities={props.carrierInternalDispatchSettings}
    handleAddClick={props.handleAddDispatchSettings}
    handleEditRow={props.handleUpdateDispatchSettings}
    handleDeleteRow={props.handleRemoveDispatchSettings}
    entitiesFields={carrierInternalDispatchColumnSettings}
    groupName={CARRIER_GROUP_NAME_INTERNAL_DISPATCH_SETTINGS}
    isHiddenAddIcon={R.gt(R.length(props.carrierInternalDispatchSettings), 0)}
    isOpened={R.path([CARRIER_GROUP_NAME_INTERNAL_DISPATCH_SETTINGS], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:internal-dispatch-settings', 'Internal Dispatch Settings')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_INTERNAL_DISPATCH_SETTINGS)}
  />
));

const carrierPortalLoginEnhancer = compose(
  withAsyncRolesByUserType({ [GC.FIELD_ROLE_ROLE_TYPE]: GC.USER_ROLE_TYPE_CARRIER }),
  withHandlers({
    handleAddCarrierPortalLoginUser: (props: Object) => () => {
      const { openModal, closeModal, initialValues, createNewUserRequest, currentBranchGuid } = props;

      const component = (
        <CreateUserForm
          closeModal={closeModal}
          submitAction={createNewUserRequest}
          roleType={GC.USER_ROLE_TYPE_CARRIER}
          currentBranchGuid={currentBranchGuid}
          initialValues={{
            [GC.FIELD_TYPE]: GC.USER_ROLE_TYPE_CARRIER,
            [GC.FIELD_CARRIER_GUID]: R.prop(GC.FIELD_GUID, initialValues),
          }}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '400px',
          maxHeight: '80vh',
          overflow: 'visible',
          title: `${G.getWindowLocale('titles:create-user', 'Create User')}
            (${G.getWindowLocale('titles:carrier', 'Carrier')})`,
        },
      };
      openModal(modal);
    },
    handleRemoveCarrierPortalLoginUser: (props: Object) => (user: Object) => {
      const { openModal, deleteCarrierPortalLoginUserRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_USER_LOGIN_ID, user)}
          textLocale={G.getWindowLocale('messages:before:remove')}
        />
      );
      const modal = {
        component,
        options: {
          height: 'auto',
          width: '600px',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => deleteCarrierPortalLoginUserRequest(R.prop(GC.FIELD_GUID, user)),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const CarrierPortalLogin = carrierPortalLoginEnhancer((props: Object) => (
  <FormGroupTable
    entities={props.carrierPortalLoginUsers}
    entitiesFields={carrierPortalLoginColumnSettings}
    groupName={CARRIER_GROUP_NAME_CARRIER_PORTAL_LOGIN}
    handleAddClick={props.handleAddCarrierPortalLoginUser}
    handleDeleteRow={props.handleRemoveCarrierPortalLoginUser}
    isOpened={R.path([CARRIER_GROUP_NAME_CARRIER_PORTAL_LOGIN], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:carrier-portal-login', 'Carrier Portal Login')}
    handleEditRow={({ guid }: Object) => G.goToRoute(routesMap.getUserSettingsRoute(guid))}
    handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_CARRIER_PORTAL_LOGIN)}
  />
));

const SynchronizationInfo = ({ entities, collapsedGroup, handleToggleFormGroup }: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    entities={entities}
    isHiddenAddIcon={true}
    entitiesFields={synchronizationInfoSettings}
    groupName={CARRIER_GROUP_NAME_SYNCHRONIZATION_INFO}
    isOpened={R.prop(CARRIER_GROUP_NAME_SYNCHRONIZATION_INFO, collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:synchronization-info', 'Synchronization Info')}
    handleToggleFormGroup={() => handleToggleFormGroup(CARRIER_GROUP_NAME_SYNCHRONIZATION_INFO)}
  />
);

const LoadBoardIntegrationInfo = ({ entities, collapsedGroup, handleToggleFormGroup }: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    entities={entities}
    isHiddenAddIcon={true}
    entitiesFields={loadBoardIntegrationInfoSettings()}
    groupName={CARRIER_GROUP_NAME_LOAD_BOARD_INTEGRATION_INFO}
    isOpened={R.prop(CARRIER_GROUP_NAME_LOAD_BOARD_INTEGRATION_INFO, collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:load-board-integration', 'Load Board Integration')}
    handleToggleFormGroup={() => handleToggleFormGroup(CARRIER_GROUP_NAME_LOAD_BOARD_INTEGRATION_INFO)}
  />
);

export const IntegrationInfo = ({ entities, collapsedGroup, handleToggleFormGroup }: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    entities={entities}
    isHiddenAddIcon={true}
    entitiesFields={integrationInfoSettings}
    groupName={CARRIER_GROUP_NAME_INTEGRATION_INFO}
    isOpened={R.prop(CARRIER_GROUP_NAME_INTEGRATION_INFO, collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:integration-info', 'Integration Info')}
    handleToggleFormGroup={() => handleToggleFormGroup(CARRIER_GROUP_NAME_INTEGRATION_INFO)}
  />
);

export const IntegrationAudit = ({ entities, collapsedGroup, handleToggleFormGroup }: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    entities={entities}
    isHiddenAddIcon={true}
    entitiesFields={integrationAuditSettings}
    groupName={CARRIER_GROUP_NAME_INTEGRATION_AUDIT}
    isOpened={R.prop(CARRIER_GROUP_NAME_INTEGRATION_AUDIT, collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:integration-audit', 'Integration Audit')}
    handleToggleFormGroup={() => handleToggleFormGroup(CARRIER_GROUP_NAME_INTEGRATION_AUDIT)}
  />
);

const getSafetyColumnSettings = (integrationType: Object = GC.CARRIER_SYNCHRONIZATION_TYPE_SAFER_WATCH) => {
  const columnSettingsMap = {
    [GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS]: rmisSafetyInfoSettings,
    [GC.CARRIER_SYNCHRONIZATION_TYPE_SAFER_WATCH]: saferWatchSafetyInfoSettings,
    [GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS]: rmisSafetyInfoSettings,
  };

  return G.getPropFromObject(integrationType, columnSettingsMap);
};

const SafetyInfo = ({ entities, collapsedGroup, handleToggleFormGroup }: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    entities={entities}
    isHiddenAddIcon={true}
    groupName={CARRIER_GROUP_NAME_SAFETY_INFO}
    isOpened={R.prop(CARRIER_GROUP_NAME_SAFETY_INFO, collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:safety-information', 'Safety Information')}
    entitiesFields={getSafetyColumnSettings(R.path([0, GC.FIELD_INTEGRATION_TYPE], entities))}
    handleToggleFormGroup={() => handleToggleFormGroup(CARRIER_GROUP_NAME_SYNCHRONIZATION_INFO)}
  />
);

export const GeneralTabComponent = (props: Object) => (
  <div>
    <GeneralDetails {...props} />
    {
      G.isTrue(R.path(['formValues', GC.FIELD_INTERNAL_DIVISION], props)) &&
      <InternalDispatchSettings {...props} />
    }
    <CarrierPortalLogin {...props} />
    <AssociatedCompany {...props} />
    <Terminal {...props} />
    {
      G.isNotNilAndNotEmpty(props.safety) &&
      <SafetyInfo
        entities={props.safety}
        collapsedGroup={props.collapsedGroup}
        handleToggleFormGroup={props.handleToggleFormGroup}
      />
    }
    <Insurances
      {...props}
      objKey={GC.FIELD_CARRIER_GUID}
      updateAction={props.updateCarrierInsuranceRequest}
      createAction={props.createCarrierInsuranceRequest}
      removeAction={props.deleteCarrierInsuranceRequest}
      objGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
      insuranceType={R.pathOr([], ['carrierConfigs', GC.FIELD_CARRIER_INSURANCE_TYPE], props)}
    />
    <CarrierReferences {...props} />
    <LoadBoardIntegrationInfo
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
      entities={R.pathOr([], ['loadBoardIntegrationList'], props)}
    />
    {
      G.isNotNilAndNotEmpty(R.path(['formValues', 'synchronizationInfo'], props)) &&
      <SynchronizationInfo
        collapsedGroup={props.collapsedGroup}
        handleToggleFormGroup={props.handleToggleFormGroup}
        entities={R.of(Array, R.path(['formValues', 'synchronizationInfo'], props))}
      />
    }
    <IntegrationInfo
      entities={props.integrationInfoList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
    <IntegrationAudit
      entities={props.integrationAuditList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
    <FormButtons
      {...props}
      zIndex={15}
      width='auto'
      additionBtns={getAdditionBtns(props)}
    />
  </div>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  safety: makeSelectSafety(state),
  carrierGuid: makeSelectCarrierGuid(state),
  authorities: makeSelectAuthorities(state),
  terminals: makeSelectCarrierTerminals(state),
  insurances: makeSelectCarrierInsurances(state),
  sendPackage: makeSelectOnboardingPackage(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  [GC.UI_FIELD_REFERENCES]: makeSelectCarrierRefList(state),
  associatedCompanies: makeSelectCarrierAssocCompanies(state),
  carrierPortalLoginUsers: makeSelectCarrierPortalLoginUsers(state),
  loadBoardIntegrationList: makeSelectLoadBoardIntegrationList(state),
  carrierInternalDispatchSettings: makeSelectCarrierInternalDispatchSettings(state),
});

export default connect(mapStateToProps, {
  setTerminalDetails,
  updateCarrierSuccess,
  createNewUserRequest,
  createCarrierRefRequest,
  updateCarrierRefRequest,
  deleteCarrierRefRequest,
  getTerminalFinanceRequest,
  getTerminalContactsRequest,
  getCarrierInsuranceRequest,
  getCarrierSafetyInfoRequest,
  createCarrierTerminalRequest,
  deleteCarrierTerminalRequest,
  createCarrierInsuranceRequest,
  updateCarrierInsuranceRequest,
  deleteCarrierInsuranceRequest,
  createCarrierAssocCompanyRequest,
  updateCarrierAssocCompanyRequest,
  deleteCarrierAssocCompanyRequest,
  deleteCarrierPortalLoginUserRequest,
  removeCarrierInternalDispatchSettingsSuccess,
  createOrUpdateCarrierInternalDispatchSettingsSuccess,
})(GeneralTabComponent);
