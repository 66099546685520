export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  moreBtnCellWidth: 50,
  expandableItems: false,
  searchableTitles: true,
  maxHeight: 'calc(100vh - 145px)',
};

export const statusMessageTableSettings = {
  minHeight: 320,
  allowEditBtn: true,
  titleRowHeight: 30,
  tableRowHeight: 30,
  useMainColors: true,
  moreBtnCellWidth: 50,
  checkBoxCellWidth: 30,
  allowSelectAll: false,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};
