import * as R from 'ramda';
import { put, call, takeEvery } from 'redux-saga/effects';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature route
import * as A from './actions';
import { takeLatest } from 'redux-saga';
/////////////////////////////////////////////////

function* getRouteConfigsByGroupSaga({ payload }: Object) {
  try {
    const options = {
      params: {
        group: payload.groupName,
        [GC.FIELD_BRANCH_GUID]: payload.branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const amousConfig = G.getAmousConfigFromWindow();
      const mappedConfigs = G.mapConfigValuesByName(data);
      const configs = R.compose(
        R.mergeRight(R.pathOr({}, ['configs'], amousConfig)),
        R.pathOr({}, ['configs']),
      )(mappedConfigs);
      const dropdowns = R.compose(
        R.mergeRight(R.pathOr({}, ['dropdowns'], amousConfig)),
        R.pathOr({}, ['dropdowns']),
      )(mappedConfigs);
      yield call(G.setAmousConfigToWindow, { configs, dropdowns });
      yield put(A.getDispatchBoardConfigsSuccess({
        res: mappedConfigs,
        groupName: payload.groupName,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'getRouteConfigsByGroupSaga');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getRouteConfigsByGroupSaga');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetCrossDockLocationsSaga({ payload }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.crossDockLocationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCrossDockLocationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCrossDockLocationsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetCrossDockLocationsSaga exception');
  }
}

function* getConfigsByNamesSaga({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;
    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getConfigsByNamesSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsByNamesSaga exception');
  }
}

function* dispatchBoardWatcherSaga() {
  yield takeLatest(A.getConfigsByNamesRequest, getConfigsByNamesSaga);
  yield takeEvery(A.getDispatchBoardConfigsRequest, getRouteConfigsByGroupSaga);
  yield takeEvery(A.getCrossDockLocationsRequest, handleGetCrossDockLocationsSaga);
}

export default dispatchBoardWatcherSaga;
