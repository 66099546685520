import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../components/form-buttons';
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { styles, renderFormGroup, FormGroupWrapper } from '../../../forms';
// feature carrier
import { GeneralGroup } from '../ui';
import { setOptions } from '../helpers';
import ContactForm from './contact-form';
import { makeSelectTerminalContacts } from '../selectors';
import { CARRIER_GROUP_NAME_TERMINAL_CONTACT } from '../constants';
import { terminalCreateFields } from '../settings/fields-settings';
import { terminalContactSettings } from '../settings/column-settings';
import {
  createTerminalContactRequest,
  updateTerminalContactRequest,
  deleteTerminalContactRequest,
} from '../actions';
//////////////////////////////////////////////////

const contactEnhance = compose(
  withHandlers({
    handleAddContact: (props: Object) => () => {
      const component = (
        <ContactForm
          terminalGuid={props.formValues.terminal.guid}
          submitAction={props.createTerminalContactRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 390,
          height: 'auto',
          title: G.getWindowLocale('titles:add-contact', 'Add Contact'),
        },
      };
      props.openModal(modal);
    },
    handleEditContact: (props: Object) => (contact: Object) => {
      const component = (
        <ContactForm
          initialValues={contact}
          terminalGuid={props.formValues.terminal.guid}
          submitAction={props.updateTerminalContactRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: 390,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-contact', 'Edit Contact'),
        },
      };
      props.openModal(modal);
    },
    handleDeleteContact: (props: Object) => ({ contactName, guid }: Object) => {
      const confirmationContent = (
        <ConfirmComponent
          display='block'
          name={contactName}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.deleteTerminalContactRequest(guid);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);

const TerminalContact = contactEnhance((props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    entities={props.terminalContacts}
    handleAddClick={props.handleAddContact}
    handleEditRow={props.handleEditContact}
    entitiesFields={terminalContactSettings}
    handleDeleteRow={props.handleDeleteContact}
    groupName={CARRIER_GROUP_NAME_TERMINAL_CONTACT}
    panelTitle={G.getWindowLocale('titles:contact-details', 'Contact Details')}
    isOpened={R.path([CARRIER_GROUP_NAME_TERMINAL_CONTACT], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(CARRIER_GROUP_NAME_TERMINAL_CONTACT)}
  />
));

const enhance = compose(
  withState('showAutocomplete', 'setShowAutocomplete', true),
  withState('renderFirstTime', 'setRenderFirstTime', true),
  withHandlers({
    handleSelectAddress: (props: Object) => async (value: string) => {
      const { change, openLoader, closeLoader } = props;

      openLoader({ showDimmer: true });

      const {
        zip,
        city,
        state,
        country,
        address,
        latitude,
        longitude,
      } = await G.geocodeByPlaceAddress(
        value,
        'carrier -> TerminalDetailsTabComponent',
        null,
        { addressFieldName: GC.FIELD_ADDRESS },
      );

      const locationArr = [
        {
          value: address,
          fieldName: `terminal.${GC.FIELD_ADDRESS}`,
        },
        {
          value: city,
          fieldName: `terminal.${GC.FIELD_CITY}`,
        },
        {
          value: state,
          fieldName: `terminal.${GC.FIELD_STATE}`,
        },
        {
          value: zip,
          fieldName: `terminal.${GC.FIELD_ZIP}`,
        },
        {
          value: country,
          fieldName: `terminal.${GC.FIELD_COUNTRY}`,
        },
        {
          value: latitude,
          fieldName: `terminal.${GC.FIELD_LATITUDE}`,
        },
        {
          value: longitude,
          fieldName: `terminal.${GC.FIELD_LONGITUDE}`,
        },
      ];

      R.forEach(({ value, fieldName }: Object) => change(fieldName, value), locationArr);

      closeLoader();
    },
    handleChangeAddress: ({ change }: Object) => (address: string) => (
      change(`terminal.${GC.FIELD_ADDRESS}`, address)
    ),
  }),
  pure,
);

export const TerminalDetailsTabComponent = (props: Object) => (
  <FormSection name='terminal'>
    <GeneralGroup>
      <FormGroupWrapper isOpened={props.collapsedGroup[terminalCreateFields.formGroup]}>
        <FormGroupTitleComponent
          withArrowDown={true}
          text={G.getWindowLocale(terminalCreateFields.title)}
          isOpened={props.collapsedGroup[terminalCreateFields.formGroup]}
          onToggleFormGroup={() => (
            props.handleToggleFormGroup(terminalCreateFields.formGroup)
          )}
        />
        {
          terminalCreateFields.fields.map((field: Object, index: number) => (
            <Field
              key={index}
              width={242}
              direction='row'
              margin='5px 10px'
              zIndex={field.zI}
              errorFontSize={14}
              labelFontSize={16}
              labelMargin='5px 0'
              labelPosition='left'
              inputs={field.inputs}
              errorMargin='0 0 5px 0'
              display={field.display}
              disabled={field.disabled}
              type={R.or(field.type, '')}
              additionClass={styles.inputGroup}
              options={setOptions(props, field)}
              validate={R.or(field.validate, [])}
              label={G.getWindowLocale(field.name)}
              component={renderFormGroup(field.type)}
              onChangeAddress={props.handleChangeAddress}
              onSelectAddress={props.handleSelectAddress}
              name={R.or(field.nameForAttribute, field.name)}
            />
          ))
        }
      </FormGroupWrapper>
      <TerminalContact {...props} />
      <FormButtons
        {...props}
        width='auto'
        handleClickCancel={props.handleTerminalClickCancel}
      />
    </GeneralGroup>
  </FormSection>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  terminalContacts: makeSelectTerminalContacts(state),
}));

export default connect(mapStateToProps, {
  createTerminalContactRequest,
  updateTerminalContactRequest,
  deleteTerminalContactRequest,
})(enhance(TerminalDetailsTabComponent));
