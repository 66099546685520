import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature intl
import { FieldBox, FieldContainer } from '../ui';
import { setInputValueToStore } from '../actions';
import { DEFAULT_TAB, CUSTOM_TAB } from '../constants';
import { makeSelectLanguage, makeSelectIntl } from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleChangeInputValue: (props: Object) => (event: Object) => {
      const { name, value } = event.target;
      props.setInputValueToStore({ name, value });
    },
  }),
);

const ActionIconComponent = (props: Object) => {
  const iconColor = G.getTheme('colors.mainBlue');
  const idCustomTab = R.equals(props.pageTab.type, CUSTOM_TAB);
  const idDefaultTab = R.equals(props.pageTab.type, DEFAULT_TAB);
  const isCustomised = R.includes(R.path(['item', 'itemId'], props), props.customKeys);
  if (R.and(idCustomTab, G.isNilOrEmpty(props.branchName))) return null;
  return (
    <Flex>
      {
        R.and(
          R.and(idCustomTab, R.or(R.equals(props.activeTab, 'custom'), R.not(isCustomised))),
          <Flex
            cursor='pointer'
            m='5px 10px 0 15px'
            onClick={() => props.handleCustomUpdateItem(props.indexedLocale, props.item)}
          >
            {I.renderEditIcon(iconColor)}
          </Flex>
        )
      }
      {
        R.and(
          R.and(idCustomTab, R.equals(props.activeTab, 'custom')),
          <Flex
            cursor='pointer'
            m='5px 10px 0 15px'
            onClick={() => props.handleRemoveCustomItem(props.indexedLocale)}
          >
            {I.trash(iconColor)}
          </Flex>
        )
      }
      {
        R.and(
          idDefaultTab,
          <Flex
            cursor='pointer'
            m='5px 10px 0 15px'
            onClick={() => props.handleRemoveItem(props.indexedLocale)}
          >
            {I.trash(iconColor)}
          </Flex>,
        )
      }
    </Flex>
  );
};

const checkIsDisabled = (
  currentUserName: string,
  langKey: string,
  item: Object,
  customKeys: Array,
  branchName: Object,
  pageTab: Object,
  activeTab: string,
) => {
  const isNotAllowed = R.equals('intl-user-michael', currentUserName);
  const isCustomPage = R.and(G.notEquals(activeTab, 'custom'), R.equals(pageTab.type, CUSTOM_TAB));
  const isEmptyItem = G.isNilOrEmpty(item);
  const isCustomised = R.and(isCustomPage, R.includes(R.path(['itemId'], item), customKeys));
  const isEmptyBranchName = R.and(
    isCustomPage,
    G.isNilOrEmpty(branchName),
  );
  return R.or(isCustomised, R.or(R.or(R.and(isNotAllowed, R.includes(langKey, ['en', 'ua'])), isEmptyItem), isEmptyBranchName));
};

const RenderRow = (props: Object) => (
  <Box>
    {
      props.filteredTableList.map(
        (itemId: string, index: number) => (
          <Flex
            height='55px'
            minWidth='232px'
            minHeight='55px'
            maxWidth='max-content'
            key={`${itemId}${index}`}
          >
            <FieldBox
              mr='80px'
              name={itemId}
              value={itemId}
              validate={G.required}
              disabled={`${itemId}-${index}`} />
            {
              R.keys(props.language).map((langKey: string, indexLg: number, langKeyArray: Array) => {
                const item = R.path([itemId, langKey], props.intFields);
                const isNotAllowed = R.equals('intl-user-michael', props.currentUserName);
                const disabled = checkIsDisabled(
                  props.currentUserName,
                  langKey,
                  item,
                  props.customKeys,
                  props.branchName,
                  props.pageTab,
                  props.activeTab);
                return (
                  <Flex
                    key={indexLg}
                    alignItems='flex-start'
                  >
                    <FieldBox
                      disabled={disabled}
                      validate={G.required}
                      key={R.add(indexLg, 1)}
                      value={R.path(['value'], item)}
                      onChange={props.handleChangeInputValue}
                      name={`${R.path(['itemId'], item)}.${R.path(['language'], item)}`}
                      mr={G.ifElse(R.equals(R.length(langKeyArray), R.inc(indexLg)), '0px', '80px')} />
                    {
                      R.and(G.isFalse(isNotAllowed), R.equals(R.length(langKeyArray), R.inc(indexLg)))
                      && (
                      <ActionIconComponent
                        item={item}
                        indexedLocale={itemId}
                        pageTab={props.pageTab}
                        activeTab={props.activeTab}
                        branchName={props.branchName}
                        customKeys={props.customKeys}
                        handleRemoveItem={props.handleRemoveItem}
                        handleRemoveCustomItem={props.handleRemoveCustomItem}
                        handleCustomUpdateItem={props.handleCustomUpdateItem} />
                      )
                    }
                  </Flex>
                );
              })
            }
          </Flex>
        ),
      )
    }
  </Box>
);

const IntlListContent = (props: Object) => (
  <Flex
    pt='10px'
    m='0px 10px 10px 10px'
    flexDirection='column'
  >
    <Flex
      height='55px'
      minWidth='232px'
      minHeight='55px'
      background='white'
      maxWidth='max-content'
    >
      <Flex
        mr='80px'
        pl='20px'
        width='232px'
        height='30px'
      >
        {G.getWindowLocale('titles:keys', 'Keys')}
      </Flex>
      {
        R.values(props.language).map((item: string, index: number) => (
          <Flex
            pl='20px'
            mr='80px'
            key={index}
            height='30px'
            width='232px'
          >
            {item}
          </Flex>
        ))
      }
    </Flex>
    <RenderRow {...props} />
  </Flex>
);

const IntlList = (props: Object) => (
  <FieldContainer>
    <IntlListContent {...props} />
  </FieldContainer>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  intFields: makeSelectIntl(state),
  language: makeSelectLanguage(state),
});

export default connect(mapStateToProps, {
  setInputValueToStore,
})(enhance(IntlList));
