import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature carrier
import {
  carrierAdditionalContactFields,
  defaultCarrierAdditionalContactFields,
  carrierAdditionalContactValidationSchemaObject,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultCarrierAdditionalContactFields,
      initialValues,
    ),
    validationSchema: carrierAdditionalContactValidationSchemaObject,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const AdditionalContactForm = (props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={carrierAdditionalContactFields}
        fieldsWrapperStyles={{
          mt: 15,
          justifyContent: 'space-between',
        }}
      />
      <FormFooter2 />
    </form>
  </Box>
);

export default enhance(AdditionalContactForm);
