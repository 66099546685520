import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet/driver
import RatesSection from './rates-section';
import {
  defaultPayrollValues1,
  getPayrollCompensationFieldSettings1,
  getPayrollCompensationFieldSettings2,
  validationSchemaPayrollCompensationObject,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: validationSchemaPayrollCompensationObject,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultPayrollValues1, initialValues),
  }),
  pure,
);

const PayrollCompensationForm = (props: Object) => {
  const { values, isSubmitting, handleSubmit } = props;

  const { rateType } = values;

  const fields = G.ifElse(
    R.equals(rateType, GC.CHARGE_RATE_TYPE_TIME),
    getPayrollCompensationFieldSettings1,
    getPayrollCompensationFieldSettings2,
  )(values);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        fieldsWrapperStyles={{ mx: 15, pt: 15, width: 610, justifyContent: 'space-between' }}
      />
      {
        R.equals(rateType, GC.CHARGE_RATE_TYPE_TIME) && <RatesSection />
      }
      <FormFooter2 boxStyles={{ px: 15, mt: 15 }} submitDisabled={isSubmitting} />
    </form>
  );
};

export default enhance(PayrollCompensationForm);
