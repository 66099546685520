import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet
import * as C from '../constants';
import FooterBtns from './footer-btns';
import { getDocFields, defaultDocFields, getDocumentFormValidationSchemaObject } from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultDocFields,
      props.initialValues,
      props.searchedValues,
    ),
    validationSchema: ({ edit }: Object) => Yup.lazy((values: Object) => (
      Yup.object().shape(getDocumentFormValidationSchemaObject(values, edit))
    )),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction, primaryObjectGuid } = props;

      let data = R.assoc('primaryObjectGuid', primaryObjectGuid, values);

      if (G.isNilOrEmpty(R.prop(GC.FIELD_DOCUMENT_URL, values))) {
        data = R.omit([GC.FIELD_DOCUMENT_URL], data);
      }

      submitAction(G.mapObjectEmptyStringFieldsToNull(data));
    },
    displayName: C.FLEET_DOCUMENT_FORM,
  }),
  pure,
);

const DocumentForm = (props: Object) => (
  <section>
    <form onSubmit={props.handleSubmit} id={C.FLEET_DOCUMENT_FORM}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={getDocFields(R.assoc('fleetType', props.fleetType, props.values), props.initialValues)}
        fileName={R.pathOr(G.getWindowLocale('actions:upload', 'Upload'), ['values', GC.FIELD_DOCUMENT_UPLOAD], props)}
        optionsForSelect={{
          [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: R.pathOr([], ['configs', GC.FIELD_DOCUMENT_DOCUMENT_TYPE], props),
        }}
      />
      <FooterBtns
        cancelAction={props.closeModal}
        actionText={G.ifElse(
          props.edit,
          G.getWindowLocale('actions:update', 'Update'),
          G.getWindowLocale('actions:create', 'Create'),
        )}
      />
    </form>
  </section>
);

export default enhance(DocumentForm);
