import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withHandlers,
} from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// forms
import { LocationFormWithSearch } from '../../../forms/forms/location-form-with-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature branch
import { makeSelectEditBranchPrimaryLocation } from '../selectors';
import {
  createBranchPrimaryLocationRequest,
  deleteBranchPrimaryLocationRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleSubmit: (props: Object) => (location: Object) => {
      const {
        branchGuid,
        initialValues,
        createBranchPrimaryLocationRequest } = props;
      const data = R.assoc(GC.BRANCH_GUID, branchGuid, location);
      if (G.isNilOrEmpty(initialValues)) {
        createBranchPrimaryLocationRequest({
          data,
          method: 'post',
        });
      } else {
        createBranchPrimaryLocationRequest({
          data,
          method: 'put',
        });
      }
    },
  }),
  withHandlers({
    handleOpenLocationForm: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        branchGuid,
        handleSubmit,
        initialValues,
        locationTypeOptions,
      } = props;

      const initialValuesWithLocationTypeGuid = G.ifElse(
        G.isNotNilAndNotEmpty(initialValues),
        R.assoc(
          GC.FIELD_LOCATION_TYPE,
          R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues),
          initialValues,
        ),
        initialValues,
      );

      const mode = G.ifElse(
        G.isNilOrEmpty(initialValues),
        G.getAddTitle,
        G.getEditTitle,
      )(['titles:primary-location', 'Primary Location']);

      const optionsForSelect = { [GC.FIELD_LOCATION_TYPE]: locationTypeOptions };

      const locationFormProps = {
        mode,
        openModal,
        closeModal,
        branchGuid,
        optionsForSelect,
        returnFullLocation: true,
        submitAndSaveLocation: true,
        submitHandler: handleSubmit,
        cancelStyles: { mr: 'auto' },
        formType: GC.LOCATION_FORM_TYPE_BILL_TO,
        initialValues: initialValuesWithLocationTypeGuid,
      };

      const modalContent = <LocationFormWithSearch {...locationFormProps} />;

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '87vh',
        },
      };

      openModal(modal);
    },
    handleDelete: (props: Object) => () => {
      const { openModal, closeModal, initialValues, deleteBranchPrimaryLocationRequest } = props;
      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => {
          closeModal();
          deleteBranchPrimaryLocationRequest(R.path(['guid'], initialValues));
        },
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:primary-location', 'Primary Location')}?`,
      });
      openModal(modalContent);
    },
  }),
  pure,
);

export const PrimaryLocation = (props: Object) => {
  const { initialValues, handleDelete, handleOpenLocationForm } = props;
  const addIcon = G.ifElse(
    G.isNotNilAndNotEmpty(props.initialValues),
    'renderEditIcon',
    'plusRound',
  );

  return (
    <Flex zIndex={11} height={40}>
      <Box pl={15} width={320}>{G.getWindowLocale('titles:primary-location', 'Primary Location')}</Box>
      <Box pl={10} maxWidth='calc(100% - 400px)'>
        {G.isNotNilAndNotEmpty(initialValues) && G.renderFullAddressString(R.or(initialValues, {}))}
        {G.isNilOrEmpty(initialValues) && G.getWindowLocale('titles:add-location', 'Add Location...')}
      </Box>
      <Box pl={15} cursor='pointer' onClick={handleOpenLocationForm}>
        {I[addIcon](G.getTheme('colors.dark.blue'))}
      </Box>
      {
        G.isNotNilAndNotEmpty(initialValues) &&
        <Box pl={15} cursor='pointer' onClick={handleDelete}>
          {I.trash(G.getTheme('colors.dark.blue'))}
        </Box>
      }
    </Flex>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  initialValues: makeSelectEditBranchPrimaryLocation(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  createBranchPrimaryLocationRequest,
  deleteBranchPrimaryLocationRequest,
})(enhance(PrimaryLocation));
