import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: '400px',
  height: '35px',
  errorTop: '110%',
  afterTop: '14px',
  errorLeft: '5px',
  labelWidth: '100%',
  borderRadius: '4px',
  flexDirection: 'column',
  errorPosition: 'absolute',
  borderColor: G.getTheme('colors.lightGrey'),
};

export const defaultFields = (languageKeyFromBack: Array, custom: boolean = false) => {
  let fields = R.reduce(
    (acc: Array, key: string) => R.assoc(key, '', acc),
    {
      key: '',
    },
    languageKeyFromBack);
  if (custom) {
    fields = R.assoc(GC.FIELD_BRANCH_GUID, null, fields);
  }
  return fields;
};

export const getFields = (language: Object, languageKeyFromBack: Array, custom: boolean = false) => {
  const fields = R.reduce(
    (acc: Array, key: string) => R.append({
      ...defaultFieldStyles,
      name: key,
      zIndex: 12,
      type: 'text',
      fieldName: key,
      loc: R.path([key], language),
      label: R.path([key], language),
    }, acc),
    G.ifElse(
      custom,
      [],
      [
        {
          ...defaultFieldStyles,
          zIndex: 12,
          type: 'text',
          name: 'key',
          fieldName: 'key',
          label: 'Item Key',
          loc: 'titles:key',
          nameForAttribute: 'key',
        },
      ],
    ),
    languageKeyFromBack);
  return fields;
};

export const defaultValidationSchemaObject = (languageKeyFromBack: Array, custom: boolean = false) => R.reduce(
  (acc: Array, key: string) => R.assoc(key, Yup.string().required(G.getRequiredLocaleTxt()), acc),
  {
    [G.ifElse(custom, GC.FIELD_BRANCH_GUID, 'key')]: Yup.string().required(G.getRequiredLocaleTxt()),
  },
  languageKeyFromBack,
);
