import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  loadBoardApiKeyField,
  loadBoardCoyoteFields,
  loadBoardAccountIdField,
  loadBoardCHRobinsonFields,
  loadBoardTruckerToolsFields,
  loadBoardIntegrationTypeField,
  loadBoardShowCustomerRateField,
  loadBoardIntegrationFieldsToData,
  loadBoardTransportationModeField,
  loadBoardRequiredMinMaxPriceField,
  defaultLoadBoardIntegrationFields,
  getLoadBoardIntegrationConfigValidationSchema,
} from '../settings';
//////////////////////////////////////////////////

const getOptionsForSelect = (props: Object) => {
  const { values, optionsForSelect, loadBoardConfigList } = props;

  const type = R.prop('type', values);
  const currentBranchGuid = G.getCurrentBranchGuid();

  const typeOptions = G.isNotNilAndNotEmpty(type) ?
    GC.EXTERNAL_LOAD_BOARD_CONFIGURATION_LIST_OPTIONS
    : R.filter(({ value }: Object) => R.not(
      R.any(
        ({ type, enterpriseGuid }: Object) => R.and(
          R.equals(value, type),
          R.equals(enterpriseGuid, currentBranchGuid),
        ),
        loadBoardConfigList,
      ),
    ), GC.EXTERNAL_LOAD_BOARD_CONFIGURATION_LIST_OPTIONS);

  if (R.equals(R.length(typeOptions), 1)) {
    typeOptions[0] = {
      value: null,
      label: G.getWindowLocale('titles:external-load-boards-not-available', 'Not available'),
    };
  }

  return R.assoc('typeOptions', typeOptions, optionsForSelect);
};

const getFields = (type: string) => {
  switch (type) {
    case GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP:
      return [loadBoardIntegrationTypeField, loadBoardAccountIdField];
    case GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS:
      return R.prepend(loadBoardIntegrationTypeField, loadBoardTruckerToolsFields);
    case GC.EXTERNAL_LOAD_BOARD_PARADE:
      return [
        loadBoardIntegrationTypeField,
        loadBoardApiKeyField,
        loadBoardTransportationModeField,
        loadBoardShowCustomerRateField,
        loadBoardRequiredMinMaxPriceField,
      ];
    case GC.EXTERNAL_LOAD_BOARD_NEWTRUL:
      return [
        loadBoardIntegrationTypeField,
        loadBoardAccountIdField,
        loadBoardApiKeyField,
        loadBoardTransportationModeField,
      ];
    case GC.EXTERNAL_LOAD_BOARD_CH_ROBINSON:
      return loadBoardCHRobinsonFields;
    case GC.EXTERNAL_LOAD_BOARD_COYOTE:
      return loadBoardCoyoteFields;
    default: return [loadBoardIntegrationTypeField];
  }
};

const enhance = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultLoadBoardIntegrationFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;
      const { type } = values;

      const fieldsToPick = R.compose(
        R.concat([GC.FIELD_GUID, GC.BRANCH_GUID, GC.FIELD_VERSION]),
        R.map(R.prop('fieldName')),
      )(getFields(type));

      const data = R.pick(loadBoardIntegrationFieldsToData, values);

      const payload = {
        data,
        ...R.compose(
          R.omit(loadBoardIntegrationFieldsToData),
          R.pick(fieldsToPick),
        )(values),
      };

      submitAction(payload);
    },
    validationSchema: ({ isEditMode }: Object) => Yup.lazy(({ type }: Object) => Yup.object().shape(
      getLoadBoardIntegrationConfigValidationSchema(type, isEditMode),
    )),
  }),
  withHandlers({
    handleCustomChange: (props: Object) => (e: Object) => {
      const { setValues } = props;

      const { name, value } = e.target;

      setValues({
        ...defaultLoadBoardIntegrationFields,
        [name]: value,
      });
    },
  }),
  pure,
);

const LoadBoardIntegrationConfigForm = (props: Object) => (
  <Box mx='auto' width='270px'>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        optionsForSelect={getOptionsForSelect(props)}
        handleCustomChange={props.handleCustomChange}
        handlers={{ handleDisableIntegrationType: () => props.isEditMode }}
        fields={getFields(R.path(['values', GC.INTEGRATION_LOADBOARD_TYPE], props))}
      />
      <FormFooter boxStyles={{ py: '10px' }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(LoadBoardIntegrationConfigForm);
