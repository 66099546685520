import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet/truck
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  documents: [],
  locations: [],
  insurances: [],
  truckConfigs: {},
  usedReport: null,
  truckDetails: {},
  branchConfigs: {},
  equipComponent: [],
  truckOwnership: {},
  activeTab: 'details',
  documentsFilter: null,
  truckSpecification: {},
  integrationSamsara: [],
  latestKnownLocation: [],
  [GC.UI_FIELD_REFERENCES]: [],
  pagination: {
    limit: 20,
    offset: 0,
  },
  dailyLog: {
    entities: [],
    totalCount: 0,
    pagination: {
      offset: 0,
    },
  },
  collapsedGroup: {
    services: true,
    dailyLog: true,
    insurance: true,
    documents: true,
    locations: true,
    ownership: true,
    components: true,
    costDetails: true,
    termDetails: true,
    truckDetails: true,
    equipmentReturn: true,
    locationContact: true,
    mainSpecification: true,
    truckRegistration: true,
    integrationSamsara: true,
    tractorSpecification: true,
    truckLatestKnownLocation: true,
  },
};

const receivedGetTruckSuccess = (state: Object, truck: Object) => (
  P.$set('truckDetails', truck, state)
);

const receivedGetTruckInsuranceSuccess = (state: Object, insurances: Array) => (
  P.$set('insurances', insurances, state)
);

const receivedGetTruckRefSuccess = (state: Object, refs: Array) => (
  P.$set(GC.UI_FIELD_REFERENCES, refs, state)
);

const setDocumentsFilter = (state: Object, filter: string) => P.$set('documentsFilter', filter, state);

const receivedGetTruckDocsSuccess = (state: Object, docs: Array) => (
  P.$set('documents', docs, state)
);

const receivedGetTruckLocationSuccess = (state: Object, locations: Array) => (
  P.$set('locations', locations, state)
);

const receivedGetTruckOwnershipSuccess = (state: Object, truckOwnership: Object) => (
  P.$set('truckOwnership', truckOwnership, state)
);

const receivedGetTruckSpecificationSuccess = (state: Object, truckSpecification: Object) => (
  P.$set('truckSpecification', truckSpecification, state)
);

const receivedGetTruckComponentSuccess = (state: Object, data: Object) => (
  P.$set('equipComponent', data, state)
);

const receivedGetTruckConfigsSuccess = (state: Object, data: Object) => (
  P.$set('truckConfigs', R.assoc('dropdowns', R.mergeRight(state.truckConfigs.dropdowns, data.dropdowns), data), state)
);

const receivedGetLocationTypesSuccess = (state: Object, data: Object) => (
  P.$override('truckConfigs.dropdowns', { [GC.TEMPLATES_LOCATION_TYPE]: data }, state)
);

const receivedTruckLatestKnownLocationSuccess = (state: Object, location: Object) => (
  P.$set('latestKnownLocation', location, state));

const receivedCreateTrucksReferenceSuccess = (state: Object, data: Object) => (
  P.$add(GC.UI_FIELD_REFERENCES, data, state)
);

const receivedCreateTruckInsuranceSuccess = (state: Object, insurance: Object) => (
  P.$add('insurances', insurance, state)
);

const receivedCreateTruckDocSuccess = (state: Object, doc: Object) => (
  P.$add('documents', doc, state)
);

const receivedCreateTruckLocationSuccess = (state: Object, location: Object) => (
  P.$add('locations', location, state)
);

const receivedCreateTruckLatestKnownLocationSuccess = (
  state: Object,
  location: Object,
) => (
  P.$set('latestKnownLocation', location, state)
);

const receivedUpdateTruckReferenceSuccess = (state: Object, ref: Object) => (
  P.$set(`${GC.UI_FIELD_REFERENCES}.${ref.guid}`, ref, state)
);

const receivedUpdateTruckInsuranceSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`insurances.${index}`, data, state)
);

const receivedUpdateTruckDocSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`documents.${index}`, data, state)
);

const receivedUpdateTruckDetailsSuccess = (state: Object, truckDetails: Object) => (
  P.$set('truckDetails', truckDetails, state)
);

const receivedUpdateTruckLocationSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`locations.${index}`, data, state)
);

const receivedUpdateTruckOwnershipSuccess = (state: Object, truckOwnership: Object) => (
  P.$set('truckOwnership', truckOwnership, state)
);

const receivedUpdateTruckSpecificationSuccess = (
  state: Object,
  truckSpecification: Object,
) => (
  P.$set('truckSpecification', truckSpecification, state)
);

const receivedUpdateTruckComponentSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`equipComponent.${index}`, data, state)
);

const receivedDeleteTruckReferenceSuccess = (state: Object, refGuid: string) => (
  P.$drop(`${GC.UI_FIELD_REFERENCES}.${refGuid}`, state)
);

const receivedDeleteTruckInsuranceSuccess = (state: Object, index: string) => (
  P.$drop(`insurances.${index}`, state)
);

const receivedDeleteTruckDocSuccess = (state: Object, index: string) => (
  P.$drop(`documents.${index}`, state)
);

const receivedDeleteTruckLocationSuccess = (state: Object, index: string) => (
  P.$drop(`locations.${index}`, state)
);

const receivedDeleteTruckComponentSuccess = (state: Object, index: string) => (
  P.$drop(`equipComponent.${index}`, state)
);

const toggleTruckFormGroup = (state: Object, groupName: string) => (
  P.$toggle(`collapsedGroup.${groupName}`, state)
);

const setActiveTab = (state: Object, tabName: string) => (
  P.$set('activeTab', tabName, state)
);

const cleanFormStore = (state: Object) => (
  P.$all(
    P.$set('truckDetails', initialState.truckDetails),
    P.$set('truckOwnership', initialState.truckOwnership),
    P.$set('truckSpecification', initialState.truckSpecification),
    P.$set('integrationSamsara', initialState.integrationSamsara),
    state,
  )
);

const getExternalTruckIdSuccess = (state: Object, data: string) => (
  P.$set('integrationSamsara', data, state)
);

const deleteExternalTruckIdSuccess = (state: Object) => (
  P.$set('integrationSamsara', [], state)
);

const getBranchConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('branchConfigs', data, state)
);

// daily log
const getDailyLogListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const { dailyLog } = state;

  const entities = R.concat(dailyLog.entities, data);
  const resultLength = R.length(data);
  const entitiesLength = R.length(entities);

  const offset = R.add(resultLength, R.path(['pagination', 'offset'], dailyLog));

  const totalCount = G.ifElse(R.equals(resultLength, 20), R.inc(entitiesLength), entitiesLength);

  return P.$set('dailyLog', { entities, totalCount, pagination: { offset } }, state);
};

const setInitialState = () => initialState;

export default createReducer({
  [A.setActiveTab]: setActiveTab,
  [A.cleanFormStore]: cleanFormStore,
  [A.setInitialState]: setInitialState,
  [A.setDocumentsFilter]: setDocumentsFilter,
  [A.toggleTruckFormGroup]: toggleTruckFormGroup,
  [A.receivedGetTruckSuccess]: receivedGetTruckSuccess,
  [A.getExternalTruckIdSuccess]: getExternalTruckIdSuccess,
  [A.receivedGetTruckRefSuccess]: receivedGetTruckRefSuccess,
  [A.receivedGetTruckDocsSuccess]: receivedGetTruckDocsSuccess,
  [A.deleteExternalTruckIdSuccess]: deleteExternalTruckIdSuccess,
  [A.receivedDeleteTruckDocSuccess]: receivedDeleteTruckDocSuccess,
  [A.receivedUpdateTruckDocSuccess]: receivedUpdateTruckDocSuccess,
  [A.receivedCreateTruckDocSuccess]: receivedCreateTruckDocSuccess,
  [A.getBranchConfigsByNamesSuccess]: getBranchConfigsByNamesSuccess,
  [A.receivedGetTruckConfigsSuccess]: receivedGetTruckConfigsSuccess,
  [A.receivedGetTruckLocationSuccess]: receivedGetTruckLocationSuccess,
  [A.receivedGetLocationTypesSuccess]: receivedGetLocationTypesSuccess,
  [A.receivedGetTruckOwnershipSuccess]: receivedGetTruckOwnershipSuccess,
  [A.receivedGetTruckInsuranceSuccess]: receivedGetTruckInsuranceSuccess,
  [A.receivedGetTruckComponentSuccess]: receivedGetTruckComponentSuccess,
  [A.receivedUpdateTruckDetailsSuccess]: receivedUpdateTruckDetailsSuccess,
  [A.receivedUpdateTruckLocationSuccess]: receivedUpdateTruckLocationSuccess,
  [A.receivedCreateTruckLocationSuccess]: receivedCreateTruckLocationSuccess,
  [A.receivedDeleteTruckLocationSuccess]: receivedDeleteTruckLocationSuccess,
  [A.receivedUpdateTruckOwnershipSuccess]: receivedUpdateTruckOwnershipSuccess,
  [A.receivedCreateTruckInsuranceSuccess]: receivedCreateTruckInsuranceSuccess,
  [A.receivedUpdateTruckReferenceSuccess]: receivedUpdateTruckReferenceSuccess,
  [A.receivedUpdateTruckInsuranceSuccess]: receivedUpdateTruckInsuranceSuccess,
  [A.receivedUpdateTruckComponentSuccess]: receivedUpdateTruckComponentSuccess,
  [A.receivedDeleteTruckReferenceSuccess]: receivedDeleteTruckReferenceSuccess,
  [A.receivedDeleteTruckInsuranceSuccess]: receivedDeleteTruckInsuranceSuccess,
  [A.receivedDeleteTruckComponentSuccess]: receivedDeleteTruckComponentSuccess,
  [A.receivedCreateTrucksReferenceSuccess]: receivedCreateTrucksReferenceSuccess,
  [A.receivedGetTruckSpecificationSuccess]: receivedGetTruckSpecificationSuccess,
  [A.receivedUpdateTruckSpecificationSuccess]: receivedUpdateTruckSpecificationSuccess,
  [A.receivedGetTruckLatestKnownLocationSuccess]: receivedTruckLatestKnownLocationSuccess,
  [A.receivedCreateTruckLatestKnownLocationSuccess]: receivedCreateTruckLatestKnownLocationSuccess,
  // daily log
  [A.getDailyLogListSuccess]: getDailyLogListSuccess,
}, initialState);
