import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal } from '../../../../components/modal/actions';
// common
import {
  toggleMarkAsHotLoadByLoadTypeRequest,
  toggleMarkAsFastLoadByLoadTypeRequest,
} from '../../../../common/actions';
// features
import Audit2 from '../../../audit2';
import ChangeEntityBranch from '../../../branch/components/change-entity-branch';
import { setExpandedContainerOptions } from '../../../expanded-container/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import Notification2 from '../../../notification2';
import { makeSelectNotification, makeSelectClaimGeneralDetails } from '../selectors';
import {
  getLoadXMLRequest,
  getLoadDetailsRequest,
  hideLoadNotificationsRequest,
  getClaimGeneralDetailsRequest,
  markLoadNotificationAsReadRequest,
  getDataAndConfigsByBranchGuidRequest,
} from '../actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  notification: makeSelectNotification(state),
  claimGeneralDetails: makeSelectClaimGeneralDetails(state),
});

export const withLoadPageActions = compose(
  connect(
    mapStateToProps,
    {
      openModal,
      getLoadXMLRequest,
      getLoadDetailsRequest,
      setExpandedContainerOptions,
      hideLoadNotificationsRequest,
      getClaimGeneralDetailsRequest,
      markLoadNotificationAsReadRequest,
      getDataAndConfigsByBranchGuidRequest,
      toggleMarkAsHotLoadByLoadTypeRequest,
      toggleMarkAsFastLoadByLoadTypeRequest,
    },
  ),
  withHandlers({
    handleChangeBranch: (props: Object) => () => {
      const {
        load,
        openModal,
        getLoadDetailsRequest,
        getDataAndConfigsByBranchGuidRequest,
      } = props;

      const guid = G.getGuidFromObject(load);
      const callback = (branchGuid: string) => {
        getLoadDetailsRequest(guid);
        getDataAndConfigsByBranchGuidRequest(branchGuid);
      };
      const component = (
        <ChangeEntityBranch
          guid={guid}
          callback={callback}
          type={GC.LOAD_TYPE_TEL}
        />
      );
      const modal = {
        p: '0 8px',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getWindowLocale('titles:change-branch', 'Change Branch'),
        },
      };
      openModal(modal);
    },
    handleDownloadXML: ({ load, getLoadXMLRequest, primaryReferenceValue }: Object) => () => (
      getLoadXMLRequest({
        [GC.FIELD_GUID]: G.getGuidFromObject(load),
        [GC.FIELD_FILE_NAME]: primaryReferenceValue,
      })
    ),
    handleOpenAuditList: ({ load, openModal, primaryReferenceValue }: Object) => () => {
      const requestOptions = {
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_LOAD,
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(load),
      };

      const component = (
        <Audit2 requestOptions={requestOptions} />
      );

      const modal = {
        p: '0',
        component,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${primaryReferenceValue}`,
        },
      };

      openModal(modal);
    },
    handleShowNotifications: (props: Object) => (e: Event) => {
      const {
        openFixedPopup,
        closeFixedPopup,
        hideLoadNotificationsRequest,
        markLoadNotificationAsReadRequest,
      } = props;

      openFixedPopup({
        version: 2,
        position: 'right',
        wrapperPadding: 0,
        el: e.currentTarget,
        boxStyles: { zIndex: 12 },
        content: (
          <Notification2
            withoutGroup={true}
            closeFixedPopup={closeFixedPopup}
            hideAction={hideLoadNotificationsRequest}
            readAction={markLoadNotificationAsReadRequest}
            notifications={R.pathOr([], ['notification', 'list'], props)}
            title={G.getWindowLocale('actions:mark-all-as-read', 'Mark all as read')}
          />
        ),
      });
    },
    handleRefreshClaimDetails: ({ load, getClaimGeneralDetailsRequest }: Object) => () =>
      getClaimGeneralDetailsRequest(G.getGuidFromObject(load)),
  }),
  pure,
);
