import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { css } from 'styled-components';
import { pure, compose, withState, withHandlers, lifecycle } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TextComponent } from '../../components/text';
import { PageTitle } from '../../components/page-title';
import { openLoader, closeLoader } from '../../components/loader/actions';
import { FormGroupTitleComponent } from '../../components/form-group-title';
// forms
import { FormGroupWrapper } from '../../forms';
import { Fieldset2 } from '../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Span, Flex, Grid, FixedFlex, RelativeBox, CancelButton } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const scrollableContainer = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;

const renderScorecardRowValue = ({ value, symbol, percentValue, prefixSymbol }: Object) => {
  if (G.isNilOrZero(value)) return <TextComponent color='dark.darkBlue'>N/A</TextComponent>;

  return (
    <TextComponent color='dark.darkBlue'>
      {
        G.isNotNil(percentValue) &&
        <Span mr={15} fontWeight='bold' color='dark.darkGrey'>({percentValue})%</Span>
      }
      {prefixSymbol} {value}{symbol}
    </TextComponent>
  );
};

const Scorecard = (props: Object) => {
  const {
    telsCount,
    ratesTotalSum,
    onTimeTelsCount,
    invoiceTotalSum,
    averageRateTotal,
    onTimeDropsCount,
    onTimeTelsPercent,
    expiredRatesCount,
    acceptedTelsCount,
    onTimeDropsPercent,
    normalizedCurrency,
    declinedRatesCount,
    canceledRatesCount,
    onTimePickupsCount,
    expiredRatesPercent,
    averageInvoiceTotal,
    onTimePickupsPercent,
    canceledRatesPercent,
    declinedRatesPercent,
    acceptedRatesPercent,
    outstandingInvoicesCount,
    carrierCompletedEventCount,
    averageInvoiceSubmitMinutes,
    averageDocumentSubmitMinutes,
    dispatcherCompletedEventCount,
    transportationModeDisplayedValue,
  } = props;

  const rows = [
    {
      value: acceptedTelsCount,
      percentValue: acceptedRatesPercent,
      text: ['titles:accepted-trips', 'Accepted Trips'],
    },
    {
      value: declinedRatesCount,
      percentValue: declinedRatesPercent,
      text: ['titles:declined-trips', 'Declined Trips'],
    },
    {
      value: canceledRatesCount,
      percentValue: canceledRatesPercent,
      text: ['titles:canceled-trips', 'Canceled Trips'],
    },
    {
      value: expiredRatesCount,
      percentValue: expiredRatesPercent,
      text: ['titles:expired-trips', 'Expired Trips'],
    },
    {
      value: G.mathRoundNumber(averageRateTotal),
      prefixSymbol: G.getCurrencySymbol(normalizedCurrency),
      text: ['titles:average-trip-total', 'Average Trip Total'],
    },
    {
      value: G.mathRoundNumber(ratesTotalSum),
      text: ['titles:trip-total', 'Trip Total'],
      prefixSymbol: G.getCurrencySymbol(normalizedCurrency),
    },
    {
      value: G.mathRoundNumber(averageInvoiceTotal),
      prefixSymbol: G.getCurrencySymbol(normalizedCurrency),
      text: ['titles:average-invoice-total', 'Average Invoice Total'],
    },
    {
      value: G.mathRoundNumber(invoiceTotalSum),
      prefixSymbol: G.getCurrencySymbol(normalizedCurrency),
      text: ['titles:invoice-total', 'Average Invoice Total'],
    },
    {
      value: onTimeTelsCount,
      percentValue: onTimeTelsPercent,
      text: ['titles:on-time-trips', 'On Time Trips'],
    },
    {
      value: dispatcherCompletedEventCount,
      text: ['titles:dispatcher-completed-events', 'Dispatcher Completed Events'],
    },
    {
      value: carrierCompletedEventCount,
      text: ['titles:carrier-completed-events', 'Carrier Completed Events'],
    },
    {
      value: onTimePickupsCount,
      percentValue: onTimePickupsPercent,
      text: ['titles:on-time-pickups', 'On Time Pickups'],
    },
    {
      value: onTimeDropsCount,
      percentValue: onTimeDropsPercent,
      text: ['titles:on-time-drops', 'On Time Drops'],
    },
    {
      symbol: ' min',
      value: G.mathRoundNumber(averageDocumentSubmitMinutes),
      text: ['titles:average-document-submit', 'Average Document Submit'],
    },
    {
      symbol: ' min',
      value: G.mathRoundNumber(averageInvoiceSubmitMinutes),
      text: ['titles:average-invoice-submit', 'Average Invoice Submit'],
    },
    {
      value: outstandingInvoicesCount,
      text: ['titles:outstanding-invoices', 'Outstanding Invoices'],
    },
  ];

  return (
    <Box
      pt={10}
      pb={20}
      bg='white'
      width='100%'
      borderRadius={16}
      // demo 5
      boxShadow='cardBoxShadow'
    >
      <Flex
        px={20}
        mb={15}
        flexWrap='wrap'
        fontWeight='bold'
        color='greyMatterhorn'
        justifyContent='space-between'
      >
        <TextComponent
          mt={10}
          maxWidth='100%'
          withEllipsis={true}
          title={transportationModeDisplayedValue}
        >
          {
            G.isNilOrEmpty(transportationModeDisplayedValue) &&
            G.getWindowLocale('titles:overall-total', 'Overall Total')
          }
          {
            G.isNotNilAndNotEmpty(transportationModeDisplayedValue) &&
            `${G.getWindowLocale('titles:mode-of-transportation', 'Mode Of Transportation')}: ${
              transportationModeDisplayedValue
            }`
          }
        </TextComponent>
        <TextComponent mt={10}>
          {G.getWindowLocale('titles:tel-count', 'TEL Count')}: {telsCount}
        </TextComponent>
      </Flex>
      <Box px={20} maxHeight={400} overflowY='auto' css={scrollableContainer}>
        {
          rows.map(({ text, value, symbol, prefixSymbol, percentValue }: Object, index: number) => (
            <Flex
              p={10}
              mt={15}
              key={index}
              fontWeight='bold'
              borderRadius={12}
              bg='transparentDarkBlue'
              justifyContent='space-between'
            >
              <TextComponent color='dark.darkGrey'>{G.getWindowLocale(...text)}</TextComponent>
              {renderScorecardRowValue({ value, symbol, percentValue, prefixSymbol })}
            </Flex>
          ))
        }
      </Box>
    </Box>
  );
};

const safetySettings = {
  [GC.FIELD_COUNTRY]: {
    width: 150,
    name: 'titles:country',
    customComponent: (row: Object) => R.compose(
      R.join(' '),
      R.map(G.toTitleCase),
      R.split('_'),
      R.pathOr('', ['data', GC.FIELD_COUNTRY]),
    )(row),
  },
  driverNatlEvgPercentage: {
    width: 180,
    name: ['titles:driver', 'titles:natl-evg-percentage'],
  },
  driverOosPercentage: {
    width: 150,
    name: ['titles:driver', 'titles:oos-percentage'],
  },
  hazmatNatlEvgPercentage: {
    width: 180,
    name: ['titles:hazmat', 'titles:natl-evg-percentage'],
  },
  hazmatOosPercentage: {
    width: 150,
    name: ['titles:hazmat', 'titles:oos-percentage'],
  },
  iepNatlEvgPercentage: {
    width: 180,
    name: ['titles:iep', 'titles:natl-evg-percentage'],
  },
  iepOosPercentage: {
    width: 150,
    name: ['titles:iep', 'titles:oos-percentage'],
  },
  vehicleNatlEvgPercentage: {
    width: 180,
    name: ['titles:vehicle', 'titles:natl-evg-percentage'],
  },
  vehicleOosPercentage: {
    width: 150,
    name: ['titles:vehicle', 'titles:oos-percentage'],
  },
};

const withToggleSafetyFormGroupTable = withState('isOpened', 'setIsOpened', true);

const Safety = withToggleSafetyFormGroupTable(({ itemList, isOpened, setIsOpened }: Object) => (
  <FormGroupWrapper isOpened={isOpened}>
    <FormGroupTitleComponent
      mb='0px'
      isOpened={isOpened}
      withArrowDown={true}
      onToggleFormGroup={() => setIsOpened(R.not)}
      text={G.getWindowLocale('titles:safety-scores', 'Safety Scores')}
    />
    {
      isOpened &&
      <Table
        itemList={itemList}
        columnSettings={safetySettings}
        tableSettings={{ checkboxCellWidth: 0, allowSelectItems: false }}
        report={{
          fields: G.mapIndexed((name: string, sequence: index) => ({ name, sequence }), R.keys(safetySettings)),
        }}
      />
    }
  </FormGroupWrapper>
));

const inputWrapperStyles = {
  mr: 30,
  width: 200,
  zIndex: 12,
};

const fieldSettings = [
  {
    type: 'datePicker',
    fieldName: 'fromDate',
    label: ['titles:date-from', 'Date From'],
    inputWrapperStyles: R.assoc('zIndex', 13, inputWrapperStyles),
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: 'filterType',
    options: 'filterTypeOptions',
    label: ['titles:filter-type', 'Filter Type'],
  },
  {
    type: 'reactSelect',
    fieldName: 'filterValue',
    options: 'filterValueOptions',
    label: ['titles:filter-value', 'Filter Value'],
    inputWrapperStyles: {
      width: 200,
      zIndex: 12,
      display: (props: Object) => G.ifElse(
        R.isNil(R.path(['values', 'filterType'], props)),
        'none',
      ),
    },
  },
];

const makeFilterTypeOptions = (divisionGuid: string) => {
  const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

  const currentBranchFilterTypeOption = {
    [GC.FIELD_VALUE]: currentBranchGuid,
    [GC.FIELD_LABEL]: G.getWindowLocale('titles:by-current-branch', 'Current Branch'),
  };

  if (R.or(G.isNilOrEmpty(divisionGuid), R.equals(divisionGuid, currentBranchGuid))) {
    return R.of(Array, currentBranchFilterTypeOption);
  }

  return [
    currentBranchFilterTypeOption,
    {
      [GC.FIELD_VALUE]: divisionGuid,
      [GC.FIELD_LABEL]: G.getWindowLocale('titles:trip-branch', 'Trip Branch'),
    },
  ];
};

const CarrierScorecards = (props: Object) => {
  const {
    pr,
    height,
    minHeight,
    overflowY,
    scorecard,
    carrierName,
    divisionGuid,
    normalizedCurrency,
    handleGetScorecardRequest,
  } = props;

  const { safetyScores, statisticData, numberOfTrucks } = R.or(scorecard, {});

  const filterType = R.path(['values', 'filterType'], props);

  return (
    <RelativeBox
      pr={pr}
      height={height}
      bg='light.mainLight'
      minHeight={minHeight}
      overflowY={overflowY}
    >
      <Flex pl={20} flexWrap='wrap'>
        <PageTitle
          mr={20}
          display='flex'
          pageTitleMarginTop={25}
          label={<Box ml='6px' cursor='pointer' onClick={handleGetScorecardRequest}>{I.reloadCircle()}</Box>}
          title={`${carrierName}${G.ifElse(G.isNotNilAndNotEmpty(numberOfTrucks), ` (${numberOfTrucks} Trucks)`, '')}`}
        />
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={fieldSettings}
          fieldsWrapperStyles={{ pt: 25 }}
          filterTypeOptions={makeFilterTypeOptions(divisionGuid)}
          filterValueOptions={R.pathOr([], ['divisions', filterType], props)}
        />
      </Flex>
      {G.isNotNilAndNotEmpty(safetyScores) && <Safety itemList={safetyScores} />}
      {
        G.isNotNilAndNotEmpty(statisticData) &&
        <Box p={20} overflowY='auto'>
          <Grid gridGap={20} gridTemplateColumns='repeat(auto-fill, minmax(350px, 1fr))'>
            {
              statisticData.map((item: Object, index: number) => (
                <Scorecard {...item} key={index} normalizedCurrency={normalizedCurrency} />
              ))
            }
          </Grid>
        </Box>
      }
      {
        G.isTrue(props.expandedContainer) &&
        <FixedFlex
          bottom='0'
          bg='white'
          zIndex={12}
          width='100%'
          p='14px 20px'
          boxShadow='standard'
        >
          <CancelButton
            height={32}
            width={150}
            p='4px 8px'
            fontSize={14}
            bgColor='none'
            background='none'
            border='1px solid'
            borderRadius='5px'
            textTransform='uppercase'
            textColor={G.getTheme('colors.greyMatterhorn')}
            borderColor={G.getTheme('colors.greyMatterhorn')}
            onClick={() => props.closeExpandedContainer(true)}
          >
            {G.getWindowLocale('actions:close', 'Close')}
          </CancelButton>
        </FixedFlex>
      }
    </RelativeBox>
  );
};


const enhance = compose(
  connect(null, { openLoader, closeLoader }),
  withFormik({
    mapPropsToValues: () => ({
      filterType: null,
      filterValue: null,
      fromDate: G.subtractMomentTimeWithFormat(G.getCurrentDate(), 3, 'months', GC.DEFAULT_DATE_FORMAT),
    }),
  }),
  withState('divisions', 'setDivisions', {}),
  withState('scorecard', 'setScorecard', null),
  withState('normalizedCurrency', 'setNormalizedCurrency', GC.DEFAULT_UI_CURRENCY),
  withHandlers({
    handleGetNormalizedCurrency: ({ setNormalizedCurrency }: Object) => async () => {
      const options = {
        params: { [GC.BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow() },
      };

      const res = await sendRequest('get', endpointsMap.branchNormalizedCurrency, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setNormalizedCurrency(data);
      } else {
        G.handleException('error handleGetNormalizedCurrency');
      }
    },
    handleGetDivisions: (props: Object) => async () => {
      const { divisions, setDivisions, openLoader, closeLoader, values: { filterType } } = props;

      if (R.or(R.isNil(filterType), R.has(filterType, divisions))) return;

      openLoader();

      const endpoint = endpointsMap.getBranchListByType(filterType);

      const options = {
        params: {
          [GC.BRANCH_TYPE]: GC.BRANCH_TYPE_ENUM_DIVISION,
        },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const divisionOptions = R.map(
          ({ name, guid }: Object) => ({ [GC.FIELD_VALUE]: guid, [GC.FIELD_LABEL]: name }),
          R.or(data, []),
        );

        setDivisions(R.assoc(filterType, divisionOptions, divisions));
      } else {
        G.handleException('error handleGetDivisions');
      }

      closeLoader();
    },
    handleGetScorecardRequest: (props: Object) => async () => {
      const {
        values,
        openLoader,
        closeLoader,
        carrierGuid,
        setScorecard,
      } = props;

      openLoader();

      const options = {
        data: { carrierGuid, fromDate: values.fromDate, filterByEnterpriseGuid: values.filterValue },
      };

      const res = await sendRequest('post', endpointsMap.carrierScorecard, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setScorecard(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      const { scorecard, handleGetScorecardRequest, handleGetNormalizedCurrency } = this.props;

      handleGetNormalizedCurrency();

      if (R.isNil(scorecard)) handleGetScorecardRequest();
    },
    componentDidUpdate(prevProps: Object) {
      const {
        setFieldValue,
        handleGetDivisions,
        handleGetScorecardRequest,
        values: { fromDate, filterType, filterValue },
      } = this.props;

      if (G.notEquals(filterType, R.path(['values', 'filterType'], prevProps))) {
        handleGetDivisions();

        if (R.isNil(filterType)) setFieldValue('filterValue', null);
      }

      if (G.isAllTrue(
        R.pathEq(fromDate, ['values', 'fromDate'], prevProps),
        R.pathEq(filterValue, ['values', 'filterValue'], prevProps),
      )) return;

      if (G.isValidMomentWithFormat(fromDate, GC.DEFAULT_DATE_FORMAT)) handleGetScorecardRequest();
    },
  }),
  pure,
);

export default enhance(CarrierScorecards);
