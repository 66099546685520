// helpers/constants
import * as GC from '../../../constants';
// feature chart
import { RC } from './filter-params';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 200,
    name: 'titles:description',
  },
  [RC.COLLECTION_DISPLAYED_VALUE]: {
    width: 250,
    name: 'titles:collection',
  },
  [GC.FIELD_CHART_DATA_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:data-type',
  },
  [GC.FIELD_CHART_GROUP_BY_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:group-by',
  },
  [GC.FIELD_CHART_CHART_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:chart-type',
  },
};
