// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const generalSection = {
  title: 'titles:password',
  fields: {
    [GC.PASSWORD_MIN_LENGTH]: {
      type: 'input',
      name: 'titles:minimum-length',
      validate: G.isEmptyOrInteger,
      nameForAttribute: GC.PASSWORD_MIN_LENGTH,
    },
    [GC.PASSWORD_MIN_SPECIAL]: {
      type: 'input',
      validate: G.isEmptyOrInteger,
      name: 'titles:minimum-special-symbols',
      nameForAttribute: GC.PASSWORD_MIN_SPECIAL,
    },
    [GC.PASSWORD_MIN_LOWER]: {
      type: 'input',
      validate: G.isEmptyOrInteger,
      nameForAttribute: GC.PASSWORD_MIN_LOWER,
      name: 'titles:minimum-lowercase-letters',
    },
    [GC.PASSWORD_MIN_UPPER]: {
      type: 'input',
      validate: G.isEmptyOrInteger,
      nameForAttribute: GC.PASSWORD_MIN_UPPER,
      name: 'titles:minimum-uppercase-letters',
    },
    [GC.PASSWORD_MIN_NUM]: {
      type: 'input',
      validate: G.isEmptyOrInteger,
      name: 'titles:minimum-numbers',
      nameForAttribute: GC.PASSWORD_MIN_NUM,
    },
    [GC.PASSWORD_VALIDATION_ENABLE]: {
      type: 'switcher',
      name: 'titles:enforce-rules',
      nameForAttribute: GC.PASSWORD_VALIDATION_ENABLE,
    },
  },
};

const ConfigPasswordGroup = {
  generalSection,
};

export {
  ConfigPasswordGroup,
};
