import * as R from 'ramda';
// components
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// constants
import * as GC from '../../../constants';
// features
import PC from '../../permission/role-permission';
// feature dashboards-payroll
import { FILTER_PARAMS } from './filter-params';
//////////////////////////////////////////////////

const stickyList = [GC.FIELD_CURRENCY, GC.GRC.ACCOUNTING_INTEGRATION_STATUS];

const actionButtons = [
  {
    iconName: 'pencil',
    action: () => {},
    permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
  },
  {
    iconName: 'trash',
    action: () => {},
    permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
  },
];

export const tableSettings = {
  stickyList,
  actionButtons,
  tableRowHeight: 32,
  tableTopIndent: 95,
  titleRowHeight: 42,
  withCheckbox: true,
  withLeftActions: true,
  withRightActions: true,
  withResizableColumns: true,
  filterProps: R.indexBy(
    R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
  ),
};
