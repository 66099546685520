import * as R from 'ramda';
import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature claim-management
import { useCreateDropdownOptionsFromAsyncConfigs } from '../../hooks';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 400,
};

const settings = {
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.CLAIM_DOCUMENT_TYPE,
    label: ['titles:document-type'],
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
  },
  [GC.FIELD_DOCUMENT_URL]: {
    type: 'text',
    isRequired: true,
    label: ['titles:url'],
    fieldName: GC.FIELD_DOCUMENT_URL,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: (props: Object) => {
        const { file, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(R.or(G.isNotNil(file), G.isNotNil(documentFilename)), 'none', 'block');
      },
    },
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    type: 'file',
    isRequired: true,
    label: ['titles:upload-file'],
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: (props: Object) => {
        const { url, version, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(
          R.or(G.isNotNilAndNotEmpty(url), R.and(G.isNotNil(version), R.isNil(documentFilename))),
          'none',
          'block',
        );
      },
    },
  },
};

const validationSchema = Yup.lazy(({ url, file }: Object) => Yup.object().shape({
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_UPLOAD]: G.ifElse(
    G.isNilOrEmpty(url),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.FIELD_DOCUMENT_URL]: G.ifElse(
    G.isNilOrEmpty(file),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

const useForm = (props: Object) => {
  const { submitAction, initialValues } = props;

  const onSubmit = (values: Object) => submitAction(G.mapObjectEmptyStringFieldsToNull(values));

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: G.setInitialFormikValues(
      R.map(() => null, settings),
      initialValues,
    ),
  });

  return formik;
};

const DocumentForm = (props: Object) => {
  const { claimBranchGuid } = props;

  const formik = useForm(props);

  const { handleSubmit } = formik;

  const options = useCreateDropdownOptionsFromAsyncConfigs({
    branchGuid: claimBranchGuid,
    names: GC.CLAIM_DOCUMENT_TYPE,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        {...options}
        fields={R.values(settings)}
        fieldsWrapperStyles={{ pt: 15, flexDirection: 'column' }}
      />
      <FormFooter2 />
    </form>
  );
};

export default DocumentForm;
