import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import { TextComponent } from '../../../components/text';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const auditDetailsFieldSettings = {
  [GC.FIELD_AUDIT_FIELD_NAME]: {
    minWidth: 175,
    name: G.getWindowLocale('titles:object-name', 'Object Name'),
  },
  [GC.FIELD_AUDIT_OLD_VALUE]: {
    minWidth: 175,
    name: G.getWindowLocale('titles:old-value', 'Old Value'),
  },
  [GC.FIELD_AUDIT_NEW_VALUE]: {
    minWidth: 175,
    name: G.getWindowLocale('titles:new-value', 'New Value'),
  },
};

const fieldSetting = {
  [GC.FIELD_DATE]: {
    width: 115,
    minWidth: 115,
    name: G.getWindowLocale('titles:date-time', 'Date/Time'),
  },
  [GC.FIELD_MODIFIED_BY]: {
    minWidth: 175,
    name: G.getWindowLocale('titles:user', 'User'),
  },
  [GC.FIELD_NAME]: {
    minWidth: 175,
    name: G.getWindowLocale('titles:name', 'Name'),
  },
  [GC.FIELD_STATUS]: {
    minWidth: 175,
    name: G.getWindowLocale('titles:action', 'Action'),
  },
};

const renderHeader = (fieldsSetting: Object) =>
  G.getNewArrayFromObjectWithHandling(
    (field: Object, key: string) => (
      <Box
        minWidth={field.minWidth}
        width={R.or(field.width, '100%')}
      >
        <TextComponent
          key={key}
          fontSize={12}
          color='darkGrey'
          textTransform='uppercase'
        >
          {field.name}
        </TextComponent>
      </Box>
    ),
    fieldsSetting,
  );

export {
  renderHeader,
  fieldSetting,
  auditDetailsFieldSettings,
};
