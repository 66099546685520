import * as R from 'ramda';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// common
import { openModal, closeModal } from '../../components/modal/actions';
// components
import { TitlePanel } from '../../components/title-panel';
import { PageActions } from '../../components/page-actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
// helpers/constants
import * as G from '../../helpers';
// hocs
import { withFixedPopover } from '../../hocs';
// hooks
import useReport from '../../hooks/use-report';
// ui
import { ListWrapper, ZOrderWrapper } from '../../ui';
// feature geo-fencing-location
import * as A from './actions';
import * as H from './helpers';
import LocationsList from './components/locations-list';
import CreateLocationForm from './components/location-form';
import ViewLocationPopup from './components/view-location-popup';
import {
  makeSelectItemList,
  makeSelectReportType,
  makeSelectCurrentLocation,
} from './selectors';
//////////////////////////////////////////////////

const GeoFencingLocation = () => {
  const dispatch = useDispatch();

  const locationList = useSelector(makeSelectItemList());
  const reportType = useSelector(makeSelectReportType());
  const currentLocation = useSelector(makeSelectCurrentLocation());

  const reportProps = {
    type: reportType,
    ...useReport({ A, type: 'geoFencingLocation' }),
  };

  const [mode, setMode] = useState(H.IDLE_MODE);
  const [formOpened, setFormOpened] = useState(false);

  const closePopup = useCallback(() => {
    dispatch(A.getGeoFencingLocationSuccess(null));
    dispatch(A.getFleetTrailerListSuccess(null));

    setFormOpened(false);
    setMode(H.IDLE_MODE);

    dispatch(closeModal());
  }, [setFormOpened]);

  const openCreateLocationForm = useCallback(() => {
    const component = (
      <CreateLocationForm
        closeModal={closePopup}
        currentLocation={currentLocation}
        handleCreateLocation={(values: Object) => {
          dispatch(A.submitGeoFencingLocationRequest({
            values,
            currentLocation,
          }));

          closePopup();
        }}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        width: 'auto',
        height: 'auto',
        title: G.getWindowLocale('titles:create-location', 'Create Location'),
      },
    };

    dispatch(openModal(modal));
  }, [closePopup, currentLocation]);

  const openViewLocationPopup = useCallback(() => {
    const component = (
      <ViewLocationPopup
        closeModal={closePopup}
        currentLocation={currentLocation}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        width: 'auto',
        height: 'auto',
        movable: false,
        withCloseIcon: true,
        title: G.getWindowLocale('titles:view-location', 'View Location'),
      },
    };

    dispatch(openModal(modal));
  }, [closePopup, currentLocation]);

  const editLocation = useCallback((guid: string) => {
    setMode(H.EDIT_MODE);

    dispatch(A.getGeoFencingLocationRequest({ guid, mode: H.EDIT_MODE }));
  }, [locationList]);

  const viewLocation = useCallback((guid: string) => {
    setMode(H.VIEW_MODE);

    dispatch(A.getGeoFencingLocationRequest({ guid, mode: H.VIEW_MODE }));
  }, [locationList]);

  useEffect(() => {
    if (G.isNotNilAndNotEmpty(currentLocation)) {
      if (R.not(formOpened)) {
        if (R.equals(mode, H.EDIT_MODE)) {
          openCreateLocationForm();
        } else {
          openViewLocationPopup();
        }

        setFormOpened(true);
      }
    } else if (formOpened) {
      setFormOpened(false);
      setMode(H.IDLE_MODE);
    }
  }, [formOpened, currentLocation, openCreateLocationForm, openViewLocationPopup]);

  return (
    <ListWrapper p={15} pl={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...reportProps}
          withCount={true}
          noExportable={true}
          hideFilterInfo={true}
          popperWithCount={true}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={false}
          withGlobalSearchFilter={false}
          filterProps={R.values(H.filterProps)}
          title={G.getWindowLocale('titles:geo-fencing-location', 'Geo Fencing Location')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <LocationsList
          {...reportProps}
          itemList={locationList}
          editLocation={editLocation}
          viewLocation={viewLocation}
          filterProps={R.values(H.filterProps)}
        />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.ANY]}>
        <PageActions
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            action: openCreateLocationForm,
            permissions: [PC.FLEET_TRAILER_WRITE],
            text: G.getWindowLocale('titles:create-location', 'Create Location'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

export default withFixedPopover(GeoFencingLocation);
