import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
// components
import { Tabs2 } from '../../../../components/tabs-mui';
// features
import { startPivotTable } from '../../../pivot-table/actions';
import PivotTableWrapper from '../../../pivot-table/components/pivot-table-wrapper';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch-report/route
import { getTabs } from '../helpers';
import { startRouteByLoad } from '../actions';
//////////////////////////////////////////////////

const PivotPayrolls = (props: Object) => {
  const {
    totalCount,
    startRouteByLoad,
    handleSetActiveTab,
  } = props;

  const handleClickTab = (tabIndex: number) => {
    if (R.equals(tabIndex, 0)) G.goToRoute(GC.ROUTE_PATH_ROUTE_REPORT_LIST);

    if (R.equals(tabIndex, 1)) startRouteByLoad();

    handleSetActiveTab(tabIndex);
  };
  const customTitleComponent = (
    <Tabs2
      activeTab={2}
      tabs={getTabs()}
      count={totalCount}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <PivotTableWrapper
      {...props}
      disableRefFilter={true}
      tabs={customTitleComponent}
      relativeFlexStyles={{ paddingTop: 15 }}
    />
  );
};

export default connect(null, {
  startPivotTable,
  startRouteByLoad,
})(PivotPayrolls);
