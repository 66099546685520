import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, withProps, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import * as C from '../../constants';
import RowActions from '../components/row-actions';
import { tableSettings, loadDocumentTabs, loadDocumentsListMap } from '../../settings/table-settings';
//////////////////////////////////////////////////

export const withTableGroupProps = compose(
  withState('activeListName', 'setActiveListName', C.ACTIVE_LIST_DOCUMENTS),
  withHandlers({
    handleChangeActiveList: (props: Object) => (listName: string, activeItem: boolean) => {
      const { mailList, setActiveListName, getLoadMailListRequest } = props;

      if (G.isAllTrue(
        G.isFalse(activeItem),
        G.isNilOrEmpty(mailList),
        R.equals(listName, C.ACTIVE_LIST_MAILS),
      )) getLoadMailListRequest();

      if (G.isFalse(activeItem)) setActiveListName(listName);
    },
    handleToggleExpandedList: ({ toggleExpandedListsSuccess }: Object) => () =>
      toggleExpandedListsSuccess({ tableNumber: 2 }),
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <RowActions
            entity={entity}
            load={props.load}
            events={props.events}
            itemList={props.itemList}
            openModal={props.openModal}
            closeModal={props.closeModal}
            loadConfigs={props.loadConfigs}
            activeTab={props.activeListName}
            loadDocuments={props.loadDocuments}
            activeListName={props.activeListName}
            loadTotalWeight={props.loadTotalWeight}
            closeFixedPopup={props.closeFixedPopup}
            loadTotalDistance={props.loadTotalDistance}
          />
        ),
      })
    ),
  }),
  withProps((props: Object) => {
    const {
      mailList,
      documents,
      pagination,
      totalCount,
      messageCenter,
      activeListName,
      linkedOrdersDocuments,
      handleLoadMoreEntities,
    } = props;

    const generateReport = (settings: Array) => R.compose(
      G.mapIndexed((item: string, index: number) => ({ sequence: index, [GC.FIELD_NAME]: item })),
      R.keys,
    )(settings);
    const itemListMap = {
      [C.ACTIVE_LIST_MAILS]: mailList,
      [C.ACTIVE_LIST_DOCUMENTS]: documents,
      [C.ACTIVE_LIST_NOTES]: messageCenter.noteList,
      [C.ACTIVE_LIST_CHAT_MESSAGES]: messageCenter.chatMessageList,
    };
    let columnSettings = R.path([activeListName, 'columnSettings'], loadDocumentsListMap);
    let itemList = R.pathOr([], [activeListName], itemListMap);

    const condition = R.and(
      G.isNotNilAndNotEmpty(linkedOrdersDocuments),
      R.equals(activeListName, C.ACTIVE_LIST_DOCUMENTS),
    );

    if (condition) {
      itemList = R.concat(itemList, linkedOrdersDocuments);
      columnSettings = R.mergeRight({
        isLinkedOrder: {
          width: 120,
          type: 'boolean',
          name: 'titles:from-cross-dock-order',
        },
      }, columnSettings);
    }

    const rowActionsPermissions = R.path([activeListName, 'rowActionsPermissions'], loadDocumentsListMap);
    const report = { fields: generateReport(columnSettings) };
    const tableData = {
      report,
      itemList,
      pagination,
      totalCount,
      tableSettings,
      columnSettings,
      withoutBorder: true,
      rowActionsPermissions,
      handleLoadMoreEntities,
      tableWrapperOverflow: 'none',
    };

    return { tableData, tabs: loadDocumentTabs };
  }),
  pure,
);
