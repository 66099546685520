import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import MultipleStatusMessageForm from '../../../../components/multiple-status-message';
// features
import StatusMessageFormComponent from '../../forms/status-message-form';
import StatusMessageMapComponent from '../../components/status-messages-map';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import AddStatusMessage from '../../components/add-status-message';
import {
  updateLoadStatusMessageRequest,
  removeLoadStatusMessageRequest } from '../actions';
//////////////////////////////////////////////////

export const withAddStatusMessage = compose(
  withHandlers({
    handleAddStatusMessage: (props: Object) => () => {
      const {
        load,
        events,
        openModal,
        closeModal,
        closeFixedPopup,
        createLoadStatusMessageRequest,
        loadConfigs: { configGroups, statusMessageConfigs } } = props;

      G.callFunction(closeFixedPopup);
      const handleCreateLoadStatusMessage = (values: Object) => {
        const data = {
          ...R.pick(GC.GROUPED_FIELDS.STATUS_MESSAGE_PICK_ARR, values),
          telStatusMessageLocation: R.omit(GC.GROUPED_FIELDS.STATUS_MESSAGE_OMIT_ARR, values),
        };
        createLoadStatusMessageRequest(data);
      };
      const initialValues = {
        [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE',
      };
      const component = (
        <StatusMessageFormComponent
          load={load}
          loadType={GC.FIELD_TEL}
          closeModal={closeModal}
          entityName={GC.FIELD_TEL}
          initialValues={initialValues}
          mapLocations={G.mapTelEventsForMap(events)}
          statusMessagesConfigs={statusMessageConfigs}
          communicationsConfigs={configGroups.communication}
          handleActionLoadStatusMessage={handleCreateLoadStatusMessage} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: null,
          height: 'auto',
          width: 'max-content',
        },
      };
      openModal(modal);
    },
    handleAddMultipleStatusMessage: (props: Object) => () => {
      const {
        load,
        events,
        openModal,
        closeModal,
        closeFixedPopup,
        createLoadMultipleStatusMessageRequest,
        loadConfigs: { configGroups, configsByNames, statusMessageConfigs } } = props;

      G.callFunction(closeFixedPopup);
      const allowStopCompleteWithoutArrival = G.getConfigValueFromStore(
        GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
        configsByNames,
      );
      const loadStatus = G.getPropFromObject(GC.FIELD_STATUS, load);
      const useArrivedDepartedForm = R.and(
        G.isFalse(allowStopCompleteWithoutArrival),
        R.includes(loadStatus, [GC.LOAD_STATUS_IN_TRANSIT, GC.LOAD_STATUS_BOOKED_STATUS]),
      );
      const componentProps = {
        load,
        closeModal,
        useArrivedDepartedForm,
        loadType: GC.LOAD_TYPE_TEL,
        events: G.mapTelEventsForMap(events),
        statusMessagesConfigs: statusMessageConfigs,
        communicationsConfigs: configGroups.communication,
        createMultipleStatusMessage: createLoadMultipleStatusMessageRequest,
      };
      const component = <MultipleStatusMessageForm {...componentProps} />;
      const modal = {
        p: 15,
        component,
        options: {
          title: null,
          height: 'auto',
          width: 'max-content',
        },
      };
      openModal(modal);
    },
    handleOpenStatusMessagesMap: (props: Object) => () => {
      const { load, itemList, events, openModal, closeModal } = props;

      const loadStatusMessageLocationName = G.ifElse(
        G.isLoadTypeClo(load),
        'cloStatusMessageLocation',
        'telStatusMessageLocation',
      );
      const statusMessages = R.compose(
        R.values,
        R.map((item: Object) => R.mergeRight(item, G.getPropFromObject(loadStatusMessageLocationName, item))),
        R.filter((item: Object) => G.isNotNilAndNotEmpty(G.getPropFromObject(loadStatusMessageLocationName, item))),
      )(itemList);
      if (G.isNilOrEmpty(statusMessages)) return;
      const component = (
        <StatusMessageMapComponent
          closeModal={closeModal}
          statusMessages={statusMessages}
          mappedEventsForMap={G.mapTelEventsForMap(events)} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'max-content',
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleOpenAddStatusMessage: (props: Object) => (event: Object) => {
      props.openFixedPopup({
        position: 'left',
        el: event.currentTarget,
        content: (
          <AddStatusMessage
            handleAddStatusMessage={props.handleAddStatusMessage}
            handleAddMultipleStatusMessage={props.handleAddMultipleStatusMessage} />
        ),
      });
    },
  }),
  pure,
);

export const withStatusMessageTableGroupRowActions = compose(
  connect(
    null,
    {
      updateLoadStatusMessageRequest,
      removeLoadStatusMessageRequest,
    },
  ),
  withHandlers({
    handleUpdateStatusMessage: (props: Object) => () => {
      const {
        load,
        entity,
        events,
        openModal,
        closeModal,
        updateLoadStatusMessageRequest,
        loadConfigs: { configGroups, statusMessageConfigs },
      } = props;
      const { telStatusMessageLocation } = entity;

      const getStatusCodeFromInitialValues = R.path([GC.FIELD_STATUS_MESSAGE_CODE, 'statusCodeConfigGuid'], entity);
      const statusCode = R.compose(
        R.pathOr(null, [GC.FIELD_ORIGINAL_CONFIG_GUID]),
        R.find(R.propEq(getStatusCodeFromInitialValues, GC.FIELD_ORIGINAL_CONFIG_GUID)),
      )(statusMessageConfigs);
      const location = G.concatLocationFields(telStatusMessageLocation);
      const locationFields = R.pick(
        [
          GC.FIELD_ZIP,
          GC.FIELD_CITY,
          GC.FIELD_STATE,
          GC.FIELD_COUNTRY,
          GC.FIELD_LATITUDE,
          GC.FIELD_LONGITUDE,
        ],
        R.or(telStatusMessageLocation, {}),
      );
      const initialValues = {
        ...entity,
        ...locationFields,
        location,
        statusCode,
        [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE',
      };
      const component = (
        <StatusMessageFormComponent
          load={load}
          loadType={GC.FIELD_TEL}
          closeModal={closeModal}
          entityName={GC.FIELD_TEL}
          initialValues={initialValues}
          mapLocations={G.mapTelEventsForMap(events)}
          statusMessagesConfigs={statusMessageConfigs}
          communicationsConfigs={configGroups.communication}
          handleActionLoadStatusMessage={updateLoadStatusMessageRequest} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: null,
          height: 'auto',
          width: 'max-content',
        },
      };
      openModal(modal);
    },
    handleRemoveStatusMessage: ({ entity, openModal, removeLoadStatusMessageRequest }: Object) => () => {
      const textLocale = G.getWindowLocale(
        'messages:remove-confirmation-status-text',
        'Are you sure you want delete this status message?',
      );
      const component = <ConfirmComponent textLocale={textLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeLoadStatusMessageRequest(entity[GC.FIELD_GUID]),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
);
