import React from 'react';
// components
import ZIndexClick from '../../../components/z-index-click';
// helpers
import * as G from '../../../helpers';
// ui
import { Box, Text } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const ZIndexClickComponent = () => {
  const description = '<ZIndexClick /> component with the ability to change zIndex on click and handle outside click.';

  const usage = (
    <ul>
      <li>Parameter `<i>zIndexOnClick</i>` overrides the z-index when the component is clicked.</li>
      <li>Parameter `<i>initialZIndex</i>` specifies the initial z-index value for the component.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='ZIndexClick'
      description={description}
      path='src/components/z-index-click'
    >
      <ZIndexClick
        initialZIndex={100}
        zIndexOnClick={2000}
      >
        <Box p={15} cursor='pointer' border={`1px solid ${G.getTheme('colors.silver')}`}>
          <Text>
            *If you want to see the result, open the developer tools and check the z-index in this box.
          </Text>
        </Box>
      </ZIndexClick>
    </ComponentStoryWrapper>
  );
};

export default ZIndexClickComponent;
