import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// common
import { setConnectedToMailIntegrationEmails } from '../../../../common/actions';
// components
import { closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
import {
  getConnectionToMailIntegrationRequiredRequest,
  getMailServiceIntegrationConfigAvailableIntegrationTypeRequest,
} from '../../../../sagas/global-actions-saga';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import { mapStatusMsgResponse } from '../../helpers';
import { makeSelectCommunicationMails } from '../../selectors';
import { getConfigByGroupSaga, getCustomerBranchListRequest, getReferenceTypesByScopeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

function* getConnectedToMailIntegrationEmailsRequest({ payload }: Object = {}) {
  try {
    if (R.and(G.isNotNilAndNotEmpty(payload), R.not(R.pathEq(GC.COMMUNICATION_CONFIG_GROUP, ['groupName'], payload)))) {
      return false;
    }

    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const communicationMails = yield select(makeSelectCommunicationMails());

    if (G.isNilOrEmpty(communicationMails)) return false;

    const emails = R.compose(
      R.join(','),
      R.uniq,
      R.values,
    )(communicationMails);

    const options = {
      params: { emails, [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.isConnectedToMail, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const connectedMails = R.indexBy(R.prop(GC.FIELD_EMAIL), R.or(data, []));

      yield put(setConnectedToMailIntegrationEmails(connectedMails));
    } else {
      yield call(G.handleException('error', 'getConnectedToMailIntegrationEmailsRequest fail'));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConnectedToMailIntegrationEmailsRequest exception');
  }
}

const mapStatusMsgItem = (item: Object) => {
  const reasonCodes = R.prop('reasonCodes', item);
  if (G.isNotNil(reasonCodes)) {
    return R.assoc('reasonCodes', JSON.parse(reasonCodes), item);
  }
  return item;
};

function* getStatusMessagesForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.statusMessagesListEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedData = mapStatusMsgResponse(data);
      yield put(A.getStatusMessagesForConfigSuccess(mappedData));
    } else {
      yield call(G.handleFailResponse, res, 'getStatusMessagesForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getStatusMessagesForConfigSaga exception');
  }
}

function* handleGetDocumentsForConfigSaga() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.documentListEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDocumentsForConfigSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDocumentsForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetDocumentsForConfigSaga exception');
  }
}

const createValidData = (data: Object) => {
  const reasonCodes = R.prop('reasonCodes', data);
  if (G.isNotNil(reasonCodes)) {
    return R.assoc('reasonCodes', JSON.stringify(reasonCodes), data);
  }
  return data;
};

function* handleCreateStatusMessageSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqData = R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload);
    const options = {
      data: createValidData(reqData),
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.statusMessagesEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
      yield put(A.createStatusMessageSuccess(mapStatusMsgItem(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateStatusMessageSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateStatusMessageSaga exception');
  }
}

function* getNotificationTriggers() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.notificationTriggersEndpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getNotificationTriggersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getNotificationTriggers fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getNotificationTriggers exception');
  }
}

function* handleCreateNotificationsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.createNotificationEndpoind, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.createNotificationSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateNotificationsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateNotificationsSaga exception');
  }
}

function* handleUpdateNotificationConfigSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'put', endpointsMap.createNotificationEndpoind, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.updateNotificationConfigsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateNotificationConfigSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateNotificationConfigSaga exception');
  }
}

function* getNotificationListTriggers() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.notificationListTriggers, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getNotificationListTriggersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getNotificationListTriggers fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getNotificationListTriggers exception');
  }
}

function* handleDeleteNotification({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getNotificationConfigEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteNotificationSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteNotification fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteNotification exception');
  }
}

function* handleDeleteDocumentConfigSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.documentEndpoint;
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const data = {
      [GC.FIELD_GUID]: payload,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };
    const res = yield call(sendRequest, 'delete', endpoint, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteDocumentConfigSuccess(payload));
      yield call(G.showToastrMessage, 'info', 'message:login:applied');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDocumentConfigSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDocumentConfigSaga exception');
  }
}

function* handleCreateDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = [JSON.stringify({
      ...payload,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    })];
    const formData = new window.FormData();
    formData.append('file', payload.file);
    formData.append('data', new window.Blob(reqBody, { type: 'application/json' }));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.documentEndpoint,
      { data: formData },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createDocumentSuccess(data));
      yield call(G.showToastrMessage, 'info', 'message:login:applied');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDocumentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDocumentSaga exception');
  }
}

function* handleUpdateStatusMessageSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: createValidData(payload),
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.statusMessagesEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
      yield put(A.updateStatusMessageSuccess(mapStatusMsgItem(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateStatusMessageSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateStatusMessageSaga exception');
  }
}

function* handleUpdateDocumentSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const formData = new window.FormData();
    formData.append('file', payload.file);
    formData.append('data', new window.Blob([JSON.stringify(payload)], { type: 'application/json' }));
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.documentEndpoint,
      { data: formData },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateDocumentSuccess(data));
      yield call(G.showToastrMessage, 'info', 'message:login:applied');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDocumentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDocumentSaga exception');
  }
}

function* handleDeleteStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getDeleteStatusMessageEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteStatusMessageSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteStatusMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteStatusMessageRequest exception');
  }
}

function* handleDownloadTemplateSaga({ payload }: Object) {
  try {
    const options = {
      resType: 'arraybuffer',
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.downloadDocTemplateEndpoint(payload.guid),
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      FileDownload(data, payload.fileName);
    } else {
      yield call(G.handleFailResponse, res, 'handleDownloadTruckSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDownloadTruckSaga exception');
  }
}

function* handleGetDataSourceListRequest() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.dataSourceList);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDataSourceListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'handleGetDataSourceListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetDataSourceListRequest exception');
  }
}

// integration mail config
function* getIntegrationMailConfigRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getIntegrationMailConfigSuccess,
      parentSagaName: 'getIntegrationMailConfigRequest',
      endpoint: endpointsMap.mailServiceIntegrationConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getIntegrationMailConfigRequest exception');
  }
}

function* createOrUpdateIntegrationMailConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const isCreate = G.isNilOrEmpty(payload.version);
    const method = G.ifElse(
      isCreate,
      'post',
      'put',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.mailServiceIntegrationConfig, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getIntegrationMailConfigRequest());
      yield call(getConnectionToMailIntegrationRequiredRequest);
      yield call(getMailServiceIntegrationConfigAvailableIntegrationTypeRequest);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (R.propEq(true, GC.FIELD_ENABLED, data)) yield call(getConnectedToMailIntegrationEmailsRequest);
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateIntegrationMailConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateIntegrationMailConfigRequest exception');
  }
}

function* removeIntegrationMailConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeMailServiceIntegrationConfig(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getIntegrationMailConfigRequest());
      yield call(getConnectionToMailIntegrationRequiredRequest);
      yield call(getMailServiceIntegrationConfigAvailableIntegrationTypeRequest);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeIntegrationMailConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeIntegrationMailConfigRequest exception');
  }
}

// mail template config
function* getMailTemplateTypesRequest({ payload }: Object) {
  try {
    if (G.isFalse(payload)) yield put(openLoader());

    const res = yield call(sendRequest, 'get', endpointsMap.mailTemplateTypes);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getMailTemplateTypesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getMailTemplateTypesRequest fail');
    }

    if (G.isFalse(payload)) yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getMailTemplateTypesRequest exception');

    if (G.isFalse(payload)) yield put(closeLoader());
  }
}

function* getMailTemplateNamesByTypeRequest({ payload }: Object) {
  try {
    const { editMode, templateType } = payload;

    if (G.isFalse(editMode)) yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_TYPE]: templateType,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.mailTemplateNames, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getMailTemplateNamesByTypeSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getMailTemplateNamesByTypeRequest fail');
    }

    if (G.isFalse(editMode)) yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getMailTemplateNamesByTypeRequest exception');

    if (G.isFalse(G.getPropFromObject('editMode', payload))) yield put(closeLoader());
  }
}

function* getMailTemplateByKeyRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_KEY]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.getMailTemplateByKey, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getMailTemplateByKeySuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getMailTemplateByKeyRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getMailTemplateByKeyRequest exception');

    yield put(closeLoader());
  }
}

function* getMailTemplateConfigRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.mailTemplateList,
      successAction: A.getMailTemplateConfigSuccess,
      parentSagaName: 'getMailTemplateConfigRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getMailTemplateConfigRequest exception');
  }
}

function* createOrUpdateMailTemplateConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const isCreate = G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload));

    const method = G.ifElse(
      isCreate,
      'post',
      'put',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.mailTemplate, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getMailTemplateConfigRequest());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateMailTemplateConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'createOrUpdateMailTemplateConfigRequest exception');

    yield put(closeLoader());
  }
}

function* removeMailTemplateConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_GUID]: payload },
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.mailTemplate, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getMailTemplateConfigRequest());

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeMailTemplateConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'removeMailTemplateConfigRequest exception');

    yield put(closeLoader());
  }
}

// sending documents config
function* getDocumentSendingConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.documentSendingConfigList,
      successAction: A.getDocumentSendingConfigListSuccess,
      parentSagaName: 'getDocumentSendingConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDocumentSendingConfigListRequest exception');
  }
}

function* createOrUpdateDocumentSendingConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const isCreate = G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload));

    const method = G.ifElse(
      isCreate,
      'post',
      'put',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      options,
      method,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.documentSendingConfig,
      successAction: A.createOrUpdateDocumentSendingConfigSuccess,
      parentSagaName: 'createOrUpdateDocumentSendingConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'createOrUpdateDocumentSendingConfigRequest exception');

    yield put(closeLoader());
  }
}

function* removeDocumentSendingConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.documentSendingConfig,
      successAction: A.removeDocumentSendingConfigSuccess,
      parentSagaName: 'removeDocumentSendingConfigRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'removeDocumentSendingConfigRequest exception');

    yield put(closeLoader());
  }
}

function* handleVisitConfigCommunicationPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_COMMUNICATION_PAGE);

    yield put(openLoader({ showDimmer: true }));

    yield call(getConfigByGroupSaga, GC.COMMUNICATION_CONFIG_GROUP);
    yield call(getStatusMessagesForConfigSaga);
    yield call(handleGetDocumentsForConfigSaga);
    yield call(getNotificationListTriggers);

    yield put(A.getAvailableDocumentTypes());

    yield call(handleGetDataSourceListRequest);
    yield call(getIntegrationMailConfigRequest);
    yield call(getMailTemplateConfigRequest);
    yield call(getCustomerBranchListRequest);
    yield call(getDocumentSendingConfigListRequest);
    yield call(getNotificationTriggers);
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_TEL });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CLO });

    yield put(closeLoader());

    break;
  }
}

function* communicationConfigWatcherSaga() {
  yield takeLatest(A.createDocumentRequest, handleCreateDocumentSaga);
  yield takeLatest(A.updateDocumentRequest, handleUpdateDocumentSaga);
  yield takeLatest(A.deleteNotificationRequest, handleDeleteNotification);
  yield takeLatest(A.getNotificationTriggersRequest, getNotificationTriggers);
  yield takeLatest(A.createNotificationRequest, handleCreateNotificationsSaga);
  yield takeLatest(A.createStatusMessageRequest, handleCreateStatusMessageSaga);
  yield takeLatest(A.updateStatusMessageRequest, handleUpdateStatusMessageSaga);
  yield takeLatest(A.deleteDocumentConfigRequest, handleDeleteDocumentConfigSaga);
  yield takeLatest(A.downloadDocumentTemplateRequest, handleDownloadTemplateSaga);
  yield takeLatest(A.deleteStatusMessageRequest, handleDeleteStatusMessageRequest);
  yield takeLatest(A.getDocumentsForConfigRequest, handleGetDocumentsForConfigSaga);
  yield takeLatest(A.getNotificationListTriggersRequest, getNotificationListTriggers);
  yield takeLatest(A.getStatusMessagesForConfigRequest, getStatusMessagesForConfigSaga);
  yield takeLatest(A.updateNotificationConfigsRequest, handleUpdateNotificationConfigSaga);
  yield takeLatest(GC.VISIT_CONFIG_COMMUNICATION_PAGE, handleVisitConfigCommunicationPageSaga);
  // integration mail config
  yield takeLatest(A.getIntegrationMailConfigRequest, getIntegrationMailConfigRequest);
  yield takeLatest(A.getConfigByGroupSuccess, getConnectedToMailIntegrationEmailsRequest);
  yield takeLatest(A.removeIntegrationMailConfigRequest, removeIntegrationMailConfigRequest);
  yield takeLatest(A.createOrUpdateIntegrationMailConfigRequest, createOrUpdateIntegrationMailConfigRequest);
  // mail template config
  yield takeLatest(A.getMailTemplateTypesRequest, getMailTemplateTypesRequest);
  yield takeLatest(A.getMailTemplateByKeyRequest, getMailTemplateByKeyRequest);
  yield takeLatest(A.getMailTemplateConfigRequest, getMailTemplateConfigRequest);
  yield takeLatest(A.removeMailTemplateConfigRequest, removeMailTemplateConfigRequest);
  yield takeLatest(A.getMailTemplateNamesByTypeRequest, getMailTemplateNamesByTypeRequest);
  yield takeLatest(A.createOrUpdateMailTemplateConfigRequest, createOrUpdateMailTemplateConfigRequest);
  // document sending config
  yield takeLatest(A.removeDocumentSendingConfigRequest, removeDocumentSendingConfigRequest);
  yield takeLatest(A.getDocumentSendingConfigListRequest, getDocumentSendingConfigListRequest);
  yield takeLatest(A.createOrUpdateDocumentSendingConfigRequest, createOrUpdateDocumentSendingConfigRequest);
}

export default communicationConfigWatcherSaga;
