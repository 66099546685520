import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// component
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import DriverRateForm, { EditDriverRateForm } from '../../rate/driver';
import CarrierRateForm, { EditCarrierRateForm } from '../../rate/carrier';
import { addNewRateToLoadPlannerTel } from '../../dispatch-planner-events/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature dispatch-planner
import * as H from '../helpers';
import SortableRates from '../components/sortable-rates';
import { saveMultipleCarrierRates, addNewRateToRouteBuilderTel } from '../actions';
//////////////////////////////////////////////////

// TODO: test checkAllowCustomerRateForDriver logic

const getTelWithGuid = (initialTel: Object) => G.ifElse(
  R.propEq(true, 'isNew', initialTel),
  R.assoc(GC.FIELD_GUID, null, initialTel),
  initialTel,
);

const removePropsFromNewItems = (propsToOmit: Array, list: Array) => R.map(
  (item: Object) => {
    if (R.propEq(true, 'isNew', item)) {
      return R.omit(propsToOmit, item);
    }

    return item;
  },
  list,
);

// const checkAllowCustomerRateForDriver = (props: Object) => {
//   const { telGuid, charges, telChanged } = props;

//   const mainCharge = R.find(R.propEq(GC.CHARGE_TYPE_MAIN, GC.FIELD_TYPE), charges);
//   const customerRateArr = [GC.CHARGE_RATE_TYPE_CUSTOMER_RATE, GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL];
//   const isCustomerMainCharge = R.includes(R.path([GC.FIELD_CHARGE_RATE_TYPE], mainCharge), customerRateArr);

//   return R.and(R.or(telChanged, R.isNil(telGuid)), isCustomerMainCharge);
// };

export const withPlannerRates = (options: Object) => compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      openLoader,
      closeLoader,
      saveMultipleCarrierRates,
      addNewRateToLoadPlannerTel,
      addNewRateToRouteBuilderTel,
    },
  ),
  withHandlers({
    handleSaveTelRate: (props: Object) => (values: Object) => {
      const { closeModal, addNewRateToLoadPlannerTel, addNewRateToRouteBuilderTel } = props;

      const page = R.path(['page'], options);

      const action = G.ifElse(
        G.isPageDispatchPlannerEvents(page),
        addNewRateToLoadPlannerTel,
        addNewRateToRouteBuilderTel,
      );

      const withoutTeamDriver = G.isNilOrEmpty(R.prop(GC.FIELD_SECONDARY_DRIVER_GUID, values));
      const telGuid = R.path([GC.FIELD_TEL, GC.FIELD_GUID], props);

      const rate = G.ifElse(
        withoutTeamDriver,
        R.omit(
          [
            GC.FIELD_SECONDARY_DRIVER_GUID,
            GC.FIELD_SECONDARY_DRIVER_CHARGES,
          ],
          values,
        ),
        values,
      );

      const fleetAssignmentFields = R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, rate);
      const rateFleetAssignment = R.pathOr({}, [GC.FIELD_FLEET_ASSIGNMENT], rate);
      const fullFleetAssignment = R.omit(GC.SYSTEM_LIST_TRAILERS, R.mergeRight(rateFleetAssignment, fleetAssignmentFields));

      const omittedRate = R.omit(
        [
          GC.FIELD_FLEET_ASSIGNMENT,
          ...GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR,
        ],
        rate,
      );

      const fleetAssignment = G.ifElse(
        withoutTeamDriver,
        R.omit(
          [
            GC.FIELD_SECONDARY_DRIVER,
            GC.FIELD_SECONDARY_DRIVER_GUID,
          ],
          fullFleetAssignment,
        ),
        fullFleetAssignment,
      );

      const data = {
        telGuid,
        rate: R.assoc(GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT, fleetAssignment, omittedRate),
      };

      action(data);

      closeModal();
    },
    handleSaveCarrierRate: (props: Object) => (values: Object) => {
      const { closeModal, addNewRateToLoadPlannerTel, addNewRateToRouteBuilderTel } = props;

      const page = R.path(['page'], options);

      const action = G.ifElse(
        G.isPageDispatchPlannerEvents(page),
        addNewRateToLoadPlannerTel,
        addNewRateToRouteBuilderTel,
      );

      const telGuid = R.path([GC.FIELD_TEL, GC.FIELD_GUID], props);

      action({ rate: values, telGuid });

      closeModal();
    },
  }),
  withHandlers({
    handleAddTelDriverRate: (props: Object) => (initialTel: Object, telChanged: boolean=false) => {
      const { openModal, handleSaveTelRate } = props;

      const tel = getTelWithGuid(initialTel);
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, tel);

      const telEvents = removePropsFromNewItems(
        ['isNew', GC.FIELD_GUID],
        R.values(R.prop(GC.FIELD_LOAD_STOPS, tel)),
      );

      const telEventsWithItems = H.setTelEventItemsForRateAssignRequest(telEvents, props.items);

      const modalContent = (
        <DriverRateForm
          tel={tel}
          telGuid={telGuid}
          isFromPlanner={true}
          branchGuid={branchGuid}
          telChanged={telChanged}
          telEvents={telEventsWithItems}
          handleSendTelRate={handleSaveTelRate}
          configsNamesArray={GC.ADD_RATE_CONFIGS_ARRAY}
          stopCount={R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], tel))}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleUpdateTelDriverRate: (props: Object) => (initialTel: Object, telChanged: boolean = false) => {
      const { openModal, closeModal, handleSaveTelRate } = props;

      const tel = getTelWithGuid(initialTel);
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      const telStatus = R.prop(GC.FIELD_STATUS, tel);
      const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, tel);
      const initialValues = R.assoc(GC.FIELD_TEL_GUID, telGuid, tel.rate);

      const telEvents = removePropsFromNewItems(
        ['isNew', GC.FIELD_GUID],
        R.values(R.prop(GC.FIELD_LOAD_STOPS, tel)),
      );

      const telEventsWithItems = H.setTelEventItemsForRateAssignRequest(telEvents, props.items);

      const modalContent = (
        <EditDriverRateForm
          telGuid={telGuid}
          isFromPlanner={true}
          telStatus={telStatus}
          branchGuid={branchGuid}
          telChanged={telChanged}
          closeModal={closeModal}
          initialValues={initialValues}
          telEvents={telEventsWithItems}
          handleSendTelRate={handleSaveTelRate}
          configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleAddTelCarrierRate: (props: Object) => (initialTel: Object, telChanged: boolean=false) => {
      const { openModal, handleSaveCarrierRate } = props;

      const tel = getTelWithGuid(initialTel);
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, tel);
      const telIsNew = R.propEq(true, 'isNew', initialTel);

      const stops = R.compose(
        R.values,
        R.pathOr({}, [GC.FIELD_LOAD_STOPS]),
      )(tel);

      const telEvents = removePropsFromNewItems(
        ['isNew', GC.FIELD_GUID],
        stops,
      );

      const loadData = G.ifElse(telIsNew, { events: telEvents }, undefined);

      const modalContent = (
        <CarrierRateForm
          stops={stops}
          telGuid={telGuid}
          loadData={loadData}
          branchGuid={branchGuid}
          telChanged={telChanged}
          stopCount={R.length(stops)}
          handleSendTelRate={handleSaveCarrierRate}
          configsNamesArray={GC.ADD_CARRIER_RATE_CONFIGS_ARRAY}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleUpdateTelCarrierRate: (props: Object) => (initialTel: Object, telChanged: boolean=false) => {
      const { openModal, handleSaveCarrierRate } = props;

      const tel = getTelWithGuid(initialTel);
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, tel);
      const telIsNew = R.propEq(true, 'isNew', initialTel);

      const telEvents = removePropsFromNewItems(
        ['isNew', GC.FIELD_GUID],
        R.values(R.prop(GC.FIELD_LOAD_STOPS, tel)),
      );

      const loadData = G.ifElse(telIsNew, { events: telEvents }, undefined);
      const initialValues = R.assoc(GC.FIELD_TEL_GUID, telGuid, tel.rate);

      const modalContent = (
        <EditCarrierRateForm
          telGuid={telGuid}
          loadData={loadData}
          branchGuid={branchGuid}
          telChanged={telChanged}
          initialValues={initialValues}
          handleSendTelRate={handleSaveCarrierRate}
          configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    handleMultiCarrierRates: (props: Object) => () => {
      const {
        tel,
        openModal,
        closeModal,
        currentRoute,
        saveMultipleCarrierRates,
      } = props;

      const width = 500;
      const telIsNew = R.propEq(true, 'isNew', tel);

      const stops = R.compose(
        R.values,
        R.pathOr({}, [GC.FIELD_LOAD_STOPS]),
      )(tel);

      const loadData = G.ifElse(
        telIsNew,
        { events: removePropsFromNewItems(['isNew', GC.FIELD_GUID], stops) },
        undefined,
      );

      const modal = {
        fixedWidth: true,
        isExpandedContainer: true,
        wrapperStyles: { width },
        title: G.getWindowLocale('titles:carrier-rates', 'Carrier Rates'),
        component: (
          <SortableRates
            loadData={loadData}
            openModal={openModal}
            closeModal={closeModal}
            tel={getTelWithGuid(tel)}
            currentRoute={currentRoute}
            saveRates={saveMultipleCarrierRates}
          />
        ),
        options: {
          minWidth: width,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
