import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import Loader from '../../../components/loader';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import { setShowLoader } from '../actions';
import { makeSelectShowLoader } from '../selectors';
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const LoaderComponent = ({ showLoader, setShowLoader }: Object) => {
  const description = '<Loader /> component is used as a loader element in the application to display a loading indicator.';

  const usage = (
    <p>Not available props</p>
  );

  const showLoaderHandler = () => {
    setShowLoader();

    setTimeout(() => {
      setShowLoader();
    }, 1000);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='Loader'
      description={description}
      path='src/components/loader'
    >
      {showLoader && <Loader />}
      <CustomButton onClick={showLoaderHandler}>Show Loader</CustomButton>
    </ComponentStoryWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  showLoader: makeSelectShowLoader(state),
});

export default connect(mapStateToProps, {
  setShowLoader,
})(LoaderComponent);
