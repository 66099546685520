import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// components
import { SelectCarriers } from '../../../../components/select-carriers';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  TIME_ZONE_OPTIONS,
  accountingOptions,
  encodingTypeOptions,
  gpsIntegrationConfigTypes,
  documentTypeMappingOptions,
  statusCodeMappingTypeOptions,
  documentHubTypeMappingOptions,
  ediExporterContentFormatOptions,
  factoringIntegrationTypeOptions,
  getCarrierRateIntegrationOptions,
  crossBorderIntegrationTypeOptions,
  statusReasonCodeMappingTypeOptions,
  getUserMonitoringIntegrationOptions,
  getEDIConfigLoadIdentificationTypes,
  statusCodeOutboundMappingTypeOptions,
  getEDIConfigSenderIdentificationTypes,
  serviceTypeMappingIntegrationTypeOptions,
  getCarrierEdiExporterTransactionTypeOptions,
  getCustomerEdiExporterTransactionTypeOptions,
  getCustomerEdiExporterReceiverQualifierTypeOptions,
} from '../../../../helpers/options';
// feature configurations
import { TIME_ZONE_TYPES_MAP } from './constants';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '270px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

const inputWrapperStyles = { mb: 25, width: 270 };

const fieldNotRequired = Yup.string().nullable(true).notRequired();
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

export const defaultFuelCardFields = {
  [GC.FIELD_ENABLED]: true,
  [GC.FIELD_ACCOUNT_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_LOGIN]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_PASSWORD]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_CARRIER_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_START_CODE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_TYPE]: GC.FUEL_CARDS_TYPE_EFS,
  [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_DEACTIVATE_CARD_AFTER_MINUTER]: null,
};

export const fuelCardFields = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:fuel-card-type',
    customDisabledFunction: 'handleDisableFuelCardsType',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_TYPE,
    options: [
      {
        value: GC.FUEL_CARDS_TYPE_EFS,
        label: GC.FUEL_CARDS_TYPE_EFS,
      },
      {
        value: GC.FUEL_CARDS_TYPE_QUIKQ,
        label: GC.FUEL_CARDS_TYPE_QUIKQ,
      },
      {
        label: 'BVD Group',
        value: GC.FUEL_CARDS_TYPE_BVD,
      },
      {
        label: 'Relay',
        value: GC.FUEL_CARDS_TYPE_RELAY,
      },
      {
        value: GC.FUEL_CARDS_TYPE_COMDATA,
        label: G.getWindowLocale('titles:comdata', 'Comdata'),
      },
    ],
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:enabled',
    fieldName: GC.FIELD_ENABLED,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    fieldName: 'useRetailPrice',
    loc: 'titles:use-retail-price',
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, GC.FUEL_CARDS_TYPE_EFS),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:account-id',
    fieldName: GC.FIELD_ACCOUNT_ID,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, GC.FUEL_CARDS_TYPE_RELAY),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:login',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_LOGIN,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      G.notContain(type, [GC.FUEL_CARDS_TYPE_BVD, GC.FUEL_CARDS_TYPE_RELAY]),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_PASSWORD,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      G.notContain(type, [GC.FUEL_CARDS_TYPE_BVD, GC.FUEL_CARDS_TYPE_RELAY]),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:carrier-id',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_CARRIER_ID,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, GC.FUEL_CARDS_TYPE_EFS),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:start-code',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_START_CODE,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, GC.FUEL_CARDS_TYPE_QUIKQ),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:deactivate-card-after-minutes',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_DEACTIVATE_CARD_AFTER_MINUTER,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, GC.FUEL_CARDS_TYPE_EFS),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:api-key',
    fieldName: GC.FIELD_API_KEY,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, GC.FUEL_CARDS_TYPE_BVD),
      'flex',
      'none',
    ),
  },
];

export const getFuelCardValidationSchema = (type: string, isEditMode: boolean) => {
  const schema = {
    [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_TYPE]: fieldRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_LOGIN]: fieldRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_PASSWORD]: G.ifElse(
      G.isTrue(isEditMode),
      fieldNotRequired,
      fieldRequired,
    ),
  };

  switch (type) {
    case GC.FUEL_CARDS_TYPE_EFS:
      const deactivateMin = Yup.number()
        .nullable(true)
        .typeError(G.getShouldBeIntegerLocaleTxt())
        .positive(G.getShouldBePositiveLocaleTxt())
        .min(0, G.getShouldBeFromToLocaleTxt(0, 1440))
        .max(1440, G.getShouldBeFromToLocaleTxt(1, 1440));

      return {
        ...schema,
        [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_CARRIER_ID]: fieldRequired,
        [GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_DEACTIVATE_CARD_AFTER_MINUTER]: deactivateMin,
      };
    case GC.FUEL_CARDS_TYPE_QUIKQ:
      return R.assoc(GC.FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_START_CODE, fieldRequired, schema);
    case GC.FUEL_CARDS_TYPE_BVD:
      return { [GC.FIELD_TYPE]: fieldRequired, [GC.FIELD_API_KEY]: fieldRequired };
    case GC.FUEL_CARDS_TYPE_RELAY:
      return { [GC.FIELD_TYPE]: fieldRequired, [GC.FIELD_ACCOUNT_ID]: fieldRequired };
    default:
      return schema;
  }
};

export const defaultEdiConfigsFields = {
  [GC.FIELD_CUSTOMER_GUID]: null,
  [GC.FIELD_SEND_RESPONSE]: false,
  [GC.FIELD_DROP_STOP_TYPES]: null,
  [GC.FIELD_PICKUP_STOP_TYPES]: null,
  [GC.FIELD_AUTO_CREATE_ORDER]: false,
  [GC.FIELD_AUTO_UPDATE_EVENT_DATES]: false,
  [GC.FIELD_USE_SHIPMENT_NUMBER_AS_PICKUP_NUMBER]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_AUTO_ACCEPT]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_DEFAULT_ITEM_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_NUMBER]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_REFERENCE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PO_NUMBER_REFERENCE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_ITEM_TEMPLATES]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_LOCATION_TEMPLATES]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE]: null,
  instructionsField: GC.SPECIAL_INSTRUCTIONS_FIELD_TYPE_SPECIAL_INSTRUCTIONS,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_OVERRIDE_CONFIGURED_BILL_TO]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_ORDER_IDENTIFICATION_REFERENCE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES]: false,
};

export const ediConfigsFields = [
  {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:customer',
    options: 'branchListByType',
    fieldName: GC.FIELD_CUSTOMER_GUID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:folderName',
    customDisabledFunction: 'handleDisableFolder',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:sender-identification',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:senderIdentificationType',
    options: getEDIConfigSenderIdentificationTypes,
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:pickup-stop-types',
    options: 'availablePickupStopTypes',
    fieldName: GC.FIELD_PICKUP_STOP_TYPES,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:drop-stop-types',
    options: 'availableDropStopTypes',
    fieldName: GC.FIELD_DROP_STOP_TYPES,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:defaultItemId',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_DEFAULT_ITEM_ID,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:shipment-number-as-primary-ref',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_NUMBER,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'cloReferenceTypes',
    loc: 'titles:shipment-number-reference',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'cloReferenceTypes',
    loc: 'titles:po-number-reference',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PO_NUMBER_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'cloReferenceTypes',
    loc: 'titles:orderIdentificationReference',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_ORDER_IDENTIFICATION_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:auto-update-event-dates',
    fieldName: GC.FIELD_AUTO_UPDATE_EVENT_DATES,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:useItemTemplates',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_ITEM_TEMPLATES,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:useLocationTemplates',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_USE_LOCATION_TEMPLATES,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:overrideBillTo',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_OVERRIDE_CONFIGURED_BILL_TO,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:lastTwoNumbersArePennies',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:auto-accept',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_AUTO_ACCEPT,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:send-response',
    fieldName: GC.FIELD_SEND_RESPONSE,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:auto-create-order',
    fieldName: GC.FIELD_AUTO_CREATE_ORDER,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:useShipmentNumberAsPickupNumber',
    fieldName: GC.FIELD_USE_SHIPMENT_NUMBER_AS_PICKUP_NUMBER,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:custom-mapper',
    fieldName: GC.FIELD_CUSTOM_MAPPER,
    options: 'availableEdiExportMappersForLoader',
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:generate-pro-number',
    fieldName: GC.FIELD_GENERATE_PRO_NUMBER,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'cloReferenceTypes',
    loc: 'titles:-pro-number-reference',
    fieldName: GC.FIELD_PRO_NUMBER_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'cloSequences',
    loc: 'titles:-pro-number-sequence',
    fieldName: GC.FIELD_PRO_NUMBER_SEQUENCE,
  },
  {
    ...defaultStyles,
    type: 'select',
    fieldName: 'instructionsField',
    loc: 'titles:instructions-field',
    options: [
      {
        value: GC.SPECIAL_INSTRUCTIONS_FIELD_TYPE_SPECIAL_INSTRUCTIONS,
        label: G.getWindowLocale('titles:special-instructions', 'Special Instructions'),
      },
      {
        value: GC.SPECIAL_INSTRUCTIONS_FIELD_TYPE_INTERNAL_INSTRUCTIONS,
        label: G.getWindowLocale('titles:internal-instructions', 'Internal Instructions'),
      },
    ],
  },
];

export const ediConfigsValidationSchema = () => Yup.lazy(
  ({ dropStopTypes, pickupStopTypes }: Object) => Yup.object().shape({
    [GC.FIELD_CUSTOMER_GUID]: fieldRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_REFERENCE]: fieldNotRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_PO_NUMBER_REFERENCE]: fieldNotRequired,
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_ORDER_IDENTIFICATION_REFERENCE]: fieldNotRequired,
    [GC.FIELD_DROP_STOP_TYPES]: G.ifElse(
      G.isNotNilAndNotEmpty(pickupStopTypes),
      G.yupArrayRequired,
      G.yupArrayNotRequired,
    ),
    [GC.FIELD_PICKUP_STOP_TYPES]: G.ifElse(
      G.isNotNilAndNotEmpty(dropStopTypes),
      G.yupArrayRequired,
      G.yupArrayNotRequired,
    ),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME]: Yup.string()
      .nullable(true)
      .notRequired()
      .min(3, G.getShouldBeFromToLocaleTxt(3, 200))
      .max(200, G.getShouldBeFromToLocaleTxt(3, 200)),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_DEFAULT_ITEM_ID]: Yup.string()
      .nullable(true)
      .notRequired()
      .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
      .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE]: Yup.string()
      .nullable(true)
      .notRequired()
      .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
      .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  }),
);

export const defaultEdiExporterConfigFields = {
  [GC.FIELD_AUTH_KEY]: null,
  [GC.FIELD_CUSTOMER_GUID]: null,
  [GC.FIELD_TRANSACTION_TYPES]: null,
  [GC.FIELD_SENT_STATUS_CODES]: null,
  [GC.FIELD_EDI_SENDER_QUALIFIER]: null,
  [GC.FIELD_ENCODING_TYPE]: GC.ENCODING_TYPE_UTF_8,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL]: null,
  [GC.FIELD_STATUS_MESSAGE_STOP_NUMBER_POSITION]: 'LX_LOOP_REFERENCE',
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT]: null,
  [GC.FIELD_CONTENT_FORMAT]: GC.EDI_EXPORTER_CONTENT_FORMAT_TYPE_MULTILINE,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PRIMARY_REFERENCE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_EVENT_WITH_STATUS]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_SHIPMENT_WITH_STATUS]: false,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_STATUS_MESSAGE_REFERENCE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_TENDER_RESPONSE_REFERENCE]: null,
  [GC.FIELD_RECEIVER_QUALIFIER]: GC.CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_GENERAL,
};

const differentReceiverIdFields = R.map((fieldName: string) => ({
  ...defaultStyles,
  fieldName,
  type: 'text',
  loc: 'titles:receiverId',
}), [
  GC.FIELD_INVOICE_RECEIVER_ID,
  GC.FIELD_ACCEPT_DECLINE_RECEIVER_ID,
  GC.FIELD_STATUS_MESSAGE_RECEIVER_ID,
  GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_ID,
]);

const differentReceiverQualifierFields = R.map((fieldName: string) => ({
  ...defaultStyles,
  fieldName,
  type: 'select',
  loc: 'titles:receiver-qualifier',
  options: G.addEmptyOptionToDropDown(getCustomerEdiExporterReceiverQualifierTypeOptions()),
  inputWrapMargin: G.ifElse(
    R.equals(fieldName, GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_QUALIFIER),
    '5px 300px 5px 0',
    '5px 0px',
  ),
}), [
  GC.FIELD_INVOICE_RECEIVER_QUALIFIER,
  GC.FIELD_ACCEPT_DECLINE_RECEIVER_QUALIFIER,
  GC.FIELD_STATUS_MESSAGE_RECEIVER_QUALIFIER,
  GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_QUALIFIER,
]);

export const ediExporterConfigFields = [
  {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:customer',
    options: 'branchListByType',
    fieldName: GC.FIELD_CUSTOMER_GUID,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:content-format',
    fieldName: GC.FIELD_CONTENT_FORMAT,
    options: ediExporterContentFormatOptions,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:folderName',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:transaction-types',
    fieldName: GC.FIELD_TRANSACTION_TYPES,
    options: getCustomerEdiExporterTransactionTypeOptions,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:protocol',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
    options: ['', GC.EDI_INTEGRATION_PROTOCOL_TYPE_FTP, GC.EDI_INTEGRATION_PROTOCOL_TYPE_SFTP],
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:remote-host',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:remote-port',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-name',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD,
  },
  {
    ...defaultStyles,
    type: 'textarea',
    loc: 'titles:private-key',
    fieldName: GC.FIELD_AUTH_KEY,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:sender-id',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:sender-qualifier',
    fieldName: GC.FIELD_EDI_SENDER_QUALIFIER,
    options: G.addEmptyOptionToDropDown(getCustomerEdiExporterReceiverQualifierTypeOptions()),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:receiverId',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:receiver-qualifier',
    inputWrapMargin: '5px 300px 5px 0',
    fieldName: GC.FIELD_RECEIVER_QUALIFIER,
    options: getCustomerEdiExporterReceiverQualifierTypeOptions,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'cloReferenceTypes',
    loc: 'titles:shipment-number-reference',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PRIMARY_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    options: 'cloReferenceTypes',
    loc: 'titles:status-message-event-references',
    fieldName: GC.FIELD_STATUS_MESSAGE_EVENT_REFERENCES,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    options: 'cloReferenceTypes',
    loc: 'titles:status-message-order-references',
    fieldName: GC.FIELD_STATUS_MESSAGE_ORDER_REFERENCES,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:tender-response-reference',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_TENDER_RESPONSE_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:longitude-reference-name',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXPORTER_LONGITUDE_REFERENCE_NAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:latitude-reference-name',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXPORTER_LATITUDE_REFERENCE_NAME,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    options: 'statusCodeList',
    loc: 'titles:send-status-codes',
    fieldName: GC.FIELD_SENT_STATUS_CODES,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:sendEventLocationWithStatusMessage',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_EVENT_WITH_STATUS,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:sendShipperNameWithStatus',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_SHIPMENT_WITH_STATUS,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:encoding-type',
    options: encodingTypeOptions,
    fieldName: GC.FIELD_ENCODING_TYPE,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:custom-mapper',
    fieldName: GC.FIELD_CUSTOM_MAPPER,
    options: 'availableEdiExportMappers',
  },
  {
    ...defaultStyles,
    pr: 25,
    type: 'select',
    loc: 'titles:status-message-default-time-zone',
    fieldName: GC.FIELD_STATUS_MESSAGE_DEFAULT_TIME_ZONE,
    options: R.compose(
      G.addEmptyOptionToDropDown,
      R.values,
      R.mapObjIndexed((label: string, value: string) => ({ label, value })),
    )(TIME_ZONE_TYPES_MAP),
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:status-message-send-first-last-event',
    fieldName: GC.FIELD_STATUS_MESSAGE_SEND_FIRST_LAST_EVENT,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:send-appointment-dates',
    fieldName: GC.FIELD_SEND_APPOINTMENT_DATES,
  },
  {
    ...defaultStyles,
    type: 'string',
    loc: 'titles:scac-override',
    fieldName: GC.FIELD_SCAC_OVERRIDE,
    inputWrapMargin: '5px 300px 5px 0',
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:send-carrier-location',
    fieldName: GC.FIELD_SEND_CARRIER_LOCATION,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: ['', 'BT', 'BY'],
    loc: 'titles:bill-to-location-identifier',
    fieldName: GC.FIELD_BILL_TO_LOCATION_IDENTIFIER,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: ['', 'SH', 'SF'],
    loc: 'titles:origin-location-identifier',
    fieldName: GC.FIELD_ORIGIN_LOCATION_IDENTIFIER,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:location-identification-qualifier',
    fieldName: GC.FIELD_LOCATION_IDENTIFICATION_QUALIFIER,
    options: [
      GC.EMPTY_OPTION_NULLABLE_OBJECT,
      {
        value: 'MUTUALLY_DEFINED',
        label: 'Mutually Defined (ZZ)',
      },
      {
        value: 'ASSIGNED_BY_BUYER_OR_BUYERS_AGENT',
        label: 'Assigned by Buyer or Buyer`s Agent (92)',
      },
    ],
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    useMenuPortalTarget: true,
    options: 'cloReferenceTypes',
    loc: 'titles:invoice-references',
    fieldName: GC.FIELD_INVOICE_REFERENCES,
    closeMenuOnScroll: R.pathEq('edi_exporter_form', ['target', 'id']),
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    useMenuPortalTarget: true,
    options: 'documentTypeOptions',
    loc: 'titles:invoice-associated-document-types',
    fieldName: GC.FIELD_INVOICE_ASSOCIATED_DOCUMENT_TYPES,
    closeMenuOnScroll: R.pathEq('edi_exporter_form', ['target', 'id']),
  },
  {
    ...defaultStyles,
    type: 'multiItems',
    loc: 'titles:references-to-comment',
    fieldName: GC.FIELD_REFERENCES_TO_COMMENT,
    placeholder: 'titles:enter-text-and-press-enter',
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:send-next-event-index',
    fieldName: GC.FIELD_STATUS_MESSAGE_SEND_NEXT_EVENT_INDEX,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:stop-number-position',
    fieldName: GC.FIELD_STATUS_MESSAGE_STOP_NUMBER_POSITION,
    options: [
      {
        label: 'Loop Reference',
        value: 'LX_LOOP_REFERENCE',
      },
      {
        value: 'BEGINNING_SEGMENT_REFERENCE',
        label: 'Beginning Segment Reference',
      },
    ],
  },
  ...differentReceiverIdFields,
  ...differentReceiverQualifierFields,
];

export const ediExporterConfigValidationSchema = {
  [GC.FIELD_CUSTOMER_GUID]: fieldRequired,
  [GC.FIELD_TRANSACTION_TYPES]: G.yupArrayRequired,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL]: fieldRequired,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(3, G.getShouldBeFromToLocaleTxt(3, 200))
    .max(200, G.getShouldBeFromToLocaleTxt(3, 200)),
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT]: Yup.number()
    .nullable(true)
    .notRequired()
    .positive(G.getShouldBePositiveLocaleTxt())
    .integer(G.getShouldBeIntegerLocaleTxt())
    .typeError('Should be a ${type}'), // eslint-disable-line
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(1, G.getShouldBeFromToLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PRIMARY_REFERENCE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_TENDER_RESPONSE_REFERENCE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_STATUS_MESSAGE_REFERENCE]: Yup.string()
    .nullable(true)
    .notRequired(),
};

export const defaultEdiExtractorConfigFields = {
  [GC.FIELD_AUTH_KEY]: null,
  eraseFileFromRemoteHost: true,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PORT]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_LOCAL_FOLDER]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_FOLDER]: null,
};

export const ediExtractorConfigFields = [
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:local-folder',
    customDisabledFunction: 'handleDisableFolder',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_LOCAL_FOLDER,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:remote-folder',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_FOLDER,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:remote-host',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:port',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PORT,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:protocol',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
    options: ['', GC.EDI_INTEGRATION_PROTOCOL_TYPE_FTP, GC.EDI_INTEGRATION_PROTOCOL_TYPE_SFTP],
  },
  {
    ...defaultStyles,
    type: 'toggle',
    fieldName: 'eraseFileFromRemoteHost',
    loc: 'titles:delete-files-from-remote-server',
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-name',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD,
  },
  {
    ...defaultStyles,
    width: 570,
    type: 'textarea',
    loc: 'titles:private-key',
    fieldName: GC.FIELD_AUTH_KEY,
  },
];

export const ediExtractorConfigValidationSchema = ({ isEditMode }: Object) => Yup.lazy((values: Object) => {
  const { authKey, password } = values;

  let schema = {
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL]: fieldRequired,
    [GC.FIELD_PASSWORD]: G.ifElse(G.isNotNilAndNotEmpty(authKey), fieldNotRequired, fieldRequired),
    [GC.FIELD_AUTH_KEY]: G.ifElse(G.isNotNilAndNotEmpty(password), fieldNotRequired, fieldRequired),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PORT]: Yup.string()
      .nullable(true),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_LOCAL_FOLDER]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_FOLDER]: Yup.string()
      .nullable(true),
  };

  if (isEditMode) {
    schema = R.omit([GC.FIELD_PASSWORD, GC.FIELD_AUTH_KEY], schema);
  }

  return Yup.object().shape(schema);
});

// user monitoring
export const userMonitoringFieldsMap = {
  [GC.FIELD_USER_MONITORING_APP]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:monitoring-app',
    fieldName: GC.FIELD_USER_MONITORING_APP,
    options: getUserMonitoringIntegrationOptions,
    customDisabledFunction: 'handleDisableIntegrationType',
  },
  [GC.FIELD_PROJECT_KEY]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:project-key',
    fieldName: GC.FIELD_PROJECT_KEY,
  },
  [GC.FIELD_ENABLED]: {
    ...defaultStyles,
    type: 'toggle',
    defaultValue: true,
    loc: 'titles:active',
    fieldName: GC.FIELD_ENABLED,
  },
};

export const userMonitoringFieldKeysGroup = {
  [GC.USER_MONITORING_APP_INTEGRATION_TYPE_SMARTLOOK]: [
    GC.FIELD_ENABLED,
    GC.FIELD_PROJECT_KEY,
  ],
};

export const getUserMonitoringValidationSchema = ({ isEditMode }: Object) =>
  Yup.lazy((values: Object) => {
    const keys = R.keys(values);
    const keysMap = {
      [GC.FIELD_USER_MONITORING_APP]: fieldRequired,
      [GC.FIELD_PROJECT_KEY]: G.ifElse(isEditMode, fieldNotRequired, fieldRequired),
    };
    const schema = R.pick(keys, keysMap);

    return Yup.object().shape(schema);
  });

// carrier integration
export const carrierIntegrationFieldsMap = (integrationType: string) => ({
  [GC.FIELD_INTEGRATION_TYPE]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: getCarrierRateIntegrationOptions,
    customDisabledFunction: 'handleDisableIntegrationType',
  },
  [GC.FIELD_NAME]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:name',
    fieldName: GC.FIELD_NAME,
  },
  [GC.CUSTOMER_BRANCH_GUID]: {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:customer',
    options: 'customerBranchList',
    inputWrapMargin: '5px 30px 5px 0',
    fieldName: GC.CUSTOMER_BRANCH_GUID,
  },
  [GC.FIELD_API_KEY]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:api-key',
    fieldName: GC.FIELD_API_KEY,
  },
  [GC.FIELD_APP_ID]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:app-id',
    fieldName: GC.FIELD_APP_ID,
  },
  [GC.FIELD_ACCOUNT_NUMBER]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:account-number',
    fieldName: GC.FIELD_ACCOUNT_NUMBER,
  },
  [GC.FIELD_USERNAME_CAMEL_CASE]: {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_USERNAME_CAMEL_CASE,
    loc: G.ifElse(
      R.equals(integrationType, GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS),
      'titles:client-id',
      'titles:user-name',
    ),
  },
  [GC.FIELD_PASSWORD]: {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_PASSWORD,
    loc: G.ifElse(
      R.equals(integrationType, GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS),
      'titles:client-secret',
      'titles:password',
    ),
  },
  [GC.FIELD_PAYMENT_TERM]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:payment-term',
    options: 'paymentTermOptions',
    fieldName: GC.FIELD_PAYMENT_TERM,
    inputWrapMargin: '5px 30px 5px 0',
  },
  [GC.FIELD_LABEL_TYPE]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:label-type',
    options: 'labelTypeOptions',
    fieldName: GC.FIELD_LABEL_TYPE,
    defaultValue: GC.CARRIER_INTEGRATION_LABEL_TYPE_NONE,
  },
  [GC.FIELD_LABEL_DOCUMENT_TYPE_GUID]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:label-document-type',
    options: GC.COMMUNICATION_DOCUMENT_TYPE,
    fieldName: GC.FIELD_LABEL_DOCUMENT_TYPE_GUID,
  },
  [GC.FIELD_STORE_BOL_DOCUMENT]: {
    ...defaultStyles,
    type: 'toggle',
    defaultValue: false,
    loc: 'titles:store-bol-document',
    fieldName: GC.FIELD_STORE_BOL_DOCUMENT,
  },
  [GC.FIELD_BOL_DOCUMENT_TYPE_GUID]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:bol-document-type',
    options: GC.COMMUNICATION_DOCUMENT_TYPE,
    fieldName: GC.FIELD_BOL_DOCUMENT_TYPE_GUID,
  },
  [GC.FIELD_BOL_PAYMENT_TERM]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:bol-payment-term',
    options: 'bolPaymentTermOptions',
    fieldName: GC.FIELD_BOL_PAYMENT_TERM,
  },
  [GC.FIELD_ENABLED]: {
    ...defaultStyles,
    type: 'toggle',
    defaultValue: true,
    loc: 'titles:active',
    fieldName: GC.FIELD_ENABLED,
  },
  [GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE]: {
    ...defaultStyles,
    type: 'select',
    options: 'carrierSequences',
    loc: 'titles:tracking-sequence',
    fieldName: GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
  },
  [GC.FIELD_PICKUP_REQUESTER_GUID]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:carrier-pickup-requester',
    fieldName: GC.FIELD_PICKUP_REQUESTER_GUID,
    options: 'availableCarrierPickupRequesterList',
  },
  [GC.FIELD_ACCOUNT_COUNTRY]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:account-country',
    fieldName: GC.FIELD_ACCOUNT_COUNTRY,
    options: ['', 'Canada', 'United States'],
  },
});

export const additionalCarrierIntegrationFieldKeys = [
  GC.FIELD_PAYMENT_TERM,
  GC.FIELD_LABEL_TYPE,
  GC.FIELD_LABEL_DOCUMENT_TYPE_GUID,
  GC.FIELD_STORE_BOL_DOCUMENT,
  GC.FIELD_BOL_DOCUMENT_TYPE_GUID,
];

const defaultCarrierIntegrationFieldKeys = [
  GC.FIELD_ENABLED,
  GC.FIELD_NAME,
  GC.FIELD_INTEGRATION_TYPE,
  GC.FIELD_PICKUP_REQUESTER_GUID,
];

const defaultCarrierIntegrationFieldKey2 = [
  GC.FIELD_ENABLED,
  GC.FIELD_NAME,
  GC.FIELD_INTEGRATION_TYPE,
];

export const carrierIntegrationFieldKeysGroup = {
  [GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE]: [
    GC.FIELD_INTEGRATION_TYPE,
    GC.FIELD_ENABLED,
    GC.FIELD_API_KEY,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_XPO_LOGISTICS]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_API_KEY,
    ...additionalCarrierIntegrationFieldKeys,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAYTON_FREIGHT]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    ...additionalCarrierIntegrationFieldKeys,
    GC.FIELD_BOL_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ROADRUNNER]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_API_KEY,
    GC.FIELD_APP_ID,
    ...additionalCarrierIntegrationFieldKeys,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ODFL]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_API_KEY,
    ...additionalCarrierIntegrationFieldKeys,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_A_DUIE_PYLE]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_FEDEX]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_API_KEY,
    GC.FIELD_PAYMENT_TERM,
    GC.FIELD_LABEL_TYPE,
    GC.FIELD_LABEL_DOCUMENT_TYPE_GUID,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_SAIA]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_PAYMENT_TERM,
    GC.FIELD_STORE_BOL_DOCUMENT,
    GC.FIELD_BOL_DOCUMENT_TYPE_GUID,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_WARD]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_HOLLAND]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_API_KEY,
    GC.FIELD_PAYMENT_TERM,
    GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_REDDAWAY]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_API_KEY,
    GC.FIELD_PAYMENT_TERM,
    GC.FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_SEFL]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_AAA_COOPER]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_API_KEY,
    GC.FIELD_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_SMTL]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_NEW_PENN]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_PAYMENT_TERM,
    GC.FIELD_LABEL_TYPE,
    GC.FIELD_LABEL_DOCUMENT_TYPE_GUID,
    GC.FIELD_BOL_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_YRC]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    ...additionalCarrierIntegrationFieldKeys,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_RIST]: [
    ...defaultCarrierIntegrationFieldKey2,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_POLARIS]: [
    ...defaultCarrierIntegrationFieldKey2,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    ...additionalCarrierIntegrationFieldKeys,
    GC.FIELD_BOL_PAYMENT_TERM,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_GLS]: [
    ...defaultCarrierIntegrationFieldKey2,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    ...additionalCarrierIntegrationFieldKeys,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_UPS]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_ACCOUNT_COUNTRY,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_DAY_AND_ROSS]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
    GC.FIELD_ACCOUNT_NUMBER,
    ...additionalCarrierIntegrationFieldKeys,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_ARC_BEST]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_ACCOUNT_NUMBER,
    GC.FIELD_API_KEY,
    ...additionalCarrierIntegrationFieldKeys,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_PASSWORD,
  ],
  [GC.CARRIER_RATE_INTEGRATION_TYPE_T_FORCE_WORLD_WIDE]: [
    ...defaultCarrierIntegrationFieldKeys,
    GC.FIELD_USERNAME_CAMEL_CASE,
    GC.FIELD_API_KEY,
  ],
};

export const additionalAccountFields = (index: number) => [
  {
    ...defaultStyles,
    width: 260,
    type: 'text',
    loc: 'titles:account-number',
    fieldName: `additionalAccounts.${index}.${GC.FIELD_ACCOUNT_NUMBER}`,
  },
  {
    ...defaultStyles,
    width: 260,
    type: 'text',
    loc: 'titles:zip',
    fieldName: `additionalAccounts.${index}.${GC.FIELD_ZIP}`,
  },
];

export const getCarrierIntegrationValidationSchema = ({ override, isEditMode }: Object) =>
  Yup.lazy((values: Object) => {
    if (G.isTrue(override)) {
      const schema = {
        [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
        additionalAccounts: Yup.array().notRequired().of(Yup.object().shape({
          [GC.FIELD_ZIP]: fieldRequired,
          [GC.FIELD_ACCOUNT_NUMBER]: fieldRequired,
        })),
      };

      return Yup.object().shape(schema);
    }
    const keys = R.keys(values);
    const keysMap = {
      [GC.FIELD_APP_ID]: fieldRequired,
      [GC.FIELD_ACCOUNT_COUNTRY]: fieldRequired,
      [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
      [GC.FIELD_USERNAME_CAMEL_CASE]: fieldRequired,
      [GC.FIELD_PICKUP_REQUESTER_GUID]: fieldRequired,
      [GC.FIELD_API_KEY]: G.ifElse(isEditMode, fieldNotRequired, fieldRequired),
      [GC.FIELD_PASSWORD]: G.ifElse(isEditMode, fieldNotRequired, fieldRequired),
      [GC.FIELD_LABEL_DOCUMENT_TYPE_GUID]: G.ifElse(
        R.pathEq(GC.CARRIER_INTEGRATION_LABEL_TYPE_NONE, [GC.FIELD_LABEL_TYPE], values),
        fieldNotRequired,
        fieldRequired,
      ),
      [GC.FIELD_BOL_PAYMENT_TERM]: G.ifElse(
        R.or(
          R.pathEq(true, [GC.FIELD_STORE_BOL_DOCUMENT], values),
          R.pathEq(GC.CARRIER_RATE_INTEGRATION_TYPE_NEW_PENN, [GC.FIELD_INTEGRATION_TYPE], values),
        ),
        fieldRequired,
        fieldNotRequired,
      ),
      [GC.FIELD_BOL_DOCUMENT_TYPE_GUID]: G.ifElse(
        R.pathEq(true, [GC.FIELD_STORE_BOL_DOCUMENT], values),
        fieldRequired,
        fieldNotRequired,
      ),
      [GC.FIELD_ACCOUNT_NUMBER]: G.ifElse(
        R.pathEq(GC.CARRIER_RATE_INTEGRATION_TYPE_XPO_LOGISTICS, [GC.FIELD_INTEGRATION_TYPE], values),
        fieldNotRequired,
        fieldRequired,
      ),
      [GC.FIELD_THIRD_PARTY_CONTACT_NAME]: Yup.string()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .max(100, G.getShouldBeFromToCharLocaleTxt(0, 100)),
      [GC.FIELD_THIRD_PARTY_CONTACT_PHONE]: Yup.string()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .max(100, G.getShouldBeFromToCharLocaleTxt(0, 40))
        .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
      [GC.FIELD_THIRD_PARTY_CONTACT_EMAIL]: Yup.string()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .email(G.getFieldIsNotValidEmailTxt())
        .max(100, G.getShouldBeFromToCharLocaleTxt(0, 40)),
    };
    const schema = R.pick(keys, keysMap);

    return Yup.object().shape(schema);
  });

export const serviceMappingFieldSettings = [
  {
    ...defaultStyles,
    zIndex: 100,
    type: 'searchselect',
    shouldCustomChange: true,
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: R.drop(1, getCarrierRateIntegrationOptions()),
  },
  {
    ...defaultStyles,
    type: 'searchselect',
    options: 'serviceCodes',
    loc: 'titles:vendor-service-code',
    fieldName: GC.FIELD_SERVICE_MAPPING_SERVICE_CODE,
  },
  {
    ...defaultStyles,
    options: 'services',
    type: 'searchselect',
    loc: 'titles:amous-service-code',
    fieldName: GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID,
  },
];

export const defaultServiceMappingFields = {
  [GC.FIELD_INTEGRATION_TYPE]: null,
  [GC.FIELD_SERVICE_MAPPING_SERVICE_CODE]: null,
  [GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID]: null,
};

export const carrierServiceMappingSchemaObject = {
  [GC.FIELD_SERVICE_MAPPING_SERVICE_CODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_INTEGRATION_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const multipleServiceMappingFieldSettings = (index: number) => [
  {
    ...defaultStyles,
    type: 'searchselect',
    options: 'serviceCodes',
    useMenuPortalTarget: true,
    inputWrapMargin: '5px 10px',
    loc: 'titles:vendor-service-code',
    closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
    fieldName: `${GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS}.${index}.${GC.FIELD_SERVICE_MAPPING_SERVICE_CODE}`,
  },
  {
    ...defaultStyles,
    options: 'services',
    type: 'searchselect',
    useMenuPortalTarget: true,
    inputWrapMargin: '5px 10px',
    loc: 'titles:amous-service-code',
    closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
    fieldName: `${GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS}.${index}.${GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID}`,
  },
];

export const defaultMultipleServiceMappingFields = () => ({
  [GC.FIELD_ID]: G.genShortId(),
  [GC.FIELD_SERVICE_MAPPING_SERVICE_CODE]: '',
  [GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID]: '',
});

export const multipleServiceMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
  [GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_SERVICE_MAPPING_SERVICE_CODE]: fieldRequired,
    [GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID]: fieldRequired,
  })),
});

const advancePaymentInputWrapperStyles = {
  mb: 25,
  width: 190,
};

export const advancePaymentFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    label: ['titles:account-code', 'Account Code'],
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_ACCOUNT_CODE,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:customer-id', 'Customer Id'],
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_CUSTOMER_ID,
  },
  {
    isRequired: true,
    type: 'reactSelect',
    customDisabledFunction: 'disableIntegrationType',
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    label: ['titles:integration-type', 'Integration Type'],
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_INTEGRATION_TYPE,
    options: [
      {
        label: 'Comdata',
        value: GC.ADVANCE_PAYMENT_INTEGRATION_TYPE_COMDATA,
      },
    ],
  },
  {
    version: 2,
    type: 'multiswitch',
    label: ['titles:fees', 'Fees'],
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_PLUS_LESS_FEES,
    options: [
      { width: 95, value: false, name: G.getWindowLocale('titles:less', 'Less') },
      { width: 95, value: true, name: G.getWindowLocale('titles:plus', 'Plus') },
    ],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    label: ['titles:wss-sign-on-name', 'WSS Sign on Name'],
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_NAME,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    label: ['titles:wss-sign-on-password', 'WSS Sign on Password'],
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_PASSWORD,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:sign-on-name', 'Sign on Name'],
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_NAME,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    label: ['titles:sign-on-password', 'Sign on Password'],
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_PASSWORD,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:security-info', 'Security Info'],
    inputWrapperStyles: advancePaymentInputWrapperStyles,
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SECURITY_INFO,
  },
];

export const defaultAdvancePaymentFields = {
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_CUSTOMER_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_ACCOUNT_CODE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SECURITY_INFO]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_PLUS_LESS_FEES]: true,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_PASSWORD]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_PASSWORD]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_INTEGRATION_TYPE]: GC.ADVANCE_PAYMENT_INTEGRATION_TYPE_COMDATA,
};

const advancePaymentValidationSchemaObject = {
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_CUSTOMER_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_ACCOUNT_CODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SECURITY_INFO]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_INTEGRATION_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_PASSWORD]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const getAdvancePaymentValidationSchemaObject = (editMode: boolean) => G.ifElse(
  editMode,
  R.omit(
    [
      GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_PASSWORD,
      GC.FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_PASSWORD,
    ],
    advancePaymentValidationSchemaObject,
  ),
  advancePaymentValidationSchemaObject,
);

// load-board integration config
export const loadBoardIntegrationFieldsToData = [
  GC.FIELD_SHOW_CUSTOMER_RATE,
  GC.FIELD_REQUIRED_MIN_MAX_PRICE,
  GC.FIELD_TRANSPORTATION_MODE_GUID,
  GC.INTEGRATION_LOADBOARD_SHOW_SHIPPER,
];

export const loadBoardIntegrationTypeField = {
  ...defaultStyles,
  type: 'select',
  options: 'typeOptions',
  shouldCustomChange: true,
  loc: 'titles:integration-type',
  fieldName: GC.INTEGRATION_LOADBOARD_TYPE,
  customDisabledFunction: 'handleDisableIntegrationType',
};

export const loadBoardAccountIdField = {
  ...defaultStyles,
  type: 'text',
  loc: 'titles:account-id',
  fieldName: GC.INTEGRATION_LOADBOARD_ACCOUNT_ID,
};

export const loadBoardTransportationModeField = {
  ...defaultStyles,
  type: 'select',
  loc: 'titles:transportation-mode',
  options: 'transportationModeOptions',
  fieldName: GC.FIELD_TRANSPORTATION_MODE_GUID,
};

export const loadBoardShowCustomerRateField = {
  ...defaultStyles,
  type: 'toggle',
  loc: 'titles:show-customer-rate',
  fieldName: GC.FIELD_SHOW_CUSTOMER_RATE,
};

export const loadBoardRequiredMinMaxPriceField = {
  ...defaultStyles,
  type: 'toggle',
  fieldName: GC.FIELD_REQUIRED_MIN_MAX_PRICE,
  loc: 'titles:required-start-price-max-price',
};

export const loadBoardApiKeyField = {
  ...defaultStyles,
  type: 'text',
  loc: 'titles:api-key',
  fieldName: GC.FIELD_PASSWORD,
};

export const loadBoardTruckerToolsFields = [
  loadBoardAccountIdField,
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:api-key',
    fieldName: GC.FIELD_USERNAME_CAMEL_CASE,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:secret-key',
    fieldName: GC.FIELD_PASSWORD,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:transportation-mode',
    options: 'transportationModeOptions',
    fieldName: GC.INTEGRATION_LOADBOARD_TRANSPORTATION_MODE_GUID,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:show-shipper',
    fieldName: GC.INTEGRATION_LOADBOARD_SHOW_SHIPPER,
  },
];

export const loadBoardCHRobinsonFields = [
  loadBoardIntegrationTypeField,
  loadBoardAccountIdField,
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:client-id',
    fieldName: GC.FIELD_USERNAME_CAMEL_CASE,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:secret-key',
    fieldName: GC.FIELD_PASSWORD,
  },
];

export const loadBoardCoyoteFields = [
  loadBoardIntegrationTypeField,
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:client-id',
    fieldName: GC.FIELD_USERNAME_CAMEL_CASE,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:secret-key',
    fieldName: GC.FIELD_PASSWORD,
  },
];

export const defaultLoadBoardTruckerToolsFields = {
  [GC.FIELD_PASSWORD]: '',
  [GC.FIELD_USERNAME_CAMEL_CASE]: '',
  [GC.INTEGRATION_LOADBOARD_ACCOUNT_ID]: '',
  [GC.INTEGRATION_LOADBOARD_SHOW_SHIPPER]: false,
  [GC.INTEGRATION_LOADBOARD_TRANSPORTATION_MODE_GUID]: '',
};

export const defaultLoadBoardIntegrationFields = {
  ...defaultLoadBoardTruckerToolsFields,
  [GC.FIELD_SHOW_CUSTOMER_RATE]: false,
  [GC.INTEGRATION_LOADBOARD_TYPE]: '',
  [GC.FIELD_REQUIRED_MIN_MAX_PRICE]: false,
  [GC.INTEGRATION_LOADBOARD_ACCOUNT_ID]: '',
};

export const getLoadBoardIntegrationConfigValidationSchema = (type: string, isEditMode: boolean) => ({
  [GC.INTEGRATION_LOADBOARD_TYPE]: fieldRequired,
  [GC.INTEGRATION_LOADBOARD_ACCOUNT_ID]: G.ifElse(
    R.includes(
      type,
      [
        GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
        GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP,
        GC.EXTERNAL_LOAD_BOARD_CH_ROBINSON,
        GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
      ],
    ),
    fieldRequired,
    fieldNotRequired,
  ),
  [GC.INTEGRATION_LOADBOARD_TRANSPORTATION_MODE_GUID]: G.ifElse(
    R.includes(
      type,
      [
        GC.EXTERNAL_LOAD_BOARD_PARADE,
        GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
        GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
      ],
    ),
    fieldRequired,
    fieldNotRequired,
  ),
  [GC.FIELD_PASSWORD]: G.ifElse(
    R.and(
      G.isFalse(isEditMode),
      G.notEquals(type, GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP),
    ),
    fieldRequired,
    fieldNotRequired,
  ),
  [GC.FIELD_USERNAME_CAMEL_CASE]: G.ifElse(
    R.includes(type, [
      GC.EXTERNAL_LOAD_BOARD_PARADE,
      GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
      GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP,
    ]),
    fieldNotRequired,
    fieldRequired,
  ),
});


export const tollChargeFields = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: [
      { label: 'Bestpass', value: GC.FLEET_INTEGRATION_TYPE_BESTPASS },
      {
        label: 'PrePass',
        value: GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
      },
    ],
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:account-id',
    fieldName: GC.INTEGRATION_TOLL_CHARGES_ACCOUNT_ID,
    fieldGroupDisplay: (_: any, { integrationType }: Object) => G.ifElse(
      R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_PRE_PASS),
      'none',
      'flex',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:security-key',
    fieldName: GC.INTEGRATION_TOLL_CHARGES_SECURITY_KEY,
    fieldGroupDisplay: (_: any, { integrationType }: Object) => G.ifElse(
      R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_PRE_PASS),
      'none',
      'flex',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:login',
    fieldName: GC.FIELD_LOGIN,
    fieldGroupDisplay: (_: any, { integrationType }: Object) => G.ifElse(
      R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_BESTPASS),
      'none',
      'flex',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PASSWORD,
    fieldGroupDisplay: (_: any, { integrationType }: Object) => G.ifElse(
      R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_BESTPASS),
      'none',
      'flex',
    ),
  },
];

export const defaultTollChargesFields = {
  [GC.FIELD_LOGIN]: null,
  [GC.FIELD_PASSWORD]: null,
  [GC.INTEGRATION_TOLL_CHARGES_ACCOUNT_ID]: null,
  [GC.INTEGRATION_TOLL_CHARGES_SECURITY_KEY]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.FLEET_INTEGRATION_TYPE_BESTPASS,
};

export const getTollChargesValidationSchema = Yup.lazy(({ version, integrationType }: Object) => Yup.object().shape({
  [GC.FIELD_LOGIN]: G.ifElse(
    R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_PRE_PASS),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.FIELD_PASSWORD]: G.ifElse(
    R.and(R.isNil(version), R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_PRE_PASS)),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.INTEGRATION_TOLL_CHARGES_ACCOUNT_ID]: G.ifElse(
    R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_BESTPASS),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.INTEGRATION_TOLL_CHARGES_SECURITY_KEY]: G.ifElse(
    R.and(R.isNil(version), R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_BESTPASS)),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

// gps integration config
export const defaultGpsIntegrationConfigFields = {
  [GC.FIELD_LOGIN]: null,
  [GC.FIELD_APP_ID]: null,
  [GC.FIELD_ENABLED]: true,
  [GC.FIELD_API_KEY]: null,
  [GC.FIELD_GROUP_ID]: null,
  [GC.FIELD_PASSWORD]: null,
  [GC.FIELD_ACCESS_TOKEN]: null,
  [GC.FIELD_AUTO_ASSIGMENT_ENABLED]: false,
  [GC.FIELD_ENABLED_DAILY_HOURS_STORAGE]: false,
  [GC.FIELD_INTEGRATION_TYPE]: GC.GPS_INTEGRATION_TYPE_SAMSARA,
};

export const gpsIntegrationConfigFieldSettings = ({ version, integrationType }: Object) => {
  const isEditMode = R.isNotNil(version);

  const inputWrapperStyles = {
    mb: 25,
    width: 270,
  };

  const toggleInputWrapperStyles = {
    mb: 25,
    width: 210,
    justifyContent: 'space-between',
  };

  const fields = {
    [GC.FIELD_INTEGRATION_TYPE]: {
      type: 'select',
      inputWrapperStyles,
      disabled: isEditMode,
      label: ['titles:integration-type'],
      options: gpsIntegrationConfigTypes,
      fieldName: GC.FIELD_INTEGRATION_TYPE,
    },
    [GC.FIELD_ENABLED]: {
      type: 'toggle',
      inputWrapperStyles,
      label: ['titles:enabled'],
      fieldName: GC.FIELD_ENABLED,
    },
    [GC.FIELD_AUTO_ASSIGMENT_ENABLED]: {
      type: 'toggle',
      label: ['titles:auto-assigment-enabled'],
      fieldName: GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      inputWrapperStyles: toggleInputWrapperStyles,
    },
    [GC.FIELD_GROUP_ID]: {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:group-id'],
      fieldName: GC.FIELD_GROUP_ID,
    },
    [GC.FIELD_ENABLED_UPDATE_TRUCK_DAILY_LOG]: {
      type: 'toggle',
      inputWrapperStyles: toggleInputWrapperStyles,
      label: ['titles:enabled-update-truck-daily-log'],
      fieldName: GC.FIELD_ENABLED_UPDATE_TRUCK_DAILY_LOG,
    },
    [GC.FIELD_ACCESS_TOKEN]: {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:access-token'],
      fieldName: GC.FIELD_ACCESS_TOKEN,
      isRequired: G.ifElse(G.isTrue(isEditMode), false, true),
    },
    [GC.FIELD_ENABLED_DAILY_HOURS_STORAGE]: {
      type: 'toggle',
      inputWrapperStyles: toggleInputWrapperStyles,
      label: ['titles:enabled-daily-hours-storage'],
      fieldName: GC.FIELD_ENABLED_DAILY_HOURS_STORAGE,
    },
    [GC.FIELD_ENABLED_UPDATE_DAILY_HOURS]: {
      type: 'toggle',
      inputWrapperStyles: toggleInputWrapperStyles,
      label: ['titles:enabled-update-daily-hours'],
      fieldName: GC.FIELD_ENABLED_UPDATE_DAILY_HOURS,
    },
    [GC.FIELD_ENABLED_HOS_LOGS_STORAGE]: {
      type: 'toggle',
      label: ['titles:enabled-hos-logs-storage'],
      inputWrapperStyles: toggleInputWrapperStyles,
      fieldName: GC.FIELD_ENABLED_HOS_LOGS_STORAGE,
    },
    [GC.FIELD_AUTO_SEND_TRIP_ENABLED]: {
      type: 'toggle',
      label: ['titles:auto-send-trip-enabled'],
      fieldName: GC.FIELD_AUTO_SEND_TRIP_ENABLED,
      inputWrapperStyles: toggleInputWrapperStyles,
    },
    [GC.FIELD_LOGIN]: {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:login'],
      fieldName: GC.FIELD_LOGIN,
    },
    [GC.FIELD_PASSWORD]: {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:password'],
      fieldName: GC.FIELD_PASSWORD,
      isRequired: G.ifElse(G.isTrue(isEditMode), false, true),
    },
    [GC.FIELD_API_KEY]: {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:api-key'],
      fieldName: GC.FIELD_API_KEY,
      isRequired: G.ifElse(G.isTrue(isEditMode), false, true),
    },
    [GC.FIELD_APP_ID]: {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      label: ['titles:app-id'],
      fieldName: GC.FIELD_APP_ID,
    },
  };

  const commonFields = [GC.FIELD_INTEGRATION_TYPE, GC.FIELD_ENABLED];

  const map = {
    [GC.GPS_INTEGRATION_TYPE_SAMSARA]: [
      ...commonFields,
      GC.FIELD_GROUP_ID,
      GC.FIELD_ACCESS_TOKEN,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      GC.FIELD_ENABLED_DAILY_HOURS_STORAGE,
      GC.FIELD_ENABLED_UPDATE_DAILY_HOURS,
      GC.FIELD_ENABLED_HOS_LOGS_STORAGE,
      GC.FIELD_AUTO_SEND_TRIP_ENABLED,
    ],
    [GC.GPS_INTEGRATION_TYPE_KEEP_TRUCKIN]: [
      ...commonFields,
      GC.FIELD_API_KEY,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
    ],
    [GC.GPS_INTEGRATION_TYPE_VERIZON]: [
      ...commonFields,
      GC.FIELD_LOGIN,
      GC.FIELD_PASSWORD,
      GC.FIELD_APP_ID,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      GC.FIELD_ENABLED_DAILY_HOURS_STORAGE,
      GC.FIELD_ENABLED_UPDATE_DAILY_HOURS,
    ],
    [GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE]: [
      ...commonFields,
      GC.FIELD_ACCESS_TOKEN,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
    ],
    [GC.GPS_INTEGRATION_TYPE_OMNITRACS]: [
      ...commonFields,
      GC.FIELD_LOGIN,
      GC.FIELD_PASSWORD,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
    ],
    [GC.GPS_INTEGRATION_TYPE_GEO_TAB]: [
      ...commonFields,
      GC.FIELD_LOGIN,
      GC.FIELD_APP_ID,
      GC.FIELD_PASSWORD,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      GC.FIELD_ENABLED_DAILY_HOURS_STORAGE,
      GC.FIELD_ENABLED_UPDATE_DAILY_HOURS,
    ],
    [GC.GPS_INTEGRATION_TYPE_CLUB_ELD]: [
      ...commonFields,
      GC.FIELD_ACCESS_TOKEN,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      GC.FIELD_ENABLED_UPDATE_DAILY_HOURS,
      GC.FIELD_ENABLED_UPDATE_TRUCK_DAILY_LOG,
    ],
    [GC.GPS_INTEGRATION_TYPE_BLUE_STAR]: [
      ...commonFields,
      GC.FIELD_LOGIN,
      GC.FIELD_PASSWORD,
      GC.FIELD_API_KEY,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      GC.FIELD_ENABLED_UPDATE_TRUCK_DAILY_LOG,
    ],
    [GC.GPS_INTEGRATION_TYPE_MASTER_ELD]: [
      ...commonFields,
      GC.FIELD_API_KEY,
      GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      GC.FIELD_ENABLED_UPDATE_DAILY_HOURS,
      GC.FIELD_ENABLED_HOS_LOGS_STORAGE,
      GC.FIELD_ENABLED_UPDATE_TRUCK_DAILY_LOG,
    ],
  };

  return R.values(R.pick(R.prop(integrationType, map), fields));
};

export const getGpsIntegrationConfigValidationSchema = ({ version, integrationType }: Object) => {
  const formModeValidation = G.ifElse(R.isNotNil(version), G.yupStringNotRequired, G.yupStringRequired);

  const apiKey = formModeValidation;
  const password = formModeValidation;
  const accessToken = formModeValidation;

  const fields = {
    apiKey,
    password,
    accessToken,
    [GC.FIELD_LOGIN]: G.yupStringRequired,
    [GC.FIELD_APP_ID]: G.yupStringRequired,
    [GC.FIELD_GROUP_ID]: G.yupStringRequired,
    [GC.FIELD_INTEGRATION_TYPE]: G.yupStringRequired,
  };

  const schema = R.pick(R.map(R.prop('fieldName'), gpsIntegrationConfigFieldSettings({ integrationType })), fields);

  return schema;
};

// dispatch integration
export const dispatchIntegrationFieldSettings = {
  [GC.FIELD_TYPE]: {
    ...defaultStyles,
    type: 'select',
    fieldName: GC.FIELD_TYPE,
    loc: 'titles:integration-type',
    customDisabledFunction: 'disableIntegrationType',
    options: [
      {
        label: 'Macro Point',
        value: GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT,
      },
      {
        label: 'Trucker Tools',
        value: GC.DISPATCH_INTEGRATION_TYPE_TRUCKER_TOOLS,
      },
      {
        label: 'FourKites',
        value: GC.DISPATCH_INTEGRATION_TYPE_FOUR_KITES,
      },
    ],
  },
  [GC.FIELD_NAME]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:name',
    fieldName: GC.FIELD_NAME,
  },
  [GC.FIELD_ACCOUNT_ID]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:account-id',
    fieldName: GC.FIELD_ACCOUNT_ID,
  },
  [GC.FIELD_LOGIN]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:login',
    fieldName: GC.FIELD_LOGIN,
  },
  [GC.FIELD_PASSWORD]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PASSWORD,
  },
  [GC.FIELD_INTEGRATION_ID]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:integration-id',
    fieldName: GC.FIELD_INTEGRATION_ID,
  },
  [GC.FIELD_TRACKING_STATUS_CODE]: {
    ...defaultStyles,
    type: 'select',
    options: 'trackingStatusCodeList',
    loc: 'titles:tracking-status-code',
    fieldName: GC.FIELD_TRACKING_STATUS_CODE,
  },
  [GC.FIELD_TRACKING_FREQUENCY_IN_MINUTES]: {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:tracking-frequency-in-minutes',
    fieldName: GC.FIELD_TRACKING_FREQUENCY_IN_MINUTES,
  },
  [GC.FIELD_HOT_LOAD_TRACKING_FREQUENCY_IN_MINUTES]: {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:hot-load-tracking-frequency-in-minutes',
    fieldName: GC.FIELD_HOT_LOAD_TRACKING_FREQUENCY_IN_MINUTES,
  },
  [GC.FIELD_SEND_AUTOMATICALLY]: {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:send-automatically',
    fieldName: GC.FIELD_SEND_AUTOMATICALLY,
  },
  [GC.FIELD_API_KEY]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:api-key',
    fieldName: GC.FIELD_API_KEY,
  },
};

export const defaultDispatchIntegrationFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_LOGIN]: null,
  [GC.FIELD_API_KEY]: null,
  [GC.FIELD_PASSWORD]: null,
  [GC.FIELD_ACCOUNT_ID]: null,
  [GC.FIELD_INTEGRATION_ID]: null,
  [GC.FIELD_SEND_AUTOMATICALLY]: false,
  [GC.FIELD_TRACKING_STATUS_CODE]: null,
  [GC.FIELD_TRACKING_FREQUENCY_IN_MINUTES]: 90,
  [GC.FIELD_HOT_LOAD_TRACKING_FREQUENCY_IN_MINUTES]: null,
  [GC.FIELD_TYPE]: GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT,
};

export const dispatchIntegrationValidationSchemaObject = ({ isEditMode }: Object) => Yup.lazy(({ type }: Object) => {
  let schema = {
    [GC.FIELD_TYPE]: fieldRequired,
    [GC.FIELD_NAME]: fieldRequired,
    [GC.FIELD_LOGIN]: fieldRequired,
    [GC.FIELD_PASSWORD]: fieldRequired,
    [GC.FIELD_INTEGRATION_ID]: fieldRequired,
    [GC.FIELD_TRACKING_STATUS_CODE]: fieldRequired,
    [GC.FIELD_TRACKING_FREQUENCY_IN_MINUTES]: Yup.number()
      .required(G.getRequiredLocaleTxt())
      .positive(G.getShouldBePositiveLocaleTxt())
      .min(15, G.getShouldBeFromToLocaleTxt(15, 1440))
      .max(1440, G.getShouldBeFromToLocaleTxt(15, 1440)),
    [GC.FIELD_HOT_LOAD_TRACKING_FREQUENCY_IN_MINUTES]: Yup.number()
      .nullable(true)
      .min(15, G.getShouldBeFromToLocaleTxt(15, 1440))
      .max(1440, G.getShouldBeFromToLocaleTxt(15, 1440)),
  };

  if (G.isTrue(isEditMode)) {
    schema = R.dissoc(GC.FIELD_PASSWORD, schema);
  }

  if (R.equals(type, GC.DISPATCH_INTEGRATION_TYPE_TRUCKER_TOOLS)) {
    schema = {
      [GC.FIELD_TYPE]: fieldRequired,
      [GC.FIELD_NAME]: fieldRequired,
      [GC.FIELD_ACCOUNT_ID]: fieldRequired,
    };
  }


  if (R.equals(type, GC.DISPATCH_INTEGRATION_TYPE_FOUR_KITES)) {
    schema = {
      [GC.FIELD_TYPE]: fieldRequired,
      [GC.FIELD_NAME]: fieldRequired,
      [GC.FIELD_API_KEY]: G.ifElse(isEditMode, fieldNotRequired, fieldRequired),
    };
  }

  return Yup.object().shape(schema);
});

// terminal integration config
export const terminalIntegrationFieldSettings = {
  [GC.FIELD_INTEGRATION_TYPE]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'disableIntegrationType',
    options: [
      {
        label: 'VeraCore',
        value: GC.TERMINAL_INTEGRATION_TYPE_VERACORE,
      },
    ],
  },
  [GC.FIELD_ENABLED]: {
    ...defaultStyles,
    type: 'toggle',
    defaultValue: false,
    loc: 'titles:enabled',
    fieldName: GC.FIELD_ENABLED,
  },
  [GC.FIELD_USERNAME_CAMEL_CASE]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-name',
    fieldName: GC.FIELD_USERNAME_CAMEL_CASE,
  },
  [GC.FIELD_PASSWORD]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PASSWORD,
  },
  [GC.FIELD_SYSTEM_ID]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:system-id',
    fieldName: GC.FIELD_SYSTEM_ID,
  },
  [GC.FIELD_DOMAIN]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:domain',
    fieldName: GC.FIELD_DOMAIN,
  },
  [GC.FIELD_OWNER_ID]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:owner-id',
    fieldName: GC.FIELD_OWNER_ID,
  },
};

export const defaultTerminalIntegrationFields = {
  [GC.FIELD_DOMAIN]: null,
  [GC.FIELD_ENABLED]: null,
  [GC.FIELD_OWNER_ID]: null,
  [GC.FIELD_PASSWORD]: null,
  [GC.FIELD_SYSTEM_ID]: null,
  [GC.FIELD_USERNAME_CAMEL_CASE]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.TERMINAL_INTEGRATION_TYPE_VERACORE,
};

export const terminalIntegrationValidationSchemaObject = ({ isEditMode }: Object) => Yup.lazy(({ integrationType }: Object) => {
  let schema = {
    [GC.FIELD_DOMAIN]: fieldRequired,
    [GC.FIELD_OWNER_ID]: fieldRequired,
    [GC.FIELD_PASSWORD]: fieldRequired,
    [GC.FIELD_SYSTEM_ID]: fieldRequired,
    [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
    [GC.FIELD_USERNAME_CAMEL_CASE]: fieldRequired,
  };

  if (G.isTrue(isEditMode)) {
    schema = R.dissoc(GC.FIELD_PASSWORD, schema);
  }

  return Yup.object().shape(schema);
});

const getStatusCodeIntegrationTypeOptions = (mappingType: string) => {
  if (R.equals(mappingType, 'outboundMapping')) return statusCodeOutboundMappingTypeOptions;

  return statusCodeMappingTypeOptions();
};

// status code mapping
export const statusCodeMappingFieldSettings = (integrationType: string, mappingType: string) => [
  {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'disableIntegrationType',
    options: getStatusCodeIntegrationTypeOptions(mappingType),
  },
  {
    ...defaultStyles,
    options: 'statusCodeOptions',
    fieldName: GC.FIELD_STATUS_CODE,
    loc: 'titles:vendor-status-code',
    type: G.ifElse(R.equals(integrationType, GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI), 'text', 'searchselect'),
  },
  {
    ...defaultStyles,
    type: 'searchselect',
    options: 'allStatusCodeList',
    loc: 'titles:amous-status-code',
    fieldName: GC.FIELD_STATUS_CODE_GUID,
  },
];

export const defaultStatusCodeMappingFields = {
  [GC.FIELD_STATUS_CODE]: '',
  [GC.FIELD_STATUS_CODE_GUID]: '',
  [GC.FIELD_INTEGRATION_TYPE]: GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT,
};

export const statusCodeMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_STATUS_CODE]: fieldRequired,
  [GC.FIELD_STATUS_CODE_GUID]: fieldRequired,
  [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
});

// status reason code mapping
export const statusReasonCodeMappingFieldSettings = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: statusReasonCodeMappingTypeOptions,
    customDisabledFunction: 'disableIntegrationType',
    label: ['titles:integration-type', 'Integration Type'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_STATUS_REASON_CODE,
    label: ['titles:vendor-status-reason-code', 'Vendor Status Reason Code'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'statusReasonCodeOptions',
    fieldName: GC.FIELD_STATUS_REASON_CODE_GUID,
    label: ['titles:amous-status-reason-code', 'Amous Status Reason Code'],
  },
];

export const defaultStatusReasonCodeMappingFields = {
  [GC.FIELD_STATUS_REASON_CODE]: '',
  [GC.FIELD_STATUS_REASON_CODE_GUID]: '',
  [GC.FIELD_INTEGRATION_TYPE]: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
};

export const statusReasonCodeMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
  [GC.FIELD_STATUS_REASON_CODE]: fieldRequired,
  [GC.FIELD_STATUS_REASON_CODE_GUID]: fieldRequired,
});

// public clo
export const publicCLOFieldSettings = [
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:login-id',
    fieldName: GC.FIELD_PUBLIC_CLO_USER_LOGIN_ID,
    customDisabledFunction: 'handleDisableUserLoginId',
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PUBLIC_CLO_USER_PASSWORD,
    fieldGroupDisplay: (field: Object, values: Object, { isSuperAdmin }: Object) => G.ifElse(
      G.isTrue(isSuperAdmin),
      'flex',
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'cloReferenceTypes',
    loc: 'titles:shipment-number-reference',
    fieldName: GC.FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:shipment-primary-number',
    fieldName: GC.FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_PRIMARY,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:auto-create-order',
    fieldName: GC.FIELD_PUBLIC_API_AUTO_CREATE_ORDER,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:enabled',
    fieldName: GC.FIELD_ENABLED,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:use-branch-integration-account-number',
    fieldName: GC.FIELD_USE_BRANCH_INTEGRATION_ACCOUNT_NUMBER,
  },
];

export const defaultPublicCLOFields = {
  [GC.FIELD_ENABLED]: true,
  [GC.FIELD_PUBLIC_CLO_USER_LOGIN_ID]: null,
  [GC.FIELD_PUBLIC_CLO_USER_PASSWORD]: null,
  [GC.FIELD_PUBLIC_API_AUTO_CREATE_ORDER]: false,
  [GC.FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_PRIMARY]: false,
  [GC.FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_REFERENCE]: null,
  [GC.FIELD_USE_BRANCH_INTEGRATION_ACCOUNT_NUMBER]: false,
};

export const publicCLOValidationSchema = ({ isEditMode }: Object) => Yup.lazy(() => {
  let schema = {
    [GC.FIELD_PUBLIC_CLO_USER_LOGIN_ID]: fieldRequired,
    [GC.FIELD_PUBLIC_CLO_USER_PASSWORD]: fieldRequired,
  };
  if (G.isTrue(isEditMode)) {
    schema = R.dissoc(GC.FIELD_PUBLIC_CLO_USER_PASSWORD, schema);
  }

  return Yup.object().shape(schema);
});

// service type mapping
export const serviceTypeMappingFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'disableIntegrationType',
    options: serviceTypeMappingIntegrationTypeOptions(),
  },
  {
    ...defaultStyles,
    type: 'searchselect',
    options: 'serviceTypeOptions',
    fieldName: GC.FIELD_SERVICE_TYPE,
    loc: 'titles:vendor-service-type-code',
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'serviceTypeConfigOptions',
    loc: 'titles:amous-service-type-code',
    fieldName: GC.FIELD_SERVICE_TYPE_CONFIG_GUID,
  },
];

export const defaultServiceTypeMappingFields = {
  [GC.FIELD_SERVICE_TYPE]: '',
  [GC.FIELD_SERVICE_TYPE_CONFIG_GUID]: '',
  [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_RATE_INTEGRATION_TYPE_FEDEX,
};

export const serviceTypeMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_SERVICE_TYPE]: fieldRequired,
  [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
  [GC.FIELD_SERVICE_TYPE_CONFIG_GUID]: fieldRequired,
});

// carrier synchronization
export const carrierSynchronizationFields = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: [
      {
        label: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
      },
      {
        value: GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS,
        label: G.getEnumLocale(GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS),
      },
    ],
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:client-id',
    fieldName: GC.FIELD_CLIENT_ID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-name',
    fieldName: GC.FIELD_USERNAME_CAMEL_CASE,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PASSWORD,
  },
  {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:remove-after-inactivity-days',
    fieldName: GC.FIELD_REMOVE_AFTER_INACTIVITY_DAYS,
  },
];

export const defaultCarrierSynchronizationFields = {
  [GC.FIELD_PASSWORD]: null,
  [GC.FIELD_CLIENT_ID]: null,
  [GC.FIELD_USERNAME_CAMEL_CASE]: null,
  [GC.FIELD_REMOVE_AFTER_INACTIVITY_DAYS]: 1,
  [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS,
};

export const carrierSynchronizationValidationSchema = ({ isEditMode }: Object) => Yup.lazy((values: Object) => {
  const { integrationType } = values;

  let schema = {
    [GC.FIELD_PASSWORD]: fieldRequired,
    [GC.FIELD_CLIENT_ID]: fieldRequired,
    [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
    [GC.FIELD_REMOVE_AFTER_INACTIVITY_DAYS]: Yup.number()
      .typeError('Should be a ${type}') // eslint-disable-line
      .min(1, G.getShouldBeFromToLocaleTxt(1, 999))
      .max(999, G.getShouldBeFromToLocaleTxt(1, 999)),
  };

  if (R.equals(integrationType, GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS)) {
    schema = {
      [GC.FIELD_PASSWORD]: fieldRequired,
      [GC.FIELD_USERNAME_CAMEL_CASE]: fieldRequired,
    };
  }

  if (isEditMode) schema = R.dissoc(GC.FIELD_PASSWORD, schema);

  return Yup.object().shape(schema);
});

// document type mapping
export const documentTypeMappingFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    options: documentTypeMappingOptions,
    fieldName: GC.FIELD_INTEGRATION_TYPE,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: 'documentTypeOptions',
    loc: 'titles:external-document-type',
    fieldName: GC.FIELD_DOCUMENT_EXTERNAL_DOCUMENT_TYPE,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:document-type-config',
    options: 'documentTypeConfigOptions',
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID,
  },
];

export const defaultDocumentTypeMappingFields = {
  [GC.FIELD_DOCUMENT_EXTERNAL_DOCUMENT_TYPE]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID]: null,
};

export const documentTypeMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
  [GC.FIELD_DOCUMENT_EXTERNAL_DOCUMENT_TYPE]: fieldRequired,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID]: fieldRequired,
});

// document type mapping
export const documentHubConfigFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: documentHubTypeMappingOptions,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:api-key',
    fieldName: GC.FIELD_API_KEY,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    options: 'telReferenceTypes',
    loc: 'titles:send-trip-references',
    fieldName: GC.FIELD_SEND_REFERENCES_GUIDS,
  },
  {
    ...defaultStyles,
    type: 'select',
    options: GC.COMMUNICATION_DOCUMENT_TYPE,
    loc: 'titles:proof-of-delivery-document-type',
    fieldName: GC.FIELD_PROOF_OF_DELIVERY_DOCUMENT_TYPE,
  },
];

export const defaultDocumentHubConfigFields = {
  [GC.FIELD_API_KEY]: null,
  [GC.FIELD_SEND_REFERENCES_GUIDS]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN,
};

export const documentHubConfigValidationSchema = ({ isEditMode }: Object) => Yup.lazy(() => {
  let schema = {
    [GC.FIELD_API_KEY]: fieldRequired,
    [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
    [GC.FIELD_SEND_REFERENCES_GUIDS]: Yup.array()
      .nullable(true)
      .max(40, G.getShouldBePickOnlyLocaleTxt(40)),
  };
  if (G.isTrue(isEditMode)) schema = R.dissoc(GC.FIELD_API_KEY, schema);

  return Yup.object().shape(schema);
});

// accounting
const accountingFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    fieldName: GC.FIELD_TYPE,
    options: accountingOptions,
    loc: 'titles:integration-type',
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:client-id',
    fieldName: GC.FIELD_CLIENT_ID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:client-secret',
    fieldName: GC.FIELD_CLIENT_SECRET,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:company-id',
    fieldName: GC.FIELD_COMPANY_ID,
  },
];

const sageAccountingFieldSettings = [
  {
    ...defaultStyles,
    type: 'select',
    fieldName: GC.FIELD_TYPE,
    options: accountingOptions,
    loc: 'titles:integration-type',
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:company-id',
    fieldName: GC.FIELD_COMPANY_ID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-id',
    fieldName: GC.FIELD_ACCOUNTING_USER_ID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-password',
    fieldName: GC.FIELD_ACCOUNTING_USER_PASSWORD,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:sender-id',
    fieldName: GC.FIELD_ACCOUNTING_SENDER_ID,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:sender-password',
    fieldName: GC.FIELD_ACCOUNTING_SENDER_PASSWORD,
  },
];

export const getAccountingFieldSettings = (type: string) => {
  if (R.equals(type, GC.ACCOUNTING_INTEGRATION_TYPE_SAGE_INTACCT)) {
    return sageAccountingFieldSettings;
  }

  return accountingFieldSettings;
};

export const defaultAccountingFields = {
  [GC.FIELD_CLIENT_ID]: null,
  [GC.FIELD_COMPANY_ID]: null,
  [GC.FIELD_CLIENT_SECRET]: null,
  [GC.FIELD_ACCOUNTING_USER_ID]: null,
  [GC.FIELD_ACCOUNTING_SENDER_ID]: null,
  [GC.FIELD_ACCOUNTING_USER_PASSWORD]: null,
  [GC.FIELD_ACCOUNTING_SENDER_PASSWORD]: null,
  [GC.FIELD_TYPE]: GC.ACCOUNTING_INTEGRATION_TYPE_BUSINESS_CENTRAL,
};

const accountingValidationSchema = {
  [GC.FIELD_TYPE]: fieldRequired,
  [GC.FIELD_CLIENT_ID]: fieldRequired,
  [GC.FIELD_COMPANY_ID]: fieldRequired,
  [GC.FIELD_CLIENT_SECRET]: fieldRequired,
};

const sageAccountingValidationSchema = {
  [GC.FIELD_TYPE]: fieldRequired,
  [GC.FIELD_COMPANY_ID]: fieldRequired,
  [GC.FIELD_ACCOUNTING_USER_ID]: fieldRequired,
  [GC.FIELD_ACCOUNTING_SENDER_ID]: fieldRequired,
  [GC.FIELD_ACCOUNTING_USER_PASSWORD]: fieldRequired,
  [GC.FIELD_ACCOUNTING_SENDER_PASSWORD]: fieldRequired,
};

export const getAccountingValidationSchema = (type: string) => {
  if (R.equals(type, GC.ACCOUNTING_INTEGRATION_TYPE_SAGE_INTACCT)) {
    return sageAccountingValidationSchema;
  }

  return accountingValidationSchema;
};

const carrierPickupRequesterStyles = R.mergeRight(defaultStyles, { width: '200px', inputWrapMargin: '5px' });

// carrier pickup requester
export const carrierPickupRequesterFieldSettings = [
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    loc: 'titles:name',
    fieldName: GC.FIELD_NAME,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    loc: 'titles:location-name',
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    ...carrierPickupRequesterStyles,
    width: '300px',
    loc: 'titles:address',
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
    addressFieldName: GC.FIELD_ADDRESS,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    loc: 'titles:city',
    fieldName: GC.FIELD_CITY,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    loc: 'titles:state',
    fieldName: GC.FIELD_STATE,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    fieldName: GC.FIELD_ZIP,
    loc: 'titles:postal-code',
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'countrySelect',
    loc: 'titles:country',
    fieldName: GC.FIELD_COUNTRY,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    loc: 'titles:contact-name',
    fieldName: GC.FIELD_CONTACT_NAME,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'phoneNumber',
    loc: 'titles:phone',
    fieldName: GC.FIELD_PHONE,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    loc: 'titles:email',
    fieldName: GC.FIELD_EMAIL,
  },
  {
    ...carrierPickupRequesterStyles,
    type: 'text',
    loc: 'titles:fax',
    fieldName: GC.FIELD_FAX,
  },
];

export const defaultCarrierPickupRequesterFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_FAX]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_NAME]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_ADDRESS]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_CONTACT_NAME]: null,
  [GC.FIELD_LOCATION_NAME]: null,
};

export const carrierPickupRequesterValidationSchema = Yup.object().shape({
  [GC.FIELD_ZIP]: fieldRequired,
  [GC.FIELD_CITY]: fieldRequired,
  [GC.FIELD_NAME]: fieldRequired,
  [GC.FIELD_STATE]: fieldRequired,
  [GC.FIELD_ADDRESS]: fieldRequired,
  [GC.FIELD_COUNTRY]: fieldRequired,
  [GC.FIELD_CONTACT_NAME]: fieldRequired,
  [GC.FIELD_LOCATION_NAME]: fieldRequired,
  [GC.FIELD_FAX]: Yup.string().nullable(true),
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_PHONE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
});

// carrier edi loader config
export const defaultCarrierEdiLoaderConfigFields = {
  [GC.FIELD_CARRIER_GUID]: null,
  [GC.FIELD_SEND_RESPONSE]: false,
  [GC.FIELD_DEFAULT_TIME_ZONE]: null,
  [GC.FIELD_EVENT_MATCHING_REFERENCE]: null,
  [GC.FIELD_INVOICE_DOCUMENT_TYPE_GUID]: null,
  [GC.FIELD_STOP_NUMBER_REFERENCE_CODE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOAD_IDENTIFICATION_TYPE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES]: true,
};

const customSelectCarriersComponent = ({ values, setFieldValue }: Object) => (
  <SelectCarriers
    width={270}
    height={30}
    isClearable={true}
    setFieldValue={setFieldValue}
    fieldName={GC.FIELD_CARRIER_GUID}
    endpointName='carrierListChildrenAndCurrent'
    value={R.pathOr(null, [GC.FIELD_CARRIER_GUID], values)}
  />
);

export const carrierEdiLoaderConfigFields = [
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:folderName',
    customDisabledFunction: 'handleDisableFolder',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:sender-identification',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:senderIdentificationType',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE,
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: 'SENDER_ID',
        label: G.getWindowLocale('titles:sender-id', 'Sender ID'),
      },
    ],
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:load-identification-type',
    options: getEDIConfigLoadIdentificationTypes,
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOAD_IDENTIFICATION_TYPE,
  },
  {
    ...defaultStyles,
    type: 'custom',
    loc: 'titles:carrier',
    fieldName: GC.FIELD_CARRIER_GUID,
    component: customSelectCarriersComponent,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:invoice-document-type',
    options: GC.COMMUNICATION_DOCUMENT_TYPE,
    fieldName: GC.FIELD_INVOICE_DOCUMENT_TYPE_GUID,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:default-time-zone',
    fieldName: GC.FIELD_DEFAULT_TIME_ZONE,
    options: G.addEmptyOptionToDropDown(TIME_ZONE_OPTIONS),
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:lastTwoNumbersArePennies',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES,
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:send-response',
    fieldName: GC.FIELD_SEND_RESPONSE,
  },
  {
    ...defaultStyles,
    type: 'searchselect',
    options: 'telReferenceTypes',
    loc: 'titles:event-matching-reference',
    fieldName: GC.FIELD_EVENT_MATCHING_REFERENCE,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:stop-number-reference-code',
    fieldName: GC.FIELD_STOP_NUMBER_REFERENCE_CODE,
  },
];

export const carrierEdiLoaderConfigValidationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_GUID]: fieldRequired,
  [GC.FIELD_INVOICE_DOCUMENT_TYPE_GUID]: fieldNotRequired,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME]: fieldRequired,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_LOAD_IDENTIFICATION_TYPE]: fieldNotRequired,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE]: fieldNotRequired,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE]: fieldNotRequired,
  [GC.FIELD_STOP_NUMBER_REFERENCE_CODE]: Yup.string().nullable(true).max(20, G.getShouldBeFromToLocaleTxt(0, 20)),
});

// carrier edi exporter config
export const defaultCarrierEdiExporterConfigFields = {
  [GC.FIELD_CARRIER_GUID]: null,
  [GC.FIELD_TEL_REFERENCES]: null,
  [GC.FIELD_EVENT_REFERENCES]: null,
  [GC.FIELD_TRANSACTION_TYPES]: null,
  [GC.FIELD_EDI_SENDER_QUALIFIER]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST]: null,
  [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT]: null,
  [GC.FIELD_CONTENT_FORMAT]: GC.EDI_EXPORTER_CONTENT_FORMAT_TYPE_MULTILINE,
};

export const carrierEdiExporterConfigFields = [
  {
    ...defaultStyles,
    type: 'custom',
    loc: 'titles:carrier',
    fieldName: GC.FIELD_CARRIER_GUID,
    component: customSelectCarriersComponent,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:content-format',
    fieldName: GC.FIELD_CONTENT_FORMAT,
    options: ediExporterContentFormatOptions,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:folderName',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME,
  },
  {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:transaction-types',
    fieldName: GC.FIELD_TRANSACTION_TYPES,
    options: getCarrierEdiExporterTransactionTypeOptions,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:protocol',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
    options: ['', GC.EDI_INTEGRATION_PROTOCOL_TYPE_FTP, GC.EDI_INTEGRATION_PROTOCOL_TYPE_SFTP],
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:remote-host',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:remote-port',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-name',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    inputWrapMargin: '5px 300px 5px 0',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:sender-id',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:sender-qualifier',
    fieldName: GC.FIELD_EDI_SENDER_QUALIFIER,
    options: G.addEmptyOptionToDropDown(getCustomerEdiExporterReceiverQualifierTypeOptions()),
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:receiverId',
    fieldName: GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID,
  },
  {
    ...defaultStyles,
    errorTop: '100%',
    type: 'multiselect',
    options: 'telReferenceTypes',
    loc: 'titles:event-references',
    fieldName: GC.FIELD_EVENT_REFERENCES,
  },
  {
    ...defaultStyles,
    errorTop: '100%',
    type: 'multiselect',
    options: 'telReferenceTypes',
    loc: 'titles:trip-references',
    inputWrapMargin: '5px 300px 5px 0',
    fieldName: GC.FIELD_TEL_REFERENCES,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:receiverId',
    fieldName: GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_ID,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:receiver-qualifier',
    inputWrapMargin: '5px 300px 5px 0',
    fieldName: GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_QUALIFIER,
    options: G.addEmptyOptionToDropDown(getCustomerEdiExporterReceiverQualifierTypeOptions()),
  },
];

export const carrierEdiExporterConfigValidationSchema = () => Yup.lazy((values: Object) => {
  const { folderName, remoteHost } = values;

  let schema = {
    [GC.FIELD_CARRIER_GUID]: fieldRequired,
    [GC.FIELD_TRANSACTION_TYPES]: G.yupArrayRequired,
    [GC.FIELD_TEL_REFERENCES]: Yup.array()
      .nullable(true)
      .max(10, G.getShouldBePickOnlyLocaleTxt(10)),
    [GC.FIELD_EVENT_REFERENCES]: Yup.array()
      .nullable(true)
      .max(10, G.getShouldBePickOnlyLocaleTxt(10)),
  };
  if (G.isAllNilOrEmpty([folderName, remoteHost])) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME]: fieldRequired,
        [GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST]: fieldRequired,
      },
    );
  }

  return Yup.object().shape(schema);
});

// vin lookup config
export const vinLookupFields = [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:type',
    fieldName: GC.FIELD_TYPE,
    customDisabledFunction: 'handleDisableType',
    options: [
      { label: 'VIN Audit', value: GC.VIN_LOOKUP_INTEGRATION_TYPE_VIN_AUDIT },
    ],
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:api-key',
    fieldName: GC.FIELD_API_KEY,
  },
];

export const defaultVinLookupFields = {
  [GC.FIELD_API_KEY]: null,
  [GC.FIELD_TYPE]: GC.VIN_LOOKUP_INTEGRATION_TYPE_VIN_AUDIT,
};

export const getVinLookupValidationSchema = (editMode: boolean = false) => {
  const schema = {
    [GC.FIELD_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    [GC.FIELD_API_KEY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  };

  if (editMode) return R.dissoc(GC.FIELD_API_KEY, schema);

  return schema;
};

// factoring integration config
export const factoringIntegrationConfigFieldSettings = {
  [GC.FIELD_FACTORING_TYPE]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:factoring-type',
    fieldName: GC.FIELD_FACTORING_TYPE,
    options: factoringIntegrationTypeOptions,
    defaultValue: GC.FACTORING_COMPANY_TYPE_TRIUMPH,
    customDisabledFunction: 'disableIntegrationType',
  },
  [GC.FIELD_ENABLED]: {
    ...defaultStyles,
    type: 'toggle',
    defaultValue: false,
    loc: 'titles:enabled',
    fieldName: GC.FIELD_ENABLED,
  },
  [GC.FIELD_ENABLED_SENDING]: {
    ...defaultStyles,
    type: 'toggle',
    defaultValue: false,
    loc: 'titles:enabled-sending',
    fieldName: GC.FIELD_ENABLED_SENDING,
  },
  [GC.FIELD_CLO_REFERENCE_TYPE_GUID]: {
    ...defaultStyles,
    type: 'searchselect',
    loc: 'titles:clo-reference',
    options: 'cloReferenceTypes',
    fieldName: GC.FIELD_CLO_REFERENCE_TYPE_GUID,
  },
  [GC.FIELD_INVOICE_REFERENCE_TYPE_GUIDS]: {
    ...defaultStyles,
    type: 'multiselect',
    loc: 'titles:invoice-references',
    options: 'customerInvoiceReferenceTypes',
    fieldName: GC.FIELD_INVOICE_REFERENCE_TYPE_GUIDS,
  },
  [GC.FIELD_EXPORT_AS]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:export-as',
    fieldName: GC.FIELD_EXPORT_AS,
    defaultValue: GC.FACTORING_EXPORT_AS_TYPE_BROKER,
    options: [
      {
        value: GC.FACTORING_EXPORT_AS_TYPE_BROKER,
        label: G.getEnumLocale(GC.FACTORING_EXPORT_AS_TYPE_BROKER),
      },
      {
        value: GC.FACTORING_EXPORT_AS_TYPE_CARRIER,
        label: G.getEnumLocale(GC.FACTORING_EXPORT_AS_TYPE_CARRIER),
      },
    ],
  },
  [GC.FIELD_USERNAME]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-name',
    fieldName: GC.FIELD_USERNAME,
  },
  [GC.FIELD_PASSWORD]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PASSWORD,
  },
  [GC.FIELD_CLIENT_NAME]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:client-name',
    fieldName: GC.FIELD_CLIENT_NAME,
  },
  [GC.FIELD_CUSTOMER_NAME_TYPE]: {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:customer-name-type',
    fieldName: GC.FIELD_CUSTOMER_NAME_TYPE,
    defaultValue: GC.FACTORING_COMPANY_CUSTOMER_NAME_TYPE_BRANCH_NAME,
    options: [
      {
        value: GC.FACTORING_COMPANY_CUSTOMER_NAME_TYPE_BRANCH_NAME,
        label: G.getWindowLocale('titles:branch-name', 'Branch Name'),
      },
      {
        value: GC.FACTORING_COMPANY_CUSTOMER_NAME_TYPE_BILL_TO_NAME,
        label: G.getWindowLocale('titles:bill-to-name', 'Bill To Name'),
      },
    ],
  },
  [GC.FIELD_CUSTOMER_CODE]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:customer-code',
    fieldName: GC.FIELD_CUSTOMER_CODE,
  },
  [GC.FIELD_CUSTOMER_NAME]: {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:customer-name',
    fieldName: GC.FIELD_CUSTOMER_NAME,
  },
};

export const factoringIntegrationFieldsMap = {
  default: [GC.FIELD_FACTORING_TYPE],
  [GC.FACTORING_COMPANY_TYPE_COMPASS]: [
    GC.FIELD_FACTORING_TYPE,
    GC.FIELD_ENABLED,
    GC.FIELD_CLO_REFERENCE_TYPE_GUID,
    GC.FIELD_ENABLED_SENDING,
    GC.FIELD_USERNAME,
    GC.FIELD_PASSWORD,
  ],
  [GC.FACTORING_COMPANY_TYPE_TRIUMPH]: [
    GC.FIELD_FACTORING_TYPE,
    GC.FIELD_ENABLED,
    GC.FIELD_CLO_REFERENCE_TYPE_GUID,
    GC.FIELD_INVOICE_REFERENCE_TYPE_GUIDS,
    GC.FIELD_EXPORT_AS,
    GC.FIELD_ENABLED_SENDING,
    GC.FIELD_USERNAME,
    GC.FIELD_PASSWORD,
  ],
  [GC.FACTORING_COMPANY_TYPE_OTR_CAPITAL]: [
    GC.FIELD_FACTORING_TYPE,
    GC.FIELD_ENABLED,
    GC.FIELD_CLO_REFERENCE_TYPE_GUID,
    GC.FIELD_CLIENT_NAME,
    GC.FIELD_CUSTOMER_NAME_TYPE,
    GC.FIELD_ENABLED_SENDING,
    GC.FIELD_USERNAME,
    GC.FIELD_PASSWORD,
  ],
  [GC.FACTORING_COMPANY_TYPE_E_CAPITAL]: [
    GC.FIELD_FACTORING_TYPE,
    GC.FIELD_ENABLED,
    GC.FIELD_CLO_REFERENCE_TYPE_GUID,
    GC.FIELD_ENABLED_SENDING,
    GC.FIELD_CUSTOMER_NAME,
    GC.FIELD_CUSTOMER_CODE,
  ],
  [GC.FACTORING_COMPANY_TYPE_RTS_FINANCIAL]: [
    GC.FIELD_FACTORING_TYPE,
    GC.FIELD_ENABLED,
    GC.FIELD_CLO_REFERENCE_TYPE_GUID,
    GC.FIELD_CLIENT_NAME,
    GC.FIELD_ENABLED_SENDING,
    GC.FIELD_USERNAME,
    GC.FIELD_PASSWORD,
  ],
};

export const factoringIntegrationConfigValidationSchema = ({ editMode }: Object) => Yup.lazy((values: Object) => {
  const { factoringType, enabledSending } = values;

  if (G.isNilOrEmpty(factoringType)) {
    return Yup.object().shape({ [GC.FIELD_FACTORING_TYPE]: fieldRequired });
  }

  const schema = {
    [GC.FIELD_CLIENT_NAME]: fieldRequired,
    [GC.FIELD_CUSTOMER_CODE]: fieldRequired,
    [GC.FIELD_CUSTOMER_NAME]: fieldRequired,
    [GC.FIELD_FACTORING_TYPE]: fieldRequired,
    [GC.FIELD_CUSTOMER_NAME_TYPE]: fieldRequired,
    [GC.FIELD_CLO_REFERENCE_TYPE_GUID]: fieldRequired,
    [GC.FIELD_USERNAME]: G.ifElse(
      G.isTrue(enabledSending),
      fieldRequired,
      fieldNotRequired,
    ),
    [GC.FIELD_PASSWORD]: G.ifElse(
      R.and(G.isTrue(enabledSending), G.isFalse(editMode)),
      fieldRequired,
      fieldNotRequired,
    ),
  };

  return Yup.object().shape(R.pick(R.prop(factoringType, factoringIntegrationFieldsMap), schema));
});

// cross border integration config
export const getCrossBorderIntegrationFieldSettings = (editMode: boolean) => [
  {
    isRequired: true,
    inputWrapperStyles,
    disabled: editMode,
    type: 'reactSelect',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: crossBorderIntegrationTypeOptions,
    label: ['titles:integration-type', 'Integration Type'],
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_ENABLED,
    label: ['titles:active', 'Active'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    isRequired: R.not(editMode),
    fieldName: GC.FIELD_API_KEY,
    label: ['titles:api-key', 'API Key'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_ACCOUNT_NAME,
    label: ['titles:accountName', 'Account Name'],
  },
];

export const defaultCrossBorderIntegrationFields = {
  [GC.FIELD_API_KEY]: null,
  [GC.FIELD_ENABLED]: false,
  [GC.FIELD_ACCOUNT_NAME]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT,
};

export const getCrossBorderIntegrationValidationSchema = (values: Object, editMode: boolean) => {
  const schema = {
    [GC.FIELD_INTEGRATION_TYPE]: G.yupStringRequired,
    [GC.FIELD_API_KEY]: G.yupStringRequired.max(255, G.getShouldBeFromToCharLocaleTxt(0, 255)),
    [GC.FIELD_ACCOUNT_NAME]: G.yupStringRequired.max(100, G.getShouldBeFromToCharLocaleTxt(0, 100)),
  };

  if (G.isTrue(editMode)) {
    return R.omit([GC.FIELD_API_KEY], schema);
  }

  return schema;
};

// truck type mapping config
export const getTruckTypeMappingFieldSettings = (editMode: boolean = false) => [
  {
    isRequired: true,
    inputWrapperStyles,
    disabled: editMode,
    type: 'reactSelect',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: crossBorderIntegrationTypeOptions,
    label: ['titles:integration-type', 'Integration Type'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: 'truckTypeOptions',
    fieldName: GC.FIELD_INTEGRATION_TRUCK_TYPE,
    label: ['titles:external-truck-type-code', 'External Truck Type Code'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: 'truckTypeConfigOptions',
    fieldName: GC.FIELD_TRUCK_TYPE_CONFIG_GUID,
    label: ['titles:amous-truck-type-code', 'Amous Truck Type Code'],
  },
];

export const defaultTruckTypeMappingFields = {
  [GC.FIELD_INTEGRATION_TRUCK_TYPE]: '',
  [GC.FIELD_TRUCK_TYPE_CONFIG_GUID]: '',
  [GC.FIELD_INTEGRATION_TYPE]: GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT,
};

export const truckTypeMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
  [GC.FIELD_INTEGRATION_TRUCK_TYPE]: fieldRequired,
  [GC.FIELD_TRUCK_TYPE_CONFIG_GUID]: fieldRequired,
});

// trailer type mapping config
export const getTrailerTypeMappingFieldSettings = (editMode: boolean = false) => [
  {
    isRequired: true,
    inputWrapperStyles,
    disabled: editMode,
    type: 'reactSelect',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    options: crossBorderIntegrationTypeOptions,
    label: ['titles:integration-type', 'Integration Type'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: 'trailerTypeOptions',
    fieldName: GC.FIELD_INTEGRATION_TRAILER_TYPE,
    label: ['titles:external-trailer-type-code', 'External Trailer Type Code'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: 'trailerTypeConfigOptions',
    fieldName: GC.FIELD_TRAILER_TYPE_CONFIG_GUID,
    label: ['titles:amous-Trailer-type-code', 'Amous Trailer Type Code'],
  },
];

export const defaultTrailerTypeMappingFields = {
  [GC.FIELD_INTEGRATION_TRAILER_TYPE]: '',
  [GC.FIELD_TRAILER_TYPE_CONFIG_GUID]: '',
  [GC.FIELD_INTEGRATION_TYPE]: GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT,
};

export const trailerTypeMappingValidationSchema = Yup.object().shape({
  [GC.FIELD_INTEGRATION_TYPE]: fieldRequired,
  [GC.FIELD_INTEGRATION_TRAILER_TYPE]: fieldRequired,
  [GC.FIELD_TRAILER_TYPE_CONFIG_GUID]: fieldRequired,
});
