import React from 'react';
import * as R from 'ramda';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature drivers-card
import { enhanceActions } from '../hocs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');

const getDispatchActions = (props: Object) => {
  const { entity, handleDispatchTelRateAction } = props;

  const telGuid = R.path([GC.FIELD_GUID], entity);
  const status = R.path([GC.FIELD_STATUS], entity);

  if (R.includes(status, GC.TEL_STATUSES_TO_DISPATCH)) {
    return [{
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      frontIcon: I.routeBuilder(iconColor, 13, 13),
      text: G.getWindowLocale('titles:dispatch', 'Dispatch'),
      action: () => handleDispatchTelRateAction({
        telGuid,
        action: GC.DISPATCH_ACTION,
      }),
    }];
  } else if (R.includes(status, GC.TEL_STATUSES_TO_CANCEL)) {
    return [{
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      frontIcon: I.routeBuilder(iconColor, 13, 13),
      text: G.getWindowLocale('titles:cancel-dispatched', 'Cancel Dispatched'),
      action: () => handleDispatchTelRateAction({
        telGuid,
        action: GC.CANCEL_DISPATCH_ACTION,
      }),
    }];
  } else if (R.includes(status, GC.TEL_NEGATIVE_STATUSES)) {
    return [{
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      frontIcon: I.routeBuilder(iconColor, 13, 13),
      text: G.getWindowLocale('titles:redispatch', 'Redispatch'),
      action: () => handleDispatchTelRateAction({
        telGuid,
        action: GC.DISPATCH_ACTION,
      }),
    }];
  }

  return [];
};

const getDispatchedActions = (props: Object) => {
  const { entity, handleAcceptTelRate, handleDeclineTelRate } = props;

  const status = R.path([GC.FIELD_STATUS], entity);
  const selectedRateGuid = R.path([GC.FIELD_SELECTED_RATE_GUID], entity);

  if (R.equals(status, GC.LOAD_STATUS_DISPATCHED)) {
    return [
      {
        permissions: [PC.DISPATCH_ACCEPT_EXECUTE],
        frontIcon: I.routeBuilder(iconColor, 13, 13),
        action: () => handleAcceptTelRate(selectedRateGuid),
        text: G.getWindowLocale('titles:accept-load', 'Accept Load'),
      },
      {
        permissions: [PC.DISPATCH_ACCEPT_EXECUTE],
        frontIcon: I.routeBuilder(iconColor, 13, 13),
        text: G.getWindowLocale('titles:decline-load', 'Decline Load'),
        action: () => handleDeclineTelRate({ tel: entity, rateGuid: selectedRateGuid }),
      },
    ];
  }

  return [];
};

const getBookInTransitActions = (props: Object) => {
  const { entity, handleCreateDocument, handleCallStatusCheck, handleAddStatusMessage } = props;

  const telGuid = R.path([GC.FIELD_GUID], entity);
  const status = R.path([GC.FIELD_STATUS], entity);

  if (R.includes(status, GC.TEL_STATUS_CHECK)) {
    return [
      {
        permissions: [PC.TEL_WRITE],
        frontIcon: I.callCheck(iconColor, 13, 13),
        action: () => handleCallStatusCheck(telGuid),
        text: G.getWindowLocale('titles:status-check', 'Status Check'),
      },
      {
        permissions: [PC.TEL_DOCUMENT_WRITE],
        frontIcon: I.documents(iconColor, 13, 13),
        action: () => handleCreateDocument(entity),
        text: G.getWindowLocale('titles:add-documents', 'Add Documents'),
      },
      {
        permissions: [PC.TEL_WRITE],
        frontIcon: I.callCheck(iconColor, 13, 13),
        action: () => handleAddStatusMessage(entity),
        text: G.getWindowLocale('titles:add-status-msg', 'Add Status Msg'),
      },
    ];
  }

  return [];
};


const getOptions = (props: Object) => {
  const {
    entity,
    useAllActions,
    closeFixedPopup,
    expandedContainer,
    handleOpenTelDetails,
    handleAddTelDriverRate,
    expandedContainerOptions,
    addTelToLoadPlannerByGuid,
    addTelToRouteBuilderByGuid,
  } = props;

  const iconColor = G.getTheme('colors.light.blue');

  if (expandedContainer) {
    const fromPage = R.path(['options', 'fromPage'], expandedContainerOptions);

    const guid = G.getGuidFromObject(entity);

    const pagesMap = {
      [GC.PAGE_DISPATCH_PLANNER]: {
        text: G.getWindowLocale('titles:add-to-route-builder', 'Add To Route Builder'),
        action: () => {
          addTelToRouteBuilderByGuid(guid);
          closeFixedPopup();
        },
      },
      [GC.PAGE_DISPATCH_PLANNER_EVENTS]: {
        text: G.getWindowLocale('titles:add-to-load-planner', 'Add To Load Planner'),
        action: () => {
          addTelToLoadPlannerByGuid(guid);
          closeFixedPopup();
        },
      },
    };

    return R.of(Array, {
      frontIcon: I.routeBuilder(iconColor),
      text: R.path([fromPage, 'text'], pagesMap),
      action: R.path([fromPage, 'action'], pagesMap),
    });
  }

  const actions1 = [
    {
      action: handleOpenTelDetails,
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      frontIcon: I.routesLoads(iconColor, 13, 13),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
    },
    ...getDispatchActions(props),
    ...getDispatchedActions(props),
    ...getBookInTransitActions(props),
  ];

  const actions2 = [
    {
      action: handleOpenTelDetails,
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      frontIcon: I.routesLoads(iconColor, 13, 13),
      text: G.getWindowLocale('actions:go-to-details', 'Go to Details'),
    },
    {
      action: handleAddTelDriverRate,
      permissions: [PC.FLEET_RATE_WRITE],
      frontIcon: I.driver(iconColor, 13, 13),
      text: G.getWindowLocale('actions:assign-driver-rate', 'Assign Driver Rate'),
    },
  ];

  if (G.isTrue(useAllActions)) return actions1;

  return actions2;
};

const CardActions = (props: Object) => <ActionsElement version={2} options={getOptions(props)} />;

export default enhanceActions(CardActions);
