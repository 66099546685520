import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// forms
import { LocationFormWithSearch } from '../../../forms/forms/location-form-with-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature carrier
import * as LC from '../constants';
import { makeSelectContractBillTo } from '../selectors';
import { contractBillToColumnSettings } from '../settings/column-settings';
import {
  removeContractBillToByContractTypeRequest,
  createOrUpdateContractBillToByContractTypeRequest } from '../actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  contractBillTo: makeSelectContractBillTo(state),
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      removeContractBillToByContractTypeRequest,
      createOrUpdateContractBillToByContractTypeRequest,
    },
  ),
  withHandlers({
    handleOpenLocationForm: (props: Object) => (initialValues: Object) => {
      const {
        type,
        openModal,
        closeModal,
        branchGuid,
        contractGuid,
        locationType,
        createOrUpdateContractBillToByContractTypeRequest } = props;

      const contractGuidName = G.ifElse(
        G.isContractTypeCarrier(type),
        GC.FIELD_CARRIER_CONTRACT_GUID,
        GC.FIELD_CUSTOMER_CONTRACT_GUID,
      );
      const modeLocale = G.ifElse(
        G.isNilOrEmpty(initialValues),
        'titles:add-location',
        'titles:edit-location',
      );
      const initialValuesWithLocationTypeGuid = G.ifElse(
        G.isNotNilAndNotEmpty(initialValues),
        R.assoc(
          GC.FIELD_LOCATION_TYPE,
          R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues),
          initialValues,
        ),
        initialValues,
      );
      const optionsForSelect = { locationType };
      const mode = G.getWindowLocale(modeLocale, 'Add Location');
      const submitHandler = (location: Object) => {
        const values = R.assoc(contractGuidName, contractGuid, location);

        createOrUpdateContractBillToByContractTypeRequest({ type, values });
      };
      const modalContent = (
        <LocationFormWithSearch
          mode={mode}
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          returnFullLocation={true}
          submitHandler={submitHandler}
          submitAndSaveLocation={false}
          optionsForSelect={optionsForSelect}
          initialValues={initialValuesWithLocationTypeGuid}
          formType={GC.LOCATION_FORM_TYPE_CONTRACT_BILL_TO_LOCATION}
        />
      );
      const modal = {
        p: '0',
        component: modalContent,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '87vh',
        },
      };
      openModal(modal);
    },
    handleDelete: (props: Object) => ({ guid }: Object) => {
      const { type, openModal, closeModal, removeContractBillToByContractTypeRequest } = props;

      const modal = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () =>
          removeContractBillToByContractTypeRequest({ type, guid }),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:bill-to', 'Bill To')}?`,
      });

      openModal(modal);
    },
  }),
  pure,
);

const ContractBillTo = (props: Object) => (
  <FormGroupTable
    fields={props.collapsedGroup}
    handleDeleteRow={props.handleDelete}
    handleEditRow={props.handleOpenLocationForm}
    entitiesFields={contractBillToColumnSettings}
    handleAddClick={props.handleOpenLocationForm}
    entities={R.pathOr([], ['contractBillTo'], props)}
    groupName={LC.CARRIER_GROUP_NAME_CONTRACT_BILL_TO}
    panelTitle={G.getWindowLocale('titles:bill-to', 'Bill To')}
    isHiddenAddIcon={G.isNotNilAndNotEmpty(props.contractBillTo)}
    isOpened={R.path([LC.CARRIER_GROUP_NAME_CONTRACT_BILL_TO], props.collapsedGroup)}
    handleToggleFormGroup={() => props.handleToggleFormGroup(LC.CARRIER_GROUP_NAME_CONTRACT_BILL_TO)} />
);

export default enhance(ContractBillTo);
