import React from 'react';
import * as R from 'ramda';
import {
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  CartesianGrid,
} from 'recharts';
// feature dashboards
import { CHART_COLORS } from '../constants';
import {
  xAxisCommonProps,
  yAxisCommonProps,
  legendCommonProps,
  cartesianGridCommonProps,
  largeChartWrapperCommonProps,
} from '../settings';
//////////////////////////////////////////////////

const { BAR_COLORS } = CHART_COLORS;

const ticks = [0, 500, 1000, 1500, 2000, 2500];

const domain = [0, 2500];

const SimpleBarChartComponent = (props: Object) => {
  const { data, ticksY, domainY, showLegend, tickFormatter, reverseColors } = props;

  const COLORS = reverseColors ? R.reverse(BAR_COLORS) : BAR_COLORS;

  return (
    <BarChart
      data={data}
      barSize={40}
      {...largeChartWrapperCommonProps}
    >
      <CartesianGrid {...cartesianGridCommonProps} />
      <XAxis
        dataKey='name'
        {...xAxisCommonProps}
      />
      <YAxis
        ticks={ticksY || ticks}
        allowDataOverflow={true}
        domain={domainY || domain}
        tickFormatter={tickFormatter}
        {...yAxisCommonProps}
      />
      <Tooltip />
      {
        showLegend && <Legend {...legendCommonProps} />
      }
      <Bar
        stackId='a'
        dataKey='value'
        fill={COLORS[2]}
      />
    </BarChart>
  );
};

export default SimpleBarChartComponent;
