import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { Tabs, withTabs } from '../../../components/tabs';
import { getConfirmModal } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ActionButton, IconWrapper } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// feature dispatch-details-new
import DamageForm from '../forms/damage-form';
import { tableSettings } from '../settings/table-settings';
import { removeLoadVehicleItemDamageSuccess, createOrUpdateLoadVehicleItemDamageSuccess } from '../load/actions';
import { removeOrderVehicleItemDamageSuccess, createOrUpdateOrderVehicleItemDamageSuccess } from '../order/actions';
/////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const greyColor = G.getTheme('colors.dark.grey');
const blueColor = G.getTheme('colors.dark.blue');
const iconColor = G.getTheme('colors.light.blue');

const getOptionsForActions = (props: Object, entity: Object) => [
  {
    permissions: null,
    frontIcon: I.pencil(iconColor),
    text: G.getWindowLocale('actions:edit', 'Edit'),
    action: () => props.handleCreateOrUpdateDamage(entity),
  },
  {
    permissions: null,
    frontIcon: I.remove(iconColor),
    action: () => props.handleRemoveDamage(entity),
    text: G.getWindowLocale('actions:delete', 'Delete'),
  },
];

const getOptionsForSelect = ({ loadConfigs }: Object) => ({
  areaOptions: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    loadConfigs.configsByNames,
    GC.CLO_ITEM_DAMAGE_REPORT_AREA,
    true,
  ),
  damageTypeOptions: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    loadConfigs.configsByNames,
    GC.CLO_ITEM_DAMAGE_REPORT_TYPE,
    true,
  ),
  severityOptions: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    loadConfigs.configsByNames,
    GC.CLO_ITEM_DAMAGE_REPORT_SEVERITY,
    true,
  ),
});

const withPreviewDocument = compose(
  connect(null, { openLoader, closeLoader }),
  withHandlers({
    handleOpenFilePreview: (props: Object) => async () => {
      const { fileUri, openLoader, closeLoader } = props;

      openLoader();
      const options = {
        params: { fileUri },
        resType: 'arraybuffer',
      };
      const endpoint = endpointsMap.damageReportDownloadFile;
      const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);
      const { status } = res;
      if (G.isResponseSuccess(status)) {
        G.openFileInWindowFromArrayBufferResponse(res);
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
);

const enhance = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      openLoader,
      closeLoader,
      removeLoadVehicleItemDamageSuccess,
      removeOrderVehicleItemDamageSuccess,
      createOrUpdateLoadVehicleItemDamageSuccess,
      createOrUpdateOrderVehicleItemDamageSuccess,
    },
  ),
  withTabs,
  withFixedPopover,
  withHandlers({
    handleCreateOrUpdateDamageRequest: (props: Object) => async (values: Object, isEditMode: boolean) => {
      const {
        closeModal,
        openLoader,
        closeLoader,
        callbackData,
        createOrUpdateLoadVehicleItemDamageSuccess,
        createOrUpdateOrderVehicleItemDamageSuccess,
      } = props;

      G.callFunction(openLoader);
      const successAction = G.ifElse(
        G.isLoadTypeTel(callbackData),
        createOrUpdateLoadVehicleItemDamageSuccess,
        createOrUpdateOrderVehicleItemDamageSuccess,
      );
      const file = G.getPropFromObject(GC.FIELD_DOCUMENT_UPLOAD, values);
      const endpoint = G.ifElse(
        G.isTrue(isEditMode),
        endpointsMap.damageReportUpdate,
        endpointsMap.damageReport,
      );
      const itemGuid = R.path(['parentProps', GC.FIELD_GUID], props);
      const reqData = R.assoc('itemGuid', itemGuid, R.dissoc(GC.FIELD_DOCUMENT_UPLOAD, values));
      const formData = new window.FormData();
      formData.append('file', file);
      formData.append('data', JSON.stringify(reqData));
      const res = await sendRequest('post', endpoint, { data: formData });
      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const message = G.getWindowLocale('messages:success:200-201', 'The request has succeeded');

        closeModal();
        successAction(data);
        G.showToastrMessageSimple('success', message);
      } else {
        G.handleFailResponseSimple(res, true, 'handleCreateOrUpdateDamageRequest fail');
      }

      G.callFunction(closeLoader);
    },
    handleRemoveDamageRequest: (props: Object) => async (guid: string) => {
      const {
        closeModal,
        openLoader,
        closeLoader,
        callbackData,
        removeLoadVehicleItemDamageSuccess,
        removeOrderVehicleItemDamageSuccess,
      } = props;

      G.callFunction(openLoader);
      const successAction = G.ifElse(
        G.isLoadTypeTel(callbackData),
        removeLoadVehicleItemDamageSuccess,
        removeOrderVehicleItemDamageSuccess,
      );
      const endpoint = endpointsMap.damageReportByGuid(guid);
      const res = await sendRequest('delete', endpoint);
      const { status } = res;

      if (G.isResponseSuccess(status)) {
        const itemGuid = R.path(['parentProps', GC.FIELD_GUID], props);
        const message = G.getWindowLocale('messages:success:remove', 'The data was removed successfully');

        closeModal();
        successAction({ guid, itemGuid });
        G.showToastrMessageSimple('success', message);
      } else {
        G.handleFailResponseSimple(res, true, 'handleRemoveDamageRequest fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  withHandlers({
    handleCreateOrUpdateDamage: (props: Object) => (entity: Object) => {
      const { activeTab, openModal, closeModal, callbackData, handleCreateOrUpdateDamageRequest } = props;

      let initialValues = entity;
      const isPhotos = G.isTrue(R.equals(activeTab, 1));
      const isEditMode = G.isNotNilAndNotEmpty(initialValues);
      const fileName = R.pathOr(G.getWindowLocale('titles:upload-file', 'Upload File'), [GC.FIELD_FILE_NAME], entity);

      if (isEditMode) {
        const type = R.path([GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], entity);
        const area = R.path([GC.FIELD_AREA, GC.FIELD_DROPDOWN_OPTION_GUID], entity);
        const severity = R.path([GC.FIELD_SEVERITY, GC.FIELD_DROPDOWN_OPTION_GUID], entity);

        initialValues = R.mergeRight(entity, { type, area, severity });
      }

      const component = (
        <DamageForm
          fileName={fileName}
          isPhotos={isPhotos}
          isEditMode={isEditMode}
          closeModal={closeModal}
          initialValues={initialValues}
          optionsForSelect={getOptionsForSelect(callbackData)}
          submitAction={(values: Object) => handleCreateOrUpdateDamageRequest(values, isEditMode)}
        />
      );
      const titleDamagesArr = G.ifElse(
        G.isTrue(isEditMode),
        ['titles:edit-damage', 'Edit Damage'],
        ['titles:add-damage', 'Add Damage'],
      );
      const titlePhotosArr = G.ifElse(
        G.isTrue(isEditMode),
        ['titles:edit-photo', 'Edit Photo'],
        ['titles:add-photo', 'Add Photo'],
      );
      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale(...G.ifElse(isPhotos, titlePhotosArr, titleDamagesArr)),
        },
      };

      openModal(modal);
    },
    handleRemoveDamage: (props: Object) => ({ guid }: Object) => {
      const { openModal, closeModal, handleRemoveDamageRequest } = props;

      const modal = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => handleRemoveDamageRequest(guid),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:damage', 'Damage')}?`,
      });

      openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <ActionsElement
            entity={entity}
            options={getOptionsForActions(props, entity)}
            handleCreateOrUpdateDamage={props.handleCreateOrUpdateDamage}
          />
        ),
      })
    ),
  }),
  pure,
);

const DamageFileCell = withPreviewDocument(({ fileName, handleOpenFilePreview }: Object) => (
  <Flex cursor='pointer' wordBreak='break-all' onClick={handleOpenFilePreview}>
    <Box mr='5px'>{I.eye(greyColor)}</Box> {fileName}
  </Flex>
));

const damageColumnSettings = {
  [GC.FIELD_TYPE]: {
    width: 150,
    name: 'titles:type',
    customComponent: R.pathOr('', ['data', GC.FIELD_TYPE, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_AREA]: {
    width: 150,
    name: 'titles:area',
    customComponent: R.pathOr('', ['data', GC.FIELD_AREA, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_SEVERITY]: {
    width: 150,
    name: 'titles:severity',
    customComponent: R.pathOr('', ['data', GC.FIELD_SEVERITY, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_NOTE]: {
    width: 300,
    name: 'titles:note',
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    width: 400,
    name: 'titles:damage-photo',
    customComponent: ({ data }: Object) => {
      const { fileUri, fileName } = data;

      if (G.isAllNilOrEmpty([fileUri, fileName])) return null;

      return <DamageFileCell fileUri={fileUri} fileName={fileName} />;
    },
  },
};

const photoColumnSettings = {
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    width: 400,
    name: 'titles:damage-photo',
    customComponent: ({ data }: Object) => {
      const { fileUri, fileName } = data;

      if (G.isAllNilOrEmpty([fileUri, fileName])) return null;

      return <DamageFileCell fileUri={fileUri} fileName={fileName} />;
    },
  },
};

const DamageTable = (props: Object) => {
  const {
    activeTab,
    totalCount,
    handleSetActiveTab,
    handleClickEditIcon,
    handleCreateOrUpdateDamage,
  } = props;

  const renderRowActions = (data: Object) => (
    <AuthWrapper has={[PC.ITEM_DAMAGE_WRITE]}>
      <IconWrapper
        px={12}
        cursor='pointer'
        onClick={(event: Object) => handleClickEditIcon(event, data)}
      >
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const actionButtonStyles = {
    px: '8px',
    height: 18,
    fontSize: 11,
    type: 'button',
    minWidth: 'unset',
    borderRadius: '5px',
    textColor: whiteColor,
    bgColor: 'transparent',
    border: `1px solid ${whiteColor}`,
  };

  const isPhotos = G.isTrue(R.equals(activeTab, 1));
  const damagesReport = R.pathOr([], ['parentProps', 'damages'], props);
  const damageReportFields = G.mapIndexed(
    (name: string, sequence: number) => ({ name, sequence }),
    R.keys(damageColumnSettings),
  );

  const photoReportFields = G.mapIndexed(
    (name: string, sequence: number) => ({ name, sequence }),
    R.keys(photoColumnSettings),
  );
  const report = { fields: G.ifElse(isPhotos, photoReportFields, damageReportFields) };

  const damages = R.filter(
    (item: Object) => G.isNotNilAndNotEmpty(R.path([GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], item)),
    damagesReport,
  );

  const photos = R.filter(
    (item: Object) => G.isNilOrEmpty(R.path([GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], item)),
    damagesReport,
  );

  const damagesTabComponent = () => (
    <AuthWrapper ml='8px' has={[PC.ITEM_DAMAGE_WRITE]}>
      <ActionButton
        {...actionButtonStyles}
        position='relative'
        onClick={() => handleCreateOrUpdateDamage()}
      >
        {G.getWindowLocale('titles:add', 'Add')}
      </ActionButton>
    </AuthWrapper>
  );

  const getDamagesTabs = () => [
    {
      withCount: false,
      renderRightStickedComponent: damagesTabComponent(),
      text: G.getWindowLocale('titles:damages', 'Damages'),
      title: G.getWindowLocale('titles:damages', 'Damages'),
    },
    {
      withCount: false,
      renderRightStickedComponent: damagesTabComponent(),
      text: G.getWindowLocale('titles:photos', 'Photos'),
      title: G.getWindowLocale('titles:photos', 'Photos'),
    },
  ];

  const wrapperStyles = {
    fontSize: '11px',
    color: whiteColor,
    width: 'max-content',
    alignItems: 'center',
    backgroundColor: blueColor,
  };

  return (
    <Box p={15} width='max-content'>
      <Tabs
        activeTab={activeTab}
        tabs={getDamagesTabs()}
        wrapperStyles={wrapperStyles}
        handleClickTab={handleSetActiveTab}
      />
      <Table
        loading={false}
        report={report}
        totalCount={totalCount}
        tableSettings={tableSettings}
        itemList={G.ifElse(isPhotos, photos, damages)}
        columnSettings={G.ifElse(isPhotos, photoColumnSettings, damageColumnSettings)}
        renderRightStickedComponent={(data: Object) => renderRowActions(data, handleClickEditIcon)}
      />
    </Box>
  );
};

export const vehicleTableSettings = {
  rowHeight: 40,
  cellFontSize: 11,
  titleFontSize: 11,
  maxHeight: '400px',
  titleRowHeight: 32,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  checkBoxCellWidth: 50,
  expandableItems: true,
  allowSelectItems: false,
  additionalTitleBgColor: G.getTheme('colors.lightGrey'),
  expandedDetailsComponent: (props: Object) => <DamageTable {...props} />,
};

export default enhance(DamageTable);
