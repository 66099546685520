import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import { ifElse } from '../../helpers';
// feature expanded-container
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  window: null,
  options: null,
  opened: false,
  componentType: null,
  visitPageGuid: null,
};

const setExpandedContainerOptions = (
  state: Object,
  { opened, options, componentType, visitPageGuid, componentsHistory }: Object,
) => (
  P.$all(
    P.$set('opened', opened),
    P.$set('componentType', componentType),
    P.$set('visitPageGuid', visitPageGuid),
    P.$set('options', ifElse(R.and(opened, state.opened), state.options, options)),
    P.$set('componentsHistory', R.or(componentsHistory, R.prop('componentsHistory', state))),
    state,
  )
);

const setInitialExpandedContainer = () => initialState;

export default createReducer({
  [A.setExpandedContainerOptions]: setExpandedContainerOptions,
  [A.setInitialExpandedContainer]: setInitialExpandedContainer,
}, initialState);
