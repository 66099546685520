import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Form } from '../../../forms';
import { Fieldset2 } from '../../../forms/formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const defaultValues = {
  [GC.FIELD_NOTE]: '',
  [GC.FIELD_RESERVATION_TIME_MINUTES]: 15,
};

const inputWrapperStyles = { mb: 25, width: 270 };

const fieldSettings = [
  {
    type: 'textarea',
    inputWrapperStyles,
    fieldName: GC.FIELD_NOTE,
    label: ['titles:note', 'Note'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_RESERVATION_TIME_MINUTES,
    label: ['titles:reservation-time', 'Reservation Time (mins)'],
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_NOTE]: G.yupStringNotRequired
    .max(1999, G.getShouldBeFromToCharLocaleTxt(1, 1999)),
  [GC.FIELD_RESERVATION_TIME_MINUTES]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .integer(G.getShouldBeIntegerLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt())
    .max(120, G.getShouldBeLessOrEqualLocaleTxt(120)),
});

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
    handleSubmit: ({ note, reservationTimeMinutes }: Object, { props }: Object) => {
      const { driverGuid, submitAction, reservationEndDate } = props;

      const data = {
        note,
        driverGuid,
        reservationTimeMinutes,
        isCreate: G.isNilOrEmpty(reservationEndDate),
      };

      submitAction(data);
    },
  }),
  pure,
);

const ReservationEndDateComponent = ({ reservationEndDate }: Object) => (
  <Flex mb={20} flexDirection='column' justifyContent='center'>
    {G.getWindowLocale('titles:reservation-end-time', 'Reservation End Time')}:
    <Box mt='5px' fontSize={20} fontWeight={600} textAlign='center' color={G.getThemeColor('colors.mainBlue')}>
      {reservationEndDate}
    </Box>
  </Flex>
);

export const ReservationForm = enhance((props: Object) => {
  const { handleSubmit, reservationEndDate } = props;

  return (
    <Box>
      {
        G.isNotNilAndNotEmpty(reservationEndDate) &&
        <ReservationEndDateComponent reservationEndDate={reservationEndDate} />
      }
      <Form onSubmit={handleSubmit}>
        <Fieldset2 {...G.getFormikProps(props)} fields={fieldSettings} fieldsWrapperStyles={{ mt: 10 }} />
        <FormFooter2 />
      </Form>
    </Box>
  );
});
