import * as R from 'ramda';
import { put, call, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import {
  getConfigsForCloConfigSuccess,
  receivedStatusMessageListForCloSuccess,
  getAccessorialConfigListForCloConfigSuccess } from './actions';
import {
  getConfigByGroupSaga,
  getSharedAccessorialListSaga,
  getSequencesByTypeForConfigSaga,
  getTermsAndConditionsDocumentRequest,
  getReferenceTypesByScopeForConfigSaga,
} from '../../sagas';
//////////////////////////////////////////////////

function* getStatusMessageListForCloConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const mapper = R.map((item: Object) => (
      { label: R.prop(GC.FIELD_DISPLAYED_VALUE, item), value: R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item) }
    ));
    yield call(crudSaga, {
      mapper,
      options,
      method: 'get',
      endpoint: endpointsMap.statusMessagesListEndpoint,
      successAction: receivedStatusMessageListForCloSuccess,
      parentSagaName: 'getStatusMessageListForCloConfigSaga',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getStatusMessageListForCloConfigSaga exception');
  }
}

function* getAccessorialConfigListForCloConfigRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      callbackSaga: getSharedAccessorialListSaga,
      endpoint: endpointsMap.accessorialsListEndpoint,
      successAction: getAccessorialConfigListForCloConfigSuccess,
      parentSagaName: 'getAccessorialConfigListForCloConfigRequest',
    });
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown exception');
    yield call(G.handleException, error, 'getAccessorialConfigListForCloConfigRequest fail');
  }
}

function* getConfigsForCloConfigSaga() {
  try {
    const names = R.join(',', [
      GC.GENERAL_EQUIPMENTS,
      GC.TEMPLATES_LOCATION_TYPE,
      GC.CLO_ORDER_TYPE_DROPDOWN,
      GC.CLO_ITEM_DAMAGE_REPORT_AREA,
      GC.COMMUNICATION_DOCUMENT_TYPE,
      GC.GENERAL_MODE_TRANSPORTATION,
      GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
    ]);
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      shouldCloseModal: true,
      mapper: G.mapConfigValuesByName,
      endpoint: endpointsMap.branchConfigsEndpoint,
      parentSagaName: 'getConfigsForCloConfigSaga',
      successAction: getConfigsForCloConfigSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsForCloConfigSaga exception');
  }
}

// default order accessorials
function* getDefaultOrderAccessorialListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.defaultOrderAccessorialList,
      successAction: A.getDefaultOrderAccessorialListSuccess,
      parentSagaName: 'getDefaultOrderAccessorialListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDefaultOrderAccessorialListRequest exception');
  }
}

function* createOrUpdateDefaultOrderAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.defaultOrderAccessorial,
      successAction: A.createOrUpdateDefaultOrderAccessorialSuccess,
      parentSagaName: 'createOrUpdateDefaultOrderAccessorialRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateServiceTypeMappingRequest exception');
  }
}

function* removeDefaultOrderAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.defaultOrderAccessorial,
      successAction: A.removeDefaultOrderAccessorialSuccess,
      parentSagaName: 'removeDefaultOrderAccessorialRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeDefaultOrderAccessorialRequest exception');
  }
}

function* handleVisitConfigCloPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_CLO_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getConfigByGroupSaga, GC.CLO_CONFIG_GROUP);
    yield call(getStatusMessageListForCloConfigSaga);
    yield call(getAccessorialConfigListForCloConfigRequest);
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_CLO });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_TEL });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CLO });
    yield call(getDefaultOrderAccessorialListRequest);
    yield call(getTermsAndConditionsDocumentRequest, { payload: GC.CLO_CONFIG_GROUP });
    yield fork(getConfigsForCloConfigSaga);
    yield put(A.getAvailableDocumentTypes());
    yield put(closeLoader());
    break;
  }
}

function* cloConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_CLO_PAGE, handleVisitConfigCloPageSaga);
  // default order accessorials
  yield takeLatest(A.removeDefaultOrderAccessorialRequest, removeDefaultOrderAccessorialRequest);
  yield takeLatest(A.getDefaultOrderAccessorialListRequest, getDefaultOrderAccessorialListRequest);
  yield takeLatest(A.createOrUpdateDefaultOrderAccessorialRequest, createOrUpdateDefaultOrderAccessorialRequest);
}

export default cloConfigWatcherSaga;
