import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const editItem = createAction('editItem');
export const selectTel = createAction('selectTel');
export const toggleTel = createAction('toggleTel');
export const deleteTel = createAction('deleteTel');
export const setTelList = createAction('setTelList');
export const cleanBuilder = createAction('cleanBuilder');
export const cleanTelRate = createAction('cleanTelRate');
export const setTelReports = createAction('setTelReports');
export const addTelsToList = createAction('addTelsToList');
export const setCloDetails = createAction('setCloDetails');
export const setTelDetails = createAction('setTelDetails');
export const sortTelEvents = createAction('sortTelEvents');
export const setFilterProps = createAction('setFilterProps');
export const setRouteErrors = createAction('setRouteErrors');
export const getTelNextPage = createAction('getTelNextPage');
export const addNewItemOnUI = createAction('addNewItemOnUI');
export const setPlannerMode = createAction('setPlannerMode');
export const selectCloEvent = createAction('selectCloEvent');
export const toggleCloEvent = createAction('toggleCloEvent');
export const updateContainer = createAction('updateContainer');
export const setCloEventList = createAction('setCloEventList');
export const addItemsForDrop = createAction('addItemsForDrop');
export const setCurrentRoute = createAction('setCurrentRoute');
export const addTelToPlanner = createAction('addTelToPlanner');
export const cleanTelDetails = createAction('cleanTelDetails');
export const toggleTelDetails = createAction('toggleTelDetails');
export const addTrailersToStop = createAction('addTrailersToStop');
export const setAvailableItems = createAction('setAvailableItems');
export const setTelListLoading = createAction('setTelListLoading');
export const addItemsForPickup = createAction('addItemsForPickup');
export const setInboundTelList = createAction('setInboundTelList');
export const toggleHidePlanned = createAction('toggleHidePlanned');
export const removeTelsFromList = createAction('removeTelsFromList');
export const savePlannerRequest = createAction('savePlannerRequest');
export const setSelectedTelList = createAction('setSelectedTelList');
export const resetTelPagination = createAction('resetTelPagination');
export const setBottomTableMode = createAction('setBottomTableMode');
export const removeItemFromStop = createAction('removeItemFromStop');
export const setOutboundTelList = createAction('setOutboundTelList');
export const setCloEventsReports = createAction('setCloEventsReports');
export const setCurrentTelReport = createAction('setCurrentTelReport');
export const cleanTelQuickFilter = createAction('cleanTelQuickFilter');
export const addItemsClosForDrop = createAction('addItemsClosForDrop');
export const selectTelOnAllLists = createAction('selectTelOnAllLists');
export const setInboundTerminals = createAction('setInboundTerminals');
export const addExchangeTerminal = createAction('addExchangeTerminal');
export const getTelDetailsRequest = createAction('getTelDetailsRequest');
export const validateRouteRequest = createAction('validateRouteRequest');
export const removeTelFromPlanner = createAction('removeTelFromPlanner');
export const setOutboundTerminals = createAction('setOutboundTerminals');
export const getCloEventsNextPage = createAction('getCloEventsNextPage');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const resetEditedCloEvents = createAction('resetEditedCloEvents');
export const setDeletedEventGuids = createAction('setDeletedEventGuids');
export const removeTrailerFromStop = createAction('removeTrailerFromStop');
export const toggleCloEventDetails = createAction('toggleCloEventDetails');
export const getInboundTelNextPage = createAction('getInboundTelNextPage');
export const addItemsClosForPickup = createAction('addItemsClosForPickup');
export const removeCloItemFromStop = createAction('removeCloItemFromStop');
export const setInitialPlannerData = createAction('setInitialPlannerData');
export const addRouteTemplateStops = createAction('addRouteTemplateStops');
export const addNewContainerToStop = createAction('addNewContainerToStop');
export const setAvailableContainers = createAction('setAvailableContainers');
export const setAvailableCloReports = createAction('setAvailableCloReports');
export const setAvailableTelReports = createAction('setAvailableTelReports');
export const createTelReportRequest = createAction('createTelReportRequest');
export const updateTelReportRequest = createAction('updateTelReportRequest');
export const setInitialCurrentRoute = createAction('setInitialCurrentRoute');
export const getOutboundTelNextPage = createAction('getOutboundTelNextPage');
export const dispatchTelRateRequest = createAction('dispatchTelRateRequest');
export const setCloEventListLoading = createAction('setCloEventListLoading');
export const setGlobalFilterValueET = createAction('setGlobalFilterValueET');
export const setGlobalFilterValueIT = createAction('setGlobalFilterValueIT');
export const setGlobalFilterValueOT = createAction('setGlobalFilterValueOT');
export const uncheckItemsOnAllLists = createAction('uncheckItemsOnAllLists');
export const setBranchGuidForRequest = createAction('setBranchGuidForRequest');
export const setTelQuickFilterParams = createAction('setTelQuickFilterParams');
export const addNewTelToRouteBuilder = createAction('addNewTelToRouteBuilder');
export const addExistedTerminalToTel = createAction('addExistedTerminalToTel');
export const setSelectedCloEventList = createAction('setSelectedCloEventList');
export const resetCloEventPagination = createAction('resetCloEventPagination');
export const setTelsToPlannerRequest = createAction('setTelsToPlannerRequest');
export const removeContainerFromStop = createAction('removeContainerFromStop');
export const addDropContainersToStop = createAction('addDropContainersToStop');
export const setCurrentCloEventReport = createAction('setCurrentCloEventReport');
export const cleanCloEventQuickFilter = createAction('cleanCloEventQuickFilter');
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
export const setInboundTelListLoading = createAction('setInboundTelListLoading');
export const uncheckAndRemoveCloEvent = createAction('uncheckAndRemoveCloEvent');
export const getRouteTemplatesRequest = createAction('getRouteTemplatesRequest');
export const getRouteTemplatesSuccess = createAction('getRouteTemplatesSuccess');
export const resetTelListAndPagination = createAction('resetTelListAndPagination');
export const setOutboundTelListLoading = createAction('setOutboundTelListLoading');
export const getCloEventDetailsRequest = createAction('getCloEventDetailsRequest');
export const getCloEventDetailsSuccess = createAction('getCloEventDetailsSuccess');
export const refreshPlannerDataRequest = createAction('refreshPlannerDataRequest');
export const getRateListByGuidsSuccess = createAction('getRateListByGuidsSuccess');
export const addTelToLoadPlannerByGuid = createAction('addTelToLoadPlannerByGuid');
export const addPickupContainersToStop = createAction('addPickupContainersToStop');
export const createRouteTemplateRequest = createAction('createRouteTemplateRequest');
export const createRouteTemplateSuccess = createAction('createRouteTemplateSuccess');
export const getEventListByGuidsSuccess = createAction('getEventListByGuidsSuccess');
export const getAvailableReportsRequest = createAction('getAvailableReportsRequest');
export const setCurrentInboundTelReport = createAction('setCurrentInboundTelReport');
export const addStopsToExchangeTerminal = createAction('addStopsToExchangeTerminal');
export const addNewRateToLoadPlannerTel = createAction('addNewRateToLoadPlannerTel');
export const addUnassignedEventToPlanner = createAction('addUnassignedEventToPlanner');
export const setRouteBuilderInitialState = createAction('setRouteBuilderInitialState');
export const setCurrentRouteInitialState = createAction('setCurrentRouteInitialState');
export const addNewStopToRouteBuilderTel = createAction('addNewStopToRouteBuilderTel');
export const setCurrentOutboundTelReport = createAction('setCurrentOutboundTelReport');
export const selectCloEventForTelRequest = createAction('selectCloEventForTelRequest');
export const selectCloEventForTelSuccess = createAction('selectCloEventForTelSuccess');
export const addTerminalToLoadPlannerTel = createAction('addTerminalToLoadPlannerTel');
export const addTerminalOnEventAddOrMove = createAction('addTerminalOnEventAddOrMove');
export const resetAllListsAndPaginations = createAction('resetAllListsAndPaginations');
export const setCloRelatedTelListLoading = createAction('setCloRelatedTelListLoading');
export const setCurrentTelReportWithRoute = createAction('setCurrentTelReportWithRoute');
export const getCrossDockLocationsSuccess = createAction('getCrossDockLocationsSuccess');
export const createCloEventsReportRequest = createAction('createCloEventsReportRequest');
export const updateCloEventsReportRequest = createAction('updateCloEventsReportRequest');
export const setCloEventQuickFilterParams = createAction('setCloEventQuickFilterParams');
export const addTerminalToLoadPlannerTelUI = createAction('addTerminalToLoadPlannerTelUI');
export const changeDefaultTelReportRequest = createAction('changeDefaultTelReportRequest');
export const removeStopFromRouteBuilderTel = createAction('removeStopFromRouteBuilderTel');
export const dispatchCarrierTelRateRequest = createAction('dispatchCarrierTelRateRequest');
export const resetCloEventListAndPagination = createAction('resetCloEventListAndPagination');
export const recalculateTelDistancesRequest = createAction('recalculateTelDistancesRequest');
export const recalculateTelDistancesSuccess = createAction('recalculateTelDistancesSuccess');
export const addNewCloStopToRouteBuilderTel = createAction('addNewCloStopToRouteBuilderTel');
export const updateCloStopsOnPlannerRequest = createAction('updateCloStopsOnPlannerRequest');
export const updateCloStopsOnPlannerSuccess = createAction('updateCloStopsOnPlannerSuccess');
export const getCloTelsAndSetToStoreRequest = createAction('getCloTelsAndSetToStoreRequest');
export const getCloTelsAndSetToStoreSuccess = createAction('getCloTelsAndSetToStoreSuccess');
export const setInitPlannerDataFromTemplate = createAction('setInitPlannerDataFromTemplate');
export const openDispatchPlannerEventsRequest = createAction('openDispatchPlannerEventsRequest');
export const resetInboundTelListAndPagination = createAction('resetInboundTelListAndPagination');
export const getCloTelsAndAddToPlannerRequest = createAction('getCloTelsAndAddToPlannerRequest');
export const addTerminalPickupToLoadPlannerTel = createAction('addTerminalPickupToLoadPlannerTel');
export const resetOutboundTelListAndPagination = createAction('resetOutboundTelListAndPagination');
export const changeDefaultCloEventReportRequest = createAction('changeDefaultCloEventReportRequest');
export const updateStopOnRouteBuilderTelRequest = createAction('updateStopOnRouteBuilderTelRequest');
export const updateStopOnRouteBuilderTelSuccess = createAction('updateStopOnRouteBuilderTelSuccess');
export const getAvailableCloEventsReportSuccess = createAction('getAvailableCloEventsReportSuccess');
export const addNewStopToRouteBuilderTelSuccess = createAction('addNewStopToRouteBuilderTelSuccess');
export const getTelForListAndAddToPlannerRequest = createAction('getTelForListAndAddToPlannerRequest');
export const addCloEventToInterTerminalTelRequest = createAction('addCloEventToInterTerminalTelRequest');
export const addCloEventToInterTerminalTelSuccess = createAction('addCloEventToInterTerminalTelSuccess');
export const getTelsByGuidsAndAddToPlannerRequest = createAction('getTelsByGuidsAndAddToPlannerRequest');
export const refreshCloEventListWithSelectedEvents = createAction('refreshCloEventListWithSelectedEvents');
export const setDispatchPlannerEventsOpenedFromPage = createAction('setDispatchPlannerEventsOpenedFromPage');
export const recalculateTelDistancesWithMoveStopRequest = createAction('recalculateTelDistancesWithMoveStopRequest');
export const recalculateTelDistancesWithMoveItemSuccess = createAction('recalculateTelDistancesWithMoveItemSuccess');
// table title filtering
export const setTelTableTitleSort = createAction('setTelTableTitleSort');
export const setTelTableTitleFilter = createAction('setTelTableTitleFilter');
export const setInboundTableTitleSort = createAction('setInboundTableTitleSort');
export const setOutboundTableTitleSort = createAction('setOutboundTableTitleSort');
export const setInboundTableTitleFilter = createAction('setInboundTableTitleFilter');
export const setCloEventsTableTitleSort = createAction('setCloEventsTableTitleSort');
export const setOutboundTableTitleFilter = createAction('setOutboundTableTitleFilter');
export const setCloEventsTableTitleFilter = createAction('setCloEventsTableTitleFilter');
// clo events map
export const getStopInfoRequest = createAction('getStopInfoRequest');
export const getStopInfoSuccess = createAction('getStopInfoSuccess');
export const addStopsFromMapToPlanner = createAction('addStopsFromMapToPlanner');
export const setCloEventListForMapLoading = createAction('setCloEventListForMapLoading');
export const getCloEventsListForMapRequest = createAction('getCloEventsListForMapRequest');
export const getCloEventsListForMapSuccess = createAction('getCloEventsListForMapSuccess');
