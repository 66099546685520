import * as R from 'ramda';
import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedFlex } from '../../../ui';
// feature audit2
import AuditItem from './audit-item';
import AuditDetails from './audit-details';
import { getBorderStyles } from '../helpers';
import { toggleAuditDetails } from '../actions';
import { fieldSetting } from '../settings/field-settings';
/////////////////////////////////////////////////

const darkGrey = G.getTheme('colors.dark.darkGrey');

const ToggleIcon = ({ condition }: Object) => G.ifElse(
  condition,
  I.arrowUpSimple,
  I.arrowDownSimple,
)(darkGrey, 14, 14);

const renderAuditItem = ({ item, borderStyle, fieldSetting }: Object) =>
  G.getNewArrayFromObjectWithHandling(
    (field: Object, key: string) => {
      if (R.equals(key, GC.FIELD_DATE)) {
        const dateTimeFormatFromConfig = G.getDateTimeFormatFromConfig();

        const dateTimeFormat = G.ifElse(
          R.equals(dateTimeFormatFromConfig, GC.DEFAULT_US_MILITARY_DATE_TIME_FORMAT),
          GC.DEFAULT_MILITARY_TIME_FORMAT,
          GC.DEFAULT_TIME_FORMAT,
        );

        return (
          <AuditItem
            key={key}
            borderStyle='none'
            width={field.width}
            minWidth={field.minWidth}
          >
            <Flex color='darkGrey'>
              <Flex mr='9px'>
                {I.time(darkGrey, 20, 20)}
              </Flex>
              <TextComponent>
                {G.createLocalDateTimeFromInstanceOrISOString(item[key], dateTimeFormat)}
              </TextComponent>
            </Flex>
          </AuditItem>
        );
      }

      let itemContent = item[key];

      if (R.equals(key, GC.FIELD_NAME)) itemContent = `"${item[key]}"`;

      if (R.equals(key, GC.FIELD_STATUS)) itemContent = G.getEnumLocale(item[key]);

      return (
        <AuditItem
          key={key}
          title={item[key]}
          minWidth={field.minWidth}
          borderStyle={borderStyle}
        >
          {itemContent}
        </AuditItem>
      );
    },
    fieldSetting,
  );

const AuditList = ({ list }: Object) => {
  const dispatch = useDispatch();

  const handleToggle = (item: Object) => dispatch(toggleAuditDetails(item));

  return (
    <Fragment>
      {
        list.map((item: Object, index: number, arr: Array) => {
          const borderStyle = getBorderStyles(arr, index);

          const backgroundColor = G.ifElse(
            item.expanded,
            G.getTheme('colors.bgGrey'),
            'none',
          );

          return (
            <Box px={15} key={index} backgroundColor={backgroundColor} data-testid='testid-auditItem'>
              <StickedFlex
                top={24}
                zIndex={2}
                alignItems='flex-start'
                backgroundColor={backgroundColor}
              >
                {renderAuditItem({ item, borderStyle, fieldSetting })}
                <Flex
                  height={45}
                  minWidth={30}
                  justifyContent='flex-end'
                  borderBottom={borderStyle}
                >
                  {
                    G.isNotNilAndNotEmpty(item.changedFields) &&
                    <Box
                      ml='auto'
                      width={14}
                      cursor='pointer'
                      data-testid='testid-toggleButton'
                      onClick={() => handleToggle(item)}
                    >
                      <ToggleIcon condition={item.expanded} />
                    </Box>
                  }
                </Flex>
              </StickedFlex>
              {item.expanded && <AuditDetails fields={item.changedFields} />}
            </Box>
          );
        })
      }
    </Fragment>
  );
};

export default AuditList;
