import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { sendCloToExternalSystemRequest } from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
// feature dispatch details new
import { removeCloFromExternalSystemRequest } from '../actions';
//////////////////////////////////////////////////

export const withOrderExternalTableGroupRowActions = compose(
  connect(
    null,
    {
      sendCloToExternalSystemRequest,
      removeCloFromExternalSystemRequest,
    },
  ),
  withHandlers({
    handleSendToExternalSystem: (props: Object) => () => {
      const { entity, closeFixedPopup, sendCloToExternalSystemRequest } = props;

      const { cloGuid, integrationType } = entity;

      G.callFunction(closeFixedPopup);

      sendCloToExternalSystemRequest({ cloGuid, integrationType });
    },
    handleRemoveCloFromExternalSystem: (props: Object) => () => {
      const { entity, openModal, closeFixedPopup, removeCloFromExternalSystemRequest } = props;

      G.callFunction(closeFixedPopup);

      const textLocale = `${G.getWindowLocale(
        'messages:remove-order-from-external-system',
        'Do you want to delete this order from external system')}?
      `;

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeCloFromExternalSystemRequest(entity),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
