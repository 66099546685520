import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { makeSelectLoader } from '../../../../components/loader/selectors';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature configs
import ConfigComponent from '../../components/config-component';
import { itemPageConfigEnhancer, defaultPageConfigEnhancer } from '../../hocs';
import {
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  saveDispatchBoardStatusActionRequest,
  updateDispatchBoardStatusActionRequest,
  deleteDispatchBoardStatusActionRequest,
} from '../../actions';
import {
  makeSelectConfigOptions,
  makeSelectConfigDropdowns,
  makeSelectConfigInitialValues,
  makeSelectConfigInheritedValues,
  makeSelectCloDispatchBoardActionPanel,
  makeSelectTelDispatchBoardActionPanel,
} from '../../selectors';
// configs ui
import StatusActionsConfigForm from './components/status-actions-config-form';
//////////////////////////////////////////////////

const enhance = compose(
  defaultPageConfigEnhancer(GC.UI_CONFIG_GROUP),
  itemPageConfigEnhancer,
  withHandlers({
    handleClickEndLabel: (props: Object) => (configName: string, popupTitle: string) => {
      if (R.equals(configName, GC.UI_CHART_COLLECTIONS_TYPE)) {
        const { handleClickEndLabel } = props;

        return handleClickEndLabel(configName, popupTitle);
      }

      const {
        cloPanel,
        telPanel,
        openModal,
        closeModal,
        saveDispatchBoardStatusActionRequest,
      } = props;

      const panelValues = G.ifElse(
        R.equals(configName, GC.LOAD_TYPE_CLO),
        cloPanel,
        telPanel,
      );

      const statusOptions = R.filter(
        ({ value }: Object) => R.isNil(R.find(R.propEq(value, GC.FIELD_STATUS), panelValues)),
        G.getStatusOptions(configName),
      );

      const component = (
        <StatusActionsConfigForm
          loadType={configName}
          closeModal={closeModal}
          statusOptions={statusOptions}
          submitAction={(values: Object) => saveDispatchBoardStatusActionRequest({
            data: values,
            loadType: configName,
          })}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 480,
          height: 'auto',
          maxHeight: '90vh',
          title: G.getAddTitle(['titles:action-button-config', 'Action Button Config']),
        },
      };

      return openModal(modal);
    },
    handleEditEntity: (props: Object) => (entity: string, loadType: string) => {
      const {
        openModal,
        closeModal,
        updateDispatchBoardStatusActionRequest,
      } = props;

      const statusOptions = R.filter(
        R.propEq(entity.status, GC.FIELD_VALUE),
        G.getStatusOptions(loadType),
      );

      const initialValues = R.pick(
        [
          GC.FIELD_STATUS,
          GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW,
          GC.FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW,
        ],
        entity,
      );

      const component = (
        <StatusActionsConfigForm
          loadType={loadType}
          closeModal={closeModal}
          statusOptions={statusOptions}
          initialValues={initialValues}
          submitAction={(values: Object) => updateDispatchBoardStatusActionRequest({
            loadType,
            data: R.mergeRight(entity, values),
          })}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 480,
          height: 'auto',
          maxHeight: '90vh',
          title: G.getEditTitle(['titles:action-button-config', 'Action Button Config']),
        },
      };

      return openModal(modal);
    },
  }),
  pure,
);

const ConfigPageComponent = (props: Object) => (
  <ConfigComponent
    {...props}
    useTabs={true}
    pageTitle='titles:ui'
    groupSettings={GC.UI_CONFIG_GROUP}
  />
);

const mapStateToProps = (state: Object) => ({
  loader: makeSelectLoader()(state),
  cloPanel: makeSelectCloDispatchBoardActionPanel()(state),
  telPanel: makeSelectTelDispatchBoardActionPanel()(state),
  dropdowns: makeSelectConfigDropdowns(state, GC.UI_CONFIG_GROUP),
  configOptions: makeSelectConfigOptions(state, GC.UI_CONFIG_GROUP),
  initialValues: makeSelectConfigInitialValues(state, GC.UI_CONFIG_GROUP),
  inheritedValues: makeSelectConfigInheritedValues(state, GC.UI_CONFIG_GROUP),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateConfigsRequest,
  restoreInheritedByConfigTypeRequest,
  saveDispatchBoardStatusActionRequest,
  updateDispatchBoardStatusActionRequest,
  deleteDispatchBoardStatusActionRequest,
})(enhance(ConfigPageComponent));
