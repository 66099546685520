import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as GC from '../../../constants';
// feature fleet/trailer
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  trailers: {},
  totalCount: 0,
  documents: [],
  locations: [],
  insurances: [],
  filterProps: [],
  filterParams: {},
  usedReport: null,
  trailerConfigs: {},
  equipComponent: [],
  trailerDetails: {},
  titleSortValues: {},
  trailerOwnership: {},
  activeTab: 'details',
  documentsFilter: null,
  globalFilterValue: '',
  tableTitleFilters: {},
  availableReports: null,
  latestKnownLocation: [],
  externalIntegration: [],
  trailerSpecification: {},
  [GC.UI_FIELD_REFERENCES]: [],
  pagination: {
    limit: 20,
    offset: 0,
  },
  collapsedGroup: {
    services: true,
    ownership: true,
    insurance: true,
    documents: true,
    locations: true,
    components: true,
    termDetails: true,
    costDetails: true,
    refrigeration: true,
    locationFields: true,
    trailerDetails: true,
    locationContact: true,
    equipmentReturn: true,
    mainSpecification: true,
    integrationSamsara: true,
    trailerRegistration: true,
    trailerLatestKnownLocation: true,
  },
};

const setInitialState = () => initialState;

const receivedGetTrailerSuccess = (state: Object, trailer: Object) => (
  P.$set('trailerDetails', trailer, state)
);

const receivedGetTrailerOwnershipSuccess = (state: Object, trailerOwnership: Object) => (
  P.$set('trailerOwnership', trailerOwnership, state)
);

const receivedGetTrailerRefSuccess = (state: Object, refs: Array) => (
  P.$set(GC.UI_FIELD_REFERENCES, refs, state)
);

const receivedGetTrailerInsuranceSuccess = (state: Object, insurances: Array) => (
  P.$set('insurances', insurances, state)
);

const receivedGetTrailerDocumentSuccess = (state: Object, docs: Array) => (
  P.$set('documents', docs, state)
);

const setDocumentsFilter = (state: Object, filter: string) => P.$set('documentsFilter', filter, state);

const receivedGetTrailerLocationSuccess = (state: Object, locations: Array) => (
  P.$set('locations', locations, state)
);

const receivedGetTrailerSpecificationSuccess = (state: Object, data: Object) => (
  P.$set('trailerSpecification', data, state)
);

const receivedGetTrailerComponentSuccess = (state: Object, data: Object) => (
  P.$set('equipComponent', data, state)
);

const receivedGetTrailerConfigsSuccess = (state: Object, data: Object) => (
  P.$set('trailerConfigs', R.assoc('dropdowns', R.mergeRight(state.trailerConfigs.dropdowns, data.dropdowns), data), state)
);

const receivedGetLocationTypesSuccess = (state: Object, data: Object) => (
  P.$override('trailerConfigs.dropdowns', { [GC.TEMPLATES_LOCATION_TYPE]: data }, state)
);

const receivedTrailerLatestKnownLocationSuccess = (state: Object, location: Object) => (
  P.$set('latestKnownLocation', location, state)
);

const receivedCreateTrailerReferenceSuccess = (state: Object, data: Object) => (
  P.$add(GC.UI_FIELD_REFERENCES, data, state)
);

const receivedCreateTrailerInsuranceSuccess = (state: Object, insurance: Object) => (
  P.$add('insurances', insurance, state)
);

const receivedCreateTrailerDocumentSuccess = (state: Object, doc: Object) => (
  P.$add('documents', doc, state)
);

const receivedCreateTrailerLocationSuccess = (state: Object, location: Object) => (
  P.$add('locations', location, state)
);

const receivedCreateTrailerLatestKnownLocationSuccess = (
  state: Object,
  location: Object,
) => (
  P.$set('latestKnownLocation', location, state)
);

const receivedUpdateTrailerReferenceSuccess = (state: Object, ref: Object) => (
  P.$set(`${GC.UI_FIELD_REFERENCES}.${ref.guid}`, ref, state)
);

const receivedUpdateTrailerInsuranceSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`insurances.${index}`, data, state)
);

const receivedUpdateTrailerDocumentSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`documents.${index}`, data, state)
);

const receivedUpdateTrailerLocationSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`locations.${index}`, data, state)
);

const receivedUpdateTrailerDetailsSuccess = (state: Object, trailer: Object) => (
  P.$set('trailerDetails', trailer, state)
);

const receivedUpdateTrailerOwnershipSuccess = (state: Object, trailerOwnership: Object) => (
  P.$set('trailerOwnership', trailerOwnership, state)
);

const receivedUpdateTrailerSpecificationSuccess = (state: Object, data: Object) => (
  P.$set('trailerSpecification', data, state)
);

const receivedUpdateTrailerComponentSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`equipComponent.${index}`, data, state)
);

const receivedDeleteTrailerReferenceSuccess = (state: Object, refGuid: string) => (
  P.$drop(`${GC.UI_FIELD_REFERENCES}.${refGuid}`, state)
);

const receivedDeleteTrailerInsuranceSuccess = (state: Object, index: string) => (
  P.$drop(`insurances.${index}`, state)
);

const receivedDeleteTrailerDocumentSuccess = (state: Object, index: string) => (
  P.$drop(`documents.${index}`, state)
);

const receivedDeleteTrailerLocationSuccess = (state: Object, index: string) => (
  P.$drop(`locations.${index}`, state)
);

const receivedDeleteTrailerComponentSuccess = (state: Object, index: string) => (
  P.$drop(`equipComponent.${index}`, state)
);

const toggleTrailerFormGroup = (state: Object, groupName: string) => (
  P.$toggle(`collapsedGroup.${groupName}`, state)
);

const setActiveTab = (state: Object, tabName: string) => (
  P.$set('activeTab', tabName, state)
);

const cleanFormStore = (state: Object) => (
  P.$all(
    P.$set('externalIntegration', []),
    P.$set('trailerDetails', initialState.trailerDetails),
    P.$set('trailerOwnership', initialState.trailerOwnership),
    P.$set('trailerSpecification', initialState.trailerSpecification),
    state,
  )
);

const getExternalTrailerIdSuccess = (state: Object, data: string) => (
  P.$set('externalIntegration', data, state)
 );

const deleteExternalTrailerIdSuccess = (state: Object, guid: string) => {
  const { externalIntegration } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), externalIntegration);

  return P.$drop(`externalIntegration.${index}`, state);
};

export default createReducer({
  [A.setActiveTab]: setActiveTab,
  [A.cleanFormStore]: cleanFormStore,
  [A.setInitialState]: setInitialState,
  [A.setDocumentsFilter]: setDocumentsFilter,
  [A.toggleTrailerFormGroup]: toggleTrailerFormGroup,
  [A.receivedGetTrailerSuccess]: receivedGetTrailerSuccess,
  [A.getExternalTrailerIdSuccess]: getExternalTrailerIdSuccess,
  [A.receivedGetTrailerRefSuccess]: receivedGetTrailerRefSuccess,
  [A.deleteExternalTrailerIdSuccess]: deleteExternalTrailerIdSuccess,
  [A.receivedGetLocationTypesSuccess]: receivedGetLocationTypesSuccess,
  [A.receivedGetTrailerConfigsSuccess]: receivedGetTrailerConfigsSuccess,
  [A.receivedGetTrailerLocationSuccess]: receivedGetTrailerLocationSuccess,
  [A.receivedGetTrailerDocumentSuccess]: receivedGetTrailerDocumentSuccess,
  [A.receivedGetTrailerOwnershipSuccess]: receivedGetTrailerOwnershipSuccess,
  [A.receivedGetTrailerComponentSuccess]: receivedGetTrailerComponentSuccess,
  [A.receivedGetTrailerInsuranceSuccess]: receivedGetTrailerInsuranceSuccess,
  [A.receivedUpdateTrailerDetailsSuccess]: receivedUpdateTrailerDetailsSuccess,
  [A.receivedCreateTrailerDocumentSuccess]: receivedCreateTrailerDocumentSuccess,
  [A.receivedCreateTrailerLocationSuccess]: receivedCreateTrailerLocationSuccess,
  [A.receivedUpdateTrailerDocumentSuccess]: receivedUpdateTrailerDocumentSuccess,
  [A.receivedUpdateTrailerLocationSuccess]: receivedUpdateTrailerLocationSuccess,
  [A.receivedDeleteTrailerDocumentSuccess]: receivedDeleteTrailerDocumentSuccess,
  [A.receivedDeleteTrailerLocationSuccess]: receivedDeleteTrailerLocationSuccess,
  [A.receivedCreateTrailerReferenceSuccess]: receivedCreateTrailerReferenceSuccess,
  [A.receivedCreateTrailerInsuranceSuccess]: receivedCreateTrailerInsuranceSuccess,
  [A.receivedUpdateTrailerOwnershipSuccess]: receivedUpdateTrailerOwnershipSuccess,
  [A.receivedUpdateTrailerReferenceSuccess]: receivedUpdateTrailerReferenceSuccess,
  [A.receivedUpdateTrailerInsuranceSuccess]: receivedUpdateTrailerInsuranceSuccess,
  [A.receivedUpdateTrailerComponentSuccess]: receivedUpdateTrailerComponentSuccess,
  [A.receivedDeleteTrailerReferenceSuccess]: receivedDeleteTrailerReferenceSuccess,
  [A.receivedDeleteTrailerInsuranceSuccess]: receivedDeleteTrailerInsuranceSuccess,
  [A.receivedDeleteTrailerComponentSuccess]: receivedDeleteTrailerComponentSuccess,
  [A.receivedGetTrailerSpecificationSuccess]: receivedGetTrailerSpecificationSuccess,
  [A.receivedTrailerLatestKnownLocationSuccess]: receivedTrailerLatestKnownLocationSuccess,
  [A.receivedUpdateTrailerSpecificationSuccess]: receivedUpdateTrailerSpecificationSuccess,
  [A.receivedCreateTrailerLatestKnownLocationSuccess]: receivedCreateTrailerLatestKnownLocationSuccess,
}, initialState);
