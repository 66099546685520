import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormButtons } from '../../../../components/form-buttons';
import { ConfirmComponent } from '../../../../components/confirm';
import { FormGroupTable } from '../../../../components/form-group-table';
import { FormGroupTitleComponent } from '../../../../components/form-group-title';
// features
import Audit2 from '../../../audit2';
import { AuthWrapper } from '../../../permission';
import References from '../../../reference/components/reference';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Form, TabFormWrapper } from '../../../../forms';
// feature fleet
import { latestKnownLocationSettings } from '../../settings/fields-settings';
import ContactsFormGroupTable from '../../components/contacts-form-group-table';
import { withAddOrEditLastLocation } from '../../hocs/add-or-edit-last-location';
import PayToLocationFormGroupTable from '../../components/pay-to-location-form-group-table';
import * as I from '../../../../svgs';
// feature fleet/driver
import { renderField } from './render-field';
import SafetyViolation from './safety-violation';
import IntegrationFormComponent from '../../components/integration-form';
import { externalIntegrationColumnSettings } from '../settings/column-settings';
import { makeSelectDriverRefList, makeSelectDriverContactList } from '../selectors';
import { DRIVER_GROUP_NAME_INTEGRATION, DRIVER_GROUP_NAME_LATES_KNOWN_LOCATIONS } from '../constants';
import { accountingFields, editDriverFields, editDriverHoursFields } from '../settings/fields-settings';
import {
  updateDriverRequest,
  updateDriverContactRequest,
  deleteDriverContactRequest,
  createDriverContactRequest,
  deleteDriverReferenceRequest,
  createDriverReferenceRequest,
  updateDriverReferenceRequest,
  deleteExternalDriverIdRequest,
  createExternalDriverIdRequest,
  updateExternalDriverIdRequest,
  toggleDriverExternalEnableRequest,
  createDriverLatestKnownLocationRequest,
} from '../actions';
//////////////////////////////////////////////////

const renderReferences = (props: Object) => (
  <References
    openModal={props.openModal}
    references={props.references}
    closeModal={props.closeModal}
    scope={GC.REF_SCOPE_NAME_FLEET_DRIVER}
    handleCreateReference={props.handleCreateReference}
    handleUpdateReference={props.handleUpdateReference}
    handleRemoveReference={props.handleDeleteReference}
  />
);

const getAdditionalColumnActions = ({ handleOpenLastKnownLocationAudit }: Object) => [{
  height: 'max-content',
  icon: I.renderAuditHistoryIcon(),
  handleClick: handleOpenLastKnownLocationAudit,
}];

const renderLatestKnownLocation = (props: Object) => (
  <FormGroupTable
    notEditable={true}
    notDeletable={true}
    fields={props.collapsedGroup}
    entities={props.latestKnownLocations}
    entitiesFields={latestKnownLocationSettings}
    additionalColumnActions={getAdditionalColumnActions(props)}
    handleEditRow={props.handleClickAddOrEditLatestKnownLocation}
    handleAddClick={props.handleClickAddOrEditLatestKnownLocation}
    isOpened={R.path([DRIVER_GROUP_NAME_LATES_KNOWN_LOCATIONS], props.collapsedGroup)}
    panelTitle={G.getWindowLocale('titles:last-known-location', 'Last Known Location')}
    handleToggleFormGroup={() => props.handleToggleFormGroup(DRIVER_GROUP_NAME_LATES_KNOWN_LOCATIONS)}
  />
);

const renderExternalIntegration = (props: Object) => {
  const {
    assignInfo,
    collapsedGroup,
    handleToggleFormGroup,
    handleDeleteIntegrationRow,
    handleOpenAddIntegrationModal,
    handleOpenEditIntegrationModal,
    toggleDriverExternalEnableRequest,
  } = props;

  const entities = R.compose(
    R.map((item: Object) => G.ifElse(
      R.includes(
        R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE, item),
        [GC.FLEET_INTEGRATION_TYPE_SAMSARA, GC.FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN],
      ),
      R.mergeRight(item, R.pick([GC.FIELD_TEAM_DRIVER, GC.FIELD_TRUCK, GC.FIELD_TRAILERS], assignInfo)),
      item,
    )),
    R.pathOr([], ['integrationList']),
  )(props);

  return (
    <FormGroupTable
      entities={entities}
      fields={collapsedGroup}
      handleDeleteRow={handleDeleteIntegrationRow}
      handleEditRow={handleOpenEditIntegrationModal}
      handleAddClick={handleOpenAddIntegrationModal}
      entitiesFields={externalIntegrationColumnSettings}
      handleToggleEnabled={toggleDriverExternalEnableRequest}
      panelTitle={G.getWindowLocale('titles:integration', 'Integration')}
      isOpened={R.path(['collapsedGroup', DRIVER_GROUP_NAME_INTEGRATION], props)}
      handleToggleFormGroup={() => handleToggleFormGroup(DRIVER_GROUP_NAME_INTEGRATION)}
    />
  );
};

const initialHoursValues = {
  dutyStatusCode: GC.DUTY_STATUS_CODE_TYPE_ON_DUTY,
};

const enhance = compose(
  withHandlers({
    handleFormSubmit: (props: Object) => (values: Object) => {
      const { updateDriverRequest, setComplexInitialValues } = props;

      let data = G.mapObjectEmptyStringFieldsToNull(values);

      setComplexInitialValues(data);

      if (G.isNilOrEmpty(R.path([GC.DUTY_STATUS_CODE], data))) {
        data = R.mergeRight(values, initialHoursValues);
      }

      updateDriverRequest(data);
    },
    handleUpdate: (props: Object) => () => {
      const {
        touch,
        errors,
        openModal,
        closeModal,
        formValues,
        initialValues,
        updateDriverRequest,
      } = props;

      if (R.isEmpty(errors)) {
        let data = G.mapObjectEmptyStringFieldsToNull(formValues);

        if (G.isNilOrEmpty(R.path([GC.DUTY_STATUS_CODE], data))) {
          data = R.mergeRight(formValues, initialHoursValues);
        }

        if (R.and(
          G.isTrue(R.prop('teamDriver', formValues)),
          G.isFalse(R.prop('teamDriver', initialValues)),
        )) {
          const text = G.getWindowLocale(
            'messages:driver-becomes-team-driver',
            'If a driver becomes a Team Driver, related equipment will be unassigned',
          );

          const component = <ConfirmComponent textLocale={text} />;

          const modal = {
            component,
            options: {
              width: 600,
              controlButtons: [
                {
                  type: 'button',
                  name: G.getWindowLocale('actions:update', 'Update'),
                  action: () => {
                    closeModal();
                    updateDriverRequest(R.assoc('updated', true, data));
                  },
                },
              ],
            },
          };

          return openModal(modal);
        }

        updateDriverRequest(R.assoc('updated', true, data));
      }

      R.forEachObjIndexed((_: string, key: string) => (touch(key)), errors);
    },
    handleOpenAddIntegrationModal: (props: Object) => () => {
      const createdIntegrations = R.compose(
        R.map(R.prop(GC.FIELD_FLEET_INTEGRATION_TYPE)),
        R.pathOr([], ['integrationList']),
      )(props);

      const component = (
        <IntegrationFormComponent
          isEditMode={false}
          openLoader={props.openLoader}
          closeModal={props.closeModal}
          closeLoader={props.closeLoader}
          objectKey={GC.FIELD_DRIVER_GUID}
          createdIntegrations={createdIntegrations}
          submitAction={props.createExternalDriverIdRequest}
          objectGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          title: G.getWindowLocale('titles:add-integration', 'Add Integration'),
        },
      };

      props.openModal(modal);
    },
    handleOpenEditIntegrationModal: (props: Object) => (entity: Object) => {
      const component = (
        <IntegrationFormComponent
          isEditMode={true}
          initialValues={entity}
          createdIntegrations={[]}
          closeModal={props.closeModal}
          closeLoader={props.closeLoader}
          objectKey={GC.FIELD_DRIVER_GUID}
          formType={GC.FIELD_FLEET_EDIT_EXTERNAL_ID}
          submitAction={props.updateExternalDriverIdRequest}
          objectGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 340,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-integration', 'Edit Integration'),
        },
      };

      props.openModal(modal);
    },
    handleActionForAddOrEditLastLocationHoc: (props: Object) => (values: Object) => (
      props.createDriverLatestKnownLocationRequest(
        R.assoc(GC.FIELD_DRIVER_GUID, R.path(['initialValues', GC.FIELD_GUID], props), values),
      )
    ),
    handleDeleteIntegrationRow: (props: Object) => (entity: Object) => {
      const { openModal, deleteExternalDriverIdRequest } = props;

      const data = {
        [GC.FIELD_GUID]: R.prop(GC.FIELD_GUID, entity),
        [GC.FIELD_DRIVER_GUID]: R.prop(GC.FIELD_DRIVER_GUID, entity),
      };

      const component = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_FLEET_EXTERNAL_ID, entity)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteExternalDriverIdRequest(data);
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleOpenLastKnownLocationAudit: (props: Object) => () => {
      const { initialValues, openModal } = props;

      const requestOptions = {
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_DRIVER_LATEST_LOCATION,
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(initialValues),
      };

      const modal = {
        p: '0',
        component: <Audit2 requestOptions={requestOptions} />,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: G.getWindowLocale('titles:audit', 'Audit'),
        },
      };

      openModal(modal);
    },
    handleCreateReference: (props: Object) => (values: Object) => {
      const reqBody = {
        selectedList: R.of(Array, R.path(['initialValues', GC.FIELD_GUID], props)),
        values,
      };
      props.createDriverReferenceRequest(reqBody);
    },
    handleUpdateReference: ({ updateDriverReferenceRequest }: Object) => (reqBody: Object) => (
      updateDriverReferenceRequest(reqBody)
    ),
    handleEmployedStatus: (props: Object) => (event: Object) => {
      props.change(GC.FIELD_DRIVER_EMPLOYED, R.path(['target', 'checked'], event));

      if (G.isFalse(R.path(['target', 'checked'], event))) {
        props.change(GC.DUTY_STATUS_CODE_TYPE_ON_DUTY, false);
      }
    },
    handleTerminationDate: (props: Object) => (value: Object) => {
      const { change, formValues } = props;

      if (G.isNilOrEmpty(value)) return change(GC.FIELD_FLEET_TERMINATION_DATE, null);

      const currentHireDateFormValue = G.getPropFromObject(GC.FIELD_FLEET_CURRENT_HIRE_DATE, formValues);
      const currentHireDate = moment(G.ifElse(G.isNotNilAndNotEmpty(currentHireDateFormValue), currentHireDateFormValue));

      const currentHireDateFormattedValue = currentHireDate.format(GC.DEFAULT_DATE_FORMAT);

      const terminationDate = moment(value);
      const terminationDateFormattedValue = terminationDate.format(GC.DEFAULT_DATE_FORMAT);

      change(GC.FIELD_FLEET_TERMINATION_DATE, terminationDateFormattedValue);

      const condition = R.or(
        G.isNilOrEmpty(currentHireDateFormattedValue),
        R.gt(terminationDate.diff(currentHireDate), 0),
      );

      if (condition) {
        change(GC.FIELD_DRIVER_EMPLOYED, false);
        change(GC.FIELD_FLEET_CURRENT_HIRE_DATE, null);
        change(GC.DUTY_STATUS_CODE_TYPE_ON_DUTY, false);
        change(GC.FIELD_FLEET_PREVIOUS_HIRE_DATE, currentHireDateFormattedValue);
      }
    },
    handleHireDate: (props: Object) => (value: Object) => {
      const { change } = props;

      if (G.isNilOrEmpty(value)) return props.change(GC.FIELD_FLEET_CURRENT_HIRE_DATE, null);

      const currentHireDate = moment(value);

      const terminationDate = G.ifElse(
        G.isNotNilAndNotEmpty(R.path(['formValues', GC.FIELD_FLEET_TERMINATION_DATE], props)),
        moment(R.path(['formValues', GC.FIELD_FLEET_TERMINATION_DATE], props)),
        moment(),
      );

      const condition = R.or(
        G.isNilOrEmpty(R.path(['formValues', GC.FIELD_FLEET_TERMINATION_DATE], props)),
        R.gt(currentHireDate.diff(terminationDate), 0),
      );

      change(GC.FIELD_FLEET_CURRENT_HIRE_DATE, currentHireDate.format(GC.DEFAULT_DATE_FORMAT));

      if (condition) {
        change(GC.FIELD_DRIVER_EMPLOYED, true);
        change(GC.FIELD_FLEET_TERMINATION_DATE, null);
      }
    },
    handleDeleteReference: (props: Object) => (guid: Object) => {
      const component = (
        <ConfirmComponent
          name={R.path([GC.FIELD_REFERENCES, guid, GC.FIELD_NAME], props)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.closeModal();
                props.deleteDriverReferenceRequest(guid);
              },
            },
          ],
        },
      };

      props.openModal(modal);
    },
  }),
  withAddOrEditLastLocation,
  pure,
);

const renderButtons = ({ handleUpdate }: Object) => ([
  {
    width: 'auto',
    type: 'button',
    action: handleUpdate,
    btnText: G.getWindowLocale('actions:update', 'Update'),
  },
]);

const EditDriverDetailsComponent = (props: Object) => (
  <TabFormWrapper>
    <Form minHeight='max-content' onSubmit={props.handleSubmit(props.handleFormSubmit)}>
      {
        [editDriverFields, accountingFields, editDriverHoursFields].map((group: Object, index: number) => (
          <AuthWrapper key={index} display='block' has={group.permissions}>
            <FormGroupTitleComponent
              withArrowDown={true}
              text={G.getWindowLocale(group.title)}
              isOpened={R.path(['collapsedGroup', group.name], props)}
              onToggleFormGroup={() => props.handleToggleFormGroup(group.name)}
            />
            {
              group.fields.map((field: Object, index: number) => {
                if (G.showFormField(field.showField, props.formValues)) return renderField(props, field, index);

                return null;
              })
            }
          </AuthWrapper>
        ))
      }
      <FormButtons
        {...props}
        zIndex={20}
        width='auto'
        buttons={renderButtons(props)}
      />
    </Form>
    {
      G.isNotNilAndNotEmpty(R.path(['initialValues', GC.FIELD_GUID], props)) &&
      <SafetyViolation
        handleToggleFormGroup={props.handleToggleFormGroup}
        driverGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
        isOpened={R.path(['collapsedGroup', 'safetyViolation'], props)}
        optionsForSelect={R.pick(
          [
            'documentType',
            'safetyViolationType',
            'safetyViolationSeverity',
            'safetyViolationDisciplinaryAction',
          ],
          R.pathOr({}, ['driverConfigs'], props),
        )}
      />
    }
    {renderExternalIntegration(props)}
    {renderLatestKnownLocation(props)}
    <PayToLocationFormGroupTable
      openModal={props.openModal}
      closeModal={props.closeModal}
      formValues={props.formValues}
      collapsedGroup={props.collapsedGroup}
      updateDriverRequest={props.updateDriverRequest}
      handleToggleFormGroup={props.handleToggleFormGroup}
      payToLocation={R.path(['formValues', 'payToLocation'], props)}
      removePayToLocation={() => {
        props.closeModal();
        const newValues = R.mergeRight(props.formValues, { updated: true, payToLocation: null });
        props.updateDriverRequest(newValues);
      }}
      addOrEditPayToLocation={(values: Object) => {
        props.closeModal();
        const newValues = R.mergeRight(props.formValues, { updated: true, payToLocation: values });
        props.updateDriverRequest(newValues);
      }}
    />
    <ContactsFormGroupTable
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      createAction={props.createDriverContactRequest}
      updateAction={props.updateDriverContactRequest}
      removeAction={props.deleteDriverContactRequest}
      entities={R.pathOr([], [GC.FIELD_CONTACTS], props)}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
    {renderReferences(props)}
  </TabFormWrapper>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  references: makeSelectDriverRefList(state),
  contacts: makeSelectDriverContactList(state),
}));

export default connect(mapStateToProps, {
  updateDriverRequest,
  updateDriverContactRequest,
  deleteDriverContactRequest,
  createDriverContactRequest,
  deleteDriverReferenceRequest,
  createDriverReferenceRequest,
  updateDriverReferenceRequest,
  updateExternalDriverIdRequest,
  deleteExternalDriverIdRequest,
  createExternalDriverIdRequest,
  toggleDriverExternalEnableRequest,
  createDriverLatestKnownLocationRequest,
})(enhance(EditDriverDetailsComponent));
