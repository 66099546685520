import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Tabs } from '../../../components/tabs';
import { TabsMui } from '../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-list
import { setFleetDriverActiveTab } from '../actions';
//////////////////////////////////////////////////

const tabs = [
  {
    value: GC.FLEET_DRIVER_REPORT,
    text: G.getWindowLocale('titles:fleet-list-of-drivers', 'Fleet: List of Drivers'),
  },
  {
    value: GC.FLEET_DRIVER_ONBOARDING_REPORT,
    text: G.getWindowLocale('titles:driver-onboarding', 'Driver Onboarding'),
  },
];

const DriverTabs = ({ totalCount, reportType, handleClickTab }: Object) => {
  const activeTabIndex = R.findIndex(R.propEq(reportType, 'value'), tabs);
  const correctTabs = R.assocPath([activeTabIndex, 'withCount'], true, tabs);

  return (
    <TabsMui
      count={totalCount}
      tabs={correctTabs}
      setActiveMuiTab={handleClickTab}
      activeMuiTab={R.path([activeTabIndex, 'value'], tabs)}
    />
  );
};

const enhance = compose(
  connect(null, { setFleetDriverActiveTab }),
  withHandlers({
    handleClickTab: (props: Object) => (value: number) => {
      if (R.propEq(value, 'reportType', props)) return;

      props.setFleetDriverActiveTab(value);
    },
  }),
  branch(
    ({ reportType }: Object) => G.isNilOrEmpty(reportType),
    renderNothing,
  ),
  pure,
);

export default enhance(DriverTabs);
