import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
// helpers/constants
import * as G from '../../../helpers';
// feature carrier-edi
import { statusMessageTableSettings } from '../settings/table-settings';
import { statusMessageColumnSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({
    name,
    sequence,
  }), R.keys(statusMessageColumnSettings)),
};

const StatusMessages = (props: Object) => (
  <Table
    report={report}
    hasSelectable={false}
    itemList={props.itemList}
    tableSettings={statusMessageTableSettings}
    columnSettings={statusMessageColumnSettings}
  />
);

export default StatusMessages;
