import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
// feature audit2
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  type: null,
  auditList: [],
  totalCount: 0,
  loading: false,
  objectGuid: null,
  pagination: {
    offset: 0,
    limit: 20,
  },
};

const setInitialState = () => initialState;

const toggleAuditDetails = (state: Object, data: Object) => P.$toggle(`auditList.${data.index}.expanded`, state);

const receivedAuditListSuccess = (state: Object, data: Object) => {
  const { auditList, pagination } = state;

  const { type, results, totalCount, objectGuid } = data;

  const indexAdditional = R.length(R.values(auditList));

  const newOffset = R.sum(R.values(pagination));

  const list = R.compose(
    R.mergeRight(auditList),
    R.indexBy(R.prop('index')),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        expanded: false,
        index: R.add(index, indexAdditional),
        notExpandable: G.isNilOrEmpty(item.changedFields),
      },
    )),
  )(results);

  return P.$all(
    P.$set('type', type),
    P.$set('auditList', list),
    P.$set('objectGuid', objectGuid),
    P.$set('totalCount', totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const setAuditLoading = (state: Object, data: boolean = false) => P.$set('loading', data, state);

export default createReducer({
  [A.setAuditLoading]: setAuditLoading,
  [A.setInitialState]: setInitialState,
  [A.toggleAuditDetails]: toggleAuditDetails,
  [A.receivedAuditListSuccess]: receivedAuditListSuccess,
}, initialState);
