import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

// TODO: with functional component and common logic

class LoadChatMessages extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncNotes: null };
    this.getLoadNotesRequest = this.getLoadNotesRequest.bind(this);
  }

  componentDidMount() {
    this.getLoadNotesRequest(this.props);
  }

  async getLoadNotesRequest(props: Object) {
    const { loadGuid, loadType } = props;
    const params = { loadGuid };
    const endpoint = G.ifElse(
      G.isLoadTypeClo(loadType),
      endpointsMap.cloNoteList,
      endpointsMap.telNoteList,
    );
    const res = await sendRequest('get', endpoint, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const newState = P.$set('asyncNotes', R.or(data, []), this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncLoadNotes -> getLoadNotesRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getLoadNotesRequest)}</div>;
  }
}

export const withAsyncLoadNotes = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <LoadChatMessages
          loadGuid={this.props.loadGuid}
          loadType={this.props.loadType}
          render={(parentState: Object, getLoadNotesRequest: Function) =>
            <Component
              {...this.props}
              asyncNotes={parentState.asyncNotes}
              getLoadNotesRequest={getLoadNotesRequest} />
          } />
      );
    }
  };
};
