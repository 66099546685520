import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import { TableCell } from '../../components/table-cells';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_SOURCE]: {
    width: 120,
    name: 'titles:source',
  },
  [GC.FIELD_LOG_DATE]: {
    width: 220,
    type: 'date',
    name: 'titles:date',
  },
  [GC.FIELD_TOTAL_DISTANCE]: {
    width: 120,
    name: 'titles:total-distance',
    customComponent: (row: Object) => {
      const { totalDistance, totalDistanceUom } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(totalDistance)) return null;

      return <TableCell text={`${totalDistance} ${G.getUomLocale(totalDistanceUom)}`} />;
    },
  },
  [GC.FIELD_ODOMETER]: {
    width: 120,
    name: 'titles:odometer',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 220,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 220,
    type: 'date',
    name: 'titles:update-on',
  },
};

const columnSettings = R.map(R.pick(['type', 'name', 'width', 'sortable', 'searchable', 'customComponent']), settings);

const placeholder = { text: 'Choose', key: 'titles:choose' };

const filterProps = R.mapObjIndexed((
  { name, options, collection, filterType, disableFilter, type = 'string' }: Object,
  value: string,
) => ({
  value,
  options,
  collection,
  placeholder,
  disableFilter,
  type: R.or(filterType, type),
  [GC.FIELD_NAME]: G.getWindowLocale(name),
}), settings);

const reportFields = G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings));

const makeRequestPayload = ({ primaryObjectBranchGuid }: Object) => ({
  fields: reportFields,
  [GC.CURRENT_BRANCH]: primaryObjectBranchGuid,
  systemFields: [{ sequence: 1, name: GC.FIELD_TOTAL_DISTANCE_UOM }],
});

const FILTER_PARAMS = R.values(filterProps);

const dailyLogSettings = {
  filterProps,
  columnSettings,
  isReport: true,
  actionsPicker: [],
  noExportable: false,
  groupName: 'dailyLog',
  useSearchableColumns: true,
  withResizableColumns: true,
  useNewTitleFilterInputs: true,
  reportType: GC.FLEET_TRUCK_DAILY_LOG_REPORT,
  formGroupTitleArr: ['titles:daily-log', 'Daily Log'],
  endpoints: {
    list: 'truckDailyLogList',
    export: 'truckDailyLogListExport',
  },
  additionalTableSettings: {
    searchableTitles: true,
    tableWrapperProps: {
      minHeight: 400,
      maxHeight: 'calc(100vh - 700px)',
    },
  },
  // helpers
  makeRequestPayload,
};

export { FILTER_PARAMS, dailyLogSettings };
