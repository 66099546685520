import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const fieldNotRequired = Yup.string().nullable(true);
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const defaultStyles = {
  width: '270px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

export const fieldSettings = (integrationType: string) => [
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:integration-type',
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    customDisabledFunction: 'handleDisableIntegrationType',
    options: [
      {
        label: 'Skybitz',
        value: GC.TRAILER_TRACKING_INTEGRATION_TYPE_SKYBITZ,
      },
      {
        label: 'Power Fleet',
        value: GC.FLEET_INTEGRATION_TYPE_POWER_FLEET,
      },
      {
        label: 'Spireon',
        value: GC.FLEET_INTEGRATION_SPIREON,
      },
    ],
  },
  {
    ...defaultStyles,
    type: 'toggle',
    loc: 'titles:active',
    fieldName: GC.FIELD_ENABLED,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:client-id',
    fieldName: GC.FIELD_CLIENT_ID,
    fieldGroupDisplay: G.ifElse(
      R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_POWER_FLEET),
      'none',
    ),
  },
  {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_CLIENT_SECRET,
    loc: G.ifElse(
      R.equals(integrationType, GC.FLEET_INTEGRATION_TYPE_POWER_FLEET),
      'titles:token',
      'titles:client-secret',
    ),
  },
];

const defaultFields = {
  [GC.FIELD_ENABLED]: true,
  [GC.FIELD_CLIENT_ID]: null,
  [GC.FIELD_CLIENT_SECRET]: null,
  [GC.FIELD_INTEGRATION_TYPE]: GC.TRAILER_TRACKING_INTEGRATION_TYPE_SKYBITZ,
};

const validationSchema = ({ editMode }: Object) => Yup.lazy(({ integrationType }: string) => Yup.object().shape({
  [GC.FIELD_CLIENT_ID]: G.ifElse(
    G.notEquals(integrationType, GC.FLEET_INTEGRATION_TYPE_POWER_FLEET),
    fieldRequired,
  ),
  [GC.FIELD_CLIENT_SECRET]: G.ifElse(
    G.isTrue(editMode),
    fieldNotRequired,
    fieldRequired,
  ),
}));

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const TrailerTrackingIntegrationForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      optionsForSelect={props.optionsForSelect}
      fields={fieldSettings(R.path(['values', GC.FIELD_INTEGRATION_TYPE], props))}
      handlers={{
        handleDisableIntegrationType: () => props.editMode,
      }}
    />
    <FormFooter boxStyles={{ mt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(TrailerTrackingIntegrationForm);
