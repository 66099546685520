import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
// feature fleet-profile
import { Tab, TabsGroupWrapper, tabsGroupWrapperEnhance } from '../../components/tabs-group-wrapper';
import {
  withGeneralSettings,
  withPayrollSettings,
  withDocumentsSettings,
  withRatingEngineSettings,
} from '../hocs/with-tab-group-settings';
/////////////////////////////////////////////

const GeneralTab = withGeneralSettings(Tab);
const PayrollTab = withPayrollSettings(Tab);
const Documents = withDocumentsSettings(Tab);
const RatingEngineTab = withRatingEngineSettings(Tab);

const ActiveTab = (props: Object) => {
  const { activeTab } = props;

  const tabsMap = [
    <GeneralTab key={1} {...props} />,
    <Documents key={2} {...props} />,
    <RatingEngineTab key={3} {...props} />,
    <PayrollTab key={4} {...props} />,
  ];

  return R.pathOr(null, [activeTab], tabsMap);
};

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: G.getWindowLocale('titles:documents', 'Documents') },
  { text: G.getWindowLocale('titles:rating-engine', 'Rating Engine') },
  { text: G.getWindowLocale('titles:payroll', 'Payroll') },
];

const VendorTabsGroups = (props: Object) => (
  <TabsGroupWrapper
    tabs={tabs}
    activeTab={props.activeTab}
    setTabsView={props.setTabsView}
    tabsCollapsedView={props.tabsCollapsedView}
    handleSetActiveTab={props.handleSetActiveTab}
  >
    <ActiveTab {...props} />
  </TabsGroupWrapper>
);

export default tabsGroupWrapperEnhance(VendorTabsGroups);
