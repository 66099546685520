import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withHandlers,
  renderNothing,
  withPropsOnChange,
} from 'react-recompose';
// components
import { PageTabs } from '../../../../components/page-tabs';
import { PageTitle } from '../../../../components/page-title';
import { ConfirmComponent } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { makeSelectInitialDataLoadedStatus } from '../../../permission/selectors';
import { makeSelectExpandedContainerOpened } from '../../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { FixedFlex, CancelButton, PageHeaderWrapper } from '../../../../ui';
// feature fleet-list
import { visitFleetListOnFleetProfilePage } from '../../../fleet-list/actions';
// feature fleet
import Audit2 from '../../../audit2';
import * as C from '../../constants';
import { SimpleWrapper } from '../../ui';
import AssignInfoComponent from '../../components/assign-info';
// feature fleet/driver
import { makeSelectAssignInfo } from '../../driver/selectors';
import LatestKnownLocationHeaderTitle from '../../components/latest-known-location-header-title';
// feature fleet/trailer
import { EditTrailerWrapper } from '../ui';
import { EDIT_TRAILER_FORM } from '../constants';
import EditTrailerDetails from './trailer-details-tab';
import EditTrailerEquipment from './trailer-equip-tab';
import EditTrailerSpecification from './trailer-spec-tab';
import { withComplexInitialValues } from '../../hocs/complex-initial-values';
import {
  setActiveTab,
  setInitialState,
  updateTrailerRequest,
  toggleTrailerFormGroup,
  getTrailerLocationRequest,
  getTrailerComponentRequest,
  validateBeforeChangeTrailerTab,
  getTrailerSpecificationRequest,
  changeAssignedToDivisionRequest,
} from '../actions';
import {
  makeSelectTrailerConfigs,
  makeSelectTrailerDetails,
  makeSelectCollapsedGroup,
  makeSelectTrailerActiveTab,
  makeSelectTrailerFormValues,
  makeSelectExternalIntegrationList,
  makeSelectLatestKnownLocationList,
} from '../selectors';
//////////////////////////////////////////////////

const renderTitle = ({ initialValues }: Object) => {
  const unitId = R.pathOr('', [GC.FIELD_UNIT_ID], initialValues);

  const prefix = G.getWindowLocale('titles:fleet-trailer', 'Fleet: Trailer');

  if (G.isNilOrEmpty(unitId)) return `${prefix}`;

  return `${prefix} ${unitId}`;
};

const renderTab = (props: Object) => {
  const trailerGuid = R.path(['initialValues', GC.FIELD_GUID], props);

  const auditProps = {
    useFullScreen: true,
    requestOptions: {
      [GC.FIELD_OBJECT_GUID]: trailerGuid,
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_TRAILER,
    },
  };

  const tabs = {
    audit: () => <Audit2 {...auditProps} />,
    [C.FLEET_TAB_DETAILS]: () => (
      <EditTrailerDetails
        {...props}
        trailerGuid={trailerGuid}
        primaryObjectGuid={trailerGuid}
        branchGuid={R.path(['complexInitialValues', GC.FIELD_BRANCH_GUID], props)}
      />
    ),
    [C.FLEET_TAB_DOCUMENTS]: () => <EditTrailerSpecification {...props} />,
    [C.FLEET_TAB_SERVICE]: () =>
      <EditTrailerEquipment {...props} branchGuid={R.path(['initialValues', GC.FIELD_BRANCH_GUID], props)} />,
  };

  return tabs[props.stateActiveTab]();
};

const enhance = compose(
  reduxForm({
    form: EDIT_TRAILER_FORM,
    enableReinitialize: true,
  }),
  withState('location', 'setLocation', {}),
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withPropsOnChange(
    ['complexInitialValues'],
    ({ setInitialValues, complexInitialValues }: Object) => setInitialValues(complexInitialValues),
  ),
  withState('fieldsErrors', 'setFieldError', {}),
  withHandlers({
    handleToggleFormGroup: ({ toggleTrailerFormGroup }: Object) => (fieldName: string) =>
      toggleTrailerFormGroup(fieldName),
    handleSetFieldError: (props: Object) => (errorState: Object) => {
      const errorsState = R.clone(props.fieldsErrors);
      if (R.has(errorState.groupName, errorsState)) {
        if (R.has(errorState.name, errorsState[errorState.groupName])) return;
        errorsState[errorState.groupName][errorState.name] = errorState.error;
      } else {
        errorsState[errorState.groupName] = { [errorState.name]: errorState.error };
      }
      props.setFieldError(errorsState);
    },
    handleClearFieldError: (props: Object) => (groupName: string, name: string) => {
      const errorsState = R.clone(props.fieldsErrors);
      if (R.has(groupName, errorsState)) {
        if (R.not(R.has(name, errorsState[groupName]))) return;
        errorsState[groupName] = R.dissoc(name, errorsState[groupName]);
      }
      props.setFieldError(errorsState);
    },
    handleTabNav: (props: Object) => () => {
      const trailerGuid = R.path(['initialValues', GC.FIELD_GUID], props);
      const detailTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRAILER,
        formName: EDIT_TRAILER_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => props.handleSetActiveTab(tabName),
      });
      const docsTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRAILER,
        formName: EDIT_TRAILER_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.getTrailerSpecificationRequest(trailerGuid);
        },
      });
      const serviceTab = (tabName: string) => ({
        touch: props.touch,
        fleet: C.FLEET_TRAILER,
        formName: EDIT_TRAILER_FORM,
        tabName: props.stateActiveTab,
        tabAction: () => {
          props.handleSetActiveTab(tabName);
          props.getTrailerLocationRequest(trailerGuid);
          props.getTrailerComponentRequest(trailerGuid);
          props.visitFleetListOnFleetProfilePage({
            fleetProfileGuid: trailerGuid,
            fleetProfileType: GC.FIELD_TRAILER,
            fleetProfileGuidType: GC.FIELD_TRAILER_GUID,
            reportType: GC.FLEET_EQUIPMENT_SERVICE_REPORT,
          });
        },
      });
      const iconColor = 'icons.iconColor';
      const iconColorGrey = 'icons.iconColorGrey';
      return [
        {
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_DETAILS),
            I.searchDocument(G.getTheme(iconColor)),
            I.searchDocument(G.getTheme(iconColorGrey)),
          ),
          tabName: C.FLEET_TAB_DETAILS,
          label: G.getWindowLocale('titles:general-details', 'General Details'),
          action: (tabName: string) => (
            props.validateBeforeChangeTrailerTab(detailTab(tabName))
          ),
        },
        {
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_DOCUMENTS),
            I.lesnik(G.getTheme(iconColor)),
            I.lesnik(G.getTheme(iconColorGrey)),
          ),
          tabName: C.FLEET_TAB_DOCUMENTS,
          label: G.getWindowLocale('titles:specifications', 'Specifications'),
          action: (tabName: string) => props.validateBeforeChangeTrailerTab(docsTab(tabName)),

        },
        {
          frontIcon: G.ifElse(
            R.equals(props.stateActiveTab, C.FLEET_TAB_SERVICE),
            I.gearSolid(G.getTheme(iconColor)),
            I.gearSolid(G.getTheme(iconColorGrey)),
          ),
          tabName: C.FLEET_TAB_SERVICE,
          label: G.getWindowLocale('titles:maintenance', 'Maintenance'),
          action: (tabName: string) => props.validateBeforeChangeTrailerTab(serviceTab(tabName)),
        },
      ];
    },
    handleClickCancel: (props: Object) => () => {
      const { openModal, closeModal, expandedContainerOpened, closeExpandedContainer } = props;

      if (expandedContainerOpened) return closeExpandedContainer();

      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirmation-leave-page',
            'All changes will be lost. Are you sure you want leave page?')
          }
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:leave', 'Leave'),
              action: () => {
                closeModal();

                G.historyGoBack();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleCancel: ({ expandedContainerOpened, closeExpandedContainer }: Object) => () => {
      if (expandedContainerOpened) return closeExpandedContainer();

      G.historyGoBack();
    },
  }),
  lifecycle({
    componentWillUnmount() {
      const { setInitialState } = this.props;

      setInitialState();
    },
  }),
  branch(
    (props: Object) => (
      R.and(
        R.not(props.initialDataLoaded),
        G.isNilOrEmpty(R.path(['initialValues', GC.FIELD_GUID], props)),
      )
    ),
    renderNothing,
  ),
  pure,
);

const EditFormComponent = enhance((props: Object) => {
  const {
    openModal,
    closeModal,
    handleTabNav,
    handleCancel,
    initialValues,
    stateActiveTab,
    handleSetActiveTab,
    latestKnownLocations,
    expandedContainerOpened,
    changeAssignedToDivisionRequest,
  } = props;

  return (
    <EditTrailerWrapper
      mb={60}
      background={G.getTheme('colors.white')}
      pl={G.ifElse(expandedContainerOpened, 25)}
      pb={G.ifElse(expandedContainerOpened, 60)}
      height={G.ifElse(expandedContainerOpened, '100vh')}
      overflowY={G.ifElse(expandedContainerOpened, 'auto')}
    >
      <PageHeaderWrapper
        zI='13'
        aI='flex-end'
        position='relative'
        height='max-content'
        justify='space-between'
      >
        <SimpleWrapper>
          <PageTabs
            zI={20}
            height={150}
            withAudit={true}
            isLabelExist={true}
            tabs={handleTabNav()}
            activeTab={stateActiveTab}
            setActiveTab={handleSetActiveTab}
          />
          <PageTitle
            showStatus={true}
            title={renderTitle(props)}
            status={R.path(['formValues', GC.FIELD_AVAILABLE], props)}
          />
          <AssignInfoComponent
            trailer={true}
            openModal={openModal}
            type={C.FLEET_TRAILER}
            closeModal={closeModal}
            initialValues={initialValues}
            assignInfo={R.prop(C.FLEET_ASSIGN_INFO, props)}
            expandedContainerOpened={expandedContainerOpened}
            objGuid={R.path(['initialValues', GC.FIELD_GUID], props)}
            version={R.path(['initialValues', GC.FIELD_VERSION], props)}
            branchGuid={R.path(['initialValues', GC.BRANCH_GUID], props)}
            changeAssignedToDivisionRequest={changeAssignedToDivisionRequest}
            assignedToDivision={R.path(['formValues', 'assignedToDivision'], props)}
          />
        </SimpleWrapper>
        {
          G.isNotNilAndNotEmpty(latestKnownLocations)
          && <LatestKnownLocationHeaderTitle latestKnownLocations={latestKnownLocations} />
        }
      </PageHeaderWrapper>
      {renderTab(props)}
      {
        G.notContain(
          stateActiveTab,
          [
            C.FLEET_TAB_DETAILS,
            C.FLEET_TAB_DOCUMENTS,
          ],
        ) &&
        <FixedFlex
          pl={70}
          left='0px'
          height={60}
          bottom='0px'
          width='100%'
          zIndex={12}
          borderTop='1px solid'
          bg={G.getTheme('forms.actionsFooter.bgColor')}
          borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
        >
          <CancelButton width={100} onClick={() => handleCancel()}>
            {G.getWindowLocale('actions:cancel', 'Cancel')}
          </CancelButton>
        </FixedFlex>
      }
    </EditTrailerWrapper>
  );
});

const EditFormWithComplexInitialValues = withComplexInitialValues((props: Object) => (
  <EditFormComponent {...props} initialValues={props.complexInitialValues} />
));

const mapStateToProps = (state: Object) => (createStructuredSelector({
  assignInfo: makeSelectAssignInfo(state),
  activeTab: makeSelectTrailerActiveTab(state),
  initialValues: makeSelectTrailerDetails(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  trailerConfigs: makeSelectTrailerConfigs(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  externalIntegration: makeSelectExternalIntegrationList(state),
  latestKnownLocations: makeSelectLatestKnownLocationList(state),
  expandedContainerOpened: makeSelectExpandedContainerOpened(state),
  formValues: makeSelectTrailerFormValues(state, EDIT_TRAILER_FORM),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  setActiveTab,
  setInitialState,
  updateTrailerRequest,
  toggleTrailerFormGroup,
  getTrailerLocationRequest,
  getTrailerComponentRequest,
  validateBeforeChangeTrailerTab,
  getTrailerSpecificationRequest,
  changeAssignedToDivisionRequest,
  visitFleetListOnFleetProfilePage,
})(EditFormWithComplexInitialValues);
