import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../components/table';
// features
import { orderColumnSettings as columnSettings } from '../../dispatch-report/settings/column-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { RelativeBox } from '../../../ui';
// feature dispatch-planner
import { defaultTelClosReport } from '../settings/default-report';
//////////////////////////////////////////////////

const rowHeight = 30;

const telClosTableSettings = {
  coloredHeader: true,
  useMainColors: false,
  allowSelectItems: false,
  checkBoxCellWidth: '0px',
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

const mapData = (orders: Array = []) => R.map(
  (order: Object) => ({
    [GC.FIELD_LOAD_STATUS]: R.prop(GC.FIELD_LOAD_STATUS, order),
    [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: R.prop(GC.FIELD_BRANCH_NAME, order),
    [GC.GRC.PRIMARY_REFERENCE_VALUE]: R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_REFERENCE, GC.FIELD_VALUE], order),
  }),
  orders,
);

const renderContent = ({ data }: Object) => {
  if (G.isNilOrEmpty(data)) return null;
  const tableData = {
    columnSettings,
    totalCount: data.length,
    report: defaultTelClosReport,
    itemList: mapData(data.clos),
    tableSettings: telClosTableSettings,
    pagination: {
      limit: data.length,
      offset: data.length,
    },
  };
  return (
    <Table {...tableData} />
  );
};

const LoadDetails = (props: Object) => (
  <RelativeBox
    zIndex='0'
    pl={props.margin}
    width='fit-content'
    boxShadow='inset -1px -1px 0px 1px rgba(221, 221, 221, 0.5)'
  >
    {renderContent(props)}
  </RelativeBox>
);

export default LoadDetails;
