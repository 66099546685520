import { createAction } from 'redux-act';
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

export const getFakePayrollData = createAction('getFakePayrollData');

const {
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
} = getReportActions();

export {
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
};
