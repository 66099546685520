import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { uomLocaleMap } from '../../../constants';
import { enumLocaleMap } from '../../../constants/enums';
import {
  TIME_ZONE_OPTIONS,
  getDateTimeFormatOptions,
  getTransportationModeTypeOptions,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const getDisplayedValueFromConfigDropdowns = (field: Object, entity: Object, props: Object) => {
  const { dropdownName, nameForAttribute } = field;

  const value = G.getPropFromObject(nameForAttribute, entity);
  const dropdownOptions = R.pathOr([], ['dropdowns', dropdownName, 'options'], props);

  if (G.isNilOrEmpty(value)) return;

  if (G.isArray(value)) {
    return R.compose(
      R.join(', '),
      R.map((item: string) => R.compose(
        R.pathOr([], [GC.FIELD_DISPLAYED_VALUE]),
        R.find(({ guid, parentGuid }: Object) => R.includes(item, [guid, parentGuid])),
      )(dropdownOptions)),
    )(value);
  }

  const option = R.find(({ guid, parentGuid }: Object) => R.includes(value, [guid, parentGuid]), dropdownOptions);

  return R.prop(GC.FIELD_DISPLAYED_VALUE, option);
};

const branchSection = [
  {
    title: 'titles:branch',
    fields: {
      [GC.GENERAL_BRANCH_DEFAULT_CURRENCY]: {
        type: 'select',
        name: 'titles:default-currency',
        options: R.tail(GC.CURRENCY_OPTIONS),
        nameForAttribute: GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
      },
      [GC.GENERAL_BRANCH_TIMEZONE]: {
        type: 'select',
        name: 'titles:time-zone',
        options: TIME_ZONE_OPTIONS,
        nameForAttribute: GC.GENERAL_BRANCH_TIMEZONE,
      },
      [GC.GENERAL_BRANCH_DATE_TIME_FORMAT]: {
        type: 'select',
        name: 'titles:date-time-format',
        options: getDateTimeFormatOptions(),
        nameForAttribute: GC.GENERAL_BRANCH_DATE_TIME_FORMAT,
      },
      [GC.GENERAL_BRANCH_ACCOUNT_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-account-number',
        nameForAttribute: GC.GENERAL_BRANCH_ACCOUNT_NUMBER_AUTOGENERATED,
      },
      [GC.GENERAL_BRANCH_ACCOUNT_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'branchSequences',
        name: 'titles:account-number-sequence',
        nameForAttribute: GC.GENERAL_BRANCH_ACCOUNT_NUMBER_SEQUENCE,
      },
      [GC.GENERAL_BRANCH_USE_COMMISSION_ASSIGNMENT]: {
        type: 'switcher',
        name: 'titles:use-commission-assignment',
        nameForAttribute: GC.GENERAL_BRANCH_USE_COMMISSION_ASSIGNMENT,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:branch-subtype',
    configName: GC.GENERAL_BRANCH_SUBTYPE,
  },
];

const routeSection = [
  {
    title: 'titles:route-name',
    fields: {
      [GC.GENERAL_ROUTE_NAME_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-route-name',
        nameForAttribute: GC.GENERAL_ROUTE_NAME_AUTOGENERATED,
      },
      [GC.GENERAL_ROUTE_NAME_PREFIX]: {
        type: 'input',
        name: 'titles:route-name-prefix',
        nameForAttribute: GC.GENERAL_ROUTE_NAME_PREFIX,
      },
      [GC.GENERAL_ROUTE_NAME_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:route-name-sequence',
        nameForAttribute: GC.GENERAL_ROUTE_NAME_SEQUENCE,
      },
    },
  },
  {
    title: 'titles:draft-route',
    fields: {
      [GC.GENERAL_DRAFT_ROUTE_NAME_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-draft-route-name',
        nameForAttribute: GC.GENERAL_DRAFT_ROUTE_NAME_AUTOGENERATED,
      },
      [GC.GENERAL_DRAFT_ROUTE_NAME_PREFIX]: {
        type: 'input',
        name: 'titles:draft-route-name-prefix',
        nameForAttribute: GC.GENERAL_DRAFT_ROUTE_NAME_PREFIX,
      },
      [GC.GENERAL_DRAFT_ROUTE_NAME_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:draft-route-name-sequence',
        nameForAttribute: GC.GENERAL_DRAFT_ROUTE_NAME_SEQUENCE,
      },
    },
  },
];

const loadCustomStatusSection = [
  {
    configType: 'custom',
    title: 'titles:load-custom-status',
    configName: GC.CUSTOM_LOAD_CUSTOM_STATUS_CONFIG,
    customConfigName: GC.CUSTOM_LOAD_CUSTOM_STATUS_CONFIG,
    fields: [
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_NAME,
      },
      {
        name: 'titles:applicable-for',
        nameForAttribute: GC.FIELD_APPLICABLE_FOR,
        customLogic: (_: Object, { applicableFor }: Object) =>
          R.join(', ', R.map(G.getEnumLocale, R.or(applicableFor, []))),
      },
      {
        name: 'titles:warning-level',
        nameForAttribute: GC.FIELD_WARNING_LEVEL,
        customLogic: (field: Object, { warningLevel }: Object) => G.getEnumLocale(warningLevel),
      },
    ],
  },
];

const servicesSection = [{
  fields: [],
  configType: 'dropdown',
  title: 'titles:services',
  addPopupTitle: 'titles:add',
  editPopupTitle: 'titles:edit',
  configName: GC.GENERAL_SERVICES,
}];

const accessorialsSection = [{
  configType: 'custom',
  addPopupTitle: 'titles:add',
  title: 'titles:accessorials',
  editPopupTitle: 'titles:edit',
  configName: GC.CUSTOM_ACCESSORIALS_CONFIG,
  customConfigName: GC.CUSTOM_ACCESSORIALS_CONFIG,
  fields: [
    {
      name: 'titles:stored-value',
      nameForAttribute: GC.FIELD_CHARGE_RATE_NAME_STORED,
    },
    {
      name: 'titles:default-value',
      nameForAttribute: GC.FIELD_CHARGE_RATE_NAME,
    },
    {
      name: 'titles:rate-type',
      nameForAttribute: GC.FIELD_CHARGE_RATE_TYPE,
      customLogic: (field: Object, { rateType }: Object) => G.getEnumLocale(rateType),
    },
    {
      name: 'titles:rate-unit',
      nameForAttribute: GC.FIELD_CHARGE_RATE_UNIT,
      customLogic: (field: Object, { rateUnit }: Object) => {
        if (G.isNilOrEmpty(rateUnit)) return '';

        const rateUnitLocale = R.pathOr(
          G.getPropFromObject(rateUnit, enumLocaleMap),
          [rateUnit, 'plural'],
          uomLocaleMap,
        );

        return G.getWindowLocale(rateUnitLocale, rateUnit);
      },
    },
    {
      name: 'titles:service',
      nameForAttribute: GC.FIELD_ACCESSORIAL_SERVICE,
    },
    {
      name: 'titles:fuel-related',
      nameForAttribute: GC.FIELD_ACCESSORIAL_FUEL_RELATED,
    },
    {
      name: 'titles:non-taxable',
      nameForAttribute: GC.FIELD_CHARGE_NON_TAXABLE,
    },
    {
      name: 'titles:internal-expense',
      nameForAttribute: GC.FIELD_CHARGE_INTERNAL_EXPENSE,
    },
    {
      name: 'titles:advance-payment',
      nameForAttribute: GC.FIELD_ADVANCE_PAYMENT,
    },
  ],
}];

const equipmentsSection = [{
  fields: [],
  configType: 'dropdown',
  title: 'titles:equipments',
  addPopupTitle: 'titles:add',
  editPopupTitle: 'titles:edit',
  configName: GC.GENERAL_EQUIPMENTS,
}];

const transportationModeSection = [
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:mode-of-transportation',
    configName: GC.GENERAL_MODE_TRANSPORTATION,
  },
  {
    configType: 'custom',
    title: 'titles:transportation-mode-grouping',
    configName: GC.CUSTOM_TRANSPORTATION_MODE_GROUPING_CONFIG,
    customConfigName: GC.CUSTOM_TRANSPORTATION_MODE_GROUPING_CONFIG,
    fields: [
      {
        name: 'titles:mode',
        dropdownName: GC.GENERAL_MODE_TRANSPORTATION,
        customLogic: getDisplayedValueFromConfigDropdowns,
        nameForAttribute: GC.FIELD_TRANSPORTATION_MODE_GUID,
      },
      {
        tableColumnWith: 600,
        name: 'titles:equipments',
        dropdownName: GC.GENERAL_EQUIPMENTS,
        nameForAttribute: GC.FIELD_EQUIPMENTS,
        customLogic: getDisplayedValueFromConfigDropdowns,
      },
      {
        tableColumnWith: 1200,
        name: 'titles:services',
        dropdownName: GC.GENERAL_SERVICES,
        nameForAttribute: GC.FIELD_SERVICES,
        customLogic: getDisplayedValueFromConfigDropdowns,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:transportation-mode-mapping',
    configName: GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:mode',
        dropdownName: GC.GENERAL_MODE_TRANSPORTATION,
        customLogic: getDisplayedValueFromConfigDropdowns,
        nameForAttribute: GC.FIELD_TRANSPORTATION_MODE_GUID,
      },
      {
        name: 'titles:type-of-icon',
        nameForAttribute: GC.FIELD_ICON_TYPE,
        customLogic: (field: Object, { iconType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(iconType, GC.FIELD_VALUE)),
        )(getTransportationModeTypeOptions()),
      },
    ],
  },
];

const transportationServiceTypeSection = [{
  fields: [],
  configType: 'dropdown',
  addPopupTitle: 'titles:add',
  title: 'titles:service-type',
  editPopupTitle: 'titles:edit',
  configName: GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
}];

const uomCalculationSection = [
  {
    title: 'titles:uom-calculator',
    fields: {
      [GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM]: {
        type: 'select',
        name: 'titles:default-system',
        nameForAttribute: GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
        options: [
          { label: G.getWindowLocale('titles:metric', 'Metric'), value: GC.METRIC_SYSTEM },
          { label: G.getWindowLocale('titles:imperial', 'Imperial'), value: GC.IMPERIAL_SYSTEM },
        ],
      },
    },
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:distance-calculator',
    configName: GC.CUSTOM_DISTANCE_CALCULATOR_CONFIG,
    customConfigName: GC.CUSTOM_DISTANCE_CALCULATOR_CONFIG,
    fields: [
      {
        name: 'titles:config-name',
        nameForAttribute: GC.FIELD_NAME,
      },
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
      },
    ],
  },
  // TODO: render fields depends of distanceCalculatorConfig
  {
    title: 'titles:distance',
    fields: {
      [GC.GENERAL_DC_CONFIG]: {
        type: 'select',
        name: 'titles:calculator-type',
        nameForAttribute: GC.GENERAL_DC_CONFIG,
        options: 'distanceCalculatorConfigOptions',
      },
      [GC.GENERAL_DC_ROUTING_TYPE]: {
        type: 'select',
        name: 'titles:routing-type',
        nameForAttribute: GC.GENERAL_DC_ROUTING_TYPE,
        options: [
          { label: G.getWindowLocale('titles:practical', 'Practical'), value: 'PRACTICAL' },
          { label: G.getWindowLocale('titles:shortest', 'Shortest'), value: 'SHORTEST' },
          { label: G.getWindowLocale('titles:fastest', 'Fastest'), value: 'FASTEST' },
          { label: G.getWindowLocale('titles:interstate', 'Interstate'), value: 'INTERSTATE' },
          { label: G.getWindowLocale('titles:national-network', 'National Network'), value: 'NATIONAL_NETWORK' },
          { label: G.getWindowLocale('titles:eco', 'ECO'), value: 'ECO' },
        ],
      },
      [GC.GENERAL_DC_BORDER_OPEN]: {
        type: 'switcher',
        name: 'titles:border-open',
        nameForAttribute: GC.GENERAL_DC_BORDER_OPEN,
      },
      [GC.GENERAL_DC_TOLL_DISCOURAGE]: {
        type: 'switcher',
        name: 'titles:toll-discourage',
        nameForAttribute: GC.GENERAL_DC_TOLL_DISCOURAGE,
      },
      [GC.GENERAL_DC_USE_GEO_DISTANCE_FOR_COST_ALLOCATION]: {
        type: 'switcher',
        name: 'titles:use-geo-distance-for-cost-allocation',
        nameForAttribute: GC.GENERAL_DC_USE_GEO_DISTANCE_FOR_COST_ALLOCATION,
      },
      [GC.GENERAL_DC_GOOGLE_MAPS_AVOID]: {
        type: 'select',
        name: 'titles:google-maps-avoid',
        nameForAttribute: GC.GENERAL_DC_GOOGLE_MAPS_AVOID,
        options: [
          { label: G.getWindowLocale('titles:tolls', 'Tolls'), value: 'TOLLS' },
          { label: G.getWindowLocale('titles:highways', 'Highways'), value: 'HIGHWAYS' },
          { label: G.getWindowLocale('titles:ferries', 'Ferries'), value: 'FERRIES' },
        ],
      },
      [GC.GENERAL_DC_PRO_MILES_VEHICLE_TYPE]: {
        type: 'select',
        name: 'titles:pro-miles-vehicle-type',
        nameForAttribute: GC.GENERAL_DC_PRO_MILES_VEHICLE_TYPE,
        options: [
          { value: 'TRUCK_2_AXLE', label: G.getWindowLocale('titles:truck-2-axle', 'Truck 2 Axle ') },
          { value: 'TRUCK_3_AXLE', label: G.getWindowLocale('titles:truck-3-axle', 'Truck 3 Axle ') },
          {
            value: 'TRACTOR_2_AXLE_BOBTAIL',
            label: G.getWindowLocale('titles:tractor-2-axle-bobtail', 'Tractor 2 Axle - Bobtail'),
          },
          {
            value: 'TRACTOR_3_AXLE_BOBTAIL',
            label: G.getWindowLocale('titles:tractor-3-axle-bobtail', 'Tractor 3 Axle - Bobtail'),
          },
          {
            value: 'TRACTOR_2_AXLE_TRAILER_1_AXLE',
            label: G.getWindowLocale('titles:tractor-2-axle-trailer-1-Axle', 'Tractor 2 Axle, Trailer 1 Axle'),
          },
          {
            value: 'TRACTOR_2_AXLE_TRAILER_2_AXLE',
            label: G.getWindowLocale('titles:tractor-2-axle-trailer-2-axle', 'Tractor 2 Axle, Trailer 2 Axle'),
          },
          {
            value: 'TRACTOR_3_AXLE_TRAILER_1_AXLE',
            label: G.getWindowLocale('titles:tractor-3-axle-trailer-1-axle', 'Tractor 3 Axle, Trailer 1 Axle'),
          },
          {
            value: 'TRACTOR_3_AXLE_TRAILER_2_AXLE',
            label: G.getWindowLocale('titles:tractor-3-axle-trailer-2-axle', 'Tractor 3 Axle Trailer 2 Axle'),
          },
          {
            value: 'TRACTOR_3_AXLE_TRAILER_SPLIT_2_AXLE',
            label: G.getWindowLocale(
              'titles:tractor-3-axle-trailer-split-2-axle',
              'Tractor 3 Axle, Trailer Split 2 Axle',
            ),
          },
          {
            value: 'TRACTOR_3_AXLE_TRAILER_3_AXLE',
            label: G.getWindowLocale('titles:tractor-3-axle-trailer-3-axle', 'Tractor 3 Axle, Trailer 3 Axle'),
          },
          {
            value: 'TRACTOR_2_AXLE_DOUBLE',
            label: G.getWindowLocale('titles:tractor-2-axle-double', 'Tractor 2 Axle, Double') },
          {
            value: 'TRACTOR_3_AXLE_DOUBLE',
            label: G.getWindowLocale('titles:tractor-3-axle-double', 'Tractor 3 Axle, Double'),
          },
          {
            value: 'TRACTOR_2_AXLE_6_TIRES',
            label: G.getWindowLocale('titles:tractor-2-axle-6-tires', 'Tractor 2 Axle, 6 Tires'),
          },
        ],
      },
      [GC.GENERAL_DC_TRUCK_WIDTH]: {
        type: 'input',
        name: 'titles:truck-width',
        nameForAttribute: GC.GENERAL_DC_TRUCK_WIDTH,
      },
      [GC.GENERAL_DC_TRUCK_HEIGHT]: {
        type: 'input',
        name: 'titles:truck-height',
        nameForAttribute: GC.GENERAL_DC_TRUCK_HEIGHT,
      },
      [GC.GENERAL_DC_TRUCK_LENGTH]: {
        type: 'input',
        name: 'titles:truck-length',
        nameForAttribute: GC.GENERAL_DC_TRUCK_LENGTH,
      },
    },
  },
];

const ConfigGeneralGroup = {
  branchSection,
  routeSection,
  loadCustomStatusSection,
  servicesSection,
  accessorialsSection,
  equipmentsSection,
  transportationModeSection,
  transportationServiceTypeSection,
  uomCalculationSection,
};

export {
  ConfigGeneralGroup,
};
