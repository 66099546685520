import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { css } from 'styled-components';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, lifecycle, withState, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
import { openLoader, closeLoader } from '../../../../../components/loader/actions';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex } from '../../../../../ui';
// utilities
import { sendRequest } from '../../../../../utilities/http';
import endpointsMap from '../../../../../utilities/endpoints';
// feature configs
import { integrationTypeToServiceCodeMap } from '../constants';
import {
  serviceMappingFieldSettings,
  defaultServiceMappingFields,
  carrierServiceMappingSchemaObject,
  multipleServiceMappingFieldSettings,
  defaultMultipleServiceMappingFields,
  multipleServiceMappingValidationSchema } from '../settings';
//////////////////////////////////////////////////

const getValidOptions = ({ values, services, serviceCodes, initialValues, serviceMappingList }: Object) => {
  const integrationType = G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, values);

  if (G.isNilOrEmpty(integrationType)) return { services: [], serviceCodes: [] };

  const groupedServiceMappingList = R.groupBy(R.prop(GC.FIELD_INTEGRATION_TYPE), serviceMappingList);
  const createdServices = R.map(
    ({ serviceConfigGuid }: Object) => serviceConfigGuid,
    R.pathOr([], [integrationType], groupedServiceMappingList),
  );
  const selectedServices = R.map(
    ({ serviceConfigGuid }: Object) => serviceConfigGuid,
    R.pathOr([], [GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS], values),
  );
  const disabledServices = R.concat(selectedServices, createdServices);
  const mappedServices = R.map(
    (item: Object) => {
      const { value } = item;

      const disabled = R.and(
        R.includes(value, disabledServices),
        G.notEquals(value, G.getPropFromObject(GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID, initialValues)),
      );

      return R.assoc('isDisabled', disabled, item);
    },
    services,
  );

  return { services: mappedServices, serviceCodes };
};

export const withAsyncServiceCodes = compose(
  connect(null, { openLoader, closeLoader }),
  withState('serviceCodes', 'setServiceCodes', []),
  withHandlers({
    handleGetServiceCodes: (props: Object) => async (integrationType: Object) => {
      const { openLoader, closeLoader, setServiceCodes } = props;

      if (G.isNilOrEmpty(integrationType)) return setServiceCodes([]);

      const serviceCodes = G.getItemFromWindow(integrationType);
      if (G.isNotNilAndNotEmpty(serviceCodes)) return setServiceCodes(serviceCodes);

      openLoader();
      const options = {
        params: {
          carrierRateVendor: integrationType,
        },
      };
      const endpoint = endpointsMap.accessorialServiceCodes;
      const res = await sendRequest('get', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        const mappedServiceCodes = R.compose(
          G.addEmptyOptionToDropDown,
          R.map((serviceCode: string) => ({
            [GC.FIELD_VALUE]: serviceCode,
            [GC.FIELD_LABEL]: R.path([integrationType, serviceCode], integrationTypeToServiceCodeMap),
          })),
        )(data);
        setServiceCodes(mappedServiceCodes);
        G.setItemToWindow(integrationType, mappedServiceCodes);
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  pure,
);

const enhance = compose(
  withAsyncServiceCodes,
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(carrierServiceMappingSchemaObject),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultServiceMappingFields,
      props.initialValues,
    ),
  }),
  lifecycle({
    componentDidMount() {
      const { initialValues, handleGetServiceCodes } = this.props;

      const integrationType = G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, initialValues);
      handleGetServiceCodes(integrationType);
    },
  }),
  pure,
);

const ServiceMappingFormComponent = (props: Object) => (
  <Box mx='auto' width='270px'>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        fields={serviceMappingFieldSettings}
        optionsForSelect={getValidOptions(props)}
        handlers={{
          handleCustomChange: props.handleChangeIntegrationType,
          handleDisableIntegrationType: () => props.disableIntegrationType,
        }}
      />
      <FormFooter boxStyles={{ py: '10px' }} closeModal={props.closeModal} />
    </form>
  </Box>
);

export default enhance(ServiceMappingFormComponent);

const ServiceMappingRow = ({ push, form, remove, optionsForSelect }: Object) => {
  const serviceCodeServiceConfigGuids = R.path(['values', GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS], form);

  return (
    <Box>
      <Flex
        my='5px'
        cursor='pointer'
        width='max-content'
        onClick={() => push(defaultMultipleServiceMappingFields())}
      >
        <Box fontWeight='bold' color={G.getTheme('colors.light.blue')}>
          {G.getWindowLocale('titles:service-mapping', 'Service Mapping')}
        </Box>
        <Box ml={10}>{I.plusRound()}</Box>
      </Flex>
      {
        G.isNotNilAndNotEmpty(serviceCodeServiceConfigGuids) &&
        serviceCodeServiceConfigGuids.map((_: any, index: number) => (
          <Flex key={index}>
            {
              R.gt(R.length(serviceCodeServiceConfigGuids), 1) &&
              <Box mt={15} width={13} cursor='pointer' onClick={() => remove(index)}>
                {I.trash()}
              </Box>
            }
            <FieldsetComponent
              {...form}
              key={index}
              optionsForSelect={optionsForSelect}
              fields={multipleServiceMappingFieldSettings(index)}
            />
          </Flex>
        ))
      }
    </Box>
  );
};

const multipleServiceMappingEnhance = compose(
  withAsyncServiceCodes,
  withFormik({
    validationSchema: multipleServiceMappingValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: () => ({
      [GC.FIELD_INTEGRATION_TYPE]: GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE,
      [GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS]: R.of(Array, defaultMultipleServiceMappingFields()),
    }),
  }),
  withHandlers({
    handleChangeIntegrationType: (props: Object) => (e: Object) => {
      const { setValues, handleGetServiceCodes } = props;

      const fieldValue = R.path(['field', GC.FIELD_INTEGRATION_TYPE], e);
      const values = {
        [GC.FIELD_INTEGRATION_TYPE]: fieldValue,
        [GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS]: R.of(Array, defaultMultipleServiceMappingFields()),
      };

      setValues(values);
      handleGetServiceCodes(fieldValue);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetServiceCodes(GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE);
    },
  }),
  pure,
);

export const MultipleServiceMappingForm = multipleServiceMappingEnhance((props: Object) => (
  <form
    id='form_wrapper'
    onSubmit={props.handleSubmit}
    css={`
      overflow: auto;
      max-height: calc(90vh - 60px);
    `}
  >
    <FieldsetComponent
      {...G.getFormikProps(props)}
      fields={R.take(1, serviceMappingFieldSettings)}
      handlers={{
        handleDisableIntegrationType: () => false,
        handleCustomChange: props.handleChangeIntegrationType,
      }}
    />
    <FieldArray
      name={GC.FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS}
      render={(arrayHelpers: Object) =>
        <ServiceMappingRow
          {...arrayHelpers}
          optionsForSelect={getValidOptions(props)}
        />
      }
    />
    <FormFooter boxStyles={{ py: 10 }} closeModal={props.closeModal} />
  </form>
));
