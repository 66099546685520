import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { createLoadDocumentsRequest, createPrintLoadDocumentsRequest } from '../../../common/actions';
// features
import { PrintTelDocumentsForm } from '../../dispatch-details-new/forms/print-form';
// forms
import AddDocumentsForm from '../../../forms/forms/add-load-documents-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncTelDocuments,
  withAsyncAvailableDocumentTypes,
  withConnectModalAndLoaderActions,
} from '../../../hocs';
//////////////////////////////////////////////////

const EnhancedAddDocumentsForm = withAsyncAvailableDocumentTypes(AddDocumentsForm);

const PrintCreateDocumentForm = compose(
  withConnectModalAndLoaderActions,
  withAsyncTelDocuments,
  withAsyncAvailableDocumentTypes,
)(PrintTelDocumentsForm);

const enhance = compose(
  withHandlers({
    handleAddDocument: (props: Object) => () => {
      const {
        tel,
        openModal,
        closeModal,
        createLoadDocumentsRequest,
      } = props;

      const guid = G.getGuidFromObject(tel);

      const initialValues = {
        [GC.FIELD_PRIMARY_OBJECT_GUID]: guid,
        [GC.FIELD_DOCUMENT_PROOF_TYPE]: GC.DOCUMENT_PROOF_TYPE_GENERAL_VALUE,
      };

      const handleCreateLoadDocument = (values: Object, callback: Function) => {
        const valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);

        let data;

        if (G.isNotNilAndNotEmpty(R.prop(GC.FIELD_DOCUMENT_UPLOAD, valuesToUse))) {
          const commonProps = R.omit([GC.FIELD_DOCUMENT_UPLOAD], valuesToUse);

          data = R.compose(
            R.map((file: Object) => R.assoc(GC.FIELD_DOCUMENT_UPLOAD, file, commonProps)),
            R.prop(GC.FIELD_DOCUMENT_UPLOAD),
          )(valuesToUse);
        } else {
          data = R.of(Array, valuesToUse);
        }

        createLoadDocumentsRequest({
          data,
          callback,
          loadType: GC.LOAD_TYPE_TEL,
        });
      };

      const component = (
        <EnhancedAddDocumentsForm
          load={tel}
          onlyGeneralFile={true}
          closeModal={closeModal}
          entityName={GC.FIELD_TEL}
          initialValues={initialValues}
          branchGuid={R.prop(GC.BRANCH_GUID, tel)}
          handleActionLoadDocument={handleCreateLoadDocument}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-document', 'Add Document'),
        },
      };

      openModal(modal);
    },
    handlePrintCreateLoadDocument: (props: Object) => () => {
      const { tel, openModal, createPrintLoadDocumentsRequest } = props;

      const modalContent = (
        <PrintCreateDocumentForm
          withType={true}
          telGuid={G.getGuidFromObject(tel)}
          submitAction={(data: Object) => (
            createPrintLoadDocumentsRequest(R.assoc('loadGuid', G.getGuidFromObject(tel), data))
          )}
        />
      );

      const modal = G.getDefaultModalOptions(
        modalContent,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
  }),
  pure,
);

const withLoadDocuments = compose(
  connect(null, {
    createLoadDocumentsRequest,
    createPrintLoadDocumentsRequest,
  }),
  withConnectModalAndLoaderActions,
  enhance,
);

export default withLoadDocuments;
