import * as R from 'ramda';
import { put, call, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import { getGeneralConfigsForTelConfigSuccess } from './actions';
import {
  getConfigByGroupSaga,
  getSequencesByTypeForConfigSaga,
  getReferenceTypesByScopeForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

function* getAccessorialConfigListForTelConfigRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.accessorialsListEndpoint,
      successAction: A.getAccessorialConfigListForTelConfigSuccess,
      parentSagaName: 'getAccessorialConfigListForTelConfigRequest',
    });
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown exception');
    yield call(G.handleException, error, 'getAccessorialConfigListForTelConfigRequest fail');
  }
}

function* getCrossDockLocationsSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.crossDockLocationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCrossDockLocationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCrossDockLocationsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetCrossDockLocationsSaga exception');
  }
}

function* getGeneralConfigsForTelConfigSaga() {
  try {
    const names = R.join(',', [
      GC.GENERAL_MODE_TRANSPORTATION,
      GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
    ]);
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      shouldCloseModal: true,
      mapper: G.mapConfigValuesByName,
      endpoint: endpointsMap.branchConfigsEndpoint,
      parentSagaName: 'getGeneralConfigsForTelConfigSaga',
      successAction: getGeneralConfigsForTelConfigSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getGeneralConfigsForTelConfigSaga exception');
  }
}

// default carrier accessorials
function* getDefaultCarrierAccessorialListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.defaultCarrierAccessorialList,
      successAction: A.getDefaultCarrierAccessorialListSuccess,
      parentSagaName: 'getDefaultCarrierAccessorialListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDefaultCarrierAccessorialListRequest exception');
  }
}

function* createOrUpdateDefaultCarrierAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.defaultCarrierAccessorial,
      successAction: A.createOrUpdateDefaultCarrierAccessorialSuccess,
      parentSagaName: 'createOrUpdateDefaultCarrierAccessorialRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateDefaultCarrierAccessorialRequest exception');
  }
}

function* removeDefaultCarrierAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      payload,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.defaultCarrierAccessorial,
      successAction: A.removeDefaultCarrierAccessorialSuccess,
      parentSagaName: 'removeDefaultCarrierAccessorialRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeDefaultCarrierAccessorialRequest exception');
  }
}

function* handleVisitConfigTelPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_TEL_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getConfigByGroupSaga, GC.TEL_CONFIG_GROUP);
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_TEL });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_TEL });
    yield call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CLO });
    yield fork(getGeneralConfigsForTelConfigSaga);
    yield call(getCrossDockLocationsSaga);
    yield call(getAccessorialConfigListForTelConfigRequest);
    yield call(getDefaultCarrierAccessorialListRequest);
    yield put(A.getAvailableDocumentTypes());
    yield put(closeLoader());
    break;
  }
}

function* telConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_TEL_PAGE, handleVisitConfigTelPageSaga);
  // default carrier accessorials
  yield takeLatest(A.removeDefaultCarrierAccessorialRequest, removeDefaultCarrierAccessorialRequest);
  yield takeLatest(A.getDefaultCarrierAccessorialListRequest, getDefaultCarrierAccessorialListRequest);
  yield takeLatest(A.createOrUpdateDefaultCarrierAccessorialRequest, createOrUpdateDefaultCarrierAccessorialRequest);
}

export default telConfigWatcherSaga;
