import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature dashboard
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  groupList: null,
  dashboardList: [],
  dashboardCharts: [],
  currentDashboard: {},
};

const getChartDataRequest = (state: Object, chart: Object) => (
  P.$add('dashboardCharts', { ...chart, pending: true }, state)
);

const getChartDataSuccess = (state: Object, { data, index }: Object) => {
  const chart = P.$get(`dashboardCharts.${index}`, state);
  const chartWithData = {
    ...chart,
    data,
    pending: false,
  };
  return P.$set(`dashboardCharts.${index}`, chartWithData, state);
};

const getChartDataFailed = (state: Object, chartInfo: Object) => {
  const index = R.findIndex(
    ({ chart }: Object) => R.equals(chart.guid, chartInfo.chart.guid),
    state.dashboardCharts,
  );
  const newChart = R.pathOr({}, [index], state.dashboardCharts);
  return P.$set(`dashboardCharts.${index}`, { ...newChart, pending: false }, state);
};

const getDashboardListSuccess = (state: Object, dashboards: Array) => (
  P.$set('dashboardList', dashboards, state)
);

const getChartsByCollectionSuccess = (state: Object, groups: Array) => (
  P.$set('groupList', groups, state)
);

const getChartsByCollectionFailed = (state: Object) => (
  P.$set('groupList', [], state)
);

const createDashboardSuccess = (state: Object, dashboard: Object) => (
  P.$add('dashboardList', dashboard, state)
);

const updateDashboardSuccess = (state: Object, { data, index }: Object) => (
  P.$set(`dashboardList.${index}`, data, state)
);

const deleteDashboardSuccess = (state: Object, index: number) => (
  P.$drop(`dashboardList.${index}`, state)
);

const setCurrentDashboard = (state: Object, dashboard: Object) => (
  P.$set('currentDashboard', dashboard, state)
);

const cleanDashboardCharts = (state: Object, index: number) => {
  if (R.isNil(index)) {
    return P.$set('dashboardCharts', [], state);
  }
  return P.$drop(`dashboardCharts.${index}`, state);
};

const setDashboardChartsPosition = (state: Object, charts: Array) => (
  P.$set('dashboardCharts', charts, state)
);

export const cleanAnalyticState = () => initialState;

export default createReducer({
  [A.getChartDataFailed]: getChartDataFailed,
  [A.cleanAnalyticState]: cleanAnalyticState,
  [A.getChartDataRequest]: getChartDataRequest,
  [A.getChartDataSuccess]: getChartDataSuccess,
  [A.setCurrentDashboard]: setCurrentDashboard,
  [A.cleanDashboardCharts]: cleanDashboardCharts,
  [A.createDashboardSuccess]: createDashboardSuccess,
  [A.updateDashboardSuccess]: updateDashboardSuccess,
  [A.deleteDashboardSuccess]: deleteDashboardSuccess,
  [A.getDashboardListSuccess]: getDashboardListSuccess,
  [A.setDashboardChartsPosition]: setDashboardChartsPosition,
  [A.getChartsByCollectionFailed]: getChartsByCollectionFailed,
  [A.getChartsByCollectionSuccess]: getChartsByCollectionSuccess,
}, initialState);
