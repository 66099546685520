import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import React, { Fragment } from 'react';
import { pure, compose, lifecycle, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// hocs
import { withAsyncGetUnitIdListByEntityType } from '../../../hocs';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const styles = {
  width: 270,
  errorTop: '110%',
  afterTop: '14px',
  labelWidth: '100%',
  errorFontSize: '12px',
  flexDirection: 'column',
  errorMargin: '0 0 5px 0',
  errorPosition: 'absolute',
  inputWrapMargin: '5px 0px',
};

const removeDateField = [{
  ...styles,
  zIndex: 11,
  isClearable: true,
  type: 'datePicker',
  loc: 'titles:remove-date',
  fieldName: GC.FIELD_REPLACE_OR_REMOVE_DATE,
}];

const assignEntityFieldSettings = (entityType: string) => [
  {
    ...styles,
    options: 'unitIds',
    type: 'searchselect',
    loc: G.ifElse(R.equals(entityType, 'truck'), 'titles:truck', 'titles:trailer'),
    fieldName: G.ifElse(R.equals(entityType, 'truck'), GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID),
  },
  {
    ...styles,
    isClearable: true,
    type: 'datePicker',
    loc: 'titles:installed-date',
    fieldName: GC.FIELD_INSTALL_DATE,
  },
];

const fieldNotRequired = Yup.string().nullable(true).notRequired();
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const validationSchema = ({ truckUnitId, trailerUnitId }: Object) => Yup.lazy(({ truckGuid, trailerGuid }: Object) =>
  Yup.object().shape({
    [GC.FIELD_INSTALL_DATE]: G.ifElse(
      G.isOneNotNilOrNotEmpty([truckGuid, trailerGuid]),
      fieldRequired,
      fieldNotRequired,
    ),
    [GC.FIELD_REPLACE_OR_REMOVE_DATE]: G.ifElse(
      G.isOneNotNilOrNotEmpty([truckUnitId, trailerUnitId]),
      fieldRequired,
      fieldNotRequired,
    ),
  }),
);

const enhance = compose(
  withAsyncGetUnitIdListByEntityType,
  lifecycle({
    componentDidMount() {
      this.props.getAsyncGetUnitIdList();
    },
  }),
  withPropsOnChange(['asyncUnitIdList'], ({ asyncUnitIdList }: Object) => {
    const unitIds = R.map(({ guid, unitId }: Object) => ({
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: unitId,
    }), asyncUnitIdList);

    return {
      optionsForSelect: { unitIds },
    };
  }),
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      initialValues,
      {
        [GC.FIELD_INSTALL_DATE]: null,
        [GC.FIELD_REPLACE_OR_REMOVE_DATE]: null,
      },
    ),
  }),
  pure,
);

const getCurrentEntityElement = ({ truckUnitId, trailerUnitId }: Object) => {
  if (G.isAllNilOrEmpty([truckUnitId, trailerUnitId])) return null;

  const getLocaleArr = G.ifElse(
    G.isNotNilAndNotEmpty(truckUnitId),
    ['titles:current-truck', 'Current Truck'],
    ['titles:current-trailer', 'Current Trailer'],
  );

  return (
    <Fragment>
      {G.getWindowLocale(...getLocaleArr)}: <b>{R.or(truckUnitId, trailerUnitId)}</b>
    </Fragment>
  );
};

const AssignSharedComponentForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    {
      G.isNotNil(getCurrentEntityElement(props)) &&
      <Flex justifyContent='space-between'>
        <TextComponent
          ml='5px'
          width={270}
          maxWidth={270}
          display='block'
          withEllipsis={true}
          title={R.or(props.truckUnitId, props.trailerUnitId)}
        >
          {getCurrentEntityElement(props)}
        </TextComponent>
        <FieldsetComponent {...G.getFormikProps(props)} flexGrow='unset' fields={removeDateField} />
      </Flex>
    }
    <FieldsetComponent
      {...G.getFormikProps(props)}
      justifyContent='space-between'
      optionsForSelect={props.optionsForSelect}
      fields={assignEntityFieldSettings(props.entityType)}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
);

export default enhance(AssignSharedComponentForm);
