import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
// feature carrier-rating-report
import TelList from '../components/expanded-details';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_STATUS]: {
    width: 150,
    type: 'enum',
    name: 'titles:status',
  },
  [GC.FIELD_BRANCH_NAME]: {
    width: 150,
    name: 'titles:branch',
  },
  totalCount: {
    width: 150,
    name: 'titles:total-count',
  },
  successCount: {
    width: 150,
    name: 'titles:success-count',
  },
  failedCount: {
    width: 150,
    name: 'titles:failed-count',
  },
  startedBy: {
    width: 200,
    name: 'titles:started-by',
    customComponent: ({ data }: Object) => {
      const user = `${R.pathOr('', ['createdByFirstName'], data)} ${
        R.pathOr('', ['createdByLastName'], data)} (${R.pathOr('', ['createdByLoginId'], data)})`;

      return (
        <TextComponent
          title={user}
          display='block'
          maxWidth='100%'
          withEllipsis={true}
        >
          {user}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
  },
};

export const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_STATUS },
    { sequence: 2, name: GC.FIELD_BRANCH_NAME },
    { sequence: 3, name: 'totalCount' },
    { sequence: 4, name: 'successCount' },
    { sequence: 5, name: 'failedCount' },
    { sequence: 6, name: 'startedBy' },
    { sequence: 7, name: GC.FIELD_CREATED_DATE },
  ],
};

export const telTableSettings = {
  rowHeight: 30,
  titleRowHeight: 30,
  tableRowHeight: 30,
  coloredHeader: true,
  useMainColors: false,
  allowSelectItems: false,
  checkBoxCellWidth: '0px',
};

export const telReport = {
  fields: [
    { sequence: 1, name: GC.FIELD_PRIMARY_REFERENCE_VALUE },
    { sequence: 2, name: 'error' },
  ],
};

export const telColumnSettings = {
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    name: 'titles:tel-number',
    customComponent: ({ data, fieldData }: Object) => {
      if (G.hasAmousCurrentUserPermissions([PC.TEL_READ, PC.TEL_WRITE])) {
        return (
          <TextComponent
            display='block'
            maxWidth='100%'
            cursor='pointer'
            withEllipsis={true}
            color={G.getTheme('colors.light.blue')}
            title={G.getWindowLocale('actions:go-to-details', 'Go To Details')}
            onClick={() => G.goToLoadDetailsByConfigAndLoadType(routesMap, (R.prop(GC.FIELD_TEL_GUID, data)))}
          >
            {fieldData}
          </TextComponent>
        );
      }

      return fieldData;
    },
  },
  error: {
    width: 400,
    name: 'titles:error',
  },
};

export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  useMainColors: true,
  checkBoxCellWidth: 70,
  expandableItems: true,
  allowSelectItems: true,
  maxHeight: 'calc(100vh - 140px)',
  expandedDetailsComponent: TelList,
};
