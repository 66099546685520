import * as R from 'ramda';
import React from 'react';
import { compose, withProps, lifecycle, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet
import EquipmentComponentForm from '../../fleet/components/equipment-component-form';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncConfigs,
  withState('initialValues', 'setInitialValues', {}),
  withHandlers({
    handleGetSharedComponent: (props: Object) => async () => {
      const { guid, openLoader, closeLoader, setInitialValues } = props;

      G.callFunction(openLoader);
      const endpoint = endpointsMap.getFleetSharedComponent(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const initialValues = {
          ...data,
          [GC.FIELD_COMPONENT_TYPE]: R.path([GC.FIELD_COMPONENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
          [GC.FIELD_COMPONENT_MANUFACTURER]: R.path(
            [GC.FIELD_COMPONENT_MANUFACTURER, GC.FIELD_DROPDOWN_OPTION_GUID],
            data,
          ),
        };

        setInitialValues(initialValues);
      } else {
        G.handleFailResponse(res);
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { guid, handleGetSharedComponent } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) handleGetSharedComponent();
    },
  }),
  withProps(({ asyncConfigs }: Object) => {
    const componentType = G.mapDropdownConfigWithParentGuid(asyncConfigs, GC.FLEET_COMPONENT_TYPE);
    const componentManufacturer = G.mapDropdownConfigWithParentGuid(asyncConfigs, GC.FLEET_COMPONENT_MANUFACTURER);

    return {
      configs: { componentType, componentManufacturer },
    };
  }),
);

export default enhance((props: Object) => (
  <EquipmentComponentForm {...props} fromFleetSharedComponentList={true} />
));
