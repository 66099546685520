import { createAction } from 'redux-act';
//////////////////////////////////////////////////

const setAuditLoading = createAction('setAuditLoading');
const setInitialState = createAction('setInitialState');
const toggleAuditDetails = createAction('toggleAuditDetails');
const getAuditListRequest = createAction('getAuditListRequest');
const receivedAuditListSuccess = createAction('receivedAuditListSuccess');

export {
  setAuditLoading,
  setInitialState,
  toggleAuditDetails,
  getAuditListRequest,
  receivedAuditListSuccess,
};
