import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_BRANCH_ACCOUNT_NAME]: {
    width: 200,
    name: 'titles:account-number',
  },
  [GC.FIELD_ACTIVE]: {
    width: 200,
    type: 'boolean',
    name: 'titles:active',
  },
  [GC.FIELD_PRIMARY_DIVISION]: {
    width: 150,
    type: 'boolean',
    name: 'titles:primary-division',
  },
  [GC.FIELD_PRIMARY_DIVISION_NAME]: {
    width: 200,
    name: 'titles:primary-division-name',
  },
  [GC.FIELD_DELETED]: {
    width: 200,
    type: 'boolean',
    name: 'titles:deleted',
  },
  [GC.GRC.PARENT_BRANCH_NAME]: {
    width: 200,
    name: ['titles:parent', 'titles:name'],
  },
  [GC.FIELD_BRANCH_SCAC]: {
    width: 200,
    name: 'titles:scac',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_BRANCH_DELETE_DATE]: {
    type: 'date',
    name: 'titles:delete-date',
  },
  [GC.FIELD_BRANCH_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_BRANCH_DESCRIPTION]: {
    width: 200,
    name: 'titles:description',
  },
  [GC.FIELD_BRANCH_DUNS_NUMBER]: {
    width: 200,
    name: 'titles:duns-number',
  },
  [GC.FIELD_BRANCH_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_BRANCH_DBA]: {
    width: 200,
    name: 'titles:dba',
  },
  [GC.FIELD_BRANCH_FEDERAL_EIN]: {
    width: 200,
    name: 'titles:federal-ein',
  },
  [GC.FIELD_BRANCH_MC_NUMBER]: {
    width: 200,
    name: 'titles:mc-number',
  },
  [GC.FIELD_BRANCH_NOTES]: {
    width: 200,
    name: 'titles:display-notes',
  },
  [GC.FIELD_BRANCH_SUSPENDED]: {
    width: 200,
    type: 'boolean',
    name: 'titles:suspended',
  },
  [GC.FIELD_BRANCH_BRANCH_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:type',
  },
  [`${GC.FIELD_BRANCH_SUBTYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: 'titles:branch-subtype',
  },
  [GC.FIELD_BRANCH_CREDIT_LIMIT]: {
    width: 200,
    name: 'titles:credit-limit',
  },
  [GC.FIELD_BRANCH_CREDIT_USED]: {
    width: 200,
    name: 'titles:credit-used',
  },
  [GC.FIELD_BRANCH_DEACTIVATE_ON_CREDIT_LIMIT]: {
    width: 200,
    type: 'boolean',
    name: 'titles:deactivate:on-credit-limit',
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_ZIP}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:zip'],
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_CITY}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:city'],
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_STATE}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:state'],
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_COUNTRY}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:country'],
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_COMMENTS}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:comments'],
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_ADDRESS_1}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:address1'],
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_LOCATION_NAME}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:location-name'],
  },
  [`${GC.SYSTEM_OBJECT_PRIMARY_LOCATION}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: ['titles:primary-location', 'titles:location-type'],
  },
  [GC.GRC.PRIMARY_LOCATION_CONTACT_NAME]: {
    width: 200,
    name: ['titles:primary-location', 'titles:contact-name'],
  },
  [GC.GRC.PRIMARY_LOCATION_EMAILS_FIELD]: {
    width: 200,
    name: ['titles:primary-location', 'titles:emails'],
  },
  [GC.GRC.PRIMARY_LOCATION_PHONE]: {
    width: 200,
    name: ['titles:primary-location', 'titles:phone'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ZIP}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:zip'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_CITY}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:city'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_STATE}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:state'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COUNTRY}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:country'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_COMMENTS}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:comments'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_ADDRESS_1}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:address1'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_NAME}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:location-name'],
  },
  [`${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 200,
    name: ['titles:bill-to', 'titles:location-type'],
  },
  [GC.GRC.BILL_TO_CONTACT_NAME]: {
    width: 200,
    name: ['titles:bill-to', 'titles:contact-name'],
  },
  [GC.GRC.BILL_TO_EMAILS_FIELD]: {
    width: 200,
    name: ['titles:bill-to', 'titles:emails'],
  },
  [GC.GRC.BILL_TO_PHONE]: {
    width: 200,
    name: ['titles:bill-to', 'titles:phone'],
  },
  [GC.GRC.BROKER_AGENTS_NAME]: {
    width: 300,
    name: ['titles:broker-agents', 'titles:name'],
  },
  [GC.GRC.BROKER_AGENTS_ID]: {
    width: 300,
    name: ['titles:broker-agents', 'titles:id'],
  },
  [GC.GRC.TEL_SALE_REPRESENTATIVES_NAME]: {
    width: 300,
    name: ['titles:tel', 'titles:sale-representatives', 'titles:name'],
  },
  [GC.GRC.TEL_SALE_REPRESENTATIVES_ID]: {
    width: 300,
    name: ['titles:tel', 'titles:sale-representatives', 'titles:id'],
  },
  [GC.GRC.CARRIER_REPRESENTATIVES_NAME]: {
    width: 300,
    name: ['titles:carrier-representatives', 'titles:name'],
  },
  [GC.GRC.CARRIER_REPRESENTATIVES_ID]: {
    width: 300,
    name: ['titles:carrier-representatives', 'titles:id'],
  },
  [GC.GRC.SALE_PERSONS_NAME]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:name'],
  },
  [GC.GRC.SALE_PERSONS_ID]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:id'],
  },
  [GC.GRC.CLO_SALE_REPRESENTATIVES_NAME]: {
    width: 300,
    name: ['titles:clo', 'titles:sale-representatives', 'titles:name'],
  },
  [GC.GRC.CLO_SALE_REPRESENTATIVES_ID]: {
    width: 300,
    name: ['titles:clo', 'titles:sale-representatives', 'titles:id'],
  },
  [GC.GRC.ACCOUNT_MANAGERS_NAME]: {
    width: 300,
    name: ['titles:account-managers', 'titles:name'],
  },
  [GC.GRC.ACCOUNT_MANAGERS_ID]: {
    width: 300,
    name: ['titles:account-managers', 'titles:id'],
  },
  [GC.FIELD_INTEGRATION_ACCOUNT_NUMBER]: {
    width: 210,
    name: 'titles:order-import-account-number',
  },
};
