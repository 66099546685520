import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { StyledLink, ReactSelect } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import { setDocumentsFilter } from '../actions';
import { makeSelectDocumentsFilter } from '../selectors';
//////////////////////////////////////////////////

const documentsFilterMapStateToProps = (state: Object) => createStructuredSelector({
  documentsFilter: makeSelectDocumentsFilter(state),
});

const DocumentsFilter = connect(documentsFilterMapStateToProps, { setDocumentsFilter })((props: Object) => {
  const { documentsFilter, documentTypeOptions, setDocumentsFilter } = props;

  return (
    <ReactSelect
      height={30}
      value={documentsFilter}
      options={documentTypeOptions}
      onChange={(option: Object) => setDocumentsFilter(R.pathOr(null, [GC.FIELD_VALUE], option))}
      additionalStyles={{
        container: (baseStyles: Object) => ({
          ...baseStyles,
          width: 230,
          margin: '5px 10px',
        }),
        menu: (baseStyles: Object) => ({
          ...baseStyles,
          zIndex: 11,
          color: G.getTheme('colors.black'),
        }),
      }}
    />
  );
});

const statusOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: GC.DOCUMENT_STATUS_CURRENT,
    label: G.getWindowLocale('titles:current', 'Current'),
  },
  {
    value: GC.DOCUMENT_STATUS_HISTORICAL,
    label: G.getWindowLocale('titles:historical', 'Historical'),
  },
];

const settings = {
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    isRequired: true,
    fieldType: 'select',
    name: 'titles:document-type',
    options: 'documentTypeOptions',
    customComponent: R.path(['data', GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_DOCUMENT_STATUS]: {
    isRequired: true,
    fieldType: 'select',
    name: 'titles:status',
    options: statusOptions,
    customComponent: ({ data }: Object) => R.compose(
      R.path([GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_STATUS, data), GC.FIELD_VALUE)),
    )(statusOptions),
  },
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 300,
    name: 'titles:document-name',
    customComponent: ({ data }: Object) => {
      const url = G.getPropFromObject(GC.FIELD_DOCUMENT_URL, data);
      const documentFileName = G.getPropFromObject(GC.FIELD_DOCUMENT_FILE_NAME, data);
      const title = R.or(documentFileName, url);

      return (
        <TextComponent title={title} display='block' maxWidth='90%' withEllipsis={true}>
          {
            G.isNotNilAndNotEmpty(url) &&
            <StyledLink
              target='_blank'
              textDecoration='underline'
              href={G.makeURLString(url)}
              color={G.getTheme('colors.light.blue')}
            >
              {url}
            </StyledLink>
          }
          {G.isNotNilAndNotEmpty(documentFileName) && documentFileName}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DOCUMENT_DESCRIPTION]: {
    width: 200,
    type: 'textarea',
    name: 'titles:description',
    additionalInputWrapperStyles: { width: 400 },
  },
  [GC.FIELD_DOCUMENT_SIGNED_DATE]: {
    width: 150,
    fieldType: 'datePicker',
    name: 'titles:signed-date',
    maxDate: (props: Object) => {
      const expirationDate = R.path(['values', GC.FIELD_DOCUMENT_EXPIRATION_DATE], props);

      return R.and(G.isNotNilAndNotEmpty(expirationDate), G.makeMomentInstance(expirationDate));
    },
  },
  [GC.FIELD_DOCUMENT_EXPIRATION_DATE]: {
    width: 150,
    fieldType: 'datePicker',
    name: 'titles:expired-date',
    minDate: (props: Object) => {
      const signedDate = R.path(['values', GC.FIELD_DOCUMENT_SIGNED_DATE], props);

      return R.and(G.isNotNilAndNotEmpty(signedDate), G.makeMomentInstance(signedDate));
    },
  },
  [GC.FIELD_DOCUMENT_SHOWN_FOR_DRIVER]: {
    width: 120,
    type: 'boolean',
    fieldType: 'toggle',
    defaultValue: false,
    name: 'titles:shown-for-driver',
    additionalInputWrapperStyles: { width: 400 },
  },
  [GC.FIELD_DOCUMENT_URL]: {
    width: 150,
    fieldType: 'text',
    name: 'titles:url',
    fieldName: GC.FIELD_DOCUMENT_URL,
    additionalInputWrapperStyles: {
      width: 400,
      display: (props: Object) => {
        const { file, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(R.or(G.isNotNil(file), G.isNotNil(documentFilename)), 'none', 'block');
      },
    },
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    width: 150,
    fieldType: 'file',
    name: 'titles:document-template',
    additionalInputWrapperStyles: {
      width: 400,
      display: (props: Object) => {
        const { url, version, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(
          R.or(G.isNotNilAndNotEmpty(url), R.and(G.isNotNil(version), R.isNil(documentFilename))),
          'none',
          'block',
        );
      },
    },
  },
};

const emptyFileOrUrl = () =>
  G.getWindowLocale('messages:empty-file-or-url', 'Please, select a file or enter file URL');

export const defaultValidationSchemaDocumentFormObject = {
  [GC.FIELD_DOCUMENT_DESCRIPTION]: Yup.string()
    .nullable(true),
  [GC.FIELD_DOCUMENT_STATUS]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const getDocumentFormValidationSchemaObject = (values: Object, edit: any) => {
  const requiredFields = [R.prop(GC.FIELD_DOCUMENT_URL, values), R.prop(GC.FIELD_DOCUMENT_UPLOAD, values)];

  if (R.any(G.isNotNilAndNotEmpty)(requiredFields)) return defaultValidationSchemaDocumentFormObject;

  const requiredFieldsValidationSchemaObject = {
    [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string()
      .nullable(true)
      .required(G.ifElse(
        G.isTrue(edit),
        G.getShouldBeFileLocaleTxt(),
        emptyFileOrUrl(),
      )),
    [GC.FIELD_DOCUMENT_URL]: Yup.string()
      .nullable(true)
      .required(G.ifElse(
        G.isTrue(edit),
        G.getShouldBeUrlLocaleTxt(),
        emptyFileOrUrl(),
      )),
  };

  return R.mergeRight(defaultValidationSchemaDocumentFormObject, requiredFieldsValidationSchemaObject);
};

const validationSchema = Yup.lazy(({ url, file }: Object) => Yup.object().shape({
  [GC.FIELD_DOCUMENT_STATUS]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_DESCRIPTION]: G.yupStringNotRequired,
  [GC.FIELD_DOCUMENT_URL]: G.ifElse(
    G.isAllNilOrEmpty([url, file]),
    G.yupStringRequired,
    Yup.string().nullable(true).url(G.getShouldBeUrlLocaleTxt()),
  ),
  [GC.FIELD_DOCUMENT_UPLOAD]: G.ifElse(
    G.isAllNilOrEmpty([url, file]),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

const defaultInputWrapperStyles = {
  mb: 25,
  width: 190,
};

const getReport = (settings: Object) => ({
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
    R.omit([GC.FIELD_DOCUMENT_URL, GC.FIELD_DOCUMENT_UPLOAD]),
  )(settings),
});

const defaultValues = R.map(() => null, R.dissoc(GC.FIELD_DOCUMENT_FILE_NAME, settings));

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    minDate,
    maxDate,
    fieldType = 'text',
    isRequired = false,
    additionalInputWrapperStyles = {},
  }: Object, fieldName: string) => ({
    minDate,
    maxDate,
    options,
    fieldName,
    isRequired,
    label: R.of(Array, name),
    [GC.FIELD_TYPE]: fieldType,
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...additionalInputWrapperStyles,
    },
  })),
  R.dissoc(GC.FIELD_DOCUMENT_FILE_NAME),
)(settings);

const vendorFieldSettings = R.insert(
  5,
  {
    type: 'toggle',
    inputWrapperStyles: { mb: 25, width: 400 },
    label: ['titles:attachable-to-customer-invoice'],
    fieldName: GC.FIELD_ATTACHABLE_TO_CUSTOMER_INVOICE,
  },
  R.reject(R.propEq(GC.FIELD_DOCUMENT_SHOWN_FOR_DRIVER, 'fieldName'), fieldSettings),
);

const columnSettings = R.map(R.pick(['name', 'type', 'width', 'customComponent']), settings);

const vendorColumnSettings = {
  ...R.dissoc(GC.FIELD_DOCUMENT_SHOWN_FOR_DRIVER, columnSettings),
  [GC.FIELD_ATTACHABLE_TO_CUSTOMER_INVOICE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:attachable-to-customer-invoice',
  },
};

const report = getReport(settings);
const vendorReport = getReport(vendorColumnSettings);

export const documentSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  groupName: 'documents',
  itemTitleArr: ['titles:document', 'Document'],
  primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  additionalTableSettings: { checkBoxCellWidth: 100 },
  formGroupTitleArr: ['titles:documents', 'Documents'],
  actionsPicker: ['edit', 'remove', 'downloadDocument', 'previewDocument'],
  downloadOptions: {
    endpointName: 'driverDownloadDoc',
    requestParamsPicker: [GC.FIELD_DOCUMENT_FILE_NAME],
    primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  },
  endpoints: {
    list: 'driverDocList',
    remove: 'getCurrentDocumentsEndpoint',
  },
  fieldsWrapperStyles: {
    width: 400,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  AdditionalFormGroupTitleComponent: ({ isOpened, configGroup }: Object) => (
    <DocumentsFilter
      isOpened={isOpened}
      documentTypeOptions={R.drop(1, R.pathOr([], ['dropdownOptions', GC.DRIVER_DOCUMENT_TYPE], configGroup))}
    />
  ),
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    documentTypeOptions: R.path(['dropdownOptions', GC.DRIVER_DOCUMENT_TYPE], configGroup),
  }),
  makeInitialValues: (initialValues: Object) => {
    const { documentType, documentFilename } = initialValues;

    return {
      ...initialValues,
      [GC.FIELD_DOCUMENT_UPLOAD]: documentFilename,
      [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.getDropdownOptionGuidFromObject(documentType),
    };
  },
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, createOrUpdateEntityRequest } = props;

    createOrUpdateEntityRequest({
      method: 'post',
      additionalOptions,
      groupName: 'documents',
      values: G.makeDataForDocument(values),
      endpoint: G.ifElse(
        G.isNotNilAndNotEmpty(G.getGuidFromObject(values)),
        endpointsMap.driverDocUpdate,
        endpointsMap.driverDoc,
      ),
    });
  },
};

export const vendorDocumentSettings = {
  ...documentSettings,
  report: vendorReport,
  fieldSettings: vendorFieldSettings,
  columnSettings: vendorColumnSettings,
  endpoints: {
    remove: 'removeVendorDocument',
    list: 'fleetVendorDocumentList',
  },
  downloadOptions: {
    endpointName: 'fleetVendorDocumentDownloadFile',
    requestParamsPicker: [GC.FIELD_DOCUMENT_FILE_NAME],
    primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  },
  AdditionalFormGroupTitleComponent: ({ isOpened, branchConfigs }: Object) => (
    <DocumentsFilter
      isOpened={isOpened}
      documentTypeOptions={R.drop(1, R.pathOr([], ['dropdownOptions', GC.CARRIER_DOCUMENT_TYPE], branchConfigs))}
    />
  ),
  // helpers
  makeOptionsForSelect: ({ branchConfigs }: Object) => ({
    documentTypeOptions: R.path(['dropdownOptions', GC.CARRIER_DOCUMENT_TYPE], branchConfigs),
  }),
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, createOrUpdateEntityRequest } = props;

    createOrUpdateEntityRequest({
      method: 'post',
      additionalOptions,
      groupName: 'documents',
      values: G.makeDataForDocument(values),
      endpoint: G.ifElse(
        G.isNotNilAndNotEmpty(G.getGuidFromObject(values)),
        endpointsMap.fleetVendorDocumentUpdate,
        endpointsMap.fleetVendorDocument,
      ),
    });
  },
};

export const truckDocumentSettings = {
  ...documentSettings,
  endpoints: {
    list: 'truckDocList',
    remove: 'getCurrentTruckDocEndpoint',
  },
  downloadOptions: {
    endpointName: 'truckDownloadDoc',
    requestParamsPicker: [GC.FIELD_DOCUMENT_FILE_NAME],
    primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  },
  AdditionalFormGroupTitleComponent: ({ isOpened, configGroup }: Object) => (
    <DocumentsFilter
      isOpened={isOpened}
      documentTypeOptions={R.drop(1, R.pathOr([], ['dropdownOptions', GC.TRUCK_DOCUMENT_TYPE], configGroup))}
    />
  ),
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    documentTypeOptions: R.path(['dropdownOptions', GC.TRUCK_DOCUMENT_TYPE], configGroup),
  }),
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, createOrUpdateEntityRequest } = props;

    createOrUpdateEntityRequest({
      method: 'post',
      additionalOptions,
      groupName: 'documents',
      values: G.makeDataForDocument(values),
      endpoint: G.ifElse(
        G.isNotNilAndNotEmpty(G.getGuidFromObject(values)),
        endpointsMap.truckDocUpdate,
        endpointsMap.truckDoc,
      ),
    });
  },
};

export const trailerDocumentSettings = {
  ...documentSettings,
  endpoints: {
    list: 'trailerDocList',
    remove: 'getCurrentTrailerDocEndpoint',
  },
  downloadOptions: {
    endpointName: 'trailerDownloadDoc',
    requestParamsPicker: [GC.FIELD_DOCUMENT_FILE_NAME],
    primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  },
  AdditionalFormGroupTitleComponent: ({ isOpened, configGroup }: Object) => (
    <DocumentsFilter
      isOpened={isOpened}
      documentTypeOptions={R.drop(1, R.pathOr([], ['dropdownOptions', GC.TRAILER_DOCUMENT_TYPE], configGroup))}
    />
  ),
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    documentTypeOptions: R.path(['dropdownOptions', GC.TRAILER_DOCUMENT_TYPE], configGroup),
  }),
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, createOrUpdateEntityRequest } = props;

    createOrUpdateEntityRequest({
      method: 'post',
      additionalOptions,
      groupName: 'documents',
      values: G.makeDataForDocument(values),
      endpoint: G.ifElse(
        G.isNotNilAndNotEmpty(G.getGuidFromObject(values)),
        endpointsMap.trailerDocUpdate,
        endpointsMap.trailerDoc,
      ),
    });
  },
};
