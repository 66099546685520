import * as R from 'ramda';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

export const getTabs = (activeTab: number) => {
  const tabs = [
    {
      withCount: R.equals(activeTab, 0),
      text: G.getWindowLocale('titles:tel', 'TEL'),
    },
    {
      withCount: R.equals(activeTab, 1),
      text: G.getWindowLocale('titles:trip-by-order', 'Trip By Order'),
    },
    {
      withCount: R.equals(activeTab, 2),
      text: G.getWindowLocale('titles:trip-events', 'Trip Events'),
    },
    {
      withCount: R.equals(activeTab, 3),
      text: G.getWindowLocale('titles:pivot-table-trip', 'Pivot Table Trip'),
    },
  ];

  return G.ifElse(
    G.isCurrentUserTypeCarrier(),
    R.take(1, tabs),
    tabs,
  );
};

export const getListActionsOpt = (props: Object) => {
  const {
    handlePrint,
    getXMLRequest,
    handleAddCarrierRate,
    handleDispatchTelList,
    handleAddReferenceList,
    handlePostTelToLoadBoard,
    handleSendTelToMacroPoint,
    handleAddCarrierRateByReport,
  } = props;

  const mainLightColor = G.getTheme('colors.light.mainLight');

  return [
    {
      action: getXMLRequest,
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      action: handlePrint,
      icon: I.printer(mainLightColor, 20, 20),
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      text: G.getWindowLocale('actions:print', 'Print'),
    },
    {
      type: 'massAction',
      action: handlePostTelToLoadBoard,
      icon: I.plusRound(mainLightColor, 25, 25),
      permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
      text: G.getWindowLocale('titles:post-to-loadboard', 'Post To Load Board'),
    },
    {
      type: 'massAction',
      permissions: [PC.TEL_WRITE],
      action: handleSendTelToMacroPoint,
      icon: I.callCheck(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:activate-macro-point', 'Activate MacroPoint'),
    },
    {
      type: 'massAction',
      permissions: [PC.TEL_WRITE],
      action: handleAddReferenceList,
      icon: I.plusRound(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    },
    {
      type: 'massAction',
      action: handleDispatchTelList,
      permissions: [PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE],
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('actions:mass-dispatch', 'Mass Dispatch'),
    },
    {
      type: 'massAction',
      action: handleAddCarrierRate,
      permissions: [PC.CARRIER_RATE_WRITE],
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('titles:mass-add-carrier-rate', 'Mass Add Carrier Rate'),
    },
    {
      permissions: [PC.CARRIER_RATE_WRITE],
      action: handleAddCarrierRateByReport,
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('titles:mass-add-carrier-rate-by-report', 'Mass Add Carrier Rate By Report'),
    },
  ];
};
