import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
// feature configs
import { mailFieldSettings, defaultMailFields, mailValidationSchema } from '../settings';
//////////////////////////////////////////////////

const IntegrationMailConfig = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      flexDirection='column'
      fields={mailFieldSettings}
      handlers={{ handleDisableIntegrationType: () => G.isTrue(props.editMode) }}
    />
    <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={props.closeModal} />
  </form>
);

export const enhance = compose(
  withFormik({
    validationSchema: mailValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultMailFields,
      initialValues,
    ),
  }),
  pure,
);

export default enhance(IntegrationMailConfig);
