import styled from 'styled-components';
// helpers/constants
import * as G from '../../../../helpers';
//////////////////////////////////////////////////////

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  position: absolute;
  justify-content: space-around;
  margin-top: ${({ isPie }: Object) => G.ifElse(isPie, '20px', 0)};
  & > div:first-child {
    z-index: 11;
    width: ${({ isPie }: Object) => G.ifElse(isPie, 'unset', '100%')};
  }
  & svg > g > g:nth-child(2) > g:nth-child(2) > g > g[aria-labelledby^="id"]{
    display: none;
  }
  & > div:nth-child(2) {
    width: 50%;
    overflow-y: auto;
    overflow-x: none;
    & > div {
      height: 100%;
      maxWidth: 600px;
    }
  }
`;

export const TotalWrapper = styled.div`
  width: 100%;
  display: flex;
  height: inherit;
  position: static;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${() => G.getTheme('colors.light.black')};
  & > div:nth-child(2) {
    color: #fa715d;
    border-bottom: 2px solid ${() => G.getTheme('colors.light.grey')};
  }
  & > div:nth-child(3) {
    padding: 16px 0;
    width: max-content;
  }
`;
