import { pure, compose, withHandlers } from 'react-recompose';
//////////////////////////////////////////////////

const withSaveRoute = compose(
  withHandlers({
    handleClickSavePlanner: (props: Object) => (withClose: boolean) => {
      const { currentRoute, currentRouteItems } = props;
      props.validateRouteRequest({
        withClose,
        currentRoute,
        currentRouteItems,
      });
    },
  }),
  pure,
);

export default withSaveRoute;
