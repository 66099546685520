import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const selectOrderDetailsStore = (state: Object) => state.orderDetails;

const makeSelectEvents = () => createSelector(
  selectOrderDetailsStore,
  ({ events }: Object) => R.sortBy(R.prop(GC.FIELD_CLO_EVENT_INDEX), R.values(events)),
);

const makeSelectOrderDetails = () => createSelector(
  selectOrderDetailsStore,
  ({ details }: Object) => details,
);

const makeSelectFirstList = () => createSelector(
  selectOrderDetailsStore,
  ({ lists, firstActiveListName }: Object) => R.pathOr([], [firstActiveListName], lists),
);

const makeSelectSecondList = () => createSelector(
  selectOrderDetailsStore,
  ({ lists, secondActiveListName }: Object) => R.pathOr([], [secondActiveListName], lists),
);

const makeSelectLists = () => createSelector(
  selectOrderDetailsStore,
  ({ lists }: Object) => lists,
);

const makeSelectOrderGuid = () => createSelector(
  selectOrderDetailsStore,
  ({ details }: Object) => R.path([GC.FIELD_GUID], details),
);

const makeSelectLoadConfigs = () => createSelector(
  selectOrderDetailsStore,
  ({ loadConfigs }: Object) => loadConfigs,
);

const makeSelectCharges = () => createSelector(
  selectOrderDetailsStore,
  ({ charges }: Object) => charges,
);

const makeSelectSelectedCustomerRate = () => createSelector(
  selectOrderDetailsStore,
  R.compose(
    R.find(R.pathEq(true, ['selected'])),
    R.pathOr([], ['lists', 'customerRateList']),
  ),
);

const makeSelectActiveListNames = () => createSelector(
  selectOrderDetailsStore,
  ({ activeLists }: Object) => activeLists,
);

const makeSelectActiveLists = () => createSelector(
  selectOrderDetailsStore,
  ({ lists, activeLists }: Object) => R.compose(
    R.indexBy(R.prop('listName')),
    R.map((listName: Object) => ({
      listName,
      pagination: R.path([listName, 'pagination'], lists),
      totalCount: R.path([listName, 'totalCount'], lists),
      list: R.or(R.path([listName, 'list'], lists), R.path([listName], lists)),
    })),
    R.uniq,
  )(activeLists),
);

const makeSelectOrderTotalWeight = () => createSelector(
  selectOrderDetailsStore,
  ({ details }: Object) => {
    const items = R.pathOr([], [GC.FIELD_LOAD_ITEMS], details);
    if (G.isNotNilAndNotEmpty(items)) {
      const total = G.calcItemsTotalWeightWithoutQty(items);

      return G.renameKeys({
        [GC.FIELD_ITEM_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
        [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
      }, total);
    }

    return {};
  },
);

const makeSelectOrderTotalDistance = () => createSelector(
  selectOrderDetailsStore,
  ({ details }: Object) => {
    const distances = R.compose(
      R.filter(G.isNotNilAndNotEmpty),
      R.map(R.prop(GC.FIELD_DISTANCE_CLO)),
      R.pathOr([], [GC.FIELD_LOAD_STOPS]),
    )(details);

    return G.calculateTotalDistance(distances);
  },
);

const makeSelectOrderItemsVolume = () => createSelector(
  selectOrderDetailsStore,
  ({ details }: Object) => G.calculateTotalVolumeWithoutQty(R.pathOr([], ['items'], details)),
);

const makeSelectLoadDocuments = () => createSelector(
  selectOrderDetailsStore,
  ({ documents }: Object) => documents,
);

const makeSelectStatusMessageConfigs = () => createSelector(
  selectOrderDetailsStore,
  ({ loadConfigs: { statusMessageConfigs } }: Object) => statusMessageConfigs,
);

const makeSelectMessageCenter = () => createSelector(
  selectOrderDetailsStore,
  ({ messageCenter }: Object) => messageCenter,
);

const makeSelectMessageCenterList = () => createSelector(
  selectOrderDetailsStore,
  ({ messageCenter }: Object) => {
    const { activeTab } = messageCenter;
    const entities = R.pathOr([], [activeTab], messageCenter);

    return { entities, activeTab };
  },
);

const makeSelectRouteTelConfigs = () => createSelector(
  selectOrderDetailsStore,
  ({ loadConfigs }: Object) => G.getRouteTelConfigsForCreateClo(R.prop('configsByNames', loadConfigs)),
);

const makeSelectMailList = () => createSelector(
  selectOrderDetailsStore,
  ({ mailList }: Object) => mailList,
);

const makeSelectRelatedLoadList = () => createSelector(
  selectOrderDetailsStore,
  ({ relatedLoadList }: Object) => relatedLoadList,
);

const makeSelectRelatedLoadListLoading = () => createSelector(
  selectOrderDetailsStore,
  ({ relatedLoadListLoading }: Object) => relatedLoadListLoading,
);

const makeSelectLinkedLoadList = () => createSelector(
  selectOrderDetailsStore,
  ({ linkedLoadList }: Object) => linkedLoadList,
);

const makeSelectPinnedNote = () => createSelector(
  selectOrderDetailsStore,
  ({ messageCenter }: Object) => R.compose(
    R.find(R.propEq(true, 'pinned')),
    R.prop('noteList'),
  )(messageCenter),
);

const makeSelectNotification = () => createSelector(
  selectOrderDetailsStore,
  ({ notification }: Object) => notification,
);

const makeSelectCustomerInvoiceList = () => createSelector(
  selectOrderDetailsStore,
  ({ lists }: Object) => lists.customerInvoiceList,
);

const makeSelectExpandedLists = () => createSelector(
  selectOrderDetailsStore,
  ({ expandedLists }: Object) => expandedLists,
);

const makeSelectHiddenTabs = () => createSelector(
  selectOrderDetailsStore,
  ({ hiddenTabs }: Object) => hiddenTabs,
);

const makeSelectInvoiceTotals = () => createSelector(
  selectOrderDetailsStore,
  ({ invoiceTotals }: Object) => invoiceTotals,
);

const makeSelectLoadCustomStatuses = () => createSelector(
  selectOrderDetailsStore,
  ({ loadCustomStatuses }: Object) => loadCustomStatuses,
);

const makeSelectOrderTransportationModeGroupingList = () => createSelector(
  selectOrderDetailsStore,
  ({ transportationModeGroupingList }: Object) => transportationModeGroupingList,
);

const makeSelectClaimGeneralDetails = () => createSelector(
  selectOrderDetailsStore,
  ({ claimGeneralDetails }: Object) => claimGeneralDetails,
);

export {
  makeSelectLists,
  makeSelectEvents,
  makeSelectCharges,
  makeSelectMailList,
  makeSelectOrderGuid,
  makeSelectFirstList,
  makeSelectHiddenTabs,
  makeSelectSecondList,
  makeSelectPinnedNote,
  makeSelectLoadConfigs,
  makeSelectActiveLists,
  makeSelectOrderDetails,
  makeSelectNotification,
  makeSelectExpandedLists,
  makeSelectLoadDocuments,
  makeSelectMessageCenter,
  makeSelectInvoiceTotals,
  makeSelectLinkedLoadList,
  makeSelectActiveListNames,
  makeSelectRouteTelConfigs,
  makeSelectRelatedLoadList,
  makeSelectOrderTotalWeight,
  makeSelectOrderItemsVolume,
  makeSelectMessageCenterList,
  makeSelectLoadCustomStatuses,
  makeSelectOrderTotalDistance,
  makeSelectCustomerInvoiceList,
  makeSelectClaimGeneralDetails,
  makeSelectSelectedCustomerRate,
  makeSelectStatusMessageConfigs,
  makeSelectRelatedLoadListLoading,
  makeSelectOrderTransportationModeGroupingList,
};
