import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { CancelSaveFooter } from '../../../components/form-footer';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers
import * as G from '../../../helpers';
// ui
import { CustomButton } from '../../../ui';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpen: (props: Object) => () => {
      const { openModal, closeModal } = props;

      const component = (
        <form onSubmit={closeModal}>
          <h2>Form fields...</h2>
          <CancelSaveFooter
            closeModal={closeModal}
            submitAction={closeModal}
            boxStyles={{ mt: '10px' }}
            saveBtnText={G.getWindowLocale('actions:add', 'Add')}
          />
        </form>
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 320,
          movable: false,
          title: 'Modal title',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const CancelSaveFooterComponent = (props: Object) => {
  const description = '<CancelSaveFooter /> component renders a footer section for forms or modals requiring a combination of cancel and save action buttons.';

  const usage = (
    <ul>
      <li>Parameter `<i>saveBtnText</i>` is a text for the save button.</li>
      <li>Parameter `<i>submitStyles</i>` are styles for the save button.</li>
      <li>Parameter `<i>cancelStyles</i>` are styles for the cancel button.</li>
      <li>Parameter `<i>cancelBtnText</i>` is a text for the cancel button.</li>
      <li>Parameter `<i>boxStyles</i>` are styles for the container box of the footer.</li>
      <li>Parameter `<i>submitDisabled</i>` is a boolean determining if the save button is disabled.</li>
      <li>Parameter `<i>submitAction</i>` is a function that is triggered when the save button is clicked.</li>
      <li>Parameter `<i>cancelAction</i>` is a function that is triggered when the cancel button is clicked.</li>
    </ul>
  );

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='CancelSaveFooter'
      description={description}
      path='src/components/form-footer'
    >
      <CustomButton onClick={props.handleOpen}>Open modal with component `CancelSaveFooter`</CustomButton>
    </ComponentStoryWrapper>
  );
};

export default connect(null, {
  openModal,
  closeModal,
})(enhance(CancelSaveFooterComponent));
