import * as R from 'ramda';
import { put, call, takeLatest, select } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// feature configs
import * as A from './actions';
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
import { closeModal } from '../../../../components/modal/actions';
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
import { getConfigByGroupSaga, getStatusMessagesConfigSaga, getStatusMessagesForConfigSaga } from '../../sagas';
//////////////////////////////////////////////////

// gps integration config
function* getGpsIntegrationConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.eldIntegrationConfigList,
      successAction: A.getGpsIntegrationConfigListSuccess,
      parentSagaName: 'getGpsIntegrationConfigListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getGpsIntegrationConfigListRequest exception');
  }
}

function* createOrUpdateGpsIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.eldIntegrationConfig, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateGpsIntegrationConfigSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateGpsIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateGpsIntegrationConfigRequest exception');
  }
}

function* removeGpsIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.eldIntegrationConfig, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.removeGpsIntegrationConfigSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeGpsIntegrationConfigRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeGpsIntegrationConfigRequest exception');
  }
}

// onboarding integration config
function* getOnboardingIntegrationConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getOnboardingIntegrationConfigListSuccess,
      parentSagaName: 'getOnboardingIntegrationConfigListRequest',
      endpoint: endpointsMap.driverOnboardingIntegrationConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getOnboardingIntegrationConfigListRequest exception');
  }
}

function* createOrUpdateOnboardingIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.driverOnboardingIntegrationConfig, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getOnboardingIntegrationConfigListRequest);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateOnboardingIntegrationConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateOnboardingIntegrationConfigRequest exception');
  }
}

function* removeOnboardingIntegrationConfigRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.removeDriverOnboardingIntegrationConfig(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(getOnboardingIntegrationConfigListRequest);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeOnboardingIntegrationConfigRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeOnboardingIntegrationConfigRequest exception');
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_DRIVER_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getConfigByGroupSaga, GC.DRIVER_CONFIG_GROUP);
    yield call(getStatusMessagesConfigSaga);
    yield call(getStatusMessagesForConfigSaga);
    yield call(getGpsIntegrationConfigListRequest);
    yield call(getOnboardingIntegrationConfigListRequest);
    yield put(closeLoader());
    break;
  }
}

function* driverConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_DRIVER_PAGE, handleVisitPageSaga);
  yield takeLatest(A.removeGpsIntegrationConfigRequest, removeGpsIntegrationConfigRequest);
  yield takeLatest(A.getGpsIntegrationConfigListRequest, getGpsIntegrationConfigListRequest);
  yield takeLatest(A.createOrUpdateGpsIntegrationConfigRequest, createOrUpdateGpsIntegrationConfigRequest);
  // onboarding integration config
  yield takeLatest(A.removeOnboardingIntegrationConfigRequest, removeOnboardingIntegrationConfigRequest);
  yield takeLatest(
    A.createOrUpdateOnboardingIntegrationConfigRequest,
    createOrUpdateOnboardingIntegrationConfigRequest,
  );
}

export default driverConfigWatcherSaga;
