import * as R from 'ramda';
import React from 'react';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers } from 'react-recompose';
// components
import { ChatComponent } from '../../../components/chat';
import { LocalLoader, withLocalLoaderHoc } from '../../../components/local-loader';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
/////////////////////////////////////////////////

const Chat = (props: Object) => {
  const { guid, chatMessages, localLoaderOpen, handleCreateChatMessage } = props;
  const currentUserGuid = G.getAmousCurrentUserGuidFromWindow();

  return (
    <LocalLoader
      width='350px'
      height='400px'
      localLoaderOpen={localLoaderOpen}
      overflow={G.ifElse(localLoaderOpen, 'hidden', 'auto')}
    >
      <ChatComponent
        height='100%'
        width='350px'
        chatMessages={chatMessages}
        currentUserGuid={currentUserGuid}
        headerBgColor={G.getTheme('colors.lightGrey')}
        textareaPermissions={[PC.TEL_CHAT_MESSAGE_WRITE]}
        addNewMessageAction={(data: Object) => handleCreateChatMessage({
          telGuid: guid,
          text: R.path(['message'], data),
          authorUserGuid: currentUserGuid,
        })} />
    </LocalLoader>
  );
};

const enhanceChatMessages = compose(
  withHandlers({
    handleGetChatMessages: (props: Object) => async () => {
      try {
        const { guid, setChatMessages, handleOpenLocalLoader, handleCloseLocalLoader } = props;
        handleOpenLocalLoader();
        const options = {
          params: { loadGuid: guid },
        };
        const res = await sendRequest('get', endpointsMap.telChatMessageList, options);
        const { data, status } = res;
        if (G.isResponseSuccess(status)) {
          setChatMessages(data);
        } else {
          G.handleFailResponseSimple(res);
        }
        handleCloseLocalLoader();
      } catch (error) {
        props.handleCloseLocalLoader();
        G.catchSendRequestSimple(error, 'handleGetChatMessages');
      }
    },
  }),
  withHandlers({
    handleCreateChatMessage: (props: Object) => async (data: Object) => {
      try {
        const { handleGetChatMessages, handleOpenLocalLoader, handleCloseLocalLoader } = props;
        handleOpenLocalLoader();
        const res = await sendRequest('post', endpointsMap.telChatMessage, { data });
        const { status } = res;
        if (G.isResponseSuccess(status)) {
          handleGetChatMessages();
        } else {
          G.handleFailResponseSimple(res);
        }
        handleCloseLocalLoader();
      } catch (error) {
        props.handleCloseLocalLoader();
        G.catchSendRequestSimple(error, 'handleCreateChatMessage');
      }
    },
  }),
);

const enhance = compose(
  withLocalLoaderHoc,
  withState('chatMessages', 'setChatMessages', []),
  enhanceChatMessages,
  lifecycle({
    componentDidMount() {
      const { handleGetChatMessages } = this.props;
      handleGetChatMessages();
    },
  }),
  pure,
);

export default enhance(Chat);
