import React from 'react';
// components
import { QuickFilter2 } from '../../../components/quick-filter';
// feature components-story
import ComponentStoryWrapper from '../component-story-wrapper';
//////////////////////////////////////////////////

const QuickFilter2Component = () => {
  const description = '<QuickFilter2 /> serves as an input handler tailored for data filtering, offering adaptable behavior controlled through its props.';

  const usage = (
    <ul>
      <li>Parameter `<i>width</i>` is the width of the input field.</li>
      <li>Parameter `<i>label</i>` is a label text for the input field.</li>
      <li>Parameter `<i>handleSetFilter</i>` is a function to set the filter value.</li>
      <li>Parameter `<i>placeholder</i> is the placeholder text for the input field.</li>
      <li>Parameter `<i>endIconName</i>` is an icon displayed at the end of the input field.</li>
      <li>Parameter `<i>inputWrapperStyles</i>` is a styling properties for the input wrapper.</li>
    </ul>
  );

  const handleSetFilter = (value: string) => {
    window.alert(`Filter value is ${value}`);
  };

  return (
    <ComponentStoryWrapper
      usage={usage}
      name='QuickFilter2'
      description={description}
      path='src/components/quick-filter'
    >
      <QuickFilter2
        width={200}
        label='Filter by Name'
        endIconName='globalSearch2'
        handleSetFilter={handleSetFilter}
      />
    </ComponentStoryWrapper>
  );
};

export default QuickFilter2Component;
