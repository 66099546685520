import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  terminalIntegrationFieldSettings,
  defaultTerminalIntegrationFields,
  terminalIntegrationValidationSchemaObject,
} from '../settings';
//////////////////////////////////////////////////

const veracoreFields = [
  GC.FIELD_INTEGRATION_TYPE,
  GC.FIELD_ENABLED,
  GC.FIELD_USERNAME_CAMEL_CASE,
  GC.FIELD_PASSWORD,
  GC.FIELD_DOMAIN,
  GC.FIELD_OWNER_ID,
  GC.FIELD_SYSTEM_ID,
];

const fieldsToPick = ({ integrationType }: Object) => {
  const map = {
    [GC.TERMINAL_INTEGRATION_TYPE_VERACORE]: veracoreFields,
  };

  return R.pathOr([], [integrationType], map);
};

const enhance = compose(
  withFormik({
    validationSchema: terminalIntegrationValidationSchemaObject,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultTerminalIntegrationFields,
      initialValues,
    ),
  }),
  pure,
);

const TerminalIntegrationForm = (props: Object) => (
  <Box width={270}>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...G.getFormikProps(props)}
        justifyContent='space-between'
        optionsForSelect={props.optionsForSelect}
        handlers={{ disableIntegrationType: () => props.isEditMode }}
        fields={R.values(R.pick(fieldsToPick(props.values), terminalIntegrationFieldSettings))}
      />
      <FormFooter boxStyles={{ pt: 10 }} />
    </form>
  </Box>
);

export default enhance(TerminalIntegrationForm);
