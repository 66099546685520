import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs, withAsyncRolesByUserType } from '../../../hocs';
// feature fleet
import { createFleetItemRequest } from '../actions';
import SharedComponentForm from './shared-component-form';
import PayrollAccessorialForm from './payroll-accessorial-form';
import { createFormSettingsMap } from '../settings/field-settings';
//////////////////////////////////////////////////

const formEnhance = compose(
  withFormik({
    mapPropsToValues: ({ defaultFields }: Object) => defaultFields,
    validationSchema: ({ validationSchema }: Object) => validationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { fromVendorPage, createFleetItemRequest } = props;

      createFleetItemRequest({ values, fromVendorPage });
    },
  }),
  pure,
);

const getOptionsForSelect = ({ rolesGuids, asyncConfigs }: Object) => {
  const truckType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.TRUCK_TRUCK_TYPE,
    true,
  );
  const trailerType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.TRAILER_TRAILER_TYPE,
    true,
  );

  return { rolesGuids, truckType, trailerType };
};

const CreateForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      {...getOptionsForSelect(props)}
      fields={props.fieldSettings}
      fieldsWrapperStyles={{ px: '0px', pt: 15, flexDirection: 'column' }}
    />
    <FormFooter2 />
  </form>
));

const createDriverEnhance = compose(
  withProps(() => {
    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const settings = G.getPropFromObject('driver', createFormSettingsMap);

    return R.assoc('currentBranchGuid', currentBranchGuid, settings);
  }),
  withAsyncRolesByUserType({ [GC.FIELD_ROLE_ROLE_TYPE]: GC.USER_ROLE_TYPE_DRIVER }),
);

const createTruckEnhance = compose(
  withProps(() => {
    const configsNamesArray = R.of(Array, GC.TRUCK_TRUCK_TYPE);
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const settings = G.getPropFromObject('truck', createFormSettingsMap);

    return {
      ...settings,
      branchGuid,
      configsNamesArray,
    };
  }),
  withAsyncConfigs,
);

const createTrailerEnhance = compose(
  withProps(() => {
    const configsNamesArray = R.of(Array, GC.TRAILER_TRAILER_TYPE);
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const settings = G.getPropFromObject('trailer', createFormSettingsMap);

    return {
      ...settings,
      branchGuid,
      configsNamesArray,
    };
  }),
  withAsyncConfigs,
);

const createVendorEnhance = compose(
  withProps(() => G.getPropFromObject('vendor', createFormSettingsMap)),
);

export const CreateTruck = createTruckEnhance(CreateForm);
export const CreateDriver = createDriverEnhance(CreateForm);
export const CreateVendor = createVendorEnhance(CreateForm);
export const CreateTrailer = createTrailerEnhance(CreateForm);

const CreateFleetItemByReportType = (props: Object) => {
  const { reportType, accessorialConfigs, createFleetItemRequest } = props;

  const componentMap = {
    [GC.FLEET_TRUCK_REPORT]: <CreateTruck {...props} />,
    [GC.FLEET_DRIVER_REPORT]: <CreateDriver {...props} />,
    [GC.FLEET_VENDOR_REPORT]: <CreateVendor {...props} />,
    [GC.FLEET_TRAILER_REPORT]: <CreateTrailer {...props} />,
    [GC.FLEET_SHARED_COMPONENT_REPORT]: (
      <SharedComponentForm
        {...props}
        submitAction={createFleetItemRequest}
        branchGuid={G.getAmousCurrentBranchGuidFromWindow()}
        configsNamesArray={[GC.FLEET_COMPONENT_TYPE, GC.FLEET_COMPONENT_MANUFACTURER]}
      />
    ),
    [GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT]: (
      <PayrollAccessorialForm
        {...props}
        {...accessorialConfigs}
        submitAction={createFleetItemRequest}
        branchGuid={G.getAmousCurrentBranchGuidFromWindow()}
      />
    ),
    [GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT]: (
      <PayrollAccessorialForm
        {...props}
        {...accessorialConfigs}
        isVendor={true}
        submitAction={createFleetItemRequest}
        branchGuid={G.getAmousCurrentBranchGuidFromWindow()}
      />
    ),
  };

  return G.getPropFromObject(reportType, componentMap);
};

export default connect(null, { createFleetItemRequest })(CreateFleetItemByReportType);
