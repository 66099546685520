import React from 'react';
import * as R from 'ramda';
import { getIn } from 'formik';
import 'draft-js/dist/Draft.css';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// forms
import { Error, LabelBox } from '../../../../../forms/formik/fieldset2/ui';
// helpers/constants
import * as G from '../../../../../helpers';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../../../hocs/common';
// ui
import { Box, RelativeBox, EditorWrapper } from '../../../../../ui';
////////////////////////////////////////////////

const getLabel = (label: Array) => G.ifElse(
  G.isNilOrEmpty(G.getWindowLocaleWithoutDefault(R.head(label))),
  R.last(label),
  G.getWindowLocale(R.head(label)),
);

const getInputValue = (editorState: Object) => {
  if (G.isNotNilAndNotEmpty(editorState)) {
    return R.trim(editorState.getCurrentContent().getPlainText());
  }
};

const getNewEditorState = (props: Object) => {
  const { value, onChange, fieldName, shouldCustomChange } = props;

  const { entityMap, contentBlocks } = htmlToDraft(value);

  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const newEditorState = EditorState.createWithContent(contentState);

  if (G.isTrue(shouldCustomChange)) onChange({ fieldName, value: newEditorState });

  return newEditorState;
};

const enhance = compose(
  withState('inputValue', 'setInputValue', ''),
  withState('editorState', 'setEditorState', (props: Object) => {
    const { setInputValue } = props;

    const newEditorState = getNewEditorState(props);

    setInputValue(getInputValue(newEditorState));

    return newEditorState;
  }),
  withHandlers({
    handleOverrideEditorState: (props: Object) => () => {
      const { setEditorState, setInputValue } = props;

      const newEditorState = getNewEditorState(props);

      setEditorState(newEditorState);
      setInputValue(getInputValue(newEditorState));
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'value',
    callbackName: 'handleOverrideEditorState',
  }),
  pure,
);

export const EditorComponent = enhance((props: Object) => {
  const {
    errors,
    touched,
    onChange,
    fieldName,
    inputValue,
    editorState,
    setInputValue,
    wrapperStyles,
    setEditorState,
    setFieldTouched,
    shouldCustomChange,
  } = props;

  const { width, error, label, isRequired, inputWrapperStyles } = wrapperStyles;

  const labelToUse = getLabel(label);

  const hasError = G.isAllTrue(
    G.isTrue(isRequired),
    getIn(touched, fieldName),
    R.or(G.isNilOrEmpty(inputValue), G.isNotNilAndNotEmpty(getIn(errors, fieldName))),
  );

  return (
    <RelativeBox {...G.spreadUiStyles(inputWrapperStyles)}>
      <LabelBox
        left={5}
        top={-13}
        fontSize={11}
        color={G.getTheme('colors.darkGrey')}
      >
        <Box className={G.ifElse(isRequired, 'required', 'not-required')}>
          {labelToUse}
        </Box>
      </LabelBox>
      <EditorWrapper
        hasError={R.or(hasError, error)}
        css={inputWrapperStyles.additionalStyles}
      >
        <Editor
          stripPastedStyles={true}
          editorState={editorState}
          onEditorStateChange={(value: string) => {
            if (G.isNilOrFalse(R.path([fieldName], touched))) setFieldTouched(fieldName, true);

            setEditorState(value);
            setInputValue(getInputValue(value));

            if (G.isNilOrFalse(R.path([fieldName], touched))) setFieldTouched(fieldName, true);

            setEditorState(value);

            if (shouldCustomChange) onChange({ value, fieldName });
          }}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'], //eslint-disable-line
            link: { inDropdown: true },
            list: { inDropdown: true },
            inline: { inDropdown: true },
            history: { inDropdown: true },
            textAlign: { inDropdown: true },
          }}
        />
      </EditorWrapper>
      {error && <Error title={error} width={width}>{error}</Error>}
    </RelativeBox>
  );
});
