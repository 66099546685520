import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const renderEndIcon = ({ isReport, itemList, singleItem }: Object) => {
  if (isReport) return null;

  const icon = I.plusRound(whiteColor);

  if (R.or(R.isNil(singleItem), R.and(G.isTrue(singleItem), G.isNilOrEmpty(itemList)))) return icon;

  return null;
};

const FormGroupTitle = (props: Object) => {
  const {
    text,
    isOpened,
    itemList,
    isReport,
    singleItem,
    totalCount,
    handleAddEntity,
    handleToggleFormGroupTable,
    AdditionalFormGroupTitleComponent,
  } = props;

  return (
    <Flex
      pl={15}
      pr={16}
      bg='mainBlue'
      color='white'
      minHeight={30}
    >
      <Flex width='max-content'>
        <Box px='5px' width='max-content'>{text}{R.isNotNil(totalCount) && ` (${totalCount})`}</Box>
        <Box mr='5px' cursor='pointer' onClick={() => handleAddEntity()}>
          {renderEndIcon({ isReport, itemList, singleItem })}
        </Box>
        {
          R.and(isOpened, R.isNotNil(AdditionalFormGroupTitleComponent)) &&
          <AdditionalFormGroupTitleComponent {...props} />
        }
      </Flex>
      <Box width='100%' onClick={handleToggleFormGroupTable}>
        <Box ml='auto' width='max-content' transform={G.ifElse(isOpened, 'rotate(180deg)')}>
          {I.arrowDownSimple(whiteColor)}
        </Box>
      </Box>
    </Flex>
  );
};

export default FormGroupTitle;
