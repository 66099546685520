import * as R from 'ramda';
import React from 'react';
import { withHandlers } from 'react-recompose';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const withAddTrailer = withHandlers({
  handleClickAddTrailer: (props: Object) => (data: Object) => {
    const { openModal, closeModal, addTrailersToStop, trailersInService, trailersInServiceOptions } = props;

    const { tel, event, pickedUp } = data;

    const { droppedTrailerGuids, pickedUpTrailerGuids } = event;

    const options = R.reject(
      (item: Object) => {
        const { value } = item;

        if (G.isTrue(pickedUp)) return R.includes(value, pickedUpTrailerGuids);

        return R.includes(value, droppedTrailerGuids);
      },
      trailersInServiceOptions,
    );

    const component = (
      <SelectDropdownForm
        isMulti={true}
        options={options}
        cancelAction={closeModal}
        fieldLabel={G.getWindowLocale('titles:trailers', 'Trailers')}
        submitAction={(guids: Array) => {
          const trailers = R.map(
            (guid: string) => R.find(R.propEq(guid, GC.FIELD_GUID), trailersInService),
            guids,
          );
          addTrailersToStop({ tel, event, guids, trailers, pickedUp });
          closeModal();
        }}
      />
    );
    const modal = { p: 15, component, options: {} };
    openModal(modal);
  },
});
