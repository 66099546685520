import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Error, Label } from '../../../forms';
// ui
import { Box, RelativeBox, ReactSelect } from '../../../ui';
// feature charts
import { MultiValue } from '../ui';
//////////////////////////////////////////////////


const renderMultiValueComponent = (props: Object) => (
  <MultiValue useGradient={R.gt(R.length(props.data.label), 18)}>
    <span {...props.removeProps}>x</span>
    <span />
    <span title={props.data.label}>{props.data.label}</span>
  </MultiValue>
);

export const renderMultiSelect = ({
  zI,
  width,
  label,
  input,
  margin,
  options,
  display,
  labelWidth,
  errorMargin,
  placeholder,
  labelMargin,
  selectAction,
  labelPosition,
  labelFontSize,
  errorFontSize,
  selectedRoles,
  meta: { error, touched },
}: Object) => (
  <RelativeBox
    zIndex={zI}
    margin={margin}
    display={display}
  >
    {
      G.isNotNil(labelPosition)
      && (
        <Label pl='15px' m={labelMargin} width={labelWidth} fontSize={labelFontSize}>
          {label}
        </Label>
      )
    }
    <Box width={width}>
      <ReactSelect
        isMulti={true}
        name={input.name}
        options={options}
        isClearable={false}
        value={selectedRoles}
        onChange={selectAction}
        placeholder={placeholder}
        hasError={R.and(touched, error)}
        components={{
          MultiValue: renderMultiValueComponent,
        }}
        additionalStyles={{
          control: (baseStyles: Object) => ({
            ...baseStyles,
            minHeight: 30,
            borderRadius: 2,
            height: 'max-content',
            borderColor: G.getTheme('colors.light.grey'),
          }),
        }}
      />
    </Box>
    {touched && error && <Error m={errorMargin} fontSize={errorFontSize}>{error}</Error>}
  </RelativeBox>
);
