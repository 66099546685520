import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const setCarrierGuid = createAction('setCarrierGuid');
const getCarrierRateRequestReportDataRequest = createAction('getReportDataRequest');

export {
  // report
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  setCarrierGuid,
  getCarrierRateRequestReportDataRequest,
};
