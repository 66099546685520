import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
import NoteFormComponent from '../../forms/note-form';
import ChatMessageFormComponent from '../../forms/chat-message-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// feature dispatch-details new
import { makeSelectMessageCenter, makeSelectMessageCenterList } from '../selectors';
import {
  updateLoadNoteRequest,
  removeLoadNoteRequest,
  createLoadNoteRequest,
  updateLoadChatMessageRequest,
  changeMessageCenterActiveTab,
  createLoadChatMessageRequest,
  removeLoadChatMessageRequest } from '../actions';
//////////////////////////////////////////////////

const getRowActionsOptions = (props: Object) => {
  const {
    entity,
    activeTab,
    handlePinMessage,
    handleEditMessage,
    handleRemoveMessage,
  } = props;

  const iconColor = G.getTheme('colors.light.blue');
  const editIcon = I.pencil(iconColor);
  const removeIcon = I.remove(iconColor);
  const editTxtLocale = () => G.getWindowLocale('actions:edit', 'Edit');
  const deleteTxtLocale = () => G.getWindowLocale('actions:delete', 'Delete');

  if (R.equals(activeTab, 'noteList')) {
    const pinIconColor = G.getThemeByCond(
      entity.pinned,
      'colors.light.blue',
      'colors.dark.grey',
    );
    const pinTxtLocale = G.ifElse(
      entity.pinned,
      G.getWindowLocale('actions:unpin', 'Unpin'),
      G.getWindowLocale('actions:pin', 'Pin'),
    );

    return [
      {
        permissions: null,
        frontIcon: editIcon,
        text: editTxtLocale(),
        action: handleEditMessage,
      },
      {
        permissions: null,
        frontIcon: removeIcon,
        text: deleteTxtLocale(),
        action: handleRemoveMessage,
      },
      {
        permissions: null,
        text: pinTxtLocale,
        action: handlePinMessage,
        frontIcon: I.pinNew(pinIconColor),
      },
    ];
  }

  const chatPermissions = [PC.TEL_ADMINISTRATION_EXECUTE];

  return [
    {
      frontIcon: editIcon,
      text: editTxtLocale(),
      action: handleEditMessage,
      permissions: chatPermissions,
    },
    {
      frontIcon: removeIcon,
      text: deleteTxtLocale(),
      action: handleRemoveMessage,
      permissions: chatPermissions,
    },
  ];
};

const messageCenterRowActionsEnhance = compose(
  connect(
    null,
    {
      updateLoadNoteRequest,
      removeLoadNoteRequest,
      updateLoadChatMessageRequest,
      removeLoadChatMessageRequest,
    },
  ),
  withHandlers({
    handleEditNote: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        updateLoadNoteRequest,
      } = props;

      const component = (
        <NoteFormComponent
          initialValues={entity}
          closeModal={closeModal}
          handleActionLoadNote={updateLoadNoteRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: '410px',
          height: 'max-content',
          title: G.getWindowLocale('actions:edit-note', 'Edit Note'),
        },
      };

      openModal(modal);
    },
    handleEditChatMessage: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        updateLoadChatMessageRequest,
      } = props;

      const component = (
        <ChatMessageFormComponent
          initialValues={entity}
          closeModal={closeModal}
          handleActionLoadChatMessage={updateLoadChatMessageRequest}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          width: '380px',
          height: 'max-content',
          title: G.getWindowLocale('titles:edit-message', 'Edit Message'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handlePinMessage: (props: Object) => () => {
      const { entity, closeFixedPopup, updateLoadNoteRequest } = props;
      const { pinned } = entity;

      G.callFunction(closeFixedPopup);
      const requestData = R.assoc('pinned', R.not(pinned), entity);

      updateLoadNoteRequest(requestData);
    },
    handleEditMessage: ({ activeTab, handleEditNote, handleEditChatMessage }: Object) => () => {
      if (R.equals(activeTab, 'noteList')) {
        handleEditNote();
      } else {
        handleEditChatMessage();
      }
    },
    handleRemoveMessage: (props: Object) => () => {
      const {
        entity,
        activeTab,
        openModal,
        removeLoadNoteRequest,
        removeLoadChatMessageRequest,
      } = props;

      const noteTxtLocale = G.getWindowLocale(
        'messages:remove-ref-confirmation-text',
        'Are you sure you want delete this note?',
      );
      const chatTxtLocale = G.getWindowLocale(
        'messages:chat-messages:remove-ref-confirmation-text',
        'Are you sure you want delete this chat message?',
      );
      const getTextLocale = G.ifElse(
        R.equals(activeTab, 'noteList'),
        noteTxtLocale,
        chatTxtLocale,
      );
      const action = () => G.ifElse(
        R.equals(activeTab, 'noteList'),
        removeLoadNoteRequest,
        removeLoadChatMessageRequest,
      )(entity[GC.FIELD_GUID]);
      const component = <ConfirmComponent textLocale={getTextLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              action,
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const MessageCenterRowActions = messageCenterRowActionsEnhance((props: Object) => (
  <ActionsElement version={2} options={getRowActionsOptions(props)} />
));

const messageCenterListMapStateToProps = (state: Object) => createStructuredSelector({
  messageCenterList: makeSelectMessageCenterList(state),
});

const messageCenterMapStateToProps = (state: Object) => createStructuredSelector({
  messageCenter: makeSelectMessageCenter(state),
});

export const withLoadMessageCenterHeaderActions = connect(
  messageCenterMapStateToProps, { changeMessageCenterActiveTab },
);

export const withLoadMessageCenterActions = compose(
  connect(
    messageCenterListMapStateToProps,
    {
      createLoadNoteRequest,
      createLoadChatMessageRequest,
    },
  ),
  withFixedPopover,
  withHandlers({
    handleAddMessage: (props: Object) => ({ message }: Object) => {
      const {
        load,
        createLoadNoteRequest,
        createLoadChatMessageRequest,
        messageCenterList: { activeTab },
      } = props;

      if (R.equals(activeTab, 'chatMessageList')) {
        const currentUserGuid = G.getAmousCurrentUserGuidFromWindow();
        const data = {
          [GC.FIELD_TEXT]: message,
          authorUserGuid: currentUserGuid,
          [GC.FIELD_TEL_GUID]: G.getGuidFromObject(load),
        };

        return createLoadChatMessageRequest(data);
      }

      const data = {
        pinned: false,
        [GC.FIELD_TEXT]: message,
        [GC.FIELD_LOAD_GUID]: G.getGuidFromObject(load),
      };

      return createLoadNoteRequest(data);
    },
    handleClickEditIcon: (props: Object) => (event: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <MessageCenterRowActions
            entity={entity}
            load={props.load}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            handlePinMessage={props.handlePinMessage}
            handleEditMessage={props.handleEditMessage}
            handleRemoveMessage={props.handleRemoveMessage}
            activeTab={R.path(['messageCenterList', 'activeTab'], props)}
          />
        ),
      })
    ),
  }),
  pure,
);
