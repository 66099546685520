import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import SingleTextareaForm from '../../../forms/forms/single-textarea-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const enhance = compose(
  withHandlers({
    handleFormSubmit: (props: Object) => (values: any) => (
      props.handleActionUpdateLoad({ [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: values })
    ),
  }),
  pure,
);

const validateSpecialInstruction = Yup.object().shape({
  value: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 2000))
    .max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
});

const SpecialInstructionsForm = enhance((props: Object) => {
  const { closeModal, initialValues, handleFormSubmit } = props;

  return (
    <SingleTextareaForm
      closeModal={closeModal}
      inputStyles={{ height: 150 }}
      submitHandler={handleFormSubmit}
      labelLocale='titles:special-instructions'
      validationSchema={validateSpecialInstruction}
      initialValues={{ value: R.path([GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS], initialValues) }}
    />
  );
});

export default SpecialInstructionsForm;
