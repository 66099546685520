import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// features
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// component
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// report-common
import { generateDefaultReport } from '../../../report-common';
// ui
import { RelativeBox, ActionButton } from '../../../ui';
// feature dispatch-planner-events
import * as H from '../helpers';
import CustomTitle from './custom-title';
import Confirm from '../components/confirm';
import SelectLoadsForm from './select-loads-form';
import { pageSizes } from '../settings/page-settings';
import { ADDED_TO_INTER_TERMINAL } from '../constants';
import { cloEventTableSettings } from '../settings/table-settings';
import CrossDockLocationForm from '../components/cross-dock-location-form';
import { filterProps, getColumnSettings } from '../settings/clo-events-table-settings';
import {
  selectCloEvent,
  addTelToPlanner,
  toggleHidePlanned,
  setCloEventsReports,
  getCloEventsNextPage,
  setGlobalFilterValue,
  toggleCloEventDetails,
  setCurrentCloEventReport,
  cleanCloEventQuickFilter,
  uncheckAndRemoveCloEvent,
  getCloEventDetailsRequest,
  setCloEventsTableTitleSort,
  selectCloEventForTelRequest,
  setCloEventsTableTitleFilter,
  createCloEventsReportRequest,
  updateCloEventsReportRequest,
  setCloEventQuickFilterParams,
  resetCloEventListAndPagination,
  changeDefaultCloEventReportRequest,
  getTelForListAndAddToPlannerRequest,
  addCloEventToInterTerminalTelRequest,
  refreshCloEventListWithSelectedEvents,
} from '../actions';
import {
  makeSelectTelList,
  makeSelectHidePlanned,
  makeSelectCloEventList,
  makeSelectCurrentRoute,
  makeSelectCloEventTotal,
  makeSelectCloEventReport,
  makeSelectGlobalFilterValue,
  makeSelectCurrentRouteItems,
  makeSelectCloEventPagination,
  makeSelectCloEventListLoading,
  makeSelectCloEventFilterParams,
  makeSelectCrossDockLocationList,
  makeSelectAvailableCloEventReports,
  makeSelectCloEventsTableTitleFilters,
  makeSelectCloEventsTableTitleSortValues,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleSetGlobalFilter: (props: Object) => (value: string) => {
      if (R.equals(props.globalFilterValue, value)) return;
      props.setGlobalFilterValue(value);
      props.refreshCloEventListWithSelectedEvents();
    },
    handleAddTerminalToTelFromCrossDock: (props: Object) => (data: Object) => {
      const { selectedValue } = data;
      const {
        openModal,
        closeModal,
        crossDocks,
        currentRoute,
        addCloEventToInterTerminalTelRequest } = props;
      const telTerminalDrop = R.compose(
        R.find((telEvent: Object) => R.and(
          G.isStopDrop(telEvent),
          G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
        )),
        R.values,
        R.path([GC.SYSTEM_LIST_TELS, R.prop(GC.FIELD_VALUE, selectedValue), GC.FIELD_LOAD_STOPS]),
      )(currentRoute);
      if (G.isNotNilAndNotEmpty(telTerminalDrop)) {
        addCloEventToInterTerminalTelRequest(data);
        closeModal();
        return;
      }
      const options = G.addEmptyOptionToDropDown(G.getLocationOptions(R.values(crossDocks)));
      const selectLocation = (templateId: string) => {
        addCloEventToInterTerminalTelRequest(R.assoc(GC.FIELD_TEMPLATE_ID, templateId, data));
        closeModal();
      };
      const component = (
        <CrossDockLocationForm
          singleSelect={true}
          closeModal={closeModal}
          locationOptions={options}
          setLocations={selectLocation} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-location', 'Select Locations'),
        },
      };
      openModal(modal);
    },
  }),
  withHandlers({
    handleListRequest: ({ getCloEventsNextPage }: Object) => (isInitial: boolean = false) =>
      getCloEventsNextPage(isInitial),
    getQuickFilteredListRequest: (props: Object) => () => {
      const { resetCloEventListAndPagination, refreshCloEventListWithSelectedEvents } = props;

      resetCloEventListAndPagination();
      refreshCloEventListWithSelectedEvents();
    },
    handleSelectClo: (props: Object) => (eventGuid: string, event: Object) => {
      const {
        telList,
        openModal,
        closeModal,
        currentRoute,
        addTelToPlanner,
        uncheckAndRemoveCloEvent,
        selectCloEventForTelRequest,
        getTelForListAndAddToPlannerRequest } = props;
      const { cloGuid, telGuid } = event;
      let title;
      let component;
      if (event.selected) {
        title = G.getWindowLocale('messages:remove-stop', 'Are you sure you want to remove stop?');
        component = (
          <Confirm
            submitData={event}
            closeModal={closeModal}
            handleSubmitAction={uncheckAndRemoveCloEvent}
            cancelText={G.getWindowLocale('actions:cancel', 'Cancel')}
            actionText={G.getWindowLocale('actions:confirm', 'Confirm')}
            text={G.getWindowLocale(
              'titles:all-clo-events-will-be-removed',
              'All CLO events will be removed from route',
            )} />
        );
      } else if (G.isNotNilAndNotEmpty(telGuid)) {
        const notTelInList = R.compose(
          R.isNil,
          R.find(R.propEq(telGuid, GC.FIELD_GUID)),
        )(telList);
        if (notTelInList) {
          return getTelForListAndAddToPlannerRequest(telGuid);
        }
        return addTelToPlanner(telGuid);
      } else {
        const tels = R.values(R.path(['currentRoute', 'tels'], props));
        if (R.equals(R.path(['length'], tels), 1)) {
          return selectCloEventForTelRequest({
            returnObject: { cloGuid, eventGuid },
            selectedValue: { value: R.path([GC.FIELD_GUID], R.head(tels)) },
          });
        }
        title = G.getWindowLocale('titles:choose-tel-for-stop', 'Please, choose TEL for selected stop');
        component = (
          <SelectLoadsForm
            initialValue=''
            closeModal={closeModal}
            returnObject={{ cloGuid, eventGuid }}
            options={H.createTelOptions(currentRoute)}
            handleSubmitAction={selectCloEventForTelRequest}
          />
        );
      }
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 'min-content',
          height: 'max-content',
        },
      };
      openModal(modal);
    },
    handleToggleDetails: ({ toggleCloEventDetails }: Object) => ({ guid, cloGuid }: Object) => (
      toggleCloEventDetails({ guid, cloGuid })
    ),
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setReport,
        selectedReport,
        requestPending,
        createCloEventsReportRequest,
        updateCloEventsReportRequest,
        refreshCloEventListWithSelectedEvents,
      } = props;

      const report = R.or(selectedReport, generateDefaultReport(GC.CLO_EVENT_REPORT));

      const component = (
        <EditReport
          fields={fields}
          usedReport={report}
          setReport={setReport}
          requestPending={requestPending}
          createReportRequest={createCloEventsReportRequest}
          updateReportRequest={updateCloEventsReportRequest}
          onReportSet={refreshCloEventListWithSelectedEvents}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleCleanFilter: (props: Object) => () => {
      props.cleanCloEventQuickFilter();
      props.refreshCloEventListWithSelectedEvents();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(
        R.propEq(reportGuid, 'guid'),
        props.reportList,
      );
      props.setReport(selectedReport);
      props.refreshCloEventListWithSelectedEvents();
    },
    handleToggleHidePlanned: (props: Object) => () => {
      props.toggleHidePlanned();
      props.refreshCloEventListWithSelectedEvents();
    },
    handleAddToInterTerminal: (props: Object) => (event: Object) => {
      const tels = R.values(R.path(['currentRoute', 'tels'], props));
      if (R.equals(R.path(['length'], tels), 1)) {
        return props.handleAddTerminalToTelFromCrossDock({
          returnObject: { event },
          selectedValue: { value: R.path([GC.FIELD_GUID], R.head(tels)) },
        });
      }
      const title = G.getWindowLocale('titles:choose-tel-for-stop', 'Please, choose TEL for selected stop');
      const component = (
        <SelectLoadsForm
          initialValue=''
          returnObject={{ event }}
          closeModal={props.closeModal}
          options={H.createTelOptions(props.currentRoute)}
          handleSubmitAction={props.handleAddTerminalToTelFromCrossDock}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 'min-content',
          height: 'max-content',
        },
      };
      props.openModal(modal);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    maxHeight,
    totalCount,
    pagination,
    currentRoute,
    selectedReport,
    handleSelectClo,
    titleSortValues,
    tableTitleFilters,
    handleToggleDetails,
    getCloEventsNextPage,
    handleTableTitleFilter,
    handleAddToInterTerminal,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  let report = selectedReport;

  if (G.isNotNil(selectedReport)) {
    report = R.assoc(
      'fields',
      R.prepend({
        p: '0px',
        sequence: 0,
        freezed: true,
        name: ADDED_TO_INTER_TERMINAL,
      }, selectedReport.fields),
      selectedReport,
    );
  }
  const itemListToUse = R.map(
    (event: Object) => {
      if (R.isNil(event.details)) return event;
      const matchEvent = R.find(
        R.eqProps(GC.FIELD_GUID, event),
        event.details,
      );

      const isInPlanner = H.isInPlanner(currentRoute, R.prop(GC.FIELD_STOP_ITEM_IDS, matchEvent));

      return R.assoc('isInPlanner', isInPlanner, event);
    },
    itemList,
  );

  const data = {
    report,
    loading,
    allChecked,
    totalCount,
    pagination,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    itemList: itemListToUse,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    onOptionClick: handleSelectClo,
    columnSettings: getColumnSettings({ handleAddToInterTerminal }),
    tableSettings: R.mergeRight(cloEventTableSettings, { maxHeight }),
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(filterProps)),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getCloEventsNextPage,
    ),
  };

  return <Table {...data} />;
};

const createOrderEnhance = compose(
  connect(null, { setExpandedContainerOptions }),
  withHandlers({
    handleOpenCreateNewDO: ({ setExpandedContainerOptions }: Object) => () =>
      setExpandedContainerOptions({ opened: true, componentType: GC.PAGE_NEW_DO }),
  }),
  pure,
);

const CreateOrder = createOrderEnhance(({ handleOpenCreateNewDO }: Object) => (
  <ActionButton
    ml={15}
    order={2}
    height={30}
    width={130}
    borderRadius='3px'
    textTransform='uppercase'
    onClick={() => handleOpenCreateNewDO()}
  >
    {G.getWindowLocale('actions:create-order', 'Create Order')}
  </ActionButton>
));

const renderTitlePanel = (props: Object) => {
  const {
    totalCount,
    hidePlanned,
    selectedReport,
    globalFilterValue,
    setCloEventsReports,
    handleSetGlobalFilter,
    handleToggleHidePlanned,
    updateCloEventsReportRequest,
  } = props;

  const options = [
    {
      width: 80,
      value: false,
      name: G.getWindowLocale('actions:show-planned', 'Show Planned'),
    },
    {
      width: 80,
      value: true,
      name: G.getWindowLocale('actions:hide-planned', 'Hide Planned'),
    },
  ];

  return (
    <TitlePanel
      {...props}
      pt='0px'
      version={2}
      loading={false} // HACK: some UI error block title panel - remove after fix
      hideFilterInfo={true}
      useExactFilters={true}
      name='clo-event-report'
      filterProps={filterProps}
      withoutQuickFilter={true}
      type={GC.CLO_EVENT_REPORT}
      usedReport={selectedReport}
      hiddenRightFilterInfo={false}
      setReports={setCloEventsReports}
      height={pageSizes.tableHeaderHeight}
      additionalComponent={<CreateOrder />}
      updateReportRequest={updateCloEventsReportRequest}
      customTitleComponent={
        <CustomTitle
          count={totalCount}
          withMultiSwitch={true}
          globalFilterValue={globalFilterValue}
          handleSetGlobalFilter={handleSetGlobalFilter}
          text={G.getWindowLocale('titles:clo-events', 'CLO Events')}
          multiSwitchProps={{
            options,
            onSwitch: handleToggleHidePlanned,
            selectedOptionIndex: G.ifElse(hidePlanned, 1, 0),
          }}
        />
      }
    />
  );
};

const CloEvents = enhance((props: Object) => (
  <RelativeBox>
    <RelativeBox pr={15} zIndex={2}>
      {renderTitlePanel(props)}
    </RelativeBox>
    <RelativeBox zIndex={1}>
      {renderTable(props)}
    </RelativeBox>
  </RelativeBox>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  noExportable: () => true,
  telList: makeSelectTelList(state),
  itemList: makeSelectCloEventList(state),
  hidePlanned: makeSelectHidePlanned(state),
  totalCount: makeSelectCloEventTotal(state),
  currentRoute: makeSelectCurrentRoute(state),
  loading: makeSelectCloEventListLoading(state),
  pagination: makeSelectCloEventPagination(state),
  selectedReport: makeSelectCloEventReport(state),
  plannerItems: makeSelectCurrentRouteItems(state),
  crossDocks: makeSelectCrossDockLocationList(state),
  filterParams: makeSelectCloEventFilterParams(state),
  reportList: makeSelectAvailableCloEventReports(state),
  globalFilterValue: makeSelectGlobalFilterValue(state),
  tableTitleFilters: makeSelectCloEventsTableTitleFilters(state),
  titleSortValues: makeSelectCloEventsTableTitleSortValues(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectCloEvent,
  addTelToPlanner,
  toggleHidePlanned,
  setCloEventsReports,
  getCloEventsNextPage,
  setGlobalFilterValue,
  toggleCloEventDetails,
  cleanCloEventQuickFilter,
  uncheckAndRemoveCloEvent,
  getCloEventDetailsRequest,
  selectCloEventForTelRequest,
  createCloEventsReportRequest,
  updateCloEventsReportRequest,
  resetCloEventListAndPagination,
  setReport: setCurrentCloEventReport,
  getTelForListAndAddToPlannerRequest,
  addCloEventToInterTerminalTelRequest,
  refreshCloEventListWithSelectedEvents,
  setUsedReport: setCurrentCloEventReport,
  getItemListRequest: getCloEventsNextPage,
  setTableTitleSort: setCloEventsTableTitleSort,
  setTableTitleFilter: setCloEventsTableTitleFilter,
  setQuickFilterParams: setCloEventQuickFilterParams,
  resetListAndPagination: resetCloEventListAndPagination,
  changeDefaultReportRequest: changeDefaultCloEventReportRequest,
})(CloEvents);
