import * as R from 'ramda';
import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { renderBorderColor } from '../../forms/helpers';
//////////////////////////////////////////////////

export const RightTitlePanel = styled.div`
  display: flex;
  z-index: 11;
  align-items: center;
  & #dropdown-user-export {
    & > div > img {
      height: 20px;
    }
  }
`;

export const FormSectionWrapper = styled.div`
width: 660px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 0 5px 5px 5px;
  border: ${() => `1px solid ${G.getTheme('colors.light.darkRed')}`};
  & > span:first-child {
    font-size: 14px;
    padding: 8px 15px;
    color: ${() => G.getTheme('colors.white')};
    background: ${() => G.getTheme('colors.light.mainRed')};
  }
  & > form {
    min-height: 442px;
    background: linear-gradient(
      to right,
      ${() => G.getTheme('forms.labelsWrapper.bgColor')} 188px,
      ${() => G.getTheme('pages.layOutBgColor')} 0%
    );
  }
`;

export const PreviewWrapper = styled.div`
  display: flex;
  min-height: 390px;
  position: relative;
  align-items: center;
`;

export const fieldClass = css`
  width: 188px;
  padding-left: 15px;
`;

export const MultiValue = styled.div`
  margin: 2px;
  padding: 3px 5px;
  width: max-content;
  border-radius: 2px;
  display: inline-flex;
  background: ${() => G.getTheme('dropDown.borderColor')};
  border: ${() => `1px solid ${G.getTheme('dropDown.textColor')}`};
  & > span:nth-child(1) {
    padding: 0 2px;
    &:hover {
      cursor: pointer;
      color: ${() => G.getTheme('dropDown.head.hover.textColor')};
    }
  }
  & > span:nth-child(2) {
    width: 1px;
    margin: 0 3px;
    background: ${() => G.getTheme('dropDown.textColor')};
  }
  & > span:nth-child(3) {
    max-width: 160px;
    overflow: hidden;
    position: relative;
    &:after {
      top: 0px;
      right: 0px;
      width: 10px;
      content: '';
      height: 16px;
      position: absolute;
      background: ${({ useGradient }: Object) => G.ifElse(
        useGradient,
        `linear-gradient(90deg, rgba(0,0,0,0), ${G.getTheme('dropDown.borderColor')})`,
        '',
      )};
    }}
`;

export const PreviewButton = styled.div`
  border-radius: 2px;
  height: 30px;
  margin: 0 15px;
  padding: 8px 0;
  text-align: center;
  width: 100px;
  color: ${() => G.getTheme('colors.white')};
  background-color: ${() => G.getTheme('colors.light.darkRed')};
  &:hover {
    cursor: pointer;
  }
`;

export const PreviewFooter = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background: ${() => G.getTheme('colors.light.lightGrey')};
`;

export const PreviewMessage = styled.ul`
  font-size: 28px;
  color: ${() => G.getTheme('colors.light.middleGrey')};
`;

export const AdditionFiltersWrapper = styled.div`
  width: 1350px;
  border-radius: 5px;
  margin: 20px 0 55px;
  border: ${() => `1px solid ${G.getTheme('colors.light.darkRed')}`};
  & > div:first-child {
    height: 32px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    color: ${() => G.getTheme('colors.light.mainLight')};
    background-color: ${() => G.getTheme('colors.light.mainRed')};
    & > span:nth-child(2) {
      padding: 0 5px;
      &:hover {
        cursor: pointer;
      }
      & svg path {
        fill: ${() => G.getTheme('colors.white')};
      }
    }
  }
`;

export const AdditionalFilterMessage = styled.div`
  display: flex;
  height: 100px;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  color: ${() => G.getTheme('colors.light.middleGrey')};
`;

export const groupStyles = css`
  margin: 10px;
  display: flex;
  align-items: center;
`;

export const chartNameErrorClass = css`
  width: 150px;
`;
