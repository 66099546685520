import * as R from 'ramda';
import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('carrierEdi');

const makeSelectGroupBy = () => createSelector(
  store,
  ({ groupBy }: Object) => groupBy,
);

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);


const makeSelectGlobalFilterValue = () => createSelector(
  store,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectFilterProps = () => createSelector(
  store,
  ({ filterProps }: Object) => filterProps,
);

export {
  makeSelectGroupBy,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectGlobalFilterValue,
};
