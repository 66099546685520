import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-planner
import * as H from '../helpers';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const HIDDEN = 'HIDDEN';
const CLOS_SHOWN = 'CLO_SHOWN';
const ITEMS_SHOWN = 'ITEMS_SHOWN';

const getExpandOptions = (mode: string, isClo: boolean) => {
  const options = {
    [HIDDEN]: {
      value: HIDDEN,
      icon: 'arrowUpSimple',
      text: G.getWindowLocale('actions:hide', 'Hide'),
    },
    [ITEMS_SHOWN]: {
      value: ITEMS_SHOWN,
      icon: 'arrowDownSimple',
      text: G.getWindowLocale('actions:show-items', 'Show Items'),
    },
    [CLOS_SHOWN]: {
      value: CLOS_SHOWN,
      icon: 'arrowDownSimple',
      text: G.getWindowLocale('actions:show-clos', 'Show CLOs'),
    },
  };
  if (isClo) {
    if (R.equals(mode, HIDDEN)) {
      return [options[ITEMS_SHOWN]];
    }
    return [options[HIDDEN]];
  }
  if (R.equals(mode, HIDDEN)) {
    return [options[ITEMS_SHOWN], options[CLOS_SHOWN]];
  } else if (R.equals(mode, CLOS_SHOWN)) {
    return [options[ITEMS_SHOWN], options[HIDDEN]];
  }
  return [options[CLOS_SHOWN], options[HIDDEN]];
};

const enhance = compose(
  withState('expandedMode', 'setExpandedMode', HIDDEN),
  withHandlers({
    handleSetExpandedMode: ({ itemsExpanded, setExpandedMode }: Object) => () => {
      const mode = G.ifElse(itemsExpanded, ITEMS_SHOWN, HIDDEN);
      setExpandedMode(mode);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'itemsExpanded',
    callbackName: 'handleSetExpandedMode',
  }),
  pure,
);

const renderWeight = (item: Object) => G.ifElse(
  G.isNotNil(item[GC.FIELD_ITEM_WEIGHT]),
  `${item[GC.FIELD_ITEM_WEIGHT]} ${item[GC.FIELD_ITEM_WEIGHT_TYPE]}`,
  '',
);

const renderQuantity = ({ quantity, packageType }: Object) => G.ifElse(
  G.isAllNotNilOrNotEmpty([quantity, packageType]),
  `, ${quantity} ${packageType}`,
  '',
);

const renderItemMainInfo = (item: Object) => `${renderWeight(item)}${renderQuantity(item)}`;

const renderItems = ({
  tel,
  clos,
  stop,
  items,
  color,
  containers,
  handleEditItem,
  handleRemoveItem,
}: Object) => {
  const boxBG = G.getTheme('colors.light.mainLight');
  const boxBorderColor = G.getTheme('colors.light.darkGrey');

  return stop.itemIds.map((id: Object, i: number) => {
    const item = R.pathOr(null, [id], items);

    if (R.isNil(item)) return null;

    const { temperatureSensor } = item;

    const hasTemperatureSensor = R.equals(temperatureSensor, ENUMS.ENUM_YES);

    const isCloItem = G.isLoadTypeClo(item);
    const loadName = H.getLoadName(
      G.ifElse(isCloItem, R.path([item.loadGuid], clos), tel),
      G.getWindowLocale('titles:unknown', 'Unknown'),
    );
    const isVehicle = G.isItemTypeVehicle(item);
    const vinShort = R.compose(
      R.takeLast(6),
      R.pathOr('', [GC.FIELD_VIN]),
    )(item);
    const itemInfo = G.ifElse(
      isVehicle,
      `${vinShort}, ${G.filterAndJoinObjectFields(
        item,
        [
          GC.FIELD_YEAR,
          GC.FIELD_MAKE,
          GC.FIELD_MODEL,
        ],
      )} (${loadName})`,
      `${R.prop(GC.FIELD_ITEM_ID, item)} (${loadName})`,
    );
    let title = itemInfo;

    if (isVehicle) {
      title = `${G.filterAndJoinObjectFields(
        item,
        [
          GC.FIELD_VIN,
          GC.FIELD_YEAR,
          GC.FIELD_MAKE,
          GC.FIELD_MODEL,
          GC.FIELD_COLOR,
        ],
      )
      } (${loadName})`;
    } else {
      const container = R.path([R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item)], containers);
      if (G.isNotNil(container)) {
        title = `${itemInfo}, ${G.getWindowLocale('titles:container', 'Container')}: ${H.getContainerInfo(container)}`;
      }
    }

    return (
      <Box
        p='5px'
        bg={boxBG}
        width='100%'
        display='flex'
        borderBottom='1px solid'
        borderColor={boxBorderColor}
        key={`${i}-${stop.guid}-${item.guid}-${item.itemInternalId}`}
      >
        <TextComponent
          mr='6px'
          width='auto'
          color={color}
          fontSize={12}
          title={title}
          display='block'
          overflow='hidden'
          withEllipsis={true}
        >
          {itemInfo}
        </TextComponent>
        <Flex ml='auto' whiteSpace='nowrap' fontSize={12} justifyContent='space-between'>
          <Box wordBreak='break-all'>
            {renderItemMainInfo(item)}
          </Box>
          {
            hasTemperatureSensor &&
            <Box ml='4px' title={G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor')}>
              {I.temperatureSensor(null, 16, 16)}
            </Box>
          }
          <Flex>
            <Box
              ml='6px'
              mr='8px'
              cursor='pointer'
              onClick={() => handleEditItem(stop, id)}
            >
              {I.pencil(color, 13, 13)}
            </Box>
            <Box cursor='pointer' onClick={() => handleRemoveItem(stop, id)}>
              {I.minusInRound(color, 13, 13)}
            </Box>
          </Flex>
        </Flex>
      </Box>
    );
  });
};

const getGroupedItemsByClo = (items: Object) => R.compose(
  R.map((loadName: string) => ({
    loadName,
    items: R.compose(
      R.filter(R.propEq(loadName, 'loadName')),
      R.values,
    )(items),
  })),
  R.uniq,
  R.filter(G.isNotNil),
  R.map(R.pathOr(null, ['loadName'])),
  R.values,
)(items);

const renderClos = (props: Object) => {
  const { tel, clos, stop, items, color } = props;
  const itemsToUse = R.compose(
    R.map((item: Object) => {
      const loadName = H.getLoadName(
        G.ifElse(G.isLoadTypeClo(item), R.path([item.loadGuid], clos), tel),
        G.getWindowLocale('titles:unknown', 'Unknown'),
      );

      return R.assoc('loadName', loadName, item);
    }),
    R.pick(R.prop(GC.FIELD_STOP_ITEM_IDS, stop)),
  )(items);
  const groupedItems = getGroupedItemsByClo(itemsToUse);
  const boxBG = G.getTheme('colors.light.mainLight');
  const boxBorderColor = G.getTheme('colors.light.darkGrey');

  return groupedItems.map(({ items, loadName }: Object, i: number) => {
    const totalWeight = G.calcItemsTotalWeightWithoutQty(items);
    const weightText = `${R.pathOr('-', [GC.FIELD_ITEM_WEIGHT], totalWeight)} ${
      R.pathOr('', [GC.FIELD_ITEM_WEIGHT_TYPE], totalWeight)}`;

    return (
      <Box
        p='5px'
        key={i}
        bg={boxBG}
        width='100%'
        display='flex'
        borderBottom='1px solid'
        borderColor={boxBorderColor}
      >
        <TextComponent
          mr='6px'
          color={color}
          fontSize={12}
          display='block'
          title={loadName}
          overflow='hidden'
          withEllipsis={true}
          width='calc(50% - 12px)'
        >
          {loadName}
        </TextComponent>
        <Flex width='50%' fontSize={12}>
          <Box mx='4px'>{R.length(items)} {G.getWindowLocale('titles:items', 'Items')}:</Box>
          <Box fontWeight='bold'>{weightText}</Box>
        </Flex>
      </Box>
    );
  });
};

const Content = (props: Object) => {
  if (R.equals(props.expandedMode, HIDDEN)) return null;

  const condition = R.and(
    G.isNilOrEmpty(R.prop('itemIds', props.stop)),
    G.isNilOrEmpty(R.prop('items', props.stop)),
  );

  if (condition) return null;

  return (
    <Box
      overflow='auto'
      maxHeight='200px'
      flexDirection='column'
    >
      {R.equals(props.expandedMode, ITEMS_SHOWN) && renderItems(props)}
      {R.equals(props.expandedMode, CLOS_SHOWN) && renderClos(props)}
    </Box>
  );
};

const ItemsInfo = (props: Object) => {
  const { stop, items, handleClickAddItems } = props;
  const count = H.getStopItemsCount(stop, items);
  const stopItems = R.filter(
    ({ itemInternalId }: Object) => R.includes(itemInternalId, stop.itemIds),
    R.values(items),
  );
  const totalWeight = G.calcItemsTotalWeightWithoutQty(stopItems);
  const weightText = `${R.pathOr('-', [GC.FIELD_ITEM_WEIGHT], totalWeight)} ${
    R.pathOr('', [GC.FIELD_ITEM_WEIGHT_TYPE], totalWeight)}`;

  const hasTemperatureSensor = R.any(
    ({ temperatureSensor }: Object) => R.equals(temperatureSensor, ENUMS.ENUM_YES),
    stopItems,
  );

  return (
    <Flex>
      <Box cursor='pointer' onClick={() => handleClickAddItems(stop)}>
        {I.plusRound(props.color, 13, 13)}
      </Box>
      <Box mx='4px'>{count} {G.getWindowLocale('titles:items', 'Items')}:</Box>
      <Box fontWeight='bold'>{weightText}</Box>
      {
        hasTemperatureSensor &&
        <Box ml='4px' title={G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor')}>
          {I.temperatureSensor(null, 16, 16)}
        </Box>
      }
    </Flex>
  );
};

const StopItems = enhance((props: StopInfoPropsType) => {
  const { stop, expandedMode } = props;
  const expandOptions = getExpandOptions(
    expandedMode,
    R.or(G.isLoadTypeClo(stop), R.propEq(GC.STOP_TYPE_INDEPENDENT, GC.FIELD_STOP_TYPE, stop)),
  );

  return (
    <Box
      width='100%'
      borderRadius={5}
      overflow='hidden'
      bg={G.getTheme('colors.light.lightGrey')}
    >
      <Flex px='8px' height={24} fontSize={12} justifyContent='space-between'>
        <ItemsInfo {...props} color={blueColor} />
        <Flex>
          {
            expandOptions.map((option: Object, i: number) => (
              <Flex mr='6px' key={i} cursor='pointer' onClick={() => props.setExpandedMode(option.value)}>
                <Box color={blueColor}>{option.text}</Box>
                <Box ml='3px'>{I[option.icon](blueColor, 9, 6)}</Box>
              </Flex>
            ))
          }
        </Flex>
      </Flex>
      <Content {...props} color={blueColor} />
    </Box>
  );
});

export default StopItems;
