import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DragDropContext } from 'react-beautiful-dnd';
import { pure, compose, branch, withState, withHandlers, renderNothing } from 'react-recompose';
// components
import { Tabs, withTabs } from '../../components/tabs';
import { PageActions } from '../../components/page-actions';
import { ConfirmComponent } from '../../components/confirm';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { startPivotTable } from '../pivot-table/actions';
import { makeSelectPivotTableTotalCount } from '../pivot-table/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import PivotTableWrapper from '../pivot-table/components/pivot-table-wrapper';
import {
  makeSelectAvailableReferenceTypesByScope,
  makeSelectAllAvailableReferenceTypesByScopeName,
} from '../reference/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// feature dashboard
import ChartComponent from './components/chart';
import { getTabs } from './settings/tabs-options';
import RightSidebar from './components/right-sidebar';
import { EmptyDashboard, AnalyticsPageWrapper } from './ui';
import CreateDashboard from './components/create-dashboard';
import { DASHBOARD_FORM, ANALYTICS_DND_ID } from './constants';
import {
  makeSelectGroupList,
  makeSelectDashboardList,
  makeSelectDashboardCharts,
  makeSelectCurrentDashboard } from './selectors';
import {
  getChartDataRequest,
  setCurrentDashboard,
  cleanDashboardCharts,
  createDashboardRequest,
  updateDashboardRequest,
  deleteDashboardRequest } from './actions';
//////////////////////////////////////////////////

const RenderChartsView = (props: Object) => {
  if (R.or(G.isNilOrEmpty(props.currentDashboard), R.isEmpty(props.dashboardList))) {
    return (
      <EmptyDashboard isOpen={props.showSidebar}>
        {G.getWindowLocale('titles:create-dashboard', 'Create Dashboard')}
      </EmptyDashboard>
    );
  }
  return (
    <ChartComponent
      isOpen={props.showSidebar}
      activeTab={props.activeTab}
      handleClickTab={props.handleClickTab}
      dashboardCharts={props.dashboardCharts}
      currentDashboard={props.currentDashboard}
      handleEditDashboard={props.handleEditDashboard}
      setCurrentDashboard={props.setCurrentDashboard}
      cleanDashboardCharts={props.cleanDashboardCharts}
      handleDeleteDashboard={props.handleDeleteDashboard}
      handleSaveAsNewDashboard={props.handleSaveAsNewDashboard} />
  );
};

const enhance = compose(
  withTabs,
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      R.isNil(props.groupList),
    ),
    renderNothing,
  ),
  withState('showSidebar', 'setStatusSidebar', true),
  withState('filteredGroup', 'setList', ({ groupList }: Object) => groupList),
  withHandlers({
    handleClickTab: (props: Object) => (index: number) => {
      if (G.notEquals(index, 0)) {
        const pivotTableTypesMap = {
          1: GC.PIVOT_TEL_REPORT,
          2: GC.PIVOT_CLO_REPORT,
        };
        props.startPivotTable(pivotTableTypesMap[index]);
      }
      props.handleSetActiveTab(index);
    },
    handleFilterGroups: (props: Object) => (inputValue: Object) => {
      const filteredGroup = R.filter(
        (group: Object) => G.stringContains(R.toLower(group.group), R.toLower(inputValue)),
        props.groupList,
      );
      props.setList(filteredGroup);
    },
    handleCreateDashboard: (props: Object) => () => {
      const modalContext = (
        <CreateDashboard closeModal={props.closeModal} submitAction={props.createDashboardRequest} />
      );
      const modal = {
        component: modalContext,
        options: {
          width: 300,
          height: 'auto',
          btnWidth: '130px',
          title: G.getWindowLocale('titles:create', 'Create'),
          controlButtons: [
            {
              type: 'button',
              id: DASHBOARD_FORM,
              name: G.getWindowLocale('actions:create', 'Create'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleEditDashboard: (props: Object) => () => {
      const modalContext = (
        <CreateDashboard
          closeModal={props.closeModal}
          initialValues={props.currentDashboard}
          submitAction={props.updateDashboardRequest} />
      );
      const modal = {
        component: modalContext,
        options: {
          width: 300,
          height: 'auto',
          btnWidth: '130px',
          title: G.getWindowLocale('actions:update-dashboard', 'Update Dashboard'),
          controlButtons: [
            {
              type: 'button',
              id: DASHBOARD_FORM,
              name: G.getWindowLocale('actions:update', 'Update'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleDeleteDashboard: (props: Object) => () => {
      const { openModal, closeModal, currentDashboard, deleteDashboardRequest } = props;

      const modalContext = (
        <ConfirmComponent
          name={G.getPropFromObject(GC.FIELD_NAME, currentDashboard)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component: modalContext,
        options: {
          width: 440,
          btnWidth: 190,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteDashboardRequest(G.getGuidFromObject(currentDashboard));
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleSaveAsNewDashboard: (props: Object) => () => {
      const chartsDetails = props.dashboardCharts.map((chart: Object) => R.omit(['data'], chart));
      const initialValues = {
        ...props.currentDashboard,
        name: '',
        guid: null,
        chartsDetails,
        saveAsNew: true,
      };
      const modalContext = (
        <CreateDashboard
          initialValues={initialValues}
          closeModal={props.closeModal}
          submitAction={props.createDashboardRequest} />
      );
      const modal = {
        component: modalContext,
        options: {
          width: 300,
          height: 'auto',
          btnWidth: '130px',
          title: G.getWindowLocale('messages:are-you-sure', 'Are you sure?'),
          controlButtons: [
            {
              type: 'button',
              id: DASHBOARD_FORM,
              name: G.getWindowLocale('actions:save', 'Save'),
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleDragEnd: (props: Object) => (result: Object) => {
      const condition = G.notEquals(
        ANALYTICS_DND_ID,
        R.pathOr(null, ['destination', 'droppableId'], result),
      );
      if (condition) return;
      const { source } = result;
      const charts = R.compose(
        R.pathOr([], ['items']),
        R.find(R.propEq(source.droppableId, 'group')),
      )(props.groupList);
      const chart = {
        x: 0,
        y: 0,
        h: 2,
        w: 3,
        chartGuid: charts[source.index].guid,
        chart: charts[source.index],
      };
      props.getChartDataRequest(chart);
    },
  }),
  pure,
);

const DashboardComponent = (props: Object) => {
  const listActions = [
    {
      action: props.handleCreateDashboard,
      text: G.getWindowLocale('titles:create-new-dashboard', 'Create New Dashboard'),
      icon: I.createCloIcon(G.getTheme('colors.light.mainLight'), '40px', '40px', 'transparent'),
    },
  ];

  if (R.equals(props.activeTab, 0)) {
    return (
      <DragDropContext onDragEnd={props.handleDragEnd}>
        <AnalyticsPageWrapper p={15}>
          <RenderChartsView {...props} />
          <RightSidebar
            showSidebar={props.showSidebar}
            groupList={props.filteredGroup}
            dashboardList={props.dashboardList}
            dashboardCharts={props.dashboardCharts}
            setStatusSidebar={props.setStatusSidebar}
            currentDashboard={props.currentDashboard}
            handleFilterGroups={props.handleFilterGroups}
            getChartDataRequest={props.getChartDataRequest}
            setCurrentDashboard={props.setCurrentDashboard}
            cleanDashboardCharts={props.cleanDashboardCharts} />
          <AuthWrapper has={[PC.DASHBOARD_WRITE]}>
            <PageActions listActions={listActions} shadowColor={G.getTheme('colors.light.darkGrey')} />
          </AuthWrapper>
        </AnalyticsPageWrapper>
      </DragDropContext>
    );
  }
  const tabsComponent = (
    <Tabs
      activeTab={props.activeTab}
      count={props.pivotTableTotalCount}
      handleClickTab={props.handleClickTab}
      tabs={getTabs(props.currentDashboard.name, props.activeTab)} />
  );

  return <PivotTableWrapper {...props} tabs={tabsComponent} />;
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  groupList: makeSelectGroupList(state),
  dashboardList: makeSelectDashboardList(state),
  dashboardCharts: makeSelectDashboardCharts(state),
  currentDashboard: makeSelectCurrentDashboard(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  pivotTableTotalCount: makeSelectPivotTableTotalCount(state),
  allAvailableRefTypesByScopeName: makeSelectAllAvailableReferenceTypesByScopeName(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  startPivotTable,
  setCurrentDashboard,
  getChartDataRequest,
  cleanDashboardCharts,
  createDashboardRequest,
  deleteDashboardRequest,
  updateDashboardRequest,
})(enhance(DashboardComponent));
