import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useMemo, useEffect, useCallback } from 'react';
// components
import EditReport from '../../../components/edit-report';
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// feature carrier-profile
import Form from '../forms';
import { makeSelectItemListByGroupName } from '../selectors';
import {
  getItemListSuccess,
  removeEntityRequest,
  toggleFormGroupTable,
  createOrUpdateEntityRequest,
  getCarrierProfileConfigsRequest,
} from '../actions';
//////////////////////////////////////////////////

const useFormGroupOperations = (props: Object, options: Object = {}) => {
  const {
    group,
    dispatch,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    primaryObjectGuid,
    handleToggleFormGroup,
  } = props;

  const {
    isReport,
    groupName,
    endpoints,
    customRemoveHandler,
    primaryObjectGuidKey,
  } = group;

  const { getReportItemList } = options;

  const itemList = useSelector((state: Object) => makeSelectItemListByGroupName(state, groupName));

  const handleToggleFormGroupTable = () => (
    G.isFunction(handleToggleFormGroup) ? handleToggleFormGroup(groupName) : dispatch(toggleFormGroupTable(groupName))
  );

  const handleCreateOrUpdateEntityRequest = (...args: Array) =>
    dispatch(createOrUpdateEntityRequest(...args));

  const handleGetCarrierProfileConfigsRequest = (...args: Array) =>
    dispatch(getCarrierProfileConfigsRequest(...args));

  const getItemListRequest = useCallback(async () => {
    const {
      makeRequestPayload,
      getItemListRequestMethod = 'get',
    } = group;

    const { list, getListByGuid } = R.or(endpoints, {});

    if (G.isAllNilOrEmpty([list, getListByGuid])) return;

    let options = {
      params: { [primaryObjectGuidKey]: primaryObjectGuid },
    };

    if (G.isFunction(makeRequestPayload)) options = makeRequestPayload(props);

    let endpoint;

    if (G.isFunction(getListByGuid)) {
      endpoint = getListByGuid(props);
    } else {
      endpoint = R.prop(list, endpointsMap);
    }

    G.callFunction(openLoader);

    const res = await sendRequest(getItemListRequestMethod, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      dispatch(getItemListSuccess({ data, groupName }));
    } else {
      G.handleFailResponseSimple(res);
    }

    G.callFunction(closeLoader);
  }, [groupName]);

  const handleCreateOrUpdateItem = useCallback((entity: Object) => {
    const {
      fields,
      CustomForm,
      itemTitleArr,
      defaultValues,
      omitFormFields,
      validationSchema,
      makeInitialValues,
      useGetListRequest,
      configOptionNames,
      inputWrapperStyles,
      fieldsWrapperStyles,
      primaryObjectGuidKey,
      makeOptionsForSelect,
      modalAdditionalOptions = {},
      customCreateOrUpdateHandler,
    } = group;

    const isEditMode = G.isNotNil(G.getGuidFromObject(entity));
    const endpointName = G.getPropFromObject('createOrUpdate', endpoints);

    let initialValues = G.ifElse(
      isEditMode,
      entity,
      R.assoc(primaryObjectGuidKey, primaryObjectGuid, defaultValues),
    );

    if (G.isFunction(makeInitialValues)) initialValues = makeInitialValues(initialValues, props);

    const method = G.ifElse(isEditMode, 'put', 'post');

    let submitAction = (values: Object, additionalOptions: Object) => handleCreateOrUpdateEntityRequest({
      method,
      values,
      groupName,
      additionalOptions,
      endpoint: G.getPropFromObject(endpointName, endpointsMap),
      getItemListRequest: G.ifElse(useGetListRequest, R.or(getReportItemList, getItemListRequest)),
    });

    if (G.isFunction(customCreateOrUpdateHandler)) {
      submitAction = (values: Object, options: Object) =>
        customCreateOrUpdateHandler({ ...props, values, handleCreateOrUpdateEntityRequest }, options);
    }

    let optionsForSelect = {};

    if (G.isFunction(makeOptionsForSelect)) optionsForSelect = makeOptionsForSelect(props);

    let component;

    if (G.isNotNil(CustomForm)) {
      component = (
        <CustomForm
          {...props}
          {...group}
          submitAction={submitAction}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          handleGetCarrierProfileConfigsRequest={handleGetCarrierProfileConfigsRequest}
        />
      );
    } else {
      component = (
        <Form
          fields={fields}
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={initialValues}
          omitFormFields={omitFormFields}
          validationSchema={validationSchema}
          optionsForSelect={optionsForSelect}
          configOptionNames={configOptionNames}
          inputWrapperStyles={inputWrapperStyles}
          fieldsWrapperStyles={fieldsWrapperStyles}
          handleGetCarrierProfileConfigsRequest={handleGetCarrierProfileConfigsRequest}
        />
      );
    }

    const title = G.ifElse(isEditMode, G.getEditTitle, G.getAddTitle)(itemTitleArr);

    const modal = {
      p: 15,
      component,
      options: { title },
      ...G.isFunction(modalAdditionalOptions) ? modalAdditionalOptions(isEditMode) : modalAdditionalOptions,
    };

    openModal(modal);
  }, [group]);

  const handleRemoveItem = useCallback((guid: string, item: Object) => {
    const { endpoints, itemTitleArr, useGetListRequest } = group;

    const { remove, getRemoveEndpoint } = endpoints;

    const component = (
      <ConfirmComponent
        name={G.getWindowLocale(...itemTitleArr)}
        textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
      />
    );

    let endpoint;

    if (G.isFunction(getRemoveEndpoint)) {
      endpoint = getRemoveEndpoint(item);
    } else {
      endpoint = G.getPropFromObject(remove, endpointsMap);

      if (G.isFunction(endpoint)) endpoint = endpoint(guid);
    }

    let removeAction = () => dispatch(removeEntityRequest({
      guid,
      endpoint,
      isReport,
      groupName,
      getItemListRequest: G.ifElse(useGetListRequest, R.or(getReportItemList, getItemListRequest)),
    }));

    if (G.isFunction(customRemoveHandler)) {
      removeAction = () => customRemoveHandler(props, item);
    }

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: removeAction,
            name: G.getWindowLocale('actions:remove', 'Remove'),
          },
        ],
      },
    };

    openModal(modal);
  }, [groupName, customRemoveHandler]);

  const handlePreviewDocument = useCallback(async (entity: Object, extraOptions: Object) => {
    const { fileUri, fileName, documentFilename } = entity;

    const { endpointName, primaryObjectGuidKey } = extraOptions;

    if (G.isNilOrEmpty(endpointName)) return;

    openLoader();

    const options = {
      resType: 'arraybuffer',
      params: {
        fileUri,
        [primaryObjectGuidKey]: primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.or(fileName, documentFilename),
      },
    };

    const endpoint = G.getPropFromObject(endpointName, endpointsMap);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.openFileInWindowFromArrayBufferResponse(res);
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, []);

  const handleDownloadDocument = useCallback(async (entity: Object, extraOptions: Object) => {
    const { fileUri, fileName, documentFilename } = entity;

    const { endpointName, primaryObjectGuidKey } = extraOptions;

    if (G.isNilOrEmpty(endpointName)) return;

    openLoader();

    const options = {
      resType: 'arraybuffer',
      params: {
        fileUri,
        [primaryObjectGuidKey]: primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.or(fileName, documentFilename),
      },
    };

    const endpoint = G.getPropFromObject(endpointName, endpointsMap);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      G.showToastrMessageSimple(
        'success',
        G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
      );
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, []);

  useEffect(() => {
    if (isReport) return;

    if (R.isNil(itemList)) getItemListRequest();
  }, [isReport, getItemListRequest]);

  return {
    handleRemoveItem,
    getItemListRequest,
    handlePreviewDocument,
    handleDownloadDocument,
    handleCreateOrUpdateItem,
    handleToggleFormGroupTable,
    handleCreateOrUpdateEntityRequest,
    itemList: R.propOr(itemList, 'itemList', props),
  };
};

const useReportFormGroupOperations = (props: Object) => {
  const {
    group,
    openModal,
    setReports,
    reportList,
    selectItem,
    setUsedReport,
    selectedReport,
    updateReportRequest,
    createReportRequest,
    setTableTitleFilter,
    resetListAndPagination,
    setTableTitleSortValues,
    getReportItemListRequest,
    getAvailableReportsRequest,
    changeDefaultReportRequest,
  } = props;

  const {
    groupName,
    reportType,
    makeRequestPayload,
  } = group;

  const handleGetItemListRequest = useCallback(() => {
    const endpointName = R.path(['endpoints', 'list'], group);

    if (G.isNilOrEmpty(endpointName)) return;

    getReportItemListRequest({
      groupName,
      reportType,
      reqParams: makeRequestPayload(props),
      endpoint: G.getPropFromObject(endpointName, endpointsMap),
    });
  }, [groupName]);

  const {
    handleSetReports,
    handleSelectItem,
    handleSetUsedReport,
    handleSetTableTitleSort,
    handleSetTableTitleFilter,
    handleResetListAndPagination,
    handleChangeDefaultReportRequest,
  } = useMemo(() => ({
    handleSelectItem: (id: string) => selectItem({ id, groupName }),
    handleResetListAndPagination: () => resetListAndPagination({ groupName }),
    handleSetReports: (reports: Object) => setReports({ reports, groupName }),
    handleSetTableTitleFilter: (data: Object) => setTableTitleFilter({ data, groupName }),
    handleSetUsedReport: (usedReport: Object) => setUsedReport({ groupName, usedReport }),
    handleSetTableTitleSort: (data: Object) => setTableTitleSortValues({ data, groupName }),
    handleChangeDefaultReportRequest: (data: Object) => changeDefaultReportRequest({
      data,
      groupName,
      reportType,
      getItemListRequest: handleGetItemListRequest,
    }),
  }), [groupName]);

  const handleSelectReport = useCallback((reportGuid: string, asDefault: boolean) => {
    const selectedReport = R.find(R.propEq(GC.FIELD_GUID, reportGuid), reportList);

    setUsedReport(selectedReport);

    if (R.not(asDefault)) handleGetItemListRequest();
  }, [reportList, handleGetItemListRequest]);

  const handleEditReport = useCallback((fields: Array) => {
    const handleCreateReportRequest = (newReport: Object) => (
      createReportRequest({ groupName, newReport, reportType, getItemListRequest: handleGetItemListRequest })
    );

    const handleUpdateReportRequest = (newReport: Object) => (
      updateReportRequest({ groupName, newReport, reportType, getItemListRequest: handleGetItemListRequest })
    );

    const component = (
      <EditReport
        fields={fields}
        hasIssues={false}
        usedReport={selectedReport}
        setReport={handleSetUsedReport}
        onReportSet={() => handleGetItemListRequest()}
        updateReportRequest={handleUpdateReportRequest}
        createReportRequest={handleCreateReportRequest}
      />
    );

    const modal = G.getDefaultReportModal(component);

    openModal(modal);
  }, [selectedReport, handleGetItemListRequest]);

  const {
    itemList,
    handleRemoveItem,
    handleCreateOrUpdateItem,
    handleToggleFormGroupTable,
  } = useFormGroupOperations(
    props,
    {
      getReportItemList: () => {
        handleResetListAndPagination();
        handleGetItemListRequest();
      },
    },
  );

  useEffect(() => {
    if (R.isNil(itemList)) {
      getAvailableReportsRequest({ groupName, reportType, setUsedReport: true, callback: handleGetItemListRequest });
    }
  }, []);

  const handleTableTitleFilter = (data: Object) => G.handleTableTitleFilter({
    setTableTitleSort: handleSetTableTitleSort,
    getItemListRequest: handleGetItemListRequest,
    setTableTitleFilter: handleSetTableTitleFilter,
    resetListAndPagination: handleResetListAndPagination,
  })(data);

  return {
    itemList,
    handleSelectItem,
    handleSetReports,
    handleRemoveItem,
    handleEditReport,
    handleSelectReport,
    handleSetUsedReport,
    handleTableTitleFilter,
    handleSetTableTitleSort,
    handleCreateOrUpdateItem,
    handleSetTableTitleFilter,
    handleToggleFormGroupTable,
    handleResetListAndPagination,
    handleChangeDefaultReportRequest,
    getItemListRequest: handleGetItemListRequest,
  };
};

export {
  useFormGroupOperations,
  useReportFormGroupOperations,
};
